import { Card, Input, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function Skeleton() {
  const t = useTranslationMarketing('coupons.couponForm.code');

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="flex-start" spacing="tight">
          <Input.Skeleton />
          <Text fontSize="caption">{t('helpText')}</Text>
        </Stack>
      </Card.Body>
    </Card>
  );
}
