import { ChangeEvent } from 'react';
import {
  Box,
  Icon,
  IconButton,
  Link,
  Text,
  Tooltip,
} from '@nimbus-ds/components';
import {
  ExclamationCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionFormProps } from '../PromotionForm';

export function ProgressiveDiscounts({
  values,
  errors,
  onChangeProgressiveDiscounts,
}: Readonly<
  Pick<PromotionFormProps, 'values' | 'errors' | 'onChangeProgressiveDiscounts'>
>) {
  const t = useTranslationMarketing('promotions.settingsPage.discountType');

  const { discountForQuantity, progressiveDiscounts } = values;

  if (!discountForQuantity) return null;

  const handleProgressiveDiscountsChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const newProgressiveDiscounts =
        progressiveDiscounts?.map((item, i) =>
          i === index ? { ...item, [name]: value } : item,
        ) || [];
      onChangeProgressiveDiscounts(newProgressiveDiscounts);
    };

  const deleteProgressiveDiscount = (index: number) => {
    const newProgressiveDiscounts =
      progressiveDiscounts.filter((_, i) => i !== index) || [];
    onChangeProgressiveDiscounts(newProgressiveDiscounts);
  };

  const addProgressiveDiscount = () => {
    const newProgressiveDiscounts = [
      ...progressiveDiscounts,
      { quantity: 0, discount: 0 },
    ];
    onChangeProgressiveDiscounts(newProgressiveDiscounts);
  };

  const errorMessage = (
    index: number,
    field: 'quantity' | 'discount' | null = null,
  ): string => {
    const path = `progressiveDiscounts[${index}]` + (field ? `.${field}` : '');
    return errors[path] ?? '';
  };

  const errorMessages = (index: number) => {
    const errorMessages = new Set<string>();
    errorMessage(index) && errorMessages.add(errorMessage(index));
    errorMessage(index, 'quantity') &&
      errorMessages.add(errorMessage(index, 'quantity'));
    errorMessage(index, 'discount') &&
      errorMessages.add(errorMessage(index, 'discount'));
    return [...errorMessages];
  };

  return (
    <>
      {progressiveDiscounts.map((progressiveDiscount, i) => (
        <>
          <Box display="flex" gap="4" alignItems="flex-end" key={i}>
            <FormField.Input
              name="discount"
              label={t('discountNew')}
              value={progressiveDiscount.discount || ''}
              onChange={handleProgressiveDiscountsChange(i)}
              append={<Text color="neutral-textLow">%</Text>}
              appendPosition="end"
              appearance={
                errorMessage(i, 'discount') || errorMessage(i)
                  ? 'danger'
                  : undefined
              }
            />
            <Box minWidth="140px" width="100%">
              <FormField.Input
                name="quantity"
                label={t('quantity')}
                value={progressiveDiscount.quantity || ''}
                onChange={handleProgressiveDiscountsChange(i)}
                append={<Text color="neutral-textLow">{t('products')}</Text>}
                appendPosition="end"
                appearance={
                  errorMessage(i, 'quantity') || errorMessage(i)
                    ? 'danger'
                    : undefined
                }
              />
            </Box>
            {progressiveDiscounts.length > 1 && (
              <Tooltip content={t('deleteProgressiveDiscount')} arrow={false}>
                <IconButton
                  source={<TrashIcon />}
                  size="2rem"
                  onClick={() => deleteProgressiveDiscount(i)}
                />
              </Tooltip>
            )}
          </Box>
          {errorMessages(i).map((error, i) => (
            <Box display="inline-flex" gap="1" key={i}>
              <Icon
                color="danger-textLow"
                source={<ExclamationCircleIcon size={12} />}
              />
              <Text color="danger-textLow" fontSize="caption">
                {t(error)}
              </Text>
            </Box>
          ))}
        </>
      ))}
      <Link
        onClick={addProgressiveDiscount}
        textDecoration="none"
        appearance="primary"
      >
        <PlusCircleIcon />
        {t('newProgressiveDiscount')}
      </Link>
    </>
  );
}
