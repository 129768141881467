import useCategoriesMetafields from 'domains/Catalog/Categories/hooks/useCategoriesMetafields';
import { MetafieldSelectedsOrErrorType } from 'domains/Catalog/Products/pages/components/Variants/types';
import { useGetCategoriesMetafields } from 'domains/Metafields/hooks';
import { MetafieldsSelected } from '../../types';
import SectionCategoriesMetafields from '../SectionCategoriesMetafields';

export interface SectionCategoriesMetafieldsExistingProps {
  id: string;
  selecteds: MetafieldSelectedsOrErrorType;
  apiSelecteds: MetafieldSelectedsOrErrorType;
  onChange: (metafields: MetafieldsSelected) => void;
}

export default function SectionCategoriesMetafieldsExisting({
  id,
  ...rest
}: Readonly<SectionCategoriesMetafieldsExistingProps>) {
  const { status } = useGetCategoriesMetafields();
  const { status: metafieldsStatus } = useCategoriesMetafields(id);

  const showError = status.isError;
  const showLoading = status.isLoading || metafieldsStatus.isLoading;
  const showSuccess = status.isSuccess && metafieldsStatus.isSuccess;

  return (
    <SectionCategoriesMetafields
      {...rest}
      showError={showError}
      showLoading={showLoading}
      showSuccess={showSuccess}
    />
  );
}
