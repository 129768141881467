import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getUnreadMessagesCountAction } from '../messagesSlice';
import { getUnreadMessagesCount } from '../messagesSlice/messagesSelectors';

function useGetUnreadMessagesCount() {
  const dispatch = useAppDispatch();
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);

  const fetchUnreadMessagesCount = useCallback(async () => {
    await dispatch(getUnreadMessagesCountAction());
  }, [dispatch]);

  return {
    unreadMessagesCount,
    fetchUnreadMessagesCount,
  };
}

export default useGetUnreadMessagesCount;
