import { ReactNode } from 'react';
import { Link, Popover } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { useBoolean } from 'commons/hooks';

interface PopoverToogleProps {
  label: ReactNode;
  content: ReactNode;
}

function PopoverToogle({ label, content }: PopoverToogleProps): JSX.Element {
  const [popover, showPopover, hidePopover] = useBoolean(false);

  const Icon = popover ? ChevronUpIcon : ChevronDownIcon;

  return (
    <Popover
      arrow={false}
      content={content}
      position="bottom-start"
      visible={popover}
      maxWidth="80%"
      onVisibility={(current) => (current ? showPopover() : hidePopover())}
    >
      <Link as="button" textDecoration="none">
        {label}
        <Icon />
      </Link>
    </Popover>
  );
}

export default PopoverToogle;
