import { Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import { LOCATION_TAGS } from '../../constants';

export interface SalesChannelCardValuesInterface {
  tags: string[];
}

interface SalesChannelCardProps {
  readonly values: SalesChannelCardValuesInterface;
  readonly onChange: (name: string) => void;
}

function SalesChannelCard({
  values,
  onChange,
}: SalesChannelCardProps): JSX.Element {
  const t = useTranslationShipping();
  const onlineChecked = values?.tags?.includes(LOCATION_TAGS.online) ?? false;
  const offlineChecked = values?.tags?.includes(LOCATION_TAGS.offline) ?? false;

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('locations.form.salesChannel.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="base">
          <Text>{t('locations.form.salesChannel.subtitle')}</Text>
          <hr />
          <Checkbox
            name={LOCATION_TAGS.online}
            label={t('locations.form.salesChannel.online')}
            checked={onlineChecked}
            onChange={() => onChange(LOCATION_TAGS.online)}
          />
          <hr />
          <Checkbox
            name={LOCATION_TAGS.offline}
            label={t('locations.form.salesChannel.offline')}
            checked={offlineChecked}
            onChange={() => onChange(LOCATION_TAGS.offline)}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

SalesChannelCard.Skeleton = Skeleton;

export default SalesChannelCard;
