import { useState } from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { ModalConfirmationBase } from 'App/components';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useToast, useToastStatus } from 'commons/hooks';
import { useDisableFtpMutation } from 'domains/Online/Themes/themesApi';
import { trackingThemeFtpDisable } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { useCheckFtpStatus } from '../../hooks';

interface DisableFtpModalProps {
  showModal: boolean;
  onClose: () => void;
}

export function DisableFtpModal({
  showModal,
  onClose,
}: Readonly<DisableFtpModalProps>) {
  const t = useTranslationOnline('themesPage.theme.disableFtpModal');
  const { addToast } = useToast();
  const [disableFtp, { status: statusDisable }] = useDisableFtpMutation();
  const { checkFtpStatus } = useCheckFtpStatus();
  const [isLoading, setIsLoading] = useState(false);

  useToastStatus({
    status: toStatusType(statusDisable),
    error: t('error'),
  });

  const handleClickDisableFtp = async () => {
    trackingThemeFtpDisable();
    setIsLoading(true);
    await disableFtp();
    checkFtpStatus({
      status: 'closed',
      onSuccess: () => {
        addToast({
          label: t('success'),
          appearance: 'success',
        });
        setIsLoading(false);
        onClose();
      },
    });
  };

  return (
    <>
      <ModalConfirmationBase
        show={showModal && !isLoading}
        title={t('title')}
        text={t('subtitle')}
        label={t('confirm')}
        labelCancel={t('cancel')}
        appearance="primary"
        disabled={isLoading}
        onConfirm={handleClickDisableFtp}
        onCancel={onClose}
      />
      <Modal open={showModal && isLoading} onDismiss={onClose}>
        <Stack column align="stretch" spacing="none">
          <Text color="neutral-textLow">{t('progress1')}</Text>
          <Text color="neutral-textLow">{t('progress2')}</Text>
        </Stack>
      </Modal>
    </>
  );
}
