import { useDismissibleComponent } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTransactionFeeToPay } from '../../pages/TransactionFeesPage/hooks';
import { getMonthName } from '../../pages/utils';

const LOCAL_STORAGE_KEY = 'transactionFeesPaymentPopupSeenAt';
const POPUP_SEEN_EXPIRATION_IN_DAYS = 15;

function useTransactionFeesPaymentAlert(initialFetch = false) {
  const { transactionFeeToPay, isSuccess, shouldShowPaymentButton, onPay } =
    useTransactionFeeToPay(initialFetch);
  const lang = useGetLanguage();

  const deadlineDay =
    transactionFeeToPay?.deadlineDate &&
    new Date(transactionFeeToPay.deadlineDate).getUTCDate();
  const deadlineMonth =
    transactionFeeToPay?.deadlineDate &&
    getMonthName(transactionFeeToPay.deadlineDate, lang, false);

  const { shouldShow: popupNotDismissed, dismiss: dismissModal } =
    useDismissibleComponent(LOCAL_STORAGE_KEY, POPUP_SEEN_EXPIRATION_IN_DAYS);

  const shouldShowModal =
    isSuccess &&
    shouldShowPaymentButton &&
    transactionFeeToPay &&
    transactionFeeToPay.isVeryCloseToDeadline &&
    transactionFeeToPay.owesMoreThanBlockingAmount &&
    popupNotDismissed;

  return {
    transactionFeeToPay,
    isSuccess,
    shouldShowPaymentButton,
    deadlineDay,
    deadlineMonth,
    dismissModal,
    onPay,
    shouldShowModal,
    popupDismissed: !popupNotDismissed,
  };
}

export default useTransactionFeesPaymentAlert;
