import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetNotificationResponseDto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import paymentNotificationService from '../paymentNotificationService';

export interface InterfaceNotificationSchema {
  status: StatusType;
  data: GetNotificationResponseDto[];
}

const initialState: InterfaceNotificationSchema = {
  status: 'idle',
  data: [],
};

export const fetchNotifications = createAsyncThunk(
  'paymentNotification/fetchNotifications',
  paymentNotificationService.getNotifications,
);

export const deleteNotification = createAsyncThunk<void, string>(
  'paymentNotification/deleteNotification',
  async (notificationId) => {
    await paymentNotificationService.deleteNotification(notificationId);
  },
);

const notificationSlice = createSlice({
  name: 'paymentNotification',
  initialState,
  reducers: {
    cleanLoadStatusAction(state) {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload as GetNotificationResponseDto[];
      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(deleteNotification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = state.data.filter(
          (notification) => notification.id !== action.meta.arg,
        );
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const { reducer } = notificationSlice;

export const { cleanLoadStatusAction } = notificationSlice.actions;
