import { useState } from 'react';

function useStepper(initialStep = 1) {
  const [activeStep, setActiveStep] = useState(initialStep);

  function setStep(step: number) {
    setActiveStep(step);
  }

  return {
    activeStep,
    setStep,
  };
}

export default useStepper;
