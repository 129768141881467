import { useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { InventoryByVariant, InventoryByVariantSkeleton } from './components';
import { useInventoryAndLocations } from './hooks';

function EditInventoryPage(): JSX.Element {
  const t = useTranslationCatalog();
  const { goBack } = useNavegate();
  const {
    productId,
    variantId,
    isLoading,
    locations,
    fetchInventoryByVariant,
  } = useInventoryAndLocations();

  useEffect(fetchInventoryByVariant, [fetchInventoryByVariant]);

  const headerNavigation = { onClick: goBack };

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={
        <HeaderContent
          title={t('products.variants.editInventory')}
          documentTitle={t('products.editProductTitle')}
        />
      }
    >
      {isLoading && <InventoryByVariantSkeleton />}

      {!isLoading && (
        <InventoryByVariant
          productId={productId}
          locations={locations}
          variantId={variantId}
        />
      )}
    </IonPageStratus>
  );
}

export default EditInventoryPage;
