import { useGetCategoriesMetafields } from 'domains/Metafields/hooks';
import RenderMetafieldsWhen from '../../RenderMetafieldWhen';

type ConditionsInterface = {
  hasInternals: boolean;
  hasExternals: boolean;
  isAvailable: boolean;
};

interface RenderVariantMetafieldsWhenProps {
  conditions: ConditionsInterface[];
  children: React.ReactNode;
}

export default function RenderCustomerMetafieldWhen({
  conditions,
  children,
}: Readonly<RenderVariantMetafieldsWhenProps>) {
  const { isAvailable, hasInternals, hasExternals } =
    useGetCategoriesMetafields();
  const values = { isAvailable, hasInternals, hasExternals };

  return (
    <RenderMetafieldsWhen values={values} conditions={conditions}>
      {children}
    </RenderMetafieldsWhen>
  );
}
