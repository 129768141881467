import { Box, Text } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CELL_BOX_PROPS } from '../SeoRedirectsItem';

function SeoRedirectListHeader() {
  const t = useTranslationConfigurations('seoRedirects.seoRedirectsListCard');

  return (
    <Box {...CELL_BOX_PROPS}>
      <Text fontWeight="bold">{t('oldUrl')}</Text>
      <Text fontWeight="bold">{t('newUrl')}</Text>
    </Box>
  );
}

export default SeoRedirectListHeader;
