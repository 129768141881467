import { useEffect, useState } from 'react';
import { Box, Icon, Spinner } from '@nimbus-ds/components';
import { ExclamationCircleIcon, CheckCircleIcon } from '@nimbus-ds/icons';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import { useMetafield } from 'domains/Orders/Orders/hooks';
import MetafieldInput from '../MetafieldInput';

import './MetafieldActionItem.scss';

const INTERVAL_RESET = 2000;

export const mapStatusAppearence = {
  error: 'danger',
  success: 'success',
  loading: 'neutral',
};

export const mapStatusIcon = {
  error: (
    <Icon
      color="danger-interactive"
      source={<ExclamationCircleIcon size="small" />}
    />
  ),
  success: (
    <Icon
      color="success-interactive"
      source={<CheckCircleIcon size="small" />}
    />
  ),
  loading: <Spinner color="neutral-textLow" size="small" />,
};

interface MetaFieldActionItemProps {
  metafieldId: string;
  orderId: string;
}

function MetafieldActionItem({
  metafieldId,
  orderId,
}: MetaFieldActionItemProps) {
  const t = useTranslationsMetafields();

  const { metafield, updateMetafield, statusUpdate, clearUpdateStatus } =
    useMetafield(metafieldId, orderId);

  const [value, setValue] = useState<string>(metafield?.value || '');

  useEffect(() => {
    if (statusUpdate === 'success' || statusUpdate === 'error') {
      const timer = setTimeout(() => {
        clearUpdateStatus(metafieldId);
        setValue(metafield?.value || '');
      }, INTERVAL_RESET);
      return () => clearTimeout(timer);
    }
  }, [clearUpdateStatus, metafield?.value, metafieldId, statusUpdate]);

  if (metafield === null) {
    return null;
  }

  const defaultLoadingText =
    metafield.type === 'select' && value === '' ? t('select.one') : value;

  const handleChange = (val: string) => {
    setValue(val);
  };

  const handleUpdate = (val) => {
    if (val !== metafield?.value) {
      updateMetafield(val);
    }
  };

  const handleClickBeforeInterval = () => {
    if (statusUpdate !== 'loading') {
      clearUpdateStatus(metafieldId);
    }
    if (statusUpdate === 'error') {
      setValue(metafield?.value || '');
    }
  };

  const showDescription = statusUpdate !== 'error' && metafield.description;
  const appearance = mapStatusAppearence[statusUpdate];

  return (
    <Box padding="4">
      <MetafieldInput
        metafield={metafield}
        showLoadingInput={statusUpdate !== 'idle'}
        showDescription={!!showDescription}
        showError={statusUpdate === 'error'}
        showInput={statusUpdate === 'idle'}
        itemTypeGenericProps={{
          value,
          onChange: handleChange,
          onUpdate: handleUpdate,
          statusUpdate,
        }}
        itemTypeLoadingProps={{
          appearance,
          append: mapStatusIcon[statusUpdate],
          disabled: statusUpdate === 'loading',
          value: defaultLoadingText || value,
          onClick: handleClickBeforeInterval,
        }}
      />
    </Box>
  );
}

export default MetafieldActionItem;
