const commonForm = {
  id: 'form-checkout',
  cardNumber: {
    id: 'input_cardNumber',
  },
  cardholderName: {
    id: 'input_cardholderName',
  },
  expirationMonth: {
    id: 'input_expirationMonth',
  },
  expirationYear: {
    id: 'input_expirationYear',
  },
  securityCode: {
    id: 'input_securityCode',
  },
  cardholderEmail: {
    id: 'input_cardholderEmail',
  },
  installments: {
    id: 'select_installments',
  },
  issuer: {
    id: 'select_issuer',
  },
};

const formWithIdentification = {
  ...commonForm,
  identificationType: {
    id: 'select_identificationType',
  },
  identificationNumber: {
    id: 'input_identificationNumber',
  },
};

export const formConfigMercadoPago = (country: string) =>
  country === 'MX' ? commonForm : formWithIdentification;
