import { Checkbox } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, InterfaceNameChecked } from '@tiendanube/components';
import { Stack } from 'commons/components';

interface FreeShippingAndShowInStoreProps {
  freeShipping: boolean;
  published: boolean;
  onChange: ({ name, value }: { name: string; value: boolean }) => void;
  isDigital: boolean;
}

function FreeShippingAndShowInStore({
  freeShipping,
  published,
  onChange,
  isDigital,
}: FreeShippingAndShowInStoreProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const handleOnChange = ({ name, checked }: InterfaceNameChecked) => {
    onChange({ name, value: checked });
  };

  return (
    <Card title={t('products.detail.moreOptions')}>
      <Stack column align="flex-start">
        {!isDigital && (
          <Checkbox
            label={t('products.detail.freeShipping')}
            name="freeShipping"
            checked={freeShipping}
            onChange={(event) => handleOnChange(event.target)}
          />
        )}
        <Checkbox
          label={t('products.detail.showInStore')}
          checked={published}
          name="published"
          onChange={(event) => handleOnChange(event.target)}
        />
      </Stack>
    </Card>
  );
}

export default FreeShippingAndShowInStore;
