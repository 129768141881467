import { useSelector } from 'react-redux';
import { Country } from '@tiendanube/common/src/enums';
import { getCountry } from '../../authSlice/authSelectors';

function useGetCountry(): Country {
  const country = useSelector(getCountry);

  return country;
}

export default useGetCountry;
