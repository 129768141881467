import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getRankProvincesByShippingTime = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.rankProvincesByShippingTime,
);

export const getRankProvincesByShippingTimeStatus = createSimpleSelector(
  [getRankProvincesByShippingTime],
  (rankProvincesByShippingTime) =>
    convertStatusTypeToObject(rankProvincesByShippingTime.status),
);

export const getRankProvincesByShippingTimeData = createSimpleSelector(
  [getRankProvincesByShippingTime],
  (rankProvincesByShippingTime) => rankProvincesByShippingTime.data,
);
