import { Table, Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { InternalNavLink } from 'commons/components';
import {
  Actions,
  DiscountType,
  ShowMoreScope,
  StatusTagItem,
} from 'domains/Marketing/Promotions/components';

interface PromotionsListDesktopRowProps {
  promotion: PromotionsItemListResponseDto;
}

export function PromotionsListDesktopRow({
  promotion: {
    id,
    name,
    discountType,
    discountTypeInfo,
    scope,
    scopeType,
    scopeVisibility,
    validity,
    status,
  },
}: Readonly<PromotionsListDesktopRowProps>) {
  return (
    <Table.Row>
      <Table.Cell>
        <Text lineClamp={1} fontWeight="medium">
          <InternalNavLink to={`/promotions/edit/${id}`}>
            <Text color="primary-interactive" fontWeight="medium">
              {name}
            </Text>
          </InternalNavLink>
        </Text>
      </Table.Cell>
      <Table.Cell>
        <DiscountType
          discountType={discountType}
          discountTypeInfo={discountTypeInfo}
        />
      </Table.Cell>
      <Table.Cell>
        <ShowMoreScope
          scope={scope}
          scopeType={scopeType}
          scopeVisibility={scopeVisibility}
        />
      </Table.Cell>
      <Table.Cell>
        <Text>{validity}</Text>
      </Table.Cell>
      <Table.Cell>
        <StatusTagItem status={status} />
      </Table.Cell>
      <Table.Cell>
        <Actions id={id} status={status} />
      </Table.Cell>
    </Table.Row>
  );
}
