import { Textarea } from '@nimbus-ds/components';
import { CardWithTitle } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

export function Skeleton() {
  const t = useTranslationConfigurations('externalCodes.facebook.fbPixel');
  return (
    <CardWithTitle title={t('title')}>
      <SplitSection.Skeleton>
        <Textarea.Skeleton />
      </SplitSection.Skeleton>
    </CardWithTitle>
  );
}
