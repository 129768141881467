import { useTranslation } from 'react-i18next';
import { PlansListResponseDto } from '@tiendanube/common';
import { Grid, Stack, Title } from '@tiendanube/components';
import { formatCurrency } from 'commons/utils';
import { useTranslationBilling } from 'domains/Billing';
import { ComparisonTableMobileRow } from './components';

interface ComparisonTableMobileProps {
  features: string[];
  firstPlan: PlansListResponseDto;
  secondPlan: PlansListResponseDto;
}

function ComparisonTableMobile({
  features,
  firstPlan,
  secondPlan,
}: ComparisonTableMobileProps): JSX.Element {
  const t = useTranslationBilling();
  const {
    i18n: { language },
  } = useTranslation();

  const formatPrice = (value: number, currency: string): string =>
    formatCurrency(value, currency, language, true);

  return (
    <Stack column align="stretch">
      <Stack.Item>
        <Title type="h3">{`${t(
          'billingPlansPage.comparisonTable.features',
        )}`}</Title>
      </Stack.Item>
      <Stack.Item>
        <Grid spacing="none" fluid>
          <ComparisonTableMobileRow
            feature={t('billingPlansPage.comparisonTable.monthlyCost')}
            firstValue={formatPrice(
              firstPlan.price.value,
              firstPlan.price.currency,
            )}
            secondValue={formatPrice(
              secondPlan.price.value,
              secondPlan.price.currency,
            )}
            bold
          />
          {features.map((feature, index) => (
            <ComparisonTableMobileRow
              key={index}
              feature={feature}
              firstValue={firstPlan.features[index]}
              secondValue={secondPlan.features[index]}
            />
          ))}
        </Grid>
      </Stack.Item>
    </Stack>
  );
}

export default ComparisonTableMobile;
