import { DataTable, Stack, Text } from '@tiendanube/components';
import { InvoiceHistoryDataTable } from './components';
import TaxCondition from '../TaxCondition';

function InvoiceHistoryWeb(): JSX.Element {
  return (
    <div className="invoice-history-web">
      <Stack column spacing="loose" align="stretch">
        <TaxCondition.Skeleton />
        <Stack column spacing="base" align="flex-start">
          <Text.Skeleton />
          <DataTable>
            <InvoiceHistoryDataTable.Skeleton />
          </DataTable>
        </Stack>
      </Stack>
    </div>
  );
}
export default InvoiceHistoryWeb;
