import { useState } from 'react';
import { OrderFulfillRequestDto } from '@tiendanube/common';
import {
  Checkbox,
  Input,
  InterfaceNameChecked,
  InterfaceNameValue,
  Modal,
} from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface ConfirmTrackingNumModalProps {
  shippingTrackingNum?: string;
  onClose: () => void;
  onConfirm: (fulfill: OrderFulfillRequestDto) => void;
}

function ConfirmTrackingNumModal({
  shippingTrackingNum,
  onClose,
  onConfirm,
}: ConfirmTrackingNumModalProps): JSX.Element {
  const t = useTranslationOrders();
  const { ref } = useAutoFocus();
  const [notifyFulfillment, setNotifyFulfillment] =
    useState<OrderFulfillRequestDto>({
      fulfillments: [],
      shippingTrackingNum,
      sendNotification: true,
    });

  const handleChange = ({
    name,
    value,
    checked,
  }: InterfaceNameValue & InterfaceNameChecked) =>
    setNotifyFulfillment((state) => ({ ...state, [name]: value ?? checked }));

  const handleClickFulfill = () => onConfirm(notifyFulfillment);

  return (
    <Modal
      title={t('quickActions.modal.title')}
      show
      onDismiss={onClose}
      primaryAction={{
        children: `${t('quickActions.modal.action')}`,
        onClick: handleClickFulfill,
        appearance: 'primary',
      }}
      secondaryAction={{
        children: `${t('quickActions.modal.close')}`,
        onClick: onClose,
      }}
    >
      <Stack innerRef={ref} column align="stretch">
        <Input
          value={notifyFulfillment.shippingTrackingNum}
          label={t('quickActions.modal.trankingNumber')}
          type="text"
          name="shippingTrackingNum"
          onChange={handleChange as () => InterfaceNameValue}
        />
        <Checkbox
          label={t('quickActions.modal.sendNotification')}
          name="sendNotification"
          onChange={handleChange as () => InterfaceNameChecked}
          checked={notifyFulfillment.sendNotification}
        />
      </Stack>
    </Modal>
  );
}

export default ConfirmTrackingNumModal;
