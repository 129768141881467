import {
  Alert,
  BaseCard,
  InterfaceNameValue,
  RadioButtonGroup,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import useStorage from 'commons/hooks/useStorage';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import RoundPricesOption from '../RoundPricesOption';
import { ActionEnum, UpdateByEnum } from '../types';
import VariationInput from '../VariationInput';
import VariationTypeList from '../VariationTypeList';

interface EditOriginalPriceProps {
  priceVariation: string;
  priceVariationAction: ActionEnum;
  priceVariationType: UpdateByEnum;
  showAlertDiscount: boolean;
  roundPrices: boolean;
  error: string;
  handleChangeRoundPrices: (round: boolean) => void;
  handleChange: (event: InterfaceNameValue) => void;
}

function EditOriginalPrice({
  priceVariation,
  priceVariationAction,
  priceVariationType,
  showAlertDiscount,
  roundPrices,
  error,
  handleChangeRoundPrices,
  handleChange,
}: EditOriginalPriceProps): JSX.Element {
  const t = useTranslationCatalog();

  const options = [
    {
      label: t('products.editPrice.increase'),
      value: ActionEnum.INCREASE,
    },
    {
      label: t('products.editPrice.decrease'),
      value: ActionEnum.DECREASE,
    },
  ];

  const [dismissedDiscountAlert, setDismissedDiscountAlert] =
    useStorage<boolean>(`show_price_edit_alert_discount`, false);

  const handlePriceVariationTypeChange = (type: UpdateByEnum) => {
    handleChange({ name: 'priceVariationType', value: type });
  };

  const handleDiscountAlertDismiss = () => setDismissedDiscountAlert(true);

  const showRoundPrices = priceVariationType === UpdateByEnum.PERCENTAGE;

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t('products.editPrice.originalPrice')}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="base">
          <Stack.Item>
            <RadioButtonGroup
              name="priceVariationAction"
              options={options}
              value={priceVariationAction}
              onChange={handleChange}
            />
          </Stack.Item>
          {!dismissedDiscountAlert && (
            <Alert
              show={showAlertDiscount}
              onDismiss={handleDiscountAlertDismiss}
            >
              {t('products.editPrice.adviceDiscount')}
            </Alert>
          )}
          <Stack.Item>
            <VariationTypeList
              variationType={priceVariationType}
              onChange={handlePriceVariationTypeChange}
              index={0}
            />
          </Stack.Item>
          <Stack.Item>
            <VariationInput
              name="priceVariation"
              variation={priceVariation}
              variationType={priceVariationType}
              onChange={handleChange}
              error={error}
            />
          </Stack.Item>
          {showRoundPrices && (
            <Stack.Item>
              <RoundPricesOption
                roundPrices={roundPrices}
                onChange={handleChangeRoundPrices}
                name="roundPricesCheckbox"
              />
            </Stack.Item>
          )}
          {showAlertDiscount && (
            <Stack.Item>
              <Text size="caption" appearance="light" background>
                {t('products.editPrice.alertDiscount')}
              </Text>
            </Stack.Item>
          )}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default EditOriginalPrice;
