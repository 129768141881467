import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';
import SendWhatsAppLink from 'commons/components/SendWhatsAppLink';
import { useModal } from 'commons/hooks';
import { SendEmailModal } from 'domains/Customers/components';
import { CustomerAnonymizeAlert } from 'domains/Customers/Customers/pages/CustomerDetailsPage/components';
import { useFormatIdentification } from 'domains/Orders/utils/useFormatIdentification';
import { CustomerLink, EmailType } from './components';

interface Whatsapp {
  phoneNumber: string;
  message: string;
  url?: string;
  signature?: string;
}

interface CustomerDataCardProps {
  id?: string;
  name: string;
  email: EmailType;
  whatsapp?: Whatsapp;
  identification?: string;
  hideNotInformedEmail?: boolean;
  businessName?: string;
  country?: string;
  tradeName?: string;
  fiscalRegime?: string;
  invoiceUse?: string;
  stateRegistration?: string;
  businessActivity?: string;
  isAnonymized?: boolean;
}

function CustomerDataCard({
  id,
  name,
  email,
  whatsapp,
  identification,
  country,
  hideNotInformedEmail,
  businessName,
  tradeName,
  fiscalRegime,
  invoiceUse,
  stateRegistration,
  businessActivity,
  isAnonymized,
}: CustomerDataCardProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  const identificationFormatted = useFormatIdentification({
    identification: identification || '',
    country: country || '',
  });

  return (
    <>
      <Card title={t('customerDataCard.title')}>
        <CustomerAnonymizeAlert
          isAnonymized={!!isAnonymized}
          startedAnonymized={false}
          onCancelAnonymize={() => null}
        />
        {!isAnonymized && (
          <Stack column spacing="tight" align="flex-start">
            <Stack.Item>
              <Stack column spacing="none" align="flex-start">
                <CustomerLink
                  id={id}
                  name={name}
                  email={email}
                  hideNotInformedEmail={hideNotInformedEmail}
                  openSendEmailModal={openSendEmailModal}
                />

                {!!whatsapp?.phoneNumber && (
                  <SendWhatsAppLink
                    phoneNumber={whatsapp.phoneNumber}
                    textChild={whatsapp.phoneNumber}
                    textWhatsapp={whatsapp.message}
                    url={whatsapp.url}
                    signature={whatsapp.signature}
                  />
                )}
              </Stack>
            </Stack.Item>
            {!!identification && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {businessName ? (
                    <>{t('customerDataCard.identificationWithFacture')}</>
                  ) : (
                    <>{t('customerDataCard.identification')}</>
                  )}
                </Text>
                <Text>{identificationFormatted}</Text>
              </Stack.Item>
            )}
            {!!businessName && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.businessName')}
                </Text>
                <Text>{businessName}</Text>
              </Stack.Item>
            )}
            {!!tradeName && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.tradeName')}
                </Text>
                <Text>{tradeName}</Text>
              </Stack.Item>
            )}
            {!!stateRegistration && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.stateRegistration')}
                </Text>
                <Text>{stateRegistration}</Text>
              </Stack.Item>
            )}
            {!!businessActivity && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.businessActivity')}
                </Text>
                <Text>{businessActivity}</Text>
              </Stack.Item>
            )}
            {!!fiscalRegime && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.fiscalRegime')}
                </Text>
                <Text>
                  {t(`customerDataCard.fiscalRegimeCode${fiscalRegime}`)}
                </Text>
              </Stack.Item>
            )}
            {!!invoiceUse && (
              <Stack.Item>
                <Text size="caption" appearance="light" block>
                  {t('customerDataCard.invoiceUse')}
                </Text>
                <Text>{t(`customerDataCard.invoiceUseCode${invoiceUse}`)}</Text>
              </Stack.Item>
            )}
          </Stack>
        )}
      </Card>
      {showSendEmailModal && (
        <SendEmailModal
          email={email.to}
          customerId={String(id)}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default CustomerDataCard;
