import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export const getRoutesToWatchState = (state: RootStateType) =>
  state.router.routesToWatch;

export const getRoutesToWatchStatus = createSimpleSelector(
  [getRoutesToWatchState],
  ({ status }) => status,
);

export const getRoutesToWatchData = createSimpleSelector(
  [getRoutesToWatchState],
  ({ data }) => data,
);

export const getCurrentPageState = (state: RootStateType) =>
  state.router.currentPage;
