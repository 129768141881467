import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getCustomers = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.customers,
);

export const getCustomersData = createSimpleSelector(
  [getCustomers],
  (customers) => customers.data,
);

export const getCustomersStatus = createSimpleSelector(
  [getCustomers],
  (clients) => convertStatusTypeToObject(clients.status),
);
