import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';
import { CopyLink } from 'commons/components';

interface PaymentLinkCardProps {
  checkoutUrl: string;
}

function PaymentLinkCard({ checkoutUrl }: PaymentLinkCardProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('draftOrders.paymentLinkCard.title')}>
      <Stack column align="stretch" spacing="tight">
        <Text>{t('draftOrders.paymentLinkCard.text')}</Text>
        <CopyLink
          value={checkoutUrl}
          message={t('draftOrders.paymentLinkCard.copyLinkMessage')}
          text={t('draftOrders.paymentLinkCard.copyLinkText')}
        />
      </Stack>
    </Card>
  );
}

export default PaymentLinkCard;
