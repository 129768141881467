import * as Yup from 'yup';
import { validateZipCode } from 'domains/Orders/DraftOrders/draftOrdersService';
import { emailValidation } from './utils';

export const validationSchema = (initialEmail: string) =>
  Yup.object().shape({
    name: Yup.string().required('customerForm.customerData.errors.name'),
    email: Yup.string()
      .required('customerForm.customerData.errors.email.required')
      .email('customerForm.customerData.errors.email.invalid')
      .test(
        'email-is-unique',
        'customerForm.customerData.errors.email.unique',
        (value) => (value ? emailValidation(value, initialEmail) : true),
      ),
    identification: Yup.string().test(
      'length',
      'draftOrders.newDraftOrder.clientData.errors.identification',
      (value) => {
        if (!value) return true;
        return value.length >= 7;
      },
    ),
    phone: Yup.string(),
    street: Yup.string(),
    number: Yup.string(),
    floor: Yup.string(),
    locality: Yup.string(),
    city: Yup.string(),
    zipcode: Yup.string().test(
      'zipcode-is-valid',
      'customerForm.deliveryInformation.error',
      async (value) => {
        if (!value) return true;
        try {
          const response = await validateZipCode(value);
          return response;
        } catch (e) {
          return false;
        }
      },
    ),
    province: Yup.string(),
    country: Yup.string(),
  });
