import { useTranslation } from 'react-i18next';
import { Stack, Button, InterfaceButton } from '@tiendanube/components';
import Skeleton from './Skeleton';

export interface CancelAndSaveButtonsProps
  extends Omit<
    InterfaceButton,
    'children' | 'spinner' | 'disabled' | 'appearance'
  > {
  onCancel: () => void;
  onSave: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  cancelText?: string;
  saveText?: string;
  cancelIsDisabled?: boolean;
}

function CancelAndSaveButtons({
  onCancel,
  onSave,
  isLoading,
  isDisabled = false,
  cancelText,
  saveText,
  cancelIsDisabled = false,
  ...buttonSaveProps
}: CancelAndSaveButtonsProps): JSX.Element {
  const { t } = useTranslation('common');

  const cancelButtonText = cancelText || t('cancelAndSaveButtons.cancel');
  const saveButtonText = saveText || t('cancelAndSaveButtons.save');

  return (
    <Stack spacing="base" justify="flex-end">
      <Stack.Item>
        <Button disabled={cancelIsDisabled} onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button
          spinner={isLoading}
          disabled={isDisabled || isLoading}
          onClick={onSave}
          appearance="primary"
          {...buttonSaveProps}
        >
          {saveButtonText}
        </Button>
      </Stack.Item>
    </Stack>
  );
}

CancelAndSaveButtons.Skeleton = Skeleton;

export default CancelAndSaveButtons;
