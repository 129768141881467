import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getFacebookConnectionStatusAction,
  getFacebookConnectionData,
  cleanFacebookConnectionDataAction,
} from 'domains/Dashboard/slices/socialMessagesSlice';

const ACCESS_TOKEN_ERROR = 3;

function useFacebookConnectionStatusAlert() {
  const dispatch = useAppDispatch();
  const data = useSelector(getFacebookConnectionData);

  const getFacebookConnectionStatus = useCallback(() => {
    dispatch(getFacebookConnectionStatusAction());
  }, [dispatch]);

  const cleanFacebookConnectionData = () => {
    dispatch(cleanFacebookConnectionDataAction());
  };

  const showAlert = data?.status === ACCESS_TOKEN_ERROR;

  return {
    showAlert,
    getFacebookConnectionStatus,
    cleanFacebookConnectionData,
  };
}

export default useFacebookConnectionStatusAlert;
