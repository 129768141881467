import { EmptySearch } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface EmptySearchOrErrorResultProps {
  hasZeroProducts?: boolean;
  isError?: boolean;
  isDigital?: boolean;
}

function EmptySearchOrErrorResult({
  hasZeroProducts,
  isError,
  isDigital,
}: Readonly<EmptySearchOrErrorResultProps>) {
  const t = useTranslationOrders();

  if (isError) {
    return <EmptySearch title={t('productSearcher.internalServerError')} />;
  }

  const productTypeKey = isDigital ? 'digital' : 'physical';

  return (
    <EmptySearch
      title={t(
        hasZeroProducts
          ? 'productSearcher.emptyProductsResultTitle'
          : 'productSearcher.emptySearchResultTitle',
      )}
      text={t(
        hasZeroProducts
          ? 'productSearcher.emptyProductsResultDescription'
          : `productSearcher.emptySearchResultDescription.${productTypeKey}`,
      )}
    />
  );
}

export default EmptySearchOrErrorResult;
