import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootStateType } from 'App/store';
import {
  fetchPaymentsHistory as fetchPaymentsHistoryAction,
  getPaymentsHistoryStatus,
  fetchPaymentsHistorySelector,
  paymentHistoryForYearSelector,
} from 'domains/Billing/TransactionFees/TransactionFeesSlice';

function usePaymentsHistory(year?: string, initialFetch = false) {
  const dispatch = useAppDispatch();
  const status = useSelector(getPaymentsHistoryStatus);
  const paymentsHistory = useSelector(fetchPaymentsHistorySelector);

  const fetchPaymentsHistory = useCallback(() => {
    paymentsHistory || dispatch(fetchPaymentsHistoryAction());
  }, [dispatch, paymentsHistory]);

  const splitPayments = useSelector((state: RootStateType) =>
    paymentHistoryForYearSelector(state, true, year),
  );
  const noSplitPayments = useSelector((state: RootStateType) =>
    paymentHistoryForYearSelector(state, false, year),
  );

  useEffect(() => {
    initialFetch && fetchPaymentsHistory();
  }, [fetchPaymentsHistory, initialFetch]);

  return {
    status,
    fetchPaymentsHistory,
    paymentsHistory,
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
    splitPayments,
    noSplitPayments,
  };
}

export default usePaymentsHistory;
