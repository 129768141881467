import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

const createGenericAsyncActionCase = <T, K, L>(
  builder: ActionReducerMapBuilder<K>,
  action: AsyncThunk<T, L, object>,
  initialState: K | null,
  name: string,
) => {
  builder
    .addCase(action.pending, (state) => {
      if (initialState) {
        state[name].data = initialState[name].data;
      }
      state[name].status = 'loading';
    })
    .addCase(action.fulfilled, (state, { payload }) => {
      state[name].data = payload;
      state[name].status = 'success';
    })
    .addCase(action.rejected, (state) => {
      if (initialState) {
        state[name].data = initialState[name].data;
      }
      state[name].status = 'error';
    });
};

export default createGenericAsyncActionCase;
