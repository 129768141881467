import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { JobsSchema } from './types';
import jobsServices from '../jobsServices/jobsServices';

const initialState: JobsSchema = {
  backgroundJobs: {},
  cloneProduct: {},
  csvDownloadLink: {
    status: 'idle',
    url: '',
  },
};

export const getJobsAction = createAsyncThunk(
  'jobs/getJobsAction',
  jobsServices.getJobsStorage,
);

export const setJobAction = createAsyncThunk(
  'jobs/setJobAction',
  jobsServices.setJobStorage,
);

export const removeJobAction = createAsyncThunk(
  'jobs/removeJobAction',
  jobsServices.removeJobStorage,
);

export const checkJobStatusAction = createAsyncThunk(
  'jobs/checkJobStatusAction',
  jobsServices.checkJobStatus,
);

export const setJobStatusAction = createAsyncThunk(
  'jobs/setJobStatusAction',
  jobsServices.setJobStatus,
);

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    cleanJobCsvDownload(state) {
      state.csvDownloadLink.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobsAction.fulfilled, (state, action) => {
      const type = action.meta.arg;
      state[type] = action.payload;
    });

    builder.addCase(setJobAction.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      state[type] = action.payload;
    });

    builder.addCase(removeJobAction.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      state[type] = action.payload;
    });

    builder.addCase(checkJobStatusAction.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      state[type] = action.payload;
    });

    builder.addCase(setJobStatusAction.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      state[type] = action.payload;
    });
  },
});

export const { reducer } = jobsSlice;

export const { cleanJobCsvDownload } = jobsSlice.actions;
