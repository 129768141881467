import { useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { Text, InterfaceText } from '@tiendanube/components';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';

interface CurrencyPriceProps extends Omit<InterfaceText, 'children'> {
  price: number;
  currency?: CurrencyType;
  preFix?: string;
  withoutDecimal?: boolean;
}

function CurrencyPrice({
  price,
  currency,
  preFix,
  withoutDecimal,
  ...rest
}: CurrencyPriceProps): JSX.Element {
  const { code } = useGetCurrency();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Text {...rest}>
      {!!preFix && `${preFix} `}
      {formatCurrency(
        price,
        (currency as string) || code,
        language,
        withoutDecimal,
      )}
    </Text>
  );
}

export default CurrencyPrice;
