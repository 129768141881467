import { Box } from '@nimbus-ds/components';
import { useParams } from 'react-router-dom';
import { OwnerResourceType } from '@tiendanube/common';
import useGetIsUserFullRole from 'domains/Auth/hooks/useGetIsUserFullRole';
import { trackingProductVariantMetafieldSave } from 'domains/Catalog/Products/tracking';
import {
  CardMetafieldsList,
  EmptyCard,
  LinkToMetafields,
} from 'domains/Metafields/components';
import EmptyOnCard from 'domains/Metafields/components/EmptyCard/EmptyCard';
import FilterUpsellOnAlertCard from 'domains/Metafields/components/FilterUpsellAlertCard';
import LinkToMetafieldsBasedOnUser from 'domains/Metafields/components/LinkToMetafieldsBasedOnUser';
import MetafieldsVariantsDataList from 'domains/Metafields/components/MetafieldsVariantsDataList/MetafieldsVariantsDataList';
import { RenderProductsAndVariantsMetafieldWhen } from 'domains/Metafields/components/RenderMetafieldWhen';
import UpsellOnAlertCard from 'domains/Metafields/components/UpsellAlertCard';
import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import { SourceType } from 'domains/Metafields/types';
import { HandleChangeInterface } from '../../hooks/useProductForm';
import {
  MetafieldSelectedInterface,
  MetafieldSelectedsOrErrorType,
  OnChangeToAllMetafieldsVariants,
} from '../Variants/types';

export interface SectionProductsAndVariantsMetafieldsProps {
  resource?: OwnerResourceType;
  selecteds: {
    product: MetafieldSelectedsOrErrorType;
    product_variant: MetafieldSelectedsOrErrorType;
  };
  apiSelecteds: {
    product: MetafieldSelectedsOrErrorType;
    product_variant: MetafieldSelectedsOrErrorType;
  };
  onVariantChange: ({ name, value }: HandleChangeInterface) => void;
  onProductChange?: ({ name, value }: HandleChangeInterface) => void;
  onChangeToAllMetafieldsVariants?: OnChangeToAllMetafieldsVariants;
  showError: boolean;
  showSuccess: boolean;
  showLoading: boolean;
}

function SectionProductsAndVariantsMetafields({
  resource,
  selecteds,
  apiSelecteds,
  onVariantChange,
  onProductChange,
  onChangeToAllMetafieldsVariants,
  showError,
  showLoading,
  showSuccess,
}: Readonly<SectionProductsAndVariantsMetafieldsProps>) {
  const { metafields, apiMetafields, hasInternals, status, fetchMetafields } =
    useGetProductsAndVariantsMetafields(resource);
  const { id: productId } = useParams<{ id: string }>();
  const isFullUser = useGetIsUserFullRole();

  const allSelecteds = [...selecteds.product, ...selecteds.product_variant];
  const allApiSelecteds = [
    ...apiSelecteds.product,
    ...apiSelecteds.product_variant,
  ];

  const handleChange = (
    id: string,
    value: string | null,
    source: SourceType,
    resource: OwnerResourceType = 'product_variant',
  ) => {
    trackingProductVariantMetafieldSave(id, productId, value);

    const selectedsByResource = selecteds[resource];
    const apiSelectedsByResource = apiSelecteds[resource];

    const initialValue = {
      internals: selectedsByResource,
      fromApi: apiSelectedsByResource,
    };
    const isAdmin = source === SourceType.ADMIN;

    const selectedArray =
      selectedsByResource !== 'error' ? selectedsByResource : [];
    const apiSelectedArray =
      apiSelectedsByResource !== 'error' ? apiSelectedsByResource : [];
    const attr = isAdmin ? 'internals' : 'fromApi';

    const rest = isAdmin
      ? selectedArray.filter((current) => current.id !== id)
      : apiSelectedArray.filter((current) => current.id !== id);

    initialValue[attr] = value !== null ? [...rest, { id, value }] : rest;

    if (resource === 'product') {
      onProductChange?.({
        name: 'productMetafields',
        value: initialValue,
      });
    } else {
      onVariantChange({
        name: 'metafields',
        value: initialValue,
      });
    }
  };

  const isSelectedsError =
    selecteds.product === 'error' ||
    selecteds.product_variant === 'error' ||
    apiSelecteds.product === 'error' ||
    apiSelecteds.product_variant === 'error';

  const apiMetafieldsList = apiMetafields || [];
  const metafieldsList = metafields || [];

  return (
    <>
      {(showError || (status.isSuccess && isSelectedsError)) && (
        <CardMetafieldsList.ErrorState onRetry={fetchMetafields} />
      )}
      {showLoading && <CardMetafieldsList.Skeleton />}

      {showSuccess && (
        <>
          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: false,
                isFilterAvailable: false,
              },
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: false,
                isFilterAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <UpsellOnAlertCard
                    hasInternals={hasInternals}
                    owner="variant"
                  />
                }
                apiMetafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            ) : (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            )}
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: true,
                isFilterAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={<EmptyOnCard />}
                apiMetafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            ) : (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            )}
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: true,
                hasExternals: false,
                isAvailable: true,
                isFilterAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={
                  <>
                    <FilterUpsellOnAlertCard hasInternals={hasInternals} />
                    <MetafieldsVariantsDataList
                      source={SourceType.ADMIN}
                      metafields={metafieldsList}
                      selectedMetafields={
                        allSelecteds as MetafieldSelectedInterface[]
                      }
                      onChange={handleChange}
                      onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                    />
                    <LinkToMetafields />
                  </>
                }
                metafieldsTotal={metafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <>
                    <MetafieldsVariantsDataList
                      source={SourceType.ADMIN}
                      metafields={metafieldsList}
                      selectedMetafields={
                        allSelecteds as MetafieldSelectedInterface[]
                      }
                      onChange={handleChange}
                      onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                    />
                    <Box marginTop="4" />
                  </>
                }
                metafieldsTotal={metafieldsList.length}
              />
            )}
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: true,
                isFilterAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={
                  <>
                    <FilterUpsellOnAlertCard hasInternals={hasInternals} />
                    <MetafieldsVariantsDataList
                      source={SourceType.ADMIN}
                      metafields={metafieldsList}
                      selectedMetafields={
                        allSelecteds as MetafieldSelectedInterface[]
                      }
                      onChange={handleChange}
                      onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                    />
                    <LinkToMetafields hasPaddingBottom />
                  </>
                }
                metafieldsTotal={
                  metafieldsList.length + apiMetafieldsList.length
                }
                apiMetafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <>
                    <MetafieldsVariantsDataList
                      source={SourceType.ADMIN}
                      metafields={metafieldsList}
                      selectedMetafields={
                        allSelecteds as MetafieldSelectedInterface[]
                      }
                      onChange={handleChange}
                      onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                    />
                    <Box marginTop="4" />
                  </>
                }
                metafieldsTotal={
                  metafieldsList.length + apiMetafieldsList.length
                }
                apiMetafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
              />
            )}
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: true,
                hasExternals: false,
                isAvailable: true,
                isFilterAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsVariantsDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      allSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                  <LinkToMetafieldsBasedOnUser isFullUser={isFullUser} />
                </>
              }
              metafieldsTotal={metafieldsList.length}
            />
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: true,
                isFilterAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsVariantsDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      allSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                  <LinkToMetafieldsBasedOnUser
                    isFullUser={isFullUser}
                    hasPaddingBottom
                  />
                </>
              }
              apiMetafieldSlot={
                <MetafieldsVariantsDataList
                  source={SourceType.APP}
                  metafields={apiMetafieldsList}
                  selectedMetafields={
                    allApiSelecteds as MetafieldSelectedInterface[]
                  }
                  onChange={handleChange}
                  onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                />
              }
              metafieldsTotal={apiMetafieldsList.length + metafieldsList.length}
            />
          </RenderProductsAndVariantsMetafieldWhen>

          <RenderProductsAndVariantsMetafieldWhen
            resource={resource}
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: true,
                isFilterAvailable: true,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={<EmptyCard />}
                apiMetafieldSlot={
                  <MetafieldsVariantsDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      allApiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <>
                    <MetafieldsVariantsDataList
                      source={SourceType.APP}
                      metafields={apiMetafieldsList}
                      selectedMetafields={
                        allApiSelecteds as MetafieldSelectedInterface[]
                      }
                      onChange={handleChange}
                      onApplytoAllVariants={onChangeToAllMetafieldsVariants}
                    />
                    <Box marginTop="4" />
                  </>
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderProductsAndVariantsMetafieldWhen>
        </>
      )}
    </>
  );
}

export default SectionProductsAndVariantsMetafields;
