import {
  ProductSectionResponseDto,
  ProductSectionsCodeType,
} from '@tiendanube/common';
import {
  Link,
  Stack,
  Chip,
  InterfaceNameChecked,
} from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface SelectedSectionsProps {
  onOpen: () => void;
  sectionsSelected: ProductSectionsCodeType[];
  sections: ProductSectionResponseDto[];
  onChange: (event: InterfaceNameChecked) => void;
}

function SelectedSections({
  onOpen,
  sectionsSelected,
  sections,
  onChange,
}: SelectedSectionsProps): JSX.Element {
  const t = useTranslationCatalog();

  const handleChange = (name: string) => onChange({ checked: false, name });

  return (
    <Stack column align="stretch">
      <Stack>
        {sections
          .filter((section) => sectionsSelected.includes(section.code))
          .map((section, index) => (
            <Chip
              key={index}
              id={section.code}
              label={section.name}
              onDismiss={handleChange}
            />
          ))}
      </Stack>
      <Link
        icon={EditIcon}
        iconPosition="start"
        appearance="primary"
        onClick={onOpen}
      >
        {t('products.detail.highlight.editSelected')}
      </Link>
    </Stack>
  );
}

export default SelectedSections;
