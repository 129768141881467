import { InteractiveList } from '@nimbus-ds/patterns';
import { VariantListItem } from './VariantListItem';
import { ProductInterface, SelectedProductInterface } from '../../../types';
import './VariantList.scss';

interface VariantListProps<Minimalistic extends boolean> {
  variants: ProductInterface<false>[];
  selectedVariants: SelectedProductInterface[];
  minimalistic?: boolean;
  selectedVariantsLimitReached?: boolean;
  onChange: (
    variants: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => void;
}

function VariantList<Minimalisitic extends boolean>({
  variants,
  onChange,
  selectedVariants,
  minimalistic,
  selectedVariantsLimitReached = false,
}: Readonly<VariantListProps<Minimalisitic>>) {
  const getVariantName = (variant: ProductInterface<false>) =>
    Object.values(variant.variantValues).join(', ');

  return (
    <div className="stratus--variant-list-container">
      <InteractiveList>
        {variants
          .filter((variant) => !!variant.variantValues)
          .map((variant) => (
            <div
              key={variant.variantId}
              className="stratus--variant-list-item-container"
            >
              <VariantListItem
                variant={variant}
                selectedVariant={selectedVariants.find(
                  (sv) => sv.variantId === variant.variantId,
                )}
                name={getVariantName(variant)}
                minimalistic={minimalistic}
                selectedVariantsLimitReached={selectedVariantsLimitReached}
                onChange={onChange}
              />
            </div>
          ))}
      </InteractiveList>
    </div>
  );
}

export default VariantList;
