import { Box, Table } from '@nimbus-ds/components';
import { ErrorScreen, PaginationStratus } from 'commons/components';
import { useGetRolesQuery } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  UsersListDesktopHeader,
  UsersListDesktopRow,
  UsersListDesktopSkeleton,
} from './components';
import './UsersListDesktop.scss';
import { useUsersListDesktop } from '../../hooks';

function UsersListDesktop() {
  const t = useTranslationConfigurations('usersListPage');
  const {
    pagination,
    changePage,
    showSkeleton: isUsersLoading,
    data: users,
    isError: isUsersError,
    refetch,
  } = useUsersListDesktop();
  const {
    data: storeRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch: rolesRefetch,
  } = useGetRolesQuery();

  const showSkeleton = isUsersLoading || isRolesLoading;
  const showErrorScreen = isUsersError || isRolesError || !users || !storeRoles;
  const allRoles = { ...storeRoles?.roles, ...storeRoles?.appRoles };

  const refetchAll = () => {
    refetch();
    rolesRefetch();
  };

  if (showSkeleton) return <UsersListDesktopSkeleton />;
  if (showErrorScreen) return <ErrorScreen onRetry={refetchAll} />;

  return (
    <Box flexDirection="column" gap="2" display="flex">
      <div className="stratus--users-list-page-table">
        <Table>
          <UsersListDesktopHeader />
          <Table.Body>
            {users.map((user) => (
              <UsersListDesktopRow
                key={`users-list-item-${user.id}`}
                user={user}
                storeRoles={allRoles}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
      <PaginationStratus
        currentPage={pagination.currentPage}
        totalItems={pagination.totalResults}
        itemName={t('users')}
        page={pagination.currentPage}
        perPage={pagination.perPage}
        onPageSelect={changePage}
      />
    </Box>
  );
}

export default UsersListDesktop;
