import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CategoriesMetafieldsInterface } from '../types';

export const getCategoriesMetafieldsState = (
  state: RootStateType,
): CombinedState<CategoriesMetafieldsInterface> =>
  state.catalog.categoriesMetafields;

export const getCategoriesMetafieldsData = createSimpleSelector(
  [getCategoriesMetafieldsState],
  (state) => state.data,
);

export const getCategoriesMetafieldsStatus = createSimpleSelector(
  [getCategoriesMetafieldsState],
  (state) => convertStatusTypeToObject(state.status),
);
