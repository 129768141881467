export function isValidNumber(value: string, decimals?: number): boolean {
  let test;
  if (decimals) {
    const reg = new RegExp(`\\d*(\\.){0,1}\\d{0,${decimals}}`);
    test = value.match(reg);
  } else {
    test = value.match(/\d*(\.){0,1}\d*/);
  }

  return (test && test[0] === test?.input) || false;
}

export function isValidIntegerNumber(value: string): boolean {
  const test = value.match(/\d*/);
  return (test && test[0] === test?.input) || false;
}
