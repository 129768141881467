import { WhatsappResponseDto, WhatsappRequestDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { WHATSAPP_BASE_URL, WhatsappEndpoints, WhatsappTags } from './config';

export const WhatsappApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [WhatsappTags.GetWhatsapp],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [WhatsappEndpoints.getWhatsapp]: builder.query<WhatsappResponseDto, void>(
        {
          query: () => ({
            method: 'GET',
            url: WHATSAPP_BASE_URL,
          }),
          providesTags: [WhatsappTags.GetWhatsapp],
        },
      ),
      [WhatsappEndpoints.updateWhatsapp]: builder.mutation<
        void,
        WhatsappRequestDto
      >({
        query: (data) => ({
          method: 'PATCH',
          url: WHATSAPP_BASE_URL,
          data,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [WhatsappTags.GetWhatsapp],
      }),
    }),
  });

export const { useGetWhatsappQuery, useUpdateWhatsappMutation } = WhatsappApi;
