import { Route, Switch } from 'react-router-dom';
import { Responsive } from 'commons/components';
import { FREE_SHIPPING_ROUTES } from './freeShippingRoutes';
import {
  FreeShippingListDesktopPage,
  FreeShippingListMobilePage,
  NewFreeShippingPage,
  EditFreeShippingPage,
} from './pages';

function FreeShipping() {
  return (
    <Switch>
      <Route path={FREE_SHIPPING_ROUTES.freeShippingList} exact>
        <Responsive
          mobile={<FreeShippingListMobilePage />}
          desktop={<FreeShippingListDesktopPage />}
        />
      </Route>
      <Route path={FREE_SHIPPING_ROUTES.newFreeShipping} exact>
        <NewFreeShippingPage />
      </Route>
      <Route path={FREE_SHIPPING_ROUTES.editFreeShipping} exact>
        <EditFreeShippingPage />
      </Route>
    </Switch>
  );
}

export default FreeShipping;
