import { Box, Card, Link, Text, Thumbnail, Title } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingEnaClickNewOnboarding202411 } from 'domains/Dashboard/tracking';
import ImageSrc from './enp-image.png';

const videoLink = 'https://www.youtube.com/watch?v=bbZUJ4GEQzc';

function FirstSaleCourseCard() {
  const t = useTranslationDashboard('firstSale.e-commerce');

  return (
    <ShowByCountry allowedCountries={[Country.MX]}>
      <Card>
        <Card.Body>
          <Box
            display="flex"
            flexDirection={{
              lg: 'row',
              md: 'row',
              xs: 'column',
            }}
            gap="4"
          >
            <Box
              minWidth={{
                md: '215px',
              }}
              maxWidth={{
                lg: '237px',
              }}
            >
              <Thumbnail aspectRatio="16/9" alt="image" src={ImageSrc} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              justifyContent="center"
              maxWidth="703px"
            >
              <Box display="flex" flexDirection="column" gap="2">
                <Title as="h3">{t('title')}</Title>
                <Text>{t('description')}</Text>
              </Box>
              <Link
                textDecoration="none"
                appearance="primary"
                as="a"
                target="_blank"
                href={videoLink}
                onClick={trackingEnaClickNewOnboarding202411('primera-venta')}
              >
                {t('link')}
              </Link>
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </ShowByCountry>
  );
}

export default FirstSaleCourseCard;
