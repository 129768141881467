import ModalConfirmationAction from 'commons/components/ModalConfirmationAction';
import useBulkActions from '../../useBulkActions';

export interface BulkActionsWithConfirmProps {
  bulkActions: Record<
    string,
    {
      title: string;
      body: string;
      btnCancel: string;
      btnOk: string;
      textProgress: string;
      textSuccess: string;
      textError: string;
      appearanceOk: 'primary' | 'secondary' | 'danger' | 'default';
      handleAction: () => Promise<void>;
    }
  >;
}

function BulkActionsWithConfirm({
  bulkActions,
}: BulkActionsWithConfirmProps): JSX.Element | null {
  const {
    selectedAction,
    clearBulkActions,
    showModal,
    closeModal,
    toggleEditMode,
  } = useBulkActions();

  const mustRender =
    showModal &&
    selectedAction &&
    Object.keys(bulkActions).includes(selectedAction);

  if (!mustRender) return null;

  const {
    title,
    body,
    btnCancel,
    btnOk,
    appearanceOk,
    textProgress,
    textSuccess,
    textError,
    handleAction,
  } = bulkActions[selectedAction];

  const handlePrimaryButton = async () => {
    await handleAction();
    toggleEditMode();
    clearBulkActions();
  };

  return (
    <ModalConfirmationAction
      title={title}
      body={body}
      btnOk={btnOk}
      btnCancel={btnCancel}
      textProgress={textProgress}
      textSuccess={textSuccess}
      textError={textError}
      appearance={appearanceOk}
      onAction={handlePrimaryButton}
      onClose={closeModal}
    />
  );
}

export default BulkActionsWithConfirm;
