import { combineReducers } from 'redux';
import { reducer as devModeReducer } from 'App/settings/devMode';
import { reducer as jobsReducer } from 'App/settings/jobs';
import { reducer as menuReducer } from 'App/settings/menu';
import { reducer as routingLog } from 'App/settings/routingLog';
import { reducer as accountReducer } from 'domains/Account';
import { reducer as authReducer } from 'domains/Auth';
import { reducer as authenticationFactorReducer } from 'domains/AuthenticationFactor';
import { reducer as billingReducer } from 'domains/Billing';
import { reducer as catalogReducer } from 'domains/Catalog';
import { reducer as customersReducer } from 'domains/Customers';
import { reducer as dashboardReducer } from 'domains/Dashboard';
import { reducer as fulfillmentOrdersReducer } from 'domains/FulfillmentOrders';
import { reducer as marketingReducer } from 'domains/Marketing';
import { reducer as metafieldsReducer } from 'domains/Metafields';
import { reducer as onlineReducer } from 'domains/Online';
import { reducer as ordersReducer } from 'domains/Orders';
import { reducer as partnersAppsReducer } from 'domains/PartnersApps';
import { reducer as paymentsReducer } from 'domains/Payments';
import { reducer as salesChannelsReducer } from 'domains/SalesChannels';
import { reducer as shippingReducer } from 'domains/Shipping';
import { reducer as statisticsReducer } from 'domains/Statistics';
import { cirrusApi } from './rtk/cirrusApi';

const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  catalog: catalogReducer,
  statistics: statisticsReducer,
  customers: customersReducer,
  marketing: marketingReducer,
  apps: partnersAppsReducer,
  shipping: shippingReducer,
  online: onlineReducer,
  billing: billingReducer,
  payments: paymentsReducer,
  account: accountReducer,
  authenticationFactor: authenticationFactorReducer,
  metafields: metafieldsReducer,
  dashboard: dashboardReducer,
  menu: menuReducer,
  jobs: jobsReducer,
  router: routingLog,
  fulfillmentOrders: fulfillmentOrdersReducer,
  salesChannels: salesChannelsReducer,
  [cirrusApi.reducerPath]: cirrusApi.reducer,
  devMode: devModeReducer,
});

export default rootReducer;
