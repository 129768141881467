import {
  ReactNode,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

const noop = () => null;

export interface InterfaceTopAdBarContext {
  showTopAdBar: () => void;
  hideTopAdBar: () => void;
  isShowTopAdBar: boolean;
}

interface TopAdBarProviderProps {
  children: ReactNode;
}

export const TopAdBarContext = createContext<InterfaceTopAdBarContext>({
  showTopAdBar: noop,
  hideTopAdBar: noop,
  isShowTopAdBar: true,
});

function TopAdBarProvider({ children }: Readonly<TopAdBarProviderProps>) {
  const [isShowTopAdBar, setIsShowTopAdBar] = useState(true);

  const showTopAdBar = useCallback(() => setIsShowTopAdBar(true), []);
  const hideTopAdBar = useCallback(() => setIsShowTopAdBar(false), []);

  const value = useMemo(
    () => ({
      isShowTopAdBar,
      showTopAdBar,
      hideTopAdBar,
    }),
    [isShowTopAdBar, showTopAdBar, hideTopAdBar],
  );

  return (
    <TopAdBarContext.Provider value={value}>
      {children}
    </TopAdBarContext.Provider>
  );
}

export default TopAdBarProvider;
