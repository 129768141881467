import { ReactNode, createContext, useEffect, useMemo } from 'react';
import { UPSELL_FLOW_AB_TEST_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useDismissibleComponent } from 'commons/hooks';
import { PAYMENT_SOFTBLOCK } from 'config/upsellFlowSources';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useAdminDisabled,
  useGetIsFreePlan,
  useGetStoreRemainingDaysToExpiration,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { trackingBillingSoftBlockModalShow } from 'domains/Billing/tracking';

const noop = () => null;

export interface UpsellSoftBlockContext {
  upsellSoftBlock: boolean;
  dismiss: () => void;
}

export interface InterfaceUpsellSoftBlockContextProvider {
  children: ReactNode;
}

export const UpsellSoftBlockContext = createContext<UpsellSoftBlockContext>({
  upsellSoftBlock: false,
  dismiss: noop,
});

function UpsellSoftBlockContextProvider({
  children,
}: Readonly<InterfaceUpsellSoftBlockContextProvider>): JSX.Element {
  const isAdminDisabled = useAdminDisabled();
  const [hasUpsellAbTestTag] = useHasTags(UPSELL_FLOW_AB_TEST_TAG);
  const isMobileDevice = useIsMobileDevice();
  const remainingDaysToExpiration = useGetStoreRemainingDaysToExpiration();
  const { validateRoles } = useRoles();
  const { isFreePlan, isTrial } = useGetIsFreePlan();
  const { shouldShow, dismiss } = useDismissibleComponent(
    'upsell-soft-block',
    1,
  );

  const isExpired =
    remainingDaysToExpiration !== undefined && remainingDaysToExpiration <= 0;
  const isAdminEnabled = !isAdminDisabled;
  const isFullRole = validateRoles('full');

  const upsellSoftBlock =
    isTrial &&
    !isFreePlan &&
    hasUpsellAbTestTag &&
    isFullRole &&
    isExpired &&
    isAdminEnabled &&
    shouldShow;

  const values = useMemo(
    () => ({
      upsellSoftBlock,
      dismiss,
    }),
    [dismiss, upsellSoftBlock],
  );

  useEffect(() => {
    if (upsellSoftBlock && !isMobileDevice) {
      trackingBillingSoftBlockModalShow({
        featureKey: PAYMENT_SOFTBLOCK,
      });
    }
  }, [isMobileDevice, upsellSoftBlock]);

  return (
    <UpsellSoftBlockContext.Provider value={values}>
      {children}
    </UpsellSoftBlockContext.Provider>
  );
}

export default UpsellSoftBlockContextProvider;
