import { useEffect, useRef, useState } from 'react';
import { Link, Text } from '@tiendanube/components';
import { DuplicateIcon } from '@tiendanube/icons';
import { trackingApplyToAllVariants } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

import './ApplyValuesToAllVariants.scss';

interface ApplyValuesToAllVariantsProps {
  value?: string;
  onChange: () => void;
  valueWasManuallyChanged?: boolean;
}

function ApplyValuesToAllVariants({
  value,
  onChange,
  valueWasManuallyChanged = true,
}: ApplyValuesToAllVariantsProps) {
  const t = useTranslationCatalog();
  const changedValue = useRef(value);
  const [show, setShow] = useState(false);

  const handleClick = () => {
    trackingApplyToAllVariants();
    onChange();
    setShow(false);
  };

  useEffect(() => {
    const isVisible = valueWasManuallyChanged && changedValue.current !== value;
    let timeOut;
    if (isVisible) {
      setShow(true);
      timeOut = setTimeout(() => {
        setShow(false);
      }, 5000);
    }
    return () => clearTimeout(timeOut);
  }, [value, valueWasManuallyChanged]);

  if (!show) return null;

  return (
    <div className="stratus--apply-to-all-variants">
      <Link appearance="primary" onClick={handleClick}>
        <Text size="caption" appearance="primary">
          <DuplicateIcon /> {t('products.variants.applyValuesToAll')}
        </Text>
      </Link>
    </div>
  );
}

export default ApplyValuesToAllVariants;
