import { Route, RouteProps } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';

interface RouteByCountryProps extends RouteProps {
  allowedCountries: Country[];
}

function RouteByCountry({
  allowedCountries,
  children,
  ...routeProps
}: RouteByCountryProps) {
  const country = useGetCountry();
  if (!allowedCountries.includes(country)) return null;
  return <Route {...routeProps}>{children}</Route>;
}

export default RouteByCountry;
