import { Thumbnail, Text, Spinner, Box } from '@nimbus-ds/components';
import classNames from 'classnames';
import { ExclamationTriangleIcon } from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import './ImageThumbnail.scss';

interface ImageThumbnailProps {
  url: string;
  isLoading: boolean;
  isError: boolean;
  onRetry: () => void;
}
function ImageThumbnail({
  url,
  isLoading,
  isError,
  onRetry,
}: ImageThumbnailProps) {
  const t = useTranslationCatalog();
  const { isMobile } = useResponsive();

  const actions = isError || isLoading;

  const styleThumbnailWithActions = classNames({
    'nimbus--thumbnail-overlay': actions,
    'nimbus--thumbnail-overlay--white': actions,
  });

  const styleContentThumbnailWithAction = classNames({
    'stratus--image-thumbnail--content': actions,
  });

  const withInMobile = isMobile ? '100%' : '311px';

  return (
    <>
      <div className={styleContentThumbnailWithAction}>
        <Thumbnail src={url} width={withInMobile} aspectRatio="2/1" alt="image">
          <div className={styleThumbnailWithActions} onClick={onRetry}>
            {isLoading && <Spinner size="large" />}
            {isError && (
              <span className="nimbus--thumbnail-error">
                <ExclamationTriangleIcon size="large" />
              </span>
            )}
          </div>
        </Thumbnail>
      </div>
      {actions && (
        <Box display="inline-flex" gap="2">
          {isLoading && (
            <>
              <Spinner size="small" color="currentColor" />
              <Text>{t('categories.detail.featuredImage.loadingText')}</Text>
            </>
          )}
          {isError && (
            <Text color="danger-interactive">
              {t('categories.detail.featuredImage.errorText')}
            </Text>
          )}
        </Box>
      )}
    </>
  );
}

export default ImageThumbnail;
