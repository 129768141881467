import * as Yup from 'yup';

export const uploadTaxDataWithDniSchema = Yup.object().shape({
  dni: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'invoicesInfoPage.errors.missingDni')
    .min(8, 'invoicesInfoPage.errors.missingDni')
    .max(8, 'invoicesInfoPage.errors.missingDni'),
  businessName: Yup.string().required('invoicesInfoPage.errors.businessName'),
  address: Yup.string().required('invoicesInfoPage.errors.address'),
});

export const uploadTaxDataWithCuitSchema = Yup.object().shape({
  cuit: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'invoicesInfoPage.errors.missingCuit')
    .min(11, 'invoicesInfoPage.errors.missingCuit')
    .max(11, 'invoicesInfoPage.errors.missingCuit'),
});
