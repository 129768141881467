import { ReactNode } from 'react';
import { Title, Text } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';

interface TagAndSeoItemTitleProps {
  children: ReactNode;
  title: string;
  description?: string;
}

function TagAndSeoItemTitle({
  children,
  title,
  description,
}: TagAndSeoItemTitleProps): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item fill>
        <Title as="h3">{title}</Title>
      </Stack.Item>
      {!!description && (
        <Stack.Item fill>
          <Text>{description}</Text>
        </Stack.Item>
      )}
      <Stack.Item fill>{children}</Stack.Item>
    </Stack>
  );
}

export default TagAndSeoItemTitle;
