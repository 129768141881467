import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { useActionMessages } from './useActionMessages';
import {
  useBulkActions,
  BulkActionsWithConfirm,
  BulkActionsWithoutConfirm,
} from '../BulkActionsProvider';

function BulkActionsMessages(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const { selectedRowsId } = useBulkActions();
  const {
    deleteMessages,
    spamMessages,
    refreshMessages,
    readMessages,
    unreadMessages,
  } = useActionMessages();

  const bulkActionsWithConfirm = {
    delete: {
      title: t('messages.modalBulkDelete.title'),
      body: t('messages.modalBulkDelete.body'),
      btnCancel: t('messages.modalBulkDelete.cancel'),
      btnOk: t('messages.modalBulkDelete.ok'),
      textProgress: t('messages.modalBulkDelete.progress'),
      textSuccess: t('messages.modalBulkDelete.success'),
      textError: t('messages.modalBulkDelete.error'),
      appearanceOk: 'danger' as const,
      handleAction: async () => {
        await deleteMessages(selectedRowsId);
        refreshMessages();
      },
    },
    spam: {
      title: t('messages.modalBulkSpam.title'),
      body: t('messages.modalBulkSpam.body'),
      btnCancel: t('messages.modalBulkSpam.cancel'),
      btnOk: t('messages.modalBulkSpam.ok'),
      textProgress: t('messages.modalBulkSpam.progress'),
      textSuccess: t('messages.modalBulkSpam.success'),
      textError: t('messages.modalBulkSpam.error'),
      appearanceOk: 'danger' as const,
      handleAction: async () => {
        await spamMessages(selectedRowsId);
        refreshMessages();
      },
    },
  };

  const bulkActionsWithoutConfirm = {
    read: {
      textProgress: t('messages.modalBulkRead.progress'),
      textSuccess: t('messages.modalBulkRead.success'),
      textError: t('messages.modalBulkRead.error'),
      handleAction: async () => {
        await readMessages(selectedRowsId);
        refreshMessages();
      },
    },
    unread: {
      textProgress: t('messages.modalBulkUnread.progress'),
      textSuccess: t('messages.modalBulkUnread.success'),
      textError: t('messages.modalBulkUnread.error'),
      handleAction: async () => {
        await unreadMessages(selectedRowsId);
        refreshMessages();
      },
    },
  };

  return (
    <>
      <BulkActionsWithConfirm bulkActions={bulkActionsWithConfirm} />
      <BulkActionsWithoutConfirm bulkActions={bulkActionsWithoutConfirm} />
    </>
  );
}

export default BulkActionsMessages;
