import { ChangeEventHandler } from 'react';
import { Checkbox, Text } from '@nimbus-ds/components';
import ModalAside from 'App/components/lab/ModalAside/ModalAside';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface PrivacyPolicyModalProps {
  isShow: boolean;
  checked: boolean;
  onAccept: () => void;
  onClose: () => void;
  onChange: (checked: boolean) => void;
}

export function PrivacyPolicyModal({
  checked,
  isShow,
  onAccept,
  onClose,
  onChange,
}: Readonly<PrivacyPolicyModalProps>) {
  const t = useTranslationOnline('contentPages.privacyPolicy');
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    onChange(e.target.checked);

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Text>{t('text')}</Text>
      <Checkbox
        onChange={handleChange}
        name="acceptPrivaryPolicy"
        label={t('checkLabel')}
        checked={checked}
      />
      <CancelAndConfirmButtons
        confirmText={t('cta')}
        isConfirmDisabled={!checked}
        onConfirm={onAccept}
        onCancel={onClose}
      />
    </ModalAside>
  );
}
