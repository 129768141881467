import { logEvent } from 'commons/utils/tracking';
import {
  NUVEM_ENVIO_CLICK,
  NUVEM_ENVIO_VIEW,
  ORDER_BUTTON_PRINT_DOCUMENT,
} from 'config/trackingEvents';
import { DocumentType } from '../constants';

export const trackPrintNuvemEnvioLabel = (orderIds: string) => {
  logEvent(NUVEM_ENVIO_VIEW, {
    orderId: orderIds,
    action: DocumentType.NUVEM_ENVIO_LABEL,
    page: 'order',
  });
};

export const trackPrintNuvemEnvioLabelFromBanner = (orderIds: string[]) => {
  logEvent(NUVEM_ENVIO_CLICK, {
    orderId: orderIds.join(','),
    action: 'printNuvemEnvioLabel',
    page: 'print document',
  });
};

export const trackingPrintDocument = (
  documentType: DocumentType,
  externalSaleId: string[],
) => {
  logEvent(ORDER_BUTTON_PRINT_DOCUMENT, {
    page: 'order',
    order: externalSaleId.join(', '),
    action: documentType,
  });
};
