import { Alert } from '@nimbus-ds/components';
import { ExternalLink } from 'commons/components';
import { HELP_LINKS_PROMOTIONS_FTP } from 'commons/constants';
import { useBoolean, useTranslationLanguage } from 'commons/hooks';
import { useHasFtp } from 'domains/Auth/hooks';
import useTranslationMarketing from 'domains/Marketing/useTranslationMarketing';

export function PromotionAlertOpenFTP() {
  const [show, , close] = useBoolean(useHasFtp());
  const language = useTranslationLanguage();

  const t = useTranslationMarketing('promotions.alertFtp');
  return (
    <Alert show={show} appearance="primary" onRemove={close}>
      {t('title')}
      <ExternalLink href={HELP_LINKS_PROMOTIONS_FTP[language]}>
        {t('link')}
      </ExternalLink>
    </Alert>
  );
}
