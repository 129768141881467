import { VirtualDataList } from 'commons/components';
import InvoiceHistoryDataTableRow from './components/InvoiceHistoryDataTableRow';
import InvoiceHistoryDataTableSkeleton from './InvoiceHistoryDataTableSkeleton';

interface InvoiceHistoryDataTableProps {
  data: string[];
  onRefresh: () => Promise<void>;
  fetchMore: () => void;
  shouldShowSpinner: boolean;
}

function InvoiceHistoryDataTable({
  data,
  onRefresh,
  fetchMore,
  shouldShowSpinner,
}: InvoiceHistoryDataTableProps): JSX.Element {
  return (
    <VirtualDataList
      data={data}
      itemContent={(index, id) => (
        <InvoiceHistoryDataTableRow key={index} id={id} />
      )}
      onRefresh={onRefresh}
      endReached={fetchMore}
      showFooter={shouldShowSpinner}
    />
  );
}

InvoiceHistoryDataTable.Skeleton = InvoiceHistoryDataTableSkeleton;

export default InvoiceHistoryDataTable;
