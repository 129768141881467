import { useCallback } from 'react';
import { Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { SubscriptionResponseDto } from '@tiendanube/common';
import { useToastStatus } from 'commons/hooks';
import { useGetSubscriptions } from 'domains/Billing/Checkout/hooks';
import { useCancelSubscription } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface CancelSubscriptionModalProps {
  onClose: () => void;
  subscription?: SubscriptionResponseDto;
}

function CancelSubscriptionModal({
  onClose,
  subscription,
}: CancelSubscriptionModalProps): JSX.Element {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps.cancelSubscriptionModal',
  );
  const handleCancel = () => {
    subscription && cancelSubscription(subscription.id);
  };

  const { status, cancelSubscription, resetStatus, isLoading } =
    useCancelSubscription();
  const { refreshSubscriptions } = useGetSubscriptions();

  const onSuccess = useCallback(() => {
    refreshSubscriptions();
    resetStatus();
    onClose();
  }, [refreshSubscriptions, resetStatus, onClose]);

  useToastStatus({
    status,
    error: t('cancelationError'),
    success: t('cancelationSuccess'),
    onSuccess,
    onError: resetStatus,
  });

  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header
        title={t('title', { name: subscription?.description ?? '' })}
      />
      <Modal.Body>
        <Text>{t('message')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={isLoading}>
          {t('abortButton')}
        </Button>
        <Button onClick={handleCancel} appearance="danger" disabled={isLoading}>
          {isLoading && <Spinner color="currentColor" size="small" />}
          {t('continueButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelSubscriptionModal;
