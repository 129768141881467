import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { EmptySearch } from '@tiendanube/components';

function StockHistoryEmty(): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return (
    <EmptySearch
      title={t('products.stockHistoryModal.empty.title')}
      text={t('products.stockHistoryModal.empty.text')}
    />
  );
}

export default StockHistoryEmty;
