import { Alert } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface BadColumnsAlertProps {
  badColumnsQuantity: number;
}

function BadColumnsAlert({ badColumnsQuantity }: BadColumnsAlertProps) {
  const t = useTranslationCatalog();

  return (
    <Alert
      appearance="warning"
      title={t('products.importExportPage.alterations.alterationsAlert.title', {
        count: badColumnsQuantity,
      })}
      show={badColumnsQuantity > 0}
    >
      {t('products.importExportPage.alterations.alterationsAlert.body', {
        count: badColumnsQuantity,
      })}
    </Alert>
  );
}

export default BadColumnsAlert;
