import { Tag, TagProperties, Text } from '@nimbus-ds/components';
import { StatusPromotionType } from '@tiendanube/common';
import { useTranslationMarketing } from 'domains/Marketing/hook';

const APPEARANCE_BY_STATUS: Record<
  StatusPromotionType,
  TagProperties['appearance']
> = {
  active: 'success',
  inactive: 'neutral',
  expired: 'warning',
} as const;

interface StatusTagItemProps {
  status: StatusPromotionType;
}

export function StatusTagItem({ status }: Readonly<StatusTagItemProps>) {
  const t = useTranslationMarketing('promotions.status');
  return (
    <Tag appearance={APPEARANCE_BY_STATUS[status]}>
      <Text>{t(status)}</Text>
    </Tag>
  );
}
