import { Alert, Text, Link } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

interface ExportOrdersAlertProps {
  exportingStatus: string;
  csvUrl: string;
  resetExportingStatus: () => void;
}

function ExportOrdersAlert({
  exportingStatus,
  csvUrl,
  resetExportingStatus,
}: ExportOrdersAlertProps) {
  const t = useTranslationOrders();

  const handleDismiss =
    exportingStatus === 'loading' ? undefined : resetExportingStatus;

  const title = t(`exportPage.alert.title.${exportingStatus}`);
  const description = t(`exportPage.alert.message.${exportingStatus}`);
  const appearanceAlert = {
    success: 'success',
    error: 'danger',
  };
  const appearance = appearanceAlert[exportingStatus] || 'primary';
  const show = exportingStatus !== 'idle';
  const downloadTextLink =
    exportingStatus === 'success' ? t('exportPage.alert.download') : undefined;

  return (
    <Alert
      title={title}
      appearance={appearance}
      show={show}
      onRemove={handleDismiss}
    >
      {exportingStatus !== 'error' && <Text as="p">{description}</Text>}
      {!!downloadTextLink && (
        <Link appearance="primary" as="a" href={csvUrl} onClick={handleDismiss}>
          {downloadTextLink}
        </Link>
      )}
    </Alert>
  );
}

export default ExportOrdersAlert;
