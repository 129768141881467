export const validateErrorCodesMercadoPago = (
  errors: {
    code: string;
    message: string;
  }[],
) => {
  //https://www.mercadopago.com.ar/developers/es/docs/checkout-api/response-handling/data-insertion-errors
  const initialValue = {};
  const errorEmpty = (key: string) => `form.${key}.errors.empty`;
  const errorInvalid = (key: string) => `form.${key}.errors.invalid`;

  const parseErrorMessage = (code: string) =>
    ({
      '205': { cardNumber: errorEmpty('cardNumber') },
      E301: { cardNumber: errorInvalid('cardNumber') },
      '221': { cardholderName: errorEmpty('cardholderName') },
      '208': { expirationDate: errorInvalid('expirationDate') },
      '325': { expirationDate: errorInvalid('expirationDate') },
      '209': { expirationDate: errorInvalid('expirationDate') },
      '326': { expirationDate: errorInvalid('expirationDate') },
      '400': { expirationDate: errorInvalid('expirationDate') },
      '214': { identificationNumber: errorEmpty('identificationNumber') },
      '324': { identificationNumber: errorInvalid('identificationNumber') },
      '224': { securityCode: errorEmpty('securityCode') },
      E302: { securityCode: errorInvalid('securityCode') },
    }[code] ?? {});

  return errors.reduce(
    (acc, { code }) => ({
      ...acc,
      ...parseErrorMessage(code),
    }),
    initialValue,
  );
};
