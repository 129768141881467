import { Box, Spinner, Text } from '@nimbus-ds/components';

interface UploadingMessageInterface {
  message: string;
}

function UploadingMessage({
  message,
}: UploadingMessageInterface): JSX.Element | null {
  if (!message) return null;
  return (
    <Box display="flex" flexWrap="wrap" gap="2">
      <Spinner size="small" />
      <Text>{message}</Text>
    </Box>
  );
}

export default UploadingMessage;
