import { Country } from '@tiendanube/common';
import {
  NUMBER_OF_DIGITOS_CNPJ,
  NUMBER_OF_DIGITOS_CPF,
} from 'domains/Orders/Orders/utils';
import { validateZipCode } from '../../draftOrdersService';

// validation for the CPF or CNPJ
const BLACKLIST_CPF = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
  '12345678909',
];

const BLACKLIST_CNPJ = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999',
];

const verifierDigitCPF = (digits) => {
  const numbers = digits.split('').map((number) => parseInt(number, 10));

  const modulus = numbers.length + 1;
  const multiplied = numbers.map((number, index) => number * (modulus - index));
  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
};

const verifierDigitCNPJ = (digits) => {
  let index = 2;
  const reverse = digits
    .split('')
    .reduce((buffer, number) => [parseInt(number, 10)].concat(buffer), []);
  const sum = reverse.reduce((buffer, number) => {
    buffer += number * index;
    index = index === 9 ? 2 : index + 1;
    return buffer;
  }, 0);

  const mod = sum % 11;
  return mod < 2 ? 0 : 11 - mod;
};

const strip = (number) => (number || '').replace(/[^\d]/g, '');

const isValidCPF = (number) => {
  const stripped = strip(number);

  // CPF can't be blacklisted
  if (BLACKLIST_CPF.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 9);
  numbers += verifierDigitCPF(numbers);
  numbers += verifierDigitCPF(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

const isValidCNPJ = (number) => {
  const stripped = strip(number);

  // CNPJ can't be blacklisted
  if (BLACKLIST_CNPJ.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigitCNPJ(numbers);
  numbers += verifierDigitCNPJ(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

export const validateIdentification = (value: string, country: Country) => {
  if (country === 'BR') {
    if (value.length === NUMBER_OF_DIGITOS_CPF) {
      return isValidCPF(value);
    } else if (value.length === NUMBER_OF_DIGITOS_CNPJ) {
      return isValidCNPJ(value);
    }
    return false;
  }

  return !value || value.length >= 7;
};

export const validateZipcode = async (
  value: string | undefined,
  country: Country,
) => {
  if (!value) return true;
  if (country !== 'CL') {
    try {
      const response = await validateZipCode(value);
      return response;
    } catch (e) {
      return false;
    }
  } else {
    // FIXME - Remove this when CEP service validation is ready for Chile
    const isMatch = /^[0-9]+$/.test(value);
    const isMinLengthValid = value.length >= 5;
    const isMaxLengthValid = value.length <= 7;
    return isMatch && isMinLengthValid && isMaxLengthValid;
  }
};

export const email = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const notAllowedEmoji = /\p{Emoji}/gu;
