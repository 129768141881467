import { ReactNode, useEffect, useRef, useState } from 'react';
import { BaseModal, Text, Title } from '@tiendanube/components';
import { CloseIcon } from '@tiendanube/icons';
import { getStorage, setStorage } from 'App/storage';
import StackInfoIcon from './StackInfoIcon';

import './InfoModal.css';

interface InterfaceInfoModal {
  text: string;
  title: string;
  storage: string;
  children: ReactNode;
  onDismiss?: () => void;
}

function InfoModal({
  text,
  title,
  storage,
  children,
  onDismiss,
}: InterfaceInfoModal): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const timeOutRef = useRef<number>(0);

  useEffect(() => {
    const fn = async () => {
      const wasTutorialShowed = await getStorage(storage);
      if (!wasTutorialShowed) {
        timeOutRef.current = window.setTimeout(() => {
          setShowModal(true);
          setStorage(storage, true);
        }, 500);
      }
    };

    fn();
    return () => {
      if (timeOutRef.current) {
        window.clearTimeout(timeOutRef.current);
      }
    };
  }, [setShowModal, storage]);

  const handleOnDismiss = () => {
    setShowModal(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <BaseModal show={showModal} onDismiss={handleOnDismiss} portal>
      <div className="InfoPageModalHero">
        <div className="InfoPageModalHeroCloseIcon" onClick={handleOnDismiss}>
          <CloseIcon />
        </div>
        <Text>{text}</Text>
        <Title type="h1">{title}</Title>
      </div>
      <div className="InfoPageModalContent">{children}</div>
    </BaseModal>
  );
}

InfoModal.StackInfoIcon = StackInfoIcon;

export default InfoModal;
