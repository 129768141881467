import { Alert } from '@nimbus-ds/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

type ProcessingInstallationAlertProps = {
  processingInstallation: boolean;
};

function ProcessingInstallationAlert({
  processingInstallation,
}: ProcessingInstallationAlertProps) {
  const t = useTranslationPayments();

  if (!processingInstallation) {
    return null;
  }

  return (
    <Alert appearance="warning">
      {t('paymentProvider.alertProcessingInstallation')}
    </Alert>
  );
}

export default ProcessingInstallationAlert;
