import { BaseCard, Stack, Title, Text } from '@tiendanube/components';
import { Toggle } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface FreeShipppingCardProps {
  allowFreeShipping: boolean;
  onChange: (data: InterfaceNameBooleanValue) => void;
}

function FreeShipppingCard({
  allowFreeShipping,
  onChange,
}: FreeShipppingCardProps): JSX.Element {
  const t = useTranslationShipping();

  const handleChange = () => {
    onChange({ name: 'allowFreeShipping', value: !allowFreeShipping });
  };

  const title = t('oca.config.freeShipping.title');
  const subtitle = t('oca.config.freeShipping.subtitle');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column align="stretch" spacing="tight">
          <Stack justify="space-between" spacing="none">
            <Title type="h3">{title}</Title>
            <Toggle
              name="toggleFreeShipppingCardCollapsible"
              onChange={handleChange}
              checked={allowFreeShipping}
            />
          </Stack>
          <Text>{subtitle}</Text>
        </Stack>
      </BaseCard.Header>
    </BaseCard>
  );
}

FreeShipppingCard.Skeleton = Skeleton;

export default FreeShipppingCard;
