import { useEffect, useState } from 'react';
import {
  Input,
  InterfaceInput,
  InterfaceNameValue,
} from '@tiendanube/components';

const formatCPF = (value) =>
  value
    .replace(/\D/g, '') // Elimina cualquier caracter que no sea número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    .slice(0, 14); // Limita la longitud del CPF

const formatCNPJ = (value) =>
  value
    .replace(/\D/g, '') // Elimina cualquier caracter que no sea número
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2')
    .slice(0, 18); // Limita la longitud del CNPJ

interface CnpjCpfInputProps {
  value: string;
  name: string;
  label: string;
  helpText: string;
  appearance: InterfaceInput['appearance'];
  onChange: (e: InterfaceNameValue) => void;
}

function CnpjCpfInput({
  value,
  onChange,
  name,
  label,
  helpText,
  appearance,
}: CnpjCpfInputProps) {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    let newValue = value;
    if (value.length > 11) {
      newValue = formatCNPJ(value);
    } else {
      newValue = formatCPF(value);
    }
    setFormattedValue(newValue);
  }, [value]);

  const handleChange = ({ value, name }: InterfaceNameValue) => {
    onChange({ value: value.replace(/\D/g, ''), name });
  };

  return (
    <Input
      type="text"
      name={name}
      label={label}
      value={formattedValue}
      onChange={handleChange}
      placeholder={value.length > 11 ? '00.000.000/0000-00' : '000.000.000-00'}
      helpText={helpText}
      appearance={appearance}
    />
  );
}

export default CnpjCpfInput;
