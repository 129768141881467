import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InventoryLevelByLocationResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { getInventoriesByLocation } from '../../locationSlice';
import {
  getInventoryLevelsByLocationStatus,
  getInventoryLevelsByLocationResults,
  getInventoryLevelsByLocationTotal,
} from '../../locationSlice/locationSelectors';

export interface UseInventoryLevelsByLocationInterface {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  inventoryLevels: InventoryLevelByLocationResponseDto[];
  total: number;
  fetchInventoryLevels: () => void;
}

function useInventoryLevelsByLocation(
  id: string,
): UseInventoryLevelsByLocationInterface {
  const dispatch = useAppDispatch();
  const inventoryLevels = useSelector(getInventoryLevelsByLocationResults);
  const total = useSelector(getInventoryLevelsByLocationTotal);
  const { isLoading, isError, isSuccess } = useSelector(
    getInventoryLevelsByLocationStatus,
  );

  const fetchInventoryLevels = useCallback(() => {
    dispatch(
      getInventoriesByLocation({
        locationId: id,
      }),
    );
  }, [id, dispatch]);

  return {
    isLoading,
    isError,
    isSuccess,
    inventoryLevels,
    total,
    fetchInventoryLevels,
  };
}

export default useInventoryLevelsByLocation;
