import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  getMetafieldById,
  getMetafieldUpdateStatusById,
  updateMetafield as updateMetafieldAction,
  clearUpdateStatus as clearUpdateStatusAction,
} from 'domains/Orders/OrderMetafields';

function useMetafield(metafieldId: string, orderId: string) {
  const dispatch = useAppDispatch();

  const statusUpdate = useSelector((state: RootStateType) =>
    getMetafieldUpdateStatusById(state, metafieldId),
  );

  const metafield = useSelector((state: RootStateType) =>
    getMetafieldById(state, metafieldId),
  );

  const updateMetafield = (value: string | null) => {
    dispatch(updateMetafieldAction({ orderId, metafieldId, value }));
  };

  const clearUpdateStatus = (metafieldId: string) => {
    dispatch(clearUpdateStatusAction({ metafieldId }));
  };

  return {
    metafield,
    statusUpdate,
    updateMetafield,
    clearUpdateStatus,
  };
}

export default useMetafield;
