import { useToastProgress } from 'App/components/ToastProgressContext';
import { useToast } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

export type LocationToastTypes = 'sort' | 'setAsMain' | 'delete';

interface UseLocationToastResult {
  onError: (type: LocationToastTypes) => void;
  onSuccess: (type: LocationToastTypes) => void;
  onLoading: (type: LocationToastTypes) => void;
}

function useLocationListToast(): UseLocationToastResult {
  const t = useTranslationShipping();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const onError = (type: LocationToastTypes) => {
    closeToastProgress();
    addToast({
      label: t(`locations.${type}.error`),
      appearance: 'danger',
    });
  };

  const onSuccess = (type: LocationToastTypes) => {
    closeToastProgress();
    addToast({
      label: t(`locations.${type}.success`),
      appearance: 'success',
    });
  };

  const onLoading = (type: LocationToastTypes) => {
    addToastProgress({
      label: t(`locations.${type}.loading`),
    });
  };

  return {
    onError,
    onSuccess,
    onLoading,
  };
}

export default useLocationListToast;
