import { Select, Text, Box } from '@nimbus-ds/components';

const SkeletonBlock: JSX.Element = (
  <>
    <Text.Skeleton width="15%" />
    <Select.Skeleton width="100%" />
  </>
);

function Skeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" gap="2-5">
      {SkeletonBlock}
      {SkeletonBlock}
      {SkeletonBlock}
    </Box>
  );
}

export default Skeleton;
