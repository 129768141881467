import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { getTokenById } from '../../partnersAppsSlice';

export interface GetToken {
  (id: string): Promise<string>;
}

function useGetToken(): GetToken {
  const dispatch = useAppDispatch();

  const getToken = async (appId: string) => {
    const result = await dispatch(getTokenById(appId));
    const { token } = unwrapResult(result);
    return token;
  };
  return getToken;
}

export default useGetToken;
