import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { cleanPaymentProvidersAction } from 'domains/Payments/PaymentProviders/paymentProvidersSlice';
import {
  getCustomPaymentDataSelector,
  getCustomPaymentStatusLoadSelector,
  getCustomPaymentStatusUpdateSelector,
  getCustomPaymentStatusDisableSelector,
} from '../../customPaymentService/customPaymentSelectors/customPaymentSelectors';
import {
  cleanDisabledStatusAction,
  cleanUpdateStatusAction,
  disableCustomPayment as disableCustomPaymentAction,
  getCustomPayment,
  updateCustomPayment as updateCustomPaymentAction,
} from '../../customPaymentSlice';

function useCustomPayment() {
  const dispatch = useAppDispatch();
  const customPayment = useSelector(getCustomPaymentDataSelector);
  const statusLoad = useSelector(getCustomPaymentStatusLoadSelector);
  const statusUpdate = useSelector(getCustomPaymentStatusUpdateSelector);
  const statusDisable = useSelector(getCustomPaymentStatusDisableSelector);

  const fetchCustomPayment = useCallback(() => {
    dispatch(getCustomPayment());
  }, [dispatch]);

  const updateCustomPayment = useCallback(
    async (payload) => {
      await dispatch(updateCustomPaymentAction(payload));
      dispatch(cleanUpdateStatusAction());
      dispatch(cleanPaymentProvidersAction());
    },
    [dispatch],
  );

  const disableCustomPayment = useCallback(
    async (payload) => {
      await dispatch(disableCustomPaymentAction(payload));
      dispatch(cleanDisabledStatusAction());
      dispatch(cleanPaymentProvidersAction());
    },
    [dispatch],
  );

  return {
    customPayment,
    statusLoad,
    statusUpdate,
    statusDisable,
    fetchCustomPayment,
    updateCustomPayment,
    disableCustomPayment,
  };
}

export default useCustomPayment;
