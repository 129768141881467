import { ChangeEvent, useEffect } from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NavigationMenuItemDataDto } from '@tiendanube/common';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useGetAllPages } from 'domains/Online/ContentPages/hooks';

interface SelectContentPageProps {
  selected?: string;
  onSelect: (data: NavigationMenuItemDataDto) => void;
}

function SelectContentPage({ onSelect, selected }: SelectContentPageProps) {
  const { pages, isLoading } = useGetAllPages();
  const language = useGetLanguage();

  const handleSelect = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    onSelect({ id: value });
  };

  useEffect(() => {
    if (pages.length > 0) {
      onSelect({ id: selected || pages[0].id });
    }
  }, [pages, onSelect, selected]);

  if (isLoading) return <Select.Skeleton width="100%" />;

  return pages.length > 0 ? (
    <FormField.Select
      id="selected-page"
      name="selected-page"
      onChange={handleSelect}
      defaultValue={selected}
      value={selected}
    >
      {pages.map(({ id, title }) => (
        <Select.Option
          key={id}
          label={title[language] ?? ''}
          value={id ?? '0'}
        />
      ))}
    </FormField.Select>
  ) : null;
}

export default SelectContentPage;
