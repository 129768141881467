import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';

function CustomerDataCardSkeleton(): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('customerDataCard.title')}>
      <Stack column spacing="tight" align="flex-start">
        <Stack.Item>
          <Stack column spacing="none" align="flex-start">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CustomerDataCardSkeleton;
