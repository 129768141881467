import { useEffect } from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { useRecentlyDisabled } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders.recentlyDisabledAlert';
function RecentlyDisabledAlert(): JSX.Element | null {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const { cleanRecentlyDisabled, recentlyDisabledPaymentProviderId: id } =
    useRecentlyDisabled();

  // Restrict show Alert to NuvemPago or Pago Nube
  const show = muvemPagoOrPagoNubeIds.includes(id || '');

  useEffect(
    () => () => {
      cleanRecentlyDisabled();
    },
    [cleanRecentlyDisabled],
  );

  if (!show) return null;
  return (
    <Alert
      show
      title={t(`${id}.title`)}
      appearance="success"
      onRemove={cleanRecentlyDisabled}
    >
      <Text>{t(`${id}.body`)}</Text>
    </Alert>
  );
}

export default RecentlyDisabledAlert;
