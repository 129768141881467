import { ReactNode } from 'react';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { Prompt } from 'react-router';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import { IonPageStratus } from 'commons/components/IonPageStratus';
import {
  HeaderContent,
  HeaderTop,
} from 'commons/components/IonPageStratus/components';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ProductPageProps {
  title: string;
  subtitle?: string;
  submitLabel: string;
  cancelLabel: string;
  isDirty: boolean;
  isSaving: boolean;
  isError?: boolean;
  children: ReactNode;
  handleSave: () => void;
  isEmptyTags: boolean;
}

function LocationPage({
  title,
  subtitle = '',
  submitLabel,
  cancelLabel,
  isDirty,
  isSaving,
  isError = false,
  children,
  handleSave,
  isEmptyTags,
}: ProductPageProps): JSX.Element {
  const { goBack } = useNavegate();
  const t = useTranslationShipping();
  const backNavigation = {
    onClick: goBack,
  };

  const mainAction = {
    onClick: handleSave,
    icon: CheckCircleIcon,
    spinner: isSaving,
    disabled: isSaving,
  };

  const mainActions = !isError ? { mainAction: mainAction } : {};

  const hasShippingLocationTag = useHasShippingLocationTag();
  const validateHasEmptyTags = hasShippingLocationTag ? isEmptyTags : false;

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} {...mainActions} />}
      headerContent={<HeaderContent title={title} subtitle={subtitle} />}
      width="small"
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      {children}

      {!isError && (
        <CancelAndConfirmButtons
          confirmText={submitLabel}
          cancelText={cancelLabel}
          onConfirm={handleSave}
          onCancel={goBack}
          isLoading={isSaving}
          isCancelDisabled={isSaving}
          isConfirmDisabled={isSaving || validateHasEmptyTags}
        />
      )}
    </IonPageStratus>
  );
}

export default LocationPage;
