import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseCard, ErrorState, Tabs } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { MonthList } from './components';
import useTranslationBilling from '../../../useTranslationBilling';
import { usePaymentsHistory } from '../TransactionFeesPage/hooks';

function TransactionFeesMonthSelectionPage(): JSX.Element {
  const t = useTranslationBilling();
  const { year } = useParams<{ year: string }>();
  const [activeTab, setActiveTab] = useState(0);
  const { goBack } = useNavegate();
  const headerNavigation = { onClick: goBack };
  const {
    isLoading,
    isError,
    fetchPaymentsHistory,
    splitPayments,
    noSplitPayments,
  } = usePaymentsHistory(year, true);

  if (isError)
    return (
      <ErrorState
        title={t('transactionFeesMonthSelection.errorResults.title')}
        action={{
          children: t('transactionFeesMonthSelection.errorResults.action'),
          onClick: fetchPaymentsHistory,
        }}
      />
    );

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={<HeaderContent title={year} />}
      width="small"
    >
      <BaseCard>
        <BaseCard.Body padding="none">
          <div className="stratus--billing-cpts-tabs">
            <Tabs activeTab={activeTab} onChange={setActiveTab}>
              <Tabs.Item
                label={t('transactionFeesMonthSelection.splitTab')}
                key="splitTab"
              >
                <MonthList
                  payments={splitPayments}
                  isLoading={isLoading}
                  split
                />
              </Tabs.Item>
              <Tabs.Item
                label={t('transactionFeesMonthSelection.noSplitTab')}
                key="noSplitTab"
              >
                <MonthList
                  payments={noSplitPayments}
                  isLoading={isLoading}
                  split={false}
                />
              </Tabs.Item>
            </Tabs>
          </div>
        </BaseCard.Body>
      </BaseCard>
    </IonPageStratus>
  );
}

export default TransactionFeesMonthSelectionPage;
