import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { ProductCloneResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { cleanUpInventory } from 'domains/Catalog/Inventory/inventorySlice';
import { CloneProductToParamsInterface } from 'domains/Catalog/Products/productsServices';
import {
  deleteProduct as deleteProductAction,
  cloneProduct as cloneProductAction,
} from 'domains/Catalog/Products/productsSlice';

export interface UseProductActionsResult {
  deleteProduct: (id: string) => Promise<void>;
  cloneProduct: (
    productToClone: CloneProductToParamsInterface,
  ) => Promise<ProductCloneResponseDto>;
}

function useProductActions(): UseProductActionsResult {
  const dispatch = useAppDispatch();

  const deleteProduct = useCallback(
    async (id: string) => {
      const result = await dispatch(deleteProductAction(id));
      dispatch(cleanUpInventory());
      return unwrapResult(result);
    },
    [dispatch],
  );

  const cloneProduct = useCallback(
    async (productToClone: CloneProductToParamsInterface) => {
      const result = await dispatch(cloneProductAction(productToClone));
      const productCloneResponse = unwrapResult(result);
      dispatch(cleanUpInventory());
      return productCloneResponse;
    },
    [dispatch],
  );

  return {
    deleteProduct,
    cloneProduct,
  };
}

export default useProductActions;
