import {
  MapContainer,
  TileLayer,
  useMap,
  MapContainerProps,
} from 'react-leaflet';
import { useGetCountry } from 'domains/Auth/hooks';

// We created this component as a solution to this issue:
// https://github.com/PaulLeCam/react-leaflet/issues/998
// which consists of not displaying the map correctly when rendering for the first time
function FixResizeReactLeaflet() {
  const map = useMap();

  setTimeout(() => {
    map.invalidateSize();
  }, 0);

  return null;
}

type StatisticsMapContainerProps = Omit<MapContainerProps, 'style'>;

function StatisticsMapContainer({
  children,
  ...rest
}: StatisticsMapContainerProps) {
  const country = useGetCountry();
  const isAR = 'AR' === country;
  return (
    <MapContainer style={{ height: '373px' }} {...rest}>
      <FixResizeReactLeaflet />
      {isAR && (
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | <a href="http://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2" target="_blank">Instituto Geográfico Nacional</a> contributors'
          url="https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png"
        />
      )}
      {!isAR && (
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      )}
      {children}
    </MapContainer>
  );
}

export default StatisticsMapContainer;
