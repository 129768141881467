import { Alert } from '@nimbus-ds/components';
import { useGetCountry } from 'domains/Auth/hooks';
import { usePayorderToPay } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

export default function DisabledRecurrenciesAlert() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodWithRecurrency.disabledRecurrenciesAlert',
  );

  const country = useGetCountry();
  const { isForPlanOnly } = usePayorderToPay();
  if (country !== 'BR' || isForPlanOnly) return null;

  return <Alert appearance="warning">{t('message')}</Alert>;
}
