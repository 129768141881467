import { useEffect } from 'react';
import { CalloutCard, CalloutCardProps } from '@nimbus-ds/patterns';
import { BannerToShowType } from '@tiendanube/common';
import { LockIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { useAuthenticationFactorDashboardStatus } from 'domains/Account/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import {
  tracking2faWarningClick,
  tracking2faClick,
  tracking2faLimitedAccessClick,
} from 'domains/Dashboard/tracking';

const doNotTrack = () => {
  /* empty function to avoid tracking event */
};

const LOG_EVENTS: Record<BannerToShowType, () => void> = {
  install_authentication_factor: tracking2faClick,
  deadline_incoming: tracking2faWarningClick,
  deadline_passed: tracking2faLimitedAccessClick,
  install_recovery_codes: doNotTrack,
  last_recovery_codes: doNotTrack,
  '': doNotTrack,
};

function CardAuthenticationFactorPromotion() {
  const t = useTranslationDashboard();
  const { goTo } = useNavegate();
  const {
    authenticationFactorsDashboardStatus,
    fetchAuthenticationFactorsDashboardStatus,
    status,
  } = useAuthenticationFactorDashboardStatus();

  useEffect(() => {
    fetchAuthenticationFactorsDashboardStatus();
  }, [fetchAuthenticationFactorsDashboardStatus]);

  const handleClick = (bannerToShow: BannerToShowType) => () => {
    const trackingEvent = LOG_EVENTS[bannerToShow];
    trackingEvent();
    goTo(ACCOUNT_ROUTES.authenticationFactor);
  };

  if (
    status !== 'success' ||
    !authenticationFactorsDashboardStatus ||
    authenticationFactorsDashboardStatus?.bannerToShow === ''
  )
    return null;

  const bannerInfo = (
    bannerToShow: Exclude<BannerToShowType, ''>,
  ): [CalloutCardProps['appearance'], string, string] => {
    switch (bannerToShow) {
      case 'deadline_incoming':
        return [
          'warning',
          t('authenticationFactors.deadlineIncoming.title'),
          t('authenticationFactors.deadlineIncoming.subtitle', {
            count: authenticationFactorsDashboardStatus.daysToDeadline,
          }),
        ];
      case 'deadline_passed':
        return [
          'danger',
          t('authenticationFactors.deadlinePassed.title'),
          t('authenticationFactors.deadlinePassed.subtitle'),
        ];
      case 'install_authentication_factor':
        return [
          'primary',
          t('authenticationFactors.installAuthenticationFactor.title'),
          t('authenticationFactors.installAuthenticationFactor.subtitle'),
        ];
      case 'install_recovery_codes':
        return [
          'primary',
          t('authenticationFactors.installRecoveryCodes.title'),
          t('authenticationFactors.installRecoveryCodes.subtitle'),
        ];
      case 'last_recovery_codes':
        return [
          'primary',
          t('authenticationFactors.lastRecoveryCodes.title'),
          t('authenticationFactors.lastRecoveryCodes.subtitle'),
        ];
    }
  };

  const [appearance, title, subtitle] = bannerInfo(
    authenticationFactorsDashboardStatus.bannerToShow,
  );

  return (
    <CalloutCard
      appearance={appearance}
      icon={LockIcon}
      title={title}
      subtitle={subtitle}
      onClick={handleClick(authenticationFactorsDashboardStatus.bannerToShow)}
    />
  );
}

export default CardAuthenticationFactorPromotion;
