import { useContext } from 'react';
import { BulkActionsContext } from './BulkActionsProvider';

function useBulkActions(): BulkActionsContext {
  const {
    selectedRowsId,
    selectedAction,
    showModal,
    editMode,
    setSelectedRowsId,
    setSelectedAction,
    clearBulkActions,
    openModal,
    closeModal,
    toggleEditMode,
  } = useContext(BulkActionsContext);

  return {
    selectedRowsId,
    selectedAction,
    showModal,
    editMode,
    setSelectedRowsId,
    setSelectedAction,
    clearBulkActions,
    openModal,
    closeModal,
    toggleEditMode,
  };
}

export default useBulkActions;
