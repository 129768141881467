import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsStoredFilters } from 'domains/Statistics/hooks';
import {
  getStatisticsPaymentsSummaryData,
  getStatisticsPaymentsSummaryStatus,
} from 'domains/Statistics/selectors';
import { getPaymentsSummary } from 'domains/Statistics/statisticsSlice';

function useGetStatisticsPayment() {
  const dispatch = useAppDispatch();
  const data = useSelector(getStatisticsPaymentsSummaryData);
  const status = useSelector(getStatisticsPaymentsSummaryStatus);

  const fetchPaymentsSummary = useCallback(() => {
    dispatch(getPaymentsSummary());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchPaymentsSummary);

  const isEmpty =
    status.isSuccess &&
    !Object.values(data.results).some((item) => item.values.length > 0);

  return {
    ...status,
    isEmpty,
    fetchPaymentsSummary,
    data,
  };
}

export default useGetStatisticsPayment;
