import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import {
  cleanExportProducts,
  exportProducts as exportProductsAction,
} from 'domains/Catalog/Products/productsSlice';
import { defaultExportFilters } from 'domains/Catalog/Products/productsSlice/constants';
import { exportProductsStatus as exportProductsStatusSelector } from 'domains/Catalog/Products/productsSlice/productSelectors';

function useDonwloadProductCsv() {
  const dispatch = useAppDispatch();
  const { filters } = useListFilters('products/import', defaultExportFilters);
  const exportProductsStatus = useSelector(exportProductsStatusSelector);
  const { setJob, cleanJob, jobStatus, csvDownloadLinkUrl } = useBackgroundJobs(
    'productsCsvDownload',
    true,
  );

  const exportProducts = useCallback(
    async (includeDescriptions: boolean) => {
      const { token } = unwrapResult(
        await dispatch(
          exportProductsAction({ ...filters, includeDescriptions }),
        ),
      );
      dispatch(cleanExportProducts());
      setJob(String(token));
    },
    [dispatch, filters, setJob],
  );

  const isExporting =
    exportProductsStatus === 'loading' || jobStatus === 'pending';

  const getJobCsvDownloadStatus = () => {
    const isFailed = jobStatus === 'failed';
    const isSuccess = jobStatus === 'ready' || jobStatus === 'downloaded';

    if (isExporting) {
      return 'loading';
    } else if (isFailed) {
      return 'error';
    } else if (isSuccess) {
      return 'success';
    } else {
      return 'idle';
    }
  };

  const ressetExportingStatus = useCallback(() => {
    dispatch(cleanExportProducts());
    cleanJob();
  }, [cleanJob, dispatch]);

  return {
    isExporting,
    jobCsvDownloadStatus: getJobCsvDownloadStatus(),
    csvDownloadLinkUrl,
    exportProductsStatus,
    ressetExportingStatus,
    exportProducts,
  };
}

export default useDonwloadProductCsv;
