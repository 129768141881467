import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function RelatedProductCardErrorState(): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <Card>
      <Box>
        <Title as="h4">{t('products.detail.relatedProduct.title')}</Title>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="4"
      >
        <Box>
          <ExclamationTriangleIcon size="large" />
        </Box>
        <Box>
          <Text>
            {t('products.detail.relatedProduct.error.getReTry.title')}
          </Text>
        </Box>
      </Box>
    </Card>
  );
}

export default RelatedProductCardErrorState;
