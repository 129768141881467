import { Select, Text } from '@nimbus-ds/components';
import { FormField, FormFieldSelectBaseProps } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

interface InputLocationProps
  extends Omit<FormFieldSelectBaseProps, 'children'> {
  readonly isLoading?: boolean;
  readonly options: { value: string; label: string }[];
}
function SelectForLocation({
  isLoading,
  options,
  ...props
}: InputLocationProps): JSX.Element {
  return (
    <>
      {isLoading && (
        <Stack column spacing="tight" align="flex-start">
          <Text.Skeleton width="10%" />
          <Select.Skeleton width="100%" />
        </Stack>
      )}
      {!isLoading && options.length > 0 && (
        <FormField.Select {...props}>
          {options.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ))}
        </FormField.Select>
      )}
    </>
  );
}

export default SelectForLocation;
