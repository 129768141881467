import { ChangeEventHandler, useState } from 'react';
import { Text, Card, Title, Checkbox, Box } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { toStatusType } from 'App/rtk';
import { SubmitButton } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  whatsappDisableChatbutton,
  whatsappEditNumberCLick,
  whatsappEnableChatbutton,
} from 'domains/Configurations/Whatsapp/tracking';
import {
  useUpdateWhatsappMutation,
  useGetWhatsappQuery,
} from 'domains/Configurations/Whatsapp/whatsappApi';
import {
  initialValuesWhatsappForm as initialValues,
  validationSchemaWhatsapp as validationSchema,
  validatePhoneNumber,
} from './whatsappFormConfig';

interface WhatsappFormToastMessageState {
  error: string;
  success: string;
}

export function WhatsappForm() {
  const t = useTranslationConfigurations('whatsapp');
  const { t: tCommon } = useTranslation('common');

  /* We create a local state to save the toast texts.
   * This is to prevent the "added button" toas text from being changed to "saved changes" while the toast is still visible.
   */
  const [toastTexts, setToastTexts] = useState<WhatsappFormToastMessageState>({
    error: t('toast.errorAdd'),
    success: t('toast.successAdd'),
  });
  const [onSubmit, { status, isLoading }] = useUpdateWhatsappMutation();
  const { data } = useGetWhatsappQuery();

  const { values, errors, handleChange, handleOnSubmit, isDirty } = useForm({
    initialValues: data?.phone ? data : initialValues,
    validationSchema,
    onSubmit: (validatedData) => {
      const error = data?.phone ? t('toast.errorSave') : t('toast.errorAdd');
      const success = data?.phone
        ? t('toast.successSave')
        : t('toast.successAdd');

      // Before submitting, we add to the state the toast texts that correspond to the case
      setToastTexts({
        error,
        success,
      });
      whatsappEditNumberCLick();
      onSubmit(validatedData);
    },
  });

  const ctaText = data?.phone ? t('save') : t('add');
  const isPhoneInvalid = !!errors.phone;

  // We show the toast with the text saved in the state before doing sumbit
  useToastStatus({
    error: toastTexts.error,
    success: toastTexts.success,
    status: toStatusType(status),
  });

  const handleChangePhone: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (validatePhoneNumber(e.target.value)) {
      handleChange(e);
    }
  };

  const handleChangeChatButon: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
      whatsappEnableChatbutton();
    } else {
      whatsappDisableChatbutton();
    }
    handleChange(e);
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">{t('titleCard')}</Title>
        </Card.Header>
        <FormField.Input
          autoFocus
          name="phone"
          type="tel"
          inputMode="decimal"
          appearance={isPhoneInvalid ? 'danger' : 'none'}
          label={t('input.label')}
          helpText={t(errors.phone || '')}
          showHelpText={isPhoneInvalid}
          append={<Text>{data?.countryCode}</Text>}
          value={values.phone}
          placeholder={t('input.placeholder')}
          onChange={handleChangePhone}
        />
        <Card.Footer>
          <Checkbox
            name="enable"
            checked={values.enable}
            label={t('checkboxLabel')}
            onChange={handleChangeChatButon}
          />
        </Card.Footer>
      </Card>
      <Box display="flex" justifyContent="flex-end">
        <SubmitButton
          onClick={handleOnSubmit}
          isLoading={isLoading}
          disabled={!isDirty}
        >
          {ctaText}
        </SubmitButton>
      </Box>
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
    </>
  );
}
