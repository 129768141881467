import { Capacitor } from '@capacitor/core';
import { Text, Button } from '@tiendanube/components';
import { PlusCircleIcon, RocketIcon, LockIcon } from '@tiendanube/icons';
import {
  HeaderContent,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { STORE_APP } from 'commons/constants/externalLinks';
import { useTranslationCommon } from 'commons/hooks';
import IconItemStratus from '../IconItemStratus';

function ForcedUpdate() {
  const t = useTranslationCommon();
  const platform = Capacitor.getPlatform();

  const handleOnClick = () => {
    const url: string = STORE_APP[platform];

    if (Object.keys(STORE_APP).includes(platform)) {
      window.open(url);
    }
  };

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('forceUpdate.title')} />}
    >
      <Text>{t('forceUpdate.info')}</Text>
      <br />
      <br />
      <IconItemStratus
        text={t('forceUpdate.plusCircleLink')}
        icon={PlusCircleIcon}
      />
      <IconItemStratus text={t('forceUpdate.rocketLink')} icon={RocketIcon} />
      <IconItemStratus text={t('forceUpdate.lockLink')} icon={LockIcon} />
      <br />
      <br />
      <Button onClick={handleOnClick} appearance="primary">
        {t('forceUpdate.update')}
      </Button>
    </IonPageStratus>
  );
}

export default ForcedUpdate;
