import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { InvoicesResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { invoicesHistory } from '../types';

export const getInvoiceHistoryState = (
  state: RootStateType,
): CombinedState<invoicesHistory> => state.billing.invoices.invoices;

export const getInvoiceHistory = (state: RootStateType) =>
  getInvoiceHistoryState(state).data;

export const getCurrentPage = (state: RootStateType) =>
  getInvoiceHistoryState(state).data.pagination.currentPage;

export const getTotalPages = (state: RootStateType) =>
  getInvoiceHistoryState(state).data.pagination.totalPages;

export const getInvoiceHistoryStatus = (state: RootStateType) =>
  getInvoiceHistoryState(state).status.get;

export const getInvoiceHistoryList = (state: RootStateType) =>
  getInvoiceHistoryState(state).data.results;

export const getInvoiceHistoryUpdateStatus = (state: RootStateType) =>
  getInvoiceHistoryState(state).status.update;

export const getInvoiceIds = createSelector(
  getInvoiceHistoryList,
  (monthlyInvoiceList) => {
    const ids: string[] = [];
    for (const monthlyInvoice of monthlyInvoiceList) {
      for (const invoiceRow of monthlyInvoice.row) {
        ids.push(invoiceRow.invoice.id);
      }
    }
    return ids;
  },
);

const getInvoiceHistoryFromList = (
  monthlyInvoiceList: InvoicesResponseDto[],
  id,
) => {
  for (const monthlyInvoice of monthlyInvoiceList) {
    for (const index in monthlyInvoice.row) {
      if (monthlyInvoice.row[index].invoice.id === id) {
        const { invoice, amount, period, currency } = monthlyInvoice.row[index];
        const { month, total, currency: totalCurrency } = monthlyInvoice;
        return {
          itFirst: index === '0',
          id: invoice.id,
          item: invoice.item,
          type: invoice.type,
          amount,
          month,
          period,
          total,
          invoice: { invoice, amount, period, currency },
          totalCurrency,
        };
      }
    }
  }
};

const getEntityById = (_: RootStateType, id: string) => id;

export const getInvoiceById = createSelector(
  getInvoiceHistoryList,
  getEntityById,
  (monthlyInvoiceList, id) => getInvoiceHistoryFromList(monthlyInvoiceList, id),
);

export const getInvoiceLinkStatus = (state: RootStateType) =>
  getInvoiceHistoryState(state).status.link;
