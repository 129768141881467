import { Box, Text } from '@nimbus-ds/components';
import { VariantResponseDto } from '@tiendanube/common';
import { createTextSingleVariantPlace } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';

interface LabelStockProps {
  defaultValue: { variant: VariantResponseDto };
}

function LabelStock({ defaultValue }: LabelStockProps): JSX.Element {
  const t = useTranslationCatalog();
  const { locations } = useLocations();

  const variant = defaultValue.variant;
  const isEmptyStock = variant.stock === 0;

  const variantsPlaceText =
    locations.length > 1
      ? createTextSingleVariantPlace(variant, locations)
      : undefined;
  const color = isEmptyStock ? 'danger-textLow' : 'neutral-textHigh';
  const quantity =
    variant.stock === null
      ? t('products.itemList.infiniteStock')
      : isEmptyStock
      ? t('products.itemList.emptyStock')
      : t('products.itemList.unit', { count: variant.stock });

  return (
    <Box
      marginTop="0-5"
      minHeight={{ xs: '32px' }}
      maxHeight={{ xs: '32px' }}
      overflow="hidden"
      display="grid"
      alignItems="center"
    >
      <Text fontSize="base" as="p" color={color}>
        {quantity}
      </Text>
      {variantsPlaceText && (
        <Text
          as="p"
          color="neutral-textDisabled"
          fontSize="caption"
          lineHeight="caption"
          lineClamp={1}
        >
          {`${t(`${variantsPlaceText?.i18key}`, {
            value: variantsPlaceText?.value,
          })}`}
        </Text>
      )}
    </Box>
  );
}

export default LabelStock;
