import { Stack, Text } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';
import {
  useStatisticsFilterContext,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import StatsColorIcon from '../../../StatsColorIcon';
import { StratusLegendContentProps } from '../../StratusLegendContent';

function StratusLegendContentCompared({
  payload = [],
  isCombined = false,
}: StratusLegendContentProps) {
  const { statisticsFilters } = useStatisticsFilterContext();
  const t = useTranslationStatistics();
  const customMainPeriodText =
    statisticsFilters.mainPeriodFrom &&
    statisticsFilters.mainPeriodTo &&
    `${dateFormat(
      statisticsFilters.mainPeriodFrom,
      Format.DD_MM_YYYY,
    )} - ${dateFormat(statisticsFilters.mainPeriodTo, Format.DD_MM_YYYY)}`;

  const customComparePeriodText =
    statisticsFilters.comparePeriodFrom &&
    statisticsFilters.comparePeriodTo &&
    `${dateFormat(
      statisticsFilters.comparePeriodFrom,
      Format.DD_MM_YYYY,
    )} - ${dateFormat(statisticsFilters.comparePeriodTo, Format.DD_MM_YYYY)}`;

  const half = payload.length / 2;
  const [firstData] = payload.splice(0, half);
  const [secondaryData] = payload.splice(-half);
  const borderRadious = isCombined ? '0px' : undefined;

  return (
    <Stack spacing="tight">
      <Stack spacing="none">
        <StatsColorIcon color={firstData?.color} borderRadius={borderRadious} />
      </Stack>{' '}
      <Text appearance="primary" size="caption">
        {customMainPeriodText ||
          t(`statistics.filters.${statisticsFilters.mainPeriod}`)}
      </Text>
      <Stack spacing="none">
        <StatsColorIcon
          color={secondaryData?.color}
          borderRadius={borderRadious}
        />
      </Stack>{' '}
      <Text appearance="primary" size="caption">
        {customComparePeriodText ||
          (statisticsFilters.comparePeriod &&
            t(`statistics.filters.${statisticsFilters.comparePeriod}`))}
      </Text>
    </Stack>
  );
}

export default StratusLegendContentCompared;
