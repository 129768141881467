import { useCallback } from 'react';
import { Box, Title, Text } from '@nimbus-ds/components';
import { GenerateProductSeoResponseDto } from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import AiGenerationModal from 'commons/components/AiGenerationModal';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useGenerateProductSeo } from 'domains/Catalog/Products/pages/hooks';

export interface SeoGenerationModalProps {
  onDismiss: () => void;
  onUseContent: (seoTitle: string, seoDescription: string) => void;
  show?: boolean;
  product: {
    name?: string;
    description?: string;
    lang: string;
  };
}

function SeoGenerationModal({
  onDismiss,
  onUseContent,
  show = false,
  product,
}: Readonly<SeoGenerationModalProps>) {
  const t = useTranslationCatalog();

  const { generateSeo } = useGenerateProductSeo();

  const insufficientInfo = !product.name || !product.description;

  const handleGenerateContent = useCallback(
    async () =>
      await generateSeo({
        product_name: product.name,
        product_description: product.description,
        lang: product.lang,
      }),
    [generateSeo, product],
  );

  const handleUseContent = useCallback(
    ({ seo_title, seo_description }: GenerateProductSeoResponseDto) => {
      onUseContent(seo_title, seo_description);
    },
    [onUseContent],
  );

  return (
    <AiGenerationModal
      show={show}
      feature={AiFeature.AI_PRODUCTS_SEO}
      insufficientInfo={insufficientInfo}
      insufficientInfoError={t(
        'products.detail.seo.aiGeneration.insufficientInfo.text',
      )}
      generateOnOpen
      showCtas
      onDismiss={onDismiss}
      onGenerateContent={handleGenerateContent}
      onUseContent={handleUseContent}
      feedbackTitle={t('products.detail.seo.aiGeneration.feedbackModal.title')}
      feedbackText={t('products.detail.seo.aiGeneration.feedbackModal.text')}
      feedbackAsAside
      previewGeneratedContent={({
        seo_title,
        seo_description,
      }: GenerateProductSeoResponseDto) => (
        <>
          <Box>
            <Title as="h6">
              {t('products.detail.seo.aiGeneration.seo_title')}
            </Title>
            <Text color="primary-textLow">{seo_title}</Text>
          </Box>

          <Box marginTop="2">
            <Title as="h6">
              {t('products.detail.seo.aiGeneration.seo_description')}
            </Title>
            <Text color="primary-textLow">{seo_description}</Text>
          </Box>
        </>
      )}
    />
  );
}

export default SeoGenerationModal;
