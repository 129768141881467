import { Button, Icon } from '@nimbus-ds/components';
import { HomeIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useNavegate } from 'App/hooks';
import { trackShippingDistributionCenterDefine } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

export interface LocationEmptyListOrigin {
  origin: 'locations' | 'deliveryMethods';
}

function LocationsListEmpty({ origin }: LocationEmptyListOrigin): JSX.Element {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const handlePrimary = () => {
    trackShippingDistributionCenterDefine(origin);
    goTo('/settings/locations/new/main');
  };

  const title = `${origin}.emptyResults.title`;
  const body = `${origin}.emptyResults.body`;
  const action = `${origin}.emptyResults.action`;

  return (
    <EmptyMessage
      title={t(title)}
      text={t(body)}
      icon={
        <HomeIcon
          size={32}
          style={{
            marginTop: '3rem',
            marginBottom: '1.5rem',
          }}
        />
      }
      actions={
        <Button appearance="primary" onClick={handlePrimary}>
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          {`${t(action)}`}
        </Button>
      }
    />
  );
}

export default LocationsListEmpty;
