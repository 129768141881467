import { useEffect } from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { useHasCatalogInventory } from 'domains/Auth/hooks';
import { useInventoryAppliedFilters } from 'domains/Catalog/Inventory/pages/hooks';
import { useInventoryLevelsByLocation } from 'domains/Shipping/Locations/hooks';
import useLocationById from 'domains/Shipping/Locations/hooks/useLocation/useLocation';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  DeleteLocationModalError,
  DeleteLocationModalSkeleton,
} from './components';

interface DeleteLocationModalProps {
  locationId: string;
  isLoadingLocations: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
function DeleteLocationModal({
  locationId,
  isLoadingLocations,
  onClose,
  onConfirm,
}: Readonly<DeleteLocationModalProps>) {
  const t = useTranslationShipping();
  const { location } = useLocationById(locationId);
  const { goTo } = useNavegate();
  const hasCatalogInventory = useHasCatalogInventory();
  const { filters, applyFilters } = useInventoryAppliedFilters();

  const { fetchInventoryLevels, isError, isLoading, isSuccess, total } =
    useInventoryLevelsByLocation(locationId);

  const handleGoToInventory = () => {
    onClose();
    applyFilters({
      ...filters,
      locationId,
    });
    hasCatalogInventory && goTo(`/inventory?locationId=${locationId}`);
    !hasCatalogInventory && goTo(`/products`);
  };

  useEffect(() => {
    fetchInventoryLevels();
  }, [fetchInventoryLevels]);

  return (
    <Modal open onDismiss={onClose}>
      {isLoading && <DeleteLocationModalSkeleton />}
      {isError && (
        <DeleteLocationModalError
          onRetryError={fetchInventoryLevels}
          onClose={onClose}
        />
      )}
      {!isLoading && isSuccess && (
        <>
          <Modal.Header
            title={
              total > 0
                ? t('locations.delete.titleWithInventory')
                : t('locations.delete.title', { name: location?.name })
            }
          />
          <Modal.Body padding="none">
            <Text>
              {total > 0
                ? t('locations.delete.descriptionWithInventory', {
                    name: location.name,
                  })
                : t('locations.delete.description')}
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Stack spacing="base" justify="flex-end">
              <CancelAndConfirmButtons
                onCancel={onClose}
                onConfirm={total > 0 ? handleGoToInventory : onConfirm}
                isConfirmDisabled={isLoadingLocations}
                isCancelDisabled={isLoadingLocations}
                confirmText={
                  total > 0
                    ? t('locations.delete.goToInventory')
                    : t('locations.delete.confirm')
                }
              />
            </Stack>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
export default DeleteLocationModal;
