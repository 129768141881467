import { SHOW_DELETED_COUPONS_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { RadioButtonGroup } from 'commons/components';
import useTranslationMarketing from 'domains/Marketing/hook/useTranslationMarketing';

interface RadioGroupFilter<T, TName> {
  name: TName;
  value: T;
  options: readonly T[];
  onChange: (name: TName, value: T) => void;
}

function RadioGroupFilter<T extends string, TName extends string>({
  name,
  options,
  value,
  onChange,
}: Readonly<RadioGroupFilter<T, TName>>) {
  const t = useTranslationMarketing(`coupons.filters.${name}`);
  const [hasDeletedCouponsTag] = useHasTags(SHOW_DELETED_COUPONS_TAG);
  const all =
    hasDeletedCouponsTag && name === 'status' ? 'allExceptDeleted' : 'all';
  const labelOptions = options.map((value) => ({
    value,
    label: t(value || all),
  }));

  return (
    <RadioButtonGroup
      label={t('title')}
      name={name}
      options={labelOptions}
      onChange={({ name, value }) => onChange(name, value)}
      value={value}
      boldLabel
    />
  );
}

export default RadioGroupFilter;
