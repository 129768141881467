import { Preferences } from '@capacitor/preferences';

const PREFIX_SESSION = 'session_';

export async function setStorage<T>(
  key: string,
  value: T,
  session = false,
): Promise<void> {
  const prefix = session ? PREFIX_SESSION : '';
  await Preferences.set({
    key: prefix + key,
    value: JSON.stringify(value),
  });
}

export async function getStorage<T>(key: string, session = false): Promise<T> {
  const prefix = session ? PREFIX_SESSION : '';
  const item = await Preferences.get({ key: prefix + key });
  return item.value ? JSON.parse(item.value) : undefined;
}

export async function removeStorage(key: string): Promise<void> {
  await Preferences.remove({
    key: key,
  });
}

export async function removeSessionKeys(): Promise<void> {
  const { keys } = await Preferences.keys();
  const sessionKeys = keys.filter((key) => key.startsWith(PREFIX_SESSION));
  sessionKeys.forEach((sk) => removeStorage(sk));
}
