import VirtualDataList from 'commons/components/VirtualDataList';
import { usePagesList } from 'domains/Online/ContentPages/hooks';
import PagesListItem from './PagesListItem';

function PagesListResultMobile() {
  const { pagesIds, shouldShowSpinner, getMorePages, goToPage } =
    usePagesList();

  const handlePullToRefresh = async () => {
    goToPage(1);
  };

  return (
    <VirtualDataList
      onRefresh={handlePullToRefresh}
      isPullable
      data={pagesIds}
      endReached={getMorePages}
      itemContent={(_, id) => <PagesListItem id={id} />}
      showFooter={shouldShowSpinner}
    />
  );
}

export default PagesListResultMobile;
