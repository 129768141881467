/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FulfillmentOrdersResponseDto,
  OrderDetailsResponseDto,
  OrderShippingCostProductRequestDto,
  OrderShippingCostRequestDto,
} from '@tiendanube/common';
import { FulfillmentPreferenceType } from '@tiendanube/common/src/enums';
import { useAppDispatch } from 'App/store';
import { ProductInterface } from 'domains/Orders/components/ProductSearcher';
import {
  cleanEditOrderShippingCost,
  getOrderShippingCost,
} from 'domains/Orders/Orders/ordersSlice';
import { useShippingCosts } from './useShippingCosts';
import { OrderEditProduct } from '../components/Products';

export function useEditOrderActions(
  orderDetails: OrderDetailsResponseDto | null,
  fulfillmentOrder: FulfillmentOrdersResponseDto | undefined,
  editedOrderProducts: OrderEditProduct[],
  setEditedOrderProducts: (editedProducts: OrderEditProduct[]) => void,
  isOrderEdited: boolean,
  setIsOrderEdited: (isEdited: boolean) => void,
) {
  const ffoProducts: OrderEditProduct[] = useMemo(
    () =>
      fulfillmentOrder
        ? editedOrderProducts.filter(
            (p) => p.fulfillmentOrderId === fulfillmentOrder.id,
          )
        : editedOrderProducts,
    [fulfillmentOrder, editedOrderProducts],
  );

  const { isLoading: isLoadingShippingCosts } = useShippingCosts(
    fulfillmentOrder?.id,
  );

  const abortController = useRef(new AbortController());

  const previousFfoProducts = useRef<OrderEditProduct[]>(ffoProducts);

  const dispatch = useAppDispatch();

  const isFfoEdited = useMemo(
    () => ffoProducts.some((ffoProduct) => ffoProduct.editType),
    [ffoProducts],
  );

  const calculateNewShippingCosts = useCallback(
    (products: OrderEditProduct[]) => {
      if (!orderDetails) {
        return;
      }

      const toOrderShippingRecotizationRequest = (p: OrderEditProduct) => {
        if (p.editType === 'add') {
          return {
            productId: Number(p.productId),
            variantId: Number(p.variantId),
            quantity: p.quantity,
            type: 'add',
          } as OrderShippingCostProductRequestDto;
        }

        if (!p.originalQuantity) {
          // Should never happen
          throw new Error(
            `Product with variantId ${p.variantId} not found for order ${orderDetails.id} when editing the order`,
          );
        }
        const quantityDiff = p.quantity - p.originalQuantity;

        return {
          lineItemId: p.id,
          quantity: p.editType !== 'edit' ? p.quantity : Math.abs(quantityDiff),
          type: quantityDiff > 0 ? 'add' : 'remove',
        } as OrderShippingCostProductRequestDto;
      };

      const shippingCostRequest: OrderShippingCostRequestDto = {
        products: products
          .filter((p) => p.editType !== undefined)
          .map(toOrderShippingRecotizationRequest),
        fulfillmentOrderId: fulfillmentOrder?.id,
      };

      if (shippingCostRequest.products.length === 0 || !isFfoEdited) {
        return;
      }

      if (isLoadingShippingCosts) {
        abortController.current.abort();
      }

      let signal = abortController.current.signal;

      if (abortController.current.signal.aborted) {
        const newAbortController = new AbortController();
        signal = newAbortController.signal;
        abortController.current = newAbortController;
      }

      dispatch(
        getOrderShippingCost({
          id: orderDetails?.id,
          shippingCostRequest,
          signal: signal,
        }),
      );
    },
    [
      dispatch,
      orderDetails,
      fulfillmentOrder,
      isFfoEdited,
      isLoadingShippingCosts,
    ],
  );

  useEffect(() => {
    const ffoProductsChanged =
      ffoProducts.length !== previousFfoProducts.current.length ||
      !ffoProducts.every(
        (product, idx) =>
          product.id === previousFfoProducts.current[idx].id &&
          product.variantId === previousFfoProducts.current[idx].variantId &&
          product.editType === previousFfoProducts.current[idx].editType &&
          product.quantity === previousFfoProducts.current[idx].quantity,
      );

    const isDigitalFulfillment =
      fulfillmentOrder?.shipping.type ===
        FulfillmentPreferenceType.DELIVERY_DIGITAL ||
      fulfillmentOrder?.shipping.type ===
        FulfillmentPreferenceType.NON_SHIPPABLE;

    if (ffoProductsChanged) {
      previousFfoProducts.current = ffoProducts;
    }

    if (isFfoEdited && ffoProductsChanged && !isDigitalFulfillment) {
      calculateNewShippingCosts(ffoProducts);
    }
  }, [ffoProducts, calculateNewShippingCosts, isFfoEdited, fulfillmentOrder]);

  const updateIsOrderEdited = (products: OrderEditProduct[]) => {
    const isEdited = products.some((p) => p.editType !== undefined);
    if (isOrderEdited !== isEdited) {
      setIsOrderEdited(isEdited);
    }
  };

  const handleAddProduct = (newProducts: ProductInterface<false>[]) => {
    if (!editedOrderProducts) return;

    const productsToAdd: OrderEditProduct[] = newProducts.map((p) => ({
      productId: p.productId,
      variantId: p.variantId,
      title: p.name,
      photoUrl: p.imageUrl,
      price: p.price,
      totalPrice: p.price,
      quantity: 1,
      sku: p.sku,
      variants: Object.values(p.variantValues).join(', '),
      editType: 'add',
      fulfillmentOrderId: fulfillmentOrder?.id,
      locationId: fulfillmentOrder?.assignedLocation.id,
    }));

    setEditedOrderProducts([...editedOrderProducts, ...productsToAdd]);

    if (!isOrderEdited) {
      setIsOrderEdited(true);
    }
  };

  const getUpdatedOrderProduct = (
    idx: number,
    products: OrderEditProduct[],
    productToRemove: OrderEditProduct,
  ) => {
    const beforeProduct = products.slice(0, idx);
    const afterProduct = products.slice(idx + 1);

    let updatedProducts: OrderEditProduct[];
    if (!productToRemove.editType || productToRemove.editType === 'edit') {
      const quantity =
        productToRemove.originalQuantity ?? productToRemove.quantity;
      updatedProducts = [
        ...beforeProduct,
        {
          ...productToRemove,
          editType: 'delete',
          quantity,
          totalPrice: productToRemove.price * quantity,
          restoreStock: true,
          fulfillmentOrderId: fulfillmentOrder?.id,
          locationId: fulfillmentOrder?.assignedLocation.id,
        },
        ...afterProduct,
      ];
    } else {
      updatedProducts = [...beforeProduct, ...afterProduct];
    }
    return updatedProducts;
  };

  const handleRemoveProduct = (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => {
    if (!editedOrderProducts || !orderDetails) return;

    const editedProductIdx = editedOrderProducts.findIndex(
      (p) =>
        p.id === lineItemId &&
        p.productId === productId &&
        p.variantId === variantId &&
        p.fulfillmentOrderId === fulfillmentOrder?.id,
    );

    if (editedProductIdx === -1) return;

    const productToRemove = editedOrderProducts[editedProductIdx];

    if (productToRemove.editType === 'delete') return;

    const updatedProducts = getUpdatedOrderProduct(
      editedProductIdx,
      editedOrderProducts,
      productToRemove,
    );

    setEditedOrderProducts(updatedProducts);
    updateIsOrderEdited(updatedProducts);
  };

  const handleReAddProduct = (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => {
    if (!editedOrderProducts || !orderDetails) return;

    const updatedProducts = getUpdatedReAddedProducts(
      editedOrderProducts,
      productId,
      variantId,
      lineItemId,
    );

    setEditedOrderProducts(updatedProducts);
    updateIsOrderEdited(updatedProducts);
  };

  const getUpdatedReAddedProducts = (
    products: OrderEditProduct[],
    productId: string,
    variantId: string,
    lineItemId?: string,
  ): OrderEditProduct[] =>
    products.map((p) => {
      if (
        p.id === lineItemId &&
        p.productId === productId &&
        p.variantId === variantId &&
        p.fulfillmentOrderId === fulfillmentOrder?.id
      ) {
        return {
          ...p,
          editType: undefined,
          restoreStock: true,
          fulfillmentOrderId: fulfillmentOrder?.id,
          locationId: fulfillmentOrder?.assignedLocation.id,
        };
      }
      return p;
    });

  const handleEditProduct = (editedProduct: OrderEditProduct) => {
    if (!editedOrderProducts || !orderDetails) return;

    if (editedProduct.quantity === 0) {
      return handleRemoveProduct(
        editedProduct.productId,
        editedProduct.variantId,
        editedProduct.id,
      );
    }

    const updatedProducts = getUpdatedEditedOrderProduct(
      editedProduct,
      editedOrderProducts,
    );

    setEditedOrderProducts(updatedProducts);
    updateIsOrderEdited(updatedProducts);
  };

  const getUpdatedEditedOrderProduct = (
    editedProduct: OrderEditProduct,
    products: OrderEditProduct[],
  ): OrderEditProduct[] => {
    if (!orderDetails) return [];
    return products.map((p) => {
      if (
        p.id === editedProduct.id &&
        p.productId === editedProduct.productId &&
        p.variantId === editedProduct.variantId &&
        p.fulfillmentOrderId === fulfillmentOrder?.id
      ) {
        if (!editedProduct.originalQuantity) {
          // In this case, we edited a newly added product
          return editedProduct;
        }
        const isEdited =
          editedProduct.quantity !== editedProduct.originalQuantity;
        return {
          ...editedProduct,
          editType: isEdited ? 'edit' : undefined,
          fulfillmentOrderId: fulfillmentOrder?.id,
          locationId: fulfillmentOrder?.assignedLocation.id,
        };
      }
      return p;
    });
  };

  const handleRestoreStock = (
    productId: string,
    variantId: string,
    isRestore: boolean,
    lineItemId?: string,
  ) => {
    if (!editedOrderProducts) return;

    const updatedProducts = editedOrderProducts.map((p) => {
      if (
        p.id === lineItemId &&
        p.productId === productId &&
        p.variantId === variantId &&
        p.fulfillmentOrderId === fulfillmentOrder?.id
      ) {
        return { ...p, restoreStock: isRestore };
      }
      return p;
    });

    setEditedOrderProducts(updatedProducts);
  };

  useEffect(() => {
    if (!isFfoEdited) {
      abortController.current.abort();

      dispatch(
        cleanEditOrderShippingCost({
          fulfillmentOrderId: fulfillmentOrder?.id,
        }),
      );
    }
  }, [isFfoEdited, dispatch, fulfillmentOrder, abortController]);

  return {
    handleAddProduct,
    handleRemoveProduct,
    handleReAddProduct,
    handleRestoreStock,
    handleEditProduct,
    isOrderEdited,
    ffoProducts,
  };
}
