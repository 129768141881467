import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CorreiosContractsRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanUpdateCorreiosContractStatus,
  getUpdateCorreiosContractStatus,
  updateCorreiosContractAction,
} from './../../deliveryMethodsSlice';

function useUpdateCorreiosContract() {
  const dispatch = useAppDispatch();
  const status = useSelector(getUpdateCorreiosContractStatus);

  const updateCorreiosContract = useCallback(
    async (body: CorreiosContractsRequestDto) => {
      await dispatch(updateCorreiosContractAction(body));
      dispatch(cleanUpdateCorreiosContractStatus());
    },
    [dispatch],
  );

  return { updateCorreiosContract, status };
}

export default useUpdateCorreiosContract;
