import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export const getDashboard = (state: RootStateType) => state.dashboard;

const getFirstSaleEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.firstSale,
);

const getFirstSale = createSimpleSelector(
  [getFirstSaleEntity],
  (firstSale) => firstSale.get,
);

export const getFirstSaleStatus = createSimpleSelector(
  [getFirstSale],
  (firstSale) => convertStatusTypeToObject(firstSale.status),
);

export const getFirstSaleData = createSimpleSelector(
  [getFirstSale],
  (firstSale) => firstSale.data,
);

const markFirstSaleReal = createSimpleSelector(
  [getFirstSaleEntity],
  (firstSale) => firstSale.markFirstSaleReal,
);

export const markFirstSaleRealStatus = createSimpleSelector(
  [markFirstSaleReal],
  (firstSale) => firstSale.status,
);

export const markFirstSaleRealData = createSimpleSelector(
  [markFirstSaleReal],
  (firstSale) => firstSale.data,
);
