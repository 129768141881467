import { Stack, Text } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import ScopesItem from '../ScopesItem';

function Skeleton() {
  const { t } = useTranslationPartnerApps();

  return (
    <CardWithTitle title={t('scopes.title')}>
      <Stack column align="stretch">
        <Text.Skeleton />
        <ScopesItem.Skeleton />
        <ScopesItem.Skeleton />
      </Stack>
    </CardWithTitle>
  );
}

export default Skeleton;
