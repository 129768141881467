import { Menu } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import AccountMenuContent from './components/AccountMenuContent';

interface AccountMenuProps {
  visible: boolean;
  device: 'mobile' | 'desktop';
}

function AccountMenuWeb({ visible, device }: AccountMenuProps): JSX.Element {
  return (
    <div
      className={classNames('stratus--menu-container', 'stratus--submenu', {
        visible: visible,
      })}
    >
      <Menu>
        <AccountMenuContent device={device} />
      </Menu>
    </div>
  );
}

export default AccountMenuWeb;
