import { useEffect, useState } from 'react';
import { Alert, Link, Text, Card } from '@nimbus-ds/components';
import { ExclamationTriangleIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router';
import { Link as LinkRouter } from 'react-router-dom';
import { useNavegate, useQuery } from 'App/hooks';
import {
  CancelAndSaveButtons,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IconSaveLink,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { TERMS_OF_USE_LINK } from 'commons/constants';
import { useToastStatus } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGoToPlans, useIsMobileDevice } from 'domains/Auth/hooks';
import {
  useTranslationPartnerApps,
  useMultiCDIncompatibleAlert,
} from 'domains/PartnersApps/hooks';
import { trackingPartnersAppsAddClick } from 'domains/PartnersApps/tracking';
import { PermissionsCard } from './components';
import { useGetAppsScopes, useGetAppsAuthorizationLink } from './hooks';

function AppScopesAuthorizationPage() {
  const isMobile = useIsMobileDevice();
  const { t, language } = useTranslationPartnerApps();
  const redirectUri = useQuery().get('redirect_uri');
  const { id } = useParams<{ id: string }>();
  const { goBack } = useNavegate();
  const { getAppsLinkAuthorization, status } = useGetAppsAuthorizationLink();
  const [
    loadingRedirectAutomaticApproval,
    setLoadingRedirectAutomaticApproval,
  ] = useState(true);
  const {
    appScopes,
    isLoading,
    isError,
    isSuccess,
    fetchAppScopes,
    statusCode,
  } = useGetAppsScopes(id, redirectUri);
  const { goToPlans } = useGoToPlans();

  const showMultiInventoryIncompatibleAlert = useMultiCDIncompatibleAlert(
    appScopes?.category,
    !!appScopes?.isMultiCDCompatible,
  );

  useToastStatus({
    status,
    error: t('scopes.toastError'),
  });

  const handleGetAppsScopes = () => fetchAppScopes(id);

  const backNavigation = {
    onClick: goBack,
  };

  const handleGenerateLink = async () => {
    const redirectLink = await getAppsLinkAuthorization(id);
    trackingPartnersAppsAddClick(id, appScopes?.nameApp);
    openWindow(redirectLink, true);
  };

  const getErrorMessage = () => {
    if (isError) {
      if (statusCode === '403') {
        return (
          <EmptyMessage
            title=""
            text={t('genericError.notAuthorized')}
            icon={<ExclamationTriangleIcon size={32} />}
          />
        );
      }
      return (
        <ErrorScreen
          description={t('genericError.description')}
          onRetry={handleGetAppsScopes}
        />
      );
    }
  };

  useEffect(() => {
    if (isSuccess && appScopes?.authorization.automaticApprovalUrl) {
      openWindow(appScopes.authorization.automaticApprovalUrl, isMobile);
      setLoadingRedirectAutomaticApproval(!isMobile);
      return;
    }
    setLoadingRedirectAutomaticApproval(false);
  }, [isSuccess, appScopes?.authorization.automaticApprovalUrl, isMobile]);

  const toAuthorize =
    !loadingRedirectAutomaticApproval &&
    isSuccess &&
    appScopes?.authorization.availableForCountry &&
    appScopes?.authorization.availableForStorePlan;

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop
          navigation={backNavigation}
          actions={<IconSaveLink onClick={handleGenerateLink} />}
        />
      }
      headerContent={
        <HeaderContent
          title={
            isLoading && !isSuccess
              ? 'skeleton'
              : `${t('scopes.mainTitle', {
                  nameApp: appScopes?.nameApp,
                })}`
          }
        />
      }
    >
      {isSuccess && !appScopes?.authorization.isPublished && (
        <Alert appearance="primary">{t('scopes.notPublished')}</Alert>
      )}

      {showMultiInventoryIncompatibleAlert && (
        <Alert
          appearance="warning"
          title={t('scopes.showMultiInventoryIncompatibleAlert.title')}
        >
          {t('scopes.showMultiInventoryIncompatibleAlert.description')}
        </Alert>
      )}

      {isSuccess && !appScopes?.authorization.availableForCountry && (
        <Alert appearance="warning" title={t('scopes.limitedCountry.title')}>
          <Text>
            <Trans
              t={t}
              i18nKey="scopes.limitedCountry.description"
              components={{
                AppStoreLink: (
                  <Link
                    as="a"
                    appearance="primary"
                    href={appScopes?.appStore}
                    target="_blank"
                    textDecoration="none"
                  >
                    {' '}
                  </Link>
                ),
              }}
            />
          </Text>
        </Alert>
      )}

      {isSuccess &&
        appScopes?.authorization.availableForCountry &&
        !appScopes?.authorization.availableForStorePlan && (
          <Alert appearance="warning" title={t('scopes.limitedPlan.title')}>
            <Text>
              {t('scopes.limitedPlan.description')}{' '}
              <Link
                as="button"
                appearance="primary"
                textDecoration="none"
                onClick={goToPlans}
              >
                {t('scopes.limitedPlan.link')}
              </Link>
            </Text>
          </Alert>
        )}

      {(isLoading || loadingRedirectAutomaticApproval) && (
        <>
          <PermissionsCard.Skeleton />
          <CancelAndSaveButtons.Skeleton />
        </>
      )}
      {getErrorMessage()}
      {toAuthorize && (
        <>
          <PermissionsCard
            storePermissions={appScopes?.storeScopes}
            clientPermissions={appScopes.clientScopes}
          />
          <div className="stratus--base-card-no-box-shadow">
            <Card>
              <Card.Body>
                <Stack column align="flex-start">
                  <Text>
                    <Trans
                      t={t}
                      i18nKey="scopes.alert.line1"
                      components={{
                        pdpLink: (
                          <Link
                            as="a"
                            appearance="primary"
                            href={appScopes.privacyPolicyUrl}
                            target="_blank"
                          >
                            {' '}
                          </Link>
                        ),
                        appsLink: (
                          <Link as={LinkRouter} appearance="primary" to="/apps">
                            {' '}
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                  <Text>{t('scopes.alert.line2')}</Text>
                  <Text>
                    <Trans
                      t={t}
                      i18nKey="scopes.alert.line3"
                      components={{
                        tduLink: (
                          <Link
                            as="a"
                            appearance="primary"
                            href={TERMS_OF_USE_LINK[language]}
                            target="_blank"
                          >
                            {' '}
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                </Stack>
              </Card.Body>
            </Card>
          </div>
          <CancelAndSaveButtons
            onCancel={goBack}
            onSave={handleGenerateLink}
            isLoading={status === 'loading'}
            icon={ExternalLinkIcon}
            iconPosition="end"
            saveText={t('scopes.btnAccept')}
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default AppScopesAuthorizationPage;
