import {
  BrowserSearchIcon,
  ChatDotsIcon,
  TelephoneIcon,
  WhatsappIcon,
} from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import useTopBar from 'App/components/Topbar/useTopBar';
import { FEATURE_CS_PHONE_CALL, FEATURE_CS_WHATSAPP } from 'App/features';
import { openWindow } from 'commons/utils/window';
import { SUPPORT_PHONECALL, SUPPORT_WHATSAPP } from 'config/upsellFlowSources';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import {
  canCountryLink,
  phoneCallLink,
  whatsappCountryLink,
} from './constants';
import { SupportCardEntry } from './SupportCardEntry';

interface SupportCardBodyProps {
  onOpenChat: () => void;
  onClose: () => void;
}

export function SupportCardBody({
  onOpenChat,
  onClose,
}: Readonly<SupportCardBodyProps>) {
  const { noReadMessagesCount } = useTopBar();
  const t = useTranslationBilling();
  const { country } = useStoreInfo();
  const { isAvailable: isAvailableWhatsapp } =
    useStatusFeatureByKey(FEATURE_CS_WHATSAPP);
  const { isAvailable: isAvailablePhoneCall } = useStatusFeatureByKey(
    FEATURE_CS_PHONE_CALL,
  );

  const openInNewTab = (url: string) => {
    onClose();
    openWindow(url, true);
  };

  const onClickWhatsapp = useUpsellFlow({
    title: t('supportChanels.types.whatsapp.upsell'),
    featureKey: FEATURE_CS_WHATSAPP,
    trackingSource: SUPPORT_WHATSAPP,
    callback: () => {
      openInNewTab(whatsappCountryLink[country] || whatsappCountryLink.default);
    },
  });

  const onClickPhoneCall = useUpsellFlow({
    title: t('supportChanels.types.call.upsell'),
    featureKey: FEATURE_CS_PHONE_CALL,
    trackingSource: SUPPORT_PHONECALL,
    callback: () => {
      openInNewTab(phoneCallLink[country]);
    },
  });

  return (
    <Menu.Body>
      <SupportCardEntry
        title={t('supportChanels.types.centerAttention.title')}
        icon={BrowserSearchIcon}
        onClick={() => {
          openInNewTab(canCountryLink[country] || canCountryLink.default);
        }}
      />
      <SupportCardEntry
        title={t('supportChanels.types.chat.title')}
        icon={ChatDotsIcon}
        count={noReadMessagesCount}
        onClick={onOpenChat}
      />
      <SupportCardEntry
        title={t('supportChanels.types.whatsapp.title')}
        icon={WhatsappIcon}
        showUpsell={!isAvailableWhatsapp}
        onClick={onClickWhatsapp}
      />
      {!!phoneCallLink[country] && (
        <SupportCardEntry
          title={t('supportChanels.types.call.title')}
          icon={TelephoneIcon}
          showUpsell={!isAvailablePhoneCall}
          onClick={onClickPhoneCall}
        />
      )}
    </Menu.Body>
  );
}
