import { Text, Box, Modal, List, Title } from '@nimbus-ds/components';
import { OrderUpdateLineItem } from '@tiendanube/common';
import { useAsyncFunc } from '@tiendanube/stratus/src/commons/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import { trackingOrderReopenWithIssuesButtonClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

const productListLimit = 10;

interface updateMetadata {
  stockNeeded: number;
  stockAvailable: number;
  lineItems: OrderUpdateLineItem[];
}
interface InsufficientStockModalProps {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  updateMetadata: updateMetadata;
  toastSuccess: () => void;
  onError: () => void;
}

function InsufficientStockModal({
  onConfirm,
  onDismiss,
  open,
  updateMetadata: { stockNeeded, stockAvailable, lineItems },
  toastSuccess,
  onError,
}: Readonly<InsufficientStockModalProps>) {
  const t = useTranslationOrders();

  const openOrder = () => {
    trackingOrderReopenWithIssuesButtonClick(), onDismiss(), toastSuccess();
  };
  const [handleReopen] = useAsyncFunc(
    async () => onConfirm(),
    openOrder,
    onError,
  );

  const showItemsAsList = lineItems.length <= productListLimit;

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginLeft="2"
      >
        <Title as="h4">{t('detail.insufficientStock.stockChange')}</Title>
        <Title as="h4">{t('detail.insufficientStock.action')}</Title>

        {showItemsAsList && (
          <>
            <Box marginTop="4">
              <Text>
                {t('detail.insufficientStock.body1', {
                  count: stockAvailable,
                  stockNeeded: stockNeeded,
                })}{' '}
                {t('detail.insufficientStock.body2')}
              </Text>
            </Box>
            <Box marginTop="4" marginBottom="4">
              <Text>{t('detail.insufficientStock.insufficientStock')}</Text>
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <List>
                  {lineItems.map((lineItem: OrderUpdateLineItem) => (
                    <List.Item key={lineItem.id}>
                      <Text>
                        {lineItem.name} {' - '}
                        {t('detail.insufficientStock.issues', {
                          count: lineItem.stockNeeded - lineItem.stockAvailable,
                        })}
                      </Text>
                    </List.Item>
                  ))}
                </List>
              </Box>
            </Box>
          </>
        )}

        {!showItemsAsList && (
          <>
            <Box marginTop="4">
              <Text>
                {t('detail.insufficientStock.body1', {
                  stockNeeded,
                  count: stockAvailable,
                })}
              </Text>
            </Box>
            <Box marginTop="4">
              <Text>{t('detail.insufficientStock.body2')}</Text>
            </Box>
          </>
        )}
        <Box display="flex" alignItems="flex-end" alignSelf="flex-end">
          <CancelAndConfirmButtons
            confirmText={t('detail.insufficientStock.reopen')}
            cancelText={t('detail.insufficientStock.cancel')}
            onConfirm={() => handleReopen()}
            onCancel={onDismiss}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default InsufficientStockModal;
