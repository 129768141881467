import { ReactNode, createContext, useState } from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { CancelAndConfirmButtons } from 'commons/components';

type ModalConfirmationContextType = {
  userConfirmation: (a: string, cb: (a: boolean) => void) => void;
  isOpen: boolean;
};

export const ModalConfirmationContext =
  createContext<ModalConfirmationContextType>({
    isOpen: false,
    userConfirmation: () => null,
  });

interface ModalConfirmationInterface {
  children: ReactNode;
}

interface modalStateInterface {
  show: boolean;
  message: string;
  callback: (a: boolean) => void;
}

function ModalConfirmation({
  children,
}: ModalConfirmationInterface): JSX.Element {
  const { t } = useTranslation(['common']);
  const { Header, Body } = Modal;

  const [modalState, setModalState] = useState<modalStateInterface>({
    show: false,
    message: '',
    callback: () => null,
  });

  const userConfirmation = (
    message: string,
    callback: (a: boolean) => void,
  ) => {
    setModalState({
      show: true,
      message,
      callback,
    });
  };

  const handlePrimaryButton = () => {
    modalState.callback(true);
    setModalState({
      show: false,
      message: '',
      callback: () => null,
    });
  };

  const handleDismiss = () => {
    modalState.callback(false);
    setModalState({
      show: false,
      message: '',
      callback: () => null,
    });
  };

  return (
    <ModalConfirmationContext.Provider
      value={{ userConfirmation, isOpen: modalState.show }}
    >
      {children}
      <Modal open={modalState.show} onDismiss={handleDismiss}>
        <Header title={t('exitEdit.title')} />
        <Body padding="none">
          <Text>{modalState.message}</Text>
        </Body>
        <CancelAndConfirmButtons
          onCancel={handleDismiss}
          onConfirm={handlePrimaryButton}
          cancelText={t('exitEdit.continue')}
          confirmText={t('exitEdit.confirm')}
          appearance="danger"
        />
      </Modal>
    </ModalConfirmationContext.Provider>
  );
}

export default ModalConfirmation;
