import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { applyCoupon as applyCouponAction } from '../../../couponsSlice';
import {
  getCouponData,
  getCouponStatus,
} from '../../../couponsSlice/couponsSelectors';
import { CouponsFormInterface } from '../components/CouponsForm';

function useCoupons() {
  const dispatch = useAppDispatch();
  const status = useSelector(getCouponStatus);
  const data = useSelector(getCouponData);

  const applyCoupon = async ({ couponCode }: CouponsFormInterface) => {
    dispatch(
      applyCouponAction({
        couponCode,
      }),
    );
  };

  return { applyCoupon, ...status, data };
}

export default useCoupons;
