import { Box, IconButton, Tooltip } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { OrderEditProduct } from 'domains/Orders/Orders/pages/OrderEditPage/components/Products/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface EditOrderProductActionsProps {
  product: OrderEditProduct;
  onRemoveProduct: (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => void;
}

export function EditOrderProductDesktopActions({
  product,
  onRemoveProduct,
}: Readonly<EditOrderProductActionsProps>) {
  const t = useTranslationOrders();

  return (
    <Box display="flex">
      <Tooltip content={t('editOrders.actions.remove')} position="top" arrow>
        <IconButton
          onClick={() =>
            onRemoveProduct(product.productId, product.variantId, product.id)
          }
          source={<TrashIcon />}
          size="2em"
        />
      </Tooltip>
    </Box>
  );
}
