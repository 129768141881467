import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useRefreshInfoToSync } from 'domains/Auth/hooks';
import {
  cleanVerifyMercadoPagoStatus,
  getMercadoPagoVerifyData,
  getMercadoPagoVerifyStatus,
  verifyMercadoPagoAction,
  cleanVerifyMercadoPagoData,
} from 'domains/Dashboard/slices/paymentsMessagesSlice';

function useVerifyMercadoPagoAlert() {
  const dispatch = useAppDispatch();
  const refreshInfoToSync = useRefreshInfoToSync();
  const status = useSelector(getMercadoPagoVerifyStatus);
  const data = useSelector(getMercadoPagoVerifyData);

  const verifyMercadoPago = useCallback(async () => {
    await dispatch(verifyMercadoPagoAction());
    dispatch(cleanVerifyMercadoPagoStatus());
    refreshInfoToSync(true);
  }, [dispatch, refreshInfoToSync]);

  const handleCleanVerifyMercadoPagoData = useCallback(() => {
    dispatch(cleanVerifyMercadoPagoData());
  }, [dispatch]);

  return {
    data,
    status,
    verifyMercadoPago,
    handleCleanVerifyMercadoPagoData,
  };
}

export default useVerifyMercadoPagoAlert;
