import { Box, Text } from '@nimbus-ds/components';

export function HistoricChargeSkeleton() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      padding="4"
      justifyContent="space-between"
      gap="2"
    >
      <Box display="flex" flexDirection="column" flex="1 1 95%" gap="1-5">
        <Text.Skeleton width="50%" />
        <Text.Skeleton width="20%" />
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 5%">
        <Text.Skeleton />
      </Box>
    </Box>
  );
}
