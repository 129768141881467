import { Fragment } from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { RecurrentPaymentData } from './components';
import RecurrentPaymentsStatusSkeleton from './Skeleton';
import { useRecurrentPaymentChargeData } from '../../hooks';

function RecurrentPaymentsStatus() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.recurrentPaymentsStatus',
  );
  const {
    recurrentPaymentCharges: recurrentPaymentChargeData,
    isError,
    isLoading,
    isSuccess,
    refreshRecurrentPaymentChargeData,
  } = useRecurrentPaymentChargeData();

  if (isLoading) {
    return <RecurrentPaymentsStatusSkeleton />;
  }

  const recurrentPaymentCharges = recurrentPaymentChargeData?.filter(
    ({ amountValue }) => amountValue && amountValue > 0,
  );

  if (isSuccess && !recurrentPaymentCharges?.length) return null;

  return (
    <Card padding="none">
      <Box padding="4" paddingBottom="none">
        <Title as="h3">{t('title')}</Title>
      </Box>
      <hr />
      {isError ? (
        <ErrorState
          title={t('error.title')}
          action={{
            children: t('error.retry'),
            onClick: refreshRecurrentPaymentChargeData,
          }}
        />
      ) : (
        recurrentPaymentCharges?.map((charge) => (
          <Fragment key={`${charge.concept}-${charge.externalReference}`}>
            <RecurrentPaymentData data={charge} />
            <hr />
          </Fragment>
        ))
      )}
    </Card>
  );
}

export default RecurrentPaymentsStatus;
