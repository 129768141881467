import { Table, Text } from '@nimbus-ds/components';
import { ProviderRateDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { PaymentMethodInlineLabel } from 'domains/Payments/PaymentProviders/components';
import { TRANSLATE_PAYMENT_PREFIX } from 'domains/Payments/PaymentProviders/components/utils';
import { useGetCptFee } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { PaymentMethodsHeader } from './PaymentMethodsHeader';
import { PercentFeeItem } from '../../PercentFeeItem';

interface PaymentProviderProps {
  providerId: string;
  rates: ProviderRateDto[];
}

export function PaymentMethodsTable({
  rates,
  providerId,
}: Readonly<PaymentProviderProps>) {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);
  const { getCptFee } = useGetCptFee();

  const getDayToWithdrawMoney = (daysToWithdrawMoney) => {
    if (daysToWithdrawMoney) {
      return t('daysToWithdrawMoney', { count: daysToWithdrawMoney });
    }
    return t('withdrawMoneyAtTheMoment');
  };

  const hasPaymentMethods = rates.length > 0;

  return (
    <div className="stratus--payment-provider-mobile-table">
      <Stack>
        {hasPaymentMethods && (
          <PaymentMethodInlineLabel
            paymentMethods={rates[0].paymentMethods}
            providerId={providerId}
          />
        )}
      </Stack>
      <Table>
        <PaymentMethodsHeader />
        <Table.Body>
          {rates.map(({ definitions, paymentMethods }, index) => (
            <>
              {index !== 0 && (
                <Stack>
                  <PaymentMethodInlineLabel
                    paymentMethods={paymentMethods}
                    providerId={providerId}
                  />
                </Stack>
              )}
              <Table.Row key={index}>
                <Table.Cell>
                  {definitions.map(({ daysToWithdrawMoney }, index) => (
                    <div key={index} className="payment-method-cell">
                      <Stack>
                        <Text>
                          {getDayToWithdrawMoney(daysToWithdrawMoney)}
                        </Text>
                      </Stack>
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {definitions.map((definition, index) => (
                    <div key={`item-${index}`} className="payment-method-cell">
                      <PercentFeeItem
                        definition={definition}
                        id={providerId}
                        key={index}
                      />
                    </div>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  <div className="payment-method-cell cpt-to-negotiate">
                    {definitions.map((_, index) => (
                      <Stack key={`item-${index}`} justify="flex-end">
                        {getCptFee({
                          providerId,
                          paymentMethods,
                        })}
                      </Stack>
                    ))}
                  </div>
                </Table.Cell>
              </Table.Row>
            </>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
