import { Button, Icon, Text } from '@nimbus-ds/components';
import { CheckCircleIcon, ChevronLeftIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { AddActiveShippingMethodsDetailInterface } from '../../AddActiveShippingMethodsDetailModal';
import { AboutAppCard, HowToInstallCard } from '../../components';

interface AddActiveShippingMethodsDetailModalProps {
  app: AddActiveShippingMethodsDetailInterface;
  onSave: () => void;
  onClose: () => void;
}

function AddGenericModal({
  app,
  onSave,
  onClose,
}: AddActiveShippingMethodsDetailModalProps) {
  const t = useTranslationShipping();

  return (
    <SideModal
      open
      onRemove={onClose}
      maxWidth={{ xs: '100%', md: '45%', lg: '540px' }}
      padding="base"
      paddingHeader="none"
      title={`${t(
        app.id
          ? 'deliveryMethods.activeShippingMethods.modal.detail.enableTitle'
          : 'deliveryMethods.activeShippingMethods.modal.detail.title',
        {
          app: app.name,
        },
      )}`}
      headerAction={
        <Button appearance="transparent" onClick={onClose}>
          <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          <Text fontWeight="bold" fontSize="base">
            {t('deliveryMethods.title')}
          </Text>
        </Button>
      }
      headerIcon={
        <Icon
          color="primary-textHigh"
          onClick={onSave}
          source={<CheckCircleIcon />}
        />
      }
    >
      <Stack column spacing="base" align="flex-end">
        <AboutAppCard
          name={app.name}
          description={app.description}
          tags={app.tags}
          links={app.links}
          id={app.appId}
        />
        {!app.id && <HowToInstallCard name={app.name} />}
        <CancelAndConfirmButtons
          confirmText={
            app.id
              ? t('deliveryMethods.activeShippingMethods.modal.enable')
              : t('deliveryMethods.activeShippingMethods.modal.continue')
          }
          onConfirm={onSave}
          onCancel={onClose}
        />
      </Stack>
    </SideModal>
  );
}

export default AddGenericModal;
