import { Box, Tag, Text } from '@nimbus-ds/components';
import {
  DesktopIcon,
  LocationIcon,
  MobileIcon,
  UserIcon,
} from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { UserSessionDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useTranslationAccount } from '../../../../../hooks';
import { useGetLocationDescription, useIsCurrentSession } from '../../../hooks';
import { ICON_SIZE } from '../../../settings';
import { DeleteUserSessionAction } from '../../DeleteUserSessionAction';
import { LastActivityText } from '../../LastActivityText';

interface UserSessionListMobileRowProps {
  userSession: UserSessionDto;
}

export function UserSessionsListMobileRow({
  userSession: { id, userAgent, location, user, lastActivity },
}: Readonly<UserSessionListMobileRowProps>) {
  const t = useTranslationAccount('sessionManagementPage.table.body');
  const isCurrentSession = useIsCurrentSession(id);
  const locationFullName = useGetLocationDescription(location);

  return (
    <DataList.Row backgroundColor="transparent" gap="1">
      <Stack justify="space-between" align="flex-start" spacing="none">
        <Stack column align="flex-start" gap="1">
          <Stack spacing="tight">
            <LocationIcon size={ICON_SIZE} />{' '}
            <Text fontWeight="bold">{locationFullName}</Text>
          </Stack>
          <Stack spacing="tight">
            <UserIcon size={ICON_SIZE} />{' '}
            <Text wordBreak="break-word">{user.email}</Text>
          </Stack>
          {userAgent ? (
            <Stack spacing="tight">
              {userAgent.isMobile ? (
                <MobileIcon size={ICON_SIZE} />
              ) : (
                <DesktopIcon size={ICON_SIZE} />
              )}{' '}
              <Text>
                {userAgent.operatingSystem} - {userAgent.browser}
              </Text>
            </Stack>
          ) : (
            <Stack spacing="tight">
              <DesktopIcon size={ICON_SIZE} />{' '}
              <Text>{t('withoutUserAgent.text')}</Text>
            </Stack>
          )}
          {!isCurrentSession && (
            <LastActivityText lastActivity={lastActivity} />
          )}
        </Stack>
        {isCurrentSession ? (
          <Box
            display="flex"
            justifyContent="flex-end"
            minWidth="100px"
            marginLeft="4"
          >
            <Tag appearance="primary">
              <Text fontSize="caption" color="primary-textLow" lineClamp={1}>
                {t('currentSessionTag')}
              </Text>
            </Tag>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            minWidth="45px"
            marginLeft="4"
          >
            <DeleteUserSessionAction id={id} />
          </Box>
        )}
      </Stack>
    </DataList.Row>
  );
}
