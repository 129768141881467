import { Tag } from '@nimbus-ds/components';
import { PaymentProviderDto } from '@tiendanube/common';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProvider';

type PaymentProviderStatusLabelProps = Pick<
  PaymentProviderDto,
  | 'isActive'
  | 'mustCompleteInstallation'
  | 'processingInstallation'
  | 'isNew'
  | 'isDeprecated'
>;

function PaymentProviderStatusLabel({
  isActive,
  mustCompleteInstallation,
  processingInstallation,
  isDeprecated,
  isNew,
}: Readonly<PaymentProviderStatusLabelProps>) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const getStatusTag = () => {
    if (processingInstallation) {
      return <Tag appearance="warning">{t('processing')}</Tag>;
    } else if (mustCompleteInstallation) {
      return <Tag appearance="warning">{t('pending')}</Tag>;
    } else if (isActive) {
      return <Tag appearance="success">{t('active')}</Tag>;
    }
    return <Tag>{t('inactive')}</Tag>;
  };

  return (
    <>
      {isNew && <Tag appearance="success">{t('new')}</Tag>}
      {isDeprecated && <Tag appearance="warning">{t('migrationPill')}</Tag>}
      {getStatusTag()}
    </>
  );
}

export default PaymentProviderStatusLabel;
