import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DevModeSchema } from './devModeSelectors';
import { getDevMode } from '../devModeServices';

export const getDevModeAction = createAsyncThunk(
  'devMode',
  async (payload?: number) => await getDevMode(payload),
);

const initialState: DevModeSchema = {
  data: null,
  status: 'idle',
};

const devModeSlice = createSlice({
  name: 'devMode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDevModeAction.fulfilled, (state, action) => {
      state.data = action.payload.response;
      state.status = 'success';
    });
    builder.addCase(getDevModeAction.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getDevModeAction.rejected, (state) => {
      state.status = 'error';
    });
  },
});

export const { reducer } = devModeSlice;
