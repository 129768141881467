import { VariantResponseDto } from '@tiendanube/common';
import { CATALOG_ALERT_NO_WEIGHT_AND_DIMENSIONS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';

const hasValue = (value: string | number) => Number(value) !== 0;

interface UseProductVariantsResultseShowNoWeightAndDimensionsProps
  extends Pick<VariantResponseDto, 'weight' | 'depth' | 'width' | 'height'> {
  variantId: string;
}

function useShowNoWeightAndDimension({
  variantId,
  weight,
  depth,
  width,
  height,
}: UseProductVariantsResultseShowNoWeightAndDimensionsProps) {
  const [hasAlertNoWeightAndDimension] = useHasTags([
    CATALOG_ALERT_NO_WEIGHT_AND_DIMENSIONS,
  ]);
  const hasMessurements =
    hasValue(weight) && hasValue(depth) && hasValue(width) && hasValue(height);

  return hasAlertNoWeightAndDimension && !!variantId && !hasMessurements;
}

export default useShowNoWeightAndDimension;
