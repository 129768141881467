import { Box, Alert } from '@nimbus-ds/components';
import { Title } from '@tiendanube/components';
import { Layout, RemarksCard, useResponsive } from 'commons/components';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import CustomerDataCard from 'domains/Orders/components/CustomerDataCard';
import DateAndDevice from 'domains/Orders/components/DateAndDevice';
import DetailsHeader from 'domains/Orders/components/DetailsHeader';
import OrderSummaryCard from 'domains/Orders/components/OrderSummaryCard';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { DraftOrderDetail } from './types';
import DeliveryInfoCard from '../DeliveryInfoCard';
import PaymentCard from '../PaymentCard';
import PaymentLinkCard from '../PaymentLinkCard';

interface DraftOrderDetailsProps {
  draftOrderDetails: DraftOrderDetail;
  onConfirmDraftOrder: (id: string) => Promise<void>;
}

function DraftOrderDetails({
  draftOrderDetails,
  onConfirmDraftOrder,
}: DraftOrderDetailsProps): JSX.Element {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();
  const handleConfirmDrafOrder = async () => {
    await onConfirmDraftOrder(draftOrderDetails.id);
  };

  const { name } = useHeaderMenuData();

  const subject = t('draftOrders.customerDataCard.mailSubject', {
    storeName: name,
  });

  const body = t('draftOrders.customerDataCard.mailBody', {
    clientName: draftOrderDetails.contactName,
    link: draftOrderDetails.checkoutUrl,
  });

  const waText = t('draftOrders.customerDataCard.waText', {
    clientName: draftOrderDetails.contactName,
    link: draftOrderDetails.checkoutUrl,
    interpolation: { escapeValue: false },
  });

  const orderHasLineItems = draftOrderDetails.products.length > 0;

  return (
    <>
      {!orderHasLineItems && (
        <Box marginTop="4">
          <Alert appearance="warning">
            {t('draftOrders.alert.noLineItems')}
          </Alert>
        </Box>
      )}
      <Layout
        mainContent
        left={
          <>
            <DetailsHeader
              title={t('draftOrders.detail.mainTitle')}
              right={
                <DateAndDevice
                  date={draftOrderDetails.date}
                  origin={draftOrderDetails.origin}
                  orderOrigin={draftOrderDetails.orderOrigin}
                />
              }
            />
            {orderHasLineItems && (
              <PaymentLinkCard checkoutUrl={draftOrderDetails.checkoutUrl} />
            )}
            <OrderSummaryCard
              amounts={draftOrderDetails.amounts}
              products={draftOrderDetails.products}
              currency={draftOrderDetails.currency}
            />
            <PaymentCard
              onClickButton={handleConfirmDrafOrder}
              orderHasLineItems={orderHasLineItems}
            />
            {!!draftOrderDetails.remarks && (
              <RemarksCard
                title={t('draftOrders.remarksCard.title')}
                errorMessage={t('draftOrders.remarksCard.error')}
                remarks={draftOrderDetails.remarks}
                readOnly
              />
            )}
          </>
        }
        right={
          <>
            {isDesktop && (
              <Title type="h2">{`${t('draftOrders.detail.asideTitle')}`}</Title>
            )}
            <CustomerDataCard
              id={draftOrderDetails.customer.id}
              name={draftOrderDetails.contactName}
              email={{
                to: draftOrderDetails.contactEmail,
                subject,
                body,
              }}
              whatsapp={
                draftOrderDetails.contactPhone
                  ? {
                      phoneNumber: draftOrderDetails.contactPhone,
                      message: waText,
                    }
                  : undefined
              }
              identification={
                draftOrderDetails.contactIdentification
                  ? draftOrderDetails.contactIdentification
                  : undefined
              }
            />
            {draftOrderDetails.delivery && (
              <DeliveryInfoCard deliveryData={draftOrderDetails.delivery} />
            )}
          </>
        }
      />
    </>
  );
}

export default DraftOrderDetails;
