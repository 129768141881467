import { useCallback, useEffect } from 'react';

export const useScrollable = ({
  containerRef: { current },
  data,
  currentPage,
  isLoading,
  isFetching,
  incrementPage,
}) => {
  const handleScroll = useCallback(() => {
    const scrollableElement = current?.parentElement.parentElement as Element;
    if (!scrollableElement) return;
    if (data?.pagination.totalPages === currentPage) {
      scrollableElement.removeEventListener('scrollend', handleScroll);
      return;
    }

    const isBottom =
      scrollableElement.scrollTop + scrollableElement.clientHeight ===
      scrollableElement.scrollHeight;

    if (!isBottom || isLoading || isFetching) return;
    incrementPage();
  }, [current, data, currentPage, incrementPage, isLoading, isFetching]);

  useEffect(() => {
    const scrollableElement = current?.parentElement.parentElement;
    scrollableElement?.addEventListener('scrollend', handleScroll);
    return () =>
      scrollableElement?.removeEventListener('scrollend', handleScroll);
  }, [current, currentPage, handleScroll]);
};
