import { useSelector } from 'react-redux';
import {
  PaymentProviderDto,
  UpdateBasePaymentProviderSettingsRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { invokeEventPayments } from 'domains/Payments/tracking';
import { useGetNativePaymentProvider } from '../';
import {
  getInstalledPaymentProviderUpdateStatus,
  updatePaymentProviderSettingsAction,
  cleanUpdateStatusAction,
} from '../../paymentProvidersSlice';

interface UseNativePaymentProviderSettingsResult<T extends PaymentProviderDto> {
  isLoading: boolean;
  isError: boolean;
  updateStatus: StatusType;
  paymentProvider?: T;
  fetch: () => void;
  updatePaymentProviderSettings: (
    payload: UpdateBasePaymentProviderSettingsRequestDto & { appId: string },
  ) => void;
}

function useNativePaymentProviderSettings<T extends PaymentProviderDto>(
  id: string,
): UseNativePaymentProviderSettingsResult<T> {
  const dispatch = useAppDispatch();

  const { isLoading, isError, paymentProvider, fetch } =
    useGetNativePaymentProvider<T>(id);
  const updateStatus = useSelector(getInstalledPaymentProviderUpdateStatus);

  const updatePaymentProviderSettings = async (payload) => {
    await dispatch(updatePaymentProviderSettingsAction(payload));
    dispatch(cleanUpdateStatusAction());
    invokeEventPayments('updatedProvider', id);
  };

  return {
    isLoading,
    isError,
    updateStatus,
    paymentProvider,
    fetch,
    updatePaymentProviderSettings,
  };
}

export default useNativePaymentProviderSettings;
