import { ChangeEvent } from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { FulfillmentOrderAssignedLocationDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DocumentType } from '../../constants';

interface DocumentLocationFormProps {
  locationId?: string;
  type: DocumentType;
  locations: FulfillmentOrderAssignedLocationDto[];
  handleSelectLocation: (e: ChangeEvent<HTMLSelectElement>) => void;
}

function DocumentLocationForm({
  locationId,
  type,
  locations,
  handleSelectLocation,
}: Readonly<DocumentLocationFormProps>) {
  const t = useTranslationFulfillmentOrders('printManager.locationSelect');

  return (
    <Stack column align="stretch">
      <FormField.Select
        label={t('label')}
        helpText={t('helpText.' + type)}
        showHelpText
        name="locationId"
        id="locationId"
        onChange={handleSelectLocation}
      >
        <Select.Option value="" label={t('placeholder')} />
        {locations.map(({ id, name }) => (
          <Select.Option
            key={id}
            value={id}
            label={name}
            selected={id === locationId}
          />
        ))}
      </FormField.Select>
    </Stack>
  );
}

export default DocumentLocationForm;
