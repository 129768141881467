import { ReactElement } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';

interface ShowByCountryProps {
  children: ReactElement;
  allowedCountries?: Country[];
  excludeCountries?: Country[];
}

function ShowByCountry({
  allowedCountries = [],
  excludeCountries = [],
  children,
}: ShowByCountryProps) {
  const country = useGetCountry();
  const isAllowedCountry =
    allowedCountries.length === 0 || allowedCountries.includes(country);
  const isExcludedCountry = excludeCountries.includes(country);

  return isAllowedCountry && !isExcludedCountry ? children : null;
}

export default ShowByCountry;
