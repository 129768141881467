import { FunctionComponent } from 'react';
import { Card, Title, Tag, Box, Text } from '@nimbus-ds/components';
import {
  FulfillmentOrdersResponseDto,
  OrderDetailsProductDto,
  OrderDetailsResponseDto,
  OrderFulfillRequestDto,
} from '@tiendanube/common';
import { DataList, InterfaceLabel } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import OrderSummaryProducts from 'domains/Orders/components/OrderSummaryCard/OrderSummaryProducts';
import OrderSummaryDelivery from '../OrderSummaryDelivery';
import './CardOrderAndFulfillment.scss';

interface CardOrderAndFulfillmentProps {
  order: OrderDetailsResponseDto;
  headerLabel: InterfaceLabel;
  packOrder: () => void;
  fulfillOrder: (orders: OrderFulfillRequestDto) => void;
  isLoading: boolean;
  products: OrderDetailsProductDto[];
  title: string;
  number?: number;
  fulfillmentOrder?: FulfillmentOrdersResponseDto;
}

function CardOrderAndFulfillment({
  order: { currency },
  order,
  headerLabel,
  packOrder,
  fulfillOrder,
  isLoading,
  products,
  number,
  title,
  fulfillmentOrder,
}: CardOrderAndFulfillmentProps): JSX.Element {
  const Icon = headerLabel.icon as unknown as FunctionComponent;
  const apearances = {
    secondary: 'neutral',
    transparent: 'neutral',
    primary: 'primary',
    success: 'success',
    default: 'neutral',
    warning: 'warning',
    danger: 'danger',
  };
  const { isDesktop } = useResponsive();

  return (
    <Card>
      <Card.Header>
        <Box display="block">
          <Box display="flex" justifyContent="space-between">
            <Box gap="2" display="flex" alignItems="center">
              {number !== undefined && <Tag> {`# ${number}`} </Tag>}
              {isDesktop ? (
                fulfillmentOrder ? (
                  <Text
                    color="neutral-textHigh"
                    fontWeight="regular"
                    fontSize="highlight"
                  >
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </Text>
                ) : (
                  <Title as="h3">
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </Title>
                )
              ) : (
                !fulfillmentOrder && (
                  <Title as="h3">
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </Title>
                )
              )}
            </Box>
            <Tag appearance={apearances[headerLabel?.appearance ?? 'neutral']}>
              <Icon />
              {headerLabel.label}
            </Tag>
          </Box>
          <Box paddingTop="2">
            {!isDesktop && fulfillmentOrder && (
              <Text
                color="neutral-textHigh"
                fontWeight="regular"
                fontSize="highlight"
              >
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Text>
            )}
          </Box>
        </Box>
      </Card.Header>
      <DataList ruled={false} spacing="base">
        <div className="productsList">
          <OrderSummaryProducts products={products} currency={currency} />
        </div>
        <OrderSummaryDelivery
          orderDetails={order}
          packOrder={packOrder}
          fulfillOrder={fulfillOrder}
          isLoading={isLoading}
          fulfillmentOrder={fulfillmentOrder}
        />
      </DataList>
    </Card>
  );
}

export default CardOrderAndFulfillment;
