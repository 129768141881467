import { Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { useToastStatus } from 'commons/hooks';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useEmailVerifiedAlert from './useEmailVerifiedAlert/useEmailVerifiedAlert';

export function EmailVerifiedSuccessAlert() {
  const { status } = useEmailVerifiedAlert();
  const { id } = useGetUserInfo();
  const t = useTranslationDashboard('email-confirmed');

  useToastStatus({
    status,
    success: t('success'),
    error: t('error'),
  });

  return (
    <AlertSessionStorage
      keyName={`user-confirmed-${id}`}
      appearance="success"
      title={t('title')}
    >
      <Text>{t('description')}</Text>
    </AlertSessionStorage>
  );
}

export default EmailVerifiedSuccessAlert;
