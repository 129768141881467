import { memo } from 'react';
import { EmptySearch, ErrorState } from '@tiendanube/components';
import VirtualDataList from 'commons/components/VirtualDataList';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import InventoryItemList from './InventoryItemList';
import InventoryResultsSkeleton from './InventoryResultsSkeleton';
import { useInventory } from '../../../hooks';

function InventoryResults(): JSX.Element {
  const t = useTranslationCatalog();

  const {
    isLoading,
    isSuccess,
    isError: isErrorInventory,
    productIds,
    filters,
    hasFilters,
    loadInventory,
    setNextPage,
    resetPage,
  } = useInventory();

  const { isError: isErrorLocations, fetchLocations } = useLocations();

  const shouldShowSpinner = isLoading && productIds.length !== 0;

  const locationId = filters.locationId !== '' ? filters.locationId : undefined;

  if (isLoading && productIds.length === 0) {
    return <InventoryResultsSkeleton />;
  }

  if (isErrorLocations || (isErrorInventory && productIds.length === 0)) {
    return (
      <ErrorState
        title={t('products.errorResults.title')}
        action={{
          children: t('products.errorResults.action'),
          onClick: isErrorLocations ? fetchLocations : loadInventory,
        }}
      />
    );
  }

  if (isSuccess && productIds.length === 0 && hasFilters) {
    return (
      <EmptySearch
        text={t('products.withoutResults.text')}
        title={t('products.withoutResults.title')}
      />
    );
  }

  return (
    <VirtualDataList
      onRefresh={resetPage}
      data={productIds}
      endReached={setNextPage}
      itemContent={(index, id) => (
        <InventoryItemList key={index} id={id} locationId={locationId} />
      )}
      showFooter={shouldShowSpinner}
    />
  );
}

export default memo(InventoryResults);
