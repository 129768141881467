import { ChangeEvent, useEffect } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import useLocationFormByCountry from 'domains/Shipping/Locations/hooks/useLocationFormByCountry/useLocationFormByCountry';
import Skeleton from './Skeleton';

export interface AddressCardValuesInterface {
  id?: string;
  zipcode: string;
  country: string;
  province?: string;
  city: string;
  locality: string | null;
  street: string;
  number: string;
  floor: string;
}
export interface AddressCardErrorsInterface {
  zipcode: string;
  city: string;
  locality: string;
  street: string;
  number: string;
}

export interface AddressCardDisabledInterface {
  locality: boolean;
  street: boolean;
  city: boolean;
  province: boolean;
}
interface AddressCardProps {
  values: AddressCardValuesInterface;
  errors: Partial<AddressCardErrorsInterface>;
  countries: { value: string; label: string }[];
  provinces: { value: string; label: string }[];
  cities: { value: string; label: string }[];
  loadingCountries: boolean;
  loadingProvinces: boolean;
  loadingCities: boolean;
  fetchCountries: () => void;
  fetchProvinces: (country: string) => Promise<string>;
  fetchCities: (countryCode: string, provinceCode: string) => Promise<string>;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldValue: (name: string, value: string) => void;
  clearFields: () => void;
}

function AddressCard({
  values,
  errors,
  countries,
  provinces,
  cities,
  loadingCountries,
  loadingProvinces,
  loadingCities,
  fetchCountries,
  fetchProvinces,
  fetchCities,
  onChange,
  setFieldValue,
  clearFields,
}: AddressCardProps): JSX.Element {
  const storeCountry = useGetCountry();
  const { component: LocationForm } = useLocationFormByCountry(values);

  const handleOnChange = (e) => {
    onChange(e);
  };

  useEffect(() => {
    if (storeCountry !== Country.BR) {
      fetchCountries();
    }
  }, [fetchCountries, storeCountry]);

  return (
    <LocationForm
      onChange={handleOnChange}
      values={values}
      errors={errors}
      countries={countries}
      provinces={provinces}
      cities={cities}
      storeCountry={storeCountry}
      loadingCountries={loadingCountries}
      loadingProvinces={loadingProvinces}
      loadingCities={loadingCities}
      fetchCountries={fetchCountries}
      fetchProvinces={fetchProvinces}
      fetchCities={fetchCities}
      setFieldValue={setFieldValue}
      clearFields={clearFields}
    />
  );
}

AddressCard.Skeleton = Skeleton;

export default AddressCard;
