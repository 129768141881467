import { Box, Icon } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import './ARecordStatus.scss';

interface ARecordStatusProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export function ARecordStatus({ left, right }: Readonly<ARecordStatusProps>) {
  return (
    <Box
      display="grid"
      borderBottomWidth="1"
      borderStyle="solid"
      borderColor="neutral-surfaceDisabled"
      paddingBottom="2"
      paddingTop="2"
      gridTemplateColumns="repeat(3, 1fr)"
      alignItems="center"
    >
      <div className="stratus--a-record-status-left">{left}</div>
      <div className="stratus--a-record-status-center">
        <Icon color="neutral-textHigh" source={<ChevronRightIcon />} />
      </div>
      <div className="stratus--a-record-status-right">{right}</div>
    </Box>
  );
}
