import { useCallback } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import useToast from './useToast';

function useClipboard(): (value: string, msg: string) => Promise<void> {
  const { addToast } = useToast();
  const copyToClipborad = useCallback(
    async (value: string, msg: string): Promise<void> => {
      await Clipboard.write({
        string: value,
      });
      addToast({
        label: msg,
        appearance: 'success',
      });
    },
    [addToast],
  );

  return copyToClipborad;
}

export default useClipboard;
