import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { TransactionFeeDataInterface } from '../types';

export const getCheckoutState = (
  state: RootStateType,
): CombinedState<TransactionFeeDataInterface> => state.billing.transactionFees;

export const getTransactionFeeToPayStatus = createSimpleSelector(
  [getCheckoutState],
  ({ transactionFeeToPay }) => transactionFeeToPay.status,
);

export const fetchTransactionFeeToPaySelector = createSimpleSelector(
  [getCheckoutState],
  ({ transactionFeeToPay }) => transactionFeeToPay.data,
);

export const getPayOrderStatus = createSimpleSelector(
  [getCheckoutState],
  ({ payOrder }) => payOrder.status,
);
