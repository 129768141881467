import {
  Box,
  Card,
  Checkbox,
  Icon,
  Link,
  Text,
  Title,
} from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { Link as LinkRoute } from 'react-router-dom';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CUSTOMERS_ROUTES } from 'domains/Customers/customersRoutes';
import { SettingsCheckoutFormProps } from '../../types';

export function RestrictPurchasesCard({
  values: { customerAccounts, hidePrices },
  onChange,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.restrictPurchasesCard',
  );

  const handleChangeRadioToggle = (e) => {
    onChange({
      name: 'customerAccounts',
      value: e.target.value,
    });
    onChange({
      name: 'hidePrices',
      value: false,
    });
  };

  const handleChangeCheckToggle = ({ target: { name, checked } }) => {
    onChange({ name, value: checked });
  };

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title fontSize="h3">{t('title')}</Title>
        <Text>{t('description')}</Text>
      </Card.Header>

      <Card.Body>
        <InteractiveList onChange={handleChangeRadioToggle}>
          <InteractiveList.RadioItem
            title={t('radioOptional')}
            radio={{
              name: 'customerAccounts',
              value: 'optional',
              checked: customerAccounts === 'optional',
            }}
          />
          <InteractiveList.RadioItem
            title={t('radioMandatory')}
            radio={{
              name: 'customerAccounts',
              value: 'mandatory',
              checked: customerAccounts === 'mandatory',
            }}
          />
        </InteractiveList>
      </Card.Body>
      {customerAccounts === 'mandatory' && (
        <Card.Footer padding="base">
          <Box display="flex" flexDirection="column" width="100%" gap="4">
            <Box
              width="100%"
              backgroundColor="neutral-surfaceDisabled"
              padding="1"
              borderRadius="2"
              paddingX="2"
            >
              <Text>
                <Trans
                  t={t}
                  i18nKey="labelText"
                  components={{
                    customLink: (
                      <Link
                        as={LinkRoute}
                        href={t('linkHref')}
                        appearance="primary"
                        to={CUSTOMERS_ROUTES.customerList}
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Box>
            <Checkbox
              name="hidePrices"
              label={t('checkboxLabel')}
              checked={hidePrices}
              onChange={handleChangeCheckToggle}
            />
            <Link
              appearance="primary"
              as="a"
              href={t('externalLinkHref')}
              target="_blank"
            >
              {t('externalLinkText')}
              <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
            </Link>
          </Box>
        </Card.Footer>
      )}
    </Card>
  );
}
