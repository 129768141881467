import { useEffect, useState } from 'react';
import { ErrorState } from '@tiendanube/components';
import { useToastStatus } from 'commons/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ArgentineFiscalDataForm, CuitFiscalData } from './components';
import useArgentineFiscalData from './useArgentineFiscalData';

export interface TaxDataInterface {
  dni: string;
  businessName: string;
  address: string;
}

export interface CuitInterface {
  cuit: string;
}

function ArgentineFiscalData() {
  const {
    cuitForm,
    dniForm,
    validatedCuit,
    fiscalDataStatus,
    submitFiscalDataStatus,
    validatedCuitStatus,
    submitFiscalDataFromCuit,
    refreshInvoiceInfo,
    invoiceInfoExists,
    fiscalDataType,
    editCuit,
    invoiceInfo,
  } = useArgentineFiscalData();

  const [showExistingCuitData, setShowExistingCuitData] = useState(false);
  useEffect(() => {
    if (invoiceInfoExists && invoiceInfo.numberIdType === 'CUIT')
      setShowExistingCuitData(true);
  }, [invoiceInfoExists, invoiceInfo]);

  const t = useTranslationBilling();

  useToastStatus({
    status: submitFiscalDataStatus,
    error: t('invoicesInfoPage.errors.submit'),
    success: t('invoicesInfoPage.success'),
  });

  useToastStatus({
    status: validatedCuitStatus,
    error: t('invoicesInfoPage.errors.validCuit'),
  });

  if (showExistingCuitData)
    return (
      <CuitFiscalData
        taxData={invoiceInfo}
        loading={submitFiscalDataStatus === 'loading'}
        onEdit={() => setShowExistingCuitData(false)}
      />
    );
  if (validatedCuitStatus === 'success')
    return (
      <CuitFiscalData
        taxData={validatedCuit}
        loading={submitFiscalDataStatus === 'loading'}
        onSave={submitFiscalDataFromCuit}
        onEdit={editCuit}
      />
    );
  if (fiscalDataStatus === 'loading')
    return <ArgentineFiscalDataForm.Skeleton />;
  if (fiscalDataStatus === 'error')
    return (
      <ErrorState
        title={t('invoicesInfoPage.errors.internalError')}
        action={{
          onClick: refreshInvoiceInfo,
          children: t('invoicesInfoPage.errors.retry'),
        }}
      />
    );

  return (
    <ArgentineFiscalDataForm
      key={fiscalDataType}
      cuitForm={cuitForm}
      dniForm={dniForm}
      loading={
        validatedCuitStatus === 'loading' ||
        submitFiscalDataStatus === 'loading'
      }
      fiscalDataType={fiscalDataType}
    />
  );
}

export default ArgentineFiscalData;
