import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import {
  clearStatus as clearStatusAction,
  getProductMetafieldsStatus,
  productsBulkAssignmentMetafieldV2,
} from 'domains/Catalog/Products/ProductMetafields';
import { ProductsFiltersInterface } from 'domains/Catalog/Products/productsServices';
import { MetafieldSelectedInterface } from 'domains/Metafields/types';

interface BulkMetafieldsAssignmentProps {
  selectAll: boolean;
  totalCount: number;
  productsId: number[];
  filters: ProductsFiltersInterface;
  metafields: MetafieldSelectedInterface[];
}

interface BulkMetafieldsAssignmentResult {
  handleBulkMetafieldsAssignment: (
    bulkData: BulkMetafieldsAssignmentProps,
  ) => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  clearStatus: () => void;
}

const MAX_PRODUCTS_PER_PAGE = 20;

function useBulkMetafieldsAssignment(): BulkMetafieldsAssignmentResult {
  const dispatch = useAppDispatch();
  const status = useSelector(getProductMetafieldsStatus);

  const clearStatus = () => {
    dispatch(clearStatusAction());
  };

  const handleBulkMetafieldsAssignmentV2 = useCallback(
    async ({
      selectAll,
      totalCount,
      productsId,
      filters,
      metafields,
    }: BulkMetafieldsAssignmentProps) => {
      const isProductsIdEmpty = selectAll && totalCount > MAX_PRODUCTS_PER_PAGE;
      dispatch(
        productsBulkAssignmentMetafieldV2({
          productsId: isProductsIdEmpty ? [] : productsId,
          filters: isProductsIdEmpty
            ? filters
            : ({} as ProductsFiltersInterface),
          metafields,
        }),
      );
    },
    [dispatch],
  );

  return {
    handleBulkMetafieldsAssignment: handleBulkMetafieldsAssignmentV2,
    ...convertStatusTypeToObject(status),
    clearStatus,
  };
}

export default useBulkMetafieldsAssignment;
