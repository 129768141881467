// Domains
export const DOMAINS_ADD_DOMAIN_BTN = 'domains_add_domain_btn';
export const DOMAINS_REACTIVATE_DOMAIN_LINK = 'domains_reactivate_domain_link';

// Catalog
export const CATALOG_INSTALL_DROPI_APP = 'catalog_install_dropi_app';
export const CATALOG_SHOW_FILTERS = 'catalog_show_filters';
export const CATALOG_SEARCH_PRODUCTS = 'catalog_search_products';
export const CATALOG_METAFIELD_FILTERS_DROPDOWN =
  'catalog_metafield_filters_dropdown';
export const CATALOG_METAFIELD_FILTERS_CALLOUT =
  'catalog_metafield_filters_callout';
export const CATALOG_BULK_ACTIONS = 'catalog_bulk_actions';
export const CATALOG_EXPORT_PRODUCTS = 'catalog_export_products';
export const CATALOG_IMPORT_PRODUCTS = 'catalog_import_products';

// Orders
export const ORDERS_EXPORT_ORDERS = 'orders_export_orders';
export const ORDERS_SHOW_FILTERS = 'orders_show_filters';
export const ORDERS_SEARCH_ORDERS = 'orders_search_orders';
export const ORDERS_BULK_ACTIONS = 'orders_bulk_actions';
export const ORDERS_METAFIELD_FILTERS_DROPDOWN =
  'orders_metafield_filters_dropdown';
export const ORDERS_METAFIELD_FILTERS_CALLOUT =
  'orders_metafield_filters_callout';

// Customers
export const CUSTOMERS_SHOW_FILTERS = 'customers_show_filters';
export const CUSTOMERS_SEARCH_CUSTOMERS = 'customers_search_customers';
export const CUSTOMERS_EXPORT_CUSTOMERS = 'customers_export_customers';

// Shipping
export const SHIPPING_ADD_LOCATION = 'shipping_add_location';
export const SHIPPING_INSTALL_APP = 'shipping_install_app';
export const SHIPPING_ADD_CUSTOM_METHOD = 'shipping_add_custom_method';
export const SHIPPING_ADD_INTERNATIONAL_METHOD =
  'shipping_add_international_method';

// Users
export const USERS_CREATE_USER = 'users_create_user';
export const USERS_MANAGE_PERMISSIONS = 'users_manage_permissions';

// Support
export const SUPPORT_WHATSAPP = 'support_whatsapp';
export const SUPPORT_PHONECALL = 'support_phonecall';

// Preferences
export const PREFERENCES_GOOGLE_TAG_MANAGER = 'preferences_google_tag_manager';

// Statistics
export const STATS_FILTERS = 'stats_filters';
export const STATS_GENERAL_PAGE = 'stats_general_page';
export const STATS_PAYMENTS_PAGE = 'stats_payments_page';
export const STATS_SHIPPING_PAGE = 'stats_shipping_page';
export const STATS_PRODUCTS_PAGE = 'stats_products_page';
export const STATS_TRAFFIC_PAGE = 'stats_traffic_page';

// Themes
export const THEMES = 'themes';
export const INSTA_THEME = 'insta-theme';

// Paymenys
export const PAYMENT_SOFTBLOCK = 'payment-softblock';
export const CUSTOM_THEME = 'online_open_ftp_callout';
// Payments
export const PAYMENTS_ADD_CUSTOM_METHOD_TO_AGREE =
  'payment_add_custom_method_a_convenir';
export const PAYMENTS_ADD_CUSTOM_METHOD_TRANSFERENCIA_PIX_EFECTIVO =
  'payment_add_custom_method_transferencia_pix_efectivo';
export const PAYMENT_INSTALL_APP = 'payment_install_app';
