import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { fetchTotalOpenedOrders, getTotalResultsOpened } from '../ordersSlice';

export function useGetTotalOpenedOrders() {
  const dispatch = useAppDispatch();
  const totalOpenedOrders = useSelector(getTotalResultsOpened);

  const getTotalOpenedOrders = useCallback(() => {
    dispatch(fetchTotalOpenedOrders());
  }, [dispatch]);

  return {
    totalOpenedOrders,
    getTotalOpenedOrders,
  };
}
