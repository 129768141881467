import { useEffect } from 'react';
import { Table, Text } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus, useResponsive } from 'commons/components';
import { useAbandonedCarts } from 'domains/Orders/AbandonedCart/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  AbandonedCartsListDesktopRow,
  AbandonedCartsListDesktopSkeleton,
} from './components';

function AbandonedCartsListDesktop() {
  const t = useTranslationOrders();
  const { scrollToTop } = useLayoutScroll();
  const { isDesktop } = useResponsive();

  const {
    abandonedCartsList,
    isLoading,
    isEmpty,
    isError,
    pagination,
    goToPage,
  } = useAbandonedCarts();

  useEffect(() => {
    if (isDesktop && pagination.currentPage > 1) {
      goToPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  const shouldShowTable = !isLoading && !isEmpty && !isError;

  return (
    <div className="stratus--flashing-table-color">
      <Stack column align="stretch">
        <Table>
          <Table.Head>
            <Table.Cell width="15%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.cart')}
              </Text>
            </Table.Cell>
            <Table.Cell width="10%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.date')}
              </Text>
            </Table.Cell>
            <Table.Cell width="10%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.total')}
              </Text>
            </Table.Cell>
            <Table.Cell width="20%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.customer')}
              </Text>
            </Table.Cell>
            <Table.Cell width="30%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.email')}
              </Text>
            </Table.Cell>
            <Table.Cell width="20%">
              <Text
                textAlign="left"
                fontWeight="medium"
                color="primary-textHigh"
              >
                {t('abandonedCarts.table.header.action')}
              </Text>
            </Table.Cell>
          </Table.Head>
          {isLoading && !isEmpty && !isError && (
            <AbandonedCartsListDesktopSkeleton />
          )}
          {shouldShowTable &&
            abandonedCartsList.map((id) => (
              <AbandonedCartsListDesktopRow key={id} id={id} />
            ))}
        </Table>
        {shouldShowTable && (
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults || 0}
            itemName={t('abandonedCarts.table.pagination')}
            page={pagination.currentPage}
            perPage={pagination.perPage}
            onPageSelect={handleSelectPage}
          />
        )}
      </Stack>
    </div>
  );
}

export default AbandonedCartsListDesktop;
