import { IconButton, Popover, Tooltip } from '@nimbus-ds/components';
import { EditIcon, EllipsisIcon, StarIcon, TrashIcon } from '@nimbus-ds/icons';
import { DataList, MenuButton } from '@nimbus-ds/patterns';
import { LocationResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { Stack } from 'commons/components';
import LocationItem from 'domains/Shipping/Locations/pages/LocationListPage/components/LocationItem';
import { trackingShippingEditDistributionCenter } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationsListRowMobileSkeleton from './Skeleton';

interface LocationsListRowProps {
  location: LocationResponseDto;
  openSetMainModal: (locationId: any) => void;
  openDeleteModal: (locationId: any) => void;
}

function LocationsListRow({
  location,
  openSetMainModal,
  openDeleteModal,
}: Readonly<LocationsListRowProps>): JSX.Element | null {
  const { goTo } = useNavegate();
  const t = useTranslationShipping();
  const { Row } = DataList;
  const handleClick = () => {
    trackingShippingEditDistributionCenter('secondary', location.id);
    goTo(`/settings/locations/${location.id}`);
  };

  return (
    <Row id={location.id} key={location.id} topDivider paddingLeft="none">
      <Stack spacing="loose" justify="space-between">
        <Stack.Item />
        <LocationItem location={location} />
        <Stack.Item>
          {location.main && (
            <Tooltip content={t('locations.card.edit')}>
              <IconButton
                size="2rem"
                source={<EditIcon size="small" />}
                onClick={handleClick}
              />
            </Tooltip>
          )}
          {!location.main && (
            <Popover
              content={
                <Stack column spacing="tight" align="flex-start">
                  <MenuButton
                    label={t('locations.card.edit')}
                    startIcon={EditIcon}
                    onClick={handleClick}
                  />
                  <MenuButton
                    label={t('locations.card.setAsMain')}
                    startIcon={StarIcon}
                    onClick={() => openSetMainModal(location.id)}
                  />
                  <MenuButton
                    label={t('locations.card.delete')}
                    startIcon={TrashIcon}
                    onClick={() => openDeleteModal(location.id)}
                  />
                </Stack>
              }
              enabledHover
              position="left"
            >
              <IconButton size="2rem" source={<EllipsisIcon size="small" />} />
            </Popover>
          )}
        </Stack.Item>
      </Stack>
    </Row>
  );
}

LocationsListRow.Skeleton = LocationsListRowMobileSkeleton;

export default LocationsListRow;
