import { Box, Text } from '@nimbus-ds/components';
import { ImageItemStratusSkeleton, useResponsive } from 'commons/components';

function ProductItemSkeleton() {
  const { isMobile } = useResponsive();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box flex={isMobile ? '1' : '2'}>
        <ImageItemStratusSkeleton imageWidth="56px" />
      </Box>
      <Box
        display="flex"
        flex={isMobile ? '2' : '1'}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        paddingX="4"
        paddingTop="1"
      >
        <Box paddingRight="2">
          <Text.Skeleton width="50px" height="15px" />
        </Box>
        <Text.Skeleton width="50px" height="15px" />
      </Box>
    </Box>
  );
}

export default ProductItemSkeleton;
