import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './salesChannels.es-AR.json';
import esMX from './salesChannels.es-MX.json';
import ptBR from './salesChannels.pt-BR.json';

const salesChannelsTranslations: DomainTranslations = {
  name: 'salesChannels',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default salesChannelsTranslations;
