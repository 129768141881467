import { useState } from 'react';
import { Checkbox, Text, Title } from '@nimbus-ds/components';
import { ThemesConfigImagesResponseDto } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import HelpLink from 'App/HelpLink';
import { toStatusType } from 'App/rtk';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  Stack,
} from 'commons/components';
import { HELP_LINKS_THEMES_LOGO } from 'commons/constants';
import { useForm, useToastStatus, useTranslationLanguage } from 'commons/hooks';
import { useEditImagesMutation } from 'domains/Online/Themes/themesApi';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { IdentityImage } from './IdentityImage';
import { getEditImagesPayload } from './utils';

interface IdentityModalProps {
  images: ThemesConfigImagesResponseDto;
  ogImageStatus: 'enabled' | 'disabled';
  showModal: boolean;
  onClose: () => void;
}

export function IdentityModal({
  images,
  ogImageStatus,
  showModal,
  onClose,
}: Readonly<IdentityModalProps>) {
  const t = useTranslationOnline('themesPage.identity.identityModal');
  const language = useTranslationLanguage();
  const [editImages, { status: editImagesStatus }] = useEditImagesMutation();
  const [isOgLogoEnabled, setIsOgLogoEnabled] = useState(
    ogImageStatus === 'enabled',
  );
  const { values, handleOnSubmit, resetValues, setFieldValue, isDirty } =
    useForm<ThemesConfigImagesResponseDto>({
      initialValues: images,
      onSubmit: async () => {
        const newOgImageStatus = isOgLogoEnabled ? 'enabled' : 'disabled';
        await editImages(
          getEditImagesPayload(images, ogImageStatus, values, newOgImageStatus),
        );
        onClose();
      },
    });

  const status = toStatusType(editImagesStatus);

  useToastStatus({
    status: status,
    success: t('toast.success'),
    error: t('toast.error'),
  });

  const handleClickOgLogoCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsOgLogoEnabled(e.target.checked);
  };

  const handleCancel = () => {
    resetValues();
    setIsOgLogoEnabled(ogImageStatus === 'enabled');
    onClose();
  };

  const ogImageStatusChanged =
    (ogImageStatus === 'enabled' && !isOgLogoEnabled) ||
    (ogImageStatus === 'disabled' && isOgLogoEnabled);

  const isConfirmDisabled = !isDirty && !ogImageStatusChanged;
  const isLoading = status === 'loading';

  return (
    <ModalAside
      isOpen={showModal}
      headerContent={<HeaderContent title={t('title')} />}
      onDidDismiss={handleCancel}
    >
      <Stack column align="stretch" spacing="loose">
        <Stack column align="stretch">
          <Title as="h4">{t('logo')}</Title>
          <IdentityImage
            type="logo"
            imageUrl={values.logo}
            recommendedWidth="575px"
            recommendedHeight="230px"
            onChange={setFieldValue}
          />
          <Checkbox
            name="ogLogoEnabled"
            label={t('otherImageOnLinkCheckbox')}
            checked={isOgLogoEnabled}
            onChange={handleClickOgLogoCheckbox}
          />
          {isOgLogoEnabled && (
            <IdentityImage
              type="logoOg"
              imageUrl={values.logoOg}
              recommendedWidth="600px"
              recommendedHeight="600px"
              onChange={setFieldValue}
            />
          )}
        </Stack>
        <Stack column align="stretch">
          <Title as="h4">{t('favicon')}</Title>
          <Text color="neutral-textLow">{t('faviconInfo')}</Text>
          <IdentityImage
            type="favicon"
            imageUrl={values.favicon}
            recommendedWidth="130px"
            recommendedHeight="130px"
            onChange={setFieldValue}
          />
        </Stack>
        <CancelAndConfirmButtons
          confirmText={isLoading ? t('confirmLoading') : t('confirm')}
          isLoading={isLoading}
          isConfirmDisabled={isConfirmDisabled}
          onCancel={handleCancel}
          onConfirm={handleOnSubmit}
        />
      </Stack>
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_THEMES_LOGO[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </ModalAside>
  );
}
