import { useCallback } from 'react';
import { SuggestedGoogleShoppingCategoriesRequestDto } from '@tiendanube/common';
import { useLazySuggestedGoogleCategoriesQuery } from '../aiApi';

function useSuggestedGoogleShoppingCategories() {
  const [getData, { data, isError, isFetching }] =
    useLazySuggestedGoogleCategoriesQuery();

  const generateSuggestedCategories = useCallback(
    async (requestDto: SuggestedGoogleShoppingCategoriesRequestDto) => {
      await getData(requestDto, true);
    },
    [getData],
  );

  return {
    generateSuggestedCategories,
    suggestedCategories: data?.categories || [],
    error: isError,
    loading: isFetching,
    versions: data?.versions,
  } as const;
}

export default useSuggestedGoogleShoppingCategories;
