import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MarketingAppResponseDto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import { getMarketingApps } from '../marketingAppsService';

interface MarketingAppsInterface {
  status: StatusType;
  data: MarketingAppResponseDto[];
}

const initialState: MarketingAppsInterface = {
  status: 'idle',
  data: [],
};

export const getMarketingAppsAction = createAsyncThunk(
  'marketingApps/getMarketingAppsAction',
  async () => {
    const response = await getMarketingApps();
    return response;
  },
);

const marketingApps = createSlice({
  name: 'marketing-apps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketingAppsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMarketingAppsAction.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getMarketingAppsAction.rejected, (state) => {
        state.status = 'error';
        state.data = initialState.data;
      });
  },
});

export const { reducer } = marketingApps;
