import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  fetchAbandonedCartsList,
  getMoreAbandonedCartsList,
} from '../../abandonedCartsSlice';
import {
  getAbandonedCartsIds,
  getAbandonedCartsStatus,
  getAbandonedCartsRefreshStatus,
  getAbandonedCartsPagination,
} from '../../abandonedCartsSlice/abandonedCartsSelectors';

interface UseAbandonedCartsResult {
  abandonedCartsList: string[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  isRefreshing: boolean;
  isIdle: boolean;
  fetchAbandonedCarts: () => void;
  getMoreAbandonedCarts: () => void;
  goToPage: (page: number) => void;
  pagination: PaginationResponseDto;
}

function useAbandonedCarts(): UseAbandonedCartsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess, isIdle } = useSelector(
    getAbandonedCartsStatus,
  );
  const { isRefreshing } = useSelector(getAbandonedCartsRefreshStatus);
  const abandonedCartsList = useSelector(getAbandonedCartsIds);
  const pagination = useSelector(getAbandonedCartsPagination);

  const fetchAbandonedCarts = useCallback(
    () => dispatch(fetchAbandonedCartsList()),
    [dispatch],
  );

  const getMoreAbandonedCarts = useCallback(
    () => dispatch(getMoreAbandonedCartsList()),
    [dispatch],
  );

  const goToPage = useCallback(
    (page: number) => {
      dispatch(fetchAbandonedCartsList({ page: `${page}` }));
    },
    [dispatch],
  );

  const isEmpty = abandonedCartsList.length === 0 && !isLoading && !isError;

  return {
    abandonedCartsList,
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    isRefreshing,
    isIdle,
    fetchAbandonedCarts,
    getMoreAbandonedCarts,
    pagination,
    goToPage,
  };
}

export default useAbandonedCarts;
