import { TFunction } from 'i18next';
import { Status as StatusEnum, Country } from '@tiendanube/common/src/enums';
import {
  Card,
  InterfaceLabel,
  Label,
  Link,
  Stack,
  Text,
} from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { OrderStatusType } from '../commons/useOrderStatus';

interface OldCardPaymentProps {
  order: OrderStatusType;
  t: TFunction;
  country: Country;
  openGatewayLink: () => void;
  showButton: boolean;
  secondaryButton:
    | {
        children: string;
        onClick: () => void;
        spinner: boolean;
        disabled: boolean;
      }
    | undefined;
  headerLabel: InterfaceLabel;
  showInstallments: boolean;
  isNuvemPagoPayment: boolean;
  isMobile: boolean;
  openNuvemPago: () => void;
}

function OldCardPayment({
  order: {
    payment: {
      status,
      gateway = '',
      gatewayId,
      method,
      gatewayLink,
      installments,
    },
  },
  t,
  showButton,
  secondaryButton,
  headerLabel,
  showInstallments,
  isNuvemPagoPayment,
  openGatewayLink,
  country,
  isMobile,
  openNuvemPago,
}: OldCardPaymentProps): JSX.Element {
  const rejectedPaymentInfo = isNuvemPagoPayment
    ? ''
    : t('cardPayment.rejectedPaymentInfo', {
        gateway: t(`gateway.${gateway}`, gateway),
      });
  return (
    <Card
      title={t('cardPayment.title')}
      secondaryButton={showButton ? secondaryButton : undefined}
      headerLabel={headerLabel}
    >
      <Stack column align="flex-start" spacing="tight">
        <Text block bold>
          {t(`gateway.${gateway}`, gateway)}
        </Text>
        {method !== '' && <Label id={method} label={method} />}
        {showInstallments && (
          <Text block>{t('cardPayment.installments', { installments })}</Text>
        )}
        {!!gatewayId && (
          <>
            <Text block>
              {status !== StatusEnum.WARNIG_TRANSACTIONS
                ? t('cardPayment.gatewayId')
                : rejectedPaymentInfo}
            </Text>
            {gatewayLink ? (
              <Link
                appearance="primary"
                icon={ExternalLinkIcon}
                iconPosition="end"
                onClick={openGatewayLink}
              >
                {gatewayId}
              </Link>
            ) : (
              <Text block>{gatewayId}</Text>
            )}
          </>
        )}
        {isNuvemPagoPayment &&
          status === 'warningTransaction' &&
          (country === Country.BR && isMobile ? (
            <Text block>
              {t('cardPayment.rejectedNuvemPagoPaymentWithoutLink')}
            </Text>
          ) : (
            <Text block>
              {t('cardPayment.rejectedNuvemPagoPaymentInfo1')}{' '}
              <Link appearance="primary" onClick={openNuvemPago}>
                {t('cardPayment.rejectedNuvemPagoPaymentInfo2')}
              </Link>{' '}
              {t('cardPayment.rejectedNuvemPagoPaymentInfo3')}
            </Text>
          ))}
      </Stack>
    </Card>
  );
}

export default OldCardPayment;
