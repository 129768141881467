import { unwrapResult } from '@reduxjs/toolkit';
import {
  AssignProductsToCategoriesRequestDto,
  ProductDetailsResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { UpdateProductsParamsInterface } from 'domains/Catalog/Products/productsServices';
import {
  editPricesProduct as editPricesProductAction,
  assignCategories as assignCategoriesAction,
  updateProducts as updateProductsAction,
  deleteProducts as deleteProductsAction,
  EditPricesProductsDto,
} from 'domains/Catalog/Products/productsSlice';

export interface UseActionProducts {
  editPriceProduct: (
    payload: EditPricesProductsDto,
  ) => Promise<{ token: string }>;
  assignCategories: (
    payload: AssignProductsToCategoriesRequestDto,
  ) => Promise<void>;
  updateProducts: (
    payload: UpdateProductsParamsInterface,
  ) => Promise<ProductDetailsResponseDto[]>;
  deleteProducts: (payload: string[]) => Promise<void>;
}

function useActionProducts(): UseActionProducts {
  const dispatch = useAppDispatch();

  const editPriceProduct = async (payload: EditPricesProductsDto) => {
    const result = await dispatch(editPricesProductAction(payload));
    return unwrapResult(result);
  };

  const assignCategories = async (
    payload: AssignProductsToCategoriesRequestDto,
  ) => {
    const result = await dispatch(assignCategoriesAction(payload));
    return unwrapResult(result);
  };

  const updateProducts = async (payload: UpdateProductsParamsInterface) => {
    const result = await dispatch(updateProductsAction(payload));
    return unwrapResult(result);
  };

  const deleteProducts = async (payload: string[]) => {
    const result = await dispatch(deleteProductsAction(payload));
    return unwrapResult(result);
  };

  return {
    editPriceProduct,
    assignCategories,
    updateProducts,
    deleteProducts,
  };
}

export default useActionProducts;
