import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { BaseCard, Title } from '@tiendanube/components';
import { VirtualDataList } from 'commons/components';
import CustomerOrderListItem from 'domains/Orders/Orders/pages/OrderListPage/components/CustomerOrderListItem';
import CustomerOrdersSkeleton from './CustomerOrdersSkeleton';
import { useCustomerOrders } from '../../hooks';

import './CustomerOrders.scss';

interface CustomerOrdersProps {
  customerId: string;
}

function CustomerOrders({
  customerId,
}: CustomerOrdersProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const {
    customerOrders,
    customerOrdersIds,
    customerOrdersQuantity,
    isLoading,
    fetchCustomerOrders,
    fetchMoreCustomerOrders,
  } = useCustomerOrders(customerId);

  const titleSingular = t('customerDetails.customerActivity.orderTitle');
  const titlePlural = t('customerDetails.customerActivity.ordersTitle', {
    quantity: customerOrdersQuantity,
  });
  const isEmptyList = customerOrdersIds.length === 0;
  const shouldShowSpinner = isLoading && customerOrders.length !== 0;

  if (isLoading && isEmptyList) return <CustomerOrdersSkeleton />;
  if (isEmptyList) return null;

  return (
    <div className="stratus--customer-orders-result">
      <BaseCard>
        <BaseCard.Header>
          <Title type="h3">
            {customerOrdersQuantity === 1 ? titleSingular : titlePlural}
          </Title>
        </BaseCard.Header>
        <VirtualDataList
          onRefresh={fetchCustomerOrders}
          isPullable
          data={customerOrdersIds}
          endReached={fetchMoreCustomerOrders}
          itemContent={(index, id) => (
            <CustomerOrderListItem key={index} id={id} />
          )}
          showFooter={shouldShowSpinner}
        />
      </BaseCard>
    </div>
  );
}

export default CustomerOrders;
