import { ReactNode } from 'react';
import { PlusCircleIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import {
  ActionsDesktop,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionAlertOpenFTP } from './PromotionAlertOpenFTP';
import { PromotionHelpLink } from './PromotionHelpLink';
import { PromotionsListEmpty } from './PromotionsListEmpty';
import { PROMOTIONS_ROUTES } from '../promotionsRoutes';
import { promotionsCreationView } from '../tracking';

interface PromotionsListPageBaseProps {
  skeleton: ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  isError: boolean;
  children: ReactNode;
  onRetry: () => void;
}

export function PromotionsListPageBase({
  skeleton,
  isLoading,
  isEmpty,
  isError,
  children,
  onRetry,
}: Readonly<PromotionsListPageBaseProps>) {
  const t = useTranslationMarketing('promotions');
  const { goTo } = useNavegate();
  const gotoCreate = () => {
    promotionsCreationView();
    goTo(PROMOTIONS_ROUTES.newPromotions);
  };
  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          mainAction={
            !isEmpty
              ? {
                  onClick: gotoCreate,
                  icon: PlusCircleIcon,
                }
              : undefined
          }
        />
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          actions={
            !isEmpty && (
              <ActionsDesktop
                actions={[
                  {
                    icon: PlusCircleIcon,
                    children: t('addPromotion'),
                    appearance: 'primary',
                    onClick: gotoCreate,
                  },
                ]}
              />
            )
          }
        />
      }
    >
      {!isError && !isEmpty && <PromotionAlertOpenFTP />}
      {isLoading && skeleton}
      {isError && <ErrorScreen onRetry={onRetry} />}
      {isEmpty && <PromotionsListEmpty onCreate={gotoCreate} />}
      {!isLoading && !isError && !isEmpty && children}
      {!isError && <PromotionHelpLink />}
    </IonPageStratus>
  );
}
