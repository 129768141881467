import { RadioButtonGroup } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { RadioOptionsSkeleton } from '../RadioOptionsSkeleton';

interface StatusProps {
  value: boolean;
  onChange: ({ value, name }: InterfaceNameBooleanValue) => void;
  disabled?: boolean;
}

export function Active({
  value,
  onChange,
  disabled = false,
}: Readonly<StatusProps>) {
  const t = useTranslationMarketing('coupons.couponForm.limits.active');
  return (
    <RadioButtonGroup
      label={t('title')}
      boldLabel
      name="active"
      options={[
        {
          label: t('enabled'),
          value: 'enabled',
          disabled,
        },
        {
          label: t('disabled'),
          value: 'disabled',
          disabled,
        },
      ]}
      onChange={({ value }) =>
        onChange({ value: value === 'enabled', name: 'active' })
      }
      value={value ? 'enabled' : 'disabled'}
    />
  );
}

Active.Skeleton = RadioOptionsSkeleton;
