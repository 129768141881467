import { ChangeEvent, useState } from 'react';
import { Box, List, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside';
import {
  HeaderTop,
  HeaderContent,
  CancelAndSaveButtons,
  Stack,
  ExternalLink,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { useUpdateOurDomainMutation } from 'domains/Configurations/Domains/domainsApi';
import {
  isAlreadyInUseOurDomainError,
  isInvalidOurDomainError,
  isValidOurDomain,
  MAX_DOMAIN_LENGTH,
  UPDATE_OUR_DOMAIN_MUTATION_CACHE_KEY,
} from 'domains/Configurations/Domains/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { UpdateDomainAlert } from './UpdateDomainAlert';

interface EditOurDomainModalProps {
  currentDomain: string;
  show: boolean;
  onClose: () => void;
}

const toToastStatus = (apiError: unknown) => {
  if (
    apiError &&
    !isAlreadyInUseOurDomainError(apiError) &&
    !isInvalidOurDomainError(apiError)
  )
    return 'error';

  return 'idle';
};

export function EditOurDomainModal({
  currentDomain,
  show,
  onClose,
}: Readonly<EditOurDomainModalProps>) {
  const t = useTranslationConfigurations('domains.editModal');
  const [updateOurDomain, { isLoading, error: updateError }] =
    useUpdateOurDomainMutation({
      fixedCacheKey: UPDATE_OUR_DOMAIN_MUTATION_CACHE_KEY,
    });
  const [newUrl, setNewUrl] = useState('');
  const [error, setError] = useState('');
  const isUrlEmpty = newUrl.trim() === '';
  const prependLabel = `.${currentDomain.split('.').slice(1).join('.')}`;

  const handleChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setNewUrl(e.target.value.toLowerCase());
  };

  const handleSave = async () => {
    if (error) return;

    if (!newUrl || isUrlEmpty || !isValidOurDomain(newUrl)) {
      setError(t('error'));
      return;
    }

    try {
      const data = await updateOurDomain({ ourDomain: newUrl }).unwrap();

      const currentUrl = `https://${data.new_url}/admin/v2${CONFIGURATIONS_ROUTES.domains}?showUpdatedAlert=true`;
      window.location.href = `${data.redirectUrl}&login_to=${currentUrl}`;
    } catch (e) {
      if (e && isAlreadyInUseOurDomainError(e)) {
        setError(t('alreadyInUse'));
        return;
      }

      if (e && isInvalidOurDomainError(e)) {
        setError(t('error'));
        return;
      }
    }
  };

  useToastStatus({
    status: toToastStatus(updateError),
    error: t('errorToast'),
  });

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <UpdateDomainAlert />
      <Stack align="stretch" column>
        <FormField.Input
          id="url"
          label={t('currentUrlLabel')}
          placeholder="https://"
          value={currentDomain.split('.')[0]}
          disabled
          showHelpText
          appendPosition="end"
          append={<Text>{prependLabel}</Text>}
        />
        <FormField.Input
          id="url"
          label={t('newUrlLabel')}
          placeholder="https://"
          helpText={error ?? t('helpText')}
          appearance={error ? 'danger' : undefined}
          helpIcon={error ? ExclamationCircleIcon : undefined}
          autoFocus
          showHelpText
          appendPosition="end"
          append={<Text>{prependLabel}</Text>}
          onChange={handleChangeUrl}
          value={newUrl}
          maxLength={MAX_DOMAIN_LENGTH}
        />
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor="neutral-surface"
          borderRadius="base"
          padding="2"
          gap="3"
        >
          <List>
            <List.Item>{t('helpSection.item1')}</List.Item>
            <List.Item>{t('helpSection.item2')}</List.Item>
          </List>
          <ExternalLink
            appearance="primary"
            textDecoration="none"
            href={t('helpSection.link')}
          >
            {t('helpSection.title')}
          </ExternalLink>
        </Box>
      </Stack>
      <CancelAndSaveButtons
        onCancel={onClose}
        isLoading={isLoading}
        isDisabled={isUrlEmpty || !!error || isLoading}
        onSave={handleSave}
        saveText={isLoading ? t('savingBtn') : t('saveBtn')}
      />
    </ModalAside>
  );
}
