import { useEffect } from 'react';
import useGetNuvemPagoFromPorviderList from '../useGetNuvemPagoFromPorviderList';
import useInstalledPaymentProviders from '../useInstalledPaymentProviders';

function useNuvemPagoIsActive() {
  const {
    paymentProviders,
    fetchInstalledPaymentProviders,
    isSuccess,
    isLoading,
    isError,
  } = useInstalledPaymentProviders();
  const { nuvemPagoIsActive } =
    useGetNuvemPagoFromPorviderList(paymentProviders);
  useEffect(() => {
    /***
     * We call paymentProviders to find out whether or not the store has active nuvempago or pagonube
     */
    if (!paymentProviders) fetchInstalledPaymentProviders();
  }, [fetchInstalledPaymentProviders, paymentProviders]);

  return { nuvemPagoIsActive, isSuccess, isLoading, isError };
}

export default useNuvemPagoIsActive;
