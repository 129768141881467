import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { MessagesResponseDto } from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  getMessageById as getMessageByIdAction,
  removeMessage as removeMessageAction,
  readMessage as readMessageAction,
  unreadMessage as unreadMessageAction,
  spamMessage as spamMessageAction,
  refreshMessages as refreshMessagesAction,
  getMessagesFilters,
} from 'domains/Customers/Messages/messagesSlice';

export interface UseCouponByIdResult {
  messageById: MessagesResponseDto;
  removeMessage: () => Promise<void>;
  readMessage: () => Promise<void>;
  unreadMessage: () => Promise<void>;
  spamMessage: () => Promise<void>;
  refreshMessages: () => Promise<void>;
}

function useMessageById(id: string): UseCouponByIdResult {
  const dispatch = useAppDispatch();

  const messageById = useSelector((state: RootStateType) =>
    getMessageByIdAction(state, id),
  );

  const filters = useSelector(getMessagesFilters);

  const refreshMessages = useCallback(async () => {
    await dispatch(refreshMessagesAction(filters));
  }, [dispatch, filters]);

  const removeMessage = useCallback(async () => {
    unwrapResult(await dispatch(removeMessageAction(id)));
  }, [dispatch, id]);

  const readMessage = useCallback(async () => {
    unwrapResult(await dispatch(readMessageAction(id)));
    refreshMessages();
  }, [dispatch, id, refreshMessages]);

  const unreadMessage = useCallback(async () => {
    unwrapResult(await dispatch(unreadMessageAction(id)));
    refreshMessages();
  }, [dispatch, id, refreshMessages]);

  const spamMessage = useCallback(async () => {
    unwrapResult(await dispatch(spamMessageAction(id)));
  }, [dispatch, id]);

  return {
    messageById,
    removeMessage,
    readMessage,
    unreadMessage,
    spamMessage,
    refreshMessages,
  };
}

export default useMessageById;
