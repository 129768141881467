import { Box, Button, Card, Tag, Title } from '@nimbus-ds/components';
import { ThemesConfigDraftResponseDto } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { useModal, useTranslationLanguage } from 'commons/hooks';
import { useGetPromotedThemeInfoQuery } from 'domains/Online/Themes/themesApi/tempGetPromotedThemeInfoQuery';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { ConfirmDraftReplacementModal } from './ConfirmDraftReplacementModal';
import ThemeTraitList from './ThemeTraitList';
import {
  trackingPromotedThemeInstall,
  trackingThemeLayoutChange,
} from '../../../../tracking';
import { useInstallDraft } from '../../hooks/useInstallDraft';
import { ThemePreview } from '../Theme';
import './PromotedTheme.scss';

type NewDesignAdProps = {
  draft?: ThemesConfigDraftResponseDto;
  themeCode: string;
  themeVariant?: string;
  isBetaTheme?: boolean;
};

const THEME_INSTALL_EVENT_SOURCE = 'admin_promo_card';

export function PromotedTheme({
  themeCode,
  themeVariant,
  draft,
  isBetaTheme,
}: Readonly<NewDesignAdProps>) {
  const t = useTranslationOnline('themesPage.newDesign');

  const [
    showDraftReplacementModal,
    openDraftReplacementModal,
    closeDraftReplacementModal,
  ] = useModal(false);

  const language = useTranslationLanguage();

  const { data: themeInfo, status } = useGetPromotedThemeInfoQuery(
    themeCode,
    language,
  );

  const isLoading = toStatusType(status) === 'loading';
  const isError = toStatusType(status) === 'error';

  const handleInstallDraft = useInstallDraft(themeCode, themeVariant);

  if (isLoading || isError || !themeInfo) return null;

  const { name: designName, screenshotDesktop, screenshotMobile } = themeInfo;

  const handleConfirmAndTryTheme = async () => {
    draft ? handleConfirmTryTheme() : await handleTryTheme();
  };

  const handleConfirmTryTheme = () => {
    openDraftReplacementModal();
  };

  const handleTryTheme = async (confirmed = false) => {
    trackingThemeLayoutChange();
    trackingPromotedThemeInstall(
      designName,
      `${THEME_INSTALL_EVENT_SOURCE}${confirmed ? '_disclaimer_modal' : ''}`,
    );
    await handleInstallDraft();
  };

  return (
    <Card padding="none">
      <Card.Header data-style="stratus--promoted-theme-header">
        <Box position="relative">
          <ThemePreview
            imageDesktopUrl={screenshotDesktop}
            imageMobileUrl={screenshotMobile}
          />
          {isBetaTheme && (
            <Box
              position="absolute"
              bottom="0"
              left="0"
              px="6"
              py="2"
              zIndex="100"
              backgroundColor="transparent"
            >
              <Tag appearance="primary">{t('beta').toUpperCase()}</Tag>
            </Box>
          )}
        </Box>
      </Card.Header>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'column' }}
        paddingBottom="4"
        paddingLeft="4"
        paddingRight="4"
        gap="4"
        alignItems={{ md: 'flex-start', xs: 'flex-start' }}
        justifyContent="space-between"
      >
        <Title as="h4">{t('title', { designName })}</Title>
        <ThemeTraitList themeCode={themeCode} />
        <Button onClick={handleConfirmAndTryTheme} appearance="neutral">
          {t('try', { designName })}
        </Button>
        <ConfirmDraftReplacementModal
          showModal={showDraftReplacementModal}
          onConfirm={() => handleTryTheme(true)}
          onClose={closeDraftReplacementModal}
        />
      </Box>
    </Card>
  );
}
