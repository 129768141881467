import { Box, Card, Skeleton, Title } from '@nimbus-ds/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function PaymentSelectionForPayOrderSkeleton() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPayOrder.paymentSelection',
  );

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Skeleton width="100%" height="54px" borderRadius="5px" />
          <Skeleton width="100%" height="54px" borderRadius="5px" />
          <Skeleton width="100%" height="54px" borderRadius="5px" />
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PaymentSelectionForPayOrderSkeleton;
