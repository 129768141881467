import { logEvent } from 'commons/utils/tracking';
import {
  ORDER_FILTER,
  ORDER_SEARCH,
  ORDER_TRANSACTION_ID_LINK_CLICK,
  ORDER_SELECT_BULK_LINK_CLICK,
  ORDER_METAFIELD_SAVE,
  ORDER_METAFIELD_FILTER,
  ORDER_METAFIELD_SAVE_ERROR,
  ORDER_METAFIELD_FILTER_ERROR,
  ORDER_LIST_EXPORT,
  ORDER_DRAFT_ORDER_CREATE,
  ORDER_LIST_FILTER_CLICK,
  ORDER_EDIT_CLICK,
  ORER_EDIT_SAVE_CLICK,
  ORDER_REOPEN_BUTTON_CLICK,
  ORDER_REOPEN_WITH_ISSUES_BUTTON_CLICK,
} from 'config/trackingEvents';

type OrderFilterParams = {
  orderStatusFilter: string;
  dateRangeFilter: string;
  shippingStatusFilter: string;
  paymentStatusFilter: string;
  paymentMethodFilter: string;
  paymentProviderFilter: string;
  shippingFilter: string;
  channelFilter: string;
  applicationFilter: string;
  productsFilter: string;
  countSelectedProductsFilter: string;
  productsCountFilter: string;
  productsCountFilterValue: string;
};

export const trackingOrderFilter = (params: OrderFilterParams): void => {
  logEvent(ORDER_FILTER, {
    order_status_filter: params.orderStatusFilter,
    date_range_filter: params.dateRangeFilter,
    shipping_status_filter: params.shippingStatusFilter,
    payment_status_filter: params.paymentStatusFilter,
    payment_method_filter: params.paymentMethodFilter,
    payment_provider_filter: params.paymentProviderFilter,
    shipping_filter: params.shippingFilter,
    channel_filter: params.channelFilter,
    application_filter: params.applicationFilter,
    products_filter: params.productsFilter,
    count_selected_variants: params.countSelectedProductsFilter,
    products_count_filter: params.productsCountFilter,
    products_count_filter_value: params.productsCountFilterValue,
  });
};

export const trackingOrderSearch = (textSearch: string) => {
  logEvent(ORDER_SEARCH, {
    input: textSearch,
    type: Number.isNaN(Number(textSearch)) ? 'string' : 'number',
  });
};

export const trackingTransactionLinkClick = (): void => {
  logEvent(ORDER_TRANSACTION_ID_LINK_CLICK, {});
};

export const trackingOrderBulkSelectClick = (ordersCount: number): void => {
  logEvent(ORDER_SELECT_BULK_LINK_CLICK, { bulkSize: String(ordersCount) });
};

export const trackingOrderMetafieldSave = (
  metafieldId: string,
  orderId: string,
  value: string | null,
): void => {
  logEvent(ORDER_METAFIELD_SAVE, {
    metafieldId,
    orderId,
    value: `${value}`,
  });
};

export const trackingOrderMetafieldSaveError = (
  metafieldId: string,
  orderId: string,
  value: string | null,
): void => {
  logEvent(ORDER_METAFIELD_SAVE_ERROR, {
    metafieldId,
    orderId,
    value: `${value}`,
  });
};

export const trackingOrderMetafieldFilter = (
  metafieldId: string,
  value: string,
): void => {
  logEvent(ORDER_METAFIELD_FILTER, {
    metafieldId,
    value,
  });
};

export const trackingOrderMetafieldFilterError = (): void => {
  logEvent(ORDER_METAFIELD_FILTER_ERROR, {});
};

export const trackingOrderListExport = (): void => {
  logEvent(ORDER_LIST_EXPORT, {});
};

export const trackingOrderDraftOrderCreate = (
  paymentStatus: 'unpaid' | 'pending_confirmation' | 'paid',
): void => {
  logEvent(ORDER_DRAFT_ORDER_CREATE, {
    order_status: paymentStatus,
  });
};

export const trackingOrderListFilterClick = (): void => {
  logEvent(ORDER_LIST_FILTER_CLICK, {});
};

export const trackingOrderEditClick = (): void => {
  logEvent(ORDER_EDIT_CLICK, {});
};

export const trackingOrderEditSaveClick = (
  reason: string | undefined,
  sendNotification: boolean,
): void => {
  logEvent(ORER_EDIT_SAVE_CLICK, {
    reason: `${reason}`,
    sendNotification: `${sendNotification}`,
  });
};

export const trackingOrderReopenButtonClick = (status: string): void => {
  logEvent(ORDER_REOPEN_BUTTON_CLICK, { order_status: status });
};
export const trackingOrderReopenWithIssuesButtonClick = (): void => {
  logEvent(ORDER_REOPEN_WITH_ISSUES_BUTTON_CLICK, {});
};
