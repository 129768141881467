import { Country } from '@tiendanube/common/src/enums';
import { Grid } from '@tiendanube/components';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import {
  ProductsChartCard,
  StatisticsPage,
  StatisticsProductsItem,
} from 'domains/Statistics/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

function StatisticsProductsPage() {
  const t = useTranslationStatistics();
  return (
    <StatisticsPage title={t('statistics.productsPage.title')}>
      <ProductsChartCard />
      <Grid>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="low-stock" />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="most-visited" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="best-sellers" />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="least-sellers" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="higher-revenue" />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            <StatisticsProductsItem item="lower-revenue" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ShowByCountry excludeCountries={[Country.BR]}>
        <HelpLink
          title={t('statistics.helpLink.aboutStats')}
          linkURL={t('statistics.helpLink.aboutStatsUrl')}
          previousValue=""
          currentViewTracking={t('statistics.aboutProductsTrackingName')}
          icon="both"
        />
      </ShowByCountry>
    </StatisticsPage>
  );
}

export default StatisticsProductsPage;
