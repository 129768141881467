import { Card, Icon } from '@nimbus-ds/components';
import { ChevronDownIcon } from '@nimbus-ds/icons';
import { PaymentProviderDto } from '@tiendanube/common';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { MethodNameAndImage, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { PaymentProviderBody } from './components';
import Skeleton from './Skeleton';
import { OnPaymentProviderActionType } from '../PaymentProviderActionModal';
import { PaymentProviderStatusLabel } from '../PaymentProviderStatusLabel';
import './PaymentProviderMobile.scss';

type PaymentProviderMobileProps = {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
};

function PaymentProviderMobile({
  paymentProvider,
  onAction,
}: Readonly<PaymentProviderMobileProps>) {
  const {
    id,
    isActive,
    mustCompleteInstallation,
    logo,
    processingInstallation,
    isNew,
    isDeprecated,
  } = paymentProvider;

  const isPagoNube = muvemPagoOrPagoNubeIds.includes(id);
  const [expand, setExpand, setClose] = useModal(isPagoNube || false);

  return (
    <Card>
      {!expand && (
        <Card.Header>
          <Stack justify="space-between">
            <MethodNameAndImage image={logo} isPagoNube={isPagoNube} />
            <Stack>
              <PaymentProviderStatusLabel
                isActive={isActive}
                mustCompleteInstallation={mustCompleteInstallation}
                processingInstallation={processingInstallation}
                isNew={isNew}
                isDeprecated={isDeprecated}
              />
              <Icon
                onClick={setExpand}
                color="neutral-textHigh"
                source={<ChevronDownIcon />}
                cursor="pointer"
              />
            </Stack>
          </Stack>
        </Card.Header>
      )}
      {expand && (
        <PaymentProviderBody
          onAction={onAction}
          onClose={setClose}
          paymentProvider={paymentProvider}
        />
      )}
    </Card>
  );
}

PaymentProviderMobile.Skeleton = Skeleton;

export default PaymentProviderMobile;
