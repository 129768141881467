import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getDetailCustomShippingData,
  getDetailCustomShippingStatus,
  fetchDetailCustomShipping as fetchDetailCustomShippingAction,
} from '../../deliveryMethodsSlice';

function useGetDetailCustomShipping() {
  const dispatch = useAppDispatch();

  const { isLoading, isError, isSuccess } = useSelector(
    getDetailCustomShippingStatus,
  );
  const detailCustomShipping = useSelector(getDetailCustomShippingData);

  const fetchDetailCustomShipping = useCallback(
    (id) => {
      dispatch(fetchDetailCustomShippingAction(id));
    },
    [dispatch],
  );

  return {
    isLoading,
    isError,
    isSuccess,
    detailCustomShipping,
    fetchDetailCustomShipping,
  };
}

export default useGetDetailCustomShipping;
