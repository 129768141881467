import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  verifyAndAuthorizeAction,
  verifyAndAuthorizeValidResult,
  verifyAndAuthorizeStatus,
  cleanVerifyAndAuthorizeData,
} from 'domains/AuthenticationFactor/redux';

interface UseVerifyAuthenticationFactorValues {
  isLoading: boolean;
  isError: boolean;
  verify: (code: string, payload: object) => Promise<boolean>;
  cleanState: () => void;
}

function useVerifyAuthenticationFactor(): UseVerifyAuthenticationFactorValues {
  const { status } = useSelector(verifyAndAuthorizeStatus);
  const isValid = useSelector(verifyAndAuthorizeValidResult);
  const dispatch = useAppDispatch();
  const verify = useCallback(
    async (code: string, payload: object) => {
      const response = await dispatch(
        verifyAndAuthorizeAction({ code, payload }),
      );
      return unwrapResult(response).valid;
    },
    [dispatch],
  );

  const cleanState = useCallback(() => {
    dispatch(cleanVerifyAndAuthorizeData());
  }, [dispatch]);

  return {
    isLoading: status === 'loading',
    isError: status === 'error' || (status === 'success' && !isValid),
    verify,
    cleanState,
  };
}

export default useVerifyAuthenticationFactor;
