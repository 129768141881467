import { ErrorState } from '@tiendanube/components';
import useTranslationMarketing from 'domains/Marketing/useTranslationMarketing';

interface MarketingAppsPageErrorProps {
  getMarketingApps: () => void;
}

function MarketingAppsPageError({
  getMarketingApps,
}: MarketingAppsPageErrorProps) {
  const t = useTranslationMarketing('marketingApps');

  return (
    <ErrorState
      title={t('errorState.title')}
      action={{
        children: t('errorState.retry'),
        onClick: getMarketingApps,
      }}
    />
  );
}

export default MarketingAppsPageError;
