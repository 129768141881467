import { Link } from '@nimbus-ds/components';
import { RedoIcon } from '@nimbus-ds/icons';
import { toStatusType } from 'App/rtk';
import { useToastStatus } from 'commons/hooks';
import { useResetPasswordMutation } from 'domains/Online/Themes/themesApi';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

export function ResetPasswordLink() {
  const t = useTranslationOnline('themesPage.theme');
  const [resetPassword, { status, isLoading }] = useResetPasswordMutation();

  useToastStatus({
    status: toStatusType(status),
    success: t('toast.success'),
    error: t('toast.error'),
  });

  if (isLoading) return <Link.Skeleton width="225px" />;
  else
    return (
      <Link
        appearance="primary"
        textDecoration="none"
        onClick={() => resetPassword()}
      >
        {t('resetPassword')} <RedoIcon />
      </Link>
    );
}
