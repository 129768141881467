import { AppsListItemResponseDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { PopoverMenu } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import { useAppsListActions } from 'domains/PartnersApps/pages/AppsListPage/hooks';
import AppsListItem from '../../../AppsListItem';

interface AppsListMobileRowProps {
  app: AppsListItemResponseDto;
}

function AppsListMobileRow({ app }: AppsListMobileRowProps) {
  const { Row, Cell } = DataList;
  const { t } = useTranslationPartnerApps('appsListPage');
  const { getAppActions, status } = useAppsListActions(app);

  useToastStatus({
    status,
    error: t('toast.error'),
    progress: t('toast.loading'),
  });

  return (
    <Row id={app.id}>
      <Cell width="fill" rowTitle>
        <AppsListItem app={app} />
      </Cell>
      <Cell width="auto">
        <PopoverMenu actions={getAppActions()} />
      </Cell>
    </Row>
  );
}

export default AppsListMobileRow;
