import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { ErrorState } from '@tiendanube/components';

interface DraftOrdersListErrorProps {
  onRetryError: () => void;
}

function DraftOrdersListError({
  onRetryError,
}: DraftOrdersListErrorProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  return (
    <ErrorState
      title={t('draftOrders.error.title')}
      action={{
        children: t('draftOrders.error.action'),
        onClick: onRetryError,
      }}
    />
  );
}

export default DraftOrdersListError;
