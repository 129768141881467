import { PlansListResponseDto } from '@tiendanube/common';
import {
  Grid,
  InterfaceNameValue,
  InterfaceSelectOptionGroup,
  Link,
  Select,
  Stack,
  Text,
} from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import './ComparisonSelect.scss';
import { SelectedPlan } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components/ComparisonPlansCards/components/PlanCard/types';

interface ComparisonSelectProps {
  plansList: PlansListResponseDto[];
  currentPlanId: number;
  firstSelectedPlan: PlansListResponseDto;
  secondSelectedPlan: PlansListResponseDto;
  onSelectedFirstPlan: (firstPlanToCompare: PlansListResponseDto) => void;
  onSelectedSecondPlan: (secondPlanToCompare: PlansListResponseDto) => void;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonSelect({
  plansList,
  currentPlanId,
  firstSelectedPlan,
  secondSelectedPlan,
  onSelectedFirstPlan,
  onSelectedSecondPlan,
  onSelectPlan,
}: ComparisonSelectProps): JSX.Element {
  const t = useTranslationBilling();
  const isMobile = useIsMobileDevice();

  const planOptions: InterfaceSelectOptionGroup[] = plansList.map(
    (plan: PlansListResponseDto) => ({
      label: plan.name,
      value: `${plan.id}`,
      disabled: [firstSelectedPlan.name, secondSelectedPlan.name].includes(
        plan.name,
      ),
    }),
  );

  const handleOnPlanSelectChange = (e: InterfaceNameValue) => {
    const plan = plansList.find((plan) => plan.id === Number(e.value));
    if (!plan) return;

    if (e.name === 'planToCompare1') {
      onSelectedFirstPlan(plan);
    } else {
      onSelectedSecondPlan(plan);
    }
  };

  return (
    <div className="stratus--comparison__plan__select">
      <Grid fluid>
        <Grid.Row>
          <Grid.Column>
            <Stack column align="stretch">
              <Stack.Item>
                <Select
                  name="planToCompare1"
                  label={t('billingPlansPage.comparisonTable.plan1')}
                  options={planOptions}
                  value={`${firstSelectedPlan.id}`}
                  onChange={handleOnPlanSelectChange}
                />
              </Stack.Item>
              <Stack.Item>
                <CurrencyPrice
                  price={firstSelectedPlan.price.value}
                  currency={firstSelectedPlan.price.currency}
                  withoutDecimal
                  bold
                />{' '}
                <Text>/ {t('billingPlansPage.month')}</Text>
              </Stack.Item>
              {firstSelectedPlan.level !== 0 && !isMobile && (
                <Stack.Item>
                  <Link
                    appearance="primary"
                    underline
                    onClick={() => onSelectPlan(firstSelectedPlan)}
                  >
                    {firstSelectedPlan.id === currentPlanId
                      ? t('billingPlansPage.payPlan')
                      : t('billingPlansPage.selectPlan')}
                  </Link>
                </Stack.Item>
              )}
            </Stack>
          </Grid.Column>
          <Grid.Column>
            <Stack column align="stretch">
              <Stack.Item>
                <Select
                  name="planToCompare2"
                  label={t('billingPlansPage.comparisonTable.plan2')}
                  options={planOptions}
                  value={`${secondSelectedPlan.id}`}
                  onChange={handleOnPlanSelectChange}
                />
              </Stack.Item>
              <Stack.Item>
                <CurrencyPrice
                  price={secondSelectedPlan.price.value}
                  currency={secondSelectedPlan.price.currency}
                  withoutDecimal
                  bold
                />{' '}
                <Text>/ {t('billingPlansPage.month')}</Text>
              </Stack.Item>
              {secondSelectedPlan.level !== 0 && !isMobile && (
                <Stack.Item>
                  <Link
                    appearance="primary"
                    underline
                    onClick={() => onSelectPlan(secondSelectedPlan)}
                  >
                    {secondSelectedPlan.id === currentPlanId
                      ? t('billingPlansPage.payPlan')
                      : t('billingPlansPage.selectPlan')}
                  </Link>
                </Stack.Item>
              )}
            </Stack>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ComparisonSelect;
