import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ImportInProcessModal, ImportProductsCard } from './components';
import { useImportMaxLines } from './hooks';

function ImportProductsPage() {
  const t = useTranslationCatalog();
  const { maxLines } = useImportMaxLines();
  const language = useTranslationLanguage();
  const { goBack } = useNavegate();

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('products.importExportPage.import.import')}
          subtitle={t('products.importExportPage.subtitle')}
        />
      }
      width="small"
    >
      <ImportInProcessModal>
        <ImportProductsCard
          description={t(
            'products.importExportPage.import.descriptionImportOnly',
            {
              max: maxLines,
            },
          )}
          showUpdateOptions={false}
        />
      </ImportInProcessModal>
      <HelpLink
        title={t('products.importExportPage.helpLinkTitle')}
        previousValue=""
        currentViewTracking={t('products.importExportPage.helpLinkTracking')}
        linkURL={HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default ImportProductsPage;
