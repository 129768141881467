import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  getInvoiceInfoData,
  fetchInvoiceInfo as fetchActionInvoiceInfo,
  getInvoiceInfoStatus,
  getInvoiceInfoExists,
} from 'domains/Billing/Invoices/invoicesSlice';

function useGetInvoiceInfo() {
  const dispatch = useAppDispatch();
  const status = useSelector(getInvoiceInfoStatus);
  const country = useGetCountry();

  const invoiceInfo = useSelector(getInvoiceInfoData);
  const hasInvoiceInfo = useSelector(getInvoiceInfoExists);

  const refreshInvoiceInfo = useCallback(() => {
    dispatch(fetchActionInvoiceInfo());
  }, [dispatch]);

  const fetchInvoiceInfo = useCallback(() => {
    status === 'idle' && refreshInvoiceInfo();
  }, [refreshInvoiceInfo, status]);

  const isConsumidorFinal =
    status === 'success' &&
    country === 'AR' &&
    ((invoiceInfo &&
      (!invoiceInfo.vatCondition ||
        invoiceInfo.vatCondition === 'consumidor_final')) ||
      hasInvoiceInfo === false);

  return {
    ...convertStatusTypeToObject(status),
    status,
    fetchInvoiceInfo,
    refreshInvoiceInfo,
    invoiceInfo,
    hasInvoiceInfo,
    isConsumidorFinal,
  };
}

export default useGetInvoiceInfo;
