import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { checkoutApi } from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import { usePostAppChargeMutation } from 'domains/PartnersApps/partnersAppsApi';

export default function useCreateAppCharge() {
  const [createAppChargeAction, { status, isLoading }] =
    usePostAppChargeMutation();
  const dispatch = useAppDispatch();

  const createAppCharge = useCallback(
    (appId: string) =>
      createAppChargeAction(appId)
        .unwrap()
        .then(() => {
          dispatch(
            checkoutApi.util.invalidateTags([
              checkoutTags.pendingCharges,
              checkoutTags.recurrentPaymentChargesData,
            ]),
          );
        }),
    [createAppChargeAction, dispatch],
  );

  return { status, isLoading, createAppCharge };
}
