import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetLastPaidChargesQuery } from '../../../CheckoutApi';

function useLastPaidCharges(initialFetch = true) {
  const {
    refetch,
    data: lastPaidCharges,
    status,
  } = useGetLastPaidChargesQuery(undefined, { skip: !initialFetch });

  return {
    status: toStatusType(status),
    ...convertStatusTypeToObject(status),
    lastPaidCharges,
    refreshLastPaidCharges: refetch,
  };
}

export { useLastPaidCharges };
