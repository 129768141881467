import { Link, Tag } from '@nimbus-ds/components';
import { ForbiddenIcon, LockIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import { Responsive, Stack } from 'commons/components';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface UserHas2faTagProps {
  has2fa: boolean;
  isCurrentUser?: boolean;
}

function UserHas2faTag({
  has2fa,
  isCurrentUser,
}: Readonly<UserHas2faTagProps>) {
  const t = useTranslationConfigurations('usersListPage.table');
  const { goTo } = useNavegate();

  const handleGoTo2fa = () => goTo(ACCOUNT_ROUTES.authenticationFactor);

  if (has2fa)
    return (
      <Tag appearance="success">
        <LockIcon size={12} />
        <Responsive desktop={<>{t('enabledTag')}</>} mobile={<>{t('2fa')}</>} />
      </Tag>
    );

  return (
    <Stack spacing="tight">
      <Tag appearance="warning">
        <ForbiddenIcon size={12} />
        <Responsive
          desktop={<>{t('disabledTag')}</>}
          mobile={<>{t('2fa')}</>}
        />
      </Tag>
      {isCurrentUser && (
        <Link
          appearance="primary"
          textDecoration="none"
          onClick={handleGoTo2fa}
        >
          {t('enable')}
        </Link>
      )}
    </Stack>
  );
}

export default UserHas2faTag;
