import {
  Box,
  Link as LinkNimbus,
  Tag,
  Text,
  Thumbnail,
} from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { Link } from 'react-router-dom';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { Stack, useResponsive } from 'commons/components';
import {
  useMultiCDIncompatibleAlert,
  useTranslationPartnerApps,
} from 'domains/PartnersApps/hooks';
import AppsListItemStatus from '../AppsListItemStatus';

interface AppsListItemProps {
  app: AppsListItemResponseDto;
}

function AppsListItem({ app }: AppsListItemProps) {
  const { isMobile } = useResponsive();
  const { t } = useTranslationPartnerApps('appsListPage');

  const showMultiInventoryIncompatibleAlert = useMultiCDIncompatibleAlert(
    app?.categoryType,
    app?.isMultiCDCompatible,
  );

  const url = app.isNative ? `/apps/${app.id}` : app.url;

  const textName = (
    <Text
      fontWeight="bold"
      color={app.installed && url ? 'primary-interactive' : 'neutral-textLow'}
      lineClamp={1}
    >
      {app.name}
    </Text>
  );

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      gap="2"
      alignItems="flex-start"
    >
      <Stack spacing="tight" align="flex-start">
        <Thumbnail width="48px" src={app.image} alt={app.name} />
        <Stack.Item fill>
          <Stack column align="flex-start" spacing="tight">
            <Stack column align="flex-start" spacing="none">
              {app.installed && url ? (
                <LinkNimbus
                  as={app.isNative ? Link : 'a'}
                  textDecoration="none"
                  to={url}
                  href={url}
                  target={app.isNative ? undefined : '_blank'}
                >
                  {textName}
                </LinkNimbus>
              ) : (
                textName
              )}
              <Text lineClamp={1}>{app.category}</Text>
            </Stack>
            {isMobile && <AppsListItemStatus app={app} />}
          </Stack>
        </Stack.Item>
      </Stack>

      {showMultiInventoryIncompatibleAlert && (
        <Box
          display="flex"
          alignItems="center"
          margin="auto"
          flexGrow="1"
          minWidth="342px"
        >
          <Tag appearance="warning">
            <ExclamationTriangleIcon /> {t('isMultiCDIncompatibleAlert')}
          </Tag>
        </Box>
      )}
    </Box>
  );
}

export default AppsListItem;
