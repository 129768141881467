import { Link, Text } from '@nimbus-ds/components';
import { useTranslationCommon } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin/goToAdmin';
import { trackingBillingPostUpsellAdbarClick } from 'domains/Billing/tracking';
import { AdBar } from './AdBar';

export function ExpiredStoreAdBar() {
  const t = useTranslationCommon('topAdBar.expired');
  const goToCheckout = goToAdmin('account/checkout/start/');

  const handleClick = () => {
    trackingBillingPostUpsellAdbarClick();
    goToCheckout();
  };

  return (
    <AdBar appearence="danger">
      <Text fontWeight="bold" color="danger-textLow">
        {t('text')}
      </Text>
      <Link onClick={handleClick} appearance="primary" textDecoration="none">
        {t('cta')}
      </Link>
    </AdBar>
  );
}
