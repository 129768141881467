import { useCallback, useEffect, useState } from 'react';
import { getStorage, setStorage } from 'App/storage';

function useStorage<T>(
  key: string,
  initialValue: T,
  session = false,
): [T, (a: T) => void, boolean] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(initialValue);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fn = async () => {
      try {
        // Get from local storage by key
        const item = await getStorage<string>(key, session);
        // Parse stored json or if none return initialValue
        const data = item ? (JSON.parse(item) as T) : initialValue;
        setStoredValue(data);
        setLoaded(true);
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
    };
    fn();
  }, [initialValue, key, session]);
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        setStorage(key, JSON.stringify(valueToStore), session);
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key, session, storedValue],
  );
  return [storedValue, setValue, loaded];
}

export default useStorage;
