import { CreditCardIcon, MoneyIcon, ShoppingCartIcon } from '@nimbus-ds/icons';
import { CheckoutPaymentMethod } from '@tiendanube/common';

export function getIconForPaymentMethod(method: CheckoutPaymentMethod) {
  return {
    cc: <CreditCardIcon />,
    transfer: <MoneyIcon />,
    ticket: <ShoppingCartIcon />,
  }[method];
}
