import { logEvent } from 'commons/utils/tracking';
import {
  PAYMENT_CUSTOM_SAVE,
  PAYMENT_CUSTOM_CASH_CLICK,
  PAYMENT_CUSTOM_WIRE_TRANSFER_CLICK,
  PAYMENT_CUSTOM_PIX_CLICK,
  PAYMENT_CUSTOM_OTHER_CLICK,
} from 'config/trackingEvents';
import { CustomPaymentMethod } from './types';

export const trackingSave = (): void => {
  logEvent(PAYMENT_CUSTOM_SAVE, {});
};

export const trackingPaymentMethodToggle = (group: CustomPaymentMethod) => {
  const event = {
    cash: PAYMENT_CUSTOM_CASH_CLICK,
    wireTransfer: PAYMENT_CUSTOM_WIRE_TRANSFER_CLICK,
    pix: PAYMENT_CUSTOM_PIX_CLICK,
    other: PAYMENT_CUSTOM_OTHER_CLICK,
  }[group];

  logEvent(event, {});
};
