import { useCallback } from 'react';
import { cleanBlanks } from 'commons/utils';

export interface useWhatsappMessageLinkProps {
  phone: string;
  message?: string;
}

function useWhatsappMessageLink(): (
  props: useWhatsappMessageLinkProps,
) => string {
  const getUrl = ({
    phone,
    message = '',
  }: useWhatsappMessageLinkProps): string => {
    const bodyEncoded = encodeURIComponent(message);
    const phoneNumberClean = cleanBlanks(phone).replace('+', '');
    return phone
      ? `https://wa.me/${cleanBlanks(phoneNumberClean)}?text=${bodyEncoded}`
      : '';
  };
  return useCallback(getUrl, []);
}

export default useWhatsappMessageLink;
