import { DataTable, Text } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  actionsColBasis,
  inputColBasis,
  inputColGrow,
  editHeaderColBasis,
  productColGrow,
  inputColVariant,
} from '../../constants';

const { Header, Cell } = DataTable;

function ResultHeaderDesktop(): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <Header>
      <Cell grow={productColGrow}>
        <Text>{t('inventory.table.header.product')}</Text>
      </Cell>
      <Cell grow={inputColGrow} basis={inputColBasis}>
        <Text>{t('inventory.table.header.stock')}</Text>
      </Cell>
      <Cell grow={inputColGrow} basis={editHeaderColBasis} />
      <Cell grow={productColGrow}>
        <Text>{t('inventory.table.header.variants')}</Text>
      </Cell>
      <Cell grow={inputColGrow} basis={inputColVariant}>
        <Text>{t('inventory.table.header.sku')}</Text>
      </Cell>
      <Cell grow={inputColGrow} basis={actionsColBasis}>
        <Text>{t('inventory.table.header.history')}</Text>
      </Cell>
    </Header>
  );
}

export default ResultHeaderDesktop;
