import { Card, Icon, Text } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { AiTone } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';

interface EditInstructionsCardProps {
  tone?: AiTone;
  wordNumber?: number;
  onClick: () => void;
}

export function EditInstructionsCard({
  tone,
  wordNumber,
  onClick,
}: Readonly<EditInstructionsCardProps>) {
  const t = useTranslationCommon();

  const subtitleKey =
    tone && wordNumber
      ? 'subtitleFull'
      : tone
      ? 'subtitleTone'
      : wordNumber
      ? 'subtitleWordCount'
      : undefined;

  return (
    <div style={{ cursor: 'pointer' }}>
      <Card onClick={onClick}>
        <Stack align="center">
          <Stack.Item fill>
            <Text color="neutral-textHigh">
              {t('aiGeneration.resultsModal.editInstructions.title')}
            </Text>
            {subtitleKey && (
              <Text fontSize="caption">
                {t(
                  `aiGeneration.resultsModal.editInstructions.${subtitleKey}`,
                  {
                    tone: t(`aiGeneration.tones.values.${tone}`),
                    wordNumber,
                  },
                )}
              </Text>
            )}
          </Stack.Item>
          <Stack.Item>
            <Icon color="primary-interactive" source={<ChevronRightIcon />} />
          </Stack.Item>
        </Stack>
      </Card>
    </div>
  );
}
