import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';

export const ADDRESSING_RULES_TYPES = {
  cdPriority: 'CD_PRIORITY',
  cdProximity: 'CD_PROXIMITY',
  materialize: 'MATERIALIZE',
  app: 'APP',
};

export const INCOMPATIBLE_RULES = [
  ADDRESSING_RULES_TYPES.cdPriority,
  ADDRESSING_RULES_TYPES.cdProximity,
];

const MATERIALIZATION_CODE = 'native:maximize-materialization';
export const INITIAL_MATERIALIZATION_RULE: AddressingConfigurationRuleResponseDto =
  {
    type: ADDRESSING_RULES_TYPES.materialize,
    code: MATERIALIZATION_CODE,
    appName: null,
    appId: null,
    enabled: true,
    priority: 0,
  };

export const ADDRESSING_RULES_TRANSLATE_TYPES = {
  CD_PRIORITY: 'cdPriority',
  CD_PROXIMITY: 'cdProximity',
  APP: 'app',
};
