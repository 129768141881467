import { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';

function useScrollToError(errors: Partial<Record<string, string>>) {
  const { scrollToError } = useLayoutScroll();

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToError();
    }
  }, [errors, scrollToError]);
}

export default useScrollToError;
