import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { fetchCountries as fetchCountriesAction } from 'domains/Shipping/Addresses/addressesSlice';
import { getCountriesOptions } from '../../../ShippingMethods/shippingSlice/shippingSelectors';

export interface UseGetCountriesOptionsResult {
  countries: { value: string; label: string }[];
  isLoading: boolean;
  isError: boolean;
  fetchCountries: () => void;
}

function useGetCountriesOptions(): UseGetCountriesOptionsResult {
  const dispatch = useAppDispatch();
  const { countries, isLoading, isError } = useSelector(getCountriesOptions);

  const fetchCountries = useCallback(() => {
    dispatch(fetchCountriesAction());
  }, [dispatch]);

  return { countries, isLoading, isError, fetchCountries };
}

export default useGetCountriesOptions;
