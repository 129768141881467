import {
  ComposedChart,
  Area,
  Bar,
  YAxis,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from 'recharts';
import { CHART_COLORS } from 'domains/Statistics/utils';
import { CONVERSION_BAR_COLORS } from '../../utils';
import ConversionYAxisTick from '../ConversionYAxisTick';

export interface ConversionBarChartInterface {
  stepNumber: number;
  stepName: string;
  stepValue: number;
  percentage: number;
}

interface ConversionBarChartProps {
  data: ConversionBarChartInterface[];
}

function ConversionBarChart({ data }: ConversionBarChartProps) {
  return (
    <ResponsiveContainer height={600} width="100%">
      <ComposedChart
        margin={{ left: 80, right: 8, top: 32, bottom: 64 }}
        layout="vertical"
        data={data.map((entity) => ({
          ...entity,
          fill: CONVERSION_BAR_COLORS[entity.stepName] || CHART_COLORS.navyBlue,
        }))}
        barSize={20}
      >
        <CartesianGrid horizontal={false} />
        <YAxis
          scale="point"
          tick={<ConversionYAxisTick width={130} data={data} />}
          axisLine={false}
          tickLine={false}
          yAxisId="0"
          type="category"
        />
        <XAxis type="number" hide />
        <Area
          type="monotone"
          dataKey="percentage"
          strokeWidth={0}
          fill={CHART_COLORS.lightGray}
        />
        <Bar dataKey="percentage" fill={CHART_COLORS.navyBlue} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ConversionBarChart;
