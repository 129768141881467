import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  DraftOrderConfirmResponseDto,
  DraftOrderDetailsResponseDto,
  DraftOrdersListResponseDto,
  DraftOrdersResponseDto,
  PaginationResponseDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getDraftOrdersListPagination } from './draftOrdersSelectors';
import {
  fetchDraftOrdersList,
  fetchDraftOrderById as fetchDraftOrderByIdService,
  addDraftOrder as addDraftOrderService,
  confirmDraftOrder as confirmDraftOrderService,
  removeDraftOrder as removeDraftOrderService,
} from '../draftOrdersService';
import { NewDraftOrdersValuesInterface } from '../pages/NewDraftOrderPage/types';

type DraftOrderEntitiesType = Record<string, DraftOrdersResponseDto>;

interface DraftOrderEntitiesDetails {
  data: DraftOrderDetailsResponseDto | null;
  status: string;
}

export interface InterfaceDraftOrdersSchema {
  ids: string[];
  refreshStatus: string;
  entities: DraftOrderEntitiesType;
  entityDetails: DraftOrderEntitiesDetails;
  status: string;
  currentRequestID: string;
  pagination: PaginationResponseDto;
}

const paginationDefault = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0,
  perPage: 20,
  nextPage: null,
};

const initialState: InterfaceDraftOrdersSchema = {
  ids: [] as string[],
  status: 'idle',
  refreshStatus: 'idle',
  currentRequestID: '',
  entities: {},
  entityDetails: {
    status: 'idle',
    data: null,
  },
  pagination: paginationDefault,
};

type ThunkStateType = { state: RootStateType };

export const getDraftOrdersList = createAsyncThunk<
  DraftOrdersListResponseDto,
  undefined
>('draftOrders/fetchDraftOrdersList', async () => {
  const response = await fetchDraftOrdersList();
  return response;
});

export const getMoreDraftOrdersList = createAsyncThunk<
  DraftOrdersListResponseDto,
  undefined,
  ThunkStateType
>('draftOrders/getMoreDraftOrdersList', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const pagination = getDraftOrdersListPagination(state);
  if (!pagination.nextPage) {
    throw new Error('no valid fetch');
  }
  const response = await fetchDraftOrdersList({
    page: pagination.nextPage,
  });
  return response;
});

export const fetchDraftOrderById = createAsyncThunk<
  DraftOrderDetailsResponseDto,
  string
>('draftOrders/fetchDraftOrderById', async (id: string) => {
  const response = await fetchDraftOrderByIdService(id);
  return response;
});

export const addDraftOrder = createAsyncThunk<
  DraftOrderDetailsResponseDto,
  { draftOrder: NewDraftOrdersValuesInterface }
>('draftOrders/addDraftOrder', async ({ draftOrder }) => {
  const draftOrderResponse = addDraftOrderService(draftOrder);
  return draftOrderResponse;
});

export const confirmDraftOrder = createAsyncThunk<
  DraftOrderConfirmResponseDto,
  string
>('draftOrders/confirmDraftOrder', async (id) => {
  const response = await confirmDraftOrderService(id);
  return response;
});

export const removeDraftOrder = createAsyncThunk<void, string>(
  'draftOrders/removeDraftOrder',
  async (id) => {
    await removeDraftOrderService(id);
  },
);

export const getDraftOrderPage = createAsyncThunk<
  DraftOrdersListResponseDto,
  number
>('draftOrders/getDraftOrderPage', async (page) => {
  const response = await fetchDraftOrdersList({
    page: page,
  });
  return response;
});

export const refreshDraftOrders = createAsyncThunk<
  DraftOrdersListResponseDto,
  undefined
>('draftOrders/refreshDraftOrders', async () => {
  const data = await fetchDraftOrdersList();
  return data;
});

const draftOrdersSlice = createSlice({
  name: 'draftOrders',
  initialState,
  reducers: {
    cleanUpDraftOrderList(state) {
      state.ids = initialState.ids;
      state.entities = initialState.entities;
      state.refreshStatus = initialState.refreshStatus;
      state.status = initialState.status;
      state.pagination = initialState.pagination;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDraftOrdersList.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.results.reduce((acc, draftOrder) => {
        acc[draftOrder.id] = draftOrder;
        ids.push(draftOrder.id);
        return acc;
      }, {} as DraftOrderEntitiesType);
      state.ids = ids;
      state.status = 'success';
      state.pagination = action.payload.pagination;
      return state;
    });

    builder.addCase(getDraftOrdersList.pending, (state, action) => {
      state.status = 'loading';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getDraftOrdersList.rejected, (state, action) => {
      state.status = 'error';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getMoreDraftOrdersList.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.results.reduce((acc, draftOrder) => {
        acc[draftOrder.id] = draftOrder;
        ids.push(draftOrder.id);
        return acc;
      }, state.entities);
      state.ids = [...state.ids, ...ids];
      state.refreshStatus = 'success';
      state.status = 'success';
      state.pagination = action.payload.pagination;
      return state;
    });

    builder.addCase(getMoreDraftOrdersList.pending, (state, action) => {
      state.refreshStatus = 'refreshing';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getMoreDraftOrdersList.rejected, (state) => {
      state.refreshStatus = 'error';
      return state;
    });

    builder.addCase(fetchDraftOrderById.pending, (state) => {
      state.entityDetails.status = 'loading';
      state.entityDetails.data = null;
      return state;
    });
    builder.addCase(fetchDraftOrderById.rejected, (state) => {
      state.entityDetails.status = 'error';
      state.entityDetails.data = null;
      return state;
    });
    builder.addCase(fetchDraftOrderById.fulfilled, (state, action) => {
      state.entityDetails.status = 'success';
      state.entityDetails.data = action.payload;
      return state;
    });
    builder.addCase(removeDraftOrder.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg];
      state.ids = state.ids.filter((id) => id !== action.meta.arg);
      return state;
    });

    builder.addCase(refreshDraftOrders.pending, (state, action) => {
      state.status = 'success';
      state.refreshStatus = 'loading';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(refreshDraftOrders.rejected, (state) => {
      state.status = 'error';
      state.refreshStatus = 'idle';
      return state;
    });

    builder.addCase(refreshDraftOrders.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.results.reduce((acc, draftOrder) => {
        acc[draftOrder.id] = draftOrder;
        ids.push(draftOrder.id);
        return acc;
      }, {} as DraftOrderEntitiesType);
      state.ids = ids;
      state.status = 'success';
      state.pagination = action.payload.pagination;
      return state;
    });

    builder.addCase(getDraftOrderPage.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.results.reduce((acc, draftOrder) => {
        acc[draftOrder.id] = draftOrder;
        ids.push(draftOrder.id);
        return acc;
      }, {} as DraftOrderEntitiesType);
      state.ids = ids;
      state.status = 'success';
      state.pagination = action.payload.pagination;
      return state;
    });

    builder.addCase(getDraftOrderPage.pending, (state, action) => {
      state.status = 'loading';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getDraftOrderPage.rejected, (state, action) => {
      state.status = 'error';
      state.currentRequestID = action.meta.requestId;
      return state;
    });
  },
});

export const { cleanUpDraftOrderList } = draftOrdersSlice.actions;
export const { reducer } = draftOrdersSlice;
