import { Table } from '@nimbus-ds/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus, Stack } from 'commons/components';
import useCoupons from 'domains/Marketing/Coupons/hooks/useCoupons';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  CouponsListSkeleton,
  CouponsListRow,
  CouponsListHeader,
} from './components';

export function CouponsListDesktop() {
  const t = useTranslationMarketing();
  const { scrollToTop } = useLayoutScroll();
  const { couponsIds, pagination, isLoading, changeFilters, filters } =
    useCoupons();

  const handleSelectPage = (page: number) => {
    scrollToTop();
    changeFilters({ ...filters, page });
  };

  return (
    <Stack spacing="tight" align="stretch" column>
      <Table>
        <CouponsListHeader />
        <Table.Body>
          {isLoading ? (
            <CouponsListSkeleton />
          ) : (
            couponsIds.map((id) => <CouponsListRow key={id} id={id} />)
          )}
        </Table.Body>
      </Table>
      {!isLoading && (
        <PaginationStratus
          currentPage={pagination.currentPage}
          totalItems={pagination.totalResults || 0}
          itemName={t('coupons.pagination.items')}
          page={pagination.currentPage}
          perPage={pagination.perPage}
          onPageSelect={handleSelectPage}
        />
      )}
    </Stack>
  );
}
