import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  getValidationActionName,
  getValidationToken,
} from 'App/axiosUtils/requestValidation';
import { StoreType } from 'App/store';
import { logout } from 'domains/Auth/authSlice';
import requiredValidation from './requiredValidation';

const addLeadingSlash = (url?: string): string => {
  if (!url) return '';

  if (url.startsWith('/')) return url;

  return `/${url}`;
};

const setAxiosInterceptors = (
  axiosInstance: AxiosInstance,
  store: StoreType,
) => {
  axiosInstance.interceptors.request.use(
    async (request: AxiosRequestConfig) => {
      if (!request.url) return request;

      request.url = addLeadingSlash(request.url);

      const state = store.getState();
      const storeId = state.auth.data?.storeId ?? 'no-defined';
      const userId = state.auth.data?.user.id ?? 'no-defined';
      const storeCountry = state.auth.data?.country ?? 'no-defined';
      const authorizationCode =
        state.authenticationFactor.verifyAndAuthorize.data?.authorizationCode ??
        'no-defined';
      request.headers = {
        ...request.headers,
        'x-store-id': storeId,
        'x-user-id': userId,
        'x-authentication-factor-code': authorizationCode,
        'x-store-country': storeCountry,
      };

      const pathsWithVerification =
        state.auth.data?.pathsWithVerification ?? [];

      if (!requiredValidation(request, pathsWithVerification)) {
        return request;
      }

      const actionName = getValidationActionName(
        request.url,
        pathsWithVerification,
      );

      const token = await getValidationToken(actionName);
      request.headers = { ...request.headers, 'x-verification-token': token };
      return request;
    },
  );

  axiosInstance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      if (axios.isAxiosError(error)) {
        const isAuthorize = error.response?.config.url === '/auth/v1';
        const isCreateSession = error.response?.config.url === '/auth/v1/token';
        const unauthorized = 401;

        if (
          !isAuthorize &&
          !isCreateSession &&
          error.response?.status === unauthorized
        ) {
          await store.dispatch(logout()).unwrap();
        }
      }
      return Promise.reject(error);
    },
  );
};

export default setAxiosInterceptors;
