import React from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { NotificationComponentProps } from '../../types';

// eslint-disable-next-line react/function-component-definition
const ModalNotification: React.FC<NotificationComponentProps> = ({
  id,
  title,
  message,
  show,
  onClose,
  action,
  'data-testid': dataTestId,
}) => (
  <Modal key={id} open={show} onDismiss={onClose} data-testid={dataTestId}>
    <Modal.Header title={title} />
    <Modal.Body padding="none">
      <Text as="p" fontSize="base" lineHeight="base" textAlign="left">
        {message}
      </Text>
    </Modal.Body>
    {!!action && <Modal.Footer>{action}</Modal.Footer>}
  </Modal>
);

export default ModalNotification;
