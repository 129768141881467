import { Box, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CopyLinkBox } from './CopyLinkBox';

interface CNameRecordInfoProps {
  ourDomain: string;
  isSubdomain: boolean;
}

export function CNameRecordInfo({
  ourDomain,
  isSubdomain,
}: Readonly<CNameRecordInfoProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.cname');

  return (
    <Box
      backgroundColor="neutral-surface"
      display="flex"
      flexDirection="column"
      borderRadius="base"
      gap="2"
    >
      <Text>
        <Trans
          t={t}
          i18nKey="copyText"
          components={{
            helpLink: (
              <Link
                as="a"
                appearance="primary"
                href={isSubdomain ? t('helpLinkSubdomain') : t('helpLink')}
                target="_blank"
                textDecoration="none"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
      <CopyLinkBox
        value={ourDomain}
        message={t('copySuccess')}
        text={ourDomain}
        hasBackground
      />
    </Box>
  );
}
