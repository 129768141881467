import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getDevModeAction } from '../devModeSlice';
import { getDevModeSelector } from '../devModeSlice/devModeSelectors';

function useDevMode() {
  const dispatch = useAppDispatch();
  const devMode = useSelector(getDevModeSelector);

  const fetchDevMode = useCallback(
    (partnerId?: number) => {
      if (partnerId) {
        dispatch(getDevModeAction(partnerId));
      }
    },
    [dispatch],
  );

  return {
    devModeEnabled: devMode?.devModeEnabled,
    fetchDevMode,
  };
}

export default useDevMode;
