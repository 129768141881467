import { ErrorState } from '@tiendanube/components';
import { HeaderContent, IonPageStratus } from 'commons/components';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function ErrorComponent(): JSX.Element {
  const t = useTranslationBilling();
  const { refreshInvoiceInfo } = useGetInvoiceInfo();

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('invoiceListPage.title')} />}
      width="small"
    >
      <ErrorState
        title={t('invoicesInfoPage.errors.internalError')}
        action={{
          onClick: refreshInvoiceInfo,
          children: t('invoicesInfoPage.errors.retry'),
        }}
      />
    </IonPageStratus>
  );
}

export default ErrorComponent;
