import { BaseCard, Grid, Input, Stack } from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';
import { MexicanAddress } from '../../../../../Addresses';

interface MexicanFiscalDataFormSkeletonProps {
  onCancel: () => void;
}

function MexicanFiscalDataFormSkeleton({
  onCancel,
}: MexicanFiscalDataFormSkeletonProps): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Grid spacing="loose">
            <Grid.Row>
              {[
                <Grid.Column key={1}>
                  <Stack column align="stretch">
                    <Input.Skeleton />
                  </Stack>
                </Grid.Column>,
                <Grid.Column key={2}>
                  <Stack column align="stretch">
                    <Input.Skeleton />
                  </Stack>
                </Grid.Column>,
                <Grid.Column key={3}>
                  <Stack column align="stretch">
                    <Input.Skeleton />
                  </Stack>
                </Grid.Column>,
              ]}
            </Grid.Row>
            <MexicanAddress.Skeleton />
          </Grid>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        onCancel={onCancel}
        onSave={() => null}
        isDisabled
      />
    </>
  );
}

export default MexicanFiscalDataFormSkeleton;
