import './Toggle.scss';

interface ToggleProps {
  name: string;
  onChange?: (name: string) => void;
  checked: boolean;
}

function Toggle({ name, onChange, checked }: ToggleProps) {
  const handleOnChange = () => {
    onChange?.(name);
  };

  return (
    <label id={name} htmlFor={name} className="stratus--toggle">
      <input
        type="checkbox"
        onChange={handleOnChange}
        checked={checked}
        name={name}
      />
      <span onClick={handleOnChange} className="stratus--slider round" />
    </label>
  );
}

export default Toggle;
