import { Radio, Text, Label } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { Categories } from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { SelectionModes } from './types';

export function CategoriesSelector({
  categories,
  categoriesType,
  onChange,
  onChangeCategories,
}) {
  const t = useTranslationMarketing('freeShipping');

  return (
    <Stack column spacing="tight" align="flex-start">
      <Text fontWeight="bold">{t('settingsPage.conditions.categories')}</Text>
      <Stack spacing="tight">
        <Radio
          id="category-type-1"
          name="categoriesType"
          as="button"
          value={SelectionModes.ALL}
          checked={categoriesType === SelectionModes.ALL}
          label={t('asides.categories.all.label')}
          onChange={onChange}
        />
        <Radio
          id="category-type-2"
          name="categoriesType"
          as="button"
          value={SelectionModes.SOME}
          checked={categoriesType === SelectionModes.SOME}
          label={t('asides.categories.some.label')}
          onChange={onChange}
        />
      </Stack>
      {categoriesType === SelectionModes.ALL && (
        <Stack column align="stretch">
          <Stack.Item>
            <Label>{t('asides.categories.all.description')}</Label>
          </Stack.Item>
        </Stack>
      )}
      {categoriesType === SelectionModes.SOME && (
        <Stack column align="stretch">
          <Stack.Item>
            <Label>{t('asides.categories.some.description')}</Label>
          </Stack.Item>
          <Stack.Item>
            <Categories
              categories={categories}
              emptyTitle={t('asides.categories.empty.title')}
              emptyText={t('asides.categories.empty.text')}
              ctaText={t('asides.categories.empty.ctaText')}
              onChange={onChangeCategories}
              showTitle={false}
              selectLinkText={t('asides.categories.selector.select')}
              editLinkText={t('asides.categories.selector.edit')}
              subtitle={t('asides.categories.selector.description')}
            />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
}
