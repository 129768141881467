const SECURITY_BASE_ROUTE = '/account/security';

export const ACCOUNT_ROUTES = {
  userPreferences: '/preferences/users/',
  businessData: '/account/shop',
  socialNetworks: '/account/social-networks',
  shopCancellation: '/account/cancellation',
  authenticationFactor: `${SECURITY_BASE_ROUTE}/authentication-factor`,
  sessionManagement: `${SECURITY_BASE_ROUTE}/session-management`,
  canceledStore: '/account/canceled',
  users: '/account/users',
  userForm: '/account/users/:id',
};
