import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationListBody from './components/LocationListBody';

interface LocationsListDesktopProps {
  openSetMainModal: (locationId: any) => void;
  openDeleteModal: (locationId: any) => void;
}

function LocationsListDesktop({
  openSetMainModal,
  openDeleteModal,
}: Readonly<LocationsListDesktopProps>): JSX.Element {
  const { locations, isLoading } = useLocations();
  const t = useTranslationShipping();
  const hasShippingLocationTag = useHasShippingLocationTag();

  return (
    <Table>
      <Table.Head>
        <CustomHeaderCell width="10%">
          {t('locations.headers.priority')}
        </CustomHeaderCell>
        <CustomHeaderCell width="20%">
          {t('locations.headers.name')}
        </CustomHeaderCell>
        <CustomHeaderCell width="50%">
          {t('locations.headers.address')}
        </CustomHeaderCell>
        {hasShippingLocationTag && (
          <CustomHeaderCell width="25%">
            {t('locations.headers.tags')}
          </CustomHeaderCell>
        )}
        <CustomHeaderCell width="20%">
          {t('locations.headers.actions')}
        </CustomHeaderCell>
      </Table.Head>
      {isLoading ? (
        <LocationListBody.Skeleton />
      ) : (
        <LocationListBody
          locations={locations}
          openSetMainModal={openSetMainModal}
          openDeleteModal={openDeleteModal}
        />
      )}
    </Table>
  );
}

export default LocationsListDesktop;
