import { VirtualDataList } from 'commons/components';
import { PromotionsListPageBase } from 'domains/Marketing/Promotions/components';
import { usePromotionsMobile } from 'domains/Marketing/Promotions/hooks';
import {
  PromotionsListMobileRow,
  PromotionsListMobileSkeleton,
} from './components';

export function PromotionsListMobilePage() {
  const {
    ids,
    data,
    showSkeleton,
    showSpinner,
    isDataEmpty,
    isError,
    refetch,
    handleInfiniteScroll,
    handlePullToRefresh,
  } = usePromotionsMobile();

  const intemContent = (index: number) => (
    <PromotionsListMobileRow promotion={data[index]} index={index} />
  );

  return (
    <PromotionsListPageBase
      skeleton={<PromotionsListMobileSkeleton />}
      isLoading={showSkeleton}
      isEmpty={isDataEmpty}
      isError={isError}
      onRetry={refetch}
    >
      <VirtualDataList
        onRefresh={handlePullToRefresh}
        isPullable
        data={ids}
        endReached={handleInfiniteScroll}
        itemContent={intemContent}
        showFooter={showSpinner}
      />
    </PromotionsListPageBase>
  );
}
