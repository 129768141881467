import { Text, Title } from '@nimbus-ds/components';
import { InterfaceNameValue } from '@tiendanube/components';
import { CardCollapsible, Stack } from 'commons/components';
import {
  CategoriesInterface,
  ProductsInterface,
  ScopeSelector,
} from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';
import { CouponErrorsInterface, CouponValuesInterface } from '../../types';

interface ApplyToProps {
  values: CouponValuesInterface;
  errors: Partial<CouponErrorsInterface>;
  onChange: ({ name, value }: InterfaceNameValue) => void;
  onChangeCategories: (categories: CategoriesInterface[]) => void;
  onChangeProducts: (products: ProductsInterface[]) => void;
  collapsed: boolean;
  disabled?: boolean;
}

export function ApplyTo({
  values,
  errors,
  onChange,
  onChangeCategories,
  onChangeProducts,
  collapsed,
  disabled = false,
}: Readonly<ApplyToProps>) {
  const translationPrefix = 'coupons.couponForm.applyTo';
  const t = useTranslationMarketing(translationPrefix);

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h4">{t('title')}</Title>
          <Text>{t('optional')}</Text>
        </Stack>
      }
      active={!collapsed}
    >
      <Stack column align="stretch">
        <ScopeSelector
          values={values}
          errors={errors}
          disabled={disabled}
          onChange={onChange}
          onChangeCategories={onChangeCategories}
          onChangeProducts={onChangeProducts}
          translationPrefix={`${translationPrefix}.scope`}
        />
      </Stack>
    </CardCollapsible>
  );
}

ApplyTo.Skeleton = Skeleton;
