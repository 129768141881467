import { ChangeEvent } from 'react';
import { CardWithTitle, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { GoogleAnalitycs } from './GoogleAnalitycs';
import { GoogleMerchantId } from './GoogleMerchantId';
import { GoogleStoreOwnership } from './GoogleStoreOwnership';
import { GoogleTagManager } from './GoogleTagManager';
import { Skeleton } from './Skeleton';

interface ExternalCodesGoogleCardProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  googleAnalyticsGtmId: string;
  googleAnalyticsMeasurementId: string;
  googleAnalyticsApiSecret: string;
  googleMeta: string;
  googleMerchantId: string;
}

export function ExternalCodesGoogleCard({
  onChange,
  onChangeTextArea,
  googleAnalyticsApiSecret,
  googleAnalyticsGtmId,
  googleAnalyticsMeasurementId,
  googleMerchantId,
  googleMeta,
}: Readonly<ExternalCodesGoogleCardProps>) {
  const t = useTranslationConfigurations('externalCodes.google');
  return (
    <CardWithTitle title={t('title')}>
      <Stack column spacing="loose" align="stretch">
        <GoogleTagManager
          onChange={onChange}
          googleAnalyticsGtmId={googleAnalyticsGtmId}
        />
        <GoogleAnalitycs
          onChange={onChange}
          googleAnalyticsApiSecret={googleAnalyticsApiSecret}
          googleAnalyticsMeasurementId={googleAnalyticsMeasurementId}
        />
        <GoogleStoreOwnership
          onChangeTextArea={onChangeTextArea}
          googleMeta={googleMeta}
        />
        <GoogleMerchantId
          onChange={onChange}
          googleMerchantId={googleMerchantId}
        />
      </Stack>
    </CardWithTitle>
  );
}

ExternalCodesGoogleCard.Skeleton = Skeleton;
