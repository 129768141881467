import { Icon, Text, Link } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { PlusCircleIcon } from '@tiendanube/icons';
import { useModal } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import EnableCountryModal from '../EnableCountryModal';

function EnableCountryLink() {
  const t = useTranslationConfigurations(
    'languagesAndCurrencies.enabledCountries',
  );
  const [
    showAddAnotherCountryModal,
    openAddAnotherCountryModal,
    closeAddAnotherCountryModal,
  ] = useModal();

  return (
    <>
      <DataList.Row>
        <Link textDecoration="none" onClick={openAddAnotherCountryModal}>
          <Icon color="primary-interactive" source={<PlusCircleIcon />} />
          <Text color="primary-interactive">{t('enabledCountry')}</Text>
        </Link>
      </DataList.Row>
      {showAddAnotherCountryModal && (
        <EnableCountryModal
          show={showAddAnotherCountryModal}
          onClose={closeAddAnotherCountryModal}
        />
      )}
    </>
  );
}

export default EnableCountryLink;
