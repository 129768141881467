import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getTrialMessagesAction } from 'domains/Dashboard/slices/trialMessagesSlice';
import {
  getTrialMessagesData,
  getTrialMessagesStatus,
} from 'domains/Dashboard/slices/trialMessagesSlice/trialMessagesSelectors';

function useTrialMessages() {
  const dispatch = useAppDispatch();
  const trialMessage = useSelector(getTrialMessagesData);
  const status = useSelector(getTrialMessagesStatus);

  const getTrialMessages = useCallback(() => {
    dispatch(getTrialMessagesAction());
  }, [dispatch]);

  return {
    ...status,
    trialMessage,
    getTrialMessages,
  };
}

export default useTrialMessages;
