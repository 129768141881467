import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { useTranslationAccount } from 'domains/Account/hooks';
import { Steps, useSteps } from './useSteps';

interface UseAuthenticationFactorPageTextsResult {
  title: string;
  subtitle: string | undefined;
}

export function useAuthenticationFactorPageTexts(
  useStepsResult: ReturnType<typeof useSteps>,
  someFactorIsActive: boolean,
): UseAuthenticationFactorPageTextsResult {
  const t = useTranslationAccount('authenticationFactorPage');
  const { step } = useStepsResult;

  function getTitle() {
    if (step === Steps.List) return t('title');
    switch (useStepsResult.currentFactor) {
      case AuthenticationFactorTypes.TOTP:
        return t('totp.title');
      case AuthenticationFactorTypes.RECOVERY_CODE:
        return step === Steps.LinkAccount
          ? t('linkAccountPage.recoveryCodesTitle')
          : t('recoveryCodes.title');
    }
  }

  function getSubtitle() {
    if (step === Steps.List && !someFactorIsActive) return t('description');
    if (step === Steps.RecoveryCodes) return t('recoveryCodesPage.subtitle');
    return undefined;
  }

  return {
    title: getTitle(),
    subtitle: getSubtitle(),
  };
}
