import { Input, Text } from '@nimbus-ds/components';
import { FormField, FormFieldInputBaseProps } from '@nimbus-ds/patterns';

interface InputLocationProps extends FormFieldInputBaseProps {
  readonly isLoading?: boolean;
}
function InputForLocation({
  isLoading,
  ...props
}: InputLocationProps): JSX.Element {
  return (
    <>
      {isLoading && (
        <>
          <Text>{props.label}</Text>
          <Input.Skeleton />
        </>
      )}
      {!isLoading && <FormField.Input {...props} />}
    </>
  );
}

export default InputForLocation;
