import { useCallback, useState } from 'react';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';
import { UpdatePaymentProviderSettingsRequestDto } from '@tiendanube/common';
import { ErrorState, Link } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  DiscountsCard,
  InstallmentsCard,
  PaymentProviderSettingsSkeleton,
} from './components';
import { getValidationSchema } from './validationSchema';
import { usePaymentProviderSettings } from '../../hooks';

const TRANSLATE_PREFIX = 'paymentProvider';

const buildPayload = (
  values: UpdatePaymentProviderSettingsRequestDto,
  appId: string,
  canConfigureMinInstallmentValue: boolean,
) => {
  if (!canConfigureMinInstallmentValue) {
    values = omit(values, 'minInstallmentValues');
  }

  return {
    ...values,
    appId,
  };
};

function PaymentProviderSettingsPage(): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { t: globalTranslation } = useTranslation();
  const { goBack } = useNavegate();
  const [successSubmit, setSuccessSubmit] = useState(false);
  const { id } = useParams<{ id: string }>();

  const {
    isLoading,
    isError,
    updateStatus,
    paymentProvider,
    paymentProviderSettingValues,
    fetchInstalledPaymentProviders,
    updatePaymentProviderSettings,
  } = usePaymentProviderSettings(id);

  const {
    values,
    isDirty,
    errors,
    handleOnChange,
    handleOnChangeGroup,
    handleOnSubmit,
    handleValidations,
  } = useForm({
    initialValues: paymentProviderSettingValues,
    validationSchema: getValidationSchema(paymentProvider),
    onSubmit: (values) => {
      if (!paymentProvider?.appId) return;

      setSuccessSubmit(true);
      updatePaymentProviderSettings(
        buildPayload(
          values,
          paymentProvider.id,
          paymentProvider.canConfigureMinInstallmentValue,
        ),
      );
    },
  });

  const handleSuccess = useCallback(() => {
    fetchInstalledPaymentProviders();
    goBack();
  }, [fetchInstalledPaymentProviders, goBack]);

  useToastStatus({
    error: t('settings.toast.updateError'),
    success: t('settings.toast.updateSuccess'),
    status: updateStatus,
    onSuccess: handleSuccess,
  });

  const title = !isLoading
    ? t('settings.title', {
        paymentProvider: paymentProvider?.name,
      })
    : t('settings.titleLoading');

  const showInstallmentsCard =
    paymentProvider?.canConfigureMinInstallmentValue &&
    paymentProvider.minInstallmentValues;
  const showDiscountsCard = !!paymentProvider?.discounts;
  const showMoreSettingsLink = !!paymentProvider?.configurationUrl;

  const isUpdateLoading = updateStatus === 'loading';

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={title} />}
    >
      <Prompt
        when={isDirty && !successSubmit}
        message={globalTranslation('common:exitEdit.info')}
      />
      {isLoading && <PaymentProviderSettingsSkeleton />}
      {isError && (
        <ErrorState
          title={t('settings.error.title')}
          action={{
            children: t('settings.error.action'),
            onClick: fetchInstalledPaymentProviders,
          }}
        />
      )}

      {!isLoading && paymentProvider && (
        <>
          {showInstallmentsCard && (
            <InstallmentsCard
              minInstallmentValues={paymentProvider.minInstallmentValues || []}
              values={values}
              errors={errors}
              handleOnChangeGroup={handleOnChangeGroup}
            />
          )}
          {showDiscountsCard && (
            <DiscountsCard
              discounts={paymentProvider.discounts || []}
              values={values}
              errors={errors}
              handleOnChange={handleOnChange}
              handleOnChangeGroup={handleOnChangeGroup}
            />
          )}
          {showMoreSettingsLink && (
            <Link
              href={paymentProvider?.configurationUrl}
              target="_blank"
              appearance="primary"
              icon={ExternalLinkIcon}
              iconPosition="end"
            >
              {t('settings.moreSettingsLink', {
                paymentProvider: paymentProvider?.name,
              })}
            </Link>
          )}
          <CancelAndSaveButtonsWithAuth
            typeModal="centered"
            payload={buildPayload(
              values,
              paymentProvider.id || '',
              paymentProvider.canConfigureMinInstallmentValue,
            )}
            onSave={handleOnSubmit}
            preSubmitValidation={handleValidations}
            onCancel={goBack}
            isDisabled={isUpdateLoading || !isDirty}
            isLoading={isUpdateLoading}
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default PaymentProviderSettingsPage;
