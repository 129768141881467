import { ChangeEvent, useState } from 'react';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { v4 as uuidv4 } from 'uuid';
import { NavigationMenuItemResponseDto } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import { LanguageInterface } from 'App/i18n';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  Stack,
  TabsMultiLanguage,
} from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTranslationOnline } from 'domains/Online';
import {
  MenuItemNameInput,
  SelectCategory,
  SelectContentPage,
  SelectMenu,
  SelectRedirectTo,
} from './components';
import { useMenuItemForm } from './hooks';

interface MenuItemModalProps {
  currentMenuId?: string;
  item?: NavigationMenuItemResponseDto;
  show: boolean;
  onClose: () => void;
  onAdd: (item: NavigationMenuItemResponseDto) => void;
  onEdit: (item: NavigationMenuItemResponseDto) => void;
}

function MenuItemModal({
  currentMenuId,
  item,
  show,
  onClose,
  onAdd,
  onEdit,
}: MenuItemModalProps) {
  const t = useTranslationOnline('menus.menuItemModal');
  const language = useGetLanguage();
  const [nameError, setNameError] = useState('');
  const [urlError, setUrlError] = useState('');

  const {
    name,
    selected,
    customData,
    onChangeName,
    onSelect,
    onSelectSubtype,
    onChangeUrl,
    cleanValues,
  } = useMenuItemForm(item);

  const isNewMenuItem = !item?.id;
  const isNameEmpty = !Object.values(name).some((trad) => trad.trim() !== '');

  const handleChangeName = (name: LanguageInterface) => {
    setNameError('');
    onChangeName(name);
  };

  const handleChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlError('');
    onChangeUrl(e.target.value);
  };

  const handleClose = () => {
    if (isNewMenuItem) cleanValues();
    setNameError('');
    onClose();
  };

  const handleSave = () => {
    if (!name || isNameEmpty) {
      setNameError(t('emptyName'));
      return;
    }

    if (
      selected === 'url' &&
      (!customData?.url || customData?.url.trim() === '')
    ) {
      setUrlError(t('emptyUrl'));
      return;
    }

    if (isNewMenuItem) {
      onAdd({
        name,
        id: `new-${uuidv4()}`,
        type: selected,
        data: customData,
      });
    } else {
      onEdit({
        id: item.id,
        name,
        type: selected,
        data: customData,
      });
    }

    handleClose();
  };

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={handleClose}
      headerTop={<HeaderTop navigation={{ onClick: handleClose }} />}
      headerContent={
        <HeaderContent
          title={isNewMenuItem ? t('title') : item.name[language]}
        />
      }
    >
      <Stack column align="stretch" spacing="base">
        <TabsMultiLanguage>
          <MenuItemNameInput
            name={name}
            onChange={handleChangeName}
            error={nameError}
          />
        </TabsMultiLanguage>
        <SelectRedirectTo
          selected={selected}
          onSelect={onSelect}
          currentMenuId={currentMenuId}
        />
        {selected === 'category' && (
          <SelectCategory
            selected={customData?.id}
            onSelect={onSelectSubtype}
          />
        )}
        {selected === 'page' && (
          <SelectContentPage
            selected={customData?.id}
            onSelect={onSelectSubtype}
          />
        )}
        {selected === 'url' && (
          <FormField.Input
            id="url"
            placeholder="https://"
            helpText={urlError ?? ''}
            appearance={urlError ? 'danger' : undefined}
            helpIcon={urlError ? ExclamationCircleIcon : undefined}
            onChange={handleChangeUrl}
            defaultValue={customData?.url}
            showHelpText
          />
        )}
        {selected === 'menu' && (
          <SelectMenu
            currentMenuId={currentMenuId}
            selected={customData?.id}
            onSelect={onSelectSubtype}
          />
        )}
      </Stack>
      <CancelAndSaveButtons
        onCancel={handleClose}
        onSave={handleSave}
        isDisabled={isNameEmpty}
        saveText={isNewMenuItem ? t('add') : t('save')}
      />
    </ModalAside>
  );
}

export default MenuItemModal;
