import { ChangeEvent, useEffect, useRef } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import isEmpty from 'lodash.isempty';
import { Country } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { COUNTRY_WITH_LOCATION_FIELDS } from 'domains/Shipping/Locations/pages/constants';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  AddressCardErrorsInterface,
  AddressCardValuesInterface,
} from '../../AddressCard';
import Skeleton from '../../Skeleton';
import InputForLocation from '../InputForLocation';
import SelectForLocation from '../SelectForLocation';

export interface AddressCardGeneralProps {
  values: AddressCardValuesInterface;
  errors: Partial<AddressCardErrorsInterface>;
  countries: { value: string; label: string }[];
  provinces: { value: string; label: string }[];
  loadingCountries: boolean;
  loadingProvinces: boolean;
  fetchProvinces: (country: string) => Promise<string>;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldValue: (name: string, value: string) => void;
  clearFields: () => void;
}

function AddressCardGeneral({
  values,
  errors,
  countries,
  provinces,
  loadingCountries,
  loadingProvinces,
  fetchProvinces,
  onChange,
  setFieldValue,
  clearFields,
}: AddressCardGeneralProps): JSX.Element {
  const t = useTranslationShipping();
  const countryTranslate =
    values.country in COUNTRY_WITH_LOCATION_FIELDS
      ? values.country
      : COUNTRY_WITH_LOCATION_FIELDS[Country.AR];

  const cityHelpText = t(errors.city || '');
  const localityHelpText = t(errors.locality || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');
  const zipcodeHelpText = t(errors.zipcode || '');

  const handleChangeCountry = async (
    e: ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    clearFields();
    onChange(e);
    if (e.target.name === 'country') {
      if (e.target.value === Country.BR) {
        setFieldValue('province', '');
        return;
      }

      const province = await fetchProvinces(e.target.value);
      setFieldValue('province', province ?? '');
    }
  };

  const alreadyFetched = useRef(false);

  useEffect(() => {
    if (!alreadyFetched.current && !isEmpty(values.country)) {
      fetchProvinces(values.country).then((provinceCode) => {
        const province =
          values.province && !isEmpty(values.province)
            ? values.province
            : provinceCode;
        setFieldValue('province', province);
      });
      alreadyFetched.current = true;
    }
  }, [fetchProvinces, setFieldValue, values]);

  return (
    <Card title={t('locations.form.address.title')}>
      <Card.Header>
        <Title as="h3">{t('locations.form.address.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <SelectForLocation
            label={t(
              `locations.form.address.fields.${countryTranslate}.country`,
            )}
            name="country"
            id="country"
            value={values.country}
            onChange={handleChangeCountry}
            options={countries}
            isLoading={loadingCountries}
          />

          <SelectForLocation
            label={t(
              `locations.form.address.fields.${countryTranslate}.province`,
            )}
            name="province"
            id="province"
            value={values.province ?? ''}
            onChange={onChange}
            options={provinces}
            isLoading={loadingProvinces || loadingCountries}
          />

          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.${countryTranslate}.city`)}
            name="city"
            value={values.city ?? ''}
            helpText={cityHelpText}
            showHelpText={!!cityHelpText && !values.city}
            appearance={cityHelpText && !values.city ? 'danger' : 'none'}
            onChange={onChange}
          />

          <InputForLocation
            type="text"
            label={t(
              `locations.form.address.fields.${countryTranslate}.street`,
            )}
            onChange={onChange}
            name="street"
            value={values.street ?? ''}
            helpText={streetHelpText}
            showHelpText={!!streetHelpText && !values.street}
            appearance={streetHelpText && !values.street ? 'danger' : 'none'}
          />

          <Stack spacing="tight" align="flex-start">
            <InputForLocation
              type="text"
              label={t(
                `locations.form.address.fields.${countryTranslate}.number`,
              )}
              onChange={onChange}
              name="number"
              value={values.number ?? ''}
              helpText={numberHelpText}
              showHelpText={!!numberHelpText && !values.number}
              appearance={numberHelpText && !values.number ? 'danger' : 'none'}
            />

            <InputForLocation
              type="text"
              label={t(
                `locations.form.address.fields.${countryTranslate}.floor`,
              )}
              onChange={onChange}
              name="floor"
              value={values.floor ?? ''}
            />
          </Stack>
          <InputForLocation
            type="text"
            label={t(
              `locations.form.address.fields.${countryTranslate}.locality`,
            )}
            onChange={onChange}
            name="locality"
            value={values.locality ?? ''}
            helpText={localityHelpText}
            showHelpText={!!localityHelpText && !values.locality}
            appearance={
              localityHelpText && !values.locality ? 'danger' : 'none'
            }
          />

          <InputForLocation
            type="integer"
            label={t(
              `locations.form.address.fields.${countryTranslate}.zipcode`,
            )}
            onChange={onChange}
            name="zipcode"
            value={values.zipcode ?? ''}
            helpText={zipcodeHelpText}
            showHelpText={!!zipcodeHelpText}
            appearance={zipcodeHelpText ? 'danger' : 'none'}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

AddressCardGeneral.Skeleton = Skeleton;

export default AddressCardGeneral;
