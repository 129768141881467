import { Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { InternalNavLink, Stack } from 'commons/components';
import useCouponById from 'domains/Marketing/Coupons/hooks/useCoupon';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { CouponDiscountType, Limited, Status, Actions } from '../..';

interface CouponsListRowProps {
  id: string;
  index: number;
}

export function CouponsListRow({ index, id }: Readonly<CouponsListRowProps>) {
  const t = useTranslationMarketing();
  const { coupon } = useCouponById(id);

  if (!coupon) return null;

  const { code, status, type, value, includeShipping, used, limited } = coupon;

  const showShipping = type !== 'shipping';
  const shipping = includeShipping
    ? t('coupons.details.shippingIncluded')
    : t('coupons.details.shippingNotIncluded');

  const showPopover =
    limited &&
    (limited.minPrice ||
      limited.categoriesV2?.length > 0 ||
      limited.products?.length > 0 ||
      (limited.startDate && limited.endDate) ||
      limited.maxUsesPerClient);

  const usageLimit = limited?.maxUses
    ? t('coupons.details.usageLimit', {
        used: used,
        maxUses: limited.maxUses,
      })
    : t('coupons.details.uses', { count: used });

  return (
    <DataList.Row backgroundColor="transparent" topDivider={index !== 0}>
      <Stack column align="stretch" gap="1">
        <Stack justify="space-between" align="center">
          <InternalNavLink to={`/coupons/${id}`}>
            <Text color="primary-interactive" fontWeight="medium">
              {code}
            </Text>
          </InternalNavLink>
          <Stack justify="flex-end" spacing="tight">
            {status !== 'deleted' && <Actions id={id} status={status} />}
          </Stack>
        </Stack>
        <Stack justify="space-between" align="center">
          <CouponDiscountType type={type} value={value} />
          <Text>{usageLimit}</Text>
        </Stack>
        {showShipping && <Text>{shipping}</Text>}
        {showPopover ? (
          <Limited
            minPrice={limited.minPrice}
            categories={limited.categoriesV2}
            products={limited.products}
            startDate={limited.startDate}
            endDate={limited.endDate}
            maxUsesPerClient={limited.maxUsesPerClient}
          />
        ) : (
          <Text>{t('coupons.details.unlimited')}</Text>
        )}
        <Status status={status} />
      </Stack>
    </DataList.Row>
  );
}
