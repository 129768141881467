import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function DomainsListDesktopHeader() {
  const t = useTranslationConfigurations('domains.list');

  return (
    <Table.Head>
      <CustomHeaderCell>{t('domains')}</CustomHeaderCell>
      <CustomHeaderCell width="200px">{t('domainStatus')}</CustomHeaderCell>
      <CustomHeaderCell width="190px">{t('domainSSLStatus')}</CustomHeaderCell>
      <CustomHeaderCell width="115px">{t('actions')}</CustomHeaderCell>
    </Table.Head>
  );
}
