import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OcaRegisterRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  requestOcaRegistrationAction,
  getRequestOcaRegistrationStatus,
} from '../../deliveryMethodsSlice';

function useRequestOcaRegistration() {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getRequestOcaRegistrationStatus,
  );

  const requestOcaRegistration = useCallback(
    (payload: OcaRegisterRequestDto) => {
      dispatch(requestOcaRegistrationAction(payload));
    },
    [dispatch],
  );

  return {
    requestOcaRegistration,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useRequestOcaRegistration;
