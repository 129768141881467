import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Alert, Link } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';

interface CustomerAnonymizeAlertProps {
  isAnonymized: boolean;
  startedAnonymized: boolean;
  anonymizationAt?: string;
  onCancelAnonymize: () => void;
}

function CustomerAnonymizeAlert({
  isAnonymized,
  startedAnonymized,
  anonymizationAt,
  onCancelAnonymize,
}: CustomerAnonymizeAlertProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <>
      {startedAnonymized && !isAnonymized && (
        <Alert
          appearance="warning"
          show
          title={t('customerDetails.removeCustomerData.startedAnonymizedTitle')}
        >
          {t('customerDetails.removeCustomerData.startedAnonymizedText', {
            date: dateFormat(anonymizationAt || '', Format.DAY_MONTH_YEAR),
          })}{' '}
          <Link underline onClick={onCancelAnonymize}>
            {t('customerDetails.removeCustomerData.startedAnonymizedCancel')}
          </Link>
        </Alert>
      )}

      {isAnonymized && (
        <Alert
          appearance="primary"
          show
          title={t('customerDetails.removeCustomerData.anonymizedTitle')}
        >
          {t('customerDetails.removeCustomerData.anonymizedText')}{' '}
          <Link
            href={t('customerDetails.removeCustomerData.moreInfoLink')}
            target="_blank"
            underline
          >
            {t('customerDetails.removeCustomerData.anonymizedMoreInfo')}
          </Link>
        </Alert>
      )}
    </>
  );
}

export default CustomerAnonymizeAlert;
