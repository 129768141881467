export const BASE_NOTIFICATIONS_URL = '/v1/notifications';

export const EMAIL_BY_ID = `${BASE_NOTIFICATIONS_URL}/:id`;
export const SEND_EMAIL_VALIDATION = `${BASE_NOTIFICATIONS_URL}/email-validation`;
export const VERIFY_VALIDATION_EMAIL = `${BASE_NOTIFICATIONS_URL}/email-verification`;
export const VERIFY_INMAIL_RECORD = `${BASE_NOTIFICATIONS_URL}/verify-inmail-record`;
export const SET_MAIL_FROM = `${BASE_NOTIFICATIONS_URL}/mail-from`;

export const notificationsEndpoints = {
  getEmailsList: 'getEmailsList',
  getEmail: 'getEmail',
  verifyInmailRecord: 'verifyInmailRecord',
  updateEmail: 'updateEmail',
  sendEmailValidation: 'sendEmailValidation',
  verifyValidationEmail: 'verifyValidationEmail',
  setMailFrom: 'setMailFrom',
} as const;

export const notificationsTags = {
  emailsList: 'emailsList',
} as const;

export const VERIFY_VALIDATION_EMAIL_CACHE_KEY =
  notificationsEndpoints.verifyValidationEmail;
