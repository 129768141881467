import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { LanguagesType } from 'App/i18n';
import { RootStateType } from 'App/store';
import { useGetLanguage } from 'domains/Auth/hooks';
import { createGetProductById } from 'domains/Catalog/Inventory/inventorySlice/inventorySelectors';

interface UseInventoryItemInterface {
  product: ProductDetailsResponseDto;
  language: LanguagesType;
}

function useInventoryItem(id: string): UseInventoryItemInterface {
  const getProductById = useMemo(createGetProductById, []);
  const product = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );
  const language = useGetLanguage();

  return { product, language };
}

export default useInventoryItem;
