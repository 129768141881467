import { Trans, useTranslation } from 'react-i18next';
import { Alert, Text } from '@tiendanube/components';
import Stack from '../Stack';

interface AlertImagesValidationProps {
  filenames: string[];
  validation: 'size' | 'type';
}
function AlertImagesValidation({
  filenames,
  validation,
}: AlertImagesValidationProps) {
  const { t } = useTranslation('common');

  const validationKey =
    validation === 'size'
      ? 'alertImagesExceededSize'
      : 'alertImagesWithBadType';

  if (filenames.length === 0) return null;
  return (
    <Alert appearance="warning" show>
      <Stack column>
        {filenames.map((filename) => (
          <Text key={filename}>
            <Trans
              t={t}
              i18nKey={validationKey}
              values={{ name: filename }}
              components={{ bold: <strong /> }}
            />
          </Text>
        ))}
      </Stack>
    </Alert>
  );
}

export default AlertImagesValidation;
