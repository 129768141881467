import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductDetailsResponseDto,
  UpdateStockResponseDto,
} from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import { initialState } from './constants';
import {
  StatusType,
  InventoryEntitiesType,
  InventoryProductsAndFiltersInterface,
  ThunkStateType,
} from './types';
import inventoryServices, {
  InventoryFiltersInterface,
  EditStockParams,
  UpdateInventoryProductParamsInterface,
  StockChange,
} from '../inventoryServices';

export const getInventoryProducts = createAsyncThunk<
  InventoryProductsAndFiltersInterface,
  InventoryFiltersInterface,
  ThunkStateType
>('catalog/inventory/getProducts', async (filters) => {
  const { products, total } = await inventoryServices.getInventoryProducts(
    filters,
  );
  return { products, filters, total };
});

export const getMoreInventoryProducts = createAsyncThunk<
  InventoryProductsAndFiltersInterface,
  InventoryFiltersInterface,
  ThunkStateType
>(
  'catalog/inventory/getMoreProducts',
  async (filters) => {
    const { products, total } = await inventoryServices.getInventoryProducts(
      filters,
    );
    return { products, filters, total };
  },
  {
    condition: (filters, { getState }) => {
      const state = getState();
      if (state.catalog.inventory.lastRequestedFilters === filters) {
        return false;
      }
    },
  },
);

export const editStock = createAsyncThunk<
  { productId: string; response: UpdateStockResponseDto[] },
  EditStockParams
>('catalog/inventory/editStock', async (params) => {
  const response = await inventoryServices.updateStock(params);
  return { productId: params.productId, response };
});

export const getInventoryProductById = createAsyncThunk<
  ProductDetailsResponseDto,
  string
>('catalog/inventory/getProductById', async (id) => {
  const product = await inventoryServices.getInventoryProductById(id);
  return product;
});

export const updateProduct = createAsyncThunk<
  ProductDetailsResponseDto,
  UpdateInventoryProductParamsInterface
>('catalog/products/updateProduct', async (params) => {
  const productUpdated = await inventoryServices.updateProduct(params);
  return productUpdated;
});

export const updateStock = createAsyncThunk<
  { productId: string; response: UpdateStockResponseDto[] },
  EditStockParams
>('catalog/inventory/update', async (params) => {
  const response = await inventoryServices.updateStock(params);
  return { productId: params.productId, response };
});

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    init(state) {
      state.status = StatusType.loading;
    },
    cleanupdateStock(state) {
      state.updateStockStatus = StatusType.idle;
    },
    cleanUpInventory(state) {
      state.status = initialState.status;
      state.entities = initialState.entities;
      state.totalProducts = initialState.totalProducts;
      state.ids = initialState.ids;
      state.appliedFilters = initialState.appliedFilters;
    },
    applyFilters(state, action: PayloadAction<InventoryFiltersInterface>) {
      state.appliedFilters = action.payload;
    },
    applyStockChange(state, action: PayloadAction<StockChange>) {
      const { productId, variantId, locationId, stock } = action.payload;
      const updateLevel = (level) =>
        !locationId || level.location_id === locationId
          ? { ...level, stock }
          : level;
      const updateVariant = (variant) =>
        variant.id === variantId
          ? {
              ...variant,
              inventory_levels: variant.inventory_levels?.map(updateLevel),
            }
          : variant;
      state.entities[productId].variants =
        state.entities[productId].variants.map(updateVariant);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder.addCase(getInventoryProducts.pending, (state, action) => {
      state.status = StatusType.loading;
      state.entities = {};
      state.ids = [];
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getInventoryProducts.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        const ids: string[] = [];
        state.entities = action.payload.products.reduce((acc, product) => {
          acc[product.id.toString()] = product;
          ids.push(product.id.toString());
          return acc;
        }, {} as InventoryEntitiesType);
        state.status = StatusType.success;
        state.ids = ids;
        state.totalProducts = action.payload.total;
        state.lastRequestedFilters = action.payload.filters;
        return state;
      }
    });

    builder.addCase(getInventoryProducts.rejected, (state) => {
      state.status = StatusType.error;
      return state;
    });

    builder.addCase(getMoreInventoryProducts.pending, (state) => {
      state.status = StatusType.loading;
      return state;
    });

    builder.addCase(getMoreInventoryProducts.fulfilled, (state, action) => {
      const ids: string[] = [];
      action.payload.products.forEach((product) => {
        state.entities[product.id] = product;
        ids.push(product.id.toString());
      });
      state.status = StatusType.success;
      state.ids = state.ids.concat(ids);
      state.totalProducts = action.payload.total;
      state.lastRequestedFilters = action.payload.filters;
      return state;
    });

    builder.addCase(getMoreInventoryProducts.rejected, (state) => {
      state.status = StatusType.error;
      return state;
    });

    builder.addCase(editStock.fulfilled, (state, action) => {
      state.entities[action.payload.productId].variants = state.entities[
        action.payload.productId
      ].variants.map((variant) => {
        const responseForVariant = action.payload.response.find(
          (updateStockResponse) => variant.id === updateStockResponse.variantId,
        );
        if (responseForVariant) {
          const inventoryLevelsUpdated = variant.inventory_levels?.map(
            (invetoryLevel) => ({
              ...invetoryLevel,
              stock:
                invetoryLevel.location_id === action.meta.arg.locationId
                  ? responseForVariant.newStock
                  : invetoryLevel.stock,
            }),
          );

          return {
            ...variant,
            inventory_levels: inventoryLevelsUpdated,
            stock: responseForVariant.newStock,
          };
        }
        return variant;
      });
    });

    builder.addCase(getInventoryProductById.pending, (state, action) => {
      state.status = StatusType.loading;
      state.currentRequestID = action.meta.requestId;
    });

    builder.addCase(getInventoryProductById.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        state.status = StatusType.success;
        state.entities[action.payload.id] = action.payload;
      }
    });

    builder.addCase(getInventoryProductById.rejected, (state) => {
      state.status = StatusType.error;
    });

    builder
      .addCase(updateStock.pending, (state) => {
        state.updateStockStatus = StatusType.loading;
      })
      .addCase(updateStock.fulfilled, (state) => {
        state.updateStockStatus = StatusType.success;
      })
      .addCase(updateStock.rejected, (state) => {
        state.updateStockStatus = StatusType.error;
      });
  },
});

export const { reducer } = inventorySlice;

export const {
  cleanupdateStock,
  cleanUpInventory,
  applyFilters,
  applyStockChange,
} = inventorySlice.actions;
