import {
  PaginationResponseDto,
  ProductDetailsResponseDto,
  SearchProductListResponseDto,
  SectionCodesErrorsType,
  CustomPropertiesResponseDto,
  ProductsSortByEnum,
  StockHistoryResponseDto,
  i18nResponseDto,
  SummaryResponseDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { StatusType } from 'commons/types';
import { ProductsFiltersInterface, SortByType } from '../productsServices';

export type { EditPricesProductsDto } from '../pages/ProductListPage/components/BulkActions/ModalProductsEditPrices/types';

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export type SearchParamsInterface = {
  q?: string;
  sortBy?: ProductsSortByEnum;
  locationId?: string;
  requiresShipping?: boolean;
  published?: boolean;
};

export type statusSuccessType = 'idle' | 'success' | 'errorMetafield';

export type getProductsQueryType =
  | 'normal'
  | 'advanced'
  | 'advancedLazyVariants';

export type getProductsActionRequestType = {
  filters: ProductsFiltersInterface;
  type: getProductsQueryType;
};

export type ProductEntitiesType = Record<string, ProductDetailsResponseDto>;

export type ProductSummaryType = Record<string, SummaryResponseDto>;

export type MetafieldsPersistsStatusType =
  | 'success'
  | 'idle'
  | 'errorOnCreate'
  | 'errorOnUpdate'
  | 'errorOnClone';

export type MetafieldsIdsPersistsStatusType = Record<
  string,
  MetafieldsPersistsStatusType
>;

export type RelatedProductsTypes = Record<string, string[] | number[]>;
export interface SelectProductListInterface {
  data: SearchProductListResponseDto[] | null;
  status: string;
  refreshStatus: string;
  pagination: PaginationResponseDto;
  statusCode: string;
}
type ExportProductsType = {
  status: string;
  totalResults: number;
};

interface StandardVariantsInterface {
  status: statusType;
  data: CustomPropertiesResponseDto | null;
}

interface StandardTagsInterface {
  status: statusType;
  data: string[] | null;
}
export interface VariantStockInterface {
  [key: string]: number | undefined | null;
}

interface ImportMaxLines {
  status: StatusType;
  max: number;
}

export interface ProductsInterface {
  status: statusType;
  statusSuccess: statusSuccessType;
  statusImage: statusType;
  statusDetails: statusType;
  statusVariants: Record<string, statusType>;
  currentRequestID: string;
  currentDetailsRequestID: string;
  entities: ProductEntitiesType;
  variantStock: VariantStockInterface;
  metafieldsPersistsStatus: MetafieldsIdsPersistsStatusType;
  createHighlightProductStatus: SectionCodesErrorsType[] | null;
  ids: string[];
  appliedFilters: ProductsFiltersInterface;
  export: ExportProductsType;
  totalProducts: number;
  selectProductsList: SelectProductListInterface;
  standardVariants: StandardVariantsInterface;
  statusInventoryLevels: StatusType;
  statusExportProducts: StatusType;
  stockHistory: StockHistoryResponseDto[] | null;
  statusStockHistory: StatusType;
  importMaxLines: ImportMaxLines;
  fixesAttributes: FixesAttributesInterface;
  standardTags: StandardTagsInterface;
  relatedProducts?: RelatedProductsTypes;
  summaries: ProductSummaryType;
}

export type ThunkStateType = { state: RootStateType };

export interface ProductsAndFiltersInterface {
  sortBy?: SortByType;
  products: ProductDetailsResponseDto[];
  filters: ProductsFiltersInterface;
  total: number;
  status: statusSuccessType;
}

export interface FixesAttributesInterface {
  status: StatusType;
}

export interface AttributesToFix {
  attributes: i18nResponseDto[];
  toDelete: number[];
}
