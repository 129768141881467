import { useEffect } from 'react';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { BaseCard, Button, Stack, Title, Text } from '@tiendanube/components';
import { CopyLink, useResponsive } from 'commons/components';
import {
  useTranslationAccount,
  useInstallAuthenticationFactor,
  useAuthenticationFactorGenerateQrUrl,
} from 'domains/Account/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import Skeleton from './Skeleton';
import './SetUpAccountPage.scss';

interface SetUpAccountPageProps {
  onCancel: () => void;
  onContinue: () => void;
}

function SetUpAccountPage({
  onCancel,
  onContinue,
}: SetUpAccountPageProps): JSX.Element {
  const { isMobile } = useResponsive();
  const isMobileDevice = useIsMobileDevice();
  const t = useTranslationAccount();
  const { code, install, isLoading } = useInstallAuthenticationFactor();
  const qrUrl = useAuthenticationFactorGenerateQrUrl(code);

  useEffect(() => {
    install({ type: AuthenticationFactorTypes.TOTP });
  }, [install]);

  return isLoading ? (
    <Skeleton />
  ) : (
    <Stack column justify="space-between" align="stretch">
      <BaseCard>
        <BaseCard.Header>
          <Title type="h3">{`${t(
            'authenticationFactorPage.setUpAccountPage.title',
          )}`}</Title>
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column justify="space-between" align="stretch">
            {isMobileDevice ? (
              <>
                <Text>
                  {t(
                    'authenticationFactorPage.setUpAccountPage.body.firstInstructionMobile',
                  )}
                </Text>
                <CopyLink
                  value={code}
                  message={t(
                    'authenticationFactorPage.setUpAccountPage.body.code.message',
                  )}
                  text={t(
                    'authenticationFactorPage.setUpAccountPage.body.code.text',
                  )}
                  iconPosition="start"
                  size="small"
                />
                <Text>
                  {t(
                    'authenticationFactorPage.setUpAccountPage.body.secondInstruction',
                  )}
                </Text>
              </>
            ) : (
              <Stack column spacing="base">
                <div className="stratus--desktop-description-container">
                  <Text>
                    {t(
                      'authenticationFactorPage.setUpAccountPage.body.firstInstructionDesktop',
                    )}
                  </Text>
                </div>
                <Stack column justify="space-between">
                  {!!qrUrl && (
                    <img
                      src={qrUrl}
                      width="130px"
                      height="130px"
                      alt="qr-code"
                    />
                  )}
                  <Stack column={isMobile} align="center" spacing="tight">
                    <Text>
                      {t(
                        'authenticationFactorPage.setUpAccountPage.body.code.desktopPrefixLinkDescription',
                      )}
                    </Text>
                    <CopyLink
                      value={code}
                      message={t(
                        'authenticationFactorPage.setUpAccountPage.body.code.message',
                      )}
                      text={
                        <div className="stratus--desktop-link-description-container">
                          {t(
                            'authenticationFactorPage.setUpAccountPage.body.code.desktopLinkDescription',
                          )}
                        </div>
                      }
                      iconPosition="end"
                      size="small"
                    />
                  </Stack>
                </Stack>
                <div className="stratus--desktop-description-container">
                  <Text>
                    {t(
                      'authenticationFactorPage.setUpAccountPage.body.secondInstruction',
                    )}
                  </Text>
                </div>
              </Stack>
            )}
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <Stack justify="flex-end">
        <Button onClick={onCancel} appearance="default">
          {`${t('authenticationFactorPage.setUpAccountPage.actions.cancel')}`}
        </Button>
        <Button onClick={onContinue} appearance="primary">
          {`${t('authenticationFactorPage.setUpAccountPage.actions.continue')}`}
        </Button>
      </Stack>
    </Stack>
  );
}

export default SetUpAccountPage;
