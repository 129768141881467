import { Route, Switch } from 'react-router-dom';
import { HideNavTabs } from 'App/components/Navtabs';
import { DRAFT_ORDERS_ROUTES } from './draftOrdersRoutes';
import {
  DraftOrderList,
  DraftOrderDetailsPage,
  NewDraftOrderPage,
} from './pages';

function DraftOrders(): JSX.Element {
  return (
    <>
      <Route path={DRAFT_ORDERS_ROUTES.draftOrderList} exact>
        <DraftOrderList />
      </Route>
      <Switch>
        <Route path={DRAFT_ORDERS_ROUTES.newDraftOrder} exact>
          <HideNavTabs />
          <NewDraftOrderPage />
        </Route>
        <Route path={DRAFT_ORDERS_ROUTES.draftOrderDetails}>
          <HideNavTabs />
          <DraftOrderDetailsPage />
        </Route>
      </Switch>
    </>
  );
}

export default DraftOrders;
