import {
  CustomPaymentRequestDto,
  CustomPaymentResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCustomPayment = async (): Promise<CustomPaymentResponseDto> => {
  const { data } = await axios.get(`v1/payments/custom-payment`);
  return data;
};

const updateCustomPayment = async (
  payload: CustomPaymentRequestDto,
): Promise<void> => {
  await axios.post(`v1/payments/custom-payment`, payload);
};

const disableCustomPayment = async (): Promise<void> => {
  await axios.put(`v1/payments/custom-payment/disable`);
};

export default {
  getCustomPayment,
  updateCustomPayment,
  disableCustomPayment,
};
