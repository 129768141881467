import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CouponDeletedAlertProps {
  couponIsDeleted: boolean;
}

export function CouponDeletedAlert({
  couponIsDeleted,
}: Readonly<CouponDeletedAlertProps>) {
  const t = useTranslationMarketing('coupons.details.deletedAlert');

  if (!couponIsDeleted) {
    return null;
  }

  return (
    <Alert title={t('title')} appearance="warning">
      <Text>{t('description')}</Text>
    </Alert>
  );
}
