export const BASE_MENUS_URL = '/v1/menus';

export const DELETE_MENU_URL = `${BASE_MENUS_URL}/:id`;

export const menusEndpoints = {
  getMenus: 'getMenus',
  createMenu: 'createMenu',
  deleteMenu: 'deleteMenu',
  updateMenus: 'updateMenus',
} as const;

export const menusTags = {
  menusList: 'menusList',
} as const;
