import React, { useCallback, useState } from 'react';
import { Label, Textarea } from '@nimbus-ds/components';
import sanitizeHtml from 'sanitize-html';
import {
  GenerateProductDescriptionResponseDto,
  LanguageType,
} from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import AiGenerationModal, {
  GenerateContentParams,
} from 'commons/components/AiGenerationModal';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useGenerateProductDescription } from '../../../hooks';

const ALLOWED_HTML_TAGS = [
  'b',
  'i',
  'em',
  'strong',
  'p',
  'ul',
  'ol',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

export interface AIProductDescriptionGeneratorProps {
  onDismiss: () => void;
  onUseDescription: (description: string) => void;
  show?: boolean;
  product: {
    name?: string;
    lang: string;
  };
}

function AIProductDescriptionGenerator({
  onDismiss,
  onUseDescription,
  show = false,
  product,
}: Readonly<AIProductDescriptionGeneratorProps>) {
  const t = useTranslationCatalog();
  const [productKeywords, setProductKeywords] = useState('');

  const { generateDescription } = useGenerateProductDescription();

  const handleGenerateDescription = useCallback(
    async ({ tone, wordCount }: GenerateContentParams) => {
      const response = await generateDescription({
        product_name: product.name,
        lang: product.lang as LanguageType,
        keywords: productKeywords,
        tone,
        word_count: wordCount,
      });

      const safeDescription = sanitizeHtml(response.description_html, {
        allowedTags: ALLOWED_HTML_TAGS,
      });

      return { ...response, description_html: safeDescription };
    },
    [product, productKeywords, generateDescription],
  );

  const handleChangeKeywords = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProductKeywords(e.target.value);
  };

  const handleUseDescriptionClick = ({
    description_html,
  }: GenerateProductDescriptionResponseDto) => {
    onUseDescription(description_html);
  };

  const moveCursorToEnd = (
    e:
      | React.FocusEvent<HTMLTextAreaElement, Element>
      | React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    e.currentTarget.setSelectionRange(
      e.currentTarget.value.length,
      e.currentTarget.value.length,
    );
  };

  return (
    <AiGenerationModal
      show={show}
      feature={AiFeature.AI_PRODUCTS_DESCRIPTION}
      insufficientInfo={false}
      disabledGenerateCta={!productKeywords}
      onDismiss={onDismiss}
      onGenerateContent={handleGenerateDescription}
      onUseContent={handleUseDescriptionClick}
      feedbackTitle={t(
        'products.aiDescriptionGenerator.productDescriptionFeedbackModal.title',
      )}
      feedbackText={t(
        'products.aiDescriptionGenerator.productDescriptionFeedbackModal.text',
      )}
      showTone
      wordCountValues={{ short: 75, medium: 150, large: 300 }}
      extraControls={
        <Stack column align="stretch" spacing="tight">
          <Label htmlFor="product-keywords">
            {t('products.aiDescriptionGenerator.modal.productKeywords.label')}
          </Label>
          <Textarea
            id="product-keywords"
            name="product-keywords"
            value={productKeywords}
            lines={10}
            onFocus={moveCursorToEnd}
            onChange={handleChangeKeywords}
            placeholder={t(
              'products.aiDescriptionGenerator.modal.productKeywords.placeHolder',
            )}
          />
        </Stack>
      }
      previewGeneratedContent={({
        description_html,
      }: GenerateProductDescriptionResponseDto) => (
        <AiGenerationModal.HtmlPreview html={description_html} />
      )}
    />
  );
}

export default AIProductDescriptionGenerator;
