import { Box, Icon, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

export default function NinetyDaysAlert() {
  const { t } = useTranslation(Domain.ORDERS);
  return (
    <Box display="flex" marginTop="2">
      <Box marginRight="0-5">
        <Icon color="warning-textLow" source={<ExclamationTriangleIcon />} />
      </Box>
      <Text color="warning-textLow" fontSize="caption">
        {t('filters.paymentMethod.helperText')}
      </Text>
    </Box>
  );
}
