export enum ProductsWithoutStockOrganizeEnum {
  DEFAULT_ORDER = "defaultOrder",
  STOCK_AT_THE_END = "stockAtTheEnd",
  STOCK_HIDDEN = "stockHidden",
}

export const ProductsWithoutStockOrganizeEnumTransformer = {
  [ProductsWithoutStockOrganizeEnum.DEFAULT_ORDER]: "0",
  [ProductsWithoutStockOrganizeEnum.STOCK_AT_THE_END]: "1",
  [ProductsWithoutStockOrganizeEnum.STOCK_HIDDEN]: "2",
};

export const ProductsWithoutStockOrganizeEnumTransformerInverted = {
  "0": ProductsWithoutStockOrganizeEnum.DEFAULT_ORDER,
  "1": ProductsWithoutStockOrganizeEnum.STOCK_AT_THE_END,
  "2": ProductsWithoutStockOrganizeEnum.STOCK_HIDDEN,
};
