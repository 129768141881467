import { Box, Card, Text, Title } from '@nimbus-ds/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function SubscriptionDetailSkeleton() {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.subscriptionDetail',
  );
  return (
    <Card>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title as="h3">{t('title')}</Title>
      </Box>
      <Box display="flex" flexDirection="column" gap="2-5">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text.Skeleton width="50%" />
          <Text.Skeleton width="15%" />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text.Skeleton width="30%" />
          <Text.Skeleton width="20%" />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text.Skeleton width="40%" />
          <Text.Skeleton width="10%" />
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text.Skeleton width="30%" />
          <Text.Skeleton width="20%" />
        </Box>
      </Box>
    </Card>
  );
}

export default SubscriptionDetailSkeleton;
