import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { changePlatform as changePlatformAction } from 'domains/Auth/authSlice';

function useChangePlatform(): (isMobile: boolean) => void {
  const dispatch = useAppDispatch();

  const changePlatform = useCallback(
    (isMobile: boolean) => {
      dispatch(changePlatformAction(isMobile));
    },
    [dispatch],
  );

  return changePlatform;
}

export default useChangePlatform;
