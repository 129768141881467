import { Modal, Text } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface ExistingProductsModalProps {
  show: boolean;
  onDismiss: () => void;
  onAccept: () => void;
}

function ExistingProductsModal({
  show,
  onDismiss,
  onAccept,
}: ExistingProductsModalProps) {
  const t = useTranslationCatalog();

  return (
    <Modal
      title={t('products.importExportPage.import.existingProducts.titleModal')}
      show={show}
      onDismiss={onDismiss}
      secondaryAction={{
        children: `${t(
          'products.importExportPage.import.existingProducts.cancel',
        )}`,
        onClick: onDismiss,
      }}
      primaryAction={{
        children: `${t(
          'products.importExportPage.import.existingProducts.confirm',
        )}`,
        appearance: 'primary',
        onClick: onAccept,
      }}
    >
      <Text>
        {t('products.importExportPage.import.existingProducts.messageModal')}
      </Text>
    </Modal>
  );
}

export default ExistingProductsModal;
