import {
  Alert,
  Checkbox,
  Link,
  Select,
  Text,
  Title,
} from '@nimbus-ds/components';
import { BaseCard, Stack } from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';

function MercadoPagoSettingsSkeleton(): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Header>
          <Title.Skeleton as="h3" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Stack.Item>
              <Text.Skeleton fontSize="caption" width="150px" />
              <Text.Skeleton width="200px" />
            </Stack.Item>
            <Stack column align="stretch" spacing="tight">
              <Text.Skeleton fontSize="caption" width="50px" />
              <Select.Skeleton />
            </Stack>
            <Stack column align="stretch" spacing="tight">
              <Text.Skeleton fontSize="caption" width="75px" />
              <Select.Skeleton />
              <Text.Skeleton fontSize="caption" width="100%" />
            </Stack>
            <Alert.Skeleton />
            <Link.Skeleton width="140px" />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Title.Skeleton as="h3" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Text.Skeleton width="100%" />
            <Link.Skeleton width="140px" />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Title.Skeleton as="h3" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Stack column align="stretch" spacing="tight">
              <Text.Skeleton width="150px" />
              <Text.Skeleton width="200px" />
            </Stack>
            <Stack column align="stretch" spacing="tight">
              <Text.Skeleton width="150px" />
              <Text.Skeleton width="200px" />
            </Stack>
            <Text.Skeleton width="200px" />
            {[1, 2, 3].map((paymentMethod) => (
              <Checkbox.Skeleton key={paymentMethod} width="150px" />
            ))}
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Title.Skeleton as="h3" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Alert.Skeleton />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons.Skeleton />
    </>
  );
}

export default MercadoPagoSettingsSkeleton;
