import {
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@nimbus-ds/components';

export type ActionIconButtonProps = Pick<
  IconButtonProps,
  'onClick' | 'source' | 'backgroundColor' | 'borderColor'
> &
  Pick<TooltipProps, 'content'>;

function ActionIconButton({
  onClick,
  content,
  source,
  backgroundColor,
  borderColor,
}: ActionIconButtonProps) {
  return (
    <Tooltip content={content}>
      <IconButton
        type="button"
        size="2rem"
        source={source}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default ActionIconButton;
