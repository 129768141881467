import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Text, Alert, Checkbox } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { InterfaceNameValue } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  Stack,
} from 'commons/components';
import DateFromTo from 'commons/components/DateFromTo';
import {
  getDifference,
  addDates,
  DateType,
  getTodayIsoString,
} from 'commons/utils/date';
import { STATS_FILTERS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import {
  FiltersStatisticsModalProps,
  FilterValuesState,
} from 'domains/Statistics/types';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import { DAYS_TOTAL } from './FiltersStatisticsModalUtils';
import SelectComparePeriod from '../SelectComparePeriod';
import SelectMainPeriod from '../SelectMainPeriod';

function FiltersStatisticsModal({
  values,
  onChange,
  show,
  onClose,
}: Readonly<FiltersStatisticsModalProps>) {
  const t = useTranslationStatistics();
  const enabled = useSupportsStatsPlan(STATS_PLAN_1_AND_2);
  const today = getTodayIsoString();
  const valuesMemo = useMemo(
    () => ({
      mainPeriod: values.mainPeriod,
      mainPeriodFrom: values.mainPeriodFrom || today,
      mainPeriodTo: values.mainPeriodTo || today,
      hasCompared: !!values.comparePeriod,
      comparePeriod: values.comparePeriod || 'samePeriod',
      comparePeriodFrom: values.comparePeriodFrom || '',
      comparePeriodTo: values.comparePeriodTo || '',
    }),
    [today, values],
  );

  const [filters, setFilters] = useState<FilterValuesState>(valuesMemo);

  const handleCheck = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const getLimitDate = () => {
    if (filters.mainPeriod === 'custom') {
      return addDates(
        filters.comparePeriodFrom,
        getDifference(filters.mainPeriodTo, filters.mainPeriodFrom),
        'day',
      );
    }

    return addDates(
      filters.comparePeriodFrom,
      DAYS_TOTAL[filters.mainPeriod].total,
      DAYS_TOTAL[filters.mainPeriod].type as DateType,
    );
  };

  const handleApply = () => {
    onChange({
      mainPeriod: filters.mainPeriod,
      ...(filters.mainPeriod === 'custom' && {
        mainPeriodFrom: filters.mainPeriodFrom,
        mainPeriodTo: filters.mainPeriodTo,
      }),
      ...(filters.hasCompared && { comparePeriod: filters.comparePeriod }),
      ...(filters.hasCompared &&
        filters.comparePeriod === 'custom' && {
          comparePeriodFrom: filters.comparePeriodFrom,
          comparePeriodTo: getLimitDate(),
        }),
    });
  };

  const onChangeWithUpsell = useUpsellFlow({
    title: t('statistics.upsellTitle'),
    featureKey: FEATURE_NEW_ADMIN_STATS,
    minValue: STATS_PLAN_1_AND_2,
    trackingSource: STATS_FILTERS,
    asAside: true,
    callback: setFilters,
  });

  const handleChange = ({ name, value }: InterfaceNameValue) => {
    if (enabled)
      setFilters({
        ...filters,
        [name]: value,
      });
    else onChangeWithUpsell({ name, value });
  };

  const onConfirmWithUpsell = useUpsellFlow({
    title: t('statistics.upsellTitle'),
    featureKey: FEATURE_NEW_ADMIN_STATS,
    minValue: STATS_PLAN_1_AND_2,
    trackingSource: STATS_FILTERS,
    asAside: true,
    callback: handleApply,
  });

  const handleConfirm = () => {
    if (enabled) handleApply();
    else onConfirmWithUpsell();
  };

  const isDisabled =
    filters.mainPeriod === 'custom' &&
    (!filters.mainPeriodFrom || !filters.mainPeriodTo)
      ? true
      : filters.hasCompared &&
        filters.comparePeriod === 'custom' &&
        !filters.comparePeriodFrom
      ? true
      : false;

  useEffect(() => {
    setFilters(valuesMemo);
  }, [valuesMemo]);

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerTop={
        <HeaderTop
          navigation={{ onClick: onClose }}
          mainAction={{
            onClick: handleConfirm,
            icon: CheckCircleIcon,
          }}
        />
      }
      headerContent={
        <HeaderContent
          title={t('statistics.filters.modal.title')}
          subtitle=""
        />
      }
    >
      <Stack column align="stretch">
        <Text>{t('statistics.filters.modal.description')}</Text>
        <SelectMainPeriod value={filters.mainPeriod} onChange={handleChange} />
        {filters.mainPeriod === 'custom' && (
          <DateFromTo
            nameFrom="mainPeriodFrom"
            nameTo="mainPeriodTo"
            from={filters.mainPeriodFrom}
            to={filters.mainPeriodTo}
            onChange={handleChange}
          />
        )}
        <Checkbox
          name="hasCompared"
          label={t('statistics.filters.modal.comparePeriodLabel')}
          checked={filters.hasCompared}
          onChange={handleCheck}
        />
        {filters.hasCompared && (
          <>
            <Alert show appearance="warning">
              {t('statistics.filters.modal.alertText')}
            </Alert>
            <SelectComparePeriod
              onChange={handleChange}
              value={filters.comparePeriod}
            />
            {filters.comparePeriod === 'custom' && (
              <>
                <DateFromTo
                  nameFrom="comparePeriodFrom"
                  nameTo="comparePeriodTo"
                  from={filters.comparePeriodFrom}
                  to={getLimitDate()}
                  onChange={handleChange}
                  disabledTo
                />
                <Text>{t('statistics.filters.modal.limitDays')}</Text>
              </>
            )}
          </>
        )}
        <CancelAndSaveButtons
          onCancel={onClose}
          onSave={handleConfirm}
          isDisabled={isDisabled}
        />
      </Stack>
    </ModalAside>
  );
}

export default FiltersStatisticsModal;
