import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  InterfaceNameValue,
  RadioButtonGroup,
  InterfaceRadioButtonOption,
} from '@tiendanube/components';
import { StockType } from 'domains/Catalog/Products/productsServices';

interface OnChangeValue {
  name: string;
  value: StockType;
}

interface StockProps {
  value: StockType;
  onChange: ({ name, value }: OnChangeValue) => void;
}
function Stock({ value, onChange }: StockProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const options: StockType[] = ['', 'true', 'false', 'quantity'];

  const labelOptions: InterfaceRadioButtonOption[] = options.map((value) => ({
    value,
    label: t(`products.filters.stock.${value || 'all'}`),
  }));

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <RadioButtonGroup
      label={t('products.filter.stock')}
      name="stock"
      options={labelOptions}
      onChange={handleChange}
      value={value}
    />
  );
}

export default Stock;
