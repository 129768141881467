export const WhatsappEndpoints = {
  getWhatsapp: 'getWhatsapp',
  updateWhatsapp: 'updateWhatsapp',
} as const;

export const WhatsappTags = {
  GetWhatsapp: 'GetWhatsapp',
} as const;

export const WHATSAPP_BASE_URL = '/v1/whatsapp';
