import { useSelector } from 'react-redux';
import { OrderResponseDto } from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import { fetchOrderProducts, getOrderById } from 'domains/Orders';

interface UseOrderListItemResult {
  order: OrderResponseDto;
  getOrderProducts: () => void;
  isLoadingProducts: boolean;
  isErrorProducts: boolean;
}

function useOrderListItem(id: string): UseOrderListItemResult {
  const dispatch = useAppDispatch();

  const orderListItem = useSelector((state: RootStateType) =>
    getOrderById(state, id),
  );

  const getOrderProducts = () => dispatch(fetchOrderProducts(id));

  const isLoadingProducts = orderListItem.productListStatus === 'loading';
  const isErrorProducts = orderListItem.productListStatus === 'error';

  return {
    order: orderListItem,
    getOrderProducts,
    isLoadingProducts,
    isErrorProducts,
  };
}

export default useOrderListItem;
