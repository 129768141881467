import { Title } from '@nimbus-ds/components';
import {
  ProductSectionResponseDto,
  ProductSectionsCodeType,
} from '@tiendanube/common';
import {
  InteractiveListPriv,
  InterfaceNameChecked,
  Label,
  Text,
} from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface HighlightProductModalAsideProps {
  onClose: () => void;
  sections: ProductSectionResponseDto[];
  defaultProductSections: ProductSectionsCodeType[];
  sectionsSelected: ProductSectionsCodeType[];
  onChange: (event: InterfaceNameChecked) => void;
}

const maxHighlightPerSection = 40;

const getSectionHighlightsCount = (
  defaultProductSections: ProductSectionsCodeType[],
  sectionsSelected: ProductSectionsCodeType[],
  code: ProductSectionsCodeType,
  productCount: number,
): number => {
  const isSectionSelected =
    !defaultProductSections.includes(code) && sectionsSelected.includes(code);
  const isSectionUnselected =
    defaultProductSections.includes(code) && !sectionsSelected.includes(code);

  let highlightPerSection = productCount;
  if (isSectionUnselected) {
    highlightPerSection--;
  } else if (isSectionSelected) {
    highlightPerSection++;
  }

  return highlightPerSection;
};

function HighlightProductModalAside({
  onClose,
  sections,
  sectionsSelected,
  defaultProductSections,
  onChange,
}: HighlightProductModalAsideProps) {
  const t = useTranslationCatalog();

  return (
    <ModalAside
      isOpen
      onDidDismiss={onClose}
      headerContent={
        <Stack column align="stretch">
          <Title>{t('products.detail.highlight.modal.title')}</Title>
          <Text>{t('products.detail.highlight.modal.subtitle')}</Text>
        </Stack>
      }
    >
      <InteractiveListPriv>
        {sections.map(({ name, code, productCount }, index) => {
          const highlightPerSection = getSectionHighlightsCount(
            defaultProductSections,
            sectionsSelected,
            code,
            productCount,
          );

          const hasMoreThanTheMax =
            highlightPerSection >= maxHighlightPerSection;

          return (
            <InteractiveListPriv.CheckItem
              key={index}
              name={code}
              onChange={onChange}
              checked={sectionsSelected.includes(code)}
              disabled={hasMoreThanTheMax && !sectionsSelected.includes(code)}
              title=""
              blockLeft={
                <Stack column align="flex-start">
                  <Text>{name}</Text>
                  <Label
                    label={`${highlightPerSection}/${maxHighlightPerSection}`}
                    appearance={hasMoreThanTheMax ? 'warning' : 'default'}
                    id={code}
                  />
                </Stack>
              }
            />
          );
        })}
      </InteractiveListPriv>
    </ModalAside>
  );
}

export default HighlightProductModalAside;
