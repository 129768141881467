import { ChangeEvent, useState } from 'react';
import { Box, Input } from '@nimbus-ds/components';
import { EmptyMessage, InteractiveList } from '@nimbus-ds/patterns';
import uniq from 'lodash.uniq';
import { ExclamationTriangleIcon } from '@tiendanube/icons';
import { Stack } from 'commons/components';
import { CitiesInterface } from 'domains/Shipping/Addresses/addressesSlice/types';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import CitiesListSkeleton from './Skeleton';
import { getAllCitiesChecked } from '../utils';

interface CitiesListProps {
  cities: CitiesInterface[];
  filteredCities: CitiesInterface[];
  newSelectedCities: string[];
  allChecked: boolean | 'indeterminate';
  hasAllCities: boolean;
  handleSelectAll: (allChecked: boolean | 'indeterminate') => void;
  handleSetFilter: (filter: CitiesInterface[]) => void;
  handleSetNewSelectedCities: (newSelectedCities: string[]) => void;
}

function CitiesList({
  cities,
  filteredCities,
  newSelectedCities,
  allChecked,
  hasAllCities,
  handleSelectAll,
  handleSetFilter,
  handleSetNewSelectedCities,
}: Readonly<CitiesListProps>) {
  const t = useTranslationShipping();
  const [searchCity, setSearchCity] = useState('');

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchCity(e.target.value);
    const filteredCities = cities.filter(
      ({ name }) => name.search(new RegExp(e.target.value, 'i')) >= 0,
    );
    handleSetFilter(filteredCities);
  };

  const handleCheckAll = () => {
    if (!allChecked || allChecked === 'indeterminate') {
      const filteredCitiesIds = filteredCities.map((city) => city.id);
      handleSelectAll(getAllCitiesChecked(filteredCitiesIds, cities));
      const selectedCities = uniq([...newSelectedCities, ...filteredCitiesIds]);
      handleSetNewSelectedCities(selectedCities);
    } else {
      handleSelectAll(false);
      handleSetNewSelectedCities([]);
    }
  };

  const handleChange = (city: string, checked: boolean) => {
    let selectedCities = [...newSelectedCities];
    if (checked) {
      selectedCities = [...newSelectedCities, city];
      handleSetNewSelectedCities(selectedCities);
    } else {
      selectedCities = newSelectedCities.filter(
        (selectedCity) => selectedCity !== city,
      );
      handleSetNewSelectedCities(selectedCities);
    }

    handleSelectAll(getAllCitiesChecked(selectedCities, cities));
  };

  return (
    <Stack column align="flex-end" alignSelf="stretch">
      <Box paddingX="4" width="100%">
        <Input
          type="search"
          name="search"
          placeholder={t(
            'deliveryMethods.customShipping.selectCitiesModal.searchCity',
          )}
          value={searchCity}
          onChange={handleChangeSearch}
          onSubmit={handleChangeSearch}
        />
      </Box>

      {filteredCities.length === 0 && !!searchCity && (
        <EmptyMessage
          title={t(
            'deliveryMethods.customShipping.selectCitiesModal.titleSearch',
          )}
          text={t(
            'deliveryMethods.customShipping.selectCitiesModal.messageSearch',
          )}
          icon={<ExclamationTriangleIcon size="large" />}
        />
      )}
      <InteractiveList>
        {filteredCities.length > 0 && (
          <InteractiveList.CheckboxItem
            title={t(
              'deliveryMethods.customShipping.selectCitiesModal.textSelectAll',
            )}
            checkbox={{
              name: 'selectAll',
              checked:
                hasAllCities ||
                (allChecked !== 'indeterminate' ? allChecked : false),
              onChange: handleCheckAll,
              indeterminate: allChecked === 'indeterminate',
            }}
          />
        )}
        {filteredCities.map((city) => (
          <InteractiveList.CheckboxItem
            key={city.id}
            title={city.name}
            checkbox={{
              name: city.id,
              checked: newSelectedCities.includes(city.id),
              onChange: () =>
                handleChange(city.id, !newSelectedCities.includes(city.id)),
            }}
          />
        ))}
      </InteractiveList>
    </Stack>
  );
}

CitiesList.Skeleton = CitiesListSkeleton;
export default CitiesList;
