import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { JobsSchema } from './types';

export const getJobsState = (state: RootStateType): CombinedState<JobsSchema> =>
  state.jobs;

const getJobById = (_: RootStateType, id: string) => id;

export const getCloneProduct = createSelector(
  getJobsState,
  (jobs) => jobs.cloneProduct,
);

export const createGetCloneProductById = () =>
  createSelector(
    getCloneProduct,
    getJobById,
    (cloneProducts, id) => cloneProducts[id] || null,
  );

export const getBackgroundJobsSelector = createSelector(
  getJobsState,
  ({ backgroundJobs }) => backgroundJobs,
);

export const createGetBackgroundJobByIdSelector = () =>
  createSelector(
    getBackgroundJobsSelector,
    getJobById,
    (backgroundJobs, id) => backgroundJobs[id] || null,
  );
