import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceSelectOption, Select } from '@tiendanube/components';
import { NinetyDaysAlert } from '../OrderPaymentProvidersFilter';

interface OrderPaymentMethodsFilterProps {
  paymentMethods: string;
  paymentMethodOptions: InterfaceSelectOption[];
  handleChange: (event) => void;
}

function OrderPaymentMethodsFilter({
  paymentMethods,
  paymentMethodOptions,
  handleChange,
}: OrderPaymentMethodsFilterProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  return (
    <Box>
      <Select
        label={t('filter.paymentMethod')}
        name="paymentMethods"
        value={paymentMethods}
        options={paymentMethodOptions}
        onChange={handleChange}
      />
      {paymentMethods !== '' && <NinetyDaysAlert />}
    </Box>
  );
}

export default OrderPaymentMethodsFilter;
