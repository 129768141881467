import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList } from '@tiendanube/components';
import { ScrollTop } from 'commons/components';
import { OrderListItemSkeleton } from 'domains/Orders/Orders/pages/OrderListPage/components/OrderListResults/components/OrderResultsMobile/components/OrderListItem';

function CustomerOrdersSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerDetails.customerActivity.ordersTitle')}>
      <DataList skeleton>
        <OrderListItemSkeleton />
        <OrderListItemSkeleton />
        <OrderListItemSkeleton />
        <OrderListItemSkeleton />
        <ScrollTop />
      </DataList>
    </Card>
  );
}

export default CustomerOrdersSkeleton;
