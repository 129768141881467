import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useDeleteSubscriptionMutation } from 'domains/Billing/Checkout/CheckoutApi';

function useCancelSubscription() {
  const [cancelSubscription, { status, reset: resetStatus }] =
    useDeleteSubscriptionMutation();

  return {
    ...convertStatusTypeToObject(status),
    cancelSubscription,
    status: toStatusType(status),
    resetStatus,
  };
}

export { useCancelSubscription };
