import { useState } from 'react';
import { VariantResponseDto } from '@tiendanube/common';
import {
  Checkbox,
  Input,
  InterfaceNameChecked,
  InterfaceNameValue,
  Text,
} from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { LanguagesType } from 'App/i18n';
import {
  CancelAndSaveButtons,
  HeaderTop,
  Stack,
  TabsMultiLanguage,
} from 'commons/components';
import { useAutoFocus, useToast } from 'commons/hooks';
import { useGetAllLanguages, useGetLanguage } from 'domains/Auth/hooks';
import { ProductToCloneInterface } from 'domains/Catalog/Products/productsServices';
import { trackingProductActionDuplicateProductModal } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { ClonedProductNameInput } from './components';
import { Variant } from '../Variants/types';

interface ModalConfirmationCloneProductProps {
  onClose: () => void;
  onCloneProduct: (product: ProductToCloneInterface) => Promise<void>;
  hasImages: boolean;
  productName: Partial<Record<LanguagesType, string>>;
  onError?: () => void;
  variants: (Variant | VariantResponseDto)[];
  language?: LanguagesType;
}

export const INITIAL_STATE: ProductToCloneInterface = {
  name: { es: '', pt: '', en: '' },
  withImages: false,
  skus: {},
};

function ModalConfirmationCloneProduct({
  onCloneProduct,
  onClose,
  hasImages,
  productName,
  onError,
  variants,
}: ModalConfirmationCloneProductProps): JSX.Element {
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const { ref } = useAutoFocus();
  const mainLanguage = useGetLanguage();
  const languages = useGetAllLanguages();

  const [values, setValues] = useState<ProductToCloneInterface>({
    ...INITIAL_STATE,

    name: languages.reduce((acc, lang) => {
      acc[lang] = t('products.detail.nameCopy', {
        productName: productName[lang] || productName[mainLanguage],
      });
      return acc;
    }, {} as Record<string, string>),
    skus: variants.reduce(
      (acc, current) => ({ ...acc, [current.id]: current.sku }),
      {},
    ),
  });

  const [error, setError] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleChangeName =
    (language: LanguagesType) =>
    ({ value }: InterfaceNameValue) => {
      setValues((prev) => ({
        ...prev,
        name: { ...prev.name, [language]: value },
      }));
    };

  const handleChangeSku = (variantId: string, newSku: string) => {
    setValues((prev) => ({
      ...prev,
      skus: { ...prev.skus, [variantId]: newSku },
    }));
  };

  const handleCheck = ({ name, checked }: InterfaceNameChecked) => {
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleClose = () => {
    setValues(INITIAL_STATE);
    setError(false);
    onClose();
  };

  const isValidForm = () =>
    Object.values(values.name).every((value) => !!value.replace(/ /g, ''));

  const handleBlur = () => {
    if (isValidForm() && isSubmiting) setError(false);
  };

  const handleCloneProduct = async () => {
    const languageProductName = productName[mainLanguage];
    if (!languageProductName) return;

    trackingProductActionDuplicateProductModal(languageProductName);
    setIsSubmiting(true);
    if (!isValidForm()) {
      setError(true);
      return;
    }
    handleClose();
    addToastProgress({
      label: t('products.detail.cloneProductSuccessProgress'),
    });
    try {
      await onCloneProduct(values);
      closeToastProgress();
      addToast({
        label: t('products.detail.cloneProductSuccess'),
        appearance: 'success',
      });
    } catch {
      onError?.();
      closeToastProgress();
      addToast({
        label: t('products.detail.cloneProductError'),
        appearance: 'danger',
      });
    }
  };

  const inputAppearance = error && isSubmiting ? 'validation_error' : 'default';
  const helpText =
    error && isSubmiting ? t('products.detail.emptyInputName') : '';
  const showSkuInputs = variants.some((variant) => !!variant.sku);

  return (
    <ModalAside
      isOpen
      hasVirtualizationContent
      headerTop={
        <HeaderTop
          navigation={{ onClick: onClose }}
          mainAction={{
            children: `${t('products.detail.duplicate')}`,
            appearance: 'secondary',
            onClick: handleCloneProduct,
          }}
        />
      }
      headerContent={t('products.detail.duplicateProduct')}
      onDidDismiss={handleClose}
    >
      <Stack column align="stretch" spacing="tight" innerRef={ref}>
        <Text bold>{t('products.detail.newProductName')}</Text>
        <TabsMultiLanguage>
          <ClonedProductNameInput
            inputAppearance={inputAppearance}
            value={values.name}
            onChange={handleChangeName}
            helpText={helpText}
            onBlur={handleBlur}
          />
        </TabsMultiLanguage>
      </Stack>
      {hasImages && (
        <Checkbox
          name="withImages"
          label={t('products.detail.duplicateImages')}
          onChange={handleCheck}
          checked={values.withImages}
        />
      )}
      {showSkuInputs && (
        <Stack column align="stretch" spacing="tight">
          <Text bold>{t('products.detail.sku')}</Text>
          <Stack column align="stretch">
            {variants.map((variant) => (
              <Input
                key={variant.id}
                name={`sku-${variant.id}`}
                label={variant.values
                  .map((value) => value[mainLanguage])
                  .join(' / ')}
                value={values.skus[variant.id]}
                onChange={(e) => handleChangeSku(variant.id, e.value)}
              />
            ))}
          </Stack>
        </Stack>
      )}
      <CancelAndSaveButtons
        saveText={t('products.detail.duplicate')}
        cancelText={t('products.detail.cancel')}
        onCancel={handleClose}
        onSave={handleCloneProduct}
      />
    </ModalAside>
  );
}

export default ModalConfirmationCloneProduct;
