const PAYU = "payu";
const PAYPAL = "paypal_multiple";
const MERCADO_PAGO_ID = "mercadopago";

export const NativePaymentProviders = {
  all: [PAYU, PAYPAL, MERCADO_PAGO_ID],
  payu: PAYU,
  paypal: PAYPAL,
  mercadoPago: MERCADO_PAGO_ID,
};
