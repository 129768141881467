import { CardWithTitle, Spinner } from 'commons/components';
import { STATS_SHIPPING_PAGE } from 'config/upsellFlowSources';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_3 } from 'domains/Statistics/utils';
import useGetRankProvincesByShippingTime from './useGetRankProvincesByShippingTime';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';
import RankByProvinceChart from '../RankByProvinceChart';

function RankProvincesByShippingTimeCard() {
  const t = useTranslationStatistics();
  const {
    rankProvincesByShippingTime,
    isLoading,
    isError,
    isSuccess,
    fetchRankProvincesByShippingTime,
    openFilter,
  } = useGetRankProvincesByShippingTime();
  return (
    <CardWithTitle
      title={t('statistics.rankProvincesByShippingTimeCard.title')}
    >
      <ChartOrPlaceholder
        type="bars-horizontal"
        statsPlanRequired={STATS_PLAN_3}
        upsellSource={STATS_SHIPPING_PAGE}
      >
        {isLoading && <Spinner size="medium" />}
        {isError && <GenericError onRetry={fetchRankProvincesByShippingTime} />}
        {isSuccess && rankProvincesByShippingTime?.length === 0 && (
          <GenericEmptySearch onRetry={openFilter} />
        )}
        {isSuccess &&
          rankProvincesByShippingTime &&
          rankProvincesByShippingTime?.length > 0 && (
            <RankByProvinceChart
              color="#1395AE"
              provincesRanking={rankProvincesByShippingTime}
            />
          )}
      </ChartOrPlaceholder>
    </CardWithTitle>
  );
}

export default RankProvincesByShippingTimeCard;
