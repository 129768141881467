import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import {
  AdditionalServicesDto,
  CorreiosContractsRequestDto,
} from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { CheckCircleIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ADDITIONAL_SERVICES from './additionalServices';
import AdditionalServicesCard from './components/AdditionalServicesCard';
import ContractCard from './components/ContractCard';
import validationSchema from './validationSchema';
import { useGetCorreiosContractTypes, useGetCorreiosDetail } from '../../hooks';
import useUpdateCorreiosContract from '../../hooks/useUpdateCorreiosContract';
import { DELIVERY_METHODS_ROUTES } from '../../shippingRoutes';

const EMPTY_STATE: CorreiosContractsRequestDto = {
  codeAccount: '',
  password: '',
  contractTypeCode: 'no_contract',
};

function ContractCorreiosPage(): JSX.Element {
  const { goBack, goTo } = useNavegate();
  const t = useTranslationShipping();
  const {
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
    currentContract,
    fetchCorreiosDetail,
  } = useGetCorreiosDetail();

  const {
    correiosContractTypes,
    isLoading: isLoadingContractTypes,
    isError: isErrorContractTypes,
    fetchCorreiosContractTypes,
  } = useGetCorreiosContractTypes();

  const isLoading = isLoadingDetail || isLoadingContractTypes;
  const isError = isErrorDetail || isErrorContractTypes;

  const { updateCorreiosContract, status: updateStatus } =
    useUpdateCorreiosContract();

  const {
    values,
    errors,
    isDirty,
    handleOnChange,
    setFieldValue,
    handleOnSubmit,
  } = useForm<CorreiosContractsRequestDto, Record<string, string>>({
    initialValues: currentContract ?? EMPTY_STATE,
    onSubmit: (values) => {
      isDirty
        ? updateCorreiosContract(values)
        : goTo(DELIVERY_METHODS_ROUTES.correiosModalities);
    },
    validationSchema,
  });

  useEffect(() => {
    if (!currentContract) fetchCorreiosDetail();
  }, [fetchCorreiosDetail, currentContract]);

  useEffect(() => {
    if (!correiosContractTypes) fetchCorreiosContractTypes();
  }, [fetchCorreiosContractTypes, correiosContractTypes]);

  useToastStatus({
    status: updateStatus,
    error: t('correios.errorToast'),
    success: t('correios.successToast'),
    onSuccess: () => goTo(DELIVERY_METHODS_ROUTES.correiosModalities),
  });

  const backNavigation = {
    children: t('correios.headerBack'),
    onClick: goBack,
  };

  const headerAction = {
    onClick: handleOnSubmit,
    icon: CheckCircleIcon,
  };

  const handleAdditionalServiceCheck = (additionalServices) => {
    setFieldValue('additionalServices', additionalServices);
  };

  const handleOnChangeContractType = (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => {
    handleOnChange(data);
    if (data.value === 'no_contract') {
      const updatedAdditionalServices = values.additionalServices?.filter(
        (service) => service !== 'samePerson',
      );
      setFieldValue('additionalServices', updatedAdditionalServices);
    }
  };

  const activeAdditionalServices = ADDITIONAL_SERVICES.map((service) => {
    if (
      service.name === 'samePerson' &&
      values.contractTypeCode === 'no_contract'
    ) {
      return { ...service, active: false, disabled: true };
    }

    return {
      ...service,
      active: values.additionalServices?.includes(
        service.name as AdditionalServicesDto,
      ),
    };
  });

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop navigation={backNavigation} mainAction={headerAction} />
      }
      headerContent={<HeaderContent title={t('correios.headerTitle')} />}
    >
      <Prompt
        when={isDirty && updateStatus !== 'success'}
        message={t('common:exitEdit.info')}
      />
      {isLoading && (
        <>
          <ContractCard.Skeleton />
          <AdditionalServicesCard.Skeleton />
          <CancelAndSaveButtons.Skeleton />
        </>
      )}
      {isError && (
        <ErrorScreen
          onRetry={fetchCorreiosContractTypes}
          description={t('genericError.text')}
        />
      )}
      {correiosContractTypes && !isLoading && (
        <>
          <ContractCard
            codeAccount={values.codeAccount}
            password={values.password}
            contractTypeCode={values.contractTypeCode}
            codeAccountError={errors.codeAccount}
            passwordError={errors.password}
            contractTypeCodeError={errors.contractTypeCode}
            onChange={handleOnChange}
            availableContractTypes={correiosContractTypes || []}
            onChangeContractType={handleOnChangeContractType}
          />
          <AdditionalServicesCard
            options={activeAdditionalServices}
            onChange={handleAdditionalServiceCheck}
          />
          <CancelAndSaveButtons
            onCancel={goBack}
            onSave={handleOnSubmit}
            saveText={t('correios.contract.saveText')}
            isLoading={updateStatus === 'loading'}
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default ContractCorreiosPage;
