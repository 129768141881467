import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getCustomersData,
  getCustomersStatus,
} from 'domains/Statistics/selectors';
import { getCustomersAction } from 'domains/Statistics/statisticsSlice';

function useGetCustomersChart() {
  const dispatch = useAppDispatch();
  const customers = useSelector(getCustomersData);
  const status = useSelector(getCustomersStatus);
  const { ...filters } = useStatisticsFilterContext();

  const fetchCustomers = useCallback(() => {
    dispatch(getCustomersAction());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchCustomers);

  return {
    ...status,
    ...filters,
    customers,
    fetchCustomers,
  };
}

export default useGetCustomersChart;
