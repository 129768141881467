import { Card, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface HowToInstallCardProps {
  name: string;
}

function HowToInstallCard({ name }: HowToInstallCardProps) {
  const t = useTranslationShipping();
  return (
    <Card>
      <Card.Header
        title={t(
          'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.title',
        )}
      />
      <Card.Body>
        <Stack column align="stretch">
          <Stack column align="stretch" spacing="tight">
            <Text>
              {t(
                'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.step1.text',
                {
                  app: name,
                },
              )}
            </Text>
            <Text fontSize="caption">
              {t(
                'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.step1.description',
              )}
            </Text>
          </Stack>
          <Stack column align="stretch" spacing="tight">
            <Text>
              {t(
                'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.step2.text',
              )}
            </Text>
            <Text fontSize="caption">
              {t(
                'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.step2.description',
              )}
            </Text>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default HowToInstallCard;
