import { useState } from 'react';
import {
  BaseCard,
  Stack,
  Text,
  RadioButtonGroup,
  Button,
} from '@tiendanube/components';
import { UseFormResult } from 'commons/hooks/useForm';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { UploadTaxDataWithDni, UploadTaxDataWithCuit } from '.';
import { ArgentineFiscalDataFormSkeleton } from './ArgentineFiscalDataFormSkeleton';
import { CuitInterface, TaxDataInterface } from '../ArgentineFiscalData';

const RadioButtons = [
  {
    label: 'CUIT',
    value: 'CUIT',
  },
  {
    label: 'DNI',
    value: 'DNI',
  },
];

interface ArgentineFiscalDataFormProps {
  cuitForm: UseFormResult<CuitInterface, CuitInterface>;
  dniForm: UseFormResult<TaxDataInterface, TaxDataInterface>;
  fiscalDataType?: string;
  loading: boolean;
}

function ArgentineFiscalDataForm({
  cuitForm,
  dniForm,
  loading,
  fiscalDataType,
}: ArgentineFiscalDataFormProps) {
  const t = useTranslationBilling();
  const [option, setOption] = useState(fiscalDataType || 'CUIT');

  const handleChange = (e) => {
    setOption(e.value);
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column align="flex-start" spacing="tight">
          <Text bold>{t('invoicesInfoPage.idType')}</Text>
          <RadioButtonGroup
            name="option"
            onChange={handleChange}
            value={option}
            options={RadioButtons}
          />
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        {option === 'DNI' ? (
          <UploadTaxDataWithDni
            values={dniForm.values}
            errors={dniForm.errors}
            onChange={dniForm.handleOnChange}
          />
        ) : (
          <UploadTaxDataWithCuit
            values={cuitForm.values}
            errors={cuitForm.errors}
            onChange={cuitForm.handleOnChange}
          />
        )}
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button
          spinner={loading}
          onClick={
            option === 'DNI' ? dniForm.handleOnSubmit : cuitForm.handleOnSubmit
          }
          appearance="primary"
          disabled={loading}
        >{`${t('invoicesInfoPage.submit')}`}</Button>
      </BaseCard.Footer>
    </BaseCard>
  );
}

ArgentineFiscalDataForm.Skeleton = ArgentineFiscalDataFormSkeleton;

export { ArgentineFiscalDataForm };
