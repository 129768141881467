import { Icon, Tag } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';
import { aspectRatioDesktop } from '../../constants';

interface ImagePreviewEmptyProps {
  onClickAdd: () => void;
  from: number;
}

function ImagePreviewEmpty({
  onClickAdd,
  from,
}: ImagePreviewEmptyProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const configs = [
    { text: t('products.detail.dragAndDrop'), showNew: true },
    { text: t('products.detail.uploadFront'), showNew: false },
    { text: t('products.detail.showVariations'), showNew: false },
    { text: t('products.detail.ideasHowToUse'), showNew: false },
  ];
  return (
    <>
      {configs
        .filter((_, index) => index >= from)
        .map((text, index) => (
          <div
            className="stratus--image-preview__item opacable"
            key={configs[index].text}
          >
            <Thumbnail.Add
              helperText={configs[index].text}
              onClick={onClickAdd}
              ariaLabel={configs[index].text}
              aspectRatio={aspectRatioDesktop}
            />
            {configs[index].showNew && (
              <div className="stratus--new-tag">
                <Tag appearance="primary">
                  <Icon
                    color="primary-textLow"
                    source={<StarIcon size={12} />}
                  />
                  {t('products.detail.new')}
                </Tag>
              </div>
            )}
          </div>
        ))}
    </>
  );
}

export default ImagePreviewEmpty;

// --image-preview__item
