import { Box } from '@nimbus-ds/components';
import './ThemePreview.scss';

interface ThemePreviewProps {
  imageDesktopUrl: string;
  imageMobileUrl: string;
}

export function ThemePreview({
  imageDesktopUrl,
  imageMobileUrl,
}: Readonly<ThemePreviewProps>) {
  return (
    <Box position="relative" data-style="stratus--theme-preview">
      <Box data-style="stratus--theme-preview-desktop">
        <img
          src={imageDesktopUrl}
          alt="Preview desktop"
          data-style="stratus--theme-preview-desktop__img"
        />
      </Box>
      <Box data-style="stratus--theme-preview-mobile">
        <img
          src={imageMobileUrl}
          alt="Preview desktop"
          data-style="stratus--theme-preview-mobile__img"
        />
      </Box>
      <Box data-style="stratus--theme-preview-background" />
    </Box>
  );
}
