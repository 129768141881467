import { InterfaceInteractiveListOptions } from '@tiendanube/components';

const ADDITIONAL_SERVICES: InterfaceInteractiveListOptions[] = [
  {
    title: 'correios.contract.samePerson.title',
    name: 'samePerson',
    description: 'correios.contract.samePerson.description',
    active: false,
  },
  {
    title: 'correios.contract.declaredValue.title',
    name: 'declaredValue',
    description: 'correios.contract.declaredValue.description',
    active: false,
  },
  {
    title: 'correios.contract.ack.title',
    name: 'ack',
    description: 'correios.contract.ack.description',
    hideBorder: true,
    active: false,
  },
];

export default ADDITIONAL_SERVICES;
