import { Text, IconButton, Tag } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack, Repeater } from 'commons/components';

export function RowSkeleton() {
  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="3">
        <Stack justify="space-between" align="center">
          <Text.Skeleton width="150px" height="16px" />
          <Stack justify="flex-end" spacing="tight">
            <IconButton.Skeleton width="2rem" height="2rem" />
          </Stack>
        </Stack>
        <Stack justify="space-between" align="center">
          <Text.Skeleton width="50px" height="16px" />
          <Text.Skeleton width="50px" height="16px" />
        </Stack>
        <Text.Skeleton width="80px" height="16px" />
        <Tag.Skeleton height="16px" />
      </Stack>
    </DataList.Row>
  );
}

const itemSkeleton = () => <RowSkeleton />;

export function CouponsListMobileSkeleton() {
  return <Repeater times={4} item={itemSkeleton} />;
}
