import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanUpdateDetailActiveAppStatus,
  updateActiveShippingAction,
  updateDetailActiveStatus,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';
import { OptionsCarriersInterface } from '../../components/CarriersForm/CarriersForm';

function useUpdateDetailActiveApp() {
  const dispatch = useAppDispatch();
  const status = useSelector(updateDetailActiveStatus);

  const updateDetailActive = useCallback(
    async (id: string, body: Record<string, OptionsCarriersInterface>) => {
      await dispatch(updateActiveShippingAction({ id, body }));
      dispatch(cleanUpdateDetailActiveAppStatus());
    },
    [dispatch],
  );
  return {
    status,
    updateDetailActive,
  };
}

export default useUpdateDetailActiveApp;
