import {
  ThemesConfigResponseDto,
  EditThemesImagesRequestDto,
  ThemesFtpStatusResponseDto,
  ThemesFtpPasswordResponseDto,
  ThemesMetricsResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import {
  BASE_THEMES_URL,
  DRAFT_URL,
  FTP_PASSWORD_URL,
  FTP_URL,
  IMAGES_URL,
  PUBLISH_DRAFT_URL,
  THEMES_METRICS_URL,
  themesEndpoints,
  themesTags,
} from './config';

export const themesApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [themesTags.themesConfig],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [themesEndpoints.getThemesConfig]: builder.query<
        ThemesConfigResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_THEMES_URL,
        }),
        providesTags: [themesTags.themesConfig],
      }),
      [themesEndpoints.getFtpStatus]: builder.query<
        ThemesFtpStatusResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: FTP_URL,
        }),
      }),
      [themesEndpoints.getFtpPassword]: builder.query<
        ThemesFtpPasswordResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: FTP_PASSWORD_URL,
        }),
      }),
      [themesEndpoints.editImages]: builder.mutation<
        void,
        EditThemesImagesRequestDto
      >({
        query: (payload) => ({
          method: 'POST',
          url: IMAGES_URL,
          data: payload,
        }),
        invalidatesTags: (_, error) => (error ? [] : [themesTags.themesConfig]),
      }),
      [themesEndpoints.enableFtp]: builder.mutation<void, void>({
        query: () => ({
          method: 'POST',
          url: FTP_URL,
        }),
      }),
      [themesEndpoints.disableFtp]: builder.mutation<void, void>({
        query: () => ({
          method: 'DELETE',
          url: FTP_URL,
        }),
      }),
      [themesEndpoints.resetPassword]: builder.mutation<void, void>({
        query: () => ({
          method: 'POST',
          url: FTP_PASSWORD_URL,
        }),
      }),
      [themesEndpoints.publishDraft]: builder.mutation<void, void>({
        query: () => ({
          method: 'POST',
          url: PUBLISH_DRAFT_URL,
        }),
        invalidatesTags: (_, error) => (error ? [] : [themesTags.themesConfig]),
      }),
      [themesEndpoints.createDraft]: builder.mutation<void, void>({
        query: () => ({
          method: 'POST',
          url: DRAFT_URL,
        }),
        invalidatesTags: (_, error) => (error ? [] : [themesTags.themesConfig]),
      }),
      [themesEndpoints.deleteDraft]: builder.mutation<void, void>({
        query: () => ({
          method: 'DELETE',
          url: DRAFT_URL,
        }),
        invalidatesTags: (_, error) => (error ? [] : [themesTags.themesConfig]),
      }),
      [themesEndpoints.getThemesMetrics]: builder.query<
        ThemesMetricsResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: THEMES_METRICS_URL,
        }),
      }),
    }),
  });

export const {
  useGetThemesConfigQuery,
  useLazyGetFtpStatusQuery,
  useLazyGetFtpPasswordQuery,
  useEditImagesMutation,
  useEnableFtpMutation,
  useDisableFtpMutation,
  useResetPasswordMutation,
  usePublishDraftMutation,
  useCreateDraftMutation,
  useDeleteDraftMutation,
  useGetThemesMetricsQuery,
} = themesApi;
