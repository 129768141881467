import { useEffect, useMemo } from 'react';
import { CurrencyType } from '@tiendanube/common';
import { useBoolean } from 'commons/hooks';
import useProductInventory from 'domains/Catalog/Inventory/pages/hooks/useProductInventory';
import { EditOrderProductQuantity } from './ProductQuantity';
import { OrderEditProduct } from '../../..';

interface EditProductQuantityProps {
  product: OrderEditProduct;
  currency: CurrencyType;
  onSubmit: (editedProduct: OrderEditProduct) => void;
  editable: boolean;
}

export function EditProductQuantity({
  product,
  currency,
  onSubmit,
  editable,
}: Readonly<EditProductQuantityProps>) {
  const [isFetchVariant, setFetchVariant] = useBoolean(false);

  const {
    product: productDetails,
    isLoading,
    fetchProductInventory,
  } = useProductInventory(product.productId);

  const variantDetails = useMemo(
    () => productDetails?.variants.find((v) => v.id === product.variantId),
    [productDetails, product],
  );

  const fetchVariant = () => setFetchVariant();

  useEffect(() => {
    if (!isLoading && !variantDetails && isFetchVariant) {
      fetchProductInventory();
    }
  }, [isLoading, variantDetails, isFetchVariant, fetchProductInventory]);

  return (
    <EditOrderProductQuantity
      currency={currency}
      fetchVariant={fetchVariant}
      onSubmit={onSubmit}
      orderProduct={product}
      variantDetails={variantDetails}
      editable={editable}
    />
  );
}
