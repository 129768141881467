import { Box, Card, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function EmptyHistory() {
  const t = useTranslationMarketing('coupons.history');

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Box>
          <Text> {t('emptyHistory')} </Text>
        </Box>
      </Card.Body>
    </Card>
  );
}
