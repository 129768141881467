import {
  AbandonedCartsQueryParamsDto,
  AbandonedCartsListResponseDto,
  AbandonedCartDetailsResponseDto,
  AbandonedCartEmailConfigResponseDto,
  AbandonedCartEmailConfigParamsRequestDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  ABANDONED_CARTS_DETAILS_URL,
  BASE_URL,
  ABANDONED_CARTS_EMAIL_CONFIG_URL,
  ABANDONED_CARTS_SEND_EMAIL_URL,
} from './urls';

export const getAbandonedCartsList = async (
  params?: AbandonedCartsQueryParamsDto,
): Promise<AbandonedCartsListResponseDto> => {
  const response = await axios.get<AbandonedCartsListResponseDto>(
    BASE_URL,
    params && {
      params,
    },
  );
  return response.data;
};

export const getAbandonedCartById = async (
  id: string,
): Promise<AbandonedCartDetailsResponseDto> => {
  const response = await axios.get(
    getUrlWithParams(ABANDONED_CARTS_DETAILS_URL, { id }),
  );
  return response.data;
};

export const getAbandonedCartsEmailConfig =
  async (): Promise<AbandonedCartEmailConfigResponseDto> => {
    const response = await axios.get<AbandonedCartEmailConfigResponseDto>(
      ABANDONED_CARTS_EMAIL_CONFIG_URL,
    );

    return response.data;
  };

export const updateAbandonedCartsEmailConfig = async (
  config: AbandonedCartEmailConfigParamsRequestDto,
): Promise<AbandonedCartEmailConfigResponseDto> => {
  const { data } = await axios.patch<AbandonedCartEmailConfigResponseDto>(
    ABANDONED_CARTS_EMAIL_CONFIG_URL,
    config,
  );

  return data;
};

export const sendEmail = async (id: string): Promise<void> => {
  await axios.post<AbandonedCartEmailConfigResponseDto>(
    getUrlWithParams(ABANDONED_CARTS_SEND_EMAIL_URL, { id }),
  );
};
