import { Tree, NodeModel } from '@minoru/react-dnd-treeview';
import { DataList, Stack, Text } from '@tiendanube/components';
import DndProvider from 'App/components/DndProvider';
import { useBoolean, useInfiniteScroll } from 'commons/hooks';
import { DragIcon } from '../DragIcon';
import Spinner from '../Spinner';
import './DraggableDataList.scss';

interface BaseData {
  [key: string]: any;
}

interface DraggableDataListProps<TData> {
  data: TData[];
  showFooter?: boolean;
  onDrop: (newTree?: (TData | undefined)[]) => void;
  endReached?: () => void;
  renderItem: (item: TData) => JSX.Element;
  previewDataKey: keyof TData;
}

/**
 * @deprecated This component is deprecated. Please use SortableDataList or DraggableDataListNew instead.
 */
function DraggableDataList<TData extends BaseData>({
  data,
  showFooter = false,
  onDrop,
  endReached,
  renderItem,
  previewDataKey,
}: DraggableDataListProps<TData>) {
  const targetID = useInfiniteScroll(endReached);

  const [dragMode, setDragModeOn, setDragModeOff] = useBoolean();

  const treeData = data.map((item, i) => ({
    id: item?.id || i + 1,
    parent: 0,
    text: '',
    data: item,
  }));

  const handleOnDrop = (newTree: NodeModel<TData>[]) => {
    onDrop(newTree.map((item) => item.data));
    setDragModeOff();
  };

  return (
    <>
      <DataList spacing="tight">
        <DndProvider>
          <Tree
            tree={treeData}
            canDrag={() => dragMode}
            rootId={0}
            classes={{
              root: 'stratus--draggable-data-list__tree-root',
              draggingSource: 'stratus--draggable-data-list__dragging-source',
            }}
            sort={false}
            onDrop={handleOnDrop}
            render={(node) => (
              <DataList.Row key={node.id} id={node.id.toString()}>
                <DataList.Cell width="fill">
                  <Stack wrap spacing="tight">
                    <Stack.Item>
                      <div
                        className="stratus--draggable-data-list__drag-icon"
                        onTouchStart={setDragModeOn}
                        onTouchEnd={setDragModeOff}
                        onMouseDown={setDragModeOn}
                        onMouseUp={setDragModeOff}
                      >
                        <DragIcon />
                      </div>
                    </Stack.Item>
                    <Stack.Item fill>
                      {node?.data && renderItem(node?.data)}
                    </Stack.Item>
                  </Stack>
                </DataList.Cell>
              </DataList.Row>
            )}
            dragPreviewRender={(node) => (
              <div className="stratus--draggable-data-list__custom-preview">
                <DragIcon />
                {!!node?.item.data?.[previewDataKey] && (
                  <Text trimText>{node?.item.data?.[previewDataKey]}</Text>
                )}
              </div>
            )}
            canDrop={(tree, { dragSource, dropTargetId }) =>
              dragSource?.parent === dropTargetId
            }
            placeholderRender={() => (
              <div className="stratus--draggable-data-list__placeholder" />
            )}
          />
        </DndProvider>
      </DataList>
      <div ref={targetID} />
      {showFooter && <Spinner show={showFooter} />}
    </>
  );
}

export default DraggableDataList;
