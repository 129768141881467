export const openWindow = (url: string, targetBlank: boolean) => {
  if (targetBlank) {
    /**
     * Workaround: iOS & safari blocks windows thats are opened by async functions
     * https://stackoverflow.com/a/70463940
     */
    setTimeout(() => {
      window.open(url, '_blank');
    });
  } else {
    window.location.href = url;
  }
};
