import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  AbandonedCartEmalConfg,
  updateAbandonedCartsEmailConfig,
} from 'domains/Orders/AbandonedCart/abandonedCartsSlice';
import {
  getAbandonedCartEmailConfig,
  getAbandonedCartEmailStatus,
} from 'domains/Orders/AbandonedCart/abandonedCartsSlice/abandonedCartsSelectors';

interface UseAbandonedCartConfigEmailResult {
  abandonedCartEmailConfig: AbandonedCartEmalConfg;
  isLoading: boolean;
  updateEmailConfig: (config: AbandonedCartEmalConfg) => void;
}

function useAbandonedCartConfigEmail(): UseAbandonedCartConfigEmailResult {
  const dispatch = useAppDispatch();
  const abandonedCartEmailConfig = useSelector(getAbandonedCartEmailConfig);
  const { isLoading } = useSelector(getAbandonedCartEmailStatus);

  const updateEmailConfig = async (config: AbandonedCartEmalConfg) => {
    unwrapResult(await dispatch(updateAbandonedCartsEmailConfig(config)));
  };

  return {
    abandonedCartEmailConfig,
    isLoading,
    updateEmailConfig,
  };
}

export default useAbandonedCartConfigEmail;
