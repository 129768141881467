import { ChangeEvent, useEffect } from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NavigationMenuItemDataDto } from '@tiendanube/common';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import { useTranslationOnline } from 'domains/Online';

interface SelectCategoryProps {
  selected?: string;
  onSelect: (data: NavigationMenuItemDataDto) => void;
}

function SelectCategory({ onSelect, selected }: SelectCategoryProps) {
  const t = useTranslationOnline('menus.menuItemModal');
  const { categories, isLoading } = useCategories();
  const categoriesOptions = [
    { id: '0', title: t('categories.selectAll') },
    ...categories,
  ];

  const handleSelect = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    onSelect({ id: value });
  };

  useEffect(() => {
    // when mounted we set selected category or 0 (means all categories) if there is no selected category
    onSelect({ id: selected || '0' });
  }, [onSelect, selected]);

  if (isLoading) return <Select.Skeleton width="100%" />;

  return categoriesOptions.length > 0 ? (
    <FormField.Select
      id="selected-category"
      name="selected-category"
      onChange={handleSelect}
      defaultValue={selected}
      value={selected}
    >
      {categoriesOptions.map(({ id, title }) => (
        <Select.Option key={id} label={title} value={id} />
      ))}
    </FormField.Select>
  ) : null;
}

export default SelectCategory;
