import { combineReducers } from 'redux';
import { reducer as customPaymentReducer } from './CustomPayment/customPaymentSlice';
import { reducer as paymentNotificationReducer } from './PaymentNotification/paymentNotificationSlice';
import { reducer as paymentProvidersReducer } from './PaymentProviders/paymentProvidersSlice';

const paymentsReducer = combineReducers({
  customPayment: customPaymentReducer,
  paymentProviders: paymentProvidersReducer,
  paymentNotification: paymentNotificationReducer,
});

export default paymentsReducer;
