import { useEffect, useRef } from 'react';

export function useMenuScrollShadow() {
  const menuBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = menuBodyRef.current;
    const handleScroll = (el: HTMLDivElement) => {
      const isScrollable = el.scrollHeight > el.clientHeight;

      if (!isScrollable) {
        el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
        return;
      }

      const isScrolledToBottom =
        el.scrollHeight < el.clientHeight + el.scrollTop + 1;
      const isScrolledToTop = isScrolledToBottom ? false : el.scrollTop <= 1;

      el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
      el.classList.toggle('is-top-overflowing', !isScrolledToTop);
    };

    const listener = (event) => handleScroll(event.target);

    if (currentRef) {
      handleScroll(currentRef);
      currentRef.addEventListener('scroll', listener);
    }

    return () => {
      currentRef?.removeEventListener('scroll', listener);
    };
  }, []);

  return menuBodyRef;
}
