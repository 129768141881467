import { ChangeEvent, useState } from 'react';
import { Icon, Input } from '@nimbus-ds/components';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@nimbus-ds/icons';
import cloneDeep from 'lodash.clonedeep';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import { AttributesToFix } from 'domains/Catalog/Products/productsSlice';
import {
  getControlStyle,
  getAttributesWithConflicts,
  checkIsDuplicated,
  checkIsEmpty,
} from '../../utils';

interface FieldAttributeProps {
  values: AttributesToFix;
  language?: LanguagesType;
  setFieldValue: (name: string, value: any) => void;
  index: number;
}
function FieldAttribute({
  setFieldValue,
  values,
  index,
  language = DEFAULT_LANGUAGE,
}: FieldAttributeProps) {
  const conflictAttributesCount = getAttributesWithConflicts(values, [
    checkIsDuplicated,
    checkIsEmpty,
  ]);
  const [controlStyle, setControlStyle] = useState(
    getControlStyle(values, conflictAttributesCount),
  );
  const handleOnChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const [field, index, language] = name.split('.');
    const newValue = cloneDeep(values[field]);
    newValue[index][language] = value;
    setFieldValue(field, newValue);
    setControlStyle((state) => {
      const prevStateLanguage = state[language] ?? [];
      return {
        ...state,
        [language]: [...prevStateLanguage, Number(index)],
      };
    });
  };

  const value = values.attributes[index][language]?.trim() ?? '';

  const controlStyleIncludes = controlStyle[language]?.includes(index);
  const invalidAttributeValue = conflictAttributesCount[language][value];

  const styleAttribute = invalidAttributeValue ? 'warning' : 'success';
  const appearanceStyle = controlStyleIncludes ? styleAttribute : 'neutral';

  const iconAttributeRepeated = invalidAttributeValue ? (
    <Icon color="warning-interactive" source={<ExclamationTriangleIcon />} />
  ) : (
    <Icon color="success-interactive" source={<CheckCircleIcon />} />
  );
  const appendStyle = controlStyleIncludes ? iconAttributeRepeated : null;

  return (
    <Input
      name={`attributes.${index}.${language}`}
      key={`key-${index}-${language}`}
      value={values.attributes[index][language]}
      appearance={appearanceStyle}
      append={appendStyle}
      appendPosition="end"
      onChange={handleOnChange}
    />
  );
}

export default FieldAttribute;
