import {
  CheckoutPaymentOptionsRequestDto,
  CheckoutPaymentOptionsResponseDto,
  InstallBasePaymentProviderRequestDto,
  EnableProviderRequestDto,
  MercadoPagoAuthorizationResponseDto,
  PaymentProviderDto,
  SettingOptionsResponseDto,
  UpdateBasePaymentProviderSettingsRequestDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import { PAYMENT_PROVIDERS_URL, PAYMENT_PROVIDERS_ROUTES } from './urls';

const getInstalledPaymentProviders = async (): Promise<
  PaymentProviderDto[]
> => {
  const { data } = await axios.get(PAYMENT_PROVIDERS_URL);
  return data;
};

const getNotInstalledPaymentProviders = async (): Promise<
  PaymentProviderDto[]
> => {
  const { data } = await axios.get(PAYMENT_PROVIDERS_ROUTES.available);

  return data;
};

const getCheckoutPaymentOptions =
  async (): Promise<CheckoutPaymentOptionsResponseDto> => {
    const { data } = await axios.get(PAYMENT_PROVIDERS_ROUTES.checkoutOptions);
    return data;
  };

const getSettingOptions = async (
  providerAppId: string,
): Promise<SettingOptionsResponseDto> => {
  const { data } = await axios.get(
    PAYMENT_PROVIDERS_ROUTES.settingOptions.replace(
      '{{providerId}}',
      providerAppId,
    ),
  );
  return data;
};

const enableInstalledPaymentProvider = async ({
  providerAppId,
  payload,
}: {
  providerAppId: string;
  payload?: EnableProviderRequestDto;
}) => {
  const { data } = await axios.put(
    PAYMENT_PROVIDERS_ROUTES.enable.replace('{{providerId}}', providerAppId),
    payload,
  );
  return data;
};

const installPaymentProvider = async ({
  providerId,
  payload,
}: {
  providerId: string;
  payload: InstallBasePaymentProviderRequestDto;
}) => {
  const { data } = await axios.post(
    PAYMENT_PROVIDERS_ROUTES.installPaymentProvider.replace(
      '{{providerId}}',
      providerId,
    ),
    payload,
  );
  return data;
};

const disableInstalledPaymentProvider = async (providerId: string) => {
  const { data } = await axios.put(
    PAYMENT_PROVIDERS_ROUTES.disable.replace('{{providerId}}', providerId),
  );
  return data;
};

const updatePaymentProviderSettings = async (
  payload: UpdateBasePaymentProviderSettingsRequestDto & { appId: string },
): Promise<void> => {
  const { data } = await axios.post(
    PAYMENT_PROVIDERS_ROUTES.settings.replace('{{providerId}}', payload.appId),
    payload,
  );
  return data;
};

const updateCheckoutPaymentOptions = async (
  payload: CheckoutPaymentOptionsRequestDto,
): Promise<CheckoutPaymentOptionsResponseDto> => {
  const { data } = await axios.post(
    PAYMENT_PROVIDERS_ROUTES.checkoutOptions,
    payload,
  );
  return data;
};

const getMercadoPagoAuthorization = async (
  redirectUri: string,
): Promise<MercadoPagoAuthorizationResponseDto> => {
  const { data } = await axios.get(
    PAYMENT_PROVIDERS_ROUTES.mercadoPagoAuthorization,
    { params: { redirectUri } },
  );
  return data;
};

const updateMercadoPagoInstallments = async (): Promise<void> => {
  const { data } = await axios.put(
    PAYMENT_PROVIDERS_ROUTES.mercadoPagoInstallments,
  );
  return data;
};

export default {
  getInstalledPaymentProviders,
  getNotInstalledPaymentProviders,
  getCheckoutPaymentOptions,
  getSettingOptions,
  enableInstalledPaymentProvider,
  installPaymentProvider,
  disableInstalledPaymentProvider,
  updatePaymentProviderSettings,
  updateCheckoutPaymentOptions,
  getMercadoPagoAuthorization,
  updateMercadoPagoInstallments,
};
