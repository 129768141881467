import { TimelineChangesInterface } from './types';
import './TimelineChanges.scss';

export function TimelineChanges({
  changes,
}: Readonly<TimelineChangesInterface>) {
  return (
    <div className="changes">
      {changes.map(({ compact, field, value }, index) => (
        <div
          key={`${field}-${index}`}
          className={compact ? 'single-line-height' : 'default-line-height'}
        >
          <p className="timeline-text">
            {field}: <span>{value}</span>
          </p>
        </div>
      ))}
    </div>
  );
}
