import { useEffect } from 'react';
import { Grid, Input, Stack } from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { AddressProps } from '.';
import { useGetAddressesMexico } from './hooks';
import MexicanAddressSkeleton from './MexicanAddressSkeleton';

function MexicanAddress({
  values,
  errors,
  setMultipleFieldValues,
}: AddressProps): JSX.Element {
  const t = useTranslationBilling();

  const { fetchAddressesMexico, addressesMexico, isError, isLoading } =
    useGetAddressesMexico();

  useEffect(() => {
    if (addressesMexico) {
      const { province, city, locality } = addressesMexico;
      setMultipleFieldValues({
        province,
        city,
        locality,
      });
    }
  }, [addressesMexico, setMultipleFieldValues]);

  const onChange = ({ name, value }): void => {
    setMultipleFieldValues({ [name]: value });
    if (name === 'zipcode' && value.length === 5) {
      fetchAddressesMexico(value);
    }
    if (name === 'zipcode' && value.length !== 5) {
      clearFields();
    }
  };

  const clearFields = () => {
    setMultipleFieldValues({
      province: '',
      city: '',
      locality: '',
    });
  };

  let zipcodeHelpText = '';
  let zipcodeAppearance: 'default' | 'validation_error' | 'validation_loading' =
    'default';

  if (isLoading) {
    zipcodeAppearance = 'validation_loading';
    zipcodeHelpText = '';
    delete errors.zipcode;
  } else if (isError) {
    zipcodeHelpText = t('invoicesInfoPage.address.errors.zipcodeInvalid');
    zipcodeAppearance = 'validation_error';
  } else if (errors.zipcode) {
    zipcodeHelpText = t(errors.zipcode || '');
    zipcodeAppearance = 'validation_error';
  }

  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');
  const floorHelpText = t(errors.floor || '');

  return (
    <>
      <Grid.Row>
        <Grid.Column key={1}>
          <Stack column align="stretch">
            <InputNumber
              type="integer"
              label={t('invoicesInfoPage.address.zipcode')}
              onChange={onChange}
              name="zipcode"
              maxLength={8}
              value={values.zipcode}
              helpText={zipcodeHelpText}
              appearance={zipcodeAppearance}
            />
          </Stack>
        </Grid.Column>
      </Grid.Row>

      {isLoading ? (
        <>
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </>
      ) : (
        <>
          <Grid.Row>
            {[
              <Grid.Column key={1}>
                <Stack column align="stretch">
                  <Input
                    type="text"
                    label={t('invoicesInfoPage.address.province')}
                    name="province"
                    value={values.province}
                    disabled
                  />
                </Stack>
              </Grid.Column>,
              <Grid.Column key={2}>
                <Stack column align="stretch">
                  <Input
                    type="text"
                    label={t('invoicesInfoPage.address.city')}
                    name="city"
                    value={values.city}
                    disabled
                  />
                </Stack>
              </Grid.Column>,
            ]}
          </Grid.Row>
          <Input
            type="text"
            label={t('invoicesInfoPage.address.locality')}
            onChange={onChange}
            name="locality"
            value={values.locality}
            disabled
          />
        </>
      )}

      <Grid.Row>
        {[
          <Grid.Column key={1}>
            <Stack column align="stretch">
              <Input
                type="text"
                label={t('invoicesInfoPage.address.street')}
                onChange={onChange}
                name="street"
                value={values.street}
                helpText={streetHelpText}
                appearance={streetHelpText ? 'validation_error' : 'default'}
              />
            </Stack>
          </Grid.Column>,
          <Grid.Column key={2}>
            <Stack column align="stretch">
              <Input
                type="text"
                label={t('invoicesInfoPage.address.number')}
                onChange={onChange}
                name="number"
                value={values.number}
                helpText={numberHelpText}
                appearance={numberHelpText ? 'validation_error' : 'default'}
              />
            </Stack>
          </Grid.Column>,
          <Grid.Column key={3}>
            <Stack column align="stretch">
              <Input
                type="text"
                label={t('invoicesInfoPage.address.floor')}
                onChange={onChange}
                name="floor"
                value={values.floor}
                helpText={floorHelpText}
                appearance={floorHelpText ? 'validation_error' : 'default'}
              />
            </Stack>
          </Grid.Column>,
        ]}
      </Grid.Row>
    </>
  );
}

MexicanAddress.Skeleton = MexicanAddressSkeleton;

export default MexicanAddress;
