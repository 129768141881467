import { Box } from '@nimbus-ds/components';
import { VariantResponseDto } from '@tiendanube/common';
import { InputAsyncStock } from 'domains/Catalog/Inventory/pages/InventoryListPage/components/InventoryResultsDesktop/components/ResultRowDesktop/components';
import useEditVariantsValues from 'domains/Catalog/Products/pages/hooks/useEditVariantsValues';
import {
  LabelStock,
  InputAsync,
} from '../../../../ProductResultsDesktop/components/ResultRowDesktop/components';

interface InputsProps {
  variant: VariantResponseDto;
}
function Inputs({ variant }: Readonly<InputsProps>) {
  const {
    hasCatalogInventory,
    variantsStock,
    enableEditStock,
    withDuplicateAttributesMainLanguage,
    enableEditPrice,
    jobStatus,
    handleEditVariant,
    onErrorStock,
    onErrorPrice,
  } = useEditVariantsValues(variant.product_id);

  const hasPromotionalPrice =
    variant.promotional_price !== null && variant.promotional_price > 0;

  return (
    <Box
      display="grid"
      gridTemplateColumns="110px 110px 110px"
      gridGap="2"
      pl="1"
    >
      {hasCatalogInventory ? (
        <LabelStock key={variant.id} defaultValue={{ variant }} />
      ) : (
        <InputAsyncStock
          asyncFunc={handleEditVariant(variant.id)}
          key={variant.id}
          defaultValue={variantsStock[variant.id] ?? null}
          onError={onErrorStock}
          disabled={!enableEditStock || withDuplicateAttributesMainLanguage}
        />
      )}
      <InputAsync
        asyncFunc={handleEditVariant(variant.id)}
        defaultValue={variant.price || 0}
        name="price"
        onError={onErrorPrice}
        lineThrough={hasPromotionalPrice}
        disabled={
          !enableEditPrice ||
          jobStatus === 'pending' ||
          withDuplicateAttributesMainLanguage
        }
      />
      <InputAsync
        asyncFunc={handleEditVariant(variant.id)}
        key={variant.id}
        defaultValue={variant.promotional_price || 0}
        name="promotional_price"
        onError={onErrorPrice}
        disabled={
          !enableEditPrice ||
          jobStatus === 'pending' ||
          withDuplicateAttributesMainLanguage
        }
      />
    </Box>
  );
}

export default Inputs;
