import { useTranslation } from 'react-i18next';
import { FulfillmentPreferenceTypeEnum } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { useToast } from 'commons/hooks';

interface UseActionsToastResult {
  onError: () => void;
  onCancelSuccess: () => void;
  onArchiveSuccess: () => void;
  onPaidSuccess: () => void;
  onPackSuccess: () => void;
  onFulfillSuccess: () => void;
  onOpenSuccess: () => void;
}

function useActionsToast(
  fulfillmentPrefeenceType?: FulfillmentPreferenceTypeEnum,
): UseActionsToastResult {
  const { t } = useTranslation(Domain.ORDERS);
  const { addToast } = useToast();

  const onError = () =>
    addToast({
      label: t('toast.error'),
      appearance: 'danger',
    });

  const onCancelSuccess = () =>
    addToast({
      label: t('toast.successCancel'),
      appearance: 'success',
    });

  const onArchiveSuccess = () =>
    addToast({
      label: t('toast.successArchive'),
      appearance: 'success',
    });

  const onPaidSuccess = () =>
    addToast({
      label: t('toast.successPaid'),
      appearance: 'success',
    });

  const onPackSuccess = () =>
    addToast({
      label: t('toast.successPacked'),
      appearance: 'success',
    });

  const onFulfillSuccess = () => {
    if (!fulfillmentPrefeenceType) return;

    addToast({
      label: t(`toast.successFulfill-${fulfillmentPrefeenceType}`),
      appearance: 'success',
    });
  };

  const onOpenSuccess = () =>
    addToast({
      label: t('toast.successOpen'),
      appearance: 'success',
    });

  return {
    onError,
    onCancelSuccess,
    onArchiveSuccess,
    onPaidSuccess,
    onPackSuccess,
    onFulfillSuccess,
    onOpenSuccess,
  };
}

export default useActionsToast;
