import { Table } from '@nimbus-ds/components';
import { DomainsListDesktopHeader } from './DomainsListDesktopHeader';
import { DomainsListDesktopRow } from './DomainsListDesktopRow/DomainsListDesktopRow';
import { Skeleton } from './Skeleton';
import { DomainsListProps } from '../types';
import './DomainsListDesktop.scss';

export function DomainsListDesktop({
  domains,
  isDeleting,
  isUpdating,
  onDeleteDomain,
  onSetPrimaryDomain,
}: Readonly<DomainsListProps>) {
  return (
    <div className="stratus--domains-list-desktop">
      <Table>
        <DomainsListDesktopHeader />
        <Table.Body>
          {domains.map((domain) => (
            <DomainsListDesktopRow
              key={domain.id}
              domain={domain}
              domainsCount={domains.length}
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              ourDomain={
                domains.find((domain) => domain.isOurDomain)?.name ?? ''
              }
              onDeleteDomain={() => onDeleteDomain(domain.id)}
              onSetPrimaryDomain={onSetPrimaryDomain}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

DomainsListDesktop.Skeleton = Skeleton;
