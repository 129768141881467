import { Alert, Text } from '@nimbus-ds/components';
import dayjs from 'dayjs';
import { useStoreStatus } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PaymentStatusAlert from '../PaymentStatusAlert';

interface StoreStatusAlertProps {
  includePaymentAlert: boolean;
}

function StoreStatusAlert({ includePaymentAlert }: StoreStatusAlertProps) {
  const { storeStatus, storefrontDisableDate } = useStoreStatus();
  const t = useTranslationBilling('checkout.storeStatusAlert');

  if (storeStatus === 'admin_down' && storefrontDisableDate) {
    return (
      <Alert appearance="danger" title={t('adminDown.title')}>
        <Text>
          {t('adminDown.message', {
            date: dayjs(new Date(storefrontDisableDate))
              .tz('UTC')
              .format('DD/MM'),
          })}
        </Text>
      </Alert>
    );
  }
  if (storeStatus === 'store_down') {
    return (
      <Alert appearance="danger" title={t('storeDown.title')}>
        <Text>{t('storeDown.message')}</Text>
      </Alert>
    );
  }

  if (includePaymentAlert) return <PaymentStatusAlert concept="plan-cost" />;
  return null;
}

export default StoreStatusAlert;
