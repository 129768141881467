import { Icon, Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { CurrencyType } from '@tiendanube/common';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { DataList, Text, Stack, Tooltip } from '@tiendanube/components';
import {
  StickyNoteIcon,
  ChatDotsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@tiendanube/icons';
import { NavigationLink } from 'commons/components';
import { useToggle } from 'commons/useToggle';
import { formatCurrency } from 'commons/utils';
import { dateFormat, Format } from 'commons/utils/date';
import { useCustomerOrderListItem } from 'domains/Customers/Customers/pages/CustomerDetailsPage/hooks';
import { ordersRoutes } from 'domains/Orders';
import { ResultProductsMobile } from 'domains/Orders/components';
import { BASE_URL } from 'domains/Orders/Orders/utils';
import OriginLabel, { showOriginLabel } from '../commons/OriginLabel';
import {
  ColumnFulfillmentStatus,
  ColumnPaymentStatus,
} from '../commons/Status';

const { Row, Cell } = DataList;

interface OrderListItemProps {
  id: string;
}

function CustomerOrderListItem({ id }: OrderListItemProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation([DomainEnum.ORDERS]);

  const order = useCustomerOrderListItem(id);

  const [showProducts, setShowProducts] = useToggle();

  if (!order) return null;

  const productsQuantity = order.products.reduce(
    (total, product) => total + product.quantity,
    0,
  );

  const numberOrder = `${t('resultRow.title', { number: order.number })}`;

  const urlOrder = `${BASE_URL}/orders/${id}`;

  return (
    <Row id={id}>
      <Cell width={60} rowTitle>
        <Stack spacing="tight">
          <Stack.Item>
            <NavigationLink
              name={numberOrder}
              url={urlOrder}
              goToNavegate={generatePath(ordersRoutes.orderDetails, { id })}
              fontSize="highlight"
              fontWeight="medium"
            />
          </Stack.Item>
          {!!order.remarks && (
            <Stack.Item>
              <Tooltip labelIcon={StickyNoteIcon} labelText={order.remarks}>
                <Text>{order.remarks}</Text>
              </Tooltip>
            </Stack.Item>
          )}
          {showOriginLabel(order) && (
            <Stack.Item>
              <OriginLabel order={order} />
            </Stack.Item>
          )}
        </Stack>
      </Cell>
      <Cell width={40} alignRight>
        <Text>{dateFormat(order.createdAt, Format.DAY_MONTH)}</Text>
      </Cell>
      <Cell width={60}>
        <Stack spacing="tight">
          {order.consumer && order.consumer.firstName !== '' ? (
            <>
              <Stack.Item>
                <Text>{order.consumer.firstName}</Text>
              </Stack.Item>
              {!!order.consumer.remarks && (
                <Stack.Item>
                  <Tooltip
                    labelIcon={ChatDotsIcon}
                    labelText={order.consumer.remarks}
                  >
                    <Text>{order.consumer.remarks}</Text>
                  </Tooltip>
                </Stack.Item>
              )}
            </>
          ) : (
            <Stack.Item>
              <Text appearance="light">{t('without-customer')}</Text>
            </Stack.Item>
          )}
        </Stack>
      </Cell>
      <Cell width={40} alignRight>
        <Text key="4">
          {formatCurrency(order.total, order.currency, language)}
        </Text>
      </Cell>
      <Cell width={100}>
        <Link
          appearance="primary"
          onClick={setShowProducts}
          textDecoration="none"
        >
          {t('resultRow.products.label', {
            count: productsQuantity,
          })}
          <Icon
            color="currentColor"
            source={showProducts ? <ChevronUpIcon /> : <ChevronDownIcon />}
          />
        </Link>
      </Cell>
      <Cell width="auto" extraPadding>
        <ColumnPaymentStatus key={order.id} order={order} />
      </Cell>
      <Cell width="auto" extraPadding>
        <ColumnFulfillmentStatus key={order.id} order={order} />
      </Cell>
      <ResultProductsMobile
        show={showProducts}
        quantityUniqueProducts={order.products.length}
        products={order.products}
        currency={order.currency as CurrencyType}
      />
    </Row>
  );
}

export default CustomerOrderListItem;
