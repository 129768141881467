import { useEffect, useMemo } from 'react';
import { Alert, List, Text } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';
import { trackingShippingDistributionCenterAdd } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  useGetCityOptions,
  useGetCountriesOptions,
  useGetProvincesOptions,
} from '../../hooks';
import useLocations from '../../hooks/useLocations';
import { FormLocation } from '../components';
import { validationSchema } from '../components/FormLocation/validationSchema';
import LocationPage from '../components/LocationPage';
import { COUNTRY_WITH_CITIES } from '../constants';
import { LocationDetailsError } from '../EditLocationPage/components';
import { LocationErrorsInterface, LocationValuesInterface } from '../types';

const initialValues = {
  locationName: '',
  document: '',
  country: '',
  zipcode: '',
  province: '',
  city: '',
  locality: '',
  street: '',
  number: '',
  floor: '',
  tags: [],
};

export interface NewLocationPageProps {
  type: 'main' | 'secondary';
}

export const appsCategoryWithMultiCDCompatibility = ['shipping', 'accounting'];

function NewLocationPage({ type }: NewLocationPageProps): JSX.Element {
  const t = useTranslationShipping();
  const { goTo, goBack, getQueryParam } = useNavegate();
  const { fetchLocations, createLocation, locations } = useLocations();
  const { data: apps, isError: isErrorApps, refetch } = useGetAppsListQuery();
  const appsWithoutMultiCDCompatibility = apps?.filter(
    ({ isMultiCDCompatible, categoryType }) =>
      appsCategoryWithMultiCDCompatibility.includes(categoryType) &&
      !isMultiCDCompatible,
  );

  const {
    provinces,
    isLoading: isLoadingProvinces,
    isError: isErrorProvinces,
    fetchProvinces,
  } = useGetProvincesOptions();

  const {
    cities,
    isLoading: isLoadingCities,
    isError: isErrorCities,
    fetchCities,
  } = useGetCityOptions();

  initialValues.country = useGetCountry();

  const {
    countries,
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    fetchCountries,
  } = useGetCountriesOptions();

  const { addToast } = useToast();

  const [handleCreateLocation, isSaving] = useAsyncFunc<
    LocationValuesInterface,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        await createLocation(data);
        await fetchLocations();
      }
    },
    () => {
      trackingShippingDistributionCenterAdd(type);
      addToast({
        label: t(`locations.form.add.${type}.success`),
        appearance: 'success',
      });

      const locationsPath =
        getQueryParam('from') === 'onBoarding'
          ? '/settings/locations'
          : '/settings/locations/created';

      locations.length === 0 ? goTo(locationsPath) : goBack();
    },
    () => {
      addToast({
        label: t(`locations.form.add.${type}.error`),
        appearance: 'danger',
      });
    },
  );

  useEffect(() => {
    if (!apps) refetch();
  }, [apps, refetch]);

  const _validationSchema = useMemo(
    () => validationSchema(locations),
    [locations],
  );

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const {
    errors,
    values,
    isDirty,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<LocationValuesInterface, LocationErrorsInterface>({
    initialValues,
    validationSchema: _validationSchema,
    onSubmit: handleCreateLocation,
  });

  const isErrorOnLoadForm =
    isErrorCountries || isErrorProvinces || isErrorCities || isErrorApps;
  const onRetryError = () => {
    fetchCountries();
    fetchProvinces(values.country);
    if (values.country in COUNTRY_WITH_CITIES && values.province) {
      fetchCities(values.country, values.province);
    }
  };

  return (
    <LocationPage
      isSaving={isSaving}
      handleSave={handleOnSubmit}
      title={t(`locations.form.add.${type}.title`)}
      subtitle={
        type === 'main' ? t(`locations.form.add.main.subtitle`) : undefined
      }
      submitLabel={t(`locations.form.add.${type}.save`)}
      cancelLabel={t(`locations.form.add.${type}.cancel`)}
      isDirty={isDirty}
      isError={isErrorOnLoadForm}
      isEmptyTags={values.tags.length === 0}
    >
      {isErrorOnLoadForm && (
        <LocationDetailsError onRetryError={onRetryError} />
      )}
      {!isErrorOnLoadForm &&
        appsWithoutMultiCDCompatibility &&
        appsWithoutMultiCDCompatibility.length > 0 && (
          <Alert
            appearance="warning"
            title={t(`locations.form.add.secondary.incompatibleAppsTitle`)}
          >
            <Text>
              {t(`locations.form.add.secondary.incompatibleAppsDescription`)}
            </Text>
            <List as="ul">
              {appsWithoutMultiCDCompatibility.map(({ id, name }) => (
                <List.Item key={id}>{name}</List.Item>
              ))}
            </List>
          </Alert>
        )}
      {!isErrorOnLoadForm && (
        <FormLocation
          values={values}
          errors={errors}
          countries={countries}
          provinces={provinces}
          cities={cities}
          loadingCountries={isLoadingCountries}
          loadingProvinces={isLoadingProvinces}
          loadingCities={isLoadingCities}
          fetchCountries={fetchCountries}
          fetchProvinces={fetchProvinces}
          fetchCities={fetchCities}
          handleChange={handleOnChange}
          setFieldValue={setFieldValue}
        />
      )}
    </LocationPage>
  );
}

export default NewLocationPage;
