import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  CheckCircleIcon,
  MailIcon,
  WhatsappIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from '@tiendanube/icons';
import { PopoverMenu } from 'commons/components';
import ModalConfirmationAction from 'commons/components/ModalConfirmationAction';
import { useModal, useWhatsappMessageLink } from 'commons/hooks';
import { useToastMessagesAction } from 'domains/Customers/hooks';

export interface MessagesListRowProps {
  type: string;
  phone: string;
  replied: boolean;
  onActionDelete: () => Promise<void>;
  onActionRead: () => Promise<void>;
  onActionUnread: () => Promise<void>;
  onActionSpam: () => Promise<void>;
  onActionSendEmail: () => void;
  hasCustomerId?: boolean;
}
function MessagesActionsRow({
  type,
  replied,
  phone,
  onActionDelete,
  onActionRead,
  onActionUnread,
  onActionSpam,
  onActionSendEmail,
  hasCustomerId,
}: MessagesListRowProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const handleToast = useToastMessagesAction();
  const getUrlWhatsApp = useWhatsappMessageLink();
  const [showRemoveModal, openRemoveModal, closeRemoveModal] = useModal();
  const [showSpamModal, openSpamModal, closeSpamModal] = useModal();

  const readAction = {
    label: t('messages.readAction.label'),
    startIcon: CheckCircleIcon,
    onClick: () =>
      handleToast({
        handleAction: onActionRead,
        textProgress: t('messages.readAction.textProgress'),
        textSuccess: t('messages.readAction.textSuccess'),
        textError: t('messages.readAction.textError'),
      }),
  };

  const unreadAction = {
    label: t('messages.unreadAction.label'),
    startIcon: CheckCircleIcon,
    onClick: () =>
      handleToast({
        handleAction: onActionUnread,
        textProgress: t('messages.unreadAction.textProgress'),
        textSuccess: t('messages.unreadAction.textSuccess'),
        textError: t('messages.unreadAction.textError'),
      }),
  };

  const emailAction = {
    label: t('messages.emailAction.label'),
    startIcon: MailIcon,
    onClick: onActionSendEmail,
  };

  const whatsAppAction = {
    label: t('messages.whatsAppAction.label'),
    startIcon: WhatsappIcon,
    onClick: () =>
      (window.location.href = getUrlWhatsApp({
        phone,
        message: t('messages.whatsAppAction.message'),
      })),
  };

  const spamAction = {
    label: t('messages.spamAction.label'),
    startIcon: ExclamationTriangleIcon,
    onClick: openSpamModal,
  };

  const removeAction = {
    label: t('messages.removeAction.label'),
    startIcon: TrashIcon,
    onClick: openRemoveModal,
  };

  const notShowAction = type !== 'newsletter' && type !== 'order_cancellation';

  const notRepliedReadAction = !replied && notShowAction ? [readAction] : [];
  const repliedReadAction = replied && notShowAction ? [unreadAction] : [];
  const notShowActionSpamAction = notShowAction ? [spamAction] : [];
  const notShowWhatsAppAction = phone ? [whatsAppAction] : [];

  const actions = hasCustomerId
    ? [
        ...notRepliedReadAction,
        ...repliedReadAction,
        emailAction,
        ...notShowWhatsAppAction,
        ...notShowActionSpamAction,
        removeAction,
      ]
    : [
        ...notRepliedReadAction,
        ...repliedReadAction,
        ...notShowActionSpamAction,
        removeAction,
      ];

  return (
    <>
      <PopoverMenu actions={actions} />
      {showRemoveModal && (
        <ModalConfirmationAction
          title={t('messages.removeAction.title')}
          body={t('messages.removeAction.body')}
          btnOk={t('Eliminar')}
          btnCancel={t('messages.removeAction.btnCancel')}
          textProgress={t('messages.removeAction.textProgress')}
          textSuccess={t('messages.removeAction.textSuccess')}
          textError={t('messages.removeAction.textError')}
          appearance="danger"
          onAction={onActionDelete}
          onClose={closeRemoveModal}
        />
      )}
      {showSpamModal && (
        <ModalConfirmationAction
          title={t('messages.spamAction.title')}
          body={t('messages.spamAction.body')}
          btnOk={t('messages.spamAction.btnOk')}
          btnCancel={t('messages.spamAction.btnCancel')}
          textProgress={t('messages.spamAction.textProgress')}
          textSuccess={t('messages.spamAction.textSuccess')}
          textError={t('messages.spamAction.textError')}
          appearance="danger"
          onAction={onActionSpam}
          onClose={closeSpamModal}
        />
      )}
    </>
  );
}

export default MessagesActionsRow;
