import { useMemo } from 'react';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import { appsCategoryWithMultiCDCompatibility } from 'domains/Shipping/Locations/pages/NewLocationPage/NewLocationPage';

const useMultiCDIncompatibleAlert = (
  appCategory = '',
  isMultiCDCompatible = false,
) => {
  const { locations } = useLocations();

  const showMultiInventoryIncompatibleAlert = useMemo(() => {
    const category = appCategory || '';

    const isMultiCDCategory =
      appsCategoryWithMultiCDCompatibility.includes(category);
    const isMultipleInventoryStore = locations.length > 1;

    if (!isMultiCDCategory || !isMultipleInventoryStore) return false;

    return !isMultiCDCompatible;
  }, [appCategory, isMultiCDCompatible, locations]);

  return showMultiInventoryIncompatibleAlert;
};

export default useMultiCDIncompatibleAlert;
