import { Box, Input } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

function ProvincesListSkeleton() {
  return (
    <Stack column align="center">
      <Box paddingX="4" width="100%">
        <Input.Skeleton />
      </Box>
      <InteractiveList>
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
        <InteractiveList.ButtonItemSkeleton title="" />
      </InteractiveList>
    </Stack>
  );
}

export default ProvincesListSkeleton;
