import { useEffect } from 'react';
import { Button, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderTop } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useProductsFilters } from 'domains/Catalog/Products/pages/hooks';
import { ProductsFiltersType } from 'domains/Catalog/Products/productsServices';
import {
  Categories,
  FreeShipping,
  PromotionalPrice,
  Published,
  Stock,
  StockQuantity,
  WeightDimention,
  SortBy,
} from '../../../ProductListPage/components/ProductListSearch/FilterModal/components';

interface ExportProductsFiltersModalProps {
  show: boolean;
  filters: ProductsFiltersType;
  onConfirm: (filters: ProductsFiltersType) => void;
  onClose: () => void;
}

function ExportProductsFiltersModal({
  show,
  filters,
  onConfirm,
  onClose,
}: ExportProductsFiltersModalProps) {
  const t = useTranslationCatalog();
  const { values, errors, validateFilters, handleChange, setAllValues } =
    useProductsFilters(filters);

  useEffect(() => {
    setAllValues(filters);
  }, [filters, setAllValues]);

  const handleConfirm = () => {
    if (!validateFilters()) return;
    onConfirm(values);
    onClose();
  };

  const handleCancel = () => {
    setAllValues(filters);
    onClose();
  };

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={t('products.importExportPage.filters.title')}
    >
      <Stack column align="stretch">
        <SortBy value={values.sortBy || ''} onChange={handleChange} />
        <Categories value={values.categoryId} onChange={handleChange} />
        <Stock value={values.stock} onChange={handleChange} />
        {values.stock === 'quantity' && (
          <StockQuantity
            min={values.minStock}
            max={values.maxStock}
            onChange={handleChange}
            minError={errors.minStock}
            maxError={errors.maxStock}
          />
        )}
        <PromotionalPrice
          value={values.promotionalPrice}
          onChange={handleChange}
        />
        <Published value={values.published} onChange={handleChange} />
        <FreeShipping value={values.freeShipping} onChange={handleChange} />
        <WeightDimention
          value={values.weightDimension}
          onChange={handleChange}
        />
        <Stack spacing="base" justify="flex-end">
          <Button onClick={handleCancel}>{`${t(
            'products.filter.cancel',
          )}`}</Button>
          <Button onClick={handleConfirm} appearance="primary">
            {`${t('products.filter.filter')}`}
          </Button>
        </Stack>
      </Stack>
    </ModalAside>
  );
}

export default ExportProductsFiltersModal;
