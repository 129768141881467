import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';
import { ChatDotsIcon, MailIcon, ForbiddenIcon } from '@tiendanube/icons';
import { CustomersMessagesType } from 'domains/Customers/Customers/customersSlice';

export interface MessagesListItemLabelTypeProps {
  id: string;
  type: CustomersMessagesType;
}

function MessagesListItemLabelType({
  id,
  type,
}: MessagesListItemLabelTypeProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CUSTOMERS);

  const typeDigest = (type: CustomersMessagesType) => {
    switch (type) {
      case 'newsletter' || 'newsletter-popup':
        return {
          icon: MailIcon,
          title: `${t(
            'customerDetails.customerActivity.messageLabels.newsletter',
          )}`,
        };
      case 'order_cancellation':
        return {
          icon: ForbiddenIcon,
          title: `${t(
            'customerDetails.customerActivity.messageLabels.repentance',
          )}`,
        };
      default:
        return {
          icon: ChatDotsIcon,
          title: `${t(
            'customerDetails.customerActivity.messageLabels.message',
          )}`,
        };
    }
  };
  const labelType = typeDigest(type);

  return (
    <Label id={`${id}-label`} label={labelType.title} icon={labelType.icon} />
  );
}

export default MessagesListItemLabelType;
