import { i18nResponseDto } from '@tiendanube/common';
import { CouponValueTypes } from '@tiendanube/common/src/domains/store-activity-logs/enums';
import { dateFormat, Format } from 'commons/utils/date';

const parseCurrency = ({ value, currency }) =>
  `${currency}${parseFloat(value)}`;
const parsePercentage = ({ value }) => `${parseFloat(value)}%`;
const translateValue = ({ t, key, value }) =>
  t(`keysTranslations.${key}.type.${value}`).toLowerCase();
const parseValue = ({ eventData, value, currency }) => {
  if (eventData.new.type === CouponValueTypes.PERCENTAGE)
    return parsePercentage({ value });
  if (eventData.new.type === CouponValueTypes.ABSOLUTE)
    return parseCurrency({ value, currency });
  return value;
};

const parseDate = ({ value }: { value: string }) =>
  dateFormat(value, Format.DD_MM_YY);

const translateNames = ({ key, eventData, language }) => {
  const lang = language.split('-')[0];
  return eventData.new[key]
    .map(({ name }: { name: i18nResponseDto }) => name[lang])
    .join(', ');
};

export const valueTransformationMap = {
  start_date: parseDate,
  end_date: parseDate,
  min_price: parseCurrency,
  value: parseValue,
  categories: translateNames,
  products: translateNames,
  limits: translateValue,
  quantity: translateValue,
  client: translateValue,
  active: translateValue,
  type: translateValue,
  valid: translateValue,
  first_consumer_purchase: translateValue,
  includes_shipping: translateValue,
  combines_with_other_discounts: translateValue,
};

export const emptyValueFor = (key: string) => `${key}.empty`;
