import { BaseCard, InteractiveListPriv } from '@tiendanube/components';

function PaymentsHistoryCardSkeleton(): JSX.Element {
  return (
    <BaseCard.Body>
      <InteractiveListPriv skeleton>
        <InteractiveListPriv.ActionItem
          skeleton
          name=""
          title=""
          onChange={() => null}
        />
        <InteractiveListPriv.ActionItem
          skeleton
          name=""
          title=""
          onChange={() => null}
        />
        <InteractiveListPriv.ActionItem
          skeleton
          name=""
          title=""
          onChange={() => null}
        />
      </InteractiveListPriv>
    </BaseCard.Body>
  );
}

export default PaymentsHistoryCardSkeleton;
