import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Input } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailVideoBlur } from 'domains/Catalog/Products/tracking';

interface VideoProps {
  videoUrl?: string;
  errors: Partial<Record<string, string>>;
  onChange: ({ name, value }: { name: string; value: string }) => void;
  isEdit?: boolean;
}

function Video({
  videoUrl,
  errors,
  onChange,
  isEdit,
}: VideoProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const hasError = !!errors[`videoUrl`];
  const errorHelpText = errors[`videoUrl`] || '';
  const sender = useTrackFullCatalog();
  useScrollToError(errors);

  const handleBlur = ({ value }: { name: string; value: string }) => {
    sender(() => {
      trackingProductDetailVideoBlur(Boolean(value), isEdit);
    });
  };

  return (
    <Card title={t('products.detail.video')}>
      <Input
        type="text"
        label={t('products.detail.videoLabel')}
        placeholder="https://"
        onChange={onChange}
        onBlur={handleBlur}
        name="videoUrl"
        value={videoUrl || ''}
        appearance={hasError ? 'validation_error' : undefined}
        helpText={hasError ? t(errorHelpText) : undefined}
      />
    </Card>
  );
}

export default Video;
