import { Box, BoxBackgroundColorProperties, Text } from '@nimbus-ds/components';
import { AdminDisabledReasonsType } from '@tiendanube/common';
import { useTranslationCommon } from 'commons/hooks';
import useIsEmbeddedApp from 'domains/PartnersApps/components/EmbeddedApp/useIsEmbeddedApp';
import { useExpirationBanner } from './useExpirationBanner';

const BANNER_BACKGROUND_COLOR: Record<
  AdminDisabledReasonsType,
  BoxBackgroundColorProperties
> = {
  cancelled: 'warning-surface',
  expired: 'danger-surface',
};

const WIDH_MENU_DESKTOP = 270;

export function ExpirationBanner() {
  const {
    showExpirationBanner,
    disabledReason,
    remainingDaysToExpiration,
    isAlmostExpired,
    isExpired,
  } = useExpirationBanner();
  const { isEmbeddedApp } = useIsEmbeddedApp();

  const keyPrefix = isAlmostExpired ? 'almostTimeToExpire' : disabledReason;

  const t = useTranslationCommon(`expirationBanner.${keyPrefix}`);

  if (showExpirationBanner && !isEmbeddedApp && !isExpired) {
    return (
      <Box
        backgroundColor={
          isAlmostExpired
            ? 'warning-surface'
            : BANNER_BACKGROUND_COLOR[disabledReason]
        }
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        height="3rem"
        gap="2"
        position="fixed"
        bottom="0"
        width={`calc(100% - ${WIDH_MENU_DESKTOP}px)`}
      >
        <Box display="flex" gap="2" alignItems="center">
          <Text>
            {t(
              disabledReason !== 'cancelled' && remainingDaysToExpiration === 0
                ? 'expiresTodayText'
                : 'text',
              {
                count: remainingDaysToExpiration,
              },
            )}
          </Text>
        </Box>
      </Box>
    );
  }
  return null;
}
