import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { GenerateContentsPageRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { generateContentsPageAction } from '../../contentPagesSlice';

function useGenerateContentsPage() {
  const dispatch = useAppDispatch();

  const generateContentsPage = useCallback(
    async (requestDto: GenerateContentsPageRequestDto) => {
      const result = await dispatch(generateContentsPageAction(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { generateContentsPage };
}

export default useGenerateContentsPage;
