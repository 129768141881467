import { ChangeEvent } from 'react';
import {
  FreeShippingDeliveryMethodsDto,
  FreeShippingZoneDto,
} from '@tiendanube/common';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { Stack } from 'commons/components';
import { CategoriesInterface } from 'domains/Marketing/components';
import { FreeShippingFormState } from 'domains/Marketing/FreeShipping/types';
import {
  CategoriesSelector,
  CombinesWithOtherDiscounts,
  ConditionsCard,
  DeliveryMethodsCard,
  DeliveryZonesSelector,
  PurchaseValue,
} from './components';

export interface FreeShippingFormProps {
  values: FreeShippingFormState;
  errors: Partial<Record<string, string>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeMethods: (methods: FreeShippingDeliveryMethodsDto[]) => void;
  onChangeCategories: (categories: CategoriesInterface[]) => void;
  onChangeZones: (methods: FreeShippingZoneDto[]) => void;
}

export function FreeShippingForm({
  values,
  errors,
  onChange,
  onChangeMethods,
  onChangeCategories,
  onChangeZones,
}: Readonly<FreeShippingFormProps>) {
  useScrollToError(errors);
  const showRequiredError = errors?.methods === 'empty';

  return (
    <Stack column align="flex-start">
      <DeliveryMethodsCard
        methods={values.methods}
        showRequiredError={showRequiredError}
        onChange={onChangeMethods}
      />
      <ConditionsCard>
        <CombinesWithOtherDiscounts
          value={values.combinesWithOtherDiscounts}
          onChange={onChange}
        />
        <CategoriesSelector
          categories={values.categories}
          categoriesType={values.categoriesType}
          onChangeCategories={onChangeCategories}
          onChange={onChange}
        />
        <DeliveryZonesSelector
          zones={values.zones}
          zonesType={values.zonesType}
          onChange={onChange}
          onChangeZones={onChangeZones}
        />
        <PurchaseValue value={values.priceMinimum} onChange={onChange} />
      </ConditionsCard>
    </Stack>
  );
}
