import {
  StratusLegendContentCompared,
  StratusLegendContentDefault,
} from './components';
import { LegendStratusProps } from '../LegendStratus';

export interface StratusLegendContentProps
  extends Omit<LegendStratusProps, 'children'> {
  formatLabel?: (label: string) => string;
  hasComparedData?: boolean;
  isCombined?: boolean;
}

function StratusLegendContent({
  hasComparedData = false,
  ...props
}: StratusLegendContentProps) {
  if (hasComparedData) {
    return <StratusLegendContentCompared {...props} />;
  }
  return <StratusLegendContentDefault {...props} />;
}

export default StratusLegendContent;
