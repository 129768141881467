import {
  VerifyAuthenticationFactorCodeRequestDto,
  VerifyAuthenticationFactorCodeResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

export const AuthenticationFactorService = {
  verifyAndAuthorize: async (
    params: VerifyAuthenticationFactorCodeRequestDto,
  ): Promise<VerifyAuthenticationFactorCodeResponseDto> => {
    const { data } = await axios.post(
      '/v1/authentication-factor/verify',
      params,
    );
    return data;
  },
};
