import { useMemo } from 'react';
import { FEATURE_ORDER_PRODUCTS_FILTER } from 'App/features';
import { ORDERS_PRODUCTS_FILTER_ENABLED } from 'App/featuresFlags';
import {
  useGetTags,
  useStatusFeatureByKeyWithDefaultValue,
} from 'domains/Auth/hooks';

function useIsProductsFilterEnabled() {
  const tags = useGetTags();
  const hasProductsFilterTag = useMemo(
    () => tags.includes(ORDERS_PRODUCTS_FILTER_ENABLED),
    [tags],
  );

  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_ORDER_PRODUCTS_FILTER,
    0,
  );

  const isEnabled = useMemo(
    () => isAvailable && hasProductsFilterTag,
    [isAvailable, hasProductsFilterTag],
  );

  return isEnabled;
}

export default useIsProductsFilterEnabled;
