import { useEffect } from 'react';
import { Alert, Link, Box, Text, Button } from '@nimbus-ds/components';
import { FirstSaleType } from '@tiendanube/common';
import { useBoolean, useModal, useToastStatus } from 'commons/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import FirstSaleModal from './FirstSaleModal';
import useAlertFirstSale from './useAlertFirstSale';

function AlertFirstSale() {
  const t = useTranslationDashboard();
  const { getFirstSale, firstSale, markFirstSale, markFirstSaleRealStatus } =
    useAlertFirstSale();
  const [isShowAlert, openAlert, closeAlert] = useBoolean();
  const [isShow, openModal, closeModal] = useModal();

  useToastStatus({
    status: markFirstSaleRealStatus,
    error: t('firstSaleAlert.error'),
    onSuccess: openModal,
  });

  useEffect(() => {
    if (!firstSale) getFirstSale();
    firstSale?.type === 'first-sale' && !firstSale?.isRead
      ? openAlert()
      : closeAlert();
  }, [closeAlert, firstSale, getFirstSale, openAlert]);

  const handleMarkFirstSaleRead = (type: FirstSaleType) => () => {
    markFirstSale({
      type,
      isRead: true,
    });
  };

  const isDisabled = markFirstSaleRealStatus === 'loading';

  return (
    <>
      <Alert show={isShowAlert} appearance="primary" onRemove={closeAlert}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{
            lg: 'row',
            md: 'column',
            xs: 'column',
          }}
          gap="2"
        >
          <Text color="primary-textHigh" fontWeight="bold">
            {t('firstSaleAlert.title')}
          </Text>
          <Box display="flex" gap="4" alignItems="center">
            <Button
              disabled={isDisabled}
              onClick={handleMarkFirstSaleRead('first-sale-real')}
              appearance="primary"
            >
              {t('firstSaleAlert.button')}
            </Button>
            <Link
              as="button"
              disabled={isDisabled}
              onClick={handleMarkFirstSaleRead('first-sale-test')}
            >
              {t('firstSaleAlert.link')}
            </Link>
          </Box>
        </Box>
      </Alert>
      <FirstSaleModal
        saleType={firstSale?.type}
        isShow={isShow}
        onDismiss={closeModal}
      />
    </>
  );
}

export default AlertFirstSale;
