import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  OcaLabelsSettingOptionsResponseDto,
  OrderDetailsForOcaLabelResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getOcaLabelSettingOptions,
  getOrderDetailsForOcaLabel,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';
import {
  getOcaLabelSettingOptionsData,
  getOcaLabelSettingOptionsStatus,
  getOrdersDetailsForOcaLabelsData,
  getOrdersDetailsForOcaLabelsStatus,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice/fulfillmentOrdersSelectors';

export interface UseOcaCardsInfoResult {
  documentSettings: OcaLabelsSettingOptionsResponseDto;
  ordersDetails: OrderDetailsForOcaLabelResponseDto[];
  isLoadingCardsInfo: boolean;
  isErrorCardsInfo: boolean;
  isSuccessCardsInfo: boolean;
  getCardsInfo: (
    orders: string[],
    isOverwriting: boolean,
  ) => Promise<{
    ordersDetails: OrderDetailsForOcaLabelResponseDto[];
    documentSettings: OcaLabelsSettingOptionsResponseDto;
  }>;
}

function useOcaCardsInfo(): UseOcaCardsInfoResult {
  const dispatch = useAppDispatch();
  const {
    isLoading: isLoadingSettings,
    isError: isErrorInSettings,
    isSuccess: isSuccessSettings,
  } = useSelector(getOcaLabelSettingOptionsStatus);

  const {
    isLoading: isLoadingOrderDetails,
    isError: isErrorInOrderDetails,
    isSuccess: isSuccessOrderDetails,
  } = useSelector(getOrdersDetailsForOcaLabelsStatus);

  const isLoadingCardsInfo = isLoadingOrderDetails || isLoadingSettings;
  const isSuccessCardsInfo = isSuccessOrderDetails && isSuccessSettings;
  const isErrorCardsInfo = isErrorInSettings || isErrorInOrderDetails;

  const documentSettings = useSelector(getOcaLabelSettingOptionsData);
  const ordersDetails = useSelector(getOrdersDetailsForOcaLabelsData);

  const getCardsInfo = useCallback(
    async (orders: string[], isOverwriting: boolean) => {
      await dispatch(getOcaLabelSettingOptions());
      const documentSettings = unwrapResult(
        await dispatch(getOcaLabelSettingOptions()),
      );
      const ordersDetails = unwrapResult(
        await dispatch(
          getOrderDetailsForOcaLabel({
            orders,
            isOverwriting,
          }),
        ),
      );
      return {
        ordersDetails,
        documentSettings,
      };
    },
    [dispatch],
  );

  return {
    documentSettings,
    ordersDetails,
    isLoadingCardsInfo,
    isSuccessCardsInfo,
    isErrorCardsInfo,
    getCardsInfo,
  };
}

export default useOcaCardsInfo;
