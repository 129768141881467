import isEqual from 'lodash.isequal';
import {
  SelectedProductInterface,
  VariantsSelect,
} from 'domains/Catalog/Products/components';
import { SelectedVariantInterface } from '..';

export const generateVariant = (
  product: SelectedProductInterface,
): SelectedVariantInterface => {
  const defaultValues = {};
  product.attributes.forEach((attribute) => {
    defaultValues[attribute.name] = attribute.values[0];
  });
  const productVariant = getVariantByValues(product, defaultValues);

  const variantId =
    product.attributes.length === 0
      ? product.variants[0].id
      : productVariant?.id;
  const price =
    product.attributes.length === 0
      ? product.variants[0].price
      : productVariant?.price;

  return {
    productId: product.id,
    stock: productVariant?.stock,
    variantId: variantId,
    quantity: 1,
    price: price,
    values: defaultValues,
  };
};

export const getVariantByValues = (
  product: SelectedProductInterface,
  values: Record<string, string>,
): VariantsSelect | undefined =>
  product.variants.find((v) => isEqual(v.values, values));
