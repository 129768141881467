import {
  Checkbox,
  Text,
  Stack,
  Tooltip,
  InterfaceNameChecked,
} from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface RoundPricesOptionProps {
  roundPrices: boolean;
  onChange: (round: boolean) => void;
  name: string;
}

function RoundPricesOption({
  roundPrices,
  onChange,
  name,
}: RoundPricesOptionProps): JSX.Element {
  const t = useTranslationCatalog();

  const handleChange = (e: InterfaceNameChecked) => onChange(e.checked);

  return (
    <Stack spacing="tight" align="center">
      <Checkbox
        label={t('products.editPrice.roundPrice')}
        name={name}
        checked={roundPrices}
        onChange={handleChange}
      />
      <Tooltip
        ariaLabel={t('products.editPrice.roundPriceTooltip')}
        labelIcon={InfoCircleIcon}
      >
        <Text>{t('products.editPrice.roundPriceTooltip')}</Text>
      </Tooltip>
    </Stack>
  );
}

export default RoundPricesOption;
