import { ReactNode } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import classNames from 'classnames';
import { BaseCard, Title } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import Skeleton from './Skeleton';
import './BaseCardCollapsible.scss';
import Toggle from '../Toggle';

interface BaseCardCollapsibleProps {
  title: string | ReactNode;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string | ReactNode;
  active: boolean;
  onChange: () => void;
  appearance?: 'chevron' | 'toggle';
  paddingBody: 'none' | 'tight' | 'base' | 'loose';
  alignSwitch?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  skeleton?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

/**
 * @deprecated This component is deprecated. Please use CardCollapsible instead.
 */
function BaseCardCollapsible({
  title,
  titleSize = 'h3',
  subtitle,
  active,
  disabled = false,
  children,
  appearance = 'chevron',
  paddingBody = 'base',
  alignSwitch = 'center',
  onChange,
}: BaseCardCollapsibleProps): JSX.Element {
  const renderComponent: Record<'chevron' | 'toggle', ReactNode> = {
    chevron: (
      <div className="stratus--card-collapsible-action-collapse">
        {active ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </div>
    ),
    toggle: <Toggle name="toggleCardCollapsible" checked={active} />,
  };

  const handleChange = !disabled ? onChange : () => null;

  const classNameHeader = classNames('stratus--card-collapsible-header', {
    'stratus--card-collapsible-header__disabled': disabled,
  });

  const header =
    typeof title === 'string' ? <Title type={titleSize}>{title}</Title> : title;

  return (
    <div className="stratus--card-collapsible">
      <BaseCard>
        <BaseCard.Header>
          <div className={classNameHeader} onClick={handleChange}>
            <Box
              display="grid"
              gridTemplateColumns="1fr auto"
              gap="2"
              alignItems={alignSwitch}
            >
              {header}
              {renderComponent[appearance]}
            </Box>
          </div>
          {subtitle && typeof subtitle === 'string' ? (
            <Box marginTop="4">
              <Text fontSize="highlight">{subtitle}</Text>
            </Box>
          ) : (
            subtitle
          )}
        </BaseCard.Header>
        {active && (
          <BaseCard.Body padding={paddingBody}>
            <div className="stratus--card-collapsible-content">{children}</div>
          </BaseCard.Body>
        )}
      </BaseCard>
    </div>
  );
}

BaseCardCollapsible.Skeleton = Skeleton;

export default BaseCardCollapsible;
