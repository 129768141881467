import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  InterfaceNameValue,
  RadioButtonGroup,
  InterfaceRadioButtonOption,
} from '@tiendanube/components';
import { WeightDimentionType } from 'domains/Catalog/Products/productsServices';

interface OnChangeValue {
  name: string;
  value: WeightDimentionType;
}

interface StockProps {
  value: WeightDimentionType;
  onChange: ({ name, value }: OnChangeValue) => void;
}
function WeightDimention({ value, onChange }: StockProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const options: WeightDimentionType[] = [
    '',
    'non-dimensions',
    'non-weight',
    'false',
  ];

  const labelOptions: InterfaceRadioButtonOption[] = options.map((value) => ({
    value,
    label: t(`products.filters.weightDimension.${value || 'all'}`),
  }));

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <RadioButtonGroup
      label={t('products.filter.weightDimension')}
      name="weightDimension"
      options={labelOptions}
      onChange={handleChange}
      value={value}
    />
  );
}

export default WeightDimention;
