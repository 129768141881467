import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

const getUsersListState = (state: RootStateType) =>
  state.cirrusApi.queries.getUsersList;

const getUsersListInfState = (state: RootStateType) =>
  state.cirrusApi.queries.getUsersListInf;

export const getUsersQuantity = createSimpleSelector(
  [getUsersListState],
  (usersList) =>
    typeof usersList?.data === 'object'
      ? (usersList?.data as any)?.pagination?.totalResults
      : 0,
);

export const getUsersInfQuantity = createSimpleSelector(
  [getUsersListInfState],
  (usersList) =>
    typeof usersList?.data === 'object'
      ? (usersList?.data as any)?.pagination?.totalResults
      : 0,
);
