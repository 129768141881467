// Constants
const HEADER_BYTE_LENGTH = 12; // Number of bytes to read from the file header
const RIFF_HEADER = '52494646'; // RIFF file type header
const RIFF_OFFSET_INIT = 16; // Offset inital to identify RIFF sub-format
const RIFF_OFFSET_END = 24; // Offset end to identify RIFF sub-format

// Image MIME types
const IMAGE_MIME_TYPES: Record<string, string> = {
  '89504e47': 'image/png',
  '47494638': 'image/gif',
  ffd8: 'image/jpeg',
  [`${RIFF_HEADER}-webp`]: 'image/webp', // RIFF WebP
};

// Video MIME types
const VIDEO_MIME_TYPES: Record<string, string> = {
  '1a45dfa3': 'video/webm',
  [`${RIFF_HEADER}-avi`]: 'video/avi', // RIFF AVI
  '00000014': 'video/quicktime',
  '000001ba': 'video/mpeg',
  '000001b3': 'video/mpeg',
  '66747970': 'video/mpeg',
  '00000020': 'video/mp4',
  '0000001c': 'video/3gpp',
  '464c5601': 'video/x-flv',
};

// Sub-formats for RIFF files
const RIFF_FORMATS: Record<string, string> = {
  '57454250': 'image/webp',
  '41564920': 'video/avi',
};

// Combine all MIME types
const ALL_MIME_TYPES: Record<string, string> = {
  ...IMAGE_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
};

// Export MIME type arrays for external usage
export const imageMimeTypes = Object.values(IMAGE_MIME_TYPES);
export const videoMimeTypes = Object.values(VIDEO_MIME_TYPES);

/**
 * Detects the MIME type of a file based on its binary header.
 * @param file - The file to analyze.
 * @returns A promise resolving to the MIME type or an empty string if unknown.
 */
export const getMimeType = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = new Uint8Array(
        e.target?.result as ArrayBuffer,
      ).subarray(0, HEADER_BYTE_LENGTH);

      // Convert buffer to hex string
      const headerHex = Array.from(arrayBuffer)
        .map((byte) => byte.toString(RIFF_OFFSET_INIT).padStart(2, '0'))
        .join('');

      // Handle RIFF format specifically
      if (headerHex.startsWith(RIFF_HEADER)) {
        const riffType = headerHex.slice(RIFF_OFFSET_INIT, RIFF_OFFSET_END); // Extract sub-format
        return resolve(RIFF_FORMATS[riffType] || '');
      }

      // General MIME type matching
      for (const [signature, mimeType] of Object.entries(ALL_MIME_TYPES)) {
        if (headerHex.startsWith(signature)) {
          return resolve(mimeType);
        }
      }

      resolve(''); // Return empty string if no match
    };

    reader.readAsArrayBuffer(file);
  });

export default getMimeType;
