import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { ProductInterface } from 'domains/Orders/components/ProductSearcher';
import { getProductsData } from './productSelectors';

export const getSelectProductListSelector = (state: RootStateType) =>
  getProductsData(state).selectProductsList;

export const getSelectProductListDataSelector = (state: RootStateType) =>
  getSelectProductListSelector(state).data;

export const getEditOrderProductsDataSelector = (
  state: RootStateType,
): ProductInterface<false>[] | undefined =>
  getSelectProductListSelector(state).data?.flatMap((p) =>
    p.variants.map((v) => ({
      ...v,
      ...p,
      productId: p.id.toString(),
      variantId: v.id.toString(),
      variantValues: v.values,
      stock: v.stock,
      inventoryLevels: v.inventoryLevels,
      sku: v.sku,
      visible: p.visible,
    })),
  );

export const getSelectProductListStatusSelector = (state) => {
  const status = getSelectProductListSelector(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getSelectProductListPaginationSelector = (state: RootStateType) =>
  getSelectProductListSelector(state).pagination;

export const getSelectProductListRefreshStatusSelector = (
  state: RootStateType,
) => {
  const status = getSelectProductListSelector(state).refreshStatus;
  return {
    isRefreshing: status === 'loading',
    isRefreshError: status === 'error',
    isRefreshSuccess: status === 'success',
  };
};

export const getAuthorizeStatusCode = createSimpleSelector(
  [getSelectProductListSelector],
  (selectProductsList) => selectProductsList.statusCode,
);
