import { Alert, Label, Stack, Text } from '@tiendanube/components';
import { BoxPackedIcon, LocationIcon } from '@tiendanube/icons';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import FulfillOrdersList from '../FulfillOrdersList';
import { FulfillOrdersValue } from '../FulfillOrdersModal';

interface FulfillBulkOrdersProps {
  fulfillOrders: FulfillOrdersValue[];
  onChangeShippingCode: (
    orderId: string,
    fulfillmentId: string | undefined,
    value,
  ) => void;
  onRemoveFulfillOrders: (
    orderId: string,
    fulfillmentId: string | undefined,
  ) => void;
  selectedOrders: number;
  selectedPackages: number;
  hasFulfillments: boolean;
  ordersMissingTracking: number;
  isSingleOrder: boolean;
}

function FulfillBulkOrders({
  fulfillOrders,
  onChangeShippingCode,
  onRemoveFulfillOrders,
  selectedOrders,
  selectedPackages,
  hasFulfillments,
  ordersMissingTracking,
  isSingleOrder,
}: FulfillBulkOrdersProps): JSX.Element {
  const t = useTranslationOrders();
  const { appliedFilters } = useOrdersList();
  const { locations } = useLocations();

  const locationFilterLabel = locations.find(
    (location) => location.id === appliedFilters.location,
  )?.name;
  const maybeWithFulfillments = hasFulfillments
    ? 'withFulfillments'
    : 'withoutFulfillments';

  return selectedOrders > ORDERS_PER_PAGE ? (
    <>
      {ordersMissingTracking > 0 && (
        <Alert
          title={t(
            `fulfillModal.missingTrackingAlert.${maybeWithFulfillments}.title`,
            {
              count: ordersMissingTracking,
            },
          )}
          appearance="warning"
          show
        >
          <Text size="caption">
            {t(
              `fulfillModal.missingTrackingAlert.${maybeWithFulfillments}.description`,
            )}
          </Text>
        </Alert>
      )}
      <Stack column align="stretch" spacing="tight">
        <Stack.Item>
          <Text size="caption">{t('fulfillModal.titleBulkOrderSelected')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Label
            id="orders-bulk-fulfill-label"
            icon={BoxPackedIcon}
            label={
              selectedOrders < 100
                ? `${t(
                    `fulfillModal.orderBulkSelected.${maybeWithFulfillments}`,
                    {
                      count: selectedOrders,
                      countFulfillments: selectedPackages,
                    },
                  )}`
                : `${t(
                    `fulfillModal.orderBulkSelectedMax.${maybeWithFulfillments}`,
                    {
                      countFulfillments: selectedPackages,
                    },
                  )}`
            }
          />
        </Stack.Item>
        <Stack.Item>
          {!!locationFilterLabel && (
            <Label
              id="orders-bulk-location-label"
              icon={LocationIcon}
              label={`${t('fulfillModal.fromLocation')} ${locationFilterLabel}`}
            />
          )}
        </Stack.Item>
      </Stack>
    </>
  ) : (
    <FulfillOrdersList
      fulfillOrders={fulfillOrders}
      onRemoveFulfillOrders={onRemoveFulfillOrders}
      onChangeShippingCode={onChangeShippingCode}
      isSingleOrder={isSingleOrder}
    />
  );
}

export default FulfillBulkOrders;
