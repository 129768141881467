// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './catalog.es-AR.json';
import esMX from './catalog.es-MX.json';
import ptBR from './catalog.pt-BR.json';

const catalogTranslations: DomainTranslations = {
  name: 'catalog',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default catalogTranslations;
