import { FC } from 'react';
import { Stack, Text } from '@tiendanube/components';
import { IconProps } from '@tiendanube/icons';

import './StackInfoIcon.css';

interface StackInfoIconInterface {
  icon: FC<IconProps>;
  title: string;
  subtitle: string;
  color: 'success' | 'blue' | 'dark';
}

const stylesColor = {
  success: 'Success',
  blue: 'PrimaryBlue',
  dark: 'DarkBlue',
};

function StackInfoIcon({
  icon,
  title,
  subtitle,
  color,
}: StackInfoIconInterface): JSX.Element {
  const Icon = icon;

  const iconWrapperClasses = `StackInfoIcon ${
    stylesColor[color] || stylesColor['success']
  }`;

  return (
    <Stack align="stretch" spacing="tight">
      <Stack.Item>
        <div className={iconWrapperClasses} aria-label={title}>
          <Icon size="small" />
        </div>
      </Stack.Item>
      <Stack.Item fill>
        <Text bold block size="base">
          {title}
        </Text>
        <Text appearance="light" block size="caption">
          {subtitle}
        </Text>
      </Stack.Item>
    </Stack>
  );
}

export default StackInfoIcon;
