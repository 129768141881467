import { endOfWeek, isAfter, endOfMonth, endOfYear } from 'date-fns';
import dayjs from 'dayjs';
import { dateFormat, getTimeFromString } from 'commons/utils/date';
import { StatisticsGranularityType } from 'domains/Statistics/types';
import { FOTMATS_TOOLTIP } from 'domains/Statistics/utils';
import useTranslationStatistics from '../useTranslationStatistics';

function useGetFormatedDateTooltip(granularity: StatisticsGranularityType) {
  const t = useTranslationStatistics();

  const getFormattedPeriod = (startDate: Date | number, endDate: Date) => {
    const endDateToFormat = getEndDate(endDate);

    const start = dayjs(startDate).format(FOTMATS_TOOLTIP['weekly']);
    const end = dayjs(endDateToFormat).format(FOTMATS_TOOLTIP['weekly']);

    if (start !== end) {
      return t('statistics.formatTooltip.period', {
        start,
        end,
      });
    }
    return start;
  };

  const getFormattedWeek = (value: string) => {
    const time = getTimeFromString(value);
    const endOfWeekDate = endOfWeek(time, { weekStartsOn: 1 });

    return getFormattedPeriod(time, endOfWeekDate);
  };

  const getFormattedMonth = (value: string) => {
    const time = getTimeFromString(value);
    const endOfMonthDate = endOfMonth(time);

    return getFormattedPeriod(time, endOfMonthDate);
  };

  const getFormattedYear = (value: string) => {
    const time = getTimeFromString(value);
    const endOfYearDate = endOfYear(time);

    return getFormattedPeriod(time, endOfYearDate);
  };

  const getDateFormated = (date: string) => {
    if (!date) return '';
    switch (granularity) {
      case 'weekly':
        return getFormattedWeek(date);

      case 'monthly':
        return getFormattedMonth(date);

      case 'yearly':
        return getFormattedYear(date);

      default:
        return dateFormat(date, FOTMATS_TOOLTIP[granularity]);
    }
  };

  const getEndDate = (endDate: Date) => {
    const today = new Date();
    if (isAfter(endDate, today)) {
      return today;
    }
    return endDate;
  };

  return getDateFormated;
}

export default useGetFormatedDateTooltip;
