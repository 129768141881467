import {
  TransactionFeePaidResponseDto,
  TransactionFeeToPayResponseDto,
  TransactionFeeDetailListResponseDto,
  PaymentsHistoryResponseDto,
  PayOrderDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  TRANSACTION_FEE_TO_PAY,
  TRANSACTION_FEE_PAID,
  PAYMENTS_HISTORY,
  TRANSACTION_FEES_LIST,
  GET_OR_CREATE_PAY_ORDER,
} from './url';

const fetchTransactionFeeToPay =
  async (): Promise<TransactionFeeToPayResponseDto> => {
    const { data } = await axios.get(TRANSACTION_FEE_TO_PAY);
    return data;
  };

const fetchTransactionFeePaid =
  async (): Promise<TransactionFeePaidResponseDto> => {
    const { data } = await axios.get(TRANSACTION_FEE_PAID);
    return data;
  };

const fetchPaymentsHistory = async (): Promise<
  PaymentsHistoryResponseDto[]
> => {
  const { data } = await axios.get(PAYMENTS_HISTORY, {
    params: { concept: 'cpts' },
  });
  return data;
};

const fetchTransactionFees = async ({
  page,
  limit,
  split,
  paymentId,
}: {
  page?: number;
  limit?: number;
  split?: boolean;
  paymentId?: string;
}): Promise<TransactionFeeDetailListResponseDto> => {
  const { data } = await axios.get(TRANSACTION_FEES_LIST, {
    params: {
      page,
      limit,
      split: split || undefined,
      payment_id: paymentId || undefined,
    },
  });
  return data;
};

const getOrCreatePayOrder = async (): Promise<PayOrderDto> => {
  const { data } = await axios.post<PayOrderDto>(GET_OR_CREATE_PAY_ORDER);
  return data;
};

export default {
  fetchTransactionFeeToPay,
  fetchTransactionFeePaid,
  fetchPaymentsHistory,
  fetchTransactionFees,
  getOrCreatePayOrder,
};
