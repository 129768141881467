import { useSelector } from 'react-redux';
import { getAppVersion } from 'commons/utils/getAppVersion';
import { getCompatibilityVersion } from 'domains/Auth/authSlice/authSelectors';

export enum UpdateApp {
  INMEDIATED = 'INMEDIATED',
  SUGGESTED = 'SUGGESTED',
  FLEXIBLE = 'FLEXIBLE',
}

const getUpdateType = (compatibilityVersion: string): UpdateApp => {
  const appVersion = getAppVersion();
  const [APP_MAJOR, APP_MINOR, APP_PARCHE] = appVersion.split('.');
  const [BFF_MAJOR, BFF_MINOR, BFF_PARCHE] = compatibilityVersion.split('.');

  const requiredUpdateMajor = Number(BFF_MAJOR) > Number(APP_MAJOR);
  const requiredUpdateMinor = Number(BFF_MINOR) > Number(APP_MINOR);
  const requireSuggested = Number(BFF_PARCHE) > Number(APP_PARCHE);
  const isEqualVersion = Number(BFF_MINOR) === Number(APP_MINOR);

  if (requiredUpdateMajor || requiredUpdateMinor) {
    return UpdateApp.INMEDIATED;
  }
  if (isEqualVersion && requireSuggested) {
    return UpdateApp.SUGGESTED;
  }

  return UpdateApp.FLEXIBLE;
};

function useUpdateApp(updateType: UpdateApp): boolean {
  const compatibilityVersion = useSelector(getCompatibilityVersion);

  return updateType === getUpdateType(compatibilityVersion);
}

export default useUpdateApp;
