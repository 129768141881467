import { Box, Card, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function Skeleton() {
  const t = useTranslationMarketing('coupons.history');

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Box paddingBottom="2">
          <Text.Skeleton />
        </Box>
        <Box paddingBottom="2">
          <Text.Skeleton />
        </Box>
        <Box paddingBottom="2">
          <Text.Skeleton />
        </Box>
      </Card.Body>
    </Card>
  );
}
