import { useHistory } from 'react-router';
import { AuthenticationFactorDto } from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { AUTH_ROUTES } from 'domains/Auth/authRoutes';
import { useToken } from 'domains/Auth/hooks';
import { DASHBOARD_ROUTES } from 'domains/Dashboard';
import { useStatusMessages } from '../index';

interface UseVerificationCodeHandlersResponse {
  handleSubmit: (
    authenticationFactor: AuthenticationFactorDto,
  ) => Promise<void>;
  requestHasBeenExecutedAndFailed: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isRequestTimeoutError: boolean;
}

function useVerificationCodeHandlers(): UseVerificationCodeHandlersResponse {
  const { replace } = useHistory();
  const { addProgressToast, addSuccessToast, closeProgressToast } =
    useStatusMessages('authentication');
  const {
    createSession,
    authorizeData,
    isError,
    isLoading,
    isLogin,
    isRequestTimeoutError,
    isUnauthorizedError,
  } = useToken();

  const handleSubmit = async (
    authenticationFactor: AuthenticationFactorDto,
  ) => {
    if (!authorizeData || authorizeData.type === 'none')
      return replace(AUTH_ROUTES.logout);
    addProgressToast();

    const isValid = await createSession({
      authorizationCode: authorizeData.authorizationCode,
      authenticationFactor,
    });
    closeProgressToast();
    if (isValid) {
      addSuccessToast();
      let url = DASHBOARD_ROUTES.dashboard;
      if (authenticationFactor.type === AuthenticationFactorTypes.RECOVERY_CODE)
        url = `${url}?login_with_recovery_code`;
      return replace(url);
    }
  };

  return {
    handleSubmit,
    requestHasBeenExecutedAndFailed:
      isUnauthorizedError && !isLogin && !isLoading,
    isSuccess: isLogin,
    isError: !isUnauthorizedError && isError,
    isLoading,
    isRequestTimeoutError,
  };
}

export default useVerificationCodeHandlers;
