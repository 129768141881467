import { Device } from '@capacitor/device';
import { datadogLogs } from '@datadog/browser-logs';
import { LoggerRequestDto } from '@tiendanube/common';
import axios from 'App/axios';
import { getStorage } from 'App/storage';

const log = async (logStratus: LoggerRequestDto): Promise<void> => {
  try {
    const deviceInfo = await Device.getInfo();
    axios.post('/logger/info', {
      ...logStratus,
      context: deviceInfo,
    });
  } catch (error) {
    console.error(error);
  }
};

export const logError = async (messenge: string, ex: Error) => {
  const storeInfo = await getStorage('auth');
  const deviceInfo = await Device.getInfo();
  datadogLogs.logger.error(
    messenge,
    {
      storeInfo,
      deviceInfo,
    },
    ex,
  );
};

export default log;
