import { Link, LinkProperties } from '@nimbus-ds/components';
import { AiFeature } from '@tiendanube/common/src/enums';
import { useTranslationCommon } from 'commons/hooks';
import { trackingAiGenerateCtaClick } from 'domains/Ai/tracking';
import { AiIcon } from './AiIcon';

interface GenerateWithAiLinkProps {
  onClick: () => void;
  trackingFeature?: AiFeature;
  fontSize?: LinkProperties['fontSize'];
}
function GenerateWithAiLink({
  onClick,
  trackingFeature,
  fontSize = 'base',
}: Readonly<GenerateWithAiLinkProps>) {
  const t = useTranslationCommon();
  const handleOnClick = () => {
    if (trackingFeature) {
      trackingAiGenerateCtaClick({ feature: trackingFeature });
    }
    onClick();
  };
  return (
    <Link
      appearance="primary"
      onClick={handleOnClick}
      textDecoration="none"
      fontSize={fontSize}
    >
      <AiIcon />
      {t('aiGeneration.generateWithAI')}
    </Link>
  );
}

export default GenerateWithAiLink;
