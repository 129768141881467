// TODO: import this from App/constants
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';

const SETTING_BASE_ROUTE = '/settings';

export const PAYMENTS_BASE_ROUTE = `${SETTING_BASE_ROUTE}/payments`;

export const PAYMENTS_ROUTES = {
  customPaymentOldRoute: `${PAYMENTS_BASE_ROUTE}/custom-payment`,
  customPaymentNewRoute: `${PAYMENTS_BASE_ROUTE}/custom-payments`,
  settings: `${PAYMENTS_BASE_ROUTE}/:id`,
  enableMercadoPago: `${PAYMENTS_BASE_ROUTE}/mercadopago/enable`,
  mercadoPagoSettings: `${PAYMENTS_BASE_ROUTE}/mercadopago`,
  payUSettings: `${PAYMENTS_BASE_ROUTE}/${NativePaymentProviders.payu}`,
  payPalSettings: `${PAYMENTS_BASE_ROUTE}/${NativePaymentProviders.paypal}`,
  nuvempagoInitSettings: `${PAYMENTS_BASE_ROUTE}/nuvempago`,
  PAYMENTS_BASE_ROUTE,
  SETTING_BASE_ROUTE,
};
