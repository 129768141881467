import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, Text } from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';
import { StatsProductsItemType } from 'domains/Statistics/types';

import './StatsProductsItemEmpty.scss';

interface StatsProductsItemEmptyProps {
  type: StatsProductsItemType;
  quantity: string;
}

function StatsProductsItemEmpty({
  type,
  quantity,
}: StatsProductsItemEmptyProps): JSX.Element {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.STATISTICS);
  const { code } = useGetCurrency();
  const formatedQuantity = formatCurrency(Number(quantity), code, language);
  const isRecipe = type === 'higher-revenue' || type === 'lower-revenue';

  return (
    <div className="stratus--products-in-number-tab-empty">
      <Stack column align="center" justify="center">
        <InfoCircleIcon size="medium" />
        <Text textAlign="center">
          {t(`statistics.statsProductsCard.statsProductsEmpty.${type}`, {
            quantity: isRecipe ? formatedQuantity : quantity,
            ...(!isRecipe && { count: Number(quantity) }),
          })}
        </Text>
      </Stack>
    </div>
  );
}

export default StatsProductsItemEmpty;
