import { Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface ContactInfoPageErrorProps {
  fetchContactInfo: () => void;
}

function ContactInfoPageError({ fetchContactInfo }: ContactInfoPageErrorProps) {
  const t = useTranslationOnline('contactInfoPage');

  return (
    <Card>
      <Card.Body>
        <ErrorState
          title={t('errorState.title')}
          action={{
            children: t('errorState.action'),
            onClick: fetchContactInfo,
          }}
        />
      </Card.Body>
    </Card>
  );
}

export default ContactInfoPageError;
