import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Box, Checkbox, Link, Text } from '@nimbus-ds/components';
import { ChargeDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  useChargesToPay,
  useExpirationDate,
} from 'domains/Billing/Checkout/hooks';
import {
  PaymentStatusTag,
  ChargePeriodDetail,
} from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { TrialStatusTag } from './components';
import ChargeToPaySkeleton from './Skeleton';

interface ChargeToPayProps {
  charge: ChargeDto;
  setSelectedCharges?: Dispatch<SetStateAction<ChargeDto[]>>;
  hidePeriod?: boolean;
}

function ChargeToPay({
  charge,
  setSelectedCharges,
  hidePeriod = false,
}: ChargeToPayProps) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const formatCurrency = useFormatCurrency();
  const [isSelected, setIsSelected] = useState(charge.status !== 'IN_PROCESS');
  const { chargeTotalWithoutTaxes } = useChargesToPay(false);
  const { goTo } = useNavegate();
  const { isTrial } = useGetIsFreePlan();

  const { storeStatus } = useStoreStatus();
  const isPlan = charge.conceptCode === 'plan-cost';
  const forceSelection =
    charge.status === 'IN_PROCESS' ||
    (isPlan &&
      (storeStatus === 'admin_down' ||
        storeStatus === 'store_down' ||
        isTrial));

  const { expiresIn } = useExpirationDate(
    charge.conceptCode,
    charge.metadata?.appId,
  );

  const toogleChargeSelection = useCallback(
    (checked: boolean, charge: ChargeDto) => {
      if (checked) {
        setIsSelected(true);
        setSelectedCharges &&
          setSelectedCharges((selectedCharges) => [...selectedCharges, charge]);
      } else {
        setIsSelected(false);
        setSelectedCharges &&
          setSelectedCharges((selectedCharges) =>
            selectedCharges.filter(({ id }) => id !== charge.id),
          );
      }
    },
    [setSelectedCharges],
  );

  const goToDetails = () => {
    const externalReferenceQuery =
      charge.conceptCode === 'app-cost' &&
      charge.subscription?.externalReference
        ? `&externalReference=${charge.subscription.externalReference}`
        : '';
    goTo(
      `${CHECKOUT_ROUTES.conceptDetails}?concept=${charge.conceptCode}${externalReferenceQuery}&chargeId=${charge.id}`,
    );
  };

  const shouldHideDetails =
    (charge.conceptCode === 'plan-cost' && storeStatus === 'store_down') ||
    (charge.conceptCode === 'app-cost' && !charge.subscription);

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding="4"
      justifyContent="space-between"
    >
      <Box display="flex" flex="1 1 5%">
        <Box>
          <Checkbox
            name={`checkbox-charge-${charge.id}`}
            checked={isSelected}
            onChange={(e) => toogleChargeSelection(e.target.checked, charge)}
            disabled={forceSelection}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 75%" gap="1-5">
        <Text>{charge.description}</Text>
        {!hidePeriod && <ChargePeriodDetail {...charge} />}
        {isPlan && isTrial ? (
          <TrialStatusTag paymentStatus={charge.status} />
        ) : (
          <PaymentStatusTag paymentStatus={charge.status} />
        )}
        {!shouldHideDetails && (
          <Link fontSize="caption" appearance="primary" onClick={goToDetails}>
            {t('chargeDetails')}
          </Link>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 20%"
        alignItems="flex-end"
        gap="2"
      >
        <Text>
          {formatCurrency(
            chargeTotalWithoutTaxes(charge),
            charge.amountCurrency,
          )}
        </Text>
        {charge.conceptCode === 'plan-cost' && storeStatus === 'store_down' && (
          <Text fontSize="caption" color="neutral-textLow">
            {t('churned.expiredPlan')}
          </Text>
        )}
        {!isTrial &&
          storeStatus !== 'store_down' &&
          !!expiresIn &&
          expiresIn > 0 && (
            <Text fontSize="caption" color="neutral-textLow">
              {t('expiresIn', { expiresIn })}
            </Text>
          )}
      </Box>
    </Box>
  );
}

ChargeToPay.Skeleton = ChargeToPaySkeleton;

export default ChargeToPay;
