import { Route, Switch } from 'react-router-dom';
import { CONTENT_PAGES_ROUTES } from './contentPageRoutes';
import { PagesList, PageEdit, PageCreation } from './pages';

const { pagesList, pageCreation, pageEditing } = CONTENT_PAGES_ROUTES;

function ContentPages() {
  return (
    <Switch>
      <Route exact path={pagesList}>
        <PagesList />
      </Route>
      <Route exact path={pageCreation}>
        <PageCreation />
      </Route>
      <Route exact path={pageEditing}>
        <PageEdit />
      </Route>
    </Switch>
  );
}

export default ContentPages;
