import { Icon, Input, Title, Box } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import { InteractiveList } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside';
import {
  ErrorScreen,
  InteractiveListRightCheckAll,
  InteractiveListRightCheckbox,
  Repeater,
  Stack,
} from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import useDeliverySubzonesModalAside, {
  UseDeliverySubzonesModalAsideProps,
} from './useDeliverySubzonesModalAside';

interface DeliverySubzonesModalAsideProps
  extends UseDeliverySubzonesModalAsideProps {
  onClose: () => void;
}

const skeleton = () => <InteractiveListRightCheckbox.Skeleton />;

export function DeliverySubzonesModalAside({
  isOpen,
  zone,
  onChange,
  onRemove,
  onClose,
}: Readonly<DeliverySubzonesModalAsideProps>) {
  const t = useTranslationMarketing('common');
  const { ref } = useAutoFocus();
  const {
    subzonesStore,
    isLoading,
    isError,
    isSuccess,
    isAllZoneSelected,
    search,
    selectedCount,
    allCheckedValue,
    refetch,
    handleCheckAll,
    handleOnChangeSearch,
    handleCheckedSubzone,
  } = useDeliverySubzonesModalAside({
    isOpen,
    zone,
    onChange,
    onRemove,
  });

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={
        <Box paddingLeft="4" paddingRight="4">
          <Title>{zone.description}</Title>
        </Box>
      }
      fluid
    >
      <Stack innerRef={ref} column align="stretch">
        <Box paddingLeft="4" paddingRight="4">
          <Input
            value={search}
            onChange={handleOnChangeSearch}
            append={<Icon source={<SearchIcon size="small" />} />}
            appendPosition="start"
            placeholder={t('search')}
          />
        </Box>
        {isError && <ErrorScreen onRetry={refetch} />}
        {isLoading && (
          <InteractiveList>
            <Repeater times={10} item={skeleton} />
          </InteractiveList>
        )}
        {isSuccess && (
          <InteractiveList>
            <InteractiveListRightCheckAll
              gender="female"
              selectedCount={selectedCount}
              checked={allCheckedValue}
              onChange={handleCheckAll}
            />
            {subzonesStore.map(({ id, description, checked }) => {
              const handleChange = () => {
                handleCheckedSubzone(
                  {
                    id,
                    description,
                  },
                  !checked,
                );
              };
              return (
                <InteractiveListRightCheckbox
                  key={id}
                  title={description}
                  checkbox={{
                    name: `method_${id}`,
                    checked: isAllZoneSelected || checked,
                    onChange: handleChange,
                  }}
                />
              );
            })}
          </InteractiveList>
        )}
      </Stack>
    </ModalAside>
  );
}
