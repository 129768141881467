export const SettingsCheckoutEndpoints = {
  getPreferencesCheckout: 'getPreferencesCheckout',
  updatePreferencesCheckout: 'updatePreferencesCheckout',
} as const;

export const SettingsCheckoutTags = {
  getPreferencesCheckout: 'getPreferencesCheckout',
} as const;

export const BASE_SETTINGS_CHECKOUT_URL = '/v1/settings/preferences-checkout';
