import { Stack, Text } from '@tiendanube/components';
import {
  GoogleShoppingCategories,
  SeoCategories,
} from 'domains/Catalog/Products/pages/components/Categories/components';
import { CardMetafieldsList } from 'domains/Metafields/components';
import FeaturedImageCard from './components/FeaturedImageCard';
import InformationEditCategoryCard from './components/InformationEditCategoryCard';

function Skeleton() {
  return (
    <Stack column align="stretch" spacing="tight">
      <Text.Skeleton size="featured" width="large" />
      <InformationEditCategoryCard.Skeleton />
      <FeaturedImageCard.Skeleton />
      <GoogleShoppingCategories.Skeleton />
      <SeoCategories.Skeleton />
      <CardMetafieldsList.Skeleton />
    </Stack>
  );
}

export default Skeleton;
