import { CardWithTitle, Spinner } from 'commons/components';
import { STATS_GENERAL_PAGE } from 'config/upsellFlowSources';
import {
  useStatisticsConversionStore,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import { ConversionYourStoreEmpty, ConversionBarChart } from './components';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';

function ConversionOfYourStoreCard() {
  const t = useTranslationStatistics();
  const {
    storeConversion,
    storeConversionStatus,
    hasAppliedFilters,
    fetchStoreConversion,
    openFilter,
  } = useStatisticsConversionStore();
  const isEmpty = storeConversion.length === 0;

  return (
    <CardWithTitle title={t('statistics.orderConversionCard.title')}>
      <ChartOrPlaceholder
        type="funnel"
        height={500}
        statsPlanRequired={STATS_PLAN_1_AND_2}
        upsellSource={STATS_GENERAL_PAGE}
      >
        {storeConversionStatus === 'loading' && <Spinner size="medium" />}
        {storeConversionStatus === 'error' && (
          <GenericError
            onRetry={fetchStoreConversion}
            text={t('statistics.orderConversionCard.error')}
          />
        )}
        {storeConversionStatus === 'success' &&
          isEmpty &&
          hasAppliedFilters && <GenericEmptySearch onRetry={openFilter} />}
        {storeConversionStatus === 'success' &&
          isEmpty &&
          !hasAppliedFilters && (
            <ConversionYourStoreEmpty onRetry={openFilter} />
          )}
        {storeConversionStatus === 'success' && !isEmpty && (
          <ConversionBarChart data={storeConversion} />
        )}
      </ChartOrPlaceholder>
    </CardWithTitle>
  );
}

export default ConversionOfYourStoreCard;
