import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Text, Modal } from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useAsyncFunc, useToast } from 'commons/hooks';

interface ConfirmationRemoveDraftOrderModalProps {
  onRemoveDraftOrder: () => Promise<void>;
  onClose: () => void;
  goBack?: () => void;
}

function ConfirmationRemoveDraftOrderModal({
  onRemoveDraftOrder,
  onClose,
  goBack,
}: ConfirmationRemoveDraftOrderModalProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [handleRemoveDraftOrder, , error, clearError] = useAsyncFunc(
    async () => {
      onClose();
      addToastProgress({
        label: t('draftOrders.modalDelete.progress'),
      });
      try {
        await onRemoveDraftOrder();
        addToast({
          label: t('draftOrders.modalDelete.success'),
          appearance: 'success',
        });
      } catch (e) {
        addToast({
          label: t('draftOrders.modalDelete.error'),
          appearance: 'danger',
        });
      } finally {
        closeToastProgress();
        goBack?.();
      }
    },
  );

  useEffect(() => {
    if (error) {
      closeToastProgress();
      addToast({
        label: t('draftOrders.modalDelete.error'),
        appearance: 'danger',
      });
      clearError();
    }
  }, [error, clearError, addToast, closeToastProgress, t]);

  return (
    <Modal
      show
      title={t('draftOrders.modalDelete.title')}
      onDismiss={onClose}
      primaryAction={{
        children: `${t('draftOrders.modalDelete.confirm')}`,
        onClick: handleRemoveDraftOrder,
        appearance: 'danger',
      }}
      secondaryAction={{
        children: `${t('draftOrders.modalDelete.cancel')}`,
        onClick: onClose,
      }}
    >
      <Text>{t('draftOrders.modalDelete.body')}</Text>
    </Modal>
  );
}

export default ConfirmationRemoveDraftOrderModal;
