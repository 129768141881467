import { CardCollapsible } from 'commons/components';
import { ShippingInformationCard } from '../../../components';

function Skeleton() {
  return (
    <>
      <ShippingInformationCard.Skeleton />
      <CardCollapsible.Skeleton />
      <CardCollapsible.Skeleton />
    </>
  );
}
export default Skeleton;
