import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';
import { getSummaries } from 'domains/Catalog/Products/productsSlice/productSelectors';

function useSummary(id: string) {
  const summaries = useSelector(getSummaries);
  return !isEmpty(summaries) ? summaries[id] : null;
}

export default useSummary;
