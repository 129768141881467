import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { DraftOrderDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  fetchDraftOrderById,
  confirmDraftOrder as confirmDraftOrderAction,
  cleanUpDraftOrderList,
  removeDraftOrder as removeDraftOrderAction,
} from '../../draftOrdersSlice';
import {
  getDraftOrderDetailsStatus,
  getDraftOrderDetails,
} from '../../draftOrdersSlice/draftOrdersSelectors';

interface UseDraftOrderDetailsResult {
  fetchDraftOrderDetails: () => Promise<void>;
  removeDraftOrder: (id: string) => Promise<void>;
  confirmDraftOrder: (id: string) => Promise<{ orderId: string }>;
  draftOrderDetails: DraftOrderDetailsResponseDto | null;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useDraftOrderDetails(id: string): UseDraftOrderDetailsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getDraftOrderDetailsStatus,
  );
  const draftOrderDetails = useSelector(getDraftOrderDetails);

  const fetchDraftOrderDetails = useCallback(async () => {
    await dispatch(fetchDraftOrderById(id));
  }, [dispatch, id]);

  const confirmDraftOrder = useCallback(
    async (id: string) => {
      const response = await dispatch(confirmDraftOrderAction(id));
      await dispatch(cleanUpDraftOrderList());

      return unwrapResult(response);
    },
    [dispatch],
  );

  const removeDraftOrder = useCallback(
    async (id) => {
      await dispatch(removeDraftOrderAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    fetchDraftOrderDetails();
  }, [fetchDraftOrderDetails]);

  return {
    fetchDraftOrderDetails,
    removeDraftOrder,
    confirmDraftOrder,
    draftOrderDetails,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useDraftOrderDetails;
