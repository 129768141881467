import { useState } from 'react';
import { Input, Text, Alert, InterfaceNameValue } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { TaxDataInterface } from '../ArgentineFiscalData';

interface UploadTaxDataWithDniProps {
  onChange: (e: InterfaceNameValue) => void;
  values: TaxDataInterface;
  errors: Partial<TaxDataInterface>;
}

export function UploadTaxDataWithDni({
  onChange,
  values,
  errors,
}: UploadTaxDataWithDniProps) {
  const t = useTranslationBilling();
  const { ref } = useAutoFocus();
  const [showAlert, setShowAlert] = useState(true);

  const dniHelpText = t(errors.dni || '');
  const businessNameHelpText = t(errors.businessName || '');
  const addressHelpText = t(errors.address || '');

  const closeAlert = () => setShowAlert(false);

  return (
    <Stack innerRef={ref} column align="stretch">
      <Alert show={showAlert} onDismiss={closeAlert} appearance="primary">
        {t('invoicesInfoPage.taxCondition.resAfip')}
      </Alert>
      <Stack spacing="tight" column align="flex-start">
        <Text>{t('invoicesInfoPage.taxCondition.invoiceType')}</Text>
        <Text bold>{t('invoicesInfoPage.taxCondition.invoiceTypeB')}</Text>
      </Stack>
      <Stack spacing="tight" column align="flex-start">
        <Text>{`${t('invoicesInfoPage.taxCondition.title')}:`}</Text>
        <Text bold>{t('invoicesInfoPage.taxCondition.vatCondition')}</Text>
      </Stack>
      <Input
        name="dni"
        onChange={onChange}
        helpText={dniHelpText}
        appearance={dniHelpText ? 'validation_error' : 'default'}
        value={values.dni}
        type="number"
        label={t('invoicesInfoPage.taxCondition.idTypeDni')}
      />
      <Input
        name="businessName"
        onChange={onChange}
        helpText={businessNameHelpText}
        appearance={businessNameHelpText ? 'validation_error' : 'default'}
        value={values.businessName}
        label={t('invoicesInfoPage.taxCondition.name')}
      />
      <Input
        name="address"
        onChange={onChange}
        helpText={addressHelpText}
        appearance={addressHelpText ? 'validation_error' : 'default'}
        value={values.address}
        label={t('invoicesInfoPage.taxCondition.address')}
      />
    </Stack>
  );
}
