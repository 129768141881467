import { Card, Input, Label, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { StatusType } from 'commons/types';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface EditFallbackCardProps {
  readonly cost?: string;
  readonly days?: string;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly handleCancel: () => void;
  readonly handleUpdateFallback: () => void;
  readonly status: StatusType;
}

function FallbackInfo({
  cost,
  days,
  onChange,
  handleCancel,
  handleUpdateFallback,
  status,
}: EditFallbackCardProps) {
  const t = useTranslationShipping();

  return (
    <>
      <Card.Body>
        <Stack column align="stretch">
          <Text fontSize="base" lineHeight="base" textAlign="left">
            {t('deliveryMethods.fallback.subTitle')}
          </Text>
          <Stack column spacing="tight" align="stretch">
            <Label htmlFor="cost">{t('deliveryMethods.fallback.cost')}</Label>
            <Input
              name="cost"
              onChange={onChange}
              disabled={status === 'loading'}
              value={cost}
              type="number"
              append={`${t('deliveryMethods.defaultShippingData.currency')}`}
              appendPosition="start"
            />
          </Stack>
          <Stack column spacing="tight" align="stretch">
            <Label htmlFor="days">{t('deliveryMethods.fallback.term')}</Label>
            <Input
              name="days"
              onChange={onChange}
              disabled={status === 'loading'}
              value={days}
              type="number"
              append={`${t('deliveryMethods.defaultShippingData.days')}`}
              appendPosition="end"
            />
          </Stack>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <CancelAndConfirmButtons
          onCancel={handleCancel}
          onConfirm={handleUpdateFallback}
          isLoading={status === 'loading'}
          isCancelDisabled={status === 'loading'}
        />
      </Card.Footer>
    </>
  );
}

export default FallbackInfo;
