import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'commons/utils';
import { STATS_TRAFFIC_PAGE } from 'config/upsellFlowSources';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_3, thousandSeparator } from 'domains/Statistics/utils';
import { ACQUISITION_SUMMARY_CHART_COLORS } from './constants';
import useAcquisitionSummary from './useAcquisitionSummary';
import CombinedBarChartAndSummaryCard from '../CombinedBarChartAndSummaryCard';

type AcquisitionItemsType = 'revenue' | 'sales';

function AcquisitionSummaryCard() {
  const t = useTranslationStatistics();

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    fetchAcquisitionSummary,
    hasComparedFilter,
    isEmpty,
    openFilter,
  } = useAcquisitionSummary();

  const {
    i18n: { language },
  } = useTranslation();
  const { code } = useGetCurrency();

  const formatSummaryTitle = (key) => t(`statistics.acquisitionCard.${key}`);

  const formatValueSummary = (text: number, key: string) => {
    const curr = ['revenue'];
    if (curr.includes(key)) return formatCurrency(text, code, language);
    return thousandSeparator(text);
  };

  const formatLabelTooltip = (label: string) => {
    const [, key] = label.split('.');
    return t(`statistics.acquisitionCard.formatKeys.${key}`);
  };

  return (
    <CombinedBarChartAndSummaryCard<AcquisitionItemsType>
      selectedPrimary="revenue"
      title={t('statistics.acquisitionCard.title')}
      data={data}
      statsPlanRequired={STATS_PLAN_3}
      upsellSource={STATS_TRAFFIC_PAGE}
      colors={ACQUISITION_SUMMARY_CHART_COLORS}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      fetchData={fetchAcquisitionSummary}
      hasComparedFilter={hasComparedFilter}
      formatSummaryTitle={formatSummaryTitle}
      formatLabelTooltip={formatLabelTooltip}
      formatValueSummary={formatValueSummary}
      onOpenFilter={openFilter}
      isEmpty={isEmpty}
    />
  );
}

export default AcquisitionSummaryCard;
