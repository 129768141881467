import { Box, Table } from '@nimbus-ds/components';
import { Repeater } from 'commons/components';
import { PromotionsListDesktopRowSkeleton } from './PromotionsListDesktopRowSkeleton';
import { PromotionsListHeader } from './PromotionsListHeader';

const promotionsListRow = () => <PromotionsListDesktopRowSkeleton />;

export function PromotionsListDesktopSkeleton() {
  return (
    <Box flexDirection="column" gap="2" display="flex">
      <Table>
        <PromotionsListHeader />
        <Table.Body>
          <Repeater times={5} item={promotionsListRow} />
        </Table.Body>
      </Table>
    </Box>
  );
}
