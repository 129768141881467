import { useState } from 'react';
import { Title } from '@nimbus-ds/components';
import { Stack, useResponsive } from 'commons/components';
import { useUuidIndexedArray } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ColorTile } from './components';

const MODAL_ASIDE_BREAKPOINT = 540;

// Be sure to mantain 6 colors per row
const colorMobileGrid = [
  ['none', '#FFFFFF', '#000000', '#666666', '#F5F5F5', '#E5E3E3'],
  ['#DCD7D3', '#D5CCD1', '#B7C2BB', '#BECDD2', '#D3E4C4', '#A9CFCA'],
  ['#FECDCE', '#FFE5D5', '#FFF5D9', '#EDFFD9', '#D9FFF9', '#D9E3FF'],
  ['#DE432C', '#F59C31', '#F2C730', '#3D9963', '#5CA6E5', '#8317E5'],
];

// Be sure to mantain 12 colors per row
const colorDesktopGrid = [
  [
    'none',
    '#FFFFFF',
    '#000000',
    '#666666',
    '#F5F5F5',
    '#E5E3E3',
    '#DCD7D3',
    '#D5CCD1',
    '#B7C2BB',
    '#BECDD2',
    '#D3E4C4',
    '#A9CFCA',
  ],
  [
    '#FECDCE',
    '#FFE5D5',
    '#FFF5D9',
    '#EDFFD9',
    '#D9FFF9',
    '#D9E3FF',
    '#DE432C',
    '#F59C31',
    '#F2C730',
    '#3D9963',
    '#5CA6E5',
    '#8317E5',
  ],
];

interface ColorsTileRowProps {
  colors: string[];
  selectedColor: string | undefined;
  handleColorSelect: (colorCode: string) => void;
  isMobile: boolean;
}

function ColorsTileRow({
  colors,
  selectedColor,
  handleColorSelect,
  isMobile,
}: Readonly<ColorsTileRowProps>) {
  const rowGap = isMobile ? '4' : '2';
  const colorsItems = useUuidIndexedArray(colors, 'color');
  return (
    <Stack justify="space-between" align="stretch" gap={rowGap} flex="1 1 auto">
      {colorsItems.map((colorItem) => (
        <Stack.Item key={colorItem.uuid} fill>
          <ColorTile
            color={colorItem.color}
            selected={selectedColor === colorItem.color}
            onClick={() => handleColorSelect(colorItem.color)}
          />
        </Stack.Item>
      ))}
    </Stack>
  );
}

interface ColorPickerProps {
  onSelect: (colorCode: string) => void;
  initialSelectedColor?: string;
}

function ColorPicker({
  onSelect,
  initialSelectedColor = undefined,
}: Readonly<ColorPickerProps>) {
  const { isMobile } = useResponsive(MODAL_ASIDE_BREAKPOINT); // For now, ColorPicker will be used only inside a ModalAside component
  const t = useTranslationCatalog(
    'products.modal.editPhoto.actions.changeBackgroundColor.modal.colorPicker',
  );

  const [selectedColor, setSelectedColor] = useState<string | undefined>(
    initialSelectedColor,
  );

  const handleColorSelect = (colorCode: string) => {
    setSelectedColor(colorCode);
    onSelect(colorCode);
  };

  const colorGrid = isMobile ? colorMobileGrid : colorDesktopGrid;

  const indexedColorGrid = useUuidIndexedArray(colorGrid, 'colors');

  return (
    <Stack column spacing="tight" align="flex-start">
      <Title as="h6" color="neutral-textHigh" textAlign="left">
        {t('title')}
      </Title>
      <Stack column gap="2" alignSelf="stretch" align="stretch">
        {indexedColorGrid.map((colorsItem) => (
          <ColorsTileRow
            key={colorsItem.uuid}
            colors={colorsItem.colors}
            handleColorSelect={handleColorSelect}
            selectedColor={selectedColor}
            isMobile={isMobile}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default ColorPicker;
