import './HtmlPreview.scss';

interface HtmlPreviewProps {
  html: string;
}

export function HtmlPreview({ html }: Readonly<HtmlPreviewProps>) {
  return (
    <div
      className="stratus--ai-generation-html-preview"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}
