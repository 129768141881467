import { useEffect, useState } from 'react';
import { InputNumber } from 'commons/components';
import { useTimeOut } from 'commons/hooks';
import useAsyncInput from 'domains/Catalog/Products/pages/ProductListPage/components/ProductResultsDesktop/components/ResultRowDesktop/useAsyncInput';

interface InputAsyncStockProps {
  defaultValue: number | string | null;
  onError?: () => void;
  asyncFunc: ({
    value,
    name,
  }: {
    value: string;
    name: string;
  }) => Promise<void>;
  disabled?: boolean;
}

function InputAsyncStock({
  defaultValue,
  onError,
  asyncFunc,
  disabled = false,
}: InputAsyncStockProps): JSX.Element {
  const [value, setValue] = useState(defaultValue);
  const resetValueWithDelay = useTimeOut(() => {
    setValue(defaultValue);
  });

  const [onFetchVariant, loading, error, success] = useAsyncInput<
    undefined,
    void
  >(async () => await asyncFunc({ value: `${value}`, name: 'stock' }));

  useEffect(() => {
    if (error) {
      resetValueWithDelay();
      onError?.();
    }
  }, [error, onError, resetValueWithDelay]);

  const handleOnChange = ({ value }) => {
    setValue(value);
  };

  const handleOnBlur = async () => {
    if (value !== defaultValue) {
      await onFetchVariant();
    }
  };

  const appearance = error
    ? 'validation_error'
    : success
    ? 'validation_success'
    : loading
    ? 'validation_loading'
    : value !== null && value !== '' && value <= 0
    ? 'danger'
    : value !== null && value !== '' && value <= 3
    ? 'warning'
    : undefined;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <InputNumber
      type="float"
      value={`${value ?? '∞'}`}
      onChange={handleOnChange}
      name="stock"
      appearance={appearance}
      onBlur={handleOnBlur}
      placeholder="∞"
      autoSelect
      disabled={disabled}
    />
  );
}

export default InputAsyncStock;
