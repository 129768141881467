import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { DiscountCircleIcon } from '@tiendanube/icons';
import ShowByRole from 'App/components/ShowByRole';
import { MIGRATION_OLD_PROMOTIONS } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useGetTags } from 'domains/Auth/hooks';
import { useHasFreeShippingEnabled } from 'domains/Marketing/FreeShipping/hooks';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import {
  menuSecondaryPromotionListClick,
  menuSecondaryFreeshipingListClick,
} from './tracking';

interface MarketingMenuProps {
  isMobile: boolean;
}

const path = {
  coupons: '/coupons',
  freeShipping: MARKETING_ROUTES.freeShippingList,
  promotions: MARKETING_ROUTES.promotionsList,
};

const pathOld = {
  freeShipping: '/free-shipping/',
  promotions: '/marketing/promotions/',
};

// eslint-disable-next-line max-statements
function MarketingMenu({ isMobile }: Readonly<MarketingMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const tags = useGetTags();
  const { hasOldFreeShipping, availableFreeShipping } =
    useHasFreeShippingEnabled();

  const menuCoupons = {
    children: t('menu.manage.marketing.coupons') as string,
    onClick: handleClickItem,
    active: pathname.startsWith(path.coupons),
    href: path.coupons,
  };

  const menuFreeShipping = {
    children: t('menu.manage.marketing.freeShipping') as string,
    onClick: () => {
      menuSecondaryFreeshipingListClick();
      handleClickItem();
    },
    active: pathname.startsWith(path.freeShipping),
    external: false,
    href: path.freeShipping,
  };

  const menuFreeShippingOld = {
    children: t('menu.manage.marketing.freeShipping') as string,
    onClick: () => {
      menuSecondaryFreeshipingListClick();
      goToAdmin(pathOld.freeShipping)();
    },
    active: pathname.startsWith(pathOld.freeShipping),
    external: true,
    href: '/admin' + pathOld.freeShipping,
  };

  const menuPromotions = {
    children: t('menu.manage.marketing.promotions') as string,
    onClick: () => {
      menuSecondaryPromotionListClick();
      handleClickItem();
    },
    active: pathname.startsWith(path.promotions),
    external: false,
    href: path.promotions,
  };

  const menuPromotionsOld = {
    children: t('menu.manage.marketing.promotions') as string,
    onClick: () => {
      menuSecondaryPromotionListClick();
      goToAdmin(pathOld.promotions)();
    },
    active: pathname.startsWith(pathOld.promotions),
    external: true,
    href: '/admin' + pathOld.promotions,
  };

  const hasOldPromotionsTag = tags.includes(MIGRATION_OLD_PROMOTIONS);

  const renderOldFreeshipping =
    hasOldFreeShipping && availableFreeShipping && !isMobile;
  const renderNewFreeshipping = !hasOldFreeShipping && availableFreeShipping;

  const renderOldPromotions = hasOldPromotionsTag && !isMobile;
  const renderNewPromotions = !hasOldPromotionsTag;

  const itemsMarketing: {
    children: string;
    onClick: () => void;
    external?: boolean;
    href: string;
    active?: boolean;
  }[] = [
    menuCoupons,
    ...(renderNewFreeshipping ? [menuFreeShipping] : []),
    ...(renderOldFreeshipping ? [menuFreeShippingOld] : []),
    ...(renderNewPromotions ? [menuPromotions] : []),
    ...(renderOldPromotions ? [menuPromotionsOld] : []),
  ];

  const activeMarketing =
    pathname.startsWith(path.coupons) ||
    pathname.startsWith(path.freeShipping) ||
    pathname.startsWith(path.promotions);

  return (
    <ShowByRole includeRoles={['full', 'marketing']}>
      <MenuButton.Accordion
        contentid="content-marketing"
        menuButton={{
          id: 'control-marketing',
          label: t('menu.manage.discounts.title'),
          startIcon: DiscountCircleIcon,
          'aria-controls': 'content-marketing',
          onClick: handleClickAccordion,
        }}
        open={activeMarketing}
        active={activeMarketing}
        as={Link}
        to={path.coupons}
      >
        {itemsMarketing.map((item, index) =>
          !item.external ? (
            <MenuButton
              key={index}
              as={Link}
              label={item.children}
              onClick={item.onClick}
              to={item.href}
              active={item.active}
            />
          ) : (
            <MenuButton
              key={index}
              as="a"
              onClick={item.onClick}
              label={item.children}
              href={item.href}
              active={item.active}
            />
          ),
        )}
      </MenuButton.Accordion>
    </ShowByRole>
  );
}

export default MarketingMenu;
