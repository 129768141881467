import {
  CheckoutPaymentMethod,
  ConceptCode,
  GatewayPaymentMethodType,
  PaymentBillingEngineDto,
} from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { isPayableCharge } from './utils';
import { useGetLastPaymentDataQuery } from '../CheckoutApi';

const CARD_METHODS: GatewayPaymentMethodType[] = ['CREDIT_CARD', 'DEBIT_CARD'];

function useGetLastPayment(
  concept: ConceptCode,
  externalReference?: string | null,
  initialFetch = true,
) {
  const {
    data: paymentData,
    refetch,
    status,
  } = useGetLastPaymentDataQuery(
    { concept, externalReference },
    { skip: !initialFetch },
  );
  const paymentStatus = paymentData?.conceptStatus;
  const payment = paymentData?.payment;
  const hasPayment = paymentData?.hasPayment;

  const lastSuccessfulPayment = paymentData?.lastSuccessfulPayment;
  const lastPaymentMethodUsed = getLastPaymentMethod(payment);
  const lastSuccessfulPaymentMethodUsed = getLastPaymentMethod(
    lastSuccessfulPayment,
  );
  const isPending = paymentStatus && isPayableCharge(paymentStatus);

  return {
    ...convertStatusTypeToObject(status),
    status: toStatusType(status),
    payment,
    paymentStatus,
    hasPayment,
    isFromRecurrentPayment: paymentData?.payOrder?.automaticallyGenerated,
    refreshLastPayment: refetch,
    isFirstPayment: paymentData?.isFirstPayment,
    lastPaymentMethodUsed,
    lastSuccessfulPayment,
    lastRelevantCharge: paymentData?.lastRelevantCharge,
    lastSuccessfulPaymentMethodUsed,
    isPending,
    getLastPaymentMethod,
  };
}

function getLastPaymentMethod(
  payment?: PaymentBillingEngineDto,
): CheckoutPaymentMethod | undefined {
  if (!payment) return undefined;
  if (payment.paymentMethod.type === 'TRANSFER') return 'transfer';
  if (CARD_METHODS.includes(payment.gatewayDetail?.paymentMethod)) return 'cc';
  return 'ticket';
}

export default useGetLastPayment;
