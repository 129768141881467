import { Icon, Table, Text, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { TRANSLATE_PAYMENT_PREFIX } from 'domains/Payments/PaymentProviders/components/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

export function PaymentMethodsHeader() {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);

  return (
    <Table.Head>
      <Table.Cell width="100px">
        <Text>{t('table.head.term')}</Text>
      </Table.Cell>
      <Table.Cell width="100px">
        <Stack spacing="tight">
          <Text>{t('table.head.fee')}</Text>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Stack justify="flex-end" spacing="tight">
          <Text>{t('table.head.cpt')}</Text>
          <Tooltip content={t('table.head.cptDetail')} position="left">
            <Icon source={<InfoCircleIcon size="small" />} />
          </Tooltip>
        </Stack>
      </Table.Cell>
    </Table.Head>
  );
}
