import { Responsive } from 'commons/components';
import { PaymentProviderListDesktop } from '../PaymentProviderListDesktop';
import { PaymentProviderListMobile } from '../PaymentProviderListMobile';

function Skeleton(): JSX.Element {
  return (
    <Responsive
      mobile={<PaymentProviderListMobile.Skeleton />}
      desktop={<PaymentProviderListDesktop.Skeleton />}
    />
  );
}

export default Skeleton;
