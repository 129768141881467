import { useState } from 'react';
import { CategoryTabedResponseDto } from '@tiendanube/common';
import { useToast } from 'commons/hooks';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useActionProducts } from '../../useActionProducts';

interface UseAssignCategoriesResult {
  categories: CategoryTabedResponseDto[];
  isLoading: boolean;
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => void;
  assignCategories: () => Promise<void>;
}

function useAssignCategories(
  selectedRowsId: string[],
): UseAssignCategoriesResult {
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const categories = useGetCategories();
  const { assignCategories: assignCategoriesAction } = useActionProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const assignCategories = async () => {
    try {
      setIsLoading(true);
      await assignCategoriesAction({
        products: selectedRowsId.map(Number),
        categories: selectedCategories.map(Number),
      });
      addToast({
        label: t('products.assignCategoriesModal.success', {
          count: selectedCategories.length,
        }),
        appearance: 'success',
      });
    } catch (error) {
      addToast({
        label: t('products.assignCategoriesModal.error'),
        appearance: 'danger',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    categories,
    isLoading,
    selectedCategories,
    setSelectedCategories,
    assignCategories,
  };
}

export default useAssignCategories;
