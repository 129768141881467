import { FulfillmentPreferenceTypeEnum, StatusEnum } from '@tiendanube/common';
import { fulfillment } from '../../pages/OrderListPage/components/commons/Status/status';

export const generateFulfillmentLabel = (
  status: StatusEnum,
  preferenceType: FulfillmentPreferenceTypeEnum,
  t,
) => {
  const fulfillmentLabel = fulfillment(status, preferenceType);

  return {
    ...fulfillmentLabel,
    id: fulfillmentLabel.status,
    label: t(`status.${fulfillmentLabel.status}`, {
      context: 'fulfillmentOrder',
    }),
  };
};
