import { Route } from 'react-router-dom';
import {
  TransactionFeesMonthSelectionPage,
  TransactionFeesPage,
  TransactionFeesListPage,
} from './pages';
import { TRANSACTION_FEES_ROUTES } from './transactionFeesRoutes';

function TransactionFees(): JSX.Element {
  return (
    <>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFees}>
        <TransactionFeesPage />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesSplitList}>
        <TransactionFeesListPage split />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesNoSplitList}>
        <TransactionFeesListPage split={false} />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesSplitMonthly}>
        <TransactionFeesListPage split />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesNoSplitMonthly}>
        <TransactionFeesListPage split={false} />
      </Route>
      <Route exact path={TRANSACTION_FEES_ROUTES.transactionFeesMonthSelection}>
        <TransactionFeesMonthSelectionPage />
      </Route>
    </>
  );
}

export default TransactionFees;
