import { DataList } from '@nimbus-ds/patterns';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { LocationsListRow } from './components';

interface LocationsListMobileProps {
  openSetMainModal: (locationId: any) => void;
  openDeleteModal: (locationId: any) => void;
}

function LocationsListMobile({
  openSetMainModal,
  openDeleteModal,
}: Readonly<LocationsListMobileProps>): JSX.Element {
  const { locations, isLoading } = useLocations();

  return (
    <>
      {isLoading && (
        <DataList>
          <LocationsListRow.Skeleton />
          <LocationsListRow.Skeleton />
          <LocationsListRow.Skeleton />
        </DataList>
      )}
      {!isLoading && (
        <DataList bottomDivider={false}>
          {locations.map((location) => (
            <LocationsListRow
              key={location.id}
              location={location}
              openSetMainModal={openSetMainModal}
              openDeleteModal={openDeleteModal}
            />
          ))}
        </DataList>
      )}
    </>
  );
}

export default LocationsListMobile;
