import { HomeIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { useHandleMenuClick, useHasTags } from 'App/hooks';
import { dashboardRoutes, trackingMenuPrimaryHome } from 'domains/Dashboard';

const path = dashboardRoutes.dashboard;

export function DashboardMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const [hasNewOnboardingTag] = useHasTags(NEW_ONBOARDING_B);

  const label = hasNewOnboardingTag
    ? t('menu.newDashboard')
    : t('menu.dashboard');

  const handleClick = () => {
    trackingMenuPrimaryHome();
    handleClickItem();
  };

  return (
    <MenuButton
      as={Link}
      to={path}
      startIcon={HomeIcon}
      onClick={handleClick}
      active={pathname.startsWith(path)}
      label={label}
    />
  );
}
