export const ExternalCodesEndpoints = {
  getExternalCodes: 'getExternalCodes',
  updateExternalCodes: 'updateExternalCodes',
  deleteFacebookCode: 'deleteFacebookCode',
  postCheckoutCodeRequest: 'postCheckoutCodeRequest',
} as const;

export const ExternalCodesTags = {
  getExternalCodes: 'getExternalCodes',
} as const;

export const BASE_EXTERNAL_CODES_URL = '/v1/settings/external-codes';
export const DELETE_FBE_CODE = BASE_EXTERNAL_CODES_URL + '/fbe-code';
export const REQUEST_CHECKOUT_CODE_APROVAL = `${BASE_EXTERNAL_CODES_URL}/checkout-tracking-code-request`;
