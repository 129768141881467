import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ShippingProvinceResponseDto } from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import {
  InterfaceShippingSchema,
  ShippingProvincesInterface,
  statusType,
} from './types';
import shippingService from '../shippingService';

const initialProvinces: ShippingProvincesInterface = {
  status: statusType.idle,
  provinceList: [],
};

const initialState: InterfaceShippingSchema = {
  status: statusType.idle,
  currentRequestID: '',
  provinces: initialProvinces,
};

export const getProvincesByCountry = createAsyncThunk<
  ShippingProvinceResponseDto[],
  string
>('shipping/getProvincesByCountry', async (country) => {
  const provinces = await shippingService.getProvincesByCountry(country);
  return provinces;
});

const shippingSlice = createSlice({
  name: 'shippingSlice',
  initialState,
  reducers: {
    init(state) {
      state.status = statusType.loading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });
    builder.addCase(getProvincesByCountry.pending, (state, action) => {
      state.provinces.status = statusType.loading;
      state.provinces.provinceList = initialState.provinces.provinceList;
      state.currentRequestID = action.meta.requestId;
      return state;
    });
    builder.addCase(getProvincesByCountry.fulfilled, (state, action) => {
      state.provinces.provinceList = action.payload;
      state.provinces.status = statusType.success;
      return state;
    });
    builder.addCase(getProvincesByCountry.rejected, (state) => {
      state.provinces.status = statusType.error;
      return state;
    });
  },
});

export const { reducer } = shippingSlice;
