import * as Yup from 'yup';

const VALID_LENGTHS_FOR_NUMBER_ID = [12, 13];
const ALPHANUMERIC_WITH_SPECIAL_CHARS_REGEX = /^[a-zA-Z0-9\s/-]*$/;

export default Yup.object().shape({
  zipcode: Yup.string()
    .required('invoicesInfoPage.address.errors.zipcode')
    .test(
      'length',
      'invoicesInfoPage.address.errors.zipcode',
      (value) => value?.length === 5,
    ),
  province: Yup.string().required(),
  city: Yup.string().required(),
  locality: Yup.string()
    .test(
      'localityRequired',
      'invoicesInfoPage.address.errors.locality',
      (_, obj) => !obj.parent.province || obj.parent.locality,
    )
    .required(),
  street: Yup.string().required('invoicesInfoPage.address.errors.street'),
  number: Yup.string().matches(
    ALPHANUMERIC_WITH_SPECIAL_CHARS_REGEX,
    'invoicesInfoPage.address.errors.numberInvalid',
  ),
  floor: Yup.string().nullable(),
  name: Yup.string().required('invoicesInfoPage.errors.name'),
  vatCondition: Yup.string().required('invoicesInfoPage.errors.vatCondition'),
  numberId: Yup.string()
    .typeError('invoicesInfoPage.errors.numberId')
    .required('invoicesInfoPage.errors.numberId')
    .test(
      'is-correct-length',
      'invoicesInfoPage.errors.numberIdLength',
      (value) => {
        const length = value?.toString().length;
        return !!length && VALID_LENGTHS_FOR_NUMBER_ID.includes(length);
      },
    ),
});
