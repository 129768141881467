import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc } from 'commons/hooks';
import { fetchFeatureUses as fetchFeatureUsesAction } from 'domains/Auth/authSlice';

interface UseFeatureUsesByKeyResult {
  featureUses: number;
  isLoading: boolean;
  isError: boolean;
  getFeatureUses: () => Promise<void>;
}

function useFeatureUsesByKey(key: string): UseFeatureUsesByKeyResult {
  const dispatch = useAppDispatch();
  const [featureUses, setFeatureUses] = useState(0);

  const fetchFeatureUses = useCallback(async () => {
    const featureUses = await dispatch(fetchFeatureUsesAction(key));
    return unwrapResult(featureUses);
  }, [dispatch, key]);

  const [getFeatureUses, isLoading, isError] = useAsyncFunc(async () => {
    const featureUses = await fetchFeatureUses();
    setFeatureUses(featureUses);
  });

  return {
    featureUses,
    isLoading,
    isError,
    getFeatureUses,
  };
}

export default useFeatureUsesByKey;
