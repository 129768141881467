import { useState } from 'react';

function useGetImageDimensions(src: string) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [dimensionsReady, setDimensionsReady] = useState(false);

  const img = new Image();

  img.onload = function () {
    if (dimensionsReady) {
      return;
    }

    setWidth(img.width);
    setHeight(img.height);

    setDimensionsReady(true);
  };

  img.src = src;

  return {
    dimensionsReady,
    width,
    height,
  };
}

export default useGetImageDimensions;
