export interface BackgroundGalleryElement {
  backgroundUrl: string;
  thumbnailUrl: string;
  id: string;
}
// These images are hosted on the S3 bucket `tiendanube-blog`
export const backgrounds: BackgroundGalleryElement[] = [
  {
    id: 'background-01',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-01.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-01.jpg',
  },
  {
    id: 'background-02',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-02.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-02.jpg',
  },
  {
    id: 'background-03',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-03.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-03.jpg',
  },
  {
    id: 'background-04',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-04.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-04.jpg',
  },
  {
    id: 'background-05',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-05.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-05.jpg',
  },
  {
    id: 'background-06',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-06.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-06.jpg',
  },
  {
    id: 'background-07',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-07.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-07.jpg',
  },
  {
    id: 'background-08',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-08.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-08.jpg',
  },
  {
    id: 'background-09',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-09.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-09.jpg',
  },
  {
    id: 'background-10',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-10.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-10.jpg',
  },
  {
    id: 'background-11',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-11.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-11.jpg',
  },
  {
    id: 'background-12',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-12.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-12.jpg',
  },
  {
    id: 'background-13',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-13.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-13.jpg',
  },
  {
    id: 'background-14',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-14.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-14.jpg',
  },
  {
    id: 'background-15',
    backgroundUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/background-15.jpg',
    thumbnailUrl:
      'https://d1ih8jugeo2m5m.cloudfront.net/background-gallery/v2/thumbnail-15.jpg',
  },
];
