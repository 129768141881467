import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsPlanLojinha, useStatusFeatureByKey } from 'domains/Auth/hooks';

function useHasAccessToStats() {
  const isPlanLojinha = useIsPlanLojinha();
  const { validateRoles } = useRoles();
  const { isAvailable } = useStatusFeatureByKey(FEATURE_NEW_ADMIN_STATS);

  const hasStatsRole = validateRoles(['statistics']);

  return hasStatsRole && isAvailable && !isPlanLojinha;
}

export default useHasAccessToStats;
