import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Stack, InterfaceNameValue, Input } from '@tiendanube/components';
import { InputPhone } from 'commons/components';

export interface PersonReceivingOrderValues {
  firstName: string;
  lastName: string;
  phone: string;
}

interface PersonReceivingOrderProps extends PersonReceivingOrderValues {
  onChange: (event: InterfaceNameValue) => void;
}

function PersonReceivingOrder({
  onChange,
  firstName,
  lastName,
  phone,
}: PersonReceivingOrderProps) {
  const { t } = useTranslation([Domain.ORDERS]);

  return (
    <Card title={`${t('editDeliveryAdressModal.consumerCard.title')}`}>
      <Stack column align="stretch">
        <Stack.Item fill>
          <Input
            name="firstName"
            label={t('editDeliveryAdressModal.consumerCard.firstName')}
            onChange={onChange}
            value={firstName}
          />
        </Stack.Item>
        <Stack.Item fill>
          <Input
            name="lastName"
            label={t('editDeliveryAdressModal.consumerCard.lastName')}
            onChange={onChange}
            value={lastName}
          />
        </Stack.Item>
        <Stack.Item fill>
          <InputPhone
            name="phone"
            label={t('editDeliveryAdressModal.consumerCard.phone')}
            onChange={onChange}
            value={phone}
          />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default PersonReceivingOrder;
