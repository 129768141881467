import { Box } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { PreferencesCheckoutResponseDto } from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { ShowByTag } from 'App/components';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import { toStatusType } from 'App/rtk';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
  SubmitButton,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  DesignPreferencesCard,
  CustomerInformationCard,
  BuyerNoteCard,
  TrackingPageMessageCard,
  RestrictPurchasesCard,
  AcceleratedCheckoutCard,
  ClearSaleCard,
} from './components';
import { useUpdatePreferencesCheckoutMutation } from '../SettingsCheckoutApi';

type SettingsCheckoutFormProps = {
  data: PreferencesCheckoutResponseDto;
};

export function SettingsCheckoutForm({
  data: initialValues,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm',
  );
  const [updatePreferencesCheckout, { status, isLoading }] =
    useUpdatePreferencesCheckoutMutation();
  const country = useGetCountry();
  const { t: tCommon } = useTranslation('common');

  const {
    values,
    handleOnChange,
    handleChange,
    handleOnBlur,
    handleOnSubmit,
    isDirty,
  } = useForm({
    initialValues,
    onSubmit: updatePreferencesCheckout,
  });

  useToastStatus({
    status: toStatusType(status),
    error: t('toastError'),
    success: t('toastSuccess'),
  });

  const submitButton = (
    <SubmitButton
      disabled={!isDirty}
      isLoading={isLoading}
      onClick={handleOnSubmit}
    >
      {t('buttonSave')}
    </SubmitButton>
  );

  const headerContent = (
    <HeaderContent
      title={t('title')}
      subtitle={t('subTitle')}
      actions={submitButton}
    />
  );

  const acceleratedCheckoutCard = (
    <AcceleratedCheckoutCard
      values={values}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );

  return (
    <IonPageStratus
      headerTop={
        <HeaderTop
          actions={
            <Responsive
              desktop={submitButton}
              mobile={
                <Box display="flex" gap="none" alignItems="center" padding="2">
                  <SubmitButton
                    appearance="transparent"
                    isLoading={isLoading}
                    onClick={handleOnSubmit}
                  >
                    {!isLoading && <CheckCircleIcon />}
                    {t('buttonSave')}
                  </SubmitButton>
                </Box>
              }
            />
          }
        />
      }
      width="small"
      headerContent={headerContent}
    >
      <Box display="flex" flexDirection="column" gap="4">
        <DesignPreferencesCard
          values={values}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <CustomerInformationCard
          values={values}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <BuyerNoteCard
          values={values}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <TrackingPageMessageCard
          values={values}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <ShowByCountry allowedCountries={[Country.BR]}>
          <ClearSaleCard
            onChange={handleChange}
            clearSaleCode={values.clearSaleCode}
          />
        </ShowByCountry>
        <RestrictPurchasesCard
          values={values}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <ShowByCountry allowedCountries={[Country.BR, Country.AR, Country.MX]}>
          {country === Country.BR ? (
            acceleratedCheckoutCard
          ) : (
            <ShowByTag tag="wallet-auto-adoption">
              {acceleratedCheckoutCard}
            </ShowByTag>
          )}
        </ShowByCountry>
        <Box display="flex" justifyContent="flex-end">
          {submitButton}
        </Box>
      </Box>
      <HelpLink
        title={t('helpLink.title')}
        linkURL={t('helpLink.href')}
        previousValue=""
        currentViewTracking="helpLink.currentViewTracking"
        icon="both"
      />
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
    </IonPageStratus>
  );
}
