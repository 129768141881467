import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MenuSchema } from './menuSelectors';
import { getMenu } from '../menuServices';

export const getMenuAction = createAsyncThunk('menu', getMenu);

const initialState: MenuSchema = {
  data: null,
  status: 'idle',
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuAction.fulfilled, (state, action) => {
      state.data = action.payload.response;
      state.status = 'success';
    });
    builder.addCase(getMenuAction.pending, (state) => {
      state.status = 'loading';
    });
  },
});

export const { reducer } = menuSlice;
