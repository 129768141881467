import { Box } from '@nimbus-ds/components';
import { Repeater } from 'commons/components';
import { HistoricCharge } from './components';

export function LastPaymentsSkeleton() {
  return (
    <Box display="flex" flexDirection="column">
      <Repeater
        times={3}
        item={() => (
          <Box>
            <HistoricCharge.Skeleton />
            <br />
          </Box>
        )}
      />
    </Box>
  );
}
