import {
  CitiesResponseDto,
  CreateTaxBillingDataRequestDto,
  GetCitiesResponseDto,
  InvoicesResponseDto,
  ProvinceDto,
  ResultPaginationResponseDto,
  ShippingAddressResponseDto,
  TaxInformationResponseDto,
  DownloadInvoiceResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCountries = async () => {
  const { data } = await axios.get<Record<string, string>>(
    `v1/shipping/countries`,
  );
  return data;
};

const addressesBrazil = async (zipcode: string) => {
  const { data } = await axios.get<ShippingAddressResponseDto>(
    `v1/shipping/addresses/br/${zipcode}`,
  );
  return data;
};

const addressesMexico = async (zipcode: string) => {
  const { data } = await axios.get<ShippingAddressResponseDto>(
    `v1/shipping/addresses/mx/${zipcode}`,
  );
  return data;
};

const getProvinces = async (countryCode: string) => {
  const { data } = await axios.get<ProvinceDto[]>(
    `v1/shipping/provinces/${countryCode}`,
  );
  return data;
};

const getCities = async (countryCode: string, provinceCode: string) => {
  const { data } = await axios.get<CitiesResponseDto[]>(`v1/shipping/cities`, {
    params: { countryCode, provinceCode },
  });
  return data;
};

const getBillingCities = async (countryCode: string) => {
  const { data } = await axios.get<
    ResultPaginationResponseDto<GetCitiesResponseDto[]>
  >(`v1/billing/countries/${countryCode}/cities`);
  return data;
};

const createFiscalData = async (
  taxBillingData: CreateTaxBillingDataRequestDto,
) => {
  const { data } = await axios.post(
    `/v1/billing/tax-billing-data`,
    taxBillingData,
  );
  return data;
};

const getFiscalData = async () => {
  const { data } = await axios.get(`/v1/billing/tax-billing-data`);
  return data;
};

const getBillingEngineFiscalData = async () => {
  const { data } = await axios.get(`/v1/billing/billing-engine-tax-data`);
  return data;
};

const updateFiscalData = async (
  taxBillingData: CreateTaxBillingDataRequestDto,
  id = 1,
) => {
  const { data } = await axios.put(
    `/v1/billing/tax-billing-data/${id}`,
    taxBillingData,
  );
  return data;
};

const validateCuit = async (cuit: string) => {
  const { data } = await axios.get<TaxInformationResponseDto>(
    `/v1/billing/tax-agency-information/${cuit}`,
  );
  return data;
};

const getInvoiceHistory = async (params: {
  page?: number;
  perPage?: number;
}) => {
  const { data } = await axios.get<
    ResultPaginationResponseDto<InvoicesResponseDto[]>
  >(`/v1/billing/invoices`, { params });
  return data;
};

const getDownloadLink = async (id: string) => {
  const { data } = await axios.get<DownloadInvoiceResponseDto>(
    `/v1/billing/invoices/${id}`,
  );
  return data;
};

export default {
  createFiscalData,
  getDownloadLink,
  getFiscalData,
  getBillingEngineFiscalData,
  addressesBrazil,
  addressesMexico,
  getCountries,
  getProvinces,
  getCities,
  getBillingCities,
  getInvoiceHistory,
  validateCuit,
  updateFiscalData,
};
