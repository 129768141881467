import { TrackingAnalyticsResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import { QUALITY_LEAD_NOTIFY_URL, VISITED_AFTER_PAYING_URL } from './urls';

export async function visitedAfterPaying() {
  const { data } = await axios.post<TrackingAnalyticsResponseDto>(
    VISITED_AFTER_PAYING_URL,
  );
  return data;
}

export async function qualityLeadNotify() {
  const { data } = await axios.post<TrackingAnalyticsResponseDto>(
    QUALITY_LEAD_NOTIFY_URL,
  );
  return data;
}
