import { Text, Link } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface EmptySelectedSectionsProps {
  onOpen: () => void;
}

function EmptySelectedSections({
  onOpen,
}: EmptySelectedSectionsProps): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <Stack column align="stretch">
      <Text>{t('products.detail.highlight.description')}</Text>
      <Link
        icon={PlusCircleIcon}
        iconPosition="start"
        appearance="primary"
        onClick={onOpen}
      >
        {t('products.detail.highlight.select')}
      </Link>
    </Stack>
  );
}

export default EmptySelectedSections;
