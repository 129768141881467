import { Input, IconButton, Tooltip } from '@nimbus-ds/components';
import { RedoIcon } from '@nimbus-ds/icons';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { callWhenPress } from 'commons/transformers';

interface RegenerateInstructionsProps {
  feature: AiFeature;
  regenerationInstructions: string;
  onChangeRegenerationInstructions: (value: string) => void;
  onRegenerateContent: () => void;
}

export function RegenerateInstructions({
  feature,
  regenerationInstructions,
  onChangeRegenerationInstructions,
  onRegenerateContent,
}: Readonly<RegenerateInstructionsProps>) {
  const t = useTranslationCommon();

  const handleChangeRegenerationInstructions = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChangeRegenerationInstructions(e.target.value);
  };

  return (
    <Stack align="center" spacing="tight">
      <Stack.Item fill>
        <Input
          id={`regeneration-feedback-${feature}`}
          value={regenerationInstructions}
          placeholder={t('aiGeneration.regenerateInstructions.placeholder')}
          onChange={handleChangeRegenerationInstructions}
          onKeyDown={callWhenPress('Enter', onRegenerateContent)}
        />
      </Stack.Item>
      <Tooltip
        content={t('aiGeneration.regenerateInstructions.tooltip')}
        position="left"
      >
        <IconButton
          source={<RedoIcon />}
          onClick={onRegenerateContent}
          size="2rem"
        />
      </Tooltip>
    </Stack>
  );
}
