import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import {
  CustomerFiltersParamsType,
  CustomersFiltersType,
  defaultFilters,
  SortType,
} from 'domains/Customers/Customers/customersServices';
import {
  refreshCustomersList as getCustomersAction,
  getMoreCustomersList as getMoreCustomersAction,
  advancedSearchCustomers as advancedSearchCustomersAction,
} from 'domains/Customers/Customers/customersSlice';
import {
  getCustomersIds,
  getCustomersPagination,
  getCustomersRefreshStatus,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseCustomerResults {
  isRefreshSuccess: boolean;
  isRefreshError: boolean;
  isRefreshing: boolean;
  customersIds: string[];
  pagination: PaginationResponseDto;
  hasFilters: boolean;
  refreshCustomers: () => Promise<void>;
  getMoreCustomers: () => Promise<void>;
  goToPage: (p: number) => void;
  sortTotalConsumed: (sort: SortType) => void;
  getCustomers: (customFilters?: any) => void;
  advancedSearchCustomers: (customFilters?: any) => void;
  changeFilters: (customFilters: CustomersFiltersType) => void;
  filters: CustomersFiltersType;
}

function useCustomerResults(): UseCustomerResults {
  const dispatch = useAppDispatch();

  const { isRefreshing, isRefreshError, isRefreshSuccess } = useSelector(
    getCustomersRefreshStatus,
  );
  const customersIds = useSelector(getCustomersIds);
  const pagination = useSelector(getCustomersPagination);

  const { filters, changeFilters, hasFilters } = useListFilters(
    'customers',
    defaultFilters,
  );

  const getCustomers = useCallback(
    (customFilters?: CustomerFiltersParamsType) => {
      const fetchFilters = customFilters
        ? { ...customFilters, page: 1 }
        : filters;

      changeFilters(fetchFilters);
      dispatch(getCustomersAction(fetchFilters));
    },
    [changeFilters, dispatch, filters],
  );

  const advancedSearchCustomers = useCallback(
    (customFilters?: CustomersFiltersType) => {
      const fetchFilters = customFilters || filters;

      changeFilters(fetchFilters);
      dispatch(advancedSearchCustomersAction(fetchFilters));
    },
    [filters, changeFilters, dispatch],
  );

  const refreshCustomers = useCallback(async () => {
    await dispatch(getCustomersAction({ ...filters, page: 1 }));
  }, [dispatch, filters]);

  const getMoreCustomers = useCallback(async () => {
    await dispatch(getMoreCustomersAction());
  }, [dispatch]);

  const goToPage = useCallback(
    (page: number) => {
      if (filters.page === page) return;
      const newFilters = { ...filters, page };
      changeFilters(newFilters);
      dispatch(getCustomersAction({ ...newFilters, page }));
    },
    [changeFilters, dispatch, filters],
  );

  const sortTotalConsumed = useCallback(
    (sort) => {
      const newFilters = { ...filters, sort };
      changeFilters(newFilters);
      dispatch(getCustomersAction({ ...newFilters }));
    },
    [changeFilters, dispatch, filters],
  );

  return {
    isRefreshSuccess,
    isRefreshError,
    isRefreshing,
    customersIds,
    pagination,
    hasFilters,
    filters,
    refreshCustomers,
    getMoreCustomers,
    goToPage,
    getCustomers,
    advancedSearchCustomers,
    changeFilters,
    sortTotalConsumed,
  };
}

export default useCustomerResults;
