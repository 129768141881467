import { useState } from 'react';

const PER_PAGE_DEFAULT = 10;
const CURRENT_PAGE_DEFAULT = 1;

function useLocalPagination<TData>(list: TData[], perPage = PER_PAGE_DEFAULT) {
  const [currentPage, setCurrentPage] = useState(CURRENT_PAGE_DEFAULT);

  const totalResults = list.length;
  const lastPage = currentPage * perPage;
  const nextPage = currentPage === lastPage ? null : currentPage + 1;
  const totalPages = Math.ceil(totalResults / perPage);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const currentData = list.slice(startIndex, endIndex);
  const hasPagination = totalResults > perPage;

  return {
    currentData,
    currentPage,
    nextPage,
    perPage,
    totalPages,
    totalResults,
    setCurrentPage,
    hasPagination,
  };
}

export default useLocalPagination;
