import {
  Box,
  Button,
  Card,
  Link,
  Tag,
  Text,
  Title,
} from '@nimbus-ds/components';
import { CodeIcon, CopyIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import {
  ThemesConfigCurrentResponseDto,
  ThemesConfigFtpResponseDto,
} from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { Stack, SubmitButton, useResponsive } from 'commons/components';
import { HELP_LINKS_THEMES } from 'commons/constants';
import {
  useClipboard,
  useModal,
  useToastStatus,
  useTranslationLanguage,
} from 'commons/hooks';
import { useLoggedAdminLink } from 'domains/Auth/hooks';
import { useLazyGetFtpPasswordQuery } from 'domains/Online/Themes/themesApi';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { DisableFtpModal } from './DisableFtpModal';
import { ResetPasswordLink } from './ResetPasswordLink';
import './FtpCard.scss';

interface FtpCardProps {
  currentTheme: ThemesConfigCurrentResponseDto;
  ftp: ThemesConfigFtpResponseDto;
}

export function FtpCard({ currentTheme, ftp }: Readonly<FtpCardProps>) {
  const t = useTranslationOnline('themesPage.theme');
  const language = useTranslationLanguage();
  const copyToClipboard = useClipboard();
  const { isMobile } = useResponsive();
  const { goToLoggedAdminLink, isLoading } = useLoggedAdminLink();
  const [fetchFtpPassword, { status }] = useLazyGetFtpPasswordQuery();
  const [showDisableFtpModal, openDisableFtpModal, closeDisableFtpModal] =
    useModal();

  useToastStatus({
    status: toStatusType(status),
    progress: t('ftpPasswordLoading'),
    error: t('ftpPasswordError'),
  });

  const handleClickEdit = async () => {
    await goToLoggedAdminLink('/themes/settings/active');
  };

  const handleClickFtpHost = () =>
    copyToClipboard(ftp?.host || '', t('ftpHostCopied'));

  const handleClickFtpUser = () =>
    copyToClipboard(ftp?.username || '', t('ftpUserCopied'));

  const handleClickFtpPassword = async () => {
    const { data } = await fetchFtpPassword();
    copyToClipboard(data?.password || '', t('ftpPasswordCopied'));
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Stack column align="stretch" data-style="stratus--themes-ftp-values">
            <Stack spacing="tight">
              <Title as="h4">{currentTheme.name}</Title>
              <Tag appearance="success">{t('activeDesign')}</Tag>
            </Stack>
            <Text color="neutral-textLow">
              {isMobile ? (
                <Trans
                  t={t}
                  i18nKey="ftpInfo"
                  components={{
                    a: (
                      <Link
                        as="a"
                        href={HELP_LINKS_THEMES[language]}
                        target="_blank"
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              ) : (
                t('ftpInfoWithoutLink')
              )}
            </Text>
            <Box onClick={handleClickFtpHost}>
              <FormField.Input
                label={t('ftpHost')}
                append={<CopyIcon />}
                appendPosition="end"
                value={ftp.host}
                disabled
              />
            </Box>
            <Box onClick={handleClickFtpUser}>
              <FormField.Input
                label={t('ftpUser')}
                append={<CopyIcon />}
                appendPosition="end"
                value={ftp.username}
                disabled
              />
            </Box>
            <Box onClick={handleClickFtpPassword}>
              <FormField.Input
                label={t('ftpPassword')}
                append={<CopyIcon />}
                appendPosition="end"
                value="********"
                disabled
              />
            </Box>
            <ResetPasswordLink />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Button onClick={openDisableFtpModal}>
            <CodeIcon /> {t('closeFtp')}
          </Button>
          <SubmitButton
            onClick={handleClickEdit}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('edit')}
          </SubmitButton>
        </Card.Footer>
      </Card>
      <DisableFtpModal
        showModal={showDisableFtpModal}
        onClose={closeDisableFtpModal}
      />
    </>
  );
}
