import { useEffect } from 'react';
import { Alert } from '@nimbus-ds/components';
import { MetafieldsPersistsStatusType } from 'domains/Catalog/Products/productsSlice';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface AlertPersistsMetafieldsProps {
  status: MetafieldsPersistsStatusType;
  onClose: () => void;
}

function AlertPersistsMetafields({
  status,
  onClose,
}: AlertPersistsMetafieldsProps): JSX.Element | null {
  const t = useTranslationCatalog();
  const prefix = 'products.detail.alertPersistsMetafields';
  const body = t(`${prefix}.${status}`);
  const show = status !== 'success' && status !== 'idle';

  useEffect(
    () => () => {
      onClose();
    },
    [onClose],
  );

  if (!show) return null;
  return (
    <Alert show appearance="warning" onRemove={onClose}>
      {body}
    </Alert>
  );
}

export default AlertPersistsMetafields;
