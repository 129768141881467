import { useEffect, useState } from 'react';
import {
  SizeAttributeType,
  StandardSizeResponseDtoV2,
} from '@tiendanube/common';
import { InteractiveList, Stack, Text } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import StandardSizeOptions from './StandardSizeOptions';
import { filterSizesByType } from './utils';
import {
  filterSuggestedValues,
  getSuggestedListOptionsByAttribute,
} from '../../utils';
import { StandardAttributeProps } from '../types';

const SIZE_TYPES: SizeAttributeType[] = ['adult', 'kid', 'footwear'];

export interface StandardSizeAttributeProps extends StandardAttributeProps {
  sizes: StandardSizeResponseDtoV2;
}

function StandardSizeAttribute({
  sizes,
  selectedValues,
  languageToUse,
  children,
  onCheckOption,
}: StandardSizeAttributeProps): JSX.Element {
  const t = useTranslationCatalog();

  const [selectedAdultSizes, setSelectedAdultSizes] = useState<string[]>([]);
  const [selectedKidSizes, setSelectedKidSizes] = useState<string[]>([]);
  const [selectedFootwearSizes, setSelectedFootwearSizes] = useState<string[]>(
    [],
  );

  const selectedStandard = [
    ...selectedAdultSizes,
    ...selectedKidSizes,
    ...selectedFootwearSizes,
  ];

  const suggestedOptions = getSuggestedListOptionsByAttribute(
    sizes.suggested,
    selectedValues,
    languageToUse,
  );

  useEffect(() => {
    setSelectedAdultSizes(
      filterSizesByType(sizes.standard.adult, selectedValues, languageToUse),
    );
    setSelectedKidSizes(
      filterSizesByType(sizes.standard.kid, selectedValues, languageToUse),
    );
    setSelectedFootwearSizes(
      filterSizesByType(sizes.standard.footwear, selectedValues, languageToUse),
    );
  }, [sizes, selectedValues, languageToUse]);

  const handleCheckStandardValue = (
    sizeType: SizeAttributeType,
    selected: string[],
  ) => {
    if (sizeType === 'adult') {
      onCheckOption([
        ...filterSuggestedValues(
          selectedValues,
          languageToUse,
          sizes.suggested,
        ),
        ...selected,
        ...selectedKidSizes,
        ...selectedFootwearSizes,
      ]);
    }

    if (sizeType === 'kid') {
      onCheckOption([
        ...filterSuggestedValues(
          selectedValues,
          languageToUse,
          sizes.suggested,
        ),
        ...selectedAdultSizes,
        ...selected,
        ...selectedFootwearSizes,
      ]);
    }

    if (sizeType === 'footwear') {
      onCheckOption([
        ...filterSuggestedValues(
          selectedValues,
          languageToUse,
          sizes.suggested,
        ),
        ...selectedAdultSizes,
        ...selectedKidSizes,
        ...selected,
      ]);
    }
  };

  const handleCheckAll = (sizeType: SizeAttributeType) => {
    handleCheckStandardValue(sizeType, Object.keys(sizes.standard[sizeType]));
  };

  const handleUncheckAll = (sizeType: SizeAttributeType) => {
    handleCheckStandardValue(sizeType, []);
  };

  const handleCheckSuggestedValue = (selectedIds: string[]) =>
    onCheckOption([...selectedStandard, ...selectedIds]);

  return (
    <Stack column align="stretch" spacing="loose">
      <Stack column align="stretch">
        <Stack column align="stretch" spacing="tight">
          <Text bold>{t('products.variants.selectedSizes')}</Text>
          <Text size="caption">
            {t('products.variants.selectedSizesCaption')}
          </Text>
        </Stack>
        {children}
      </Stack>
      <Stack column align="stretch">
        {suggestedOptions.length > 0 && (
          <>
            <Text bold>{t('products.variants.suggestedSizes')}</Text>
            <InteractiveList
              mode="multi"
              onChange={handleCheckSuggestedValue}
              options={suggestedOptions}
            />
          </>
        )}
        <Text bold>{t('products.variants.basicSizes')}</Text>
        <Stack column align="stretch" spacing="loose">
          {SIZE_TYPES.map((sizeType) => (
            <StandardSizeOptions
              key={sizeType}
              sizeType={sizeType}
              standardSizes={sizes.standard}
              languageToUse={languageToUse}
              selectedValues={selectedValues}
              onCheckOption={handleCheckStandardValue}
              onCheckAll={handleCheckAll}
              onUncheckAll={handleUncheckAll}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default StandardSizeAttribute;
