import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCustomerMetafields,
  getCustomerMetafieldsDetails,
  getCustomerMetafieldsData,
  getCustomerMetafieldsDetailsData,
  getCustomerMetafieldsDetailsStatus,
  getCustomerMetafieldsStatus,
} from 'domains/Customers/Customers/CustomerMetafields/customerMetafieldsSlice';

export type FormattedCustomerMetafield = {
  name: string;
  value: string;
  app?: string;
  type: string;
};

export default function useCustomerMetafields(id: string) {
  const dispatch = useAppDispatch();

  const status = useSelector(getCustomerMetafieldsStatus);
  const statusDetails = useSelector(getCustomerMetafieldsDetailsStatus);
  const customerMetafields = useSelector(getCustomerMetafieldsData);
  const customerMetafieldsDetails = useSelector(
    getCustomerMetafieldsDetailsData,
  );

  const fetchCustomerMetafields = useCallback(async () => {
    await dispatch(getCustomerMetafields(id));
  }, [dispatch, id]);

  const fetchCustomerMetafieldsDetails = useCallback(async () => {
    await dispatch(getCustomerMetafieldsDetails(id));
  }, [dispatch, id]);

  return {
    fetchCustomerMetafields,
    fetchCustomerMetafieldsDetails,
    customerMetafields,
    customerMetafieldsDetails,
    status,
    statusDetails,
  };
}
