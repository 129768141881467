import React from 'react';
import { BoxProps, Button } from '@nimbus-ds/components';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { trackingAiGenerateContentSelect } from 'domains/Ai/tracking';
import GeneratedWithAiText from './components/GeneratedWithAiText';
import PaginationControls from './components/PaginationControls';
import { AiGenerationResultsCard } from '../AiGenerationResultsCard';
import './AiGenerationResults.scss';

interface AiGenerationResultsProps extends BoxProps {
  feature: AiFeature;
  current: number;
  total: number;
  onPrevious: () => void;
  onNext: () => void;
  onUseContent: () => void;
  children: React.ReactNode;
}

export function AiGenerationResults({
  feature,
  current,
  total,
  onPrevious,
  onNext,
  onUseContent,
  children,
}: Readonly<AiGenerationResultsProps>) {
  const t = useTranslationCommon();

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection && selection.toString() !== '') {
      trackingAiGenerateContentSelect({ feature });
    }
  };

  return (
    <AiGenerationResultsCard>
      <GeneratedWithAiText />

      <div className="stratus--ai-generation-content" onMouseUp={handleMouseUp}>
        {children}
      </div>

      <Stack justify="flex-end">
        {total > 1 && (
          <Stack.Item fill>
            <PaginationControls
              current={current}
              total={total}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          </Stack.Item>
        )}

        <Stack.Item>
          <Button onClick={onUseContent} appearance="primary">
            {t('aiGeneration.resultsModal.cta.useGeneratedContent')}
          </Button>
        </Stack.Item>
      </Stack>
    </AiGenerationResultsCard>
  );
}
