import { DataList } from '@tiendanube/components';
import ScrollTop from 'commons/components/ScrollTop';
import { CustomerListItemSkeleton } from '../CustomerListItem';

function CustomerResultsMobileSkeleton(): JSX.Element {
  return (
    <DataList skeleton>
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <CustomerListItemSkeleton />
      <ScrollTop />
    </DataList>
  );
}

export default CustomerResultsMobileSkeleton;
