import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

export const getAcquisitionSummary = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.acquisitionSummary,
);

export const getAcquisitionSummaryData = createSimpleSelector(
  [getAcquisitionSummary],
  (globalInfoEntity) => globalInfoEntity.data,
);

export const getAcquisitionSummaryStatus = createSimpleSelector(
  [getAcquisitionSummary],
  (globalInfoEntity) => convertStatusTypeToObject(globalInfoEntity.status),
);
