import { useCallback } from 'react';
import { Card, Text, Box, Button, Spinner } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { InputFile } from 'commons/components';
import {
  trackingBillingPayWireTransferClick,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import InstructionsSkeleton from './Skeleton';
import { WireData } from '../';
import { useUploadPaymentProof } from '../../hooks';

import 'domains/Billing/Checkout/Checkout.scss';

function Instructions() {
  const {
    onChangeFile,
    onRemoveFile,
    onSubmit,
    file,
    isLoading,
    setupStatus: {
      isError: setupError,
      isLoading: setupLoading,
      isSuccess: setupSuccess,
    },
    retrySetup,
    fileTooBigError,
  } = useUploadPaymentProof();
  const t = useTranslationBilling('checkout.wireTransferPage.instructions');
  const logEvent = useCheckoutTracking();

  const handleOnSubmit = useCallback(() => {
    trackingBillingPayWireTransferClick(logEvent);
    onSubmit();
  }, [onSubmit, logEvent]);

  if (setupError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: retrySetup,
        }}
      />
    );

  if (setupLoading) return <InstructionsSkeleton />;

  if (!setupSuccess) return null;

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Card padding="none">
        <div className="stratus--checkout-card_content">
          <Box display="flex" flexDirection="column" gap="4" marginBottom="4">
            <Text fontWeight="bold">{t('firstInstruction')}</Text>
            <WireData />
          </Box>
        </div>
        <hr />
        <div className="stratus--checkout-card_content">
          <Box display="flex" flexDirection="column" gap="4" marginBottom="4">
            <Text fontWeight="bold">{t('secondInstruction')}</Text>
            <Text>{t('additionalInstructions')}</Text>
            <InputFile
              fullWidth
              fileName={file?.name}
              isError={fileTooBigError}
              onChangeFile={onChangeFile}
              onRemoveFile={onRemoveFile}
              accept="application/pdf,image/jpeg,image/png"
              helperText={t('chooseFile')}
              errorText={fileTooBigError ? t('fileTooBigError') : ''}
            />
            <Box display="flex" flexDirection="row" gap="1">
              <Text>{t('recommendedFormatsText')}: </Text>
              <Text fontWeight="bold">{t('recommendedFormats')}</Text>
            </Box>
          </Box>
        </div>
      </Card>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Button
          onClick={handleOnSubmit}
          appearance="primary"
          disabled={!file || isLoading || fileTooBigError}
        >
          {t('submit')} {isLoading && <Spinner size="small" />}
        </Button>
      </Box>
    </Box>
  );
}

export default Instructions;
