export enum NotificationPresentationType {
  Alert = 'alert',
  Modal = 'modal',
}

export type NotificationStyleType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'neutral';

export type NotificationMetadataResponse = {
  titleMetadata?: Record<string, any>;
  messageMetadata?: Record<string, any>;
};

export enum NotificationTypes {
  AntiFraudStoreDisabled = 'anti-fraud.store-disabled',
  KycStoreWarning = 'kyc.store-warning',
  KycStoreInfo = 'kyc.store-info',
  KycStoreBlocked = 'kyc.store-blocked',
  RefundError = 'refund.error',
  NoType = 'without-type',
  WithdrawalRefused = 'withdrawal-refused',
  WithdrawalCanceled = 'withdrawal-canceled',
  WithdrawalCreated = 'withdrawal-created',
  WithdrawalProcessing = 'withdrawal-processing',
  WithdrawBlockedByRisk = 'withdraw-blocked-by-risk',
  WithdrawBlockedByLending = 'withdraw-blocked-by-lending',
  WithdrawUnblockedByLending = 'withdraw-unblocked-by-lending',
  LendingInstallmentWillOverdue = 'lending-installment-will-overdue',
  LendingInstallmentDueToday = 'lending-installment-due-today',
  LendingInstallmentOverdue = 'lending-installment-overdue',
  LendingNotifyNuvemPagoExclusive = 'lending-notify-nuvem-pago-exclusive',
  LivenessPending = 'liveness-pending',
  LivenessInAnalysis = 'liveness-in-analysis',
  LivenessApproved = 'liveness-approved',
  LivenessPartiallyDenied = 'liveness-partially-denied',
  LivenessDeniedNewCohort = 'liveness-denied-new-cohort',
  LivenessDeniedMerchantBase = 'liveness-denied-merchant-base',
}

export type GetNotificationsResponse = {
  id: string;
  title: string;
  message: string;
  titleCode?: string;
  messageCode?: string;
  style: NotificationStyleType;
  linkName?: string;
  linkUrl?: string;
  linkTag?: string;
  type?: NotificationTypes;
  metadata?: NotificationMetadataResponse;
  deletable?: boolean;
  presentationType?: NotificationPresentationType;
  hasInternalUrl?: boolean;
  deleteOnClose?: boolean;
  deleteOnRefresh?: boolean;
};

export type AppearanceTypes =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral';

export type NotificationComponentProps = Pick<
  GetNotificationsResponse,
  'id' | 'title' | 'message' | 'style'
> & {
  show: boolean;
  onClose: (open?: boolean) => void;
  action?: React.ReactNode;
  'data-testid'?: string;
};
