import { DataTable, Stack, Text } from '@tiendanube/components';
import { ImageItemStratusSkeleton } from 'commons/components';
import { cellSize } from './utils';

interface ResultProductsDesktopSkeletonProps {
  listLayout: 'orders' | 'draftOrders';
  quantityUniqueProducts: number;
}

export function ResultProductsDesktopSkeleton({
  listLayout,
  quantityUniqueProducts,
}: ResultProductsDesktopSkeletonProps): JSX.Element | null {
  const getCellSize = cellSize(listLayout);

  return (
    <>
      {Array.from({ length: quantityUniqueProducts }).map((_, i) => (
        <DataTable.Row
          id="skeleton-row"
          align="center"
          key={`product-skeleton-${i}`}
        >
          <DataTable.Cell grow={1} basis={getCellSize('product')}>
            <ImageItemStratusSkeleton imageWidth="48px">
              <Stack column align="flex-start" spacing="tight">
                <Text.Skeleton size="caption" />
                <Text.Skeleton size="caption" />
              </Stack>
            </ImageItemStratusSkeleton>
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('quantity')}>
            <Text.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('unitPrice')}>
            <Text.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('total')}>
            <Text.Skeleton />
          </DataTable.Cell>
          {listLayout === 'orders' && <DataTable.Cell grow={0} basis={80} />}
        </DataTable.Row>
      ))}
    </>
  );
}
