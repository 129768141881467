import { useMemo } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons, CurrencyPrice } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { ProductInterface } from '../types';

interface ProductSearcherConfirmProps<Minimalistic extends boolean> {
  selectedProducts: ProductInterface<Minimalistic>[];
  minimalistic?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

function ProductSearcherConfirm<Minimalistic extends boolean>({
  selectedProducts,
  minimalistic,
  onConfirm,
  onCancel,
}: Readonly<ProductSearcherConfirmProps<Minimalistic>>) {
  const t = useTranslationOrders();
  const currency = useGetCurrency();

  const totalSelected = selectedProducts.length;
  const totalPrice = useMemo(
    () =>
      minimalistic
        ? 0
        : (selectedProducts as ProductInterface<false>[]).reduce(
            (acc, curr) => acc + curr.price,
            0,
          ),
    [selectedProducts, minimalistic],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Text>
          {t('productSearcher.searcherConfirm.selectedProductsCount', {
            count: totalSelected,
          })}
        </Text>
        {!minimalistic && (
          <CurrencyPrice
            price={totalPrice}
            currency={currency.code}
            bold
            appearance="light"
            size="highlight"
          />
        )}
      </Box>

      <Box display="flex" alignItems="center">
        <CancelAndConfirmButtons
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmText={
            minimalistic
              ? t('productSearcher.searcherConfirm.saveConfirm')
              : t('productSearcher.searcherConfirm.addConfirm')
          }
        />
      </Box>
    </Box>
  );
}

export default ProductSearcherConfirm;
