import { useEffect, useState } from 'react';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import BrazilianFiscalDataForm, {
  FiscalDataformBrazil,
} from './components/BrazilianFiscalDataForm';
import useBrazilianFiscalData from './useBrazilianFiscalData';
import validationSchema from './validationSchema';
import { useGetAddressesBrazil } from '../../../Addresses';

const ZIPCODE_LENGTH = 8;

const defaultValues = {
  zipcode: '',
  province: '',
  city: '',
  neighborhood: '',
  street: '',
  number: '',
  complement: '',
  name: '',
  numberId: '',
  stateRegistration: '',
  stateRegistrationOption: '',
};

function BrazilianFiscalData() {
  const t = useTranslationBilling();
  const { createFiscalData, updateFiscalData, status, cleanData } =
    useBrazilianFiscalData();
  const { goBack } = useNavegate();
  const [initialValues, setInitialValues] =
    useState<Partial<FiscalDataformBrazil>>(defaultValues);

  const { invoiceInfo, fetchInvoiceInfo, isLoading, hasInvoiceInfo } =
    useGetInvoiceInfo();
  const { errors, values, setFieldValue, handleOnSubmit, isDirty } =
    useForm<FiscalDataformBrazil>({
      initialValues: initialValues as FiscalDataformBrazil,
      validationSchema,
      onSubmit: (data) => {
        if (hasInvoiceInfo) {
          updateFiscalData(data, invoiceInfo.id);
        } else {
          createFiscalData(data);
        }
      },
    });

  const { fetchAddressesBrazil } = useGetAddressesBrazil();

  useEffect(() => {
    if (invoiceInfo) {
      const zipcode = invoiceInfo.zipcode
        ?.toString()
        .padStart(ZIPCODE_LENGTH, '0');
      setInitialValues({
        street: invoiceInfo.address,
        name: invoiceInfo.name,
        numberId: invoiceInfo.numberId,
        complement: invoiceInfo.floor,
        neighborhood: invoiceInfo.locality,
        province: invoiceInfo.provinceName,
        city: invoiceInfo.cityName,
        number: invoiceInfo.number?.toString(),
        zipcode: zipcode,
        stateRegistration: invoiceInfo.stateRegistration,
        doesNotHaveStateRegistration: invoiceInfo.doesNotHaveStateRegistration,
        exemptFromStateRegistration: invoiceInfo.exemptFromStateRegistration,
      });
      if (zipcode) fetchAddressesBrazil(zipcode);
    }
  }, [invoiceInfo, setInitialValues, fetchAddressesBrazil]);
  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  const onChange = ({ name, value }) => setFieldValue(name, value);

  useToastStatus({
    error: t('invoicesInfoPage.error'),
    success: t('invoicesInfoPage.success'),
    status,
    onSuccess: () => {
      cleanData();
      goBack();
    },
  });

  return isLoading ? (
    <BrazilianFiscalDataForm.Skeleton onCancel={goBack} />
  ) : (
    <BrazilianFiscalDataForm
      onSave={handleOnSubmit}
      onCancel={goBack}
      onChange={onChange}
      values={values}
      errors={errors}
      isLoading={status === 'loading' || isLoading}
      setFieldValue={setFieldValue}
      isDirty={isDirty}
    />
  );
}

export default BrazilianFiscalData;
