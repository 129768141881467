import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getInstalledPaymentProvidersAction,
  getInstalledPaymentProvidersData,
  getInstalledPaymentProvidersStatus,
} from '../../paymentProvidersSlice';

function useInstalledPaymentProviders() {
  const dispatch = useAppDispatch();
  const paymentProviders = useSelector(getInstalledPaymentProvidersData);
  const { isError, isLoading, isSuccess, isIdle } = useSelector(
    getInstalledPaymentProvidersStatus,
  );

  const fetchInstalledPaymentProviders = useCallback(() => {
    dispatch(getInstalledPaymentProvidersAction());
  }, [dispatch]);

  return {
    isError,
    isLoading,
    isSuccess,
    isIdle,
    paymentProviders,
    fetchInstalledPaymentProviders,
  };
}

export default useInstalledPaymentProviders;
