import { LoggerRequestDto } from '@tiendanube/common';
import { Locale } from 'App/i18n/enums';
import log from 'commons/logger';

export const toSnakeCase = (value: string) =>
  value
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .join('_')
    .toLowerCase();

export const toCamelCase = (value: string) =>
  value.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const keysToSnake = function (o: any) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnakeCase(k)] = keysToSnake(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => keysToSnake(i));
  }

  return o;
};

export const buildQueryParamsUrlWithSnakeCase = (
  object: Record<string, string>,
): string =>
  Object.keys(object)
    .map((key) => `${toSnakeCase(key)}=${object[key]}`)
    .join('&');

export const sanitizateFilters = (
  filters: Record<string, string | number | any>,
): Record<string, string> =>
  Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== '') {
      return { ...acc, [key]: filters[key].toString() };
    }
    return acc;
  }, {});

export const removeEmptyValues = <T extends object>(
  object: T,
): Record<string, string> =>
  Object.keys(object).reduce((acc, key) => {
    if (object[key] !== '') {
      return { ...acc, [key]: object[key].toString() };
    }
    return acc;
  }, {});

export const formatCurrency = (
  number: number,
  currency: string,
  locale: string,
  withoutDecimal?: boolean,
): string => {
  const options = {
    style: 'currency',
    currency: currency,
    ...(withoutDecimal && {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  };

  try {
    return new Intl.NumberFormat(locale, options).format(number);
  } catch (error) {
    /**
     * To kwow more about this error, we need to log it and prevent the app from crashing
     * the user will see the number without formatting
     */
    const data: LoggerRequestDto = {
      level: 'error',
      message: 'Stratus:Error:FormatCurrency',
      data: {
        number,
        currency,
        locale,
        withoutDecimal,
        formatOptions: new Intl.NumberFormat(locale, options).resolvedOptions(),
      },
      location: window.location.href,
      error: {
        message: error.message,
        stack: error.stack,
      },
    };
    log(data);
    return String(number);
  }
};

const validateText = (text: string) => (typeof text === 'string' ? text : '');

/** TODO: from mAPI return string with null, FIX mAPI! */
export const emptyIsNullInText = (text: string): string =>
  validateText(text).search('null') !== -1 ? '' : text;

export const cleanBlanks = (text: string): string =>
  validateText(text).replace(/ /g, '');

export const countNewLineInText = (text: string): number =>
  validateText(text).split('\n').length || 1;

export const getFixedLocaleFromLanguage = (language: string): Locale => {
  if (language.startsWith('es')) {
    return Locale.ARGENTINA;
  } else {
    return Locale.BRAZIL;
  }
};

export const emailRegex = /^$|^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const BASE_URL_ENV = import.meta.env.VITE_BASE_URL;

export function formatTwoDigits(number: number, locale: string) {
  return new Intl.NumberFormat(locale, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  }).format(number);
}
