import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanForwardConfirmationEmail,
  getforwardConfirmationEmailStatus,
  forwardConfirmationEmailAction,
} from 'domains/Dashboard/slices/usersSlice';

function useEmailVerifiedAlert() {
  const dispatch = useAppDispatch();
  const status = useSelector(getforwardConfirmationEmailStatus);

  const forwardConfirmationEmail = useCallback(async () => {
    await dispatch(forwardConfirmationEmailAction());
    dispatch(cleanForwardConfirmationEmail());
  }, [dispatch]);
  return {
    status,
    forwardConfirmationEmail,
  };
}

export default useEmailVerifiedAlert;
