import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const useTranslatePaymentType = (): ((methodType: string) => string) => {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps.paymentDetails',
  );

  return (methodType: string) => {
    const translations = {
      MERCADO_PAGO: t('paymentTypes.mercadoPago'),
      TRANSFER: t('paymentTypes.transfer'),
      NUVEM_PAGO: t('paymentTypes.nuvemPago'),
      NUVEM_ENVIO: t('paymentTypes.nuvemEnvio'),
    };
    return translations[methodType] || methodType;
  };
};

export default useTranslatePaymentType;
