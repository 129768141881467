import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Text } from '@tiendanube/components';
import { cellWidthSettings } from '../MessagesListRow';

const { Header, Cell } = DataTable;

function MessagesListHeader(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  return (
    <Header>
      <Cell basis={cellWidthSettings.messagesColBasis}>
        <Text>{t('messages.table.header.messages')}</Text>
      </Cell>
      <Cell basis={cellWidthSettings.dateColBasis}>
        <Text>{t('messages.table.header.date')}</Text>
      </Cell>
      <Cell basis={cellWidthSettings.stateColBasis}>
        <Text>{t('messages.table.header.state')}</Text>
      </Cell>
      <Cell basis={cellWidthSettings.actionsColBasis}>
        <Text>{t('messages.table.header.actions')}</Text>
      </Cell>
    </Header>
  );
}

export default MessagesListHeader;
