import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { Link } from '@tiendanube/components';
import { ImageItemStratus } from 'commons/components';
import { CATALOG_ROUTES } from 'domains/Catalog/catalogRoutes';
import { ProductHiddenAndWithoutStock } from 'domains/Catalog/Products/pages/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

export interface ProductsBySectionItemProps {
  productId: string;
  name: string;
  imageUrl?: string;
  visible: boolean;
  stock: boolean;
  moveProductToUp?: (() => void) | null;
  firstProductId?: string; // Used to hide first "Move to top text"
}

function ProductsBySectionItem({
  productId,
  imageUrl,
  name,
  visible,
  stock,
  moveProductToUp = null,
  firstProductId,
}: Readonly<ProductsBySectionItemProps>) {
  const t = useTranslationCatalog();

  const productPath = useMemo(
    () =>
      generatePath(CATALOG_ROUTES.editProduct, {
        id: productId,
      }),
    [productId],
  );

  const handleMoveProductToUp = useCallback(
    () => moveProductToUp?.(),
    [moveProductToUp],
  );

  return (
    <ImageItemStratus
      id={productId}
      imageUrl={imageUrl}
      title={name}
      bold={false}
      to={productPath}
      trimTitle
      imageWidth="56px"
      rightContent={
        firstProductId !== productId &&
        moveProductToUp && (
          <Link appearance="primary" onClick={handleMoveProductToUp}>
            {t('products.detail.moveProductToUp')}
          </Link>
        )
      }
    >
      <ProductHiddenAndWithoutStock
        productId={productId}
        isVisible={visible}
        hasStock={stock}
      />
    </ImageItemStratus>
  );
}

export default ProductsBySectionItem;
