import { DataList, Stack, Text } from '@tiendanube/components';

const { Row, Cell } = DataList;

function InvoiceHistoryDataTableRow(): JSX.Element {
  return (
    <Row id="">
      <Cell>
        <Stack column align="flex-start">
          <Stack column align="flex-start" spacing="tight">
            <Text.Skeleton />
            <Stack column spacing="none" align="flex-start">
              <Text.Skeleton />
              <Text.Skeleton />
            </Stack>
          </Stack>
        </Stack>
      </Cell>
      <Cell alignRight>
        <Stack column align="flex-end">
          <Stack column align="flex-end" spacing="tight">
            <Text.Skeleton />
          </Stack>
        </Stack>
      </Cell>
    </Row>
  );
}

export default InvoiceHistoryDataTableRow;
