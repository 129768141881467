import { Fragment } from 'react';
import { Box, Card, Title } from '@nimbus-ds/components';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { RecurrentPaymentData } from './components';

function RecurrentPaymentsStatusSkeleton() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.recurrentPaymentsStatus',
  );

  return (
    <Card padding="none">
      <Box padding="4" paddingBottom="none">
        <Title as="h3">{t('title')}</Title>
      </Box>
      <hr />
      {getMockArrayForSkeleton(2).map((id) => (
        <Fragment key={id}>
          <RecurrentPaymentData.Skeleton />
          <hr />
        </Fragment>
      ))}
    </Card>
  );
}

export default RecurrentPaymentsStatusSkeleton;
