import { useSelector } from 'react-redux';
import {
  getConversionStoreEntityData,
  getConversionStoreEntityStatus,
} from 'domains/Statistics/selectors';

function useGetStatisticsConversionStoreData() {
  const storeConversionStatus = useSelector(getConversionStoreEntityStatus);
  const storeConversion = useSelector(getConversionStoreEntityData);

  const storeConversionIsEmpty =
    storeConversionStatus === 'success' && storeConversion.length === 0;

  return {
    storeConversionStatus,
    storeConversion,
    storeConversionIsEmpty,
  };
}

export default useGetStatisticsConversionStoreData;
