import { BaseCard, Input, InterfaceNameValue } from '@tiendanube/components';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationBilling } from 'domains/Billing';
import ChileanFiscalDataFormSkeleton from './ChileanFiscalDataFormSkeleton';

export interface FiscalDataformChile {
  name: string;
  numberId: string;
  address: string;
}

interface ChileanFiscalDataFormProps {
  values: FiscalDataformChile;
  onChange: (data: InterfaceNameValue) => void;
  onSave: () => void;
  onCancel: () => void;
  errors: Partial<FiscalDataformChile>;
  isLoading: boolean;
  isDirty: boolean;
}

function ChileanFiscalDataForm({
  values,
  onChange,
  onSave,
  onCancel,
  errors,
  isLoading,
  isDirty,
}: ChileanFiscalDataFormProps) {
  const t = useTranslationBilling();
  const { ref } = useAutoFocus();
  const nameHelpText = t(errors.name || '');
  const numberIdHelpText = t(errors.numberId || '');
  const addressHelpText = t(errors.address || '');

  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Stack column align="stretch" innerRef={ref}>
            <Input
              type="text"
              label={t('invoicesInfoPage.name')}
              name="name"
              value={values.name}
              helpText={nameHelpText}
              appearance={nameHelpText ? 'validation_error' : 'default'}
              onChange={onChange}
            />
            <Input
              type="text"
              label={t('invoicesInfoPage.numberId')}
              name="numberId"
              value={values.numberId}
              helpText={numberIdHelpText}
              appearance={numberIdHelpText ? 'validation_error' : 'default'}
              onChange={onChange}
            />
            <Input
              type="text"
              label={t('invoicesInfoPage.address')}
              name="address"
              value={values.address}
              helpText={addressHelpText}
              appearance={addressHelpText ? 'validation_error' : 'default'}
              onChange={onChange}
            />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        isLoading={isLoading}
        onCancel={onCancel}
        onSave={onSave}
        isDisabled={!isDirty}
      />
    </>
  );
}

ChileanFiscalDataForm.Skeleton = ChileanFiscalDataFormSkeleton;

export default ChileanFiscalDataForm;
