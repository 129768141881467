import { useMemo } from 'react';
import {
  attributeHasValues,
  hasDefaultVariant,
} from 'domains/Catalog/Products/utils';
import { Variant, Attributes, BrokenVariantsAlertType } from '../../types';

function useBrokenVariantsAlert(
  variants: Variant[],
  attributes: Attributes,
): BrokenVariantsAlertType | undefined {
  const alertType = useMemo(() => {
    // product has unnamed variants
    if (Object.keys(attributes).some((attributeName) => attributeName === '')) {
      return 'UNNAMED_VARIANTS';
    }

    // product with default variant should not display alert
    if (
      Object.keys(attributes).length === 0 &&
      variants.length === 1 &&
      hasDefaultVariant(variants)
    ) {
      return;
    }

    const validAttributesLength =
      Object.values(attributes).filter((attribute) =>
        attributeHasValues(attribute),
      ).length || 1;

    // product variants and attributes differ
    if (
      variants.some(
        (variant) => variant.values.length !== validAttributesLength,
      )
    ) {
      return 'WRONG_VALUES';
    }
  }, [variants, attributes]);

  return alertType;
}

export default useBrokenVariantsAlert;
