import { ReactNode, createContext, useState } from 'react';
import { useModal } from 'commons/hooks';

const noop = () => null;

interface StockHistoryInterface {
  productId: string;
  variantId: string;
  variantName: string;
  locationId?: string;
}

export interface StockHistoryContextResult {
  openStockHistoryModal: (variant: StockHistoryInterface) => void;
  closeStockHistoryModal: () => void;
  isOpen: boolean;
  variantStock: StockHistoryInterface | null;
  showAccessToStockHistory: boolean;
}

export const StockHistoryContext = createContext<StockHistoryContextResult>({
  openStockHistoryModal: noop,
  closeStockHistoryModal: noop,
  variantStock: null,
  isOpen: false,
  showAccessToStockHistory: false,
});

interface StockHistoryProviderProps {
  children: ReactNode;
}

function StockHistoryProvider({
  children,
}: StockHistoryProviderProps): JSX.Element {
  const [variantStock, setVariantStock] =
    useState<StockHistoryInterface | null>(null);
  const [isOpen, openModal, closeModal] = useModal();

  const openStockHistoryModal = (variant: StockHistoryInterface) => {
    setVariantStock(variant);
    openModal();
  };
  const closeStockHistoryModal = () => {
    setVariantStock(null);
    closeModal();
  };

  return (
    <StockHistoryContext.Provider
      value={{
        openStockHistoryModal,
        closeStockHistoryModal,
        variantStock,
        isOpen,
        // This value, by default, returns false. We return true when we are inside
        // the context that only consumes the editing component
        showAccessToStockHistory: true,
      }}
    >
      {children}
    </StockHistoryContext.Provider>
  );
}

export default StockHistoryProvider;
