import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchDetailInternationalShipping as fetchDetailInternationalShippingAction,
  getDetailInternationalShippingData,
  getDetailInternationalShippingStatus,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useGetInternationalShippingDetail() {
  const dispatch = useAppDispatch();
  const internationalShippingDetail = useSelector(
    getDetailInternationalShippingData,
  );
  const { isError, isLoading } = useSelector(
    getDetailInternationalShippingStatus,
  );

  const fetchInternationalShippingDetail = useCallback(
    (id: string) => {
      dispatch(fetchDetailInternationalShippingAction(id));
    },
    [dispatch],
  );

  return {
    internationalShippingDetail,
    fetchInternationalShippingDetail,
    isError,
    isLoading,
  };
}

export default useGetInternationalShippingDetail;
