import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getStatisticsFreeTrial = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.freeTrial,
);

export const getStatisticsFreeTrialData = createSimpleSelector(
  [getStatisticsFreeTrial],
  (freeTrial) => freeTrial.data,
);
export const getStatisticsFreeTrialStatus = createSimpleSelector(
  [getStatisticsFreeTrial],
  (freeTrial) => convertStatusTypeToObject(freeTrial.status),
);

export const getInitStatisticsFreeTrialStatus = createSimpleSelector(
  [getStatisticsFreeTrial],
  (freeTrial) => freeTrial.post.status,
);
