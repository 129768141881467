import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderCancelRequestDto } from '@tiendanube/common';
import { Reason, Status, Domain } from '@tiendanube/common/src/enums';
import {
  Select,
  Checkbox,
  Alert,
  Stack,
  Link,
  Text,
} from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { CancelAndSaveButtons } from 'commons/components';
import { HOW_TO_CANCEL_AN_ORDER_LINKS } from 'commons/constants';
import { useGetOrdersByIds } from 'domains/Orders/Orders/hooks';
import OrderCancelLabels from './OrderCancelLabels';

interface OrderCancelModalProps {
  show: boolean;
  hideModal: () => void;
  orderIds: string[];
  isLoading: boolean;
  appliedAction: (action: OrderCancelRequestDto) => void;
}

const cancelOrder = {
  reasons: [
    Reason.CUSTOMER,
    Reason.INVENTORY,
    Reason.FRAUD,
    Reason.TEST,
    Reason.OTHER,
  ],
  defaultValues: {
    reason: Reason.CUSTOMER,
    email: true,
    restock: true,
    refund: false,
  },
};

function OrderCancelModal({
  show,
  hideModal,
  orderIds,
  appliedAction,
  isLoading,
}: OrderCancelModalProps): JSX.Element {
  const {
    t,
    i18n: { language },
  } = useTranslation([Domain.ORDERS]);
  const [cancelOrderState, setCancelOrderState] = useState(
    cancelOrder.defaultValues,
  );

  const orders = useGetOrdersByIds(orderIds);
  const reasonOptions = cancelOrder.reasons.map((option) => ({
    label: t(`cancelModal.reason.options.${option}`, {
      count: orders.length,
    }),
    value: option,
  }));

  const handleSelectOrderState = useCallback(({ name, value }) => {
    setCancelOrderState((state) => ({
      ...state,
      ...{ [name]: value },
    }));
  }, []);

  const handleCheckOrderState = useCallback(({ name, checked }) => {
    setCancelOrderState((state) => ({
      ...state,
      ...{ [name]: checked },
    }));
  }, []);

  const onClickPrimary = async () => {
    await appliedAction(cancelOrderState);
  };

  const alertHasAOrderPaid = orders.some(
    (order) =>
      order.payment.status === Status.PAID && order.status === Status.OPEN,
  );

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={hideModal}
      headerContent={t('cancelModal.title', {
        count: orderIds.length,
        orderId: orders[0]?.number,
      })}
    >
      <Stack column spacing="tight" align="stretch">
        {alertHasAOrderPaid ? (
          <Stack.Item>
            <Alert
              appearance="warning"
              title={t('cancelModal.alertPaidTitle')}
              show
            >
              <Stack column align="flex-start">
                <Text size="caption">
                  {t('cancelModal.alertPaidDescription')}
                </Text>
                <Link
                  href={HOW_TO_CANCEL_AN_ORDER_LINKS[language]}
                  size="small"
                  icon={ExternalLinkIcon}
                  underline
                >
                  {t('cancelModal.alertPaidLink')}
                </Link>
              </Stack>
            </Alert>
          </Stack.Item>
        ) : null}
        {orders.length > 1 && (
          <OrderCancelLabels
            orders={orders}
            bulkSelectedOrdersCount={orderIds.length}
          />
        )}
        <Stack.Item />
        <Stack.Item>
          <Select
            label={t('cancelModal.reason.label')}
            name="reason"
            options={reasonOptions}
            value={cancelOrderState.reason}
            onChange={handleSelectOrderState}
          />
        </Stack.Item>
        <Stack.Item />
        <Stack.Item>
          <Checkbox
            label={t('cancelModal.email', {
              count: orders.length,
            })}
            name="email"
            onChange={handleCheckOrderState}
            checked={cancelOrderState.email}
          />
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={t('cancelModal.restock')}
            name="restock"
            onChange={handleCheckOrderState}
            checked={cancelOrderState.restock}
          />
        </Stack.Item>
        <Stack.Item />
        <Stack.Item>
          <CancelAndSaveButtons
            saveText={t('cancelModal.actionPrimary', {
              count: orders.length,
            })}
            onSave={onClickPrimary}
            onCancel={hideModal}
            cancelText={t('cancelModal.actionSecondary')}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default OrderCancelModal;
