import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const noop: () => void = () => null;

const DesktopMenuContext = createContext({
  isShowMenu: true,
  showMenu: noop,
  hideMenu: noop,
});

export function useDesktopMenuContext() {
  return useContext(DesktopMenuContext);
}

function DesktopMenuProvider({ children }) {
  const [isShowMenu, setIsShowMenu] = useState(true);

  const showMenu = useCallback(() => {
    setIsShowMenu(true);
  }, []);

  const hideMenu = useCallback(() => {
    setIsShowMenu(false);
  }, []);

  const value = useMemo(
    () => ({
      isShowMenu,
      showMenu,
      hideMenu,
    }),
    [isShowMenu, showMenu, hideMenu],
  );

  return (
    <DesktopMenuContext.Provider value={value}>
      {children}
    </DesktopMenuContext.Provider>
  );
}

export { DesktopMenuProvider };
