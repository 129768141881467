import { capitalizeFirstLetter } from 'commons/utils/capitalizeFirstLetter';

/**
 * We use a mock date and change only the month of it to get its name
 * It's important we use a date that's far from the end or the start of the month
 * to avoid any undesired behavior product of timezones discrepancies
 */
const MOCK_DATE = new Date(2000, 10, 10, 10);

export const getMonthName = (
  value: Date | number | string,
  language: string,
  capitalize = true,
): string => {
  let date: Date;

  if (typeof value === 'number') {
    date = MOCK_DATE;
    date.setMonth(Number(value) - 1);
  } else {
    date = new Date(value);
  }

  const name = date.toLocaleString(language, { month: 'long' });
  return capitalize
    ? capitalizeFirstLetter(name)
    : name.charAt(0).toLowerCase() + name.slice(1);
};
