import { DiscountType } from '@tiendanube/common';
import { SelectedVariantInterface } from '../../..';

export const getSubtotal = (variants: SelectedVariantInterface[]): number => {
  let subtotal = 0;

  variants.forEach((variant) => {
    if (variant.price && variant.quantity)
      subtotal += variant.price * variant.quantity;
  });

  return subtotal;
};

export const getTotal = (
  subtotal: number,
  discount: number,
  discountType: DiscountType,
  shipping: number,
): number => {
  subtotal =
    discountType === 'percentage'
      ? (subtotal = Number(subtotal) * (1 - Number(discount) / 100))
      : (subtotal = Number(subtotal) - Number(discount));

  return subtotal + Number(shipping);
};
