import { useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { Text } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { ImageItemStratus } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { ResultProductsMobileSkeleton } from './ResultProductsMobileSkeleton';
import './ResultProductsMobile.scss';

interface ProductsProps {
  productId: string;
  title: string;
  photoUrl: string;
  quantity: number;
  price: number;
  variant?: string;
  sku?: string;
}

interface ResultProductsMobileProps {
  show: boolean;
  products?: ProductsProps[];
  quantityUniqueProducts: number;
  currency: CurrencyType;
}

function ResultProductsMobile({
  show,
  products,
  quantityUniqueProducts,
  currency,
}: ResultProductsMobileProps): JSX.Element | null {
  const {
    i18n: { language },
  } = useTranslation();
  const t = useTranslationOrders();
  const { goTo } = useNavegate();

  const goToProduct = (productId: string) => {
    goTo(`/products/${productId}`);
  };

  if (!show) return null;

  return !products ? (
    <ResultProductsMobileSkeleton
      quantityUniqueProducts={quantityUniqueProducts}
    />
  ) : (
    <>
      {products.map((product) => (
        <div
          key={`product-${product.productId}`}
          className="stratus--orders-list-products-mobile"
        >
          <ImageItemStratus
            id={product.productId}
            title={product.title}
            trimTitle
            imageUrl={product.photoUrl}
            imageWidth="48px"
            headerRight={
              <Text block size="caption">
                {formatCurrency(
                  product.quantity * product.price,
                  currency,
                  language,
                  false,
                )}
              </Text>
            }
            onClickTitle={() => goToProduct(product.productId)}
            onClickImage={() => goToProduct(product.productId)}
          >
            {!!product.variant && (
              <Text block size="caption">
                {product.variant}
              </Text>
            )}
            {!!product.sku && (
              <Text block size="caption">
                {t('resultProducts.sku', { sku: product.sku })}
              </Text>
            )}
            <Text block size="caption">
              {t('resultProducts.quantityAndPrice', {
                quantity: product.quantity,
                price: formatCurrency(product.price, currency, language, false),
              })}
            </Text>
          </ImageItemStratus>
        </div>
      ))}
    </>
  );
}

export default ResultProductsMobile;
