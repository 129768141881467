import { useTranslation } from 'react-i18next';
import { StockHistoryResponseDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, DataList, Text } from '@tiendanube/components';
import { InternalNavLink } from 'commons/components';
import { useHasShippingMultiCD } from 'domains/Auth/hooks';
import { Author } from '../Author';
import DateDayMouthHourMinuteText from '../DateDayMouthHourMinuteText';
import LabelFrom from '../LabelFrom';
import LabelReason from '../LabelReason';
import StockMovement from '../StockMovement';
import TextStock from '../TextStock';

const { Row, Cell } = DataList;

interface StockHistoryModalItemListProps {
  stockHistory: StockHistoryResponseDto;
}

function StockHistoryModalItemList({
  stockHistory,
}: StockHistoryModalItemListProps) {
  const { t } = useTranslation(Domain.CATALOG);
  const location =
    stockHistory.locationName &&
    t(
      stockHistory.reason === 'locationChange'
        ? 'products.stockHistoryModal.labelLocation.toLocation'
        : 'products.stockHistoryModal.labelLocation.inLocation',
      { locationName: stockHistory.locationName },
    );
  const hasShippingMultiCD = useHasShippingMultiCD();

  return (
    <Row key={stockHistory.id} id={stockHistory.id}>
      <Cell>
        <Stack spacing="tight">
          <Stack.Item>
            <StockMovement
              id={stockHistory.id}
              variation={stockHistory.variationStock}
              variationSymbol={stockHistory.symbol}
            />
          </Stack.Item>
          {hasShippingMultiCD && (
            <Stack.Item>
              <Text>{location}</Text>
            </Stack.Item>
          )}
        </Stack>
      </Cell>
      <Cell alignRight>
        <TextStock variation={stockHistory.stock} />
      </Cell>
      <Cell width={100}>
        <Stack column align="stretch" spacing="base">
          <Stack.Item>
            {!!stockHistory.orderId && !!stockHistory.orderNumber && (
              <InternalNavLink to={`/orders/${stockHistory.orderId}`}>{`${t(
                'products.stockHistoryModal.author.order',
                {
                  orderId: stockHistory.orderNumber,
                },
              )}`}</InternalNavLink>
            )}
            {!!stockHistory.orderId && !stockHistory.orderNumber && (
              <InternalNavLink to={`/abandoned-carts/${stockHistory.orderId}`}>
                {t('products.stockHistoryModal.author.cart', {
                  cartId: stockHistory.orderId,
                })}
              </InternalNavLink>
            )}
          </Stack.Item>
          <Stack.Item />
        </Stack>
      </Cell>
      <Cell>
        <Stack column align="stretch" spacing="tight">
          <Stack.Item fill>
            <Author
              userName={stockHistory.userName}
              orderId={stockHistory.orderId}
              isUserNameTN={stockHistory.isUserNameTN || false}
              isExternalApp={stockHistory.from === 'api'}
              orderNumber={stockHistory.orderNumber}
            />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell alignRight>
        <DateDayMouthHourMinuteText date={stockHistory.createdAt} />
      </Cell>
      {!!stockHistory.cause && (
        <Cell width={100}>
          <Text size="caption">
            {t('products.stockHistoryModal.cause')}: {stockHistory.cause}
          </Text>
        </Cell>
      )}
      <Cell extraPadding width="fill">
        <Stack wrap>
          <Stack.Item>
            <LabelReason reason={stockHistory.reason} />
          </Stack.Item>
          <Stack.Item>
            <LabelFrom from={stockHistory.from} />
          </Stack.Item>
        </Stack>
      </Cell>
    </Row>
  );
}

export default StockHistoryModalItemList;
