import { Table, Tag, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { Skeleton } from './Skeleton';
import { DNSVerificationModal } from '../../../../AttachDomainModal/components/DNSVerificationModal';
import { Actions } from '../../Actions';
import { CheckDomainErrorsLink } from '../../CheckDomainErrorsLink';
import { DomainSSLStatusTag } from '../../DomainSSLStatusTag';
import { DomainStatusTag } from '../../DomainStatusTag';
import { PrimaryDomainTag } from '../../PrimaryDomainTag';
import { ReactivateDomainLink } from '../../ReactivateDomainLink';
import { DomainsListRowProps } from '../../types';

export function DomainsListDesktopRow({
  domain,
  domainsCount,
  ourDomain,
  isDeleting,
  isUpdating,
  onDeleteDomain,
  onSetPrimaryDomain,
}: Readonly<DomainsListRowProps>) {
  const t = useTranslationConfigurations('domains');
  const [verificationModalOpen, openVerificationModal, closeVerificationModal] =
    useModal();

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Stack spacing="tight" wrap>
            <Text lineClamp={1}>{domain.name}</Text>
            {domain.isOurDomain && (
              <Tag appearance="neutral">{t('tags.default')}</Tag>
            )}
            {domain.isPrimaryDomain && <PrimaryDomainTag />}
          </Stack>
        </Table.Cell>
        <Table.Cell>
          {!domain.isOurDomain && isUpdating ? (
            <Tag.Skeleton />
          ) : (
            <Stack spacing="tight" wrap>
              <DomainStatusTag
                domainId={domain.id}
                isOurDomain={domain.isOurDomain}
                status={domain.status.domain}
              />
              {!domain.isOurDomain && <ReactivateDomainLink />}
              {!domain.isOurDomain && (
                <CheckDomainErrorsLink
                  domainId={domain.id}
                  onClick={openVerificationModal}
                />
              )}
            </Stack>
          )}
        </Table.Cell>
        <Table.Cell>
          {!domain.isOurDomain && isUpdating ? (
            <Tag.Skeleton />
          ) : (
            <DomainSSLStatusTag
              isOurDomain={domain.isOurDomain}
              status={domain.status.ssl}
            />
          )}
        </Table.Cell>
        <Table.Cell>
          <Actions
            domain={domain}
            ourDomain={ourDomain}
            onDelete={onDeleteDomain}
            domainsCount={domainsCount}
            isDeleting={isDeleting}
            onSetPrimaryDomain={onSetPrimaryDomain}
          />
        </Table.Cell>
      </Table.Row>
      <DNSVerificationModal
        isOpen={verificationModalOpen}
        onClose={closeVerificationModal}
        isSubdomain={domain.isSubdomain}
        domainId={domain.id}
        domainName={domain.name}
        ourDomain={ourDomain}
      />
    </>
  );
}

DomainsListDesktopRow.Skeleton = Skeleton;
