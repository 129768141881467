import { Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Text, IconButton } from '@tiendanube/components';
import { MailIcon } from '@tiendanube/icons';
import {
  CurrencyPrice,
  SendWhatsappIconButton,
  Stack,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import { dateFormat, Format } from 'commons/utils/date';
import { SendEmailModal } from 'domains/Customers/components';
import { useCustomerListItem } from 'domains/Customers/Customers/pages/CustomerListPage/hooks';

interface ResultRowDesktopProps {
  id: string;
}

const { Row, Cell } = DataTable;

function ResultRowDesktop({ id }: ResultRowDesktopProps): JSX.Element | null {
  const { t } = useTranslation([Domain.CUSTOMERS]);
  const { push } = useHistory();
  const { customer } = useCustomerListItem(id);
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  if (!customer) return null;

  const goToCustomerDetails = () => {
    push(`/customers/${customer.id}`);
  };

  const goToOrderDetails = () => {
    push(`/orders/${customer.last_order_id}`);
  };

  const handleOnClickSendEmail = () => {
    openSendEmailModal();
  };

  return (
    <>
      <Row id={id} align="center">
        <Cell basis={400}>
          <Stack column justify="space-around">
            <Text trimText trimLines={1}>
              <Link
                appearance="primary"
                onClick={goToCustomerDetails}
                textDecoration="none"
              >
                {customer.name}
              </Link>
            </Text>
          </Stack>
        </Cell>
        <Cell grow={0} basis={170}>
          <Stack gap="1">
            {!!customer.lastOrder?.orderNumber && (
              <Link
                appearance="primary"
                onClick={goToOrderDetails}
                textDecoration="none"
              >
                #{customer.lastOrder.orderNumber}
              </Link>
            )}
            {!!customer.lastOrder?.completedAt && (
              <Text>
                {dateFormat(customer.lastOrder.completedAt, Format.DD_MM_YYYY)}
              </Text>
            )}
          </Stack>
        </Cell>
        <Cell basis={200}>
          <Stack column justify="space-around">
            <CurrencyPrice
              price={Number(customer.total_spent.amount)}
              currency={customer.total_spent.currency}
            />
          </Stack>
        </Cell>
        <Cell grow={0} basis={125}>
          <Stack>
            <IconButton
              ariaLabel={customer.email}
              icon={MailIcon}
              onClick={handleOnClickSendEmail}
            />
            {!!customer.phone && (
              <SendWhatsappIconButton
                phone={customer.phone}
                message={t('tableRow.waText', {
                  clientName: customer.name,
                })}
              />
            )}
          </Stack>
        </Cell>
      </Row>
      {showSendEmailModal && (
        <SendEmailModal
          email={customer.email}
          customerId={String(customer.id)}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default ResultRowDesktop;
