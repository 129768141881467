import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth/authSlice';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const getTrialMessagesAction = createAsyncThunk(
  'dashboard/getTrialMessagesAction',
  dashboardService.getTrialMessages,
);

const trialMessagesSlice = createSlice({
  name: 'trialMessagesSlice',
  initialState: initialState.trialMessages,
  reducers: {
    cleanTrialMessages(state) {
      state = initialState.trialMessages;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.trialMessages;
      return state;
    });

    builder
      .addCase(getTrialMessagesAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTrialMessagesAction.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getTrialMessagesAction.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      });
  },
});

export const { cleanTrialMessages } = trialMessagesSlice.actions;
export const { reducer } = trialMessagesSlice;
