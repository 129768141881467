import { RelatedProductTypes } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  CREATE_RELATED_PRODUCT,
  CREATE_RELATED_PRODUCT_CONFIRM,
  CREATE_RELATED_PRODUCT_MODAL,
  UPDATE_RELATED_PRODUCT,
  UPDATE_RELATED_PRODUCT_CONFIRM,
  UPDATE_RELATED_PRODUCT_MODAL,
} from 'config/trackingEvents';
import { RelatedProductType } from 'domains/Catalog/Products/pages/hooks/useProductForm';

export const trackUpdateRelatedProduct = (type: RelatedProductTypes) => {
  logEvent(UPDATE_RELATED_PRODUCT, {
    relationship_type: type.toLocaleLowerCase(),
  });
};

export const trackCreateRelatedProduct = (type: RelatedProductTypes) => {
  logEvent(CREATE_RELATED_PRODUCT, {
    relationship_type: type.toLocaleLowerCase(),
  });
};

export const trackCreateRelatedProductModal = (type: RelatedProductTypes) => {
  logEvent(CREATE_RELATED_PRODUCT_MODAL, {
    relationship_type: type.toLowerCase(),
  });
};

export const trackUpdateRelatedProductModal = (type: RelatedProductTypes) => {
  logEvent(UPDATE_RELATED_PRODUCT_MODAL, {
    relationship_type: type.toLowerCase(),
  });
};

export const trackCreateRelatedProductConfirm = (type: RelatedProductTypes) => {
  logEvent(CREATE_RELATED_PRODUCT_CONFIRM, {
    relationship_type: type.toLowerCase(),
  });
};

export const trackUpdateRelatedProductConfirm = (type: RelatedProductTypes) => {
  logEvent(UPDATE_RELATED_PRODUCT_CONFIRM, {
    relationship_type: type.toLowerCase(),
  });
};

type TrackRelatedProductParams = {
  type: RelatedProductTypes;
  initialValue?: RelatedProductType[];
  currentValue?: RelatedProductType[];
};

export const trackRelatedProduct = ({
  type,
  initialValue,
  currentValue,
}: TrackRelatedProductParams) => {
  if (!currentValue) {
    return;
  }

  if (!initialValue) {
    if (Array.isArray(currentValue)) {
      trackUpdateRelatedProduct(type);
    }
    return;
  }

  if (initialValue.length === 0 && currentValue.length > 0) {
    trackCreateRelatedProduct(type);
    return;
  }

  if (
    Array.isArray(currentValue) &&
    Array.isArray(initialValue) &&
    initialValue?.length > 0
  ) {
    trackUpdateRelatedProduct(type);
    return;
  }
};
