import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import { getLimitingFeatureByKey } from 'domains/Auth/authSlice/authSelectors';

function useGetLimitingFeatureByKey(key: string, value: number) {
  const getLimitingFeatureByKeyMemo = useMemo(
    () => getLimitingFeatureByKey,
    [],
  );

  const limitingFeature = useSelector((state: RootStateType) =>
    getLimitingFeatureByKeyMemo(state, key),
  );

  return limitingFeature === value;
}

export default useGetLimitingFeatureByKey;
