import { Button, Icon } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useResponsive } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import emptyPromotions from './empty-promotions.png';

interface PromotionsListEmptyProps {
  onCreate: () => void;
}

export function PromotionsListEmpty({
  onCreate,
}: Readonly<PromotionsListEmptyProps>) {
  const t = useTranslationMarketing('promotions.empty');

  const { isMobile } = useResponsive();

  const imgWidth = isMobile ? 313 : 492;

  return (
    <EmptyMessage
      title={t('title')}
      text={t('text')}
      actions={
        <Button appearance="primary" onClick={onCreate}>
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          {t('action')}
        </Button>
      }
      illustration={<img width={imgWidth} src={emptyPromotions} />}
    />
  );
}
