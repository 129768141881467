import { useEffect } from 'react';
import useGetCustomerMetafields from 'domains/Metafields/hooks/useGetCustomerMetafields';

export default function useFetchCustomerMetafields() {
  const { fetchMetafields } = useGetCustomerMetafields();

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);
}
