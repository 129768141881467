import { Title } from '@nimbus-ds/components';
import { StoreDomainDto } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { Responsive, Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import {
  useDeleteDomainMutation,
  useSetPrimaryDomainMutation,
  useUpdateDomainStatusMutation,
} from 'domains/Configurations/Domains/domainsApi';
import { trackingDeleteDomainClick } from 'domains/Configurations/Domains/tracking';
import { UPDATE_DOMAIN_STATUS_CACHE_KEY } from 'domains/Configurations/Domains/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { DomainsListMobile, DomainsListDesktop } from './components';

interface DomainsListProps {
  domains: StoreDomainDto[] | undefined;
  isLoading: boolean;
}

export function DomainsList({
  domains,
  isLoading,
}: Readonly<DomainsListProps>) {
  const t = useTranslationConfigurations('domains.list');

  const [deleteDomain, { status, isLoading: isDeleting }] =
    useDeleteDomainMutation();

  const [setPrimaryDomain, { status: primaryDomainStatus }] =
    useSetPrimaryDomainMutation();

  const [, { status: updateDomainStatus, isLoading: isUpdating }] =
    useUpdateDomainStatusMutation({
      fixedCacheKey: UPDATE_DOMAIN_STATUS_CACHE_KEY,
    });

  const handleDeleteDomain = (id: string) => {
    trackingDeleteDomainClick();
    deleteDomain(id);
  };

  useToastStatus({
    error: t('deleteToast.error'),
    success: t('deleteToast.success'),
    status: toStatusType(status),
  });

  useToastStatus({
    error: t('updateToast.error'),
    progress: t('updateToast.progress'),
    status: toStatusType(updateDomainStatus),
  });

  useToastStatus({
    success: t('primaryToast.success'),
    error: t('primaryToast.error'),
    progress: t('primaryToast.progress'),
    status: toStatusType(primaryDomainStatus),
  });

  if (!domains && !isLoading) return null;

  return (
    <Stack column align="stretch" gap="3">
      <Title as="h5">{t('title')}</Title>
      <Responsive
        mobile={
          isLoading || !domains ? (
            <DomainsListMobile.Skeleton />
          ) : (
            <DomainsListMobile
              domains={domains}
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              onDeleteDomain={handleDeleteDomain}
              onSetPrimaryDomain={setPrimaryDomain}
            />
          )
        }
        desktop={
          isLoading || !domains ? (
            <DomainsListDesktop.Skeleton />
          ) : (
            <DomainsListDesktop
              domains={domains}
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              onDeleteDomain={handleDeleteDomain}
              onSetPrimaryDomain={setPrimaryDomain}
            />
          )
        }
      />
    </Stack>
  );
}
