import { Resource } from 'i18next';
import commonLocale from 'App/locales';
import accountLocales from 'domains/Account/locales';
import loginLocale from 'domains/Auth/locales';
import authenticationFactorLocales from 'domains/AuthenticationFactor/locales';
import billingLocales from 'domains/Billing/locales';
import catalogLocale from 'domains/Catalog/locales';
import configurationsLocales from 'domains/Configurations/locales';
import customersLocale from 'domains/Customers/locales';
import dashboardLocales from 'domains/Dashboard/locales';
import fulfillmentOrdersLocales from 'domains/FulfillmentOrders/locales';
import marketingLocale from 'domains/Marketing/locales';
import metafieldsLocales from 'domains/Metafields/locales';
import nubeKeyboardLocales from 'domains/NubeKeyboard/locales';
import onlineLocales from 'domains/Online/locales';
import ordersLocales from 'domains/Orders/locales';
import partersAppsLocales from 'domains/PartnersApps/locales';
import paymentsLocales from 'domains/Payments/locales';
import salesChannelsLocales from 'domains/SalesChannels/locales';
import shippingLocales from 'domains/Shipping/locales';
import statisticsLocales from 'domains/Statistics/locales';
import { Locale } from './enums';
import { DomainTranslations } from './types';

export const languages: Locale[] = [
  Locale.ARGENTINA,
  Locale.MEXICO,
  Locale.BRAZIL,
  Locale.COLOMBIA,
  Locale.CHILE,
];

export const domains: DomainTranslations[] = [
  loginLocale,
  catalogLocale,
  customersLocale,
  marketingLocale,
  commonLocale,
  nubeKeyboardLocales,
  dashboardLocales,
  ordersLocales,
  statisticsLocales,
  shippingLocales,
  billingLocales,
  partersAppsLocales,
  accountLocales,
  paymentsLocales,
  metafieldsLocales,
  authenticationFactorLocales,
  onlineLocales,
  configurationsLocales,
  salesChannelsLocales,
  fulfillmentOrdersLocales,
];

const resources = getResources(languages, domains);

export function getResources(
  languages: Locale[],
  domains: DomainTranslations[],
): Resource {
  return languages.reduce((current, lang) => {
    const domain = domains.reduce(
      (current, domain) => ({
        ...current,
        [domain.name]: domain.languages[lang],
      }),
      {},
    );
    return { ...current, [lang]: domain };
  }, {});
}

export default resources;
