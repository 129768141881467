import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  createFiscalData as createFiscalDataAction,
  updateFiscalData as updateFiscalDataAction,
  cleanFiscalData,
  getFiscalDataStatus,
} from 'domains/Billing/Invoices/invoicesSlice';
import { FiscalDataformChile } from './components/ChileanFiscalDataForm';

function useChileanFiscalData() {
  const dispatch = useAppDispatch();
  const status = useSelector(getFiscalDataStatus);

  const cleanData = useCallback(() => {
    dispatch(cleanFiscalData());
  }, [dispatch]);

  const createFiscalData = async (data: FiscalDataformChile) => {
    await dispatch(createFiscalDataAction(data));
    cleanData();
  };

  const updateFiscalData = async (data: FiscalDataformChile) => {
    await dispatch(updateFiscalDataAction(data));
    cleanData();
  };

  return {
    cleanData,
    createFiscalData,
    updateFiscalData,
    status,
  };
}

export default useChileanFiscalData;
