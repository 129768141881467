import { Box, Link, Spinner, Text } from '@nimbus-ds/components';
import { UseVariantsRangeResult } from 'domains/Catalog/Inventory/pages/hooks';
import { trackingProductShowMoreVariantsOnInventoryList } from 'domains/Catalog/Products/tracking';

import './ShowHideVariantsLink.scss';

interface ShowHideVariantsLinkProps
  extends Omit<UseVariantsRangeResult, 'variants'> {
  tracking: boolean;
}
function ShowHideVariantsLink({
  tracking = false,
  link,
  spinner,
}: ShowHideVariantsLinkProps) {
  const handleClickLink = () => {
    if (tracking) {
      trackingProductShowMoreVariantsOnInventoryList();
    }
    link.handleClick();
  };

  if (!link.show && !spinner.show) {
    return null;
  }

  return (
    <div className="stratus--show-hide-variants-link">
      <div className="container">
        {link.show && (
          <Link
            appearance="primary"
            as="button"
            onClick={handleClickLink}
            textDecoration="none"
          >
            {link.text}
          </Link>
        )}
        {spinner.show && (
          <Box display="flex" flexDirection="row" gap="2">
            <Spinner size="small" />
            <Text>{spinner.text}</Text>
          </Box>
        )}
      </div>
    </div>
  );
}

export default ShowHideVariantsLink;
