import { CreditCardIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useHandleMenuClick } from 'App/hooks';
import { logEvent } from 'commons/utils/tracking';
import { MENU_SECONDARY_PREFERENCES_CHECKOUT } from 'config/trackingEvents';
import { CONFIGURATIONS_ROUTES } from '../configurationsRoutes';

const path = CONFIGURATIONS_ROUTES.settingsCheckout;

export function SettingsCheckoutMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  const handleClickNewAdmin = () => {
    logEvent(MENU_SECONDARY_PREFERENCES_CHECKOUT, {});
    handleClickItem();
  };

  return (
    <MenuButton
      as={Link}
      onClick={handleClickNewAdmin}
      startIcon={CreditCardIcon}
      active={pathname.startsWith(path)}
      label={t('settingsMenu.advanced.checkout')}
      to={path}
    />
  );
}
