import { Text } from '@nimbus-ds/components';
import { Checkbox, InterfaceNameChecked } from '@tiendanube/components';
import { CardWithTitle, Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { ExternalCheckoutCardValueInterface } from '../ExternalCheckoutCard/ExternalCheckoutCard';

export const TRANSLATE_PREFIX = 'advancedSettingsModal.modalCheckout';

interface ExternalCheckoutCardProps {
  modal: ExternalCheckoutCardValueInterface[];
  onChange: (event: InterfaceNameChecked) => void;
}

function ModalCheckoutCard({ modal, onChange }: ExternalCheckoutCardProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <CardWithTitle title={t('title')}>
      <Stack column align="stretch">
        <Text>{t('subtitle')}</Text>
        {modal?.map(({ id, enabled, deactivationBlocked, name }) => (
          <Checkbox
            key={id}
            label={`${t('allow')} ${name}`}
            name={id}
            checked={enabled}
            disabled={deactivationBlocked}
            onChange={onChange}
          />
        ))}
      </Stack>
    </CardWithTitle>
  );
}

export default ModalCheckoutCard;
