import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import {
  StatisticsPage,
  StatisticsPaymentsCard,
} from 'domains/Statistics/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

function StatisticsPaymentsPage() {
  const t = useTranslationStatistics();

  return (
    <StatisticsPage title={`${t('statistics.paymentsPage.title')}`}>
      <StatisticsPaymentsCard />
      <ShowByCountry excludeCountries={[Country.BR]}>
        <HelpLink
          title={t('statistics.helpLink.aboutStats')}
          linkURL={t('statistics.helpLink.aboutStatsUrl')}
          previousValue=""
          currentViewTracking={t('statistics.aboutProductsTrackingName')}
          icon="both"
        />
      </ShowByCountry>
    </StatisticsPage>
  );
}

export default StatisticsPaymentsPage;
