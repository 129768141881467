import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getPendingOrdersAction,
  getPendingOrdersData,
  getPendingOrdersStatus,
} from '../../ordersSlice';

function useGetPendingOrders() {
  const dispatch = useAppDispatch();
  const pendingOrders = useSelector(getPendingOrdersData);
  const status = useSelector(getPendingOrdersStatus);

  const getPendingOrders = useCallback(() => {
    dispatch(getPendingOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    getPendingOrders();
  }, [getPendingOrders]);

  return {
    pendingOrders,
    ...status,
  };
}

export default useGetPendingOrders;
