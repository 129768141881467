import { useCallback } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

interface UseTranslationAndLanguagePaymentsResponse {
  t: TFunction;
  language: string;
}

function useTranslationAndLanguagePayments(
  translatePrefix?: string,
): UseTranslationAndLanguagePaymentsResponse {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.PAYMENTS);
  const getTranslatedText = useCallback(
    (key: string) =>
      key.startsWith('common:') ? t(key) : t(`${translatePrefix}.${key}`),
    [translatePrefix, t],
  );

  return {
    t: translatePrefix ? (getTranslatedText as TFunction) : t,
    language,
  };
}

export default useTranslationAndLanguagePayments;
