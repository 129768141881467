import { useContext } from 'react';
import {
  ToastContext,
  InterfaceToastContext,
} from 'App/components/ToastContext';

function useToast(): InterfaceToastContext {
  const { addToast, closeToast } = useContext(ToastContext);
  return {
    addToast,
    closeToast,
  };
}

export default useToast;
