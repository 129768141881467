import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { DraftOrderDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  addDraftOrder as addDraftOrderAction,
  cleanUpDraftOrderList,
} from '../../draftOrdersSlice';
import { NewDraftOrdersValuesInterface } from '../../pages/NewDraftOrderPage/types';

type addDraftOrderType = (
  draftOrder: NewDraftOrdersValuesInterface,
) => Promise<DraftOrderDetailsResponseDto>;

interface UseCreateDraftOrderResult {
  addDraftOrder: addDraftOrderType;
}

function useCreateDraftOrder(): UseCreateDraftOrderResult {
  const dispatch = useAppDispatch();

  const addDraftOrder: addDraftOrderType = useCallback(
    async (draftOrder) => {
      const result = await dispatch(addDraftOrderAction({ draftOrder }));
      await dispatch(cleanUpDraftOrderList());
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { addDraftOrder };
}

export default useCreateDraftOrder;
