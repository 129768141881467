import { InteractiveList } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { UpdateByEnum } from '../types';

interface VariationTypeListProps {
  variationType: UpdateByEnum;
  onChange: (variationType: UpdateByEnum) => void;
  // Workaround to get different IDs for same title by adding blanks
  // https://github.com/TiendaNube/design-system-nimbus/issues/469
  index: number;
}

function VariationTypeList({
  variationType,
  onChange,
  index,
}: VariationTypeListProps): JSX.Element {
  const t = useTranslationCatalog();

  const handleChange = ([updateBy]: string[]) => {
    onChange(updateBy as UpdateByEnum);
  };

  const options = [
    {
      title: t('products.editPrice.percentage') + ' '.repeat(index),
      name: UpdateByEnum.PERCENTAGE,
      active: variationType === UpdateByEnum.PERCENTAGE,
    },
    {
      title: t('products.editPrice.fixedValue') + ' '.repeat(index),
      name: UpdateByEnum.ABSOLUTE,
      active: variationType === UpdateByEnum.ABSOLUTE,
    },
  ];

  return (
    <InteractiveList onChange={handleChange} mode="single" options={options} />
  );
}

export default VariationTypeList;
