import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  OcaLabelCostPreviewResponseDto,
  OcaLabelCostPreviewShippingOrderRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { generateOcaLabelsCostsPreviews } from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';

export interface UseOcaLabelsCostsPreviewsResult {
  generateCostsPreviews: (
    payload: OcaLabelCostPreviewShippingOrderRequestDto[],
  ) => Promise<OcaLabelCostPreviewResponseDto[]>;
}

function useOcaLabelsCostsPreviews(): UseOcaLabelsCostsPreviewsResult {
  const dispatch = useAppDispatch();

  const generateCostsPreviews = useCallback(
    async (payload) =>
      unwrapResult(await dispatch(generateOcaLabelsCostsPreviews(payload))),
    [dispatch],
  );

  return {
    generateCostsPreviews,
  };
}

export default useOcaLabelsCostsPreviews;
