import { ChangeEvent, useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import {
  BuyXPayY,
  ProgressiveDiscount,
  PromotionRequestDto,
} from '@tiendanube/common';
import {
  ApplyToCard,
  DiscountTypeCard,
  NameCard,
  LimitsCard,
} from './components';
import { ScopeValuesInterface } from '../../pages/types';
import { usePromotionTypesQuery } from '../../promotionsApi';

export interface PromotionFormProps {
  mode: 'edit' | 'add';
  values: PromotionRequestDto;
  errors: Partial<Record<string, string>>;
  isExternal: boolean;
  setFieldValue: (name: string, value: any) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeCategories: (scopeValues: ScopeValuesInterface[]) => void;
  onChangeProducts: (scopeValues: ScopeValuesInterface[]) => void;
  onChangeIsExternal: (value: boolean) => void;
  onChangeProgressiveDiscounts: (
    progressiveDiscounts: ProgressiveDiscount[],
  ) => void;
  onChangeBuyXPayY: (buyXPayY: BuyXPayY | null) => void;
}

export function PromotionForm({
  mode,
  values,
  errors,
  isExternal,
  setFieldValue,
  onChange,
  onChangeSelect,
  onChangeCategories,
  onChangeProducts,
  onChangeIsExternal,
  onChangeProgressiveDiscounts,
  onChangeBuyXPayY,
}: Readonly<PromotionFormProps>) {
  const { data: discountTypes } = usePromotionTypesQuery();

  useEffect(() => {
    const discountTypeSelected = discountTypes?.find(
      (current) => current.type === values.promotionType,
    );
    setFieldValue(
      'discountForQuantity',
      discountTypeSelected?.isDiscountForQuantity || false,
    );
    setFieldValue('discountTypeId', discountTypeSelected?.id || '');
    onChangeIsExternal(!!discountTypeSelected?.external);
  }, [discountTypes, onChangeIsExternal, setFieldValue, values.promotionType]);

  useEffect(() => {
    if (values.startDate > values.endDate)
      setFieldValue('endDate', values.startDate);
  }, [setFieldValue, values.endDate, values.startDate]);

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <NameCard values={values} onChange={onChange} disabled={isExternal} />
      <DiscountTypeCard
        values={values}
        errors={errors}
        onChangeSelect={onChangeSelect}
        onChangeProgressiveDiscounts={onChangeProgressiveDiscounts}
        onChangeBuyXPayY={onChangeBuyXPayY}
      />

      {!isExternal && (
        <>
          <LimitsCard values={values} errors={errors} onChange={onChange} />
          <ApplyToCard
            mode={mode}
            values={values}
            errors={errors}
            onChange={onChange}
            onChangeCategories={onChangeCategories}
            onChangeProducts={onChangeProducts}
          />
        </>
      )}
    </Box>
  );
}
