import { LanguageInterface, LanguagesType } from 'App/i18n';
import { areTranslatedStringsEqual } from '../../../utils';

export const filterSizesByType = (
  standardSizes: Record<string, LanguageInterface>,
  standardSizesValues: LanguageInterface[],
  languageToUse: LanguagesType,
) => {
  let selectedIds: string[] = [];

  standardSizesValues.forEach((size) =>
    Object.keys(standardSizes).forEach((standardSizeKey) => {
      if (
        areTranslatedStringsEqual(
          languageToUse,
          standardSizes[standardSizeKey],
          size,
        )
      ) {
        selectedIds = [...selectedIds, standardSizeKey];
      }
    }),
  );

  return selectedIds;
};
