import { Text, Stack } from '@tiendanube/components';
import { Icon as IconType } from '@tiendanube/icons';

import './IconItemStratus.css';

interface IconItemStratusProps {
  icon: IconType;
  text: string;
}

function IconItemStratus({
  icon: Icon,
  text,
}: IconItemStratusProps): JSX.Element {
  return (
    <div className="stratus--icon-item-stratus">
      <Stack wrap>
        <Stack.Item>
          <div className="stratus--icon-item-stratus__icon">
            <Icon />
          </div>
        </Stack.Item>
        <Stack.Item>
          <Text size="caption">{text}</Text>
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default IconItemStratus;
