import { ReactNode } from 'react';
import classNames from 'classnames';

import './TextDisabledContainer.scss';

interface TextDisabledContainerProps {
  children: ReactNode;
  disabled?: boolean;
}

function TextDisabledContainer({
  children,
  disabled = false,
}: TextDisabledContainerProps) {
  return (
    <div
      className={classNames({ 'stratus--text-disabled-container': disabled })}
    >
      {children}
    </div>
  );
}

export default TextDisabledContainer;
