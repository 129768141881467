import { useState, useEffect } from 'react';
import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { CheckCircleIcon, ChevronLeftIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import {
  CancelAndConfirmButtons,
  ErrorScreen,
  Stack,
} from 'commons/components';
import { CitiesInterface } from 'domains/Shipping/Addresses/addressesSlice/types';
import useGetCities from 'domains/Shipping/Addresses/hooks/useGetCities';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import CitiesList from './CitiesList';
import { getAllCitiesChecked } from './utils';

interface SelectCitiesModalProps {
  isOpen: boolean;
  onChange: (citiesSelected: string[], allChecked: boolean) => void;
  onClose: () => void;
  selectedCities: string[];
  hasAllCities: boolean;
  selectedNameProvince: string;
  selectedProvinceCode: string;
}

function SelectCitiesModal({
  isOpen,
  onClose,
  onChange,
  selectedCities,
  hasAllCities,
  selectedProvinceCode,
  selectedNameProvince,
}: SelectCitiesModalProps) {
  const t = useTranslationShipping();

  const { cities, isError, isLoading, fetchCities } =
    useGetCities(selectedProvinceCode);

  const [newSelectedCities, setNewSelectedCities] = useState(selectedCities);
  const [filter, setFilter] = useState<CitiesInterface[]>(cities);
  const [allChecked, setAllChecked] = useState<boolean | 'indeterminate'>(
    hasAllCities || getAllCitiesChecked(selectedCities, cities),
  );

  const handleSetNewSelectedCities = (newSelectedCities: string[]) => {
    setNewSelectedCities(newSelectedCities);
  };

  const handleSetFilter = (filter: CitiesInterface[]) => {
    setFilter(filter);
  };

  const handleSelectAll = (allChecked: boolean | 'indeterminate') => {
    setAllChecked(allChecked);
  };

  const handleSave = () => {
    onChange(
      newSelectedCities,
      allChecked !== 'indeterminate' ? allChecked : false,
    );
    onClose();
  };

  useEffect(() => {
    if (cities.length === 0) {
      fetchCities();
    }

    if (cities.length > 0) {
      getAllCitiesChecked(selectedCities, cities);
      setFilter(cities);
    }

    if (hasAllCities && cities.length > 0) {
      setNewSelectedCities(cities.map((city) => city.id));
    }
  }, [cities, fetchCities, hasAllCities, selectedCities]);

  return (
    <SideModal
      open={isOpen}
      onRemove={onClose}
      maxWidth={{ xs: '100%', md: '45%', lg: '540px' }}
      paddingBody="none"
      headerAction={
        <Link onClick={onClose} textDecoration="none">
          <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          <Text fontWeight="bold" fontSize="highlight">
            {t('deliveryMethods.customShipping.selectCitiesModal.headerTitle')}
          </Text>
        </Link>
      }
      headerIcon={
        <Link onClick={handleSave} textDecoration="none">
          <Icon source={<CheckCircleIcon />} />
          <Text>
            {t('deliveryMethods.customShipping.selectCitiesModal.save')}
          </Text>
        </Link>
      }
      title={selectedNameProvince}
    >
      {isError && (
        <ErrorScreen
          description={t('deliveryMethods.customShipping.error.title')}
          onRetry={fetchCities}
        />
      )}
      {!isError && isLoading && !cities.length && <CitiesList.Skeleton />}
      {!isError && !isLoading && !!cities.length && (
        <Stack column align="flex-end" alignSelf="stretch">
          <CitiesList
            cities={cities}
            filteredCities={filter}
            newSelectedCities={newSelectedCities}
            allChecked={allChecked}
            hasAllCities={hasAllCities}
            handleSelectAll={handleSelectAll}
            handleSetFilter={handleSetFilter}
            handleSetNewSelectedCities={handleSetNewSelectedCities}
          />
          {filter.length > 0 && (
            <Box width="100%" padding="4">
              <CancelAndConfirmButtons
                confirmText={t(
                  'deliveryMethods.customShipping.selectCitiesModal.save',
                )}
                cancelText={t(
                  'deliveryMethods.customShipping.selectCitiesModal.cancel',
                )}
                onConfirm={handleSave}
                onCancel={onClose}
              />
            </Box>
          )}
        </Stack>
      )}
    </SideModal>
  );
}

export default SelectCitiesModal;
