/* eslint-disable max-statements */
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import isEmpty from 'lodash.isempty';
import { ShippingAddressResponseDto } from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import InputBrazilianZipCode from 'commons/components/InputBrazilianZipCode';
import { useAsyncFunc } from 'commons/hooks';
import { ZIPCODE_VALUE_LENGTH } from 'domains/Shipping/Locations/pages/constants';
import shippingService from 'domains/Shipping/ShippingMethods/shippingService';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  AddressCardErrorsInterface,
  AddressCardValuesInterface,
} from '../../AddressCard';
import Skeleton from '../../Skeleton';
import InputForLocation from '../InputForLocation';
import SelectForLocation from '../SelectForLocation';

export interface AddressCardBrazilProps {
  values: AddressCardValuesInterface;
  errors: Partial<AddressCardErrorsInterface>;
  storeCountry: string;
  countries: { value: string; label: string }[];
  loadingCountries: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldValue: (name: string, value: string) => void;
  clearFields: () => void;
}

function AddressCardBrazil({
  values,
  errors,
  storeCountry,
  countries,
  loadingCountries,
  onChange,
  setFieldValue,
  clearFields: clearAllFields,
}: AddressCardBrazilProps): JSX.Element {
  const t = useTranslationShipping();

  const handleChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    clearAllFields();
    onChange(e);
  };

  const [disabledFields, setDisabledFields] = useState({
    locality: true,
    street: true,
    city: true,
    province: true,
  });

  const setField = (name: string, value: string) => {
    if (!isEmpty(value)) {
      setFieldValue(name, value);
    }
    setDisabledFields((prev) => ({
      ...prev,
      [name]: !isEmpty(value),
    }));
  };

  const [resolveAddressForBrazil, isLoading, isErrorAddress] = useAsyncFunc<
    string,
    ShippingAddressResponseDto
  >(async (data) => {
    const values = await shippingService.addressesBrazil(data as string);
    setField('province', values.province);
    setField('city', values.city);
    setField('street', values.street);
    setField('locality', values.locality);
  });

  const clearFields = useCallback(() => {
    setFieldValue('province', '');
    setFieldValue('city', '');
    setFieldValue('locality', '');
    setFieldValue('street', '');
    setDisabledFields({
      locality: true,
      street: true,
      city: true,
      province: true,
    });
  }, [setFieldValue, setDisabledFields]);
  const handleChangeAddress = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    onChange(e);
    if (e.target.value.length === ZIPCODE_VALUE_LENGTH) {
      resolveAddressForBrazil(e.target.value);
    }
    if (e.target.value.length < ZIPCODE_VALUE_LENGTH) {
      clearFields();
    }
  };

  const localityHelpText = t(errors.locality || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');
  const zipcodeHelpText = isErrorAddress
    ? t('locations.form.errors.zipcodeInvalid')
    : t(errors.zipcode || '');

  const useEffectOnceTime = useRef(false);
  useEffect(() => {
    const { country, zipcode } = values;
    if (
      !useEffectOnceTime.current &&
      !isEmpty(country) &&
      zipcode &&
      zipcode.length === ZIPCODE_VALUE_LENGTH
    ) {
      if (storeCountry !== Country.BR) {
        clearFields();
      }
      resolveAddressForBrazil(zipcode);
      useEffectOnceTime.current = true;
    }
    if (zipcode && zipcode.length < 8 && !useEffectOnceTime.current) {
      useEffectOnceTime.current = true;
      clearFields();
    }
  }, [
    resolveAddressForBrazil,
    storeCountry,
    values,
    clearFields,
    setFieldValue,
  ]);

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('locations.form.address.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          {storeCountry !== Country.BR && (
            <SelectForLocation
              label={t(`locations.form.address.fields.BR.country`)}
              name="country"
              value={values.country}
              onChange={handleChangeCountry}
              id="country"
              isLoading={loadingCountries}
              options={countries}
            />
          )}
          <InputBrazilianZipCode
            label={t(`locations.form.address.fields.BR.zipcode`)}
            onChange={handleChangeAddress}
            name="zipcode"
            maxLength={ZIPCODE_VALUE_LENGTH}
            value={values.zipcode ?? ''}
            helpText={zipcodeHelpText}
            showHelpText={
              ((isErrorAddress &&
                values.zipcode.length < ZIPCODE_VALUE_LENGTH) ||
                !!errors.zipcode) &&
              !values.zipcode
            }
            appearance={
              (isErrorAddress &&
                values.zipcode.length < ZIPCODE_VALUE_LENGTH) ||
              !!errors.zipcode
                ? 'danger'
                : 'none'
            }
          />
          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.BR.province`)}
            name="province"
            value={values.province ?? ''}
            disabled={disabledFields.province}
            isLoading={isLoading}
          />
          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.BR.city`)}
            name="city"
            value={values.city ?? ''}
            disabled={disabledFields.city}
            onChange={onChange}
            isLoading={isLoading}
          />
          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.BR.locality`)}
            onChange={onChange}
            name="locality"
            value={values.locality ?? ''}
            disabled={disabledFields.locality}
            helpText={localityHelpText}
            showHelpText={!!localityHelpText && !values.locality}
            appearance={
              localityHelpText && !values.locality ? 'danger' : 'none'
            }
            isLoading={isLoading}
          />
          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.BR.street`)}
            onChange={onChange}
            name="street"
            value={values.street ?? ''}
            disabled={disabledFields.street}
            helpText={streetHelpText}
            showHelpText={!!streetHelpText && !values.street}
            appearance={streetHelpText && !values.street ? 'danger' : 'none'}
            isLoading={isLoading}
          />
          <Stack spacing="tight" align="flex-start">
            <InputForLocation
              type="text"
              label={t(`locations.form.address.fields.BR.number`)}
              onChange={onChange}
              name="number"
              value={values.number ?? ''}
              helpText={numberHelpText}
              showHelpText={!!numberHelpText && !values.number}
              appearance={numberHelpText && !values.number ? 'danger' : 'none'}
            />
            <InputForLocation
              type="text"
              label={t(`locations.form.address.fields.BR.floor`)}
              onChange={onChange}
              name="floor"
              value={values.floor ?? ''}
            />
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

AddressCardBrazil.Skeleton = Skeleton;

export default AddressCardBrazil;
