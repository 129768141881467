import { ReactNode, createContext, useState, useCallback } from 'react';
import { InterfaceToast, Toast } from '@tiendanube/components';

type AddToastType = Pick<InterfaceToast, 'label' | 'appearance'>;

export interface InterfaceToastContext {
  closeToast: () => void;
  addToast: (toast: AddToastType) => void;
}

export const ToastContext = createContext({} as InterfaceToastContext);

interface InterfaceToastContextProvider {
  children: ReactNode;
}

function ToastContextProvider({
  children,
}: InterfaceToastContextProvider): JSX.Element {
  const [toast, setToast] = useState<AddToastType | null>(null);

  const closeToast = useCallback(() => {
    setToast(null);
  }, []);

  const addToast = useCallback(
    (newToast: AddToastType): void => {
      closeToast();
      setToast(newToast);
    },
    [closeToast],
  );

  return (
    <ToastContext.Provider value={{ closeToast, addToast }}>
      {children}
      {toast && (
        <Toast
          label={toast.label}
          appearance={toast.appearance}
          onClose={closeToast}
        />
      )}
    </ToastContext.Provider>
  );
}

export default ToastContextProvider;
