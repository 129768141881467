import { useCallback, useEffect } from 'react';
import { Accordion, Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  useGetLastPayment,
  useGetSubscriptions,
  usePaymentMethods,
} from 'domains/Billing/Checkout/hooks';
import 'domains/Billing/Checkout/Checkout.scss';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PaymentOption } from './components';
import PaymentSelectionSkeleton from './Skeleton';
import { useContinuePayment } from '../../hooks';

function PaymentSelection(): JSX.Element {
  const {
    paymentMethods,
    selectPaymentMethod,
    status: methodsFetchStatus,
    paymentMethodSelected,
    refreshPaymentMethods,
  } = usePaymentMethods();
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPlan.paymentSelection',
  );
  const {
    status: lastPaymentFetchStatus,
    refreshLastPayment,
    lastSuccessfulPaymentMethodUsed,
  } = useGetLastPayment('plan-cost');
  const {
    subscriptionForPlan,
    status: subscriptionsFetchStatus,
    refreshSubscriptions,
  } = useGetSubscriptions();

  const { setupStatus, retrySetup: retryContinueSetup } = useContinuePayment();

  const retrySetup = useCallback(() => {
    retryContinueSetup();
    refreshLastPayment();
    refreshPaymentMethods();
    refreshSubscriptions();
  }, [
    retryContinueSetup,
    refreshLastPayment,
    refreshPaymentMethods,
    refreshSubscriptions,
  ]);

  const { isLoading, isSuccess, isError } = combineStatus(
    methodsFetchStatus,
    subscriptionsFetchStatus,
    lastPaymentFetchStatus,
    setupStatus.status,
  );

  // Select default option depending on previously used
  useEffect(() => {
    if (!isSuccess || !paymentMethods) return;

    let paymentMethod = paymentMethods?.find(
      (method) => method.method === lastSuccessfulPaymentMethodUsed,
    );

    if (!paymentMethod) paymentMethod = paymentMethods[0];

    let optionIndex = paymentMethod?.recurrencyOptions.findIndex(
      (option) =>
        option.recurringFrequency === subscriptionForPlan?.recurringFrequency &&
        option.recurringInterval === subscriptionForPlan?.recurringInterval,
    );

    if (optionIndex === undefined || optionIndex < 0) optionIndex = 0;

    selectPaymentMethod({
      method: paymentMethod.method,
      recurrencyOption: paymentMethod.recurrencyOptions[optionIndex],
      nameInList: `${paymentMethod.method},${optionIndex}`,
    });
  }, [
    lastSuccessfulPaymentMethodUsed,
    subscriptionForPlan,
    paymentMethods,
    selectPaymentMethod,
    isSuccess,
  ]);

  if (isLoading) return <PaymentSelectionSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: retrySetup,
        }}
      />
    );

  const lastUsedPaymentMethodIndex =
    paymentMethods?.findIndex(
      (method) => method.method === paymentMethodSelected?.method,
    ) ?? 0;

  return (
    <Card padding="none">
      <div className="stratus--checkout-card_content">
        <Card.Header title={t('title')} />
      </div>
      <Accordion selectedDefault={lastUsedPaymentMethodIndex.toString()}>
        {paymentMethods?.map((paymentMethod, index) => (
          <Accordion.Item index={index.toString()} key={paymentMethod.method}>
            <PaymentOption paymentMethod={paymentMethod} />
          </Accordion.Item>
        ))}
      </Accordion>
    </Card>
  );
}

export default PaymentSelection;
