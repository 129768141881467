import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProductSectionResponseDto,
  ProductSectionDetailResponseDto,
  SectionProductDto,
  ContactInfoResponseDto,
  ContactInfoRequestDto,
  PasswordProtectedResponseDto,
  PasswordProtectedRequestDto,
} from '@tiendanube/common';
import { StatusType } from 'commons/types';
import * as themesService from '../themesService';
import { SectionCodeType } from '../types';

type SectionDetail = Record<
  SectionCodeType,
  ProductSectionDetailResponseDto | null
>;
interface ProductSectionsDetailsInterface {
  data: SectionDetail;
  status: StatusType;
  updateStatus: StatusType;
}
interface OrderProduct {
  order: number;
  id: string;
}
interface UpdateProductsSectionParams {
  products: OrderProduct[];
  code: SectionCodeType;
}
export interface ProductSectionsInterface {
  data: ProductSectionResponseDto[] | null;
  status: StatusType;
  entityDetail: ProductSectionsDetailsInterface;
}

export interface ContactInfoInterface {
  status: StatusType;
  updateStatus: StatusType;
  data: ContactInfoResponseDto | null;
}

export interface PasswordProtectedInterface {
  status: StatusType;
  updateStatus: StatusType;
  data: PasswordProtectedResponseDto | null;
}

export interface ThemesInterface {
  contactInfo: ContactInfoInterface;
  passwordProtected: PasswordProtectedInterface;
  productSection: ProductSectionsInterface;
}

const initialState: ThemesInterface = {
  contactInfo: {
    status: 'idle',
    updateStatus: 'idle',
    data: null,
  },
  passwordProtected: {
    status: 'idle',
    updateStatus: 'idle',
    data: null,
  },
  productSection: {
    data: null,
    status: 'idle',
    entityDetail: {
      data: {
        primary: null,
        slider: null,
        sale: null,
        new: null,
        recent: null,
        coming: null,
        offer: null,
      },
      status: 'idle',
      updateStatus: 'idle',
    },
  },
};

export const getProductSections = createAsyncThunk<
  ProductSectionResponseDto[],
  void
>('themes/getProuctSection', async () => themesService.getProductSections());

export const getProductSectionDetailByCode = createAsyncThunk<
  ProductSectionDetailResponseDto,
  SectionCodeType
>('themes/getProductSectionDetailByCode', async (code) =>
  themesService.getProductSectionDetailByCode(code),
);

export const updateProductsSection = createAsyncThunk<
  SectionProductDto[],
  UpdateProductsSectionParams
>('themes/updateProductsSection', async (params) =>
  themesService.updateProductsSection(params),
);

export const getContactInfoAction = createAsyncThunk<
  ContactInfoResponseDto,
  void
>('contactInfo/getContactInfo', themesService.getContactInfo);

export const updateContactInfoAction = createAsyncThunk<
  void,
  ContactInfoRequestDto
>('contactInfo/updateContactInfo', async (params) =>
  themesService.updateContactInfo(params),
);

export const getPasswordProtectedAction = createAsyncThunk<
  PasswordProtectedResponseDto,
  void
>('passwordProtected/getPasswordProtected', async () =>
  themesService.getPasswordProtected(),
);

export const updatePasswordProtectedAction = createAsyncThunk<
  void,
  PasswordProtectedRequestDto
>('passwordProtected/updatePasswordProtected', async (params) =>
  themesService.updatePasswordProtected(params),
);

const themesSlice = createSlice({
  name: 'themes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductSections.pending, (state) => {
        state.productSection.data = null;
        state.productSection.status = 'loading';
      })
      .addCase(getProductSections.fulfilled, (state, action) => {
        state.productSection.status = 'success';
        state.productSection.data = action.payload;
      })
      .addCase(getProductSections.rejected, (state) => {
        state.productSection.data = null;
        state.productSection.status = 'error';
      })
      .addCase(getProductSectionDetailByCode.pending, (state, action) => {
        state.productSection.entityDetail.data[action.meta.arg] = null;
        state.productSection.entityDetail.status = 'loading';
      })
      .addCase(getProductSectionDetailByCode.rejected, (state, action) => {
        state.productSection.entityDetail.data[action.meta.arg] = null;
        state.productSection.entityDetail.status = 'error';
      })
      .addCase(getProductSectionDetailByCode.fulfilled, (state, action) => {
        state.productSection.entityDetail.status = 'success';
        state.productSection.entityDetail.data[action.payload.code] =
          action.payload;
      })
      .addCase(updateProductsSection.pending, (state) => {
        state.productSection.entityDetail.updateStatus = 'loading';
      })
      .addCase(updateProductsSection.rejected, (state) => {
        state.productSection.entityDetail.updateStatus = 'error';
      })
      .addCase(updateProductsSection.fulfilled, (state, action) => {
        state.productSection.entityDetail.updateStatus = 'success';
        state.productSection.data?.forEach((section) => {
          if (section.code === action.meta.arg.code) {
            section.productCount = action.payload.length;
          }
        });
        if (state.productSection.entityDetail.data[action.meta.arg.code]) {
          state.productSection.entityDetail.data = {
            ...state.productSection.entityDetail.data,
            [action.meta.arg.code]: {
              ...state.productSection.entityDetail.data[action.meta.arg.code],
              products: action.payload,
            },
          };
        }
      });

    builder
      .addCase(getContactInfoAction.pending, (state) => {
        state.contactInfo.data = null;
        state.contactInfo.status = 'loading';
      })
      .addCase(getContactInfoAction.fulfilled, (state, action) => {
        state.contactInfo.status = 'success';
        state.contactInfo.data = action.payload;
      })
      .addCase(getContactInfoAction.rejected, (state) => {
        state.contactInfo.data = null;
        state.contactInfo.status = 'error';
      });

    builder
      .addCase(updateContactInfoAction.pending, (state) => {
        state.contactInfo.updateStatus = 'loading';
      })
      .addCase(updateContactInfoAction.rejected, (state) => {
        state.contactInfo.updateStatus = 'error';
      })
      .addCase(updateContactInfoAction.fulfilled, (state, action) => {
        state.contactInfo.updateStatus = 'success';
        state.contactInfo.data = action.meta.arg;
      });

    builder
      .addCase(getPasswordProtectedAction.pending, (state) => {
        state.passwordProtected.data = null;
        state.passwordProtected.status = 'loading';
      })
      .addCase(getPasswordProtectedAction.fulfilled, (state, action) => {
        state.passwordProtected.status = 'success';
        state.passwordProtected.data = action.payload;
      })
      .addCase(getPasswordProtectedAction.rejected, (state) => {
        state.passwordProtected.data = null;
        state.passwordProtected.status = 'error';
      });

    builder
      .addCase(updatePasswordProtectedAction.pending, (state) => {
        state.passwordProtected.updateStatus = 'loading';
      })
      .addCase(updatePasswordProtectedAction.rejected, (state) => {
        state.passwordProtected.updateStatus = 'error';
      })
      .addCase(updatePasswordProtectedAction.fulfilled, (state, action) => {
        state.passwordProtected.updateStatus = 'success';
        state.passwordProtected.data = action.meta.arg;
      });
  },
});

export const { reducer } = themesSlice;
