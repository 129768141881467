import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import { HeaderContent, IonPageStratus } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  SeoRedirectsForm,
  SeoRedirectsListCard,
  SeoRedirectsInfoAlert,
} from './components';

function SeoRedirectsPage() {
  const t = useTranslationConfigurations('seoRedirects');

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Box display="flex" flexDirection="column" marginTop="2" gap="8">
        <SeoRedirectsInfoAlert />
        <SeoRedirectsForm />
        <SeoRedirectsListCard />
        <HelpLink
          title={t('helpLink.text')}
          linkURL={t('helpLink.link')}
          icon="both"
          previousValue=""
          currentViewTracking=""
        />
      </Box>
    </IonPageStratus>
  );
}

export default SeoRedirectsPage;
