import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const getPagSeguroUrlAction = createAsyncThunk(
  'dashboard/getPagSeguroMessagesAction',
  dashboardService.getPagSeguroUrl,
);

export const removeMercadoPagoCredentialsNotificationAction = createAsyncThunk(
  'dashboard/removeMercadoPagoCredentialsNotificationAction',
  dashboardService.removeMercadoPagoCredentialsNotification,
);

export const verifyMercadoPagoAction = createAsyncThunk(
  'dashboard/verifyMercadoPagoAction',
  dashboardService.verifyMercadoPago,
);

const paymentsMessagesSlice = createSlice({
  name: 'paymentsMessagesSlice',
  initialState: initialState.paymentMessages,
  reducers: {
    cleanGetPagseguroUrlStatus(state) {
      state.pagSeguro.status = 'idle';
    },
    cleanRemoveMercadoPagoCredentialsNotificationAction(state) {
      state.mercadopago.credentials.removeNotification.status = 'idle';
      return state;
    },
    cleanVerifyMercadoPagoStatus(state) {
      state.mercadopago.verify.status = 'idle';
    },
    cleanVerifyMercadoPagoData(state) {
      state.mercadopago.verify.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.paymentMessages;
      return state;
    });

    builder
      .addCase(getPagSeguroUrlAction.pending, (state) => {
        state.pagSeguro.status = 'loading';
      })
      .addCase(getPagSeguroUrlAction.rejected, (state) => {
        state.pagSeguro.status = 'error';
      })
      .addCase(getPagSeguroUrlAction.fulfilled, (state, action) => {
        state.pagSeguro.status = 'success';
        state.pagSeguro.data = action.payload;
      });

    builder
      .addCase(
        removeMercadoPagoCredentialsNotificationAction.pending,
        (state) => {
          state.mercadopago.credentials.removeNotification.status = 'loading';
        },
      )
      .addCase(
        removeMercadoPagoCredentialsNotificationAction.rejected,
        (state) => {
          state.mercadopago.credentials.removeNotification.status = 'error';
        },
      )
      .addCase(
        removeMercadoPagoCredentialsNotificationAction.fulfilled,
        (state) => {
          state.mercadopago.credentials.removeNotification.status = 'success';
        },
      );

    builder
      .addCase(verifyMercadoPagoAction.pending, (state) => {
        state.mercadopago.verify.status = 'loading';
      })
      .addCase(verifyMercadoPagoAction.rejected, (state) => {
        state.mercadopago.verify.status = 'error';
      })
      .addCase(verifyMercadoPagoAction.fulfilled, (state, action) => {
        state.mercadopago.verify.status = 'success';
        state.mercadopago.verify.data = action.payload;
      });
  },
});

export const { reducer } = paymentsMessagesSlice;
export const {
  cleanGetPagseguroUrlStatus,
  cleanRemoveMercadoPagoCredentialsNotificationAction,
  cleanVerifyMercadoPagoStatus,
  cleanVerifyMercadoPagoData,
} = paymentsMessagesSlice.actions;
