import { useState } from 'react';
import {
  ResultPaginationResponseDto,
  SectionProductDto,
} from '@tiendanube/common';
import { getProductsOrderProductList } from '../../themesService';
import { ProductsOrderListFiltersInterface } from '../../types';

const initialValue: ResultPaginationResponseDto<SectionProductDto[]> = {
  pagination: {
    totalPages: 0,
    currentPage: 0,
    totalResults: 0,
    perPage: 20,
    nextPage: 1,
  },
  results: [],
};

function useProductOrderList() {
  const [status, setStatus] = useState('idle');
  const [products, setProducts] =
    useState<ResultPaginationResponseDto<SectionProductDto[]>>(initialValue);

  const fetchProductOrderlist = async (
    filters: ProductsOrderListFiltersInterface,
    reset = false,
  ) => {
    try {
      setStatus('loading');
      const moreProducts = await getProductsOrderProductList(filters);
      setStatus('success');
      const previusProducts = reset ? [] : products.results;
      setProducts({
        pagination: moreProducts.pagination,
        results: [...previusProducts, ...moreProducts.results],
      });
    } catch (_) {
      setStatus('error');
    }
  };

  const resetProducts = () => {
    setProducts(initialValue);
  };

  return {
    products,
    status,
    setProducts,
    resetProducts,
    fetchProductOrderlist,
  };
}

export default useProductOrderList;
