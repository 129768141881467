import { AlertSessionStorage } from 'App/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function SeoRedirectsInfoAlert() {
  const t = useTranslationConfigurations('seoRedirects.infoAlert');
  return (
    <AlertSessionStorage keyName="SeoRedirectsInfoAlert" appearance="primary">
      {t('text')}
    </AlertSessionStorage>
  );
}

export default SeoRedirectsInfoAlert;
