import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationMarketing(keyPrefix?: string): TFunction {
  const { t } = useTranslation(Domain.MARKETING, { keyPrefix });

  return t;
}

export default useTranslationMarketing;
