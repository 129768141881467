import { Card, Text, Link } from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function HighlightProductCardSkeleton(): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <Card title={t('products.detail.highlight.title')}>
      <Stack column align="stretch">
        <Text>{t('products.detail.highlight.description')}</Text>
        <Link.Skeleton />
      </Stack>
    </Card>
  );
}

export default HighlightProductCardSkeleton;
