import { Alert, Text } from '@nimbus-ds/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function BackgroudRemoveUncertaintyAlert() {
  const t = useTranslationCatalog(
    'products.modal.editPhoto.alerts.backgroudRemoveUncertainty',
  );
  return (
    <Alert appearance="warning" title={t('title')}>
      <Text color="neutral-textLow">{t('content')}</Text>
    </Alert>
  );
}

export default BackgroudRemoveUncertaintyAlert;
