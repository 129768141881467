import { AcceleratedCheckoutCardSkeleton } from './AcceleratedCheckoutCard';
import { BuyerNoteCardSkeleton } from './BuyerNoteCard';
import { CustomerInformationCardSkeleton } from './CustomerInformationCard';
import { DesignPreferencesCardSkeleton } from './DesignPreferencesCard';
import { RestrictPurchasesCardSkeleton } from './RestrictPurchasesCard';
import { TrackingPageMessageCardSkeleton } from './TrackingPageMessageCard';

export function SettingsCheckoutFormSkeleton() {
  return (
    <>
      <DesignPreferencesCardSkeleton />
      <CustomerInformationCardSkeleton />
      <BuyerNoteCardSkeleton />
      <TrackingPageMessageCardSkeleton />
      <RestrictPurchasesCardSkeleton />
      <AcceleratedCheckoutCardSkeleton />
    </>
  );
}
