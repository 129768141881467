const V1_BASE_URL = 'v1/shipping';

export const LOCAL_SHIPPING_URL = `${V1_BASE_URL}/local-shippings`;
export const AVAILABLE_APPS_URL = `${V1_BASE_URL}/methods-available`;
export const CUSTOM_SHIPPINGS_URL = `${V1_BASE_URL}/custom-shippings`;
export const CUSTOM_SHIPPINGS_DETAIL_URL = `${V1_BASE_URL}/custom-shippings/:id`;
export const INTERNATIONALS_URL = `${V1_BASE_URL}/internationals`;
export const INTERNATIONALS_DETAIL_URL = `${INTERNATIONALS_URL}/:id`;
export const SHIPPING_LOCALES_URL = `${V1_BASE_URL}/locales`;
export const SHIPPING_FALLBACKS = `${V1_BASE_URL}/fallbacks`;
export const OCA_URL = `${V1_BASE_URL}/oca`;
export const OCA_REGISTRATION_URL = `${OCA_URL}/registration`;
export const OCA_CONFIGURATION_URL = `${OCA_URL}/configuration`;
export const OCA_AGENCIES_URL = `${OCA_URL}/agencies`;
export const CORREIOS_URL = `${V1_BASE_URL}/correios`;
export const CORREIOS_CONTRACTS_URL = `${CORREIOS_URL}/contracts`;
export const CORREIOS_SHIPPING_METHODS_URL = `${CORREIOS_URL}/shipping-methods`;
export const APPS_URL = `${V1_BASE_URL}/apps`;
export const APPS_DETAIL_URL = `${APPS_URL}/:id`;
export const SHIPPING_METHODS_URL = `${V1_BASE_URL}/methods`;
export const ONBOARDING_NUVEM_ENVIO_URL = `${V1_BASE_URL}/nuvem-envio/configs`;
export const ADDRESSING_CONFIGURATION_URL = `${V1_BASE_URL}/addressing-configuration/rules`;
