export const bulkactions: Record<string, string> = {
  CANCEL: 'cancel',
  ARCHIVE: 'archive',
  PAY: 'pay',
  PACK: 'pack',
  FULFILL: 'fulfill',
  PRINT_RECEIPT: 'printReceipt',
  PRINT_PACKING_LABEL: 'printPackingLabel',
  PRINT_CONTENT_DECLARATION: 'printContentDeclaration',
  ASSIGN_METAFIELDS: 'assignMetafields',
} as const;

export const optionsActions = Object.values(bulkactions);
