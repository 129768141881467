import { Box, Text, IconButton } from '@nimbus-ds/components';
import { CELL_BOX_PROPS } from './SeoRedirectsItem';

function Skeleton() {
  return (
    <Box {...CELL_BOX_PROPS}>
      <Text.Skeleton />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Text.Skeleton />
        <IconButton.Skeleton height="32px" width="32px" />
      </Box>
    </Box>
  );
}

export default Skeleton;
