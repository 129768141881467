import { Box, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { useResponsive } from 'commons/components';
import { VariantStockPrice } from 'domains/Orders/components/ProductSearcher/ProductList/VariantStockPrice';
import {
  ProductInterface,
  SelectedProductInterface,
} from 'domains/Orders/components/ProductSearcher/types';

interface VariantListItemProps<Minimalistic extends boolean> {
  name: string;
  variant: ProductInterface<false>;
  selectedVariant?: SelectedProductInterface;
  minimalistic?: boolean;
  selectedVariantsLimitReached?: boolean;
  onChange: (
    variants: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => void;
}

function VariantListItem<Minimalistic extends boolean>({
  name,
  variant,
  selectedVariant,
  minimalistic,
  selectedVariantsLimitReached = false,
  onChange,
}: Readonly<VariantListItemProps<Minimalistic>>) {
  const isDisabled =
    (!minimalistic &&
      ((variant as ProductInterface<false>).stock === 0 ||
        selectedVariant?.isPreselected)) ||
    (selectedVariantsLimitReached && !selectedVariant);
  const { isMobile } = useResponsive();

  return (
    <InteractiveList.CheckboxItem
      topDivider={false}
      key={variant.variantId}
      title={name}
      showTitle={false}
      checkbox={{
        name: 'checkbox-element',
        disabled: isDisabled,
        checked: !!selectedVariant,
        onChange: ({ currentTarget }) =>
          onChange([variant], currentTarget.checked),
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box flex="1" alignSelf="center" paddingRight="2">
          <Text
            color={isDisabled ? 'neutral-textDisabled' : 'neutral-textHigh'}
          >
            {name}
          </Text>
        </Box>
        {!minimalistic && (
          <Box flex={isMobile ? '2' : '1'}>
            <VariantStockPrice variant={variant} />
          </Box>
        )}
      </Box>
    </InteractiveList.CheckboxItem>
  );
}

export default VariantListItem;
