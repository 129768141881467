import { Button, Card } from '@nimbus-ds/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface SummarySkeletonProps {
  isRecurringPaymentActivated?: boolean;
}

function SummarySkeleton({
  isRecurringPaymentActivated,
}: SummarySkeletonProps): JSX.Element {
  const translationKey = isRecurringPaymentActivated
    ? 'checkout.plansAndSubscriptions.summary.withRP'
    : 'checkout.plansAndSubscriptions.summary.withoutRP';

  const t = useTranslationBilling(translationKey);

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Button.Skeleton />
      </Card.Body>
    </Card>
  );
}

export default SummarySkeleton;
