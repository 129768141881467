import { Title } from '@nimbus-ds/components';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { backgrounds, BackgroundGalleryElement } from './backgrounds';
import './BackgroundGallery.scss';

interface BackgroundGalleryProps {
  selectedBackground?: BackgroundGalleryElement;
  onSelect: (background: BackgroundGalleryElement) => void;
}

function BackgroundGallery({
  selectedBackground,
  onSelect,
}: Readonly<BackgroundGalleryProps>) {
  const t = useTranslationCatalog();

  return (
    <Stack column spacing="tight" align="flex-start">
      <Title as="h6" color="neutral-textLow" textAlign="left">
        {t(
          'products.modal.editPhoto.actions.changeBackgroundImage.modal.label',
        )}
      </Title>
      <Stack gap="3" alignSelf="stretch" align="stretch" wrap>
        {backgrounds.map((background) => (
          <div
            key={uuidv4()}
            onClick={() => onSelect(background)}
            className={classNames('stratus--background-gallery-tile', {
              '--selected': selectedBackground === background,
            })}
            style={{ backgroundImage: `url(${background.thumbnailUrl})` }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default BackgroundGallery;
