import { useEffect } from 'react';
import { ErrorState } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import VirtualDataList from 'commons/components/VirtualDataList';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  TransactionListItem,
  TransactionsResultsMobileSkeleton,
} from './components';
import { useTransactionsList } from '../../hooks';

export interface TransactionListMobileProps {
  split: boolean;
  paymentId?: string;
}

function TransactionListMobile({
  split,
  paymentId,
}: TransactionListMobileProps): JSX.Element {
  const t = useTranslationBilling();
  const { isMobile } = useResponsive();

  const {
    isLoading,
    isError,
    transactionIds,
    refreshTransactions,
    getMoreTransactions,
    goToPage,
  } = useTransactionsList({ split, paymentId });

  const isEmptyList = transactionIds.length === 0;
  const shouldShowSpinner = isLoading && !isEmptyList;

  useEffect(() => {
    if (isMobile) {
      goToPage(1);
    }
  }, [isMobile, goToPage]);

  if (isLoading && isEmptyList) return <TransactionsResultsMobileSkeleton />;
  if (isError && isEmptyList)
    return (
      <ErrorState
        title={t('transactionFeesList.errorResults.title')}
        action={{
          children: t('transactionFeesList.errorResults.action'),
          onClick: refreshTransactions,
        }}
      />
    );

  return (
    <VirtualDataList
      onRefresh={refreshTransactions}
      isPullable
      data={transactionIds}
      endReached={getMoreTransactions}
      itemContent={(index, id) => <TransactionListItem key={index} id={id} />}
      showFooter={shouldShowSpinner}
    />
  );
}

export default TransactionListMobile;
