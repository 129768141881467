import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  getProvincesData,
  getProvincesStatus,
  fetchProvinces as fetchProvincesAction,
} from '../../addressesSlice';

function useGetProvinces() {
  const country = useGetCountry();
  const dispatch = useAppDispatch();
  const { isLoading, isError } = useSelector(getProvincesStatus);
  const provinces = useSelector(getProvincesData);

  const fetchProvinces = useCallback(async () => {
    await dispatch(fetchProvincesAction(country));
  }, [country, dispatch]);

  return {
    isLoading,
    isError,
    provinces,
    fetchProvinces,
  };
}

export default useGetProvinces;
