import { Box } from '@nimbus-ds/components';
import { ConceptCode, CurrencyType } from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ChargeDataRows, TaxAlert } from './components';
import { getTaxesSummary } from './utils';

export interface ChargeDisplayInterface {
  description: string;
  amountValue: number;
  amountBaseValue?: number;
  amountCurrency: CurrencyType;
  appliedDiscounts?: {
    description: string;
    amountValue: number;
    amountCurrency: CurrencyType;
    rate: number;
    type?: string;
  }[];
  taxes?: {
    taxCode: string;
    amountValue: number;
    amountCurrency: CurrencyType;
    taxRate: number;
  }[];
  recurringInterval?: number;
  recurringFrequency?: string;
  explanation?: {
    message: string;
    link: string;
  };
  conceptCode: ConceptCode;
  fromDate?: string;
  toDate?: string;
  isProportional?: boolean;
}

interface PaymentDataContentProps {
  showTaxes?: boolean;
  charges: ChargeDisplayInterface[];
}

function PaymentDataContent({
  showTaxes = false,
  charges,
}: PaymentDataContentProps): JSX.Element {
  const t = useTranslationBilling('checkout.paymentDataContent');

  return (
    <Box display="flex" flexDirection="column" gap="4">
      {charges &&
        charges.map((charge) => (
          <ChargeDataRows key={charge.description} charge={charge} />
        ))}
      {showTaxes ? (
        getTaxesSummary(charges, t).map((tax) => (
          <ChargeDataRows key={tax.description} charge={tax} />
        ))
      ) : (
        <TaxAlert />
      )}
    </Box>
  );
}

export default PaymentDataContent;
