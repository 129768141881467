import { Text, Modal } from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useToast } from 'commons/hooks';
import { useModalConformationActionResult } from './hooks/useModalConfirmationAction';

interface ModalConfirmationActionProps
  extends useModalConformationActionResult {
  appearance?: 'primary' | 'secondary' | 'danger' | 'default';
  onClose: () => void;
  onAction: () => Promise<void>;
}

function ModalConfirmationAction({
  title,
  body,
  btnOk,
  btnCancel,
  textProgress,
  textSuccess,
  textError,
  appearance = 'primary',
  onAction,
  onClose,
}: ModalConfirmationActionProps): JSX.Element {
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const handleAction = async () => {
    onClose();
    addToastProgress({
      label: textProgress,
    });
    try {
      await onAction();
      closeToastProgress();
      addToast({
        label: textSuccess,
        appearance: 'success',
      });
    } catch {
      closeToastProgress();
      addToast({
        label: textError,
        appearance: 'danger',
      });
    }
  };

  return (
    <Modal
      show
      title={title}
      onDismiss={onClose}
      primaryAction={{
        children: btnOk,
        onClick: handleAction,
        appearance,
      }}
      secondaryAction={{
        children: btnCancel,
        onClick: onClose,
      }}
    >
      <Text>{body}</Text>
    </Modal>
  );
}

export default ModalConfirmationAction;
