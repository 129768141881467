import { Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { Link, useLocation } from 'react-router-dom';
import { Option } from '@tiendanube/common';
import { TiendanubeIcon } from '@tiendanube/icons';
import { config } from 'App/components/ExternalServices/Amplitude/config';
import ShowByRole from 'App/components/ShowByRole';
import { useHandleMenuClick } from 'App/hooks';
import { useMenu } from 'App/settings/menu/hooks';
import { getIcon } from 'App/settings/menu/utils';
import { trackingMenuClick, trackingNuvempagoMenuClick } from 'App/tracking';
import { addOldAdminPrefix } from '../../../utils';

function NuvemPagoMenu() {
  const { pathname, hash } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const { menu } = useMenu();

  const clear = (str: string) => str.replace('#', '').replace(/\/$/, '');
  const isActive = (goTo: string, useHash = true): boolean => {
    const [path, fragment] = goTo.split('#').map(clear);
    const isSamePath = clear(pathname) === path;

    if (!useHash) return isSamePath;

    return isSamePath && clear(hash).startsWith(fragment);
  };
  const getButtonElement = (oldAdmin?: boolean) => (oldAdmin ? 'a' : Link);
  const getOptionHref = ({ oldAdmin, goTo }: Option) =>
    oldAdmin ? addOldAdminPrefix(goTo ?? '') : undefined;

  const { NuvemPago: nuvemPagoMenu, PagoNube: pagoNubeMenu } =
    menu?.Main.FinancialServicesPayments?.options ?? {};

  const nuvemPagoMenuIsActive =
    !!nuvemPagoMenu?.goTo && isActive(nuvemPagoMenu.goTo, false);
  const pagoNubeMenuIsActive =
    !!pagoNubeMenu?.goTo && isActive(pagoNubeMenu.goTo, false);

  const handlePagoNubeClick = () => {
    trackingNuvempagoMenuClick(pagoNubeMenu ? 'active' : 'inactive');
    handleClickItem();
  };

  const handleNuvemPagoClick = (option: Option) => () => {
    option.tracking &&
      trackingMenuClick({
        ...option.tracking,
        instanceName: config.nuvempagoInstanceName,
      });
    handleClickItem();
  };

  return (
    <ShowByRole includeRoles="nuvem_pago">
      <>
        {pagoNubeMenu && (
          <MenuButton
            as={Link}
            to={pagoNubeMenu.goTo ?? ''}
            startIcon={TiendanubeIcon}
            onClick={handlePagoNubeClick}
            active={pagoNubeMenuIsActive}
            label={pagoNubeMenu.label}
          >
            {!!pagoNubeMenu.tag && (
              <Tag appearance="primary">{pagoNubeMenu.tag}</Tag>
            )}
          </MenuButton>
        )}

        {nuvemPagoMenu &&
          (nuvemPagoMenu.options ? (
            <MenuButton.Accordion
              contentid={`${nuvemPagoMenu.key}-content`}
              menuButton={{
                id: `${nuvemPagoMenu.key}-control`,
                label: nuvemPagoMenu.label,
                startIcon: TiendanubeIcon,
                'aria-controls': `${nuvemPagoMenu.key}-content`,
              }}
              active={nuvemPagoMenuIsActive}
              open={nuvemPagoMenuIsActive ? true : undefined}
            >
              {Object.values(nuvemPagoMenu.options).map((option) => (
                <MenuButton
                  key={option.key}
                  as={getButtonElement(option.oldAdmin)}
                  to={option.goTo ?? ''}
                  href={getOptionHref(option)}
                  active={isActive(option.goTo ?? '')}
                  startIcon={option.icon ? getIcon(option.icon) : undefined}
                  label={option.label}
                  onClick={handleNuvemPagoClick(option)}
                >
                  {!!option.tag && <Tag appearance="primary">{option.tag}</Tag>}
                </MenuButton>
              ))}
            </MenuButton.Accordion>
          ) : (
            <MenuButton
              key={nuvemPagoMenu.key}
              as={getButtonElement(nuvemPagoMenu.oldAdmin)}
              to={nuvemPagoMenu.goTo ?? ''}
              href={getOptionHref(nuvemPagoMenu)}
              active={nuvemPagoMenuIsActive}
              startIcon={TiendanubeIcon}
              label={nuvemPagoMenu.label}
              onClick={handleClickItem}
            />
          ))}
      </>
    </ShowByRole>
  );
}

export default NuvemPagoMenu;
