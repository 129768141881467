import { ChangeEvent } from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { RadioOptionsSkeleton } from '../RadioOptionsSkeleton';

interface DiscountCombinationProps {
  values: { combinesWithOtherDiscounts: boolean };
  onChange: ({ name, value }: InterfaceNameBooleanValue) => void;
  disabled?: boolean;
}

export function DiscountCombination({
  values: { combinesWithOtherDiscounts },
  onChange,
  disabled = false,
}: Readonly<DiscountCombinationProps>) {
  const t = useTranslationMarketing(
    'coupons.couponForm.limits.discountCombination',
  );
  return (
    <Checkbox
      name="combinesWithOtherDiscounts"
      label={t('label')}
      onChange={({
        target: { name, checked },
      }: ChangeEvent<HTMLInputElement>) => onChange({ name, value: checked })}
      checked={combinesWithOtherDiscounts}
      disabled={disabled}
    />
  );
}

DiscountCombination.Skeleton = RadioOptionsSkeleton;
