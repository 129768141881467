import { Modal } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent } from 'commons/components';
import { AuthenticationFactorVerifyAndAuthorizeCard } from 'domains/AuthenticationFactor/components';
import { useTranslationAuthenticationFactor } from '../../hooks';
import { AuthenticationFactorVerifyAndAuthorizeCardProps } from '../AuthenticationFactorVerifyAndAuthorizeCard/AuthenticationFactorVerifyAndAuthorizeCard';

interface AuthenticationFactorVerifyCodeAsideModalProps
  extends Omit<AuthenticationFactorVerifyAndAuthorizeCardProps, 'onSubmit'> {
  isOpen: boolean;
  type: 'aside' | 'centered';
  payload?: object;
}

function AuthenticationFactorVerifyCodeModal({
  isOpen,
  type,
  payload,
  onCancel,
  onIsValidCode,
}: AuthenticationFactorVerifyCodeAsideModalProps) {
  const t = useTranslationAuthenticationFactor(
    'authenticationFactorVerifyCodeCard',
  );
  const title = t('title');

  switch (type) {
    case 'aside':
      return (
        <ModalAside
          isOpen={isOpen}
          onDidDismiss={onCancel}
          headerContent={<HeaderContent title={title} />}
        >
          {isOpen && (
            <AuthenticationFactorVerifyAndAuthorizeCard
              payload={payload}
              onCancel={onCancel}
              onIsValidCode={onIsValidCode}
            />
          )}
        </ModalAside>
      );
    case 'centered':
      return (
        <Modal show={isOpen} onDismiss={onCancel} title={title}>
          {isOpen && (
            <AuthenticationFactorVerifyAndAuthorizeCard
              payload={payload}
              onCancel={onCancel}
              onIsValidCode={onIsValidCode}
            />
          )}
        </Modal>
      );
    default:
      return null;
  }
}

export default AuthenticationFactorVerifyCodeModal;
