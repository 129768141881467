import { MetafieldSelectedsOrErrorListV2Type } from '@tiendanube/common';

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export interface CategoriesMetafieldsInterface {
  data: MetafieldSelectedsOrErrorListV2Type;
  status: statusType;
}
