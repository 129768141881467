import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import {
  useGetAllLanguages,
  useGetLanguage,
  useHasShippingMultiCD,
} from 'domains/Auth/hooks';
import { createGetProductById } from 'domains/Catalog/Products/productsSlice/productSelectors';
import { productDigest } from '../../digests';

function useDigestedProduct(id: string) {
  const hasShippingMultiCD = useHasShippingMultiCD();
  const language = useGetLanguage();
  const languages = useGetAllLanguages();
  const getProductById = useMemo(createGetProductById, []);

  const product = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );

  const productMemo = useMemo(
    () =>
      product &&
      productDigest(product, language, languages, hasShippingMultiCD),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [product, language, hasShippingMultiCD],
  );

  return productMemo;
}

export default useDigestedProduct;
