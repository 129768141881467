import { IconButton, Table, Text } from '@nimbus-ds/components';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';

function LocationsListBodySkeleton(): JSX.Element | null {
  const { Row, Cell, Body } = Table;
  const hasShippingLocationTag = useHasShippingLocationTag();

  return (
    <Body>
      <Row>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        {hasShippingLocationTag && (
          <Cell padding="base">
            <Text.Skeleton width="80%" />
          </Cell>
        )}
        <Cell padding="small">
          <IconButton.Skeleton />
        </Cell>
      </Row>
      <Row>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        {hasShippingLocationTag && (
          <Cell padding="base">
            <Text.Skeleton width="80%" />
          </Cell>
        )}
        <Cell padding="small">
          <IconButton.Skeleton />
        </Cell>
      </Row>
      <Row>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        <Cell padding="base">
          <Text.Skeleton width="80%" />
        </Cell>
        {hasShippingLocationTag && (
          <Cell padding="base">
            <Text.Skeleton width="80%" />
          </Cell>
        )}
        <Cell padding="small">
          <IconButton.Skeleton />
        </Cell>
      </Row>
    </Body>
  );
}

export default LocationsListBodySkeleton;
