import { useCallback } from 'react';
import { FEATURE_ONLY_LAYOUT_DEFAULT } from 'App/features';
import { useNavegate } from 'App/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import { useThemesRequireUpsell } from './useThemesRequireUpsell';

interface UseUpsellFromThemesProps {
  title: string;
  callback?: () => void;
  trackingSource?: string;
  asAside?: boolean;
  confirmationTextCta?: string;
}

export function useUpsellFromThemes({
  title,
  callback,
  asAside,
  trackingSource,
  confirmationTextCta,
}: UseUpsellFromThemesProps) {
  const { replace } = useNavegate();
  const requireUpsell = useThemesRequireUpsell();
  const handleCloseModal = useCallback(() => {
    replace(THEMES_ROUTES.themes);
  }, [replace]);

  const initUpsellFlow = useUpsellFlow({
    title,
    featureKey: FEATURE_ONLY_LAYOUT_DEFAULT,
    minValue: 1,
    asAside,
    trackingSource,
    onCloseModal: handleCloseModal,
    callback,
    confirmationTextCta,
  });

  return {
    initUpsellFlow,
    requireUpsell,
  };
}
