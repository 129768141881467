import { StatusType } from 'commons/types';
import { DashboardSchema } from './types';

const DEFAULT_VALUES = {
  status: 'idle' as StatusType,
};

export const CLOUDFLARE_BLOCK_DISMISS_EXPIRATION_TTL_IN_SECONDS = 21600; // 6hs

export const initialState: DashboardSchema = {
  firstSale: {
    get: DEFAULT_VALUES,
    markFirstSaleReal: DEFAULT_VALUES,
  },
  onboarding: DEFAULT_VALUES,
  users: {
    forwardConfirmationEmail: DEFAULT_VALUES,
  },
  trialMessages: DEFAULT_VALUES,
  paymentMessages: {
    mercadopago: {
      credentials: {
        removeNotification: DEFAULT_VALUES,
        updateCredentials: DEFAULT_VALUES,
      },
      verify: {
        status: 'idle',
      },
    },
    pagSeguro: DEFAULT_VALUES,
  },
  billingPlan: {
    updateToFreePlan: {
      status: 'idle',
    },
  },
  socialMessages: {
    facebookConnection: {
      status: 'idle',
    },
  },
};
