import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'domains/Billing/utils';

export default Yup.object().shape({
  zipcode: Yup.string()
    .required('invoicesInfoPage.address.errors.zipcode')
    .test(
      'length',
      'invoicesInfoPage.address.errors.zipcode',
      (value) => value?.length === 8,
    ),
  province: Yup.string().required(),
  city: Yup.string().required(),
  neighborhood: Yup.string()
    .test(
      'neighborhoodRequired',
      'invoicesInfoPage.address.errors.neighborhood',
      (_, obj) => !obj.parent.province || obj.parent.neighborhood,
    )
    .nullable(),
  street: Yup.string()
    .test(
      'streetRequired',
      'invoicesInfoPage.address.errors.street',
      (_, obj) => !obj.parent.province || obj.parent.street,
    )
    .nullable(),
  number: Yup.number()
    .typeError('invoicesInfoPage.address.errors.numberInvalid')
    .required('invoicesInfoPage.address.errors.number'),
  complement: Yup.string().nullable(),
  name: Yup.string().required('invoicesInfoPage.errors.name'),
  stateRegistrationOption: Yup.string(),
  stateRegistration: Yup.string()
    .when('stateRegistrationOption', {
      is: (value: string) => value === 'hasStateRegistration',
      then: Yup.string().required('invoicesInfoPage.errors.stateRegistration'),
    })
    .nullable(),
  numberId: Yup.string()
    .typeError('invoicesInfoPage.errors.numberId')
    .required('invoicesInfoPage.errors.numberId')
    .test(
      'is-valid-id',
      'invoicesInfoPage.errors.numberIdInvalid',
      function (value) {
        if (typeof value !== 'string') return false;
        const length = value.length;
        if (length !== 11 && length !== 14) {
          return this.createError({
            message: 'invoicesInfoPage.errors.numberIdLength',
          });
        }
        if (length === 14) {
          return (
            validateCNPJ(value) ||
            this.createError({
              message: 'invoicesInfoPage.errors.numberIdInvalid',
            })
          );
        }
        if (length === 11) {
          return (
            validateCPF(value) ||
            this.createError({
              message: 'invoicesInfoPage.errors.numberIdInvalid',
            })
          );
        }
        return false;
      },
    ),
});
