import { ChangeEvent } from 'react';
import { Prompt } from 'react-router';
import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import { UserRolesType } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  DataCard,
  PasswordCard,
  RolesCard,
  UserFormPageError,
} from '../../components';
import UserFormPageSkeleton from '../UserFormPageSkeleton';

interface UserFormProps {
  isMainUser?: boolean;
  isLoading: boolean;
  isError: boolean;
  isDirty: boolean;
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors: Partial<Record<string, any>>;
  submitStatus: string;
  storeRoles: UserRolesType | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (name: string, value: any) => void;
  onRefetch: () => void;
  onSubmit: () => void;
  onValidate: (onValid: () => void) => Promise<void>;
  isCurrentUser?: boolean;
}

function UserForm({
  isMainUser = false,
  isLoading,
  isError,
  isDirty,
  values,
  errors,
  requestErrors,
  submitStatus,
  storeRoles,
  onChange,
  onChangeRole,
  onRefetch,
  onSubmit,
  onValidate,
  isCurrentUser = false,
}: Readonly<UserFormProps>) {
  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const showRolesCard = !isMainUser && !isCurrentUser;

  if (isLoading) return <UserFormPageSkeleton showRolesCard={showRolesCard} />;
  if (isError || !storeRoles) return <UserFormPageError refetch={onRefetch} />;
  return (
    <>
      <Prompt
        when={submitStatus === 'idle' && isDirty}
        message={t('exitPrompt')}
      />
      <DataCard
        values={values}
        errors={errors}
        requestErrors={requestErrors}
        onChange={onChange}
      />
      {showRolesCard && (
        <RolesCard
          roles={storeRoles}
          values={values}
          errors={errors}
          onChangeRole={onChangeRole}
        />
      )}
      <PasswordCard
        values={values}
        errors={errors}
        requestErrors={requestErrors}
        onChange={onChange}
      />
      <CancelAndSaveButtonsWithAuth
        saveText={t('confirmEdit')}
        typeModal="centered"
        payload={values}
        onSave={onSubmit}
        preSubmitValidation={onValidate}
        onCancel={goBack}
        isDisabled={!isDirty}
        isLoading={submitStatus === 'loading'}
      />
    </>
  );
}

export default UserForm;
