import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useLocationById from 'domains/Shipping/Locations/hooks/useLocation/useLocation';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface SetLocationMainModalProps {
  locationId: string;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
function SetLocationMainModal({
  locationId,
  isLoading,
  onClose,
  onConfirm,
}: Readonly<SetLocationMainModalProps>) {
  const t = useTranslationShipping();
  const { location } = useLocationById(locationId);

  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header
        title={t('locations.setAsMain.title', { name: location.name })}
      />
      <Modal.Body padding="none">
        <Text>{t('locations.setAsMain.description')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Stack spacing="base" justify="flex-end">
          <CancelAndConfirmButtons
            onCancel={onClose}
            onConfirm={onConfirm}
            isConfirmDisabled={isLoading}
            isCancelDisabled={isLoading}
            confirmText={t('locations.setAsMain.confirm')}
          />
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
export default SetLocationMainModal;
