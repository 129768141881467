import { UserSessionDto } from '@tiendanube/common';
import { useGetUserSessionsListDesktop } from './useGetUserSessionsListDesktop';
import { useGetUserSessionsListMobile } from './useGetUserSessionsListMobile';
import { useResponsive } from '../../../../../commons/components';

export function useGetUserSessions(): UserSessionDto[] {
  const { isMobile } = useResponsive();
  const { data: desktopResult } = useGetUserSessionsListDesktop();
  const { data: mobileResult } = useGetUserSessionsListMobile();

  return isMobile ? mobileResult : desktopResult;
}
