import { useCallback, useEffect } from 'react';
import isEqual from 'lodash.isequal';
import { getStorage } from 'App/storage';
import { FiltersStatisticsInterface } from 'domains/Statistics/types';
import { KEY_STORAGE_FILTERS } from 'domains/Statistics/utils';
import useStatisticsFilters, {
  UseStatisticsFiltersResult,
} from '../useStatisticsFilters';

function useStatisticsStoredFilters(
  fetchStatisticsCallback: () => void,
): UseStatisticsFiltersResult {
  const {
    statisticsFilters,
    handleOnChangeFilters,
    hasComparedFilter,
    hasAppliedFilters,
  } = useStatisticsFilters();

  const restoreSavedFilters = useCallback(async () => {
    const savedFilters = await getStorage<FiltersStatisticsInterface>(
      KEY_STORAGE_FILTERS,
    );
    if (savedFilters && !isEqual(savedFilters, statisticsFilters)) {
      handleOnChangeFilters(savedFilters);
    } else {
      fetchStatisticsCallback();
    }
  }, [statisticsFilters, handleOnChangeFilters, fetchStatisticsCallback]);

  useEffect(() => {
    restoreSavedFilters();
  }, [restoreSavedFilters]);

  return {
    statisticsFilters,
    handleOnChangeFilters,
    hasComparedFilter,
    hasAppliedFilters,
  };
}

export default useStatisticsStoredFilters;
