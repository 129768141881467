import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Link } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import { GetNotificationResponseDto } from '@tiendanube/common';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import AlertNotification from './components/AlertNotification';
import ModalNotification from './components/ModalNotification';
import { notificationActionStyle } from './constants';
import usePaymentNotification from './hooks/usePaymentNotification';
import {
  NotificationComponentProps,
  NotificationPresentationType,
  NotificationStyleType,
  NotificationTypes,
} from './types';
import useTranslationPayments from '../useTranslationPayments';

const isAlert = (presentationType?: NotificationPresentationType) =>
  presentationType === NotificationPresentationType.Alert;

const isModal = (presentationType?: NotificationPresentationType) =>
  presentationType === NotificationPresentationType.Modal;

const getComponent = ({ presentationType }: GetNotificationResponseDto) =>
  isModal(presentationType) ? ModalNotification : AlertNotification;

type ActionUrlArgs = Pick<
  GetNotificationResponseDto,
  | 'linkName'
  | 'linkUrl'
  | 'linkTag'
  | 'hasInternalUrl'
  | 'deleteOnClose'
  | 'deleteOnRefresh'
> & { linkStyle?: NotificationStyleType };

type NotificationRendererProps = GetNotificationResponseDto & {
  removeNotification: (id: string) => Promise<any>;
  component?: React.VFC<NotificationComponentProps>;
};

const TRANSLATE_PREFIX = 'paymentNotification';

function NotificationRenderer({
  title,
  id,
  type,
  style,
  message,
  linkName,
  linkUrl,
  linkTag,
  hasInternalUrl,
  deleteOnClose,
  deleteOnRefresh,
  metadata,
  messageCode,
  titleCode,
  removeNotification,
  deletable = true,
  presentationType = NotificationPresentationType.Alert,
  component: Component = AlertNotification,
}: NotificationRendererProps) {
  const [showNotification, setShowNotification] = useState(true);
  const storeInfo = useStoreInfo();
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const history = useHistory();

  const deleteNotificationOnClose = useCallback(async () => {
    setShowNotification(false);

    try {
      if (!deleteOnClose) return;
      await removeNotification(id);
    } catch (error) {
      /* empty */
    }
  }, [id, removeNotification, deleteOnClose]);

  const actionUrl = useCallback(
    ({ linkName, linkUrl, linkStyle, hasInternalUrl }: ActionUrlArgs) => {
      if (linkName && linkUrl) {
        const isInternal = hasInternalUrl ?? linkUrl.includes(storeInfo.url);

        const handleLinkClick = () =>
          isInternal
            ? history.push(linkUrl ?? '')
            : window.open(linkUrl, '_blank');

        if (isInternal) {
          try {
            const url = new URL(linkUrl ?? '');
            linkUrl = `${url.pathname}${url.hash}`;
          } catch (_) {
            /* empty */
          }

          return (
            <Link
              as="a"
              onClick={handleLinkClick}
              appearance={linkStyle && notificationActionStyle[linkStyle]}
            >
              {linkName}
            </Link>
          );
        }
        return (
          <Button
            appearance={linkStyle && notificationActionStyle[linkStyle]}
            onClick={handleLinkClick}
          >
            {linkName}
          </Button>
        );
      } else if (linkName) {
        const handleLinkClick = () => {
          deleteNotificationOnClose();
        };
        return (
          <Button
            appearance={linkStyle && notificationActionStyle[linkStyle]}
            onClick={handleLinkClick}
          >
            {linkName}
          </Button>
        );
      }
    },
    [history, deleteNotificationOnClose, storeInfo.url],
  );

  const notificationMessage = useMemo(() => {
    if (messageCode) {
      return t(messageCode, { ...metadata?.messageMetadata });
    }

    return t(message);
  }, [message, messageCode, metadata?.messageMetadata, t]);

  const notificationTitle = useMemo(() => {
    if (titleCode) {
      return t(titleCode, { ...metadata?.titleMetadata });
    }

    return t(title);
  }, [metadata?.titleMetadata, t, title, titleCode]);

  useEffect(() => {
    async function deleteNotificationOnRefresh() {
      try {
        if (!deleteOnRefresh) return;
        await removeNotification(id);
      } catch (error) {
        /* empty */
      }
    }

    deleteNotificationOnRefresh();
  }, [
    deletable,
    id,
    presentationType,
    removeNotification,
    style,
    type,
    deleteOnRefresh,
  ]);

  return (
    <Component
      id={id}
      title={notificationTitle}
      message={notificationMessage}
      style={style}
      show={showNotification}
      onClose={deleteNotificationOnClose}
      action={actionUrl({
        linkName,
        linkUrl,
        linkTag,
        linkStyle: isModal(presentationType) ? style : undefined,
        hasInternalUrl,
        deleteOnClose,
        deleteOnRefresh,
      })}
    />
  );
}

interface InterfaceNotification {
  listeningToTypes?: NotificationTypes[];
}

function Notification({
  listeningToTypes,
}: InterfaceNotification): JSX.Element {
  const { notifications, fetchNotifications, deleteNotification } =
    usePaymentNotification();
  const [showNotifications, setShowNotifications] = useState<
    GetNotificationResponseDto[]
  >([]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    const filteredNotifications = notifications?.filter((notification) => {
      if (!listeningToTypes) return true;
      return listeningToTypes.includes(
        notification.type ?? NotificationTypes.NoType,
      );
    });

    const notificationsToRender = filteredNotifications.filter(
      ({ presentationType }) => isAlert(presentationType),
    );
    const firstModalNotification = filteredNotifications.find(
      ({ presentationType }) => isModal(presentationType),
    );

    if (firstModalNotification) {
      notificationsToRender.push(firstModalNotification);
    }

    setShowNotifications(notificationsToRender);
  }, [listeningToTypes, notifications]);

  return (
    <>
      {showNotifications.map((notification) => (
        <NotificationRenderer
          key={notification.id}
          {...notification}
          removeNotification={deleteNotification}
          component={getComponent(notification)}
        />
      ))}
    </>
  );
}

export default Notification;
