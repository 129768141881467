import { useEffect } from 'react';
import { useToastMessagesAction } from 'domains/Customers/hooks';
import { useBulkActions } from '../..';

export interface BulkActionsWithoutConfirmProps {
  bulkActions: Record<
    string,
    {
      textProgress: string;
      textSuccess: string;
      textError: string;
      handleAction: () => Promise<void>;
    }
  >;
}

function BulkActionsWithoutConfirm({
  bulkActions,
}: BulkActionsWithoutConfirmProps): null {
  const { selectedAction, clearBulkActions, toggleEditMode } = useBulkActions();

  const handleToast = useToastMessagesAction();

  useEffect(() => {
    if (selectedAction && Object.keys(bulkActions).includes(selectedAction)) {
      const { textProgress, textSuccess, textError, handleAction } =
        bulkActions[selectedAction];

      handleToast({
        textProgress,
        textSuccess,
        textError,
        handleAction,
      });
      toggleEditMode();
      clearBulkActions();
    }
  }, [
    clearBulkActions,
    bulkActions,
    handleToast,
    selectedAction,
    toggleEditMode,
  ]);

  return null;
}

export default BulkActionsWithoutConfirm;
