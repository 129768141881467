import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { TransactionFeesListInterface } from '../types';

export const getTransactionListState = (
  state: RootStateType,
): CombinedState<TransactionFeesListInterface> =>
  state.billing.transactionFees.transactionsList;

const getEntityById = (_: RootStateType, id: string) => id;

export const getTransactionFeesStatus = createSelector(
  getTransactionListState,
  (transactionFees) => ({
    isLoading: transactionFees.status === 'loading',
    isError: transactionFees.status === 'error',
    isSuccess: transactionFees.status === 'success',
  }),
);

export const getTransactionFeesIds = createSelector(
  getTransactionListState,
  (transactionFees) => transactionFees.ids,
);

export const getTransactionFeesPagination = createSelector(
  getTransactionListState,
  (transactionFees) => transactionFees.pagination,
);

export const getTransactionListEntities = createSelector(
  getTransactionListState,
  (transactionFees) => transactionFees.entities,
);

export const getTransactionListItemById = createSelector(
  getTransactionListEntities,
  getEntityById,
  (entities, id) => entities[id],
);
