import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SuggestionsDto, UnsubscribeStoreRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  deleteAccount as deleteAccountAction,
  fetchShopCancellation as fetchShopCancellationAction,
} from 'domains/Account/accountSlice';
import {
  deleteAccountStatus,
  fetchShopCancellationStatus,
  shopCancellationSelector,
} from 'domains/Account/accountSlice/accountSelectors';

export interface RecommendationValueInterface {
  label: string;
  value: string;
}
export interface ReasonsInterface {
  selected?: RecommendationValueInterface;
  reasons?: RecommendationValueInterface[];
}

export interface SubReasonsInterface {
  selected?: RecommendationValueInterface;
  subReasons?: {
    default: string | null;
    values: RecommendationValueInterface[] | null;
  };
}
interface UseShopCancellationResult {
  reasons: ReasonsInterface | null;
  subReasons: SubReasonsInterface | null;
  suggestions?: SuggestionsDto | null;
  fetchShopCancellation: () => void;
  setReason: (label: string) => void;
  setSubReason: (label: string) => void;
  deleteAccount: (arg: UnsubscribeStoreRequestDto) => void;
  deleteStatus: 'idle' | 'loading' | 'success' | 'error';
  getStatus: 'idle' | 'loading' | 'success' | 'error';
}

function useShopCancellation(): UseShopCancellationResult {
  const { deleteStatus } = useSelector(deleteAccountStatus);
  const { getStatus } = useSelector(fetchShopCancellationStatus);
  const { shopCancellation } = useSelector(shopCancellationSelector);

  const dispatch = useAppDispatch();

  const deleteAccount = (arg: UnsubscribeStoreRequestDto) => {
    dispatch(deleteAccountAction(arg));
  };

  const [reasons, setReasons] = useState<ReasonsInterface | null>(null);
  const [subReasons, setSubReasons] = useState<SubReasonsInterface | null>(
    null,
  );
  const [suggestions, setSuggestions] = useState<
    SuggestionsDto | null | undefined
  >(null);

  useEffect(() => {
    if (shopCancellation) {
      setReasons({
        reasons: shopCancellation.map(({ code, description }) => ({
          label: description,
          value: code,
        })),
      });
    }
  }, [shopCancellation]);

  const fetchShopCancellation = useCallback(() => {
    if (shopCancellation.length === 0) {
      dispatch(fetchShopCancellationAction());
    }
  }, [dispatch, shopCancellation]);

  const setReason = (value: string) => {
    const selecteReason = shopCancellation.find(
      (reason) => reason.code === value,
    );
    if (selecteReason) {
      const { options, code, description, optionDefault, suggestions } =
        selecteReason;
      setReasons((state) => ({
        ...state,
        selected: { value: code, label: description },
      }));
      setSubReasons({
        subReasons: {
          default: optionDefault,
          values:
            options?.map(({ description, code }) => ({
              label: description,
              value: code,
            })) || null,
        },
      });
      setSuggestions(suggestions);
    }
  };

  const setSubReason = (value: string) => {
    const subReason = shopCancellation
      .find((reason) => reason.code === reasons?.selected?.value)
      ?.options?.find((subReason) => subReason.code === value);
    if (subReason) {
      const { code, description, suggestions } = subReason;
      setSubReasons((state) => ({
        ...state,
        selected: { label: description, value: code },
      }));
      setSuggestions(suggestions);
    }
  };

  return {
    fetchShopCancellation,
    reasons,
    subReasons,
    suggestions,
    setSubReason,
    setReason,
    deleteAccount,
    deleteStatus,
    getStatus,
  };
}

export default useShopCancellation;
