import { useEffect } from 'react';
import { DataTable, ErrorState } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus, useResponsive } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ResultHeaderDesktop } from './components';
import ResultRowDesktop from './components/ResultRowDesktop';
import ResultsSkeleton from './components/ResultsSkeleton';
import { useTransactionsList } from '../../hooks';

export interface TransactionListDesktopProps {
  split: boolean;
  paymentId?: string;
}

function TransactionListDesktop({
  split,
  paymentId,
}: TransactionListDesktopProps): JSX.Element {
  const t = useTranslationBilling();
  const { scrollToTop } = useLayoutScroll();
  const { isDesktop } = useResponsive();
  const {
    isLoading,
    isError,
    transactionIds,
    pagination,
    goToPage,
    refreshTransactions,
  } = useTransactionsList({ split, paymentId });

  const isEmptyList = transactionIds.length === 0;

  useEffect(() => {
    if (isDesktop) {
      goToPage(1);
    }
  }, [isDesktop, goToPage]);

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  if (isError && isEmptyList)
    return (
      <ErrorState
        title={t('transactionFeesList.errorResults.title')}
        action={{
          children: t('transactionFeesList.errorResults.action'),
          onClick: refreshTransactions,
        }}
      />
    );

  return (
    <>
      <DataTable>
        <ResultHeaderDesktop />
        {isLoading ? (
          <ResultsSkeleton />
        ) : (
          transactionIds.map((id) => <ResultRowDesktop key={id} id={id} />)
        )}
      </DataTable>
      {!isLoading && (
        <PaginationStratus
          currentPage={pagination.currentPage}
          totalItems={pagination.totalResults}
          itemName={t('transactionFeesList.pagination.items')}
          page={pagination.currentPage}
          perPage={20}
          onPageSelect={handleSelectPage}
        />
      )}
    </>
  );
}

export default TransactionListDesktop;
