import { KeyboardEvent } from 'react';
import { KeyboardEventKey } from 'commons/types';

export default function callWhenPress(
  keyObserved: KeyboardEventKey,
  fn: () => void,
) {
  return function ({ key }: KeyboardEvent<HTMLInputElement>) {
    if (keyObserved === key) fn();
  };
}
