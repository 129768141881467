import { Route, Switch } from 'react-router-dom';
import { MESSAGES_ROUTES } from './messagesRoutes';
import { MessagesListPage } from './pages';

function Messages(): JSX.Element {
  return (
    <Switch>
      <Route exact path={MESSAGES_ROUTES.messages}>
        <MessagesListPage />
      </Route>
    </Switch>
  );
}

export default Messages;
