import { Icon, Tag, Text } from '@nimbus-ds/components';
import IconsShippingMethodTag from './IconsShippingMethodTag';
import { ActiveShippingMethodIconType } from './utils';

interface ActiveShippingMethodsTagProps {
  icon: ActiveShippingMethodIconType;
  text: string;
}

function ActiveShippingMethodsTag({
  text,
  icon,
}: ActiveShippingMethodsTagProps) {
  return (
    <Tag appearance="neutral">
      <Icon source={<IconsShippingMethodTag icon={icon} size={12} />} />
      <Text fontSize="caption">{text}</Text>
    </Tag>
  );
}

export default ActiveShippingMethodsTag;
