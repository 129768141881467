import { Alert } from '@nimbus-ds/components';
import { Format, dateFormat } from 'commons/utils/date';
import { useGetSubscriptions } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

export default function TrialStatusAlert() {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.trialStatusAlert',
  );
  const { subscriptionForPlan } = useGetSubscriptions();

  if (!subscriptionForPlan) return null;

  return (
    <Alert title={t('title')} appearance="primary">
      {t('message', {
        date: dateFormat(
          subscriptionForPlan.nextExecutionDate,
          Format.DAY_MONTH_LONG,
        ),
      })}
    </Alert>
  );
}
