import { useCallback } from 'react';
import { Clipboard } from '@capacitor/clipboard';

export interface CopyToClipboard {
  (text: string): Promise<boolean>;
}
function useClipboard(): CopyToClipboard {
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      await Clipboard.write({
        string: text,
      });

      return true;
    } catch (e) {
      return false;
    }
  }, []);

  return copyToClipboard;
}

export default useClipboard;
