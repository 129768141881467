import { useTranslation } from 'react-i18next';
import { Country, Domain } from '@tiendanube/common/src/enums';
import { Card, Input, Grid } from '@tiendanube/components';
import { IdentificationInput, InputPhone } from 'commons/components';
import IdentificationBrInput from 'commons/components/IdentificationBrInput/IdentificationBrInput';
import { useGetCountry } from 'domains/Auth/hooks';

export interface ClientDataValuesInterface {
  firstName: string;
  lastName: string;
  email: string;
  identification?: string;
  phone?: string;
}

export interface ClientDataErrorsInterface {
  firstName: string;
  lastName: string;
  email: string;
  identification?: string;
}

interface ClientDataProps {
  onChange: ({ value, name }: { value: string; name: string }) => void;
  errors: Partial<ClientDataErrorsInterface>;
  values: ClientDataValuesInterface;
}

function ClientData({
  values,
  onChange,
  errors,
}: ClientDataProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const storeCountry = useGetCountry();
  const isBrazil = storeCountry === Country.BR;

  return (
    <Card title={t('draftOrders.newDraftOrder.clientData.title')}>
      <Grid>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <Input
              name="firstName"
              value={values.firstName}
              onChange={onChange}
              label={t('draftOrders.newDraftOrder.clientData.name')}
              appearance={errors.firstName ? 'validation_error' : undefined}
              helpText={t(errors.firstName || '')}
            />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            <Input
              name="lastName"
              value={values.lastName}
              onChange={onChange}
              label={t('draftOrders.newDraftOrder.clientData.lastName')}
              appearance={errors.lastName ? 'validation_error' : undefined}
              helpText={t(errors.lastName || '')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Input
              name="email"
              value={values.email}
              onChange={onChange}
              label={t('draftOrders.newDraftOrder.clientData.email')}
              appearance={errors.email ? 'validation_error' : undefined}
              helpText={t(errors.email || '')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <InputPhone
              name="phone"
              value={values.phone}
              onChange={onChange}
              label={t('draftOrders.newDraftOrder.clientData.phone')}
            />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            {isBrazil ? (
              <IdentificationBrInput
                name="identification"
                value={values.identification}
                error={errors.identification}
                onChange={onChange}
              />
            ) : (
              <IdentificationInput
                name="identification"
                value={values.identification}
                error={errors.identification}
                onChange={onChange}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Card>
  );
}

export default ClientData;
