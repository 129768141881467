import { Box, Input } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { CancelAndConfirmButtons, Stack } from 'commons/components';

function CitiesListSkeleton() {
  return (
    <Stack column align="flex-end" alignSelf="stretch">
      <Box paddingX="4" width="100%">
        <Input.Skeleton />
      </Box>
      <InteractiveList>
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
        <InteractiveList.CheckboxItemSkeleton title="" />
      </InteractiveList>
      <Box padding="4" width="100%">
        <CancelAndConfirmButtons.Skeleton />
      </Box>
    </Stack>
  );
}

export default CitiesListSkeleton;
