import { ChangeEvent } from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CombinesWithOtherDiscountsProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
}

export function CombinesWithOtherDiscounts({
  onChange,
  value,
}: Readonly<CombinesWithOtherDiscountsProps>) {
  const t = useTranslationMarketing('freeShipping.settingsPage.conditions');

  return (
    <Checkbox
      name="combinesWithOtherDiscounts"
      label={t('combinesWithOtherDiscounts')}
      onChange={onChange}
      checked={value}
    />
  );
}
