import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { getAddressesState } from 'domains/Shipping/Addresses/addressesSlice';
import { InterfaceShippingSchema } from '../types';

export const getShippingState = (
  state: RootStateType,
): CombinedState<InterfaceShippingSchema> => state.shipping.shippingMethods;

export const getCountriesOptions = createSelector(
  getAddressesState,
  (shipping) => {
    const countries = shipping.countries.data || {};
    const countriesOptions = Object.keys(countries).map((k) => ({
      label: countries[k],
      value: k,
    }));

    return {
      countries: countriesOptions,
      isLoading: shipping.countries.status === 'loading',
      isError: shipping.countries.status === 'error',
    };
  },
);

export const getProvincesByCountryOptions = createSelector(
  getShippingState,
  (shipping) => {
    const provinces = shipping.provinces.provinceList.map((province) => ({
      value: province.code,
      label: province.name,
    }));

    return {
      provinces: provinces,
      isLoading: shipping.provinces.status === 'loading',
      isError: shipping.provinces.status === 'error',
    };
  },
);
