import SortableTree from '@nosferatu500/react-sortable-tree';
import classNames from 'classnames';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import DndProvider from 'App/components/DndProvider';
import { useInfiniteScroll } from 'commons/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { CustomNode } from './CustomNode';
import Spinner from '../Spinner';
import '@nosferatu500/react-sortable-tree/style.css';
import './DraggableDataListNew.scss';

/**
 * This component should be used for drag & drop list thats needs virtualization (large lists).
 *
 * Limitations:
 * - no browser native search: it is not possible to use ctrl + f to search an item of the list.
 * - in windows, cannot use scroll wheel while dragging an item.
 *
 * If bowser native search or scroll wheel in windows is needed, use SortableDataList component instead.
 */

interface DraggableDataListNewProps<TItem> {
  data: TItem[];
  showFooter?: boolean;
  onChange: (newTree?: (TItem | undefined)[]) => void;
  endReached?: () => void;
  renderItem: (item: TItem, index: number) => JSX.Element;
}

const renderNode = (props) => <CustomNode {...props} />;

export function DraggableDataListNew<TItem>({
  data,
  onChange,
  renderItem,
  endReached,
  showFooter = false,
}: Readonly<DraggableDataListNewProps<TItem>>) {
  const targetID = useInfiniteScroll(endReached);
  const isMobileDevice = useIsMobileDevice();

  const containerClass = classNames('stratus--draggable-tree', {
    'stratus--draggable-tree__mobile': isMobileDevice,
  });

  return (
    <>
      <div className={containerClass}>
        <DndProvider>
          <SortableTree
            treeData={data}
            onChange={onChange}
            theme={FileExplorerTheme}
            maxDepth={0}
            canDrag={({ node }) => !node.noDragging}
            canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
            nodeContentRenderer={renderNode}
            generateNodeProps={(rowInfo) => ({
              title: renderItem(rowInfo.node, rowInfo.treeIndex),
            })}
            virtuosoProps={{
              useWindowScroll: true,
            }}
            overscan={{ main: 1000, reverse: 1000 }}
          />
        </DndProvider>
      </div>
      <div ref={targetID} />
      {showFooter && <Spinner show={showFooter} />}
    </>
  );
}
