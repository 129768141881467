import { ReactElement } from 'react';
import { Domain } from '@tiendanube/common/src/enums';
import ModalConfirmationAction, {
  useModalConfirmationAction,
} from 'commons/components/ModalConfirmationAction';
import { useModal } from 'commons/hooks';

export const TRANSLATION_PREFIX = {
  activate: 'modalActivate',
  deactivate: 'modalDeactivate',
  delete: 'modalDelete',
};

interface CouponModalConfirmationActionProps {
  children: (openModal: () => void) => ReactElement;
  action: 'activate' | 'deactivate' | 'delete';
  onAction: () => Promise<void>;
}

function CouponModalConfirmationAction({
  children,
  action,
  onAction,
}: Readonly<CouponModalConfirmationActionProps>) {
  const [showModal, openModal, closeModal] = useModal();
  const {
    title,
    body,
    btnOk,
    btnCancel,
    textProgress,
    textSuccess,
    textError,
  } = useModalConfirmationAction(
    Domain.MARKETING,
    `coupons.${TRANSLATION_PREFIX[action]}`,
  );

  return (
    <>
      {children(openModal)}
      {showModal && (
        <ModalConfirmationAction
          title={title}
          body={body}
          btnOk={btnOk}
          btnCancel={btnCancel}
          textProgress={textProgress}
          textSuccess={textSuccess}
          textError={textError}
          appearance={action === 'delete' ? 'danger' : 'primary'}
          onAction={onAction}
          onClose={closeModal}
        />
      )}
    </>
  );
}

export default CouponModalConfirmationAction;
