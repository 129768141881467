import { UpdateContentPagesRequestDto } from '@tiendanube/common';
import { LanguageNameValueInterface } from 'App/i18n';
import { SeoParametersCard, TitleAndContentCard } from './components';

export interface FormPageProps {
  values: UpdateContentPagesRequestDto;
  errors: Partial<Record<string, string>>;
  onChange: (e: LanguageNameValueInterface) => void;
}

function FormPage(props: FormPageProps) {
  const { values, onChange } = props;
  return (
    <>
      <TitleAndContentCard {...props} />
      <SeoParametersCard values={values} onChange={onChange} />
    </>
  );
}

export default FormPage;
