import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getRankProvincesByShippingTimeData,
  getRankProvincesByShippingTimeStatus,
} from 'domains/Statistics/selectors';
import { fetchRankProvincesByShippingTime as fetchRankProvincesByShippingTimeAction } from 'domains/Statistics/statisticsSlice';

function useGetRankProvincesByShippingTime() {
  const dispatch = useAppDispatch();
  const { openFilter } = useStatisticsFilterContext();
  const rankProvincesByShippingTime = useSelector(
    getRankProvincesByShippingTimeData,
  );
  const status = useSelector(getRankProvincesByShippingTimeStatus);

  const fetchRankProvincesByShippingTime = useCallback(() => {
    dispatch(fetchRankProvincesByShippingTimeAction());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchRankProvincesByShippingTime);

  return {
    fetchRankProvincesByShippingTime,
    rankProvincesByShippingTime,
    openFilter,
    ...status,
  };
}

export default useGetRankProvincesByShippingTime;
