import { Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from '@tiendanube/components';

function SuggestedCategoriesListSkeleton(): JSX.Element {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <DataList.Row key={index} gap="1">
          <Stack justify="space-between">
            <Text.Skeleton width="100%" />
          </Stack>
        </DataList.Row>
      ))}
    </>
  );
}

export default SuggestedCategoriesListSkeleton;
