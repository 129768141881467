import { useCallback } from 'react';
import { generatePath } from 'react-router';
import { ChargeDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import {
  usePostAdvancedChargeMutation,
  usePostCreatePayOrderMutation,
} from 'domains/Billing/Checkout/CheckoutApi';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';

export function useChargeCreation() {
  const { storeStatus } = useStoreStatus();
  const isChurned = storeStatus === 'store_down';
  const { isTrial } = useGetIsFreePlan();
  const { goTo } = useNavegate();

  const [advancePlanCharge, { status: advancePlanChargeStatus }] =
    usePostAdvancedChargeMutation();
  const [createPayOrder, { status: createPayOrderStatus }] =
    usePostCreatePayOrderMutation();

  const { isLoading, isError, status } = combineStatus(
    advancePlanChargeStatus,
    createPayOrderStatus,
  );

  const onContinue = useCallback(
    async (extraCharges?: ChargeDto[]) => {
      if (isChurned) {
        goTo(CHECKOUT_ROUTES.choosePaymentMethodForPlan);
        return;
      }
      if (isTrial) {
        const charge = await advancePlanCharge().unwrap();
        if (!charge) return;
        const extraChargesIds = extraCharges?.map(({ id }) => id) || [];
        const payOrder = await createPayOrder([
          charge.id,
          ...extraChargesIds,
        ]).unwrap();
        if (!payOrder) return;
        goTo(
          generatePath(CHECKOUT_ROUTES.choosePaymentMethod, {
            payOrderId: payOrder.id,
          }),
        );
      }
    },
    [isChurned, isTrial, advancePlanCharge, createPayOrder, goTo],
  );

  return { onContinue, isLoading, isError, status };
}
