import { useState } from 'react';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Link, Stack, Text, Chip } from '@tiendanube/components';
import { PlusCircleIcon, EditIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import {
  useHiddenCategories,
  useTrackFullCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductDetailCategoriesClick } from 'domains/Catalog/Products/tracking';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import { SelectCategories } from './components';

interface CategoriesProps {
  onChange: (categories: string[]) => void;
  selectedCategories: string[];
  isEdit?: boolean;
}

function Categories({
  onChange,
  selectedCategories,
  isEdit,
}: CategoriesProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const categories = useGetCategories();

  const { hasHiddenCategories } = useHiddenCategories();

  const sender = useTrackFullCatalog();

  const [showCategories, setShowCategories] = useState(false);

  const hasCategoriesSelected = !!selectedCategories.length;

  const Icon = !hasCategoriesSelected ? PlusCircleIcon : EditIcon;

  const openText = !hasCategoriesSelected
    ? t('products.detail.addCategories')
    : t('products.detail.editCategories');

  const handleHideCategores = () => {
    setShowCategories(false);
  };

  const handleChangeCategories = (categoriesIds: string[]) => {
    onChange(categoriesIds);
  };

  const handleRemoveCategory = (categoryId: string) => {
    onChange(selectedCategories.filter((category) => category !== categoryId));
  };

  const handleOnOpen = () => {
    sender(() => {
      trackingProductDetailCategoriesClick(isEdit);
    });
    setShowCategories(true);
  };

  return (
    <Card title={t('products.detail.categories')}>
      {!hasCategoriesSelected && (
        <>
          <Text>{`${t('products.detail.infoCategories')}`}</Text>
          <br />
        </>
      )}
      <Stack spacing="tight" wrap>
        {categories.map((category) => {
          const { id, title } = category;
          if (selectedCategories.includes(id)) {
            return (
              <Stack.Item key={id}>
                <Chip
                  id={id}
                  label={title}
                  onDismiss={handleRemoveCategory}
                  {...(!isCategoryVisible(category) &&
                    hasHiddenCategories && {
                      icon: EyeOffIcon,
                    })}
                />
              </Stack.Item>
            );
          }
          return null;
        })}
      </Stack>
      <br />
      <Link
        icon={Icon}
        iconPosition="start"
        appearance="primary"
        onClick={handleOnOpen}
      >
        {openText}
      </Link>
      <ModalAside
        isOpen={showCategories}
        onDidDismiss={handleHideCategores}
        headerContent={t('products.detail.categories')}
      >
        <SelectCategories
          selecteds={selectedCategories}
          onChange={handleChangeCategories}
        />
      </ModalAside>
    </Card>
  );
}

export default Categories;
