const BASE_URL = 'v1/statistics';

export const STATS_DAILY_URL = `${BASE_URL}/daily`;
export const STATS_PRODUCTS_URL = `${BASE_URL}/products`;
export const STATS_ORDERS_URL = `${BASE_URL}/orders`;
export const STATS_GLOBAL_INFO_URL = `${BASE_URL}/global-info`;
export const STATS_STORE_CONVERSION_URL = `${BASE_URL}/store-conversion`;
export const STATS_PRODUCTS_IN_NUMBER_URL = `${BASE_URL}/products-in-number`;
export const STATS_SUMMARY_URL = `${BASE_URL}/summary`;
export const STATS_FREE_TRIAL = `${BASE_URL}/free-trial`;
export const STATS_STORE_HAS_GA = `${BASE_URL}/store-has-ga`;
export const STATS_CUSTOMERS = `${BASE_URL}/customers`;
export const STATS_PAYMENTS = `${BASE_URL}/payments-performance`;
export const STATS_PRODUCTS_SUMMARY_URL = `${BASE_URL}/products-summary`;
export const STATS_ACQUISITION_SUMMARY_URL = `${BASE_URL}/acquisition-summary`;
export const STATS_SHIPPING_URL = `${BASE_URL}/shipping`;
export const STATS_SHIPPING_SUMMARY_URL = `${STATS_SHIPPING_URL}/summary`;
export const STATS_SHIPPING_SALES_BY_PROVINCE_URL = `${STATS_SHIPPING_URL}/sales-by-province`;
export const STATS_SHIPPING_REVENUE_BY_PROVINCE_URL = `${STATS_SHIPPING_URL}/revenue-by-province`;
export const STATS_SHIPPING_PROVINCES_BY_SHIPPING_COST = `${STATS_SHIPPING_URL}/provinces-by-shipping-cost`;
export const STATS_SHIPPING_PROVINCES_BY_SHIPPING_TIME = `${STATS_SHIPPING_URL}/provinces-by-shipping-time`;
