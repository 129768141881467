import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';

export type ReasonType =
  | 'orderNew'
  | 'orderCancelled'
  | 'orderReopened'
  | 'orderEdition'
  | 'productCreation'
  | 'stockEdition'
  | 'locationChange'
  | 'stockReserved'
  | 'stockFreed';

interface LabelReasonProps {
  reason: ReasonType;
}

const text: Record<ReasonType, string> = {
  orderNew: 'products.stockHistoryModal.reason.orderNew',
  orderCancelled: 'products.stockHistoryModal.reason.orderCanceled',
  orderReopened: 'products.stockHistoryModal.reason.orderReopened',
  productCreation: 'products.stockHistoryModal.reason.productCreation',
  stockEdition: 'products.stockHistoryModal.reason.stockEdition',
  locationChange: 'products.stockHistoryModal.reason.locationChange',
  orderEdition: 'products.stockHistoryModal.reason.orderEdition',
  stockReserved: 'products.stockHistoryModal.reason.stockReserved',
  stockFreed: 'products.stockHistoryModal.reason.stockFreed',
};

function LabelReason({ reason }: LabelReasonProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  return <Label id={reason} label={`${t(text[reason])}`} />;
}

export default LabelReason;
