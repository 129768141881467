import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getShippingRevenueByProvince = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.shippingRevenueByProvince,
);

export const getShippingRevenueByProvinceStatus = createSimpleSelector(
  [getShippingRevenueByProvince],
  (shippingRevenueByProvince) =>
    convertStatusTypeToObject(shippingRevenueByProvince.status),
);

export const getShippingRevenueByProvinceData = createSimpleSelector(
  [getShippingRevenueByProvince],
  (shippingRevenueByProvince) => shippingRevenueByProvince.data,
);
