import { useTranslation } from 'react-i18next';
import { Spinner, Stack, Text } from '@tiendanube/components';
import AppCenter from '../AppCenter';

function AppLoading(): JSX.Element {
  const { t } = useTranslation();
  const loading = t('apps.load.loading');
  return (
    <AppCenter>
      <Stack column spacing="tight">
        <Stack.Item>
          <Spinner size="large" />
        </Stack.Item>
        <Stack.Item>
          <Text bold>{loading}</Text>
        </Stack.Item>
      </Stack>
    </AppCenter>
  );
}

export default AppLoading;
