import { DataList } from '@tiendanube/components';
import ImageItemVariant from '../ImageItemVariant';

const { Row, Cell } = DataList;

function ContentProductItemListSkeleton(): JSX.Element {
  return (
    <Row id="id">
      <Cell width={80} rowTitle>
        <ImageItemVariant.Skeleton />
      </Cell>
      <Cell width={20} rowTitle />
    </Row>
  );
}

export default ContentProductItemListSkeleton;
