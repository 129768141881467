import { useCallback, useState } from 'react';
import { Alert, Text, Link, Icon, Box, Modal } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { LanguagesType } from 'App/i18n';
import { CancelAndConfirmButtons, TabsMultiLanguage } from 'commons/components';
import { useForm, useModal, useToastStatus } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useDuplicateAttributes } from 'domains/Catalog/Products/pages/components/Variants/hooks';
import { useProductDetails } from 'domains/Catalog/Products/pages/EditProductPage/hooks';
import useFixesAttributes from 'domains/Catalog/Products/pages/hooks/useFixesAttributes';
import { getErrorLanguages, trimmedAttributes } from '../../utils';
import DuplicateAttributesEdit from '../DuplicateAttributesEdit';

import './DuplicateAttributesEdit.scss';

interface DuplicateAttributesAlertProps {
  id: string;
}
function DuplicateAttributesAlert({ id }: DuplicateAttributesAlertProps) {
  const t = useTranslationCatalog(
    'products.detail.brokenVariantsAlert.duplicateAttributes',
  );
  const { withDuplicateAttributesSecondaryLanguage } =
    useDuplicateAttributes(id);
  const language = useGetLanguage();
  const { product } = useProductDetails(id);

  const [languageError, setLanguageError] = useState(language);
  const [showCancel, openCancel, closeCancel] = useModal();
  const {
    updateAttributes,
    initialValuesToFix,
    updateAttributesStatus,
    fetchProduct,
    isLoading,
  } = useFixesAttributes(product.id);

  const changeLanguageError = (language: LanguagesType) => {
    setLanguageError(language);
  };

  const { setFieldValue, handleOnSubmit, values } = useForm({
    initialValues: initialValuesToFix,
    onSubmit: async (values) => {
      const errorLanguage = getErrorLanguages(values);

      errorLanguage.length > 0 && changeLanguageError(errorLanguage[0]);
      const attributes = trimmedAttributes(values.attributes).filter(
        (_, index) => !values.toDelete.includes(index),
      );

      if (errorLanguage.length === 0) {
        await updateAttributes({
          id: product.id,
          fixAttributes: {
            ...values,
            attributes,
          },
        });
      }
    },
  });

  const textAlert = withDuplicateAttributesSecondaryLanguage
    ? t('altText')
    : t('text');

  const handleChange = (name: string, value: any) => {
    setFieldValue(name, value);
  };

  const handleOnError = useCallback(() => {
    closeCancel();
  }, [closeCancel]);

  const handleOnSave = useCallback(() => {
    closeCancel();
    fetchProduct();
  }, [closeCancel, fetchProduct]);

  useToastStatus({
    status: updateAttributesStatus,
    error: t('toastError'),
    success: t('toastSuccess'),
    onSuccess: handleOnSave,
    onError: handleOnError,
  });

  return (
    <>
      <Alert show title={t('title')} appearance="warning">
        <Text>{textAlert}</Text>
        <Box display="flex" gap="1">
          <Link onClick={openCancel}>
            <Icon color="currentColor" source={<EditIcon size={16} />} />
            {t('linkEdit')}
          </Link>
        </Box>
      </Alert>
      <Modal open={showCancel} onDismiss={closeCancel}>
        <Modal.Header title={t('modalTitle')} />
        <Modal.Body padding="none">
          <Box display="flex" flexDirection="column" gap="6">
            <Box>
              <Text>{t('modalDescription')}</Text>
            </Box>
            <div className="stratus--duplicate-attributes-edit-spacing">
              <TabsMultiLanguage initialLanguage={languageError}>
                <DuplicateAttributesEdit
                  values={values}
                  setFieldValue={handleChange}
                  variants={product.variants}
                />
              </TabsMultiLanguage>
            </div>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <CancelAndConfirmButtons
            onCancel={closeCancel}
            onConfirm={handleOnSubmit}
            cancelText={t('modalCancel')}
            confirmText={t('modalSave')}
            isLoading={isLoading}
            isCancelDisabled={isLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DuplicateAttributesAlert;
