import {
  FreeShippingAvailableResponseDto,
  FreeShippingItemListResponseDto,
  FreeShippingOptionsResponseDto,
  FreeShippingRequestDto,
  FreeShippingResponseDto,
  FreeShippingZoneResponseDto,
  ResultPaginationResponseDto,
} from '@tiendanube/common';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { cirrusApi, infinityBehavior, tableBehavior } from 'App/rtk';
import { GenericParamsType } from 'commons/types';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  FreeShippingTags,
  FreeShippingEndpoints,
  FREE_SHIPPING_AVAILABLE_URL,
  FREE_SHIPPING_STATUS_URL,
  FREE_SHIPPING_ID_URL,
  BASE_FREE_SHIPPING_URL,
  FREE_SHIPPING_OPTIONS_URL,
  FREE_SHIPPING_ZONES_URL,
  FREE_SHIPPING_SUBZONES_URL,
} from './settings';
import { FreeShippingFormState } from '../types';

export const freeShippingApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [FreeShippingTags.freeShippingsAvailable],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [FreeShippingEndpoints.getFreeShippingAvailable]: builder.query<
        FreeShippingAvailableResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: FREE_SHIPPING_AVAILABLE_URL,
        }),
        providesTags: [FreeShippingTags.freeShippingsAvailable],
      }),
      [FreeShippingEndpoints.getFreeShippingsList]: builder.query<
        ResultPaginationResponseDto<FreeShippingItemListResponseDto[]>,
        GenericParamsType
      >({
        query: (params) => ({
          method: 'GET',
          url: BASE_FREE_SHIPPING_URL,
          params,
        }),
        ...tableBehavior(FreeShippingEndpoints.getFreeShippingsList),
      }),
      [FreeShippingEndpoints.getFreeShippingsListInf]: builder.query<
        ResultPaginationResponseDto<FreeShippingItemListResponseDto[]>,
        GenericParamsType
      >({
        query: (params) => ({
          method: 'GET',
          url: BASE_FREE_SHIPPING_URL,
          params,
        }),
        ...infinityBehavior(FreeShippingEndpoints.getFreeShippingsListInf),
      }),
      [FreeShippingEndpoints.setFreeShippingStatus]: builder.mutation<
        void,
        { id: string; status: boolean }
      >({
        query: ({ id, status }) => ({
          method: 'PATCH',
          url: getUrlWithParams(FREE_SHIPPING_STATUS_URL, {
            id,
            status: status ? 'enable' : 'disable',
          }),
        }),
        onQueryStarted({ id, status }, { dispatch, queryFulfilled }) {
          const endpoint =
            window.innerWidth > MOBILE_BREAKPOINT
              ? FreeShippingEndpoints.getFreeShippingsList
              : FreeShippingEndpoints.getFreeShippingsListInf;

          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                endpoint as never,
                undefined as never,
                (
                  draft: ResultPaginationResponseDto<
                    FreeShippingItemListResponseDto[]
                  >,
                ) => {
                  const found = draft.results.find(
                    (current) => current.id === id,
                  );
                  if (found) found.enable = status;
                },
              ),
            );
          });
        },
      }),
      [FreeShippingEndpoints.deleteFreeShipping]: builder.mutation<
        void,
        string
      >({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(FREE_SHIPPING_ID_URL, { id }),
        }),
        onQueryStarted(id, { dispatch, queryFulfilled }) {
          const endpoint =
            window.innerWidth > MOBILE_BREAKPOINT
              ? FreeShippingEndpoints.getFreeShippingsList
              : FreeShippingEndpoints.getFreeShippingsListInf;

          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                endpoint as never,
                undefined as never,
                (
                  draft: ResultPaginationResponseDto<
                    FreeShippingItemListResponseDto[]
                  >,
                ) => {
                  draft.results = draft.results.filter(
                    ({ id: currentId }) => currentId !== id,
                  );
                },
              ),
            );
          });
        },
      }),
      [FreeShippingEndpoints.getFreeShippingsOptions]: builder.query<
        FreeShippingOptionsResponseDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: FREE_SHIPPING_OPTIONS_URL,
        }),
      }),
      [FreeShippingEndpoints.getFreeShippingsZones]: builder.query<
        FreeShippingZoneResponseDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: FREE_SHIPPING_ZONES_URL,
        }),
        keepUnusedDataFor: 300,
      }),
      [FreeShippingEndpoints.getFreeShippingsSubZones]: builder.query<
        FreeShippingZoneResponseDto[],
        string
      >({
        query: (id) => ({
          method: 'GET',
          url: getUrlWithParams(FREE_SHIPPING_SUBZONES_URL, { id }),
        }),
        keepUnusedDataFor: 300,
      }),
      [FreeShippingEndpoints.createFreeShipping]: builder.mutation<
        void,
        FreeShippingRequestDto
      >({
        query: (payload) => ({
          method: 'POST',
          url: BASE_FREE_SHIPPING_URL,
          data: payload,
        }),
      }),
      [FreeShippingEndpoints.getFreeShipping]: builder.query<
        FreeShippingFormState,
        string
      >({
        query: (id) => ({
          method: 'GET',
          url: getUrlWithParams(FREE_SHIPPING_ID_URL, { id }),
        }),
        transformResponse: ({
          priceMinimum,
          categories,
          zones,
          ...rest
        }: FreeShippingResponseDto) => ({
          priceMinimum: priceMinimum > 0 ? `${priceMinimum}` : '',
          categoriesType: categories.length ? 'some' : 'all',
          categories,
          zonesType: zones.length ? 'some' : 'all',
          zones,
          ...rest,
        }),
      }),
      [FreeShippingEndpoints.updateFreeShipping]: builder.mutation<
        void,
        { id: string; payload: FreeShippingRequestDto }
      >({
        query: ({ id, payload }) => ({
          method: 'PUT',
          url: getUrlWithParams(FREE_SHIPPING_ID_URL, { id }),
          data: payload,
        }),
      }),
    }),
  });

export const {
  useSetFreeShippingStatusMutation,
  useDeleteFreeShippingMutation,
  useGetFreeShippingAvailableQuery,
  useGetFreeShippingsOptionsQuery,
  useGetFreeShippingsZonesQuery,
  useGetFreeShippingsSubZonesQuery,
  useCreateFreeShippingMutation,
  useGetFreeShippingQuery,
  useUpdateFreeShippingMutation,
} = freeShippingApi;
