import { Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ShippingModalityCard from '../ShippingModalityCard';

function Skeleton() {
  const t = useTranslationShipping();
  return (
    <Stack column align="stretch" spacing="base">
      <Title as="h3">
        {`${t(
          'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.title',
        )}`}
      </Title>
      <Text>
        {t(
          'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.text',
        )}
      </Text>
      <ShippingModalityCard.Skeleton />
    </Stack>
  );
}

export default Skeleton;
