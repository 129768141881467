import { BaseCard, ErrorState } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

import './TransactionFeesErrorState.scss';

interface TransactionFeesErrorStateProps {
  onClick: () => void;
}

function TransactionFeesErrorState({
  onClick,
}: TransactionFeesErrorStateProps): JSX.Element {
  const t = useTranslationBilling();
  return (
    <BaseCard.Body>
      <div className="stratus--transaction-fees-error-state">
        <ErrorState
          title={t('transactionFeesPage.errorState.title')}
          action={{
            children: t('transactionFeesPage.errorState.retry'),
            onClick,
          }}
        />
      </div>
    </BaseCard.Body>
  );
}

export default TransactionFeesErrorState;
