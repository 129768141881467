import { Tabs, Box, Text } from '@nimbus-ds/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useStatisticsDaily } from 'domains/Statistics/hooks';
import StatsDailyTabContent from './StatsDailyTabContent';

interface CardStatisticsDailyProps {
  showAmounts?: boolean;
}

function CardStatisticsDaily({
  showAmounts = true,
}: Readonly<CardStatisticsDailyProps>) {
  const t = useTranslationDashboard();
  const { statisticsDaily, loading, showTabs } = useStatisticsDaily();

  return (
    <Box display="flex" flexDirection="column" gap="4">
      {!showTabs ? (
        <>
          <Text fontSize="highlight">{t('tabs.thisday')}</Text>
          {loading && <StatsDailyTabContent.Skeleton />}
          {!loading && (
            <StatsDailyTabContent
              values={statisticsDaily?.today}
              hideAmounts={!showAmounts}
            />
          )}
        </>
      ) : (
        <Tabs preSelectedTab={0}>
          <Tabs.Item label={t('tabs.thisday')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.today}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
          <Tabs.Item label={t('tabs.lastday')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.yesterday}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
          <Tabs.Item label={t('tabs.currentWeek')}>
            {loading && <StatsDailyTabContent.Skeleton />}
            {!loading && (
              <StatsDailyTabContent
                values={statisticsDaily?.currentWeek}
                hideAmounts={!showAmounts}
              />
            )}
          </Tabs.Item>
        </Tabs>
      )}
    </Box>
  );
}

export default CardStatisticsDaily;
