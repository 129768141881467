import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Card,
  Grid,
  Input,
  InterfaceNameValue,
  Stack,
} from '@tiendanube/components';
import { IdentificationInput } from 'commons/components';

export interface CustomerDataValuesInterface {
  name: string;
  email: string;
  phone?: string;
  identification?: string;
}

export interface CustomerDataErrorsInterface {
  name: string;
  email: string;
  identification?: string;
}

interface CustomerDataCardProps {
  onChange: ({ value, name }: InterfaceNameValue) => void;
  errors: Partial<CustomerDataErrorsInterface>;
  values: CustomerDataValuesInterface;
}

function CustomerDataCard({
  values,
  onChange,
  errors,
}: CustomerDataCardProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  const handleChangeEmail = ({ value }: InterfaceNameValue) => {
    onChange({ name: 'email', value: value.trim() });
  };

  return (
    <Card title={t('customerForm.customerData.title')}>
      <Stack column align="stretch">
        <Stack.Item>
          <Input
            name="name"
            value={values.name}
            onChange={onChange}
            label={t('customerForm.customerData.name')}
            appearance={errors.name ? 'validation_error' : undefined}
            helpText={t(errors.name || '')}
          />
        </Stack.Item>
        <Stack.Item>
          <Input
            name="email"
            value={values.email}
            onChange={handleChangeEmail}
            label={t('customerForm.customerData.email')}
            appearance={errors.email ? 'validation_error' : undefined}
            helpText={t(errors.email || '')}
          />
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={6} md={3} lg={3}>
                <Input
                  name="phone"
                  value={values.phone}
                  onChange={onChange}
                  label={t('customerForm.customerData.phone')}
                />
              </Grid.Column>
              <Grid.Column sm={6} md={3} lg={3}>
                <IdentificationInput
                  name="identification"
                  value={values.identification}
                  error={errors.identification}
                  onChange={onChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CustomerDataCard;
