import { useState, useCallback } from 'react';

export type UseModalResult = [boolean, () => void, () => void];

function useModal(openByDefault = false): UseModalResult {
  const [show, setShow] = useState(openByDefault);

  return [
    show,
    useCallback(() => {
      setShow(true);
    }, []),
    useCallback(() => {
      setShow(false);
    }, []),
  ];
}

export default useModal;
