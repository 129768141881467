import { Tag, Text } from '@nimbus-ds/components';
import { CouponStatus } from '@tiendanube/common';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';

const APPEARENCE: Record<
  CouponStatus,
  'success' | 'neutral' | 'warning' | 'danger'
> = {
  enabled: 'success',
  disabled: 'neutral',
  forbidden: 'warning',
  deleted: 'danger',
};

interface StatusProps {
  status: CouponStatus;
}

export function Status({ status }: Readonly<StatusProps>) {
  const t = useTranslationMarketing();

  return (
    <Tag appearance={APPEARENCE[status]}>
      <Text fontSize="caption" lineClamp={1}>
        {t(`coupons.details.status.${status}`)}
      </Text>
    </Tag>
  );
}

Status.Skeleton = Skeleton;
