import { DataList } from '@tiendanube/components';
import DraftOrdersListRowMobileSkeleton from './DraftOrdersListRowMobileSkeleton';

function DraftOrdersListMobileSkeleton(): JSX.Element {
  return (
    <DataList>
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
      <DraftOrdersListRowMobileSkeleton />
    </DataList>
  );
}

export default DraftOrdersListMobileSkeleton;
