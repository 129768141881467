import {
  Input,
  InterfaceNameValue,
  InterfaceInput,
} from '@tiendanube/components';
import { isValidIntegerNumber, isValidNumber } from './validatorUtils';

type TypeValidator = 'integer' | 'float' | 'float2d' | 'float3d' | 'float5d';

type Validator = Record<TypeValidator, (v: string) => boolean>;

const validators: Validator = {
  integer: isValidIntegerNumber,
  float: (v) => isValidNumber(v),
  float2d: (v) => isValidNumber(v, 2),
  float3d: (v) => isValidNumber(v, 3),
  float5d: (v) => isValidNumber(v, 5),
};
export interface InputNumberProps extends Omit<InterfaceInput, 'type'> {
  type: TypeValidator;
  label?: string;
  appendLabel?: string;
  prependLabel?: string;
  value: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  autoSelect?: boolean;
  appearance?:
    | 'default'
    | 'warning'
    | 'danger'
    | 'success'
    | 'validation_loading'
    | 'validation_success'
    | 'validation_error';
  onChange?: (event: InterfaceNameValue) => void;
  onBlur?: (event: InterfaceNameValue) => void;
  helpText?: string;
  lineThrough?: boolean;
}

/**
 * @deprecated This component is deprecated. Please use InputNumberNimbus instead
 */
function InputNumber({
  type,
  label = '',
  appendLabel,
  prependLabel,
  value,
  placeholder = '',
  name,
  disabled = false,
  appearance,
  autoSelect = false,
  onChange,
  onBlur = () => null,
  helpText,
  lineThrough,
  ...props
}: InputNumberProps): JSX.Element {
  const handleChange = ({ name, value }) => {
    let sanitizedValue = value.replace(',', '.');
    if (isNaN(sanitizedValue)) sanitizedValue = '';
    if (validators[type](sanitizedValue))
      onChange?.({ name, value: sanitizedValue });
  };

  return (
    <Input
      label={label}
      inputMode="decimal"
      appendLabel={appendLabel}
      prependLabel={prependLabel}
      placeholder={placeholder}
      value={value}
      name={name}
      disabled={disabled}
      onChange={handleChange}
      appearance={appearance}
      onBlur={onBlur}
      helpText={helpText}
      autoSelect={autoSelect}
      lineThrough={lineThrough}
      {...props}
    />
  );
}

export default InputNumber;
