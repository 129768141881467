import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getStatisticsShippingSummaryEntity = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.shippingSummary,
);

export const getStatisticsShippingSummaryStatus = createSimpleSelector(
  [getStatisticsShippingSummaryEntity],
  (ShippingSummary) => convertStatusTypeToObject(ShippingSummary.status),
);

export const getStatisticsShippingSummaryData = createSimpleSelector(
  [getStatisticsShippingSummaryEntity],
  (ShippingSummary) => ShippingSummary.data,
);
