import { Country } from '@tiendanube/common/src/enums';
import { Grid } from '@tiendanube/components';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import {
  RankProvincesByShippingCostCard,
  RankProvincesByShippingTimeCard,
  SalesByProvinceCard,
  ShippingRevenueByProvinceCard,
  ShippingSummaryCard,
  StatisticsPage,
} from 'domains/Statistics/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

function StatisticsShippingPage() {
  const t = useTranslationStatistics();
  return (
    <StatisticsPage title={t('statistics.shippingPage.title')}>
      <ShippingSummaryCard />
      <ShippingRevenueByProvinceCard />
      <SalesByProvinceCard />
      <Grid>
        <Grid.Row>
          <Grid.Column lg={3} md={6} sm={6}>
            <RankProvincesByShippingCostCard />
          </Grid.Column>
          <Grid.Column lg={3} md={6} sm={6}>
            <RankProvincesByShippingTimeCard />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ShowByCountry excludeCountries={[Country.BR]}>
        <HelpLink
          title={t('statistics.helpLink.aboutStats')}
          linkURL={t('statistics.helpLink.aboutStatsUrl')}
          previousValue=""
          currentViewTracking={t('statistics.aboutProductsTrackingName')}
          icon="both"
        />
      </ShowByCountry>
    </StatisticsPage>
  );
}

export default StatisticsShippingPage;
