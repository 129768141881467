import { Card, Text, Stack } from '@tiendanube/components';
import '../../PlanCard.scss';

function PlanCardSkeleton(): JSX.Element {
  return (
    <div className="stratus--card">
      <Card title="" headerLabel="skeleton" primaryButton="skeleton">
        <Stack column align="stretch">
          <Stack.Item>
            <Text.Skeleton size="featured" width="fill" />
          </Stack.Item>
          <Stack.Item>
            <Text.Skeleton />
          </Stack.Item>
          <hr />
          <Stack column align="stretch">
            <Stack.Item>
              <Text.Skeleton width="fill" />
            </Stack.Item>
            <Stack.Item>
              <Text.Skeleton width="fill" />
            </Stack.Item>
            <Stack.Item>
              <Text.Skeleton width="fill" />
            </Stack.Item>
          </Stack>
        </Stack>
      </Card>
    </div>
  );
}

export default PlanCardSkeleton;
