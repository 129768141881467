import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Text } from '@tiendanube/components';
import { CashIcon } from '@tiendanube/icons';
import { useAsyncFunc, useToast } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';

interface PaymentCardProps {
  onClickButton: () => Promise<void>;
  orderHasLineItems: boolean;
}

function PaymentCard({
  onClickButton,
  orderHasLineItems,
}: PaymentCardProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const { addToast } = useToast();
  const canSetPaymentReceived = useHasPermission('set_payment_received');

  const [handleOnClick, isLoading, error, clearError] =
    useAsyncFunc(onClickButton);

  useEffect(() => {
    if (error) {
      addToast({
        appearance: 'danger',
        label: t('draftOrders.paymentCard.errorMessage'),
      });
      clearError();
    }
  }, [error, addToast, clearError, t]);

  const button = canSetPaymentReceived
    ? {
        children: `${t('draftOrders.paymentCard.markAsReceived')}`,
        disabled: isLoading || !orderHasLineItems,
        spinner: isLoading,
        onClick: handleOnClick,
      }
    : undefined;

  return (
    <Card
      title={t('draftOrders.paymentCard.title')}
      headerLabel={{
        id: 'string',
        appearance: 'default',
        icon: CashIcon,
        label: `${t('draftOrders.paymentCard.status')}`,
      }}
      secondaryButton={button}
    >
      <Text>{t('draftOrders.paymentCard.description')}</Text>
    </Card>
  );
}

export default PaymentCard;
