import { useState, useEffect } from 'react';
import { InputNumber } from 'commons/components';
import { useTimeOut } from 'commons/hooks';
import { useGetCurrency } from 'domains/Auth/hooks';
import useAsyncInput from '../../useAsyncInput';

interface InputStockProps {
  defaultValue: number;
  appearance?: 'default' | 'warning' | 'danger' | undefined;
  name: string;
  onError?: () => void;
  lineThrough?: boolean;
  asyncFunc: ({
    value,
    name,
  }: {
    value: string;
    name: string;
  }) => Promise<void>;
  disabled?: boolean;
}

function InputAsync({
  defaultValue,
  appearance: defaultAppearance = 'default',
  name,
  onError,
  asyncFunc,
  lineThrough,
  disabled = false,
}: InputStockProps): JSX.Element {
  const [value, setValue] = useState(defaultValue);
  const resetValueWithDelay = useTimeOut(() => {
    setValue(defaultValue);
  });
  const { symbol } = useGetCurrency();

  const [doAsync, loading, error, success] = useAsyncInput<undefined, void>(
    async () => {
      await asyncFunc({
        value: `${value}`,
        name: name,
      });
    },
  );

  useEffect(() => {
    if (error) {
      resetValueWithDelay();
      onError?.();
    }
  }, [error, onError, resetValueWithDelay]);

  const handleOnChange = ({ value }) => {
    setValue(value);
  };

  const handleOnBlur = async () => {
    if (value !== defaultValue) {
      await doAsync();
    }
  };

  const appearance = error
    ? 'validation_error'
    : success
    ? 'validation_success'
    : loading
    ? 'validation_loading'
    : defaultAppearance;

  const valueInput = value === 0 ? '' : `${value}`;

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <InputNumber
      type="float"
      value={valueInput}
      onChange={handleOnChange}
      name={name}
      appearance={appearance}
      onBlur={handleOnBlur}
      prependLabel={symbol}
      autoSelect
      lineThrough={lineThrough}
      disabled={disabled}
    />
  );
}

export default InputAsync;
