import { arrayMove } from '@dnd-kit/sortable';
import {
  CategoryTabedResponseDto,
  SectionProductDto,
} from '@tiendanube/common';
import {
  DataList,
  EmptySearch,
  InterfaceNameValue,
  Stack,
  Text,
} from '@tiendanube/components';
import {
  CardWithTitle,
  ErrorScreen,
  SortableDataList,
  VirtualDataList,
  DraggableDataListNew,
} from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { SortBySelectType } from 'domains/Online';
import {
  CategoriesSelect,
  ProductsBySectionItem,
  ProductsBySectionItemProps,
  SortBySelect,
} from './components';

export interface ProductListInterface extends ProductsBySectionItemProps {
  order: number;
}

interface ProductsBySectionProps {
  products: ProductListInterface[];
  productsStatus: string;
  productsCount: number;
  categoryId: string;
  categories: CategoryTabedResponseDto[];
  sortBy: SortBySelectType;
  isLoading?: boolean;
  onEnded: () => void;
  onChange: (data: InterfaceNameValue) => void;
  onChangeProducts: (products: SectionProductDto[]) => void;
}

function ProductsBySection({
  products,
  productsStatus,
  productsCount,
  categoryId,
  categories,
  sortBy,
  onEnded,
  onChange,
  onChangeProducts,
}: Readonly<ProductsBySectionProps>) {
  const t = useTranslationCatalog();
  const shouldUseVirtualizedList = productsCount > 2000;

  const handleChangeProducts = (products) => onChangeProducts(products);

  const moveProductToUp = (productIndex: number) => {
    if (!productIndex) return;

    // prevent scroll to top just keep current scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const handleScroll = () => window.scrollTo(0, scrollTop);
    window.addEventListener('scroll', handleScroll);

    onChangeProducts(arrayMove(products, productIndex, 0));

    setTimeout(() => {
      window.removeEventListener('scroll', handleScroll);
    }, 100);
  };

  return (
    <CardWithTitle title={t('products.order.productsBySeccion.title')}>
      <Stack column align="stretch">
        <Text>{t('products.order.productsBySeccion.subtitle')}</Text>
        <CategoriesSelect
          categories={categories}
          value={categoryId}
          onChange={onChange}
        />
        <SortBySelect value={sortBy} onChange={onChange} />
        {productsStatus === 'success' && products.length === 0 && (
          <EmptySearch
            title={t('products.order.productsBySeccion.emptySearch.title')}
            text={t('products.order.productsBySeccion.emptySearch.text')}
          />
        )}
        {productsStatus === 'error' && (
          <ErrorScreen
            onRetry={onEnded}
            description={t('products.order.productsBySeccion.error')}
          />
        )}
        {productsStatus !== 'error' && sortBy !== 'user' && (
          <VirtualDataList
            data={products.map((i) => i.productId)}
            endReached={onEnded}
            showFooter={productsStatus === 'loading'}
            itemContent={(i) => (
              <DataList.Row id={products[i].productId}>
                <DataList.Cell width={100}>
                  <ProductsBySectionItem
                    productId={products[i].productId}
                    name={products[i].name}
                    imageUrl={products[i].imageUrl}
                    visible={products[i].visible}
                    stock={products[i].stock}
                  />
                </DataList.Cell>
              </DataList.Row>
            )}
          />
        )}
        {productsStatus !== 'error' &&
          sortBy === 'user' &&
          (shouldUseVirtualizedList ? (
            <DraggableDataListNew<ProductListInterface>
              data={products}
              onChange={handleChangeProducts}
              showFooter={productsStatus === 'loading'}
              endReached={onEnded}
              renderItem={(product, index) => (
                <ProductsBySectionItem
                  productId={product.productId}
                  name={product.name}
                  imageUrl={product.imageUrl}
                  visible={product.visible}
                  stock={product.stock}
                  moveProductToUp={() => moveProductToUp(index)}
                  firstProductId={products[0].productId}
                />
              )}
            />
          ) : (
            <SortableDataList<ProductListInterface>
              customIdKey="productId"
              items={products}
              onChange={handleChangeProducts}
              showFooter={productsStatus === 'loading'}
              endReached={onEnded}
              renderItem={(product, index) => (
                <ProductsBySectionItem
                  productId={product.productId}
                  name={product.name}
                  imageUrl={product.imageUrl}
                  visible={product.visible}
                  stock={product.stock}
                  moveProductToUp={() => moveProductToUp(index)}
                  firstProductId={products[0].productId}
                />
              )}
            />
          ))}
      </Stack>
    </CardWithTitle>
  );
}

export default ProductsBySection;
