import { Plugins, Capacitor } from '@capacitor/core';
import '@tiendanube/capacitor-keyboard-nube';
import { LoginResponseDto } from '@tiendanube/common';

const { KeyboardNube } = Plugins;

export const setConfigKeyboard = ({
  accessToken,
  defaultLanguage,
}: LoginResponseDto): void => {
  if (Capacitor.getPlatform() === 'web') return;

  KeyboardNube.setToken({
    token: accessToken,
  });

  KeyboardNube.setLocale({
    locale: defaultLanguage,
  });
};

export const cleanupKeyboardConfig = (): void => {
  if (Capacitor.getPlatform() === 'web') return;
  KeyboardNube.setToken({ token: null });
};
