import { Box, Link } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

export interface LinkToMetafieldsProps {
  hasPaddingBottom?: boolean;
}

export default function LinkToMetafields({
  hasPaddingBottom = false,
}: LinkToMetafieldsProps) {
  const t = useTranslationsMetafields();
  const { goTo } = useNavegate();
  const handleClickLink = () => goTo('/settings/metafields');
  return (
    <Box padding="4" paddingBottom={hasPaddingBottom ? '4' : 'none'}>
      <Link
        onClick={handleClickLink}
        textDecoration="none"
        fontSize="base"
        appearance="primary"
      >
        <EditIcon size="small" />
        {t('link')}
      </Link>
    </Box>
  );
}
