import { ExternalLinkIcon, TrashIcon } from '@nimbus-ds/icons';
import { MenuButtonProperties } from '@nimbus-ds/menubutton';
import { isNumber } from 'lodash';
import { PopoverMenu } from 'commons/components';
import { useModal } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { trackingThemeLayoutDraftActionsView } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { DeleteDraftModal } from './DeleteDraftModal';

type ActionPlacementRelative = 'append' | 'prepend';
type ActionPlacement = ActionPlacementRelative | number;

export interface DraftActionsProps {
  previewUrl: string;
  extraActions?: {
    placement: ActionPlacement;
    actions: MenuButtonProperties[];
  };
}

export function DraftActions({
  previewUrl,
  extraActions,
}: Readonly<DraftActionsProps>) {
  const t = useTranslationOnline('themesPage.draft.actions');
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();

  const handleClickPreview = () => {
    trackingThemeLayoutDraftActionsView();
    openWindow(previewUrl, true);
  };

  const actionsToAdd = extraActions?.actions || [];
  const actions = [
    ...(extraActions?.placement === 'prepend' ? actionsToAdd : []),
    {
      label: t('view'),
      startIcon: ExternalLinkIcon,
      onClick: handleClickPreview,
    },
    {
      label: t('delete'),
      startIcon: TrashIcon,
      onClick: openDeleteModal,
    },
    ...(extraActions?.placement === 'append' ? actionsToAdd : []),
  ];

  if (extraActions?.placement && isNumber(extraActions?.placement)) {
    actions.splice(extraActions?.placement, 0, ...actionsToAdd);
  }

  return (
    <>
      <PopoverMenu actions={actions} />
      <DeleteDraftModal
        showModal={showDeleteModal}
        onClose={closeDeleteModal}
      />
    </>
  );
}
