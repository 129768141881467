import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CustomPropertiesResponseDto } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { LanguageInterface } from 'App/i18n';
import { useGetLanguage } from 'domains/Auth/hooks';
import {
  Attribute,
  AttributeOptionInterface,
  AttributeType,
} from 'domains/Catalog/Products/pages/components/Variants/types';
import {
  isStandardVariant,
  getStandardVariantsFlatten,
  isAttributeValueSelected,
  formatAttributeOptions,
} from '../../utils';

const EMPTY_OBJECT = {};

function useAttributeForm(
  standardVariants: CustomPropertiesResponseDto | null,
  selectedVariantType: AttributeType | null,
  locale: string,
  attribute?: Attribute,
) {
  const language = useGetLanguage();
  const [name, setName] = useState<LanguageInterface>(attribute?.name || {});
  const [attributeOptions, setAttributeOptions] = useState<
    AttributeOptionInterface[]
  >(attribute?.values || [EMPTY_OBJECT]);

  const formattedStandardVariants = getStandardVariantsFlatten(
    standardVariants,
    selectedVariantType,
  );

  const handleChangeName = (language: string, value: string) => {
    setName({ ...name, [language]: value });
  };

  const handleAddOption = () => {
    setAttributeOptions([...attributeOptions, { id: uuidv4() }]);
  };

  const handleChangeOption = (
    index: number,
    language: string,
    value: string,
  ) => {
    setAttributeOptions((currentState) => {
      const newState = [...currentState];
      newState[index] = { ...newState[index], [language]: value };
      return newState;
    });
  };

  const handleRemoveOption = (index: number) => {
    setAttributeOptions((attributeOptions) => {
      const updated = attributeOptions.filter((_, i) => i !== index);
      return updated.length === 0 ? [EMPTY_OBJECT] : updated;
    });
  };

  const handleCheckOption = (selectedIds: string[]) => {
    const selectedVariants = selectedIds.map((id) => ({
      ...formattedStandardVariants[id],
      id: uuidv4(),
    }));

    const newSelectedAttributes = selectedVariants.filter(
      (selectedOption) =>
        !isAttributeValueSelected(attributeOptions, language, selectedOption),
    );

    const updatedAttributeOptions = formatAttributeOptions([
      ...attributeOptions,
      ...newSelectedAttributes,
    ]).filter((attributeOption) => {
      if (
        isAttributeValueSelected(
          Object.values(formattedStandardVariants),
          language,
          attributeOption,
        )
      ) {
        return isAttributeValueSelected(
          selectedVariants,
          language,
          attributeOption,
        );
      }
      return true;
    });

    setAttributeOptions(updatedAttributeOptions);
  };

  const handleBlurOption = (
    index: number,
    language: string,
    { value }: InterfaceNameValue,
  ) => {
    const options = attributeOptions.filter(
      (attribute) =>
        attribute[language]?.toLowerCase().trim() ===
        value.toLowerCase().trim(),
    );

    if (options.length > 1 || (value.trim() === '' && index > 0)) {
      handleRemoveOption(index);
    }
  };

  const handleSortOptions = (attributeOptionsSorted: LanguageInterface[]) => {
    setAttributeOptions(attributeOptionsSorted);
  };

  const clearValues = () => {
    setName({});
    setAttributeOptions([EMPTY_OBJECT]);
  };

  useEffect(() => {
    if (
      !!attribute?.name[language] &&
      !isStandardVariant(language, locale, attribute.name[language])
    ) {
      setName(attribute?.name || {});
    }

    setAttributeOptions(
      attribute?.values.map((option) => ({ ...option, id: uuidv4() })) || [
        EMPTY_OBJECT,
      ],
    );
  }, [attribute, language, standardVariants, locale]);

  return {
    name,
    attributeOptions,
    setAttributeOptions,
    handleChangeName,
    handleAddOption,
    handleRemoveOption,
    handleChangeOption,
    handleCheckOption,
    handleBlurOption,
    handleSortOptions,
    clearValues,
  };
}

export default useAttributeForm;
