import { useState } from 'react';
import { PaymentProviderDto } from '@tiendanube/common';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { useToastProgress } from 'App/components/ToastProgressContext';
import {
  AuthenticationWithoutConfirmationModal,
  ModalConfirmationWithAuth,
} from 'domains/AuthenticationFactor';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  usePaymentProviderActionModal,
  useSpecialDisabledModal,
} from './hooks';

export type PaymentProviderActionType = 'enable' | 'disable' | 'uninstall';
export type OnPaymentProviderActionType = (
  appId: string,
  action: PaymentProviderActionType,
) => void;

interface PaymentProviderActionModalProps {
  provider: PaymentProviderDto;
  action: PaymentProviderActionType;
  onClose: () => void;
}

const TRANSLATE_PREFIX = 'paymentProvider.actionModal';

function PaymentProviderActionModal({
  provider,
  action,
  onClose,
}: PaymentProviderActionModalProps): JSX.Element | null {
  const { id, wasInstalled, isInstallable, isApp } = provider;
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const [isConfirmAction, setIsConfirmAction] = useState(false);

  const handleConfirmAction = () => {
    const event = isEnableAction ? 'installConfirm' : 'uninstallConfirm';
    invokeEventPayments(event, id);
    setIsConfirmAction(true);
    const actionFunction = actionProvider[action];
    if ((wasInstalled || !isEnableAction) && !isPagoNube && !isEnableAction) {
      addToastProgress({ label: progress });
    }

    return actionFunction();
  };

  const handleClose = () => {
    closeToastProgress();
    onClose();
  };

  const { actionProvider, isUpdating, progress, confirm, text, title } =
    usePaymentProviderActionModal(provider, action, handleClose);

  const isEnableAction = action === 'enable';

  const { SpecialDisabledModal } = useSpecialDisabledModal(
    !isEnableAction ? id : '',
  );

  const isPagoNube = muvemPagoOrPagoNubeIds.includes(id);

  const isFirstInstallation =
    ((!wasInstalled && isApp) || isPagoNube) && isEnableAction;

  if (isUpdating || isConfirmAction) return null;

  return (
    <>
      {!SpecialDisabledModal && isFirstInstallation && (
        <AuthenticationWithoutConfirmationModal
          onConfirm={handleConfirmAction}
          onCancel={onClose}
          loadingMessage={
            isInstallable ? t('progressEnable') : t('updateEnable')
          }
        />
      )}

      {!SpecialDisabledModal && !isFirstInstallation && (
        <ModalConfirmationWithAuth
          title={title}
          text={text}
          labelConfirm={confirm}
          labelCancel={t('cancel')}
          onCancel={onClose}
          onConfirm={handleConfirmAction}
        />
      )}

      {SpecialDisabledModal && (
        <SpecialDisabledModal
          onCancel={onClose}
          onConfirm={handleConfirmAction}
        />
      )}
    </>
  );
}

export default PaymentProviderActionModal;
