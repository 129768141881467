import React from 'react';
import {
  GlobeIcon,
  TruckIcon,
  MobileIcon,
  InfoCircleIcon,
  ArrowsVerticalIcon,
  ShoppingCartIcon,
  SearchIcon,
  IconProps,
} from '@nimbus-ds/icons';
import { AdditionalSettingType } from './AditionalSettingCard';

export const ADDITIOAL_SETTING_ITEM_ICONS: Record<
  AdditionalSettingType,
  React.FC<IconProps>
> = {
  'abandoned-cart': ShoppingCartIcon,
  'custom-link': GlobeIcon,
  'free-shipping': TruckIcon,
  'google-shopping': SearchIcon,
  'instagram-shops': MobileIcon,
  'sort-products': ArrowsVerticalIcon,
  'weight-dimension': InfoCircleIcon,
};

export const ADDITIOAL_SETTING_EVENT_CARD: Record<
  AdditionalSettingType,
  string
> = {
  'abandoned-cart': 'abandoned_carts',
  'custom-link': 'domain',
  'free-shipping': 'free_shipping',
  'google-shopping': 'google_shop',
  'instagram-shops': 'instagram_shop',
  'sort-products': 'organize_products',
  'weight-dimension': 'weight_dimension',
};
