import { Link, Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { AlertSessionStorage } from 'App/components';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useSendEmailValidationMutation } from 'domains/Configurations/Notifications/notificationsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface AmazonSesAlertProps {
  storeEmail: string;
  validateEmail: () => void;
}

export function AmazonSesAlert({
  storeEmail,
  validateEmail,
}: Readonly<AmazonSesAlertProps>) {
  const t = useTranslationConfigurations(
    'notifications.notificationsListPage.emailsAlert',
  );
  const [sendValidationEmail, { status: sendEmailStatus }] =
    useSendEmailValidationMutation();

  useToastStatus({
    status: toStatusType(sendEmailStatus),
    progress: t('sendEmailToast.progress'),
    success: t('sendEmailToast.success'),
    error: t('sendEmailToast.error'),
  });

  return (
    <AlertSessionStorage keyName="emailOriginAlert" appearance="primary">
      <Stack column align="flex-start">
        <Stack column align="flex-start" spacing="none">
          <Text fontWeight="bold">
            <Trans
              t={t}
              i18nKey="text"
              values={{ mainUserEmail: storeEmail }}
              components={{
                bold: <strong />,
              }}
            />
          </Text>
        </Stack>
        <Stack column align="flex-start" spacing="none">
          <Text>
            <Trans
              t={t}
              i18nKey="step1"
              values={{ mainUserEmail: storeEmail }}
              components={{
                bold: <strong />,
                sendEmailLink: (
                  <Link onClick={() => sendValidationEmail()}> </Link>
                ),
              }}
            />
          </Text>
          <Text>
            <Trans t={t} i18nKey="step2" components={{ bold: <strong /> }} />
          </Text>
          <Text>{t('step3')}</Text>
          <Text>
            <Trans
              t={t}
              i18nKey="step4"
              components={{
                bold: <strong />,
                validateEmailLink: (
                  <Link onClick={() => validateEmail()}> </Link>
                ),
              }}
            />
          </Text>
        </Stack>
      </Stack>
    </AlertSessionStorage>
  );
}
