import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { addOpacityToHexColor } from 'commons/utils/addOpacityToHexColor';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { StatisticsGranularityType } from 'domains/Statistics/types';
import {
  abbreviateNumber,
  BAR_CHART_HEIGTH,
  CHART_COLORS,
  FONT_SIZE_BAR_CHART,
  MARGIN_BAR_CHART,
} from 'domains/Statistics/utils';
import xAxisFormatDate from 'domains/Statistics/utils/xAxisFormatDate';
import LegendStratus from '../../LegendStratus';
import StratusLegendContent from '../../StratusLegendContent';
import StratusTooltipContent from '../../StratusTooltipContent';
import TooltipChartStratus from '../../TooltipChartStratus';

interface CustomersBarChartValues {
  name: string;
  newCustomers: number;
  recurringCustomers: number;
  newCustomersCompared?: number;
  recurringCustomersCompared?: number;
}

interface CustomersBarChartProps {
  values: CustomersBarChartValues[];
  hasComparedFilter: boolean;
  granularity: StatisticsGranularityType;
}

function CustomersBarChart({
  values,
  hasComparedFilter,
  granularity,
}: CustomersBarChartProps) {
  const t = useTranslationStatistics();
  const handleFormatedDate = xAxisFormatDate(granularity);

  const formatLabel = (label: string) =>
    t(`statistics.customersChartCard.${label.replace('Compared', '')}`);

  return (
    <ResponsiveContainer height={BAR_CHART_HEIGTH}>
      <BarChart
        data={values}
        margin={{ left: -MARGIN_BAR_CHART, right: MARGIN_BAR_CHART }}
        style={{ fontSize: FONT_SIZE_BAR_CHART }}
        maxBarSize={values.length > 8 ? 12 : undefined}
      >
        <CartesianGrid vertical={false} strokeDasharray="2 0" />
        <XAxis dataKey="name" tickFormatter={handleFormatedDate} />
        <YAxis
          tickFormatter={abbreviateNumber}
          axisLine={false}
          tickLine={false}
        />
        <Legend
          content={
            <LegendStratus>
              <StratusLegendContent
                hasComparedData={hasComparedFilter}
                formatLabel={formatLabel}
                isCombined
              />
            </LegendStratus>
          }
        />
        <Tooltip
          content={
            <TooltipChartStratus>
              <StratusTooltipContent
                granularity={granularity}
                hasComparedData={hasComparedFilter}
                formatLabel={formatLabel}
                isCombined
              />
            </TooltipChartStratus>
          }
        />
        <Bar
          dataKey="newCustomers"
          stackId="principal"
          fill={CHART_COLORS.skyBlue}
          stroke={CHART_COLORS.skyBlue}
        />
        <Bar
          dataKey="recurringCustomers"
          stackId="principal"
          fill={addOpacityToHexColor(CHART_COLORS.skyBlue, 0.5)}
          stroke={CHART_COLORS.skyBlue}
        />

        {hasComparedFilter && (
          <>
            <Bar
              dataKey="newCustomersCompared"
              stackId="compared"
              fill={CHART_COLORS.navyBlue}
              stroke={CHART_COLORS.navyBlue}
            />
            <Bar
              dataKey="recurringCustomersCompared"
              stackId="compared"
              fill={addOpacityToHexColor(CHART_COLORS.navyBlue, 0.5)}
              stroke={CHART_COLORS.navyBlue}
            />
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default CustomersBarChart;
