import { useSelector } from 'react-redux';
import { getRoles, getRolesWithoutFilters } from './authSelectors';

type validateRolesType = (
  includeRoles?: string | string[],
  excludeRoles?: string | string[],
) => boolean;

type validateUserRolesType = (
  includeRoles?: string | string[],
  excludeRoles?: string | string[],
  rolesWithoutFilter?: boolean,
) => boolean;

export interface UseRolesResult {
  validateRoles: validateRolesType;
  validateRolesWithoutFilter: validateRolesType;
}

export function useRoles(): UseRolesResult {
  const userRoles = useSelector(getRoles);
  const userRolesWithoutFilter = useSelector(getRolesWithoutFilters);

  const validateRoles: validateRolesType = (includeRoles, excludeRoles) =>
    validateUserRoles(includeRoles, excludeRoles, false);

  const validateRolesWithoutFilter: validateRolesType = (
    includeRoles,
    excludeRoles,
  ) => validateUserRoles(includeRoles, excludeRoles, true);

  const validateUserRoles: validateUserRolesType = (
    includeRoles,
    excludeRoles,
    rolesWithoutFilter = false,
  ) => {
    const hasIncludeRoles =
      includeRoles && isValidRole(includeRoles, rolesWithoutFilter);
    const hasExcludeRoles =
      excludeRoles && !isValidRole(excludeRoles, rolesWithoutFilter);

    if (!excludeRoles && hasIncludeRoles) {
      return true;
    }

    if (!includeRoles && hasExcludeRoles) {
      return true;
    }

    if (hasIncludeRoles && hasExcludeRoles) {
      return true;
    }

    return false;
  };

  const isValidRole = (
    find: string[] | string,
    rolesWithoutFilter: boolean,
  ): boolean => {
    const roles = !rolesWithoutFilter ? userRoles : userRolesWithoutFilter;

    return (
      (typeof find === 'string'
        ? roles.includes(find)
        : find.some((rol: string) => roles.includes(rol))) ||
      roles.includes('full')
    );
  };

  return { validateRoles, validateRolesWithoutFilter };
}
