import { IconButton } from '@nimbus-ds/components';
import { DataList } from '@tiendanube/components';
import { Repeater } from 'commons/components';
import { AppsListItemSkeleton } from '../../../AppsListItem';

function ItemSkeleton(): JSX.Element {
  const { Row, Cell } = DataList;

  return (
    <Row id="skeleton">
      <Cell width="fill" rowTitle>
        <AppsListItemSkeleton />
      </Cell>
      <Cell width="auto" alignRight>
        <IconButton.Skeleton height="2rem" width="2rem" />
      </Cell>
    </Row>
  );
}

function AppsListMobileSkeleton() {
  return <Repeater item={() => <ItemSkeleton />} />;
}

export default AppsListMobileSkeleton;
