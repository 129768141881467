import { useTranslation } from 'react-i18next';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Domain as DomainEnum, Status } from '@tiendanube/common/src/enums';
import { Card, Link, Stack, Text } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { useModal } from 'commons/hooks';
import { emptyIsNullInText } from 'commons/utils';
import LocationText from 'domains/Orders/components/LocationText';
import EditDeliveryAdressModal from '../EditDeliveryAdressModal';
import { DeliveryAddressValuesType } from '../EditDeliveryAdressModal/EditDeliveryAdressModal';

interface CardDeliveryProps {
  onEditDeliveryAddress: (deliveryAddress: DeliveryAddressValuesType) => void;
  order: OrderDetailsResponseDto;
}

function CardDeliveryAddress({
  onEditDeliveryAddress,
  order: {
    sameBillingAndShippingAddress,
    shipping,
    fulfillment: {
      preference: { deliveryAddress: deliveryAddress },
      status,
    },
  },
}: CardDeliveryProps) {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const [isShowModal, openModal, closeModal] = useModal();

  if (!deliveryAddress?.street) return null;

  const {
    firstName,
    lastName,
    phone,
    street,
    number,
    floor,
    zipcode,
    locality,
    city,
    province,
    country,
  } = deliveryAddress;

  const title = sameBillingAndShippingAddress
    ? t('cardDeliveryAddress.billingAndDeliveryAddressesTitle')
    : t('cardDeliveryAddress.title');

  const editText = sameBillingAndShippingAddress
    ? t('cardDeliveryAddress.billingAndDeliveryAddressesLink')
    : t('cardDeliveryAddress.link');

  const canEditAddress = status !== Status.SHIPPED;

  return (
    <>
      <Card title={title}>
        <Stack column align="flex-start" spacing="tight">
          <Stack.Item>
            <Text block>{`${firstName} ${lastName}`}</Text>
            <Text block>{phone}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text appearance="light" block>{`${t(
              'cardDeliveryAddress.address',
            )}`}</Text>
            <Text block>
              {`${street} ${emptyIsNullInText(number)} ${
                floor ?? ''
              }  (${zipcode})`}
            </Text>
            <LocationText
              locality={locality}
              city={city}
              province={province}
              country={country}
            />
          </Stack.Item>
          {canEditAddress && (
            <Stack.Item>
              <Link icon={EditIcon} appearance="primary" onClick={openModal}>
                {editText}
              </Link>
            </Stack.Item>
          )}
        </Stack>
      </Card>
      {/*
       ** The following conditional rendering is to avoid persisting the form's
       ** internal state when the form is closed, since IonModal renders the component
       ** even if it's not visible.
       */}
      {isShowModal && (
        <EditDeliveryAdressModal
          deliveryAddress={deliveryAddress as DeliveryAddressValuesType}
          isShow
          hasGeneratedTag={deliveryAddress.hasGeneratedTag}
          shipping={shipping}
          onBack={closeModal}
          onClose={closeModal}
          onEditDeliveryAddress={onEditDeliveryAddress}
        />
      )}
    </>
  );
}

export default CardDeliveryAddress;
