import { useState } from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { ContentTemplateIdType } from '@tiendanube/common';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { LanguageNameValueInterface } from 'App/i18n';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  CancelAndSaveButtons,
} from 'commons/components';
import { useForm, useModal, useToastStatus } from 'commons/hooks';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { PrivacyPolicyModal } from './PrivacyPolicyModal';
import { CONTENT_PAGES_ROUTES } from '../../contentPageRoutes';
import { useCreateContentPages } from '../../hooks';
import { validationSchema } from '../../validationSchema';
import { FormPage, FormPageSkeleton } from '../components';

const PAGES_WITH_EMPTY_SUGGESTED_CONTENT = ['blank'];

function PageCreation() {
  const [accepted, setAccepted] = useState(false);
  const [language] = useGetAllLanguages();
  const { goBack, getQueryParam, replace, buildPath } = useNavegate();
  const templateId =
    (getQueryParam('templateId') as ContentTemplateIdType) || 'blank';
  const [showPrivacyPolicy, , closePrivacyPolicy] = useModal(
    templateId === 'privacyPolicy',
  );
  const t = useTranslationOnline('contentPages');
  const { t: translate } = useTranslation();
  const { initialValues, status, statusTemplate, createPage } =
    useCreateContentPages(templateId);

  const { values, errors, isDirty, handleOnSubmit, setFieldValue } = useForm({
    initialValues,
    validationSchema: validationSchema(language),

    onSubmit: async (data) => {
      if (data) {
        const id = await createPage(data);
        replace(
          buildPath(CONTENT_PAGES_ROUTES.pageEditing, { id: String(id) }),
        );
      }
    },
  });

  useToastStatus({
    error: t('formPage.toast.edit.error'),
    success: t('formPage.toast.edit.success'),
    status,
  });

  useToastStatus({
    error: t('formPage.toast.template.error'),
    onError: goBack,
    status: statusTemplate,
  });

  const handleChange = ({ name, value }: LanguageNameValueInterface) => {
    setFieldValue(name, value);
  };

  const handleClose = () => {
    closePrivacyPolicy();
    goBack();
  };

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('formPage.createPage')} />}
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
    >
      <Alert show={accepted && !showPrivacyPolicy} appearance="warning">
        {t('privacyPolicy.alert')}
      </Alert>
      <Prompt when={isDirty} message={translate('common:exitEdit.info')} />
      {statusTemplate === 'loading' && <FormPageSkeleton />}
      {statusTemplate !== 'loading' && statusTemplate !== 'error' && (
        <>
          <FormPage values={values} errors={errors} onChange={handleChange} />
          <CancelAndSaveButtons
            onCancel={goBack}
            onSave={handleOnSubmit}
            saveText={t('formPage.createButton')}
            isLoading={status === 'loading'}
            isDisabled={
              PAGES_WITH_EMPTY_SUGGESTED_CONTENT.includes(templateId) &&
              !isDirty
            }
          />
          <HelpLink
            title={t('helpLink.title')}
            linkURL={t('helpLink.linkURL')}
            previousValue=""
            currentViewTracking={t('helpLink.currentViewTracking')}
            icon="both"
          />
        </>
      )}
      <PrivacyPolicyModal
        isShow={showPrivacyPolicy}
        onClose={handleClose}
        onChange={setAccepted}
        checked={accepted}
        onAccept={closePrivacyPolicy}
      />
    </IonPageStratus>
  );
}

export default PageCreation;
