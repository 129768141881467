import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomPaymentRequestDto,
  CustomPaymentResponseDto,
} from '@tiendanube/common';
import { StatusSaveType, StatusType } from 'commons/types';
import customPaymentService from '../customPaymentService';

export interface InterfaceCustomPaymentSchema {
  status: {
    load: StatusType;
    update: StatusSaveType;
    disable: StatusSaveType;
  };
  data: CustomPaymentResponseDto;
}

const initialState: InterfaceCustomPaymentSchema = {
  status: {
    load: 'idle',
    update: 'idle',
    disable: 'idle',
  },
  data: {
    cash: {
      active: false,
      name: '',
      instructions: '',
      percentage: 0,
      allowDiscountCombination: false,
    },
    wireTransfer: {
      active: false,
      name: '',
      instructions: '',
      percentage: 0,
      allowDiscountCombination: false,
    },
    other: {
      active: false,
      name: '',
      instructions: '',
      percentage: 0,
      allowDiscountCombination: false,
    },
    pix: {
      active: false,
      name: '',
      instructions: '',
      percentage: 0,
      allowDiscountCombination: false,
    },
  },
};

export const getCustomPayment = createAsyncThunk(
  'customPayment/getCustomPayment',
  customPaymentService.getCustomPayment,
);

export const updateCustomPayment = createAsyncThunk<
  void,
  CustomPaymentRequestDto
>('customPayment/updateCustomPayment', async (payload) => {
  await customPaymentService.updateCustomPayment(payload);
});

export const disableCustomPayment = createAsyncThunk<
  void,
  CustomPaymentRequestDto
>('customPayment/disableCustomPayment', async () => {
  await customPaymentService.disableCustomPayment();
});

const customPaymentSlice = createSlice({
  name: 'customPayment',
  initialState,
  reducers: {
    cleanUpdateStatusAction(state) {
      state.status.update = 'idle';
    },
    cleanDisabledStatusAction(state) {
      state.status.disable = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomPayment.pending, (state) => {
        state.status.load = 'loading';
      })
      .addCase(getCustomPayment.fulfilled, (state, action) => {
        state.status.load = 'success';
        state.data = action.payload;
      })
      .addCase(getCustomPayment.rejected, (state) => {
        state.status.load = 'error';
      })
      .addCase(updateCustomPayment.pending, (state) => {
        state.status.update = 'saving';
      })
      .addCase(updateCustomPayment.fulfilled, (state, action) => {
        state.status.update = 'success';
        state.data = action.meta.arg;
      })
      .addCase(updateCustomPayment.rejected, (state) => {
        state.status.update = 'error';
      })
      .addCase(disableCustomPayment.pending, (state) => {
        state.status.disable = 'saving';
      })
      .addCase(disableCustomPayment.fulfilled, (state, action) => {
        state.status.disable = 'success';
        state.data = action.meta.arg;
      })
      .addCase(disableCustomPayment.rejected, (state) => {
        state.status.disable = 'error';
      });
  },
});

export const { reducer } = customPaymentSlice;

export const { cleanUpdateStatusAction, cleanDisabledStatusAction } =
  customPaymentSlice.actions;
