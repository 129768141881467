import { StoreInfoResponse } from '@tiendanube/nexo';
import { StoreInfo } from 'domains/PartnersApps/hooks';

export const digestStoreInfo = ({
  id,
  name,
  url,
  country,
  language,
  languages,
  currency,
}: StoreInfo): StoreInfoResponse => ({
  id,
  name,
  url,
  country,
  language,
  languages,
  currency,
});
