import { useDevMode } from 'App/settings/devMode/hooks';
import { useHasExperimentalAppTag } from 'domains/PartnersApps/hooks';
import { AppInstalled } from 'domains/PartnersApps/partnersAppService';
import { getApp, getKeyQueryDevModeFromQueryParams } from './utils';

function checkDevModeEnabled(
  hasAppDevExperimentalTag: boolean,
  devModeEnabled?: boolean,
) {
  if (devModeEnabled || (!devModeEnabled && hasAppDevExperimentalTag)) {
    return true;
  }

  return false;
}

function useExperimentalApp(app?: AppInstalled): boolean {
  const hasAppDevExperimentalTag = useHasExperimentalAppTag();
  const appDevModeUrl = getKeyQueryDevModeFromQueryParams();
  const { devModeEnabled } = useDevMode();

  if (checkDevModeEnabled(hasAppDevExperimentalTag, devModeEnabled)) {
    return true;
  }

  if (!app) return false;

  const appDevInStorage = getApp(app.id);
  const emptyUrl = !app.url;

  if (emptyUrl || appDevModeUrl || appDevInStorage) {
    return true;
  }

  return false;
}

export default useExperimentalApp;
