import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDashboard } from '../../firstSaleSlice';

const getSocialMessagesEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.socialMessages,
);

const getFacebookConnection = createSimpleSelector(
  [getSocialMessagesEntity],
  (socialMessages) => socialMessages.facebookConnection,
);

export const getFacebookConnectionData = createSimpleSelector(
  [getFacebookConnection],
  (facebookConnection) => facebookConnection.data,
);
