import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getAccessToken } from 'domains/Auth/authSlice/authSelectors';
import productsServices, {
  UploadImageType,
  UploadVideoType,
} from 'domains/Catalog/Products/productsServices';
import { addImage as addImageAction } from 'domains/Catalog/Products/productsSlice';

interface UseAddImagesResult {
  addImage: UploadImageType;
  addVideo: UploadVideoType;
}

function useAddImages(): UseAddImagesResult {
  const dispatch = useAppDispatch();
  const accessToken = useSelector(getAccessToken) || '';

  const addImage: UploadImageType = useCallback(
    async (params) => {
      const result = await dispatch(addImageAction(params));
      return unwrapResult(result);
    },
    [dispatch],
  );

  const addVideo: UploadVideoType = useCallback(
    async (file) => {
      const id = await productsServices.uploadVideo(file, accessToken);
      return { id, url: '' };
    },
    [accessToken],
  );

  return { addImage, addVideo };
}

export default useAddImages;
