import { FEATURE_NUVEM_MARKETING } from 'App/features';
import { MARKETING_AUTOMATION_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';

export function useNuvemMarketing() {
  const { nuvemApps } = useNuvemApps();
  const hasNuvemMarketingApplication = !!nuvemApps['marketing-automation'];

  const { isAvailable: hasNuvemMarketingFeature } = useStatusFeatureByKey(
    FEATURE_NUVEM_MARKETING,
  );

  const [hasNuvemMarketingTag] = useHasTags([MARKETING_AUTOMATION_ENABLED]);

  return {
    hasNuvemMarketingTag,
    hasNuvemMarketingFeature,
    hasNuvemMarketingApplication,
    hasAccessToNuvemMarketing:
      hasNuvemMarketingApplication ||
      hasNuvemMarketingFeature ||
      hasNuvemMarketingTag,
  };
}
