import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  getCitiesData,
  fetchCities as fetchActionCities,
  getCitiesStatus,
} from '../../../Addresses/addressesSlice';

export interface UseGetCityOptionsResult {
  isLoading: boolean;
  isError: boolean;
  cities: { value: string; label: string }[];
  fetchCities: (countryCode: string, provinceCode: string) => Promise<string>;
}

function useGetCityOptions(): UseGetCityOptionsResult {
  const dispatch = useAppDispatch();

  const [province, setProvince] = useState('');
  const { isLoading, isError } = useSelector(getCitiesStatus);

  const fetchCities = useCallback(
    async (countryCode: string, provinceCode: string) => {
      setProvince(provinceCode);
      const result = await dispatch(
        fetchActionCities({ countryCode, provinceCode }),
      );

      const code = unwrapResult(result)[0]?.name ?? '';
      return code;
    },
    [dispatch],
  );

  const citiesData = useSelector((state: RootStateType) =>
    getCitiesData(state, province),
  );

  const cities = citiesData.map((city) => ({
    value: city.name,
    label: city.name,
  }));

  return { isLoading, isError, cities, fetchCities };
}

export default useGetCityOptions;
