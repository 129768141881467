import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './config';

export const cirrusApi = createApi({
  reducerPath: 'cirrusApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
});
