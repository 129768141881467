import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { setStorage } from 'App/storage';
import { useAppDispatch } from 'App/store';
import {
  getHasStatisticsFilters,
  getStatisticsFilters,
} from 'domains/Statistics/selectors';
import { changeFilter } from 'domains/Statistics/statisticsSlice';
import { FiltersStatisticsInterface } from 'domains/Statistics/types';
import { KEY_STORAGE_FILTERS } from 'domains/Statistics/utils';

export interface UseStatisticsFiltersResult {
  statisticsFilters: FiltersStatisticsInterface;
  handleOnChangeFilters: (filters: FiltersStatisticsInterface) => void;
  hasComparedFilter: boolean;
  hasAppliedFilters: boolean;
}

function useStatisticsFilters(): UseStatisticsFiltersResult {
  const dispatch = useAppDispatch();
  const statisticsFilters = useSelector(getStatisticsFilters);
  const { hasAppliedFilters, hasComparedFilter } = useSelector(
    getHasStatisticsFilters,
  );

  const handleOnChangeFilters = useCallback(
    async (filters: FiltersStatisticsInterface) => {
      await setStorage<FiltersStatisticsInterface>(
        KEY_STORAGE_FILTERS,
        filters,
      );
      dispatch(changeFilter(filters));
    },
    [dispatch],
  );

  return {
    statisticsFilters,
    handleOnChangeFilters,
    hasComparedFilter,
    hasAppliedFilters,
  };
}

export default useStatisticsFilters;
