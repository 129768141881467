import {
  ShippingAddressResponseDto,
  ShippingProvinceResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getProvincesByCountry = async (
  country: string,
): Promise<ShippingProvinceResponseDto[]> => {
  const { data } = await axios.get<ShippingProvinceResponseDto[]>(
    `v1/shipping/provinces/${country}`,
  );
  return data;
};

const addressesBrazil = async (
  zipcode: string,
): Promise<ShippingAddressResponseDto> => {
  const { data } = await axios.get<ShippingAddressResponseDto>(
    `v1/shipping/addresses/br/${zipcode}`,
  );
  return data;
};

export const validateCountryZipCode = async (
  zipcode: string,
  country: string,
): Promise<boolean> => {
  const response = await axios.get<boolean>(
    `v1/shipping/country/${country}/zipcode/validate/${zipcode}`,
  );
  return response.data;
};

interface ShippingServiceInterface {
  getProvincesByCountry: (
    country: string,
  ) => Promise<ShippingProvinceResponseDto[]>;
  addressesBrazil: (zipcode: string) => Promise<ShippingAddressResponseDto>;
  validateCountryZipCode: (
    zipcode: string,
    country: string,
  ) => Promise<boolean>;
}

const shippingService: ShippingServiceInterface = {
  getProvincesByCountry,
  addressesBrazil,
  validateCountryZipCode,
};

export default shippingService;
