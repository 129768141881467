import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { MessagesInterface } from '../types';

export const getMessagesState = (
  state: RootStateType,
): CombinedState<MessagesInterface> => state.customers.messages;

const getEntityById = (_: RootStateType, id: string) => id;

export const getMessagesFilters = createSelector(
  getMessagesState,
  (customers) => customers.filters,
);

export const getMessagesStatus = createSelector(
  getMessagesState,
  (messages) => ({
    isLoading: messages.status === 'loading',
    isError: messages.status === 'error',
    isSuccess: messages.status === 'success',
  }),
);

export const getMessages = createSelector(
  getMessagesState,
  (messages) => messages.entities || [],
);

export const getMessagesIds = createSelector(
  getMessagesState,
  (messages) => messages.ids,
);

export const getMessagesRefreshStatus = createSelector(
  getMessagesState,
  (messageState) => ({
    isRefreshError: messageState.refreshStatus === 'error',
    isRefreshSuccess: messageState.refreshStatus === 'success',
    isRefreshing: messageState.refreshStatus === 'refreshing',
  }),
);

export const getMessagesPagination = createSelector(
  getMessagesState,
  (messageState) => messageState.pagination,
);

export const getMessagesEntities = createSelector(
  getMessagesState,
  (messageState) => messageState.entities,
);

export const getMessageById = createSelector(
  getMessagesEntities,
  getEntityById,
  (messagesEntities, id) => messagesEntities[id],
);

export const getUnreadMessagesCount = createSelector(
  getMessagesState,
  (messages) => messages.unreadMessagesCount,
);
