import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Domain } from '@tiendanube/common/src/enums';
import { Modal, Text } from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useToast } from 'commons/hooks';
import { useProductsList } from 'domains/Catalog/Products/pages/hooks';
import { getTotalProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useBulkActions } from '../../BulkActionsProvider';
import { BulkActionTypeEnum } from '../../hooks/useBulkActionProducts/types';
import { useActionProducts } from '../useActionProducts';

function ModalsBulkActions(): JSX.Element | null {
  const { t } = useTranslation(Domain.CATALOG);
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const {
    selectedAction,
    clearBulkActions,
    selectedRowsId,
    selectAll,
    showModal,
    closeModal,
    toggleEditMode,
  } = useBulkActions();
  const productsCount = useSelector(getTotalProducts);

  const count = selectAll ? productsCount : selectedRowsId.length;

  const { updateProducts, deleteProducts } = useActionProducts();
  const { refreshProductsList } = useProductsList();

  const config = {
    [BulkActionTypeEnum.PUBLISH]: {
      title: t('products.modalPublish.title', { count }),
      body: t('products.modalPublish.body', { count }),
      cancel: t('products.modalPublish.cancel'),
      ok: t('products.modalPublish.ok'),
      progress: t('products.modalPublish.progress', { count }),
      success: t('products.modalPublish.success', { count }),
      error: t('products.modalPublish.error', { count }),
      appearanceOk: 'primary',
      action: async () =>
        await updateProducts({
          ids: selectedRowsId,
          product: {
            published: true,
          },
        }),
    },
    [BulkActionTypeEnum.DRAFT]: {
      title: t('products.modalDraft.title', { count }),
      body: t('products.modalDraft.body', { count }),
      cancel: t('products.modalDraft.cancel'),
      ok: t('products.modalDraft.ok'),
      progress: t('products.modalDraft.progress', { count }),
      success: t('products.modalDraft.success', { count }),
      error: t('products.modalDraft.error', { count }),
      appearanceOk: 'primary',
      action: async () =>
        await updateProducts({
          ids: selectedRowsId,
          product: {
            published: false,
          },
        }),
    },
    [BulkActionTypeEnum.DELETE]: {
      title: t('products.modalDelete.title', { count }),
      body: t('products.modalDelete.body', { count }),
      cancel: t('products.modalDelete.cancel'),
      ok: t('products.modalDelete.ok'),
      progress: t('products.modalDelete.progress', { count }),
      success: t('products.modalDelete.success', { count }),
      error: t('products.modalDelete.error', { count }),
      appearanceOk: 'danger',
      action: async () => {
        await deleteProducts(selectedRowsId);
        await refreshProductsList();
      },
    },
  };

  const mustRender =
    showModal && selectedAction
      ? Object.keys(config).includes(selectedAction)
      : false;

  if (!mustRender) return null;

  const {
    title,
    body,
    cancel,
    ok,
    appearanceOk,
    progress,
    success,
    error,
    action,
  } = config[selectedAction as BulkActionTypeEnum];

  const handlePrimaryButton = async () => {
    closeModal();
    addToastProgress({
      label: progress,
    });
    try {
      await action();
      closeToastProgress();
      addToast({
        label: success,
        appearance: 'success',
      });
      toggleEditMode();
      clearBulkActions();
    } catch (e) {
      closeToastProgress();
      addToast({
        label: error,
        appearance: 'danger',
      });
    }
  };

  return (
    <Modal
      show
      title={title}
      onDismiss={closeModal}
      primaryAction={{
        children: ok,
        onClick: handlePrimaryButton,
        appearance: appearanceOk,
      }}
      secondaryAction={{
        children: cancel,
        onClick: closeModal,
      }}
    >
      <Text>{body}</Text>
    </Modal>
  );
}
export default ModalsBulkActions;
