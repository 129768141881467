import { BaseCard, Stack, Text } from '@tiendanube/components';

function CustomPaymentSkeleton(): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Header>
          <Stack justify="space-between">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="small" />
          </Stack>
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column>
            <Text.Skeleton width="fill" />
            <Text.Skeleton width="fill" size="featured" />
            <Text.Skeleton width="fill" />
            <Text.Skeleton width="fill" size="featured" />
            <Text.Skeleton width="fill" />
            <Text.Skeleton width="fill" size="featured" />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Stack justify="space-between">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="small" />
          </Stack>
        </BaseCard.Header>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Stack justify="space-between">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="small" />
          </Stack>
        </BaseCard.Header>
      </BaseCard>
    </>
  );
}

export default CustomPaymentSkeleton;
