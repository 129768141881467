import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { seoRedirectsRoutes } from 'domains/Configurations/SeoRedirects/seoRedirectsRoutes';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import { LOCATIONS_BASE_ROUTE } from 'domains/Shipping/Locations/locationsRoutes';

export const MENU_PATHS = {
  locations: LOCATIONS_BASE_ROUTE,
  shipping: SHIPPING_METHODS_BASE_ROUTE,
  payments: PAYMENTS_BASE_ROUTE,
  metafields: '/settings/metafields',
  emails: CONFIGURATIONS_ROUTES.notifications,
  i18n: CONFIGURATIONS_ROUTES.i18n,
  checkoutOptions: CONFIGURATIONS_ROUTES.settingsCheckout,
  externalCodes: CONFIGURATIONS_ROUTES.externalCodes,
  redirects301: seoRedirectsRoutes.redirects,
  domains: CONFIGURATIONS_ROUTES.domains,
  businessData: ACCOUNT_ROUTES.businessData,
  authenticationFactor: ACCOUNT_ROUTES.authenticationFactor,
} as const;

export type PathsType = keyof typeof MENU_PATHS;
