import { useState } from 'react';
import { Icon, Link } from '@nimbus-ds/components';
import { EmptyMessage, HelpLink } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Action, Domain } from '@tiendanube/common/src/enums';
import {
  Button,
  DataTable,
  EmptyState,
  ErrorState,
  Stack,
  Text,
} from '@tiendanube/components';
import {
  PlusCircleIcon,
  SearchIcon,
  ExternalLinkIcon,
} from '@tiendanube/icons';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus } from 'commons/components';
import { CAN_LINK_ABOUT_ORDERS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { SortType } from 'commons/types';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useBulkActionOrders,
  useOrdersList,
} from 'domains/Orders/Orders/hooks';
import {
  ORDERS_LIST_TIMEOUT_ERROR,
  ORDERS_PER_PAGE,
} from 'domains/Orders/Orders/pages/constants';
import {
  OrderResultsDesktopSkeleton,
  ResultHeaderDesktop,
  ResultRowDesktop,
} from './components';
import emptyOrders from '../../empty-orders.png';
import { useFixedBulkHeaderScroll } from '../../hooks/useFixedBulkHeaderScroll';
import { OrderSelectInBulk } from '../OrderSelectInBulk';
import './OrderResultsDesktop.scss';

interface OrderResultsMobileProps {
  onHandleBulkAction: (ids: string[], value: Action) => void;
}

function OrderResultsDesktop({
  onHandleBulkAction,
}: OrderResultsMobileProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const { scrollToTop } = useLayoutScroll();
  const language = useTranslationLanguage();
  useFixedBulkHeaderScroll();

  const {
    isLoading,
    isSuccess,
    isError,
    statusSuccess,
    ordersIds,
    pagination,
    filters,
    hasFilters,
    getOrdersList,
    goToPage,
  } = useOrdersList();

  const [sortByDate, setSortByDate] = useState<SortType>('desc');

  const { selectedRowsId, bulkAction, handleOnSelectRow } = useBulkActionOrders(
    {
      rowsId: ordersIds,
      onHandleBulkAction,
      actionElement: <OrderSelectInBulk />,
    },
  );

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  const isEmptyList = ordersIds.length === 0;
  const isFirstPage = pagination.currentPage === 1;
  const showSkeleton =
    isLoading && (isEmptyList || filters.page !== 1 || !isFirstPage);

  const sortedOrdersIds =
    sortByDate === 'desc' ? ordersIds : [...ordersIds].reverse();

  if (
    isError &&
    isEmptyList &&
    statusSuccess === ORDERS_LIST_TIMEOUT_ERROR &&
    hasFilters
  )
    return (
      <EmptyMessage
        title={t('timeoutErrorResults.title')}
        text={t('timeoutErrorResults.text')}
        icon={<SearchIcon size={30} />}
      />
    );
  if (isError && isEmptyList)
    return (
      <ErrorState
        title={t('errorResults.title')}
        action={{
          children: t('errorResults.action'),
          onClick: getOrdersList,
        }}
      />
    );
  if (isSuccess && isEmptyList) {
    if (hasFilters) {
      return (
        <EmptyMessage
          title={t('withoutResults.title')}
          text={t('withoutResults.text')}
          icon={<SearchIcon size={30} />}
        />
      );
    }
    return (
      <EmptyState image={emptyOrders} title={t('emptyResults.title')}>
        <Stack spacing="base" column align="flex-start">
          <Stack.Item>
            <Text>{`${t('emptyResults.body')}`}</Text>
          </Stack.Item>
          <Stack.Item>
            <Button
              icon={PlusCircleIcon}
              appearance="primary"
              onClick={goToAdmin('draft_orders/new')}
            >
              {`${t('draftOrders.addDraftOrder')}`}
            </Button>
          </Stack.Item>
        </Stack>
      </EmptyState>
    );
  }

  return (
    <div className="stratus--orders-result-desktop stratus--flashing-table-color">
      <Stack spacing="tight" align="stretch" column>
        <DataTable
          bulkAction={bulkAction}
          selectedRowsId={selectedRowsId}
          onSelectRow={handleOnSelectRow}
        >
          <ResultHeaderDesktop
            sortByDate={sortByDate}
            setSortByDate={setSortByDate}
          />
          {showSkeleton && <OrderResultsDesktopSkeleton />}
          {!showSkeleton &&
            sortedOrdersIds.map((id) => <ResultRowDesktop key={id} id={id} />)}
        </DataTable>
        {!isLoading && (
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults || 0}
            itemName={t('pagination.items')}
            page={pagination.currentPage}
            perPage={ORDERS_PER_PAGE}
            onPageSelect={handleSelectPage}
          />
        )}
      </Stack>
      <HelpLink>
        <Link
          as="a"
          href={CAN_LINK_ABOUT_ORDERS[language]}
          target="_blank"
          appearance="primary"
          textDecoration="none"
        >
          {t('helpText')}
          <Icon source={<ExternalLinkIcon />} color="currentColor" />
        </Link>
      </HelpLink>
    </div>
  );
}

export default OrderResultsDesktop;
