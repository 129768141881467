import { Card, Select, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

function OcaLabelSettingCardSkeleton() {
  const t = useTranslationFulfillmentOrders('ocaPrePrint.card');

  return (
    <div
      style={{
        width: '49%',
      }}
    >
      <Card>
        <Card.Header>
          <Stack spacing="tight">
            <Title.Skeleton as="h4" />
          </Stack>
        </Card.Header>
        <Card.Body>
          <Stack column align="stretch">
            <Stack column spacing="tight" align="baseline">
              <Text.Skeleton width="30%" />
              <Text.Skeleton width="80%" />
            </Stack>
            <hr />
            <Stack column spacing="tight" align="stretch" alignSelf="auto">
              <Text fontWeight="bold">{t('origin')}</Text>
              <Select.Skeleton width="100%" />
              <Select.Skeleton width="100%" />
            </Stack>
            <hr />
            <Stack column spacing="tight" align="stretch">
              <Text fontWeight="bold">{t('box')}</Text>
              <Select.Skeleton width="100%" />
            </Stack>
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Text.Skeleton width="30%" />
        </Card.Footer>
      </Card>
    </div>
  );
}

export default OcaLabelSettingCardSkeleton;
