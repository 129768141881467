import {
  Accordion,
  Box,
  Button,
  Card,
  Spinner,
  Title,
} from '@nimbus-ds/components';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import { useGetSubscriptions } from 'domains/Billing/Checkout/hooks';
import { PaymentDataContent } from 'domains/Billing/Checkout/pages/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useAdvancePlanCharge } from '../../hooks';
import SummaryError from '../../SummaryError';
import SummarySkeleton from '../../SummarySkeleton';

function SummaryForAdvancingCharge() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.advanceCharge',
  );
  const {
    total,
    refreshSubscriptions,
    subscriptions,
    status: subscriptionStatus,
    currency,
  } = useGetSubscriptions();
  const formatCurrency = useFormatCurrency();
  const storePaymentDiscount = useGetStorePaymentDiscount();

  const {
    handlePayAdvancedPlanCharge,
    isLoading: isCreatingAdvancedCharge,
    createStatus,
    setupStatus: pendingChargesStatus,
  } = useAdvancePlanCharge();

  const { isLoading, isError } = combineStatus(
    subscriptionStatus,
    pendingChargesStatus,
  );

  useToastStatus({ status: createStatus, error: t('errorCreatingCharge') });

  if (isLoading) return <SummarySkeleton />;

  if (isError) return <SummaryError onClick={refreshSubscriptions} />;

  if (!total) return null;

  return (
    <Card padding="none">
      <Box gap="2" display="flex" flexDirection="column">
        <Accordion selectedDefault={storePaymentDiscount ? '0' : undefined}>
          <Accordion.Item index="0">
            <Accordion.Header borderTop="none">
              <Box width="100%">
                <Title as="h3" textAlign="left">
                  {`${t('title')}: ${formatCurrency(total, currency)}`}
                </Title>
              </Box>
            </Accordion.Header>

            <Accordion.Body>
              {subscriptions && (
                <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
                  <PaymentDataContent charges={subscriptions} />
                </Box>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Box paddingRight="4" paddingBottom="4" paddingLeft="4">
          <Card.Footer>
            <Button
              appearance="primary"
              disabled={isCreatingAdvancedCharge}
              onClick={handlePayAdvancedPlanCharge}
            >
              {isCreatingAdvancedCharge && (
                <Spinner color="currentColor" size="small" />
              )}
              {t('goToPay')}
            </Button>
          </Card.Footer>
        </Box>
      </Box>
    </Card>
  );
}

export default SummaryForAdvancingCharge;
