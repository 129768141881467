import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Select,
  InterfaceSelectOptionGroup,
  InterfaceNameValue,
} from '@tiendanube/components';
import { SortBySelectType } from 'domains/Online';

interface SortBySelectProps {
  value: SortBySelectType;
  onChange: ({ name, value }: InterfaceNameValue) => void;
}
function SortBySelect({ value, onChange }: SortBySelectProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const options: SortBySelectType[] = [
    'price-ascending',
    'price-descending',
    'alpha-ascending',
    'alpha-descending',
    'created-at-ascending',
    'created-at-descending',
    'best-selling',
    'user',
  ];

  const labelOptions: InterfaceSelectOptionGroup[] = options.map((value) => ({
    value,
    label: t(`products.filters.sortBy.${value}`),
  }));

  return (
    <Select
      label={t('products.filter.sortBy')}
      name="sortBy"
      value={value}
      options={labelOptions}
      onChange={onChange}
    />
  );
}

export default SortBySelect;
