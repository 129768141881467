import { ErrorState } from '@tiendanube/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CouponsListErrorProps {
  onRetryError: () => void;
}

export function CouponsListError({
  onRetryError,
}: Readonly<CouponsListErrorProps>) {
  const t = useTranslationMarketing('coupons.errorResults');
  return (
    <ErrorState
      title={t('title')}
      action={{
        children: t('action'),
        onClick: onRetryError,
      }}
    />
  );
}
