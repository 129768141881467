import { useCallback } from 'react';
import { differenceInDays } from 'date-fns';
import useStorage from '../useStorage';

/**
 * Hook that implements logic for using the local storage to keep track of if a user has dismissed a certain component or not.
 *
 * @param key The base key string that will be used to save data to the local storage.
 * @param expiresAfterDays (optional) Indicates if the data saved to storage should be ignored after a determined number of days.
 * in different contexts.
 *
 */
function useDismissibleComponent(key: string, expiresAfterDays?: number) {
  const [dismissedDate, setDismissedDate, loaded] = useStorage<
    string | undefined
  >(key, undefined, true);

  const shouldShow =
    loaded &&
    (!dismissedDate ||
      (!!expiresAfterDays &&
        differenceInDays(new Date(), new Date(dismissedDate)) >=
          expiresAfterDays));

  const dismiss = useCallback(() => {
    setDismissedDate(new Date().toISOString());
  }, [setDismissedDate]);

  return {
    shouldShow,
    dismiss,
  };
}

export default useDismissibleComponent;
