import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { DataTable, Stack } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus } from 'commons/components';
import { MessagesListHeader, MessagesListRow } from './components/';
import MessagesListDesktopSkeleton from './components/MessagesListDesktopSkeleton';
import { useBulkActionMessages, useMessages } from '../../hooks';
import { useBulkActions } from '../BulkActionsProvider';

function MessagesListDesktop(): JSX.Element {
  const { t } = useTranslation([DomainEnum.CUSTOMERS]);
  const { messagesIds, isLoading, isRefreshing, pagination, goToPage } =
    useMessages();
  const { selectedRowsId } = useBulkActions();
  const { bulkAction, handleOnSelectRow } = useBulkActionMessages({
    rowsId: messagesIds,
  });
  const { scrollToTop } = useLayoutScroll();

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };
  const loading = isLoading || isRefreshing;

  return (
    <div className="stratus--flashing-table-color">
      <Stack spacing="tight" align="stretch" column>
        <DataTable
          bulkAction={bulkAction}
          selectedRowsId={selectedRowsId}
          onSelectRow={!loading ? handleOnSelectRow : undefined}
        >
          <MessagesListHeader />
          {loading && <MessagesListDesktopSkeleton />}
          {!loading &&
            messagesIds.map((id) => (
              <MessagesListRow messageId={id} key={id} />
            ))}
        </DataTable>
        {!loading && (
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults}
            itemName={t('messages.item')}
            page={pagination.currentPage}
            perPage={pagination.perPage}
            onPageSelect={handleSelectPage}
          />
        )}
      </Stack>
    </div>
  );
}

export default MessagesListDesktop;
