import { Stack, Text, DataTable, Link } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import { dateFormat, Format } from 'commons/utils/date';
import {
  MessagesActionsRow,
  MessagesListItemLabelReplied,
  MessagesListItemLabelType,
  SendEmailModal,
} from 'domains/Customers/components';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import { useMessageById } from '../../../../hooks';

interface MessagesListRowProps {
  messageId: string;
}

export const cellWidthSettings = {
  messagesColBasis: 400,
  dateColBasis: 170,
  stateColBasis: 200,
  actionsColBasis: 50,
};

function MessagesListRow({
  messageId,
}: MessagesListRowProps): JSX.Element | null {
  const {
    messageById,
    removeMessage,
    readMessage,
    unreadMessage,
    spamMessage,
  } = useMessageById(messageId);
  const { goTo } = useNavegate();
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();
  const t = useTranslationCustomers();

  if (!messageById) return null;

  const {
    customerId,
    name,
    date,
    message,
    type,
    replied,
    phone,
    email,
    product,
    order,
  } = messageById;

  const onActionSendEmail = () => openSendEmailModal();

  const actions = (
    <MessagesActionsRow
      type={type}
      phone={phone}
      replied={replied}
      onActionDelete={removeMessage}
      onActionRead={readMessage}
      onActionUnread={unreadMessage}
      onActionSpam={spamMessage}
      onActionSendEmail={onActionSendEmail}
      hasCustomerId={!!customerId}
    />
  );

  const handleClick = () => goTo(`/customers/${customerId}`);

  const handleClickOrder = () => goTo(`/orders/${order?.id}`);

  const { Row, Cell } = DataTable;

  const notShowActionsLabelReplied =
    type !== 'newsletter' && type !== 'order_cancellation';

  return (
    <>
      <Row
        id={messageId}
        onClick={customerId ? handleClick : undefined}
        align="center"
      >
        <Cell basis={cellWidthSettings.messagesColBasis}>
          <Stack align="flex-start" spacing="tight" column>
            {customerId ? (
              <Link appearance="primary" onClick={handleClick}>
                {name}
              </Link>
            ) : (
              <Text bold>{name}</Text>
            )}
            {product && (
              <Stack.Item>
                <Text>{t('messages.product')}</Text>{' '}
                <Link appearance="primary" href={product.url} target="_blank">
                  {product.name}
                </Link>
              </Stack.Item>
            )}
            {order && (
              <Stack.Item>
                <Text>{t('messages.order.title')}</Text>{' '}
                <Link appearance="primary" onClick={handleClickOrder}>
                  {t('messages.order.descripcion', {
                    orderNumber: order.number,
                  })}
                </Link>
              </Stack.Item>
            )}
            <Text>{message}</Text>
          </Stack>
        </Cell>
        <Cell basis={cellWidthSettings.dateColBasis}>
          <Text>{dateFormat(date, Format.DD_MM_YYYY)}</Text>
        </Cell>
        <Cell basis={cellWidthSettings.stateColBasis}>
          <Stack spacing="tight">
            <Stack.Item>
              <MessagesListItemLabelType id={messageId} type={type} />
            </Stack.Item>
            {notShowActionsLabelReplied && (
              <Stack.Item>
                <MessagesListItemLabelReplied
                  id={messageId}
                  replied={replied}
                />
              </Stack.Item>
            )}
          </Stack>
        </Cell>
        <Cell basis={cellWidthSettings.actionsColBasis}>{actions}</Cell>
      </Row>
      {showSendEmailModal && (
        <SendEmailModal
          email={email}
          customerId={customerId}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default MessagesListRow;
