import { TransactionFeePaymentDetailDto } from '@tiendanube/common';
import { DataList, Stack } from '@tiendanube/components';
import MonthListSkeleton from './MonthListSkeleton/MonthListSkeleton';
import MonthRow from '../MonthRow';

interface MonthListProps {
  payments: TransactionFeePaymentDetailDto[];
  isLoading: boolean;
  split: boolean;
}

function MonthList({
  payments,
  isLoading,
  split,
}: MonthListProps): JSX.Element {
  if (isLoading) return <MonthListSkeleton />;
  return (
    <Stack column align="stretch">
      <DataList>
        {payments.map((payment) => (
          <MonthRow key={payment.id} payment={payment} split={split} />
        ))}
      </DataList>
    </Stack>
  );
}

export default MonthList;
