import { useState, useEffect } from 'react';
import { Card, Input, Text } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { NewLineToBr } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import { useToggle } from 'commons/useToggle';
import { countNewLineInText } from 'commons/utils';

interface RemarksCardProps {
  remarks: string;
  title: string;
  errorMessage: string;
  onChange?: ({ value, name }: { value: string; name: string }) => void;
  onRemark?: (t: string) => Promise<void>;
  readOnly?: boolean;
}

function RemarksCard({
  remarks,
  title,
  errorMessage,
  onChange,
  onRemark,
  readOnly = false,
}: RemarksCardProps): JSX.Element {
  const { addToast } = useToast();

  const [showInput, setShowInput] = useToggle();
  const [currentRemarks, setCurrentRemarks] = useState<string>(remarks);
  const [saveRemark, , error, clearError] = useAsyncFunc(async () => {
    await onRemark?.(currentRemarks);
  });

  useEffect(() => {
    if (error) {
      addToast({
        appearance: 'danger',
        label: errorMessage,
      });
      setCurrentRemarks(remarks);
      clearError();
    }
  }, [error, remarks, addToast, clearError, errorMessage]);

  const handleChangeInput = ({ value }) => {
    setCurrentRemarks(value);
    onChange?.({ value, name: 'remarks' });
  };

  const handleOutInput = async () => {
    if (remarks !== currentRemarks) {
      saveRemark();
    }
    setShowInput();
  };

  return (
    <Card
      title={title}
      headerIcon={readOnly ? undefined : EditIcon}
      onClickHeaderIcon={readOnly ? undefined : setShowInput}
    >
      {!showInput && !!currentRemarks && (
        <Text background>
          <NewLineToBr text={currentRemarks} />
        </Text>
      )}
      {showInput && (
        <Input
          onChange={handleChangeInput}
          onBlur={handleOutInput}
          value={currentRemarks}
          rows={countNewLineInText(currentRemarks)}
          maxLength={1000}
          name="remark"
          placeholder=""
          multiRows
          focused
        />
      )}
    </Card>
  );
}

export default RemarksCard;
