import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

interface BingMetaProps {
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  bingMeta?: string;
}

export function BingMeta({
  onChangeTextArea,
  bingMeta,
}: Readonly<BingMetaProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.otherServices.bingMeta',
  );

  return (
    <SplitSection title={t('title')}>
      <FormField.Textarea
        onChange={onChangeTextArea}
        value={bingMeta}
        name="bingMeta"
        id="bingMeta"
        placeholder={t('placeholder')}
        label={t('label')}
        lines={6}
      />
    </SplitSection>
  );
}
