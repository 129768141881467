import { useCallback } from 'react';
import { useTrackingAnalytics } from 'App/settings/trackingAnalytics';
import { useAppDispatch } from 'App/store';
import { refreshInfoToSync } from '../../authSlice';

type UseRefreshInfoStoreUser = (noCache: boolean) => Promise<void>;

function useRefreshInfoStoreUser(): UseRefreshInfoStoreUser {
  const dispatch = useAppDispatch();
  const { executeTrackingRedirect } = useTrackingAnalytics();

  const refreshInfo = useCallback(
    async (noCache = false) => {
      await dispatch(refreshInfoToSync(noCache));
      executeTrackingRedirect();
    },
    [dispatch, executeTrackingRedirect],
  );

  return refreshInfo;
}

export default useRefreshInfoStoreUser;
