import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCustomerMetafields,
  getCustomerMetafieldsData,
  getCustomerMetafieldsStatus,
} from 'domains/Customers/Customers/CustomerMetafields/customerMetafieldsSlice';

export default function useCustomerMetafields(id?: string) {
  const dispatch = useAppDispatch();

  const status = useSelector(getCustomerMetafieldsStatus);
  const customerMetafields = useSelector(getCustomerMetafieldsData);

  const fetchCustomerMetafields = useCallback(async () => {
    if (id) {
      await dispatch(getCustomerMetafields(id));
    }
  }, [dispatch, id]);

  return {
    fetchCustomerMetafields,
    customerMetafields,
    status,
  };
}
