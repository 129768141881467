import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethodSelection } from './types';
import { initialState } from './utils';

const checkout = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    selectPaymentMethod(
      state,
      { payload }: PayloadAction<PaymentMethodSelection>,
    ) {
      state.paymentMethodSelected = payload;
    },
  },
});

export const { reducer } = checkout;

export const { selectPaymentMethod } = checkout.actions;
