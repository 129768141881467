import { Table, Text } from '@nimbus-ds/components';
import { FreeShippingItemListResponseDto } from '@tiendanube/common';
import {
  Actions,
  DeliveryMethodsItem,
  DeliveryZonesItem,
  ShowMoreCategories,
  StatusTagItem,
} from 'domains/Marketing/FreeShipping/components';
import FreeShippingListRowSkeleton from './FreeShippingListRowSkeleton';

interface FreeShippingListRowProps {
  freeShipping: FreeShippingItemListResponseDto;
}

function FreeShippingListRow({
  freeShipping: {
    methods,
    priceMinimum,
    categories,
    categoriesVisibility,
    zones,
    enable,
    id,
  },
}: Readonly<FreeShippingListRowProps>) {
  return (
    <Table.Row>
      <Table.Cell>
        <DeliveryMethodsItem methods={methods} id={id} />
      </Table.Cell>
      <Table.Cell>
        <Text>{priceMinimum}</Text>
      </Table.Cell>
      <Table.Cell>
        <ShowMoreCategories
          categories={categories}
          categoriesVisibility={categoriesVisibility}
        />
      </Table.Cell>
      <Table.Cell>
        <DeliveryZonesItem zones={zones} />
      </Table.Cell>
      <Table.Cell>
        <StatusTagItem status={enable} />
      </Table.Cell>
      <Table.Cell>
        <Actions id={id} status={enable} />
      </Table.Cell>
    </Table.Row>
  );
}

FreeShippingListRow.Skeleton = FreeShippingListRowSkeleton;

export default FreeShippingListRow;
