import { useState } from 'react';
import {
  InventoryLevelsRequestDto,
  LocationResponseDto,
} from '@tiendanube/common';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function useStockDistribution(
  location: LocationResponseDto[],
  initialinventory: InventoryLevelsRequestDto[],
  inventory: InventoryLevelsRequestDto[] = [],
  setInventory: (locationsSelected: InventoryLevelsRequestDto[]) => void,
) {
  const t = useTranslationCatalog();

  const [locationsSelected, setLocationsSelected] = useState<
    InventoryLevelsRequestDto[]
  >([]);

  const toggleLocations = (id: string) => {
    const index = locationsSelected.findIndex(
      ({ locationId }) => locationId === id,
    );
    if (index === -1) {
      setLocationsSelected([
        ...locationsSelected,
        { locationId: id, stock: null },
      ]);
      setInventory([...inventory, { locationId: id, stock: null }]);
    } else {
      setLocationsSelected(
        locationsSelected.filter(({ locationId }) => locationId !== id),
      );
      setInventory(inventory.filter(({ locationId }) => locationId !== id));
    }
  };

  const getLocationName = (locationId: string) => {
    const loc = location.find(({ id }) => locationId === id);
    const name = loc?.name ?? '';
    const main = loc?.main ? ` (${t('inventory.mainLocation')})` : '';
    return `${name}${main}`;
  };

  const locationsNotSelected = location
    .filter(
      ({ id }) => !initialinventory.some(({ locationId }) => id === locationId),
    )
    .map(({ id }) => ({ locationId: id, stock: null }));

  const getLocationStock = (locationId: string) =>
    locationsSelected.find(({ locationId: id }) => locationId === id)?.stock ??
    null;

  const getInventoryStock = (locationId: string) =>
    inventory.find(({ locationId: id }) => locationId === id)?.stock ?? null;

  const isChecked = (id: string) =>
    locationsSelected.some(({ locationId }) => locationId === id);

  const changeStockByLocation = ({ locationId, stock }) => {
    const updateStock = (prevLocationsSelected: InventoryLevelsRequestDto[]) =>
      prevLocationsSelected.map((location: InventoryLevelsRequestDto) => {
        if (location.locationId === locationId) {
          return { ...location, stock };
        }
        return location;
      });
    setLocationsSelected(updateStock(locationsSelected));
    setInventory(updateStock(inventory));
  };

  return {
    isChecked,
    toggleLocations,
    changeStockByLocation,
    getLocationName,
    getLocationStock,
    getInventoryStock,
    locationsSelected,
    locationsNotSelected,
  };
}

export default useStockDistribution;
