import { ChangeEvent, FocusEvent } from 'react';
import { Button } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AuthenticationFactorType } from '@tiendanube/common';
import { LayoutBackground } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { HeaderContent, Spinner, Stack } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useVerificationCodeHandlers } from 'domains/Account/hooks';
import Logo from 'domains/Auth/components/Logo';
import { BACKGROUND_IMAGE_URL } from 'domains/Auth/constants';
import { validationSchema } from './validationSchema';
import { AUTH_ROUTES } from '../../authRoutes';

interface VerifyCodeValues {
  code: string;
}

const initialValues = {
  code: '',
};

interface AuthenticationFactorCodePageProps {
  authenticationFactorType: AuthenticationFactorType;
  helpLink: JSX.Element;
  codeLength: number;
  modifyCode?: (code: string) => string;
  backButtonPath?: string;
  translationKeyPrefix: string;
  beforeSubmit?: () => void;
}

function AuthenticationFactorCodePage({
  authenticationFactorType,
  helpLink,
  modifyCode = (code) => code,
  codeLength,
  backButtonPath,
  translationKeyPrefix,
  beforeSubmit,
}: Readonly<AuthenticationFactorCodePageProps>): JSX.Element {
  const { t } = useTranslation(['login']);
  const {
    handleSubmit: handleVerifySubmit,
    isSuccess,
    isError,
    requestHasBeenExecutedAndFailed,
    isLoading,
    isRequestTimeoutError,
  } = useVerificationCodeHandlers();
  const { goTo } = useNavegate();

  useToastStatus({
    error: t('loginInternalServerError'),
    status: isError ? 'error' : 'idle',
  });

  const { values, handleOnChange, handleOnSubmit, handleOnBlur } = useForm<
    VerifyCodeValues,
    VerifyCodeValues
  >({
    initialValues,
    validationSchema,
    onSubmit: async ({ code }: VerifyCodeValues) =>
      handleVerifySubmit({ code, type: authenticationFactorType }),
    validateOnBlur: ['code'],
  });

  const disableSubmitButton =
    values.code.length !== codeLength || isLoading || isSuccess;

  if (isRequestTimeoutError) {
    return <Redirect to={AUTH_ROUTES.logout} />;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleOnChange({ name: e.target.name, value: modifyCode(e.target.value) });
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    handleOnBlur({ name: e.target.name, value: modifyCode(e.target.value) });
  };

  const onClick = () => {
    if (beforeSubmit) beforeSubmit();
    handleOnSubmit();
  };

  return (
    <LayoutBackground image={BACKGROUND_IMAGE_URL}>
      <Logo />
      <HeaderContent
        title={t(`authenticationFactor.${translationKeyPrefix}.title`)}
        subtitle={t(`authenticationFactor.${translationKeyPrefix}.subtitle`)}
      />
      <br />
      <Stack column justify="space-between" align="stretch">
        <FormField.Input
          onChange={handleChange}
          value={values.code}
          name="code"
          type="text"
          required
          appearance={requestHasBeenExecutedAndFailed ? 'danger' : undefined}
          label={t(`authenticationFactor.${translationKeyPrefix}.codeLabel`)}
          onBlur={handleBlur}
          showHelpText={requestHasBeenExecutedAndFailed}
          helpText={t('authenticationFactor.codeValidation')}
          maxLength={codeLength}
        />
        {helpLink}

        <Stack justify="flex-start">
          {!!backButtonPath && (
            <Button onClick={() => goTo(backButtonPath)}>
              {t('authenticationFactor.cancelLabel')}
            </Button>
          )}
          <Button
            onClick={onClick}
            appearance="primary"
            disabled={disableSubmitButton}
          >
            <Spinner show={isLoading} appearance="light" size="base" />
            {t('authenticationFactor.submitLabel')}
          </Button>
        </Stack>
      </Stack>
    </LayoutBackground>
  );
}

export default AuthenticationFactorCodePage;
