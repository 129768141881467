import { useSelector } from 'react-redux';
import { getUsersInfQuantity, getUsersQuantity } from '../../selectors';

function useGetUsersQuantity() {
  const usersDesktopQuantity = useSelector(getUsersQuantity);
  const usersMobileQuantity = useSelector(getUsersInfQuantity);

  return usersDesktopQuantity || usersMobileQuantity || 0;
}

export default useGetUsersQuantity;
