import { useSelector } from 'react-redux';
import { getCanEnterAdmin } from 'domains/Auth/authSlice/authSelectors';

function useCanEnterAdmin() {
  const canEnterAdmin = useSelector(getCanEnterAdmin);

  return canEnterAdmin;
}

export default useCanEnterAdmin;
