import { Icon, Tag } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function PrimaryDomainTag() {
  const t = useTranslationConfigurations('domains');

  return (
    <Tag appearance="primary">
      <Icon color="primary-textLow" source={<StarIcon size={12} />} />
      {t('tags.principal')}
    </Tag>
  );
}
