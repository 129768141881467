import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getProvincesByCountry } from '../../../ShippingMethods/shippingSlice';
import { getProvincesByCountryOptions } from '../../../ShippingMethods/shippingSlice/shippingSelectors';

export interface UseGetProvinceOptionsResult {
  provinces: { value: string; label: string }[];
  isLoading: boolean;
  isError: boolean;
  fetchProvinces: (country: string) => Promise<string>;
}

function useGetProvincesOptions(): UseGetProvinceOptionsResult {
  const dispatch = useAppDispatch();

  const { provinces, isLoading, isError } = useSelector(
    getProvincesByCountryOptions,
  );

  const fetchProvinces = useCallback(
    async (country: string) => {
      const result = await dispatch(getProvincesByCountry(country));
      const code = unwrapResult(result)[0]?.code ?? '';
      return code;
    },
    [dispatch],
  );

  return { isLoading, isError, provinces, fetchProvinces };
}

export default useGetProvincesOptions;
