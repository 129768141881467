import { VirtualDataList } from 'commons/components';
import useCoupons from 'domains/Marketing/Coupons/hooks/useCoupons';
import { CouponsListMobileSkeleton, CouponsListRow } from './components';

const itemContent = (index, id) => <CouponsListRow index={index} id={id} />;

export function CouponsListMobile() {
  const {
    couponsIds,
    isLoading,
    fetchCoupons,
    isRefreshing,
    fetchMoreCoupons,
  } = useCoupons();

  const handleRefresh = async () => {
    if (isLoading || isRefreshing) return;
    await fetchCoupons();
  };

  const shouldShowSpinner = isRefreshing && couponsIds.length !== 0;

  return (
    <>
      {isLoading && <CouponsListMobileSkeleton />}
      {couponsIds.length > 0 && (
        <VirtualDataList
          isPullable
          data={couponsIds}
          itemContent={itemContent}
          onRefresh={handleRefresh}
          endReached={fetchMoreCoupons}
          showFooter={shouldShowSpinner}
        />
      )}
    </>
  );
}
