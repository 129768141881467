import { useCallback, useState } from 'react';
import * as Yup from 'yup';

export interface UseYupValidationResult<TValues, TErrors> {
  errors: Partial<TErrors>;
  isValidating: boolean;
  validate: (data: TValues, onValid: (data: TValues) => void) => void;
  validateField: (field: keyof TValues, data: TValues) => void;
  cleanErrors: () => void;
}

type TSchema = Yup.AnyObjectSchema;

function useYupValidation<TValues, TErrors>(
  schema: TSchema,
): UseYupValidationResult<TValues, TErrors> {
  const [errors, setErrors] = useState<Partial<TErrors>>({});
  const [isValidating, setIsValidating] = useState(false);

  const mapValidationErrors = (yupError) => {
    const errors = {};
    yupError.inner.forEach((valErr, index) => {
      errors[valErr.path] = yupError.errors[index];
    });
    return errors;
  };

  const validateField = async (field, data) => {
    setIsValidating(true);
    try {
      await schema.validateAt(field, data, { abortEarly: false });
      setErrors((prev) => ({
        ...prev,
        [field]: undefined,
      }));
    } catch (error) {
      setErrors(mapValidationErrors(error));
    } finally {
      setIsValidating(false);
    }
  };

  const validate = async (data, onValid) => {
    setIsValidating(true);
    try {
      await schema.validate(data, { abortEarly: false });
      setErrors({});
      onValid(data);
    } catch (error) {
      setErrors(mapValidationErrors(error));
    } finally {
      setIsValidating(false);
    }
  };

  const cleanErrors = useCallback(() => {
    setErrors({});
  }, []);

  return { errors, validate, validateField, isValidating, cleanErrors };
}

export default useYupValidation;
