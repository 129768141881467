import useAuthenticationWithoutConfirmationModal from './useAuthenticationWithoutConfirmationModal';
import { AuthenticationFactorVerifyCodeModal } from '../AuthenticationFactorVerifyCodeModal';

interface ModalConfirmationWithAuthProps {
  payload?: object;
  onConfirm: () => void;
  loadingMessage?: string;
  onCancel: () => void;
}

function AuthenticationWithoutConfirmationModal({
  payload,
  onConfirm,
  loadingMessage,
  onCancel,
}: Readonly<ModalConfirmationWithAuthProps>): JSX.Element {
  const { showAuthenticationFactorModal, handleCancel, handleIsValidCode } =
    useAuthenticationWithoutConfirmationModal({
      onSave: onConfirm,
      loadingMessage,
      onCancel,
    });

  return (
    <AuthenticationFactorVerifyCodeModal
      isOpen={showAuthenticationFactorModal}
      type="centered"
      onCancel={handleCancel}
      onIsValidCode={handleIsValidCode}
      payload={payload}
    />
  );
}

export default AuthenticationWithoutConfirmationModal;
