import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { DraftOrdersResponseDto } from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import { removeDraftOrder as removeDraftOrderAction } from '../../draftOrdersSlice';
import { getDraftOrdersListItemById } from '../../draftOrdersSlice/draftOrdersSelectors';

export interface UseDraftOrderListItemResult {
  draftOrder: DraftOrdersResponseDto;
  removeDraftOrder: (id: string) => Promise<void>;
}

function useDtaftOrderListItem(id: string): UseDraftOrderListItemResult {
  const dispatch = useAppDispatch();

  const draftOrder = useSelector((state: RootStateType) =>
    getDraftOrdersListItemById(state, id),
  );

  const removeDraftOrder = useCallback(
    async (id: string) => {
      const result = await dispatch(removeDraftOrderAction(id));
      unwrapResult(result);
    },
    [dispatch],
  );

  return { draftOrder, removeDraftOrder };
}

export default useDtaftOrderListItem;
