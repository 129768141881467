import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';
import { aspectRatioDesktop } from '../../constants';

interface WithDragAndDropThumbnailProps {
  showAdd: boolean;
  renderAdd: boolean;
  children: React.ReactElement;
}

/**
 * @function WithDragAndDropThumbnail
 * @description This component wraps its children to conditionally render
 * an add button (Thumbnail.Add) overlay. It also adjusts styles to provide
 * a seamless user experience for adding images via drag-and-drop.
 * It differentiates between `showAdd` and `renderAdd` to allow the add button
 * to be rendered with opacity even when it is not visible, which helps
 * in retaining drag events for a smoother UX.
 */
function WithDragAndDropThumbnail({
  showAdd,
  renderAdd,
  children,
}: Readonly<WithDragAndDropThumbnailProps>) {
  const { t } = useTranslation(Domain.CATALOG);
  if (!renderAdd) return children;
  const text = t('products.detail.dragAndDrop');
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          zIndex: showAdd ? 30 : 10,
          opacity: showAdd ? 0.9 : 0,
          width: '100%',
          pointerEvents: 'none',
        }}
      >
        <Thumbnail.Add
          onClick={() => null}
          ariaLabel={text}
          helperText={text}
          aspectRatio={aspectRatioDesktop}
        />
      </div>
      {children}
    </div>
  );
}

export default WithDragAndDropThumbnail;
