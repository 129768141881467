import { Icon, Popover, Tag, Text } from '@nimbus-ds/components';
import { ChevronDownIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { SelectedZonesInterface } from 'domains/Shipping/DeliveryMethods/pages/NewCustomShippingPage/components/DeliveryZoneCard';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeliveryZonesInfoProps {
  readonly deliveryZones: SelectedZonesInterface[];
}

function DeliveryZonesInfo({ deliveryZones }: DeliveryZonesInfoProps) {
  const t = useTranslationShipping();

  return (
    <Stack spacing="tight">
      <Text>{t('deliveryMethods.newInformationShipping.locations')}</Text>
      <Popover
        content={
          <Stack column spacing="tight" align="flex-start">
            <Text fontWeight="bold">
              {t(
                'deliveryMethods.newInformationShipping.informationPopover.title',
              )}
            </Text>
            <Stack spacing="tight">
              {deliveryZones.map((zone) => (
                <Tag key={zone.provinceId} id={`${zone.provinceId}`}>
                  <Text>{`${zone.provinceName} ${
                    zone.citiesIds.length > 0
                      ? `(${zone.citiesIds.length})`
                      : ''
                  }`}</Text>
                </Tag>
              ))}
            </Stack>
          </Stack>
        }
      >
        <Icon source={<ChevronDownIcon />} />
      </Popover>
    </Stack>
  );
}

export default DeliveryZonesInfo;
