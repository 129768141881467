import './FullHeightCardContainer.scss';

interface FullHeightCardContainer {
  children: JSX.Element | JSX.Element[];
}

/**
This component is responsible for ensuring that the Card components grow to occupy
100% of their parent's size. To make this work, the parent of the card's must have
a display flex property and, in turn, must be a direct child of this component.
*/

function FullHeightCardContainer({ children }: FullHeightCardContainer) {
  return <div className="stratus--full-height-container ">{children}</div>;
}

export default FullHeightCardContainer;
