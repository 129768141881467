import { OnboardingTasksResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import {
  DASHBOARD_ONBOARDING_TASK_PAYMENT_METHODS_URL,
  DASHBOARD_ONBOARDING_TASK_SHIPPING_NATIVE_URL,
  DASHBOARD_ONBOARDING_TASKS_URL,
} from '../urls';

export async function getOnboardingTasks(): Promise<OnboardingTasksResponseDto> {
  const { data } = await axios.get<OnboardingTasksResponseDto>(
    DASHBOARD_ONBOARDING_TASKS_URL,
  );
  return data;
}

export async function updateOnboardingTaskSetupPayments() {
  await axios.post(DASHBOARD_ONBOARDING_TASK_PAYMENT_METHODS_URL);
}

export async function updateOnboardingTaskSetupShippingNative() {
  await axios.post(DASHBOARD_ONBOARDING_TASK_SHIPPING_NATIVE_URL);
}
