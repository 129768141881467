import { Card, Stack, Text } from '@tiendanube/components';
import { SendWhatsAppLink } from 'commons/components';
import { emptyIsNullInText } from 'commons/utils';
import { useStoreName } from 'domains/Auth/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

export interface DeliveryInfoCardInterface {
  receiverName: string;
  receiverPhone: string;
  zipcode: string;
  street: string;
  number: string;
  floor: string;
  city: string;
  province: string;
  country: string;
}

interface DeliveryInfoCardProps {
  deliveryData: DeliveryInfoCardInterface;
  url?: string;
  signature?: string;
}

function DeliveryInfoCard({
  deliveryData,
  url,
  signature,
}: DeliveryInfoCardProps) {
  const t = useTranslationOrders();
  const nameStore = useStoreName();

  return (
    <Card title={t('abandonedCarts.deliveryInfoCard.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{deliveryData.receiverName}</Text>
          {!!deliveryData.receiverPhone && (
            <SendWhatsAppLink
              phoneNumber={deliveryData.receiverPhone}
              textChild={deliveryData.receiverPhone}
              textWhatsapp={t('abandonedCarts.sendEmail.body', {
                nameClient: deliveryData.receiverName,
                nameStore,
                interpolation: { escapeValue: false },
              })}
              url={url}
              signature={signature}
            />
          )}
        </Stack.Item>
        <Stack.Item>
          <Text size="caption" appearance="light" block>
            {`${t('abandonedCarts.deliveryInfoCard.address')}`}
          </Text>
          <Text block>
            {`
              ${deliveryData.street} 
              ${emptyIsNullInText(deliveryData.number)} 
              ${deliveryData.floor} 
              (${deliveryData.zipcode})`}
          </Text>
          <Text block>
            {`${deliveryData.city}, ${deliveryData.province}, ${deliveryData.country}`}
          </Text>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCard;
