import * as Yup from 'yup';

export default Yup.object().shape({
  zipcode: Yup.string().required('address.errors.zipcode'),
  provinceCode: Yup.string().required('address.errors.province'),
  neighborhood: Yup.string().required('address.errors.neighborhood'),
  complement: Yup.string(),
  street: Yup.string().required('address.errors.street'),
  city: Yup.string().required('address.errors.city'),
  number: Yup.string().required('address.errors.number'),
});
