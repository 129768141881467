import { Box, IconButton, Tooltip } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { TrashIcon, DuplicateIcon, ShareIcon } from '@tiendanube/icons';
import { PopoverMenu, Responsive } from 'commons/components';

interface ActionsResponsiveProps {
  onCloneProduct: () => void;
  onRemoveProduct: () => void;
  onClipBoard: () => void;
  disableClone?: boolean;
}

function ActionsResponsive({
  onCloneProduct,
  onRemoveProduct,
  onClipBoard,
  disableClone = false,
}: Readonly<ActionsResponsiveProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const shareLabel = t('products.detail.header.share');
  const duplicateLabel = t('products.detail.header.duplicate');
  const deleteLabel = t('products.detail.header.delete');

  const shareAction = {
    label: shareLabel,
    startIcon: ShareIcon,
    onClick: onClipBoard,
  };
  const cloneAction = {
    label: duplicateLabel,
    startIcon: DuplicateIcon,
    onClick: onCloneProduct,
  };

  const deleteAction = {
    label: deleteLabel,
    startIcon: TrashIcon,
    onClick: onRemoveProduct,
  };

  const actions = disableClone
    ? [shareAction, deleteAction]
    : [shareAction, cloneAction, deleteAction];

  return (
    <Responsive
      mobile={<PopoverMenu actions={actions} />}
      desktop={
        <Box display="flex" flexDirection="row" gap="3">
          {actions.map(({ label, onClick, startIcon: StartIcon }) => (
            <Tooltip content={label} key={label}>
              <IconButton
                size="2rem"
                source={<StartIcon />}
                onClick={onClick}
              />
            </Tooltip>
          ))}
        </Box>
      }
    />
  );
}

export default ActionsResponsive;
