import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { generateProductDescription } from '../../../productsSlice';

function useGenerateProductDescription() {
  const dispatch = useAppDispatch();

  const generateDescription = useCallback(
    async (requestDto) => {
      const result = await dispatch(generateProductDescription(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { generateDescription };
}

export default useGenerateProductDescription;
