import { InterfaceLink, Label, Link } from '@tiendanube/components';
import { RocketIcon } from '@tiendanube/icons';
import './LinkPopover.scss';

interface LinkPopoverProps extends InterfaceLink {
  pro?: boolean;
}

function LinkPopover({
  children,
  icon,
  disabled,
  onClick,
  spinner,
  iconPosition,
  appearance,
  pro,
}: LinkPopoverProps): JSX.Element {
  return (
    <div className="stratus--link-popover">
      <Link
        icon={icon}
        disabled={disabled}
        onClick={onClick}
        spinner={spinner}
        iconPosition={iconPosition}
        appearance={appearance}
      >
        <span>{children}</span>
        {pro && (
          <Label
            id={`pro-${children}`}
            label="Pro"
            icon={RocketIcon}
            appearance="primary"
          />
        )}
      </Link>
    </div>
  );
}

export default LinkPopover;
