import { Text } from '@nimbus-ds/components';
import { NavigationMenuItemResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { Actions } from './components';
import Skeleton from './Skeleton';

interface MenuItemNodeProps {
  node: NavigationMenuItemResponseDto;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

function MenuItemNode({
  node: { id, name },
  onDelete,
  onEdit,
}: MenuItemNodeProps) {
  const language = useGetLanguage();
  const handleDelete = () => onDelete(id);
  const handleEdit = () => onEdit(id);

  return (
    <Stack align="center" justify="space-between">
      <Text lineClamp={1}>{name[language]}</Text>
      <Actions onDelete={handleDelete} onEdit={handleEdit} />
    </Stack>
  );
}

MenuItemNode.Skeleton = Skeleton;

export default MenuItemNode;
