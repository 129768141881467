import { IconButton } from '@tiendanube/components';
import { WhatsappIcon } from '@tiendanube/icons';
import useWhatsappMessageLink, {
  useWhatsappMessageLinkProps,
} from 'commons/hooks/useWhatsappMessageLink';
import { openWindow } from 'commons/utils/window';

function SendWhatsappIconButton({
  phone,
  message,
}: useWhatsappMessageLinkProps): JSX.Element {
  const getUrl = useWhatsappMessageLink();

  const goToSendEmail = () => {
    openWindow(
      getUrl({
        phone,
        message,
      }),
      true,
    );
  };

  return (
    <IconButton icon={WhatsappIcon} onClick={goToSendEmail} ariaLabel={phone} />
  );
}

export default SendWhatsappIconButton;
