import { Tree, NodeModel } from '@minoru/react-dnd-treeview';
import classNames from 'classnames';
import DndProvider from 'App/components/DndProvider';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { Placeholder, DragPreview, DraggableTreeSkeleton } from './components';

import './DraggableTree.scss';

interface RenderItemProps<TData> {
  node: NodeModel<TData>;
  depth: number;
  isOpen: boolean;
  hasChildren: boolean;
  onToggle: () => void;
}

interface DraggableTreeProps<TData> {
  data: NodeModel<TData>[];
  dragMode: boolean;
  onDrop: (newTree: NodeModel<TData>[]) => void;
  renderItem: (item: RenderItemProps<TData>) => JSX.Element;
}

function DraggableTree<TData>({
  data,
  onDrop,
  dragMode,
  renderItem,
}: DraggableTreeProps<TData>) {
  const isMobileDevice = useIsMobileDevice();

  const styleDraggableTree = classNames('stratus--draggable-tree', {
    'stratus--draggable-tree__mobile': isMobileDevice,
  });

  return (
    <div className={styleDraggableTree}>
      <DndProvider>
        <Tree
          canDrag={() => dragMode}
          tree={data}
          rootId="0"
          render={(node, { depth, isOpen, onToggle }) => {
            const hasChildren = data.some((some) => some.parent === node.id);
            return renderItem({ node, depth, isOpen, hasChildren, onToggle });
          }}
          dragPreviewRender={({ item }) => <DragPreview item={item} />}
          onDrop={onDrop}
          classes={{
            root: 'stratus--draggable-tree__root',
            draggingSource: 'stratus--draggable-tree__dragging-source',
            placeholder: 'stratus--draggable-tree__placeholder-container',
          }}
          sort={false}
          insertDroppableFirst={false}
          canDrop={(_tree, { dragSource, dropTargetId }) => {
            if (dragSource?.parent === dropTargetId) {
              return true;
            }
          }}
          initialOpen
          dropTargetOffset={10}
          placeholderRender={(_, { depth }) => <Placeholder depth={depth} />}
        />
      </DndProvider>
    </div>
  );
}

DraggableTree.Skeleton = DraggableTreeSkeleton;

export default DraggableTree;
