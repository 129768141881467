import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CustomersDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getCustomerById,
  deleteCustomer as deleteCustomerAction,
  updateCustomerNote as updateCustomerNoteAction,
} from 'domains/Customers/Customers/customersSlice';
import {
  getCustomerDetails,
  getCustomerDetailsStatus,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseCustomerDetailsResult {
  fetchCustomerDetails: () => Promise<void>;
  deleteCustomer: () => Promise<void>;
  updateCustomerNote: (data: string) => Promise<CustomersDetailsResponseDto>;
  customerDetails: CustomersDetailsResponseDto;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useCustomerDetails(id: string): UseCustomerDetailsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getCustomerDetailsStatus,
  );
  const customerDetails = useSelector(getCustomerDetails);

  const fetchCustomerDetails = useCallback(async () => {
    await dispatch(getCustomerById(id));
  }, [dispatch, id]);

  const deleteCustomer = useCallback(async () => {
    await dispatch(deleteCustomerAction(id));
  }, [dispatch, id]);

  const updateCustomerNote = useCallback(
    async (data: string) => {
      const result = await dispatch(
        updateCustomerNoteAction({ customerId: id, note: data }),
      );
      return unwrapResult(result);
    },
    [dispatch, id],
  );

  useEffect(() => {
    fetchCustomerDetails();
  }, [fetchCustomerDetails]);

  return {
    fetchCustomerDetails,
    deleteCustomer,
    updateCustomerNote,
    customerDetails,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useCustomerDetails;
