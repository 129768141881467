import { Fragment, memo } from 'react';
import { Box, Thumbnail, Text } from '@nimbus-ds/components';
import { EditIcon, HistoryIcon } from '@nimbus-ds/icons';
import { VariantResponseDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { PopoverMenu } from 'commons/components';
import { useToast } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';
import {
  useInventory,
  useInventoryItem,
  useVariantsRange,
} from 'domains/Catalog/Inventory/pages/hooks';
import { joinVariantValues } from 'domains/Catalog/Inventory/utils';
import { LinkGoToProduct } from 'domains/Catalog/Products/pages/ProductListPage/components/ProductResultsDesktop/components/ResultRowDesktop/components';
import { stockForLocation } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useInventoryProductActions from '../../../hooks/useInventoryProductActions';
import { InputAsyncStock } from '../../InventoryResultsDesktop/components/ResultRowDesktop/components';
import { ShowHideVariantsLink } from '../../ShowHideVariantsLink';

const maxEntries = 10;

interface ProductItemListInterface {
  id: string;
  locationId?: string;
}

function InventoryItemList({ id, locationId }: ProductItemListInterface) {
  const { product, language } = useInventoryItem(id);
  const t = useTranslationCatalog();
  const { editStock } = useInventory();
  const { addToast } = useToast();
  const range = useVariantsRange(product, maxEntries);
  const {
    showAccessToStockHistory,
    handleOpenStockHistoryModal,
    handleOpenEditStockModal,
  } = useInventoryProductActions(id, locationId);

  const canEditStock = useHasPermission('edit_stock');

  if (!product) return null;

  const productName = product.name[language] || '';
  const thumbnail = {
    src: product.images[0]?.src ?? undefined,
    alt: product.images[0]?.alt?.[language] ?? productName,
  };
  const variants = range.variants;

  const handleEditStock =
    (id: string, locationId?: string) =>
    async ({ value }) => {
      await editStock({
        productId: product.id,
        action: 'replace',
        value: value !== '' ? Number(value) : null,
        variantId: id,
        locationId,
      });
    };

  const onErrorStock = () => {
    addToast({
      appearance: 'danger',
      label: t('products.toast.errorUpdateStock'),
    });
  };

  const actions = (variant: VariantResponseDto) => [
    ...(canEditStock
      ? [
          {
            label: t('inventory.actions.editStock'),
            startIcon: EditIcon,
            onClick: () => handleOpenEditStockModal(variant),
          },
        ]
      : []),
    ...(showAccessToStockHistory
      ? [
          {
            label: t('inventory.actions.viewStockHistory'),
            startIcon: HistoryIcon,
            onClick: () => handleOpenStockHistoryModal(variant),
          },
        ]
      : []),
  ];

  return (
    <DataList.Row id={id}>
      <Box
        display="grid"
        gridTemplateColumns="48px auto"
        gridGap="4"
        alignItems="flex-start"
        px="2"
        width="100%"
      >
        <Thumbnail {...thumbnail} />
        <Box display="flex" flexDirection="column" gap="2">
          <LinkGoToProduct title={productName} id={product.id} bold />
          <Box
            display="grid"
            gridTemplateColumns="80px auto 32px"
            gridGap="2"
            alignItems="center"
            marginTop="2"
          >
            {variants.map((variant) => (
              <Fragment key={variant.id}>
                <InputAsyncStock
                  asyncFunc={handleEditStock(variant.id, locationId)}
                  defaultValue={stockForLocation(variant, locationId)}
                  onError={onErrorStock}
                  disabled={!canEditStock}
                />
                <Text lineClamp={1}>
                  {joinVariantValues(variant, language)}
                </Text>
                <PopoverMenu actions={actions(variant)} />
              </Fragment>
            ))}
          </Box>
          <ShowHideVariantsLink {...range} tracking={false} />
        </Box>
      </Box>
    </DataList.Row>
  );
}

export default memo(InventoryItemList);
