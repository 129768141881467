import { Chip, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useProductDetails } from 'domains/Catalog/Products/pages/EditProductPage/hooks';
import { getVariantsValuesByLanguage } from '../CardVariants/utils';

interface AttributesDuplicatePreviewProps {
  id: string;
}
function AttributesDuplicatePreview({ id }: AttributesDuplicatePreviewProps) {
  const language = useGetLanguage();
  const t = useTranslationCatalog();
  const { product } = useProductDetails(id);

  return (
    <Stack column align="stretch">
      <Text fontSize="highlight">{t('products.variants.title')}</Text>
      {product.attributes.map((attribute, index) => (
        <Stack key={index} column align="stretch" spacing="tight">
          <Text>{attribute[language]}</Text>
          <Stack spacing="tight" wrap>
            {getVariantsValuesByLanguage(product.variants, index, language).map(
              (value, i) => (
                <Chip text={value} key={i} />
              ),
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default AttributesDuplicatePreview;
