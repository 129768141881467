import { ChangeEvent } from 'react';
import { Text, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CardCollapsible, InputNumberNimbus, Stack } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ShippingConditionsCardProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  weightMin?: string;
  weightMax?: string;
  valueMin?: string;
  valueMax?: string;
  error?: Partial<Record<string, string>>;
}

function ShippingConditionsCardProps({
  onChange,
  weightMin = '',
  weightMax = '',
  valueMin = '',
  valueMax = '',
  error,
}: ShippingConditionsCardProps) {
  const { symbol } = useGetCurrency();
  const t = useTranslationShipping();

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h3">
            {t('deliveryMethods.customShipping.conditionsCard.title')}
          </Title>
          <Text>
            {t('deliveryMethods.customShipping.conditionsCard.optional')}
          </Text>
        </Stack>
      }
      active={false}
    >
      <Stack column align="stretch">
        <Text>
          {t('deliveryMethods.customShipping.conditionsCard.description')}
        </Text>
        <Text>{`${t(
          'deliveryMethods.customShipping.conditionsCard.weight.title',
        )}`}</Text>
        <Stack wrap align="stretch">
          <Stack.Item fill>
            <FormField
              label={t(
                'deliveryMethods.customShipping.conditionsCard.weight.from',
              )}
              showHelpText
              helpText={
                error?.cartWeightMin ? t(error.cartWeightMin) : undefined
              }
            >
              <InputNumberNimbus
                type="float"
                appendPosition="end"
                append={t(
                  'deliveryMethods.customShipping.conditionsCard.weight.appendLabelInput',
                )}
                onChange={onChange}
                value={weightMin}
                name="cartWeightMin"
                appearance={error?.cartWeightMin ? 'danger' : 'neutral'}
              />
            </FormField>
          </Stack.Item>
          <Stack.Item fill>
            <FormField
              label={t(
                'deliveryMethods.customShipping.conditionsCard.weight.to',
              )}
              showHelpText
              helpText={
                error?.cartWeightMax ? t(error.cartWeightMax) : undefined
              }
            >
              <InputNumberNimbus
                type="float"
                appendPosition="end"
                append={t(
                  'deliveryMethods.customShipping.conditionsCard.weight.appendLabelInput',
                )}
                onChange={onChange}
                value={weightMax}
                name="cartWeightMax"
                appearance={error?.cartWeightMax ? 'danger' : 'neutral'}
              />
            </FormField>
          </Stack.Item>
        </Stack>
        <Text>{`${t(
          'deliveryMethods.customShipping.conditionsCard.value.title',
        )}`}</Text>
        <Stack wrap align="stretch">
          <Stack.Item fill>
            <FormField
              label={t(
                'deliveryMethods.customShipping.conditionsCard.value.from',
              )}
              showHelpText
              helpText={error?.cartValueMin ? t(error.cartValueMin) : undefined}
            >
              <InputNumberNimbus
                type="float"
                append={symbol}
                onChange={onChange}
                value={valueMin}
                name="cartValueMin"
                appearance={error?.cartValueMin ? 'danger' : 'neutral'}
              />
            </FormField>
          </Stack.Item>
          <Stack.Item fill>
            <FormField
              label={t(
                'deliveryMethods.customShipping.conditionsCard.value.to',
              )}
              showHelpText
              helpText={error?.cartValueMax ? t(error.cartValueMax) : undefined}
            >
              <InputNumberNimbus
                type="float"
                append={symbol}
                onChange={onChange}
                value={valueMax}
                name="cartValueMax"
                appearance={error?.cartValueMax ? 'danger' : 'neutral'}
              />
            </FormField>
          </Stack.Item>
        </Stack>
      </Stack>
    </CardCollapsible>
  );
}

export default ShippingConditionsCardProps;
