import { StatisticsGranularityType } from 'domains/Statistics/types';
import {
  StratusTooltipContentCompared,
  StratusTooltipContentDefault,
} from './components';
import { TooltipChartStratusProps } from '../TooltipChartStratus/TooltipChartStratus';

export interface StratusTooltipContentProps
  extends Omit<TooltipChartStratusProps, 'children'> {
  formatLabel?: (label: string) => string;
  hasComparedData?: boolean;
  granularity: StatisticsGranularityType;
  isCombined?: boolean;
}

function StratusTooltipContent({
  hasComparedData = false,
  ...props
}: StratusTooltipContentProps) {
  if (hasComparedData) {
    return <StratusTooltipContentCompared {...props} />;
  }
  return <StratusTooltipContentDefault {...props} />;
}

export default StratusTooltipContent;
