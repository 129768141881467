function updateArrayElementById<T extends { id: string | number }>(
  arr: T[],
  id: T['id'],
  updateFn: (element: T) => T,
) {
  return arr.map((element) =>
    element.id === id ? updateFn(element) : element,
  );
}

export default updateArrayElementById;
