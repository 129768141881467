import { EyeIcon, EyeOffIcon, TrashIcon } from '@nimbus-ds/icons';
import { StatusPromotionType } from '@tiendanube/common';
import { ActionIconButton, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  DeletePromotionModal,
  StatusPromotionModal,
} from 'domains/Marketing/Promotions/components';
import { usePromotionsActions } from 'domains/Marketing/Promotions/hooks';

interface ActionsProps {
  id: string;
  status: StatusPromotionType;
}

export function Actions({ id, status }: Readonly<ActionsProps>) {
  const t = useTranslationMarketing('promotions.actions');
  const isActive = status === 'active';

  const {
    showStatusModal,
    showDeleteModal,
    handleChangeStatus,
    handleDelete,
    openDeleteModal,
    closeDeleteModal,
    openStatusModal,
    closeStatusModal,
  } = usePromotionsActions(id, isActive);

  const showStatusBtn = status !== 'expired';
  const EyeStatusIcon = isActive ? EyeOffIcon : EyeIcon;
  const typeStatus = isActive ? 'disable' : 'enable';

  return (
    <>
      <Stack spacing="tight">
        {showStatusBtn && (
          <ActionIconButton
            content={t(`${typeStatus}.label`)}
            source={<EyeStatusIcon />}
            onClick={openStatusModal}
          />
        )}
        <ActionIconButton
          content={t('delete.label')}
          source={<TrashIcon />}
          onClick={openDeleteModal}
        />
      </Stack>
      <StatusPromotionModal
        show={showStatusModal}
        isActive={isActive}
        onClose={closeStatusModal}
        onChangeStatus={handleChangeStatus}
      />
      <DeletePromotionModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
    </>
  );
}
