import { Country } from '@tiendanube/common/src/enums';
import {
  BaseCard,
  IconButton,
  Link,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { CogIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import TaxConditionSkeleton from './TaxConditionSkeleton';
import useTaxCondition from './useTaxCondition';

function TaxCondition(): JSX.Element {
  const country = useGetCountry();
  const t = useTranslationBilling();
  const { isMobile } = useResponsive();
  const { invoiceType, vatCondition } = useTaxCondition();
  const { goTo } = useNavegate();

  const handleEdit = () => goTo('/account/invoices/info');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack justify="space-between">
          <Title type="h3">{`${t(
            'invoicesInfoPage.taxCondition.title',
          )}`}</Title>
          {isMobile && (
            <IconButton
              ariaLabel={t('invoicesInfoPage.taxCondition.editVatCondition')}
              icon={CogIcon}
              onClick={handleEdit}
            />
          )}
        </Stack>
      </BaseCard.Header>
      {country === Country.AR && !!vatCondition && invoiceType && (
        <BaseCard.Body>
          <Text>{`${vatCondition} - ${t(
            'invoiceHistory.bill',
          )} ${invoiceType}`}</Text>
        </BaseCard.Body>
      )}
      {!isMobile && (
        <BaseCard.Footer>
          <Link appearance="primary" icon={CogIcon} onClick={handleEdit}>
            {t('invoicesInfoPage.taxCondition.settings')}
          </Link>
        </BaseCard.Footer>
      )}
    </BaseCard>
  );
}

TaxCondition.Skeleton = TaxConditionSkeleton;

export default TaxCondition;
