import { Alert } from '@tiendanube/components';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields/useTraslationsMetafields';

interface AlertOrderMetafieldsProps {
  show: boolean;
}

function AlertOrderMetafields({ show }: AlertOrderMetafieldsProps) {
  const t = useTranslationMetafields();

  const alertMetafieldsSuccessTitle = t(
    'assignOrdersMetafieldsModal.successTitle',
  );
  const alertMetafieldsSuccessText = t(
    'assignOrdersMetafieldsModal.successText',
  );

  return (
    <Alert show={show} title={alertMetafieldsSuccessTitle}>
      {alertMetafieldsSuccessText}
    </Alert>
  );
}
export default AlertOrderMetafields;
