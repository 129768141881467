import { Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { AUTH_ROUTES } from '../../authRoutes';
import {
  trackingLoginWithRecoveryCodes,
  trackingRecoveryCodesHelpLinkClicked,
} from '../../tracking';
import AuthenticationFactorCodePage from '../AuthenticationFactorCodePage';

function RecoveryCodePage(): JSX.Element {
  const { t } = useTranslation(['login']);

  const helpLink = (
    <Link
      as="a"
      href={t('authenticationFactor.recoveryCode.helpLink')}
      target="_blank"
      appearance="primary"
      fontSize="caption"
      textDecoration="none"
      onClick={trackingRecoveryCodesHelpLinkClicked}
    >
      {t('authenticationFactor.recoveryCode.helpLinkText')}
    </Link>
  );

  return (
    <AuthenticationFactorCodePage
      authenticationFactorType={AuthenticationFactorTypes.RECOVERY_CODE}
      helpLink={helpLink}
      codeLength={19}
      translationKeyPrefix="recoveryCode"
      backButtonPath={AUTH_ROUTES.verificationCode}
      beforeSubmit={trackingLoginWithRecoveryCodes}
    />
  );
}

export default RecoveryCodePage;
