import { useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { VirtualDataList, HeaderContent } from 'commons/components';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import {
  useHasMultipleLocations,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { useInventoryItem } from 'domains/Catalog/Inventory/pages/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import {
  CreatedAt,
  StockHistoryEmpty,
  StockHistoryError,
  StockHistoryModalItemList,
  StockHistorySkeleton,
} from './components';
import { useStockHistory } from './hooks';
import { useProductDetails } from '../../EditProductPage/hooks';
import { LocationSelection } from '../Location/components';
import ProductVariant from '../ProductVariant';

const noop = async () => Promise.resolve();

interface StockHistoryModalProps {
  productId: string;
  isOpen: boolean;
  variantId: string;
  variantName?: string;
  locationId?: string;
  hideLocationSelector?: boolean;
  onClose: () => void;
}

function StockHistoryModal({
  productId,
  isOpen,
  onClose,
  variantId,
  variantName,
  locationId,
  hideLocationSelector = false,
}: StockHistoryModalProps) {
  const t = useTranslationCatalog();
  const { product: productStockInventory } = useInventoryItem(productId);
  const { product: productStockProduct } = useProductDetails(productId);
  const product = productStockInventory || productStockProduct;
  const [currentLocationId, setCurrentLocationId] = useState(locationId);
  const isMobile = useIsMobileDevice();
  const { locations, locationsMainId } = useLocations();
  const hasMultipleLocations = useHasMultipleLocations(locations);
  const {
    fetchStockHistory,
    stockHistory,
    isError,
    isLoading,
    isEmpty,
    isSuccess,
  } = useStockHistory({
    productId,
    variantId,
    locationId: currentLocationId,
  });

  useEffect(() => {
    if (locationsMainId || locationId) {
      setCurrentLocationId(locationId ?? locationsMainId);
    }
  }, [locationsMainId, locationId]);

  useEffect(() => {
    if (!hasMultipleLocations || currentLocationId !== undefined) {
      fetchStockHistory();
    }
  }, [fetchStockHistory, hasMultipleLocations, currentLocationId]);

  const handleOnRetryError = (): void => fetchStockHistory();

  const oldestDate = stockHistory?.[stockHistory?.length - 1]?.createdAt;
  const variation = variantName
    ? t('products.stockHistoryModal.variant', { variation: variantName })
    : '';

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={
        <HeaderContent title={t('products.stockHistoryModal.title')} />
      }
      hasVirtualizationContent
    >
      <Box gap="0-5" paddingTop="1-5">
        {isError && <StockHistoryError onRetryError={handleOnRetryError} />}
        {isLoading && (
          <StockHistorySkeleton
            hasInventory={hasMultipleLocations}
            hideLocationSelector={hideLocationSelector}
          />
        )}
        {isSuccess && stockHistory && (
          <Box display="flex" flexDirection="column" gap="2-5">
            {product && (
              <ProductVariant product={product} variant={variation} />
            )}

            <Stack column align="stretch" spacing="loose">
              {hasMultipleLocations && !hideLocationSelector && (
                <LocationSelection
                  locations={locations}
                  locationId={currentLocationId}
                  hasVariants
                  isMobile={isMobile}
                  onChange={({ value }) => setCurrentLocationId(value)}
                />
              )}
              <Stack.Item>
                {isEmpty ? (
                  <StockHistoryEmpty />
                ) : (
                  <>
                    <Stack.Item fill>
                      {!!oldestDate && <CreatedAt date={oldestDate} />}
                    </Stack.Item>
                    <Stack.Item fill>
                      <VirtualDataList
                        onRefresh={noop}
                        isPullable={false}
                        showFooter={false}
                        endReached={() => null}
                        data={stockHistory.map((stock) => stock.id)}
                        itemContent={(i) => (
                          <StockHistoryModalItemList
                            stockHistory={stockHistory[i]}
                          />
                        )}
                      />
                    </Stack.Item>
                  </>
                )}
              </Stack.Item>
            </Stack>
          </Box>
        )}
      </Box>
    </ModalAside>
  );
}

export default StockHistoryModal;
