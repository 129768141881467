import { Icon, Tag, Text } from '@nimbus-ds/components';
import { CheckCircleIcon, EditIcon, TrashIcon } from '@nimbus-ds/icons';
import { OrderEditProductType } from 'domains/Orders/Orders/pages/OrderEditPage/components/Products/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

const iconSize = 12;

interface EditOrderProductTagProps {
  editType: OrderEditProductType;
}

function EditOrderProductTag({ editType }: Readonly<EditOrderProductTagProps>) {
  const t = useTranslationOrders();

  return {
    add: (
      <Tag appearance="success">
        <Icon
          color="success-textHigh"
          source={<CheckCircleIcon size={iconSize} />}
        />
        <Text color="success-textHigh" fontSize="caption">
          {t('editOrders.productList.tags.added')}
        </Text>
      </Tag>
    ),
    edit: (
      <Tag appearance="primary">
        <Icon color="primary-textHigh" source={<EditIcon size={iconSize} />} />
        <Text color="primary-textHigh" fontSize="caption">
          {t('editOrders.productList.tags.edited')}
        </Text>
      </Tag>
    ),
    delete: (
      <Tag appearance="danger">
        <Icon color="danger-textHigh" source={<TrashIcon size={iconSize} />} />
        <Text color="danger-textHigh" fontSize="caption">
          {t('editOrders.productList.tags.removed')}
        </Text>
      </Tag>
    ),
  }[editType];
}

export default EditOrderProductTag;
