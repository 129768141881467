import { logEvent } from 'commons/utils/tracking';
import {
  NUVEM_ENVIO_CLICK,
  NUVEM_ENVIO_COMMUNICATION,
} from 'config/trackingEvents';

export const trackingCommunication = (
  type: 'jadlog' | 'correios',
  page: 'shipping' | 'orders',
) => {
  logEvent(NUVEM_ENVIO_COMMUNICATION, {
    type,
    page,
  });
};

export const trackingCommunicationButton = (
  type: 'jadlog' | 'correios',
  page: 'shipping' | 'orders',
  action?: string,
) => {
  if (action) {
    logEvent(NUVEM_ENVIO_CLICK, {
      type,
      page,
      action,
    });
  } else {
    logEvent(NUVEM_ENVIO_CLICK, {
      type,
      page,
    });
  }
};
