import * as Yup from 'yup';

export interface WhatsappFormInterface {
  countryCode?: string;
  phone: string;
  enable: boolean;
}

export const initialValuesWhatsappForm = {
  countryCode: '',
  phone: '',
  enable: false,
};

export const validationSchemaWhatsapp = Yup.object().shape({
  countryCode: Yup.string(),
  enable: Yup.boolean(),
  phone: Yup.string().required('input.error.required'),
});

// PHONE_NUMBER_REGEX enforces the WhatsApp requirement for phone numbers:
// Only digits (0-9) are allowed, and there is no specified limit on the length.
const PHONE_NUMBER_REGEX = /^\d+$/;

/**
 * Validates a phone number.
 * @param value - The input string to be validated.
 * @returns {boolean} - Returns true if the input contains only digits or is an empty string.
 *                     Returns false otherwise.
 */

export function validatePhoneNumber(value: string): boolean {
  return PHONE_NUMBER_REGEX.test(value) || value === '';
}
