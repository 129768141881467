import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OcaConfigRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanOcaConfigurationCreateStatus,
  cleanOcaConfigurationUpdateStatus,
  createOcaConfigurationAction,
  getOcaConfigurationAction,
  getOcaConfigurationData,
  getOcaConfigurationLoadStatus,
  getOcaConfigurationSaveStatus,
  getOcaConfigurationUpdateStatus,
  updateOcaConfigurationAction,
} from '../../../../deliveryMethodsSlice';

function useOcaConfiguration() {
  const dispatch = useAppDispatch();
  const ocaConfiguration = useSelector(getOcaConfigurationData);
  const ocaConfigurationLoadStatus = useSelector(getOcaConfigurationLoadStatus);
  const ocaConfigurationSaveStatus = useSelector(getOcaConfigurationSaveStatus);
  const ocaConfigurationUpdateStatus = useSelector(
    getOcaConfigurationUpdateStatus,
  );

  const getOcaConfiguration = useCallback(() => {
    dispatch(getOcaConfigurationAction());
  }, [dispatch]);

  const createOcaConfiguration = useCallback(
    async (payload: OcaConfigRequestDto) => {
      await dispatch(createOcaConfigurationAction(payload));
      dispatch(cleanOcaConfigurationCreateStatus());
    },
    [dispatch],
  );

  const updateOcaConfiguration = useCallback(
    async (id: string, payload: OcaConfigRequestDto) => {
      await dispatch(updateOcaConfigurationAction({ id, payload }));
      dispatch(cleanOcaConfigurationUpdateStatus());
    },
    [dispatch],
  );

  return {
    ocaConfiguration,
    ocaConfigurationLoadStatus,
    ocaConfigurationSaveStatus,
    ocaConfigurationUpdateStatus,
    getOcaConfiguration,
    createOcaConfiguration,
    updateOcaConfiguration,
  };
}

export default useOcaConfiguration;
