import { TransactionFeeDataInterface } from './types';

export const initialState: TransactionFeeDataInterface = {
  transactionFeeToPay: {
    data: null,
    status: 'idle',
  },
  transactionFeePaid: {
    data: null,
    status: 'idle',
  },
  paymentsHistory: {
    data: null,
    status: 'idle',
  },
  payOrder: {
    status: 'idle',
  },
  transactionsList: {
    split: false,
    status: 'idle',
    entities: {},
    ids: [],
    pagination: {
      currentPage: 0,
      totalPages: 1,
      totalResults: 0,
      perPage: 20,
      nextPage: null,
    },
    currentRequestID: '',
  },
};
