import { DownloadProductsTemplateResponseDto } from '@tiendanube/common';
import { useAsyncFunc, useToast, WrappedFuncType } from 'commons/hooks';
import productsServices from 'domains/Catalog/Products/productsServices';

interface UseDownloadTemplateCsvResult {
  isLoading: boolean;
  getTemplateCsv: WrappedFuncType<void, DownloadProductsTemplateResponseDto>;
}
interface useDownloadTemplateCsvProps {
  messageSuccess: string;
  messageError: string;
}

function useDownloadTemplateCsv({
  messageSuccess,
  messageError,
}: useDownloadTemplateCsvProps): UseDownloadTemplateCsvResult {
  const { addToast } = useToast();

  const [getTemplateCsv, isLoading] = useAsyncFunc(async () => {
    try {
      const result = await productsServices.fetchTemplateCsv();
      addToast({
        label: messageSuccess,
        appearance: 'success',
      });
      return result;
    } catch (error) {
      addToast({
        label: messageError,
        appearance: 'danger',
      });
      return error;
    }
  });

  return {
    isLoading,
    getTemplateCsv,
  };
}

export default useDownloadTemplateCsv;
