import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  LoginRequestDto,
  LoginResponseDto,
  AuthSyncResponseDto,
  AuthorizeResponseDto,
  TokenRequestDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { isMobile } from './authSelectors';
import authServices from '../authService';
import { AuthDataInterface, InterfaceAuthSchema } from '../authService/types';

const initialState: InterfaceAuthSchema = {
  status: 'idle',
  data: null,
  isAuthInit: false,
  isMobile: false,
  statusCode: '',
  statusAuthCode: 'idle',
  statusSync: 'idle',
  authorize: {
    status: 'idle',
    data: null,
    statusCode: '',
  },
  isEmbeddedApp: false,
};

export const authorize = createAsyncThunk<
  AuthorizeResponseDto,
  LoginRequestDto
>('auth/v1', async (data) => await authServices.authorize(data));

export const createSession = createAsyncThunk<
  LoginResponseDto,
  TokenRequestDto
>(
  'auth/v1/token',
  async (values: TokenRequestDto) => await authServices.createSession(values),
);

export const refreshInfoToSync = createAsyncThunk<
  AuthSyncResponseDto | undefined,
  boolean
>('auth/refreshInfoToSync', async (noCache) => {
  const data = await authServices.getInfoToSync(noCache);
  return data;
});

export const getCurrentAuth = createAsyncThunk<
  LoginResponseDto | null,
  string | undefined
>('auth/init', async (code) => {
  let state: LoginResponseDto | undefined;
  if (code) {
    state = await authServices.getAuthByToken(code);
  } else {
    state = await authServices.getAuthStorage();
  }

  if (state) {
    return state;
  }
  return null;
});

export const fetchFeatureUses = createAsyncThunk<number, string>(
  'auth/featureUses',
  async (featureCode) => {
    const { featureUses } = await authServices.getFeatureUsesByKey(featureCode);

    return featureUses;
  },
);

export const logout = createAsyncThunk<
  void,
  undefined,
  { state: RootStateType }
>('auth/logout', async (_, thunkApi) => {
  const isMobileDevice = isMobile(thunkApi.getState());
  await authServices.logout(isMobileDevice);
  return;
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changePlatform(state, action: { type: string; payload: boolean }) {
      state.isMobile = action.payload;
    },
    changeIsEmbeddedApp(state, action) {
      state.isEmbeddedApp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = {
        ...initialState,
        isMobile: state.isMobile,
        isAuthInit: initialState.isAuthInit,
      };
      return state;
    });

    builder.addCase(authorize.pending, (state) => {
      state.authorize.status = 'loading';
    });
    builder.addCase(authorize.fulfilled, (state, action) => {
      state.authorize.data = action.payload;
      state.authorize.status = 'success';
    });
    builder.addCase(authorize.rejected, (state, action) => {
      state.authorize.data = null;
      state.authorize.status = 'error';
      state.authorize.statusCode = action.error.message || '';
    });

    builder.addCase(createSession.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(createSession.fulfilled, (state, action) => {
      state.data = action.payload;
      state.authorize.data = null;
      state.authorize.status = 'idle';
      state.status = 'success';
      state.isAuthInit = true;
    });
    builder.addCase(createSession.rejected, (state, action) => {
      state.data = null;
      state.status = 'error';
      state.statusCode = action.error.message || '';
    });

    builder.addCase(getCurrentAuth.fulfilled, (state, action) => {
      state.data = action.payload;
      state.statusAuthCode = 'success';
      state.isAuthInit = true;
      return state;
    });

    builder.addCase(getCurrentAuth.rejected, (state) => {
      state.statusAuthCode = 'error';
    });

    builder.addCase(refreshInfoToSync.pending, (state) => {
      state.statusSync = 'loading';
    });

    builder.addCase(refreshInfoToSync.fulfilled, (state, action) => {
      const newData = { ...state.data, ...action.payload } as AuthDataInterface;
      state.data = newData;
      state.statusSync = 'success';
    });
  },
});

export const { changePlatform, changeIsEmbeddedApp } = authSlice.actions;

export const { reducer } = authSlice;
