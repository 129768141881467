import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { UpdateStockResponseDto, VariantResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  editVariants as editVariantsAction,
  editStock as editStockAction,
} from 'domains/Catalog/Products/productsSlice';
import {
  EditStockParams,
  EditVariantParamsInterface,
} from '../../../productsServices';

type EditVariantsType = (
  params: EditVariantParamsInterface,
) => Promise<VariantResponseDto>;

type EditStockType = (
  params: EditStockParams,
) => Promise<{ productId: string; response: UpdateStockResponseDto[] }>;

interface UseEditVariantsResult {
  editVariants: EditVariantsType;
  editStock: EditStockType;
}

function useEditVariants(): UseEditVariantsResult {
  const dispatch = useAppDispatch();

  const editVariants: EditVariantsType = useCallback(
    async (params) => {
      const editedVariant = await dispatch(editVariantsAction(params));
      return unwrapResult(editedVariant);
    },
    [dispatch],
  );

  const editStock: EditStockType = useCallback(
    async (params) => {
      const editedStock = await dispatch(editStockAction(params));
      return unwrapResult(editedStock);
    },
    [dispatch],
  );

  return {
    editVariants,
    editStock,
  };
}

export default useEditVariants;
