import { Box, IconButton, Popover, Tooltip } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { SupportCard } from './components';
import { getTotalNoRead } from '../ExternalServices/SunshineConversations/startSmooch';
import './supportChanels.scss';
import { useSupportChat } from '../SupportChatContext';
import useTopBar from '../Topbar/useTopBar';

export function SupportChanels() {
  const { t } = useTranslation('common');
  const {
    noReadMessagesCount,
    setNoReadMessagesCount,
    isHelpWidgetOpen,
    toggleHelpWidget,
  } = useTopBar();
  const { openChat, onClose, onOpen, onReceivedMessage } = useSupportChat();

  const handleUpdateNoReadMessages = () => {
    setNoReadMessagesCount(getTotalNoRead());
  };

  onOpen(() => toggleHelpWidget());
  onClose(handleUpdateNoReadMessages);
  onReceivedMessage(handleUpdateNoReadMessages);

  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" width="100%">
          <SupportCard onClose={toggleHelpWidget} onOpenChat={openChat} />
        </Box>
      }
      padding="none"
      arrow={false}
      position="bottom-end"
      overflow="hidden"
      width="18rem"
      visible={isHelpWidgetOpen}
      onVisibility={toggleHelpWidget}
    >
      <Tooltip
        content={
          noReadMessagesCount > 0
            ? t('topbar.unreadMessages', {
                unread: noReadMessagesCount,
              })
            : t('topbar.help')
        }
        arrow={false}
      >
        <Box position="relative">
          <IconButton
            size="2.125rem"
            source={<QuestionCircleIcon size={18} />}
            backgroundColor="transparent"
            borderColor={{
              xs: 'transparent',
              hover: 'neutral-interactiveHover',
            }}
            data-style="icon-button-override"
            as="div"
            id="support-chanel"
          />
          {noReadMessagesCount > 0 && (
            <Box
              position="absolute"
              top="4px"
              right="4px"
              backgroundColor="primary-interactive"
              borderRadius="full"
              width="6px"
              height="6px"
            />
          )}
        </Box>
      </Tooltip>
    </Popover>
  );
}
