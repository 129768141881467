import { useState } from 'react';
import { DataList, Text, Link, Stack } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { CurrencyPrice, DateDayMonthText } from 'commons/components';
import { ResultProductsMobile } from 'domains/Orders/components';
import { useDtaftOrderListItem } from 'domains/Orders/DraftOrders/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface DraftOrderListRowProps {
  id: string;
  currency?: 'ARS' | 'BRL' | 'MXN' | 'USD';
}

function DraftOrderListRow({
  id,
  currency,
}: DraftOrderListRowProps): JSX.Element | null {
  const t = useTranslationOrders();
  const { goTo } = useNavegate();
  const { draftOrder } = useDtaftOrderListItem(id);
  const [showProducts, setShowProducts] = useState(false);

  const { Row, Cell } = DataList;

  if (!draftOrder) return null;

  const goToDraftOrder = () => goTo(`/draft-orders/${id}`);

  const toggleShowProducts = () => {
    setShowProducts(!showProducts);
  };

  return (
    <>
      <Row id={id} key={id} onClick={goToDraftOrder}>
        <Cell width={60} rowTitle>
          <Stack spacing="tight">
            <Stack.Item>
              <Link appearance="primary" onClick={goToDraftOrder}>
                {`#${draftOrder.id}`}
              </Link>
            </Stack.Item>
          </Stack>
        </Cell>
        <Cell width={40} alignRight>
          <CurrencyPrice price={draftOrder.total} currency={currency} />
        </Cell>
        <Cell>
          <DateDayMonthText date={draftOrder.date} />
        </Cell>
        <Cell width={60}>
          <Stack spacing="tight">
            <Stack.Item>
              <Text>{draftOrder.contactName}</Text>
            </Stack.Item>
          </Stack>
        </Cell>
        <Cell>
          <Link
            appearance="primary"
            icon={showProducts ? ChevronUpIcon : ChevronDownIcon}
            iconPosition="end"
            onClick={toggleShowProducts}
          >
            {t('resultRow.products.label', {
              count: draftOrder.quantityProducts,
            })}
          </Link>
        </Cell>
      </Row>
      <ResultProductsMobile
        show={showProducts}
        quantityUniqueProducts={draftOrder.products.length}
        products={draftOrder.products}
        currency={draftOrder.currency}
      />
    </>
  );
}

export default DraftOrderListRow;
