import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import {
  FulfillmentOrderAssignedLocationDto,
  ProductsSortByEnum,
} from '@tiendanube/common';
import { Input } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import { EmptySearchOrErrorResult } from '../EmptySearchResult';
import { ProductList } from '../ProductList';
import { ProductListSkeleton } from '../ProductListSkeleton';
import { PreSelectedProductInterface, ProductInterface } from '../types';
import { useProductSearch } from '../useProductSearch';
import '../ProductSearcher.scss';

interface ProductSearcherProps<Minimalistic extends boolean> {
  isOpen: boolean;
  selectedProducts: ProductInterface<Minimalistic>[];
  setSelectedProducts: (products: ProductInterface<Minimalistic>[]) => void;
  preselectedProducts?: PreSelectedProductInterface<Minimalistic>[];
  sortBy?: ProductsSortByEnum | null;
  onlyPublishedProducts?: boolean;
  loadInitial?: boolean;
  location?: FulfillmentOrderAssignedLocationDto;
  requiresShipping?: boolean;
  minimalistic?: boolean;
  selectedProductsLimit?: number;
  onProductSelect: (products: ProductInterface<Minimalistic>[]) => void;
}

function ProductSearcherContent<Minimalistic extends boolean>({
  isOpen,
  selectedProducts,
  setSelectedProducts,
  preselectedProducts = [],
  onlyPublishedProducts = false,
  sortBy = null,
  location,
  requiresShipping,
  minimalistic,
  selectedProductsLimit,
  onProductSelect,
}: Readonly<ProductSearcherProps<Minimalistic>>) {
  const t = useTranslationOrders();
  const [searchValue, setSearchValue] = useState('');
  const [submittedSearchValue, setSubmittedSearchValue] = useState('');

  const {
    products,
    isLoading,
    isSuccess,
    fetchProducts,
    fetchMoreProducts,
    isError,
    isRefreshing,
  } = useProductSearch({
    sortBy,
    locationId: location?.id,
    requiresShipping,
    onlyPublished: onlyPublishedProducts,
  });

  const handleSearchChange = ({ value }: { value: string }) =>
    setSearchValue(value);

  const handleSearchSubmit = useCallback(
    ({ value }) => {
      setSearchValue(value);
      setSubmittedSearchValue(value);
      fetchProducts(value);
    },
    [fetchProducts],
  );

  const selectedLimitReached = useMemo(
    () =>
      !!selectedProductsLimit &&
      selectedProducts.length >= selectedProductsLimit,
    [selectedProductsLimit, selectedProducts],
  );

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      handleSearchSubmit({ value: searchValue });
    }, 200);

    return () => clearTimeout(debouncedSearch);
  }, [searchValue, handleSearchSubmit, isOpen]);

  const handleProductsSelect = (
    newSelectedProducts: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => {
    let newSet: ProductInterface<Minimalistic>[] = [];
    if (checked) {
      newSet = [...selectedProducts, ...newSelectedProducts];
    } else {
      newSet = selectedProducts.filter(
        (p) => !newSelectedProducts.find((sp) => sp.variantId === p.variantId),
      );
    }
    if (!!selectedProductsLimit && newSet.length > selectedProductsLimit) {
      newSet = newSet.slice(0, selectedProductsLimit);
    }
    setSelectedProducts(newSet);
    onProductSelect(newSet);
  };

  const onReached = useCallback(() => {
    fetchMoreProducts(searchValue);
  }, [fetchMoreProducts, searchValue]);

  return (
    <Box flexDirection="column">
      <Box marginBottom="4" marginTop="3">
        <Input
          type="search"
          name="product"
          placeholder={
            minimalistic
              ? t('productSearcher.minimalisticSearchPlaceholder')
              : t('productSearcher.searchPlaceholder')
          }
          value={searchValue}
          focused={isOpen}
          onChange={handleSearchChange}
          onSubmit={handleSearchSubmit}
        />
        <Box marginTop="1">
          {isSuccess && (
            <Text fontSize="caption">
              {submittedSearchValue !== ''
                ? t('productSearcher.foundProducts', {
                    count: products.length,
                  })
                : t('productSearcher.totalProducts', {
                    count: products.length,
                  })}
              {location &&
                t('productSearcher.inLocation', {
                  location: location.name,
                })}
            </Text>
          )}
        </Box>
      </Box>
      {isLoading && (
        <div className="stratus--products-list-skeleton-container">
          <ProductListSkeleton />
        </div>
      )}
      {isError && <EmptySearchOrErrorResult isError />}
      {isSuccess && products.length === 0 && (
        <EmptySearchOrErrorResult
          hasZeroProducts={searchValue === ''}
          isDigital={!requiresShipping}
        />
      )}
      {isSuccess && products.length > 0 && (
        <div className="stratus--products-list-container">
          <ProductList
            products={products}
            selectedProducts={[
              ...selectedProducts.map((p) => ({
                ...p,
                isPreselected: false,
              })),
              ...preselectedProducts.map((p) => ({
                ...p,
                isPreselected: true,
              })),
            ]}
            onChange={handleProductsSelect}
            onReached={onReached}
            minimalistic={minimalistic}
            isRefreshing={isRefreshing}
            selectedVariantsLimitReached={selectedLimitReached}
          />
        </div>
      )}
    </Box>
  );
}

export default ProductSearcherContent;
