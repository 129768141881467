import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Modal, Text } from '@tiendanube/components';

interface DeleteCustomerModalProps {
  show: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

function DeleteCustomerModal({
  show,
  onConfirm,
  onDismiss,
}: DeleteCustomerModalProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const [disableConfirm, setDisableConfirm] = useState(false);

  const handleOnConfirm = () => {
    setDisableConfirm(true);
    onConfirm();
  };

  return (
    <Modal
      title={t('customerDetails.deleteCustomer.title')}
      show={show}
      onDismiss={onDismiss}
      primaryAction={{
        children: `${t('customerDetails.deleteCustomer.confirm')}`,
        appearance: 'danger',
        disabled: disableConfirm,
        onClick: handleOnConfirm,
      }}
      secondaryAction={{
        children: `${t('customerDetails.deleteCustomer.cancel')}`,
        onClick: onDismiss,
      }}
    >
      <Text>{t('customerDetails.deleteCustomer.body')}</Text>
    </Modal>
  );
}

export default DeleteCustomerModal;
