import {
  Table,
  Text,
  Box,
  Tag,
  IconButton,
  Title,
} from '@nimbus-ds/components';
import { Repeater, Stack, showMoreContentBoxStyle } from 'commons/components';

const tagSkeleton = () => <Tag.Skeleton />;

function TagsListSkeleton() {
  return (
    <Box {...showMoreContentBoxStyle} maxHeight="80px" overflow="hidden">
      <Repeater times={2} item={tagSkeleton} />
    </Box>
  );
}

export function PromotionsListDesktopRowSkeleton() {
  return (
    <Table.Row>
      <Table.Cell>
        <Stack column align="flex-start">
          <Title.Skeleton as="h5" />
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton width="100px" />
      </Table.Cell>
      <Table.Cell>
        <TagsListSkeleton />
      </Table.Cell>
      <Table.Cell>
        <TagsListSkeleton />
      </Table.Cell>
      <Table.Cell>
        <Tag.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Stack spacing="tight">
          <IconButton.Skeleton height="32px" width="32px" />
          <IconButton.Skeleton height="32px" width="32px" />
        </Stack>
      </Table.Cell>
    </Table.Row>
  );
}
