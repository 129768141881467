import { useGetTags } from 'domains/Auth/hooks';

/**
 * Custom hook to check if all specified tags are included in the store's tags.
 * @param tags - The tag(s) to check, can be a single string or an array of strings.
 * @returns An array of booleans indicating whether each specified tag is present in the user's tags.
 *  */
const useHasTags = (tags: string | string[]): boolean[] => {
  const allTags = useGetTags();
  const normalizedTags = typeof tags === 'string' ? [tags] : tags;

  // Map over each tag and check if it's included in the user's tags.
  return normalizedTags.map((tag) => allTags.includes(tag));
};

export default useHasTags;
