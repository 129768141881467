import { Switch, Route } from 'react-router-dom';
import GeneralStatsPlanThreePage from '../Pages/GeneralStatsPlanThreePage';
import StatisticsAcquisitionPage from '../Pages/StatisticsAcquisitionPage';
import StatisticsPaymentsPage from '../Pages/StatisticsPaymentsPage';
import StatisticsProductsPage from '../Pages/StatisticsProductsPage';
import StatisticsShippingPage from '../Pages/StatisticsShippingPage';
import { STATS_ROUTES } from '../statisticsRoutes';

function StatsRoutes() {
  return (
    <Switch>
      <Route path={STATS_ROUTES.stats} exact>
        <GeneralStatsPlanThreePage />
      </Route>
      <Route path={STATS_ROUTES.products}>
        <StatisticsProductsPage />
      </Route>
      <Route path={STATS_ROUTES.payments}>
        <StatisticsPaymentsPage />
      </Route>
      <Route path={STATS_ROUTES.acquisition}>
        <StatisticsAcquisitionPage />
      </Route>
      <Route path={STATS_ROUTES.shipping}>
        <StatisticsShippingPage />
      </Route>
    </Switch>
  );
}

export default StatsRoutes;
