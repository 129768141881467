import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import { routesMerged } from 'App/constants/routesMerged';
import { setCurrentPage } from 'App/settings/routingLog';
import { useAppDispatch } from 'App/store';

function useRouteWatcher() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let match;
    for (const RexParam of Object.values(routesMerged)) {
      match = matchPath(pathname, {
        path: RexParam,
        exact: true,
        strict: true,
      });
      if (match) {
        dispatch(
          setCurrentPage({
            pathPattern: match.path,
          }),
        );
        break;
      }
    }
    if (!match && import.meta.env.VITE_APP_ENV !== 'production') {
      console.error(
        `Url ${pathname} not registered, please register the route by exporting it in the domain router`,
      );
      dispatch(
        setCurrentPage({
          pathPattern: '',
        }),
      );
    }
  }, [pathname, dispatch]);
}

export default useRouteWatcher;
