import { Title } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import {
  DEFAULT_ERROR,
  LABEL_ERRORS,
  LABEL_ERRORS_TRANSLATES,
} from '../../utils/OcaLabelErrors';

interface OcaLabelErrorProps {
  error: string | null;
}

function OcaLabelError({ error }: Readonly<OcaLabelErrorProps>): JSX.Element {
  const t = useTranslationFulfillmentOrders(
    `printManager.oca.errors.${
      !!error && LABEL_ERRORS.includes(error)
        ? LABEL_ERRORS_TRANSLATES[error]
        : DEFAULT_ERROR
    }`,
  );

  return (
    <Stack column spacing="tight" align="center">
      <ExclamationTriangleIcon
        size={32}
        style={{
          marginTop: '3rem',
        }}
      />
      <Title as="h5">{t('title')}</Title>
      <div dangerouslySetInnerHTML={{ __html: t('description') }} />
    </Stack>
  );
}

export default OcaLabelError;
