import { useTranslation } from 'react-i18next';
import { LocationResponseDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import {
  InteractiveList,
  InterfaceNameValue,
  Select,
} from '@tiendanube/components';

import './LocationSelection.scss';

interface SelectLocationProps {
  locations: LocationResponseDto[];
  locationId?: string | null;
  hasVariants: boolean;
  isMobile: boolean;
  onChange: (event: InterfaceNameValue) => void;
}

function LocationSelection({
  locations,
  locationId,
  hasVariants,
  isMobile,
  onChange,
}: SelectLocationProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const locationMain = locations.find((location) => location.main);

  const optionsSelect = locations.map((location) => {
    const { id, address, main } = location;
    const name = main
      ? `${location.name} (${t('products.detail.locationSelectMain')})`
      : location.name;
    const label = isMobile || hasVariants ? name : `${name} - ${address}`;
    return {
      value: id,
      label,
    };
  });

  const optionsInteractiveList = locations.map((location) => {
    const { id, address, main } = location;
    const title = main
      ? `${location.name} (${t('products.detail.locationSelectMain')})`
      : location.name;
    const isActive = (main && !locationId) || id === locationId;
    return {
      title,
      name: id,
      description: isActive ? address : undefined,
      active: isActive,
    };
  });

  const handleChangeInteractiveList = ([locationId]: string[]) => {
    onChange({ name: 'locationId', value: locationId });
  };

  return (
    <div className="stratus--location-selection">
      {locations.length <= 6 && !hasVariants && (
        <InteractiveList
          mode="single"
          options={optionsInteractiveList}
          onChange={handleChangeInteractiveList}
        />
      )}
      {(locations.length > 6 || hasVariants) && (
        <Select
          name="locationId"
          value={locationId ?? locationMain?.id}
          options={optionsSelect}
          onChange={onChange}
        />
      )}
    </div>
  );
}

export default LocationSelection;
