import { Box, BoxProps, Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Link as LinkRouter } from 'react-router-dom';
import { Stack } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingSelectStatisticsClick } from 'domains/Dashboard/tracking';
import { STATS_ROUTES } from 'domains/Statistics/statisticsRoutes';
import { thousandSeparator } from 'domains/Statistics/utils';
import Skeleton from './Skeleton';
import StatsDailyValueCard from './StatsDailyValueCard';

export const STYLE_STATS_DAILY_TAB_CONTENT: BoxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '4',
};

interface StatsDailyTabContentValues {
  visits: number;
  salesQty: number;
  billing?: number;
  averageTicket?: number;
}

interface StatsDailyTabContentProps {
  values?: StatsDailyTabContentValues;
  hideAmounts: boolean;
}

function StatsDailyTabContent({
  values,
  hideAmounts,
}: StatsDailyTabContentProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const t = useTranslationDashboard();
  const { code } = useGetCurrency();

  const getFormatedValue = (value?: number, isCurrencyFormat?: boolean) => {
    if (hideAmounts && isCurrencyFormat) return '*****';
    // returning a hyphen helps us to identify when an error occurs in the call to the service
    if (value === undefined) return '-';
    if (isCurrencyFormat) {
      return formatCurrency(value, code, language);
    }

    return thousandSeparator(value, true);
  };

  return (
    <Stack column align="stretch">
      <Box {...STYLE_STATS_DAILY_TAB_CONTENT}>
        <StatsDailyValueCard
          title={t('tabs.visits')}
          value={getFormatedValue(values?.visits)}
          textTooltip={t('tabs.visitsTooltip')}
        />
        <StatsDailyValueCard
          title={t('tabs.sales')}
          value={getFormatedValue(values?.salesQty)}
        />
        <StatsDailyValueCard
          title={t('tabs.billing')}
          value={getFormatedValue(values?.billing, true)}
          textTooltip={t('tabs.billingTooltip')}
        />
        <StatsDailyValueCard
          title={t('tabs.averageTicket')}
          value={getFormatedValue(values?.averageTicket, true)}
          textTooltip={t('tabs.averageTicketTooltip')}
        />
      </Box>
      <Link
        appearance="primary"
        textDecoration="none"
        as={LinkRouter}
        to={STATS_ROUTES.stats}
        onClick={trackingSelectStatisticsClick}
      >
        {t('tabs.link')}
      </Link>
    </Stack>
  );
}

StatsDailyTabContent.Skeleton = Skeleton;

export default StatsDailyTabContent;
