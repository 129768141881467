import { Box, Input, Label } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function ProductsCountFilter({ value, onChange }): JSX.Element {
  const t = useTranslationOrders();

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ name: 'productsCount', value: e.target.value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  return (
    <Box borderStyle="none">
      <Box paddingBottom="2" display="flex" alignItems="flex-start" gap="1">
        <Label>{t('filter.productsCount')}</Label>
      </Box>
      <Box>
        <Input
          type="number"
          min={1}
          value={value ?? ''}
          placeholder={t('filter.productsCountPlaceholder')}
          onChange={handleValueChange}
          onKeyDown={handleKeyDown}
        />
      </Box>
    </Box>
  );
}

export default ProductsCountFilter;
