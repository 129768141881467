import { Box, Tag, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { useResponsive } from '../../../../../commons/components';
import { useTranslationAccount } from '../../../hooks';
import { useGetLastActivityDescription } from '../hooks';
import { ICON_SIZE, INACTIVITY_LIMIT_TIME_IN_DAYS } from '../settings';

interface LastActivityTextProps {
  lastActivity: string;
}

export function LastActivityText({
  lastActivity,
}: Readonly<LastActivityTextProps>) {
  const t = useTranslationAccount('sessionManagementPage.table.body');
  const { isMobile } = useResponsive();
  const deviceKey = isMobile ? 'mobile' : 'desktop';
  const currentDate = new Date();
  const lastActivityDate = new Date(lastActivity);
  const lastActivityDescription = useGetLastActivityDescription(
    lastActivity,
    isMobile,
  );

  if (isDateOlderThanInactivityDeadline(lastActivityDate, currentDate)) {
    const tag = (
      <Tag appearance="warning">
        <ExclamationCircleIcon size={ICON_SIZE} />{' '}
        <Text fontSize="caption" color="warning-textLow" lineClamp={1}>
          {t(`lastActivity.${deviceKey}.inactiveTag`)}
        </Text>
      </Tag>
    );

    if (isMobile) {
      return <Box marginTop="1">{tag}</Box>;
    }

    return tag;
  }

  return <Text>{lastActivityDescription}</Text>;
}

function isDateOlderThanInactivityDeadline(
  date: Date,
  currentDate: Date,
): boolean {
  const tenDaysAgo = new Date(currentDate);
  tenDaysAgo.setDate(currentDate.getDate() - INACTIVITY_LIMIT_TIME_IN_DAYS);

  return date < tenDaysAgo;
}
