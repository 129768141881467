import { Button, ButtonProps, Spinner } from '@nimbus-ds/components';

export interface SubmitButtonProps
  extends Omit<ButtonProps, 'disabled' | 'children' | 'appearance'> {
  appearance?: ButtonProps['appearance'];
  isLoading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

function SubmitButton({
  isLoading = false,
  disabled = false,
  children,
  appearance = 'primary',
  ...props
}: SubmitButtonProps) {
  return (
    <Button appearance={appearance} disabled={isLoading || disabled} {...props}>
      {isLoading && <Spinner color="currentColor" size="small" />}
      {children}
    </Button>
  );
}

export default SubmitButton;
