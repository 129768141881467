export const ActivityLogAuthorTypes = {
  USER: "user",
  APP: "app",
} as const;

export const SortOrder = {
  ASC: "asc",
  DESC: "desc",
} as const;

export type SortOrderType = typeof SortOrder[keyof typeof SortOrder];

export type ActivityLogAuthorType =
  typeof ActivityLogAuthorTypes[keyof typeof ActivityLogAuthorTypes];

export const ActivityLogEntityTypes = {
  COUPON: "coupon",
} as const;

export type ActivityLogEntityType =
  typeof ActivityLogEntityTypes[keyof typeof ActivityLogEntityTypes];

export const CouponActivityTypes = {
  COUPON_CREATED: "CouponCreated",
  COUPON_UPDATED: "CouponUpdated",
  COUPON_DELETED: "CouponDeleted",
  COUPON_ACTIVATED: "CouponActivated",
  COUPON_DEACTIVATED: "CouponDeactivated",
};

export type CouponActivityType =
  typeof CouponActivityTypes[keyof typeof CouponActivityTypes];

export const ActivityType = { ...CouponActivityTypes };
export type ActivityType = CouponActivityType;

export const MIN_PAGE_NUMBER = 1;
export const MAX_PAGE_SIZE = 500;

export const CouponValueTypes = {
  PERCENTAGE: "percentage",
  ABSOLUTE: "absolute",
} as const;

export type CouponValueType =
  typeof CouponValueTypes[keyof typeof CouponValueTypes];
