import { useMemo } from 'react';
import { VirtualInteractiveList } from 'commons/components';
import { ProductListItem } from './ProductListItem';
import { ProductInterface, SelectedProductInterface } from '../types';

interface ProductListProps<Minimalistic extends boolean> {
  products: ProductInterface<false>[];
  selectedProducts: SelectedProductInterface[];
  minimalistic?: boolean;
  selectedVariantsLimitReached?: boolean;
  onChange: (
    selectedProducts: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => void;
  onReached: () => void;
  isRefreshing?: boolean;
}

interface ProductListItemContainerProps<Minimalistic extends boolean> {
  selectedProducts: SelectedProductInterface[];
  index: number;
  productIds: string[];
  products: ProductInterface<false>[];
  minimalistic?: boolean;
  selectedVariantsLimitReached?: boolean;
  onChange: (
    selectedProducts: ProductInterface<Minimalistic>[],
    checked: boolean,
  ) => void;
}

function ProductListItemContainer<Minimalistic extends boolean>({
  index,
  productIds,
  products,
  selectedProducts,
  onChange,
  minimalistic,
  selectedVariantsLimitReached,
}: Readonly<ProductListItemContainerProps<Minimalistic>>) {
  const variants = useMemo(
    () => products.filter((p) => p.productId === productIds[index]),
    [products, productIds, index],
  );
  const selectedVariants = useMemo(
    () =>
      selectedProducts.filter(
        (sProduct) => sProduct.productId === productIds[index],
      ),
    [selectedProducts, productIds, index],
  );

  return (
    <ProductListItem
      variants={variants}
      selectedVariants={selectedVariants}
      minimalistic={minimalistic}
      onChange={onChange}
      selectedVariantsLimitReached={selectedVariantsLimitReached}
    />
  );
}

function ProductList<Minimalistic extends boolean>({
  onChange,
  onReached,
  products,
  selectedProducts,
  minimalistic,
  selectedVariantsLimitReached,
  isRefreshing = false,
}: Readonly<ProductListProps<Minimalistic>>) {
  const productIds = useMemo(
    () => [...new Set(products.map((product) => product.productId))],
    [products],
  );

  return (
    <VirtualInteractiveList
      totalCount={productIds.length}
      endReached={onReached}
      showFooter={isRefreshing}
      itemContent={(index) => (
        <ProductListItemContainer
          index={index}
          productIds={productIds}
          products={products}
          selectedProducts={selectedProducts}
          onChange={onChange}
          minimalistic={minimalistic}
          selectedVariantsLimitReached={selectedVariantsLimitReached}
        />
      )}
    />
  );
}

export default ProductList;
