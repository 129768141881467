import { useCallback, useState } from 'react';
import { Alert, Button, Card, Link, Text, Title } from '@nimbus-ds/components';
import { RecoveryCodesResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import { trackingRecoveryCodesUpdate } from 'domains/Account/tracking';
import useRegenerateRecoveryCodes from './useRegenerateRecoveryCodes';
import RecoveryCodesCard from '../RecoveryCodesCard';

interface RecoveryCodesPageProps {
  recoveryCodes: RecoveryCodesResponseDto[];
  onContinue: () => void;
  allowToGenerateNewCodes: boolean;
}

function RecoveryCodesPage({
  recoveryCodes,
  onContinue,
  allowToGenerateNewCodes,
}: Readonly<RecoveryCodesPageProps>) {
  const t = useTranslationAccount('authenticationFactorPage.recoveryCodesPage');
  const { regenerate, status, reset } = useRegenerateRecoveryCodes();
  const [codesAlreadyRegenerated, setCodesAlreadyRegenerated] = useState(false);

  const onlyOneCodeLeft =
    recoveryCodes.filter(({ usedAt }) => !usedAt).length === 1;
  const showRegenerateCodesCard =
    allowToGenerateNewCodes && !codesAlreadyRegenerated && !onlyOneCodeLeft;

  const regenerateCodes = () => {
    trackingRecoveryCodesUpdate();
    setCodesAlreadyRegenerated(true);
    regenerate();
  };

  const onError = useCallback(() => {
    reset();
    onContinue();
  }, [reset, onContinue]);

  useToastStatus({
    status,
    success: t('updateCodes.newCodesGenerated'),
    onSuccess: reset,
    error: t('updateCodes.errorGeneratingCodes'),
    onError,
  });

  if (status === 'loading') {
    return <RecoveryCodesCard.Skeleton />;
  }

  return (
    <>
      {onlyOneCodeLeft && (
        <Alert appearance="warning" title={t('oneCodeLeftAlert.title')}>
          <Text>{t('oneCodeLeftAlert.description')}</Text>
          <Link onClick={regenerateCodes}>{t('oneCodeLeftAlert.action')}</Link>
        </Alert>
      )}
      <RecoveryCodesCard recoveryCodes={recoveryCodes} />
      {showRegenerateCodesCard && (
        <Card>
          <Card.Body>
            <Stack column gap="2" align="flex-start">
              <Title as="h6">{t('updateCodes.title')}</Title>
              <Text>{t('updateCodes.description')}</Text>
            </Stack>
          </Card.Body>
          <Card.Footer>
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={regenerateCodes}
            >
              {t('updateCodes.generate')}
            </Link>
          </Card.Footer>
        </Card>
      )}
      <Stack justify="flex-end">
        <Button onClick={onContinue}>{t('return')}</Button>
      </Stack>
    </>
  );
}

export default RecoveryCodesPage;
