import { useState } from 'react';
import ModalAside from 'App/components/lab/ModalAside';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons, HeaderTop } from 'commons/components';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import HowToInstallOcaCard from './HowToInstallOcaCard';
import { AddActiveShippingMethodsDetailInterface } from '../../AddActiveShippingMethodsDetailModal';
import { AboutAppCard } from '../../components';

interface AddOcaModalProps {
  app: AddActiveShippingMethodsDetailInterface;
  onClose: () => void;
}

function AddOcaModal({ app, onClose }: AddOcaModalProps) {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const [option, setOption] = useState<'signup' | 'config'>(
    app.ocaRegisterDate ? 'config' : 'signup',
  );

  const handleOnConfirm = () => {
    goTo(
      option === 'signup'
        ? DELIVERY_METHODS_ROUTES.requestOcaRegistration
        : DELIVERY_METHODS_ROUTES.oca,
    );
  };

  const backNavigation = {
    onClick: onClose,
    children: t('deliveryMethods.title'),
  };

  return (
    <ModalAside
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={`${t(
        'deliveryMethods.activeShippingMethods.modal.detail.title',
        {
          app: app.name,
        },
      )}`}
      onDidDismiss={onClose}
      isOpen
    >
      <AboutAppCard
        name={app.name}
        description={app.description}
        tags={app.tags}
        links={app.links}
        id={app.appId}
      />
      <HowToInstallOcaCard
        ocaRegisterDate={app.ocaRegisterDate}
        option={option}
        onChangeOption={setOption}
      />
      <CancelAndSaveButtons
        saveText={t('deliveryMethods.activeShippingMethods.modal.continue')}
        onSave={handleOnConfirm}
        onCancel={onClose}
      />
    </ModalAside>
  );
}

export default AddOcaModal;
