import { Alert, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface AlertIsOverwritingOcaLabelsProps {
  isUnique: boolean;
  totalCost: string;
}
function AlertIsOverwritingOcaLabels({
  isUnique,
  totalCost,
}: Readonly<AlertIsOverwritingOcaLabelsProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint');
  return (
    <Alert appearance="primary" title={t('regenerate.title')}>
      <Stack column align="flex-start">
        <Text>
          {t('regenerate.description', {
            action: isUnique ? t('actionUnique') : t('action'),
            totalCost: totalCost,
          })}
        </Text>
        <Stack column align="flex-start" spacing="tight">
          <Text fontWeight="bold">{t('regenerate.helpText')}</Text>
          <Text>{t('regenerate.helpText2')}</Text>
        </Stack>
      </Stack>
    </Alert>
  );
}

export default AlertIsOverwritingOcaLabels;
