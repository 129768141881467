import { ChangeEvent, useMemo, useState } from 'react';
import {
  FreeShippingSubzoneDto,
  FreeShippingZoneDto,
} from '@tiendanube/common';
import { useGetFreeShippingsSubZonesQuery } from 'domains/Marketing/FreeShipping/freeShippingApi';
import { DeliveryZonesSelectedInterface } from '../DeliveryZonesModalAside';

export interface DeliverySubzonesCheckedInterface
  extends FreeShippingSubzoneDto {
  checked: boolean;
}

export interface UseDeliverySubzonesModalAsideProps {
  isOpen: boolean;
  zone: DeliveryZonesSelectedInterface;
  onChange: (zone: FreeShippingZoneDto) => void;
  onRemove: (idZone: string) => void;
}

function useDeliverySubzonesModalAside({
  isOpen,
  zone,
  onChange,
  onRemove,
}: UseDeliverySubzonesModalAsideProps) {
  const { data, isLoading, isFetching, isError, isSuccess, refetch } =
    useGetFreeShippingsSubZonesQuery(zone.id, { skip: !isOpen });

  const [search, setSearch] = useState('');
  const searchLowerCase = search.toLowerCase();
  const subzonesStore: DeliverySubzonesCheckedInterface[] = useMemo(
    () =>
      (data || [])
        .filter(
          ({ description }) =>
            !search || description.toLowerCase().includes(searchLowerCase),
        )
        .map((currentData) => ({
          ...currentData,
          checked: zone.subzones.some(
            (current) => current.id === currentData.id,
          ),
        })),
    [data, search, searchLowerCase, zone.subzones],
  );

  const isAllZoneSelected =
    zone.subzones.length === subzonesStore.length && zone.selected;

  const handleOnChangeSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const handleCheckedSubzone = (
    subzone: FreeShippingSubzoneDto,
    checked: boolean,
  ) => {
    const { id, description, subzones } = zone;
    if (isAllZoneSelected) {
      const othersSubzonesStore = subzonesStore.filter(
        (current) => current.id !== subzone.id,
      );
      if (subzonesStore.length > 1) {
        // Case 1/6: User is unchecking a subzone -> add others subzones
        return onChange({ id, description, subzones: othersSubzonesStore });
      } else {
        // Case 2/6: User is unchecking a unique subzone -> remove zone e.g. Capital Federal
        return onRemove(id);
      }
    }

    if (checked) {
      const newSubzones = [...subzones, subzone];
      if (newSubzones.length === subzonesStore.length) {
        // Case 3/6: User is checking a last unchecked subzone -> add zone.
        return onChange({ id, description, subzones: [] });
      } else {
        // Case 4/6: User is checking a (not last) unchecked subzone -> add this subzone
        return onChange({ id, description, subzones: newSubzones });
      }
    }

    if (subzones.length > 1) {
      // Case 5/6: User is unchecking subzone (not last) -> remove this subzone.
      const othersSubzones = subzones.filter(
        (current) => current.id !== subzone.id,
      );
      return onChange({
        id,
        description,
        subzones: othersSubzones,
      });
    } else {
      // Case 6/6:User is unchecking last checked subzone -> remove zone.
      return onRemove(id);
    }
  };

  const selectedCount = isAllZoneSelected
    ? subzonesStore.length || 0
    : zone.subzones.length;

  const allCheckedValue: boolean | 'indeterminate' = isAllZoneSelected
    ? true
    : selectedCount < (subzonesStore.length || 0) && selectedCount > 0
    ? 'indeterminate'
    : false;

  const handleCheckAll = () => {
    const { id, description } = zone;
    if (isAllZoneSelected) {
      return onRemove(id);
    } else {
      return onChange({
        id,
        description,
        subzones: subzonesStore,
      });
    }
  };

  const isLoadingState = isLoading || isFetching;
  const isSuccessState = !isLoadingState && isSuccess;

  return {
    subzonesStore,
    isLoading: isLoadingState,
    isError,
    isSuccess: isSuccessState,
    isAllZoneSelected,
    search,
    selectedCount,
    allCheckedValue,
    refetch,
    handleCheckAll,
    handleOnChangeSearch,
    handleCheckedSubzone,
  };
}

export default useDeliverySubzonesModalAside;
