import { Alert, Text } from '@nimbus-ds/components';
import { format } from 'date-fns';
import { useDismissibleComponent } from 'commons/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { usePayment } from '../../hooks';
import { PaymentStatusAlertProps } from '../../PaymentStatusAlert';
import GoToPayPlanLink from '../GoToPayPlanLink/GoToPayPlanLink';

function PaymentStatusAlertTransfer({
  concept,
  externalReference,
  isExternalContext = false,
  chargeId,
}: PaymentStatusAlertProps) {
  const t = useTranslationBilling('checkout.paymentStatusAlert.transfer');
  const paymentData = usePayment(concept, externalReference, chargeId);
  const formatCurrency = useFormatCurrency();
  const isMobileDevice = useIsMobileDevice();

  // We use a key that contains the id of the payment so that the alert can be seen at least once per successful payment
  const { dismiss, shouldShow } = useDismissibleComponent(
    `${PaymentStatusAlertTransfer.name}Success-${
      paymentData?.payment?.id ?? ''
    }`,
  );

  if (!paymentData) return null;
  const { paymentStatus, payment } = paymentData;

  if (!payment) return null;

  const mobileSuffix = isMobileDevice ? 'Mobile' : '';

  switch (paymentStatus) {
    case 'IN_PROCESS':
      return (
        <Alert
          show={!isExternalContext}
          appearance="warning"
          title={t('inProgress.title')}
        >
          <Text>{t(`inProgress.message`)}</Text>
        </Alert>
      );
    case 'FAILED':
      // In an external context, for example in Dashboard, we show this only alert
      return (
        <Alert appearance="danger" title={t('failed.title')}>
          <Text>{t(`failed.message${mobileSuffix}`)}</Text>
          {isExternalContext && <GoToPayPlanLink />}
        </Alert>
      );
    case 'PAID': {
      if (!payment.paidAt) return null;
      return (
        <Alert
          appearance="success"
          show={!isExternalContext && shouldShow}
          onRemove={dismiss}
        >
          {t('success.message', {
            amount: formatCurrency(payment.amountValue, payment.amountCurrency),
            date: format(new Date(payment.paidAt), 'dd/MM/yyyy'),
          })}
        </Alert>
      );
    }
    default:
      return null;
  }
}

export default PaymentStatusAlertTransfer;
