import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Currency } from '@tiendanube/common/src/enums';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { useAppDispatch } from 'App/store';
import { getDefaultCurrency } from 'domains/Auth/authSlice/authSelectors';
import { useGetLimitingFeatureByKey } from 'domains/Auth/hooks';
import { StatisticsDailyType } from 'domains/Statistics/selectors/types';
import { STATS_PLAN_FREE } from 'domains/Statistics/utils';
import {
  getStatisticsDaily,
  getStatisticsDailyLoadingError,
} from '../../selectors';
import { getDaily } from '../../statisticsSlice';

export interface UseStatisticsDailyResult {
  statisticsDaily: StatisticsDailyType;
  currency: Currency;
  error: boolean;
  loading: boolean;
  showTabs: boolean;
}

function useStatisticsDaily(): UseStatisticsDailyResult {
  const dispatch = useAppDispatch();
  const statisticsDaily = useSelector(getStatisticsDaily);
  const currency = useSelector(getDefaultCurrency);
  const { error, loading } = useSelector(getStatisticsDailyLoadingError);
  const isPlanFree = useGetLimitingFeatureByKey(
    FEATURE_NEW_ADMIN_STATS,
    STATS_PLAN_FREE,
  );

  useEffect(() => {
    dispatch(getDaily());
  }, [dispatch, isPlanFree]);

  return {
    showTabs: !isPlanFree,
    statisticsDaily,
    currency,
    error,
    loading,
  };
}

export default useStatisticsDaily;
