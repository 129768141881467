import {
  AddGenericModal,
  AddOcaModal,
  AvailableShippingAppsLinks,
  AvailableShippingAppsTags,
  AddCorreiosModal,
} from './components';

export interface AddActiveShippingMethodsDetailInterface {
  name: string;
  description: string;
  id?: string;
  appId: string;
  tags: AvailableShippingAppsTags[];
  links: AvailableShippingAppsLinks[];
  code: string;
  logo: string;
  ocaRegisterDate?: string;
}

interface AddActiveShippingMethodsDetailModalProps {
  app: AddActiveShippingMethodsDetailInterface;
  onClose: () => void;
  onSave: () => void;
}

function AddNativeShippingMethodsDetailModal({
  onClose,
  onSave,
  app,
}: AddActiveShippingMethodsDetailModalProps): JSX.Element {
  if (app.code === 'oca-partner-ar')
    return <AddOcaModal app={app} onClose={onClose} />;
  if (app.code === 'correios')
    return <AddCorreiosModal app={app} onClose={onClose} />;

  return <AddGenericModal app={app} onSave={onSave} onClose={onClose} />;
}

export default AddNativeShippingMethodsDetailModal;
