import useGetIsUserFullRole from 'domains/Auth/hooks/useGetIsUserFullRole';
import {
  EmptyCard,
  EmptyAlert,
  UpsellAlertCard,
} from 'domains/Metafields/components';
import CardMetafieldsList from 'domains/Metafields/components/CardMetafieldsList';
import LinkToMetafieldsBasedOnUser from 'domains/Metafields/components/LinkToMetafieldsBasedOnUser';
import MetafieldsOrdersDataList from 'domains/Metafields/components/MetafieldsOrdersDataList';
import { RenderOrderMetafieldWhen } from 'domains/Metafields/components/RenderMetafieldWhen';
import { useGetMetafields } from 'domains/Orders/Orders/hooks';

interface SectionOrdersMetafieldsProps {
  orderId: string;
}
function SectionOrdersMetafields({ orderId }: SectionOrdersMetafieldsProps) {
  const {
    isSuccess,
    isLoading,
    isError,
    hasInternals,
    apiMetafields,
    metafields,
    fetchMetafields,
  } = useGetMetafields();
  const isFullUser = useGetIsUserFullRole();

  const handleOnRetry = () => fetchMetafields(orderId);

  const apiMetafieldsList = apiMetafields ? Object.keys(apiMetafields) : [];
  const metafieldsList = metafields ? Object.keys(metafields) : [];
  return (
    <>
      {isError && <CardMetafieldsList.ErrorState onRetry={handleOnRetry} />}
      {isLoading && <CardMetafieldsList.Skeleton />}

      {isSuccess && (
        <>
          <RenderOrderMetafieldWhen
            conditions={[
              { hasInternals: false, hasExternals: true, isAvailable: false },
              { hasInternals: true, hasExternals: true, isAvailable: false },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={
                  <UpsellAlertCard hasInternals={hasInternals} owner="order" />
                }
                apiMetafieldSlot={
                  <MetafieldsOrdersDataList
                    list={apiMetafieldsList}
                    orderId={orderId}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <MetafieldsOrdersDataList
                    list={apiMetafieldsList}
                    orderId={orderId}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderOrderMetafieldWhen>

          <RenderOrderMetafieldWhen
            conditions={[
              { hasInternals: false, hasExternals: false, isAvailable: true },
            ]}
          >
            {isFullUser ? <EmptyAlert ownerResource="order" /> : null}
          </RenderOrderMetafieldWhen>

          <RenderOrderMetafieldWhen
            conditions={[
              { hasInternals: true, hasExternals: false, isAvailable: true },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsOrdersDataList
                    list={metafieldsList}
                    orderId={orderId}
                  />
                  <LinkToMetafieldsBasedOnUser isFullUser={isFullUser} />
                </>
              }
              metafieldsTotal={metafieldsList.length}
            />
          </RenderOrderMetafieldWhen>

          <RenderOrderMetafieldWhen
            conditions={[
              { hasInternals: false, hasExternals: true, isAvailable: true },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={<EmptyCard />}
                apiMetafieldSlot={
                  <MetafieldsOrdersDataList
                    list={apiMetafieldsList}
                    orderId={orderId}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <MetafieldsOrdersDataList
                    list={apiMetafieldsList}
                    orderId={orderId}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderOrderMetafieldWhen>

          <RenderOrderMetafieldWhen
            conditions={[
              { hasInternals: true, hasExternals: true, isAvailable: true },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsOrdersDataList
                    list={metafieldsList}
                    orderId={orderId}
                  />
                  <LinkToMetafieldsBasedOnUser
                    isFullUser={isFullUser}
                    hasPaddingBottom
                  />
                </>
              }
              apiMetafieldSlot={
                <MetafieldsOrdersDataList
                  list={apiMetafieldsList}
                  orderId={orderId}
                />
              }
              metafieldsTotal={metafieldsList.length + apiMetafieldsList.length}
            />
          </RenderOrderMetafieldWhen>
        </>
      )}
    </>
  );
}

export default SectionOrdersMetafields;
