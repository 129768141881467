import { useEffect, useState } from 'react';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import MexicanFiscalDataForm, {
  FiscalDataformMexico,
} from './components/MexicanFiscalDataForm';
import useMexicanFiscalData from './useMexicanFiscalData';
import validationSchema from './validationSchema';
import { useGetAddressesMexico } from '../../../Addresses';

const ZIPCODE_LENGTH = 5;

const defaultValues = {
  name: '',
  numberId: '',
  street: '',
  locality: '',
  province: '',
  city: '',
  zipcode: '',
  number: '',
  floor: '',
  vatCondition: '',
};

function MexicanFiscalData() {
  const t = useTranslationBilling();
  const { createFiscalData, updateFiscalData, status, cleanData } =
    useMexicanFiscalData();
  const { goBack } = useNavegate();

  const { invoiceInfo, fetchInvoiceInfo, isLoading, hasInvoiceInfo } =
    useGetInvoiceInfo();
  const [initialValues, setInitialValues] =
    useState<Partial<FiscalDataformMexico>>(defaultValues);
  const {
    errors,
    values,
    setFieldValue,
    handleOnSubmit,
    setMultipleFieldValues,
    isDirty,
  } = useForm<FiscalDataformMexico>({
    initialValues: initialValues as FiscalDataformMexico,
    validationSchema,
    onSubmit: (data) => {
      if (hasInvoiceInfo) {
        updateFiscalData(data, invoiceInfo.id);
      } else {
        createFiscalData(data);
      }
    },
  });

  const { fetchAddressesMexico } = useGetAddressesMexico();

  useEffect(() => {
    if (invoiceInfo) {
      const zipcode = invoiceInfo.zipcode
        ?.toString()
        .padStart(ZIPCODE_LENGTH, '0');
      setInitialValues({
        street: invoiceInfo.address,
        name: invoiceInfo.name,
        numberId: invoiceInfo.numberId,
        floor: invoiceInfo.floor,
        number: invoiceInfo.number?.toString(),
        zipcode: zipcode,
        vatCondition: invoiceInfo.vatCondition?.toString(),
        city: invoiceInfo.cityName,
        province: invoiceInfo.provinceName,
        locality: invoiceInfo.locality,
      });
      if (zipcode) fetchAddressesMexico(zipcode);
    }
  }, [invoiceInfo, setInitialValues, fetchAddressesMexico]);
  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  const onChange = ({ name, value }) => setFieldValue(name, value);

  useToastStatus({
    error: t('invoicesInfoPage.error'),
    success: t('invoicesInfoPage.success'),
    status,
    onSuccess: () => {
      cleanData();
      goBack();
    },
  });

  return isLoading ? (
    <MexicanFiscalDataForm.Skeleton onCancel={goBack} />
  ) : (
    <MexicanFiscalDataForm
      onSave={handleOnSubmit}
      onCancel={goBack}
      onChange={onChange}
      values={values}
      errors={errors}
      setMultipleFieldValues={setMultipleFieldValues}
      isLoading={isLoading || status === 'loading'}
      isDirty={isDirty}
    />
  );
}

export default MexicanFiscalData;
