import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { StatePriv } from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import {
  CustomerMessages,
  CustomerOrders,
  CustomerActivitySkeleton,
} from '../../components';
import useCustomerActivity from '../../hooks/useCustomerActivity';

interface CustomerActivityProps {
  customerId: string;
  email: string;
  phone: string;
}

function CustomerActivity({
  customerId,
  email,
  phone,
}: CustomerActivityProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const {
    isLoadingMessages,
    isLoadingOrders,
    customerMessagesIds,
    customerOrdersIds,
  } = useCustomerActivity();

  const isLoading = isLoadingMessages || isLoadingOrders;
  const isEmptyMessages = !customerMessagesIds.length;
  const isEmptyOrders = !customerOrdersIds.length;

  if (isLoading && isEmptyMessages && isEmptyOrders) {
    return <CustomerActivitySkeleton />;
  }
  if (isEmptyMessages && isEmptyOrders) {
    return (
      <StatePriv
        title={t('customerDetails.customerActivity.emptyOrdersTitle')}
        text={`${t('customerDetails.customerActivity.emptyOrdersText')}`}
        icon={InfoCircleIcon}
      />
    );
  }

  return (
    <>
      <CustomerMessages customerId={customerId} phone={phone} email={email} />
      <CustomerOrders customerId={customerId} />
    </>
  );
}

export default CustomerActivity;
