import { SVGProps } from 'react';
import { Text } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import { InternalNavLink } from 'commons/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { thousandSeparator } from 'domains/Statistics/utils';
import { PERCENTAGE_NUMBER_COLORS, LABELS } from '../../utils';
import { ConversionBarChartInterface } from '../ConversionBarChart';

interface CustomXAxisTickProps extends SVGProps<SVGForeignObjectElement> {
  data: ConversionBarChartInterface[];
  index?: number;
}

function ConversionYAxisTick({
  x = 0,
  y = 0,
  index = 0,
  data,
  width = 0,
  height = 0,
}: CustomXAxisTickProps) {
  const t = useTranslationStatistics();
  return (
    <foreignObject
      x={Number(x) - 130}
      y={Number(y) - 10}
      width={width}
      height={height}
    >
      <Stack column align="flex-end" spacing="none">
        <Text
          fontSize="highlight"
          color={
            PERCENTAGE_NUMBER_COLORS[data[index]?.stepName] || 'primary-textLow'
          }
        >
          {thousandSeparator(data[index]?.percentage)}%
        </Text>
        <Text textAlign="right" fontSize="caption" color="neutral-textLow">
          {t(LABELS[data[index]?.stepName], {
            count: data[index].stepValue,
            replace: {
              count: thousandSeparator(data[index].stepValue),
            },
          })}
        </Text>
        {data[index]?.stepName === 'cartsThatCanBeConvertedIntoSales' &&
          data[index].stepValue > 0 && (
            <InternalNavLink to="/abandoned-carts">
              {t('statistics.orderConversionCard.link')}
            </InternalNavLink>
          )}
      </Stack>
    </foreignObject>
  );
}

export default ConversionYAxisTick;
