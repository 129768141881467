type UnknownFunction = (...args: any[]) => any;
type ExtractReturnType<T extends readonly UnknownFunction[]> = {
  [index in keyof T]: T[index] extends T[number] ? ReturnType<T[index]> : never;
};
type Selector<State = any, Result = unknown> = (state: State) => Result;
type SelectorArray = ReadonlyArray<Selector>;
type SelectorResultArray<Selectors extends SelectorArray> =
  ExtractReturnType<Selectors>;

const createSimpleSelector =
  <Selectors extends SelectorArray, Result, State>(
    callbacks: [...Selectors],
    combiner: (...args: SelectorResultArray<Selectors>) => Result,
  ) =>
  (state: State): Result => {
    const result = callbacks.map((fn) => fn(state));
    return combiner(...(result as any));
  };

export default createSimpleSelector;
