import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { incrementNewOrders } from '../../ordersSlice';

type UseIncrementNewOrdersType = () => void;

function useIncrementNewOrders(): UseIncrementNewOrdersType {
  const dispatch = useAppDispatch();

  const refreshOrders = useCallback(async () => {
    dispatch(incrementNewOrders());
  }, [dispatch]);

  return refreshOrders;
}

export default useIncrementNewOrders;
