import {
  BaseCard,
  IconButton,
  Link,
  Stack,
  Text,
} from '@tiendanube/components';
import { useResponsive } from 'commons/components';

function TaxConditionSkeleton(): JSX.Element {
  const { isMobile } = useResponsive();

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack justify="space-between">
          <Text.Skeleton />
          {isMobile && <IconButton.Skeleton />}
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Text.Skeleton />
      </BaseCard.Body>
      {!isMobile && (
        <BaseCard.Footer>
          <Link.Skeleton />
        </BaseCard.Footer>
      )}
    </BaseCard>
  );
}

export default TaxConditionSkeleton;
