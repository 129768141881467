import { ReactNode } from 'react';
import { isDescendant } from '@nosferatu500/react-sortable-tree';
import { NodeRendererProps } from '@nosferatu500/react-sortable-tree/node-renderer-default';
import { ChevronDownIcon, ChevronRightIcon } from '@tiendanube/icons';
import { DragIcon, Stack } from 'commons/components';
import './CustomNode.scss';

const INDENT_PER_DEPTH = 32;

function CustomNode(props: NodeRendererProps) {
  const {
    toggleChildrenVisibility,
    connectDragPreview,
    connectDragSource,
    canDrag,
    node,
    title,
    draggedNode,
    path,
    treeIndex,
    buttons,
    treeId: _treeId,
    isOver: _isOver, // Not needed, but preserved for other renderers
    parentNode: _parentNode, // Needed for dndManager
  } = props;
  const nodeTitle = title as ReactNode;
  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const indent = (path.length - 1) * INDENT_PER_DEPTH;
  const showToggleButton =
    toggleChildrenVisibility &&
    node.children &&
    (node.children.length > 0 || typeof node.children === 'function');

  const toggleVisibility = () =>
    toggleChildrenVisibility?.({
      node,
      path,
      treeIndex,
    });

  const handle =
    canDrag &&
    connectDragSource(
      <div className="stratus--category-node-icon">
        <DragIcon />
      </div>,
      {
        dropEffect: 'copy',
      },
    );

  return connectDragPreview(
    <div
      style={{
        opacity: isDraggedDescendant ? 0.5 : 1,
        paddingInlineStart: indent,
      }}
      className="stratus--category-node"
    >
      {handle}
      {showToggleButton && (
        <div
          aria-label={node.expanded ? 'Collapse' : 'Expand'}
          className="stratus--category-node-icon"
          onClick={toggleVisibility}
        >
          {node.expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </div>
      )}
      <Stack flex="1" justify="space-between">
        <Stack.Item fill>{nodeTitle}</Stack.Item>
        <div>
          {buttons?.map((btn, index) => (
            <div key={index}>{btn}</div>
          ))}
        </div>
      </Stack>
    </div>,
  );
}

export default CustomNode;
