import { useCallback, useEffect, useState } from 'react';
import { InterfaceInput, Input } from '@tiendanube/components';
import {
  DIGITS_LIMITS_CPF_CNPJ,
  formatCpfCnpj,
} from 'domains/Orders/Orders/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

interface IdentificationInputProps extends Partial<InterfaceInput> {
  label?: string;
  name?: string;
  value?: string;
  error?: string;
  onChange: ({ value, name }: { value: string; name: string }) => void;
}

function IdentificationBrInput({
  label,
  name = 'identification',
  value,
  error,
  onChange,
}: IdentificationInputProps) {
  const t = useTranslationOrders();
  const [formattedValue, setFormattedValue] = useState(value);

  const handleOnChange = useCallback(
    ({ value }) => {
      const valueCharacterCleanup = value.replace(/\D/g, '');
      const limitedFieldValue = valueCharacterCleanup.slice(
        0,
        DIGITS_LIMITS_CPF_CNPJ,
      );
      const formattedFieldtValue = formatCpfCnpj(valueCharacterCleanup);

      setFormattedValue(formattedFieldtValue);

      onChange({ value: limitedFieldValue, name });
    },
    [name, onChange],
  );

  useEffect(() => {
    if (!formattedValue && value) handleOnChange({ value });
  }, [formattedValue, handleOnChange, value]);

  return (
    <Input
      name={name}
      label={label ?? t('draftOrders.newDraftOrder.clientData.identification')}
      value={formattedValue || ''}
      helpText={t(error || '')}
      appearance={error ? 'validation_error' : undefined}
      onChange={handleOnChange}
      maxLength={DIGITS_LIMITS_CPF_CNPJ}
    />
  );
}

export default IdentificationBrInput;
