import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { StatusType } from 'commons/types';
import { MetafieldSelectedInterface } from 'domains/Metafields/types';
import { ProductsFiltersInterface } from '../../productsServices';
import {
  productVariantBulkAssignment,
  productVariantBulkAssignmentV2,
} from '../productMetafieldsService';

export interface InterfaceMetafieldBulkAssignmentsSchema {
  status: StatusType;
}

const initialState: InterfaceMetafieldBulkAssignmentsSchema = {
  status: 'idle',
};

export const productsBulkAssignmentMetafield = createAsyncThunk<
  void,
  {
    productsId: number[];
    filters: ProductsFiltersInterface;
    metafields: MetafieldSelectedInterface[];
  }
>(
  'products/metafields/bulk-assignment',
  async ({ productsId, filters, metafields }) => {
    await productVariantBulkAssignment({
      product_ids: productsId,
      filters,
      metafields,
    });
  },
);

export const productsBulkAssignmentMetafieldV2 = createAsyncThunk<
  void,
  {
    productsId: number[];
    filters: ProductsFiltersInterface;
    metafields: MetafieldSelectedInterface[];
  }
>(
  'products/metafields/bulk-assignment/v2',
  async ({ productsId, filters, metafields }) => {
    await productVariantBulkAssignmentV2({
      product_ids: productsId,
      filters,
      metafields,
    });
  },
);

const productMetafieldsSlice = createSlice({
  name: 'productMetafields',
  initialState,
  reducers: {
    clearStatus(state) {
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productsBulkAssignmentMetafield.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(productsBulkAssignmentMetafield.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(productsBulkAssignmentMetafield.fulfilled, (state) => {
        state.status = 'success';
      });

    builder
      .addCase(productsBulkAssignmentMetafieldV2.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(productsBulkAssignmentMetafieldV2.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(productsBulkAssignmentMetafieldV2.fulfilled, (state) => {
        state.status = 'success';
      });
  },
});

export const { reducer } = productMetafieldsSlice;
export const { clearStatus } = productMetafieldsSlice.actions;
