import { Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface PrintDocumentPageErrorProps {
  onRetryError: () => void;
}

function PrintDocumentPageError({
  onRetryError,
}: Readonly<PrintDocumentPageErrorProps>): JSX.Element {
  const t = useTranslationFulfillmentOrders('printManager.errorOnLoadPage');
  return (
    <EmptyMessage
      title={t('title')}
      icon={
        <ExclamationTriangleIcon
          size={32}
          style={{
            marginTop: '3rem',
          }}
        />
      }
      actions={
        <Link appearance="neutral" onClick={onRetryError}>
          {`${t('action')}`}
        </Link>
      }
    />
  );
}

export default PrintDocumentPageError;
