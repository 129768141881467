import { DataTable, Text } from '@tiendanube/components';
import FeatureValue from '../../../FeatureValue';

const { Cell, Row } = DataTable;

interface ComparisonTableDesktopRowProps {
  id: number;
  feature: string;
  firstValue: string | number | boolean;
  secondValue: string | number | boolean;
  thirdValue: string | number | boolean;
  fourthValue?: string | number | boolean;
  bold?: boolean;
}

function ComparisonTableDesktopRow({
  id,
  feature,
  firstValue,
  secondValue,
  thirdValue,
  fourthValue,
  bold,
}: ComparisonTableDesktopRowProps): JSX.Element {
  return (
    <Row id={`${id}`}>
      <Cell grow={0} basis={360}>
        <Text block>{feature}</Text>
      </Cell>
      <Cell>
        <FeatureValue value={firstValue} bold={bold} />
      </Cell>
      <Cell>
        <FeatureValue value={secondValue} bold={bold} />
      </Cell>
      <Cell>
        <FeatureValue value={thirdValue} bold={bold} />
      </Cell>
      {fourthValue !== undefined && (
        <Cell>
          <FeatureValue value={fourthValue} bold={bold} />
        </Cell>
      )}
    </Row>
  );
}

export default ComparisonTableDesktopRow;
