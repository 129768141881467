import { Text, InterfaceText } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';

interface DateDayMonthTextProps extends Omit<InterfaceText, 'children'> {
  date: string;
}

function DateDayMonthText({
  date,
  ...props
}: DateDayMonthTextProps): JSX.Element {
  return <Text {...props}>{dateFormat(date, Format.DAY_MONTH)}</Text>;
}

export default DateDayMonthText;
