import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDashboard } from '../../firstSaleSlice';

const getPaymentMessagesEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.paymentMessages,
);

const getPagseguro = createSimpleSelector(
  [getPaymentMessagesEntity],
  (paymentMessages) => paymentMessages.pagSeguro,
);

export const getPagseguroData = createSimpleSelector(
  [getPagseguro],
  (pagseguro) => pagseguro.data,
);

export const getPagseguroStatus = createSimpleSelector(
  [getPagseguro],
  (pagseguro) => pagseguro.status,
);
