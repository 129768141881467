import { useState } from 'react';
import { PaymentProviderDto } from '@tiendanube/common';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import { customPaymentsIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import {
  FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
  FEATURE_ONLY_NUVEM_PAGO,
} from 'App/features';
import { useNavegate } from 'App/hooks';
import { Responsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  PAYMENTS_ADD_CUSTOM_METHOD_TO_AGREE,
  PAYMENT_INSTALL_APP,
} from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { PAYMENTS_ROUTES } from 'domains/Payments/paymentsRoutes';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import Skeleton from './Skeleton';
import { PaymentProviderActionModal } from '../../components';
import { PaymentProviderActionType } from '../PaymentProviderActionModal';
import { PaymentProviderListDesktop } from '../PaymentProviderListDesktop';
import { PaymentProviderListMobile } from '../PaymentProviderListMobile';

interface PaymentProviderListProps {
  paymentProviders: PaymentProviderDto[];
}

function PaymentProviderList({
  paymentProviders,
}: Readonly<PaymentProviderListProps>) {
  const t = useTranslationPayments('customPayment');
  const [action, setAction] = useState<PaymentProviderActionType>();
  const [paymentProvider, setPaymentProvider] = useState<PaymentProviderDto>();
  const selectProviderWithUpsell = useUpsellFlow({
    featureKey: FEATURE_ONLY_NUVEM_PAGO,
    trackingSource: PAYMENT_INSTALL_APP,
    callback: (provider: PaymentProviderDto) => {
      dispatchPaymentAction(provider, 'enable');
    },
  });
  const { goTo } = useNavegate();
  const initUpsellFlow = useUpsellFlow({
    trackingSource: PAYMENTS_ADD_CUSTOM_METHOD_TO_AGREE,
    title: t('upsell.title'),
    featureKey: FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
    callback: () => {
      goTo(PAYMENTS_ROUTES.customPaymentNewRoute);
    },
  });

  const [
    showPaymentProviderActionModal,
    openPaymentProviderActionModal,
    closePaymentProviderActionModal,
  ] = useModal();

  const handleAction = (
    providerId: string,
    action: PaymentProviderActionType,
  ) => {
    const provider = paymentProviders.find(({ id }) => id === providerId);
    if (customPaymentsIds.includes(providerId) && action === 'enable') {
      if (provider?.isInstallable) goTo(PAYMENTS_ROUTES.customPaymentNewRoute);
      else initUpsellFlow();
    } else if (provider) {
      if (!provider.isInstallable && action === 'enable') {
        selectProviderWithUpsell(provider);
        return;
      }
      dispatchPaymentAction(provider, action);
    }
  };

  const dispatchPaymentAction = (
    provider: PaymentProviderDto,
    action: PaymentProviderActionType,
  ) => {
    if (action === 'enable') {
      if (provider.id === NativePaymentProviders.paypal) {
        return goTo(PAYMENTS_ROUTES.payPalSettings);
      }
      if (provider.id === NativePaymentProviders.payu) {
        return goTo(PAYMENTS_ROUTES.payUSettings);
      }
    }
    setPaymentProvider(provider);
    setAction(action);
    openPaymentProviderActionModal();
  };

  return (
    <>
      <Responsive
        mobile={
          <PaymentProviderListMobile
            onAction={handleAction}
            paymentProviders={paymentProviders}
          />
        }
        desktop={
          <PaymentProviderListDesktop
            onAction={handleAction}
            paymentProviders={paymentProviders}
          />
        }
      />
      {showPaymentProviderActionModal && paymentProvider && action && (
        <PaymentProviderActionModal
          provider={paymentProvider}
          action={action}
          onClose={closePaymentProviderActionModal}
        />
      )}
    </>
  );
}

PaymentProviderList.Skeleton = Skeleton;

export default PaymentProviderList;
