import { useCallback, useState } from 'react';
import { CategoriesOptionsResponseDto } from '@tiendanube/common';
import { useAsyncFunc } from 'commons/hooks';
import { getProductsOrder } from '../../themesService';

function useProductOrder() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [values, setValues] = useState<CategoriesOptionsResponseDto>();

  const [fetchProductOrder, isLoading, isError] = useAsyncFunc<
    boolean | void,
    Promise<CategoriesOptionsResponseDto>
  >(
    useCallback(async (setLoading = true) => {
      setLoading && setIsSuccess(false);
      setValues(await getProductsOrder());
      setLoading && setIsSuccess(true);
    }, []),
  );

  const categories = values?.categories;
  const options = values?.options;
  const status = isSuccess
    ? 'success'
    : isLoading
    ? 'loading'
    : isError
    ? 'error'
    : 'idle';

  return {
    categories,
    options,
    status,
    fetchProductOrder,
  };
}

export default useProductOrder;
