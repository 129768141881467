import { useEffect } from 'react';
import { Prompt } from 'react-router';
import { customPaymentsIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import {
  CalloutCard,
  ErrorState,
  InterfaceNameValue,
} from '@tiendanube/components';
import { RocketIcon } from '@tiendanube/icons';
import {
  FEATURE_MAX_CUSTOM_PAYMENTS,
  FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
} from 'App/features';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useForm, useModal, useToast } from 'commons/hooks';
import useTimeout from 'commons/hooks/useTimeOut';
import { InterfaceNameBooleanValue } from 'commons/types';
import goToAdmin from 'commons/utils/gotToAdmin';
import { PAYMENTS_ADD_CUSTOM_METHOD_TRANSFERENCIA_PIX_EFECTIVO } from 'config/upsellFlowSources';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import {
  CancelAndSaveButtonsWithAuth,
  ModalConfirmationWithAuth,
} from 'domains/AuthenticationFactor';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useKycStatus } from 'domains/NuvemPagoCommons/hooks/useKycStatus';
import { trackingSave } from 'domains/Payments/CustomPayment/tracking';
import { useInstalledPaymentProviders } from 'domains/Payments/PaymentProviders/hooks';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { CustomPaymentSkeleton, PixTransFerCash } from './components';
import Other from './components/Other/Other';
import { validationSchema } from './validationSchema';
import { useCustomPayment } from '../../hooks';

interface EditCustomPaymentPageProps {
  fromOldAdmin: boolean;
}

// eslint-disable-next-line max-statements
function EditCustomPaymentPage({
  fromOldAdmin,
}: EditCustomPaymentPageProps): JSX.Element {
  const t = useTranslationPayments();
  const { addToast } = useToast();
  const { goTo } = useNavegate();

  const { paymentProviders } = useInstalledPaymentProviders();
  const [
    showModalDisableConfirm,
    openModalDisableConfirm,
    closeModalDisableConfirm,
  ] = useModal();

  const { maxUse } = useStatusFeatureByKey(FEATURE_MAX_CUSTOM_PAYMENTS);
  const { isKycRejected } = useKycStatus();
  const { isAvailable: onlyNuvemPago } = useStatusFeatureByKey(
    FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
  );

  const onlyOtherIsAvailable = paymentProviders?.find((provider) =>
    customPaymentsIds.includes(provider.id),
  );
  const onlyOther = onlyNuvemPago
    ? isKycRejected || !!onlyOtherIsAvailable?.isInstallable
    : maxUse === 1;

  const {
    customPayment,
    statusLoad,
    statusUpdate,
    statusDisable,
    fetchCustomPayment,
    updateCustomPayment,
    disableCustomPayment,
  } = useCustomPayment();

  const {
    values,
    errors,
    isDirty,
    setFieldValue,
    handleOnSubmit,
    handleValidations,
  } = useForm({
    initialValues: customPayment,
    validationSchema,
    onSubmit: (values) => {
      updateCustomPayment(values);
    },
  });

  const initUpsellFlow = useUpsellFlow({
    title: t('customPayment.upsell.title'),
    trackingSource: PAYMENTS_ADD_CUSTOM_METHOD_TRANSFERENCIA_PIX_EFECTIVO,
    featureKey: FEATURE_MAX_CUSTOM_PAYMENTS,
    minValue: 2,
  });

  const handleChange = (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => {
    setFieldValue(group, { ...values[group], [data.name]: data.value });
  };

  const goToAdminPayments = goToAdmin('/payments');
  const goToAdminPaymentsDelay = useTimeout(goToAdminPayments, 1000);
  const goToSettingsPayments = () => goTo(PAYMENTS_BASE_ROUTE);
  const goToSettingsPaymentssDelay = useTimeout(goToSettingsPayments, 1000);
  const handleSuccess = () => {
    if (fromOldAdmin) {
      // Temporarily redirect to old admin until will be migrated all payments
      goToAdminPaymentsDelay();
    } else {
      goToSettingsPaymentssDelay();
    }
  };
  const handleOnClose = () => {
    // Temporarily redirect to old admin until will be migrated all payments
    fromOldAdmin ? goToAdminPayments() : goToSettingsPayments();
  };
  const headerNavigation = { onClick: handleOnClose };

  const canBeDisabled =
    !values.cash.active &&
    !values.other.active &&
    !values.wireTransfer.active &&
    (!values.pix?.active || false);

  const handleSaveUpdate = () => {
    trackingSave();
    handleOnSubmit();
  };

  const handleConfirmDisable = () => {
    closeModalDisableConfirm();
    disableCustomPayment(values);
  };

  const isUpdating = statusUpdate === 'saving';
  const isDisabling = statusDisable === 'saving';

  useEffect(fetchCustomPayment, [fetchCustomPayment]);

  useEffect(() => {
    if (statusUpdate === 'success') {
      addToast({
        label: t('customPayment.toast.updateSuccess'),
        appearance: 'success',
      });
      handleSuccess();
    }
    if (statusUpdate === 'error') {
      addToast({
        label: t('customPayment.toast.updateError'),
        appearance: 'danger',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate]);

  useEffect(() => {
    if (statusDisable === 'success') {
      addToast({
        label: t('customPayment.toast.disableSuccess'),
        appearance: 'success',
      });
      handleSuccess();
    }
    if (statusDisable === 'error') {
      addToast({
        label: t('customPayment.toast.disableError'),
        appearance: 'danger',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDisable]);

  const onChange = onlyNuvemPago ? initUpsellFlow : handleChange;

  const buttons = canBeDisabled ? (
    <CancelAndSaveButtons
      onSave={openModalDisableConfirm}
      onCancel={handleOnClose}
      isDisabled={isDisabling || (statusLoad === 'success' && !isDirty)}
      isLoading={isDisabling}
    />
  ) : (
    <CancelAndSaveButtonsWithAuth
      typeModal="centered"
      payload={values}
      onSave={handleSaveUpdate}
      preSubmitValidation={handleValidations}
      onCancel={handleOnClose}
      isDisabled={isUpdating || (statusLoad === 'success' && !isDirty)}
      isLoading={isUpdating}
    />
  );
  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={
        <HeaderContent
          title={t('customPayment.title')}
          subtitle={t('customPayment.subtitle')}
        />
      }
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      {statusLoad === 'loading' && <CustomPaymentSkeleton />}

      {statusLoad === 'error' && (
        <ErrorState
          title={t('customPayment.error.title')}
          action={{
            children: t('customPayment.error.action'),
            onClick: fetchCustomPayment,
          }}
        />
      )}

      {statusLoad === 'success' && onlyOther && (
        <>
          <Other
            disabled={false}
            values={values.other}
            errors={errors}
            onChange={handleChange}
          />
          {buttons}
          <CalloutCard
            appearance="primary"
            icon={RocketIcon}
            title={t('customPayment.callout.title')}
            subtitle={t('customPayment.callout.subtitle')}
            onClick={initUpsellFlow}
          />
          <PixTransFerCash
            disabled={onlyOther}
            values={values}
            errors={errors}
            onChange={onChange}
          />
        </>
      )}

      {statusLoad === 'success' && !onlyOther && (
        <>
          <PixTransFerCash
            disabled={onlyOther}
            values={values}
            errors={errors}
            onChange={onChange}
          />
          <Other
            disabled={false}
            values={values.other}
            errors={errors}
            onChange={onChange}
          />
          {buttons}
        </>
      )}

      {showModalDisableConfirm && (
        <ModalConfirmationWithAuth
          title={t('customPayment.modalDisable.title')}
          text={t('customPayment.modalDisable.text')}
          labelConfirm={t('customPayment.modalDisable.label')}
          labelCancel={t('customPayment.modalDisable.labelCancel')}
          onConfirm={handleConfirmDisable}
          onCancel={closeModalDisableConfirm}
        />
      )}
    </IonPageStratus>
  );
}
export default EditCustomPaymentPage;
