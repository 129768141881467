import {
  ActivityLogEntityType,
  ActivityType,
  SortOrder,
  SortOrderType,
} from '@tiendanube/common/src/domains/store-activity-logs/enums';
import { cirrusApi } from 'App/rtk';
import { StoreCouponActivityLogResponseDto } from 'domains/Marketing/Coupons/couponHistorySlice';

interface StoreActivityLogsApiParams {
  entityType?: ActivityLogEntityType;
  entityId?: string;
  type?: ActivityType;
  beginDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
  sortOrder?: SortOrderType;
}

export const STORE_ACTIVITY_LOGS_DEFAULT_SIZE = 20;

const storeActivityLogsApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityLogs: builder.query<
      StoreCouponActivityLogResponseDto,
      StoreActivityLogsApiParams
    >({
      query: (params) => {
        const { size, sortOrder, ...queryParams } = params;
        return {
          url: `v1/store-activity-logs`,
          method: 'GET',
          params: {
            ...queryParams,
            size: size ?? STORE_ACTIVITY_LOGS_DEFAULT_SIZE,
            sortOrder: sortOrder ?? SortOrder.DESC,
          },
        };
      },
    }),
  }),
});

export const { useGetActivityLogsQuery } = storeActivityLogsApi;
