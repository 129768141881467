import { Box, Icon, Link, Tag, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { FulfillmentOrdersResponseDto } from '@tiendanube/common';
import { useResponsive } from 'commons/components';
import { generateFulfillmentLabel } from 'domains/Orders/Orders/utils/OrderDetailsPage';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { appearances } from '../../../../constants';
import { useShippingCosts } from '../../../hooks';

interface ProductListRightHeaderProps {
  fulfillmentOrder?: FulfillmentOrdersResponseDto;
  hasDigitalProducts: boolean;
  isEditable: boolean;
  openProductSearcher: () => void;
}

export function ProductListRightHeader({
  fulfillmentOrder,
  hasDigitalProducts,
  isEditable,
  openProductSearcher,
}: Readonly<ProductListRightHeaderProps>) {
  const { isMobile } = useResponsive();
  const t = useTranslationOrders();
  const { isError: failedFulfillmentCotization } = useShippingCosts(
    fulfillmentOrder?.id,
  );

  const fulfillmentLabel =
    fulfillmentOrder &&
    generateFulfillmentLabel(
      fulfillmentOrder.status,
      fulfillmentOrder.shipping.type,
      t,
    );
  const LabelIcon = fulfillmentLabel?.icon;

  return (
    <Box display="flex" justifyContent="space-between">
      {hasDigitalProducts && (
        <Tag appearance="primary">
          {t('editOrders.productList.digitalProducts')}
        </Tag>
      )}
      {isEditable ? (
        <Box marginLeft="4" display="flex" flexDirection="row">
          {failedFulfillmentCotization && (
            <Box paddingRight="4">
              <Tag appearance="danger">
                <Icon
                  color="danger-textLow"
                  source={<ExclamationTriangleIcon size="small" />}
                />
                {!isMobile && (
                  <Text color="danger-textLow" fontSize="base">
                    {t('editOrders.productList.failedFulfillmentCotization')}
                  </Text>
                )}
              </Tag>
            </Box>
          )}
          <Link textDecoration="none" onClick={openProductSearcher}>
            <Icon color="primary-interactive" source={<PlusCircleIcon />} />
            <Text color="primary-interactive" fontSize="base">
              {t(
                `editOrders.productList.${
                  isMobile ? 'addProductMobile' : 'addProduct'
                }`,
              )}
            </Text>
          </Link>
        </Box>
      ) : (
        <Box marginLeft="2">
          <Tag
            appearance={appearances[fulfillmentLabel?.appearance ?? 'neutral']}
          >
            {LabelIcon && <LabelIcon />}
            {fulfillmentLabel?.label}
          </Tag>
        </Box>
      )}
    </Box>
  );
}
