import { Alert, Button, Text } from '@nimbus-ds/components';
import { Link } from 'react-router-dom';
import { useDismissibleComponent } from 'commons/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { paymentsRoutes } from 'domains/Payments';
import { useNuvemPagoIsActive } from 'domains/Payments/PaymentProviders/hooks';
import { useTransactionFeeToPay } from '../../hooks';

function NuvemPagoFreeTransactionsAlert() {
  const t = useTranslationBilling(
    'transactionFeesPage.nuvemPagoFreeTransactionsAlert',
  );

  const { shouldShow, dismiss } = useDismissibleComponent(
    'freeTransactionFeesAlertDismissed',
  );
  const { transactionFeeToPay } = useTransactionFeeToPay(false);
  const { nuvemPagoIsActive, isSuccess } = useNuvemPagoIsActive();

  if (
    !isSuccess ||
    nuvemPagoIsActive ||
    !transactionFeeToPay?.requiresFreeTransactionFeeInfoText ||
    !shouldShow
  )
    return null;

  return (
    <Alert title={t('title')} onRemove={dismiss} appearance="primary">
      <Text>{t('message')}</Text>
      <Button
        as={Link}
        appearance="primary"
        to={paymentsRoutes.PAYMENTS_BASE_ROUTE}
      >
        {t('button')}
      </Button>
    </Alert>
  );
}

export default NuvemPagoFreeTransactionsAlert;
