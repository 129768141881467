import { Box, Card, Text } from '@nimbus-ds/components';
import { EditIcon, InfiniteIcon } from '@nimbus-ds/icons';
import { InventoryLevelsRequestDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { ActionIconButton } from 'commons/components';
import { Stock } from 'domains/Catalog/Inventory/types';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface SelectedLocationsProps {
  initialinventory: InventoryLevelsRequestDto[];
  onOpenModal: (locationId: string, stock: Stock) => void;
  getInventoryStock: (locationId: string) => Stock;
  getLocationName: (locationId: string) => string;
}

function SelectedLocations({
  initialinventory,
  onOpenModal,
  getInventoryStock,
  getLocationName,
}: SelectedLocationsProps) {
  const t = useTranslationCatalog();

  if (!initialinventory.length) return null;

  const stockText = (stock: Stock) =>
    stock === null ? (
      <Box display="flex" alignItems="center" gap="1">
        <InfiniteIcon />
        <Text>{t('products.detail.variantStockInfinite')}</Text>
      </Box>
    ) : (
      <Text>{t('products.detail.available', { count: Number(stock) })}</Text>
    );

  return (
    <Card.Body>
      <Box paddingBottom="4">
        <Text fontWeight="bold">
          {t('editInventory.stockDistributionCard.selectedCentersTitle')}
        </Text>
      </Box>
      <DataList>
        {initialinventory.map(({ locationId }) => {
          const handleClick = () =>
            onOpenModal(locationId, getInventoryStock(locationId));

          return (
            <DataList.Row key={locationId} id={locationId}>
              <DataList.Cell width="fill">
                <Box
                  flex="1"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Text>{getLocationName(locationId)}</Text>
                  <Box display="flex" alignItems="center" gap="2">
                    {stockText(getInventoryStock(locationId))}
                    <ActionIconButton
                      content={t(
                        'editInventory.stockDistributionCard.editStock',
                      )}
                      source={<EditIcon />}
                      onClick={handleClick}
                    />
                  </Box>
                </Box>
              </DataList.Cell>
            </DataList.Row>
          );
        })}
      </DataList>
    </Card.Body>
  );
}

export default SelectedLocations;
