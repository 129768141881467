import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getMetafieldsCustomerData,
  getMetafieldsCustomerStatus,
} from 'domains/Metafields/metafieldsSlice';
import { fetchMetafieldsCustomerAction } from 'domains/Metafields/metafieldsSlice/metafieldsSlice';
import useCustomerMetafieldsAccess from '../useCustomerMetafieldsAccess';

export default function useGetCustomerMetafields() {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsCustomerData);
  const { isLoading, isSuccess, isError, isIdle } = useSelector(
    getMetafieldsCustomerStatus,
  );
  const { isAvailable } = useCustomerMetafieldsAccess();

  const fetchMetafields = useCallback(
    () => dispatch(fetchMetafieldsCustomerAction()),
    [dispatch],
  );

  const hasInternals =
    !!metafields?.internals && Object.keys(metafields?.internals).length > 0;
  const hasExternals =
    !!metafields?.fromApi && Object.keys(metafields?.fromApi).length > 0;

  const status = { isLoading, isError, isSuccess, isIdle };

  return {
    metafields: hasInternals ? metafields.internals : null,
    apiMetafields: hasExternals ? metafields.fromApi : null,
    status,
    isIdle,
    fetchMetafields,
    hasExternals,
    hasInternals,
    isAvailable,
  };
}
