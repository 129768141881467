import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { GenerateCategorySeoRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { generateCategorySeo } from '../../categoriesSlice';

function useGenerateCategorySeo() {
  const dispatch = useAppDispatch();

  const generateSeo = useCallback(
    async (requestDto: GenerateCategorySeoRequestDto) => {
      const result = await dispatch(generateCategorySeo(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { generateSeo };
}

export default useGenerateCategorySeo;
