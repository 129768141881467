import { useRef, useEffect } from 'react';

const ELEMENTS_TO_FIND = ['INPUT', 'TEXTAREA'];
const ENABLED_AND_VISILE = ':not([disabled]):not([hidden])';

const useAutoFocus = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const inputElement = ref.current?.querySelector<HTMLInputElement>(
      ELEMENTS_TO_FIND.join(`${ENABLED_AND_VISILE}, `) + ENABLED_AND_VISILE,
    );
    setTimeout(() => {
      inputElement?.focus();
    });
  }, []);
  return { ref };
};

export default useAutoFocus;
