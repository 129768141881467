import { Box, Card, Title } from '@nimbus-ds/components';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import { RowData } from './components';
import { useStoreStatusTitle } from '../../hooks';

function PlansAndAppsSkeleton(): JSX.Element {
  const title = useStoreStatusTitle();

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{title}</Title>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          {getMockArrayForSkeleton(3).map((key) => (
            <RowData.Skeleton key={key} />
          ))}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PlansAndAppsSkeleton;
