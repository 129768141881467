import { Modal, Text, Select, Button, Box } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import useAppUninstallReasons from '../../hooks/useAppUninstallReasons';

const getReasonTranslationKey = (reason: string) => {
  const formattedReason = reason
    .toLowerCase()
    .replaceAll(',', '')
    .replaceAll('/', '-')
    .replaceAll(':', '')
    .replaceAll(' ', '-');
  return `uninstallReasons.${formattedReason}`;
};

function UninstallAppModal(): JSX.Element {
  const {
    reasonDetail,
    reasonId,
    onChangeReasonDetail,
    onChangeReasonId,
    handleSubmit,
    uninstallReasons,
    shouldOpenReasonDetailsInput,
    onClose,
    shouldOpenModal,
    isLoadingUninstallApp,
  } = useAppUninstallReasons();

  const { t } = useTranslationPartnerApps('appsListPage.uninstallAppModal');

  return (
    <Modal open={shouldOpenModal} onDismiss={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Body>
        <Box
          display="flex"
          gap="4"
          flexDirection="column"
          as="form"
          onSubmit={handleSubmit}
          id="uninstall-app-form"
        >
          <Text>{t('description')}</Text>

          <FormField.Select
            data-testid="uninstall-app-modal-select"
            id="uninstall-app-modal-select"
            name="select-reason"
            required
            onChange={onChangeReasonId}
            value={reasonId}
          >
            {uninstallReasons.map((reason) => (
              <Select.Option
                data-testid="uninstall-app-modal-select-option"
                label={t(getReasonTranslationKey(reason.name))}
                value={reason.id.toString()}
                key={reason.id}
              />
            ))}
          </FormField.Select>
          {shouldOpenReasonDetailsInput && (
            <FormField.Textarea
              id="message"
              placeholder={t('reasonDetailsInputPlaceholder')}
              value={reasonDetail}
              onChange={onChangeReasonDetail}
            />
          )}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>{t('cancelButton')}</Button>
        <Button
          appearance="primary"
          disabled={isLoadingUninstallApp}
          type="submit"
          form="uninstall-app-form"
        >
          {t('confirmButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UninstallAppModal;
