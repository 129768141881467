import { Stack, Text } from '@tiendanube/components';
import { InvoiceHistoryDataTable } from './components';
import TaxCondition from '../TaxCondition';

function InvoiceHistoryMobileSkeleton(): JSX.Element {
  return (
    <Stack column spacing="loose" align="stretch">
      <TaxCondition.Skeleton />
      <Stack column align="stretch" spacing="base">
        <Text.Skeleton />
        <InvoiceHistoryDataTable.Skeleton />
      </Stack>
    </Stack>
  );
}
export default InvoiceHistoryMobileSkeleton;
