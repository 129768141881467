import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import { Format, dateFormat } from 'commons/utils/date';
import {
  cleanExportOrders,
  exportOrders as exportOrdersAction,
  exportOrdersDownloadStatus,
} from 'domains/Orders/Orders/ordersSlice';
import { defaultFilters } from '../useOrdersExport';

function useDownloadOrdersCsv() {
  const dispatch = useAppDispatch();
  const { filters } = useListFilters('orders/export', defaultFilters);
  const downloadStatus = useSelector(exportOrdersDownloadStatus);
  const { setJob, jobStatus, csvDownloadLinkUrl, cleanJob } =
    useBackgroundJobs('ordersCsvDownload');

  const exportOrders = useCallback(async () => {
    const dateFrom = filters.dateFrom;
    const dateTo = filters.dateTo;
    const formattedDateFrom =
      dateFrom === '' ? dateFrom : dateFormat(dateFrom, Format.DD_MM_YYYY);
    const formattedDateTo =
      dateTo === '' ? dateTo : dateFormat(dateTo, Format.DD_MM_YYYY);

    const { token } = unwrapResult(
      await dispatch(
        exportOrdersAction({
          ...filters,
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
        }),
      ),
    );
    dispatch(cleanExportOrders());
    setJob(String(token));
  }, [dispatch, filters, setJob]);

  const isExporting = downloadStatus === 'loading' || jobStatus === 'pending';

  const getJobCsvDownloadStatus = () => {
    const isFailed = downloadStatus === 'error' || jobStatus === 'failed';

    const isSuccess = jobStatus === 'ready' || jobStatus === 'downloaded';

    if (isExporting) return 'loading';
    else if (isFailed) return 'error';
    else if (isSuccess) return 'success';
    else return 'idle';
  };

  const resetExportingStatus = useCallback(() => {
    dispatch(cleanExportOrders());
    cleanJob();
  }, [cleanJob, dispatch]);

  return {
    isExporting,
    jobCsvDownloadStatus: getJobCsvDownloadStatus(),
    csvDownloadLinkUrl,
    resetExportingStatus,
    exportOrders,
  };
}

export default useDownloadOrdersCsv;
