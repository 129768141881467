import { ConceptCode } from '@tiendanube/common';
import {
  PaymentStatusAlertTransfer,
  PaymentStatusAlertCreditCard,
  PaymentStatusAlertMercadoPago,
  PaymentStatusAlertPending,
} from './components';
import { usePayment } from './hooks';

export interface PaymentStatusAlertProps {
  concept: ConceptCode;
  externalReference?: string | null;
  isExternalContext?: boolean;
  chargeId?: string;
}

// TODO we could extract any logic specific to concept 'plan-cost' to another component that uses this one
function PaymentStatusAlert(props: PaymentStatusAlertProps) {
  const { concept, externalReference, isExternalContext, chargeId } = props;
  const paymentData = usePayment(concept, externalReference, chargeId);

  if (!paymentData) return null;

  const {
    hasPayment,
    paymentStatus,
    payment,
    isSuccess,
    lastPaymentMethodUsed,
  } = paymentData;

  if (!isSuccess) return null;

  if (paymentStatus === 'PENDING') {
    return <PaymentStatusAlertPending {...props} />;
  }

  if (!hasPayment || !payment) return null;

  if (lastPaymentMethodUsed === 'transfer')
    return <PaymentStatusAlertTransfer {...props} />;

  if (isExternalContext || lastPaymentMethodUsed === 'cc')
    return <PaymentStatusAlertCreditCard {...props} />;

  return <PaymentStatusAlertMercadoPago {...props} />;
}

export default PaymentStatusAlert;
