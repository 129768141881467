import {
  CouponFilterDiscountType,
  HasUseLimitType,
  HasMinValueType,
  IncludeShippingType,
  SortByType,
  StatusType,
  TermType,
} from '@tiendanube/common';

export const DEFAULT_SORT_BY = 'alpha-ascending';

export type CouponFiltersType = {
  page: number;
  q: string;
  sortBy?: SortByType;
  discountType: CouponFilterDiscountType;
  includeShipping: IncludeShippingType;
  termType: TermType;
  status: StatusType;
  createdAtMin: string;
  createdAtMax: string;
  hasMinValue: HasMinValueType;
  hasUseLimit: HasUseLimitType;
};

export type CouponFiltersKeys = keyof CouponFiltersType;

export const defaultFilters: CouponFiltersType = {
  page: 1,
  q: '',
  sortBy: DEFAULT_SORT_BY,
  discountType: '',
  includeShipping: '',
  termType: '',
  status: '',
  createdAtMin: '',
  createdAtMax: '',
  hasMinValue: '',
  hasUseLimit: '',
};
