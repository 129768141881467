import { useState } from 'react';
import { Card, Checkbox, Spinner, Text, Title } from '@nimbus-ds/components';
import {
  OcaLabelCostPreviewResponseDto,
  OcaLabelSettingBoxOptionsDto,
  OcaCreateLabelRequestDto,
  OrderDetailsForOcaLabelResponseDto,
} from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import OcaLabelSettingCardSkeleton from './Skeleton';
import { DIMENSIONS_TYPES, SHIPPING_TYPE } from '../../constants/constants';
import useOcaCardsInfo from '../../hooks/useOcaCardsInfo';
import useOcaLabelsCostsPreviews from '../../hooks/useOcaLabelsCostsPreviews';
import { validateDimensions } from '../../utils/utils';
import OcaBoxOptionsForm from '../OcaBoxOptionsForm';
import OcaDispatchDataForm from '../OcaDispatchDataForm';

interface OcaLabelSettingCardProps {
  order: OrderDetailsForOcaLabelResponseDto;
  label: OcaCreateLabelRequestDto;
  costPreview?: OcaLabelCostPreviewResponseDto;
  selected: boolean;
  isLoadingCostPreviewByHandle: boolean;
  isUnique: boolean;
  onChangeLabel: (label: OcaCreateLabelRequestDto) => void;
  onSelectLabel: (externalId: string) => void;
  updateCostsPreviews: (
    newCostsPreviews: OcaLabelCostPreviewResponseDto[],
  ) => void;
}

function OcaLabelSettingCard({
  order,
  label,
  costPreview,
  selected,
  isLoadingCostPreviewByHandle,
  isUnique,
  onChangeLabel,
  onSelectLabel,
  updateCostsPreviews,
}: Readonly<OcaLabelSettingCardProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint.card');
  const { documentSettings } = useOcaCardsInfo();
  const { generateCostsPreviews } = useOcaLabelsCostsPreviews();
  const [isLoadingCostPreview, setIsLoadingCostPreview] = useState(
    isLoadingCostPreviewByHandle,
  );
  const { destination, dimensions, externalId, number, type } = order;
  const address =
    type === SHIPPING_TYPE.ship
      ? `${destination.address} ${destination.number}, ${destination.city} - ${destination.province}`
      : destination.address;

  const costPreviewFormatted = costPreview
    ? Intl.NumberFormat('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(costPreview.cost.value)
    : '--,--';

  const handleOnChangeLabel = async (
    name: string,
    value: OcaLabelSettingBoxOptionsDto | string,
  ) => {
    const newLabel: OcaCreateLabelRequestDto = DIMENSIONS_TYPES.includes(name)
      ? {
          ...label,
          boxOption: {
            ...label.boxOption,
            dimensions: {
              ...label.boxOption.dimensions,
              [name]: value,
            },
          },
        }
      : {
          ...label,
          [name]: value,
        };

    onChangeLabel(newLabel);
    if (validateDimensions(newLabel.boxOption.dimensions) && !!value) {
      setIsLoadingCostPreview(true);
      await generateCostsPreviews([newLabel]).then((costsPreviews) => {
        updateCostsPreviews(costsPreviews);
        setIsLoadingCostPreview(false);
      });
    }
  };

  return (
    <div
      style={{
        width: '49%',
      }}
    >
      <Card>
        <Card.Header>
          <Stack spacing="tight">
            {!isUnique && (
              <Checkbox
                key={externalId}
                name={externalId}
                checked={selected}
                onChange={() => onSelectLabel(externalId)}
              />
            )}
            <Title as="h4">
              {t('title')} #{number}
            </Title>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Stack column align="stretch">
            <Stack column spacing="tight" align="baseline">
              <Text>
                <b>{t('weight')}</b> {dimensions.weight}kg
              </Text>
              <Text>
                <b>{t('destination')}</b> {address}
              </Text>
            </Stack>
            <hr />
            <OcaDispatchDataForm
              documentSettings={documentSettings}
              label={label}
              onChange={handleOnChangeLabel}
            />
            <hr />
            <OcaBoxOptionsForm
              boxOptions={documentSettings.boxOptions}
              boxOption={label.boxOption}
              onChange={handleOnChangeLabel}
            />
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Text>
            {t('cost')}{' '}
            {!isLoadingCostPreview && !isLoadingCostPreviewByHandle && (
              <b>${costPreviewFormatted}</b>
            )}
            {(isLoadingCostPreview || isLoadingCostPreviewByHandle) && (
              <Spinner size="small" />
            )}
          </Text>
        </Card.Footer>
      </Card>
    </div>
  );
}

OcaLabelSettingCard.Skeleton = OcaLabelSettingCardSkeleton;
export default OcaLabelSettingCard;
