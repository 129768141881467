import {
  CurrencyResponseDto,
  EnabledCountriesListResponseDto,
} from '@tiendanube/common';

export const enabledCountries = (data?: EnabledCountriesListResponseDto) =>
  data?.results.map((item) => ({
    id: item.id,
    countryId: item.countryId,
    countryCode: item.countryCode,
    countryName: item.countryName,
  }));
export const mainCountryData = (data?: EnabledCountriesListResponseDto) => {
  const mainCountry = data?.results?.find((country) => country.isMain);
  return { mainCountryId: mainCountry ? mainCountry.id : '' };
};

export const mainCurrencyData = (data?: CurrencyResponseDto[]) => {
  const mainCurrency = data?.find((currency) => currency.isMain);
  return { mainCurrencyId: mainCurrency ? mainCurrency.currencyId : '' };
};

export const mainEnabledCountriesIds = (
  data?: EnabledCountriesListResponseDto,
) => {
  const mainCountry = data?.results?.find((country) => country.isMain);
  return {
    countryId: mainCountry ? mainCountry.countryId : '',
    currencyId: mainCountry ? mainCountry.currencyId : '',
    languageId: mainCountry ? mainCountry.languageId : '',
  };
};

export const enabledCountriesId = (data?: EnabledCountriesListResponseDto) => {
  const countriesId = data?.results?.map((country) => country.countryId);
  return { countriesId: countriesId ?? [] };
};

export const currencyExchangeRate = (data?: CurrencyResponseDto[]) =>
  data?.filter((currency) => !currency.isMain);

export const mainCurrencyCodeData = (data?: CurrencyResponseDto[]) => {
  const mainCurrency = data?.find((currency) => currency.isMain);
  return mainCurrency ? mainCurrency.currencyCode : '';
};
