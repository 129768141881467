import { useTranslation } from 'react-i18next';
import InteractiveListRightCheckbox from '../InteractiveListRightCheckbox';

interface InteractiveListRightCheckAllProps {
  selectedCount: number;
  checked: boolean | 'indeterminate';
  gender: 'male' | 'female';
  onChange: () => void;
}

function InteractiveListRightCheckAll({
  selectedCount,
  checked,
  gender,
  onChange,
}: InteractiveListRightCheckAllProps): JSX.Element {
  const { t } = useTranslation('common', { keyPrefix: 'selectAllChecks' });
  const title =
    selectedCount === 0
      ? t(`all.${gender}`)
      : t(`some.${gender}`, { count: selectedCount });
  const checkedOrIndeterminate =
    checked === 'indeterminate'
      ? { checked: false, indeterminate: true }
      : { checked };
  return (
    <InteractiveListRightCheckbox
      title={title}
      grayed
      checkbox={{
        name: 'selectAll',
        ...checkedOrIndeterminate,
        onChange,
      }}
    />
  );
}

export default InteractiveListRightCheckAll;
