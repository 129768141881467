import { useMemo } from 'react';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { FEATURE_METAFIELDS_ORDERS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { ORDERS_METAFIELD_FILTERS_CALLOUT } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { MetafieldsFilters } from 'domains/Metafields/components';
import { useGetMetafieldsOrders } from 'domains/Metafields/hooks';
import {
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
} from 'domains/Metafields/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface MetafieldsOrdersFiltersProps {
  selecteds: MetafieldSelectedInterface[];
  onChange: OnChangeMetafieldType;
}

function MetafieldsOrdersFilters({
  selecteds,
  onChange,
}: MetafieldsOrdersFiltersProps): JSX.Element | null {
  const { metafields, status, fetchMetafields } = useGetMetafieldsOrders();
  const t = useTranslationOrders();
  const { goTo } = useNavegate();

  const onCickMetafieldCallout = useUpsellFlow({
    title: t('filter.upsell.metafieldFilters'),
    featureKey: FEATURE_METAFIELDS_ORDERS,
    trackingSource: ORDERS_METAFIELD_FILTERS_CALLOUT,
    callback: () => {
      goTo('/settings/metafields');
    },
    asAside: true,
  });

  const metafieldsArray = useMemo(
    () =>
      Object.entries({
        ...metafields.internals,
        ...metafields.fromApi,
      })
        .map(([id, metafield]) => ({ ...metafield, id }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [metafields],
  );

  return metafieldsArray.length > 0 ? (
    <MetafieldsFilters
      metafields={metafieldsArray}
      status={status}
      selecteds={selecteds}
      onChange={onChange}
      onFetch={fetchMetafields}
    />
  ) : (
    <CalloutCard
      appearance="primary"
      icon={PlusCircleIcon}
      title={t('filter.metafieldFiltersCalloutTitle')}
      subtitle={t('filter.metafieldFiltersCalloutSubtitle')}
      onClick={onCickMetafieldCallout}
    />
  );
}

export default MetafieldsOrdersFilters;
