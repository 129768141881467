import { Card, Box, Skeleton } from '@nimbus-ds/components';

function FormPageSkeleton() {
  return (
    <>
      <Card>
        <Card.Body>
          <Box display="flex" flexDirection="column" gap="8">
            <Box display="flex" flexDirection="column" gap="2">
              <Skeleton height="1rem" width="100px" />
              <Skeleton height="2rem" width="100%" borderRadius="6px" />
            </Box>
            <Box display="flex" flexDirection="column" gap="2">
              <Skeleton height="1rem" width="100px" />
              <Skeleton height="15rem" width="100%" borderRadius="6px" />
            </Box>
            <Box display="flex" flexDirection="row" gap="2">
              <Skeleton height="1rem" width="16px" />
              <Skeleton height="1rem" width="200px" />
            </Box>
          </Box>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Box
            display="flex"
            flexDirection="row"
            gap="2"
            justifyContent="space-between"
          >
            <Skeleton height="1.5rem" width="200px" />
            <Skeleton height="2rem" width="32px" />
          </Box>
        </Card.Header>
      </Card>
    </>
  );
}

export default FormPageSkeleton;
