import {
  Input,
  InterfaceNameValue,
  Select,
  Stack,
} from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { SelectedProductInterface } from 'domains/Catalog/Products/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { SelectedVariantInterface } from '../../OrderSummary';

interface ProductAttributesProps {
  product: SelectedProductInterface;
  variant: SelectedVariantInterface;
  errors?: Record<string, string>;
  onChangeAttribute: (
    product: SelectedProductInterface,
    variant: SelectedVariantInterface,
    event: InterfaceNameValue,
  ) => void;
  onChangeQuantity: (
    variant: SelectedVariantInterface,
    e: InterfaceNameValue,
  ) => void;
}

function ProductAttributes({
  product,
  variant,
  errors,
  onChangeAttribute,
  onChangeQuantity,
}: ProductAttributesProps): JSX.Element {
  const t = useTranslationOrders();

  const isDuplicated = !!errors?.[`products[${variant.variantId}].duplicated`];
  const hasQuantityError =
    !isDuplicated && !!errors?.[`products[${variant.variantId}].quantity`];

  return (
    <Stack wrap column align="stretch" spacing="base">
      {product.attributes.map((attribute) => (
        <Select
          key={attribute.name}
          name={attribute.name}
          label={attribute.name}
          placeholder={t(
            'draftOrders.newDraftOrder.orderSummary.placeholderSelect',
          )}
          options={attribute.values.map((variant) => ({
            label: variant,
            value: variant,
          }))}
          value={variant.values?.[attribute.name]}
          onChange={(e) => onChangeAttribute(product, variant, e)}
        />
      ))}
      <Stack align="flex-end">
        <Stack.Item fill>
          <Input
            name={`${product.id}-quantity`}
            label={t('draftOrders.newDraftOrder.orderSummary.quantity')}
            type="number"
            value={variant.quantity?.toString()}
            appearance={hasQuantityError ? 'validation_error' : undefined}
            helpText={t(
              errors?.[`products[${variant.variantId}].quantity`] || '',
            )}
            onChange={(e) => onChangeQuantity(variant, e)}
          />
        </Stack.Item>
        <CurrencyPrice price={variant.price ? variant.price : 0} preFix="x" />
      </Stack>
    </Stack>
  );
}

export default ProductAttributes;
