import { DataList } from '@tiendanube/components';
import AbandonedCartsListRowMobileSkeleton from './AbandonedCartsListRowMobileSkeleton';

function AbandonedCartsListMobileSkeleton() {
  return (
    <DataList>
      <AbandonedCartsListRowMobileSkeleton />
      <AbandonedCartsListRowMobileSkeleton />
      <AbandonedCartsListRowMobileSkeleton />
      <AbandonedCartsListRowMobileSkeleton />
      <AbandonedCartsListRowMobileSkeleton />
      <AbandonedCartsListRowMobileSkeleton />
    </DataList>
  );
}

export default AbandonedCartsListMobileSkeleton;
