import { ReactNode, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useNavegate, useSubPathname } from 'App/hooks';
import goToAuthAdminRedirect from 'commons/utils/goToAuthAdminRedirect';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useIsLogged, useIsMobileDevice } from 'domains/Auth/hooks';
import { isDomainWebAdmin, getIsPageWithCodeParam } from './utils';

interface InterfacePrivateRoutesProps {
  children?: ReactNode;
  path: string;
}

function PrivateRoutes({
  children,
  path,
}: InterfacePrivateRoutesProps): JSX.Element | null {
  const { isLogin, isInit, isAuthCodeError } = useIsLogged();
  const { pathname, removeSearchParams, hasSearchParam } = useNavegate();
  const { saveTemporal } = useSubPathname();
  const isMobile = useIsMobileDevice();

  useEffect(() => {
    if (
      hasSearchParam('code') &&
      isLogin &&
      isInit &&
      !getIsPageWithCodeParam(pathname)
    ) {
      removeSearchParams(['code']);
    }
  }, [hasSearchParam, isInit, isLogin, pathname, removeSearchParams]);

  if (!isInit) {
    return null;
  }

  //if the authorization code cannot be resolved it is redirected to the admin root
  if (isAuthCodeError) {
    goToAdmin('/')();
  }

  if (isInit && !isLogin && isDomainWebAdmin(document.location.host)) {
    //Before exiting the application we temporarily persist the subpathname (#hash)
    saveTemporal();
    goToAuthAdminRedirect();

    return null;
  }

  //redirect to old admin when the user is not logged in and is not on a mobile device
  if (!isMobile && !isLogin) {
    goToAdmin('/')();
    return null;
  }

  return (
    <Route path={path}>
      {isLogin ? children : <Redirect to="/auth/login" />}
    </Route>
  );
}

export default PrivateRoutes;
