import { EcommerceNaPraticaResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { DashboardEndpoints, ECOMMERCE_NA_PRATICA_URL } from './config';

export const dashboardApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [DashboardEndpoints.getEcommerceNaPratica]: builder.query<
      EcommerceNaPraticaResponseDto,
      void
    >({
      query: () => ({
        method: 'GET',
        url: ECOMMERCE_NA_PRATICA_URL,
      }),
    }),
  }),
});

export const { useGetEcommerceNaPraticaQuery } = dashboardApi;
