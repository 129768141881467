import { PropsWithChildren } from 'react';
import { Icon, Link, LinkProperties } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import './ExternalLink.scss';

export type ExternalLinkProps = PropsWithChildren<
  { href?: string; onClick?: () => void } & Pick<
    LinkProperties,
    'appearance' | 'textDecoration' | 'fontSize'
  >
>;

const noop = () => void 0;

function ExternalLink({
  href,
  children,
  appearance = 'neutral',
  textDecoration,
  onClick = noop,
  fontSize = 'base',
}: ExternalLinkProps) {
  return (
    <div className="stratus--external-link">
      <Link
        textDecoration={textDecoration}
        as="a"
        href={href}
        target="_blank"
        appearance={appearance}
        onClick={onClick}
        fontSize={fontSize}
      >
        {children}
        <Icon color="currentColor" source={<ExternalLinkIcon />} />
      </Link>
    </div>
  );
}

export default ExternalLink;
