import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';

function DeliveryInfoCardSkeleton(): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('draftOrders.deliveryInfoCard.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text.Skeleton width="large" />
        </Stack.Item>
        <Stack.Item>
          <Stack column spacing="none" align="flex-start">
            <Text.Skeleton size="caption" />
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCardSkeleton;
