import { TFunction } from 'i18next';
import { TinyMCE } from 'tinymce';
import axios from 'App/axios';

/* We should migrate to TinyMCE v6 but it has not support to paste from Word and preserve styles */

const IMAGE_MAX_SIZE_BYTES = 10 * 1024 * 1024;

type InitOptions = Parameters<TinyMCE['init']>[0];
export type EditorOptions = InitOptions & {
  selector?: undefined;
  target?: undefined;
};

/* Image Uploader TinyMCE v6 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const imageUploadHandlerV6 = (t: TFunction) => async (blobInfo) =>
  new Promise<string>((resolve, reject) => {
    const formData = new FormData();

    const filenameSplittedByDot = blobInfo.filename().split('.');
    const extension = filenameSplittedByDot.at(-1).toLowerCase();
    const filename = `${filenameSplittedByDot
      .slice(0, -1)
      .join('.')}.${extension}`;

    const imageFile = new File([blobInfo.blob()], filename);
    formData.append('file', imageFile, filename);

    if (imageFile.size >= IMAGE_MAX_SIZE_BYTES) {
      reject(t('products.detail.errorImageDescriptionMaxLength'));
      return;
    }

    axios
      .post('v1/products/description/images', formData)
      .then((response) => resolve(response.data.publicUrl))
      .catch(() =>
        reject({
          message: t('products.detail.errorUploadDescriptionImage'),
          remove: true,
        }),
      );
  });

/* Image Uploader TinyMCE v5 */
const imageUploadHandler =
  (t: TFunction) => async (blobInfo, success, failure) => {
    const formData = new FormData();

    const filenameSplittedByDot = blobInfo.filename().split('.');
    const extension = filenameSplittedByDot.at(-1).toLowerCase();
    const filename = `${filenameSplittedByDot
      .slice(0, -1)
      .join('.')}.${extension}`;

    const imageFile = new File([blobInfo.blob()], filename);
    formData.append('file', imageFile, filename);

    if (imageFile.size >= IMAGE_MAX_SIZE_BYTES) {
      failure(t('products.detail.errorImageDescriptionMaxLength'));
      return;
    }

    try {
      const response = await axios.post(
        'v1/products/description/images',
        formData,
      );
      success(response.data.publicUrl);
    } catch (e) {
      failure(t('products.detail.errorUploadDescriptionImage'));
    }
  };

const BASE_URL = import.meta.env.BASE_URL;
const ASSETS_FONTS_SRC = '/assets/fonts';
const TINYMCE_FONTS =
  !BASE_URL || BASE_URL === '/'
    ? ASSETS_FONTS_SRC
    : `${BASE_URL}/${ASSETS_FONTS_SRC}`;

/* TinyMCE v6 rename formatselect toolbar item to blocks */
export const getConfig = (t: TFunction): EditorOptions => {
  const toolbarOptions = {
    toolbar:
      'formatselect | bold italic forecolor | undo redo | removeformat | bullist numlist | alignment | link image | table  | customFuenteHTML | aiEditor',
    toolbar_groups: {
      alignment: {
        icon: 'align-left',
        tooltip: 'Alignment',
        items: 'alignleft aligncenter alignright alignjustify',
      },
    },
  };

  return {
    resize: true,
    height: 250,
    min_height: 200,
    max_height: 450,
    object_resizing: 'img',
    menubar: false,
    statusbar: true,
    contextmenu: false,
    plugins: ['link', 'code', 'image', 'advlist', 'lists', 'table'],
    extended_valid_elements: 'script[language|type|src]',
    branding: false,
    setup: (editor) => {
      editor.ui.registry.addToggleButton('customFuenteHTML', {
        icon: 'sourcecode',
        text: t('products.detail.nameSourceCode'),
        onAction: (api) => {
          editor.execCommand('mceCodeEditor', false, 'customFuenteHTML');
          api.setActive(!api.isActive());
        },
      });

      /**
       * This is a workaround to prevent tox menu move when parent scrolls down.
       * It is necessary until we upgrade to v6. Then we can use ui_mode: 'split'.
       * https://github.com/tinymce/tinymce/issues/5097#issuecomment-663319124
       *  */
      editor.on('PostRender', () => {
        const container = editor.getContainer();
        const uiContainer = document.querySelector('.tox.tox-tinymce-aux');
        if (uiContainer) container.parentNode?.appendChild(uiContainer);
      });
    },
    content_style: ` 
        @font-face {
            font-family: 'Geist';
            src: url('${TINYMCE_FONTS}/Geist-Regular.woff2')
                format('woff2');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
          }
        @font-face {
            font-family: 'Geist';
            src: url('${TINYMCE_FONTS}/Geist-SemiBold.woff2')
                format('woff2');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
          }
        body { font-family:Geist; font-size:15px; padding:8px; margin: 0; }
        .mce-content-body {
          margin: 0;
        }
        p {
          margin: 0 0 0.75em 0;
        }
        `,
    file_picker_types: 'image',
    images_upload_handler: imageUploadHandler(t),
    images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
    image_advtab: true,
    convert_urls: false,
    ...toolbarOptions,
  };
};
