import { Link, Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { useNavegate } from 'App/hooks';
import {
  trackingCustomerAlertClose,
  trackingCustomerAlertPlans,
} from 'domains/Customers/tracking';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields/useTraslationsMetafields';

export default function InviteToCreateMetafieldAlert() {
  const t = useTranslationMetafields();
  const { goTo } = useNavegate();

  const handleClick = () => {
    trackingCustomerAlertPlans('CustomerListPage');
    goTo('/settings/metafields');
  };

  const onClose = () => {
    trackingCustomerAlertClose('CustomerListPage');
  };

  return (
    <AlertSessionStorage
      appearance="primary"
      title={t(`createInviteCustomer.title`)}
      keyName="showInviteToCreateMetafieldAlert"
      onRemove={onClose}
    >
      <Text>{t(`createInviteCustomer.subtitle`)}</Text>
      <Link onClick={handleClick}>{t(`createInviteCustomer.link`)}</Link>
    </AlertSessionStorage>
  );
}
