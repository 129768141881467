import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('invoicesInfoPage.errors.name'),
  address: Yup.string().required('invoicesInfoPage.errors.address'),
  numberId: Yup.string().required('invoicesInfoPage.errors.number_id'),
  personType: Yup.string().required('invoicesInfoPage.errors.person_type'),
  numberIdType: Yup.string().required('invoicesInfoPage.errors.number_id_type'),
  vatCondition: Yup.string()
    .nullable(false)
    .required('invoicesInfoPage.errors.vat_condition'),
});
