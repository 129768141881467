import { useState } from 'react';
import productsServices from 'domains/Catalog/Products/productsServices';
import { defaultFilters } from 'domains/Catalog/Products/productsSlice/constants';

function useGetProductCountForCategory(id: string) {
  const [productsCount, setProductsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProductCount = async () => {
    try {
      const { total } = await productsServices.getProducts({
        ...defaultFilters,
        categoryId: id,
      });

      setProductsCount(total);
    } catch (e) {
      console.error('Error obtaining product count', e);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchProductCount,
    productsCount,
    isLoading,
  };
}

export default useGetProductCountForCategory;
