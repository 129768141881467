import { Text, IconButton } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

function Skeleton(): JSX.Element {
  return (
    <DataList.Row gap="1">
      <Stack justify="space-between" align="flex-start">
        <Stack column align="flex-start" gap="1">
          <Text.Skeleton fontSize="highlight" />
          <Text.Skeleton fontSize="highlight" />
          <Text.Skeleton fontSize="highlight" />
        </Stack>
        <Stack gap="1">
          <IconButton.Skeleton width="32px" height="32px" />
        </Stack>
      </Stack>
    </DataList.Row>
  );
}

export default Skeleton;
