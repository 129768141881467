import { ChangeEvent } from 'react';
import {
  Input as InputNimbus,
  InputProps as InputNimbusProps,
} from '@nimbus-ds/components';
import {
  isValidIntegerNumber,
  isValidNumber,
} from '../InputNumber/validatorUtils';

type TypeValidator = 'integer' | 'float' | 'float2d' | 'float3d' | 'float5d';

type Validator = Record<TypeValidator, (v: string) => boolean>;

const validators: Validator = {
  integer: isValidIntegerNumber,
  float: (v) => isValidNumber(v),
  float2d: (v) => isValidNumber(v, 2),
  float3d: (v) => isValidNumber(v, 3),
  float5d: (v) => isValidNumber(v, 5),
};

interface InputNumberNimbusProps extends Omit<InputNimbusProps, 'type'> {
  readonly type: TypeValidator;
  readonly onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly defaultDecimals?: number;
}

function InputNumberNimbus({
  type,
  onChange,
  defaultDecimals,
  ...props
}: InputNumberNimbusProps): JSX.Element {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let sanitizedValue = e.target.value.replace(',', '.').trim();
    if (isNaN(+sanitizedValue)) sanitizedValue = '';
    if (
      !!defaultDecimals &&
      type !== 'integer' &&
      sanitizedValue.split('.')[1] &&
      sanitizedValue.split('.')[1].length > defaultDecimals
    ) {
      sanitizedValue = parseFloat(sanitizedValue).toFixed(defaultDecimals);
    }
    if (validators[type](sanitizedValue)) e.target.value = sanitizedValue;
    onChange(e);
  };

  return <InputNimbus onChange={handleChange} inputMode="decimal" {...props} />;
}

export default InputNumberNimbus;
