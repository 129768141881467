import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  OrderDetailsResponseDto,
  OrderCancelRequestDto,
  OrderFulfillRequestDto,
  OrderFulfillmentUpdateRequestDto,
  OrderUpdateResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import ordersService from '../../ordersService';
import {
  fetchOrderById,
  cleanUpDetails,
  archiveOrder as archiveOrderById,
  cancelOrderById,
  openOrderById,
  remarkOrderById,
  paidOrderById,
  packOrderById,
  fulfillOrderById,
  refreshOrderById,
  partiallyPaidOrderById,
} from '../../ordersSlice/orderSlice';
import { getOrderDetailsWithStatus } from '../../ordersSlice/ordersSelectors';
import { DeliveryAddressValuesType } from '../../pages/OrderDetailsPage/components/EditDeliveryAdressModal/EditDeliveryAdressModal';

interface InterfaceOrderDetails {
  isError: boolean;
  isLoading: boolean;
  orderDetails: OrderDetailsResponseDto | null;
  archiveOrder: () => Promise<OrderDetailsResponseDto>;
  paidOrder: () => Promise<OrderDetailsResponseDto>;
  partiallyPaidOrder: (method: string) => Promise<OrderDetailsResponseDto>;
  openOrder: (validateStock?: boolean) => Promise<OrderUpdateResponseDto>;
  packOrder: (
    fulfillments?: OrderFulfillmentUpdateRequestDto[],
  ) => Promise<OrderDetailsResponseDto>;
  remarkOrder: (text: string) => void;
  cancelOrder: (
    action: OrderCancelRequestDto,
  ) => Promise<OrderDetailsResponseDto>;
  fulfillOrder: (
    action: OrderFulfillRequestDto,
    fulfillments?: OrderFulfillmentUpdateRequestDto[],
  ) => Promise<OrderDetailsResponseDto>;
  editDeliveryAddress: (deliveryAddress: DeliveryAddressValuesType) => void;
}

function useOrderDetails(id: string): InterfaceOrderDetails {
  const dispatch = useAppDispatch();

  const { isError, isLoading, orderDetails } = useSelector(
    getOrderDetailsWithStatus,
  );

  useEffect(() => {
    const promise = dispatch(fetchOrderById(id));
    return () => {
      promise.abort();
      dispatch(cleanUpDetails());
    };
  }, [dispatch, id]);

  const archiveOrder = useCallback(async () => {
    const payload = {
      isActionFromOrdersList: false,
    };
    const archivedOrder = await dispatch(archiveOrderById({ id, payload }));
    return unwrapResult(archivedOrder);
  }, [id, dispatch]);

  const paidOrder = useCallback(
    async (gateway?: string, gatewayMethod?: string, gatewayName?: string) => {
      const paidOrder = await dispatch(
        paidOrderById({
          id,
          gateway,
          gatewayMethod,
          gatewayName,
          isActionFromOrdersList: false,
        }),
      );
      return unwrapResult(paidOrder);
    },
    [id, dispatch],
  );

  const partiallyPaidOrder = useCallback(
    async (method: string, name?: string) => {
      const partiallyPaidOrder = await dispatch(
        partiallyPaidOrderById({ id, method, name }),
      );
      return unwrapResult(partiallyPaidOrder);
    },
    [id, dispatch],
  );

  const openOrder = useCallback(
    async (validateStock?: boolean) => {
      const orderOpened = await dispatch(
        openOrderById({ id, isActionFromOrdersList: false, validateStock }),
      );
      return unwrapResult(orderOpened);
    },
    [dispatch, id],
  );

  const cancelOrder = useCallback(
    async (action: OrderCancelRequestDto) => {
      // We set as true because this action redirects to the orders list, so we need it to refresh the order synchronously
      action.isActionFromOrdersList = true;
      const canceledOrder = await dispatch(cancelOrderById({ id, action }));
      return unwrapResult(canceledOrder);
    },
    [dispatch, id],
  );

  const remarkOrder = useCallback(
    async (text: string) =>
      unwrapResult(await dispatch(remarkOrderById({ id, text }))),

    [dispatch, id],
  );

  const packOrder = useCallback(
    async (fulfillments?: OrderFulfillmentUpdateRequestDto[]) => {
      const packedOrder = await dispatch(
        packOrderById({
          orderId: id,
          fulfillments: fulfillments ?? [],
          updateHistory: true,
          isActionFromOrdersList: false,
        }),
      );
      return unwrapResult(packedOrder);
    },
    [id, dispatch],
  );

  const fulfillOrder = useCallback(
    async (action: OrderFulfillRequestDto) => {
      const fulfilledOrder = await dispatch(
        fulfillOrderById({
          orderId: id,
          action: { ...action, isActionFromOrdersList: false },
          updateHistory: true,
        }),
      );
      return unwrapResult(fulfilledOrder);
    },
    [dispatch, id],
  );

  const editDeliveryAddress = useCallback(
    async (deliveryAddress: DeliveryAddressValuesType) => {
      await ordersService.editDeliveryAddress({
        id,
        deliveryAddress,
      });

      dispatch(refreshOrderById(id));
    },
    [dispatch, id],
  );

  return {
    fulfillOrder,
    packOrder,
    remarkOrder,
    isError,
    isLoading,
    orderDetails,
    archiveOrder,
    cancelOrder,
    openOrder,
    paidOrder,
    partiallyPaidOrder,
    editDeliveryAddress,
  };
}

export default useOrderDetails;
