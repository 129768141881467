// PAYMENTS EVENTS
export const PAYMENT_CUSTOM_SAVE = 'payment_custom_save';
export const PAYMENT_CUSTOM_PIX_CLICK = 'payment_custom_pix_click';
export const PAYMENT_CUSTOM_WIRE_TRANSFER_CLICK =
  'payment_custom_wire_transfer_click';
export const PAYMENT_CUSTOM_CASH_CLICK = 'payment_custom_cash_click';
export const PAYMENT_CUSTOM_OTHER_CLICK = 'payment_custom_other_click';
export const PAYMENT_PAGE_VIEW = 'payment_page_view';
export const PAYMENT_INTEGRATION_REMOVE_CLICK =
  'payment_integration_remove_click';
export const PAYMENT_INTEGRATION_START = 'payment_integration_start';
export const PAYMENT_METHOD_SELECT = 'payment_method_select';
export const PAYMENT_ADD_CONFIRM = 'payment_add_confirm';
export const PAYMENTS_GATEWAY_SEE_SITE_CLICK =
  'payments_gateway_see_site_click';
export const PAYMENT_CUSTOM_CONFIGURE = 'payment_custom_configure';
export const PAYMENT_CUSTOM_EDIT = 'payment_custom_edit';
export const PAYMENT_INTEGRATION_EDIT_CLICK = 'payment_integration_edit_click';
export const PAYMENT_MP_SYNC_CLICK = 'payment_mp_sync_click';

// CATALOG EVENTS
export const CATALOG_PRODUCT_BULK_PRICE =
  'new_admin_product_bulk_price_change_click';
export const CATALOG_PRODUCT_BULK_METAFIELDS =
  'new_admin_product_bulk_metafields';
export const CATALOG_PRODUCT_BULK_METAFIELDS_CANCEL =
  'new_admin_product_bulk_metafields_cancel';
export const CATALOG_PRODUCT_BULK_METAFIELDS_SAVE =
  'new_admin_product_bulk_metafields_save';
export const CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS =
  'new_admin_product_domain_bulk_metafields';
export const CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_SAVE =
  'new_admin_product_domain_bulk_metafields_save';
export const CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_CANCEL =
  'new_admin_product_domain_bulk_metafields_cancel';
export const CATALOG_PRODUCT_ORGANIZE_CLICK =
  'new_admin_product_organize_click';
// CATALOG DETAIL PRODUCTS
export const CATALOG_PRODUCT_DETAIL_METAFIELD_CLICK =
  'new_admin_product_detail_metafield_click';
export const CATALOG_PRODUCT_DETAIL_IMAGES_CLICK =
  'new_admin_product_detail_images_click';
export const CATALOG_PRODUCT_DETAIL_IMAGES_SUCCESS =
  'new_admin_product_detail_images_success';
export const CATALOG_PRODUCT_DETAIL_IMAGES_ERROR =
  'new_admin_product_detail_images_error';
export const CATALOG_PRODUCT_DETAIL_VIDEO_BLUR =
  'new_admin_product_detail_video_blur';
export const CATALOG_PRODUCT_DETAIL_PRICE_BLUR =
  'new_admin_product_detail_price_blur';
export const CATALOG_PRODUCT_DETAIL_PROMOTIONAL_BLUR =
  'new_admin_product_detail_promotional_blur';
export const CATALOG_PRODUCT_DETAIL_COST_BLUR =
  'new_admin_product_detail_cost_blur';
export const CATALOG_PRODUCT_DETAIL_SKU_BLUR =
  'new_admin_product_detail_sku_blur';
export const CATALOG_PRODUCT_DETAIL_WEIGHT_BLUR =
  'new_admin_product_detail_weight_blur';
export const CATALOG_PRODUCT_DETAIL_DEPTH_BLUR =
  'new_admin_product_detail_depth_blur';
export const CATALOG_PRODUCT_DETAIL_WIDTH_BLUR =
  'new_admin_product_detail_width_blur';
export const CATALOG_PRODUCT_DETAIL_HEIGHT_BLUR =
  'new_admin_product_detail_height_blur';
export const CATALOG_PRODUCT_DETAIL_NETWORK_BLUR =
  'new_admin_product_detail_network_blur';
export const CATALOG_PRODUCT_DETAIL_CATEGORY_CLICK =
  'new_admin_product_detail_category_click';
export const CATALOG_PRODUCT_DETAIL_SEO_BLUR =
  'new_admin_product_detail_seo_blur';
export const CATALOG_PRODUCT_DETAIL_SAVE_SUCCESS =
  'new_admin_product_detail_save_success';
export const CATALOG_PRODUCT_DETAIL_SAVE_ERROR =
  'new_admin_product_detail_save_error';
export const CATALOG_PRODUCT_DETAIL_CANCEL_CLICK =
  'new_admin_product_detail_cancel_click';
export const CATALOG_PRODUCT_LIST_FILTER_SEARCH =
  'new_admin_product_list_filter_search';
export const CATALOG_PRODUCT_LIST_ORDER_CLICK =
  'new_admin_product_list_order_click';
export const CATALOG_PRODUCT_LIST_DUPLICATE_CLICK =
  'new_admin_product_list_duplicate_click';
export const CATALOG_PRODUCT_LIST_DELETE_CLICK =
  'new_admin_product_list_delete_icon_click';
export const CATALOG_PRODUCT_DROP_IMAGES = 'new_admin_product_list_drop_images';
export const CATALOG_INVENTORY_LIST_SELECTOR_CLICK =
  'new_admin_inventory_list_selector_click';
export const CATALOG_PRODUCT_DETAIL_CREATE_CATEGORY_SUCCESS =
  'new_admin_product_detail_create_category_success';
export const CATALOG_PRODUCT_DETAIL_VARIANT_HIDE_SELECT =
  'new_admin_product_detail_variant_hide_select';

// CATEGORIES EVENTS
export const CATEGORIES_SAVE_METAFIELDS =
  'new_admin_categories_save_metafields';
export const CATEGORIES_TREE_SAVE_BEFORE =
  'new_admin_categories_tree_save_before';
export const CATEGORIES_TREE_SAVE_AFTER =
  'new_admin_categories_tree_save_after';
export const CATEGORIES_CREATE_BUTTON_CLICK =
  'new_admin_categories_create_button_click';
export const CATEGORIES_SUBCATEGORY_CLICK =
  'new_admin_categories_subcategory_click';
export const CATEGORIES_EDIT_CLICK = 'new_admin_categories_edit_click';
export const CATEGORIES_HIDE_CLICK = 'new_admin_categories_hide_click';
export const CATEGORIES_UNHIDE_CLICK = 'new_admin_categories_unhide_click';
export const CATEGORIES_DELETE_CLICK = 'new_admin_categories_delete_click';
export const CATEGORIES_CATEGORY_CLICK = 'new_admin_categories_category_click';
export const CATEGORIES_DESCRIPTION_IMAGE_CLICK =
  'new_admin_categories_description_image_click';
export const CATEGORIES_DESRIPTION_ON_BLUR = 'categories_description_on_blur';

export const PRODUCT_STOCK_EDIT_CLICK = 'product_stock_edit_click';
export const PRODUCT_VARIANT_STOCK_EDIT_CLICK =
  'product_variant_stock_edit_click';
export const PRODUCT_EDIT_ON_PRODUCT_LIST = 'product_edit_on_product_list';

export const PRODUCT_LIST_ACTIONS_COPY_CLICK =
  'product-list-actions-copy-click';
export const INVENTORY_HISTORY_CLICK = 'inventory_history_click';
export const PRODUCT_ACTION_DUPLICATE_PRODUCT_MODAL =
  'product_action-duplicate-product_submit';
export const VARIANT_APPLY_TO_ALL = 'variant_apply_to_all';
export const PRODUCT_VARIANT_METAFIELD_SAVE = 'product_variant_metafield_save';
export const PRODUCT_VARIANT_METAFIELD_SAVE_ERROR =
  'product_variant_metafield_save_error';

export const PRODUCT_VARIANT_METAFIELD_FILTER =
  'product_variant_metafield_filter';
export const PRODUCT_VARIANT_METAFIELD_FILTER_ERROR =
  'product_variant_metafield_filter_error';
export const PRODUCT_ADD_NEW_PRODUCT_CLICK = 'product_add_new_product_click';
export const PRODUCT_EXPORT_IMPORT = 'product_export_import';
export const PRODUCT_MASS_UPLOAD_IGNORE_ALL_COLUMNS =
  'product_mass_upload_export_ignore_all_click';
export const PRODUCT_EDIT_NEW_VARIANT_STOCK = 'product_edit_new_variant_stock';
export const WEIGHT_AND_VOLUME_ALERT_CLICK = 'weight_and_volume_alert_click';
export const WEIGHT_AND_VOLUME_ALERT_CLOSE = 'weight_and_volume_alert_close';

export const PRODUCT_STOCK_CHANGE_ON_INVENTORY_LIST =
  'product_stock_change_on_inventory_list';
export const PRODUCT_STOCK_INVENTORY_FROM_INVENTORY =
  'product_stock_inventory_from_inventory';
export const PRODUCT_STOCK_CALCULATOR_FROM_INVENTORY =
  'product_stock_calculator_from_inventory';
export const PRODUCT_STOCK_CALCULATOR_CHANGE =
  'product_stock_calculator_change';
export const PRODUCT_SHOW_MORE_VARIANTS_ON_INVENTORY_LIST =
  'product_show_more_variants_on_inventory_list';
export const CORE_PRODUCT_ADD_ONBOARDING_CLICK =
  'core_product_add_onboarding_click';
export const CORE_PRODUCT_IMPORT_ONBOARDING_CLICK =
  'core_product_import_onboarding_click';
export const CORE_APP_STORE_DROPSHIPPING_APP_CLICK =
  'core_app_store_dropshipping_app_click';
export const CORE_APP_STORE_DROPSHIPPING_LIST_CLICK =
  'core_app_store_dropshipping_list_click';

//CUSTOMERS EVENTS
export const CUSTOMER_TRY_EXPORT = 'customer_try_export';
export const CUSTOMER_FILTER_MODAL_OPEN = 'customer_filter_modal_open';
export const CUSTOMER_FILTER = 'customer_filter';
export const CUSTOMER_SAVE_METAFIELDS = 'customer_save_metafields';
export const CUSTOMER_ALERT_PLANS = 'customer_alert_plans';
export const CUSTOMER_ALERT_CLOSE = 'customer_alert_close';

// ORDERS EVENTS
export const ORDER_FILTER = 'order_filter';
export const ORDER_SEARCH = 'order_search';
export const ORDER_TRANSACTION_ID_LINK_CLICK =
  'order_transaction_id_link_click';
export const ORDER_SELECT_BULK_LINK_CLICK = 'order_select_bulk_link_click';
export const ORDER_METAFIELD_SAVE = 'order_metafield_save';
export const ORDER_METAFIELD_SAVE_ERROR = 'order_metafield_save_error';
export const ORDER_METAFIELD_FILTER = 'order_metafield_filter';
export const ORDER_METAFIELD_FILTER_ERROR = 'order_metafield_filter_error';
export const ORDERS_BULK_METAFIELDS = 'new_admin_orders_bulk_metafields';
export const ORDERS_BULK_METAFIELDS_CANCEL =
  'new_admin_orders_bulk_metafields_cancel';
export const ORDERS_BULK_METAFIELDS_SAVE =
  'new_admin_orders_bulk_metafields_save';
export const ORDER_LIST_EXPORT = 'order_list_export';
export const ORDER_DRAFT_ORDER_CREATE = 'order_draft_order_create';
export const ORDER_LIST_FILTER_CLICK = 'order_list_filter_click';
export const ORDER_EDIT_CLICK = 'order_edit_click';
export const ORER_EDIT_SAVE_CLICK = 'order_edit_save_click';
export const ORDER_BUTTON_PRINT_DOCUMENT = 'order_button_print_document';
export const ORDER_REOPEN_BUTTON_CLICK = 'order_reopen_button_click';
export const ORDER_REOPEN_WITH_ISSUES_BUTTON_CLICK =
  'order_reopen_with_issues_button_click';

// NUVEMPAGO
export const NUVEMPAGO_MENU_CLICK = 'click-nuvem-pago-menu';
export const PAYMENTS_METHODS_NUVEMPAGO_ACTIVATE_CLICK =
  'payments_methods_nuvempago_activate_click';
export const PAYMENTS_METHODS_NUVEMPAGO_DEACTIVATE_CLICK =
  'payments_methods_nuvempago_deactivate_click';
export const PAYMENTS_METHODS_MODAL_NUVEMPAGO_DEACTIVATE_CLICK =
  'payments_methods_modal_nuvempago_deactivate_click';
export const NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_ACTIVATE_CLICK =
  'nuvempago_manual_activation_registration_activate_click';

// 2FA EVENTS
export const AUTHENTICATION_FACTOR_ACTIVATED =
  'authentication-factor-activated';
export const AUTHENTICATION_FACTOR_DEACTIVATED =
  'authentication-factor-deactivated';
export const AUTHENTICATION_FACTOR_RECOVERY_CODES_COPIED =
  'two_steps_verification_recovery_key_copy_button_click';
export const AUTHENTICATION_FACTOR_RECOVERY_CODES_DOWNLOADED =
  'two_steps_verification_recovery_key_download_button_click';
export const AUTHENTICATION_FACTOR_RECOVERY_CODES_START_ACTIVATION =
  'two_steps_verification_activate_key_button_click';
export const AUTHENTICATION_FACTOR_RECOVERY_CODES_UPDATE =
  'two_steps_verification_update_recovery_key_button_click';
export const AUTHENTICATION_FACTOR_LOGIN_WITH_RECOVERY_CODES =
  'login_access_with_key_recovery_confirm_button_click';
export const AUTHENTICATION_FACTOR_RECOVERY_CODES_HELP_LINK_CLICKED =
  'login_access_with_key_recovery_help_button_click';
export const DASHBOARD_RECOVERY_CODES_UPDATE_AFTER_LOGIN =
  'general_login_modal_access_with_key_recovery_update_button_click';

// SESSION MANAGEMENT EVENTS
export const SESSION_MANAGEMENT_MENU_ITEM_CLICK =
  'session_management_menu_item_click';
export const SESSION_MANAGEMENT_CLOSE_SESSION_BUTTON_CLICK =
  'session_management_close_session_button_click';
export const SESSION_MANAGEMENT_CLOSE_ALL_SESSIONS_BUTTON_CLICK =
  'session_management_close_all_sessions_button_click';

// BILLING EVENTS
export const BILLING_GO_TO_PAY_PLAN_CLICK = 'billing_go_to_pay_plan_click';
export const BILLING_GO_TO_ANNUAL_DISCOUNT = 'billing_go_to_annual_discount';
export const BILLING_UPSELL_MODAL_SHOW = 'billing_upsell_modal_show';
export const BILLING_UPSELL_MODAL_CLOSE = 'billing_upsell_modal_close';
export const BILLING_UPSELL_PLAN_CHANGE = 'billing_upsell_plan_change';
export const BILLING_DOWNGRADE_PLAN = 'billing_downgrade_plan';
export const BILLING_UPSELL_PLANS_PAGE_CLICK =
  'billing_upsell_modal_plans_page_click';
export const BILLING_POST_UPSELL_ADBAR_CLICK = 'post_upsell_adbar_click';
export const BILLING_SOFT_BLOCK_PAYMENT_CLICK =
  'billing_soft_block_payment_click';
export const BILLING_SOFT_BLOCK_MODAL_SHOW = 'billing_soft_block_modal_show';

export const BILLING_VIEW_CHECKOUT_CHARGES = 'billing_view_checkout_charges';
export const BILLING_VIEW_CHECKOUT_CHARGES_AFTER_PAYMENT =
  'billing_view_checkout_charges_after_payment';
export const BILLING_CHECKOUT_START_PLAN_PAYMENT =
  'billing_checkout_start_plan_payment_click';
export const BILLING_VIEW_CHECKOUT_SELECT_METHOD_FOR_PLAN =
  'billing_view_checkout_select_method_for_plan';
export const BILLING_CHECKOUT_SELECT_METHOD_FOR_PLAN_CLICK =
  'billing_checkout_select_method_for_plan_click';
export const BILLING_VIEW_CHECKOUT_PAY_CREDIT_CARD =
  'billing_view_checkout_pay_credit_card';
export const BILLING_CHECKOUT_CREDIT_CARD_PAY_CLICK =
  'billing_checkout_credit_card_pay_click';
export const BILLING_VIEW_CHECKOUT_PAY_WIRE_TRANSFER =
  'billing_view_checkout_pay_wire_transfer';
export const BILLING_CHECKOUT_WIRE_TRANSFER_PAY_CLICK =
  'billing_checkout_wire_transfer_pay_click';
export const BILLING_CHANGE_PLANS_CLICK = 'billing_checkout_change_plan_click';
export const BILLING_RECURRING_CANCEL_CLICK =
  'billing_checkout_cancel_recurrent_payment_click';
export const BILLING_LAST_PAYMENTS = 'billing_checkout_last_payments';
export const BILLING_CHECKOUT_VIEW_DETAILS = 'billing_checkout_view_details';
export const TEST_DISCOUNTS_ADBAR_SHOW = 'test_discounts_adbar_show';
export const TEST_DISCOUNTS_ADBAR_CLICK = 'test_discounts_adbar_click';
export const BILLING_PAYORDER_CHECKOUT_VIEW =
  'billing_view_new_checkout_pay_order';
export const BILLING_PAYORDER_CHECKOUT_CONTINUE_CLICK =
  'billing_new_checkout_pay_order_start_payment_click';
export const BILLING_PAYORDER_CHECKOUT_FINISH =
  'billing_new_checkout_pay_order_finish';

// PARTNERS EVENTS
export const PARTNERS_APPS_ADD_CLICK = 'partners_apps_add_click';
export const MENU_PRIMARY_APPS_CLICK = 'menu_primary_apps_click';

// SHIPPING EVENTS
export const SHIPPING_DISTRIBUTION_CENTER_DEFINE =
  'shipping_distribution_center_define';
export const SHIPPING_DISTRIBUTION_CENTER_ADD =
  'shipping_distribution_center_add';
export const SHIPPING_VIEWED_METHOD = 'shipping_viewed_method';
export const SHIPPING_SAVED_METHODS = 'shipping_saved_method';
export const SHIPPING_APPS_MORE_CLICK = 'shipping-apps_more_click';
export const SHIPPING_DISTRIBUTION_CENTER_CREATED_NAVIGATION =
  'shipping_distribution_center_created_navigation';
export const SHIPPING_ADD_DISTRIBUTION_CENTER =
  'shipping_add_distribution_center';
export const SHIPPING_ENABLE_MULTICD_BY_CD = 'shipping_enable_multicd_by_cd';
export const SHIPPING_EDIT_DISTRIBUTION_CENTER =
  'shipping_edit_distribution_center';
export const SHIPPING_DELETE_DISTRIBUTION_CENTER =
  'shipping_delete_distribution_center';
export const SHIPPING_UPGRADE_MODAL_SEE_PLANS_CLICK =
  'shipping_upgrade_modal_see_plans_click';
export const SHIPPING_UPGRADE_CARD_SEE_PLANS_CLICK =
  'shipping_upgrade_modal_see_plans_click';
export const SHIPPING_DISTRIBUTION_CENTER_NOW_ALLOWED =
  'shipping_distribution_center_now_allowed';
export const SHIPPING_NAVIGATE_TO_DISTRIBUTION_CENTER =
  'shipping_navigate_to_distribution_center';
export const SHIPPING_CREATE_SECONDARY_CD_BY_ADDRESSING_RULE_CARD =
  'shipping_create_secondary_cd_by_addressing_rule_card';
export const SHIPPING_OPEN_ADDRESSING_RULE_MODAL =
  'shipping_open_addressing_rule_modal';
export const SHIPPING_CHANGE_ADDRESSING_RULE =
  'shipping_change_addressing_rule';
export const SHIPPING_CLOSE_ADDRESSING_RULE_MODAL =
  'shipping_close_addressing_rule_modal';
export const SHIPPING_PRINT_DOCUMENT_PAGE_VIEW =
  'shipping_print_document_page_view';
export const SHIPPING_PRINT_DOCUMENT_SETTING = 'shipping_print_receipt_setting';
export const SHIPPING_PRINT_DOCUMENT_CANCEL_BUTTON_CLICK =
  'shipping_print_document_cancel_button_click';
export const SHIPPING_PRINT_DOCUMENT_CONFIRMATION_BUTTON_CLICK =
  'shipping_print_document_confirmation_button_click';

// STATS EVENTS
export const STATS_SEE_PLANS_CLICK = 'stats_see_plans_click';
export const STATS_TRIAL_BAR_CLICK = 'stats_trial_bar_click';

// COMMONS EVENTS
export const UPGRADE_SEE_PLANS_CLICK = 'upgrade_see_plans_click';
export const HELP_TUTORIAL_CLICK = 'help_tutorial_click';
export const MENU_PRIMARY_PRODUCTS_CLICK = 'menu_primary_products_click';
export const MENU_PRIMARY_STATS_CLICK = 'menu_primary_stats_click';
export const MENU_CONFIGURATION_CLICK = 'menu_configuration_click';

// PAGO NUBE EVENTS

export const CLICK_ACTIVATE_NUVEM_PAGO_PAYMENT_METHODS =
  'click-activate-nuvem-pago-payment-methods';
export const CLICK_ACTIVATE_NUVEM_PAGO_MODAL_DOCUMENT =
  'click-activate-nuvem-pago-modal-document';
export const SUCCESS_ACTIVATION_NUVEM_PAGO = 'success-activation-nuvem-pago';
export const PAGO_NUBE_ONBOARDING_MODAL_SEE_SETTINGS_CLICK =
  'pago_nube_onboarding_modal_see_settings_click';

// NUVEM PAGO NUBE EVENTS
export const NUVEMPAGO_ONBOARDING_MODAL_SEE_SETTINGS_CLICK =
  'nuvempago_onboarding_modal_see_settings_click';
export const PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_EDIT_CLICK =
  'payment_nuvem_pago_payments_menu_edit_click';
export const PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_REMOVE_CLICK =
  'payment_nuvem_pago_payments_menu_remove_click';
export const PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_DEADLINE_FEES_CLICK =
  'payment_nuvem_pago_payments_menu_deadline_fees_click';
export const PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_ACCOUNT_CLICK =
  'payment_nuvem_pago_payments_menu_account_click';
export const PAYMENT_MODAL_OTHERSPLANS_REMOVE_NUVEMPAGO_REMOVE_CLICK =
  'payment_modal_otherplans_remove_nuvempago_remove_click';
export const PAYMENT_MODAL_NEXT_REMOVE_NUVEMPAGO_REMOVE_CLICK =
  'payment_modal_next_remove_nuvempago_remove_click';
export const PAYMENT_MODAL_FREEMIUM_REMOVE_NUVEMPAGO_REMOVE_CLICK =
  'payment_modal_freemium_remove_nuvempago_remove_click';
export const NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_CAN_CLICK =
  'nuvempago_manual_activation_registration_can_click';

// THEMES EVENTS
export const MENU_SECONDARY_PREFERENCES_CLICK =
  'menu_secondary_preferences_click';
export const MENU_SECONDARY_PASSWORD_PROTECTED_CLICK =
  'menu_secondary_password_protected_click';
export const MENU_SECONDARY_THEMES_CLICK = 'menu_secondary_themes_click';
export const THEME_LAYOUT_CUSTOMIZE_CURRENT = 'theme_layout_customize_current';
export const THEME_FTP_ENABLE = 'theme_ftp_enable';
export const THEME_FTP_DISABLE = 'theme_ftp_disable';
export const THEME_LAYOUT_CUSTOMIZE_DRAFT = 'theme_layout_customize_draft';
export const THEME_LAYOUT_CHANGE = 'theme_layout_change';
export const THEME_SELECTION_UPDATED = 'theme_selection_updated';
export const THEME_LAYOUT_DRAFT_ACTIONS_VIEW =
  'theme_layout_draft_actions_view';
export const THEME_LAYOUT_DRAFT_PUBLISH = 'theme_layout_draft_publish';
export const THEME_LAYOUT_DRAFT_DELETE = 'theme_layout_draft_delete';
export const THEME_LAYOUT_DRAFT_CREATION = 'theme_layout_draft_creation';
export const THEME_LAYOUT_DRAFT_CREATION_DEFAULT =
  'theme_layout_draft_creation_default';
export const THEME_LAYOUT_DRAFT_CREATION_OTHERS =
  'theme_layout_draft_creation_others';

// PAGES EVENTS
export const MENU_SECONDARY_PAGES_CLICK = 'menu_secondary_pages_click';

// WHATSAPP EVENTS
export const MENU_SECONDARY_WHATSAPP_CLICK = 'menu_secondary_whatsapp_click';
export const WHATSAPP_ENABLE_CHATBUTTON = 'whatsapp_enable_chatbutton';
export const WHATSAPP_DISABLE_CHATBUTTON = 'whatsapp_disable_chatbutton';
export const WHATSAPP_EDIT_NUMBER_CLICK = 'whatsapp_edit_number_click';

// NAVIGATION EVENTS
export const MENU_SECONDARY_NAVIGATION_CLICK =
  'menu_secondary_navigation_click';
export const MENU_STORE_VISIT = 'menu_store_visit';
export const MENU_SECONDARY_BLOG_CLICK = 'menu_secondary_blog_click';

// ACCOUNT MENU EVENTS
export const MENU_PRIMARY_ACCOUNT_CLICK = 'menu_primary_user_account_click';

// STORE DOMAINS EVENTS
export const MENU_SECONDARY_DOMAINS_CLICK = 'menu_secondary_domains_click';
export const ADD_DOMAIN_CLICK = 'domains_associate';
export const DELETE_DOMAIN_CLICK = 'domains_delete';
export const CREATE_DOMAIN_SUBMIT_CLICK = 'domain_create_submit';
export const DOMAINS_ERROR_ALERT_DASHBOARD_CLICK =
  'domains_error_alert_dashboard_click';
export const DOMAINS_ERROR_ALERT_DASHBOARD_DISMISS =
  'domains_error_alert_dashboard_dismiss';
export const DOMAIN_ERROR_REVIEW_CLICK = 'domain_error_review_link_click';
export const DOMAINS_ERROR_ALERT_DASHBOARD_SHOW =
  'domains_error_alert_dashboard_show';

// PREFERENCES CHECKOUT EVENTS
export const MENU_SECONDARY_PREFERENCES_CHECKOUT =
  'menu_secondary_preferences_checkout_click';

// USERS PERMISSIONS
export const MENU_SECONDARY_USERS_PERMISSIONS_CLICK =
  'menu_secondary_users_click';

// NOTIFICATIONS
export const MENU_SECONDARY_NOTIFICATIONS_CLICK =
  'menu_secondary_notifications_click';
export const CNAME_CONFIG_CAN_CLICK = 'cname_config_can_click';

// NUVEM ENVIO EVENTS
export const NUVEM_ENVIO_CLICK = 'nuvemenvio_click';
export const OPEN_ORDERS_NUVEM_ENVIO_DASHBOARD = 'open_nuvem_envio_dashboard';
export const NUVEM_ENVIO_VIEW = 'nuvemenvio_view';
export const NUVEMVIO_JADLOG_WELCOME_PAGE = 'Shipping options - Jadlog welcome';
export const NUVEM_ENVIO_COMMUNICATION = 'nuvemenvio_communication';

// DASHBOARD EVENTS
export const MENU_PRIMARY_HOME_CLICK = 'menu_primary_home_click';
export const DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PAYMENT_ALL =
  'dashboard_orders_frequent_access_waiting_payment_all';
export const DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_SHIPPING_ALL =
  'dashboard_orders_frequent_access_waiting_shipping_all';
export const DASHBOARD_ORDERS_FREQUENT_ACCESS_WAITING_PACKING =
  'dashboard_orders_frequent_access_waiting_packing';
export const DASHBOARD_SELECT_THEME_CLICK = 'dashboard_select_theme_click';
export const DASHBOARD_ADD_PRODUCT_CLICK = 'dashboard_add_product_click';
export const DASHBOARD_SHIPPING_CLICK = 'dashboard_shipping_click';
export const DASHBOARD_PAYMENTS_CLICK = 'dashboard_payments_click';
export const ONBOARDING_ENP_CLICK = 'onboarding_enp_click';
export const ONBOARDING_ENA_CLICK = 'onboarding_ena_click';
export const DASHBOARD_SELECT_STATISTICS_CLICK =
  'dashboard_select_statistics_click';
export const DAHSBOARD_ADDITIONAL_SETTINGS_CLICK =
  'dashboard_additional_settings_click';
export const DASHBOARD_FREE_COURSES_CLICK = 'dashboard_free_courses_click';
export const DASHBOARD_BOOST_STORE_CLICK = 'dashboard_boost_store_click';
export const DASHBOARD_2FA_CLICK = 'dashboard_2fa_click';
export const DASHBOARD_2FA_WARNING_CLICK = 'dashboard_2fa_warning_click';
export const DASHBOARD_2FA_LIMITED_ACCESS_CLICK =
  'dashboard_2fa_limited_access_click';
export const DASHBOARD_BILLING_TRANSACTION_FEES_PAGE_CLICK =
  'dashboard_billing_transaction_fees_page_click';
export const DASHBOARD_BILLING_TRANSACTION_FEES_PAYMENT_AVAILABLE_CLICK =
  'dashboard_billing_tx_fees_page_from_reminder_modal_click';
export const DASHBOARD_BILLING_TX_FEES_PAGE_FROM_REMINDER_MODAL_CLICK =
  'dashboard_billing_tx_fees_page_from_reminder_modal_click';
export const DASHBOARD_TRANSACTION_FEES_FREE_NUVEM_PAGO_CLICK =
  'dashboard_transaction_fees_free_nuvem_pago_click';

export const CLOUDFLARE_MIGRATION_ADBAR_CLICK =
  'cloudflare_migration_adbar_click';

export const CLOUDFLARE_SOFTBLOCK_UPDATED_CLICK =
  'cloudflare-softblock-updated_click';

export const CLOUDFLARE_SOFTBLOCK_TUTORIAL_CLICK =
  'cloudflare-softblock-tutorial_click';

export const CLOUDFLARE_SOFTBLOCK_SKIP_CLICK =
  'cloudflare-softblock-skip_click';

// PROMOTIONS
export const MENU_SECONDARY_PROMOTION_LIST_CLICK =
  'menu_secondary_promotions_list_click';
export const PROMOTIONS_CREATION_VIEW = 'promotions_creation_view';

// MARKETING
export const COUPONS_FILTERED = 'marketing_coupons_filter';
export const COUPONS_ORDERED = 'marketing_coupons_order';
export const DELETED_COUPONS_FILTERED = 'marketing_coupons_deleted_filter';
export const DELETED_COUPON_DETAIL = 'marketing_coupons_deleted_detail';
export const ACCESS_EDIT_COUPON_PAGE = 'marketing_coupons_access_edit_page';
export const EXIT_EDIT_COUPON_PAGE = 'marketing_coupons_exit_edit_page';

// FREE SHIPPING
export const MENU_SECONDARY_FREE_SHIPPING_LIST_CLICK =
  'menu_secondary_free_shipping_list_click';

// EXTERNAL CODES
export const MENU_SECONDARY_PREFERENCES_ADVANCED_CLICK =
  'menu_secondary_preferences_advanced_click';

// SEO REDIRECTS
export const MENU_SECONDARY_SEO_REDIRECTS_CLICK =
  'menu_secondary_seo_redirects_click';

// SOCIAL EVENTS
export const MENU_PRIMARY_SALES_CHANNEL_CLICK =
  'menu_primary_sales_channel_click';

export const MENU_SECONDARY_MARKETPLACES_APPS_CLICK =
  'menu_secondary_meli_apps_click';

// AI GENERATE EVENTS
export const AI_GENERATE_CTA_CLICK = 'ai_generate_cta_click';
export const AI_GENERATE_GENERATE_CLICK = 'ai_generate_generate_click';
export const AI_GENERATE_CANCEL_CLICK = 'ai_generate_cancel_click';
export const AI_GENERATE_REGENERATE_CLICK = 'ai_generate_regenerate_click';
export const AI_GENERATE_USE_CONTENT_CLICK = 'ai_generate_use_content_click';
export const AI_GENERATE_CONTENT_SELECT = 'ai_generate_content_select';

// AI IMAGES EVENTS
export const AI_EDIT_IMAGE_REMOVE_BACKGROUND_CTA_CLICK =
  'ai_edit_img_remove_bg_cta_click';
export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_CTA_CLICK =
  'ai_edit_img_replace_bg_w_color_cta_click';
export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_CTA_CLICK =
  'ai_edit_img_replace_bg_w_image_cta_click';

export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_PICK_CLICK =
  'ai_edit_img_replace_bg_w_color_pick_click';
export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_PICK_CLICK =
  'ai_edit_img_replace_bg_w_image_pick_click';

export const AI_EDIT_IMAGE_REMOVE_BACKGROUND_USE_CLICK =
  'ai_edit_img_remove_bg_use_click';
export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_USE_CLICK =
  'ai_edit_img_replace_bg_w_color_use_click';
export const AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_USE_CLICK =
  'ai_edit_img_replace_bg_w_image_use_click';

export const AI_EDIT_IMAGE_SMART_ERASE_CTA_CLICK =
  'ai_edit_img_smart_erase_cta_click';
export const AI_EDIT_IMAGE_SMART_ERASE_ERASE_CLICK =
  'ai_edit_img_smart_erase_canvas_erase_click';
export const AI_EDIT_IMAGE_SMART_ERASE_UNDO_CLICK =
  'ai_edit_img_smart_erase_canvas_undo_click';
export const AI_EDIT_IMAGE_SMART_ERASE_USE_CLICK =
  'ai_edit_img_smart_erase_use_click';

// AI GOOGLE SHOPPING CATEGORIES
export const AI_GOOGLE_SHOPPING_CATEGORIES_USE_CLICK =
  'ai_google_shopping_categories_use_click';

export const MARKETING_AUTOMATION_PAGE_VIEW = 'marketing_automation_page_view';
export const MARKETING_AUTOMATION_INSTALL_CLICK =
  'marketing_automation_install_click';
export const MENU_SECONDARY_MARKETING_AUTOMATION_CLICK =
  'menu_secondary_marketing_automation_click';
export const MENU_SECONDARY_MARKETING_APPS_CLICK =
  'menu_secondary_marketing_apps_click';
export const MARKETING_APPS_VIEW_DETAILS = 'marketing_apps_view_details';

export const CREATE_RELATED_PRODUCT = 'related_product_create';
export const UPDATE_RELATED_PRODUCT = 'related_product_edit';
export const CREATE_RELATED_PRODUCT_MODAL = 'related_product_modal_add_click';
export const UPDATE_RELATED_PRODUCT_MODAL = 'related_product_modal_edit_click';
export const CREATE_RELATED_PRODUCT_CONFIRM =
  'related_product_confirm_add_click';
export const UPDATE_RELATED_PRODUCT_CONFIRM =
  'related_product_confirm_edit_click';

// NUVEMCHAT
export const NUVEMCHAT_MENU_CLICK = 'nuvemchat_menu_click';

// DOMAIN SOFTBLOCK

export const SOFTBLOCK_NAMESERVER_SHOW = 'softblock-nameserver-show';
export const SOFTBLOCK_NAMESERVER_ERROR_SHOW = 'softblock-nameserver-error';
export const SOFTBLOCK_NAMESERVER_SKIP = 'softblock-nameserver-skip ';
export const SOFTBLOCK_NAMESERVER_UPDATED = 'softblock-nameserver-updated';
export const SOFTBLOCK_NAMESERVER_TUTORIAL = 'softblock-nameserver-tutorial';

//NEW ONBOARDING 202411
export const DASHBOARD_SEE_STORE = 'dashboard_see_store_click';
export const DASHBOARD_THEME_PERSONALIZE = 'dashboard_theme_personalize_click';
export const DASHBOARD_REGISTER_LOCATION = 'dashboard_register_location_click';
export const DASHBOARD_SEE_PREFERENCES_NUVEM_ENVIO =
  'dashboard_see_preferences_nuvem_envio_click';
export const DASHBOARD_SEE_PREFERENCES_ENVIO_NUBE =
  'dashboard_see_preferences_envio_nube_click';
export const DASHBOARD_SEE_PREFERENCES_NUVEM_PAGO =
  'dashboard_see_preferences_nuvem_pago_click';
export const DASHBOARD_SEE_PREFERENCES_PAGO_NUBE =
  'dashboard_see_preferences_pago_nube_click';
export const DASHBOARD_PAYMENT_OTHERS = 'dashboard_payment_others_click';
