import { useEffect } from 'react';
import { LoginResponseDto } from '@tiendanube/common';
import { setConfigKeyboard } from 'App/keyboard';
import { getStorage } from 'App/storage';

function useSetConfigKeyboard(): void {
  useEffect(() => {
    const fn = async () => {
      const data = await getStorage<LoginResponseDto>('auth');
      setConfigKeyboard(data);
    };
    fn();
  }, []);
}

export default useSetConfigKeyboard;
