import { useTranslation } from 'react-i18next';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';
import { emptyIsNullInText } from 'commons/utils';

interface CardBillingAddressProps {
  order: OrderDetailsResponseDto;
}

function CardBillingAddress({
  order: { billingAddress: billingAddress },
}: CardBillingAddressProps) {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  if (!billingAddress?.street) return null;

  const {
    name,
    phone,
    street,
    number,
    floor,
    zipcode,
    city,
    province,
    country,
  } = billingAddress;

  return (
    <Card isOpenByDefault title={t('cardBillingAddress.title')} isCollapsible>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{name}</Text>
          <Text block>{phone}</Text>
        </Stack.Item>

        <Stack.Item>
          <Text appearance="light" block>{`${t(
            'cardBillingAddress.address',
          )}`}</Text>
          <Text block>
            {`${street} ${emptyIsNullInText(number)} ${
              floor ?? ''
            }  (${zipcode})`}
          </Text>
          <Text block>{`${city}, ${province}, ${country}`}</Text>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CardBillingAddress;
