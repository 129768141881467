import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { useIsLogged } from 'domains/Auth/hooks';
import { refreshFreeTrialAction } from 'domains/Statistics/statisticsSlice';

function useRefreshStatisticsFreeTrial() {
  const dispatch = useAppDispatch();
  const { isLogin } = useIsLogged();

  const refreshFreeTrial = useCallback(() => {
    if (isLogin) dispatch(refreshFreeTrialAction());
  }, [dispatch, isLogin]);

  return refreshFreeTrial;
}

export default useRefreshStatisticsFreeTrial;
