export const formatDate = (dateISO: Date | string | undefined) => {
  if (!dateISO) return '';

  // Check if the date is already in the format DD/MM/YYYY to prevent reformatting
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (typeof dateISO === 'string' && dateFormatRegex.test(dateISO)) {
    return dateISO;
  }

  const date = new Date(dateISO);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
};

// Refference: // https://www.geradorcnpj.com/javascript-validar-cnpj.htm
export const validateCNPJ = (value: string) => {
  const disallowedCodes = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  // Elimina CNPJs invalidos conhecidos
  if (disallowedCodes.includes(value)) return false;

  // Valida DVs
  let size = value.length - 2;
  let nums = value.substring(0, size);
  const digit = value.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(nums.charAt(size - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (resultado !== parseInt(digit.charAt(0), 10)) {
    return false;
  }

  size += 1;
  nums = value.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(nums.charAt(size - i), 10) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  return resultado === parseInt(digit.charAt(1), 10);
};

// Refference: https://www.devmedia.com.br/validar-cpf-com-javascript/23916
export const validateCPF = (value: string) => {
  const code = value.replace(/[-.]/g, '');
  const disallowedCodes = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  // Elimina CNPJs invalidos conhecidos
  if (disallowedCodes.includes(code)) return false;

  let sum = 0;
  let diff;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(code.substring(i - 1, i), 10) * (11 - i);
  diff = (sum * 10) % 11;

  if (diff === 10 || diff === 11) diff = 0;
  if (diff !== parseInt(code.substring(9, 10), 10)) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(code.substring(i - 1, i), 10) * (12 - i);
  diff = (sum * 10) % 11;

  if (diff === 10 || diff === 11) diff = 0;
  return diff === parseInt(code.substring(10, 11), 10);
};

export const shouldRedirectBecauseOfFinishedChargeError = (
  errorMessage: string,
) => {
  const regex = /^Similar \S+ charge \S+ already exists$/;

  return (
    errorMessage ===
      'Process for updating plan charge should not be available for this store' ||
    errorMessage ===
      'Process for advancing plan charge should not be available for this store' ||
    regex.test(errorMessage)
  );
};
