import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Card,
  Input,
  Stack,
  InterfaceNameValue,
  Grid,
} from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import CountrySelect from 'commons/components/CountrySelect';

export interface DeliveryInfoValuesInterface {
  address: string;
  number: string;
  floor: string;
  locality: string;
  city: string;
  zipcode: string;
  province: string;
  country: string;
}

export interface DeliveryInfoErrorsInterface {
  address: string;
  number: string;
  floor: string;
  locality: string;
  city: string;
  zipcode: string;
  province: string;
}

interface DeliveryInfoCardProps {
  values: DeliveryInfoValuesInterface;
  errors?: Partial<DeliveryInfoErrorsInterface>;
  onChange: ({ value, name }: InterfaceNameValue) => void;
  onBlurZipCode: (data: InterfaceNameValue) => void;
}

function DeliveryInfoCard({
  values,
  errors,
  onChange,
  onBlurZipCode,
}: DeliveryInfoCardProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerForm.deliveryInformation.title')} isCollapsible>
      <Stack column align="stretch">
        <Stack.Item>
          <Input
            name="address"
            value={values.address}
            onChange={onChange}
            label={t('customerForm.deliveryInformation.street')}
          />
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={2} md={2} lg={2}>
                <InputNumber
                  type="integer"
                  name="number"
                  value={values.number}
                  onChange={onChange}
                  label={t('customerForm.deliveryInformation.number')}
                />
              </Grid.Column>
              <Grid.Column sm={4} md={4} lg={4}>
                <Input
                  name="floor"
                  value={values.floor}
                  onChange={onChange}
                  label={t('customerForm.deliveryInformation.floor')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={2} md={2} lg={2}>
                <Input
                  name="zipcode"
                  value={values.zipcode}
                  onChange={onChange}
                  label={t('customerForm.deliveryInformation.zipcode')}
                  appearance={errors?.zipcode ? 'validation_error' : 'default'}
                  onBlur={onBlurZipCode}
                />
              </Grid.Column>
              <Grid.Column sm={4} md={4} lg={4}>
                <Input
                  name="locality"
                  value={values.locality}
                  onChange={onChange}
                  label={t('customerForm.deliveryInformation.locality')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
        <Stack.Item>
          <Input
            name="city"
            value={values.city}
            onChange={onChange}
            label={t('customerForm.deliveryInformation.city')}
          />
        </Stack.Item>
        <Stack.Item>
          <Input
            name="province"
            value={values.province}
            onChange={onChange}
            label={t('customerForm.deliveryInformation.province')}
          />
        </Stack.Item>
        <Stack.Item>
          <CountrySelect value={values.country} onChange={onChange} />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCard;
