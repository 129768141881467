import getImageMimeType from '../getImageMimeType';

const ACCEPT_TYPE_FILE = 'image/jpeg,image/gif,image/png,image/webp';
const ACCEPT_TYPE_FILE_ARRAY = ACCEPT_TYPE_FILE.split(',');
const MAX_SIZE_IMAGE = 10;

export const BAD_IMAGE_TYPE = 'BAD_IMAGE_TYPE';
export const BAD_IMAGE_SIZE = 'BAD_IMAGE_SIZE';

const validateImage = async (imageFile: File) => {
  const mimeType = await getImageMimeType(imageFile);

  if (
    !ACCEPT_TYPE_FILE_ARRAY.includes(imageFile.type) ||
    !ACCEPT_TYPE_FILE_ARRAY.includes(mimeType)
  )
    return BAD_IMAGE_TYPE;

  if (imageFile.size / 1024 / 1024 >= MAX_SIZE_IMAGE) return BAD_IMAGE_SIZE;

  return null;
};

export default validateImage;
