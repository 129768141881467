import { useState } from 'react';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside';
import HelpLink from 'App/HelpLink';
import { toStatusType } from 'App/rtk';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  Stack,
} from 'commons/components';
import {
  DESIGNERS_DOCUMENTATION_LINKS,
  HELP_LINKS_THEMES_CSS,
} from 'commons/constants';
import { useToastStatus, useTranslationLanguage } from 'commons/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { openWindow } from 'commons/utils/window';
import { useEnableFtpMutation } from 'domains/Online/Themes/themesApi';
import { trackingThemeFtpEnable } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { useCheckFtpStatus } from '../../hooks';

interface EnableFtpModalProps {
  showModal: boolean;
  hasFtpConfigAvailable: boolean;
  onClose: () => void;
}

export function EnableFtpModal({
  showModal,
  hasFtpConfigAvailable,
  onClose,
}: Readonly<EnableFtpModalProps>) {
  const t = useTranslationOnline('themesPage.theme.enableFtpModal');
  const language = useTranslationLanguage();
  const { handleGoToPlans, isMobileNonFreemium } = useGoToPlans();
  const [enableFtp, { status: statusEnable }] = useEnableFtpMutation();
  const { checkFtpStatus } = useCheckFtpStatus();
  const [isLoading, setIsLoading] = useState(false);

  useToastStatus({
    status: toStatusType(statusEnable),
    error: t('error'),
  });

  const handleClickViewDocs = () => {
    openWindow(DESIGNERS_DOCUMENTATION_LINKS[language], true);
  };

  const handleClickEnableFtp = async () => {
    trackingThemeFtpEnable();
    setIsLoading(true);
    await enableFtp();
    checkFtpStatus({
      status: 'open',
      onSuccess: () => {
        setIsLoading(false);
        onClose();
      },
    });
  };

  return (
    <ModalAside
      isOpen={showModal}
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
      onDidDismiss={onClose}
    >
      {!hasFtpConfigAvailable && (
        <CalloutCard
          title={t('upgradePlanTitle')}
          subtitle={t('upgradePlanSubtitle')}
          appearance="primary"
          icon={RocketIcon}
          link={isMobileNonFreemium ? ' ' : undefined}
          onClick={!isMobileNonFreemium ? handleGoToPlans : undefined}
        />
      )}
      {hasFtpConfigAvailable && (
        <>
          <Alert appearance="warning" title={t('updatesWarningTitle')}>
            <Stack column align="flex-start">
              <Text>{t('updatesWarningSubtitle')}</Text>
              <Link as="a" onClick={handleClickViewDocs}>
                {t('viewDocs')} <ExternalLinkIcon />
              </Link>
            </Stack>
          </Alert>
          <CancelAndConfirmButtons
            confirmText={isLoading ? t('confirmLoading') : t('confirm')}
            isLoading={isLoading}
            isConfirmDisabled={!hasFtpConfigAvailable}
            onCancel={onClose}
            onConfirm={handleClickEnableFtp}
          />
        </>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_THEMES_CSS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </ModalAside>
  );
}
