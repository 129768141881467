import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  ShippingFallbackAvailableRequestDto,
  ShippingFallbackRequestDto,
  ShippingFallbacksResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  getFallbackAction,
  getFallbackSelector,
  getFallbackStatus,
  createFallbackAction,
  updateFallbackAction,
  cleanUpdateFallbackStatus,
  updateAvailableFallbackAction,
} from '../../../deliveryMethodsSlice';

export interface UseFallbackResult {
  fetchFallback: () => void;
  fallback: ShippingFallbacksResponseDto | null;
  getStatus: StatusType;
  updateStatus: StatusType;
  updateFallback: (payload: ShippingFallbackRequestDto) => Promise<void>;
  updateAvailableFallback: (
    payload: ShippingFallbackAvailableRequestDto,
  ) => Promise<void>;
}

function useFallback(): UseFallbackResult {
  const dispatch = useAppDispatch();
  const fallback = useSelector(getFallbackSelector);
  const { getStatus, updateStatus } = useSelector(getFallbackStatus);

  const fetchFallback = useCallback(() => {
    if (!fallback) {
      dispatch(getFallbackAction());
    }
  }, [dispatch, fallback]);

  const updateFallback = useCallback(
    async (payload: ShippingFallbackRequestDto) => {
      if (fallback) {
        await dispatch(
          updateFallbackAction({
            fallback: payload,
            id: fallback.id,
          }),
        );
      } else {
        await dispatch(createFallbackAction(payload));
      }
      dispatch(cleanUpdateFallbackStatus());
    },
    [dispatch, fallback],
  );

  const updateAvailableFallback = useCallback(
    async (payload: ShippingFallbackAvailableRequestDto) => {
      if (!fallback) {
        return;
      }

      await dispatch(
        updateAvailableFallbackAction({
          payload,
          id: fallback.id,
        }),
      );

      dispatch(cleanUpdateFallbackStatus());
    },
    [dispatch, fallback],
  );

  return {
    fetchFallback,
    fallback,
    getStatus,
    updateStatus,
    updateFallback,
    updateAvailableFallback,
  };
}
export default useFallback;
