import { useState } from 'react';
import {
  Box,
  Modal,
  Text,
  Button,
  Textarea,
  Alert,
} from '@nimbus-ds/components';
import { ProvideAiFeedbackRequestDto } from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import ModalAside from 'App/components/lab/ModalAside';
import { SubmitButton } from 'commons/components';
import { useToast, useTranslationCommon } from 'commons/hooks';
import useProvideAiFeedback from 'commons/hooks/useProvideAiFeedback';

export interface AiFeedbackModalProps {
  show?: boolean;
  title: string;
  text: string;
  feature: AiFeature;
  feedbackParams?: Partial<ProvideAiFeedbackRequestDto>;
  asAside?: boolean;
  onDismiss: () => void;
}
function AiFeedbackModal({
  show = true,
  title,
  text,
  feature,
  feedbackParams,
  asAside = false,
  onDismiss,
}: Readonly<AiFeedbackModalProps>) {
  const t = useTranslationCommon();
  const [feedback, setFeedback] = useState('');

  const { sendAiGenerationFeedback } = useProvideAiFeedback();
  const [sendingFeedback, setSendingFeedback] = useState(false);

  const [requestError, setRequestError] = useState('');
  const { addToast } = useToast();

  const handleOnDismiss = () => {
    setRequestError('');
    onDismiss();
  };

  const feedbackInputIsEmpty = !feedback.trim();

  const handleSendFeedback = async () => {
    if (feedbackInputIsEmpty) {
      handleOnDismiss();
      return;
    }
    try {
      setSendingFeedback(true);
      setRequestError('');
      await sendAiGenerationFeedback({
        feedback: feedback,
        feature,
        ...feedbackParams,
      });
    } catch (error) {
      setRequestError(t('aiFeedbackModal.requestFailedError'));
      setSendingFeedback(false);
      return;
    }
    setSendingFeedback(false);
    handleOnDismiss();
    addToast({
      label: t('aiFeedbackModal.tooltipThankYouMessage'),
      appearance: 'success',
    });
  };

  const handleChangeFeedback = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
  };

  const modalBody = (
    <Box
      marginTop="2"
      display="flex"
      flexDirection="column"
      gap="4"
      width="100%"
    >
      {!!requestError && <Alert appearance="warning">{requestError}</Alert>}
      <Text>{text}</Text>
      <Box display="flex" flexDirection="column" gap="1" width="100%">
        <Textarea
          id="feedback"
          name="feedback"
          type="text"
          value={feedback}
          lines={3}
          onChange={handleChangeFeedback}
          disabled={sendingFeedback}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap="4"
        width="100%"
      >
        <Button onClick={handleOnDismiss}>
          {t('aiFeedbackModal.buttons.cancel.text')}
        </Button>
        <SubmitButton
          onClick={handleSendFeedback}
          appearance="primary"
          disabled={sendingFeedback}
          isLoading={sendingFeedback}
        >
          {sendingFeedback
            ? t('aiFeedbackModal.buttons.sendDisabled.text')
            : t('aiFeedbackModal.buttons.send.text')}
        </SubmitButton>
      </Box>
    </Box>
  );

  return asAside ? (
    <ModalAside
      isOpen={show}
      onDidDismiss={handleOnDismiss}
      headerContent={title}
    >
      <Box>{modalBody}</Box>
    </ModalAside>
  ) : (
    <Modal open={show} onDismiss={handleOnDismiss}>
      <Modal.Header title={title} />
      <Modal.Body padding="none">{modalBody}</Modal.Body>
    </Modal>
  );
}

export default AiFeedbackModal;
