import { Alert, Text } from '@nimbus-ds/components';
import { dateFormat, Format } from 'commons/utils/date';
import { useExpirationDate } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PaymentStatusAlertProps } from '../../PaymentStatusAlert';

export default function PaymentStatusAlertPending({
  concept,
  isExternalContext,
  externalReference,
}: Readonly<PaymentStatusAlertProps>) {
  const t = useTranslationBilling('checkout.paymentStatusAlert.pending');
  const { expirationDate, appName } = useExpirationDate(
    concept,
    externalReference || undefined,
  );
  if (!expirationDate || isExternalContext) return null;
  if (concept === 'plan-cost') {
    return (
      <Alert appearance="warning" title={t('plan.title')}>
        <Text>
          {t('plan.message', {
            date: dateFormat(
              expirationDate.toISOString(),
              Format.DD_MM_YYYY,
              'UTC',
            ),
          })}
        </Text>
      </Alert>
    );
  }
  if (concept === 'app-cost') {
    return (
      <Alert appearance="warning">
        <Text>
          {t('apps.message', {
            date: dateFormat(
              expirationDate.toISOString(),
              Format.DAY_MONTH_LONG,
            ),
            appName,
          })}
        </Text>
      </Alert>
    );
  }

  return null;
}
