import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { fetchAuthenticationFactorQr as fetchAuthenticationFactorQrAction } from 'domains/Account/accountSlice';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { useTranslationAccount } from '../index';

function useAuthenticationFactorGenerateQrUrl(code: string): string {
  const dispatch = useAppDispatch();
  const t = useTranslationAccount();
  const { email } = useGetUserInfo();
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const fetchAuthenticationFactorQr = useCallback(async () => {
    const username = t('authenticationFactorPage.setUpAccountPage.username');
    const response = await dispatch(
      fetchAuthenticationFactorQrAction(
        `otpauth://totp/${username}: ${email}?secret=${code}`,
      ),
    );
    setQrCodeUrl(unwrapResult(response));
  }, [code, dispatch, email, t]);

  useEffect(() => {
    fetchAuthenticationFactorQr();
  }, [fetchAuthenticationFactorQr]);

  return qrCodeUrl;
}

export default useAuthenticationFactorGenerateQrUrl;
