import { SupportCardBody, SupportCardHeader } from './components';

interface SupportCardProps {
  onClose: () => void;
  onOpenChat: () => void;
}

export function SupportCard({
  onClose,
  onOpenChat,
}: Readonly<SupportCardProps>) {
  return (
    <>
      <SupportCardHeader />
      <SupportCardBody onOpenChat={onOpenChat} onClose={onClose} />
    </>
  );
}
