import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';

function AppsListDesktopHeader() {
  const { t } = useTranslationPartnerApps('appsListPage.table');

  return (
    <Table.Head>
      <CustomHeaderCell>{t('app')}</CustomHeaderCell>
      <CustomHeaderCell width="180px">{t('status')}</CustomHeaderCell>
      <CustomHeaderCell width="80px">{t('actions')}</CustomHeaderCell>
    </Table.Head>
  );
}

export default AppsListDesktopHeader;
