import { Table, Text } from '@nimbus-ds/components';
import { IconButton } from '@tiendanube/components';
import { CustomHeaderCell, Repeater } from 'commons/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

function PagesListResultDesktopSkeleton() {
  const t = useTranslationOnline('contentPages.pageList.headers');
  const handleBackgroundColor = (line: number) =>
    line % 2 === 0 ? 'neutral-background' : 'neutral-surface';

  const itemRow = (index: number) => (
    <Table.Row key={index} backgroundColor={handleBackgroundColor(index)}>
      <Table.Cell width="60%" padding="base">
        <Text.Skeleton width="150px" />
      </Table.Cell>
      <Table.Cell width="30%" padding="base">
        <Text.Skeleton width="75px" />
      </Table.Cell>
      <Table.Cell width="10%" padding="small">
        <IconButton.Skeleton />
      </Table.Cell>
    </Table.Row>
  );

  return (
    <Table>
      <Table.Head>
        <CustomHeaderCell width="60%">{t('page')}</CustomHeaderCell>
        <CustomHeaderCell width="30%">{t('lastUpdate')}</CustomHeaderCell>
        <CustomHeaderCell width="10%">{t('actions')}</CustomHeaderCell>
      </Table.Head>
      <Table.Body>
        <Repeater times={10} item={itemRow} />
      </Table.Body>
    </Table>
  );
}

export default PagesListResultDesktopSkeleton;
