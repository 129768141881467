import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, InterfaceInput } from '@tiendanube/components';
import { InputNumber } from 'commons/components';

interface IdentificationInputProps extends Partial<InterfaceInput> {
  value?: string;
  error?: string;
  onChange: ({ value, name }: { value: string; name: string }) => void;
}

function IdentificationInput({
  value,
  error,
  onChange,
}: IdentificationInputProps) {
  const { i18n, t } = useTranslation(Domain.ORDERS);

  if (i18n.language === 'es-MX') return null;

  return (
    <Stack.Item>
      <InputNumber
        type="integer"
        name="identification"
        label={t('draftOrders.newDraftOrder.clientData.identification')}
        value={value || ''}
        helpText={t(error || '')}
        appearance={error ? 'validation_error' : undefined}
        onChange={onChange}
      />
    </Stack.Item>
  );
}

export default IdentificationInput;
