import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Button, DataList, Input, Stack, Text } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { getSubtotal, getTotal } from './amountUtils';
import { OrderSummaryValuesInterface } from '../..';

interface OrderSummaryAmountsProps {
  values: OrderSummaryValuesInterface;
  onChange: ({ value, name }: { value: string; name: string }) => void;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function OrderSummaryAmounts({
  values,
  onChange,
}: OrderSummaryAmountsProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const { symbol } = useGetCurrency();

  const prevSubtotal = usePrevious(values.subtotal);
  const prevTotal = usePrevious(values.total);

  useEffect(() => {
    if (
      Number(prevSubtotal) === getSubtotal(values.products) &&
      Number(prevTotal) ===
        getTotal(
          getSubtotal(values.products),
          values.discount,
          values.discountType,
          values.shipping,
        )
    )
      return;

    onChange({
      value: getSubtotal(values.products).toString(),
      name: 'subtotal',
    });
    onChange({
      value: getTotal(
        getSubtotal(values.products),
        values.discount,
        values.discountType,
        values.shipping,
      ).toString(),
      name: 'total',
    });
  }, [prevSubtotal, prevTotal, values, onChange]);

  return (
    <DataList ruled={false} spacing="tight">
      <DataList.Row id="subtotal" grayed>
        <DataList.Cell width={70}>
          <Text>{`${t(
            'draftOrders.newDraftOrder.orderSummary.subtotal',
          )}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice price={values.subtotal} block textAlign="right" bold />
        </DataList.Cell>
      </DataList.Row>
      <DataList.Row id="discount">
        <DataList.Cell width={70}>
          <Stack column spacing="extra-tight" align="flex-start">
            <Text>{`${t(
              'draftOrders.newDraftOrder.orderSummary.discount',
            )}`}</Text>
            <Stack spacing="tight" justify="flex-end">
              <Button
                onClick={() =>
                  onChange({ value: 'percentage', name: 'discountType' })
                }
                appearance={
                  values.discountType === 'percentage' ? 'primary' : 'default'
                }
                iconSize="small"
              >
                {`${t(
                  'draftOrders.newDraftOrder.orderSummary.discountType.percentage',
                )}`}
              </Button>
              <Button
                onClick={() =>
                  onChange({ value: 'absolute', name: 'discountType' })
                }
                appearance={
                  values.discountType === 'absolute' ? 'primary' : 'default'
                }
                iconSize="small"
              >
                {`${t(
                  'draftOrders.newDraftOrder.orderSummary.discountType.fixed',
                )}`}
              </Button>
            </Stack>
          </Stack>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <Input
            type="number"
            name="discount"
            value={values.discount ? values.discount.toString() : ''}
            onChange={onChange}
            placeholder="-"
            prependLabel={values.discountType === 'absolute' ? '$' : ''}
            appendLabel={values.discountType === 'percentage' ? '%' : ''}
          />
        </DataList.Cell>
      </DataList.Row>
      <DataList.Row id="shipping">
        <DataList.Cell width={70}>
          <Text>{`${t(
            'draftOrders.newDraftOrder.orderSummary.shipping',
          )}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <Input
            type="number"
            name="shipping"
            value={values.shipping ? values.shipping.toString() : ''}
            onChange={onChange}
            placeholder={`${symbol}0.00`}
          />
        </DataList.Cell>
      </DataList.Row>
      <DataList.Row id="total" grayed>
        <DataList.Cell width={70}>
          <Text bold>{`${t(
            'draftOrders.newDraftOrder.orderSummary.total',
          )}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice price={values.total} block textAlign="right" bold />
        </DataList.Cell>
      </DataList.Row>
    </DataList>
  );
}

export default OrderSummaryAmounts;
