import { Icon, Table, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { PaymentMethodType, PaymentProviderDto } from '@tiendanube/common';
import { Link } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { PaymentMethodInlineLabel } from 'domains/Payments/PaymentProviders/components';
import {
  TRANSLATE_PAYMENT_PREFIX,
  providersShowingLabels,
} from 'domains/Payments/PaymentProviders/components/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { PaymentMethodsHeader } from './PaymentMethodsHeader';

interface PaymentMethodsEmptyTableProps {
  provider: PaymentProviderDto;
}

export function PaymentMethodsEmptyTable({
  provider: { id, ratesUrl, name },
}: Readonly<PaymentMethodsEmptyTableProps>) {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);

  const provider = providersShowingLabels.find(
    ({ provider }) => provider === id,
  );

  return (
    <div className="stratus--payment-provider-mobile-table">
      <Stack column align="flex-start">
        <Stack spacing="tight">
          <Icon source={<InfoCircleIcon size="small" />} />
          <Text>
            <Trans
              t={t}
              i18nKey="alertEmptyRate"
              values={{ paymentProvider: name }}
              components={{
                TyCLink: <Link underline href={ratesUrl} target="_blank" />,
              }}
            />
          </Text>
        </Stack>
        <Stack column align="flex-start" gap="1">
          {provider && (
            <PaymentMethodInlineLabel
              paymentMethods={provider.labels as PaymentMethodType[]}
              providerId={id}
            />
          )}
          <Table>
            <PaymentMethodsHeader />
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <div className="payment-method-cell">
                    <Text>-</Text>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="payment-method-cell">
                    <Text>-</Text>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="payment-method-cell">
                    <Stack justify="flex-end">
                      <Text>-</Text>
                    </Stack>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Stack>
      </Stack>
    </div>
  );
}
