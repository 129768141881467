import { Card, Radio } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function Skeleton() {
  const t = useTranslationMarketing('coupons.couponForm.discountType');

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack>
          <Radio.Skeleton as="button" />
          <Radio.Skeleton as="button" />
          <Radio.Skeleton as="button" />
        </Stack>
      </Card.Body>
    </Card>
  );
}
