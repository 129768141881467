import { useEffect, useState } from 'react';
import { Spinner } from 'commons/components';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import xAxisFormatDate from 'domains/Statistics/utils/xAxisFormatDate';
import { CombinedChart, CombinedSummary } from './components';
import { CombinedBarChartAndSummaryCardProps } from './types';
import { noopFormat, noopFormatValueSummary, getSortedSelected } from './utils';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import DoubleCardResponsive from '../DoubleCardResponsive';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';

/**
 * This component renders the combined bar chart based on the defined data structure.
 * At the same time, it renders the data summary and displays the compared bar charts as selected by the user.
 * @param formatLabelTooltip - This callback receives each of the TKeys to format for the title that corresponds to the summary item.
 * @param formatSummaryTitle - This callback receives the each of the TKeys to format by the title that corresponds to the default tooltip.
 * @param formatValueSummary - Two properties are received in this callback:
 * value: The value of the summary["key"].value property. This value is what is displayed in the summary.
 * key: The TKeys that is currently iterating. This property is used to identify the type of format that is desired for the value (currency, thousands, etc).
 */

function CombinedBarChartAndSummaryCard<TKeys extends string>({
  selectedPrimary,
  title,
  colors,
  isLoading,
  isError,
  isSuccess,
  data,
  fetchData,
  hasComparedFilter,
  isEmpty = false,
  statsPlanRequired,
  upsellSource,
  formatLabelTooltip = noopFormat,
  formatSummaryTitle = noopFormat,
  onOpenFilter,
  formatValueSummary = noopFormatValueSummary,
}: Readonly<CombinedBarChartAndSummaryCardProps<TKeys>>) {
  const [selecteds, setSelecteds] = useState<string[]>([selectedPrimary]);
  const [principal, compared] = getSortedSelected(selecteds, colors);
  const showValues = useSupportsStatsPlan(statsPlanRequired);

  const getFormatedDate = xAxisFormatDate(data.granularity);

  useEffect(() => {
    if (hasComparedFilter) setSelecteds([selectedPrimary]);
  }, [hasComparedFilter, selectedPrimary]);

  const handleOnSelect = (selected: string) => {
    setSelecteds((prev) => {
      // 👀 when we have data compared by date range, we cannot select more than two 👇
      if (hasComparedFilter) return [selected];
      if (prev.includes(selected)) {
        const currentSelected = prev.filter((item) => item !== selected);
        // 👀 we avoid empty array in the selected state 👇
        return currentSelected.length > 0 ? currentSelected : prev;
      }
      return [...prev, selected];
    });
  };

  return (
    <DoubleCardResponsive
      title={title}
      left={
        <ChartOrPlaceholder
          type="bars-stacked"
          statsPlanRequired={statsPlanRequired}
          upsellSource={upsellSource}
        >
          {isLoading && <Spinner />}
          {isError && <GenericError onRetry={fetchData} />}
          {isSuccess && isEmpty && (
            <GenericEmptySearch onRetry={onOpenFilter} />
          )}
          {isSuccess && !isEmpty && (
            <CombinedChart
              data={data}
              colors={colors}
              selectedPrimary={principal}
              selectedSecondary={compared}
              hasComparedFilter={hasComparedFilter}
              formatLabelTooltip={formatLabelTooltip}
              xAxisFormatter={getFormatedDate}
            />
          )}
        </ChartOrPlaceholder>
      }
      right={
        <CombinedSummary<TKeys>
          colors={colors}
          totals={data.summary}
          showValues={showValues}
          onChange={handleOnSelect}
          selecteds={selecteds}
          formatValue={formatValueSummary}
          formatSummaryTitle={formatSummaryTitle}
          isMaxSelected={selecteds.length >= 2}
          dataHasComparison={hasComparedFilter}
        />
      }
    />
  );
}

export default CombinedBarChartAndSummaryCard;
