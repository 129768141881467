import { ErrorState } from '@tiendanube/components';
import useTranslationSalesChannels from 'domains/SalesChannels/useTranslationSalesChannels';

interface MarketplacesAppsPageErrorProps {
  getMarketplacesApps: () => void;
}

function MarketplacesAppsPageError({
  getMarketplacesApps,
}: MarketplacesAppsPageErrorProps) {
  const t = useTranslationSalesChannels('marketplacesApps');

  return (
    <ErrorState
      title={t('errorState.title')}
      action={{
        children: t('errorState.retry'),
        onClick: getMarketplacesApps,
      }}
    />
  );
}

export default MarketplacesAppsPageError;
