import { Box } from '@nimbus-ds/components';
import { ErrorScreen, HeaderContent, IonPageStratus } from 'commons/components';
import { useGetPreferencesCheckoutQuery } from './SettingsCheckoutApi';
import {
  SettingsCheckoutForm,
  SettingsCheckoutFormSkeleton,
} from './SettingsCheckoutForm';
import useTranslationConfigurations from '../useTranslationConfigurations';

export function SettingsCheckoutPage() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm',
  );
  const { data, isFetching, isLoading, isError, refetch } =
    useGetPreferencesCheckoutQuery();

  const isSkeleton = isFetching || isLoading;

  const headerContent = (
    <HeaderContent title={t('title')} subtitle={t('subTitle')} />
  );

  if (data) return <SettingsCheckoutForm data={data} />;

  return (
    <IonPageStratus width="small" headerContent={headerContent}>
      <Box display="flex" flexDirection="column" gap="4">
        {isSkeleton && <SettingsCheckoutFormSkeleton />}
        {isError && (
          <ErrorScreen onRetry={refetch} description={t('errorState')} />
        )}
      </Box>
    </IonPageStratus>
  );
}
