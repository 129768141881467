import { MIGRATION_OLD_FREE_SHIPPING } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import { useFreeShippingStatus } from './useFreeShippingStatus';

export function useHasFreeShippingEnabled() {
  const tags = useGetTags();
  const hasOldFreeShipping = tags.includes(MIGRATION_OLD_FREE_SHIPPING);
  const { available: availableFreeShipping } = useFreeShippingStatus();
  return { hasOldFreeShipping, availableFreeShipping };
}
