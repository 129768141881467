import { Card, Title } from '@nimbus-ds/components';
import { MetafieldV2Dto } from '@tiendanube/common';
import { MetafieldItem } from 'domains/Metafields/components/MetafieldInput';
import { SourceType } from 'domains/Metafields/types';

interface ModalOrderCardProps {
  metafields: MetafieldV2Dto[];
  filledMetafields: { id: string; value: string }[];
  handleChange: (id: string, value: string) => void;
  hasTitle: boolean;
  title: string;
}

export default function ModalOrderCard({
  metafields,
  filledMetafields,
  handleChange,
  hasTitle,
  title,
}: ModalOrderCardProps) {
  return (
    <Card>
      {hasTitle && (
        <Card.Header>
          <Title as="h3">{title}</Title>
        </Card.Header>
      )}
      {metafields?.map((metafield) => {
        const found = filledMetafields.find((m) => m.id === metafield.id);
        const value = found?.value || '';
        return (
          <MetafieldItem
            key={metafield.id}
            metafield={metafield}
            value={value}
            source={SourceType.ADMIN}
            onChange={handleChange}
          />
        );
      })}
    </Card>
  );
}
