import HelpLink from 'App/HelpLink';
import { HELP_LINKS_FREE_SHIPPING } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function FreeShippingHelpLink() {
  const t = useTranslationMarketing('freeShipping');
  const language = useTranslationLanguage();

  return (
    <HelpLink
      title={t('helpLink')}
      linkURL={HELP_LINKS_FREE_SHIPPING[language]}
      icon="both"
      currentViewTracking=""
      previousValue=""
    />
  );
}
