/* eslint-disable max-statements */
import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { Action } from '@tiendanube/common/src/enums';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { DEFAULT_LAST_30_DAYS } from 'App/featuresFlags';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc, useListFilters, useToast } from 'commons/hooks';
import { getTodayIsoString, subtractDays } from 'commons/utils/date';
import { useGetTags } from 'domains/Auth/hooks';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  defaultFilters,
  FiltersParamsType,
  FiltersType,
  InterfaceOrderUpdateParams,
} from '../../ordersService';
import {
  fetchMoreOrders,
  fetchOrders,
  updateOrders as updateOrdersAction,
} from '../../ordersSlice';
import {
  getAppliedFilters,
  getOrdersCount,
  getOrdersIds,
  getOrdersPagination,
  getOrdersStatus,
  getOrdersStatusSuccess,
} from '../../ordersSlice/ordersSelectors';
import { useGetTotalOpenedOrders } from '../useGetTotalOpenedOrders';

interface UseOrdersListResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  statusSuccess: string;
  ordersCount: number;
  totalOpenOrders: number;
  ordersIds: string[];
  pagination: PaginationResponseDto;
  filters: FiltersType;
  appliedFilters: FiltersType;
  hasFilters: boolean;
  getOrdersList: (filters?: FiltersParamsType) => Promise<void>;
  refreshOrdersList: () => Promise<void>;
  getMoreOrders: () => Promise<void>;
  goToPage: (page: number) => Promise<void>;
  updateOrders: (params: InterfaceOrderUpdateParams) => Promise<void>;
  changeFilters: (filters: FiltersType) => void;
  removeMetafieldsFilters: () => void;
}

const TYPE_FETCH_ORDERS = 'advanced';

function useOrdersList(): UseOrdersListResult {
  const dispatch = useAppDispatch();
  const t = useTranslationOrders();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const tags = useGetTags();
  const hasDefaultFilterLimit = tags.includes(DEFAULT_LAST_30_DAYS);

  const { filters, hasFilters, changeFilters, removeMetafieldsFilters } =
    useListFilters('orders', defaultFilters);

  const { isLoading, isSuccess, isError } = useSelector(getOrdersStatus);
  const statusSuccess = useSelector(getOrdersStatusSuccess);
  const ordersCount = useSelector(getOrdersCount);
  const ordersIds = useSelector(getOrdersIds);
  const pagination = useSelector(getOrdersPagination);
  const { setDisabled } = useBulkActions();
  const appliedFilters = useSelector(getAppliedFilters);
  const { totalOpenedOrders: totalOpenOrders } = useGetTotalOpenedOrders();

  const setLast30Days = useCallback(
    (customFilters) => {
      if (!hasDefaultFilterLimit) return customFilters;
      const today = getTodayIsoString();
      const lastMonth = subtractDays(today, 30);

      return {
        ...customFilters,
        dateFrom: lastMonth,
      };
    },
    [hasDefaultFilterLimit],
  );

  const getOrdersList = useCallback(
    async (customFilters?: FiltersParamsType) => {
      const fetchFilters = customFilters
        ? { ...customFilters, page: 1, perPage: ORDERS_PER_PAGE }
        : setLast30Days(filters);

      if (!isEqual(fetchFilters, appliedFilters) || appliedFilters.page === 1) {
        const filtersDigest = Object.fromEntries(
          Object.entries(fetchFilters).filter(
            ([key, value]) =>
              (value !== 'all' && value !== 'open') ||
              (value === 'all' && key === 'status'),
          ),
        ) as FiltersType;
        changeFilters(filtersDigest);
        await dispatch(
          fetchOrders({ filters: fetchFilters, type: TYPE_FETCH_ORDERS }),
        );
      }
    },
    [filters, appliedFilters, changeFilters, dispatch, setLast30Days],
  );

  const refreshOrdersList = useCallback(async () => {
    const filtersWithFirstPage = {
      ...filters,
      page: 1,
      perPage: ORDERS_PER_PAGE.toString(),
    };
    changeFilters(filtersWithFirstPage);
    await dispatch(
      fetchOrders({ filters: filtersWithFirstPage, type: TYPE_FETCH_ORDERS }),
    );
  }, [filters, changeFilters, dispatch]);

  const getMoreOrders = useCallback(async () => {
    if (ordersIds.length !== filters.page * ORDERS_PER_PAGE) return;
    const newFilters = {
      ...filters,
      page: filters.page + 1,
      perPage: ORDERS_PER_PAGE.toString(),
    };
    changeFilters(newFilters);
    await dispatch(
      fetchMoreOrders({ filters: newFilters, type: TYPE_FETCH_ORDERS }),
    );
  }, [changeFilters, dispatch, filters, ordersIds.length]);

  const goToPage = useCallback(
    async (page: number) => {
      if (filters.page === page) return;
      const newFilters = {
        ...filters,
        page,
        perPage: ORDERS_PER_PAGE.toString(),
      };
      changeFilters(newFilters);
      await dispatch(
        fetchOrders({ filters: newFilters, type: TYPE_FETCH_ORDERS }),
      );
    },
    [changeFilters, dispatch, filters],
  );

  const updateOrdersError = (params) => {
    setDisabled(false);
    showFeedbackToast('error', params?.actionType);
  };

  const [updateOrders] = useAsyncFunc<InterfaceOrderUpdateParams, void>(
    async (params) => {
      if (!params) return;
      showFeedbackToast('loading', params.actionType);
      const result = unwrapResult(await dispatch(updateOrdersAction(params)));
      showFeedbackToast('success', params.actionType);
      return result;
    },
    () => getOrdersList(),
    updateOrdersError,
  );

  const showFeedbackErrorToast = (action) => {
    const errorMessage =
      action && [Action.ARCHIVE, Action.CANCEL].includes(action)
        ? action
        : 'default';
    addToast({
      label: t(`bulkActions.toast.error.${errorMessage}`),
      appearance: 'danger',
    });
  };

  const showFeedbackToast = (
    type: 'loading' | 'success' | 'error',
    action?: Action,
  ) => {
    if (type === 'loading') {
      addToastProgress({ label: t(`bulkActions.toast.loading.${action}`) });
    } else {
      closeToastProgress();
      if (type === 'success')
        addToast({
          label: t(`bulkActions.toast.success.${action}`),
          appearance: 'success',
        });
      else {
        showFeedbackErrorToast(action);
      }
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    statusSuccess,
    ordersCount,
    totalOpenOrders,
    ordersIds,
    pagination,
    filters,
    appliedFilters,
    hasFilters,
    getOrdersList,
    refreshOrdersList,
    getMoreOrders,
    goToPage,
    updateOrders,
    changeFilters,
    removeMetafieldsFilters,
  };
}

export default useOrdersList;
