import { useEffect, useState } from 'react';
import { Box, Icon, Link, Popover, Text } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon, LocationIcon } from '@nimbus-ds/icons';
import { LocationResponseDto } from '@tiendanube/common';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingInventoryListSelectorClick } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { LocationEntry } from './components';

interface LocationSelectorProps {
  selectedLocationId?: string;
  onSelectionChange: (locationId: string) => void;
}

function LocationSelector({
  selectedLocationId,
  onSelectionChange,
}: LocationSelectorProps): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);

  const t = useTranslationCatalog();

  const { locationMain, locationsOthers, fetchLocations } = useLocations();

  const sender = useTrackFullCatalog();

  useEffect(() => {
    if (!locationMain) {
      fetchLocations();
    }
  }, [fetchLocations, locationMain]);

  const onLocationChange = (location: LocationResponseDto) => {
    sender(trackingInventoryListSelectorClick);
    onSelectionChange(location.id);
    setIsOpen(false);
  };

  if (!selectedLocationId || !locationMain || locationsOthers.length === 0)
    return null;

  const selectedLocation =
    locationsOthers.find((location) => location.id === selectedLocationId) ||
    locationMain;

  return (
    <Box display="flex" justifyContent="flex-start" paddingBottom="2">
      <Popover
        arrow={false}
        position="bottom-start"
        padding="small"
        visible={isOpen}
        onVisibility={setIsOpen}
        content={
          <Box display="flex" gap="1" flexDirection="column" width="100%">
            {[locationMain, ...locationsOthers].map((location) => (
              <LocationEntry
                key={location.id}
                location={location}
                isSelected={location.id === selectedLocation?.id}
                onSelectionChange={onLocationChange}
              />
            ))}
          </Box>
        }
      >
        <Link appearance="neutral" textDecoration="none">
          <Box display="flex" gap="2">
            <Icon
              color="neutral-textHigh"
              source={<LocationIcon />}
              cursor="pointer"
            />
            <Box display="flex" gap="1" as="span">
              <Text
                as="span"
                fontSize="highlight"
                lineHeight="highlight"
                color="neutral-textLow"
              >
                {t('inventory.inLocation')}
              </Text>
              <Text
                fontSize="highlight"
                lineHeight="highlight"
                as="span"
                fontWeight="medium"
                color="neutral-textHigh"
              >
                {selectedLocation?.name}
              </Text>
            </Box>
            <Icon
              color="neutral-textHigh"
              source={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              cursor="pointer"
            />
          </Box>
        </Link>
      </Popover>
    </Box>
  );
}

export default LocationSelector;
