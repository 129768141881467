import { Box, Button, IconButton, Text } from '@nimbus-ds/components';
import { NotificationIcon, QuestionCircleIcon } from '@nimbus-ds/icons';
import { Avatar } from 'App/components/Topbar/components';

interface MainMenuMobileHeaderProps {
  onClickNews: () => void;
  onClickProfile: () => void;
  onClickSupport: () => void;
  name: string;
}

export function MainMenuMobileHeader({
  onClickProfile,
  onClickSupport,
  onClickNews,
  name,
}: Readonly<MainMenuMobileHeaderProps>) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding="2"
      backgroundColor="neutral-surface"
    >
      <Box display="flex" flexDirection="row">
        <IconButton
          source={<NotificationIcon size={18} />}
          backgroundColor="transparent"
          borderColor={{
            xs: 'transparent',
          }}
          data-style="icon-button-override"
          onClick={onClickNews}
          tabIndex={-1}
        />

        <IconButton
          source={<QuestionCircleIcon size={18} />}
          backgroundColor="transparent"
          borderColor={{
            xs: 'transparent',
          }}
          data-style="icon-button-override"
          onClick={onClickSupport}
          tabIndex={-1}
        />
      </Box>
      <Box>
        <Button onClick={onClickProfile} tabIndex={-1}>
          <Avatar name={name} />
          <Box maxWidth="270px">
            <Text
              fontWeight="medium"
              color="neutral-textHigh"
              lineClamp={1}
              wordBreak="break-all"
            >
              {name}
            </Text>
          </Box>
        </Button>
      </Box>
    </Box>
  );
}
