import { Alert, Button, Text } from '@nimbus-ds/components';
import { addDays, isAfter } from 'date-fns';
import { Link } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { useStorage } from 'commons/hooks';
import { useGetStoreInfo } from 'domains/Auth/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

const POS_URL_PATH = '/pos';
const STORAGE_KEY = 'draft-orders-pos-plg-date';
const ALERT_DISMISS_DAYS = 30;

export function DraftOrdersPOSAlert() {
  const t = useTranslationOrders();
  const { id } = useGetStoreInfo();
  const { pathname } = useNavegate();

  const [posNotifyDate, setPosNotifyDate, isLoaded] = useStorage<number | null>(
    `${STORAGE_KEY}-${id}`,
    null,
  );

  const hasExpired =
    !!posNotifyDate && isAfter(new Date(), new Date(posNotifyDate));

  const showAlert = isLoaded && (!posNotifyDate || hasExpired);
  const expirationDate = addDays(new Date(), ALERT_DISMISS_DAYS).getTime();

  const params = new URLSearchParams({ source: pathname });
  const urlWithParams = `${POS_URL_PATH}?${params}`;

  const handleAlertDismiss = () => {
    setPosNotifyDate(expirationDate);
  };

  return (
    <Alert
      appearance="primary"
      show={showAlert}
      title={t('draftOrders.posAlert.title')}
      onRemove={handleAlertDismiss}
    >
      <Text>{t('draftOrders.posAlert.description')}</Text>
      <Button as={Link} to={urlWithParams} onClick={handleAlertDismiss}>
        {t('draftOrders.posAlert.cta')}
      </Button>
    </Alert>
  );
}
