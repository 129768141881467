import { Box, Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { PopoverToogle, Stack } from 'commons/components';
import { Scope } from 'domains/Marketing/enums';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { ShowMoreScope } from 'domains/Marketing/Promotions/components';

type ConditionsProps = Pick<
  PromotionsItemListResponseDto,
  'scope' | 'scopeType' | 'scopeVisibility' | 'validity'
>;

export function Limits({
  scope,
  scopeType,
  validity,
  scopeVisibility,
}: Readonly<ConditionsProps>) {
  const t = useTranslationMarketing('promotions.conditions');
  const countScopeLimit = scopeType !== Scope.ALL ? 1 : 0;
  const countValidityLimit = validity.split('-').length === 2 ? 1 : 0;
  const totalLimits = countScopeLimit + countValidityLimit;

  return (
    <PopoverToogle
      label={t('details.limited', { count: totalLimits })}
      content={
        <Box flexGrow="1">
          <Stack column align="flex-start" gap="4">
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('scope')}</Text>
              <ShowMoreScope
                scope={scope}
                scopeType={scopeType}
                scopeVisibility={scopeVisibility}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('validity')}</Text>
              <Text>{validity}</Text>
            </Box>
          </Stack>
        </Box>
      }
    />
  );
}
