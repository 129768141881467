import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  fetchTotalAllOrders,
  getTotalResultsAllOrders,
} from '../../ordersSlice';

function useTotalOrders() {
  const dispatch = useAppDispatch();
  const { validateRoles } = useRoles();
  const hasOrderRole = validateRoles('orders');

  const [isLoadingHasOrders, setIsLoadingHasOrders] = useState(true);

  const totalAllOrders = useSelector(getTotalResultsAllOrders);

  const hasOrder = totalAllOrders > 0;

  const getTotalAllOrders = useCallback(async () => {
    if (hasOrderRole && !hasOrder) await dispatch(fetchTotalAllOrders());
    setIsLoadingHasOrders(false);
  }, [dispatch, hasOrder, hasOrderRole, setIsLoadingHasOrders]);

  useEffect(() => {
    getTotalAllOrders();
  }, [getTotalAllOrders, hasOrder]);

  return {
    isLoadingHasOrders,
    hasOrder,
    totalAllOrders,
  };
}

export default useTotalOrders;
