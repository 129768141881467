import { Route } from 'react-router-dom';
import BillingCouponsPage from './pages/CouponsPage/CouponsPage';
import { BILLING_ROUTES } from '../billingRoutes';

function BillingCoupons() {
  return (
    <Route exact path={BILLING_ROUTES.coupons}>
      <BillingCouponsPage />
    </Route>
  );
}

export default BillingCoupons;
