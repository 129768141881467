import { useState } from 'react';
import {
  Alert,
  Card,
  Text,
  List,
  Box,
  Tag,
  Title,
  Button,
  Icon,
  Link,
} from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { SubmitButton } from 'commons/components';
import { TOP_ADBAR_LINKS } from 'commons/constants/externalLinks';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useCloudflareBlocked from 'domains/Dashboard/hooks/useCloudflareBlocked';
import {
  trackingSoftblockSkipClick,
  trackingSoftblockTutorialClick,
  trackingSoftblockUpdatedClick,
} from 'domains/Dashboard/tracking';

interface CloudflareBlockCardProps {
  hardBlock: boolean;
  onDismiss: () => Promise<void>;
}

function CloudflareBlockCard({
  hardBlock,
  onDismiss,
}: CloudflareBlockCardProps) {
  const t = useTranslationDashboard();
  const language = useTranslationLanguage();
  const tutorialUrl = TOP_ADBAR_LINKS[language];

  const { validateIPs } = useCloudflareBlocked();

  const [validatorRunning, setValidatorRunning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onClickUpdated = async () => {
    trackingSoftblockUpdatedClick();
    setShowError(false);
    setValidatorRunning(true);

    try {
      const migrationSuccess = await validateIPs();
      if (migrationSuccess) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
    } catch (err) {
      // Failed to connect to backend, but nothing to do here
    } finally {
      setValidatorRunning(false);
    }
  };

  const onClickTutorial = () => {
    trackingSoftblockTutorialClick();
  };

  const onClickDismiss = () => {
    trackingSoftblockSkipClick();
    onDismiss();
  };

  return (
    <>
      {showSuccess || (
        <Card>
          <Card.Header>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Title as="h3">{t('cloudflareBlock.title')}</Title>
              <Tag appearance="danger">{t('cloudflareBlock.tag')}</Tag>
            </Box>
          </Card.Header>
          <Card.Body>
            <Text>{t('cloudflareBlock.description')}</Text>
            <Box paddingTop="4" paddingBottom="4">
              <List>
                <List.Item>{t('cloudflareBlock.advantage1')}</List.Item>
                <List.Item>{t('cloudflareBlock.advantage2')}</List.Item>
                <List.Item>{t('cloudflareBlock.advantage3')}</List.Item>
              </List>
            </Box>
            <Text>
              <Trans
                t={t}
                i18nKey="cloudflareBlock.alert"
                components={{
                  strong: <strong />,
                }}
              />
            </Text>
            {showError && (
              <Box paddingTop="4">
                <Alert
                  appearance="warning"
                  title={t('cloudflareBlock.error.title')}
                >
                  <Text>{t('cloudflareBlock.error.text')}</Text>
                  <Link as="a" href={tutorialUrl} target="_blank">
                    {t('cloudflareBlock.error.link')}
                  </Link>
                </Alert>
              </Box>
            )}
            <Box
              paddingTop="4"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Box display="flex" gap="2" paddingBottom="2">
                <SubmitButton
                  onClick={onClickUpdated}
                  appearance="neutral"
                  isLoading={validatorRunning}
                >
                  {t('cloudflareBlock.ctaDone')}
                </SubmitButton>
                <Button
                  as="a"
                  appearance="primary"
                  href={tutorialUrl}
                  target="_blank"
                  onClick={onClickTutorial}
                >
                  {t('cloudflareBlock.ctaTutorial')}
                  <Icon color="currentColor" source={<ExternalLinkIcon />} />
                </Button>
              </Box>
              {hardBlock || (
                <Link onClick={onClickDismiss}>
                  {t('cloudflareBlock.ctaDismiss')}
                </Link>
              )}
            </Box>
          </Card.Body>
        </Card>
      )}
      {showSuccess && (
        <Card>
          <Card.Header>
            <Title as="h3">{t('cloudflareBlock.success.title')}</Title>
          </Card.Header>
          <Card.Body>
            <Alert appearance="success">
              {t('cloudflareBlock.success.text')}
            </Alert>
          </Card.Body>
          <Card.Footer>
            <Button onClick={onDismiss}>
              {t('cloudflareBlock.success.link')}
            </Button>
          </Card.Footer>
        </Card>
      )}
    </>
  );
}

export default CloudflareBlockCard;
