import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
} from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import TransactionListDesktop from './components/TransactionListDesktop';
import TransactionListMobile from './components/TransactionListMobile';
import { useTransactionListDate } from './hooks';
import { getMonthName } from '../utils';

export interface TransactionFeesListProps {
  split: boolean;
}

function TransactionFeesListPage({
  split,
}: TransactionFeesListProps): JSX.Element {
  const { paymentId } = useParams<{ paymentId: string }>();
  const t = useTranslationBilling();
  const history = useHistory();

  const goToBack = () => {
    history.goBack();
  };

  const backNavigation = {
    onClick: goToBack,
  };

  const transactionsDate = useTransactionListDate(split, paymentId);
  const lang = useGetLanguage();

  const month = transactionsDate ? getMonthName(transactionsDate, lang) : '';
  const year = transactionsDate ? transactionsDate.getFullYear() : '';

  const splitPath = split ? 'split' : 'noSplit';
  const paymentPath = paymentId ? 'forPayment' : 'forNextSettlement';
  const title = t(`transactionFeesList.${splitPath}.${paymentPath}.title`);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent title={title} subtitle={`${month} ${year}`} />
      }
      width="small"
    >
      <Responsive
        mobile={<TransactionListMobile split={split} paymentId={paymentId} />}
        desktop={<TransactionListDesktop split={split} paymentId={paymentId} />}
      />
    </IonPageStratus>
  );
}

export default TransactionFeesListPage;
