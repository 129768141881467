import { Route, Switch } from 'react-router-dom';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import EditCustomPaymentPage from './CustomPayment/pages/EditCustomPaymentPage';
import EnableMercadoPagoPage from './PaymentProviders/pages/EnableMercadoPagoPage';
import MercadoPagoSettingsPage from './PaymentProviders/pages/MercadoPagoSettingsPage';
import NuvempagoInitSettingsPage from './PaymentProviders/pages/NuvempagoInitSettingsPage';
import PaymentProvidersSettingsPage from './PaymentProviders/pages/PaymentProviderSettingsPage';
import PaymentProvidersListPage from './PaymentProviders/pages/PaymentProvidersListPage';
import PayPalSettingsPage from './PaymentProviders/pages/PayPalSettingsPage';
import PayUSettingsPage from './PaymentProviders/pages/PayUSettingsPage';
import { PAYMENTS_BASE_ROUTE, PAYMENTS_ROUTES } from './paymentsRoutes';

function Payments(): JSX.Element {
  return (
    <Switch>
      <Route path={PAYMENTS_ROUTES.customPaymentOldRoute} exact>
        <EditCustomPaymentPage fromOldAdmin />
      </Route>

      <ToggleMigratedOldDomain domain="settingspayments">
        <Switch>
          <Route path={PAYMENTS_BASE_ROUTE} exact>
            <PaymentProvidersListPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.nuvempagoInitSettings}>
            <NuvempagoInitSettingsPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.payUSettings} exact>
            <PayUSettingsPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.payPalSettings} exact>
            <PayPalSettingsPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.customPaymentNewRoute} exact>
            <EditCustomPaymentPage fromOldAdmin={false} />
          </Route>
          <Route path={PAYMENTS_ROUTES.mercadoPagoSettings}>
            <MercadoPagoSettingsPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.enableMercadoPago} exact>
            <EnableMercadoPagoPage />
          </Route>
          <Route path={PAYMENTS_ROUTES.settings}>
            <PaymentProvidersSettingsPage />
          </Route>
        </Switch>
      </ToggleMigratedOldDomain>
    </Switch>
  );
}

export default Payments;
