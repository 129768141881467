import { useEffect } from 'react';
import { NuvemApps } from '@tiendanube/common';
import { AppLoading } from 'domains/PartnersApps/components/EmbeddedApp';
import { DisplayApp } from './components';
import { useNuvemApps } from './hooks';

interface NuvemAppPageProps {
  code: NuvemApps;
}

function NuvemAppPage({ code }: NuvemAppPageProps): JSX.Element {
  const { isEmpty, nuvemApps, fetchNuvemApps } = useNuvemApps();

  const nuvemApp = nuvemApps[code];

  useEffect(() => {
    isEmpty && fetchNuvemApps();
  }, [isEmpty, fetchNuvemApps]);

  if (!nuvemApp) return <AppLoading />;

  return <DisplayApp app={nuvemApp} />;
}

export default NuvemAppPage;
