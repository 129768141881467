import { Box, Select, Text, Button } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function Skeleton() {
  const t = useTranslationConfigurations('languagesAndCurrencies.mainCountry');
  return (
    <>
      <Box display="grid" paddingTop="2" gridGap="2">
        <Text fontSize="caption">{t('mainCountrylabel')}</Text>
        <Select.Skeleton width="100%" />
      </Box>
      <Button.Skeleton />
    </>
  );
}

export default Skeleton;
