import { useNavegate } from 'App/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  DASHBOARD_ROUTES,
  trackingBillingTransactionFeesPaymentAvailableClick,
} from 'domains/Dashboard';
import { AlertCase } from './components';
import { useTransactionFeesPaymentAlert } from '../hooks';

interface TransactionFeesPaymentAlertProps {
  initialFetch?: boolean;
}

/**
 * Props for the TransactionFeesPaymentAlert component.
 *
 * @interface TransactionFeesPaymentAlertProps
 * @property {boolean} [initialFetch=true] - Specifies whether an initial fetch should be performed
 *                                          when the component mounts. If set to true (default), the
 *                                          component fetches data on mount; if set to false, it does
 *                                          not perform the initial fetch.
 */

function TransactionFeesPaymentAlert({
  initialFetch = true,
}: Readonly<TransactionFeesPaymentAlertProps>) {
  const t = useTranslationBilling('transactionFeesPaymentAlert');
  const { pathname } = useNavegate();
  const {
    transactionFeeToPay,
    isSuccess,
    shouldShowPaymentButton,
    deadlineDay,
    deadlineMonth,
    popupDismissed,
  } = useTransactionFeesPaymentAlert(initialFetch);
  const isMobileDevice = useIsMobileDevice();

  if (!isSuccess || !transactionFeeToPay || transactionFeeToPay.isEmpty)
    return null;

  const {
    paymentFailed,
    owesMoreThanBlockingAmount,
    isCloseToDeadline,
    blockedByTransactionFees,
    isVeryCloseToDeadline,
  } = transactionFeeToPay;

  const mobileSuffix = isMobileDevice ? 'Mobile' : '';
  const handleEventClick = () => {
    if (pathname === DASHBOARD_ROUTES.dashboard)
      trackingBillingTransactionFeesPaymentAvailableClick();
  };

  if (paymentFailed) {
    return (
      <AlertCase
        appearance="danger"
        title={t('paymentFailed.title')}
        message={t('paymentFailed.message')}
      />
    );
  }
  if (blockedByTransactionFees) {
    return (
      <AlertCase
        appearance="danger"
        title={t('blockedAlert.title')}
        message={t(`blockedAlert.message${mobileSuffix}`)}
      />
    );
  }
  if (shouldShowPaymentButton) {
    if (!owesMoreThanBlockingAmount || !isCloseToDeadline) {
      return (
        <AlertCase
          appearance="primary"
          title={t('softAlert.title')}
          message={t(`softAlert.message${mobileSuffix}`, {
            day: deadlineDay,
            month: deadlineMonth,
          })}
          eventClick={handleEventClick}
        />
      );
    }
    if (!isVeryCloseToDeadline || (isVeryCloseToDeadline && popupDismissed)) {
      return (
        <AlertCase
          appearance="warning"
          title={t('hardAlert.title')}
          message={t(`hardAlert.message${mobileSuffix}`, {
            day: deadlineDay,
            month: deadlineMonth,
          })}
        />
      );
    }
  }
  return null;
}

export default TransactionFeesPaymentAlert;
