import { useMemo } from 'react';
import { Badge, Tabs, Text } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { PaymentProviderDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { getProvidersByStatus } from 'domains/Payments/PaymentProviders/components/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import Skeleton from './Skeleton';
import { PaymentProviderDesktop } from '../../components';
import { OnPaymentProviderActionType } from '../PaymentProviderActionModal';

interface PaymentProviderListDesktopProps {
  paymentProviders: PaymentProviderDto[];
  onAction: OnPaymentProviderActionType;
}

const TRANSLATE_PREFIX = 'paymentProviders';

function PaymentProviderListDesktop({
  paymentProviders,
  onAction,
}: Readonly<PaymentProviderListDesktopProps>) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { disabled, enabled, pending } = useMemo(
    () => getProvidersByStatus(paymentProviders),
    [paymentProviders],
  );

  const renderPaymentProviders = (paymentProvidersList) => (
    <Stack column>
      {paymentProvidersList.map((paymentProviders) => (
        <PaymentProviderDesktop
          key={paymentProviders.id}
          paymentProvider={paymentProviders}
          onAction={onAction}
        />
      ))}
    </Stack>
  );

  const renderEmptyMessage = (status) => (
    <Stack spacing="loose" column>
      <EmptyMessage
        title={t(`emptyMessage.${status}`)}
        text={
          status === 'enabled' ? t('emptyMessage.enabledSubtitle') : undefined
        }
        icon={<SearchIcon size={32} />}
      />
    </Stack>
  );

  return (
    <Tabs preSelectedTab={0}>
      <Tabs.Item label={t('desktopTabs.all')}>
        {renderPaymentProviders(paymentProviders)}
      </Tabs.Item>
      <Tabs.Item label={t('desktopTabs.enabled')}>
        {enabled.length > 0
          ? renderPaymentProviders(enabled)
          : renderEmptyMessage('enabled')}
      </Tabs.Item>
      <Tabs.Item label={t('desktopTabs.disabled')}>
        {disabled.length > 0
          ? renderPaymentProviders(disabled)
          : renderEmptyMessage('disabled')}
      </Tabs.Item>
      <Tabs.Item
        label={t('desktopTabs.pending')}
        labelContent={<Badge appearance="primary" count={pending.length} />}
      >
        {pending.length > 0 ? (
          <Stack align="flex-start" spacing="loose" column>
            <Text>{t('desktopTabs.pendingSubtitle')}</Text>
            {renderPaymentProviders(pending)}
          </Stack>
        ) : (
          renderEmptyMessage('pending')
        )}
      </Tabs.Item>
    </Tabs>
  );
}

PaymentProviderListDesktop.Skeleton = Skeleton;

export default PaymentProviderListDesktop;
