import { Box } from '@nimbus-ds/components';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { Stack, Text } from '@tiendanube/components';
import StatsColorIcon from '../../../StatsColorIcon';
import { StratusLegendContentProps } from '../../StratusLegendContent';

import './StratusLegendContentDefault.scss';

function StratusLegendContentDefault({
  payload,
  formatLabel = (text: string) => text,
  isCombined = false,
}: StratusLegendContentProps) {
  // separate payload by yAxisId to render in two columns
  const data = payload?.reduce(
    (acc, act) => {
      if (act.payload?.['yAxisId'] === 'left') {
        acc[0].push(act);
      } else {
        acc[1].push(act);
      }
      return acc;
    },
    [[], []] as Payload[][],
  );
  const flexDirection = isCombined ? 'column' : 'row';

  return (
    <Box display="flex" flexDirection={flexDirection} gap="4">
      {data?.map((data, index) => (
        <div
          key={`item-${index}`}
          className="stratus--stratus-legend-content-default__container"
        >
          {data?.map((entry, index) => {
            const strokeColor = data?.[0]?.color;
            const strokeColorCompared = data?.[1]?.color;
            return (
              <Stack key={`item-${index}`} spacing="tight">
                <StatsColorIcon
                  strokeColor={strokeColorCompared || strokeColor}
                  color={entry.color}
                />
                <Text>{formatLabel(entry.value)}</Text>
              </Stack>
            );
          })}
        </div>
      ))}
    </Box>
  );
}

export default StratusLegendContentDefault;
