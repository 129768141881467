import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { InterfaceIdLabel, SearchFilter } from '@tiendanube/components';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import { useModal } from 'commons/hooks';
import { CUSTOMERS_SEARCH_CUSTOMERS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { trackingCustomerFilterModalOpen } from 'domains/Customers/tracking';
import formatDateFilterChips from 'domains/Metafields/utils';
import { useCustomerResults } from '../../hooks';
import FilterModal from '../FilterModal';

function CustomerListSearch(): JSX.Element | null {
  const { t } = useTranslation([DomainEnum.CUSTOMERS]);

  const { filters, getCustomers, advancedSearchCustomers, customersIds } =
    useCustomerResults();

  const [showModal, openModal, closeModal] = useModal();

  useEffect(() => {
    const hasMetafieldsFilters = Object.keys(filters).some((filter) =>
      filter.startsWith('mf-'),
    );

    if (hasMetafieldsFilters) {
      advancedSearchCustomers();
    } else {
      getCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDismiss = (key: string): void => {
    const updatedFilters = { ...filters, [key]: '' };
    delete updatedFilters[key];

    const hasMetafieldsFilters = Object.keys(updatedFilters).some((filter) =>
      filter.startsWith('mf-'),
    );

    if (hasMetafieldsFilters) {
      advancedSearchCustomers(updatedFilters);
    } else {
      getCustomers(updatedFilters);
    }
  };

  const handleSubmit = useUpsellFlow({
    title: t('customerListPage.upsell.search'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: CUSTOMERS_SEARCH_CUSTOMERS,
    callback: (q: string): void => {
      getCustomers({ q });
    },
  });

  const hasCustomers = customersIds.length > 0;

  const appliedFilters: InterfaceIdLabel[] = useMemo(
    () =>
      Object.entries(filters)
        .filter(
          ([key, value]) => value !== '' && key !== 'page' && key !== 'sort',
        )
        .map(([key, value]) => {
          const label = formatDateFilterChips(value);
          return { id: key, label };
        }),
    [filters],
  );

  const showSearchFilter = hasCustomers || appliedFilters.length > 0;

  const openFilterModal = () => {
    trackingCustomerFilterModalOpen();
    openModal();
  };

  return (
    <div className="stratus--alert-content-plans">
      {showSearchFilter && (
        <SearchFilter
          placeholder={t('filter.placeHolder')}
          resultCount=""
          appliedFilters={appliedFilters}
          onSubmit={handleSubmit}
          onDismiss={handleDismiss}
          onClick={openFilterModal}
        />
      )}

      <FilterModal
        show={showModal}
        closeModal={closeModal}
        filters={filters}
        getCustomers={getCustomers}
        advancedSearchCustomers={advancedSearchCustomers}
      />
    </div>
  );
}

export default CustomerListSearch;
