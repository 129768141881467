import { Box, Title } from '@nimbus-ds/components';
import { CardMetafields } from 'domains/Metafields/components/CardMetafieldsList/components';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

import './CardMetafieldsList.scss';

interface CardMetafieldsListProps {
  metafieldSlot?: React.ReactNode;
  apiMetafieldSlot?: React.ReactNode;
  metafieldsTotal: number;
}
function CardMetafieldsList({
  metafieldSlot,
  apiMetafieldSlot,
  metafieldsTotal,
}: CardMetafieldsListProps) {
  const t = useTranslationsMetafields();
  const fromApiTitle = t('fromApiTitle');

  return (
    <CardMetafields length={metafieldsTotal}>
      {!!metafieldSlot && metafieldSlot}

      {!!apiMetafieldSlot && (
        <div className="metafields-api-title">
          <Box padding="4">
            <Title as="h5">{fromApiTitle}</Title>
          </Box>
          {apiMetafieldSlot}
        </div>
      )}
    </CardMetafields>
  );
}

CardMetafieldsList.ErrorState = CardMetafields.ErrorState;
CardMetafieldsList.Skeleton = CardMetafields.Skeleton;

export default CardMetafieldsList;
