import { Repeater, Stack } from 'commons/components';
import PaymentProviderMobile from '../PaymentProviderMobile';

function Skeleton() {
  return (
    <Stack column>
      <Repeater times={8} item={PaymentProviderMobile.Skeleton} />
    </Stack>
  );
}

export default Skeleton;
