import { Alert } from '@nimbus-ds/components';
import { useParams } from 'react-router';
import { useCloneProductJobs } from 'App/settings/jobs/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

const appearncesMap: Record<string, 'primary' | 'success' | 'danger'> = {
  pending: 'primary',
  ready: 'success',
  failed: 'danger',
};

function AlertCloneStatus() {
  const { id } = useParams<{ id: string }>();

  const { job, removeJob } = useCloneProductJobs(id);

  const t = useTranslationCatalog('products.detail.alertCloneStatus');

  const status = job?.status;

  const title = t(`${status}.title`);
  const body = t(`${status}.body`);

  const appearance = appearncesMap[status];

  const handleRemove = status !== 'pending' ? removeJob : undefined;

  if (!status) return null;

  return (
    <Alert appearance={appearance} title={title} onRemove={handleRemove}>
      {body}
    </Alert>
  );
}

export default AlertCloneStatus;
