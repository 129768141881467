import { STATS_GENERAL_PAGE } from 'config/upsellFlowSources';
import {
  useGetFormattedValueSummary,
  useGetStatisticsGlobalInfo,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { SummaryType } from 'domains/Statistics/types';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import { COLORS } from './constants';
import BarChartAndSummaryCard from '../BarChartAndSummaryCard';
import SummaryFreemiumCard from '../SummaryFreemiumCard';

function GlobalInformationOfYourStoreCard() {
  const t = useTranslationStatistics();
  const {
    hasComparedFilter,
    fetchStatisticsGlobalInfo,
    globalInfo,
    globalInfoStatus,
    totalsGlobalInfo,
    granularity,
    openFilter,
  } = useGetStatisticsGlobalInfo();
  const fomatValues = useGetFormattedValueSummary();

  const getFormatedLabel = (
    dataKey: string,
    selectedPrimary: string,
    selectedSecondary: string,
  ) =>
    ({
      principalValue: t(`statistics.globalInfoCard.${selectedPrimary}.title`),
      secondaryValue: t(`statistics.globalInfoCard.${selectedSecondary}.title`),
    }[dataKey] || dataKey);

  const formatTitleSummary = (text: string) =>
    t(`statistics.globalInfoCard.${text}.title`);
  const formatTextTooltip = (text: string) =>
    t(`statistics.globalInfoCard.${text}.tooltip`);

  const rightTitle = !hasComparedFilter
    ? t('statistics.globalInfoCard.chooseTwoData')
    : undefined;

  return (
    <BarChartAndSummaryCard<SummaryType>
      title={t('statistics.globalInfoCard.title')}
      rightTitle={rightTitle}
      colors={COLORS}
      results={globalInfo}
      totals={totalsGlobalInfo}
      granularity={granularity}
      hasComparedFilter={hasComparedFilter}
      onClickEmpty={openFilter}
      statsPlanRequired={STATS_PLAN_1_AND_2}
      upsellSource={STATS_GENERAL_PAGE}
      rightOnDisabled={<SummaryFreemiumCard />}
      onFetch={fetchStatisticsGlobalInfo}
      status={globalInfoStatus}
      formatValueSummary={fomatValues}
      formatLabelTooltip={getFormatedLabel}
      formatSummaryTooltip={formatTextTooltip}
      formatSummaryTitle={formatTitleSummary}
      defaultSelected="uniqueVisits"
    />
  );
}

export default GlobalInformationOfYourStoreCard;
