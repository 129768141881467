import { useTranslation } from 'react-i18next';
import { useGetCurrency } from 'domains/Auth/hooks';
import { CouponActivityLogDto } from 'domains/Marketing/Coupons/couponHistorySlice';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { emptyValueFor, valueTransformationMap } from './utils';
import { CouponHistoryLogChange } from '../CouponHistoryTimelineItem';

export const useParseCouponLogChanges = () => {
  const t = useTranslationMarketing('coupons.history');
  const {
    i18n: { language },
  } = useTranslation();
  const { symbol } = useGetCurrency();
  const translateKey = (key) => t(`keysTranslations.${key}.title`);
  const translateValue = (key, value, eventData) => {
    let translatedValue = value;
    if (value === null) {
      const emptyValue = emptyValueFor(key);
      translatedValue = (
        emptyValue !== undefined
          ? t(`keysTranslations.${emptyValue}`)
          : t(`keysTranslations.default`)
      ).toLowerCase();
    }

    if (value !== null && valueTransformationMap[key])
      translatedValue = valueTransformationMap[key]({
        value,
        key,
        eventData,
        language,
        currency: symbol,
        t,
      });
    return translatedValue;
  };

  const fillChanges = (
    changes: CouponHistoryLogChange[],
    eventData,
  ): CouponHistoryLogChange[] => {
    const hasField = (key: string) =>
      changes.some((change) => change.field === translateKey(key));

    if (hasField('start_date') && hasField('end_date')) {
      const startDateChange = changes.find(
        (change) => change.field === translateKey('start_date'),
      );
      if (startDateChange) startDateChange.compact = true;
    }

    if (hasField('type')) {
      const indexType = changes.findIndex(
        (change) => change.field === translateKey('type'),
      );

      if (!hasField('value') && eventData.new['type'] !== 'shipping') {
        changes.splice(indexType + 1, 0, {
          field: translateKey('value'),
          value: translateValue('value', eventData.new['value'], eventData),
          compact: false,
        });
      }

      changes[indexType].compact = true;
    }

    if (hasField('value') && !hasField('type')) {
      const valueType = changes.findIndex(
        (change) => change.field === translateKey('value'),
      );

      changes.splice(valueType, 0, {
        field: translateKey('type'),
        value: translateValue('type', eventData.new['type'], eventData),
        compact: true,
      });
    }

    return changes;
  };

  const parseCouponLogChanges = (
    event: CouponActivityLogDto,
  ): CouponHistoryLogChange[] => {
    const changes: CouponHistoryLogChange[] = event.data?.differences
      ? Object.entries(event.data.differences).map(([key, value]) => ({
          field: translateKey(key),
          value: translateValue(key, value.newValue, event.data),
          compact: false,
        }))
      : [];

    return fillChanges(changes, event.data);
  };

  return { parseCouponLogChanges };
};
