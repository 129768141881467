import { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import {
  NavigationMenuItemResponseDto,
  NavigationMenuResponseDto,
} from '@tiendanube/common';
import updateArrayElementById from 'commons/utils/updateArrayElementById';

const updateMenuById = (
  menus: NavigationMenuResponseDto[],
  id: string,
  updateMenu: (menu: NavigationMenuResponseDto) => NavigationMenuResponseDto,
) => updateArrayElementById<NavigationMenuResponseDto>(menus, id, updateMenu);

const addMenuItem =
  (item: NavigationMenuItemResponseDto) =>
  (menu: NavigationMenuResponseDto) => ({
    ...menu,
    items: [...menu.items, item],
  });

const deleteMenuItem =
  (itemId: string) => (menu: NavigationMenuResponseDto) => ({
    ...menu,
    items: menu.items.filter((item) => item.id !== itemId),
  });

const updateMenuItems =
  (items: NavigationMenuItemResponseDto[]) =>
  (menu: NavigationMenuResponseDto) => ({
    ...menu,
    items,
  });

const updateMenuItem =
  (updatedItem: NavigationMenuItemResponseDto) =>
  (menu: NavigationMenuResponseDto) => ({
    ...menu,
    items: updateArrayElementById<NavigationMenuItemResponseDto>(
      menu.items,
      updatedItem.id,
      () => updatedItem,
    ),
  });

function useMenusForm(initialMenus: NavigationMenuResponseDto[]) {
  const [isDirty, setIsDirty] = useState(false);
  const [menus, setMenus] = useState<NavigationMenuResponseDto[]>(initialMenus);

  const handleAddMenuItem =
    (menuId: string) => (item: NavigationMenuItemResponseDto) => {
      setMenus((prevMenus) =>
        updateMenuById(prevMenus, menuId, addMenuItem(item)),
      );
    };

  const handleDeleteMenuItem = (menuId: string) => (itemId: string) => {
    setMenus((prevMenus) =>
      updateMenuById(prevMenus, menuId, deleteMenuItem(itemId)),
    );
  };

  const handleDropMenuItem =
    (menuId: string) => (items: NavigationMenuItemResponseDto[]) => {
      setMenus((prevMenus) =>
        updateMenuById(prevMenus, menuId, updateMenuItems(items)),
      );
    };

  const handleEditMenuItem =
    (menuId: string) => (updatedItem: NavigationMenuItemResponseDto) => {
      setMenus((prevMenus) =>
        updateMenuById(prevMenus, menuId, updateMenuItem(updatedItem)),
      );
    };

  const resetValues = () => setMenus(initialMenus);

  useEffect(() => {
    setIsDirty(!isEqual(initialMenus, menus));
  }, [initialMenus, menus]);

  useEffect(() => {
    if (initialMenus.length > 0) {
      setMenus(initialMenus);
      setIsDirty(false);
    }
  }, [initialMenus]);

  return {
    menus,
    isDirty,
    resetValues,
    handleAddMenuItem,
    handleDeleteMenuItem,
    handleDropMenuItem,
    handleEditMenuItem,
  };
}

export default useMenusForm;
