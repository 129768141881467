import { ChangeEvent, useEffect, useRef } from 'react';
import { Box } from '@nimbus-ds/components';
import { HiddenCategoryTag } from 'domains/Catalog/Categories/components';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import { TreeData } from '../../hooks/useCategoriesTree/useCategoriesTreeNew';
import { InputCategory } from '../CategoryNode/components';

interface CategoryNodeNewProps {
  node: TreeData;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  autofocus: boolean;
  showEmptyCategories: boolean;
  onPressEnter: () => void;
}

function CategoryNodeNew({
  node,
  onChange,
  autofocus,
  showEmptyCategories,
  onPressEnter,
}: CategoryNodeNewProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const isAdding = node?.id.startsWith('new-');
  const isVisible = isCategoryVisible({ visibility: node.visibility });

  useEffect(() => {
    if (autofocus) {
      inputRef?.current?.focus();
    }
  }, [autofocus]);

  const flexGrow = isAdding ? '1' : '0';

  return (
    <Box display="flex" gap="2" alignItems="center">
      <Box display="flex" flexDirection="column" flexGrow={flexGrow}>
        <InputCategory
          ref={inputRef}
          value={node.text}
          isAdding={isAdding}
          showEmptyCategories={showEmptyCategories && node.text.length === 0}
          handleChange={onChange}
          onPressEnter={onPressEnter}
        />
      </Box>
      {!isVisible && <HiddenCategoryTag />}
    </Box>
  );
}

export default CategoryNodeNew;
