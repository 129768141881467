import { useGetFreeShippingAvailableQuery } from '../freeShippingApi';

interface UseFreeShippingStatusProps {
  available: boolean;
}

export function useFreeShippingStatus(): UseFreeShippingStatusProps {
  const { data } = useGetFreeShippingAvailableQuery();

  return { available: data?.status || false };
}
