import { Thumbnail, Box, Badge } from '@nimbus-ds/components';
import { Responsive } from 'commons/components';
import { RelatedProductType } from 'domains/Catalog/Products/pages/hooks/useProductForm';
import './RelatedProductImages.scss';

export interface RelatedProductImageProps {
  size?: 'small' | 'large';
  relatedProducts: RelatedProductType[];
}

function RelatedProductImages({
  size = 'small',
  relatedProducts = [],
}: Readonly<RelatedProductImageProps>) {
  const maxNumberOfShownProducts = 5;
  const sizeMap = {
    small: '48px',
    large: '64px',
  };
  return (
    <Responsive
      mobile={
        <div className="stratus--image-preview_related_product_item">
          <Box display="flex" flexDirection="row" gap="3-5">
            {relatedProducts
              .slice(0, maxNumberOfShownProducts)
              .map((product) => (
                <Thumbnail
                  key={`${product.id}`}
                  src={product.imageUrl}
                  width={sizeMap[size]}
                  height={sizeMap[size]}
                  alt={product.name}
                />
              ))}
          </Box>
          {relatedProducts.length > maxNumberOfShownProducts && (
            <div className="stratus--related-product-tag">
              <Badge
                count={`+${relatedProducts.length - maxNumberOfShownProducts}`}
                appearance="neutral"
              />
            </div>
          )}
        </div>
      }
      desktop={
        <Box display="flex" flexDirection="row" gap="3-5">
          {relatedProducts.map((product) => (
            <Thumbnail
              key={`${product.id}`}
              src={product.imageUrl}
              width={sizeMap[size]}
              height={sizeMap[size]}
              alt={product.name}
            />
          ))}
        </Box>
      }
    />
  );
}

export default RelatedProductImages;
