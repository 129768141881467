import { Alert, Link } from '@nimbus-ds/components';
import {
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DocumentType } from '../../constants';
import { useGoToPrintPage } from '../../hooks';

interface AlertOcaLabelsWithErrorProps {
  externalIds: string[];
  information?: (
    | InformationForContentDeclarationResponseDto
    | InformationForDocumentResponseDto
  )[];
}

function AlertOcaLabelsWithError({
  externalIds,
  information,
}: Readonly<AlertOcaLabelsWithErrorProps>) {
  const t = useTranslationFulfillmentOrders(
    externalIds.length > 1
      ? 'printManager.oca.errorPlural'
      : 'printManager.oca.error',
  );
  const { goToPrintPage } = useGoToPrintPage();

  const orderAndFulfillmentOrderNumbers = information?.flatMap(
    ({ order }) => `#${order.number}`,
  );

  return (
    <Alert
      appearance="primary"
      title={`${t('title')} ${orderAndFulfillmentOrderNumbers?.join(', ')}`}
    >
      <Link
        as="a"
        target="_blank"
        onClick={() =>
          goToPrintPage({
            ids: externalIds,
            type: DocumentType.LABEL,
            hasOca: true,
            hasOcaAndNotGeneratedTag: true,
            forceOca: true,
          })
        }
      >
        {t('action')}
      </Link>
    </Alert>
  );
}

export default AlertOcaLabelsWithError;
