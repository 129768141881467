import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { resetRecoveryCodes } from 'domains/Account/accountSlice';

function useResetRecoveryCodes() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(resetRecoveryCodes());
  }, [dispatch]);
}

export default useResetRecoveryCodes;
