import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthorizeResponseDto, TokenRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { createSession as createSessionAction } from 'domains/Auth/authSlice';
import {
  getAuthorizeData,
  getLoginStatus,
} from 'domains/Auth/authSlice/authSelectors';

interface useTokenResponse {
  createSession: (payload: TokenRequestDto) => Promise<boolean>;
  isLogin: boolean;
  isError: boolean;
  isLoading: boolean;
  statusCode: string;
  isInternalServerError: boolean;
  isRequestTimeoutError: boolean;
  isUnauthorizedError: boolean;
  authorizeData: AuthorizeResponseDto | null;
}

function useToken(): useTokenResponse {
  const status = useSelector(getLoginStatus);
  const authorizeData = useSelector(getAuthorizeData);
  const dispatch = useAppDispatch();
  const createSession = useCallback(
    async (payload: TokenRequestDto) => {
      const response = await dispatch(createSessionAction(payload));
      return 'auth/v1/token/fulfilled' === response.type;
    },
    [dispatch],
  );

  const isInternalServerError = status.statusCode === '500';
  const isRequestTimeoutError = status.statusCode === '408';
  const isUnauthorizedError = status.statusCode === '401';

  return {
    createSession,
    ...status,
    authorizeData,
    isInternalServerError,
    isRequestTimeoutError,
    isUnauthorizedError,
  };
}

export default useToken;
