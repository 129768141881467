import {
  ReactNode,
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  UpsellFlowModal,
  UpsellFlowModalProps,
} from 'domains/Billing/UpsellFlow/UpsellFlowModal';
import {
  UpsellUnavailableModal,
  UpsellUnavailableModalProps,
} from 'domains/Billing/UpsellFlow/UpsellUnavailableModal';

export interface InterfaceUpsellFlowModalContext {
  showUpsellFlowModal: (props: UpsellFlowModalProps) => void;
  hideUpsellFlowModal: () => void;
  updateUpsellFlowModalProps: (newProps: Partial<UpsellFlowModalProps>) => void;
  showUpsellUnavailableModal: (props: UpsellUnavailableModalProps) => void;
  hideUpsellUnavailableModal: () => void;
}

export const UpsellFlowModalContext = createContext(
  {} as InterfaceUpsellFlowModalContext,
);

interface InterfaceUpsellFlowModalContextProvider {
  children: ReactNode;
}

function UpsellFlowModalContextProvider({
  children,
}: Readonly<InterfaceUpsellFlowModalContextProvider>) {
  const [upsellFlowModalProps, setUpsellFlowModalProps] =
    useState<UpsellFlowModalProps>();

  const [upsellUnavailableProps, setUpsellUnavailableProps] =
    useState<UpsellUnavailableModalProps>();

  const showUpsellFlowModal = useCallback(
    (props: UpsellFlowModalProps) => {
      setUpsellFlowModalProps(props);
    },
    [setUpsellFlowModalProps],
  );

  const updateUpsellFlowModalProps = useCallback(
    (newProps: Partial<UpsellFlowModalProps>) => {
      setUpsellFlowModalProps((props) => {
        if (props === undefined) {
          return;
        }

        return { ...props, ...newProps };
      });
    },
    [setUpsellFlowModalProps],
  );

  const hideUpsellFlowModal = useCallback(() => {
    setUpsellFlowModalProps((props) => {
      if (props === undefined) {
        return undefined;
      }

      return { ...props, show: false };
    });
  }, [setUpsellFlowModalProps]);

  const showUpsellUnavailableModal = useCallback(
    (props: UpsellUnavailableModalProps) => {
      setUpsellUnavailableProps(props);
    },
    [setUpsellUnavailableProps],
  );

  const hideUpsellUnavailableModal = useCallback(() => {
    setUpsellUnavailableProps((props) => {
      if (props === undefined) {
        return undefined;
      }

      return { ...props, show: false };
    });
  }, [setUpsellUnavailableProps]);

  const providerValue = useMemo(
    () => ({
      showUpsellFlowModal,
      hideUpsellFlowModal,
      updateUpsellFlowModalProps,
      showUpsellUnavailableModal,
      hideUpsellUnavailableModal,
    }),
    [
      showUpsellFlowModal,
      hideUpsellFlowModal,
      updateUpsellFlowModalProps,
      showUpsellUnavailableModal,
      hideUpsellUnavailableModal,
    ],
  );

  return (
    <UpsellFlowModalContext.Provider value={providerValue}>
      {children}
      {upsellFlowModalProps && <UpsellFlowModal {...upsellFlowModalProps} />}
      {upsellUnavailableProps && (
        <UpsellUnavailableModal {...upsellUnavailableProps} />
      )}
    </UpsellFlowModalContext.Provider>
  );
}

export default UpsellFlowModalContextProvider;
