import { useSelector } from 'react-redux';
import {
  getStoreName,
  getStoreURL,
  getStoreURLReduced,
  isUserLogin,
} from '../../authSlice/authSelectors';

interface InterfaceUseHeaderMenuData {
  isLogin: boolean;
  name: string;
  url: string;
  urlReduced: string;
}

function useHeaderMenuData(): InterfaceUseHeaderMenuData {
  const url = useSelector(getStoreURL);
  const urlReduced = useSelector(getStoreURLReduced);
  const name = useSelector(getStoreName);
  const isLogin = useSelector(isUserLogin);
  return { url, urlReduced, name, isLogin };
}

export default useHeaderMenuData;
