import { Card, Title } from '@nimbus-ds/components';
import { DataList } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function PaymentSelectionSkeleton() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPlan.paymentSelection',
  );
  return (
    <Card>
      <Card.Header title={t('title')} />
      <DataList ruled>
        <DataList.Row id="1">
          <Title.Skeleton />
        </DataList.Row>
        <DataList.Row id="2">
          <Title.Skeleton />
        </DataList.Row>
      </DataList>
    </Card>
  );
}

export default PaymentSelectionSkeleton;
