import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { STORAGE_NAVEGATE_SUBPATHNAME } from './constants';
import useNavegate from '../useNavegate';

export interface UseSubPathname {
  /**
   * Subpathname: corresponds to the URL generated internally
   * by the embedded applications
   */
  value: string | null;
  /**
   * Receive a new subpathname that will be synchronized with the URL
   */
  syncNewSubPathname: (newSubPahtname: string) => void;
  /**
   * This action will be called to persist the value of the sub pathname
   */
  saveTemporal: () => void;
  /**
   * If we have persisted a sub pathname value temporarily a subpatname
   * will be restored, removed and redirected
   */
  restoreTemporal: () => void;
}

function useSubPathname(): UseSubPathname {
  const { replace, pathname } = useNavegate();
  const { createHref } = useHistory();
  const { hash } = useLocation();

  const value = useMemo(() => {
    const subPathname = hash.split('#')[1];
    if (subPathname && subPathname.indexOf('/') === 0) {
      return subPathname;
    }
    return null;
  }, [hash]);

  const syncNewSubPathname = useCallback(
    (newSubPathname: string) => {
      if (newSubPathname === value) {
        // update if subPathname is not required if current path is equal to new subPathname
        return;
      }

      const BASE_URL = import.meta.env.VITE_BASE_URL;

      const hrefWithSubPath = createHref({
        pathname,
        hash: '#' + newSubPathname,
      });

      const hrefWithoutBaseUrl =
        BASE_URL === '/'
          ? hrefWithSubPath
          : hrefWithSubPath.replace(BASE_URL, '');

      replace(hrefWithoutBaseUrl);
    },
    [createHref, pathname, replace, value],
  );

  const saveTemporal = useCallback(() => {
    if (value) {
      sessionStorage.setItem(STORAGE_NAVEGATE_SUBPATHNAME, value);
    }
  }, [value]);

  const restoreTemporal = () => {
    const oldSubPathname = sessionStorage.getItem(STORAGE_NAVEGATE_SUBPATHNAME);
    sessionStorage.removeItem(STORAGE_NAVEGATE_SUBPATHNAME);
    return oldSubPathname;
  };

  return {
    value,
    syncNewSubPathname,
    saveTemporal,
    restoreTemporal,
  };
}

export default useSubPathname;
