import { AuthenticationFactorVerifyCodeCard } from 'domains/AuthenticationFactor/components';
import { useVerifyAuthenticationFactor } from 'domains/AuthenticationFactor/hooks';

export interface AuthenticationFactorVerifyAndAuthorizeCardProps {
  title?: string;
  payload?: object;
  onCancel: () => void;
  onIsValidCode: () => void | Promise<void>;
}

function AuthenticationFactorVerifyAndAuthorizeCard({
  title,
  payload = {},
  onCancel,
  onIsValidCode,
}: AuthenticationFactorVerifyAndAuthorizeCardProps) {
  const { verify, isLoading, isError } = useVerifyAuthenticationFactor();
  const onSubmit = async (data) => await verify(data.code, payload);
  const onAfterVerify = async (valid: boolean) => {
    if (valid) onIsValidCode();
  };
  const handleCancel = () => {
    onCancel();
  };

  return (
    <AuthenticationFactorVerifyCodeCard
      title={title}
      isLoading={isLoading}
      isError={isError}
      onCancel={handleCancel}
      onSubmit={onSubmit}
      onAfterVerify={onAfterVerify}
    />
  );
}

export default AuthenticationFactorVerifyAndAuthorizeCard;
