import { Title } from '@tiendanube/components';
import { Layout, RemarksCardSkeleton, useResponsive } from 'commons/components';
import {
  DetailsHeaderSkeleton,
  CustomerDataCardSkeleton,
  OrderSummaryCardSkeleton,
} from 'domains/Orders/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  CardBillingAddressSkeleton,
  CardDeliveryAddressSkeleton,
  CardFulfillmentSkeleton,
  CardPaymentSkeleton,
  CardTimelineSkeleton,
  CardTrackingPageSkeleton,
} from '../../components';

function OrderDetailsSkeleton(): JSX.Element {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();

  return (
    <Layout
      mainContent
      right={
        <>
          {isDesktop && (
            <Title type="h2">{`${t('detail.subTitleAside')}`}</Title>
          )}
          <CustomerDataCardSkeleton />
          <CardDeliveryAddressSkeleton />
          <CardBillingAddressSkeleton />
          <CardTimelineSkeleton />
          <CardTrackingPageSkeleton />
        </>
      }
      left={
        <>
          <DetailsHeaderSkeleton />
          <OrderSummaryCardSkeleton />
          <CardPaymentSkeleton />
          <CardFulfillmentSkeleton />
          <RemarksCardSkeleton />
        </>
      }
    />
  );
}

export default OrderDetailsSkeleton;
