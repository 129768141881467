import { Alert, Button, Text } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { trackPrintNuvemEnvioLabelFromBanner } from '../tracking';

interface AlertNuvemEnvioProps {
  readonly externalSaleId: string[];
}

function AlertNuvemEnvio({ externalSaleId }: AlertNuvemEnvioProps) {
  const t = useTranslationFulfillmentOrders('alertNuvemEnvio');
  const { goTo } = useNavegate();
  const storeCountry = useGetCountry();

  const handlePrintNuvemEnvio = () => {
    const path = `#/dashboard?&externalSaleIds=${externalSaleId}`;
    trackPrintNuvemEnvioLabelFromBanner(externalSaleId);

    if (storeCountry === Country.AR) {
      goTo(`${partnersAppsRoutes.envionubear}${path}`);
    }

    if (storeCountry === Country.BR) {
      goTo(`${partnersAppsRoutes.nuvemenvio}${path}`);
    }

    if (storeCountry === Country.MX) {
      goTo(`${partnersAppsRoutes.envionubemx}${path}`);
    }
  };

  return (
    <Alert appearance="primary" title={t('title')}>
      <Text>{t('content')}</Text>

      <Button onClick={handlePrintNuvemEnvio}>{t('button')}</Button>
    </Alert>
  );
}

export default AlertNuvemEnvio;
