import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getStoreHasGAEntityData,
  getStoreHasGAEntityStatus,
} from 'domains/Statistics/selectors';
import { getStoreHasGAAction as getStoreHasGAAction } from 'domains/Statistics/statisticsSlice';

function useGetStoreHasGA() {
  const dispatch = useAppDispatch();

  const getStoreHasGA = useCallback(() => {
    dispatch(getStoreHasGAAction());
  }, [dispatch]);

  const status = useSelector(getStoreHasGAEntityStatus);
  const hasGA = useSelector(getStoreHasGAEntityData);

  useEffect(() => {
    getStoreHasGA();
  }, [getStoreHasGA]);

  return {
    ...status,
    hasGA,
  };
}

export default useGetStoreHasGA;
