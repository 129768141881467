import { useCallback, useEffect } from 'react';
import { Button, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { InventoryFiltersType } from 'domains/Catalog/Inventory/inventoryServices/types';
import { useInventoryFilters } from 'domains/Catalog/Inventory/pages/hooks';
import {
  Categories,
  SortBy,
  Stock,
  StockQuantity,
} from 'domains/Catalog/Products/pages/ProductListPage/components/ProductListSearch/FilterModal/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

export const defaultValuesFilter: InventoryFiltersType = {
  sortBy: '',
  categoryId: '',
  stock: '',
  maxStock: '',
  minStock: '',
};

interface FilterModalProps {
  show: boolean;
  hideModal: () => void;
  filter: InventoryFiltersType;
  addParam: (f: InventoryFiltersType) => void;
}

function FilterModal({
  show,
  hideModal,
  filter,
  addParam,
}: FilterModalProps): JSX.Element {
  const t = useTranslationCatalog();

  const { values, errors, validateFilters, handleChange, setAllValues } =
    useInventoryFilters(filter);

  useEffect(() => {
    setAllValues(filter);
  }, [filter, setAllValues]);

  const showStockQuantity = values.stock === 'quantity';

  const handleApply = useCallback(() => {
    if (!validateFilters()) return;

    const buildValues = {
      ...values,
      ...(!showStockQuantity && { maxStock: '', minStock: '' }),
    } as InventoryFiltersType;

    addParam(buildValues);
    hideModal();
  }, [addParam, values, hideModal, validateFilters, showStockQuantity]);

  const handleReset = () => setAllValues(defaultValuesFilter);

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={hideModal}
      headerContent={t('products.filter.filterBy')}
    >
      <Stack column align="stretch">
        <SortBy value={values.sortBy || ''} onChange={handleChange} />

        <Categories value={values.categoryId} onChange={handleChange} />

        <Stock value={values.stock} onChange={handleChange} />

        {showStockQuantity && (
          <StockQuantity
            min={values.minStock}
            max={values.maxStock}
            onChange={handleChange}
            minError={errors.minStock}
            maxError={errors.maxStock}
          />
        )}

        <Stack spacing="base" justify="flex-end">
          <Stack.Item>
            <Button onClick={handleReset}>{`${t(
              'products.filter.cleanFilter',
            )}`}</Button>
          </Stack.Item>
          <Stack.Item>
            <Button onClick={handleApply} appearance="primary">
              {`${t('products.filter.filter')}`}
            </Button>
          </Stack.Item>
        </Stack>
      </Stack>
    </ModalAside>
  );
}

export default FilterModal;
