import { Card, Link, Icon } from '@nimbus-ds/components';
import { HistoryIcon } from '@nimbus-ds/icons';
import { StockHistoryModal } from 'domains/Catalog/Products/pages/components';
import { useStockHistoryContext } from 'domains/Catalog/Products/pages/components/StockHistoryModal/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface StockHistoryProps {
  variantId: string;
  variantName: string;
  productId: string;
}

function StockHistory({
  variantId,
  variantName,
  productId,
}: StockHistoryProps) {
  const t = useTranslationCatalog();

  const {
    showAccessToStockHistory,
    variantStock,
    isOpen,
    openStockHistoryModal,
    closeStockHistoryModal,
  } = useStockHistoryContext();

  if (!showAccessToStockHistory) return null;

  const handleOpenStockHistoryModal = () => {
    openStockHistoryModal({
      variantId,
      variantName,
      productId,
    });
  };

  return (
    <>
      <Card.Footer>
        <Link
          appearance="primary"
          as="button"
          textDecoration="none"
          onClick={handleOpenStockHistoryModal}
        >
          <Icon color="primary-interactive" source={<HistoryIcon />} />
          {t('products.detail.goToStockHistory')}
        </Link>
      </Card.Footer>
      {variantStock && (
        <StockHistoryModal
          productId={variantStock.productId}
          variantId={variantStock.variantId}
          variantName={variantStock.variantName}
          onClose={closeStockHistoryModal}
          isOpen={isOpen}
        />
      )}
    </>
  );
}

export default StockHistory;
