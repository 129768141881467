import { useCallback } from 'react';
import { useNavegate } from 'App/hooks';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { usePostAdvancedChargeMutation } from 'domains/Billing/Checkout/CheckoutApi';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  useGetLastPayment,
  useRedirectOnSimilarCharge,
} from 'domains/Billing/Checkout/hooks';
import {
  trackingBillingStartPlanPayment,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';

function useAdvancePlanCharge() {
  const [createAdvancedPlanCharge] = usePostAdvancedChargeMutation();
  const { goTo } = useNavegate();
  const { status, handleFunctionBehaviour } = useRedirectOnSimilarCharge(
    () => createAdvancedPlanCharge().unwrap(),
    CHECKOUT_ROUTES.plansAndSubscriptions,
  );

  const { isPending: planPending, status: setupStatus } =
    useGetLastPayment('plan-cost');
  const logEvent = useCheckoutTracking();

  const handlePayAdvancedPlanCharge = useCallback(() => {
    trackingBillingStartPlanPayment(logEvent);
    if (planPending) {
      goTo(CHECKOUT_ROUTES.choosePaymentMethodForPlan);
    } else {
      handleFunctionBehaviour().then(() => {
        goTo(CHECKOUT_ROUTES.choosePaymentMethodForPlan);
      });
    }
  }, [handleFunctionBehaviour, goTo, planPending, logEvent]);

  return {
    createAdvancedPlanCharge,
    handlePayAdvancedPlanCharge,
    ...convertStatusTypeToObject(status),
    createStatus: status,
    setupStatus,
  };
}

export default useAdvancePlanCharge;
