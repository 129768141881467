import { Icon, Link, Text, Title } from '@nimbus-ds/components';
import { BaseCard } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import Stack from 'commons/components/Stack';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProvider.settings.mercadopago';
const CHANGE_TERMS_AND_RATES_URL =
  'https://www.mercadopago.com.ar/settings/release-options';

function TermsAndRatesCard(): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title as="h3">{t('termsAndRates.title')}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <Text>{t('termsAndRates.body')}</Text>
          <Link
            as="a"
            href={CHANGE_TERMS_AND_RATES_URL}
            target="_blank"
            appearance="primary"
            textDecoration="none"
          >
            {t('termsAndRates.changeTermAndRates')}
            <Icon color="currentColor" source={<ExternalLinkIcon />} />
          </Link>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default TermsAndRatesCard;
