import { useTranslation } from 'react-i18next';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useToast } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';

type UserFlowContext = 'configuration' | 'authentication';

interface UseStatusMessagesResponse {
  addProgressToast: () => void;
  addSuccessToast: (isActive?: boolean) => void;
  closeProgressToast: () => void;
}

function useStatusMessages(
  context: UserFlowContext,
): UseStatusMessagesResponse {
  const { t: translationAuth } = useTranslation(['login']);
  const translationAccount = useTranslationAccount(
    'authenticationFactorPage.linkAccountPage.toast',
  );
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress: closeProgressToast } =
    useToastProgress();
  const toastSuccess = (label: string) =>
    addToast({ label, appearance: 'success' });

  switch (context) {
    case 'authentication':
      return {
        addProgressToast: () =>
          addToastProgress({
            label: translationAuth('authenticationFactor.loading'),
          }),
        addSuccessToast: () =>
          toastSuccess(translationAuth('authenticationFactor.success')),
        closeProgressToast,
      };
    case 'configuration':
      return {
        addProgressToast: () =>
          addToastProgress({
            label: translationAccount('progress'),
          }),
        addSuccessToast: (isActive?: boolean) => {
          toastSuccess(
            translationAccount(
              isActive
                ? 'deactivatedAccountSuccess'
                : 'activatedAccountSuccessRecoveryCodes',
            ),
          );
        },
        closeProgressToast,
      };
  }
}

export default useStatusMessages;
