import { Box, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { InterfaceNameValue } from '@tiendanube/components';
import { InputNumberNimbus, RadioButtonGroup, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { RadioOptionsSkeleton } from '../RadioOptionsSkeleton';

export interface QuantityValuesInterface {
  limitedType: 'unlimited' | 'limited';
  maxUses: string;
}

export interface QuantityErrorsInterface {
  maxUses: string;
  maxUsesGreaterThanCurrentUses: string;
}

interface QuantityProps {
  values: QuantityValuesInterface;
  errors?: Partial<QuantityErrorsInterface>;
  onChange: ({ value, name }: InterfaceNameValue) => void;
  currentUses: number;
  disabled?: boolean;
}

export function Quantity({
  values: { limitedType, maxUses },
  errors: {
    maxUses: maxUsesError,
    maxUsesGreaterThanCurrentUses: maxUsesGreaterThanCurrentUsesError,
  } = {},
  onChange,
  currentUses,
  disabled = false,
}: Readonly<QuantityProps>) {
  const translationsPrefix = 'coupons.couponForm.limits.quantity';
  const t = useTranslationMarketing();
  const error = maxUsesError || maxUsesGreaterThanCurrentUsesError;
  const appearance = error ? 'danger' : undefined;

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <Text fontWeight="bold">{t(`${translationsPrefix}.title`)}</Text>
      </Stack.Item>
      <Stack.Item>
        <RadioButtonGroup
          name="limitedType"
          options={[
            {
              label: t(`${translationsPrefix}.type.unlimited`),
              value: 'unlimited',
              disabled,
            },
            {
              label: t(`${translationsPrefix}.type.limited`),
              value: 'limited',
              disabled,
            },
          ]}
          onChange={onChange}
          value={limitedType}
        />
      </Stack.Item>

      {limitedType === 'limited' && (
        <Stack.Item>
          <Box paddingTop="3">
            <FormField
              label={t(`${translationsPrefix}.label`)}
              helpText={error ? t(error, { count: currentUses }) : undefined}
              appearance={appearance}
              showHelpText={!!error}
              id="maxUses"
            >
              <InputNumberNimbus
                type="float"
                name="maxUses"
                value={maxUses}
                onChange={({ target: { name, value } }) =>
                  onChange({ name, value })
                }
                appearance={appearance}
                id="maxUses"
                disabled={disabled}
              />
            </FormField>
          </Box>
        </Stack.Item>
      )}
    </Stack>
  );
}

Quantity.Skeleton = RadioOptionsSkeleton;
