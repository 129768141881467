import { Card, Text, Link, Stack } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import { useModal } from 'commons/hooks';
import {
  OnChangeSelectType,
  SelectedProductInterface,
  SelectProductList,
} from 'domains/Catalog/Products/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface NewDraftOrderProductProps {
  onChange: OnChangeSelectType;
  selected: SelectedProductInterface[];
  refreshProducts?: boolean;
  setRefreshProducts?: (refresh: boolean) => void;
}

function NewDraftOrderProduct({
  onChange,
  selected,
  refreshProducts,
  setRefreshProducts,
}: NewDraftOrderProductProps): JSX.Element {
  const t = useTranslationOrders();
  const [isShow, openModal, closeModal] = useModal();
  const title = t('draftOrders.newDraftOrder.NewDraftOrderProduct.title');

  return (
    <Card title={title}>
      <Stack column align="flex-start">
        <Text>{`${t(
          'draftOrders.newDraftOrder.NewDraftOrderProduct.description',
        )}`}</Text>
        <Link icon={PlusCircleIcon} appearance="primary" onClick={openModal}>
          {`${t('draftOrders.newDraftOrder.NewDraftOrderProduct.addProducts')}`}
        </Link>
      </Stack>
      <SelectProductList
        isShow={isShow}
        title={title}
        onClose={closeModal}
        selectedProducts={selected}
        onChange={onChange}
        addItemsToTop
        refreshProducts={refreshProducts}
        setRefreshProducts={setRefreshProducts}
      />
    </Card>
  );
}

export default NewDraftOrderProduct;
