import { ThemesConfigImagesResponseDto } from '@tiendanube/common';

export const getEditImagesPayload = (
  images: ThemesConfigImagesResponseDto,
  ogImageStatus: 'enabled' | 'disabled',
  editedImages: ThemesConfigImagesResponseDto,
  editedImageStatus: 'enabled' | 'disabled',
) => ({
  imagesToDelete: ['logo', 'logoOg', 'favicon'].filter(
    (type) => images[type] && !editedImages[type],
  ),
  imagesToUpload: {
    logo:
      editedImages.logo && editedImages.logo !== images.logo
        ? editedImages.logo
        : undefined,
    logoOg:
      editedImages.logoOg && editedImages.logoOg !== images.logoOg
        ? editedImages.logoOg
        : undefined,
    favicon:
      editedImages.favicon && editedImages.favicon !== images.favicon
        ? editedImages.favicon
        : undefined,
  },
  ogImageStatus:
    editedImageStatus !== ogImageStatus ? editedImageStatus : undefined,
});
