import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { LoginResponseDto, RegisterDeviceRequestDto } from '@tiendanube/common';
import { PlatformPush as PlatformPushEnum } from '@tiendanube/common/src/enums';
import axios from 'App/axios';
import { i18nConfig, Locale } from 'App/i18n/enums';
import { getStorage, setStorage } from 'App/storage';

const DEVICE_INFO = 'deviceInfo';
const AUTH = 'auth';
const IS_NATIVE_PLATFORM = Capacitor.isNativePlatform();
const MIN_FCM_TOKEN_LENGTH = 100;

const isValidFcmToken = (token = '') => token.length >= MIN_FCM_TOKEN_LENGTH;

type DeviceStorageType = {
  token: string;
  deviceId: string;
};

const hasDeviceTokenInStorage = async (
  forceUpdate = false,
): Promise<boolean> => {
  const deviceInfo = await getStorage<DeviceStorageType>(DEVICE_INFO);
  const isValidToken = isValidFcmToken(deviceInfo?.token);

  if (deviceInfo && (forceUpdate || !isValidToken)) {
    // if there is a token in storage that's not from FCM we remove it and regenerate.
    await saveDeviceInfo({ ...deviceInfo, token: '' });
    return false;
  }

  return deviceInfo && !!deviceInfo?.token && isValidToken;
};

export const checkAndRegisterDevice = async (
  forceUpdate = false,
): Promise<void> => {
  if (!IS_NATIVE_PLATFORM) return;

  const permissions = await PushNotifications.requestPermissions();

  const hasToken = await hasDeviceTokenInStorage(forceUpdate);
  if (hasToken) return;

  if (permissions.receive === 'granted') {
    PushNotifications.register();
  }
};

export const registerDeviceOnNuvemShop = async (): Promise<void> => {
  if (!IS_NATIVE_PLATFORM) return;

  const deviceInfoPromise = getStorage<DeviceStorageType>(DEVICE_INFO);
  const authPromise = getStorage<LoginResponseDto>(AUTH);
  const localePromise = getStorage<Locale>(i18nConfig.STORAGE);
  const [deviceInfo, { storeId }, locale] = await Promise.all([
    deviceInfoPromise,
    authPromise,
    localePromise,
  ]);

  if (
    !deviceInfo ||
    !deviceInfo.deviceId ||
    !deviceInfo.token ||
    !storeId ||
    !locale
  ) {
    return;
  }

  const payload: RegisterDeviceRequestDto = {
    storeId: Number(storeId),
    platform_push: PlatformPushEnum.FCM,
    token: deviceInfo.token,
    device_id: deviceInfo.deviceId,
    locale,
  };

  try {
    axios.post('/v1/devices', payload);
  } catch (error) {
    console.error(error);
  }
};

export const saveDeviceInfo = async (
  deviceInfo: DeviceStorageType,
): Promise<void> => {
  await setStorage(DEVICE_INFO, deviceInfo);
};
