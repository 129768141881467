import {
  DataList,
  IconButton,
  Label,
  Link,
  Stack,
  Text,
  Thumbnail,
} from '@tiendanube/components';
import OrganizeSectionLayout from '../OrganizeSectionLayout';

function SkeletonRow() {
  return (
    <Stack spacing="tight" align="flex-start">
      <Stack.Item>
        <Thumbnail.Skeleton width="56px" />
      </Stack.Item>
      <Stack.Item fill>
        <Stack column align="stretch" spacing="none">
          <Stack.Item>
            <Stack wrap justify="space-between" align="flex-start">
              <Stack.Item fill>
                <Link.Skeleton />
              </Stack.Item>
              <Stack.Item>
                <IconButton.Skeleton />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item fill>
            <Label.Skeleton />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

function OrganizeSectionSkeleton() {
  const rows = new Array(4).fill(0).map((_, i) => i.toString());

  return (
    <OrganizeSectionLayout link={<Text.Skeleton />}>
      <DataList spacing="tight">
        {rows.map((i) => (
          <DataList.Row key={i} id={`OrganizeSectionSkeleton-${i}`}>
            <DataList.Cell width="fill">
              <SkeletonRow />
            </DataList.Cell>
          </DataList.Row>
        ))}
      </DataList>
    </OrganizeSectionLayout>
  );
}

export default OrganizeSectionSkeleton;
