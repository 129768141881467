import {
  DataTable,
  IconButton,
  Popover,
  Stack,
  Text,
} from '@tiendanube/components';
import DraftOrderListHeaderDesktop from '../DraftOrderListHeaderDesktop';

function DraftOrderListDesktopSkeleton() {
  const rows = Array.from(Array(10).keys());

  return (
    <DataTable>
      <DraftOrderListHeaderDesktop />
      {rows.map((i) => (
        <DataTable.Row
          id="DraftOrderListDesktopSkeleton"
          key={`DraftOrderListDesktopSkeleton-${i}`}
        >
          <DataTable.Cell grow={0} basis={120}>
            <Text.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={80}>
            <Text.Skeleton width="small" />
          </DataTable.Cell>
          <DataTable.Cell grow={1} basis={100}>
            <Text.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={200}>
            <Text.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={200}>
            <Popover.Skeleton />
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={125}>
            <Stack>
              <IconButton.Skeleton />
              <IconButton.Skeleton />
            </Stack>
          </DataTable.Cell>
        </DataTable.Row>
      ))}
    </DataTable>
  );
}

export default DraftOrderListDesktopSkeleton;
