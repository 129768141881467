import { ChevronDownIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@tiendanube/components';
import { AplicationsProps } from '../types';

function ApplicationsDesktop({ applications }: Readonly<AplicationsProps>) {
  const { t } = useTranslation('common');

  if (applications.length === 0) return null;

  return (
    <Popover
      name="myApplications"
      menu={applications}
      renderInitiator={(onClick) => (
        <Button onClick={onClick} icon={ChevronDownIcon} iconPosition="end">
          {t('myApps')}
        </Button>
      )}
    />
  );
}

export default ApplicationsDesktop;
