import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getUpdateInternationalShippingDetailStatus,
  updateInternationalShippingDetail as updateInternationalShippingDetailAction,
  cleanUpdateInternationalShippingDetail,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';
import { ValuesInterface } from '../../../DeliveryMethodsPage/components';

function useUpdateInternationalShippingDetail() {
  const dispatch = useAppDispatch();
  const status = useSelector(getUpdateInternationalShippingDetailStatus);

  const updateInternationalShippingDetail = useCallback(
    async (id: string, payload: ValuesInterface) => {
      await dispatch(updateInternationalShippingDetailAction({ id, payload }));
      dispatch(cleanUpdateInternationalShippingDetail());
    },
    [dispatch],
  );
  return { status, updateInternationalShippingDetail };
}
export default useUpdateInternationalShippingDetail;
