import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import {
  EmptySearch,
  ErrorState,
  EmptyState,
  Text,
} from '@tiendanube/components';
import VirtualDataList from 'commons/components/VirtualDataList';
import { useCustomerResults } from 'domains/Customers/Customers/pages/CustomerListPage/hooks';
import { CustomerListItem, CustomerResultsMobileSkeleton } from './components';
import emptyCustomers from '../../empty-customers.png';

function CustomerResultsMobile(): JSX.Element {
  const { t } = useTranslation([DomainEnum.CUSTOMERS]);

  const {
    isRefreshError,
    isRefreshSuccess,
    isRefreshing,
    customersIds,
    hasFilters,
    refreshCustomers,
    getMoreCustomers,
  } = useCustomerResults();

  const isEmptyList = customersIds.length === 0;
  const shouldShowSpinner = isRefreshing && customersIds.length !== 0;

  if (isRefreshing && isEmptyList) return <CustomerResultsMobileSkeleton />;
  if (isRefreshError)
    return (
      <ErrorState
        title={t('errorResults.title')}
        action={{
          children: t('errorResults.action'),
          onClick: refreshCustomers,
        }}
      />
    );
  if (isRefreshSuccess && isEmptyList) {
    if (hasFilters) {
      return (
        <EmptySearch
          text={t('withoutResults.text')}
          title={t('withoutResults.title')}
        />
      );
    }
    return (
      <EmptyState image={emptyCustomers} title={t('emptyResults.title')}>
        <Text>{`${t('emptyResults.body')}`}</Text>
      </EmptyState>
    );
  }

  return (
    <VirtualDataList
      onRefresh={refreshCustomers}
      isPullable
      data={customersIds}
      endReached={getMoreCustomers}
      itemContent={(index, id) => <CustomerListItem key={index} id={id} />}
      showFooter={shouldShowSpinner}
    />
  );
}

export default CustomerResultsMobile;
