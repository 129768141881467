import { MetafieldSelectedsOrErrorListV2Type } from '@tiendanube/common';
import axios from 'App/axios';

const getCategoriesMetafields = async (
  categoryId: string,
): Promise<MetafieldSelectedsOrErrorListV2Type> => {
  const { data } = await axios.get(`v1/categories/${categoryId}/metafields`);
  return data;
};

const categoriesMetafieldsService = {
  getCategoriesMetafields,
};

export default categoriesMetafieldsService;
