import { FEATURE_EDIT_ORDER } from 'App/features';
import { useStatusFeatureByKeyWithDefaultValue } from 'domains/Auth/hooks';

function useIsEditOrdersEnabled() {
  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_EDIT_ORDER,
    0,
  );

  return isAvailable;
}

export default useIsEditOrdersEnabled;
