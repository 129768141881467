import { useSelector } from 'react-redux';
import { TransactionFeeDetailResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getTransactionListItemById } from '../../../TransactionFeesSlice';

interface UseTransactionListItemInterface {
  transaction: TransactionFeeDetailResponseDto;
}

function useTransactionListItem(id: string): UseTransactionListItemInterface {
  const transactionItem = useSelector((state: RootStateType) =>
    getTransactionListItemById(state, id),
  );

  return { transaction: transactionItem };
}

export default useTransactionListItem;
