import { Route, Switch } from 'react-router';
import { infoMessageRoutes } from './infoMessageRoutes';
import { InfoMessagePage } from './pages/InfoMessagePage';

export function InfoMessage() {
  return (
    <Switch>
      <Route path={infoMessageRoutes.infoMessage} exact>
        <InfoMessagePage />
      </Route>
    </Switch>
  );
}
