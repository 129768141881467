import {
  Text,
  Stack,
  Label,
  DataList,
  IconButton,
} from '@tiendanube/components';

function MessagesListItemSkeleton(): JSX.Element {
  const { Row, Cell } = DataList;
  return (
    <Row id="skeleton">
      <Cell width={60} rowTitle>
        <Text.Skeleton width="fill" />
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton width="fill" />
      </Cell>
      <Cell width={100}>
        <Text.Skeleton size="featured" width="fill" />
      </Cell>
      <Cell width={70} extraPadding>
        <Stack spacing="tight">
          <Stack.Item>
            <Label.Skeleton />
          </Stack.Item>
          <Stack.Item>
            <Label.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell width={30} alignRight>
        <IconButton.Skeleton />
      </Cell>
    </Row>
  );
}

export default MessagesListItemSkeleton;
