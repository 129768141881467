import { ConversionType } from 'domains/Statistics/types';
import { CHART_COLORS } from 'domains/Statistics/utils';

export const LABELS: Record<ConversionType, string> = {
  createdCarts: 'statistics.orderConversionCard.steps.createdCarts',
  clickOnBuy: 'statistics.orderConversionCard.steps.clickOnBuy',
  personalDataCompleted:
    'statistics.orderConversionCard.steps.personalDataCompleted',
  shippingAddressCompleted:
    'statistics.orderConversionCard.steps.shippingAddressCompleted',
  shippingMethodSelected:
    'statistics.orderConversionCard.steps.shippingMethodSelected',
  paymentAdded: 'statistics.orderConversionCard.steps.paymentAdded',
  convertedCarts: 'statistics.orderConversionCard.steps.convertedCarts',
  cartsThatCanBeConvertedIntoSales:
    'statistics.orderConversionCard.steps.totalCarts',
};

export const PERCENTAGE_NUMBER_COLORS = {
  convertedCarts: 'primary-interactive',
  cartsThatCanBeConvertedIntoSales: 'danger-interactive',
};

export const CONVERSION_BAR_COLORS = {
  convertedCarts: CHART_COLORS.skyBlue,
  cartsThatCanBeConvertedIntoSales: CHART_COLORS.darkRed,
};
