import {
  BaseCard,
  InterfaceNameValue,
  RadioButtonGroup,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import RoundPricesOption from '../RoundPricesOption';
import { ActionEnum, UpdateByEnum } from '../types';
import VariationInput from '../VariationInput';
import VariationTypeList from '../VariationTypeList';

interface EditPromotionalPriceProps {
  promoPriceVariation: string;
  promoPriceVariationAction: ActionEnum;
  promoPriceVariationType: UpdateByEnum;
  roundPromoPrices: boolean;
  showAlertCreate: boolean;
  error: string;
  handleChangeRoundPromoPrices: (round: boolean) => void;
  handleChange: (event: InterfaceNameValue) => void;
}

function EditPromotionalPrice({
  promoPriceVariation,
  promoPriceVariationAction,
  promoPriceVariationType,
  roundPromoPrices,
  showAlertCreate,
  error,
  handleChangeRoundPromoPrices,
  handleChange,
}: EditPromotionalPriceProps): JSX.Element {
  const t = useTranslationCatalog();

  const options = [
    {
      label: t('products.editPrice.create'),
      value: ActionEnum.REPLACE,
    },
    {
      label: t('products.editPrice.increase'),
      value: ActionEnum.INCREASE,
    },
    {
      label: t('products.editPrice.decrease'),
      value: ActionEnum.DECREASE,
    },
  ];

  const handlePromoPriceVariationTypeChange = (type: UpdateByEnum) => {
    handleChange({ name: 'promoPriceVariationType', value: type });
  };

  const showRoundPrices = promoPriceVariationType === UpdateByEnum.PERCENTAGE;

  const info = {
    [ActionEnum.REPLACE]: t('products.editPrice.infoCreate'),
    [ActionEnum.INCREASE]: t('products.editPrice.infoIncrease'),
    [ActionEnum.DECREASE]: t('products.editPrice.infoDecrease'),
  }[promoPriceVariationAction];

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t('products.editPrice.promotionalPrice')}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="base">
          <Stack.Item>
            <RadioButtonGroup
              name="promoPriceVariationAction"
              options={options}
              value={promoPriceVariationAction}
              onChange={handleChange}
            />
          </Stack.Item>
          <Stack.Item fill>
            <Text size="caption">{info}</Text>
          </Stack.Item>
          <Stack.Item>
            <VariationTypeList
              variationType={promoPriceVariationType}
              onChange={handlePromoPriceVariationTypeChange}
              index={1}
            />
          </Stack.Item>
          <Stack.Item>
            <VariationInput
              name="promoPriceVariation"
              variation={promoPriceVariation}
              variationType={promoPriceVariationType}
              onChange={handleChange}
              error={error}
            />
          </Stack.Item>
          {showRoundPrices && (
            <Stack.Item>
              <RoundPricesOption
                roundPrices={roundPromoPrices}
                onChange={handleChangeRoundPromoPrices}
                name="roundPromoPricesCheckbox"
              />
            </Stack.Item>
          )}
          {showAlertCreate && (
            <Stack.Item>
              <Text size="caption" appearance="light" background>
                {t('products.editPrice.alertCreate')}
              </Text>
            </Stack.Item>
          )}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default EditPromotionalPrice;
