import { AdminStatusType } from '@tiendanube/common';
import { useStoreStatus } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const ADMIN_DOWN_STATUS: AdminStatusType = 'admin_down';

/**
 * Provides a title based on the store's status.
 * If the store is churned (applications are deactivated), the title only shows "plan" instead of "plan and applications".
 */
function useStoreStatusTitle() {
  const { storeStatus } = useStoreStatus();
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps',
  );

  const titleBasedOnStoreStatus =
    storeStatus === ADMIN_DOWN_STATUS
      ? t('titleWithoutApps')
      : t('titleWithApps');

  return storeStatus ? titleBasedOnStoreStatus : t('titleWithApps');
}

export { useStoreStatusTitle };
