import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  useGetInternationalShippingDetail,
  useUpdateInternationalShippingDetail,
} from './hooks';
import ShippingPage from '../components/ShippingPage';
import { InternationalShippingForm } from '../DeliveryMethodsPage/components';
import { newInternationalShippingSchema } from '../NewInternationalShippingPage/newInternationalShippingSchema';

function EditInternationalShippingPage() {
  const t = useTranslationShipping();
  const { id } = useParams<{ id: string }>();
  const { goBack } = useNavegate();
  const {
    internationalShippingDetail,
    fetchInternationalShippingDetail,
    isError,
    isLoading,
  } = useGetInternationalShippingDetail();

  const { status, updateInternationalShippingDetail } =
    useUpdateInternationalShippingDetail();

  const { values, handleChange, errors, handleOnSubmit, isDirty } = useForm({
    initialValues: internationalShippingDetail,
    validationSchema: newInternationalShippingSchema,
    onSubmit: (data) => {
      if (data) updateInternationalShippingDetail(id, data);
    },
  });

  const handleonRetry = () => {
    fetchInternationalShippingDetail(id);
  };

  useToastStatus({
    status,
    success: t(
      'deliveryMethods.internationalShippingMethods.toastSuccess.edited',
    ),
    error: t('deliveryMethods.internationalShippingMethods.toastError.edition'),
    onSuccess: goBack,
  });

  useEffect(() => {
    if (internationalShippingDetail?.id !== id)
      fetchInternationalShippingDetail(id);
  }, [fetchInternationalShippingDetail, id, internationalShippingDetail]);

  return (
    <ShippingPage
      handleSave={handleOnSubmit}
      title={t('deliveryMethods.internationalShippingMethods.titleEdit')}
      isLoading={isLoading}
      isUpdating={status === 'loading'}
      isError={isError}
    >
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />
      {isLoading && <InternationalShippingForm.Skeleton />}
      {isError && (
        <ErrorScreen
          onRetry={handleonRetry}
          description={t(
            'deliveryMethods.activeShippingMethods.error.description',
          )}
        />
      )}
      {values && !isError && !isLoading && (
        <InternationalShippingForm
          values={values}
          handleChange={handleChange}
          errors={errors}
        />
      )}
    </ShippingPage>
  );
}
export default EditInternationalShippingPage;
