import { Alert, Link, Text } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { BrokenVariantsAlertType } from '../../../../types';

interface BrokenVariantsAlertProps {
  alertType: BrokenVariantsAlertType;
}

const ALERT_CONTENT_PREFIX: Record<BrokenVariantsAlertType, string> = {
  UNNAMED_VARIANTS: 'unnamedVariants',
  WRONG_VALUES: 'wrongValues',
};

function BrokenVariantsAlert({ alertType }: BrokenVariantsAlertProps) {
  const t = useTranslationCatalog();
  const alertPrefix = ALERT_CONTENT_PREFIX[alertType];

  return (
    <Alert
      show
      title={t(`products.detail.brokenVariantsAlert.${alertPrefix}.title`)}
      appearance="danger"
    >
      <Text>
        {t(`products.detail.brokenVariantsAlert.${alertPrefix}.text`)}
        <Link
          href={t('products.detail.brokenVariantsAlert.helpLink')}
          target="_blank"
          size="base"
          underline
        >
          {t('products.detail.brokenVariantsAlert.contactUs')}
        </Link>
        .
      </Text>
    </Alert>
  );
}

export default BrokenVariantsAlert;
