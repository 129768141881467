import { Card, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { toStatusType } from 'App/rtk';
import { Stack, SubmitButton } from 'commons/components';
import { useAutoFocus, useForm, useToastStatus } from 'commons/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useCreateSeoRedirectsListMutation } from 'domains/Configurations/SeoRedirects/seoRedirectsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

const initialValues = {
  urlOld: '',
  urlNew: '',
};

function SeoRedirectsForm() {
  const t = useTranslationConfigurations('seoRedirects.seoRedirectsForm');
  const { ref } = useAutoFocus();
  const [createSeoRedirects, { status, isLoading }] =
    useCreateSeoRedirectsListMutation();
  const { t: tCommon } = useTranslation('common');
  const isMobile = useIsMobileDevice();
  const { values, handleOnSubmit, handleChange, isDirty, resetValues, errors } =
    useForm({
      initialValues,
      onSubmit: createSeoRedirects,
    });

  useToastStatus({
    status: toStatusType(status),
    error: t('toast.error'),
    success: t('toast.success'),
    onSuccess: resetValues,
  });

  const textButton = isLoading ? 'ctaLoading' : 'cta';

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack innerRef={ref} column align="stretch">
          <Text>{t('text')}</Text>
          <FormField.Input
            autoFocus={!isMobile}
            value={values.urlOld}
            name="urlOld"
            label={t('inputOldUrlLabel')}
            onChange={handleChange}
            placeholder={t('inputOldUrlPlaceholder')}
            helpText={errors.urlOld ? t(errors.urlOld) : ''}
            appearance={errors.urlOld ? 'danger' : undefined}
          />
          <FormField.Input
            value={values.urlNew}
            name="urlNew"
            onChange={handleChange}
            label={t('inputNewUrlLabel')}
            placeholder={t('inputNewUrlPlaceholder')}
            helpText={errors.urlNew ? t(errors.urlNew) : ''}
            appearance={errors.urlNew ? 'danger' : undefined}
          />
          <SubmitButton
            isLoading={isLoading}
            disabled={!values.urlNew || !values.urlOld}
            onClick={handleOnSubmit}
          >
            {t(textButton)}
          </SubmitButton>
        </Stack>
      </Card.Body>
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
    </Card>
  );
}

export default SeoRedirectsForm;
