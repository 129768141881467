import { SuggestionsDto } from '@tiendanube/common';
import { BaseCard, Title } from '@tiendanube/components';
import { useTranslationAccount } from 'domains/Account/hooks';
import {
  ReasonsInterface,
  SubReasonsInterface,
} from 'domains/Account/hooks/useShopCancellation';
import CancelAndContinue from '../CancelAndContinue';
import ReasonsCard from '../Cards/ReasonsCard';
import SuggestionsCard from '../Cards/SuggestionsCard';

interface ShopCancellationSteptwoProps {
  onContinue: () => void;
  onCancel: () => void;
  reasons: ReasonsInterface | null;
  setReason: (label: string) => void;
  setSubReason: (label: string) => void;
  subReasons: SubReasonsInterface | null;
  suggestions?: SuggestionsDto | null;
  isLoading: boolean;
}

function ShopCancellationSteptwo({
  onContinue,
  onCancel,
  reasons,
  setReason,
  setSubReason,
  subReasons,
  suggestions,
  isLoading,
}: ShopCancellationSteptwoProps): JSX.Element {
  const t = useTranslationAccount();

  const handleOnChangeSubReason = ({ value }) => setSubReason(value);
  const handleOnChangeReason = ({ value }) => setReason(value);
  return (
    <>
      <BaseCard>
        <BaseCard.Header>
          <Title type="h3">{`${t(
            'shopCancellationPage.stepTwo.yourOpinion',
          )}`}</Title>
        </BaseCard.Header>
        <BaseCard.Body>
          <ReasonsCard
            isLoading={isLoading}
            onChangeSubReason={handleOnChangeSubReason}
            onChangeReason={handleOnChangeReason}
            reasons={reasons?.reasons}
            reasonSelected={reasons?.selected?.value}
            subReasons={subReasons?.subReasons?.values}
            subReasonSelected={subReasons?.selected?.value}
            subReasonsDefault={subReasons?.subReasons?.default}
          />
        </BaseCard.Body>
        <BaseCard.Footer>
          {!suggestions && (
            <CancelAndContinue
              onCancel={onCancel}
              onContinue={onContinue}
              disableNext={!reasons?.selected}
            />
          )}
        </BaseCard.Footer>
      </BaseCard>
      {suggestions && (
        <SuggestionsCard
          title={suggestions.title}
          subTitle={suggestions.description}
          links={suggestions.helpLinks}
          onCancel={onCancel}
          onContinue={onContinue}
        />
      )}
    </>
  );
}

export default ShopCancellationSteptwo;
