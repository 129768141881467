import {
  CheckoutTrackingCodeRequestDto,
  ExternalCodesRequestDto,
  ExternalCodesResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import {
  BASE_EXTERNAL_CODES_URL,
  DELETE_FBE_CODE,
  ExternalCodesEndpoints,
  ExternalCodesTags,
  REQUEST_CHECKOUT_CODE_APROVAL,
} from './config';

export const ExternalCodesApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [ExternalCodesTags.getExternalCodes],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [ExternalCodesEndpoints.getExternalCodes]: builder.query<
        ExternalCodesResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_EXTERNAL_CODES_URL,
        }),
        providesTags: [ExternalCodesTags.getExternalCodes],
      }),
      [ExternalCodesEndpoints.updateExternalCodes]: builder.mutation<
        void,
        ExternalCodesRequestDto
      >({
        query: (data) => ({
          method: 'PATCH',
          url: BASE_EXTERNAL_CODES_URL,
          data,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [ExternalCodesTags.getExternalCodes],
      }),
      [ExternalCodesEndpoints.deleteFacebookCode]: builder.mutation<void, void>(
        {
          query: () => ({
            method: 'DELETE',
            url: DELETE_FBE_CODE,
          }),
          invalidatesTags: (_, error) =>
            error ? [] : [ExternalCodesTags.getExternalCodes],
        },
      ),
      [ExternalCodesEndpoints.postCheckoutCodeRequest]: builder.mutation<
        void,
        CheckoutTrackingCodeRequestDto
      >({
        query: (data) => ({
          method: 'POST',
          url: REQUEST_CHECKOUT_CODE_APROVAL,
          data,
        }),
      }),
    }),
  });

export const {
  useGetExternalCodesQuery,
  useUpdateExternalCodesMutation,
  useDeleteFacebookCodeMutation,
  usePostCheckoutCodeRequestMutation,
} = ExternalCodesApi;
