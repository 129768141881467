import { FormField } from '@nimbus-ds/patterns';
import { i18nRequestDto } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { DEFAULT_LANGUAGE } from 'App/i18n';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface ContactTextInputProps {
  contactText: i18nRequestDto;
  language?: string;
  handleChangeGroup: (group: string, data: InterfaceNameValue) => void;
}

function ContactTextInput({
  contactText,
  language = DEFAULT_LANGUAGE,
  handleChangeGroup,
}: ContactTextInputProps) {
  const t = useTranslationOnline('contactInfoPage');

  const handleChangeContactText = (event) =>
    handleChangeGroup('contactText', {
      name: language,
      value: event.target.value,
    });

  return (
    <FormField.Textarea
      id="contactText"
      label={t('contactText')}
      helpText={t('contactTextInfo')}
      onChange={handleChangeContactText}
      defaultValue={contactText?.[language] || ''}
      showHelpText
    />
  );
}

export default ContactTextInput;
