import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchTimelineById as fetchTimelineByIdAction,
  getTimeline,
  getTimelineStatus,
} from 'domains/Orders/Orders/ordersSlice';

interface useTimelineSectionProps {
  orderId: string;
  fetch?: boolean;
}

function useTimelineSection({ orderId, fetch }: useTimelineSectionProps) {
  const dispatch = useAppDispatch();
  const status = useSelector(getTimelineStatus);
  const logEntries = useSelector(getTimeline);

  const fetchTimelineById = useCallback(() => {
    dispatch(fetchTimelineByIdAction({ id: orderId, setLoading: true }));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (fetch) {
      fetchTimelineById();
    }
  }, [dispatch, orderId, fetchTimelineById, fetch]);

  return {
    fetchTimelineById,
    logEntries,
    status,
  };
}

export default useTimelineSection;
