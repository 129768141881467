import { useCallback, useState } from 'react';
import {
  AuthenticationFactorResponseDto,
  AuthenticationFactorType,
} from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';

export enum Steps {
  List,
  ConfirmIdentity,
  SetUpAccount,
  LinkAccount,
  RecoveryCodes,
}

export enum Actions {
  Activating,
  Deactivating,
  Viewing,
}

interface UseStepsInitialResult {
  step: Steps.List;
  startFlow: (type: AuthenticationFactorType) => () => void;
}

interface UseStepsFlowStartedResult {
  step: Exclude<Steps, Steps.List>;
  currentAction: Actions;
  currentFactor: AuthenticationFactorType;
  currentFactorIsActive: boolean;
  nextStep: () => void;
  backStep: () => void;
}

type UseStepsResult = UseStepsInitialResult | UseStepsFlowStartedResult;

const FIRST_STEP_AUTH = {
  [AuthenticationFactorTypes.TOTP]: Steps.ConfirmIdentity,
  [AuthenticationFactorTypes.RECOVERY_CODE]: Steps.LinkAccount,
};
const NEXT_STEP_AUTH = {
  [AuthenticationFactorTypes.TOTP]: Steps.List,
  [AuthenticationFactorTypes.RECOVERY_CODE]: Steps.RecoveryCodes,
};
const getConfirmIdentityNextStep = (currentFactorIsActive: boolean) =>
  currentFactorIsActive ? Steps.LinkAccount : Steps.SetUpAccount;

const calculateAction = (
  currentFactorIsActive: boolean,
  type: AuthenticationFactorType,
): Actions => {
  switch (type) {
    case AuthenticationFactorTypes.TOTP:
      return currentFactorIsActive ? Actions.Deactivating : Actions.Activating;
    case AuthenticationFactorTypes.RECOVERY_CODE:
      return currentFactorIsActive ? Actions.Viewing : Actions.Activating;
  }
};

export function useSteps(
  authenticationFactors: AuthenticationFactorResponseDto[],
  fetchAuthenticationFactors: () => void,
): UseStepsResult {
  const [step, setStep] = useState(Steps.List);
  const [currentFactor, setCurrentFactor] = useState<AuthenticationFactorType>(
    AuthenticationFactorTypes.TOTP,
  );
  const [currentAction, setCurrentAction] = useState(Actions.Activating);

  const factorIsActive = (type: AuthenticationFactorType) =>
    authenticationFactors.some(
      (factor) => factor.type === type && factor.active,
    );
  const currentFactorIsActive = factorIsActive(currentFactor);

  const nextStep = useCallback(() => {
    if (step === Steps.ConfirmIdentity) {
      setStep(getConfirmIdentityNextStep(currentFactorIsActive));
    }
    if (step === Steps.SetUpAccount) setStep(Steps.LinkAccount);
    if (step === Steps.LinkAccount) {
      if (currentAction !== Actions.Viewing) {
        fetchAuthenticationFactors();
      }
      setStep(NEXT_STEP_AUTH[currentFactor]);
    }
    if (step === Steps.RecoveryCodes) setStep(Steps.List);
  }, [
    currentAction,
    currentFactor,
    currentFactorIsActive,
    fetchAuthenticationFactors,
    step,
  ]);

  const backStep = () => setStep(Steps.List);

  const startFlow = (type: AuthenticationFactorType) => () => {
    setCurrentFactor(type);
    setCurrentAction(calculateAction(factorIsActive(type), type));
    setStep(FIRST_STEP_AUTH[type]);
  };

  return step === Steps.List
    ? { step, startFlow }
    : {
        step,
        currentAction,
        currentFactor,
        currentFactorIsActive,
        nextStep,
        backStep,
      };
}
