import { Tag, Text } from '@nimbus-ds/components';
import { LocationIcon, TruckIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { useNavegate } from 'App/hooks';
import {
  IonPageStratus,
  HeaderContent,
  HeaderTop,
  Stack,
} from 'commons/components';
import { useHasAllowedMultipleLocation } from 'domains/Auth/hooks';
import { trackingShippingDistributionCenterCreatedNavigation } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import './NewLocationCreatedPage.scss';

function NewLocationCreatedPage(): JSX.Element {
  const t = useTranslationShipping();

  const title = t(`locations.firstLocationCreated.title`);
  const subtitle = t(`locations.firstLocationCreated.subtitle`);

  const { goTo, goBack } = useNavegate();
  const backNavitation = { onClick: () => goBack() };
  const hasAllowedMultipleLocation = useHasAllowedMultipleLocation();

  const goToDeliveryMethods = () => {
    goTo('/settings/shipping-methods');
    trackingShippingDistributionCenterCreatedNavigation('deliveryMethods');
  };

  const goToLocations = () => {
    goTo('/settings/locations');
    trackingShippingDistributionCenterCreatedNavigation('locations');
  };

  const notAllowedClick = () => {
    trackingShippingDistributionCenterCreatedNavigation('comingSoon');
  };

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavitation} />}
      headerContent={<HeaderContent title={title} subtitle={subtitle} />}
      width="small"
    >
      <div className="stratus--new-location-created-page">
        <Stack column align="stretch" spacing="base">
          <Stack.Item>
            <CalloutCard
              appearance="primary"
              icon={TruckIcon}
              title={t(
                'locations.firstLocationCreated.goToDeliveryMethods.title',
              )}
              subtitle={t(
                `locations.firstLocationCreated.goToDeliveryMethods.subtitle`,
              )}
              onClick={goToDeliveryMethods}
            />
          </Stack.Item>
          <Stack.Item>
            {hasAllowedMultipleLocation && (
              <CalloutCard
                appearance="primary"
                icon={LocationIcon}
                title={t(
                  `locations.firstLocationCreated.goToCreateLocations.title`,
                )}
                subtitle={t(
                  `locations.firstLocationCreated.goToCreateLocations.subtitle`,
                )}
                onClick={goToLocations}
              />
            )}
            {!hasAllowedMultipleLocation && (
              <CalloutCard
                appearance="neutral"
                icon={LocationIcon}
                title={t(
                  `locations.firstLocationCreated.goToCreateLocations.title`,
                )}
                subtitle={t(
                  `locations.firstLocationCreated.goToCreateLocations.subtitle`,
                )}
                link={
                  <Tag>
                    <Text fontSize="caption" lineHeight="caption">
                      {t('locations.multiLocations.comingSoon')}
                    </Text>
                  </Tag>
                }
                onClick={notAllowedClick}
              />
            )}
          </Stack.Item>
        </Stack>
      </div>
    </IonPageStratus>
  );
}

export default NewLocationCreatedPage;
