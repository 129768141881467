import { MetafieldV2Dto } from '@tiendanube/common';
import { ErrorState, Title } from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { StatusObjectType } from 'commons/types';
import { MetafieldItem } from 'domains/Metafields/components';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import {
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
  SourceType,
} from 'domains/Metafields/types';

interface MetafieldsFiltersProps {
  metafields: MetafieldV2Dto[];
  status: StatusObjectType;
  selecteds: MetafieldSelectedInterface[];
  onChange: OnChangeMetafieldType;
  onFetch: () => void;
}

function MetafieldsFilters({
  metafields,
  status,
  selecteds,
  onChange,
  onFetch,
}: MetafieldsFiltersProps): JSX.Element | null {
  const t = useTranslationsMetafields();

  const title = t('title');
  const selectFirstOption = t('metafields:select.all');

  if (!status.isLoading && metafields.length === 0) return null;

  return (
    <Stack column align="stretch">
      <Title type="h5">{title}</Title>
      {status.isLoading && <MetafieldItem.Skeleton />}
      {status.isSuccess &&
        metafields.map((metafield, index) => {
          const found = selecteds.find(
            (current) => current.id === metafield.id,
          );
          const value = found ? found.value || '' : '';
          return (
            <MetafieldItem
              selectFirstOption={selectFirstOption}
              source={SourceType.ADMIN}
              metafield={metafield}
              disabled={false}
              value={value}
              key={index}
              onChange={onChange}
            />
          );
        })}
      {status.isError && (
        <ErrorState
          title={t('errorState.title')}
          action={{
            children: t('errorState.action'),
            onClick: onFetch,
          }}
        />
      )}
    </Stack>
  );
}

export default MetafieldsFilters;
