import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getNotificationDataSelector,
  getNotificationStatusLoadSelector,
} from '../paymentNotificationSlice/paymentNotificationSelector/paymentNotificationSelector';
import {
  cleanLoadStatusAction,
  fetchNotifications as fetchNotificationsAction,
  deleteNotification as deleteNotificationAction,
} from '../paymentNotificationSlice/paymentNotificationSlice';

function usePaymentNotification() {
  const dispatch = useAppDispatch();
  const notifications = useSelector(getNotificationDataSelector);
  const statusLoad = useSelector(getNotificationStatusLoadSelector);

  const fetchNotifications = useCallback(() => {
    dispatch(fetchNotificationsAction());
  }, [dispatch]);

  const deleteNotification = useCallback(
    async (notificationId: string) => {
      await dispatch(deleteNotificationAction(notificationId));
      dispatch(cleanLoadStatusAction());
    },
    [dispatch],
  );

  return {
    notifications,
    statusLoad,
    fetchNotifications,
    deleteNotification,
  };
}

export default usePaymentNotification;
