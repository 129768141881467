import { ReactNode } from 'react';
import { Box } from '@nimbus-ds/components';

export interface InterfaceStackItem {
  /** React node of type children */
  children?: ReactNode;
  /** Define whether the item should stretch beyond it's limit */
  fill?: boolean;
}

/**
 * @param children React node of type children
 * @param fill Define whether the item should stretch beyond it's limit
 */
function Item({ children, fill = false }: InterfaceStackItem): JSX.Element {
  const flex = fill ? '1 1 auto' : '0 1 auto';
  return <Box flex={flex}>{children}</Box>;
}

export default Item;
