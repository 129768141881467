import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceNameValue } from '@tiendanube/components';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { useToast } from 'commons/hooks';
import {
  useHasCatalogInventory,
  useHasPermission,
  useIsSeller,
} from 'domains/Auth/hooks';
import useDuplicateAttributes from 'domains/Catalog/Products/pages/components/Variants/hooks/useDuplicateAttributes';
import {
  useEditVariants,
  useVariantsStock,
} from 'domains/Catalog/Products/pages/hooks';
import { trackingProductEditOnProductList } from 'domains/Catalog/Products/tracking';
import useProductItemList from '../useProductItemList';

function useEditVariantsValues(productId: string) {
  const { t } = useTranslation(Domain.CATALOG);
  const { addToast } = useToast();
  const { product } = useProductItemList(productId);
  const { editStock, editVariants } = useEditVariants();
  const variantsStock = useVariantsStock();
  const hasCatalogInventory = useHasCatalogInventory();
  const { jobStatus } = useBackgroundJobs('editPricesProducts');
  const { withDuplicateAttributesMainLanguage } =
    useDuplicateAttributes(productId);
  const isSeller = useIsSeller();
  const enableEditPrice = useHasPermission('edit_price');
  const enableEditStock = useHasPermission('edit_stock');

  const handleEditVariant =
    (id: string) =>
    async ({ value, name }: InterfaceNameValue) => {
      if (name === 'stock') {
        if (isSeller) {
          trackingProductEditOnProductList('variant_stock_change');
        }
        await editStock({
          productId: product.id,
          action: 'replace',
          value: value !== '' ? Number(value) : null,
          variantId: id,
        });
      } else {
        if (name === 'price' && isSeller) {
          trackingProductEditOnProductList('variant_price_change');
        }
        await editVariants({
          productId: product.id,
          variant: {
            id,
            [name]: Number(value),
          },
        });
      }
    };

  const onErrorStock = () => {
    addToast({
      appearance: 'danger',
      label: t('products.toast.errorUpdateStock'),
    });
  };

  const onErrorPrice = () => {
    addToast({
      appearance: 'danger',
      label: t('products.toast.errorUpdatePrices'),
    });
  };

  return {
    hasCatalogInventory,
    variantsStock,
    enableEditStock,
    withDuplicateAttributesMainLanguage,
    enableEditPrice,
    jobStatus,
    handleEditVariant,
    onErrorStock,
    onErrorPrice,
  };
}

export default useEditVariantsValues;
