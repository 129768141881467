import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  createFiscalData as createFiscalDataAction,
  updateFiscalData as updateFiscalDataAction,
  cleanFiscalData,
  cleanAddresses,
  getFiscalDataStatus,
  getCityId,
  getProvinceId,
} from 'domains/Billing/Invoices/invoicesSlice';
import { FiscalDataformBrazil } from './components';

function useBrazilianFiscalData() {
  const dispatch = useAppDispatch();
  const status = useSelector(getFiscalDataStatus);
  const cityId = Number(useSelector(getCityId));
  const provinceId = Number(useSelector(getProvinceId));

  const cleanData = useCallback(() => {
    dispatch(cleanFiscalData());
    dispatch(cleanAddresses());
  }, [dispatch]);

  const createFiscalData = async ({
    name,
    complement,
    neighborhood,
    number,
    numberId,
    street,
    zipcode,
  }: FiscalDataformBrazil) => {
    await dispatch(
      createFiscalDataAction({
        name,
        numberId: numberId,
        zipcode: Number(zipcode),
        number: Number(number),
        address: street,
        cityId,
        floor: complement,
        locality: neighborhood,
        provinceId,
      }),
    );
    cleanData();
  };

  const updateFiscalData = async (
    {
      name,
      complement,
      neighborhood,
      number,
      numberId,
      street,
      zipcode,
      exemptFromStateRegistration,
      stateRegistration,
      doesNotHaveStateRegistration,
    }: FiscalDataformBrazil,
    id?: number,
  ) => {
    await dispatch(
      updateFiscalDataAction({
        name,
        numberId: numberId,
        zipcode: Number(zipcode),
        number: Number(number),
        address: street,
        cityId,
        floor: complement,
        locality: neighborhood,
        provinceId,
        exemptFromStateRegistration,
        stateRegistration,
        doesNotHaveStateRegistration,
        id,
      }),
    );
    cleanData();
  };

  return {
    cleanData,
    createFiscalData,
    updateFiscalData,
    status,
  };
}

export default useBrazilianFiscalData;
