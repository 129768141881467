import { useEffect } from 'react';
import { Box, Text, Title } from '@nimbus-ds/components';
import { useParams } from 'react-router';
import { Alert, Popover } from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
  RemarksCard,
} from 'commons/components';
import { HELP_LINKS_CUSTOMER_DETAILS } from 'commons/constants';
import {
  useAsyncFunc,
  useModal,
  useToast,
  useTranslationLanguage,
} from 'commons/hooks';
import { dateFormat, Format } from 'commons/utils/date';
import { openWindow } from 'commons/utils/window';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import {
  CustomerAnonymizeAlert,
  CustomerDataCard,
  CustomerDetailsPageSkeleton,
  CustomerDetailsPageError,
  DeleteCustomerModal,
  RemoveCustomerDataModal,
  CustomerActivity,
} from './components';
import {
  useAnonymizeCustomer,
  useCustomerActions,
  useCustomerDetails,
} from './hooks';
import useCustomerMetafields from './hooks/useCustomerMetafields';

function CustomerDetailsPage() {
  const id = useParams<{ id: string }>().id;
  const t = useTranslationCustomers();
  const language = useTranslationLanguage();
  const { goBack, replace } = useNavegate();
  const { addToast } = useToast();
  const [
    isShowDeleteCustomerModal,
    openDeleteCustomerModal,
    closeDeleteCustomerModal,
  ] = useModal();
  const {
    customerDetails,
    isLoading,
    isError,
    isSuccess,
    fetchCustomerDetails,
    updateCustomerNote,
  } = useCustomerDetails(id);
  const { exportMailSent, deleteCustomer, getActions } = useCustomerActions({
    id: id,
    actions: customerDetails.actions,
    isEditable: !(
      customerDetails.isAnonymized || customerDetails.startedAnonymized
    ),
    openDeleteCustomerModal,
  });
  const { anonymizeCustomer, cancelAnonymizeCustomerLink } =
    useAnonymizeCustomer(id);
  const [
    isShowRemoveCustomerModal,
    openRemoveCustomerModal,
    closeRemoveCustomerModal,
  ] = useModal();

  const { fetchCustomerMetafieldsDetails, customerMetafieldsDetails } =
    useCustomerMetafields(id);

  useEffect(() => {
    fetchCustomerMetafieldsDetails();
  }, [fetchCustomerMetafieldsDetails]);

  const [handleOnAnonymizeCustomer, , error, clearError] = useAsyncFunc(
    async () => {
      await anonymizeCustomer();
      closeRemoveCustomerModal();
      addToast({
        label: t('customerDetails.removeCustomerData.success'),
        appearance: 'success',
      });
      fetchCustomerDetails();
    },
  );

  useEffect(() => {
    if (error) {
      addToast({
        label: t('customerDetails.removeCustomerData.error'),
        appearance: 'danger',
      });
      closeRemoveCustomerModal();
      clearError();
    }
  }, [error, clearError, addToast, t, closeRemoveCustomerModal]);

  const title = t('customerDetails.title');

  const customerNameText = customerDetails.isAnonymized
    ? t('customerDetails.anonymous')
    : customerDetails.name;

  const firstInteractionText = t('customerDetails.subtitle', {
    date: dateFormat(customerDetails.firstInteraction, Format.DAY_MONTH_YEAR),
  });

  const handleOnCancelAnonymize = () => {
    openWindow(cancelAnonymizeCustomerLink(), true);
  };

  const handleOnDelete = async () => {
    closeDeleteCustomerModal();
    await deleteCustomer();
    replace('/customers');
  };

  const handleOnRemark = async (value: string) => {
    await updateCustomerNote(value);
  };

  const backNavitation = { onClick: () => goBack() };

  const headerTop = () => {
    if (isSuccess)
      return (
        <HeaderTop
          navigation={backNavitation}
          actions={
            <Popover menu={getActions()} name="dropdownMenu" position="right" />
          }
        />
      );
  };
  const headerContent = () => {
    if (isLoading)
      return <HeaderContent title="skeleton" subtitle="skeleton" />;
    if (isError) return <HeaderContent title={t('customerDetails.title')} />;
    if (isSuccess)
      return (
        <Box gap="4" display="flex" flexDirection="column">
          <HeaderContent
            title={title}
            actions={<ActionsDesktop actions={getActions()} />}
          />
          <Box gap="2" display="flex" flexDirection="column">
            <Title as="h2">{customerNameText}</Title>
            <Text>{firstInteractionText}</Text>
          </Box>
        </Box>
      );
  };

  return (
    <IonPageStratus
      headerTop={headerTop()}
      headerContent={headerContent()}
      width="medium"
    >
      {isLoading && <CustomerDetailsPageSkeleton />}
      {isError && (
        <CustomerDetailsPageError onRetryError={fetchCustomerDetails} />
      )}
      {isSuccess && (
        <>
          {(customerDetails.isAnonymized ||
            customerDetails.startedAnonymized) && (
            <CustomerAnonymizeAlert
              isAnonymized={customerDetails.isAnonymized}
              startedAnonymized={customerDetails.startedAnonymized}
              anonymizationAt={customerDetails.anonymizationAt}
              onCancelAnonymize={handleOnCancelAnonymize}
            />
          )}
          {exportMailSent && (
            <Alert
              appearance="primary"
              show
              title={t('customerDetails.exportCustomerDataAlert.title')}
            >
              {t('customerDetails.exportCustomerDataAlert.body')}
            </Alert>
          )}
          <Layout
            mainContent
            orderMobile="reverse"
            left={
              <CustomerActivity
                customerId={id}
                phone={customerDetails.phone}
                email={customerDetails.email}
              />
            }
            right={
              <>
                {!customerDetails.isAnonymized && (
                  <CustomerDataCard
                    name={customerDetails.name}
                    email={customerDetails.email}
                    phone={customerDetails.phone}
                    businesName={customerDetails.businessName}
                    stateRegistration={customerDetails.stateRegistration}
                    tradeName={customerDetails.tradeName}
                    identification={customerDetails.identification}
                    fiscalRegime={customerDetails.fiscalRegime}
                    businessActivity={customerDetails.businessActivity}
                    address={customerDetails.defaultAddress}
                    allowRemove={!customerDetails.startedAnonymized}
                    onRemoveData={openRemoveCustomerModal}
                    metafields={customerMetafieldsDetails}
                  />
                )}
                <RemarksCard
                  remarks={customerDetails.note}
                  onRemark={handleOnRemark}
                  title={t('customerDetails.notesCard.title')}
                  errorMessage={t('customerDetails.notesCard.errorMessage')}
                />
              </>
            }
          />
        </>
      )}

      <RemoveCustomerDataModal
        show={isShowRemoveCustomerModal}
        onConfirm={handleOnAnonymizeCustomer}
        onDismiss={closeRemoveCustomerModal}
      />
      <DeleteCustomerModal
        show={isShowDeleteCustomerModal}
        onConfirm={handleOnDelete}
        onDismiss={closeDeleteCustomerModal}
      />
      <HelpLink
        title={t('customerDetails.helpLink.title')}
        previousValue=""
        currentViewTracking=""
        linkURL={HELP_LINKS_CUSTOMER_DETAILS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default CustomerDetailsPage;
