import { Box, Button, Link, Text, Title } from '@nimbus-ds/components';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { Country } from '@tiendanube/common/src/enums';
import FeatureFlag from 'App/components/FeatureFlag';
import { DESKTOP_BREAKPOINT } from 'App/constants/breakpoints';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { useResponsive } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  trackingCoreProductAddOnboardingClick,
  trackingCoreProductImportOnboardingClick,
} from 'domains/Catalog/Products/tracking';
import DropshippingAppCard from './DropshippingAppCard';
import emptyProducts from './empty-products.png';

function ProductListEmty() {
  const t = useTranslationCatalog();
  const { push } = useHistory();
  const { isMobile } = useResponsive(DESKTOP_BREAKPOINT);
  const storeCountry = useGetCountry();

  const onClickAddProduct = () => {
    trackingCoreProductAddOnboardingClick();
    push('/products/new');
  };
  const onClickImport = () => {
    trackingCoreProductImportOnboardingClick();
    push('/products/import/onboarding');
  };

  const shouldShowDropshipping = storeCountry === Country.BR;

  const illustration = (
    <Box maxWidth="470px">
      <img
        width={isMobile ? '330px' : '90%'}
        src={emptyProducts}
        alt="dropshipping-logo"
      />
    </Box>
  );

  return (
    <EmptyMessage
      illustration={illustration}
      title={t('products.emptyResults.title')}
      actions={
        <Box>
          <Text>
            <Trans
              t={t}
              i18nKey="products.emptyResults.body"
              components={{
                br: <br />,
              }}
            />
          </Text>
          <Box paddingTop="4" display="flex" gap="4" flexWrap="wrap">
            <Button appearance="primary" onClick={onClickAddProduct}>
              {t('products.emptyResults.action_primary')}
            </Button>
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={onClickImport}
            >
              {t('products.emptyResults.action_secondary')}
            </Link>
          </Box>
          {shouldShowDropshipping && (
            <>
              <Box paddingTop="8" paddingBottom="8">
                <hr />
              </Box>
              <FeatureFlag
                flag={NEW_ONBOARDING_B}
                renderElse={
                  <Box>
                    <Title as="h5" fontWeight="medium">
                      {t('products.emptyResults.dropshipping.title')}
                    </Title>
                    <Box paddingTop="2">
                      <Text>
                        {t('products.emptyResults.dropshipping.body')}
                      </Text>
                    </Box>
                    <Box paddingTop="4">
                      <DropshippingAppCard />
                    </Box>
                  </Box>
                }
              >
                <Box>
                  <Title as="h4" fontWeight="medium">
                    {t('products.emptyResults.dropshipping.new_title')}
                  </Title>
                  <Box paddingTop="4">
                    <DropshippingAppCard />
                  </Box>
                </Box>
              </FeatureFlag>
            </>
          )}
        </Box>
      }
    />
  );
}

export default ProductListEmty;
