import { useContext } from 'react';
import { Skeleton, Alert, Text, Card } from '@nimbus-ds/components';
import { CalloutCard, Layout } from '@nimbus-ds/patterns';
import { Button } from '@tiendanube/components';
import { RocketIcon, UploadIcon } from '@tiendanube/icons';
import { FEATURE_MONTHLY_CATALOG_EXPORT } from 'App/features';
import { InputFile } from 'commons/components';
import { CATALOG_IMPORT_PRODUCTS } from 'config/upsellFlowSources';
import { useIsMobileDevice, useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  DownloadTemplateLink,
  ExistingProductsModal,
  LinesExceededModal,
  UpdateOptions,
} from './components';
import { useImportMaxLines, useImportProducts } from '../../hooks';
import { ImportInProcessModalContext } from '../ImportInProcessModal';
import ImportProductsAlterationsModal from '../ImportProductsAlterationsModal';

const APPEARANCE_ALERT = {
  failed: 'warning',
  pending: 'primary',
  ready: 'success',
};

interface ImportProductsCardProps {
  description?: string;
  showUpdateOptions: boolean;
}

function ImportProductsCard({
  description,
  showUpdateOptions,
}: ImportProductsCardProps) {
  const t = useTranslationCatalog('products.importExportPage.import');
  const isMobileDevice = useIsMobileDevice();
  const {
    updateOptions,
    showImportProductsAlterationsModal,
    showExistingProductsModal,
    jobStatus,
    cleanJob,
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    isLoadingCsv,
    isErrorCsv,
    isLoadingImport,
    isErrorImport,
    onUpdateOptionsChange,
    onCheckExistingProducts,
    onConfirm,
    onImport,
    closeShowImportProductsAlterationsModal,
    closeShowExistingProductsModal,
  } = useImportProducts();
  const { maxLines, loadingMaxLines } = useImportMaxLines();
  const showConfirmationModal = useContext(ImportInProcessModalContext);
  const { isAvailable } = useStatusFeatureByKey(FEATURE_MONTHLY_CATALOG_EXPORT);

  const handleShowConfirmation = (file: File) => {
    if (jobStatus === 'pending') {
      showConfirmationModal((isConfirmed) => {
        if (isConfirmed) {
          onChangeFile?.(file);
        } else {
          onRemoveFile();
        }
      });
    } else {
      onChangeFile?.(file);
    }
  };

  const disabledButton =
    isLoadingCsv ||
    isErrorCsv ||
    !fileName ||
    status !== 'Complete' ||
    isLoadingImport ||
    isErrorImport;

  const isError = ['FormatError', 'SizeError', 'ReadingError'].includes(status);

  const errorMessage = {
    FormatError: t('uploadFile.errorFormat'),
    SizeError: t('uploadFile.errorSize'),
    ReadingError: t('error'),
  };

  const handleDismissAlert =
    jobStatus === 'ready' || jobStatus === 'failed' ? cleanJob : undefined;

  const handleUpsellCallout = useUpsellFlow({
    title: t('upsell.modalTitle'),
    featureKey: FEATURE_MONTHLY_CATALOG_EXPORT,
    trackingSource: CATALOG_IMPORT_PRODUCTS,
    callback: () => {
      // Just re-render the component
    },
  });

  return (
    <>
      <Card>
        <Card.Body padding="base">
          <Layout columns="1">
            <Layout.Section>
              <Alert
                onRemove={handleDismissAlert}
                show={jobStatus !== 'idle'}
                title={t(`alert.title.${jobStatus}`)}
                appearance={APPEARANCE_ALERT[jobStatus]}
              >
                {t(`alert.description.${jobStatus}`)}
              </Alert>
            </Layout.Section>
            <Layout.Section>
              <Text>
                {description ||
                  t(isMobileDevice ? 'descriptionMobile' : 'description', {
                    max: maxLines,
                  })}
              </Text>
            </Layout.Section>
            <Layout.Section>
              {!loadingMaxLines && !isAvailable && (
                <CalloutCard
                  appearance="primary"
                  title={t('upsell.calloutTitle')}
                  subtitle={t('upsell.calloutSubtitle')}
                  icon={RocketIcon}
                  onClick={handleUpsellCallout}
                />
              )}
              <DownloadTemplateLink />
              {loadingMaxLines && (
                <Skeleton width="100%" height="68px" borderRadius="0.5rem" />
              )}
              {!loadingMaxLines && (
                <InputFile
                  fullWidth
                  fileName={fileName}
                  isError={isError}
                  isErrorRetry={isErrorCsv}
                  isLoading={isLoadingCsv || status === 'Loading'}
                  disabled={!isAvailable}
                  onRetry={onRetry}
                  onChangeFile={handleShowConfirmation}
                  onRemoveFile={onRemoveFile}
                  accept=".csv"
                  helperText={t('uploadFile.description')}
                  errorText={errorMessage[status] ?? undefined}
                />
              )}
              {showUpdateOptions && (
                <UpdateOptions
                  disabled={isLoadingImport}
                  options={updateOptions}
                  onChange={onUpdateOptionsChange}
                />
              )}
            </Layout.Section>
          </Layout>
        </Card.Body>
        <Card.Footer padding="base">
          <Button
            appearance="primary"
            icon={UploadIcon}
            iconPosition="start"
            onClick={showUpdateOptions ? onCheckExistingProducts : onConfirm}
            disabled={disabledButton}
            spinner={isLoadingImport}
          >
            {isLoadingImport ? t('importing') : t('import')}
          </Button>
        </Card.Footer>
      </Card>
      <ExistingProductsModal
        show={showExistingProductsModal}
        onDismiss={closeShowExistingProductsModal}
        onAccept={onConfirm}
      />
      <ImportProductsAlterationsModal
        badColumns={badColumns}
        columnOptions={columnOptions}
        show={showImportProductsAlterationsModal}
        onConfirm={onImport}
        onClose={closeShowImportProductsAlterationsModal}
        matchColumns={matchColumns}
        setMatchingColumns={setMatchingColumns}
        ignoreAllColumns={ignoreAllColumns}
        undoIgnoreAllColumns={undoIgnoreAllColumns}
      />
      <LinesExceededModal
        open={status === 'LinesExceededError'}
        onDismiss={onRemoveFile}
        maxLines={maxLines}
      />
    </>
  );
}

export default ImportProductsCard;
