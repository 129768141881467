import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceCustomPaymentSchema } from '../../customPaymentSlice';

const getCustomPaymentState = (
  state: RootStateType,
): CombinedState<InterfaceCustomPaymentSchema> => state.payments.customPayment;

export const getCustomPaymentDataSelector = (state: RootStateType) =>
  getCustomPaymentState(state).data;

export const getCustomPaymentStatusLoadSelector = (state: RootStateType) =>
  getCustomPaymentState(state).status.load;

export const getCustomPaymentStatusUpdateSelector = (state: RootStateType) =>
  getCustomPaymentState(state).status.update;

export const getCustomPaymentStatusDisableSelector = (state: RootStateType) =>
  getCustomPaymentState(state).status.disable;
