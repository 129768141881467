import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  BaseCard,
  Button,
  Input,
  Link,
  Stack,
  Title,
} from '@tiendanube/components';
import { RECOVER_PASSWORD_LINKS } from 'commons/constants';
import { useForm } from 'commons/hooks';
import useValidateCredentials from 'domains/Account/hooks/useValidateCredentials';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { validationSchema } from './validationSchema';

interface ConfirmIdentityPageValues {
  email: string;
  password: string;
}

const initialValues = { email: '', password: '' };

interface ConfirmIdentityPageProps {
  onCancel: () => void;
  onContinue: () => void;
}

function ConfirmIdentityPage({
  onCancel,
  onContinue,
}: ConfirmIdentityPageProps): JSX.Element {
  const { email } = useGetUserInfo();
  const { validateCredentials, isLoading, isError, isValid } =
    useValidateCredentials();
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.ACCOUNT);
  const {
    errors,
    values,
    isValidating,
    isDirty,
    handleOnBlur,
    handleOnChange,
    handleOnSubmit,
  } = useForm<ConfirmIdentityPageValues, ConfirmIdentityPageValues>({
    initialValues,
    validationSchema,
    onSubmit: async ({ password }) => {
      const isValidResponse = await validateCredentials({ password });
      if (isValidResponse) onContinue();
    },
    validateOnBlur: ['password'],
  });

  const isValidatingOrLoading = isValidating || isLoading;

  const buildHelpText = (): string => {
    if (isError || isValid === false) {
      return t(
        'authenticationFactorPage.confirmIdentityPage.password.errors.invalid',
      );
    } else {
      return t(errors.password || '');
    }
  };

  return (
    <Stack column justify="space-between" align="stretch">
      <BaseCard>
        <BaseCard.Header>
          <Title type="h3">{`${t(
            'authenticationFactorPage.confirmIdentityPage.title',
          )}`}</Title>
        </BaseCard.Header>
        <BaseCard.Body>
          <Input
            value={email}
            name="email"
            type="email"
            required
            disabled
            label={t(
              'authenticationFactorPage.confirmIdentityPage.email.label',
            )}
          />
        </BaseCard.Body>
        <BaseCard.Body>
          <Input
            onChange={handleOnChange}
            value={values.password}
            name="password"
            type="password"
            required
            appearance={
              errors.password || isError || isValid === false
                ? 'validation_error'
                : undefined
            }
            label={t(
              'authenticationFactorPage.confirmIdentityPage.password.label',
            )}
            maxLength={-1}
            helpText={buildHelpText()}
            onBlur={handleOnBlur}
          />
        </BaseCard.Body>
        <BaseCard.Body>
          <Link
            href={RECOVER_PASSWORD_LINKS[language]}
            target="_blank"
            appearance="primary"
            size="small"
          >
            {t('authenticationFactorPage.confirmIdentityPage.recovery.message')}
          </Link>
        </BaseCard.Body>
      </BaseCard>
      <Stack justify="flex-end">
        <Button onClick={onCancel} appearance="default">
          {`${t(
            'authenticationFactorPage.confirmIdentityPage.actions.cancel',
          )}`}
        </Button>
        <Button
          onClick={handleOnSubmit}
          appearance="primary"
          spinner={isValidatingOrLoading}
          disabled={!isDirty || isValidatingOrLoading}
        >
          {`${t(
            'authenticationFactorPage.confirmIdentityPage.actions.continue',
          )}`}
        </Button>
      </Stack>
    </Stack>
  );
}

export default ConfirmIdentityPage;
