import { CustomerMessagesSkeleton, CustomerOrdersSkeleton } from '../..';

function CustomerActivitySkeleton(): JSX.Element {
  return (
    <>
      <CustomerMessagesSkeleton />
      <CustomerOrdersSkeleton />
    </>
  );
}

export default CustomerActivitySkeleton;
