import { DataTable, Text } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import {
  BASIS_ACTIONS,
  BASIS_VARIANT,
  GROW_ACTIONS,
  GROW_LOCATION,
  GROW_VARIANT,
} from '../../constants';

const { Header, Cell } = DataTable;

export interface VariantsPreviewDesktopHeaderProps {
  hasMultipleLocations: boolean;
  isDigital?: boolean;
}

function VariantsPeviewDesktopHeader({
  hasMultipleLocations,
  isDigital = false,
}: VariantsPreviewDesktopHeaderProps): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <Header>
      <Cell grow={GROW_VARIANT} basis={BASIS_VARIANT}>
        <Text>{t('products.variants.table.variant')}</Text>
      </Cell>
      <Cell>
        <Text>{t('products.variants.table.stock')}</Text>
      </Cell>
      {hasMultipleLocations && (
        <Cell grow={GROW_LOCATION}>
          <Text>{t('products.variants.table.location')}</Text>
        </Cell>
      )}
      <Cell>
        <Text>{t('products.variants.table.price')}</Text>
      </Cell>
      {!isDigital && (
        <Cell>
          <Text>{t('products.variants.table.weight')}</Text>
        </Cell>
      )}
      <Cell grow={GROW_ACTIONS} basis={BASIS_ACTIONS}>
        <Text>{t('products.variants.table.actions')}</Text>
      </Cell>
    </Header>
  );
}

export default VariantsPeviewDesktopHeader;
