import { StatusType } from 'commons/types';
import { STATS_PRODUCTS_PAGE } from 'config/upsellFlowSources';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { StatisticsGranularityType } from 'domains/Statistics/types';
import {
  STATS_PLAN_1_AND_2,
  thousandSeparator,
} from 'domains/Statistics/utils';
import { colors } from './constants';
import { ProductsSummaryTypes } from './types';
import useProductsChartCard from './useProductsChartCard';
import BarChartAndSummaryCard from '../BarChartAndSummaryCard';

function ProductsChartCard() {
  const t = useTranslationStatistics();
  const {
    results,
    totals,
    granularity,
    hasComparedFilter,
    fetchProductsSummary,
    status,
    openFilter: onClickEmpty,
  } = useProductsChartCard();

  const formatValueSummary = (text: number) => thousandSeparator(text);
  const formatLabelTooltip = (
    dataKey: string,
    selectedPrimary: string,
    selectedSecondary: string,
  ) =>
    ({
      principalValue: t(`statistics.statsProductsSummary.${selectedPrimary}`),
      secondaryValue: t(`statistics.statsProductsSummary.${selectedSecondary}`),
    }[dataKey] || dataKey);

  const formatSummaryTitle = (text: string) =>
    t(`statistics.statsProductsSummary.${text}`);
  const formatTextTooltip = () => '';

  return (
    <BarChartAndSummaryCard<ProductsSummaryTypes>
      title={t('statistics.statsProductsSummary.title')}
      colors={colors}
      results={results}
      totals={totals}
      granularity={granularity as StatisticsGranularityType}
      hasComparedFilter={hasComparedFilter}
      onClickEmpty={onClickEmpty}
      onFetch={fetchProductsSummary}
      statsPlanRequired={STATS_PLAN_1_AND_2}
      upsellSource={STATS_PRODUCTS_PAGE}
      status={status as StatusType}
      formatValueSummary={formatValueSummary}
      formatLabelTooltip={formatLabelTooltip}
      formatSummaryTitle={formatSummaryTitle}
      formatSummaryTooltip={formatTextTooltip}
      defaultSelected="sold"
    />
  );
}

export default ProductsChartCard;
