import { GetDocumentSettingsResponseDto } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  SHIPPING_PRINT_DOCUMENT_PAGE_VIEW,
  SHIPPING_PRINT_DOCUMENT_SETTING,
  SHIPPING_PRINT_DOCUMENT_CANCEL_BUTTON_CLICK,
  SHIPPING_PRINT_DOCUMENT_CONFIRMATION_BUTTON_CLICK,
  NUVEM_ENVIO_VIEW,
} from 'config/trackingEvents';
import { DocumentType } from './pages/PrintDocumentPage/constants';

export const trackingShippingPrintDocumentPageView = (
  documentType: DocumentType,
) => {
  logEvent(SHIPPING_PRINT_DOCUMENT_PAGE_VIEW, {
    documentType,
  });
};

export const trackingNuvemEnvioBannerView = (orderId: string[]) => {
  logEvent(NUVEM_ENVIO_VIEW, {
    page: 'print document',
    orderId: orderId.join(','),
    action: 'nuvem envio banner',
  });
};

export const trackingShippingPrintDocumentSettings = (
  documentType: DocumentType,
  name: string,
  checked: boolean,
) => {
  logEvent(SHIPPING_PRINT_DOCUMENT_SETTING, {
    documentType,
    name,
    checked: checked.toString(),
  });
};

export const trackingShippingPrintDocumentCancelButtonClick = (
  documentType: DocumentType,
  origin: 'header' | 'footer',
) => {
  logEvent(SHIPPING_PRINT_DOCUMENT_CANCEL_BUTTON_CLICK, {
    documentType,
    origin,
  });
};

export const trackingShippingPrintDocumentConfirmationButtonClick = (
  documentType: DocumentType,
  action: 'print' | 'download',
  settings: GetDocumentSettingsResponseDto,
) => {
  const settingsCopy = { ...settings };
  delete (settingsCopy as { availablePageSizes?: any }).availablePageSizes;

  logEvent(SHIPPING_PRINT_DOCUMENT_CONFIRMATION_BUTTON_CLICK, {
    documentType,
    action,
    settings: JSON.stringify(settingsCopy),
  });
};
