import { Tooltip } from '@nimbus-ds/components';
import { PrinterIcon } from '@nimbus-ds/icons';
import { Button } from '@tiendanube/components';
import { Stack, useResponsive } from 'commons/components';
import { ActionsProps } from 'commons/components/IonPageStratus/components/';
import {
  ActionCustomPopover,
  ActionPopover,
} from 'commons/components/IonPageStratus/components/ActionsDesktop/components/';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface ActionsDesktopProps extends ActionsProps {
  renderActionsAsButtons?: boolean;
  numPrimaryActions?: number;
}

function ActionsOrderDesktop({
  actions,
  renderActionsAsButtons = false,
  numPrimaryActions = 1,
}: Readonly<ActionsDesktopProps>) {
  const { isMobile } = useResponsive(1200);
  const t = useTranslationOrders();

  const printLabelActions = actions.filter(
    (action) => action.name && action.name === 'printLabel',
  );

  const otherActions = actions.filter(
    (action) => !action.name || action.name !== 'printLabel',
  );

  const mainAction = otherActions[0];
  const moreActions = otherActions.slice(
    numPrimaryActions,
    otherActions.length,
  );
  const renderMoreAsButton = moreActions.length === 1;
  const renderActionsAsSubmenu = moreActions.length > 1;
  const renderActionsAsBtns =
    renderActionsAsButtons && !isMobile && moreActions.length > 1;

  if (actions.length === 0) return null;

  return (
    <Stack spacing="tight">
      {renderMoreAsButton && <Button {...moreActions[0]} />}
      {renderActionsAsBtns ? (
        moreActions.map((action, index) => <Button key={index} {...action} />)
      ) : renderActionsAsSubmenu ? (
        <ActionPopover actions={moreActions} />
      ) : null}

      {mainAction?.tooltip ? (
        <Tooltip content={mainAction.tooltip} arrow position="left">
          <Button {...mainAction} />
        </Tooltip>
      ) : (
        mainAction && <Button {...mainAction} />
      )}

      {printLabelActions.length > 1 ? (
        <ActionCustomPopover
          key="printLabel"
          actions={printLabelActions}
          icon={PrinterIcon}
          name={`${t('detail.printLabels')}`}
          appearance="primary"
        />
      ) : printLabelActions.length === 1 ? (
        <Button key={`printLabel-${0}`} {...printLabelActions[0]} />
      ) : null}
    </Stack>
  );
}

export default ActionsOrderDesktop;
