import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getActiveShippingMethodsDataSelector,
  getActiveShippingMethodsStatusSelector,
  getActiveShippingMethodsAction,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useActiveShippingMethods() {
  const dispatch = useAppDispatch();

  const activeShippingMethods = useSelector(
    getActiveShippingMethodsDataSelector,
  );

  const { isError, isLoading } = useSelector(
    getActiveShippingMethodsStatusSelector,
  );

  const getActiveShippingMethods = useCallback(() => {
    dispatch(getActiveShippingMethodsAction());
  }, [dispatch]);

  return {
    getActiveShippingMethods,
    activeShippingMethods,
    isLoading,
    isError,
  };
}

export default useActiveShippingMethods;
