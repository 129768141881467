import { useEffect, useState } from 'react';
import { useResponsive } from 'commons/components';

const COUPON_HISTORY_COMPONENT_MOBILE_HEIGHT = '75vh';

export const useComponentHeight = ({ mainContainerRef }) => {
  const { isDesktop } = useResponsive();
  const [height, setHeight] = useState<string>(
    isDesktop ? '0px' : COUPON_HISTORY_COMPONENT_MOBILE_HEIGHT,
  );

  useEffect(() => {
    if (!mainContainerRef.current) return;

    const mainContainer = mainContainerRef.current;

    const calculateHeight = () => {
      if (!isDesktop) {
        setHeight(COUPON_HISTORY_COMPONENT_MOBILE_HEIGHT);
      } else {
        const firstColumn = mainContainer.firstChild?.firstChild;
        if (firstColumn) {
          const currentHeight = firstColumn.clientHeight;
          setHeight(`${currentHeight}px`);
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      calculateHeight();
    });

    resizeObserver.observe(mainContainer);
    calculateHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [mainContainerRef, isDesktop]);

  return {
    height,
    setHeight: (newHeight: number) => setHeight(`${newHeight}px`),
  };
};
