import { useEffect } from 'react';
import {
  useGetVariantsMetafields,
  useTranslationsMetafields,
} from 'domains/Metafields/hooks';

function useFetchProductVariantsMetafields() {
  const t = useTranslationsMetafields();
  const selectFirstOptionMetafields = t('select.one');

  const { fetchMetafields } = useGetVariantsMetafields();

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  return {
    selectFirstOptionMetafields,
  };
}

export default useFetchProductVariantsMetafields;
