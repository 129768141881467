import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CategoryResponseDto,
  CategoryCreateRequestDto,
  CategoryTabedResponseDto,
  CategoryUploadImageResponseDto,
} from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import { statusType, CategoriesInterface, CategoriesEntities } from './types';
import categoriesServices from '../categoriesServices';

const initialState: CategoriesInterface = {
  status: statusType.idle,
  currentRequestID: '',
  entities: {},
  ids: [],
  options: [],
};

export const getCategories = createAsyncThunk<CategoryResponseDto[]>(
  'catalog/categories/getCategiores',
  async () => {
    const categories = await categoriesServices.getCategories();
    return categories;
  },
);

export const getCategoryOptions = createAsyncThunk<CategoryTabedResponseDto[]>(
  'catalog/categories/getCategoryOptions',
  async () => {
    const categories = await categoriesServices.getOptions();
    return categories;
  },
);

export const createCategory = createAsyncThunk<
  {
    category: CategoryResponseDto;
    options: CategoryTabedResponseDto[];
  },
  CategoryCreateRequestDto
>('catalog/categories/createCategory', async (newCategory) => {
  const category = await categoriesServices.createCategory(newCategory);
  const options = await categoriesServices.getOptions();
  return {
    category,
    options,
  };
});

export const uploadImageAction = createAsyncThunk<
  CategoryUploadImageResponseDto,
  File
>('catalog/categories/uploadImageCategory', async (file) => {
  const imageCategory = await categoriesServices.uploadImageCategory(file);
  return imageCategory;
});

export const generateCategorySeo = createAsyncThunk(
  'catalog/categories/generateCategorySeo',
  categoriesServices.generateCategorySeo,
);

const categoriesSlice = createSlice({
  name: 'categoriesSlice',
  initialState,
  reducers: {
    init(state) {
      state.status = statusType.loading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder.addCase(getCategories.pending, (state, action) => {
      state.status = statusType.loading;
      state.entities = {};
      state.ids = [];
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        const ids: string[] = [];
        state.entities = action.payload.reduce((acc, order) => {
          acc[order.id.toString()] = order;
          ids.push(order.id.toString());
          return acc;
        }, {} as CategoriesEntities);
        state.status = statusType.success;
        state.ids = ids;
        return state;
      }
    });

    builder.addCase(getCategories.rejected, (state) => {
      state.status = statusType.error;
      return state;
    });

    builder
      .addCase(getCategoryOptions.pending, (state, action) => {
        state.status = statusType.loading;
        state.currentRequestID = action.meta.requestId;
      })
      .addCase(getCategoryOptions.fulfilled, (state, action) => {
        state.options = action.payload;
        state.status = statusType.success;
      })
      .addCase(getCategoryOptions.rejected, (state) => {
        state.status = statusType.error;
      });

    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.options = action.payload.options;
    });
  },
});

export const { reducer } = categoriesSlice;
