import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const forwardConfirmationEmailAction = createAsyncThunk(
  'dashboard/forwardConfirmationEmailAction',
  dashboardService.forwardConfirmationEmail,
);

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: initialState.users,
  reducers: {
    cleanForwardConfirmationEmail(state) {
      state.forwardConfirmationEmail =
        initialState.users.forwardConfirmationEmail;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.users;
      return state;
    });

    builder
      .addCase(forwardConfirmationEmailAction.pending, (state) => {
        state.forwardConfirmationEmail.status = 'loading';
      })
      .addCase(forwardConfirmationEmailAction.rejected, (state) => {
        state.forwardConfirmationEmail.status = 'error';
      })
      .addCase(forwardConfirmationEmailAction.fulfilled, (state) => {
        state.forwardConfirmationEmail.status = 'success';
      });
  },
});

export const { reducer } = usersSlice;
export const { cleanForwardConfirmationEmail } = usersSlice.actions;
