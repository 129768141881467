import { Text, Title } from '@nimbus-ds/components';
import { EditIcon, TrashIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { ActionIconButton, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import PhysicalLocationsSkeleton from './Skeleton';

interface PhysicalLocationsRowProps {
  id: string;
  name: string;
  extra: string;
  onDelete: (id: string) => () => void;
  onEdit: (id: string) => () => void;
}

function PhysicalLocationsRow({
  id,
  name,
  extra,
  onDelete,
  onEdit,
}: PhysicalLocationsRowProps) {
  const t = useTranslationShipping();

  return (
    <DataList.Row id={id}>
      <Stack justify="space-between">
        <Stack column align="flex-start" spacing="none">
          <Title as="h5">{name}</Title>
          <Text fontSize="caption">{extra}</Text>
        </Stack>
        <Stack spacing="tight" justify="flex-end">
          <ActionIconButton
            source={<EditIcon />}
            content={t('physicalLocations.editLocation')}
            onClick={onEdit(id)}
          />
          <ActionIconButton
            source={<TrashIcon />}
            content={t('physicalLocations.deleteLocation.title')}
            onClick={onDelete(id)}
          />
        </Stack>
      </Stack>
    </DataList.Row>
  );
}

PhysicalLocationsRow.Skeleton = PhysicalLocationsSkeleton;

export default PhysicalLocationsRow;
