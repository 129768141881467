import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import { getPhone } from '../../authSlice/authSelectors';

function useGetPhone(): string {
  const phone = useSelector<RootStateType, string>(getPhone);

  return phone;
}

export default useGetPhone;
