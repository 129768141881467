import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label, Stack } from '@tiendanube/components';
import { EyeOffIcon } from '@tiendanube/icons';
import { AppearanceColorEnum } from 'commons/enums';

interface HiddenProductPillProps {
  id: string;
  withIcon?: boolean;
}

function HiddenProductPill({
  id,
  withIcon = false,
}: HiddenProductPillProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  return (
    <Stack spacing="tight" wrap>
      <Stack.Item>
        <Label
          id={id}
          label={`${t('products.itemList.hidden')}`}
          appearance={AppearanceColorEnum.WARNING}
          icon={withIcon ? EyeOffIcon : undefined}
        />
      </Stack.Item>
    </Stack>
  );
}

export default HiddenProductPill;
