import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import useGetStatisticsFreeTrialData from '../useGetStatisticsFreeTrialData';

function useSupportsStatsPlan(statsPlanRequired: number) {
  const { freeTrial } = useGetStatisticsFreeTrialData();
  const { maxUse } = useStatusFeatureByKey(FEATURE_NEW_ADMIN_STATS);
  const isEnabledFreeTrial = freeTrial?.status === 'enabled';

  // Starting June 2024, no more stores will be using the trial so this can be simplified
  return maxUse === -1 || maxUse >= statsPlanRequired || isEnabledFreeTrial;
}

export default useSupportsStatsPlan;
