import { ReactNode } from 'react';
import { Stack, Thumbnail, Text } from '@tiendanube/components';
import '../ImageItemStratus.scss';

interface ImageItemStratusSkeletonProps {
  children?: ReactNode;
  headerRight?: ReactNode;
  imageWidth?: string;
}

function ImageItemStratus({
  children,
  headerRight,
  imageWidth = '72px',
}: ImageItemStratusSkeletonProps): JSX.Element {
  return (
    <div className="stratus--image-item">
      <Stack spacing="tight" align="flex-start">
        <Stack.Item>
          <Thumbnail.Skeleton aspectRatio="1-1" width={imageWidth} />
        </Stack.Item>
        <Stack.Item fill>
          <Stack column align="stretch" spacing="none">
            <Stack.Item>
              <Stack wrap justify="space-between" align="flex-start">
                <Stack.Item fill>
                  <Text.Skeleton width="large" />
                </Stack.Item>
                {!!headerRight && <Stack.Item>{headerRight}</Stack.Item>}
              </Stack>
            </Stack.Item>
            {!!children && <Stack.Item fill>{children}</Stack.Item>}
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default ImageItemStratus;
