// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './marketing.es-AR.json';
import esMX from './marketing.es-MX.json';
import ptBR from './marketing.pt-BR.json';

const marketingTranslations: DomainTranslations = {
  name: 'marketing',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default marketingTranslations;
