import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ActivityLogEntityTypes } from '@tiendanube/common/src/domains/store-activity-logs/enums';
import { RootStateType, useAppDispatch } from 'App/store';
import { incrementPage as incrementPageAction } from 'domains/Marketing/Coupons/couponHistorySlice';
import { useGetActivityLogsQuery } from 'domains/StoreActivityLogs';
import { useScrollable } from '../components/CouponForm/components/CouponHistory/hooks/useScrollable';
import {
  addItems,
  resetCouponHistory as resetCouponHistoryAction,
} from '../couponHistorySlice';

export function useCouponHistory({ couponId, containerRef }) {
  const dispatch = useAppDispatch();
  const incrementPage = useCallback(
    () => dispatch(incrementPageAction()),
    [dispatch],
  );

  const resetCouponHistory = useCallback(
    () => dispatch(resetCouponHistoryAction()),
    [dispatch],
  );
  const { items, currentPage } = useSelector(
    (state: RootStateType) => state.marketing.couponHistory,
  );
  const { data, isLoading, isSuccess, isError, isFetching, refetch } =
    useGetActivityLogsQuery({
      entityId: couponId,
      entityType: ActivityLogEntityTypes.COUPON,
      page: currentPage,
    });

  useEffect(() => {
    resetCouponHistory();
    refetch();
  }, [couponId, refetch, resetCouponHistory]);

  useScrollable({
    containerRef,
    data,
    currentPage,
    isLoading,
    isFetching,
    incrementPage,
  });

  useEffect(() => {
    if (
      isSuccess &&
      data?.results &&
      data.pagination.currentPage === currentPage
    ) {
      dispatch(addItems(data));
    }
  }, [isSuccess, data, dispatch, currentPage]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    isFetching,
    refetch,
    currentPage,
    items,
  };
}
