import {
  Box,
  Card,
  Checkbox,
  Link,
  Skeleton,
  Tag,
  Title,
} from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function AcceleratedCheckoutCardSkeleton() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.acceleratedCheckoutCard',
  );

  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Title fontSize="h2">{t('title')}</Title>
          </Box>
          <Tag.Skeleton width="100px" />
        </Box>
      </Card.Header>

      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Skeleton height="20px" width="112.5px" />
          <Checkbox.Skeleton width="200px" />
          <Link.Skeleton width="300px" />
        </Box>
      </Card.Body>
    </Card>
  );
}
