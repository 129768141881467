import * as Yup from 'yup';
import { LanguagesType } from 'App/i18n';

export const buildValidationSchema = (
  languages: LanguagesType[],
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    name: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string()
            .trim()
            .required('businessDataPage.update.errorName'),
        }),
        {},
      ),
    }),
  });
