import { Box } from '@nimbus-ds/components';
import { MoneyIcon, ShoppingCartIcon } from '@nimbus-ds/icons';
import { InternalNavLink } from 'commons/components';
import CircleIcon from './CircleIcon';

interface LinkWithIconProps {
  id: string;
  opportunity: boolean;
  paymentIntents: number;
}

function LinkWithIcon({ id, opportunity, paymentIntents }: LinkWithIconProps) {
  return (
    <Box display="inline-flex" flexWrap="nowrap">
      <InternalNavLink to={`/abandoned-carts/${id}`}>#{id}</InternalNavLink>
      {paymentIntents > 0 ? (
        <CircleIcon icon={MoneyIcon} paymentIntents={paymentIntents} />
      ) : opportunity ? (
        <CircleIcon icon={ShoppingCartIcon} />
      ) : null}
    </Box>
  );
}

export default LinkWithIcon;
