import { Grid, Stack, Text, DataList } from '@tiendanube/components';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { InputNumber } from 'commons/components';
import { STATS_PRODUCTS_PAGE } from 'config/upsellFlowSources';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import StatsProductsItemPlaceholder from '../StatsProductsItemPlaceholder';

export interface YourProductsInNumberPagination {
  totalResults: number;
  perPage: number;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
}

interface StatsProductsPlaceholderProps {
  labelHeader: string;
  inputType: 'currency' | 'number';
  inputLabel: string;
  value: string;
}

function StatsProductsPlaceholder({
  labelHeader,
  inputLabel,
  inputType,
  value,
}: Readonly<StatsProductsPlaceholderProps>) {
  const t = useTranslationStatistics();
  const { symbol } = useGetCurrency();

  const handleUpsell = useUpsellFlow({
    title: t('statistics.upsellTitle'),
    featureKey: FEATURE_NEW_ADMIN_STATS,
    minValue: STATS_PLAN_1_AND_2,
    trackingSource: STATS_PRODUCTS_PAGE,
  });

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item fill>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text>{labelHeader}</Text>
            </Grid.Column>
            <Grid.Column>
              <InputNumber
                type="integer"
                name="quantity"
                prependLabel={inputType === 'currency' ? symbol : ''}
                appendLabel={inputType === 'number' ? inputLabel : ''}
                value={value}
                onFocus={handleUpsell}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Stack.Item>
      <Stack.Item fill>
        <DataList spacing="tight">
          {[...Array(5).keys()].map((i) => (
            <DataList.Row key={i} id={i.toString()} onClick={handleUpsell}>
              <DataList.Cell width={100}>
                <StatsProductsItemPlaceholder totalType={inputType} />
              </DataList.Cell>
            </DataList.Row>
          ))}
        </DataList>
      </Stack.Item>
    </Stack>
  );
}

export default StatsProductsPlaceholder;
