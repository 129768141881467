import { Card, Text, Input, Title } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface EditOrderReasonProps {
  readonly reason?: string;
  readonly disabled?: boolean;
  readonly onChange: (reason: string) => void;
}

function EditOrderReason({
  reason,
  disabled,
  onChange,
}: EditOrderReasonProps): JSX.Element {
  const t = useTranslationOrders();

  return (
    <Card>
      <Card.Header>
        <Title as="h5">{t('editOrders.reason.title')}</Title>
        <Text fontSize="caption" lineHeight="caption">
          {t('editOrders.reason.text')}
        </Text>
      </Card.Header>
      <Card.Body>
        <Input
          maxLength={50}
          name="remark"
          placeholder=""
          onChange={(e) => onChange(e.currentTarget.value)}
          value={reason}
          disabled={disabled}
        />
      </Card.Body>
    </Card>
  );
}

export default EditOrderReason;
