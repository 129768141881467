import { ReactNode } from 'react';

interface BlurProps {
  radius?: string;
  children: ReactNode;
}
function Blur({ radius = '3px', children }: Readonly<BlurProps>) {
  return <div style={{ filter: `blur(${radius})` }}>{children}</div>;
}

export default Blur;
