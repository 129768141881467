import { ErrorState } from '@tiendanube/components';
import useTranslationSalesChannels from 'domains/SalesChannels/useTranslationSalesChannels';

interface SalesChannelsAppsPageErrorProps {
  getSalesChannelsApps: () => void;
}

function SalesChannelsAppsPageError({
  getSalesChannelsApps,
}: SalesChannelsAppsPageErrorProps) {
  const t = useTranslationSalesChannels('salesChannelsApps');

  return (
    <ErrorState
      title={t('errorState.title')}
      action={{
        children: t('errorState.retry'),
        onClick: getSalesChannelsApps,
      }}
    />
  );
}

export default SalesChannelsAppsPageError;
