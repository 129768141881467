import { ContentPagesInterface } from './types';

export const initialContentPageValues = {
  title: {},
  content: {},
  showInStore: true,
  seoTitle: {},
  seoDescription: {},
  pageUrl: {},
};

export const initialState: ContentPagesInterface = {
  pagesList: {
    status: 'idle',
    data: null,
  },
  allPagesList: {
    status: 'idle',
    data: [],
  },
  templatesList: {
    status: 'idle',
    data: null,
  },
  templateById: {
    status: 'idle',
    data: null,
  },
  pageDetail: {
    status: 'idle',
    data: initialContentPageValues,
  },
  pageCreation: {
    status: 'idle',
    data: initialContentPageValues,
  },
  pageUpdate: {
    status: 'idle',
    data: null,
  },
  pageDelete: {
    status: 'idle',
    data: null,
  },
};
