import { Title } from '@nimbus-ds/components';
import { BaseCard, Link, DataList } from '@tiendanube/components';
import { Stack } from 'commons/components';
import MenuItemNode from '../MenuItemNode';
import '../MenuItemDataList/MenuItemDataList.scss';

function Skeleton() {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Title.Skeleton as="h3" />
      </BaseCard.Header>
      <BaseCard.Body>
        <div className="stratus--menu-item-data-list">
          <Stack column align="stretch" spacing="tight">
            <DataList spacing="tight">
              {Array.from(Array(3).keys()).map((i) => (
                <DataList.Row key={i} id={`row-${i}`}>
                  <DataList.Cell width="fill">
                    <MenuItemNode.Skeleton />
                  </DataList.Cell>
                </DataList.Row>
              ))}
            </DataList>
            <Link.Skeleton />
          </Stack>
        </div>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Skeleton;
