import { Select, Tag, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

function DocumentPageSizeFormSkeleton() {
  const t = useTranslationFulfillmentOrders('printManager.pageSize');

  return (
    <Stack column align="stretch">
      <Text>{t('title')}</Text>
      <Tag.Skeleton />
      <FormField label={t('label')} id="pageSize">
        <Select.Skeleton />
      </FormField>
    </Stack>
  );
}

export default DocumentPageSizeFormSkeleton;
