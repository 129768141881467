import { Accordion, Box, Card, Text } from '@nimbus-ds/components';
import { CurrencyPrice } from 'commons/components';
import {
  useChargesToPay,
  usePaymentMethods,
} from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { AccountDataContent, PaymentDataContent } from '../../../components';

import 'domains/Billing/Checkout/Checkout.scss';

function PaymentData() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPlan.paymentData',
  );
  const { paymentMethodSelected } = usePaymentMethods();
  const { chargeTotalWithoutTaxes } = useChargesToPay(false);

  return (
    <Card padding="none">
      <Accordion>
        <Accordion.Item index="0">
          <Accordion.Header title={t('title')} />
          <Accordion.Body>
            <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
              <AccountDataContent />
              {paymentMethodSelected && (
                <div className="stratus--checkout-card_content--extra-item">
                  <PaymentDataContent
                    charges={[
                      {
                        conceptCode: 'plan-cost',
                        ...paymentMethodSelected.recurrencyOption,
                      },
                    ]}
                  />
                </div>
              )}
            </Box>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {paymentMethodSelected && (
        <div className="stratus--checkout-card_footer">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text fontSize="highlight" fontWeight="bold">
              {t('total')}:
            </Text>
            <CurrencyPrice
              currency={paymentMethodSelected.recurrencyOption.amountCurrency}
              price={chargeTotalWithoutTaxes(
                paymentMethodSelected.recurrencyOption,
              )}
              bold
            />
          </Box>
        </div>
      )}
    </Card>
  );
}

export default PaymentData;
