import {
  StatisticsGlobalInfoResponseDto,
  StatisticsStoreConversionResponseDto,
  ResultPaginationResponseDto,
  StatisticsProductsInNumberResponseDto,
  StatisticsGetSummaryResponseDto,
  StatisticsFiltersParamsRequestDto,
  StatisticsGetFreeTrialResponseDto,
  StatisticsCustomersResponseDto,
  StatisticsPaymentsPerformanceResponseDto,
  StatisticsAcquisitionSummaryResponseDto,
  StatisticsProductsSummaryResponseDto,
  StatisticsShippingSummaryResponseDto,
  StatisticsProvinceRankValueResponseDto,
  StatisticsShippingRankByProvinceResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  GetDailyType,
  GetOrdersType,
  GetProductsType,
  GetGlobalInfoType,
  GetStoreConversionType,
} from './types';
import {
  STATS_ORDERS_URL,
  STATS_PRODUCTS_URL,
  STATS_DAILY_URL,
  STATS_GLOBAL_INFO_URL,
  STATS_PRODUCTS_IN_NUMBER_URL,
  STATS_STORE_CONVERSION_URL,
  STATS_SUMMARY_URL,
  STATS_FREE_TRIAL,
  STATS_STORE_HAS_GA,
  STATS_CUSTOMERS,
  STATS_PAYMENTS,
  STATS_PRODUCTS_SUMMARY_URL,
  STATS_ACQUISITION_SUMMARY_URL,
  STATS_SHIPPING_SUMMARY_URL,
  STATS_SHIPPING_SALES_BY_PROVINCE_URL,
  STATS_SHIPPING_REVENUE_BY_PROVINCE_URL,
  STATS_SHIPPING_PROVINCES_BY_SHIPPING_COST,
  STATS_SHIPPING_PROVINCES_BY_SHIPPING_TIME,
} from './urls';

const getDaily: GetDailyType = async () => {
  const response = await axios.get(STATS_DAILY_URL);
  return response.data;
};

const getProducts: GetProductsType = async () => {
  const response = await axios.get(STATS_PRODUCTS_URL);
  return response.data;
};

const getOrders: GetOrdersType = async () => {
  const response = await axios.get(STATS_ORDERS_URL);
  return response.data;
};

const getGlobalInfo: GetGlobalInfoType = async (filters) => {
  const { data } = await axios.get<StatisticsGlobalInfoResponseDto>(
    STATS_GLOBAL_INFO_URL,
    {
      params: filters,
    },
  );

  return data;
};

const getStoreConversion: GetStoreConversionType = async (params) => {
  const { data } = await axios.get<StatisticsStoreConversionResponseDto[]>(
    STATS_STORE_CONVERSION_URL,
    { params },
  );

  return data;
};

const getStatsProducts = async (params) => {
  const { data } = await axios.get<
    ResultPaginationResponseDto<StatisticsProductsInNumberResponseDto[]>
  >(STATS_PRODUCTS_IN_NUMBER_URL, { params });

  return data;
};

const getSummary = async (params: StatisticsFiltersParamsRequestDto) => {
  const { data } = await axios.get<StatisticsGetSummaryResponseDto>(
    STATS_SUMMARY_URL,
    { params },
  );

  return data;
};

const getFreeTrial = async () => {
  const { data } = await axios.get<StatisticsGetFreeTrialResponseDto>(
    STATS_FREE_TRIAL,
  );
  return data;
};

const initFreeTrial = async () => {
  const { data } = await axios.post<StatisticsGetFreeTrialResponseDto>(
    STATS_FREE_TRIAL,
  );
  return data;
};

const getStoreHasGA = async () => {
  const { data } = await axios.get<boolean>(STATS_STORE_HAS_GA);
  return data;
};

const getCustomers = async (params: StatisticsFiltersParamsRequestDto) => {
  const { data } = await axios.get<StatisticsCustomersResponseDto>(
    STATS_CUSTOMERS,
    { params },
  );
  return data;
};

const getProductsSummary = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsProductsSummaryResponseDto>(
    STATS_PRODUCTS_SUMMARY_URL,
    { params },
  );

  return data;
};

const getPaymentsSummary = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsPaymentsPerformanceResponseDto>(
    STATS_PAYMENTS,
    { params },
  );

  return data;
};

const getAcquisitionSummary = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsAcquisitionSummaryResponseDto>(
    STATS_ACQUISITION_SUMMARY_URL,
    { params },
  );
  return data;
};

const getShippingSummary = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsShippingSummaryResponseDto>(
    STATS_SHIPPING_SUMMARY_URL,
    { params },
  );
  return data;
};

const getShippingSalesByProvince = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsShippingRankByProvinceResponseDto>(
    STATS_SHIPPING_SALES_BY_PROVINCE_URL,
    { params },
  );
  return data;
};

const getShippingRevenueByProvince = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsShippingRankByProvinceResponseDto>(
    STATS_SHIPPING_REVENUE_BY_PROVINCE_URL,
    { params },
  );
  return data;
};

const getRankProvincesByShippingCost = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsProvinceRankValueResponseDto[]>(
    STATS_SHIPPING_PROVINCES_BY_SHIPPING_COST,
    { params },
  );
  return data;
};

const getRankProvincesByShippingTime = async (
  params: StatisticsFiltersParamsRequestDto,
) => {
  const { data } = await axios.get<StatisticsProvinceRankValueResponseDto[]>(
    STATS_SHIPPING_PROVINCES_BY_SHIPPING_TIME,
    { params },
  );
  return data;
};

export default {
  getFreeTrial,
  getDaily,
  getProducts,
  getOrders,
  getGlobalInfo,
  getStoreConversion,
  getStatsProducts,
  getSummary,
  initFreeTrial,
  getStoreHasGA,
  getCustomers,
  getProductsSummary,
  getPaymentsSummary,
  getAcquisitionSummary,
  getShippingSummary,
  getShippingSalesByProvince,
  getShippingRevenueByProvince,
  getRankProvincesByShippingCost,
  getRankProvincesByShippingTime,
};
