import { ReactNode, useState } from 'react';
import { Menu } from '@nimbus-ds/patterns';
import { ThemeProvider } from '@nimbus-ds/styles';
import { Spotlight } from './components';

interface MenuWithSpotlightProps {
  children: ReactNode;
}

function MenuWithSpotlight({ children }: MenuWithSpotlightProps): JSX.Element {
  const [hover, setHover] = useState(false);

  return (
    <ThemeProvider theme="next" style={{ height: '100%' }}>
      <Menu>
        <Spotlight className="stratus--spotlight">
          <div
            className="stratus--spotlight-bg"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <div
              className="stratus--spotlight-border"
              style={{
                opacity: hover ? 1 : 0,
                WebkitMaskImage: `radial-gradient(50% 100px at var(--mouse-x) var(--mouse-y), black 30%, transparent)`,
              }}
            />
            {children}
          </div>
        </Spotlight>
      </Menu>
    </ThemeProvider>
  );
}

export default MenuWithSpotlight;
