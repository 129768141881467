import {
  PaymentMethodDiscountDto,
  UpdateMercadoPagoSettingsRequestDto,
} from '@tiendanube/common';
import {
  BaseCard,
  InterfaceNameValue,
  Stack,
  Title,
} from '@tiendanube/components';
import { SMART_PAYMENT_ADMIN } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { InputNumber } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface DiscountsCardProps {
  discounts: PaymentMethodDiscountDto[];
  values: UpdateMercadoPagoSettingsRequestDto;
  errors: Partial<Record<string, string>>;
  handleOnChangeGroup: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function DiscountsCard({
  discounts,
  values,
  errors,
  handleOnChangeGroup,
}: DiscountsCardProps): JSX.Element | null {
  const t = useTranslationPayments();
  const [hasSmartPaymentAdmin] = useHasTags(SMART_PAYMENT_ADMIN);

  const handleChangeDiscount = (data: InterfaceNameValue) => {
    handleOnChangeGroup('discounts', data);
  };

  const getDiscountStatus = (status, paymentMethod) => {
    if (!hasSmartPaymentAdmin) {
      return !values.paymentMethodTypes.includes(paymentMethod);
    } else return !status;
  };

  if (values.integrationType !== 'transparent') return null;

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{t('paymentProvider.settings.discounts')}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          {discounts?.map(({ enabled, paymentMethodType }) => (
            <InputNumber
              key={paymentMethodType}
              type="integer"
              name={paymentMethodType}
              label={t(`paymentProvider.method.${paymentMethodType}`)}
              appendLabel="%"
              max="100"
              value={values.discounts?.[paymentMethodType] || ''}
              onChange={handleChangeDiscount}
              disabled={getDiscountStatus(enabled, paymentMethodType)}
              helpText={t(errors?.[`discounts[${paymentMethodType}]`] || '')}
              appearance={
                errors?.[`discounts[${paymentMethodType}]`]
                  ? 'validation_error'
                  : 'default'
              }
            />
          ))}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default DiscountsCard;
