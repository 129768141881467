import { Box } from '@nimbus-ds/components';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import Range0Img from './images/0_sales.png';
import Range1000Img from './images/1000_sales.png';
import Range100Img from './images/100_sales.png';
import Range5000Img from './images/5000_sales.png';
import Range500Img from './images/500_sales.png';

import './SalesQuantityRange.scss';

interface SalesRange {
  min: number;
  max: number;
  imageSrc: string;
}

const salesRanges: SalesRange[] = [
  { min: 0, max: 99, imageSrc: Range0Img },
  { min: 100, max: 499, imageSrc: Range100Img },
  { min: 500, max: 999, imageSrc: Range500Img },
  { min: 1000, max: 4999, imageSrc: Range1000Img },
  { min: 5000, max: Infinity, imageSrc: Range5000Img },
];

function getImageSrc(totalOpenOrders: number | undefined): string {
  if (!totalOpenOrders) {
    return Range0Img;
  }

  const matchingRange = salesRanges.find(
    (range) => totalOpenOrders >= range.min && totalOpenOrders <= range.max,
  );

  if (!matchingRange) {
    return Range0Img;
  }

  return matchingRange.imageSrc;
}

function SalesQuantityRange() {
  const { totalOpenOrders } = useOrdersList();
  const imageSrc = getImageSrc(totalOpenOrders);

  return (
    <Box
      display={{
        lg: 'flex',
        md: 'none',
        xs: 'none',
      }}
      alignItems="center"
      justifyContent="center"
      maxHeight="280px"
    >
      <img
        className="stratus--sales-quantity-range__image"
        // validate the correct alt text
        alt={`Sales quantity range: ${totalOpenOrders}`}
        src={imageSrc}
      />
    </Box>
  );
}

export default SalesQuantityRange;
