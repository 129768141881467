import { Text, Box } from '@nimbus-ds/components';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import { Input } from 'commons/components';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { FormPageProps } from '../../../FormPage';

interface InputsSeoParameters
  extends Pick<FormPageProps, 'values' | 'onChange'> {
  language?: LanguagesType;
}

function InputsSeoParameters({
  values,
  onChange,
  language = DEFAULT_LANGUAGE,
}: InputsSeoParameters) {
  const t = useTranslationOnline('contentPages.formPage.cardSeo');
  const { url } = useHeaderMenuData();
  const shortenDomain = `...${url.substring(url.length - 15)}/`;

  const handleChange = ({ name, value }) => {
    onChange({
      name,
      value: { ...values.title, [language]: value },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Input
        label={t('seoTitle.label')}
        value={values.seoTitle?.[language] || ''}
        name="seoTitle"
        type="text"
        placeholder={t('seoTitle.placeholder')}
        onChange={handleChange}
        onBlur={handleChange}
      />

      <Input
        label={t('seoDescription')}
        value={values.seoDescription?.[language] || ''}
        name="seoDescription"
        type="textarea"
        onChange={handleChange}
        onBlur={handleChange}
      />

      <Input
        label={t('urlPage')}
        value={values.pageUrl?.[language] || ''}
        name="pageUrl"
        type="text"
        onChange={handleChange}
        onBlur={handleChange}
        append={<Text>{shortenDomain}</Text>}
      />
    </Box>
  );
}

export default InputsSeoParameters;
