import { useSelector } from 'react-redux';
import { getInfoStore, getIsFreePlan } from '../../authSlice/authSelectors';

function useGetIsFreePlan() {
  const isFreePlan = useSelector(getIsFreePlan);
  const infoStore = useSelector(getInfoStore);

  return { isFreePlan, isTrial: infoStore.isTrial };
}

export default useGetIsFreePlan;
