import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import { webpSupported } from 'commons/utils/webpSupported';
import { COMPATIBLE_APP_VERSION } from './version';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BFF,
  headers: {
    'x-admin-platform': Capacitor.getPlatform(),
    'x-admin-front-version': COMPATIBLE_APP_VERSION,
    'x-admin-build-version': import.meta.env.VITE_APP_VERSION || false,
  },
});

export const setTokenToAxiosInstance = (token: string): void => {
  axiosInstance.defaults.headers['x-access-token'] = token;
};

export const cleanTokenInAxiosInstance = () => {
  setTokenToAxiosInstance('');
};

webpSupported((isSupported) => {
  const accepts = String(
    axiosInstance.defaults.headers.common['Accept'] ?? '',
  ).split(', ');

  const webpAccepted = isSupported ? ['image/webp'] : [];

  axiosInstance.defaults.headers.common['Accept'] = [
    ...webpAccepted,
    ...accepts,
  ].join(', ');
});

export default axiosInstance;
