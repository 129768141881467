import { ProductInterface } from './types';

export function getProductNameWithVariant<Minimalistic extends boolean>(
  product: ProductInterface<Minimalistic>,
  quantity?: number,
): string {
  const variantName = Object.values(product.variantValues).join(', ');
  const fullProductName =
    product.name + (variantName ? ` (${variantName})` : '');
  return quantity ? `${fullProductName} (${quantity})` : fullProductName;
}
