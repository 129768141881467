import { IconButton, Text, Thumbnail, Input } from '@nimbus-ds/components';
import { DataTable } from '@tiendanube/components';
import { Stack } from 'commons/components';
import {
  productColGrow,
  inputColBasis,
  inputColVariant,
  inputColGrow,
  editHeaderColBasis,
  actionsColBasis,
} from '../../constants';
import ResultHeaderDesktop from '../ResultHeaderDesktop';

function ResultDesktopSkeleton() {
  const { Row, Cell } = DataTable;

  return (
    <>
      <ResultHeaderDesktop />
      {[...Array(10)].map((_, k) => (
        <Row id={`row-${k}`} key={k}>
          <Cell grow={productColGrow}>
            <Stack spacing="tight" align="flex-start">
              <Thumbnail.Skeleton aspectRatio="1/1" width="72px" />
              <Stack.Item fill>
                <Text.Skeleton width="150px" fontSize="caption" />
              </Stack.Item>
            </Stack>
          </Cell>
          <Cell grow={inputColGrow} basis={inputColBasis}>
            <Input.Skeleton width="120px" />
          </Cell>
          <Cell grow={inputColGrow} basis={editHeaderColBasis} />
          <Cell grow={productColGrow} />
          <Cell grow={inputColGrow} basis={inputColVariant} />
          <Cell grow={inputColGrow} basis={actionsColBasis}>
            <Stack>
              <IconButton.Skeleton height="32px" width="32px" />
            </Stack>
          </Cell>
        </Row>
      ))}
    </>
  );
}

export default ResultDesktopSkeleton;
