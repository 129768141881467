import axios from 'App/axios';
import { setStorage, getStorage } from 'App/storage';
import {
  JobTypeIdInterface,
  JobsType,
  JobsTypesType,
  SetJobInterface,
  UpdateJobStatusTypeIdInterface,
} from '../jobsSlice';

const TTL = 60 * 60 * 1000; // one hour to keep alive

const filterExpired = (jobs: JobsType) => {
  const now = new Date().getTime();
  const currentsFilteredArray = Object.entries(jobs).filter(
    ([_, value]) => now - value.ttl < TTL,
  );
  return Object.fromEntries(currentsFilteredArray);
};

const getJobsStorage = async (type: JobsTypesType) => {
  const currents = await getStorage<JobsType>(`job-${type}`);
  if (!currents) return {};
  const currentsFiltered = filterExpired(currents);
  setJobsStorage(type, currentsFiltered);
  return currentsFiltered;
};

const setJobsStorage = async (type: JobsTypesType, jobs: JobsType) => {
  setStorage(`job-${type}`, jobs);
};

const setJobStorage = async (job: SetJobInterface) => {
  const { type, id, token, status, ttl, url } = job;
  const currents = await getJobsStorage(type);
  const newTtl = ttl === 0 ? new Date().getTime() : ttl;
  const payload: JobsType = {
    ...currents,
    [id]: { token, ttl: newTtl, status, ...(url && { url }) },
  };
  setJobsStorage(type, payload);
  return payload;
};

const removeJobStorage = async (job: JobTypeIdInterface) => {
  const { type, id } = job;
  const currents = await getJobsStorage(type);
  delete currents[id];
  setJobsStorage(type, currents);
  return currents;
};

const checkJobStatus = async (job: JobTypeIdInterface) => {
  const { type, id, csvDownload } = job;
  const currents = await getJobsStorage(type);
  const currentJob = currents[id];
  const haveCsvDownload = csvDownload ? '/csv-download' : '';
  const { data } = await axios.get(
    `/v1/jobs/${currentJob.token}/status${haveCsvDownload}`,
  );
  if (currentJob.status === data.status) {
    return currents;
  }
  return setJobStorage({
    id,
    type,
    ...currentJob,
    ...data,
  });
};

const setJobStatus = async (job: UpdateJobStatusTypeIdInterface) => {
  const { type, id, status } = job;
  const currents = await getJobsStorage(type);
  const currentJob = currents[id];
  return setJobStorage({ id, type, ...currentJob, status });
};

const jobsServices = {
  setJobStorage,
  getJobsStorage,
  removeJobStorage,
  checkJobStatus,
  setJobStatus,
};

export default jobsServices;
