export const I18nEndpoints = {
  getEnabledCountriesList: 'getEnabledCountriesList',
  getCountries: 'getCountries',
  getCurrencies: 'getCurrencies',
  getLanguages: 'getLanguages',
  enableCountry: 'enableCountry',
  deleteEnabledCountry: 'deleteEnabledCountry',
  updateEnabledCountry: 'updateEnabledCountry',
  updateMainCountry: 'updateMainCountry',
  updateMainCurrency: 'updateMainCurrency',
  getSuggestedRate: 'getSuggestedRate',
  updateExchangeRate: 'updateExchangeRate',
} as const;
