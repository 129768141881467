import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const getFacebookConnectionStatusAction = createAsyncThunk(
  'dashboard/getFacebookConnectionStatusAction',
  dashboardService.getFacebookConnectionStatus,
);

const socialMessagesSlice = createSlice({
  name: 'socialMessagesSlice',
  initialState: initialState.socialMessages,
  reducers: {
    cleanFacebookConnectionDataAction(state) {
      state.facebookConnection.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.socialMessages;
      return state;
    });

    builder
      .addCase(getFacebookConnectionStatusAction.pending, (state) => {
        state.facebookConnection.status = 'loading';
      })
      .addCase(getFacebookConnectionStatusAction.rejected, (state) => {
        state.facebookConnection.status = 'error';
      })
      .addCase(getFacebookConnectionStatusAction.fulfilled, (state, action) => {
        state.facebookConnection.status = 'success';
        state.facebookConnection.data = action.payload;
      });
  },
});

export const { reducer } = socialMessagesSlice;
export const { cleanFacebookConnectionDataAction } =
  socialMessagesSlice.actions;
