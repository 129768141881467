import { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Link, Text } from '@nimbus-ds/components';
import { generatePath } from 'react-router';
import { useNavegate } from 'App/hooks';
import { useStorage } from 'commons/hooks';
import { ActiveShippingMethodsInterface } from 'domains/Shipping/DeliveryMethods/pages/components/ActiveShippingMethodsCard/components/ActiveShippingMethodsList/ActiveShippingMethodsList';
import { NATIVE_SHIPPING_METHODS_EDIT_LINKS } from 'domains/Shipping/DeliveryMethods/pages/components/ActiveShippingMethodsCard/constants';
import useGetDetailActiveApp from 'domains/Shipping/DeliveryMethods/pages/EditCarriersPage/useGetDetailActiveApp';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  trackingCommunication,
  trackingCommunicationButton,
} from '../tracking';

const JADLOG_PATH =
  'https://atendimento.nuvemshop.com.br/pt_BR/-nuvem-envio-jadlog/como-funciona-o-nuvem-envio-jadlog-com-pontos-de-postagem';

interface JadlogCommunicationProps {
  activeShippingMethods: ActiveShippingMethodsInterface[];
  page: 'shipping' | 'orders';
}

function JadlogCommunication({
  activeShippingMethods,
  page,
}: JadlogCommunicationProps) {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const [modalJadlogClosed, setModalJadlogClosed] = useStorage(
    'nuvem-envio-jadlog',
    false,
  );
  const [nuvemEnvioApp, setNuvemEnvioApp] =
    useState<ActiveShippingMethodsInterface>(
      {} as ActiveShippingMethodsInterface,
    );
  const { detailActiveApp, fetchDetailActiveApp, isError, isLoading } =
    useGetDetailActiveApp();

  const isJadlogEnabled = useMemo(
    () =>
      detailActiveApp?.options?.['ne-jadlog-package']?.isEnabled ||
      detailActiveApp?.options?.['ne-jadlog-com']?.isEnabled,
    [detailActiveApp?.options],
  );

  const handleActions = {
    navigate: () => {
      trackingCommunicationButton('jadlog', page);
      const path =
        NATIVE_SHIPPING_METHODS_EDIT_LINKS[nuvemEnvioApp.code] ??
        generatePath(DELIVERY_METHODS_ROUTES.carriersDetail, {
          id: nuvemEnvioApp.id,
        });
      goTo(path);
    },
    removeModal: () => {
      trackingCommunicationButton('jadlog', page, 'close');
      setModalJadlogClosed(true);
    },
    moreInfo: () => trackingCommunicationButton('jadlog', page, 'mais-sobre'),
  };

  useEffect(() => {
    trackingCommunication('jadlog', page);
    const nuvemEnvioMethod = activeShippingMethods.find((method) =>
      method.code.includes('nuvem-envio'),
    );
    if (nuvemEnvioMethod) {
      setNuvemEnvioApp(nuvemEnvioMethod);
      fetchDetailActiveApp(nuvemEnvioMethod.id);
    }
  }, [activeShippingMethods, fetchDetailActiveApp, page]);

  if (isLoading) return <Alert.Skeleton />;

  const shouldShowAlert =
    !isLoading &&
    !isError &&
    detailActiveApp?.options &&
    Object.keys(detailActiveApp.options).length > 0 &&
    !isJadlogEnabled &&
    (page === 'shipping' || (page === 'orders' && !modalJadlogClosed));

  if (!shouldShowAlert) return null;

  return (
    <Box>
      <Alert
        appearance="primary"
        title={t(`nuvem-envio-commons.${page}.jadlog-communication.title`)}
        {...(page === 'orders' && { onRemove: handleActions.removeModal })}
      >
        <Text>
          {t(`nuvem-envio-commons.${page}.jadlog-communication.text`)}
        </Text>
        <Box display="flex" flexDirection="row" gap="4" alignItems="center">
          <Button onClick={handleActions.navigate}>
            {t(`nuvem-envio-commons.${page}.jadlog-communication.button`)}
          </Button>
          <Link
            as="a"
            href={JADLOG_PATH}
            target="_blank"
            onClick={handleActions.moreInfo}
          >
            {t('nuvem-envio-commons.shipping.jadlog-communication.link')}
          </Link>
        </Box>
      </Alert>
    </Box>
  );
}

export default JadlogCommunication;
