import { useGetIsFreePlan } from 'domains/Auth/hooks';
import { FreePlanDetail, SubscriptionDetail } from './components';
import 'domains/Billing/Checkout/Checkout.scss';

function PlansAndApps(): JSX.Element {
  const { isFreePlan } = useGetIsFreePlan();
  return isFreePlan ? <FreePlanDetail /> : <SubscriptionDetail />;
}

export default PlansAndApps;
