import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  UpdateDocumentSettingsRequestDto,
  GetDocumentSettingsResponseDto,
  CreateLabelRequestDto,
  CreateReceiptRequestDto,
  CreateContentDeclarationRequestDto,
  OcaLabelsSettingOptionsResponseDto,
  OrderDetailsForOcaLabelResponseDto,
  OcaLabelCostPreviewShippingOrderRequestDto,
  OcaCreateLabelRequestDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import fulfillmentOrderService from '../fulfillmentOrdersService';

export type statusType = 'idle' | 'loading' | 'success' | 'error';
export interface InterfaceDocumentSettingsSchema {
  status: statusType;
  settings?: GetDocumentSettingsResponseDto;
}

export interface InterfaceDocumentSchema {
  status: statusType;
}

export interface InterfaceOcaLabelSettingOptionsSchema {
  data: OcaLabelsSettingOptionsResponseDto;
  status: statusType;
}

export interface InterfaceOcaLabelsOrdersDetailsSchema {
  data: OrderDetailsForOcaLabelResponseDto[];
  status: statusType;
}

export interface InterfaceOcaLabelsSchema {
  data: OcaLabelResponseDto[];
  status: statusType;
}

export interface InterfaceCreateOcaLabelsSchema {
  token?: string;
  tokenStatus: statusType;
}

export interface InterfaceFulfillmentOrdersSchema {
  documentSettings: InterfaceDocumentSettingsSchema;
  document: InterfaceDocumentSchema;
  ocaDocumentSettings: InterfaceOcaLabelSettingOptionsSchema;
  ocaOrdersDetails: InterfaceOcaLabelsOrdersDetailsSchema;
  ocaLabels: InterfaceOcaLabelsSchema;
  createOcaLabels: InterfaceCreateOcaLabelsSchema;
}

const initialState: InterfaceFulfillmentOrdersSchema = {
  documentSettings: {
    status: 'idle',
  },
  document: {
    status: 'idle',
  },
  ocaDocumentSettings: {
    data: {
      boxOptions: [],
      dispatchMethods: [],
      schedulingForWithdrawals: [],
      agencies: [],
    },
    status: 'idle',
  },
  ocaOrdersDetails: {
    data: [],
    status: 'idle',
  },
  ocaLabels: {
    data: [],
    status: 'idle',
  },
  createOcaLabels: {
    tokenStatus: 'idle',
  },
};

export const getDocumentSettings =
  createAsyncThunk<GetDocumentSettingsResponseDto>(
    'printManager/getDocumentSettings',
    async () => {
      const documentSettings =
        await fulfillmentOrderService.getDocumentSettings();
      return documentSettings;
    },
  );

export const updateDocumentSettings = createAsyncThunk(
  'printManager/updateDocumentSettings',
  (labelSetting: UpdateDocumentSettingsRequestDto) =>
    fulfillmentOrderService.updateDocumentSettings(labelSetting),
);

export const createInformationForLabelDocument = createAsyncThunk(
  'printManager/label',
  async (params: CreateLabelRequestDto) =>
    fulfillmentOrderService.createInformationForLabelDocument(params),
);

export const createInformationForContentDeclarationDocument = createAsyncThunk(
  'printManager/contentDeclaration',
  async (params: CreateContentDeclarationRequestDto) =>
    fulfillmentOrderService.createInformationForContentDeclarationDocument(
      params,
    ),
);

export const createInformationForReceiptDocument = createAsyncThunk(
  'printManager/receipt',
  async (params: CreateReceiptRequestDto) =>
    fulfillmentOrderService.createInformationForReceiptDocument(params),
);

export const createLabelDocumentDownloadLink = createAsyncThunk(
  'printManager/labelDownload',
  async (params: CreateLabelRequestDto) =>
    fulfillmentOrderService.createLabelDocumentDownloadLink(params),
);

export const createContentDeclarationDownloadLink = createAsyncThunk(
  'printManager/contentDeclarationDownload',
  async (params: CreateContentDeclarationRequestDto) =>
    fulfillmentOrderService.createContentDeclarationDownloadLink(params),
);

export const createReceiptDownloadLink = createAsyncThunk(
  'printManager/receiptDownload',
  async (params: CreateReceiptRequestDto) =>
    fulfillmentOrderService.createReceiptDownloadLink(params),
);

export const getOcaLabelSettingOptions = createAsyncThunk(
  'printManager/ocaDocumentSettingOptions',
  async () => fulfillmentOrderService.getOcaLabelSettingOptions(),
);

export const getOrderDetailsForOcaLabel = createAsyncThunk(
  'printManager/ocaOrdersDetailsForDocument',
  async (payload: { orders: string[]; isOverwriting: boolean }) =>
    fulfillmentOrderService.getOrderDetailsForOcaLabel(payload),
);

export const generateOcaLabelsCostsPreviews = createAsyncThunk(
  'printManager/ocaCostsPreviews',
  async (params: OcaLabelCostPreviewShippingOrderRequestDto) =>
    fulfillmentOrderService.generateOcaLabelsCostsPreviews(params),
);

export const createOcaLabels = createAsyncThunk(
  'printManager/createOcaLabels',
  async (params: OcaCreateLabelRequestDto[]) =>
    fulfillmentOrderService.createOcaLabels(params),
);

export const getOcaLabels = createAsyncThunk(
  'printManager/getOcaLabels',
  async (orders: string[]) => fulfillmentOrderService.getOcaLabels(orders),
);

export const getOcaLabelsGenerationStatus = createAsyncThunk(
  'printManager/getOcaLabelsGenerationStatus',
  async (token: string) =>
    fulfillmentOrderService.getOcaLabelsGenerationStatus(token),
);

const fulfillmentOrdersSlice = createSlice({
  name: 'printManager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentSettings.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.documentSettings.status = 'success';
        state.documentSettings.settings = action.payload;
      })
      .addCase(getDocumentSettings.pending, (state) => {
        state.documentSettings.status = 'loading';
        return state;
      })
      .addCase(getDocumentSettings.rejected, (state) => {
        state.documentSettings.status = 'error';
        return state;
      });

    builder
      .addCase(updateDocumentSettings.fulfilled, (state) => {
        state.documentSettings.status = 'success';
        state.documentSettings.settings =
          initialState.documentSettings.settings;
        return state;
      })
      .addCase(updateDocumentSettings.rejected, (state) => {
        state.documentSettings.status = 'error';
        return state;
      });

    builder
      .addCase(createInformationForLabelDocument.fulfilled, (state) => {
        state.document.status = 'success';
        return state;
      })
      .addCase(createInformationForLabelDocument.pending, (state) => {
        state.document.status = 'loading';
        return state;
      })
      .addCase(createInformationForLabelDocument.rejected, (state) => {
        state.document.status = 'error';
        return state;
      });

    builder
      .addCase(
        createInformationForContentDeclarationDocument.fulfilled,
        (state) => {
          state.document.status = 'success';
          return state;
        },
      )
      .addCase(
        createInformationForContentDeclarationDocument.pending,
        (state) => {
          state.document.status = 'loading';
          return state;
        },
      )
      .addCase(
        createInformationForContentDeclarationDocument.rejected,
        (state) => {
          state.document.status = 'error';
          return state;
        },
      );

    builder
      .addCase(createInformationForReceiptDocument.fulfilled, (state) => {
        state.document.status = 'success';
        return state;
      })
      .addCase(createInformationForReceiptDocument.pending, (state) => {
        state.document.status = 'loading';
        return state;
      })
      .addCase(createInformationForReceiptDocument.rejected, (state) => {
        state.document.status = 'error';
        return state;
      });

    builder
      .addCase(getOcaLabelSettingOptions.fulfilled, (state, action) => {
        state.ocaDocumentSettings.status = 'success';
        state.ocaDocumentSettings.data = action.payload;
        return state;
      })
      .addCase(getOcaLabelSettingOptions.pending, (state) => {
        state.ocaDocumentSettings.status = 'loading';
        return state;
      })
      .addCase(getOcaLabelSettingOptions.rejected, (state) => {
        state.ocaDocumentSettings.status = 'error';
        return state;
      });
    builder
      .addCase(getOrderDetailsForOcaLabel.fulfilled, (state, action) => {
        state.ocaOrdersDetails.status = 'success';
        state.ocaOrdersDetails.data = action.payload;
        return state;
      })
      .addCase(getOrderDetailsForOcaLabel.pending, (state) => {
        state.ocaOrdersDetails.status = 'loading';
        return state;
      })
      .addCase(getOrderDetailsForOcaLabel.rejected, (state) => {
        state.ocaOrdersDetails.status = 'error';
        return state;
      });
    builder.addCase(createOcaLabels.fulfilled, (state, action) => {
      state.createOcaLabels.token = action.payload.job.token;
      return state;
    });
    builder
      .addCase(getOcaLabels.fulfilled, (state, action) => {
        state.ocaLabels.status = 'success';
        state.ocaLabels.data = action.payload;
        return state;
      })
      .addCase(getOcaLabels.pending, (state) => {
        state.ocaLabels.status = 'loading';
        return state;
      })
      .addCase(getOcaLabels.rejected, (state) => {
        state.ocaLabels.status = 'error';
        return state;
      });
    builder
      .addCase(getOcaLabelsGenerationStatus.fulfilled, (state, action) => {
        let status: statusType = 'idle';
        switch (action.payload.status) {
          case 'error':
            status = 'error';
            break;
          case 'success':
            status = 'success';
            state.createOcaLabels.token = undefined;
            break;
          case 'pending':
            status = 'loading';
            break;
        }
        state.createOcaLabels.tokenStatus = status;
        return state;
      })
      .addCase(getOcaLabelsGenerationStatus.pending, (state) => {
        state.createOcaLabels.tokenStatus = 'loading';
        return state;
      })
      .addCase(getOcaLabelsGenerationStatus.rejected, (state) => {
        state.createOcaLabels.tokenStatus = 'error';
        return state;
      });
  },
});

export const { reducer } = fulfillmentOrdersSlice;
