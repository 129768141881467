import { useEffect } from 'react';
import { Icon, Link, Text } from '@nimbus-ds/components';
import { GiftBoxIcon } from '@nimbus-ds/icons';
import dayjs from 'dayjs';
import { match } from 'path-to-regexp';
import { Trans, useTranslation } from 'react-i18next';
import { useNavegate } from 'App/hooks';
import { useDismissibleComponent } from 'commons/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import {
  useGetIsFreePlan,
  useGetPlan,
  useGetPlanExpiresIn,
} from 'domains/Auth/hooks';
import { billingRoutes } from 'domains/Billing';
import {
  trackingBillingTestDiscountsAdBarShow,
  trackingTestDiscounysAdBarClick,
} from 'domains/Billing/tracking';
import { AdBar } from './AdBar';
import { useDesktopMenuContext } from '../Menu/DesktopMenuProvider';
import useTopBar from '../Topbar/useTopBar';

interface StorePaymentDiscountProps {
  percentage: number;
  expirationDate: Date;
}

const HIDDEN_LINK_FOR_THIS_ROUTES = [
  billingRoutes.plans,
  billingRoutes.plansAndSubscriptions,
  billingRoutes.choosePaymentMethodForPlan,
  billingRoutes.payWithCreditCard,
];

export function StorePaymentDiscount({
  percentage,
  expirationDate,
}: Readonly<StorePaymentDiscountProps>) {
  const { t } = useTranslation('common');
  const { isFreePlan, isTrial } = useGetIsFreePlan();
  const { handleGoToPlans } = useGoToPlans();
  const { pathname } = useNavegate();
  const { setHeaderNavigation, hideMenu, showMenu } = useTopBar();
  const { hideMenu: hideAsideMenu, showMenu: showAsideMenu } =
    useDesktopMenuContext();
  const { name: planName } = useGetPlan();
  const expiresIn = useGetPlanExpiresIn();
  const { dismiss, shouldShow } = useDismissibleComponent(
    'storePaymentDiscount',
    7,
  );

  const isHiddenLink = HIDDEN_LINK_FOR_THIS_ROUTES.some((route) => {
    const matchRoute = match(route, { decode: decodeURIComponent });
    return matchRoute(pathname);
  });

  const hideMenus = () => {
    hideMenu();
    hideAsideMenu();
  };

  const showMenus = () => {
    showMenu();
    showAsideMenu();
  };

  const handleClick = () => {
    hideMenus();
    trackingTestDiscounysAdBarClick();
    setHeaderNavigation({
      children: t('settingsMenu.goBack'),
      onClick() {
        showMenus();
        handleGoToPlans();
      },
    });
    handleGoToPlans();
  };

  const getText = () => {
    if (
      !isFreePlan &&
      isTrial &&
      !HIDDEN_LINK_FOR_THIS_ROUTES.includes(pathname)
    ) {
      return 'topAdBar.storePaymentDiscount.textv2';
    }

    return 'topAdBar.storePaymentDiscount.text';
  };

  useEffect(() => {
    if (shouldShow) trackingBillingTestDiscountsAdBarShow();
    return () => {
      dismiss();
    };
  }, [shouldShow, dismiss]);

  return (
    <AdBar appearence="success">
      <Icon color="success-textLow" source={<GiftBoxIcon />} />
      <Text color="success-textLow">
        <Trans
          t={t}
          i18nKey={getText()}
          components={{ bold: <strong /> }}
          values={{
            percentage,
            expirationDate: dayjs(expirationDate).tz('UTC').format('DD/MM'),
            planName,
            days: expiresIn,
          }}
        />
      </Text>
      {!isHiddenLink && (
        <Link onClick={handleClick} appearance="primary" textDecoration="none">
          {t('topAdBar.storePaymentDiscount.link')}
        </Link>
      )}
    </AdBar>
  );
}
