import { ChangeEvent } from 'react';
import { Select, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { LocationResponseDto } from '@tiendanube/common';
import { CardCollapsible, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ShippingLocationCardProp {
  locations: LocationResponseDto[];
  locationId?: string;
  error?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function ShippingLocationCard({
  locations,
  locationId,
  error,
  onChange,
}: ShippingLocationCardProp) {
  const t = useTranslationShipping();

  locations.sort((a, b) =>
    a.main && !b.main ? -1 : b.main && !a.main ? 1 : 0,
  );

  const optionsSelect = locations.map((location) => {
    const { id, main } = location;
    const name = main
      ? `${location.name} ${t(
          'deliveryMethods.customShipping.locationCard.main',
        )}`
      : location.name;
    return {
      value: id,
      label: name,
    };
  });

  return (
    <CardCollapsible
      title={`${t('deliveryMethods.customShipping.locationCard.title')}`}
      active
    >
      <Stack column align="stretch">
        <Text>
          {t('deliveryMethods.customShipping.locationCard.description')}
        </Text>

        <FormField.Select
          name="locationId"
          id="locationId"
          value={locationId ?? ''}
          onChange={(e) => onChange(e)}
          showHelpText={!!error}
          helpText={error && !locationId ? t(error) : undefined}
          appearance={error && !locationId ? 'danger' : 'none'}
        >
          <Select.Option
            value=""
            label={t('deliveryMethods.customShipping.locationCard.placeholder')}
          />
          {optionsSelect.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              label={option.label}
            />
          ))}
        </FormField.Select>
      </Stack>
    </CardCollapsible>
  );
}

export default ShippingLocationCard;
