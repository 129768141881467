export const FreeShippingEndpoints = {
  getFreeShippingAvailable: 'getFreeShippingAvailable',
  getFreeShippingsList: 'getFreeShippingsList',
  getFreeShippingsListInf: 'getFreeShippingsListInf',
  getFreeShippingsOptions: 'getFreeShippingsOptions',
  getFreeShippingsZones: 'getFreeShippingsZones',
  getFreeShippingsSubZones: 'getFreeShippingsSubZones',
  setFreeShippingStatus: 'setFreeShippingStatus',
  deleteFreeShipping: 'deleteFreeShipping',
  createFreeShipping: 'createFreeShipping',
  getFreeShipping: 'getFreeShipping',
  updateFreeShipping: 'updateFreeShipping',
} as const;

export const FreeShippingTags = {
  freeShippingsAvailable: 'freeShippingsAvailable',
} as const;

export const BASE_FREE_SHIPPING_URL = '/v1/free-shipping';
export const FREE_SHIPPING_ID_URL = `${BASE_FREE_SHIPPING_URL}/:id`;
export const FREE_SHIPPING_AVAILABLE_URL = `${BASE_FREE_SHIPPING_URL}/available`;
export const FREE_SHIPPING_STATUS_URL = `${BASE_FREE_SHIPPING_URL}/:id/:status`;
export const FREE_SHIPPING_OPTIONS_URL = `${BASE_FREE_SHIPPING_URL}/shipping-options`;
export const FREE_SHIPPING_ZONES_URL = `${BASE_FREE_SHIPPING_URL}/zones`;
export const FREE_SHIPPING_SUBZONES_URL = `${BASE_FREE_SHIPPING_URL}/subzones/:id`;
