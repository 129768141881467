import { Box, Text, Thumbnail } from '@nimbus-ds/components';
import {
  ProductDetailsResponseDto,
  VariantResponseDto,
} from '@tiendanube/common';

interface ImageItemDescriptionProps {
  language: string;
  product: ProductDetailsResponseDto;
  variant?: VariantResponseDto;
}

function ImageItemDescription({
  language,
  product,
  variant,
}: ImageItemDescriptionProps): JSX.Element {
  const [firstImage] = product?.images ? product.images : [];

  const variantValue = variant?.values?.map((v) => v[language]).join(' / ');

  return (
    <Box display="flex" flexDirection="row">
      <Box>
        {firstImage ? (
          <Thumbnail
            src={firstImage.src}
            alt={
              (firstImage.alt
                ? firstImage.alt[language]
                : `${product?.name[language]} - ${firstImage.id}`) || ''
            }
            width="72px"
            aspectRatio="1/1"
          />
        ) : (
          <Thumbnail aspectRatio="1/1" width="72px" alt="no-image" />
        )}
      </Box>
      <Box
        marginLeft="2"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <Text fontSize="highlight">{product?.name[language]}</Text>
        <Text fontSize="base">{variantValue}</Text>
      </Box>
    </Box>
  );
}

export default ImageItemDescription;
