import { ReactNode } from 'react';
import { Box, BoxProps } from '@nimbus-ds/components';
import Item from './Stack.Item';

export type { InterfaceStackItem } from './Stack.Item';

const mapSpacing: Record<string, BoxProps['gap']> = {
  none: 'none',
  tight: '2',
  base: '4',
  loose: '6',
};

export interface InterfaceStack {
  /** React node of type children */
  children: ReactNode;
  /** Justify content */
  justify?: BoxProps['justifyContent'];
  /** Align-items */
  align?: BoxProps['alignItems'];
  /** Spacing between children */
  spacing?: 'base' | 'tight' | 'loose' | 'none';
  /** Define if the stack is a column */
  column?: boolean;
  /** Define if items break into lines upon reaching the parent's limit */
  wrap?: boolean;
  /** Define gap between elements */
  gap?: BoxProps['gap'];
  /** Is the same used in flexbox */
  flex?: BoxProps['flex'];
  /** Align self element */
  alignSelf?: BoxProps['alignSelf'];
  /** Change render order */
  order?: BoxProps['order'];
  /** A reference to a React element allowing to access the underlying DOM */
  innerRef?: React.RefObject<HTMLDivElement> | false;
}

/**
 * @param children React node of type children
 * @param justify Justify content
 * @param align Align-items
 * @param spacing Define if the stack is a column
 * @param column Define if the stack is a column
 * @param wrap Define if items break into lines upon reaching the parent's limit
 * @param gap Define gap between elements
 * @param flex Is the same used in flexbox
 * @param alignSelf Align self element
 * @param order Change render order
 * @param innerRef A reference to a React element allowing to access the underlying DOM
 */

function Stack({
  children,
  justify = 'flex-start',
  align = 'center',
  spacing = 'base',
  column = false,
  wrap = false,
  gap = '4',
  flex = '0 1 auto',
  alignSelf = 'auto',
  innerRef,
  ...props
}: InterfaceStack): JSX.Element {
  const getGap = () => {
    // If receive gap, overwrite "spacing" value.
    if (spacing === 'base' && gap !== '4') {
      return gap;
    }
    return mapSpacing[spacing];
  };

  return (
    <Box
      {...props}
      display="flex"
      justifyContent={justify}
      ref={innerRef || undefined}
      alignItems={align}
      gap={getGap()}
      flexWrap={wrap ? 'wrap' : 'nowrap'}
      flexDirection={column ? 'column' : 'row'}
      flex={flex}
      alignSelf={alignSelf}
    >
      {children}
    </Box>
  );
}

Stack.Item = Item;

export default Stack;
