import { useCallback } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { FEATURE_ONLY_NUVEM_PAGO_PAYMENTS } from 'App/features';
import { NUVEMPAGO_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useGetCountry,
  useHasLimitingFeature,
  useIsLogged,
} from 'domains/Auth/hooks';
import { useGetKycStatusQuery } from '../nuvemPagoCommonsApi';

const AVAILABLE_NUVEMPAGO_COUNTRIES = [Country.BR, Country.AR];

export function useKycStatus() {
  const { isLogin } = useIsLogged();
  const { validateRoles } = useRoles();
  const hasFullRole = validateRoles(['full']);
  const storeCountry = useGetCountry();
  const hasNuvempagoEnabled = useHasTags(NUVEMPAGO_ENABLED);
  const hasKycFeature = useHasLimitingFeature(FEATURE_ONLY_NUVEM_PAGO_PAYMENTS);
  const availableNuvempago =
    AVAILABLE_NUVEMPAGO_COUNTRIES.includes(storeCountry);
  const requireFetch =
    isLogin &&
    hasNuvempagoEnabled &&
    availableNuvempago &&
    hasFullRole &&
    hasKycFeature;
  const {
    refetch: fetchKycStatus,
    data,
    ...kycQueryResult
  } = useGetKycStatusQuery(undefined, {
    skip: !requireFetch,
  });

  const refreshKycStatus = useCallback(() => {
    if (requireFetch) fetchKycStatus();
  }, [fetchKycStatus, requireFetch]);

  return {
    ...kycQueryResult,
    isKycRejected: data?.status === 'denied',
    refreshKycStatus,
  };
}
