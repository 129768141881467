export const DAYS_TOTAL = {
  currentDay: {
    total: 1,
    type: 'day',
  },
  currentWeek: {
    total: 1,
    type: 'week',
  },
  currentMonth: {
    total: 1,
    type: 'month',
  },
  currentQuarter: {
    total: 3,
    type: 'month',
  },
  currentYear: {
    total: 1,
    type: 'year',
  },
};
