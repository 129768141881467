import { useNavegate } from 'App/hooks';
import useShouldRedirectPlanPage from 'App/hooks/useShouldRedirectPlanPage';
import goToAdmin from 'commons/utils/gotToAdmin';
import { BILLING_ROUTES } from 'domains/Billing/billingRoutes';
import useGetIsFreemium from '../useGetIsFreemium';
import useIsMobileDevice from '../useIsMobileDevice';

function useGoToPlans() {
  const isFreemium = useGetIsFreemium();
  const isMobile = useIsMobileDevice();
  const plansPageNewAdmin = useShouldRedirectPlanPage();
  const { goTo } = useNavegate();

  const goToPlans = () => {
    if (plansPageNewAdmin) {
      return goTo(BILLING_ROUTES.plans);
    } else if (!plansPageNewAdmin && !isMobile) {
      return goToAdmin(BILLING_ROUTES.plans)();
    }
    return null;
  };

  const isMobileNonFreemium = isMobile && !isFreemium;

  return { goToPlans, isMobileNonFreemium };
}

export default useGoToPlans;
