import { Text, Stack } from '@tiendanube/components';

function DateAndDeviceSkeleton(): JSX.Element {
  return (
    <Stack spacing="tight">
      <Text.Skeleton />
      <Text.Skeleton />
    </Stack>
  );
}

export default DateAndDeviceSkeleton;
