import { useEffect } from 'react';
import { Tabs } from '@nimbus-ds/components';
import { useAppDispatch } from 'App/store';
import { HeaderContent } from 'commons/components';
import IonPageStratus from 'commons/components/IonPageStratus/IonPageStratus';
import {
  freeShippingApi,
  FreeShippingTags,
} from 'domains/Marketing/FreeShipping/freeShippingApi';
import { useAddressingConfigurationRulesManagement } from 'domains/Shipping/DeliveryMethods/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { LocationsListEmpty } from 'domains/Shipping/Locations/pages/LocationListPage/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  InternationalShippingMethods,
  NationalDeliveryMethods,
  PhysicalLocations,
} from './components';
import './DeliveryMethodsPage.scss';

function DeliveryMethodsPage() {
  const t = useTranslationShipping();
  const dispatch = useAppDispatch();
  const {
    fetchLocations,
    locationMain,
    isSuccess,
    isLoading: isLoadingLocation,
  } = useLocations();
  const { getStatus: rulesStatus, fetchRules } =
    useAddressingConfigurationRulesManagement();

  useEffect(() => {
    fetchLocations();
    fetchRules();
    return () => {
      dispatch(
        freeShippingApi.util.invalidateTags([
          FreeShippingTags.freeShippingsAvailable,
        ]),
      );
    };
  }, [fetchLocations, dispatch, fetchRules]);

  const locationMainIsEmpty = !locationMain && isSuccess;

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('deliveryMethods.title')} />}
      width="medium"
    >
      {locationMainIsEmpty && <LocationsListEmpty origin="deliveryMethods" />}
      {!locationMainIsEmpty && (
        <div className="stratus--delivery-methods-tabs">
          <Tabs fullWidth>
            <Tabs.Item label={t('deliveryMethods.tabs.nationals')}>
              <NationalDeliveryMethods
                showConfigurationInfoCard={!isLoadingLocation && !!locationMain}
                isLoadingConfigurationInfoCard={
                  rulesStatus === 'loading' && isLoadingLocation
                }
              />
            </Tabs.Item>
            <Tabs.Item label={t('deliveryMethods.tabs.international')}>
              <InternationalShippingMethods />
            </Tabs.Item>
            <Tabs.Item label={t('deliveryMethods.tabs.phisical')}>
              <PhysicalLocations />
            </Tabs.Item>
          </Tabs>
        </div>
      )}
    </IonPageStratus>
  );
}

export default DeliveryMethodsPage;
