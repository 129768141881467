import { Box, Button, Icon, Modal, Text, Title } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import Image from './FirstPaymentModalImage.png';
import { useFirstPaymentModal } from './hooks';

function FirstPaymentModal() {
  const { shouldShow, closeModal } = useFirstPaymentModal();
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.firstPaymentModal',
  );
  return (
    <Modal open={shouldShow} onDismiss={closeModal}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="8"
        padding="5"
        paddingTop="12"
        paddingBottom="2"
      >
        <img src={Image} />
        <Box display="flex" flexDirection="column" alignItems="center" gap="4">
          <Title fontSize="6">{t('title')}</Title>
          <Text textAlign="center" fontSize="highlight">
            {t('message')}
          </Text>
        </Box>
        <Box display="flex" flexDirection="row" gap="2">
          <Button onClick={closeModal}>{t('closeButton')}</Button>
          <Button as="a" appearance="primary" href={t('link')} target="_blank">
            {t('linkMessage')}{' '}
            <Icon color="currentColor" source={<ExternalLinkIcon />} />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default FirstPaymentModal;
