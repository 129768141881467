import { AxiosRequestConfig } from 'axios';

function requiredValidation(
  { url, method }: AxiosRequestConfig,
  pathsWithVerification: string[],
) {
  if (!url || !method || method.toUpperCase() === 'GET') {
    return false;
  }

  if (url.includes('advanced-search')) {
    return false;
  }

  const loginPaths = ['/auth/login', '/auth/v1'];
  if (loginPaths.includes(url)) {
    return true;
  }

  return pathsWithVerification.find((path) => url.includes(path));
}

export default requiredValidation;
