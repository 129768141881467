import { useSelector } from 'react-redux';
import { useGetLimitingFeatureByKey } from 'domains/Auth/hooks';
import {
  getStatisticsFreeTrialData,
  getStatisticsFreeTrialStatus,
} from 'domains/Statistics/selectors';

function useGetStatisticsFreeTrialData() {
  const isPlanFree = useGetLimitingFeatureByKey('new_admin_stats', 1);
  const { isError, isLoading } = useSelector(getStatisticsFreeTrialStatus);
  const freeTrial = useSelector(getStatisticsFreeTrialData);

  return {
    isLoading,
    isPlanFree,
    isError,
    freeTrial,
  };
}

export default useGetStatisticsFreeTrialData;
