import { Box } from '@nimbus-ds/components';
import { SummaryType } from 'domains/Statistics/types';
import Skeleton from './Skeleton';
import SummaryValuesCard, { SummaryValues } from '../../SummaryValuesCard';

interface SummaryFreemiumCardContentProps {
  values: Record<SummaryType, SummaryValues>;
}

function SummaryFreemiumCardContent({
  values,
}: SummaryFreemiumCardContentProps) {
  return (
    <Box
      display="grid"
      gridGap="4"
      gridTemplateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        xs: 'repeat(1, 1fr)',
      }}
      paddingLeft="4"
      paddingRight="4"
      paddingBottom="4"
    >
      <SummaryValuesCard
        item="uniqueVisits"
        currentValue={values.uniqueVisits.currentValue}
        previousValue={values.uniqueVisits.previousValue}
        percentageDiff={values.uniqueVisits.percentageDiff}
        textTooltip="statistics.globalInfoCard.uniqueVisits.tooltip"
      />

      <SummaryValuesCard
        item="orders"
        currentValue={values.orders.currentValue}
        previousValue={values.orders.previousValue}
        percentageDiff={values.orders.percentageDiff}
        textTooltip="statistics.globalInfoCard.orders.tooltip"
      />

      <SummaryValuesCard
        item="billing"
        currentValue={values.billing.currentValue}
        previousValue={values.billing.previousValue}
        percentageDiff={values.billing.percentageDiff}
        textTooltip="statistics.globalInfoCard.billing.tooltip"
      />

      <SummaryValuesCard
        item="averageSpend"
        currentValue={values.averageSpend.currentValue}
        previousValue={values.averageSpend.previousValue}
        percentageDiff={values.averageSpend.percentageDiff}
        textTooltip="statistics.globalInfoCard.averageSpend.tooltip"
      />

      <SummaryValuesCard
        item="conversion"
        currentValue={values.conversion.currentValue}
        previousValue={values.conversion.previousValue}
        percentageDiff={values.conversion.percentageDiff}
        textTooltip="statistics.globalInfoCard.conversion.tooltip"
      />
    </Box>
  );
}

SummaryFreemiumCardContent.Skeleton = Skeleton;

export default SummaryFreemiumCardContent;
