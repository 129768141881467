import { Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('locations.form.salesChannel.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="base">
          <Text>{t('locations.form.salesChannel.subtitle')}</Text>
          <hr />
          <Checkbox.Skeleton />
          <hr />
          <Checkbox.Skeleton />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default Skeleton;
