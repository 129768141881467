import { useState } from 'react';
import { Text } from '@nimbus-ds/components';
import { Label } from '@tiendanube/components';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface FulfillmentOrderSelectedHeaderProps {
  labels: string[];
  isLoading: boolean;
}

function FulfillmentOrderSelectedHeader({
  labels,
  isLoading,
}: Readonly<FulfillmentOrderSelectedHeaderProps>) {
  const t = useTranslationFulfillmentOrders('printManager.informationCard');
  const [showAllLabels, setShowAllLabels] = useState(false);
  const firstTenLabels = labels.slice(0, 10);
  const restOfLabels = labels.slice(10);
  return (
    <Stack column align="stretch">
      <Text>{t('selectedOrders')}</Text>
      <Stack wrap spacing="tight">
        {!isLoading &&
          firstTenLabels
            .sort((a, b) => a.localeCompare(b))
            .map((label) => (
              <Label
                key={label}
                id={label}
                label={label}
                appearance="success"
              />
            ))}
        {showAllLabels &&
          restOfLabels
            .sort((a, b) => a.localeCompare(b))
            .map((label) => (
              <Label
                key={label}
                id={label}
                label={label}
                appearance="success"
              />
            ))}
        {!isLoading && restOfLabels.length > 0 && (
          <Label
            id="showMore"
            onClick={() => setShowAllLabels(!showAllLabels)}
            appearance="secondary"
            label={
              showAllLabels
                ? t('showMoreLabels.less')
                : `${t('showMoreLabels.more')} (${restOfLabels.length})`
            }
          />
        )}
        {isLoading && (
          <>
            <Label.Skeleton />
            <Label.Skeleton />
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default FulfillmentOrderSelectedHeader;
