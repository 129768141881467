import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import { getInvoiceById } from 'domains/Billing/Invoices/invoicesSlice';

function useInvoiceItem(id: string) {
  const invoice = useSelector((state: RootStateType) =>
    getInvoiceById(state, id),
  );

  return invoice;
}

export default useInvoiceItem;
