import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PasswordProtectedRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getPasswordProtectedAction,
  passwordProtectedData,
  passwordProtectedStatus,
  passwordProtectedUpdateStatus,
  updatePasswordProtectedAction,
} from 'domains/Online/Themes/themesSlice';

function usePasswordProtected() {
  const dispatch = useAppDispatch();
  const status = useSelector(passwordProtectedStatus);
  const updateStatus = useSelector(passwordProtectedUpdateStatus);
  const passwordProtected = useSelector(passwordProtectedData);

  const fetchPasswordProtected = useCallback(
    () => dispatch(getPasswordProtectedAction()),
    [dispatch],
  );

  const updatePasswordProtected = (
    passwordProtected: PasswordProtectedRequestDto,
  ) => dispatch(updatePasswordProtectedAction(passwordProtected));

  useEffect(() => {
    fetchPasswordProtected();
  }, [fetchPasswordProtected]);

  return {
    status,
    updateStatus,
    passwordProtected,
    fetchPasswordProtected,
    updatePasswordProtected,
  };
}

export default usePasswordProtected;
