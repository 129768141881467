import { useEffect } from 'react';
import useNavTabs from './useNavTabs';

export default function HideNavTabs(): null {
  const { showNavTabs, hideNavTabs } = useNavTabs();

  useEffect(() => {
    hideNavTabs();
    return () => showNavTabs();
  }, [showNavTabs, hideNavTabs]);

  return null;
}
