import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  checkJobStatusAction,
  setJobAction,
  removeJobAction,
  createGetCloneProductById,
  getJobsAction,
} from '../../jobsSlice';

const TYPE_JOB = 'cloneProduct';

function useCloneProductJobs(id = '') {
  const dispatch = useAppDispatch();

  const getCloneProductById = useMemo(createGetCloneProductById, []);

  const job = useSelector((state: RootStateType) =>
    getCloneProductById(state, id),
  );

  const setJob = useCallback(
    async (id: string, token: string) => {
      dispatch(
        setJobAction({ id, type: TYPE_JOB, token, ttl: 0, status: 'pending' }),
      );
    },
    [dispatch],
  );

  const getJobs = useCallback(async () => {
    dispatch(getJobsAction(TYPE_JOB));
  }, [dispatch]);

  const removeJob = useCallback(async () => {
    dispatch(removeJobAction({ id, type: TYPE_JOB }));
  }, [dispatch, id]);

  const checkJob = useCallback(async () => {
    if (job?.status === 'pending') {
      dispatch(checkJobStatusAction({ id, type: TYPE_JOB }));
    }
  }, [dispatch, id, job?.status]);

  return { job, getJobs, setJob, removeJob, checkJob };
}

export default useCloneProductJobs;
