import { Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { EcosystemIcon } from '@tiendanube/icons';
import { SOCIAL_GOOGLE_ADMIN, SOCIAL_TIKTOK_ADMIN } from 'App/featuresFlags';
import { useHasTags, useHandleMenuClick } from 'App/hooks';
import { trackingMenuPrimarySalesChannelClick } from 'App/tracking';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useGetCountry,
  useIsMobileDevice,
  useIsPlanLojinha,
} from 'domains/Auth/hooks';
import { menuSecondaryMarketplacesAppsClick } from './tracking';

const PATHS = {
  google: '/apps/social-google/',
  productFeeds: '/marketing/product_feeds/',
  otherSalesChannels: '/sales-channels/apps',
  marketplaces: '/sales-channels/marketplaces',
  socialTiktok: '/apps/social-tiktok/',
  facebookNewAdmin: '/social/meta/#/facebook',
  instagramNewAdmin: '/social/meta/#/instagram',
};

const ACTIVE_SALES_CHANNELS_PATHS_REGEX = new RegExp(
  `^(${Object.values(PATHS).join('|')})`,
);

function SalesChannelsMenu() {
  const { validateRoles } = useRoles();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const isPlanLojinha = useIsPlanLojinha();
  const storeCountry = useGetCountry();
  const isMobileDevice = useIsMobileDevice();

  const getIsMenuMetaActive = (path: string) =>
    `${pathname}${window.location.hash}`.startsWith(path);

  const facebookActive = getIsMenuMetaActive(PATHS.facebookNewAdmin);
  const instagramActive = getIsMenuMetaActive(PATHS.instagramNewAdmin);

  const activeSalesChannelsPath =
    ACTIVE_SALES_CHANNELS_PATHS_REGEX.test(pathname) ||
    ACTIVE_SALES_CHANNELS_PATHS_REGEX.test(
      `${pathname}${window.location.hash}`,
    );

  const hasMetaPermission = validateRoles('meta');
  const hasGooglePermission = validateRoles('google_shopping');
  const hasTiktokPermission = validateRoles('tiktok');
  const hasFullPermission = validateRoles('full');
  const [hasSocialGoogleAdmin, hasSocialTiktokAdmin] = useHasTags([
    SOCIAL_GOOGLE_ADMIN,
    SOCIAL_TIKTOK_ADMIN,
  ]);

  const defaultPath = isMobileDevice
    ? PATHS.marketplaces
    : PATHS.facebookNewAdmin;

  const handleMenuSalesChannelClick = () => {
    trackingMenuPrimarySalesChannelClick();
    handleClickAccordion();
  };

  const menuItemsBaseProps = (name: string, salesChannelPath: string) => ({
    children: `${t(`menu.salesChannels.more.${name}`)}`,
    active:
      name === 'facebook'
        ? facebookActive
        : name === 'instagram'
        ? instagramActive
        : pathname === salesChannelPath,
    href: salesChannelPath,
  });

  const getSalesChannel = (name: string, salesChannelPath: string) => ({
    ...menuItemsBaseProps(name, salesChannelPath),
    onClick: goToAdmin(salesChannelPath),
    external: true,
    href: '/admin' + salesChannelPath,
  });

  const getSocialSalesChannel = (name: string, salesChannelPath: string) => ({
    ...menuItemsBaseProps(name, salesChannelPath),
    onClick: handleClickItem,
    external: false,
    href: salesChannelPath,
  });

  const [facebook, instagram] = [
    getSocialSalesChannel('facebook', PATHS.facebookNewAdmin),
    getSocialSalesChannel('instagram', PATHS.instagramNewAdmin),
  ];

  const google = {
    ...getSocialSalesChannel('google', PATHS.google),
  };

  const socialTiktok = getSocialSalesChannel('tiktok', PATHS.socialTiktok);

  const productFeeds = getSalesChannel('productFeeds', PATHS.productFeeds);

  const otherSalesChannels = getSocialSalesChannel(
    'otherSalesChannels',
    PATHS.otherSalesChannels,
  );

  const marketplacesApps = {
    children: `${t(`menu.salesChannels.more.marketplaces`)}`,
    active: pathname === PATHS.marketplaces,
    onClick: () => {
      menuSecondaryMarketplacesAppsClick();
      handleClickItem();
    },
    href: PATHS.marketplaces,
    external: false,
  };

  const getMenuItems = () => {
    const menuItems: {
      onClick: () => void;
      external?: boolean;
      children: string;
      active: boolean;
      href: string;
      label?: string;
    }[] = [];

    if (!isMobileDevice && hasMetaPermission) {
      menuItems.push(facebook);

      // Meta Shops is only available in BR and MX
      if (!isMobileDevice && [Country.BR, Country.MX].includes(storeCountry)) {
        menuItems.push(instagram);
      }
    }

    if (!isMobileDevice && hasGooglePermission) {
      if (hasSocialGoogleAdmin) {
        menuItems.push(google);
      } else {
        menuItems.push(productFeeds);
      }
    }

    if (!isPlanLojinha && hasFullPermission) {
      menuItems.push(marketplacesApps);
    }

    if (
      !isMobileDevice &&
      ([Country.CL, Country.BR, Country.MX, Country.CO].includes(
        storeCountry,
      ) ||
        hasSocialTiktokAdmin) &&
      hasTiktokPermission
    ) {
      menuItems.push(socialTiktok);
    }

    if (hasFullPermission) {
      menuItems.push(otherSalesChannels);
    }

    return menuItems;
  };

  return (
    <MenuButton.Accordion
      contentid="content-sales-channels"
      menuButton={{
        id: 'control-sales-channels',
        label: t('menu.salesChannels.more.title'),
        startIcon: EcosystemIcon,
        'aria-controls': 'content-sales-channels',
        onClick: handleMenuSalesChannelClick,
      }}
      open={activeSalesChannelsPath}
      active={activeSalesChannelsPath}
      as={Link}
      to={defaultPath}
    >
      {getMenuItems().map((item, index) =>
        !item.external ? (
          <MenuButton
            key={index}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          >
            {!!item.label && <Tag appearance="primary">{item.label}</Tag>}
          </MenuButton>
        ) : (
          <MenuButton
            key={index}
            as="a"
            onClick={item.onClick}
            label={item.children}
            href={item.href}
            active={item.active}
          />
        ),
      )}
    </MenuButton.Accordion>
  );
}

export default SalesChannelsMenu;
