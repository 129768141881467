const autoDownloadFileFromUrl = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url);
    const fileBlob = await response.blob();

    // Get file name
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];

    const fileUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');

    link.href = fileUrl;
    link.download = fileName;
    link.click();

    return true;
  } catch (_error) {
    return false;
  }
};

export default autoDownloadFileFromUrl;
