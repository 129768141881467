import { Suspense } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { LayoutBackground, PageTitle, Form } from '@tiendanube/components';
import { Input } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import Logo from 'domains/Auth/components/Logo';
import { BACKGROUND_IMAGE_URL } from 'domains/Auth/constants';
import { useLogin, useIsLogged } from 'domains/Auth/hooks';
import { DASHBOARD_BASE_PATH } from 'domains/Dashboard';
import { validationSchema } from './validationSchema';

interface LoginPageValues {
  email: string;
  password: string;
}

const initialValues = { email: '', password: '' };

function LoginPage(): JSX.Element {
  const { t } = useTranslation(['login']);
  const { isLogin: isLogged } = useIsLogged();
  const {
    handleSubmit,
    isLoading,
    isError,
    isSuccess,
    isInternalServerError,
    alertAppearence,
    alertText,
    hasAuthFactor,
  } = useLogin();
  const { errors, values, handleOnBlur, handleOnChange, handleOnSubmit } =
    useForm<LoginPageValues, LoginPageValues>({
      initialValues,
      validationSchema,
      onSubmit: handleSubmit,
    });

  const buildInputAppearance = (key?: string) => (key ? 'danger' : undefined);

  useToastStatus({
    error: t('loginInternalServerError'),
    status: isError && isInternalServerError ? 'error' : 'idle',
  });

  if (isSuccess && hasAuthFactor) {
    return <Redirect to="/auth/verification" />;
  }

  if (isLogged) {
    return <Redirect to={DASHBOARD_BASE_PATH} />;
  }
  // We created this function to prevent the Form component from sending the data it will receive in the onClickSubmit callback
  //  since it is not compatible with the event received in handleOnSubmit callback
  const save = () => {
    handleOnSubmit();
  };

  return (
    <Suspense fallback="loading">
      <LayoutBackground image={BACKGROUND_IMAGE_URL}>
        <Logo />
        <Box display="flex" flexDirection="column" gap="4">
          <PageTitle title={t('loginHeaderTitle')} />
          <Form
            alertText={alertText}
            alertAppearance={alertAppearence}
            submitLabel={t('loginFormSubmitLabel')}
            onClickSubmit={save}
            submitting={isLoading}
          >
            <Input
              label={t('loginInputEmailLabel')}
              value={values.email}
              name="email"
              type="email"
              autoComplete="email"
              placeholder=""
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              appearance={buildInputAppearance(errors.email)}
              helpText={errors.email ? t(errors.email) : undefined}
              required
            />

            <Input
              label={t('loginInputPassLabel')}
              value={values.password}
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder=""
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              appearance={buildInputAppearance(errors.password)}
              helpText={errors.password ? t(errors.password) : undefined}
              required
            />
          </Form>
        </Box>
      </LayoutBackground>
    </Suspense>
  );
}

export default LoginPage;
