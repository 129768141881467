import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationConfigurations(keyPrefix?: string): TFunction {
  const { t } = useTranslation(Domain.CONFIGURATIONS, { keyPrefix });
  return t;
}

export default useTranslationConfigurations;
