import { FilterStatisticsProvider } from './components';
import { StatsRoutes } from './StatsRoutes';

import './Statistics.scss';

function Statistics() {
  return (
    <div className="stratus--statistics">
      <FilterStatisticsProvider>
        <StatsRoutes />
      </FilterStatisticsProvider>
    </div>
  );
}

export default Statistics;
