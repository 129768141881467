import { BILLING_ROUTES } from './billingRoutes';
import { CHECKOUT_ROUTES } from './Checkout/checkoutRoutes';
import { INVOICES_ROUTES } from './Invoices';

export { default as reducer } from './billingReducers';
export { default as useTranslationBilling } from './useTranslationBilling';
export const billingRoutes = {
  ...BILLING_ROUTES,
  ...CHECKOUT_ROUTES,
  ...INVOICES_ROUTES,
};
