import { Table, Link, Input, Text, Box } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import {
  WIDTH_CELL_CURRENCY_EQUIVALENT,
  WIDTH_CELL_SUGGESTION,
} from 'domains/Configurations/I18n/utils/constants';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function Skeleton() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const tableRow = (
    <Table.Row>
      <Table.Cell>
        <Box paddingTop="1-5">
          <Text.Skeleton width="50px" />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Input.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Box paddingTop="1-5">
          <Link.Skeleton />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
  return (
    <Table>
      <Table.Head>
        <CustomHeaderCell width={WIDTH_CELL_CURRENCY_EQUIVALENT}>
          {t('exchangeRates.table.currency')}
        </CustomHeaderCell>
        <CustomHeaderCell width={WIDTH_CELL_CURRENCY_EQUIVALENT}>
          {t('exchangeRates.table.equivalent')}
        </CustomHeaderCell>
        <CustomHeaderCell width={WIDTH_CELL_SUGGESTION} />
      </Table.Head>
      <Table.Body>
        {tableRow}
        {tableRow}
        {tableRow}
      </Table.Body>
    </Table>
  );
}

export default Skeleton;
