import { FC } from 'react';
import { CheckIcon, IconProps, InfoCircleIcon } from '@nimbus-ds/icons';
import { KeyPrefix } from 'i18next';
import { Domain } from '@tiendanube/common/dist/src/enums';

type TraitType = 'feature' | 'info';

export const TRAIT_TYPE_ICON: Record<TraitType, FC<IconProps>> = {
  feature: CheckIcon,
  info: InfoCircleIcon,
};

type TranslationKey = KeyPrefix<typeof Domain.ONLINE>;
type ThemeTrait = {
  type: TraitType;
  description: TranslationKey;
  descriptionValues?: Record<string, string>;
};

export const THEMES_TRAITS: Record<string, ThemeTrait[]> = {
  patagonia: [
    { type: 'feature', description: 'themesPage.themesTraits.target.starter' },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.style.minimalist',
    },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.benefits.increasesSales',
      descriptionValues: { percentage: '15' },
    },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.benefits.faster',
      descriptionValues: { percentage: '25' },
    },
    {
      type: 'info',
      description: 'themesPage.themesTraits.status.betaContinuous',
    },
    { type: 'info', description: 'themesPage.themesTraits.appSupport.reduced' },
  ],
};
