import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getNewCustomShippingStatus,
  createCustomShippingAction,
  NewCustomShippingParamsInterface,
  cleanCreateCustomShippingStatus,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useNewCustomShipping() {
  const dispatch = useAppDispatch();
  const status = useSelector(getNewCustomShippingStatus);
  const createNewCustomShipping = async (
    values: NewCustomShippingParamsInterface,
  ) => {
    await dispatch(createCustomShippingAction(values));
    dispatch(cleanCreateCustomShippingStatus());
  };
  return {
    status,
    createNewCustomShipping,
  };
}

export default useNewCustomShipping;
