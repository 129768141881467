import { CustomerFormValuesInterface } from './types';

export const initialValues: CustomerFormValuesInterface = {
  id: '',
  name: '',
  email: '',
  phone: '',
  identification: '',
  address: '',
  number: '',
  floor: '',
  locality: '',
  city: '',
  zipcode: '',
  province: '',
  country: '',
  metafields: {
    internals: [],
    fromApi: [],
  },
};
