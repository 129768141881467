import { DataTable, IconButton, Stack, Text } from '@tiendanube/components';

const { Row, Cell } = DataTable;

function ResultRowDesktopSkeleton(): JSX.Element {
  return (
    <Row id="skeleton-row" align="center">
      <Cell basis={400}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell grow={0} basis={170}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell basis={200}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell grow={0} basis={125}>
        <Stack>
          <Stack.Item>
            <IconButton.Skeleton />
          </Stack.Item>
          <Stack.Item>
            <IconButton.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
    </Row>
  );
}

export default ResultRowDesktopSkeleton;
