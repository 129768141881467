import { Text } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import { DataTable, Link } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { useTransactionListItem } from '../../../../hooks';
import { formatDate } from '../../../utils';

interface ResultRowDesktopProps {
  id: string;
}

const { Row, Cell } = DataTable;

function ResultRowDesktop({ id }: ResultRowDesktopProps): JSX.Element | null {
  const { transaction } = useTransactionListItem(id);
  const { push } = useHistory();

  if (!transaction) return null;

  const goToOrderDetails = () => {
    push(`/orders/${transaction.id}`);
  };

  return (
    <Row id={id} align="center">
      <Cell basis={100}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text color="primary-textLow">
            <Link appearance="primary" onClick={goToOrderDetails}>
              {`#${transaction.order}`}
            </Link>
          </Text>
        </div>
      </Cell>
      <Cell grow={0} basis={200}>
        <Text color="neutral-textLow" as="span">
          <CurrencyPrice
            price={transaction.total}
            currency={transaction.totalCurrency}
          />
        </Text>
      </Cell>
      <Cell grow={0} basis={200}>
        <Text color="primary-textLow" as="span">
          <CurrencyPrice
            price={transaction.amount}
            currency={transaction.amountCurrency}
          />
          {!!transaction.rate && ` (${transaction.rate}%)`}
        </Text>
      </Cell>
      <Cell basis={100}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text color="primary-textLow">{formatDate(transaction.date)}</Text>
        </div>
      </Cell>
    </Row>
  );
}

export default ResultRowDesktop;
