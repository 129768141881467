import { Card, Icon, Tag } from '@nimbus-ds/components';
import { ChevronDownIcon } from '@nimbus-ds/icons';
import { MethodNameAndImage, Stack } from 'commons/components';

function Skeleton() {
  return (
    <Card>
      <Card.Header>
        <Stack justify="space-between">
          <MethodNameAndImage.Skeleton />
          <Stack>
            <Tag.Skeleton />
            <Icon
              color="neutral-textHigh"
              source={<ChevronDownIcon />}
              cursor="pointer"
            />
          </Stack>
        </Stack>
      </Card.Header>
    </Card>
  );
}

export default Skeleton;
