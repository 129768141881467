import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  regenerateRecoveryCodes as regenerateRecoveryCodesAction,
  regenerateRecoveryCodesStatus,
  resetRegenerateRecoveryCodes,
} from 'domains/Account/accountSlice';

interface UseRecoveryCodesResult {
  regenerate: () => void;
  status: StatusType;
  reset: () => void;
}

function useRecoveryCodes(): UseRecoveryCodesResult {
  const { status } = useSelector(regenerateRecoveryCodesStatus);
  const dispatch = useAppDispatch();
  const regenerate = useCallback(
    () => dispatch(regenerateRecoveryCodesAction()),
    [dispatch],
  );
  const reset = useCallback(
    () => dispatch(resetRegenerateRecoveryCodes()),
    [dispatch],
  );

  return {
    regenerate,
    status,
    reset,
  };
}

export default useRecoveryCodes;
