import React from 'react';
import { Text, Box } from '@nimbus-ds/components';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { useGetLanguage } from 'domains/Auth/hooks';
import { UseVariantsRangeResult } from 'domains/Catalog/Inventory/pages/hooks';
import { ShowHideVariantsLink } from 'domains/Catalog/Inventory/pages/InventoryListPage/components/ShowHideVariantsLink';
import { joinVariantValues } from 'domains/Catalog/Inventory/utils';
import { hasVariants } from 'domains/Catalog/Products/utils';
import { Headers, Inputs } from './components';

import './TableValuesVariants.scss';

interface TableValuesVariantsProps {
  product: ProductDetailsResponseDto;
  range: UseVariantsRangeResult;
}
function TableValuesVariants({
  product,
  range,
}: Readonly<TableValuesVariantsProps>) {
  const language = useGetLanguage();
  const variants = range.variants;
  const hasVariantsProduct = hasVariants(product);
  const backgroundColor = hasVariantsProduct ? 'neutral-surface' : undefined;

  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor={backgroundColor}
      position="relative"
      left="-12px"
      pl="2"
      width="100vw"
      gap="1"
      data-style="stratus--table-values-variants"
    >
      {variants.map((variant, index) => (
        <React.Fragment key={variant.id}>
          {index === 0 && hasVariantsProduct && <Box mt="2" />}
          {hasVariantsProduct && (
            <Box pl="1">
              <Text key={variant.id} color="neutral-textHigh" lineClamp={3}>
                {joinVariantValues(variant, language)}
              </Text>
            </Box>
          )}
          <Headers />
          <Inputs variant={variant} />
          {hasVariantsProduct && index < variants.length - 1 && (
            <div className="line-separator" />
          )}
        </React.Fragment>
      ))}
      {(range.link.show || range.spinner.show) && (
        <Box mt="2" mb="3">
          <ShowHideVariantsLink {...range} tracking={false} />
        </Box>
      )}
      {!range.link.show && !range.spinner.show && hasVariantsProduct && (
        <Box mb="3" />
      )}
    </Box>
  );
}

export default TableValuesVariants;
