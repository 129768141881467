import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { trackingOrderBulkSelectClick } from 'domains/Orders/tracking';
import {
  cleanBulkOrdersIds,
  fetchBulkOrders,
  getBulkOrdersIds,
  getMissingTrackingNumberOrderCount,
  cleanOrdersMissingTracking,
  getFulfillmentOrders,
} from '../../ordersSlice';

interface UseSelectBulkOrdersResult {
  ordersCount: number;
  isBulkSelected: boolean;
  handleSelect: () => void;
  handleDeselect: () => void;
  missingTrackingNumberCount: number;
  hasFulfillments: boolean;
  cleanOrdersMissingTrackingNumber: () => void;
}

function useSelectBulkOrders(filters, setDisabled): UseSelectBulkOrdersResult {
  const [isBulkSelected, setIsBulkSelected] = useState<boolean>(false);

  const { setSelectedRowsId } = useBulkActions();

  const orderIds = useSelector(getBulkOrdersIds);

  const missingTrackingNumberCount = useSelector(
    getMissingTrackingNumberOrderCount,
  );

  const hasFulfillments = useSelector(getFulfillmentOrders).some(
    ({ fulfillments }) => fulfillments.length,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const hasOrderIds = orderIds.length > 0;
    if (hasOrderIds) setSelectedRowsId(orderIds);
    setIsBulkSelected(hasOrderIds);
    if (hasOrderIds) setDisabled(true);
  }, [orderIds, setSelectedRowsId, setDisabled]);

  const handleSelect = async () => {
    const result = await dispatch(fetchBulkOrders(filters));
    const count = unwrapResult(result).data.orders.length;
    trackingOrderBulkSelectClick(count);
  };

  const handleDeselect = () => {
    setSelectedRowsId([]);
    setDisabled(false);
    dispatch(cleanBulkOrdersIds());
  };

  const cleanOrdersMissingTrackingNumber = () => {
    dispatch(cleanOrdersMissingTracking());
  };

  return {
    ordersCount: orderIds.length,
    isBulkSelected,
    handleSelect,
    handleDeselect,
    missingTrackingNumberCount,
    cleanOrdersMissingTrackingNumber,
    hasFulfillments,
  };
}

export default useSelectBulkOrders;
