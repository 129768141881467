import { ReactNode } from 'react';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { Prompt } from 'react-router';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { StatusType } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionHelpLink } from './PromotionHelpLink';

interface PromotionPageProps {
  mode: 'edit' | 'add';
  children: ReactNode;
  isDirty: boolean;
  isExternal: boolean;
  saveStatus: StatusType;
  loadStatus: StatusType;
  onSave: () => void;
}

export function PromotionPage({
  mode,
  children,
  isDirty,
  isExternal,
  saveStatus,
  loadStatus,
  onSave,
}: Readonly<PromotionPageProps>): JSX.Element {
  const { goBack } = useNavegate();
  const tCommon = useTranslationCommon('exitEdit');
  const t = useTranslationMarketing('promotions.settingsPage');

  const isSaving = saveStatus === 'loading';
  const saveMode = isSaving ? 'saving' : 'save';

  const title = t(`${mode}.title`);
  const saveText = t(`${mode}.${saveMode}`);

  const disadledAction =
    (!isDirty && mode === 'edit') || isSaving || isExternal;

  const headerAction = {
    onClick: onSave,
    children: saveText,
    icon: CheckCircleIcon,
    spinner: isSaving,
    disabled: disadledAction,
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop navigation={{ onClick: goBack }} mainAction={headerAction} />
      }
      headerContent={<HeaderContent title={title} />}
    >
      {children}
      <Prompt
        when={isDirty && saveStatus !== 'success'}
        message={tCommon('info')}
      />
      {loadStatus === 'success' && (
        <CancelAndConfirmButtons
          isLoading={isSaving}
          confirmText={saveText}
          isCancelDisabled={isSaving}
          isConfirmDisabled={disadledAction}
          onCancel={goBack}
          onConfirm={onSave}
        />
      )}
      {loadStatus === 'loading' && <CancelAndConfirmButtons.Skeleton />}
      {loadStatus !== 'error' && <PromotionHelpLink />}
    </IonPageStratus>
  );
}
