import { forwardRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

interface DrawableCanvasProps {
  width: number;
  height: number;
  backgroundImageSrc: string;
  brushRadius: number;
  brushColor: string;
  disabled?: boolean;
  onDraw?: (isEmpty: boolean) => void;
}

const DrawableCanvas = forwardRef(function DrawableCanvas(
  {
    width,
    height,
    backgroundImageSrc,
    brushRadius,
    brushColor,
    disabled = false,
    onDraw,
  }: Readonly<DrawableCanvasProps>,
  ref,
) {
  const transparentColor = 'rgba(0,0,0,0)';

  const handleChange = (canvas: CanvasDraw) => {
    if (onDraw) {
      const contents = JSON.parse(canvas.getSaveData());
      onDraw(contents.lines.length === 0);
    }
  };

  return (
    <CanvasDraw
      ref={ref}
      lazyRadius={0}
      brushRadius={brushRadius}
      brushColor={disabled ? transparentColor : brushColor}
      catenaryColor={transparentColor}
      canvasWidth={width}
      canvasHeight={height}
      imgSrc={backgroundImageSrc}
      disabled={disabled}
      onChange={handleChange}
      hideGrid
    />
  );
});

export default DrawableCanvas;
