import { DataList, Text, Stack } from '@tiendanube/components';
import { ChevronRightIcon } from '@tiendanube/icons';

const { Row, Cell } = DataList;

function MonthRowSkeleton(): JSX.Element {
  return (
    <Row id="skeleton-row">
      <Cell width={70}>
        <Stack column align="flex-start">
          <Text.Skeleton />
          <Text.Skeleton />
        </Stack>
      </Cell>
      <Cell width={30} alignRight>
        <ChevronRightIcon />
      </Cell>
    </Row>
  );
}

export default MonthRowSkeleton;
