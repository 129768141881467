import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  createFiscalData as createFiscalDataAction,
  updateFiscalData as updateFiscalDataAction,
  cleanFiscalData,
  cleanAddresses,
  getFiscalDataStatus,
  getCityId,
  getProvinceId,
} from 'domains/Billing/Invoices/invoicesSlice';
import { FiscalDataformMexico } from './components/MexicanFiscalDataForm';

function useMexicanFiscalData() {
  const dispatch = useAppDispatch();
  const status = useSelector(getFiscalDataStatus);
  const cityId = Number(useSelector(getCityId));
  const provinceId = Number(useSelector(getProvinceId));

  const cleanData = useCallback(() => {
    dispatch(cleanFiscalData());
    dispatch(cleanAddresses());
  }, [dispatch]);

  const createFiscalData = async ({
    name,
    numberId,
    street,
    locality,
    zipcode,
    number,
    floor,
    vatCondition,
  }: FiscalDataformMexico) => {
    await dispatch(
      createFiscalDataAction({
        name,
        numberId: numberId,
        zipcode,
        number,
        address: street,
        cityId,
        floor: floor === '' ? null : floor,
        locality,
        provinceId,
        vatCondition: Number(vatCondition),
      }),
    );
    cleanData();
  };

  const updateFiscalData = async (
    {
      name,
      numberId,
      street,
      locality,
      zipcode,
      number,
      floor,
      vatCondition,
    }: FiscalDataformMexico,
    id?: number,
  ) => {
    await dispatch(
      updateFiscalDataAction({
        name,
        numberId: numberId,
        zipcode,
        number,
        address: street,
        cityId,
        floor: floor === '' ? null : floor,
        locality,
        provinceId,
        id,
        vatCondition: Number(vatCondition),
      }),
    );
    cleanData();
  };

  return {
    cleanData,
    createFiscalData,
    updateFiscalData,
    status,
  };
}

export default useMexicanFiscalData;
