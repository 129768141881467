import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import { Text } from '@tiendanube/components';
import { DragIcon } from 'commons/components';

import './DragPreview.scss';

interface DragPreviewProps<TData> {
  item: DragLayerMonitorProps<TData>['item'];
}

function DragPreview<TData>({ item }: DragPreviewProps<TData>): JSX.Element {
  return (
    <div className="stratus--draggable-tree__drag-preview">
      <div className="stratus--draggable-tree__drag-preview-detail">
        <DragIcon />
      </div>
      <div className="stratus--draggable-tree__drag-preview-detail">
        <Text>{item.text}</Text>
      </div>
    </div>
  );
}

export default DragPreview;
