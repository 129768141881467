import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchPhysicalLocationsAction,
  updatePhysicalLocationAction,
  getPhysicalLocations,
  getPhysicalLocationsStatus,
  updatePhysicalLocationsStatus,
  cleanUpdatePhysicalLocationStatus,
} from '../../deliveryMethodsSlice';

export interface PhysicalLocation {
  id: string;
  name: string;
  extra: string;
}

function useEditPhysicalLocationPage() {
  const physicalLocations = useSelector(getPhysicalLocations);
  const [physicalLocation, setPhysicalLocation] =
    useState<PhysicalLocation | null>(null);
  const getStatus = useSelector(getPhysicalLocationsStatus);
  const updateStatus = useSelector(updatePhysicalLocationsStatus);
  const dispatch = useAppDispatch();

  const fetchPhysicalLocations = useCallback(
    (forceUpdate?: boolean) => {
      if (!physicalLocations || forceUpdate) {
        dispatch(fetchPhysicalLocationsAction());
      }
    },
    [dispatch, physicalLocations],
  );

  const getPhysicalLocationById = useCallback(
    (id: string) => {
      const physicalLocation = physicalLocations?.find((p) => p.id === id);

      if (physicalLocation) {
        setPhysicalLocation(physicalLocation);
        return;
      }
      fetchPhysicalLocations(true);
    },
    [physicalLocations, fetchPhysicalLocations],
  );

  const updatePhysicalLocation = async (arg: PhysicalLocation) => {
    await dispatch(updatePhysicalLocationAction(arg));
    dispatch(cleanUpdatePhysicalLocationStatus());
  };

  return {
    getStatus,
    updatePhysicalLocation,
    getPhysicalLocationById,
    physicalLocation,
    updateStatus,
  };
}

export default useEditPhysicalLocationPage;
