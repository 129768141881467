import {
  MinInstallmentValueDto,
  UpdatePaymentProviderSettingsRequestDto,
} from '@tiendanube/common';
import {
  BaseCard,
  InterfaceNameValue,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface InstallmentsCardProps {
  minInstallmentValues: MinInstallmentValueDto[];
  values: UpdatePaymentProviderSettingsRequestDto;
  errors: Partial<Record<string, string>>;
  handleOnChangeGroup: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function InstallmentsCard({
  minInstallmentValues,
  values,
  errors,
  handleOnChangeGroup,
}: InstallmentsCardProps): JSX.Element {
  const t = useTranslationPayments();

  const handleChangeInstallment = (data: InterfaceNameValue) => {
    handleOnChangeGroup('minInstallmentValues', data);
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t(
          'paymentProvider.settings.installments',
        )}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="tight">
          <Text size="caption" appearance="light">
            {t('paymentProvider.settings.minInstallmentValue')}
          </Text>
          {minInstallmentValues.map(({ currency, defaultMinValue }) => (
            <InputNumber
              key={currency}
              type="float"
              name={currency}
              prependLabel={currency}
              min={`${defaultMinValue}`}
              value={values.minInstallmentValues?.[currency] || ''}
              onChange={handleChangeInstallment}
              appearance={
                errors?.[`installments[${currency}]`]
                  ? 'validation_error'
                  : 'default'
              }
              helpText={t(errors?.[`installments[${currency}]`] || '', {
                value: defaultMinValue,
              })}
            />
          ))}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default InstallmentsCard;
