import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCategoriesMetafields,
  getCategoriesMetafieldsData,
  getCategoriesMetafieldsStatus,
} from '../../categoriesMetafields/categoriesMetafieldsSlice';

export default function useCategoriesMetafields(id?: string) {
  const dispatch = useAppDispatch();

  const status = useSelector(getCategoriesMetafieldsStatus);
  const categoriesMetafields = useSelector(getCategoriesMetafieldsData);

  const fetchCategoriesMetafields = useCallback(async () => {
    if (id) {
      await dispatch(getCategoriesMetafields(id));
    }
  }, [dispatch, id]);

  return {
    fetchCategoriesMetafields,
    categoriesMetafields,
    status,
  };
}
