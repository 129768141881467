import { DataList } from '@tiendanube/components';
import LeftCell from './LeftCell';
import RightCell from './RightCell';
import useInvoiceItem from '../../../useInvoiceItem';

const { Row } = DataList;

interface InvoiceHistoryDataTableRowProps {
  id: string;
}

function InvoiceHistoryDataTableRow({ id }: InvoiceHistoryDataTableRowProps) {
  const invoice = useInvoiceItem(id);

  if (!invoice) return null;

  const {
    id: invoiceId,
    type,
    period,
    itFirst,
    month,
    amount,
    item,
    invoice: originalInvoice,
    total,
    totalCurrency,
  } = invoice;

  return (
    <Row id={`${invoiceId}`} key={invoiceId}>
      <LeftCell
        invoiceItem={item}
        invoiceType={type}
        period={period}
        month={!!itFirst && month}
      />
      <RightCell
        amount={amount}
        total={!!itFirst && total}
        invoice={originalInvoice}
        currency={totalCurrency}
      />
    </Row>
  );
}

export default InvoiceHistoryDataTableRow;
