import { Country } from '@tiendanube/common/src/enums';
import { Link, Modal, Text } from '@tiendanube/components';
import { useModal } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useGetCountry, useIsMobileDevice } from 'domains/Auth/hooks';
import AuthenticationFactorActivationCard from './AuthenticationFactorActivationCard';

const getGoogleAuthenticatorLink = (country: string) =>
  `https://support.google.com/accounts/answer/1066447?hl=${country}`;

interface TOTPActivationCardProps {
  active: boolean;
  onContinue: () => void;
}

function TOTPActivationCard({ active, onContinue }: TOTPActivationCardProps) {
  const [isShow, openModal, closeModal] = useModal();
  const isMobileDevice = useIsMobileDevice();
  const storeCountry = useGetCountry();
  const t = useTranslationAccount(
    'authenticationFactorPage.totpActivationCard',
  );
  const modalTranslationBase = active
    ? 'deactivateAccountModal'
    : 'activateAccountModal';
  const modalSecondaryActionLabel =
    `${modalTranslationBase}.secondaryActionLabel` +
    (active ? '' : isMobileDevice ? '.cancel' : '.createUsers');

  const handleModalSecondaryAction = () => {
    if (active || isMobileDevice) {
      closeModal();
    } else {
      goToAdmin(ACCOUNT_ROUTES.userPreferences)();
    }
  };

  const googleAuthUrl = getGoogleAuthenticatorLink(
    storeCountry === Country.BR ? 'pt' : 'es',
  );

  const inactiveDescription = (
    <>
      <Text>
        {t('inactiveDescription.firstParagraph')}
        <Link href={googleAuthUrl} target="_blank" appearance="primary">
          {t('inactiveDescription.googleAuthenticator')}
        </Link>
        {t('inactiveDescription.connector')}
        <Link href="https://authy.com" target="_blank" appearance="primary">
          {t('inactiveDescription.authy')}
        </Link>
      </Text>
      <Text>{t('inactiveDescription.secondParagraph')}</Text>
    </>
  );

  return (
    <>
      <AuthenticationFactorActivationCard
        active={active}
        title={t('title')}
        activeDescription={t('activeDescription')}
        inactiveDescription={inactiveDescription}
        activeActionText={t('deactivate')}
        inactiveActionText={t('activate')}
        onContinue={openModal}
      />

      <Modal
        title={t(`${modalTranslationBase}.title`)}
        show={isShow}
        onDismiss={closeModal}
        primaryAction={{
          children: t(`${modalTranslationBase}.primaryActionLabel`),
          onClick: onContinue,
          appearance: 'primary',
        }}
        secondaryAction={{
          children: t(modalSecondaryActionLabel),
          onClick: handleModalSecondaryAction,
        }}
      >
        <Text size="highlight">{t(`${modalTranslationBase}.description`)}</Text>
      </Modal>
    </>
  );
}

export default TOTPActivationCard;
