import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface FrequencyDataInterface {
  recurringFrequency: string;
  recurringInterval: number;
}

function getFrequencyLocalePath({
  recurringFrequency,
  recurringInterval,
}: FrequencyDataInterface): string {
  let interval = recurringInterval;

  // If the frequency is by days, we convert it to a monthly interval for certain values
  if (recurringFrequency === 'D') {
    switch (interval) {
      case 1:
        return 'daily';
      case 30:
        interval = 1;
        break;
      case 90:
        interval = 3;
        break;
      case 360:
        interval = 12;
        break;
      default:
        return 'byDays';
    }
  }
  switch (interval) {
    case 1:
      return 'monthly';
    case 2:
      return 'twoMonths';
    case 3:
      return 'threeMonths';
    case 6:
      return 'sixMonths';
    case 12:
      return 'twelveMonths';
    default:
      return 'byMonths';
  }
}

const useFrequencyTranslation = () => {
  const t = useTranslationBilling('checkout.frequencies');

  const getFrequencyDescriptor = (frequencyData: FrequencyDataInterface) =>
    t(`descriptor.${getFrequencyLocalePath(frequencyData)}`, {
      interval: frequencyData.recurringInterval.toString(),
    });

  const getFrequencyInterval = (frequencyData: FrequencyDataInterface) =>
    t(`interval.${getFrequencyLocalePath(frequencyData)}`, {
      interval: frequencyData.recurringInterval.toString(),
    });

  return { getFrequencyDescriptor, getFrequencyInterval };
};

export default useFrequencyTranslation;
