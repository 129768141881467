import { useState } from 'react';
import { LocationResponseDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import { useHasMultipleLocations } from 'domains/Catalog/hooks';
import {
  EditNewVariantStockModal,
  EditStockModal,
} from 'domains/Catalog/Products/pages/components/Stock';
import { VariantListItemInterface } from '../../../CardVariants/CardVariants';
import { VariantsPreviewProps } from '../../VariantsPreview';
import VariantInfo from '../VariantInfo';

type VariantsPreviewMobileProps = Omit<
  VariantsPreviewProps,
  'onChangeToAllVariants'
> & { locations: LocationResponseDto[] };

function VariantsPreviewMobile({
  isDigital,
  variants,
  onClickImage,
  onClickVariant,
  onChange,
  locations,
}: VariantsPreviewMobileProps): JSX.Element {
  const { goTo } = useNavegate();
  const [selectedVariant, setSelectedVariant] =
    useState<VariantListItemInterface>();
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(0);
  const [showEditStockModal, openEditStockModal, closeEditStockModal] =
    useModal();
  const [
    showEditNewVariantStockModal,
    openEditNewVariantStockModal,
    closeEditNewVariantStockModal,
  ] = useModal();
  const hasMultipleLocations = useHasMultipleLocations(locations);

  const handleOpenVariantStock = (variant: VariantListItemInterface) => {
    setSelectedVariant(variant);
    setSelectedVariantIndex(variants.findIndex((v) => v.name === variant.name));
    if (variant.id && hasMultipleLocations)
      goTo(`/inventory/${variant.productId}/${variant.id}`);
    else if (variant.id) openEditStockModal();
    else openEditNewVariantStockModal();
  };

  return (
    <>
      <DataList ruled spacing="tight">
        {variants.map((variant, index) => {
          const id = `variant-${variant.id || index}`;
          return (
            <DataList.Row key={id} id={id}>
              <DataList.Cell width="fill">
                <VariantInfo
                  variant={variant}
                  locations={locations}
                  index={index}
                  isDigital={isDigital || false}
                  onChange={onChange}
                  onClickImage={onClickImage}
                  onClickVariant={onClickVariant}
                  onOpenVariantStock={handleOpenVariantStock}
                />
              </DataList.Cell>
            </DataList.Row>
          );
        })}
      </DataList>

      {!hasMultipleLocations && showEditStockModal && (
        <EditStockModal
          isShow={showEditStockModal}
          productId={selectedVariant?.productId || ''}
          variantId={selectedVariant?.id || ''}
          currentStock={selectedVariant?.stock || null}
          isVariantStock
          variantName={selectedVariant?.name}
          onClose={closeEditStockModal}
          locationId={selectedVariant?.locationId}
        />
      )}
      <EditNewVariantStockModal
        isShow={showEditNewVariantStockModal}
        variantIndex={selectedVariantIndex}
        value={selectedVariant?.stock || null}
        variantName={selectedVariant?.name}
        onChange={onChange}
        onClose={closeEditNewVariantStockModal}
      />
    </>
  );
}

export default VariantsPreviewMobile;
