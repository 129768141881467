import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CustomersMessagesResponseDto } from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  refreshCustomerMessagesList as refreshCustomerMessagesListAction,
  removeCustomerMessage as removeCustomerMessageAction,
  readCustomerMessage as readCustomerMessageAction,
  unreadCustomerMessage as unreadCustomerMessageAction,
  spamCustomerMessage as spamCustomerMessageAction,
} from 'domains/Customers/Customers/customersSlice';
import { getCustomerMessagesById } from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface useCustomerMessagesListItemProps {
  customerMessagesById: CustomersMessagesResponseDto;
  refreshCustomerMessagesList: () => Promise<void>;
  removeCustomerMessage: () => Promise<void>;
  readCustomerMessage: () => Promise<void>;
  unreadCustomerMessage: () => Promise<void>;
  spamCustomerMessage: () => Promise<void>;
}

function useCustomerMessagesListItem(
  id: string,
): useCustomerMessagesListItemProps {
  const dispatch = useAppDispatch();

  const customerMessagesById = useSelector((state: RootStateType) =>
    getCustomerMessagesById(state, id),
  );

  const refreshCustomerMessagesList = useCallback(async () => {
    unwrapResult(await dispatch(refreshCustomerMessagesListAction(id)));
  }, [dispatch, id]);

  const removeCustomerMessage = useCallback(async () => {
    unwrapResult(await dispatch(removeCustomerMessageAction(id)));
  }, [dispatch, id]);

  const readCustomerMessage = useCallback(async () => {
    unwrapResult(await dispatch(readCustomerMessageAction(id)));
  }, [dispatch, id]);

  const unreadCustomerMessage = useCallback(async () => {
    unwrapResult(await dispatch(unreadCustomerMessageAction(id)));
  }, [dispatch, id]);

  const spamCustomerMessage = useCallback(async () => {
    unwrapResult(await dispatch(spamCustomerMessageAction(id)));
  }, [dispatch, id]);

  return {
    customerMessagesById,
    removeCustomerMessage,
    readCustomerMessage,
    unreadCustomerMessage,
    spamCustomerMessage,
    refreshCustomerMessagesList,
  };
}

export default useCustomerMessagesListItem;
