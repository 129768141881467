import { OrderFulfillmentsDto } from '@tiendanube/common';
import { OrderShippingMethod } from '@tiendanube/common/src/domains/orders/orders/OrderBulkResponseDto';
import { Status } from '@tiendanube/common/src/enums';
import { getTodayIsoString, ninetyDaysAgo } from 'commons/utils/date';
import { SHIPPING_METHOD_CODE_OCA } from 'domains/Shipping/ShippingMethods/constants';
import { FulfillmentStatusType } from '../../ordersService';

export const shouldShowMultiCDFullfillmentStatus = (
  statusType: FulfillmentStatusType,
  hasMultiCD: boolean,
) => {
  const multicdStatuses: FulfillmentStatusType[] = [
    Status.PARTIALLY_PACKED,
    Status.PARTIALLY_FULFILLED,
  ];
  return hasMultiCD || !multicdStatuses.includes(statusType);
};

export const fulfillmentOrdersFromSelectedOrderIds = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) =>
  fulfillmentOrders.filter(({ orderId }) => selectedOrderIds.includes(orderId));

export const fulfillmentOrdersIdsFromSelectedOrderIds = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) =>
  fulfillmentOrders.flatMap(({ orderId, fulfillmentIds }) =>
    selectedOrderIds.includes(orderId) ? fulfillmentIds : [],
  );

export const ordersWithoutFulfillmentOrders = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) => {
  const filteredOrders = fulfillmentOrdersFromSelectedOrderIds(
    selectedOrderIds,
    fulfillmentOrders,
  );
  return filteredOrders.filter(
    ({ fulfillmentIds }) => fulfillmentIds.length === 0,
  );
};

export const shippingMethodFromOrdersBySelectedOrderIds = (
  selectedOrdersIds: string[],
  ordersShippingMethods: OrderShippingMethod[],
) =>
  ordersShippingMethods.filter(({ orderId }) =>
    selectedOrdersIds.includes(orderId),
  );

export const validateDateLimit = (values) => {
  const hasFiltersWithDateRestrictionsApplied =
    values.paymentMethods !== '' ||
    values.paymentProvider !== '' ||
    values.appId !== '';

  const dateFromIn90DaysRange =
    values.dateFrom === '' || values.dateFrom < ninetyDaysAgo;
  const dateToIn90DaysRange =
    values.dateTo === '' || values.dateTo < ninetyDaysAgo;

  const newDateFrom =
    dateFromIn90DaysRange && hasFiltersWithDateRestrictionsApplied
      ? ninetyDaysAgo
      : values.dateFrom;
  const newDateTo =
    dateToIn90DaysRange && hasFiltersWithDateRestrictionsApplied
      ? getTodayIsoString()
      : values.dateTo;

  return {
    ...values,
    dateFrom: newDateFrom,
    dateTo: newDateTo,
  };
};

export const checkCustomPaymentProvider = (key, value) =>
  key === 'paymentProvider' && value === 'custom';

export const hasOca = (orderShippingMethod: OrderShippingMethod) =>
  orderShippingMethod.shipping === SHIPPING_METHOD_CODE_OCA;
