import { CheckboxProps } from '@nimbus-ds/components';

const LABEL_SETTINGS = [
  'showProductsInfo',
  'showOrdersNote',
  'showLabelRecipientDocument',
  'showSenderInfo',
] as const;

export type documentSettingsDto = typeof LABEL_SETTINGS[number];

const LABEL_SETTINGS_OPTIONS: CheckboxProps[] = [
  {
    label: 'showProductsInfo',
    name: 'showProductsInfo',
  },
  {
    label: 'showMerchantNote',
    name: 'showMerchantNote',
  },
  {
    label: 'showOrdersNote',
    name: 'showOrdersNote',
  },
  {
    label: 'showLabelRecipientDocument',
    name: 'showLabelRecipientDocument',
  },
  {
    label: 'showSenderInfo',
    name: 'showSenderInfo',
  },
];

export const LABEL_SETTING_RECEIPT_STUP: CheckboxProps = {
  label: 'showReceiptStub',
  name: 'showReceiptStub',
};

export default LABEL_SETTINGS_OPTIONS;
