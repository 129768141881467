import { Alert, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { combineStatus } from 'commons/utils/combineStatus';
import { PaymentStatusAlert } from 'domains/Billing/Checkout/components';
import { useGetLastPayment } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useTransactionFeeToPay } from '../../hooks';

function TransactionFeesPaymentStatusAlert() {
  const t = useTranslationBilling('transactionFeesPage.paymentStatusAlert');
  const {
    paymentStatus,
    status: lastPaymentStatus,
    isFromRecurrentPayment,
  } = useGetLastPayment('transaction-fee');
  const { transactionFeeToPay, status: transactionFeesStatus } =
    useTransactionFeeToPay();

  const { isSuccess } = combineStatus(lastPaymentStatus, transactionFeesStatus);

  if (!isSuccess) return null;

  if (
    transactionFeeToPay &&
    paymentStatus === 'FAILED' &&
    isFromRecurrentPayment
  ) {
    return (
      <Alert
        appearance="danger"
        title={t('failed.title', {
          period: transactionFeeToPay.periodText,
        })}
      >
        <Text>{t('failed.message')}</Text>

        <Link appearance="primary" as="a" href={t('failed.helpLink')}>
          {t('moreInformation')}
          <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
        </Link>
      </Alert>
    );
  }

  return <PaymentStatusAlert concept="transaction-fee" />;
}

export default TransactionFeesPaymentStatusAlert;
