import { useEffect } from 'react';
import { PHISHING_SUSPECT_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useGetIsFreePlan, useGetIsFreemium } from 'domains/Auth/hooks';
import {
  VERIFY_VALIDATION_EMAIL_CACHE_KEY,
  useVerifyValidationEmailMutation,
} from 'domains/Configurations/Notifications/notificationsApi';
import { CnameWarningAlert } from 'domains/Configurations/Notifications/pages/NotificationsListPage';

function EmailValidationAlert() {
  const isFreemium = useGetIsFreemium();
  const { isFreePlan } = useGetIsFreePlan();
  const [isPhishingSuspect] = useHasTags(PHISHING_SUSPECT_TAG);
  const [validateEmail, { data: validateData }] =
    useVerifyValidationEmailMutation({
      fixedCacheKey: VERIFY_VALIDATION_EMAIL_CACHE_KEY,
    });

  const isEmailSettingLimited = (isFreemium && isFreePlan) || isPhishingSuspect;
  const isVerifyRequired = !!validateData?.required;
  const isVerified = !!validateData?.verified;
  const isMailFromVerifiedOrPending =
    !!validateData?.mailFromDomainStatus &&
    ['Success', 'Pending'].includes(validateData?.mailFromDomainStatus);
  const isMailFromFailed =
    !!validateData?.mailFromDomainStatus &&
    validateData?.mailFromDomainStatus === 'Failed';

  const showCnameAlert =
    !isEmailSettingLimited &&
    isVerifyRequired &&
    isVerified &&
    !isMailFromVerifiedOrPending;

  useEffect(() => {
    if (!isEmailSettingLimited && !validateData) {
      validateEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmail]);

  return showCnameAlert ? (
    <CnameWarningAlert isMailFromFailed={isMailFromFailed} />
  ) : null;
}

export default EmailValidationAlert;
