import { ReactNode } from 'react';
import { BaseCard, Stack, Title, Text } from '@tiendanube/components';

interface CardWithTitleProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
}

function CardWithTitle({
  title,
  subtitle,
  children,
}: CardWithTitleProps): JSX.Element {
  return (
    <BaseCard>
      {!!title && (
        <BaseCard.Header>
          <Stack column align="stretch" spacing="tight">
            <Title type="h3">{title}</Title>
            {!!subtitle && <Text> {subtitle} </Text>}
          </Stack>
        </BaseCard.Header>
      )}
      <BaseCard.Body padding="base">{children}</BaseCard.Body>
    </BaseCard>
  );
}

export default CardWithTitle;
