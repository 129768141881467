import { Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { useTranslationAccount } from '../../../hooks';
import { ICON_SIZE } from '../settings';

export function AppSessionsDisclaimer() {
  const t = useTranslationAccount('sessionManagementPage.alerts');

  return (
    <Stack spacing="tight">
      <ExclamationCircleIcon size={ICON_SIZE} color="dimgrey" />
      <Text fontSize="caption" color="neutral-textDisabled">
        {t('appSessionsDisclaimer')}
      </Text>
    </Stack>
  );
}
