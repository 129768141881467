import { Button, Card } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import { usePaymentMethods } from 'domains/Billing/Checkout/hooks';
import {
  trackingBillingStartPlanPayment,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import SummaryError from '../../SummaryError';
import SummarySkeleton from '../../SummarySkeleton';

function SummaryForChurned() {
  const { goTo } = useNavegate();
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.forChurned',
  );
  const { isLoading, isError, paymentMethods, refreshPaymentMethods } =
    usePaymentMethods();
  const logEvent = useCheckoutTracking();
  const handleGoToPay = () => {
    trackingBillingStartPlanPayment(logEvent);
    goTo(CHECKOUT_ROUTES.choosePaymentMethodForPlan);
  };
  const formatCurrency = useFormatCurrency();

  if (isLoading) return <SummarySkeleton />;

  if (isError) return <SummaryError onClick={refreshPaymentMethods} />;

  const recurrencyOption = paymentMethods?.[0].recurrencyOptions[0];

  if (!recurrencyOption?.amountBaseValue) return null;

  return (
    <Card>
      <Card.Header
        title={`${t('title')}: ${formatCurrency(
          recurrencyOption.amountBaseValue,
          recurrencyOption.amountCurrency,
        )}`}
      />
      <Card.Footer>
        <Button appearance="primary" onClick={handleGoToPay}>
          {t('goToPay')}
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default SummaryForChurned;
