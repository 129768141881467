import { useState, useCallback } from 'react';

export type useBooleanResult = [boolean, () => void, () => void];

function useBoolean(defaultValue = false): useBooleanResult {
  const [value, setValue] = useState(defaultValue);

  return [
    value,
    useCallback(() => {
      setValue(true);
    }, []),
    useCallback(() => {
      setValue(false);
    }, []),
  ];
}

export default useBoolean;
