import { useEffect, ReactElement } from 'react';
import { useLocation } from 'react-router';
import { MIGRATION_ENABLE_ALL_MIGRATED_PAGES } from 'App/featuresFlags';
import { ROUTE_MAPPING, TAGS_MIGRATE } from 'App/migrateConfig';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useGetSyncStatus,
  useGetTags,
  useIsMobileDevice,
} from 'domains/Auth/hooks';

interface ToggleMigratedDomainProps {
  domain: keyof typeof ROUTE_MAPPING;
  children: ReactElement;
  force?: boolean;
}

function ToggleMigratedDomain({
  domain,
  children,
  force = false,
}: ToggleMigratedDomainProps) {
  const tags = useGetTags();
  const { pathname } = useLocation();
  const isMobileDevice = useIsMobileDevice();
  const statusSync = useGetSyncStatus();

  useEffect(() => {
    if (statusSync !== 'success') {
      return;
    }

    if (isMobileDevice) return;

    if (force) return;

    if (tags.includes(TAGS_MIGRATE[domain])) return;

    if (tags.includes(MIGRATION_ENABLE_ALL_MIGRATED_PAGES)) return;

    const possibleId = pathname.match(/\d+/g);
    const pathToOldAdmin = ROUTE_MAPPING[domain]?.replace(
      ':id',
      possibleId ? possibleId[0] : '',
    );
    goToAdmin(pathToOldAdmin)();
  }, [domain, force, isMobileDevice, pathname, statusSync, tags]);

  return children;
}

export default ToggleMigratedDomain;
