import { Icon, Text } from '@nimbus-ds/components';
import { PaymentMethodType } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { usePaymentMethodLabel } from './usePaymentMethodLabel';
import { PAYMENT_METHOD_TYPE_ICONS, isLastItem } from '../utils';

interface PaymentMethodLabelProps {
  providerId: string;
  paymentMethods: PaymentMethodType[];
}

function PaymentMethodLabel({
  paymentMethods,
  providerId,
}: PaymentMethodLabelProps): JSX.Element {
  const t = useTranslationPayments();
  const getTranslation = usePaymentMethodLabel();

  const PaymentIcon = PAYMENT_METHOD_TYPE_ICONS[paymentMethods[0]];
  return (
    <Stack gap="1-5">
      <Icon source={<PaymentIcon />} />
      <Text>
        {paymentMethods.map(
          (paymentMethod, index) =>
            `${t(getTranslation(providerId, paymentMethod))}${
              !isLastItem(paymentMethods, index) ? ',' : ''
            } `,
        )}
      </Text>
    </Stack>
  );
}

export default PaymentMethodLabel;
