import {
  Stack,
  DataTable,
  Text,
  IconButton,
  Thumbnail,
} from '@tiendanube/components';
import {
  PRODUCT_COL_GROW,
  INPUT_COL_GROW,
  INPUT_COL_BASIC,
  VARIANTS_COL_BASIC,
  ACTIONS_COL_BASIC,
} from '../../utils';

function ResultItemSkeleton(): JSX.Element {
  const { Row, Cell } = DataTable;

  return (
    <div className="stratus--product-result-desktop-result-row-desktop">
      <Row id="sekelton" align="center">
        <Cell grow={PRODUCT_COL_GROW}>
          <Stack spacing="tight" align="flex-start">
            <Stack.Item>
              <Thumbnail.Skeleton aspectRatio="1-1" width="72px" />
            </Stack.Item>
            <Stack.Item fill>
              <Text.Skeleton width="large" />
            </Stack.Item>
          </Stack>
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Text.Skeleton size="featured" width="fill" />
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Text.Skeleton size="featured" width="fill" />
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Text.Skeleton size="featured" width="fill" />
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={VARIANTS_COL_BASIC} />
        <Cell grow={INPUT_COL_GROW} basis={ACTIONS_COL_BASIC}>
          <Stack spacing="tight">
            <IconButton.Skeleton />
            <IconButton.Skeleton />
            <IconButton.Skeleton />
          </Stack>
        </Cell>
      </Row>
    </div>
  );
}

export default ResultItemSkeleton;
