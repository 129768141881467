import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getMenuAction } from '../menuSlice';
import { getMenuSelector } from '../menuSlice/menuSelectors';

function useMenu() {
  const dispatch = useAppDispatch();
  const menu = useSelector(getMenuSelector);

  const fetchMenu = useCallback(() => {
    dispatch(getMenuAction());
  }, [dispatch]);

  return {
    menu,
    fetchMenu,
  };
}

export default useMenu;
