import { Input, Stack } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  return (
    <CardWithTitle
      title={t('oca.config.account.title')}
      subtitle={t('oca.config.account.subtitle')}
    >
      <Stack column align="stretch">
        <Input.Skeleton />
        <Input.Skeleton />
      </Stack>
    </CardWithTitle>
  );
}

export default Skeleton;
