import { BaseCard, Input, Stack } from '@tiendanube/components';

function Skeleton(): JSX.Element {
  return (
    <BaseCard>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Skeleton;
