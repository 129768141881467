import {
  CustomPropertiesResponseDto,
  CustomPropertyResponseDtoV2,
} from '@tiendanube/common';
import {
  InterfaceInteractiveListOptions,
  InterfaceSelectOption,
} from '@tiendanube/components';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { getLocaleStandardName } from '../constants';
import { areTranslatedStringsEqual, isAttributeValueSelected } from '../utils';

export const getVariantSelectOptions = (
  standardVariants: CustomPropertiesResponseDto | null,
  languageToUse: string,
  locale: string,
): InterfaceSelectOption[] => {
  const standardVariantsArray = standardVariants
    ? Object.keys(standardVariants).map((key) => ({
        label: standardVariants[key].name?.[languageToUse] || key,
        value: key,
      }))
    : [];

  const customProperties = standardVariantsArray.filter(
    (property) => !['color', 'size'].includes(property.value),
  );

  return [
    {
      label: getLocaleStandardName().color[languageToUse],
      value: 'color',
    },
    {
      label: getLocaleStandardName(locale).size[languageToUse],
      value: 'size',
    },
    ...customProperties,
    {
      label: getLocaleStandardName().new_attribute[languageToUse],
      value: 'new_attribute',
    },
  ];
};

const filterSelectedValues = (
  selectedValues: LanguageInterface[],
  languageToUse: LanguagesType,
  filterFrom?: Record<string, LanguageInterface>,
) => {
  if (!filterFrom || !Object.keys(filterFrom).length) return [];

  let selectedIds: string[] = [];
  selectedValues.forEach((selected) =>
    Object.keys(filterFrom).forEach((valueKey) => {
      if (
        areTranslatedStringsEqual(languageToUse, filterFrom[valueKey], selected)
      ) {
        selectedIds = [...selectedIds, valueKey];
      }
    }),
  );
  return selectedIds;
};

export const filterStandardValues = (
  selectedValues: LanguageInterface[],
  languageToUse: LanguagesType,
  standardValues: Record<string, LanguageInterface>,
) => filterSelectedValues(selectedValues, languageToUse, standardValues);

export const filterSuggestedValues = (
  selectedValues: LanguageInterface[],
  languageToUse: LanguagesType,
  suggestedValues?: Record<string, LanguageInterface>,
) => filterSelectedValues(selectedValues, languageToUse, suggestedValues);

export const getSuggestedListOptionsByAttribute = (
  suggestedValues: CustomPropertyResponseDtoV2['suggested'] | null,
  selectedValues: LanguageInterface[],
  languageToUse: LanguagesType,
): InterfaceInteractiveListOptions[] =>
  !!suggestedValues && Object.keys(suggestedValues).length > 0
    ? Object.keys(suggestedValues).map((sizeId) => {
        const option = suggestedValues[sizeId];
        return {
          title: option[languageToUse],
          name: sizeId,
          active: isAttributeValueSelected(
            selectedValues,
            languageToUse,
            option,
          ),
        };
      })
    : [];
