import { Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { useNavegate } from 'App/hooks';
import { removeNonNumericCharacters } from './utils';
import { AUTH_ROUTES } from '../../authRoutes';
import AuthenticationFactorCodePage from '../AuthenticationFactorCodePage';

function VerificationCodePage(): JSX.Element {
  const { t } = useTranslation(['login']);
  const { goTo } = useNavegate();

  const helpLink = (
    <Link
      onClick={() => goTo(AUTH_ROUTES.recoveryCode)}
      appearance="primary"
      fontSize="caption"
      textDecoration="none"
    >
      {t('authenticationFactor.totp.recoveryCodesText')}
    </Link>
  );

  return (
    <AuthenticationFactorCodePage
      authenticationFactorType={AuthenticationFactorTypes.TOTP}
      helpLink={helpLink}
      codeLength={6}
      translationKeyPrefix="totp"
      modifyCode={removeNonNumericCharacters}
    />
  );
}

export default VerificationCodePage;
