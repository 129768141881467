import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreName } from 'domains/Auth/hooks';

function useDocumentTitle(title: string | undefined) {
  const { t } = useTranslation('common');
  const admin = t('admin');
  const storeName = useStoreName();

  useEffect(() => {
    const defaultTabName = [storeName, admin].join(' ~ ');
    document.title = title
      ? [defaultTabName, title].join(' ~ ')
      : defaultTabName;
    return () => {
      document.title = defaultTabName;
    };
  }, [admin, storeName, title]);
}

export default useDocumentTitle;
