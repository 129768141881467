import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FirstSaleRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getFirstSaleData,
  markFirstSaleRealStatus as markFirstSaleRealStatusSelector,
  getFirstSaleAction,
  markFirstSaleRealAction,
  clearMarkFirstSaleRealAction,
} from 'domains/Dashboard/slices/firstSaleSlice';

function useAlertFirstSale() {
  const dispatch = useAppDispatch();
  const firstSale = useSelector(getFirstSaleData);
  const markFirstSaleRealStatus = useSelector(markFirstSaleRealStatusSelector);

  const getFirstSale = useCallback(() => {
    dispatch(getFirstSaleAction());
  }, [dispatch]);

  const markFirstSale = useCallback(
    async (payload: FirstSaleRequestDto) => {
      await dispatch(markFirstSaleRealAction(payload));
      dispatch(clearMarkFirstSaleRealAction());
    },
    [dispatch],
  );

  return {
    getFirstSale,
    markFirstSale,
    firstSale,
    markFirstSaleRealStatus,
  };
}

export default useAlertFirstSale;
