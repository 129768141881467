import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getDeliveryMethodsState } from './deliveryMethodsSelectors';

export const getOcaAgencies = (state: RootStateType) =>
  getDeliveryMethodsState(state).ocaAgencies;

export const getOcaAgenciesData = (state: RootStateType) =>
  getOcaAgencies(state).data;

export const getOcaAgenciesZipcode = (state: RootStateType) =>
  getOcaAgencies(state).zipcode;

export const getOcaConfiguration = (state: RootStateType) =>
  getDeliveryMethodsState(state).ocaConfiguration;

export const getOcaConfigurationData = (state: RootStateType) =>
  getOcaConfiguration(state).detail.data;

export const getOcaConfigurationLoadStatus = (state: RootStateType) => {
  const status = getOcaConfiguration(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const getOcaConfigurationSaveStatus = (state: RootStateType) =>
  getOcaConfiguration(state).create.status;

export const getOcaConfigurationUpdateStatus = (state: RootStateType) =>
  getOcaConfiguration(state).update.status;
