import { useCallback } from 'react';
import { SortByType } from '@tiendanube/common';
import { CouponFiltersType } from '../../couponsService';
import { trackingCouponsOrdered } from '../../tracking';

function useCouponListPage(
  updateFilters: (filters: Partial<CouponFiltersType>) => void,
) {
  const handleSubmit = useCallback((_q: string) => {
    // We do nothing because we are already searching on every keypress
  }, []);

  const handleChange = useCallback(
    (q: string) => {
      updateFilters({ q, page: 1 });
    },
    [updateFilters],
  );

  const handleSort = useCallback(
    ({ value }: { value: SortByType }) => {
      trackingCouponsOrdered();
      updateFilters({ sortBy: value });
    },
    [updateFilters],
  );

  const handleDismiss = useCallback(
    (filterKey: string) => {
      updateFilters({ [filterKey]: '' });
    },
    [updateFilters],
  );

  return { handleSubmit, handleChange, handleSort, handleDismiss };
}

export default useCouponListPage;
