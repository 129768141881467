import { useEffect } from 'react';
import { Alert, Button, Text } from '@nimbus-ds/components';
import { Link } from 'react-router-dom';
import {
  useRedirectionsDashboard,
  useTranslationDashboard,
} from 'domains/Dashboard/hooks';
import useFacebookConnectionStatusAlert from './useFacebookConnectionStatusAlert';

function FacebookConnectionStatusAlert() {
  const t = useTranslationDashboard('socialMessages.facebookConnection');
  const {
    alerts: { facebook },
  } = useRedirectionsDashboard();
  const {
    showAlert,
    getFacebookConnectionStatus,
    cleanFacebookConnectionData,
  } = useFacebookConnectionStatusAlert();

  useEffect(getFacebookConnectionStatus, [getFacebookConnectionStatus]);

  return (
    <Alert
      appearance="warning"
      show={showAlert}
      title={t('title')}
      onRemove={cleanFacebookConnectionData}
    >
      <Text>{t('description')}.</Text>
      {facebook.to ? (
        <Button
          as={Link}
          to={facebook.to}
          target={facebook.target}
          appearance="neutral"
        >
          {t('cta')}
        </Button>
      ) : (
        <Button
          as="a"
          href={facebook.href}
          target={facebook.target}
          appearance="neutral"
        >
          {t('cta')}
        </Button>
      )}
    </Alert>
  );
}

export default FacebookConnectionStatusAlert;
