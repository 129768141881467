import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { InterfaceCouponsSchema } from '../couponsSlice';

const getCouponsState = (
  state: RootStateType,
): CombinedState<InterfaceCouponsSchema> => state.marketing.coupons;

const getEntityById = (_: RootStateType, id: string) => id;

export const getCouponsStatus = createSelector(
  getCouponsState,
  (couponState) => ({
    isLoading: couponState.status === 'loading',
    isError: couponState.status === 'error',
    isSuccess: couponState.status === 'success',
  }),
);

export const getCouponsRefreshStatus = createSelector(
  getCouponsState,
  (couponState) => ({
    isRefreshError: couponState.refreshStatus === 'error',
    isRefreshSuccess: couponState.refreshStatus === 'success',
    isRefreshing: couponState.refreshStatus === 'refreshing',
  }),
);

export const getCouponsPagination = createSelector(
  getCouponsState,
  (couponState) => couponState.pagination,
);

export const getTotalCoupons = createSimpleSelector(
  [getCouponsState],
  (couponState) => couponState.total,
);

export const getCouponsFilters = createSelector(
  getCouponsState,
  (customers) => customers.filters,
);

export const getCouponsIds = createSelector(
  getCouponsState,
  (couponState) => couponState.ids,
);

export const getCouponsEntities = createSelector(
  getCouponsState,
  (couponState) => couponState.entities,
);

export const getCouponById = createSelector(
  getCouponsEntities,
  getEntityById,
  (couponsEntities, id) => couponsEntities[id],
);

export const getCouponPagination = createSelector(
  getCouponsState,
  (couponState) => couponState.pagination,
);
