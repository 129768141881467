export enum i18nConfig {
  STORAGE = 'locale',
}

export enum Locale {
  ARGENTINA = 'es-AR',
  BRAZIL = 'pt-BR',
  MEXICO = 'es-MX',
  COLOMBIA = 'es-CO',
  CHILE = 'es-CL',
}
