import { Box, Button, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { RedoIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import {
  useGetApp,
  useTranslationPartnerApps,
} from 'domains/PartnersApps/hooks';
import ErrorImage from './error.png';
import AppCenter from '../AppCenter';

function AppErrorState({
  onRetry,
  appId,
}: {
  onRetry: () => void;
  appId: string;
}): JSX.Element {
  const { t } = useTranslationPartnerApps();
  const { app } = useGetApp(appId);
  return (
    <AppCenter>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ xs: 'column', md: 'column', lg: 'row', xl: 'row' }}
        paddingX={{ xs: '2', md: '2', lg: '8', xl: '8' }}
        marginX="auto"
        width="100%"
        gap="4"
      >
        <Box
          display="flex"
          justifyContent={{ xs: 'center', md: 'center', lg: 'flex-end' }}
          width={{ xs: '100%', md: '100%', lg: '50%' }}
        >
          <Box
            as="img"
            alt="This shows the possibility of illustration on an empty message"
            src={ErrorImage}
            height="368px"
            width="auto"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          width={{ xs: '100%', md: '100%', lg: '50%' }}
        >
          <Title
            as="h4"
            textAlign={{ xs: 'center', md: 'center', lg: 'left', xl: 'left' }}
          >
            {t('error.title', {
              appName: app.name,
            })}
          </Title>
          <Text
            textAlign={{ xs: 'center', md: 'center', lg: 'left', xl: 'left' }}
            lineClamp={3}
          >
            {t('error.message')}
          </Text>
          <Box
            mt="4"
            display="flex"
            gap="4"
            alignItems="center"
            flexDirection={{
              xs: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row',
            }}
            justifyContent={{
              xs: 'center',
              md: 'center',
              lg: 'flex-start',
              xl: 'flex-start',
            }}
          >
            <Button
              appearance="primary"
              data-testid="button-retry"
              onClick={onRetry}
            >
              {t('error.retry')}
              <Icon color="currentColor" source={<RedoIcon />} />
            </Button>
            {!!app.supportUrl && (
              <Link
                as="a"
                appearance="primary"
                target="_blank"
                href={app.supportUrl}
              >
                {t('error.suport')}
                <Icon color="currentColor" source={<ExternalLinkIcon />} />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </AppCenter>
  );
}

export default AppErrorState;
