import { OriginAddressCard, SenderCard } from './components';

function Skeleton(): JSX.Element {
  return (
    <>
      <SenderCard.Skeleton />
      <OriginAddressCard.Skeleton />
    </>
  );
}

export default Skeleton;
