import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Text } from '@tiendanube/components';

function LocationSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  return (
    <Card title={t('products.detail.location')}>
      <Text.Skeleton width="fill" />
      <br />
      <Text.Skeleton width="fill" />
      <br />
      <br />
      <Text.Skeleton width="fill" />
    </Card>
  );
}

export default LocationSkeleton;
