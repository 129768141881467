import { Table } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationAccount } from '../../../../../hooks';

export function UserSessionsListDesktopHeader() {
  const t = useTranslationAccount('sessionManagementPage.table.headers');

  return (
    <Table.Head>
      <Table.Row backgroundColor="neutral-surface">
        <Table.Cell as="th" width="40%">
          {t('location')}
        </Table.Cell>
        <Table.Cell as="th" width="35%">
          {t('user')}
        </Table.Cell>
        <Table.Cell as="th" width="25%">
          {t('device')}
        </Table.Cell>
        <Table.Cell as="th" width={{ md: '200px', xl: '230px' }}>
          {t('lastActivity')}
        </Table.Cell>
        <Table.Cell as="th" width={{ md: '50px', xl: '65px' }}>
          <Stack justify="center">{t('closeAction')}</Stack>
        </Table.Cell>
      </Table.Row>
    </Table.Head>
  );
}
