import { Link } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import { SortableDataList } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  SelectProductList,
  SelectedProductInterface,
} from 'domains/Catalog/Products/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { getSectionProductsMaped, getSelectedProductsMaped } from './utils';
import { ProductItemType } from '../../../OrganizeProductsPage/types';
import {
  LIMIT_PRODUCTS_SELECTED,
  SORT_BY_CREATED_AT_DESCENDING,
} from '../../constants';
import OrganizeSectionLayout from '../OrganizeSectionLayout';
import ProductItem from '../ProductItem/ProductItem';

interface OrganizeSectionProps {
  products: ProductItemType[];
  onChange: (newTree?: (ProductItemType | undefined)[]) => void;
  onRemove: (id: string) => void;
}

function OrganizeSection({
  products,
  onChange,
  onRemove,
}: OrganizeSectionProps) {
  const t = useTranslationCatalog();
  const [isShow, onOpen, onClose] = useModal();

  const handleOnChange = (selecteds: SelectedProductInterface[]) => {
    onChange(getSectionProductsMaped(selecteds));
  };

  const canAddMoreProducts = products.length < LIMIT_PRODUCTS_SELECTED;

  const link = canAddMoreProducts ? (
    <Link
      appearance="primary"
      icon={PlusCircleIcon}
      iconPosition="start"
      onClick={onOpen}
    >
      {t('products.featuredProductsPage.addProducts')}
    </Link>
  ) : undefined;

  return (
    <OrganizeSectionLayout link={link}>
      <SortableDataList<ProductItemType>
        customIdKey="productId"
        items={products}
        onChange={onChange}
        renderItem={(product) => (
          <ProductItem product={product} onRemove={onRemove} />
        )}
      />
      {isShow && (
        <SelectProductList
          onClose={onClose}
          isShow={isShow}
          title={t('products.featuredProductsPage.addProducts')}
          selectedProducts={getSelectedProductsMaped(products)}
          onChange={handleOnChange}
          limit={LIMIT_PRODUCTS_SELECTED}
          sortBy={SORT_BY_CREATED_AT_DESCENDING}
        />
      )}
    </OrganizeSectionLayout>
  );
}

export default OrganizeSection;
