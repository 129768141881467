import { Text, Title } from '@nimbus-ds/components';
import { InterfaceNameValue } from '@tiendanube/components';
import { CardCollapsible, Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  Client,
  Quantity,
  QuantityValuesInterface,
  DatesValuesInterface,
  QuantityErrorsInterface,
  DatesErrorsInterface,
  Dates,
  ValueCart,
  Active,
  LimitsSkeleton,
  ClientErrorsInterface,
  ClientValuesInterface,
} from './components';
import { DiscountCombination } from './components/DiscountCombination';
import { CouponValuesInterface } from '../../types';

export interface LimitsValuesInterface
  extends QuantityValuesInterface,
    DatesValuesInterface,
    ClientValuesInterface {
  minPrice: string;
  combinesWithOtherDiscounts: boolean;
}

export interface LimitsErrorsInterface
  extends QuantityErrorsInterface,
    DatesErrorsInterface,
    ClientErrorsInterface {}

interface LimitsProps {
  values: CouponValuesInterface;
  errors: Partial<LimitsErrorsInterface>;
  onChange: ({
    value,
    name,
  }: InterfaceNameValue | InterfaceNameBooleanValue) => void;
  collapsed: boolean;
  showActiveInput: boolean;
  currentUses: number;
  disabled?: boolean;
}

export function Limits({
  values,
  errors,
  onChange,
  collapsed,
  showActiveInput,
  currentUses,
  disabled = false,
}: Readonly<LimitsProps>) {
  const t = useTranslationMarketing();

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h4">{t('coupons.couponForm.limits.title')}</Title>
          <Text>{t('coupons.couponForm.limits.optional')}</Text>
        </Stack>
      }
      active={!collapsed}
    >
      <Stack column align="stretch">
        <Text>{t('coupons.couponForm.limits.info')}</Text>
        <DiscountCombination
          values={values}
          onChange={onChange}
          disabled={disabled}
        />
        {showActiveInput && (
          <Active
            value={values.active}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        <Quantity
          values={values}
          errors={errors}
          onChange={onChange}
          currentUses={currentUses}
          disabled={disabled}
        />
        <Client
          values={values}
          errors={errors}
          onChange={onChange}
          disabled={disabled}
        />
        <Dates
          values={values}
          errors={errors}
          onChange={onChange}
          disabled={disabled}
        />
        <ValueCart
          value={values.minPrice}
          onChange={onChange}
          disabled={disabled}
        />
      </Stack>
    </CardCollapsible>
  );
}

Limits.Skeleton = LimitsSkeleton;
