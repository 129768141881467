import { useEffect, useState } from 'react';
import { Box, Card, Tag, Text, Title, Toggle } from '@nimbus-ds/components';
import { ShippingFallbacksResponseDto } from '@tiendanube/common';
import { ErrorScreen } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { FallbackEdit, FallbackInfo } from './components';
import useFallback from './useFallbackCard';

const INITIAL_STATE = {
  id: '',
  cost: '',
  days: '',
  available: false,
};

function FallbackCard() {
  const t = useTranslationShipping();
  const {
    fetchFallback,
    fallback,
    getStatus,
    updateStatus,
    updateFallback,
    updateAvailableFallback,
  } = useFallback();
  const [values, setValues] = useState<ShippingFallbacksResponseDto>(
    fallback ?? INITIAL_STATE,
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isAvailable = values?.available;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setValues({
      ...values,
      available: checked,
    });
    updateAvailableFallback({ available: checked });
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCancel = () => {
    setValues({
      ...values,
      cost: fallback?.cost ?? values.cost,
      days: fallback?.days ?? values.days,
    });
    setIsEdit(!isEdit);
  };

  const handleUpdateFallback = async () => {
    await updateFallback(values);
    setIsEdit(!isEdit);
  };

  useToastStatus({
    status: updateStatus,
    error: t('deliveryMethods.fallback.error'),
    success: t('deliveryMethods.fallback.success'),
    onError: close,
    onSuccess: close,
  });

  useEffect(() => {
    fetchFallback();
  }, [fetchFallback]);

  useEffect(() => {
    if (getStatus === 'success' && fallback) {
      setValues(fallback);
    }
  }, [getStatus, fallback]);

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h4">{t('deliveryMethods.fallback.title')}</Title>
          {getStatus === 'success' && (
            <Toggle
              name="fallbackToggle"
              checked={values?.available}
              onChange={handleToggle}
            />
          )}
          {getStatus === 'loading' && <Tag.Skeleton />}
        </Box>
      </Card.Header>
      {!isAvailable && !(getStatus === 'error' || updateStatus === 'error') && (
        <Card.Body>
          <Text fontSize="base" lineHeight="base" textAlign="left">
            {t('deliveryMethods.fallback.subTitle')}
          </Text>
        </Card.Body>
      )}
      {(getStatus === 'error' || updateStatus === 'error') && (
        <ErrorScreen
          description={t('deliveryMethods.fallback.titleError')}
          onRetry={fetchFallback}
        />
      )}
      {isAvailable && !isEdit && (
        <FallbackInfo
          cost={values?.cost}
          days={values?.days}
          handleEdit={handleEdit}
        />
      )}
      {isAvailable && isEdit && (
        <FallbackEdit
          cost={values?.cost}
          days={values?.days}
          onChange={handleChange}
          handleCancel={handleCancel}
          handleUpdateFallback={handleUpdateFallback}
          status={updateStatus}
        />
      )}
    </Card>
  );
}

export default FallbackCard;
