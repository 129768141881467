import { useState } from 'react';
import { Card, Link, Text, Title } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { NotificationVariablesResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useClipboard } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { VariableItem } from './VariableItem';

interface VariablesInfoCardProps {
  emailVariables: NotificationVariablesResponseDto;
}

export function VariablesInfoCard({ emailVariables }: VariablesInfoCardProps) {
  const t = useTranslationConfigurations(
    'notifications.editNotificationPage.infoCard',
  );
  const copyToClipboard = useClipboard();
  const [showMore, setShowMore] = useState(false);

  const handleClickVariable = (variable: string) =>
    copyToClipboard(variable, t('variables.copySuccess'));

  const handleToggleShowMore = () => setShowMore((prev) => !prev);

  return (
    <Card>
      <Card.Header>
        <Title as="h4" fontWeight="medium">
          {t('title')}
        </Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Text>{t('description')}</Text>
          <Stack column spacing="tight" align="flex-start">
            {emailVariables.variables.map(({ title, code, description }) => (
              <VariableItem
                key={`variable-${code}`}
                title={title}
                code={code}
                description={description}
                onClickVariable={handleClickVariable}
              />
            ))}
            {showMore &&
              emailVariables.moreVariables?.map(
                ({ title, code, description }) => (
                  <VariableItem
                    key={`variable-${code}`}
                    title={title}
                    code={code}
                    description={description}
                    onClickVariable={handleClickVariable}
                  />
                ),
              )}
            {emailVariables.moreVariables && (
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={handleToggleShowMore}
              >
                {!showMore ? (
                  <>
                    {t('variables.showMore')} <ChevronDownIcon />
                  </>
                ) : (
                  <>
                    {t('variables.showLess')} <ChevronUpIcon />
                  </>
                )}
              </Link>
            )}
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}
