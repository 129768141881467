import { ChevronDownIcon } from '@tiendanube/icons';
import Center from '../Center';

function Pull(): JSX.Element {
  return (
    <Center>
      <ChevronDownIcon />
    </Center>
  );
}

export default Pull;
