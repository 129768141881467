import i18next from 'i18next';
import { i18nConfig, Locale } from './enums';
import { setStorage } from '../storage';

export const buildLocaleFromLanguageCountry = (
  language: string,
  country: string,
): Locale => `${language}-${country}` as Locale;

const saveLanguage = async (
  defaultLanguage: string,
  country: string,
): Promise<void> => {
  const locale = buildLocaleFromLanguageCountry(defaultLanguage, country);

  await setStorage<Locale>(i18nConfig.STORAGE, locale);

  if (i18next.language !== locale) {
    i18next.changeLanguage(locale);
  }
};

export default saveLanguage;
