import { logEvent } from 'commons/utils/tracking';
import {
  CATALOG_PRODUCT_BULK_METAFIELDS,
  CATALOG_PRODUCT_BULK_METAFIELDS_CANCEL,
  CATALOG_PRODUCT_BULK_METAFIELDS_SAVE,
  CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS,
  CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_CANCEL,
  CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_SAVE,
} from 'config/trackingEvents';

export const trackingProductBulkMetafields = (productsId: string[]) => {
  logEvent(CATALOG_PRODUCT_BULK_METAFIELDS, {
    productsId: productsId.join(', '),
  });
};

export const trackingProductBulkMetafieldsCancel = (productsId: string[]) => {
  logEvent(CATALOG_PRODUCT_BULK_METAFIELDS_CANCEL, {
    productsId: productsId.join(', '),
  });
};

export const trackingProductBulkMetafieldsSave = (
  productsId: string[],
  productsCount: number,
  metafieldsId: string[],
) => {
  logEvent(CATALOG_PRODUCT_BULK_METAFIELDS_SAVE, {
    productsId: productsId.join(', '),
    productsCount: productsCount.toString(),
    metafieldsId: metafieldsId.join(', '),
  });
};

export const trackingProductDomainBulkMetafields = (productsId: string[]) => {
  logEvent(CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS, {
    productsId: productsId.join(', '),
  });
};

export const trackingProductDomainBulkMetafieldsCancel = (
  productsId: string[],
) => {
  logEvent(CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_CANCEL, {
    productsId: productsId.join(', '),
  });
};

export const trackingProductDomainBulkMetafieldsSave = (
  productsId: string[],
  productsCount: number,
  metafieldsId: string[],
) => {
  logEvent(CATALOG_PRODUCT_DOMAIN_BULK_METAFIELDS_SAVE, {
    productsId: productsId.join(', '),
    productsCount: productsCount.toString(),
    metafieldsId: metafieldsId.join(', '),
  });
};
