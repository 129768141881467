import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import {
  fetchBillingEngineInvoiceInfo,
  getBillingEngineInvoiceInfoExists,
  getBillingEngineInvoiceInfoStatus,
} from '../invoicesSlice';

function useGetBillingEngineInvoiceInfo() {
  const dispatch = useAppDispatch();
  const status = useSelector(getBillingEngineInvoiceInfoStatus);
  const hasInvoiceInfo = useSelector(getBillingEngineInvoiceInfoExists);

  const refreshInvoiceInfo = useCallback(() => {
    dispatch(fetchBillingEngineInvoiceInfo());
  }, [dispatch]);

  const fetchInvoiceInfo = useCallback(() => {
    status === 'idle' && refreshInvoiceInfo();
  }, [refreshInvoiceInfo, status]);

  return {
    ...convertStatusTypeToObject(status),
    status,
    fetchInvoiceInfo,
    hasInvoiceInfo,
  };
}

export default useGetBillingEngineInvoiceInfo;
