import { DataList } from '@tiendanube/components';
import MetafieldActionItem from 'domains/Metafields/components/MetafieldInput/components/MetafieldActionItem';

import './MetafieldsOrdersDataList.scss';

interface MetafieldsOrdersDataListProps {
  list: string[];
  orderId: string;
}

export default function MetafieldsOrdersDataList({
  list,
  orderId,
}: MetafieldsOrdersDataListProps) {
  return (
    <div className="stratus--card-orders-metafields">
      <DataList spacing="base" ruled={false}>
        {list?.map((metafieldId) => (
          <DataList.Row id={metafieldId} key={metafieldId}>
            <DataList.Cell width={100}>
              <MetafieldActionItem
                key={metafieldId}
                orderId={orderId}
                metafieldId={metafieldId}
              />
            </DataList.Cell>
          </DataList.Row>
        ))}
      </DataList>
    </div>
  );
}
