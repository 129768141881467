import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import { getSummaryData, getSummaryStatus } from 'domains/Statistics/selectors';
import { getSummaryAction } from 'domains/Statistics/statisticsSlice';

function useGetSummaryFreemium() {
  const dispatch = useAppDispatch();
  const summaryFreemium = useSelector(getSummaryData);
  const { isError, isLoading, isSuccess } = useSelector(getSummaryStatus);
  const { statisticsFilters } = useStatisticsFilterContext();

  const fetchSummaryFreemium = useCallback(() => {
    dispatch(getSummaryAction());
  }, [dispatch]);

  useEffect(fetchSummaryFreemium, [fetchSummaryFreemium, statisticsFilters]);

  return {
    summaryFreemium,
    fetchSummaryFreemium,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useGetSummaryFreemium;
