import { ChangeEvent } from 'react';
import { ExternalCodesRequestDto } from '@tiendanube/common';
import { Skeleton } from './Skeleton';
import { ExternalCodesConversionCard } from '../ExternalCodesConversionCard/ExternalCodesConversionCard';
import { ExternalCodesConversionCodesCard } from '../ExternalCodesConversionCodesCard/ExternalCodesConversionCodesCard';
import { ExternalCodesFacebookCard } from '../ExternalCodesFacebookCard';
import { ExternalCodesGoogleCard } from '../ExternalCodesGoogleCard';
import { ExternalCodesOtherServicesCard } from '../ExternalCodesOtherServicesCard';

export interface ExternalCodesFormProps {
  values: ExternalCodesRequestDto;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  errors: Partial<Record<string, string>>;
}

export function ExternalCodesForm({
  values,
  onChange,
  onChangeTextArea,
  errors,
}: Readonly<ExternalCodesFormProps>) {
  return (
    <>
      <ExternalCodesGoogleCard
        onChange={onChange}
        onChangeTextArea={onChangeTextArea}
        googleAnalyticsApiSecret={values.googleAnalyticsApiSecret}
        googleAnalyticsGtmId={values.googleAnalyticsGtmId}
        googleAnalyticsMeasurementId={values.googleAnalyticsMeasurementId}
        googleMerchantId={values.googleMerchantId}
        googleMeta={values.googleMeta}
      />
      <ExternalCodesFacebookCard
        errors={errors}
        fbPixelCode={values.fbPixelCode}
        onChangeTextArea={onChangeTextArea}
      />
      <ExternalCodesConversionCard
        purchaseEventOnPaidOrder={values.purchaseEventOnPaidOrder}
        onChange={onChange}
      />
      <ExternalCodesConversionCodesCard
        purchaseEventOnPaidOrder={values.purchaseEventOnPaidOrder}
        thankYouCheckoutConversion={values.thankYouCheckoutConversion}
        confirmationCheckoutConversion={values.confirmationCheckoutConversion}
        script={values.script}
        assortedJs={values.assortedJs}
        onChangeTextArea={onChangeTextArea}
      />
      <ExternalCodesOtherServicesCard
        afip={values.afip}
        onChangeTextArea={onChangeTextArea}
        onChange={onChange}
        bingMeta={values.bingMeta}
        olark={values.olark}
        ebitSlug={values.ebitSlug}
        ebitAccount={values.ebitAccount}
      />
    </>
  );
}

ExternalCodesForm.Skeleton = Skeleton;
