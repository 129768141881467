import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApplyCouponRequestDto } from '@tiendanube/common/src/domains/billing/ApplyCouponRequestDto';
import { logout } from 'domains/Auth';
import { initialState } from './utils';
import couponsServices from '../couponsServices';

export const applyCoupon = createAsyncThunk(
  'billing/coupons',
  async (data: ApplyCouponRequestDto) => {
    const response = await couponsServices.applyCoupon(data);
    return response;
  },
);

const coupons = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    cleanCouponData(state) {
      state.data = initialState.data;
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.data = initialState.data;
      return state;
    });

    builder.addCase(applyCoupon.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    });

    builder.addCase(applyCoupon.rejected, (state) => {
      state.status = 'error';
      state.data = initialState.data;
    });

    builder.addCase(applyCoupon.pending, (state) => {
      state.status = 'loading';
    });
  },
});

export const { reducer } = coupons;

export const { cleanCouponData: cleanCouponData } = coupons.actions;
