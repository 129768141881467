import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ConceptCode } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import {
  useGetPayOrderToPayQuery,
  usePostCreatePayOrderMutation,
} from '../CheckoutApi';

const MAIN_CONCEPTS: ConceptCode[] = [
  'plan-cost',
  'app-cost',
  'plan-offline-cost',
  'transaction-fee',
  'shipping-fee',
  'shipping-cost',
  'plan-cost-upgrade',
];

function usePayorderToPay(initialFetch = true) {
  const { payOrderId } = useParams<{ payOrderId: string }>() || '';

  const {
    status,
    data: payorderToPay,
    refetch,
  } = useGetPayOrderToPayQuery(payOrderId, {
    skip: !payOrderId || !initialFetch,
  });

  const [createPayorder, { status: payOrderCreationStatus }] =
    usePostCreatePayOrderMutation();

  const isForPlanOnly = !payorderToPay?.charges.some(
    ({ conceptCode }) => conceptCode !== 'plan-cost',
  );

  const conceptsToPay = useMemo(
    () => new Set(payorderToPay?.charges.map(({ conceptCode }) => conceptCode)),
    [payorderToPay],
  );

  const recurrentPaymentCompatible = payorderToPay?.charges.some(
    ({ concept }) => concept.recurrentPaymentCompatible,
  );
  const mainConcept = Array.from(conceptsToPay).find((conceptCode) =>
    MAIN_CONCEPTS.includes(conceptCode),
  );

  return {
    createPayorder,
    payorderToPay,
    status: toStatusType(status),
    refreshPayOrderToPay: refetch,
    payOrderCreationStatus: toStatusType(payOrderCreationStatus),
    ...convertStatusTypeToObject(status),
    payOrderId,
    isForPlanOnly,
    conceptsToPay,
    recurrentPaymentCompatible,
    mainConcept,
  };
}

export default usePayorderToPay;
