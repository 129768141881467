import { SETTING_BASE_ROUTE } from 'App/constants/settingsRoute';

export const LOCATIONS_BASE_ROUTE = `${SETTING_BASE_ROUTE}/locations`;

export const LOCATIONS_ROUTES = {
  editLocation: `${LOCATIONS_BASE_ROUTE}/:id`,
  newLocationMain: `${LOCATIONS_BASE_ROUTE}/new/main`,
  newLocationSecondary: `${LOCATIONS_BASE_ROUTE}/new/secondary`,
  newLocationCreated: `${LOCATIONS_BASE_ROUTE}/created`,
};
