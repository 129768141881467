import { MetafieldsListV2Dto, MetafieldsMapV2Dto } from '@tiendanube/common';
import axios from 'App/axios';
import {
  METAFIELDS_CATEGORIES_URL,
  METAFIELDS_CUSTOMERS_URL,
  METAFIELDS_ORDERS_URL,
  METAFIELDS_VARIANTS_URL,
  METAFIELDS_PRODUCTS_AND_VARIANTS_URL,
} from './urls';

export const getMetafieldsOrders = async (): Promise<MetafieldsMapV2Dto> => {
  const { data } = await axios.get<MetafieldsMapV2Dto>(METAFIELDS_ORDERS_URL);
  return data;
};

export const getMetafieldsVariants = async (): Promise<MetafieldsListV2Dto> => {
  const { data } = await axios.get<MetafieldsListV2Dto>(
    METAFIELDS_VARIANTS_URL,
  );
  return data;
};

export const getMetafieldsCustomer = async (): Promise<MetafieldsListV2Dto> => {
  const { data } = await axios.get<MetafieldsListV2Dto>(
    METAFIELDS_CUSTOMERS_URL,
  );
  return data;
};

export const getMetafieldsCategories =
  async (): Promise<MetafieldsListV2Dto> => {
    const { data } = await axios.get<MetafieldsListV2Dto>(
      METAFIELDS_CATEGORIES_URL,
    );
    return data;
  };

export const getMetafieldsProductsAndVariants =
  async (): Promise<MetafieldsListV2Dto> => {
    const { data } = await axios.get<MetafieldsListV2Dto>(
      METAFIELDS_PRODUCTS_AND_VARIANTS_URL,
    );
    return data;
  };
