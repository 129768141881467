import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AbandonedCartsListResponseDto,
  AbandonedCartsResponseDto,
  PaginationResponseDto,
  AbandonedCartDetailsResponseDto,
  AbandonedCartEmailConfigResponseDto,
  AbandonedCartsQueryParamsDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { StatusType } from 'commons/types';
import { getAbandonedCartsPagination } from './abandonedCartsSelectors';
import {
  getAbandonedCartById,
  getAbandonedCartsList,
  updateAbandonedCartsEmailConfig as updateAbandonedCartsEmailConfigApi,
  getAbandonedCartsEmailConfig as getAbandonedCartsEmailConfigApi,
  sendEmail as sendEmailApi,
} from '../abandonedCartsService';

type AbandonedCartsEntitiesType = Record<string, AbandonedCartsResponseDto>;

export type AbandonedCartEmalConfg = 'automatic' | 'manual';

type AbandonedCartEntitiesDetailsType = {
  data: AbandonedCartDetailsResponseDto | null;
  status: StatusType;
};

interface AbandonedCartsEmailConfig {
  config: AbandonedCartEmalConfg;
  status: StatusType;
  changeStatus: StatusType;
}

export interface InterfaceAbandonedCartsSchema {
  ids: string[];
  refreshStatus: string;
  entities: AbandonedCartsEntitiesType;
  entityDetails: AbandonedCartEntitiesDetailsType;
  status: string;
  currentRequestID: string;
  pagination: PaginationResponseDto;
  abandonedCartsEmailConfig: AbandonedCartsEmailConfig;
}

const initialState: InterfaceAbandonedCartsSchema = {
  ids: [],
  status: 'idle',
  refreshStatus: 'idle',
  currentRequestID: '',
  entities: {},
  entityDetails: {
    status: 'idle',
    data: null,
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalResults: 0,
    perPage: 20,
    nextPage: null,
  },
  abandonedCartsEmailConfig: {
    config: 'automatic',
    status: 'idle',
    changeStatus: 'idle',
  },
};

type ThunkStateType = { state: RootStateType };

export const fetchAbandonedCartsList = createAsyncThunk<
  [AbandonedCartsListResponseDto, AbandonedCartEmailConfigResponseDto],
  AbandonedCartsQueryParamsDto | undefined
>('abandonedCarts/fetchAbandonedCartsList', async (params) => {
  const abandonedCarts = await Promise.all([
    getAbandonedCartsList(params),
    getAbandonedCartsEmailConfigApi(),
  ]);
  return abandonedCarts;
});

export const getMoreAbandonedCartsList = createAsyncThunk<
  AbandonedCartsListResponseDto,
  undefined,
  ThunkStateType
>('abandonedCarts/getMoreAbandonedCartsList', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const pagination = getAbandonedCartsPagination(state);

  if (!pagination.nextPage) {
    throw new Error('no valid fetch');
  }
  const response = await getAbandonedCartsList({
    page: String(pagination.nextPage),
  });
  return response;
});

export const fetchAbandonedCartById = createAsyncThunk<
  AbandonedCartDetailsResponseDto,
  string
>('abandonedCarts/fetchAbandonedCartById', async (id: string) => {
  const response = await getAbandonedCartById(id);
  return response;
});

export const updateAbandonedCartsEmailConfig = createAsyncThunk<
  AbandonedCartEmalConfg,
  AbandonedCartEmalConfg
>('abandonedCarts/updateAbandonedCartsEmailConfig', async (config) => {
  const response = await updateAbandonedCartsEmailConfigApi({ config });

  return response.abandonedCartsEmailConfig;
});

export const sendEmail = createAsyncThunk<void, string>(
  'abandonedCarts/sendEmail',
  async (id) => {
    await sendEmailApi(id);
  },
);

const abandonedCartsSlice = createSlice({
  name: 'abandonedCarts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAbandonedCartsList.fulfilled, (state, action) => {
      const ids: string[] = [];
      const [abandonedCartsList, abandonedCartsEmailConfig] = action.payload;
      state.entities = abandonedCartsList.results.reduce(
        (acc, abandonedCarts) => {
          acc[abandonedCarts.id] = abandonedCarts;
          ids.push(abandonedCarts.id);
          return acc;
        },
        {},
      );
      state.ids = ids;
      state.status = 'success';
      state.pagination = abandonedCartsList.pagination;

      state.abandonedCartsEmailConfig.config =
        abandonedCartsEmailConfig.abandonedCartsEmailConfig;
    });

    builder.addCase(fetchAbandonedCartsList.pending, (state, action) => {
      state.status = 'loading';
      state.currentRequestID = action.meta.requestId;
      state.entities = {};
      state.ids = [];
      state.pagination = initialState.pagination;
    });

    builder.addCase(fetchAbandonedCartsList.rejected, (state, action) => {
      state.status = 'error';
      state.currentRequestID = action.meta.requestId;
    });

    builder.addCase(getMoreAbandonedCartsList.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.results.reduce((acc, abandonedCarts) => {
        acc[abandonedCarts.id] = abandonedCarts;
        ids.push(abandonedCarts.id);
        return acc;
      }, state.entities);
      state.ids = [...state.ids, ...ids];
      state.refreshStatus = 'success';
      state.status = 'success';
      state.pagination = action.payload.pagination;
    });

    builder.addCase(getMoreAbandonedCartsList.pending, (state, action) => {
      state.refreshStatus = 'refreshing';
      state.currentRequestID = action.meta.requestId;
    });

    builder.addCase(getMoreAbandonedCartsList.rejected, (state) => {
      state.refreshStatus = 'error';
    });

    builder.addCase(fetchAbandonedCartById.pending, (state) => {
      state.entityDetails.status = 'loading';
      state.entityDetails.data = null;
    });

    builder.addCase(fetchAbandonedCartById.rejected, (state) => {
      state.entityDetails.status = 'error';
      state.entityDetails.data = null;
    });

    builder.addCase(fetchAbandonedCartById.fulfilled, (state, action) => {
      state.entityDetails.status = 'idle';
      state.entityDetails.data = action.payload;
    });

    // Email config
    builder.addCase(updateAbandonedCartsEmailConfig.pending, (state) => {
      state.abandonedCartsEmailConfig.changeStatus = 'loading';
    });

    builder.addCase(updateAbandonedCartsEmailConfig.rejected, (state) => {
      state.abandonedCartsEmailConfig.changeStatus = 'error';
    });

    builder.addCase(
      updateAbandonedCartsEmailConfig.fulfilled,
      (state, action) => {
        state.abandonedCartsEmailConfig.changeStatus = 'success';
        state.abandonedCartsEmailConfig.config = action.payload;
      },
    );
  },
});

export const { reducer } = abandonedCartsSlice;
