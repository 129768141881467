import { InterfaceText } from '@tiendanube/components';

const APPEARANCE = {
  '1': 'success',
  '-1': 'danger',
  '0': 'light',
};

export function getAppearanceFromNumber(
  num: number,
): InterfaceText['appearance'] {
  return APPEARANCE[Math.sign(num)];
}
