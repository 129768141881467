// TODO: The commented code should be removed when the integration with the BFF is finished

import { MercadoPagoVerifyResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import {
  // DASHBOARD_PAYMENTS_MESSAGES_URL,
  DASHBOARD_PAYMENTS_MESSAGES_PAGSEGURO_URL,
  DASHBOARD_PAYMENTS_MESSAGES_MERCADOPAGO_URL,
} from '../urls';

export async function getPagSeguroUrl() {
  const { data } = await axios.get<string>(
    DASHBOARD_PAYMENTS_MESSAGES_PAGSEGURO_URL,
  );
  return data;
}

export async function removeMercadoPagoCredentialsNotification() {
  await axios.delete(DASHBOARD_PAYMENTS_MESSAGES_MERCADOPAGO_URL);
}

export async function verifyMercadoPago() {
  const { data } = await axios.get<MercadoPagoVerifyResponseDto>(
    DASHBOARD_PAYMENTS_MESSAGES_MERCADOPAGO_URL,
  );
  return data;
}
