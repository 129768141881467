import { useLocation } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import useShouldRedirectPlanPage from 'App/hooks/useShouldRedirectPlanPage';
import goToAdmin from 'commons/utils/gotToAdmin';
import { logEvent } from 'commons/utils/tracking';
import { UPGRADE_SEE_PLANS_CLICK } from 'config/trackingEvents';
import { useGetIsFreemium, useIsMobileDevice } from 'domains/Auth/hooks';
import { BILLING_ROUTES } from 'domains/Billing/billingRoutes';

export function useGoToPlans() {
  const { goTo } = useNavegate();
  const { pathname } = useLocation();
  const isFreemium = useGetIsFreemium();
  const isMobileDevice = useIsMobileDevice();
  const plansPageNewAdmin = useShouldRedirectPlanPage();

  function handleGoToPlans() {
    logEvent(UPGRADE_SEE_PLANS_CLICK, { current_view: pathname });

    if (plansPageNewAdmin) {
      goTo(BILLING_ROUTES.plans);
    } else if (!isMobileDevice) {
      goToAdmin(BILLING_ROUTES.plans)();
    }
  }

  const isMobileNonFreemium = isMobileDevice && !isFreemium;

  return { handleGoToPlans, isMobileNonFreemium };
}
