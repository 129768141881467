const CUSTOM_PAYMENTS = "pagos-personalizados";
const CUSTOM_PAYMENTS_EN = "custom-payments";
const NUVEMPAGO = "nuvempago";
const PAGONUBE = "nuvempago_commons";
const MERCADOPAGO = "mercadopago";
const PAYPAL = "paypal_multiple";
const OPENPAY = "openpay";

const PAYPAL_APP_ID = "2362";

export const PaymentProvidersCode = {
  customPayments: CUSTOM_PAYMENTS,
  customPaymentsEn: CUSTOM_PAYMENTS_EN,
  mercadopago: MERCADOPAGO,
  nuvemPago: NUVEMPAGO,
  pagoNube: PAGONUBE,
  paypal: PAYPAL,
  openpay: OPENPAY,
};

export const muvemPagoOrPagoNubeIds = [
  PaymentProvidersCode.nuvemPago,
  PaymentProvidersCode.pagoNube,
];

export const customPaymentsIds = [
  PaymentProvidersCode.customPayments,
  PaymentProvidersCode.customPaymentsEn,
];

export const payPalAppId = PAYPAL_APP_ID;
