import { Select, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from '../../hook';

interface SkeletonProps {
  showTitle: boolean;
}

function Skeleton({ showTitle }: Readonly<SkeletonProps>) {
  const t = useTranslationMarketing('freeShipping.asides.categories');

  return (
    <>
      {showTitle && <Text fontWeight="bold">{t('title')}</Text>}
      <Select.Skeleton />
    </>
  );
}

export default Skeleton;
