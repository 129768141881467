import {
  Input as InputNimbus,
  InputProps as InputNimbusProps,
  Box,
  Text,
  Textarea,
} from '@nimbus-ds/components';

interface InputProps extends Omit<InputNimbusProps, 'onChange' | 'onBlur'> {
  onChange?: (params: { name: string; value: string }) => void;
  onBlur?: (params: { name: string; value: string }) => void;
  label?: string | null;
  helpText?: string | null;
  append?: React.ReactNode;
  lines?: number;
}

function Input({ onChange, onBlur, label, helpText, ...props }: InputProps) {
  const handleOnChange = ({ target: { name, value } }) =>
    onChange && onChange({ name, value });

  const handleOnBlur = ({ target: { name, value } }) =>
    onBlur && onBlur({ name, value });

  return (
    <Box display="flex" flexDirection="column" gap="2" marginBottom="4">
      {!!label && <Text color="neutral-textLow">{label}</Text>}
      {props.type === 'password' ? (
        <InputNimbus.Password
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          {...props}
        />
      ) : props.type === 'textarea' ? (
        <Textarea
          name={props.name}
          id={props.id || 'textarea'}
          value={props.value}
          placeholder={props.placeholder}
          appearance={props.appearance}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          lines={props.lines || 4}
        />
      ) : (
        <InputNimbus
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          {...props}
        />
      )}
      {!!helpText && <Text color="danger-textLow">{helpText}</Text>}
    </Box>
  );
}

export default Input;
