import { Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface UserFormPageErrorProps {
  refetch: () => void;
}

function UserFormPageError({ refetch }: Readonly<UserFormPageErrorProps>) {
  const t = useTranslationOnline('contactInfoPage');

  return (
    <Card>
      <Card.Body>
        <ErrorState
          title={t('errorState.title')}
          action={{
            children: t('errorState.action'),
            onClick: refetch,
          }}
        />
      </Card.Body>
    </Card>
  );
}

export default UserFormPageError;
