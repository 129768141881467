import { Badge, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { StopIcon } from '@nimbus-ds/icons';
import { DataTable } from '@nimbus-ds/patterns';
import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import { SortableDataList, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { ADDRESSING_RULES_TRANSLATE_TYPES } from '../../constants';

interface ActiveRulesListProps {
  rules: AddressingConfigurationRuleResponseDto[];
  handleOnChangePriority: (
    newOrder: AddressingConfigurationRuleResponseDto[],
  ) => void;
  handleRemoveRule: (rule: AddressingConfigurationRuleResponseDto) => void;
}

function ActiveRulesList({
  rules,
  handleOnChangePriority,
  handleRemoveRule,
}: Readonly<ActiveRulesListProps>) {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');

  return (
    <Stack column spacing="tight" align="flex-start">
      <Stack spacing="tight">
        <Badge appearance="success" count={rules.length} />
        <Title as="h6" fontWeight="bold">
          {t('modal.activeRules.title')}
        </Title>
      </Stack>
      {rules.length > 0 && (
        <DataTable header>
          <SortableDataList<AddressingConfigurationRuleResponseDto>
            items={rules.sort((a, b) => a.priority - b.priority)}
            customIdKey="code"
            onChange={handleOnChangePriority}
            renderItem={(rule) => (
              <Stack justify="space-between" spacing="tight">
                <Stack column spacing="tight" align="flex-start">
                  <Text>
                    {t(
                      `types.${
                        ADDRESSING_RULES_TRANSLATE_TYPES[rule.type]
                      }.title`,
                      {
                        appName: rule.appName,
                      },
                    )}
                  </Text>
                  <Text fontSize="caption">
                    {t(
                      `types.${
                        ADDRESSING_RULES_TRANSLATE_TYPES[rule.type]
                      }.description`,
                    )}
                  </Text>
                </Stack>
                <Link onClick={() => handleRemoveRule(rule)}>
                  <Icon color="danger-interactive" source={<StopIcon />} />
                </Link>
              </Stack>
            )}
          />
        </DataTable>
      )}
      {rules.length === 0 && (
        <Text fontSize="caption">{t('modal.activeRules.noRules')}</Text>
      )}
    </Stack>
  );
}

export default ActiveRulesList;
