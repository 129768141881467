import { Card, Icon, Title } from '@nimbus-ds/components';
import Stack from '../Stack';

function CardCollapsibleSkeleton(): JSX.Element {
  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Title.Skeleton as="h3" />
          <Icon.Skeleton />
        </Stack>
      </Card.Header>
    </Card>
  );
}

export default CardCollapsibleSkeleton;
