import cloneDeep from 'lodash.clonedeep';
import { Text, Stack, Chip } from '@tiendanube/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { attributeHasValues } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { Attributes } from '../../types';

interface AttributesPreviewProps {
  attributes: Attributes;
  onChangeAttributes: (attributes: Attributes) => void;
}

function AttributesPreview({
  attributes,
  onChangeAttributes,
}: AttributesPreviewProps) {
  const language = useGetLanguage();
  const t = useTranslationCatalog();
  const handleRemoveOption = (attribute: string, index: number) => {
    const attributesClone = cloneDeep(attributes);
    const updatedValues = attributesClone[attribute].values.filter(
      (_, i) => i !== index,
    );
    attributesClone[attribute] = {
      ...attributesClone[attribute],
      values: updatedValues.length === 0 ? [{}] : updatedValues,
    };
    onChangeAttributes(attributesClone);
  };

  const currentAttributes = Object.keys(attributes).filter((name) =>
    attributeHasValues(attributes[name]),
  );

  return (
    <Stack column align="stretch">
      <Text size="highlight">{t('products.variants.title')}</Text>
      {currentAttributes.map((attribute, index) => (
        <Stack key={index} column align="stretch" spacing="tight">
          <Text>{attribute}</Text>
          <Stack spacing="tight" wrap>
            {attributes[attribute].values.map((value, index) => {
              const translatedValue = value[language];
              return (
                !!translatedValue && (
                  <Chip
                    key={translatedValue}
                    id={translatedValue}
                    label={translatedValue}
                    onDismiss={() => handleRemoveOption(attribute, index)}
                  />
                )
              );
            })}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default AttributesPreview;
