import { logEvent } from 'commons/utils/tracking';
import {
  ADD_DOMAIN_CLICK,
  CREATE_DOMAIN_SUBMIT_CLICK,
  DELETE_DOMAIN_CLICK,
  DOMAINS_ERROR_ALERT_DASHBOARD_CLICK,
  DOMAINS_ERROR_ALERT_DASHBOARD_DISMISS,
  DOMAINS_ERROR_ALERT_DASHBOARD_SHOW,
  DOMAIN_ERROR_REVIEW_CLICK,
  SOFTBLOCK_NAMESERVER_ERROR_SHOW,
  SOFTBLOCK_NAMESERVER_SHOW,
  SOFTBLOCK_NAMESERVER_SKIP,
  SOFTBLOCK_NAMESERVER_TUTORIAL,
  SOFTBLOCK_NAMESERVER_UPDATED,
} from 'config/trackingEvents';

type SourceSoftblock = 'login' | 'domain';

export const trackingAddDomainClick = () => {
  logEvent(ADD_DOMAIN_CLICK, {});
};

export const trackingDeleteDomainClick = () => {
  logEvent(DELETE_DOMAIN_CLICK, {});
};

export const trackingCreateDomainSubmitClick = () => {
  logEvent(CREATE_DOMAIN_SUBMIT_CLICK, {});
};

export const trackingDomainsErrorAlertDashboardClick = () => {
  logEvent(DOMAINS_ERROR_ALERT_DASHBOARD_CLICK, {});
};

export const trackingDomainsErrorAlertDashboardDismiss = () => {
  logEvent(DOMAINS_ERROR_ALERT_DASHBOARD_DISMISS, {});
};

export const trackingDomainErrorReviewClick = () => {
  logEvent(DOMAIN_ERROR_REVIEW_CLICK, {});
};

export const trackingDomainsErrorAlertDashboardShow = () => {
  logEvent(DOMAINS_ERROR_ALERT_DASHBOARD_SHOW, {});
};

export const trackingSoftblockNameserverViewed = (
  storeId: string,
  source: SourceSoftblock,
  isErrorScreen: boolean,
) => {
  const event = isErrorScreen
    ? SOFTBLOCK_NAMESERVER_ERROR_SHOW
    : SOFTBLOCK_NAMESERVER_SHOW;
  logEvent(event, {
    source,
    storeId,
  });
};

export const trackingRememberLaterClick = (
  storeId: string,
  source: SourceSoftblock,
) => {
  logEvent(SOFTBLOCK_NAMESERVER_SKIP, {
    source,
    storeId,
  });
};

export const trackingNotifyChangesClick = (
  storeId: string,
  source: SourceSoftblock,
) => {
  logEvent(SOFTBLOCK_NAMESERVER_UPDATED, {
    source,
    storeId,
  });
};

export const trackingGoToTutorialClick = (
  storeId: string,
  source: SourceSoftblock,
) => {
  logEvent(SOFTBLOCK_NAMESERVER_TUTORIAL, {
    storeId,
    source,
  });
};
