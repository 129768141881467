import { useEffect } from 'react';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
} from 'commons/components';
import CurrentPage from './components/CurrentPage';
import { useSteps, useAuthenticationFactorPageTexts } from './hooks';
import {
  useAuthenticationFactors,
  useResetRecoveryCodes,
  useTranslationAccount,
  useRecoveryCodes,
} from '../../hooks';

function AuthenticationFactorPage(): JSX.Element {
  const { authenticationFactors, fetchAuthenticationFactors, status } =
    useAuthenticationFactors();

  const resetRecoveryCodes = useResetRecoveryCodes();
  const { recoveryCodes } = useRecoveryCodes();

  const someActiveAuthenticationFactor = authenticationFactors.some(
    (factor) => factor.active,
  );
  const t = useTranslationAccount('authenticationFactorPage');
  const { goBack } = useNavegate();
  const useStepsResponse = useSteps(
    authenticationFactors,
    fetchAuthenticationFactors,
  );
  const { title, subtitle } = useAuthenticationFactorPageTexts(
    useStepsResponse,
    someActiveAuthenticationFactor,
  );

  useEffect(() => {
    // TODO: check function usage: https://github.com/TiendaNube/services-new-admin/pull/1793#discussion_r1042694037
    fetchAuthenticationFactors();
  }, [fetchAuthenticationFactors]);

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={title} subtitle={subtitle} />}
    >
      {status === 'error' ? (
        <ErrorScreen
          description={t('errorStateDescription')}
          onRetry={fetchAuthenticationFactors}
        />
      ) : (
        <Stack column align="stretch">
          <CurrentPage
            useStepsResponse={useStepsResponse}
            authenticationFactors={authenticationFactors}
            recoveryCodes={recoveryCodes}
            resetRecoveryCodes={resetRecoveryCodes}
            authenticationFactorsAreLoading={status === 'loading'}
          />
          <HelpLink
            title={t('linkDescription')}
            linkURL={t('helpLink')}
            icon="both"
            currentViewTracking=""
            previousValue=""
          />
        </Stack>
      )}
    </IonPageStratus>
  );
}

export default AuthenticationFactorPage;
