import { Box, Card, Title, Text, Link, Icon } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { IconBox } from 'commons/components';
import {
  useRedirectionsDashboard,
  useTranslationDashboard,
} from 'domains/Dashboard/hooks';
import { trackingAdditionalSettingClick } from 'domains/Dashboard/tracking';
import {
  ADDITIOAL_SETTING_EVENT_CARD,
  ADDITIOAL_SETTING_ITEM_ICONS,
} from './utils';

export type AdditionalSettingType =
  | 'custom-link'
  | 'free-shipping'
  | 'weight-dimension'
  | 'sort-products'
  | 'abandoned-cart'
  | 'instagram-shops'
  | 'google-shopping';

interface AditionalSettingCardProps {
  item: AdditionalSettingType;
}

function AditionalSettingCard({ item }: AditionalSettingCardProps) {
  const t = useTranslationDashboard();
  const { additionalSetting } = useRedirectionsDashboard();
  const linkProperties = additionalSetting[item];
  const IconItem = ADDITIOAL_SETTING_ITEM_ICONS[item];
  const handleClick = () => {
    const card = ADDITIOAL_SETTING_EVENT_CARD[item];
    trackingAdditionalSettingClick({ card });
  };

  return (
    <Card>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        height="100%"
        flexWrap="nowrap"
        gap="2"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Title as="h4">{t(`additionalSetting.${item}.title`)}</Title>
          <Text textAlign="left">
            {t(`additionalSetting.${item}.description`)}
          </Text>
          {linkProperties.to ? (
            <Link
              as={LinkRoute}
              to={linkProperties?.to}
              target={linkProperties.target}
              onClick={handleClick}
              appearance="primary"
              textDecoration="none"
            >
              {t(`additionalSetting.${item}.link`)}
            </Link>
          ) : (
            <Link
              as="a"
              href={linkProperties.href}
              target={linkProperties.target}
              onClick={handleClick}
              appearance="primary"
              textDecoration="none"
            >
              {t(`additionalSetting.${item}.link`)}
            </Link>
          )}
        </Box>
        <IconBox flex="0 0 auto" backgroundColor="primary-surface">
          <Icon source={<IconItem color="currentColor" size="medium" />} />
        </IconBox>
      </Box>
    </Card>
  );
}

export default AditionalSettingCard;
