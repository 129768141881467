import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { ErrorState, InterfaceLink } from '@tiendanube/components';

interface CustomerFormPageErrorProps {
  onRetry: () => void;
}

function CustomerFormPageError({
  onRetry,
}: CustomerFormPageErrorProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  const retryButton: InterfaceLink = {
    children: `${t('customerForm.errorState.retry')}`,
    onClick: onRetry,
  };

  return (
    <ErrorState
      title={t('customerForm.errorState.errorTitle')}
      action={retryButton}
    />
  );
}

export default CustomerFormPageError;
