import { useState, useCallback } from 'react';
import {
  Thumbnail,
  Text,
  Link,
  LinkBaseProps,
  Box,
} from '@nimbus-ds/components';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { ActionRowProducts } from 'domains/Catalog/Products/pages/ProductListPage/components';
import Skeleton from './Skeleton';
import { HiddenProductPill } from '../../HiddenProductPill';

export interface ImageItemVariantProps {
  thumbnail?: string;
  link: Pick<LinkBaseProps, 'children' | 'onClick'>;
  product: ProductDetailsResponseDto;
}

function ImageItemVariant({
  thumbnail,
  link,
  product,
}: Readonly<ImageItemVariantProps>) {
  const [errorLoad, setErrorLoad] = useState(false);
  const handleError = useCallback(() => setErrorLoad(true), []);

  const hiddenProduct = !product.published;

  return (
    <Box
      display="flex"
      gap="1"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box display="flex" gap="3-5" alignItems="flex-start">
        <Box>
          {thumbnail && !errorLoad ? (
            <Thumbnail
              src={thumbnail}
              alt="my-image"
              width="48px"
              height="48px"
              onError={handleError}
            />
          ) : (
            <Thumbnail alt="my-image" width="48px" height="48px" />
          )}
        </Box>
        <Link onClick={link.onClick} textDecoration="none">
          <Text
            color="primary-interactive"
            fontSize="highlight"
            fontWeight="medium"
            lineClamp={2}
          >
            {link.children}
          </Text>
        </Link>
      </Box>
      <Box display="flex" alignItems="center" gap="2" ml="1">
        {hiddenProduct && <HiddenProductPill id={`${product.id}`} />}
        <ActionRowProducts id={product.id} />
      </Box>
    </Box>
  );
}
ImageItemVariant.Skeleton = Skeleton;

export default ImageItemVariant;
