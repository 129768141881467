import { useSelector } from 'react-redux';
import {
  getCloudflareBlocked,
  getCloudflareHardBlocked,
} from 'domains/Auth/authSlice/authSelectors';
import { useRefreshInfoToSync } from 'domains/Auth/hooks';
import { CLOUDFLARE_BLOCK_DISMISS_EXPIRATION_TTL_IN_SECONDS } from 'domains/Dashboard/slices/constants';
import dashboardService from '../../services';

function useCloudflareBlocked() {
  const ttl = CLOUDFLARE_BLOCK_DISMISS_EXPIRATION_TTL_IN_SECONDS;

  const cloudflareBlocked = useSelector(getCloudflareBlocked);
  const hardBlock = useSelector(getCloudflareHardBlocked);
  const refreshInfoToSync = useRefreshInfoToSync();

  const dismissCloudflareBlock = async () => {
    const timestampNow = Math.round(new Date().getTime() / 1000);
    await dashboardService.dismissCloudflareBlock(timestampNow + ttl);

    refreshInfoToSync(true);
  };

  const validateIPs = async () => {
    const { migrationSuccess } =
      await dashboardService.cloudflareIPsMigrationCheck();

    return migrationSuccess;
  };

  return {
    cloudflareBlocked,
    hardBlock,
    dismissCloudflareBlock,
    validateIPs,
  };
}

export default useCloudflareBlocked;
