import { ReactNode } from 'react';
import { Box } from '@nimbus-ds/components';
import { useResponsive } from 'commons/components';

export type VariantCellProps = { children: ReactNode };

function VariantCell({ children }: VariantCellProps) {
  const { isMobile } = useResponsive();

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="2"
      alignItems="center"
      height={isMobile ? '' : '34px'}
    >
      {children}
    </Box>
  );
}

export default VariantCell;
