import { useEffect } from 'react';
import { Alert } from '@nimbus-ds/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface AlertPersistsSectionCodesProps {
  show: boolean;
  onClose: () => void;
}

function AlertPersistsSectionCodes({
  show,
  onClose,
}: AlertPersistsSectionCodesProps): JSX.Element | null {
  const t = useTranslationCatalog();
  const { scrollToTop } = useLayoutScroll();

  useEffect(
    () => () => {
      onClose();
    },
    [onClose],
  );

  useEffect(() => {
    if (show) scrollToTop();
  }, [show, scrollToTop]);

  if (!show) return null;
  return (
    <Alert
      show
      appearance="warning"
      title={t('products.detail.highlight.error.update.title')}
    >
      {t('products.detail.highlight.error.update.description')}
    </Alert>
  );
}

export default AlertPersistsSectionCodes;
