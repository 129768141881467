import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  updateDetailCustomShippingStatus,
  updateDetailCustomShippingAction,
  cleanUpdateCustomShippingStatus,
} from '../../deliveryMethodsSlice';

function useUpdateCustomShipping() {
  const dispatch = useAppDispatch();

  const status = useSelector(updateDetailCustomShippingStatus);

  const updateDetailCustomShipping = useCallback(
    async (id, values) => {
      await dispatch(updateDetailCustomShippingAction({ id, data: values }));
      dispatch(cleanUpdateCustomShippingStatus());
    },
    [dispatch],
  );

  return {
    status,
    updateDetailCustomShipping,
  };
}

export default useUpdateCustomShipping;
