import { useEffect, useState } from 'react';
import { Input } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useAutoFocus, useToast } from 'commons/hooks';
import { useGetAddressesBrazil } from 'domains/Shipping/Addresses/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { AddressProps } from '..';
import Skeleton from '../Skeleton';

function BrazilianAddress({
  values,
  errors,
  setFieldValue,
  autoFocus,
}: AddressProps) {
  const t = useTranslationShipping();
  const { ref } = useAutoFocus();

  const {
    fetchAddressesBrazil,
    addressesBrazil,
    notFound,
    isLoading,
    isError,
  } = useGetAddressesBrazil();

  const { addToast } = useToast();

  const [disabled, setDisabled] = useState({
    neighborhood: true,
    street: true,
  });

  useEffect(() => {
    if (isError) {
      addToast({
        label: t('address.errors.zipcodeCheckError'),
        appearance: 'danger',
      });
    }
  }, [isError, addToast, t]);

  useEffect(() => {
    if (addressesBrazil) {
      const { province, city, locality, street } = addressesBrazil;
      setFieldValue('province', province);
      setFieldValue('provinceCode', '');
      setFieldValue('city', city);
      setFieldValue('neighborhood', locality ?? '');
      setFieldValue('street', street ?? '');
      setDisabled({
        neighborhood: locality !== null,
        street: street !== null,
      });
    }
  }, [addressesBrazil, setDisabled, setFieldValue]);

  const onChange = ({ name, value }): void => {
    setFieldValue(name, value);
    const hasGuion = value.includes('-');
    if (
      (name === 'zipcode' && !hasGuion && value.length === 8) ||
      (name === 'zipcode' && hasGuion && value.length === 9)
    ) {
      fetchAddressesBrazil(value);
    }
    if (name === 'zipcode' && value.length < 8) {
      clearFields();
    }
  };

  const clearFields = () => {
    setFieldValue('province', '');
    setFieldValue('provinceCode', '');
    setFieldValue('city', '');
    setFieldValue('neighborhood', '');
    setFieldValue('street', '');
    setDisabled({ neighborhood: true, street: true });
  };

  let zipcodeHelpText = '';
  let zipcodeAppearance: 'default' | 'validation_error' | 'validation_loading' =
    'default';

  if (isLoading) {
    zipcodeAppearance = 'validation_loading';
  } else if (notFound) {
    zipcodeHelpText = t('address.errors.zipcodeInvalid');
    zipcodeAppearance = 'validation_error';
  } else if (errors.zipcode) {
    zipcodeHelpText = t(errors.zipcode || '');
    zipcodeAppearance = 'validation_error';
  }

  const neighborhoodHelpText = t(errors.neighborhood || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');

  return (
    <Stack column align="stretch" innerRef={autoFocus && ref}>
      <Input
        label={t('address.zipcode')}
        onChange={onChange}
        name="zipcode"
        maxLength={9}
        value={values.zipcode}
        helpText={zipcodeHelpText}
        appearance={zipcodeAppearance}
      />

      {isLoading ? (
        <>
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </>
      ) : (
        <>
          <Input
            type="text"
            label={t('address.province')}
            name="province"
            value={values.province}
            disabled
          />
          <Input
            type="text"
            label={t('address.city')}
            name="city"
            value={values.city}
            disabled
          />
          <Input
            type="text"
            label={t('address.neighborhood')}
            onChange={onChange}
            name="neighborhood"
            value={values.neighborhood}
            disabled={disabled.neighborhood}
            helpText={neighborhoodHelpText}
            appearance={neighborhoodHelpText ? 'validation_error' : 'default'}
          />
          <Input
            type="text"
            label={t('address.street')}
            onChange={onChange}
            name="street"
            value={values.street}
            disabled={disabled.street}
            helpText={streetHelpText}
            appearance={streetHelpText ? 'validation_error' : 'default'}
          />
        </>
      )}

      <Input
        type="text"
        label={t('address.number')}
        onChange={onChange}
        name="number"
        value={values.number}
        helpText={numberHelpText}
        appearance={numberHelpText ? 'validation_error' : 'default'}
      />
      <Input
        type="text"
        label={t('address.complement')}
        onChange={onChange}
        name="complement"
        value={values.complement}
      />
    </Stack>
  );
}

BrazilianAddress.Skeleton = Skeleton;

export default BrazilianAddress;
