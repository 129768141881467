import { useState } from 'react';
import { Input, Text, Box } from '@nimbus-ds/components';
import { IconButton, InterfaceNameValue, Stack } from '@tiendanube/components';
import { TrashIcon } from '@tiendanube/icons';
import { applyNimbusNameValue, callWhenPress } from 'commons/transformers';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface CustomAttributeInputProps {
  value: string;
  name: string;
  helpText: string | false;
  isStandardValue: boolean;
  showDeleteIcon: boolean;
  lastItem: boolean;
  onChange: (event: InterfaceNameValue) => void;
  onRemove: () => void;
  onPressEnter: () => void;
  onBlur: (event: InterfaceNameValue) => void;
}

function CustomAttributeInput({
  value,
  name,
  lastItem,
  helpText,
  isStandardValue,
  showDeleteIcon = true,
  onPressEnter,
  onChange,
  onRemove,
  onBlur,
}: CustomAttributeInputProps): JSX.Element {
  const t = useTranslationCatalog();
  const [disabled, setDisabled] = useState(isStandardValue);

  const handleBlur = (e: InterfaceNameValue): void => {
    if (isStandardValue) {
      setDisabled(true);
    }
    onBlur(e);
  };

  const handlerPressEnter = () => {
    if (value.trim() !== '' && lastItem) onPressEnter();
  };

  return (
    <Stack.Item>
      <Stack spacing="tight">
        <Stack.Item fill>
          <Input
            appearance={helpText ? 'danger' : 'neutral'}
            value={value}
            name={name}
            onKeyDown={callWhenPress('Enter', handlerPressEnter)}
            onChange={applyNimbusNameValue(onChange)}
            onBlur={applyNimbusNameValue(handleBlur)}
            disabled={disabled}
          />
          {!!helpText && (
            <Box mt="1">
              <Text fontSize="caption" color="danger-textLow">
                {helpText}
              </Text>
            </Box>
          )}
        </Stack.Item>
        {showDeleteIcon && (
          <Stack.Item>
            <IconButton
              icon={TrashIcon}
              ariaLabel={t('products.variants.delete')}
              onClick={onRemove}
            />
          </Stack.Item>
        )}
      </Stack>
    </Stack.Item>
  );
}

export default CustomAttributeInput;
