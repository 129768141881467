import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import {
  PushNotifications,
  PushNotificationSchema,
  ActionPerformed,
  Token,
} from '@capacitor/push-notifications';
import { logError } from 'commons/logger';
import actionPushNotification from './actionPushNotification';
import { saveDeviceInfo, registerDeviceOnNuvemShop } from './device';
import preventRegistrationWithoutPreviousAuth from './preventRegistrationWithoutPreviousAuth';

const MAX_REG_TRIES = 6;

const addFirebasePushNotificacionListeners = (
  onOrderCreatedRef: React.MutableRefObject<() => void>,
): void => {
  if (!Capacitor.isNativePlatform()) return;

  let tries = 1;

  PushNotifications.addListener('registration', async (token: Token) => {
    if (!(await preventRegistrationWithoutPreviousAuth())) {
      console.error(
        'registration should not be executed if a user is not authenticated',
      );
      return;
    }
    const deviceId = await Device.getId();

    await saveDeviceInfo({ deviceId: deviceId.identifier, token: token.value });
    await registerDeviceOnNuvemShop();
  });

  PushNotifications.addListener('registrationError', async () => {
    if (tries < MAX_REG_TRIES) {
      tries++;
      PushNotifications.register();
    }
  });

  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      actionPushNotification(notification.notification.data);
    },
  );

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      if (notification.data?.event === 'order_created') {
        onOrderCreatedRef.current();
      }
    },
  );
};

const ANDROID_CHANNEL_NEW_ORDER = 'New Order';

const createChannelAndroid = async () => {
  if (Capacitor.getPlatform() !== 'android') return;
  try {
    const listChannels = await PushNotifications.listChannels();
    const existsChannelNewOrder =
      listChannels.channels.filter((c) => c.name === ANDROID_CHANNEL_NEW_ORDER)
        .length >= 1;

    if (existsChannelNewOrder) return;

    await PushNotifications.createChannel({
      description: 'General Notifications',
      id: 'channel_order_created',
      importance: 5,
      lights: true,
      name: ANDROID_CHANNEL_NEW_ORDER,
      sound: 'neworder.wav',
      vibration: true,
      visibility: 1,
    });
  } catch (error) {
    logError(`Push Channel Error: ${error}`, error);
  }
};

const useCapacitorListeners = (onOrderCreated: () => void): void => {
  const onOrderCreatedRef = useRef(onOrderCreated);

  useEffect(() => {
    onOrderCreatedRef.current = onOrderCreated;
  }, [onOrderCreated]);

  useEffect(() => {
    addFirebasePushNotificacionListeners(onOrderCreatedRef);
    createChannelAndroid();
  }, []);
};

export default useCapacitorListeners;
