import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function UsersListDesktopHeader() {
  const t = useTranslationConfigurations('usersListPage.table');

  return (
    <Table.Head>
      <CustomHeaderCell>{t('name')}</CustomHeaderCell>
      <CustomHeaderCell>{t('email')}</CustomHeaderCell>
      <CustomHeaderCell>{t('permissions')}</CustomHeaderCell>
      <CustomHeaderCell width="180px">{t('2fa')}</CustomHeaderCell>
      <CustomHeaderCell width="100px">{t('actions')}</CustomHeaderCell>
    </Table.Head>
  );
}

export default UsersListDesktopHeader;
