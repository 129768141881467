import { DataList } from '@nimbus-ds/patterns';
import { FreeShippingItemListResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import {
  Actions,
  DeliveryMethodsItem,
  StatusTagItem,
} from 'domains/Marketing/FreeShipping/components';
import { FreeShippingUseConditions } from './FreeShippingUseConditions';

interface FreeShippingListRowProps {
  freeShipping: FreeShippingItemListResponseDto;
  index: number;
}

export function FreeShippingListRow({
  freeShipping,
  index,
}: Readonly<FreeShippingListRowProps>) {
  const { id, methods, enable } = freeShipping;

  return (
    <DataList.Row backgroundColor="transparent" topDivider={index !== 0}>
      <Stack column align="stretch" gap="2">
        <Stack justify="space-between" align="center">
          <Stack column align="stretch" spacing="none">
            <DeliveryMethodsItem methods={methods} id={id} />
          </Stack>
          <Actions status={enable} id={id} />
        </Stack>
        <FreeShippingUseConditions freeShipping={freeShipping} />
        <StatusTagItem status={enable} />
      </Stack>
    </DataList.Row>
  );
}
