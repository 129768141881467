import { Box, Text, Link } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields/useTraslationsMetafields';

export default function EmptyOnCard() {
  const t = useTranslationMetafields();
  const { goTo } = useNavegate();

  const handleClick = () => goTo('/settings/metafields');

  return (
    <Box
      paddingX="4"
      paddingBottom="4"
      paddingTop="2"
      gap="4"
      display="flex"
      flexDirection="column"
    >
      <Text>{t('createInvite.text')}</Text>
      <Link appearance="primary" textDecoration="none" onClick={handleClick}>
        <PlusCircleIcon />
        {t('createInvite.link')}
      </Link>
    </Box>
  );
}
