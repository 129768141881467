import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  createFiscalData as createFiscalDataAction,
  updateFiscalData as updateFiscalDataAction,
  cleanFiscalData,
  getFiscalDataStatus,
} from 'domains/Billing/Invoices/invoicesSlice';
import { FiscalDataformColombia } from './components/ColombianFiscalDataForm';

function useColombianFiscalData() {
  const dispatch = useAppDispatch();
  const status = useSelector(getFiscalDataStatus);

  const cleanData = useCallback(() => {
    dispatch(cleanFiscalData());
  }, [dispatch]);

  const createFiscalData = async ({
    name,
    vatCondition,
    personType,
    numberIdType,
    address,
    numberId,
    cityId,
  }: FiscalDataformColombia) => {
    await dispatch(
      createFiscalDataAction({
        name,
        numberId,
        address,
        vatCondition,
        personType,
        numberIdType,
        cityId: Number(cityId),
      }),
    );
    cleanData();
  };

  const updateFiscalData = async (data: FiscalDataformColombia) => {
    await dispatch(
      updateFiscalDataAction({ ...data, cityId: Number(data.cityId) }),
    );
    cleanData();
  };

  return {
    cleanData,
    createFiscalData,
    updateFiscalData,
    status,
  };
}

export default useColombianFiscalData;
