import { InformationForContentDeclarationResponseDto } from '@tiendanube/common';
import './ContentDeclarationPreview.scss';
import { useResponsive } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface ContentDeclarationPreviewProps {
  information: InformationForContentDeclarationResponseDto[];
  locationId: string;
}

function ContentDeclarationPreview({
  information,
  locationId,
}: Readonly<ContentDeclarationPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.contentDeclaration');
  const { isMobile } = useResponsive();

  return (
    <>
      {information
        .filter(
          ({ fulfillmentOrder }) =>
            !locationId || fulfillmentOrder.assignedLocation.id === locationId,
        )
        .map(({ fulfillmentOrder, store, order }) => (
          <div
            key={fulfillmentOrder.id + order.id}
            className={`stratus--content-declaration ${
              isMobile ? 'mobile' : ''
            }`}
          >
            <h1>{t('title')}</h1>

            <table className="address-table">
              <thead>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.recipient')}</b>{' '}
                    {fulfillmentOrder.recipient.name}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.document')}</b>{' '}
                    {fulfillmentOrder.recipient.identifier || ''}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.address')}</b>{' '}
                    {fulfillmentOrder.destination?.streetAddress ?? ''}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{t('entity.city')}</b>{' '}
                    {fulfillmentOrder.destination?.cityAndState ?? ''}
                  </td>
                  <td>
                    <b>{t('entity.zipcode')}</b>{' '}
                    {fulfillmentOrder.destination?.zipcode ?? ''}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="address-table">
              <thead>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.store')}</b> {store.name}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.document')}</b> {store.identifier}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <b>{t('entity.address')}</b>{' '}
                    {store.address?.streetAddress ?? ''}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{t('entity.city')}</b>{' '}
                    {store.address?.cityAndState ?? ''}
                  </td>
                  <td>
                    <b>{t('entity.zipcode')}</b> {store.address?.zipcode ?? ''}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="items-table">
              <thead>
                <tr>
                  <th colSpan={4}>{t('contentIdentification.title')}</th>
                </tr>
                <tr>
                  <th>{t('contentIdentification.contentDiscrimination')}</th>
                  <th>{t('contentIdentification.quantity')}</th>
                  <th>{t('contentIdentification.weight')}</th>
                  <th>{t('contentIdentification.value')}</th>
                </tr>
              </thead>
              <tbody>
                {fulfillmentOrder.lineItems.map(
                  ({ name, quantity, unitPrice, weight }) => (
                    <tr className="product-row" key={name}>
                      <td>{name}</td>
                      <td>{quantity}</td>
                      <td>{weight} kg</td>
                      <td>{unitPrice}</td>
                    </tr>
                  ),
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td>{t('contentIdentification.total')}</td>
                  <td>{fulfillmentOrder.totalQuantity}</td>
                  <td>{fulfillmentOrder.totalWeight} kg</td>
                  <td>{fulfillmentOrder.totalPrice}</td>
                </tr>
              </tfoot>
            </table>

            <table className="declaration-section">
              <thead>
                <tr>
                  <th>{t('declaration.title')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="declaration-text">
                    <p>{t('declaration.legalOrNaturalPersonText')}</p>
                    <p>{t('declaration.flammableMaterialText')}</p>
                    <div className="signature-lines">
                      <div className="signature-line-city" />
                      <div>,</div>
                      <div className="signature-line-day" />
                      <div>{t('declaration.dateSeparator')}</div>
                      <div className="signature-line-month" />
                      <div>{t('declaration.dateSeparator')}</div>
                      <div className="signature-line-year" />
                      <div className="signature-line-name" />
                    </div>
                    <p className="signature">{t('declaration.signature')}</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="attention">
              <b>{t('note.title')}</b>
              {t('note.text')}
            </p>

            <div className="observations">
              <p>{t('observations.title')}</p>
              <div className="observation-item">
                <p className="observation-number">I.</p>
                <p>{t('observations.icmsText')}</p>
              </div>
              <div className="observation-item">
                <p className="observation-number">II.</p>
                <p>{t('observations.taxEvasionText')}</p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default ContentDeclarationPreview;
