import { useCallback } from 'react';
import { Box, Icon, Radio, Text } from '@nimbus-ds/components';
import { IconProps } from '@nimbus-ds/icons';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { usePaymentSelectionForPayOrder } from '../../hooks';

interface PaymentOptionProps {
  icon: React.FC<IconProps>;
  setPaymentMethodSelected: (method: CheckoutPaymentMethod) => void;
  paymentMethodSelected: CheckoutPaymentMethod;
  method: CheckoutPaymentMethod;
  isLoading: boolean;
}

function PaymentOption({
  icon,
  method,
  setPaymentMethodSelected,
  isLoading,
  paymentMethodSelected,
}: Readonly<PaymentOptionProps>) {
  const t = useTranslationBilling(
    `checkout.choosePaymentMethodForPayOrder.paymentSelection.${method}`,
  );
  const { availablePaymentMethods } = usePaymentSelectionForPayOrder();

  const handleClick = useCallback(() => {
    if (!isLoading) {
      setPaymentMethodSelected(method);
    }
  }, [setPaymentMethodSelected, isLoading, method]);

  if (!availablePaymentMethods?.includes(method)) return null;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      onClick={handleClick}
    >
      <Box display="flex" flexDirection="row" gap="3">
        <Icon source={icon({})} />
        <Box display="flex" flexDirection="column" gap="1">
          <Text>{t('title')}</Text>
          <Text fontSize="caption" color="neutral-textLow">
            {t('subtitle')}
          </Text>
        </Box>
      </Box>
      <Radio
        name="payment-option"
        checked={paymentMethodSelected === method}
        id={method}
        disabled={isLoading}
      />
    </Box>
  );
}

export default PaymentOption;
