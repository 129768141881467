import { Box, Button, Card } from '@nimbus-ds/components';
import { Input } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function CreditCardDataCardSkeleton() {
  const t = useTranslationBilling(
    'checkout.payWithCreditCard.creditCardDataCard',
  );

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Card>
        <Card.Header title={t('title')} />
        <Card.Body>
          <Box display="flex" flexDirection="column" gap="4">
            <Input.Skeleton />
            <Input.Skeleton />
            <Box display="flex" flexDirection="row" gap="4">
              <Box display="flex" flexDirection="column" flexGrow="1">
                <Input.Skeleton />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow="1">
                <Input.Skeleton />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap="4">
              <Box display="flex" flexDirection="column" flex="1 1 35%">
                <Input.Skeleton />
              </Box>
              <Box display="flex" flexDirection="column" flex="1 1 65%">
                <Input.Skeleton />
              </Box>
            </Box>
            <Input.Skeleton />
          </Box>
        </Card.Body>
      </Card>

      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Button.Skeleton />
      </Box>
    </Box>
  );
}

export default CreditCardDataCardSkeleton;
