import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useToast } from 'commons/hooks';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useTranslationOnline } from 'domains/Online';
import {
  getAllPagesAction,
  getAllPagesListStatusSelector,
  getAllPagesListDataSelector,
} from '../../contentPagesSlice';

function useGetAllPages() {
  const t = useTranslationOnline('contentPages.pageList');
  const dispatch = useAppDispatch();
  const { addToast } = useToast();
  const status = useSelector(getAllPagesListStatusSelector);
  const pages = useSelector(getAllPagesListDataSelector);

  const fetchAllPages = useCallback(async () => {
    try {
      const result = await dispatch(getAllPagesAction());
      return unwrapResult(result);
    } catch (error) {
      addToast({
        label: t('error.title'),
        appearance: 'danger',
      });
    }
  }, [addToast, dispatch, t]);

  useEffect(() => {
    if (status === 'idle') {
      fetchAllPages();
    }
  }, [fetchAllPages, status]);

  return {
    ...convertStatusTypeToObject(status),
    pages,
  };
}

export default useGetAllPages;
