import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavegate } from 'App/hooks';
import { BASE_URL_ENV } from 'commons/utils';

function GoogleAnalytics(): null {
  const { pathname } = useNavegate();

  useEffect(() => {
    const parts = pathname.split('/');
    const contentGroup = parts[1];
    const path = pathname.replace(BASE_URL_ENV, '/');
    const newPath = path.endsWith('/') ? path.slice(0, -1) : path;

    ReactGA.gtag('event', 'page_view', {
      content_group: contentGroup,
      page_path: newPath,
    });
  }, [pathname]);

  return null;
}

export default GoogleAnalytics;
