export const BASE_THEMES_URL = '/v1/themes';
export const IMAGES_URL = `${BASE_THEMES_URL}/images`;
export const FTP_URL = `${BASE_THEMES_URL}/ftp`;
export const FTP_PASSWORD_URL = `${FTP_URL}/password`;
export const DRAFT_URL = `${BASE_THEMES_URL}/draft`;
export const PUBLISH_DRAFT_URL = `${DRAFT_URL}/publish`;
export const THEMES_METRICS_URL = `${BASE_THEMES_URL}/metrics`;

export const themesEndpoints = {
  getThemesConfig: 'getThemesConfig',
  getFtpStatus: 'getFtpStatus',
  getFtpPassword: 'getFtpPassword',
  editImages: 'editImages',
  uploadImages: 'uploadImages',
  deleteImages: 'deleteImages',
  enableFtp: 'enableFtp',
  disableFtp: 'disableFtp',
  resetPassword: 'resetPassword',
  publishDraft: 'publishDraft',
  createDraft: 'createDraft',
  deleteDraft: 'deleteDraft',
  getThemesMetrics: 'getThemesMetrics',
} as const;

export const themesTags = {
  themesConfig: 'themesConfig',
} as const;
