import { Route } from 'react-router-dom';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { MarketplacesAppsPage } from './MarketplacesApps/pages';
import SalesChannelsAppsPage from './SalesChannelsApps/pages/SalesChannelsAppsPage';
import { SALES_CHANNELS_ROUTES } from './salesChannelsRoutes';

function SalesChannels(): JSX.Element {
  const isMobile = useIsMobileDevice();

  if (isMobile)
    return (
      <>
        <Route path={SALES_CHANNELS_ROUTES.salesChannelsApps}>
          <SalesChannelsAppsPage />
        </Route>
        <Route path={SALES_CHANNELS_ROUTES.marketplacesApps}>
          <MarketplacesAppsPage />
        </Route>
      </>
    );

  return (
    <>
      <Route path={SALES_CHANNELS_ROUTES.salesChannelsApps}>
        <ToggleMigratedOldDomain domain="sales-channels-apps">
          <SalesChannelsAppsPage />
        </ToggleMigratedOldDomain>
      </Route>
      <Route path={SALES_CHANNELS_ROUTES.marketplacesApps}>
        <ToggleMigratedOldDomain domain="marketplaces-apps">
          <MarketplacesAppsPage />
        </ToggleMigratedOldDomain>
      </Route>
    </>
  );
}

export default SalesChannels;
