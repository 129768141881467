import { ReactNode } from 'react';
import { Stack, Text } from '@tiendanube/components';
import { InterfaceStack } from '@tiendanube/components/dist/Stack';
import { InterfaceText } from '@tiendanube/components/dist/Text';
import { Icon as IconType } from '@tiendanube/icons';
import { IconProps } from '@tiendanube/icons/dist/icons';
import './ListItem.scss';

interface ListItemProps {
  icon: IconType;
  iconSize?: IconProps['size'];
  neutralIcon?: boolean;
  content: string | ReactNode;
  contentSize?: InterfaceText['size'];
  spacing?: InterfaceStack['spacing'];
}

function ListItem({
  icon: Icon,
  content,
  iconSize = 'medium',
  contentSize,
  neutralIcon,
  spacing,
}: Readonly<ListItemProps>): JSX.Element {
  return (
    <Stack align="flex-start" spacing={spacing}>
      <Stack.Item>
        <div
          className={`stratus--list-item-icon${neutralIcon ? '-neutral' : ''}`}
        >
          <Icon size={iconSize} />
        </div>
      </Stack.Item>
      <Text size={contentSize}>{content}</Text>
    </Stack>
  );
}

export default ListItem;
