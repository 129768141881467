import { Card, Input, Title } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  const title = t('locations.form.informations.title');
  return (
    <Card>
      <Card.Header>
        <Title as="h3">{title}</Title>
      </Card.Header>
      <Card.Body>
        <Input.Skeleton />
      </Card.Body>
    </Card>
  );
}

export default Skeleton;
