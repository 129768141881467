import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getDeliveryMethodsState } from './deliveryMethodsSelectors';

export const getInternationalShippingSelector = (state: RootStateType) =>
  getDeliveryMethodsState(state).internationalShipping;

const getInternationalShippingList = (state: RootStateType) =>
  getInternationalShippingSelector(state).list;

export const getInternationalShippingListData = (state: RootStateType) =>
  getInternationalShippingList(state).data;

export const getInternationalShippingListStatus = (state: RootStateType) => {
  const status = getInternationalShippingList(state).status;
  return convertStatusTypeToObject(status);
};

export const getDeleteInternationalShipping = (state: RootStateType) =>
  getInternationalShippingSelector(state).delete;

export const getDeleteInternationalShippingStatus = (state: RootStateType) =>
  getDeleteInternationalShipping(state).status;

export const newInternationalShipping = (state: RootStateType) =>
  getInternationalShippingSelector(state).create;
export const newInternationalShippingStatus = (state: RootStateType) =>
  newInternationalShipping(state).status;

export const getDetailInternationalShippingData = (state: RootStateType) =>
  getInternationalShippingSelector(state).detail.data;

export const getDetailInternationalShippingStatus = (state: RootStateType) => {
  const status = getInternationalShippingSelector(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const getUpdateInternationalShippingDetailStatus = (
  state: RootStateType,
) => getInternationalShippingSelector(state).update.status;
