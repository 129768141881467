import { Redirect } from 'react-router';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { useEnableMercadoPago } from '../../hooks';

function EnableMercadoPagoPage(): JSX.Element | null {
  const { updateStatus } = useEnableMercadoPago();

  if (updateStatus !== 'success') return null;

  return <Redirect to={PAYMENTS_BASE_ROUTE} />;
}

export default EnableMercadoPagoPage;
