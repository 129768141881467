import { DataTable, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function DraftOrderListHeaderDesktop() {
  const t = useTranslationOrders();
  return (
    <DataTable.Header>
      <DataTable.Cell grow={0} basis={120}>
        <Text>{t('draftOrders.list.table.number')}</Text>
      </DataTable.Cell>
      <DataTable.Cell grow={0} basis={80}>
        <Text>{t('draftOrders.list.table.date')}</Text>
      </DataTable.Cell>
      <DataTable.Cell grow={1} basis={100}>
        <Text>{t('draftOrders.list.table.customer')}</Text>
      </DataTable.Cell>
      <DataTable.Cell grow={0} basis={200}>
        <Text>{t('draftOrders.list.table.total')}</Text>
      </DataTable.Cell>
      <DataTable.Cell grow={0} basis={200}>
        <Text>{t('draftOrders.list.table.products')}</Text>
      </DataTable.Cell>
      <DataTable.Cell grow={0} basis={125}>
        <Text>{t('draftOrders.list.table.actions')}</Text>
      </DataTable.Cell>
    </DataTable.Header>
  );
}

export default DraftOrderListHeaderDesktop;
