import { Box, Text } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function Headers() {
  const t = useTranslationCatalog('products.table.header');
  return (
    <Box
      display="grid"
      gridTemplateColumns="110px 110px 110px"
      gridGap="2"
      pl="1"
    >
      <Text fontSize="caption" color="neutral-textLow">
        {t('stock')}
      </Text>
      <Text fontSize="caption" color="neutral-textLow">
        {t('price')}
      </Text>
      <Text fontSize="caption" color="neutral-textLow">
        {t('promotionalPrice')}
      </Text>
    </Box>
  );
}

export default Headers;
