import {
  Input,
  InterfaceInput,
  InterfaceNameValue,
} from '@tiendanube/components';

interface InputPhoneProps extends Omit<InterfaceInput, 'onChange'> {
  onChange: (event: InterfaceNameValue) => void;
}

const PHONE_NUMBER_REGEX = /^\+?(?:[0-9]?){0,14}[0-9]$/;

function isValidPhoneNumber(value: string): boolean {
  return PHONE_NUMBER_REGEX.test(value) || value === '' || value === '+';
}

function InputPhone({ onChange, ...props }: InputPhoneProps) {
  const handleOnChange = ({ name, value }: InterfaceNameValue) => {
    if (isValidPhoneNumber(value)) {
      onChange({ name, value });
    }
  };
  return <Input onChange={handleOnChange} {...props} />;
}

export default InputPhone;
