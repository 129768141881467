import { useCallback } from 'react';
import { OcaCreateLabelRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { createOcaLabels as createOcaLabelsAction } from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';

export interface UseCreateOcaLabelsResult {
  createOcaLabels: (labels: OcaCreateLabelRequestDto[]) => Promise<void>;
}

function useCreateOcaLabels(): UseCreateOcaLabelsResult {
  const dispatch = useAppDispatch();

  const createOcaLabels = useCallback(
    async (labels: OcaCreateLabelRequestDto[]) => {
      await dispatch(createOcaLabelsAction(labels));
    },
    [dispatch],
  );

  return {
    createOcaLabels,
  };
}

export default useCreateOcaLabels;
