import { IconButton, Text } from '@nimbus-ds/components';
import { DesktopIcon, LocationIcon, UserIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { Repeater, Stack } from 'commons/components';
import { ICON_SIZE } from '../../../settings';

export function UserSessionItemMobileSkeleton(): JSX.Element {
  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="1" spacing="tight">
        <Stack justify="space-between" align="center">
          <Stack spacing="tight">
            <LocationIcon size={ICON_SIZE} /> <Text.Skeleton width="170px" />
          </Stack>
          <Stack spacing="tight">
            <IconButton.Skeleton height="2rem" width="2rem" />
          </Stack>
        </Stack>
        <Stack spacing="tight">
          <UserIcon size={ICON_SIZE} /> <Text.Skeleton width="170px" />
        </Stack>
        <Stack spacing="tight">
          <DesktopIcon size={ICON_SIZE} /> <Text.Skeleton width="170px" />
        </Stack>
        <Text.Skeleton width="180px" />
      </Stack>
    </DataList.Row>
  );
}

const itemSkeleton = () => <UserSessionItemMobileSkeleton />;

export function UserSessionsListMobileSkeleton() {
  return <Repeater times={4} item={itemSkeleton} />;
}
