import { Icon } from '@nimbus-ds/components';
import MenuButton from '@nimbus-ds/menubutton';
import { LocationResponseDto } from '@tiendanube/common';
import { CheckIcon } from '@tiendanube/icons';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface LocationEntryProps {
  location: LocationResponseDto;
  isSelected: boolean;
  onSelectionChange: (locationId: LocationResponseDto) => void;
}

function LocationEntry({
  location,
  isSelected,
  onSelectionChange,
}: LocationEntryProps): JSX.Element {
  const handleClick = () => !isSelected && onSelectionChange(location);
  const t = useTranslationCatalog();
  const locationName = location.main
    ? `${location.name} (${t('inventory.mainLocation')})`
    : location.name;
  return (
    <MenuButton label={locationName} active={isSelected} onClick={handleClick}>
      {isSelected && (
        <Icon color="primary-interactive" source={<CheckIcon />} />
      )}
    </MenuButton>
  );
}

export default LocationEntry;
