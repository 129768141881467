import { OwnerResourceType } from '@tiendanube/common';
import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import RenderMetafieldWhen from '../../RenderMetafieldWhen';

type ConditionsInterface = {
  hasInternals: boolean;
  hasExternals: boolean;
  isAvailable: boolean;
  isFilterAvailable: boolean;
};

interface RenderProductsAndVariantsMetafieldWhenProps {
  conditions: ConditionsInterface[];
  resource?: OwnerResourceType;
  children: React.ReactNode;
}

export default function RenderProductsAndVariantsMetafieldWhen({
  conditions,
  resource,
  children,
}: Readonly<RenderProductsAndVariantsMetafieldWhenProps>) {
  const { isAvailable, isFilterAvailable, hasInternals, hasExternals } =
    useGetProductsAndVariantsMetafields(resource);
  const values = { isAvailable, isFilterAvailable, hasInternals, hasExternals };

  return (
    <RenderMetafieldWhen values={values} conditions={conditions}>
      {children}
    </RenderMetafieldWhen>
  );
}
