/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Badge, Box, Chip, Skeleton, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceIdLabel } from '@tiendanube/components';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import { ORDERS_ALLOW_PRODUCT_NAME_FILTER } from 'App/featuresFlags';
import { useResponsive, SearchFilter } from 'commons/components';
import { useModal } from 'commons/hooks';
import { dateFormat, Format, ninetyDaysAgo } from 'commons/utils/date';
import { ORDERS_SEARCH_ORDERS } from 'config/upsellFlowSources';
import { useGetTags } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import formatDateFilterChips from 'domains/Metafields/utils/formatDateFilterChips';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import useGetOrdersFilters from 'domains/Orders/Orders/hooks/useGetOrdersFilters/useGetOrdersFilters';
import {
  FiltersParamsType,
  FilterStatusEnum,
} from 'domains/Orders/Orders/ordersService';
import { getOrdersAggregations } from 'domains/Orders/Orders/ordersSlice';
import { useOrdersMetafields } from 'domains/Orders/Orders/pages/hooks';
import { checkCustomPaymentProvider } from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import {
  trackingOrderSearch,
  trackingOrderListFilterClick,
} from 'domains/Orders/tracking';
import { useGetShippingMethods } from 'domains/Shipping/DeliveryMethods/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import FilterModal from './FilterModal';
import { useFilterSelectedProducts } from '../../hooks/useFilterSelectedProducts';

interface OrderListFilterProps {
  filters: FiltersParamsType;
  ordersCount: number;
  isLoading: boolean;
  onChange: (filters: FiltersParamsType) => void;
}

interface InterfaceIdLabelBadge extends InterfaceIdLabel {
  badgeCount: number;
}

function OrderListFilter({
  filters,
  ordersCount,
  isLoading,
  onChange,
}: OrderListFilterProps): JSX.Element | null {
  const { t } = useTranslation([Domain.ORDERS]);
  const { shippingMethods, fetchShippingMethods } = useGetShippingMethods();
  const { locations, fetchLocations } = useLocations();
  const {
    paymentProvidersOrdersFilters,
    applicationOrdersFilters,
    fetchOrdersFilters,
  } = useGetOrdersFilters();
  const { statusSuccess } = useOrdersList();

  const [showModal, onOpenModal, onCloseModal] = useModal();
  const { isDesktop } = useResponsive();
  const tags = useGetTags();
  const { fetchMetafields } = useOrdersMetafields();

  const aggregations = useSelector(getOrdersAggregations);
  const [query, setQuery] = useState(filters.q || '');
  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const {
    selectedProducts,
    setSelectedProducts,
    serializeProducts,
    deserializeProducts,
  } = useFilterSelectedProducts(filters.products, aggregations);

  const handleOpenModal = () => {
    onOpenModal();
    trackingOrderListFilterClick();
  };

  const handleChangeText = useUpsellFlow({
    title: t('filter.upsell.search'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SEARCH_ORDERS,
    callback: (text: string) => {
      onChange({ ...filters, status: FilterStatusEnum.ALL, q: text });
      trackingOrderSearch(text);
    },
  });

  const handleDismiss = (key: string) => {
    const hasFiltersWithDateRestrictionsApplied =
      filters.paymentMethods !== '' ||
      filters.paymentProvider !== '' ||
      filters.appId !== '';

    const dateAndPaymentsFilter =
      hasFiltersWithDateRestrictionsApplied && key === 'dateFrom';
    const dateAfterNinetyDays = filters.dateFrom > ninetyDaysAgo;

    if (dateAndPaymentsFilter && !dateAfterNinetyDays) return;

    let newValue: string;

    if (key.startsWith('variant-')) {
      const variantId = key.split('-')[1];
      newValue = serializeProducts(
        selectedProducts.filter((p) => p.variantId !== variantId),
      );
      setSelectedProducts((prev) =>
        prev.filter((p) => p.variantId !== variantId),
      );
      key = 'products';
    } else {
      newValue =
        dateAndPaymentsFilter && dateAfterNinetyDays ? ninetyDaysAgo : '';
    }

    onChange({ ...filters, [key]: newValue });
  };
  const withoutResult = statusSuccess && ordersCount === 0;

  const appliedFilters: InterfaceIdLabel[] = Object.entries(filters)
    .filter(
      ([key, value]) =>
        (value !== '' &&
          key !== 'page' &&
          key !== 'perPage' &&
          value !== FilterStatusEnum.ALL &&
          value !== 'open') ||
        (key === 'status' && value === FilterStatusEnum.ALL),
    )
    .flatMap(([key, value]) => {
      if (key === 'shippingMethod') {
        const label = Object.values(shippingMethods)
          .flat()
          .find(({ code }) => code === value)?.name;
        return { id: key, label: label };
      }
      if (key === 'location') {
        const label = Object.values(locations)
          .flat()
          .find(({ id }) => id === value)?.name;
        return { id: key, label: label };
      }
      if (checkCustomPaymentProvider(key, value)) {
        const label = t(`filters.paymentProvider.${value}`);
        return { id: key, label: label };
      }
      if (key === 'paymentProvider' && paymentProvidersOrdersFilters) {
        const label =
          paymentProvidersOrdersFilters &&
          Object.values(paymentProvidersOrdersFilters)
            .flat()
            .find(({ id }) => id === value)?.name;
        return { id: key, label: label };
      }
      if (key === 'dateFrom') {
        const label = t('appliedFilters.dateFrom', {
          dateFrom: dateFormat(value as string, Format.DAY_MONTH_YEAR_NUMBER),
        });
        return { id: key, label: label };
      }
      if (key === 'dateTo') {
        const label = t('appliedFilters.dateTo', {
          dateTo: dateFormat(value as string, Format.DAY_MONTH_YEAR_NUMBER),
        });
        return { id: key, label: label };
      }
      if (key === 'status' && value === FilterStatusEnum.ALL) {
        const label = t(`filters.status.${value}`);
        return { id: key, label: label };
      }
      if (key === 'paymentMethods') {
        return {
          id: key,
          label: t(`filters.paymentMethod.${value}`),
        };
      }
      if (key === 'appId' && applicationOrdersFilters) {
        const label = Object.values(applicationOrdersFilters)
          .flat()
          .find(({ id }) => id === value)?.name;
        return { id: key, label: label };
      }
      if (key === 'productsCount') {
        return {
          id: key,
          label: `${t(`filter.productsCount`)}: ${value}`,
        };
      }
      if (key === 'products') {
        const deserializedProducts = deserializeProducts(value);
        return aggregations?.['variants']
          ?.map((product) => {
            if (
              deserializedProducts.find(
                (dp) => dp.variantId === product.key.toString(),
              )
            ) {
              return product;
            }
            return undefined;
          })
          .filter((p) => !!p && !!p.label)
          .map((product) => {
            // Product should always be set but typescript check doesn't realize it
            if (!product) {
              return { id: '', label: '' };
            }
            return {
              id: `variant-${product.key}`,
              label: product.label,
              badgeCount: product.total,
            };
          });
      }

      return {
        id: key,
        label: t(`appliedFilters.${value}`, value as string),
      };
    })
    .filter((f) => !!f);

  const desktopPlaceholder = tags.includes(ORDERS_ALLOW_PRODUCT_NAME_FILTER)
    ? t('filter.inputLabelDesktopProductName')
    : t('filter.inputLabelDesktop');

  const placeholder = isDesktop ? desktopPlaceholder : t('filter.inputLabel');

  const resultCount = isLoading
    ? ''
    : appliedFilters.length > 0
    ? t('filter.result', { count: ordersCount })
    : t('filter.resultOpened', { count: ordersCount });

  const resultCountLabel = withoutResult ? '' : resultCount;
  const label = isDesktop ? t('filter.inputAriaLabel') : '';

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  useEffect(() => {
    fetchShippingMethods();
  }, [fetchShippingMethods]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    fetchOrdersFilters();
  }, [fetchOrdersFilters]);

  useEffect(() => {
    setQuery(filters.q ?? '');
  }, [filters.q]);

  return (
    <>
      <SearchFilter
        placeholder={placeholder}
        onSubmit={handleChangeText}
        onClick={handleOpenModal}
        onDismiss={handleDismiss}
        appliedFilters={[]}
        ariaLabel={t('filter.inputAriaLabel')}
        label={label}
        onChange={handleChangeQuery}
        value={query}
      />
      <Box display="flex" flexWrap="wrap" gap="2">
        {isLoading && <Skeleton width="120px" height="16px" />}
        {!!resultCountLabel && <Text>{resultCountLabel}</Text>}
        {!isLoading &&
          appliedFilters.map((filter) => {
            const isMetafieldFilter = filter.id.startsWith(
              METAFIELD_FILTER_PREFIX,
            );
            const isRemovable =
              filter.id !== 'dateFrom' ||
              filters.dateFrom > ninetyDaysAgo ||
              (filters.paymentMethods === '' &&
                filters.paymentProvider === '' &&
                filters.appId === '');
            return (
              <Box key={filter.id}>
                <Chip
                  text={
                    isMetafieldFilter
                      ? formatDateFilterChips(filter.label)
                      : filter.label
                  }
                  icon={
                    (filter as InterfaceIdLabelBadge).badgeCount !==
                    undefined ? (
                      <Badge
                        appearance="neutral"
                        count={(filter as InterfaceIdLabelBadge).badgeCount}
                      />
                    ) : undefined
                  }
                  removable={isRemovable}
                  onClick={() => handleDismiss(filter.id)}
                />
              </Box>
            );
          })}
      </Box>
      <FilterModal
        show={showModal}
        hideModal={onCloseModal}
        filter={filters}
        addParam={onChange}
        selectedProducts={selectedProducts}
        addSelectedProductParam={setSelectedProducts}
        serializeProducts={serializeProducts}
      />
    </>
  );
}

export default OrderListFilter;
