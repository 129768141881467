import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CheckoutPaymentOptionsRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanCheckoutPaymentOptionsStatusAction,
  getCheckoutPaymentOptionsAction,
  getCheckoutPaymentOptionsData,
  getCheckoutPaymentOptionsStatus,
  getCheckoutPaymentOptionUpdateStatus,
  updateCheckoutPaymentOptionsAction,
} from '../../paymentProvidersSlice';
import useInstalledPaymentProviders from '../useInstalledPaymentProviders';

function useCheckoutPaymentOptions() {
  const dispatch = useAppDispatch();
  const checkoutPaymentOptions = useSelector(getCheckoutPaymentOptionsData);
  const checkoutPaymentOptionsUpdateStatus = useSelector(
    getCheckoutPaymentOptionUpdateStatus,
  );
  const { isSuccess, isLoading, isError } = useSelector(
    getCheckoutPaymentOptionsStatus,
  );

  const { fetchInstalledPaymentProviders } = useInstalledPaymentProviders();

  const fetchCheckoutPaymentOptions = useCallback(() => {
    dispatch(getCheckoutPaymentOptionsAction());
  }, [dispatch]);

  const updateCheckoutPaymentOptions = useCallback(
    async (payload: CheckoutPaymentOptionsRequestDto) => {
      await dispatch(updateCheckoutPaymentOptionsAction(payload));
      fetchInstalledPaymentProviders();
      dispatch(cleanCheckoutPaymentOptionsStatusAction());
    },
    [dispatch, fetchInstalledPaymentProviders],
  );

  return {
    isSuccess,
    isLoading,
    isError,
    checkoutPaymentOptions,
    checkoutPaymentOptionsUpdateStatus,
    fetchCheckoutPaymentOptions,
    updateCheckoutPaymentOptions,
  };
}

export default useCheckoutPaymentOptions;
