/**
 * VITE_BUILD_PERFORMANCE_CI environment variable
 * defined to indicate that the build is being
 * built through CircleCI from the job performnace
 * for an analysis through Lighthouse
 *
 * @returns boolean
 */
export function getIsBuildPerformanceCi(): boolean {
  return !!import.meta.env.VITE_BUILD_PERFORMANCE_CI;
}
