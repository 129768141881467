import {
  GetDocumentSettingsResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import './DocumentPreview.scss';
import { useResponsive } from 'commons/components';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import GeneralDocumentPreviewFields from './GeneralDocumentPreviewFields';
import OcaLabelError from '../../../OcaLabelError';

interface DocumentPreviewProps {
  type: DocumentType;
  information: InformationForDocumentResponseDto[];
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
  ocaLabels: OcaLabelResponseDto[];
  hasOca: boolean;
}

function DocumentPreview({
  type,
  information,
  settings,
  locationId,
  ocaLabels,
  hasOca,
}: Readonly<DocumentPreviewProps>) {
  const { isMobile } = useResponsive();
  return (
    <>
      {information
        .filter(
          ({ fulfillmentOrder }) =>
            !locationId || fulfillmentOrder.assignedLocation.id === locationId,
        )
        .map(({ order, fulfillmentOrder, store }) => {
          const ocaLabel = ocaLabels.find(
            (ocaLabel) => ocaLabel.externalId === order.id,
          );
          const shouldRenderOca = hasOca && ocaLabel && !isMobile;
          return (
            <>
              {shouldRenderOca && (
                <div
                  key={fulfillmentOrder.number + order.number}
                  className="stratus--order-document oca"
                >
                  <div className="oca-document">
                    <GeneralDocumentPreviewFields
                      fulfillmentOrder={fulfillmentOrder}
                      order={order}
                      settings={settings}
                      store={store}
                      isOca
                    />
                  </div>
                  {!ocaLabel.error && !!ocaLabel.html && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ocaLabel.html,
                      }}
                    />
                  )}
                  {(!!ocaLabel.error ||
                    (!ocaLabel.error && !ocaLabel.html)) && (
                    <div className="error">
                      <OcaLabelError error={ocaLabel.error} />
                    </div>
                  )}
                </div>
              )}
              {!shouldRenderOca && (
                <div
                  key={fulfillmentOrder.number + order.number}
                  className={`stratus--order-document ${type}${
                    isMobile ? '-mobile' : ''
                  } `}
                >
                  <GeneralDocumentPreviewFields
                    fulfillmentOrder={fulfillmentOrder}
                    order={order}
                    settings={settings}
                    store={store}
                  />
                </div>
              )}
            </>
          );
        })}
    </>
  );
}

export default DocumentPreview;
