import { Text, IconButton, Tag } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Repeater, Stack } from 'commons/components';

function ItemSkeleton() {
  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="3">
        <Stack justify="space-between" align="center">
          <Stack.Item>
            <Text.Skeleton width="130px" height="16px" />
            <Text.Skeleton width="130px" height="16px" />
          </Stack.Item>
          <IconButton.Skeleton height="2rem" width="2rem" />
        </Stack>
        <Stack column spacing="tight" align="stretch">
          <Tag.Skeleton height="20px" />
          <Text.Skeleton width="80px" height="16px" />
        </Stack>
      </Stack>
    </DataList.Row>
  );
}

const itemSkeleton = () => <ItemSkeleton />;

export function PromotionsListMobileSkeleton() {
  return <Repeater times={4} item={itemSkeleton} />;
}
