import { useSelector } from 'react-redux';
import {
  isUserLogin,
  isAuthInit,
  isAuthCodeError as isAuthCodeErrorSelector,
} from '../../authSlice/authSelectors';

interface InterfaceUseIsLogged {
  isLogin: boolean;
  isInit: boolean;
  isAuthCodeError: boolean;
}

function useIsLogged(): InterfaceUseIsLogged {
  const isLogin = useSelector(isUserLogin);
  const isInit = useSelector(isAuthInit);
  const isAuthCodeError = useSelector(isAuthCodeErrorSelector);
  return { isLogin, isInit, isAuthCodeError };
}

export default useIsLogged;
