import { useEffect } from 'react';
import amplitude from 'amplitude-js';
import { useGetPlan, useGetUserInfo } from 'domains/Auth/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { config } from './config';

function Amplitude(): null {
  const { country, name, id } = useStoreInfo();
  const { planId } = useGetPlan();
  const { email } = useGetUserInfo();

  useEffect(() => {
    const userProperties = {
      store_id: id,
      country,
      name,
      plan: planId,
      email: email,
    };

    amplitude.getInstance().init(config.apiKey);
    amplitude.getInstance().setUserProperties(userProperties);
    amplitude.getInstance().setUserId(id);

    amplitude
      .getInstance(config.nuvempagoInstanceName)
      .init(config.nuvempagoApiKey);
    amplitude
      .getInstance(config.nuvempagoInstanceName)
      .setUserProperties(userProperties);
    amplitude.getInstance(config.nuvempagoInstanceName).setUserId(id);

    return () => {
      amplitude.getInstance().clearUserProperties();
      amplitude.getInstance(config.nuvempagoInstanceName).clearUserProperties();
    };
  }, [country, id, name, planId, email]);

  return null;
}

export default Amplitude;
