import { useMemo } from 'react';
import { UsersListItemResponseDto } from '@tiendanube/common';
import { useListMobile } from 'App/hooks';
import { UsersEndpoints } from 'domains/Configurations/Users/usersApi';

function useUsersListMobile() {
  const result = useListMobile<UsersListItemResponseDto>({
    filters: { perPage: '20' },
    endpoint: UsersEndpoints.getUsersListInf,
  });

  const ids = useMemo(
    () => result.data.map((current) => current.id),
    [result.data],
  );
  return { ...result, ids };
}

export default useUsersListMobile;
