import React from 'react';
import { Text, Modal, InterfaceButton } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface ModalConfirmationBaseProps {
  show: boolean;
  title?: string;
  text?: React.ReactElement | string;
  label?: string;
  labelCancel?: string;
  appearance?: InterfaceButton['appearance'];
  onConfirm: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

function ModalConfirmationBase({
  show,
  title,
  text,
  label,
  disabled,
  labelCancel,
  appearance,
  onConfirm,
  onCancel,
}: ModalConfirmationBaseProps): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <Modal
      title={title || t('common:exitEdit.title')}
      show={show}
      onDismiss={onCancel}
      primaryAction={{
        children: label || `${t('common:exitEdit.confirm')}`,
        onClick: onConfirm,
        disabled,
        appearance: appearance || 'danger',
      }}
      secondaryAction={{
        children: labelCancel || `${t('common:exitEdit.continue')}`,
        onClick: onCancel,
      }}
    >
      {['string', 'undefined'].includes(typeof text) ? (
        <Text>{text || t('common:exitEdit.info')}</Text>
      ) : (
        text
      )}
    </Modal>
  );
}

export default ModalConfirmationBase;
