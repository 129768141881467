import { Table } from '@nimbus-ds/components';
import { FreeShippingItemListResponseDto } from '@tiendanube/common';
import {
  FreeShippingListTableSkeleton,
  FreeShippingListRow,
  FreeShippingListHeader,
} from './components';

interface FreeShippingListTableProps {
  data: FreeShippingItemListResponseDto[];
}

function FreeShippingListTable({ data }: Readonly<FreeShippingListTableProps>) {
  return (
    <Table>
      <FreeShippingListHeader />
      <Table.Body>
        {data.map((freeShipping) => (
          <FreeShippingListRow
            freeShipping={freeShipping}
            key={freeShipping.id}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

FreeShippingListTable.Skeleton = FreeShippingListTableSkeleton;

export default FreeShippingListTable;
