import { useCallback } from 'react';
import { Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { useToastStatus } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useGetRecurrentPaymentConcepts } from 'domains/Billing/Checkout/hooks';
import { useCancelRecurrentPayment } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import {
  trackingBillingRecurringCancel,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface CancelRecurrentPaymentModalProps {
  onClose: () => void;
}

function CancelRecurrentPaymentModal({
  onClose,
}: CancelRecurrentPaymentModalProps): JSX.Element {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.withRP.modal',
  );

  const { recurrentPaymentForTransactionFeesActivated } =
    useGetRecurrentPaymentConcepts();

  const { status, resetStatus, isLoading, cancelRecurrentPayment } =
    useCancelRecurrentPayment();

  const logEvent = useCheckoutTracking();
  const handleCancel = () => {
    trackingBillingRecurringCancel(logEvent);
    cancelRecurrentPayment({
      concepts: [
        { code: 'plan-cost' },
        { code: 'transaction-fee' },
        { code: 'app-cost' },
      ],
    });
  };

  const goToOldCheckout = goToAdmin('/account/checkout/start/');

  const onSuccess = useCallback(() => {
    // TODO: In the future we won't redirect to Old Admin, so we should refresh the recurrent payment data with the next line
    resetStatus();
    goToOldCheckout();
  }, [resetStatus, goToOldCheckout]);

  useToastStatus({
    status,
    error: t('cancelationError'),
    success: t('cancelationSuccess'),
    onSuccess,
    onError: resetStatus,
  });

  const message = recurrentPaymentForTransactionFeesActivated
    ? t('messageWithCptsRpActive')
    : t('messageWithCptsRpDisabled');

  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Text>{message}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={isLoading}>
          {t('abortButton')}
        </Button>
        <Button onClick={handleCancel} appearance="danger" disabled={isLoading}>
          {isLoading && <Spinner color="currentColor" size="small" />}
          {t('continueButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CancelRecurrentPaymentModal;
