import { Link } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface ShowMoreRolesProps {
  hasMoreRoles: boolean;
  showMoreRoles: () => void;
  showLessRoles: () => void;
}

function ShowMoreRoles({
  hasMoreRoles,
  showMoreRoles,
  showLessRoles,
}: Readonly<ShowMoreRolesProps>) {
  const t = useTranslationConfigurations('usersListPage.table');

  if (hasMoreRoles)
    return (
      <Link appearance="primary" textDecoration="none" onClick={showMoreRoles}>
        {t('showMoreRoles')}
      </Link>
    );

  return (
    <Link appearance="primary" textDecoration="none" onClick={showLessRoles}>
      {t('showLessRoles')}
    </Link>
  );
}

export default ShowMoreRoles;
