import { DataList, Text } from '@tiendanube/components';

const { Row, Cell } = DataList;

function TransactionListItemSkeleton(): JSX.Element {
  return (
    <Row id="id">
      <Cell width={70}>
        <Text.Skeleton />
      </Cell>
      <Cell width={30} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={70}>
        <Text.Skeleton width="large" />
      </Cell>
      <Cell width={30} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={70}>
        <Text.Skeleton width="large" />
      </Cell>
      <Cell width={30} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={100} />
    </Row>
  );
}

export default TransactionListItemSkeleton;
