import { ChangeEvent, useEffect } from 'react';
import {
  CustomShippingResponseDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { Stack } from 'commons/components';
import Skeleton from './Skeleton';
import {
  ShippingConditionsCard,
  ShippingInformationCard,
  ShippingLocationCard,
  ShippingTermCard,
} from '../../../components';
import { DeliveryZoneCard } from '../../../NewCustomShippingPage/components';
import { SelectedZonesInterface } from '../../../NewCustomShippingPage/components/DeliveryZoneCard';

interface CustomShippingFormProps {
  values: CustomShippingResponseDto;
  locations: LocationResponseDto[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (name: string, value: any) => void;
  fetchLocations: () => Promise<void>;
  errors?: Partial<Record<string, string>>;
}

function CustomShippingForm({
  values,
  locations,
  handleChange,
  setFieldValue,
  fetchLocations,
  errors,
}: CustomShippingFormProps) {
  const onChangeZone = (deliveryZones: SelectedZonesInterface[]) => {
    setFieldValue('deliveryZones', deliveryZones);
  };

  useEffect(() => {
    if (!locations.length) {
      fetchLocations();
    }
    if (locations.length === 1 && !values.locationId) {
      setFieldValue('locationId', locations[0]?.id || '');
    }
  }, [fetchLocations, setFieldValue, locations, values]);

  return (
    <Stack column>
      <ShippingInformationCard
        name={values.name}
        deliveryCost={values.deliveryCost}
        cost={values.cost}
        showAsPickUpPoint={values.showAsPickUpPoint}
        allowedForFreeShipping={values.allowedForFreeShipping}
        onChange={handleChange}
        error={errors && errors.name}
      />
      {locations.length > 1 && (
        <ShippingLocationCard
          locations={locations}
          locationId={values.locationId}
          error={errors && errors.locationId}
          onChange={handleChange}
        />
      )}
      <ShippingConditionsCard
        onChange={handleChange}
        weightMin={values.cartWeightMin}
        weightMax={values.cartWeightMax}
        valueMin={values.cartValueMin}
        valueMax={values.cartValueMax}
        error={errors}
      />
      <DeliveryZoneCard
        onChange={onChangeZone}
        selectedZones={values.deliveryZones}
      />
      <ShippingTermCard
        timeMin={values.deliveryTimeMin}
        timeMax={values.deliveryTimeMax}
        onChange={handleChange}
        error={errors}
      />
    </Stack>
  );
}

CustomShippingForm.Skeleton = Skeleton;

export default CustomShippingForm;
