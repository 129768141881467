import { useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { useCloneProductJobs } from 'App/settings/jobs/hooks';
import ScrollToTop from 'commons/components/ScrollTop';
import ProductDetailsError from './components/ProductDetailsError';
import ProductDetailsPage from './components/ProductDetailsPage';
import ProductDetailsSkeleton from './components/ProductDetailsSkeleton';
import useProductDetails from './hooks/useProductDetails';
import { StockHistoryModal } from '../components';
import { useStockHistoryContext } from '../components/StockHistoryModal/hooks';

function EditProductPageWithUseParams(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  return <EditProductPageMemo id={id} />;
}

const EditProductPageMemo = memo(EditProductPage);

function EditProductPage({ id }: { id: string }): JSX.Element | null {
  const { fetchProduct, loading, error, product } = useProductDetails(id);
  const { getJobs, checkJob } = useCloneProductJobs(id);

  const { variantStock, isOpen, closeStockHistoryModal } =
    useStockHistoryContext();

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  useEffect(() => {
    checkJob();
  }, [checkJob]);

  if (error) {
    return <ProductDetailsError />;
  }

  if (loading) {
    return <ProductDetailsSkeleton />;
  }

  if (product === null) {
    return null;
  }

  return (
    <>
      <ScrollToTop />
      <ProductDetailsPage />
      {variantStock && (
        <StockHistoryModal
          productId={variantStock.productId}
          variantId={variantStock.variantId}
          variantName={variantStock.variantName}
          onClose={closeStockHistoryModal}
          isOpen={isOpen}
        />
      )}
    </>
  );
}

export default EditProductPageWithUseParams;
