import { HeaderContent, IonPageStratus, Responsive } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { InvoiceHistoryWeb, InvoiceHistoryMobile } from './components';

function InvoiceHistory(): JSX.Element {
  const t = useTranslationBilling();

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('invoiceHistory.title')} />}
    >
      <Responsive
        mobile={<InvoiceHistoryMobile />}
        desktop={<InvoiceHistoryWeb />}
      />
    </IonPageStratus>
  );
}

export default InvoiceHistory;
