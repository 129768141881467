import { useState } from 'react';
import { Icon, Text, Link, Box } from '@nimbus-ds/components';
import { ChevronUpIcon, ChevronDownIcon } from '@nimbus-ds/icons';
import {
  ProductDetailsResponseDto,
  SummaryResponseDto,
} from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { LanguagesType } from 'App/i18n';
import { useHasCatalogInventory } from 'domains/Auth/hooks';
import { useVariantsRange } from 'domains/Catalog/Inventory/pages/hooks';
import { useSummary } from 'domains/Catalog/Products/pages/hooks';
import {
  createTextVariantsPlace,
  hasVariants,
} from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { MAX_ENTRIES } from '../../ProductResultsDesktop/components/utils';
import ImageItemVariant from '../ImageItemVariant';
import TableValuesVariant from '../TableValuesVariant';

import './ContentProductItemRow.scss';

const { Row, Cell } = DataList;

const getStock = (
  product: ProductDetailsResponseDto,
  summary: SummaryResponseDto | null,
) => {
  if (summary) {
    return summary.stock;
  }
  const { variants } = product;
  if (variants.every((variant) => variant.stock === null)) {
    return null;
  }
  return variants.reduce(
    (current, variant) => (variant.stock ? current + variant.stock : current),
    0,
  );
};

interface ContentProductItemRowProps {
  product: ProductDetailsResponseDto;
  language: LanguagesType;
}
function ContentProductItemRow({
  product,
  language,
}: ContentProductItemRowProps) {
  const { goTo } = useNavegate();
  const t = useTranslationCatalog();
  const range = useVariantsRange(product, MAX_ENTRIES);
  const [showVariants, setShowVariants] = useState(false);
  const { locations } = useLocations();
  const hasCatalogInventory = useHasCatalogInventory();
  const hasVariantsProduct = hasVariants(product);

  const summary = useSummary(product.id);

  if (!product) return null;

  const createTextStock = (product: ProductDetailsResponseDto) => {
    const variantsPlaceText =
      hasCatalogInventory &&
      locations.length > 1 &&
      createTextVariantsPlace(product, locations);

    let textStock: string | undefined;

    const stock = getStock(product, summary);
    switch (stock) {
      case null:
        textStock = t('products.itemList.infiniteStock');
        break;
      case 0:
        textStock = t('products.itemList.emptyStock');
        break;
      default:
        textStock = t('products.itemList.unit', { count: stock });
    }

    return (
      <>
        <Text fontSize="base" as="span" color="neutral-textHigh">
          {textStock}
        </Text>
        {variantsPlaceText && (
          <Text as="span" fontSize="base" color="neutral-textDisabled">
            {` ${t(`${variantsPlaceText?.i18key}`, {
              value: variantsPlaceText?.value,
            })}`}
          </Text>
        )}
      </>
    );
  };

  const goToProductDetails = () => {
    goTo(`/products/${product.id}`);
  };

  const linkImage = {
    children: product.name[language],
    onClick: goToProductDetails,
  };

  const thumbnail = product.images[0]?.src ? product.images[0].src : undefined;
  const handleShowVariants = () => {
    const showVariantUpdated = !showVariants;
    setShowVariants(showVariantUpdated);
  };

  const totalVariants = summary ? summary.variants : product.variants.length;
  const stock = createTextStock(product);

  return (
    <div className="stratus--content-product-item-row">
      <Row id={product.id}>
        <Cell width={100}>
          <ImageItemVariant
            link={linkImage}
            product={product}
            thumbnail={thumbnail}
          />
        </Cell>
        {hasVariantsProduct && <Cell width={100}>{stock}</Cell>}
        <Cell width={100}>
          {hasVariantsProduct ? (
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={handleShowVariants}
            >
              {totalVariants === 1
                ? t('products.itemList.variant')
                : t('products.itemList.variants', {
                    total: totalVariants,
                  })}
              <Icon
                color="currentColor"
                source={showVariants ? <ChevronUpIcon /> : <ChevronDownIcon />}
              />
            </Link>
          ) : (
            <TableValuesVariant range={range} product={product} />
          )}
        </Cell>
        {showVariants && hasVariantsProduct && (
          <Cell width={100}>
            <TableValuesVariant product={product} range={range} />
          </Cell>
        )}
        {(!hasVariantsProduct || !showVariants) && <Box marginBottom="1" />}
      </Row>
    </div>
  );
}

export default ContentProductItemRow;
