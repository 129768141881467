import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Title,
  Text,
  Label,
  Textarea,
  Spinner,
} from '@nimbus-ds/components';
import {
  GenerateCategorySeoResponseDto,
  LanguageType,
} from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Stack } from '@tiendanube/components';
import AiGenerationModal from 'commons/components/AiGenerationModal';
import { AiGenerationResultsCard } from 'commons/components/AiGenerationModal/components';
import useGenerateCategorySeo from 'domains/Catalog/Categories/hooks/useGenerateCategorySeo';
import useGetProductCountForCategory from 'domains/Catalog/Categories/pages/EditCategoryPage/hooks/useGetProductCountForCategory';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

const MIN_PRODUCTS_COUNT_FOR_GENERATION = 3;

export interface SeoCategoriesGenerationModalProps {
  onDismiss: () => void;
  onUseContent: (seoTitle: string, seoDescription: string) => void;
  show?: boolean;
  category: {
    id: string;
    name: string;
    description: string;
    lang: LanguageType;
  };
}

function SeoCategoriesGenerationModal({
  onDismiss,
  onUseContent,
  show = false,
  category,
}: Readonly<SeoCategoriesGenerationModalProps>) {
  const t = useTranslationCatalog();

  const [merchantProvidedContext, setMerchantProvidedContext] = useState('');

  const { generateSeo } = useGenerateCategorySeo();

  const { isLoading, productsCount, fetchProductCount } =
    useGetProductCountForCategory(category.id);

  const insufficientInfo = !category.name;
  const needsMerchantProvidedContext =
    !isLoading && productsCount < MIN_PRODUCTS_COUNT_FOR_GENERATION;

  const handleGenerateContent = useCallback(
    async () =>
      await generateSeo({
        category_id: category.id,
        category_name: category.name,
        category_description: category.description || '',
        merchant_provided_context: merchantProvidedContext,
        lang: category.lang,
      }),
    [generateSeo, category, merchantProvidedContext],
  );

  useEffect(() => {
    if (show && isLoading && !insufficientInfo) {
      fetchProductCount();
    }
  }, [show, isLoading, insufficientInfo, fetchProductCount]);

  const handleChangeContext = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMerchantProvidedContext(e.target.value);
  };

  const handleUseContent = useCallback(
    ({ seo_title, seo_description }: GenerateCategorySeoResponseDto) => {
      onUseContent(seo_title, seo_description);
    },
    [onUseContent],
  );

  return (
    <AiGenerationModal
      show={show}
      feature={AiFeature.AI_CATEGORIES_SEO}
      insufficientInfo={insufficientInfo}
      insufficientInfoError={t(
        'categories.detail.seoCategory.aiGeneration.insufficientInfoError',
      )}
      generateOnOpen={
        !isLoading && productsCount >= MIN_PRODUCTS_COUNT_FOR_GENERATION
      }
      showCtas={!isLoading}
      disabledGenerateCta={!merchantProvidedContext}
      onDismiss={onDismiss}
      onGenerateContent={handleGenerateContent}
      onUseContent={handleUseContent}
      feedbackTitle={t(
        'categories.detail.seoCategory.aiGeneration.feedbackModal.title',
      )}
      feedbackText={t(
        'categories.detail.seoCategory.aiGeneration.feedbackModal.text',
      )}
      extraControls={
        <>
          {isLoading && (
            <AiGenerationResultsCard
              alignItems="center"
              justifyContent="center"
            >
              <Stack>
                <Stack.Item>
                  <Spinner size="small" />
                </Stack.Item>

                <Stack.Item>
                  <Text color="neutral-textHigh">
                    {t(
                      'categories.detail.seoCategory.aiGeneration.fetchingProducts',
                    )}
                  </Text>
                </Stack.Item>
              </Stack>
            </AiGenerationResultsCard>
          )}
          {!isLoading && needsMerchantProvidedContext && (
            <Stack column align="stretch" spacing="tight">
              <Label htmlFor="merchant-provided-context">
                {t(
                  'categories.detail.seoCategory.aiGeneration.merchantProvidedContextLabel',
                )}
              </Label>
              <Textarea
                id="merchant-provided-context"
                name="merchant-provided-context"
                value={merchantProvidedContext}
                lines={5}
                onChange={handleChangeContext}
              />
            </Stack>
          )}
        </>
      }
      previewGeneratedContent={({
        seo_title,
        seo_description,
      }: GenerateCategorySeoResponseDto) => (
        <>
          <Box>
            <Title as="h6">
              {t('categories.detail.seoCategory.aiGeneration.seo_title')}
            </Title>
            <Text color="primary-textLow">{seo_title}</Text>
          </Box>

          <Box marginTop="2">
            <Title as="h6">
              {t('categories.detail.seoCategory.aiGeneration.seo_description')}
            </Title>
            <Text color="primary-textLow">{seo_description}</Text>
          </Box>
        </>
      )}
    />
  );
}

export default SeoCategoriesGenerationModal;
