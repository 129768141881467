import { useAppDispatch } from 'App/store';
import {
  themesApi,
  themesTags,
  useLazyGetFtpStatusQuery,
} from 'domains/Online/Themes/themesApi';

export function useCheckFtpStatus() {
  const dispatch = useAppDispatch();
  const [fetchFtpStatus] = useLazyGetFtpStatusQuery();

  const checkFtpStatus = async ({
    status,
    onSuccess,
  }: {
    status: 'open' | 'closed' | 'disabled';
    onSuccess: () => void;
  }) => {
    const { data } = await fetchFtpStatus();
    if (data?.status === status) {
      dispatch(themesApi.util.invalidateTags([themesTags.themesConfig]));
      onSuccess();
    } else setTimeout(() => checkFtpStatus({ status, onSuccess }), 2000);
  };

  return {
    checkFtpStatus,
  };
}
