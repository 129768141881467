import { Badge, Box, Tag, Text } from '@nimbus-ds/components';
import { FreeShippingItemZoneDto } from '@tiendanube/common';
import { Stack } from 'commons/components';

interface DeliveryZonesItemProps {
  zones: FreeShippingItemZoneDto[];
}

export function DeliveryZonesItem({ zones }: Readonly<DeliveryZonesItemProps>) {
  return (
    <Box display="flex" flexWrap="wrap" gap="2" justifyContent="flex-start">
      {zones.map(({ name, count }, key) => (
        <Tag key={key}>
          <Stack spacing="tight">
            <Text lineClamp={1}>{name}</Text>
            {!!count && <Badge appearance="primary" count={count} />}
          </Stack>
        </Tag>
      ))}
    </Box>
  );
}
