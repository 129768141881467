import { useCallback, useEffect, useMemo } from 'react';
import { PaymentProviderDto } from '@tiendanube/common';
import useInstalledPaymentProviders from '../useInstalledPaymentProviders';
import useNotInstalledPaymentProviders from '../useNotInstalledPaymentProviders';

interface UseGetNativePaymentProviderResult<T extends PaymentProviderDto> {
  isInstalled: boolean;
  isLoading: boolean;
  isError: boolean;
  paymentProvider?: T;
  fetch: () => void;
}

function useGetNativePaymentProvider<T extends PaymentProviderDto>(
  id: string,
): UseGetNativePaymentProviderResult<T> {
  const {
    isError: isNotInstalledError,
    isLoading: isNotInstalledLoading,
    notInstalledPaymentProviders,
    fetchNotInstalledPaymentProviders,
  } = useNotInstalledPaymentProviders();
  const {
    isLoading: isInstalledLoading,
    isError: isInstalledError,
    paymentProviders: installedPaymentProviders,
    fetchInstalledPaymentProviders,
  } = useInstalledPaymentProviders();

  const installedPaymentProvider = useMemo(() => {
    const provider = installedPaymentProviders?.find(
      (provider) => provider.id === id,
    );
    if (!provider) return provider;
    return provider as T;
  }, [id, installedPaymentProviders]);
  const notInstalledPaymentProvider = () => {
    const provider = notInstalledPaymentProviders?.find(
      (provider) => provider.id === id,
    );
    if (!provider) return provider;
    return provider as T;
  };
  const isInstalled = !!installedPaymentProvider;

  const fetch = useCallback(() => {
    if (!installedPaymentProviders) fetchInstalledPaymentProviders();
    if (!isInstalled && !notInstalledPaymentProviders) {
      fetchNotInstalledPaymentProviders();
    }
  }, [
    fetchInstalledPaymentProviders,
    installedPaymentProviders,
    isInstalled,
    notInstalledPaymentProviders,
    fetchNotInstalledPaymentProviders,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    isInstalled,
    isLoading: isInstalled ? isInstalledLoading : isNotInstalledLoading,
    isError: isInstalled ? isInstalledError : isNotInstalledError,
    paymentProvider: isInstalled
      ? installedPaymentProvider
      : notInstalledPaymentProvider(),
    fetch,
  };
}

export default useGetNativePaymentProvider;
