import { accountRoutes } from 'domains/Account';
import { authRoutes } from 'domains/Auth';
import { billingRoutes } from 'domains/Billing';
import { catalogRoutes } from 'domains/Catalog';
import { configurationsRoutes } from 'domains/Configurations';
import { customersRouter } from 'domains/Customers';
import { dashboardRoutes } from 'domains/Dashboard';
import { marketingRoutes } from 'domains/Marketing';
import { nubeKeyboardRoutes } from 'domains/NubeKeyboard';
import { themesRoutes } from 'domains/Online';
import { ordersRoutes } from 'domains/Orders';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { paymentsRoutes } from 'domains/Payments';
import { salesChannelsRoutes } from 'domains/SalesChannels';
import { shippingRoutes } from 'domains/Shipping';
import { statisticsRoutes } from 'domains/Statistics';

export const routesMerged = {
  ...statisticsRoutes,
  ...shippingRoutes,
  ...paymentsRoutes,
  ...partnersAppsRoutes,
  ...ordersRoutes,
  ...nubeKeyboardRoutes,
  ...marketingRoutes,
  ...dashboardRoutes,
  ...customersRouter,
  ...catalogRoutes,
  ...billingRoutes,
  ...authRoutes,
  ...accountRoutes,
  ...themesRoutes,
  ...configurationsRoutes,
  ...salesChannelsRoutes,
};
