import {
  MessagesResponseDto,
  QueryParamsRequestDto,
  ResultPaginationResponseDto,
  UnreadMessagesCountDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  buildQueryParamsUrlWithSnakeCase,
  removeEmptyValues,
} from 'commons/utils';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  BULK_MESSAGES_URL,
  MESSAGES_PAGE_URL,
  MESSAGE_DETAIL_URL,
  READ_MESSAGE_URL,
  SPAM_MESSAGE_URL,
  UNREAD_MESSAGES_COUNT_URL,
  UNREAD_MESSAGE_URL,
} from './urls';

const getMessages = async (filters: QueryParamsRequestDto) => {
  const params =
    filters &&
    buildQueryParamsUrlWithSnakeCase(
      removeEmptyValues<QueryParamsRequestDto>(filters),
    );
  const { data } = await axios.get<
    Promise<ResultPaginationResponseDto<MessagesResponseDto[]>>
  >(`${MESSAGES_PAGE_URL}&${params}`);
  return data;
};

const removeMessage = async (id: string) => {
  const { data } = await axios.delete(
    getUrlWithParams(MESSAGE_DETAIL_URL, { id }),
  );
  return data;
};

const readMessage = async (id: string) => {
  const { data } = await axios.post(getUrlWithParams(READ_MESSAGE_URL, { id }));
  return data;
};

const unreadMessage = async (id: string) => {
  const { data } = await axios.post(
    getUrlWithParams(UNREAD_MESSAGE_URL, { id }),
  );
  return data;
};

const spamMessage = async (id: string) => {
  const { data } = await axios.post(getUrlWithParams(SPAM_MESSAGE_URL, { id }));
  return data;
};

const actionsMessage = async (ids: string[], action: string) => {
  await axios.patch(BULK_MESSAGES_URL, { ids, action });
};

const getUnreadMessagesCount = async () => {
  const { data } = await axios.get<UnreadMessagesCountDto>(
    UNREAD_MESSAGES_COUNT_URL,
  );
  return data;
};

export default {
  getMessages,
  removeMessage,
  readMessage,
  unreadMessage,
  spamMessage,
  actionsMessage,
  getUnreadMessagesCount,
};
