import { useCallback, useEffect, useState } from 'react';
import { Box, Label } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import {
  BaseCard,
  Input,
  InterfaceNameValue,
  Select,
  Text,
  Tooltip,
} from '@tiendanube/components';
import {
  CancelAndSaveButtons,
  InputNumberNimbus,
  Stack,
} from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationBilling } from 'domains/Billing';
import BrazilianFiscalDataFormSkeleton from './BrazilianFiscalDataFormSkeleton';
import { BrazilianAddress } from '../../../../Addresses';

export interface FiscalDataformBrazil {
  zipcode: string;
  province: string;
  city: string;
  neighborhood: string;
  street: string;
  number: string;
  complement: string;
  name: string;
  numberId: string;
  stateRegistration: string;
  doesNotHaveStateRegistration: boolean;
  exemptFromStateRegistration: boolean;
  stateRegistrationOption: string;
}

interface BrazilianFiscalDataFormProps {
  values: FiscalDataformBrazil;
  onChange: (
    data: Pick<InterfaceNameValue, 'name'> & { value: string | boolean },
  ) => void;
  onSave: () => void;
  onCancel: () => void;
  errors: Partial<FiscalDataformBrazil>;
  isLoading: boolean;
  setFieldValue: (field: string, value: any) => void;
  isDirty: boolean;
}

function BrazilianFiscalDataForm({
  values,
  onChange,
  onSave,
  onCancel,
  errors,
  isLoading,
  setFieldValue,
  isDirty,
}: BrazilianFiscalDataFormProps) {
  const t = useTranslationBilling();
  const nameHelpText = t(errors.name || '');
  const numberIdHelpText = t(errors.numberId || '');
  const stateRegistrationError = t(errors.stateRegistration || '');
  const { ref } = useAutoFocus();

  const [stateRegistrationType, setStateRegistrationType] = useState('empty');

  const handleChangeOptionStateRegistration = useCallback(
    (value: string) => {
      setStateRegistrationType(value);
      setFieldValue('stateRegistrationOption', value);

      setFieldValue('doesNotHaveStateRegistration', false);
      setFieldValue('exemptFromStateRegistration', false);

      // without state registration but with exceptions
      if (value !== 'hasStateRegistration') {
        setFieldValue('stateRegistration', '');

        const exemptFromStateRegistration =
          value === 'exemptFromStateRegistration';
        const doesNotHaveStateRegistration =
          value === 'doesNotHaveStateRegistration';

        setFieldValue(
          'exemptFromStateRegistration',
          exemptFromStateRegistration,
        );
        setFieldValue(
          'doesNotHaveStateRegistration',
          doesNotHaveStateRegistration,
        );
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (values?.numberId && values?.numberId?.length <= 11) {
      setStateRegistrationType('empty');
    }
  }, [values.numberId]);

  useEffect(() => {
    if (values.doesNotHaveStateRegistration) {
      setStateRegistrationType('doesNotHaveStateRegistration');
      return;
    }
    if (values.exemptFromStateRegistration) {
      setStateRegistrationType('exemptFromStateRegistration');
      return;
    }

    if (values.stateRegistrationOption === 'empty') {
      setStateRegistrationType('empty');
      return;
    }

    if (values.stateRegistration) {
      setStateRegistrationType('hasStateRegistration');
    }
  }, [
    values.doesNotHaveStateRegistration,
    values.exemptFromStateRegistration,
    values.stateRegistration,
    values.stateRegistrationOption,
  ]);

  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Stack column align="stretch" innerRef={ref}>
            <Input
              type="text"
              label={t('invoicesInfoPage.name')}
              name="name"
              value={values.name}
              helpText={nameHelpText}
              appearance={nameHelpText ? 'validation_error' : 'default'}
              onChange={onChange}
            />
            <Input
              type="text"
              label={t('invoicesInfoPage.numberId')}
              name="numberId"
              value={values.numberId}
              helpText={numberIdHelpText}
              appearance={numberIdHelpText ? 'validation_error' : 'default'}
              onChange={(evt) => {
                onChange({ name: 'numberId', value: evt.value });
                if (values.numberId.length > 11) {
                  setFieldValue('stateRegistration', '');

                  setFieldValue('doesNotHaveStateRegistration', false);
                  setFieldValue('exemptFromStateRegistration', false);
                  setFieldValue('stateRegistrationOption', '');
                  setStateRegistrationType('empty');
                }
              }}
            />

            {Boolean(values.numberId) && values.numberId.length > 11 && (
              <Box display="flex" gap="2" flexDirection="column">
                <Box display="flex" gap="2">
                  <Label id="invoicesInfoPage.stateRegistration.label">
                    {t('invoicesInfoPage.stateRegistration.label')}
                  </Label>
                  <Tooltip labelIcon={InfoCircleIcon} position="bottom">
                    <Text>
                      {t('invoicesInfoPage.stateRegistration.tooltip')}
                    </Text>
                  </Tooltip>
                </Box>
                <Select
                  label=""
                  name="stateRegistrationOption"
                  value={stateRegistrationType}
                  options={[
                    {
                      label: t(
                        'invoicesInfoPage.stateRegistration.options.select',
                      ),
                      value: 'empty',
                    },
                    {
                      label: t(
                        'invoicesInfoPage.stateRegistration.options.exempt',
                      ),
                      value: 'exemptFromStateRegistration',
                    },
                    {
                      label: t(
                        'invoicesInfoPage.stateRegistration.options.doesNotHave',
                      ),
                      value: 'doesNotHaveStateRegistration',
                    },
                    {
                      label: t(
                        'invoicesInfoPage.stateRegistration.options.hasStateRegistration',
                      ),
                      value: 'hasStateRegistration',
                    },
                  ]}
                  onChange={(evt) =>
                    handleChangeOptionStateRegistration(evt.value)
                  }
                />
                {stateRegistrationType === 'hasStateRegistration' && (
                  <Box mt="2">
                    <InputNumberNimbus
                      value={values.stateRegistration}
                      appearance={stateRegistrationError ? 'danger' : 'neutral'}
                      type="integer"
                      onChange={(evt) =>
                        onChange({
                          name: 'stateRegistration',
                          value: evt.target.value,
                        })
                      }
                      name="stateRegistrationNumber"
                      placeholder={t(
                        'invoicesInfoPage.stateRegistration.placeholder',
                      )}
                    />
                  </Box>
                )}
              </Box>
            )}

            <BrazilianAddress
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        isLoading={isLoading}
        onCancel={onCancel}
        onSave={onSave}
        isDisabled={!isDirty}
      />
    </>
  );
}

BrazilianFiscalDataForm.Skeleton = BrazilianFiscalDataFormSkeleton;

export default BrazilianFiscalDataForm;
