import { ErrorState } from '@tiendanube/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface EditNotificationPageErrorProps {
  refetch: () => void;
}

export function EditNotificationPageError({
  refetch,
}: Readonly<EditNotificationPageErrorProps>) {
  const t = useTranslationConfigurations('notifications.editNotificationPage');

  return (
    <ErrorState
      title={t('errorState.title')}
      action={{
        children: t('errorState.action'),
        onClick: refetch,
      }}
    />
  );
}
