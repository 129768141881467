import axios from 'App/axios';
import { MetafieldBulkAssignmentsInterface } from '../productMetafieldsSlice/types';

export const productVariantBulkAssignment = async (
  payload: MetafieldBulkAssignmentsInterface,
): Promise<void> => {
  const { data } = await axios.post<void>(
    `/v1/products/metafields/bulk-assignment`,
    payload,
  );
  return data;
};

export const productVariantBulkAssignmentV2 = async (
  payload: MetafieldBulkAssignmentsInterface,
) => {
  const { data } = await axios.post<void>(
    `v2/products/metafields/bulk-assignment`,
    payload,
  );
  return data;
};
