import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCorreiosContractData,
  getCorreiosDetailAction,
  getCorreiosShippingModalitiesData,
  getCorreiosShippingModalitiesStatus,
} from '../../deliveryMethodsSlice';

function useGetCorreiosDetail() {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(
    getCorreiosShippingModalitiesStatus,
  );
  const currentShippingModalities = useSelector(
    getCorreiosShippingModalitiesData,
  );
  const currentContract = useSelector(getCorreiosContractData);

  const fetchCorreiosDetail = useCallback(() => {
    dispatch(getCorreiosDetailAction());
  }, [dispatch]);

  return {
    isLoading,
    isError,
    isSuccess,
    currentContract,
    currentShippingModalities,
    fetchCorreiosDetail,
  };
}

export default useGetCorreiosDetail;
