import { DataList, InteractiveList } from '@nimbus-ds/patterns';
import { Repeater } from 'commons/components';

function InteractiveListSkeleton() {
  return (
    <InteractiveList bottomDivider>
      <Repeater
        times={6}
        item={(index) => (
          <DataList.Row key={index}>
            <InteractiveList.StructureSkeleton
              title="titleSkeleton"
              description="descriptionSkeleton"
            />
          </DataList.Row>
        )}
      />
    </InteractiveList>
  );
}

export default InteractiveListSkeleton;
