import { Button } from '@tiendanube/components';
import useEmailMessageLink, {
  useEmailMessageLinkProps,
} from 'commons/hooks/useEmailMessageLink';

interface SendEmailButtonProps extends useEmailMessageLinkProps {
  textButton?: string;
}

function SendEmailButton({
  email,
  subject,
  body,
  url,
  signature,
  textButton,
}: SendEmailButtonProps): JSX.Element {
  const getUrl = useEmailMessageLink();

  const goToSendEmail = () => {
    window.location.href = getUrl({
      email,
      subject,
      body,
      url,
      signature,
    });
  };

  return (
    <Button onClick={goToSendEmail}>{textButton ? textButton : email}</Button>
  );
}

export default SendEmailButton;
