import { CardCollapsible } from 'commons/components';

function Skeleton(): JSX.Element {
  return (
    <>
      <CardCollapsible.Skeleton />
      <CardCollapsible.Skeleton />
      <CardCollapsible.Skeleton />
      <CardCollapsible.Skeleton />
      <CardCollapsible.Skeleton />
    </>
  );
}

export default Skeleton;
