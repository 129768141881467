import { GatewayPaymentMethodType } from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const useTranslatePaymentMethod = () => {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps.paymentDetails',
  );

  return (method: GatewayPaymentMethodType) => {
    // When the payment method is "BANK_TRANSFER", there's no need to render its payment type.
    if (method === 'BANK_TRANSFER') return undefined;

    const translations = {
      CREDIT_CARD: t('paymentMethods.creditCard'),
      DEBIT_CARD: t('paymentMethods.debitCard'),
      BALANCE: t('paymentMethods.balance'),
      TICKET: t('paymentMethods.ticket'),
    };
    return translations[method] || method;
  };
};

export default useTranslatePaymentMethod;
