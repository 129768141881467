import { InvoiceDto, InvoicesResponseDto } from '@tiendanube/common';
import { DataTable, Text, IconButton } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { CurrencyPrice } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import DataTableRowSkeleton from './DataTableRowSkeleton';
import InvoiceItem from './InvoiceItem';
import useDownloadInvoice from '../../../../../useDownloadInvoice';

const { Cell, Row } = DataTable;

type DataTableRowProps = InvoicesResponseDto;

function DataTableRow({
  month,
  row,
  total,
  currency,
}: DataTableRowProps): JSX.Element {
  const t = useTranslationBilling();
  const { getLink } = useDownloadInvoice();

  const handleDownload = (invoice: InvoiceDto) => getLink(invoice);

  return (
    <>
      <div className="stratus--row-no-bottom-border">
        {row.map(({ amount, invoice, period, currency }, index) => (
          <Row
            key={index}
            id={month.concat(index.toString())}
            align="flex-start"
          >
            <Cell grow={1}>{index === 0 && <Text bold>{month}</Text>}</Cell>
            <Cell grow={2}>
              <Text>{period}</Text>
            </Cell>
            <Cell grow={4}>
              <InvoiceItem {...invoice} />
            </Cell>
            <Cell grow={1}>
              <CurrencyPrice price={Number(amount)} currency={currency} />
            </Cell>
            <Cell grow={1}>
              <IconButton
                icon={DownloadIcon}
                ariaLabel={t('invoiceHistory.buttonArial')}
                onClick={() =>
                  handleDownload({ amount, invoice, period, currency })
                }
              />
            </Cell>
          </Row>
        ))}
      </div>
      {row.length > 0 && (
        <Row id={month.concat('total')} align="flex-start" border-bottom="0">
          <Cell grow={1} />
          <Cell grow={2} />
          <Cell grow={4} />
          <Cell grow={1}>
            <CurrencyPrice bold price={Number(total)} currency={currency} />
          </Cell>
          <Cell grow={1} />
        </Row>
      )}
    </>
  );
}

DataTableRow.Skeleton = DataTableRowSkeleton;

export default DataTableRow;
