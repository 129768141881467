import 'array-flat-polyfill';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@nimbus-ds/styles/dist/index.css';
import './config/datadogSettings';

if (
  Capacitor.getPlatform() !== 'web' &&
  Capacitor.isPluginAvailable('Keyboard')
) {
  Keyboard.setAccessoryBarVisible({ isVisible: true });
  Keyboard.setResizeMode({ mode: KeyboardResize.Native });
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// the service worker is temporarily disabled until ignore prefetching
// of files innecesary like TinyMCE
serviceWorker.unregister();
