const VALIDATION_TOKEN_PREFIX = 'validation_token:';

const TIMEOUT_MS = 5 * 1000;

const getValidationToken = (action: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const validationIframe = document.getElementById(
      'validationIframe',
    ) as HTMLIFrameElement;

    if (!validationIframe) reject('Error: no validation iframe detected');

    if (validationIframe) {
      const pageValidation = validationIframe.src;
      const pageValidationOrigin = new URL(pageValidation).origin;
      validationIframe.contentWindow?.postMessage(
        `getToken:${action}`,
        pageValidationOrigin,
      );

      const timer = setTimeout(() => {
        window.removeEventListener('message', messageEventListener);
        reject('Validation timeout');
      }, TIMEOUT_MS);

      const messageEventListener = ({ origin, data }: MessageEvent) => {
        if (
          pageValidation &&
          typeof data === 'string' &&
          data.startsWith(VALIDATION_TOKEN_PREFIX) &&
          origin === pageValidationOrigin
        ) {
          clearTimeout(timer);
          window.removeEventListener('message', messageEventListener);
          resolve(data.split(VALIDATION_TOKEN_PREFIX)[1]);
        }
      };

      window.addEventListener('message', messageEventListener);
    }
  });

export default getValidationToken;
