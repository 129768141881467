import {
  InfoMessageRequestDto,
  InfoMessageResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import {
  InfoMessageEndpoints,
  InfoMessageTags,
  INFO_MESSAGE_URL,
} from './config';

export const infoMessageApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [InfoMessageTags.GetInfoMessage],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [InfoMessageEndpoints.getInfoMessage]: builder.query<
        InfoMessageResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: INFO_MESSAGE_URL,
        }),
        providesTags: [InfoMessageTags.GetInfoMessage],
      }),
      [InfoMessageEndpoints.updateInfoMessage]: builder.mutation<
        void,
        InfoMessageRequestDto
      >({
        query: (data) => ({
          method: 'PATCH',
          url: INFO_MESSAGE_URL,
          data,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [InfoMessageTags.GetInfoMessage],
      }),
    }),
  });

export const { useGetInfoMessageQuery, useUpdateInfoMessageMutation } =
  infoMessageApi;
