import { CategoryDetailsResponseDto } from '@tiendanube/common';

export function sanitizerCategory(
  category: CategoryDetailsResponseDto,
): CategoryDetailsResponseDto {
  return Object.keys(category).reduce((acc, key) => {
    if (key === 'image' && category[key]?.id === '') {
      return acc;
    }
    acc[key] = category[key];
    return acc;
  }, {} as CategoryDetailsResponseDto);
}
