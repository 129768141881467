/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Checkbox, Modal, Text } from '@nimbus-ds/components';
import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useAddressingConfigurationRulesManagement } from 'domains/Shipping/DeliveryMethods/hooks';
import {
  trackingShippingChangeAddressingRule,
  trackingShippingCloseAddressingRule,
} from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import './AddressingConfigurationModal.scss';
import ActiveRulesList from './ActiveRulesList';
import InactiveRulesList from './InactiveRulesList';
import IncompatibleRuleAlert from './IncompatibleRuleAlert';
import { INITIAL_MATERIALIZATION_RULE, INCOMPATIBLE_RULES } from '../constants';
import {
  getDisabledRules,
  getEnabledRules,
  getIncompatibleRule,
  getMaterializationRule,
  reprioritizeRules,
} from '../utils';

interface AddressingConfigurationModalProps {
  closeModal: () => void;
}

function AddressingConfigurationModal({
  closeModal,
}: Readonly<AddressingConfigurationModalProps>) {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');
  const { rules, saveStatus, fetchRules, saveConfigurationRules, cleanStatus } =
    useAddressingConfigurationRulesManagement();
  const [isDirty, setIsDirty] = useState(false);
  const [automaticallyDisabledRule, setAutomaticallyDisabledRule] = useState<
    string | null
  >(null);
  const [materializationRule, setMaterializationRule] =
    useState<AddressingConfigurationRuleResponseDto>(
      INITIAL_MATERIALIZATION_RULE,
    );
  const [activeRules, setActiveRules] = useState<
    AddressingConfigurationRuleResponseDto[]
  >([]);
  const [inactiveRules, setInactiveRules] = useState<
    AddressingConfigurationRuleResponseDto[]
  >([]);

  const onChangeRule = (
    type: 'priority' | 'add' | 'remove',
    newActiveRules: AddressingConfigurationRuleResponseDto[],
    newInactiveRules?: AddressingConfigurationRuleResponseDto[],
  ) => {
    setActiveRules(newActiveRules);
    if (newInactiveRules) {
      setInactiveRules(newInactiveRules);
    }
    trackingShippingChangeAddressingRule(newActiveRules, type);
    setIsDirty(true);
  };

  const handleOnChangePriority = (newOrder) => {
    const newActiveRules = reprioritizeRules(newOrder);
    onChangeRule('priority', newActiveRules);
  };

  const handleCheckMaterialization = () => {
    setMaterializationRule({
      ...materializationRule,
      enabled: !materializationRule.enabled,
    });
    trackingShippingChangeAddressingRule(
      [materializationRule],
      'materialization',
    );
    setIsDirty(true);
  };

  const handleRemoveRule = (rule: AddressingConfigurationRuleResponseDto) => {
    const newActiveRules = activeRules.filter(
      (activeRule) => activeRule.code !== rule.code,
    );

    if (INCOMPATIBLE_RULES.includes(rule.type)) {
      setAutomaticallyDisabledRule(null);
    }

    onChangeRule('remove', reprioritizeRules(newActiveRules), [
      ...inactiveRules,
      rule,
    ]);
  };

  const handleAddRule = (newRule: AddressingConfigurationRuleResponseDto) => {
    let newActiveRules = [...activeRules, { ...newRule, enabled: true }];
    const newInactiveRules = inactiveRules.filter(
      (inactiveRule) => inactiveRule.code !== newRule.code,
    );

    const hasIncompatibleRule = !!activeRules.find((rule) =>
      INCOMPATIBLE_RULES.includes(rule.type),
    );

    if (INCOMPATIBLE_RULES.includes(newRule.type) && hasIncompatibleRule) {
      const incompatibleRule = getIncompatibleRule(rules, newRule);
      setAutomaticallyDisabledRule(incompatibleRule.type);
      newActiveRules = newActiveRules.filter(
        (rule) => rule.type !== incompatibleRule.type,
      );
      newInactiveRules.push(incompatibleRule);
    }

    onChangeRule('add', reprioritizeRules(newActiveRules), newInactiveRules);
  };

  const handleSaveRules = async () => {
    await saveConfigurationRules([materializationRule, ...activeRules]);
  };

  const handleCloseModal = (type: 'close' | 'save') => {
    closeModal();
    trackingShippingCloseAddressingRule(
      [materializationRule, ...activeRules],
      type,
    );
  };

  useToastStatus({
    error: t('modal.toast.error'),
    success: t('modal.toast.success'),
    status: saveStatus,
    onSuccess: () => {
      cleanStatus();
      handleCloseModal('save');
    },
  });

  useEffect(() => {
    if (rules.length === 0) {
      fetchRules();
    }
    if (rules.length > 0) {
      setActiveRules(getEnabledRules(rules));
      setInactiveRules(getDisabledRules(rules));
      setMaterializationRule(getMaterializationRule(rules));
    }
  }, [fetchRules, rules]);

  return (
    <Modal open onDismiss={() => handleCloseModal('close')}>
      <Modal.Header title={t('modal.title')} />
      <Modal.Body padding="none">
        <Stack align="flex-start" column>
          <Text fontSize="base">{t('modal.description')}</Text>

          <ActiveRulesList
            rules={activeRules}
            handleOnChangePriority={handleOnChangePriority}
            handleRemoveRule={handleRemoveRule}
          />
          <hr />
          <InactiveRulesList
            rules={inactiveRules}
            handleAddRule={handleAddRule}
          />

          {!!automaticallyDisabledRule && (
            <IncompatibleRuleAlert
              deactivatedRule={automaticallyDisabledRule}
            />
          )}

          <Checkbox
            name="materialiaze"
            label={t('modal.materialization')}
            checked={materializationRule.enabled}
            onChange={handleCheckMaterialization}
          />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndConfirmButtons
          onCancel={() => handleCloseModal('close')}
          onConfirm={handleSaveRules}
          isLoading={saveStatus === 'loading'}
          isConfirmDisabled={!isDirty}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default AddressingConfigurationModal;
