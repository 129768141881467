import { Card, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function CardTimelineSkeleton(): JSX.Element {
  const t = useTranslationOrders();
  return (
    <Card title={t('cardTimeline.title')}>
      <Text.Skeleton size="featured" width="fill" />
      <Text.Skeleton size="featured" width="fill" />
      <Text.Skeleton size="featured" width="fill" />
    </Card>
  );
}

export default CardTimelineSkeleton;
