import { Radio, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

interface RadioOptionsSkeletonInterface {
  title: string;
}

export function RadioOptionsSkeleton({
  title,
}: Readonly<RadioOptionsSkeletonInterface>) {
  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <Text fontWeight="bold">{title}</Text>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <Radio.Skeleton as="button" />
          <Radio.Skeleton as="button" />
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
