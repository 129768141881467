import { BoxProps, Link, Text } from '@nimbus-ds/components';
import { ChevronLeftIcon, ChevronRightIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import './PaginationControls.scss';

interface PaginationControlsProps extends BoxProps {
  current: number;
  total: number;
  onPrevious: () => void;
  onNext: () => void;
}
function PaginationControls({
  current,
  total,
  onPrevious,
  onNext,
}: Readonly<PaginationControlsProps>) {
  return (
    <div className="stratus--ai-generation-pagination">
      <Stack gap="1" align="center">
        <Stack.Item>
          <Link
            appearance="primary"
            onClick={onPrevious}
            as="button"
            disabled={current === 1}
          >
            <ChevronLeftIcon />
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Text color="primary-textLow" textAlign="center">
            {current}/{total}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Link
            appearance="primary"
            onClick={onNext}
            as="button"
            disabled={current === total}
          >
            <ChevronRightIcon />
          </Link>
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default PaginationControls;
