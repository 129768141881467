/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { useParams } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import {
  CreateDocumentRequestDto,
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
  useResponsive,
} from 'commons/components';
import { openWindow } from 'commons/utils/window';
import {
  trackingShippingPrintDocumentCancelButtonClick,
  trackingShippingPrintDocumentConfirmationButtonClick,
  trackingShippingPrintDocumentPageView,
} from 'domains/FulfillmentOrders/tracking';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import {
  PrintDocumentForm,
  PrintDocumentPageError,
  PrintDocumentPreview,
} from './components';
import AlertNuvemEnvio from './components/AlertNuvemEnvio';
import AlertOcaLabelsWithError from './components/AlertOcaLabelsWithError';
import CardRegenerateOcaLabels from './components/CardRegenerateOcaLabels';
import {
  DOCUMENT_SETTINGS_EMPTY_STATE,
  DocumentType,
  FULFILLMENT_ORDER_ID_IDENTIFIER,
} from './constants';
import {
  useCreateDocumentsDownload,
  useGetInformationForDocument,
} from './hooks';
import useDocumentSettings from './hooks/useDocumentsSettings/useDocumentsSettings';
import useGetOcaLabels from './hooks/useGetOcaLabels';
import {
  defineIfShouldShowAlertNuvemEnvioLabel,
  getRequestByLocationIdForContentDeclaration,
  getRequestByLocationIdForDocument,
} from './utils';

interface PrintDocumentPageProps {
  readonly type: DocumentType;
}

function PrintDocumentPage({ type }: PrintDocumentPageProps) {
  const t = useTranslationFulfillmentOrders('printManager');
  const { isMobile } = useResponsive();
  const { goBack } = useNavegate();

  const {
    fetchInformationForDocument,
    information,
    isError,
    isLoading,
    isSuccess,
  } = useGetInformationForDocument(type);

  const {
    getOcaLabels,
    isLoadingLabels,
    isErrorLabels,
    mustWaitGeneration,
    labels,
    progress,
  } = useGetOcaLabels();

  const shouldShowAlertNuvemEnvioLabel = useMemo(
    () => defineIfShouldShowAlertNuvemEnvioLabel(type, information),
    [information, type],
  );

  const ocaLabelsWithError = labels
    .filter((label) => !!label.error || (!label.error && !label.html))
    .map((label) => label.externalId);

  const { documentSettings, fetchDocumentSettings } = useDocumentSettings();
  const { handleDownloadDocument, setMultiple, isGenerating } =
    useCreateDocumentsDownload();
  const componentRef = useRef(null);

  const selectedIds: string[] =
    useParams<{ ids: string }>().ids?.split(',') || [];
  const hasOca = !!useParams<{ oca: string }>().oca;
  const fetchParams: CreateDocumentRequestDto = selectedIds.reduce(
    (acc: CreateDocumentRequestDto, id) => {
      if (id.includes(FULFILLMENT_ORDER_ID_IDENTIFIER)) {
        acc.fulfillmentOrders?.push(
          id.replace(FULFILLMENT_ORDER_ID_IDENTIFIER, ''),
        );
      } else {
        acc.orders?.push(id);
      }
      return acc;
    },
    { fulfillmentOrders: [], orders: [] },
  );

  const [settings, setSettings] = useState<GetDocumentSettingsResponseDto>({
    ...(documentSettings ?? DOCUMENT_SETTINGS_EMPTY_STATE),
  });
  const [locationId, setLocationId] = useState<string>('');
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  const handleDownload = useCallback(async () => {
    if (
      (!settings?.downloadDocument &&
        type !== DocumentType.CONTENT_DECLARATION &&
        !isMobile) ||
      hasOca
    ) {
      handlePrint();
      trackingShippingPrintDocumentConfirmationButtonClick(
        type,
        'print',
        settings,
      );
      return;
    }

    const { fulfillmentOrders, orders } =
      type === DocumentType.CONTENT_DECLARATION
        ? getRequestByLocationIdForContentDeclaration({
            informationForContentDeclaration:
              information as InformationForContentDeclarationResponseDto[],
            locationId,
          })
        : getRequestByLocationIdForDocument({
            informationForDocument:
              information as InformationForDocumentResponseDto[],
            locationId,
            type,
          });

    setMultiple(!!orders && orders.length > 1);

    const documentUrl = await handleDownloadDocument({
      type,
      params: {
        fulfillmentOrders: fulfillmentOrders,
        orders: orders,
        settings: settings,
      },
    });

    if (documentUrl) {
      trackingShippingPrintDocumentConfirmationButtonClick(
        type,
        'download',
        settings,
      );
      openWindow(documentUrl, true);
    }
  }, [
    handleDownloadDocument,
    handlePrint,
    hasOca,
    information,
    isMobile,
    locationId,
    setMultiple,
    settings,
    type,
  ]);

  const handleGoBack = () => {
    trackingShippingPrintDocumentCancelButtonClick(type, 'header');
    goBack();
  };

  const onRetryError = () => {
    fetchInformationForDocument(fetchParams);
    if (type !== DocumentType.CONTENT_DECLARATION) {
      fetchDocumentSettings();
    }
  };

  useEffect(() => {
    fetchInformationForDocument(fetchParams);
    fetchDocumentSettings();
    trackingShippingPrintDocumentPageView(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
        handleDownload();
        event.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleDownload]);

  useEffect(() => {
    if (
      !mustWaitGeneration &&
      hasOca &&
      labels.length === 0 &&
      !isLoadingLabels
    ) {
      getOcaLabels(fetchParams.orders ?? []);
    }
  });

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          navigation={{
            onClick: handleGoBack,
          }}
        />
      }
      headerContent={
        <HeaderContent
          title={t(`${type}.title`)}
          subtitle={t(`${type}.subtitle`)}
        />
      }
    >
      <Stack align="baseline" justify="center" wrap={isMobile}>
        {(isError ||
          (isSuccess && information?.length === 0) ||
          (hasOca && isErrorLabels)) && (
          <PrintDocumentPageError onRetryError={onRetryError} />
        )}
        {!(isError || (isSuccess && information?.length === 0)) && (
          <>
            <Box width="100%" gap="4" flexDirection="column" display="flex">
              {shouldShowAlertNuvemEnvioLabel && (
                <AlertNuvemEnvio externalSaleId={selectedIds} />
              )}
              <PrintDocumentForm
                type={type}
                information={information}
                locationId={locationId}
                settings={settings}
                isPrinting={isGenerating}
                setSettings={setSettings}
                setLocationId={setLocationId}
                handleDownload={handleDownload}
                hasOca={hasOca}
              />
              {ocaLabelsWithError.length > 0 &&
                information &&
                information.length > 0 && (
                  <AlertOcaLabelsWithError
                    externalIds={ocaLabelsWithError}
                    information={(
                      information as InformationForDocumentResponseDto[]
                    ).filter(({ order }) =>
                      ocaLabelsWithError.includes(order.id),
                    )}
                  />
                )}
              {hasOca &&
                information &&
                information.length > 0 &&
                labels.length > 0 &&
                ocaLabelsWithError.length === 0 && (
                  <CardRegenerateOcaLabels externalIds={selectedIds} />
                )}
            </Box>
            <PrintDocumentPreview
              type={type}
              information={information}
              isLoading={isLoading || (hasOca && isLoadingLabels)}
              hasOca={hasOca}
              ocaLabels={labels}
              progressOca={progress}
              settings={settings}
              locationId={locationId}
              componentRef={componentRef}
            />
          </>
        )}
      </Stack>
    </IonPageStratus>
  );
}

export default PrintDocumentPage;
