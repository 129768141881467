import { Alert, Text } from '@nimbus-ds/components';
import { useDismissibleComponent } from 'commons/hooks';
import { GoToBillingPlansLink } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

interface AffiliateDiscountPercentageAlertProps {
  mobileSuffix?: string;
  affiliateDiscountPercentage?: number;
  show?: boolean;
}

export function AffiliateDiscountPercentageAlert({
  mobileSuffix = '',
  affiliateDiscountPercentage,
  show = false,
}: Readonly<AffiliateDiscountPercentageAlertProps>) {
  const t = useTranslationDashboard('trialMessages');
  const { shouldShow, dismiss } = useDismissibleComponent(
    'affiliate-discount-percentage-alert',
    1,
  );

  return (
    <Alert
      show={shouldShow && !!affiliateDiscountPercentage && show}
      appearance="success"
      title={t('discount.title')}
      onRemove={dismiss}
    >
      <Text>
        {t(`discount.description${mobileSuffix}`, {
          count: affiliateDiscountPercentage,
        })}
      </Text>
      <GoToBillingPlansLink>{t('cta')}</GoToBillingPlansLink>
    </Alert>
  );
}
