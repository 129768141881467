import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Text } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import {
  CustomersFiltersType,
  SortType,
} from 'domains/Customers/Customers/customersServices/types';

const { Header, Cell } = DataTable;

interface ResultHeaderDesktopProp {
  filters: CustomersFiltersType;
  onSortTotalConsumed: (sort: SortType) => void;
}

function ResultHeaderDesktop({
  filters,
  onSortTotalConsumed,
}: ResultHeaderDesktopProp): JSX.Element {
  const { t } = useTranslation([Domain.CUSTOMERS]);

  const handleSortByTotalConsumed = () => {
    const sort =
      filters.sort === '' || filters.sort === 'desc' ? 'asc' : 'desc';
    onSortTotalConsumed(sort);
  };

  return (
    <Header>
      <Cell basis={400}>
        <Text>{t('tableHeader.client')}</Text>
      </Cell>
      <Cell grow={0} basis={170}>
        <Text>{t('tableHeader.lastSell')}</Text>
      </Cell>
      <Cell basis={200}>
        <div onClick={handleSortByTotalConsumed}>
          <Text>
            {filters.sort === 'asc' ? <ChevronUpIcon /> : <ChevronDownIcon />}
            {t('tableHeader.total')}
          </Text>
        </div>
      </Cell>
      <Cell grow={0} basis={125}>
        <Text>{t('tableHeader.contact')}</Text>
      </Cell>
    </Header>
  );
}

export default ResultHeaderDesktop;
