/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Icon, Link } from '@nimbus-ds/components';
import { PlusCircleIcon, ListIcon } from '@nimbus-ds/icons';
import { Stack } from '@tiendanube/components';
import { FEATURE_MAX_LOCATIONS } from 'App/features';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import { SHIPPING_ADD_LOCATION } from 'config/upsellFlowSources';
import {
  useHasAllowedMultipleLocation,
  useStatusFeatureByKey,
} from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { trackingShippingAddDistributionCenter } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { LocationsLimitAlert, LocationsList } from './components';
import LocationsListEmpty from './components/LocationsListEmpty';
import LocationsListError from './components/LocationsListError';
import MultiCdEnableModal from './components/MultiCdEnableModal';
import useLocations from '../../hooks/useLocations';
import useMultiCDActivation from '../../hooks/useMultiCDActivation';

function LocationListPage(): JSX.Element {
  const t = useTranslationShipping();
  const [isSorting, setIsSorting] = useState(false);
  const {
    locationsMainId,
    locations,
    isError,
    isEmpty,
    isLoading,
    isSuccess,
    fetchLocations,
  } = useLocations();

  const { available, getMultiCDAvailability } = useMultiCDActivation();
  const { goTo } = useNavegate();

  const { maxUse: limitOfLocations, unlimited: unlimitedLocations } =
    useStatusFeatureByKey(FEATURE_MAX_LOCATIONS);
  const hasAllowedMultipleLocation = useHasAllowedMultipleLocation();
  const [showMulticdModal, openMulticdModal, closeMulticdModal] = useModal();

  const actionSort: ActionProp = {
    children: t('locations.sort.action'),
    icon: ListIcon,
    onClick: () => setIsSorting(true),
    appearance: 'default',
  };

  const handleClickAdd = useUpsellFlow({
    title: t('locations.limit.upsell'),
    featureKey: FEATURE_MAX_LOCATIONS,
    minValue: locations.length + 1,
    trackingSource: SHIPPING_ADD_LOCATION,
    callback: () => {
      const type = locationsMainId.length === 0 ? 'main' : 'secondary';
      if (available) {
        trackingShippingAddDistributionCenter('enabled', type);
        goTo(`/settings/locations/new/${type}`);
      }

      if (!available) {
        trackingShippingAddDistributionCenter('multicd-unavailable', type);
        openMulticdModal();
      }
    },
    onCloseModal: () => {
      trackingShippingAddDistributionCenter('disabled', 'secondary');
    },
  });

  const actionAdd: ActionProp = {
    children: t('locations.add'),
    icon: PlusCircleIcon,
    onClick: handleClickAdd,
    appearance: !hasAllowedMultipleLocation ? 'default' : 'primary',
    disabled: !hasAllowedMultipleLocation,
  };

  const renderMenuTop = isSuccess && !isEmpty && (
    <Stack spacing="loose" align="flex-start">
      {locations.length > 1 && (
        <Link onClick={actionSort.onClick} textDecoration="none">
          {t('locations.sort.action')}
        </Link>
      )}
      <Link onClick={actionAdd.onClick}>
        <Icon color="currentColor" source={<PlusCircleIcon />} />
      </Link>
    </Stack>
  );

  const subtitle = isSorting
    ? t('locations.sort.subtitle')
    : t('locations.subtitle');

  useEffect(() => {
    fetchLocations();
    getMultiCDAvailability();
  }, [fetchLocations, getMultiCDAvailability]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop actions={renderMenuTop} />}
      width="large"
      headerContent={
        <HeaderContent
          title={isSorting ? t('locations.sort.title') : t('locations.title')}
          subtitle={!isEmpty || isLoading ? subtitle : ''}
          actions={
            isSuccess &&
            !isEmpty &&
            !isSorting && (
              <ActionsDesktop
                actions={
                  locations.length > 1 ? [actionSort, actionAdd] : [actionAdd]
                }
              />
            )
          }
        />
      }
    >
      {isEmpty && isSuccess && <LocationsListEmpty origin="locations" />}
      {isError && <LocationsListError onRetryError={fetchLocations} />}
      {isSuccess &&
        locations.length >= limitOfLocations &&
        !unlimitedLocations &&
        hasAllowedMultipleLocation && (
          <LocationsLimitAlert limitOfLocations={limitOfLocations} />
        )}

      {!isError && (
        <LocationsList
          hasAllowedMultipleLocation={hasAllowedMultipleLocation}
          isEmpty={isEmpty}
          isLoading={isLoading}
          isSorting={isSorting}
          handleIsSorting={() => setIsSorting(!isSorting)}
        />
      )}
      {showMulticdModal && <MultiCdEnableModal onClose={closeMulticdModal} />}
    </IonPageStratus>
  );
}

export default LocationListPage;
