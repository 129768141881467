import { useEffect, useState } from 'react';
import {
  Input,
  InterfaceInput,
  InterfaceNameValue,
} from '@tiendanube/components';

const formatDNI = (value) => value.replace(/\D/g, '').slice(0, 8); // Limita la longitud del DNI a 8 dígitos
const formatCUIT = (value) =>
  value
    .replace(/\D/g, '') // Elimina cualquier caracter que no sea número
    .replace(/(\d{2})(\d)/, '$1-$2')
    .replace(/(\d{8})(\d)/, '$1-$2')
    .slice(0, 13); // Limita la longitud del CUIT a 13 caracteres (incluyendo guiones)

interface CnpjCpfInputProps {
  value: string;
  name: string;
  label: string;
  helpText?: string;
  appearance?: InterfaceInput['appearance'];
  onChange: (e: InterfaceNameValue) => void;
}

function DniCuitInput({
  value,
  onChange,
  name,
  label,
  appearance,
  helpText,
}: CnpjCpfInputProps) {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    let newValue = value;
    if (value.length > 8) {
      newValue = formatCUIT(value);
    } else {
      newValue = formatDNI(value);
    }
    setFormattedValue(newValue);
  }, [value]);

  const handleChange = ({ value, name }: InterfaceNameValue) => {
    onChange({ value: value.replace(/\D/g, ''), name });
  };

  return (
    <Input
      type="text"
      name={name}
      label={label}
      value={formattedValue}
      onChange={handleChange}
      placeholder={value.length > 8 ? 'XX-XXXXXXXX-X' : 'XXXXXXXX'}
      helpText={helpText}
      appearance={appearance}
    />
  );
}

export default DniCuitInput;
