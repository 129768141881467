import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SortByType } from '@tiendanube/common/src/domains/products';
import { Link } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { SearchFilter, SortByPopover } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useProductsList } from 'domains/Catalog/Products/pages/hooks';
import { ProductsFiltersType } from 'domains/Catalog/Products/productsServices';
import {
  useGetProductsAndVariantsMetafields,
  useGetVariantsMetafields,
} from 'domains/Metafields/hooks';
import FilterModal from './FilterModal';
import useProductListSearch from './useProductListSearch';

interface ProductsListFilterProps {
  filters: ProductsFiltersType;
  productsCount: number;
  isLoading: boolean;
  onChange: (filters: ProductsFiltersType) => void;
}

function ProductListSearch({
  filters,
  productsCount,
  isLoading,
  onChange,
}: ProductsListFilterProps): JSX.Element | null {
  const t = useTranslationCatalog();
  const { search } = useLocation();
  const { goTo } = useNavegate();
  const {
    handleChangeText,
    handleDismiss,
    optionsLabels,
    showExportFiltersLink,
    resultCount,
    label,
  } = useProductListSearch({ onChange, filters, isLoading, productsCount });
  const { fetchMetafields } = useGetVariantsMetafields();
  const { getProductsList } = useProductsList();

  const { fetchMetafields: fetchProductsAndVariantsMetafields } =
    useGetProductsAndVariantsMetafields();

  const [showModal, onOpenModal, onCloseModal] = useModal();
  const [query, setQuery] = useState(filters.q || '');

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const handleSortBy = ({ value: sortBy }) => {
    getProductsList({ ...filters, sortBy });
  };

  const listOptions: SortByType[] = [
    'price-ascending',
    'price-descending',
    'alpha-ascending',
    'alpha-descending',
    'created-at-ascending',
    'created-at-descending',
    'best-selling',
    'user',
  ];

  const hasSorBy =
    filters.sortBy && listOptions.includes(filters.sortBy)
      ? filters.sortBy
      : 'created-at-descending';

  const valueOption = filters.q ? 'more-relevant' : hasSorBy;

  useEffect(() => {
    setQuery(filters.q ?? '');
  }, [filters.q]);

  useEffect(() => {
    fetchMetafields();
    fetchProductsAndVariantsMetafields();
  }, [fetchMetafields, fetchProductsAndVariantsMetafields]);

  return (
    <>
      <SearchFilter
        placeholder={t('products.filter.placeHolder')}
        ariaLabel={t('products.filter.ariaLabelButtonFilter')}
        label={label}
        onSubmit={handleChangeText}
        resultCount={resultCount}
        onClick={onOpenModal}
        onDismiss={handleDismiss}
        isLoading={isLoading}
        appliedFilters={optionsLabels}
        rightElement={
          showExportFiltersLink && (
            <Link
              appearance="primary"
              icon={DownloadIcon}
              onClick={() => goTo(`/products/import${search}`)}
            >
              {t('products.filters.exportResult')}
            </Link>
          )
        }
        value={query}
        onChange={handleChangeQuery}
        sortByElement={
          <SortByPopover
            listOptions={listOptions.map((value) => ({
              value: value,
              label: t(`products.filters.sortBy.${value}`),
            }))}
            valueOption={{
              value: valueOption,
              label: t(`products.filters.sortBy.${valueOption}`),
            }}
            onChange={handleSortBy}
          />
        }
      />
      <FilterModal
        filter={filters}
        addParam={onChange}
        show={showModal}
        hideModal={onCloseModal}
      />
    </>
  );
}

export default ProductListSearch;
