import { Thumbnail, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function Skeleton() {
  return (
    <Stack spacing="tight" align="flex-start">
      <Thumbnail.Skeleton aspectRatio="1/1" width="72px" />
      <Stack column align="flex-start" spacing="tight">
        <Text.Skeleton />
        <Stack spacing="tight" wrap>
          <Text.Skeleton />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Skeleton;
