import { useEffect, useState } from 'react';
import { InterfaceSelectOption } from '@tiendanube/components';
import { useAsyncFunc, useToast, WrappedFuncType } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import productsServices from 'domains/Catalog/Products/productsServices';

interface useImportColumnOptionsResult {
  columnOptions: InterfaceSelectOption[];
  getColumnOptions: WrappedFuncType<
    string[],
    InterfaceSelectOption[] | undefined
  >;
  isLoading: boolean;
  isError: boolean;
  clearError: () => void;
  setColumnOptions: (columnOptions: InterfaceSelectOption[]) => void;
}

function useImportColumnOptions(): useImportColumnOptionsResult {
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const [columnOptions, setColumnOptions] = useState<InterfaceSelectOption[]>(
    [],
  );

  const [getColumnOptions, isLoading, isError, clearError] = useAsyncFunc(
    async (fileColumns?: string[]) => {
      if (columnOptions.length) {
        return columnOptions;
      }
      if (fileColumns) {
        const result = await productsServices.fetchImportColumns();
        const colsOptions = Object.entries(result).map(([key, value]) => ({
          label: value,
          value: key,
          disabled: fileColumns.includes(value),
        }));
        setColumnOptions(colsOptions);
        return colsOptions;
      }
    },
  );

  useEffect(() => {
    if (isError) {
      addToast({
        label: t('products.importExportPage.import.error'),
        appearance: 'danger',
      });
    }
  }, [addToast, isError, t]);

  return {
    columnOptions,
    getColumnOptions,
    isLoading,
    isError,
    clearError,
    setColumnOptions,
  };
}

export default useImportColumnOptions;
