import { Input, InterfaceNameValue } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { CuitInterface } from '../ArgentineFiscalData';

interface UploadTaxDataWithCuitProps {
  values: CuitInterface;
  errors: Partial<CuitInterface>;
  onChange: (e: InterfaceNameValue) => void;
}

export function UploadTaxDataWithCuit({
  values,
  errors,
  onChange,
}: UploadTaxDataWithCuitProps) {
  const t = useTranslationBilling();

  const cuitHelpText = t(errors.cuit || '');

  return (
    <Input
      name="cuit"
      type="number"
      value={values.cuit}
      onChange={onChange}
      helpText={cuitHelpText}
      appearance={cuitHelpText ? 'validation_error' : 'default'}
      label={t('invoicesInfoPage.cuitNumber')}
    />
  );
}
