import { Label } from '@nimbus-ds/components';
import { i18nResponseDto } from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Input, InterfaceNameValue } from '@tiendanube/components';
import { FEATURE_AI_TIER } from 'App/features';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import { Stack } from 'commons/components';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import { useModal } from 'commons/hooks';
import { useAiUsage } from 'domains/Ai/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import AltTextGenerationModal, {
  AltTextGenerationContext,
} from '../AltTextGenerationModal';

interface EditTextAltFormProps {
  language?: LanguagesType;
  value: i18nResponseDto;
  imageUrl: string;
  altTextGenerationContext: AltTextGenerationContext;
  onChange: (e: i18nResponseDto) => void;
}

function EditTextAltForm({
  language = DEFAULT_LANGUAGE,
  onChange,
  imageUrl,
  value,
  altTextGenerationContext,
}: Readonly<EditTextAltFormProps>) {
  const t = useTranslationCatalog();
  const [showAiModal, openAiModal, closeAiModal] = useModal();
  const { isLoadingAiUses, requiredTier, aiUsesUpsellTitle } = useAiUsage();

  const handleOpenAiModalClick = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: AiFeature.AI_PRODUCT_IMAGE_ALT_TEXT,
    callback: openAiModal,
    minValue: requiredTier,
    asAside: true,
  });

  const handleOnChange = ({ value: newValue }: InterfaceNameValue) => {
    onChange({
      ...value,
      [language]: newValue,
    });
  };

  const handleUseContent = (generatedAltText: string) =>
    handleOnChange({ name: 'altText', value: generatedAltText });

  return (
    <>
      <Stack column align="stretch" spacing="tight">
        <Stack spacing="tight">
          <Label color="neutral-textLow" htmlFor="input_altText">
            {t('products.modal.editPhoto.actions.altText.modal.title')}
          </Label>
          {!isLoadingAiUses && (
            <GenerateWithAiLink
              fontSize="caption"
              onClick={handleOpenAiModalClick}
            />
          )}
        </Stack>
        <Input
          value={value[language]}
          multiRows
          rows={3}
          name="altText"
          onChange={handleOnChange}
        />
      </Stack>
      <AltTextGenerationModal
        show={showAiModal}
        language={language}
        imageUrl={imageUrl}
        altTextGenerationContext={altTextGenerationContext}
        onDismiss={closeAiModal}
        onUseContent={handleUseContent}
      />
    </>
  );
}

export default EditTextAltForm;
