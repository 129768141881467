import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const getFirstSaleAction = createAsyncThunk(
  'dashboard/getFirstSaleAction',
  dashboardService.getFirstSale,
);

export const markFirstSaleRealAction = createAsyncThunk(
  'dashboard/markFirstSaleRealAction',
  dashboardService.markFirstSaleRead,
);

const firstSaleSlice = createSlice({
  name: 'firstSaleSlice',
  initialState: initialState.firstSale,
  reducers: {
    clearMarkFirstSaleRealAction(state) {
      state.markFirstSaleReal.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.firstSale;
      return state;
    });

    builder
      .addCase(getFirstSaleAction.pending, (state) => {
        state.get.status = 'loading';
      })
      .addCase(getFirstSaleAction.rejected, (state) => {
        state.get.status = 'error';
      })
      .addCase(getFirstSaleAction.fulfilled, (state, action) => {
        state.get.status = 'success';
        state.get.data = action.payload;
      });

    builder
      .addCase(markFirstSaleRealAction.pending, (state) => {
        state.markFirstSaleReal.status = 'loading';
      })
      .addCase(markFirstSaleRealAction.rejected, (state) => {
        state.markFirstSaleReal.status = 'error';
      })
      .addCase(markFirstSaleRealAction.fulfilled, (state, action) => {
        state.markFirstSaleReal.status = 'success';
        state.get.data = action.payload;
      });
  },
});

export const { reducer } = firstSaleSlice;
export const { clearMarkFirstSaleRealAction } = firstSaleSlice.actions;
