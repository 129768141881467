import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { ExternalLink } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

interface GoogleMerchantIdProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  googleMerchantId: string;
}

export function GoogleMerchantId({
  onChange,
  googleMerchantId,
}: Readonly<GoogleMerchantIdProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.google.googleMerchantId',
  );
  return (
    <SplitSection title={t('title')} description={t('description')}>
      <FormField.Input
        onChange={onChange}
        value={googleMerchantId}
        name="googleMerchantId"
        placeholder={t('placeholder')}
        label={t('label')}
      />
      <ExternalLink appearance="primary" textDecoration="none" href={t('href')}>
        {t('link')}
      </ExternalLink>
    </SplitSection>
  );
}
