import { useState } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceNameValue } from '@tiendanube/components';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { useListFilters, useToast } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { defaultFilters } from 'domains/Catalog/Products/productsSlice/constants';
import { getTotalProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { UseModalProductsEditPrices } from './types';
import { useBulkActions } from '../../../BulkActionsProvider';
import { useActionProducts } from '../../useActionProducts';
import { trackingProductBulkPrice } from '../tracking';
import {
  ActionEnum,
  BulkActionTypeEnum,
  UpdateByEnum,
  EditPricesProductsDto,
} from '../types';

const initial_values = {
  priceVariation: '',
  priceVariationAction: ActionEnum.INCREASE,
  priceVariationType: UpdateByEnum.PERCENTAGE,
  promoPriceVariation: '',
  promoPriceVariationAction: ActionEnum.REPLACE,
  promoPriceVariationType: UpdateByEnum.PERCENTAGE,
};

function useModalProductsEditPrices(): UseModalProductsEditPrices {
  const t = useTranslationCatalog();
  const country = useGetCountry();
  const { filters } = useListFilters('products', defaultFilters);
  const isRoundEnabledByDefault = () => country === 'AR';
  const { setJob } = useBackgroundJobs('editPricesProducts');

  const { activeAlertPrice } = useBulkActions();

  const { editPriceProduct } = useActionProducts();

  const {
    showModal,
    selectedRowsId,
    selectAll,
    selectedAction,
    closeModal,
    clearBulkActions,
    toggleEditMode,
  } = useBulkActions();
  const productsCount = useSelector(getTotalProducts);

  const { addToast } = useToast();

  const [values, setValues] = useState(initial_values);
  const [roundPrices, setRoundPrices] = useState(isRoundEnabledByDefault);
  const [roundPromoPrices, setRoundPromoPrices] = useState(
    isRoundEnabledByDefault,
  );
  const [isLoading, setIsLoading] = useState(false);

  const resetValues = () => {
    setValues(initial_values);
  };

  const handleChange = (event: InterfaceNameValue) => {
    const { name, value } = event;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOnClose = () => {
    resetValues();
    closeModal();
  };

  const handleOnClick = async () => {
    setIsLoading(true);
    const filtersParam = !selectAll ? { productsIds: selectedRowsId } : filters;
    const editPricesProductsDto: EditPricesProductsDto = {
      ...filtersParam,
      ...values,
      priceRoundPrices: roundPrices,
      promoPriceRoundPrices: roundPromoPrices,
    };
    try {
      const { token } = await editPriceProduct(editPricesProductsDto);
      setJob(token);
      trackingProductBulkPrice(editPricesProductsDto);
      closeModal();
      activeAlertPrice();
      toggleEditMode();
      clearBulkActions();
      resetValues();
    } catch (error) {
      addToast({
        label: t('products.editPrice.saveError'),
        appearance: 'danger',
      });
    }
    setIsLoading(false);
  };

  const title = t('products.editPrice.title', {
    count: selectAll ? productsCount : selectedRowsId?.length,
  });

  const {
    priceVariation,
    priceVariationAction,
    priceVariationType,
    promoPriceVariation,
    promoPriceVariationAction,
    promoPriceVariationType,
  } = values;

  const showAlertDiscount = priceVariationAction === ActionEnum.DECREASE;

  const showAlertCreate = promoPriceVariationAction === ActionEnum.REPLACE;

  const isPercentageError = (price: string) =>
    price !== '' && !(Number(price) >= 0 && Number(price) <= 100);

  const priceVariationError =
    priceVariationType === UpdateByEnum.PERCENTAGE &&
    isPercentageError(priceVariation)
      ? t('products.editPrice.percentageError')
      : '';
  const promoPriceVariationError =
    promoPriceVariationType === UpdateByEnum.PERCENTAGE &&
    isPercentageError(promoPriceVariation)
      ? t('products.editPrice.percentageError')
      : '';

  const disabledPrimayAction = !(
    (priceVariation || promoPriceVariation) &&
    !priceVariationError &&
    !promoPriceVariationError
  );

  const mustRender =
    showModal && selectedAction === BulkActionTypeEnum.UPDATE_PRICE;

  return {
    title,
    priceVariation,
    priceVariationAction,
    priceVariationType,
    promoPriceVariation,
    promoPriceVariationAction,
    promoPriceVariationType,
    disabledPrimayAction,
    showAlertDiscount,
    showAlertCreate,
    roundPrices: roundPrices,
    roundPromoPrices: roundPromoPrices,
    showModal: mustRender,
    priceVariationError,
    promoPriceVariationError,
    isLoading,
    handleChangeRoundPrices: setRoundPrices,
    handleChangeRoundPromoPrices: setRoundPromoPrices,
    handleChange,
    handleOnClose,
    handleOnClick,
  };
}

export default useModalProductsEditPrices;
