import * as Yup from 'yup';
import { LanguagesType } from 'App/i18n';
import { validateEmojis } from '../CategoriesListPage/utils';

export const buildCategorySchema = (
  languages: LanguagesType[],
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    name: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string()
            .required('categories.detail.name.error')
            .test(
              'emoji-validation',
              'categories.detail.name.validationEmojis',
              validateEmojis,
            ),
        }),
        {},
      ),
    }),
    description: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().test(
            'emoji-validation',
            'categories.detail.name.validationEmojis',
            validateEmojis,
          ),
        }),
        {},
      ),
    }),
    googleShoppingCategory: Yup.string().test(
      'emoji-validation',
      'categories.detail.name.validationEmojis',
      validateEmojis,
    ),
    seoTitle: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().test(
            'emoji-validation',
            'categories.detail.name.validationEmojis',
            validateEmojis,
          ),
        }),
        {},
      ),
    }),
    seoDescription: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().test(
            'emoji-validation',
            'categories.detail.name.validationEmojis',
            validateEmojis,
          ),
        }),
        {},
      ),
    }),
    handle: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().required(
            'categories.detail.seoCategory.form.errorUrl',
          ),
        }),
        {},
      ),
    }),
  });
