import { BaseCard, Input, Stack, Text } from '@tiendanube/components';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';

function Skeleton(): JSX.Element {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column spacing="base" align="stretch">
          <Stack.Item>
            <Text.Skeleton size="featured" width="large" />
          </Stack.Item>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <TabsMultiLanguage>
          <Stack column align="stretch" spacing="tight">
            <Stack.Item>
              <Input.Skeleton />
            </Stack.Item>
            <Stack.Item>
              <Input.Skeleton />
            </Stack.Item>
          </Stack>
        </TabsMultiLanguage>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Skeleton;
