import { CardWithTitle, Spinner } from 'commons/components';
import { STATS_GENERAL_PAGE } from 'config/upsellFlowSources';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import ClientsBarChart from './ClientsBarChart';
import useGetClientsChart from './useGetCustomersChart';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import GenericError from '../GenericError';

function CustomersChartCard() {
  const t = useTranslationStatistics();
  const {
    fetchCustomers,
    customers,
    isLoading,
    isError,
    isSuccess,
    hasComparedFilter,
  } = useGetClientsChart();

  return (
    <CardWithTitle title={t('statistics.customersChartCard.title')}>
      <ChartOrPlaceholder
        type="bars-stacked"
        height={500}
        statsPlanRequired={STATS_PLAN_1_AND_2}
        upsellSource={STATS_GENERAL_PAGE}
      >
        {isLoading && <Spinner size="medium" />}
        {isError && <GenericError onRetry={fetchCustomers} />}
        {isSuccess && customers && (
          <ClientsBarChart
            granularity={customers.granularity}
            hasComparedFilter={hasComparedFilter}
            values={customers.results}
          />
        )}
      </ChartOrPlaceholder>
    </CardWithTitle>
  );
}

export default CustomersChartCard;
