import { useSelector } from 'react-redux';
import { LanguagesType } from 'App/i18n';
import { getAllLanguages } from '../../authSlice/authSelectors';

function useGetAllLanguages(): LanguagesType[] {
  const languages = useSelector(getAllLanguages);

  return languages as LanguagesType[];
}

export default useGetAllLanguages;
