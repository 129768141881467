import {
  InterfaceSelectOption,
  InterfaceNameValue,
  Select,
  Stack,
  Text,
  Alert,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

export const TRANSLATE_PREFIX = 'advancedSettingsModal.transparentCheckout';

interface TransparentCheckoutGroup {
  value: string;
  options: InterfaceSelectOption[];
}

export interface TransparentCheckoutValuesInterface {
  card?: TransparentCheckoutGroup;
  debit?: TransparentCheckoutGroup;
  offline?: TransparentCheckoutGroup;
  pix?: TransparentCheckoutGroup;
  wireTransfer?: TransparentCheckoutGroup;
}

interface TransparentCheckoutCardProps
  extends TransparentCheckoutValuesInterface {
  shouldShowMercadoPagoPixWarning?: boolean;
  onChange: (event: InterfaceNameValue) => void;
}

function TransparentCheckoutCard({
  card,
  debit,
  offline,
  pix,
  wireTransfer,
  shouldShowMercadoPagoPixWarning,
  onChange,
}: TransparentCheckoutCardProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <CardWithTitle title={t('title')}>
      <Stack column align="stretch">
        <Text>{t('subtitle')}</Text>
        {card && (
          <Select
            onChange={onChange}
            value={card.value}
            label={t('card')}
            name="card"
            options={card.options}
          />
        )}
        {debit && (
          <Select
            onChange={onChange}
            value={debit.value}
            label={t('debit')}
            name="debit"
            options={debit.options}
          />
        )}
        {offline && (
          <Select
            onChange={onChange}
            value={offline.value}
            label={t('offline')}
            name="offline"
            options={offline.options}
          />
        )}
        {pix && (
          <>
            <Select
              onChange={onChange}
              value={pix.value}
              label={t('pix')}
              name="pix"
              options={pix.options}
            />
            <Alert appearance="primary" show={shouldShowMercadoPagoPixWarning}>
              {t('alertPix')}
            </Alert>
          </>
        )}
        {wireTransfer && (
          <Select
            onChange={onChange}
            value={wireTransfer.value}
            label={t('wireTransfer')}
            name="wireTransfer"
            options={wireTransfer.options}
          />
        )}
      </Stack>
    </CardWithTitle>
  );
}

export default TransparentCheckoutCard;
