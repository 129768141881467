import { SectionCodesResponseDto } from '@tiendanube/common';
import { InterfaceNameChecked } from '@tiendanube/components';
import { useModal } from 'commons/hooks';
import { HighlightProductCard, HighlightProductModalAside } from './components';
import useHighlightProduct from './useHighlightProduct';
import { removeErrorsFromSectionCodes } from '../../EditProductPage/digests';

interface HighlightProductProps {
  sectionCodes: SectionCodesResponseDto;
  onChange: (event: InterfaceNameChecked) => void;
  isEdit: boolean;
  productId?: string;
}

function HighlightProduct({
  sectionCodes,
  onChange,
  isEdit,
  productId,
}: HighlightProductProps): JSX.Element {
  const [isShowModal, openModal, closeModal] = useModal();

  const {
    productSections,
    showError,
    handleReTry,
    showSkeleton,
    defaultProductSections,
  } = useHighlightProduct({ sectionCodes, isEdit, productId });

  if (showSkeleton) return <HighlightProductCard.Skeleton />;
  if (showError)
    return <HighlightProductCard.ErrorState onRetry={handleReTry} />;

  return (
    <>
      <HighlightProductCard
        onChange={onChange}
        onOpen={openModal}
        sectionsSelected={removeErrorsFromSectionCodes(sectionCodes)}
        sections={productSections}
      />
      {isShowModal && (
        <HighlightProductModalAside
          sections={productSections}
          defaultProductSections={removeErrorsFromSectionCodes(
            defaultProductSections,
          )}
          sectionsSelected={removeErrorsFromSectionCodes(sectionCodes)}
          onChange={onChange}
          onClose={closeModal}
        />
      )}
    </>
  );
}

export default HighlightProduct;
