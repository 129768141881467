import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { Repeater } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function PaymentMethodSkeleton() {
  return (
    <>
      <hr />
      <Box
        padding="4"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text.Skeleton height="30px" width="30%" />
        <ChevronRightIcon />
      </Box>
    </>
  );
}

export default function ChoosePaymentMethodSkeleton() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodWithRecurrency.chooseMethod',
  );
  return (
    <Card padding="none">
      <Box display="flex" flexDirection="column">
        <Box padding="4">
          <Title as="h4">{t('title')}</Title>
        </Box>
        <Repeater times={3} item={PaymentMethodSkeleton} />
      </Box>
    </Card>
  );
}
