import { useParams } from 'react-router';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useAbandonedCartDetails } from 'domains/Orders/AbandonedCart/hooks';
import {
  AbandonedCartDetails,
  AbandonedCartDetailsError,
  AbandonedCartDetailsSkeleton,
} from './components';

function AbandonedCartDetailsPage() {
  const { goBack } = useNavegate();
  const { id } = useParams<{ id: string }>();
  const {
    abandonedCartDetails,
    fetchAbandonedCartDetails,
    isLoading,
    isError,
  } = useAbandonedCartDetails(id);

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          navigation={{
            onClick: goBack,
          }}
        />
      }
      headerContent={<HeaderContent title={`#${id}`} />}
    >
      {isLoading && <AbandonedCartDetailsSkeleton />}
      {isError && (
        <AbandonedCartDetailsError onRetryError={fetchAbandonedCartDetails} />
      )}
      {abandonedCartDetails && (
        <AbandonedCartDetails abandonedCartDetails={abandonedCartDetails} />
      )}
    </IonPageStratus>
  );
}

export default AbandonedCartDetailsPage;
