import { Trans } from 'react-i18next';
import { Link, Text } from '@tiendanube/components';
import { FEATURE_ONLY_NUVEM_PAGO } from 'App/features';
import { Stack } from 'commons/components';
import {
  useGetIsNext,
  useGoToPlans,
  useStatusFeatureByKey,
} from 'domains/Auth/hooks';
import { ModalConfirmationWithAuth } from 'domains/AuthenticationFactor';
import { invokeEventNuvemPagoDisabled } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProvider.disableNuvemPagoModal';

interface DisableNuvemPagoModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

function DisableNuvemPagoModal({
  onCancel,
  onConfirm,
}: DisableNuvemPagoModalProps): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { goToPlans } = useGoToPlans();

  const { maxUse } = useStatusFeatureByKey(FEATURE_ONLY_NUVEM_PAGO);

  const isNext = useGetIsNext();

  const plan = maxUse === 1 ? 'freemium' : isNext ? 'next' : 'others';

  const title = t(`${plan}.title`);
  const textFirstkey = `${plan}.first`;
  const textSecondKey = `${plan}.second`;
  const confirm = t(`confirm`);
  const cancel = t(`cancel`);

  const handleConfirm = () => {
    invokeEventNuvemPagoDisabled(plan);
    onConfirm();
  };

  const text = (
    <Stack column align="flex-start">
      <Text>
        <Trans
          t={t}
          i18nKey={textFirstkey}
          components={{
            PlanLink: <Link appearance="primary" onClick={goToPlans} />,
          }}
        />
      </Text>
      <Text>
        <Trans
          t={t}
          i18nKey={textSecondKey}
          components={{
            PlanLink: <Link appearance="primary" onClick={goToPlans} />,
          }}
        />
      </Text>
    </Stack>
  );

  return (
    <ModalConfirmationWithAuth
      title={title}
      text={text}
      labelConfirm={confirm}
      labelCancel={cancel}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    />
  );
}

export default DisableNuvemPagoModal;
