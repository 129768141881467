import { IconButton, Text, Link, Box, Tag } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { Link as LinkRoute, generatePath } from 'react-router-dom';
import { DataList } from '@tiendanube/components';
import { ModalConfirmationAction } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { CONTENT_PAGES_ROUTES } from 'domains/Online/ContentPages';
import { usePagesList } from 'domains/Online/ContentPages/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

const { Row } = DataList;

interface PagesListItemProps {
  id: string;
}

function PagesListItem({ id }: PagesListItemProps) {
  const t = useTranslationOnline('contentPages.pageList');
  const language = useGetLanguage();
  const { pages, deleteStatus, deleteContentPage } = usePagesList();
  const page = pages?.find((page) => page.id === id);
  const goToPage = generatePath(CONTENT_PAGES_ROUTES.pageEditing, { id });
  const [showRemoveModal, openRemoveModal, closeRemoveModal] = useModal();

  const handleDelete = () => deleteContentPage(id);

  if (!page) return null;

  return (
    <>
      <Row id={id}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          paddingLeft="2"
          paddingRight="2"
        >
          <Box display="flex" flexDirection="column" gap="2">
            <Link
              as={LinkRoute}
              to={goToPage}
              appearance="primary"
              textDecoration="none"
            >
              {page.title[language]}
              {!page.showInStore && (
                <Tag appearance="warning">{t('draft')}</Tag>
              )}
            </Link>
            <Text>{page.lastUpdate}</Text>
          </Box>
          <Box>
            <IconButton
              onClick={openRemoveModal}
              source={<TrashIcon />}
              disabled={deleteStatus === 'loading'}
              size="2rem"
            />
          </Box>
        </Box>
      </Row>
      {showRemoveModal && (
        <ModalConfirmationAction
          title={t('removeAction.title')}
          body={t('removeAction.body')}
          btnOk={t('removeAction.btnOk')}
          btnCancel={t('removeAction.btnCancel')}
          textProgress={t('removeAction.textProgress')}
          textSuccess={t('removeAction.textSuccess')}
          textError={t('removeAction.textError')}
          appearance="danger"
          onAction={handleDelete}
          onClose={closeRemoveModal}
        />
      )}
    </>
  );
}

export default PagesListItem;
