import { Box, Card, Text, Title, Link } from '@nimbus-ds/components';
import { ExternalLink, IconBox } from 'commons/components';

interface ImproveYourStoreCardProps {
  title: string;
  description: string;
  link: string;
  linkText: string;
  icon: React.ReactNode;
  onClick: () => void;
}

function ImproveYourStoreCard({
  title,
  description,
  link,
  linkText,
  icon,
  onClick,
}: ImproveYourStoreCardProps) {
  const LinkComponent = isExternalLink(link) ? ExternalLink : Link;

  return (
    <Card>
      <Card.Header>
        <Box display="flex" gap="4" alignItems="center">
          <IconBox
            color="primary-interactive"
            backgroundColor="primary-surface"
          >
            {icon}
          </IconBox>
          <Title as="h4">{title}</Title>
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="4">
        <Text>{description}</Text>
      </Box>
      <LinkComponent
        onClick={onClick}
        href={link}
        appearance="primary"
        textDecoration="none"
      >
        {linkText}
      </LinkComponent>
    </Card>
  );
}

function isExternalLink(urlOrPath: string) {
  if (urlOrPath.startsWith('/')) {
    return false;
  }

  try {
    const targetURL = new URL(urlOrPath);
    return window.location.origin !== targetURL.origin;
  } catch (err) {
    return true;
  }
}

export default ImproveYourStoreCard;
