import { IconButton, Tag, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

export function Skeleton() {
  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="4">
        <Text.Skeleton width="200px" />
        <Stack justify="space-between" align="center">
          <Stack>
            <Tag.Skeleton height="20px" />
            <Tag.Skeleton height="20px" />
          </Stack>
          <IconButton.Skeleton height="32px" width="32px" />
        </Stack>
      </Stack>
    </DataList.Row>
  );
}
