import { EmptyState, Text } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import emptyCategries from './empty-categories.png';

interface CategoriesListEmptyProps {
  onClose: () => void;
}

function CategoriesListEmpty({
  onClose,
}: CategoriesListEmptyProps): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <EmptyState
      image={emptyCategries}
      title={t('categories.emptyResults.title')}
      primaryActionLabel={t('categories.action')}
      onClickPrimary={onClose}
    >
      <Text>{`${t('categories.emptyResults.body')}`}</Text>
    </EmptyState>
  );
}

export default CategoriesListEmpty;
