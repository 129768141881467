import { Icon, Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Link as LinkRoute } from 'react-router-dom';
import { LogOutIcon } from '@tiendanube/icons';
import { HeaderContent, IonPageStratus } from 'commons/components';
import { AUTH_ROUTES } from 'domains/Auth/authRoutes';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

export function AdminDisabledScreen() {
  const t = useTranslationDashboard();
  return (
    <IonPageStratus headerContent={<HeaderContent title={t('title')} />}>
      <EmptyMessage
        title={t('adminDisabled.title')}
        icon={<ExclamationTriangleIcon size={32} />}
        text={t('adminDisabled.description')}
        actions={
          <Link
            as={LinkRoute}
            to={AUTH_ROUTES.logout}
            appearance="primary"
            textDecoration="none"
          >
            {t('adminDisabled.cta')}
            <Icon color="currentColor" source={<LogOutIcon />} />
          </Link>
        }
      />
    </IonPageStratus>
  );
}
