import { Card, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { useGetCountry } from 'domains/Auth/hooks';
import { trackingFreeCoursesClick } from 'domains/Dashboard/tracking';

interface FreeContentCardProps {
  text: string;
  link: string;
}

const FREE_CONTENT_CARD_STYLE_ICON = {
  verticalAlign: 'text-top',
  marginLeft: '4px',
};

function FreeContentCard({ text, link }: FreeContentCardProps) {
  const country = useGetCountry();
  const handleClick = () => {
    trackingFreeCoursesClick({ country });
  };
  return (
    <Card>
      <Link
        as="a"
        appearance="primary"
        textDecoration="none"
        href={link}
        onClick={handleClick}
        target="_blank"
      >
        <Text color="currentColor">
          {text}
          <ExternalLinkIcon style={FREE_CONTENT_CARD_STYLE_ICON} />
        </Text>
      </Link>
    </Card>
  );
}

export default FreeContentCard;
