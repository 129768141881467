import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { tagsByStoreAction } from 'domains/Catalog/Products/productsSlice';
import {
  tagsByStoreData,
  tagsByStoreStatus,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

function useFetchTags() {
  const dispatch = useAppDispatch();
  const status = useSelector(tagsByStoreStatus);
  const tags = useSelector(tagsByStoreData);

  const optionsTags = tags
    ? tags.map((tag) => ({
        label: tag,
        value: tag,
      }))
    : [];

  const fetchStandardTags = useCallback(() => {
    dispatch(tagsByStoreAction());
  }, [dispatch]);

  return {
    ...convertStatusTypeToObject(status),
    optionsTags,
    fetchStandardTags,
  };
}

export default useFetchTags;
