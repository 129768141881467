import { useCallback } from 'react';
import { PaymentProviderDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanUpdateStatusAction,
  disablePaymentProviderAction,
} from '../../paymentProvidersSlice';
import { HookUtils } from '../utils';

interface UseDisableInstalledPaymentProviderResult {
  disable: () => Promise<void>;
}

function useDisableInstalledPaymentProvider(
  paymentProvider?: PaymentProviderDto,
): UseDisableInstalledPaymentProviderResult {
  const dispatch = useAppDispatch();

  return {
    disable: useCallback(async () => {
      await dispatch(
        disablePaymentProviderAction(HookUtils.getProviderId(paymentProvider)),
      );
      dispatch(cleanUpdateStatusAction());
    }, [dispatch, paymentProvider]),
  };
}

export default useDisableInstalledPaymentProvider;
