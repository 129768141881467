import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDashboard } from '../../firstSaleSlice';

const getTrialMessagesEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.trialMessages,
);

export const getTrialMessagesData = createSimpleSelector(
  [getTrialMessagesEntity],
  (trialMessages) => trialMessages.data,
);

export const getTrialMessagesStatus = createSimpleSelector(
  [getTrialMessagesEntity],
  (trialMessages) => convertStatusTypeToObject(trialMessages.status),
);
