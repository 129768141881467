import { useGetFeatures } from 'domains/Auth/hooks';

function useShowByFeature(feature: string): boolean {
  const storeFeatures = useGetFeatures();
  const featureExists = storeFeatures.includes(feature);

  return featureExists;
}

export default useShowByFeature;
