import { combineReducers } from 'redux';
import { reducer as contentPagesReducer } from './ContentPages/contentPagesSlice';
import { reducer as themesReducer } from './Themes/themesSlice';

const onlineReducer = combineReducers({
  themes: themesReducer,
  pages: contentPagesReducer,
});

export default onlineReducer;
