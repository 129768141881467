import { DataList } from '@nimbus-ds/patterns';
import { DomainsListMobileRow } from './DomainsListMobileRow/DomainsListMobileRow';
import { Skeleton } from './Skeleton';
import { DomainsListProps } from '../types';
import './DomainsListMobile.scss';

export function DomainsListMobile({
  domains,
  onDeleteDomain,
  isDeleting,
  isUpdating,
  onSetPrimaryDomain,
}: Readonly<DomainsListProps>) {
  return (
    <div className="stratus--domains-list-mobile">
      <DataList>
        {domains.map((domain) => (
          <DomainsListMobileRow
            key={domain.id}
            domain={domain}
            domainsCount={domains.length}
            ourDomain={domains.find((domain) => domain.isOurDomain)?.name ?? ''}
            onDeleteDomain={() => onDeleteDomain(domain.id)}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onSetPrimaryDomain={onSetPrimaryDomain}
          />
        ))}
      </DataList>
    </div>
  );
}

DomainsListMobile.Skeleton = Skeleton;
