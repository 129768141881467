import { ReactNode, ReactElement, cloneElement } from 'react';
import classNames from 'classnames';
import { Stack } from '@tiendanube/components';
import ScrollProvider from 'App/components/AppLayout/ScrollProvider';
import { HeaderContent } from 'commons/components';
import FooterBottom from '../FooterBottom';
import HeaderTop from '../HeaderTop';
import Modal from '../Modal/Modal';
import useMountTransition from '../Modal/useMountTransition';
import './ModalAside.scss';

const TRANSITION_TIME = 600;

interface ModalAsideProps {
  onDidDismiss?: () => void;
  children: ReactNode;
  headerTop?: ReactNode;
  footer?: ReactNode;
  footerHeight?: string;
  headerContent?: ReactNode | string;
  fluid?: boolean;
  isOpen: boolean;
  hasVirtualizationContent?: boolean;
  extraHeaderGap?: boolean;
}

function ModalAside({
  onDidDismiss,
  children,
  headerTop,
  footer,
  footerHeight = '0px',
  headerContent,
  fluid,
  isOpen,
  hasVirtualizationContent = false,
  extraHeaderGap = false,
}: ModalAsideProps) {
  const hasTransitionedIn = useMountTransition(isOpen, TRANSITION_TIME);
  const className = `modal-container ${hasTransitionedIn && 'in'} ${
    isOpen && 'visible'
  }`;

  function backNavigation() {
    if (onDidDismiss) {
      return {
        onClick: onDidDismiss,
      };
    }
  }

  // In Aside Modals we need to enforce herderTop to be always visible (Mobile and Desktop)
  const headerTopAllways = () =>
    cloneElement(headerTop as ReactElement, { showAllways: true });

  function HeaderContentCompose() {
    return typeof headerContent === 'string' ? (
      <HeaderContent title={headerContent} />
    ) : (
      headerContent
    );
  }

  const HeaderTopCompose = !headerTop ? (
    <HeaderTop navigation={backNavigation()} showAllways />
  ) : (
    headerTopAllways()
  );

  if (!hasTransitionedIn) return null;

  return (
    <Modal className={`${className} modal-aside`} handleClose={onDidDismiss}>
      {HeaderTopCompose}
      <ScrollProvider isFromModal heightOffset={footer ? footerHeight : '0px'}>
        <div
          className={classNames('modal-aside-content__container', {
            'full-content__area': !hasVirtualizationContent,
            'fluid-content__area': fluid,
          })}
        >
          <div
            className={classNames({
              'modal-aside-extra-header__gap': extraHeaderGap,
            })}
          >
            {HeaderContentCompose()}
          </div>
          <div className="modal-aside__content">
            <Stack column align="stretch" spacing="loose">
              {children}
            </Stack>
          </div>
        </div>
      </ScrollProvider>

      {!!footer && <FooterBottom height={footerHeight}>{footer}</FooterBottom>}
    </Modal>
  );
}

export default ModalAside;
