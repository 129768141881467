import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { changeIsEmbeddedApp as changeIsEmbebedAppAction } from 'domains/Auth/authSlice';
import { getIsEmbeddedApp } from 'domains/Auth/authSlice/authSelectors';

function useIsEmbeddedApp() {
  const dispatch = useAppDispatch();
  const isEmbeddedApp = useSelector(getIsEmbeddedApp);

  const changeIsEmbeddedApp = useCallback(
    (isEmbeddedApp: boolean) => {
      dispatch(changeIsEmbebedAppAction(isEmbeddedApp));
    },
    [dispatch],
  );

  return {
    isEmbeddedApp,
    changeIsEmbeddedApp,
  };
}

export default useIsEmbeddedApp;
