import { Route, Switch } from 'react-router-dom';
import {
  NewPromotionPage,
  EditPromotionPage,
  PromotionsListPage,
} from './pages';
import { PROMOTIONS_ROUTES } from './promotionsRoutes';

function Promotions() {
  return (
    <Switch>
      <Route path={PROMOTIONS_ROUTES.promotionsList} exact>
        <PromotionsListPage />
      </Route>
      <Route path={PROMOTIONS_ROUTES.newPromotions} exact>
        <NewPromotionPage />
      </Route>
      <Route path={PROMOTIONS_ROUTES.editPromotions} exact>
        <EditPromotionPage />
      </Route>
    </Switch>
  );
}

export default Promotions;
