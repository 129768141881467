import {
  MetafieldSelectedDetails,
  MetafieldSelectedsOrErrorListV2Type,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCustomerMetafields = async (
  customerId: string,
): Promise<MetafieldSelectedsOrErrorListV2Type> => {
  const { data } = await axios.get(`v1/customers/${customerId}/metafields`);
  return data;
};

const getCustomerMetafieldsDetails = async (
  customerId: string,
): Promise<MetafieldSelectedDetails[]> => {
  const { data } = await axios.get(
    `v1/customers/${customerId}/metafields/plain`,
  );
  return data;
};

const customerMetafieldsService = {
  getCustomerMetafields,
  getCustomerMetafieldsDetails,
};

export default customerMetafieldsService;
