import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppsAdminLinksResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useIsLogged } from 'domains/Auth/hooks';
import { getAppsLinksSelector } from 'domains/PartnersApps/partnersAppsSlice';
import { getAppsLinks as getAppsLinksAction } from 'domains/PartnersApps/partnersAppsSlice/partnersAppsSlice';

type AppLinkType = 'default' | 'bulk-action';

interface UseAppsLinksResult {
  fetchAppsLinks: () => void;
  getAppsLinks: (
    section: string,
    type: AppLinkType,
  ) => AppsAdminLinksResponseDto[];
  generateBulkAppLink: (url: string, ids: Array<string | number>) => string;
}

function useAppsLinks(): UseAppsLinksResult {
  const dispatch = useAppDispatch();
  const { isLogin } = useIsLogged();

  const appsLinks = useSelector(getAppsLinksSelector);

  const fetchAppsLinks = useCallback(() => {
    if (isLogin) dispatch(getAppsLinksAction());
  }, [dispatch, isLogin]);

  const getAppsLinks = useCallback(
    (section: string, type: AppLinkType) =>
      appsLinks.filter(
        (appLink) => appLink.section === section && appLink.type === type,
      ),
    [appsLinks],
  );

  const generateBulkAppLink = (url: string, ids: Array<string | number>) =>
    `${url}&id[]=${ids.join('&id[]=')}`;

  return {
    fetchAppsLinks,
    getAppsLinks,
    generateBulkAppLink,
  };
}

export default useAppsLinks;
