import { InterfaceIdLabel } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import formatDateFilterChips from 'domains/Metafields/utils/formatDateFilterChips';

function useProductListSearch({ onChange, filters, isLoading, productsCount }) {
  const t = useTranslationCatalog();
  const categories = useGetCategories();
  const { isDesktop } = useResponsive();

  const handleChangeText = (text: string) => {
    onChange({ ...filters, q: text, sortBy: '' });
  };

  const handleDismiss = (key: string) => {
    if (key === 'stock') {
      return onChange({
        ...filters,
        ['stock']: '',
        ['maxStock']: '',
        ['minStock']: '',
      });
    }
    onChange({ ...filters, [key]: '' });
  };

  const onlyFiltersToLabel = ([filter, value]) => {
    const isEmptyValue = value === '';
    const isPage = filter === 'page';
    const isSortBy = filter === 'sortBy';
    const isQuantityStock = filter === 'maxStock' || filter === 'minStock';
    return !isEmptyValue && !isPage && !isQuantityStock && !isSortBy;
  };

  const buildLabel = ([filter, value]) => {
    if (filter === 'categoryId') {
      if (value !== 'has_no_categories') {
        return {
          id: filter,
          label: categories.find(({ id }) => `${id}` === value)?.title || '',
        };
      }

      return {
        id: filter,
        label: t('products.filters.categoryId.hasNoCategories'),
      };
    }
    if (value === 'quantity' && filter === 'stock') {
      return {
        id: filter,
        label:
          filters.maxStock === ''
            ? t(`products.filters.quantityMaxStock`, {
                min: filters.minStock || 0,
              })
            : t(`products.filters.quantityStock`, {
                min: filters.minStock || 0,
                max: filters.maxStock,
              }),
      };
    }
    if (filter.startsWith('mf-')) {
      return {
        id: filter,
        label: formatDateFilterChips(value),
      };
    }
    return {
      id: filter,
      label: t(`products.filters.${filter}.${value}`, value as string),
    };
  };

  const optionsLabels: InterfaceIdLabel[] = isLoading
    ? []
    : Object.entries(filters).filter(onlyFiltersToLabel).map(buildLabel);

  const hasAppliedFilters = optionsLabels.length > 0;
  const hasSearchFilter = filters.q; // La exportación no acepta este parametro
  const showExportFiltersLink =
    hasAppliedFilters && !isLoading && productsCount > 0 && !hasSearchFilter;

  const resultCount = isLoading
    ? ''
    : hasAppliedFilters
    ? t('products.filters.filterResult', { count: productsCount })
    : t('products.filters.result', { count: productsCount });

  const label = isDesktop ? t('products.filter.filter') : '';

  return {
    handleChangeText,
    handleDismiss,
    optionsLabels,
    showExportFiltersLink,
    resultCount,
    label,
  };
}

export default useProductListSearch;
