import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OwnerResourceType } from '@tiendanube/common';
import { useListFilters, useToast } from 'commons/hooks';
import { defaultFilters } from 'domains/Catalog/Products/productsSlice/constants';
import { getTotalProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import {
  trackingProductBulkMetafieldsCancel,
  trackingProductBulkMetafieldsSave,
  trackingProductDomainBulkMetafieldsCancel,
  trackingProductDomainBulkMetafieldsSave,
} from 'domains/Metafields/components/ProductsAndVariants/ModalAssignMetafields/tracking';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import { useBulkActions } from '../../ProductListPage/components/BulkActionsProvider';
import { useBulkMetafieldsAssignment } from '../../ProductListPage/components/hooks';
import { BulkActionTypeEnum } from '../../ProductListPage/components/hooks/useBulkActionProducts/types';

type ValuesType = {
  productsId: string[];
  metafields: { id: string; value: string }[];
};

const initialValues: ValuesType = {
  productsId: [],
  metafields: [],
};

function useModalAssignMetafields(ownerResource: OwnerResourceType) {
  const t = useTranslationsMetafields();
  const {
    handleBulkMetafieldsAssignment,
    isLoading,
    isError,
    isSuccess,
    clearStatus,
  } = useBulkMetafieldsAssignment();

  const {
    showModal,
    selectedRowsId,
    selectedAction,
    selectAll,
    closeModal,
    clearBulkActions,
    toggleEditMode,
    activeAlertMetafields,
  } = useBulkActions();
  const { filters } = useListFilters('products', defaultFilters);
  const productsCount = useSelector(getTotalProducts);

  const { addToast } = useToast();

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (isSuccess) {
      clearStatus();
      closeModal();
      toggleEditMode();
      clearBulkActions();
      resetValues();
      trackingBulkMetafieldsSave();
      activeAlertMetafields();
    }

    if (isError) {
      addToast({
        label: t('assignProductsMetafieldsModal.saveError'),
        appearance: 'danger',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, productsId: selectedRowsId }));
  }, [selectedRowsId]);

  const trackingBulkMetafieldsSave = () => {
    const trackingFn =
      ownerResource === 'product_variant'
        ? trackingProductBulkMetafieldsSave
        : trackingProductDomainBulkMetafieldsSave;

    trackingFn(
      values.productsId,
      productsCount,
      values.metafields.map(({ id }) => id),
    );
  };

  const trackingBulkMetafieldsCancel = () => {
    const trackingCancelFn =
      ownerResource === 'product_variant'
        ? trackingProductBulkMetafieldsCancel
        : trackingProductDomainBulkMetafieldsCancel;

    trackingCancelFn(values.productsId);
  };

  const resetValues = () => {
    setValues(initialValues);
  };

  const handleChange = (id: string, value: string) => {
    setValues((prevState) => ({
      ...prevState,
      metafields: prevState.metafields
        .filter((m) => m.id !== id)
        .concat({ id, value }),
    }));
  };

  const handleOnClose = () => {
    trackingBulkMetafieldsCancel();
    resetValues();
    closeModal();
  };

  const handleClick = () => {
    handleBulkMetafieldsAssignment({
      selectAll,
      totalCount: productsCount,
      productsId: values.productsId.map(Number),
      filters,
      metafields: values.metafields.map(({ id, value }) => ({
        id,
        value,
      })),
    });
  };

  const bulkAction =
    ownerResource === 'product_variant'
      ? BulkActionTypeEnum.ASSIGN_METAFIELDS
      : BulkActionTypeEnum.ASSIGN_PRODUCT_METAFIELDS;

  const mustRender = showModal && selectedAction === bulkAction;

  return {
    showModal: mustRender,
    values,
    isLoading,
    handleChange,
    handleOnClose,
    handleClick,
  };
}

export default useModalAssignMetafields;
