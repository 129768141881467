import { Box, Card, Skeleton, Title } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function CustomerInformationCardSkeleton() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.customerInformationCard',
  );

  return (
    <Card>
      <Card.Header>
        <Title fontSize="h2">{t('title')}</Title>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Box display="flex" flexDirection="column" gap="2">
            <Title.Skeleton height="1.5em" />
            <Box display="flex" flexDirection="row" gap="2">
              <Skeleton height="1rem" width="16px" />
              <Skeleton height="1rem" width="200px" />
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap="2">
            <Title.Skeleton height="1.5em" />
            <Box display="flex" flexDirection="row" gap="2">
              <Skeleton height="1rem" width="16px" />
              <Skeleton height="1rem" width="200px" />
            </Box>
            <Box display="flex" flexDirection="row" gap="2">
              <Skeleton height="1rem" width="16px" />
              <Skeleton height="1rem" width="200px" />
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
