import { Accordion } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { ARecordInfo } from './ARecordInfo';

interface ARecordStepProps {
  step: number;
  domainId: string;
}

export function ARecordStep({ step, domainId }: Readonly<ARecordStepProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.a');
  const index = `${step - 1}`;

  return (
    <Accordion.Item index={index}>
      <Accordion.Header title={`${step}. ${t('title')}`} />
      <Accordion.Body>
        <ARecordInfo domainId={domainId} />
      </Accordion.Body>
    </Accordion.Item>
  );
}
