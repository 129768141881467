import { useState } from 'react';
import {
  OptionsBusinessInfoResponseDto,
  ValuesBusinessInfoResponseDto,
} from '@tiendanube/common';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import accountServices from 'domains/Account/accountServices';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import useRefreshInfoStoreUser from 'domains/Auth/hooks/useRefreshInfoToSync';
import { initialInfo, initialOptions } from './initialValues';
import { UseBusinessDataResult } from './types';
import { buildValidationSchema } from './validationSchema';
import useTranslationAccount from '../useTranslationAccount';

function useBusinessData(): UseBusinessDataResult {
  const t = useTranslationAccount();
  const { addToast } = useToast();
  const [businessDataInfo, setBusinessDataInfo] =
    useState<ValuesBusinessInfoResponseDto>(initialInfo);
  const [businessDataOptions, setBusinessDataOptions] =
    useState<OptionsBusinessInfoResponseDto>(initialOptions);
  const refreshInfo = useRefreshInfoStoreUser();
  const languages = useGetAllLanguages();
  const validationSchema = buildValidationSchema(languages);

  const [fetchBusinessData, isLoading, isError] = useAsyncFunc(async () => {
    const { values, options } = await accountServices.getBusinessData();
    setBusinessDataInfo(values);
    setBusinessDataOptions(options);
  });

  const handleUdpate = async () => {
    await accountServices.updateBusinessData(businessDataValues);
    setBusinessDataInfo(businessDataValues);
    refreshInfo(true);
  };

  const handleUpdateSuccess = () => {
    addToast({
      label: t('businessDataPage.update.success'),
      appearance: 'success',
    });
  };

  const handleUpdateError = () => {
    addToast({
      label: t('businessDataPage.update.error'),
      appearance: 'danger',
    });
  };

  const [updateBusinessData, isLoadingUpdate] = useAsyncFunc(
    handleUdpate,
    handleUpdateSuccess,
    handleUpdateError,
  );

  const {
    values: businessDataValues,
    errors,
    isDirty,
    handleOnSubmit,
    setFieldValue,
  } = useForm<ValuesBusinessInfoResponseDto>({
    initialValues: businessDataInfo,
    onSubmit: updateBusinessData,
    validationSchema,
  });

  const setBusinessDataValues = ({ name, value }) => {
    setFieldValue(name, value);
  };

  return {
    fetchBusinessData,
    setBusinessDataValues,
    isLoading,
    isError,
    errors,
    businessDataValues,
    businessDataOptions,
    handleOnSubmit,
    isLoadingUpdate,
    isDirty,
  };
}

export default useBusinessData;
