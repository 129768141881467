import { useCallback, useEffect } from 'react';
import { Button } from '@nimbus-ds/components';
import {
  DISCOUNT_VALUES,
  HAS_USE_LIMIT_VALUES,
  HAS_MIN_VALUE_VALUES,
  INCLUDE_SHIPPING_VALUES,
  STATUS_VALUES,
  TERM_VALUES,
} from '@tiendanube/common/src/domains/marketing/coupons/types';
import ModalAside from 'App/components/lab/ModalAside';
import { SHOW_DELETED_COUPONS_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { Stack } from 'commons/components';
import {
  CouponFiltersType,
  defaultFilters,
} from 'domains/Marketing/Coupons/couponsService';
import useCouponsFilters from 'domains/Marketing/Coupons/hooks/useCouponsFilters';
import {
  trackingCouponsFiltered,
  trackingDeletedCouponsFiltered,
} from 'domains/Marketing/Coupons/tracking';
import useTranslationMarketing from 'domains/Marketing/hook/useTranslationMarketing';
import { CreatedAt, RadioGroupFilter } from './components';

interface FilterModalProps {
  show: boolean;
  hideModal: () => void;
  filter: CouponFiltersType;
  addParam: (f: CouponFiltersType) => void;
}

function FilterModal({
  show,
  filter,
  addParam,
  hideModal,
}: Readonly<FilterModalProps>) {
  const t = useTranslationMarketing('coupons.filters');

  const { values, handleChange, setAllValues } = useCouponsFilters(filter);

  useEffect(() => setAllValues(filter), [filter, setAllValues]);

  const handleApply = useCallback(() => {
    trackingCouponsFiltered();
    if (values.status === 'deleted') trackingDeletedCouponsFiltered();
    addParam(values);
    hideModal();
  }, [addParam, values, hideModal]);

  const handleReset = () =>
    setAllValues({ ...values, ...defaultFilters, sortBy: values.sortBy });

  const [hasDeletedCouponsTag] = useHasTags(SHOW_DELETED_COUPONS_TAG);

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={hideModal}
      headerContent={t('filterBy')}
    >
      <Stack column align="stretch">
        <RadioGroupFilter
          name="discountType"
          value={values.discountType}
          options={DISCOUNT_VALUES}
          onChange={handleChange}
        />
        <RadioGroupFilter
          name="includeShipping"
          value={values.includeShipping}
          options={INCLUDE_SHIPPING_VALUES}
          onChange={handleChange}
        />
        <RadioGroupFilter
          name="hasUseLimit"
          value={values.hasUseLimit}
          options={HAS_USE_LIMIT_VALUES}
          onChange={handleChange}
        />
        <RadioGroupFilter
          name="termType"
          value={values.termType}
          options={TERM_VALUES}
          onChange={handleChange}
        />
        <RadioGroupFilter
          name="hasMinValue"
          value={values.hasMinValue}
          options={HAS_MIN_VALUE_VALUES}
          onChange={handleChange}
        />
        <RadioGroupFilter
          name="status"
          value={values.status}
          options={
            hasDeletedCouponsTag
              ? STATUS_VALUES
              : STATUS_VALUES.filter((status) => status !== 'deleted')
          }
          onChange={handleChange}
        />
        <CreatedAt
          dateFrom={values.createdAtMin}
          dateTo={values.createdAtMax}
          onChange={handleChange}
        />

        <Stack justify="flex-end" alignSelf="stretch">
          <Button onClick={handleReset}>{t('cleanFilter')}</Button>
          <Button onClick={handleApply} appearance="primary">
            {t('filter')}
          </Button>
        </Stack>
      </Stack>
    </ModalAside>
  );
}

export default FilterModal;
