import {
  BoxPackedIcon,
  ClockIcon,
  DrinkIcon,
  FlagIcon,
  GlassesIcon,
  HistoryIcon,
  HomeIcon,
  InvoiceIcon,
  LocationIcon,
  MoonIcon,
  PlanetIcon,
  Sizes,
  StoreIcon,
  TruckIcon,
} from '@nimbus-ds/icons';
import { ActiveShippingMethodIconType } from '../utils';

interface ActiveShippingMethodsTagProps {
  readonly icon: ActiveShippingMethodIconType;
  readonly size?: Sizes | number;
}

function IconsShippingMethodTag({ icon, size }: ActiveShippingMethodsTagProps) {
  switch (icon) {
    case 'flag':
      return <FlagIcon size={size} />;
    case 'store':
      return <StoreIcon size={size} />;
    case 'map-marked':
      return <LocationIcon size={size} />;
    case 'plane':
      return <PlanetIcon size={size} />;
    case 'map-marker-alt':
      return <GlassesIcon size={size} />;
    case 'home':
      return <HomeIcon size={size} />;
    case 'motorcycle':
      return <ClockIcon size={size} />;
    case 'clock':
      return <ClockIcon size={size} />;
    case 'unlock':
      return <InvoiceIcon size={size} />;
    case 'utensils':
      return <DrinkIcon size={size} />;
    case 'truck':
      return <TruckIcon size={size} />;
    case 'boxes-alt':
      return <BoxPackedIcon size={size} />;
    case 'undo':
      return <HistoryIcon size={size} />;
    case 'warehouse':
      return <StoreIcon size={size} />;
    case 'tape':
      return <HomeIcon size={size} />;
    case 'rocket':
      return <MoonIcon size={size} />;
    case 'box-alt':
      return <BoxPackedIcon size={size} />;
    default:
      return <FlagIcon size={size} />;
  }
}

export default IconsShippingMethodTag;
