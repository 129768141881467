import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getMetafieldsCategoriesData,
  getMetafieldsCategoriesStatus,
} from 'domains/Metafields/metafieldsSlice';
import { fetchMetafieldsCategoriesAction } from 'domains/Metafields/metafieldsSlice/metafieldsSlice';
import useCategoriesMetafieldsAccess from '../useCategoriesMetafieldsAccess';

function useGetCategoriesMetafields() {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsCategoriesData);
  const { isLoading, isSuccess, isError, isIdle } = useSelector(
    getMetafieldsCategoriesStatus,
  );
  const { isAvailable } = useCategoriesMetafieldsAccess();

  const fetchMetafields = useCallback(
    () => dispatch(fetchMetafieldsCategoriesAction()),
    [dispatch],
  );

  const hasInternals =
    !!metafields?.internals && Object.keys(metafields?.internals).length > 0;
  const hasExternals =
    !!metafields?.fromApi && Object.keys(metafields?.fromApi).length > 0;

  const status = { isLoading, isError, isSuccess, isIdle };

  return {
    metafields: hasInternals ? metafields.internals : null,
    apiMetafields: hasExternals ? metafields.fromApi : null,
    status,
    isIdle,
    fetchMetafields,
    hasExternals,
    hasInternals,
    isAvailable,
  };
}

export default useGetCategoriesMetafields;
