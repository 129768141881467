import { useEffect } from 'react';
import useScript from 'commons/hooks/useScript';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { config } from './config';

declare global {
  interface Window {
    AppcuesSettings: Record<string, boolean>;
    Appcues: {
      identify: (email: string, config: Record<string, string>) => void;
    };
  }
}

function Appcues(): null {
  const { id: externalUserId, email, firstName, lastName } = useGetUserInfo();
  const {
    name: storeName,
    id: storeId,
    country: countryCode,
    url: storeUrl,
    createdAt,
  } = useStoreInfo();

  const statusScript = useScript(`https://fast.appcues.com/${config.id}.js`);

  useEffect(() => {
    window.AppcuesSettings = {
      enableURLDetection: true,
    };

    if (statusScript === 'ready') {
      if (!window.Appcues || !window.Appcues.identify) {
        console.error('No Appcues and identify found in window');
        return;
      }

      window.Appcues.identify(email, {
        externalUserId,
        storeId,
        firstName,
        lastName,
        email,
        storeName,
        countryCode,
        storeUrl,
        createdAt,
      });
    }
  }, [
    countryCode,
    email,
    firstName,
    externalUserId,
    lastName,
    statusScript,
    storeId,
    storeName,
    storeUrl,
    createdAt,
  ]);

  return null;
}

export default Appcues;
