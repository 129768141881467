interface BulkActionOption {
  value: string;
  showCounts: boolean;
  showWhenAllSelected: boolean;
  showOnlyWhenFiltering?: boolean;
}
export enum BulkActionTypeEnum {
  DELETE = 'delete',
  PUBLISH = 'publish',
  DRAFT = 'draft',
  UPDATE_PRICE = 'updatePrice',
  ASSIGN_CATEGORIES = 'assignCategories',
  ASSIGN_METAFIELDS = 'assignMetafields',
  ASSIGN_PRODUCT_METAFIELDS = 'assignProductMetafields',
}

export const bulkactions: BulkActionOption[] = [
  {
    value: BulkActionTypeEnum.DELETE,
    showCounts: true,
    showWhenAllSelected: false,
  },
  {
    value: BulkActionTypeEnum.PUBLISH,
    showCounts: true,
    showWhenAllSelected: false,
  },
  {
    value: BulkActionTypeEnum.DRAFT,
    showCounts: true,
    showWhenAllSelected: false,
  },
  {
    value: BulkActionTypeEnum.UPDATE_PRICE,
    showCounts: true,
    showWhenAllSelected: true,
  },
  {
    value: BulkActionTypeEnum.ASSIGN_CATEGORIES,
    showCounts: true,
    showWhenAllSelected: false,
  },
  {
    value: BulkActionTypeEnum.ASSIGN_PRODUCT_METAFIELDS,
    showCounts: true,
    showWhenAllSelected: false,
    showOnlyWhenFiltering: true,
  },
  {
    value: BulkActionTypeEnum.ASSIGN_METAFIELDS,
    showCounts: true,
    showWhenAllSelected: false,
    showOnlyWhenFiltering: true,
  },
];
