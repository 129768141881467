import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VerifyAuthenticationFactorCodeRequestDto } from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { StatusType } from 'commons/types';
import { AuthenticationFactorService } from '../services';

export interface AuthenticationFactorSchema {
  verifyAndAuthorize: {
    data: { valid: boolean; authorizationCode: string } | null;
    status: StatusType;
  };
}

const initialState: AuthenticationFactorSchema = {
  verifyAndAuthorize: {
    data: null,
    status: 'idle',
  },
};

export const verifyAndAuthorizeAction = createAsyncThunk(
  'authentication-factor/verifyAndAuthorizeAction',
  async ({
    code,
    payload,
  }: Omit<VerifyAuthenticationFactorCodeRequestDto, 'type'>) =>
    await AuthenticationFactorService.verifyAndAuthorize({
      type: AuthenticationFactorTypes.TOTP,
      code,
      payload,
    }),
);

const authenticationFactorSlice = createSlice({
  name: 'authentication-factor',
  initialState,
  reducers: {
    cleanVerifyAndAuthorizeData(state) {
      state.verifyAndAuthorize = initialState.verifyAndAuthorize;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAndAuthorizeAction.pending, (state) => {
        state.verifyAndAuthorize.status = 'loading';
      })
      .addCase(verifyAndAuthorizeAction.fulfilled, (state, action) => {
        state.verifyAndAuthorize.data = action.payload;
        state.verifyAndAuthorize.status = 'success';
      })
      .addCase(verifyAndAuthorizeAction.rejected, (state) => {
        state.verifyAndAuthorize.status = 'error';
      });
  },
});

export const { reducer } = authenticationFactorSlice;

export const { cleanVerifyAndAuthorizeData } =
  authenticationFactorSlice.actions;
