import { Button, Modal, Text } from '@nimbus-ds/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface DeleteFeaturedImageModalProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
function DeleteFeaturedImageModal({
  show,
  onConfirm,
  onCancel,
}: DeleteFeaturedImageModalProps) {
  const t = useTranslationCatalog();

  return (
    <Modal onDismiss={onCancel} open={show}>
      <Modal.Header
        title={t('categories.detail.featuredImage.modalDeleteTitle')}
      />
      <Modal.Body>
        <Text fontSize="base" lineHeight="base" textAlign="left">
          {t('categories.detail.featuredImage.modalDeleteDescription')}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="neutral" onClick={onCancel}>
          <Text color="neutral-textHigh">
            {t('categories.detail.featuredImage.modalDeleteCancel')}
          </Text>
        </Button>
        <Button appearance="danger" onClick={onConfirm}>
          <Text color="danger-textLow">
            {t('categories.detail.featuredImage.modalDeleteConfirm')}
          </Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteFeaturedImageModal;
