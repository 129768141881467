import { Box, Select } from '@nimbus-ds/components';
import { DataList, Label, Text, Stack } from '@tiendanube/components';
import { AuthorSkeleton } from '..';
import ProductVariant from '../../../ProductVariant';

const { Cell, Row } = DataList;

const rows = new Array(4).fill(0).map((_, i) => i.toString());

interface StockHistorySkeletonProps {
  hasInventory: boolean;
  hideLocationSelector?: boolean;
}

function StockHistorySkeleton({
  hasInventory,
  hideLocationSelector = false,
}: StockHistorySkeletonProps): JSX.Element {
  return (
    <Box display="flex" flexDirection="column" gap="2-5">
      <ProductVariant.Skeleton />
      <Stack column align="stretch">
        <Stack.Item fill>
          <Text.Skeleton width="large" />
        </Stack.Item>
        {hasInventory && !hideLocationSelector && (
          <Stack.Item fill>
            <Select.Skeleton />
          </Stack.Item>
        )}
      </Stack>
      <DataList>
        {rows.map((i) => (
          <Row key={i} id={i}>
            <Cell>
              <Stack spacing="tight">
                <Stack.Item>
                  <Label.Skeleton />
                </Stack.Item>
              </Stack>
            </Cell>
            <Cell alignRight>
              <Text.Skeleton />
            </Cell>
            <Cell width={100}>
              <Stack column align="stretch" spacing="base">
                <Stack.Item />
                <Stack.Item />
              </Stack>
            </Cell>
            <Cell>
              <Stack column align="stretch" spacing="tight">
                <Stack.Item fill>
                  <AuthorSkeleton />
                </Stack.Item>
              </Stack>
            </Cell>
            <Cell alignRight>
              <Text.Skeleton size="caption" />
            </Cell>
            <Cell extraPadding width="fill">
              <Stack wrap>
                <Stack.Item>
                  <Label.Skeleton />
                </Stack.Item>
                <Stack.Item>
                  <Label.Skeleton />
                </Stack.Item>
              </Stack>
            </Cell>
          </Row>
        ))}
      </DataList>
    </Box>
  );
}

export default StockHistorySkeleton;
