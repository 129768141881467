import { AiFeature, AiTone, AiWordCount } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { FirstTimeGenerateButtons } from './components/FirstTimeGenerateButtons';
import { ToneControls } from './components/ToneControls';
import { WordCountControls } from './components/WordCountControls';

interface AiGenerationInstructionsProps {
  feature: AiFeature;
  tone?: AiTone;
  setTone: (tone: AiTone) => void;
  wordCount?: AiWordCount;
  wordNumber?: number;
  setWordCount: (wordCount: AiWordCount) => void;
  showTone?: boolean;
  showWordCount?: boolean;
  extraControls?: React.ReactNode;
  showCtas?: boolean;
  disabledGenerateCta: boolean;
  onGenerateContent: () => void;
  onCancel: () => void;
}

export function AiGenerationInstructions({
  feature,
  tone,
  setTone,
  wordCount,
  wordNumber,
  setWordCount,
  showTone,
  showWordCount,
  extraControls,
  showCtas,
  disabledGenerateCta,
  onGenerateContent,
  onCancel,
}: Readonly<AiGenerationInstructionsProps>) {
  return (
    <Stack column align="stretch">
      <Stack.Item>{extraControls}</Stack.Item>
      {showTone && (
        <ToneControls feature={feature} tone={tone} setTone={setTone} />
      )}
      {showWordCount && (
        <WordCountControls
          feature={feature}
          wordCount={wordCount}
          wordNumber={wordNumber}
          setWordCount={setWordCount}
        />
      )}
      {showCtas && (
        <FirstTimeGenerateButtons
          disabledGenerateCta={disabledGenerateCta}
          onGenerateContent={onGenerateContent}
          onCancel={onCancel}
        />
      )}
    </Stack>
  );
}
