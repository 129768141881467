import { NotificationStyleType, AppearanceTypes } from './types';

export const notificationStyle: Record<NotificationStyleType, AppearanceTypes> =
  {
    info: 'primary',
    success: 'success',
    warning: 'warning',
    error: 'danger',
    neutral: 'neutral',
  };

export const notificationActionStyle: Record<
  NotificationStyleType,
  'primary' | 'danger' | 'neutral'
> = {
  success: 'primary',
  info: 'primary',
  warning: 'neutral',
  error: 'danger',
  neutral: 'neutral',
};
