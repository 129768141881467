import {
  AppLinkAuthorizationResponseDto,
  AppScopesAndAuthorizationResponseDto,
  AppsAdminLinksResponseDto,
  NuvemAppsReponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { AppToken, AppInstalled } from './types';
import { APPS_AUTHORIZATION_URL, APPS_SCOPES_URL } from './urls';

// TODO: replace alls hardcoded urls by statics urls

const getToken = async (appId: string): Promise<AppToken> => {
  const { data } = await axios.get<AppToken>(`/v1/apps/${appId}/token`);
  return data;
};

const getApp = async (appId: string): Promise<AppInstalled> => {
  const { data } = await axios.get<AppInstalled>(`/v1/apps/${appId}`);
  return data;
};

const getNuvemApps = async (): Promise<NuvemAppsReponseDto> => {
  const { data } = await axios.get<NuvemAppsReponseDto>('/v1/apps/nuvemapps');
  return data;
};

const getAppsLinks = async (): Promise<AppsAdminLinksResponseDto[]> => {
  const { data } = await axios.get<AppsAdminLinksResponseDto[]>(
    '/v1/apps/admin-links',
  );
  return data;
};

const getAppsScopesAuthorization = async ({
  id,
  redirectUri,
}: {
  id: string;
  redirectUri: string | null;
}) => {
  try {
    const { data } = await axios.get<AppScopesAndAuthorizationResponseDto>(
      getUrlWithParams(APPS_SCOPES_URL, { appId: id }),
      {
        params: redirectUri ? { redirectUri } : {},
      },
    );
    return data;
  } catch (error) {
    if (error?.isAxiosError && error.response?.status === 403) {
      throw new Error('403');
    }
    throw new Error(error?.response?.status || '500');
  }
};

const getLinkAuthorization = async (appId: string) => {
  const { data } = await axios.get<AppLinkAuthorizationResponseDto>(
    getUrlWithParams(APPS_AUTHORIZATION_URL, { appId }),
  );

  return data;
};

export default {
  getToken,
  getNuvemApps,
  getAppsLinks,
  getApp,
  getAppsScopesAuthorization,
  getLinkAuthorization,
};
