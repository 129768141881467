import {
  InventoryLevelByLocationResponseDto,
  LocationFullResponseDto,
  LocationPriorityRequestDto,
  LocationPriorityResponseDto,
  LocationResponseDto,
  PageParamsRequestDto,
  ResultPaginationResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  EditLocationParamsInterface,
  LocationValuesInterface,
} from '../pages/types';

export const getLocationsList = async (): Promise<LocationResponseDto[]> => {
  const { data } = await axios.get('v1/locations/');
  return data;
};

export const getLocationById = async (
  id: string,
): Promise<LocationFullResponseDto> => {
  const { data } = await axios.get(`v1/locations/${id}`);
  return data;
};

export const createLocation = async (
  payload: LocationValuesInterface,
): Promise<void> => {
  await axios.post('v1/locations/', payload);
};

export const updateLocation = async ({
  id,
  location: payload,
}: EditLocationParamsInterface): Promise<void> => {
  await axios.put(`v1/locations/${id}`, payload);
};

export const updateLocationsPriorities = async (
  locations: LocationPriorityRequestDto[],
): Promise<LocationPriorityResponseDto[]> => {
  const { data } = await axios.patch('v1/locations/priorities', locations);
  return data;
};

export const updateMainLocation = async (id: string): Promise<void> => {
  await axios.patch(`v1/locations/${id}/chosen-as-default`);
};

export const deleteLocation = async (id: string): Promise<void> => {
  await axios.delete(`v1/locations/${id}`);
};

export const getInventoriesByLocation = async (
  locationId: string,
  params?: PageParamsRequestDto,
): Promise<
  ResultPaginationResponseDto<InventoryLevelByLocationResponseDto[]>
> => {
  const { data } = await axios.get(
    `v1/locations/${locationId}/inventory-levels`,
    {
      params,
    },
  );
  return data;
};

export const getMultiCDAvailability = async (): Promise<boolean> => {
  const { data } = await axios.get('v1/shipping/multicd/availability');
  return data;
};

export const activateMultiCD = async (): Promise<void> => {
  await axios.post('v1/shipping/multicd/availability');
};
