import { Card } from '@nimbus-ds/components';
import { LanguagesType } from 'App/i18n';
import { TabsMultiLanguage } from 'commons/components';
import InputsTitleAndContent from './InputsTitleAndContent';
import { InputsTitleAndContentProps } from './InputsTitleAndContent/InputsTitleAndContent';

function FormPage(props: Readonly<InputsTitleAndContentProps>) {
  const missingLanguages = Object.keys(props.errors)
    .filter((err) => err.startsWith('title') || err.startsWith('content'))
    .map((err) => err.split('.')[1]);

  return (
    <Card>
      <Card.Body>
        <TabsMultiLanguage
          initialLanguage={missingLanguages[0] as LanguagesType}
        >
          <InputsTitleAndContent {...props} />
        </TabsMultiLanguage>
      </Card.Body>
    </Card>
  );
}

export default FormPage;
