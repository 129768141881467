import * as Yup from 'yup';
import { Country } from '@tiendanube/common/src/enums';
import { validateCNPJ, validateCPF } from 'domains/Billing/utils';

const discountsRule = {
  [Country.BR]: Yup.string()
    .typeError('errors.empty')
    .required('errors.empty')
    .test('is-valid-id', 'errors.empty', function (value) {
      if (typeof value !== 'string') return false;

      const length = value.length;

      if (length === 0) {
        return (
          validateCNPJ(value) ||
          this.createError({
            message: 'errors.empty',
          })
        );
      }

      if (length !== 11 && length !== 14) {
        return this.createError({
          message: 'errors.invalid',
        });
      }
      if (length === 14) {
        return (
          validateCNPJ(value) ||
          this.createError({
            message: 'errors.invalid',
          })
        );
      }
      if (length === 11) {
        return (
          validateCPF(value) ||
          this.createError({
            message: 'errors.invalid',
          })
        );
      }
      return false;
    }),
  [Country.AR]: Yup.string()
    .required('errors.empty')
    .matches(/^[0-9]+$/, 'errors.empty')
    .test('is-valid-id', 'errors.empty', function (value) {
      if (typeof value !== 'string') return false;

      if (value.length === 0) {
        return this.createError({ message: 'errors.empty' });
      }
      if (value.length !== 11) {
        return this.createError({ message: 'errors.invalid' });
      }
      return true;
    }),
};

export const getValidationSchema = (country: Country) =>
  Yup.object().shape({
    document: discountsRule[country],
  });
