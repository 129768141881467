import { Alert, Card, Text, Link } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { ContactInfoRequestDto } from '@tiendanube/common';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  IonPageStratus,
  Stack,
  TabsMultiLanguage,
} from 'commons/components';
import IdentificationBrInput from 'commons/components/IdentificationBrInput';
import { HELP_LINKS_CONTACT_INFO } from 'commons/constants';
import { useForm, useToastStatus, useTranslationLanguage } from 'commons/hooks';
import { useGetCountry, useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import {
  ContactInfoPageError,
  ContactInfoPageSkeleton,
  ContactTextInput,
} from './components';
import useContactInfo from './useContactInfo';
import { validationSchema } from './validationSchema';

const initialValues = {
  mail: '',
  address: '',
  phone: '',
  contactText: {},
};

function ContactInfoPage() {
  const t = useTranslationOnline('contactInfoPage');
  const language = useTranslationLanguage();
  const country = useGetCountry();
  const { goBack } = useNavegate();
  const {
    status,
    updateStatus,
    contactInfo,
    updateContactInfo,
    fetchContactInfo,
  } = useContactInfo();
  const isMobile = useIsMobileDevice();
  const {
    values,
    errors,
    isDirty,
    handleOnChange,
    handleOnChangeGroup,
    handleOnSubmit,
  } = useForm<ContactInfoRequestDto>({
    initialValues: contactInfo ?? initialValues,
    validationSchema: validationSchema(country),
    onSubmit: (data) => updateContactInfo(data),
  });

  useToastStatus({
    status: updateStatus,
    error: t('toast.error'),
    success: t('toast.success'),
  });

  const handleChange = (event) =>
    handleOnChange({
      name: event.target.name || event.target.id,
      value: event.target.value,
    });

  const handleChangeBusinessName = ({ name, value }) =>
    handleOnChange({ name, value });

  const handleChangeEmail = (event) => {
    handleOnChange({ name: 'mail', value: event.target.value.trim() });
  };

  const isUpdateLoading = updateStatus === 'loading';
  const showArgentinaWarning = country === 'AR';
  const isEmailInvalid = !!errors.mail;

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={`${t('title')}`} />}
    >
      {status === 'loading' && <ContactInfoPageSkeleton />}
      {status === 'error' && (
        <ContactInfoPageError fetchContactInfo={fetchContactInfo} />
      )}
      {status === 'success' && (
        <>
          <Alert appearance="warning" show={showArgentinaWarning}>
            <Text>
              <Trans
                t={t}
                i18nKey="argentinaWarning"
                components={{
                  ddcLink: (
                    <Link
                      as="a"
                      textDecoration="underline"
                      href={t('argentinaWarningLink')}
                      target="_blank"
                    >
                      {' '}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Alert>
          <Card>
            <Card.Body>
              <Stack column align="stretch">
                {country === 'BR' && (
                  <>
                    <FormField.Input
                      id="businessName"
                      label={t('businessName')}
                      onChange={handleChange}
                      defaultValue={values.businessName}
                    />
                    <IdentificationBrInput
                      label={t('businessId')}
                      name="businessId"
                      value={values.businessId}
                      error={errors.businessId}
                      onChange={handleChangeBusinessName}
                    />
                  </>
                )}
                <FormField.Input
                  id="mail"
                  label={t('email')}
                  helpText={
                    isEmailInvalid ? t(`${errors.mail}`) : t('emailInfo')
                  }
                  appearance={isEmailInvalid ? 'danger' : undefined}
                  onChange={handleChangeEmail}
                  defaultValue={values.mail}
                  autoFocus={!isMobile}
                  showHelpText
                />
                <FormField.Input
                  id="address"
                  label={t('address')}
                  onChange={handleChange}
                  defaultValue={values.address}
                />
                <FormField.Input
                  id="phone"
                  label={t('phone')}
                  onChange={handleChange}
                  defaultValue={values.phone}
                />
                <TabsMultiLanguage>
                  <ContactTextInput
                    contactText={values.contactText}
                    handleChangeGroup={handleOnChangeGroup}
                  />
                </TabsMultiLanguage>
              </Stack>
            </Card.Body>
          </Card>
          <CancelAndSaveButtons
            isDisabled={!isDirty}
            isLoading={isUpdateLoading}
            onSave={handleOnSubmit}
            onCancel={goBack}
          />
          <HelpLink
            title={t('helpLink.title')}
            previousValue=""
            currentViewTracking={t('helpLink.amplitudeName')}
            linkURL={HELP_LINKS_CONTACT_INFO[language]}
            icon="both"
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default ContactInfoPage;
