import { Box } from '@nimbus-ds/components';
import { SubscriptionResponseDto } from '@tiendanube/common';
import { useStoreStatus } from 'domains/Auth/hooks';
import {
  useGetLastPayment,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';
import ChurnedPlanRowData from '../ChurnedPlanRowData';
import RowData from '../RowData';

interface SubscriptionBodyProps {
  onCancelSubscription: (subscription: SubscriptionResponseDto) => void;
}

function SubscriptionBody({
  onCancelSubscription,
}: Readonly<SubscriptionBodyProps>) {
  const { subscriptions } = useGetSubscriptions();
  const { paymentStatus } = useGetLastPayment('plan-cost');
  const { storeStatus } = useStoreStatus();
  const marginBottom = paymentStatus !== 'PAID' ? '6' : undefined;

  const isChurned = subscriptions?.length === 0 && storeStatus === 'store_down';

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="6"
      marginBottom={marginBottom}
    >
      {isChurned && <ChurnedPlanRowData />}
      {subscriptions?.map((subscription) => (
        <RowData
          subscription={subscription}
          key={subscription.id}
          onCancelSubscription={() => onCancelSubscription(subscription)}
        />
      ))}
    </Box>
  );
}

export default SubscriptionBody;
