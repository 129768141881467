import {
  InteractiveList,
  InterfaceInteractiveListOptions,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface AdditionalServicesCardProps {
  options: InterfaceInteractiveListOptions[];
  onChange: (values: string[]) => void;
}

function AdditionalServicesCard({
  options,
  onChange,
}: AdditionalServicesCardProps): JSX.Element {
  const t = useTranslationShipping();
  const translatedOptions = options.map((option) => ({
    ...option,
    title: t(option.title),
    description: option.description ? t(option.description) : '',
  }));

  return (
    <CardWithTitle title={t('correios.contract.additionalServices')}>
      <InteractiveList
        mode="multi"
        options={translatedOptions}
        onChange={onChange}
      />
    </CardWithTitle>
  );
}

AdditionalServicesCard.Skeleton = Skeleton;

export default AdditionalServicesCard;
