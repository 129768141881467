import { Text } from '@nimbus-ds/components';
import { useSelector } from 'react-redux';
import {
  PaymentProvidersCode,
  muvemPagoOrPagoNubeIds,
  customPaymentsIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { FEATURE_ONLY_NUVEM_PAGO } from 'App/features';
import { useGetCountry, useGetPlan } from 'domains/Auth/hooks';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  TRANSLATE_PAYMENT_PREFIX,
  getCustomPaymentsCpt,
} from '../../components';
import { getInstalledPaymentProvidersData } from '../../paymentProvidersSlice';

interface GetCptFeeProps {
  providerId: string;
  paymentMethods: Array<any>;
}

export const useGetCptFee = () => {
  const { plansList, isLoading, isSuccess, currentPlanLevel } =
    useBillingPlans();
  const paymentProviders = useSelector(getInstalledPaymentProvidersData);
  const { transactionFee: cpt, isNext, isEvolucion, isFreePlan } = useGetPlan();
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);
  const storeCountry = useGetCountry();

  const isEnterprisePlan = isNext || isEvolucion;

  const getCptFee = ({ paymentMethods, providerId }: GetCptFeeProps) => {
    const isNuvemPagoEnterprise =
      PaymentProvidersCode.nuvemPago === providerId && isEnterprisePlan;
    const isFreePlanLoading = isFreePlan && isLoading;
    const isFreePlanSuccess = isFreePlan && isSuccess;

    if (isNuvemPagoEnterprise) {
      return <Text color="success-textLow">{t('cptToNegotiate')}</Text>;
    }

    if (muvemPagoOrPagoNubeIds.includes(providerId)) {
      return <Text color="success-textLow">{t('freeCpt')}</Text>;
    }

    if (customPaymentsIds.includes(providerId)) {
      const isPagoNubeActive = !!paymentProviders?.find(
        ({ id, isActive }) => muvemPagoOrPagoNubeIds.includes(id) && isActive,
      );
      return (
        <Text>{`${getCustomPaymentsCpt({
          cpt,
          ifOther: paymentMethods.includes('other'),
          isFreePlan,
          plansList,
          storeCountry,
          currentPlanLevel,
          isPagoNubeActive,
          isEnterprisePlan,
        })}%`}</Text>
      );
    }

    if (storeCountry === 'AR' || storeCountry === 'BR') {
      if (isFreePlanLoading) {
        return <Text.Skeleton />;
      }

      if (isFreePlanSuccess) {
        const plan = plansList.find(
          ({ featureKeys }) => !featureKeys[FEATURE_ONLY_NUVEM_PAGO],
        );
        if (plan) {
          return <Text>{`${plan.fee.value}%`}</Text>;
        }
      }
    }

    return <Text>{`${cpt}%`}</Text>;
  };

  return {
    getCptFee,
  };
};
