import { Box, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { LeftAndRightButtons } from 'commons/components';
import { usePaginationStratus } from 'commons/hooks';

interface CompactPaginationInterface {
  totalResults: number;
  perPage: number;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
}

interface CompactPaginationStratusProps {
  pagination: CompactPaginationInterface;
  onPageSelect: (page: number) => void;
  itemName: string;
}

function CompactPaginationStratus({
  pagination: { totalResults, perPage, currentPage, totalPages },
  onPageSelect,
  itemName,
}: CompactPaginationStratusProps) {
  const { t } = useTranslation('common', { keyPrefix: 'compactPagination' });
  const { firstItem, lastItem } = usePaginationStratus({
    currentPage,
    perPage,
    totalItems: totalResults,
  });
  const hasNextPage = currentPage < totalPages;
  const hasPrevPage = currentPage < firstItem;
  const handleOnNext = () => {
    onPageSelect(currentPage + 1);
  };
  const handleOnPrevious = () => {
    onPageSelect(currentPage - 1);
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text color="neutral-textDisabled">
        {t('label', {
          currentPage: firstItem,
          perPage: lastItem,
          totalItems: totalResults,
          itemName,
        })}
      </Text>
      <LeftAndRightButtons
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
        disabledNext={!hasNextPage}
        disabledPrevious={!hasPrevPage}
        grayed
      />
    </Box>
  );
}
export default CompactPaginationStratus;
