import { Badge } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { MoneyIcon } from '@tiendanube/icons';
import ShowByRole from 'App/components/ShowByRole';
import { FEATURE_ABANDONED_CARTS } from 'App/features';
import {
  MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  MIGRATION_ORDERS,
} from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import useShowByFeature from 'App/hooks/useShowByFeature';
import objectToUrlParams from 'commons/utils/objectToUrlParams';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetTags } from 'domains/Auth/hooks';
import { useOrdersList } from 'domains/Orders/Orders/hooks';

const PATH = {
  orders: '/orders',
  orderOldAdmin: '/admin/orders',
  draftOrders: '/draft-orders',
  abandonedCarts: '/abandoned-carts',
};

interface OrdersMenuProps {
  isMobile: boolean;
}

function OrdersMenu({ isMobile }: Readonly<OrdersMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { totalOpenOrders } = useOrdersList();
  const { validateRoles } = useRoles();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const { appliedFilters } = useOrdersList();
  const tags = useGetTags();

  const hasAbandonedCartsFeature = useShowByFeature(FEATURE_ABANDONED_CARTS);

  const hasOrdersNewAdmin =
    tags.includes(MIGRATION_ORDERS) ||
    tags.includes(MIGRATION_ENABLE_ALL_MIGRATED_PAGES);

  const activeOrders =
    pathname.startsWith(PATH.orders) ||
    pathname.startsWith(PATH.abandonedCarts) ||
    pathname.startsWith(PATH.draftOrders);

  const showOrders = validateRoles('orders');
  const showDraftOrders = validateRoles('draft_orders');
  const showAbandonedCarts = validateRoles('abandoned_carts');

  const menuOrders = {
    children: `${t('menu.manage.orders.ordersList')}`,
    onClick: handleClickItem,
    active: pathname.startsWith(PATH.orders),
    href: `${PATH.orders}?${objectToUrlParams(appliedFilters)}`,
    badge: totalOpenOrders,
  };

  const menuDraftOrder = {
    children: `${t('menu.manage.orders.draftOrders')}`,
    onClick: handleClickItem,
    active: pathname.startsWith(PATH.draftOrders),
    href: PATH.draftOrders,
  };
  const menuAbandonedCarts = {
    children: `${t('menu.manage.orders.abandonedCarts')}`,
    onClick: handleClickItem,
    active: pathname.startsWith(PATH.abandonedCarts),
    href: PATH.abandonedCarts,
  };

  const itemsOrders: {
    children: string;
    onClick: () => void;
    active: boolean;
    href: string;
    badge?: number;
  }[] = [
    ...(showOrders ? [menuOrders] : []),
    ...(showDraftOrders ? [menuDraftOrder] : []),
    ...(showAbandonedCarts && hasAbandonedCartsFeature
      ? [menuAbandonedCarts]
      : []),
  ];

  const basePath = () => {
    if (validateRoles('orders')) {
      return PATH.orders;
    }
    return validateRoles('draft_orders')
      ? PATH.draftOrders
      : PATH.abandonedCarts;
  };

  const badgeOrders =
    totalOpenOrders > 0 && !activeOrders ? (
      <Badge appearance="primary" count={totalOpenOrders} />
    ) : undefined;

  const ordesOldAdmin =
    !isMobile && !hasOrdersNewAdmin && basePath() === PATH.orders
      ? PATH.orderOldAdmin
      : '';

  return (
    <ShowByRole includeRoles={['draft_orders', 'orders', 'abandoned_carts']}>
      <MenuButton.Accordion
        contentid="content-orders"
        menuButton={{
          id: 'control-orders',
          label: t('menu.manage.orders.title'),
          startIcon: MoneyIcon,
          children: badgeOrders,
          'aria-controls': 'content-orders',
          onClick: !ordesOldAdmin ? handleClickAccordion : undefined,
        }}
        open={activeOrders}
        active={activeOrders}
        as={ordesOldAdmin ? 'a' : Link}
        to={basePath()}
        href={ordesOldAdmin || undefined}
      >
        {itemsOrders.map((item, index) => (
          <MenuButton
            key={index}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          >
            {item.badge && activeOrders ? (
              <Badge count={totalOpenOrders} appearance="primary" />
            ) : undefined}
          </MenuButton>
        ))}
      </MenuButton.Accordion>
    </ShowByRole>
  );
}

export default OrdersMenu;
