import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ErrorState } from '@tiendanube/components';
import { HeaderTop, IonPageStratus } from 'commons/components/IonPageStratus';

function ProductDetailsError(): JSX.Element {
  const { t } = useTranslation(['catalog']);
  const history = useHistory();

  const goToBack = () => {
    history.goBack();
  };

  const backNavitation = {
    onClick: goToBack,
  };

  return (
    <IonPageStratus headerTop={<HeaderTop navigation={backNavitation} />}>
      <ErrorState title={t('products.detail.errorPage.message')} />
    </IonPageStratus>
  );
}

export default ProductDetailsError;
