import { DataList } from '@tiendanube/components';
import ScrollTop from 'commons/components/ScrollTop';
import { TransactionListItemSkeleton } from '../TransactionListItem';

function TransactionsResultsMobileSkeleton(): JSX.Element {
  return (
    <DataList skeleton>
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <TransactionListItemSkeleton />
      <ScrollTop />
    </DataList>
  );
}

export default TransactionsResultsMobileSkeleton;
