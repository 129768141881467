import { useCallback } from 'react';
import { CheckoutPaymentMethod, RecurrencyOptionDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  usePayorderToPay,
  useUpdateCharge,
  usePaymentSetup,
} from 'domains/Billing/Checkout/hooks';
import { checkoutApi } from '../../../CheckoutApi';
import { checkoutTags } from '../../../CheckoutApi/config';

function useContinuePayment() {
  const dispatch = useAppDispatch();
  const { payPayOrder: payPayOrderPerMethod, extraStatus } = usePaymentSetup();
  const { updateCharge, status: updateSubscriptionsStatus } = useUpdateCharge();

  const { payorderToPay } = usePayorderToPay();

  const statusObject = combineStatus(updateSubscriptionsStatus, extraStatus);

  const onPay = useCallback(
    async (
      paymentMethod: CheckoutPaymentMethod,
      recurrencyOption: RecurrencyOptionDto,
    ) => {
      if (!payorderToPay || statusObject.isLoading) return;
      if (
        payorderToPay.charges.some(
          ({ conceptCode }) => conceptCode === 'plan-cost',
        )
      ) {
        await updateCharge({
          method: paymentMethod,
          recurringFrequency: recurrencyOption.recurringFrequency,
          recurringInterval: recurrencyOption.recurringInterval,
        })
          .unwrap()
          .finally(() => {
            dispatch(
              checkoutApi.util.invalidateTags([
                checkoutTags.pendingCharges,
                checkoutTags.payOrderToPay,
              ]),
            );
          });
      }
      const payPayOrder = payPayOrderPerMethod[paymentMethod];
      payPayOrder(payorderToPay.id);
    },
    [payPayOrderPerMethod, payorderToPay, updateCharge, statusObject, dispatch],
  );

  return {
    ...statusObject,
    onPay,
  };
}

export default useContinuePayment;
