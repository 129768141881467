import { Box, Text } from '@nimbus-ds/components';
import './Avatar.scss';

interface AvatarProps {
  name: string;
}

function Avatar({ name }: Readonly<AvatarProps>): JSX.Element {
  const initial = name.slice(0, 1).toUpperCase();

  return (
    <div className="stratus--avatar">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="1"
        borderRadius="full"
        backgroundColor="primary-surface"
        width="1.25rem"
        height="1.25rem"
      >
        <Text fontWeight="bold" fontSize="caption" color="primary-interactive">
          {initial}
        </Text>
      </Box>
    </div>
  );
}

export default Avatar;
