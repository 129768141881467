import { SizeAttributeType } from '@tiendanube/common';
import { LanguageInterface, Locale } from 'App/i18n';
import { AttributeType } from 'domains/Catalog/Products/pages/components/Variants/types';

const STANDARD_SIZE_NAME_LOCALE_ES: Record<
  Exclude<Locale, Locale.BRAZIL>,
  string
> = {
  'es-AR': 'Talle',
  'es-CL': 'Talla',
  'es-CO': 'Talla',
  'es-MX': 'Talla',
};

export const getLocaleStandardName = (
  locale = 'es-AR',
): Record<AttributeType, LanguageInterface> => {
  const sizeLocale: Locale =
    locale.split('-')[0] === 'es' ? (locale as Locale) : Locale.ARGENTINA;
  return {
    color: {
      es: 'Color',
      en: 'Color',
      pt: 'Cor',
    },
    size: {
      es: STANDARD_SIZE_NAME_LOCALE_ES[sizeLocale],
      en: 'Size',
      pt: 'Tamanho',
    },
    new_attribute: {
      es: 'Nueva...',
      en: 'New...',
      pt: 'Nova...',
    },
  };
};

export const TRANSLATED_STANDARD_SIZE: Record<
  SizeAttributeType,
  LanguageInterface
> = {
  adult: {
    es: 'Adultos',
    en: 'Adults',
    pt: 'Adultos',
  },
  kid: {
    es: 'Niños',
    en: 'Kids',
    pt: 'Crianças',
  },
  footwear: {
    es: 'Calzados',
    en: 'Footwear',
    pt: 'Calçados',
  },
};
