import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStorage } from 'commons/hooks';
import { useGetStoreInfo } from 'domains/Auth/hooks';
import {
  getOnboardingAction,
  getOnboardingData,
  getOnboardingStatus,
  updateOnboardingTaskSetupPaymentsAction,
} from 'domains/Dashboard/slices/onBoardingSlice';

const ONBOARRDING_TASKS_COMPLETED_ITEM = 'onboarding-tasks';

function useOnboardingTasks() {
  const dispatch = useAppDispatch();
  const onboardingTasks = useSelector(getOnboardingData);
  const { id } = useGetStoreInfo();
  const status = useSelector(getOnboardingStatus);
  // we set the store.id to identify that the store has completed onboarding
  const localStorageItem = `${ONBOARRDING_TASKS_COMPLETED_ITEM}-${id}`;
  const [onboardingTasksCompleted, setOnboardingTasksCompleted, isLoaded] =
    useStorage(localStorageItem, false);
  const getOnboarding = useCallback(() => {
    if (isLoaded && !onboardingTasksCompleted) dispatch(getOnboardingAction());
  }, [dispatch, isLoaded, onboardingTasksCompleted]);

  const hasCompleted =
    onboardingTasksCompleted || onboardingTasks?.status === 'completed';

  const hasCompletedSetupPayments =
    hasCompleted ||
    onboardingTasks?.tasks.find((item) => item.name === 'setup_payments')
      ?.completed;

  const updateOnboardingTaskSetupPayments = () => {
    dispatch(updateOnboardingTaskSetupPaymentsAction());
  };

  useEffect(() => {
    /***
     * save the necessary information in localStorage
     * to avoid making unnecessary requests
     *  */
    if (hasCompleted) setOnboardingTasksCompleted(hasCompleted);
  }, [hasCompleted, setOnboardingTasksCompleted]);

  const hasStartedOnboarding =
    !hasCompleted && onboardingTasks?.tasks.some((task) => task.completed);

  return {
    ...status,
    hasCompleted,
    onboardingTasks,
    getOnboarding,
    hasCompletedSetupPayments,
    updateOnboardingTaskSetupPayments,
    hasStartedOnboarding,
  };
}

export default useOnboardingTasks;
