import { Alert, Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface DomainUpdatedSuccessAlertProps {
  domainName: string;
  onRemove: () => void;
}

export function DomainUpdatedSuccessAlert({
  domainName,
  onRemove,
}: Readonly<DomainUpdatedSuccessAlertProps>) {
  const t = useTranslationConfigurations('domains');

  return (
    <Alert appearance="success" onRemove={onRemove}>
      <Text>
        <Trans
          t={t}
          i18nKey="updatedSuccessAlert"
          values={{ domainName }}
          components={{ bold: <strong /> }}
        />
      </Text>
    </Alert>
  );
}
