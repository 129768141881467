import { Box, Text } from '@nimbus-ds/components';
import { generatePath } from 'react-router';
import { FreeShippingItemListResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { PopoverToogle, Stack } from 'commons/components';
import { FREE_SHIPPING_ROUTES } from 'domains/Marketing/FreeShipping';
import {
  DeliveryZonesItem,
  ShowMoreCategories,
} from 'domains/Marketing/FreeShipping/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface FreeShippingUseConditionsProps {
  freeShipping: FreeShippingItemListResponseDto;
}

export function FreeShippingUseConditions({
  freeShipping: { id, priceMinimum, zones, categories, categoriesVisibility },
}: Readonly<FreeShippingUseConditionsProps>) {
  const t = useTranslationMarketing('freeShipping.useConditions');
  const { goTo } = useNavegate();
  const handleClick = () => {
    goTo(generatePath(FREE_SHIPPING_ROUTES.editFreeShipping, { id }));
  };

  return (
    <PopoverToogle
      label={t('title')}
      content={
        <Box flexGrow="1">
          <Stack column align="flex-start" gap="4">
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('minimalPrice')}</Text>
              <Text>{priceMinimum}</Text>
            </Box>
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('categories')}</Text>
              <ShowMoreCategories
                categories={categories}
                categoriesVisibility={categoriesVisibility}
                onClick={handleClick}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="2">
              <Text fontWeight="bold">{t('shippingZones')}</Text>
              <DeliveryZonesItem zones={zones} />
            </Box>
          </Stack>
        </Box>
      }
    />
  );
}
