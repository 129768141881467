export const InfoMessageEndpoints = {
  getInfoMessage: 'getInfoMessage',
  updateInfoMessage: 'updateInfoMessage',
} as const;

export const InfoMessageTags = {
  GetInfoMessage: 'GetInfoMessage',
} as const;

export const INFO_MESSAGE_URL = 'v1/info-message';
