export const handleClickExternal =
  (target: string): (() => void) =>
  () => {
    window.location.href = target;
  };

export function createHiddenTempIframe(url: string, temp = 3000) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  iframe.addEventListener('load', () => {
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, temp);
  });

  iframe.src = url;
}
