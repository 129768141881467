import * as Yup from 'yup';
import { Country } from '@tiendanube/common/src/enums';
import { validateIdentification } from 'domains/Orders/DraftOrders/pages/NewDraftOrderPage/utils';

export const validationSchema = (country: Country) =>
  Yup.object().shape({
    businessId: Yup.string().test(
      'valid-identification',
      'draftOrders.newDraftOrder.clientData.errors.identification',
      (value) =>
        value && country === 'BR'
          ? validateIdentification(value, country)
          : true,
    ),
    mail: Yup.string().email('emailError'),
    address: Yup.string(),
    phone: Yup.string(),
  });
