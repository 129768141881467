import { useEffect, useMemo } from 'react';
import { ONLINE_CURRENT_THEME_SURVEY } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useScript } from 'commons/hooks';
import {
  useGetMainUserId,
  useGetPlanContext,
  useGetTags,
  useIsPlanLojinha,
} from 'domains/Auth/hooks';
import { DASHBOARD_BASE_PATH } from 'domains/Dashboard';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import { useGetThemesMetricsQuery } from 'domains/Online/Themes/themesApi';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import { useStoreInfo } from 'domains/PartnersApps/hooks';

declare global {
  interface Window {
    satismeter: (params: any) => void;
  }
}

export function Satismeter(): null {
  const mainUserId = useGetMainUserId();
  const planContext = useGetPlanContext();
  const { pathname } = useNavegate();
  const isLojinha = useIsPlanLojinha();
  const tags = useGetTags();
  const {
    name: storeName,
    id: storeId,
    country: countryCode,
    createdAt,
    hasFtp,
    successPortfolio,
    isTrial,
    currentSegment,
    npsElegible,
  } = useStoreInfo();
  const { data: themesMetrics } = useGetThemesMetricsQuery();
  const satismeterKey =
    countryCode === 'BR'
      ? import.meta.env.VITE_SATISMETER_WRITE_KEY_BR
      : import.meta.env.VITE_SATISMETER_WRITE_KEY_ZZ;

  const statusScript = useScript('https://app.satismeter.com/satismeter.js');

  const showSurvey = useMemo(
    () => ({
      showToDashboard: pathname.includes(DASHBOARD_BASE_PATH) && npsElegible,
      showToThemes:
        pathname.includes(THEMES_ROUTES.themes) &&
        !isLojinha &&
        tags.includes(ONLINE_CURRENT_THEME_SURVEY),
      showToMarketing: [
        MARKETING_ROUTES.couponList,
        MARKETING_ROUTES.promotionsList,
        MARKETING_ROUTES.freeShippingList,
      ].some((route) => pathname.includes(route)),
    }),
    [pathname, npsElegible, isLojinha, tags],
  );

  const satismeterConfig = useMemo(
    () => ({
      writeKey: satismeterKey,
      userId: mainUserId,
      traits: {
        name: storeName,
        createdAt,
        storeId,
        activeTheme: themesMetrics?.currentProductive,
        previousActiveTheme: themesMetrics?.previousProductive,
        activeThemeAt: themesMetrics?.productiveInstalledAt,
        draftTheme: themesMetrics?.currentDraft,
        previousDraftTheme: themesMetrics?.previousDraft,
        draftThemeAt: themesMetrics?.draftInstalledAt,
        storeCountry: countryCode,
        ftpOpen: hasFtp,
        successPortfolio: successPortfolio,
        isTrial: isTrial,
        sellerSegment: currentSegment,
        plan: planContext,
      },
    }),
    [
      satismeterKey,
      mainUserId,
      storeName,
      createdAt,
      storeId,
      countryCode,
      hasFtp,
      successPortfolio,
      isTrial,
      currentSegment,
      planContext,
      themesMetrics,
    ],
  );

  useEffect(() => {
    if (statusScript === 'ready' && !!satismeterKey) {
      if (!window.satismeter) {
        console.error('No satismeter found in window');
        return;
      }

      if (
        showSurvey.showToThemes ||
        showSurvey.showToDashboard ||
        showSurvey.showToMarketing
      ) {
        window.satismeter(satismeterConfig);
      }
    }
  }, [
    statusScript,
    npsElegible,
    satismeterKey,
    isLojinha,
    satismeterConfig,
    tags,
    showSurvey,
  ]);

  return null;
}
