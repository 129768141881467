import ShowByRole from 'App/components/ShowByRole';
import { PaymentStatusAlert } from 'domains/Billing/Checkout/components';
import { KycRejectedAlert } from 'domains/NuvemPagoCommons/components/KycRejectedAlert';
import AlertFirstSale from '../AlertFirstSale';
import EmailVerifiedAlert from '../EmailVerifiedAlert';
import FacebookConnectionStatusAlert from '../FacebookConnectionStatusAlert';
import NuvemEnvioAdminBlockingAlert from '../NuvemEnvioAdminBlockingAlert';
import TrialMessages from '../TrialMessages';
import UpdateSuggestion from '../UpdateSuggestion';

function NewAlertNotifications() {
  // This component duplicates AlertNotification to implement New Onboarding feature [NA-5773]. Should be unified after testing results.
  return (
    <>
      <ShowByRole includeRoles="full">
        <>
          <KycRejectedAlert />
          <NuvemEnvioAdminBlockingAlert />
          <EmailVerifiedAlert />
          <TrialMessages />
          <FacebookConnectionStatusAlert />
          <UpdateSuggestion />
        </>
      </ShowByRole>
      <ShowByRole includeRoles="orders">
        <AlertFirstSale />
      </ShowByRole>
      <ShowByRole includeRoles="full">
        <PaymentStatusAlert concept="plan-cost" isExternalContext />
      </ShowByRole>
    </>
  );
}

export default NewAlertNotifications;
