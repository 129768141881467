import { Card, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function CardTrackingPageSkeleton() {
  const t = useTranslationOrders();

  return (
    <Card title={t('trackingPage.title')}>
      <Stack column align="stretch">
        <Text>{t('trackingPage.description')}</Text>
        <Stack>
          <Text.Skeleton />
          <Text.Skeleton />
        </Stack>
      </Stack>
    </Card>
  );
}

export default CardTrackingPageSkeleton;
