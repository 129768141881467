import { Link, Text, Box } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

interface FilterUpsellOnAlertCardProps {
  hasInternals: boolean;
}

export default function FilterUpsellOnAlertCard({
  hasInternals,
}: FilterUpsellOnAlertCardProps) {
  const t = useTranslationsMetafields();
  const { handleGoToPlans } = useGoToPlans();

  const handleClick = () => {
    handleGoToPlans();
  };

  const alertText = hasInternals
    ? t(`alertMetafieldFilterUpsellOnAlertCard.withMetafields.description`)
    : t(`alertMetafieldFilterUpsellOnAlertCard.withoutMetafields.description`);

  return (
    <Box margin="4">
      <AlertSessionStorage
        appearance="primary"
        title={t(`alertMetafieldFilterUpsellOnAlertCard.title`)}
        keyName="showFilterUpsellAlertCard"
      >
        <Text>{alertText}</Text>
        <Link appearance="primary" textDecoration="none" onClick={handleClick}>
          {t(`alertMetafieldFilterUpsellOnAlertCard.button`)}
        </Link>
      </AlertSessionStorage>
    </Box>
  );
}
