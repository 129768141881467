import { useState } from 'react';
import { Accordion, Box, Button, Card, Title } from '@nimbus-ds/components';
import { useGetSubscriptions } from 'domains/Billing/Checkout/hooks';
import { PaymentDataContent } from 'domains/Billing/Checkout/pages/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import SummaryError from '../../SummaryError';
import SummarySkeleton from '../../SummarySkeleton';
import CancelRecurrentPaymentModal from '../CancelRecurrentPaymentModal/CancelRecurrentPaymentModal';

function SummaryWithRP() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.withRP',
  );
  const {
    total,
    isLoading,
    isError,
    refreshSubscriptions,
    subscriptions,
    currency,
  } = useGetSubscriptions();
  const [cancelationModalOpen, setCancelationModalOpen] = useState(false);
  const toggleCancelationModal = () => {
    setCancelationModalOpen((prevState) => !prevState);
  };
  const formatCurrency = useFormatCurrency();

  if (isLoading) return <SummarySkeleton />;

  if (isError) return <SummaryError onClick={refreshSubscriptions} />;

  if (!total) return null;

  return (
    <>
      <Card padding="none">
        <Box gap="2" display="flex" flexDirection="column">
          <Accordion>
            <Accordion.Item index="0">
              <Accordion.Header borderTop="none">
                <Box width="100%">
                  <Title as="h3" textAlign="left">
                    {`${t('title')}: ${formatCurrency(total, currency)}`}
                  </Title>
                </Box>
              </Accordion.Header>

              <Accordion.Body>
                {subscriptions && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    flexGrow="1"
                  >
                    <PaymentDataContent charges={subscriptions} />
                  </Box>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Box paddingRight="4" paddingBottom="4" paddingLeft="4">
            <Card.Footer>
              <Button appearance="primary" onClick={toggleCancelationModal}>
                {t('cancelRecurrentPayment')}
              </Button>
            </Card.Footer>
          </Box>
        </Box>
      </Card>

      {cancelationModalOpen && (
        <CancelRecurrentPaymentModal onClose={toggleCancelationModal} />
      )}
    </>
  );
}

export default SummaryWithRP;
