/* eslint-disable max-statements */
import { generatePath } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { openWindow } from 'commons/utils/window';
import { useHasShippingOcaPrint } from 'domains/Auth/hooks';
import { fulfillmentOrdersRoutes } from 'domains/FulfillmentOrders';
import { DocumentType } from '../../constants';

interface GoToPrintPageProps {
  ids: string[];
  type: DocumentType;
  hasOca?: boolean;
  hasOcaAndNotGeneratedTag?: boolean;
  forceOca?: boolean;
}

function useGoToPrintPage() {
  const { goTo } = useNavegate();
  const hasOcaPrint = useHasShippingOcaPrint();

  const goToPrintPage = (params: GoToPrintPageProps) => {
    const { ids, type } = params;

    if (
      params.hasOca &&
      params.hasOcaAndNotGeneratedTag &&
      type === DocumentType.LABEL
    ) {
      if (hasOcaPrint) {
        const routeParams: {
          ids: string;
          overwrite?: string;
        } = {
          ids: ids.join(','),
        };
        if (params.forceOca) {
          routeParams.overwrite = 'overwrite';
        }
        goTo(generatePath(fulfillmentOrdersRoutes['prePrintOca'], routeParams));
        return;
      }

      openWindow(
        `admin/orders/oca_pre_multiple_packing_slip/${ids.join(',')}`,
        true,
      );
      return;
    }

    if (params.hasOca && type === DocumentType.LABEL) {
      if (hasOcaPrint) {
        goTo(
          generatePath(fulfillmentOrdersRoutes[type], {
            ids: ids.join(','),
            oca: 'oca',
          }),
        );
        return;
      }

      openWindow(`admin/orders/packing_slip/${ids.join(',')}`, true);
      return;
    }

    goTo(generatePath(fulfillmentOrdersRoutes[type], { ids: ids.join(',') }));
  };

  return { goToPrintPage };
}

export default useGoToPrintPage;
