import { FILTER_ALL } from '@tiendanube/common/src/domains/marketing/coupons/types';
import { InterfaceIdLabel } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';
import { useTranslationMarketing } from '../../hook';
import { CouponFiltersKeys, CouponFiltersType } from '../couponsService';

function useFilterLabels(filters: CouponFiltersType) {
  const t = useTranslationMarketing('coupons.filters');

  const buildLabel = (key: CouponFiltersKeys): InterfaceIdLabel | null => {
    if (key === 'page' || key === 'sortBy' || filters[key] === FILTER_ALL)
      return null;
    if (key === 'createdAtMin')
      return {
        id: key,
        label: `${t('createdAt.from')}: ${dateFormat(
          filters.createdAtMin,
          Format.DAY_MONTH_YEAR_NUMBER,
        )}`,
      };
    if (key === 'createdAtMax')
      return {
        id: key,
        label: `${t('createdAt.until')}: ${dateFormat(
          filters.createdAtMax,
          Format.DAY_MONTH_YEAR_NUMBER,
        )}`,
      };
    if (key === 'q')
      return {
        id: key,
        label: filters.q,
      };
    const filterKey = t(`${key}.title`);
    const filterValue = t(`${key}.${filters[key]}`).toLowerCase();
    return {
      id: key,
      label: `${filterKey}: ${filterValue}`,
    };
  };

  return (Object.keys(filters) as CouponFiltersKeys[])
    .map(buildLabel)
    .filter((label): label is InterfaceIdLabel => label !== null);
}

export default useFilterLabels;
