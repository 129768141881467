import { useEffect } from 'react';
import { Card, Link, Tag, Text, Title } from '@nimbus-ds/components';
import { EditIcon, HomeIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import ShowByRole from 'App/components/ShowByRole';
import { FEATURE_MAX_LOCATIONS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { ErrorScreen, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { SHIPPING_ADD_LOCATION } from 'config/upsellFlowSources';
import { useHasAllowedMultipleLocation } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useAddressingConfigurationRulesManagement } from 'domains/Shipping/DeliveryMethods/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useMultiCDActivation from 'domains/Shipping/Locations/hooks/useMultiCDActivation';
import MultiCdEnableModal from 'domains/Shipping/Locations/pages/LocationListPage/components/MultiCdEnableModal';
import {
  trackingShippinCreateSecondaryCDByAddressingRuleCard,
  trackingShippingOpenAddressingRule,
} from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ActiveRulesCardList from './ActiveRulesCardList';
import AddressingConfigurationInfoCardSkeleton from './Skeleton';
import { ADDRESSING_RULES_TYPES } from '../constants';
import AddressingConfigurationModal from '../Modal';

function AddressingConfigurationInfoCard(): JSX.Element {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');
  const { goTo } = useNavegate();
  const hasAllowedMultiLocations = useHasAllowedMultipleLocation();
  const [
    showConfigurationModal,
    openConfigurationModal,
    closeConfigurationModal,
  ] = useModal();
  const { rules, getStatus, fetchRules } =
    useAddressingConfigurationRulesManagement();
  const { locations, fetchLocations } = useLocations();
  const { available, getMultiCDAvailability } = useMultiCDActivation();
  const [showMulticdModal, openMulticdModal, closeMulticdModal] = useModal();

  const isError = getStatus === 'error';

  const rulesWithoutMaterialization = rules.filter(
    (rule) => rule.type !== ADDRESSING_RULES_TYPES.materialize && rule.enabled,
  );

  const primaryRule = rules.find(
    (rule) => rule.priority === 1 && rule.enabled,
  ) as AddressingConfigurationRuleResponseDto;

  const fetchRulesAndLocations = () => {
    fetchRules();
    fetchLocations();
  };

  const handleClickAdd = useUpsellFlow({
    title: t('info.locations.upsell'),
    featureKey: FEATURE_MAX_LOCATIONS,
    minValue: 2,
    trackingSource: SHIPPING_ADD_LOCATION,
    callback: () => {
      if (available) {
        trackingShippinCreateSecondaryCDByAddressingRuleCard('locations');
        goTo('/settings/locations/new/secondary');
      }

      if (!available) {
        trackingShippinCreateSecondaryCDByAddressingRuleCard(
          'multicd-unavailable',
        );
        openMulticdModal();
      }
    },
    onCloseModal: () => {
      trackingShippinCreateSecondaryCDByAddressingRuleCard('plans');
    },
  });

  const notAllowedClick = () => {
    trackingShippinCreateSecondaryCDByAddressingRuleCard('comingSoon');
  };

  useEffect(() => {
    getMultiCDAvailability();
  }, [getMultiCDAvailability]);

  return (
    <Card>
      <Card.Header>
        <Stack justify="space-between">
          <Title as="h4">{t('info.title')}</Title>
          <Tag appearance={hasAllowedMultiLocations ? 'primary' : 'neutral'}>
            {t('info.new')}
          </Tag>
        </Stack>
      </Card.Header>
      <Card.Body>
        {!isError && (
          <Stack column spacing="base" align="stretch">
            <Text fontSize="base" lineHeight="base" textAlign="left">
              {t('info.description')}
            </Text>
            {locations.length > 1 && primaryRule && (
              <ActiveRulesCardList rules={rules} />
            )}
          </Stack>
        )}
        {isError && (
          <ErrorScreen
            description={t('info.error.description')}
            onRetry={fetchRulesAndLocations}
          />
        )}
      </Card.Body>
      {!isError && (
        <Card.Footer>
          {locations.length > 1 && (
            <Link
              onClick={() => {
                openConfigurationModal();
                trackingShippingOpenAddressingRule(rules);
              }}
              appearance="primary"
              textDecoration="none"
            >
              <EditIcon />
              {rulesWithoutMaterialization.length > 0
                ? t('info.edit')
                : t('info.create')}
            </Link>
          )}
          {locations.length === 1 && (
            <ShowByRole includeRoles={['locations']}>
              <>
                {hasAllowedMultiLocations && (
                  <CalloutCard
                    appearance="primary"
                    title={t('info.locations.title')}
                    subtitle={t('info.locations.subtitle')}
                    icon={HomeIcon}
                    onClick={handleClickAdd}
                  />
                )}
                {!hasAllowedMultiLocations && (
                  <CalloutCard
                    appearance="neutral"
                    title={t('info.locations.title')}
                    subtitle={t('info.locations.subtitle')}
                    icon={HomeIcon}
                    onClick={notAllowedClick}
                    link={
                      <Tag>
                        <Text fontSize="caption" lineHeight="caption">
                          {t('info.locations.comingSoon')}
                        </Text>
                      </Tag>
                    }
                  />
                )}
              </>
            </ShowByRole>
          )}
        </Card.Footer>
      )}
      {!isError && showConfigurationModal && (
        <AddressingConfigurationModal closeModal={closeConfigurationModal} />
      )}
      {!isError && showMulticdModal && (
        <MultiCdEnableModal onClose={closeMulticdModal} />
      )}
    </Card>
  );
}

AddressingConfigurationInfoCard.Skeleton =
  AddressingConfigurationInfoCardSkeleton;
export default AddressingConfigurationInfoCard;
