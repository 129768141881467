import { CustomPropertyResponseDtoV2 } from '@tiendanube/common';
import { InteractiveList, Stack, Text } from '@tiendanube/components';
import { LanguagesType, LanguageInterface } from 'App/i18n';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { getSuggestedListOptionsByAttribute } from '../../utils';

interface CustomAttributeProps {
  suggestedValues?: CustomPropertyResponseDtoV2['suggested'];
  languageToUse: LanguagesType;
  onCheckOption: (selected: string[]) => void;
  children: React.ReactElement;
  selectedValues: LanguageInterface[];
}

function CustomAttribute({
  suggestedValues,
  languageToUse,
  onCheckOption,
  selectedValues,
  children,
}: CustomAttributeProps) {
  const t = useTranslationCatalog();
  const suggestedOptions = getSuggestedListOptionsByAttribute(
    suggestedValues ?? null,
    selectedValues,
    languageToUse,
  );

  return (
    <Stack column align="stretch" spacing="loose">
      <Stack column align="stretch">
        <Text bold>{t('products.variants.options')}</Text>
        <Stack column align="stretch">
          {children}
        </Stack>
      </Stack>
      {suggestedOptions.length > 0 && (
        <Stack column align="stretch">
          <Text bold>{t('products.variants.suggestedValues')}</Text>
          <InteractiveList
            mode="multi"
            onChange={onCheckOption}
            options={suggestedOptions}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default CustomAttribute;
