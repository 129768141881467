import { BaseCard, Grid, Input, Stack, Title } from '@tiendanube/components';
import { useTranslationAccount } from 'domains/Account/hooks';

function GeneralInformationBusinessDataCard(): JSX.Element {
  const t = useTranslationAccount();

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">
          {t('businessDataPage.generalInformationCard.title') as string}
        </Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Input.Skeleton />
          <Stack.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column sm={2} md={2} lg={2}>
                  <Input.Skeleton />
                </Grid.Column>
                <Grid.Column sm={2} md={2} lg={2}>
                  <Input.Skeleton />
                </Grid.Column>
                <Grid.Column sm={2} md={2} lg={2}>
                  <Input.Skeleton />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Stack.Item>
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default GeneralInformationBusinessDataCard;
