import { Button, Stack, Text } from '@tiendanube/components';
import {
  CreditCardIcon,
  EditIcon,
  MoneyIcon,
  ShoppingCartIcon,
  StatsIcon,
} from '@tiendanube/icons';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { InfoModal } from 'commons/components';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders.onboardingNuvemPagoModal';

interface OnboardingNuvemPagoModalProps {
  id: string;
  onClick: () => void;
}

const icons = {
  money: MoneyIcon,
  shoppingCart: ShoppingCartIcon,
  creditCard: CreditCardIcon,
  edit: EditIcon,
  stats: StatsIcon,
};

function OnboardingNuvemPagoModal({
  id,
  onClick,
}: OnboardingNuvemPagoModalProps): JSX.Element {
  const { StackInfoIcon } = InfoModal;
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const handleClick = () => {
    invokeEventPayments('onBoardSettings', id);
    onClick();
  };

  return (
    <InfoModal
      text={t('text')}
      title={t('title')}
      storage="wasOnboardingNuvemPagoModalShowed"
    >
      <Stack column align="flex-start">
        <Text>{t('subtitle')}</Text>
        <Stack column spacing="loose" justify="center">
          <Stack column align="stretch">
            <StackInfoIcon
              color="blue"
              icon={icons[t('items.1.icon')]}
              title={t('items.1.title')}
              subtitle={t('items.1.subtitle')}
            />
            <StackInfoIcon
              color="blue"
              icon={icons[t('items.2.icon')]}
              title={t('items.2.title')}
              subtitle={t('items.2.subtitle')}
            />
            <StackInfoIcon
              color="blue"
              icon={icons[t('items.3.icon')]}
              title={t('items.3.title')}
              subtitle={t('items.3.subtitle')}
            />
            <StackInfoIcon
              color="blue"
              icon={icons[t('items.4.icon')]}
              title={t('items.4.title')}
              subtitle={t('items.4.subtitle')}
            />
          </Stack>
          <Stack justify="center">
            <FeatureFlag
              flag={NEW_ONBOARDING_B}
              renderElse={
                <Button appearance="primary" onClick={handleClick}>
                  {`${t('button')}`}
                </Button>
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </InfoModal>
  );
}

export default OnboardingNuvemPagoModal;
