import {
  CloudflareIPsMigrationCheckResponseDto,
  ProvideAiFeedbackRequestDto,
  RegenerateAiContentRequestDto,
  RegenerateAiContentResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  DASHBOARD_CLOUDFLARE_MIGRATION_CHECK,
  DASHBOARD_DISMISS_CLOUDFLARE_BLOCK_URL,
  DASHBOARD_USERS_FORWARD_CONFIRMATION_EMAIL_URL,
} from '../urls';

export async function forwardConfirmationEmail(): Promise<void> {
  await axios.post(DASHBOARD_USERS_FORWARD_CONFIRMATION_EMAIL_URL);
}

export async function dismissCloudflareBlock(
  expiration: number,
): Promise<void> {
  await axios.post(DASHBOARD_DISMISS_CLOUDFLARE_BLOCK_URL, {
    timestamp: expiration,
  });
}

export async function cloudflareIPsMigrationCheck(): Promise<CloudflareIPsMigrationCheckResponseDto> {
  const { data } = await axios.patch(DASHBOARD_CLOUDFLARE_MIGRATION_CHECK);

  return data;
}

export async function regenerateAiContent(
  requestDto: RegenerateAiContentRequestDto,
): Promise<RegenerateAiContentResponseDto> {
  const response = await axios.post(
    `/v1/dashboard/ai-generation/regenerate`,
    requestDto,
  );
  return response.data;
}

export async function provideAiFeedback(
  requestDto: ProvideAiFeedbackRequestDto,
): Promise<void> {
  const response = await axios.post(`/v1/dashboard/ai-feedback`, requestDto);
  return response.data;
}
