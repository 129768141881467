import { useCallback } from 'react';
import {
  AuthenticationFactorResponseDto,
  RecoveryCodesResponseDto,
} from '@tiendanube/common';
import { Actions, Steps, useSteps } from '../../hooks';
import AuthenticationFactorList from '../AuthenticationFactorList';
import ConfirmIdentityPage from '../ConfirmIdentityPage';
import LinkAccountPage from '../LinkAccountPage';
import RecoveryCodesModal from '../RecoveryCodesModal';
import RecoveryCodesPage from '../RecoveryCodesPage';
import SetUpAccountPage from '../SetUpAccountPage';

interface CurrentPageProps {
  useStepsResponse: ReturnType<typeof useSteps>;
  authenticationFactors: AuthenticationFactorResponseDto[];
  recoveryCodes: RecoveryCodesResponseDto[];
  resetRecoveryCodes: () => void;
  authenticationFactorsAreLoading: boolean;
}

function CurrentPage({
  useStepsResponse,
  authenticationFactors,
  recoveryCodes,
  resetRecoveryCodes,
  authenticationFactorsAreLoading,
}: Readonly<CurrentPageProps>) {
  const onRecoveryCodesContinue = useCallback(() => {
    if (useStepsResponse.step === Steps.RecoveryCodes) {
      resetRecoveryCodes();
      useStepsResponse.nextStep();
    }
  }, [resetRecoveryCodes, useStepsResponse]);

  const { step } = useStepsResponse;

  if (step === Steps.List) {
    const { startFlow } = useStepsResponse;
    return (
      <>
        <AuthenticationFactorList
          isLoading={authenticationFactorsAreLoading}
          authenticationFactors={authenticationFactors}
          onContinue={startFlow}
        />
        <RecoveryCodesModal
          open={recoveryCodes.length > 0}
          recoveryCodes={recoveryCodes}
          onDismiss={resetRecoveryCodes}
        />
      </>
    );
  }
  const {
    backStep,
    nextStep,
    currentAction,
    currentFactor,
    currentFactorIsActive,
  } = useStepsResponse;
  switch (step) {
    case Steps.ConfirmIdentity:
      return <ConfirmIdentityPage onCancel={backStep} onContinue={nextStep} />;
    case Steps.SetUpAccount:
      return <SetUpAccountPage onCancel={backStep} onContinue={nextStep} />;
    case Steps.LinkAccount:
      return (
        <LinkAccountPage
          onCancel={backStep}
          onContinue={nextStep}
          active={currentFactorIsActive}
          authenticationFactor={currentFactor}
        />
      );
    case Steps.RecoveryCodes:
      return (
        <RecoveryCodesPage
          onContinue={onRecoveryCodesContinue}
          recoveryCodes={recoveryCodes}
          allowToGenerateNewCodes={currentAction === Actions.Viewing}
        />
      );
  }
}

export default CurrentPage;
