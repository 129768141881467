import { Alert, Text, Link } from '@nimbus-ds/components';
import { useTranslationCustomers } from 'domains/Customers/hooks';

function ExportCustomerListAlert({
  exportingStatus,
  csvUrl,
  ressetExportingStatus,
}) {
  const t = useTranslationCustomers();

  const handleDismiss =
    exportingStatus === 'pending' ? undefined : ressetExportingStatus;

  const title = {
    ready: t(`customerListPage.exportAlert.title.success`),
    downloaded: t(`customerListPage.exportAlert.title.success`),
    pending: t(`customerListPage.exportAlert.title.loading`),
    failed: t(`customerListPage.exportAlert.title.error`),
  }[exportingStatus];

  const description = {
    ready: t(`customerListPage.exportAlert.message.success`),
    downloaded: t(`customerListPage.exportAlert.message.success`),
    pending: t(`customerListPage.exportAlert.message.loading`),
    failed: t(`customerListPage.exportAlert.message.error`),
  }[exportingStatus];

  const appearance = {
    ready: 'success',
    downloaded: 'success',
    pending: 'primary',
    failed: 'danger',
  }[exportingStatus];

  const show = exportingStatus !== 'idle';

  const downloadTextLink =
    exportingStatus === 'success' || exportingStatus === 'downloaded'
      ? t('customerListPage.exportAlert.download')
      : undefined;

  return (
    <Alert
      title={title}
      appearance={appearance}
      show={show}
      onRemove={handleDismiss}
    >
      {exportingStatus !== 'failed' && <Text as="p">{description}</Text>}
      {!!downloadTextLink && (
        <Link appearance="neutral" as="a" href={csvUrl} onClick={handleDismiss}>
          {downloadTextLink}
        </Link>
      )}
    </Alert>
  );
}

export default ExportCustomerListAlert;
