import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchInternationalShipping as fetchInternationalShippingAction,
  deleteInternationalShipping as deleteInternationalShippingAction,
  getDeleteInternationalShippingStatus,
  getInternationalShippingListData,
  getInternationalShippingListStatus,
  cleanDeleteInternationalShippingStatus,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useGetInternationalShipping() {
  const dispatch = useAppDispatch();
  const internationalShipping = useSelector(getInternationalShippingListData);
  const { isLoading, isError } = useSelector(
    getInternationalShippingListStatus,
  );
  const deleteStatus = useSelector(getDeleteInternationalShippingStatus);

  const fetchInternationalShipping = useCallback(() => {
    dispatch(fetchInternationalShippingAction());
  }, [dispatch]);

  const deleteInternationalShipping = useCallback(
    async (id: string) => {
      await dispatch(deleteInternationalShippingAction(id));
      dispatch(cleanDeleteInternationalShippingStatus());
    },
    [dispatch],
  );

  return {
    fetchInternationalShipping,
    deleteInternationalShipping,
    internationalShipping,
    isLoading,
    isError,
    deleteStatus,
  };
}

export default useGetInternationalShipping;
