import { CombinedState } from '@reduxjs/toolkit';
import { DevMode } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { StatusType } from 'commons/types';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export interface DevModeSchema {
  status: StatusType;
  data: DevMode | null;
}

export const getDevModeState = (
  state: RootStateType,
): CombinedState<DevModeSchema> => state.devMode;

export const getDevModeSelector = createSimpleSelector(
  [getDevModeState],
  (devMode) => devMode.data,
);
