import { FEATURE_METAFIELDS_CUSTOMER } from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';

export default function useCustomerMetafieldsAccess() {
  const { isAvailable: hasMetafieldsCustomerByPlan } = useStatusFeatureByKey(
    FEATURE_METAFIELDS_CUSTOMER,
  );

  return {
    isAvailable: hasMetafieldsCustomerByPlan,
  };
}
