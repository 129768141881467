// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './online.es-AR.json';
import esCL from './online.es-CL.json';
import esCO from './online.es-CO.json';
import esMX from './online.es-MX.json';
import ptBR from './online.pt-BR.json';

const onlineTranslations: DomainTranslations = {
  name: 'online',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
    [Locale.CHILE]: esCL,
    [Locale.COLOMBIA]: esCO,
  },
};

export default onlineTranslations;
