import { Box, Text } from '@nimbus-ds/components';
import { LocationIcon } from '@nimbus-ds/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent, HeaderTop } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import EditStock, { EditStockProps } from '../EditStock';

interface EditShowModalProps extends EditStockProps {
  isShow: boolean;
  showHeader?: boolean;
  locationName?: string;
}

function EditStockModal({
  isShow,
  productId,
  variantId,
  currentStock,
  isVariantStock,
  variantName,
  displayStockTypes = isVariantStock,
  displayApplyToAll = isVariantStock,
  locationId,
  locationName,
  showHeader = true,
  onClose,
}: Readonly<EditShowModalProps>): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: () => onClose() }} />}
      headerContent={
        <HeaderContent title={t('products.detail.editStock.title')} />
      }
    >
      {!!locationName && (
        <Box display="inline-flex" alignItems="center" gap="1">
          <LocationIcon />
          <Text lineHeight="caption">{locationName}</Text>
        </Box>
      )}
      <EditStock
        productId={productId}
        variantId={variantId}
        currentStock={currentStock}
        isVariantStock={isVariantStock}
        variantName={variantName}
        displayStockTypes={displayStockTypes}
        displayApplyToAll={displayApplyToAll}
        locationId={locationId}
        showHeader={showHeader}
        onClose={onClose}
      />
    </ModalAside>
  );
}

export default EditStockModal;
