import { ReactNode } from 'react';
import './Center.css';

interface CenterInterface {
  children: ReactNode;
}

function Center({ children }: CenterInterface): JSX.Element {
  return <div className="stratus--center">{children}</div>;
}

export default Center;
