import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getImportMaxLines } from 'domains/Catalog/Products/productsSlice';
import {
  importMaxLines,
  importMaxLinesStatus,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

function useImportMaxLines() {
  const dispatch = useAppDispatch();
  const maxLines = useSelector(importMaxLines);
  const maxLinesStatus = useSelector(importMaxLinesStatus);
  const loadingMaxLines =
    maxLinesStatus === 'loading' || maxLinesStatus === 'idle';

  useEffect(() => {
    if (maxLinesStatus === 'idle') {
      dispatch(getImportMaxLines());
    }
  }, [dispatch, maxLinesStatus]);

  return {
    maxLines,
    loadingMaxLines,
  };
}

export default useImportMaxLines;
