import { Spinner, InterfaceSpinner } from '@tiendanube/components';
import Center from '../Center';

interface SpinnerProps extends InterfaceSpinner {
  show?: boolean;
}

function SpinnerStratus({ show = true, ...rest }: SpinnerProps) {
  if (!show) return null;

  return (
    <Center>
      <Spinner size="large" {...rest} />
    </Center>
  );
}

export default SpinnerStratus;
