import { Tag } from '@nimbus-ds/components';
import { ExclamationTriangleIcon, CheckCircleIcon } from '@nimbus-ds/icons';
import { DomainStatus } from '@tiendanube/common';
import { useGetDomainsErrorsQuery } from 'domains/Configurations/Domains/domainsApi';
import {
  useCustomDomainDisabled,
  useHasDomainsErrorAlertTag,
} from 'domains/Configurations/Domains/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

const ICON_SIZE = 14;

interface DomainStatusTagProps {
  domainId: string;
  status: DomainStatus;
  isOurDomain: boolean;
}

export function DomainStatusTag({
  domainId,
  status,
  isOurDomain,
}: Readonly<DomainStatusTagProps>) {
  const t = useTranslationConfigurations('domains');
  const isFreePlan = useCustomDomainDisabled();
  const hasDomainsErrorAlertTag = useHasDomainsErrorAlertTag();
  const { data: domainsWithErrors } = useGetDomainsErrorsQuery(undefined, {
    skip: !hasDomainsErrorAlertTag,
  });

  const verificationError =
    !isFreePlan && domainsWithErrors?.ids.includes(domainId);

  if (isFreePlan && !isOurDomain)
    return (
      <Tag appearance="neutral">
        <ExclamationTriangleIcon size={ICON_SIZE} />
        {t('tags.inactive')}
      </Tag>
    );

  if (verificationError && hasDomainsErrorAlertTag)
    return (
      <Tag appearance="danger">
        <ExclamationTriangleIcon size={ICON_SIZE} />
        {t('tags.errorDns')}
      </Tag>
    );

  if (status === 'ok')
    return (
      <Tag appearance="success">
        <CheckCircleIcon size={ICON_SIZE} />
        {t('tags.active')}
      </Tag>
    );

  if (status === 'error')
    return (
      <Tag appearance="danger">
        <ExclamationTriangleIcon size={ICON_SIZE} />
        {t('tags.inactive')}
      </Tag>
    );

  return (
    <Tag appearance="warning">
      <ExclamationTriangleIcon size={ICON_SIZE} />
      {t('tags.needDns')}
    </Tag>
  );
}
