import { Box } from '@nimbus-ds/components';
import { Label } from '@tiendanube/components';
import { DragIcon, PictureIcon } from '@tiendanube/icons';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import TipsForTakingProductPhotosModal from '../TipsForTakingProductPhotosModal';

function RecommendationsGridRow(): JSX.Element {
  const t = useTranslationCatalog('products.detail');
  return (
    <Box display="flex" flexWrap="wrap" gap="2">
      <Label
        id="ImagePreviewDesktopLabel1"
        label={`${t('minSize')}`}
        icon={DragIcon}
      />
      <Label
        id="ImagePreviewDesktopLabel2"
        label={`${t('recommendedFormats')}`}
        icon={PictureIcon}
      />
      <TipsForTakingProductPhotosModal />
    </Box>
  );
}

export default RecommendationsGridRow;
