import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import { StatusObjectType } from 'commons/types';
import {
  cleanStatusVariants as cleanStatusVariantsAction,
  getProductVariants as getProductVariantsAction,
} from 'domains/Catalog/Products/productsSlice';
import { createProductsStatusVariantsById } from 'domains/Catalog/Products/productsSlice/productSelectors';

interface UseProductVariantsResult {
  statusVariants: StatusObjectType;
  cleanStatusVariants: () => void;
  getProductVariants: () => void;
}

function useProductVariants(idProduct: string): UseProductVariantsResult {
  const dispatch = useAppDispatch();

  const getProductsStatusVariantsById = useMemo(
    createProductsStatusVariantsById,
    [],
  );

  const statusVariants = useSelector((state: RootStateType) =>
    getProductsStatusVariantsById(state, idProduct),
  );

  const cleanStatusVariants = useCallback(() => {
    dispatch(cleanStatusVariantsAction({ id: idProduct }));
  }, [dispatch, idProduct]);

  const getProductVariants = useCallback(() => {
    dispatch(getProductVariantsAction(idProduct));
  }, [dispatch, idProduct]);

  return {
    cleanStatusVariants,
    statusVariants,
    getProductVariants,
  };
}

export default useProductVariants;
