import { useCallback } from 'react';
import { BackgroundGalleryElement } from 'domains/Catalog/Products/pages/components/ImageGallery/components/EditImageModal/EditImageActions/components/ChangeBackgroundImageModal/components/backgrounds';
import { ImageGalleryState } from 'domains/Catalog/Products/pages/components/ImageGallery/ImageGallery';
import useEditImage from './useEditImage';
import { useLazyChangeImageBackgroundQuery } from '../aiApi';

function useChangeImageBackground(initialImage: ImageGalleryState) {
  const [getData, { data, isError, isFetching }] =
    useLazyChangeImageBackgroundQuery();

  const editedImageUncertain = data && data.uncertaintyScore > 0.7;

  const { displayedImage, validImageUrl } = useEditImage(
    initialImage,
    data?.imageBase64,
  );

  const handleBackgroundColorSelected = useCallback(
    async (color: string) => {
      await getData(
        {
          image_url: validImageUrl,
          color,
        },
        true,
      );
    },
    [getData, validImageUrl],
  );

  const handleBackgroundImageSelected = useCallback(
    async (background: BackgroundGalleryElement) => {
      await getData({
        image_url: validImageUrl,
        backgroundImageUrl: background.backgroundUrl,
      });
    },
    [getData, validImageUrl],
  );

  return {
    changingBackground: isFetching,
    errorOnBackgroundChange: isError,
    unSavedChanges: data !== undefined,
    displayedImage,
    editedImageUncertain,
    handleBackgroundColorSelected,
    handleBackgroundImageSelected,
  } as const;
}

export default useChangeImageBackground;
