import { useEffect } from 'react';
import { ErrorState } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { usePagesList } from 'domains/Online/ContentPages/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import {
  PagesListEmpty,
  TemplateModal,
  PagesListResult,
  PagesListResultSkeleton,
} from './components';

function PagesList() {
  const t = useTranslationOnline('contentPages.pageList');
  const [isShow, openModal, closeModal] = useModal();
  const { status, isEmpty, deleteStatus, getPages, refreshPage } =
    usePagesList();

  useEffect(() => {
    getPages();
  }, [getPages]);

  const isSuccess = status === 'success';

  const actionsDesktop: ActionProp[] = [
    {
      children: t('createButton'),
      icon: PlusCircleIcon,
      appearance: 'primary',
      onClick: openModal,
    },
  ];

  useToastStatus({
    error: t('toast.deletete.error'),
    onSuccess: refreshPage,
    status: deleteStatus,
  });

  const headerContent = (
    <HeaderContent
      title={t('title')}
      actions={
        isSuccess ? <ActionsDesktop actions={actionsDesktop} /> : undefined
      }
    />
  );

  const mainAction = {
    icon: PlusCircleIcon,
    onClick: () => openModal(),
    children: t('createButton'),
  };

  const headerTop = <HeaderTop mainAction={mainAction} />;

  return (
    <IonPageStratus
      width="medium"
      headerContent={headerContent}
      headerTop={isSuccess ? headerTop : undefined}
    >
      {status === 'loading' && <PagesListResultSkeleton />}
      {status === 'success' && !isEmpty && <PagesListResult />}
      {status === 'success' && isEmpty && (
        <PagesListEmpty openModal={openModal} />
      )}
      {status === 'error' && (
        <ErrorState
          title={t('error.title')}
          action={{
            onClick: refreshPage,
            children: t('error.retry'),
          }}
        />
      )}
      <TemplateModal isShow={isShow} onClose={closeModal} />
    </IonPageStratus>
  );
}

export default PagesList;
