import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getUpdateInstallmentsStatus,
  updateMercadoPagoInstallmentsAction,
} from '../../paymentProvidersSlice';

function useUpdateMercadoPagoInstallments() {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(
    getUpdateInstallmentsStatus,
  );

  const updateInstallments = async (): Promise<void> => {
    dispatch(updateMercadoPagoInstallmentsAction());
  };

  return {
    isLoading,
    updateInstallments,
    isError,
    isSuccess,
  };
}

export default useUpdateMercadoPagoInstallments;
