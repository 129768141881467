import dayjs from 'dayjs';
import { dateFormat, Format } from 'commons/utils/date';
import { useTranslationAccount } from 'domains/Account/hooks';

export function useGetLastActivityDescription(
  lastActivityDate: string,
  isMobile: boolean,
): string {
  const translationKey = isMobile ? 'mobile' : 'desktop';
  const t = useTranslationAccount(
    `sessionManagementPage.table.body.lastActivity.${translationKey}`,
  );
  const timeDifferenceInMs =
    new Date().getTime() - dayjs(lastActivityDate).valueOf();

  const elapsedMinutes = Math.floor(timeDifferenceInMs / (1000 * 60));
  if (elapsedMinutes === 1) {
    return t('sinceOneMinute');
  }
  if (elapsedMinutes < 60) {
    return t('sinceMinutes', { elapsedMinutes });
  }

  const elapsedHours = Math.floor(elapsedMinutes / 60);
  if (elapsedHours === 1) {
    return t('sinceOneHour');
  }
  if (elapsedHours < 24) {
    return t('sinceHours', { elapsedHours });
  }

  const formattedDate = formatDate(lastActivityDate, isMobile);

  return t('sinceDate', {
    formattedDate,
    interpolation: { escapeValue: false },
  });
}

function formatDate(date: string, isMobile: boolean): string {
  const format = isMobile ? Format.DD_MM_YYYY : Format.DAY_MONTH;
  return dateFormat(date, format);
}
