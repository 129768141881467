import { Text } from '@nimbus-ds/components';
import { useTranslationCommon } from 'commons/hooks';

export function CheckAIErrorsDisclaimer() {
  const t = useTranslationCommon();
  return (
    <Text
      as="span"
      fontSize="caption"
      fontWeight="regular"
      color="neutral-textDisabled"
    >
      {t('aiGeneration.checkErrorsDisclaimer')}
    </Text>
  );
}
