import { createSelector } from '@reduxjs/toolkit';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

export const getStatisticsFilters = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.filters,
);

export const getHasStatisticsFilters = createSelector(
  getStatisticsFilters,
  (filters) => {
    const hasComparedFilter =
      !!filters.comparePeriod ||
      !!filters.comparePeriodFrom ||
      !!filters.comparePeriodTo;

    const hasAppliedFilters =
      filters.mainPeriod !== 'currentDay' ||
      !!filters.mainPeriodFrom ||
      !!filters.mainPeriodTo ||
      hasComparedFilter;

    return {
      hasComparedFilter,
      hasAppliedFilters,
    };
  },
);
