import { ReactNode } from 'react';
import { Box, BoxProps, TagProps } from '@nimbus-ds/components';

interface AdBarProps {
  appearence: TagProps['appearance'];
  children: ReactNode;
}

const backgroundColors: Record<string, BoxProps['backgroundColor']> = {
  neutral: 'neutral-surface',
  primary: 'primary-surface',
  success: 'success-surface',
  warning: 'warning-surface',
  danger: 'danger-surface',
};

const borderColors = {
  neutral: 'neutral-surfaceHighlight',
  primary: 'primary-surfaceHighlight',
  danger: 'danger-surfaceHighlight',
};

export function AdBar({
  appearence = 'primary',
  children,
}: Readonly<AdBarProps>) {
  return (
    <Box
      backgroundColor={backgroundColors[appearence]}
      borderColor={borderColors[appearence]}
      borderBottomWidth={borderColors[appearence] ? '1' : 'none'}
      borderStyle="solid"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      height="3rem"
      gap="2"
      padding="2"
    >
      {children}
    </Box>
  );
}
