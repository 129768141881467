import { addMounthsToday, getTodayIsoString } from 'commons/utils/date';
import { Scope } from 'domains/Marketing/enums';
import { CouponValuesInterface } from './types';

export const initialValues: CouponValuesInterface = {
  couponCode: '',
  active: true,
  discountType: 'percentage',
  valuePercentage: '0',
  valueAbsolute: '0',
  includeShipping: false,
  limitedType: 'unlimited',
  clientLimitationType: 'unlimited',
  combinesWithOtherDiscounts: true,
  maxUses: '0',
  maxUsesPerClient: '0',
  dateType: 'unlimited',
  startDate: getTodayIsoString(),
  endDate: addMounthsToday(1),
  minPrice: '0',
  scopeType: Scope.ALL,
  categories: [],
  products: [],
};
