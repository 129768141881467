import {
  ReactNode,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react';

const noop = () => null;

export interface InterfaceNavTabsContext {
  showNavTabs: () => void;
  hideNavTabs: () => void;
  isShowNavTabs: boolean;
  showSideBar: () => void;
  hideSideBar: () => void;
  isShowSideBar: boolean;
  toggleSideBar: () => void;
}

interface NavTabsContextProviderProps {
  children: ReactNode;
}

export const NavTabsContext = createContext<InterfaceNavTabsContext>({
  showNavTabs: noop,
  hideNavTabs: noop,
  isShowNavTabs: true,
  showSideBar: noop,
  hideSideBar: noop,
  isShowSideBar: true,
  toggleSideBar: noop,
});

function NavTabsContextProvider({
  children,
}: Readonly<NavTabsContextProviderProps>) {
  const [isShowNavTabs, setIsShowNavTabs] = useState(true);
  const [isShowSideBar, setIsShowSideBar] = useState(false);

  const showNavTabs = useCallback(() => setIsShowNavTabs(true), []);
  const hideNavTabs = useCallback(() => setIsShowNavTabs(false), []);

  const showSideBar = useCallback(() => setIsShowSideBar(true), []);
  const hideSideBar = useCallback(() => setIsShowSideBar(false), []);
  const toggleSideBar = useCallback(
    () => setIsShowSideBar((prevValue) => !prevValue),
    [],
  );

  const value = useMemo(
    () => ({
      showNavTabs,
      hideNavTabs,
      isShowNavTabs,
      isShowSideBar,
      hideSideBar,
      showSideBar,
      toggleSideBar,
    }),
    [
      showNavTabs,
      hideNavTabs,
      isShowNavTabs,
      isShowSideBar,
      hideSideBar,
      showSideBar,
      toggleSideBar,
    ],
  );

  return (
    <NavTabsContext.Provider value={value}>{children}</NavTabsContext.Provider>
  );
}

export default NavTabsContextProvider;
