import { Card, Stack, Text } from '@tiendanube/components';
import { CopyLink } from 'commons/components';
import SendWhatsAppLink from 'commons/components/SendWhatsAppLink';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import ShareAbandonedCartCardSkeleton from './ShareAbandonedCartCardSkeleton';

interface CardShareAbandonedCartProps {
  ulrAbanadonedCart: string;
  phoneCustomer: string;
  nameClient: string;
  nameStore: string;
}

function ShareAbandonedCartCard({
  ulrAbanadonedCart,
  phoneCustomer,
  nameClient,
  nameStore,
}: CardShareAbandonedCartProps) {
  const t = useTranslationOrders();

  return (
    <Card title={t('abandonedCarts.cardShareAbandonedCart.title')}>
      <Stack column align="stretch" spacing="tight">
        <Text>{t('abandonedCarts.cardShareAbandonedCart.text')}</Text>
        <Stack>
          <CopyLink
            value={ulrAbanadonedCart}
            message={t('abandonedCarts.cardShareAbandonedCart.copyLinkMessage')}
            text={t('abandonedCarts.cardShareAbandonedCart.copyLinkText')}
            iconPosition="start"
          />
          <SendWhatsAppLink
            textWhatsapp={t('abandonedCarts.sendEmail.body', {
              nameClient,
              nameStore,
              interpolation: { escapeValue: false },
            })}
            phoneNumber={phoneCustomer}
            textChild={t(
              'abandonedCarts.cardShareAbandonedCart.whatsAppSendText',
            )}
            url={ulrAbanadonedCart}
          />
        </Stack>
      </Stack>
    </Card>
  );
}

ShareAbandonedCartCard.Skeleton = ShareAbandonedCartCardSkeleton;

export default ShareAbandonedCartCard;
