import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MetafieldSelectedsOrErrorListV2Type } from '@tiendanube/common';
import { CategoriesMetafieldsInterface, statusType } from './types';
import categoriesMetafieldsService from '../categoriesMetafieldsService';

const initialState: CategoriesMetafieldsInterface = {
  status: statusType.idle,
  data: {
    internals: [],
    fromApi: [],
  },
};

export const getCategoriesMetafields = createAsyncThunk<
  MetafieldSelectedsOrErrorListV2Type,
  string
>(
  'categories/metafields',
  async (id) => await categoriesMetafieldsService.getCategoriesMetafields(id),
);

const categoriesMetafields = createSlice({
  name: 'categoriesMetafields',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesMetafields.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = statusType.success;
      })
      .addCase(getCategoriesMetafields.pending, (state) => {
        state.status = statusType.loading;
      })
      .addCase(getCategoriesMetafields.rejected, (state) => {
        state.status = statusType.error;
      });
  },
});

export const { reducer } = categoriesMetafields;
