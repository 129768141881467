import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CouponsResponseDto } from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import { fetchCoupon as fetchCouponAction } from '../couponsSlice';
import { getCouponById } from '../couponsSlice/couponsSelectors';

export interface UseCouponByIdResult {
  coupon?: CouponsResponseDto;
}

function useCouponById(id: string): UseCouponByIdResult {
  const dispatch = useAppDispatch();

  const coupon = useSelector((state: RootStateType) =>
    getCouponById(state, id),
  );

  useEffect(() => {
    if (!coupon) {
      dispatch(fetchCouponAction(id));
    }
  }, [coupon, dispatch, id]);

  return { coupon };
}

export default useCouponById;
