import { useLocation } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import useAddPhysicalLocationPage, {
  PhysicalLocationBody,
} from './useAddPhysicalLocationPage';
import PhysicalLocationForm from '../DeliveryMethodsPage/components/PhysicalLocations/components/PhysicalLocationForm';
import { validationSchema } from '../EditPhysicalLocationPage/validationSchema';

const defaultPhysicalLocation = { name: '', extra: '' };

function AddPhysicalLocationPage() {
  const t = useTranslationShipping();
  const { search } = useLocation();
  const isNew = search.includes('new');
  const { goBack } = useNavegate();

  const { createPhysicalLocation, status } = useAddPhysicalLocationPage();

  const handleSubmit = (data: PhysicalLocationBody) => {
    const { extra, name } = data;
    createPhysicalLocation({ extra, name });
  };

  const { values, handleChange, handleOnSubmit, errors, isDirty } = useForm<
    PhysicalLocationBody,
    PhysicalLocationBody
  >({
    initialValues: defaultPhysicalLocation,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useToastStatus({
    error: t('physicalLocations.addAndEdit.errors.error'),
    success: t('physicalLocations.addAndEdit.errors.success'),
    status,
    onSuccess: () => {
      goBack();
    },
  });

  const title = isNew
    ? t('physicalLocations.addAndEdit.title.new')
    : t('physicalLocations.addAndEdit.title.add');

  return (
    <PhysicalLocationForm
      title={title}
      values={values}
      errors={errors}
      onChange={handleChange}
      onCancel={goBack}
      onSave={handleOnSubmit}
      loading={status === 'loading'}
      isDirty={isDirty}
    />
  );
}

export default AddPhysicalLocationPage;
