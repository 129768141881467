import { StatisticsInterface } from './types';
import { FiltersStatisticsInterface } from '../types';

export const defaultFilter: FiltersStatisticsInterface = {
  mainPeriod: 'currentWeek',
};

const paginationDefault = {
  totalPages: 0,
  currentPage: 1,
  totalResults: 0,
  perPage: 5,
  nextPage: null,
};

const defaultQuantity = {
  stock: '10',
  visits: '10',
  sales: '10',
  recipe: '2000',
};

const defaultTotals = {
  value: 0,
  previousValue: 0,
};

const defaultBarChartValues = {
  keys: [],
  values: [],
};

export const initialState: StatisticsInterface = {
  status: 'idle',
  daily: null,
  orders: null,
  products: null,
  filters: defaultFilter,
  globalInfoEntity: {
    data: {
      lastUpdate: '',
      results: {
        averageSpend: [],
        orders: [],
        uniqueVisits: [],
        billing: [],
        conversion: [],
      },
      totals: {
        averageSpend: defaultTotals,
        orders: defaultTotals,
        uniqueVisits: defaultTotals,
        billing: defaultTotals,
        conversion: defaultTotals,
      },
      granularity: 'hourly',
    },
    status: 'idle',
  },
  conversionStoreEntity: {
    data: [],
    status: 'idle',
  },
  statsProducts: {
    'low-stock': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.stock, page: 1 },
    },
    'best-sellers': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.sales, page: 1 },
    },
    'higher-revenue': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.recipe, page: 1 },
    },
    'most-visited': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.visits, page: 1 },
    },
    'least-sellers': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.sales, page: 1 },
    },
    'lower-revenue': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.recipe, page: 1 },
    },
    'least-visited': {
      status: 'idle',
      results: [],
      pagination: paginationDefault,
      filters: { quantity: defaultQuantity.visits, page: 1 },
    },
  },
  freeTrial: {
    status: 'idle',
    data: null,
    post: {
      status: 'idle',
    },
  },
  summary: {
    data: null,
    status: 'idle',
  },
  customers: {
    data: null,
    status: 'idle',
  },
  productsSummary: {
    data: {
      results: {
        sold: [],
      },
      totals: {
        sold: defaultTotals,
      },
      granularity: 'hourly',
    },
    status: 'idle',
  },
  paymentsSummary: {
    status: 'idle',
    data: {
      granularity: 'hourly',
      results: {
        revenueByInstallments: defaultBarChartValues,
        revenueByPaymentGateway: defaultBarChartValues,
        revenueByPaymentMethod: defaultBarChartValues,
        salesByInstallments: defaultBarChartValues,
        salesByPaymentGateway: defaultBarChartValues,
        salesByPaymentMethod: defaultBarChartValues,
      },
      summary: {
        revenueByInstallments: defaultTotals,
        revenueByPaymentGateway: defaultTotals,
        revenueByPaymentMethod: defaultTotals,
        salesByInstallments: defaultTotals,
        salesByPaymentGateway: defaultTotals,
        salesByPaymentMethod: defaultTotals,
      },
    },
  },
  acquisitionSummary: {
    status: 'idle',
    data: {
      granularity: 'hourly',
      results: {
        revenue: defaultBarChartValues,
        sales: defaultBarChartValues,
      },
      summary: {
        revenue: defaultTotals,
        sales: defaultTotals,
      },
    },
  },
  shippingSummary: {
    data: {
      granularity: 'hourly',
      results: {
        revenueByFreeShipping: defaultBarChartValues,
        revenueByShippingGateway: defaultBarChartValues,
        salesByFreeShipping: defaultBarChartValues,
        salesByShippingGateway: defaultBarChartValues,
      },
      summary: {
        revenueByFreeShipping: defaultTotals,
        revenueByShippingGateway: defaultTotals,
        salesByFreeShipping: defaultTotals,
        salesByShippingGateway: defaultTotals,
      },
    },
    status: 'idle',
  },
  shippingRankByProvinceBySales: {
    status: 'idle',
    data: null,
  },
  shippingRevenueByProvince: {
    status: 'idle',
    data: null,
  },
  rankProvincesByShippingCost: {
    status: 'idle',
    data: null,
  },
  rankProvincesByShippingTime: {
    status: 'idle',
    data: null,
  },
  storeHasGA: {
    status: 'idle',
    data: false,
  },
};
