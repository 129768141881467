import { useCallback, useEffect } from 'react';
import { InteractiveList } from '@nimbus-ds/patterns';
import { AiFeature } from '@tiendanube/common/src/enums';
import { useToast } from 'commons/hooks';
import { useAiUsage } from 'domains/Ai/hooks';
import useChangeImageBackground from 'domains/Ai/hooks/useChangeImageBackground';
import {
  trackingAiEditImageReplaceBgWithImageCtaClick,
  trackingAiEditImageReplaceBgWithColorCtaClick,
  trackingAiEditImageRemoveBgUseClick,
} from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditPhotoBaseModal from '../../../EditImageBaseModal';
import { EditedImage } from '../../../EditImageModal';
import BackgroudRemoveUncertaintyAlert from '../Alerts/BackgroudRemoveUncertaintyAlert';
import ChangeBackgroundColorModal from '../ChangeBackgroundColorModal';
import ChangeBackgroundImageModal from '../ChangeBackgroundImageModal';
import ModalInteractiveListAction from '../ModalInteractiveListAction';

interface RemoveBackgroundModalProps {
  currentImage: ImageGalleryState;
  onSaveImage: (editedImage: EditedImage) => void;
  isShow?: boolean;
  onClose?: () => void;
}

function RemoveBackgroundModal({
  currentImage,
  onSaveImage,
  isShow = false,
  onClose,
}: Readonly<RemoveBackgroundModalProps>) {
  const { addToast } = useToast();
  const t = useTranslationCatalog('products.modal.editPhoto');

  const {
    errorOnBackgroundChange,
    unSavedChanges,
    displayedImage,
    handleBackgroundColorSelected,
    changingBackground,
    editedImageUncertain,
  } = useChangeImageBackground(currentImage);

  const handleOnSubmit = () => {
    if (unSavedChanges && displayedImage.base64) {
      onSaveImage({ base64: displayedImage.base64 });
      trackingAiEditImageRemoveBgUseClick();
    }
    if (onClose) {
      onClose();
    }
  };

  const { isLoadingAiUses, requiredTier, refetchAiUses } = useAiUsage();

  const handleRemoveBackgroundOnShow = useCallback(async () => {
    await handleBackgroundColorSelected('none');
    refetchAiUses();
  }, [handleBackgroundColorSelected, refetchAiUses]);

  useEffect(() => {
    if (isShow) {
      handleRemoveBackgroundOnShow();
    }
  }, [
    isShow,
    handleBackgroundColorSelected,
    refetchAiUses,
    handleRemoveBackgroundOnShow,
  ]);

  const onSaveWithBackgroundChanged = (editedImage: EditedImage) => {
    onSaveImage(editedImage);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (errorOnBackgroundChange) {
      addToast({
        appearance: 'danger',
        label: t('toasts.error.label'),
      });
    }
  }, [errorOnBackgroundChange, addToast, t]);

  return (
    <EditPhotoBaseModal
      aiFeatureTrackingSource={AiFeature.AI_IMAGES_REMOVE_BG}
      isShow={isShow}
      loadingImage={changingBackground}
      displayImageSource={displayedImage}
      onClose={onClose}
      translationPrefix="products.modal.editPhoto.actions.removeBackground.modal"
      beforeImageChildren={
        editedImageUncertain ? <BackgroudRemoveUncertaintyAlert /> : undefined
      }
      afterImageChildren={
        !changingBackground &&
        !errorOnBackgroundChange &&
        !isLoadingAiUses && (
          <InteractiveList>
            <ModalInteractiveListAction
              cta={t('actions.changeBackgroundColor.cta')}
              onOpen={() =>
                trackingAiEditImageReplaceBgWithColorCtaClick({
                  fromRemoveBackground: true,
                })
              }
              aIPoweredAction
              isBeta
              upSellProps={{
                trackingSource: AiFeature.AI_IMAGES_REPLACE_BG_COLOR,
                requiredTier,
              }}
              modalChildren={
                <ChangeBackgroundColorModal
                  currentBackgroudColor="none"
                  currentImage={displayedImage}
                  onSaveImage={onSaveWithBackgroundChanged}
                />
              }
            />
            <ModalInteractiveListAction
              cta={t('actions.changeBackgroundImage.cta')}
              aIPoweredAction
              isBeta
              upSellProps={{
                trackingSource: AiFeature.AI_IMAGES_REPLACE_BG_IMAGE,
                requiredTier,
              }}
              onOpen={() =>
                trackingAiEditImageReplaceBgWithImageCtaClick({
                  fromRemoveBackground: true,
                })
              }
              modalChildren={
                <ChangeBackgroundImageModal
                  currentImage={displayedImage}
                  onSaveImage={onSaveImage}
                />
              }
            />
          </InteractiveList>
        )
      }
      handleOnSave={handleOnSubmit}
      showAiUsage
      enableSaveButton={
        unSavedChanges && !changingBackground && !errorOnBackgroundChange
      }
      fullWidthChild
    />
  );
}

export default RemoveBackgroundModal;
