import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import SuccessCard from './components/SuccessCard';

function RequestOcaRegistrationSuccessPage(): JSX.Element {
  const { goBack } = useNavegate();
  const t = useTranslationShipping();

  const backNavigation = {
    children: t('oca.requestRegistrationSuccess.headerBack'),
    onClick: goBack,
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent
          title={t('oca.requestRegistrationSuccess.headerTitle')}
        />
      }
    >
      <SuccessCard />
    </IonPageStratus>
  );
}

export default RequestOcaRegistrationSuccessPage;
