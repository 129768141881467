import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  getBillingCitiesData,
  getBillingCitiesStatus,
  fetchBillingCities as fetchActionCities,
} from 'domains/Billing/Invoices/invoicesSlice';

function useGetBillingCities() {
  const countryCode = useGetCountry();
  const dispatch = useAppDispatch();
  const { isLoading, isError } = useSelector(getBillingCitiesStatus);

  const cities = useSelector((state: RootStateType) =>
    getBillingCitiesData(state),
  );

  const fetchCities = useCallback(() => {
    dispatch(fetchActionCities(countryCode));
  }, [dispatch, countryCode]);

  return {
    isLoading,
    isError,
    cities,
    fetchCities,
  };
}

export default useGetBillingCities;
