import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  setJobAction,
  removeJobAction,
  getJobsAction,
  cleanJobCsvDownload,
  setJobStatusAction,
  createGetBackgroundJobByIdSelector,
  checkJobStatusAction,
} from '../../jobsSlice';

const TYPE_JOB = 'backgroundJobs';

function useBackgroundJobs(id = '', csvDownload = false) {
  const dispatch = useAppDispatch();
  const getBackgroundJobById = useMemo(createGetBackgroundJobByIdSelector, []);
  const job = useSelector((state: RootStateType) =>
    getBackgroundJobById(state, id),
  );

  const getJobs = useCallback(async () => {
    dispatch(getJobsAction(TYPE_JOB));
  }, [dispatch]);

  const checkJob = useCallback(async () => {
    if (
      job?.status === 'ready' ||
      job?.status === 'downloaded' ||
      job?.status === 'failed'
    ) {
      return job.status;
    } else if (job?.token) {
      const result = unwrapResult(
        await dispatch(
          checkJobStatusAction({ id, type: TYPE_JOB, csvDownload }),
        ),
      );
      return result[id]?.status;
    }
    return null;
  }, [csvDownload, dispatch, id, job?.token, job?.status]);

  const jobStatus = job?.status || 'idle';

  const csvDownloadLinkUrl = job?.url || '';

  const setJob = useCallback(
    async (token: string) => {
      dispatch(
        setJobAction({ id, type: TYPE_JOB, token, ttl: 0, status: 'pending' }),
      );
    },
    [dispatch, id],
  );

  const removeJob = useCallback(async () => {
    dispatch(removeJobAction({ id, type: TYPE_JOB }));
  }, [dispatch, id]);

  const setFailedJob = useCallback(async () => {
    dispatch(
      setJobStatusAction({
        id,
        type: TYPE_JOB,
        status: 'failed',
      }),
    );
  }, [dispatch, id]);

  const setDownloadedJob = useCallback(async () => {
    dispatch(
      setJobStatusAction({
        id,
        type: TYPE_JOB,
        status: 'downloaded',
      }),
    );
  }, [dispatch, id]);

  const cleanJob = useCallback(async () => {
    dispatch(cleanJobCsvDownload());
    removeJob();
  }, [dispatch, removeJob]);

  return {
    csvDownloadLinkUrl,
    jobStatus,
    checkJob,
    getJobs,
    setJob,
    removeJob,
    setFailedJob,
    setDownloadedJob,
    cleanJob,
  };
}

export default useBackgroundJobs;
