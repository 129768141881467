import { Text, Title } from '@nimbus-ds/components';
import { CardCollapsible, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { Active, Quantity, Dates, ValueCart, Client } from './';

export function LimitsSkeleton() {
  const t = useTranslationMarketing('coupons.couponForm.limits');

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h4">{t('title')}</Title>
          <Text>{t('optional')}</Text>
        </Stack>
      }
      active
    >
      <Stack column align="stretch">
        <Text>{t('info')}</Text>
        <Active.Skeleton title={t('active.title')} />
        <Quantity.Skeleton title={t('quantity.title')} />
        <Client.Skeleton title={t('client.title')} />
        <Dates.Skeleton title={t('date.title')} />
        <ValueCart.Skeleton />
      </Stack>
    </CardCollapsible>
  );
}
