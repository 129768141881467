import { InteractiveListPriv } from '@tiendanube/components';

function VirtualInteractiveListSkeleton(): JSX.Element {
  const optionsSkeleton = [...Array(7)].map(() => ({
    name: '',
    title: '',
  }));

  return (
    <InteractiveListPriv>
      {optionsSkeleton.map((option, i) => (
        <InteractiveListPriv.CheckItem
          key={i}
          name={option.name}
          title={option.title}
          skeleton
          checked={false}
          onChange={() => null}
        />
      ))}
    </InteractiveListPriv>
  );
}

export default VirtualInteractiveListSkeleton;
