import { Box, IconButton, Table, Tag, Text } from '@nimbus-ds/components';
import { Repeater, Stack } from 'commons/components';
import UsersListDesktopHeader from '../UsersListDesktopHeader';

function UsersListDesktopItemSkeleton(index) {
  return (
    <Table.Row key={index}>
      <Table.Cell>
        <Text.Skeleton width="180px" />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton width="180px" />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton width="100px" />
      </Table.Cell>
      <Table.Cell>
        <Tag.Skeleton width="80px" height="1rem" />
      </Table.Cell>
      <Table.Cell>
        <Box width="fit-content">
          <Stack spacing="tight">
            <IconButton.Skeleton height="2rem" width="2rem" />
            <IconButton.Skeleton height="2rem" width="2rem" />
          </Stack>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}

function UsersListDesktopSkeleton() {
  return (
    <Box flexDirection="column" gap="2" display="flex">
      <div className="stratus--users-list-page-table">
        <Table>
          <UsersListDesktopHeader />
          <Table.Body>
            <Repeater times={10} item={UsersListDesktopItemSkeleton} />
          </Table.Body>
        </Table>
      </div>
    </Box>
  );
}

export default UsersListDesktopSkeleton;
