import { DataList, Text, Link, Label } from '@tiendanube/components';

const { Row, Cell } = DataList;

function OrderListItemSkeleton(): JSX.Element {
  return (
    <Row id="skeleton-row">
      <Cell width={60} rowTitle>
        <Link.Skeleton />
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton width="small" />
      </Cell>
      <Cell width={60} trimText>
        <Text.Skeleton width="large" />
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={100}>
        <Text.Skeleton />
      </Cell>
      <Cell width="auto" extraPadding>
        <Label.Skeleton />
      </Cell>
      <Cell width="auto" extraPadding>
        <Label.Skeleton />
      </Cell>
    </Row>
  );
}

export default OrderListItemSkeleton;
