import {
  CreateContentDeclarationRequestDto,
  CreateLabelRequestDto,
  CreateReceiptRequestDto,
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelCostPreviewShippingOrderRequestDto,
  OcaLabelCostPreviewResponseDto,
  OcaLabelsSettingOptionsResponseDto,
  OrderDetailsForOcaLabelResponseDto,
  UpdateDocumentSettingsRequestDto,
  OcaCreateLabelRequestDto,
  OcaLabelResponseDto,
  OcaCreateLabelResponseDto,
  OcaLabelsGenerationStatusResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import getUrlWithParams from 'commons/utils/getUrlWithParams';

const getDocumentSettings =
  async (): Promise<GetDocumentSettingsResponseDto> => {
    const { data } = await axios.get('v1/fulfillment-orders/document-settings');
    return data;
  };

const updateDocumentSettings = async (
  payload: UpdateDocumentSettingsRequestDto,
): Promise<void> => {
  await axios.patch('v1/fulfillment-orders/document-settings', payload);
};

const createInformationForLabelDocument = async (
  payload: CreateLabelRequestDto,
): Promise<InformationForDocumentResponseDto[]> => {
  const { data } = await axios.post('v1/fulfillment-orders/labels', payload);
  return data;
};

const createInformationForContentDeclarationDocument = async (
  payload: CreateContentDeclarationRequestDto,
): Promise<InformationForContentDeclarationResponseDto[]> => {
  const { data } = await axios.post(
    'v1/fulfillment-orders/content-declaration',
    payload,
  );
  return data;
};

const createInformationForReceiptDocument = async (
  payload: CreateReceiptRequestDto,
): Promise<InformationForDocumentResponseDto[]> => {
  const { data } = await axios.post('v1/fulfillment-orders/receipts', payload);
  return data;
};

const createLabelDocumentDownloadLink = async (
  payload: CreateLabelRequestDto,
): Promise<string> => {
  const { data } = await axios.post(
    'v1/fulfillment-orders/labels/download',
    payload,
  );
  return data;
};

const createContentDeclarationDownloadLink = async (
  payload: CreateContentDeclarationRequestDto,
): Promise<string> => {
  const { data } = await axios.post(
    'v1/fulfillment-orders/content-declaration/download',
    payload,
  );
  return data;
};

const createReceiptDownloadLink = async (
  payload: CreateReceiptRequestDto,
): Promise<string> => {
  const { data } = await axios.post(
    'v1/fulfillment-orders/receipts/download',
    payload,
  );
  return data;
};

const getOcaLabelSettingOptions =
  async (): Promise<OcaLabelsSettingOptionsResponseDto> => {
    const { data } = await axios.get(
      'v1/shipping/oca/document-settings-options',
    );
    return data;
  };

const getOrderDetailsForOcaLabel = async (payload: {
  orders: string[];
  isOverwriting: boolean;
}): Promise<OrderDetailsForOcaLabelResponseDto[]> => {
  const { data } = await axios.get(
    getUrlWithParams('v1/shipping/oca/orders-details/:orders/:overwrite', {
      orders: payload.orders.join(','),
      overwrite: `${payload.isOverwriting}`,
    }),
  );
  return data;
};

const generateOcaLabelsCostsPreviews = async (
  payload: OcaLabelCostPreviewShippingOrderRequestDto,
): Promise<OcaLabelCostPreviewResponseDto[]> => {
  const { data } = await axios.post('v1/shipping/oca/costs-previews', payload);
  return data;
};

const createOcaLabels = async (
  payload: OcaCreateLabelRequestDto[],
): Promise<OcaCreateLabelResponseDto> => {
  const { data } = await axios.post('v1/shipping/oca/labels', payload);
  return data;
};

const getOcaLabels = async (
  orders: string[],
): Promise<OcaLabelResponseDto[]> => {
  const { data } = await axios.get(
    getUrlWithParams('v1/shipping/oca/labels/:orders', {
      orders: orders.join(','),
    }),
  );
  return data;
};

const getOcaLabelsGenerationStatus = async (
  token: string,
): Promise<OcaLabelsGenerationStatusResponseDto> => {
  const { data } = await axios.get(
    getUrlWithParams('v1/shipping/oca/labels/status/:token', {
      token,
    }),
  );
  return data;
};

export default {
  getDocumentSettings,
  getOcaLabelSettingOptions,
  getOrderDetailsForOcaLabel,
  generateOcaLabelsCostsPreviews,
  createOcaLabels,
  getOcaLabelsGenerationStatus,
  getOcaLabels,
  updateDocumentSettings,
  createInformationForLabelDocument,
  createInformationForContentDeclarationDocument,
  createInformationForReceiptDocument,
  createLabelDocumentDownloadLink,
  createContentDeclarationDownloadLink,
  createReceiptDownloadLink,
};
