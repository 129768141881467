import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SectionCodesResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import {
  getProductDetailsFetchStatus,
  createGetProductSectionsCodeByProductId,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useProductSections } from 'domains/Online';

interface useHighlightProductProps {
  sectionCodes: SectionCodesResponseDto;
  isEdit: boolean;
  productId?: string;
}

function useHighlightProduct({
  sectionCodes,
  isEdit,
  productId,
}: useHighlightProductProps) {
  const { loading } = useSelector(getProductDetailsFetchStatus);
  const getProductSectionsCodeByProductId = useMemo(
    createGetProductSectionsCodeByProductId,
    [],
  );
  const defaultProductSections = useSelector((state: RootStateType) => {
    if (productId) return getProductSectionsCodeByProductId(state, productId);
    return [];
  });

  const {
    productSections,
    status: productSectionsStatus,
    fetchProductSections,
  } = useProductSections();

  useEffect(fetchProductSections, [fetchProductSections]);

  const loadingProductDetail = isEdit ? loading : false;
  const showSkeleton =
    productSectionsStatus === 'loading' || loadingProductDetail;

  const getSectionCodesError = sectionCodes.includes('get-error');
  const showError = productSectionsStatus === 'error' || getSectionCodesError;

  const handleReTry = !getSectionCodesError ? fetchProductSections : undefined;

  return {
    showSkeleton,
    productSections: productSections || [],
    showError,
    handleReTry,
    defaultProductSections,
  };
}

export default useHighlightProduct;
