import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Radio, Text, Checkbox } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { InteractiveList, InterfaceNameValue } from '@tiendanube/components';
import { CancelAndSaveButtons, InputNumber, Stack } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import { StockChange } from 'domains/Catalog/Inventory/inventoryServices';
import { useInventoryItem } from 'domains/Catalog/Inventory/pages/hooks';
import { useEditVariants } from 'domains/Catalog/Products/pages/hooks';
import { trackingProductStockCalculatorChange } from 'domains/Catalog/Products/tracking';
import { OptionEditType } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import ProductVariant from '../../ProductVariant';

import './EditStock.scss';

export interface EditStockProps {
  productId: string;
  variantId: string;
  currentStock: string | null;
  isVariantStock?: boolean;
  variantName?: string;
  displayStockTypes?: boolean;
  displayStockTypesAs?: 'radio' | 'list';
  displayApplyToAll?: boolean;
  locationId?: string;
  locationName?: string;
  showHeader?: boolean;
  onClose: (stockChange?: StockChange) => void;
}

const MAX_CAUSE_LENGTH = 40;

function EditStock({
  productId,
  variantId,
  currentStock,
  isVariantStock,
  variantName,
  displayStockTypes = isVariantStock,
  displayStockTypesAs = 'list',
  displayApplyToAll = isVariantStock,
  locationId,
  showHeader = true,
  onClose,
}: Readonly<EditStockProps>): JSX.Element {
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const { editStock } = useEditVariants();
  const [isInfinite, setIsInfinite] = useState(true);
  const [editOption, setEditOption] = useState<OptionEditType>(
    currentStock === null ? 'replace' : 'add',
  );

  const { product } = useInventoryItem(productId);
  const [value, setValue] = useState('');
  const [finalValue, setFinalValue] = useState<string | null>();
  const [applyToAll, setApplyToAll] = useState(false);
  const [cause, setCause] = useState('');

  const [handleConfirmStock, isLoading] = useAsyncFunc(
    async () => {
      const variationValue =
        editOption === 'discount' ? Number(value) * -1 : Number(value);
      const { response } = await editStock({
        productId,
        action: editOption === 'replace' ? 'replace' : 'variation',
        value: !isInfinite ? variationValue : null,
        variantId: isVariantStock && !applyToAll ? variantId : undefined,
        locationId,
        cause,
      });
      const hasCause = !!cause;
      trackingProductStockCalculatorChange(
        editOption,
        applyToAll,
        isInfinite,
        hasCause,
      );
      setValue('');
      setFinalValue(`${response[0].newStock}`);
      setApplyToAll(false);
      response.forEach((newStock) => {
        onClose({
          productId: productId,
          variantId: newStock.variantId,
          locationId: locationId,
          stock: newStock.newStock,
        });
      });
    },
    () => {
      addToast({
        appearance: 'success',
        label: t('products.detail.editStock.success'),
      });
    },
    () => {
      addToast({
        appearance: 'danger',
        label: t('products.detail.editStock.error'),
      });
    },
  );
  useEffect(() => {
    setIsInfinite(currentStock === null);
  }, [currentStock]);

  useEffect(() => {
    if (isInfinite) {
      if (displayStockTypes) setEditOption('replace');
      setFinalValue(null);
    } else {
      currentStock !== null && setEditOption('add');
      setFinalValue(currentStock);
    }
    setApplyToAll(false);
    setValue('');
  }, [currentStock, isInfinite, displayStockTypes]);

  const isInfiniteOptions = [
    {
      title: t('products.detail.infinite'),
      name: 'infinite',
      active: isInfinite,
    },
    {
      title: t('products.detail.limited'),
      name: 'limited',
      active: !isInfinite,
    },
  ];

  const editOptions = [
    {
      label: t('products.detail.editStock.add'),
      value: 'add',
    },
    {
      label: t('products.detail.editStock.discount'),
      value: 'discount',
    },
    {
      label: t('products.detail.editStock.replace'),
      value: 'replace',
    },
  ];

  const editOptionMap = {
    add: {
      applyToAllLabel: t('products.detail.editStock.applyToAllAdd'),
      operation: '+',
    },
    discount: {
      applyToAllLabel: t('products.detail.editStock.applyToAllDiscount'),
      operation: '-',
    },
    replace: {
      applyToAllLabel: t('products.detail.editStock.applyToAllReplace'),
      operation: '',
    },
  };

  const { applyToAllLabel, operation } = editOptionMap[editOption];
  const currentStockNumber = currentStock || '0';
  const variation = variantName
    ? t('products.stockHistoryModal.variant', {
        variation: variantName,
      })
    : undefined;

  const isModified = finalValue !== currentStock;

  const handleChangeInfinite = () => setIsInfinite(!isInfinite);
  const handleChangeEditOption = (option) => setEditOption(option);
  const handleChangeCause = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCause(e.target.value);
  };
  const handleChange = (e: InterfaceNameValue) => {
    setValue(e.value);
    if (editOption === 'add')
      setFinalValue(`${Number(currentStockNumber) + Number(e.value)}`);
    else if (editOption === 'discount') {
      const result = Number(currentStockNumber) - Number(e.value);
      setFinalValue(result >= 0 ? `${result}` : '0');
    } else setFinalValue(e.value);
  };
  const handleClose = () => {
    setValue('');
    setFinalValue(currentStock);
    setApplyToAll(false);
    onClose();
  };

  return (
    <>
      {showHeader && product && (
        <ProductVariant product={product} variant={variation} />
      )}
      <Stack column align="stretch" spacing="loose">
        {displayStockTypes && (
          <>
            {displayStockTypesAs === 'list' && (
              <InteractiveList
                onChange={handleChangeInfinite}
                mode="single"
                options={isInfiniteOptions}
              />
            )}
            {displayStockTypesAs === 'radio' && (
              <Box
                display="flex"
                flexDirection="row"
                gap="2"
                borderBottomWidth="1"
                borderStyle="solid"
                borderColor="neutral-surfaceHighlight"
                paddingBottom="3"
              >
                {isInfiniteOptions.map(({ name, title, active }) => (
                  <Radio
                    key={name}
                    label={title}
                    name={`stock-type-${name}`}
                    value={name}
                    as="radio"
                    onChange={() => {
                      setIsInfinite(name === 'infinite');
                    }}
                    checked={active}
                  />
                ))}
              </Box>
            )}
          </>
        )}
        {!isInfinite && (
          <Stack column align="stretch" spacing="tight">
            {currentStock === null && (
              <InputNumber
                type="integer"
                label={t('products.detail.quantity')}
                name="stockVariation"
                value={value}
                onChange={handleChange}
              />
            )}
            {currentStock !== null && (
              <>
                <Text fontSize="caption">
                  {t('products.detail.editStock.adjustQuantity')}
                </Text>
                <Box display="flex" gap="2">
                  {editOptions.map(({ label, value }) => (
                    <Radio
                      key={label}
                      label={label}
                      name={`edit-option-${label}`}
                      value={value}
                      as="button"
                      onChange={() => handleChangeEditOption(value)}
                      checked={editOption === value}
                    />
                  ))}
                </Box>
                <Stack align="flex-end">
                  <Stack.Item fill>
                    <InputNumber
                      type="integer"
                      label={t('products.detail.editStock.currentStock')}
                      value={currentStockNumber}
                      name="currentStock"
                      disabled
                      onChange={() => null}
                    />
                  </Stack.Item>
                  <div className="stratus--stock-operation">
                    <Text>{operation}</Text>
                  </div>
                  <Stack.Item fill>
                    <InputNumber
                      type="integer"
                      label={t(`products.detail.editStock.${editOption}`)}
                      value={value}
                      name="stockVariation"
                      onChange={handleChange}
                    />
                  </Stack.Item>
                  <div className="stratus--stock-operation">
                    <Text>=</Text>
                  </div>
                  <Stack.Item fill>
                    <InputNumber
                      type="integer"
                      label={t('products.detail.editStock.newStock')}
                      appearance={!isModified ? undefined : 'success'}
                      value={finalValue || '0'}
                      name="stock"
                      disabled
                      onChange={() => null}
                    />
                  </Stack.Item>
                </Stack>
              </>
            )}
          </Stack>
        )}
        <FormField.Textarea
          id="cuase"
          helpText={t('products.detail.editStock.cause.helpText', {
            count: cause.length,
            max: MAX_CAUSE_LENGTH,
          })}
          value={cause}
          name="cause"
          label={t('products.detail.editStock.cause.label')}
          placeholder={t('products.detail.editStock.cause.placeHolder')}
          rows={2}
          maxLength={MAX_CAUSE_LENGTH}
          onChange={handleChangeCause}
          showHelpText
        />
        {displayApplyToAll && (
          <Checkbox
            label={applyToAllLabel}
            name="exportCardCheckbox"
            checked={applyToAll}
            onChange={(e) => setApplyToAll(e.target.checked)}
          />
        )}
      </Stack>

      <CancelAndSaveButtons
        saveText={t('products.detail.editStock.save')}
        cancelText={t('products.detail.editStock.cancel')}
        onCancel={handleClose}
        onSave={handleConfirmStock}
        isDisabled={!isModified}
        isLoading={isLoading}
      />
    </>
  );
}

export default EditStock;
