import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { ProvideAiFeedbackRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { provideAiFeedback } from '../../../domains/Dashboard/slices/aiFeedbackSlice';

function useProvideAiFeedback() {
  const dispatch = useAppDispatch();

  const sendAiGenerationFeedback = useCallback(
    async (requestDto: ProvideAiFeedbackRequestDto) => {
      const result = await dispatch(provideAiFeedback(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { sendAiGenerationFeedback };
}

export default useProvideAiFeedback;
