import { Menu } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import HelpMenuContent from './components/HelpMenuContent';

interface AccountMenuProps {
  visible: boolean;
  onClose: () => void;
}

function HelpMenu({ visible, onClose }: Readonly<AccountMenuProps>) {
  return (
    <div
      className={classNames('stratus--menu-container', 'stratus--submenu', {
        visible,
      })}
    >
      <Menu>
        <HelpMenuContent onClose={onClose} />
      </Menu>
    </div>
  );
}

export default HelpMenu;
