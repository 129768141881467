import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceAdressesSchema } from '../types';

export const getAddressesState = (
  state: RootStateType,
): CombinedState<InterfaceAdressesSchema> => state.shipping.adresses;

export const getCountries = (state: RootStateType) =>
  getAddressesState(state).countries.data || {};

export const getCountriesArray = (state: RootStateType) => {
  const countries = getCountries(state);
  return Object.keys(countries).map((k) => ({
    label: countries[k],
    value: k,
  }));
};

export const getEntityAddresses = (state: RootStateType) =>
  getAddressesState(state).addressesBrasil;

export const getAddressesData = (state: RootStateType) =>
  getEntityAddresses(state).data;

export const getAddressesStatus = (state: RootStateType) => {
  const status = getEntityAddresses(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    notFound: status === 'not-found',
  };
};

export const getProvinces = (state: RootStateType) =>
  getAddressesState(state).provinces;
export const getProvincesData = (state: RootStateType) =>
  getProvinces(state).data;

export const getProvincesStatus = (state: RootStateType) => {
  const status = getProvinces(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const getCities = (state: RootStateType) =>
  getAddressesState(state).cities;
export const getCitiesData = (state: RootStateType, provinceCode: string) =>
  getCities(state).data[provinceCode] || [];

export const getCitiesStatus = (state: RootStateType) => {
  const status = getCities(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};
