import { Table } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { PromotionsListDesktopRow } from './PromotionsListDesktopRow';
import { PromotionsListHeader } from './PromotionsListHeader';

interface PromotionsListTableProps {
  data: PromotionsItemListResponseDto[];
}

export function PromotionsListTable({
  data,
}: Readonly<PromotionsListTableProps>) {
  return (
    <Table>
      <PromotionsListHeader />
      <Table.Body>
        {data.map((promotion) => (
          <PromotionsListDesktopRow promotion={promotion} key={promotion.id} />
        ))}
      </Table.Body>
    </Table>
  );
}
