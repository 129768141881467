import { Box, Text, Title } from '@nimbus-ds/components';

interface SkeletonProps {
  children: React.ReactNode;
}

export function Skeleton({ children }: Readonly<SkeletonProps>) {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box display="flex" flexDirection="column" gap="2">
        <Title.Skeleton height="16px" />
        <Text.Skeleton />
      </Box>
      {children}
    </Box>
  );
}
