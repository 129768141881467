import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getEditOrderShippingCosts,
  getEditOrderShippingCostsStatus,
} from 'domains/Orders/Orders/ordersSlice';

export function useShippingCosts(fulfillmentOrderId: string | undefined) {
  const shippingCosts = useSelector(getEditOrderShippingCosts);
  const status = useSelector(getEditOrderShippingCostsStatus);

  const fulfillmentShippingCosts = useMemo(
    () =>
      shippingCosts.find(
        (sc) => sc.fulfillmentOrderId === (fulfillmentOrderId ?? null),
      ),
    [shippingCosts, fulfillmentOrderId],
  );
  const fulfillmentStatus = useMemo(
    () =>
      status.find((s) => s.ffooId === (fulfillmentOrderId ?? ''))?.status ??
      'idle',
    [status, fulfillmentOrderId],
  );
  const isLoading = useMemo(
    () => fulfillmentStatus === 'loading',
    [fulfillmentStatus],
  );
  const isError = useMemo(
    () => fulfillmentStatus === 'error',
    [fulfillmentStatus],
  );
  const isLoadingAny = useMemo(
    () => status.some((s) => s.status === 'loading'),
    [status],
  );
  const isErrorAny = useMemo(
    () => status.some((s) => s.status === 'error'),
    [status],
  );

  return {
    shippingCosts: fulfillmentShippingCosts,
    shippingCostsStatus: fulfillmentStatus,
    isLoading,
    isError,
    isErrorAny,
    isLoadingAny,
    allFulfillmentsShippingCosts: shippingCosts,
    allFulfillmentsShippingCostsStatus: status,
  };
}
