import { Route, Switch } from 'react-router-dom';
import { HideNavTabs } from 'App/components/Navtabs';
import { CATALOG_ROUTES } from './catalogRoutes';
import { CategoriesListPageNew } from './Categories/pages/CategoriesListPage';
import EditCategoryPage from './Categories/pages/EditCategoryPage';

function Categories(): JSX.Element {
  return (
    <Switch>
      <Route path={CATALOG_ROUTES.categoriesList} exact>
        <CategoriesListPageNew />
      </Route>
      <Route path={CATALOG_ROUTES.editCategory}>
        <HideNavTabs />
        <EditCategoryPage />
      </Route>
    </Switch>
  );
}

export default Categories;
