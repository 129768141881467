import { toSnakeCase } from 'commons/utils';
import {
  fetchProductsPayloadInterface,
  ProductsFiltersInterface,
} from './types';

const buildParamsUrlWithSnakeCase = (
  object: Partial<fetchProductsPayloadInterface>,
) => {
  const toSnakeParams = Object.entries(object).map(([key, value]) => [
    toSnakeCase(key),
    value,
  ]);
  return Object.fromEntries(toSnakeParams);
};

const metafieldDigest = (
  filtersParams: ProductsFiltersInterface,
): Partial<fetchProductsPayloadInterface> => {
  let othersFilters: Partial<fetchProductsPayloadInterface> = {};
  let metafieldsFilters: Record<string, string> = {};
  Object.entries(filtersParams).forEach(([key, value]) => {
    if (key.startsWith('mf-') && value !== '') {
      metafieldsFilters = {
        ...metafieldsFilters,
        [key.split('mf-')[1]]: `${value}`,
      };
      return;
    }
    if (value !== '' || (key === 'sortBy' && value === '')) {
      othersFilters = { ...othersFilters, [key]: value };
    }
  });

  const metafieldsFiltersArray = Object.entries(metafieldsFilters);

  return {
    ...othersFilters,
    metafields: metafieldsFiltersArray.length
      ? metafieldsFiltersArray
      : undefined,
  };
};

export function getProductAdvancedDigest(filters: ProductsFiltersInterface) {
  return buildParamsUrlWithSnakeCase(metafieldDigest(filters));
}

export function sanitizateFilters(filters: ProductsFiltersInterface) {
  return Object.keys(filters).reduce((acc, key) => {
    if (filters[key] !== '' || key === 'sortBy') {
      acc[key] = filters[key];
    }
    return acc;
  }, {});
}
