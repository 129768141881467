import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

export const getSummaryEntity = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.summary,
);

export const getSummaryData = createSimpleSelector(
  [getSummaryEntity],
  (globalInfoEntity) => globalInfoEntity.data,
);

export const getSummaryStatus = createSimpleSelector(
  [getSummaryEntity],
  (globalInfoEntity) => convertStatusTypeToObject(globalInfoEntity.status),
);
