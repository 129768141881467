import { Checkbox, Grid, Input, Modal } from '@tiendanube/components';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { useAutoFocus, useToastStatus } from 'commons/hooks';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import useSendEmailModal from './useSendEmailModal';

interface SendEmailModalProps {
  email: string;
  customerId: string;
  closeSendEmailModal: () => void;
}

function SendEmailModal({
  email,
  customerId,
  closeSendEmailModal,
}: SendEmailModalProps): JSX.Element {
  const t = useTranslationCustomers();
  const { ref } = useAutoFocus();
  const { setFieldValue, handleOnSubmit, values, errors, status } =
    useSendEmailModal(closeSendEmailModal, email, customerId);

  const handleOnChange = ({ name, value }) => {
    setFieldValue(name, value);
  };

  const handleOnChangeCheckbox = ({ name, checked }) => {
    setFieldValue(name, checked);
  };

  useToastStatus({
    status,
    error: t('sendEmailModal.errorMessage'),
    success: t('sendEmailModal.successMessage'),
  });

  return (
    <Modal
      title={t('sendEmailModal.title')}
      onDismiss={closeSendEmailModal}
      show
    >
      <Stack column align="stretch" spacing="loose" innerRef={ref}>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={6} md={3} lg={3}>
                <Input
                  name="forEmail"
                  label={t('sendEmailModal.forEmail')}
                  value={values.forEmail}
                  disabled
                />
              </Grid.Column>
              <Grid.Column sm={6} md={3} lg={3}>
                <Input
                  name="fromEmail"
                  label={t('sendEmailModal.fromEmail')}
                  value={values.fromEmail}
                  disabled
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>

        <Input
          name="subject"
          label={t('sendEmailModal.subject')}
          value={values.subject}
          onChange={handleOnChange}
          helpText={errors.subject ? t(errors.subject) : undefined}
          appearance={errors.subject ? 'validation_error' : undefined}
        />

        <Input
          name="content"
          label={t('sendEmailModal.content')}
          rows={4}
          multiRows
          value={values.content}
          onChange={handleOnChange}
          helpText={errors.content ? t(errors.content) : undefined}
          appearance={errors.content ? 'validation_error' : undefined}
        />

        <Checkbox
          name="sendCopy"
          checked={values.sendCopy}
          label={t(`sendEmailModal.sendCopy`, { email: values.fromEmail })}
          onChange={handleOnChangeCheckbox}
        />

        <CancelAndSaveButtons
          onCancel={closeSendEmailModal}
          onSave={handleOnSubmit}
          isLoading={status === 'loading'}
          isDisabled={status === 'loading'}
          cancelText={t('sendEmailModal.cancelButton')}
          saveText={t('sendEmailModal.sendButton')}
          cancelIsDisabled={status === 'loading'}
        />
      </Stack>
    </Modal>
  );
}

export default SendEmailModal;
