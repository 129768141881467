import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Input } from '@tiendanube/components';
import TagAndSeoItemTitle from '../TagAndSeoItemTitle';

export type onChangeBrandType = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => void;

interface BrandProps {
  onChange: onChangeBrandType;
  value: string;
}

function Brand({ onChange, value }: BrandProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return (
    <TagAndSeoItemTitle title={t('products.detail.brand.title')}>
      <Input
        label={t('products.detail.brand.name')}
        placeholder={t('products.detail.brand.placeholder')}
        onChange={onChange}
        value={value}
        name="brand"
      />
    </TagAndSeoItemTitle>
  );
}

export default Brand;
