import { Text, Title } from '@nimbus-ds/components';
import { CardCollapsible, Stack } from 'commons/components';
import { ScopeSelector } from 'domains/Marketing/components/ScopeSelector';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function Skeleton() {
  const t = useTranslationMarketing('coupons.couponForm.applyTo');

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h4">{t('title')}</Title>
          <Text>{t('optional')}</Text>
        </Stack>
      }
      active
    >
      <ScopeSelector.Skeleton />
    </CardCollapsible>
  );
}
