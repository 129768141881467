import {
  CategoryTabedResponseDto,
  ProductsWithoutStockOrganizeEnum,
  SectionProductDto,
} from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { SortBySelectType } from 'domains/Online';
import ProductsBySection from './components/ProductsBySection';
import ProductsWithoutStockCard from './components/ProductsWithoutStockCard';

interface OrganizeProductsProps {
  noStockProductToBottom: ProductsWithoutStockOrganizeEnum;
  categoryId: string;
  categories: CategoryTabedResponseDto[];
  sortBy: SortBySelectType;
  isSaving: boolean;
  products: SectionProductDto[];
  productsStatus: string;
  productsCount: number;
  onEnded: () => void;
  onChange: (data: InterfaceNameValue) => void;
  onChangeProducts: (prodcuts: SectionProductDto[]) => void;
  onSubmit: () => void;
}

function OrganizeProducts({
  noStockProductToBottom,
  categoryId,
  categories,
  sortBy,
  isSaving,
  products,
  productsStatus,
  productsCount,
  onEnded,
  onChange,
  onChangeProducts,
  onSubmit,
}: Readonly<OrganizeProductsProps>) {
  const { goBack } = useNavegate();

  return (
    <Stack column align="stretch">
      <ProductsWithoutStockCard
        noStockProductToBottom={noStockProductToBottom}
        onChange={onChange}
      />
      <ProductsBySection
        categoryId={categoryId}
        categories={categories}
        sortBy={sortBy}
        products={products}
        productsStatus={productsStatus}
        productsCount={productsCount}
        onEnded={onEnded}
        onChange={onChange}
        onChangeProducts={onChangeProducts}
      />
      <CancelAndSaveButtons
        isDisabled={isSaving}
        isLoading={isSaving}
        onSave={onSubmit}
        onCancel={goBack}
      />
      <Stack.Item />
    </Stack>
  );
}

export default OrganizeProducts;
