import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  countryCode: Yup.string().required(
    'oca.requestRegistration.errors.phoneRequired',
  ),
  areaCode: Yup.string().required(
    'oca.requestRegistration.errors.phoneRequired',
  ),
  phoneNumber: Yup.string().required(
    'oca.requestRegistration.errors.phoneRequired',
  ),
  proofOfRegistrationAfip: Yup.mixed().required(
    'oca.requestRegistration.errors.fileRequired',
  ),
  taxStatusForm: Yup.mixed().required(
    'oca.requestRegistration.errors.fileRequired',
  ),
  proofOfRegistrationIIBB: Yup.mixed().required(
    'oca.requestRegistration.errors.fileRequired',
  ),
  ocaRegistrationData: Yup.mixed().required(
    'oca.requestRegistration.errors.fileRequired',
  ),
});

export default validationSchema;
