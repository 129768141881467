import { Card, Icon, Link, Text } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface EditFallbackCardProps {
  readonly cost?: string;
  readonly days?: string;
  readonly handleEdit: () => void;
}

function FallbackInfo({ cost, days, handleEdit }: EditFallbackCardProps) {
  const t = useTranslationShipping();

  return (
    <>
      <Card.Body>
        <Stack column align="stretch">
          <Text fontSize="base" lineHeight="base" textAlign="left">
            {t('deliveryMethods.fallback.subTitle')}
          </Text>
          <Stack column spacing="none" align="stretch">
            <Text>
              {t('deliveryMethods.fallback.cost')}{' '}
              <Text as="span" fontWeight="bold">
                {t('deliveryMethods.fallback.currency')} {cost}
              </Text>
            </Text>
            <Text>
              {t('deliveryMethods.fallback.term')}{' '}
              <Text as="span" fontWeight="bold">
                {days} {t('deliveryMethods.fallback.days')}
              </Text>
            </Text>
          </Stack>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Link appearance="primary" onClick={handleEdit} textDecoration="none">
          <Icon color="primary-interactive" source={<EditIcon />} />
          {t('deliveryMethods.fallback.edit')}
        </Link>
      </Card.Footer>
    </>
  );
}

export default FallbackInfo;
