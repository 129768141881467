import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchRecoveryCodesAlertToShow as fetchRecoveryCodesAlertToShowAction,
  recoveryCodesAlertToShow,
  resetRecoveryCodesAlertToShow,
} from '../../accountSlice';

function useRecoveryCodesAlertToShow() {
  const { data } = useSelector(recoveryCodesAlertToShow);
  const dispatch = useAppDispatch();
  const fetch = useCallback(() => {
    dispatch(fetchRecoveryCodesAlertToShowAction());
  }, [dispatch]);
  const reset = useCallback(() => {
    dispatch(resetRecoveryCodesAlertToShow());
  }, [dispatch]);

  return {
    fetch,
    reset,
    recoveryCodesAlertToShow: data,
  };
}

export default useRecoveryCodesAlertToShow;
