import { useEffect, useState } from 'react';
import { Input, Stack } from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { AddressProps } from '.';
import BrazilianAddressSkeleton from './BrazilianAddressSkeleton';
import useGetAddressesBrazil from './hooks/useGetAddressesBrazil';

function BrazilianAddress({
  values,
  errors,
  setFieldValue,
}: AddressProps): JSX.Element {
  const t = useTranslationBilling();

  const { fetchAddressesBrazil, addressesBrazil, isError, isLoading } =
    useGetAddressesBrazil();

  const [disabled, setDisabled] = useState({
    neighborhood: true,
    street: true,
  });

  useEffect(() => {
    if (addressesBrazil) {
      const { province, city, locality, street } = addressesBrazil;
      setFieldValue('province', province);
      setFieldValue('city', city);
      setFieldValue('neighborhood', locality);
      setFieldValue('street', street);
      setDisabled({
        neighborhood: locality !== null,
        street: street !== null,
      });
    }
  }, [addressesBrazil, setDisabled, setFieldValue]);

  const onChange = ({ name, value }): void => {
    setFieldValue(name, value);
    if (name === 'zipcode') {
      clearFields();
      if (value.length === 8) {
        fetchAddressesBrazil(value);
      }
    }
  };

  const clearFields = () => {
    ['province', 'city', 'neighborhood', 'street'].forEach((field) => {
      setFieldValue(field, '');
      delete errors[field];
    });
    delete errors.zipcode;
    setDisabled({ neighborhood: true, street: true });
  };

  let zipcodeHelpText = '';
  let zipcodeAppearance: 'default' | 'validation_error' | 'validation_loading' =
    'default';

  if (isLoading) {
    zipcodeAppearance = 'validation_loading';
    zipcodeHelpText = '';
  } else if (isError) {
    zipcodeHelpText = t('invoicesInfoPage.address.errors.zipcodeInvalid');
    zipcodeAppearance = 'validation_error';
  } else if (errors.zipcode) {
    zipcodeHelpText = t(errors.zipcode || '');
    zipcodeAppearance = 'validation_error';
  }

  const neighborhoodHelpText = t(errors.neighborhood || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');

  return (
    <Stack column align="stretch">
      <InputNumber
        type="integer"
        label={t('invoicesInfoPage.address.zipcode')}
        onChange={onChange}
        name="zipcode"
        maxLength={8}
        value={values.zipcode}
        helpText={zipcodeHelpText}
        appearance={zipcodeAppearance}
      />

      {isLoading ? (
        <>
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </>
      ) : (
        <>
          <Input
            type="text"
            label={t('invoicesInfoPage.address.province')}
            name="province"
            value={values.province}
            disabled
          />
          <Input
            type="text"
            label={t('invoicesInfoPage.address.city')}
            name="city"
            value={values.city}
            disabled
          />
          <Input
            type="text"
            label={t('invoicesInfoPage.address.neighborhood')}
            onChange={onChange}
            name="neighborhood"
            value={values.neighborhood}
            disabled={disabled.neighborhood}
            helpText={neighborhoodHelpText}
            appearance={neighborhoodHelpText ? 'validation_error' : 'default'}
          />
          <Input
            type="text"
            label={t('invoicesInfoPage.address.street')}
            onChange={onChange}
            name="street"
            value={values.street}
            disabled={disabled.street}
            helpText={streetHelpText}
            appearance={streetHelpText ? 'validation_error' : 'default'}
          />
        </>
      )}

      <Input
        type="text"
        label={t('invoicesInfoPage.address.number')}
        onChange={onChange}
        name="number"
        value={values.number}
        helpText={numberHelpText}
        appearance={numberHelpText ? 'validation_error' : 'default'}
      />
      <Input
        type="text"
        label={t('invoicesInfoPage.address.complement')}
        onChange={onChange}
        name="complement"
        value={values.complement}
      />
    </Stack>
  );
}

BrazilianAddress.Skeleton = BrazilianAddressSkeleton;

export default BrazilianAddress;
