import { Stack, Input } from '@tiendanube/components';

function MexicanAddressSkeleton() {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Stack spacing="tight">
          <Stack.Item fill>
            <Input.Skeleton />
          </Stack.Item>
          <Stack.Item fill>
            <Input.Skeleton />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default MexicanAddressSkeleton;
