import { UserSessionDto } from '@tiendanube/common';
import { useGetUserSessions } from './useGetUserSessions';
import { INACTIVITY_LIMIT_TIME_IN_DAYS } from '../settings';

export function useHasInactiveUserSessions(): boolean {
  const userSessions = useGetUserSessions();

  if (userSessions.length === 0) {
    return false;
  }

  const currentDate = new Date();
  const inactivityDeadline = new Date(currentDate);
  inactivityDeadline.setDate(
    currentDate.getDate() - INACTIVITY_LIMIT_TIME_IN_DAYS,
  );

  return userSessions.some(
    (userSession: UserSessionDto) =>
      new Date(userSession.lastActivity) < inactivityDeadline,
  );
}
