import {
  AuthenticationFactorDashboardStatusResponseDto,
  AuthenticationFactorResponseDto,
  BusinessInfoRequestDto,
  BusinessInfoResponseDto,
  InstallAuthenticationFactorRequestDto,
  InstallAuthenticationFactorResponseDto,
  SocialNetworksRequestDto,
  SocialNetworksResponseDto,
  UnsubscribeReasonsDto,
  UnsubscribeStoreRequestDto,
  UpdateAuthenticationFactorRequestDto,
  UpdateAuthenticationFactorResponseDto,
  ValuesBusinessInfoResponseDto,
  RecoveryCodesResponseDto,
  AuthenticationFactorType,
  RecoveryCodesAlertToShowResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getBusinessData = async (): Promise<BusinessInfoResponseDto> => {
  const { data } = await axios.get(`v1/account/business-info`);
  return data;
};

const updateBusinessData = async (
  businessData: BusinessInfoRequestDto,
): Promise<ValuesBusinessInfoResponseDto> => {
  const { data } = await axios.post(`v1/account/business-info`, businessData);
  return data;
};

const getSocialNetworks = async (): Promise<SocialNetworksResponseDto> => {
  const { data } = await axios.get(`v1/account/social-networks`);
  return data;
};

const updateSocialNetworks = async (
  socialNetworksData: SocialNetworksRequestDto,
): Promise<SocialNetworksResponseDto> => {
  const { data } = await axios.patch(
    `v1/account/social-networks`,
    socialNetworksData,
  );
  return data;
};

const getUnsubscribeReasons = async (): Promise<UnsubscribeReasonsDto[]> => {
  const { data } = await axios.get('/v1/account/unsuscribe-reasons');
  return data;
};

const deleteAccount = (reasons: UnsubscribeStoreRequestDto) =>
  axios.delete('/v1/account/unsubscribe', { data: reasons });

const getAuthenticationFactors = async (): Promise<
  AuthenticationFactorResponseDto[]
> => {
  const response = await axios.get('/v1/account/authentication-factors');
  return response.data;
};

const getRecoveryCodes = async (): Promise<RecoveryCodesResponseDto[]> => {
  const response = await axios.get(
    '/v1/account/authentication-factors/recovery-codes',
  );
  return response.data;
};

const regenerateRecoveryCodes = async (): Promise<
  RecoveryCodesResponseDto[]
> => {
  const response = await axios.post(
    '/v1/account/authentication-factors/recovery-codes/regenerate',
  );
  return response.data;
};

const installAuthenticationFactor = async (
  payload: InstallAuthenticationFactorRequestDto,
): Promise<
  InstallAuthenticationFactorResponseDto<AuthenticationFactorType>
> => {
  const response = await axios.post(
    '/v1/account/authentication-factors',
    payload,
  );
  return response.data;
};

const updateAuthenticationFactor = async (
  payload: UpdateAuthenticationFactorRequestDto,
): Promise<UpdateAuthenticationFactorResponseDto> => {
  const response = await axios.put(
    '/v1/account/authentication-factors',
    payload,
  );
  return response.data;
};

const invalidateSessions = async (): Promise<void> => {
  await axios.post('/admin/auth/invalidate-sessions', undefined, {
    baseURL: window.location.origin,
  });
};

const getAuthenticationFactorsDashboardStatus =
  async (): Promise<AuthenticationFactorDashboardStatusResponseDto> => {
    const response = await axios.get(
      'v1/account/authentication-factors-dashboard-status',
    );
    return response.data;
  };

const getAuthenticationFactorQr = async (content: string): Promise<string> => {
  const response = await axios.get('v1/account/generate-qr-code', {
    params: { content },
  });
  return response.data;
};

const getRecoveryCodesAlertToShow =
  async (): Promise<RecoveryCodesAlertToShowResponseDto> => {
    const response = await axios.get('v1/account/recovery-codes-alert-to-show');
    return response.data;
  };

const accountServices: {
  getBusinessData: () => Promise<BusinessInfoResponseDto>;
  updateBusinessData: (
    businessData: BusinessInfoRequestDto,
  ) => Promise<ValuesBusinessInfoResponseDto>;
  getSocialNetworks: () => Promise<SocialNetworksResponseDto>;
  updateSocialNetworks: (
    socialNetworksData: SocialNetworksRequestDto,
  ) => Promise<SocialNetworksResponseDto>;
  getUnsubscribeReasons: () => Promise<UnsubscribeReasonsDto[]>;
  deleteAccount: (reasons: UnsubscribeStoreRequestDto) => void;
  getAuthenticationFactors: () => Promise<AuthenticationFactorResponseDto[]>;
  getRecoveryCodes: () => Promise<RecoveryCodesResponseDto[]>;
  regenerateRecoveryCodes: () => Promise<RecoveryCodesResponseDto[]>;
  installAuthenticationFactor: (
    payload: InstallAuthenticationFactorRequestDto,
  ) => Promise<
    InstallAuthenticationFactorResponseDto<AuthenticationFactorType>
  >;
  updateAuthenticationFactor: (
    payload: UpdateAuthenticationFactorRequestDto,
  ) => Promise<UpdateAuthenticationFactorResponseDto>;
  invalidateSessions: () => Promise<void>;
  getAuthenticationFactorsDashboardStatus: () => Promise<AuthenticationFactorDashboardStatusResponseDto>;
  getAuthenticationFactorQr: (content: string) => Promise<string>;
  getRecoveryCodesAlertToShow: () => Promise<RecoveryCodesAlertToShowResponseDto>;
} = {
  getBusinessData,
  updateBusinessData,
  getSocialNetworks,
  updateSocialNetworks,
  getUnsubscribeReasons,
  deleteAccount,
  getAuthenticationFactors,
  getRecoveryCodes,
  regenerateRecoveryCodes,
  installAuthenticationFactor,
  updateAuthenticationFactor,
  invalidateSessions,
  getAuthenticationFactorsDashboardStatus,
  getAuthenticationFactorQr,
  getRecoveryCodesAlertToShow,
};

export default accountServices;
