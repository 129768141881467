import { AiFeature, AiVersionsType } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  AI_GOOGLE_SHOPPING_CATEGORIES_USE_CLICK,
  AI_GENERATE_CANCEL_CLICK,
  AI_GENERATE_CONTENT_SELECT,
  AI_GENERATE_CTA_CLICK,
  AI_GENERATE_GENERATE_CLICK,
  AI_GENERATE_REGENERATE_CLICK,
  AI_GENERATE_USE_CONTENT_CLICK,
  AI_EDIT_IMAGE_REMOVE_BACKGROUND_CTA_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_CTA_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_CTA_CLICK,
  AI_EDIT_IMAGE_REMOVE_BACKGROUND_USE_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_USE_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_USE_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_PICK_CLICK,
  AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_PICK_CLICK,
  AI_EDIT_IMAGE_SMART_ERASE_CTA_CLICK,
  AI_EDIT_IMAGE_SMART_ERASE_ERASE_CLICK,
  AI_EDIT_IMAGE_SMART_ERASE_USE_CLICK,
  AI_EDIT_IMAGE_SMART_ERASE_UNDO_CLICK,
} from 'config/trackingEvents';

interface TrackingAiVersionsProps {
  versions?: AiVersionsType;
}

interface TrackingAiFeatureProps extends TrackingAiVersionsProps {
  feature?: AiFeature;
}

interface TrackingAiEditImageFromRemoveBgProps {
  fromRemoveBackground: boolean;
  trackingAiFeatureProps?: TrackingAiFeatureProps;
}

interface TrackingAiEditImageBgWithImageProps {
  selectedImageId: string;
  trackingAiFeatureProps?: TrackingAiFeatureProps;
}

interface TrackingAiEditImageBackgroundColorProps {
  color: string;
  trackingAiFeatureProps?: TrackingAiFeatureProps;
}

const logAiEvent = (
  eventName: string,
  trackingAiProps: TrackingAiFeatureProps,
  eventCustomProps?: Record<string, string>,
): void => {
  logEvent(eventName, {
    ...trackingAiProps.versions,
    ...eventCustomProps,
    ...(trackingAiProps.feature && { feature: trackingAiProps.feature }),
  });
};

// AI GOOGLE SHOPPING CATEGORIES
export const trackingAiGoogleShoppingCategoryUseClick = (
  position: number,
  trackingAiVersionsProps: TrackingAiVersionsProps,
): void => {
  logAiEvent(AI_GOOGLE_SHOPPING_CATEGORIES_USE_CLICK, trackingAiVersionsProps, {
    position: `${position}`,
  });
};

// AI GENERATION FEATURES
export const trackingAiGenerateCtaClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
): void => {
  logAiEvent(AI_GENERATE_CTA_CLICK, trackingAiFeatureProps);
};

export const trackingAiGenerateGenerateClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
): void => {
  logAiEvent(AI_GENERATE_GENERATE_CLICK, trackingAiFeatureProps);
};

export const trackingAiGenerateRegenerateClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
  attemptNumber: number,
  withoutInstruccions: boolean,
): void => {
  logAiEvent(AI_GENERATE_REGENERATE_CLICK, trackingAiFeatureProps, {
    attempt_number: `${attemptNumber}`,
    without_instruccions: withoutInstruccions ? 'true' : 'false',
  });
};

export const trackingAiGenerateCancelClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
): void => {
  logAiEvent(AI_GENERATE_CANCEL_CLICK, trackingAiFeatureProps);
};

export const trackingAiGenerateUseContentClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
): void => {
  logAiEvent(AI_GENERATE_USE_CONTENT_CLICK, trackingAiFeatureProps);
};

export const trackingAiGenerateContentSelect = (
  trackingAiFeatureProps: TrackingAiFeatureProps,
): void => {
  logAiEvent(AI_GENERATE_CONTENT_SELECT, trackingAiFeatureProps);
};

// AI IMAGE FEATURE REMOVE BACKGROUND
export const trackingAiEditImageRemoveBgCtaClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_REMOVE_BACKGROUND_CTA_CLICK, trackingAiFeatureProps);
};

export const trackingAiEditImageRemoveBgUseClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_REMOVE_BACKGROUND_USE_CLICK, trackingAiFeatureProps);
};

// AI IMAGE FEATURE REPLACE BACKGROUND - COLOR
export const trackingAiEditImageReplaceBgWithColorCtaClick = (
  {
    fromRemoveBackground,
    trackingAiFeatureProps,
  }: TrackingAiEditImageFromRemoveBgProps = {
    fromRemoveBackground: false,
  },
): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_CTA_CLICK,
    trackingAiFeatureProps ?? {},
    {
      from_remove_background: fromRemoveBackground.toString(),
    },
  );
};

export const trackingAiEditImageReplaceBgWithColorPickClick = ({
  color,
  trackingAiFeatureProps,
}: TrackingAiEditImageBackgroundColorProps): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_PICK_CLICK,
    trackingAiFeatureProps ?? {},
    {
      color,
    },
  );
};

export const trackingAiEditImageReplaceBgWithColorUseClick = ({
  color,
  trackingAiFeatureProps,
}: TrackingAiEditImageBackgroundColorProps): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_COLOR_USE_CLICK,
    trackingAiFeatureProps ?? {},
    {
      color,
    },
  );
};

// AI IMAGE FEATURE REPLACE BACKGROUND - IMAGE
export const trackingAiEditImageReplaceBgWithImageCtaClick = (
  {
    fromRemoveBackground,
    trackingAiFeatureProps,
  }: TrackingAiEditImageFromRemoveBgProps = {
    fromRemoveBackground: false,
  },
): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_CTA_CLICK,
    trackingAiFeatureProps ?? {},
    {
      from_remove_background: fromRemoveBackground.toString(),
    },
  );
};

export const trackingAiEditImageReplaceBgWithImagePickClick = ({
  selectedImageId,
  trackingAiFeatureProps,
}: TrackingAiEditImageBgWithImageProps): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_PICK_CLICK,
    trackingAiFeatureProps ?? {},
    {
      selected_image_id: selectedImageId,
    },
  );
};

export const trackingAiEditImageReplaceBgWithImageUseClick = ({
  selectedImageId,
  trackingAiFeatureProps,
}: TrackingAiEditImageBgWithImageProps): void => {
  logAiEvent(
    AI_EDIT_IMAGE_REPLACE_BACKGROUND_WITH_IMAGE_USE_CLICK,
    trackingAiFeatureProps ?? {},
    {
      selected_image_id: selectedImageId,
    },
  );
};

// AI IMAGE FEATURE SMART ERASE
export const trackingAiEditImageSmartEraseCtaClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_SMART_ERASE_CTA_CLICK, trackingAiFeatureProps);
};

export const trackingAiEditImageSmartEraseEraseClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_SMART_ERASE_ERASE_CLICK, trackingAiFeatureProps);
};

export const trackingAiEditImageSmartEraseUndoClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_SMART_ERASE_UNDO_CLICK, trackingAiFeatureProps);
};

export const trackingAiEditImageSmartEraseUseClick = (
  trackingAiFeatureProps: TrackingAiFeatureProps = {},
): void => {
  logAiEvent(AI_EDIT_IMAGE_SMART_ERASE_USE_CLICK, trackingAiFeatureProps);
};
