import { Timeline } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';
import { CouponActivityLogDto } from 'domains/Marketing/Coupons/couponHistorySlice';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { mapEventTypeToIconConfig } from './utils';
import { CouponHistoryTimelineItem } from '../CouponHistoryTimelineItem';
import { useParseCouponLogAuthor } from '../hooks/useParseCouponLogAuthor';
import { useParseCouponLogChanges } from '../hooks/useParseCouponLogChanges';

interface CouponHistoryTimelineProps {
  items: CouponActivityLogDto[];
}

export function CouponHistoryTimeline({
  items,
}: Readonly<CouponHistoryTimelineProps>) {
  const { parseCouponLogAuthor } = useParseCouponLogAuthor();
  const { parseCouponLogChanges } = useParseCouponLogChanges();
  const t = useTranslationMarketing('coupons.history');

  const getTimelineItemProps = (event) => {
    const { appearance, icon } = mapEventTypeToIconConfig(event.type);
    const date = dateFormat(event.happenedAt, Format.DD_MM_YY);
    const time = dateFormat(event.happenedAt, Format.HOUR_MINUTE);
    const author = parseCouponLogAuthor(event);
    const changes = parseCouponLogChanges(event);
    return { date, time, author, changes, appearance, icon };
  };

  return (
    <Timeline>
      {items.map((event: CouponActivityLogDto, index) => {
        const { appearance, icon, date, time, author, changes } =
          getTimelineItemProps(event);

        return (
          <CouponHistoryTimelineItem
            appearance={appearance}
            key={event.entity.id + event.createdAt}
            title={t(`actions.${event.type}`)}
            date={date}
            time={time}
            author={author}
            changes={changes}
            icon={icon}
            isLast={items.length > 1 && index === items.length - 1}
          />
        );
      })}
    </Timeline>
  );
}
