import { ProductsWithoutStockOrganizeEnum } from '@tiendanube/common/src/domains/products/enums';
import {
  InteractiveListPriv,
  InterfaceNameValue,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

export interface ProductsWithoutStockCardProps {
  noStockProductToBottom: ProductsWithoutStockOrganizeEnum;
  onChange: (event: InterfaceNameValue) => void;
}

function ProductsWithoutStockCard({
  noStockProductToBottom,
  onChange,
}: ProductsWithoutStockCardProps) {
  const t = useTranslationCatalog();
  const handleOnChange = ({ name }) => {
    onChange({
      name: 'noStockProductToBottom',
      value: name,
    });
  };

  return (
    <CardWithTitle title={t('products.order.productsWithoutStockCard.title')}>
      <InteractiveListPriv>
        <InteractiveListPriv.RadioItem
          value={ProductsWithoutStockOrganizeEnum.STOCK_AT_THE_END}
          checked={
            noStockProductToBottom ===
            ProductsWithoutStockOrganizeEnum.STOCK_AT_THE_END
          }
          onChange={handleOnChange}
          title={t('products.order.productsWithoutStockCard.stockAtTheEnd')}
        />
        <InteractiveListPriv.RadioItem
          value={ProductsWithoutStockOrganizeEnum.DEFAULT_ORDER}
          checked={
            !noStockProductToBottom ||
            noStockProductToBottom ===
              ProductsWithoutStockOrganizeEnum.DEFAULT_ORDER
          }
          onChange={handleOnChange}
          title={t('products.order.productsWithoutStockCard.defaultOrder')}
        />
        <InteractiveListPriv.RadioItem
          value={ProductsWithoutStockOrganizeEnum.STOCK_HIDDEN}
          checked={
            noStockProductToBottom ===
            ProductsWithoutStockOrganizeEnum.STOCK_HIDDEN
          }
          onChange={handleOnChange}
          title={t('products.order.productsWithoutStockCard.stockHidden')}
        />
      </InteractiveListPriv>
    </CardWithTitle>
  );
}

export default ProductsWithoutStockCard;
