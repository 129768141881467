import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { StatusType } from 'commons/types';

export const infinityBehavior = (name: string) => ({
  serializeQueryArgs: () => name,
  merge: (currentCache, newItems, { arg }) => {
    if (new URLSearchParams(arg).get('page') === '1') {
      currentCache.results = newItems.results;
    } else {
      currentCache.results = [...currentCache.results, ...newItems.results];
    }
    currentCache.pagination = newItems.pagination;
  },
  forceRefetch({ currentArg, previousArg }) {
    return currentArg !== previousArg;
  },
});

export const tableBehavior = (name: string) => ({
  serializeQueryArgs: () => name,
  forceRefetch({ currentArg, previousArg }) {
    return currentArg !== previousArg;
  },
});

const statusTypeMapping: Record<QueryStatus, StatusType> = {
  [QueryStatus.uninitialized]: 'idle',
  [QueryStatus.pending]: 'loading',
  [QueryStatus.fulfilled]: 'success',
  [QueryStatus.rejected]: 'error',
};

export const toStatusType = (status: QueryStatus): StatusType =>
  statusTypeMapping[status];

export const mergeStatusType = (statuses: StatusType[]): StatusType => {
  if (statuses.some((status) => status === 'loading')) return 'loading';
  if (statuses.some((status) => status === 'error')) return 'error';
  if (statuses.every((status) => status === 'success')) return 'success';
  return 'idle';
};
