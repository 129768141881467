import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@tiendanube/components';
import useBackPreviousVersion from './useBackPreviousVersion';

function BackPreviousVersionButton(): JSX.Element | null {
  const { t } = useTranslation('common');

  const { hasBackPreviousVersion, goToPreviosVersion } =
    useBackPreviousVersion();

  if (!hasBackPreviousVersion) {
    return null;
  }

  return (
    <Stack.Item>
      <Button onClick={goToPreviosVersion}>
        {`${t('topbar.backToAdmin')}`}
      </Button>
    </Stack.Item>
  );
}

export default BackPreviousVersionButton;
