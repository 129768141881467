import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { PathsType } from '../types';

export function useGetDefaultSettingsOption(): PathsType {
  const isMobile = useIsMobileDevice();
  const { validateRoles } = useRoles();

  const hasShipping = validateRoles('shipping');
  const hasPayments = !isMobile && validateRoles('payments');
  const hasLocations = validateRoles('locations');
  const hasEmails = validateRoles('emails');
  const hasI18n = validateRoles('i18n');
  const hasCheckoutOptions = validateRoles('preferences_checkout');
  const hasExternalCodes = validateRoles('preferences_advanced');
  const hasSeoRedirects = validateRoles('seo_redirects');
  const hasDomains = validateRoles('domains');

  switch (true) {
    case hasShipping:
      return 'shipping';
    case hasLocations:
      return 'locations';
    case hasPayments:
      return 'payments';
    case hasEmails:
      return 'emails';
    case hasI18n:
      return 'i18n';
    case hasCheckoutOptions:
      return 'checkoutOptions';
    case hasExternalCodes:
      return 'externalCodes';
    case hasSeoRedirects:
      return 'redirects301';
    case hasDomains:
      return 'domains';
    default:
      return 'locations';
  }
}
