import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanAddresses,
  fetchAddresses as fetchAddressesAction,
  getAddressesData,
  getAddressesStatus,
} from 'domains/Billing/Invoices/invoicesSlice';

function useGetAddressesMexico() {
  const dispatch = useAppDispatch();
  const addressesMexico = useSelector(getAddressesData);
  const { isError, isLoading } = useSelector(getAddressesStatus);

  const fetchAddressesMexico = useCallback(
    (zipcode) => {
      dispatch(fetchAddressesAction({ zipcode, countryCode: 'MX' }));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      dispatch(cleanAddresses());
    },
    [dispatch],
  );

  return {
    fetchAddressesMexico,
    addressesMexico,
    isError,
    isLoading,
  };
}

export default useGetAddressesMexico;
