import { CategoryTabedResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { CategoriesEndpoints } from './types';
import { CATEGORIES_OPTIONS_URL } from './urls';

export const categoriesApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [CategoriesEndpoints.getCategoriesOptions]: builder.query<
      CategoryTabedResponseDto[],
      void
    >({
      query: (params) => ({
        method: 'GET',
        url: CATEGORIES_OPTIONS_URL,
        params,
      }),
    }),
  }),
});

export const { useGetCategoriesOptionsQuery } = categoriesApi;
