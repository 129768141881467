import { Card, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function CardDeliveryAddressSkeleton(): JSX.Element {
  const t = useTranslationOrders();

  return (
    <Card title={t('cardDeliveryAddress.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text.Skeleton width="large" />
        </Stack.Item>
        <Stack.Item>
          <Stack column spacing="none" align="flex-start">
            <Text.Skeleton size="caption" />
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Text.Skeleton width="large" />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CardDeliveryAddressSkeleton;
