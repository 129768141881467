import { useCallback, useMemo } from 'react';
import { Accordion, Box, Button, Spinner, Text } from '@nimbus-ds/components';
import { PaymentMethodDto } from '@tiendanube/common';
import { InteractiveList } from '@tiendanube/components';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useToastStatus } from 'commons/hooks';
import { TaxAlert } from 'domains/Billing/Checkout/components';
import {
  useFrequencyTranslation,
  usePaymentMethods,
} from 'domains/Billing/Checkout/hooks';
import { useContinuePayment } from 'domains/Billing/Checkout/pages/ChoosePaymentMethodForPlan/hooks';
import { getIconForPaymentMethod } from 'domains/Billing/Checkout/pages/utils';
import {
  trackingBillingSelectPaymentMethodForPlanClick,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PaymentOptionProps {
  paymentMethod: PaymentMethodDto;
}

function PaymentOption({ paymentMethod }: Readonly<PaymentOptionProps>) {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPlan.paymentSelection',
  );

  const { paymentMethodSelected, selectPaymentMethod } = usePaymentMethods();
  const { isShowMenu, showMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu, showMenu: showAsideMenu } =
    useDesktopMenuContext();
  const showMenus = useCallback(() => {
    showMenu();
    showAsideMenu();
  }, [showMenu, showAsideMenu]);
  const { getFrequencyDescriptor } = useFrequencyTranslation();
  const {
    onContinue,
    setupStatus,
    isLoading: continueIsLoading,
    status: continueStatus,
  } = useContinuePayment();

  useToastStatus({
    status: continueStatus,
    error: t('continueError'),
    onSuccess: isShowMenu || isShowAsideMenu ? showMenus : undefined,
  });

  const options = useMemo(
    () =>
      paymentMethod.recurrencyOptions.map((option, index) => {
        const name = `${paymentMethod.method},${index}`;
        return {
          title: `${t('subscription')} ${getFrequencyDescriptor(option)}`,
          name: name,
          labels: option.appliedDiscounts.map((discount) => ({
            id: `recurrencyOption-${index}`,
            appearance: 'success' as const,
            label:
              discount.type === 'store_discount'
                ? t(`discountLabel_${discount.type}`, {
                    percentage: discount.rate,
                  })
                : t('discountLabel', {
                    percentage: discount.rate,
                  }),
          })),
          active: paymentMethodSelected?.nameInList === name,
          originalOption: option,
        };
      }),
    [paymentMethod, paymentMethodSelected, t, getFrequencyDescriptor],
  );

  const handleOnSelect = (selected: string[]) => {
    const originalOption = options.find(
      (option) => option.name === selected[0],
    )?.originalOption;
    if (!originalOption) return;
    selectPaymentMethod({
      nameInList: selected[0],
      method: paymentMethod.method,
      recurrencyOption: originalOption,
    });
  };

  const continueButtonLoading = setupStatus.isLoading || continueIsLoading;

  const logEvent = useCheckoutTracking();
  const handleContinue = useCallback(() => {
    trackingBillingSelectPaymentMethodForPlanClick(
      logEvent,
      paymentMethod.method,
    );
    onContinue();
  }, [onContinue, paymentMethod, logEvent]);

  return (
    <>
      <Accordion.Header
        title={t(`paymentMethods.${paymentMethod.method}`)}
        icon={getIconForPaymentMethod(paymentMethod.method)}
      />
      <Accordion.Body>
        <Box display="flex" flexDirection="column" flexGrow="1" gap="4">
          {paymentMethod.doesRecurrentPayment && (
            <Text>{t('recurrentPayment')}</Text>
          )}
          <InteractiveList
            options={options}
            mode="single"
            onChange={handleOnSelect}
          />

          <TaxAlert />

          <Button
            onClick={handleContinue}
            appearance="primary"
            disabled={continueButtonLoading}
          >
            {t('buttonContinue')}
            {continueButtonLoading && <Spinner size="small" />}
          </Button>
        </Box>
      </Accordion.Body>
    </>
  );
}

export default PaymentOption;
