import { IconButton, Tooltip } from '@nimbus-ds/components';
import { TrashIcon, EditIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';
import { ImageGalleryState } from '../../../../ImageGallery';
import { VIEDO_UPLOAD_THUMBNAIL } from '../../../constants';
import MainImageTag from '../../../MainImageTag';
import { aspectRatioDesktop } from '../../constants';

interface ThumbnailItemProps {
  image: ImageGalleryState;
  alt: string;
  src: string;
  showMainTag: boolean;
  onError: () => void;
  onRemove: (imageId: string) => void;
  onEdit: (imageId: string) => void;
}

function ThumbnailItem({
  image,
  alt,
  showMainTag,
  onEdit,
  onError,
  onRemove,
}: Readonly<ThumbnailItemProps>) {
  const { t } = useTranslation(Domain.CATALOG);

  if (image.isError) {
    return (
      <Thumbnail.Error
        onClick={onError}
        ariaLabel={t('products.detail.errorUploadPhoto')}
        src={image.src}
        altText={alt}
        aspectRatio={aspectRatioDesktop}
      />
    );
  }

  const src = image.mediaType === 'video' ? VIEDO_UPLOAD_THUMBNAIL : image.src;

  return (
    <div className="stratus--image-preview__item">
      <Thumbnail
        loading={image.isLoading}
        src={src}
        altText={alt}
        aspectRatio={aspectRatioDesktop}
      />
      <Tooltip content={t('products.detail.deletePhoto')}>
        <IconButton
          type="button"
          size="2rem"
          source={<TrashIcon />}
          onClick={() => onRemove(image.id)}
        />
      </Tooltip>
      <Tooltip content={t('products.detail.editPhoto')}>
        <IconButton
          type="button"
          size="2rem"
          source={<EditIcon />}
          onClick={() => onEdit(image.id)}
        />
      </Tooltip>
      {showMainTag && (
        <MainImageTag text={`${t('products.detail.mainImage')}`} />
      )}
    </div>
  );
}

export default ThumbnailItem;
