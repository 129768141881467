const CELL_SIZES = {
  orders: {
    product: 350,
    quantity: 140,
    unitPrice: 200,
    total: 200,
  },
  draftOrders: {
    product: 350,
    quantity: 130,
    unitPrice: 200,
    total: 200,
  },
};

export function cellSize(
  listLayout: keyof typeof CELL_SIZES,
): (cell: string) => number {
  return (cell: string) => CELL_SIZES[listLayout][cell];
}
