import { HTMLAttributeAnchorTarget } from 'react';
import { SOCIAL_GOOGLE_ADMIN } from 'App/featuresFlags';
import {
  useAdminLinks,
  useGetTags,
  useIsMobileDevice,
  useTransactionFeeAdminDisabled,
} from 'domains/Auth/hooks';
import { TRANSACTION_FEES_BASE_ROUTE } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { catalogRoutes } from 'domains/Catalog';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { AdditionalSettingType } from 'domains/Dashboard/pages/DashboardPage/components/AdditionalSettings/AditionalSettingCard';
import { FREE_SHIPPING_ROUTES } from 'domains/Marketing/FreeShipping';
import { ordersRoutes } from 'domains/Orders';

interface RedirectionsDashboard {
  to?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
}

interface UseRedirectionsDashboardRsult {
  alerts: { facebook: RedirectionsDashboard };
  additionalSetting: Record<AdditionalSettingType, RedirectionsDashboard>;
}

function useRedirectionsDashboard(): UseRedirectionsDashboardRsult {
  const isMobileDevice = useIsMobileDevice();
  const tags = useGetTags();
  const isTransactionFeeAdminDisabled = useTransactionFeeAdminDisabled();
  const { getNewAdminLink, getOldAdminLink } = useAdminLinks();
  const openNewAdminInNewTab = isMobileDevice ? '_blank' : undefined;
  const withSocialGoogle = tags.includes(SOCIAL_GOOGLE_ADMIN);

  const getDomainLink = () =>
    isTransactionFeeAdminDisabled
      ? TRANSACTION_FEES_BASE_ROUTE
      : CONFIGURATIONS_ROUTES.domains;

  return {
    alerts: {
      facebook: {
        href: getNewAdminLink('/social/meta/#/facebook'),
        target: openNewAdminInNewTab,
      },
    },
    additionalSetting: {
      'custom-link': {
        to: getDomainLink(),
      },
      'free-shipping': {
        to: FREE_SHIPPING_ROUTES.freeShippingList,
      },
      'google-shopping': {
        ...(withSocialGoogle && !isMobileDevice
          ? { to: '/apps/social-google/' }
          : {
              href: withSocialGoogle
                ? getNewAdminLink('/apps/social-google/')
                : getOldAdminLink('/marketing/product_feeds/'),
            }),
        target: openNewAdminInNewTab,
      },
      'instagram-shops': {
        ...(isMobileDevice
          ? { href: getNewAdminLink('/social/meta/#/instagram') }
          : { to: '/social/meta/#/instagram' }),
        target: openNewAdminInNewTab,
      },
      'abandoned-cart': {
        to: ordersRoutes.abandonedCartList,
      },
      'sort-products': {
        to: catalogRoutes.organizeProducts,
      },
      'weight-dimension': {
        to: '/products?page=1&weightDimension=non-dimensions',
      },
    },
  };
}

export default useRedirectionsDashboard;
