import { ReactElement, cloneElement } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import './LegendStratus.scss';

export interface LegendStratusProps {
  payload?: Payload[];
  children: ReactElement;
}

function LegendStratus({ payload, children }: LegendStratusProps) {
  return (
    <div className="stratus--legend-stratus">
      {cloneElement(children, { ...children.props, payload })}
    </div>
  );
}

export default LegendStratus;
