import { useSelector } from 'react-redux';
import { getTimeline } from 'domains/Orders/Orders/ordersSlice';
import { LOG_TYPE_ORDER_EDIT } from '../../constants';

function useOrderIsEdited() {
  const timeline = useSelector(getTimeline);
  return timeline.some((event) => event.type === LOG_TYPE_ORDER_EDIT);
}

export default useOrderIsEdited;
