import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface UuidIndexedArrayInterface {
  uuid: string;
  [x: string]: any;
}

const useUuidIndexedArray = (
  array: any[],
  itemKey = 'item',
): UuidIndexedArrayInterface[] =>
  useMemo(
    () => array.map((item) => ({ uuid: uuidv4(), [itemKey]: item })),
    [array, itemKey],
  );

export default useUuidIndexedArray;
