// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './account.es-AR.json';
import esMX from './account.es-MX.json';
import ptBR from './account.pt-BR.json';

const accountTranslations: DomainTranslations = {
  name: 'account',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default accountTranslations;
