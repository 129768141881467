import { createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDefaultLanguage } from 'domains/Auth/authSlice/authSelectors';

interface Action {
  appId: string;
  section: string;
  path: string;
  text: string;
}

export const getAppsState = (state: RootStateType) => state.apps;

export const getEntities = createSimpleSelector(
  [getAppsState],
  (data) => data.entities,
);

export const getNuvemAppsSelector = createSimpleSelector(
  [getAppsState],
  (AppsState) => AppsState.nuvemapps,
);

export const getAppsLinksSelector = createSimpleSelector(
  [getAppsState],
  (AppsState) => AppsState.appsLinks,
);

export const getAppIdToUninstallSelector = createSimpleSelector(
  [getAppsState],
  (AppsState) => AppsState.appIdToUninstall,
);

export const getActionsOpen = createSelector(
  getAppsState,
  getDefaultLanguage,
  (AppsState, defaultLanguage) => {
    const entities = Object.values(AppsState.entities);
    const actionsOpen: Action[] = entities
      .filter((app) => app.action)
      .map(
        (app) =>
          app.action?.open.map((action): Action => {
            const texti18n: string =
              typeof action.text === 'string'
                ? action.text
                : String(action.text[defaultLanguage]);

            return {
              appId: app.id,
              ...action,
              text: texti18n,
            };
          }) || [],
      )
      .reduce((acc, curr) => [...acc, ...curr], []);

    return actionsOpen;
  },
);

export const getActionsOpenProducts = createSelector(
  getActionsOpen,
  (actionsOpen) =>
    actionsOpen.filter((action) => action.section === 'products'),
);

export const getTokens = createSimpleSelector(
  [getAppsState],
  (AppsState) => AppsState.tokens,
);

export const createGetAppById = () =>
  createSelector(
    [getEntities, (_: RootStateType, id: string) => id],
    (entities, id) => entities[id] || null,
  );

export const getAppStatus = (state: RootStateType) => {
  const status = getAppsState(state).statusApp;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

const getAppsScopesAuthorization = (state: RootStateType) =>
  getAppsState(state).scopes;

const getAppsLinkAuthorization = (state: RootStateType) =>
  getAppsState(state).appLinkAuthorization;

export const getAppsScopeAuthorizationData = (state: RootStateType) =>
  getAppsScopesAuthorization(state).data;

export const getAppsScopeAuthorizationStatus = (state: RootStateType) => {
  const status = getAppsScopesAuthorization(state).status;
  return convertStatusTypeToObject(status);
};

export const getAppsScopeAuthorizationStatusCode = (state: RootStateType) =>
  getAppsScopesAuthorization(state).statusCode;

export const getLinkAuthorizationStatus = (state: RootStateType) =>
  getAppsLinkAuthorization(state).status;
