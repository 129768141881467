import { Checkbox } from '@nimbus-ds/components';
import { DataList, InteractiveList } from '@nimbus-ds/patterns';

interface SekletonProps {
  avoidHorizontalPadding?: boolean;
}

function Skeleton({ avoidHorizontalPadding = false }: Readonly<SekletonProps>) {
  const horizontalPadding = avoidHorizontalPadding ? 'none' : undefined;

  return (
    <DataList.Row
      flexDirection="row"
      gap="2"
      cursor="pointer"
      alignItems="flex-start"
      backgroundColor={{ hover: 'neutral-surface' }}
      transitionDuration="fast"
      transitionProperty="background-color"
      transitionTimingFunction="ease-in-out"
      boxShadow={{ focusWithin: 'focusRing' }}
      borderWidth="none"
      borderTopWidth="1"
      paddingLeft={horizontalPadding}
      paddingRight={horizontalPadding}
    >
      <InteractiveList.StructureSkeleton title="title" />
      <Checkbox.Skeleton width="0" />
    </DataList.Row>
  );
}

export default Skeleton;
