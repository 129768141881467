import { Responsive } from 'commons/components';
import { useFetchCustomerMetafields } from 'domains/Customers/hooks';
import { CustomerResultsDesktop, CustomerResultsMobile } from './components';

function CustomerListResults(): JSX.Element {
  useFetchCustomerMetafields();

  return (
    <Responsive
      mobile={<CustomerResultsMobile />}
      desktop={<CustomerResultsDesktop />}
    />
  );
}

export default CustomerListResults;
