import { Text, Modal, Button } from '@nimbus-ds/components';
import ModalAside from 'App/components/lab/ModalAside';
import useTranslationBilling from '../useTranslationBilling';

export interface UpsellUnavailableModalProps {
  show?: boolean;
  title?: string;
  asAside?: boolean;
  onDismiss: () => void;
}

export function UpsellUnavailableModal({
  show = true,
  title,
  asAside = false,
  onDismiss,
}: Readonly<UpsellUnavailableModalProps>) {
  const t = useTranslationBilling();

  const modalTitle = title || t('upsellFlow.modal.title');

  const closeModalCta = (
    <Button appearance="primary" onClick={onDismiss}>
      {t('upsellFlow.unavailable.cta')}
    </Button>
  );

  return asAside ? (
    <ModalAside
      isOpen={show}
      onDidDismiss={onDismiss}
      headerContent={modalTitle}
    >
      <Text>{t('upsellFlow.unavailable.message')}</Text>
      {closeModalCta}
    </ModalAside>
  ) : (
    <Modal open={show} onDismiss={onDismiss}>
      <Modal.Header title={modalTitle} />
      <Modal.Body padding="none">
        <Text>{t('upsellFlow.unavailable.message')}</Text>
      </Modal.Body>
      <Modal.Footer>{closeModalCta}</Modal.Footer>
    </Modal>
  );
}
