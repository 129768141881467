import { ReactElement, useState, cloneElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@tiendanube/components';
import { LanguagesType } from 'App/i18n';
import { useGetAllLanguages, useGetLanguage } from 'domains/Auth/hooks';

interface TabsMultiLanguageProps {
  children: ReactElement;
  initialLanguage?: LanguagesType;
}

function TabsMultiLanguage({
  children,
  initialLanguage,
}: TabsMultiLanguageProps): JSX.Element {
  const { t } = useTranslation('common');
  const languages = useGetAllLanguages();
  const defaultLanguages = useGetLanguage();
  const [activeTab, setActiveTab] = useState(0);
  const handleOnChange = (activeTab: number) => {
    setActiveTab(activeTab);
  };

  useEffect(() => {
    if (!initialLanguage) return;

    const tab = languages.findIndex((lang) => lang === initialLanguage);
    if (tab !== activeTab) setActiveTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLanguage, languages]);

  if (languages.length <= 1)
    return cloneElement(children, {
      ...children.props,
      language: defaultLanguages,
    });

  return (
    <Tabs activeTab={activeTab} onChange={handleOnChange}>
      {languages.map((language) => (
        <Tabs.Item
          key={language}
          label={t(`tabsMultilanguage.title.${language}`)}
        >
          {cloneElement(children, { ...children.props, language })}
        </Tabs.Item>
      ))}
    </Tabs>
  );
}

export default TabsMultiLanguage;
