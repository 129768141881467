import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  CreateDocumentRequestDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  createInformationForLabelDocument,
  createInformationForContentDeclarationDocument,
  createInformationForReceiptDocument,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';
import { getDocumentStatus } from 'domains/FulfillmentOrders/fulfillmentOrdersSlice/fulfillmentOrdersSelectors';
import { DocumentType } from '../../constants';

export interface UseGetDocumentInformationResult {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  fetchInformationForDocument: (
    params: CreateDocumentRequestDto,
  ) => Promise<void>;
}

function useGetInformationForDocument(
  type: DocumentType,
): UseGetDocumentInformationResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(getDocumentStatus);
  const [information, setInformation] = useState<
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[]
  >();

  const fetchInformationForDocument = useCallback(
    async (params: CreateDocumentRequestDto) => {
      switch (type) {
        case DocumentType.CONTENT_DECLARATION:
          setInformation(
            unwrapResult(
              await dispatch(
                createInformationForContentDeclarationDocument(params),
              ),
            ),
          );
          break;
        case DocumentType.RECEIPT:
          setInformation(
            unwrapResult(
              await dispatch(createInformationForReceiptDocument(params)),
            ),
          );
          break;
        case DocumentType.LABEL:
        default:
          setInformation(
            unwrapResult(
              await dispatch(createInformationForLabelDocument(params)),
            ),
          );
          break;
      }
    },
    [dispatch, type],
  );

  return {
    isLoading,
    isError,
    isSuccess,
    information,
    fetchInformationForDocument,
  };
}

export default useGetInformationForDocument;
