import { Box, Card, Skeleton } from '@nimbus-ds/components';

export function FreeShippingDetailsSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Card>
        <Card.Body>
          <Box display="flex" gap="5" flexDirection="column">
            <Skeleton width="83px" height="28px" borderRadius="4px" />
            <Skeleton width="320px" height="18px" borderRadius="4px" />
            <Skeleton width="35px" height="16px" borderRadius="4px" />
          </Box>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Box display="flex" gap="5" flexDirection="column">
            <Skeleton width="83px" height="28px" borderRadius="4px" />
            <Skeleton width="320px" height="18px" borderRadius="4px" />
            <Skeleton width="140px" height="16px" borderRadius="4px" />
            <Skeleton width="35px" height="16px" borderRadius="4px" />
            <Skeleton width="40px" height="16px" borderRadius="4px" />
            <Skeleton width="320px" height="32px" borderRadius="4px" />
            <Skeleton width="140px" height="16px" borderRadius="4px" />
            <Skeleton width="35px" height="16px" borderRadius="4px" />
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}
