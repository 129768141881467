import {
  FulfillmentOrderRecipientForDocumentResponseDto,
  FulfillmentOrderShippingPickupDetailsForDocumentResponseDto,
} from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface PickupDocumentPreviewProps {
  shippingName: string;
  recipient: FulfillmentOrderRecipientForDocumentResponseDto;
  pickupDetails: FulfillmentOrderShippingPickupDetailsForDocumentResponseDto | null;
  showLabelRecipientDocument: boolean;
}

function PickupDocumentPreview({
  shippingName,
  recipient,
  pickupDetails,
  showLabelRecipientDocument,
}: Readonly<PickupDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="address-section">
      <p className="bold">{t('pickup.title')}</p>
      {!!pickupDetails?.address && (
        <>
          <p className="bold-name">{pickupDetails?.name || shippingName}</p>
          {!!pickupDetails.address.streetAddress && (
            <p>{pickupDetails.address.streetAddress}</p>
          )}
          {!!pickupDetails.address.additionalAddressDetails && (
            <p>{pickupDetails.address.additionalAddressDetails}</p>
          )}
          {!!pickupDetails.address.zipLocation && (
            <p>{pickupDetails.address.zipLocation}</p>
          )}
          {!!pickupDetails.address.country && (
            <p>{pickupDetails.address.country}</p>
          )}
        </>
      )}
      {!pickupDetails?.address && (
        <>
          {pickupDetails?.name !== shippingName && (
            <p className="bold-name">{shippingName}</p>
          )}

          <p className={pickupDetails?.name === shippingName ? 'bold' : ''}>
            {pickupDetails?.name}
          </p>
        </>
      )}
      <p className="bold">
        {t('pickup.name')} {recipient.name}
      </p>
      {!!recipient.phone && (
        <p className="bold">
          {t('recipient.contact')} {recipient.phone}
        </p>
      )}
      {showLabelRecipientDocument && (
        <p>
          {t('recipient.document')} {recipient.identifier}
        </p>
      )}
    </div>
  );
}

export default PickupDocumentPreview;
