import { useEffect } from 'react';
import { checkAndRegisterDevice } from 'App/capacitor/device';

function useNotifications() {
  useEffect(() => {
    checkAndRegisterDevice();
  }, []);
}

export default useNotifications;
