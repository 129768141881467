import { Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useGetFormatedDateTooltip } from 'domains/Statistics';
import {
  ColorAndText,
  ThousandSeparatorText,
} from 'domains/Statistics/components';
import { capitalizeFirstLetter } from 'domains/Statistics/utils';
import { StratusTooltipContentProps } from '../../StratusTooltipContent';
import '../../StratusTooltipContent.scss';

function StratusTooltipContentCompared({
  payload: data = [],
  formatLabel = (val: string) => val,
  isCombined = false,
  granularity,
}: Omit<StratusTooltipContentProps, 'hasComparedData'>) {
  const getDateFormatted = useGetFormatedDateTooltip(granularity);

  if (data === null) return null;

  const segments = data.filter(
    ({ dataKey }) =>
      `${dataKey}`?.startsWith('current.') ||
      `${dataKey}`?.startsWith('principalValue'),
  );
  const comparedSegments = data.filter(
    ({ dataKey }) =>
      `${dataKey}`?.startsWith('previous.') ||
      `${dataKey}`?.startsWith('secondaryValue'),
  );

  const renderContent = (
    value?: number,
    date?: string,
    color?: string,
    dataKey?: string | number,
  ) => {
    const text =
      isCombined && dataKey
        ? formatLabel?.(dataKey.toString())
        : !isCombined && date
        ? getDateFormatted(date)
        : '';

    return (
      <Stack
        key={`${value}-${date}${color}`}
        spacing="tight"
        wrap
        justify="space-between"
      >
        <div className="stratus--tooltip-content__max-width">
          <ColorAndText color={color} text={text} />
        </div>
        <ThousandSeparatorText
          size="caption"
          textAlign="right"
          value={value ?? 0}
          withoutDecimal={false}
        />
      </Stack>
    );
  };

  const tooltipFirstHeader =
    segments[0] && getDateFormatted(segments[0].payload?.current?.name);

  const tooltipSecondHeader =
    comparedSegments[0] &&
    getDateFormatted(comparedSegments[0].payload?.previous?.name);

  return (
    <Stack column align="stretch" spacing="tight">
      {!!tooltipFirstHeader && (
        <Text fontSize="caption">
          {capitalizeFirstLetter(tooltipFirstHeader)}
        </Text>
      )}
      {segments?.map(({ color, value, payload, dataKey }) =>
        renderContent(
          value,
          payload?.current?.name || payload?.name,
          color,
          dataKey,
        ),
      )}
      {!!tooltipSecondHeader && (
        <Text fontSize="caption">
          {capitalizeFirstLetter(tooltipSecondHeader)}
        </Text>
      )}
      {comparedSegments?.map(({ color, value, payload, dataKey }) =>
        renderContent(
          value,
          payload?.previous?.name || payload?.secondaryName,
          color,
          dataKey,
        ),
      )}
    </Stack>
  );
}

export default StratusTooltipContentCompared;
