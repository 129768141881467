import { useSelector } from 'react-redux';
import { getNewOrdersCount } from '../../ordersSlice/ordersSelectors';

function useGetNewOrdersCount(): number {
  const count = useSelector(getNewOrdersCount);

  return count;
}

export default useGetNewOrdersCount;
