import { useMemo } from 'react';
import { Label, Radio, Text } from '@nimbus-ds/components';
import { AiFeature, AiWordCount } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';

export interface WordCountControlsProps {
  feature: AiFeature;
  wordCount?: AiWordCount;
  wordNumber?: number;
  setWordCount: (wordCount: AiWordCount) => void;
}
export function WordCountControls({
  feature,
  wordCount,
  wordNumber,
  setWordCount,
}: Readonly<WordCountControlsProps>) {
  const t = useTranslationCommon();

  const handleChangeWordCount: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setWordCount(event.target.value as AiWordCount);
  };

  const wordCounts = useMemo(
    () => [
      {
        label: t(`aiGeneration.wordCount.values.${AiWordCount.SHORT}`),
        value: AiWordCount.SHORT,
      },
      {
        label: t(`aiGeneration.wordCount.values.${AiWordCount.MEDIUM}`),
        value: AiWordCount.MEDIUM,
      },
      {
        label: t(`aiGeneration.wordCount.values.${AiWordCount.LARGE}`),
        value: AiWordCount.LARGE,
      },
    ],
    [t],
  );

  return (
    <Stack column align="flex-start" spacing="tight">
      <Label>{t('aiGeneration.wordCount.label')}</Label>
      <Stack gap="1">
        {wordCounts.map(({ label, value }) => (
          <Stack.Item key={`ai-word-count-${feature}-${value}`}>
            <Radio
              as="button"
              name={`ai-word-count-${feature}`}
              id={`ai-word-count-${feature}-${value}`}
              label={label}
              value={value}
              onChange={handleChangeWordCount}
              checked={value === wordCount}
            />
          </Stack.Item>
        ))}
      </Stack>
      {!!wordNumber && (
        <Stack.Item>
          <Text fontSize="caption">
            {t('aiGeneration.resultsModal.editInstructions.subtitleWordCount', {
              wordNumber,
            })}
          </Text>
        </Stack.Item>
      )}
    </Stack>
  );
}
