import { Box, Text } from '@nimbus-ds/components';
import { CurrencyPrice } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import { ProductInterface } from '../../types';

interface VariantStockPriceProps {
  variant: ProductInterface<false>;
}

function VariantStockPrice({ variant }: Readonly<VariantStockPriceProps>) {
  const t = useTranslationOrders();
  const currency = useGetCurrency();

  const getStockTextOption = () => {
    if (variant.stock === null) {
      return 'infiniteStock';
    }
    if (variant.stock > 0) {
      return 'inStock';
    }
    return 'outOfStock';
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      flex="1"
    >
      <Text
        color={variant.stock === 0 ? 'danger-interactive' : 'neutral-textLow'}
      >
        {t(`productSearcher.${getStockTextOption()}`, {
          count: variant.stock ?? 0,
        })}
      </Text>
      <CurrencyPrice
        price={variant.price}
        currency={currency.code}
        bold
        appearance="light"
      />
    </Box>
  );
}

export default VariantStockPrice;
