import { Box, BoxProps } from '@nimbus-ds/components';

interface AiGenerationResultsCardProps extends BoxProps {
  children: React.ReactNode;
}

export function AiGenerationResultsCard({
  children,
  ...boxProps
}: Readonly<AiGenerationResultsCardProps>) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="2"
      p="4"
      backgroundColor="primary-surface"
      minHeight="80px"
      gap="2"
      {...boxProps}
    >
      {children}
    </Box>
  );
}
