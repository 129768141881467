import { Link, Box, Icon } from '@nimbus-ds/components';
import { ChevronLeftIcon, ChevronRightIcon } from '@nimbus-ds/icons';

export interface PaginationProps {
  onPrevious: () => void;
  onNext: () => void;
  disabledNext?: boolean;
  disabledPrevious?: boolean;
  grayed?: boolean;
}

function LeftAndRightButtons({
  onPrevious,
  onNext,
  grayed,
  disabledNext = false,
  disabledPrevious = false,
}: PaginationProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="none"
      padding="2"
      backgroundColor={grayed ? 'neutral-surfaceDisabled' : undefined}
      borderRadius="2"
    >
      <Box paddingX="2" paddingY="0-5">
        <Link disabled={disabledPrevious} as="button" onClick={onPrevious}>
          <Icon source={<ChevronLeftIcon />} />
        </Link>
      </Box>
      <Box paddingX="2" paddingY="0-5">
        <Link disabled={disabledNext} as="button" onClick={onNext}>
          <Icon source={<ChevronRightIcon />} />
        </Link>
      </Box>
    </Box>
  );
}
export default LeftAndRightButtons;
