import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InstallBasePaymentProviderRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanUpdateStatusAction,
  installPaymentProviderAction,
  getInstalledPaymentProviderUpdateStatus,
} from '../../paymentProvidersSlice';

function useInstallPaymentProvider() {
  const dispatch = useAppDispatch();
  const updateStatus = useSelector(getInstalledPaymentProviderUpdateStatus);

  const install = useCallback(
    async (
      providerId: string,
      payload: InstallBasePaymentProviderRequestDto,
    ) => {
      await dispatch(installPaymentProviderAction({ providerId, payload }));
      dispatch(cleanUpdateStatusAction());
    },
    [dispatch],
  );

  return {
    install,
    updateStatus,
  };
}

export default useInstallPaymentProvider;
