import { Stack, Text } from '@tiendanube/components';
import { CheckIcon, CloseIcon } from '@tiendanube/icons';

interface CheckItemProps {
  checked?: boolean;
  children: string;
}

function CheckItem({ checked, children }: CheckItemProps): JSX.Element {
  return (
    <Stack.Item>
      <Stack spacing="tight">
        <Stack.Item>
          {checked && (
            <Text appearance="success">
              <CheckIcon />
            </Text>
          )}
          {!checked && (
            <Text appearance="light">
              <CloseIcon />
            </Text>
          )}
        </Stack.Item>
        <Stack.Item fill>
          <Text appearance={checked ? 'default' : 'light'}>{children}</Text>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
}

export default CheckItem;
