import { createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceMetafieldBulkAssignmentsSchema } from '../productMetafieldsSlice';

export const geProductMetafieldsState = (
  state: RootStateType,
): InterfaceMetafieldBulkAssignmentsSchema => state.catalog.productMetafields;

export const getProductMetafieldsStatus = createSelector(
  geProductMetafieldsState,
  (productMetafields) => productMetafields.status,
);
