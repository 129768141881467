import { logEvent } from 'commons/utils/tracking';
import {
  THEME_FTP_DISABLE,
  THEME_FTP_ENABLE,
  THEME_LAYOUT_CHANGE,
  THEME_LAYOUT_CUSTOMIZE_CURRENT,
  THEME_LAYOUT_CUSTOMIZE_DRAFT,
  THEME_LAYOUT_DRAFT_ACTIONS_VIEW,
  THEME_LAYOUT_DRAFT_CREATION,
  THEME_LAYOUT_DRAFT_CREATION_DEFAULT,
  THEME_LAYOUT_DRAFT_CREATION_OTHERS,
  THEME_LAYOUT_DRAFT_DELETE,
  THEME_LAYOUT_DRAFT_PUBLISH,
  THEME_SELECTION_UPDATED,
} from 'config/trackingEvents';

export const trackingThemeLayoutCustomizeCurrent = (): void => {
  logEvent(THEME_LAYOUT_CUSTOMIZE_CURRENT, {});
};

export const trackingThemeFtpEnable = (): void => {
  logEvent(THEME_FTP_ENABLE, {});
};

export const trackingThemeFtpDisable = (): void => {
  logEvent(THEME_FTP_DISABLE, {});
};

export const trackingThemeLayoutCustomizeDraft = (): void => {
  logEvent(THEME_LAYOUT_CUSTOMIZE_DRAFT, {});
};

export const trackingThemeLayoutChange = (): void => {
  logEvent(THEME_LAYOUT_CHANGE, {});
};

export const trackingPromotedThemeInstall = (
  theme: string,
  from: string,
): void => {
  // Note: should match event tracked in instatheme:
  // https://github.com/TiendaNube/tiendanube/blob/master/institucional/laravel/application/views/insti/theme_store/list.tpl#L470C35-L470C58
  logEvent(THEME_SELECTION_UPDATED, {
    theme,
    from,
  });
};

export const trackingThemeLayoutDraftActionsView = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_ACTIONS_VIEW, {});
};

export const trackingThemeLayoutDraftPublish = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_PUBLISH, {});
};

export const trackingThemeLayoutDraftDelete = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_DELETE, {});
};

export const trackingThemeLayoutDraftCreation = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_CREATION, {});
};

export const trackingThemeLayoutDraftCreationDefault = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_CREATION_DEFAULT, {});
};

export const trackingThemeLayoutDraftCreationDefaultOthers = (): void => {
  logEvent(THEME_LAYOUT_DRAFT_CREATION_OTHERS, {});
};
