import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useIsLogged } from 'domains/Auth/hooks';
import useLogout from './useLogout';

function LogoutPage(): JSX.Element | null {
  const { isLogin } = useIsLogged();
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, [logout]);

  if (!isLogin) {
    return <Redirect to="/auth/login" />;
  }
  return null;
}

export default LogoutPage;
