import { ResultPaginationResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { emptyPagination } from './constants';
import { UseGenericDataListProps, UseQueryResult } from './types';

function useListDesktop<T>({ endpoint, filters }: UseGenericDataListProps) {
  const dataTableResources = cirrusApi.endpoints[endpoint].useQuery(
    filters,
  ) as UseQueryResult<ResultPaginationResponseDto<Array<T>>>;

  const { data, isFetching, isLoading, isSuccess } = dataTableResources;

  const dataResult = data?.results || ([] as Array<T>);
  return {
    ...dataTableResources,
    data: dataResult,
    pagination: data?.pagination || emptyPagination,
    showSkeleton: isFetching || isLoading,
    isDataEmpty: isSuccess && dataResult.length === 0,
  };
}

export default useListDesktop;
