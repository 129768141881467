import { Country } from '@tiendanube/common/src/enums';

export const COUNTRY_WITH_CITIES = {
  [Country.CO]: Country.CO,
  [Country.CL]: Country.CL,
};

export const COUNTRY_WITH_MANDATORY_ZIPCODE = {
  [Country.AR]: Country.AR,
  [Country.BR]: Country.BR,
  [Country.MX]: Country.MX,
};

export const COUNTRY_WITH_OPTIONAL_LOCALITY = {
  [Country.CO]: Country.CO,
  [Country.CL]: Country.CL,
};

export const ZIPCODE_VALUE_LENGTH = 9;

export const COUNTRY_WITH_LOCATION_FIELDS = {
  [Country.AR]: Country.AR,
  [Country.BR]: Country.BR,
  [Country.CL]: Country.CL,
  [Country.CO]: Country.CO,
  [Country.MX]: Country.MX,
};

export const LOCATION_TAGS = {
  online: 'online',
  offline: 'offline',
};
