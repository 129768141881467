import { Link } from 'react-router-dom';

interface LinkGoToAdminProductProps {
  title: string;
  id: string;
  bold?: boolean;
}

function LinkGoToAdminProduct({
  title,
  id,
  bold = false,
}: LinkGoToAdminProductProps): JSX.Element {
  return (
    <Link
      className="nimbus--link nimbus--link--primary nimbus--link--base"
      to={`/products/${id}`}
    >
      {bold ? <b>{title}</b> : title}
    </Link>
  );
}

export default LinkGoToAdminProduct;
