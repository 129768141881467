import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getBouncedEmails,
  getOrderBouncedEmails,
} from 'domains/Orders/Orders/ordersSlice';

function useOrdersEmailTracking(id: string) {
  const dispatch = useAppDispatch();
  const bouncedEmails = useSelector(getBouncedEmails);
  const fetchBouncedEmails = useCallback(
    () => dispatch(getOrderBouncedEmails({ id })),
    [id, dispatch],
  );

  const showAlert = bouncedEmails.length > 0;

  return { showAlert, fetchBouncedEmails };
}

export default useOrdersEmailTracking;
