import { useEffect } from 'react';
import { Box, Button, Modal, Text, Link } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  DASHBOARD_ROUTES,
  trackingBillingTxFeesPageReminderModalClick,
} from 'domains/Dashboard';
import { useTransactionFeesPaymentAlert } from '../hooks';

interface TransactionFeesPaymentModalProps {
  initialFetch?: boolean;
}

/**
 * Props for the TransactionFeesPaymentModalProps component.
 *
 * @interface TransactionFeesPaymentModalProps
 * @property {boolean} [initialFetch=true] - Specifies whether an initial fetch should be performed
 *                                          when the component mounts. If set to true (default), the
 *                                          component fetches data on mount; if set to false, it does
 *                                          not perform the initial fetch.
 */

function TransactionFeesPaymentModal({
  initialFetch = true,
}: Readonly<TransactionFeesPaymentModalProps>) {
  const [showing, showModal, hideModal] = useModal();
  const t = useTranslationBilling('transactionFeesPaymentPopup');
  const {
    transactionFeeToPay,
    isSuccess,
    shouldShowModal,
    deadlineDay,
    deadlineMonth,
    onPay,
    dismissModal,
  } = useTransactionFeesPaymentAlert(initialFetch);
  const { pathname } = useNavegate();
  const isMobileDevice = useIsMobileDevice();

  useEffect(() => {
    if (shouldShowModal) showModal();
  }, [shouldShowModal, showModal]);

  if (
    !isSuccess ||
    !transactionFeeToPay ||
    transactionFeeToPay.isEmpty ||
    !transactionFeeToPay.deadlineDate
  )
    return null;

  const closeModal = () => {
    dismissModal();
    hideModal();
  };

  const mobileSuffix = isMobileDevice ? 'Mobile' : '';

  const handleClick = () => {
    if (pathname === DASHBOARD_ROUTES.dashboard)
      trackingBillingTxFeesPageReminderModalClick();
    onPay();
  };

  return (
    <Modal open={showing} onDismiss={closeModal}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Box display="flex" flexDirection="column" gap="6">
          <Text>
            {t(`message1${mobileSuffix}`, {
              day: deadlineDay,
              month: deadlineMonth,
            })}
          </Text>
          <Text>{t('message2')}</Text>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            gap="4"
          >
            <Link as="a" href={t('helpLink')}>
              {t('helpLinkText')}
            </Link>
            {!isMobileDevice && (
              <Button appearance="primary" onClick={handleClick}>
                {t('payButton')}
              </Button>
            )}
          </Box>
        </Box>
      </Modal.Body>
    </Modal>
  );
}

export default TransactionFeesPaymentModal;
