import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';

function CustomerDataCardSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerDetails.customerData.title')}>
      <Stack column align="stretch" spacing="tight">
        <Stack.Item>
          <Text.Skeleton width="large" />
          <Text.Skeleton width="fill" />
          <Text.Skeleton width="fill" />
        </Stack.Item>
        <Stack.Item>
          <Text.Skeleton width="fill" />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CustomerDataCardSkeleton;
