import { createSelector } from '@reduxjs/toolkit';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getCheckoutState } from './transactionFeeToPaySelectors';

export const getPaymentsHistoryStatus = createSimpleSelector(
  [getCheckoutState],
  ({ paymentsHistory }) => paymentsHistory.status,
);

export const fetchPaymentsHistorySelector = createSimpleSelector(
  [getCheckoutState],
  ({ paymentsHistory }) => paymentsHistory.data,
);

export const paymentHistoryForYearSelector = createSelector(
  [
    fetchPaymentsHistorySelector,
    (state, split: boolean) => split,
    (state, split, year?: string) => year,
  ],
  (data, split, year) => {
    const dataForYear = data?.find((dataPerYear) => dataPerYear.year === year);
    if (!dataForYear) return [];
    if (split) {
      return dataForYear.payments.split;
    }
    return dataForYear.payments.noSplit;
  },
);
