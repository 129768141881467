import { Accordion, Icon, Link, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { ARecordStatus } from './ARecordStatus';
import { CopyLinkBox } from '../../CopyLinkBox';

interface ARecordVerificationProps {
  currentARecords: string[];
  expectedIps: string[];
}

export function ARecordVerification({
  currentARecords,
  expectedIps,
}: Readonly<ARecordVerificationProps>) {
  const t = useTranslationConfigurations('domains.attachModal');
  const currentARecordsIsEmpty = currentARecords.length === 0;

  const correctARecords = currentARecords.filter((ip) =>
    expectedIps.includes(ip),
  );

  const wrongARecords = currentARecords.filter(
    (ip) => !expectedIps.includes(ip),
  );

  const missingExpectedARecords = expectedIps.filter(
    (ip) => !currentARecords.includes(ip),
  );

  const toBeReplacedARecords =
    wrongARecords.length > 0 && correctARecords.length < expectedIps.length
      ? Math.min(
          currentARecords.length,
          expectedIps.length - correctARecords.length,
        )
      : 0;

  const toBeAddedARecordsCount =
    correctARecords.length < expectedIps.length
      ? expectedIps.length - (toBeReplacedARecords + correctARecords.length)
      : 0;

  const toBeAddedARecords = currentARecordsIsEmpty
    ? expectedIps
    : toBeAddedARecordsCount > 0
    ? missingExpectedARecords.slice(0, toBeAddedARecordsCount)
    : [];

  return (
    <Accordion.Item index="0">
      <Accordion.Header
        title={t('verificationModal.a.title')}
        icon={<Icon source={<InfoCircleIcon size="medium" />} />}
      />
      <Accordion.Body>
        <Stack column align="stretch" flex="1">
          <Stack column align="stretch" spacing="tight">
            <Text color="danger-textLow" fontWeight="medium">
              {currentARecordsIsEmpty
                ? t('verificationModal.a.errorEmpty')
                : t('verificationModal.a.error')}
            </Text>
            {currentARecordsIsEmpty && (
              <Text>
                <Trans
                  t={t}
                  i18nKey="step2.a.copyText"
                  components={{
                    helpLink: (
                      <Link
                        as="a"
                        appearance="primary"
                        href={t('step2.a.helpLink')}
                        target="_blank"
                        textDecoration="none"
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
            )}
            <Stack column align="stretch" spacing="none">
              {!currentARecordsIsEmpty &&
                correctARecords.map((ip) => (
                  <ARecordStatus
                    key={ip}
                    left={<Text>{ip}</Text>}
                    right={<Text>{t('verificationModal.a.correct')}</Text>}
                  />
                ))}
              {!!toBeReplacedARecords &&
                wrongARecords
                  .slice(0, toBeReplacedARecords)
                  .map((ip, index) => (
                    <ARecordStatus
                      key={ip}
                      left={
                        <Text>
                          <s>{ip}</s>
                        </Text>
                      }
                      right={
                        <Stack column spacing="none" key={ip}>
                          <Text>{t('verificationModal.a.replace')}</Text>
                          <CopyLinkBox
                            value={missingExpectedARecords[index]}
                            message={t('step2.a.copySuccess')}
                            text={missingExpectedARecords[index]}
                          />
                        </Stack>
                      }
                    />
                  ))}

              {toBeAddedARecords.map((ip) => (
                <ARecordStatus
                  key={ip}
                  left={
                    <CopyLinkBox
                      value={ip}
                      message={t('step2.a.copySuccess')}
                      text={ip}
                    />
                  }
                  right={<Text>{t('verificationModal.a.add')}</Text>}
                />
              ))}
              {currentARecords.length > expectedIps.length &&
                currentARecords
                  .filter((a) => !expectedIps.includes(a))
                  .slice(toBeReplacedARecords)
                  .map((ip) => (
                    <ARecordStatus
                      key={ip}
                      left={
                        <Text>
                          <s>{ip}</s>
                        </Text>
                      }
                      right={<Text>{t('verificationModal.a.remove')}</Text>}
                    />
                  ))}
            </Stack>
          </Stack>
          {!currentARecordsIsEmpty && (
            <Text>
              <Trans
                t={t}
                i18nKey="verificationModal.a.help"
                components={{
                  helpLink: (
                    <Link
                      as="a"
                      appearance="primary"
                      href={t('step2.a.helpLink')}
                      target="_blank"
                      textDecoration="none"
                    >
                      {' '}
                    </Link>
                  ),
                }}
              />
            </Text>
          )}
        </Stack>
      </Accordion.Body>
    </Accordion.Item>
  );
}
