export const canCountryLink = {
  AR: 'https://ayuda.tiendanube.com/es_AR/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
  BR: 'https://atendimento.nuvemshop.com.br/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
  CL: 'https://ayuda.tiendanube.com/es_AR/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
  MX: 'https://ayuda.tiendanube.com/es_MX/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
  CO: 'https://ayuda.tiendanube.com/es_CO/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
  default:
    'https://ayuda.tiendanube.com/es_MX/?utm_source=admin-desktop&utm_medium=header&utm_campaign=help',
};

export const whatsappCountryLink = {
  AR: 'https://api.whatsapp.com/send/?phone=5491120401234&text&type=phone_number&app_absent=0',
  BR: 'https://api.whatsapp.com/send/?phone=551140207216&text&type=phone_number&app_absent=0',
  CL: 'https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0',
  MX: 'https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0',
  CO: 'https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0',
  default:
    'https://api.whatsapp.com/send/?phone=5216644149766&text&type=phone_number&app_absent=0',
};

export const phoneCallLink = {
  BR: 'https://nuvemshopajuda.zendesk.com/hc/pt',
};
