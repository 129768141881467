import { Box } from '@nimbus-ds/components';
import { BoxPackedIcon, MoneyIcon, TruckIcon } from '@nimbus-ds/icons';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import {
  MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  MIGRATION_ORDERS,
} from 'App/featuresFlags';
import { BlockWithTitle } from 'commons/components';
import {
  useGetTags,
  useIsMobileDevice,
  useStatusFeatureByKey,
} from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { ordersRoutes } from 'domains/Orders';
import { useGetPendingOrders } from 'domains/Orders/Orders/hooks';
import PendingOrdersCard from './PendingOrdersCard';
import {
  ORDER_FILTERS,
  getEmptyPreffix,
  handleClick,
} from './pendingOrdersUtils';

export type PendingOrderType =
  | 'pendingPayment'
  | 'pendingShipping'
  | 'pendingPacked';

function PendingOrders() {
  const t = useTranslationDashboard('pendingOrders');
  const { pendingOrders, isLoading, isError } = useGetPendingOrders();
  const { isAvailable: isFiltersAllowed } = useStatusFeatureByKey(
    FEATURE_SEARCH_FILTER,
  );
  const isMobileDevice = useIsMobileDevice();
  const tags = useGetTags();
  if (isError) return null;

  const hasOrdersAccess =
    tags.includes(MIGRATION_ORDERS) ||
    tags.includes(MIGRATION_ENABLE_ALL_MIGRATED_PAGES);
  const shouldGoToOldAdmin = !hasOrdersAccess && !isMobileDevice;

  const getFilterPath = (filter: PendingOrderType) => {
    if (shouldGoToOldAdmin) {
      return `/admin${ORDER_FILTERS.old[filter]}`;
    }
    return `${ordersRoutes.orderList}${
      isFiltersAllowed ? ORDER_FILTERS.new[filter] : ''
    }`;
  };

  const emptyPayment = pendingOrders?.payment === 0;
  const emptyShipping = pendingOrders?.shipping === 0;
  const emptyUnpacked = pendingOrders?.unpacked === 0;
  const getTitle = (isEmpty: boolean, count?: number) =>
    isEmpty ? '' : t('orders', { count });

  return (
    <BlockWithTitle title={t('title')}>
      <Box
        display="flex"
        flexDirection={{
          lg: 'row',
          md: 'column',
          xs: 'column',
        }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap="4"
      >
        <PendingOrdersCard
          appearance="warning-surface"
          icon={<MoneyIcon size="medium" />}
          subtitle={t(`pendingPayment${getEmptyPreffix(emptyPayment)}`)}
          title={getTitle(
            emptyPayment,
            shouldGoToOldAdmin
              ? pendingOrders?.paymentOldAdminResult
              : pendingOrders?.payment,
          )}
          skeleton={isLoading}
          to={getFilterPath('pendingPayment')}
          onClick={handleClick('pendingPayment')}
          hideIcon={!isFiltersAllowed || emptyPayment}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
        <PendingOrdersCard
          appearance="primary-surface"
          icon={<TruckIcon size="medium" />}
          subtitle={
            shouldGoToOldAdmin
              ? t(`oldPendingShipping${getEmptyPreffix(emptyShipping)}`)
              : t(`pendingShipping${getEmptyPreffix(emptyShipping)}`)
          }
          title={getTitle(
            emptyShipping,
            shouldGoToOldAdmin
              ? pendingOrders?.shippingOldAdminResult
              : pendingOrders?.shipping,
          )}
          skeleton={isLoading}
          to={getFilterPath('pendingShipping')}
          onClick={handleClick('pendingShipping')}
          hideIcon={!isFiltersAllowed || emptyShipping}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
        <PendingOrdersCard
          icon={<BoxPackedIcon size="medium" />}
          subtitle={t(`pendingPacked${getEmptyPreffix(emptyUnpacked)}`)}
          title={getTitle(
            emptyUnpacked,
            shouldGoToOldAdmin
              ? pendingOrders?.unpackedOldAdminResult
              : pendingOrders?.unpacked,
          )}
          skeleton={isLoading}
          to={getFilterPath('pendingPacked')}
          onClick={handleClick('pendingPacked')}
          hideIcon={!isFiltersAllowed || emptyUnpacked}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
      </Box>
    </BlockWithTitle>
  );
}

export default PendingOrders;
