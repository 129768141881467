import { LanguageType, i18nResponseDto } from '@tiendanube/common';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import { useProductDetails } from 'domains/Catalog/Products/pages/EditProductPage/hooks';
import {
  getErrorDuplicatedLanguages,
  hasDuplicateAttributes,
} from '../../components/CardVariants/utils';

function useDuplicateAttributes(productId: string): {
  withDuplicateAttributesMainLanguage: boolean;
  withDuplicateAttributesSecondaryLanguage: boolean;
} {
  const { product } = useProductDetails(productId);
  const languages = useGetAllLanguages();

  if (!product) {
    return {
      withDuplicateAttributesMainLanguage: false,
      withDuplicateAttributesSecondaryLanguage: false,
    };
  }

  const isObjectAttributes =
    typeof product.attributes === 'object' &&
    !Array.isArray(product.attributes);

  const attributes = isObjectAttributes
    ? Object.values<i18nResponseDto>(product.attributes)
    : product.attributes ?? [];

  const getErrorsByLanguages = getErrorDuplicatedLanguages({
    attributes,
    toDelete: [],
  });
  const withDuplicatedInMain = getErrorsByLanguages.some(
    (item: LanguageType) => item === languages[0],
  );

  const withDuplicated = hasDuplicateAttributes(attributes);

  return {
    withDuplicateAttributesMainLanguage: withDuplicatedInMain,
    withDuplicateAttributesSecondaryLanguage:
      withDuplicated && !withDuplicatedInMain,
  };
}

export default useDuplicateAttributes;
