import { ActivityLogAuthorTypes } from '@tiendanube/common/src/domains/store-activity-logs/enums';
import { CouponActivityLogDto } from 'domains/Marketing/Coupons/couponHistorySlice';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export const useParseCouponLogAuthor = () => {
  const t = useTranslationMarketing('coupons.history');
  const withBy = (author: string) => `${t('by')} ${author}`;

  const withSource = (author: string, source: string) =>
    `${withBy(author)} ${source}`;

  const parseCouponLogAuthor = (event: CouponActivityLogDto) => {
    if (!event.author.name) return withBy(event.author.email ?? '');

    if (event.author.type === ActivityLogAuthorTypes.APP)
      return withSource(event.author.name, `(${t('application')})`);

    if (event.author.isEmployee)
      return withSource(event.author.name.split(' ')[0], t('fromNuvemshop'));

    return withBy(event.author.name);
  };

  return { parseCouponLogAuthor };
};
