import { useMemo } from 'react';
import { Title } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { FreeShippingDeliveryMethodsDto } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import {
  ErrorScreen,
  InteractiveListRightCheckbox,
  Repeater,
} from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { useGetFreeShippingsOptionsQuery } from '../freeShippingApi';

interface DeliveryMethodsCheckedInterface
  extends FreeShippingDeliveryMethodsDto {
  checked: boolean;
}

interface DeliveryMethodsModalAsideProps {
  isOpen: boolean;
  selected: FreeShippingDeliveryMethodsDto[];
  onChange: (methods: FreeShippingDeliveryMethodsDto[]) => void;
  onClose: () => void;
}

const skeleton = () => <InteractiveListRightCheckbox.Skeleton />;

export function DeliveryMethodsModalAside({
  isOpen,
  selected,
  onChange,
  onClose,
}: Readonly<DeliveryMethodsModalAsideProps>) {
  const t = useTranslationMarketing('freeShipping.asides.deliveryMethods');
  const {
    data: methodsStore,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetFreeShippingsOptionsQuery();

  const dataSelected: DeliveryMethodsCheckedInterface[] = useMemo(
    () =>
      (methodsStore || []).map((currentData) => ({
        ...currentData,
        checked: selected.some(
          (current) =>
            current.idMethod === currentData.idMethod &&
            current.idOption === currentData.idOption,
        ),
      })),
    [methodsStore, selected],
  );

  const handleCheckedMethod = (
    method: FreeShippingDeliveryMethodsDto,
    checked: boolean,
  ) => {
    if (checked) {
      onChange([...selected, method]);
    } else {
      onChange(
        selected.filter(
          (current) =>
            !(
              current.idMethod === method.idMethod &&
              current.idOption === method.idOption
            ),
        ),
      );
    }
  };

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={<Title>{t('title')}</Title>}
    >
      {isError && <ErrorScreen onRetry={refetch} />}
      {!isError && (
        <InteractiveList>
          {isLoading && <Repeater times={10} item={skeleton} />}
          {isSuccess &&
            dataSelected.map((method, index) => {
              const { idMethod, idOption, description, checked } = method;
              const handleChange = () => {
                handleCheckedMethod(
                  { idMethod, idOption, description },
                  !checked,
                );
              };
              return (
                <InteractiveListRightCheckbox
                  key={`${idMethod}-${idOption}`}
                  title={method.description}
                  checkbox={{
                    name: `method_${index}`,
                    checked: method.checked,
                    onChange: handleChange,
                  }}
                />
              );
            })}
        </InteractiveList>
      )}
    </ModalAside>
  );
}
