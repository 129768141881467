import { useGetTags } from 'domains/Auth/hooks';

interface ShowByTagProps {
  children: JSX.Element;
  tag: string | string[];
}

function ShowByTag({ tag, children }: ShowByTagProps) {
  const tags = useGetTags();

  function checkIfContainsAllTags(currentTags: string[]): boolean {
    return currentTags.every((element) => tags.includes(element));
  }

  const hasTag =
    typeof tag === 'string' ? tags.includes(tag) : checkIfContainsAllTags(tag);

  if (!hasTag) return null;

  return children;
}

export default ShowByTag;
