import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface DeleteUserModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
}

function DeleteUserModal({
  isOpen,
  isLoading,
  onClose,
  onDelete,
}: Readonly<DeleteUserModalProps>) {
  const t = useTranslationConfigurations('usersListPage.deleteModal');

  return (
    <Modal open={isOpen} onDismiss={onClose}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Text>{t('info')}</Text>
      </Modal.Body>
      <CancelAndConfirmButtons
        onCancel={onClose}
        onConfirm={onDelete}
        confirmText={t('confirm')}
        appearance="danger"
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default DeleteUserModal;
