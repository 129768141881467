import { ReactNode, useEffect, useState } from 'react';
import { BaseCardCollapsible } from 'commons/components';
import {
  ErrorState,
  Skeleton,
} from 'domains/Metafields/components/CardMetafieldsList/components';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

import './CardMetafields.scss';

interface CardMetafieldsProps {
  length?: number;
  children: ReactNode;
}
function CardMetafields({ length, children }: CardMetafieldsProps) {
  const [autoOpen, setAutoOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const t = useTranslationsMetafields();

  const handleClickToogle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (length && length < 4) {
      setAutoOpen(true);
    }
  }, [autoOpen, length]);

  useEffect(() => {
    if (autoOpen) {
      setOpen(true);
    }
  }, [autoOpen]);

  return (
    <div className="stratus--card-metafields">
      <BaseCardCollapsible
        active={open}
        title={t('title')}
        titleSize="h4"
        paddingBody="none"
        onChange={handleClickToogle}
      >
        {children}
      </BaseCardCollapsible>
    </div>
  );
}
CardMetafields.ErrorState = ErrorState;
CardMetafields.Skeleton = Skeleton;

export default CardMetafields;
