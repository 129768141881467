import { Country } from '@tiendanube/common/src/enums';
import HelpLink from 'App/HelpLink';
import {
  CancelAndSaveButtons,
  HeaderContent,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { AuthenticationWithoutConfirmationModal } from 'domains/AuthenticationFactor';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  NuvempagoInitArgentineCard,
  NuvempagoInitBrazilianCard,
  NuvempagoInitSettingsPageSkeleton,
} from './components';
import { canCountryLink } from './constants';
import useNuvempagoInitSettingsPage from './useNuvempagoInitSettingsPage';

const TRANSLATE_PREFIX = 'nuvempagoSettings';

function NuvempagoInitSettingsPage() {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const {
    authenticationFactor,
    closeAuthenticationFactor,
    paymentProvider,
    country,
    errors,
    handleOnChange,
    handleOnSubmit,
    isLoading,
    updateLoading,
    values,
    handleConfirmAction,
  } = useNuvempagoInitSettingsPage();

  if (isLoading) return <NuvempagoInitSettingsPageSkeleton />;

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Stack column align="flex-start">
        {country === Country.BR && (
          <NuvempagoInitBrazilianCard
            onChange={handleOnChange}
            document={values.document}
            error={errors.document}
          />
        )}
        {country === Country.AR && (
          <NuvempagoInitArgentineCard
            onChange={handleOnChange}
            document={values.document}
            error={errors.document}
          />
        )}
        <Stack alignSelf="flex-end">
          <CancelAndSaveButtons
            onCancel={closeAuthenticationFactor}
            onSave={handleOnSubmit}
            saveText={t('enable')}
            isLoading={updateLoading}
          />
        </Stack>
        <Stack alignSelf="center">
          {paymentProvider && (
            <HelpLink
              previousValue=""
              title={t('helpLink.title')}
              currentViewTracking={t('helpLink.amplitudeName')}
              linkURL={canCountryLink[country] || canCountryLink.default}
              icon="both"
              appearance="primary"
              showInMobile
            />
          )}
        </Stack>
      </Stack>
      {authenticationFactor && (
        <AuthenticationWithoutConfirmationModal
          onConfirm={handleConfirmAction}
          onCancel={closeAuthenticationFactor}
          payload={authenticationFactor}
        />
      )}
    </IonPageStratus>
  );
}

export default NuvempagoInitSettingsPage;
