import { Link, Textarea } from '@nimbus-ds/components';
import { CardWithTitle, Stack } from 'commons/components';
import { SplitSection } from '../SplitSection';

export function Skeleton() {
  return (
    <CardWithTitle title="Otros Servicios">
      <Stack column spacing="loose" align="stretch">
        <SplitSection.Skeleton>
          <Textarea.Skeleton />
          <Link.Skeleton />
        </SplitSection.Skeleton>
        <SplitSection.Skeleton>
          <Textarea.Skeleton />
          <SplitSection.Skeleton>
            <Textarea.Skeleton />
            <Link.Skeleton />
          </SplitSection.Skeleton>
        </SplitSection.Skeleton>
      </Stack>
    </CardWithTitle>
  );
}
