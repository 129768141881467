import { Card, Text } from '@nimbus-ds/components';
import { ArrowRightIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { IconBox, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface EmailOriginAlertProps {
  isValidated: boolean;
  storeName: string;
  storeEmail: string;
}

export function EmailOriginAlert({
  isValidated,
  storeName,
  storeEmail,
}: Readonly<EmailOriginAlertProps>) {
  const t = useTranslationConfigurations('notifications.notificationsListPage');

  const emailOriginText = isValidated
    ? 'originEmailInfo.verified'
    : 'originEmailInfo.unverified';

  return (
    <Card padding="small">
      <Stack>
        <IconBox
          color="primary-interactive"
          backgroundColor="primary-surface"
          height="32px"
          width="32px"
          minWidth="32px"
        >
          <ArrowRightIcon size="small" />
        </IconBox>
        <Text>
          <Trans
            t={t}
            i18nKey={emailOriginText}
            values={{ storeName, storeEmail }}
            components={{
              bold: <strong />,
            }}
          />
        </Text>
      </Stack>
    </Card>
  );
}
