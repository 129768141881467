import { ShippingProvinceResponseDto } from '@tiendanube/common';

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export interface ShippingProvincesInterface {
  status: statusType;
  provinceList: ShippingProvinceResponseDto[];
}

export interface InterfaceShippingSchema {
  status: statusType;
  currentRequestID: string;
  provinces: ShippingProvincesInterface;
}
