import { Key } from 'react';
import {
  DataList,
  InterfaceNameValue,
  Stack,
  Text,
  InterfaceSelectOptionGroup,
} from '@tiendanube/components';
import { InfoCircleIcon, UploadIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import './ImportProductsAlterations.scss';
import {
  AlterationItem,
  BadColumnsAlert,
  ConfirmIgnoreModal,
  IgnoreAllLink,
} from './components';

interface ImportProductsAlterationsModalProps {
  badColumns: string[];
  matchColumns: Record<string, string>;
  columnOptions?: InterfaceSelectOptionGroup[];
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setMatchingColumns: ({ name, value }: InterfaceNameValue) => void;
  ignoreAllColumns: () => void;
  undoIgnoreAllColumns: () => void;
}

function ImportProductsAlterationsModal({
  badColumns,
  matchColumns,
  columnOptions,
  show,
  onClose,
  onConfirm,
  setMatchingColumns,
  ignoreAllColumns,
  undoIgnoreAllColumns,
}: ImportProductsAlterationsModalProps): JSX.Element {
  const t = useTranslationCatalog();
  const [showIgnoreModal, openIgnoreModal, closeIgnoreModal] = useModal();

  const handleVerification = () => {
    if (Object.values(matchColumns).includes('ignore')) openIgnoreModal();
    else onConfirm();
  };

  const handleOnConfirm = () => {
    closeIgnoreModal();
    onConfirm();
  };

  const columnItems = Object.keys(matchColumns);
  const totalColumnsQuantity = Object.keys(matchColumns).length;
  const ignoredColumnsQuantity = Object.keys(matchColumns).filter(
    (col) => matchColumns[col] === 'ignore',
  ).length;
  const allColumnsAreIgnored = totalColumnsQuantity === ignoredColumnsQuantity;

  return (
    <>
      <ModalAside
        isOpen={show}
        onDidDismiss={onClose}
        headerTop={
          <HeaderTop
            navigation={{
              onClick: onClose,
            }}
            mainAction={{
              children: t('products.importExportPage.alterations.confirm'),
              appearance: 'secondary',
              icon: UploadIcon,
              disabled: allColumnsAreIgnored,
              onClick: handleVerification,
            }}
          />
        }
        headerContent={
          <HeaderContent
            title={t('products.importExportPage.alterations.title')}
            subtitle={t('products.importExportPage.alterations.subtitle')}
          />
        }
      >
        <BadColumnsAlert badColumnsQuantity={badColumns.length} />
        <Stack column align="stretch">
          <Stack.Item>
            <Stack>
              <Stack.Item fill>
                <Text block size="caption">
                  <InfoCircleIcon />
                  {t(
                    'products.importExportPage.alterations.ignoredColumnsQuantity',
                    {
                      count: ignoredColumnsQuantity,
                      total: totalColumnsQuantity,
                    },
                  )}
                </Text>
              </Stack.Item>
              <IgnoreAllLink
                allColumnsAreIgnored={allColumnsAreIgnored}
                onClickIgnoreAll={ignoreAllColumns}
                onClickUndoIgnoreAll={undoIgnoreAllColumns}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <div className="stratus--import-products-alteratons-modal-data-list">
              <DataList>
                {columnItems.map((column: string, key: Key) => (
                  <DataList.Row key={`${column}-${key}`} id={`${key}`}>
                    <DataList.Cell width="fill">
                      <AlterationItem
                        columnName={column}
                        options={columnOptions}
                        value={matchColumns[column]}
                        isBadColumn={badColumns.includes(column)}
                        onChange={setMatchingColumns}
                      />
                    </DataList.Cell>
                  </DataList.Row>
                ))}
              </DataList>
            </div>
          </Stack.Item>
          <Stack.Item>
            <CancelAndSaveButtons
              saveText={t('products.importExportPage.alterations.confirm')}
              onSave={handleVerification}
              onCancel={onClose}
              isDisabled={allColumnsAreIgnored}
              icon={UploadIcon}
            />
          </Stack.Item>
        </Stack>
      </ModalAside>

      <ConfirmIgnoreModal
        show={showIgnoreModal}
        onClose={closeIgnoreModal}
        onConfirm={handleOnConfirm}
      />
    </>
  );
}

export default ImportProductsAlterationsModal;
