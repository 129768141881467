import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ZENDESK_LIVE_CHAT_WIDGET_TAG } from 'App/featuresFlags';
import { useModal } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import {
  SunshineConversations,
  WebWidgetLiveChat,
  openModal as SmoochOpenModal,
  statusIsOk as SmoochStatusIsOk,
  openWidget as WebWidgetOpen,
  verifyStatus as WebWidgetStatusIsOk,
} from '../ExternalServices';

const noop = () => null;

export interface InterfaceSupportChatContext {
  openChat: () => void;
  onReceivedMessage: (callback: () => void) => void;
  onClose: (callback: () => void) => void;
  onOpen: (callback: () => void) => void;
}

export interface InterfaceSupportChatContextProvider {
  children: ReactNode;
}

export const SupportChatContext = createContext<InterfaceSupportChatContext>({
  openChat: noop,
  onReceivedMessage: noop,
  onClose: noop,
  onOpen: noop,
});

function SupportChatContextProvider({
  children,
}: Readonly<InterfaceSupportChatContextProvider>) {
  const [chatIsDestroyed, setChatIsDestroyed] = useState(false);
  const [showChat, openChat, closeChat] = useModal();
  const tags = useGetTags();

  const onReceivedMessageCallback = useRef<(() => void) | null>(null);
  const onCloseCallback = useRef<(() => void) | null>(null);
  const onOpenCallback = useRef<(() => void) | null>(null);

  const onReceivedMessage = useCallback((callback: () => void) => {
    onReceivedMessageCallback.current = callback;
  }, []);

  const onClose = useCallback((callback: () => void) => {
    onCloseCallback.current = callback;
  }, []);

  const onOpen = useCallback((callback: () => void) => {
    onOpenCallback.current = callback;
  }, []);

  const getChatOpener = useCallback(() => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      WebWidgetOpen();
    } else {
      SmoochOpenModal();
    }
  }, [tags]);

  const getChatStatus = useCallback(() => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      return WebWidgetStatusIsOk();
    }
    return SmoochStatusIsOk();
  }, [tags]);

  const handleOpenChat = useCallback(() => {
    if (!chatIsDestroyed && getChatStatus()) {
      openChat();
      getChatOpener();
      onOpenCallback.current?.();
    }
  }, [chatIsDestroyed, getChatStatus, openChat, getChatOpener, onOpenCallback]);

  const handleChatDestroyed = () => {
    if (showChat) closeChat();
    setChatIsDestroyed(true);
  };

  const handleReceivedMessage = () => {
    if (onReceivedMessageCallback.current) {
      onReceivedMessageCallback.current();
    }
  };

  const handleCloseChat = () => {
    closeChat();
    if (onCloseCallback.current) {
      onCloseCallback.current();
    }
  };

  const getChatOnCondition = () => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      return <WebWidgetLiveChat />;
    }
    return (
      <SunshineConversations
        onCloseModal={handleCloseChat}
        onReady={handleReceivedMessage}
        onDestroy={handleChatDestroyed}
        onReceivedMessage={handleReceivedMessage}
      />
    );
  };

  const value = useMemo(
    () => ({
      openChat: handleOpenChat,
      onClose,
      onOpen,
      onReceivedMessage,
    }),
    [handleOpenChat, onClose, onOpen, onReceivedMessage],
  );

  return (
    <SupportChatContext.Provider value={value}>
      {children}
      {getChatOnCondition()}
    </SupportChatContext.Provider>
  );
}

export default SupportChatContextProvider;
