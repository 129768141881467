import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import { useEditProduct } from '../../EditProductPage/hooks';
import SectionProductsAndVariantsMetafields, {
  SectionProductsAndVariantsMetafieldsProps,
} from '../SectionProductsAndVariantsMetafields';

type SectionProductsAndVariantsMetafieldsExisting = {
  id: string;
} & Omit<
  SectionProductsAndVariantsMetafieldsProps,
  'showLoading' | 'showError' | 'showSuccess'
>;

export default function SectionVariantsMetafieldsExisting({
  id,
  ...rest
}: SectionProductsAndVariantsMetafieldsExisting) {
  const { status } = useGetProductsAndVariantsMetafields();
  const { status: editStatus } = useEditProduct(id);

  const showError = status.isError;
  const showLoading = status.isLoading || editStatus.isLoading;
  const showSuccess = status.isSuccess && editStatus.isSuccess;

  return (
    <SectionProductsAndVariantsMetafields
      {...rest}
      showError={showError}
      showLoading={showLoading}
      showSuccess={showSuccess}
    />
  );
}
