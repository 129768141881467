import { Box, Button, Link, Modal, Text, Title } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { FirstSaleType } from '@tiendanube/common';
import { DASHBOARD_TIPS_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import FirstSaleSrc from './images/first-sale-image.png';
import TestImageSrc from './images/test-image.png';

interface FirstSaleModalProps {
  onDismiss: () => void;
  isShow: boolean;
  saleType?: FirstSaleType;
}

const FIRST_SALE_MODAL_IMAGES = {
  'first-sale-test': TestImageSrc,
  'first-sale-real': FirstSaleSrc,
};

function FirstSaleModal({
  isShow,
  onDismiss,
  saleType = 'not-first-sale',
}: FirstSaleModalProps) {
  const t = useTranslationDashboard();
  const language = useTranslationLanguage();
  // we return null to avoid showing wrong translation keys in case they don't exist
  if (saleType === 'not-first-sale' || saleType === 'first-sale') return null;
  const link = DASHBOARD_TIPS_LINKS[language];
  const handleClick = () => {
    onDismiss();
  };

  return (
    <Modal onDismiss={onDismiss} open={isShow}>
      <Title as="h3">{t(`firstSaleAlert.modal.${saleType}.title`)}</Title>
      <Box display="flex" flexDirection="column" gap="4">
        <img src={FIRST_SALE_MODAL_IMAGES[saleType]} />
        <Text>{t(`firstSaleAlert.modal.${saleType}.description`)}</Text>
        <Box
          display="flex"
          flexDirection="row"
          gap="4"
          justifyContent="flex-end"
        >
          <Link onClick={onDismiss} appearance="primary" textDecoration="none">
            {t(`firstSaleAlert.modal.close`)}
          </Link>
          <Button
            as="a"
            href={link}
            target="_blank"
            appearance="primary"
            onClick={handleClick}
          >
            {t(`firstSaleAlert.modal.seeTips`)}
            <ExternalLinkIcon />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default FirstSaleModal;
