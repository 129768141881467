import { Badge, Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { Link } from 'react-router-dom';
import { Option } from '@tiendanube/common';
import { addOldAdminPrefix } from 'App/components/Menu/components/utils';
import { useHandleMenuClick, useNavegate, useSubPathname } from 'App/hooks';
import { getIcon } from '../../utils';

export interface MenuItemProps {
  option: Option;
}

function MenuItem({ option }: Readonly<MenuItemProps>) {
  const { pathname } = useNavegate();
  const { value } = useSubPathname();
  const { handleClickItem } = useHandleMenuClick();
  const startIcon = option.icon ? getIcon(option.icon) : undefined;
  const goTo = option.goTo ?? '';

  const [pathnameOption, hashOption] = option.goTo?.split('#') ?? [];
  const isActive = pathnameOption === pathname && hashOption === value;

  const tag = !!option.tag && <Tag appearance="primary">{option.tag}</Tag>;
  const badge = !!option.badge && (
    <Badge count={option.badge} appearance="primary" />
  );

  return option.oldAdmin ? (
    <MenuButton
      as="a"
      label={option.label}
      startIcon={startIcon}
      href={addOldAdminPrefix(goTo)}
    >
      {tag}
      {badge}
    </MenuButton>
  ) : (
    <MenuButton
      as={Link}
      label={option.label}
      startIcon={startIcon}
      to={goTo}
      active={isActive}
      onClick={handleClickItem}
    >
      {tag}
      {badge}
    </MenuButton>
  );
}

export default MenuItem;
