import { Label } from '@nimbus-ds/components';
import { Input, Stack } from '@tiendanube/components';
import { LanguagesType, LanguageInterface, DEFAULT_LANGUAGE } from 'App/i18n';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductDetailSEOBlur } from 'domains/Catalog/Products/tracking';
import SeoGenerationModal from '../SeoGenerationModal';

export type onChangeSeoType = (data: {
  name: string;
  value: LanguageInterface;
}) => void;

export interface FormSeoProps {
  language?: LanguagesType;
  name: LanguageInterface;
  description: LanguageInterface;
  seoTitle: LanguageInterface;
  seoDescription: LanguageInterface;
  onChangeSeo: onChangeSeoType;
  seoUrl: LanguageInterface;
  showAiModal: boolean;
  openAiModal: () => void;
  closeAiModal: () => void;
  showGenerateWithAi: boolean;
  isEdit?: boolean;
}

function FormSeo({
  language = DEFAULT_LANGUAGE,
  name,
  description,
  onChangeSeo,
  seoDescription,
  seoTitle,
  seoUrl,
  showAiModal,
  openAiModal,
  closeAiModal,
  showGenerateWithAi,
  isEdit,
}: Readonly<FormSeoProps>): JSX.Element {
  const t = useTranslationCatalog();
  const sender = useTrackFullCatalog();

  const product = {
    name: name[language],
    description: description[language],
    lang: language,
  };

  const handleUseContent = (
    generatedSeoTitle: string,
    generatedSeoDescription: string,
  ) => {
    onChangeSeo({
      name: 'seoTitle',
      value: { ...seoTitle, [language]: generatedSeoTitle },
    });
    onChangeSeo({
      name: 'seoDescription',
      value: { ...seoDescription, [language]: generatedSeoDescription },
    });
  };

  const handleOnChange = (data: { value: string; name: string }) => {
    const previousValue =
      data.name === 'seoTitle'
        ? seoTitle
        : data.name === 'seoUrl'
        ? seoUrl
        : seoDescription;

    onChangeSeo({
      name: data.name,
      value: {
        ...previousValue,
        [language]: data.value,
      },
    });
  };

  const handleBlur = () => {
    sender(() => {
      trackingProductDetailSEOBlur(isEdit);
    });
  };

  return (
    <>
      <Stack column align="stretch">
        <Stack column align="stretch" spacing="tight">
          <Stack spacing="tight">
            <Label htmlFor="input_seoTitle">
              {t('products.detail.seo.form.title')}
            </Label>
            {showGenerateWithAi && (
              <GenerateWithAiLink fontSize="caption" onClick={openAiModal} />
            )}
          </Stack>
          <Input
            type="text"
            placeholder={t('products.detail.seo.form.titlePlaceholder')}
            onChange={handleOnChange}
            name="seoTitle"
            value={seoTitle[language]}
            helpText={t('products.detail.seo.form.titleHelperText', {
              count: seoTitle[language]?.length || 0,
            })}
            maxLength={70}
            onBlur={handleBlur}
          />
        </Stack>
        <Stack column align="stretch" spacing="tight">
          <Stack spacing="tight">
            <Label htmlFor="input_seoDescription">
              {t('products.detail.seo.form.description')}
            </Label>
            {showGenerateWithAi && (
              <GenerateWithAiLink fontSize="caption" onClick={openAiModal} />
            )}
          </Stack>
          <Input
            type="text"
            placeholder={t('products.detail.seo.form.descriptionPlaceholder')}
            onChange={handleOnChange}
            name="seoDescription"
            value={seoDescription[language]}
            rows={3}
            multiRows
            maxLength={320}
            helpText={t('products.detail.seo.form.descriptionHelperText', {
              count: seoDescription[language]?.length || 0,
            })}
          />
        </Stack>
        <Stack.Item>
          <Input
            type="text"
            label={t('products.detail.seo.form.url')}
            placeholder=""
            onChange={handleOnChange}
            name="seoUrl"
            value={seoUrl[language]}
            maxLength={156}
            prependLabel={t('products.detail.seo.form.urlPrependLabel')}
          />
        </Stack.Item>
      </Stack>
      <SeoGenerationModal
        show={showAiModal}
        onDismiss={closeAiModal}
        onUseContent={handleUseContent}
        product={product}
      />
    </>
  );
}

export default FormSeo;
