import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { logEvent } from 'commons/utils/tracking';
import {
  CLICK_ACTIVATE_NUVEM_PAGO_PAYMENT_METHODS,
  SUCCESS_ACTIVATION_NUVEM_PAGO,
  CLICK_ACTIVATE_NUVEM_PAGO_MODAL_DOCUMENT,
  PAGO_NUBE_ONBOARDING_MODAL_SEE_SETTINGS_CLICK,
  NUVEMPAGO_ONBOARDING_MODAL_SEE_SETTINGS_CLICK,
  PAYMENT_MODAL_FREEMIUM_REMOVE_NUVEMPAGO_REMOVE_CLICK,
  PAYMENT_MODAL_NEXT_REMOVE_NUVEMPAGO_REMOVE_CLICK,
  PAYMENT_MODAL_OTHERSPLANS_REMOVE_NUVEMPAGO_REMOVE_CLICK,
  PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_ACCOUNT_CLICK,
  PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_EDIT_CLICK,
  PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_DEADLINE_FEES_CLICK,
  PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_REMOVE_CLICK,
  PAYMENT_INTEGRATION_REMOVE_CLICK,
  PAYMENT_INTEGRATION_START,
  PAYMENT_METHOD_SELECT,
  PAYMENT_ADD_CONFIRM,
  PAYMENTS_GATEWAY_SEE_SITE_CLICK,
  PAYMENT_CUSTOM_CONFIGURE,
  PAYMENT_CUSTOM_EDIT,
  PAYMENT_INTEGRATION_EDIT_CLICK,
  PAYMENT_MP_SYNC_CLICK,
  NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_ACTIVATE_CLICK,
  PAYMENTS_METHODS_MODAL_NUVEMPAGO_DEACTIVATE_CLICK,
  PAYMENTS_METHODS_NUVEMPAGO_ACTIVATE_CLICK,
  PAYMENTS_METHODS_NUVEMPAGO_DEACTIVATE_CLICK,
  NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_CAN_CLICK,
} from 'config/trackingEvents';

// PAGO NUBE EVENTS

const clickActivateNuvemPagoPaymentMethods = () => {
  logEvent(CLICK_ACTIVATE_NUVEM_PAGO_PAYMENT_METHODS, {});
};
const clickActivateNuvemPagoModalDocument = () => {
  logEvent(CLICK_ACTIVATE_NUVEM_PAGO_MODAL_DOCUMENT, {});
};
const successActivationNuvemPago = () => {
  logEvent(SUCCESS_ACTIVATION_NUVEM_PAGO, {});
};
const pagoNubeOnboardingModalSeeSettingsClick = () => {
  logEvent(PAGO_NUBE_ONBOARDING_MODAL_SEE_SETTINGS_CLICK, {});
};

// NUVEM PAGO EVENTS MENU

const paymentNuvemPagoPaymentsMenuEditClick = () => {
  logEvent(PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_EDIT_CLICK, {});
};
const paymentNuvemPagoPaymentsMenuRemoveClick = () => {
  logEvent(PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_REMOVE_CLICK, {});
};
const paymentNuvemPagoPaymentsMenuDeadlineFeesClick = () => {
  logEvent(PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_DEADLINE_FEES_CLICK, {});
};

const paymentNuvemPagoPaymentsMenuAccountClick = () => {
  logEvent(PAYMENT_NUVEM_PAGO_PAYMENTS_MENU_ACCOUNT_CLICK, {});
};

// NUVEM PAGO EVENTS DISABLED

const paymentModalOthersplanRemoveNuvempagoRemoveClick = () => {
  logEvent(PAYMENT_MODAL_OTHERSPLANS_REMOVE_NUVEMPAGO_REMOVE_CLICK, {});
};
const paymentModalNextRemoveNuvempagoRemoveClick = () => {
  logEvent(PAYMENT_MODAL_NEXT_REMOVE_NUVEMPAGO_REMOVE_CLICK, {});
};
const paymentModalFreemiumRemoveNuvempagoRemoveClick = () => {
  logEvent(PAYMENT_MODAL_FREEMIUM_REMOVE_NUVEMPAGO_REMOVE_CLICK, {});
};

export const paymentsMethodsNuvempagoDeactivateClick = () => {
  logEvent(PAYMENTS_METHODS_NUVEMPAGO_DEACTIVATE_CLICK, {});
};

export const paymentMethodsModalNuvempagoDeactivateClick = () => {
  logEvent(PAYMENTS_METHODS_MODAL_NUVEMPAGO_DEACTIVATE_CLICK, {});
};

// NUVEM PAGO EVENTS ENABLED
export const paymentsMethodsNuvempagoActivateClick = () => {
  logEvent(PAYMENTS_METHODS_NUVEMPAGO_ACTIVATE_CLICK, {});
};

export const nuvempagoManualActivationRegistrationActivateClick = () => {
  logEvent(NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_ACTIVATE_CLICK, {});
};

export const nuvempagoManualActivationRegistrationCanClick = () => {
  logEvent(NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_CAN_CLICK, {});
};

// NUVEM PAGO EVENTS ONBOARDING
export const nuvemPagoOnboardingModalSeeSettingsClick = () => {
  logEvent(NUVEMPAGO_ONBOARDING_MODAL_SEE_SETTINGS_CLICK, {});
};

// GENERIC EVENTS

const paymenGenericPaymentsMenuRemoveClick = (gateway) => {
  logEvent(PAYMENT_INTEGRATION_REMOVE_CLICK, { gateway });
};
const paymenGenericPaymentsMenuEnableClick = (gateway) => {
  logEvent(PAYMENT_INTEGRATION_START, { gateway });
};
const clickActivateGenericPaymentMethods = (gateway) => {
  logEvent(PAYMENT_METHOD_SELECT, { gateway });
};
const clickActivateCustomPaymentsPaymentMethods = () => {
  logEvent(PAYMENT_CUSTOM_CONFIGURE, {});
};
const paymenGenericUpdatedProvider = (gateway) => {
  logEvent(PAYMENT_ADD_CONFIRM, { gateway });
};
const goToOfficialSiteGenericPaymenProvider = (gateway) => {
  logEvent(PAYMENTS_GATEWAY_SEE_SITE_CLICK, { gateway });
};
const customPaymentsMenuEditClick = () => {
  logEvent(PAYMENT_CUSTOM_EDIT, {});
};
const genericPaymentsMenuEditClick = (gateway) => {
  logEvent(PAYMENT_INTEGRATION_EDIT_CLICK, { gateway });
};
const updateInstallmentsMercadopagoPaymenProvider = (_, success) => {
  const status = {};
  if (typeof success === 'boolean') {
    status['success'] = success;
  }
  logEvent(PAYMENT_MP_SYNC_CLICK, status);
};

const { nuvemPago, pagoNube, customPayments, customPaymentsEn, mercadopago } =
  PaymentProvidersCode;

const GenericProvider = 'generic';

export type EVENTS_PAYMENTS_TYPE =
  | 'menuSettings'
  | 'menuDisabled'
  | 'menuEnable'
  | 'menuTermsAndRates'
  | 'menuAccount'
  | 'initInstall'
  | 'install'
  | 'uninstall'
  | 'installConfirm'
  | 'uninstallConfirm'
  | 'enabledAlert'
  | 'onBoardSettings'
  | 'updatedProvider'
  | 'goToOfficialSite'
  | 'updateInstallments'
  | 'about';

type RECORD_ID_EVENT_TYPE = Record<
  string,
  (gateway?: string, param?: any) => void
>;

const eventsPaymentsByTypeAndId: Record<
  EVENTS_PAYMENTS_TYPE,
  RECORD_ID_EVENT_TYPE
> = {
  menuSettings: {
    [nuvemPago]: paymentNuvemPagoPaymentsMenuEditClick,
    [customPayments]: customPaymentsMenuEditClick,
    [customPaymentsEn]: customPaymentsMenuEditClick,
    [GenericProvider]: genericPaymentsMenuEditClick,
  },
  menuDisabled: {
    [nuvemPago]: paymentNuvemPagoPaymentsMenuRemoveClick,
    [GenericProvider]: paymenGenericPaymentsMenuRemoveClick,
    [nuvemPago]: paymentsMethodsNuvempagoDeactivateClick,
  },
  menuEnable: {
    [GenericProvider]: paymenGenericPaymentsMenuEnableClick,
    [nuvemPago]: paymentsMethodsNuvempagoActivateClick,
  },
  menuTermsAndRates: {
    [nuvemPago]: paymentNuvemPagoPaymentsMenuDeadlineFeesClick,
  },
  menuAccount: {
    [nuvemPago]: paymentNuvemPagoPaymentsMenuAccountClick,
  },
  initInstall: {
    [pagoNube]: clickActivateNuvemPagoPaymentMethods,
    [customPayments]: clickActivateCustomPaymentsPaymentMethods,
    [GenericProvider]: clickActivateGenericPaymentMethods,
  },
  install: {
    [pagoNube]: clickActivateNuvemPagoModalDocument,
    [nuvemPago]: paymentsMethodsNuvempagoActivateClick,
  },
  uninstall: {
    [nuvemPago]: paymentsMethodsNuvempagoDeactivateClick,
  },
  installConfirm: {
    [nuvemPago]: nuvempagoManualActivationRegistrationActivateClick,
  },
  uninstallConfirm: {
    [nuvemPago]: paymentMethodsModalNuvempagoDeactivateClick,
  },
  enabledAlert: {
    [pagoNube]: successActivationNuvemPago,
  },
  onBoardSettings: {
    [nuvemPago]: nuvemPagoOnboardingModalSeeSettingsClick,
    [pagoNube]: pagoNubeOnboardingModalSeeSettingsClick,
  },
  updatedProvider: {
    [GenericProvider]: paymenGenericUpdatedProvider,
  },
  goToOfficialSite: {
    [GenericProvider]: goToOfficialSiteGenericPaymenProvider,
  },
  updateInstallments: {
    [mercadopago]: updateInstallmentsMercadopagoPaymenProvider,
  },
  about: {
    [nuvemPago]: nuvempagoManualActivationRegistrationCanClick,
  },
};

export const invokeEventPayments = (
  type: EVENTS_PAYMENTS_TYPE,
  id: string,
  params?: any,
) => {
  const eventType = eventsPaymentsByTypeAndId[type];
  const event = eventType[id] || eventType[GenericProvider];
  event?.(id, params);
};

type PLAN_TYPE = 'freemium' | 'others' | 'next';

const eventsNuvemPagoDisabled: Record<PLAN_TYPE, () => void> = {
  freemium: paymentModalFreemiumRemoveNuvempagoRemoveClick,
  others: paymentModalOthersplanRemoveNuvempagoRemoveClick,
  next: paymentModalNextRemoveNuvempagoRemoveClick,
};

export const invokeEventNuvemPagoDisabled = (type: PLAN_TYPE) => {
  const event = eventsNuvemPagoDisabled[type];
  event?.();
};
