import { useContext } from 'react';
import {
  UpsellFlowModalContext,
  InterfaceUpsellFlowModalContext,
} from 'App/components/UpsellFlowModalContext';

function useUpsellFlowModal(): InterfaceUpsellFlowModalContext {
  return useContext(UpsellFlowModalContext);
}

export default useUpsellFlowModal;
