import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { List, Text } from '@tiendanube/components';

function GuideAndroid(): JSX.Element {
  const { t } = useTranslation([Domain.NUBEKEYBOARD]);

  return (
    <List>
      <List.Item>
        <Text>
          {`${t(`install.android.steps.1.regular`)}`}{' '}
          <Text.Span bold>{`${t('install.android.steps.1.bold')}`}</Text.Span>{' '}
          {`${t('install.android.steps.1.regular2')}`}
        </Text>
      </List.Item>
      <List.Item>
        <Text>
          {`${t(`install.android.steps.2.regular`)}`}{' '}
          <Text.Span bold>{`${t('install.android.steps.2.bold')}`}</Text.Span>
        </Text>
      </List.Item>
      <List.Item>
        <Text>
          {`${t(`install.android.steps.3.regular`)}`}{' '}
          <Text.Span bold>{`${t('install.android.steps.3.bold')}`}</Text.Span>
        </Text>
      </List.Item>
      <List.Item>
        <Text>
          {`${t(`install.android.steps.4.regular`)}`}{' '}
          <Text.Span bold>{`${t('install.android.steps.4.bold')}`}</Text.Span>
        </Text>
      </List.Item>
      <List.Item>
        <Text.Span bold>{`${t(`install.android.steps.5`)}`}</Text.Span>
      </List.Item>
      <List.Item>
        <Text>
          {`${t(`install.android.steps.6.regular`)}`}{' '}
          <Text.Span bold>{`${t('install.android.steps.6.bold')}`}</Text.Span>
        </Text>
      </List.Item>
      <List.Item>
        <Text>{`${t(`install.android.steps.7`)}`}</Text>
      </List.Item>
    </List>
  );
}

export default memo(GuideAndroid);
