import { Fragment } from 'react';

function NewLineToBr({ text }: { text: string }): JSX.Element {
  return (
    <>
      {text.split('\n').map((item, key) => (
        <Fragment key={key}>
          {item}
          <br />
        </Fragment>
      ))}
    </>
  );
}

export default NewLineToBr;
