import { useContext } from 'react';
import { NavTabsContext, InterfaceNavTabsContext } from './NavTabsProvider';

function useNavTabs(): InterfaceNavTabsContext {
  const {
    isShowNavTabs,
    showNavTabs,
    hideNavTabs,
    isShowSideBar,
    hideSideBar,
    showSideBar,
    toggleSideBar,
  } = useContext(NavTabsContext);

  return {
    isShowNavTabs,
    showNavTabs,
    hideNavTabs,
    isShowSideBar,
    hideSideBar,
    showSideBar,
    toggleSideBar,
  };
}

export default useNavTabs;
