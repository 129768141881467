import { Alert, Box, Checkbox, Title } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { CardWithTitle } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SettingsCheckoutFormProps } from '../../types';

export function CustomerInformationCard({
  values: {
    checkoutMandatoryBillingInfo,
    checkoutMandatoryContactPhone,
    checkoutMandatoryCpfCnpj,
    checkoutMandatoryCpfCnpjEnable,
    checkoutShowInvoice,
  },
  onChange,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.customerInformationCard',
  );

  const country = useGetCountry();

  const handleChange = ({ target: { name, checked } }) => {
    onChange({ name, value: checked });
  };

  const getDniCheckbox = () => {
    if (country === Country.BR) return null;
    return (
      <>
        <Checkbox
          disabled={checkoutMandatoryCpfCnpjEnable}
          onChange={handleChange}
          checked={checkoutMandatoryCpfCnpj}
          name="checkoutMandatoryCpfCnpj"
          label={t('checkoutMandatoryCpfCnpj')}
        />
        <Alert show={checkoutMandatoryCpfCnpjEnable} appearance="warning">
          {t('alert')}
        </Alert>
      </>
    );
  };

  const getCheckoutShowInvoice = () => {
    if (checkoutShowInvoice === undefined) return null;

    return (
      <Checkbox
        onChange={handleChange}
        checked={checkoutShowInvoice}
        name="checkoutShowInvoice"
        label={t('checkoutShowInvoice')}
      />
    );
  };

  return (
    <CardWithTitle title={t('title')}>
      <Box display="flex" flexDirection="column" gap="2">
        <Title fontSize="h5">{t('titlePhone')}</Title>
        <Checkbox
          onChange={handleChange}
          checked={checkoutMandatoryContactPhone}
          name="checkoutMandatoryContactPhone"
          label={t('checkoutMandatoryContactPhone')}
        />
        <Title fontSize="h5">{t('titleBillingInfo')}</Title>
        {getDniCheckbox()}
        <Checkbox
          onChange={handleChange}
          checked={checkoutMandatoryBillingInfo}
          name="checkoutMandatoryBillingInfo"
          label={t('checkoutMandatoryBillingInfo')}
        />
        {getCheckoutShowInvoice()}
      </Box>
    </CardWithTitle>
  );
}
