import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { PromotedThemeInfoResponseDto } from '@tiendanube/common';

const PROMOTIONAL_INFO: Record<
  string,
  (language: string) => PromotedThemeInfoResponseDto
> = {
  patagonia: (language) => ({
    name: 'Patagonia',
    screenshotDesktop: `https://d2r9epyceweg5n.cloudfront.net/assets/screenshots/${language}/full/patagonia-Clothing.jpg`,
    screenshotMobile: `https://d2r9epyceweg5n.cloudfront.net/assets/screenshots/${language}/mobile/patagonia-Clothing.jpg`,
  }),
};

// temporary hardcode promoted theme info until monolith PR is deployed
// https://tiendanube.atlassian.net/browse/LIG-589
// https://github.com/TiendaNube/tiendanube/pull/30024
export const useGetPromotedThemeInfoQuery = (
  themeCode: string,
  language: string,
) => {
  const locale = language.toLowerCase().replace('-', '_');

  if (!PROMOTIONAL_INFO[themeCode]) {
    return {
      status: 'rejected' as QueryStatus,
    };
  } else {
    return {
      data: PROMOTIONAL_INFO[themeCode](locale),
      status: 'fulfilled' as QueryStatus,
    };
  }
};
