enum Action {
  FULFILL = "fulfill",
  PACK = "pack",
  ARCHIVE = "archive",
  PAY = "pay",
  PRINT_RECEIPT = "printReceipt",
  PRINT_PACKING_LABEL = "printPackingLabel",
  PRINT_CONTENT_DECLARATION = "printContentDeclaration",
  CANCEL = "cancel",
  ASSIGN_METAFIELDS = "assignMetafields",
}

export default Action;
