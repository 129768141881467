import {
  Status as StatusEnum,
  Gateway as GatewayEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import {
  CreditCardIcon,
  CashIcon,
  BoxUnpackedIcon,
  TruckIcon,
  ArchiveIcon,
  ExclamationTriangleIcon,
  IconProps,
  MailIcon,
} from '@tiendanube/icons';
import { AppearanceColorEnum } from 'commons/enums';
import getIcon from 'domains/Orders/Orders/pages/getIcon';

interface InterfaceStatus {
  icon: React.FC<IconProps>;
  status: string;
  appearance: AppearanceColorEnum;
}

const fallback = (status: string): InterfaceStatus => ({
  icon: ExclamationTriangleIcon,
  status,
  appearance: AppearanceColorEnum.SECONDARY,
});

export const payment = (
  status: StatusEnum,
  gateway: GatewayEnum,
): InterfaceStatus => {
  const icon =
    gateway === GatewayEnum.NOT_PROVIDED || gateway === GatewayEnum.OFFLINE
      ? CashIcon
      : CreditCardIcon;

  switch (status) {
    case StatusEnum.PENDING:
      return {
        icon,
        status: StatusEnum.PENDING,
        appearance: AppearanceColorEnum.WARNING,
      };
    case StatusEnum.PARTIALLY_PAID:
      return {
        icon,
        status: StatusEnum.PARTIALLY_PAID,
        appearance: AppearanceColorEnum.WARNING,
      };
    case StatusEnum.PAID:
      return {
        icon,
        status: StatusEnum.PAID,
        appearance: AppearanceColorEnum.SUCCESS,
      };
    case StatusEnum.VOIDED:
    case StatusEnum.WARNIG_TRANSACTIONS:
      return {
        icon,
        status: StatusEnum.VOIDED,
        appearance: AppearanceColorEnum.DANGER,
      };
    default:
      return fallback(status);
  }
};

export const fulfillment = (
  status: StatusEnum,
  fulfillmentPreferenceType: FulfillmentPreferenceTypeEnum,
): InterfaceStatus => {
  const icon = getIcon(status, fulfillmentPreferenceType);

  const isDeliveryDigital =
    fulfillmentPreferenceType ===
    FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL;

  const isNonShippable =
    fulfillmentPreferenceType === FulfillmentPreferenceTypeEnum.NON_SHIPPABLE;

  switch (status) {
    case StatusEnum.UNPACKED:
      return {
        icon: isDeliveryDigital || isNonShippable ? MailIcon : BoxUnpackedIcon,
        status:
          isDeliveryDigital || isNonShippable
            ? StatusEnum.UNPACKED_DIGITAL
            : status,
        appearance:
          isDeliveryDigital || isNonShippable
            ? AppearanceColorEnum.PRIMARY
            : AppearanceColorEnum.SECONDARY,
      };
    case StatusEnum.READY_FOR_PICKUP:
    case StatusEnum.PACKED:
    case StatusEnum.UNFULFILLED:
    case StatusEnum.UNSHIPPED:
      return {
        icon,
        status:
          fulfillmentPreferenceType === FulfillmentPreferenceTypeEnum.PICKUP
            ? StatusEnum.PICKUP
            : StatusEnum.UNSHIPPED,
        appearance: AppearanceColorEnum.PRIMARY,
      };
    case StatusEnum.DISPATCHED:
    case StatusEnum.MARKED_AS_FULFILLED:
    case StatusEnum.FULFILLED:
    case StatusEnum.SHIPPED: {
      const shippedStatus =
        fulfillmentPreferenceType === FulfillmentPreferenceTypeEnum.PICKUP
          ? StatusEnum.PICKUPED
          : StatusEnum.SHIPPED;

      return {
        icon: isDeliveryDigital || isNonShippable ? MailIcon : icon,
        status:
          isDeliveryDigital || isNonShippable
            ? StatusEnum.SHIPPED_DIGITAL
            : shippedStatus,
        appearance: AppearanceColorEnum.SUCCESS,
      };
    }
    case StatusEnum.PARTIALLY_FULFILLED:
      return {
        icon,
        status: StatusEnum.PARTIALLY_FULFILLED,
        appearance: AppearanceColorEnum.PRIMARY,
      };
    case StatusEnum.PARTIALLY_PACKED:
      return {
        icon: isDeliveryDigital ? TruckIcon : BoxUnpackedIcon,
        status: StatusEnum.PARTIALLY_PACKED,
        appearance: AppearanceColorEnum.DEFAULT,
      };
    case StatusEnum.DELIVERED:
      return {
        icon,
        status: StatusEnum.DELIVERED,
        appearance: AppearanceColorEnum.SUCCESS,
      };
    default:
      return fallback(status);
  }
};

export const order = (status: StatusEnum): InterfaceStatus => {
  switch (status) {
    case StatusEnum.CANCELLED:
      return {
        icon: ExclamationTriangleIcon,
        status: StatusEnum.CANCELLED,
        appearance: AppearanceColorEnum.DEFAULT,
      };
    case StatusEnum.ARCHIVED:
    case StatusEnum.CLOSED:
      return {
        icon: ArchiveIcon,
        status: StatusEnum.ARCHIVED,
        appearance: AppearanceColorEnum.DEFAULT,
      };
    default:
      return fallback(status);
  }
};
