import { OrderDetailsResponseDto, OrderResponseDto } from '@tiendanube/common';

export default function getOrderItemFromDetail(
  orderDetails: OrderDetailsResponseDto,
): OrderResponseDto {
  return {
    ...orderDetails,
    quantityProducts: orderDetails.products
      .map((p) => p.quantity)
      .reduce((acc, next) => acc + next, 0),
    quantityUniqueProducts: orderDetails.products.length,
    total: orderDetails.amounts.total,
    fulfillment: {
      ...orderDetails.fulfillment,
      preferenceType: orderDetails.fulfillment.preference.type,
    },
    fulfillmentOrders:
      orderDetails.fulfillmentOrders?.map((ffo) => ({
        id: ffo.id,
        location: {
          id: ffo.assignedLocation.id,
          name: ffo.assignedLocation.name,
        },
        number: ffo.number,
        shipping_info: {
          shipping_type: ffo.shipping.type,
          tracking_code: ffo.trackingInfo.code,
        },
        status: ffo.status,
      })) ?? [],
    orderOrigin: orderDetails?.orderOrigin || '',
  };
}
