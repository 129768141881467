import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Select,
  InterfaceSelectOptionGroup,
  InterfaceNameValue,
} from '@tiendanube/components';
import { SortByType } from 'domains/Catalog/Products/productsServices';

interface OnChangeValue {
  name: string;
  value: SortByType;
}

interface SortByProps {
  value: SortByType;
  onChange: ({ name, value }: OnChangeValue) => void;
}
function SortBy({ value, onChange }: SortByProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const options: SortByType[] = [
    'price-ascending',
    'price-descending',
    'alpha-ascending',
    'alpha-descending',
    'created-at-ascending',
    'created-at-descending',
    'best-selling',
    'user',
  ];

  const defaultValue = value || 'created-at-descending';

  const labelOptions: InterfaceSelectOptionGroup[] = options.map((value) => ({
    value,
    label: t(`products.filters.sortBy.${value}`),
  }));

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <Select
      label={t('products.filter.sortBy')}
      name="sortBy"
      value={defaultValue}
      options={labelOptions}
      onChange={handleChange}
    />
  );
}

export default SortBy;
