import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import useWindowSize from 'commons/hooks/useWindowSize';

interface UseResponsiveResult {
  isMobile: boolean;
  isDesktop: boolean;
}

function useResponsive(
  breakpoint: number = MOBILE_BREAKPOINT,
): UseResponsiveResult {
  const { width } = useWindowSize();
  const isMobile = width < breakpoint;
  return {
    isMobile,
    isDesktop: !isMobile,
  };
}

export default useResponsive;
