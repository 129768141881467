import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getShippingRevenueByProvinceData,
  getShippingRevenueByProvinceStatus,
} from 'domains/Statistics/selectors';
import { fetchShippingRevenueByProvince } from 'domains/Statistics/statisticsSlice';

function useGetRevenueByProvince() {
  const dispatch = useAppDispatch();
  const revenueByProvince = useSelector(getShippingRevenueByProvinceData);
  const status = useSelector(getShippingRevenueByProvinceStatus);

  const fetchRevenueByProvince = useCallback(() => {
    dispatch(fetchShippingRevenueByProvince());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchRevenueByProvince);

  return {
    fetchRevenueByProvince,
    revenueByProvince,
    ...status,
  };
}

export default useGetRevenueByProvince;
