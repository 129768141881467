import {
  CitiesResponseDto,
  ProvinceDto,
  ShippingAddressResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCountries = async () => {
  const { data } = await axios.get<Record<string, string>>(
    `v1/shipping/countries`,
  );
  return data;
};

const addressesBrazil = async (zipcode: string) => {
  const { data } = await axios.get<ShippingAddressResponseDto>(
    `v1/shipping/addresses/br/${zipcode}`,
  );
  return data;
};

const getProvinces = async (countryCode: string) => {
  const { data } = await axios.get<ProvinceDto[]>(
    `v1/shipping/provinces/${countryCode}`,
  );
  return data;
};

const getCities = async (countryCode: string, provinceCode: string) => {
  const { data } = await axios.get<CitiesResponseDto[]>(`v1/shipping/cities`, {
    params: { countryCode, provinceCode },
  });
  return data;
};

export default {
  getCountries,
  addressesBrazil,
  getProvinces,
  getCities,
};
