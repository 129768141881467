import { Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { AiIcon } from 'commons/components/GenerateWithAiLink';
import { useTranslationCommon } from 'commons/hooks';

function GeneratedWithAiText() {
  const t = useTranslationCommon();

  return (
    <Stack spacing="tight">
      <Stack.Item>
        <AiIcon />
      </Stack.Item>
      <Stack.Item>
        <Text fontSize="caption" fontWeight="medium" color="primary-textLow">
          {t('aiGeneration.generatedWithAi')}
        </Text>
      </Stack.Item>
    </Stack>
  );
}

export default GeneratedWithAiText;
