import * as Yup from 'yup';
import { PaymentProviderDto } from '@tiendanube/common';

export const getValidationSchema = (
  paymentProvider: PaymentProviderDto | undefined,
) => {
  if (!paymentProvider) return Yup.object().shape({});

  const minInstallmentValues = paymentProvider?.minInstallmentValues?.reduce(
    (values, { currency, defaultMinValue }) => ({
      ...values,
      [currency]: Yup.string().test('installment-is-valid', function (value) {
        if (!value || Number(value) <= 0) {
          return this.createError({
            message:
              'paymentProvider.settings.minInstallmentValueRequiredError',
            path: `installments[${currency}]`,
          });
        }
        if (Number(value) < defaultMinValue) {
          return this.createError({
            message: 'paymentProvider.settings.minDefaultInstallmentValueError',
            path: `installments[${currency}]`,
          });
        }

        return true;
      }),
    }),
    {},
  );

  const discounts = paymentProvider?.discounts?.reduce(
    (values, { paymentMethodType }) => ({
      ...values,
      [paymentMethodType]: Yup.string().test(
        'discount-is-valid',
        function (value) {
          if (Number(value) < 0 || Number(value) > 100) {
            return this.createError({
              message: 'paymentProvider.settings.discountValueError',
              path: `discounts[${paymentMethodType}]`,
            });
          }

          return true;
        },
      ),
    }),
    {},
  );

  return Yup.object().shape({
    minInstallmentValues: Yup.object().shape({
      ...minInstallmentValues,
    }),
    discounts: Yup.object().shape({
      ...discounts,
    }),
  });
};
