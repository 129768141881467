const ROUTE_BASE = '/apps';

export const PARTNER_APPS_ROUTES = {
  apps: ROUTE_BASE,
  appsDetail: `${ROUTE_BASE}/:id`,
  widgets: `${ROUTE_BASE}/widgets`,
  nuvempago: `${ROUTE_BASE}/nuvempago`,
  nuvempagoBr: `${ROUTE_BASE}/nuvempago-app-br`,
  nuvemenvio: `${ROUTE_BASE}/nuvemenvio`,
  envionubear: `${ROUTE_BASE}/envionube/ar`,
  envionubemx: `${ROUTE_BASE}/envionube/mx`,
  nuvemenvioBilling: `${ROUTE_BASE}/nuvemenvio/#/billing`,
  nuvemenvioConfigurations: `${ROUTE_BASE}/nuvemenvio/#/configurations`,
  testApplication: `${ROUTE_BASE}/test-application`,
  authorization: `${ROUTE_BASE}/:id/authorize`,
  payUAuthorization: `${ROUTE_BASE}/payu/authorization`,
};
