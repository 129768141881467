import { Card, Icon, Tag, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { GetAvailableMethodsResponseDto } from '@tiendanube/common';
import { Stack } from '@tiendanube/components';
import { MethodItem } from 'commons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping/useTranslationShipping';
import EnabledShippingMethodsSkeleton from './Skeleton';
import { getRegisterDateLabel } from '../../../../utils';
import ActiveShippingMethodsTag from '../../../ActiveShippingMethodsTag';
import IconsShippingMethodTag from '../../../ActiveShippingMethodsTag/IconsShippingMethodTag';

interface EnabledShippingMethodsProps {
  title: string;
  apps: GetAvailableMethodsResponseDto[];
  onChange: (id: string[]) => void;
}

function EnabledShippingMethods({
  title,
  apps,
  onChange,
}: Readonly<EnabledShippingMethodsProps>) {
  const t = useTranslationShipping();
  const { locations } = useLocations();

  return (
    <Card padding="none">
      <Card.Header title={title} padding="base" />
      <Card.Body>
        <Stack column>
          <InteractiveList bottomDivider={false}>
            {apps.map(
              ({
                appId,
                name,
                logo,
                ocaRegisterDate,
                tags,
                isMultiCDCompatible,
              }) => (
                <InteractiveList.ButtonItem
                  key={appId}
                  title=""
                  iconButton={{
                    onClick: () => onChange([appId]),
                  }}
                >
                  <MethodItem
                    name={name}
                    image={logo}
                    label={getRegisterDateLabel(ocaRegisterDate, t)}
                  >
                    {isMultiCDCompatible && locations.length > 1 && (
                      <Tag appearance="primary">
                        <Icon
                          source={
                            <IconsShippingMethodTag icon="store" size={12} />
                          }
                        />
                        <Text fontSize="caption">
                          {t(
                            'deliveryMethods.activeShippingMethods.modal.isMultiCDCompatible',
                          )}
                        </Text>
                      </Tag>
                    )}
                    {tags.map(({ icon, text }) => (
                      <ActiveShippingMethodsTag
                        key={text}
                        text={text}
                        icon={icon}
                      />
                    ))}
                  </MethodItem>
                </InteractiveList.ButtonItem>
              ),
            )}
          </InteractiveList>
        </Stack>
      </Card.Body>
    </Card>
  );
}

EnabledShippingMethods.Skeleton = EnabledShippingMethodsSkeleton;
export default EnabledShippingMethods;
