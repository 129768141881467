import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { SettingOptionsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { getSettingOptionsAction } from '../../paymentProvidersSlice';

function useGetSettingOptions(providerId: string) {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<StatusType>('idle');
  const [settings, setSettings] = useState<SettingOptionsResponseDto>();

  const getSettingOptions = useCallback(async () => {
    setStatus('loading');
    try {
      const data = await dispatch(getSettingOptionsAction(providerId));
      const settings = unwrapResult(data);
      setSettings(settings);
    } catch (error) {
      setStatus('error');
    } finally {
      setStatus('success');
    }
  }, [dispatch, providerId]);

  useEffect(() => {
    getSettingOptions();
  }, [getSettingOptions]);

  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
    countries: settings?.countries ?? [],
    currencies: settings?.currencies ?? [],
    installments: settings?.installments ?? [],
    integrationTypes: settings?.integrationTypes ?? [],
    paymentMethodsByCountry: settings?.paymentMethodsByCountry ?? {},
    taxes: settings?.taxes ?? [],
  };
}

export default useGetSettingOptions;
