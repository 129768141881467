import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getDraftOrdersList,
  getMoreDraftOrdersList,
  getDraftOrderPage,
} from '../../draftOrdersSlice';
import {
  getDraftOrdersIds,
  getDraftOrdersRefreshStatus,
  getDraftOrdersStatus,
  getDraftOrdersPagination,
} from '../../draftOrdersSlice/draftOrdersSelectors';

export interface useDrafOrdersResult {
  draftOdersList: string[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  isRefreshing: boolean;
  fetchDraftOrders: () => void;
  getMoreDraftOrders: () => void;
  goToPage: (page: number) => void;
  pagination: PaginationResponseDto;
}

function useDraftOrders(): useDrafOrdersResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(getDraftOrdersStatus);
  const { isRefreshing } = useSelector(getDraftOrdersRefreshStatus);
  const draftOdersList = useSelector(getDraftOrdersIds);
  const pagination = useSelector(getDraftOrdersPagination);

  const fetchDraftOrders = useCallback(
    () => dispatch(getDraftOrdersList()),
    [dispatch],
  );

  const getMoreDraftOrders = useCallback(
    () => dispatch(getMoreDraftOrdersList()),
    [dispatch],
  );

  const goToPage = useCallback(
    (page: number) => {
      dispatch(getDraftOrderPage(page));
    },
    [dispatch],
  );

  const isEmpty = draftOdersList.length === 0 && !isLoading && !isError;

  return {
    draftOdersList,
    isLoading,
    isSuccess,
    isError,
    fetchDraftOrders,
    isEmpty,
    getMoreDraftOrders,
    isRefreshing,
    pagination,
    goToPage,
  };
}

export default useDraftOrders;
