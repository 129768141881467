import { useState } from 'react';
import { Modal, Spinner, Text } from '@nimbus-ds/components';
import {
  OcaLabelSettingBoxOptionsDto,
  OcaCreateLabelRequestDto,
} from '@tiendanube/common';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DIMENSIONS_TYPES } from '../../constants/constants';
import useOcaCardsInfo from '../../hooks/useOcaCardsInfo';
import useOcaLabelsCostsPreviews from '../../hooks/useOcaLabelsCostsPreviews';
import {
  defineInitialMassiveValues,
  formatCost,
  generateMassiveLabelsByOneLabel,
  validateDimensions,
} from '../../utils/utils';
import OcaBoxOptionsForm from '../OcaBoxOptionsForm';
import OcaDispatchDataForm from '../OcaDispatchDataForm';

interface OcaLabelSettingMassiveModalProps {
  selectedOrders: string[];
  isOverwriting: boolean;
  closeModal: () => void;
  onChangeSelectedLabels: (
    newLabel: Omit<OcaCreateLabelRequestDto, 'externalId'>,
  ) => void;
}

function OcaLabelSettingMassiveModal({
  selectedOrders,
  isOverwriting,
  closeModal,
  onChangeSelectedLabels,
}: Readonly<OcaLabelSettingMassiveModalProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint');
  const { documentSettings } = useOcaCardsInfo();
  const [label, setLabel] = useState<
    Omit<OcaCreateLabelRequestDto, 'externalId'>
  >(defineInitialMassiveValues(documentSettings, isOverwriting));
  const [selectedTotalCost, setSelectedTotalCost] = useState<string>('--,--');
  const { generateCostsPreviews } = useOcaLabelsCostsPreviews();
  const [isLoadingCostsPreviews, setIsLoadingCostsPreviews] = useState(false);

  const handleOnChangeLabel = async (
    name: string,
    value: OcaLabelSettingBoxOptionsDto | string,
  ) => {
    const newLabel = DIMENSIONS_TYPES.includes(name)
      ? {
          ...label,
          boxOption: {
            ...label.boxOption,
            dimensions: {
              ...label.boxOption.dimensions,
              [name]: value,
            },
          },
        }
      : {
          ...label,
          [name]: value,
        };

    setLabel(newLabel);
    if (validateDimensions(newLabel.boxOption.dimensions)) {
      await generateTotalCostPreview(newLabel);
    }
  };

  const generateTotalCostPreview = async (
    newLabel: Omit<OcaCreateLabelRequestDto, 'externalId'>,
  ) => {
    const labels = generateMassiveLabelsByOneLabel(
      selectedOrders,
      newLabel,
      isOverwriting,
    );
    setIsLoadingCostsPreviews(true);
    await generateCostsPreviews(labels).then((costsPreviews) => {
      const totalLabelsCost = costsPreviews.reduce(
        (accumulator, currentItem) => accumulator + currentItem.cost.value,
        0,
      );
      setSelectedTotalCost(
        costsPreviews.length > 0 ? formatCost(totalLabelsCost) : '--,--',
      );
      setIsLoadingCostsPreviews(false);
    });
  };

  return (
    <Modal open onDismiss={() => closeModal()}>
      <Modal.Header title={t('modal.title')} />
      <Modal.Body padding="none">
        <Stack column align="stretch">
          <OcaDispatchDataForm
            documentSettings={documentSettings}
            label={label}
            onChange={handleOnChangeLabel}
          />
          <hr />
          <OcaBoxOptionsForm
            boxOptions={documentSettings.boxOptions}
            boxOption={label.boxOption}
            onChange={handleOnChangeLabel}
          />
          <Text>
            {t('modal.totalCost')}{' '}
            {!isLoadingCostsPreviews && <b>${selectedTotalCost}</b>}
            {isLoadingCostsPreviews && <Spinner size="small" />}
          </Text>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndConfirmButtons
          onCancel={() => closeModal()}
          onConfirm={() => {
            onChangeSelectedLabels(label);
            closeModal();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default OcaLabelSettingMassiveModal;
