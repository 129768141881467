import { useTranslation } from 'react-i18next';
import { CurrencyType, OrdersListProductDto } from '@tiendanube/common';
import { DataTable, Stack, Text } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { CurrencyPrice, ImageItemStratus } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import './ResultProductsDesktop.scss';
import { ResultProductsDesktopSkeleton } from './ResultProductsDesktopSkeleton';
import { cellSize } from './utils';

interface ResultProductsDesktopProps {
  show: boolean;
  products?: OrdersListProductDto[];
  quantityUniqueProducts: number;
  currency: CurrencyType;
  listLayout: 'orders' | 'draftOrders';
}

function ResultProductsDesktop({
  show,
  products,
  quantityUniqueProducts,
  currency,
  listLayout,
}: ResultProductsDesktopProps): JSX.Element | null {
  const {
    i18n: { language },
  } = useTranslation();
  const t = useTranslationOrders();
  const { goTo } = useNavegate();
  const getCellSize = cellSize(listLayout);

  if (!show) return null;

  const goToProduct = (productId: string) => {
    goTo(`/products/${productId}`);
  };

  return (
    <div className="stratus--orders-list-products">
      <DataTable>
        <DataTable.Header>
          <DataTable.Cell grow={1} basis={getCellSize('product')}>
            <Text>{t('resultProducts.product')}</Text>
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('quantity')}>
            <Text>{t('resultProducts.quantity')}</Text>
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('unitPrice')}>
            <Text>{t('resultProducts.unitPrice')}</Text>
          </DataTable.Cell>
          <DataTable.Cell grow={0} basis={getCellSize('total')}>
            <Text>{t('resultProducts.total')}</Text>
          </DataTable.Cell>
          {listLayout === 'orders' && <DataTable.Cell grow={0} basis={80} />}
        </DataTable.Header>
        {!products ? (
          <ResultProductsDesktopSkeleton
            listLayout={listLayout}
            quantityUniqueProducts={quantityUniqueProducts}
          />
        ) : (
          products.map((product) => (
            <DataTable.Row
              key={`product-${product.productId}`}
              id={`product-${product.productId}`}
              align="center"
            >
              <DataTable.Cell grow={1} basis={getCellSize('product')}>
                <ImageItemStratus
                  id={product.productId}
                  title={product.title}
                  trimTitle
                  imageUrl={product.photoUrl}
                  imageWidth="48px"
                  onClickTitle={() => goToProduct(product.productId)}
                  onClickImage={() => goToProduct(product.productId)}
                >
                  <Stack column align="flex-start" spacing="tight">
                    {!!product.variant && (
                      <Text block size="caption">
                        {product.variant}
                      </Text>
                    )}
                    {!!product.sku && (
                      <Text block size="caption">
                        {t('resultProducts.sku', { sku: product.sku })}
                      </Text>
                    )}
                    <Text block size="caption">
                      {t('resultProducts.quantityAndPrice', {
                        quantity: product.quantity,
                        price: formatCurrency(
                          product.price,
                          currency,
                          language,
                          false,
                        ),
                      })}
                    </Text>
                  </Stack>
                </ImageItemStratus>
              </DataTable.Cell>
              <DataTable.Cell grow={0} basis={getCellSize('quantity')}>
                <Text block>{product.quantity}</Text>
              </DataTable.Cell>
              <DataTable.Cell grow={0} basis={getCellSize('unitPrice')}>
                <CurrencyPrice price={product.price} currency={currency} />
              </DataTable.Cell>
              <DataTable.Cell grow={0} basis={getCellSize('total')}>
                <CurrencyPrice
                  price={product.quantity * product.price}
                  currency={currency}
                />
              </DataTable.Cell>
              {listLayout === 'orders' && (
                <DataTable.Cell grow={0} basis={80} />
              )}
            </DataTable.Row>
          ))
        )}
      </DataTable>
    </div>
  );
}

export default ResultProductsDesktop;
