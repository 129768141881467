import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, Text, Card, Link } from '@tiendanube/components';
import { GlobeIcon } from '@tiendanube/icons';
import {
  HeaderContent,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import GuideAndroid from '../../components/GuideAndroid';
import GuideIOs from '../../components/GuideIOs';

function NubeKeyboardPage() {
  const { t } = useTranslation([Domain.NUBEKEYBOARD]);
  const platform = Capacitor.getPlatform();

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Stack column align="flex-start">
        <Stack.Item>
          <Text appearance="secondary">{`${t('introduction.tagline')}`}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text>{`${t('introduction.text')}`}</Text>
        </Stack.Item>
      </Stack>
      <br />
      <Card title={`${t('install.title')}`} isCollapsible>
        <Stack column align="flex-start">
          <Stack.Item>
            {platform === 'ios' ? <GuideIOs /> : <GuideAndroid />}
          </Stack.Item>
          <Stack.Item>
            <Link
              target="_blank"
              href={t('install.helplink.url')}
              appearance="primary"
            >
              {t('install.helplink.text')}
            </Link>
          </Stack.Item>
        </Stack>
      </Card>
      <Card title={`${t('use.title')}`} isCollapsible>
        <Stack column align="flex-start">
          <Stack.Item>
            <Text>{`${t('use.text.1')}`}</Text>
          </Stack.Item>
          <Stack.Item>
            {platform === 'ios' ? (
              <img
                src="/assets/img/NubeKeyboard/iOS.jpg"
                alt="ios"
                width="100%"
              />
            ) : (
              <img
                src="/assets/img/NubeKeyboard/Android.jpg"
                alt="ios"
                width="100%"
              />
            )}
          </Stack.Item>
          <Stack.Item>
            <Text>
              {`${t('use.text.2.regular')}`}{' '}
              <Text.Span bold appearance="primary">
                {`${t('use.text.2.bold')}`} <GlobeIcon />{' '}
              </Text.Span>
              {`${t('use.text.2.regular2')}`}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {`${t('use.text.3.regular')}`}{' '}
              <Text.Span bold appearance="primary">
                {`${t('use.text.3.bold')}`}
              </Text.Span>{' '}
              {`${t('use.text.3.regular2')}`}
            </Text>
          </Stack.Item>
        </Stack>
      </Card>
    </IonPageStratus>
  );
}

export default NubeKeyboardPage;
