import { OnChangeValue, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { OptionType, colourStyles } from './styleConfig';

interface PredictMultiSelectProps {
  defaultValue: OptionType[];
  options: OptionType[];
  onChange: (
    newValue: OnChangeValue<OptionType, true>,
    action: ActionMeta<OptionType>,
  ) => void;
  optionMessage?: () => string;
  createText?: string;
}

function PredictMultiSelect({
  defaultValue,
  options,
  onChange,
  optionMessage,
  createText = '',
}: PredictMultiSelectProps) {
  return (
    <CreatableSelect
      isMulti
      options={options}
      styles={colourStyles}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder=""
      noOptionsMessage={optionMessage}
      formatCreateLabel={(value) => `${createText} ${value}`}
    />
  );
}
export default PredictMultiSelect;
