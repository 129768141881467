import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import TabsImportAndExportProducts from './components/TabsImportAndExportProducts';

function ImportAndExportProductsPage() {
  const t = useTranslationCatalog();
  const language = useTranslationLanguage();
  const { goBack } = useNavegate();

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('products.importExportPage.title')}
          subtitle={t('products.importExportPage.subtitle')}
        />
      }
      width="small"
    >
      <TabsImportAndExportProducts />
      <HelpLink
        title={t('products.importExportPage.helpLinkTitle')}
        previousValue=""
        currentViewTracking={t('products.importExportPage.helpLinkTracking')}
        linkURL={HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default ImportAndExportProductsPage;
