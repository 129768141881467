import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationDashboard(
  keyPrefix?: string,
): TFunction<Domain.DASHBOARD> {
  const { t } = useTranslation(Domain.DASHBOARD, { keyPrefix });

  return t;
}

export default useTranslationDashboard;
