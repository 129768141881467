import { Card, Label, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

export function CardPaymentSkeleton() {
  const t = useTranslationOrders();

  return (
    <Card title={t('cardPayment.paymentType')}>
      <Stack column align="flex-start">
        <Text.Skeleton width="large" />
        <Label.Skeleton />
        <Text.Skeleton width="large" />
      </Stack>
    </Card>
  );
}
