import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InstallAuthenticationFactorRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  authenticationFactorCodeData,
  authenticationFactorCodeStatus,
  installAuthenticationFactor as installAuthenticationFactorAction,
} from '../../accountSlice';

interface useInstallAuthenticationFactorResult {
  install: (payload: InstallAuthenticationFactorRequestDto) => Promise<boolean>;
  status: StatusType;
  code: string;
  isLoading: boolean;
  isError: boolean;
}

function useInstallAuthenticationFactor(): useInstallAuthenticationFactorResult {
  const { status } = useSelector(authenticationFactorCodeStatus);
  const { data } = useSelector(authenticationFactorCodeData);
  const dispatch = useAppDispatch();
  const install = useCallback(
    async (payload: InstallAuthenticationFactorRequestDto) => {
      const response = await dispatch(
        installAuthenticationFactorAction(payload),
      );
      return installAuthenticationFactorAction.rejected.type !== response.type;
    },
    [dispatch],
  );

  const code =
    data && 'secret' in data.authenticationFactor
      ? data.authenticationFactor.secret
      : '';

  return {
    install,
    status,
    isLoading: status === 'loading',
    code,
    isError: status === 'error',
  };
}

export default useInstallAuthenticationFactor;
