import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Text, Modal } from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useToast } from 'commons/hooks';

export interface ProductToCloneInterface {
  name: string;
  withImages: boolean;
}

interface ModalConfirmationRemoveProductProps {
  onClose: () => void;
  onRemoveProduct: () => Promise<void>;
  goBack?: () => void;
}

export const initialState: ProductToCloneInterface = {
  name: '',
  withImages: false,
};

function ModalConfirmationRemoveProduct({
  onRemoveProduct,
  onClose,
  goBack,
}: ModalConfirmationRemoveProductProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const handleRemoveProduct = async () => {
    onClose();
    addToastProgress({
      label: t('products.modalDelete.progress'),
    });
    try {
      await onRemoveProduct();
      closeToastProgress();
      addToast({
        label: t('products.modalDelete.success'),
        appearance: 'success',
      });
      goBack?.();
    } catch {
      closeToastProgress();
      addToast({
        label: t('products.modalDelete.error'),
        appearance: 'danger',
      });
    }
  };

  return (
    <Modal
      show
      title={t('products.modalDelete.title')}
      onDismiss={onClose}
      primaryAction={{
        children: `${t('products.modalDelete.ok')}`,
        onClick: handleRemoveProduct,
        appearance: 'danger',
      }}
      secondaryAction={{
        children: `${t('products.modalDelete.cancel')}`,
        onClick: onClose,
      }}
    >
      <Text>{t('products.modalDelete.body')}</Text>
    </Modal>
  );
}

export default ModalConfirmationRemoveProduct;
