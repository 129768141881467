import { ReactElement } from 'react';
import { useGetTags } from 'domains/Auth/hooks';

interface FeatureFlagProps {
  flag: string;
  renderElse?: ReactElement;
  children?: ReactElement | false;
}

function FeatureFlag({ flag, renderElse, children }: FeatureFlagProps) {
  const tags = useGetTags();
  const noMatchFlag = !tags.includes(flag);

  if (noMatchFlag) {
    return renderElse ? renderElse : null;
  }

  if (!children) return null;

  return children;
}

export default FeatureFlag;
