const V1_BASE_URL = 'v1/billing';
const BILLING_CHECKOUT_PAY = `${V1_BASE_URL}/checkout/pay`;

export const GET_SUBSCRIPTIONS = `${V1_BASE_URL}/subscriptions`;
export const GET_RECURRENT_PAYMENT_CONCEPTS = `${V1_BASE_URL}/recurrent-payments/concepts`;
export const GET_LAST_PAYMENT = `${V1_BASE_URL}/subscriptions/last-payment`;
export const GET_PENDING_CHARGES = `${V1_BASE_URL}/charges/pending`;
export const GET_CHARGE_DETAILS = `${V1_BASE_URL}/charges`;
export const DELETE_RECURRENT_PAYMENT = `${V1_BASE_URL}/delete-recurrent-payments`;
export const DELETE_SUBSCRIPTION = `${V1_BASE_URL}/subscriptions`;
export const GET_PAYMENT_METHODS = `${V1_BASE_URL}/plan-payment-options`;
export const GET_STORE_SUBSCRIPTION_ENABLED = `${V1_BASE_URL}/sub-enabled`;
export const GET_PAY_ORDER = `${V1_BASE_URL}/payorders`;
export const POST_CREATE_PAY_ORDER = `${V1_BASE_URL}/payorders`;
export const PATCH_PLAN_CHARGE = `${V1_BASE_URL}/plan/charge`;
export const POST_PAYMENT_CREDIT_CARD = `${BILLING_CHECKOUT_PAY}/credit-card`;
export const POST_CREATE_MP_PREFERENCE = `${BILLING_CHECKOUT_PAY}/mercado-pago/preferences`;
export const POST_UPLOAD_PAYMENT_PROOF = `${BILLING_CHECKOUT_PAY}/wire-transfer`;
export const GET_WIRE_TRANSFER_DATA = `${BILLING_CHECKOUT_PAY}/wire-transfer/data`;
export const POST_CREATE_ADVANCED_PLAN_CHARGE = `${V1_BASE_URL}/checkout/advance-plan-charge`;
export const GET_RECURRENT_PAYMENT_CHARGE_DATA = `${V1_BASE_URL}/checkout/recurrent-payment-charges`;
export const GET_LAST_PAID_CHARGES = `${V1_BASE_URL}/charges/last-paid-for-concepts`;
export const POST_ASSERT_STORE = `${V1_BASE_URL}/checkout/assert-store-existence`;
export const GET_PAYMENT_HISTORY_FOR_CONCEPT = `${V1_BASE_URL}/payments/history/:concept`;
export const GET_DOWNLOAD_INVOICE = `${V1_BASE_URL}/invoices/billing-engine/:invoiceId`;

export const checkoutEndpoints = {
  getSubscriptions: 'getSubscriptions',
  getStoreSubscriptionEnabled: 'getStoreSubscriptionEnabled',
  getRecurrentPaymentConcepts: 'getRecurrentPaymentConcepts',
  getPendingCharges: 'getPendingCharges',
  getPaymentMethods: 'getPaymentMethods',
  getWireTransferData: 'getWireTransferData',
  getPayOrderToPay: 'getPayOrderToPay',
  getLastPaymentData: 'getLastPaymentData',
  deleteRecurrentPayment: 'deleteRecurrentPayment',
  deleteSubscription: 'deleteSubscription',
  postCreatePayOrder: 'postCreatePayOrder',
  patchUpdatePlanCharge: 'patchUpdatePlanCharge',
  postCreditCardPayment: 'postCreditCardPayment',
  postMpPreferenceForPayOrder: 'postMpPreferenceForPayOrder',
  postAdvancedCharge: 'postAdvancedCharge',
  postPaymentProof: 'postPaymentProof',
  getRecurrentPaymentChargeData: 'getRecurrentPaymentChargeData',
  getLastPaidCharges: 'getLastPaidCharges',
  postAssertStore: 'postAssertStore',
  getChargeDetails: 'getChargeDetails',
  getPaymentHistoryForConcept: 'getPaymentHistoryForConcept',
  getDownloadInvoice: 'getDownloadInvoice',
} as const;

export const checkoutTags = {
  subscriptions: 'subscriptions',
  storeSubscriptionEnabled: 'storeSubscriptionEnabled',
  recurrentPaymentConcepts: 'getRecurrentPaymentConcepts',
  pendingCharges: 'pendingCharges',
  paymentMethods: 'paymentMethods',
  wireTransferData: 'wireTransferData',
  payOrderToPay: 'payOrderToPay',
  lastPaymentData: 'lastPaymentData',
  recurrentPaymentChargesData: 'recurrentPaymentChargesData',
  lastPaidCharges: 'lastPaidCharges',
  chargeDetails: 'chargeDetails',
  paymentHistoryForConcept: 'paymentHistoryForConcept',
} as const;

export const CHECKOUT_API_CACHE_TIME = 300;
