import { Alert, Link, Text, Box } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import { OwnerResourceType } from 'domains/Metafields/types';

interface UpsellOnAlertCardProps {
  hasInternals: boolean;
  owner: OwnerResourceType;
}

export default function UpsellOnAlertCard({
  hasInternals,
  owner,
}: UpsellOnAlertCardProps) {
  const t = useTranslationsMetafields();
  const { goTo } = useNavegate();

  const handleClick = () => {
    goTo('/account/plans');
  };

  const alertText = hasInternals
    ? t(`alertMetafieldUpsellOnAlertCard.${owner}.withMetafields.description`)
    : t(
        `alertMetafieldUpsellOnAlertCard.${owner}.withoutMetafields.description`,
      );

  return (
    <Box margin="4">
      <Alert
        appearance="primary"
        title={t(`alertMetafieldUpsellOnAlertCard.${owner}.title`)}
      >
        <Text>{alertText}</Text>
        <Link appearance="primary" textDecoration="none" onClick={handleClick}>
          {t(`alertMetafieldUpsellOnAlertCard.button`)}
        </Link>
      </Alert>
    </Box>
  );
}
