import { Box, Text } from '@nimbus-ds/components';
import { CopyLink } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useGetWireTransferData } from '../../../hooks';

function WireDataAr() {
  const t = useTranslationBilling(
    'checkout.wireTransferPage.instructions.wireData',
  );
  const { wireTransferData } = useGetWireTransferData();

  if (!wireTransferData) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" gap="1">
        <Box display="flex" flexDirection="row" gap="2" alignItems="baseline">
          <Text fontSize="highlight">{t('alias')}: </Text>
          <CopyLink
            value={wireTransferData.alias}
            message={t('aliasCopied')}
            text={wireTransferData.alias}
            size="base"
          />
        </Box>
        <Box display="flex" flexDirection="row" gap="2" alignItems="baseline">
          <Text fontSize="highlight">{t('keyType')}: </Text>
          <CopyLink
            value={wireTransferData.cbu}
            message={t('keyTypeCopied')}
            text={wireTransferData.cbu}
            size="base"
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="0-5">
        <Text color="neutral-textLow">
          {t('bank')}: {wireTransferData.bank}
        </Text>
        <Text color="neutral-textLow">
          {t('accountType')}: {wireTransferData.accountType}
        </Text>
        <Text color="neutral-textLow">
          {t('accountNumber')}: {wireTransferData.accountNumber}
        </Text>
        <Text color="neutral-textLow">
          {t('accountHolder')}: {wireTransferData.accountHolder}
        </Text>
        <Text color="neutral-textLow">
          {t('idType')}: {wireTransferData.cuit}
        </Text>
      </Box>
    </>
  );
}

export default WireDataAr;
