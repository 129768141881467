import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getStatisticsPaymentsSummaryEntity = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.paymentsSummary,
);

export const getStatisticsPaymentsSummaryStatus = createSimpleSelector(
  [getStatisticsPaymentsSummaryEntity],
  (paymentsSummary) => convertStatusTypeToObject(paymentsSummary.status),
);

export const getStatisticsPaymentsSummaryData = createSimpleSelector(
  [getStatisticsPaymentsSummaryEntity],
  (paymentsSummary) => paymentsSummary.data,
);
