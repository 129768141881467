import { Box, Select, Text, Modal, Button } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function Skeleton() {
  const t = useTranslationConfigurations('languagesAndCurrencies');

  return (
    <>
      <Modal.Body padding="none">
        <Box display="grid" paddingTop="2" gridGap="3">
          <Text>{t('enabledCountryModal.labelCountry')}</Text>
          <Select.Skeleton width="100%" />
          <Text>{t('enabledCountryModal.labelCurrency')}</Text>
          <Select.Skeleton width="100%" />
          <Text>{t('enabledCountryModal.labelLanguage')}</Text>
          <Select.Skeleton width="100%" />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box display="flex" gap="2" paddingTop="4">
          <Button.Skeleton />
          <Button.Skeleton />
        </Box>
      </Modal.Footer>
    </>
  );
}

export default Skeleton;
