import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  createPhysicalLocationAction,
  createPhysicalLocationsStatus,
  cleanCreatePhysicalLocationStatus,
} from '../../deliveryMethodsSlice';

export interface PhysicalLocationBody {
  name: string;
  extra: string;
}

function useAddPhysicalLocationPage() {
  const status = useSelector(createPhysicalLocationsStatus);
  const dispatch = useAppDispatch();

  const createPhysicalLocation = async (arg: PhysicalLocationBody) => {
    await dispatch(createPhysicalLocationAction(arg));
    dispatch(cleanCreatePhysicalLocationStatus());
  };

  return {
    createPhysicalLocation,
    status,
  };
}

export default useAddPhysicalLocationPage;
