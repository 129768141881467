import {
  AiUsageLimitResponseDto,
  AiUsageReportResponseDto,
  AiUsageResponseDto,
  ChangeImageBackgroundRequestDto,
  ChangeImageBackgroundResponseDto,
  ProductImageSmartEraseRequestDto,
  ProductImageSmartEraseResponseDto,
  SuggestedGoogleShoppingCategoriesRequestDto,
  SuggestedGoogleShoppingCategoriesResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import {
  SMART_ERASE_URL,
  CHANGE_IMAGE_BACKGROUND_URL,
  SUGGESTED_GOOGLE_CATEGORIES_URL,
  GET_USAGE_LIMITS_URL,
  GET_USAGE_URL,
  GET_USAGE_REPORT_URL,
} from './config';

export const aiApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    smartErase: builder.query<
      ProductImageSmartEraseResponseDto,
      ProductImageSmartEraseRequestDto
    >({
      query: (requestDto: ProductImageSmartEraseRequestDto) => ({
        method: 'POST',
        url: SMART_ERASE_URL,
        data: requestDto,
      }),
    }),

    changeImageBackground: builder.query<
      ChangeImageBackgroundResponseDto,
      ChangeImageBackgroundRequestDto
    >({
      query: (requestDto: ChangeImageBackgroundRequestDto) => ({
        method: 'POST',
        url: CHANGE_IMAGE_BACKGROUND_URL,
        data: requestDto,
      }),
      keepUnusedDataFor: 120,
    }),

    suggestedGoogleCategories: builder.query<
      SuggestedGoogleShoppingCategoriesResponseDto,
      SuggestedGoogleShoppingCategoriesRequestDto
    >({
      query: (requestDto: SuggestedGoogleShoppingCategoriesRequestDto) => ({
        method: 'POST',
        url: SUGGESTED_GOOGLE_CATEGORIES_URL,
        data: requestDto,
      }),
      keepUnusedDataFor: 300,
    }),

    getUsage: builder.query<AiUsageResponseDto, void>({
      query: () => ({
        method: 'GET',
        url: GET_USAGE_URL,
      }),
      keepUnusedDataFor: 300,
    }),

    getUsageReport: builder.query<AiUsageReportResponseDto, void>({
      query: () => ({
        method: 'GET',
        url: GET_USAGE_REPORT_URL,
      }),
      keepUnusedDataFor: 300,
    }),

    getUsageLimits: builder.query<AiUsageLimitResponseDto, void>({
      query: () => ({
        method: 'GET',
        url: GET_USAGE_LIMITS_URL,
      }),
      keepUnusedDataFor: 300,
    }),
  }),
});

export const {
  useLazySmartEraseQuery,
  useLazyChangeImageBackgroundQuery,
  useLazySuggestedGoogleCategoriesQuery,
  useGetUsageQuery,
  useGetUsageReportQuery,
  useGetUsageLimitsQuery,
} = aiApi;
