import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetStoreSubscriptionEnabledQuery } from '../Checkout/CheckoutApi';

function useHasMigratedToBillingEngine() {
  const {
    status,
    data: isEnabled,
    refetch,
  } = useGetStoreSubscriptionEnabledQuery();

  return {
    isEnabled,
    status,
    fetchStoreSubscriptionIsEnabled: refetch,
    ...convertStatusTypeToObject(status),
  };
}
export default useHasMigratedToBillingEngine;
