import DraggableTreeRowSkeleton from './DraggableTreeRowSkeleton';
import '../../DraggableTree.scss';

function DraggableTreeSkeleton(): JSX.Element {
  return (
    <div className="stratus--draggable-tree">
      <div className="stratus--draggable-tree__root">
        <ul>
          <DraggableTreeRowSkeleton />
          <DraggableTreeRowSkeleton depth={1} />
          <DraggableTreeRowSkeleton depth={1} />
          <DraggableTreeRowSkeleton />
          <DraggableTreeRowSkeleton />
        </ul>
      </div>
    </div>
  );
}

export default DraggableTreeSkeleton;
