import { logEvent } from 'commons/utils/tracking';
import {
  AUTHENTICATION_FACTOR_ACTIVATED,
  AUTHENTICATION_FACTOR_DEACTIVATED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_COPIED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_DOWNLOADED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_START_ACTIVATION,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_UPDATE,
  SESSION_MANAGEMENT_CLOSE_ALL_SESSIONS_BUTTON_CLICK,
  SESSION_MANAGEMENT_CLOSE_SESSION_BUTTON_CLICK,
  SESSION_MANAGEMENT_MENU_ITEM_CLICK,
} from 'config/trackingEvents';

export const trackingAuthenticationFactorConfiguration = (
  currentActivationStatus: boolean,
): void => {
  logEvent(
    currentActivationStatus
      ? AUTHENTICATION_FACTOR_DEACTIVATED
      : AUTHENTICATION_FACTOR_ACTIVATED,
    {},
  );
};

export const trackingRecoveryCodesCopied = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_COPIED, {});
};

export const trackingRecoveryCodesDownloaded = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_DOWNLOADED, {});
};

export const trackingRecoveryCodesStartActivation = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_START_ACTIVATION, {});
};

export const trackingRecoveryCodesUpdate = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_UPDATE, {});
};

export const trackingSessionManagementMenuItemClick = (): void => {
  logEvent(SESSION_MANAGEMENT_MENU_ITEM_CLICK, {});
};

export const trackingSessionManagementCloseSessionButtonClick = (): void => {
  logEvent(SESSION_MANAGEMENT_CLOSE_SESSION_BUTTON_CLICK, {});
};

export const trackingSessionManagementCloseAllSessionsButtonClick =
  (): void => {
    logEvent(SESSION_MANAGEMENT_CLOSE_ALL_SESSIONS_BUTTON_CLICK, {});
  };
