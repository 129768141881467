import { Button, EmptyState, Stack, Text } from '@tiendanube/components';
import { CogIcon } from '@tiendanube/icons';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import emptyAbandonedCartsImage from './empty-abandoned-carts.png';

interface AbandonedCartsListEmptyProps {
  onClickConfig: () => void;
}

function AbandonedCartsListEmpty({
  onClickConfig,
}: AbandonedCartsListEmptyProps) {
  const t = useTranslationOrders();
  return (
    <EmptyState
      image={emptyAbandonedCartsImage}
      title={t('abandonedCarts.empty.title')}
    >
      <Stack column align="stretch">
        <Stack.Item fill>
          <Text>{t('abandonedCarts.empty.description')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Button icon={CogIcon} appearance="primary" onClick={onClickConfig}>
            {t('abandonedCarts.empty.button')}
          </Button>
        </Stack.Item>
      </Stack>
    </EmptyState>
  );
}

export default AbandonedCartsListEmpty;
