import { Box } from '@nimbus-ds/components';
import { PaginationStratus } from 'commons/components';
import { PromotionsListPageBase } from 'domains/Marketing/Promotions/components';
import { usePromotionsDesktop } from 'domains/Marketing/Promotions/hooks';
import {
  PromotionsListDesktopSkeleton,
  PromotionsListTable,
} from './components';

export function PromotionsListDesktopPage() {
  const {
    pagination,
    changePage,
    showSkeleton,
    data,
    isDataEmpty,
    isError,
    refetch,
  } = usePromotionsDesktop();

  return (
    <PromotionsListPageBase
      skeleton={<PromotionsListDesktopSkeleton />}
      isLoading={showSkeleton}
      isEmpty={isDataEmpty}
      isError={isError}
      onRetry={refetch}
    >
      <Box flexDirection="column" gap="2" display="flex">
        <PromotionsListTable data={data} />
        <PaginationStratus
          currentPage={pagination.currentPage}
          totalItems={pagination.totalResults}
          itemName=""
          page={pagination.currentPage}
          perPage={pagination.perPage}
          onPageSelect={changePage}
        />
      </Box>
    </PromotionsListPageBase>
  );
}
