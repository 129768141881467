import { useEffect, useState } from 'react';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  ColombianFiscalDataForm,
  ColombianNumberIdType,
  ColombianVatConditionType,
} from './components';
import { FiscalDataformColombia } from './components/ColombianFiscalDataForm';
import useColombianFiscalData from './useColombianFiscalData';
import validationSchema from './validationSchema';

const defaultValues: FiscalDataformColombia = {
  address: '',
  name: '',
  numberId: '',
  cityId: '',
  vatCondition: 'RESPONSABLE_IVA',
  personType: 'PERSONA_JURIDICA',
  numberIdType: 'NIT',
};

function ColombianFiscalData() {
  const t = useTranslationBilling();
  const { createFiscalData, updateFiscalData, status, cleanData } =
    useColombianFiscalData();
  const { goBack } = useNavegate();
  const { invoiceInfo, fetchInvoiceInfo, isLoading, hasInvoiceInfo } =
    useGetInvoiceInfo();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const { errors, values, handleOnChange, handleOnSubmit, isDirty } =
    useForm<FiscalDataformColombia>({
      initialValues,
      validationSchema,
      onSubmit: (data) => {
        if (hasInvoiceInfo) {
          updateFiscalData(data);
        } else {
          createFiscalData(data);
        }
      },
    });

  useEffect(() => {
    if (hasInvoiceInfo) {
      setInitialValues({
        name: invoiceInfo.name,
        numberId: invoiceInfo.numberId,
        cityId: invoiceInfo.cityId,
        address: invoiceInfo.address,
        vatCondition: invoiceInfo.vatCondition as ColombianVatConditionType,
        personType: invoiceInfo.personType,
        numberIdType: invoiceInfo.numberIdType as ColombianNumberIdType,
      });
    }
  }, [invoiceInfo, hasInvoiceInfo, setInitialValues]);
  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  useToastStatus({
    error: t('invoicesInfoPage.error'),
    success: t('invoicesInfoPage.success'),
    status,
    onSuccess: () => {
      cleanData();
      goBack();
    },
  });

  return isLoading ? (
    <ColombianFiscalDataForm.Skeleton onCancel={goBack} />
  ) : (
    <ColombianFiscalDataForm
      onSave={handleOnSubmit}
      onCancel={goBack}
      onChange={handleOnChange}
      values={values}
      errors={errors}
      isLoading={status === 'loading' || isLoading}
      isDirty={isDirty}
    />
  );
}

export default ColombianFiscalData;
