import {
  FulfillmentOrderShippingForDocumentResponseDto,
  FulfillmentOrderTrackingInfoForDocumentResponseDto,
  PaymentForDocumentResponseDto,
} from '@tiendanube/common';
import { SHIPPING_TYPE } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface CheckoutDocumentPreviewProps {
  payment: PaymentForDocumentResponseDto;
  shipping: FulfillmentOrderShippingForDocumentResponseDto | null;
  tracking: FulfillmentOrderTrackingInfoForDocumentResponseDto | null;
}

function CheckoutDocumentPreview({
  payment,
  shipping,
  tracking,
}: Readonly<CheckoutDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="details">
      <div className="detail-row">
        <p>
          {t('payment.name')} {payment.name}
        </p>
      </div>
      {!!shipping?.name && shipping?.type === SHIPPING_TYPE && (
        <div className="detail-row">
          <p>
            {t('shipping.name')} {shipping.name}
          </p>
        </div>
      )}
      {!!tracking?.code && (
        <div className="detail-row">
          <p>
            {t('trackingNumber')} {tracking.code}
          </p>
        </div>
      )}
    </div>
  );
}

export default CheckoutDocumentPreview;
