import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FulfillmentOrderResponseDto,
  OrderFulfillBulkRequestDto,
  OrderFulfillmentsDto,
  OrderResponseDto,
} from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, Button, Checkbox } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useGetOrdersByIds } from 'domains/Orders/Orders/hooks';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import FulfillBulkOrders from './FulfillBulkOrders';

export interface FulfillOrdersValue {
  id: string;
  number: number;
  consumer: string;
  shippingCode: string;
  fulfillment?: FulfillmentOrderResponseDto;
  zipcode?: string;
}

interface FulfillOrdersModalProps {
  show: boolean;
  orderIds: string[];
  isLoading: boolean;
  onClose: () => void;
  onConfirm: (action: OrderFulfillBulkRequestDto[]) => void;
  ordersMissingTracking: number;
  packagesCount: number;
  hasFulfillments: boolean;
  fulfillmentsForModal: OrderFulfillmentsDto[];
  isSingleOrder: boolean;
}

function FulfillOrdersModal({
  show,
  orderIds,
  isLoading,
  onClose,
  onConfirm,
  ordersMissingTracking,
  packagesCount,
  hasFulfillments,
  fulfillmentsForModal,
  isSingleOrder,
}: FulfillOrdersModalProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const orders = useGetOrdersByIds(orderIds);

  const [fulfillOrders, setFulfillOrders] = useState<FulfillOrdersValue[]>([]);
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  function getOrdersWithFulfillmentsIds(
    orderIds: string[],
  ): OrderFulfillmentsDto[] {
    return orderIds.map((id) => {
      const order = fulfillmentsForModal.find((order) => order.orderId === id);
      return {
        orderId: id,
        fulfillments: order?.fulfillments || [],
        fulfillmentIds: order?.fulfillmentIds || [],
      };
    });
  }

  function fromOrderToBaseFulfillOrder(
    order: OrderResponseDto,
    fulfillmentOrder?: FulfillmentOrderResponseDto,
  ): FulfillOrdersValue {
    return {
      id: order.id,
      number: order.number,
      consumer: order.consumer?.name || '',
      shippingCode: fulfillmentOrder
        ? fulfillmentOrder?.shipping_info?.tracking_code || ''
        : order?.fulfillment?.trackingNumber || '',
      zipcode: order.zipcode,
    };
  }

  useEffect(() => {
    const transformOrderToFulfillOrder = (
      order: OrderResponseDto,
    ): FulfillOrdersValue | FulfillOrdersValue[] =>
      order.fulfillmentOrders.length > 0
        ? order.fulfillmentOrders.map((fulfillmentOrder) => ({
            ...fromOrderToBaseFulfillOrder(order, fulfillmentOrder),
            fulfillment: fulfillmentOrder,
          }))
        : fromOrderToBaseFulfillOrder(order);
    if (show) {
      const updatedFulfillOrders = orders.flatMap((order) =>
        transformOrderToFulfillOrder(order),
      );
      setFulfillOrders(updatedFulfillOrders);
    }
  }, [show, orders]);

  const handleChangeShippingCode = (
    orderId: string,
    fulfillmentId: string | undefined,
    value,
  ) => {
    setFulfillOrders((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex(
        (order) =>
          order.id === orderId && order.fulfillment?.id === fulfillmentId,
      );
      newState[index].shippingCode = value;
      return newState;
    });
  };

  const handleRemoveFulfillOrders = (
    orderId: string,
    fulfillmentId: string | undefined,
  ) => {
    setFulfillOrders((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex(
        (order) =>
          order.id === orderId && order.fulfillment?.id === fulfillmentId,
      );
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleOnConfirm = () => {
    const selectedOrders = getOrdersWithFulfillmentsIds(orderIds);

    const action: OrderFulfillBulkRequestDto[] =
      selectedOrders.length > ORDERS_PER_PAGE
        ? selectedOrders
            .map((order) =>
              order.fulfillments.length > 0
                ? order.fulfillments.map((fulfillment) => ({
                    orderId: order.orderId,
                    fulfillment,
                  }))
                : [{ orderId: order.orderId, fulfillment: undefined }],
            )
            .flat()
            .map(({ orderId, fulfillment }) => ({
              orderId,
              fulfillment,
              sendNotification: sendEmail,
            }))
        : fulfillOrders.map((order) => ({
            orderId: order.id,
            shippingTrackingNum: order.shippingCode,
            sendNotification: sendEmail,
            fulfillment: order.fulfillment && {
              fulfillmentId: order.fulfillment.id,
              status: order.fulfillment.status,
              shippingType: order.fulfillment.shipping_info.shipping_type,
            },
          }));
    onConfirm(action);
  };

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerContent={t('fulfillModal.title')}
    >
      <Stack column align="stretch">
        <FulfillBulkOrders
          fulfillOrders={fulfillOrders}
          onRemoveFulfillOrders={handleRemoveFulfillOrders}
          onChangeShippingCode={handleChangeShippingCode}
          selectedOrders={orderIds.length}
          ordersMissingTracking={ordersMissingTracking}
          selectedPackages={packagesCount}
          hasFulfillments={hasFulfillments}
          isSingleOrder={isSingleOrder}
        />
        <Stack.Item>
          <Checkbox
            label={t('fulfillModal.sendEmail')}
            name="sendEmail"
            onChange={() => setSendEmail(!sendEmail)}
            checked={sendEmail}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack spacing="base" justify="flex-end">
            <Stack.Item>
              <Button onClick={onClose}>{`${t('fulfillModal.cancel')}`}</Button>
            </Stack.Item>
            <Stack.Item>
              <Button
                onClick={handleOnConfirm}
                appearance="primary"
                disabled={isLoading}
                spinner={isLoading}
              >
                {isLoading
                  ? `${t('fulfillModal.confirmLoading')}`
                  : `${t('fulfillModal.confirm')}`}
              </Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default FulfillOrdersModal;
