import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CorreiosOptionsRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanUpdateCorreiosShippingModalitiesStatus,
  getUpdateCorreiosShippingModalitiesStatus,
  updateCorreiosShippingModalitiesAction,
} from '../../deliveryMethodsSlice';

function useUpdateCorreiosShippingModalities() {
  const dispatch = useAppDispatch();
  const status = useSelector(getUpdateCorreiosShippingModalitiesStatus);

  const updateCorreiosShippingModalities = useCallback(
    async (body: CorreiosOptionsRequestDto[]) => {
      await dispatch(updateCorreiosShippingModalitiesAction(body));
      dispatch(cleanUpdateCorreiosShippingModalitiesStatus());
    },
    [dispatch],
  );

  return { updateCorreiosShippingModalities, status };
}

export default useUpdateCorreiosShippingModalities;
