import { ConceptCode, PayOrderStatus, PaymentStatus } from '@tiendanube/common';
import { useGetChargeDetailsQuery } from '../../../CheckoutApi';
import { useGetLastPayment } from '../../../hooks';

export default function usePayment(
  concept: ConceptCode,
  externalReference?: string | null,
  chargeId?: string,
) {
  const usesChargeData = !!chargeId;

  const lastPaymentHook = useGetLastPayment(
    concept,
    externalReference,
    !usesChargeData,
  );

  const { isSuccess, data: charge } = useGetChargeDetailsQuery(chargeId || '', {
    skip: !chargeId,
  });

  if (!usesChargeData) return lastPaymentHook;

  const payment = charge?.lastPayment;

  if (!isSuccess) return null;

  return {
    hasPayment: !!payment,
    paymentStatus: convertPaymentStatus(payment?.status),
    payment,
    isSuccess,
    lastPaymentMethodUsed: lastPaymentHook.getLastPaymentMethod(payment),
    isFromRecurrentPayment: !!charge?.payOrder?.automaticallyGenerated,
  };
}

function convertPaymentStatus(
  status: PaymentStatus | undefined,
): PayOrderStatus {
  const dict: Record<PaymentStatus, PayOrderStatus> = {
    CANCELLED: 'CANCELLED',
    REFUNDED: 'CANCELLED',
    FAILED: 'FAILED',
    SUCCESS: 'PAID',
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROCESS',
  };
  return status ? dict[status] : 'PENDING';
}
