import { EyeIcon, EyeOffIcon, TrashIcon } from '@nimbus-ds/icons';
import { ActionIconButton, Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  DeleteFreeShippingModal,
  StatusFreeShippingModal,
} from '../components';
import { useFreeShipingActions } from '../hooks';

interface ActionsProps {
  id: string;
  status: boolean;
}

export function Actions({ id, status }: Readonly<ActionsProps>) {
  const t = useTranslationMarketing('freeShipping.actions');
  const {
    showStatusModal,
    showDeleteModal,
    handleChangeStatus,
    handleDelete,
    openDeleteModal,
    closeDeleteModal,
    openStatusModal,
    closeStatusModal,
  } = useFreeShipingActions(id, status);

  const EyeStatusIcon = !status ? EyeIcon : EyeOffIcon;
  const typeStatus = !status ? 'enable' : 'disable';

  return (
    <>
      <Stack spacing="tight">
        <ActionIconButton
          content={t(`${typeStatus}.label`)}
          source={<EyeStatusIcon />}
          onClick={openStatusModal}
        />
        <ActionIconButton
          content={t('delete.label')}
          source={<TrashIcon />}
          onClick={openDeleteModal}
        />
      </Stack>
      <StatusFreeShippingModal
        show={showStatusModal}
        status={status}
        onClose={closeStatusModal}
        onChangeStatus={handleChangeStatus}
      />
      <DeleteFreeShippingModal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
      />
    </>
  );
}
