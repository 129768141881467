import { Text, Link, Box } from '@nimbus-ds/components';
import { CopyLink } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface TrackingNumberInfoProps {
  urlTrackingCode: string | null;
  trackingNumber: string | null | undefined;
}

function TrackingNumberInfo({
  trackingNumber,
  urlTrackingCode,
}: TrackingNumberInfoProps) {
  const t = useTranslationOrders();

  if (!trackingNumber) return null;

  return (
    <>
      <Text>{`${t('cardFulfillment.trankingNumber.title')}`}</Text>
      <Box display="flex">
        <Link as="a" href={urlTrackingCode || undefined} target="_blank">
          <Text
            lineClamp={1}
            wordBreak="break-all"
            color={
              urlTrackingCode ? 'primary-interactive' : 'neutral-interactive'
            }
          >
            {trackingNumber}
          </Text>
        </Link>

        <CopyLink
          value={`${trackingNumber}`}
          message={t('cardFulfillment.trankingNumber.copyMessage')}
          text={t('cardFulfillment.trankingNumber.copy')}
          iconPosition="start"
        />
      </Box>
    </>
  );
}

export default TrackingNumberInfo;
