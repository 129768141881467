import { Radio, Text } from '@nimbus-ds/components';
import { NoInfer } from 'commons/types';
import { Stack } from '../index';

interface InterfaceRadioButtonOption<TOption> {
  label: string;
  value: TOption;
  disabled?: boolean;
}

interface InterfaceRadioButtonGroup<TOption, TName> {
  name: TName;
  label?: string;
  value: NoInfer<TOption>;
  options: InterfaceRadioButtonOption<TOption>[];
  onChange: ({ name, value }: { name: TName; value: TOption }) => void;
  boldLabel?: boolean;
}

function RadioButtonGroup<TName extends string, TOption extends string>({
  label,
  name,
  options,
  onChange,
  value,
  boldLabel = false,
}: Readonly<InterfaceRadioButtonGroup<TOption, TName>>) {
  return (
    <Stack column spacing="tight" align="flex-start">
      {!!label && (
        <Text fontWeight={boldLabel ? 'bold' : undefined}>{label}</Text>
      )}
      <Stack spacing="tight" wrap>
        {options.map((option) => (
          <Radio
            name={name}
            label={option.label}
            as="button"
            onChange={(_) => onChange({ name, value: option.value })}
            value={option.value}
            checked={value === option.value}
            key={option.value}
            id={`radio_${name}_${option.value}`}
            disabled={option.disabled}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default RadioButtonGroup;
