import { useState } from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationAccount } from '../../../hooks';
import { useHasInactiveUserSessions } from '../hooks';

export function InactiveUserSessionsAlert() {
  const t = useTranslationAccount('sessionManagementPage.alerts');
  const hasInactiveSessions = useHasInactiveUserSessions();
  const [show, setShow] = useState(true);
  const handleRemove = () => setShow(false);

  if (hasInactiveSessions) {
    return (
      <Alert appearance="warning" show={show} onRemove={handleRemove}>
        <Text color="warning-textLow">{t('inactiveSessions')}</Text>
      </Alert>
    );
  }

  return null;
}
