import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import {
  ADDRESSING_RULES_TYPES,
  INITIAL_MATERIALIZATION_RULE,
} from './constants';

export const reprioritizeRules = (
  rules: AddressingConfigurationRuleResponseDto[],
) => {
  const appRules = rules
    .filter((rule) => rule.type === ADDRESSING_RULES_TYPES.app)
    .map((rule, index) => ({ ...rule, priority: 1 + index }));
  const otherRules = rules
    .filter((rule) => rule.type !== ADDRESSING_RULES_TYPES.app)
    .map((rule, index) => ({
      ...rule,
      priority: appRules.length === 0 ? 1 + index : 2 + index,
    }));

  return [...appRules, ...otherRules];
};

export const getEnabledRules = (
  rules: AddressingConfigurationRuleResponseDto[],
) =>
  rules.filter(
    (rule) => rule.type !== ADDRESSING_RULES_TYPES.materialize && rule.enabled,
  );

export const getDisabledRules = (
  rules: AddressingConfigurationRuleResponseDto[],
) =>
  rules.filter(
    (rule) => rule.type !== ADDRESSING_RULES_TYPES.materialize && !rule.enabled,
  );

export const getMaterializationRule = (
  rules: AddressingConfigurationRuleResponseDto[],
) =>
  rules.find((rule) => rule.type === ADDRESSING_RULES_TYPES.materialize) ||
  INITIAL_MATERIALIZATION_RULE;

export const getIncompatibleRule = (
  rules: AddressingConfigurationRuleResponseDto[],
  newRule: AddressingConfigurationRuleResponseDto,
) =>
  rules.find((rule) => {
    if (newRule.type === ADDRESSING_RULES_TYPES.cdPriority) {
      return rule.type === ADDRESSING_RULES_TYPES.cdProximity;
    }
    return rule.type === ADDRESSING_RULES_TYPES.cdPriority;
  }) as AddressingConfigurationRuleResponseDto;
