import { Stack, Input } from '@tiendanube/components';

function Skeleton() {
  return (
    <Stack column align="stretch">
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Stack spacing="tight">
        <Stack.Item fill>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item fill>
          <Input.Skeleton />
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export default Skeleton;
