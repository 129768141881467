import { useSelector } from 'react-redux';
import { OrderResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getOrdersByIds } from '../../ordersSlice/ordersSelectors';

function useOrderListItem(ids: string[]): OrderResponseDto[] {
  const orders = useSelector((state: RootStateType) =>
    getOrdersByIds(state, ids),
  );

  return orders;
}

export default useOrderListItem;
