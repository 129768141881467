import { useSelector } from 'react-redux';
import { DataList, Text, Link } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { RootStateType } from 'App/store';
import { CurrencyPrice } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import { getAbandonedCartsListItemById } from 'domains/Orders/AbandonedCart/abandonedCartsSlice/abandonedCartsSelectors';
import { useAbandonedCartConfigEmail } from 'domains/Orders/AbandonedCart/hooks';
import {
  AbandonedCartsSendEmail,
  LinkWithIcon,
} from 'domains/Orders/AbandonedCart/pages/AbandonedCartsListPage/components';

const { Row, Cell } = DataList;

interface AbandonedCartsListMobileRowProps {
  id: string;
}

function AbandonedCartsListMobileRow({ id }: AbandonedCartsListMobileRowProps) {
  const { goTo } = useNavegate();
  const abandonedCarts = useSelector((state: RootStateType) =>
    getAbandonedCartsListItemById(state, id),
  );
  const { abandonedCartEmailConfig } = useAbandonedCartConfigEmail();

  const goToAbandonedCartDetails = () => {
    goTo(`/abandoned-carts/${abandonedCarts.id}`);
  };

  const goToCustomer = () => {
    goTo(`/customers/${abandonedCarts.customer.id}`);
  };

  const isManualEmailsSetting = abandonedCartEmailConfig === 'manual';
  const paymentCount = abandonedCarts.paymentCount ?? 0;

  return (
    <Row
      id={abandonedCarts.id}
      key={abandonedCarts.id}
      onClick={goToAbandonedCartDetails}
    >
      <Cell width={60} rowTitle>
        <LinkWithIcon
          id={abandonedCarts.id}
          opportunity={abandonedCarts.opportunity}
          paymentIntents={paymentCount}
        />
      </Cell>
      <Cell width={40} alignRight>
        <Text>{dateFormat(abandonedCarts.date, Format.DAY_MONTH)}</Text>
      </Cell>
      <Cell width={60}>
        {abandonedCarts.customer.id ? (
          <Link appearance="primary" onClick={goToCustomer}>
            {abandonedCarts.customer.name}
          </Link>
        ) : (
          <Text>{abandonedCarts.customer.name}</Text>
        )}
      </Cell>
      <Cell width={40} alignRight>
        <CurrencyPrice
          price={abandonedCarts.total}
          currency={abandonedCarts.currency}
        />
      </Cell>
      <Cell width={100}>
        <Text>{abandonedCarts.customer.email}</Text>
      </Cell>
      {isManualEmailsSetting && (
        <Cell width={100} extraPadding>
          <AbandonedCartsSendEmail abandonedCart={abandonedCarts} />
        </Cell>
      )}
    </Row>
  );
}

export default AbandonedCartsListMobileRow;
