import { Button, Modal, Spinner, Text } from '@nimbus-ds/components';
import { useAuthenticationFactorAuthorization } from 'domains/AuthenticationFactor/hooks';
import { AuthenticationFactorVerifyCodeModal } from '../AuthenticationFactorVerifyCodeModal';

interface ModalConfirmationWithAuthProps {
  title: string;
  text: React.ReactElement | string;
  labelConfirm: string;
  labelCancel: string;
  appearanceConfirm?: 'neutral' | 'primary' | 'danger' | 'transparent';
  appearanceCancel?: 'neutral' | 'primary' | 'danger' | 'transparent';
  payload?: object;
  onClose?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

function ModalConfirmationWithAuth({
  title,
  text,
  labelConfirm,
  labelCancel,
  appearanceConfirm = 'primary',
  appearanceCancel = 'neutral',
  payload,
  onClose,
  onCancel,
  onConfirm,
}: ModalConfirmationWithAuthProps): JSX.Element {
  const {
    isLoading: isLoadingAuthFactor,
    showAuthenticationFactorModal,
    handleCancel,
    handleSave,
    handleIsValidCode,
  } = useAuthenticationFactorAuthorization({ onSave: onConfirm });

  const handleDismiss = onClose ? onClose : onCancel;

  return (
    <>
      {!showAuthenticationFactorModal && (
        <Modal open onDismiss={handleDismiss}>
          <Modal.Header title={title} />
          <Modal.Body padding="none">
            <Text textAlign="left">{text}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button appearance={appearanceCancel} onClick={onCancel}>
              {labelCancel}
            </Button>
            <Button
              appearance={appearanceConfirm}
              disabled={isLoadingAuthFactor}
              onClick={handleSave}
            >
              {isLoadingAuthFactor && (
                <Spinner color="currentColor" size="small" />
              )}
              {labelConfirm}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <AuthenticationFactorVerifyCodeModal
        isOpen={showAuthenticationFactorModal}
        type="centered"
        onCancel={handleCancel}
        onIsValidCode={handleIsValidCode}
        payload={payload}
      />
    </>
  );
}

export default ModalConfirmationWithAuth;
