import { Grid, Stack, Text } from '@tiendanube/components';
import { ComparisonTableMobileRowSkeleton } from '..';
import { ComparisonSelectSkeleton } from '../../..';

function ComparisonTableMobileSkeleton(): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <ComparisonSelectSkeleton />
      </Stack.Item>
      <Stack.Item>
        <Text.Skeleton size="featured" width="large" />
      </Stack.Item>
      <Stack.Item>
        <Grid spacing="none" fluid>
          <ComparisonTableMobileRowSkeleton />
          <ComparisonTableMobileRowSkeleton />
          <ComparisonTableMobileRowSkeleton />
          <ComparisonTableMobileRowSkeleton />
        </Grid>
      </Stack.Item>
    </Stack>
  );
}

export default ComparisonTableMobileSkeleton;
