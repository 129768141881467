import { Chip } from '@nimbus-ds/components';
import { FreeShippingZoneDto } from '@tiendanube/common';
import { Stack } from '@tiendanube/components';
import { SelectOrEditLink } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { DeliveryZonesModalAside } from '../../DeliveryZonesModalAside';

interface DeliveryZonesProps {
  zones: FreeShippingZoneDto[];
  onChange: (methods: FreeShippingZoneDto[]) => void;
}

export function DeliveryZones({
  zones,
  onChange,
}: Readonly<DeliveryZonesProps>) {
  const t = useTranslationMarketing('freeShipping.asides.deliveryZones');
  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !zones.length;
  const hasChilds = length > 0;

  return (
    <>
      {!isEmpty && (
        <>
          <Stack spacing="tight" wrap>
            {zones.map(({ id, description, subzones: { length } }) => {
              const childs = hasChilds ? `(${length})` : '';
              return (
                <Stack.Item key={`${id}`}>
                  <Chip
                    key={`${id}`}
                    text={`${description} ${childs}`}
                    removable
                    onClick={() => {
                      onChange(zones.filter((zone) => zone.id !== id));
                    }}
                  />
                </Stack.Item>
              );
            })}
          </Stack>
          <br />
        </>
      )}
      <SelectOrEditLink
        isEdit={!isEmpty}
        onSelect={openModal}
        selectLinkText={t('selector.select')}
        editLinkText={t('selector.edit')}
      />
      <DeliveryZonesModalAside
        isOpen={isShowModal}
        selected={zones}
        onClose={closeModal}
        onChange={onChange}
      />
    </>
  );
}
