import { useEffect, useRef } from 'react';

type useDrageModeResult = React.RefObject<HTMLDivElement>;

function useInfiniteScroll(endReached?: () => void): useDrageModeResult {
  const targetID: useDrageModeResult = useRef(null);

  useEffect(() => {
    const marginEndScroll = 1;
    const margin = Math.trunc(window.innerHeight * marginEndScroll);
    const options = {
      rootMargin: `0px 0px ${margin}px 0px`,
    };
    const callback = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        endReached?.();
      }
    };
    const observer = new IntersectionObserver(callback, options);
    observer.observe(targetID.current as HTMLDivElement);
    return () => {
      observer.disconnect();
    };
  }, [endReached]);

  return targetID;
}

export default useInfiniteScroll;
