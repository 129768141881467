import * as Yup from 'yup';

export const newCustomShippingSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('deliveryMethods.validations.requiredField'),
    locationId: Yup.string().required('deliveryMethods.validations.location'),
    cartWeightMax: Yup.string()
      .test(
        'weightMaxNegativesValues',
        'deliveryMethods.validations.negativeValues',
        (value) => {
          if (value && Number(value) < 0) {
            return false;
          }
          return true;
        },
      )
      .test(
        'weightMax',
        'deliveryMethods.validations.weightMax',
        (value, { parent: { cartWeightMin } }) => {
          if (cartWeightMin && value) {
            return Number(value) >= Number(cartWeightMin);
          }
          return true;
        },
      ),
    cartWeightMin: Yup.string().test(
      'weightMinNegativesValues',
      'deliveryMethods.validations.negativeValues',
      (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      },
    ),
    cartValueMax: Yup.string()
      .test(
        'valueMaxNegativesValues',
        'deliveryMethods.validations.negativeValues',
        (value) => {
          if (value && Number(value) < 0) {
            return false;
          }
          return true;
        },
      )
      .test(
        'valueMax',
        'deliveryMethods.validations.valueMax',
        (value, { parent: { cartValueMin } }) => {
          if (cartValueMin && value) {
            return Number(value) >= Number(cartValueMin);
          }
          return true;
        },
      ),
    cartValueMin: Yup.string().test(
      'valueMinNegativesValues',
      'deliveryMethods.validations.negativeValues',
      (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      },
    ),
    deliveryTimeMax: Yup.string()
      .test(
        'timeMaxNegativeValues',
        'deliveryMethods.validations.negativeValues',
        (value) => {
          if (value && Number(value) < 0) {
            return false;
          }
          return true;
        },
      )
      .test(
        'timeMax',
        'deliveryMethods.validations.termMax',
        (value, { parent: { deliveryTimeMin } }) => {
          if (deliveryTimeMin) {
            return Number(deliveryTimeMin) <= Number(value);
          }
          return true;
        },
      ),
    deliveryTimeMin: Yup.string().test(
      'timeMinNegativeValues',
      'deliveryMethods.validations.negativeValues',
      (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      },
    ),
  });
