import { ReactNode, useState, useCallback, createContext } from 'react';
import { BulkActionTypeEnum } from '../BulkActions/ModalProductsEditPrices/types';

export interface BulkActionsContext {
  selectedRowsId: string[];
  selectAll: boolean;
  selectedAction: BulkActionTypeEnum | null;
  setSelectedRowsId: (ids: string[]) => void;
  setSelectAll: (value: boolean) => void;
  setSelectedAction: (action: BulkActionTypeEnum) => void;
  clearBulkActions: () => void;
  showModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  editMode: boolean;
  toggleEditMode: () => void;
  showAlertMetafields: boolean;
  showAlertPrice: boolean;
  activeAlertMetafields: () => void;
  deactivateAlertMetafields: () => void;
  activeAlertPrice: () => void;
  disabled: boolean;
  setDisabled: (boolean) => void;
}

export const BulkActionsContext = createContext({} as BulkActionsContext);

interface BulkActionsContextProviderParams {
  children: ReactNode;
}

function BulkActionsContextProvider({
  children,
}: BulkActionsContextProviderParams): JSX.Element {
  const [rowsId, setRowsId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showAlertMetafields, setShowAlertMetafields] = useState(false);
  const [showAlertPrice, setShowAlertPrice] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const setSelectedRowsId = useCallback((ids) => setRowsId(ids), []);
  const setSelectedAction = useCallback((action) => setAction(action), []);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  const toggleEditMode = useCallback(
    () => setEditMode((current) => !current),
    [],
  );

  const activeAlertMetafields = useCallback(
    () => setShowAlertMetafields(true),
    [],
  );

  const deactivateAlertMetafields = useCallback(
    () => setShowAlertMetafields(false),
    [],
  );

  const activeAlertPrice = useCallback(() => setShowAlertPrice(true), []);

  const clearBulkActions = useCallback(() => {
    setRowsId([]);
    setSelectAll(false);
    setAction(null);
  }, []);

  return (
    <BulkActionsContext.Provider
      value={{
        selectedRowsId: rowsId,
        selectAll,
        selectedAction: action,
        setSelectedRowsId,
        setSelectAll,
        setSelectedAction,
        clearBulkActions,
        showModal,
        openModal,
        closeModal,
        editMode,
        toggleEditMode,
        showAlertMetafields,
        showAlertPrice,
        activeAlertMetafields,
        deactivateAlertMetafields,
        activeAlertPrice,
        disabled,
        setDisabled,
      }}
    >
      {children}
    </BulkActionsContext.Provider>
  );
}

export default BulkActionsContextProvider;
