import * as Yup from 'yup';
import { OcaAgencyResponseDto } from '@tiendanube/common';

export const sender = (
  getOcaAgencies: (zipcode: string) => Promise<OcaAgencyResponseDto[]>,
) =>
  Yup.object().shape({
    name: Yup.string().required('oca.config.sender.errors.name'),
    surname: Yup.string().required('oca.config.sender.errors.surname'),
    companyName: Yup.string().required('oca.config.sender.errors.companyName'),
    cuit: Yup.string()
      .required('oca.config.sender.errors.cuit')
      .matches(/^$|\d{2}-\d{8}-\d{1}/, 'oca.config.sender.errors.cuitInvalid'),
    zipcode: Yup.string()
      .required('oca.config.sender.errors.zipcode')
      .matches(/^$|\d{4}/, 'oca.config.sender.errors.zipcodeInvalid')
      .test(
        'zipcode-is-valid',
        'oca.config.sender.errors.zipcodeInvalid',
        async (value) => {
          if (!value) return true;
          try {
            const data = await getOcaAgencies(value);
            return data.length > 0;
          } catch (e) {
            return false;
          }
        },
      ),
  });

export const originAddress = Yup.object().shape({
  street: Yup.string().required('oca.config.originAddress.errors.street'),
  number: Yup.string().required('oca.config.originAddress.errors.number'),
  locality: Yup.string().required('oca.config.originAddress.errors.locality'),
});

export const ocaConfigarationSchemaStep1 = (
  getOcaAgencies: (zipcode: string) => Promise<OcaAgencyResponseDto[]>,
) =>
  Yup.object().shape({
    sender: sender(getOcaAgencies),
    originAddress,
  });
