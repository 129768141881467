import {
  MessagesResponseDto,
  PaginationResponseDto,
  QueryParamsRequestDto,
} from '@tiendanube/common';

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
  refresh = 'refreshing',
}

export type MessagesEntitiesType = Record<string, MessagesResponseDto>;

export interface MessagesInterface {
  status: statusType;
  refreshStatus: statusType;
  exportStatus: statusType;
  currentRequestID: string;
  entities: MessagesEntitiesType;
  ids: string[];
  pagination: PaginationResponseDto;
  filters: QueryParamsRequestDto;
  unreadMessagesCount: number;
}
