import { ReactNode, createContext } from 'react';
import { useModal } from 'commons/hooks';
import { useStatisticsFilters } from 'domains/Statistics/hooks';
import { UseStatisticsFiltersResult } from 'domains/Statistics/hooks/useStatisticsFilters';

const noop = () => null;

export interface FilterStatisticsContextInterface
  extends UseStatisticsFiltersResult {
  showFilter: boolean;
  openFilter: () => void;
  closeFilter: () => void;
}

interface FilterStatisticsProviderProps {
  children: ReactNode;
}

export const FilterStatisticsContext =
  createContext<FilterStatisticsContextInterface>({
    showFilter: false,
    openFilter: noop,
    closeFilter: noop,
    statisticsFilters: { mainPeriod: 'currentDay' },
    handleOnChangeFilters: noop,
    hasComparedFilter: false,
    hasAppliedFilters: false,
  });

function FilterStatisticsProvider({ children }: FilterStatisticsProviderProps) {
  const [showFilter, openFilter, closeFilter] = useModal();
  const statisticsFilters = useStatisticsFilters();
  return (
    <FilterStatisticsContext.Provider
      value={{
        showFilter,
        openFilter,
        closeFilter,
        ...statisticsFilters,
      }}
    >
      {children}
    </FilterStatisticsContext.Provider>
  );
}

export default FilterStatisticsProvider;
