import { Alert, Box, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { PaymentBillingEngineDto } from '@tiendanube/common';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { usePayment } from '../../hooks';
import { PaymentStatusAlertProps } from '../../PaymentStatusAlert';
import GoToPayPlanLink from '../GoToPayPlanLink';

/**
 * When this component is used outside of the New Checkout (i.e. external context), we don't
 * want to show the messages for these error codes, and instead show the generic message.
 */
const errorCodeExternalContextBlacklist = [
  'invalidCardNumber',
  'invalidExpirationDate',
  'invalidSecurityCode',
  'invalidCardGeneric',
];

const failedReasonCodeMap = (
  payment: PaymentBillingEngineDto | undefined,
  isExternalContext: boolean,
) => {
  if (payment?.isInvalidOperator) return 'invalidOperator';
  const errorCodeMap = {
    ['cc_rejected_insufficient_amount']: 'insufficientFunds',
    ['cc_rejected_call_for_authorize']: 'callRequired',
    ['cc_rejected_high_risk']: 'highRisk',
    ['cc_rejected_blacklist']: 'highRisk',
    ['cc_rejected_bad_filled_card_number']: 'invalidCardNumber',
    ['cc_rejected_bad_filled_date']: 'invalidExpirationDate',
    ['cc_rejected_bad_filled_security_code']: 'invalidSecurityCode',
    ['cc_rejected_bad_filled_other']: 'invalidCardGeneric',
  };
  const errorCode =
    (payment?.errorCode && errorCodeMap[payment?.errorCode]) || 'genericError';
  return isExternalContext &&
    errorCodeExternalContextBlacklist.includes(errorCode)
    ? 'genericError'
    : errorCode;
};

function PaymentStatusAlertCreditCard({
  concept,
  externalReference,
  isExternalContext = false,
  chargeId,
}: PaymentStatusAlertProps) {
  const t = useTranslationBilling('checkout.paymentStatusAlert.creditCard');
  const paymentData = usePayment(concept, externalReference, chargeId);
  const isMobileDevice = useIsMobileDevice();

  if (!paymentData) return null;
  const { payment, paymentStatus, isFromRecurrentPayment } = paymentData;

  const errorCode = failedReasonCodeMap(payment, isExternalContext);

  switch (paymentStatus) {
    case 'IN_PROCESS':
      return (
        <Alert
          show={!isExternalContext}
          appearance="warning"
          title={t('inProgress.title')}
        >
          <Text>{t('inProgress.message')}</Text>
        </Alert>
      );
    case 'FAILED': {
      // we add the suffix "Mobile"to the translation key when we are in the mobile application to change the content
      const mobileSuffix = isMobileDevice ? 'Mobile' : '';
      const recurrentPaymentPath = isFromRecurrentPayment
        ? `recurrentPaymentActivated${mobileSuffix}`
        : `recurrentPaymentDisabled${mobileSuffix}`;
      const isHighRisk = errorCode === 'highRisk';
      const externalLink = (
        <Link as="a" href={t('failed.highRiskHelpLink')}>
          {t('moreInformation')}
          <Icon source={<ExternalLinkIcon />} />
        </Link>
      );
      const button = <GoToPayPlanLink as={isHighRisk ? 'button' : 'link'} />;
      return (
        <Alert appearance="danger" title={t('failed.title')}>
          <Text>{t(`failed.${recurrentPaymentPath}.${errorCode}`)}</Text>
          {isHighRisk && !isExternalContext && externalLink}
          {isHighRisk && isExternalContext && (
            <Box display="flex" flexDirection="row" gap="2">
              {button}
              {externalLink}
            </Box>
          )}
          {isExternalContext && !isHighRisk && button}
        </Alert>
      );
    }
    default:
      return null;
  }
}

export default PaymentStatusAlertCreditCard;
