import { useState } from 'react';
import { SocialNetworksRequestDto } from '@tiendanube/common';
import { useAsyncFunc } from 'commons/hooks';
import accountServices from '../../../accountServices';

interface RequestStatus {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}
interface useSocialNetworksResult {
  updateSocialNetworks: (socialNetworks: SocialNetworksRequestDto) => void;
  socialNetworks: SocialNetworksRequestDto;
  fetchSocialNetworks: () => void;
  fetchStatus: RequestStatus;
  updateStatus: RequestStatus;
}
interface useSocialNetworksProp {
  onFetchError?: () => void;
  onFetchSuccess?: () => void;
  onUpdateSuccess?: () => void;
  onUpdateError?: () => void;
}

const useSocialNetworks = ({
  onUpdateSuccess,
  onFetchSuccess,
  onFetchError,
  onUpdateError,
}: useSocialNetworksProp): useSocialNetworksResult => {
  const [socialNetworks, setSocialNetworks] =
    useState<SocialNetworksRequestDto>({});

  const fetchSocialNetworksRequest = async () => {
    setSocialNetworks(await accountServices.getSocialNetworks());
  };

  const updateSocialNetworksRequest = async (
    socialNetworks?: SocialNetworksRequestDto,
  ) => {
    if (socialNetworks) {
      setSocialNetworks(
        await accountServices.updateSocialNetworks(socialNetworks),
      );
    }
  };

  const [
    fetchSocialNetworks,
    fetchIsLoading,
    fetchIsError,
    ,
    fetchErrorMessage,
  ] = useAsyncFunc<void, void>(
    fetchSocialNetworksRequest,
    onFetchSuccess,
    onFetchError,
  );
  const [
    updateSocialNetworks,
    updateIsLoading,
    updateIsError,
    ,
    updateErrorMessage,
  ] = useAsyncFunc<SocialNetworksRequestDto, void>(
    updateSocialNetworksRequest,
    onUpdateSuccess,
    onUpdateError,
  );

  return {
    fetchSocialNetworks,
    fetchStatus: {
      isLoading: fetchIsLoading,
      isError: fetchIsError,
      errorMessage: fetchErrorMessage,
    },
    updateStatus: {
      isLoading: updateIsLoading,
      isError: updateIsError,
      errorMessage: updateErrorMessage,
    },
    updateSocialNetworks,
    socialNetworks,
  };
};

export default useSocialNetworks;
