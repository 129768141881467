import { Text, Tooltip } from '@tiendanube/components';
import { Icon } from '@tiendanube/icons';
import './SecondaryTooltip.scss';

interface SecondaryTooltipProps {
  icon: Icon;
  tooltipText: string;
}

function SecondaryTooltip({
  icon,
  tooltipText,
}: SecondaryTooltipProps): JSX.Element {
  return (
    <div className="stratus--secondary-tooltip">
      <Tooltip labelIcon={icon}>
        <Text>{tooltipText}</Text>
      </Tooltip>
    </div>
  );
}

export default SecondaryTooltip;
