import { LatLngExpression } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { addOpacityToHexColor } from 'commons/utils/addOpacityToHexColor';
import { calculateOpacity } from 'commons/utils/calculateOpacity';
import { useGetCountry } from 'domains/Auth/hooks';
import StatisticsMapContainer from '../StatisticsMapContainer';

const CENTER_BY_COUNTRY = {
  AR: [-38.4212955, -63.587402499999996],
  BR: [-14.235004, -51.92528],
  MX: [23.634501, -102.552784],
};

const ZOOM_BY_COUNTRY = {
  AR: 3,
  BR: 3,
  MX: 4,
};

interface ProvincesRankingPoint {
  id: string;
  polygon: LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][];
}

interface StatisticsProvincesMapProps {
  provinces?: ProvincesRankingPoint[];
  color?: string;
}

function StatisticsProvincesMap({
  provinces,
  color = '#BC462F',
}: StatisticsProvincesMapProps) {
  const country = useGetCountry();
  return (
    <StatisticsMapContainer
      center={CENTER_BY_COUNTRY[country] || CENTER_BY_COUNTRY.BR}
      zoom={ZOOM_BY_COUNTRY[country] || ZOOM_BY_COUNTRY.BR}
      zoomControl={false}
    >
      {provinces?.map(({ id, polygon }, i) => (
        <Polygon
          key={id}
          color={addOpacityToHexColor(
            color,
            calculateOpacity(i, provinces.length),
          )}
          positions={polygon}
        />
      ))}
    </StatisticsMapContainer>
  );
}

export default StatisticsProvincesMap;
