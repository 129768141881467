interface StepperProps {
  children: JSX.Element[];
  activeStep: number;
}

function Stepper({ children, activeStep }: StepperProps) {
  return children[activeStep - 1];
}

export default Stepper;
