import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LocationFullResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { getLocationById } from '../../locationSlice';
import { getLocationDeatils } from '../../locationSlice/locationSelectors';

export interface UseLocationDetailsInterface {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  location: LocationFullResponseDto;
  fetchLocation: () => void;
}

function useLocationDetails(id: string): UseLocationDetailsInterface {
  const dispatch = useAppDispatch();

  const { location, isLoading, isError, isSuccess } =
    useSelector(getLocationDeatils);

  const fetchLocation = useCallback(() => {
    dispatch(getLocationById(id));
  }, [id, dispatch]);

  return { isLoading, isError, isSuccess, location, fetchLocation };
}

export default useLocationDetails;
