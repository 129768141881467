import { Input, InteractiveList, Text, Stack } from '@tiendanube/components';

const MOCK_OPTIONS = [
  { title: '', name: '' },
  { title: '', name: '' },
  { title: '', name: '' },
  { title: '', name: '' },
];

function AttributeValuesModalSkeleton() {
  return (
    <Stack column align="stretch">
      <Input.Skeleton />
      <Input.Skeleton />
      <Text.Skeleton />
      <InteractiveList
        mode="multi"
        skeleton
        options={MOCK_OPTIONS}
        onChange={() => null}
      />
    </Stack>
  );
}

export default AttributeValuesModalSkeleton;
