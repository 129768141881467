import {
  Input,
  InterfaceNameValue,
  Stack,
  Button,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import { useTranslationBilling } from 'domains/Billing';
import { useCoupons } from '../hooks';

export interface CouponsFormInterface {
  couponCode: string;
}

interface CouponsFormProps {
  values: CouponsFormInterface;
  onChange: (data: InterfaceNameValue) => void;
  onSave: () => void;
}

function CouponsForm({ values, onChange, onSave }: CouponsFormProps) {
  const t = useTranslationBilling();
  const {
    data: { errors },
    isLoading,
  } = useCoupons();
  let codeHelpText = '';
  if (errors?.isNotFound)
    codeHelpText = t('couponsPage.couponsForm.errors.notFound');
  if (errors?.isUsed || errors?.couponAlreadyInPlace)
    codeHelpText = t('couponsPage.couponsForm.errors.isUsed');
  const saveButtonText = t('couponsPage.couponsForm.saveButtonText');

  return (
    <CardWithTitle title={t('couponsPage.couponsForm.title')}>
      <Stack column align="stretch">
        <Input
          type="text"
          name="couponCode"
          value={values.couponCode}
          helpText={codeHelpText}
          appearance={codeHelpText ? 'validation_error' : 'default'}
          onChange={onChange}
        />
        <Stack column align="flex-start">
          <Button disabled={isLoading} onClick={onSave} appearance="primary">
            {saveButtonText}
          </Button>
        </Stack>
      </Stack>
    </CardWithTitle>
  );
}

export default CouponsForm;
