import { Box } from '@nimbus-ds/components';
import SummaryValuesCard from '../../SummaryValuesCard';

function Skeleton() {
  return (
    <Box
      display="grid"
      gridGap="4"
      gridTemplateColumns={{
        lg: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        xs: 'repeat(1, 1fr)',
      }}
      paddingLeft="4"
      paddingRight="4"
      paddingBottom="4"
    >
      <SummaryValuesCard.Skeleton />
      <SummaryValuesCard.Skeleton />
      <SummaryValuesCard.Skeleton />
      <SummaryValuesCard.Skeleton />
      <SummaryValuesCard.Skeleton />
    </Box>
  );
}

export default Skeleton;
