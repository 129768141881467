import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { FreeShippingFormState } from '../types';

const { Header, Body, Footer } = Modal;

interface ConfirmScopeFreeShippingModalProps {
  values: FreeShippingFormState;
  show: boolean;
  onClose: () => void;
  onConfirm: (values: FreeShippingFormState) => void;
}

const SCOPES_MAP = {
  all: {
    some: 'categories',
    all: 'both',
  },
  some: {
    all: 'zones',
    some: 'n/a',
  },
};

export function ConfirmScopeFreeShippingModal({
  values,
  show,
  onClose,
  onConfirm,
}: Readonly<ConfirmScopeFreeShippingModalProps>) {
  const t = useTranslationMarketing('freeShipping.confirmScopeModal');

  const { categoriesType, zonesType } = values;

  const typeAll = SCOPES_MAP[categoriesType][zonesType];

  const handleConfirm = () => {
    onConfirm(values);
    onClose();
  };

  return (
    <Modal open={show} onDismiss={onClose}>
      <Header title={t(`${typeAll}.title`)} />
      <Body padding="none">
        <Text>{t(`${typeAll}.body`)}</Text>
      </Body>
      <Footer>
        <CancelAndConfirmButtons
          onCancel={onClose}
          onConfirm={handleConfirm}
          cancelText={t('cancel')}
          confirmText={t('confirm')}
        />
      </Footer>
    </Modal>
  );
}
