import {
  SeoRedirectsRequestDto,
  SeoRedirectsResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { REMOVE_SEO_REDIRECT_URL, SEO_REDIRECT_URL } from './urls';

const SeoRedirectsEndpoints = {
  getSeoRedirectsList: 'getSeoRedirectsList',
  removeSeoRedirect: 'removeSeoRedirect',
  createSeoRedirectsList: 'createSeoRedirectsList',
} as const;

const SeoRedirectsTags = {
  seoRedirectsList: 'seoRedirectsList',
} as const;

export const seoRedirectsApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [SeoRedirectsTags.seoRedirectsList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [SeoRedirectsEndpoints.getSeoRedirectsList]: builder.query<
        SeoRedirectsResponseDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: SEO_REDIRECT_URL,
        }),
        providesTags: [SeoRedirectsTags.seoRedirectsList],
      }),
      [SeoRedirectsEndpoints.createSeoRedirectsList]: builder.mutation<
        SeoRedirectsResponseDto,
        SeoRedirectsRequestDto
      >({
        query: (data) => ({
          method: 'POST',
          url: SEO_REDIRECT_URL,
          data,
        }),
        invalidatesTags: [SeoRedirectsTags.seoRedirectsList],
      }),
      [SeoRedirectsEndpoints.removeSeoRedirect]: builder.mutation<void, string>(
        {
          query: (id) => ({
            method: 'DELETE',
            url: getUrlWithParams(REMOVE_SEO_REDIRECT_URL, { id }),
          }),
          invalidatesTags: [SeoRedirectsTags.seoRedirectsList],
        },
      ),
    }),
  });

export const {
  useGetSeoRedirectsListQuery,
  useCreateSeoRedirectsListMutation,
  useRemoveSeoRedirectMutation,
} = seoRedirectsApi;
