import MenuItem from 'App/settings/menu/components/MenuItem/MenuItem';
import { useMenu } from 'App/settings/menu/hooks';

interface ExternalAppsMenuProps {
  isMobile: boolean;
}

function ExternalAppsEnhanceMenu({
  isMobile,
}: ExternalAppsMenuProps): JSX.Element | null {
  const { menu } = useMenu();
  if (isMobile || !menu) return null;

  return (
    <>
      {Object.values(menu?.Main?.Enhance?.options || {}).map((option) => (
        <MenuItem option={option} key={option.key} />
      ))}
    </>
  );
}

export default ExternalAppsEnhanceMenu;
