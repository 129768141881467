import { Checkbox, CheckboxProps, Label, Tag } from '@nimbus-ds/components';
import { GetDocumentSettingsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import LABEL_SETTINGS_OPTIONS, {
  LABEL_SETTING_RECEIPT_STUP,
} from '../../LabelSettings';

interface PrintDocumentConfigurationProps {
  settings: GetDocumentSettingsResponseDto;
  isLoading: boolean;
  onChangeSetting: (name: string, value: boolean) => void;
  hasPickup: boolean;
}

function DocumentSettingsForm({
  settings,
  isLoading,
  onChangeSetting,
  hasPickup,
}: Readonly<PrintDocumentConfigurationProps>) {
  const t = useTranslationFulfillmentOrders('printManager.informationCard');

  const currentSettings: CheckboxProps[] = LABEL_SETTINGS_OPTIONS.map(
    ({ label, name }) => ({
      label,
      name,
      checked: settings[name],
    }),
  );

  const baseCheckBoxList = Object.keys(currentSettings).map((key) => {
    const { name, checked, label } = currentSettings[key];

    return (
      <Checkbox
        key={key}
        name={name}
        checked={checked}
        label={label ? t(label) : ''}
        onChange={(event) => onChangeSetting(name, event.target.checked)}
      />
    );
  });

  return (
    <Stack justify="flex-start" align="stretch" column>
      <Label>{t('title')}</Label>
      {!isLoading && baseCheckBoxList}
      {!isLoading && hasPickup && (
        <Stack justify="space-between">
          <Checkbox
            key={LABEL_SETTING_RECEIPT_STUP.name}
            name={LABEL_SETTING_RECEIPT_STUP.name}
            checked={settings[LABEL_SETTING_RECEIPT_STUP.name]}
            label={t(LABEL_SETTING_RECEIPT_STUP.label ?? '')}
            onChange={(event) =>
              onChangeSetting(
                LABEL_SETTING_RECEIPT_STUP.name,
                event.target.checked,
              )
            }
          />
          <Tag appearance="primary">{t('tagNew')}</Tag>
        </Stack>
      )}
      {isLoading && (
        <>
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
        </>
      )}
    </Stack>
  );
}

export default DocumentSettingsForm;
