import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { ExternalLink } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

interface EbitProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  ebitAccount?: string;
  ebitSlug?: string;
}

export function Ebit({ onChange, ebitAccount, ebitSlug }: Readonly<EbitProps>) {
  const t = useTranslationConfigurations('externalCodes.otherServices.ebit');

  return (
    <SplitSection title={t('title')} description={t('description')}>
      <FormField.Input
        onChange={onChange}
        value={ebitAccount}
        name="ebitAccount"
        placeholder={t('ebitAccountPlaceholder')}
        label={t('ebitAccountLabel')}
      />
      <FormField.Input
        onChange={onChange}
        value={ebitSlug}
        name="ebitSlug"
        placeholder={t('ebitSlugPlaceholder')}
        label={t('ebitSlugLabel')}
      />
      <ExternalLink href={t('href')} appearance="primary" textDecoration="none">
        {t('link')}
      </ExternalLink>
    </SplitSection>
  );
}
