import { Link, Stack, Text } from '@tiendanube/components';
import { InfoCircleIcon, SlidersIcon } from '@tiendanube/icons';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

interface ConversionYourStoreEmptyProps {
  onRetry: () => void;
}

function ConversionYourStoreEmpty({ onRetry }: ConversionYourStoreEmptyProps) {
  const t = useTranslationStatistics();
  return (
    <Stack column align="center" justify="center">
      <InfoCircleIcon size="medium" />
      <Text textAlign="center">
        {t('statistics.orderConversionCard.empty.text')}
      </Text>
      <Link
        icon={SlidersIcon}
        iconPosition="end"
        appearance="primary"
        onClick={onRetry}
      >
        {t('statistics.orderConversionCard.empty.link')}
      </Link>
    </Stack>
  );
}

export default ConversionYourStoreEmpty;
