import * as Yup from 'yup';

export default Yup.object().shape({
  zipcode: Yup.string()
    .required('address.errors.zipcode')
    .test('length', 'address.errors.zipcode', (value) => value?.length === 9),
  province: Yup.string().required(),
  city: Yup.string().required(),
  neighborhood: Yup.string()
    .test(
      'neighborhoodRequired',
      'address.errors.neighborhood',
      (_, obj) => !obj.parent.province || obj.parent.neighborhood,
    )
    .nullable(),
  street: Yup.string()
    .test(
      'streetRequired',
      'address.errors.street',
      (_, obj) => !obj.parent.province || obj.parent.street,
    )
    .nullable(),
  number: Yup.string().required('address.errors.number'),
  complement: Yup.string(),
});
