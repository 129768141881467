import { Box, Card, Tag, Title } from '@nimbus-ds/components';
import { ThemesConfigCurrentResponseDto } from '@tiendanube/common';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { Stack, SubmitButton } from 'commons/components';
import { useLoggedAdminLink } from 'domains/Auth/hooks';
import { trackingThemeLayoutCustomizeCurrent } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { NewThemeActions } from './NewThemeActions';
import { ThemeActions } from './ThemeActions';
import { ThemePreview } from '../Theme';

interface ThemeCardProps {
  currentTheme: ThemesConfigCurrentResponseDto;
  hasThemeConfigAvailable: boolean;
  hasFtpConfigAvailable: boolean;
  highlightEditCTA: boolean;
}

export function ThemeCard({
  currentTheme,
  hasThemeConfigAvailable,
  hasFtpConfigAvailable,
  highlightEditCTA,
}: Readonly<ThemeCardProps>) {
  const t = useTranslationOnline('themesPage.theme');
  const { goToLoggedAdminLink, isLoading } = useLoggedAdminLink();

  const handleClickEdit = async () => {
    await goToLoggedAdminLink(
      '/themes/settings/active',
      trackingThemeLayoutCustomizeCurrent,
    );
  };

  return (
    <Card>
      <Card.Body>
        <ThemePreview
          imageDesktopUrl={currentTheme.screenshotDesktop}
          imageMobileUrl={currentTheme.screenshotMobile}
        />
      </Card.Body>
      <Card.Body>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          gap="2"
          flexWrap="wrap"
        >
          <Stack spacing="tight">
            <Title as="h4">{currentTheme.name}</Title>
            <Tag appearance="success">{t('activeDesign')}</Tag>
          </Stack>
          <Box display="flex" gap="2">
            <SubmitButton
              appearance={highlightEditCTA ? 'primary' : 'neutral'}
              onClick={handleClickEdit}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {hasThemeConfigAvailable ? t('edit') : t('editColors')}
            </SubmitButton>
            <FeatureFlag
              flag={NEW_ONBOARDING_B}
              renderElse={
                <ThemeActions
                  hasThemeConfigAvailable={hasThemeConfigAvailable}
                  hasFtpConfigAvailable={hasFtpConfigAvailable}
                />
              }
            >
              <NewThemeActions
                hasThemeConfigAvailable={hasThemeConfigAvailable}
                hasFtpConfigAvailable={hasFtpConfigAvailable}
              />
            </FeatureFlag>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
