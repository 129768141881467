import {
  BaseCard,
  Title,
  List,
  Text,
  Link,
  Stack,
  Button,
} from '@tiendanube/components';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';

interface ShopCancellationStepOneProps {
  onContinue: () => void;
}

function ShopCancellationStepOne({
  onContinue,
}: ShopCancellationStepOneProps): JSX.Element {
  const t = useTranslationAccount();
  const isMobileDevice = useIsMobileDevice();

  const onCheck = goToAdmin('/account/checkout/finish');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t('shopCancellationPage.stepOne.title')}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <List>
            <List.Item>
              <Text>
                {`${t('shopCancellationPage.stepOne.lastDayMessageOne')}`}
              </Text>
              {'\u00A0'}
              <Text>
                <strong>
                  {`${t('shopCancellationPage.stepOne.lastDayMessageTwo')}`}
                </strong>
              </Text>
              {'\u00A0'}
              <Text>
                {`${t('shopCancellationPage.stepOne.lastDayMessageThird')}`}
              </Text>
            </List.Item>
            <List.Item>
              {isMobileDevice ? (
                <Text>{`${t('shopCancellationPage.stepOne.check')}`}</Text>
              ) : (
                <Link appearance="primary" underline onClick={onCheck}>
                  {`${t('shopCancellationPage.stepOne.check')} `}
                </Link>
              )}{' '}
              <Text>
                {`${t('shopCancellationPage.stepOne.expirationDate')}`}
              </Text>
            </List.Item>
          </List>
          <Text appearance="light" size="caption">
            {`${t('shopCancellationPage.stepOne.disclaimer')}`}
          </Text>
          <Text>{`${t('shopCancellationPage.stepOne.missYou')}`}</Text>
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button onClick={onContinue} appearance="primary">{`${t(
          'shopCancellationPage.continueButton',
        )}`}</Button>
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default ShopCancellationStepOne;
