import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getStandardVariantsAction } from 'domains/Catalog/Products/productsSlice';
import {
  getStandardVariantsData,
  getStandardVariantsStatus,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

function useStandardVariants() {
  const dispatch = useAppDispatch();
  const status = useSelector(getStandardVariantsStatus);
  const standardVariants = useSelector(getStandardVariantsData);

  const fetchStandardVariants = useCallback(() => {
    dispatch(getStandardVariantsAction());
  }, [dispatch]);

  return {
    ...convertStatusTypeToObject(status),
    standardVariants,
    fetchStandardVariants,
  };
}

export default useStandardVariants;
