import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchMetafieldsData,
  getMetafieldsData,
  getMetafieldsStatus,
} from 'domains/Orders/OrderMetafields';
import { useOrdersMetafields } from '../../pages/hooks';

function useGetMetafields() {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsData);
  const { isError, isLoading, isSuccess } = useSelector(getMetafieldsStatus);
  const { isMetafieldAllowedByPlan } = useOrdersMetafields();

  const fetchMetafields = useCallback(
    (id: string) => {
      dispatch(fetchMetafieldsData(id));
    },
    [dispatch],
  );

  const hasInternals =
    !!metafields?.internals && Object.keys(metafields?.internals).length > 0;
  const hasExternals =
    !!metafields?.fromApi && Object.keys(metafields?.fromApi).length > 0;

  return {
    metafields: hasInternals ? metafields.internals : null,
    apiMetafields: hasExternals ? metafields.fromApi : null,
    isLoading,
    isSuccess,
    isError,
    fetchMetafields,
    hasExternals,
    hasInternals,
    isAvailable: isMetafieldAllowedByPlan,
  };
}

export default useGetMetafields;
