import {
  CreateNavigationMenuRequestDto,
  NavigationMenuResponseDto,
  NavigationMenusRequestDto,
  NavigationMenusResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  menusEndpoints,
  BASE_MENUS_URL,
  DELETE_MENU_URL,
  menusTags,
} from './config';

export const menusApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [menusTags.menusList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [menusEndpoints.getMenus]: builder.query<
        NavigationMenusResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_MENUS_URL,
        }),
        providesTags: [menusTags.menusList],
      }),
      [menusEndpoints.createMenu]: builder.mutation<
        NavigationMenuResponseDto,
        CreateNavigationMenuRequestDto
      >({
        query: (newMenu) => ({
          method: 'POST',
          url: BASE_MENUS_URL,
          data: newMenu,
        }),
        invalidatesTags: (_, error) => (error ? [] : [menusTags.menusList]),
      }),
      [menusEndpoints.deleteMenu]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(DELETE_MENU_URL, { id }),
        }),
        invalidatesTags: (_, error) => (error ? [] : [menusTags.menusList]),
      }),
      [menusEndpoints.updateMenus]: builder.mutation<
        NavigationMenuResponseDto,
        NavigationMenusRequestDto
      >({
        query: (updatedMenus) => ({
          method: 'PUT',
          url: BASE_MENUS_URL,
          data: updatedMenus,
        }),
        invalidatesTags: (_, error) => (error ? [] : [menusTags.menusList]),
      }),
    }),
  });

export const {
  useGetMenusQuery,
  useCreateMenuMutation,
  useDeleteMenuMutation,
  useUpdateMenusMutation,
} = menusApi;
