import { useContext } from 'react';
import { InterfaceTopAdBarContext, TopAdBarContext } from './TopAdBarProvider';

export function useTopAdBar(): InterfaceTopAdBarContext {
  const { isShowTopAdBar, showTopAdBar, hideTopAdBar } =
    useContext(TopAdBarContext);

  return {
    isShowTopAdBar,
    showTopAdBar,
    hideTopAdBar,
  };
}
