import {
  PaginationResponseDto,
  ResultPaginationResponseDto,
  UserSessionDto,
} from '@tiendanube/common';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { cirrusApi, infinityBehavior, tableBehavior } from 'App/rtk';
import { UserSessionEndpoints, CIRRUS_USER_SESSIONS_URL } from './settings';

const getUserSessionsEndpoint = () =>
  window.innerWidth > MOBILE_BREAKPOINT
    ? UserSessionEndpoints.getUserSessions
    : UserSessionEndpoints.getUserSessionsInf;

export const userSessionsApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [UserSessionEndpoints.getUserSessions]: builder.query<
      ResultPaginationResponseDto<UserSessionDto[]>,
      void
    >({
      query: (params) => ({
        method: 'GET',
        url: CIRRUS_USER_SESSIONS_URL,
        params,
      }),
      ...tableBehavior(UserSessionEndpoints.getUserSessions),
    }),
    [UserSessionEndpoints.getUserSessionsInf]: builder.query<
      ResultPaginationResponseDto<UserSessionDto[]>,
      void
    >({
      query: (params) => ({
        method: 'GET',
        url: CIRRUS_USER_SESSIONS_URL,
        params,
      }),
      ...infinityBehavior(UserSessionEndpoints.getUserSessionsInf),
    }),
    [UserSessionEndpoints.deleteUserSession]: builder.mutation<void, string>({
      query: (id) => ({
        method: 'DELETE',
        url: CIRRUS_USER_SESSIONS_URL,
        data: { sessionId: id },
      }),
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            cirrusApi.util.updateQueryData(
              getUserSessionsEndpoint() as never,
              undefined as never,
              (draft: ResultPaginationResponseDto<UserSessionDto[]>) => {
                draft.results = draft.results.filter(
                  ({ id: currentId }) => currentId !== id,
                );
                draft.pagination = repaginateResults(
                  draft.pagination,
                  draft.results.length,
                );
              },
            ),
          );
        });
      },
    }),
    [UserSessionEndpoints.deleteAllUserSessions]: builder.mutation<
      void,
      string
    >({
      query: (id) => ({
        method: 'DELETE',
        url: CIRRUS_USER_SESSIONS_URL,
        data: { currentSessionId: id },
      }),
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            cirrusApi.util.updateQueryData(
              getUserSessionsEndpoint() as never,
              undefined as never,
              (draft: ResultPaginationResponseDto<UserSessionDto[]>) => {
                draft.results = draft.results.filter(
                  ({ id: currentId }) => currentId === id,
                );
                draft.pagination = repaginateResults(
                  draft.pagination,
                  draft.results.length,
                );
              },
            ),
          );
        });
      },
    }),
  }),
});

function repaginateResults(
  pagination: PaginationResponseDto,
  totalResults: number,
): PaginationResponseDto {
  const onlyOnePageLeft = totalResults <= pagination.perPage;

  if (onlyOnePageLeft) {
    return {
      totalPages: 1,
      currentPage: 1,
      totalResults,
      perPage: pagination.perPage,
      nextPage: null,
    };
  }

  return pagination;
}

export const {
  useDeleteUserSessionMutation,
  useDeleteAllUserSessionsMutation,
} = userSessionsApi;
