import { PaymentProviderDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import Skeleton from './Skeleton';
import { OnPaymentProviderActionType } from '../PaymentProviderActionModal';
import PaymentProviderMobile from '../PaymentProviderMobile';

interface PaymentProviderListMobile {
  paymentProviders: PaymentProviderDto[];
  onAction: OnPaymentProviderActionType;
}

function PaymentProviderListMobile({
  paymentProviders,
  onAction,
}: Readonly<PaymentProviderListMobile>) {
  return (
    <Stack column>
      {paymentProviders.map((paymentProviders) => (
        <PaymentProviderMobile
          key={paymentProviders.id}
          paymentProvider={paymentProviders}
          onAction={onAction}
        />
      ))}
    </Stack>
  );
}

PaymentProviderListMobile.Skeleton = Skeleton;

export default PaymentProviderListMobile;
