import { Country } from '@tiendanube/common/src/enums';
import buttonIcon from './assets/button-chat-nimbus.png';
import businessIcon from './assets/dark-icon-nimbus.png';
import headerIcon from './assets/white-icon-nimbus.png';
import { SuncoTexts, SuncoOptions } from './interfaces';
import * as suncoTexts from './suncoTexts';

const suncoLocals: Record<string, SuncoTexts> = {
  [Country.AR]: suncoTexts.esAR,
  [Country.BR]: suncoTexts.ptBR,
  [Country.MX]: suncoTexts.esMX,
  [Country.CL]: suncoTexts.esCL,
  [Country.CO]: suncoTexts.esCO,
};

const integrationIdByCountry: Record<Country, string> = {
  [Country.AR]: import.meta.env.VITE_SUNCO_INTEGRATION_ID_AR,
  [Country.BR]: import.meta.env.VITE_SUNCO_INTEGRATION_ID_BR,
  [Country.MX]: import.meta.env.VITE_SUNCO_INTEGRATION_LATAM,
  [Country.CL]: import.meta.env.VITE_SUNCO_INTEGRATION_ID_LATAM,
  [Country.CO]: import.meta.env.VITE_SUNCO_INTEGRATION_ID_LATAM,
  [Country.PE]: import.meta.env.VITE_SUNCO_INTEGRATION_ID_LATAM,
};

export function getConfig(country: string): SuncoOptions {
  return {
    integrationId:
      integrationIdByCountry[country] ||
      import.meta.env.VITE_SUNCO_INTEGRATION_ID_LATAM,
    appId: import.meta.env.VITE_SUNCO_APP_ID || '',
    secret: import.meta.env.VITE_SUNCO_SECRET || '',
    customColors: {
      brandColor: '100E4A',
      conversationColor: '0050C3',
      actionColor: '0050C3',
    },
    buttonIconUrl: buttonIcon,
    businessIconUrl: businessIcon,
    headerIcon: headerIcon,
    suncoTexts: suncoLocals[country],
  };
}
