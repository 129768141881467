import { IconButton, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

function LocationsListRowMobileSkeleton(): JSX.Element {
  const { Row } = DataList;

  return (
    <Row gap="1" topDivider>
      <Stack spacing="loose" gap="1" justify="space-between">
        <Stack.Item fill>
          <Stack column spacing="tight" align="stretch">
            <Text.Skeleton width="30%" />
            <Text.Skeleton width="fill" />
            <Text.Skeleton width="fill" />
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <IconButton.Skeleton height="2rem" width="2rem" />
        </Stack.Item>
      </Stack>
    </Row>
  );
}

export default LocationsListRowMobileSkeleton;
