import { ChangeEvent } from 'react';
import { Radio, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { getTodayIsoString } from 'commons/utils/date';
import { useTranslationMarketing } from '../hook';

export interface DatesValuesInterface {
  dateType: 'unlimited' | 'limited';
  startDate: string;
  endDate: string;
}

interface DatesProps {
  values: DatesValuesInterface;
  errors?: Partial<DatesValuesInterface>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function Dates({
  values,
  errors,
  onChange,
}: Readonly<DatesProps>): JSX.Element {
  const t = useTranslationMarketing('common.dates');

  const { dateType, startDate, endDate } = values;
  const { startDate: startDateError, endDate: endDateError } = errors || {};

  const today = getTodayIsoString();

  return (
    <>
      <Text fontWeight="bold">{t('title')}</Text>
      <Stack spacing="tight">
        <Radio
          id="date-type-1"
          name="dateType"
          as="button"
          value="unlimited"
          checked={dateType === 'unlimited'}
          label={t('type.unlimited')}
          onChange={onChange}
        />
        <Radio
          id="date-type-2"
          name="dateType"
          as="button"
          value="limited"
          checked={dateType === 'limited'}
          label={t('type.limited')}
          onChange={onChange}
        />
      </Stack>

      {dateType === 'limited' && (
        <Stack spacing="tight">
          <FormField.Input
            type="date"
            name="startDate"
            label={t('start')}
            value={startDate}
            min={today}
            appearance={startDateError ? 'danger' : undefined}
            onChange={onChange}
          />
          <FormField.Input
            type="date"
            name="endDate"
            label={t('end')}
            value={endDate}
            min={startDate}
            appearance={endDateError ? 'danger' : undefined}
            onChange={onChange}
          />
        </Stack>
      )}
    </>
  );
}
