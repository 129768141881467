import { GetKycStatusResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { KYC_STATUS_URL } from './urls';

const NuvemPagoCommonsEndpoints = {
  getKycStatus: 'getKycStatus',
} as const;

export const nuvemPagoCommonsApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [NuvemPagoCommonsEndpoints.getKycStatus]: builder.query<
      GetKycStatusResponseDto,
      void
    >({
      query: () => ({
        method: 'GET',
        url: KYC_STATUS_URL,
      }),
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetKycStatusQuery } = nuvemPagoCommonsApi;
