import readTextFormFile from '../readTextFormFile';

const csvDetectPositionBreakLine = (str: string) =>
  str.indexOf('\n') > -1 ? str.indexOf('\n') : str.indexOf('\r');

const csvDetectSeparate = (str: string) => (str.indexOf(';') > -1 ? ';' : ',');

const csvGetHeaderFromText = (str: string): string[] => {
  const strCleaned = str.replace(/"/g, '').replace(/\r/g, '');
  const firstLine = strCleaned.slice(0, csvDetectPositionBreakLine(strCleaned));
  return firstLine.split(csvDetectSeparate(firstLine));
};

const readHeaderTextFromCsvFile = async (file: File): Promise<string[]> => {
  const chunkBytes = 1000;
  const chunkFile = file.slice(0, chunkBytes);
  const chunkFileToText = await readTextFormFile(chunkFile);
  const csvHeaderFromText = csvGetHeaderFromText(chunkFileToText);
  return csvHeaderFromText;
};

export default readHeaderTextFromCsvFile;
