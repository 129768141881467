import { useCallback, useState } from 'react';
import { InterfaceNameValue } from '@tiendanube/components';
import { InventoryFiltersType } from 'domains/Catalog/Inventory/inventoryServices';
import useFilterLabel from '../useFilterLabel';

const defaultValueErrors = { minStock: '', maxStock: '' };

interface UseInventoryFiltersResult {
  values: InventoryFiltersType;
  errors: Record<string, string>;
  validateFilters: () => boolean;
  getFilterChips: () => { id: string; label: string }[];
  handleChange: ({ name, value }: InterfaceNameValue) => void;
  setAllValues: (filters: InventoryFiltersType) => void;
}

function useInventoryFilters(
  filters: InventoryFiltersType,
): UseInventoryFiltersResult {
  const getFilterLabel = useFilterLabel(filters);

  const [values, setValues] = useState(filters);
  const [errors, setErrors] = useState(defaultValueErrors);

  const handleChange = useCallback(
    ({ name, value }) => {
      setValues({ ...values, [name]: value });
      setErrors(defaultValueErrors);
    },
    [values],
  );

  const setAllValues = useCallback((filters: InventoryFiltersType) => {
    setValues(filters);
  }, []);

  const validateFilters = useCallback(() => {
    if (values.stock === 'quantity') {
      const { minStock, maxStock } = values;
      if (Number(maxStock) > 0 && Number(minStock) > Number(maxStock)) {
        setErrors({
          minStock: '',
          maxStock: 'products.filters.errors.maxStockIsMin',
        });
        return false;
      }
    }

    return true;
  }, [values]);

  const getFilterChips = useCallback(() => {
    const filterChips = Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== '' && !(key === 'maxStock' || key === 'minStock'),
      )
      .map(getFilterLabel);

    return filterChips;
  }, [filters, getFilterLabel]);

  return {
    values,
    errors,
    validateFilters,
    getFilterChips,
    handleChange,
    setAllValues,
  };
}

export default useInventoryFilters;
