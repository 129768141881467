import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationAccount } from '../../../hooks';
import {
  trackingSessionManagementCloseAllSessionsButtonClick,
  trackingSessionManagementCloseSessionButtonClick,
} from '../../../tracking';
import { useGetTranslationKeyByUser } from '../hooks';

const { Header, Body, Footer } = Modal;

interface DeleteUserSessionModalProps {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
  isDeleteAllAction: boolean;
}

export function DeleteUserSessionModal({
  show,
  onClose,
  onDelete,
  isDeleteAllAction,
}: Readonly<DeleteUserSessionModalProps>) {
  const translationKey = isDeleteAllAction ? 'deleteAllModal' : 'deleteModal';
  const t = useTranslationAccount(`sessionManagementPage.${translationKey}`);
  const translationKeyByUser = useGetTranslationKeyByUser();
  const handleSessionManagementDeleteButtonClick = () =>
    isDeleteAllAction
      ? trackingSessionManagementCloseAllSessionsButtonClick()
      : trackingSessionManagementCloseSessionButtonClick();
  const handleDelete = () => {
    onDelete();
    onClose();
    handleSessionManagementDeleteButtonClick();
  };

  return (
    <Modal open={show} onDismiss={onClose}>
      <Header title={t('title')} />
      <Body padding="none">
        <Text>{t(`body.${translationKeyByUser}`)}</Text>
      </Body>
      <Footer>
        <CancelAndConfirmButtons
          onCancel={onClose}
          onConfirm={handleDelete}
          cancelText={t('cancel')}
          confirmText={t('confirm')}
          appearance="primary"
        />
      </Footer>
    </Modal>
  );
}
