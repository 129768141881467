import { Text, Thumbnail } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { ASPECT_RATIO_IMAGE, WIDTH_IMAGE } from '../utils';

function MethodNameAndImageSkeleton() {
  return (
    <Stack spacing="tight">
      <Thumbnail.Skeleton
        aspectRatio={ASPECT_RATIO_IMAGE}
        width={WIDTH_IMAGE}
      />
      <Text.Skeleton width="70px" />
    </Stack>
  );
}

export default MethodNameAndImageSkeleton;
