import { Checkbox } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionFormProps } from '../PromotionForm';

type DiscountCombinationProps = Pick<PromotionFormProps, 'values' | 'onChange'>;

export function DiscountCombination({
  values: { combinesWithOtherDiscounts },
  onChange,
}: Readonly<DiscountCombinationProps>) {
  const t = useTranslationMarketing(
    'promotions.settingsPage.applyTo.discountCombination',
  );
  return (
    <Checkbox
      name="combinesWithOtherDiscounts"
      label={t('label')}
      onChange={onChange}
      checked={combinesWithOtherDiscounts}
    />
  );
}
