import {
  Button,
  Card,
  Skeleton,
  Tag,
  Text,
  Title,
  Box,
} from '@nimbus-ds/components';
import { Layout, Stack, useResponsive } from 'commons/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

export function ThemesPageSkeleton() {
  const t = useTranslationOnline('themesPage');
  const { isMobile } = useResponsive();

  return (
    <Layout
      left={
        <>
          <Card>
            <Card.Body>
              <Stack column align="stretch">
                <Skeleton width="100%" height={isMobile ? '210px' : '280px'} />
                <Stack spacing="tight">
                  <Title.Skeleton as="h4" width="100px" />
                  <Tag.Skeleton width="100px" />
                </Stack>
              </Stack>
            </Card.Body>
            <Card.Footer>
              <Button.Skeleton width="150px" />
              <Button.Skeleton width="130px" />
            </Card.Footer>
          </Card>
          <Card>
            <Card.Body>
              <Stack justify="space-between">
                <Stack.Item fill>
                  <Stack align="flex-start" column spacing="tight">
                    <Stack spacing="tight">
                      <Title.Skeleton as="h4" />
                      <Tag.Skeleton />
                    </Stack>
                    <Text.Skeleton />
                  </Stack>
                </Stack.Item>
                <Box display="flex" gap="2">
                  <Button.Skeleton />
                  <Button.Skeleton />
                </Box>
              </Stack>
            </Card.Body>
          </Card>
        </>
      }
      right={
        <Card>
          <Card.Header>
            <Title as="h4">{t('identity.title')}</Title>
          </Card.Header>
          <Card.Body>
            <Skeleton width="100%" height="95px" borderRadius="0.5rem" />
          </Card.Body>
          <Card.Footer>
            <Button.Skeleton width="115px" />
          </Card.Footer>
        </Card>
      }
      mainContent
      orderMobile="default"
    />
  );
}
