import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { StatisticsInterface } from '../types';

const getStatistics = (
  state: RootStateType,
): CombinedState<StatisticsInterface> => state.statistics;

export const getStatisticsOrders = createSelector(
  getStatistics,
  (statistics) => statistics.orders,
);

export const getStatisticsOrdersStatus = createSelector(
  getStatistics,
  (data) => data.status,
);

export const getStatisticsOrdersLoadingError = createSelector(
  getStatisticsOrdersStatus,
  (status) => ({
    error: status === 'error',
    loading: status === 'loading',
  }),
);
