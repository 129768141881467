import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { fixesAttributesAction } from 'domains/Catalog/Products/productsSlice';
import {
  fixAttributesStatus,
  initialValuesToFixAttributes,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useProductDetails } from '../../EditProductPage/hooks';

function useFixesAttributes(productId: string) {
  const dispatch = useAppDispatch();

  const updateAttributesStatus = useSelector(fixAttributesStatus);

  const { isLoading, isSuccess, isError } = convertStatusTypeToObject(
    updateAttributesStatus,
  );

  const { fetchProduct } = useProductDetails(productId);

  const getFixFieldsProductById = useMemo(initialValuesToFixAttributes, []);
  const initialValuesToFix = useSelector((state: RootStateType) =>
    getFixFieldsProductById(state, productId),
  );

  const updateAttributes = useCallback(
    async (payload) => {
      const editedAttributes = await dispatch(fixesAttributesAction(payload));
      return unwrapResult(editedAttributes);
    },
    [dispatch],
  );

  return {
    updateAttributes,
    initialValuesToFix,
    updateAttributesStatus,
    fetchProduct,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useFixesAttributes;
