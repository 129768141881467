import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { Box, BoxBaseProps, Link, LinkProperties } from '@nimbus-ds/components';
import { useTranslationCommon } from 'commons/hooks';

const DEFAULT_MAX_HEIGHT = 80;

export const showMoreContentBoxStyle: BoxBaseProps = {
  flexWrap: 'wrap',
  gap: '2',
  justifyContent: 'flex-start',
  display: 'flex',
};

interface ShowMoreContentProps {
  maxHeight?: number;
  contentName?: string;
  children: ReactNode;
  buttonStyles?: Omit<LinkProperties, 'children'>;
  buttonIconOpen?: ReactNode;
  buttonIconClose?: ReactNode;
  onClick?: () => void;
}

/**
 * Component that allows displaying or hiding content with a "Show More / Show Less" link.
 *
 * This component wraps a set of child elements and shows only a maximum amount of content defined.
 * If the content exceeds this maximum height, a "Show More" button is displayed to expand or collapse the content.
 *
 * @component
 * @param {number} [props.maxHeight] - The maximum height of the content before showing the "Show More" button
 * @param {contentName} [props.contentName] - Content name.
 * @param {ReactNode}[props.children] - The child elements to be shown or hidden.
 * @param {Function} [props.onClick] - An optional callback function to handle the click on the "Show More" button.
 * @example
 * // Example usage of the ShowMoreContent component
 * <ShowMoreContent>
 *   <p>This is an example of content that can be expanded or collapsed.</p>
 *   <p>More Lines 1</p>
 *   <p>More Lines 2</p>
 *   <p>More Lines 3</p>
 * </ShowMoreContent>
 */
export function ShowMoreContent({
  maxHeight = DEFAULT_MAX_HEIGHT,
  contentName = '',
  children,
  buttonStyles,
  buttonIconClose,
  buttonIconOpen,
  onClick,
}: Readonly<ShowMoreContentProps>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const t = useTranslationCommon('showMoreContent');
  const [hasMoreMaxHeight, setHasMoreMaxHeight] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const hideExcessRowsStyle: BoxBaseProps = {
    maxHeight: `${maxHeight}px`,
    overflow: 'hidden',
  };

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver((event) =>
      setHasMoreMaxHeight(event[0].target.scrollHeight > maxHeight),
    );
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, [maxHeight]);

  const hideExcessRows = () => {
    if (!showMore) return hideExcessRowsStyle;
    return {};
  };

  const handleClickShow = () => {
    if (onClick) {
      onClick();
      return;
    }
    setShowMore((prev) => !prev);
  };

  return (
    <Box flexDirection="column" gap="2" display="flex">
      <Box
        {...showMoreContentBoxStyle}
        {...hideExcessRows()}
        ref={containerRef}
      >
        {children}
      </Box>
      {hasMoreMaxHeight && (
        <Link
          {...buttonStyles}
          {...(!buttonStyles && { appearance: 'primary' })}
          as="button"
          onClick={handleClickShow}
        >
          {!showMore
            ? `${t('more')} ${contentName}`
            : `${t('less')} ${contentName}`}

          {showMore ? buttonIconOpen : buttonIconClose}
        </Link>
      )}
    </Box>
  );
}
