import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getMarketingAppsAction } from '../../../marketingAppsSlice';
import {
  getMarketingAppsStatus,
  getMarketingAppsData,
} from '../../../marketingAppsSlice/marketingAppsSelectors';

function useMarketingApps() {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(getMarketingAppsStatus);

  const marketingApps = useSelector(getMarketingAppsData);

  const getMarketingApps = useCallback(
    () => dispatch(getMarketingAppsAction()),
    [dispatch],
  );

  useEffect(() => {
    getMarketingApps();
  }, [getMarketingApps]);

  return {
    isLoading,
    isSuccess,
    isError,
    marketingApps,
    getMarketingApps,
  };
}

export default useMarketingApps;
