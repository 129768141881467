import { useMemo } from 'react';
import { AiUsageLimitResponseDto } from '@tiendanube/common';
import {
  FEATURE_AI_TIER,
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_DISABLED,
  TIER_LEGACY_1,
  TIER_LEGACY_2,
  TIER_LEGACY_3,
  TIER_LEGACY_TOP,
  TIER_TOP,
} from 'App/features';
import { useTranslationCommon } from 'commons/hooks';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useGetUsageLimitsQuery, useGetUsageQuery } from '../aiApi';

// Note: The backend was build to distinguish between text-based uses and image-based uses, given that image-based uses are significantly more expensive.
//       However, as a business decision we decided to unify both cases in a single limit, so the correct behavior until we decide to either go through with the split limits
//       or remove the distinction from the backend is to treat the backend responses as follows:
//        - Only look at the `text_generations` property of the limits response and treat it as the global limit.
//        - Add the values of both `text_generations` and `image_generations` in the usage response to get the total uses.
export const TEXT_GENERATION = 'text_generations';
export const IMAGE_GENERATION = 'image_generations';

type AiTier =
  | typeof TIER_DISABLED
  | typeof TIER_1
  | typeof TIER_2
  | typeof TIER_3
  | typeof TIER_TOP
  | typeof TIER_LEGACY_1
  | typeof TIER_LEGACY_2
  | typeof TIER_LEGACY_3
  | typeof TIER_LEGACY_TOP;

type UseAiUsageResponse = {
  isLoadingAiUses: boolean;
  aiUses: number;
  remainingAiUses: number;
  maxAiUsesInPlan: number;
  maxUsesInNextPlan: number;
  requiredTier: AiTier;
  refetchAiUses: () => void;
  aiUsesUpsellTitle?: string;
};

function useAiUsage(): UseAiUsageResponse {
  const t = useTranslationCommon();
  const {
    isSuccess: isSuccessUsage,
    isFetching: isFetchingUsage,
    data: usage,
    refetch: refetchAiUses,
  } = useGetUsageQuery();

  const currentTier: AiTier = useStatusFeatureByKey(FEATURE_AI_TIER).maxUse;

  const { isSuccess: isSuccessLimits, data: limits } = useGetUsageLimitsQuery();

  return useMemo(() => {
    const getUpsellTitle = (
      maxAiUsesInPlan: number,
      maxUsesInNextPlan: number,
    ) => {
      if (maxUsesInNextPlan === -1)
        return t('ai.remainingUses.upsellTitle.infinite');

      if (maxAiUsesInPlan === 0) {
        return t('ai.remainingUses.upsellTitle.nextTierFromZero', {
          maxUsesInNextPlan,
        });
      }
      return t('ai.remainingUses.upsellTitle.nextTier', {
        maxUsesInNextPlan,
        maxAiUsesInPlan,
      });
    };

    const isLoadingAiUses = !isSuccessUsage || !isSuccessLimits;
    if (isLoadingAiUses) {
      return {
        isLoadingAiUses,
        aiUses: 0,
        remainingAiUses: 0,
        maxAiUsesInPlan: 0,
        maxUsesInNextPlan: 0,
        requiredTier: TIER_DISABLED,
        refetchAiUses,
      };
    }

    const temporaryIncreaseUntilBackendReturns = isFetchingUsage ? 1 : 0;
    const aiUses =
      usage![TEXT_GENERATION] +
      usage![IMAGE_GENERATION] +
      temporaryIncreaseUntilBackendReturns;

    // Important: Order matters here because the legacy tiers could be considered as disabled
    if (isLegacyTier(currentTier)) {
      return {
        isLoadingAiUses,
        aiUses,
        remainingAiUses: -1,
        maxAiUsesInPlan: -1,
        maxUsesInNextPlan: -1,
        requiredTier: TIER_LEGACY_1,
        refetchAiUses,
      };
    }

    if (isDisabledInLegacyTier(currentTier, limits!)) {
      return {
        isLoadingAiUses,
        aiUses,
        remainingAiUses: 0,
        maxAiUsesInPlan: 0,
        maxUsesInNextPlan: -1,
        requiredTier: TIER_LEGACY_1,
        refetchAiUses,
        aiUsesUpsellTitle: getUpsellTitle(0, -1),
      };
    }

    const maxAiUsesInPlan =
      currentTier === TIER_DISABLED ? 0 : limits![currentTier][TEXT_GENERATION];

    const remainingAiUses =
      maxAiUsesInPlan === -1 ? -1 : Math.max(maxAiUsesInPlan - aiUses, 0);

    const requiredTier = calculateRequiredTier(aiUses, limits!);

    const maxUsesInNextPlan = limits![requiredTier][TEXT_GENERATION];

    return {
      isLoadingAiUses,
      aiUses,
      remainingAiUses,
      maxAiUsesInPlan,
      maxUsesInNextPlan,
      requiredTier,
      refetchAiUses,
      aiUsesUpsellTitle: getUpsellTitle(maxAiUsesInPlan, maxUsesInNextPlan),
    };
  }, [
    currentTier,
    isSuccessLimits,
    isSuccessUsage,
    isFetchingUsage,
    limits,
    refetchAiUses,
    usage,
    t,
  ]);
}

function calculateRequiredTier(
  uses: number,
  limits: AiUsageLimitResponseDto,
): AiTier {
  const tiers: AiTier[] = [TIER_1, TIER_2, TIER_3, TIER_TOP];

  // Find the first tier that has a limit higher than the number of uses
  for (const tier of tiers) {
    const limit = limits[tier][TEXT_GENERATION];

    if (limit < 0) {
      return tier;
    }
    if (limit > uses) {
      return tier;
    }
  }

  // The definition of the limits guarantees the loop above finds the desired tier, but this is required as a failsafe for type safety
  return limitsAreLegacy(limits) ? TIER_LEGACY_1 : TIER_1;
}

function isLegacyTier(currentTier: string) {
  return currentTier.indexOf('legacy') !== -1;
}

function isDisabledInLegacyTier(
  currentTier: string,
  limits: AiUsageLimitResponseDto,
) {
  if (currentTier !== TIER_DISABLED) return false;

  return limitsAreLegacy(limits);
}

function limitsAreLegacy(limits: AiUsageLimitResponseDto) {
  for (const tier in limits) {
    if (tier.indexOf('legacy') === -1) return false;
  }
  return true;
}
export default useAiUsage;
