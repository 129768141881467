import { useState } from 'react';
import { IconButton, Title, Tooltip } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { NavigationMenuItemResponseDto } from '@tiendanube/common';
import { BaseCard } from '@tiendanube/components';
import { ModalConfirmationBase } from 'App/components';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { useTranslationOnline } from 'domains/Online';
import { useDeleteMenuMutation } from 'domains/Online/Menus/MenusApi';
import Skeleton from './Skeleton';
import MenuItemDataList from '../MenuItemDataList';
import MenuItemModal from '../MenuItemModal';

interface MenuCardProps {
  id?: string;
  name?: string;
  handle?: string;
  items: NavigationMenuItemResponseDto[];
  showSkeleton: boolean;
  onAddMenuItem: (item: NavigationMenuItemResponseDto) => void;
  onDeleteMenuItem: (itemId: string) => void;
  onDropMenuItem: (items: NavigationMenuItemResponseDto[]) => void;
  onEditMenuItem: (item: NavigationMenuItemResponseDto) => void;
}

function MenuCard({
  id,
  name,
  handle,
  items,
  showSkeleton = false,
  onAddMenuItem,
  onDeleteMenuItem,
  onDropMenuItem,
  onEditMenuItem,
}: MenuCardProps) {
  const t = useTranslationOnline('menus.list.menuCard');
  const [deleteMenu, { status, isLoading: isDeleting }] =
    useDeleteMenuMutation();

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [showMenuItemModal, openMenuItemModal, closeMenuItemModal] = useModal();
  const isMainMenu = handle === 'navigation';

  const handleDeleteMenu = () => {
    if (id) deleteMenu(id);
  };

  const handleOpenEditMenuItemModal = (itemId: string) => {
    setSelectedItemId(itemId);
    openMenuItemModal();
  };

  const handleOpenAddMenuItemModal = () => {
    setSelectedItemId('');
    openMenuItemModal();
  };

  const selectedItem = selectedItemId
    ? items.find((item) => item.id === selectedItemId)
    : undefined;

  useToastStatus({
    error: t('deleteToast.error'),
    success: t('deleteToast.success'),
    status: toStatusType(status),
    onSuccess: closeDeleteModal,
    onError: closeDeleteModal,
  });

  return (
    <>
      {showSkeleton ? (
        <Skeleton />
      ) : (
        <BaseCard>
          <BaseCard.Header>
            <Stack justify="space-between" align="center">
              <Title as="h3">{isMainMenu ? t('title') : name}</Title>
              {!isMainMenu && (
                <Tooltip content={t('actions.delete')}>
                  <IconButton
                    size="32px"
                    source={<TrashIcon />}
                    onClick={openDeleteModal}
                  />
                </Tooltip>
              )}
            </Stack>
          </BaseCard.Header>
          <BaseCard.Body padding="none">
            <MenuItemDataList
              items={items}
              onOpenMenuItemModal={handleOpenAddMenuItemModal}
              onDeleteMenuItem={onDeleteMenuItem}
              onDropMenuItem={onDropMenuItem}
              onEditMenuItem={handleOpenEditMenuItemModal}
            />
          </BaseCard.Body>
        </BaseCard>
      )}
      <ModalConfirmationBase
        show={showDeleteModal}
        title={t('deleteMenuModal.title', { name })}
        text={t('deleteMenuModal.info')}
        label={t('deleteMenuModal.confirmButton')}
        labelCancel={t('deleteMenuModal.cancelButton')}
        onConfirm={handleDeleteMenu}
        onCancel={closeDeleteModal}
        disabled={isDeleting}
      />
      <MenuItemModal
        currentMenuId={id}
        item={selectedItem}
        show={showMenuItemModal}
        onAdd={onAddMenuItem}
        onClose={closeMenuItemModal}
        onEdit={onEditMenuItem}
      />
    </>
  );
}

MenuCard.Skeleton = Skeleton;

export default MenuCard;
