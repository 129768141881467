import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getAcquisitionSummaryData,
  getAcquisitionSummaryStatus,
} from 'domains/Statistics/selectors';
import { getAcquisitionSummary } from 'domains/Statistics/statisticsSlice';

function useAcquisitionSummary() {
  const dispatch = useAppDispatch();
  const status = useSelector(getAcquisitionSummaryStatus);
  const data = useSelector(getAcquisitionSummaryData);
  const { openFilter } = useStatisticsFilterContext();

  const fetchAcquisitionSummary = useCallback(() => {
    dispatch(getAcquisitionSummary());
  }, [dispatch]);

  const { hasComparedFilter } = useStatisticsStoredFilters(
    fetchAcquisitionSummary,
  );

  const isEmpty =
    status.isSuccess &&
    !Object.values(data.results).some((item) => item.values.length > 0);

  return {
    data,
    fetchAcquisitionSummary,
    hasComparedFilter,
    openFilter,
    isEmpty,
    ...status,
  };
}

export default useAcquisitionSummary;
