import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  ResultPaginationResponseDto,
  StatisticsFiltersParamsRequestDto,
  StatisticsGlobalInfoResponseDto,
  StatisticsOrdersResponseDto,
  StatisticsProductsInNumberResponseDto,
  StatisticsProductsResponseDto,
  StatisticsStoreConversionResponseDto,
  StatisticsProductsInNumberFiltersParamsRequestDto,
  StatisticsGetSummaryResponseDto,
  StatisticsProductsSummaryResponseDto,
  StatisticsCustomersResponseDto,
  StatisticsProvinceRankValueResponseDto,
  StatisticsShippingRankByProvinceResponseDto,
  StatisticsPaymentsPerformanceResponseDto,
  StatisticsShippingSummaryResponseDto,
  StatisticsAcquisitionSummaryResponseDto,
} from '@tiendanube/common';
import { logout } from 'domains/Auth';
import { initialState } from './constants';
import { StatisticsDailyType, ThunkStateType } from './types';
import {
  createGetStatsProductsFiltersByItem,
  getStatisticsFilters,
} from '../selectors';
import statisticsServices from '../statisticsService';
import {
  FiltersStatisticsInterface,
  StatsProductsItemType,
  StatsProductsFilters,
} from '../types';

export const getDaily = createAsyncThunk<StatisticsDailyType>(
  'statistics/getDaily',
  async () => {
    const statisticsDaily = await statisticsServices.getDaily();
    return statisticsDaily;
  },
);

export const getOrders = createAsyncThunk<StatisticsOrdersResponseDto>(
  'statistics/orders',
  async () => {
    const statisticsOrders = await statisticsServices.getOrders();
    return statisticsOrders;
  },
);

export const getProducts = createAsyncThunk<StatisticsProductsResponseDto>(
  'statistics/products',
  async () => {
    const statisticsProducts = await statisticsServices.getProducts();
    return statisticsProducts;
  },
);

export const getGlobalInfo = createAsyncThunk<
  StatisticsGlobalInfoResponseDto,
  undefined,
  ThunkStateType
>('statistics/getGlobalInfo', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);

  return statisticsServices.getGlobalInfo(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const getStoreConversion = createAsyncThunk<
  StatisticsStoreConversionResponseDto[],
  undefined,
  ThunkStateType
>('statistics/getConversionStore', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getStoreConversion(
    filters as StatisticsProductsInNumberFiltersParamsRequestDto,
  );
});

export const getStatsProducts = createAsyncThunk<
  ResultPaginationResponseDto<StatisticsProductsInNumberResponseDto[]>,
  StatsProductsItemType,
  ThunkStateType
>('statistics/getStatsProducts', async (item, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  const getProductsFilters = createGetStatsProductsFiltersByItem();
  const statsProductsFilters = getProductsFilters(state, item);

  return statisticsServices.getStatsProducts({
    ...filters,
    ...statsProductsFilters,
    type: item,
  } as StatisticsProductsInNumberFiltersParamsRequestDto);
});

export const getSummaryAction = createAsyncThunk<
  StatisticsGetSummaryResponseDto,
  undefined,
  ThunkStateType
>('statistics/fetchGlobalInfoTable', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getSummary(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const getFreeTrialAction = createAsyncThunk(
  'statistics/getFreeTrialAction',
  statisticsServices.getFreeTrial,
);

export const refreshFreeTrialAction = createAsyncThunk(
  'statistics/refreshFreeTrialAction',
  statisticsServices.getFreeTrial,
);

export const initFreeTrialAction = createAsyncThunk(
  'statistics/initFreeTrialAction',
  statisticsServices.initFreeTrial,
);

export const getStoreHasGAAction = createAsyncThunk(
  'statistics/getStoreHasGA',
  statisticsServices.getStoreHasGA,
);

export const getCustomersAction = createAsyncThunk<
  StatisticsCustomersResponseDto,
  undefined,
  ThunkStateType
>('statistics/getCustomersAction', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getCustomers(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const getProductsSummary = createAsyncThunk<
  StatisticsProductsSummaryResponseDto,
  undefined,
  ThunkStateType
>('statistics/getProductsSummary', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  const response = await statisticsServices.getProductsSummary(
    filters as StatisticsFiltersParamsRequestDto,
  );
  return response;
});

export const getPaymentsSummary = createAsyncThunk<
  StatisticsPaymentsPerformanceResponseDto,
  undefined,
  ThunkStateType
>('statistics/getPaymentsSummary', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getPaymentsSummary(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const getAcquisitionSummary = createAsyncThunk<
  StatisticsAcquisitionSummaryResponseDto,
  undefined,
  ThunkStateType
>('statistics/getAcquisition', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return await statisticsServices.getAcquisitionSummary(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const getShippingSummary = createAsyncThunk<
  StatisticsShippingSummaryResponseDto,
  undefined,
  ThunkStateType
>('statistics/getShippingSummary', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getShippingSummary(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const fetchShippingSalesByProvince = createAsyncThunk<
  StatisticsShippingRankByProvinceResponseDto,
  undefined,
  ThunkStateType
>('statistics/fetchShippingSalesByProvince', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getShippingSalesByProvince(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const fetchShippingRevenueByProvince = createAsyncThunk<
  StatisticsShippingRankByProvinceResponseDto,
  undefined,
  ThunkStateType
>('statistics/fetchShippingRevenueByProvince', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);

  return statisticsServices.getShippingRevenueByProvince(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const fetchRankProvincesByShippingCost = createAsyncThunk<
  StatisticsProvinceRankValueResponseDto[],
  undefined,
  ThunkStateType
>('statistics/fetchRankProvincesByShippingCost', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getRankProvincesByShippingCost(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

export const fetchRankProvincesByShippingTime = createAsyncThunk<
  StatisticsProvinceRankValueResponseDto[],
  undefined,
  ThunkStateType
>('statistics/fetchRankProvincesByShippingTime', (_, thunkApi) => {
  const state = thunkApi.getState();
  const filters = getStatisticsFilters(state);
  return statisticsServices.getRankProvincesByShippingTime(
    filters as StatisticsFiltersParamsRequestDto,
  );
});

const statisticsSlice = createSlice({
  name: 'statisticsSlice',
  initialState,
  reducers: {
    init(state) {
      state.status = 'loading';
    },
    changeFilter(state, action: PayloadAction<FiltersStatisticsInterface>) {
      state.filters = action.payload;
    },
    changeStatsProductsFilter(
      state,
      action: PayloadAction<StatsProductsFilters>,
    ) {
      state.statsProducts[action.payload.item].filters = {
        ...state.statsProducts[action.payload.item].filters,
        ...action.payload.values,
      };
    },
    clearInitFreeTrialStatus(state) {
      state.freeTrial.post.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder.addCase(getDaily.pending, (state) => {
      state.status = 'loading';
      state.daily = null;
    });

    builder.addCase(getDaily.fulfilled, (state, action) => {
      state.daily = action.payload;
      state.status = 'success';
    });

    builder.addCase(getDaily.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(getOrders.pending, (state) => {
      state.status = 'loading';
      state.orders = null;
    });

    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
      state.status = 'success';
    });

    builder.addCase(getOrders.rejected, (state) => {
      state.status = 'error';
    });

    builder.addCase(getProducts.pending, (state) => {
      state.status = 'loading';
      state.products = null;
    });

    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.status = 'success';
    });

    builder.addCase(getProducts.rejected, (state) => {
      state.status = 'error';
    });

    // stats screen

    builder.addCase(getGlobalInfo.fulfilled, (state, action) => {
      state.globalInfoEntity.status = 'success';
      state.globalInfoEntity.data = action.payload;
    });

    builder.addCase(getGlobalInfo.rejected, (state) => {
      state.globalInfoEntity.status = 'error';
    });

    builder.addCase(getGlobalInfo.pending, (state) => {
      state.globalInfoEntity.status = 'loading';
    });

    builder.addCase(getStoreConversion.fulfilled, (state, action) => {
      state.conversionStoreEntity.status = 'success';
      state.conversionStoreEntity.data = action.payload;
    });

    builder.addCase(getStoreConversion.rejected, (state) => {
      state.conversionStoreEntity.status = 'error';
    });

    builder.addCase(getStoreConversion.pending, (state) => {
      state.conversionStoreEntity.status = 'loading';
    });

    builder.addCase(getStatsProducts.fulfilled, (state, action) => {
      state.statsProducts[action.meta.arg].status = 'success';
      state.statsProducts[action.meta.arg].results = action.payload.results;
      state.statsProducts[action.meta.arg].pagination =
        action.payload.pagination;
    });

    builder.addCase(getStatsProducts.rejected, (state, action) => {
      state.statsProducts[action.meta.arg].status = 'error';
    });

    builder.addCase(getStatsProducts.pending, (state, action) => {
      state.statsProducts[action.meta.arg].status = 'loading';
    });

    builder
      .addCase(getSummaryAction.pending, (state) => {
        state.summary.data = null;
        state.summary.status = 'loading';
      })
      .addCase(getSummaryAction.rejected, (state) => {
        state.summary.data = null;
        state.summary.status = 'error';
      })
      .addCase(getSummaryAction.fulfilled, (state, action) => {
        state.summary.data = action.payload;
        state.summary.status = 'success';
      });

    builder
      .addCase(getFreeTrialAction.pending, (state) => {
        state.freeTrial.status = 'loading';
        state.freeTrial.data = null;
      })
      .addCase(getFreeTrialAction.rejected, (state) => {
        state.freeTrial.status = 'error';
      })
      .addCase(getFreeTrialAction.fulfilled, (state, action) => {
        state.freeTrial.status = 'success';
        state.freeTrial.data = action.payload;
      });

    builder
      .addCase(refreshFreeTrialAction.pending, (state) => {
        state.freeTrial.status = 'loading';
      })
      .addCase(refreshFreeTrialAction.fulfilled, (state, action) => {
        state.freeTrial.status = 'success';
        state.freeTrial.data = action.payload;
      })
      .addCase(refreshFreeTrialAction.rejected, (state) => {
        state.freeTrial.data = null;
        state.freeTrial.status = 'error';
      });

    builder
      .addCase(initFreeTrialAction.pending, (state) => {
        state.freeTrial.post.status = 'loading';
      })
      .addCase(initFreeTrialAction.rejected, (state) => {
        state.freeTrial.post.status = 'error';
        state.freeTrial.data = null;
      })
      .addCase(initFreeTrialAction.fulfilled, (state, action) => {
        state.freeTrial.post.status = 'success';
        state.freeTrial.data = action.payload;
      });

    builder
      .addCase(getCustomersAction.pending, (state) => {
        state.customers.status = 'loading';
        state.customers.data = null;
      })
      .addCase(getCustomersAction.rejected, (state) => {
        state.customers.status = 'error';
      })
      .addCase(getCustomersAction.fulfilled, (state, action) => {
        state.customers.status = 'success';
        state.customers.data = action.payload;
      });

    builder
      .addCase(getProductsSummary.pending, (state) => {
        state.productsSummary.status = 'loading';
      })
      .addCase(getProductsSummary.rejected, (state) => {
        state.productsSummary.status = 'error';
      })
      .addCase(getProductsSummary.fulfilled, (state, action) => {
        state.productsSummary.status = 'success';
        state.productsSummary.data = action.payload;
      });

    builder
      .addCase(getPaymentsSummary.pending, (state) => {
        state.paymentsSummary.status = 'loading';
      })
      .addCase(getPaymentsSummary.rejected, (state) => {
        state.paymentsSummary.status = 'error';
      })
      .addCase(getPaymentsSummary.fulfilled, (state, action) => {
        state.paymentsSummary.status = 'success';
        state.paymentsSummary.data = action.payload;
      });

    builder
      .addCase(getAcquisitionSummary.pending, (state) => {
        state.acquisitionSummary.status = 'loading';
      })
      .addCase(getAcquisitionSummary.rejected, (state) => {
        state.acquisitionSummary.status = 'error';
      })
      .addCase(getAcquisitionSummary.fulfilled, (state, action) => {
        state.acquisitionSummary.status = 'success';
        state.acquisitionSummary.data = action.payload;
      });

    builder
      .addCase(getShippingSummary.pending, (state) => {
        state.shippingSummary.status = 'loading';
      })
      .addCase(getShippingSummary.rejected, (state) => {
        state.shippingSummary.status = 'error';
      })
      .addCase(getShippingSummary.fulfilled, (state, action) => {
        state.shippingSummary.status = 'success';
        state.shippingSummary.data = action.payload;
      });

    builder
      .addCase(fetchShippingSalesByProvince.pending, (state) => {
        state.shippingRankByProvinceBySales.status = 'loading';
      })
      .addCase(fetchShippingSalesByProvince.rejected, (state) => {
        state.shippingRankByProvinceBySales.status = 'error';
      })
      .addCase(fetchShippingSalesByProvince.fulfilled, (state, action) => {
        state.shippingRankByProvinceBySales.status = 'success';
        state.shippingRankByProvinceBySales.data = action.payload;
      });

    builder
      .addCase(fetchShippingRevenueByProvince.pending, (state) => {
        state.shippingRevenueByProvince.status = 'loading';
      })
      .addCase(fetchShippingRevenueByProvince.rejected, (state) => {
        state.shippingRevenueByProvince.status = 'error';
      })
      .addCase(fetchShippingRevenueByProvince.fulfilled, (state, action) => {
        state.shippingRevenueByProvince.status = 'success';
        state.shippingRevenueByProvince.data = action.payload;
      });

    builder
      .addCase(fetchRankProvincesByShippingCost.pending, (state) => {
        state.rankProvincesByShippingCost.status = 'loading';
      })
      .addCase(fetchRankProvincesByShippingCost.rejected, (state) => {
        state.rankProvincesByShippingCost.status = 'error';
      })
      .addCase(fetchRankProvincesByShippingCost.fulfilled, (state, action) => {
        state.rankProvincesByShippingCost.status = 'success';
        state.rankProvincesByShippingCost.data = action.payload;
      });

    builder
      .addCase(fetchRankProvincesByShippingTime.pending, (state) => {
        state.rankProvincesByShippingTime.status = 'loading';
      })
      .addCase(fetchRankProvincesByShippingTime.rejected, (state) => {
        state.rankProvincesByShippingTime.status = 'error';
      })
      .addCase(fetchRankProvincesByShippingTime.fulfilled, (state, action) => {
        state.rankProvincesByShippingTime.status = 'success';
        state.rankProvincesByShippingTime.data = action.payload;
      });

    builder
      .addCase(getStoreHasGAAction.pending, (state) => {
        state.storeHasGA.status = 'loading';
      })
      .addCase(getStoreHasGAAction.rejected, (state) => {
        state.storeHasGA.status = 'error';
      })
      .addCase(getStoreHasGAAction.fulfilled, (state, action) => {
        state.storeHasGA.status = 'success';
        state.storeHasGA.data = action.payload;
      });
  },
});

export const { reducer } = statisticsSlice;
export const {
  changeFilter,
  changeStatsProductsFilter,
  clearInitFreeTrialStatus,
} = statisticsSlice.actions;
