import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Text } from '@tiendanube/components';

interface TextStockProps {
  variation: number | null;
}

function TextStock({ variation }: TextStockProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const text =
    variation !== null
      ? `${variation} ${t('products.stockHistoryModal.labelStock.inStock')}`
      : t('products.stockHistoryModal.labelStock.infiniteStock');

  return <Text>{text}</Text>;
}

export default TextStock;
