import { useLayoutEffect } from 'react';
import {
  Text,
  Button,
  Icon,
  Link,
  Title,
  Spinner,
} from '@nimbus-ds/components';
import { ExclamationTriangleIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { PATH } from 'App/components/Menu/components/utils';
import { useNavTabs } from 'App/components/Navtabs';
import { useHandleMenuClick } from 'App/hooks';
import { IonPageStratus, Stack } from 'commons/components';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useLoggedAdminLink, useIsMobileDevice } from 'domains/Auth/hooks';
import useLogout from 'domains/Auth/pages/LogoutPage/useLogout';
import './CanceledStorePage.scss';

function CanceledStorePage() {
  const t = useTranslationAccount();
  const { hideSideBar, hideNavTabs, showNavTabs } = useNavTabs();
  const logout = useLogout();
  const isMobileDevice = useIsMobileDevice();
  const { goToLoggedAdminLink, isLoading } = useLoggedAdminLink();
  const { handleClickItem } = useHandleMenuClick();

  useLayoutEffect(() => {
    hideSideBar();
    hideNavTabs();

    return () => {
      showNavTabs();
    };
  }, [hideSideBar, hideNavTabs, showNavTabs]);

  const handleRecoverStore = async () => {
    await goToLoggedAdminLink('/account/checkout/start/');
  };

  const handleLogout = isMobileDevice ? handleClickItem : logout;

  return (
    <div className="stratus--canceled-store-page">
      <IonPageStratus>
        <Stack column align="center" justify="center">
          <ExclamationTriangleIcon size="large" />
          <Title as="h4" textAlign="center">
            {t('canceledStorePage.title')}
          </Title>
          <Text textAlign="center">{t('canceledStorePage.subTitle')}</Text>
          <Stack align="center" justify="center" wrap>
            <Button onClick={handleRecoverStore} appearance="primary">
              {isLoading && <Spinner color="currentColor" size="small" />}
              {t('canceledStorePage.recoverStore')}
              <Icon color="currentColor" source={<ExternalLinkIcon />} />
            </Button>
            <Link
              onClick={handleLogout}
              appearance="primary"
              as="a"
              textDecoration="none"
              href={isMobileDevice ? PATH.logout : undefined}
            >
              {t('canceledStorePage.logout')}
            </Link>
          </Stack>
        </Stack>
      </IonPageStratus>
    </div>
  );
}

export default CanceledStorePage;
