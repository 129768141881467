import { Route, Switch } from 'react-router-dom';
import ToggleMigratedDomain from 'App/components/Migrations/ToggleMigratedDomain';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { HideNavTabs } from 'App/components/Navtabs';
import { MIGRATION_PRODUCTS_FORCE } from 'App/featuresFlags';
import {
  useGetTags,
  useHasPermission,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { CATALOG_ROUTES } from './catalogRoutes';
import StockHistoryProvider from './Products/pages/components/StockHistoryModal/StockHistoryProvider';
import EditProductPage from './Products/pages/EditProductPage';
import ImportAndExportProductsPage from './Products/pages/ImportAndExportProductsPage';
import ImportProductsPage from './Products/pages/ImportAndExportProductsPage/ImportProductsPage';
import NewProductPage from './Products/pages/NewProductPage';
import OrganizeFeaturedProductsPage from './Products/pages/OrganizeFeaturedProductsPage';
import OrganizeProductsPage from './Products/pages/OrganizeProductsPage';
import ProductListPage from './Products/pages/ProductListPage';

function Products(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();
  const tags = useGetTags();
  const hasProductsForceTag = tags.includes(MIGRATION_PRODUCTS_FORCE);
  const canImportExportProductsCsv = useHasPermission(
    'import_export_products_csv',
  );

  return (
    <>
      <Route path={CATALOG_ROUTES.productList} exact>
        <ToggleMigratedDomain domain="products" force={hasProductsForceTag}>
          <ProductListPage />
        </ToggleMigratedDomain>
      </Route>
      <Switch>
        <Route path={CATALOG_ROUTES.newProduct} exact>
          <StockHistoryProvider>
            <HideNavTabs />
            <ToggleMigratedDomain
              domain="productsnew"
              force={hasProductsForceTag}
            >
              <NewProductPage />
            </ToggleMigratedDomain>
          </StockHistoryProvider>
        </Route>
        <Route path={CATALOG_ROUTES.organizeFeaturedProducts} exact>
          <OrganizeFeaturedProductsPage />
        </Route>
        <Route path={CATALOG_ROUTES.organizeProducts} exact>
          {isMobileDevice ? (
            <OrganizeProductsPage />
          ) : (
            <ToggleMigratedOldDomain domain="productsorganize">
              <OrganizeProductsPage />
            </ToggleMigratedOldDomain>
          )}
        </Route>
        {canImportExportProductsCsv && (
          <Route path={CATALOG_ROUTES.importAndExportProducts} exact>
            {isMobileDevice ? (
              <>
                <HideNavTabs />
                <ImportAndExportProductsPage />
              </>
            ) : (
              <ToggleMigratedOldDomain domain="productsimport">
                <ImportAndExportProductsPage />
              </ToggleMigratedOldDomain>
            )}
          </Route>
        )}
        {canImportExportProductsCsv && (
          <Route path={CATALOG_ROUTES.importProducts} exact>
            {isMobileDevice ? (
              <>
                <HideNavTabs />
                <ImportProductsPage />
              </>
            ) : (
              <ToggleMigratedOldDomain domain="productsimport">
                <ImportProductsPage />
              </ToggleMigratedOldDomain>
            )}
          </Route>
        )}
        <Route path={CATALOG_ROUTES.editProduct}>
          <StockHistoryProvider>
            <HideNavTabs />
            <ToggleMigratedDomain
              domain="products-detail"
              force={hasProductsForceTag}
            >
              <EditProductPage />
            </ToggleMigratedDomain>
          </StockHistoryProvider>
        </Route>
      </Switch>
    </>
  );
}

export default Products;
