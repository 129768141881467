import { useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

interface KeyboardStateResult {
  isOpen?: boolean;
  keyboardHeight?: number;
}

const platform = Capacitor.getPlatform();

const isMobile = () => ['android', 'ios'].includes(platform);

function useKeyboard(): KeyboardStateResult {
  const [keyboardState, setKeyboardState] = useState<KeyboardStateResult>({
    isOpen: false,
    keyboardHeight: 0,
  });

  useEffect(() => {
    const showCallback = ({ keyboardHeight }) => {
      setKeyboardState({
        isOpen: true,
        keyboardHeight,
      });
    };
    const hideCallback = () => {
      setKeyboardState({
        isOpen: false,
        keyboardHeight: 0,
      });
    };
    if (isMobile()) {
      Keyboard.addListener('keyboardDidShow', showCallback);
      Keyboard.addListener('keyboardDidHide', hideCallback);
    }

    return () => {
      if (isMobile()) {
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  return {
    isOpen: keyboardState.isOpen,
    keyboardHeight: keyboardState.keyboardHeight,
  };
}

export default useKeyboard;
