import { Alert, Button, Text } from '@nimbus-ds/components';
import { ShowByTag } from 'App/components';
import { MUST_REFRESH_MERCADOPAGO_TOKEN } from 'App/featuresFlags';
import { useBoolean } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin/goToAdmin';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useMercadoPagoCredentialsAlert from './useMercadoPagoCredentialsAlert/useMercadoPagoCredentialsAlert';

function MercadoPagoCredentialsAlert() {
  const t = useTranslationDashboard('paymentMessages.mercadoPago.credentials');
  const [show, , close] = useBoolean(true);
  const { removeNotifications } = useMercadoPagoCredentialsAlert();

  const handleCloseAlert = () => {
    removeNotifications();
    close();
  };

  return (
    <ShowByTag tag={MUST_REFRESH_MERCADOPAGO_TOKEN}>
      <Alert
        show={show}
        onRemove={handleCloseAlert}
        appearance="danger"
        title={t('title')}
      >
        <Text>{t('description')}</Text>
        <Button
          appearance="primary"
          onClick={goToAdmin('payments/connect/mercadopago/start/')}
        >
          {t('cta')}
        </Button>
      </Alert>
    </ShowByTag>
  );
}

export default MercadoPagoCredentialsAlert;
