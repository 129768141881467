import { FreeShippingRequestDto } from '@tiendanube/common';
import { FreeShippingFormState } from './types';

export const payloadDigest = (
  data: FreeShippingFormState,
): FreeShippingRequestDto => {
  const {
    priceMinimum,
    categoriesType,
    categories,
    zonesType,
    zones,
    ...rest
  } = data;

  return {
    ...rest,
    categories: categoriesType === 'some' ? categories : [],
    zones: zonesType === 'some' ? zones : [],
    priceMinimum: Number(priceMinimum),
  };
};
