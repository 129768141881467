import { ReactElement, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './ReactPortal.scss';

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  return wrapperElement;
}

export interface ReactPortalProps {
  children: ReactElement;
  wrapperId: string;
}

function ReactPortal({
  children,
  wrapperId = 'react-portal-wrapper',
}: ReactPortalProps) {
  const wrapperElement = useRef<HTMLElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let element = document.getElementById(wrapperId);
    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      setLoaded(true);
      element = createWrapperAndAppendToBody(wrapperId);
      document.body.appendChild(element);
    }
    wrapperElement.current = element;

    const timeOut = setTimeout(() => {
      setLoaded(true);
    }, 0);

    return () => {
      clearTimeout(timeOut);
      // delete the programatically created element
      if (loaded && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId, loaded]);

  // wrapperElement state will be null on very first render.
  if (wrapperElement.current === null) return null;

  if (!loaded) return null;

  return ReactDOM.createPortal(children, wrapperElement.current);
}

export default ReactPortal;
