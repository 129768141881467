import { Icon, Link } from '@nimbus-ds/components';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon, RedoIcon } from '@tiendanube/icons';

interface ErrorScreenProps {
  onRetry: () => void;
  description?: string;
}

function ErrorScreen({ onRetry, description }: ErrorScreenProps) {
  const { t } = useTranslation('common');
  const descriptionText = description || t('errorScreen.description');
  return (
    <EmptyMessage
      title=""
      text={descriptionText}
      icon={<ExclamationTriangleIcon size={32} />}
      actions={
        <Link appearance="primary" onClick={onRetry}>
          <Icon color="currentColor" source={<RedoIcon />} />
          {t('errorScreen.link')}
        </Link>
      }
    />
  );
}

export default ErrorScreen;
