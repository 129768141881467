import { Label } from '@tiendanube/components';
import { MailIcon } from '@tiendanube/icons';
import { AbandonedCartEmalConfg } from 'domains/Orders/AbandonedCart/abandonedCartsSlice';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface EmailConfigStatusLabelProps {
  status: AbandonedCartEmalConfg;
  isLoading?: boolean;
}

function EmailConfigStatusLabel({
  status,
  isLoading,
}: EmailConfigStatusLabelProps) {
  const t = useTranslationOrders();

  const TEXT_STATUS = {
    manual: t('abandonedCarts.labelManualSend'),
    automatic: t('abandonedCarts.labelAutomaticSend'),
  };

  if (isLoading) return <Label.Skeleton />;

  return (
    <Label
      icon={MailIcon}
      id="abandonedCartEmailConfig"
      appearance="primary"
      label={TEXT_STATUS[status]}
    />
  );
}

export default EmailConfigStatusLabel;
