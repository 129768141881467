import { Box, Text } from '@nimbus-ds/components';
import { STYLE_STATS_DAILY_VALUE_CARD } from './StatsDailyValueCard';

function Skeleton() {
  return (
    <Box {...STYLE_STATS_DAILY_VALUE_CARD}>
      <Box display="flex" gap="2" flexDirection="column">
        <Box display="flex" gap="2">
          <Text.Skeleton />
        </Box>
        <Text.Skeleton />
      </Box>
    </Box>
  );
}

export default Skeleton;
