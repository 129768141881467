const metafieldDigest = (filtersParams: Record<string, string>) => {
  let othersFilters = {};
  let metafieldsFilters = {};
  Object.entries(filtersParams).forEach(([key, value]) => {
    if (key.startsWith('mf-') && value !== '') {
      metafieldsFilters = {
        ...metafieldsFilters,
        [key.split('mf-')[1]]: `${value}`,
      };
      return;
    }
    if (key === 'page' || key === 'perPage') {
      othersFilters = { ...othersFilters, [key]: value };
    }
  });

  const metafieldsFiltersArray = Object.entries(metafieldsFilters);

  return {
    ...othersFilters,
    metafields: metafieldsFiltersArray.length
      ? metafieldsFiltersArray
      : undefined,
  };
};

export function getCustomerAdvancedDigest(filters: Record<string, string>) {
  return metafieldDigest(filters);
}
