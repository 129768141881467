import { ChangeEvent } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface PasswordCardProps {
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors?: Partial<Record<string, any>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function PasswordCard({
  values,
  errors,
  requestErrors,
  onChange,
}: Readonly<PasswordCardProps>) {
  const t = useTranslationConfigurations('userFormPage');

  const getCurrentPasswordError = () => {
    if (errors.currentPassword) return t(`errors.${errors.currentPassword}`);
    if (requestErrors?.current_password)
      return t(`errors.currentPasswordInvalid`);
    return undefined;
  };

  const getNewPasswordError = () => {
    if (errors.password) return t(`errors.${errors.password}`);
    if (requestErrors?.new_password || requestErrors?.password)
      return t('errors.newPasswordLeaked');
    return undefined;
  };

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('passwordCard.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column>
          {values.currentPassword !== undefined && (
            <FormField.Input
              name="currentPassword"
              type="password"
              label={t('passwordCard.currentPassword')}
              value={values.currentPassword}
              onChange={onChange}
              showHelpText={!!getCurrentPasswordError()}
              helpText={getCurrentPasswordError()}
              appearance={getCurrentPasswordError() ? 'danger' : undefined}
            />
          )}
          <FormField.Input
            name="password"
            type="password"
            label={t('passwordCard.password')}
            value={values.password}
            onChange={onChange}
            showHelpText={!!getNewPasswordError()}
            helpText={getNewPasswordError()}
            appearance={getNewPasswordError() ? 'danger' : undefined}
          />
          <FormField.Input
            name="confirmPassword"
            type="password"
            label={t('passwordCard.confirmPassword')}
            value={values.confirmPassword}
            onChange={onChange}
            showHelpText={!!errors.confirmPassword}
            helpText={
              errors.confirmPassword
                ? t(`errors.${errors.confirmPassword}`)
                : undefined
            }
            appearance={errors.confirmPassword ? 'danger' : undefined}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default PasswordCard;
