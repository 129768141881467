import { ChangeEvent } from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NavigationMenuItemType } from '@tiendanube/common';
import { useTranslationOnline } from 'domains/Online';
import { useMenuRedirectToOptions } from '../../hooks';

interface SelectRedirectToProps {
  currentMenuId?: string;
  selected?: NavigationMenuItemType;
  onSelect: (selected: NavigationMenuItemType) => void;
}

function SelectRedirectTo({
  onSelect,
  selected,
  currentMenuId,
}: SelectRedirectToProps) {
  const t = useTranslationOnline('menus.menuItemModal');
  const { isLoading, options } = useMenuRedirectToOptions(currentMenuId);

  const handleSelect = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    onSelect(value as NavigationMenuItemType);
  };

  if (isLoading) return <Select.Skeleton width="100%" />;

  return (
    <FormField.Select
      id="redirect-to"
      name="redirect-to"
      label={t('redirectTo')}
      onChange={handleSelect}
      defaultValue={selected}
    >
      {options.map(({ label, value }) => (
        <Select.Option key={value} label={label} value={value} />
      ))}
    </FormField.Select>
  );
}

export default SelectRedirectTo;
