export const CHECKOUT_BASE_ROUTE = '/account/checkout';

export const FROM_PAYMENT_QUERY = 'fromPayment';
export const AFTER_PAY_ACTION_QUERY = 'afterPay';
export const AFTER_PAY_CALLBACK_QUERY = 'callbackUrl';

const CHOOSE_RECURRENCY_BASE_ROUTE = `${CHECKOUT_BASE_ROUTE}/choose-payment-method/recurrency-options/:payOrderId`;

export const CHECKOUT_ROUTES = {
  plansAndSubscriptions: `${CHECKOUT_BASE_ROUTE}/charges`,
  choosePaymentMethodForPlan: `${CHECKOUT_BASE_ROUTE}/choose-payment-method/plan`,
  choosePaymentMethodForPayOrder: `${CHECKOUT_BASE_ROUTE}/choose-payment-method/pay-order/:payOrderId`,
  payWithCreditCard: `${CHECKOUT_BASE_ROUTE}/pay/credit-card/:payOrderId`,
  payWithWireTransfer: `${CHECKOUT_BASE_ROUTE}/pay/wire-transfer/:payOrderId`,
  conceptDetails: `${CHECKOUT_BASE_ROUTE}/concept-details`,
  choosePaymentMethod: `${CHOOSE_RECURRENCY_BASE_ROUTE}/method`,
  choosePaymentRecurrency: `${CHOOSE_RECURRENCY_BASE_ROUTE}/recurrency`,
};
