import { useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { useAutoFocus, useForm } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  CostAndDaysValuesInterface,
  CostAndDaysCard,
  FreeShippingCard,
} from './components';
import Skeleton from './Skeleton';
import { onDirtyType } from '../../types';

export interface ConfigureOcaStep3Values extends CostAndDaysValuesInterface {
  allowFreeShipping: boolean;
}

interface ConfigureOcaStep3Props {
  values: ConfigureOcaStep3Values;
  isCreateMode: boolean;
  isSaving: boolean;
  onChange: (values: ConfigureOcaStep3Values) => void;
  onDirty: onDirtyType;
}

function ConfigureOcaStep3({
  values,
  isCreateMode,
  isSaving,
  onChange,
  onDirty,
}: ConfigureOcaStep3Props) {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const { ref } = useAutoFocus();

  const {
    values: valuesForm,
    isDirty,
    handleOnChange,
    handleOnSubmit,
  } = useForm({
    initialValues: values,
    onSubmit: onChange,
  });

  useEffect(() => {
    onDirty('step3', isDirty);
  }, [isDirty, onDirty]);

  return (
    <Stack column align="flex-end" innerRef={ref}>
      <CostAndDaysCard
        additionalCost={valuesForm.additionalCost}
        additionalDays={valuesForm.additionalDays}
        onChange={handleOnChange}
      />
      <FreeShippingCard
        allowFreeShipping={valuesForm.allowFreeShipping}
        onChange={handleOnChange}
      />
      <CancelAndSaveButtons
        isLoading={isSaving}
        onCancel={goBack}
        onSave={handleOnSubmit}
        saveText={
          isCreateMode ? t('oca.config.active') : t('oca.config.update')
        }
      />
    </Stack>
  );
}

ConfigureOcaStep3.Skeleton = Skeleton;

export default ConfigureOcaStep3;
