import { useEffect, ReactNode } from 'react';
import { Sidebar, Box } from '@nimbus-ds/components';
import { AppShell } from '@nimbus-ds/patterns';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { NUVEMENVIO_ADMIN_BLOCK } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import useWindowSize from 'commons/hooks/useWindowSize';
import { useGetTags } from 'domains/Auth/hooks';
import {
  TRANSACTION_FEES_BASE_ROUTE,
  TRANSACTION_FEES_ROUTES,
} from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { DASHBOARD_BASE_PATH } from 'domains/Dashboard';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { ExpirationBanner } from '../ExpirationBanner/ExpirationBanner';
import { useDesktopMenuContext } from '../Menu/DesktopMenuProvider';
import { useNavTabs } from '../Navtabs';
import { TopAdBar } from '../TopAdBar';
import './AppLayout.scss';
import { useTopAdBar } from '../TopAdBar/useTopAdBar';

const TRANSACTION_FEES_ROUTES_VALUES = [
  TRANSACTION_FEES_BASE_ROUTE,
  ...Object.values(TRANSACTION_FEES_ROUTES),
];

const UNLOCKED_ROUTES = [
  DASHBOARD_BASE_PATH, // we enable this route so that the notification can be displayed on the Dashboard
  partnersAppsRoutes.nuvemenvioBilling,
  ...TRANSACTION_FEES_ROUTES_VALUES,
];

interface AppLayoutInterface {
  children: ReactNode;
  topbar?: ReactNode;
  menu: ReactNode;
  navtabs: ReactNode;
}

function AppLayout({
  children,
  menu,
  topbar,
  navtabs,
}: Readonly<AppLayoutInterface>) {
  const { width } = useWindowSize();
  const { isShowSideBar, hideSideBar } = useNavTabs();
  const { isShowTopAdBar } = useTopAdBar();
  const { pathname, goTo } = useNavegate();
  const { isDesktop } = useResponsive();
  const { isShowMenu } = useDesktopMenuContext();

  const tags = useGetTags();

  const shouldShowNavtabs = width <= MOBILE_BREAKPOINT;

  /*
    AQUI ESTAMOS VALIDANDO SE O MERCHANT TEM A TAG, 
    CASO TENHA PRECISAMOS REDIRECIONAR PARA A 
    TELA DE BILLING DO APLICATIVO NUVEM ENVIO.

    MOTIVO: PARA FORÇAR O MERCHANT EFETUAR O PAGAMENTO
    PENDENTE DOS ENVIOS FEITOS

    =====

    AQUÍ ESTAMOS VALIDANDO SI EL COMERCIANTE TIENE LA ETIQUETA,
    SI LO TIENES, NECESITAMOS REDIRIGIRLO A LA
    PANTALLA DE FACTURACIÓN DEL ENVÍO DE LA APLICACIÓN EN LA NUBE.

    MOTIVO: OBLIGAR AL COMERCIO A REALIZAR EL PAGO
    PENDIENTES DE ENVÍOS REALIZADOS
  */

  useEffect(() => {
    if (tags.includes(NUVEMENVIO_ADMIN_BLOCK)) {
      hideSideBar();

      const shouldRedirect = !UNLOCKED_ROUTES.includes(pathname);

      if (shouldRedirect) {
        goTo(partnersAppsRoutes.nuvemenvioBilling);
      }
    }
  }, [hideSideBar, tags, pathname, goTo]);

  return (
    <AppShell menu={!shouldShowNavtabs && isShowMenu && menu}>
      {shouldShowNavtabs && (
        <div className="stratus--app-layout-sidebar">
          <Sidebar
            open={isShowSideBar}
            onRemove={hideSideBar}
            position="right"
            maxWidth="305px"
            needRemoveScroll
          >
            {menu}
          </Sidebar>
        </div>
      )}
      {isDesktop && (
        <div className="stratus--app-layout-header">
          {isShowTopAdBar && <TopAdBar />} {!!topbar && topbar}
        </div>
      )}
      {!isShowMenu ? (
        <Box height="calc(100vh - 50px)">{children}</Box>
      ) : (
        children
      )}
      {shouldShowNavtabs ? navtabs : <ExpirationBanner />}
    </AppShell>
  );
}

export default AppLayout;
