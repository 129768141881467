import { DataList, Text, Stack, Link } from '@tiendanube/components';

function AbandonedCartsListRowMobileSkeleton(): JSX.Element {
  const { Row, Cell } = DataList;

  return (
    <Row id="abandonded-carts-mobile-row-skeleton">
      <Cell width={60} rowTitle>
        <Link.Skeleton />
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton width="small" />
      </Cell>
      <Cell width={60}>
        <Stack spacing="tight">
          <Text.Skeleton />
        </Stack>
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={100}>
        <Text.Skeleton width="large" />
      </Cell>
    </Row>
  );
}

export default AbandonedCartsListRowMobileSkeleton;
