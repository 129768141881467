import {
  ReactNode,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { InterfaceButton } from '@tiendanube/components';
import { useBoolean } from 'commons/hooks';

const noop = () => null;

type HeaderNavigaionType =
  | Pick<InterfaceButton, 'children' | 'onClick'>
  | undefined;

export interface InterfaceTopbarContext {
  headerNavigation: HeaderNavigaionType;
  setHeaderNavigation: (headerNavigation: HeaderNavigaionType) => void;
  noReadMessagesCount: number;
  setNoReadMessagesCount: (count: number) => void;
  isHelpWidgetOpen: boolean;
  toggleHelpWidget: () => void;
  isShowMenu: boolean;
  showMenu: () => void;
  hideMenu: () => void;
}

export interface InterfaceTopbarContextProvider {
  children: ReactNode;
}

export const TopbarContext = createContext<InterfaceTopbarContext>({
  headerNavigation: undefined,
  setHeaderNavigation: noop,
  noReadMessagesCount: 0,
  setNoReadMessagesCount: noop,
  isHelpWidgetOpen: false,
  toggleHelpWidget: noop,
  isShowMenu: true,
  showMenu: noop,
  hideMenu: noop,
});

function TopbarContextProvider({
  children,
}: InterfaceTopbarContextProvider): JSX.Element {
  const [header, setHeader] = useState<HeaderNavigaionType>();
  const [isShowMenu, showMenu, hideMenu] = useBoolean(true);
  const [noReadMessagesCount, setNoReadMessagesCount] = useState(0);
  const [isHelpWidgetOpen, setIsHelpWidgetOpen] = useState(false);

  const setHeaderNavigation = useCallback(
    (headerNavigation) => setHeader(headerNavigation),
    [],
  );

  const toggleHelpWidget = useCallback(
    () => setIsHelpWidgetOpen((prevState) => !prevState),
    [],
  );

  const memoizedValue = useMemo(
    () => ({
      headerNavigation: header,
      setHeaderNavigation,
      noReadMessagesCount,
      setNoReadMessagesCount,
      isHelpWidgetOpen,
      toggleHelpWidget,
      isShowMenu,
      showMenu,
      hideMenu,
    }),
    [
      header,
      setHeaderNavigation,
      noReadMessagesCount,
      setNoReadMessagesCount,
      isHelpWidgetOpen,
      toggleHelpWidget,
      isShowMenu,
      showMenu,
      hideMenu,
    ],
  );

  return (
    <TopbarContext.Provider value={memoizedValue}>
      {children}
    </TopbarContext.Provider>
  );
}

export default TopbarContextProvider;
