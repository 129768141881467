import { useCallback } from 'react';
import { useHistory, useLocation, generatePath } from 'react-router';

export interface Navegate {
  pathname: string;
  referrer: string;
  buildPath: (path: string, params?: Record<string, string | number>) => string;
  goBack: () => void;
  goTo: (path: string) => void;
  replace: (path: string) => void;
  goToReferrer: () => void;
  removeSearchParams: (searchParamsToRemove: string[]) => void;
  hasSearchParam: (searchParam: string) => boolean;
  getQueryParam: (searchParam: string) => string | null;
}

type State = {
  referrer?: string;
};

const DEFAULT_REFERRER = '/';

function useNavegate(): Navegate {
  const { goBack, push, replace } = useHistory();
  const { pathname, state, search } = useLocation<State>();

  const referrer = state?.referrer || DEFAULT_REFERRER;

  const goToReferrer = () => {
    goTo(referrer);
  };

  const buildPath = (path: string, params?: Record<string, string | number>) =>
    generatePath(path, params);

  const goTo = useCallback(
    (path: string) => push(path, { referrer: pathname }),
    [pathname, push],
  );

  const hasSearchParam = useCallback(
    (searchParam: string) => {
      const queryParams = new URLSearchParams(search);
      return queryParams.has(searchParam);
    },
    [search],
  );

  const getQueryParam = useCallback(
    (searchParam: string) => {
      const queryParams = new URLSearchParams(search);
      return queryParams.get(searchParam);
    },
    [search],
  );

  const removeSearchParams = useCallback(
    (searchParamsToRemove: string[]) => {
      const queryParams = new URLSearchParams(search);

      const queryParamsInitial = queryParams.toString();
      queryParams.forEach((_, key) => {
        if (searchParamsToRemove.includes(key)) {
          queryParams.delete(key);
        }
      });

      if (queryParamsInitial !== queryParams.toString()) {
        replace({
          search: queryParams.toString(),
        });
      }
    },
    [replace, search],
  );

  return {
    goBack,
    goTo,
    pathname,
    goToReferrer,
    referrer,
    buildPath,
    replace,
    removeSearchParams,
    hasSearchParam,
    getQueryParam,
  };
}

export default useNavegate;
