import { v4 as uuidv4 } from 'uuid';
import { DataList, Text, Link, Stack, Popover } from '@tiendanube/components';

function DraftOrdersListRowMobileSkeleton(): JSX.Element {
  const { Row, Cell } = DataList;

  return (
    <Row id={uuidv4()}>
      <Cell width={60} rowTitle>
        <Stack spacing="tight">
          <Stack.Item>
            <Link.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell width={60}>
        <Stack spacing="tight">
          <Stack.Item>
            <Text.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell width={40} alignRight>
        <Text.Skeleton />
      </Cell>
      <Cell>
        <Popover.Skeleton />
      </Cell>
    </Row>
  );
}

export default DraftOrdersListRowMobileSkeleton;
