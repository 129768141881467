import { useEffect } from 'react';
import { Alert } from '@nimbus-ds/components';
import { PHISHING_SUSPECT_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import {
  useGetIsFreePlan,
  useGetIsFreemium,
  useGetMainUserId,
  useStoreName,
} from 'domains/Auth/hooks';
import { useVerifyValidationEmailMutation } from 'domains/Configurations/Notifications/notificationsApi';
import { useGetUserQuery } from 'domains/Configurations/Users/usersApi';
import { AmazonSesAlert } from './AmazonSesAlert';
import { CnameWarningAlert } from './CnameWarningAlert';
import { EmailOriginAlert } from './EmailOriginAlert';

export function EmailOriginInfo() {
  const storeName = useStoreName();
  const mainUserId = useGetMainUserId();
  const isFreemium = useGetIsFreemium();
  const { isFreePlan } = useGetIsFreePlan();
  const [isPhishingSuspect] = useHasTags(PHISHING_SUSPECT_TAG);

  const { data: mainUser } = useGetUserQuery(mainUserId);
  const [validateEmail, { data: validateData, status }] =
    useVerifyValidationEmailMutation();

  useEffect(() => {
    validateEmail();
  }, [validateEmail]);

  const isEmailSettingLimited = (isFreemium && isFreePlan) || isPhishingSuspect;
  const isValidated = !!validateData?.verified;
  const isValidateRequired = !!validateData?.required;
  const isMailFromVerifiedOrPending =
    !!validateData?.mailFromDomainStatus &&
    ['Success', 'Pending'].includes(validateData?.mailFromDomainStatus);
  const isMailFromFailed =
    !!validateData?.mailFromDomainStatus &&
    validateData?.mailFromDomainStatus === 'Failed';
  const storeEmail = mainUser?.email;

  const loadSesAlert = isValidateRequired && !isValidated;

  const loadCnameWarningAlert =
    !loadSesAlert && isValidateRequired && !isMailFromVerifiedOrPending;

  if (status === 'pending') return <Alert.Skeleton height="48px" />;

  if (status === 'rejected' || !storeEmail || isValidated === undefined)
    return null;

  return (
    <>
      {!isEmailSettingLimited && loadSesAlert && (
        <AmazonSesAlert storeEmail={storeEmail} validateEmail={validateEmail} />
      )}
      {!isEmailSettingLimited && loadCnameWarningAlert && (
        <CnameWarningAlert isMailFromFailed={isMailFromFailed} />
      )}
      <EmailOriginAlert
        isValidated={isValidated}
        storeName={storeName}
        storeEmail={storeEmail}
      />
    </>
  );
}
