import { PaymentProviderDto } from '@tiendanube/common';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import {
  useGoToPaymentProviderInstallationRoute,
  usePaymentProviderSettings,
} from 'domains/Payments/PaymentProviders/hooks';
import { invokeEventPayments } from 'domains/Payments/tracking';

export function useInstallApplication(
  paymentProvider: PaymentProviderDto,
  openSpecialInstallationModal: () => void,
) {
  const { wasInstalled, id } = paymentProvider;

  const { goToInstallationRoute } =
    useGoToPaymentProviderInstallationRoute(paymentProvider);
  const { enableInstalledPaymentProvider } = usePaymentProviderSettings(id);

  const installApp = async () => {
    if (wasInstalled) {
      return enableInstalledPaymentProvider();
    }
    invokeEventPayments('initInstall', id);
    if (muvemPagoOrPagoNubeIds.includes(id)) {
      return openSpecialInstallationModal();
    }
    return goToInstallationRoute();
  };

  return {
    installApp,
  };
}
