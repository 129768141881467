import { ModalConfirmationBase } from 'App/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface ConfirmDraftReplacementModalProps {
  showModal: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export function ConfirmDraftReplacementModal({
  showModal,
  onConfirm,
  onClose,
}: Readonly<ConfirmDraftReplacementModalProps>) {
  const t = useTranslationOnline('themesPage.newDesign.confirmation');

  return (
    <ModalConfirmationBase
      show={showModal}
      title={t('title')}
      text={t('description')}
      label={t('confirm')}
      labelCancel={t('cancel')}
      appearance="primary"
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  );
}
