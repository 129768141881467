import { useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';

function useFormatCurrency() {
  const { code } = useGetCurrency();
  const {
    i18n: { language },
  } = useTranslation();

  return (price: number, currency?: CurrencyType, withoutDecimal = false) =>
    formatCurrency(
      price,
      (currency as string) || code,
      language,
      withoutDecimal,
    );
}

export default useFormatCurrency;
