function useScaleDimensionsToFit(maxWidth: number, maxHeight: number) {
  function scaleToFit(width: number, height: number) {
    if (width <= maxWidth && height <= maxHeight) {
      return [width, height];
    }

    // If the image is more restricted by width than height, set width to max width and scale height accordingly
    if (width / maxWidth >= height / maxHeight) {
      return [maxWidth, Math.round((height / width) * maxWidth)];
    }

    // Otherwise, do the same but for height
    return [Math.round((width / height) * maxHeight), maxHeight];
  }

  return {
    scaleToFit,
  };
}

export default useScaleDimensionsToFit;
