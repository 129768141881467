import { Box, Card, Text } from '@nimbus-ds/components';
import { InventoryLevelsRequestDto } from '@tiendanube/common';
import { DataList, Checkbox } from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { Stock } from 'domains/Catalog/Inventory/types';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface UnselectedLocationsProps {
  locationsNotSelected: InventoryLevelsRequestDto[];
  toggleLocations: (name: string) => void;
  getLocationName: (locationId: string) => string;
  getLocationStock: (locationId: string) => Stock;
  changeStockByLocation: (data: { locationId: string; stock: Stock }) => void;
  isChecked: (locationId: string) => boolean;
}

function UnselectedLocations({
  locationsNotSelected,
  toggleLocations,
  getLocationName,
  getLocationStock,
  changeStockByLocation,
  isChecked,
}: UnselectedLocationsProps) {
  const t = useTranslationCatalog();

  const handleToggleCheckbox = ({ name }) => {
    toggleLocations(name);
  };

  return (
    <Card.Body>
      <Box paddingBottom="4">
        <Text fontWeight="bold">
          {t('editInventory.stockDistributionCard.addNewCenter')}
        </Text>
      </Box>
      <DataList>
        {locationsNotSelected.map(({ locationId }) => {
          const handleChange = ({ value }) => {
            changeStockByLocation({
              locationId: locationId,
              stock: value !== '' ? Number(value) : null,
            });
          };
          return (
            <DataList.Row key={locationId} id={locationId}>
              <DataList.Cell width="fill">
                <Box
                  flex="1"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Checkbox
                    name={locationId}
                    label={getLocationName(locationId)}
                    onChange={handleToggleCheckbox}
                    checked={isChecked(locationId)}
                  />
                  {isChecked(locationId) && (
                    <InputNumber
                      type="integer"
                      value={String(getLocationStock(locationId) ?? '')}
                      placeholder="∞"
                      onChange={handleChange}
                      name={locationId}
                      min="0"
                      autoSelect
                      focused
                    />
                  )}
                </Box>
              </DataList.Cell>
            </DataList.Row>
          );
        })}
      </DataList>
    </Card.Body>
  );
}

export default UnselectedLocations;
