import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import {
  MessagesListItem,
  MessagesActionsRow,
  SendEmailModal,
} from 'domains/Customers/components';
import { useMessageById } from 'domains/Customers/Messages/pages/MessagesListPage/hooks';

export interface MessagesListRowProps {
  messageId: string;
}
function MessagesListRow({
  messageId,
}: MessagesListRowProps): JSX.Element | null {
  const {
    messageById,
    removeMessage,
    readMessage,
    unreadMessage,
    spamMessage,
  } = useMessageById(messageId);
  const { goTo } = useNavegate();
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  if (!messageById) return null;

  const {
    customerId,
    name,
    date,
    message,
    type,
    replied,
    phone,
    email,
    product,
    order,
  } = messageById;

  const onActionSendEmail = () => openSendEmailModal();

  const actions = (
    <MessagesActionsRow
      type={type}
      phone={phone}
      replied={replied}
      onActionDelete={removeMessage}
      onActionRead={readMessage}
      onActionUnread={unreadMessage}
      onActionSpam={spamMessage}
      onActionSendEmail={onActionSendEmail}
      hasCustomerId={!!customerId}
    />
  );

  const goToCustomer = () => goTo(`/customers/${customerId}`);

  return (
    <>
      <MessagesListItem
        id={messageId}
        name={name}
        date={date}
        message={message}
        type={type}
        actions={actions}
        replied={replied}
        onClick={goToCustomer}
        hasCustomerId={!!customerId}
        product={product}
        order={order}
      />
      {showSendEmailModal && (
        <SendEmailModal
          email={email}
          customerId={customerId}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default MessagesListRow;
