import { useState, useEffect, useRef } from 'react';
import axios from 'App/axios';

const INITIAL_STATE = {
  pageValidation: '',
};

interface ValidationInitialResult {
  pageValidation: string;
}

function useGetValidationInitial() {
  const isMounted = useRef(false);
  const [validationInitial, setValidationInitial] =
    useState<ValidationInitialResult>(INITIAL_STATE);

  useEffect(() => {
    if (isMounted.current || validationInitial.pageValidation) return;

    isMounted.current = true;

    const getValidationInitialData = async () => {
      const { data } = await axios.get<ValidationInitialResult>(
        'v1/request-validator/initial',
      );

      if (data.pageValidation) setValidationInitial(data);
    };

    getValidationInitialData();

    return () => {
      isMounted.current = false;
    };
  }, [validationInitial]);

  return validationInitial;
}

export default useGetValidationInitial;
