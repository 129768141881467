import { Box, Link, Text } from '@nimbus-ds/components';

export default function RowDataSkeleton() {
  return (
    <Box flexDirection="column" display="flex" gap="2">
      <Box
        display="flex"
        flexDirection="row"
        gap="14"
        justifyContent="space-between"
      >
        <Text.Skeleton />
        <Text.Skeleton />
      </Box>
      <Link.Skeleton />
    </Box>
  );
}
