import {
  FulfillmentOrderDestinationForDocumentResponseDto,
  FulfillmentOrderRecipientForDocumentResponseDto,
} from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface RecipientDocumentPreviewProps {
  recipient: FulfillmentOrderRecipientForDocumentResponseDto;
  destination: FulfillmentOrderDestinationForDocumentResponseDto | null;
  showLabelRecipientDocument: boolean;
}

function RecipientDocumentPreview({
  recipient,
  destination,
  showLabelRecipientDocument,
}: Readonly<RecipientDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general.recipient');
  return (
    <div className="address-section">
      <p>{t('name')}</p>
      <p className="bold-name">{recipient.name}</p>
      {!!recipient.phone && (
        <p className="bold-name">
          {t('contact')} {recipient.phone}
        </p>
      )}
      {showLabelRecipientDocument && (
        <p>
          {t('document')} {recipient.identifier}
        </p>
      )}
      {!!destination && (
        <>
          {!!destination.streetAddress && <p>{destination.streetAddress}</p>}
          {!!destination.additionalAddressDetails && (
            <p>{destination.additionalAddressDetails}</p>
          )}
          {!!destination.zipLocation && <p>{destination.zipLocation}</p>}
          {!!destination.country && <p>{destination.country}</p>}
        </>
      )}
    </div>
  );
}

export default RecipientDocumentPreview;
