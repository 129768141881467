import { ErrorState } from '@tiendanube/components';
import { useTranslationOnline } from 'domains/Online';

interface MenusPageError {
  onRetry: () => void;
}

function MenusPageError({ onRetry }: MenusPageError) {
  const t = useTranslationOnline('menus.list.error');

  return (
    <ErrorState
      title={t('title')}
      action={{
        children: t('retry'),
        onClick: onRetry,
      }}
    />
  );
}

export default MenusPageError;
