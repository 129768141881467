import { useModal } from 'commons/hooks';
import {
  MessagesListItem,
  MessagesActionsRow,
  SendEmailModal,
} from 'domains/Customers/components';
import { useCustomerMessagesListItem } from 'domains/Customers/Customers/pages/CustomerDetailsPage/hooks';

export interface CustomerMessagesItemProps {
  id: string;
  phone: string;
  email: string;
}

function CustomerMessagesItem({
  id,
  phone,
  email,
}: CustomerMessagesItemProps): JSX.Element | null {
  const {
    customerMessagesById,
    removeCustomerMessage,
    readCustomerMessage,
    unreadCustomerMessage,
    spamCustomerMessage,
  } = useCustomerMessagesListItem(id);
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  if (!customerMessagesById) return null;

  const { customerId, name, date, message, type, replied } =
    customerMessagesById;

  const onActionSendEmail = () => openSendEmailModal();

  const actions = (
    <MessagesActionsRow
      type={type}
      phone={phone}
      replied={replied}
      onActionDelete={removeCustomerMessage}
      onActionRead={readCustomerMessage}
      onActionUnread={unreadCustomerMessage}
      onActionSpam={spamCustomerMessage}
      onActionSendEmail={onActionSendEmail}
      hasCustomerId={!!customerId}
    />
  );

  return (
    <>
      <MessagesListItem
        id={id}
        name={name}
        date={date}
        message={message}
        type={type}
        actions={actions}
        replied={replied}
        hasCustomerId={!!customerId}
      />
      {showSendEmailModal && (
        <SendEmailModal
          email={email}
          customerId={customerId}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default CustomerMessagesItem;
