import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CatalogStateInterface } from 'domains/Catalog/types';
import { StatusType } from '../types';

export const getCatalog = (
  state: RootStateType,
): CombinedState<CatalogStateInterface> => state.catalog;

export const getInventoryData = createSelector(
  getCatalog,
  (catalog) => catalog.inventory,
);

export const getInventoryProductIds = createSelector(
  getInventoryData,
  (catalog) => catalog.ids,
);

export const getInventoryStatus = createSelector(getInventoryData, (data) => ({
  isLoading: data.status === StatusType.loading,
  isSuccess: data.status === StatusType.success,
  isError: data.status === StatusType.error,
}));

export const getInventory = createSelector(
  getInventoryProductIds,
  getInventoryStatus,
  (ids, status) => ({
    ids,
    ...status,
  }),
);

export const getInventoryEntities = createSelector(
  getInventoryData,
  (data) => data.entities,
);

export const getAppliedFilters = createSelector(
  getInventoryData,
  (data) => data.appliedFilters,
);

const getEntityById = (_: RootStateType, id: string) => id;

export const createGetProductById = () =>
  createSelector(
    getInventoryEntities,
    getEntityById,
    (entities, id) => entities[id] || null,
  );

export const getTotalInventoryProducts = createSelector(
  getInventoryData,
  (data) => data.totalProducts,
);

export const getEditStockStatus = createSimpleSelector(
  [getInventoryData],
  (data) => data.updateStockStatus,
);
