function downloadByUri(uri: string) {
  const link = document.createElement('a');
  link.download = uri;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 1000);
}

export default downloadByUri;
