import { useCallback } from 'react';
import { Box, Link, Text } from '@nimbus-ds/components';
import { SubscriptionResponseDto } from '@tiendanube/common';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useGetRecurrentPaymentConcepts } from 'domains/Billing/Checkout/hooks';
import {
  trackingBillingChangePlansClick,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import RowDataSkeleton from './Skeleton';

interface RowDataProps {
  subscription: SubscriptionResponseDto;
  onCancelSubscription?: () => void;
}

function RowData({
  subscription,
  onCancelSubscription,
}: RowDataProps): JSX.Element {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps',
  );
  const { handleGoToPlans: goToPlans } = useGoToPlans();
  const { recurrentPaymentForPlanActivated } = useGetRecurrentPaymentConcepts();
  const formatCurrency = useFormatCurrency();
  const isMobileDevice = useIsMobileDevice();
  const logEvent = useCheckoutTracking();

  const handlePlanChange = useCallback(() => {
    trackingBillingChangePlansClick(logEvent);
    goToPlans();
  }, [goToPlans, logEvent]);

  return (
    <Box display="flex" flexDirection="column" gap="0-5">
      <Box
        display="flex"
        flexDirection="row"
        gap="14"
        justifyContent="space-between"
      >
        <Text fontSize="highlight">{subscription.description}</Text>
        <Text fontSize="highlight">{`${formatCurrency(
          subscription.amountValue,
          subscription.amountCurrency,
        )}`}</Text>
      </Box>
      {!recurrentPaymentForPlanActivated &&
        !isMobileDevice &&
        (subscription.isPlan ? (
          <Link
            textDecoration="none"
            appearance="primary"
            onClick={handlePlanChange}
          >
            {t('changePlan')}
          </Link>
        ) : (
          <Link appearance="primary" onClick={onCancelSubscription}>
            {t('cancelSubscription')}
          </Link>
        ))}
    </Box>
  );
}

RowData.Skeleton = RowDataSkeleton;

export default RowData;
