import { ProductsFiltersInterface } from 'domains/Catalog/Products/productsServices';

export { Domain } from '@tiendanube/common/src/enums';
export { BulkActionTypeEnum } from '../../hooks/useBulkActionProducts/types';

export enum ActionEnum {
  REPLACE = 'replace',
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export enum UpdateByEnum {
  PERCENTAGE = 'percentage',
  ABSOLUTE = 'absolute',
}

export interface EditPricesProductsDto
  extends Partial<ProductsFiltersInterface> {
  productsIds?: string[];
  promoPriceVariationType: UpdateByEnum;
  priceVariation: string;
  priceVariationAction: ActionEnum;
  priceVariationType: UpdateByEnum;
  promoPriceVariation: string;
  promoPriceVariationAction: ActionEnum;
  categoryId?: string;
  priceRoundPrices: boolean;
  promoPriceRoundPrices: boolean;
}
