import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { FiscalData } from '../types';

const getFiscalData = (state: RootStateType): CombinedState<FiscalData> =>
  state.billing.invoices.fiscalData;

export const getFiscalDataStatus = (state: RootStateType) =>
  getFiscalData(state).status;

export const getFetchFiscalDataStatus = (state: RootStateType) =>
  getFiscalData(state).invoiceInformation.status;

export const getInvoiceInfo = (state: RootStateType) =>
  getFiscalData(state).invoiceInformation;

export const getInvoiceInfoData = createSelector(
  getInvoiceInfo,
  (invoiceInfo) => {
    if (invoiceInfo.data) {
      const newInvoiceInfo = {
        ...invoiceInfo.data,
        cityId: String(invoiceInfo.data.cityId),
      };
      return newInvoiceInfo;
    }
    return invoiceInfo.data;
  },
);

export const getInvoiceInfoExists = (state: RootStateType) =>
  getInvoiceInfo(state).hasInvoiceInfo;

export const getBillingEngineInvoiceInfoExists = (state: RootStateType) =>
  getInvoiceInfo(state).hasBillingEngineInvoiceInfo;

export const getInvoiceInfoStatus = (state: RootStateType) =>
  getInvoiceInfo(state).status;

export const getBillingEngineInvoiceInfoStatus = (state: RootStateType) =>
  getInvoiceInfo(state).billingEngineInvoiceInfoStatus;

export const getBillingCities = (state: RootStateType) =>
  getFiscalData(state).cities;
export const getBillingCitiesData = (state: RootStateType) =>
  getBillingCities(state).data || [];

export const getBillingCitiesStatus = (state: RootStateType) => {
  const status = getBillingCities(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const getInvoiceInformation = (state: RootStateType) =>
  getFiscalData(state).invoiceInformation;

export const getInvoiceInformationWasFetched = (state: RootStateType) =>
  getInvoiceInformation(state).status === 'success';

export const getFiscalDataType = (state: RootStateType) =>
  getInvoiceInformation(state).data?.numberIdType;

export const getValidatedCuitStatus = (state: RootStateType) =>
  getFiscalData(state).validateCuit.status;

export const vatCondition = (state: RootStateType) =>
  getInvoiceInformation(state).data?.vatCondition;

export const invoiceType = (state: RootStateType) =>
  getInvoiceInformation(state).data?.type;

export const getValidatedCuit = (state: RootStateType) =>
  getFiscalData(state).validateCuit.data;

export const getFiscalDataCuit = createSelector(
  getInvoiceInformation,
  getFiscalDataType,
  ({ data }, fiscalDataType) =>
    fiscalDataType === 'CUIT' ? { cuit: data?.numberId || '' } : { cuit: '' },
);

export const getFiscalDataDni = createSelector(
  getInvoiceInformation,
  getFiscalDataType,
  ({ data }, fiscalDataType) => ({
    dni: (fiscalDataType === 'DNI' && data.numberId) || '',
    address: (fiscalDataType === 'DNI' && data.address) || '',
    businessName: (fiscalDataType === 'DNI' && data.name) || '',
  }),
);
