import { useMemo } from 'react';
import { DataList } from '@nimbus-ds/patterns';
import { v4 as uuidv4 } from 'uuid';
import { useLocalPagination } from 'commons/hooks';
import CompactPaginationStratus from '../CompactPaginationStratus';

const { Row } = DataList;

interface ListWithPaginationProps<TData> {
  list: TData[];
  perPage?: number;
  header?: React.ReactNode;
  renderItem: (item: TData) => React.ReactNode;
}

function ListWithPagination<TData>({
  list,
  perPage = 10,
  renderItem,
  header,
}: ListWithPaginationProps<TData>) {
  const { setCurrentPage, currentData, hasPagination, ...pagination } =
    useLocalPagination(list, perPage);
  // We use uuidv4 and useMemo to generate unique keys for list items.
  // By doing this, we ensure that the keys won't repeat, even if the component is reused on the same page.
  const uniqueId = useMemo(() => uuidv4(), []);

  return (
    <DataList>
      {!!header && <Row backgroundColor="neutral-surface">{header}</Row>}
      {currentData.map((item, i) => (
        <Row paddingX="4" paddingY="2" gap="1" key={`${uniqueId}-${i}`}>
          {renderItem(item)}
        </Row>
      ))}
      {hasPagination && (
        <Row backgroundColor="neutral-surface" paddingX="4" paddingY="2">
          <CompactPaginationStratus
            pagination={pagination}
            onPageSelect={setCurrentPage}
            itemName="redireccionamientos"
          />
        </Row>
      )}
    </DataList>
  );
}

export default ListWithPagination;
