import { Alert, Box, Title } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside/ModalAside';
import { toStatusType } from 'App/rtk';
import { useToastStatus } from 'commons/hooks';
import { useLoggedAdminLink } from 'domains/Auth/hooks';
import { useCreateDraftMutation } from 'domains/Online/Themes/themesApi';
import {
  trackingThemeLayoutDraftCreationDefault,
  trackingThemeLayoutDraftCreationDefaultOthers,
} from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { useGoToThemesStore } from '../../hooks';
import { useThemesRequireUpsell } from '../../hooks/useThemesRequireUpsell';

interface NewDraftModalProps {
  showModal: boolean;
  onClose: () => void;
}

export function NewDraftModal({
  showModal,
  onClose,
}: Readonly<NewDraftModalProps>) {
  const t = useTranslationOnline('themesPage.draft.newDraftModal');
  const { goToLoggedAdminLink, isLoading } = useLoggedAdminLink();
  const [createDraft, { status }] = useCreateDraftMutation();
  const requireUpsell = useThemesRequireUpsell();
  const handleGoToThemesStore = useGoToThemesStore();

  useToastStatus({
    status: toStatusType(status),
    progress: t('toast.progress'),
    error: t('toast.error'),
  });

  const handleClickCurrentDesign = async () => {
    trackingThemeLayoutDraftCreationDefault();
    await createDraft();
    await goToLoggedAdminLink('/themes/settings/draft');
  };

  const handleClickOtherDesign = async () => {
    trackingThemeLayoutDraftCreationDefaultOthers();
    await handleGoToThemesStore();
  };

  return (
    <ModalAside isOpen={showModal} fluid onDidDismiss={onClose}>
      <Box paddingX="4">
        <Title as="h1">{t('title')}</Title>
      </Box>
      <InteractiveList>
        <InteractiveList.ButtonItem
          title={t('currentDesign')}
          description={t('currentDesignInfo')}
          iconButton={{
            onClick: handleClickCurrentDesign,
            disabled: isLoading,
          }}
        />
        <InteractiveList.ButtonItem
          title={t('otherDesign')}
          description={t('otherDesignInfo')}
          iconButton={{
            onClick: handleClickOtherDesign,
          }}
        />
        {requireUpsell && (
          <Box padding="4">
            <Alert show color="primary-textHigh" appearance="primary">
              {t('alertUpsell')}
            </Alert>
          </Box>
        )}
      </InteractiveList>
    </ModalAside>
  );
}
