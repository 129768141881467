import { ReactNode } from 'react';
import { BaseCard, Stack } from '@tiendanube/components';
import { Responsive } from 'commons/components';

interface OrganizeSectionLayoutProps {
  link?: ReactNode;
  children: ReactNode;
}

function OrganizeSectionLayout({ children, link }: OrganizeSectionLayoutProps) {
  return (
    <Responsive
      mobile={
        <Stack column align="stretch">
          {!!link && <Stack.Item>{link}</Stack.Item>}
          <Stack.Item>{children}</Stack.Item>
        </Stack>
      }
      desktop={
        <BaseCard>
          {!!link && <BaseCard.Header>{link}</BaseCard.Header>}
          <BaseCard.Body padding="none">{children}</BaseCard.Body>
        </BaseCard>
      }
    />
  );
}

export default OrganizeSectionLayout;
