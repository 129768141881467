import { useContext, useEffect } from 'react';
import { BulkActionsContext } from './BulkActionsProvider';

function useBulkActions(availableRows?: string[]): BulkActionsContext {
  const {
    selectedRowsId,
    selectAll,
    selectedAction,
    showModal,
    editMode,
    setSelectedRowsId,
    setSelectAll,
    setSelectedAction,
    clearBulkActions,
    openModal,
    closeModal,
    toggleEditMode,
    showAlertMetafields,
    showAlertPrice,
    activeAlertMetafields,
    deactivateAlertMetafields,
    activeAlertPrice,
    disabled,
    setDisabled,
  } = useContext(BulkActionsContext);

  useEffect(() => {
    if (
      availableRows?.length &&
      selectedRowsId.some((rowId) => !availableRows.includes(rowId))
    ) {
      setSelectedRowsId(
        selectedRowsId.filter((rowId) => availableRows.includes(rowId)),
      );
    }
  }, [availableRows, selectedRowsId, setSelectedRowsId]);

  return {
    selectedRowsId,
    selectAll,
    selectedAction,
    showModal,
    editMode,
    setSelectedRowsId,
    setSelectAll,
    setSelectedAction,
    clearBulkActions,
    openModal,
    closeModal,
    toggleEditMode,
    showAlertMetafields,
    showAlertPrice,
    activeAlertMetafields,
    deactivateAlertMetafields,
    activeAlertPrice,
    disabled,
    setDisabled,
  };
}

export default useBulkActions;
