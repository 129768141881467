import { useTranslation } from 'react-i18next';
import { LoginRequestDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useAuthorize, useToken } from '../';

type AlertAppearanceType =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | undefined;

const ALERT_APPEARANCE_BY_STATUS_CODE = {
  400: 'danger',
  401: 'danger',
  403: 'warning',
  500: 'danger',
};

function useErrorText() {
  const { t } = useTranslation(['login']);

  const errorText = {
    400: t('loginInternalServerError'),
    401: t('loginError'),
    403: t('loginPermissionError'),
    500: t('loginInternalServerError'),
  };

  const getAlertText = (
    isInternalServerError: boolean,
    statusCode: string,
    isError: boolean,
  ) => (isError && !isInternalServerError ? errorText[statusCode] : undefined);

  const getAlertAppearence = (
    isInternalServerError: boolean,
    statusCode: string,
    isError: boolean,
  ) =>
    isError && !isInternalServerError
      ? ALERT_APPEARANCE_BY_STATUS_CODE[statusCode]
      : undefined;

  return {
    getAlertText,
    getAlertAppearence,
  };
}

function useLogin(): {
  handleSubmit: (data: LoginRequestDto) => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  hasAuthFactor: boolean;
  isInternalServerError: boolean;
  alertText: string;
  alertAppearence: AlertAppearanceType;
} {
  const { goTo } = useNavegate();
  const { getAlertAppearence, getAlertText } = useErrorText();
  const {
    authorize,
    isError: isAuthorizeError,
    isLoading: isAuthorizeLoading,
    isSuccess: isAuthorizeSuccess,
    statusCode: authorizeStatusCode,
    isInternalServerError: isAuthorizeInternalServerError,
  } = useAuthorize();
  const {
    createSession,
    isError: isTokenError,
    isLoading: isTokenLoading,
    isLogin: isTokenSuccess,
    statusCode: tokenStatusCode,
    authorizeData,
    isInternalServerError: isCreateSessionInternalServerError,
    isUnauthorizedError: isCreateSessionUnauthorizedError,
    isRequestTimeoutError: isCreateSessionRequestTimeoutError,
  } = useToken();

  const statusCode = isAuthorizeError
    ? authorizeStatusCode
    : isTokenError
    ? tokenStatusCode
    : '';
  const isInternalServerError =
    isAuthorizeInternalServerError ||
    isCreateSessionInternalServerError ||
    isCreateSessionUnauthorizedError ||
    isCreateSessionRequestTimeoutError;
  const isError = isAuthorizeError || isTokenError;
  const isSuccess =
    authorizeData?.type === 'none'
      ? isAuthorizeSuccess && isTokenSuccess
      : isAuthorizeSuccess;

  const handleSubmit = async (data: LoginRequestDto) => {
    const { hasAuthFactor, authorizationCode } = await authorize(data);
    if (hasAuthFactor) return;
    const isValid = await createSession({ authorizationCode, code: undefined });
    if (!isValid) return;
    return goTo('/dashboard');
  };

  return {
    handleSubmit,
    isSuccess,
    isLoading: isAuthorizeLoading || isTokenLoading,
    isError,
    alertText: getAlertText(isInternalServerError, statusCode, isError),
    alertAppearence: getAlertAppearence(
      isInternalServerError,
      statusCode,
      isError,
    ),
    isInternalServerError,
    hasAuthFactor: authorizeData?.type === 'authentication-factor',
  };
}

export default useLogin;
