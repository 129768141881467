import { Accordion, Text, Link } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { getPurchaseDomain } from '../utils';

export function PurchaseDomainStep({ domainName }) {
  const t = useTranslationConfigurations('domains.attachModal');
  const domainProvider = getPurchaseDomain(domainName);

  return (
    <Accordion.Item index="0">
      <Accordion.Header title={t('step1.title')} />
      <Accordion.Body>
        <Text>
          {t('step1.text')}{' '}
          <Link
            textDecoration={domainProvider ? 'none' : 'underline'}
            as="a"
            appearance={domainProvider ? 'primary' : 'neutral'}
            href={
              domainProvider?.href || t('step1.recommendedDomainProvidersLink')
            }
            target="_blank"
          >
            {domainProvider?.name ?? t('step1.recommendedDomainProviders')}
          </Link>
          .
        </Text>
      </Accordion.Body>
    </Accordion.Item>
  );
}
