import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getShippingSalesByProvince = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.shippingRankByProvinceBySales,
);

export const getShippingSalesByProvinceStatus = createSimpleSelector(
  [getShippingSalesByProvince],
  (shippingRankProvincesBySales) =>
    convertStatusTypeToObject(shippingRankProvincesBySales.status),
);

export const getShippingSalesByProvinceData = createSimpleSelector(
  [getShippingSalesByProvince],
  (shippingRankProvincesBySales) => shippingRankProvincesBySales.data,
);
