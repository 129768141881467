import { Responsive } from 'commons/components';
import {
  PagesListResultDesktopSkeleton,
  PagesListResultMobileSkeleton,
} from './components';

function PagesListResult() {
  return (
    <Responsive
      desktop={<PagesListResultDesktopSkeleton />}
      mobile={<PagesListResultMobileSkeleton />}
    />
  );
}

export default PagesListResult;
