import {
  BarcodeIcon,
  CashIcon,
  CreditCardIcon,
  ExternalLinkIcon,
  MoneyIcon,
  PixIcon,
  TransferPesoIcon,
  WalletIcon,
} from '@nimbus-ds/icons';
import {
  PaymentMethodType,
  PaymentProviderDto,
  PlansListResponseDto,
} from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';

export const isLastItem = (list: Array<any>, index: number) =>
  index + 1 === list.length;

export const TRANSLATE_PAYMENT_PREFIX = 'paymentRatesCard';

export const getProvidersByStatus = (paymentProviders: PaymentProviderDto[]) =>
  paymentProviders.reduce(
    (acc, paymentProvider) => {
      const { mustCompleteInstallation, isActive, processingInstallation } =
        paymentProvider;
      if (!mustCompleteInstallation && isActive && !processingInstallation) {
        acc.enabled.push(paymentProvider);
      } else if (
        !mustCompleteInstallation &&
        !isActive &&
        !processingInstallation
      ) {
        acc.disabled.push(paymentProvider);
      } else if (mustCompleteInstallation || processingInstallation) {
        acc.pending.push(paymentProvider);
      }
      return acc;
    },
    {
      enabled: [] as PaymentProviderDto[],
      disabled: [] as PaymentProviderDto[],
      pending: [] as PaymentProviderDto[],
    },
  );

export const PAYMENT_METHOD_TYPE_ICONS: Record<PaymentMethodType, React.FC> = {
  creditCard: CreditCardIcon,
  debitCard: CreditCardIcon,
  creditAndDebitCard: CreditCardIcon,
  wallet: WalletIcon,
  wireTransfer: TransferPesoIcon,
  cash: CashIcon,
  bankDebit: CreditCardIcon,
  boleto: BarcodeIcon,
  pix: PixIcon,
  ticket: BarcodeIcon,
  other: MoneyIcon,
  redirect: ExternalLinkIcon,
};

export const providersShowingLabels = [
  { provider: 'primefy', labels: ['creditCard', 'boleto', 'pix'] },
];

interface GetCustomPaymentsCpt {
  cpt: number;
  currentPlanLevel: number;
  storeCountry: Country;
  plansList: PlansListResponseDto[];
  isFreePlan: boolean;
  ifOther: boolean;
  isPagoNubeActive: boolean;
  isEnterprisePlan: boolean;
}

export const getCustomPaymentsCpt = ({
  cpt,
  ifOther,
  isFreePlan,
  plansList,
  storeCountry,
  currentPlanLevel,
  isPagoNubeActive,
  isEnterprisePlan,
}: GetCustomPaymentsCpt) => {
  const planA = plansList.find(({ level }) => level === 1);
  const planB = plansList.find(({ level }) => level === 2);

  if (isPagoNubeActive) {
    return 0;
  }

  if (storeCountry === 'AR' && ifOther && isFreePlan) {
    return planA?.fee.value;
  }

  if (!ifOther && currentPlanLevel < 2 && !isEnterprisePlan) {
    return planB?.fee.value;
  }

  return cpt;
};
