import { useRef } from 'react';
import { Card, Box } from '@nimbus-ds/components';
import { useCouponHistory } from 'domains/Marketing/Coupons/hooks/useCouponHistory';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import CouponHistoryError from './CouponHistoryError';
import { CouponHistoryTimeline } from './CouponHistoryTimeline';
import EmptyHistory from './EmptyHistory';
import LoadingTimelineItem from './LoadingTimelineItem';
import Skeleton from './Skeleton';
import './CouponHistory.scss';

interface CouponHistoryProps {
  couponId: string;
  height: string;
}

export function CouponHistory({
  couponId,
  height,
}: Readonly<CouponHistoryProps>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const t = useTranslationMarketing('coupons.history');

  const { data, isLoading, isSuccess, isError, isFetching, refetch, items } =
    useCouponHistory({
      couponId,
      containerRef,
    });

  if (isLoading) return <CouponHistory.Skeleton />;
  if (isError) return <CouponHistory.Error handleClick={() => refetch()} />;
  if (isSuccess && data?.pagination.totalResults === 0)
    return <CouponHistory.EmptyHistory />;
  return (
    <div
      className="stratus--coupon-history"
      style={{ maxHeight: height, gap: 10 }}
    >
      <Card padding="none">
        <Box paddingTop="4" paddingRight="4" paddingLeft="4">
          <Card.Header title={t('title')} />
        </Box>
        <Box
          paddingBottom="6"
          marginBottom="4"
          paddingRight="4"
          paddingLeft="4"
          overflowY="auto"
        >
          <Card.Body>
            <div ref={containerRef}>
              <CouponHistoryTimeline items={items} />
              {isFetching && <CouponHistory.LoadingTimelineItem />}
            </div>
          </Card.Body>
        </Box>
      </Card>
    </div>
  );
}

CouponHistory.Skeleton = Skeleton;
CouponHistory.Error = CouponHistoryError;
CouponHistory.EmptyHistory = EmptyHistory;
CouponHistory.LoadingTimelineItem = LoadingTimelineItem;
