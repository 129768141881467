import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { ErrorState } from '@tiendanube/components';

interface StockHistoryErrorProps {
  onRetryError: () => void;
}

function StockHistoryError({
  onRetryError,
}: StockHistoryErrorProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return (
    <ErrorState
      title={t('products.stockHistoryModal.error.title')}
      action={{
        children: t('products.stockHistoryModal.error.retry'),
        onClick: onRetryError,
      }}
    />
  );
}

export default StockHistoryError;
