import { Table } from '@nimbus-ds/components';
import { Repeater } from 'commons/components';
import { DomainsListDesktopHeader } from './DomainsListDesktopHeader';
import { DomainsListDesktopRow } from './DomainsListDesktopRow/DomainsListDesktopRow';
import './DomainsListDesktop.scss';

export function Skeleton() {
  return (
    <div className="stratus--domains-list-desktop">
      <Table>
        <DomainsListDesktopHeader />
        <Table.Body>
          <Repeater times={3} item={DomainsListDesktopRow.Skeleton} />
        </Table.Body>
      </Table>
    </div>
  );
}
