import { Box } from '@nimbus-ds/components';
import { Card, Input, InterfaceNameValue } from '@tiendanube/components';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductDetailSKUBlur } from 'domains/Catalog/Products/tracking';
import { HandleChangeInterface } from '../../hooks/useProductForm/types';
import ApplyValuesToAllVariants from '../ApplyValuesToAllVariants';
import { OnChangeToAllVariants } from '../Variants/types';

interface ProductCodesProps {
  barcode: string;
  sku: string;
  asColumns?: boolean;
  onChange: (data: HandleChangeInterface) => void;
  onChangeToAllVariants?: OnChangeToAllVariants;
  isEdit?: boolean;
}

function ProductCodes({
  onChange,
  sku,
  onChangeToAllVariants,
  barcode,
  asColumns = false,
  isEdit,
}: ProductCodesProps): JSX.Element | null {
  const t = useTranslationCatalog();
  const columnGrid = { xs: asColumns ? '1fr 1fr' : '1fr' };
  const sender = useTrackFullCatalog();

  const handleSKUBlur = (event: InterfaceNameValue) => {
    const { value } = event;
    const hasValue = !!value;
    sender(() => {
      trackingProductDetailSKUBlur(hasValue, isEdit);
    });
  };

  return (
    <Card title={t('products.detail.productCodes')}>
      <Box display="grid" gridTemplateColumns={columnGrid} gridGap="4">
        <Box display="flex" flexDirection="column" gap="2">
          <Input
            type="text"
            label={t('products.detail.sku')}
            placeholder=""
            helpText={t('products.detail.skuInfo')}
            value={sku || ''}
            onChange={onChange}
            onBlur={handleSKUBlur}
            name="sku"
          />
          {onChangeToAllVariants && (
            <ApplyValuesToAllVariants
              value={sku || ''}
              onChange={onChangeToAllVariants('sku', sku)}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap="2">
          <Input
            type="text"
            label={t('products.detail.barcode')}
            placeholder=""
            helpText={t('products.detail.barcodeInfo')}
            value={barcode || ''}
            onChange={onChange}
            name="barcode"
          />
          {onChangeToAllVariants && (
            <ApplyValuesToAllVariants
              value={barcode || ''}
              onChange={onChangeToAllVariants('barcode', barcode)}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default ProductCodes;
