import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import { getStatusFeatureByKeyWithDefaultValue } from 'domains/Auth/authSlice/authSelectors';

function useStatusFeatureByKeyWithDefaultValue(
  key: string,
  defaultValue: number,
) {
  const getStatusFeatureByKeyWithDefaultValueMemo = useMemo(
    () => getStatusFeatureByKeyWithDefaultValue,
    [],
  );
  const statusFeature = useSelector((state: RootStateType) =>
    getStatusFeatureByKeyWithDefaultValueMemo(state, key, defaultValue),
  );

  return statusFeature;
}

export default useStatusFeatureByKeyWithDefaultValue;
