import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { Domain } from '@tiendanube/common/src/enums';
import { Stack, Button, Popover } from '@tiendanube/components';
import { CheckCircleIcon } from '@tiendanube/icons';
import HelpLink from 'App/HelpLink';
import { SubmitButton } from 'commons/components';
import { IonPageStratus } from 'commons/components/IonPageStratus';
import {
  ActionProp,
  ActionsDesktop,
  ApplicationsDesktop,
  HeaderContent,
  HeaderTop,
} from 'commons/components/IonPageStratus/components';
import { CAN_LINK_ABOUT_PRODUCTS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailCancelClick } from 'domains/Catalog/Products/tracking';
import { ConfirmNullStockVariantsModal } from './compnents/ConfirmNullStockVariantsModal';

interface ProductPageProps {
  children: ReactNode;
  isDirty: boolean;
  isSaving: boolean;
  isDisabled?: boolean;
  handleSave: () => void;
  actions?: ActionProp[];
  appLinks?: ActionProp[];
  title: string;
  showModalNullStockVariant: boolean;
  handleCloseModalNullStockVariant: () => void;
  handleConfirmModalNullVaritantStock: () => void;
  isEdit?: boolean;
}

function ProductPage({
  isSaving,
  handleSave,
  actions = [],
  appLinks = [],
  children,
  isDisabled = false,
  title,
  isDirty,
  showModalNullStockVariant,
  handleCloseModalNullStockVariant,
  handleConfirmModalNullVaritantStock,
  isEdit,
}: ProductPageProps): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation(Domain.CATALOG);
  const language = useTranslationLanguage();
  const sender = useTrackFullCatalog();

  const goBack = () => history.goBack();

  const headerAction = {
    onClick: handleSave,
    children: t('products.detail.header.save'),
    icon: CheckCircleIcon,
    spinner: isSaving,
    disabled: isDisabled || isSaving,
  };

  const renderMenuMobile = actions.length > 0 && (
    <Popover menu={actions} name="dropdownMenu" position="right" />
  );

  const renderActions = actions.length > 0 && (
    <ActionsDesktop actions={actions} />
  );

  const headerNavigation = { onClick: goBack };

  const handleCancel = () => {
    sender(() => {
      trackingProductDetailCancelClick(isEdit);
    });
    goBack();
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop
          navigation={headerNavigation}
          mainAction={headerAction}
          actions={renderMenuMobile}
        />
      }
      headerContent={
        <HeaderContent
          title={title}
          documentTitle={t('products.editProductTitle')}
          actions={renderActions}
          applications={<ApplicationsDesktop applications={appLinks} />}
        />
      }
    >
      <form onSubmit={handleSave}>
        <Stack column align="stretch" spacing="base">
          {children}
          <Stack spacing="base" justify="flex-end">
            <Stack.Item>
              <Button type="button" onClick={handleCancel}>{`${t(
                'products.detail.cancel',
              )}`}</Button>
            </Stack.Item>
            <Stack.Item>
              <SubmitButton
                type="submit"
                disabled={isDisabled || isSaving}
                isLoading={isSaving}
                appearance="primary"
              >
                {`${t('products.detail.save')}`}
              </SubmitButton>
            </Stack.Item>
          </Stack>
        </Stack>
      </form>
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />
      <HelpLink
        title={t('helpLink.aboutProducts')}
        linkURL={CAN_LINK_ABOUT_PRODUCTS[language]}
        previousValue="product_details"
        currentViewTracking={t('helpLink.aboutProductsTrackingName')}
      />
      <ConfirmNullStockVariantsModal
        show={showModalNullStockVariant}
        onClose={handleCloseModalNullStockVariant}
        onConfirm={handleConfirmModalNullVaritantStock}
      />
    </IonPageStratus>
  );
}

export default ProductPage;
