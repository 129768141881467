import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useVerifyMercadoPagoAlert from '../VerifyMercadoPagoAlert/useVerifyMercadoPagoAlert';

function VerifyMercadoPagoSuccessOrDangerAlert() {
  const t = useTranslationDashboard('paymentMessages.mercadoPago.notVerified');
  const { data, handleCleanVerifyMercadoPagoData } =
    useVerifyMercadoPagoAlert();
  const title = data?.verified ? t('success.title') : t('danger.title');
  const description = data?.verified
    ? t('success.description')
    : t('danger.description');

  if (!data) return null;

  return (
    <Alert
      show={!!data}
      appearance={data.verified ? 'success' : 'danger'}
      title={title}
      onRemove={handleCleanVerifyMercadoPagoData}
    >
      <Text>{description}</Text>
    </Alert>
  );
}

export default VerifyMercadoPagoSuccessOrDangerAlert;
