import MessagesListItemSkeleton from './MessagesListItemSkeleton';

function MessagesListDesktopSkeleton(): JSX.Element {
  return (
    <>
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
    </>
  );
}

export default MessagesListDesktopSkeleton;
