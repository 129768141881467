import { useEffect } from 'react';
import { LocationResponseDto } from '@tiendanube/common';
import { Responsive } from 'commons/components';
import { useHasShippingMultiCD } from 'domains/Auth/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { VariantsPreviewDesktop, VariantsPreviewMobile } from './components';
import { Variant, OnChangeToAllVariants } from '../../types';
import { VariantListItemInterface } from '../CardVariants/CardVariants';

export type OnClickStockHistoryType = (
  variant: VariantListItemInterface,
) => void;

export interface VariantsPreviewProps {
  variants: VariantListItemInterface[];
  locations?: LocationResponseDto[];
  isEdit?: boolean;
  onClickVariant: (index: number) => void;
  onClickImage: (index: number) => void;
  onChange: (i: number, v: Partial<Variant>) => void;
  onChangeToAllVariants: OnChangeToAllVariants;
  isDigital?: boolean;
}

function VariantsPreview({
  variants,
  isEdit,
  onClickImage,
  onClickVariant,
  onChange,
  onChangeToAllVariants,
  isDigital = false,
}: VariantsPreviewProps): JSX.Element {
  const hasShippingMultiCD = useHasShippingMultiCD();

  const {
    locations,
    isSuccess: isSuccessLocations,
    fetchLocations,
  } = useLocations();

  useEffect(() => {
    if (!isSuccessLocations && hasShippingMultiCD) {
      fetchLocations();
    }
  }, [isSuccessLocations, fetchLocations, hasShippingMultiCD]);

  const mobileProps = {
    variants,
    onClickImage,
    onClickVariant,
    onChange,
    locations,
    isDigital,
  };
  const desktopProps = {
    ...mobileProps,
    isEdit,
    onChangeToAllVariants,
    isDigital,
  };

  return (
    <Responsive
      mobile={<VariantsPreviewMobile {...mobileProps} />}
      desktop={<VariantsPreviewDesktop {...desktopProps} />}
    />
  );
}

export default VariantsPreview;
