import { ChangeEvent } from 'react';
import { FormField, FormFieldInputBaseProps } from '@nimbus-ds/patterns';

interface InputBrazilianZipCodeProps
  extends Omit<FormFieldInputBaseProps, 'onChange'> {
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function ZipcodeFormatter(zipcode: string): string {
  let newZipcode = zipcode.replace(/\D/g, '');
  newZipcode = newZipcode.replace(/^(\d{5})(\d)/, '$1-$2');
  return newZipcode;
}

function InputBrazilianZipcode({
  value,
  onChange,
  ...props
}: InputBrazilianZipCodeProps) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const zipcode = ZipcodeFormatter(e.target.value);
    e.target.value = zipcode;
    onChange(e);
  };
  const zipcode = ZipcodeFormatter(value ?? '');

  return (
    <FormField.Input onChange={handleOnChange} value={zipcode} {...props} />
  );
}

export default InputBrazilianZipcode;
