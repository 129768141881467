/* eslint-disable max-statements */
import { useState, useEffect, useMemo } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain, Status } from '@tiendanube/common/src/enums';
import {
  Select,
  InterfaceSelectOptionGroup,
  Stack,
  Button,
} from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { FEATURE_METAFIELDS_ORDERS, FEATURE_SEARCH_FILTER } from 'App/features';
import { ORDERS_PRODUCTS_COUNT_FILTER_ENABLED } from 'App/featuresFlags';
import { DateFilter } from 'commons/components';
import { optionValue } from 'commons/components/DateFilter/DateFilter';
import {
  ORDERS_METAFIELD_FILTERS_DROPDOWN,
  ORDERS_SHOW_FILTERS,
} from 'config/upsellFlowSources';
import { useGetTags, useHasOrdersMultiCD } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
} from 'domains/Metafields/types';
import {
  OrderPaymentMethodsFilter,
  OrderApplicationsFilter,
} from 'domains/Orders/components';
import OrderPaymentProvidersFilter from 'domains/Orders/components/OrderPaymentProvidersFilter';
import ProductsCountFilter from 'domains/Orders/components/ProductsCountFilter';
import { ProductInterface } from 'domains/Orders/components/ProductSearcher';
import ProductsFilter from 'domains/Orders/components/ProductsFilter';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import useGetOrdersFilters from 'domains/Orders/Orders/hooks/useGetOrdersFilters/useGetOrdersFilters';
import useIsProductsFilterEnabled from 'domains/Orders/Orders/hooks/useIsProductsFilterEnabled';
import {
  status,
  fulfillmentStatus,
  paymentStatus,
  orderOrigin,
  FilterStatusEnum,
  FiltersParamsType,
} from 'domains/Orders/Orders/ordersService';
import { shouldShowMultiCDFullfillmentStatus } from 'domains/Orders/Orders/utils/OrderListFilter';
import { validateDateLimit } from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import {
  trackingOrderFilter,
  trackingOrderMetafieldFilter,
} from 'domains/Orders/tracking';
import { useGetShippingMethods } from 'domains/Shipping/DeliveryMethods/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import { MetafieldsOrdersFilters } from './components';

interface FilterModalProps {
  show: boolean;
  filter: FiltersParamsType;
  selectedProducts: ProductInterface<true>[];
  addSelectedProductParam: (products: ProductInterface<true>[]) => void;
  serializeProducts: (products: ProductInterface<true>[]) => string;
  hideModal: () => void;
  addParam: (filters: FiltersParamsType) => void;
}

function FilterModal({
  show,
  hideModal,
  filter,
  addParam,
  selectedProducts,
  addSelectedProductParam,
  serializeProducts,
}: FilterModalProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);

  const { shippingMethods } = useGetShippingMethods();
  const { paymentMethodOptions, paymentProviderOptions, applicationOptions } =
    useGetOrdersFilters();
  const [values, setValues] = useState(filter);
  const [selectedProductsValues, setSelectedProductsValues] =
    useState(selectedProducts);

  const [selectedOption, setSelectedOption] = useState(optionValue.ALL);

  const hasOrdersMultiCD = useHasOrdersMultiCD();
  const hasEditOrders = useIsEditOrdersEnabled();
  const tags = useGetTags();
  const hasProductsFilter = useIsProductsFilterEnabled();
  const hasProductsCountFilter = useMemo(
    () => tags.includes(ORDERS_PRODUCTS_COUNT_FILTER_ENABLED),
    [tags],
  );

  const { locations } = useLocations();
  const isLocationsFilterShowable = hasOrdersMultiCD && locations.length > 1;

  useEffect(() => {
    setValues(filter);
  }, [filter]);

  useEffect(() => {
    setSelectedProductsValues(selectedProducts);
  }, [selectedProducts]);

  const handleChange = useUpsellFlow({
    title: t('filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SHOW_FILTERS,
    asAside: true,
    callback: ({ name, value }) => {
      setValues((prev) => ({ ...prev, [name]: value }));
    },
  });

  const handleProductsFilterChange = useUpsellFlow({
    title: t('filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SHOW_FILTERS,
    asAside: true,
    callback: ({ name, value }) => {
      setSelectedProductsValues(value);
      const serializedProducts = serializeProducts(value);
      setValues((prev) => ({ ...prev, [name]: serializedProducts }));
    },
  });

  const handleDateRangeChange = useUpsellFlow({
    title: t('filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SHOW_FILTERS,
    asAside: true,
    callback: ({ option, dateFrom, dateTo }) => {
      setValues((prev) => ({ ...prev, dateFrom: dateFrom, dateTo: dateTo }));
      setSelectedOption(option);
    },
  });

  const handleClear = () => {
    setValues({
      status: FilterStatusEnum.EMPTY,
      dateFrom: '',
      dateTo: '',
      fulfillmentStatus: FilterStatusEnum.EMPTY,
      paymentStatus: FilterStatusEnum.EMPTY,
      paymentMethods: '',
      shippingMethod: '',
      paymentProvider: '',
      location: '',
      origin: '',
      appId: '',
      products: '',
      productsCount: '',
    });
    setSelectedProductsValues([]);
  };

  const handleConfirm = useUpsellFlow({
    title: t('filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SHOW_FILTERS,
    asAside: true,
    callback: () => {
      addSelectedProductParam(selectedProductsValues);
      addParam(validateDateLimit(values));
      hideModal();
      trackingOrderFilter({
        orderStatusFilter: values.status,
        dateRangeFilter: selectedOption,
        shippingStatusFilter: String(!!values.fulfillmentStatus),
        paymentStatusFilter: String(!!values.paymentStatus),
        paymentMethodFilter: String(!!values.paymentMethods),
        paymentProviderFilter: String(!!values.paymentProvider),
        shippingFilter: String(!!values.shippingMethod),
        channelFilter: String(!!values.origin),
        applicationFilter: String(!!values.appId),
        productsFilter: String(!!values.products),
        countSelectedProductsFilter: String(selectedProductsValues.length),
        productsCountFilter: String(!!values.productsCount),
        productsCountFilterValue: String(values.productsCount),
      });
    },
  });

  const statusOptions: InterfaceSelectOptionGroup[] = status.map((status) => ({
    value: status === Status.OPEN ? '' : status,
    label: t(`filters.status.${status}`),
  }));

  const fulfillmentStatusOptions: InterfaceSelectOptionGroup[] =
    fulfillmentStatus
      .filter((status) =>
        shouldShowMultiCDFullfillmentStatus(status, hasOrdersMultiCD),
      )
      .map((status) => ({
        value: status,
        label: t(`filters.fulfillment.${status}`),
      }));

  const paymentOptions: InterfaceSelectOptionGroup[] = paymentStatus
    .concat(hasEditOrders ? [Status.PARTIALLY_PAID] : [])
    .map((payment) => ({
      value: payment,
      label: t(`filters.payment.${payment}`),
    }));

  const shippingMethodAllOption: InterfaceSelectOptionGroup = {
    group: t('filters.shippingMethod.all'),
    options: [{ label: t('filters.shippingMethod.all'), value: '' }],
  };
  const shippingMethodOptions: InterfaceSelectOptionGroup[] = [
    shippingMethodAllOption,
  ].concat(
    Object.keys(shippingMethods).map((key) => ({
      group: t(`filters.shippingMethod.${key}`),
      options: shippingMethods[key].map((method) => ({
        label: method.name,
        value: method.code,
      })),
    })),
  );

  const locationOptions: InterfaceSelectOptionGroup[] = [
    { value: '', label: t('filters.location.all') },
    ...locations.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];

  const originOptions: InterfaceSelectOptionGroup[] = orderOrigin.map(
    (origin) => ({
      value: origin,
      label: t(`filters.origin.${origin}`),
    }),
  );

  const handleChangeMetafields: OnChangeMetafieldType = (id, value) => {
    trackingOrderMetafieldFilter(id, value);
    if (value !== null) {
      setValues((values) => ({
        ...values,
        [METAFIELD_FILTER_PREFIX + id]: value,
      }));
      return;
    }
    setValues((values) => {
      const newValues = { ...values };
      delete newValues[METAFIELD_FILTER_PREFIX + id];
      return newValues;
    });
  };

  const onChangeMetafieldFilter = useUpsellFlow({
    title: t('filter.upsell.metafieldFilters'),
    featureKey: FEATURE_METAFIELDS_ORDERS,
    trackingSource: ORDERS_METAFIELD_FILTERS_DROPDOWN,
    callback: handleChangeMetafields,
    asAside: true,
  });

  const selecteds = Object.entries(values)
    .filter(([key]) => key.startsWith(METAFIELD_FILTER_PREFIX))
    .map(([key, value]) => ({
      id: key.split(METAFIELD_FILTER_PREFIX)[1],
      value,
    })) as MetafieldSelectedInterface[];

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={hideModal}
      headerContent={t('filter.filterBy')}
      footer={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          width="100%"
          gap="4"
          flex="1"
        >
          <Button onClick={handleClear}>{`${t('filter.cleanFilter')}`}</Button>
          <Button onClick={handleConfirm} appearance="primary">
            {`${t('filter.filter')}`}
          </Button>
        </Box>
      }
      footerHeight="70px"
    >
      <Stack column align="stretch" spacing="loose">
        <Select
          label={t('filter.status')}
          name="status"
          value={values.status}
          options={statusOptions}
          onChange={handleChange}
        />
        <DateFilter
          label={t('filter.dateRange')}
          dateFrom={values.dateFrom}
          dateTo={values.dateTo}
          onChange={handleDateRangeChange}
          threeMonthsLimited={
            values.paymentMethods !== '' || values.paymentProvider !== ''
          }
        />
        <Stack column align="stretch" spacing="base">
          <Select
            label={t('filter.fulfillmentStatus')}
            name="fulfillmentStatus"
            value={values.fulfillmentStatus}
            options={fulfillmentStatusOptions}
            onChange={handleChange}
          />
          <Select
            label={t('filter.paymentStatus')}
            name="paymentStatus"
            value={values.paymentStatus}
            options={paymentOptions}
            onChange={handleChange}
          />
          {hasProductsFilter && (
            <ProductsFilter
              selectedProducts={selectedProductsValues}
              onProductsChange={handleProductsFilterChange}
            />
          )}
          {hasProductsCountFilter && (
            <ProductsCountFilter
              value={values.productsCount}
              onChange={handleChange}
            />
          )}
          <OrderPaymentMethodsFilter
            paymentMethods={values.paymentMethods}
            paymentMethodOptions={paymentMethodOptions}
            handleChange={handleChange}
          />
          <OrderPaymentProvidersFilter
            paymentProviders={values.paymentProvider}
            paymentProviderOptions={paymentProviderOptions}
            handleChange={handleChange}
          />
          <Select
            label={t('filter.shippingMethod')}
            name="shippingMethod"
            value={values.shippingMethod}
            options={shippingMethodOptions}
            onChange={handleChange}
          />
          <Select
            label={t('filter.origin')}
            name="origin"
            value={values.origin}
            options={originOptions}
            onChange={handleChange}
          />
          {isLocationsFilterShowable && (
            <Select
              label={t('filter.location')}
              name="location"
              value={values.location}
              options={locationOptions}
              onChange={handleChange}
            />
          )}
          <OrderApplicationsFilter
            application={values.appId}
            applicationOptions={applicationOptions}
            handleChange={handleChange}
          />
        </Stack>

        <MetafieldsOrdersFilters
          selecteds={selecteds}
          onChange={onChangeMetafieldFilter}
        />
      </Stack>
    </ModalAside>
  );
}

export default FilterModal;
