import { Preferences } from '@capacitor/preferences';

export const EXPIRE_AT = {
  SEVEN_DAYS: 60 * 60 * 24 * 7,
};

interface StorageItem<T> {
  value: T;
  expiresAt: number | null; // Timestamp in seconds or null for no expiration
}

export async function setStorage<T>(
  key: string,
  value: T,
  ttl?: number,
): Promise<void> {
  const expiresAt = ttl ? Date.now() + ttl * 1000 : null;
  const item: StorageItem<T> = { value, expiresAt };

  await Preferences.set({
    key: key,
    value: JSON.stringify(item),
  });
}

export async function getStorage<T>(key: string): Promise<T | undefined> {
  const item = await Preferences.get({ key: key });

  if (!item.value) {
    return undefined;
  }

  const storageItem: StorageItem<T> = JSON.parse(item.value);

  if (storageItem.expiresAt && storageItem.expiresAt < Date.now()) {
    await removeStorage(key);
    return undefined;
  }

  return storageItem.value;
}

export async function removeStorage(key: string): Promise<void> {
  await Preferences.remove({ key: key });
}

// Usage Example:
// Set a value with a time-to-live (TTL) of 1 hour (3600000 milliseconds)
// await setStorage('myKey', 'myValue', 3600000);

// Get the value (will return undefined if expired or does not exist)
// const myValue = await getStorage('myKey');
