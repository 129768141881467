import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { getStoreConversion } from 'domains/Statistics/statisticsSlice';
import useGetStatisticsConversionStoreData from '../useGetStatisticsConversionStoreData';
import useStatisticsFilterContext from '../useStatisticsFilterContext';
import useStatisticsStoredFilters from '../useStatisticsStoredFilters';

function useStatisticsConversionStore() {
  const dispatch = useAppDispatch();
  const data = useGetStatisticsConversionStoreData();
  const { hasAppliedFilters, hasComparedFilter, openFilter } =
    useStatisticsFilterContext();

  const fetchStoreConversion = useCallback(async () => {
    await dispatch(getStoreConversion());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchStoreConversion);

  return {
    ...data,
    openFilter,
    hasAppliedFilters,
    hasComparedFilter,
    fetchStoreConversion,
  };
}

export default useStatisticsConversionStore;
