import { Route, Switch } from 'react-router-dom';
import { HideNavTabs } from 'App/components/Navtabs';
import { LOCATIONS_BASE_ROUTE, LOCATIONS_ROUTES } from './locationsRoutes';
import EditLocationPage from './pages/EditLocationPage';
import LocationListPage from './pages/LocationListPage';
import NewLocationCreatedPage from './pages/NewLocationCreatedPage/NewLocationCreatedPage';
import NewLocationPage from './pages/NewLocationPage';

function Locations() {
  return (
    <Switch>
      <Route path={LOCATIONS_BASE_ROUTE} exact>
        <LocationListPage />
      </Route>
      <Route path={LOCATIONS_ROUTES.newLocationMain} exact>
        <HideNavTabs />
        <NewLocationPage type="main" />
      </Route>
      <Route path={LOCATIONS_ROUTES.newLocationSecondary} exact>
        <HideNavTabs />
        <NewLocationPage type="secondary" />
      </Route>
      <Route path={LOCATIONS_ROUTES.newLocationCreated} exact>
        <HideNavTabs />
        <NewLocationCreatedPage />
      </Route>
      <Route path={LOCATIONS_ROUTES.editLocation}>
        <HideNavTabs />
        <EditLocationPage />
      </Route>
    </Switch>
  );
}

export default Locations;
