import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { toStatusType } from 'App/rtk';
import { CardWithTitle, ExternalLink, Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import {
  useDeleteFacebookCodeMutation,
  useGetExternalCodesQuery,
} from 'domains/Configurations/ExternalCodes/externalCodesApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { LinkedFacebookPixelCode } from './LinkedFacebookPixelCode';
import { Skeleton } from './Skeleton';
import { SplitSection } from '../SplitSection';

interface ExternalCodesFacebookCardProps {
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  fbPixelCode: string;
  errors: Partial<Record<string, string>>;
}

export function ExternalCodesFacebookCard({
  onChangeTextArea,
  fbPixelCode,
  errors,
}: Readonly<ExternalCodesFacebookCardProps>) {
  const t = useTranslationConfigurations('externalCodes.facebook');
  const { data } = useGetExternalCodesQuery();
  const [onUnlink, { isLoading: isDeleting, status }] =
    useDeleteFacebookCodeMutation();

  const handleClick = () => {
    onUnlink();
  };

  const handleChangeFacebookPixelCode = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    event.target.value = event.target.value.trim();
    onChangeTextArea(event);
  };

  const showLinkedCard = fbPixelCode && data?.fbPixelCode;

  useToastStatus({
    status: toStatusType(status),
    success: t('fbPixel.linked.toast.success'),
    error: t('fbPixel.linked.toast.error'),
  });

  useScrollToError(errors);

  const errorFbPixelCode = errors?.fbPixelCode || '';

  return (
    <CardWithTitle title={t('title')}>
      <Stack column spacing="loose" align="stretch">
        <SplitSection
          title={t('fbPixel.title')}
          description={t('fbPixel.description')}
        >
          {showLinkedCard ? (
            <LinkedFacebookPixelCode
              onClick={handleClick}
              fbPixelCode={fbPixelCode}
              hasFbConfigured={data.hasFbConfigured}
              isDeleting={isDeleting}
            />
          ) : (
            <FormField.Textarea
              onChange={handleChangeFacebookPixelCode}
              value={fbPixelCode}
              name="fbPixelCode"
              id="fbPixelCode"
              placeholder={t('fbPixel.placeholder')}
              label={t('fbPixel.label')}
              helpText={t(errorFbPixelCode)}
              showHelpText={!!errorFbPixelCode}
              appearance={errorFbPixelCode ? 'danger' : undefined}
            />
          )}
        </SplitSection>
        <ExternalLink
          href={t('fbPixel.href')}
          textDecoration="none"
          appearance="primary"
        >
          {t('fbPixel.link')}
        </ExternalLink>
      </Stack>
    </CardWithTitle>
  );
}

ExternalCodesFacebookCard.Skeleton = Skeleton;
