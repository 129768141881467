import { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { Stack } from '@tiendanube/components';
import ScrollProvider from 'App/components/AppLayout/ScrollProvider';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useResponsive } from '../Responsive';
import './IonPageStratus.scss';

export interface IonPageStratusProps {
  children: ReactNode;
  headerTop?: ReactNode;
  headerContent?: ReactNode;
  width?: 'small' | 'medium' | 'large';
}

function IonPageStratus({
  headerTop,
  headerContent,
  children,
  width = 'large',
}: Readonly<IonPageStratusProps>) {
  const { isMobile } = useResponsive();
  const { setHeaderNavigation } = useTopBar();

  useEffect(() => {
    !headerTop && setHeaderNavigation(undefined);
    return () => setHeaderNavigation(undefined);
  }, [headerTop, setHeaderNavigation]);

  const classStratusPageContent = classNames(
    'stratus--page-content-container',
    `stratus--page-width-${width}`,
    { 'stratus--page-no-header-top': headerTop === undefined && isMobile },
  );

  return (
    <div className="stratus--page">
      {!!headerTop && headerTop}
      <ScrollProvider>
        <div className={classStratusPageContent}>
          {headerContent}
          <div className="stratus--page-content">
            <Stack column align="stretch" spacing="base">
              {children}
            </Stack>
          </div>
        </div>
      </ScrollProvider>
    </div>
  );
}

export default IonPageStratus;
