import { HTMLAttributes } from 'react';
import { BoxSprinkle, Checkbox, CheckboxProps } from '@nimbus-ds/components';
import {
  InteractiveList,
  DataList,
  InteractiveListStructureProperties,
} from '@nimbus-ds/patterns';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from './Skeleton';

export interface InteractiveListRightCheckboxInterface
  extends Omit<InteractiveListStructureProperties, 'title'> {
  checkbox: CheckboxProps;
  grayed?: boolean;
  topDivider?: boolean;
  avoidHorizontalPadding?: boolean;
  title?: string;
  titleSlot?: React.ReactNode;
}

export type InteractiveListRightCheckboxProps =
  InteractiveListRightCheckboxInterface &
    Omit<HTMLAttributes<HTMLElement>, 'color'>;

function InteractiveListRightCheckbox({
  checkbox,
  grayed = false,
  topDivider = true,
  avoidHorizontalPadding = false,
  title = '',
  titleSlot,
  ...rest
}: InteractiveListRightCheckboxProps): JSX.Element {
  const itemID = uuidv4();
  const backgroundColor: BoxSprinkle['backgroundColor'] = grayed
    ? {
        xs: 'neutral-surfaceDisabled',
      }
    : {
        hover: 'neutral-surface',
      };
  const horizontalPadding = avoidHorizontalPadding ? 'none' : undefined;

  return (
    <DataList.Row
      role="menuitemcheckbox"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      as="label"
      aria-checked={checkbox.checked}
      htmlFor={itemID}
      flexDirection="row"
      gap="2"
      cursor="pointer"
      alignItems="flex-start"
      backgroundColor={backgroundColor}
      transitionDuration="fast"
      transitionProperty="background-color"
      transitionTimingFunction="ease-in-out"
      boxShadow={{ focusWithin: 'focusRing' }}
      borderWidth="none"
      borderTopWidth={topDivider ? '1' : 'none'}
      paddingLeft={horizontalPadding}
      paddingRight={horizontalPadding}
    >
      <InteractiveList.Structure title={title} {...rest}>
        {titleSlot}
      </InteractiveList.Structure>
      <Checkbox id={itemID} {...checkbox} />
    </DataList.Row>
  );
}

InteractiveListRightCheckbox.Skeleton = Skeleton;

export default InteractiveListRightCheckbox;
