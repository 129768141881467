import {
  EyeIcon,
  EyeOffIcon,
  IconProps,
  PlusCircleIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { CouponActivityType } from '@tiendanube/common';

export interface EventTypeIconConfig {
  icon?: React.FC<IconProps>;
  appearance: 'default' | 'primary' | 'warning' | 'success';
}
export const eventTypeIconConfigMap: {
  [key in CouponActivityType]: EventTypeIconConfig;
} = {
  CouponCreated: {
    icon: PlusCircleIcon,
    appearance: 'primary',
  },
  CouponActivated: {
    icon: EyeIcon,
    appearance: 'success',
  },
  CouponDeactivated: {
    icon: EyeOffIcon,
    appearance: 'default',
  },
  CouponUpdated: {
    appearance: 'default',
  },
  CouponDeleted: {
    icon: TrashIcon,
    appearance: 'warning',
  },
};
