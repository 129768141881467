import { Box } from '@nimbus-ds/components';
import { TimelineChanges } from './components/Changes/TimelineChanges';
import { TimelineDetails } from './components/Details/TimelineDetails';
import { InterfaceCouponHistoryTimelineItem } from './types';
import './CouponHistoryTimelineItem.scss';

export function CouponHistoryTimelineItem({
  icon: IconType,
  appearance = 'default',
  title,
  author,
  changes,
  date,
  time,
  key,
  isLast = false,
}: InterfaceCouponHistoryTimelineItem) {
  const component = (
    <>
      <div className="nimbus--timeline-item" key={key + '-details'}>
        <TimelineDetails
          {...{ icon: IconType, appearance, title, author, date, time }}
        />
      </div>

      {!isLast && (
        <div className="nimbus--timeline-item nimbus--timeline-item-description">
          <div className="nimbus--timeline-no-icon nimbus--timeline-secondary-item-no-icon" />
          <Box width="100%">
            <TimelineChanges {...{ changes }} />
          </Box>
        </div>
      )}
    </>
  );

  return isLast ? (
    <div className="nimbus--timeline-item-last-description-container">
      {component}
    </div>
  ) : (
    component
  );
}
