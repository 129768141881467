import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import { Prompt, useHistory } from 'react-router';
import { Alert, Button, Stack } from '@tiendanube/components';
import { RemarksCard } from 'commons/components';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import {
  useDocumentTitle,
  useForm,
  useAsyncFunc,
  useToast,
} from 'commons/hooks';
import { useGetCountry, useIsMobileDevice } from 'domains/Auth/hooks';
import { SelectedProductInterface } from 'domains/Catalog/Products/components';
import { trackingOrderDraftOrderCreate } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import NewDraftOrderProduct, {
  OrderSummary,
  ClientData,
  OrderStatus,
  SalesChannel,
  SelectedVariantInterface,
  AddressCard,
} from './components';
import { initialValues } from './constants';
import {
  NewDraftOrdersValuesInterface,
  NewDraftOrdersErrorsInterface,
} from './types';
import { validationSchema } from './validationSchema';
import { DraftOrdersPOSAlert } from '../../components/DraftOrdersPOSAlert';
import { STOCK_ERROR } from '../../constants';
import useCreateDraftOrder from '../../hooks/useCreateDraftOrder';

function NewDraftOrderPage(): JSX.Element {
  const history = useHistory();
  const t = useTranslationOrders();
  const country = useGetCountry();
  const isMobileDevice = useIsMobileDevice();

  const { addDraftOrder } = useCreateDraftOrder();
  const { addToast } = useToast();
  const [selectedProducts, setSelectedProducts] = useState<
    SelectedProductInterface[]
  >([]);
  const [refreshProductsList, setRefreshProductsList] = useState(false);

  const title = t('draftOrders.newDraftOrder.title');
  useDocumentTitle(title);

  const [handleCreateDraftOrder, isLoading, isError, clearError, errorMessage] =
    useAsyncFunc<NewDraftOrdersValuesInterface, Promise<void>>(async (data) => {
      if (data) {
        const draftOrder = await addDraftOrder(data);
        trackingOrderDraftOrderCreate(values.paymentStatus);
        addToast({
          label: t('draftOrders.newDraftOrder.success'),
          appearance: 'success',
        });
        if (values.paymentStatus === 'unpaid')
          history.replace(`/draft-orders/${draftOrder.id}`);
        else history.replace(`/orders/${draftOrder.id}`);
      }
    });

  const getVariantIdsWithoutStock = (stockErrorMessage: string) =>
    stockErrorMessage.split('.')[1].split(',');

  const {
    errors,
    values,
    isDirty,
    isValidating,
    handleOnChange,
    handleValidations,
    handleOnSubmit,
    setFieldValue,
  } = useForm<NewDraftOrdersValuesInterface, NewDraftOrdersErrorsInterface>({
    initialValues,
    validationSchema: validationSchema(country),
    onSubmit: (data) => {
      handleValidations(() => handleCreateDraftOrder(data));
    },
    validateOnBlur: ['zipcode'],
  });

  const revalidateVariants = useCallback(
    (unavailableVariants: string[]) => {
      const newProducts = values.products.map((selectedVariant) => {
        if (unavailableVariants.includes(`${selectedVariant.variantId}`)) {
          selectedVariant.stock = 0;
        }
        return selectedVariant;
      });

      setFieldValue('products', newProducts);
      handleValidations(() => undefined);
    },
    [handleValidations, setFieldValue, values.products],
  );

  useEffect(() => {
    if (isError) {
      const isStockError = errorMessage.startsWith(STOCK_ERROR);
      const errorLabel = isStockError
        ? t('draftOrders.newDraftOrder.stockError')
        : t('draftOrders.newDraftOrder.error');
      setRefreshProductsList(isStockError);
      if (isStockError) {
        const variantIds = getVariantIdsWithoutStock(errorMessage);
        variantIds.length > 0 && revalidateVariants(variantIds);
      }
      addToast({
        label: errorLabel,
        appearance: 'danger',
      });
      clearError();
    }
  }, [
    isError,
    clearError,
    addToast,
    errorMessage,
    setRefreshProductsList,
    revalidateVariants,
    t,
  ]);

  // Check if there are not selected products and set values to 0
  // (OrderSummary doesn't trigger the change because it's not rendered
  // when selectedProducts is empty, so the useEffect is not executed)
  useEffect(() => {
    if (selectedProducts.length === 0) {
      setFieldValue('products', []);
      setFieldValue('subtotal', 0);
      setFieldValue('total', 0);
    }
  }, [setFieldValue, selectedProducts]);

  const handleOnSelectProduct = (
    selected: SelectedProductInterface[],
  ): void => {
    setSelectedProducts(selected);
  };

  const handleOnSelectVariant = (
    selectedVariants: SelectedVariantInterface[],
  ) => {
    setFieldValue('products', selectedVariants);
  };

  const handleOnRemoveVariant = (variant: SelectedVariantInterface) => {
    const variantsQuantity = values.products.filter(
      (v) => v.productId === variant.productId,
    ).length;

    if (variantsQuantity === 1) {
      setSelectedProducts((prev) =>
        prev.filter((product) => product.id !== variant.productId),
      );
    } else {
      const variantIndex = values.products.findIndex((v) =>
        isEqual(v, variant),
      );

      setFieldValue(
        'products',
        values.products.filter((_, index) => variantIndex !== index),
      );
    }
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: history.goBack }} />}
      headerContent={<HeaderContent title={title} />}
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />
      {!isMobileDevice && <DraftOrdersPOSAlert />}
      <Stack column align="stretch">
        <Alert show={!!errors.products} appearance="danger">
          {`${t(errors.products || '')}`}
        </Alert>
        <NewDraftOrderProduct
          selected={selectedProducts}
          onChange={handleOnSelectProduct}
          refreshProducts={refreshProductsList}
          setRefreshProducts={setRefreshProductsList}
        />
        {selectedProducts.length > 0 && (
          <OrderSummary
            selectedProducts={selectedProducts}
            values={values}
            errors={errors}
            onChangeVariants={handleOnSelectVariant}
            onChange={handleOnChange}
            onRemove={handleOnRemoveVariant}
          />
        )}
        <ClientData values={values} onChange={handleOnChange} errors={errors} />
        <OrderStatus
          paymentStatus={values.paymentStatus}
          onChange={handleOnChange}
        />
        <AddressCard
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
        />
        <SalesChannel
          value={values.salesChannel}
          error={errors.salesChannel}
          onChange={handleOnChange}
        />
        <RemarksCard
          title={t('draftOrders.remarksCard.title')}
          errorMessage={t('draftOrders.remarksCard.error')}
          remarks={values.remarks}
          onChange={handleOnChange}
        />
        <Stack spacing="base" justify="flex-end">
          <Button onClick={history.goBack}>{`${t(
            'draftOrders.newDraftOrder.cancel',
          )}`}</Button>
          <Button
            spinner={isValidating || isLoading}
            disabled={isValidating || isLoading}
            onClick={handleOnSubmit}
            appearance="primary"
          >
            {`${t('draftOrders.newDraftOrder.submit')}`}
          </Button>
        </Stack>
      </Stack>
    </IonPageStratus>
  );
}

export default NewDraftOrderPage;
