import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon, QuestionCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { trackingNewAdminCanLinkClick } from 'App/tracking';
import { Stack } from 'commons/components';
import { useIsMobileDevice } from 'domains/Auth/hooks';

interface HelpLinkProps {
  linkURL: string;
  title: string;
  currentViewTracking: string;
  previousValue: string;
  appearance?: 'primary' | 'neutral';
  icon?: 'question' | 'external' | 'both';
  textDecoration?: 'underline' | 'none';
  hasDisclaimer?: boolean;
  showInMobile?: boolean;
}

function HelpLink({
  linkURL,
  title,
  currentViewTracking,
  previousValue,
  appearance = 'primary',
  icon = 'question',
  textDecoration = 'none',
  hasDisclaimer = false,
  showInMobile = false,
}: HelpLinkProps) {
  const { t } = useTranslation('common');
  const isMobile = useIsMobileDevice();

  const handleTrackingNewAdminCanLinkClick = () =>
    trackingNewAdminCanLinkClick(previousValue, currentViewTracking);

  if (isMobile && !showInMobile) return null;

  return (
    <Stack align="center" justify="center">
      <Stack column gap="0-5">
        <Stack spacing="tight">
          {(icon === 'question' || icon === 'both') && (
            <Box
              display="flex"
              borderRadius="base"
              backgroundColor="primary-surface"
              p="2"
            >
              <QuestionCircleIcon />
            </Box>
          )}
          <Link
            appearance={appearance}
            as="a"
            href={linkURL}
            textDecoration={textDecoration}
            target="_blank"
            onClick={handleTrackingNewAdminCanLinkClick}
          >
            {title}
            {hasDisclaimer && '*'}
            {(icon === 'external' || icon === 'both') && (
              <Icon
                color="currentColor"
                source={<ExternalLinkIcon size={16} />}
              />
            )}
          </Link>
        </Stack>
        {hasDisclaimer && (
          <Text as="p" fontSize="caption">
            {t('helpText.inReview')}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}

export default HelpLink;
