interface BulkActionOption {
  value: string;
  showCounts: boolean;
}

export type BulkActionType = 'delete' | 'read' | 'unread' | 'spam';

export const bulkactions: BulkActionOption[] = [
  {
    value: 'delete',
    showCounts: true,
  },
  {
    value: 'read',
    showCounts: true,
  },
  {
    value: 'unread',
    showCounts: true,
  },
  {
    value: 'spam',
    showCounts: true,
  },
];
