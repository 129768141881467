import React, { useEffect } from 'react';
import { Tooltip, IconButton, Box } from '@nimbus-ds/components';
import {
  EditIcon,
  EllipsisIcon,
  LinkIcon,
  QuestionCircleIcon,
  StarIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { StoreDomainDto } from '@tiendanube/common';
import { Popover } from '@tiendanube/components';
import { ModalConfirmationBase } from 'App/components';
import { Stack, useResponsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useCreateDomainMutation } from 'domains/Configurations/Domains/domainsApi';
import { useCustomDomainDisabled } from 'domains/Configurations/Domains/hooks';
import { CREATE_DOMAIN_CACHE_KEY } from 'domains/Configurations/Domains/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { AttachDomainModal } from '../../AttachDomainModal';
import { EditOurDomainModal } from '../../EditOurDomainModal';

interface ActionsProps {
  domain: StoreDomainDto;
  ourDomain: string;
  domainsCount: number;
  isDeleting: boolean;
  onDelete: () => void;
  onSetPrimaryDomain: (id: string) => void;
}

export function Actions({
  domain: {
    id,
    name,
    isOurDomain,
    isPrimaryDomain,
    isSubdomain,
    supportsOurDns,
    status,
  },
  ourDomain,
  domainsCount,
  isDeleting,
  onDelete,
  onSetPrimaryDomain,
}: Readonly<ActionsProps>) {
  const t = useTranslationConfigurations('domains');
  const { isMobile } = useResponsive();
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [showEditDomainModal, openEditDomainModal, closeEditDomainModal] =
    useModal();
  const [showAttachDomainModal, openAttachDomainModal, closeAttachDomainModal] =
    useModal();

  const showStarIcon =
    !isOurDomain &&
    status.domain === 'ok' &&
    status.ssl === 'ok' &&
    !isPrimaryDomain;
  const showAttachIcon = !isOurDomain && status.domain !== 'ok';
  const showHelpIcon = !isOurDomain && status.domain === 'error';
  const isFreePlan = useCustomDomainDisabled();

  const [, { status: createDomainStatus, data: createDomainData }] =
    useCreateDomainMutation({
      fixedCacheKey: CREATE_DOMAIN_CACHE_KEY,
    });

  useEffect(() => {
    if (createDomainStatus === 'fulfilled' && createDomainData?.[0].id === id) {
      openAttachDomainModal();
    }
  }, [createDomainStatus, createDomainData, openAttachDomainModal, id]);

  const handleEdit = () => openEditDomainModal();
  const handleDelete = () => openDeleteModal();
  const handleHelp = () => openWindow(t('help.error'), true);
  const handleSetAsPrimary = () => onSetPrimaryDomain(id);

  const actions = [
    {
      children: t('actions.edit'),
      onClick: handleEdit,
      icon: EditIcon,
      show: isOurDomain && domainsCount === 1,
    },
    {
      children: t('actions.attach'),
      onClick: openAttachDomainModal,
      icon: LinkIcon,
      show: showAttachIcon && !isOurDomain && !isFreePlan,
    },
    {
      children: t('actions.help'),
      onClick: handleHelp,
      icon: QuestionCircleIcon,
      show: showHelpIcon && !isOurDomain,
    },
    {
      children: t('actions.star'),
      onClick: handleSetAsPrimary,
      icon: StarIcon,
      show: showStarIcon && !isOurDomain,
    },
    {
      children: t('actions.delete'),
      onClick: handleDelete,
      icon: TrashIcon,
      show: !isOurDomain,
    },
  ];

  if (actions.filter((action) => action.show).length === 0) return null;

  return (
    <Box minHeight="32px">
      <Stack spacing="tight">
        {isMobile ? (
          <Popover
            name="domains-menu"
            menu={actions.filter((option) => option.show)}
            position="right"
            renderInitiator={(onClick) => (
              <IconButton
                size="2rem"
                source={<EllipsisIcon />}
                onClick={onClick}
              />
            )}
          />
        ) : (
          actions.map(
            (action) =>
              action.show && (
                <Tooltip key={action.children} content={action.children}>
                  <IconButton
                    size="2rem"
                    source={React.createElement(action.icon)}
                    onClick={action.onClick}
                  />
                </Tooltip>
              ),
          )
        )}
      </Stack>
      {isOurDomain ? (
        <EditOurDomainModal
          currentDomain={name}
          show={showEditDomainModal}
          onClose={closeEditDomainModal}
        />
      ) : (
        <>
          <ModalConfirmationBase
            show={showDeleteModal}
            title={t('deleteModal.title')}
            text={t('deleteModal.info')}
            label={t('deleteModal.confirmBtn')}
            labelCancel={t('deleteModal.cancelBtn')}
            onConfirm={onDelete}
            onCancel={closeDeleteModal}
            disabled={isDeleting}
          />
          {showAttachIcon && (
            <AttachDomainModal
              show={showAttachDomainModal}
              domainName={name}
              domainId={id}
              supportsOurDns={supportsOurDns}
              isSubdomain={isSubdomain}
              ourDomain={ourDomain}
              onClose={closeAttachDomainModal}
            />
          )}
        </>
      )}
    </Box>
  );
}
