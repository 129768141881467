import { Box } from '@nimbus-ds/components';
import { Menu } from '@nimbus-ds/patterns';
import { Logo, LogoProps } from 'App/components';

interface MainMenuDesktopHeaderProps {
  headerLogo: LogoProps['src'];
  headerLogoColor: string;
}

export function MainMenuDesktopHeader({
  headerLogo,
  headerLogoColor,
}: Readonly<MainMenuDesktopHeaderProps>) {
  return (
    <div className="stratus--menu-header">
      <Menu.Header>
        <Box
          display="flex"
          gap="2"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          maxHeight="20px"
        >
          <Logo src={headerLogo} color={headerLogoColor} />
        </Box>
      </Menu.Header>
    </div>
  );
}
