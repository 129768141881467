import React from 'react';
import { Box } from '@nimbus-ds/components';
import classNames from 'classnames';
import { Text } from '@tiendanube/components';
import './TimelineDetails.scss';
import { TimelineDetailsInterface } from './types';

export function TimelineDetails({
  icon: Icon,
  appearance = 'default',
  title,
  author,
  date,
  time,
}: Readonly<TimelineDetailsInterface>) {
  const iconClass = React.useMemo(
    () =>
      classNames(
        'nimbus--timeline-icon',
        `nimbus--timeline-icon--${appearance}`,
      ),
    [appearance],
  );

  const renderIcon = React.useMemo(
    () =>
      Icon ? (
        <div className={iconClass}>
          <Icon />
        </div>
      ) : (
        <div className="nimbus--timeline-no-icon" />
      ),
    [Icon, iconClass],
  );

  return (
    <>
      {renderIcon}
      <Box paddingTop="2" width="100%">
        <div className="nimbus--timeline-item__details">
          <div className="nimbus--timeline-item__info timelime-info">
            <Text size="caption">{title}</Text>
            <p
              title={author}
              className="authorText nimbus--text nimbus--text-size--caption nimbus--text-color--light nimbus--text-align--left"
            >
              {author}
            </p>
          </div>
          <div className="nimbus--timeline-item__timestamp timelime-info">
            <Text textAlign="right" size="caption">
              {date}
            </Text>
            <Text textAlign="right" appearance="light" size="caption">
              {time}
            </Text>
          </div>
        </div>
      </Box>
    </>
  );
}
