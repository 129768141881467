import { Box, Link, Text } from '@nimbus-ds/components';
import { CopyIcon } from '@nimbus-ds/icons';

interface VariableItemProps {
  title: string;
  code: string;
  description: string;
  onClickVariable: (variable: string) => void;
}

export function VariableItem({
  title,
  code,
  description,
  onClickVariable,
}: VariableItemProps) {
  return (
    <>
      {!!title && (
        <Text fontWeight="medium" lineHeight="highlight">
          {title}
        </Text>
      )}
      {!!code && (
        <Box>
          <Text fontSize="caption">{description}</Text>
          <Link
            fontSize="caption"
            appearance="primary"
            textDecoration="none"
            onClick={() => onClickVariable(code)}
          >
            <Text
              color="currentColor"
              fontSize="caption"
              wordBreak="break-all"
              lineClamp={1}
            >
              {code}
            </Text>
            <CopyIcon />
          </Link>
        </Box>
      )}
    </>
  );
}
