const V1_BASE_URL = 'v1/payments';

export const PAYMENT_PROVIDERS_URL = `${V1_BASE_URL}/providers`;

export const PAYMENT_PROVIDERS_ROUTES = {
  available: `${PAYMENT_PROVIDERS_URL}/available`,
  settings: `${PAYMENT_PROVIDERS_URL}/{{providerId}}/settings`,
  checkoutOptions: `${PAYMENT_PROVIDERS_URL}/checkout-options`,
  settingOptions: `${PAYMENT_PROVIDERS_URL}/{{providerId}}/setting-options`,
  enable: `${PAYMENT_PROVIDERS_URL}/{{providerId}}/enable`,
  disable: `${PAYMENT_PROVIDERS_URL}/{{providerId}}/disable`,
  installPaymentProvider: `${PAYMENT_PROVIDERS_URL}/{{providerId}}/install`,
  mercadoPagoAuthorization: `${PAYMENT_PROVIDERS_URL}/mercado-pago-authorization`,
  mercadoPagoInstallments: `${PAYMENT_PROVIDERS_URL}/mercado-pago/installments`,
};
