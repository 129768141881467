import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';
import LocationText from 'domains/Orders/components/LocationText';

export interface DeliveryInfoCardInterface {
  receiverName: string;
  receiverPhone: string;
  zipcode: string;
  street: string;
  number: string;
  floor: string | null;
  city: string;
  province: string;
  country: string;
  locality: string;
}

interface DeliveryInfoCardProps {
  deliveryData: DeliveryInfoCardInterface;
}

function DeliveryInfoCard({
  deliveryData,
}: DeliveryInfoCardProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  function AddressText() {
    let address = '';
    address += deliveryData.street ? `${deliveryData.street} ` : '';
    address += deliveryData.number ? `${deliveryData.number} ` : '';
    address += deliveryData.floor ? `${deliveryData.floor} ` : '';
    address += deliveryData.zipcode ? `(${deliveryData.zipcode})` : '';

    return address ? <Text block>{address}</Text> : null;
  }

  return (
    <Card title={t('draftOrders.deliveryInfoCard.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{deliveryData.receiverName}</Text>
          <Text block>{deliveryData.receiverPhone}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text size="caption" appearance="light" block>
            {`${t('draftOrders.deliveryInfoCard.address')}`}
          </Text>
          <AddressText />
          <LocationText
            locality={deliveryData.locality}
            city={deliveryData.city}
            province={deliveryData.province}
            country={deliveryData.country}
          />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCard;
