import { Share } from '@capacitor/share';

type ShareToAnyType = (name: string, url: string) => Promise<void>;

const shareToAny: ShareToAnyType = async (name, url) => {
  await Share.share({
    title: '',
    text: name,
    url,
    dialogTitle: '',
  });
};

export default shareToAny;
