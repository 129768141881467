import { Responsive } from 'commons/components';
import {
  PromotionsListDesktopPage,
  PromotionsListMobilePage,
} from './components';

export function PromotionsListPage() {
  return (
    <Responsive
      mobile={<PromotionsListMobilePage />}
      desktop={<PromotionsListDesktopPage />}
    />
  );
}
