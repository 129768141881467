import {
  PreferencesCheckoutRequestDto,
  PreferencesCheckoutResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import {
  BASE_SETTINGS_CHECKOUT_URL,
  SettingsCheckoutEndpoints,
  SettingsCheckoutTags,
} from './config';

export const settingsCheckoutApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [SettingsCheckoutTags.getPreferencesCheckout],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [SettingsCheckoutEndpoints.getPreferencesCheckout]: builder.query<
        PreferencesCheckoutResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_SETTINGS_CHECKOUT_URL,
        }),
        providesTags: [SettingsCheckoutTags.getPreferencesCheckout],
      }),
      [SettingsCheckoutEndpoints.updatePreferencesCheckout]: builder.mutation<
        PreferencesCheckoutResponseDto,
        PreferencesCheckoutRequestDto
      >({
        query: (payload) => ({
          method: 'PUT',
          url: BASE_SETTINGS_CHECKOUT_URL,
          data: payload,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [SettingsCheckoutTags.getPreferencesCheckout],
      }),
    }),
  });

export const {
  useGetPreferencesCheckoutQuery,
  useUpdatePreferencesCheckoutMutation,
} = settingsCheckoutApi;
