import { Link, Icon, Text } from '@nimbus-ds/components';
import { InfiniteIcon } from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import { VariantListItemInterface } from 'domains/Catalog/Products/pages/components/Variants/components/CardVariants';
import useVariantStock from 'domains/Catalog/Products/pages/hooks/useVariantStock';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface LinkStockProps {
  variant: VariantListItemInterface;
  onEditStock: () => void;
  canEditStock: boolean;
}

function LinkStock({ variant, onEditStock, canEditStock }: LinkStockProps) {
  const t = useTranslationCatalog();
  const stock = useVariantStock(variant.id);
  const { isMobile } = useResponsive();

  const variantStock = stock !== undefined ? stock : variant.stock;

  const stockNumber = (
    <>
      {variantStock === null ? (
        <Icon color="currentColor" source={<InfiniteIcon />} />
      ) : undefined}
      {variantStock === null && t('products.detail.variantStockInfinite')}
      {variantStock !== null &&
        (isMobile
          ? t('products.detail.variantStock', {
              count: Number(variantStock) || 0,
            })
          : t('products.detail.available', {
              count: Number(variantStock),
            }))}{' '}
    </>
  );

  return canEditStock ? (
    <Link appearance="primary" textDecoration="none" onClick={onEditStock}>
      {stockNumber}
    </Link>
  ) : (
    <Text>{stockNumber}</Text>
  );
}

export default LinkStock;
