import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { useListDesktop } from 'App/hooks';
import { useListFilters } from 'commons/hooks';
import { PromotionsEndpoints } from '../promotionsApi';

const DEFAULT_FILTERS = { page: 1 };

export function usePromotionsDesktop() {
  const { filters, changeFilters } = useListFilters(
    'promotions',
    DEFAULT_FILTERS,
  );

  const changePage = (page: number) => {
    changeFilters({ ...filters, page });
  };

  const result = useListDesktop<PromotionsItemListResponseDto>({
    filters: { ...filters, perPage: '20' },
    endpoint: PromotionsEndpoints.promotionsList,
  });

  return { ...result, changePage, filters };
}
