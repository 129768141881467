import { useParams } from 'react-router';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { HELP_LINKS_USERS_PERMISSIONS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { EditUserForm, NewUserForm } from './components';

function UserFormPage() {
  const t = useTranslationConfigurations('userFormPage');
  const language = useTranslationLanguage();
  const { id } = useParams<{ id: string }>();
  const { goBack } = useNavegate();

  const isNewUser = id === 'new';

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent title={t(isNewUser ? 'title' : 'titleEdit')} />
      }
    >
      {isNewUser ? <NewUserForm /> : <EditUserForm userId={id} />}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_USERS_PERMISSIONS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default UserFormPage;
