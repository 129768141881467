import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { StatsIcon } from '@tiendanube/icons';
import { useHandleMenuClick } from 'App/hooks';
import { MenuItem } from 'App/settings/menu/components';
import { useMenu } from 'App/settings/menu/hooks';
import { trackingMenuPrimaryStatsClick } from 'App/tracking';
import { STATS_ROUTES } from 'domains/Statistics/statisticsRoutes';

function StatsMenu() {
  const { t } = useTranslation('common');
  const { menu } = useMenu();

  const { pathname, hash } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  /**
   * Menu Options to Prax Analytics
   * Prax is a third party app
   *
   * TODO
   * - Move Stats menu to BFF
   */
  const moreOptionMenu = menu
    ? Object.values(menu?.Main.Top.options?.Statistics.options ?? {})
    : [];

  const withMenuOption = moreOptionMenu.some((item) => {
    hash;
    const [pathnameItem] = item.goTo?.split('#') ?? [];
    return pathnameItem === pathname;
  });

  const isActiveMenu =
    pathname.startsWith(STATS_ROUTES.stats) || withMenuOption;

  const SUB_ITEMS = [
    {
      children: t('menu.stats.generals'),
      onClick: handleClickItem,
      active: pathname === STATS_ROUTES.stats,
      href: STATS_ROUTES.stats,
    },
    {
      children: t('menu.stats.payments'),
      onClick: handleClickItem,
      active: pathname === STATS_ROUTES.payments,
      href: STATS_ROUTES.payments,
    },
    {
      children: t('menu.stats.shipping'),
      onClick: handleClickItem,
      active: pathname === STATS_ROUTES.shipping,
      href: STATS_ROUTES.shipping,
    },
    {
      children: t('menu.stats.products'),
      onClick: handleClickItem,
      active: pathname === STATS_ROUTES.products,
      href: STATS_ROUTES.products,
    },
    {
      children: t('menu.stats.acquisition'),
      onClick: handleClickItem,
      active: pathname === STATS_ROUTES.acquisition,
      href: STATS_ROUTES.acquisition,
    },
  ];

  const handleMenuStatsClick = () => {
    trackingMenuPrimaryStatsClick();
    handleClickItem();
  };

  return (
    <MenuButton.Accordion
      contentid="content-stats"
      menuButton={{
        id: 'control-stats',
        label: t('menu.stats.title'),
        startIcon: StatsIcon,
        'aria-controls': 'content-stats',
        onClick: handleMenuStatsClick,
      }}
      open={isActiveMenu}
      active={isActiveMenu}
      as={Link}
      to={STATS_ROUTES.stats}
    >
      {SUB_ITEMS.map((item, index) => (
        <MenuButton
          key={index}
          as={Link}
          label={item.children}
          onClick={item.onClick}
          to={item.href}
          active={item.active}
        />
      ))}
      {moreOptionMenu.map((option) => (
        <MenuItem option={option} key={option.key} />
      ))}
    </MenuButton.Accordion>
  );
}

export default StatsMenu;
