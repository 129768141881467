import { InterfaceNameValue } from '@tiendanube/components';
import { FEATURE_MAX_CUSTOM_PAYMENTS } from 'App/features';
import { InterfaceNameBooleanValue } from 'commons/types';
import { PAYMENTS_ADD_CUSTOM_METHOD_TRANSFERENCIA_PIX_EFECTIVO } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import TypePayment from '../TypePayment';
import { TypePaymentValuesInterface } from '../TypePayment/TypePayment';

interface PixTransFerCashValuesInterface {
  cash: TypePaymentValuesInterface;
  wireTransfer: TypePaymentValuesInterface;
  pix?: TypePaymentValuesInterface;
}

interface PixTransFerCashProps {
  disabled: boolean;
  values: PixTransFerCashValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function PixTransFerCash({
  disabled,
  values,
  errors,
  onChange,
}: PixTransFerCashProps): JSX.Element {
  const t = useTranslationPayments();

  const initUpsellFlow = useUpsellFlow({
    title: t('customPayment.upsell.title'),
    trackingSource: PAYMENTS_ADD_CUSTOM_METHOD_TRANSFERENCIA_PIX_EFECTIVO,
    featureKey: FEATURE_MAX_CUSTOM_PAYMENTS,
    minValue: 2,
  });

  const handleChange = (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => {
    if (disabled) initUpsellFlow();
    else onChange(group, data);
  };

  return (
    <>
      {values.pix && (
        <TypePayment
          title={t('customPayment.pix')}
          disabled={false}
          group="pix"
          values={values.pix}
          errors={errors}
          onChange={handleChange}
        />
      )}
      <TypePayment
        title={t('customPayment.wireTransfer')}
        disabled={false}
        group="wireTransfer"
        values={values.wireTransfer}
        errors={errors}
        onChange={handleChange}
      />
      <TypePayment
        title={t('customPayment.cash')}
        disabled={false}
        group="cash"
        values={values.cash}
        errors={errors}
        onChange={handleChange}
      />
    </>
  );
}
export default PixTransFerCash;
