import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AbandonedCartDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { fetchAbandonedCartById } from '../../abandonedCartsSlice';
import {
  getAbandonedCartDetailsStatus,
  getAbandonedCartDetails,
} from '../../abandonedCartsSlice/abandonedCartsSelectors';

interface UseAbandonedCartDetailsResult {
  abandonedCartDetails: AbandonedCartDetailsResponseDto | null;
  fetchAbandonedCartDetails: () => Promise<void>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useAbandonedCartDetails(id: string): UseAbandonedCartDetailsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getAbandonedCartDetailsStatus,
  );
  const abandonedCartDetails = useSelector(getAbandonedCartDetails);

  const fetchAbandonedCartDetails = useCallback(async () => {
    await dispatch(fetchAbandonedCartById(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchAbandonedCartDetails();
  }, [fetchAbandonedCartDetails]);

  return {
    abandonedCartDetails,
    fetchAbandonedCartDetails,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useAbandonedCartDetails;
