import { Thumbnail, Text } from '@nimbus-ds/components';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import Skeleton from './Skeleton';
import { LinkGoToProduct } from '../../ProductListPage/components/ProductResultsDesktop/components/ResultRowDesktop/components';

interface ProductVariantProps {
  product: ProductDetailsResponseDto;
  variant: string | undefined;
}

function ProductVariant({
  product,
  variant,
}: ProductVariantProps): JSX.Element {
  const firstImage = product.images?.[0];
  const language = useGetLanguage();

  return (
    <Stack spacing="tight" align="flex-start">
      {firstImage ? (
        <Thumbnail
          aspectRatio="1/1"
          width="72px"
          src={firstImage.src}
          alt={firstImage.alt?.[language] || ''}
        />
      ) : (
        <Thumbnail width="72px" alt="camera" />
      )}
      <Stack column align="flex-start" spacing="tight">
        <LinkGoToProduct
          title={product.name[language] || ''}
          id={product.id}
          bold
        />
        {!!variant && (
          <Stack spacing="tight" wrap>
            <Text>{variant}</Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

ProductVariant.Skeleton = Skeleton;

export default ProductVariant;
