import { Button } from '@nimbus-ds/components';
import { MenuButtonProps } from '@nimbus-ds/menubutton';

interface PublishDraftButtonProps {
  publishAction: MenuButtonProps;
  highlightCTA: boolean;
}

export function PublishDraftButton({
  publishAction,
  highlightCTA,
}: Readonly<PublishDraftButtonProps>) {
  return (
    <Button
      onClick={publishAction.onClick}
      appearance={highlightCTA ? 'primary' : 'neutral'}
    >
      {publishAction.label}
    </Button>
  );
}
