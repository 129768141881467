import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { NuvemAppsInstalled } from 'domains/PartnersApps/partnersAppService';
import {
  getNuvemAppsSelector,
  getNuvemApps as getNuvemAppsToDispatch,
} from 'domains/PartnersApps/partnersAppsSlice';

interface UseNuvemAppsResult {
  nuvemApps: NuvemAppsInstalled;
  fetchNuvemApps: () => void;
  isEmpty: boolean;
}

function useNuvemApps(): UseNuvemAppsResult {
  const dispatch = useAppDispatch();
  const nuvemApps = useSelector(getNuvemAppsSelector);

  const fetchNuvemApps = useCallback(() => {
    dispatch(getNuvemAppsToDispatch());
  }, [dispatch]);

  const isEmpty = Object.keys(nuvemApps).length === 0;

  return { isEmpty, nuvemApps, fetchNuvemApps };
}

export default useNuvemApps;
