import { useEffect } from 'react';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import { FiltersStatisticsInterface } from 'domains/Statistics/types';
import { FiltersStatisticsModal, FilterStatisticsCard } from './components';

const noop = () => null;

interface FiltersStatisticsProps {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  defaultValues?: FiltersStatisticsInterface;
}

function FiltersStatistics({
  isOpen = false,
  onClose = noop,
  onOpen = noop,
  defaultValues,
}: FiltersStatisticsProps) {
  const { handleOnChangeFilters, statisticsFilters } =
    useStatisticsFilterContext();

  useEffect(() => {
    if (defaultValues) {
      handleOnChangeFilters(defaultValues);
    }
  }, [defaultValues, handleOnChangeFilters]);

  const handleOnChange = (filters: FiltersStatisticsInterface) => {
    handleOnChangeFilters(filters);
    onClose?.();
  };

  const handleOnRemove = () => {
    handleOnChangeFilters({
      ...statisticsFilters,
      comparePeriod: undefined,
      comparePeriodFrom: undefined,
      comparePeriodTo: undefined,
    });
  };

  return (
    <>
      <FilterStatisticsCard
        values={statisticsFilters}
        onClick={onOpen}
        onRemove={handleOnRemove}
      />
      <FiltersStatisticsModal
        show={isOpen}
        onClose={onClose}
        onChange={handleOnChange}
        values={statisticsFilters}
      />
    </>
  );
}

export default FiltersStatistics;
