import { Text, IconButton, Tag } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Repeater, Stack } from 'commons/components';

function ItemSkeleton() {
  return (
    <DataList.Row backgroundColor="transparent">
      <Stack column align="stretch" gap="3">
        <Stack justify="space-between" align="center">
          <Text.Skeleton width="128px" />
          <IconButton.Skeleton height="2rem" width="2rem" />
        </Stack>
        <Stack column spacing="tight" align="stretch">
          <Text.Skeleton width="128px" />
          <Tag.Skeleton width="72px" height="16px" />
        </Stack>
      </Stack>
    </DataList.Row>
  );
}

const rowSkeleton = () => <ItemSkeleton />;

export function FreeShippingMobileSkeleton() {
  return <Repeater item={rowSkeleton} />;
}
