import { Label, Stack } from '@tiendanube/components';
import { EyeOffIcon, ExclamationTriangleIcon } from '@tiendanube/icons';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface ProductHiddenAndWithoutStockProps {
  isVisible: boolean;
  hasStock: boolean;
  productId: string;
}

function ProductHiddenAndWithoutStock({
  isVisible,
  hasStock,
  productId,
}: ProductHiddenAndWithoutStockProps) {
  const t = useTranslationCatalog();

  if (hasStock && isVisible) return null;

  return (
    <Stack wrap spacing="tight">
      {!isVisible && (
        <Stack.Item>
          <Label
            id={productId}
            label={`${t('products.productHiddenAndWithoutStock.hidden')}`}
            appearance="warning"
            icon={EyeOffIcon}
          />
        </Stack.Item>
      )}
      {!hasStock && (
        <Stack.Item>
          <Label
            id={productId}
            label={`${t('products.productHiddenAndWithoutStock.withoutStock')}`}
            appearance="warning"
            icon={ExclamationTriangleIcon}
          />
        </Stack.Item>
      )}
    </Stack>
  );
}

export default ProductHiddenAndWithoutStock;
