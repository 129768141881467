import { Table, Text } from '@nimbus-ds/components';

const rows = Array.from(Array(10).keys());

function AbandonedCartsListDesktopSkeleton() {
  return (
    <>
      {rows.map((i) => (
        <Table.Row
          id="AbandonedCartsListDesktopSkeleton"
          key={`AbandonedCartsListDesktopSkeleton-${i}`}
        >
          <Table.Cell>
            <Text.Skeleton />
          </Table.Cell>
          <Table.Cell>
            <Text.Skeleton width="small" />
          </Table.Cell>
          <Table.Cell>
            <Text.Skeleton />
          </Table.Cell>
          <Table.Cell>
            <Text.Skeleton width="large" />
          </Table.Cell>
          <Table.Cell>
            <Text.Skeleton />
          </Table.Cell>
          <Table.Cell>
            <Text.Skeleton />
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
}

export default AbandonedCartsListDesktopSkeleton;
