import { Label, Link } from '@tiendanube/components';
import { EyeOffIcon } from '@tiendanube/icons';
import { ImageItemStratus, Stack } from 'commons/components';
import { VariantListItemInterface } from 'domains/Catalog/Products/pages/components/Variants/components/CardVariants/CardVariants';
import { Variant } from 'domains/Catalog/Products/pages/components/Variants/types';
import { useShowNoWeightAndDimension } from 'domains/Catalog/Products/pages/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import VariantMissingDataTag from '../../../VariantMissingDataTag';

export interface HiddenVariantInfoProps {
  isDigital: boolean;
  variant: VariantListItemInterface;
  index: number;
  onChange: (i: number, v: Partial<Variant>) => void;
}

function HiddenVariantInfo({
  isDigital,
  variant: { id: variantId, weight, depth, width, height, name, imageUrl },
  index,
  onChange,
}: HiddenVariantInfoProps) {
  const t = useTranslationCatalog();
  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight,
    depth,
    width,
    height,
  });
  const handleShowVariant = () => onChange(index, { show: true });

  return (
    <ImageItemStratus
      title={name}
      id={variantId}
      imageUrl={imageUrl}
      appearance="default"
    >
      <Stack column align="flex-start" spacing="tight">
        <Label
          id={variantId}
          label={`${t('products.productHiddenAndWithoutStock.hidden')}`}
          appearance="warning"
          icon={EyeOffIcon}
        />
        {showNoWeightAndDimension && !isDigital && <VariantMissingDataTag />}
        <Link appearance="primary" onClick={handleShowVariant}>
          {t('products.detail.showVariant')}
        </Link>
      </Stack>
    </ImageItemStratus>
  );
}

export default HiddenVariantInfo;
