import { Fragment } from 'react';
import { Box, Card } from '@nimbus-ds/components';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import OnboardingTaskAccordion from './OnboardingAccordionTasks';
import OnboardingTaskCard from './OnboardingTaskCard';
import useNewOnboardingSteps from './useOnboardingTasks';
import './OnboardingTasks.scss';

function NewOnboardingTasks() {
  const { hasCompleted, isError, isLoading, onboardingTasks } =
    useNewOnboardingSteps();

  if (hasCompleted || isError) {
    return null;
  }
  // This component duplicates OnboardingTasks to implement New Onboarding feature [NA-5773]. Should be unified after testing results.
  return (
    <div className="stratus--onboarding-tasks-container">
      <Card padding="none">
        <Box display="flex" flexDirection="column">
          {isLoading ? (
            getMockArrayForSkeleton(4).map((element) => (
              <Fragment key={element}>
                <OnboardingTaskCard.Skeleton />
              </Fragment>
            ))
          ) : (
            <OnboardingTaskAccordion items={onboardingTasks?.tasks || []} />
          )}
        </Box>
      </Card>
    </div>
  );
}

export default NewOnboardingTasks;
