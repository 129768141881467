import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { WhatsappIcon } from '@tiendanube/icons';
import { useHandleMenuClick } from 'App/hooks';
import { configurationsRoutes } from 'domains/Configurations';
import { menuSecondaryWhatsAppClick } from './tracking';

const path = configurationsRoutes.whatsapp;

export function WhatsappMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  return (
    <MenuButton
      as={Link}
      to={path}
      startIcon={WhatsappIcon}
      onClick={() => {
        menuSecondaryWhatsAppClick();
        handleClickItem();
      }}
      active={pathname.startsWith(path)}
      label={t('settingsMenu.communication.whatsapp')}
    />
  );
}
