import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { RegenerateAiContentRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { regenerateAiContent } from '../../../domains/Dashboard/slices/aiRegenerateContentSlice';

function useRegenerateAiContent() {
  const dispatch = useAppDispatch();

  const regenerateContent = useCallback(
    async (requestDto: RegenerateAiContentRequestDto) => {
      const result = await dispatch(regenerateAiContent(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { regenerateContent };
}

export default useRegenerateAiContent;
