import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'commons/utils';
import { STATS_PAYMENTS_PAGE } from 'config/upsellFlowSources';
import { useGetCurrency } from 'domains/Auth/hooks';
import {
  useStatisticsFilterContext,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { STATS_PLAN_3, thousandSeparator } from 'domains/Statistics/utils';
import { STATS_PAYMENTS_SUMMARY_COLORS } from './constants';
import useGetStatisticsPayment from './useGetStatisticsPayment';
import CombinedBarChartAndSummaryCard from '../CombinedBarChartAndSummaryCard';

export type ItemsType =
  | 'revenueByPaymentMethod'
  | 'salesByPaymentMethod'
  | 'revenueByPaymentGateway'
  | 'salesByPaymentGateway'
  | 'revenueByInstallments'
  | 'salesByInstallments';

function StatisticsPaymentsCard() {
  const t = useTranslationStatistics();
  const {
    i18n: { language },
  } = useTranslation();
  const { code } = useGetCurrency();
  const { hasComparedFilter, openFilter } = useStatisticsFilterContext();

  const { data, isLoading, isError, isSuccess, fetchPaymentsSummary, isEmpty } =
    useGetStatisticsPayment();

  const formatSummaryTitle = (key) =>
    t(`statistics.statsPaymentsSummary.${key}.summaryTitle`);

  const formatValueSummary = (text: number, key: string) => {
    const curr = [
      'revenueByPaymentMethod',
      'revenueByPaymentGateway',
      'revenueByInstallments',
    ];
    if (curr.includes(key)) return formatCurrency(text, code, language);
    return thousandSeparator(text);
  };

  const formatLabelTooltip = (label: string) => {
    const [, key] = label.split('.');
    return t(`statistics.statsPaymentsSummary.formatKeys.${key}`);
  };

  return (
    <CombinedBarChartAndSummaryCard<ItemsType>
      selectedPrimary="revenueByPaymentMethod"
      title={t('statistics.statsPaymentsSummary.title')}
      data={data}
      statsPlanRequired={STATS_PLAN_3}
      upsellSource={STATS_PAYMENTS_PAGE}
      colors={STATS_PAYMENTS_SUMMARY_COLORS}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      fetchData={fetchPaymentsSummary}
      formatLabelTooltip={formatLabelTooltip}
      formatSummaryTitle={formatSummaryTitle}
      formatValueSummary={formatValueSummary}
      hasComparedFilter={hasComparedFilter}
      onOpenFilter={openFilter}
      isEmpty={isEmpty}
    />
  );
}

export default StatisticsPaymentsCard;
