import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetWireTransferDataQuery } from 'domains/Billing/Checkout/CheckoutApi';

function useGetWireTransferData() {
  const {
    data: wireTransferData,
    status,
    refetch,
  } = useGetWireTransferDataQuery();

  return {
    wireTransferData,
    status: toStatusType(status),
    ...convertStatusTypeToObject(status),
    refreshWireTransferData: refetch,
  };
}

export default useGetWireTransferData;
