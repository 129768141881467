import { BoxProps } from '@nimbus-ds/components';
import { PendingOrdersCardAppearanceType } from './PendingOrdersCard';

export const ICON_BORDER_COLOR_STYLE: Record<
  PendingOrdersCardAppearanceType,
  BoxProps['borderColor']
> = {
  'neutral-surface': 'neutral-interactive',
  'primary-surface': 'primary-surfaceHighlight',
  'warning-surface': 'warning-surfaceHighlight',
};
