import { Fragment } from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { generatePath } from 'react-router';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { useToastStatus } from 'commons/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  usePaymentMethods,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import { getIconForPaymentMethod } from 'domains/Billing/Checkout/pages/utils';
import {
  trackingBillingSelectPaymentMethodForPlanClick,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import ChoosePaymentMethodSkeleton from './Skeleton';
import { useContinuePayment } from '../../../ChoosePaymentRecurrencyPage/hooks';

export default function ChoosePaymentMethod() {
  const t = useTranslationBilling('checkout.choosePaymentMethodWithRecurrency');
  const { refreshPaymentMethods, paymentMethods, isLoading, isError } =
    usePaymentMethods();
  const { goTo } = useNavegate();
  const { payOrderId, recurrentPaymentCompatible } = usePayorderToPay();
  const { onPay, status: continueStatus } = useContinuePayment();

  useToastStatus({
    status: continueStatus,
    error: t('chooseMethod.continueError'),
    progress: t('chooseMethod.continueLoading'),
  });
  const logEvent = useCheckoutTracking();

  if (isLoading) return <ChoosePaymentMethodSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: refreshPaymentMethods,
        }}
      />
    );

  const handleSelectMethod = (selectedMethod: CheckoutPaymentMethod) => {
    const recurrencies = paymentMethods?.find(
      ({ method }) => method === selectedMethod,
    )?.recurrencyOptions;
    if (recurrencies?.length === 1) {
      trackingBillingSelectPaymentMethodForPlanClick(logEvent, selectedMethod);
      onPay(selectedMethod, recurrencies[0]);
    } else {
      goTo(
        `${generatePath(CHECKOUT_ROUTES.choosePaymentRecurrency, {
          payOrderId: payOrderId,
        })}?method=${selectedMethod}`,
      );
    }
  };

  return (
    <Card padding="none">
      <Box display="flex" flexDirection="column">
        <Box padding="4">
          <Title as="h4">{t('chooseMethod.title')}</Title>
        </Box>
        {paymentMethods?.map(({ method }) => (
          <Fragment key={method}>
            <hr />
            <Box
              onClick={() => handleSelectMethod(method)}
              padding="4"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="row" gap="3">
                {getIconForPaymentMethod(method)}
                <Text>
                  {t(
                    method === 'cc' && recurrentPaymentCompatible
                      ? 'ccWithRp'
                      : method,
                  )}
                </Text>
              </Box>
              <ChevronRightIcon />
            </Box>
          </Fragment>
        ))}
      </Box>
    </Card>
  );
}
