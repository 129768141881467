import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import DisableNuvemPagoModal from '../../../DisableNuvemPagoModal';
import DisablePagoNubeModal from '../../../DisablePagoNubeModal';

const nuvemPagoDisableComponents = {
  [PaymentProvidersCode.nuvemPago]: DisableNuvemPagoModal,
  [PaymentProvidersCode.pagoNube]: DisablePagoNubeModal,
};

function useSpecialDisbaledModal(paymentProviderId: string) {
  const SpecialDisabledModal = nuvemPagoDisableComponents[paymentProviderId];

  return {
    SpecialDisabledModal,
  };
}

export default useSpecialDisbaledModal;
