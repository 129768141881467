import { Card, Link, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DocumentType } from '../../constants';
import { useGoToPrintPage } from '../../hooks';

interface CardRegenerateOcaLabelsProps {
  externalIds: string[];
}

function CardRegenerateOcaLabels({
  externalIds,
}: Readonly<CardRegenerateOcaLabelsProps>) {
  const t = useTranslationFulfillmentOrders(
    externalIds.length > 1
      ? 'printManager.oca.regenerate.plural'
      : 'printManager.oca.regenerate.single',
  );
  const { goToPrintPage } = useGoToPrintPage();

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="flex-start">
          <Text>{t('description')}</Text>
          <Link
            as="a"
            onClick={() =>
              goToPrintPage({
                ids: externalIds,
                type: DocumentType.LABEL,
                hasOca: true,
                hasOcaAndNotGeneratedTag: true,
                forceOca: true,
              })
            }
          >
            {t('action')}
          </Link>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default CardRegenerateOcaLabels;
