import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  InventoryLevelByLocationResponseDto,
  LocationFullResponseDto,
  LocationPriorityRequestDto,
  LocationPriorityResponseDto,
  LocationResponseDto,
  PageParamsRequestDto,
  ResultPaginationResponseDto,
} from '@tiendanube/common';
import {
  InterfaceLocationsSchema,
  InventoryLevelsInterface,
  LocationEntityDetails,
} from './types';
import {
  getLocationsList as getLocationsListService,
  getLocationById as getLocationByIdService,
  createLocation as createLocationService,
  updateLocation as updateLocationService,
  updateLocationsPriorities as updateLocationsPrioritiesService,
  updateMainLocation as updateMainLocationService,
  deleteLocation as deleteLocationService,
  getInventoriesByLocation as getInventoriesByLocationService,
  activateMultiCD as activateMultiCDService,
  getMultiCDAvailability as getMultiCDAvailabilityService,
} from '../locationsService';
import { LOCATION_TAGS } from '../pages/constants';
import {
  EditLocationParamsInterface,
  LocationValuesInterface,
} from '../pages/types';

const entityDetailsDefault: LocationEntityDetails = {
  status: 'idle',
  data: {
    id: '',
    locationName: '',
    main: false,
    zipcode: '',
    country: '',
    province: '',
    city: '',
    locality: '',
    street: '',
    number: '',
    floor: '',
    tags: [LOCATION_TAGS.online, LOCATION_TAGS.offline],
  },
};

const inventoryLevelsDefault: InventoryLevelsInterface = {
  status: 'idle',
  data: [],
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalResults: 0,
    perPage: 20,
    nextPage: null,
  },
};

const initialState: InterfaceLocationsSchema = {
  ids: [] as string[],
  idMain: '',
  entities: [],
  status: 'idle',
  refreshStatus: 'idle',
  entityDetails: entityDetailsDefault,
  inventoryLevels: inventoryLevelsDefault,
};

export const getLocationsList = createAsyncThunk<LocationResponseDto[]>(
  'locations/getLocationsList',
  async () => {
    const locations = await getLocationsListService();
    return locations;
  },
);

export const getLocationById = createAsyncThunk<
  LocationFullResponseDto,
  string
>('locations/getLocationById', async (id) => {
  const locations = await getLocationByIdService(id);
  return locations;
});

export const createLocation = createAsyncThunk<void, LocationValuesInterface>(
  'locations/createLocation',
  async (location) => {
    await createLocationService(location);
  },
);

export const updateLocation = createAsyncThunk<
  void,
  EditLocationParamsInterface
>('locations/createLocation', async (location) => {
  await updateLocationService(location);
});

export const updateLocationsPriorities = createAsyncThunk<
  LocationPriorityResponseDto[],
  LocationPriorityRequestDto[]
>('locations/updateLocationsPriorities', async (locations) => {
  const newLocationsPriorities = await updateLocationsPrioritiesService(
    locations,
  );
  return newLocationsPriorities;
});

export const updateMainLocation = createAsyncThunk<void, string>(
  'locations/updateMainLocation',
  async (locationId) => {
    await updateMainLocationService(locationId);
  },
);

export const deleteLocation = createAsyncThunk<void, string>(
  'locations/deleteLocation',
  async (locationId) => {
    await deleteLocationService(locationId);
  },
);

export const getInventoriesByLocation = createAsyncThunk<
  ResultPaginationResponseDto<InventoryLevelByLocationResponseDto[]>,
  { locationId: string; params?: PageParamsRequestDto }
>('locations/getInventoriesByLocation', async ({ locationId, params }) => {
  const inventories = await getInventoriesByLocationService(locationId, params);
  return inventories;
});

export const getMultiCDAvailability = createAsyncThunk<boolean>(
  'locations/multiCDAvailability',
  async () => {
    const available = await getMultiCDAvailabilityService();
    return available;
  },
);

export const activateMultiCD = createAsyncThunk<void>(
  'locations/activateMultiCD',
  async () => {
    await activateMultiCDService();
  },
);

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocationsList.fulfilled, (state, action) => {
        const ids: string[] = [];
        state.entities = action.payload.map((location) => {
          ids.push(location.id);
          if (location.main) state.idMain = location.id;
          return location;
        });
        state.ids = ids;
        state.status = 'success';
        state.entityDetails.status = initialState.entityDetails.status;
      })
      .addCase(getLocationsList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLocationsList.rejected, (state) => {
        state.status = 'error';
      });

    builder
      .addCase(getLocationById.pending, (state) => {
        state.entityDetails.status = 'loading';
      })
      .addCase(getLocationById.rejected, (state) => {
        state.entityDetails.status = 'error';
      })
      .addCase(getLocationById.fulfilled, (state, action) => {
        state.entityDetails.status = 'success';
        state.entityDetails.data = action.payload;
      });
    builder
      .addCase(updateLocation.pending, (state) => {
        state.entityDetails.status = 'loading';
      })
      .addCase(updateLocation.rejected, (state) => {
        state.entityDetails.status = 'error';
      })
      .addCase(updateLocation.fulfilled, (state) => {
        state.entityDetails.status = 'success';
      });
    builder
      .addCase(updateLocationsPriorities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLocationsPriorities.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(updateLocationsPriorities.fulfilled, (state, action) => {
        state.status = 'success';
        action.payload.forEach(({ locationId, main, priority }) => {
          state.entities.map((location) => {
            if (location.id === locationId) {
              location.main = main;
              location.priority = priority;
            }
            return location;
          });
        });
      });
    builder
      .addCase(updateMainLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMainLocation.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(updateMainLocation.fulfilled, (state, action) => {
        state.idMain = action.meta.arg;
        const locationMainOldPriority = state.entities.find(
          (location) => location.id === action.meta.arg,
        )?.priority as number;
        state.entities.map((location) => {
          if (location.priority < locationMainOldPriority) {
            location.priority += 1;
            location.main = false;
          } else if (location.id === action.meta.arg) {
            location.priority = 0;
            location.main = true;
          }
          return location;
        });
        state.status = 'success';
      });
    builder
      .addCase(deleteLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteLocation.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        const deletedLocation = state.entities.find(
          (location) => location.id === action.meta.arg,
        ) as LocationResponseDto;

        state.entities.map((location) => {
          if (location.priority > deletedLocation.priority) {
            location.priority -= 1;
          }
          return location;
        });
        state.ids = state.ids.filter((id) => id !== action.meta.arg);
        state.entities = state.entities.filter(
          (location) => location.id !== action.meta.arg,
        );
        state.status = 'success';
      });
    builder
      .addCase(getInventoriesByLocation.pending, (state) => {
        state.inventoryLevels.status = 'loading';
      })
      .addCase(getInventoriesByLocation.rejected, (state) => {
        state.inventoryLevels.status = 'error';
      })
      .addCase(getInventoriesByLocation.fulfilled, (state, action) => {
        state.inventoryLevels.status = 'success';
        state.inventoryLevels.data = action.payload.results;
        state.inventoryLevels.pagination = action.payload.pagination;
      });
  },
});

export const { reducer } = locationSlice;
