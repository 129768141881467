import { combineReducers } from 'redux';
import {
  CustomerMetafieldsInterface,
  reducer as customerMetafieldsReducer,
} from './Customers/CustomerMetafields/customerMetafieldsSlice';
import {
  reducer as customersReducer,
  CustomersInterface,
} from './Customers/customersSlice';
import {
  MessagesInterface,
  reducer as messagesReducer,
} from './Messages/messagesSlice';

export interface CatalogStateInterface {
  customers: CustomersInterface;
  messages: MessagesInterface;
  customerMetafields: CustomerMetafieldsInterface;
}

const catalogReducers = combineReducers({
  customers: customersReducer,
  messages: messagesReducer,
  customerMetafields: customerMetafieldsReducer,
});

export default catalogReducers;
