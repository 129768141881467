import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Stack, Text, Link } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { CopyLink } from 'commons/components';

interface CardTrackingPageProps {
  trackingPageLink: string;
}

function CardTrackingPage({ trackingPageLink }: CardTrackingPageProps) {
  const { t } = useTranslation(Domain.ORDERS);
  return (
    <Card title={t('trackingPage.title')}>
      <Stack column align="stretch">
        <Stack.Item>
          <Text>{t('trackingPage.description')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Stack.Item>
              <CopyLink
                value={trackingPageLink}
                message={t('trackingPage.toastMessage')}
                text={t('trackingPage.copyLink')}
                iconPosition="start"
              />
            </Stack.Item>
            <Link
              appearance="primary"
              href={trackingPageLink}
              icon={ExternalLinkIcon}
            >
              {t('trackingPage.toAaccess')}
            </Link>
          </Stack>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CardTrackingPage;
