import { InterfaceNameValue } from '@tiendanube/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import TypePayment, {
  TypePaymentValuesInterface,
} from '../TypePayment/TypePayment';

interface OtherProps {
  disabled: boolean;
  values: TypePaymentValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function Other({
  disabled,
  values,
  errors,
  onChange,
}: OtherProps): JSX.Element {
  const t = useTranslationPayments();

  return (
    <TypePayment
      title={t('customPayment.other')}
      disabled={disabled}
      group="other"
      values={values}
      errors={errors}
      onChange={onChange}
    />
  );
}

export default Other;
