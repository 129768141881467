import { SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { ErrorScreen, VirtualDataList } from 'commons/components';
import {
  UserSessionsListMobileRow,
  UserSessionsListMobileSkeleton,
} from './components';
import { useTranslationAccount } from '../../../../hooks';
import {
  useGetTranslationKeyByUser,
  useGetUserSessionsListMobile,
} from '../../hooks';

export function UserSessionsListMobile() {
  const t = useTranslationAccount('sessionManagementPage');
  const {
    ids,
    data: userSessions,
    showSkeleton,
    showSpinner,
    isError,
    refetch,
    handleInfiniteScroll,
    handlePullToRefresh,
  } = useGetUserSessionsListMobile();
  const translationKeyByUser = useGetTranslationKeyByUser();

  if (showSkeleton) {
    return <UserSessionsListMobileSkeleton />;
  }

  if (isError) {
    return (
      <ErrorScreen
        onRetry={refetch}
        description={t('errors.sessionsLoading')}
      />
    );
  }

  if (userSessions.length === 0) {
    return (
      <EmptyMessage
        title={t('emptyResult.title')}
        text={t(`emptyResult.text.${translationKeyByUser}`)}
        icon={<SearchIcon size={30} />}
      />
    );
  }

  return (
    <VirtualDataList
      onRefresh={handlePullToRefresh}
      isPullable
      data={ids}
      endReached={handleInfiniteScroll}
      itemContent={(index) => (
        <UserSessionsListMobileRow
          key={`user-sessions-list-item-${userSessions[index].id}`}
          userSession={userSessions[index]}
        />
      )}
      showFooter={showSpinner}
    />
  );
}
