import { Box, Icon, Tag, Text } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import { LocationResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface LocationItemProps {
  location: LocationResponseDto;
  isDragging?: boolean;
}

function LocationItem({
  location,
  isDragging = false,
}: Readonly<LocationItemProps>): JSX.Element | null {
  const t = useTranslationShipping();
  return (
    <Box
      marginLeft={isDragging ? '2' : 'none'}
      marginRight={isDragging ? '2' : 'none'}
    >
      <Stack spacing="base" align="center">
        <Text>{location.priority + 1}</Text>
        <Stack column spacing="tight" align="flex-start">
          <Stack spacing="tight" align="flex-start">
            <Text fontWeight="bold" color="primary-interactive">
              {location.name}
            </Text>
            {location.main && (
              <Tag appearance="primary">
                <Icon source={<StarIcon />} />
                {t('locations.card.label')}
              </Tag>
            )}
          </Stack>
          <Text>{location.address}</Text>
        </Stack>
      </Stack>
    </Box>
  );
}

export default LocationItem;
