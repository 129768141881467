import {
  OcaLabelCostPreviewResponseDto,
  OcaLabelSettingBoxOptionsDimensionsDto,
  OcaCreateLabelRequestDto,
  OcaLabelsSettingOptionsResponseDto,
  OrderDetailsForOcaLabelResponseDto,
} from '@tiendanube/common';

export const defineInitialValues = (
  orders: OrderDetailsForOcaLabelResponseDto[],
  documentSettings: OcaLabelsSettingOptionsResponseDto,
  isOverwriting: boolean,
): OcaCreateLabelRequestDto[] =>
  orders.map(({ externalId }) => ({
    boxOption: {
      dimensions: {
        depth: 0,
        height: 0,
        weight: 0,
        width: 0,
      },
      description: '',
    },
    dispatchMethod: documentSettings.dispatchMethods[0].code,
    externalId: externalId,
    agency: documentSettings.agencies[0].code,
    schedulingForWithdrawals: documentSettings.schedulingForWithdrawals[0].code,
    overwrite: isOverwriting,
  }));

export const defineInitialMassiveValues = (
  documentSettings: OcaLabelsSettingOptionsResponseDto,
  isOverwriting: boolean,
): Omit<OcaCreateLabelRequestDto, 'externalId'> => ({
  boxOption: {
    dimensions: {
      depth: 0,
      height: 0,
      weight: 0,
      width: 0,
    },
    description: '',
  },
  dispatchMethod: documentSettings.dispatchMethods[0].code,
  agency: documentSettings.agencies[0].code,
  schedulingForWithdrawals: documentSettings.schedulingForWithdrawals[0].code,
  overwrite: isOverwriting,
});

export const validateDimensions = (
  dimensions: OcaLabelSettingBoxOptionsDimensionsDto,
): boolean =>
  dimensions.depth > 0 && dimensions.height > 0 && dimensions.width > 0;

export const validateLabels = (
  labels: Omit<OcaCreateLabelRequestDto, 'externalId'>[],
): boolean =>
  labels.every((label) => validateDimensions(label.boxOption.dimensions));

export const mergeCostsPreviewList = (
  newCostsPreviews: OcaLabelCostPreviewResponseDto[],
  currentCostsPreviews: OcaLabelCostPreviewResponseDto[],
): OcaLabelCostPreviewResponseDto[] => {
  const mapCostPreviews = new Map(
    currentCostsPreviews.map((costPreview) => [
      costPreview.externalId,
      costPreview,
    ]),
  );
  newCostsPreviews.forEach((costPreview) => {
    if (mapCostPreviews.has(costPreview.externalId)) {
      mapCostPreviews.set(costPreview.externalId, {
        ...mapCostPreviews.get(costPreview.externalId),
        ...costPreview,
      });
    } else {
      mapCostPreviews.set(costPreview.externalId, costPreview);
    }
  });

  return Array.from(mapCostPreviews.values());
};

export const mergeLabelsList = (
  newLabels: OcaCreateLabelRequestDto[],
  currentLabels: OcaCreateLabelRequestDto[],
): OcaCreateLabelRequestDto[] => {
  const mapLabels = new Map(
    currentLabels.map((costPreview) => [costPreview.externalId, costPreview]),
  );
  newLabels.forEach((costPreview) => {
    if (mapLabels.has(costPreview.externalId)) {
      mapLabels.set(costPreview.externalId, {
        ...mapLabels.get(costPreview.externalId),
        ...costPreview,
      });
    } else {
      mapLabels.set(costPreview.externalId, costPreview);
    }
  });

  return Array.from(mapLabels.values());
};

export const generateMassiveLabelsByOneLabel = (
  selectedLabels: string[],
  newLabel: Omit<OcaCreateLabelRequestDto, 'externalId'>,
  isOverwriting,
): OcaCreateLabelRequestDto[] =>
  selectedLabels.map((externalId) => ({
    boxOption: newLabel.boxOption,
    dispatchMethod: newLabel.dispatchMethod,
    agency: newLabel.agency,
    schedulingForWithdrawals: newLabel.schedulingForWithdrawals,
    externalId: externalId,
    overwrite: isOverwriting,
  }));

export const formatCost = (value: number): string =>
  Intl.NumberFormat('es-AR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
