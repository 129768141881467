import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getRankProvincesByShippingCost = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.rankProvincesByShippingCost,
);

export const getRankProvincesByShippingCostStatus = createSimpleSelector(
  [getRankProvincesByShippingCost],
  (rankProvincesByShippingCost) =>
    convertStatusTypeToObject(rankProvincesByShippingCost.status),
);

export const getRankProvincesByShippingCostData = createSimpleSelector(
  [getRankProvincesByShippingCost],
  (rankProvincesByShippingCost) => rankProvincesByShippingCost.data,
);
