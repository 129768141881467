import { useRef, useEffect } from 'react';
import { App, AppState } from '@capacitor/app';

function useAppStateChange(callback: () => void): void {
  const refCallback = useRef(callback);

  useEffect(() => {
    refCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    App.addListener('appStateChange', (state: AppState) => {
      if (state.isActive) {
        refCallback.current();
      }
    });
  }, []);
}

export default useAppStateChange;
