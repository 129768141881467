import { Card, Box, Thumbnail, Text, Title, Link } from '@nimbus-ds/components';
import { Checkbox, DataList } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function InventoryByVariantSkeleton() {
  const t = useTranslationCatalog();

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box>
          <Thumbnail.Skeleton width="72px" aspectRatio="1/1" />
        </Box>
        <Box
          marginLeft="2"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Text.Skeleton width="100px" />
        </Box>
      </Box>

      <Card>
        <Card.Header>
          <Title as="h3">
            {t('editInventory.stockDistributionCard.title')}
          </Title>
        </Card.Header>

        <Text>{t('editInventory.stockDistributionCard.description')}</Text>

        <Card.Body padding="none">
          <Box paddingY="4">
            <Text fontWeight="bold">
              {t('editInventory.stockDistributionCard.addNewCenter')}
            </Text>
          </Box>
          <DataList>
            {[...Array(3)].map((_, key) => (
              <DataList.Row key={key} id={String(key)}>
                <DataList.Cell width="fill">
                  <Checkbox.Skeleton />
                </DataList.Cell>
              </DataList.Row>
            ))}
          </DataList>
        </Card.Body>

        <Card.Footer>
          <Link.Skeleton width="100px" />
        </Card.Footer>
      </Card>
    </>
  );
}

export default InventoryByVariantSkeleton;
