import { Link } from '@tiendanube/components';
import { WhatsappIcon } from '@tiendanube/icons';
import { cleanBlanks } from 'commons/utils';

interface SendWhatsAppLinkProps {
  textWhatsapp: string;
  phoneNumber: string;
  textChild: string;
  url?: string;
  signature?: string;
}

function SendWhatsAppLink({
  textWhatsapp,
  phoneNumber,
  textChild,
  signature,
  url,
}: SendWhatsAppLinkProps): JSX.Element {
  const phoneNumberClean = cleanBlanks(phoneNumber).replace('+', '');

  const textWhatsappEncoded = encodeURIComponent(textWhatsapp);
  const urlEncode = url || '';
  const signatureEncode = signature
    ? encodeURIComponent(`\n\n${signature}`)
    : '';
  const waHref = `https://wa.me/${phoneNumberClean}?text=${textWhatsappEncoded}${urlEncode}${signatureEncode}`;
  return (
    <Link appearance="primary" icon={WhatsappIcon} href={waHref}>
      {textChild}
    </Link>
  );
}

export default SendWhatsAppLink;
