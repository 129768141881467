import { BaseCard, Stack, Text, Button, Input } from '@tiendanube/components';

export function ArgentineFiscalDataFormSkeleton() {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column align="flex-start" spacing="tight">
          <Text.Skeleton />
          <Stack>
            <Button.Skeleton />
            <Button.Skeleton />
          </Stack>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Input.Skeleton />
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button.Skeleton />
      </BaseCard.Footer>
    </BaseCard>
  );
}
