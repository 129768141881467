import { ReactNode, createContext, useCallback, useState } from 'react';
import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

type CallbackType = (isEnabled: boolean) => void;

type ImportInProcessModalContextType = (callback: CallbackType) => void;

export const ImportInProcessModalContext =
  createContext<ImportInProcessModalContextType>(() => null);

interface ImportInProcessModalInterface {
  children: ReactNode;
}
interface ModalStateInterface {
  show: boolean;
  callback: CallbackType;
}

export function ImportInProcessModal({
  children,
}: Readonly<ImportInProcessModalInterface>): JSX.Element {
  const t = useTranslationCatalog(
    'products.importExportPage.import.importInProcessModal',
  );
  const { Header, Body } = Modal;

  const [modalState, setModalState] = useState<ModalStateInterface>({
    show: false,
    callback: () => null,
  });

  const userConfirmation = useCallback((callback: CallbackType) => {
    setModalState({
      show: true,
      callback,
    });
  }, []);

  const handlePrimaryButton = () => {
    modalState.callback(true);
    setModalState({
      show: false,
      callback: () => null,
    });
  };

  const handleDismiss = () => {
    modalState.callback(false);
    setModalState({
      show: false,
      callback: () => null,
    });
  };

  return (
    <ImportInProcessModalContext.Provider value={userConfirmation}>
      {children}
      <Modal open={modalState.show} onDismiss={handleDismiss}>
        <Header title={t('titleModal')} />
        <Body padding="none">
          <Text>{t('messageModal')}</Text>
        </Body>
        <CancelAndConfirmButtons
          onCancel={handleDismiss}
          onConfirm={handlePrimaryButton}
          cancelText={t('cancelButton')}
          confirmText={t('confirmButton')}
          appearance="primary"
        />
      </Modal>
    </ImportInProcessModalContext.Provider>
  );
}
