import { Card, Title, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { InterfaceNameValue } from '@tiendanube/components';
import { CnpjCpfInput, Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'nuvempagoSettings';

interface NuvempagoInitArgentineCard {
  onChange: (event: InterfaceNameValue) => void;
  document: string;
  error?: string;
}

function NuvempagoInitArgentineCard({
  document,
  onChange,
  error,
}: NuvempagoInitArgentineCard) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">{t('card.title')}</Title>
        </Card.Header>
        <Card.Body>
          <Stack column align="flex-start">
            <Text>{t('card.subtitle')}</Text>
            <CnpjCpfInput
              onChange={onChange}
              value={document}
              name="document"
              label={t('card.identification')}
              helpText={t(error || 'card.helpText')}
              appearance={error ? 'validation_error' : undefined}
            />
          </Stack>
        </Card.Body>
      </Card>
      <Text>
        <Trans
          t={t}
          i18nKey="disclaimer"
          components={{
            TyCLink: (
              <Link
                as="a"
                appearance="primary"
                href={t('tycLink')}
                target="_blank"
              >
                {' '}
              </Link>
            ),
            PdPLink: (
              <Link
                as="a"
                appearance="primary"
                href={t('pdpLink')}
                target="_blank"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
    </>
  );
}

export default NuvempagoInitArgentineCard;
