import { BaseCard, Title } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function CardBillingAddressSkeleton(): JSX.Element {
  const t = useTranslationOrders();

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h4">{`${t('cardBillingAddress.title')}`}</Title>
      </BaseCard.Header>
    </BaseCard>
  );
}

export default CardBillingAddressSkeleton;
