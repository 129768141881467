import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  ContentPagesResponseDto,
  UpdateContentPagesRequestDto,
} from '@tiendanube/common';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { LanguageNameValueInterface } from 'App/i18n';
import { CancelAndSaveButtons } from 'commons/components';
import { useForm } from 'commons/hooks';
import { StatusType } from 'commons/types';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import { validationSchema } from 'domains/Online/ContentPages/validationSchema';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { FormPage } from '../../components';

interface EditFormPageProps {
  initialValues: ContentPagesResponseDto;
  updatePage: (payload: UpdateContentPagesRequestDto) => void;
  statusUpdate: StatusType;
}

function EditFormPage({
  initialValues,
  updatePage,
  statusUpdate,
}: EditFormPageProps) {
  const [isSaveForm, setIsSaveForm] = useState(false);
  const t = useTranslationOnline('contentPages');
  const { t: tCommon } = useTranslation();
  const [language] = useGetAllLanguages();
  const { goBack } = useNavegate();
  const { values, errors, isDirty, setFieldValue, handleOnSubmit } = useForm({
    initialValues,
    validationSchema: validationSchema(language),
    onSubmit: updatePage,
  });

  const handleSubmit = () => {
    setIsSaveForm(true);
    handleOnSubmit();
  };

  const showPrompt = isDirty && !isSaveForm;

  const handleChange = ({ name, value }: LanguageNameValueInterface) => {
    setFieldValue(name, value);
  };

  return (
    <>
      <Prompt when={showPrompt} message={tCommon('common:exitEdit.info')} />
      <FormPage values={values} errors={errors} onChange={handleChange} />
      <CancelAndSaveButtons
        onCancel={goBack}
        onSave={handleSubmit}
        saveText={t('formPage.saveButton')}
        isDisabled={!isDirty}
        isLoading={statusUpdate === 'loading'}
      />
      <HelpLink
        title={t('helpLink.title')}
        linkURL={t('helpLink.linkURL')}
        previousValue=""
        currentViewTracking={t('helpLink.currentViewTracking')}
        icon="both"
      />
    </>
  );
}

export default EditFormPage;
