import './StatsColorIcon.scss';

interface StatsColorIconProps {
  color?: string;
  strokeColor?: string;
  secondaryColor?: string;
  borderRadius?: string;
}

function StatsColorIcon({
  color = '#CCCCCC',
  secondaryColor,
  borderRadius = '2px',
  strokeColor,
}: StatsColorIconProps) {
  return (
    <div
      className="stratus--icon"
      style={{
        ...(color && {
          background: `linear-gradient(to right bottom, ${color} 50%, ${
            secondaryColor || color
          } 50%)`,
          borderRadius,
          ...(strokeColor && { border: `1px solid ${strokeColor}` }),
        }),
      }}
    />
  );
}

export default StatsColorIcon;
