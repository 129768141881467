import { ListWithPagination } from 'commons/components';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import { SeoRedirectsItem, SeoRedirectListHeader } from './components';

function Skeleton() {
  return (
    <ListWithPagination
      list={getMockArrayForSkeleton(10)}
      header={<SeoRedirectListHeader />}
      perPage={10}
      renderItem={() => <SeoRedirectsItem.Skeleton />}
    />
  );
}

export default Skeleton;
