import { useState } from 'react';
import { Stack } from '@tiendanube/components';
import { Responsive } from 'commons/components';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import {
  ComparisonSelect,
  ComparisonTableDesktop,
  ComparisonTableMobile,
} from './components';
import { SelectedPlan } from '../ComparisonPlansCards/components/PlanCard/types';

interface ComparisonTableProps {
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonTable({ onSelectPlan }: ComparisonTableProps): JSX.Element {
  const { features, plansList, currentPlanId, currentPlanLevel } =
    useBillingPlans();

  const currentPlanIndex = plansList.findIndex((p) => p.id === currentPlanId);
  const [firstSelectedPlan, setFirstSelectedPlan] = useState(
    plansList[currentPlanIndex],
  );
  const [secondSelectedPlan, setSecondSelectedPlan] = useState(
    currentPlanIndex < 3
      ? plansList[currentPlanIndex + 1]
      : plansList[currentPlanIndex - 1],
  );

  return (
    <Responsive
      mobile={
        <Stack column spacing="loose" align="stretch">
          <Stack.Item>
            <ComparisonSelect
              plansList={plansList}
              currentPlanId={currentPlanId}
              firstSelectedPlan={firstSelectedPlan}
              secondSelectedPlan={secondSelectedPlan}
              onSelectedFirstPlan={setFirstSelectedPlan}
              onSelectedSecondPlan={setSecondSelectedPlan}
              onSelectPlan={onSelectPlan}
            />
          </Stack.Item>
          <Stack.Item>
            <ComparisonTableMobile
              features={features}
              firstPlan={firstSelectedPlan}
              secondPlan={secondSelectedPlan}
            />
          </Stack.Item>
        </Stack>
      }
      desktop={
        <ComparisonTableDesktop
          features={features}
          plansList={plansList}
          onSelectPlan={onSelectPlan}
          currentPlanId={currentPlanId}
          currentPlanLevel={currentPlanLevel}
        />
      }
    />
  );
}
export default ComparisonTable;
