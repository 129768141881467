const MARGIN_TOP = 16;

export const MARGIN_BOTTOM = 32;
export const TICK_WIDTH = 64;
export const RANK_BY_PROVINCE_BAR_CHART_MARGIN = {
  left: TICK_WIDTH / 2,
  right: MARGIN_TOP,
  top: MARGIN_TOP,
  bottom: MARGIN_BOTTOM,
};
