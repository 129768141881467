import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { ErrorState } from '@tiendanube/components';

interface CustomerDetailsPageErrorProps {
  onRetryError: () => void;
}

function CustomerDetailsPageError({
  onRetryError,
}: CustomerDetailsPageErrorProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  return (
    <ErrorState
      title={t('customerDetails.error.title')}
      action={{
        children: t('customerDetails.error.action'),
        onClick: onRetryError,
      }}
    />
  );
}

export default CustomerDetailsPageError;
