import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getStatisticsProductsSummaryData,
  getStatisticsProductsSummaryStatus,
} from 'domains/Statistics/selectors';
import { getProductsSummary } from 'domains/Statistics/statisticsSlice';

function useProductsChartCard() {
  const dispatch = useAppDispatch();
  const data = useSelector(getStatisticsProductsSummaryData);
  const status = useSelector(getStatisticsProductsSummaryStatus);
  const { hasComparedFilter, openFilter } = useStatisticsFilterContext();

  const fetchProductsSummary = useCallback(() => {
    dispatch(getProductsSummary());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchProductsSummary);

  return {
    hasComparedFilter,
    openFilter,
    status,
    fetchProductsSummary,
    ...data,
  };
}

export default useProductsChartCard;
