import { DEFAULT_LANGUAGE } from 'App/i18n';
import { Input } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function BuyerNoteCardInput({
  value: initialValues,
  language = DEFAULT_LANGUAGE,
  onChange,
  onBlur,
}) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.buyerNoteCard',
  );

  const handleChange = ({ name, value }) => {
    onChange({
      name,
      value: { ...initialValues, [language]: value },
    });
  };

  const handleBlur = ({ name, value }) => {
    onBlur({
      name,
      value: { ...initialValues, [language]: value },
    });
  };

  return (
    <Input
      name="checkoutNoteLabel"
      value={initialValues[language]}
      onChange={handleChange}
      onBlur={handleBlur}
      label={t('checkoutNoteLabelText')}
      placeholder={t('checkoutNoteLabelPlaceholder')}
    />
  );
}
