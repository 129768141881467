import {
  Input,
  InterfaceNameValue,
  Select,
  Stack,
} from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { PROVINCES } from './constants';
import Skeleton from './Skeleton';

export interface OriginAddressValuesInterface {
  street: string;
  number: string;
  flat: string;
  apartment: string;
  locality: string;
  province: string;
}

interface OriginAddressCardProps {
  values: OriginAddressValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (group: string, data: InterfaceNameValue) => void;
}

function OriginAddressCard({
  values,
  errors,
  onChange,
}: OriginAddressCardProps): JSX.Element {
  const t = useTranslationShipping();

  useScrollToError(errors);

  const provinces = PROVINCES.map((province) => ({
    label: province,
    value: province,
  }));

  const handleChange = (event: InterfaceNameValue) => {
    onChange('originAddress', event);
  };

  const error = {
    street: errors['originAddress.street'] || '',
    number: errors['originAddress.number'] || '',
    flat: errors['originAddress.flat'] || '',
    apartment: errors['originAddress.apartment'] || '',
    locality: errors['originAddress.locality'] || '',
    province: errors['originAddress.province'] || '',
  };

  return (
    <CardWithTitle
      title={t('oca.config.originAddress.title')}
      subtitle={t('oca.config.originAddress.subtitle')}
    >
      <Stack column align="stretch">
        <Input
          name="street"
          label={t('oca.config.originAddress.street')}
          value={values.street}
          appearance={error.street ? 'validation_error' : 'default'}
          helpText={t(error.street)}
          onChange={handleChange}
        />
        <Stack justify="space-between" align="center" spacing="tight">
          <Input
            name="number"
            label={t('oca.config.originAddress.number')}
            value={values.number}
            appearance={error.number ? 'validation_error' : 'default'}
            helpText={t(error.number)}
            onChange={handleChange}
          />
          <Input
            name="flat"
            label={t('oca.config.originAddress.flat')}
            value={values.flat}
            appearance={error.flat ? 'validation_error' : 'default'}
            helpText={t(error.flat)}
            onChange={handleChange}
          />
          <Input
            name="apartment"
            label={t('oca.config.originAddress.apartment')}
            value={values.apartment}
            appearance={error.apartment ? 'validation_error' : 'default'}
            helpText={t(error.apartment)}
            onChange={handleChange}
          />
        </Stack>
        <Input
          name="locality"
          label={t('oca.config.originAddress.locality')}
          value={values.locality}
          appearance={error.locality ? 'validation_error' : 'default'}
          helpText={t(error.locality)}
          onChange={handleChange}
        />
        <Select
          label={t('oca.config.originAddress.province')}
          name="province"
          value={values.province}
          options={provinces}
          onChange={handleChange}
        />
      </Stack>
    </CardWithTitle>
  );
}

OriginAddressCard.Skeleton = Skeleton;

export default OriginAddressCard;
