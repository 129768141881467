import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  clearExportStatus,
  exportCustomers as exportCustomersAction,
} from 'domains/Customers/Customers/customersSlice';
import {
  getExportStatus,
  getExportToken,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseExportCustomersInterface {
  isExporting: boolean;
  isExportError: boolean;
  isExportSuccess: boolean;
  exportToken: string;
  exportCustomers: () => Promise<void>;
}

function useExportCustomers(): UseExportCustomersInterface {
  const dispatch = useAppDispatch();
  const { isExporting, isExportError, isExportSuccess } =
    useSelector(getExportStatus);
  const exportToken = useSelector(getExportToken);

  const exportCustomers = useCallback(async () => {
    await dispatch(exportCustomersAction());
    dispatch(clearExportStatus());
  }, [dispatch]);

  return {
    isExporting,
    isExportError,
    isExportSuccess,
    exportToken,
    exportCustomers,
  };
}

export default useExportCustomers;
