import { useCallback } from 'react';
import { InterfaceIdLabel } from '@tiendanube/components';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { InventoryFiltersType } from 'domains/Catalog/Inventory/inventoryServices';

type InventoryFilter = [
  string,
  InventoryFiltersType[keyof InventoryFiltersType],
];

function useFilterLabel(filters: InventoryFiltersType) {
  const t = useTranslationCatalog();
  const categories = useGetCategories();

  const getLabel = useCallback(
    ([filter, value]: InventoryFilter): InterfaceIdLabel => {
      if (filter === 'categoryId') {
        if (value !== 'has_no_categories') {
          return {
            id: filter,
            label: categories.find(({ id }) => `${id}` === value)?.title || '',
          };
        }

        return {
          id: filter,
          label: t('products.filters.categoryId.hasNoCategories'),
        };
      }
      if (value === 'quantity' && filter === 'stock') {
        return {
          id: filter,
          label:
            filters.maxStock === ''
              ? t(`products.filters.quantityMaxStock`, {
                  min: filters.minStock || 0,
                })
              : t(`products.filters.quantityStock`, {
                  min: filters.minStock || 0,
                  max: filters.maxStock,
                }),
        };
      }
      return {
        id: filter,
        label: t(`products.filters.${filter}.${value}`, value as string),
      };
    },
    [filters, categories, t],
  );

  return getLabel;
}

export default useFilterLabel;
