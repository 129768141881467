import { useCallback, useMemo } from 'react';
import { combineStatus } from 'commons/utils/combineStatus';
import { CONCEPTS_TO_SHOW } from './constants';
import { useLastPaidCharges } from './useLastPaidCharges';
import { useGetRecurrentPaymentConcepts } from '../../../hooks';

export const useManualPaidCharges = (lastPaidInitialFetch = true) => {
  const {
    lastPaidCharges,
    refreshLastPaidCharges,
    status: lastPaymentsStatus,
  } = useLastPaidCharges(lastPaidInitialFetch);

  const {
    status: recurrentPaymentStatus,
    recurrentPaymentForConcept,
    refreshRecurrentPaymentConcepts,
  } = useGetRecurrentPaymentConcepts();

  const refreshMaualPaidCharges = useCallback(() => {
    refreshRecurrentPaymentConcepts();
    refreshLastPaidCharges();
  }, [refreshRecurrentPaymentConcepts, refreshLastPaidCharges]);

  const statusObject = combineStatus(
    recurrentPaymentStatus,
    lastPaymentsStatus,
  );

  const manualPaidCharges = useMemo(
    () =>
      lastPaidCharges?.filter(
        (charge) =>
          CONCEPTS_TO_SHOW.includes(charge.conceptCode) &&
          !recurrentPaymentForConcept(
            charge.conceptCode,
            charge.metadata?.appId,
          ),
      ),
    [lastPaidCharges, recurrentPaymentForConcept],
  );

  return {
    manualPaidCharges,
    ...statusObject,
    refreshMaualPaidCharges,
  };
};
