import {
  CategoryResponseDto,
  CategoryTabedResponseDto,
} from '@tiendanube/common';

export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export type CategoriesEntities = Record<string, CategoryResponseDto>;

export interface CategoriesInterface {
  status: statusType;
  currentRequestID: string;
  entities: CategoriesEntities;
  ids: string[];
  options: CategoryTabedResponseDto[];
}
