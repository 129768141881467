import { Alert, Text } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function AddDomainAlert() {
  const t = useTranslationConfigurations('domains.addModal');

  return (
    <Alert appearance="primary">
      <Text>{t('alert')}</Text>
    </Alert>
  );
}
