import { Layout } from '@nimbus-ds/patterns';
import { ApplyTo, CouponCode, CouponHistory, DiscountType, Limits } from './';

export function CouponFormSkeleton() {
  return (
    <Layout columns="2 - asymmetric">
      <Layout.Section>
        <CouponCode.Skeleton />
        <DiscountType.Skeleton />
        <Limits.Skeleton />
        <ApplyTo.Skeleton />
      </Layout.Section>
      <Layout.Section>
        <CouponHistory.Skeleton />
      </Layout.Section>
    </Layout>
  );
}
