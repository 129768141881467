import { Box, Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Stack } from '@tiendanube/components';
import { ListItem } from 'commons/components';
import { getHelpLinkForThemeCustomization } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { THEMES_TRAITS, TRAIT_TYPE_ICON } from './theme-traits';
import './ThemeTraitList.scss';

interface ThemeTraitListProps {
  themeCode: string;
}

function ThemeTraitList({ themeCode }: Readonly<ThemeTraitListProps>) {
  const t = useTranslationOnline();
  const language = useTranslationLanguage();
  const traits = THEMES_TRAITS[themeCode];

  if (!traits) return null;

  const helpLink = getHelpLinkForThemeCustomization(themeCode)[language];

  return (
    <Box data-style="stratus--promoted-theme-trait-list">
      <Stack column align="stretch" spacing="tight">
        {traits.map((trait) => (
          <ListItem
            key={`trait-${trait.description}`}
            icon={TRAIT_TYPE_ICON[trait.type]}
            iconSize="small"
            neutralIcon={trait.type === 'info'}
            spacing="tight"
            content={
              <Trans
                t={t}
                i18nKey={trait.description}
                values={trait.descriptionValues}
                components={{
                  bold: <strong />,
                  ExternalLinkIcon: <ExternalLinkIcon />,
                  ThemeHelpLink: (
                    <Link
                      as="a"
                      appearance="primary"
                      href={helpLink}
                      target="_blank"
                      textDecoration="none"
                    >
                      {' '}
                    </Link>
                  ),
                }}
              />
            }
          />
        ))}
      </Stack>
    </Box>
  );
}

export default ThemeTraitList;
