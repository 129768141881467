import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getMetafieldsVariantsData,
  getMetafieldsVariantsStatus,
} from 'domains/Metafields/metafieldsSlice';
import { fetchMetafieldsVariantsAction } from 'domains/Metafields/metafieldsSlice/metafieldsSlice';
import useVariantsMetafieldsAccess from '../useVariantsMetafieldsAccess';

function useGetVariantsMetafields() {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsVariantsData);
  const { isLoading, isSuccess, isError, isIdle } = useSelector(
    getMetafieldsVariantsStatus,
  );
  const { isMetafieldFiltersEnabled, isMetafieldConfigEnabled } =
    useVariantsMetafieldsAccess();

  const fetchMetafields = useCallback(
    () => dispatch(fetchMetafieldsVariantsAction()),
    [dispatch],
  );

  const hasInternals =
    !!metafields?.internals && Object.keys(metafields?.internals).length > 0;
  const hasExternals =
    !!metafields?.fromApi && Object.keys(metafields?.fromApi).length > 0;

  const status = { isLoading, isError, isSuccess, isIdle };

  return {
    metafields: hasInternals ? metafields.internals : null,
    apiMetafields: hasExternals ? metafields.fromApi : null,
    status,
    isIdle,
    fetchMetafields,
    hasExternals,
    hasInternals,
    isAvailable: isMetafieldConfigEnabled,
    isFilterAvailable: isMetafieldFiltersEnabled,
  };
}

export default useGetVariantsMetafields;
