import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  createGetAppById,
  fetchAppAction,
  getAppStatus,
} from 'domains/PartnersApps/partnersAppsSlice';

function useGetApp(id: string) {
  const dispatch = useAppDispatch();
  const getAppById = useMemo(createGetAppById, []);
  const app = useSelector((state: RootStateType) => getAppById(state, id));

  const status = useSelector(getAppStatus);

  const fetchApp = useCallback(() => {
    dispatch(fetchAppAction(id));
  }, [dispatch, id]);

  return {
    app,
    status,
    fetchApp,
  };
}

export default useGetApp;
