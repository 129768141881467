import { EmptySearch } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function RelatedProductEmptySearch(): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <EmptySearch
      title={t('products.detail.relatedProduct.modal.searchEmpty')}
      text={t('products.detail.relatedProduct.modal.searchEmptyDescription')}
    />
  );
}

export default RelatedProductEmptySearch;
