import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList } from '@tiendanube/components';
import { useCustomerMessages } from 'domains/Customers/Customers/pages/CustomerDetailsPage/hooks';
import { CustomerMessagesItem, CustomerMessagesSkeleton } from './components';

interface CustomerMessagesProps {
  customerId: string;
  email: string;
  phone: string;
}

function CustomerMessages({
  customerId,
  email,
  phone,
}: CustomerMessagesProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const { customerMessagesIds, isLoading, isError } =
    useCustomerMessages(customerId);

  if (isLoading) {
    return <CustomerMessagesSkeleton />;
  }
  if (isError || !customerMessagesIds.length) {
    return null;
  }

  return (
    <Card title={t('customerDetails.customerActivity.messagesTitle')}>
      <DataList>
        {customerMessagesIds.map((messageId, key) => (
          <CustomerMessagesItem
            key={key}
            id={messageId}
            email={email}
            phone={phone}
          />
        ))}
      </DataList>
    </Card>
  );
}

export default CustomerMessages;
