import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { MobileIcon } from '@tiendanube/icons';
import { useHandleMenuClick } from 'App/hooks';

function KeyboardMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  const path = {
    appsWidgets: '/apps/widgets',
    keyboard: '/nube-keyboard',
  };

  return (
    <MenuButton
      as={Link}
      to={path.keyboard}
      startIcon={MobileIcon}
      onClick={handleClickItem}
      active={pathname.startsWith(path.keyboard)}
      label={`${t('menu.maximize.keyboard')}`}
    />
  );
}

export default KeyboardMenu;
