import { ChangeEvent } from 'react';
import { FormField, FormFieldInputProps } from '@nimbus-ds/patterns';
import { useInputMask } from 'commons/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailWeightBlur } from 'domains/Catalog/Products/tracking';

interface WeighInputProps {
  appearance?: FormFieldInputProps['appearance'];
  value: string;
  onChange: ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  onBlur?: () => void;
  isEdit?: boolean;
}

function WeighInput({
  appearance = 'none',
  value,
  onChange,
  label,
  placeholder,
  onBlur,
  isEdit,
}: Readonly<WeighInputProps>) {
  const weightMaskRef = useInputMask({
    alias: 'decimal',
    numericInput: true,
    rightAlign: false,
    digits: 3,
    max: 9999.999,
    min: 0,
  });

  const sender = useTrackFullCatalog();

  const handleBlur = () => {
    sender(() => {
      trackingProductDetailWeightBlur(isEdit);
    });
    if (onBlur) onBlur();
  };

  return (
    <FormField.Input
      aria-label={label}
      ref={weightMaskRef}
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      appearance={appearance}
      append="kg"
      appendPosition="end"
      name="weight"
      onBlur={handleBlur}
      min={0}
    />
  );
}

export default WeighInput;
