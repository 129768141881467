import HelpLink from 'App/HelpLink';
import { HELP_LINKS_PROMOTIONS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function PromotionHelpLink() {
  const t = useTranslationMarketing('promotions');
  const language = useTranslationLanguage();

  return (
    <HelpLink
      title={t('helpLink')}
      linkURL={HELP_LINKS_PROMOTIONS[language]}
      icon="both"
      currentViewTracking=""
      previousValue=""
    />
  );
}
