import { Route } from 'react-router-dom';
import BillingPlansPage from './pages/BillingPlansPage';
import { BILLING_ROUTES } from '../billingRoutes';

function BillingPlans(): JSX.Element {
  return (
    <Route exact path={BILLING_ROUTES.plans}>
      <BillingPlansPage />
    </Route>
  );
}

export default BillingPlans;
