import { getCheckEmailExists } from '../../customersServices/customersServices';

const checkEmailExists = async (value: string) => {
  try {
    const response = await getCheckEmailExists(value);
    return response.available;
  } catch (e) {
    return true;
  }
};

export const emailValidation = async (value: string, initialEmail: string) => {
  if (initialEmail === value) return true;
  return await checkEmailExists(value);
};
