import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getOrdersState } from './ordersSelectors';

const getPendingOrders = createSimpleSelector(
  [getOrdersState],
  (orders) => orders.pendingOrders,
);

export const getPendingOrdersData = createSimpleSelector(
  [getPendingOrders],
  (pendingOrders) => pendingOrders.data,
);

export const getPendingOrdersStatus = createSimpleSelector(
  [getPendingOrders],
  (pendingOrders) => convertStatusTypeToObject(pendingOrders.status),
);
