import { useMemo } from 'react';
import { Accordion, Box, Card } from '@nimbus-ds/components';
import {
  useChargesToPay,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import {
  AccountDataContent,
  PaymentDataContent,
} from 'domains/Billing/Checkout/pages/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function PayOrderSummary() {
  const { transformChargesToDisplayable } = useChargesToPay(false);
  const { payorderToPay, isSuccess } = usePayorderToPay();
  const t = useTranslationBilling('checkout.payOrderSummary');

  const charges = useMemo(
    () => payorderToPay && transformChargesToDisplayable(payorderToPay.charges),
    [payorderToPay, transformChargesToDisplayable],
  );

  const formatCurrency = useFormatCurrency();

  if (!isSuccess || !payorderToPay) return null;

  return (
    <Card padding="none">
      <Accordion selectedDefault="0">
        <Accordion.Item index="0">
          <Accordion.Header
            title={`${t('total')}: ${formatCurrency(
              payorderToPay.chargesSubtotal,
              payorderToPay.currency,
            )}`}
          />
          <Accordion.Body>
            <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
              <AccountDataContent />
              <div className="stratus--checkout-card_content--extra-item">
                {charges && <PaymentDataContent charges={charges} showTaxes />}
              </div>
            </Box>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Card>
  );
}

export default PayOrderSummary;
