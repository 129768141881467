import { Box, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { ErrorScreen, Stack } from 'commons/components';
import { useGetDomainConfigQuery } from 'domains/Configurations/Domains/domainsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CopyLinkBox } from './CopyLinkBox';

interface ARecordInfoProps {
  domainId: string;
}

export function ARecordInfo({ domainId }: Readonly<ARecordInfoProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.a');
  const { data, isLoading, isError, refetch } =
    useGetDomainConfigQuery(domainId);

  return (
    <Box
      backgroundColor="neutral-surface"
      display="flex"
      flexDirection="column"
      borderRadius="base"
      gap="2"
    >
      <Text>
        <Trans
          t={t}
          i18nKey="copyText"
          components={{
            helpLink: (
              <Link
                as="a"
                appearance="primary"
                href={t('helpLink')}
                target="_blank"
                textDecoration="none"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
      {isError && (
        <Box padding="4">
          <ErrorScreen description={t('error.title')} onRetry={refetch} />
        </Box>
      )}
      <Stack>
        {isLoading ? (
          <Text.Skeleton width="80px" />
        ) : (
          data?.cloudflareIps.map((ip) => (
            <CopyLinkBox
              key={ip}
              value={ip}
              message={t('copySuccess')}
              text={ip}
              hasBackground
            />
          ))
        )}
      </Stack>
    </Box>
  );
}
