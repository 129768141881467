import { useCallback, useEffect, useState } from 'react';
import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import {
  FEATURE_ONLY_LAYOUT_DEFAULT,
  FEATURE_ONLY_NUVEM_PAGO,
  FEATURE_ONLY_SHIPPING_NATIVE,
  FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
} from 'App/features';
import { useInterval, useToast } from 'commons/hooks';
import { logError } from 'commons/logger';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import { trackingBillingUpsellModalShow } from 'domains/Billing/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { BULLET_ACTIVATION_PERIOD_IN_MS } from './constants';
import useCompareFeatureValues from '../useCompareFeatureValues';
import useFindPlanForFeature from '../useFindPlanForFeature';
import usePlanChangePayment from '../usePlanChangePayment';
import useUpsellFlowModal from '../useUpsellFlowModal';

interface UpsellFlowParams {
  title?: string;
  featureKey: string;
  minValue?: TypeFeaturesPlansListResponseDto;
  callback?: (...args: any[]) => void;
  trackingSource?: string;
  asAside?: boolean;
  onCloseModal?: () => void;
  confirmationTextCta?: string;
}

function useUpsellFlow({
  title,
  featureKey,
  minValue = 1,
  callback,
  trackingSource,
  asAside = false,
  onCloseModal,
  confirmationTextCta,
}: UpsellFlowParams) {
  const { addToast } = useToast();
  const t = useTranslationBilling();

  const { setInterval, clearInterval } = useInterval();
  const { validateRoles } = useRoles();
  const { canChangePlanForFree, createPlanChangePayment } =
    usePlanChangePayment();
  const { getCurrentPlan, getNextPlan } = useFindPlanForFeature();
  const { negatableMeetsRequirement } = useCompareFeatureValues();
  const featureAvailability = useStatusFeatureByKey(featureKey);
  const {
    showUpsellFlowModal,
    hideUpsellFlowModal,
    updateUpsellFlowModalProps,
    showUpsellUnavailableModal,
    hideUpsellUnavailableModal,
  } = useUpsellFlowModal();
  const { currentPlanId, updateBillingPlan } = useBillingPlans(false);
  const [showError, setShowError] = useState(false);

  // These features work in reverse, 0 being "not available" and 1 being "available"
  const negativeFeatures = [
    FEATURE_ONLY_NUVEM_PAGO,
    FEATURE_ONLY_SHIPPING_NATIVE,
    FEATURE_ONLY_LAYOUT_DEFAULT,
    FEATURE_ONLY_NUVEM_PAGO_PAYMENTS,
  ];

  const currentValue = featureAvailability.maxUse;
  const isNegativeFeature = negativeFeatures.includes(featureKey);

  const resetUpsellFlow = useCallback(() => {
    onCloseModal?.();
    hideUpsellFlowModal();
    hideUpsellUnavailableModal();
    clearInterval();
  }, [
    hideUpsellFlowModal,
    hideUpsellUnavailableModal,
    clearInterval,
    onCloseModal,
  ]);

  const redirectToCheckout = useCallback(
    ({ paymentId, payOrderId }) => {
      hideUpsellFlowModal();

      if (paymentId) {
        goToAdmin(`account/checkout/payment/${paymentId}`)();
      } else {
        goToAdmin(`account/checkout/pay_order/${payOrderId}`)();
      }
    },
    [hideUpsellFlowModal],
  );

  const changePlan = useCallback(
    async (planId: number) => {
      try {
        const { needRedirect, paymentId, payOrderId } = await updateBillingPlan(
          planId,
        );

        if (needRedirect) {
          redirectToCheckout({ paymentId, payOrderId });
          return;
        }
      } catch (error) {
        setShowError(true);
      }
    },
    [updateBillingPlan, redirectToCheckout, setShowError],
  );

  const createPaymentAndGoToCheckout = useCallback(
    async (planId: number) => {
      try {
        const { paymentId, payOrderId } = await createPlanChangePayment(planId);

        redirectToCheckout({ paymentId, payOrderId });
      } catch (error) {
        setShowError(true);
      }
    },
    [createPlanChangePayment, redirectToCheckout, setShowError],
  );

  const startUpsellFlow = useCallback(
    (...args: any[]) => {
      const currentPlan = getCurrentPlan();

      const newPlan = getNextPlan({ featureKey, minValue, isNegativeFeature });

      if (newPlan === undefined || currentPlan === undefined) {
        showUpsellUnavailableModal({
          title,
          asAside,
          onDismiss: resetUpsellFlow,
        });

        if (validateRoles('full')) {
          logError(
            `New plan not found during upsell flow for feature ${featureKey} and current plan ${currentPlanId}`,
            new Error(),
          );
        }
        return;
      }

      const onFinalize = () => {
        resetUpsellFlow();
        callback?.(...args);
      };

      const onAccept = async () => {
        updateUpsellFlowModalProps({
          confirmationTextCta,
          ctasDisabled: true,
          planChangeInProgress: true,
        });
        if (canChangePlanForFree) {
          setInterval(
            (index) => {
              updateUpsellFlowModalProps({
                bulletProgress: index,
                planChangeInProgress: index !== newPlan.upsellBullets.length,
              });
            },
            BULLET_ACTIVATION_PERIOD_IN_MS,
            newPlan.upsellBullets.length,
          );

          await changePlan(newPlan.id);

          if (newPlan.upsellBullets.length) {
            // If the plan has upsell bullets, set prop to enable CTA
            updateUpsellFlowModalProps({ planChangeComplete: true });
          } else {
            // If not, close the modal automatically
            onFinalize();
          }
        } else {
          await createPaymentAndGoToCheckout(newPlan.id);
        }
      };

      trackingBillingUpsellModalShow({
        featureKey: featureKey,
        currentPlan: currentPlan.name,
        newPlan: newPlan.name,
        currentPlanValue: currentPlan.featureKeys[featureKey],
        newPlanValue: newPlan.featureKeys[featureKey],
        source: trackingSource,
      });

      showUpsellFlowModal({
        title,
        featureKey: featureKey,
        asAside,
        currentPlan,
        newPlan,
        onAccept,
        onDismiss: resetUpsellFlow,
        onFinalize,
        trackingSource,
      });
    },
    [
      getCurrentPlan,
      getNextPlan,
      featureKey,
      minValue,
      isNegativeFeature,
      trackingSource,
      showUpsellFlowModal,
      title,
      asAside,
      resetUpsellFlow,
      showUpsellUnavailableModal,
      validateRoles,
      currentPlanId,
      callback,
      updateUpsellFlowModalProps,
      confirmationTextCta,
      canChangePlanForFree,
      setInterval,
      changePlan,
      createPaymentAndGoToCheckout,
    ],
  );

  useEffect(() => {
    if (showError) {
      setShowError(false);
      resetUpsellFlow();
      addToast({
        label: t('upsellFlow.error'),
        appearance: 'danger',
      });
    }
  }, [showError, addToast, resetUpsellFlow, t]);

  return useCallback(
    (...args: any[]) => {
      const meetsRequirement = negatableMeetsRequirement(
        currentValue,
        minValue,
        isNegativeFeature,
      );

      if (meetsRequirement) {
        callback?.(...args);
      } else {
        startUpsellFlow(...args);
      }
    },
    [
      currentValue,
      negatableMeetsRequirement,
      startUpsellFlow,
      callback,
      minValue,
      isNegativeFeature,
    ],
  );
}

export default useUpsellFlow;
