import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export const getSalesChannelsAppsState = (state: RootStateType) =>
  state.salesChannels.salesChannelsApps;

export const getSalesChannelsAppsStatus = createSimpleSelector(
  [getSalesChannelsAppsState],
  ({ status }) => convertStatusTypeToObject(status),
);

export const getSalesChannelsAppsData = createSimpleSelector(
  [getSalesChannelsAppsState],
  ({ data }) => data,
);
