import { IconProps } from '@nimbus-ds/icons';

export function AiIcon({ color = 'gradient', size = 16 }: Readonly<IconProps>) {
  const sizes = { small: 16, medium: 24, large: 32 };
  const mappedSize = typeof size === 'number' ? size : sizes[size];

  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      height={mappedSize}
      width={mappedSize}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient
        id="gradient"
        gradientUnits="userSpaceOnUse"
        x1="6.50818"
        x2="13.1423"
        y1="16.5534"
        y2="-.447736"
      >
        <stop offset=".0530275" stopColor="#0050c3" />
        <stop offset=".396194" stopColor="#4629ba" />
        <stop offset=".947857" stopColor="#d8446e" />
      </linearGradient>

      <path
        clipRule="evenodd"
        d="M12.2313 2.46465C11.8986 2.98996 11.4528 3.43573 10.9275 3.76842C11.4528 4.10111 11.8986 4.54688 12.2313 5.0722C12.564 4.54688 13.0098 4.10111 13.5352 3.76842C13.0098 3.43573 12.564 2.98996 12.2313 2.46465ZM11.4985 0.622416C11.6348 -0.207472 12.8279 -0.207472 12.9641 0.622416L12.972 0.670956C13.1673 1.88214 14.1177 2.83248 15.329 3.02777L15.33 3.02793L15.3775 3.03567C16.2075 3.17192 16.2075 4.36492 15.3775 4.50117L15.329 4.50907C14.1177 4.70437 13.1673 5.65471 12.972 6.86589L12.9641 6.91443C12.8279 7.74431 11.6348 7.74431 11.4985 6.91443L11.4906 6.86589C11.2953 5.65471 10.3449 4.70437 9.13365 4.50907L9.0851 4.50117C8.25516 4.36492 8.25516 3.17192 9.0851 3.03567L9.13365 3.02777C10.3449 2.83248 11.2953 1.88214 11.4906 0.670956L11.4985 0.622416Z"
        fill={color === 'gradient' ? 'url(#gradient)' : color}
        fillRule="evenodd"
      />

      <path
        clipRule="evenodd"
        d="M5.34622 4.88373L5.4644 4.14974L5.46475 4.1476C5.61409 3.23865 6.92088 3.23865 7.07022 4.1476L7.07056 4.14974L7.18875 4.88373C7.51419 6.90269 9.09783 8.48625 11.1169 8.81172L11.8511 8.92992L11.8532 8.93026C12.7622 9.07959 12.7622 10.3863 11.8532 10.5356L11.8511 10.536L11.117 10.6541C9.09798 10.9796 7.51426 12.5631 7.18877 14.582L7.07056 15.3161L7.07022 15.3183C6.92088 16.2272 5.61409 16.2272 5.46475 15.3183L5.4644 15.3161L5.34622 14.5822C5.02073 12.5632 3.4371 10.9796 1.41806 10.6542L0.683899 10.536L0.681761 10.5356C-0.227254 10.3863 -0.227254 9.07959 0.681761 8.93026L0.683899 8.92992L1.41794 8.81174C3.43702 8.48627 5.02077 6.90269 5.34622 4.88373ZM6.26748 6.53594C5.65147 7.97012 4.50454 9.11698 3.07026 9.73294C4.50454 10.3489 5.65147 11.4958 6.26748 12.9299C6.88349 11.4958 8.03043 10.3489 9.4647 9.73294C8.03043 9.11698 6.88349 7.97012 6.26748 6.53594Z"
        fill={color === 'gradient' ? 'url(#gradient)' : color}
        fillRule="evenodd"
      />
    </svg>
  );
}
