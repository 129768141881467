import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanAddresses,
  fetchAddressesBrazil as fetchAddressesBrazilAction,
  getAddressesData,
  getAddressesStatus,
} from '../../addressesSlice';

function useGetAddressesBrazil() {
  const dispatch = useAppDispatch();
  const addressesBrazil = useSelector(getAddressesData);
  const { isError, isLoading, notFound } = useSelector(getAddressesStatus);

  const fetchAddressesBrazil = useCallback(
    (id) => {
      dispatch(fetchAddressesBrazilAction(id));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      dispatch(cleanAddresses());
    },
    [dispatch],
  );

  return {
    fetchAddressesBrazil,
    addressesBrazil,
    isError,
    isLoading,
    notFound,
  };
}

export default useGetAddressesBrazil;
