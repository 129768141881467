import { useCallback, useMemo, useState, type ReactNode } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type Active,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useInfiniteScroll } from 'commons/hooks';
import { SortableItem, SortableOverlay } from './components';
import Spinner from '../Spinner';
import './SortableDataList.scss';

/**
 * This component should be used for drag & drop list.
 * Allows to use scroll wheel while dragging and browser native search item using ctrl + f.
 *
 * Limitations:
 * - no virtualization: render large list has a negative performance impact in dnd interaction.
 *
 * If virtualization is needed, use DraggableDataListNew component instead. (for now)
 */

interface SortableDataListProps<T extends object> {
  customIdKey?: string;
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, index: number): ReactNode;
  showFooter?: boolean;
  endReached?: () => void;
}

export function SortableDataList<T extends object>({
  customIdKey = 'id',
  items,
  onChange,
  renderItem,
  endReached,
  showFooter = false,
}: Readonly<SortableDataListProps<T>>) {
  const targetID = useInfiniteScroll(endReached);
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item[customIdKey] === active?.id),
    [active, items, customIdKey],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => setActive(active),
    [],
  );
  const handleDragCancel = useCallback(() => setActive(null), []);
  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = items.findIndex(
          (item) => item[customIdKey] === active.id,
        );
        const overIndex = items.findIndex(
          (item) => item[customIdKey] === over.id,
        );
        onChange(arrayMove(items, activeIndex, overIndex));
      }
      setActive(null);
    },
    [onChange, items, customIdKey],
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={items.map((item) => item[customIdKey])}>
          <ul className="stratus--sortable-data-list-ul" role="application">
            {items.map((item, i) => (
              <SortableItem key={item[customIdKey]} id={item[customIdKey]}>
                {renderItem(item, i)}
              </SortableItem>
            ))}
          </ul>
        </SortableContext>
        <SortableOverlay>
          {activeItem ? (
            <SortableItem id={activeItem[customIdKey]}>
              {renderItem(activeItem, 0)}
            </SortableItem>
          ) : null}
        </SortableOverlay>
      </DndContext>
      <div ref={targetID} />
      {showFooter && <Spinner show={showFooter} />}
    </>
  );
}
