import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useToast } from 'commons/hooks';

interface InterfaceToastAction {
  handleAction: () => Promise<void>;
  textProgress: string;
  textSuccess: string;
  textError: string;
}

function useToastMessagesAction(): (attibutes: InterfaceToastAction) => void {
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const handleToast = async ({
    handleAction,
    textProgress,
    textSuccess,
    textError,
  }: InterfaceToastAction) => {
    addToastProgress({
      label: textProgress,
    });
    try {
      await handleAction();
      closeToastProgress();
      addToast({
        label: textSuccess,
        appearance: 'success',
      });
    } catch {
      closeToastProgress();
      addToast({
        label: textError,
        appearance: 'danger',
      });
    }
  };

  return handleToast;
}

export default useToastMessagesAction;
