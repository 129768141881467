import { useEffect } from 'react';
import { usePostAssertStoreMutation } from '../CheckoutApi';

export function useAssertStore() {
  const [assertStore, { isUninitialized }] = usePostAssertStoreMutation({
    fixedCacheKey: 'key',
  });

  useEffect(() => {
    if (isUninitialized) assertStore();
  }, [isUninitialized, assertStore]);
}
