import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';

interface ImagesEmptyProps {
  onOpenModal: () => void;
}

function ImagesEmpty({ onOpenModal }: ImagesEmptyProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return (
    <Thumbnail.Add
      helperText={t('products.detail.firstPhoto')}
      onClick={onOpenModal}
      ariaLabel={t('products.detail.addPhotos')}
      aspectRatio="16-9"
    />
  );
}

export default ImagesEmpty;
