import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import {
  getProductSectionDetailByCode,
  updateProductsSection as updateProductsSectionAction,
} from '../../themesSlice';
import {
  getProductSectionsDetailsStatusSelector,
  getProductSectionsDetailsUpdateStatusSelector,
  createGetProductsSectionById,
} from '../../themesSlice/themesSelectors';
import { SectionCodeType, SectionProductItemInterface } from '../../types';

function useProductSectionDetails(id: string) {
  const dispatch = useAppDispatch();
  const getProductById = useMemo(createGetProductsSectionById, []);
  const productsSectionDetails = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );
  const status = useSelector(getProductSectionsDetailsStatusSelector);
  const updateStatus = useSelector(
    getProductSectionsDetailsUpdateStatusSelector,
  );

  const fetchProductSectionsDetails = useCallback(
    (code?: SectionCodeType) => {
      if (code) {
        dispatch(getProductSectionDetailByCode(code));
      }
    },
    [dispatch],
  );

  const updateProductsSection = async (
    code: SectionCodeType,
    products: SectionProductItemInterface[],
  ) => {
    unwrapResult(
      await dispatch(
        updateProductsSectionAction({
          code,
          products: products.map((product, i) => ({
            order: i + 1,
            id: product.productId,
          })),
        }),
      ),
    );
  };

  return {
    productsSectionDetails,
    status,
    fetchProductSectionsDetails,
    updateProductsSection,
    updateStatus,
  };
}

export default useProductSectionDetails;
