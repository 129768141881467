import { useCallback, useState } from 'react';
import { BuyXPayY, ProgressiveDiscount } from '@tiendanube/common';
import { BUY_X_PAY_Y_PROMOTIONS_TAG } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { promotionDefault, PromotionTypes } from './constants';
import { ScopeValuesInterface } from './types';
import { validationSchema } from './validationSchema';
import {
  PromotionPage,
  PromotionsDetailsSkeletons,
  PromotionForm,
} from '../components';
import {
  useCreatePromotionMutation,
  usePromotionTypesQuery,
} from '../promotionsApi';

export function NewPromotionPage() {
  const t = useTranslationMarketing('promotions.settingsPage.add');
  const [hasBuyXPayYTag] = useHasTags(BUY_X_PAY_Y_PROMOTIONS_TAG);

  const { goBack } = useNavegate();

  const [isExternal, setIsExternal] = useState(false);

  const [createFreeShipping, { status: statusCreate }] =
    useCreatePromotionMutation();

  const { status: statusType, refetch: refecchTypes } =
    usePromotionTypesQuery();

  const getPromotionDefault = () => {
    const defaults = promotionDefault;
    if (hasBuyXPayYTag) {
      defaults.promotionType = PromotionTypes.BUY_X_PAY_Y;
    }
    return defaults;
  };

  const {
    values,
    errors,
    isDirty,
    handleChange,
    setFieldValue,
    handleChangeSelect,
    handleOnSubmit,
  } = useForm({
    initialValues: getPromotionDefault(),
    validationSchema,
    onSubmit: createFreeShipping,
  });

  const handleChangeCategories = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('categories', scopeValues);
  };

  const handleChangeProducts = (scopeValues: ScopeValuesInterface[]) => {
    setFieldValue('products', scopeValues);
  };

  const handleChangeProgressiveDiscounts = (
    progressiveDiscounts: ProgressiveDiscount[],
  ) => {
    setFieldValue('progressiveDiscounts', progressiveDiscounts);
  };

  const handleChangeBuyXPayY = (buyXPayY: BuyXPayY | null) => {
    setFieldValue('buyXPayY', buyXPayY);
  };

  const handleChangeIsExternal = useCallback((value: boolean) => {
    setIsExternal(value);
  }, []);

  const saveStatus = toStatusType(statusCreate);
  const loadStatus = toStatusType(statusType);

  useToastStatus({
    error: t('error'),
    success: t('success'),
    status: saveStatus,
    onSuccess: goBack,
  });

  return (
    <PromotionPage
      mode="add"
      isDirty={isDirty}
      isExternal={isExternal}
      saveStatus={saveStatus}
      loadStatus={loadStatus}
      onSave={handleOnSubmit}
    >
      {loadStatus === 'error' && <ErrorScreen onRetry={refecchTypes} />}
      {loadStatus === 'loading' && <PromotionsDetailsSkeletons />}
      {loadStatus === 'success' && (
        <PromotionForm
          mode="add"
          values={values}
          errors={errors}
          isExternal={isExternal}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          onChangeSelect={handleChangeSelect}
          onChangeCategories={handleChangeCategories}
          onChangeProducts={handleChangeProducts}
          onChangeIsExternal={handleChangeIsExternal}
          onChangeProgressiveDiscounts={handleChangeProgressiveDiscounts}
          onChangeBuyXPayY={handleChangeBuyXPayY}
        />
      )}
    </PromotionPage>
  );
}
