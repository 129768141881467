import { Box, Icon, Text } from '@nimbus-ds/components';
import {
  StoreIcon,
  TruckIcon,
  WalletIcon,
  GlobeIcon,
  BrowserIcon,
  PencilIcon,
  ChevronLeftIcon,
} from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import ShowByRole from 'App/components/ShowByRole';
import { useNavegate, useHandleMenuClick } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsPlanLojinha } from 'domains/Auth/hooks';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { ExternalCodesMenu } from 'domains/Configurations/ExternalCodes/ExternalCodesMenu';
import { NotificationsMenu } from 'domains/Configurations/Notifications/NotificationsMenu';
import { SeoRedirectsMenu } from 'domains/Configurations/SeoRedirects';
import { seoRedirectsRoutes } from 'domains/Configurations/SeoRedirects/seoRedirectsRoutes';
import { SettingsCheckoutMenu } from 'domains/Configurations/SettingsCheckout';
import { WhatsappMenu } from 'domains/Configurations/Whatsapp';
import { InfoMessageMenu } from 'domains/Online/InfoMessage';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import { menuPaymentsClick, menuSecondaryDomainsClick } from './tracking';
import { useMenuScrollShadow } from '../../hooks';

const path = {
  locations: '/settings/locations',
  payments: PAYMENTS_BASE_ROUTE,
  shippingMethods: SHIPPING_METHODS_BASE_ROUTE,
  email: '/notifications/',
  infoMessage: '/info_message/',
  i18n: CONFIGURATIONS_ROUTES.i18n,
  checkout: '/settings/checkout/',
  redirects301: seoRedirectsRoutes.redirects,
  domains: CONFIGURATIONS_ROUTES.domains,
  metafields: '/settings/metafields',
};

interface SettingsMenuProps {
  visible: boolean;
}

function SettingsMenu({ visible }: Readonly<SettingsMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { goTo } = useNavegate();
  const isPlanLojinha = useIsPlanLojinha();
  const { validateRoles } = useRoles();
  const menuBodyRef = useMenuScrollShadow();

  const { handleClickItem } = useHandleMenuClick();

  const handleClickDomains = () => {
    menuSecondaryDomainsClick();
    handleClickItem();
  };

  const handleClickPayments = () => {
    menuPaymentsClick();
    handleClickItem();
  };

  const shippingAndPaymentTitle = () => {
    if (validateRoles(['shipping', 'locations']) && validateRoles('payments')) {
      return t('settingsMenu.payments.title');
    }
    return validateRoles(['shipping', 'locations'])
      ? t('settingsMenu.payments.title_only_shipping')
      : t('settingsMenu.payments.title_only_payments');
  };

  return (
    <div
      className={classNames('stratus--menu-container', 'stratus--submenu', {
        visible: visible,
      })}
    >
      <Menu>
        <Menu.Header>
          <Box
            display="flex"
            gap="1"
            as="button"
            backgroundColor="transparent"
            borderColor="transparent"
            onClick={() => goTo('/')}
            alignItems="center"
            cursor="pointer"
          >
            <Icon
              color="neutral-textHigh"
              source={<ChevronLeftIcon size={14} />}
            />
            <Text fontWeight="medium" lineClamp={1} color="neutral-textHigh">
              {t('settingsMenu.title')}
            </Text>
          </Box>
        </Menu.Header>
        <div className="stratus--menu-body" ref={menuBodyRef}>
          <Menu.Body>
            <ShowByRole
              includeRoles={['full', 'shipping', 'locations', 'payments']}
            >
              <Menu.Section title={shippingAndPaymentTitle()}>
                <ShowByRole includeRoles={['shipping']}>
                  <Menu.Button
                    as={Link}
                    to={path.shippingMethods}
                    startIcon={TruckIcon}
                    onClick={handleClickItem}
                    active={pathname.startsWith(path.shippingMethods)}
                    label={t('settingsMenu.payments.shippingMethods')}
                  />
                </ShowByRole>

                <ShowByRole includeRoles={['locations']}>
                  <Menu.Button
                    as={Link}
                    to={path.locations}
                    startIcon={StoreIcon}
                    onClick={handleClickItem}
                    active={pathname.startsWith(path.locations)}
                    label={t('settingsMenu.payments.locations')}
                  />
                </ShowByRole>

                <ShowByRole includeRoles="payments">
                  <Menu.Button
                    as={Link}
                    to={path.payments}
                    startIcon={WalletIcon}
                    onClick={handleClickPayments}
                    active={pathname.startsWith(path.payments)}
                    label={t('settingsMenu.payments.methods')}
                  />
                </ShowByRole>
              </Menu.Section>
            </ShowByRole>

            <ShowByRole includeRoles="emails">
              <Menu.Section title={t('settingsMenu.communication.title')}>
                <NotificationsMenu />
                <ShowByRole includeRoles={['full']}>
                  <>
                    <WhatsappMenu />
                    <InfoMessageMenu />
                  </>
                </ShowByRole>
              </Menu.Section>
            </ShowByRole>

            <ShowByRole includeRoles={['i18n']}>
              <Menu.Section title={t('settingsMenu.general.title')}>
                <Menu.Button
                  as={Link}
                  to={path.i18n}
                  startIcon={GlobeIcon}
                  active={pathname.startsWith(path.i18n)}
                  label={t('settingsMenu.general.languages')}
                  onClick={handleClickItem}
                />
              </Menu.Section>
            </ShowByRole>

            <ShowByRole
              includeRoles={[
                'full',
                'preferences_checkout',
                'preferences_advanced',
                'seo_redirects',
                'domains',
              ]}
            >
              {!isPlanLojinha && (
                <Menu.Section title={t('settingsMenu.advanced.title')}>
                  <ShowByRole includeRoles="preferences_checkout">
                    <SettingsCheckoutMenu />
                  </ShowByRole>
                  <ShowByRole includeRoles="preferences_advanced">
                    <ExternalCodesMenu />
                  </ShowByRole>
                  <ShowByRole includeRoles="seo_redirects">
                    <SeoRedirectsMenu />
                  </ShowByRole>

                  <ShowByRole includeRoles="domains">
                    <Menu.Button
                      as={Link}
                      to={path.domains}
                      startIcon={BrowserIcon}
                      onClick={handleClickDomains}
                      active={pathname.startsWith(path.domains)}
                      label={t('settingsMenu.advanced.domains')}
                    />
                  </ShowByRole>

                  <ShowByRole includeRoles={['full']}>
                    <Menu.Button
                      as={Link}
                      to={path.metafields}
                      startIcon={PencilIcon}
                      onClick={handleClickItem}
                      active={pathname.startsWith(path.metafields)}
                      label={t('settingsMenu.advanced.metafields')}
                    />
                  </ShowByRole>
                </Menu.Section>
              )}
            </ShowByRole>
          </Menu.Body>
        </div>
      </Menu>
    </div>
  );
}

export default SettingsMenu;
