import { useMemo } from 'react';
import { Box, BoxProps, Checkbox } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { toStatusType } from 'App/rtk';
import {
  BaseCardCollapsible,
  CardWithTitle,
  SubmitButton,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { Skeleton } from './Skeleton';
import {
  useGetInfoMessageQuery,
  useUpdateInfoMessageMutation,
} from '../infoMessageApi';

export const INFO_MESSAGE_FORM_LAYOUT_STYLE: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8',
};

export function InfoMessageForm() {
  const t = useTranslationOnline('infoMessage.form');
  const { t: tCommon } = useTranslation();
  const initialValues = useMemo(
    () => ({
      message: t('defaultMessage'),
      hideShippingDeliveryDate: false,
      showShippingEmergencyMessage: false,
    }),
    [t],
  );
  const { data: infoMessage } = useGetInfoMessageQuery();
  const [updateInfoMessage, { status, isLoading }] =
    useUpdateInfoMessageMutation();
  const {
    values,
    handleOnSubmit,
    handleChange,
    handleChangeTextArea,
    setFieldValue,
    isDirty,
  } = useForm({
    initialValues: infoMessage ?? initialValues,
    onSubmit: (data) =>
      updateInfoMessage({
        ...data,
        message: data.message.trim() || initialValues.message,
      }),
  });

  useToastStatus({
    status: toStatusType(status),
    success: t('toast.success'),
    error: t('toast.error'),
  });

  const handleToggle = () => {
    setFieldValue(
      'showShippingEmergencyMessage',
      !values.showShippingEmergencyMessage,
    );
  };
  return (
    <Box {...INFO_MESSAGE_FORM_LAYOUT_STYLE}>
      <BaseCardCollapsible
        title={t('title')}
        subtitle={t('subtitle')}
        active={!!values.showShippingEmergencyMessage}
        onChange={handleToggle}
        paddingBody="base"
        appearance="toggle"
        alignSwitch="flex-start"
      >
        <FormField.Textarea
          value={values.message}
          label={t('textAreaLabel')}
          appearance="none"
          id="message"
          name="message"
          onChange={handleChangeTextArea}
          lines={3}
        />
      </BaseCardCollapsible>
      <ShowByCountry allowedCountries={[Country.AR]}>
        <CardWithTitle title={t('showShippingTime.title')}>
          <Checkbox
            name="hideShippingDeliveryDate"
            onChange={handleChange}
            type="checkbox"
            checked={values.hideShippingDeliveryDate}
            label={t('showShippingTime.label')}
          />
        </CardWithTitle>
      </ShowByCountry>
      <Box display="flex" flexDirection="row" gap="2" justifyContent="flex-end">
        <SubmitButton
          onClick={handleOnSubmit}
          disabled={isLoading || !isDirty}
          isLoading={isLoading}
        >
          {t('save')}
        </SubmitButton>
      </Box>

      <Prompt when={isDirty} message={tCommon('common:exitEdit.info')} />
    </Box>
  );
}

InfoMessageForm.Skeleton = Skeleton;
