import { Box, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

// Imitation of nimbus <Tag> element for only one tab for cosmetic consistency
interface TablikeProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

function Tablike({ label, children }: Readonly<TablikeProps>) {
  return (
    <Stack column align="stretch" spacing="tight">
      <Box
        marginBottom="2"
        borderColor="neutral-surfaceHighlight"
        borderBottomWidth="2"
        borderStyle="solid"
      >
        <Text>
          <div style={{ lineHeight: '32px' }}>{label}</div>
        </Text>
      </Box>
      {children}
    </Stack>
  );
}

export default Tablike;
