import { ErrorState } from '@tiendanube/components';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { SelectChargesToPay, SelectChargesWithCreation } from './components';
import { useSelectCharges } from './hooks';
import SelectChargesToPayTabSkeleton from './Skeleton';

export function SelectChargesToPayTab() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const { storeStatus } = useStoreStatus();
  const { isTrial } = useGetIsFreePlan();

  const { isLoading, isError, refreshChargesToPay, planIsListed } =
    useSelectCharges();

  if (isLoading) return <SelectChargesToPayTabSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: refreshChargesToPay }}
      />
    );

  if (!planIsListed && (isTrial || storeStatus === 'store_down')) {
    return <SelectChargesWithCreation />;
  }

  return <SelectChargesToPay />;
}
