import { createSelector } from '@reduxjs/toolkit';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { StatsProductsItemType } from 'domains/Statistics/types';
import { getStatistics } from '../statisticsSelectors';

const getStatsProducts = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.statsProducts,
);

export const createGetStatsProductsResultByItem = () =>
  createSelector(
    [getStatsProducts, (_, item: StatsProductsItemType) => item],
    (products, item) => products[item].results,
  );

export const createGetStatsProductsStatusByItem = () =>
  createSelector(
    [getStatsProducts, (_, item: StatsProductsItemType) => item],
    (products, item) => products[item].status,
  );

export const createGetStatsProductsFiltersByItem = () =>
  createSelector(
    [getStatsProducts, (_, item: StatsProductsItemType) => item],
    (products, item) => products[item].filters,
  );

export const createGetStatsProductsPaginationByItem = () =>
  createSelector(
    [getStatsProducts, (_, item: StatsProductsItemType) => item],
    (products, item) => products[item].pagination,
  );
