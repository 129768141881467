import { Tag } from '@nimbus-ds/components';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import { RecurringAppStatusTag } from './RecurringAppStatusTag';

interface AppStatusTagProps {
  app: AppsListItemResponseDto;
}

export function AppStatusTag({ app }: AppStatusTagProps) {
  const { t } = useTranslationPartnerApps('appsListPage.status');
  const withRemainingDays = app.daysRemaining === null || app.daysRemaining > 0;

  if (app.installed) {
    if (!app.payable && !app.disabledInStore && (withRemainingDays || app.free))
      return <Tag appearance="success">{t('enabled')}</Tag>;
    if (!app.isOnetimer) return <RecurringAppStatusTag app={app} />;
    return <Tag appearance="success">{t('enabled')}</Tag>;
  }

  if (app.free) return <Tag appearance="neutral">{t('disabled')}</Tag>;
  if (app.trial)
    return (
      <Tag appearance="neutral">
        {t('remainingTrialDays', { count: app.trialDaysForStore })}
      </Tag>
    );
  if (app.installable) return <Tag appearance="neutral">{t('disabled')}</Tag>;
  if (app.payable && !app.isOnetimer)
    return <RecurringAppStatusTag app={app} />;
  return <Tag appearance="neutral">{t('disabled')}</Tag>;
}
