import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { CalloutCard } from '@tiendanube/components';
import { TiendanubeIcon } from '@tiendanube/icons';
import { GOOGLE_ANALYTICS_DIFFERENCES_WITH_STATISTICS_LINK } from 'commons/constants/externalLinks';
import { openWindow } from 'commons/utils/window';

function GoogleAnalyticsDifferencesCard() {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.STATISTICS);

  const handleOnClick = () => {
    openWindow(
      GOOGLE_ANALYTICS_DIFFERENCES_WITH_STATISTICS_LINK[language],
      true,
    );
  };

  return (
    <CalloutCard
      appearance="secondary"
      icon={TiendanubeIcon}
      onClick={handleOnClick}
      textLink={{
        children: t('statistics.googleAnalyticsDifferencesCard.link'),
      }}
      title={t('statistics.googleAnalyticsDifferencesCard.title')}
      subtitle={t('statistics.googleAnalyticsDifferencesCard.description')}
    />
  );
}

export default GoogleAnalyticsDifferencesCard;
