import { Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function CouponHistoryError({ handleClick }) {
  const t = useTranslationMarketing('coupons.history');

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <ErrorState
          title={t('errorState.title')}
          action={{
            children: t('errorState.retry'),
            onClick: handleClick,
          }}
        />
      </Card.Body>
    </Card>
  );
}
