import { ChangeEvent } from 'react';
import { Radio, Text } from '@nimbus-ds/components';
import { CardWithTitle, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface ExternalCodesConversionCardProps {
  purchaseEventOnPaidOrder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RADIO_BUTTON_VALUES = {
  paid: '1',
  completed: '0',
};

export function ExternalCodesConversionCard({
  onChange,
  purchaseEventOnPaidOrder,
}: Readonly<ExternalCodesConversionCardProps>) {
  const t = useTranslationConfigurations('externalCodes.conversion');
  return (
    <CardWithTitle title={t('title')}>
      <Stack column spacing="tight" align="stretch">
        <Text>{t('label')}</Text>
        <Radio
          id="purchaseEventOnPaidOrder1"
          onChange={onChange}
          as="button"
          name="purchaseEventOnPaidOrder"
          label={t('completed')}
          value={RADIO_BUTTON_VALUES.completed}
          checked={purchaseEventOnPaidOrder === RADIO_BUTTON_VALUES.completed}
        />
        <Radio
          id="purchaseEventOnPaidOrder2"
          onChange={onChange}
          as="button"
          name="purchaseEventOnPaidOrder"
          label={t('paid')}
          value={RADIO_BUTTON_VALUES.paid}
          checked={purchaseEventOnPaidOrder === RADIO_BUTTON_VALUES.paid}
        />
      </Stack>
    </CardWithTitle>
  );
}
