import { FEATURE_METAFIELDS_CATEGORIES } from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';

export default function useCategoriesMetafieldsAccess() {
  const { isAvailable: hasMetafieldsCategoriesByPlan } = useStatusFeatureByKey(
    FEATURE_METAFIELDS_CATEGORIES,
  );

  return {
    isAvailable: hasMetafieldsCategoriesByPlan,
  };
}
