import { Box } from '@nimbus-ds/components';
import LinkToMetafields, {
  LinkToMetafieldsProps,
} from '../LinkToMetafields/LinkToMetafields';

interface LinkToMetafieldsBasedOnUserProps extends LinkToMetafieldsProps {
  isFullUser: boolean;
}

function LinkToMetafieldsBasedOnUser({
  isFullUser,
  ...props
}: Readonly<LinkToMetafieldsBasedOnUserProps>) {
  if (isFullUser) return <LinkToMetafields {...props} />;
  return <Box marginTop="4" />;
}

export default LinkToMetafieldsBasedOnUser;
