import {
  ProductSectionResponseDto,
  ProductSectionDetailResponseDto,
  SectionProductRequestDto,
  SectionProductDto,
  CategoriesOptionsResponseDto,
  ResultPaginationResponseDto,
  ContactInfoResponseDto,
  ContactInfoRequestDto,
  PasswordProtectedResponseDto,
  PasswordProtectedRequestDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  CONTACT_INFO_URL,
  PASSWORD_PROTECTED_URL,
  PRODUCT_SECTIONS_URL,
  PRODUCTS_ORDER_URL,
} from './themesUrl';
import { ProductsOrderListFiltersInterface } from '../types';

export const getProductSections = async () => {
  const { data } = await axios.get<ProductSectionResponseDto[]>(
    PRODUCT_SECTIONS_URL,
  );
  return data;
};

export const getProductSectionDetailByCode = async (code: string) => {
  const { data } = await axios.get<ProductSectionDetailResponseDto>(
    `${PRODUCT_SECTIONS_URL}/${code}`,
  );
  return data;
};

export const updateProductsSection = async ({
  code,
  products,
}: {
  code: string;
  products: SectionProductRequestDto[];
}) => {
  const { data } = await axios.put<SectionProductDto[]>(
    `${PRODUCT_SECTIONS_URL}/${code}`,
    products,
  );
  return data;
};

export const getProductsOrder = async () => {
  const { data } = await axios.get<CategoriesOptionsResponseDto>(
    PRODUCTS_ORDER_URL,
  );
  return data;
};

export const updateProductsOrder = async ({ categoryId, ...payload }) => {
  await axios.put<void>(`${PRODUCTS_ORDER_URL}/${categoryId}`, payload);
};

export const getProductsOrderProductList = async (
  filters: ProductsOrderListFiltersInterface,
) => {
  const { categoryId, sortBy, page, perPage } = filters;
  const { data } = await axios.get<
    ResultPaginationResponseDto<SectionProductDto[]>
  >(
    `${PRODUCTS_ORDER_URL}/products?categoryId=${categoryId}&sortBy=${sortBy}&page=${page}&perPage=${perPage}`,
  );
  return data;
};

export const getContactInfo = async () => {
  const { data } = await axios.get<ContactInfoResponseDto>(CONTACT_INFO_URL);
  return data;
};

export const updateContactInfo = async (payload: ContactInfoRequestDto) => {
  await axios.put<void>(CONTACT_INFO_URL, payload);
};

export const getPasswordProtected = async () => {
  const { data } = await axios.get<PasswordProtectedResponseDto>(
    PASSWORD_PROTECTED_URL,
  );
  return data;
};

export const updatePasswordProtected = async (
  payload: PasswordProtectedRequestDto,
) => {
  await axios.post<void>(PASSWORD_PROTECTED_URL, payload);
};
