import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Text,
  Popover,
} from '@nimbus-ds/components';
import { NotificationIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { trackingMenuPrimaryAccountClick } from 'App/tracking';
import { BackNavigation } from 'commons/components';
import { openWindow } from 'commons/utils/window';
import { useHeaderMenuData, useIsLogged } from 'domains/Auth/hooks';
import { Avatar, ProductUpdatesLink } from './components';
import useTopBar from './useTopBar';
import { AccountMenuContent } from '../Menu/components/AccountMenu/components';
import ButtonBackPreviousVersion from '../Migrations/ButtonBackPreviousVersion';
import { SupportChanels } from '../SupportChanels';
import './Topbar.scss';

function TopBar(): JSX.Element {
  const { t } = useTranslation('common');
  const { headerNavigation, isShowMenu } = useTopBar();
  const { name } = useHeaderMenuData();
  const { isLogin } = useIsLogged();
  const [showPopover, setShowPopover] = useState(false);
  const location = useLocation();

  const handleAccountClick = () => {
    if (!showPopover) {
      trackingMenuPrimaryAccountClick();
    }
    return setShowPopover(!showPopover);
  };

  const handleClickNews = () => {
    openWindow(t('topbar.whatsNewLink'), true);
  };

  useEffect(() => {
    setShowPopover(false);
  }, [location]);

  return (
    <div className="stratus--top-bar">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {headerNavigation && (
            <BackNavigation onClick={headerNavigation.onClick}>
              {headerNavigation.children}
            </BackNavigation>
          )}
        </Box>
        {isShowMenu && (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={{ xs: '1', md: '2' }}
          >
            <ProductUpdatesLink />
            <Tooltip content={t('topbar.whatsNew')} arrow={false}>
              <IconButton
                size="2.125rem"
                source={<NotificationIcon size={18} />}
                backgroundColor="transparent"
                borderColor={{
                  xs: 'transparent',
                  hover: 'neutral-interactiveHover',
                }}
                data-style="icon-button-override"
                onClick={handleClickNews}
              />
            </Tooltip>
            {isLogin && <SupportChanels />}
            <Popover
              content={
                <Box display="flex" flexDirection="column" width="100%">
                  <AccountMenuContent />
                </Box>
              }
              arrow={false}
              padding="none"
              width="18rem"
              position="bottom-end"
              visible={showPopover}
              onVisibility={setShowPopover}
            >
              <Button
                appearance="transparent"
                data-style="button-override"
                onClick={handleAccountClick}
              >
                <Avatar name={name} />
                <Box maxWidth="270px">
                  <Text
                    fontWeight="medium"
                    color="neutral-textHigh"
                    lineClamp={1}
                    wordBreak="break-all"
                  >
                    {name}
                  </Text>
                </Box>
              </Button>
            </Popover>
            <ButtonBackPreviousVersion />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default TopBar;
