import { Alert, Link, Text } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { useTranslationCommon } from 'commons/hooks';
import { paymentsRoutes } from 'domains/Payments';
import { useInstalledPaymentProviders } from 'domains/Payments/PaymentProviders/hooks';
import { useKycStatus } from '../hooks/useKycStatus';

export function KycRejectedAlert() {
  const t = useTranslationCommon('kycRejectedAlert');
  const { isKycRejected } = useKycStatus();
  const { paymentProviders, isSuccess } = useInstalledPaymentProviders();
  const hasOtherCustomPayment = paymentProviders?.some(
    (provider) => provider.isActive,
  );

  return (
    <Alert
      show={isKycRejected && isSuccess && !hasOtherCustomPayment}
      appearance="warning"
      title={t('title')}
    >
      <Text>{t('description')}</Text>
      <Link as={LinkRoute} to={paymentsRoutes.customPaymentNewRoute}>
        {t('settingCustomPayment')}
      </Link>
    </Alert>
  );
}
