import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import {
  BaseCard,
  DataList,
  IconButton,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { ImageItemStratusSkeleton } from 'commons/components';

function OrderSummaryCardSkeleton(): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack>
          <Stack.Item fill>
            <Title type="h3">{`${t('orderSummaryCard.title')}`}</Title>
          </Stack.Item>
          <IconButton.Skeleton />
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body padding="none">
        <DataList ruled={false} spacing="tight">
          <DataList.Row key={1} id="1">
            <DataList.Cell width={70}>
              <ImageItemStratusSkeleton>
                <Stack wrap spacing="tight" align="flex-start" column>
                  <Text.Skeleton size="caption" />
                  <Text.Skeleton />
                </Stack>
              </ImageItemStratusSkeleton>
            </DataList.Cell>
            <DataList.Cell width={30} alignRight>
              <Text.Skeleton />
            </DataList.Cell>
          </DataList.Row>
          <DataList.Row grayed id="subtotal">
            <DataList.Cell width={70}>
              <Text.Skeleton width="large" />
            </DataList.Cell>
            <DataList.Cell width={30} alignRight>
              <Text.Skeleton />
            </DataList.Cell>
          </DataList.Row>
          <DataList.Row id="total" grayed>
            <DataList.Cell width={70}>
              <Text.Skeleton width="large" />
            </DataList.Cell>
            <DataList.Cell width={30} alignRight>
              <Text.Skeleton />
            </DataList.Cell>
          </DataList.Row>
        </DataList>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default OrderSummaryCardSkeleton;
