import { BaseCard, Stack, Text } from '@tiendanube/components';

function TransactionFeeToPaidCard(): JSX.Element {
  return (
    <>
      <BaseCard.Body>
        <Stack spacing="tight" column align="flex-start">
          <Text.Skeleton width="large" />
          <Text.Skeleton width="large" />
          <Text.Skeleton size="featured" />
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Text.Skeleton width="small" />
      </BaseCard.Footer>
    </>
  );
}

export default TransactionFeeToPaidCard;
