import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  ContentTemplateIdType,
  CreateContentPagesRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  createPageAction,
  createPageStatusSelector,
  cleanPageCreationAction,
  getTemplateByIdAction,
  cleanTemplateByIdStatusAction,
  getTemplateByIdStatusSelector,
  getTemplateByIdDataSelector,
  getTemplateIdStatusSelector,
} from '../../contentPagesSlice';

function useCreateContentPages(templateId: ContentTemplateIdType) {
  const dispatch = useAppDispatch();
  const status = useSelector(createPageStatusSelector);
  const statusTemplate = useSelector(getTemplateByIdStatusSelector);
  const initialValues = useSelector(getTemplateByIdDataSelector);
  const id = useSelector(getTemplateIdStatusSelector);
  const hasData = id && templateId === id;

  const getTemplatesById = useCallback(async () => {
    await dispatch(getTemplateByIdAction(templateId));
    dispatch(cleanTemplateByIdStatusAction());
  }, [dispatch, templateId]);

  const createPage = useCallback(
    async (payload: CreateContentPagesRequestDto) => {
      const { id } = unwrapResult(await dispatch(createPageAction(payload)));
      dispatch(cleanPageCreationAction());
      return id;
    },
    [dispatch],
  );

  useEffect(() => {
    if (!hasData) {
      getTemplatesById();
    }
  }, [getTemplatesById, hasData]);

  return {
    createPage,
    initialValues,
    status,
    statusTemplate,
  };
}

export default useCreateContentPages;
