import { Box, Text, Skeleton as NimbusSkeleton } from '@nimbus-ds/components';
import { IconBox } from 'commons/components';
import { BOX_PROPS_PENDING_ORDERS_CARD } from './PendingOrdersCard';

function Skeleton() {
  return (
    <Box {...BOX_PROPS_PENDING_ORDERS_CARD}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" gap="2" alignItems="center">
          <IconBox
            width="36px"
            height="36px"
            borderStyle="solid"
            borderWidth="1"
            borderColor="neutral-interactive"
            backgroundColor="neutral-surface"
          >
            <NimbusSkeleton borderRadius="8px" width="32px" height="32px" />
          </IconBox>
          <Box display="flex" gap="0-5" flexDirection="column">
            <Text.Skeleton width="80px" />
            <Text.Skeleton width="40px" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <NimbusSkeleton borderRadius="4px" width="16px" height="16px" />
        </Box>
      </Box>
    </Box>
  );
}

export default Skeleton;
