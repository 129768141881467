import { Tag, Text, Thumbnail } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import MethodNameAndImageSkeleton from './MethodNameAndImageSkeleton';
import { ASPECT_RATIO_IMAGE, WIDTH_IMAGE } from './utils';
import './MethodNameAndImage.scss';

interface MethodNameAndImageProps {
  image: string;
  name?: string;
  label?: string;
  icon?: JSX.Element;
  isPagoNube?: boolean;
}

function MethodNameAndImage({
  name,
  image,
  label,
  icon,
  isPagoNube,
}: MethodNameAndImageProps) {
  return (
    <div
      className={`stratus--method-name-and-image ${
        isPagoNube && 'pago-nube-method'
      }`}
    >
      <Stack spacing="tight">
        <Thumbnail
          aspectRatio={ASPECT_RATIO_IMAGE}
          width={WIDTH_IMAGE}
          alt={name ?? ''}
          src={image}
        />
        {!!name && <Text fontWeight="bold">{name}</Text>}
        {!!label && (
          <Tag appearance="warning">
            {icon}
            <Text fontSize="caption" color="warning-textLow" lineClamp={1}>
              {label}
            </Text>
          </Tag>
        )}
      </Stack>
    </div>
  );
}

MethodNameAndImage.Skeleton = MethodNameAndImageSkeleton;

export default MethodNameAndImage;
