import { useSelector } from 'react-redux';
import { AdminDisabledReasonsType } from '@tiendanube/common';
import {
  getAdminDisabledReason,
  getIsAlmostExpired,
  getRemainingDaysToExpiration,
  getShowExpirationBanner,
} from 'domains/Auth/authSlice/authSelectors';
import { useAdminDisabled } from 'domains/Auth/hooks';
import { useGetRecurrentPaymentConcepts } from 'domains/Billing/Checkout/hooks';

export function useExpirationBanner() {
  const remainingDaysToExpiration = useSelector(getRemainingDaysToExpiration);
  const showExpirationBanner = useSelector(getShowExpirationBanner);
  const reason = useSelector(getAdminDisabledReason);
  const isAlmostExpired = useSelector(getIsAlmostExpired);

  const { recurrentPaymentForPlanActivated } = useGetRecurrentPaymentConcepts();
  const isDisabled = useAdminDisabled();
  //Although the reason value can only be "expired" or "cancelled", we do this validation because even if the store is not disabled,
  //can return the reason value as "canceled"
  const disabledReason: AdminDisabledReasonsType =
    isDisabled && reason === 'cancelled' ? 'cancelled' : 'expired';

  const isExpired =
    disabledReason === 'expired' &&
    remainingDaysToExpiration !== undefined &&
    remainingDaysToExpiration < 0;

  return {
    disabledReason,
    remainingDaysToExpiration,
    isAlmostExpired,
    showExpirationBanner:
      isAlmostExpired && recurrentPaymentForPlanActivated
        ? false
        : showExpirationBanner,
    isExpired,
  };
}
