import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeleteShippingModalProps {
  title: string;
  description: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirmDelete: () => void;
}
function DeleteShippingModal({
  title,
  description,
  isLoading,
  isOpen,
  onClose,
  onConfirmDelete,
}: DeleteShippingModalProps) {
  const t = useTranslationShipping();

  const cancelButtonText = t(
    'deliveryMethods.deleteShippingModal.buttonCancel',
  );
  const deleteButtonText = t(
    'deliveryMethods.deleteShippingModal.buttondelete',
  );

  return (
    <Modal open={isOpen} onDismiss={onClose}>
      <Modal.Header title={title} />
      <Modal.Body padding="none">
        <Text>{description}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Stack spacing="base" justify="flex-end">
          <CancelAndConfirmButtons
            onCancel={onClose}
            onConfirm={onConfirmDelete}
            isLoading={isLoading}
            isCancelDisabled={isLoading}
            cancelText={cancelButtonText}
            confirmText={deleteButtonText}
            appearance="danger"
          />
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteShippingModal;
