import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import { HeaderContent, IonPageStratus } from 'commons/components';
import { HELP_LINK_MORE_OF_LANGUAGES_CURRENCIES } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  EnabledCountriesCard,
  ExchangeRatesCard,
  MainCountryCard,
  MainCurrencyCard,
  AddMoreCurrenciesCard,
} from './components';

function I18nPage() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const language = useTranslationLanguage();

  return (
    <IonPageStratus
      width="small"
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      <EnabledCountriesCard />
      <MainCountryCard />
      <MainCurrencyCard />
      <ExchangeRatesCard />
      <AddMoreCurrenciesCard />
      <Box paddingTop="6">
        <HelpLink
          title={t('helpLinks.moreOfCurrencyAndLanguage')}
          linkURL={HELP_LINK_MORE_OF_LANGUAGES_CURRENCIES[language]}
          previousValue=""
          currentViewTracking={t('helpLinks.moreOfCurrencyAmplitude')}
          icon="both"
        />
      </Box>
    </IonPageStratus>
  );
}

export default I18nPage;
