import { useState, useEffect, useCallback } from 'react';
import {
  NavigationMenuItemResponseDto,
  NavigationMenuItemType,
  NavigationMenuItemDataDto,
} from '@tiendanube/common';
import { LanguageInterface } from 'App/i18n';

const EMPTY_NAME = {
  en: '',
  es: '',
  pt: '',
};

const setInitialCustomDataByType = (
  itemType: NavigationMenuItemType,
): NavigationMenuItemDataDto => {
  if (itemType === 'specialpage-home_url') {
    return { page: 'home_url' };
  }

  if (itemType === 'specialpage-contact_form_url') {
    return { page: 'contact_form_url' };
  }

  if (itemType === 'specialpage-blog_url') {
    return { page: 'blog_url' };
  }

  if (itemType === 'url') {
    return { url: '' };
  }

  return { id: '' };
};

function useMenuItemForm(item?: NavigationMenuItemResponseDto) {
  const [name, setName] = useState<LanguageInterface>(item?.name ?? EMPTY_NAME);
  const [selected, setSelected] = useState<NavigationMenuItemType>(
    'specialpage-home_url',
  );
  const [customData, setCustomData] = useState<NavigationMenuItemDataDto>({
    page: 'contact_form_url',
  });

  const onChangeName = (name: LanguageInterface) => setName(name);

  const onSelect = useCallback((selected: NavigationMenuItemType) => {
    setSelected(selected);
    setCustomData(setInitialCustomDataByType(selected));
  }, []);

  const onSelectSubtype = useCallback((data: NavigationMenuItemDataDto) => {
    setCustomData(data);
  }, []);

  const onChangeUrl = useCallback((value: string) => {
    setCustomData({ url: value });
  }, []);

  const cleanValues = useCallback(() => {
    setName(EMPTY_NAME);
    setSelected('specialpage-home_url');
    setCustomData({
      page: 'home_url',
    });
  }, []);

  useEffect(() => {
    if (item) {
      onChangeName(item.name);
      setSelected(item.type);
      setCustomData(item.data);
    } else {
      cleanValues();
    }
  }, [item, cleanValues]);

  return {
    name,
    selected,
    customData,
    onChangeName,
    onSelect,
    onSelectSubtype,
    onChangeUrl,
    cleanValues,
  };
}

export default useMenuItemForm;
