import { memo } from 'react';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { MainMenu, AccountMenu, SettingsMenu, HelpMenu } from './components';
import './Menu.scss';

const PATH = {
  settings: '/settings',
  account: '/account',
};

interface MenuProps {
  device: 'mobile' | 'desktop';
}

function Menu({ device }: MenuProps): JSX.Element {
  const { pathname } = useNavegate();
  const { isMobile: isMobileResponsive } = useResponsive();
  const [isVisibleSupport, showVisibleSupport, hiddenVisibleSupport] =
    useModal();

  const isVisibleSettings = pathname.startsWith(PATH.settings);
  const isVisibleAccount = pathname.startsWith(PATH.account);

  return (
    <>
      <MainMenu device={device} onClickSupport={showVisibleSupport} />
      <HelpMenu onClose={hiddenVisibleSupport} visible={isVisibleSupport} />
      <AccountMenu
        visible={isMobileResponsive && isVisibleAccount}
        device={device}
      />
      <SettingsMenu visible={isVisibleSettings} />
    </>
  );
}

export default memo(Menu);
