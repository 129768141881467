import { Alert, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { openWindow } from 'commons/utils/window';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface DraftPublishedAlertProps {
  show: boolean;
  onRemove: () => void;
}

export function DraftPublishedAlert({
  show,
  onRemove,
}: Readonly<DraftPublishedAlertProps>) {
  const t = useTranslationOnline(
    'themesPage.draft.actions.draftPublishedAlert',
  );
  const { url } = useHeaderMenuData();

  const handleClickGoToStore = () => openWindow(url, true);

  return (
    <Alert
      show={show}
      appearance="success"
      title={t('title')}
      onRemove={onRemove}
    >
      <Stack column align="flex-start">
        <Text>{t('body')}</Text>
        <Link onClick={handleClickGoToStore}>
          {t('link')} <ExternalLinkIcon />
        </Link>
      </Stack>
    </Alert>
  );
}
