import { ChangeEvent, useEffect, useState } from 'react';
import { Alert, Modal } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { toStatusType } from 'App/rtk';
import { CancelAndConfirmButtons } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { usePostCheckoutCodeRequestMutation } from 'domains/Configurations/ExternalCodes/externalCodesApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface RequestCheckoutScriptApprovalModalProps {
  show: boolean;
  onClose: () => void;
  previousScript: string;
}

function RequestCheckoutScriptApprovalModal({
  show,
  onClose,
  previousScript,
}: Readonly<RequestCheckoutScriptApprovalModalProps>) {
  const [script, setScript] = useState(previousScript);

  const t = useTranslationConfigurations(
    'externalCodes.conversionCodes.trackingCodesTabs.forCheckout.modal',
  );

  const handleChangeTextArea = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setScript(value);
  };
  const [onSubmit, { isLoading, status }] =
    usePostCheckoutCodeRequestMutation();

  const handleSubmit = (
    e?: React.SyntheticEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e?.preventDefault();
    onSubmit({ script });
  };

  useToastStatus({
    status: toStatusType(status),
    success: t('toast.success'),
    error: t('toast.error'),
  });

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      onClose();
    }
  }, [status, onClose]);

  return (
    <Modal open={show} onDismiss={onClose}>
      <Modal.Header title={t('title')} />
      <Alert appearance="warning" title={t('alert.title')}>
        {t('alert.content')}
      </Alert>
      <FormField.Textarea
        id="script"
        name="script"
        label={t('inputLabel')}
        value={script}
        onChange={handleChangeTextArea}
        autoFocus
        lines={6}
      />
      <Modal.Footer>
        <CancelAndConfirmButtons
          isLoading={isLoading}
          isCancelDisabled={isLoading}
          confirmText={t('buttonConfirm')}
          onCancel={onClose}
          onConfirm={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default RequestCheckoutScriptApprovalModal;
