import { useSelector } from 'react-redux';
import {
  getCustomerMessagesIds,
  getCustomerMessagesStatus,
  getCustomerOrdersIds,
  getCustomerOrdersPagination,
  getCustomerOrdersStatus,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseCustomerActivityResult {
  isErrorMessages: boolean;
  isLoadingMessages: boolean;
  isSuccessMessages: boolean;
  isErrorOrders: boolean;
  isLoadingOrders: boolean;
  isSuccessOrders: boolean;
  customerMessagesIds: string[];
  customerOrdersIds: string[];
  customerOrdersQuantity: number;
}

function useCustomerActivity(): UseCustomerActivityResult {
  const {
    isError: isErrorMessages,
    isLoading: isLoadingMessages,
    isSuccess: isSuccessMessages,
  } = useSelector(getCustomerMessagesStatus);
  const {
    isError: isErrorOrders,
    isLoading: isLoadingOrders,
    isSuccess: isSuccessOrders,
  } = useSelector(getCustomerOrdersStatus);
  const customerMessagesIds = useSelector(getCustomerMessagesIds);
  const customerOrdersIds = useSelector(getCustomerOrdersIds);
  const customerOrdersPagination = useSelector(getCustomerOrdersPagination);

  return {
    isErrorMessages,
    isLoadingMessages,
    isSuccessMessages,
    isErrorOrders,
    isLoadingOrders,
    isSuccessOrders,
    customerMessagesIds,
    customerOrdersIds,
    customerOrdersQuantity: customerOrdersPagination?.totalResults,
  };
}

export default useCustomerActivity;
