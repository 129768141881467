import { Text } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import { DataList, Link } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useTransactionListItem } from '../../../../hooks';
import { formatDate } from '../../../utils';

const { Row, Cell } = DataList;

interface TransactionListItemProps {
  id: string;
}

function TransactionListItem({ id }: TransactionListItemProps): JSX.Element {
  const { push } = useHistory();
  const { transaction } = useTransactionListItem(id);
  const t = useTranslationBilling();

  // TODO Check the correct url for this
  const goToOrderDetails = () => {
    push(`/orders/${transaction.order}`);
  };

  return (
    <Row key={id} id={id}>
      <Cell width={70}>
        <Text color="primary-textLow">
          <Link appearance="primary" onClick={goToOrderDetails}>
            {`#${transaction.order}`}
          </Link>
        </Text>
      </Cell>
      <Cell width={30} alignRight>
        <Text color="neutral-textLow" as="span">{`${formatDate(
          transaction.date,
        )}`}</Text>
      </Cell>
      <Cell width={70}>
        <Text color="neutral-textLow">
          {`${t('transactionFeesList.mobile.total')}`}
        </Text>
      </Cell>
      <Cell width={30} alignRight>
        <Text color="neutral-textLow" as="span">
          <CurrencyPrice
            price={transaction.total}
            currency={transaction.totalCurrency}
          />
        </Text>
      </Cell>
      <Cell width={70}>
        <Text color="primary-textLow">
          {`${t('transactionFeesList.mobile.cost')}`}
          {!!transaction.rate && ` (${transaction.rate}%)`}
        </Text>
      </Cell>
      <Cell width={30} alignRight>
        <Text color="primary-textLow" as="span">
          <CurrencyPrice
            price={transaction.amount}
            currency={transaction.amountCurrency}
          />
        </Text>
      </Cell>
      <Cell width={100} />
    </Row>
  );
}

export default TransactionListItem;
