import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  NUVEM_ENVIO_CLICK,
  SHIPPING_APPS_MORE_CLICK,
  SHIPPING_DISTRIBUTION_CENTER_DEFINE,
  SHIPPING_DISTRIBUTION_CENTER_ADD,
  SHIPPING_VIEWED_METHOD,
  SHIPPING_DISTRIBUTION_CENTER_CREATED_NAVIGATION,
  SHIPPING_ADD_DISTRIBUTION_CENTER,
  SHIPPING_EDIT_DISTRIBUTION_CENTER,
  SHIPPING_UPGRADE_MODAL_SEE_PLANS_CLICK,
  SHIPPING_DISTRIBUTION_CENTER_NOW_ALLOWED,
  SHIPPING_UPGRADE_CARD_SEE_PLANS_CLICK,
  SHIPPING_NAVIGATE_TO_DISTRIBUTION_CENTER,
  SHIPPING_CREATE_SECONDARY_CD_BY_ADDRESSING_RULE_CARD,
  SHIPPING_CHANGE_ADDRESSING_RULE,
  SHIPPING_OPEN_ADDRESSING_RULE_MODAL,
  SHIPPING_CLOSE_ADDRESSING_RULE_MODAL,
  SHIPPING_SAVED_METHODS,
  SHIPPING_ENABLE_MULTICD_BY_CD,
} from 'config/trackingEvents';

const OPEN_CONFIGURATION_FROM_NUVEM_ENVIO_MODAL =
  'Open configuration from Nuvem Envio modal';
const CLOSE_CONFIGURATION_FROM_NUVEM_ENVIO_MODAL =
  'Close configuration from Nuvem Envio modal';

export const trackShippingDistributionCenterDefine = (
  origin: 'locations' | 'deliveryMethods',
) => {
  logEvent(SHIPPING_DISTRIBUTION_CENTER_DEFINE, {
    origin: origin,
  });
};

export const trackingShippingDistributionCenterAdd = (
  type: 'main' | 'secondary',
) => {
  logEvent(SHIPPING_DISTRIBUTION_CENTER_ADD, {
    type: type,
  });
};

export const trackingShippingDistributionCenterCreatedNavigation = (
  destiny: 'locations' | 'deliveryMethods' | 'comingSoon',
) => {
  logEvent(SHIPPING_DISTRIBUTION_CENTER_CREATED_NAVIGATION, {
    destiny: destiny,
  });
};

export const trackingShippingAddDistributionCenter = (
  creationStatus: 'enabled' | 'disabled' | 'multicd-unavailable',
  type: 'main' | 'secondary',
) => {
  logEvent(SHIPPING_ADD_DISTRIBUTION_CENTER, {
    creationStatus: creationStatus,
    type: type,
  });
};

export const trackingEnableMultiCdByCdPage = (action: 'confirm' | 'cancel') => {
  logEvent(SHIPPING_ENABLE_MULTICD_BY_CD, {
    action: action,
  });
};

export const trackingShippingEditDistributionCenter = (
  type: 'main' | 'secondary',
  locationId: string,
) => {
  logEvent(SHIPPING_EDIT_DISTRIBUTION_CENTER, {
    type: type,
    locationId: locationId,
  });
};

export const trackingShippingUpgradeModalSeePlansClick = (
  destiny: 'plans' | 'dismissed',
  limitOfLocations: string,
) => {
  logEvent(SHIPPING_UPGRADE_MODAL_SEE_PLANS_CLICK, {
    destiny: destiny,
    limitOfLocations: limitOfLocations,
  });
};

export const trackingShippingUpgradeCardSeePlansClick = (
  origin: 'card' | 'alert',
) => {
  logEvent(SHIPPING_UPGRADE_CARD_SEE_PLANS_CLICK, {
    origin: origin,
  });
};

export const trackingShippingDistributionCenterNowAllowed = (
  origin: 'card' | 'button',
) => {
  logEvent(SHIPPING_DISTRIBUTION_CENTER_NOW_ALLOWED, {
    origin: origin,
  });
};

export const trackingShippingViewedMethod = (
  appId: string,
  appName: string,
) => {
  logEvent(SHIPPING_VIEWED_METHOD, {
    appId: appId,
    method: appName,
  });
};

export const trackingShippingAppsMoreClick = (
  appId: string,
  appName: string,
) => {
  logEvent(SHIPPING_APPS_MORE_CLICK, {
    appId: appId,
    method: appName,
  });
};

export const trackingShippingNavigateToCD = () => {
  logEvent(SHIPPING_NAVIGATE_TO_DISTRIBUTION_CENTER, {});
};

export const trackingShippinCreateSecondaryCDByAddressingRuleCard = (
  destiny: 'locations' | 'plans' | 'comingSoon' | 'multicd-unavailable',
) => {
  logEvent(SHIPPING_CREATE_SECONDARY_CD_BY_ADDRESSING_RULE_CARD, {
    destiny: destiny,
  });
};

export const trackingShippingOpenAddressingRule = (
  rules: AddressingConfigurationRuleResponseDto[],
) => {
  logEvent(SHIPPING_OPEN_ADDRESSING_RULE_MODAL, {
    rules: JSON.stringify(rules),
  });
};

export const trackingShippingChangeAddressingRule = (
  rules: AddressingConfigurationRuleResponseDto[],
  type: 'materialization' | 'priority' | 'add' | 'remove',
) => {
  logEvent(SHIPPING_CHANGE_ADDRESSING_RULE, {
    rules: JSON.stringify(rules),
    type: type,
  });
};

export const trackingShippingCloseAddressingRule = (
  rules: AddressingConfigurationRuleResponseDto[],
  type: 'close' | 'save',
) => {
  logEvent(SHIPPING_CLOSE_ADDRESSING_RULE_MODAL, {
    rules: JSON.stringify(rules),
    type: type,
  });
};

export const trackingNuvemEnvioModalOpened = (storeId: string) => {
  logEvent(NUVEM_ENVIO_CLICK, {
    storeId: storeId,
    action: OPEN_CONFIGURATION_FROM_NUVEM_ENVIO_MODAL,
  });
};

export const trackingNuvemEnvioModalClosed = (storeId: string) => {
  logEvent(NUVEM_ENVIO_CLICK, {
    storeId: storeId,
    action: CLOSE_CONFIGURATION_FROM_NUVEM_ENVIO_MODAL,
  });
};

export const trackingShippingModalities = (
  storeId: string,
  appName: string,
  modalities: string,
) => {
  const trackingAllowedApps = ['Nuvem Envio'];

  if (trackingAllowedApps.includes(appName)) {
    logEvent(SHIPPING_SAVED_METHODS, {
      storeId,
      appName,
      modalities,
    });
  }
};
