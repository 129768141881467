/**
 * Returns an array of unique random elements from the input array.
 * @param array The input array to choose random elements from.
 * @param count The number of random elements to return.
 * @returns An array of unique random elements from the input array.
 */

export function getRandomElements<T>(array: T[], count = 3): T[] {
  const result: T[] = [];
  const usedIndexes: number[] = [];

  while (result.length < count && result.length < array.length) {
    const randomIndex = Math.floor(Math.random() * array.length);

    if (!usedIndexes.includes(randomIndex)) {
      result.push(array[randomIndex]);
      usedIndexes.push(randomIndex);
    }
  }

  return result;
}
