import { Text } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeliveryCartWeightInfoProps {
  cartWeightMin?: string;
  cartWeightMax?: string;
}

function DeliveryCartWeightInfo({
  cartWeightMin,
  cartWeightMax,
}: Readonly<DeliveryCartWeightInfoProps>) {
  const t = useTranslationShipping();

  return (
    <Text>
      {!!cartWeightMax &&
        !!cartWeightMin &&
        t('deliveryMethods.newInformationShipping.weightRange', {
          cartWeightMin,
          cartWeightMax,
        })}
      {!cartWeightMax &&
        !!cartWeightMin &&
        t('deliveryMethods.newInformationShipping.weightWithoutMax', {
          cartWeightMin,
        })}
      {!!cartWeightMax &&
        !cartWeightMin &&
        t('deliveryMethods.newInformationShipping.weightWithoutMin', {
          cartWeightMax,
        })}
    </Text>
  );
}

export default DeliveryCartWeightInfo;
