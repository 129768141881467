import {
  NotificationDetailsDto,
  NotificationsListResponseDto,
  SendEmailValidationResponseDto,
  SetMailFromResponseDto,
  VerifyInmailRecordResponseDto,
  VerifyValidationEmailResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  BASE_NOTIFICATIONS_URL,
  EMAIL_BY_ID,
  SEND_EMAIL_VALIDATION,
  SET_MAIL_FROM,
  VERIFY_INMAIL_RECORD,
  VERIFY_VALIDATION_EMAIL,
  notificationsEndpoints,
  notificationsTags,
} from './config';

export const notificationsApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [notificationsTags.emailsList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [notificationsEndpoints.getEmailsList]: builder.query<
        NotificationsListResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_NOTIFICATIONS_URL,
        }),
        providesTags: [notificationsTags.emailsList],
      }),
      [notificationsEndpoints.getEmail]: builder.query<
        NotificationDetailsDto,
        string
      >({
        query: (id) => ({
          method: 'GET',
          url: getUrlWithParams(EMAIL_BY_ID, { id }),
        }),
      }),
      [notificationsEndpoints.verifyInmailRecord]: builder.query<
        VerifyInmailRecordResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: VERIFY_INMAIL_RECORD,
        }),
      }),
      [notificationsEndpoints.updateEmail]: builder.mutation<
        NotificationDetailsDto,
        {
          id: string;
          payload: {
            data: NotificationDetailsDto;
            initialValues: NotificationDetailsDto;
          };
        }
      >({
        query: ({ id, payload }) => ({
          method: 'PUT',
          url: getUrlWithParams(EMAIL_BY_ID, { id }),
          data: payload,
        }),
      }),
      [notificationsEndpoints.sendEmailValidation]: builder.mutation<
        SendEmailValidationResponseDto,
        void
      >({
        query: () => ({
          method: 'POST',
          url: SEND_EMAIL_VALIDATION,
        }),
      }),
      [notificationsEndpoints.verifyValidationEmail]: builder.mutation<
        VerifyValidationEmailResponseDto,
        void
      >({
        query: () => ({
          method: 'POST',
          url: VERIFY_VALIDATION_EMAIL,
        }),
      }),
      [notificationsEndpoints.setMailFrom]: builder.mutation<
        SetMailFromResponseDto,
        void
      >({
        query: () => ({
          method: 'POST',
          url: SET_MAIL_FROM,
        }),
      }),
    }),
  });

export const {
  useGetEmailsListQuery,
  useGetEmailQuery,
  useLazyVerifyInmailRecordQuery,
  useUpdateEmailMutation,
  useSendEmailValidationMutation,
  useVerifyValidationEmailMutation,
  useSetMailFromMutation,
} = notificationsApi;
