import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import { useNavegate } from 'App/hooks';
import { useToastStatus } from 'commons/hooks';
import { StatusType } from 'commons/types';
import {
  PAYMENTS_BASE_ROUTE,
  PAYMENTS_ROUTES,
} from 'domains/Payments/paymentsRoutes';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import useEnableInstalledPaymentProvider from '../useEnableInstalledPaymentProvider';

interface UseEnableInstalledPaymentProviderResult {
  isEnableAction: boolean;
  updateStatus: StatusType;
}

function useEnableMercadoPago(): UseEnableInstalledPaymentProviderResult {
  const t = useTranslationPayments();
  const { pathname, search } = useLocation();
  const { hasSearchParam, replace } = useNavegate();
  const { enable, updateStatus } = useEnableInstalledPaymentProvider(
    NativePaymentProviders.mercadoPago,
  );

  const isEnableAction = hasSearchParam('code') && hasSearchParam('state');
  const isUpdateAction = pathname.endsWith(PAYMENTS_ROUTES.mercadoPagoSettings);

  const onFinished = useCallback(() => {
    if (!isUpdateAction) replace(PAYMENTS_BASE_ROUTE);
    if (isUpdateAction) replace(PAYMENTS_ROUTES.mercadoPagoSettings);
  }, [isUpdateAction, replace]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (code && state) enable({ code, state });
  }, [enable, search]);

  useToastStatus({
    status: isEnableAction ? updateStatus : 'idle',
    error: t(
      !isUpdateAction
        ? 'paymentProvider.actionModal.errorEnable'
        : 'paymentProvider.settings.mercadopago.account.changeUserError',
    ),
    success: t(
      !isUpdateAction
        ? 'paymentProvider.actionModal.successEnable'
        : 'paymentProvider.settings.mercadopago.account.changeUserSuccess',
    ),
    onSuccess: onFinished,
    onError: onFinished,
  });

  return {
    isEnableAction,
    updateStatus,
  };
}

export default useEnableMercadoPago;
