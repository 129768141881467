import { Box, Thumbnail, Text } from '@nimbus-ds/components';

function Skeleton() {
  return (
    <Box display="flex" gap="3-5">
      <Box>
        <Thumbnail.Skeleton width="48px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="2">
        <Text.Skeleton fontSize="caption" width="150px" />
        <Text.Skeleton fontSize="caption" width="80px" />
        <Text.Skeleton fontSize="caption" width="100px" />
      </Box>
    </Box>
  );
}

export default Skeleton;
