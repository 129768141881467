export const initDirty = { step1: false, step2: false, step3: false };

export const defaultValues = {
  id: '',
  sender: {
    name: '',
    surname: '',
    companyName: '',
    cuit: '',
    zipcode: '',
  },
  originAddress: {
    street: '',
    number: '',
    flat: '',
    apartment: '',
    locality: '',
    province: 'Capital Federal',
  },
  account: {
    user: '',
    password: '',
    numberAccount: '',
  },
  operational: {
    door: '',
    store: '',
    storeToDoor: '',
    storeToStore: '',
    agency: {
      code: '',
      address: '',
    },
    defaultOrigin: 'door',
  },
  allowFreeShipping: false,
  additionalCost: 0,
  additionalDays: 0,
};
