import { Route, Switch } from 'react-router';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { EditNotificationPage, NotificationsListPage } from './pages';
import { configurationsRoutes } from '../../Configurations';

function Notifications(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();

  if (isMobileDevice)
    return (
      <Switch>
        <Route path={configurationsRoutes.notifications} exact>
          <NotificationsListPage />
        </Route>
        <Route path={configurationsRoutes.notificationsForm}>
          <EditNotificationPage />
        </Route>
      </Switch>
    );

  return (
    <Switch>
      <Route path={configurationsRoutes.notifications} exact>
        <ToggleMigratedOldDomain domain="notifications">
          <NotificationsListPage />
        </ToggleMigratedOldDomain>
      </Route>
      <Route path={configurationsRoutes.notificationsForm}>
        <ToggleMigratedOldDomain domain="notifications">
          <EditNotificationPage />
        </ToggleMigratedOldDomain>
      </Route>
    </Switch>
  );
}

export default Notifications;
