import { useCallback } from 'react';
import { ImageGalleryState } from 'domains/Catalog/Products/pages/components/ImageGallery/ImageGallery';
import useEditImage from './useEditImage';
import { useLazySmartEraseQuery } from '../aiApi';

function useSmartErase(initialImage: ImageGalleryState) {
  const [getData, { data, isError, isFetching }] = useLazySmartEraseQuery();
  const { displayedImage, validImageUrl } = useEditImage(
    initialImage,
    data?.imageBase64,
  );

  const handleSmartErase = useCallback(
    async (maskBase64: string) => {
      const imageParam = displayedImage.base64
        ? { imageBase64: displayedImage.base64 }
        : { imageUrl: validImageUrl };

      await getData({
        ...imageParam,
        maskBase64,
      });
    },
    [displayedImage.base64, getData, validImageUrl],
  );

  return {
    changingBackground: isFetching,
    errorOnBackgroundChange: isError,
    unSavedChanges: data !== undefined,
    displayedImage,
    handleSmartErase,
  } as const;
}

export default useSmartErase;
