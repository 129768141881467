import { Button, Box, Card, Icon, Text } from '@nimbus-ds/components';
import { CopyIcon, DownloadIcon } from '@nimbus-ds/icons';
import { RecoveryCodesResponseDto } from '@tiendanube/common';
import { Stack, useResponsive } from 'commons/components';
import { useClipboard } from 'commons/hooks';
import downloadBlob from 'commons/utils/downloadBlob';
import { useTranslationAccount } from 'domains/Account/hooks';
import './RecoveryCodesCard.scss';
import {
  trackingRecoveryCodesCopied,
  trackingRecoveryCodesDownloaded,
} from 'domains/Account/tracking';
import RecoveryCodesCardSkeleton from './RecoveryCodesCardSkeleton';

function codesString(recoveryCodes: RecoveryCodesResponseDto[]) {
  return recoveryCodes
    .filter(({ usedAt }) => !usedAt)
    .map(({ recoveryCode }) => recoveryCode)
    .join('\n');
}

interface RecoveryCodesCardProps {
  recoveryCodes: RecoveryCodesResponseDto[];
  showDescription?: boolean;
}

function RecoveryCodesCard({
  recoveryCodes,
  showDescription,
}: Readonly<RecoveryCodesCardProps>): JSX.Element {
  const t = useTranslationAccount('authenticationFactorPage.recoveryCodesPage');
  const copyToClipboard = useClipboard();
  const { isDesktop } = useResponsive();

  const copyCodes = () => {
    trackingRecoveryCodesCopied();
    copyToClipboard(codesString(recoveryCodes), t('codesCopied'));
  };
  const downloadCodes = () => {
    trackingRecoveryCodesDownloaded();
    downloadBlob('recovery_codes.txt', codesString(recoveryCodes));
  };

  const sortedRecoveryCodes = recoveryCodes
    .filter(({ usedAt }) => !usedAt)
    .concat(recoveryCodes.filter(({ usedAt }) => usedAt));

  return (
    <Card>
      <Card.Body>
        <Stack column>
          {showDescription && <Text>{t('description')}</Text>}
          <div
            className={`stratus--recovery-codes__grid ${
              isDesktop ? 'stratus--recovery-codes__grid--desktop' : ''
            }`}
          >
            {sortedRecoveryCodes.map(({ recoveryCode, usedAt }) => (
              <Box
                key={recoveryCode}
                backgroundColor="neutral-surfaceDisabled"
                borderRadius="base"
                padding="2"
              >
                <Text
                  textAlign="center"
                  color={usedAt ? 'neutral-textDisabled' : 'neutral-textHigh'}
                >
                  {recoveryCode} {!!usedAt && `(${t('used')})`}
                </Text>
              </Box>
            ))}
          </div>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Button appearance="neutral" onClick={copyCodes}>
          <Icon source={<CopyIcon />} />
          {t('copy')}
        </Button>
        <Button appearance="primary" onClick={downloadCodes}>
          <Icon source={<DownloadIcon />} color="neutral-background" />
          {t('download')}
        </Button>
      </Card.Footer>
    </Card>
  );
}

RecoveryCodesCard.Skeleton = RecoveryCodesCardSkeleton;

export default RecoveryCodesCard;
