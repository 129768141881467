import { DataList, Text, Stack, IconButton } from '@tiendanube/components';

const { Row, Cell } = DataList;

function CustomerListItemSkeleton(): JSX.Element {
  return (
    <Row id="id">
      <Cell rowTitle width={70} trimText>
        <Text.Skeleton width="large" />
      </Cell>
      <Cell width={30} alignRight>
        <Stack>
          <Stack.Item fill>
            <IconButton.Skeleton />
          </Stack.Item>
          <Stack.Item fill>
            <IconButton.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell width={30}>
        <Text.Skeleton />
      </Cell>
      <Cell width={70} alignRight>
        <Text.Skeleton width="large" />
      </Cell>
    </Row>
  );
}

export default CustomerListItemSkeleton;
