import { Stack } from 'commons/components';
import { ActiveShippingMethodsCard, FallbackCard } from '../../../components';
import AddressingConfigurationInfoCard from '../AddressingConfiguration/Card';
import CustomShippingCard from '../CustomShippingCard';

interface NationalShippingMethodsProps {
  showConfigurationInfoCard: boolean;
  isLoadingConfigurationInfoCard: boolean;
}

function NationalShippingMethods({
  showConfigurationInfoCard,
  isLoadingConfigurationInfoCard,
}: Readonly<NationalShippingMethodsProps>) {
  return (
    <Stack column spacing="loose">
      <FallbackCard />
      <ActiveShippingMethodsCard />
      <CustomShippingCard />
      {isLoadingConfigurationInfoCard && (
        <AddressingConfigurationInfoCard.Skeleton />
      )}
      {showConfigurationInfoCard && <AddressingConfigurationInfoCard />}
    </Stack>
  );
}

export default NationalShippingMethods;
