import { ChangeEventHandler, useState } from 'react';
import { Button, Icon, Popover, Radio } from '@nimbus-ds/components';
import { ArrowsVerticalIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { useResponsive } from 'commons/components';
import './SortByPopover.scss';

interface OnChangeValue<T> {
  value: T;
}

interface ListOption<T> {
  label: string;
  value: T;
}

interface SortByPopoverProps<T> {
  listOptions: ListOption<T>[];
  valueOption: ListOption<T>;
  onChange: ({ value }: OnChangeValue<T>) => void;
}

function SortByPopover<T extends string>({
  listOptions,
  valueOption,
  onChange,
}: Readonly<SortByPopoverProps<T>>) {
  const [visible, setVisible] = useState(false);

  const { isDesktop } = useResponsive();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange({ value: event.target.value as T });
    setTimeout(() => {
      setVisible(false);
    }, 150);
  };

  const styleResponsiveButton = isDesktop
    ? 'stratus--popover-button-text-inline'
    : '';

  return (
    <div className={styleResponsiveButton}>
      <Popover
        data-style="popover-nopadding-content"
        visible={visible}
        onVisibility={setVisible}
        arrow={false}
        position="bottom"
        content={
          <div className="stratus--content-data-list">
            <DataList>
              {listOptions.map((optionSelect) => (
                <DataList.Row key={optionSelect.value} gap="4">
                  <Radio
                    id={`sortBy-${optionSelect.value}`}
                    name="sortBy"
                    label={optionSelect.label}
                    value={optionSelect.value}
                    onChange={handleChange}
                    checked={optionSelect.value === valueOption.value}
                  />
                </DataList.Row>
              ))}
            </DataList>
          </div>
        }
      >
        <Button>
          <Icon source={<ArrowsVerticalIcon />} />
          {isDesktop && valueOption.label}
        </Button>
      </Popover>
    </div>
  );
}

export default SortByPopover;
