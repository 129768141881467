import { DataList } from '@tiendanube/components';
import { MessagesListItemSkeleton } from 'domains/Customers/components';

function CouponListMobileSkeleton(): JSX.Element {
  return (
    <DataList>
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
      <MessagesListItemSkeleton />
    </DataList>
  );
}

export default CouponListMobileSkeleton;
