import { Alert, Text, Box, List } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

const productListLimit = 10;

interface InsufficientStockProducts {
  id: string;
  title: string;
  variants: string;
}
interface InsufficientStockAlertProps {
  products: InsufficientStockProducts[];
  show: boolean;
}
function InsufficientStockAlert({
  products,
  show,
}: InsufficientStockAlertProps): JSX.Element {
  const t = useTranslationOrders();
  const showItemsAsList = products.length < productListLimit;

  return showItemsAsList ? (
    <Alert
      title={t('detail.insufficientStock.insufficientStock')}
      appearance="warning"
      show={show}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <List>
          {products.map((product) => (
            <List.Item key={product.id}>
              <Text>
                {product.title}
                {product.variants ? ` / ${product.variants}` : ''}
              </Text>
            </List.Item>
          ))}
        </List>
      </Box>
    </Alert>
  ) : (
    <Alert
      title={t('detail.insufficientStock.insufficientStock_plural')}
      appearance="warning"
      show={show}
    >
      <Box />
    </Alert>
  );
}

export default InsufficientStockAlert;
