import { MetafieldV2Dto } from '@tiendanube/common';
import { StatusObjectType } from 'commons/types';
import { MetafieldsFilters } from 'domains/Metafields/components';
import {
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
} from 'domains/Metafields/types';

interface MetafieldsVariantsFiltersProps {
  selecteds: MetafieldSelectedInterface[];
  metafields: MetafieldV2Dto[] | null;
  status: StatusObjectType;
  fetchMetafields: () => void;
  onChange: OnChangeMetafieldType;
}

function MetafieldsVariantsFilters({
  metafields,
  status,
  fetchMetafields,
  selecteds,
  onChange,
}: MetafieldsVariantsFiltersProps): JSX.Element | null {
  return (
    <MetafieldsFilters
      metafields={metafields || []}
      status={status}
      selecteds={selecteds}
      onChange={onChange}
      onFetch={fetchMetafields}
    />
  );
}

export default MetafieldsVariantsFilters;
