import { Box, Button, Link } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { TRANSACTION_FEES_ROUTES } from '../../transactionFeesRoutes';

interface GoToPayButtonProps {
  onClick?: () => void;
}

function GoToPayButton({ onClick }: GoToPayButtonProps) {
  const t = useTranslationBilling('transactionFeesPaymentAlert');
  const isMobileDevice = useIsMobileDevice();

  return (
    <Box display="flex" flexDirection="row" gap="4">
      {!isMobileDevice && (
        <Button
          as={LinkRoute}
          appearance="primary"
          to={TRANSACTION_FEES_ROUTES.transactionFees}
          onClick={onClick}
        >
          {t('goToPay')}
        </Button>
      )}
      <Link target="_blank" as="a" href={t('helpLink')}>
        {t('helpLinkText')}
      </Link>
    </Box>
  );
}

export default GoToPayButton;
