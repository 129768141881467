import { ReactElement } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { addOpacityToHexColor } from 'commons/utils/addOpacityToHexColor';
import {
  BAR_CHART_HEIGTH,
  FONT_SIZE_BAR_CHART,
  abbreviateNumber,
  MARGIN_BAR_CHART_TOTAL,
} from 'domains/Statistics/utils';
import LegendStratus from '../LegendStratus';
import TooltipChartStratus from '../TooltipChartStratus';

export interface BarChartInterface {
  name: string;
  principalValue: number;
  secondaryValue?: number;
  secondaryName?: string;
}

interface BarChartStratusProps {
  values: BarChartInterface[];
  primaryColor: string;
  secondaryColor: string;
  customTooltipContent?: ReactElement;
  cutomLegendContent?: ReactElement;
  oneYAxis: boolean;
  xAxisFormatter?: (value: string) => string;
  secondaryColorOpacity?: boolean;
}

function BarChartStratus({
  values,
  primaryColor,
  secondaryColor,
  secondaryColorOpacity = false, // We force the opacity with this prop since it does not apply for all cases
  customTooltipContent,
  cutomLegendContent,
  oneYAxis = false,
  xAxisFormatter,
}: BarChartStratusProps) {
  const hasSecondary = values.some((value) => value.secondaryValue);
  const marginRight = oneYAxis ? MARGIN_BAR_CHART_TOTAL : 0;
  return (
    <ResponsiveContainer width="100%" height={BAR_CHART_HEIGTH}>
      <BarChart
        data={values}
        margin={{ right: marginRight }}
        style={{ fontSize: FONT_SIZE_BAR_CHART }}
        maxBarSize={values.length > 8 ? 12 : undefined}
      >
        <CartesianGrid vertical={false} strokeDasharray="2 0" />
        <XAxis dataKey="name" tickFormatter={xAxisFormatter} />
        <YAxis
          yAxisId="left"
          type="number"
          tickFormatter={abbreviateNumber}
          axisLine={false}
          tickLine={false}
        />
        {!oneYAxis && (
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={abbreviateNumber}
            axisLine={false}
            tickLine={false}
          />
        )}
        {cutomLegendContent && (
          <Legend
            content={<LegendStratus>{cutomLegendContent}</LegendStratus>}
          />
        )}
        {customTooltipContent && (
          <Tooltip
            trigger="hover"
            content={
              <TooltipChartStratus>{customTooltipContent}</TooltipChartStratus>
            }
          />
        )}
        <Bar dataKey="principalValue" fill={primaryColor} yAxisId="left" />
        {hasSecondary && (
          <Bar
            dataKey="secondaryValue"
            stroke={secondaryColor}
            strokeWidth={1}
            yAxisId={oneYAxis ? 'left' : 'right'}
            /*
             * We do not use the "opacity" prop of <Bar /> component
             * because we want the color with opacity to reach the legend and the tooltip
             */
            fill={
              secondaryColorOpacity
                ? addOpacityToHexColor(secondaryColor, 0.5)
                : secondaryColor
            }
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartStratus;
