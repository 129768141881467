import { IconButton, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function Skeleton() {
  return (
    <Stack align="center" justify="space-between">
      <Stack>
        <IconButton.Skeleton width="32px" height="32px" />
        <Text.Skeleton width="100px" />
      </Stack>
      <IconButton.Skeleton width="32px" height="32px" />
    </Stack>
  );
}

export default Skeleton;
