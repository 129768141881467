import { SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function CouponsEmptySearch() {
  const t = useTranslationMarketing('coupons.emptySearch');

  return (
    <EmptyMessage
      title={t('title')}
      text={t('body')}
      icon={<SearchIcon size={32} />}
    />
  );
}
