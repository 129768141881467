import { Card, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

function ShareAbandonedCartCardSkeleton() {
  const t = useTranslationOrders();

  return (
    <Card title={t('abandonedCarts.cardShareAbandonedCart.title')}>
      <Stack.Item>
        <Text.Skeleton />
      </Stack.Item>
      <Stack>
        <Stack.Item>
          <Text.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Text.Skeleton />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default ShareAbandonedCartCardSkeleton;
