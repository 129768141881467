import { ChangeEvent } from 'react';
import { Box, Button, Chip, Icon, Input, Text } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { InterfaceIdLabel } from '@tiendanube/components';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import { CATALOG_SEARCH_PRODUCTS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useResponsive } from '../Responsive';

interface SearchFilterProps {
  placeholder: string;
  label?: string;
  ariaLabel?: string;
  resultCount?: string;
  appliedFilters: InterfaceIdLabel[];
  isLoading?: boolean;
  onClick?: () => void;
  onDismiss?: (key: string) => any;
  rightElement?: React.ReactNode;
  onSubmit: (text: string) => void;
  value: string;
  onChange: (value: string) => void;
  sortByElement?: React.ReactNode;
}
function SearchFilter({
  placeholder,
  label: labelButton,
  ariaLabel,
  resultCount,
  appliedFilters,
  rightElement,
  onSubmit,
  onDismiss,
  onClick,
  isLoading = false,
  value,
  onChange,
  sortByElement,
}: Readonly<SearchFilterProps>) {
  const t = useTranslationCatalog();
  const { isMobile } = useResponsive();

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const handleSearchSubmit = useUpsellFlow({
    title: t('products.filter.upsell.search'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: CATALOG_SEARCH_PRODUCTS,
    callback: onSubmit,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSearchSubmit(value);
    }
  };

  const handleClick = (id: string) => {
    onDismiss?.(id);
    onChange('');
  };

  const renderFiltersButton = onClick && (
    <Button onClick={onClick}>
      <Icon source={<SlidersIcon />} />
      {labelButton}
    </Button>
  );

  const styleGridSortBy = sortByElement ? '9.5fr 0.5fr' : '';

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Box display="grid" gridTemplateColumns={styleGridSortBy} gridGap="1">
        <Box display="flex" gap="1">
          <Input.Search
            type="search"
            name="search"
            aria-label={ariaLabel}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            value={value}
            onChange={handleChange}
            maxLength={150}
          />
          {renderFiltersButton}
        </Box>
        {!!sortByElement && <Box>{sortByElement}</Box>}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        gap="2"
      >
        <Box
          alignItems="center"
          display="flex"
          gap="2"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          {!!resultCount && <Text>{resultCount}</Text>}
          {isLoading && <Text.Skeleton width="122px" />}
          {!isLoading &&
            appliedFilters?.map(({ id, label }) => (
              <Chip
                key={id}
                id={id}
                text={label}
                removable
                onClick={() => handleClick(id)}
              />
            ))}
        </Box>
        <Box>{rightElement}</Box>
      </Box>
    </Box>
  );
}

export default SearchFilter;
