import { useCallback, useState } from 'react';
import { InterfaceNameValue } from '@tiendanube/components';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ProductsFiltersType } from 'domains/Catalog/Products/productsServices';
import { trackingProductVariantMetafieldFilter } from 'domains/Catalog/Products/tracking';
import {
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
} from 'domains/Metafields/types';

const defaultValueErrors = { minStock: '', maxStock: '' };

interface UseProductsFiltersResult {
  values: ProductsFiltersType;
  errors: Record<string, string>;
  selecteds: MetafieldSelectedInterface[];
  validateFilters: () => boolean;
  getFilterChips: () => { id: string; label: string }[];
  handleChange: ({ name, value }: InterfaceNameValue) => void;
  handleChangeMetafields: OnChangeMetafieldType;
  setAllValues: (filters: ProductsFiltersType) => void;
}

function useProductsFilters(
  filters: ProductsFiltersType,
): UseProductsFiltersResult {
  const t = useTranslationCatalog();

  const categories = useGetCategories();

  const [values, setValues] = useState(filters);
  const [errors, setErrors] = useState(defaultValueErrors);

  const handleChange = useCallback(
    ({ name, value }) => {
      setValues({ ...values, [name]: value });
      setErrors(defaultValueErrors);
    },
    [values],
  );

  const handleChangeMetafields: OnChangeMetafieldType = (id, value) => {
    trackingProductVariantMetafieldFilter(id, value);
    if (value !== null) {
      setValues((values) => ({
        ...values,
        [`mf-${id}`]: value,
      }));
      return;
    }
    setValues((values) => {
      const newValues = { ...values };
      delete newValues[`mf-${id}`];
      return newValues;
    });
  };

  const setAllValues = useCallback((filters: ProductsFiltersType) => {
    setValues(filters);
  }, []);

  const validateFilters = useCallback(() => {
    if (values.stock === 'quantity') {
      const { minStock, maxStock } = values;
      if (Number(maxStock) > 0 && Number(minStock) > Number(maxStock)) {
        setErrors({
          minStock: '',
          maxStock: 'products.filters.errors.maxStockIsMin',
        });
        return false;
      }
    }

    return true;
  }, [values]);

  const getFilterChips = useCallback(() => {
    const filterChips = Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== '' && !(key === 'maxStock' || key === 'minStock'),
      )
      .map(([key, value]) => {
        if (key === 'categoryId') {
          if (value !== 'has_no_categories') {
            return {
              id: key,
              label:
                categories.find(({ id }) => `${id}` === value)?.title || '',
            };
          }

          return {
            id: key,
            label: t('products.filters.categoryId.hasNoCategories'),
          };
        }
        if (value === 'quantity' && key === 'stock') {
          return {
            id: key,
            label:
              filters.maxStock === ''
                ? t(`products.filters.quantityMaxStock`, {
                    min: filters.minStock || 0,
                  })
                : t(`products.filters.quantityStock`, {
                    min: filters.minStock || 0,
                    max: filters.maxStock,
                  }),
          };
        }
        return {
          id: key,
          label: t(`products.filters.${key}.${value}`, value as string),
        };
      });

    return filterChips;
  }, [categories, filters, t]);

  const selecteds = Object.entries(values)
    .filter(([key]) => key.startsWith('mf-'))
    .map(([key, value]) => ({
      id: key.split('mf-')[1],
      value,
    })) as MetafieldSelectedInterface[];

  return {
    values,
    errors,
    selecteds,
    validateFilters,
    getFilterChips,
    handleChange,
    handleChangeMetafields,
    setAllValues,
  };
}

export default useProductsFilters;
