import { toStatusType } from 'App/rtk';
import { useModal, useToastStatus } from 'commons/hooks';
import { useCurrentUserSessionId } from 'domains/Auth/hooks';
import { useTranslationAccount } from '../../../hooks';
import {
  useDeleteAllUserSessionsMutation,
  useDeleteUserSessionMutation,
} from '../userSessionsApi';

export function useDeleteUserSessionAction(id?: string) {
  const deleteUserSession = useDeleteUserSessionMutation();
  const deleteAllUserSessions = useDeleteAllUserSessionsMutation();
  const currentSessionId = useCurrentUserSessionId();

  let translationKey;
  let deleteMutation;
  let deleteStatus;
  let handleDelete;

  if (id) {
    translationKey = 'delete';
    [deleteMutation, { status: deleteStatus }] = deleteUserSession;
    handleDelete = () => {
      deleteMutation(id);
    };
  } else {
    translationKey = 'deleteAll';
    [deleteMutation, { status: deleteStatus }] = deleteAllUserSessions;
    handleDelete = () => {
      deleteMutation(currentSessionId);
    };
  }
  const t = useTranslationAccount(
    `sessionManagementPage.actions.${translationKey}`,
  );
  useToastStatus({
    status: toStatusType(deleteStatus),
    error: t('error'),
    success: t('success'),
    progress: t('progress'),
  });
  const [showDeleteModal, handleClick, handleClose] = useModal();

  return {
    showDeleteModal,
    handleDelete,
    handleClick,
    handleClose,
  };
}
