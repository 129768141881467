import { Route, Switch } from 'react-router-dom';
import ToggleMigratedDomain from 'App/components/Migrations/ToggleMigratedDomain';
import { MIGRATION_ABANDONED_CARTS_FORCE } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import { ABANDONED_CART_ROUTES } from './abandonedCartRoutes';
import { AbandonedCartList, AbandonedCartDetails } from './pages';

function AbandonedCarts() {
  const tags = useGetTags();
  const hasAbandonedCartForceTag = tags.includes(
    MIGRATION_ABANDONED_CARTS_FORCE,
  );

  return (
    <Switch>
      <Route path={ABANDONED_CART_ROUTES.abandonedCartList} exact>
        <ToggleMigratedDomain
          domain="abandoned-carts"
          force={hasAbandonedCartForceTag}
        >
          <AbandonedCartList />
        </ToggleMigratedDomain>
      </Route>
      <Route path={ABANDONED_CART_ROUTES.abandonedCartDetails}>
        <AbandonedCartDetails />
      </Route>
    </Switch>
  );
}

export default AbandonedCarts;
