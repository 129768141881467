import { useState } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { CombinedChartDataInterface } from '../CombinedChart';

function useCombinedChartLegend<TKeys extends string>(
  data: CombinedChartDataInterface<TKeys>,
  selectedPrimary: string,
  selectedSecondary?: string,
) {
  const [legendSegments, setLegendSegments] = useState<Record<string, boolean>>(
    {},
  );

  const [hover, setHover] = useState<string | null>(null);

  const handleLegendMouseEnter = (data: Payload) => {
    const { dataKey } = data;

    if (typeof dataKey === 'string' && !legendSegments[dataKey]) {
      setHover(dataKey);
    }
  };

  const handleLegendMouseLeave = () => {
    setHover(null);
  };

  const disableAllLinesAndBarsIfFirstTime = (clonedLegendSegments) => {
    ['current', 'previous'].forEach((prefix) => {
      // So first of all we disable every line
      data.results[selectedPrimary].keys.forEach((key) => {
        // Only assign true if it's the first time (null or undefined)
        clonedLegendSegments[`${prefix}.${key}`] ??= true;
      });
      selectedSecondary &&
        data.results[selectedSecondary].keys.forEach((key) => {
          // Only assign true if it's the first time (null or undefined)
          clonedLegendSegments[`${prefix}.${key}`] ??= true;
        });
      // Then disable totals
      clonedLegendSegments[`${prefix}.totals`] ??= true;
    });
  };

  const enableAllLinesAndBars = (clonedLegendSegments) => {
    ['current', 'previous'].forEach((prefix) => {
      // Enable all lines but totals
      data.results[selectedPrimary].keys.forEach(
        (key) => (clonedLegendSegments[`${prefix}.${key}`] = false),
      );
      selectedSecondary &&
        data.results[selectedSecondary].keys.forEach(
          (key) => (clonedLegendSegments[`${prefix}.${key}`] = false),
        );
      // Enable totals
      clonedLegendSegments[`${prefix}.totals`] = false;
    });
  };

  const selectBarFromLegend = (data: Payload) => {
    const key = data?.dataKey as string;

    const clonedLegendSegments = { ...legendSegments };
    const currentValue = clonedLegendSegments[key];
    // If is first time you select then should select only that one and disable everything else.
    disableAllLinesAndBarsIfFirstTime(clonedLegendSegments);
    // Select bar or line. If it's first time then set in false (show), else just toggle the current value.
    clonedLegendSegments[key] =
      typeof currentValue === 'boolean' ? !currentValue : false;

    // If everything is disabled then turn everything on
    if (
      !Object.keys(clonedLegendSegments).some(
        (key) => clonedLegendSegments[key] === false,
      )
    ) {
      enableAllLinesAndBars(clonedLegendSegments);
    }

    // Set new state
    setLegendSegments(clonedLegendSegments);
    setHover(null);

    // We want to hover after click
    handleLegendMouseEnter(data);
  };

  return {
    legendSegments,
    hover,
    handleLegendMouseEnter,
    handleLegendMouseLeave,
    selectBarFromLegend,
  };
}

export default useCombinedChartLegend;
