import { TrackingMenuClickArgs } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  CLOUDFLARE_MIGRATION_ADBAR_CLICK,
  DASHBOARD_PAYMENT_OTHERS,
  DASHBOARD_REGISTER_LOCATION,
  DASHBOARD_SEE_PREFERENCES_ENVIO_NUBE,
  DASHBOARD_SEE_PREFERENCES_NUVEM_ENVIO,
  DASHBOARD_SEE_PREFERENCES_NUVEM_PAGO,
  DASHBOARD_SEE_PREFERENCES_PAGO_NUBE,
  DASHBOARD_SEE_STORE,
  DASHBOARD_THEME_PERSONALIZE,
  HELP_TUTORIAL_CLICK,
  MENU_CONFIGURATION_CLICK,
  MENU_PRIMARY_ACCOUNT_CLICK,
  MENU_PRIMARY_PRODUCTS_CLICK,
  MENU_PRIMARY_SALES_CHANNEL_CLICK,
  MENU_PRIMARY_STATS_CLICK,
  MENU_STORE_VISIT,
  NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_CAN_CLICK,
  NUVEMPAGO_MENU_CLICK,
} from 'config/trackingEvents';
import { config } from './components/ExternalServices/Amplitude/config';

export const trackingMenuClick = (args: TrackingMenuClickArgs) => {
  logEvent(args.tag, args.options ?? {}, args.instanceName);
};

export const trackingNuvempagoMenuClick = (status: string): void => {
  logEvent(
    NUVEMPAGO_MENU_CLICK,
    { active: status },
    config.nuvempagoInstanceName,
  );
};

export const trackingNewAdminCanLinkClick = (
  previousValue: string,
  tutorial: string,
) => {
  logEvent(HELP_TUTORIAL_CLICK, {
    current_view: previousValue,
    tutorial: tutorial,
  });
};

export const trackingMenuPrimaryProductsClick = () => {
  logEvent(MENU_PRIMARY_PRODUCTS_CLICK, {});
};

export const trackingMenuPrimaryStatsClick = () => {
  logEvent(MENU_PRIMARY_STATS_CLICK, {});
};

export const trackingMenuConfigurationClick = () => {
  logEvent(MENU_CONFIGURATION_CLICK, {});
};

export const tranckingCloudflareMigrationAdBarClick = () => {
  logEvent(CLOUDFLARE_MIGRATION_ADBAR_CLICK, {});
};

export const trackingMenuPrimarySalesChannelClick = () => {
  logEvent(MENU_PRIMARY_SALES_CHANNEL_CLICK, {});
};

export const trackingMenuStoreVisit = () => {
  logEvent(MENU_STORE_VISIT, {});
};

export const trackingMenuPrimaryAccountClick = () => {
  logEvent(MENU_PRIMARY_ACCOUNT_CLICK, {});
};

export const nuvempagoManualActivationRegistrationCanClick = () => {
  logEvent(NUVEMPAGO_MANUAL_ACTIVATION_REGISTRATION_CAN_CLICK, {});
};

export const trackingDashboardSeeStoreClick = (): void => {
  logEvent(DASHBOARD_SEE_STORE, {});
};

export const trackingThemePersonalizeClick = (): void => {
  logEvent(DASHBOARD_THEME_PERSONALIZE, {});
};

export const trackingRegisterLocationClick = (): void => {
  logEvent(DASHBOARD_REGISTER_LOCATION, {});
};

export const trackingSeePreferencesNuvemEnvioClick = (): void => {
  logEvent(DASHBOARD_SEE_PREFERENCES_NUVEM_ENVIO, {});
};

export const trackingSeePreferencesEnvioNubeClick = (): void => {
  logEvent(DASHBOARD_SEE_PREFERENCES_ENVIO_NUBE, {});
};

export const trackingSeePreferencesNuvemPagoClick = (): void => {
  logEvent(DASHBOARD_SEE_PREFERENCES_NUVEM_PAGO, {});
};

export const trackingSeePreferencesPagoNubeClick = (): void => {
  logEvent(DASHBOARD_SEE_PREFERENCES_PAGO_NUBE, {});
};

export const trackingPaymentOthersClick = (): void => {
  logEvent(DASHBOARD_PAYMENT_OTHERS, {});
};
