import { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@nimbus-ds/components';
import { PlansListResponseDto } from '@tiendanube/common';
import { PATH } from 'App/components/Menu/components/utils';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import { FEATURE_ONLY_LAYOUT_DEFAULT } from 'App/features';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import {
  CardWithTitle,
  ErrorScreen,
  HeaderTop,
  IonPageStratus,
  SubmitButton,
} from 'commons/components';
import { useInterval, useToast, useToastStatus } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin/goToAdmin';
import { INSTA_THEME, THEMES } from 'config/upsellFlowSources';
import { useTranslationBilling } from 'domains/Billing';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import {
  trackingBillingUpsellPlanChange,
  trackingBillingUpsellPlansPageClick,
} from 'domains/Billing/tracking';
import FreeTrialAlert from 'domains/Billing/UpsellFlow/components/FreeTrialAlert';
import UpsellBodyCard from 'domains/Billing/UpsellFlow/components/UpsellBodyCard';
import useFindPlanForFeature from 'domains/Billing/UpsellFlow/hooks/useFindPlanForFeature';
import { BULLET_ACTIVATION_PERIOD_IN_MS } from 'domains/Billing/UpsellFlow/hooks/useUpsellFlow/constants';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { Skeleton } from './Skeleton';
import {
  useGetThemesConfigQuery,
  usePublishDraftMutation,
} from '../../themesApi';
import { THEMES_ROUTES } from '../../themesRoutes';

function ThemesUpsellPage() {
  const t = useTranslationOnline('themesUpsellPage');
  const tBilling = useTranslationBilling('upsellFlow');
  const tOnline = useTranslationOnline(
    'themesPage.draft.actions.publishDraftModal',
  );
  const [bulletProgress, setBulletProgress] = useState(0);
  const [oldPlan, setOldPlan] = useState<PlansListResponseDto>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { goTo } = useNavegate();
  const { showMenu, hideMenu } = useDesktopMenuContext();
  const { getNextPlan, getCurrentPlan } = useFindPlanForFeature();
  const [publish, { status, isLoading: isPublishing, isSuccess: isPublished }] =
    usePublishDraftMutation();

  useToastStatus({
    status: toStatusType(status),
    progress: tOnline('progress'),
    success: tOnline('success'),
    error: tOnline('error'),
  });
  const {
    updateBillingPlan,
    refreshPlans,
    isLoading,
    isError,
    isSuccess,
    fetchBillingPlans,
  } = useBillingPlans();
  const { setInterval, clearInterval } = useInterval();
  const { addToast } = useToast();
  useGetThemesConfigQuery();

  const newPlan = getNextPlan({
    featureKey: FEATURE_ONLY_LAYOUT_DEFAULT,
    minValue: 1,
    isNegativeFeature: true,
  });
  const currentPlan = getCurrentPlan();

  const changePlan = useCallback(async () => {
    if (!newPlan?.id) return;
    trackingBillingUpsellPlanChange({
      featureKey: FEATURE_ONLY_LAYOUT_DEFAULT,
      currentPlan: currentPlan?.name || '',
      newPlan: newPlan.name,
      currentPlanValue: 1,
      newPlanValue: 0,
      source: INSTA_THEME,
    });
    setIsUpdating(true);
    try {
      setInterval(
        () => {
          setBulletProgress((prev) => prev + 1);
        },
        BULLET_ACTIVATION_PERIOD_IN_MS,
        newPlan.upsellBullets.length,
      );
      await updateBillingPlan(newPlan?.id, false);
      setIsUpdated(true);
      setOldPlan(currentPlan);
    } catch (error) {
      addToast({
        label: t('upsellFlow.error'),
        appearance: 'danger',
      });
      clearInterval();
    } finally {
      setIsUpdating(false);
    }
  }, [
    newPlan,
    currentPlan,
    setInterval,
    updateBillingPlan,
    addToast,
    t,
    clearInterval,
  ]);

  useEffect(() => {
    hideMenu();
    return () => {
      showMenu();
      refreshPlans();
    };
  }, [hideMenu, showMenu, refreshPlans]);

  const goToPlansPage = () => {
    trackingBillingUpsellPlansPageClick({
      featureKey: FEATURE_ONLY_LAYOUT_DEFAULT,
      currentPlan: currentPlan?.name || '',
      newPlan: newPlan?.name || '',
      currentPlanValue: 1,
      newPlanValue: 0,
      source: THEMES,
    });
    goToAdmin(PATH.plans)();
  };

  const publishDraft = () => {
    publish();
  };

  useEffect(() => {
    if (isPublished) goTo(`${THEMES_ROUTES.themes}?publishedDraft=true`);
  }, [goTo, isPublished]);

  const planChangeInProgress =
    isUpdating ||
    (bulletProgress > 0 &&
      bulletProgress < (newPlan?.upsellBullets?.length || 0));

  const handleCancel = goToAdmin('/themes/settings/draft');
  const alert =
    planChangeInProgress || isUpdated ? null : (
      <Box marginBottom="4">
        <FreeTrialAlert />
      </Box>
    );

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop
          navigation={{
            onClick: handleCancel,
            children: t('goBack'),
          }}
        />
      }
    >
      {isError && <ErrorScreen onRetry={fetchBillingPlans} />}
      {!isError && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="440px">
            <CardWithTitle title={t('cardTitle')}>
              {isLoading && <Skeleton />}
              {isSuccess && currentPlan && newPlan && (
                <>
                  {alert}
                  <UpsellBodyCard
                    currentPlan={oldPlan || currentPlan}
                    newPlan={newPlan}
                    planChangeInProgress={planChangeInProgress}
                    bulletProgress={bulletProgress}
                    goToPlansPage={goToPlansPage}
                    planChangeComplete={!planChangeInProgress && isUpdated}
                  />
                  <Box
                    marginTop="4"
                    display="flex"
                    gap="2"
                    justifyContent="flex-end"
                  >
                    {!isUpdated && (
                      <Button onClick={handleCancel} disabled={isUpdating}>
                        {tBilling('modal.ctaCancel')}
                      </Button>
                    )}
                    <SubmitButton
                      appearance="primary"
                      onClick={isUpdated ? publishDraft : changePlan}
                      isLoading={isPublishing || planChangeInProgress}
                    >
                      {isUpdated && !planChangeInProgress
                        ? t('ctas.publish')
                        : tBilling('modal.ctaAccept')}
                    </SubmitButton>
                  </Box>
                </>
              )}
            </CardWithTitle>
          </Box>
        </Box>
      )}
    </IonPageStratus>
  );
}

export default ThemesUpsellPage;
