import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { CalloutCard } from '@tiendanube/components';
import { TiendanubeIcon } from '@tiendanube/icons';
import { GOOGLE_ANALYTICS_CONFIGURATION_LINK } from 'commons/constants/externalLinks';
import { openWindow } from 'commons/utils/window';

function GoogleAnalyticsCardSetup() {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.STATISTICS);

  const handleOnClick = () => {
    openWindow(GOOGLE_ANALYTICS_CONFIGURATION_LINK[language], true);
  };

  return (
    <CalloutCard
      appearance="secondary"
      icon={TiendanubeIcon}
      onClick={handleOnClick}
      textLink={{ children: t('statistics.googleAnalyticsCard.link') }}
      title={t('statistics.googleAnalyticsCard.title')}
      subtitle={t('statistics.googleAnalyticsCard.description')}
    />
  );
}

export default GoogleAnalyticsCardSetup;
