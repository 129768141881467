import { useSelector } from 'react-redux';
import {
  getAdminDisableDate,
  getStoreStatus,
  getStorefrontDisableDate,
} from 'domains/Auth/authSlice/authSelectors';

function useStoreStatus() {
  const storeStatus = useSelector(getStoreStatus);
  const adminDisableDate = useSelector(getAdminDisableDate);
  const storefrontDisableDate = useSelector(getStorefrontDisableDate);

  return {
    storeStatus,
    adminDisableDate,
    storefrontDisableDate,
  };
}

export default useStoreStatus;
