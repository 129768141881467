import { useTranslation } from 'react-i18next';
import {
  CustomPropertiesResponseDto,
  CustomPropertyResponseDtoV2,
} from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Input,
  InterfaceNameValue,
  Select,
  Stack,
  Text,
} from '@tiendanube/components';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { useGetLanguage } from 'domains/Auth/hooks';
import {
  CustomAttribute,
  CustomAttributeValues,
  StandardColorAttribute,
  StandardSizeAttribute,
} from './components';
import { getVariantSelectOptions } from './utils';
import { AttributeType } from '../../../types';
import {
  getStandardSizesFlatten,
  getStandardVariantsFlatten,
  isAttributeValueSelected,
} from '../utils';

interface AttributeValuesProps {
  language?: LanguagesType;
  name: LanguageInterface;
  attributeOptions: LanguageInterface[];
  standardVariants: CustomPropertiesResponseDto | null;
  selectedVariantType: AttributeType | null;
  confirmPressed: boolean;
  emptyValuesError: boolean;
  onChangeName: (language: string, value: string) => void;
  onAddOption: () => void;
  onChangeOption: (index: number, language: string, value: string) => void;
  onRemoveOption: (index: number) => void;
  onSelectVariant: (language: string, e: InterfaceNameValue) => void;
  onCheckOption: (selected: string[]) => void;
  onBlurOption: (
    index: number,
    language: string,
    e: InterfaceNameValue,
  ) => void;
  onSortOptions: (sorted: LanguageInterface[]) => void;
}

function AttributeValues({
  language,
  name,
  attributeOptions,
  standardVariants,
  selectedVariantType,
  emptyValuesError,
  confirmPressed,
  onChangeName,
  onAddOption,
  onChangeOption,
  onRemoveOption,
  onSelectVariant,
  onCheckOption,
  onBlurOption,
  onSortOptions,
}: AttributeValuesProps) {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(Domain.CATALOG);
  const defaultLanguage = useGetLanguage();
  const languageToUse = language || defaultLanguage;

  const handleChangeName = ({ value }: InterfaceNameValue): void => {
    onChangeName(languageToUse, value);
  };

  const handleChangeOption = (
    index: number,
    { value }: InterfaceNameValue,
  ): void => {
    onChangeOption(index, languageToUse, value);
  };

  const handleBlurOption = (index: number, e: InterfaceNameValue) => {
    onBlurOption(index, languageToUse, e);
  };

  const showAttributeName = selectedVariantType === 'new_attribute';

  const showColorAttributeValues =
    selectedVariantType === 'color' && !!standardVariants?.color;
  const showSizeAttributeValues =
    selectedVariantType === 'size' && !!standardVariants?.size;

  const isCustomAttribute =
    !showColorAttributeValues &&
    !showSizeAttributeValues &&
    !!selectedVariantType;

  const showCustomAttributeValues = showAttributeName || isCustomAttribute;

  const formattedStandardVariants = getStandardVariantsFlatten(
    standardVariants,
    selectedVariantType,
  );

  const selectedValues: LanguageInterface[] = formattedStandardVariants
    ? attributeOptions.filter((attribute) =>
        isAttributeValueSelected(
          Object.values(formattedStandardVariants),
          languageToUse,
          attribute,
        ),
      )
    : [];

  const showNameError =
    confirmPressed &&
    (!name[languageToUse] || name[languageToUse]?.trim() === '');

  const suggestedValues: CustomPropertyResponseDtoV2 | undefined =
    !!selectedVariantType && selectedVariantType !== 'new_attribute'
      ? standardVariants?.[selectedVariantType]
      : undefined;

  return (
    <Stack column align="stretch" spacing="base">
      <Stack column align="stretch" spacing="tight">
        <Text bold>{t('products.variants.property')}</Text>
        <Select
          onChange={(e) => onSelectVariant(languageToUse, e)}
          name={`variant-type-${language}`}
          placeholder={t('products.variants.selectVariantType')}
          options={getVariantSelectOptions(
            standardVariants,
            languageToUse,
            locale,
          )}
          value={selectedVariantType ? selectedVariantType : undefined}
        />
      </Stack>
      {showAttributeName && (
        <Stack column align="stretch" spacing="tight">
          <Text bold>{t('products.variants.name')}</Text>
          <Input
            value={name[languageToUse]}
            name={`name-${language}`}
            onChange={handleChangeName}
            placeholder=""
            required
            helpText={
              showNameError ? t('products.variants.emptyNameError') : ''
            }
            appearance={showNameError ? 'validation_error' : undefined}
          />
        </Stack>
      )}

      {showCustomAttributeValues && (
        <CustomAttribute
          languageToUse={languageToUse}
          selectedValues={attributeOptions}
          suggestedValues={suggestedValues?.suggested}
          onCheckOption={onCheckOption}
        >
          <CustomAttributeValues
            emptyValuesError={emptyValuesError}
            attributeOptions={attributeOptions}
            languageToUse={languageToUse}
            onChangeOption={handleChangeOption}
            onRemoveOption={onRemoveOption}
            onAddOption={onAddOption}
            onBlurOption={handleBlurOption}
            onSortOptions={onSortOptions}
          />
        </CustomAttribute>
      )}

      {showColorAttributeValues && (
        <StandardColorAttribute
          colors={standardVariants.color}
          languageToUse={languageToUse}
          selectedValues={selectedValues}
          onCheckOption={onCheckOption}
        >
          <CustomAttributeValues
            emptyValuesError={emptyValuesError}
            attributeOptions={attributeOptions}
            languageToUse={languageToUse}
            onChangeOption={handleChangeOption}
            onRemoveOption={onRemoveOption}
            onAddOption={onAddOption}
            onBlurOption={handleBlurOption}
            onSortOptions={onSortOptions}
            addOptionLabel={t('products.variants.addColorOption')}
            standardVariants={standardVariants.color.standard}
          />
        </StandardColorAttribute>
      )}

      {showSizeAttributeValues && (
        <StandardSizeAttribute
          sizes={standardVariants.size}
          languageToUse={languageToUse}
          selectedValues={selectedValues}
          onCheckOption={onCheckOption}
        >
          <CustomAttributeValues
            emptyValuesError={emptyValuesError}
            attributeOptions={attributeOptions}
            languageToUse={languageToUse}
            onChangeOption={handleChangeOption}
            onRemoveOption={onRemoveOption}
            onAddOption={onAddOption}
            onBlurOption={handleBlurOption}
            onSortOptions={onSortOptions}
            addOptionLabel={t('products.variants.addSizeOption')}
            standardVariants={getStandardSizesFlatten(standardVariants.size)}
          />
        </StandardSizeAttribute>
      )}
    </Stack>
  );
}

export default AttributeValues;
