import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StockHistoryResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getStockHistory } from 'domains/Catalog/Products/productsSlice';
import {
  stockHistoryStatus as stockHistoryStatusSelector,
  stockHistory as stockHistorySelector,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

interface UseStockHistoryResult {
  fetchStockHistory: () => void;
  stockHistory: StockHistoryResponseDto[] | null;
  isLoading: boolean;
  isError: boolean;
  isEmpty: boolean;
  isSuccess: boolean;
}

interface UseStockHistoryProps {
  productId: string;
  variantId: string;
  locationId?: string;
}

function useStockHistory({
  variantId,
  productId,
  locationId,
}: UseStockHistoryProps): UseStockHistoryResult {
  const dispatch = useAppDispatch();
  const { isSuccess, isError, isLoading } = convertStatusTypeToObject(
    useSelector(stockHistoryStatusSelector),
  );
  const stockHistory = useSelector(stockHistorySelector);

  const fetchStockHistory = useCallback(async () => {
    dispatch(
      getStockHistory({
        variantId,
        productId,
        locationId,
      }),
    );
  }, [dispatch, variantId, productId, locationId]);

  const isEmpty = (isSuccess && stockHistory?.length === 0) || !variantId;

  return {
    isSuccess: isSuccess && stockHistory !== null,
    fetchStockHistory,
    isLoading,
    isError,
    stockHistory,
    isEmpty,
  };
}

export default useStockHistory;
