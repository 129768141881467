export const BASE_I18N_URL = '/v1/i18n';

export const BASE_I18N_ENABLED_COUNTRIES_URL = `${BASE_I18N_URL}/enabled-countries`;
export const BASE_I18N_COUNTRIES_URL = `${BASE_I18N_URL}/countries`;
export const BASE_I18N_CURRENCIES_URL = `${BASE_I18N_URL}/currencies`;
export const BASE_I18N_LANGUAGES_URL = `${BASE_I18N_URL}/languages`;
export const BASE_I18N_ENABLED_COUNTRY_DELETE_URL = `${BASE_I18N_ENABLED_COUNTRIES_URL}/:id`;
export const BASE_I18N_ENABLED_COUNTRY_UPDATE_URL = `${BASE_I18N_ENABLED_COUNTRIES_URL}/:id`;
export const BASE_I18N_UPDATE_MAIN_CURRENCY_URL = `${BASE_I18N_CURRENCIES_URL}/:currencyStoreId`;
export const BASE_I18N_UPDATE_MAIN_COUNTRY_URL = `${BASE_I18N_LANGUAGES_URL}/:id`;
export const BASE_I18N_RATE_SUGGEST_URL = `${BASE_I18N_CURRENCIES_URL}/rate/:equivalentCurrency/:mainCurrency`;
export const BASE_I18N_UPDATE_EXCHANGE_RATE_URL = `${BASE_I18N_CURRENCIES_URL}/rate/:currencyCode`;

export const i18nTags = {
  enabledCountryList: 'enabledCountryList',
  currencyList: 'currencyList',
} as const;
