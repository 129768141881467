import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getPagseguroData,
  getPagseguroStatus,
} from 'domains/Dashboard/slices/paymentsMessagesSlice';
import {
  cleanGetPagseguroUrlStatus,
  getPagSeguroUrlAction,
} from 'domains/Dashboard/slices/paymentsMessagesSlice/paymentsMessagesSlice';

function usePagSgeuroCredentialsAlert() {
  const dispatch = useAppDispatch();
  const url = useSelector(getPagseguroData);
  const status = useSelector(getPagseguroStatus);

  const getPagseguroUrl = useCallback(async () => {
    await dispatch(getPagSeguroUrlAction());
    dispatch(cleanGetPagseguroUrlStatus());
  }, [dispatch]);

  return {
    url,
    status,
    getPagseguroUrl,
  };
}

export default usePagSgeuroCredentialsAlert;
