import { Box, Text } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { Repeater } from 'commons/components';

function RecurrencyItemSkeleton() {
  return (
    <>
      <hr />
      <Box
        padding="4"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text.Skeleton height="30px" width="30%" />
        <ChevronRightIcon />
      </Box>
    </>
  );
}

export function ChooseRecurrencyItemsSkeleton() {
  return <Repeater times={3} item={RecurrencyItemSkeleton} />;
}
