import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useChangePlatform, useIsMobileDevice } from 'domains/Auth/hooks';
import { isDomainWebAdmin } from './components/PrivateRoute/utils';
import { getIsBuildPerformanceCi } from './performance';
import RoutesMobile from './RoutesMobile';
import RoutesWeb from './RoutesWeb';
import { useDatadogRecordRUM } from './settings/routingLog/hooks';
import useRouteWatcher from './settings/routingLog/hooks/useRouteWatcher';
import useAppInit from './useAppInit';

function getIsMobileDevice(): boolean {
  const plaform = Capacitor.getPlatform();
  const plaformMobile = ['android', 'ios'];
  return plaformMobile.includes(plaform);
}

/**
 * Control to determine in which mode mobile
 * or desktop view has to be executed
 */
const isMobilePlatform =
  getIsMobileDevice() ||
  !isDomainWebAdmin(document.location.host) ||
  getIsBuildPerformanceCi();

function Routes() {
  const changePlatform = useChangePlatform();
  const isMobileDevice = useIsMobileDevice();
  useAppInit();
  useRouteWatcher();
  useDatadogRecordRUM();

  useEffect(() => {
    changePlatform(isMobilePlatform);
  }, [changePlatform]);

  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.shiftKey && e.metaKey && e.key === 'o') {
        changePlatform(!isMobileDevice);
      }
    };
  }, [changePlatform, isMobileDevice]);

  return isMobileDevice ? <RoutesMobile /> : <RoutesWeb />;
}

export default Routes;
