import { useSelector } from 'react-redux';
import { getUserAdmin } from 'domains/Auth/authSlice/authSelectors';

export interface UserAdminInfo {
  id: string;
  name: string;
  roles: string[];
}

function useUserAdminInfo(): UserAdminInfo {
  return useSelector(getUserAdmin);
}

export default useUserAdminInfo;
