import { FEATURE_METAFIELDS_ORDERS } from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useGetMetafieldsOrders } from 'domains/Metafields/hooks';

function useOrdersMetafields() {
  const { fetchMetafields, metafields, status } = useGetMetafieldsOrders();

  const { isAvailable: isMetafieldAllowedByPlan } = useStatusFeatureByKey(
    FEATURE_METAFIELDS_ORDERS,
  );

  return {
    isMetafieldAllowedByPlan,
    metafields,
    status,
    fetchMetafields,
  };
}

export default useOrdersMetafields;
