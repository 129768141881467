import { useState } from 'react';
import { generatePath } from 'react-router';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useModal, useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  useSetFreeShippingStatusMutation,
  useDeleteFreeShippingMutation,
} from '../freeShippingApi';
import { FREE_SHIPPING_ROUTES } from '../freeShippingRoutes';

export function useFreeShipingActions(id: string, currentStatus: boolean) {
  const t = useTranslationMarketing('freeShipping.actions');
  const { goTo } = useNavegate();

  const [enable, setEnable] = useState(false);
  const [setChangeStatus, { status: changeStatusStatus }] =
    useSetFreeShippingStatusMutation();

  const [deleteFreeShipping, { status: deleteStatus }] =
    useDeleteFreeShippingMutation();

  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [showStatusModal, openStatusModal, closeStatusModal] = useModal();

  const enablePrefix = enable ? 'enable' : 'disable';

  useToastStatus({
    status: toStatusType(changeStatusStatus),
    error: t(`${enablePrefix}.error`),
    success: t(`${enablePrefix}.success`),
    progress: t(`${enablePrefix}.progress`),
  });

  useToastStatus({
    status: toStatusType(deleteStatus),
    error: t('delete.error'),
    success: t('delete.success'),
    progress: t('delete.progress'),
  });

  const handleChangeStatus = () => {
    setChangeStatus({ id, status: !currentStatus });
    setEnable(!currentStatus);
  };
  const handleEdit = () => {
    goTo(generatePath(FREE_SHIPPING_ROUTES.editFreeShipping, { id }));
  };
  const handleDelete = async () => {
    deleteFreeShipping(id);
  };

  return {
    showStatusModal,
    showDeleteModal,
    handleChangeStatus,
    handleEdit,
    handleDelete,
    openDeleteModal,
    closeDeleteModal,
    openStatusModal,
    closeStatusModal,
  };
}
