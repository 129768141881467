import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { StatisticsInterface } from '../types';

const getStatistics = (
  state: RootStateType,
): CombinedState<StatisticsInterface> => state.statistics;

export const getStatisticsProducts = createSelector(
  getStatistics,
  (statistics) => statistics.products,
);

export const getStatisticsProductsStatus = createSelector(
  getStatistics,
  (data) => data.status,
);

export const getStatisticsProductsLoadingError = createSelector(
  getStatisticsProductsStatus,
  (status) => ({
    error: status === 'error',
    loading: status === 'loading',
  }),
);
