import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { ExternalLink } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

interface GoogleStoreOwnershipProps {
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  googleMeta: string;
}

export function GoogleStoreOwnership({
  onChangeTextArea,
  googleMeta,
}: Readonly<GoogleStoreOwnershipProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.google.googleStoreOwnership',
  );
  return (
    <SplitSection title={t('title')} description={t('description')}>
      <FormField.Textarea
        onChange={onChangeTextArea}
        value={googleMeta}
        lines={6}
        id="googleMeta"
        name="googleMeta"
        placeholder={t('placeholder')}
        label={t('label')}
      />
      <ExternalLink appearance="primary" textDecoration="none" href={t('href')}>
        {t('link')}
      </ExternalLink>
    </SplitSection>
  );
}
