import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InterfaceNameValue,
  RadioButtonGroup,
  Stack,
} from '@tiendanube/components';
import {
  addMounthsToday,
  getTodayIsoString,
  ninetyDaysAgo,
  subtractDays,
} from 'commons/utils/date';

export enum optionValue {
  ALL = 'all',
  LAST_DAY = 'lastDay',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  CUSTOM = 'custom',
}

interface DateFilterProps {
  dateFrom: string;
  dateTo: string;
  label?: string;
  onChange: ({ option, dateFrom, dateTo }) => void;
  threeMonthsLimited?: boolean;
}

function DateFilter({
  dateFrom,
  dateTo,
  label,
  onChange,
  threeMonthsLimited,
}: DateFilterProps): JSX.Element {
  const { t } = useTranslation(['common']);

  const [selectedOption, setSelectedOption] = useState(optionValue.ALL);

  const options = useMemo(
    () => [
      {
        label: t('dateFilter.all'),
        value: optionValue.ALL,
        dateFrom: '',
        dateTo: '',
      },
      {
        label: t('dateFilter.lastDay'),
        value: optionValue.LAST_DAY,
        dateFrom: subtractDays(getTodayIsoString(), 1),
        dateTo: getTodayIsoString(),
      },
      {
        label: t('dateFilter.last7Days'),
        value: optionValue.LAST_7_DAYS,
        dateFrom: subtractDays(getTodayIsoString(), 7),
        dateTo: getTodayIsoString(),
      },
      {
        label: t('dateFilter.last30Days'),
        value: optionValue.LAST_30_DAYS,
        dateFrom: subtractDays(getTodayIsoString(), 30),
        dateTo: getTodayIsoString(),
      },
      {
        label: t('dateFilter.custom'),
        value: optionValue.CUSTOM,
        dateFrom: addMounthsToday(-1),
        dateTo: getTodayIsoString(),
      },
    ],
    [t],
  );

  const handleChangeOption = (e: InterfaceNameValue) => {
    const option = options.find((o) => o.value === e.value);

    setSelectedOption(option?.value || optionValue.ALL);
    onChange({
      option: option?.value || optionValue.ALL,
      dateFrom: option?.dateFrom || '',
      dateTo: option?.dateTo || '',
    });
  };

  const handleChangeCustom = (e: InterfaceNameValue) => {
    if (e.name === 'dateFrom')
      onChange({
        option: selectedOption,
        dateFrom: e.value,
        dateTo: dateTo,
      });
    if (e.name === 'dateTo')
      onChange({
        option: selectedOption,
        dateFrom: dateFrom,
        dateTo: e.value,
      });
  };

  useEffect(() => {
    if (selectedOption !== optionValue.CUSTOM) {
      const option = options
        .filter((o) => o.value !== optionValue.CUSTOM)
        .find((o) => o.dateFrom === dateFrom && o.dateTo === dateTo);

      setSelectedOption(option ? option.value : optionValue.CUSTOM);
    }
  }, [dateFrom, dateTo, options, selectedOption]);

  return (
    <Stack column align="stretch">
      <Stack.Item>
        <RadioButtonGroup
          name="dateRange"
          label={label}
          options={options}
          onChange={handleChangeOption}
          value={selectedOption}
        />
      </Stack.Item>
      {selectedOption === 'custom' && (
        <Stack.Item>
          <Stack spacing="tight">
            <Stack.Item fill>
              <Input
                name="dateFrom"
                type="date"
                value={dateFrom}
                min={threeMonthsLimited ? ninetyDaysAgo : undefined}
                max={dateTo}
                onChange={handleChangeCustom}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Input
                name="dateTo"
                type="date"
                value={dateTo}
                min={dateFrom}
                onChange={handleChangeCustom}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
}

export default DateFilter;
