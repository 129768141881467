import { useState } from 'react';
import { Alert, Text, Box } from '@nimbus-ds/components';
import { MoneyIcon } from '@nimbus-ds/icons';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

function OpportunityAlert() {
  const t = useTranslationOrders();
  const [show, setShow] = useState(true);
  return (
    <Alert
      show={show}
      title={t('abandonedCarts.opportunityAlert.title')}
      appearance="primary"
      onRemove={() => setShow(false)}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Text>
          {t('abandonedCarts.opportunityAlert.descriptionStart')}{' '}
          <Box padding="1" display="inline-flex">
            <MoneyIcon />
          </Box>
          {t('abandonedCarts.opportunityAlert.descriptionEnd')}
        </Text>
      </Box>
    </Alert>
  );
}

export default OpportunityAlert;
