import { MetafieldsMapV2Dto } from '@tiendanube/common';
import axios from 'App/axios';
import { MetafieldBulkAssignmentsInterface } from '../orderMetafieldsSlice/types';

const getMetafields = async (id: string): Promise<MetafieldsMapV2Dto> => {
  const { data } = await axios.get<MetafieldsMapV2Dto>(
    `/v2/orders/${id}/metafields`,
  );
  return data;
};

const updateMetafield = async (
  orderId: string,
  metafieldId: string,
  value: string | null,
): Promise<void> => {
  const { data } = await axios.patch<void>(
    `v2/orders/${orderId}/metafields/${metafieldId}/values`,
    { value },
  );
  return data;
};

export const ordersBulkAssignmentMetafield = async (
  payload: MetafieldBulkAssignmentsInterface,
): Promise<void> => {
  const { data } = await axios.post<void>(
    `/v2/orders/metafields/bulk-assignment`,
    payload,
  );
  return data;
};

const ordersService = {
  getMetafields,
  updateMetafield,
  ordersBulkAssignmentMetafield,
};

export default ordersService;
