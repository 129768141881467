import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  InterfaceInput,
  InterfaceNameValue,
  Select,
} from '@tiendanube/components';
import { useGetCountries } from 'domains/Shipping/Addresses/hooks';

interface CountrySelectProps extends Partial<InterfaceInput> {
  value?: string;
  error?: string;
  onChange: ({ value, name }: InterfaceNameValue) => void;
}

function CountrySelect({ value, onChange }: CountrySelectProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const countries = useGetCountries();

  return (
    <Select
      label={t('customerForm.deliveryInformation.country')}
      name="country"
      value={value}
      options={countries}
      onChange={onChange}
    />
  );
}

export default CountrySelect;
