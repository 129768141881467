import { Card, Box, Text } from '@nimbus-ds/components';
import { PlansListResponseDto } from '@tiendanube/common';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PlanChangePreview } from '../PlanChangePreview';
import { UpsellBullets } from '../UpsellBullets';

interface UpsellBodyCardProps {
  currentPlan: PlansListResponseDto;
  newPlan: PlansListResponseDto;
  planChangeInProgress: boolean;
  bulletProgress: number;
  goToPlansPage: () => void;
  planChangeComplete?: boolean;
}

const getNewPlanTagAppearence = (planChangeInProgress: boolean) => {
  if (planChangeInProgress) return 'success';
  return 'primary';
};

function UpsellBodyCard({
  currentPlan,
  newPlan,
  planChangeInProgress,
  bulletProgress = 0,
  goToPlansPage,
  planChangeComplete = false,
}: Readonly<UpsellBodyCardProps>) {
  const t = useTranslationBilling();
  const isMobileDevice = useIsMobileDevice();
  const getBulletsTitle = () => {
    if (planChangeComplete) return t('upsellFlow.modal.bulletsHeaderComplete');
    if (planChangeInProgress)
      return t('upsellFlow.confimration.bulletsLoading', {
        plan: newPlan.name,
      });

    return t('upsellFlow.modal.bulletsHeader', {
      plan: newPlan.name,
    });
  };

  return (
    <>
      <Card>
        <PlanChangePreview
          isLoading={planChangeInProgress}
          currentPlan={currentPlan}
          newPlan={newPlan}
          planChangeComplete={planChangeComplete}
          newPlanTagAppearence={getNewPlanTagAppearence(planChangeComplete)}
          onClickNewPlanName={
            isMobileDevice || planChangeInProgress || planChangeComplete
              ? undefined
              : goToPlansPage
          }
        />
        <Box>
          {newPlan.upsellBullets.length ? (
            <UpsellBullets
              bullets={newPlan.upsellBullets}
              progress={bulletProgress}
              title={getBulletsTitle()}
            />
          ) : (
            <Text>{t('upsellFlow.modal.noBullets')}</Text>
          )}
        </Box>
      </Card>
      {!planChangeComplete && !planChangeInProgress && (
        <Box marginTop="4">
          <Text>{t('upsellFlow.modal.footer')}</Text>
        </Box>
      )}
    </>
  );
}

export default UpsellBodyCard;
