import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  BILLING_GO_TO_ANNUAL_DISCOUNT,
  BILLING_GO_TO_PAY_PLAN_CLICK,
  BILLING_UPSELL_MODAL_CLOSE,
  BILLING_UPSELL_MODAL_SHOW,
  BILLING_UPSELL_PLANS_PAGE_CLICK,
  BILLING_UPSELL_PLAN_CHANGE,
  BILLING_POST_UPSELL_ADBAR_CLICK,
  BILLING_DOWNGRADE_PLAN,
  BILLING_SOFT_BLOCK_PAYMENT_CLICK,
  BILLING_SOFT_BLOCK_MODAL_SHOW,
  TEST_DISCOUNTS_ADBAR_SHOW,
  TEST_DISCOUNTS_ADBAR_CLICK,
} from 'config/trackingEvents';

export const trackingBillingGoToPayPlanClick = (
  position: 'top' | 'bottom',
  niceName: string | undefined,
) => {
  logEvent(BILLING_GO_TO_PAY_PLAN_CLICK, {
    position: position,
    nice_name: niceName ?? '',
  });
};

export const trackingBillingDiscountForCancelAccount = (storeId: string) => {
  logEvent(BILLING_GO_TO_ANNUAL_DISCOUNT, {
    store_id: storeId,
  });
};

interface UpsellEventParams {
  featureKey: string;
  currentPlan: string;
  newPlan: string;
  currentPlanValue: TypeFeaturesPlansListResponseDto;
  newPlanValue: TypeFeaturesPlansListResponseDto;
  source?: string;
}

type DowngradeEventParams = Pick<
  UpsellEventParams,
  'currentPlan' | 'newPlan' | 'source'
>;

export const trackingBillingUpsellModalShow = ({
  featureKey,
  currentPlan,
  newPlan,
  currentPlanValue,
  newPlanValue,
  source = '',
}: UpsellEventParams) => {
  logEvent(BILLING_UPSELL_MODAL_SHOW, {
    featureKey,
    currentPlan,
    newPlan,
    currentPlanValue: <string>currentPlanValue,
    newPlanValue: <string>newPlanValue,
    source,
  });
};

export const trackingBillingUpsellModalClose = ({
  featureKey,
  currentPlan,
  newPlan,
  currentPlanValue,
  newPlanValue,
  source = '',
}: UpsellEventParams) => {
  logEvent(BILLING_UPSELL_MODAL_CLOSE, {
    featureKey,
    currentPlan,
    newPlan,
    currentPlanValue: <string>currentPlanValue,
    newPlanValue: <string>newPlanValue,
    source,
  });
};

export const trackingBillingUpsellPlanChange = ({
  featureKey,
  currentPlan,
  newPlan,
  currentPlanValue,
  newPlanValue,
  source = '',
}: UpsellEventParams) => {
  logEvent(BILLING_UPSELL_PLAN_CHANGE, {
    featureKey,
    currentPlan,
    newPlan,
    currentPlanValue: <string>currentPlanValue,
    newPlanValue: <string>newPlanValue,
    source,
  });
};

export const trackingBillingDowngradeFreePlan = ({
  currentPlan,
  newPlan,
  source = '',
}: DowngradeEventParams) => {
  logEvent(BILLING_DOWNGRADE_PLAN, {
    currentPlan,
    newPlan,
    source,
  });
};

export const trackingBillingUpsellPlansPageClick = ({
  featureKey,
  currentPlan,
  newPlan,
  currentPlanValue,
  newPlanValue,
  source = '',
}: UpsellEventParams) => {
  logEvent(BILLING_UPSELL_PLANS_PAGE_CLICK, {
    featureKey,
    currentPlan,
    newPlan,
    currentPlanValue: <string>currentPlanValue,
    newPlanValue: <string>newPlanValue,
    source,
  });
};

export const trackingBillingPostUpsellAdbarClick = () => {
  logEvent(BILLING_POST_UPSELL_ADBAR_CLICK, {});
};

export const trackingBillingSoftBlockPaymentClick = ({
  featureKey = '',
}: Pick<UpsellEventParams, 'featureKey'>) => {
  logEvent(BILLING_SOFT_BLOCK_PAYMENT_CLICK, {
    featureKey,
  });
};

export const trackingBillingSoftBlockModalShow = ({
  featureKey = '',
}: Pick<UpsellEventParams, 'featureKey'>) => {
  logEvent(BILLING_SOFT_BLOCK_MODAL_SHOW, {
    featureKey,
  });
};

export const trackingBillingTestDiscountsAdBarShow = () => {
  logEvent(TEST_DISCOUNTS_ADBAR_SHOW, {});
};

export const trackingTestDiscounysAdBarClick = () => {
  logEvent(TEST_DISCOUNTS_ADBAR_CLICK, {});
};
