import { useMemo } from 'react';
import { Title, Thumbnail, Tag, Text } from '@nimbus-ds/components';
import { Link } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderTop, SortableDataList } from 'commons/components';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  RelatedProductInterface,
  RelatedProductType,
} from 'domains/Catalog/Products/pages/hooks/useProductForm';
import { SectionProductItemInterface } from 'domains/Online';
import {
  OnChangeRelatedProduct,
  RelatedProductAcceptTypes,
} from '../../useRelatedProduct';
import ProductItem from '../ProductItem/ProductItem';

type SortableProductList = Omit<SectionProductItemInterface, 'order' | 'stock'>;

interface EditRelatedProductModalAsideProps {
  onClose: () => void;
  onOpen: () => void;
  selectedRelatedProducts: RelatedProductInterface;
  mainProduct: RelatedProductType;
  editingType?: 'alternative' | 'complementary';
  type?: RelatedProductAcceptTypes;
  onChange: OnChangeRelatedProduct;
}

const MAX_RELATED_PRODUCTS = 8;

function EditRelatedProductModalAside({
  onClose,
  onChange,
  onOpen,
  type,
  mainProduct,
  selectedRelatedProducts,
}: Readonly<EditRelatedProductModalAsideProps>) {
  const t = useTranslationCatalog();

  const relatedProducts = useMemo(() => {
    if (type === 'ALTERNATIVE') {
      return selectedRelatedProducts?.alternative || [];
    }
    return selectedRelatedProducts?.complementary || [];
  }, [selectedRelatedProducts, type]);

  const availableProducts = useMemo(
    () =>
      relatedProducts.map((item) => ({
        ...item,
        productId: item.id,
        imageUrl: item.imageUrl,
        name: item.name,
        visible: item.visible,
      })) || [],
    [relatedProducts],
  );

  const hasLessThanTheMax = useMemo(
    () => relatedProducts.length < MAX_RELATED_PRODUCTS,
    [relatedProducts],
  );

  const headerNavigation = { onClick: onClose };

  const parseToRelatedProduct = (
    product: SortableProductList,
  ): RelatedProductType => ({
    id: product.productId,
    name: product.name,
    imageUrl: product.imageUrl,
    visible: product.visible,
  });

  const removeProduct = (productId: string) => {
    const newProducts = relatedProducts.filter(
      (product) => product.id !== productId,
    );
    onChange(newProducts);
  };

  const typeText = useMemo(() => {
    if (type === 'ALTERNATIVE') {
      return t('products.detail.relatedProduct.modal.alternative');
    }
    return t('products.detail.relatedProduct.modal.complementary');
  }, [type, t]);

  return (
    <ModalAside
      isOpen
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={
        <Stack column align="stretch">
          <Title>
            {t('products.detail.relatedProduct.modal.title', {
              type: typeText,
            })}
          </Title>
          <Text>{t('products.detail.relatedProduct.modal.subtitle')}</Text>
          <Stack>
            <Thumbnail
              src={mainProduct.imageUrl}
              alt={mainProduct.name}
              width="36px"
              height="36px"
            />
            <Text>{mainProduct.name}</Text>
          </Stack>
          <Stack>
            {hasLessThanTheMax ? (
              <Link
                icon={PlusCircleIcon}
                iconPosition="start"
                appearance="primary"
                onClick={onOpen}
              >
                {t('products.detail.relatedProduct.select')}
              </Link>
            ) : (
              <Tag appearance="warning">
                <Stack>
                  <Text>
                    {t('products.detail.relatedProduct.modal.selectedItems', {
                      selected: availableProducts.length,
                    })}
                  </Text>
                </Stack>
              </Tag>
            )}
          </Stack>
        </Stack>
      }
    >
      <Stack column align="stretch">
        <Stack.Item>
          {!!availableProducts?.length && (
            <SortableDataList<SortableProductList>
              customIdKey="productId"
              items={availableProducts}
              onChange={(items) => {
                const relatedProducts = items.map(parseToRelatedProduct);
                onChange(relatedProducts);
              }}
              renderItem={(product) => (
                <ProductItem
                  product={product as SectionProductItemInterface}
                  onRemove={removeProduct}
                />
              )}
            />
          )}
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default EditRelatedProductModalAside;
