import { useEffect } from 'react';
import useScript from 'commons/hooks/useScript';
import { useGetPlan, useGetUserInfo } from 'domains/Auth/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { config } from './config';

interface UserGuiding {
  identify: (email: string, config: Record<string, string | number>) => void;
}

declare global {
  interface Window {
    userGuiding: UserGuiding;
  }
}

function UserGuiding(): null {
  const { email } = useGetUserInfo();
  const {
    id: storeId,
    country: countryCode,
    url: storeUrl,
    createdAt,
    currentSegment,
  } = useStoreInfo();
  const { niceName } = useGetPlan();

  const statusScript = useScript(
    `https://static.userguiding.com/media/user-guiding-${config.id}-embedded.js`,
  );

  useEffect(() => {
    if (statusScript === 'ready') {
      const registerIdentify = (identify: UserGuiding['identify']) => {
        identify(email, {
          email,
          storeId,
          signUpAt: createdAt,
          storePlan: niceName,
          storeUrl,
          statusByOrder: currentSegment,
          countryCode,
        });
      };

      let attempts = 0;
      const maxAttempts = 10;
      const interval = setInterval(() => {
        if (window.userGuiding && window.userGuiding.identify) {
          registerIdentify(window.userGuiding.identify);
          clearInterval(interval);
        } else {
          attempts++;
          if (attempts >= maxAttempts) {
            console.error(
              'No UserGuiding and identify found in window, attempts:',
              attempts,
            );
            clearInterval(interval);
          }
        }
      }, 500);
    }
  }, [
    statusScript,
    email,
    storeId,
    createdAt,
    niceName,
    storeUrl,
    currentSegment,
    countryCode,
  ]);

  return null;
}

export default UserGuiding;
