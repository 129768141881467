import { Tag, Text } from '@nimbus-ds/components';
import { PayOrderStatus } from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PaymentStatusTagProps {
  paymentStatus: PayOrderStatus | undefined;
}

function PaymentStatusTag({
  paymentStatus,
}: PaymentStatusTagProps): JSX.Element | null {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentStatusTag',
  );

  switch (paymentStatus) {
    case 'PENDING':
      return (
        <Tag appearance="warning">
          <Text fontSize="caption">{t('pending')}</Text>
        </Tag>
      );
    case 'PAID':
      return (
        <Tag appearance="success">
          <Text fontSize="caption">{t('received')}</Text>
        </Tag>
      );
    case 'FAILED':
      return (
        <Tag appearance="danger">
          <Text fontSize="caption">{t('failed')}</Text>
        </Tag>
      );
    case 'IN_PROCESS':
      return (
        <Tag appearance="warning">
          <Text fontSize="caption">{t('inProgress')}</Text>
        </Tag>
      );
    default:
      return null;
  }
}

export default PaymentStatusTag;
