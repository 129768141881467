import { SHIPPING_METHOD_CODE_OCA } from 'domains/Shipping/ShippingMethods/constants';

const SHIPPING_APP_REGEX = /(api_)[A-Za-z0-9_]{1,64}/g;

export function hasShippingApp(shipping: string): boolean {
  return SHIPPING_APP_REGEX.test(shipping);
}

export function isShippingMethodOca(shipping: string): boolean {
  return shipping === SHIPPING_METHOD_CODE_OCA;
}
