import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getOcaAgenciesAction,
  getOcaAgenciesData,
  getOcaAgenciesZipcode,
} from '../../../../deliveryMethodsSlice';

function useOcaAgencies() {
  const dispatch = useAppDispatch();
  const ocaAgencies = useSelector(getOcaAgenciesData);
  const ocaAgenciesZipcode = useSelector(getOcaAgenciesZipcode);

  const getOcaAgencies = useCallback(
    async (zipcode: string) => {
      const result = await dispatch(getOcaAgenciesAction(zipcode));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return {
    ocaAgencies,
    ocaAgenciesZipcode,
    getOcaAgencies,
  };
}

export default useOcaAgencies;
