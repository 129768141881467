import { Chip, Card, Text } from '@nimbus-ds/components';
import { CalendarIcon } from '@nimbus-ds/icons';
import { Button, Stack } from '@tiendanube/components';
import { SlidersIcon } from '@tiendanube/icons';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { useResponsive } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import { STATS_FILTERS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import { FiltersStatisticsInterface } from 'domains/Statistics/types';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import { OptionPeriodCompareType } from '../SelectComparePeriod';
import { OptionPeriodType } from '../SelectMainPeriod';

const LABELS_MAIN_PERIOD: Omit<Record<OptionPeriodType, string>, 'custom'> = {
  currentDay: 'statistics.filters.cardLabels.currentDay',
  currentWeek: 'statistics.filters.cardLabels.currentWeek',
  currentMonth: 'statistics.filters.cardLabels.currentMonth',
  currentQuarter: 'statistics.filters.cardLabels.currentQuarter',
  currentYear: 'statistics.filters.cardLabels.currentYear',
};

const LABELS_COMPARE_PERIOD: Omit<
  Record<OptionPeriodCompareType, string>,
  'custom'
> = {
  samePeriod: 'statistics.filters.cardLabels.samePeriod',
  samePeriodLastYear: 'statistics.filters.cardLabels.samePeriodLastYear',
};

interface FilteStatisticsCardProps {
  values: FiltersStatisticsInterface;
  onClick: () => void;
  onRemove: () => void;
}

function FilteStatisticsCard({
  values,
  onClick,
  onRemove,
}: FilteStatisticsCardProps) {
  const t = useTranslationStatistics();
  const { isDesktop, isMobile } = useResponsive();
  const enabled = useSupportsStatsPlan(STATS_PLAN_1_AND_2);

  const onRemoveWithUpsell = useUpsellFlow({
    title: t('statistics.upsellTitle'),
    featureKey: FEATURE_NEW_ADMIN_STATS,
    minValue: STATS_PLAN_1_AND_2,
    trackingSource: STATS_FILTERS,
    callback: onRemove,
  });

  // This can be removed once statistics trials are over -- Just use onRemoveWithUpsell
  const onRemoveComparePeriod = () => {
    if (enabled) {
      onRemove();
    } else {
      onRemoveWithUpsell();
    }
  };

  const customMainPeriodText =
    values.mainPeriodFrom &&
    values.mainPeriodTo &&
    t(`statistics.filters.cardLabels.customMainPeriod`, {
      from: dateFormat(values.mainPeriodFrom, Format.DD_MM_YYYY),
      to: dateFormat(values.mainPeriodTo, Format.DD_MM_YYYY),
    });

  const customComparePeriodText =
    values.comparePeriodFrom &&
    values.comparePeriodTo &&
    t(`statistics.filters.cardLabels.customComparePeriod`, {
      from: dateFormat(values.comparePeriodFrom, Format.DD_MM_YYYY),
      to: dateFormat(values.comparePeriodTo, Format.DD_MM_YYYY),
    });

  return (
    <Card>
      <Stack justify="space-between">
        <Stack
          column={isMobile}
          align={isMobile ? 'flex-start' : 'center'}
          spacing="tight"
        >
          <Text>
            {customMainPeriodText || t(LABELS_MAIN_PERIOD[values.mainPeriod])}
          </Text>
          {values.comparePeriod && (
            <Chip
              onClick={onRemoveComparePeriod}
              removable
              text={
                customComparePeriodText ||
                t(LABELS_COMPARE_PERIOD[values.comparePeriod])
              }
              icon={<CalendarIcon />}
            />
          )}
        </Stack>
        <Button
          ariaLabel={`${t('statistics.filters.button')}`}
          onClick={onClick}
          icon={SlidersIcon}
          iconPosition="end"
        >
          {isDesktop ? `${t('statistics.filters.button')}` : ''}
        </Button>
      </Stack>
    </Card>
  );
}

export default FilteStatisticsCard;
