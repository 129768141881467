import { ModalAssignMetafields } from 'domains/Metafields/components';
import AssignCategoriesModal from './AssignCategoriesModal';
import { ModalProductsEditPrices } from './ModalProductsEditPrices';
import { ModalsBulkActions } from './ModalsBulkActions';
import { useBulkActions } from '../BulkActionsProvider';
import { BulkActionTypeEnum } from '../hooks/useBulkActionProducts/types';

function BulkActions(): JSX.Element | null {
  const { showModal, selectedAction, selectedRowsId, selectAll } =
    useBulkActions();

  const isUpdatePriceModalOpen =
    selectedAction === BulkActionTypeEnum.UPDATE_PRICE;
  const isAssignCategoriesModalOpen =
    selectedAction === BulkActionTypeEnum.ASSIGN_CATEGORIES;
  const isAssignMetafieldsModalOpen =
    selectedAction === BulkActionTypeEnum.ASSIGN_METAFIELDS;
  const isAssignProductMetafieldsModalOpen =
    selectedAction === BulkActionTypeEnum.ASSIGN_PRODUCT_METAFIELDS;

  if (!showModal) return null;

  return (
    <>
      {isUpdatePriceModalOpen && <ModalProductsEditPrices />}
      {isAssignCategoriesModalOpen && <AssignCategoriesModal />}
      {isAssignMetafieldsModalOpen && (
        <ModalAssignMetafields
          selectedRowsId={selectedRowsId}
          selectAll={selectAll}
          ownerResource="product_variant"
        />
      )}
      {isAssignProductMetafieldsModalOpen && (
        <ModalAssignMetafields
          selectedRowsId={selectedRowsId}
          selectAll={selectAll}
          ownerResource="product"
        />
      )}
      {!isUpdatePriceModalOpen && !isAssignCategoriesModalOpen && (
        <ModalsBulkActions />
      )}
    </>
  );
}
export default BulkActions;
