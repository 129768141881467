import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import {
  AppItemCard,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationSalesChannels from 'domains/SalesChannels/useTranslationSalesChannels';
import {
  MarketplacesAppsPageError,
  MarketplacesAppsPageSkeleton,
} from './components';
import { useGetMarketplacesAppsQuery } from '../../marketplacesAppsApi';

function MarketplacesAppsPage() {
  const t = useTranslationSalesChannels('marketplacesApps');
  const language = useTranslationLanguage();
  const {
    data: apps,
    isLoading,
    isError,
    refetch,
  } = useGetMarketplacesAppsQuery();

  return (
    <IonPageStratus
      width="medium"
      headerTop={<HeaderTop />}
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      {isLoading && <MarketplacesAppsPageSkeleton />}
      {isError && <MarketplacesAppsPageError getMarketplacesApps={refetch} />}
      {!isLoading && !isError && !!apps && (
        <Box
          display="grid"
          gridTemplateColumns={{
            lg: '1fr 1fr 1fr',
            md: '1fr',
            xs: '1fr',
          }}
          gridGap="4"
        >
          {apps.map((marketplaceApp) => (
            <AppItemCard key={marketplaceApp.name} app={marketplaceApp} />
          ))}
        </Box>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_APPS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default MarketplacesAppsPage;
