import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

const { Header, Body, Footer } = Modal;

interface StatusPromotionModalProps {
  show: boolean;
  isActive: boolean;
  onClose: () => void;
  onChangeStatus: () => void;
}

export function StatusPromotionModal({
  show,
  isActive,
  onClose,
  onChangeStatus,
}: Readonly<StatusPromotionModalProps>) {
  const prefix = !isActive ? 'enable' : 'disable';
  const t = useTranslationMarketing(`promotions.statusModal.${prefix}`);

  const handleChangeStatus = () => {
    onChangeStatus();
    onClose();
  };
  return (
    <Modal open={show} onDismiss={onClose}>
      <Header title={t('title')} />
      <Body padding="none">
        <Text>{t('body')}</Text>
      </Body>
      <Footer>
        <CancelAndConfirmButtons
          onCancel={onClose}
          onConfirm={handleChangeStatus}
          cancelText={t('cancel')}
          confirmText={t('confirm')}
        />
      </Footer>
    </Modal>
  );
}
