import { FREE_SHIPPING_ROUTES } from './FreeShipping';
import { MARKETING_AUTOMATION_ROUTES } from './MarketingAutomation';
import { PROMOTIONS_ROUTES } from './Promotions';

export const MARKETING_ROUTES = {
  couponList: '/coupons',
  newCoupon: '/coupons/new',
  editCoupon: '/coupons/:id',
  marketingApps: '/marketing/apps',
  ...FREE_SHIPPING_ROUTES,
  ...PROMOTIONS_ROUTES,
  ...MARKETING_AUTOMATION_ROUTES,
};
