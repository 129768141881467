import { combineReducers } from 'redux';
import {
  reducer as abandonedCartsReducer,
  InterfaceAbandonedCartsSchema,
} from './AbandonedCart/abandonedCartsSlice';
import {
  reducer as draftOrdersReducer,
  InterfaceDraftOrdersSchema,
} from './DraftOrders/draftOrdersSlice';
import {
  reducer as orderMetafieldsReducer,
  InterfaceOrderMetafieldsSchema,
} from './OrderMetafields/orderMetafieldsSlice';
import {
  reducer as orderReducer,
  InterfaceOrdersSchema,
} from './Orders/ordersSlice';

export interface OrdersStateInterface {
  orders: InterfaceOrdersSchema;
  orderMetafieldsSlice: InterfaceOrderMetafieldsSchema;
  draftOrders: InterfaceDraftOrdersSchema;
  abandonedCarts: InterfaceAbandonedCartsSchema;
}

const ordersReducer = combineReducers({
  orders: orderReducer,
  orderMetafields: orderMetafieldsReducer,
  draftOrders: draftOrdersReducer,
  abandonedCarts: abandonedCartsReducer,
});

export default ordersReducer;
