import { Box, Card, Link, Text } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { useTranslationAccount } from 'domains/Account/hooks';

function CancelStoreBusinessDataPageCard() {
  const t = useTranslationAccount();

  return (
    <Card padding="base" backgroundColor="neutral-surface">
      <Box display="flex" flexDirection="column" gap="1">
        <Link
          as={LinkRoute}
          to={ACCOUNT_ROUTES.shopCancellation}
          appearance="danger"
          textDecoration="underline"
          fontSize="caption"
        >
          {t('businessDataPage.cancelStoreAlert.title')}
        </Link>
        <Text fontSize="caption" fontWeight="regular" textAlign="left">
          {t('businessDataPage.cancelStoreAlert.text')}
        </Text>
      </Box>
    </Card>
  );
}
export default CancelStoreBusinessDataPageCard;
