import { OwnerResourceType } from '@tiendanube/common';
import { Alert } from '@tiendanube/components';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields/useTraslationsMetafields';

interface AlertMetafieldsProps {
  show: boolean;
  ownerResource: OwnerResourceType;
}

function AlertMetafields({
  show,
  ownerResource,
}: Readonly<AlertMetafieldsProps>) {
  const t = useTranslationMetafields();

  const tAlert = `assign${
    ownerResource === 'product_variant' ? 'ProductVariants' : 'Products'
  }MetafieldsModal`;

  const alertMetafieldsSuccessTitle = t(`${tAlert}.successTitle`);
  const alertMetafieldsSuccessText = t(`${tAlert}.successText`);

  return (
    <Alert show={show} title={alertMetafieldsSuccessTitle}>
      {alertMetafieldsSuccessText}
    </Alert>
  );
}
export default AlertMetafields;
