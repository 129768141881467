import { useEffect } from 'react';
import { Alert, Link, Text, Title } from '@nimbus-ds/components';
import {
  MinInstallmentValueDto,
  UpdateMercadoPagoSettingsRequestDto,
} from '@tiendanube/common';
import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { BaseCard, InterfaceNameValue } from '@tiendanube/components';
import { InputNumber, Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useUpdateMercadoPagoInstallments } from 'domains/Payments/PaymentProviders/hooks';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface InstallmentsCardProps {
  minInstallmentValues: MinInstallmentValueDto[];
  values: UpdateMercadoPagoSettingsRequestDto;
  errors: Partial<Record<string, string>>;
  handleOnChangeGroup: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function InstallmentsCard({
  minInstallmentValues,
  values,
  errors,
  handleOnChangeGroup,
}: InstallmentsCardProps): JSX.Element {
  const t = useTranslationPayments();
  const { isSuccess, isError, isLoading, updateInstallments } =
    useUpdateMercadoPagoInstallments();

  const showInstallmentInputs =
    values.integrationType === 'transparent' &&
    values.paymentMethodTypes.includes('credit_card');

  const handleUpdateInstallments = () => {
    updateInstallments();
    invokeEventPayments('updateInstallments', PaymentProvidersCode.mercadopago);
  };

  const handleChangeInstallment = (data: InterfaceNameValue) => {
    handleOnChangeGroup('minInstallmentValues', data);
  };

  useEffect(() => {
    if (isSuccess)
      invokeEventPayments(
        'updateInstallments',
        PaymentProvidersCode.mercadopago,
        true,
      );
    if (isError)
      invokeEventPayments(
        'updateInstallments',
        PaymentProvidersCode.mercadopago,
        false,
      );
  }, [isSuccess, isError]);

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title as="h3">
          {t('paymentProvider.settings.mercadopago.installments.title')}
        </Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <Alert
            title={t(
              'paymentProvider.settings.mercadopago.installments.alertTitle',
            )}
            appearance="primary"
          >
            <Text>
              {t('paymentProvider.settings.mercadopago.installments.alertBody')}
            </Text>
            {!isLoading ? (
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={handleUpdateInstallments}
              >
                {t(
                  'paymentProvider.settings.mercadopago.installments.alertLink',
                )}
              </Link>
            ) : (
              <Link.Skeleton width="120px" />
            )}
          </Alert>
          {showInstallmentInputs && (
            <Stack column align="stretch" spacing="tight">
              <Text fontSize="caption">
                {t(
                  'paymentProvider.settings.mercadopago.installments.minInstallmentValue',
                )}
              </Text>
              {minInstallmentValues.map(({ currency, defaultMinValue }) => (
                <InputNumber
                  key={currency}
                  type="float"
                  name={currency}
                  prependLabel={currency}
                  min={`${defaultMinValue}`}
                  value={values.minInstallmentValues?.[currency] || ''}
                  onChange={handleChangeInstallment}
                  appearance={
                    errors?.[`installments[${currency}]`]
                      ? 'validation_error'
                      : 'default'
                  }
                  helpText={t(errors?.[`installments[${currency}]`] || '', {
                    value: 0,
                  })}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default InstallmentsCard;
