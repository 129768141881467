import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  ProductCloneResponseDto,
  ProductDetailsResponseDto,
} from '@tiendanube/common';
import { RootStateType, useAppDispatch } from 'App/store';
import { StatusObjectType } from 'commons/types';
import { cleanUpInventory } from 'domains/Catalog/Inventory/inventorySlice';
import {
  CloneProductToParamsInterface,
  UpdateProductParamsInterface,
} from 'domains/Catalog/Products/productsServices';
import {
  cleanMetafieldsPersistsStatus as cleanMetafieldsPersistsStatusAction,
  cleanCreateHighlightProductStatus as cleanCreateHighlightProductStatusAction,
  MetafieldsPersistsStatusType,
  updateProduct as updateProductAction,
} from 'domains/Catalog/Products/productsSlice';
import {
  createMetafieldsPersistsStatusById,
  createHighlightProductStatus as createHighlightProductStatusSelector,
  createGetProductByIdStatus,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useProductActions } from '../../../hooks';
import { ProductFormState } from '../../../hooks/useProductForm/types';
import useDigestedProduct from '../useDigestedProduct';

export interface UseProductEditResult {
  product: ProductFormState;
  status: StatusObjectType;
  metafieldsPersistsStatus: MetafieldsPersistsStatusType;
  updateProduct: (
    params: UpdateProductParamsInterface,
  ) => Promise<ProductDetailsResponseDto>;
  deleteProduct: (id: string) => Promise<void>;
  cloneProduct: (
    productToClone: CloneProductToParamsInterface,
  ) => Promise<ProductCloneResponseDto>;
  cleanMetafieldsPersistsStatus: () => void;
  cleanCreateHighlightProductStatus: () => void;
  updateHighlightProductError: boolean;
}

function useEditProduct(id: string): UseProductEditResult {
  const dispatch = useAppDispatch();
  const product = useDigestedProduct(id);

  const status = useSelector((state: RootStateType) =>
    createGetProductByIdStatus(state),
  );

  const getMetafieldsPersistsStatusById = useMemo(
    createMetafieldsPersistsStatusById,
    [],
  );

  const createHighlightProductStatus = useSelector(
    createHighlightProductStatusSelector,
  );

  const metafieldsPersistsStatusById = useSelector((state: RootStateType) =>
    getMetafieldsPersistsStatusById(state, id),
  );

  const { cloneProduct, deleteProduct } = useProductActions();

  const updateProduct = useCallback(
    async (data: UpdateProductParamsInterface) => {
      const result = await dispatch(updateProductAction(data));
      dispatch(cleanUpInventory());
      return unwrapResult(result);
    },
    [dispatch],
  );

  const cleanMetafieldsPersistsStatus = useCallback(async () => {
    dispatch(cleanMetafieldsPersistsStatusAction({ id }));
  }, [dispatch, id]);

  const cleanCreateHighlightProductStatus = useCallback(async () => {
    dispatch(cleanCreateHighlightProductStatusAction());
  }, [dispatch]);

  const returnMemo = useMemo(
    () => ({
      product,
      status,
      metafieldsPersistsStatus: metafieldsPersistsStatusById,
      updateHighlightProductError:
        product &&
        !!(
          product.sectionCodes.includes('update-error') ||
          createHighlightProductStatus?.includes('update-error')
        ),
      deleteProduct,
      cloneProduct,
      updateProduct,
      cleanMetafieldsPersistsStatus,
      cleanCreateHighlightProductStatus,
    }),
    [
      product,
      status,
      metafieldsPersistsStatusById,
      createHighlightProductStatus,
      deleteProduct,
      cloneProduct,
      updateProduct,
      cleanMetafieldsPersistsStatus,
      cleanCreateHighlightProductStatus,
    ],
  );

  return returnMemo;
}

export default useEditProduct;
