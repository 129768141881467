import { useEffect, useState } from 'react';
import { useAsyncFunc, useTimeOut, WrappedFuncType } from 'commons/hooks';

type UseAsyncInputFuncResult<P, R> = [
  WrappedFuncType<P, R>,
  boolean,
  boolean,
  boolean,
];

function useAsyncInput<TProps, TResult>(
  callback: (...args: [TProps?]) => Promise<TResult | void>,
): UseAsyncInputFuncResult<TProps, TResult> {
  const [success, setSuccess] = useState(false);

  const setSuccessFalseWithDelay = useTimeOut(() => {
    setSuccess(false);
  });

  const setErrorFalseWithDelay = useTimeOut(() => {
    clear();
  });

  const [wrappedFunc, isLoading, isError, clear] = useAsyncFunc<
    TProps,
    TResult
  >(async (...args) => {
    const res = await callback(...args);
    setSuccess(true);
    setSuccessFalseWithDelay();
    return res;
  });

  useEffect(() => {
    if (isError) {
      setErrorFalseWithDelay();
    }
  }, [isError, setErrorFalseWithDelay]);

  return [wrappedFunc, isLoading, isError, success];
}

export default useAsyncInput;
