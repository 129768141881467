import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import createGenericAsyncActionCase from 'commons/utils/createGenericAsyncActionCase';
import { initialState } from './utils';
import {
  getPagesService,
  getTemplatesService,
  getPageByIdService,
  createPageService,
  updatePageByIdService,
  deletePageByIdService,
  getTemplateByIdService,
  getAllPagesService,
  generateContentsPage,
} from '../contentPagesService';

export const getPagesAction = createAsyncThunk(
  'pages/getPages',
  getPagesService,
);
export const getAllPagesAction = createAsyncThunk(
  'pages/getAllPages',
  getAllPagesService,
);

export const getMorePagesAction = createAsyncThunk(
  'pages/getMorePagesAction',
  getPagesService,
);

export const getTemplatesAction = createAsyncThunk(
  'pages/getTemplates',
  getTemplatesService,
);

export const getTemplateByIdAction = createAsyncThunk(
  'pages/getTemplateByIdAction',
  getTemplateByIdService,
);

export const getPageByIdAction = createAsyncThunk(
  'pages/getPageById',
  getPageByIdService,
);

export const createPageAction = createAsyncThunk(
  'pages/createPage',
  createPageService,
);

export const updatePageByIdAction = createAsyncThunk(
  'pages/updatePage',
  updatePageByIdService,
);

export const deletePageByIdAction = createAsyncThunk(
  'pages/deletePageById',
  deletePageByIdService,
);

export const generateContentsPageAction = createAsyncThunk(
  'pages/generate',
  generateContentsPage,
);

const contentPagesSlice = createSlice({
  name: 'contentPages',
  initialState,
  reducers: {
    cleanPageCreationAction: (state) => {
      state.pageCreation.status = initialState.pageCreation.status;
    },
    cleanPageUpdateAction: (state) => {
      state.pageUpdate.status = initialState.pageUpdate.status;
      state.pageUpdate.data = initialState.pageUpdate.data;
      state.pageDetail.data = initialState.pageDetail.data;
    },
    cleanTemplatesStatusAction: (state) => {
      state.templatesList.status = initialState.templatesList.status;
    },
    cleanTemplateByIdStatusAction: (state) => {
      state.templateById.status = initialState.templateById.status;
    },
    cleanDeletePageByIdStatusAction: (state) => {
      state.pageDelete.status = initialState.pageDelete.status;
    },
  },
  extraReducers: (builder) => {
    createGenericAsyncActionCase(
      builder,
      getPagesAction,
      initialState,
      'pagesList',
    );
    createGenericAsyncActionCase(
      builder,
      getAllPagesAction,
      initialState,
      'allPagesList',
    );
    createGenericAsyncActionCase(
      builder,
      getTemplatesAction,
      initialState,
      'templatesList',
    );
    createGenericAsyncActionCase(
      builder,
      getTemplateByIdAction,
      initialState,
      'templateById',
    );
    createGenericAsyncActionCase(
      builder,
      getPageByIdAction,
      initialState,
      'pageDetail',
    );
    createGenericAsyncActionCase(
      builder,
      createPageAction,
      null,
      'pageCreation',
    );
    createGenericAsyncActionCase(
      builder,
      updatePageByIdAction,
      null,
      'pageUpdate',
    );
    createGenericAsyncActionCase(
      builder,
      deletePageByIdAction,
      null,
      'pageDelete',
    );
    builder
      .addCase(getMorePagesAction.pending, (state) => {
        state.pagesList.refreshing = 'loading';
      })
      .addCase(getMorePagesAction.rejected, (state) => {
        state.pagesList.refreshing = 'error';
      })
      .addCase(getMorePagesAction.fulfilled, (state, { payload }) => {
        state.pagesList.refreshing = 'success';
        if (state.pagesList?.data) {
          state.pagesList.data.ids = [
            ...(state.pagesList?.data.ids || []),
            ...(payload?.ids || []),
          ];
          state.pagesList.data.results = [
            ...(state.pagesList?.data?.results || []),
            ...(payload?.results || []),
          ];

          state.pagesList.data.pagination = payload.pagination;
          state.pagesList.data.filter = payload.filter;
          state.pagesList.data.status = payload.status;
        }
      });
  },
});

export const { reducer } = contentPagesSlice;
export const {
  cleanPageCreationAction,
  cleanPageUpdateAction,
  cleanTemplatesStatusAction,
  cleanTemplateByIdStatusAction,
  cleanDeletePageByIdStatusAction,
} = contentPagesSlice.actions;
