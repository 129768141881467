import { Tag, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { Skeleton } from './Skeleton';
import { DNSVerificationModal } from '../../../../AttachDomainModal/components/DNSVerificationModal';
import { Actions } from '../../Actions';
import { CheckDomainErrorsLink } from '../../CheckDomainErrorsLink';
import { DomainSSLStatusTag } from '../../DomainSSLStatusTag';
import { DomainStatusTag } from '../../DomainStatusTag';
import { PrimaryDomainTag } from '../../PrimaryDomainTag';
import { ReactivateDomainLink } from '../../ReactivateDomainLink';
import { DomainsListRowProps } from '../../types';

export function DomainsListMobileRow({
  domain,
  ourDomain,
  domainsCount,
  onDeleteDomain,
  isDeleting,
  isUpdating,
  onSetPrimaryDomain,
}: Readonly<DomainsListRowProps>) {
  const t = useTranslationConfigurations('domains');
  const [verificationModalOpen, openVerificationModal, closeVerificationModal] =
    useModal();

  return (
    <>
      <DataList.Row backgroundColor="transparent">
        <Stack column align="stretch" gap="4" spacing="tight">
          <Stack justify="space-between" align="center" wrap>
            <Text>{domain.name}</Text>
            {domain.isOurDomain && (
              <Tag appearance="neutral">{t('tags.default')}</Tag>
            )}
            {domain.isPrimaryDomain && <PrimaryDomainTag />}
          </Stack>
          <Stack justify="space-between" align="center">
            <Stack wrap>
              {!domain.isOurDomain && isUpdating ? (
                <Tag.Skeleton />
              ) : (
                <DomainStatusTag
                  domainId={domain.id}
                  isOurDomain={domain.isOurDomain}
                  status={domain.status.domain}
                />
              )}
              {!domain.isOurDomain && isUpdating ? (
                <Tag.Skeleton />
              ) : (
                <DomainSSLStatusTag
                  isOurDomain={domain.isOurDomain}
                  status={domain.status.ssl}
                />
              )}
            </Stack>
            <Actions
              domain={domain}
              ourDomain={ourDomain}
              domainsCount={domainsCount}
              isDeleting={isDeleting}
              onDelete={onDeleteDomain}
              onSetPrimaryDomain={onSetPrimaryDomain}
            />
          </Stack>
          {!domain.isOurDomain && <ReactivateDomainLink />}
          {!domain.isOurDomain && (
            <CheckDomainErrorsLink
              domainId={domain.id}
              onClick={openVerificationModal}
            />
          )}
        </Stack>
      </DataList.Row>
      <DNSVerificationModal
        isOpen={verificationModalOpen}
        onClose={closeVerificationModal}
        isSubdomain={domain.isSubdomain}
        domainId={domain.id}
        domainName={domain.name}
        ourDomain={ourDomain}
      />
    </>
  );
}

DomainsListMobileRow.Skeleton = Skeleton;
