import { Box, Text } from '@nimbus-ds/components';
import { CurrencyType } from '@tiendanube/common';
import { CurrencyPrice } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface SummaryBreakdownProps {
  readonly newTotal: number;
  readonly discountTotal: number;
  readonly currency: CurrencyType;
  readonly productsQuantity: number;
}

function SummaryBreakdown({
  newTotal,
  discountTotal,
  currency,
  productsQuantity,
}: SummaryBreakdownProps) {
  const t = useTranslationOrders();

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        paddingTop="1"
        paddingBottom="1"
        justifyContent="space-between"
      >
        <Box flex="2" alignItems="center" justifyContent="space-between">
          <Text color="neutral-textHigh">
            {t(`editOrders.summary.subtotal`, { count: productsQuantity })}
          </Text>
        </Box>
        <CurrencyPrice price={newTotal} currency={currency} size="base" />
      </Box>
      {discountTotal > 0 && (
        <Box
          display="flex"
          flexDirection="row"
          paddingTop="1"
          paddingBottom="1"
          justifyContent="space-between"
        >
          <Box flex="2" alignItems="center" justifyContent="space-between">
            <Text color="neutral-textHigh">{t('Descuentos')}</Text>
          </Box>
          <CurrencyPrice
            appearance="danger"
            price={-discountTotal}
            currency={currency}
            size="base"
          />
        </Box>
      )}
    </Box>
  );
}

export default SummaryBreakdown;
