import { Card, Stack, Text } from '@tiendanube/components';
import { emptyIsNullInText } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

export interface BillingAddressCardInterface {
  billingName: string;
  zipcode: string;
  street: string;
  number: string;
  floor: string;
  city: string;
  province: string;
  country: string;
}

interface BillingAddressCardProps {
  billingAddressData: BillingAddressCardInterface;
}

function BillingAddressCard({ billingAddressData }: BillingAddressCardProps) {
  const t = useTranslationOrders();

  return (
    <Card title={t('abandonedCarts.billingAddressCard.title')} isCollapsible>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{billingAddressData.billingName}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text size="caption" appearance="light" block>
            {`${t('abandonedCarts.billingAddressCard.address')}`}
          </Text>
          <Text block>
            {`
              ${billingAddressData.street} 
              ${emptyIsNullInText(billingAddressData.number)} 
              ${billingAddressData.floor} 
              (${billingAddressData.zipcode})
            `}
          </Text>
          <Text block>
            {`${billingAddressData.city}, ${billingAddressData.province}, ${billingAddressData.country}`}
          </Text>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default BillingAddressCard;
