import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { getGlobalInfo } from 'domains/Statistics/statisticsSlice';
import useGetStatisticsGlobalInfoData from '../useGetStatisticsGlobalInfoData';
import useStatisticsFilterContext from '../useStatisticsFilterContext';
import useStatisticsStoredFilters from '../useStatisticsStoredFilters';

function useGetStatisticsGlobalInfo() {
  const dispatch = useAppDispatch();
  const data = useGetStatisticsGlobalInfoData();
  const { hasComparedFilter, openFilter } = useStatisticsFilterContext();

  const fetchStatisticsGlobalInfo = useCallback(() => {
    dispatch(getGlobalInfo());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchStatisticsGlobalInfo);

  return {
    ...data,
    hasComparedFilter,
    openFilter,
    fetchStatisticsGlobalInfo,
  };
}

export default useGetStatisticsGlobalInfo;
