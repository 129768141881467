import { Link, Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { useNavegate } from 'App/hooks';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields';

export default function InviteToCreateMetafieldsAlert() {
  const t = useTranslationMetafields();
  const { goTo } = useNavegate();

  const handleClick = () => {
    goTo('/settings/metafields');
  };

  return (
    <AlertSessionStorage
      appearance="primary"
      title={t(`createInviteCategories.title`)}
      keyName="showInviteToCreateCategoriesMetafieldsAlert"
    >
      <Text>{t(`createInviteCategories.subtitle`)}</Text>
      <Link onClick={handleClick}>{t(`createInviteCategories.link`)}</Link>
    </AlertSessionStorage>
  );
}
