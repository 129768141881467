import { Text, Box } from '@nimbus-ds/components';
import { DataList, IconButton } from '@tiendanube/components';
import { Repeater } from 'commons/components';

const { Row } = DataList;

function PagesListResultMobileSkeleton() {
  return (
    <DataList>
      <Repeater
        times={10}
        item={(index) => (
          <Row id={String(index)} key={index}>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              paddingLeft="2"
              paddingRight="2"
            >
              <Box display="flex" flexDirection="column" gap="2">
                <Text.Skeleton width="150px" />
                <Text.Skeleton width="75px" />
              </Box>
              <Box>
                <IconButton.Skeleton />
              </Box>
            </Box>
          </Row>
        )}
      />
    </DataList>
  );
}

export default PagesListResultMobileSkeleton;
