import { Alert, AlertProps } from '@nimbus-ds/components';
import useStorage from 'commons/hooks/useStorage';

type AlertSessionStorageProps = {
  keyName: string;
} & AlertProps;

function AlertSessionStorage({
  keyName,
  onRemove,
  ...rest
}: AlertSessionStorageProps) {
  const [showAlert, setShowAlert, loaded] = useStorage(keyName, true, true);

  function handleOnClick() {
    setShowAlert(false);
    onRemove?.();
  }

  const { show, ...restWithoutShow } = rest;

  return (
    <Alert
      show={loaded && showAlert && show}
      onRemove={handleOnClick}
      {...restWithoutShow}
    />
  );
}

export default AlertSessionStorage;
