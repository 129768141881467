import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import { InventoryFiltersInterface } from 'domains/Catalog/Inventory/inventoryServices';
import { applyFilters as applyFiltersAction } from 'domains/Catalog/Inventory/inventorySlice';
import { defaultFilters } from 'domains/Catalog/Inventory/inventorySlice/constants';
import { getAppliedFilters } from 'domains/Catalog/Inventory/inventorySlice/inventorySelectors';

function useInventoryAppliedFilters() {
  const dispatch = useAppDispatch();
  const { changeFilters } = useListFilters('inventory', defaultFilters);
  const filters = useSelector(getAppliedFilters);

  const hasFilters = Object.keys(filters).some(
    (key) => filters[key] && key !== 'page' && key !== 'locationId',
  );

  const applyFilters = useCallback(
    async (newFilters: InventoryFiltersInterface) => {
      changeFilters(newFilters);
      dispatch(applyFiltersAction(newFilters));
    },
    [dispatch, changeFilters],
  );

  return {
    filters,
    hasFilters,
    applyFilters,
  };
}

export default useInventoryAppliedFilters;
