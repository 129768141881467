import { useCallback } from 'react';

export interface useEmailMessageLinkProps {
  email: string;
  subject: string;
  body: string;
  url?: string;
  signature?: string;
}

function useEmailMessageLink(): (props: useEmailMessageLinkProps) => string {
  const getUrl = ({
    subject,
    body,
    url,
    email,
    signature,
  }: useEmailMessageLinkProps): string => {
    const subjectEncoded = encodeURIComponent(subject);
    const bodyEncoded = encodeURIComponent(body);
    const urlEncode = url ? url : '';
    const signatureEncode = signature
      ? encodeURIComponent(`\n\n${signature}`)
      : '';
    return `mailto:${email}?subject=${subjectEncoded}&body=${bodyEncoded}${urlEncode}${signatureEncode}`;
  };
  return useCallback(getUrl, []);
}

export default useEmailMessageLink;
