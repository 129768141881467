import { useSelector } from 'react-redux';
import { getTransactionFeeAdminDisabled } from '../../authSlice/authSelectors';

function useTransactionFeeAdminDisabled(): boolean {
  const transactionFeeAdminDisabled = useSelector(
    getTransactionFeeAdminDisabled,
  );
  return transactionFeeAdminDisabled;
}

export default useTransactionFeeAdminDisabled;
