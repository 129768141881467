import { ApplyCouponRequestDto } from '@tiendanube/common/src/domains/billing/ApplyCouponRequestDto';
import axios from 'App/axios';
import { APPLY_COUPON } from './urls';

const applyCoupon = async (couponData: ApplyCouponRequestDto) => {
  const { data } = await axios.post(APPLY_COUPON, couponData);
  return data;
};

export default {
  applyCoupon,
};
