import {
  Text,
  Stack,
  Label,
  DataTable,
  IconButton,
} from '@tiendanube/components';
import { cellWidthSettings } from '../MessagesListRow';

function MessagesListItemSkeleton(): JSX.Element {
  const { Row, Cell } = DataTable;

  return (
    <Row id="skeleton" align="center">
      <Cell basis={cellWidthSettings.messagesColBasis}>
        <Stack column spacing="tight">
          <Text.Skeleton width="large" />
          <Text.Skeleton width="large" />
        </Stack>
      </Cell>
      <Cell basis={cellWidthSettings.dateColBasis}>
        <Text.Skeleton width="large" />
      </Cell>
      <Cell basis={cellWidthSettings.stateColBasis}>
        <Stack spacing="tight">
          <Stack.Item>
            <Label.Skeleton />
          </Stack.Item>
          <Stack.Item>
            <Label.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell basis={cellWidthSettings.actionsColBasis}>
        <IconButton.Skeleton />
      </Cell>
    </Row>
  );
}

export default MessagesListItemSkeleton;
