import { InterfaceNameValue } from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { UpdateByEnum } from '../types';

interface VariationInputProps {
  name: string;
  variation: string;
  variationType: UpdateByEnum;
  onChange: (event: InterfaceNameValue) => void;
  error: string;
}

function VariationInput({
  name,
  variation,
  variationType,
  error,
  onChange,
}: VariationInputProps): JSX.Element {
  const { symbol } = useGetCurrency();
  const t = useTranslationCatalog();

  const labelProps =
    variationType === UpdateByEnum.PERCENTAGE
      ? { appendLabel: '%' }
      : { prependLabel: symbol };

  return (
    <InputNumber
      name={name}
      type="float"
      value={variation}
      placeholder="0"
      onChange={onChange}
      appearance={error ? 'validation_error' : 'default'}
      helpText={error ? t(error) : undefined}
      {...labelProps}
    />
  );
}

export default VariationInput;
