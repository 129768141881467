import { ProductDetailsResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import { keysToSnake, sanitizateFilters } from 'commons/utils';
import productsServices from 'domains/Catalog/Products/productsServices/productsServices';
import {
  GetInventoryProductsType,
  EditStockType,
  GetInventoryProductByIdType,
  UpdateProductInterface,
} from './types';
import { INVENTORY_PRODUCTS } from './urls';

export interface UpdateProductsParamsInterface {
  ids: string[];
  product: Partial<ProductDetailsResponseDto>;
}

const updateProduct: UpdateProductInterface = productsServices.updateProduct;

const getInventoryProducts: GetInventoryProductsType = async (filters) => {
  const paramsToRequest = sanitizateFilters(filters);
  const params = { ...keysToSnake(paramsToRequest), per_page: 20 };

  const { data, headers } = await axios.get(INVENTORY_PRODUCTS, {
    params,
  });

  return {
    total: Number(headers['x-total-count'] || 0),
    products: data,
  };
};

const getInventoryProductById = productsServices.getProductById;

const updateInventoryStock = productsServices.updateStock;

interface InventoryServicesInterface {
  getInventoryProducts: GetInventoryProductsType;
  getInventoryProductById: GetInventoryProductByIdType;
  updateStock: EditStockType;
  updateProduct: UpdateProductInterface;
}

const inventoryServices: InventoryServicesInterface = {
  getInventoryProducts,
  getInventoryProductById,
  updateStock: updateInventoryStock,
  updateProduct,
};

export default inventoryServices;
