import { Box, Modal, Title, Text, Button } from '@nimbus-ds/components';
import { Link } from 'react-router-dom';
import { useStorage } from 'commons/hooks';
import { useTransactionFeeToPay } from 'domains/Billing/TransactionFees/pages/TransactionFeesPage/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingTransactionFeesFreeNuvemPagoClick } from 'domains/Dashboard/tracking';
import { paymentsRoutes } from 'domains/Payments';
import CptImageSrc from './icon-cpt.png';

function FreeTransactionFeeReminderModal() {
  const t = useTranslationDashboard('freeTreansactionFeeReminderModal');
  const [
    freeTransactionFeeInfoModalDismissed,
    setFreeTransactionFeeInfoModalDismissed,
  ] = useStorage('freeTransactionFeeInfoModalDismissed', false);
  // To avoid multiple requests, the main request to "/v1/billing/transaction-fee-to-pay" is made from the DashboardPage component
  const { transactionFeeToPay } = useTransactionFeeToPay();

  const handleClose = () => {
    setFreeTransactionFeeInfoModalDismissed(true);
  };

  return (
    <Modal
      open={
        !freeTransactionFeeInfoModalDismissed &&
        !!transactionFeeToPay?.requiresCptInfoText
      }
      onDismiss={handleClose}
    >
      <Box marginBottom="6" />
      <Box marginBottom="6" display="flex" flexDirection="column" gap="4">
        <Box display="flex" alignItems="center" justifyContent="center">
          <img alt="" src={CptImageSrc} width="79px" />
        </Box>
        <Box display="flex" flexDirection="column" gap="4">
          <Title textAlign="center" as="h3">
            {t('title')}
          </Title>
          <Text textAlign="center">{t('description')}</Text>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="2">
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button
          onClick={trackingTransactionFeesFreeNuvemPagoClick}
          as={Link}
          to={paymentsRoutes.PAYMENTS_BASE_ROUTE}
          appearance="primary"
        >
          {t('cta')}
        </Button>
      </Box>
    </Modal>
  );
}

export default FreeTransactionFeeReminderModal;
