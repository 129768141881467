import { useCallback } from 'react';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetIsFreePlan } from 'domains/Auth/hooks';
import {
  useGetLastPayment,
  useGetRecurrentPaymentConcepts,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';
import {
  SummaryForAdvancingCharge,
  SummaryWithRP,
  SummaryWithoutRP,
} from './components';
import SummaryForChurned from './components/SummaryForChurned';
import SummaryError from './SummaryError';
import SummarySkeleton from './SummarySkeleton';

function Summary() {
  const {
    recurrentPaymentForPlanActivated,
    refreshRecurrentPaymentConcepts,
    status: conceptsStatus,
  } = useGetRecurrentPaymentConcepts();

  const {
    subscriptionForPlan,
    status: subscriptionStatus,
    refreshSubscriptions,
  } = useGetSubscriptions();
  const { isFreePlan, isTrial } = useGetIsFreePlan();
  const {
    paymentStatus,
    status: lastPaymentStatus,
    refreshLastPayment,
    isPending: planPending,
  } = useGetLastPayment('plan-cost');

  const { isLoading, isError } = combineStatus(
    subscriptionStatus,
    conceptsStatus,
    lastPaymentStatus,
  );

  const retryLoad = useCallback(() => {
    refreshRecurrentPaymentConcepts();
    refreshLastPayment();
    refreshSubscriptions();
  }, [
    refreshRecurrentPaymentConcepts,
    refreshLastPayment,
    refreshSubscriptions,
  ]);

  if (isLoading) return <SummarySkeleton />;

  if (isFreePlan) return null;

  if (isError) return <SummaryError onClick={retryLoad} />;

  if (isTrial && subscriptionForPlan && paymentStatus !== 'IN_PROCESS') {
    return <SummaryForAdvancingCharge />;
  }

  if (
    !subscriptionForPlan &&
    !planPending &&
    !isTrial &&
    paymentStatus !== 'IN_PROCESS'
  ) {
    return <SummaryForChurned />;
  }

  return recurrentPaymentForPlanActivated ? (
    <SummaryWithRP />
  ) : (
    <SummaryWithoutRP />
  );
}

export default Summary;
