import { HomeIcon, TagIcon, MoneyIcon, MenuIcon } from '@nimbus-ds/icons';
import { NavTabs } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useKeyboard } from 'commons/hooks';
import useStorage from 'commons/hooks/useStorage';
import { DASHBOARD_BASE_PATH } from 'domains/Dashboard/dashboardRoutes';
import { useGetNewOrdersCount } from 'domains/Orders/Orders/hooks';
import useNavTabs from './useNavTabs';
import NavTabsContainer from '../NavTabsContainer';
import ShowByRole from '../ShowByRole';
import './Navtabs.scss';

const path = {
  dashboard: DASHBOARD_BASE_PATH,
  orders: '/orders',
  products: '/products',
};

function NavTabsMobile() {
  const [showBadgeMenu, setShowBadgeMenu] = useStorage('showBadgeMenu-2', true);
  const { t } = useTranslation(['common']);
  const count = useGetNewOrdersCount();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { isShowNavTabs, toggleSideBar } = useNavTabs();
  const { isOpen } = useKeyboard();

  const handleClick = (target: string) => {
    if (pathname !== target) {
      push(target);
    }
  };

  const openMenu = () => {
    setShowBadgeMenu(false);
    toggleSideBar();
  };

  if (isOpen || !isShowNavTabs) {
    return null;
  }

  return (
    <NavTabsContainer>
      <div className="stratus--nav-tabs-container">
        <NavTabs>
          <NavTabs.Item
            ariaLabel={t('menu.dashboard')}
            icon={<HomeIcon size="medium" />}
            onClick={() => handleClick(path.dashboard)}
            active={pathname === path.dashboard}
          />
          <ShowByRole includeRoles="orders">
            <NavTabs.Item
              ariaLabel={t('menu.orders')}
              badge={count > 0}
              icon={<MoneyIcon size="medium" />}
              onClick={() => handleClick(path.orders)}
              active={pathname.startsWith(path.orders)}
            />
          </ShowByRole>
          <ShowByRole includeRoles="products">
            <NavTabs.Item
              ariaLabel={t('menu.products')}
              icon={<TagIcon size="medium" />}
              onClick={() => handleClick(path.products)}
              active={pathname.startsWith(path.products)}
            />
          </ShowByRole>
          <NavTabs.Item
            ariaLabel={t('menu.hamburger')}
            icon={<MenuIcon size="medium" />}
            onClick={openMenu}
            badge={showBadgeMenu}
          />
        </NavTabs>
      </div>
    </NavTabsContainer>
  );
}

export default NavTabsMobile;
