import { LocationResponseDto } from '@tiendanube/common';
import {
  useHasCatalogInventory,
  useHasShippingMultiCD,
} from 'domains/Auth/hooks';

function useHasMultipleLocations(locations: LocationResponseDto[]): boolean {
  const hasShippingMultiCD = useHasShippingMultiCD();
  const hasCatalogInventory = useHasCatalogInventory();
  const hasMultipleLocations =
    locations.length > 1 && hasShippingMultiCD && hasCatalogInventory;

  return hasMultipleLocations;
}

export default useHasMultipleLocations;
