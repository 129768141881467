import { Input, Stack } from '@tiendanube/components';

function Skeleton(): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Input.Skeleton />
      </Stack.Item>
    </Stack>
  );
}

export default Skeleton;
