/* eslint-disable max-statements */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import {
  useBulkAction,
  InterfaceUseBulkActionResult,
} from '@tiendanube/components';
import { FEATURE_BULK_ACTIONS } from 'App/features';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { openWindow } from 'commons/utils/window';
import { CATALOG_BULK_ACTIONS } from 'config/upsellFlowSources';
import { useHasPermission } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useProductsList } from 'domains/Catalog/Products/pages/hooks';
import { getTotalProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import {
  trackingProductBulkMetafields,
  trackingProductDomainBulkMetafields,
} from 'domains/Metafields/components/ProductsAndVariants/ModalAssignMetafields/tracking';
import { useGetProductsAndVariantsMetafields } from 'domains/Metafields/hooks';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import { BulkActionTypeEnum, bulkactions } from './types';
import { useBulkActions } from '../../BulkActionsProvider';

export interface UseBulkActionProductsResult
  extends InterfaceUseBulkActionResult {
  showAlertMetafields: boolean;
  deactivateAlertMetafields: () => void;
  selectedRowsId: string[];
  selectAll: boolean;
  handleOnSelectAll: (value: boolean) => void;
}

interface UseBulkActionProductsParams {
  rowsId: string[];
  selectAllElement: React.ReactNode;
}

function useBulkActionProducts({
  rowsId,
  selectAllElement,
}: UseBulkActionProductsParams): UseBulkActionProductsResult {
  const { t } = useTranslation([DomainEnum.CATALOG]);
  const { generateBulkAppLink, getAppsLinks } = useAppsLinks();
  const canEditPrice = useHasPermission('edit_price');

  const {
    selectedRowsId,
    selectAll,
    setSelectedRowsId,
    setSelectAll,
    setSelectedAction,
    openModal,
    showAlertMetafields,
    deactivateAlertMetafields,
  } = useBulkActions(rowsId);
  const { hasFilters } = useProductsList();
  const productsCount = useSelector(getTotalProducts);
  const { metafields, apiMetafields } = useGetProductsAndVariantsMetafields();
  const { jobStatus } = useBackgroundJobs('editPricesProducts');

  const handleOnSelectAll = (value: boolean) => {
    if (value) setSelectedRowsId(rowsId);
    else setSelectedRowsId([]);
    setSelectAll(value);
  };

  const count = selectAll ? productsCount : selectedRowsId.length;

  const appsOptions = getAppsLinks('products', 'bulk-action').map((app) => ({
    label: app.text,
    value: app.url,
    disabled: false,
  }));

  const hasProductVariantsMetafields =
    metafields?.some((m) => m.resource === 'product_variant') ||
    apiMetafields?.some((m) => m.resource === 'product_variant');

  const hasProductMetafields =
    metafields?.some((m) => m.resource === 'product') ||
    apiMetafields?.some((m) => m.resource === 'product');

  const options = bulkactions
    .filter((item) => {
      switch (item.value) {
        case BulkActionTypeEnum.UPDATE_PRICE:
          return canEditPrice;
        case BulkActionTypeEnum.ASSIGN_METAFIELDS:
          return !(selectAll && !hasFilters) && hasProductVariantsMetafields;
        case BulkActionTypeEnum.ASSIGN_PRODUCT_METAFIELDS:
          return !(selectAll && !hasFilters) && hasProductMetafields;
        default:
          return !selectAll;
      }
    })
    .map(({ showCounts, value }) => {
      const labelCount = showCounts ? `(${count})` : '';
      const actionLabel = t(`products.bulkActions.options.${value}`);
      const disabled = value === 'updatePrice' && jobStatus === 'pending';
      return {
        label: `${actionLabel} ${labelCount}`,
        value,
        disabled,
      };
    })
    .concat(appsOptions);

  const placeholder = t(`products.bulkActions.options.choose`);

  const getLabel = (n: number) =>
    t(`products.bulkActions.selected`, {
      count: selectAll ? productsCount : n,
    });

  const onSelectAction = (value) => {
    if (value.startsWith('http')) {
      return openWindow(generateBulkAppLink(value, selectedRowsId), true);
    }
    setSelectedAction(value);
    openModal();

    if (showAlertMetafields) {
      deactivateAlertMetafields();
    }

    if (value === BulkActionTypeEnum.ASSIGN_METAFIELDS) {
      trackingProductBulkMetafields(selectedRowsId);
    }

    if (value === BulkActionTypeEnum.ASSIGN_PRODUCT_METAFIELDS) {
      trackingProductDomainBulkMetafields(selectedRowsId);
    }
  };

  const setSelectedRowsIdWithUpsell = useUpsellFlow({
    title: t('products.bulkActions.upsellTitle'),
    featureKey: FEATURE_BULK_ACTIONS,
    callback: setSelectedRowsId,
    trackingSource: CATALOG_BULK_ACTIONS,
  });

  const { bulkAction, handleOnSelectRow } = useBulkAction({
    rowsId,
    selectedRowsId,
    setSelectedRowsId: setSelectedRowsIdWithUpsell,
    placeholder,
    getLabel,
    options,
    onSelectAction,
    disabled: selectAll,
    actionElement: selectAllElement,
  });

  return {
    bulkAction,
    showAlertMetafields,
    deactivateAlertMetafields,
    handleOnSelectRow,
    selectedRowsId,
    selectAll,
    handleOnSelectAll,
  };
}

export default useBulkActionProducts;
