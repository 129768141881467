import { ReactNode } from 'react';

interface StrikethroughProps {
  children: ReactNode;
}
function Strikethrough({ children }: Readonly<StrikethroughProps>) {
  return <div style={{ textDecorationLine: 'line-through' }}>{children}</div>;
}

export default Strikethrough;
