import { Select, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import {
  OcaLabelSettingBoxOptionsDto,
  OcaCreateLabelRequestDto,
  OcaLabelsSettingOptionsResponseDto,
} from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DISPATCH_TYPES } from '../../constants/constants';

interface OcaDispatchDataFormProps {
  documentSettings: OcaLabelsSettingOptionsResponseDto;
  label: Omit<OcaCreateLabelRequestDto, 'externalId'>;
  onChange: (
    name: string,
    value: OcaLabelSettingBoxOptionsDto | string,
  ) => void;
}

function OcaDispatchDataForm({
  documentSettings,
  label,
  onChange,
}: Readonly<OcaDispatchDataFormProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint.card');
  const { dispatchMethods, agencies, schedulingForWithdrawals } =
    documentSettings;

  return (
    <Stack column spacing="tight" align="stretch" alignSelf="auto">
      <Text fontWeight="bold">{t('origin')}</Text>
      {dispatchMethods.length === 1 && (
        <Text fontWeight="bold">{dispatchMethods[0].description}</Text>
      )}
      {dispatchMethods.length > 1 && (
        <FormField.Select
          label={t('dispatch')}
          id="dispatchMethod"
          name="dispatchMethod"
          value={label.dispatchMethod}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        >
          {dispatchMethods.map((dispatchMethod) => (
            <Select.Option
              key={dispatchMethod.code}
              label={dispatchMethod.description}
              value={dispatchMethod.code}
            />
          ))}
        </FormField.Select>
      )}
      {label.dispatchMethod === DISPATCH_TYPES.store && (
        <FormField.Select
          label={t('sendMethod')}
          id="agency"
          name="agency"
          value={label.agency}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        >
          {agencies.map((agency) => (
            <Select.Option
              key={agency.code}
              label={agency.description}
              value={agency.code}
            />
          ))}
        </FormField.Select>
      )}
      {label.dispatchMethod === DISPATCH_TYPES.door && (
        <FormField.Select
          label={t('sendMethod')}
          id="schedulingForWithdrawals"
          name="schedulingForWithdrawals"
          value={label.schedulingForWithdrawals}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        >
          {schedulingForWithdrawals.map((scheduling) => (
            <Select.Option
              key={scheduling.code}
              label={scheduling.description}
              value={scheduling.code}
            />
          ))}
        </FormField.Select>
      )}
    </Stack>
  );
}

export default OcaDispatchDataForm;
