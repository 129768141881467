import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getCustomerMessagesList } from 'domains/Customers/Customers/customersSlice';
import {
  getCustomerMessages,
  getCustomerMessagesIds,
  getCustomerMessagesStatus,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

function useCustomerMessages(id: string) {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getCustomerMessagesStatus,
  );
  const customerMessages = useSelector(getCustomerMessages);
  const customerMessagesIds = useSelector(getCustomerMessagesIds);

  const fetchCustomerMessages = useCallback(async () => {
    await dispatch(getCustomerMessagesList(id));
  }, [dispatch, id]);

  return {
    customerMessages,
    customerMessagesIds,
    isLoading,
    isError,
    isSuccess,
    fetchCustomerMessages,
  };
}

export default useCustomerMessages;
