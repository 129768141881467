import { useCallback } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetRecurrentPaymentConceptsQuery } from '../CheckoutApi';

function useGetRecurrentPaymentConcepts(initialFetch = true) {
  const {
    data: recurrentPaymentConcepts,
    status,
    refetch,
  } = useGetRecurrentPaymentConceptsQuery(undefined, { skip: !initialFetch });

  const recurrentPaymentForConcept = useCallback(
    (conceptCode: ConceptCode, externalReference?: string) =>
      recurrentPaymentConcepts?.some(
        (recurrentConcept) =>
          recurrentConcept.concept.code === conceptCode &&
          (!externalReference ||
            !recurrentConcept.externalReference ||
            externalReference === recurrentConcept.externalReference),
      ),
    [recurrentPaymentConcepts],
  );
  const recurrentPaymentForPlanActivated =
    recurrentPaymentForConcept('plan-cost');
  const recurrentPaymentForTransactionFeesActivated =
    recurrentPaymentForConcept('transaction-fee');

  return {
    ...convertStatusTypeToObject(status),
    status: toStatusType(status),
    recurrentPaymentConcepts,
    refreshRecurrentPaymentConcepts: refetch,
    recurrentPaymentForConcept,
    recurrentPaymentForPlanActivated,
    recurrentPaymentForTransactionFeesActivated,
  };
}

export default useGetRecurrentPaymentConcepts;
