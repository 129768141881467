import { Button, Input, Spinner, Text, Title } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { PasswordProtectedRequestDto } from '@tiendanube/common';
import HelpLink from 'App/HelpLink';
import {
  BaseCardCollapsible,
  HeaderContent,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { HELP_LINKS_PASSWORD_PROTECTED } from 'commons/constants';
import { useForm, useToastStatus, useTranslationLanguage } from 'commons/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import {
  PasswordProtectedPageError,
  PasswordProtectedPageSkeleton,
} from './components';
import usePasswordProtected from './usePasswordProtected/usePasswordProtected';
import { validationSchema } from './validationSchema';
import './PasswordProtectedPage.scss';

const initialValues: PasswordProtectedRequestDto = {
  enabled: false,
  message: '',
  secret: '',
};

function PasswordProtectedPage() {
  const t = useTranslationOnline('passwordProtectedPage');
  const language = useTranslationLanguage();
  const {
    status,
    updateStatus,
    passwordProtected,
    updatePasswordProtected,
    fetchPasswordProtected,
  } = usePasswordProtected();
  const { values, errors, isDirty, handleOnChange, handleOnSubmit } =
    useForm<PasswordProtectedRequestDto>({
      initialValues: passwordProtected || initialValues,
      validationSchema,
      onSubmit: (data) => {
        updatePasswordProtected({
          ...data,
          message: data.message || t('messageDefault'),
        });
      },
    });

  useToastStatus({
    status: updateStatus,
    error: t('toast.error'),
    success: t('toast.success'),
  });

  const handleChange = (event) =>
    handleOnChange({
      name: event.target.name || event.target.id,
      value: event.target.value,
    });

  const handleChangeEnabled = () =>
    handleOnChange({ name: 'enabled', value: !values.enabled });

  const isUpdateLoading = updateStatus === 'loading';
  const showPasswordError = !!errors.secret;
  const passwordLabel = passwordProtected?.secret
    ? t('secret')
    : t('newSecret');

  return (
    <IonPageStratus
      width="small"
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      {status === 'loading' && <PasswordProtectedPageSkeleton />}
      {status === 'error' && (
        <PasswordProtectedPageError
          fetchPasswordProtected={fetchPasswordProtected}
        />
      )}
      {status === 'success' && (
        <>
          <BaseCardCollapsible
            active={values.enabled}
            onChange={handleChangeEnabled}
            title={
              <Stack column spacing="tight" align="flex-start">
                <Title as="h3" lineHeight="5">
                  {t('enabled')}
                </Title>
                <Text>{t('enabledInfo')}</Text>
              </Stack>
            }
            appearance="toggle"
            paddingBody="base"
            alignSwitch="flex-start"
          >
            <Stack column align="stretch">
              <div className="stratus--password-protected-page__textarea">
                <FormField.Textarea
                  id="message"
                  label={t('message')}
                  placeholder={t('messageDefault')}
                  defaultValue={values.message}
                  onChange={handleChange}
                />
              </div>
              <FormField
                label={passwordLabel}
                helpIcon={showPasswordError ? ExclamationCircleIcon : undefined}
                helpText={
                  showPasswordError ? t('secretError') : t('secretInfo')
                }
                appearance={showPasswordError ? 'danger' : 'none'}
                showHelpText
              >
                <Input.Password
                  id="secret"
                  name="secret"
                  appearance={showPasswordError ? 'danger' : undefined}
                  value={values.secret}
                  onChange={handleChange}
                />
              </FormField>
            </Stack>
          </BaseCardCollapsible>
          <Stack alignSelf="flex-end">
            <Button
              appearance="primary"
              disabled={!isDirty || isUpdateLoading}
              onClick={handleOnSubmit}
            >
              {updateStatus === 'loading' && (
                <Spinner size="small" color="currentColor" />
              )}
              {t('confirm')}
            </Button>
          </Stack>
          <HelpLink
            title={t('helpLink.title')}
            previousValue=""
            currentViewTracking={t('helpLink.amplitudeName')}
            linkURL={HELP_LINKS_PASSWORD_PROTECTED[language]}
            icon="both"
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default PasswordProtectedPage;
