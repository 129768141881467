import { VariantResponseDto, i18nResponseDto } from '@tiendanube/common';
import { LanguagesType } from 'App/i18n';
import { AttributesToFix } from 'domains/Catalog/Products/productsSlice';

type ConflictAttributes = Record<LanguagesType, Record<string, boolean>>;

type FnCheckConflict = (a: Record<string, boolean>, b: string) => boolean;

export const checkIsDuplicated: FnCheckConflict = (registerDuplicated, value) =>
  registerDuplicated[value] !== undefined && value !== '';
export const checkIsEmpty: FnCheckConflict = (_, value) => value === '';

export const getAttributesWithConflicts = (
  values: AttributesToFix,
  fnCheckConflicts: FnCheckConflict[],
): ConflictAttributes =>
  values.attributes.reduce((acc, attribute, index) => {
    if (values.toDelete.includes(index)) {
      return acc;
    }

    Object.keys(attribute).forEach((language) => {
      const value = attribute[language]?.trim();
      acc[language] = acc[language] || {};
      acc[language][value] = fnCheckConflicts.some((fn) =>
        fn(acc[language], value),
      );
    });
    return acc;
  }, {} as ConflictAttributes);

export const getErrorLanguages = (values: AttributesToFix): LanguagesType[] => {
  const repeatedAttributes = getAttributesWithConflicts(values, [
    checkIsDuplicated,
    checkIsEmpty,
  ]);

  return Object.keys(repeatedAttributes).filter((language) =>
    Object.values(repeatedAttributes[language] as Record<string, boolean>).some(
      Boolean,
    ),
  ) as LanguagesType[];
};

export const getErrorDuplicatedLanguages = (
  values: AttributesToFix,
): LanguagesType[] => {
  const repeatedAttributes = getAttributesWithConflicts(values, [
    checkIsDuplicated,
  ]);

  return Object.keys(repeatedAttributes).filter((language) =>
    Object.values(repeatedAttributes[language] as Record<string, boolean>).some(
      Boolean,
    ),
  ) as LanguagesType[];
};

export const getControlStyle = (
  values: AttributesToFix,
  conflictAttributes: ConflictAttributes,
): Record<LanguagesType, number[]> =>
  values.attributes.reduce((acc, attribute, index) => {
    Object.keys(attribute).forEach((language) => {
      const value = attribute[language]?.trim();
      if (conflictAttributes[language][value]) {
        acc[language] = acc[language] ?? [];
        acc[language] = [...acc[language], index];
      }
    });
    return acc;
  }, {} as Record<LanguagesType, number[]>);

export const getVariantsValuesByLanguage = (
  variants: VariantResponseDto[],
  index: number,
  language: LanguagesType,
): string[] =>
  variants.reduce((acc: string[], variant) => {
    const value = variant.values[index] && variant.values[index][language];
    if (value && !acc.includes(value)) acc.push(value);
    return acc;
  }, []);

export const hasDuplicateAttributes = (
  attributes: i18nResponseDto[],
): boolean => {
  const atributesCount = getAttributesWithConflicts(
    {
      attributes,
      toDelete: [],
    },
    [checkIsDuplicated],
  );

  return Object.values(atributesCount).some((atributes) =>
    Object.values(atributes).some(Boolean),
  );
};

export const trimmedAttributes = (
  attributes: i18nResponseDto[],
): i18nResponseDto[] =>
  attributes.map((attribute) => {
    const trimmedAttribute = Object.entries(attribute).reduce(
      (acc, [key, value]) => {
        acc[key] = value.trim();
        return acc;
      },
      {} as i18nResponseDto,
    );
    return trimmedAttribute;
  });
