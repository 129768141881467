import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanDeleteActiveShippingStatus,
  DeleteActiveShippingMethodParams,
  deleteActiveShippingMethodsAction,
  deleteActiveShippingMethodsStatus,
} from '../../deliveryMethodsSlice';

function useDeleteActiveShipping() {
  const dispatch = useAppDispatch();
  const status = useSelector(deleteActiveShippingMethodsStatus);

  const deleteActiveShipping = useCallback(
    async (params: DeleteActiveShippingMethodParams) => {
      await dispatch(deleteActiveShippingMethodsAction(params));
      dispatch(cleanDeleteActiveShippingStatus());
    },
    [dispatch],
  );

  return {
    status,
    deleteActiveShipping,
  };
}

export default useDeleteActiveShipping;
