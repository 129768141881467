import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ProductCreateRequestDto,
  ProductDetailsResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { cleanUpInventory } from 'domains/Catalog/Inventory/inventorySlice';
import { addProduct as addProductAction } from '../../../productsSlice';

type addProductType = (
  product: ProductCreateRequestDto,
) => Promise<ProductDetailsResponseDto>;

interface UseCreateProductResult {
  addProduct: addProductType;
}

function useCreateProduct(): UseCreateProductResult {
  const dispatch = useAppDispatch();

  const addProduct: addProductType = useCallback(
    async (product) => {
      const result = await dispatch(addProductAction({ product }));
      const productResponse = unwrapResult(result);
      dispatch(cleanUpInventory());
      return productResponse;
    },
    [dispatch],
  );

  return { addProduct };
}

export default useCreateProduct;
