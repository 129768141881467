import { Browser } from '@capacitor/browser';
import { useGetOldAdminAuthorizationCodeQuery } from 'domains/PartnersApps/partnersAppsApi';
import useAdminLinks from './useAdminLinks';
import useIsMobileDevice from '../useIsMobileDevice';

export function useLoggedAdminLink() {
  const { data: authCode, isLoading } = useGetOldAdminAuthorizationCodeQuery();
  const { getOldAdminLink } = useAdminLinks();
  const isMobileDevice = useIsMobileDevice();

  const getLoggedAdminLink = (oldAdminPath: string) =>
    `${getOldAdminLink(
      'auth/login',
    )}?code=${authCode}&login_to=${getOldAdminLink(
      oldAdminPath,
    )}&for_admin_app=true`;

  const goToLoggedAdminLink = async (
    oldAdminPath: string,
    onClick?: () => void,
  ) => {
    const oldAdminLink = getLoggedAdminLink(oldAdminPath);
    onClick?.();

    if (isMobileDevice) {
      await Browser.open({ url: oldAdminLink });
    } else {
      window.location.href = oldAdminLink;
    }
  };

  return {
    goToLoggedAdminLink,
    isLoading,
  };
}
