import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { StatusObjectType, StatusType } from 'commons/types';

export const convertStatusTypeToObject = (
  status: StatusType | QueryStatus,
): StatusObjectType => ({
  isLoading: status === 'loading' || status === QueryStatus.pending,
  isError: status === 'error' || status === QueryStatus.rejected,
  isSuccess: status === 'success' || status === QueryStatus.fulfilled,
  isIdle: status === 'idle' || status === QueryStatus.uninitialized,
});
