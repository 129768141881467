import { OptionsBusinessInfoResponseDto } from '@tiendanube/common';
import {
  BaseCard,
  Grid,
  Input,
  Select,
  Stack,
  Title,
} from '@tiendanube/components';
import { useTranslationAccount } from 'domains/Account/hooks';

export type onChangeType = (data: {
  name: string;
  value: string | boolean;
}) => void;

export type GeneralInformationBusinessDataCardValues = {
  ownerPhoneType: string;
  ownerPhoneCountry: string;
  ownerPhoneArea: string;
  phoneNumber: string;
  timeZone: string;
  brickAndMortars: string;
  businessSize: string;
  type: string;
};

export interface GeneralInformationBusinessDataCardProps {
  values: GeneralInformationBusinessDataCardValues;
  options: OptionsBusinessInfoResponseDto;
  onChange: onChangeType;
}

function GeneralInformationBusinessDataCard({
  values,
  options,
  onChange,
}: GeneralInformationBusinessDataCardProps): JSX.Element {
  const t = useTranslationAccount();
  const {
    ownerPhoneTypeOptions,
    timeZoneOptions,
    brickAndMortarsOptions,
    businessSizeOptions,
    typeOptions,
  } = options;

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">
          {t('businessDataPage.generalInformationCard.title') as string}
        </Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Select
            name="ownerPhoneType"
            label={t(
              'businessDataPage.generalInformationCard.ownerPhoneType.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.ownerPhoneType.placeholder',
            )}
            options={ownerPhoneTypeOptions}
            value={values.ownerPhoneType}
            onChange={onChange}
          />

          <Stack.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.ownerPhoneCountry.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.ownerPhoneCountry.placeholder',
                    )}
                    onChange={onChange}
                    name="ownerPhoneCountry"
                    value={values.ownerPhoneCountry}
                  />
                </Grid.Column>

                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.ownerPhoneArea.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.ownerPhoneArea.placeholder',
                    )}
                    onChange={onChange}
                    name="ownerPhoneArea"
                    value={values.ownerPhoneArea}
                  />
                </Grid.Column>

                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.phoneNumber.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.phoneNumber.placeholder',
                    )}
                    onChange={onChange}
                    name="phoneNumber"
                    value={values.phoneNumber}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Stack.Item>

          <Select
            name="timeZone"
            label={t('businessDataPage.generalInformationCard.timeZone.label')}
            placeholder={t(
              'businessDataPage.generalInformationCard.timeZone.placeholder',
            )}
            options={timeZoneOptions}
            value={values.timeZone}
            onChange={onChange}
          />

          <Select
            name="brickAndMortars"
            label={t(
              'businessDataPage.generalInformationCard.brickAndMortars.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.brickAndMortars.placeholder',
            )}
            options={brickAndMortarsOptions}
            value={values.brickAndMortars}
            onChange={onChange}
          />

          <Select
            name="businessSize"
            label={t(
              'businessDataPage.generalInformationCard.businessSize.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.businessSize.placeholder',
            )}
            options={businessSizeOptions}
            value={values.businessSize}
            onChange={onChange}
          />

          <Select
            name="type"
            label={t('businessDataPage.generalInformationCard.type.label')}
            placeholder={t(
              'businessDataPage.generalInformationCard.type.placeholder',
            )}
            options={typeOptions}
            value={values.type}
            onChange={onChange}
          />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default GeneralInformationBusinessDataCard;
