import { Route } from 'react-router-dom';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { DASHBOARD_BASE_PATH } from './dashboardRoutes';
import { useSetConfigKeyboard } from './hooks';
import { DashboardPage, NewDashboardPage } from './pages/DashboardPage';
import { AdminDisabledScreen } from './pages/DashboardPage/components';
import { useDisabledAdminForRoles } from './useDisabledAdminForRoles';

function Dashboard(): JSX.Element {
  useSetConfigKeyboard();
  const isDisabled = useDisabledAdminForRoles();

  return (
    <Route exact path={DASHBOARD_BASE_PATH}>
      {isDisabled ? (
        <AdminDisabledScreen />
      ) : (
        <FeatureFlag flag={NEW_ONBOARDING_B} renderElse={<DashboardPage />}>
          <NewDashboardPage />
        </FeatureFlag>
      )}
    </Route>
  );
}

export default Dashboard;
