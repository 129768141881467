import { DataList } from '@tiendanube/components';
import ScrollTop from 'commons/components/ScrollTop';
import { OrderListItemSkeleton } from '../OrderListItem';

function OrderResultsMobileSkeleton(): JSX.Element {
  return (
    <DataList skeleton>
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <OrderListItemSkeleton />
      <ScrollTop />
    </DataList>
  );
}

export default OrderResultsMobileSkeleton;
