import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('emailRequired').email('emailInvalid'),
  roles: Yup.array().min(1, 'requiredRoles'),
  currentPassword: Yup.string().test(
    'currentPasswordRequired',
    'currentPasswordRequired',
    (_, { parent: { password, currentPassword, confirmPassword } }) =>
      (!password && !confirmPassword) || !!currentPassword,
  ),
  password: Yup.string().test(
    'newPasswordMinLength',
    'newPasswordMinLength',
    (_, { parent: { password, confirmPassword } }) =>
      (!password && !confirmPassword) || password.length >= 12,
  ),
  confirmPassword: Yup.string()
    .test(
      'passwordsDontMatch',
      'passwordsDontMatch',
      (_, { parent: { password, confirmPassword } }) =>
        password === confirmPassword,
    )
    .test(
      'confirmPasswordRequired',
      'confirmPasswordRequired',
      (_, { parent: { password, confirmPassword } }) =>
        !password || !!confirmPassword,
    ),
});
