import { useContext, useEffect } from 'react';
import ModalAside from 'App/components/lab/ModalAside';
import { ModalConfirmationContext } from 'App/components/ModalConfirmation/ModalConfirmation';
import { HeaderContent } from 'commons/components';
import {
  useHasCatalogInventory,
  useHasShippingMultiCD,
} from 'domains/Auth/hooks';
import { useShowNoWeightAndDimension } from 'domains/Catalog/Products/pages/hooks';
import {
  Prices,
  ProductSizes,
  ProductCodes,
  VisibilityCard,
  Location,
} from '../../../../components';
import SectionProductsAndVariantsMetafieldsWrapper from '../../../SectionProductsAndVariantsMetafieldsWrapper';
import SocialShopping from '../../../SocialShopping';
import {
  OnChangeToAllMetafieldsVariants,
  OnChangeToAllVariants,
  Variant,
} from '../../types';

interface VariantDetailsModalProps {
  isShow: boolean;
  isDigital: boolean;
  variants: Variant[];
  variantSelected: number;
  productId: string;
  onChangeVariantSelected: (i: number) => void;
  onClose: () => void;
  onChangeVariant: (i: number, v: Partial<Variant>) => void;
  onChangeToAllVariants: OnChangeToAllVariants;
  onChangeToAllMetafieldsVariants: OnChangeToAllMetafieldsVariants;
}

function VariantDetailsModal({
  isShow,
  isDigital,
  variants,
  variantSelected,
  productId,
  onClose,
  onChangeVariantSelected,
  onChangeVariant,
  onChangeToAllVariants,
  onChangeToAllMetafieldsVariants,
}: VariantDetailsModalProps): JSX.Element | null {
  const variant = variants[variantSelected];
  const hasShippingMultiCD = useHasShippingMultiCD();
  const hasCatalogInventory = useHasCatalogInventory();

  const { id: variantId, weight, depth, width, height } = variant || {};

  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight,
    depth,
    width,
    height,
  });

  const { isOpen: isUserConfirmationModalOpen } = useContext(
    ModalConfirmationContext,
  );

  useEffect(() => {
    if (isUserConfirmationModalOpen && isShow) {
      onClose();
    }
  }, [isUserConfirmationModalOpen, isShow, onClose]);

  /**
   * The variant previously selected could be deleted
   * then we don't render the modal
   */
  if (!variant) {
    return null;
  }

  const onPrevious = () => {
    if (variantSelected === 0) {
      onChangeVariantSelected(variants.length - 1);
      return;
    }
    onChangeVariantSelected(variantSelected - 1);
  };
  const onNext = () => {
    if (variantSelected === variants.length - 1) {
      onChangeVariantSelected(0);
      return;
    }
    onChangeVariantSelected(variantSelected + 1);
  };

  const handleChangeVariant = ({ name, value }) => {
    onChangeVariant(variantSelected, {
      [name]: value,
    });
  };

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerContent={
        <HeaderContent
          title={variant.name}
          paginationPrevious={onPrevious}
          paginationNext={onNext}
        />
      }
    >
      <Prices
        price={variant.price}
        promotionalPrice={variant.promotionalPrice}
        published={variant.pricePublished}
        costPrice={variant.costPrice}
        onChange={handleChangeVariant}
        hasVariants
        onChangeToAllVariants={onChangeToAllVariants}
      />
      <ProductCodes
        sku={variant.sku}
        barcode={variant.barcode}
        onChange={handleChangeVariant}
        onChangeToAllVariants={onChangeToAllVariants}
        asColumns
      />
      {hasShippingMultiCD && !hasCatalogInventory && (
        <Location
          onChange={handleChangeVariant}
          locationId={variant.locationId}
          isMobile={false}
          hasVariants
        />
      )}
      {!isDigital && (
        <ProductSizes
          weight={variant.weight}
          depth={variant.depth}
          width={variant.width}
          height={variant.height}
          showAlertNoWeightAndDimension={showNoWeightAndDimension}
          onChange={handleChangeVariant}
          onChangeToAllVariants={onChangeToAllVariants}
        />
      )}
      <VisibilityCard show={variant.show} onChange={handleChangeVariant} />
      <SocialShopping
        mpn={variant.mpn}
        ageGroup={variant.ageGroup}
        gender={variant.gender}
        onChange={handleChangeVariant}
        onChangeToAllVariants={onChangeToAllVariants}
      />
      <SectionProductsAndVariantsMetafieldsWrapper
        source="modal"
        hasVariant
        productId={productId}
        selecteds={{
          product_variant: variant.metafields?.internals || [],
          product: [],
        }}
        apiSelecteds={{
          product_variant: variant.metafields?.fromApi || [],
          product: [],
        }}
        onVariantChange={handleChangeVariant}
        onChangeToAllMetafieldsVariants={onChangeToAllMetafieldsVariants}
      />
    </ModalAside>
  );
}

export default VariantDetailsModal;
