interface AppStorage {
  id: string;
  url: string;
}
type AppsStorage = Record<string, AppStorage>;

const STORAGE_KEY_EXPERIMENTAL_MODE = 'stratus-experimental-mode';
const KEY_QUERY_DEV_MODE_URL = 'dev-mode-url';
const getApps = (): AppsStorage =>
  JSON.parse(localStorage.getItem(STORAGE_KEY_EXPERIMENTAL_MODE) || '{}');

export const saveApps = (apps: AppsStorage) => {
  localStorage.setItem(STORAGE_KEY_EXPERIMENTAL_MODE, JSON.stringify(apps));
};

export const getApp = (id: string) => {
  const experimentalApps = getApps();
  return experimentalApps[id];
};

export const removeApp = (id: string) => {
  const experimentalApps = getApps();
  delete experimentalApps[id];
  saveApps(experimentalApps);
};

export const addApp = (app: AppStorage) => {
  const experimentalApps = getApps();
  experimentalApps[app.id] = app;
  saveApps(experimentalApps);
};

export const getKeyQueryDevModeFromQueryParams = (): string => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(KEY_QUERY_DEV_MODE_URL) || '';
};
