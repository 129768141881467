import { Box } from '@nimbus-ds/components';
import { Layout } from 'commons/components';
import { StoreStatusAlert } from 'domains/Billing/Checkout/components';
import {
  NoFiscalDataAlert,
  AccountData,
  RecurrentPaymentsStatus,
  FreePlanCard,
  PaymentsTabs,
} from '../';

function PlansAndSubscriptionsV2(): JSX.Element {
  return (
    <>
      <StoreStatusAlert includePaymentAlert={false} />
      <NoFiscalDataAlert />
      <Layout
        mainContent
        left={
          <Box display="flex" flexDirection="column" gap="4">
            <FreePlanCard />
            <PaymentsTabs />
            <RecurrentPaymentsStatus />
          </Box>
        }
        right={<AccountData />}
        orderMobile="reverse"
      />
    </>
  );
}

export default PlansAndSubscriptionsV2;
