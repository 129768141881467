import { Box, Title } from '@nimbus-ds/components';

interface BlockWithTitleProps {
  title: string;
  isLoading?: boolean;
  children: React.ReactNode;
}

function BlockWithTitle({
  title,
  children,
  isLoading = false,
}: BlockWithTitleProps) {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      {isLoading ? (
        <Title.Skeleton as="h3" />
      ) : (
        <Title as="h3" fontWeight="medium">
          {title}
        </Title>
      )}
      {children}
    </Box>
  );
}

export default BlockWithTitle;
