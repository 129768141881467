import { ImageGalleryState } from 'domains/Catalog/Products/pages/components/ImageGallery/ImageGallery';

function useEditImage(
  initialImage: ImageGalleryState,
  base64Overwrite: string | undefined,
) {
  const displayedImage = {
    ...initialImage,
    base64: base64Overwrite ?? initialImage.base64,
  };

  const validImageUrl =
    displayedImage.src.startsWith('blob') || displayedImage.isEdited
      ? displayedImage.publicUrl!
      : displayedImage.src;

  return {
    displayedImage,
    validImageUrl,
  };
}

export default useEditImage;
