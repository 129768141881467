import {
  Box,
  Icon,
  Link,
  Popover,
  Text,
  Thumbnail,
} from '@nimbus-ds/components';
import { EllipsisIcon, QuestionCircleIcon, StarIcon } from '@nimbus-ds/icons';
import { AppResponseDto } from '@tiendanube/common';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';

interface AppTopBarProps {
  app?: AppResponseDto;
}

function AppTopBar({ app }: Readonly<AppTopBarProps>) {
  const { t } = useTranslationPartnerApps();
  return (
    <Box
      display="flex"
      flexDirection="row"
      paddingY="2"
      paddingX="4"
      height="36px"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="neutral-surface"
      borderBottomWidth="1"
      borderTopWidth="1"
      borderStyle="solid"
      borderColor="neutral-surfaceDisabled"
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap="2">
        {!!app?.smallIcon && !!app?.name && (
          <>
            <Thumbnail
              src={app.smallIcon}
              alt="my-image"
              width="28px"
              height="28px"
            />
            <Text color="neutral-textHigh">{app.name}</Text>
          </>
        )}
      </Box>
      <Popover
        padding="none"
        arrow={false}
        content={
          <Box display="flex" flexDirection="column">
            {!!app?.partnerName && (
              <Box
                display="flex"
                flexDirection="row"
                gap="1"
                padding="4"
                borderBottomWidth="1"
                borderStyle="solid"
                borderColor="neutral-surface"
              >
                <Text color="neutral-textHigh">
                  {t('embedApp.developedBy')}
                </Text>
                <Text color="primary-interactive">{app?.partnerName}</Text>
              </Box>
            )}
            <Box padding="4" gap="4">
              {!!app?.supportUrl && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2"
                  marginBottom="2"
                >
                  <Link
                    as="a"
                    href={app.supportUrl}
                    target="_blank"
                    textDecoration="none"
                  >
                    <Icon
                      source={<QuestionCircleIcon />}
                      color="primary-textLow"
                    />
                    <Text color="primary-textLow">{t('embedApp.support')}</Text>
                  </Link>
                </Box>
              )}
              {!!app?.ratingUrl && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2"
                >
                  <Link
                    as="a"
                    target="_blank"
                    href={app.ratingUrl}
                    textDecoration="none"
                  >
                    <Icon source={<StarIcon />} color="primary-textLow" />
                    <Text color="primary-textLow">{t('embedApp.rate')}</Text>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        }
      >
        <Icon source={<EllipsisIcon />} />
      </Popover>
    </Box>
  );
}

export default AppTopBar;
