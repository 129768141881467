import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  CustomersOrdersResponseDto,
  PaginationResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getCustomerOrdersList,
  getMoreCustomerOrdersList,
} from 'domains/Customers/Customers/customersSlice';
import {
  getCustomerOrders,
  getCustomerOrdersIds,
  getCustomerOrdersPagination,
  getCustomerOrdersStatus,
} from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseCustomerOrdersResult {
  fetchCustomerOrders: () => Promise<void>;
  fetchMoreCustomerOrders: () => Promise<void>;
  customerOrders: CustomersOrdersResponseDto[];
  pagination: PaginationResponseDto | undefined;
  customerOrdersIds: string[];
  customerOrdersQuantity: number;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useCustomerOrders(id: string): UseCustomerOrdersResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getCustomerOrdersStatus,
  );
  const customerOrders = useSelector(getCustomerOrders);
  const pagination = useSelector(getCustomerOrdersPagination);
  const customerOrdersIds = useSelector(getCustomerOrdersIds);

  const fetchCustomerOrders = useCallback(async () => {
    await dispatch(getCustomerOrdersList(id));
  }, [dispatch, id]);

  const fetchMoreCustomerOrders = useCallback(async () => {
    await dispatch(getMoreCustomerOrdersList());
  }, [dispatch]);

  return {
    fetchCustomerOrders,
    fetchMoreCustomerOrders,
    customerOrders,
    pagination,
    customerOrdersIds,
    customerOrdersQuantity: pagination?.totalResults,
    isLoading,
    isSuccess,
    isError,
  };
}

export default useCustomerOrders;
