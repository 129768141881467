import { UsersListItemResponseDto } from '@tiendanube/common';
import { useListDesktop } from 'App/hooks';
import { useListFilters } from 'commons/hooks';
import { UsersEndpoints } from 'domains/Configurations/Users/usersApi';

const DEFAULT_FILTERS = { page: 1 };

function useUsersListDesktop() {
  const { filters, changeFilters } = useListFilters(
    'account/users',
    DEFAULT_FILTERS,
  );

  const changePage = (page: number) => {
    changeFilters({ ...filters, page });
  };

  const result = useListDesktop<UsersListItemResponseDto>({
    filters: { ...filters, perPage: '20' },
    endpoint: UsersEndpoints.getUsersList,
  });

  return { ...result, changePage, filters };
}

export default useUsersListDesktop;
