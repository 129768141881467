import { Route } from 'react-router-dom';
import { MarketingAppsPage } from './pages';
import { MARKETING_ROUTES } from '../marketingRoutes';

function MarketingApps(): JSX.Element {
  return (
    <Route path={MARKETING_ROUTES.featuredApplications} exact>
      <MarketingAppsPage />
    </Route>
  );
}

export default MarketingApps;
