import { CitiesInterface } from 'domains/Shipping/Addresses/addressesSlice/types';

export const getAllCitiesChecked = (
  selectedCities: string[],
  allCities: CitiesInterface[],
): boolean | 'indeterminate' => {
  if (selectedCities.length === 0) {
    return false;
  }

  if (selectedCities.length === allCities.length) {
    return true;
  }

  return 'indeterminate';
};
