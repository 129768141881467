import { Box, Text, Icon, BoxProps } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { Link } from 'react-router-dom';
import { IconBox } from 'commons/components';
import Skeleton from './Skeleton';
import { ICON_BORDER_COLOR_STYLE } from './utils';

export type PendingOrdersCardAppearanceType =
  | 'neutral-surface'
  | 'warning-surface'
  | 'primary-surface';

export const BOX_PROPS_PENDING_ORDERS_CARD: BoxProps = {
  borderColor: 'neutral-surfaceHighlight',
  borderStyle: 'solid',
  borderRadius: 'base',
  borderWidth: '1',
  padding: '2',
  gap: '2',
  flex: '1',
  width: '100%',
};

interface PendingOrdersCardProps {
  appearance?: PendingOrdersCardAppearanceType;
  title?: string;
  subtitle: string;
  to: string;
  icon: React.ReactNode;
  skeleton?: boolean;
  onClick?: () => void;
  hideIcon?: boolean;
  shouldGoToOldAdmin: boolean;
}

function PendingOrdersCard({
  appearance = 'neutral-surface',
  title,
  subtitle,
  icon,
  skeleton,
  to,
  onClick,
  hideIcon,
  shouldGoToOldAdmin,
}: PendingOrdersCardProps) {
  if (skeleton) return <Skeleton />;
  return (
    <Box {...BOX_PROPS_PENDING_ORDERS_CARD}>
      <Box
        pointerEvents={hideIcon ? 'none' : undefined}
        as={shouldGoToOldAdmin ? 'a' : Link}
        to={shouldGoToOldAdmin ? '' : to}
        href={shouldGoToOldAdmin ? to : undefined}
        onClick={onClick}
        cursor="pointer"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        textDecoration="none"
      >
        <Box display="flex" gap="2" alignItems="center">
          <IconBox
            borderStyle="solid"
            borderWidth="1"
            borderColor={ICON_BORDER_COLOR_STYLE[appearance]}
            backgroundColor={appearance}
          >
            <Icon source={icon} />
          </IconBox>
          <Box display="flex" flexDirection="column">
            {!!title && (
              <Text fontSize="highlight" fontWeight="bold">
                {title}
              </Text>
            )}
            <Text
              color="neutral-textDisabled"
              fontSize={title ? undefined : 'highlight'}
            >
              {subtitle}
            </Text>
          </Box>
        </Box>
        {!hideIcon && (
          <Box
            color="neutral-textLow"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <ChevronRightIcon />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PendingOrdersCard;
