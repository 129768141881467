import { createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'commons/types';
import { CouponActivityLogDto } from './types';

export interface InterfaceCouponHistorySchema {
  status: StatusType;
  items: CouponActivityLogDto[];
  currentPage: number;
}

const initialState: InterfaceCouponHistorySchema = {
  status: 'idle',
  items: [],
  currentPage: 1,
};

const couponSlice = createSlice({
  name: 'couponHistory',
  initialState,
  reducers: {
    addItems: (state, action) => {
      state.items = [...state.items, ...action.payload.results];
    },
    incrementPage: (state) => {
      state.currentPage += 1;
    },
    resetCouponHistory: (state) => {
      state.currentPage = 1;
      state.items = [];
    },
  },
});
export const { addItems, incrementPage, resetCouponHistory } =
  couponSlice.actions;
export const { reducer } = couponSlice;
