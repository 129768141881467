import { ptBR, es } from 'date-fns/locale';
import { Format } from 'commons/utils/date';
import { StatisticsGranularityType } from '../types';

export const LOCALES: Record<string, Locale> = {
  'pt-BR': ptBR,
  'es-AR': es,
  'es-MX': es,
};

export const MARGIN_BAR_CHART = 16;
export const MARGIN_BAR_CHART_TOTAL = MARGIN_BAR_CHART * 2; // Sum of total screen width padding
export const FONT_SIZE_BAR_CHART = '10px';
export const BAR_CHART_HEIGTH = 340;
export const STATS_PLAN_FREE = 1;
export const STATS_PLAN_1_AND_2 = 2;
export const STATS_PLAN_3 = 3;
export const STATS_PLAN_NEXT = -1;
export const STATS_DISABLED = 0;

export const FOTMATS_X_AXIS: Record<StatisticsGranularityType, Format> = {
  hourly: Format.HOUR_MINUTE_XS,
  daily: Format.DAY_MONTH_XS,
  weekly: Format.DAY_MONTH_XS,
  monthly: Format.MONTH_YEAR_XS,
  summary: Format.DAY_MONTH_XS,
  yearly: Format.YEAR_XS,
};

export const FOTMATS_TOOLTIP: Record<StatisticsGranularityType, Format> = {
  hourly: Format.HOUR_MINUTE_LONG,
  daily: Format.DAY_MONTH_LONG,
  weekly: Format.DAY_MONTH_XS,
  monthly: Format.MONTH_YEAR,
  summary: Format.DAY_MONTH_LONG,
  yearly: Format.YEAR_LONG,
};

export const KEY_STORAGE_FILTERS = 'statistics-filters';
