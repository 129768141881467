import { Title, Box } from '@nimbus-ds/components';
import { CogIcon, GlassesIcon, CalendarIcon, LockIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { HelpCard } from './HelpCard';

const HELP_CARDS = [
  {
    title: 'help.configuration.title',
    text: 'help.configuration.text',
    icon: <CogIcon />,
    href: 'help.configuration.link',
  },
  {
    title: 'help.verification.title',
    text: 'help.verification.text',
    icon: <GlassesIcon />,
    href: 'help.verification.link',
  },
  {
    title: 'help.expiration.title',
    text: 'help.expiration.text',
    icon: <CalendarIcon />,
    href: 'help.expiration.link',
  },
  {
    title: 'help.sslCertificate.title',
    text: 'help.sslCertificate.text',
    icon: <LockIcon />,
    href: 'help.sslCertificate.link',
  },
];

export function HelpSection() {
  const t = useTranslationConfigurations('domains');

  return (
    <Stack column align="stretch" gap="3">
      <Title as="h5">{t('help.title')}</Title>
      <Box
        display="grid"
        gridGap="4"
        gridTemplateColumns={{
          md: '1fr 1fr',
          xs: '1fr',
        }}
      >
        {HELP_CARDS.map(({ title, text, icon, href }) => (
          <HelpCard
            key={title}
            title={t(title)}
            text={t(text)}
            icon={icon}
            href={t(href)}
          />
        ))}
      </Box>
    </Stack>
  );
}
