import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Alert, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { EXPIRE_AT, getStorage, setStorage } from 'App/storageExpirable';
import {
  trackingWeightAndDimensionsAlertClick,
  trackingWeightAndDimensionsAlertClose,
} from 'domains/Catalog/Products/tracking';
import { useProductsList } from '../../../hooks';
import useBulkActions from '../BulkActionsProvider/useBulkActions';

const ALERT_DISMISSED = 'alertNoWeightAndDimensionsDismissed';

function AlertNoWeightAndDimensions(): JSX.Element | null {
  const { t } = useTranslation([DomainEnum.CATALOG]);

  const {
    isLoading,
    isError,
    filters,
    existsProductsWithoutWeightAndDimensions,
    getProductsList,
  } = useProductsList();

  const isMounted = useRef(false);

  const [exists, setExists] = useState(false);
  const [show, setShow] = useState(false);
  const { showAlertPrice } = useBulkActions();

  const fetchData = useCallback(async () => {
    const alertDismissed = await getStorage(ALERT_DISMISSED);

    const result = !alertDismissed
      ? await existsProductsWithoutWeightAndDimensions()
      : false;
    if (isMounted.current) {
      setExists(result);
      setShow(result);
    }
  }, [existsProductsWithoutWeightAndDimensions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isLoading || filters.weightDimension === 'false') {
      setShow(false);
    } else {
      setShow(exists);
    }
  }, [exists, isLoading, filters.weightDimension]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (showAlertPrice || isError) {
    return null;
  }

  const title = t('products.alertNoWeightAndDimensions.title');
  const primaryLabel = t('products.alertNoWeightAndDimensions.action');
  const handleOnClickPrimary = () => {
    getProductsList({ ...filters, weightDimension: 'false' });
    trackingWeightAndDimensionsAlertClick();
  };
  const description = t('products.alertNoWeightAndDimensions.description');

  const handleRemove = () => {
    setShow(false);
    setStorage(ALERT_DISMISSED, true, EXPIRE_AT.SEVEN_DAYS);
    trackingWeightAndDimensionsAlertClose();
  };

  return (
    <Alert
      show={show}
      title={title}
      appearance="warning"
      onRemove={handleRemove}
    >
      <Text>{description}</Text>
      <Button onClick={handleOnClickPrimary}>{primaryLabel}</Button>
    </Alert>
  );
}

export default AlertNoWeightAndDimensions;
