import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatadogUrlsStatusType } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import { fetchRoutesToWatch as fetchRoutesToWatchService } from '../routingLogServices';

export interface SetCurrentPageAction {
  pathPattern: string;
}

export interface CurrentPageInterface {
  pathPattern: string;
}

export interface RoutingLogSchema {
  routesToWatch: {
    status: StatusType;
    data: DatadogUrlsStatusType;
  };
  currentPage: CurrentPageInterface;
}

export const fetchRoutesToWatch = createAsyncThunk(
  'routesToWatch',
  fetchRoutesToWatchService,
);

const initialState: RoutingLogSchema = {
  routesToWatch: {
    data: {},
    status: 'idle',
  },
  currentPage: {
    pathPattern: '',
  },
};

const routingLogSlice = createSlice({
  name: 'routingLog',
  initialState,
  reducers: {
    setRoutesToWatchStatus(state, action: PayloadAction<StatusType>) {
      state.routesToWatch.status = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<SetCurrentPageAction>) {
      state.currentPage.pathPattern = action.payload.pathPattern;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoutesToWatch.fulfilled, (state, action) => {
      state.routesToWatch.data = action.payload;
      state.routesToWatch.status = 'success';
    });
    builder.addCase(fetchRoutesToWatch.pending, (state) => {
      state.routesToWatch.status = 'loading';
    });
  },
});

export const { reducer } = routingLogSlice;
export const { setCurrentPage, setRoutesToWatchStatus } =
  routingLogSlice.actions;
