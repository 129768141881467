import { useState } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import { SubscriptionResponseDto } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useModal } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  useGetLastPayment,
  useGetRecurrentPaymentConcepts,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PlansAndAppsSkeleton from '../../Skeleton';
import CancelSubscriptionModal from '../CancelSubscriptionModal';
import LastPaymentDetail from '../LastPaymentDetail';
import SubscriptionBody from '../SubscriptionBody';
import SubscriptionHeader from '../SubscriptionHeader';

function SubscriptionDetail(): JSX.Element {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps',
  );

  const { refreshSubscriptions, ...getSubscription } = useGetSubscriptions();
  const { refreshRecurrentPaymentConcepts, ...getRecurrentConcepts } =
    useGetRecurrentPaymentConcepts();

  const { refreshLastPayment, ...getLastPayment } =
    useGetLastPayment('plan-cost');

  const { isLoading, isError } = combineStatus(
    getSubscription.status,
    getLastPayment.status,
    getRecurrentConcepts.status,
  );

  const retryLoad = () => {
    refreshRecurrentPaymentConcepts();
    refreshSubscriptions();
    refreshLastPayment();
  };

  const [open, onOpen, onClose] = useModal();

  const [subscriptionToCancel, setSubscriptionToCancel] = useState<
    SubscriptionResponseDto | undefined
  >();

  const handleCancelSubscription = (subscription: SubscriptionResponseDto) => {
    setSubscriptionToCancel(subscription);
    onOpen();
  };

  if (isLoading) return <PlansAndAppsSkeleton />;

  if (isError)
    return (
      <Card padding="none">
        <Card.Header padding="base">
          <Title as="h3">{t('titleWithoutApps')}</Title>
        </Card.Header>
        <ErrorState
          title={t('error.title')}
          action={{ children: t('error.retry'), onClick: retryLoad }}
        />
      </Card>
    );

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <SubscriptionHeader />
      </Card.Header>
      <Card.Body padding="base">
        <SubscriptionBody onCancelSubscription={handleCancelSubscription} />
      </Card.Body>
      <LastPaymentDetail />
      {open && (
        <CancelSubscriptionModal
          onClose={onClose}
          subscription={subscriptionToCancel}
        />
      )}
    </Card>
  );
}

export default SubscriptionDetail;
