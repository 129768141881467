import { ArrowLeftIcon } from '@nimbus-ds/icons';
import { Stack, Text } from '@tiendanube/components';
import {
  getAppearanceFromNumber,
  thousandSeparator,
} from 'domains/Statistics/utils';

interface TrendIndicatorProps {
  value: number;
  text?: string;
}

function TrendIndicator({ value, text }: TrendIndicatorProps) {
  const transform = value > 0 ? 'rotate(90)' : 'rotate(-90)';
  const appearance = getAppearanceFromNumber(value);

  return (
    <Stack wrap justify="space-between">
      {!!text && (
        <Text size="caption" appearance={appearance}>
          {text}
        </Text>
      )}
      <Text size="caption" appearance={appearance}>
        {value !== 0 && (
          <ArrowLeftIcon color={appearance} transform={transform} size={10} />
        )}
        {thousandSeparator(value, false)}%
      </Text>
    </Stack>
  );
}

export default TrendIndicator;
