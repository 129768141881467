import { useSelector } from 'react-redux';
import { CurrencyEnum } from '@tiendanube/common';
import { Currency } from '@tiendanube/common/src/enums';
import { getDefaultCurrency } from '../../authSlice/authSelectors';

export const getSymbolFromCurrency = (currency: CurrencyEnum): 'R$' | '$' => {
  const currencies = {
    BRL: 'R$',
  };
  return currencies[currency as string] ?? '$';
};
export interface useGetCurrencyProps {
  code: Currency;
  symbol: 'R$' | '$';
}
function useGetCurrency(): useGetCurrencyProps {
  const currency = useSelector(getDefaultCurrency);

  return { code: currency, symbol: getSymbolFromCurrency(currency) };
}

export default useGetCurrency;
