import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Format, dateFormat } from 'commons/utils/date';

dayjs.extend(customParseFormat);

export default function formatDateFilterChips(metafieldValue: string | number) {
  const isDate = dayjs(metafieldValue, 'YYYY-MM-DD', true).isValid();

  if (isDate) {
    return dateFormat(`${metafieldValue}`, Format.DD_MM_YYYY);
  }
  return `${metafieldValue}`;
}
