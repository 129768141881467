import React from 'react';
import { Tag, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import { FEATURE_AI_TIER } from 'App/features';
import { Stack } from 'commons/components';
import { AiIcon } from 'commons/components/GenerateWithAiLink';
import { useModal } from 'commons/hooks';
import RemainingAiUsesText from 'domains/Ai/components/RemainingAiUsesText';
import { useAiUsage } from 'domains/Ai/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';

interface UpsellModalInteractiveListActionProps {
  trackingSource: string;
  requiredTier: TypeFeaturesPlansListResponseDto;
  remainingAiUses?: number;
  maxAiUsesInPlan?: number;
}
interface ModalInteractiveListActionProps {
  cta: string;
  onOpen?: () => void;
  onClose?: () => void;
  modalChildren: React.ReactNode;
  upSellProps?: UpsellModalInteractiveListActionProps;
  aIPoweredAction?: boolean;
  isBeta?: boolean;
}

interface ModalChildProps {
  isShow: boolean;
  onOpen: () => void;
  onClose: () => void;
}

function ModalInteractiveListAction({
  cta,
  onOpen,
  onClose,
  modalChildren,
  upSellProps,
  aIPoweredAction = false,
  isBeta = false,
}: Readonly<ModalInteractiveListActionProps>) {
  const [isShow, openModal, closeModal] = useModal();

  const handleOnOpen = () => {
    if (onOpen) {
      onOpen();
    }
    openModal();
  };
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };

  const { aiUsesUpsellTitle } = useAiUsage();

  const upsellFlow = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: upSellProps?.trackingSource,
    callback: handleOnOpen,
    minValue: upSellProps?.requiredTier,
    asAside: true,
  });

  const onClickOpen = !upSellProps ? handleOnOpen : upsellFlow;

  return (
    <InteractiveList.ButtonItem
      title=""
      showTitle={false}
      iconButton={{
        onClick: onClickOpen,
      }}
    >
      <Stack align="center" spacing="tight">
        {aIPoweredAction && <AiIcon />}
        <Stack.Item fill>
          <Text color="neutral-textHigh">{cta}</Text>
          {upSellProps?.remainingAiUses !== undefined &&
            upSellProps?.maxAiUsesInPlan !== undefined && (
              <RemainingAiUsesText
                aiFeatureTrackingSource={upSellProps.trackingSource}
                textAlign="left"
              />
            )}
        </Stack.Item>
        {isBeta && <Tag appearance="primary">Beta</Tag>}
      </Stack>
      {React.isValidElement<ModalChildProps>(modalChildren) &&
        React.cloneElement(modalChildren, {
          isShow,
          onClose: handleOnClose,
        })}
    </InteractiveList.ButtonItem>
  );
}

export default ModalInteractiveListAction;
