import { Route } from 'react-router-dom';
import { MENUS_ROUTES } from './menusRoutes';
import { MenusPage } from './pages';

function Menus() {
  return (
    <Route path={MENUS_ROUTES.menus} exact>
      <MenusPage />
    </Route>
  );
}

export default Menus;
