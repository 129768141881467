import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { InterfaceLink } from '@tiendanube/components';

interface InternalNavLinkProps extends Omit<InterfaceLink, 'href' | 'onClick'> {
  to: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function InternalNavLink({
  to,
  className = '',
  children,
  ...rest
}: InternalNavLinkProps): JSX.Element {
  return (
    <Link
      className={`nimbus--link nimbus--link--primary nimbus--link--base ${className}`}
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
}

export default InternalNavLink;
