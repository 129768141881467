import { useEffect } from 'react';
import { DiscountType } from '@tiendanube/common';
import {
  Card,
  Stack,
  Text,
  IconButton,
  InterfaceNameValue,
  Link,
  Alert,
} from '@tiendanube/components';
import { PlusCircleIcon, TrashIcon } from '@tiendanube/icons';
import { SelectedProductInterface } from 'domains/Catalog/Products/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  ProductThumbnail,
  ProductAttributes,
  OrderSummaryAmounts,
} from './components';
import { generateVariant, getVariantByValues } from './variantsUtils';

export interface SelectedVariantInterface {
  productId: string;
  variantId?: string;
  quantity?: number;
  price?: number;
  stock?: number;
  values?: Record<string, string>;
}

export interface OrderSummaryValuesInterface {
  products: SelectedVariantInterface[];
  subtotal: number;
  total: number;
  shipping: number;
  discount: number;
  discountType: DiscountType;
}

interface OrderSummaryProductsProps {
  selectedProducts: SelectedProductInterface[];
  values: OrderSummaryValuesInterface;
  errors: Record<string, string>;
  onChangeVariants: (selectedVariants: SelectedVariantInterface[]) => void;
  onChange: ({ value, name }: { value: string; name: string }) => void;
  onRemove: (variant: SelectedVariantInterface) => void;
}

function OrderSummaryProducts({
  selectedProducts,
  values,
  errors,
  onChangeVariants,
  onChange,
  onRemove,
}: OrderSummaryProductsProps): JSX.Element {
  const t = useTranslationOrders();
  useEffect(() => {
    // Check if all variant elements are created
    selectedProducts.forEach((product) => {
      if (!values.products.some((p) => p.productId === product.id)) {
        onChangeVariants([...values.products, generateVariant(product)]);
      }
    });

    // Remove variant if product is removed
    values.products.forEach((variant) => {
      if (!selectedProducts.some((p) => p.id === variant.productId)) {
        onChangeVariants(
          values.products.filter((v) => v.productId !== variant.productId),
        );
      }
    });
  }, [selectedProducts, values.products, onChangeVariants]);

  const handleOnAddVariant = (product: SelectedProductInterface) => {
    onChangeVariants([...values.products, generateVariant(product)]);
  };

  const handleOnChangeAttribute = (
    product: SelectedProductInterface,
    variant: SelectedVariantInterface,
    e: InterfaceNameValue,
  ): void => {
    if (variant.values) {
      variant.values[e.name] = e.value;
      const productVariant = getVariantByValues(product, variant.values);

      variant.variantId = productVariant?.id;
      variant.price = productVariant?.price;
      variant.stock = productVariant?.stock;
    }
    onChangeVariants(values.products);
  };

  const handleOnChangeQuantity = (
    variant: SelectedVariantInterface,
    e: InterfaceNameValue,
  ): void => {
    if (variant)
      variant.quantity = Number(e.value) > 0 ? Number(e.value) : undefined;
    onChangeVariants(values.products);
  };

  const products = selectedProducts.map((product) => ({
    productData: product,
    variants: values.products.filter((v) => v.productId === product.id),
  }));

  return (
    <Card title={t('draftOrders.newDraftOrder.orderSummary.title')}>
      <Stack column align="stretch" spacing="loose">
        {products.map(({ productData, variants }) =>
          variants.map((variant, variantIndex) => (
            <Stack key={variantIndex} column spacing="tight" align="stretch">
              <Stack spacing="base" align="stretch">
                <Stack.Item>
                  <ProductThumbnail
                    imageUrl={productData.imageUrl}
                    altText={productData.name}
                  />
                </Stack.Item>
                <Stack.Item fill>
                  <Stack column align="stretch" spacing="tight">
                    <Stack align="center">
                      <Stack.Item fill>
                        <Text bold>{productData.name}</Text>
                      </Stack.Item>
                      <IconButton
                        icon={TrashIcon}
                        ariaLabel={t(
                          'draftOrders.newDraftOrder.orderSummary.delete',
                        )}
                        onClick={() => onRemove(variant)}
                      />
                    </Stack>
                    <ProductAttributes
                      product={productData}
                      variant={variant}
                      errors={errors}
                      onChangeAttribute={handleOnChangeAttribute}
                      onChangeQuantity={handleOnChangeQuantity}
                    />
                    {productData.variants.length > variants.length && (
                      <Link
                        appearance="primary"
                        icon={PlusCircleIcon}
                        iconPosition="start"
                        onClick={() => handleOnAddVariant(productData)}
                      >
                        {t('draftOrders.newDraftOrder.orderSummary.addVariant')}
                      </Link>
                    )}
                  </Stack>
                </Stack.Item>
              </Stack>
              <Alert
                appearance="warning"
                title={t(
                  'draftOrders.newDraftOrder.orderSummary.errors.duplicated',
                )}
                show={
                  !!errors?.[`products[${variant.variantId}].duplicated`] &&
                  variants.findIndex(
                    (v) => v.variantId === variant.variantId,
                  ) !== variantIndex
                }
              >
                {t(
                  'draftOrders.newDraftOrder.orderSummary.errors.duplicatedBody',
                )}
              </Alert>
            </Stack>
          )),
        )}
        <OrderSummaryAmounts values={values} onChange={onChange} />
      </Stack>
    </Card>
  );
}

export default OrderSummaryProducts;
