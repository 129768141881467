import { Alert, Stack, Text, Link } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

// TODO: handle redirect
function FreeShippingAlert(): JSX.Element {
  const t = useTranslationShipping();

  return (
    <Alert show>
      <Stack column align="stretch">
        <Text size="caption">
          {t('shippingModalities.freeShippingAlert.text')}
        </Text>
        <Link
          iconPosition="end"
          size="small"
          underline
          icon={ExternalLinkIcon}
          iconSize="small"
        >
          {t('shippingModalities.freeShippingAlert.linkText')}
        </Link>
      </Stack>
    </Alert>
  );
}

export default FreeShippingAlert;
