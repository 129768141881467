import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import ModalAside from 'App/components/lab/ModalAside';
import { useModal } from 'commons/hooks';
import { Brand, Seo, AllTags, ShowTagsAndSeoCard } from './components';
import { onChangeBrandType } from './components/Brand/Brand';
import { onChangeSeoType } from './components/Seo/FormSeo/FormSeo';
import { SeoProps } from './components/Seo/Seo';

export type TagsType = string[];

export type onChangeTagsType = ({
  name,
  value,
}: {
  name: string;
  value: TagsType;
}) => void;

interface TagsAndSeoProps extends SeoProps {
  tags: string[];
  onChangeBrand: onChangeBrandType;
  onChangeSeo: onChangeSeoType;
  onChangeTags: onChangeTagsType;
  brand: string;
  isEdit?: boolean;
}

function TagsAndSeo({
  tags,
  onChangeBrand,
  brand,
  name,
  description,
  seoDescription,
  seoTitle,
  onChangeSeo,
  onChangeTags,
  seoUrl,
  isEdit,
}: Readonly<TagsAndSeoProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const [isShowModal, openModal, closeModal] = useModal();

  return (
    <>
      <ShowTagsAndSeoCard
        tags={tags}
        onChange={onChangeTags}
        onOpen={openModal}
      />

      <ModalAside
        isOpen={isShowModal}
        onDidDismiss={closeModal}
        headerContent={t('products.detail.tags.title')}
      >
        <AllTags tags={tags} onChange={onChangeTags} />
        <Brand onChange={onChangeBrand} value={brand} />
        <Seo
          name={name}
          description={description}
          seoDescription={seoDescription}
          seoTitle={seoTitle}
          onChangeSeo={onChangeSeo}
          seoUrl={seoUrl}
          isEdit={isEdit}
        />
      </ModalAside>
    </>
  );
}

export default TagsAndSeo;
