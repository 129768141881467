import { Modal, Box, Text } from '@nimbus-ds/components';
import CancelAndConfirmButtons from 'commons/components/CancelAndConfirmButtons';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface DeleteEnabledCountryModalProps {
  onDelete: () => void;
  onClose: () => void;
  isLoading: boolean;
}
function DeleteEnabledCountryModal({
  onDelete,
  onClose,
  isLoading,
}: DeleteEnabledCountryModalProps) {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header title={t('deleteEnabledCountryModal.title')} />
      <Modal.Body padding="none">
        <Box paddingTop="4">
          <Text>{t('deleteEnabledCountryModal.description')}</Text>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndConfirmButtons
          isLoading={isLoading}
          isCancelDisabled={isLoading}
          confirmText={t('buttonDisable')}
          onCancel={onClose}
          onConfirm={onDelete}
          appearance="danger"
        />
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteEnabledCountryModal;
