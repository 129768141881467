import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { logout as logoutAction } from '../../../authSlice/authSlice';

type useLogoutResult = () => void;

function useLogout(): useLogoutResult {
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return logout;
}

export default useLogout;
