import { useEffect, useState } from 'react';

const useMountTransition = (isOpen: boolean, unmountDelay: number) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (isOpen && !hasTransitionedIn) {
      setHasTransitionedIn(true);
    } else if (!isOpen && hasTransitionedIn) {
      timeoutId = setTimeout(() => {
        setHasTransitionedIn(false);
      }, unmountDelay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isOpen, hasTransitionedIn]);

  return hasTransitionedIn;
};

export default useMountTransition;
