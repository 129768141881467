import { useState } from 'react';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Link, Stack, Text, Tooltip } from '@tiendanube/components';
import {
  StickyNoteIcon,
  ChatDotsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@tiendanube/icons';
import { InternalNavLink } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import { dateFormat, Format } from 'commons/utils/date';
import { ResultProductsDesktop } from 'domains/Orders/components';
import { useOrderListItem } from 'domains/Orders/Orders/hooks';
import OriginLabel, {
  showOriginLabel,
} from '../../../../../commons/OriginLabel';
import {
  ColumnFulfillmentStatus,
  ColumnPaymentStatus,
} from '../../../../../commons/Status';
import QuickActionsPopover from '../QuickActionsPopover';
import './ResultRowDesktop.scss';

interface ResultRowDesktopProps {
  id: string;
}

const { Row, Cell } = DataTable;

function ResultRowDesktop({ id }: ResultRowDesktopProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation([Domain.ORDERS]);
  const { order, getOrderProducts, isLoadingProducts, isErrorProducts } =
    useOrderListItem(id);
  const [showProducts, setShowProducts] = useState(false);

  const handleShowProducts = () => {
    const showProductsUpdated = !showProducts;
    setShowProducts(showProductsUpdated);
    if (!order) return;
    if (showProductsUpdated && !order.products && !isLoadingProducts) {
      getOrderProducts();
    }
  };

  useToastStatus({
    error: t('toast.errorProductList'),
    status: isErrorProducts ? 'error' : 'idle',
  });

  if (!order) return null;

  const productsQuantity = order.quantityProducts;

  return (
    <>
      <Row key={id} id={id} align="center">
        <Cell grow={0} basis={90}>
          <div className="stratus--order-number-cell">
            <Stack spacing="tight">
              <Stack.Item>
                <InternalNavLink
                  appearance="primary"
                  to={`/orders/${order.id}`}
                >
                  {`${t('resultRow.title', { number: order.number })}`}
                </InternalNavLink>
              </Stack.Item>
              {!!order?.orderOrigin && (
                <Tooltip labelIcon={InvoiceIcon} labelText={order.orderOrigin}>
                  <Text>{order.orderOrigin}</Text>
                </Tooltip>
              )}
              {!!order.remarks && (
                <Stack.Item>
                  <Tooltip labelIcon={StickyNoteIcon} labelText={order.remarks}>
                    <Text>{order.remarks}</Text>
                  </Tooltip>
                </Stack.Item>
              )}
              {showOriginLabel(order) && (
                <Stack.Item>
                  <OriginLabel order={order} />
                </Stack.Item>
              )}
            </Stack>
          </div>
        </Cell>
        <Cell grow={0} basis={70}>
          <Text>{dateFormat(order.date, Format.DAY_MONTH)}</Text>
        </Cell>
        <Cell grow={1} basis={100}>
          <Stack spacing="tight">
            {order.consumer && order.consumer.name !== '' ? (
              <>
                <div className="stratus--orders-result-desktop__customer-name">
                  <InternalNavLink
                    appearance="primary"
                    to={`/customers/${order.consumer.id}`}
                  >
                    <Text trimText trimLines={2}>
                      {order.consumer.name}
                    </Text>
                  </InternalNavLink>
                </div>
                {!!order.consumer.remarks && (
                  <Tooltip
                    labelIcon={ChatDotsIcon}
                    labelText={order.consumer.remarks}
                  >
                    <Text>{order.consumer.remarks}</Text>
                  </Tooltip>
                )}
              </>
            ) : (
              <Text appearance="light">{t('without-customer')}</Text>
            )}
          </Stack>
        </Cell>
        <Cell grow={0} basis={125}>
          <Text trimText trimLines={1}>
            {formatCurrency(order.total, order.currency, language)}
          </Text>
        </Cell>
        <Cell grow={0} basis={100}>
          <Link
            appearance="primary"
            icon={showProducts ? ChevronUpIcon : ChevronDownIcon}
            iconPosition="end"
            onClick={handleShowProducts}
          >
            {t('resultRow.products.show', { count: productsQuantity })}
          </Link>
        </Cell>
        <Cell grow={0} basis={200}>
          <ColumnPaymentStatus order={order} />
        </Cell>
        <Cell grow={0} basis={200}>
          <div className="cell-align-self-start">
            <ColumnFulfillmentStatus order={order} />
          </div>
        </Cell>
        <Cell grow={0} basis={80}>
          <QuickActionsPopover order={order} />
        </Cell>
      </Row>
      <ResultProductsDesktop
        show={showProducts}
        products={order.products}
        quantityUniqueProducts={order.quantityUniqueProducts}
        currency={order.currency as CurrencyType}
        listLayout="orders"
      />
    </>
  );
}

export default ResultRowDesktop;
