import { Tooltip } from '@nimbus-ds/components';
import { CameraIcon, ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { Label, Stack, Thumbnail } from '@tiendanube/components';
import { AppearanceColorEnum } from 'commons/enums';
import { useGetLanguage } from 'domains/Auth/hooks';
import useDuplicateAttributes from 'domains/Catalog/Products/pages/components/Variants/hooks/useDuplicateAttributes';
import { HiddenProductPill } from '../../../../../HiddenProductPill';
import LinkGoToProduct from '../LinkGoToAdminProduct';

interface ProductCellProps {
  product: Pick<
    ProductDetailsResponseDto,
    'tags' | 'images' | 'name' | 'id' | 'published'
  >;
  maxTagsAndMaxTags: number;
}

function ProductCell({
  product,
  maxTagsAndMaxTags,
}: ProductCellProps): JSX.Element {
  const language = useGetLanguage();
  const { withDuplicateAttributesMainLanguage } = useDuplicateAttributes(
    product.id,
  );
  const { t } = useTranslation(Domain.CATALOG);

  const allsTags = product.tags.split(',');
  const tags = allsTags.slice(0, maxTagsAndMaxTags);
  const [firstImage] = product.images;

  const tagsAndPill = allsTags.length > maxTagsAndMaxTags || !product.published;
  return (
    <Stack spacing="tight" align="flex-start">
      <Stack.Item>
        {firstImage && (
          <Thumbnail
            aspectRatio="1-1"
            width="72px"
            src={firstImage.src}
            altText={
              (firstImage.alt
                ? firstImage.alt[language]
                : `${product.name[language]} - ${firstImage.id}`) || ''
            }
          />
        )}
        {!firstImage && (
          <Thumbnail.Empty
            aspectRatio="1-1"
            width="72px"
            placeholderIcon={CameraIcon}
          />
        )}
      </Stack.Item>
      <Stack.Item fill>
        <Stack column align="flex-start" spacing="tight">
          <Stack.Item>
            <LinkGoToProduct
              title={product.name[language] || ''}
              id={product.id}
              bold
            />
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="tight" wrap>
              {tags.map((tag) =>
                tag ? (
                  <Stack.Item key={tag}>
                    <Label id={tag} label={tag} />
                  </Stack.Item>
                ) : null,
              )}
            </Stack>
          </Stack.Item>
          {tagsAndPill && (
            <Stack.Item>
              {allsTags.length > maxTagsAndMaxTags && (
                <LinkGoToProduct
                  title={t('products.moreTags')}
                  id={product.id}
                />
              )}
              {!product.published && (
                <HiddenProductPill id={`${product.id}`} withIcon />
              )}
            </Stack.Item>
          )}
          {withDuplicateAttributesMainLanguage && (
            <Stack.Item>
              <Tooltip content={t('products.infoTagTooltip')}>
                <Label
                  id={`${product.id}`}
                  label={t('products.tagDuplicateAttributes')}
                  appearance={AppearanceColorEnum.WARNING}
                  icon={ExclamationTriangleIcon}
                />
              </Tooltip>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default ProductCell;
