import { Box, Button, Icon, Text } from '@nimbus-ds/components';
import { BoxUnpackedIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function InventoryEmpty(): JSX.Element {
  const t = useTranslationCatalog();
  const { goTo } = useNavegate();
  const handleClick = () => goTo('/products');

  return (
    <Box
      alignItems="center"
      display="flex"
      height="70vh"
      justifyContent="center"
      width="100%"
      flexDirection="column"
      gap="4"
    >
      <Box
        alignItems="center"
        display="flex"
        gap="2"
        flexDirection="column"
        width="100%"
      >
        <Icon
          color="neutral-textHigh"
          source={<BoxUnpackedIcon size="medium" />}
        />
        <Text
          color="neutral-textHigh"
          textAlign="center"
          fontWeight="bold"
          fontSize="highlight"
        >
          {t('inventory.emptyLocation.title')}
        </Text>
        <Text color="neutral-textHigh" textAlign="center" fontSize="base">
          {t('inventory.emptyLocation.body')}
        </Text>
      </Box>
      <Button as="a" onClick={handleClick} appearance="primary">
        {t('inventory.emptyLocation.action')}
      </Button>
    </Box>
  );
}

export default InventoryEmpty;
