import { useEffect } from 'react';
import { Stack } from '@tiendanube/components';
import { AlertSessionStorage } from 'App/components';
import { CardWithTitle, ErrorScreen } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { useProductSections } from 'domains/Online';
import { SectionCalloutCard, Skeleton } from './components';

function FeaturedProducts() {
  const t = useTranslationCatalog();
  const { productSections, status, fetchProductSections } =
    useProductSections();

  useEffect(() => {
    fetchProductSections();
  }, [fetchProductSections]);

  return (
    <CardWithTitle title={t('products.organizeProducts.featured')}>
      <Stack column align="stretch">
        <AlertSessionStorage
          appearance="primary"
          title={t('products.organizeProducts.featuredProducts.important')}
          keyName="organizeProducts"
        >
          {t('products.organizeProducts.featuredProducts.alert')}
        </AlertSessionStorage>
        {status === 'loading' && <Skeleton />}
        {status === 'error' && (
          <ErrorScreen
            onRetry={fetchProductSections}
            description={t('products.organizeProducts.error')}
          />
        )}
        {status === 'success' &&
          productSections &&
          productSections.map((section) => {
            const { code, name, productCount } = section;
            return (
              <Stack.Item key={section.code}>
                <SectionCalloutCard
                  code={code}
                  name={name}
                  total={productCount}
                />
              </Stack.Item>
            );
          })}
      </Stack>
    </CardWithTitle>
  );
}

export default FeaturedProducts;
