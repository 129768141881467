import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  enableCarrierAction,
  EnableCarrierParams,
  enableCarrierStatus,
} from '../../deliveryMethodsSlice';

function useEnableCarrier() {
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess } = useSelector(enableCarrierStatus);

  const enableCarrier = useCallback(
    async (params: EnableCarrierParams) => {
      await dispatch(enableCarrierAction(params));
    },
    [dispatch],
  );

  return {
    enableCarrier,
    isLoading,
    isSuccess,
  };
}

export default useEnableCarrier;
