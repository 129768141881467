import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { MAX_ITEMS_PER_PAGE } from 'commons/constants';
import { useListFilters } from 'commons/hooks';
import { useHasCatalogLazyVariants } from 'domains/Auth/hooks';
import {
  ProductsFiltersInterface,
  ProductsFiltersType,
} from 'domains/Catalog/Products/productsServices';
import {
  existsProductsWithoutWeightAndDimensions as existsProductsWithoutWeightAndDimensionsAction,
  getProducts,
  getMoreProducts as getMoreProductsAction,
  getProductVariants as getProductVariantsAction,
  getProductsQueryType,
  statusSuccessType,
} from 'domains/Catalog/Products/productsSlice';
import { defaultFilters } from 'domains/Catalog/Products/productsSlice/constants';
import {
  getProductsList as getProductsListSelector,
  hasVariants as hasVariantsSelector,
  getTotalProducts,
  getAppliedFilters,
  getProductsStatusSuccess,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useGetVariantsMetafields } from 'domains/Metafields/hooks';

interface UseProductsListResult {
  isLoading: boolean;
  isSuccess: boolean;
  statusSuccess: statusSuccessType;
  isError: boolean;
  productsCount: number;
  productsIds: string[];
  filters: ProductsFiltersInterface;
  appliedFilters: ProductsFiltersInterface;
  hasFilters: boolean;
  hasVariants: boolean;
  existsProductsWithoutWeightAndDimensions: () => Promise<boolean>;
  getProductsList: (filters?: ProductsFiltersType) => Promise<void>;
  refreshProductsList: () => Promise<void>;
  getMoreProducts: () => Promise<void>;
  getProductVariants: (idProduct: string) => void;
  goToPage: (page: number) => Promise<void>;
  changeFilters: (filters: ProductsFiltersInterface) => void;
  removeMetafieldsFilters: () => void;
}

function useProductsList(): UseProductsListResult {
  const dispatch = useAppDispatch();

  const { isFilterAvailable } = useGetVariantsMetafields();
  const hasCatalogLazyVariants = useHasCatalogLazyVariants();

  const { filters, changeFilters, removeMetafieldsFilters } = useListFilters(
    'products',
    defaultFilters,
    true,
  );

  // hasFilters changed because now the sortby and filter are separated and should not be used from the useListFilters hook
  const hasFilters =
    Object.keys(filters).filter(
      (key) =>
        filters[key] &&
        !((key === 'page' && Number(filters[key]) === 1) || key === 'sortBy'),
    ).length > 0;

  const {
    isLoading,
    isSuccess,
    isError,
    ids: productsIds,
  } = useSelector(getProductsListSelector);
  const appliedFilters = useSelector(getAppliedFilters);
  const productsCount = useSelector(getTotalProducts);
  const hasVariants = useSelector(hasVariantsSelector);
  const statusSuccess = useSelector(getProductsStatusSuccess);

  let typeGetProducts: getProductsQueryType;
  if (hasCatalogLazyVariants) {
    typeGetProducts = 'advancedLazyVariants';
  } else if (isFilterAvailable) {
    typeGetProducts = 'advanced';
  } else {
    typeGetProducts = 'normal';
  }

  const existsProductsWithoutWeightAndDimensions =
    useCallback(async (): Promise<boolean> => {
      const result = await dispatch(
        existsProductsWithoutWeightAndDimensionsAction(),
      );
      return unwrapResult(result);
    }, [dispatch]);

  const getProductsList = useCallback(
    async (customFilters?: ProductsFiltersType) => {
      // TODO: Remove "q" condition when the API accepts text search along with the other filters
      const newFilters =
        customFilters?.q && filters.q !== customFilters.q
          ? { q: customFilters.q }
          : { ...customFilters, q: '' };
      const fetchFilters = customFilters
        ? { ...(newFilters as ProductsFiltersInterface), page: 1 }
        : filters;

      if (!isEqual(fetchFilters, appliedFilters)) {
        changeFilters(fetchFilters);
        await dispatch(
          getProducts({ filters: fetchFilters, type: typeGetProducts }),
        );
      }
    },
    [filters, appliedFilters, changeFilters, dispatch, typeGetProducts],
  );

  const refreshProductsList = useCallback(async () => {
    const filtersWithFirstPage = { ...filters, page: 1 };

    changeFilters(filtersWithFirstPage);
    await dispatch(
      getProducts({ filters: filtersWithFirstPage, type: typeGetProducts }),
    );
  }, [filters, changeFilters, dispatch, typeGetProducts]);

  const getMoreProducts = useCallback(async () => {
    if (productsIds.length !== filters.page * MAX_ITEMS_PER_PAGE) return;
    const newFilters = { ...filters, page: filters.page + 1 };
    changeFilters(newFilters);
    await dispatch(
      getMoreProductsAction({ filters: newFilters, type: typeGetProducts }),
    );
  }, [changeFilters, dispatch, filters, productsIds.length, typeGetProducts]);

  const getProductVariants = useCallback(
    (idProduct: string) => {
      dispatch(getProductVariantsAction(idProduct));
    },
    [dispatch],
  );

  const goToPage = useCallback(
    async (page: number) => {
      const newFilters = { ...filters, page };
      changeFilters(newFilters);
      await dispatch(
        getProducts({ filters: newFilters, type: typeGetProducts }),
      );
    },
    [changeFilters, dispatch, filters, typeGetProducts],
  );

  return {
    isLoading,
    isSuccess,
    isError,
    statusSuccess,
    productsCount,
    productsIds,
    filters,
    appliedFilters,
    hasFilters,
    hasVariants,
    existsProductsWithoutWeightAndDimensions,
    getProductsList,
    refreshProductsList,
    getMoreProducts,
    getProductVariants,
    goToPage,
    changeFilters,
    removeMetafieldsFilters,
  };
}

export default useProductsList;
