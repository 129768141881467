import { Box } from '@nimbus-ds/components';
import { Text } from '@tiendanube/components';
import { ImageItemStratusSkeleton } from 'commons/components';

interface ResultProductsMobileSkeletonProps {
  readonly quantityUniqueProducts: number;
}

export function ResultProductsMobileSkeleton({
  quantityUniqueProducts,
}: ResultProductsMobileSkeletonProps): JSX.Element | null {
  return (
    <>
      {Array.from({ length: quantityUniqueProducts }).map((_, i) => (
        <div
          key={`product-skeleton-${i}`}
          className="stratus--orders-list-products-mobile"
        >
          <ImageItemStratusSkeleton
            imageWidth="48px"
            headerRight={<Text.Skeleton />}
          >
            <Box display="flex" flexDirection="column">
              <Text.Skeleton size="caption" />
              <Text.Skeleton size="caption" />
            </Box>
          </ImageItemStratusSkeleton>
        </div>
      ))}
    </>
  );
}
