import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { useAppDispatch } from 'App/store';
import { useEmailMessageLink } from 'commons/hooks';
import { anonymizeCustomer as anonymizeCustomerAction } from 'domains/Customers/Customers/customersSlice';

interface UseAnonymizeCustomerResults {
  anonymizeCustomer: () => Promise<void>;
  cancelAnonymizeCustomerLink: () => string;
}

function useAnonymizeCustomer(id: string): UseAnonymizeCustomerResults {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(Domain.CUSTOMERS);
  const getEmailMessageLink = useEmailMessageLink();

  const anonymizeCustomer = useCallback(async () => {
    await dispatch(anonymizeCustomerAction(id));
  }, [dispatch, id]);

  const cancelAnonymizeCustomerLink = useCallback(
    () =>
      getEmailMessageLink({
        email: t(
          'customerDetails.removeCustomerData.startedAnonymizedCancelEmail',
        ),
        subject: '',
        body: '',
      }),
    [getEmailMessageLink, t],
  );

  return {
    anonymizeCustomer,
    cancelAnonymizeCustomerLink,
  };
}

export default useAnonymizeCustomer;
