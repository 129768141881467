import { UpdatePayPalSettingsRequestDto } from '@tiendanube/common';
import {
  Stack,
  Text,
  Select,
  InterfaceNameValue,
  Title,
} from '@tiendanube/components';
import { BaseCardCollapsible, InputNumber } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface ShowInfoFinantialStoreProps {
  values: UpdatePayPalSettingsRequestDto;
  errors: Partial<UpdatePayPalSettingsRequestDto>;
  onChange: (event: InterfaceNameValue) => void;
  onToogle: () => void;
}

function ShowInfoFinantialStore({
  values,
  errors,
  onChange,
  onToogle,
}: ShowInfoFinantialStoreProps): JSX.Element {
  const t = useTranslationPayments('paymentProviders.payPalSettingsPage');

  const optionsMaxInstallments = new Array(12).fill(null).map((_, index) =>
    index === 0
      ? {
          label: t('showInfoFinantialStore.noneInstallments'),
          value: `${index + 1}`,
        }
      : {
          label: `${index + 1}`,
          value: `${index + 1}`,
        },
  );

  const optionsInstallmentsWithoutInterest = optionsMaxInstallments.filter(
    (current) => Number(current.value) <= Number(values.maxInstallments),
  );

  const title = (
    <Stack column align="flex-start" spacing="tight">
      <Title type="h3">{t('showInfoFinantialStore.title')}</Title>
      <Text>{t('showInfoFinantialStore.subtitle')}</Text>
    </Stack>
  );
  return (
    <BaseCardCollapsible
      active={values.showInfoOnStore}
      title={title}
      appearance="toggle"
      paddingBody="none"
      onChange={onToogle}
    >
      <Stack column justify="space-between" align="stretch">
        <Text>{t('showInfoFinantialStore.info')}</Text>
        <Select
          name="maxInstallments"
          label={t('showInfoFinantialStore.maxInstallments')}
          options={optionsMaxInstallments}
          value={`${values.maxInstallments}`}
          onChange={onChange}
        />
        <Select
          name="installmentsWithoutInterest"
          label={t('showInfoFinantialStore.installmentsWithoutInterest.label')}
          options={optionsInstallmentsWithoutInterest}
          value={`${values.installmentsWithoutInterest}`}
          onChange={onChange}
        />
        <Text size="caption">
          {t('showInfoFinantialStore.installmentsWithoutInterest.helpText')}
        </Text>
        <InputNumber
          type="float"
          label={t('showInfoFinantialStore.minInstallmentsValue')}
          prependLabel={values.minInstallmentsCurrencyCode}
          onChange={onChange}
          name="minInstallmentsValue"
          maxLength={8}
          value={values.minInstallmentsValue}
          appearance={
            errors.minInstallmentsValue ? 'validation_error' : undefined
          }
          helpText={
            errors.minInstallmentsValue
              ? t(errors.minInstallmentsValue, {
                  minValue: values.minInstallmentsValueAllowed,
                })
              : ''
          }
        />
      </Stack>
    </BaseCardCollapsible>
  );
}

export default ShowInfoFinantialStore;
