import { DataList, Stack, Text } from '@tiendanube/components';
import { useTranslationBilling } from 'domains/Billing';

const { Cell } = DataList;

interface DataTableRowProps {
  month: string | false;
  invoiceType: string;
  invoiceItem: string;
  period: string;
}

function RightCell({
  month,
  invoiceItem,
  invoiceType,
  period,
}: DataTableRowProps): JSX.Element {
  const t = useTranslationBilling();
  return (
    <Cell>
      <Stack column align="flex-start">
        {!!month && (
          <Text appearance="secondary" bold>
            {month}
          </Text>
        )}
        <Stack column align="flex-start" spacing="tight">
          <Text bold>{invoiceType}</Text>
          <Stack column spacing="none" align="flex-start">
            <Text>{invoiceItem}</Text>
            <Text appearance="light">{`${t(
              'invoiceHistory.period',
            )}: ${period}`}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Cell>
  );
}

export default RightCell;
