import { useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail, Link } from '@tiendanube/components';
import { AlertImagesValidation } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import FileDraggable from 'domains/Catalog/Products/pages/components/FileDraggable';
import { useImageLoader } from 'domains/Catalog/Products/pages/hooks';
import { trackingProductDropImages } from 'domains/Catalog/Products/tracking';
import ThumbnailItem from './ThumbnailItem';
import UploadingMessage from '../../../UploadingMessage';
import { aspectRatioDesktop } from '../../constants';
import usePreviewImages from '../../usePreviewImages';
import ImagePreviewEmpty from '../ImagePreviewEmpty';
import RecommendationsGridRow from '../RecommendationsGridRow';
import { ImagePreviewDesktopProps } from '../types';
import WithDragAndDropThumbnail from '../WithDragAndDropThumbnail';

const NUMBER_OF_IMAGES_TO_PREVIEW = 4;

function ImagePreviewDesktop({
  isEdit,
  images,
  message,
  onClickAdd,
  onRemove,
  onEdit,
  openModal,
  onError,
  onUploadImages,
}: ImagePreviewDesktopProps): JSX.Element {
  const language = useGetLanguage();
  const { t } = useTranslation(Domain.CATALOG);
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const sender = useTrackFullCatalog();

  const {
    isEmpty,
    isShowMoreImages,
    previewImages,
    morePhotosNumber,
    maxPreviewImages,
  } = usePreviewImages({
    numberOfImagesToPreview: NUMBER_OF_IMAGES_TO_PREVIEW,
    images,
  });

  const {
    imagesWithBadType,
    imagesExceededSize,
    setImagesExceededSize,
    setImagesWithBadType,
    handleOnchange,
  } = useImageLoader({
    onUploadImages,
  });

  const handleDragOver = (dragActive: boolean) => {
    setIsDragActive(dragActive);
  };

  const handleOnDrop = (files: File[]) => {
    setImagesExceededSize([]);
    setImagesWithBadType([]);
    handleOnchange(files);
    sender(() => {
      trackingProductDropImages({
        from: 'card',
        count: files.length,
        isEdit,
      });
    });
  };

  const hasAllSlots =
    (previewImages.length || 0) === NUMBER_OF_IMAGES_TO_PREVIEW;

  const textPlus = t('products.detail.morePhotos', {
    morePhotosNumber,
  });

  return (
    <FileDraggable onDropFiles={handleOnDrop} onDragOver={handleDragOver}>
      <Box display="flex" flexDirection="column" gap="4">
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gridGap="4">
          {previewImages.map((image, index) => {
            const lastPositionImage = index === previewImages.length - 1;
            const renderAdd = hasAllSlots && lastPositionImage;
            const showAdd = renderAdd && isDragActive;
            const showMainTag = index === 0;
            return (
              <WithDragAndDropThumbnail
                key={image.id}
                showAdd={showAdd}
                renderAdd={renderAdd}
              >
                <ThumbnailItem
                  key={image.id}
                  image={image}
                  alt={image?.alt?.[language] || ''}
                  src={image.src}
                  showMainTag={showMainTag}
                  onEdit={onEdit}
                  onError={onError}
                  onRemove={onRemove}
                />
              </WithDragAndDropThumbnail>
            );
          })}
          <ImagePreviewEmpty onClickAdd={onClickAdd} from={images.length} />
          {isShowMoreImages && (
            <WithDragAndDropThumbnail showAdd={isDragActive} renderAdd>
              <div style={{ opacity: isDragActive ? '0.6' : '1' }}>
                <Thumbnail.Plus
                  src={images[maxPreviewImages]?.src}
                  altText={textPlus}
                  ariaLabel={textPlus}
                  number={morePhotosNumber}
                  onClick={openModal}
                  aspectRatio={aspectRatioDesktop}
                />
              </div>
            </WithDragAndDropThumbnail>
          )}
        </Box>

        {!!message && <UploadingMessage message={message} />}
        <RecommendationsGridRow />

        {!isEmpty && (
          <Link appearance="primary" icon={EditIcon} onClick={openModal}>
            {t('products.detail.seeAllPhotos')}
          </Link>
        )}
        {imagesWithBadType.length > 0 && (
          <AlertImagesValidation
            filenames={imagesWithBadType}
            validation="type"
          />
        )}
        {imagesExceededSize.length > 0 && (
          <AlertImagesValidation
            filenames={imagesExceededSize}
            validation="size"
          />
        )}
      </Box>
    </FileDraggable>
  );
}

export default ImagePreviewDesktop;
