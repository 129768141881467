import { Responsive } from 'commons/components';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import {
  ComparisonPlansCardsDesktop,
  ComparisonPlansCardsMobile,
} from './components';
import { SelectedPlan } from './components/PlanCard/types';

interface ComparisonPlansCardsProps {
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonPlansCards({
  onSelectPlan,
}: ComparisonPlansCardsProps): JSX.Element {
  const { plansList, currentPlanId, currentPlanLevel } = useBillingPlans();

  return (
    <Responsive
      mobile={
        <ComparisonPlansCardsMobile
          plansList={plansList}
          currentPlanId={currentPlanId}
          currentPlanLevel={currentPlanLevel}
          onSelectPlan={onSelectPlan}
        />
      }
      desktop={
        <ComparisonPlansCardsDesktop
          plansList={plansList}
          currentPlanId={currentPlanId}
          currentPlanLevel={currentPlanLevel}
          onSelectPlan={onSelectPlan}
        />
      }
    />
  );
}
export default ComparisonPlansCards;
