import {
  memo,
  useMemo,
  type CSSProperties,
  type PropsWithChildren,
} from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIcon } from 'commons/components';
import './SortableItem.scss';

function SortableItemComponent({
  setNodeRef,
  setActivatorNodeRef,
  attributes,
  listeners,
  style,
  children,
}) {
  return (
    <li className="stratus--sortable-item" ref={setNodeRef} style={style}>
      <button
        className="stratus--sortable-item-drag-handle"
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
      >
        <DragIcon />
      </button>
      <div className="stratus--sortable-item-children">{children}</div>
    </li>
  );
}

const SortableItemComponentMemo = memo(SortableItemComponent);

interface SortableItemProps {
  id: UniqueIdentifier;
}

export function SortableItem({
  children,
  id,
}: Readonly<PropsWithChildren<SortableItemProps>>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const memoListeners = useMemo(() => listeners, [listeners]);
  const style: CSSProperties = useMemo(
    () => ({
      opacity: isDragging ? 0.4 : undefined,
      transform: CSS.Translate.toString(transform),
      transition,
    }),
    [isDragging, transform, transition],
  );

  return (
    <SortableItemComponentMemo
      setNodeRef={setNodeRef}
      setActivatorNodeRef={setActivatorNodeRef}
      attributes={attributes}
      listeners={memoListeners}
      style={style}
    >
      {children}
    </SortableItemComponentMemo>
  );
}
