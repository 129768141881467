import {
  FulfillmentOrderForDocumentResponseDto,
  GetDocumentSettingsResponseDto,
  OrderForDocumentResponseDto,
  StoreForDocumentResponseDto,
} from '@tiendanube/common';
import {
  PICKUP_TYPE,
  SHIPPING_TYPE,
} from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import CheckoutDocumentPreview from '../Checkout';
import DocumentHeaderPreview from '../Header';
import PickupDocumentPreview from '../Pickup';
import ProductsDocumentPreview from '../Products';
import ReceiptStubDocumentPreview from '../ReceiptStub';
import RecipientDocumentPreview from '../Recipient';
import SenderDocumentPreview from '../Sender';
import TotalDocumentPreview from '../Total';

interface GeneralDocumentPreviewFieldsProps {
  order: OrderForDocumentResponseDto;
  fulfillmentOrder: FulfillmentOrderForDocumentResponseDto;
  store: StoreForDocumentResponseDto;
  settings: GetDocumentSettingsResponseDto;
  isOca?: boolean;
}

function GeneralDocumentPreviewFields({
  fulfillmentOrder,
  store,
  order,
  settings,
  isOca = false,
}: Readonly<GeneralDocumentPreviewFieldsProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <>
      <DocumentHeaderPreview
        order={order}
        store={store}
        fulfillmentOrderNumber={fulfillmentOrder.number}
        isOca={isOca}
      />

      {settings.showProductsInfo && (
        <>
          <hr className="section-divider" />
          <ProductsDocumentPreview lineItems={fulfillmentOrder.lineItems} />
          <hr className="section-divider" />
          <TotalDocumentPreview fulfillmentOrder={fulfillmentOrder} />
          <hr className="section-divider" />
          <CheckoutDocumentPreview
            payment={order.payment}
            shipping={fulfillmentOrder.shipping}
            tracking={fulfillmentOrder.tracking}
          />
        </>
      )}

      {settings.showMerchantNote && !!order?.notes?.merchant && (
        <>
          <hr className="section-divider" />
          <div className="notes-section">
            <p>{t('notes.merchant')}</p>
            <p>{order.notes.merchant}</p>
          </div>
        </>
      )}

      {settings.showOrdersNote && !!order?.notes?.consumer && (
        <>
          <hr className="section-divider" />
          <div className="notes-section">
            <p>{t('notes.consumer')}</p>
            <p>{order.notes.consumer}</p>
          </div>
        </>
      )}

      {fulfillmentOrder.shipping?.type === SHIPPING_TYPE && (
        <>
          <hr className="section-divider" />
          <RecipientDocumentPreview
            recipient={fulfillmentOrder.recipient}
            destination={fulfillmentOrder.destination}
            showLabelRecipientDocument={settings.showLabelRecipientDocument}
          />
        </>
      )}

      {fulfillmentOrder.shipping?.type === PICKUP_TYPE && (
        <>
          <hr className="section-divider" />
          <PickupDocumentPreview
            shippingName={fulfillmentOrder.shipping.name}
            recipient={fulfillmentOrder.recipient}
            pickupDetails={fulfillmentOrder.shipping.pickupDetails}
            showLabelRecipientDocument={settings.showLabelRecipientDocument}
          />
        </>
      )}

      {settings.showSenderInfo && (
        <>
          <hr className="section-divider" />
          <SenderDocumentPreview
            storeName={store.name}
            address={store.address}
          />
        </>
      )}

      {fulfillmentOrder.shipping?.type === PICKUP_TYPE &&
        settings.showReceiptStub && (
          <>
            <hr className="section-divider" />
            <ReceiptStubDocumentPreview
              order={order}
              fulfillmentOrderNumber={fulfillmentOrder.number}
            />
          </>
        )}
    </>
  );
}

export default GeneralDocumentPreviewFields;
