import { useCallback, useMemo, useState, useEffect } from 'react';
import { Title, Thumbnail, Text } from '@nimbus-ds/components';
import { RelatedProductTypes } from '@tiendanube/common';
import {
  InteractiveListPriv,
  InterfaceNameChecked,
  Input,
  Chip,
} from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import {
  HeaderTop,
  ImageItemStratus,
  VirtualInteractiveListSkeleton,
  VirtualInteractiveList,
} from 'commons/components';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useSelectProductsList } from 'domains/Catalog/Products/components/SelectProductList/useSelectProductsList/useSelectProductsList';
import { ProductHiddenAndWithoutStock } from 'domains/Catalog/Products/pages/components';
import {
  RelatedProductInterface,
  RelatedProductType,
} from 'domains/Catalog/Products/pages/hooks/useProductForm';
import { trackCreateRelatedProductConfirm } from 'domains/Online/tracking';
import { OnChangeRelatedProduct } from '../../useRelatedProduct';
import RelatedProductEmptySearch from '../RelatedProductEmptySearch';

interface RelatedProductModalAsideProps {
  type?: RelatedProductTypes;
  onClose: () => void;
  selectedRelatedProducts: RelatedProductInterface;
  mainProduct: RelatedProductType;
  onChange: OnChangeRelatedProduct;
}

const MAX_RELATED_PRODUCTS = 8;

function RelatedProductModalAside({
  onClose,
  onChange,
  type,
  mainProduct,
  selectedRelatedProducts,
}: Readonly<RelatedProductModalAsideProps>) {
  const t = useTranslationCatalog();
  const [searchValue, setSearchValue] = useState('');

  const handleOnClearSelecteds = () => onChange([]);

  const {
    products,
    isLoading,
    isSuccess,
    fetchDraftOrderProducts,
    getMoreDraftOrdersProducts,
    isRefreshing,
  } = useSelectProductsList({
    sortBy: null,
  });

  const handleOnChange = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const handleOnSubmit = ({ value }) => {
    setSearchValue(value);
    fetchDraftOrderProducts(value);
  };

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      fetchDraftOrderProducts(searchValue);
    }, 300);
    return () => clearTimeout(debouncedSearch);
  }, [searchValue, fetchDraftOrderProducts]);

  const availableProducts = useMemo(
    () => products?.filter((prod) => prod.id !== mainProduct.id) || [],
    [products, mainProduct.id],
  );

  const relatedProducts = useMemo(() => {
    if (type === 'ALTERNATIVE') {
      return selectedRelatedProducts?.alternative || [];
    }
    return selectedRelatedProducts?.complementary || [];
  }, [selectedRelatedProducts, type]);

  const hasMoreThanTheMax = useMemo(
    () => relatedProducts.length >= MAX_RELATED_PRODUCTS,
    [relatedProducts],
  );

  const headerAction = {
    onClick: () => {
      trackCreateRelatedProductConfirm(type || 'COMPLEMENTARY');
      onClose();
    },
    children: t('products.detail.relatedProduct.modal.add'),
  };

  const headerNavigation = { onClick: onClose };

  const onReached = useCallback(() => {
    getMoreDraftOrdersProducts(searchValue);
  }, [getMoreDraftOrdersProducts, searchValue]);

  const typeText = useMemo(() => {
    if (type === 'ALTERNATIVE') {
      return t('products.detail.relatedProduct.modal.alternative');
    }
    return t('products.detail.relatedProduct.modal.complementary');
  }, [type, t]);

  return (
    <ModalAside
      isOpen
      hasVirtualizationContent
      onDidDismiss={onClose}
      headerTop={
        <HeaderTop navigation={headerNavigation} mainAction={headerAction} />
      }
      headerContent={
        <Stack column align="stretch">
          <Title>
            {t('products.detail.relatedProduct.modal.title', {
              type: typeText,
            })}
          </Title>
          <Text>{t('products.detail.relatedProduct.modal.subtitle')}</Text>
          <Stack>
            <Thumbnail
              src={mainProduct.imageUrl}
              alt={mainProduct.name}
              width="36px"
              height="36px"
            />
            <Text>{mainProduct.name}</Text>
          </Stack>
          <Stack.Item>
            <Input
              type="search"
              name="product"
              placeholder={t(
                'products.detail.relatedProduct.modal.searchPlaceholder',
              )}
              value={searchValue}
              onChange={handleOnChange}
              onSubmit={handleOnSubmit}
            />
          </Stack.Item>
          <Stack>
            <Text>
              {t('products.detail.relatedProduct.modal.countItems', {
                selected: relatedProducts.length,
                max: MAX_RELATED_PRODUCTS,
              })}
            </Text>
            {!!relatedProducts.length && (
              <Chip
                label={t('products.detail.relatedProduct.modal.selectedItems', {
                  selected: relatedProducts.length,
                })}
                id="selectedItems"
                onDismiss={handleOnClearSelecteds}
              />
            )}
          </Stack>
        </Stack>
      }
    >
      <Stack column align="stretch">
        <Stack.Item>
          {isSuccess && !availableProducts?.length && (
            <RelatedProductEmptySearch />
          )}

          {isLoading && <VirtualInteractiveListSkeleton />}

          {isSuccess && !!availableProducts?.length && (
            <VirtualInteractiveList
              totalCount={availableProducts.length}
              endReached={onReached}
              showFooter={isRefreshing}
              itemContent={(index) => {
                const product = availableProducts[index];
                const { id, name, imageUrl, visible, stock } = product;

                const isSelected = relatedProducts.some(
                  (prod) => prod.id === availableProducts[index].id,
                );

                return (
                  <InteractiveListPriv.CheckItem
                    key={index}
                    name={id}
                    onChange={(event: InterfaceNameChecked) => {
                      const { checked } = event;
                      if (checked) {
                        const newProducts = [
                          ...relatedProducts,
                          { id, name, imageUrl, visible },
                        ];
                        onChange(newProducts);
                      } else {
                        const newProducts = relatedProducts.filter(
                          (prod) => prod.id !== id,
                        );
                        onChange(newProducts);
                      }
                    }}
                    checked={isSelected}
                    disabled={hasMoreThanTheMax && !isSelected}
                    title=""
                    blockLeft={
                      <ImageItemStratus
                        id={id}
                        title={name}
                        imageUrl={imageUrl}
                        imageWidth="56px"
                        appearance="default"
                        bold={false}
                        trimTitle
                      >
                        <ProductHiddenAndWithoutStock
                          isVisible={visible}
                          hasStock={stock}
                          productId={id}
                        />
                      </ImageItemStratus>
                    }
                  />
                );
              }}
            />
          )}
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default RelatedProductModalAside;
