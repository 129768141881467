import { Alert, Text } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function UpdateDomainAlert() {
  const t = useTranslationConfigurations('domains.editModal.updateAlert');

  return (
    <Alert appearance="primary" title={t('title')}>
      <Text>{t('text')}</Text>
    </Alert>
  );
}
