import { Text } from '@nimbus-ds/components';
import { Menu } from '@nimbus-ds/patterns';
import { useTranslationAccount } from 'domains/Account/hooks';

export function SupportCardHeader() {
  const t = useTranslationAccount();

  return (
    <Menu.Header>
      <Text color="neutral-textHigh" fontWeight="bold" id="suport-card-header">
        {t('supportChanels.title')}
      </Text>
    </Menu.Header>
  );
}
