import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { EmptyState, Text } from '@tiendanube/components';
import emptyMessagesImage from './empty-messages.png';

function MessagesListEmpty(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <EmptyState
      image={emptyMessagesImage}
      title={t('messages.emptyResults.title')}
    >
      <Text>{t('messages.emptyResults.body')}</Text>
    </EmptyState>
  );
}

export default MessagesListEmpty;
