import { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Icon, Label, Link, Text } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import Domain from '@tiendanube/common/src/enums/Domain';
import { InterfaceNameValue, RadioButtonGroup } from '@tiendanube/components';
import { useBoolean } from 'commons/hooks';
import {
  getProductNameWithVariant,
  ProductInterface,
  ProductSearcher,
} from '../ProductSearcher';

interface ProductsFilterProperties {
  selectedProducts: ProductInterface<true>[];
  onProductsChange: ({
    name,
    value,
  }: {
    name: string;
    value: ProductInterface<true>[];
  }) => void;
}

const ALL_PRODUCTS = 'all';
const CUSTOM_PRODUCTS = 'custom';
const SELECTED_PRODUCTS_LIMIT = 10;

function ProductsFilter({
  selectedProducts,
  onProductsChange,
}: ProductsFilterProperties): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  const [isProductSearcherOpen, openProductSearcher, closeProductSearcher] =
    useBoolean(false);

  const resetSelectedOption = useCallback(() => {
    if (!selectedProducts.length) {
      setProductsFilterOptionSelected(ALL_PRODUCTS);
    }
  }, [selectedProducts]);

  const handleProductSearcherClose = useCallback(() => {
    closeProductSearcher();
    resetSelectedOption();
  }, [closeProductSearcher, resetSelectedOption]);

  const handleProductsChange = useCallback(
    (products: ProductInterface<true>[]) => {
      onProductsChange({ name: 'products', value: products });
      closeProductSearcher();
      if (!products.length) {
        resetSelectedOption();
      }
    },
    [onProductsChange, closeProductSearcher, resetSelectedOption],
  );

  const [productsFilterOptionSelected, setProductsFilterOptionSelected] =
    useState<string>(selectedProducts.length ? CUSTOM_PRODUCTS : ALL_PRODUCTS);

  const options = [
    {
      label: t('filter.productsFilter.allProducts'),
      value: ALL_PRODUCTS,
    },
    {
      label: t('filter.productsFilter.customProducts'),
      value: CUSTOM_PRODUCTS,
    },
  ];

  const handleChangeOption = (e: InterfaceNameValue) => {
    if (e.value === CUSTOM_PRODUCTS) {
      openProductSearcher();
    } else if (e.value === ALL_PRODUCTS) {
      onProductsChange({ name: 'products', value: [] });
    }
    setProductsFilterOptionSelected(e.value);
  };

  const handleRemoveProduct = useCallback(
    (variantId: string) => {
      handleProductsChange(
        selectedProducts.filter((product) => product.variantId !== variantId),
      );
    },
    [selectedProducts, handleProductsChange],
  );

  useEffect(() => {
    resetSelectedOption();
  }, [resetSelectedOption]);

  return (
    <Box borderStyle="none">
      <Box paddingBottom="2" display="flex" alignItems="flex-start" gap="1">
        <Label>{t('filter.productsFilter.title')}</Label>
      </Box>
      <Box>
        <RadioButtonGroup
          name="productsOptionFilter"
          options={options}
          onChange={handleChangeOption}
          value={productsFilterOptionSelected}
        />
      </Box>
      {!!selectedProducts.length && (
        <Box>
          <Box
            paddingTop="1"
            paddingBottom="2"
            display="flex"
            flex="1"
            flexDirection="row"
            flexWrap="wrap"
          >
            {selectedProducts.map((product) => (
              <Box
                key={product.variantId}
                paddingY="1"
                paddingLeft="none"
                paddingRight="2"
              >
                <Chip
                  text={getProductNameWithVariant(product)}
                  removable
                  onClick={() => handleRemoveProduct(product.variantId)}
                />
              </Box>
            ))}
          </Box>
          <Link textDecoration="none" onClick={openProductSearcher}>
            <Icon color="primary-interactive" source={<EditIcon />} />
            <Text color="primary-interactive" fontSize="base">
              {t('filter.productsFilter.editProducts')}
            </Text>
          </Link>
        </Box>
      )}
      <ProductSearcher<true>
        onClose={handleProductSearcherClose}
        onSubmit={handleProductsChange}
        isOpen={isProductSearcherOpen}
        preselectedProducts={selectedProducts}
        selectedProductsLimit={SELECTED_PRODUCTS_LIMIT}
        minimalistic
      />
    </Box>
  );
}

export default ProductsFilter;
