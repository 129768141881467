import { SalesChannelsAppResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { MarketplacesAppsTags, MarketplacesAppsEndpoints } from './types';
import { BASE_MARKETPLACES_APPS } from './urls';

export const marketplacesApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [MarketplacesAppsTags.marketplacesApps],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [MarketplacesAppsEndpoints.getMarketplacesApps]: builder.query<
        SalesChannelsAppResponseDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_MARKETPLACES_APPS,
        }),
      }),
    }),
  });

export const { useGetMarketplacesAppsQuery } = marketplacesApi;
