import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import objectToUrlParams from 'commons/utils/objectToUrlParams';
import {
  //getMessages,
  getMessagesIds,
  getMessagesList,
  getMessagesStatus,
  getMessagesRefreshStatus,
  getMoreMessagesList,
  getMessagesFilters,
  getMessagesPagination,
  changeFilters,
} from 'domains/Customers/Messages/messagesSlice';

function useMessages() {
  const dispatch = useAppDispatch();
  const { replace } = useNavegate();

  const { isError, isLoading, isSuccess } = useSelector(getMessagesStatus);

  const { isRefreshing } = useSelector(getMessagesRefreshStatus);

  const messagesIds = useSelector(getMessagesIds);

  const pagination = useSelector(getMessagesPagination);

  const filters = useSelector(getMessagesFilters);

  const fetchMessages = useCallback(() => {
    dispatch(getMessagesList(filters));
    replace(`/messages?${objectToUrlParams({ ...filters })}`);
  }, [dispatch, filters, replace]);

  const fetchMoreMessages = useCallback(
    () => dispatch(getMoreMessagesList()),
    [dispatch],
  );

  const goToPage = useCallback(
    async (page: number) => {
      await dispatch(getMessagesList({ ...filters, page: page.toString() }));
      dispatch(changeFilters({ ...filters, page: page.toString() }));
      replace(`/messages?${objectToUrlParams({ ...filters, page })}`);
    },
    [dispatch, filters, replace],
  );

  const isEmpty = messagesIds.length === 0 && !isLoading && !isError;

  return {
    messagesIds,
    isLoading,
    isError,
    isSuccess,
    isEmpty,
    isRefreshing,
    pagination,
    fetchMessages,
    fetchMoreMessages,
    goToPage,
  };
}

export default useMessages;
