import { StatePriv } from '@tiendanube/components';
import { InvoiceIcon } from '@tiendanube/icons';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function TransactionFeesEmpty(): JSX.Element {
  const t = useTranslationBilling();
  return (
    <StatePriv
      icon={InvoiceIcon}
      title={t('transactionFeesPage.emptyState.title')}
      text={t('transactionFeesPage.emptyState.description')}
    />
  );
}

export default TransactionFeesEmpty;
