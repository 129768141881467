import { Card } from '@nimbus-ds/components';
import { LanguagesType } from 'App/i18n';
import { Stack } from 'commons/components';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import FormNameAndDescription, {
  FormNameAndDescriptionProps,
} from './FormNameAndDescription';

function NameAndDescription({
  name,
  description,
  onChange,
  errors,
  autoFocus,
}: FormNameAndDescriptionProps): JSX.Element {
  const t = useTranslationCatalog();
  const missingLanguages = Object.keys(errors)
    .filter((err) => err.startsWith('name'))
    .map((err) => err.split('.')[1]);

  return (
    <Card>
      <Card.Header title={t('products.detail.nameAndDescription')} />
      <Card.Body>
        <Stack column spacing="loose" align="stretch">
          <Stack.Item fill>
            <TabsMultiLanguage
              initialLanguage={missingLanguages[0] as LanguagesType}
            >
              <FormNameAndDescription
                onChange={onChange}
                name={name}
                description={description}
                errors={errors}
                autoFocus={autoFocus}
              />
            </TabsMultiLanguage>
          </Stack.Item>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default NameAndDescription;
