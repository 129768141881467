import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './metafields.es-AR.json';
import esMX from './metafields.es-MX.json';
import ptBR from './metafields.pt-BR.json';

const metafieldsTranslations: DomainTranslations = {
  name: 'metafields',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default metafieldsTranslations;
