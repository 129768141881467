import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import useProductInventory from '../../../hooks/useProductInventory';

function useInventoryAndLocations() {
  const { productId, variantId } = useParams<{
    productId: string;
    variantId: string;
  }>();
  const { fetchProductInventory, isLoading: isLoadingProduct } =
    useProductInventory(productId);
  const {
    fetchLocations,
    locations,
    isLoading: isLoadingLocations,
  } = useLocations();

  const fetchInventoryByVariant = useCallback(() => {
    Promise.all([fetchProductInventory(), fetchLocations()]);
  }, [fetchProductInventory, fetchLocations]);

  const isLoading = isLoadingProduct || isLoadingLocations;

  const isError = isLoadingProduct || isLoadingLocations;

  return {
    isLoading,
    isError,
    locations,
    productId,
    variantId,
    fetchInventoryByVariant,
  };
}

export default useInventoryAndLocations;
