import { useRef } from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import classNames from 'classnames';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import DndProvider from 'App/components/DndProvider';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { TreeData } from 'domains/Catalog/Categories/pages/CategoriesListPage/hooks/useCategoriesTree/useCategoriesTreeNew';
import CustomNode from './CustomNode';
import '@nosferatu500/react-sortable-tree/style.css';
import './DraggableTreeNew.scss';

const MAX_DEPTH = 14;

interface DraggableTreeNewProps {
  data: TreeData[];
  renderItem: ((params: any) => any) | undefined;
  onChange: (newTree: TreeData[], draggingNode: TreeData) => void;
}

function DraggableTreeNew({
  data,
  renderItem,
  onChange,
}: Readonly<DraggableTreeNewProps>) {
  const isMobileDevice = useIsMobileDevice();
  const draggingNode = useRef<any>();

  const handeChange = (newTree: TreeData[]) => {
    onChange(newTree, draggingNode.current);
  };

  const containerClass = classNames('stratus--draggable-tree', {
    'stratus--draggable-tree__mobile': isMobileDevice,
  });

  return (
    <div className={containerClass}>
      <DndProvider>
        <SortableTree
          treeData={data}
          onChange={handeChange}
          theme={FileExplorerTheme}
          maxDepth={MAX_DEPTH}
          canDrag={({ node }) => !node.noDragging}
          canDrop={({ node, nextParent }) => {
            if (node) draggingNode.current = node;
            return !nextParent?.noChildren;
          }}
          nodeContentRenderer={(props) => <CustomNode {...props} />}
          generateNodeProps={renderItem}
          virtuosoProps={{
            useWindowScroll: true,
          }}
          overscan={{ main: 1000, reverse: 1000 }}
        />
      </DndProvider>
    </div>
  );
}

export default DraggableTreeNew;
