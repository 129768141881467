import { Icon, Link } from '@nimbus-ds/components';
import { PlusCircleIcon, EditIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';

interface SelectOrEditLinkProps {
  isEdit: boolean;
  onSelect: () => void;
  selectLinkText?: string;
  editLinkText?: string;
}

function SelectOrEditLink({
  isEdit,
  onSelect,
  selectLinkText,
  editLinkText,
}: SelectOrEditLinkProps): JSX.Element {
  const { t } = useTranslation('common', { keyPrefix: 'selectOrEditLink' });

  const selectText = selectLinkText ?? t('select');
  const editText = editLinkText ?? t('edit');
  const linkText = isEdit ? editText : selectText;
  const LinkIcon = isEdit ? EditIcon : PlusCircleIcon;
  return (
    <Link
      appearance="primary"
      as="button"
      onClick={onSelect}
      textDecoration="none"
    >
      <Icon color="primary-interactive" source={<LinkIcon />} />
      {linkText}
    </Link>
  );
}

export default SelectOrEditLink;
