import { Link } from '@tiendanube/components';
import { MailIcon } from '@tiendanube/icons';
import useEmailMessageLink, {
  useEmailMessageLinkProps,
} from 'commons/hooks/useEmailMessageLink';

interface SendEmailLinkProps extends useEmailMessageLinkProps {
  textLink?: string;
}

function SendEmailLink({
  email,
  subject,
  body,
  url,
  signature,
  textLink,
}: SendEmailLinkProps): JSX.Element {
  const getUrl = useEmailMessageLink();
  return (
    <Link
      appearance="primary"
      icon={MailIcon}
      href={getUrl({
        email,
        subject,
        body,
        url,
        signature,
      })}
    >
      {textLink ? textLink : email}
    </Link>
  );
}

export default SendEmailLink;
