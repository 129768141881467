import { useSelector } from 'react-redux';
import { LocationResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import {
  getLocationById,
  getLocationsStatus,
} from '../../locationSlice/locationSelectors';

export interface UseLocationByIdResult {
  isLoading: boolean;
  location: LocationResponseDto;
}

function useLocationById(id: string): UseLocationByIdResult {
  const { isLoading } = useSelector(getLocationsStatus);
  const location = useSelector((state: RootStateType) =>
    getLocationById(state, id),
  );

  return { isLoading, location };
}

export default useLocationById;
