import { useEffect, useState } from 'react';
import { InterfaceIdLabel } from '@tiendanube/components';
import { SearchFilter } from 'commons/components';
import { useResponsive } from 'commons/components/Responsive';
import { useModal } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  InventoryFiltersInterface,
  InventoryFiltersType,
} from 'domains/Catalog/Inventory/inventoryServices';
import FilterModal from './FilterModal';
import { useFilterLabel } from '../../../hooks';

interface InventoryFilterProps {
  filters: InventoryFiltersInterface;
  productsCount: number;
  isLoading: boolean;
  onChange: (filters: InventoryFiltersInterface) => void;
}

function InventorySearch({
  filters,
  productsCount,
  isLoading,
  onChange,
}: InventoryFilterProps): JSX.Element | null {
  const t = useTranslationCatalog();
  const { isDesktop } = useResponsive();
  const [showFilterModal, openFilterModal, closeFilterModal] = useModal();
  const getFilterLabel = useFilterLabel(filters);
  const [query, setQuery] = useState(filters.q || '');

  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  useEffect(() => {
    setQuery(filters.q ?? '');
  }, [filters.q]);

  const handleChangeText = (text: string) => {
    onChange({ ...filters, q: text });
  };

  const handleDismiss = (key: string) => {
    if (key === 'stock') {
      return onChange({
        ...filters,
        stock: '',
        maxStock: '',
        minStock: '',
      });
    }
    onChange({ ...filters, [key]: '' });
  };

  const handleChangeFilters = (newFilters: InventoryFiltersType) => {
    onChange({ ...filters, ...newFilters });
  };

  const onlyFiltersToLabel = ([filter, value]) => {
    if (value === '') {
      return false;
    }
    return !['page', 'locationId', 'maxStock', 'minStock'].includes(filter);
  };

  const optionsLabels: InterfaceIdLabel[] = Object.entries(filters)
    .filter(onlyFiltersToLabel)
    .map(getFilterLabel);

  const hasAppliedFilters = optionsLabels.length > 0;

  const resultCount = isLoading
    ? ''
    : hasAppliedFilters
    ? t('products.filters.filterResult', { count: productsCount })
    : t('products.filters.result', { count: productsCount });

  const label = isDesktop ? t('products.filter.filter') : '';

  return (
    <>
      <SearchFilter
        label={label}
        placeholder={t('products.filter.placeHolder')}
        resultCount={resultCount}
        appliedFilters={optionsLabels}
        isLoading={isLoading}
        onSubmit={handleChangeText}
        onDismiss={handleDismiss}
        onClick={openFilterModal}
        ariaLabel={t('products.filter.ariaLabelButtonFilter')}
        value={query}
        onChange={handleChangeQuery}
      />
      {showFilterModal && (
        <FilterModal
          show
          hideModal={closeFilterModal}
          filter={filters}
          addParam={handleChangeFilters}
        />
      )}
    </>
  );
}

export default InventorySearch;
