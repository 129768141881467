import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PaymentProviderDto } from '@tiendanube/common';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { InterfacePaymentProvidersSchema } from './types';
import paymentProvidersService from '../paymentProvidersService';

const initialState: InterfacePaymentProvidersSchema = {
  recentlyEnabled: {
    id: null,
  },
  recentlyDisabled: {
    id: null,
  },
  installed: {
    list: {
      data: null,
      status: 'idle',
    },
  },
  updateProvider: {
    status: 'idle',
  },
  checkoutPaymentOptions: {
    detail: {
      data: null,
      status: 'idle',
    },
    update: {
      status: 'idle',
    },
  },
  notInstalled: {
    list: {
      data: null,
      status: 'idle',
    },
  },
  nativeProviders: {
    statusUpdateInstallments: 'idle',
    statusMercadoPagoAuthorization: 'idle',
  },
};

export const getInstalledPaymentProvidersAction = createAsyncThunk(
  'paymentProviders/getInstalledPaymentProvidersAction',
  paymentProvidersService.getInstalledPaymentProviders,
);

export const enablePaymentProviderAction = createAsyncThunk(
  'paymentProviders/enablePaymentProviderAction',
  paymentProvidersService.enableInstalledPaymentProvider,
);

export const installPaymentProviderAction = createAsyncThunk(
  'paymentProviders/installNuvemPagoAction',
  paymentProvidersService.installPaymentProvider,
);

export const disablePaymentProviderAction = createAsyncThunk(
  'paymentProviders/disablePaymentProviderAction',
  paymentProvidersService.disableInstalledPaymentProvider,
);

export const getCheckoutPaymentOptionsAction = createAsyncThunk(
  'paymentProviders/getCheckoutPaymentOptionsAction',
  paymentProvidersService.getCheckoutPaymentOptions,
);

export const getSettingOptionsAction = createAsyncThunk(
  'paymentProviders/getSettingOptionsAction',
  paymentProvidersService.getSettingOptions,
);

export const updatePaymentProviderSettingsAction = createAsyncThunk(
  'paymentProviders/updatePaymentProviderSettingsAction',
  paymentProvidersService.updatePaymentProviderSettings,
);

export const updateCheckoutPaymentOptionsAction = createAsyncThunk(
  'paymentProviders/updateCheckoutPaymentOptionsAction',
  paymentProvidersService.updateCheckoutPaymentOptions,
);

export const getNotInstalledPaymentProvidersAction = createAsyncThunk(
  'paymentProviders/getNotInstalledPaymentProvidersAction',
  paymentProvidersService.getNotInstalledPaymentProviders,
);

export const getMercadoPagoAuthorizationAction = createAsyncThunk(
  'paymentProviders/getMercadoPagoAuthorizationAction',
  paymentProvidersService.getMercadoPagoAuthorization,
);

export const updateMercadoPagoInstallmentsAction = createAsyncThunk(
  'paymentProviders/updateMercadoPagoInstallmentsAction',
  paymentProvidersService.updateMercadoPagoInstallments,
);

const paymentProvidersSlice = createSlice({
  name: 'paymentProviders',
  initialState,
  reducers: {
    cleanCheckoutPaymentOptionsStatusAction(state) {
      state.checkoutPaymentOptions.update.status = 'idle';
    },
    cleanUpdateStatusAction(state) {
      state.updateProvider.status = 'idle';
    },
    cleanPaymentProvidersAction(state) {
      state.installed = initialState.installed;
      state.notInstalled = initialState.notInstalled;
    },
    cleanRecentlyEnabledAction(state) {
      state.recentlyEnabled = initialState.recentlyEnabled;
    },
    cleanRecentlyDisabledAction(state) {
      state.recentlyDisabled = initialState.recentlyDisabled;
    },
    removePaymentProverFromInstalledListAction(
      state,
      action: PayloadAction<string>,
    ) {
      const newInstalledProvers =
        state.installed.list.data?.filter(
          ({ appId }) => appId !== action.payload,
        ) || [];
      state.installed.list.data = newInstalledProvers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstalledPaymentProvidersAction.pending, (state) => {
        state.installed.list.status = 'loading';
      })
      .addCase(
        getInstalledPaymentProvidersAction.fulfilled,
        (state, action) => {
          state.installed.list.status = 'success';
          state.installed.list.data = action.payload.reduce((acc, provider) => {
            if (muvemPagoOrPagoNubeIds.includes(provider.id)) {
              acc.unshift(provider);
            } else if (provider.hasEnterprisePlanRestriction) {
              if (provider.isInstallable) {
                acc.push(provider);
              }
            } else {
              acc.push(provider);
            }
            return acc;
          }, [] as PaymentProviderDto[]);
        },
      )
      .addCase(getInstalledPaymentProvidersAction.rejected, (state) => {
        state.installed.list.status = 'error';
      });

    builder
      .addCase(installPaymentProviderAction.pending, (state) => {
        state.updateProvider.status = 'loading';
      })
      .addCase(installPaymentProviderAction.fulfilled, (state, action) => {
        state.recentlyEnabled.id = action.meta.arg.providerId;
        state.updateProvider.status = 'success';
        state.installed.list.data = null;
        state.notInstalled.list.data = null;
      })
      .addCase(installPaymentProviderAction.rejected, (state) => {
        state.updateProvider.status = 'error';
      });

    builder
      .addCase(enablePaymentProviderAction.pending, (state) => {
        state.updateProvider.status = 'loading';
      })
      .addCase(enablePaymentProviderAction.fulfilled, (state, action) => {
        state.recentlyEnabled.id = action.meta.arg.providerAppId;
        state.updateProvider.status = 'success';
        state.installed.list.data = null;
        state.notInstalled.list.data = null;
        state.recentlyDisabled.id = null;
      })
      .addCase(enablePaymentProviderAction.rejected, (state) => {
        state.updateProvider.status = 'error';
      });

    builder
      .addCase(disablePaymentProviderAction.pending, (state) => {
        state.updateProvider.status = 'loading';
      })
      .addCase(disablePaymentProviderAction.fulfilled, (state, action) => {
        state.recentlyDisabled.id = action.meta.arg;
        state.updateProvider.status = 'success';
        state.installed.list.data = null;
        state.notInstalled.list.data = null;
        state.recentlyEnabled.id = null;
      })
      .addCase(disablePaymentProviderAction.rejected, (state) => {
        state.updateProvider.status = 'error';
      });

    builder
      .addCase(getCheckoutPaymentOptionsAction.pending, (state) => {
        state.checkoutPaymentOptions.detail.status = 'loading';
      })
      .addCase(getCheckoutPaymentOptionsAction.fulfilled, (state, action) => {
        state.checkoutPaymentOptions.detail.status = 'success';
        state.checkoutPaymentOptions.detail.data = action.payload;
      })
      .addCase(getCheckoutPaymentOptionsAction.rejected, (state) => {
        state.checkoutPaymentOptions.detail.status = 'error';
      });

    builder
      .addCase(updatePaymentProviderSettingsAction.pending, (state) => {
        state.updateProvider.status = 'loading';
      })
      .addCase(updatePaymentProviderSettingsAction.fulfilled, (state) => {
        state.updateProvider.status = 'success';
        state.installed.list.data = null;
        state.notInstalled.list.data = null;
      })
      .addCase(updatePaymentProviderSettingsAction.rejected, (state) => {
        state.updateProvider.status = 'error';
      });

    builder
      .addCase(updateCheckoutPaymentOptionsAction.pending, (state) => {
        state.checkoutPaymentOptions.update.status = 'loading';
      })
      .addCase(
        updateCheckoutPaymentOptionsAction.fulfilled,
        (state, action) => {
          state.checkoutPaymentOptions.update.status = 'success';
          state.checkoutPaymentOptions.detail.data = action.payload;
        },
      )
      .addCase(updateCheckoutPaymentOptionsAction.rejected, (state) => {
        state.checkoutPaymentOptions.update.status = 'error';
      });

    builder
      .addCase(getNotInstalledPaymentProvidersAction.pending, (state) => {
        state.notInstalled.list.status = 'loading';
      })
      .addCase(
        getNotInstalledPaymentProvidersAction.fulfilled,
        (state, action) => {
          state.notInstalled.list.status = 'success';
          state.notInstalled.list.data = action.payload;
        },
      )
      .addCase(getNotInstalledPaymentProvidersAction.rejected, (state) => {
        state.notInstalled.list.status = 'error';
      });

    builder
      .addCase(getMercadoPagoAuthorizationAction.pending, (state) => {
        state.nativeProviders.statusMercadoPagoAuthorization = 'loading';
      })
      .addCase(getMercadoPagoAuthorizationAction.fulfilled, (state) => {
        state.nativeProviders.statusMercadoPagoAuthorization = 'success';
      })
      .addCase(getMercadoPagoAuthorizationAction.rejected, (state) => {
        state.nativeProviders.statusMercadoPagoAuthorization = 'error';
      });

    builder
      .addCase(updateMercadoPagoInstallmentsAction.pending, (state) => {
        state.nativeProviders.statusUpdateInstallments = 'loading';
      })
      .addCase(updateMercadoPagoInstallmentsAction.fulfilled, (state) => {
        state.nativeProviders.statusUpdateInstallments = 'success';
      })
      .addCase(updateMercadoPagoInstallmentsAction.rejected, (state) => {
        state.nativeProviders.statusUpdateInstallments = 'error';
      });
  },
});

export const { reducer } = paymentProvidersSlice;

export const {
  removePaymentProverFromInstalledListAction,
  cleanCheckoutPaymentOptionsStatusAction,
  cleanUpdateStatusAction,
  cleanPaymentProvidersAction,
  cleanRecentlyEnabledAction,
  cleanRecentlyDisabledAction,
} = paymentProvidersSlice.actions;
