import { Alert, Card, Text } from '@nimbus-ds/components';
import { Prompt, useParams } from 'react-router';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PHISHING_SUSPECT_TAG } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { useHasTags, useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
  Stack,
  TabsMultiLanguage,
  useResponsive,
} from 'commons/components';
import { HELP_LINKS_NOTIFICATIONS } from 'commons/constants';
import { useForm, useToastStatus, useTranslationLanguage } from 'commons/hooks';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  EditNotificationPageError,
  EditNotificationPageSkeleton,
  PhishingSuspectAlert,
  VariablesInfoCard,
} from './components';
import { EmailValues } from './components/EmailValues';
import { NOTIFICATION_DETAILS_INITIAL_VALUES } from './constants';
import { validationSchema } from './validationSchema';
import {
  useGetEmailQuery,
  useUpdateEmailMutation,
} from '../../notificationsApi';

function EditNotificationPage() {
  const t = useTranslationConfigurations('notifications.editNotificationPage');
  const language = useTranslationLanguage();
  const languages = useGetAllLanguages();
  const { id } = useParams<{ id: string }>();
  const { goBack } = useNavegate();
  const { isMobile } = useResponsive();
  const { scrollToTop } = useLayoutScroll();
  const [isPhishingSuspect] = useHasTags(PHISHING_SUSPECT_TAG);

  const { data: email, isLoading, isError, refetch } = useGetEmailQuery(id);
  const [updateEmail, { status }] = useUpdateEmailMutation();
  const { values, isDirty, setFieldValue, handleOnSubmit, errors } = useForm({
    validationSchema: validationSchema(languages),
    initialValues: email || NOTIFICATION_DETAILS_INITIAL_VALUES,
    onSubmit: (data) =>
      updateEmail({
        id,
        payload: {
          data,
          initialValues: email || NOTIFICATION_DETAILS_INITIAL_VALUES,
        },
      }),
  });

  const updateStatus = toStatusType(status);

  useToastStatus({
    status: updateStatus,
    success: t('toast.success'),
    error: t('toast.error'),
    onSuccess: goBack,
    onError: scrollToTop,
  });

  const hasError = languages.some((lang) => !!errors[`${lang}.templateHtml`]);
  const saveEmailAction = {
    appearance: 'primary' as const,
    onClick: handleOnSubmit,
    children: t('save'),
    disabled: !isDirty,
  };

  return (
    <IonPageStratus
      width="large"
      headerTop={
        <HeaderTop
          navigation={{ onClick: goBack }}
          mainAction={saveEmailAction}
        />
      }
      headerContent={
        <HeaderContent
          title={!isLoading ? email?.title : 'skeleton'}
          subtitle={!isLoading ? email?.description : 'skeleton'}
        />
      }
    >
      <Prompt
        when={updateStatus === 'idle' && isDirty}
        message={t('exitPrompt')}
      />
      {isLoading && <EditNotificationPageSkeleton />}
      {isError && <EditNotificationPageError refetch={refetch} />}
      {!isLoading && !isError && email && (
        <>
          <Alert show={hasError} appearance="danger">
            <Text>{t('formCard.templateHtml.errorValidation')}</Text>
          </Alert>
          <PhishingSuspectAlert
            isPhishingSuspect={isPhishingSuspect}
            isError={updateStatus === 'error'}
          />
          <Layout
            mainContent
            left={
              <Stack column align="flex-end">
                <Card>
                  <Card.Header>
                    <Text fontSize="highlight" fontWeight="bold">
                      {t('formCard.title')}
                    </Text>
                  </Card.Header>
                  <Card.Body>
                    <TabsMultiLanguage>
                      <EmailValues
                        values={values}
                        initialValues={email}
                        isPhishingSuspect={isPhishingSuspect}
                        setFieldValue={setFieldValue}
                      />
                    </TabsMultiLanguage>
                  </Card.Body>
                </Card>
                {!isMobile && (
                  <CancelAndSaveButtons
                    onCancel={goBack}
                    onSave={handleOnSubmit}
                    isDisabled={!isDirty}
                    isLoading={updateStatus === 'loading'}
                  />
                )}
              </Stack>
            }
            right={<VariablesInfoCard emailVariables={email.variables} />}
          />
          {isMobile && (
            <CancelAndSaveButtons
              onCancel={goBack}
              onSave={handleOnSubmit}
              isDisabled={!isDirty || isPhishingSuspect}
              isLoading={updateStatus === 'loading'}
            />
          )}
        </>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_NOTIFICATIONS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default EditNotificationPage;
