import { Table } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { ErrorScreen, PaginationStratus, Stack } from 'commons/components';
import {
  UserSessionsListDesktopHeader,
  UserSessionsListDesktopRow,
  UserSessionsListDesktopSkeleton,
} from './components';
import { useTranslationAccount } from '../../../../hooks';
import {
  useGetTranslationKeyByUser,
  useGetUserSessionsListDesktop,
} from '../../hooks';

export function UserSessionsListDesktop() {
  const t = useTranslationAccount('sessionManagementPage');
  const {
    pagination,
    changePage,
    showSkeleton,
    data: userSessions,
    isError,
    refetch,
  } = useGetUserSessionsListDesktop();
  const translationKeyByUser = useGetTranslationKeyByUser();

  if (showSkeleton) {
    return <UserSessionsListDesktopSkeleton />;
  }

  if (isError) {
    return (
      <ErrorScreen
        onRetry={refetch}
        description={t('errors.sessionsLoading')}
      />
    );
  }

  if (userSessions.length === 0) {
    return (
      <EmptyMessage
        title={t('emptyResult.title')}
        text={t(`emptyResult.text.${translationKeyByUser}`)}
        icon={<SearchIcon size={30} />}
      />
    );
  }

  return (
    <Stack spacing="tight" align="stretch" column>
      <Table>
        <UserSessionsListDesktopHeader />
        <Table.Body>
          {userSessions.map((userSession) => (
            <UserSessionsListDesktopRow
              key={`user-sessions-list-item-${userSession.id}`}
              userSession={userSession}
            />
          ))}
        </Table.Body>
      </Table>

      <PaginationStratus
        currentPage={pagination.currentPage}
        totalItems={pagination.totalResults}
        itemName={t('paginationItemName')}
        page={pagination.currentPage}
        perPage={pagination.perPage}
        onPageSelect={changePage}
      />
    </Stack>
  );
}
