import { Input, Stack } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton() {
  const t = useTranslationShipping();

  return (
    <CardWithTitle title={t('correios.contract.title')}>
      <Stack column align="stretch">
        <Input.Skeleton />
        <Input.Skeleton />
        <Stack column align="stretch" spacing="tight">
          <Input.Skeleton />
        </Stack>
      </Stack>
    </CardWithTitle>
  );
}

export default Skeleton;
