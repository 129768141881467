import { Box } from '@nimbus-ds/components';
import { STYLE_STATS_DAILY_TAB_CONTENT } from './StatsDailyTabContent';
import StatsDailyValueCard from './StatsDailyValueCard';

function Skeleton() {
  return (
    <Box {...STYLE_STATS_DAILY_TAB_CONTENT}>
      <StatsDailyValueCard.Skeleton />
      <StatsDailyValueCard.Skeleton />
      <StatsDailyValueCard.Skeleton />
      <StatsDailyValueCard.Skeleton />
    </Box>
  );
}

export default Skeleton;
