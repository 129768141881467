import { useEffect } from 'react';
import { DataTable } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus, Stack, useResponsive } from 'commons/components';
import { useDraftOrders } from 'domains/Orders/DraftOrders/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  DraftOrderListDesktopSkeleton,
  DraftOrderListHeaderDesktop,
  DraftOrderListRowDesktop,
} from './components';

function DraftOrdersListDesktop(): JSX.Element {
  const t = useTranslationOrders();
  const { scrollToTop } = useLayoutScroll();
  const { isDesktop } = useResponsive();

  const { draftOdersList, pagination, isLoading, isEmpty, isError, goToPage } =
    useDraftOrders();

  useEffect(() => {
    if (isDesktop && pagination.currentPage > 1) {
      goToPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  const isShowTable = !isLoading && !isEmpty && !isError;

  return (
    <Stack column align="stretch">
      <div className="stratus--flashing-table-color">
        {isLoading && !isEmpty && !isError && <DraftOrderListDesktopSkeleton />}
        {isShowTable && (
          <DataTable>
            <DraftOrderListHeaderDesktop />
            {draftOdersList.map((id) => (
              <DraftOrderListRowDesktop key={id} id={id} />
            ))}
          </DataTable>
        )}
      </div>
      {isShowTable && (
        <Stack>
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults || 0}
            itemName={t('draftOrders.list.pagination')}
            page={pagination.currentPage}
            perPage={pagination.perPage}
            onPageSelect={handleSelectPage}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default DraftOrdersListDesktop;
