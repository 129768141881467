import { Box, IconButton, Popover, Tooltip } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';
import MenuButton, { MenuButtonProps } from '@nimbus-ds/menubutton';
import { useBoolean } from 'commons/hooks';

import './PopoverMenu.scss';

interface PopoverMenuProps {
  actions: (MenuButtonProps & { tooltip?: string; topSeparator?: boolean })[];
}

function PopoverMenu({ actions }: PopoverMenuProps): JSX.Element {
  const [popover, showPopover, hidePopover] = useBoolean(false);
  return (
    <Popover
      content={
        <Box alignItems="flex-start" flexGrow="1">
          {actions.map((action) => {
            const {
              onClick,
              tooltip = false,
              topSeparator = false,
              label,
              ...rest
            } = action;

            const handleClick = () => {
              hidePopover();
              onClick?.();
            };

            const renderMenuButton = (
              <div
                className={
                  topSeparator ? 'stratus--popover-menu-separator' : ''
                }
              >
                <MenuButton onClick={handleClick} label={label} {...rest} />
              </div>
            );

            return tooltip ? (
              <Tooltip content={tooltip} key={label} arrow>
                {renderMenuButton}
              </Tooltip>
            ) : (
              renderMenuButton
            );
          })}
        </Box>
      }
      visible={popover}
      onVisibility={(current) => (current ? showPopover() : hidePopover())}
      position="bottom-end"
      arrow={false}
      padding="small"
    >
      <IconButton size="2rem" source={<EllipsisIcon />} />
    </Popover>
  );
}

export default PopoverMenu;
