/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode } from 'react';

// TODO: Migrate to new common component 🔴

/**
 * Properties for the RenderByStatus component.
 *
 * @prop success: ReactNode element displayed when the state is successful.
 * @prop loading: (Optional)ReactNode element displayed during loading.
 * @prop error: (Optional) ReactNode element displayed when there is an error.
 * @prop isError: (Optional) Boolean indicating if the state is an error.
 * @prop isLoading: (Optional) Boolean indicating if the state is loading.
 */
interface RenderByStatusProps {
  success: ReactNode;
  loading?: ReactNode;
  error?: ReactNode;
  empty?: ReactNode;
  isError?: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
}

export function RenderByStatus({
  success,
  error,
  loading,
  empty,
  isError = false,
  isLoading = false,
  isEmpty = false,
}: RenderByStatusProps) {
  if (isError && error) return <>{error}</>;
  if (isLoading && loading) return <>{loading}</>;
  if (isEmpty && empty) return <>{empty}</>;
  return <>{success}</>;
}
