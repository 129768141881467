import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { MetafieldSelectedInterface } from 'domains/Metafields/types';
import {
  getOrdersBulkAssignmentMetafieldStatus,
  ordersBulkAssignmentMetafield,
} from 'domains/Orders/OrderMetafields';

export interface BulkMetafieldsAssignmentProps {
  selectedOrderIds: number[];
  selectedMetafields: MetafieldSelectedInterface[];
}

interface BulkMetafieldsAssignmentResult {
  handleBulkMetafieldsAssignment: (
    bulkData: BulkMetafieldsAssignmentProps,
  ) => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useBulkMetafieldsAssignment(): BulkMetafieldsAssignmentResult {
  const dispatch = useAppDispatch();
  const status = useSelector(getOrdersBulkAssignmentMetafieldStatus);

  const handleBulkMetafieldsAssignment = useCallback(
    async ({
      selectedOrderIds,
      selectedMetafields,
    }: BulkMetafieldsAssignmentProps) => {
      dispatch(
        ordersBulkAssignmentMetafield({
          orders: selectedOrderIds,
          metafields: selectedMetafields,
        }),
      );
    },
    [dispatch],
  );

  return {
    handleBulkMetafieldsAssignment,
    ...convertStatusTypeToObject(status),
  };
}

export default useBulkMetafieldsAssignment;
