import { Stack, Text, Link } from '@tiendanube/components';
import { ExclamationTriangleIcon, RedoIcon } from '@tiendanube/icons';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

interface GenericErrorProps {
  onRetry: () => void;
  text?: string;
}

function GenericError({ onRetry, text }: GenericErrorProps) {
  const t = useTranslationStatistics();
  return (
    <Stack column align="center" justify="center">
      <Stack.Item>
        <ExclamationTriangleIcon size="medium" />
      </Stack.Item>
      <Stack.Item>
        <Text textAlign="center">
          {text || t('statistics.genericError.description')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Link
          icon={RedoIcon}
          iconPosition="end"
          appearance="primary"
          onClick={onRetry}
        >
          {t('statistics.genericError.link')}
        </Link>
      </Stack.Item>
    </Stack>
  );
}

export default GenericError;
