import { useState } from 'react';
import { ResultPaginationResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { emptyPagination } from '../useListDesktop/constants';
import {
  UseGenericDataListProps,
  UseQueryResult,
} from '../useListDesktop/types';

function useListMobile<T>({ endpoint, filters }: UseGenericDataListProps) {
  const [virtualPage, setVirtualPage] = useState(1);

  const dataTableResources = cirrusApi.endpoints[endpoint].useQuery({
    ...filters,
    page: virtualPage,
  }) as UseQueryResult<ResultPaginationResponseDto<Array<T>>>;

  const { refetch, data, isFetching, isLoading, isSuccess } =
    dataTableResources;

  const handlePullToRefresh = async () => {
    setVirtualPage(1);
    setTimeout(refetch);
  };

  const handleInfiniteScroll = () => {
    if (data?.pagination?.nextPage && !isFetching) {
      setVirtualPage((virtualPage) => virtualPage + 1);
    }
  };

  const dataResult = data?.results || ([] as Array<T>);
  return {
    ...dataTableResources,
    data: dataResult,
    pagination: data?.pagination || emptyPagination,
    handleInfiniteScroll,
    handlePullToRefresh,
    showSpinner: isFetching && virtualPage !== 1,
    showSkeleton: (isFetching || isLoading) && virtualPage === 1,
    isDataEmpty: isSuccess && dataResult.length === 0,
  };
}

export default useListMobile;
