import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanRecentlyEnabledAction,
  getRecentlyEnabledPaymentProvider,
} from '../../paymentProvidersSlice';

function useRecentlyEnbaled() {
  const dispatch = useAppDispatch();

  const recentlyEnabledPaymentProvider = useSelector(
    getRecentlyEnabledPaymentProvider,
  );

  const cleanRecentlyEnabled = useCallback(() => {
    dispatch(cleanRecentlyEnabledAction());
  }, [dispatch]);

  return {
    recentlyEnabledPaymentProviderId: recentlyEnabledPaymentProvider.id,
    cleanRecentlyEnabled,
  };
}

export default useRecentlyEnbaled;
