import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceSelectOption, Select } from '@tiendanube/components';
import NinetyDaysAlert from './NinetyDaysAlert';

interface OrderPaymentProvidersFilterProps {
  paymentProviders: string;
  paymentProviderOptions: InterfaceSelectOption[];
  handleChange: (event) => void;
}

function OrderPaymentProvidersFilter({
  paymentProviders,
  paymentProviderOptions,
  handleChange,
}: OrderPaymentProvidersFilterProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  return (
    <Box>
      <Select
        label={t('filter.paymentProvider')}
        name="paymentProvider"
        value={paymentProviders}
        options={paymentProviderOptions}
        onChange={handleChange}
      />
      {paymentProviders !== '' && <NinetyDaysAlert />}
    </Box>
  );
}

export default OrderPaymentProvidersFilter;
