import { Text } from '@tiendanube/components';
import { Layout, useResponsive } from 'commons/components';
import {
  CustomerDataCardSkeleton,
  DetailsHeaderSkeleton,
  OrderSummaryCardSkeleton,
} from 'domains/Orders/components';
import { BillingAddressCardSkeleton } from '../BillingAddressCard';
import { CardPaymentSkeleton } from '../CardPayment/CardPaymentSkeleton';
import { DeliveryInfoCardSkeleton } from '../DeliveryInfoCard';
import { SendEmailAbandonedCartCardSkeleton } from '../SendEmailAbandonedCartCard';
import { ShareAbandonedCartCardSkeleton } from '../ShareAbandonedCartCard';

function AbandonedCartDetailsSkeleton() {
  const { isDesktop } = useResponsive();

  return (
    <Layout
      mainContent
      right={
        <>
          {isDesktop && <Text.Skeleton />}
          <CustomerDataCardSkeleton />
          <DeliveryInfoCardSkeleton />
          <BillingAddressCardSkeleton />
        </>
      }
      left={
        <>
          <DetailsHeaderSkeleton isOrder={false} />
          <ShareAbandonedCartCardSkeleton />
          <SendEmailAbandonedCartCardSkeleton />
          <CardPaymentSkeleton />
          <OrderSummaryCardSkeleton />
        </>
      }
    />
  );
}

export default AbandonedCartDetailsSkeleton;
