export const BASE_URL = import.meta.env.VITE_BASE_URL;

export const NUMBER_OF_DIGITOS_CPF = 11;
export const NUMBER_OF_DIGITOS_CNPJ = 14;
export const DIGITS_LIMITS_CPF_CNPJ = 18;
export const formatCpfCnpj = (value: string) => {
  let formattedValue = value;

  if (value.length === NUMBER_OF_DIGITOS_CPF) {
    // Formatted as CPF (XXX.XXX.XXX-XX)
    formattedValue = value.replace(
      /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4',
    );
  } else if (value.length === NUMBER_OF_DIGITOS_CNPJ) {
    // Formatted as CNPJ (XX.XXX.XXX/XXXX-XX)
    formattedValue = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5',
    );
  }

  return formattedValue;
};
