import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Locale } from './enums';
import { languageDetector } from './languageDetector';
import resources from './resources';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

function i18nInit(): void {
  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      resources,
      ns: 'common',
      defaultNS: 'common',
      load: 'currentOnly',
      returnNull: false,
      compatibilityJSON: 'v3',
      interpolation: {
        skipOnVariables: false,
      },
      fallbackLng: (code) => {
        if (code.startsWith('es')) {
          return Locale.MEXICO;
        } else {
          return Locale.BRAZIL;
        }
      },
    });

  i18n.services.pluralResolver.addRule(Locale.BRAZIL, {
    numbers: [1, 2],
    plurals: (n: number) => Number(n !== 1),
  });
}

export default i18nInit;
