import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthenticationFactorResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  fetchAuthenticationFactors as fetchAuthenticationFactorsAction,
  authenticationFactorsStatus,
  authenticationFactorsData,
} from '../../accountSlice';

interface UseAuthenticationFactorsResult {
  fetchAuthenticationFactors: () => void;
  status: StatusType;
  authenticationFactors: AuthenticationFactorResponseDto[];
}

function useAuthenticationFactors(): UseAuthenticationFactorsResult {
  const { status } = useSelector(authenticationFactorsStatus);
  const { data: authenticationFactors } = useSelector(
    authenticationFactorsData,
  );
  const dispatch = useAppDispatch();
  const fetchAuthenticationFactors = useCallback(() => {
    dispatch(fetchAuthenticationFactorsAction());
  }, [dispatch]);

  return {
    fetchAuthenticationFactors,
    status,
    authenticationFactors,
  };
}

export default useAuthenticationFactors;
