import React from 'react';
import { useSelector } from 'react-redux';
import { Country, Action } from '@tiendanube/common/src/enums';
import { InterfaceBulkAction, useBulkAction } from '@tiendanube/components';
import { FEATURE_BULK_ACTIONS } from 'App/features';
import { ORDERS_BULK_ACTIONS } from 'config/upsellFlowSources';
import {
  useGetCountry,
  useHasPermission,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import useBulkActions from 'domains/Catalog/Products/pages/ProductListPage/components/BulkActionsProvider/useBulkActions';
import {
  trackingOrderBulkActions,
  trackingOrderBulkMetafields,
  trackingOrderBulkNuvemEnvio,
} from 'domains/Metafields/components/ModalOrdersAssignMetafields/tracking';
import { useGetMetafieldsOrders } from 'domains/Metafields/hooks';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import { getFulfillmentOrders } from '../../ordersSlice';
import {
  bulkactions,
  optionsActions,
} from '../../pages/OrderListPage/components/OrderListResults/constants';
import { fulfillmentOrdersFromSelectedOrderIds } from '../../utils/OrderListFilter/utils';

interface UseBulkActionOrdersParams {
  rowsId: string[];
  onHandleBulkAction: (ids: string[], value: Action) => void;
  actionElement?: React.ReactNode;
}

interface UseBulkActionOrdersResult {
  bulkAction: InterfaceBulkAction;
  handleOnSelectRow: (id: string) => void;
  selectedRowsId: string[];
}

function useBulkActionOrders({
  rowsId,
  onHandleBulkAction,
  actionElement,
}: UseBulkActionOrdersParams): UseBulkActionOrdersResult {
  const t = useTranslationOrders();
  const isMobile = useIsMobileDevice();
  const { getAppsLinks } = useAppsLinks();
  const storeCountry = useGetCountry();
  const { metafields } = useGetMetafieldsOrders();
  const { selectedRowsId, setSelectedRowsId, disabled } = useBulkActions();

  const fulfillmentOrders = useSelector(getFulfillmentOrders);
  const isOnlyNonShippableFulfillments = fulfillmentOrdersFromSelectedOrderIds(
    selectedRowsId,
    fulfillmentOrders,
  ).every(({ fulfillments }) =>
    fulfillments?.length > 0
      ? fulfillments.every(
          (fulfillment) => fulfillment.shippingType === 'non-shippable',
        )
      : false,
  );

  const appsOptions = getAppsLinks('orders', 'bulk-action').map((app) => ({
    label: app.text,
    value: app.url,
  }));

  const canPrintOrderShippingLabels = useHasPermission(
    'print_order_shipping_labels',
  );

  const oldAdminOptions = ['printReceipt'];
  const brazilOptions = ['printContentDeclaration'];
  const canSetPaymentReceived = useHasPermission('set_payment_received');
  const canCancelOrder = useHasPermission('cancel_order');
  const canSetOrderPackaged = useHasPermission('set_order_packaged');

  const filteredOptionsByDevice = isMobile
    ? optionsActions.filter((o) => !oldAdminOptions.includes(o))
    : optionsActions;
  const filteredOptionsByCountry =
    storeCountry === Country.BR
      ? filteredOptionsByDevice
      : filteredOptionsByDevice.filter((o) => !brazilOptions.includes(o));

  const getFilteredOptionsByPermission = () => {
    let filteredOptionsByPermission = filteredOptionsByCountry;
    if (!canSetPaymentReceived)
      filteredOptionsByPermission = filteredOptionsByPermission.filter(
        (o) => o !== bulkactions.PAY,
      );

    if (!canCancelOrder)
      filteredOptionsByPermission = filteredOptionsByPermission.filter(
        (o) => o !== bulkactions.CANCEL,
      );

    if (!canSetOrderPackaged)
      filteredOptionsByPermission = filteredOptionsByPermission.filter(
        (o) => o !== bulkactions.PACK,
      );

    if (!canPrintOrderShippingLabels)
      filteredOptionsByPermission = filteredOptionsByPermission.filter(
        (o) => o !== bulkactions.PRINT_PACKING_LABEL,
      );

    return filteredOptionsByPermission;
  };

  const hasMetafields =
    Object.keys(metafields.internals).length > 0 ||
    Object.keys(metafields.fromApi).length > 0;

  const options = getFilteredOptionsByPermission()
    .filter((action) => {
      if (action === 'assignMetafields') return hasMetafields;
      return true;
    })
    .filter((action) => {
      if (action === 'pack') return !isOnlyNonShippableFulfillments;
      return true;
    })
    .map((action) => ({
      label: t(`bulkActions.options.${action}`),
      value: action,
    }))
    .concat(appsOptions);

  const placeholder = t(`bulkActions.options.choose`);
  const getLabel = (n: number) => {
    const label =
      n < 100
        ? t(`bulkActions.selected`, { count: n })
        : t(`bulkActions.maxSelected`, { count: n });

    return label;
  };

  const onSelectAction = (value: string) => {
    onHandleBulkAction(selectedRowsId, value as Action);
    if (value !== Action.ASSIGN_METAFIELDS) {
      bulkAction.onSelectAll(false);

      if (
        value.includes('nuvem-envio-app-back') ||
        value.includes('services-ne-app-back')
      ) {
        trackingOrderBulkNuvemEnvio(selectedRowsId);
      } else {
        trackingOrderBulkActions(selectedRowsId, value as Action);
      }
    }

    if ((value as Action) === Action.ASSIGN_METAFIELDS) {
      trackingOrderBulkMetafields(selectedRowsId);
    }
  };

  const setSelectedRowsIdWithUpsell = useUpsellFlow({
    title: t('bulkActions.upsellTitle'),
    featureKey: FEATURE_BULK_ACTIONS,
    callback: setSelectedRowsId,
    trackingSource: ORDERS_BULK_ACTIONS,
  });

  const { bulkAction, handleOnSelectRow } = useBulkAction({
    rowsId,
    selectedRowsId,
    setSelectedRowsId: setSelectedRowsIdWithUpsell,
    placeholder,
    getLabel,
    options,
    onSelectAction,
    actionElement: selectedRowsId.length >= ORDERS_PER_PAGE && actionElement,
    disabled,
  });

  return { selectedRowsId, bulkAction, handleOnSelectRow };
}

export default useBulkActionOrders;
