import { MouseEvent, useState } from 'react';
import { Box, Tooltip, TooltipProps } from '@nimbus-ds/components';
import { Text } from '@tiendanube/components';

interface EllipsisWithTooltipProps extends Omit<TooltipProps, 'children'> {
  trimLines: number;
  textSize?: 'featured' | 'highlight' | 'base' | 'caption';
}

export function EllipsisWithTooltip({
  content,
  trimLines,
  textSize,
  position,
  arrow,
}: Readonly<EllipsisWithTooltipProps>) {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const onMouseEnter = (e: MouseEvent<HTMLParagraphElement>) => {
    const element = e.target as HTMLParagraphElement;
    if (!element) return;

    setIsOverflowing(element.scrollHeight > element.clientHeight);
  };

  const onMouseLeave = () => {
    setIsOverflowing(false);
  };

  return (
    <Tooltip
      position={position}
      arrow={arrow}
      content={content}
      hidden={!isOverflowing}
    >
      <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Text size={textSize} trimText trimLines={trimLines}>
          {content}
        </Text>
      </Box>
    </Tooltip>
  );
}
