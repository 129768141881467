import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CatalogStateInterface } from 'domains/Catalog/types';
import { statusType, AttributesToFix } from '../types';

export const getCatalog = (
  state: RootStateType,
): CombinedState<CatalogStateInterface> => state.catalog;

export const getProductsData = createSelector(
  getCatalog,
  (catalog) => catalog.products,
);

export const productsExportSelector = createSimpleSelector(
  [getProductsData],
  (products) => products.export,
);

export const getImageStatus = createSelector(
  getProductsData,
  (productData) => productData.statusImage,
);

export const createHighlightProductStatus = createSelector(
  getProductsData,
  (productData) => productData.createHighlightProductStatus,
);

export const getProductsStatusSuccess = createSimpleSelector(
  [getProductsData],
  (products) => products.statusSuccess,
);

export interface ImageStatusRequestInterface {
  isError: boolean;
  isLoading: boolean;
}

export const getImageStatusRequest = createSelector(
  getImageStatus,
  (state): ImageStatusRequestInterface => ({
    isError: state === statusType.error,
    isLoading: state === statusType.loading,
  }),
);

export const getProductIds = createSelector(
  getProductsData,
  (catalog) => catalog.ids,
);

export const getProductsEntities = createSelector(
  getProductsData,
  (catalog) => catalog.entities,
);

export const getProducts = createSelector(
  getProductIds,
  getProductsEntities,
  (ids, entities) => ids.filter((id) => entities[id]).map((id) => entities[id]),
);

export const getProductsStatus = createSelector(
  getProductsData,
  (productsData) => productsData.status,
);

export const getProductsStatusVariants = createSelector(
  getProductsData,
  (productsData) => productsData.statusVariants,
);

export const getProductsList = createSelector(
  getProductIds,
  getProductsStatus,
  (ids, state) => ({
    ids,
    isLoading: state === statusType.loading,
    isSuccess: state === statusType.success,
    isError: state === statusType.error,
  }),
);

export const getProductDetailsStatus = createSelector(
  getProductsData,
  (data) => data.statusDetails,
);

export const getProductDetailsFetchStatus = createSelector(
  getProductDetailsStatus,
  (status) => ({
    loading: status === statusType.loading,
    error: status === statusType.error,
  }),
);

export const getEntities = createSelector(
  getProductsData,
  (data) => data.entities,
);

export const getVariantStock = createSelector(
  getProductsData,
  (data) => data.variantStock,
);

export const getSummaries = createSelector(
  getProductsData,
  (data) => data.summaries,
);

export const getMetafieldsPersistsStatus = createSelector(
  getProductsData,
  (data) => data.metafieldsPersistsStatus,
);

export const getAppliedFilters = createSimpleSelector(
  [getProductsData],
  (products) => products.appliedFilters,
);

const getEntityById = (_: RootStateType, id: string) => id;

export const createGetProductById = () =>
  createSelector(
    getEntities,
    getEntityById,
    (entities, id) => entities[id] || null,
  );

export const createGetProductByIdStatus = createSelector(
  getProductsData,
  (data) => convertStatusTypeToObject(data.statusDetails),
);

export const createMetafieldsPersistsStatusById = () =>
  createSelector(
    getMetafieldsPersistsStatus,
    getEntityById,
    (metafieldsPersistsStatus, id) => metafieldsPersistsStatus[id] || 'idle',
  );

export const createProductsStatusVariantsById = () =>
  createSelector(
    getProductsStatusVariants,
    getEntityById,
    (getProductsStatusVariants, id) =>
      convertStatusTypeToObject(getProductsStatusVariants[id]),
  );

export const hasVariants = createSelector(getProductsData, (data) =>
  Object.values(data.entities).some((product) => product.attributes.length > 0),
);

export const getTotalProducts = createSelector(
  getProductsData,
  (productsData) => productsData.totalProducts,
);

export const getProductsExportStatus = createSimpleSelector(
  [productsExportSelector],
  (productsExport) => ({
    isLoading: productsExport.status === 'loading',
    isError: productsExport.status === 'error',
    isSuccess: productsExport.status === 'success',
  }),
);

export const getProductsExportTotal = createSimpleSelector(
  [productsExportSelector],
  (productsExport) => productsExport.totalResults,
);

export const getStandardVariantsData = createSelector(
  getProductsData,
  (products) => products.standardVariants.data,
);

export const getStandardVariantsStatus = createSelector(
  getProductsData,
  (products) => products.standardVariants.status,
);

export const createGetProductSectionsCodeByProductId = () =>
  createSelector(
    getEntities,
    getEntityById,
    (entities, id) => entities[id].sectionCodes || null,
  );

export const getSortByProducts = createSimpleSelector(
  [getProductsData],
  (products) => products.appliedFilters.sortBy,
);

export const updateInventoryLevelsStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.statusInventoryLevels,
);

export const exportProductsStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.statusExportProducts,
);

export const importMaxLinesStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.importMaxLines.status,
);

export const importMaxLines = createSimpleSelector(
  [getProductsData],
  (product) => product.importMaxLines.max,
);

export const stockHistoryStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.statusStockHistory,
);

export const stockHistory = createSimpleSelector(
  [getProductsData],
  (product) => product.stockHistory,
);

export const fixAttributesStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.fixesAttributes.status,
);

export const initialValuesToFixAttributes = () =>
  createSelector(
    getEntities,
    getEntityById,
    (entities, id): AttributesToFix => ({
      attributes: entities[id].attributes,
      toDelete: [],
    }),
  );

export const tagsByStoreData = createSimpleSelector(
  [getProductsData],
  (product) => product.standardTags.data,
);

export const tagsByStoreStatus = createSimpleSelector(
  [getProductsData],
  (product) => product.standardTags.status,
);
