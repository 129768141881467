import { useEffect, useState } from 'react';
import { Icon, Link } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import { ProvinceDto } from '@tiendanube/common';
import { ErrorScreen } from 'commons/components';
import { useGetProvinces } from 'domains/Shipping/Addresses/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ProvincesList from './ProvincesList';
import { SelectedZonesInterface } from '../../DeliveryZoneCard';
import SelectCitiesModal from '../SelectCitiesModal';

interface SelectProvinceModalProps {
  isOpen: boolean;
  onChange: (selectedZones: SelectedZonesInterface[]) => void;
  onClose: () => void;
  selectedZones: SelectedZonesInterface[];
}

function SelectProvinceModal({
  isOpen,
  onClose,
  onChange,
  selectedZones,
}: SelectProvinceModalProps) {
  const t = useTranslationShipping();
  const { isLoading, provinces, isError, fetchProvinces } = useGetProvinces();

  const [filter, setFilter] = useState<ProvinceDto[]>(provinces ?? []);
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<
    null | string
  >(null);

  const handleSelectProvince = (selectedOption: string) => {
    setSelectedProvinceCode(selectedOption);
  };

  const handleCleanProvince = () => {
    setSelectedProvinceCode(null);
  };

  const handleSetFilter = (filter: ProvinceDto[]) => {
    setFilter(filter);
  };

  const selectedProvince = provinces?.find(
    (province) => province.code === selectedProvinceCode,
  );

  const handleChangeCity = (selectedCities: string[], allChecked: boolean) => {
    const newSelectedZones = [...selectedZones.map((zone) => ({ ...zone }))];

    const positionZone = newSelectedZones.findIndex(
      (selectedZone) => selectedZone.provinceId === selectedProvince?.id,
    );

    if (positionZone > -1 && selectedCities.length === 0 && !allChecked) {
      newSelectedZones.splice(positionZone, 1);
    }

    if (positionZone > -1 && selectedCities.length > 0) {
      newSelectedZones[positionZone].citiesIds = allChecked
        ? []
        : selectedCities;
    }

    if (positionZone === -1 && selectedCities.length > 0) {
      newSelectedZones.push({
        provinceId: selectedProvince?.id || '',
        provinceName: selectedProvince?.name || '',
        citiesIds: allChecked ? [] : selectedCities,
      });
    }

    onChange(newSelectedZones);
  };

  const selectedProvinceZone = selectedZones.find(
    (zone) => zone.provinceId === selectedProvince?.id,
  );

  const selectedCities =
    selectedZones.find((zone) => zone.provinceId === selectedProvince?.id)
      ?.citiesIds || [];

  const hasAllCities =
    (selectedProvinceZone && selectedProvinceZone.citiesIds.length === 0) ??
    false;

  useEffect(() => {
    if (!provinces) {
      fetchProvinces();
    }
    if (provinces) {
      setFilter(provinces);
    }
  }, [fetchProvinces, provinces]);

  return (
    <SideModal
      open={isOpen}
      onRemove={onClose}
      maxWidth={{ xs: '100%', md: '45%', lg: '540px' }}
      paddingBody="none"
      headerAction={
        <Link onClick={onClose} textDecoration="none">
          <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
        </Link>
      }
      title={t('deliveryMethods.customShipping.selectProvinceModal.title')}
    >
      {isError && (
        <ErrorScreen
          description={t('deliveryMethods.customShipping.error.title')}
          onRetry={fetchProvinces}
        />
      )}
      {!isError && isLoading && !provinces?.length && (
        <ProvincesList.Skeleton />
      )}
      {!isError && !isLoading && !!provinces?.length && (
        <ProvincesList
          provinces={provinces}
          filteredProvinces={filter}
          handleSelectProvince={handleSelectProvince}
          handleSetFilter={handleSetFilter}
        />
      )}
      {!!selectedProvinceCode && (
        <SelectCitiesModal
          selectedProvinceCode={selectedProvinceCode}
          selectedNameProvince={selectedProvince?.name || ''}
          selectedCities={selectedCities}
          hasAllCities={hasAllCities}
          isOpen={!!selectedProvinceCode}
          onClose={handleCleanProvince}
          onChange={handleChangeCity}
        />
      )}
    </SideModal>
  );
}

export default SelectProvinceModal;
