import { Text, TextProps } from '@nimbus-ds/components';
import { RateDefinitionDto } from '@tiendanube/common';
import { customPaymentsIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Stack } from 'commons/components';
import { useTranslationLanguage } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';
import { TRANSLATE_PAYMENT_PREFIX } from 'domains/Payments/PaymentProviders/components/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface PaymentProviderProps {
  definition: RateDefinitionDto;
  id: string;
}

export function PercentFeeItem({
  definition: { percentFee, flatFee, flatFeeCurrency, plusTax },
  id,
}: Readonly<PaymentProviderProps>) {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);
  const { code } = useGetCurrency();
  const language = useTranslationLanguage();

  const getFlatFeeCurrency = (fontWeight: TextProps['fontWeight']) => (
    <Text fontWeight={fontWeight}>
      {customPaymentsIds.includes(id)
        ? t('freeTax')
        : formatCurrency(Number(flatFee), flatFeeCurrency! || code, language)}
    </Text>
  );

  return (
    <Stack gap="1">
      {percentFee ? (
        <Text fontWeight="bold">{Number(percentFee).toFixed(2)}%</Text>
      ) : (
        getFlatFeeCurrency('bold')
      )}
      {!!flatFee && !!percentFee && (
        <>
          <Text fontWeight="regular">{' + '}</Text>
          {getFlatFeeCurrency('regular')}
        </>
      )}
      {plusTax && (
        <>
          <Text fontWeight="regular">{' + '}</Text>
          <Text>{t('tax')}</Text>
        </>
      )}
    </Stack>
  );
}
