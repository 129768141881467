export const PRODUCTS_BASE_ROUTE = '/products';

export const CATALOG_ROUTES = {
  productList: PRODUCTS_BASE_ROUTE,
  newProduct: '/products/new',
  organizeFeaturedProducts: '/products/featured',
  organizeProducts: '/products/organize',
  importAndExportProducts: '/products/import',
  importProducts: '/products/import/onboarding',
  editProduct: '/products/:id',
  inventoryList: '/inventory',
  editInventory: '/inventory/:productId/:variantId',
  categoriesList: '/categories',
  editCategory: '/categories/:id',
};
