import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { toStatusType } from 'App/rtk';
import {
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  SubmitButton,
  SubmitButtonHeaderTop,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { ExternalCodesForm } from './components';
import validationSchema from './components/ExternalCodesForm/validationSchema';
import {
  useGetExternalCodesQuery,
  useUpdateExternalCodesMutation,
} from '../externalCodesApi';

const initialValues = {
  assortedJs: '',
  bingMeta: '',
  confirmationCheckoutConversion: '',
  fbPixelCode: '',
  hasFbConfigured: false,
  googleAnalyticsApiSecret: '',
  googleAnalyticsGtmId: '',
  googleAnalyticsMeasurementId: '',
  googleMerchantId: '',
  googleMeta: '',
  olark: '',
  purchaseEventOnPaidOrder: '1',
  script: '',
  thankYouCheckoutConversion: '',
};

export function ExternalCodesPage() {
  const t = useTranslationConfigurations('externalCodes');
  const { t: tCommon } = useTranslation('common');
  const {
    data = initialValues,
    refetch,
    isLoading,
    isError,
    isSuccess,
  } = useGetExternalCodesQuery();
  const [onSubmit, { isLoading: isUpdating, status }] =
    useUpdateExternalCodesMutation();
  const {
    errors,
    values,
    handleChange,
    handleChangeTextArea,
    isDirty,
    handleOnSubmit,
  } = useForm({
    validationSchema,
    initialValues: data,
    onSubmit,
  });

  useToastStatus({
    status: toStatusType(status),
    success: t('toast.success'),
    error: t('toast.error'),
  });

  const saveText = isUpdating ? t('saving') : t('save');

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('title')} />}
      headerTop={
        <HeaderTop
          actions={
            isSuccess ? (
              <SubmitButtonHeaderTop
                isLoading={isUpdating}
                onSubmit={handleOnSubmit}
                disabled={!isDirty}
              >
                {saveText}
              </SubmitButtonHeaderTop>
            ) : null
          }
        />
      }
    >
      {isLoading && <ExternalCodesForm.Skeleton />}
      {isError && (
        <Box padding="4">
          <ErrorScreen onRetry={refetch} />
        </Box>
      )}
      {isSuccess && (
        <ExternalCodesForm
          errors={errors}
          values={values}
          onChange={handleChange}
          onChangeTextArea={handleChangeTextArea}
        />
      )}
      {isSuccess && (
        <Box display="flex" justifyContent="flex-end">
          <SubmitButton
            isLoading={isUpdating}
            disabled={!isDirty}
            onClick={handleOnSubmit}
          >
            {saveText}
          </SubmitButton>
        </Box>
      )}
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
    </IonPageStratus>
  );
}
