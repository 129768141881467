import { Link } from '@nimbus-ds/components';
import { FEATURE_ALLOW_CUSTOM_DOMAIN } from 'App/features';
import { DOMAINS_REACTIVATE_DOMAIN_LINK } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useCustomDomainDisabled } from 'domains/Configurations/Domains/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function ReactivateDomainLink() {
  const t = useTranslationConfigurations('domains');
  const isFreePlan = useCustomDomainDisabled();

  const onClick = useUpsellFlow({
    title: t('upsell.reactivateDomains'),
    featureKey: FEATURE_ALLOW_CUSTOM_DOMAIN,
    callback: () => {
      // Do nothing, just re-render page
    },
    trackingSource: DOMAINS_REACTIVATE_DOMAIN_LINK,
  });

  if (!isFreePlan) return null;

  return (
    <Link
      as="button"
      textDecoration="none"
      appearance="primary"
      fontSize="caption"
      onClick={onClick}
    >
      {t('reactivate')}
    </Link>
  );
}
