import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { combineStatus } from 'commons/utils/combineStatus';
import { checkoutApi } from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import {
  useChargesToPay,
  usePaymentMethods,
  usePayorderToPay,
  useUpdateCharge,
} from 'domains/Billing/Checkout/hooks';

function useSetupPayorder() {
  const dispatch = useAppDispatch();
  const { paymentMethodSelected } = usePaymentMethods();
  const {
    chargesToPay,
    status: pendingChargesStatus,
    refreshChargesToPay,
  } = useChargesToPay();
  const { createPayorder, payorderToPay, payOrderCreationStatus } =
    usePayorderToPay();
  const { updateCharge, status: updateSubscriptionsStatus } = useUpdateCharge();

  const retrySetup = useCallback(() => {
    refreshChargesToPay();
  }, [refreshChargesToPay]);

  const setupStatus = combineStatus(pendingChargesStatus);

  const statusObject = combineStatus(
    payOrderCreationStatus,
    updateSubscriptionsStatus,
  );

  const setupPayorder = useCallback(async () => {
    if (!paymentMethodSelected || !chargesToPay) return;
    const { recurringFrequency, recurringInterval } =
      paymentMethodSelected.recurrencyOption;
    const updatedCharge = await updateCharge({
      method: paymentMethodSelected.method,
      recurringFrequency,
      recurringInterval,
    }).unwrap();
    if (!updatedCharge) return;
    /**
     * updating the plan charge might have created a charge instead of updating it
     * so we add the result to the list of charges to pay
     *  */
    const chargesIds = chargesToPay.map(({ id }) => id);
    if (!chargesIds.includes(updatedCharge.id))
      chargesIds.push(updatedCharge.id);
    return createPayorder(chargesIds)
      .unwrap()
      .finally(() => {
        dispatch(
          checkoutApi.util.invalidateTags([
            checkoutTags.pendingCharges,
            checkoutTags.payOrderToPay,
          ]),
        );
      });
  }, [
    dispatch,
    updateCharge,
    createPayorder,
    paymentMethodSelected,
    chargesToPay,
  ]);

  return {
    retrySetup,
    setupPayorder,
    setupStatus,
    payorderToPay,
    ...statusObject,
  };
}

export default useSetupPayorder;
