import { Box, Thumbnail, Text, Input, IconButton } from '@nimbus-ds/components';
import { DataList } from '@tiendanube/components';
import ScrollTop from 'commons/components/ScrollTop';

function ResultItemSkeleton(): JSX.Element {
  return (
    <DataList.Row id="id">
      <Box
        display="grid"
        gridTemplateColumns="48px auto"
        gridGap="4"
        alignItems="flex-start"
        px="2"
        width="100%"
      >
        <Thumbnail.Skeleton width="48px" />
        <Box display="flex" flexDirection="column" gap="4">
          <Text.Skeleton fontSize="highlight" width="150px" />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Input.Skeleton width="80px" />
            <IconButton.Skeleton height="32px" width="32px" />
          </Box>
        </Box>
      </Box>
    </DataList.Row>
  );
}

function InventoryResultsSkeleton(): JSX.Element {
  return (
    <DataList>
      {[...Array(8)].map((_, k) => (
        <ResultItemSkeleton key={k} />
      ))}
      <ScrollTop />
    </DataList>
  );
}

export default InventoryResultsSkeleton;
