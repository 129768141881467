import { useState } from 'react';
import { CardWithTitle, Spinner } from 'commons/components';
import { GenericError } from 'domains/Statistics/components';
import {
  useStatsProductsByItem,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import { StatsProductsItemType } from 'domains/Statistics/types';
import { STATS_PLAN_1_AND_2 } from 'domains/Statistics/utils';
import { StatsProducts } from './components';
import StatsProductsPlaceholder from './components/StatsProductsPlaceholder';

export const INPUT_TYPES: Record<StatsProductsItemType, 'currency' | 'number'> =
  {
    'low-stock': 'number',
    'most-visited': 'number',
    'least-visited': 'number',
    'best-sellers': 'number',
    'least-sellers': 'number',
    'higher-revenue': 'currency',
    'lower-revenue': 'currency',
  };

interface StatsProductsCardProps {
  item: StatsProductsItemType;
  withoutCard?: boolean;
}

function StatsProductsCard({
  item,
  withoutCard = false,
}: StatsProductsCardProps) {
  const t = useTranslationStatistics();
  const {
    products,
    isEmpty,
    pagination,
    fetchStatisticsProducts,
    filters,
    onApplyProductsFilters,
    status,
  } = useStatsProductsByItem(item);
  const enabled = useSupportsStatsPlan(STATS_PLAN_1_AND_2);

  const [value, setValue] = useState(filters.quantity);

  const handleOnChange = ({ value }) => setValue(value);

  const handleOnSelectPage = (page: number) => {
    onApplyProductsFilters({
      ...filters,
      page,
    });
  };

  const handleOnSubmit = () => {
    onApplyProductsFilters({
      ...filters,
      quantity: value,
    });
  };

  const renderContent = enabled ? (
    <>
      {status === 'loading' && <Spinner size="medium" />}
      {status === 'error' && <GenericError onRetry={fetchStatisticsProducts} />}
      {status === 'success' && (
        <StatsProducts
          item={item}
          labelHeader={t(`statistics.statsProductsCard.${item}.labelHeader`)}
          inputLabel={t(`statistics.statsProductsCard.${item}.inputLabel`)}
          inputType={INPUT_TYPES[item]}
          value={value}
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          products={products}
          pagination={pagination}
          onChangePagination={handleOnSelectPage}
          isEmpty={isEmpty}
        />
      )}
    </>
  ) : (
    <StatsProductsPlaceholder
      labelHeader={t(`statistics.statsProductsCard.${item}.labelHeader`)}
      inputLabel={t(`statistics.statsProductsCard.${item}.inputLabel`)}
      inputType={INPUT_TYPES[item]}
      value={value}
    />
  );

  if (withoutCard) return renderContent;

  return (
    <CardWithTitle title={t(`statistics.statsProductsCard.${item}.title`)}>
      {renderContent}
    </CardWithTitle>
  );
}

export default StatsProductsCard;
