import VirtualDataList from 'commons/components/VirtualDataList';
import { useDraftOrders } from 'domains/Orders/DraftOrders/hooks';
import DraftOrdersListMobileSkeleton from './DraftOrdersListMobileSkeleton';
import DraftOrdersListRow from './DraftOrdersListRow';

function DraftOrdersListMobile(): JSX.Element {
  const {
    draftOdersList,
    isLoading,
    fetchDraftOrders,
    getMoreDraftOrders,
    isRefreshing,
  } = useDraftOrders();

  const handleRefresh = async () => {
    if (isLoading || isRefreshing) return;
    await fetchDraftOrders();
  };

  const shouldShowSpinner = isRefreshing && draftOdersList.length !== 0;

  return (
    <>
      {isLoading && draftOdersList.length === 0 && (
        <DraftOrdersListMobileSkeleton />
      )}
      {draftOdersList.length > 0 && (
        <VirtualDataList
          isPullable
          data={draftOdersList}
          itemContent={(index, id) => <DraftOrdersListRow id={id} />}
          onRefresh={handleRefresh}
          endReached={getMoreDraftOrders}
          showFooter={shouldShowSpinner}
        />
      )}
    </>
  );
}

export default DraftOrdersListMobile;
