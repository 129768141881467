import { Icon, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';

interface InfoTooltipProps {
  content: string;
}

export function InfoTooltip({ content }: Readonly<InfoTooltipProps>) {
  return (
    <Tooltip arrow position="bottom" content={content}>
      <Icon
        source={<InfoCircleIcon size="small" />}
        color="primary-interactive"
      />
    </Tooltip>
  );
}
