import { CheckIcon } from '@nimbus-ds/icons';
import {
  Status as StatusEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { StoreIcon, TruckIcon } from '@tiendanube/icons';

function getIcon(
  status: StatusEnum,
  fulfillmentPreferenceType: FulfillmentPreferenceTypeEnum,
) {
  if (status === StatusEnum.DELIVERED) return CheckIcon;
  const icon =
    fulfillmentPreferenceType === FulfillmentPreferenceTypeEnum.PICKUP
      ? StoreIcon
      : TruckIcon;
  return icon;
}

export default getIcon;
