import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CorreiosContractsResponseDto,
  CorreiosOptionsRequestDto,
  CorreiosOptionsResponseDto,
  ShippingAppResponseDto,
  ShippingFallbackRequestDto,
  ShippingMethodsResponseDto,
  CustomShippingRequestDto,
  CustomShippingResponseDto,
  IdResponseDto,
  InternationalShippingResponseDto,
  OcaRegisterRequestDto,
  OcaConfigRequestDto,
  OcaConfigResponseDto,
  NuvemEnvioFrontConfigsResponseDto,
  NuvemEnvioFrontConfigsRequestDto,
  ShippingFallbackAvailableRequestDto,
  AddressingConfigurationRuleResponseDto,
} from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import {
  NewInternationalShippingParamsInterface,
  NewCustomShippingParamsInterface,
  UpdateInternationalShippingDetailParams,
  UpdateOcaConfigurationParams,
  DeleteActiveShippingMethodParams,
  EnableCarrierParams,
} from './types';
import { initialState } from './utils';
import deliveryMethodsService from '../deliveryMethodsService';
import { OptionsCarriersInterface } from '../pages/components/CarriersForm/CarriersForm';

interface ParamsDataInterface {
  id: string;
  data: CustomShippingRequestDto;
}
interface UpdateFallbackAction {
  fallback: ShippingFallbackRequestDto;
  id: string;
}

interface UpdateAvailableFallbackAction {
  payload: ShippingFallbackAvailableRequestDto;
  id: string;
}

interface UpdateDetailActiveAppParams {
  id: string;
  body: Record<string, OptionsCarriersInterface>;
}

export const getFallbackAction = createAsyncThunk(
  'shipping/getFallbackAction',
  deliveryMethodsService.getFallback,
);

export const createFallbackAction = createAsyncThunk(
  'shipping/createFallbackAction',
  (arg: ShippingFallbackRequestDto) =>
    deliveryMethodsService.createFallback(arg),
);

export const updateFallbackAction = createAsyncThunk(
  'shipping/updateFallbackAction',
  ({ fallback, id }: UpdateFallbackAction) =>
    deliveryMethodsService.updateFallback(fallback, id),
);

export const updateAvailableFallbackAction = createAsyncThunk(
  'shipping/updateAvailableFallbackAction',
  ({ payload, id }: UpdateAvailableFallbackAction) =>
    deliveryMethodsService.updateAvailableFallback(payload, id),
);

export const fetchPhysicalLocationsAction = createAsyncThunk(
  'shipping/fetchPhysicalLocations',
  deliveryMethodsService.fetchPhysicalLocations,
);

export const updatePhysicalLocationAction = createAsyncThunk(
  'shipping/updatePhysicalLocations',
  deliveryMethodsService.updatePhysicalLocation,
);

export const createPhysicalLocationAction = createAsyncThunk(
  'shipping/createPhysicalLocations',
  deliveryMethodsService.createPhysicalLocation,
);

export const deletePhysicalLocationAction = createAsyncThunk(
  'shipping/deletePhysicalLocationAction',
  deliveryMethodsService.deletePhysicalLocation,
);

export const getActiveShippingMethodsAction = createAsyncThunk(
  'shipping/getActiveShippingMethods',
  deliveryMethodsService.getActiveShippingMethods,
);

export const deleteActiveShippingMethodsAction = createAsyncThunk<
  IdResponseDto,
  DeleteActiveShippingMethodParams
>('shipping/deleteShipmentsInstalled', async ({ isNative, id }) =>
  deliveryMethodsService.deleteShipmentsInstalled(id, { isNative }),
);

export const enableCarrierAction = createAsyncThunk<
  IdResponseDto,
  EnableCarrierParams
>('shipping/enableCarrier', async ({ id }) =>
  deliveryMethodsService.enableCarrier(id),
);

export const getDetailActiveShippingAction = createAsyncThunk<
  ShippingAppResponseDto,
  string
>('shipping/getDetailActiveShippingAction', async (id) =>
  deliveryMethodsService.getDetailActiveApp(id),
);

export const updateActiveShippingAction = createAsyncThunk<
  ShippingAppResponseDto,
  UpdateDetailActiveAppParams
>('shipping/updateActiveShippingAction', async ({ id, body }) =>
  deliveryMethodsService.updateDetailActiveApp(
    id,
    Object.values(body).map((carrier) => ({
      id: carrier.id,
      isEnabled: carrier.isEnabled,
      allowedFreeShipping: carrier.allowedFreeShipping,
      additionalCost: carrier.additionalCost,
      additionalDays: carrier.additionalDays,
    })),
  ),
);

export const getAvailableAppsAction = createAsyncThunk(
  'shipping/getAvailableAppsAction',
  deliveryMethodsService.getAvailableApps,
);

export const createCustomShippingAction = createAsyncThunk<
  CustomShippingResponseDto,
  NewCustomShippingParamsInterface
>('shipping/createCustomShippingAction', async (params) =>
  deliveryMethodsService.createCustomShipping(params),
);

export const requestOcaRegistrationAction = createAsyncThunk(
  'shipping/requestOcaRegistrationAction',
  (payload: OcaRegisterRequestDto) =>
    deliveryMethodsService.requestOcaRegistration(payload),
);

export const fetchCustomShipping = createAsyncThunk<
  CustomShippingResponseDto[]
>('shipping/fetchCustomShipping', async () =>
  deliveryMethodsService.getCustomShipping(),
);

export const deleteCustomShippingAction = createAsyncThunk<void, string>(
  'shipping/deleteCustomShipping',
  async (customId) => deliveryMethodsService.deleteCustomShipping(customId),
);

export const fetchDetailCustomShipping = createAsyncThunk<
  CustomShippingResponseDto,
  string
>('shipping/fetchDetailCustomShipping', async (id) =>
  deliveryMethodsService.getDetailCustomShipping(id),
);

export const updateDetailCustomShippingAction = createAsyncThunk<
  CustomShippingResponseDto,
  ParamsDataInterface
>('shipping/updateDetailCustomShipping', async ({ id, data }) =>
  deliveryMethodsService.updateDetailCustomShipping(id, data),
);

export const fetchInternationalShipping = createAsyncThunk(
  'shipping/fetchInternationalShipping',
  deliveryMethodsService.getInternationalShipping,
);

export const deleteInternationalShipping = createAsyncThunk<
  IdResponseDto,
  string
>('shipping/deleteInternationalShipping', (id) =>
  deliveryMethodsService.deleteInternationalShipping(id),
);

export const createInternationalShipping = createAsyncThunk<
  InternationalShippingResponseDto,
  NewInternationalShippingParamsInterface
>('shipping/createInternationalShipping', async (data) =>
  deliveryMethodsService.newInternationalShipping(data),
);

export const fetchDetailInternationalShipping = createAsyncThunk<
  InternationalShippingResponseDto,
  string
>('shipping/fetchDetailInternationalShipping', async (id) =>
  deliveryMethodsService.getDetailInternationalShipping(id),
);

export const updateInternationalShippingDetail = createAsyncThunk<
  InternationalShippingResponseDto,
  UpdateInternationalShippingDetailParams
>('shipping/updateInternationalShippingDetail', ({ id, payload }) =>
  deliveryMethodsService.updateDetailInternationalShipping(id, payload),
);

export const getOcaAgenciesAction = createAsyncThunk(
  'shipping/getOcaAgencies',
  deliveryMethodsService.getOcaAgencies,
);

export const getOcaConfigurationAction = createAsyncThunk(
  'shipping/getOcaConfigation',
  async () => deliveryMethodsService.getOcaConfiguration(),
);

export const createOcaConfigurationAction = createAsyncThunk<
  OcaConfigResponseDto,
  OcaConfigRequestDto
>('shipping/createOcaConfigation', async (ocaConfiguration) =>
  deliveryMethodsService.createOcaConfiguration(ocaConfiguration),
);

export const updateOcaConfigurationAction = createAsyncThunk<
  OcaConfigResponseDto,
  UpdateOcaConfigurationParams
>('shipping/updateOcaConfigation', async ({ id, payload }) =>
  deliveryMethodsService.updateOcaConfiguration(id, payload),
);

export const getCorreiosContractTypesAction = createAsyncThunk(
  'shipping/getCorreiosContractTypesAction',
  () => deliveryMethodsService.getCorreiosContractTypes(),
);

export const getCorreiosDetailAction = createAsyncThunk(
  'shipping/getCorreiosDetailAction',
  () => deliveryMethodsService.getCorreiosDetail(),
);

export const updateCorreiosShippingModalitiesAction = createAsyncThunk<
  CorreiosOptionsResponseDto[],
  CorreiosOptionsRequestDto[]
>('shipping/updateCorreiosShippingModalitiesAction', (payload) =>
  deliveryMethodsService.updateCorreiosShippingModalities(payload),
);

export const updateCorreiosContractAction = createAsyncThunk<
  CorreiosContractsResponseDto,
  CorreiosContractsResponseDto
>('shipping/updateCorreiosContractAction', (payload) =>
  deliveryMethodsService.updateCorreiosContract(payload),
);

export const getShippingMethodsAction =
  createAsyncThunk<ShippingMethodsResponseDto>(
    'shipping/getShippingMethodsAction',
    () => deliveryMethodsService.getShippingMethods(),
  );

export const getNuvemEnvioConfigsAction =
  createAsyncThunk<NuvemEnvioFrontConfigsResponseDto>(
    'shipping/getNuvemEnvioConfigsAction',
    deliveryMethodsService.getNuvemEnvioConfigs,
  );

export const updateNuvemEnvioConfigsAction = createAsyncThunk<
  NuvemEnvioFrontConfigsResponseDto,
  NuvemEnvioFrontConfigsRequestDto
>(
  'shipping/updateNuvemEnvioConfigsAction',
  deliveryMethodsService.updateNuvemEnvioConfigs,
);

export const getAddressingConfigurationRulesAction = createAsyncThunk<
  AddressingConfigurationRuleResponseDto[]
>(
  'shipping/getAvailableAddressingConfigurationRulesAction',
  deliveryMethodsService.getAddressingConfigurationRules,
);

export const saveAddressingConfigurationRulesAction = createAsyncThunk<
  AddressingConfigurationRuleResponseDto[],
  AddressingConfigurationRuleResponseDto[]
>(
  'shipping/saveAddressingConfigurationRulesAction',
  deliveryMethodsService.saveAddressingConfigurationRules,
);

const deliveryMethodsSlice = createSlice({
  name: 'deliveryMethodsSlice',
  initialState,
  reducers: {
    cleanCreateCustomShippingStatus(state) {
      state.customShipping.create.status = 'idle';
      return state;
    },
    cleanUpdateCustomShippingStatus(state) {
      state.customShipping.update.status = 'idle';
      return state;
    },
    cleanDeleteCustomShippingStatus(state) {
      state.customShipping.delete.status = 'idle';
      return state;
    },
    cleanGetPhysicalLocationStatus(state) {
      state.physicalLocations.getStatus = 'idle';
      return state;
    },
    cleanUpdatePhysicalLocationStatus(state) {
      state.physicalLocations.updateStatus = 'idle';
      return state;
    },
    cleanCreatePhysicalLocationStatus(state) {
      state.physicalLocations.createStatus = 'idle';
      return state;
    },
    cleanDeletePhysicalLocationStatus(state) {
      state.physicalLocations.deleteStatus = 'idle';
      return state;
    },
    cleanDeleteInternationalShippingStatus(state) {
      state.internationalShipping.delete.status = 'idle';
    },
    cleanNewInternationalShippingStatus(state) {
      state.internationalShipping.create.status = 'idle';
    },
    cleanUpdateInternationalShippingDetail(state) {
      state.internationalShipping.update.status = 'idle';
    },
    cleanDeleteActiveShippingStatus(state) {
      state.activeShippingMethods.delete.status = 'idle';
    },
    cleanOcaConfigurationCreateStatus(state) {
      state.ocaConfiguration.create.status = 'idle';
      return state;
    },
    cleanOcaConfigurationUpdateStatus(state) {
      state.ocaConfiguration.update.status = 'idle';
      return state;
    },
    cleanUpdateCorreiosContractStatus(state) {
      state.correios.contract.create.status = 'idle';
    },
    cleanUpdateCorreiosShippingModalitiesStatus(state) {
      state.correios.shippingModalities.create.status = 'idle';
    },
    cleanUpdateDetailActiveAppStatus(state) {
      state.activeShippingMethods.update.status = 'idle';
    },
    cleanUpdateFallbackStatus(state) {
      state.fallback.updateStatus = 'idle';
    },
    cleanSaveAddressingConfigurationRulesStatus(state) {
      state.addressingConfiguration.saveStatus = 'idle';
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder
      .addCase(getActiveShippingMethodsAction.pending, (state) => {
        state.activeShippingMethods.list.status = 'loading';
        state.activeShippingMethods.list.data =
          initialState.activeShippingMethods.list.data;
      })
      .addCase(getActiveShippingMethodsAction.rejected, (state) => {
        state.activeShippingMethods.list.status = 'error';
      })
      .addCase(getActiveShippingMethodsAction.fulfilled, (state, action) => {
        state.activeShippingMethods.list.data = action.payload;
        state.activeShippingMethods.list.status = 'success';
      });

    builder
      .addCase(getAvailableAppsAction.pending, (state) => {
        state.availableApps.list.status = 'loading';
        state.availableApps.list.data = initialState.availableApps.list.data;
      })
      .addCase(getAvailableAppsAction.rejected, (state) => {
        state.availableApps.list.status = 'error';
      })
      .addCase(getAvailableAppsAction.fulfilled, (state, action) => {
        state.availableApps.list.data = action.payload;
        state.availableApps.list.status = 'success';
      });

    builder
      .addCase(getFallbackAction.pending, (state) => {
        state.fallback.status = 'loading';
      })
      .addCase(getFallbackAction.rejected, (state) => {
        state.fallback.status = 'error';
      })
      .addCase(getFallbackAction.fulfilled, (state, action) => {
        state.fallback.data = action.payload;
        state.fallback.status = 'success';
      });

    builder
      .addCase(createFallbackAction.pending, (state) => {
        state.fallback.updateStatus = 'loading';
      })
      .addCase(createFallbackAction.rejected, (state) => {
        state.fallback.updateStatus = 'error';
      })
      .addCase(createFallbackAction.fulfilled, (state, action) => {
        state.fallback.data = action.payload;
        state.fallback.updateStatus = 'success';
      });

    builder
      .addCase(updateFallbackAction.pending, (state) => {
        state.fallback.updateStatus = 'loading';
      })
      .addCase(updateFallbackAction.rejected, (state) => {
        state.fallback.updateStatus = 'error';
      })
      .addCase(updateFallbackAction.fulfilled, (state, action) => {
        state.fallback.data = action.payload;
        state.fallback.updateStatus = 'success';
      });

    builder
      .addCase(updateAvailableFallbackAction.pending, (state) => {
        state.fallback.updateStatus = 'loading';
      })
      .addCase(updateAvailableFallbackAction.rejected, (state) => {
        state.fallback.updateStatus = 'error';
      })
      .addCase(updateAvailableFallbackAction.fulfilled, (state) => {
        state.fallback.updateStatus = 'success';
      });

    builder
      .addCase(createCustomShippingAction.pending, (state) => {
        state.customShipping.create.status = 'loading';
      })
      .addCase(createCustomShippingAction.rejected, (state) => {
        state.customShipping.create.status = 'error';
      })
      .addCase(createCustomShippingAction.fulfilled, (state, action) => {
        state.customShipping.create.status = 'success';
        state.customShipping.detail.data = action.payload;
        state.customShipping.list.data =
          state.customShipping.list.data === null
            ? null
            : [...state.customShipping.list.data, action.payload];
      });

    builder
      .addCase(requestOcaRegistrationAction.pending, (state) => {
        state.requestOcaRegistration.status = 'loading';
      })
      .addCase(requestOcaRegistrationAction.rejected, (state) => {
        state.requestOcaRegistration.status = 'error';
      })
      .addCase(requestOcaRegistrationAction.fulfilled, (state) => {
        state.availableApps.list.data = initialState.availableApps.list.data;
        state.requestOcaRegistration.status = 'success';
      });

    builder
      .addCase(deletePhysicalLocationAction.pending, (state) => {
        state.physicalLocations.deleteStatus = 'loading';
      })
      .addCase(deletePhysicalLocationAction.rejected, (state) => {
        state.physicalLocations.deleteStatus = 'error';
      })
      .addCase(deletePhysicalLocationAction.fulfilled, (state, action) => {
        const data = state.physicalLocations.data;

        if (data) {
          state.physicalLocations.data = data?.filter(
            (obj) => obj.id !== action.payload.id,
          );
        }
        state.physicalLocations.deleteStatus = 'success';
      });

    builder
      .addCase(fetchPhysicalLocationsAction.pending, (state) => {
        state.physicalLocations.getStatus = 'loading';
      })
      .addCase(fetchPhysicalLocationsAction.rejected, (state) => {
        state.physicalLocations.getStatus = 'error';
      })
      .addCase(fetchPhysicalLocationsAction.fulfilled, (state, action) => {
        state.physicalLocations.getStatus = 'success';
        state.physicalLocations.data = action.payload;
      });

    builder
      .addCase(updatePhysicalLocationAction.pending, (state) => {
        state.physicalLocations.updateStatus = 'loading';
      })
      .addCase(updatePhysicalLocationAction.rejected, (state) => {
        state.physicalLocations.updateStatus = 'error';
      })
      .addCase(updatePhysicalLocationAction.fulfilled, (state, action) => {
        state.physicalLocations.data =
          state.physicalLocations.data?.map((location) => {
            if (location.id === action.payload.id) {
              return action.payload;
            }
            return location;
          }) || null;

        state.physicalLocations.updateStatus = 'success';
      });

    builder
      .addCase(createPhysicalLocationAction.pending, (state) => {
        state.physicalLocations.createStatus = 'loading';
      })
      .addCase(createPhysicalLocationAction.rejected, (state) => {
        state.physicalLocations.createStatus = 'error';
      })
      .addCase(createPhysicalLocationAction.fulfilled, (state, action) => {
        state.physicalLocations.data?.push(action.payload);
        state.physicalLocations.createStatus = 'success';
      })
      .addCase(fetchCustomShipping.pending, (state) => {
        state.customShipping.list.status = 'loading';
      })
      .addCase(fetchCustomShipping.rejected, (state) => {
        state.customShipping.list.status = 'error';
      })
      .addCase(fetchCustomShipping.fulfilled, (state, action) => {
        state.customShipping.list.status = 'success';
        state.customShipping.list.data = action.payload;
      });

    builder
      .addCase(deleteCustomShippingAction.pending, (state) => {
        state.customShipping.delete.status = 'loading';
      })
      .addCase(deleteCustomShippingAction.rejected, (state) => {
        state.customShipping.delete.status = 'error';
      })
      .addCase(deleteCustomShippingAction.fulfilled, (state, action) => {
        state.customShipping.delete.status = 'success';
        state.customShipping.list.data =
          state.customShipping.list.data?.filter(
            (item) => item.id !== action.meta.arg,
          ) || null;
      });

    builder
      .addCase(fetchDetailCustomShipping.pending, (state) => {
        state.customShipping.detail.status = 'loading';
        state.customShipping.detail.data =
          initialState.customShipping.detail.data;
      })
      .addCase(fetchDetailCustomShipping.rejected, (state) => {
        state.customShipping.detail.status = 'error';
      })
      .addCase(fetchDetailCustomShipping.fulfilled, (state, action) => {
        state.customShipping.detail.status = 'success';
        state.customShipping.detail.data = action.payload;
      });

    builder
      .addCase(updateDetailCustomShippingAction.pending, (state) => {
        state.customShipping.update.status = 'loading';
      })
      .addCase(updateDetailCustomShippingAction.rejected, (state) => {
        state.customShipping.update.status = 'error';
      })
      .addCase(updateDetailCustomShippingAction.fulfilled, (state, action) => {
        state.customShipping.update.status = 'success';
        state.customShipping.detail.data = action.payload;
        state.customShipping.list.data =
          state.customShipping.list.data === null
            ? null
            : state.customShipping.list.data.map((currentItem) => {
                if (currentItem.id === action.payload.id) {
                  return action.payload;
                }
                return currentItem;
              });
      });

    builder
      .addCase(fetchInternationalShipping.pending, (state) => {
        state.internationalShipping.list.status = 'loading';
      })
      .addCase(fetchInternationalShipping.rejected, (state) => {
        state.internationalShipping.list.status = 'error';
      })
      .addCase(fetchInternationalShipping.fulfilled, (state, action) => {
        state.internationalShipping.list.status = 'success';
        state.internationalShipping.list.data = action.payload;
      });

    builder
      .addCase(deleteInternationalShipping.pending, (state) => {
        state.internationalShipping.delete.status = 'loading';
      })
      .addCase(deleteInternationalShipping.rejected, (state) => {
        state.internationalShipping.delete.status = 'error';
      })
      .addCase(deleteInternationalShipping.fulfilled, (state, action) => {
        state.internationalShipping.delete.status = 'success';
        state.internationalShipping.list.data =
          state.internationalShipping.list.data?.filter(
            (item) => item.id !== action.payload.id,
          ) || null;
      });

    builder
      .addCase(createInternationalShipping.pending, (state) => {
        state.internationalShipping.create.status = 'loading';
      })
      .addCase(createInternationalShipping.rejected, (state) => {
        state.internationalShipping.create.status = 'error';
      })
      .addCase(createInternationalShipping.fulfilled, (state, action) => {
        state.internationalShipping.create.status = 'success';
        state.internationalShipping.detail.data = action.payload;
        if (state.internationalShipping.list.data) {
          state.internationalShipping.list.data = [
            ...state.internationalShipping.list.data,
            action.payload,
          ];
        }
      });

    builder
      .addCase(fetchDetailInternationalShipping.pending, (state) => {
        state.internationalShipping.detail.status = 'loading';
      })
      .addCase(fetchDetailInternationalShipping.rejected, (state) => {
        state.internationalShipping.detail.status = 'error';
      })
      .addCase(fetchDetailInternationalShipping.fulfilled, (state, action) => {
        state.internationalShipping.detail.status = 'success';
        state.internationalShipping.detail.data = action.payload;
      });

    builder
      .addCase(updateInternationalShippingDetail.pending, (state) => {
        state.internationalShipping.update.status = 'loading';
      })
      .addCase(updateInternationalShippingDetail.rejected, (state) => {
        state.internationalShipping.update.status = 'error';
      })
      .addCase(updateInternationalShippingDetail.fulfilled, (state, action) => {
        state.internationalShipping.update.status = 'success';
        state.internationalShipping.detail;
        state.internationalShipping.detail.data = action.payload;
        state.internationalShipping.list.data =
          state.internationalShipping.list.data?.map((currentItem) => {
            if (currentItem.id === action.payload.id) {
              return action.payload;
            }
            return currentItem;
          }) || null;
      });

    builder
      .addCase(deleteActiveShippingMethodsAction.pending, (state) => {
        state.activeShippingMethods.delete.status = 'loading';
      })
      .addCase(deleteActiveShippingMethodsAction.rejected, (state) => {
        state.activeShippingMethods.delete.status = 'error';
      })
      .addCase(deleteActiveShippingMethodsAction.fulfilled, (state, action) => {
        state.activeShippingMethods.delete.status = 'success';
        state.activeShippingMethods.list.data =
          state.activeShippingMethods.list.data?.filter(
            (item) => item.id !== action.payload.id,
          ) || null;

        state.availableApps = initialState.availableApps;
      });

    builder.addCase(getOcaAgenciesAction.fulfilled, (state, action) => {
      state.ocaAgencies.zipcode = action.meta.arg;
      state.ocaAgencies.data = action.payload;
    });

    builder
      .addCase(getOcaConfigurationAction.pending, (state) => {
        state.ocaConfiguration.detail.status = 'loading';
        state.ocaConfiguration.detail.data =
          initialState.ocaConfiguration.detail.data;
      })
      .addCase(getOcaConfigurationAction.rejected, (state) => {
        state.ocaConfiguration.detail.status = 'error';
      })
      .addCase(getOcaConfigurationAction.fulfilled, (state, action) => {
        if (action.payload) state.ocaConfiguration.detail.data = action.payload;
        state.ocaConfiguration.detail.status = 'success';
      });

    builder
      .addCase(createOcaConfigurationAction.pending, (state) => {
        state.ocaConfiguration.create.status = 'loading';
      })
      .addCase(createOcaConfigurationAction.rejected, (state) => {
        state.ocaConfiguration.create.status = 'error';
      })
      .addCase(createOcaConfigurationAction.fulfilled, (state, action) => {
        state.ocaConfiguration.detail.data = action.payload;
        state.activeShippingMethods.list.data =
          initialState.activeShippingMethods.list.data;
        state.ocaConfiguration.create.status = 'success';
      });

    builder
      .addCase(updateOcaConfigurationAction.pending, (state) => {
        state.ocaConfiguration.update.status = 'loading';
      })
      .addCase(updateOcaConfigurationAction.rejected, (state) => {
        state.ocaConfiguration.update.status = 'error';
      })
      .addCase(updateOcaConfigurationAction.fulfilled, (state, action) => {
        state.ocaConfiguration.detail.data = action.payload;
        state.activeShippingMethods.list.data =
          initialState.activeShippingMethods.list.data;
        state.ocaConfiguration.update.status = 'success';
      });

    builder
      .addCase(getCorreiosContractTypesAction.pending, (state) => {
        state.correios.contract.list.status = 'loading';
      })
      .addCase(getCorreiosContractTypesAction.rejected, (state) => {
        state.correios.contract.list.status = 'error';
      })
      .addCase(getCorreiosContractTypesAction.fulfilled, (state, action) => {
        state.correios.contract.list.status = 'success';
        state.correios.contract.list.data = action.payload;
      });

    builder
      .addCase(getCorreiosDetailAction.pending, (state) => {
        state.correios.contract.detail.status = 'loading';
        state.correios.shippingModalities.detail.status = 'loading';
      })
      .addCase(getCorreiosDetailAction.rejected, (state) => {
        state.correios.contract.detail.status = 'error';
        state.correios.shippingModalities.detail.status = 'error';
      })
      .addCase(
        getCorreiosDetailAction.fulfilled,
        (state, { payload: { options, account, additionalServices } }) => {
          state.correios.contract.detail.status = 'success';
          state.correios.shippingModalities.detail.status = 'success';
          state.correios.contract.detail.data = {
            ...account,
            additionalServices,
          };
          state.correios.shippingModalities.detail.data = options.reduce(
            (acc, modality) => ({ ...acc, [modality.code]: modality }),
            {},
          );
        },
      );

    builder
      .addCase(updateCorreiosShippingModalitiesAction.pending, (state) => {
        state.correios.shippingModalities.create.status = 'loading';
      })
      .addCase(updateCorreiosShippingModalitiesAction.rejected, (state) => {
        state.correios.shippingModalities.create.status = 'error';
      })
      .addCase(
        updateCorreiosShippingModalitiesAction.fulfilled,
        (state, action) => {
          state.correios.shippingModalities.create.status = 'success';
          //To Refresh list:
          state.activeShippingMethods.list.data = null;
          state.correios.shippingModalities.detail.data = action.payload.reduce(
            (acc, modality) => ({ ...acc, [modality.code]: modality }),
            {},
          );
        },
      );

    builder
      .addCase(updateCorreiosContractAction.pending, (state) => {
        state.correios.contract.create.status = 'loading';
      })
      .addCase(updateCorreiosContractAction.rejected, (state) => {
        state.correios.contract.create.status = 'error';
      })
      .addCase(updateCorreiosContractAction.fulfilled, (state, action) => {
        state.correios.contract.create.status = 'success';
        state.correios.contract.detail.data = action.payload;
      });

    builder
      .addCase(getDetailActiveShippingAction.pending, (state) => {
        state.activeShippingMethods.detail.status = 'loading';
      })
      .addCase(getDetailActiveShippingAction.rejected, (state) => {
        state.activeShippingMethods.detail.status = 'error';
      })
      .addCase(getDetailActiveShippingAction.fulfilled, (state, action) => {
        state.activeShippingMethods.detail.status = 'success';
        state.activeShippingMethods.detail.data = action.payload;
      });

    builder
      .addCase(updateActiveShippingAction.pending, (state) => {
        state.activeShippingMethods.update.status = 'loading';
      })
      .addCase(updateActiveShippingAction.rejected, (state) => {
        state.activeShippingMethods.update.status = 'error';
      })
      .addCase(updateActiveShippingAction.fulfilled, (state, action) => {
        state.activeShippingMethods.update.status = 'success';
        state.activeShippingMethods.detail.data = action.payload;
      });

    builder.addCase(getShippingMethodsAction.pending, (state) => {
      state.shippingMethods.status = 'loading';
    });
    builder.addCase(getShippingMethodsAction.fulfilled, (state, action) => {
      state.shippingMethods.status = 'success';
      state.shippingMethods.data = action.payload;
    });
    builder.addCase(getShippingMethodsAction.rejected, (state) => {
      state.shippingMethods.status = 'error';
    });
    builder
      .addCase(enableCarrierAction.pending, (state) => {
        state.activeShippingMethods.list.status = 'loading';
      })
      .addCase(enableCarrierAction.fulfilled, (state) => {
        state.activeShippingMethods.list.status = 'success';
        state.activeShippingMethods = initialState.activeShippingMethods;
        state.availableApps = initialState.availableApps;
      });

    builder
      .addCase(getNuvemEnvioConfigsAction.pending, (state) => {
        state.nuvemEnvioConfigs.status = 'loading';
      })
      .addCase(getNuvemEnvioConfigsAction.fulfilled, (state, action) => {
        state.nuvemEnvioConfigs.status = 'success';
        state.nuvemEnvioConfigs.data = action.payload;
      })
      .addCase(getNuvemEnvioConfigsAction.rejected, (state) => {
        state.nuvemEnvioConfigs.status = 'error';
      });

    builder
      .addCase(getAddressingConfigurationRulesAction.pending, (state) => {
        state.addressingConfiguration.getStatus = 'loading';
      })
      .addCase(
        getAddressingConfigurationRulesAction.fulfilled,
        (state, action) => {
          state.addressingConfiguration.getStatus = 'success';
          state.addressingConfiguration.data = action.payload;
        },
      )
      .addCase(getAddressingConfigurationRulesAction.rejected, (state) => {
        state.addressingConfiguration.getStatus = 'error';
      });

    builder
      .addCase(saveAddressingConfigurationRulesAction.pending, (state) => {
        state.addressingConfiguration.saveStatus = 'loading';
      })
      .addCase(
        saveAddressingConfigurationRulesAction.fulfilled,
        (state, action) => {
          state.addressingConfiguration.saveStatus = 'success';
          const rules = state.addressingConfiguration.data.map((rule) => {
            const newRule = action.meta.arg.find(
              (newRule) => newRule.code === rule.code,
            );
            rule.enabled = newRule?.enabled || false;
            rule.priority = newRule?.priority || 0;
            return rule;
          });

          state.addressingConfiguration.data = rules;
        },
      )
      .addCase(saveAddressingConfigurationRulesAction.rejected, (state) => {
        state.addressingConfiguration.saveStatus = 'error';
      });
  },
});

export const { reducer } = deliveryMethodsSlice;
export const {
  cleanCreateCustomShippingStatus,
  cleanDeleteCustomShippingStatus,
  cleanCreatePhysicalLocationStatus,
  cleanGetPhysicalLocationStatus,
  cleanUpdatePhysicalLocationStatus,
  cleanDeletePhysicalLocationStatus,
  cleanDeleteInternationalShippingStatus,
  cleanNewInternationalShippingStatus,
  cleanUpdateInternationalShippingDetail,
  cleanOcaConfigurationCreateStatus,
  cleanOcaConfigurationUpdateStatus,
  cleanUpdateCustomShippingStatus,
  cleanDeleteActiveShippingStatus,
  cleanUpdateCorreiosContractStatus,
  cleanUpdateCorreiosShippingModalitiesStatus,
  cleanUpdateDetailActiveAppStatus,
  cleanUpdateFallbackStatus,
  cleanSaveAddressingConfigurationRulesStatus,
} = deliveryMethodsSlice.actions;
