import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  Tag,
  Text,
  Link,
} from '@nimbus-ds/components';
import {
  CheckIcon,
  CopyIcon,
  InfoCircleIcon,
  LinkOffIcon,
} from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { QUITO_FACEBOOK_V2 } from 'App/featuresFlags';
import { Stack } from 'commons/components';
import { useClipboard } from 'commons/hooks';
import { useGetStoreInfo, useGetTags } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

const OLD_FACEBOOK_PATH = 'admin/facebook';

interface LinkedFacebookPixelCodeProps {
  onClick: () => void;
  fbPixelCode: string;
  hasFbConfigured: boolean;
  isDeleting: boolean;
}

export function LinkedFacebookPixelCode({
  fbPixelCode,
  hasFbConfigured,
  onClick,
  isDeleting,
}: Readonly<LinkedFacebookPixelCodeProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.facebook.fbPixel.linked',
  );
  const { url } = useGetStoreInfo();

  const copyToClipboard = useClipboard();

  const catalogUrl = `${url}/fb_catalog.xml`;

  const handleClickCopy = () => {
    copyToClipboard(catalogUrl, t('copied'));
  };

  const tags = useGetTags();

  const showUnlink = !(hasFbConfigured && tags.includes(QUITO_FACEBOOK_V2));

  return (
    <Card padding="small">
      <Stack column align="stretch">
        <Tag appearance="success">
          <CheckIcon />
          <Text fontSize="caption">{t('tag')}</Text>
        </Tag>
        <Stack column align="stretch" spacing="tight">
          <Text fontSize="highlight">
            <Trans
              t={t}
              i18nKey="fbPixelCode"
              values={{ fbPixelCode }}
              components={{
                bold: <strong />,
              }}
            />
          </Text>
          <Box
            display="grid"
            gridTemplateColumns="1fr auto"
            gap="2"
            alignItems="center"
          >
            <Text
              wordBreak="break-word"
              fontSize="highlight"
              lineHeight="highlight"
            >
              {t('catalogUrl', {
                catalogUrl,
                interpolation: {
                  escapeValue: false,
                },
              })}
            </Text>
            <Icon
              onClick={handleClickCopy}
              cursor="pointer"
              source={<CopyIcon />}
            />
          </Box>
        </Stack>
        {showUnlink ? (
          <Button onClick={onClick} appearance="neutral" disabled={isDeleting}>
            {isDeleting ? (
              <Spinner color="currentColor" size="small" />
            ) : (
              <LinkOffIcon />
            )}
            {t('unlinked')}
          </Button>
        ) : (
          <Stack.Item>
            <Stack spacing="tight">
              <InfoCircleIcon />
              <Text fontSize="base">
                <Trans
                  t={t}
                  i18nKey="unlinkV2"
                  components={{
                    a: (
                      <Link as="a" href={OLD_FACEBOOK_PATH}>
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Stack>
          </Stack.Item>
        )}
      </Stack>
    </Card>
  );
}
