import { ChangeEvent } from 'react';
import { Modal } from '@nimbus-ds/components';
import {
  EnableCountryRequestDto,
  EnabledCountriesResponseDto,
} from '@tiendanube/common';
import { ErrorScreen } from 'commons/components';
import CancelAndConfirmButtons from 'commons/components/CancelAndConfirmButtons';
import { useForm } from 'commons/hooks';
import useEnabledCountries from 'domains/Configurations/hooks/useEnabledCountries';
import { useGetEnabledCountriesListQuery } from 'domains/Configurations/I18n/i18nApi';
import { enabledCountriesId } from 'domains/Configurations/I18n/utils';
import { buildEnableCountrySchema } from 'domains/Configurations/I18n/utils/buildEnableCountrySchema';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import SelectsList from '../EnableCountryModal/SelectsList';

interface UpdateCountryModalProps {
  onUpdate: (data: EnableCountryRequestDto) => void;
  onClose: () => void;
  enabledCountry: EnabledCountriesResponseDto;
  isEnabledCountry: boolean;
  isLoading: boolean;
}

function UpdateCountryModal({
  onUpdate,
  onClose,
  enabledCountry,
  isEnabledCountry,
  isLoading,
}: UpdateCountryModalProps) {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const enabledCountriesIds = useGetEnabledCountriesListQuery(undefined, {
    selectFromResult: ({ data }) => enabledCountriesId(data),
  });

  const {
    isLoading: loadingList,
    isSuccess,
    isError,
    onRefetch,
  } = useEnabledCountries();
  const idsWithoutCurrentEnabledCountry =
    enabledCountriesIds.countriesId?.filter(
      (id) => enabledCountry.countryId !== id,
    );

  const { handleOnChange, handleOnSubmit, errors } = useForm({
    initialValues: enabledCountry,
    validationSchema: buildEnableCountrySchema(idsWithoutCurrentEnabledCountry),
    onSubmit: ({ countryId, currencyId, languageId }) => {
      onUpdate({
        countryId,
        currencyId,
        languageId,
      });
    },
  });

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLSelectElement>) => {
    handleOnChange({ name, value });
  };

  const title = isEnabledCountry
    ? t('updateEnabledCountryModal.mainTitle')
    : t('updateEnabledCountryModal.title');

  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header title={title} />
      {loadingList && <SelectsList.Skeleton />}
      {isError && (
        <Modal.Body padding="none">
          <ErrorScreen onRetry={onRefetch} description={t('errorState')} />
        </Modal.Body>
      )}

      {isSuccess && (
        <>
          <SelectsList
            handleChange={handleChange}
            mainCountry={enabledCountry}
            errors={errors}
          />
          <Modal.Footer>
            <CancelAndConfirmButtons
              isLoading={isLoading}
              isCancelDisabled={isLoading}
              confirmText={t('buttonSave')}
              onCancel={onClose}
              onConfirm={handleOnSubmit}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default UpdateCountryModal;
