import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent, HeaderTop } from 'commons/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { TemplateList } from './components';

interface TemplateModalProps {
  isShow: boolean;
  onClose: () => void;
}

function TemplateModal({ isShow, onClose }: TemplateModalProps) {
  const t = useTranslationOnline('contentPages.formPage');

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('createPage')} />}
    >
      {isShow && <TemplateList onClose={onClose} />}
    </ModalAside>
  );
}

export default TemplateModal;
