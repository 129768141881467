import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';

const COMMON_USER_INITIAL_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  appRoles: [],
  password: '',
  confirmPassword: '',
};

export const NEW_USER_INITIAL_VALUES: Record<
  'editAvailable' | 'editUnavailable',
  CreateUserRequestDto
> = {
  editAvailable: {
    ...COMMON_USER_INITIAL_VALUES,
    roles: [],
  },
  editUnavailable: {
    ...COMMON_USER_INITIAL_VALUES,
    roles: ['full'],
  },
};

export const EDIT_USER_INITIAL_VALUES: UpdateUserRequestDto = {
  ...COMMON_USER_INITIAL_VALUES,
  roles: [],
  currentPassword: '',
};
