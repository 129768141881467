import { Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { DataList, Text, IconButton } from '@tiendanube/components';
import { MailIcon } from '@tiendanube/icons';
import SendWhatsappIconButton from 'commons/components/SendWhatsappIconButton';
import Stack from 'commons/components/Stack';
import { useModal } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import { dateFormat, Format } from 'commons/utils/date';
import { SendEmailModal } from 'domains/Customers/components';
import { useCustomerListItem } from 'domains/Customers/Customers/pages/CustomerListPage/hooks';

const { Row, Cell } = DataList;

interface CustomerListItemProps {
  id: string;
}

function CustomerListItem({ id }: CustomerListItemProps): JSX.Element | null {
  const { push } = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation([DomainEnum.CUSTOMERS]);
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  const { customer } = useCustomerListItem(id);

  if (!customer) return null;

  const totalSpend = formatCurrency(
    Number(customer.total_spent.amount),
    customer.total_spent.currency,
    language,
  );

  const goToCustomerDetails = () => {
    push(`/customers/${customer.id}`);
  };

  const goToLastOrderDetails = () => {
    push(`/orders/${customer.last_order_id}`);
  };

  const handleOnClickSendEmail = () => openSendEmailModal();

  return (
    <>
      <Row key={id} id={id}>
        <Cell rowTitle width={70} trimText>
          <Text block appearance="primary" trimText trimLines={1}>
            <Link
              appearance="primary"
              onClick={goToCustomerDetails}
              textDecoration="none"
            >
              {customer.name}
            </Link>
          </Text>
        </Cell>
        <Cell width={30} alignRight>
          <Stack spacing="tight">
            <Stack.Item fill>
              <IconButton
                ariaLabel={customer.email}
                icon={MailIcon}
                onClick={handleOnClickSendEmail}
              />
            </Stack.Item>
            {!!customer.phone && (
              <SendWhatsappIconButton
                phone={customer.phone}
                message={t('tableRow.waText', {
                  clientName: customer.name,
                })}
              />
            )}
          </Stack>
        </Cell>
        <Cell width={45}>
          <Stack gap="1">
            {!!customer.lastOrder?.orderNumber && (
              <Link
                appearance="primary"
                onClick={goToLastOrderDetails}
                textDecoration="none"
              >
                #{customer.lastOrder.orderNumber}
              </Link>
            )}
            {!!customer.lastOrder?.completedAt && (
              <Text>
                {dateFormat(customer.lastOrder.completedAt, Format.DD_MM_YYYY)}
              </Text>
            )}
          </Stack>
        </Cell>
        <Cell width={55} alignRight>
          <Text textAlign="right">{`${t('tableRow.totalConsume', {
            total: totalSpend,
          })}`}</Text>
        </Cell>
      </Row>
      {showSendEmailModal && (
        <SendEmailModal
          email={customer.email}
          customerId={String(customer.id)}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

export default CustomerListItem;
