import { useCallback, useMemo } from 'react';
import { ChargeDto, ConceptCode, TaxDto } from '@tiendanube/common';
import { combineStatus } from 'commons/utils/combineStatus';
import useGetRecurrentPaymentConcepts from './useGetRecurrentPaymentConcepts';
import useGetSubscriptions from './useGetSubscriptions';
import { isPayableCharge } from './utils';
import { ChargeDisplayInterface } from '../pages/components/PaymentDataContent/PaymentDataContent';
import { usePendingCharges } from '../pages/PlansAndSubscriptions/hooks';

const CONCEPT_CODES_TO_PAY = ['plan-cost', 'app-cost'];

function useChargesToPay(initialFetch = true) {
  const { charges, refreshPendingCharges, ...getCharges } =
    usePendingCharges(initialFetch);
  const { subscriptions, refreshSubscriptions, ...getSubscriptions } =
    useGetSubscriptions(initialFetch);
  const {
    recurrentPaymentForConcept,
    refreshRecurrentPaymentConcepts,
    ...getRecurrentPaymentConcepts
  } = useGetRecurrentPaymentConcepts(initialFetch);

  const refreshChargesToPay = useCallback(() => {
    refreshPendingCharges();
    refreshSubscriptions();
    refreshRecurrentPaymentConcepts();
  }, [
    refreshPendingCharges,
    refreshSubscriptions,
    refreshRecurrentPaymentConcepts,
  ]);

  const getSubscriptionForCharge = useCallback(
    (charge: { description: string }) =>
      subscriptions?.find(
        (sub) =>
          charge.description === sub.description ||
          charge.description === sub.externalReference,
      ),
    [subscriptions],
  );

  /**
   * This function takes a list of charges and provides them with data that's necessary
   * to display them correctly.
   */
  const transformChargesToDisplayable = useCallback(
    (charges: ChargeDto[]): ChargeDisplayInterface[] =>
      charges?.map((charge) => {
        const subscription = getSubscriptionForCharge(charge);
        return {
          ...charge,
          recurringInterval: subscription?.recurringInterval,
          recurringFrequency: subscription?.recurringFrequency,
        };
      }),
    [getSubscriptionForCharge],
  );

  const chargesToPay = useMemo(
    (): ChargeDto[] | undefined =>
      charges?.filter(
        ({ conceptCode, status, metadata }) =>
          CONCEPT_CODES_TO_PAY.includes(conceptCode) &&
          !recurrentPaymentForConcept(conceptCode, metadata?.appId) &&
          isPayableCharge(status),
      ),
    [charges, recurrentPaymentForConcept],
  );

  const chargesToSelect = useMemo(
    (): ChargeDto[] | undefined =>
      charges?.filter(
        ({ conceptCode, metadata }) =>
          CONCEPT_CODES_TO_PAY.includes(conceptCode) &&
          !recurrentPaymentForConcept(conceptCode, metadata?.appId),
      ),
    [charges, recurrentPaymentForConcept],
  );

  const chargeTotalWithoutTaxes = ({
    amountValue,
    taxes,
  }: {
    amountValue: number;
    taxes: TaxDto[];
  }) =>
    Number(amountValue) -
    taxes.reduce((acc, { amountValue }) => acc + Number(amountValue), 0);

  const totalWithoutTaxes = useMemo(
    () =>
      chargesToPay?.reduce(
        (acc, charge) => acc + chargeTotalWithoutTaxes(charge),
        0,
      ),
    [chargesToPay],
  );

  const currency = chargesToPay?.[0]?.amountCurrency;

  const isAvailableForPayment = useCallback(
    (conceptCode: ConceptCode, externalReference?: string) =>
      chargesToPay?.some(
        (charge) =>
          charge.conceptCode === conceptCode &&
          (!externalReference ||
            charge.subscription?.externalReference === externalReference),
      ),
    [chargesToPay],
  );

  return {
    ...combineStatus(
      getCharges.status,
      getSubscriptions.status,
      getRecurrentPaymentConcepts.status,
    ),
    chargesToPay,
    transformChargesToDisplayable,
    chargeTotalWithoutTaxes,
    totalWithoutTaxes,
    currency,
    refreshChargesToPay,
    chargesToSelect,
    isAvailableForPayment,
  };
}

export default useChargesToPay;
