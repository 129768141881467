import { Box, Checkbox } from '@nimbus-ds/components';
import { BaseCardCollapsible, TabsMultiLanguage } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { BuyerNoteCardInput } from './components';
import { SettingsCheckoutFormProps } from '../../types';

export function BuyerNoteCard({
  values: { checkoutShowNote, checkoutMandatoryNote, checkoutNoteLabel },
  onChange,
  onBlur,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.buyerNoteCard',
  );

  const handleChangeTogle = () => {
    onChange({ name: 'checkoutShowNote', value: !checkoutShowNote });
  };

  const handleChange = ({ target: { name, checked } }) => {
    onChange({ name, value: checked });
  };

  return (
    <BaseCardCollapsible
      title={t('title')}
      subtitle={t('subTitle')}
      active={checkoutShowNote}
      onChange={handleChangeTogle}
      appearance="toggle"
      paddingBody="base"
      alignSwitch="flex-start"
    >
      {checkoutShowNote && (
        <Box display="flex" flexDirection="column">
          <TabsMultiLanguage>
            <BuyerNoteCardInput
              value={checkoutNoteLabel}
              onChange={onChange}
              onBlur={onBlur}
            />
          </TabsMultiLanguage>
          <Checkbox
            checked={checkoutMandatoryNote}
            name="checkoutMandatoryNote"
            label={t('checkoutMandatoryNote')}
            onChange={handleChange}
          />
        </Box>
      )}
    </BaseCardCollapsible>
  );
}
