import { ChangeEvent, useMemo, useState } from 'react';
import { Box, Button, Chip, Icon, Input, Title } from '@nimbus-ds/components';
import { SearchIcon, TagIcon } from '@nimbus-ds/icons';
import { InteractiveList, EmptyMessage } from '@nimbus-ds/patterns';
import { CategoryTabedResponseDto } from '@tiendanube/common';
import { Text } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useNavegate } from 'App/hooks';
import {
  ErrorScreen,
  InteractiveListRightCheckbox,
  Repeater,
  Stack,
} from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { catalogRoutes } from 'domains/Catalog';
import { useGetCategoriesOptionsQuery } from 'domains/Catalog/Categories/categoriesApi';
import { HiddenCategoryTag } from 'domains/Catalog/Categories/components';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import { CategoriesInterface } from './Categories';
import { useTranslationMarketing } from '../hook';
import './CategoriesModalAside.scss';

interface CategoriesCheckedInterface extends CategoryTabedResponseDto {
  checked: boolean;
}

interface CategoriesModalAsideProps {
  isOpen: boolean;
  disabled: boolean;
  selected: CategoriesInterface[];
  title: string;
  subtitle?: string;
  emptyTitle: string;
  emptyText: string;
  ctaText: string;
  onChange: (methods: CategoriesInterface[]) => void;
  onClose: () => void;
}

const skeleton = () => (
  <InteractiveListRightCheckbox.Skeleton avoidHorizontalPadding />
);

export function CategoriesModalAside({
  isOpen,
  selected,
  title,
  subtitle,
  emptyTitle,
  emptyText,
  ctaText,
  onChange,
  onClose,
  disabled,
}: Readonly<CategoriesModalAsideProps>) {
  const { ref } = useAutoFocus();
  const t = useTranslationMarketing();
  const { data, isLoading, isError, isSuccess, refetch } =
    useGetCategoriesOptionsQuery(undefined, { skip: !isOpen });

  const [search, setSearch] = useState('');
  const searchLowerCase = search.toLowerCase();
  const categoriesStore: CategoriesCheckedInterface[] = useMemo(
    () =>
      (data || [])
        .filter(
          ({ title }) =>
            !search || title.toLowerCase().includes(searchLowerCase),
        )
        .map((currentData) => ({
          ...currentData,
          checked: selected.some((current) => current.id === currentData.id),
        })),
    [data, search, searchLowerCase, selected],
  );

  const handleCheckedCategory = (
    category: CategoriesInterface,
    checked: boolean,
  ) => {
    if (checked) {
      onChange([...selected, category]);
    } else {
      onChange(selected.filter((current) => current.id !== category.id));
    }
  };

  const handleOnChangeSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
  };

  const isEmpty = isSuccess && categoriesStore.length === 0 && !search;

  const { goTo } = useNavegate();
  const goToCategoriesPage = () => {
    onClose();
    goTo(catalogRoutes.categoriesList);
  };

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={!isEmpty && <Title fontSize="h2">{title}</Title>}
    >
      <Stack innerRef={ref} column align="stretch">
        {isError && <ErrorScreen onRetry={refetch} />}
        {isEmpty && (
          <div className="stratus-empty-category-modal">
            <EmptyMessage
              title={emptyTitle}
              text={emptyText}
              icon={<TagIcon size="large" />}
              actions={
                <Button appearance="primary" onClick={goToCategoriesPage}>
                  {ctaText}
                </Button>
              }
            />
          </div>
        )}
        {isLoading && (
          <InteractiveList>
            <Repeater times={10} item={skeleton} />
          </InteractiveList>
        )}
        {isSuccess && !isEmpty && (
          <>
            {!!subtitle && (
              <Stack.Item>
                <Text> {subtitle} </Text>
              </Stack.Item>
            )}
            <Input
              value={search}
              onChange={handleOnChangeSearch}
              append={<Icon source={<SearchIcon size="small" />} />}
              appendPosition="start"
              placeholder={t('common.search')}
            />
            {selected.length > 0 && (
              <Chip
                text={t(
                  'freeShipping.asides.categories.selector.amountSelected',
                  { count: selected.length },
                )}
                removable={!disabled}
                onClick={() => onChange([])}
              />
            )}
            <div
              style={{
                marginLeft: '-1rem',
                marginRight: '-1rem',
              }}
            >
              <InteractiveList>
                {categoriesStore.map(({ id, fixed, checked, visibility }) => {
                  const title = fixed ? `${fixed.prefix}${fixed.title}` : '';
                  const handleChange = () => {
                    handleCheckedCategory(
                      {
                        id,
                        description: title,
                        visibility,
                      },
                      !checked,
                    );
                  };
                  return (
                    <InteractiveListRightCheckbox
                      key={id}
                      checkbox={{
                        name: `method_${id}`,
                        checked: checked,
                        onChange: handleChange,
                      }}
                      titleSlot={
                        <Box display="flex" gap="2" alignItems="center">
                          <Text>{title}</Text>
                          {!isCategoryVisible({ visibility }) && (
                            <HiddenCategoryTag />
                          )}
                        </Box>
                      }
                    />
                  );
                })}
              </InteractiveList>
            </div>
          </>
        )}
      </Stack>
    </ModalAside>
  );
}
