import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RecoveryCodesResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useVerifyAuthenticationFactor } from 'domains/AuthenticationFactor/hooks';
import {
  fetchRecoveryCodes as fetchRecoveryCodesAction,
  recoveryCodesData,
  recoveryCodesStatus,
} from '../../accountSlice';

interface UseRecoveryCodesResult {
  fetch: (totpCode: string) => Promise<boolean>;
  recoveryCodes: RecoveryCodesResponseDto[];
  isLoading: boolean;
  isError: boolean;
}

function useRecoveryCodes(): UseRecoveryCodesResult {
  const { verify, isLoading, isError } = useVerifyAuthenticationFactor();
  const { status } = useSelector(recoveryCodesStatus);
  const { data: recoveryCodes } = useSelector(recoveryCodesData);
  const dispatch = useAppDispatch();
  const fetch = useCallback(
    async (totpCode: string) => {
      const codeIsValid = await verify(totpCode, {});
      if (!codeIsValid) return false;
      const response = await dispatch(fetchRecoveryCodesAction());
      return fetchRecoveryCodesAction.rejected.type !== response.type;
    },
    [dispatch, verify],
  );

  return {
    fetch,
    recoveryCodes,
    isLoading: status === 'loading' || isLoading,
    isError: status === 'error' || isError,
  };
}

export default useRecoveryCodes;
