import { ChangeEvent, useEffect, useMemo } from 'react';
import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NavigationMenuItemDataDto } from '@tiendanube/common';
import { useGetMenusQuery } from 'domains/Online/Menus/MenusApi';

interface SelectMenuProps {
  selected?: string;
  currentMenuId?: string;
  onSelect: (data: NavigationMenuItemDataDto) => void;
}

function SelectMenu({ onSelect, selected, currentMenuId }: SelectMenuProps) {
  const { data, isLoading } = useGetMenusQuery();

  const menus = useMemo(
    () =>
      data && data?.navigations.length > 0
        ? data?.navigations.filter((menu) => menu.id !== currentMenuId)
        : [],
    [data, currentMenuId],
  );

  const handleSelect = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) => {
    onSelect({ id: value });
  };

  useEffect(() => {
    if (menus.length > 0) {
      onSelect({ id: selected || menus[0].id });
    }
  }, [menus, onSelect, selected]);

  if (isLoading) return <Select.Skeleton width="100%" />;

  return menus.length > 0 ? (
    <FormField.Select
      id="selected-menu"
      name="selected-menu"
      onChange={handleSelect}
      defaultValue={selected}
      value={selected}
    >
      {menus.map(({ id, name }) => (
        <Select.Option key={id} label={name} value={id} />
      ))}
    </FormField.Select>
  ) : null;
}

export default SelectMenu;
