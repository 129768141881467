import useCoupons from 'domains/Marketing/Coupons/hooks/useCoupons';
import { CustomAction } from './CustomAction';

export function Actions({ id, status }) {
  const { activateCoupon, removeCoupon, deactivateCoupon } = useCoupons();

  const showActivate = status === 'disabled';
  const showDeactivate = status === 'enabled';

  const handleActionActivate = () => activateCoupon(id);
  const handleActionDeactivate = () => deactivateCoupon(id);
  const handleActionDelete = () => removeCoupon(id);

  return (
    <>
      {showActivate && (
        <CustomAction action="activate" onAction={handleActionActivate} />
      )}
      {showDeactivate && (
        <CustomAction action="deactivate" onAction={handleActionDeactivate} />
      )}
      <CustomAction action="delete" onAction={handleActionDelete} />
    </>
  );
}
