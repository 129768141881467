import { Box } from '@nimbus-ds/components';
import { DataList, Input, Text, Stack } from '@tiendanube/components';
import { BaseCardCollapsible } from 'commons/components';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields';

function Skeleton() {
  const t = useTranslationMetafields();
  return (
    <BaseCardCollapsible
      active
      title={t('title')}
      titleSize="h4"
      paddingBody="none"
      onChange={() => null}
    >
      <DataList spacing="base" skeleton>
        <DataList.Row id="1">
          <DataList.Cell width={100}>
            <Stack column align="stretch" spacing="tight">
              <Input.Skeleton />
              <Input.Skeleton />
              <Input.Skeleton />
            </Stack>
          </DataList.Cell>
        </DataList.Row>
      </DataList>
      <Stack>
        <Box
          paddingBottom="none"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="nowrap"
          flexDirection="row"
          flex="0 1 auto"
          alignSelf="auto"
        >
          <Text.Skeleton size="base" width="large" />
        </Box>
      </Stack>
    </BaseCardCollapsible>
  );
}

export default Skeleton;
