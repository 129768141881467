import { Button, Popover, Box } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { ActionProp } from '../../../types';

function ActionCustomPopover({
  actions,
  icon: IconComponent,
  name,
  appearance,
}: {
  actions: ActionProp[];
  icon;
  name;
  appearance;
}): JSX.Element {
  return (
    <Popover
      arrow={false}
      position="bottom-end"
      padding="small"
      content={
        <Box flexDirection="column">
          {actions.map((action, index) => (
            <Box
              key={`${action.children}-${index}`}
              padding="1"
              flexDirection="column"
            >
              <MenuButton
                label={action.children ?? name}
                startIcon={action.icon}
                onClick={action.onClick}
              />
            </Box>
          ))}
        </Box>
      }
    >
      <Button appearance={appearance}>
        <IconComponent />
        {name}
      </Button>
    </Popover>
  );
}

export default ActionCustomPopover;
