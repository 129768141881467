import { Box, Card, Tag, Text, Title } from '@nimbus-ds/components';
import { ThemesConfigDraftResponseDto } from '@tiendanube/common';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { Stack, SubmitButton } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import { useLoggedAdminLink } from 'domains/Auth/hooks';
import { trackingThemeLayoutCustomizeDraft } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { DraftAd } from './DraftAd';
import { DraftToolbar } from './DraftToolbar';
import './DraftCard.scss';

interface DraftCardProps {
  draft: ThemesConfigDraftResponseDto;
  isFtpOpen: boolean;
  onDraftPublished: () => void;
  highlightPublishCTA: boolean;
  makeSwitchDraftProminent?: boolean;
  publishActionLocation: 'menu' | 'toolbar';
}

export function DraftCard({
  draft,
  isFtpOpen,
  onDraftPublished,
  highlightPublishCTA,
  makeSwitchDraftProminent = true,
  publishActionLocation,
}: Readonly<DraftCardProps>) {
  const t = useTranslationOnline('themesPage.draft');
  const { goToLoggedAdminLink, isLoading } = useLoggedAdminLink();
  const handleClickEdit = async () => {
    await goToLoggedAdminLink(
      '/themes/settings/draft',
      trackingThemeLayoutCustomizeDraft,
    );
  };

  const shouldShowDraftAd = !draft || makeSwitchDraftProminent;

  return (
    <>
      {!!draft && (
        <Card>
          <Card.Body>
            <Box
              display="flex"
              gap="4"
              flexDirection={{
                md: 'row',
                xs: 'column',
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                flexDirection="column"
                flex={{
                  md: '1 1 auto',
                }}
                gap="1"
              >
                <Stack spacing="tight">
                  <Title as="h4">{draft.name}</Title>
                  <Tag appearance="warning">{t('draftTag')}</Tag>
                </Stack>
                {!!draft.updatedAt && (
                  <Text color="neutral-textLow">
                    {t('lastEdition', {
                      lastEdition: dateFormat(
                        draft.updatedAt,
                        Format.DAY_MONTH,
                      ),
                    })}
                  </Text>
                )}
              </Box>
              <Box display="flex" gap="2" alignItems="center">
                <SubmitButton
                  onClick={handleClickEdit}
                  appearance="neutral"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  {t('actions.edit')}
                </SubmitButton>
                <DraftToolbar
                  draft={draft}
                  isFtpOpen={isFtpOpen}
                  onDraftPublished={onDraftPublished}
                  highlightPublishCTA={highlightPublishCTA}
                  publishActionLocation={publishActionLocation}
                  makeSwitchDraftProminent={makeSwitchDraftProminent}
                />
              </Box>
            </Box>
          </Card.Body>
        </Card>
      )}
      <FeatureFlag
        flag={NEW_ONBOARDING_B}
        renderElse={
          <Box> {shouldShowDraftAd && <DraftAd firstDraft={!draft} />}</Box>
        }
      >
        <DraftAd firstDraft={!draft} />
      </FeatureFlag>
    </>
  );
}
