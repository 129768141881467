import { useQuery } from 'App/hooks';
import { IonPageStratus } from 'commons/components/IonPageStratus';
import { openWindow } from 'commons/utils/window';
import CloudflareBlockCard from './components/CloudflareBlockCard';

interface CloudflareBlockPageProps {
  isBlocked?: boolean;
  hardBlock: boolean;
  onDismiss: () => Promise<void>;
}

function CloudflareBlockPage({
  onDismiss,
  hardBlock,
  isBlocked = true,
}: CloudflareBlockPageProps) {
  const backToUrl = useQuery().get('back_to');
  if (!isBlocked) {
    openWindow(backToUrl || '/admin', false);
  }

  const onDismissWithRedirect = async () => {
    await onDismiss();

    if (backToUrl) {
      openWindow(backToUrl, false);
    }
  };

  return (
    <IonPageStratus width="small">
      <CloudflareBlockCard
        onDismiss={onDismissWithRedirect}
        hardBlock={hardBlock}
      />
    </IonPageStratus>
  );
}

export default CloudflareBlockPage;
