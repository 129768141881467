import { Card, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface CardCustomerCustomizationProps {
  customerCustomfields: Record<string, string>;
}
function CardCustomerCustomization({
  customerCustomfields,
}: CardCustomerCustomizationProps) {
  const t = useTranslationOrders();

  return (
    <Card>
      <Card.Header title={t('cardCustomerCustomization.title')} />
      <Card.Body>
        <Stack wrap column align="flex-start" gap="1-5">
          {Object.entries(customerCustomfields).map(([key, value]) => (
            <Stack gap="1" wrap key={key}>
              <Text as="p" fontWeight="bold">
                {key}:
              </Text>
              <Text>{value}</Text>
            </Stack>
          ))}
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default CardCustomerCustomization;
