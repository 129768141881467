import { ChangeEvent } from 'react';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { DEFAULT_LANGUAGE, LanguageInterface, LanguagesType } from 'App/i18n';
import { useTranslationOnline } from 'domains/Online';

interface MenuItemNameInputProps {
  language?: LanguagesType;
  name: LanguageInterface;
  error?: string;
  onChange: (value: LanguageInterface) => void;
}

function MenuItemNameInput({
  language = DEFAULT_LANGUAGE,
  name,
  error,
  onChange,
}: MenuItemNameInputProps) {
  const t = useTranslationOnline('menus.menuItemModal');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...name,
      [language]: e.target.value,
    });

  return (
    <FormField.Input
      id="name"
      label={t('inputName')}
      helpText={error ?? ''}
      appearance={error ? 'danger' : undefined}
      helpIcon={error ? ExclamationCircleIcon : undefined}
      onChange={handleChange}
      defaultValue={name[language]}
      autoFocus
      showHelpText
    />
  );
}

export default MenuItemNameInput;
