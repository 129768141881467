import { Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface PasswordProtectedPageErrorProps {
  fetchPasswordProtected: () => void;
}

function PasswordProtectedPageError({
  fetchPasswordProtected,
}: PasswordProtectedPageErrorProps) {
  const t = useTranslationOnline('passwordProtectedPage');

  return (
    <Card>
      <Card.Body>
        <ErrorState
          title={t('errorState.title')}
          action={{
            children: t('errorState.action'),
            onClick: fetchPasswordProtected,
          }}
        />
      </Card.Body>
    </Card>
  );
}

export default PasswordProtectedPageError;
