import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getMetafieldsOrdersData,
  getMetafieldsOrdersStatus,
} from 'domains/Metafields/metafieldsSlice';
import { fetchMetafieldsOrdersAction } from 'domains/Metafields/metafieldsSlice/metafieldsSlice';

function useGetMetafieldsOrders() {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsOrdersData);
  const status = useSelector(getMetafieldsOrdersStatus);

  const fetchMetafields = useCallback(
    () => dispatch(fetchMetafieldsOrdersAction()),
    [dispatch],
  );

  return {
    fetchMetafields,
    metafields,
    status,
  };
}

export default useGetMetafieldsOrders;
