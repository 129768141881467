import { Country } from '@tiendanube/common/src/enums';
import { ErrorState } from '@tiendanube/components';
import ShowByCountry from 'App/components/ShowByCountry/ShowByCountry';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import WireDataSkeleton from './Skeleton';
import WireDataAr from './WireDataAr';
import { useGetWireTransferData } from '../../hooks';

function WireData() {
  const { isLoading, isError, refreshWireTransferData } =
    useGetWireTransferData();
  const t = useTranslationBilling(
    'checkout.wireTransferPage.instructions.wireData',
  );

  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: refreshWireTransferData,
        }}
      />
    );

  if (isLoading) {
    return <WireDataSkeleton />;
  }

  return (
    <ShowByCountry allowedCountries={[Country.AR]}>
      <WireDataAr />
    </ShowByCountry>
  );
}

export default WireData;
