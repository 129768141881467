import { ReactNode } from 'react';
import { Stack, Title } from '@tiendanube/components';
import { useResponsive } from 'commons/components';

interface DetailsHeaderProps {
  title: string;
  right?: ReactNode;
}

function DetailsHeader({ title, right }: DetailsHeaderProps): JSX.Element {
  const { isDesktop } = useResponsive();

  return (
    <Stack wrap justify="space-between">
      {isDesktop && (
        <Stack.Item>
          <Title type="h2">{title}</Title>
        </Stack.Item>
      )}
      <Stack.Item>{right}</Stack.Item>
    </Stack>
  );
}

export default DetailsHeader;
