import { useCallback, useState } from 'react';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { usePostPaymentProofMutation } from 'domains/Billing/Checkout/CheckoutApi';
import { usePayorderToPay } from 'domains/Billing/Checkout/hooks';

const MAX_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;

function useUploadPaymentProof() {
  const [uploadPaymentProof, { status, reset }] = usePostPaymentProofMutation({
    fixedCacheKey: 'wire-transfer-payment',
  });
  const [file, setFile] = useState<File | undefined>(undefined);
  const {
    payorderToPay,
    status: payOrderStatus,
    refreshPayOrderToPay,
  } = usePayorderToPay();

  const [fileTooBigError, setFileTooBigError] = useState<boolean>(false);

  const onChangeFile = useCallback(
    async (file: File | undefined) => {
      if (!file) return;
      setFile(file);
      if (file.size >= MAX_FILE_SIZE_IN_BYTES) {
        setFileTooBigError(true);
      }
    },
    [setFile, setFileTooBigError],
  );

  const onRemoveFile = useCallback(() => {
    setFile(undefined);
    setFileTooBigError(false);
  }, [setFile, setFileTooBigError]);

  const onSubmit = useCallback(() => {
    if (!payorderToPay || !file) {
      return;
    }
    uploadPaymentProof({ payOrderId: payorderToPay.id, file });
  }, [file, uploadPaymentProof, payorderToPay]);

  return {
    onChangeFile,
    onRemoveFile,
    fileTooBigError,
    onSubmit,
    file,
    status: toStatusType(status),
    ...convertStatusTypeToObject(status),
    cleanStatus: reset,
    setupStatus: convertStatusTypeToObject(payOrderStatus),
    retrySetup: refreshPayOrderToPay,
  };
}

export default useUploadPaymentProof;
