import { Text } from '@nimbus-ds/components';

interface LocationTextProp {
  locality?: string;
  city?: string;
  province?: string;
  country?: string;
}

function LocationText({ locality, city, province, country }: LocationTextProp) {
  let location = '';
  location += locality ? `${locality}, ` : '';
  location += city ? `${city}, ` : '';
  location += province ? `${province}, ` : '';
  location += country ? `${country}` : '';

  return location ? <Text>{location}</Text> : null;
}

export default LocationText;
