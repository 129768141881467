import { useEffect, useState } from 'react';
import { Link, Modal, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import { Stack } from 'commons/components';
import { toCamelCase } from 'commons/utils';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

const VULNERABILITIES = [
  'is_password_leaked',
  'is_password_vulnerable',
] as const;

type Vulnerability = typeof VULNERABILITIES[number];

function VulnerablePasswordAlert() {
  const t = useTranslationDashboard('vulnerablePasswordAlert');
  const { removeSearchParams, hasSearchParam } = useNavegate();
  const [vulnerability, setVulnerability] = useState<Vulnerability>();
  const { validateRoles } = useRoles();
  const guideUrl = t(validateRoles('full') ? 'linkFull' : 'linkNotFull');

  useEffect(() => {
    const vulnerability = VULNERABILITIES.find((queryParam) =>
      hasSearchParam(queryParam),
    );
    if (vulnerability) {
      removeSearchParams([vulnerability]);
      setVulnerability(vulnerability);
    }
  }, [hasSearchParam, removeSearchParams]);

  if (vulnerability === undefined) {
    return null;
  }

  const vulnerabilityTranslationBase = toCamelCase(vulnerability);

  return (
    <Modal open onDismiss={() => setVulnerability(undefined)}>
      <Modal.Header title={t(`${vulnerabilityTranslationBase}.title`)} />
      <Modal.Body padding="none">
        <Text>{t(`${vulnerabilityTranslationBase}.text`)}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Stack justify="flex-start" flex="1">
          <Link
            as="a"
            href={guideUrl}
            target="_blank"
            appearance="primary"
            textDecoration="none"
          >
            {t('linkText')}
            <ExternalLinkIcon />
          </Link>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default VulnerablePasswordAlert;
