import { ChangeEvent, useState } from 'react';
import { Box, Icon, Input } from '@nimbus-ds/components';
import { ExclamationTriangleIcon, SearchIcon } from '@nimbus-ds/icons';
import { EmptyMessage, InteractiveList } from '@nimbus-ds/patterns';
import { ProvinceDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ProvincesListSkeleton from './Skeleton';

interface ProvincesListProps {
  readonly provinces: ProvinceDto[];
  readonly filteredProvinces: ProvinceDto[];
  readonly handleSelectProvince: (selectedOption: string) => void;
  readonly handleSetFilter: (filter: ProvinceDto[]) => void;
}

function ProvincesList({
  provinces,
  filteredProvinces,
  handleSelectProvince,
  handleSetFilter,
}: Readonly<ProvincesListProps>) {
  const t = useTranslationShipping();
  const [searchValue, setSearchValue] = useState('');

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    const filtered = provinces
      ? provinces.filter(
          ({ name }) => name.search(new RegExp(e.target.value, 'i')) >= 0,
        )
      : [];

    handleSetFilter(filtered);
  };

  return (
    <Stack column align="center">
      <Box paddingX="4" width="100%">
        <Input
          type="search"
          name="search"
          append={<Icon source={<SearchIcon />} />}
          value={searchValue}
          placeholder={t(
            'deliveryMethods.customShipping.selectProvinceModal.search',
          )}
          onChange={handleChangeSearch}
          onSubmit={handleChangeSearch}
        />
      </Box>

      {!!searchValue && filteredProvinces.length === 0 && (
        <EmptyMessage
          title={t(
            'deliveryMethods.customShipping.selectProvinceModal.titleSearch',
          )}
          text={t(
            'deliveryMethods.customShipping.selectProvinceModal.messageSearch',
          )}
          icon={<ExclamationTriangleIcon size="large" />}
        />
      )}
      <InteractiveList>
        {filteredProvinces.map((province) => (
          <InteractiveList.ButtonItem
            key={province.code}
            title={province.name}
            iconButton={{
              onClick: () => handleSelectProvince(province.code),
            }}
          />
        ))}
      </InteractiveList>
    </Stack>
  );
}

ProvincesList.Skeleton = ProvincesListSkeleton;
export default ProvincesList;
