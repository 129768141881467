import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { StylesConfig } from 'react-select';

export interface OptionType {
  value: string;
  label: string;
}

export const colourStyles: StylesConfig<OptionType> = {
  control: (styles) => ({
    ...styles,
    borderColor: tokens.color.light.neutral[80].value,
    borderRadius: tokens.shape.border.radius[2].value,
    fontWeight: tokens.font.weight.regular.value,
    fontSize: tokens.font.size.body.base.value,
    fontFamily: tokens.font.family.sans.value,
    ':hover': {
      borderColor: tokens.color.light.neutral[80].value,
    },
    ':focus': {
      borderColor: tokens.color.dark.primary['interactive-pressed'].value,
    },
    boxShadow: tokens.shadow.level[0].value,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: tokens.font.size.body.base.value,
    fontFamily: tokens.font.family.sans.value,
    backgroundColor:
      isSelected || isFocused
        ? tokens.color.light.neutral[99].value
        : undefined,
    color: tokens.color.light.neutral[40].original.value,
    cursor: 'pointer',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: tokens.color.light.neutral[99].value,
    borderColor: tokens.color.light.neutral[80].value,
    borderRadius: tokens.shape.border.radius[1].value,
    borderStyle: 'solid',
    borderWidth: tokens.shape.border.width[1].value,
    color: tokens.color.light.neutral[0].value,
    cursor: 'pointer',
    height: tokens.line.height[6].value,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontFamily: tokens.font.family.sans.value,
    fontSize: tokens.font.size.body.caption.value,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ':hover': {
      backgroundColor: tokens.color.light.neutral[90].original.value,
      borderColor: tokens.color.light.neutral[70].value,
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: tokens.font.size.body.base.value,
    fontFamily: tokens.font.family.sans.value,
  }),
  dropdownIndicator: (prevStyle) => ({
    ...prevStyle,
    display: 'none',
  }),
  indicatorSeparator: (prevStyle) => ({
    ...prevStyle,
    display: 'none',
  }),
};
