import { IconButton } from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';
import { DeleteUserSessionModal } from './DeleteUserSessionModal';
import { useDeleteUserSessionAction } from '../hooks/useDeleteUserSessionAction';

interface DeleteActionProps {
  id: string;
}

export function DeleteUserSessionAction({ id }: Readonly<DeleteActionProps>) {
  const { showDeleteModal, handleDelete, handleClick, handleClose } =
    useDeleteUserSessionAction(id);

  return (
    <>
      <IconButton
        type="button"
        size="2rem"
        source={<CloseIcon />}
        onClick={handleClick}
      />
      <DeleteUserSessionModal
        show={showDeleteModal}
        onClose={handleClose}
        onDelete={handleDelete}
        isDeleteAllAction={false}
      />
    </>
  );
}
