import { Label } from '@tiendanube/components';
import { ArrowsHorizontalIcon } from '@tiendanube/icons';

type AppearanceType = 'success' | 'danger' | 'primary';
type VariationAppearanceSymbolType = '+' | '-' | '=' | '∞';
interface StockMovementProps {
  id: string;
  variation: number | '∞';
  variationSymbol: VariationAppearanceSymbolType | '';
}

const appearance: Record<VariationAppearanceSymbolType, AppearanceType> = {
  '+': 'success',
  '-': 'danger',
  '∞': 'primary',
  '=': 'primary',
};

function StockMovement({
  variationSymbol,
  variation,
  id,
}: StockMovementProps): JSX.Element {
  const symbol = variation === '∞' ? '∞' : variationSymbol;
  const label = `${
    variationSymbol !== '=' ? variationSymbol : ''
  } ${variation}`;
  const icon = variationSymbol === '=' ? { icon: ArrowsHorizontalIcon } : {};
  const appearanceVariation = variation === 0 ? 'danger' : appearance[symbol];

  return (
    <Label id={id} {...icon} appearance={appearanceVariation} label={label} />
  );
}

export default StockMovement;
