import { AuthenticationFactorType } from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import {
  useInstallAuthenticationFactor,
  useTranslationAccount,
  useUpdateAuthenticationFactor,
  useRecoveryCodes,
} from 'domains/Account/hooks';
import { OnSubmitValues } from 'domains/AuthenticationFactor/components/AuthenticationFactorVerifyCodeCard';

interface UseLinkAccountPageResult {
  title: string | undefined;
  description: string;
  submitText: string;
  onSubmit: (data: OnSubmitValues) => Promise<boolean>;
  showSuccessToast: boolean;
  showCard: boolean;
  isLoading: boolean;
  isError: boolean;
  allowToUseRecoveryCodes: boolean;
}

export function useLinkAccountPage(
  authenticationFactor: AuthenticationFactorType,
  active: boolean,
): UseLinkAccountPageResult {
  const t = useTranslationAccount('authenticationFactorPage.linkAccountPage');
  const {
    update,
    isLoading: isLoadingTotp,
    isError: isErrorTotp,
  } = useUpdateAuthenticationFactor();
  const {
    install,
    isLoading: isLoadingInstallRecoveryCodes,
    isError: isErrorInstallRecoveryCodes,
  } = useInstallAuthenticationFactor();
  const {
    fetch,
    isError: isErrorFetchRecoveryCodes,
    isLoading: isLoadingFetchRecoveryCodes,
  } = useRecoveryCodes();

  switch (authenticationFactor) {
    case AuthenticationFactorTypes.TOTP:
      return {
        title: t(active ? 'deactivateAccountTitle' : 'activateAccountTitle'),
        description: t('totpDescription'),
        submitText: t('actions.finalize'),
        onSubmit: (data) =>
          update({
            type: AuthenticationFactorTypes.TOTP,
            enabled: !active,
            code: data.code,
            codeType: data.type,
          }),
        isLoading: isLoadingTotp,
        isError: isErrorTotp,
        showSuccessToast: true,
        showCard: true,
        allowToUseRecoveryCodes: active,
      };
    case AuthenticationFactorTypes.RECOVERY_CODE: {
      const [onSubmit, isLoading, isError, showSuccessToast, submitText] =
        active
          ? [
              (data: OnSubmitValues) => fetch(data.code),
              isLoadingFetchRecoveryCodes,
              isErrorFetchRecoveryCodes,
              false,
              t('actions.verify'),
            ]
          : [
              (data: OnSubmitValues) =>
                install({
                  type: AuthenticationFactorTypes.RECOVERY_CODE,
                  code: data.code,
                }),
              isLoadingInstallRecoveryCodes,
              isErrorInstallRecoveryCodes,
              true,
              t('actions.finalize'),
            ];
      return {
        title: undefined,
        description: t('recoveryCodesDescription'),
        onSubmit,
        submitText,
        isLoading,
        isError,
        showSuccessToast,
        showCard: false,
        allowToUseRecoveryCodes: false,
      };
    }
  }
}
