import { BaseCard, Stack, Title, Text } from '@tiendanube/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  const title = t('oca.config.freeShipping.title');
  const subtitle = t('oca.config.freeShipping.subtitle');
  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column align="stretch" spacing="tight">
          <Stack justify="space-between" spacing="none">
            <Title type="h3">{title}</Title>
            <Text.Skeleton size="caption" />
          </Stack>
          <Text>{subtitle}</Text>
        </Stack>
      </BaseCard.Header>
    </BaseCard>
  );
}

export default Skeleton;
