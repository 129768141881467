import { useCallback, useState } from 'react';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { checkoutApi } from 'domains/Billing/Checkout/CheckoutApi';
import { shouldRedirectBecauseOfFinishedChargeError } from 'domains/Billing/utils';
import { checkoutTags } from '../CheckoutApi/config';

function useRedirectOnSimilarCharge(functionToExecute, redirectRoute: string) {
  const { goTo } = useNavegate();
  const [status, setStatus] = useState<StatusType>('idle');
  const dispatch = useAppDispatch();

  const handleFunctionBehaviour = useCallback(
    (...params) => {
      setStatus('loading');
      return functionToExecute(...params)
        .then((result) => {
          setStatus('success');
          return result;
        })
        .catch((error) => {
          if (
            error.status === 400 &&
            shouldRedirectBecauseOfFinishedChargeError(
              error.data.response.message,
            )
          ) {
            setStatus('success');
            dispatch(
              checkoutApi.util.invalidateTags(Object.values(checkoutTags)),
            );
            goTo(redirectRoute);
          } else {
            setStatus('error');
            throw error;
          }
        });
    },
    [functionToExecute, goTo, dispatch, setStatus, redirectRoute],
  );

  return {
    handleFunctionBehaviour,
    ...convertStatusTypeToObject(status),
    status,
  };
}

export default useRedirectOnSimilarCharge;
