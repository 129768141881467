import { INDENT_PER_DEPTH } from 'commons/components';

import './Placeholder.scss';

interface PlaceholderProps {
  depth: number;
}

function Placeholder({ depth }: PlaceholderProps): JSX.Element {
  const left = depth * INDENT_PER_DEPTH;
  return (
    <div className="stratus--draggable-tree__placeholder" style={{ left }} />
  );
}

export default Placeholder;
