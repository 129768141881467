import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'commons/utils';
import { STATS_SHIPPING_PAGE } from 'config/upsellFlowSources';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_3, thousandSeparator } from 'domains/Statistics/utils';
import { SHIPPING_SUMMARY_COMBINED_COLORS } from './constants';
import useGetShippingSummary from './useGetShippingSummary';
import CombinedBarChartAndSummaryCard from '../CombinedBarChartAndSummaryCard';

type ShippingSummaryKeys =
  | 'revenueByShippingGateway'
  | 'salesByShippingGateway'
  | 'revenueByFreeShipping'
  | 'salesByFreeShipping';

function ShippingSummaryCard() {
  const t = useTranslationStatistics();
  const {
    i18n: { language },
  } = useTranslation();
  const { code } = useGetCurrency();

  const {
    shippingSummary,
    fetchShippingSummary,
    hasComparedFilter,
    isError,
    isLoading,
    isSuccess,
    isEmpty,
    openFilter,
  } = useGetShippingSummary();

  const formatSummaryTitle = (key) => t(`statistics.shippingSummary.${key}`);

  const formatValueSummary = (text: number, key: string) => {
    const curr = ['revenueByShippingGateway', 'revenueByFreeShipping'];
    if (curr.includes(key)) return formatCurrency(text, code, language);
    return thousandSeparator(text);
  };

  const formatLabelTooltip = (label: string) => {
    const [, key] = label.split('.');
    return t(`statistics.shippingSummary.formatKeys.${key}`);
  };
  return (
    <CombinedBarChartAndSummaryCard<ShippingSummaryKeys>
      title={t('statistics.shippingSummaryCard.title')}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      hasComparedFilter={hasComparedFilter}
      selectedPrimary="revenueByShippingGateway"
      fetchData={fetchShippingSummary}
      data={shippingSummary}
      colors={SHIPPING_SUMMARY_COMBINED_COLORS}
      statsPlanRequired={STATS_PLAN_3}
      upsellSource={STATS_SHIPPING_PAGE}
      formatLabelTooltip={formatLabelTooltip}
      formatSummaryTitle={formatSummaryTitle}
      formatValueSummary={formatValueSummary}
      onOpenFilter={openFilter}
      isEmpty={isEmpty}
    />
  );
}

export default ShippingSummaryCard;
