import { Stack, Input } from '@tiendanube/components';

function BrazilianAddressSkeleton() {
  return (
    <Stack column align="stretch">
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
    </Stack>
  );
}

export default BrazilianAddressSkeleton;
