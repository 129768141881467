import { useEffect } from 'react';
import { Badge } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { UserGroupIcon } from '@tiendanube/icons';
import ShowByRole from 'App/components/ShowByRole';
import { useHandleMenuClick } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsPlanLojinha } from 'domains/Auth/hooks';
import { useGetUnreadMessagesCount } from 'domains/Customers/Messages/hooks';
import { MESSAGES_ROUTES } from 'domains/Customers/Messages/messagesRoutes';

const PATHS = {
  customers: '/customers',
  messages: MESSAGES_ROUTES.messages,
};

function CustomersMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { validateRoles } = useRoles();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const { fetchUnreadMessagesCount, unreadMessagesCount } =
    useGetUnreadMessagesCount();
  const isPlanLojinha = useIsPlanLojinha();

  useEffect(() => {
    fetchUnreadMessagesCount();
  }, [fetchUnreadMessagesCount]);

  const badgeUnreadMessagesCount = unreadMessagesCount > 0;

  const menuCustomers = {
    children: t('menu.manage.customers.customersList'),
    onClick: handleClickItem,
    active: pathname.startsWith(PATHS.customers),
    href: PATHS.customers,
  };

  const menuMessages = {
    children: t('menu.manage.customers.messages'),
    onClick: handleClickItem,
    active: pathname.startsWith(PATHS.messages),
    badge: unreadMessagesCount,
    href: PATHS.messages,
  };

  const itemsCustomers: {
    children: string;
    onClick: () => void;
    active: boolean;
    href: string;
    badge?: number;
  }[] = [
    ...(validateRoles('customers') ? [menuCustomers] : []),
    ...(validateRoles('customers') && !isPlanLojinha ? [menuMessages] : []),
  ];

  const activeCustomers =
    pathname.startsWith(PATHS.customers) || pathname.startsWith(PATHS.messages);

  return (
    <ShowByRole includeRoles="customers">
      <MenuButton.Accordion
        contentid="content-customers"
        menuButton={{
          id: 'control-customers',
          label: t('menu.manage.customers.title'),
          startIcon: UserGroupIcon,
          'aria-controls': 'content-customers',
          onClick: handleClickAccordion,
          children:
            !activeCustomers && badgeUnreadMessagesCount ? (
              <Badge count={unreadMessagesCount} appearance="primary" />
            ) : undefined,
        }}
        open={activeCustomers}
        active={activeCustomers}
        as={Link}
        to={PATHS.customers}
      >
        {itemsCustomers.map((item, index) => (
          <MenuButton
            key={index}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          >
            {item.badge ? (
              <Badge count={unreadMessagesCount} appearance="primary" />
            ) : undefined}
          </MenuButton>
        ))}
      </MenuButton.Accordion>
    </ShowByRole>
  );
}

export default CustomersMenu;
