import { Card, ErrorState } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface HighlightProductCardErrorStateProps {
  onRetry?: () => void;
}

function HighlightProductCardErrorState({
  onRetry,
}: HighlightProductCardErrorStateProps): JSX.Element {
  const t = useTranslationCatalog();

  const errorTitle = onRetry
    ? t('products.detail.highlight.error.getReTry.title')
    : t('products.detail.highlight.error.get');

  return (
    <Card title={t('products.detail.highlight.title')}>
      <ErrorState
        title={errorTitle}
        action={
          onRetry && {
            children: t('products.detail.highlight.error.getReTry.action'),
            onClick: onRetry,
          }
        }
      />
    </Card>
  );
}

export default HighlightProductCardErrorState;
