enum Country {
  AR = "AR",
  MX = "MX",
  CL = "CL",
  CO = "CO",
  BR = "BR",
  PE = "PE",
}

export default Country;
