import { Alert } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface UpgradePlanAlertProps {
  show: boolean;
  availableUses: number;
  isMaxExportReached: boolean;
  onClick: () => void;
  onDismiss: () => void;
}

function UpgradePlanAlert({
  show,
  availableUses,
  isMaxExportReached,
  onClick,
  onDismiss,
}: UpgradePlanAlertProps): JSX.Element {
  const t = useTranslationOrders();

  return (
    <div className="stratus--alert-content-plans">
      <Alert
        show={show && !isMaxExportReached}
        title={t('exportPage.upgradePlanAlert.usesAvailable.title', {
          count: availableUses,
        })}
        onDismiss={onDismiss}
      >
        {t('exportPage.upgradePlanAlert.usesAvailable.message')}
      </Alert>
      <Alert
        show={isMaxExportReached}
        title={t('exportPage.upgradePlanAlert.maxUsesReached.title')}
        primaryLabel={t('exportPage.upgradePlanAlert.maxUsesReached.cta')}
        onClickPrimary={onClick}
        appearance="warning"
      >
        {t('exportPage.upgradePlanAlert.maxUsesReached.message')}
      </Alert>
    </div>
  );
}

export default UpgradePlanAlert;
