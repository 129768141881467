import { useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
} from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling/useTranslationBilling';
import { PaymentSelectionForPayOrder } from './components';
import { usePayorderToPay } from '../../hooks';
import { trackingBillingPayOrderCheckoutView } from '../../tracking';
import { PayOrderSummary } from '../components';

function ChoosePaymentMethodForPayOrder() {
  const t = useTranslationBilling('checkout.choosePaymentMethodForPayOrder');
  const { goBack } = useHistory();
  const backNavigation = {
    onClick: goBack,
  };

  const { mainConcept } = usePayorderToPay();

  useEffect(() => {
    if (mainConcept) {
      trackingBillingPayOrderCheckoutView(mainConcept);
    }
  }, [mainConcept]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={t('title')} />}
      width="large"
    >
      <Layout
        mainContent
        left={
          <Box display="flex" flexDirection="column" flexGrow="1">
            <PaymentSelectionForPayOrder />
          </Box>
        }
        right={
          <Box display="flex" flexDirection="column" flexGrow="1">
            <PayOrderSummary />
          </Box>
        }
        orderMobile="reverse"
      />
    </IonPageStratus>
  );
}

export default ChoosePaymentMethodForPayOrder;
