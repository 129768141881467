import { useCallback } from 'react';
import { PaymentProviderDto } from '@tiendanube/common';
import {
  customPaymentsIds,
  payPalAppId,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Country } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import { useToastStatus } from 'commons/hooks';
import { useGetCountry, useGetIsFreemium } from 'domains/Auth/hooks';
import { usePaymentProviderSettings } from 'domains/Payments/PaymentProviders/hooks';
import { PAYMENTS_ROUTES } from 'domains/Payments/paymentsRoutes';
import { useModalTexts, useInstallApplication, useUninstallApp } from '..';
import { PaymentProviderActionType } from '../../PaymentProviderActionModal';

export function usePaymentProviderActionModal(
  provider: PaymentProviderDto,
  action: PaymentProviderActionType,
  onClose: () => void,
) {
  const { id, appId, wasInstalled } = provider;

  const { installApp } = useInstallApplication(provider, () => ({}));
  const { goTo } = useNavegate();
  const country = useGetCountry();
  const isFreemium = useGetIsFreemium();

  const { updateStatus, paymentProvider, disableInstalledPaymentProvider } =
    usePaymentProviderSettings(id);

  const { handleUninstallApp, makeHandleSuccess, uninstallStatus } =
    useUninstallApp(appId);

  const actionProvider: Record<PaymentProviderActionType, () => void> = {
    enable: installApp,
    disable: disableInstalledPaymentProvider,
    uninstall: handleUninstallApp,
  };

  const [title, text, confirm, progress, error, success] = useModalTexts(
    action === 'enable',
    paymentProvider?.name || '',
    customPaymentsIds.includes(id) && country === Country.BR,
    isFreemium && country === Country.MX && appId === payPalAppId,
    paymentProvider?.mustCompleteInstallation,
  );

  const handleUpdateSuccess = useCallback(() => {
    if (wasInstalled && customPaymentsIds.includes(id) && action === 'enable') {
      goTo(PAYMENTS_ROUTES.customPaymentNewRoute);
    }
    onClose();
  }, [goTo, id, onClose, wasInstalled, action]);

  useToastStatus({
    error: error,
    success: success,
    status: updateStatus,
    onSuccess: handleUpdateSuccess,
    onError: onClose,
  });

  useToastStatus({
    error: error,
    success: success,
    status: uninstallStatus,
    onSuccess: makeHandleSuccess(onClose),
    onError: onClose,
  });

  const isUpdating =
    updateStatus === 'loading' || uninstallStatus === 'loading';

  return {
    actionProvider,
    isUpdating,
    title,
    text,
    confirm,
    progress,
  };
}
