export const webpSupported = (callback: (isSupported: boolean) => void) => {
  const img = new Image();
  img.onload = () => {
    const supportsWebp = img.width > 0 && img.height > 0;
    callback(supportsWebp);
  };
  img.onerror = () => {
    callback(false);
  };
  img.src =
    'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
};
