import { useTranslation } from 'react-i18next';
import { EmptyState, Text } from '@tiendanube/components';
import ErrorImage from './error.png';
import './ErrorScreen.css';

interface ErrorScreenInterface {
  onReload: () => void;
}

function ErrorScreen({ onReload }: ErrorScreenInterface): JSX.Element {
  const { t } = useTranslation('common');
  return (
    <div className="stratus--error-container">
      <EmptyState
        fullWidth
        image={ErrorImage}
        title={`${t('errorPage.title')}`}
        primaryActionLabel={`${t('errorPage.action')}`}
        onClickPrimary={onReload}
      >
        <Text textAlign="center">{`${t('errorPage.text')}`}</Text>
      </EmptyState>
    </div>
  );
}

export default ErrorScreen;
