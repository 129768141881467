import { Card, Icon, Label, Table, Tag, Text } from '@nimbus-ds/components';
import { MethodNameAndImage, Stack } from 'commons/components';
import './PaymentProvider.scss';

function PaymentMethodCellItem({ isLastItem }: { isLastItem?: boolean }) {
  return (
    <div className="payment-method-cell">
      <Stack>
        <Text.Skeleton />
      </Stack>
      {isLastItem && <hr />}
    </div>
  );
}

function Skeleton() {
  return (
    <div className="stratus--payment-provider-card">
      <Card>
        <Card.Header>
          <Stack justify="space-between">
            <MethodNameAndImage.Skeleton />
            <Stack>
              <Tag.Skeleton />
            </Stack>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Stack column align="flex-start" spacing="base">
            <Stack>
              <Label.Skeleton />
              <Label.Skeleton />
              <Label.Skeleton />
            </Stack>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Cell width="20rem">
                    <Text.Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Text.Skeleton />
                  </Table.Cell>
                  <Table.Cell width="10rem">
                    <Text.Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Stack spacing="tight">
                      <Text.Skeleton />
                    </Stack>
                  </Table.Cell>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Cell maxWidth="280">
                    <Stack spacing="base" align="flex-start" column>
                      <Stack gap="1-5">
                        <Icon.Skeleton />
                        <Text.Skeleton />
                        <Text.Skeleton />
                      </Stack>
                    </Stack>
                  </Table.Cell>
                  <Table.Cell>
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem isLastItem />
                  </Table.Cell>
                  <Table.Cell>
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem isLastItem />
                  </Table.Cell>
                  <Table.Cell>
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem />
                    <PaymentMethodCellItem isLastItem />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Stack>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Skeleton;
