import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoNuvemshop } from './logo-nuvemshop.svg';
import { ReactComponent as LogoTiendanube } from './logo-tiendanube.svg';

const styleLogo = {
  marginTop: '15px',
  marginBottom: '15px',
};

function Logo(): JSX.Element {
  const {
    i18n: { language },
  } = useTranslation(['login']);
  if (language === 'pt-BR') {
    return (
      <LogoNuvemshop data-testid="nuvemshop" height="30" style={styleLogo} />
    );
  }

  return (
    <LogoTiendanube data-testid="tiendanube" height="30" style={styleLogo} />
  );
}

export default Logo;
