import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import axios from 'App/axios';
import { setAxiosInterceptors } from './axiosUtils';
import rootReducer from './rootReducer';
import { cirrusApi } from './rtk';

export type RootStateType = ReturnType<typeof rootReducer>;

export type AppDispatchType = typeof store.dispatch;

export type StoreType = typeof store;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cirrusApi.middleware),
});

export const useAppDispatch = (): AppDispatchType =>
  useDispatch<AppDispatchType>();

setupListeners(store.dispatch);

setAxiosInterceptors(axios, store);

export default store;
