import { SelectedProductInterface } from 'domains/Catalog/Products/components';
import { SectionProductItemInterface } from 'domains/Online';

export const getSectionProductsMaped = (
  products: SelectedProductInterface[],
): SectionProductItemInterface[] =>
  products.map((p, i) => ({
    productId: p.id,
    name: p.name,
    order: i + 1,
    stock: p.stock,
    visible: p.visible,
    imageUrl: p.imageUrl,
  }));

export const getSelectedProductsMaped = (
  products: SectionProductItemInterface[],
): SelectedProductInterface[] =>
  products.map((p) => ({
    id: p.productId,
    name: p.name,
    imageUrl: p.imageUrl || '',
    stock: p.stock,
    visible: p.visible,
    attributes: [],
    variants: [],
  }));
