import { CodeIcon, ExternalLinkIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { toStatusType } from 'App/rtk';
import { PopoverMenu } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetStoreInfo, useLoggedAdminLink } from 'domains/Auth/hooks';
import {
  useCreateDraftMutation,
  useGetThemesConfigQuery,
} from 'domains/Online/Themes/themesApi';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { EnableFtpModal } from './EnableFtpModal';
import { trackingThemeLayoutDraftCreationDefault } from '../../../../tracking';

interface ThemeActionsProps {
  hasThemeConfigAvailable: boolean;
  hasFtpConfigAvailable: boolean;
}

export function NewThemeActions({
  hasThemeConfigAvailable,
  hasFtpConfigAvailable,
}: Readonly<ThemeActionsProps>) {
  const t = useTranslationOnline('themesPage.theme.actions');
  const { url } = useGetStoreInfo();
  const [createDraft, { status }] = useCreateDraftMutation();
  const { goToLoggedAdminLink } = useLoggedAdminLink();
  const { data: themesConfig } = useGetThemesConfigQuery();
  const hasDraft = themesConfig?.draft;

  useToastStatus({
    status: toStatusType(status),
    progress: t('toast.progress'),
    error: t('toast.error'),
  });

  const handleOpenStoreFront = () => {
    openWindow(`${url}/`, true);
  };

  const handleNewDraftFromCurrentDesign = async () => {
    trackingThemeLayoutDraftCreationDefault();
    await createDraft();
    await goToLoggedAdminLink('/themes/settings/draft');
  };

  const [showEnableFtpModal, openEnableFtpModal, closeEnableFtpModal] =
    useModal();

  const actions = [
    ...(!hasDraft
      ? [
          {
            label: t('createDraft'),
            startIcon: PlusCircleIcon,
            onClick: handleNewDraftFromCurrentDesign,
          },
        ]
      : []),
    {
      label: t('openActiveDesign'),
      startIcon: ExternalLinkIcon,
      onClick: handleOpenStoreFront,
    },
    ...(hasThemeConfigAvailable
      ? [
          {
            label: t('editCode'),
            startIcon: CodeIcon,
            onClick: openEnableFtpModal,
          },
        ]
      : []),
  ];
  // This component duplicates ThemesActions to implement New Onboarding feature [NA-5773]. Should be unified after testing results.
  return (
    <>
      <PopoverMenu actions={actions} />
      <EnableFtpModal
        showModal={showEnableFtpModal}
        hasFtpConfigAvailable={hasFtpConfigAvailable}
        onClose={closeEnableFtpModal}
      />
    </>
  );
}
