import { Card, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function PasswordProtectedPageSkeleton() {
  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="flex-start">
          <Stack column spacing="tight" align="flex-start">
            <Title.Skeleton as="h3" height="20px" width="140px" />
            <Text.Skeleton height="18px" width="200px" />
          </Stack>
          <Text.Skeleton />
        </Stack>
      </Card.Header>
    </Card>
  );
}

export default PasswordProtectedPageSkeleton;
