import * as Yup from 'yup';
import { LanguagesType } from 'App/i18n';

export const validationSchema = (languages: LanguagesType[]) =>
  Yup.object().shape({
    htmlEnabled: Yup.boolean(),
    ...languages.reduce(
      (acc, current) => ({
        ...acc,
        [current]: Yup.object().shape({
          templateHtml: Yup.string().test(
            'test',
            'formCard.templateHtml.errorValidation',
            // we destructure from to access the parent of [language].templateHtml and know the value of htmlEnabled
            // we cast any since the from property is not defined in Yup.TestContext but we can access it
            (_, { from }: any) => {
              if (from?.[1]?.value?.htmlEnabled)
                return !!from?.[0]?.value?.templateHtml.trim();

              return true;
            },
          ),
        }),
      }),
      {},
    ),
  });
