import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import {
  getMultiCDAvailability as getMultiCDAvailabilityAction,
  activateMultiCD as activateMultiCDAction,
} from '../../locationSlice';

export interface UseMultiCDActivationResult {
  available: boolean;
  getMultiCDAvailability: () => Promise<void>;
  activateMultiCD: () => Promise<void>;
}

function useMultiCDActivation(): UseMultiCDActivationResult {
  const dispatch = useAppDispatch();
  const [available, setAvailable] = useState(false);

  const getMultiCDAvailability = useCallback(async () => {
    const available = unwrapResult(
      await dispatch(getMultiCDAvailabilityAction()),
    );

    setAvailable(available);
  }, [dispatch]);

  const activateMultiCD = useCallback(async () => {
    await dispatch(activateMultiCDAction());
  }, [dispatch]);

  return { available, activateMultiCD, getMultiCDAvailability };
}

export default useMultiCDActivation;
