import { Box, Text, Link } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { PlansListResponseDto } from '@tiendanube/common';
import { useTranslationLanguage } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PlanNameAndPriceProps {
  plan: PlansListResponseDto;
  align: 'left' | 'right';
  onClickPlanName?: () => void;
}

export function PlanNameAndPrice({
  plan,
  align,
  onClickPlanName,
}: Readonly<PlanNameAndPriceProps>) {
  const language = useTranslationLanguage();
  const t = useTranslationBilling();

  const formatPrice = (value: number, currency: string): string =>
    formatCurrency(value, currency, language, true);

  return (
    <Box>
      {plan.name.length > 0 && (
        <Text color="neutral-textHigh" textAlign={align} fontWeight="medium">
          {onClickPlanName ? (
            <Link
              textDecoration="none"
              appearance="primary"
              onClick={onClickPlanName}
            >
              {plan.name}
              <EditIcon size="small" />
            </Link>
          ) : (
            plan.name
          )}
        </Text>
      )}
      <Text textAlign={align}>
        {plan.price.value > 0
          ? t('upsellFlow.modal.per_month', {
              price: formatPrice(plan.price.value, plan.price.currency),
            })
          : t('upsellFlow.modal.free')}
      </Text>
    </Box>
  );
}
