import { ChangeEvent } from 'react';
import { Card, Checkbox } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CouponDiscountType } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { InputNumberNimbus, RadioButtonGroup, Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';

export interface DiscountTypeValuesInterface {
  discountType: CouponDiscountType;
  valuePercentage: string;
  valueAbsolute: string;
  includeShipping: boolean;
}

export interface DiscountTypeErrorsInterface {
  valuePercentage: string;
  valueAbsolute: string;
}

interface DiscountTypeProps {
  values: DiscountTypeValuesInterface;
  errors?: Partial<DiscountTypeErrorsInterface>;
  onChange: ({
    value,
    name,
  }: InterfaceNameValue | InterfaceNameBooleanValue) => void;
  disabled?: boolean;
}

export function DiscountType({
  values,
  errors,
  onChange,
  disabled = false,
}: Readonly<DiscountTypeProps>) {
  const t = useTranslationMarketing();
  const { symbol } = useGetCurrency();

  const { valuePercentage, valueAbsolute, discountType, includeShipping } =
    values;
  const {
    valuePercentage: valuePercentageError,
    valueAbsolute: valueAbsoluteError,
  } = errors || {};

  const handleOnChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => onChange({ name: name, value: value });

  const handleIncludeShipping = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => onChange({ name: name, value: checked });

  const percentageInputAppearance = valuePercentageError ? 'danger' : undefined;
  const absoluteInputAppearance = valueAbsoluteError ? 'danger' : undefined;

  return (
    <Card>
      <Card.Header title={t('coupons.couponForm.discountType.title')} />
      <Card.Body>
        <Stack column align="stretch">
          <RadioButtonGroup
            name="discountType"
            options={[
              {
                label: t('coupons.couponForm.discountType.type.percentage'),
                value: 'percentage',
                disabled,
              },
              {
                label: t('coupons.couponForm.discountType.type.absolute'),
                value: 'absolute',
                disabled,
              },
              {
                label: t('coupons.couponForm.discountType.type.shipping'),
                value: 'shipping',
                disabled,
              },
            ]}
            onChange={onChange}
            value={discountType}
          />

          {discountType === 'percentage' && (
            <FormField
              label={t('coupons.couponForm.discountType.percent.label')}
              helpText={t(valuePercentageError || '')}
              showHelpText={!!valuePercentageError}
              appearance={percentageInputAppearance}
              id="valuePercentage"
            >
              <InputNumberNimbus
                type="float"
                name="valuePercentage"
                id="valuePercentage"
                append="%"
                appendPosition="end"
                value={valuePercentage}
                onChange={handleOnChange}
                appearance={percentageInputAppearance}
                disabled={disabled}
              />
            </FormField>
          )}
          {discountType === 'absolute' && (
            <FormField
              label={t('coupons.couponForm.discountType.absolute.label')}
              helpText={t(valueAbsoluteError || '')}
              showHelpText={!!valueAbsoluteError}
              appearance={absoluteInputAppearance}
              id="valueAbsolute"
            >
              <InputNumberNimbus
                type="float"
                name="valueAbsolute"
                id="valueAbsolute"
                append={symbol}
                appendPosition="start"
                value={valueAbsolute}
                onChange={handleOnChange}
                appearance={absoluteInputAppearance}
                disabled={disabled}
              />
            </FormField>
          )}
          {['percentage', 'absolute'].includes(discountType) && (
            <Checkbox
              name="includeShipping"
              label={t('coupons.couponForm.discountType.includeShipping.label')}
              onChange={handleIncludeShipping}
              checked={includeShipping}
              disabled={disabled}
            />
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

DiscountType.Skeleton = Skeleton;
