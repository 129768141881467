import { useState, useCallback } from 'react';

export type WrappedFuncType<P, R> = (...args: [P?]) => Promise<R | void>;
type ClearType = () => void;

type UseAsyncFuncResult<P, R> = [
  WrappedFuncType<P, R>,
  boolean,
  boolean,
  ClearType,
  string,
];

function useAsyncFunc<TProps, TResult>(
  callback: (...args: [TProps?]) => Promise<TResult | void>,
  onSuccess?: () => void,
  onError?: (...args: [TProps?]) => void,
): UseAsyncFuncResult<TProps, TResult> {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const wrappedFunc: WrappedFuncType<TProps, TResult> = useCallback(
    async (...args) => {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await callback(...args);
        onSuccess?.();
        return result;
      } catch (error) {
        setErrorMessage((error as Error).message);
        setIsError(true);
        onError?.(...args);
      } finally {
        setIsLoading(false);
      }
    },
    [callback, onError, onSuccess],
  );

  const clear = useCallback(() => {
    setIsError(false);
    setIsLoading(false);
    setErrorMessage('');
  }, []);

  return [wrappedFunc, isLoading, isError, clear, errorMessage];
}

export default useAsyncFunc;
