import { useToast } from 'commons/hooks';
import useTranslationMetafields from '../useTranslationsMetafields/useTraslationsMetafields';

export default function useMetafieldsToast() {
  const t = useTranslationMetafields();
  const { addToast } = useToast();

  return {
    addMetafieldToast: ({ label, appearance, ...rest }) =>
      addToast({ appearance, label: t(label), ...rest }),
  };
}
