import { useSelector } from 'react-redux';
import { getAdminDisabledReason } from 'domains/Auth/authSlice/authSelectors';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useAdminDisabled,
  useTransactionFeeAdminDisabled,
} from 'domains/Auth/hooks';

export function useDisabledAdminForRoles() {
  const reason = useSelector(getAdminDisabledReason);
  const isTransactionFeeAdminDisabled = useTransactionFeeAdminDisabled();
  const adminDisabled = useAdminDisabled();
  const { validateRoles } = useRoles();

  return (
    validateRoles(undefined, 'full') &&
    (isTransactionFeeAdminDisabled || (adminDisabled && reason === 'expired'))
  );
}
