import { Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { Link } from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface EmptyRelatedProductsProps {
  title: string;
  description: string;
  onOpen: () => void;
}

function EmptyRelatedProducts({
  onOpen,
  title,
  description,
}: Readonly<EmptyRelatedProductsProps>) {
  const t = useTranslationCatalog();
  return (
    <Stack column align="stretch">
      <Text fontSize="highlight" fontWeight="medium">
        {title}
      </Text>
      <Text>{description}</Text>
      <Link
        icon={PlusCircleIcon}
        iconPosition="start"
        appearance="primary"
        onClick={onOpen}
      >
        {t('products.detail.relatedProduct.select')}
      </Link>
    </Stack>
  );
}

export default EmptyRelatedProducts;
