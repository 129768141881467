import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import { getStatusFeatureByKey } from 'domains/Auth/authSlice/authSelectors';

function useStatusFeatureByKey(key: string) {
  const getStatusFeatureByKeyMemo = useMemo(() => getStatusFeatureByKey, []);
  const statusFeature = useSelector((state: RootStateType) =>
    getStatusFeatureByKeyMemo(state, key),
  );

  return statusFeature;
}

export default useStatusFeatureByKey;
