import { Box, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { dateFormat, Format } from 'commons/utils/date';

interface CreatedAtProps {
  date: string;
}

function CreatedAt({ date }: Readonly<CreatedAtProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return (
    <Box display="flex" flexDirection="column" gap="0-5" marginBottom="4">
      <Text fontSize="caption">
        {t('products.stockHistoryModal.createdAt', {
          date: dateFormat(date, Format.DD_MM_YYYY),
        })}
      </Text>
    </Box>
  );
}

export default CreatedAt;
