import { statusType, ProductsInterface } from './types';
import {
  ProductsFiltersInterface,
  ProductsFiltersType,
} from '../productsServices';

const paginationDefault = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0,
  perPage: 20,
  nextPage: null,
};

export const defaultFilters: ProductsFiltersInterface = {
  page: 1,
  q: '',
  sortBy: '',
  categoryId: '',
  published: '',
  freeShipping: '',
  stock: '',
  minStock: '',
  maxStock: '',
  promotionalPrice: '',
  weightDimension: '',
};

export const defaultExportFilters: ProductsFiltersType = {
  categoryId: '',
  published: '',
  freeShipping: '',
  stock: '',
  minStock: '',
  maxStock: '',
  promotionalPrice: '',
  weightDimension: '',
  sortBy: '',
};

export const initialState: ProductsInterface = {
  status: statusType.idle,
  statusSuccess: 'idle',
  statusDetails: statusType.idle,
  statusImage: statusType.idle,
  statusVariants: {},
  currentRequestID: '',
  currentDetailsRequestID: '',
  entities: {},
  variantStock: {},
  summaries: {},
  createHighlightProductStatus: null,
  metafieldsPersistsStatus: {},
  selectProductsList: {
    data: null,
    status: 'idle',
    refreshStatus: 'idle',
    pagination: paginationDefault,
    statusCode: '',
  },
  totalProducts: 0,
  ids: [],
  appliedFilters: {} as ProductsFiltersInterface,
  export: {
    status: statusType.idle,
    totalResults: 0,
  },
  standardVariants: {
    status: statusType.idle,
    data: null,
  },
  statusInventoryLevels: 'idle',
  statusExportProducts: 'idle',
  stockHistory: [],
  statusStockHistory: 'idle',
  importMaxLines: {
    status: 'idle',
    max: 20000,
  },
  fixesAttributes: {
    status: 'idle',
  },
  standardTags: {
    status: statusType.idle,
    data: null,
  },
  relatedProducts: {
    complementary: [],
    alternative: [],
  },
};

export const INITIAL_LOAD_VARIANTS = 50;
