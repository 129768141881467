import { Input, Textarea } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function PhysicalLocationFormSkeleton() {
  const t = useTranslationShipping();
  return (
    <Stack column align="stretch">
      <FormField label={t('physicalLocations.addAndEdit.name')}>
        <Input.Skeleton />
      </FormField>
      <FormField label={t('physicalLocations.addAndEdit.complement')}>
        <Textarea.Skeleton width="100%" />
      </FormField>
      <CancelAndConfirmButtons.Skeleton />
    </Stack>
  );
}

export default PhysicalLocationFormSkeleton;
