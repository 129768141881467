import { ReactElement, useEffect } from 'react';

interface NavTabsContainerInterface {
  children: ReactElement;
}

function NavTabsContainer({
  children,
}: NavTabsContainerInterface): JSX.Element {
  useEffect(() => {
    const body = document.querySelector('body');
    const bodyClassName = 'nimbus--nav-tabs--on';
    if (body) {
      body.classList.add(bodyClassName);
    }
    return () => {
      if (body) {
        body.classList.remove(bodyClassName);
      }
    };
  }, []);
  return children;
}

export default NavTabsContainer;
