import { ChangeEvent } from 'react';
import { Select, Text, Toggle } from '@nimbus-ds/components';
import { QuestionCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import {
  GetDocumentPageSizeSettingResponseDto,
  GetDocumentSettingsResponseDto,
} from '@tiendanube/common';
import { Tooltip } from '@tiendanube/components';
import { InputNumberNimbus, Stack, useResponsive } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import DocumentPageSizeFormSkeleton from './Skeleton';
import { CUSTOM_PAGE_SIZE_CODE, DocumentType } from '../../constants';

interface DocumentPageSizeFormProps {
  settings: GetDocumentSettingsResponseDto;
  type: DocumentType;
  hasIncorrectSizes: {
    width: boolean;
    height: boolean;
  };
  onChangePageSize: (
    name: string,
    value: GetDocumentPageSizeSettingResponseDto,
  ) => void;
  onChangeSetting: (name: string, value: boolean) => void;
}

function DocumentPageSizeForm({
  settings,
  type,
  hasIncorrectSizes,
  onChangePageSize,
  onChangeSetting,
}: Readonly<DocumentPageSizeFormProps>) {
  const t = useTranslationFulfillmentOrders('printManager.pageSize');
  const { isMobile } = useResponsive();

  const {
    pageSize: selectedPageSize,
    availablePageSizes,
    downloadDocument,
  } = settings;

  const handleOnChangePageSize = (value: string) => {
    if (value === CUSTOM_PAGE_SIZE_CODE) {
      const customPageSize = {
        code: CUSTOM_PAGE_SIZE_CODE,
        width: selectedPageSize.width,
        height: selectedPageSize.height,
      };
      onChangePageSize('pageSize', customPageSize);
    } else {
      const pageSize = availablePageSizes.find(({ code }) => code === value);
      if (pageSize) {
        onChangePageSize('pageSize', pageSize);
      }
    }
  };

  const onChangeCustomSize = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const customPageSize = {
      code: CUSTOM_PAGE_SIZE_CODE,
      width: name === 'width' ? value : selectedPageSize.width,
      height:
        name === 'height' ? (value ? value : 'auto') : selectedPageSize.height,
    };
    onChangePageSize('pageSize', customPageSize);
  };

  return (
    <Stack column align="stretch">
      {!isMobile && (
        <>
          <Text>{t('title')}</Text>
          <Stack align="center" alignSelf="flex-start" spacing="tight">
            <Toggle
              name="downloadToggle"
              checked={downloadDocument}
              label={t('downloadDocument')}
              onChange={() =>
                onChangeSetting('downloadDocument', !downloadDocument)
              }
            />
            <Tooltip
              position="right"
              labelIcon={QuestionCircleIcon}
              labelText={t('downloadDocumentInfo')}
            >
              <Text fontSize="caption" color="neutral-background">
                {t('downloadDocumentInfo')}
              </Text>
            </Tooltip>
          </Stack>
        </>
      )}
      {(downloadDocument || isMobile) && (
        <>
          <FormField.Select
            label={t('label')}
            helpText={t('helpText.' + type)}
            showHelpText
            name="pageSize"
            id="pageSize"
            onChange={(event) => handleOnChangePageSize(event.target.value)}
          >
            {availablePageSizes.map(({ code, height, width }) => (
              <Select.Option
                key={code}
                value={code}
                label={`${code} - (${width}cm x${height}cm)`}
                selected={selectedPageSize.code === code}
              />
            ))}
            <Select.Option
              key="custom"
              value="custom"
              selected={selectedPageSize.code === 'custom'}
              label={t('custom')}
            />
          </FormField.Select>
          {selectedPageSize.code === 'custom' && (
            <Stack wrap column align="stretch">
              <Stack.Item fill>
                <FormField
                  label={t('customInputs.width')}
                  showHelpText={hasIncorrectSizes.width}
                  helpText={t('customInputs.widthHelpText')}
                >
                  <InputNumberNimbus
                    id="width"
                    type="float"
                    defaultDecimals={2}
                    append={t('customInputs.append')}
                    appendPosition="end"
                    onChange={onChangeCustomSize}
                    value={selectedPageSize.width}
                    name="width"
                    appearance={hasIncorrectSizes.width ? 'danger' : 'neutral'}
                  />
                </FormField>
              </Stack.Item>
              <Stack.Item fill>
                <FormField
                  label={t('customInputs.height')}
                  showHelpText={hasIncorrectSizes.height}
                  helpText={t('customInputs.heightHelpText')}
                >
                  <InputNumberNimbus
                    id="height"
                    type="float"
                    defaultDecimals={2}
                    append={t('customInputs.append')}
                    appendPosition="end"
                    placeholder={t('customInputs.heightPlaceholder')}
                    onChange={onChangeCustomSize}
                    value={
                      selectedPageSize.height === 'auto'
                        ? ''
                        : selectedPageSize.height
                    }
                    name="height"
                    appearance={hasIncorrectSizes.height ? 'danger' : 'neutral'}
                  />
                </FormField>
              </Stack.Item>
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

DocumentPageSizeForm.Skeleton = DocumentPageSizeFormSkeleton;
export default DocumentPageSizeForm;
