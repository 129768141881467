import { DataList } from '@nimbus-ds/patterns';
import { Repeater } from 'commons/components';
import { DomainsListMobileRow } from './DomainsListMobileRow/DomainsListMobileRow';
import './DomainsListMobile.scss';

export function Skeleton() {
  return (
    <div className="stratus--domains-list-mobile">
      <DataList>
        <Repeater times={3} item={DomainsListMobileRow.Skeleton} />
      </DataList>
    </div>
  );
}
