import {
  LocationResponseDto,
  VariantResponseDto,
  ProductDetailsResponseDto,
  SummaryResponseDto,
  CategoryTabedResponseDto,
} from '@tiendanube/common';
import { Attribute } from './pages/components/Variants/types';

export interface Variant {
  name: string;
}

type VariantsType = {
  inventory_levels?: { location_id: string }[];
};

export type OptionEditType = 'add' | 'discount' | 'replace';

export type OptionType = 'variant_price_change' | 'variant_stock_change';

type textVariantsPlaceType =
  | undefined
  | { i18key: string; value: string | number };

export const hasDefaultVariant = (variants: Variant[]) =>
  variants[0].name.trim() === '';

export const productHasVariants = (variants: Variant[]) =>
  variants.length >= 1 && !hasDefaultVariant(variants);

export const getLocationName = (
  locations?: LocationResponseDto[],
  locationId?: string,
) => locations?.find((location) => location.id === locationId)?.name || '';

export function buildStock(newStock: number | null) {
  const stockResponse = newStock ? `${newStock}` : null;
  if (stockResponse === null) {
    return '0';
  }
  return stockResponse;
}

export function attributeHasValues(attribute: Attribute) {
  return (
    attribute.values.length > 0 &&
    attribute.values.every((value) => value && Object.keys(value).length > 0)
  );
}

const createLocationsFromVariant = (
  variant: VariantsType,
  locations: LocationResponseDto[],
) => {
  const locationObject = {};
  const inventoryLevels = variant.inventory_levels || [];
  for (let i = 0; i < inventoryLevels.length; i++) {
    locationObject[inventoryLevels[i].location_id] = getLocationName(
      locations,
      inventoryLevels[i].location_id,
    );
  }
  return locationObject;
};

const createLocationsFromProduct = (
  product: { summary?: SummaryResponseDto; variants: VariantsType[] },
  locations: LocationResponseDto[],
) => {
  if (product.summary) {
    return product.summary.locationIds.reduce(
      (prev: any, locationId: string) => ({
        ...prev,
        [locationId]: getLocationName(locations, locationId),
      }),
      {},
    );
  }
  return product.variants.reduce((prev: any, variant) => {
    prev = Object.assign(prev, createLocationsFromVariant(variant, locations));
    return prev;
  }, {});
};

export const createTextVariantsPlace = (
  product: {
    summary?: SummaryResponseDto;
    variants: VariantsType[];
  },
  locations: LocationResponseDto[],
): textVariantsPlaceType => {
  const locationsFromVariants = createLocationsFromProduct(product, locations);
  const locationNames = Object.values(locationsFromVariants) as string[];
  const totalLocations = locationNames.length;

  return totalLocations === 1
    ? {
        i18key: 'products.itemList.location',
        value: locationNames[0],
      }
    : totalLocations > 1
    ? { i18key: 'products.itemList.locations', value: totalLocations }
    : undefined;
};

export const createTextSingleVariantPlace = (
  variant: VariantsType,
  locations: LocationResponseDto[],
): textVariantsPlaceType => {
  const locationsFromVariant = createLocationsFromVariant(variant, locations);
  const locationNames = Object.values(locationsFromVariant) as string[];
  const totalLocations = locationNames.length;
  return totalLocations === 1
    ? {
        i18key: 'products.itemList.location',
        value: locationNames[0],
      }
    : totalLocations > 1
    ? { i18key: 'products.itemList.locations', value: totalLocations }
    : undefined;
};

export const stockForLocation = (
  variant: VariantResponseDto,
  locationId?: string,
): number | null =>
  variant.inventory_levels?.find(
    ({ location_id }) => !locationId || location_id === locationId,
  )?.stock ?? null;

export function hasVariants(product: ProductDetailsResponseDto) {
  return !(
    product.variants.length === 1 && product.variants[0].values.length === 0
  );
}

export type VisibilityCategoryType = CategoryTabedResponseDto['visibility'];
export const isCategoryVisible = ({
  visibility,
}: Pick<CategoryTabedResponseDto, 'visibility'>) => visibility === 'visible';
