import { CategoryTabedResponseDto } from '@tiendanube/common';
import { InterfaceNameValue, Select } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface CategoriesSelectProps {
  value: string;
  categories: CategoryTabedResponseDto[];
  onChange: (e: InterfaceNameValue) => void;
}

function CategoriesSelect({
  value,
  categories,
  onChange,
}: CategoriesSelectProps): JSX.Element | null {
  const t = useTranslationCatalog();

  if (!categories) return null;

  const optionProducts = {
    label: t('products.order.productsBySeccion.products'),
    value: '0',
  };
  const optionsCategories = categories.map((a) => ({
    label: a.title,
    value: a.id,
  }));
  const options = [optionProducts, ...optionsCategories];

  return (
    <Select
      label={t('products.order.productsBySeccion.category')}
      name="categoryId"
      value={value}
      options={options}
      onChange={onChange}
    />
  );
}

export default CategoriesSelect;
