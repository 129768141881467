import { InterfaceNameValue, Select } from '@tiendanube/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

export type OptionPeriodType =
  | 'currentDay'
  | 'currentWeek'
  | 'currentMonth'
  | 'currentQuarter'
  | 'currentYear'
  | 'custom';

interface SelectMainPeriodProps {
  value: OptionPeriodType;
  onChange: (e: InterfaceNameValue) => void;
}

function SelectMainPeriod({ value, onChange }: SelectMainPeriodProps) {
  const t = useTranslationStatistics();

  const OPTIONS = [
    { label: t('statistics.filters.currentDay'), value: 'currentDay' },
    { label: t('statistics.filters.currentWeek'), value: 'currentWeek' },
    { label: t('statistics.filters.currentMonth'), value: 'currentMonth' },
    { label: t('statistics.filters.currentQuarter'), value: 'currentQuarter' },
    { label: t('statistics.filters.currentYear'), value: 'currentYear' },
    { label: t('statistics.filters.custom'), value: 'custom' },
  ];

  return (
    <Select
      label={t('statistics.filters.modal.mainPeriod')}
      options={OPTIONS}
      name="mainPeriod"
      value={value}
      onChange={onChange}
    />
  );
}

export default SelectMainPeriod;
