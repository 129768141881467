import { Box } from '@nimbus-ds/components';
import {
  InterfaceNameValue,
  InterfaceSelectOption,
  Select,
} from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { NinetyDaysAlert } from '../OrderPaymentProvidersFilter';

interface OrderApplicationsFilterProps {
  application: string;
  applicationOptions: InterfaceSelectOption[];
  handleChange: (event: InterfaceNameValue) => void;
}

function OrderApplicationsFilter({
  application,
  applicationOptions,
  handleChange,
}: Readonly<OrderApplicationsFilterProps>) {
  const t = useTranslationOrders();

  return (
    <Box>
      <Select
        label={t('filter.application')}
        name="appId"
        value={application}
        options={applicationOptions}
        onChange={handleChange}
      />
      {application !== '' && <NinetyDaysAlert />}
    </Box>
  );
}

export default OrderApplicationsFilter;
