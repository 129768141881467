import { useEffect } from 'react';
import useCheckBackgroundJobs from './useCheckBackgroundJobs';
import { useBackgroundJobs } from '../../hooks';

function BackgroundDispatcher() {
  const { getJobs } = useBackgroundJobs();

  useCheckBackgroundJobs('productsCsvDownload', true);
  useCheckBackgroundJobs('editPricesProducts');
  useCheckBackgroundJobs('customerCsvDownload', true);
  useCheckBackgroundJobs('ordersCsvDownload', true);
  useCheckBackgroundJobs('productsCsvImport');

  useEffect(() => {
    getJobs();
  }, [getJobs]);

  return null;
}

export default BackgroundDispatcher;
