import { ChangeEvent, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Alert,
  Card,
  Grid,
  InterfaceNameValue,
  Stack,
  Text,
} from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import { useResponsive, InputNumber } from 'commons/components';
import { HELP_LINK_WEIGHT_CALCULATION_URL } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import {
  trackingProductDetailDepthBlur,
  trackingProductDetailHeightBlur,
  trackingProductDetailWidthBlur,
} from 'domains/Catalog/Products/tracking';
import ApplyValuesToAllVariants from '../ApplyValuesToAllVariants';
import { OnChangeToAllVariants } from '../Variants/types';
import WeighInput from '../WeightInput';

import './ProductSizes.scss';

interface ProductSizesProps {
  appearance?: 'column' | 'row';
  weight: string;
  depth: string;
  width: string;
  height: string;
  showAlertNoWeightAndDimension: boolean;
  onChange: (data: InterfaceNameValue) => void;
  onChangeToAllVariants?: OnChangeToAllVariants;
  isEdit?: boolean;
}

function ProductSizes({
  appearance = 'column',
  weight,
  depth,
  width,
  height,
  showAlertNoWeightAndDimension,
  onChange,
  onChangeToAllVariants,
  isEdit,
}: ProductSizesProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const language = useTranslationLanguage();
  const { isMobile } = useResponsive();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const isRowAppearance = appearance === 'row' && !isMobile;
  const sender = useTrackFullCatalog();

  const handleOnBlur = () => {
    setIsShowAlert(Number(weight) > 99);
  };

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ name, value });
  };

  const handleBlurDepth = () => {
    sender(() => {
      trackingProductDetailDepthBlur(isEdit);
    });
  };

  const handleBlurWidth = () => {
    sender(() => {
      trackingProductDetailWidthBlur(isEdit);
    });
  };

  const handleBlurHeight = () => {
    sender(() => {
      trackingProductDetailHeightBlur(isEdit);
    });
  };

  const appearanceWeight = showAlertNoWeightAndDimension ? 'warning' : 'none'; // Nimbus 2
  const appearanceMeasures = showAlertNoWeightAndDimension
    ? 'warning'
    : 'default'; // Nimbus 1

  const renderInputsWidthAndHeight = (
    <>
      <Grid.Column>
        <InputNumber
          appearance={appearanceMeasures}
          type="float"
          label={t('products.detail.width')}
          onChange={onChange}
          appendLabel="cm"
          value={width}
          placeholder="30"
          name="width"
          onBlur={handleBlurWidth}
        />
        {onChangeToAllVariants && (
          <ApplyValuesToAllVariants
            value={width}
            onChange={onChangeToAllVariants('width', width)}
          />
        )}
      </Grid.Column>
      <Grid.Column>
        <InputNumber
          appearance={appearanceMeasures}
          type="float"
          label={t('products.detail.height')}
          onChange={onChange}
          appendLabel="cm"
          value={height}
          placeholder="30"
          name="height"
          onBlur={handleBlurHeight}
        />
        {onChangeToAllVariants && (
          <ApplyValuesToAllVariants
            value={height}
            onChange={onChangeToAllVariants('height', height)}
          />
        )}
      </Grid.Column>
    </>
  );
  return (
    <Box data-style="stratus--product-sizes">
      <Card title={t('products.detail.weightAndDimensions')}>
        <Stack column align="flex-start" spacing="loose">
          <Stack wrap align="stretch" column>
            <Alert
              appearance="warning"
              show={isShowAlert}
              title={t('products.detail.alertWeightTitle')}
            >
              {t('products.detail.alertWeightDescription')}
            </Alert>
            <Stack.Item>
              <Text>{`${t('products.detail.infoWeightAndDimensions')}`}</Text>
            </Stack.Item>
            <Alert appearance="warning" show={showAlertNoWeightAndDimension}>
              {t('products.detail.alertNoWeightAndDimentions')}
            </Alert>
            <Stack.Item fill>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <WeighInput
                      appearance={appearanceWeight}
                      label={t('products.detail.weight')}
                      value={weight}
                      onChange={handleChange}
                      placeholder="0.14"
                      onBlur={handleOnBlur}
                      isEdit={isEdit}
                    />
                    {onChangeToAllVariants && (
                      <ApplyValuesToAllVariants
                        value={weight}
                        onChange={onChangeToAllVariants('weight', weight)}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <InputNumber
                      appearance={appearanceMeasures}
                      type="float"
                      label={t('products.detail.depth')}
                      onChange={onChange}
                      appendLabel="cm"
                      value={depth}
                      placeholder="30"
                      name="depth"
                      onBlur={handleBlurDepth}
                    />
                    {onChangeToAllVariants && (
                      <ApplyValuesToAllVariants
                        value={depth}
                        onChange={onChangeToAllVariants('depth', depth)}
                      />
                    )}
                  </Grid.Column>
                  {isRowAppearance && renderInputsWidthAndHeight}
                </Grid.Row>
                {!isRowAppearance && (
                  <Grid.Row>{renderInputsWidthAndHeight}</Grid.Row>
                )}
              </Grid>
            </Stack.Item>
          </Stack>
          <HelpLink
            title={t('helpLink.weightCalculation')}
            linkURL={HELP_LINK_WEIGHT_CALCULATION_URL[language]}
            currentViewTracking={t('helpLink.weightCalculationTrackingName')}
            previousValue="product_details_weight_dimensions"
            appearance="neutral"
            textDecoration="underline"
            icon="external"
          />
        </Stack>
      </Card>
    </Box>
  );
}

export default ProductSizes;
