import {
  Box,
  Card,
  Title,
  Thumbnail,
  Text,
  Alert,
} from '@nimbus-ds/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function Skeleton(): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{t('categories.detail.featuredImage.title')}</Title>
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="2-5">
        <Text>{t('categories.detail.featuredImage.description')}</Text>
        <Thumbnail.Skeleton width="311px" aspectRatio="2/1" />
        <Alert.Skeleton />
      </Box>
    </Card>
  );
}

export default Skeleton;
