import { useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import ModalAside from 'App/components/lab/ModalAside';
import {
  CancelAndSaveButtons,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useCheckoutPaymentOptions } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  ExternalCheckoutCard,
  TransparentCheckoutCard,
  AdvancedSettingsModalSkeleton,
  ModalCheckoutCard,
} from './components';
import { ExternalCheckoutCardValueInterface } from './components/ExternalCheckoutCard/ExternalCheckoutCard';
import { TransparentCheckoutValuesInterface } from './components/TransparentCheckoutCard/TransparentCheckoutCard';

export const TRANSLATE_PREFIX = 'advancedSettingsModal';

interface CheckoutPaymentOptionsInterface {
  transparent: TransparentCheckoutValuesInterface;
  redirect: ExternalCheckoutCardValueInterface[];
  modal: ExternalCheckoutCardValueInterface[];
  shouldShowMercadoPagoPixWarning?: boolean;
}

const defaultValues: CheckoutPaymentOptionsInterface = {
  transparent: {},
  redirect: [],
  modal: [],
  shouldShowMercadoPagoPixWarning: false,
};

interface AdvancedSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface AdvancedSettingsProps {
  onClose: () => void;
}

function AdvancedSettings({ onClose }: Readonly<AdvancedSettingsProps>) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const {
    checkoutPaymentOptions,
    checkoutPaymentOptionsUpdateStatus,
    updateCheckoutPaymentOptions,
    fetchCheckoutPaymentOptions,
    isLoading,
    isSuccess,
    isError,
  } = useCheckoutPaymentOptions();

  useToastStatus({
    status: checkoutPaymentOptionsUpdateStatus,
    success: t('submit.success'),
    error: t('submit.error'),
    onSuccess: onClose,
  });

  const initialValues = checkoutPaymentOptions || defaultValues;

  const { values, isDirty, resetValues, handleOnSubmit, setFieldValue } =
    useForm<CheckoutPaymentOptionsInterface>({
      initialValues,
      onSubmit: (values) => {
        if (isDirty) {
          updateCheckoutPaymentOptions(values);
        } else {
          onClose();
        }
      },
    });

  const { card, debit, offline, pix, wireTransfer } = values.transparent || {};

  const showExternalCheckoutCard = !isEmpty(values.redirect);
  const showTransparentCheckoutCard = !isEmpty(values.transparent);
  const showModalCheckoutCard = !isEmpty(values.modal);

  // TODO: use handleOnChangeGroup from useForm hook when its merged on master
  const handleChangeTransparent = ({ name, value }) => {
    const newTransparent = cloneDeep(values.transparent);
    newTransparent[name].value = value;
    setFieldValue('transparent', newTransparent);
  };

  // TODO: use handleOnChangeGroup from useForm hook when its merged on master
  const handleChangeExternal = ({ name, checked }) => {
    const newRedirect = cloneDeep(values.redirect);
    const selected = newRedirect.find((current) => current.id === name);
    if (selected) selected.enabled = checked;
    setFieldValue('redirect', newRedirect);
  };

  const handleChangeModal = ({ name, checked }) => {
    const newModal = cloneDeep(values.modal);
    const selected = newModal.find((current) => current.id === name);
    if (selected) selected.enabled = checked;
    setFieldValue('modal', newModal);
  };

  const handleOnClose = () => {
    resetValues();
    onClose();
  };

  useEffect(() => {
    fetchCheckoutPaymentOptions();
  }, [fetchCheckoutPaymentOptions]);

  return (
    <>
      {isLoading && <AdvancedSettingsModalSkeleton />}
      {isError && <ErrorScreen onRetry={fetchCheckoutPaymentOptions} />}
      {isSuccess && (
        <>
          {showTransparentCheckoutCard && (
            <TransparentCheckoutCard
              card={card}
              debit={debit}
              offline={offline}
              pix={pix}
              wireTransfer={wireTransfer}
              shouldShowMercadoPagoPixWarning={
                values.shouldShowMercadoPagoPixWarning
              }
              onChange={handleChangeTransparent}
            />
          )}
          {showExternalCheckoutCard && (
            <ExternalCheckoutCard
              redirect={values.redirect}
              onChange={handleChangeExternal}
            />
          )}
          {showModalCheckoutCard && (
            <ModalCheckoutCard
              modal={values.modal}
              onChange={handleChangeModal}
            />
          )}
          <CancelAndSaveButtons
            onSave={handleOnSubmit}
            onCancel={handleOnClose}
            isDisabled={checkoutPaymentOptionsUpdateStatus === 'loading'}
            isLoading={checkoutPaymentOptionsUpdateStatus === 'loading'}
          />
        </>
      )}
    </>
  );
}

function AdvancedSettingsModal({
  isOpen,
  onClose,
}: Readonly<AdvancedSettingsModalProps>) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const headerNavigation = {
    onClick: onClose,
    children: t('headerBack'),
  };

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <AdvancedSettings onClose={onClose} />
    </ModalAside>
  );
}

export default AdvancedSettingsModal;
