import { Table, TableCellProps, Text } from '@nimbus-ds/components';

interface CustomHeaderCellProps {
  width?: TableCellProps['width'];
  children?: string;
}

export function CustomHeaderCell({
  width = 'auto',
  children = '',
}: Readonly<CustomHeaderCellProps>) {
  return (
    <Table.Cell backgroundColor="neutral-surface" as="th" width={width}>
      <Text fontWeight="medium">{children}</Text>
    </Table.Cell>
  );
}
