import { InteractiveList } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

const MOCK_OPTIONS = [
  {
    title: '',
    name: '',
    description: 'skeleton',
  },
  {
    title: '',
    name: '',
    description: 'skeleton',
  },
  {
    title: '',
    name: '',
    description: 'skeleton',
    hideBorder: true,
  },
];

function Skeleton() {
  const t = useTranslationShipping();

  return (
    <CardWithTitle title={t('correios.contract.additionalServices')}>
      <InteractiveList
        mode="multi"
        onChange={() => null}
        options={MOCK_OPTIONS}
        skeleton
      />
    </CardWithTitle>
  );
}

export default Skeleton;
