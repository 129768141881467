import { SVGProps } from 'react';
import { ProvincesRankingPoint } from '../../RankByProvinceChart';
import RankByProvinceValueAndPercentage from '../RankByProvinceValueAndPercentage';

// this constant is used to adjust the alignment of the text with the mark
const Y_POSITION = 10;

interface RankByProvinceYAxisRightProps
  extends SVGProps<SVGForeignObjectElement> {
  index?: number;
  data: ProvincesRankingPoint[];
}

function RankByProvinceYAxisRight({
  index = 0,
  data,
  y,
  x,
  width = 0,
  height = 0,
}: RankByProvinceYAxisRightProps) {
  return (
    <foreignObject
      y={Number(y) - Y_POSITION}
      x={Number(x)}
      width={width}
      height={height}
    >
      <RankByProvinceValueAndPercentage
        textAlign="left"
        value={data[index].value}
        // we omit the percentage since the calculation returned by the BFF
        // is not calculated based on the total value but on the entire top 5
      />
    </foreignObject>
  );
}

export default RankByProvinceYAxisRight;
