import { Box, IconButton, Table, Tag } from '@nimbus-ds/components';
import { Repeater } from 'commons/components';
import { AppsListItemSkeleton } from 'domains/PartnersApps/pages/AppsListPage/components';
import AppsListDesktopHeader from '../AppsListDesktopHeader';

function AppsListDesktopSkeleton() {
  return (
    <Box flexDirection="column" gap="2" display="flex">
      <div className="stratus--apps-list-page-table">
        <Table>
          <AppsListDesktopHeader />
          <Table.Body>
            <Repeater
              times={10}
              item={() => (
                <Table.Row>
                  <Table.Cell>
                    <AppsListItemSkeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <Tag.Skeleton />
                  </Table.Cell>
                  <Table.Cell>
                    <IconButton.Skeleton height="2rem" width="2rem" />
                  </Table.Cell>
                </Table.Row>
              )}
            />
          </Table.Body>
        </Table>
      </div>
    </Box>
  );
}

export default AppsListDesktopSkeleton;
