import { Box, Card, Link, Text, Title } from '@nimbus-ds/components';
import {
  CheckCircleIcon,
  CloseIcon,
  CreditCardIcon,
  EditIcon,
  HomeIcon,
  LockIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserCircleIcon,
} from '@nimbus-ds/icons';
import { NotificationsListItemResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { IconBox, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

const emailIcons = {
  abandonedcheckoutrecover: <ShoppingCartIcon />,
  customer_activate_account: <UserCircleIcon />,
  customer_reset_password: <LockIcon />,
  customer_welcome_account: <HomeIcon />,
  ordercancelled: <CloseIcon />,
  ordercaptured: <CreditCardIcon />,
  orderconfirmation: <CheckCircleIcon />,
  ordershipped: <TruckIcon />,
  orderedited: <EditIcon />,
};

interface EmailItemCardProps {
  email: NotificationsListItemResponseDto;
}

function EmailItemCard({ email }: Readonly<EmailItemCardProps>) {
  const t = useTranslationConfigurations('notifications.notificationsListPage');
  const { goTo } = useNavegate();

  const handleClickEdit = () => goTo(`/settings/notifications/${email.id}`);

  return (
    <div className="stratus--email-item-card">
      <Card padding="small">
        <Card.Body>
          <Box
            display="flex"
            gap="2"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <IconBox
              color="primary-interactive"
              backgroundColor="primary-surface"
              height="32px"
              width="32px"
              minWidth="32px"
            >
              {emailIcons[email.type]}
            </IconBox>
            <Stack column align="flex-start" spacing="tight">
              <Title as="h5" lineHeight="8">
                {email.title}
              </Title>
              <Text>{email.description}</Text>
              <Link
                as="a"
                onClick={handleClickEdit}
                appearance="primary"
                textDecoration="none"
              >
                {t('editContent')}
              </Link>
            </Stack>
          </Box>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EmailItemCard;
