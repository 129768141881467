import { useEffect } from 'react';
import { Alert } from '@nimbus-ds/components';
import { useBoolean } from 'commons/hooks';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields/useTraslationsMetafields';

interface ErrorMetafieldAlertProps {
  status: string;
}

function ErrorMetafieldAlert({ status }: ErrorMetafieldAlertProps) {
  const [error, showError, hideError] = useBoolean(false);

  const t = useTranslationMetafields();
  const errorMessage = t('errorFilterMetafields');

  useEffect(() => {
    if (status === 'errorMetafield') showError();
  }, [showError, status]);

  if (!error) return null;

  return (
    <Alert show appearance="warning" onRemove={hideError}>
      {errorMessage}
    </Alert>
  );
}

export default ErrorMetafieldAlert;
