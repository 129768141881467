import { Alert, Box, Text } from '@nimbus-ds/components';
import { TFunction } from 'i18next';
import { Status as StatusEnum, Country } from '@tiendanube/common/src/enums';
import {
  Button,
  Card,
  DataList,
  InterfaceLabel,
  Label,
  Link,
} from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { useBoolean } from 'commons/hooks';
import { usePartialPayment } from 'domains/Orders/Orders/hooks';
import CardPaymentAmounts from './CardPaymentAmounts/CardPaymentAmounts';
import OtherPaymentMethodModal from './OtherPaymentMethodModal';
import PaymentDropdownButton from './PaymentDropdownButton';
import { CUSTOM_GATEWAY } from '../../../constants';
import { useOrderIsEdited } from '../../../hooks';
import { OrderStatusType } from '../commons/useOrderStatus';

interface NewCardPaymentProps {
  order: OrderStatusType;
  t: TFunction;
  country: Country;
  openGatewayLink: () => void;
  showButton: boolean;
  headerLabel: InterfaceLabel;
  showInstallments: boolean;
  isNuvemPagoPayment: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  openNuvemPago: () => void;
  isArchived: boolean;
  isMarkingAsPaid: boolean;
  paidOrder: (
    gateway?: string,
    gatewayMethod?: string,
    gatewayName?: string,
  ) => void;
  partiallyPaidOrder: (method: string, name?: string) => void;
  isMakingPartialPayment: boolean;
}

function NewCardPayment({
  order: {
    payment: {
      status,
      gateway = '',
      gatewayId,
      method,
      methodCode,
      gatewayLink,
      installments,
    },
    amounts,
    currency,
    products,
    fulfillmentOrders,
  },
  t,
  showButton,
  headerLabel,
  showInstallments,
  isNuvemPagoPayment,
  openGatewayLink,
  country,
  isMobile,
  isDesktop,
  openNuvemPago,
  isArchived,
  isMarkingAsPaid,
  paidOrder,
  partiallyPaidOrder,
  isMakingPartialPayment,
}: NewCardPaymentProps): JSX.Element {
  const isEdited = useOrderIsEdited();
  const [isModalOpen, onOpenModal, onCloseModal] = useBoolean(false);
  const {
    totalDiffAlertText,
    partialPaymentButtonLabel,
    paymentMethodOptions,
  } = usePartialPayment({
    totalDiffPending: amounts.totalDiffPending,
    country: country as string,
    t,
    onOpenModal,
  });

  const rejectedPaymentInfo = isNuvemPagoPayment
    ? ''
    : t('cardPayment.rejectedPaymentInfo', {
        gateway: t(`gateway.${gateway}`, gateway),
      });

  const handlePartialPayment = (
    gateway: string,
    gatewayMethod: string,
    gatewayName?: string,
  ) => {
    status === StatusEnum.PENDING
      ? paidOrder(gateway, gatewayMethod, gatewayName)
      : partiallyPaidOrder(gatewayMethod, gatewayName);
  };

  const handleOtherPaymentMethodSubmit = (method: string, name?: string) => {
    handlePartialPayment(CUSTOM_GATEWAY, method, name);
    onCloseModal();
  };

  return (
    <Card title={t('cardPayment.title')} headerLabel={headerLabel}>
      <DataList ruled={false} spacing="tight">
        <CardPaymentAmounts
          amounts={amounts}
          currency={currency}
          fulfillmentOrders={fulfillmentOrders}
          totalProducts={products.reduce(
            (total, product) => total + product.quantity,
            0,
          )}
          isEdited={isEdited}
        />
        <>
          <DataList.Row id="bottom">
            <DataList.Cell width="fill">
              <>
                <Box paddingBottom="2">
                  <Text fontWeight="bold" color="neutral-textHigh">
                    {`${t('cardPayment.paymentType')}`}
                  </Text>
                </Box>
                <Text as="p" color="neutral-textHigh" fontWeight="medium">{`${t(
                  `gateway.${gateway}`,
                  gateway,
                )}`}</Text>
                {method !== '' && (
                  <Box display="block" paddingY="2">
                    <Label id={method} label={method} />
                  </Box>
                )}
                {showInstallments && (
                  <Text as="p" color="neutral-textHigh">
                    {t('cardPayment.installments', {
                      count: Number(installments),
                    })}
                  </Text>
                )}
                {!!gatewayId && (
                  <>
                    <Text
                      color="neutral-textHigh"
                      as={isDesktop ? 'span' : 'p'}
                    >
                      {status !== StatusEnum.WARNIG_TRANSACTIONS
                        ? t('cardPayment.gatewayId')
                        : rejectedPaymentInfo}
                    </Text>

                    {gatewayLink ? (
                      <Link
                        appearance="primary"
                        icon={ExternalLinkIcon}
                        iconPosition="end"
                        onClick={openGatewayLink}
                      >
                        {gatewayId}
                      </Link>
                    ) : (
                      <Text as={isDesktop ? 'span' : 'p'}>{gatewayId}</Text>
                    )}
                  </>
                )}
                {isNuvemPagoPayment &&
                  status === StatusEnum.WARNIG_TRANSACTIONS &&
                  (country === Country.BR && isMobile ? (
                    <Text as="p">
                      {t('cardPayment.rejectedNuvemPagoPaymentWithoutLink')}
                    </Text>
                  ) : (
                    <Text as="p">
                      {t('cardPayment.rejectedNuvemPagoPaymentInfo1')}{' '}
                      <Link appearance="primary" onClick={openNuvemPago}>
                        {t('cardPayment.rejectedNuvemPagoPaymentInfo2')}
                      </Link>{' '}
                      {t('cardPayment.rejectedNuvemPagoPaymentInfo3')}
                    </Text>
                  ))}
              </>
            </DataList.Cell>
          </DataList.Row>
          {isEdited &&
            !!amounts.totalDiffPending &&
            amounts.totalDiffPending !== 0 && (
              <DataList.Row id="totalDiffAlert">
                <DataList.Cell width="fill">
                  <Alert appearance="warning" title={totalDiffAlertText}>
                    {t('cardPayment.totalDiffAlert.subtitle', {
                      paidByCustomer: amounts.paidByCustomer,
                      total: amounts.total,
                    })}
                  </Alert>
                </DataList.Cell>
              </DataList.Row>
            )}
          {!isArchived &&
            (isEdited && amounts.total !== amounts.paidByCustomer ? (
              <DataList.Row id="footerButton">
                <DataList.Cell width="fill">
                  <PaymentDropdownButton
                    orderGatewayMethod={methodCode}
                    methodOptions={paymentMethodOptions}
                    label={partialPaymentButtonLabel}
                    isMakingPayment={isMakingPartialPayment || isMarkingAsPaid}
                    onPartialPayment={handlePartialPayment}
                  />
                </DataList.Cell>
              </DataList.Row>
            ) : (
              showButton && (
                <DataList.Row id="footerButton">
                  <DataList.Cell width="fill">
                    <Button
                      appearance="primary"
                      onClick={() => paidOrder()}
                      spinner={isMarkingAsPaid}
                      disabled={isMarkingAsPaid}
                    >
                      {`${t(`cardPayment.action.${status}`)}`}
                    </Button>
                  </DataList.Cell>
                </DataList.Row>
              )
            ))}
        </>
      </DataList>
      <OtherPaymentMethodModal
        isOpen={isModalOpen}
        isCharge={amounts.totalDiffPending > 0}
        onClose={onCloseModal}
        onSubmit={handleOtherPaymentMethodSubmit}
      />
    </Card>
  );
}

export default NewCardPayment;
