import { useCallback } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetSubscriptionsQuery } from '../CheckoutApi';

function useGetSubscriptions(initialFetch = true) {
  const { status, data, refetch } = useGetSubscriptionsQuery(undefined, {
    skip: !initialFetch,
  });

  const subscriptions = data?.results;
  const subscriptionForPlan = subscriptions?.find(
    (item) => item.conceptCode === 'plan-cost',
  );
  const total = subscriptions?.reduce(
    (acc, { amountValue }) => acc + amountValue,
    0,
  );

  const currency = subscriptions?.[0]?.amountCurrency;

  const getSubscriptionForConcept = useCallback(
    (concept: ConceptCode, externalReference?: string) =>
      subscriptions?.find(
        (sub) =>
          sub.conceptCode === concept &&
          (!externalReference || externalReference === sub.externalReference),
      ),
    [subscriptions],
  );

  return {
    ...convertStatusTypeToObject(status),
    status: toStatusType(status),
    subscriptions,
    subscriptionForPlan,
    total,
    refreshSubscriptions: refetch,
    currency,
    getSubscriptionForConcept,
  };
}

export default useGetSubscriptions;
