import { ArrowsHorizontalIcon, CheckCircleIcon } from '@nimbus-ds/icons';
import { MenuButtonProperties } from '@nimbus-ds/menubutton';
import { ThemesConfigDraftResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import { THEMES } from 'config/upsellFlowSources';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import { trackingThemeLayoutChange } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { DraftActions, DraftActionsProps } from './DraftActions';
import { PublishDraftButton } from './PublishDraftButton';
import { PublishDraftModal } from './PublishDraftModal';
import { useGoToThemesStore } from '../../hooks';
import { useUpsellFromThemes } from '../../hooks/useUpsellFromThemes';

interface PublishDraftButtonProps {
  draft: ThemesConfigDraftResponseDto;
  isFtpOpen: boolean;
  makeSwitchDraftProminent?: boolean;
  highlightPublishCTA: boolean;
  publishActionLocation: 'menu' | 'toolbar';
  onDraftPublished: () => void;
}

export function DraftToolbar({
  draft,
  highlightPublishCTA,
  isFtpOpen,
  onDraftPublished,
  publishActionLocation,
  makeSwitchDraftProminent = true,
}: Readonly<PublishDraftButtonProps>) {
  const publishInMenu = publishActionLocation === 'menu';

  const t = useTranslationOnline('themesPage.draft.actions');

  const [showPublishModal, openPublishModal, closePublishModal] =
    useModal(false);
  const { replace } = useNavegate();
  const { initUpsellFlow } = useUpsellFromThemes({
    title: t('upsellModalTitle'),
    callback: openPublishModal,
    confirmationTextCta: t('publish'),
    trackingSource: THEMES,
  });

  const handleGoToThemesStore = useGoToThemesStore();

  const handleCloseModal = () => {
    replace(THEMES_ROUTES.themes);
    closePublishModal();
  };

  const handlePublishClick = () => {
    if (draft.isDefault) openPublishModal();
    else initUpsellFlow();
  };

  const handleClickTryNewDesign = async () => {
    trackingThemeLayoutChange();
    await handleGoToThemesStore();
  };

  const publishAction: MenuButtonProperties = {
    label: t('publish'),
    startIcon: publishInMenu ? CheckCircleIcon : undefined,
    onClick: handlePublishClick,
  };

  const switchDraftAction = {
    label: t('tryNewDesign'),
    startIcon: ArrowsHorizontalIcon,
    onClick: handleClickTryNewDesign,
  };

  const extraActions: DraftActionsProps['extraActions'] =
    publishInMenu || !makeSwitchDraftProminent
      ? {
          placement: 1,
          actions: [
            ...(!makeSwitchDraftProminent ? [switchDraftAction] : []),
            ...(publishInMenu ? [publishAction] : []),
          ],
        }
      : undefined;

  return (
    <>
      {!publishInMenu && (
        <PublishDraftButton
          publishAction={publishAction}
          highlightCTA={highlightPublishCTA}
        />
      )}
      <DraftActions previewUrl={draft.url} extraActions={extraActions} />
      <PublishDraftModal
        showModal={showPublishModal}
        isFtpOpen={isFtpOpen}
        onDraftPublished={onDraftPublished}
        onClose={handleCloseModal}
      />
    </>
  );
}
