import { Box, Card, Text, Checkbox, Alert } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  useGetRecurrentPaymentConcepts,
  usePayorderToPay,
} from '../../../hooks';

const COUNTRIES_WITH_TRANSACTION_FEES: Country[] = [Country.AR, Country.BR];

interface IncludeTransactionFeeProps {
  includeTransactionFee: boolean;
  onCheckIncludeTransactionFee: (isChecked: boolean) => void;
}

function IncludeTransactionFee({
  includeTransactionFee,
  onCheckIncludeTransactionFee,
}: Readonly<IncludeTransactionFeeProps>) {
  const t = useTranslationBilling('checkout.includeTransactionFee');

  const handleCheckboxChange = () => {
    onCheckIncludeTransactionFee(!includeTransactionFee);
  };

  const { conceptsToPay } = usePayorderToPay();

  const country = useGetCountry();
  const { isSuccess, recurrentPaymentForTransactionFeesActivated } =
    useGetRecurrentPaymentConcepts();

  const isPayingPlan =
    conceptsToPay.has('plan-cost') && !conceptsToPay.has('transaction-fee');

  if (
    !isSuccess ||
    recurrentPaymentForTransactionFeesActivated ||
    !isPayingPlan ||
    !COUNTRIES_WITH_TRANSACTION_FEES.includes(country)
  )
    return null;

  return (
    <Card>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Text fontWeight="bold">{t('title')}</Text>
          <Box
            display="flex"
            flexDirection="row"
            gap="4"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>{t('checkboxText')}</Text>
            <Checkbox
              name="includeTransactionFee"
              onChange={handleCheckboxChange}
              checked={includeTransactionFee}
            />
          </Box>
          <Alert appearance="neutral">
            <Text fontSize="caption">{t('alertText')}</Text>
          </Alert>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default IncludeTransactionFee;
