import { CalloutCard, Stack } from '@tiendanube/components';
import { StarIcon } from '@tiendanube/icons';

function Skeleton() {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <CalloutCard
          appearance="primary"
          skeleton
          icon={StarIcon}
          title=""
          subtitle=""
        />
      </Stack.Item>
      <Stack.Item>
        <CalloutCard
          appearance="primary"
          skeleton
          icon={StarIcon}
          title=""
          subtitle=""
        />
      </Stack.Item>
      <Stack.Item>
        <CalloutCard
          appearance="primary"
          skeleton
          icon={StarIcon}
          title=""
          subtitle=""
        />
      </Stack.Item>
    </Stack>
  );
}

export default Skeleton;
