import { TFunction } from 'i18next';
import { dateFormat, Format } from 'commons/utils/date';

export const getRegisterDateLabel = (date: string | undefined, t: TFunction) =>
  date
    ? `${t('deliveryMethods.activeShippingMethods.modal.requestedSignupLabel', {
        date: dateFormat(new Date(date).toISOString(), Format.DD_MM_YYYY),
        interpolation: { escapeValue: false },
      })}`
    : undefined;
