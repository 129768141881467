import {
  Card,
  Title,
  Checkbox,
  Button,
  Input,
  Text,
} from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function WhatsappFormSkeleton() {
  const t = useTranslationConfigurations('whatsapp');

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">{t('title')}</Title>
        </Card.Header>
        <Stack column spacing="tight" align="stretch">
          <Text.Skeleton width="300px" />
          <Input.Skeleton />
        </Stack>
        <Card.Footer>
          <Checkbox.Skeleton width="300px" />
        </Card.Footer>
      </Card>
      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button.Skeleton width="120px" />
        </Stack.Item>
      </Stack>
    </>
  );
}
