import { DataList } from '@tiendanube/components';
import InvoiceHistoryDataTableRowSkeleton from './components/InvoiceHistoryDataTableRowSkeleton';

function InvoiceHistoryDataTableSkeleton(): JSX.Element {
  return (
    <DataList>
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
      <InvoiceHistoryDataTableRowSkeleton />
    </DataList>
  );
}

export default InvoiceHistoryDataTableSkeleton;
