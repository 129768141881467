import { ReactElement } from 'react';
import { useRoles } from 'domains/Auth/authSlice/useRoles';

interface ShowByRoleProps {
  children?: ReactElement | null | false;
  includeRoles?: string | string[];
  excludeRoles?: string | string[];
  rolesWithoutFilter?: boolean;
}

function ShowByRole({
  children,
  includeRoles,
  excludeRoles,
  rolesWithoutFilter,
}: ShowByRoleProps) {
  const { validateRoles, validateRolesWithoutFilter } = useRoles();
  const validate = rolesWithoutFilter
    ? validateRolesWithoutFilter
    : validateRoles;

  if (validate(includeRoles, excludeRoles)) {
    return children ? children : null;
  }

  return null;
}

export default ShowByRole;
