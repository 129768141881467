import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Input, Link, Select, Stack, Text } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackinProductDetailMPNBlur } from 'domains/Catalog/Products/tracking';
import ApplyValuesToAllVariants from '../ApplyValuesToAllVariants';
import { OnChangeToAllVariants } from '../Variants/types';

interface ShoppingLinksProps {
  mpn?: string;
  ageGroup?: string;
  gender?: string;
  onChange: ({ name, value }: { name: string; value: string }) => void;
  appearance?: 'column' | 'row';
  onChangeToAllVariants: OnChangeToAllVariants;
}

function ShoppingLinks({
  mpn = '',
  ageGroup = '',
  gender = '',
  onChange,
  appearance = 'column',
  onChangeToAllVariants,
}: ShoppingLinksProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const ageGroupOptions = [
    { label: t('products.detail.ageGroupValues.newborn'), value: 'newborn' },
    { label: t('products.detail.ageGroupValues.infant'), value: 'infant' },
    { label: t('products.detail.ageGroupValues.toddler'), value: 'toddler' },
    { label: t('products.detail.ageGroupValues.kids'), value: 'kids' },
    { label: t('products.detail.ageGroupValues.adult'), value: 'adult' },
  ];

  const genderOptions = [
    { label: t('products.detail.genderValues.female'), value: 'female' },
    { label: t('products.detail.genderValues.male'), value: 'male' },
    { label: t('products.detail.genderValues.unisex'), value: 'unisex' },
  ];

  const sender = useTrackFullCatalog();

  const handleBlur = () => {
    sender(trackinProductDetailMPNBlur);
  };

  return (
    <Card title={t('products.detail.socialShopping')}>
      <Stack column align="stretch">
        <Stack.Item>
          <Text>{t('products.detail.socialShoppingInfo')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Stack
            column={appearance === 'column'}
            wrap={appearance === 'row'}
            align="stretch"
          >
            <Stack.Item fill>
              <Input
                name="mpn"
                value={mpn}
                onChange={onChange}
                label={t('products.detail.socialShoppingMpn')}
                maxLength={30}
                onBlur={handleBlur}
              />
              <ApplyValuesToAllVariants
                value={mpn}
                onChange={onChangeToAllVariants('mpn', mpn)}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Select
                label={t('products.detail.ageGroup')}
                name="ageGroup"
                placeholder={t('products.detail.ageGroupPlaceholder')}
                options={ageGroupOptions}
                value={ageGroup || ''}
                onChange={onChange}
              />
              <ApplyValuesToAllVariants
                value={ageGroup}
                onChange={onChangeToAllVariants('ageGroup', ageGroup)}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Select
                label={t('products.detail.gender')}
                name="gender"
                placeholder={t('products.detail.genderPlaceholder')}
                options={genderOptions}
                value={gender || ''}
                onChange={onChange}
              />
              <ApplyValuesToAllVariants
                value={gender}
                onChange={onChangeToAllVariants('gender', gender)}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Link
            href={t('products.detail.socialShoppingHelpLink')}
            appearance="default"
            underline
            icon={ExternalLinkIcon}
            iconPosition="end"
          >
            {t('products.detail.socialShoppingHelp')}
          </Link>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default ShoppingLinks;
