import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationAccount(keyPrefix?: string): TFunction {
  const { t } = useTranslation(Domain.ACCOUNT, { keyPrefix });

  return t;
}

export default useTranslationAccount;
