import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MetafieldsListV2Dto, OwnerResourceType } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getMetafieldsProductsAndVariantsData,
  getMetafieldsProductsAndVariantsStatus,
} from 'domains/Metafields/metafieldsSlice';
import { fetchMetafieldsProductsAndVariantsAction } from 'domains/Metafields/metafieldsSlice/metafieldsSlice';
import useVariantsMetafieldsAccess from '../../useVariantsMetafieldsAccess';

const getMetafieldsByResource = (
  metafields: MetafieldsListV2Dto,
  resource?: OwnerResourceType,
) => {
  let internals = metafields?.internals;
  let fromApi = metafields?.fromApi;

  const productVariantsCallback = (metafield) =>
    metafield.resource === 'product_variant';
  const productsCallback = (metafield) => metafield.resource === 'product';

  if (resource === 'product_variant') {
    internals = metafields?.internals.filter(productVariantsCallback);
    fromApi = metafields?.fromApi.filter(productVariantsCallback);
  } else if (resource === 'product') {
    internals = metafields?.internals.filter(productsCallback);
    fromApi = metafields?.fromApi.filter(productsCallback);
  }

  return { internals, fromApi };
};

export default function useGetProductsAndVariantsMetafields(
  resource?: OwnerResourceType,
) {
  const dispatch = useAppDispatch();
  const metafields = useSelector(getMetafieldsProductsAndVariantsData);
  const { isLoading, isSuccess, isError, isIdle } = useSelector(
    getMetafieldsProductsAndVariantsStatus,
  );
  const { isMetafieldConfigEnabled, isMetafieldFiltersEnabled } =
    useVariantsMetafieldsAccess();

  const fetchMetafields = useCallback(
    () => dispatch(fetchMetafieldsProductsAndVariantsAction()),
    [dispatch],
  );

  const { internals, fromApi } = getMetafieldsByResource(metafields, resource);

  const hasInternals = !!internals && Object.keys(internals).length > 0;
  const hasExternals = !!fromApi && Object.keys(fromApi).length > 0;

  const status = { isLoading, isError, isSuccess, isIdle };

  return {
    metafields: hasInternals ? internals : null,
    apiMetafields: hasExternals ? fromApi : null,
    status,
    isIdle,
    fetchMetafields,
    hasExternals,
    hasInternals,
    isAvailable: isMetafieldConfigEnabled,
    isFilterAvailable: isMetafieldFiltersEnabled,
  };
}
