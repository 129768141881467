import { useEffect } from 'react';
import {
  BaseCard,
  InteractiveListPriv,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PaymentsHistoryCardSkeleton from './PaymentsHistoryCardSkeleton';
import { usePaymentsHistory } from '../../hooks';
import TransactionFeesErrorState from '../TransactionFeesErrorState';

function PaymentsHistoryCard(): JSX.Element {
  const t = useTranslationBilling();
  const {
    fetchPaymentsHistory,
    paymentsHistory,
    isLoading,
    isSuccess,
    isError,
  } = usePaymentsHistory();
  const { goTo } = useNavegate();

  useEffect(fetchPaymentsHistory, [fetchPaymentsHistory]);

  const goToDetails = (year: string) => {
    goTo(
      `${TRANSACTION_FEES_ROUTES.transactionFeesMonthSelection.replace(
        ':year',
        year,
      )}`,
    );
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack spacing="base" column align="flex-start">
          <Title type="h3">{`${t(
            'transactionFeesPage.paymentsHistoryCard.title',
          )}`}</Title>
        </Stack>
      </BaseCard.Header>
      {isLoading && <PaymentsHistoryCardSkeleton />}
      {isSuccess && paymentsHistory && (
        <BaseCard.Body>
          <InteractiveListPriv>
            {paymentsHistory.map(({ year }) => (
              <InteractiveListPriv.ActionItem
                name={year}
                title={year}
                onChange={() => goToDetails(year)}
                key={year}
              />
            ))}
          </InteractiveListPriv>
        </BaseCard.Body>
      )}
      {isSuccess && (!paymentsHistory || paymentsHistory?.length === 0) && (
        <BaseCard.Body>
          <Text size="highlight">
            {t('transactionFeesPage.paymentsHistoryCard.emptyMessage')}
          </Text>
        </BaseCard.Body>
      )}
      {isError && <TransactionFeesErrorState onClick={fetchPaymentsHistory} />}
    </BaseCard>
  );
}

export default PaymentsHistoryCard;
