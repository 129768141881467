/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-statements */
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Card, Icon, Title } from '@nimbus-ds/components';
import { DownloadIcon, PrinterIcon } from '@nimbus-ds/icons';
import {
  FulfillmentOrderAssignedLocationDto,
  GetDocumentPageSizeSettingResponseDto,
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  Stack,
  useResponsive,
} from 'commons/components';
import {
  trackingShippingPrintDocumentCancelButtonClick,
  trackingShippingPrintDocumentSettings,
} from 'domains/FulfillmentOrders/tracking';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { DocumentType } from '../../constants';
import useDocumentSettings from '../../hooks/useDocumentsSettings';
import {
  getAssignedLocationsByInformation,
  getOrderNumberAndFulfillmentOrderNumber,
  validateSizes,
} from '../../utils';
import DocumentLocationForm from '../DocumentLocationForm';
import DocumentPageSizeForm from '../DocumentPageSizeForm';
import DocumentSettingsForm from '../DocumentSettingsForm';
import FulfillmentOrderSelectedHeader from '../FulfillmentOrderSelectedHeader';

export interface PageSizesValidation {
  width: boolean;
  height: boolean;
}

interface PrintDocumentFormProps {
  type: DocumentType;
  settings: GetDocumentSettingsResponseDto;
  isPrinting: boolean;
  locationId?: string;
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  hasOca: boolean;
  setSettings: Dispatch<SetStateAction<GetDocumentSettingsResponseDto>>;
  setLocationId: Dispatch<SetStateAction<string>>;
  handleDownload: () => void;
}

function PrintDocumentForm({
  type,
  settings,
  isPrinting,
  locationId,
  information,
  hasOca,
  setSettings,
  setLocationId,
  handleDownload,
}: Readonly<PrintDocumentFormProps>) {
  const t = useTranslationFulfillmentOrders('printManager');
  const { goTo } = useNavegate();
  const { isMobile } = useResponsive();
  const { isLoading, documentSettings, updateSetting } = useDocumentSettings();
  const [hasIncorrectSizes, setHasIncorrectSizes] =
    useState<PageSizesValidation>({
      width: false,
      height: false,
    });

  const onChangeSetting = (name: string, value: boolean) => {
    setSettings({
      ...settings,
      [name]: value,
    });
    updateSetting({ [name]: value });
    trackingShippingPrintDocumentSettings(type, name, value);
  };

  const onChangePageSize = (
    name: string,
    value: GetDocumentPageSizeSettingResponseDto,
  ) => {
    setSettings({
      ...settings,
      pageSize: value,
    });

    const validatedSizes = validateSizes(value);
    setHasIncorrectSizes(validatedSizes);
    if (!validatedSizes.width && !validatedSizes.height) {
      updateSetting({ [name]: value });
    }
  };

  const allAssignedLocations = information
    ? getAssignedLocationsByInformation(information)
    : [];

  const assignedLocationsWithoutDuplicates: FulfillmentOrderAssignedLocationDto[] =
    [
      ...new Map(
        allAssignedLocations.map((location) => [location.id, location]),
      ).values(),
    ];

  const orderAndFulfillmentOrderNumbers =
    getOrderNumberAndFulfillmentOrderNumber(information ?? []);

  const handleSelectLocation = (e: ChangeEvent<HTMLSelectElement>) => {
    setLocationId(e.target.value);
  };

  const isLoadingInformation = !information || information.length === 0;

  const hasPickupType =
    information?.some(
      (info) => info.fulfillmentOrder?.shipping?.type === 'pickup',
    ) || false;

  const handleCancel = () => {
    trackingShippingPrintDocumentCancelButtonClick(type, 'footer');
    goTo('/orders');
  };

  useEffect(() => {
    if (documentSettings) {
      setSettings(documentSettings);
    }
  }, [documentSettings, setSettings]);

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('formTitle')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <FulfillmentOrderSelectedHeader
            labels={orderAndFulfillmentOrderNumbers}
            isLoading={!information}
          />
          {assignedLocationsWithoutDuplicates.length > 1 && (
            <DocumentLocationForm
              locationId={locationId}
              type={type}
              locations={assignedLocationsWithoutDuplicates}
              handleSelectLocation={handleSelectLocation}
            />
          )}
          {type !== DocumentType.CONTENT_DECLARATION && (
            <>
              <DocumentSettingsForm
                settings={settings}
                isLoading={isLoading && !information}
                onChangeSetting={onChangeSetting}
                hasPickup={hasPickupType}
              />
              {!isLoading && information && !hasOca && (
                <DocumentPageSizeForm
                  type={type}
                  settings={settings}
                  onChangePageSize={onChangePageSize}
                  hasIncorrectSizes={hasIncorrectSizes}
                  onChangeSetting={onChangeSetting}
                />
              )}
            </>
          )}
        </Stack>
      </Card.Body>
      <Card.Footer>
        <CancelAndConfirmButtons
          onCancel={handleCancel}
          onConfirm={handleDownload}
          confirmText={
            <>
              <Icon
                color="currentColor"
                source={
                  (settings.downloadDocument || isMobile) && !hasOca ? (
                    <DownloadIcon size="small" />
                  ) : (
                    <PrinterIcon size="small" />
                  )
                }
              />
              {(settings.downloadDocument || isMobile) && !hasOca
                ? t('download')
                : t('print')}
            </>
          }
          isLoading={isLoading || isPrinting || isLoadingInformation}
          isConfirmDisabled={
            hasIncorrectSizes.width || hasIncorrectSizes.height
          }
        />
      </Card.Footer>
    </Card>
  );
}

export default PrintDocumentForm;
