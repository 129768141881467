import { useEffect } from 'react';
import { Prompt, useParams } from 'react-router';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  useGetDetailCustomShipping,
  useUpdateCustomShipping,
} from '../../hooks';
import ShippingPage from '../components/ShippingPage';
import { CustomShippingForm } from '../DeliveryMethodsPage/components';
import { newCustomShippingSchema } from '../NewCustomShippingPage/newCustomShippingSchema';

function EditCustomShippingPage() {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const { id } = useParams<{ id: string }>();

  const {
    detailCustomShipping,
    fetchDetailCustomShipping,
    isLoading: isLoadingDetailCustomShipping,
    isError: isErrorDetailCustomShipping,
  } = useGetDetailCustomShipping();

  const { updateDetailCustomShipping, status } = useUpdateCustomShipping();

  const {
    locations,
    isLoading: isLoadingLocations,
    fetchLocations,
    isError: isErrorLocations,
  } = useLocations();
  const customShippingSchema = newCustomShippingSchema();

  const isLoading = isLoadingDetailCustomShipping || isLoadingLocations;
  const isError = isErrorDetailCustomShipping || isErrorLocations;

  const handleRetry = () => {
    fetchDetailCustomShipping(id);
    fetchLocations();
  };

  const {
    values,
    handleChange,
    errors,
    handleOnSubmit,
    setFieldValue,
    isDirty,
  } = useForm({
    initialValues: detailCustomShipping,
    validationSchema: customShippingSchema,
    onSubmit: (values) => {
      if (values) updateDetailCustomShipping(id, values);
    },
  });

  useToastStatus({
    status,
    success: t('deliveryMethods.customShipping.toastSuccess.edited'),
    error: t('deliveryMethods.customShipping.toastError.edition'),
    onSuccess() {
      goBack();
    },
  });

  useEffect(() => {
    if (detailCustomShipping?.id !== id) fetchDetailCustomShipping(id);
  }, [fetchDetailCustomShipping, id, detailCustomShipping]);

  return (
    <ShippingPage
      handleSave={handleOnSubmit}
      title={t('deliveryMethods.customShipping.titleEdit')}
      isUpdating={status === 'loading'}
      isError={isError}
      isLoading={isLoading && !isError}
    >
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />
      {isLoading && !isError && <CustomShippingForm.Skeleton />}
      {isError && (
        <ErrorScreen
          description={t(
            'deliveryMethods.activeShippingMethods.error.description',
          )}
          onRetry={handleRetry}
        />
      )}
      {values && !isError && !isLoading && (
        <CustomShippingForm
          values={values}
          locations={locations}
          handleChange={handleChange}
          fetchLocations={fetchLocations}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      )}
    </ShippingPage>
  );
}

export default EditCustomShippingPage;
