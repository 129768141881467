import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  PaymentProviderDto,
  UpdatePaymentProviderSettingsRequestDto,
} from '@tiendanube/common';
import { DEFAULT_ALLOW_DISCOUNT_COMBINATION } from '@tiendanube/common/src/domains/payments/providers/Constants';
import { RootStateType, useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { invokeEventPayments } from 'domains/Payments/tracking';
import {
  cleanUpdateStatusAction,
  getInstalledPaymentProviderById,
  getInstalledPaymentProviderUpdateStatus,
  updatePaymentProviderSettingsAction,
} from '../../paymentProvidersSlice';
import {
  useDisableInstalledPaymentProvider,
  useEnableInstalledPaymentProvider,
} from '../index';
import useInstalledPaymentProviders from '../useInstalledPaymentProviders';

interface UsePaymentProviderSettingsResult {
  isLoading: boolean;
  isError: boolean;
  updateStatus: StatusType;
  paymentProvider?: PaymentProviderDto;
  paymentProviderSettingValues: UpdatePaymentProviderSettingsRequestDto;
  fetchInstalledPaymentProviders: () => void;
  updatePaymentProviderSettings: (
    payload: UpdatePaymentProviderSettingsRequestDto & { appId: string },
  ) => void;
  enableInstalledPaymentProvider: () => void;
  disableInstalledPaymentProvider: () => void;
}

function usePaymentProviderSettings(
  id: string,
): UsePaymentProviderSettingsResult {
  const dispatch = useAppDispatch();

  const { isLoading, isError, fetchInstalledPaymentProviders } =
    useInstalledPaymentProviders();
  const paymentProvider = useSelector((state: RootStateType) =>
    getInstalledPaymentProviderById(state, id),
  );
  const updateStatus = useSelector(getInstalledPaymentProviderUpdateStatus);
  const { disable } = useDisableInstalledPaymentProvider(paymentProvider);
  const { enable } = useEnableInstalledPaymentProvider(paymentProvider);

  useEffect(() => {
    if (!paymentProvider) {
      fetchInstalledPaymentProviders();
    }
  }, [fetchInstalledPaymentProviders, paymentProvider]);

  const updatePaymentProviderSettings = async (payload) => {
    await dispatch(updatePaymentProviderSettingsAction(payload));
    dispatch(cleanUpdateStatusAction());
    invokeEventPayments('updatedProvider', id);
  };

  const paymentProviderSettingValues = useMemo(
    () => ({
      minInstallmentValues: paymentProvider?.minInstallmentValues?.reduce(
        (values, { currency, value }) => ({ ...values, [currency]: value }),
        {},
      ),
      discounts: paymentProvider?.discounts?.reduce(
        (values, { paymentMethodType, percentage }) => ({
          ...values,
          [paymentMethodType]: percentage,
        }),
        {},
      ),
      allowDiscountCombination:
        paymentProvider?.discounts?.[0]?.allowDiscountCombination ??
        DEFAULT_ALLOW_DISCOUNT_COMBINATION,
    }),
    [paymentProvider],
  );

  return {
    isLoading,
    isError,
    updateStatus,
    paymentProvider,
    paymentProviderSettingValues,
    fetchInstalledPaymentProviders,
    updatePaymentProviderSettings,
    enableInstalledPaymentProvider: enable,
    disableInstalledPaymentProvider: disable,
  };
}

export default usePaymentProviderSettings;
