import { useCallback } from 'react';
import {
  Box,
  Button,
  ButtonBaseProps,
  Modal,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import { ConceptCode } from '@tiendanube/common';
import { useToastStatus } from 'commons/hooks';
import { useToggle } from 'commons/useToggle';
import { useGetRecurrentPaymentConcepts } from 'domains/Billing/Checkout/hooks';
import { useCancelRecurrentPayment } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface CancelRecurrentPaymentButtonProps
  extends Omit<ButtonBaseProps, 'children'> {
  conceptCode: ConceptCode;
  externalReference?: string;
  trackEvent?: () => void;
}

function CancelRecurrentPaymentButton({
  conceptCode,
  externalReference,
  trackEvent,
  ...buttonProps
}: Readonly<CancelRecurrentPaymentButtonProps>) {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.cancelRecurrentPaymentButton',
  );

  const {
    recurrentPaymentForConcept,
    recurrentPaymentForTransactionFeesActivated,
  } = useGetRecurrentPaymentConcepts();
  const [cancelationModalOpen, toggleCancelationModal] = useToggle(false);

  const { status, resetStatus, isLoading, cancelRecurrentPayment, resetData } =
    useCancelRecurrentPayment();

  const shouldCancelTransactionFees =
    recurrentPaymentForTransactionFeesActivated && conceptCode === 'plan-cost';

  const handleCancel = () => {
    trackEvent?.();
    cancelRecurrentPayment({
      concepts: [
        { code: conceptCode, externalReference },
        ...(externalReference
          ? [{ code: conceptCode, externalReference: undefined }]
          : []),
        ...(shouldCancelTransactionFees
          ? [{ code: 'transaction-fee' as ConceptCode }]
          : []),
      ],
    });
  };

  const onSuccess = useCallback(() => {
    resetStatus();
    resetData();
    toggleCancelationModal();
  }, [resetStatus, toggleCancelationModal, resetData]);

  const recurrentPaymentActivated = recurrentPaymentForConcept(
    conceptCode,
    externalReference,
  );

  useToastStatus({
    status,
    error: t('cancelationError'),
    success: t('cancelationSuccess'),
    onSuccess,
  });

  if (!recurrentPaymentActivated) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Button
          appearance="primary"
          onClick={toggleCancelationModal}
          {...buttonProps}
        >
          {t('button')}
        </Button>
      </Box>
      <Modal open={cancelationModalOpen} onDismiss={toggleCancelationModal}>
        <Modal.Header title={t('modal.title')} />
        <Modal.Body padding="none">
          <Box display="flex" flexDirection="column" gap="3">
            <Text>
              {t(
                `modal.messages.${
                  conceptCode === 'plan-cost' &&
                  recurrentPaymentForTransactionFeesActivated
                    ? 'planAndTransactionFees'
                    : 'generic'
                }`,
              )}
            </Text>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggleCancelationModal} disabled={isLoading}>
            {t('modal.abortButton')}
          </Button>
          <Button
            onClick={handleCancel}
            appearance="danger"
            disabled={isLoading}
          >
            {isLoading && <Spinner color="currentColor" size="small" />}
            {t('modal.continueButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CancelRecurrentPaymentButton;
