import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  EnableProviderRequestDto,
  PaymentProviderDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  cleanUpdateStatusAction,
  enablePaymentProviderAction,
  getInstalledPaymentProviderUpdateStatus,
} from '../../paymentProvidersSlice';
import { HookUtils } from '../utils';

interface UseEnableInstalledPaymentProviderResult {
  enable: (payload?: EnableProviderRequestDto) => Promise<void>;
  updateStatus: StatusType;
}

function useEnableInstalledPaymentProvider(
  paymentProvider?: PaymentProviderDto | string,
): UseEnableInstalledPaymentProviderResult {
  const dispatch = useAppDispatch();
  const updateStatus = useSelector(getInstalledPaymentProviderUpdateStatus);

  const enable = useCallback(
    async (payload?: EnableProviderRequestDto) => {
      await dispatch(
        enablePaymentProviderAction({
          providerAppId:
            typeof paymentProvider === 'string'
              ? paymentProvider
              : HookUtils.getProviderId(paymentProvider),
          payload,
        }),
      );
      dispatch(cleanUpdateStatusAction());
    },
    [dispatch, paymentProvider],
  );

  return {
    enable,
    updateStatus,
  };
}

export default useEnableInstalledPaymentProvider;
