import {
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetLanguagesQuery,
} from '../../I18n/i18nApi';

function useEnabledCountries() {
  const {
    data: countriesList,
    isLoading: countriesListLoading,
    isSuccess: countriesListSuccess,
    isError: countriesListError,
    refetch: countriesListRefetch,
  } = useGetCountriesQuery();

  const {
    data: currenciesList,
    isLoading: currenciesListLoading,
    isSuccess: currenciesListSuccess,
    isError: currenciesListError,
    refetch: currenciesListRefetch,
  } = useGetCurrenciesQuery();

  const {
    data: languagesList,
    isLoading: languagesListLoading,
    isSuccess: languagesListSuccess,
    isError: languagesListError,
    refetch: languagesListRefetch,
  } = useGetLanguagesQuery();

  const isLoading =
    countriesListLoading || currenciesListLoading || languagesListLoading;
  const isSuccess =
    countriesListSuccess && currenciesListSuccess && languagesListSuccess;
  const isError =
    countriesListError || currenciesListError || languagesListError;

  const onRefetch = () => {
    countriesListRefetch();
    currenciesListRefetch();
    languagesListRefetch();
  };
  return {
    isLoading,
    isSuccess,
    isError,
    countriesList,
    currenciesList,
    languagesList,
    onRefetch,
  };
}

export default useEnabledCountries;
