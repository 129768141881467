const ADMIN_AUTH_NEW_ADMIN_ROUTE = '/admin/auth/new-admin/';

export default function goToAuthAdminRedirect() {
  /**
   * To generate a URL valid to redirect we need to remove the hash and encode the URL
   * according RFC6749 (https://datatracker.ietf.org/doc/html/rfc6749#section-3.1.2)
   * the redirect_uri must not include a fragment component
   **/
  const currentURL = window.location.href;
  const currentURLWithoutHash = currentURL.replace(/#.*$/, '');
  const currentUrlEnconded = encodeURIComponent(currentURLWithoutHash);

  window.location.href = `${ADMIN_AUTH_NEW_ADMIN_ROUTE}?redirect_uri=${currentUrlEnconded}`;
}
