import { BaseCard, Input, Stack } from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';
import { BrazilianAddress } from '../../../../../Addresses';

interface BrazilianFiscalDataFormSkeletonProps {
  onCancel: () => void;
}

function BrazilianFiscalDataFormSkeleton({
  onCancel,
}: BrazilianFiscalDataFormSkeletonProps): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Input.Skeleton />
            <Input.Skeleton />
            <BrazilianAddress.Skeleton />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        onCancel={onCancel}
        onSave={() => null}
        isDisabled
      />
    </>
  );
}

export default BrazilianFiscalDataFormSkeleton;
