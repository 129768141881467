import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Button, Grid, StatePriv, Thumbnail } from '@tiendanube/components';
import { PictureIcon } from '@tiendanube/icons';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageGalleryState } from '../../../ImageGallery/ImageGallery';
import { Variant } from '../../types';

interface VariantImageModalProps {
  isShow: boolean;
  images: ImageGalleryState[];
  variants: Variant[];
  variantSelected: number;
  onChangeVariantSelected: (i: number) => void;
  onChangeVariant: (i: number, v: Partial<Variant>) => void;
  onClose: () => void;
}

function VariantImageModal({
  isShow,
  images,
  variants,
  variantSelected,
  onChangeVariantSelected,
  onChangeVariant,
  onClose,
}: VariantImageModalProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CATALOG);

  const language = useGetLanguage();
  const { scrollToTop } = useLayoutScroll();
  const variant = variants[variantSelected];

  /**
   * The variant previously selected could be deleted
   * then we don't render the modal
   */
  if (!variant) {
    return null;
  }

  const onPrevious = () => {
    if (variantSelected === 0) {
      onChangeVariantSelected(variants.length - 1);
      return;
    }
    onChangeVariantSelected(variantSelected - 1);
  };
  const onNext = () => {
    if (variantSelected === variants.length - 1) {
      onChangeVariantSelected(0);
      return;
    }
    onChangeVariantSelected(variantSelected + 1);
  };

  const handleChangeVariantImage = (image) => {
    onChangeVariant(variantSelected, {
      imageUrl: image.src,
      imageId: image.id,
    });
  };

  const handleOnScrollToProductImages = () => {
    onClose();
    scrollToTop();
  };

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerContent={
        <HeaderContent
          title={variant.name}
          paginationPrevious={onPrevious}
          paginationNext={onNext}
        />
      }
    >
      {images.length > 0 && (
        <Grid spacing="tight">
          <Grid.Row>
            {images.map((image) => (
              <Grid.Column key={image.id} sm={2} md={2} lg={2}>
                <Thumbnail.Select
                  id={image.id}
                  aspectRatio="1-1"
                  width="100%"
                  src={image.src}
                  altText={image.alt?.[language] || ''}
                  selected={variant.imageUrl === image.src}
                  onSelect={() => handleChangeVariantImage(image)}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      )}
      {images.length === 0 && (
        <StatePriv
          icon={PictureIcon}
          title={t('products.detail.variantImageAlert')}
          text={t('products.detail.variantImageAlertInfo')}
          action={{
            children: (
              <Button
                appearance="secondary"
                onClick={handleOnScrollToProductImages}
              >
                {`${t('products.detail.variantImageAlertLink')}`}
              </Button>
            ),
          }}
        />
      )}
    </ModalAside>
  );
}

export default VariantImageModal;
