import { logEvent } from 'commons/utils/tracking';
import { PARTNERS_APPS_ADD_CLICK } from 'config/trackingEvents';

export const trackingPartnersAppsAddClick = (
  appId: string,
  appName: string | undefined,
) => {
  logEvent(PARTNERS_APPS_ADD_CLICK, {
    appId: appId,
    appName: appName ?? '',
  });
};
