import { EXCLUDE_NEW_PERMISSIONS_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';

const PRODUCTS_PERMISSIONS = [
  'import_export_products_csv',
  'edit_stock',
  'edit_price',
];

function useHasPermission(permission: string): boolean {
  const { validateRoles } = useRoles();
  const [hasExcludeNewPermissions] = useHasTags(EXCLUDE_NEW_PERMISSIONS_TAG);
  const hasPermission = validateRoles(permission);
  const isProductExcludedPermission = PRODUCTS_PERMISSIONS.includes(permission);

  return (
    (hasExcludeNewPermissions && isProductExcludedPermission) || hasPermission
  );
}

export default useHasPermission;
