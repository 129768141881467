import { Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface LocationsListErrorProps {
  onRetryError: () => void;
}

function LocationsListError({
  onRetryError,
}: LocationsListErrorProps): JSX.Element {
  const t = useTranslationShipping();
  return (
    <EmptyMessage
      title={t('locations.errorResults.title')}
      icon={
        <ExclamationTriangleIcon
          size={32}
          style={{
            marginTop: '3rem',
          }}
        />
      }
      actions={
        <Link appearance="neutral" onClick={onRetryError}>
          {`${t('locations.errorResults.action')}`}
        </Link>
      }
    />
  );
}

export default LocationsListError;
