import VirtualDataList from 'commons/components/VirtualDataList';
import { useFreeShippingMobile } from 'domains/Marketing/FreeShipping/hooks';
import { FreeShippingListRow, FreeShippingMobileSkeleton } from './components';
import { FreeShippingListPage } from '../../components';

export function FreeShippingListMobilePage() {
  const {
    ids,
    data,
    showSkeleton,
    showSpinner,
    isDataEmpty,
    isError,
    refetch,
    handleInfiniteScroll,
    handlePullToRefresh,
  } = useFreeShippingMobile();

  return (
    <FreeShippingListPage
      skeleton={<FreeShippingMobileSkeleton />}
      isLoading={showSkeleton}
      isEmpty={isDataEmpty}
      isError={isError}
      onRetry={refetch}
    >
      <VirtualDataList
        onRefresh={handlePullToRefresh}
        isPullable
        data={ids}
        endReached={handleInfiniteScroll}
        itemContent={(index) => (
          <FreeShippingListRow
            key={index}
            freeShipping={data[index]}
            index={index}
          />
        )}
        showFooter={showSpinner}
      />
    </FreeShippingListPage>
  );
}
