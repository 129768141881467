import { useMemo } from 'react';
import { Country } from '@tiendanube/common/src/enums';

interface UseFormatIdentification {
  identification: string;
  country: string;
}

const applyFormat = (value: string, pattern: string) => {
  let i = 0;
  const formatted = pattern.replace(/#/g, () => value[i++] || '');
  return formatted;
};

const BR = (identification: string) => {
  const format = {
    cpf: '###.###.###-##',
    cnpj: '##.###.###/####-##',
  };

  if (identification.length === 11) {
    return applyFormat(identification, format.cpf);
  } else if (identification.length === 14) {
    return applyFormat(identification, format.cnpj);
  }

  return identification;
};

const AR = (identification: string) => {
  const format = {
    dni: '##.###.###',
    cuit: '##-########-#',
  };

  if (identification.length === 8) {
    return applyFormat(identification, format.dni);
  } else if (identification.length === 11) {
    return applyFormat(identification, format.cuit);
  }

  return identification;
};

export const useFormatIdentification = ({
  identification,
  country,
}: UseFormatIdentification) => {
  const formattedIdentification = useMemo(() => {
    const formats = {
      [Country.BR]: BR,
      [Country.AR]: AR,
    };

    if (!formats[country]) return identification;

    return formats[country](identification);
  }, [identification, country]);

  return formattedIdentification;
};
