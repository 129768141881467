import { useMemo } from 'react';
import { Label, Radio } from '@nimbus-ds/components';
import { AiFeature, AiTone } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';

export interface ToneControlsProps {
  feature: AiFeature;
  tone?: AiTone;
  setTone: (tone: AiTone) => void;
}
export function ToneControls({
  feature,
  tone,
  setTone,
}: Readonly<ToneControlsProps>) {
  const t = useTranslationCommon();

  const handleChangeTone: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setTone(event.target.value as AiTone);
  };

  const tones = useMemo(
    () => [
      {
        label: t(`aiGeneration.tones.values.${AiTone.INFORMATIVE}`),
        value: AiTone.INFORMATIVE,
      },
      {
        label: t(`aiGeneration.tones.values.${AiTone.CAPTIVATING}`),
        value: AiTone.CAPTIVATING,
      },
      {
        label: t(`aiGeneration.tones.values.${AiTone.ELEGANT}`),
        value: AiTone.ELEGANT,
      },
      {
        label: t(`aiGeneration.tones.values.${AiTone.FUNNY}`),
        value: AiTone.FUNNY,
      },
      {
        label: t(`aiGeneration.tones.values.${AiTone.WARM}`),
        value: AiTone.WARM,
      },
    ],
    [t],
  );

  return (
    <Stack column align="stretch" spacing="tight">
      <Label>{t('aiGeneration.tones.label')}</Label>
      <Stack gap="1">
        {tones.map(({ label, value }) => (
          <Stack.Item key={`ai-tone-${feature}-${value}`}>
            <Radio
              as="button"
              name={`ai-tone-${feature}`}
              id={`ai-tone-${feature}-${value}`}
              label={label}
              value={value}
              onChange={handleChangeTone}
              checked={value === tone}
            />
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
}
