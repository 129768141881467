import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getStatisticsShippingSummaryData,
  getStatisticsShippingSummaryStatus,
} from 'domains/Statistics/selectors';
import { getShippingSummary } from 'domains/Statistics/statisticsSlice';

function useGetShippingSummary() {
  const dispatch = useAppDispatch();
  const shippingSummary = useSelector(getStatisticsShippingSummaryData);
  const status = useSelector(getStatisticsShippingSummaryStatus);
  const { hasComparedFilter, openFilter } = useStatisticsFilterContext();

  const fetchShippingSummary = useCallback(() => {
    dispatch(getShippingSummary());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchShippingSummary);

  const isEmpty =
    status.isSuccess &&
    !Object.values(shippingSummary.results).some(
      (item) => item.values.length > 0,
    );

  return {
    fetchShippingSummary,
    shippingSummary,
    hasComparedFilter,
    openFilter,
    isEmpty,
    ...status,
  };
}

export default useGetShippingSummary;
