import { dateFormat } from 'commons/utils/date';
import { StatisticsGranularityType } from 'domains/Statistics/types';
import { FOTMATS_X_AXIS } from 'domains/Statistics/utils';

function xAxisFormatDate(granularity: StatisticsGranularityType) {
  const getFormatedDate = (value: string) => {
    if (!value || value === 'auto') return '';
    return dateFormat(value, FOTMATS_X_AXIS[granularity]);
  };
  return getFormatedDate;
}

export default xAxisFormatDate;
