import { generatePath } from 'react-router';
import { IconButton } from '@tiendanube/components';
import { TrashIcon } from '@tiendanube/icons';
import { ImageItemStratus } from 'commons/components';
import { CATALOG_ROUTES } from 'domains/Catalog/catalogRoutes';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { SectionProductItemInterface } from 'domains/Online';
import ProductHiddenAndWithoutStock from '../../../ProductHiddenAndWithoutStock';

interface ProductItemProps {
  product: SectionProductItemInterface;
  onRemove: (productId: string) => void;
}

function ProductItem({ product, onRemove }: Readonly<ProductItemProps>) {
  const t = useTranslationCatalog();
  const handleOnRemove = () => {
    onRemove(product.productId);
  };

  return (
    <ImageItemStratus
      imageUrl={product.imageUrl}
      id={product.productId}
      title={product.name}
      bold={false}
      to={generatePath(CATALOG_ROUTES.editProduct, {
        id: product.productId,
      })}
      appearance="primary"
      imageWidth="56px"
      trimTitle
      headerRight={
        <IconButton
          key={product.productId}
          icon={TrashIcon}
          onClick={handleOnRemove}
          ariaLabel={t('products.detail.relatedProduct.delete')}
        />
      }
    >
      <ProductHiddenAndWithoutStock
        isVisible={product.visible}
        hasStock
        productId={product.productId}
      />
    </ImageItemStratus>
  );
}

export default ProductItem;
