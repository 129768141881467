import { Text, Box } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { EnabledCountriesResponseDto } from '@tiendanube/common/src/domains/configurations/i18n';
import { Stack } from 'commons/components';
import CompactPaginationStratus from 'commons/components/CompactPaginationStratus';
import { useLocalPagination } from 'commons/hooks';
import Skeleton from './Skeleton';
import { PER_PAGE } from '../../utils/constants';
import { getFlagEmoji } from '../../utils/getFlagEmoji';
import ActionButtons from '../ActionButtons';

interface ListEnabledCountriesProps {
  data: EnabledCountriesResponseDto[];
}
function ListEnabledCountries({ data }: ListEnabledCountriesProps) {
  const { setCurrentPage, currentData, hasPagination, ...pagination } =
    useLocalPagination(data, PER_PAGE);
  return (
    <>
      {currentData.map((enabledCountry) => (
        <DataList.Row key={enabledCountry.id} gap="1">
          <Stack justify="space-between" align="flex-start">
            <Stack column align="flex-start" gap="0-5">
              <Stack gap="1">
                <Text>{getFlagEmoji(enabledCountry.countryCode)}</Text>
                <Text fontWeight="bold">{enabledCountry.countryName}</Text>
              </Stack>
              <Text>{enabledCountry.currencyName}</Text>
              <Text>{enabledCountry.languageName}</Text>
            </Stack>
            <ActionButtons enabledCountry={enabledCountry} />
          </Stack>
        </DataList.Row>
      ))}
      {hasPagination && (
        <Box backgroundColor="neutral-surface" paddingX="4" paddingY="2">
          <CompactPaginationStratus
            pagination={pagination}
            onPageSelect={setCurrentPage}
            itemName=""
          />
        </Box>
      )}
    </>
  );
}

ListEnabledCountries.Skeleton = Skeleton;

export default ListEnabledCountries;
