import { useCallback } from 'react';
import { Browser } from '@capacitor/browser';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { logError } from 'commons/logger';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import isValidCallbackDomain from './utils/isValidCallbackDomain';
import { BILLING_ROUTES } from '../../billingRoutes';
import { TRANSACTION_FEES_ROUTES } from '../../TransactionFees/transactionFeesRoutes';
import { cleanFetchTransactionFeeToPay } from '../../TransactionFees/TransactionFeesSlice';
import {
  AFTER_PAY_ACTION_QUERY,
  AFTER_PAY_CALLBACK_QUERY,
  CHECKOUT_ROUTES,
} from '../checkoutRoutes';

export const GO_TO_PLANS_AFTER_PAYMENT = 'plans';
export const GO_TO_TRANSACTION_FEES_AFTER_PAYMENT = 'transaction-fee';

const AFTER_PAY_ROUTES = {
  [GO_TO_PLANS_AFTER_PAYMENT]: BILLING_ROUTES.plansFromUpgrade,
  [GO_TO_TRANSACTION_FEES_AFTER_PAYMENT]:
    TRANSACTION_FEES_ROUTES.transactionFees,
};

export type CompletedPaymentAction =
  | typeof GO_TO_PLANS_AFTER_PAYMENT
  | typeof GO_TO_TRANSACTION_FEES_AFTER_PAYMENT;

interface UseAfterPaymentRedirection {
  onCompletedPayment: () => void;
  addAfterPayActionToPath: (
    path: string,
    action: CompletedPaymentAction,
  ) => string;
  fullPath: string;
  afterPaySetting: string | null;
  passOnActionToPath: (path: string) => string;
}

function addFromPaymentQuery(route: string) {
  return `${route}${route.includes('?') ? '&' : '?'}fromPayment`;
}

/**
 * Provides necessary functionality for setting and interpreting the "afterPay" search param in the
 * checkout process.
 *
 * This parameter tells us where to redirect the user after a successful payment.
 */
function useAfterPaymentRedirection(): UseAfterPaymentRedirection {
  const dispatch = useAppDispatch();
  const isMobileDevice = useIsMobileDevice();
  const { getQueryParam, goTo } = useNavegate();
  const afterPaySetting = getQueryParam(
    AFTER_PAY_ACTION_QUERY,
  ) as CompletedPaymentAction;
  const callbackUrl = getQueryParam(AFTER_PAY_CALLBACK_QUERY);

  const route = addFromPaymentQuery(
    AFTER_PAY_ROUTES[afterPaySetting ?? ''] ??
      CHECKOUT_ROUTES.plansAndSubscriptions,
  );

  const extraAction = useCallback(() => {
    switch (afterPaySetting) {
      case GO_TO_TRANSACTION_FEES_AFTER_PAYMENT:
        dispatch(cleanFetchTransactionFeeToPay());
        break;
    }
  }, [afterPaySetting, dispatch]);

  const getCallbackUrl = useCallback(() => {
    if (!callbackUrl) {
      return;
    }

    try {
      const url = new URL(decodeURIComponent(callbackUrl));

      if (isValidCallbackDomain(url.hostname)) {
        return addFromPaymentQuery(url.href);
      }
    } catch (err) {
      logError('Failed to parse callbackUrl', err);
    }
  }, [callbackUrl]);

  const onCompletedPayment = useCallback(() => {
    const redirect = async (url: string) => {
      if (isMobileDevice) {
        await Browser.open({ url });
      } else {
        window.location.href = url;
      }
    };

    const callbackUrl = getCallbackUrl();
    if (callbackUrl) {
      redirect(callbackUrl);
    } else {
      goTo(route);
    }

    extraAction();
  }, [extraAction, getCallbackUrl, goTo, isMobileDevice, route]);

  const fullPath =
    getCallbackUrl() ?? new URL(route, window.location.href).href;

  const addAfterPayActionToPath = useCallback(
    (path: string, action: CompletedPaymentAction) => {
      const symbol = path.includes('?') ? '&' : '?';
      return path.concat(`${symbol}${AFTER_PAY_ACTION_QUERY}=${action}`);
    },
    [],
  );

  const passOnActionToPath = (path: string): string => {
    if (callbackUrl) {
      const [pathname, search] = path.split('?');
      const params = new URLSearchParams(search);
      params.set(AFTER_PAY_CALLBACK_QUERY, encodeURIComponent(callbackUrl));

      return `${pathname}?${params.toString()}`;
    }

    return afterPaySetting
      ? addAfterPayActionToPath(path, afterPaySetting)
      : path;
  };

  return {
    onCompletedPayment,
    addAfterPayActionToPath,
    fullPath,
    afterPaySetting,
    passOnActionToPath,
  };
}

export default useAfterPaymentRedirection;
