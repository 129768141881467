import { Card, Input, Text, Title } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { Stack } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  const storeCountry = useGetCountry();
  const title = t('locations.form.address.title');
  return (
    <Card title={title}>
      <Card.Header>
        <Title as="h3">{t('locations.form.address.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Stack column spacing="tight" align="flex-start">
            <Text.Skeleton width="10%" />
            <Input.Skeleton />
          </Stack>

          <Stack column spacing="tight" align="flex-start">
            <Text.Skeleton width="10%" />
            <Input.Skeleton />
          </Stack>

          <Stack column spacing="tight" align="flex-start">
            <Text.Skeleton width="10%" />
            <Input.Skeleton />
          </Stack>

          <Stack column spacing="tight" align="flex-start">
            <Text.Skeleton width="10%" />
            <Input.Skeleton />
          </Stack>

          <Stack column spacing="tight" align="flex-start">
            <Text.Skeleton width="10%" />
            <Input.Skeleton />
          </Stack>

          <Stack.Item>
            <Stack spacing="tight">
              <Stack.Item fill>
                <Stack column spacing="tight" align="flex-start">
                  <Text.Skeleton width="20%" />
                  <Input.Skeleton />
                </Stack>
              </Stack.Item>
              <Stack.Item fill>
                <Stack column spacing="tight" align="flex-start">
                  <Text.Skeleton width="30%" />
                  <Input.Skeleton />
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>

          {storeCountry !== Country.BR && (
            <Stack column spacing="tight" align="flex-start">
              <Text.Skeleton width="10%" />
              <Input.Skeleton />
            </Stack>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default Skeleton;
