import { SelectionModes } from '../components/FreeShippingForm/components';
import { FreeShippingFormState } from '../types';

export const freeShippingDefault: FreeShippingFormState = {
  methods: [],
  priceMinimum: '',
  categoriesType: SelectionModes.ALL,
  categories: [],
  zonesType: SelectionModes.ALL,
  zones: [],
  combinesWithOtherDiscounts: true,
};
