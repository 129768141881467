import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { useNavegate } from 'App/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';

function useGetPaymentProviderSettingsRoute(id: string) {
  const { goTo } = useNavegate();
  const goToSettingsPage = () => {
    switch (id) {
      case PaymentProvidersCode.nuvemPago:
        return goToAdmin('/nuvempago/settings')();
      case PaymentProvidersCode.pagoNube:
        return goTo('/nuvempago/#/config');
      default:
        return goTo(`/settings/payments/${id}`);
    }
  };

  return { goToSettingsPage };
}

export default useGetPaymentProviderSettingsRoute;
