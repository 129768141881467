import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import manifestBase from './manifest.json';

/**
 * This component replaces the static file manifest.json in the public
 * folder and makes it dynamic so that the name can be updated according
 * to the location.
 */
function Manifest() {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  useEffect(() => {
    const $manifest = document.querySelector('link[rel="manifest"]');

    if ($manifest) {
      manifestBase.name = t('manifest.name');
      manifestBase.short_name = t('manifest.short_name');
      manifestBase.description = t('manifest.description');

      $manifest.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(manifestBase)),
      );
    }
  }, [language, t]);

  return null;
}

export default Manifest;
