import { useEffect, useState } from 'react';
import { Icon, Link, Text, Alert } from '@nimbus-ds/components';
import isEqual from 'lodash.isequal';
import { Prompt, Redirect } from 'react-router-dom';
import { Label } from '@tiendanube/components';
import { CheckCircleIcon, TagIcon } from '@tiendanube/icons';
import { useNavegate, useQuery } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import Stack from 'commons/components/Stack';
import { useAsyncFunc, useToast } from 'commons/hooks';
import useDocumentTitle from 'commons/hooks/useDocumentTitle';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { SectionCodeType, useProductSectionDetails } from 'domains/Online';
import { OrganizeSectionError, OrganizeSectionSkeleton } from './components';
import OrganizeSection from './components/OrganizeSection';
import { LIMIT_PRODUCTS_SELECTED } from './constants';
import { ProductItemType } from '../OrganizeProductsPage/types';

function OrganizeFeaturedProductsPage() {
  const t = useTranslationCatalog();
  const section = (useQuery().get('section') || '') as SectionCodeType;
  const { goBack } = useNavegate();
  const { addToast } = useToast();

  const headerNavigation = { onClick: goBack };

  const {
    productsSectionDetails,
    fetchProductSectionsDetails,
    status,
    updateProductsSection,
    updateStatus,
  } = useProductSectionDetails(section);

  const title = productsSectionDetails?.name;
  useDocumentTitle(title);

  const [products, setProducts] = useState<ProductItemType[]>(
    productsSectionDetails?.products || [],
  );

  const [handleOnSave] = useAsyncFunc(
    async () => {
      await updateProductsSection(section, products);
    },
    () => {
      addToast({
        appearance: 'success',
        label: t('products.featuredProductsPage.toast.success'),
      });
    },
    () =>
      addToast({
        appearance: 'danger',
        label: t('products.featuredProductsPage.toast.error'),
      }),
  );

  const isDirty = !isEqual(products, productsSectionDetails?.products);

  useEffect(() => {
    fetchProductSectionsDetails(section);
  }, [fetchProductSectionsDetails, section]);

  useEffect(() => {
    setProducts(productsSectionDetails?.products || []);
  }, [productsSectionDetails, section]);

  const handleOnError = () => {
    fetchProductSectionsDetails(section);
  };

  const handleOnRemove = (productId: string) => {
    setProducts(products.filter((product) => product.productId !== productId));
  };

  const handleOnDrop = (newOrder) => {
    setProducts(newOrder);
  };

  const isUpdating = updateStatus === 'loading';
  const canAddMoreProducts = products.length < LIMIT_PRODUCTS_SELECTED;
  const hasMoreProductsThanTheLimit = products.length > LIMIT_PRODUCTS_SELECTED;
  const labelProdducts = t('products.featuredProductsPage.labelCount', {
    count: products.length,
  });
  const appearanceProducts = canAddMoreProducts ? 'transparent' : 'warning';

  if (!section) return <Redirect to="/products/organize" />;

  const actionsMobile = status === 'success' && isDirty && (
    <Link appearance="primary" as="a" onClick={handleOnSave}>
      <Icon source={<CheckCircleIcon />} />
    </Link>
  );

  const buttonsActions = status === 'success' && isDirty && (
    <CancelAndSaveButtons
      onSave={handleOnSave}
      isDisabled={isUpdating}
      isLoading={isUpdating}
      onCancel={goBack}
    />
  );

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop navigation={headerNavigation} actions={actionsMobile} />
      }
      headerContent={
        <>
          {status === 'loading' && <HeaderContent title="skeleton" />}
          {(status === 'success' || status === 'error') && (
            <HeaderContent title={title} actions={buttonsActions} />
          )}
        </>
      }
    >
      {status === 'success' ? (
        hasMoreProductsThanTheLimit ? (
          <Alert appearance="danger" show>
            <Stack spacing="tight" align="flex-start" column>
              <Text fontWeight="bold" fontSize="base">{`${t(
                'products.featuredProductsPage.hasMoreError.title',
              )}`}</Text>
              <Text fontSize="base">
                {t('products.featuredProductsPage.hasMoreError.subTitle')}
              </Text>
            </Stack>
          </Alert>
        ) : (
          <Text fontSize="base">
            {t('products.featuredProductsPage.subTitle')}
          </Text>
        )
      ) : (
        <Text.Skeleton width="fill" />
      )}
      <Stack column align="stretch">
        {status !== 'loading' && status !== 'error' && (
          <Stack.Item>
            <Label
              id="labelProdducts"
              appearance={appearanceProducts}
              icon={TagIcon}
              label={labelProdducts}
            />
          </Stack.Item>
        )}
        {status === 'loading' && <OrganizeSectionSkeleton />}
        {status === 'error' && <OrganizeSectionError onRetry={handleOnError} />}
        {status === 'success' && (
          <OrganizeSection
            products={products}
            onChange={handleOnDrop}
            onRemove={handleOnRemove}
          />
        )}
        {buttonsActions}
      </Stack>
      <Prompt
        when={status === 'success' && isDirty}
        message={t('common:exitEdit.info')}
      />
    </IonPageStratus>
  );
}

export default OrganizeFeaturedProductsPage;
