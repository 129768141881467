import { logEvent } from 'commons/utils/tracking';
import {
  AUTHENTICATION_FACTOR_LOGIN_WITH_RECOVERY_CODES,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_HELP_LINK_CLICKED,
} from 'config/trackingEvents';

export const trackingLoginWithRecoveryCodes = (): void => {
  logEvent(AUTHENTICATION_FACTOR_LOGIN_WITH_RECOVERY_CODES, {});
};

export const trackingRecoveryCodesHelpLinkClicked = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_HELP_LINK_CLICKED, {});
};
