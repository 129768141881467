import { Accordion } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CNameRecordInfo } from './CNameRecordInfo';

interface CNAMERecordStepProps {
  step: number;
  ourDomain: string;
  isSubdomain: boolean;
}

export function CNAMERecordStep({
  step,
  ourDomain,
  isSubdomain,
}: Readonly<CNAMERecordStepProps>) {
  const t = useTranslationConfigurations('domains.attachModal.step2.cname');
  const index = `${step - 1}`;

  return (
    <Accordion.Item index={index}>
      <Accordion.Header title={`${step}. ${t('title')}`} />
      <Accordion.Body>
        <CNameRecordInfo ourDomain={ourDomain} isSubdomain={isSubdomain} />
      </Accordion.Body>
    </Accordion.Item>
  );
}
