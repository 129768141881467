import { ErrorState } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

interface AbandonedCartDetailsErrorProps {
  onRetryError: () => void;
}

function AbandonedCartDetailsError({
  onRetryError,
}: AbandonedCartDetailsErrorProps) {
  const t = useTranslationOrders();
  return (
    <ErrorState
      title={t('abandonedCarts.detailsError.title')}
      action={{
        children: t('abandonedCarts.detailsError.action'),
        onClick: onRetryError,
      }}
    />
  );
}

export default AbandonedCartDetailsError;
