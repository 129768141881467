import {
  InteractiveList,
  InterfaceInteractiveListOptions,
  Label,
  Stack,
  Text,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { getRegisterDateLabel } from '../../../../../utils';

interface HowToInstallOcaCardProps {
  ocaRegisterDate?: string;
  option: 'signup' | 'config';
  onChangeOption: (option: 'signup' | 'config') => void;
}

function HowToInstallOcaCard({
  ocaRegisterDate,
  option,
  onChangeOption,
}: HowToInstallOcaCardProps) {
  const t = useTranslationShipping();

  const handleOnChange = (value: string[]) => {
    onChangeOption(value[0] as 'signup' | 'config');
  };

  const howToInstallOcaPrefix =
    'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.oca';

  const title = ocaRegisterDate
    ? t(`${howToInstallOcaPrefix}.requestedSignup.title`)
    : t(
        'deliveryMethods.activeShippingMethods.modal.detail.howToInstall.title',
      );

  const options: InterfaceInteractiveListOptions[] = [
    {
      title: t(`${howToInstallOcaPrefix}.pendingSignup.signupTitle`),
      description: t(
        `${howToInstallOcaPrefix}.pendingSignup.signupDescription`,
      ),
      name: 'signup',
      active: option === 'signup',
    },
    {
      title: t(`${howToInstallOcaPrefix}.pendingSignup.configTitle`),
      description: t(
        `${howToInstallOcaPrefix}.pendingSignup.configDescription`,
      ),
      name: 'config',
      active: option === 'config',
    },
  ];

  const ocaRegisterDateLabel = getRegisterDateLabel(ocaRegisterDate, t);

  return (
    <CardWithTitle title={title}>
      {!ocaRegisterDate && (
        <InteractiveList
          onChange={handleOnChange}
          mode="single"
          options={options}
        />
      )}
      {!!ocaRegisterDateLabel && (
        <Stack column align="flex-start">
          <Label
            id="requestedSignup"
            appearance="warning"
            label={ocaRegisterDateLabel}
          />
          <Text>
            {t(`${howToInstallOcaPrefix}.requestedSignup.description`)}
          </Text>
        </Stack>
      )}
    </CardWithTitle>
  );
}

export default HowToInstallOcaCard;
