import CouponsAlertError from './CouponsAlertError';
import CouponsAlertSuccess from './CouponsSuccessAlert';
import useCoupons from '../hooks/useCoupons';

interface CouponsAlertProps {
  show: boolean;
  onDismiss: () => void;
}
function CouponsAlert({ show, onDismiss }: CouponsAlertProps) {
  const {
    data: { errors },
  } = useCoupons();

  return errors?.affiliateDiscount ? (
    <CouponsAlertError show={show} onDismiss={onDismiss} />
  ) : (
    <CouponsAlertSuccess show={show} onDismiss={onDismiss} />
  );
}

export default CouponsAlert;
