import { useCallback, useMemo } from 'react';
import { Text } from '@nimbus-ds/components';
import { GenerateProductAltTextResponseDto } from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import AiGenerationModal from 'commons/components/AiGenerationModal';
import { useGetCategories } from 'domains/Catalog/Categories/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import useGenerateProductAltText from 'domains/Catalog/Products/pages/hooks/useGenerateProductAltText';

function PreviewGeneratedContent({
  image_alt,
}: Readonly<GenerateProductAltTextResponseDto>) {
  return <Text color="neutral-textHigh">{image_alt}</Text>;
}

export interface AltTextGenerationContext {
  productName: LanguageInterface;
  categoryIds: string[];
}

export interface AltTextGenerationModalProps {
  show?: boolean;
  language: LanguagesType;
  imageUrl: string;
  altTextGenerationContext: AltTextGenerationContext;
  onDismiss: () => void;
  onUseContent: (altText: string) => void;
}

function AltTextGenerationModal({
  show = false,
  language,
  imageUrl,
  altTextGenerationContext,
  onDismiss,
  onUseContent,
}: Readonly<AltTextGenerationModalProps>) {
  const t = useTranslationCatalog('products.modal.editPhoto.actions.altText');
  const categories = useGetCategories();

  const categoryNames = useMemo(
    () =>
      altTextGenerationContext.categoryIds.map((categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        if (!category) {
          return '';
        }

        return category.fixed?.prefix
          ? `${category.fixed.prefix}${category.fixed.title}`
          : category.title;
      }),
    [altTextGenerationContext, categories],
  );

  const { generateAltText } = useGenerateProductAltText();

  const handleGenerateContent = useCallback(
    async () =>
      await generateAltText({
        product_image_url: imageUrl,
        product_name: altTextGenerationContext.productName[language],
        product_categories: categoryNames,
        lang: language,
      }),
    [
      generateAltText,
      imageUrl,
      altTextGenerationContext.productName,
      language,
      categoryNames,
    ],
  );

  const handleUseContent = useCallback(
    ({ image_alt }: GenerateProductAltTextResponseDto) => {
      onUseContent(image_alt);
    },
    [onUseContent],
  );

  return (
    <AiGenerationModal
      show={show}
      feature={AiFeature.AI_PRODUCT_IMAGE_ALT_TEXT}
      generateOnOpen
      onDismiss={onDismiss}
      onGenerateContent={handleGenerateContent}
      onUseContent={handleUseContent}
      feedbackTitle={t('aiGeneration.feedbackModal.title')}
      feedbackText={t('aiGeneration.feedbackModal.text')}
      feedbackAsAside
      previewGeneratedContent={PreviewGeneratedContent}
    />
  );
}

export default AltTextGenerationModal;
