import { i18nResponseDto } from '@tiendanube/common';
import { useGetLanguage } from 'domains/Auth/hooks';
import EditImageActions from './EditImageActions';
import { AltTextGenerationContext } from './EditImageActions/components/EditAltTextModal/components/AltTextGenerationModal';
import EditImageBaseModal from './EditImageBaseModal';
import { ImageGalleryState } from '../../ImageGallery';

export interface EditedImage {
  base64: string;
}

interface EditImageModalProps {
  isShow: boolean;
  image: ImageGalleryState;
  altTextGenerationContext: AltTextGenerationContext;
  onClose: () => void;
  onChangeAltText: (value: i18nResponseDto) => void;
  onSaveImage: (editedImage: EditedImage, replacedImageId: string) => void;
}

function EditImageModal({
  isShow,
  onClose,
  onChangeAltText,
  image,
  altTextGenerationContext,
  onSaveImage,
}: Readonly<EditImageModalProps>) {
  const language = useGetLanguage();

  const onSaveImageHandler = (editedImage: EditedImage) => {
    onSaveImage(editedImage, image.id);
    onClose();
  };

  return (
    <EditImageBaseModal
      isShow={isShow}
      displayImageSource={image}
      onClose={onClose}
      translationPrefix="products.modal.editPhoto"
      afterImageChildren={
        <EditImageActions
          onChangeAltText={onChangeAltText}
          altTextGenerationContext={altTextGenerationContext}
          language={language}
          image={image}
          onSaveImage={onSaveImageHandler}
        />
      }
      fullWidthChild
    />
  );
}

export default EditImageModal;
