import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { StatusObjectType, StatusType } from '../../types';

export interface CombinedStatusType extends StatusObjectType {
  status: StatusType;
}

export function combineStatus(
  ...statusList: (StatusType | QueryStatus | undefined)[]
): CombinedStatusType {
  const isLoading = statusList.some(
    (status) => status === 'loading' || status === QueryStatus.pending,
  );
  const isError =
    statusList.some(
      (status) => status === 'error' || status === QueryStatus.rejected,
    ) && !isLoading;
  const isSuccess = statusList.every(
    (status) => status === 'success' || status === QueryStatus.fulfilled,
  );

  let status: StatusType;
  if (isLoading) status = 'loading';
  else if (isError) status = 'error';
  else if (isSuccess) status = 'success';
  else status = 'idle';

  return { isLoading, isError, isSuccess, status, isIdle: status === 'idle' };
}

export const INITIAL_STATUS_OBJECT: CombinedStatusType = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isIdle: true,
  status: 'idle',
};
