import { useEffect, useRef } from 'react';
import { TranslatedStandardProperty } from '@tiendanube/common';
import { InterfaceNameValue, Link } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { DraggableDataList, Stack } from 'commons/components';
import { AttributeOptionInterface } from 'domains/Catalog/Products/pages/components/Variants/types';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { isAttributeValueSelected } from '../../../utils';
import CustomAttributeInput from '../CustomAttributeInput';

interface CustomAttributeValuesProps {
  attributeOptions: AttributeOptionInterface[];
  languageToUse: LanguagesType;
  addOptionLabel?: string;
  emptyValuesError: boolean;
  standardVariants?: TranslatedStandardProperty;
  onChangeOption: (index: number, event: InterfaceNameValue) => void;
  onRemoveOption: (index: number) => void;
  onAddOption: () => void;
  onBlurOption: (index: number, event: InterfaceNameValue) => void;
  onSortOptions: (sorted: LanguageInterface[]) => void;
}

function CustomAttributeValues({
  attributeOptions,
  languageToUse,
  addOptionLabel,
  emptyValuesError,
  onChangeOption,
  onRemoveOption,
  onBlurOption,
  onAddOption,
  onSortOptions,
  standardVariants,
}: CustomAttributeValuesProps) {
  const t = useTranslationCatalog();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dataList = ref.current?.querySelector(
      '.stratus--draggable-data-list__tree-root',
    );

    if (attributeOptions.length > 1 && dataList) {
      const inputElement = dataList.querySelectorAll('input[value=""]')[0] as
        | HTMLInputElement
        | undefined;

      if (inputElement) inputElement.focus();
    }
  }, [attributeOptions.length, languageToUse]);

  const isStandardValue = (option: LanguageInterface) =>
    !!standardVariants &&
    isAttributeValueSelected(
      Object.values(standardVariants),
      languageToUse,
      option,
    );

  const formattedAttributeOptions = attributeOptions.map((option, index) => ({
    option,
    previewName: option[languageToUse],
    index,
    error:
      emptyValuesError &&
      index === 0 &&
      t('products.variants.emptyValuesError'),
    id: option.id,
  }));

  const handleSortOptions = (options) => {
    onSortOptions(options.map(({ option }) => option));
  };

  const showDeleteIcon = attributeOptions.length > 1;

  return (
    <Stack innerRef={ref} column align="stretch" spacing="tight">
      {attributeOptions.length > 0 && (
        <DraggableDataList
          data={formattedAttributeOptions}
          onDrop={handleSortOptions}
          previewDataKey="previewName"
          renderItem={({ index, option, error }) => (
            <CustomAttributeInput
              helpText={error}
              name={`option-${index}-${languageToUse}`}
              lastItem={index === attributeOptions.length - 1}
              value={option[languageToUse] || ''}
              showDeleteIcon={showDeleteIcon}
              isStandardValue={isStandardValue(option)}
              onChange={(e) => onChangeOption(index, e)}
              onRemove={() => onRemoveOption(index)}
              onBlur={(e) => onBlurOption(index, e)}
              onPressEnter={onAddOption}
            />
          )}
        />
      )}
      <Link
        onClick={onAddOption}
        icon={PlusCircleIcon}
        iconPosition="start"
        appearance="primary"
      >
        {addOptionLabel || t('products.variants.addOption')}
      </Link>
    </Stack>
  );
}

export default CustomAttributeValues;
