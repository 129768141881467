import { useEffect } from 'react';
import { Box, Link, Text } from '@nimbus-ds/components';
import { useGetRecurrentPaymentConcepts } from 'domains/Billing/Checkout/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function TaxAlert(): JSX.Element | null {
  const { fetchInvoiceInfo, isConsumidorFinal } = useGetInvoiceInfo();
  const t = useTranslationBilling('checkout.paymentDataContent.taxAlert');
  const { recurrentPaymentForPlanActivated } = useGetRecurrentPaymentConcepts();

  useEffect(fetchInvoiceInfo, [fetchInvoiceInfo]);

  if (!isConsumidorFinal) return null;

  return (
    <Box backgroundColor="neutral-surfaceDisabled" padding="2">
      <Text>
        {recurrentPaymentForPlanActivated
          ? t('message.withRecurringPayment')
          : t('message.withoutRecurringPayment')}
        <Link as="a" href={t('link')} appearance="primary">
          {t('linkMessage')}
        </Link>
      </Text>
    </Box>
  );
}

export default TaxAlert;
