import { logEvent } from 'commons/utils/tracking';
import {
  CUSTOMER_FILTER,
  CUSTOMER_FILTER_MODAL_OPEN,
  CUSTOMER_ALERT_CLOSE,
  CUSTOMER_ALERT_PLANS,
  CUSTOMER_SAVE_METAFIELDS,
  CUSTOMER_TRY_EXPORT,
} from 'config/trackingEvents';

export const trackingCustomerTryExport = (): void => {
  logEvent(CUSTOMER_TRY_EXPORT, {});
};

export const trackingCustomerFilterModalOpen = (): void => {
  logEvent(CUSTOMER_FILTER_MODAL_OPEN, {});
};

export const trackingCustomerFilter = (): void => {
  logEvent(CUSTOMER_FILTER, {});
};

export const trackingCustomerSaveMetafields = (page: string): void => {
  logEvent(CUSTOMER_SAVE_METAFIELDS, { page });
};

export const trackingCustomerAlertPlans = (page: string): void => {
  logEvent(CUSTOMER_ALERT_PLANS, { page });
};

export const trackingCustomerAlertClose = (page: string): void => {
  logEvent(CUSTOMER_ALERT_CLOSE, { page });
};
