import { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { OcaRegisterRequestDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useForm, useToast } from 'commons/hooks';
import { useGetPhone } from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import OcaDocumentationForm from './OcaDocumentationForm';
import { RequestOcaRegistrationValues } from './types';
import validationSchema from './validationShcema';
import useRequestOcaRegistration from '../../hooks/useRequestOcaRegistration/useRequestOcaRegistration';
import { DELIVERY_METHODS_ROUTES } from '../../shippingRoutes';

const INITIAL_VALUES = {
  countryCode: '',
  areaCode: '',
  phoneNumber: '',
  proofOfRegistrationAfip: null,
  taxStatusForm: null,
  proofOfRegistrationIIBB: null,
  ocaRegistrationData: null,
};

function RequestOcaRegistrationPage(): JSX.Element {
  const { goBack, goTo } = useNavegate();
  const { isError, isLoading, isSuccess, requestOcaRegistration } =
    useRequestOcaRegistration();
  const t = useTranslationShipping();
  const { addToast } = useToast();
  const currentPhone = useGetPhone();
  const [countryCode, areaCode, phoneNumber] = currentPhone
    .replace(/\+/g, '')
    .split(' ');

  const initialValues = useMemo(
    () => ({
      ...INITIAL_VALUES,
      countryCode,
      areaCode,
      phoneNumber,
    }),
    [countryCode, areaCode, phoneNumber],
  );

  useEffect(() => {
    if (isError) {
      addToast({
        label: t('oca.config.errorSave.create'),
        appearance: 'danger',
      });
    }
  }, [isError, addToast, t]);

  useEffect(() => {
    if (isSuccess) {
      goTo(DELIVERY_METHODS_ROUTES.requestOcaRegistrationSuccess);
    }
  }, [isSuccess, goTo]);

  const backNavigation = {
    children: t('oca.requestRegistration.headerBack'),
    onClick: goBack,
  };

  const {
    values,
    handleOnChange,
    setFieldValue,
    isDirty,
    errors,
    handleOnSubmit,
  } = useForm<RequestOcaRegistrationValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (
        !values.proofOfRegistrationAfip ||
        !values.taxStatusForm ||
        !values.proofOfRegistrationIIBB ||
        !values.ocaRegistrationData
      )
        return;

      const body: OcaRegisterRequestDto = {
        ...values,
        afipDoc: values.proofOfRegistrationAfip,
        fiscalDoc: values.taxStatusForm,
        iibbDoc: values.proofOfRegistrationIIBB,
        abmDoc: values.ocaRegistrationData,
      };
      requestOcaRegistration(body);
    },
  });

  const isButtonDisabled =
    Object.values(values).some((value) => !value) ||
    Object.keys(errors).length > 0;

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent title={t('oca.requestRegistration.headerTitle')} />
      }
    >
      <Prompt
        message={t('common:exitEdit.info')}
        when={isDirty && !isSuccess}
      />
      <OcaDocumentationForm
        values={values}
        errors={errors}
        onChange={handleOnChange}
        setFieldValue={setFieldValue}
      />
      <CancelAndSaveButtons
        onCancel={goBack}
        onSave={handleOnSubmit}
        isDisabled={isLoading || isButtonDisabled}
        isLoading={isLoading}
        saveText={t('oca.requestRegistration.saveText')}
      />
    </IonPageStratus>
  );
}

export default RequestOcaRegistrationPage;
