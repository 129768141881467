import * as Yup from 'yup';
import { Country } from '@tiendanube/common';
import {
  validateIdentification,
  validateZipcode,
  email,
  notAllowedEmoji,
} from './utils';

export const validationSchema = (country: Country) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      'draftOrders.newDraftOrder.clientData.errors.name',
    ),
    lastName: Yup.string().required(
      'draftOrders.newDraftOrder.clientData.errors.lastName',
    ),
    email: Yup.string()
      .matches(
        email,
        'draftOrders.newDraftOrder.clientData.errors.email.invalid',
      )
      .required('draftOrders.newDraftOrder.clientData.errors.email.required'),
    identification: Yup.string().test(
      'valid-identification',
      'draftOrders.newDraftOrder.clientData.errors.identification',
      (value) => (value ? validateIdentification(value, country) : true),
    ),
    phone: Yup.string(),
    paymentStatus: Yup.string(),
    salesCheck: Yup.string(),
    street: Yup.string(),
    number: Yup.string(),
    floor: Yup.string(),
    locality: Yup.string(),
    city: Yup.string(),
    zipcode: Yup.string().test(
      'zipcode-validation',
      'address.errors.zipcodeInvalid',
      (value) => validateZipcode(value, country),
    ),
    province: Yup.string(),
    salesChannel: Yup.string().test(
      'salesChannel',
      'draftOrders.newDraftOrder.salesChannel.errors.notAllowedEmojis',
      (value) => !notAllowedEmoji.test(value || ''),
    ),
    products: Yup.array()
      .of(
        Yup.object().shape({
          variantId: Yup.string().required('Select a variant'),
          quantity: Yup.string().test('quantity-is-valid', function (value) {
            if (!value || Number(value) <= 0) {
              return this.createError({
                message: ' ',
                path: `products[${this.parent.variantId}].quantity`,
              });
            }
            if (
              this.parent.stock !== null &&
              this.parent.stock < Number(value)
            ) {
              return this.createError({
                message: `draftOrders.newDraftOrder.orderSummary.errors.out_of_stock`,
                path: `products[${this.parent.variantId}].quantity`,
              });
            }

            return true;
          }),
        }),
      )
      .test('variants-not-duplicated', function (array) {
        const products = array || [];
        const variantIds: string[] = [];

        for (let i = 0; i < products.length; i++) {
          const product = products[i];
          if (variantIds.includes(product.variantId || ''))
            return this.createError({
              path: `products[${product.variantId}].duplicated`,
              message:
                'draftOrders.newDraftOrder.orderSummary.errors.duplicated',
            });
          else variantIds.push(product.variantId || '');
        }

        return true;
      })
      .min(1, 'draftOrders.newDraftOrder.NewDraftOrderProduct.error'),
  });
