import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import { InformationCard } from '../../../components';
import AddressCard from '../../../components/AddressCard';
import SalesChannelCard from '../../../components/SalesChannelCard';

function LocationDetailsSkeleton(): JSX.Element {
  const hasShippingLocationTag = useHasShippingLocationTag();
  return (
    <>
      <InformationCard.Skeleton />
      <AddressCard.Skeleton />
      {hasShippingLocationTag && <SalesChannelCard.Skeleton />}
    </>
  );
}

export default LocationDetailsSkeleton;
