import { useState } from 'react';
import { Alert, Text, Link } from '@nimbus-ds/components';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { trackingShippingUpgradeCardSeePlansClick } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface LocationsLimitAlertProps {
  limitOfLocations: number;
}

function LocationsLimitAlert({
  limitOfLocations,
}: LocationsLimitAlertProps): JSX.Element {
  const t = useTranslationShipping();
  const title = t(
    limitOfLocations > 1
      ? 'locations.limit.alert.title'
      : 'locations.limit.alert.titleSingular',
    { limitOfLocations },
  );

  const body =
    limitOfLocations === 3
      ? t('locations.limit.alert.bodyToUnlimited', { limitOfLocations })
      : t('locations.limit.alert.body', { limitOfLocations });

  const [show, setShow] = useState(true);
  const onRemove = () => setShow(false);
  const { handleGoToPlans: goToPlans } = useGoToPlans();
  const handleGoToPlans = () => {
    trackingShippingUpgradeCardSeePlansClick('alert');
    goToPlans();
  };

  return (
    <Alert appearance="primary" title={title} show={show} onRemove={onRemove}>
      <Text>{body}</Text>
      <Link as="button" appearance="neutral" onClick={handleGoToPlans}>
        {t('locations.limit.alert.action')}
      </Link>
    </Alert>
  );
}

export default LocationsLimitAlert;
