import { Box, Spinner, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export default function LoadingTimelineItem() {
  const t = useTranslationMarketing('coupons.history');

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="1"
      paddingTop="4"
    >
      <Spinner color="currentColor" size="small" />
      <Text>{t('loading')}</Text>
    </Box>
  );
}
