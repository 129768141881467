import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList } from '@tiendanube/components';
import { MessagesListItemSkeleton } from 'domains/Customers/components/MessagesListItem';

function CustomerMessagesSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerDetails.customerActivity.messagesTitle')}>
      <DataList>
        <MessagesListItemSkeleton />
      </DataList>
    </Card>
  );
}

export default CustomerMessagesSkeleton;
