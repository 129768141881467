import { Text } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeliveryCartValueInfoProps {
  cartValueMin?: string;
  cartValueMax?: string;
}

function DeliveryCartValueInfo({
  cartValueMin,
  cartValueMax,
}: Readonly<DeliveryCartValueInfoProps>) {
  const t = useTranslationShipping();

  return (
    <Text>
      {!!cartValueMax &&
        !!cartValueMin &&
        t('deliveryMethods.newInformationShipping.valueRange', {
          cartValueMin,
          cartValueMax,
        })}
      {!cartValueMax &&
        !!cartValueMin &&
        t('deliveryMethods.newInformationShipping.valueWithoutMax', {
          cartValueMin,
        })}
      {!!cartValueMax &&
        !cartValueMin &&
        t('deliveryMethods.newInformationShipping.valueWithoutMin', {
          cartValueMax,
        })}
    </Text>
  );
}

export default DeliveryCartValueInfo;
