import { DataList } from '@tiendanube/components';
import MonthRow from '../../MonthRow';

function MonthListSkeleton(): JSX.Element {
  return (
    <DataList>
      <MonthRow.Skeleton />
      <MonthRow.Skeleton />
      <MonthRow.Skeleton />
      <MonthRow.Skeleton />
      <MonthRow.Skeleton />
      <MonthRow.Skeleton />
    </DataList>
  );
}

export default MonthListSkeleton;
