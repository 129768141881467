import { Checkbox } from '@nimbus-ds/components';
import { Input, InterfaceNameValue, Stack } from '@tiendanube/components';
import { BaseCardCollapsible, InputNumber } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { trackingPaymentMethodToggle } from 'domains/Payments/CustomPayment/tracking';
import { CustomPaymentMethod } from 'domains/Payments/CustomPayment/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

export interface TypePaymentValuesInterface {
  active: boolean;
  name: string;
  instructions: string;
  percentage: number;
  allowDiscountCombination: boolean;
}

interface TypePaymentProps {
  title: string;
  group: CustomPaymentMethod;
  disabled: boolean;
  values: TypePaymentValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function TypePayment({
  title,
  group,
  disabled,
  values,
  errors,
  onChange,
}: TypePaymentProps): JSX.Element {
  const t = useTranslationPayments();

  const rows = values?.instructions.split('\n').length + 1;

  const isActive = values.active || false;

  const error = {
    name: t(errors[`${group}.name`] || ''),
    instructions: t(errors[`${group}.instructions`] || ''),
    percentage: t(errors[`${group}.percentage`] || ''),
  };

  const handleChange = (event: InterfaceNameValue) => {
    onChange(group, event);
  };

  const handleChangeActive = () => {
    trackingPaymentMethodToggle(group);
    onChange(group, { name: 'active', value: !isActive });
  };

  const handleChangeAllowsDiscountCombination = () => {
    onChange(group, {
      name: 'allowDiscountCombination',
      value: !values.allowDiscountCombination,
    });
  };

  return (
    <BaseCardCollapsible
      active={isActive}
      disabled={disabled}
      onChange={handleChangeActive}
      title={title}
      appearance="toggle"
      paddingBody="none"
    >
      <Stack column align="stretch">
        <Input
          name="name"
          label={t('customPayment.name')}
          value={values.name}
          appearance={error.name ? 'validation_error' : 'default'}
          helpText={error.name}
          maxLength={5000}
          onChange={handleChange}
        />
        <Input
          name="instructions"
          label={t('customPayment.instructions')}
          rows={rows}
          multiRows
          value={values.instructions}
          appearance={error.instructions ? 'validation_error' : 'default'}
          helpText={error.instructions}
          maxLength={5000}
          onChange={handleChange}
        />
        <InputNumber
          name="percentage"
          type="float"
          label={t('customPayment.percentage')}
          appendLabel="%"
          value={`${values.percentage}`}
          appearance={error.percentage ? 'validation_error' : 'default'}
          helpText={error.percentage}
          onChange={handleChange}
        />
        <Checkbox
          name={`allowDiscountCombination-${group}`}
          checked={values.allowDiscountCombination}
          label={t('customPayment.allowDiscountCombination')}
          onChange={handleChangeAllowsDiscountCombination}
          disabled={!parseInt(String(values.percentage))}
        />
      </Stack>
    </BaseCardCollapsible>
  );
}

export default TypePayment;
