import { CardWithTitle, Spinner } from 'commons/components';
import { STATS_SHIPPING_PAGE } from 'config/upsellFlowSources';
import {
  useStatisticsFilterContext,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { STATS_PLAN_3 } from 'domains/Statistics/utils';
import useGetRankProvincesByShippingCost from './useGetRankProvincesByShippingCost';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';
import RankByProvinceChart from '../RankByProvinceChart';

function RankProvincesByShippingCostCard() {
  const t = useTranslationStatistics();
  const {
    rankProvincesByShippingCost,
    isLoading,
    isError,
    isSuccess,
    fetchRankProvincesByShippingCost,
  } = useGetRankProvincesByShippingCost();
  const { openFilter } = useStatisticsFilterContext();
  return (
    <CardWithTitle
      title={t('statistics.rankProvincesByShippingCostCard.title')}
    >
      <ChartOrPlaceholder
        type="bars-horizontal"
        statsPlanRequired={STATS_PLAN_3}
        upsellSource={STATS_SHIPPING_PAGE}
      >
        {isLoading && <Spinner size="medium" />}
        {isError && <GenericError onRetry={fetchRankProvincesByShippingCost} />}
        {isSuccess && rankProvincesByShippingCost?.length === 0 && (
          <GenericEmptySearch onRetry={openFilter} />
        )}
        {isSuccess &&
          rankProvincesByShippingCost &&
          rankProvincesByShippingCost?.length > 0 && (
            <RankByProvinceChart
              provincesRanking={rankProvincesByShippingCost}
            />
          )}
      </ChartOrPlaceholder>
    </CardWithTitle>
  );
}

export default RankProvincesByShippingCostCard;
