import {
  ProductSectionResponseDto,
  ProductSectionsCodeType,
} from '@tiendanube/common';
import { Card, InterfaceNameChecked } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  EmptySelectedSections,
  ErrorState,
  SelectedSections,
  Skeleton,
} from './components';

interface HighlightProductCardProps {
  onOpen: () => void;
  onChange: (event: InterfaceNameChecked) => void;
  sectionsSelected: ProductSectionsCodeType[];
  sections: ProductSectionResponseDto[];
}

function HighlightProductCard({
  onOpen,
  sectionsSelected,
  sections,
  onChange,
}: HighlightProductCardProps): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <Card title={t('products.detail.highlight.title')}>
      {sectionsSelected.length > 0 ? (
        <SelectedSections
          onChange={onChange}
          onOpen={onOpen}
          sectionsSelected={sectionsSelected}
          sections={sections}
        />
      ) : (
        <EmptySelectedSections onOpen={onOpen} />
      )}
    </Card>
  );
}

HighlightProductCard.Skeleton = Skeleton;
HighlightProductCard.ErrorState = ErrorState;

export default HighlightProductCard;
