import React from 'react';
import { Alert } from '@nimbus-ds/components';
import { notificationStyle } from '../../constants';
import { NotificationComponentProps } from '../../types';

// eslint-disable-next-line react/function-component-definition
const AlertNotification: React.FC<NotificationComponentProps> = ({
  id,
  title,
  message,
  style,
  show,
  onClose,
  action,
  'data-testid': dataTestId,
}) => (
  <Alert
    title={title}
    key={id}
    show={show}
    appearance={notificationStyle[style]}
    onRemove={onClose}
    data-testid={dataTestId}
  >
    {message}
    {action}
  </Alert>
);

export default AlertNotification;
