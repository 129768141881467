import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { InterfaceIdLabel } from '@tiendanube/components';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import useFilterLabels from './useFilterLabels';
import { CouponValuesInterface } from '../components/CouponForm';
import { CouponFiltersType, defaultFilters } from '../couponsService';
import {
  getCouponsList,
  getMoreCouponsList,
  removeCoupon as removeCouponAction,
  activateCoupon as activateCouponAction,
  deactivateCoupon as deactivateCouponAction,
  createCoupon as createCouponAction,
  editCoupon as editCouponAction,
  cleanList as cleanListAction,
  startSearch,
} from '../couponsSlice';
import {
  getCouponsIds,
  getCouponsStatus,
  getCouponsRefreshStatus,
  getCouponPagination,
  getTotalCoupons,
} from '../couponsSlice/couponsSelectors';

interface UseCouponsResult {
  couponsIds: string[];
  pagination: PaginationResponseDto;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  isRefreshing: boolean;
  fetchCoupons: (filters?: CouponFiltersType) => void;
  fetchMoreCoupons: () => void;
  removeCoupon: (id: string) => Promise<void>;
  activateCoupon: (id: string) => Promise<void>;
  deactivateCoupon: (id: string) => Promise<void>;
  createCoupon: (coupon: CouponValuesInterface) => Promise<void>;
  editCoupon: (
    id: string,
    coupon: Partial<CouponValuesInterface>,
  ) => Promise<void>;
  total: number | null;
  filters: CouponFiltersType;
  changeFilters: (filters: CouponFiltersType) => void;
  updateFilters: (filters: Partial<CouponFiltersType>) => void;
  searchWithDebounce: (filters: CouponFiltersType) => () => void;
  optionsLabels: InterfaceIdLabel[];
}

function useCoupons(): UseCouponsResult {
  const dispatch = useAppDispatch();
  const { filters, changeFilters, updateFilters } = useListFilters(
    'coupons',
    defaultFilters,
  );
  const { isError, isLoading, isSuccess } = useSelector(getCouponsStatus);
  const { isRefreshing } = useSelector(getCouponsRefreshStatus);

  const couponsIds = useSelector(getCouponsIds);
  const pagination = useSelector(getCouponPagination);
  const total = useSelector(getTotalCoupons);

  const optionsLabels = useFilterLabels(filters);

  const fetchCoupons = useCallback(
    async (customFilters?: CouponFiltersType) => {
      const fetchFilters = customFilters ?? filters;
      dispatch(getCouponsList(fetchFilters));
    },
    [dispatch, filters],
  );

  const fetchMoreCoupons = useCallback(
    () => dispatch(getMoreCouponsList()),
    [dispatch],
  );

  const removeCoupon = useCallback(
    async (id) => {
      unwrapResult(await dispatch(removeCouponAction(id)));
    },
    [dispatch],
  );

  const activateCoupon = useCallback(
    async (id) => {
      unwrapResult(await dispatch(activateCouponAction(id)));
    },
    [dispatch],
  );

  const deactivateCoupon = useCallback(
    async (id) => {
      unwrapResult(await dispatch(deactivateCouponAction(id)));
    },
    [dispatch],
  );

  const createCoupon = useCallback(
    async (coupon) => {
      unwrapResult(await dispatch(createCouponAction({ coupon })));
      dispatch(cleanListAction());
    },
    [dispatch],
  );

  const editCoupon = useCallback(
    async (id: string, coupon: Partial<CouponValuesInterface>) => {
      unwrapResult(await dispatch(editCouponAction({ id, coupon })));
      dispatch(cleanListAction());
    },
    [dispatch],
  );

  const isEmpty = total === 0 && !isLoading && !isError;

  const searchWithDebounce = useCallback(
    (filters: CouponFiltersType) => {
      const timeToDebounce = 300;
      dispatch(startSearch());

      const debouncedSearch = setTimeout(() => {
        fetchCoupons(filters);
      }, timeToDebounce);

      return () => clearTimeout(debouncedSearch);
    },
    [dispatch, fetchCoupons],
  );

  return {
    changeFilters,
    updateFilters,
    couponsIds,
    pagination,
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    isRefreshing,
    fetchCoupons,
    fetchMoreCoupons,
    removeCoupon,
    activateCoupon,
    deactivateCoupon,
    createCoupon,
    editCoupon,
    total,
    filters,
    searchWithDebounce,
    optionsLabels: isLoading ? [] : optionsLabels,
  };
}

export default useCoupons;
