import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResultPaginationResponseDto,
  CouponsResponseDto,
  PaginationResponseDto,
  CouponsFilterRequestDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getCouponsFilters, getCouponsPagination } from './couponsSelectors';
import { CouponValuesInterface } from '../components/CouponForm';
import {
  getCouponsList as getCouponsListService,
  removeCoupon as removeCouponService,
  activateCoupon as activateCouponService,
  deactivateCoupon as deactivateCouponService,
  createCoupon as createCouponService,
  editCoupon as editCouponService,
  getCoupon as getCouponService,
  CouponFiltersType,
  defaultFilters,
} from '../couponsService';

type CouponsEntitiesType = Record<string, CouponsResponseDto>;

type ThunkStateType = { state: RootStateType };

export interface InterfaceCouponsSchema {
  ids: string[];
  refreshStatus: string;
  entities: CouponsEntitiesType;
  status: string;
  currentRequestID: string;
  pagination: PaginationResponseDto;
  total: number | null;
  filters: CouponFiltersType;
}

const initialState: InterfaceCouponsSchema = {
  ids: [] as string[],
  status: 'idle',
  refreshStatus: 'idle',
  currentRequestID: '',
  entities: {},
  pagination: {
    currentPage: 1,
    totalPages: 1,
    totalResults: 0,
    perPage: 20,
    nextPage: null,
  },
  total: null,
  filters: defaultFilters,
};

export const getCouponsList = createAsyncThunk<
  {
    response: ResultPaginationResponseDto<CouponsResponseDto[]>;
    filters: CouponFiltersType;
  },
  CouponFiltersType
>('coupons/getCouponsList', async (filters) => {
  const response = await getCouponsListService({
    ...filters,
    page: filters.page.toString(),
  });
  return { response, filters };
});

export const getMoreCouponsList = createAsyncThunk<
  { response: ResultPaginationResponseDto<CouponsResponseDto[]>; filters: any },
  undefined,
  ThunkStateType
>('coupons/getMoreCouponsList', async (_, thunkApi): Promise<any> => {
  const state = thunkApi.getState();
  const pagination = getCouponsPagination(state);
  const filters = getCouponsFilters(state);

  if (!pagination.nextPage) {
    throw new Error('no valid fetch');
  }
  const newFilters: CouponsFilterRequestDto = {
    ...filters,
    page: pagination.nextPage.toString(),
  };
  const response = await getCouponsListService(newFilters);
  return { response, filters: newFilters };
});

export const fetchCoupon = createAsyncThunk<CouponsResponseDto, string>(
  'coupons/getCouponById',
  async (id) => await getCouponService(id),
);

export const removeCoupon = createAsyncThunk<void, string>(
  'coupons/removeCoupon',
  async (id) => {
    await removeCouponService(id);
  },
);

export const activateCoupon = createAsyncThunk<void, string>(
  'coupons/activateCoupon',
  async (id) => {
    await activateCouponService(id);
  },
);

export const deactivateCoupon = createAsyncThunk<void, string>(
  'coupons/deactivateCoupon',
  async (id) => {
    await deactivateCouponService(id);
  },
);

export const createCoupon = createAsyncThunk<
  void,
  { coupon: CouponValuesInterface }
>('coupons/createCoupon', async ({ coupon }) => {
  await createCouponService(coupon);
});

export const editCoupon = createAsyncThunk<
  void,
  { id: string; coupon: Partial<CouponValuesInterface> }
>('coupons/editCoupon', async ({ id, coupon }) => {
  await editCouponService(id, coupon);
});

const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    startSearch: (state) => {
      state.status = 'loading';
    },
    cleanList(state) {
      state.status = 'idle';
      state.ids = [];
    },
  },
  extraReducers: (builder) => {
    // getCouponsList

    builder.addCase(getCouponsList.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.response.results.reduce((acc, coupon) => {
        acc[coupon.id] = coupon;
        ids.push(coupon.id);
        return acc;
      }, {} as CouponsEntitiesType);
      state.ids = ids;
      state.status = 'success';
      state.pagination = action.payload.response.pagination;
      state.total = action.payload.response.pagination.totalResults;
      state.filters = action.payload.filters;
      return state;
    });

    builder.addCase(getCouponsList.pending, (state, action) => {
      state.status = 'loading';
      state.total = null;
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getCouponsList.rejected, (state, action) => {
      state.status = 'error';
      state.total = null;
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    // getMoreCouponsList

    builder.addCase(getMoreCouponsList.fulfilled, (state, action) => {
      const ids: string[] = [];
      state.entities = action.payload.response.results.reduce(
        (acc, cuopons) => {
          acc[cuopons.id] = cuopons;
          ids.push(cuopons.id);
          return acc;
        },
        state.entities,
      );
      state.ids = [...state.ids, ...ids];
      state.refreshStatus = 'success';
      state.status = 'success';
      state.pagination = action.payload.response.pagination;
      return state;
    });

    builder.addCase(getMoreCouponsList.pending, (state, action) => {
      state.refreshStatus = 'refreshing';
      state.currentRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getMoreCouponsList.rejected, (state) => {
      state.refreshStatus = 'error';
      return state;
    });

    builder.addCase(fetchCoupon.fulfilled, (state, action) => {
      state.entities[action.meta.arg] = action.payload;
      return state;
    });

    // removeCouponAction

    builder.addCase(removeCoupon.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg];
      state.ids = state.ids.filter((id) => id !== action.meta.arg);
      return state;
    });

    builder.addCase(activateCoupon.fulfilled, (state, action) => {
      state.entities[action.meta.arg].status = 'enabled';
      return state;
    });

    builder.addCase(deactivateCoupon.fulfilled, (state, action) => {
      state.entities[action.meta.arg].status = 'disabled';
      return state;
    });
  },
});

export const { reducer } = couponSlice;

export const { cleanList, startSearch } = couponSlice.actions;
