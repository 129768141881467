import { useCallback } from 'react';
import { AiFeature } from '@tiendanube/common/src/enums';
import useModal, { UseModalResult } from '../useModal';
import useStorage from '../useStorage';

const THREE_MONTHS_IN_MS = 3600 * 24 * 91 * 1000;
function useAiFeedbackFlow(
  feature: AiFeature,
  onSkip?: () => void,
): UseModalResult {
  const [showModal, openModal, closeModal] = useModal();

  const [lastFeedbackDate, setlastFeedbackDate] = useStorage<Date | null>(
    `aiGenerationFeedbackFlow_lastFeedbackDate_${feature}`,
    null,
  );

  const openModalCheckingDates = useCallback(() => {
    const now = new Date();
    if (lastFeedbackDate === null) {
      setlastFeedbackDate(now);
      openModal();
      return;
    }

    const msSinceFeedbackWasAsked =
      now.getTime() - new Date(lastFeedbackDate).getTime();

    if (msSinceFeedbackWasAsked > THREE_MONTHS_IN_MS) {
      setlastFeedbackDate(now);
      openModal();
    }
    if (onSkip) {
      onSkip();
    }
  }, [lastFeedbackDate, setlastFeedbackDate, openModal, onSkip]);

  return [showModal, openModalCheckingDates, closeModal];
}

export default useAiFeedbackFlow;
