import { Accordion, Text } from '@nimbus-ds/components';
import { ExternalLink, Stack, SubmitButton } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetDomainsErrorsQuery } from 'domains/Configurations/Domains/domainsApi';
import {
  useCustomDomainDisabled,
  useHasDomainsErrorAlertTag,
} from 'domains/Configurations/Domains/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { DNSVerificationModal } from './DNSVerificationModal/DNSVerificationModal';

interface DNSVerificationStepProps {
  step: number;
  isSubdomain: boolean;
  domainId: string;
  domainName: string;
  ourDomain: string;
}

export function DNSVerificationStep({
  step,
  isSubdomain,
  domainId,
  domainName,
  ourDomain,
}: Readonly<DNSVerificationStepProps>) {
  const t = useTranslationConfigurations('domains.attachModal.verification');
  const [showErrorModal, openErrorModal, closeErrorModal] = useModal();
  const hasDomainsErrorAlertTag = useHasDomainsErrorAlertTag();

  const { data: domainsWithErrors } = useGetDomainsErrorsQuery(undefined, {
    skip: !hasDomainsErrorAlertTag,
  });
  const isFreePlan = useCustomDomainDisabled();

  const showVerifyBtn =
    hasDomainsErrorAlertTag &&
    !isFreePlan &&
    domainsWithErrors?.ids.includes(domainId);

  const index = `${step - 1}`;

  return (
    <>
      <Accordion.Item index={index}>
        <Accordion.Header
          title={`${step}. ${t('title')}`}
          borderBottom="base"
        />
        <Accordion.Body>
          <Stack column align="stretch">
            <Text>{t('text')}</Text>
            {showVerifyBtn && (
              <SubmitButton onClick={openErrorModal}>
                {t('verifyBtn')}
              </SubmitButton>
            )}
            <ExternalLink
              appearance="primary"
              textDecoration="none"
              href={t('helpLink.href')}
            >
              {t('helpLink.title')}
            </ExternalLink>
          </Stack>
        </Accordion.Body>
      </Accordion.Item>
      <DNSVerificationModal
        isOpen={showErrorModal}
        onClose={closeErrorModal}
        isSubdomain={isSubdomain}
        domainId={domainId}
        domainName={domainName}
        ourDomain={ourDomain}
      />
    </>
  );
}
