import { Button, Icon } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useResponsive } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import emptyFreeShippingBr from './empty-free-shipping-br.png';
import emptyFreeShippingEs from './empty-free-shipping-es.png';

interface FreeShippingListEmptyProps {
  onCreate: () => void;
}

export function FreeShippingListEmpty({
  onCreate,
}: Readonly<FreeShippingListEmptyProps>) {
  const t = useTranslationMarketing('freeShipping.empty');
  const { isMobile } = useResponsive();
  const { country } = useStoreInfo();
  const imgWidth = isMobile ? 313 : 492;

  return (
    <EmptyMessage
      title={t('title')}
      text={t('text')}
      actions={
        <Button appearance="primary" onClick={onCreate}>
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          {t('action')}
        </Button>
      }
      illustration={
        <img
          width={imgWidth}
          alt=""
          src={country === 'BR' ? emptyFreeShippingBr : emptyFreeShippingEs}
        />
      }
    />
  );
}
