import { useSelector } from 'react-redux';
import {
  getGlobalInfoData,
  getGlobalInfoDataLastUpdate,
  getGlobalInfoGranularity,
  getGlobalInfoStatus,
  getGlobalInfoTotals,
} from 'domains/Statistics/selectors';

function useGetStatisticsGlobalInfoData() {
  const globalInfo = useSelector(getGlobalInfoData);
  const globalInfoStatus = useSelector(getGlobalInfoStatus);
  const totalsGlobalInfo = useSelector(getGlobalInfoTotals);
  const granularity = useSelector(getGlobalInfoGranularity);
  const lastUpdate = useSelector(getGlobalInfoDataLastUpdate);

  const globalInfoIsEmpty =
    globalInfoStatus === 'success' &&
    !Object.values(globalInfo).some((value) => value.length > 0);

  return {
    globalInfo,
    globalInfoStatus,
    totalsGlobalInfo,
    granularity,
    lastUpdate,
    globalInfoIsEmpty,
  };
}

export default useGetStatisticsGlobalInfoData;
