import { EditStockModal } from 'domains/Catalog/Products/pages/components/Stock';
import { VariantListItemInterface } from 'domains/Catalog/Products/pages/components/Variants/components/CardVariants';
import useVariantStock from 'domains/Catalog/Products/pages/hooks/useVariantStock';

function EditVariantStockModal({
  variant,
  closeEditStockModal,
}: {
  variant: VariantListItemInterface;
  closeEditStockModal: () => void;
}) {
  const variantStock = useVariantStock(variant.id);

  return (
    <EditStockModal
      isShow
      productId={variant.productId || ''}
      variantId={variant.id || ''}
      currentStock={variantStock === null ? null : String(variantStock)}
      isVariantStock
      variantName={variant.name}
      onClose={closeEditStockModal}
      showHeader={false}
      locationId={variant?.locationId}
    />
  );
}

export default EditVariantStockModal;
