import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useForm } from 'commons/hooks';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { EmailDataInterface } from 'domains/Customers/Customers/customersServices';
import {
  getSendEmailStatus,
  sendEmailAction,
  clearSendEmailActionStatus,
} from 'domains/Customers/Customers/customersSlice';
import { validationSchema } from './validationSchema';

const initialValues = {
  forEmail: '',
  fromEmail: '',
  subject: '',
  content: '',
  sendCopy: false,
  source: 'customer_detail',
};

function useSendEmailModal(
  closeSendEmailModal: () => void,
  forEmail: string,
  customerId: string,
  source?: string,
) {
  const dispatch = useAppDispatch();
  const { email: fromEmail } = useGetUserInfo();
  const status = useSelector(getSendEmailStatus);

  const sendEmail = useCallback(
    async (customerId: string, emailData: EmailDataInterface) => {
      await dispatch(sendEmailAction({ customerId, emailData }));
      dispatch(clearSendEmailActionStatus());
    },
    [dispatch],
  );

  const sendEmailData = async () => {
    await sendEmail(customerId, {
      subject: values.subject,
      content: values.content,
      sendCopy: values.sendCopy,
      source: values.source,
    });
    closeSendEmailModal();
  };

  const {
    values,
    isDirty,
    handleOnSubmit,
    setFieldValue,
    errors,
    setMultipleFieldValues,
  } = useForm({
    initialValues,
    onSubmit: sendEmailData,
    validationSchema,
  });

  useEffect(() => {
    setMultipleFieldValues({
      ...initialValues,
      fromEmail,
      forEmail,
      ...(source && { source }),
    });
  }, [forEmail, fromEmail, source, setMultipleFieldValues]);

  return {
    values,
    isDirty,
    handleOnSubmit,
    setFieldValue,
    errors,
    status,
  };
}

export default useSendEmailModal;
