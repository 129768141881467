import { CostAndDaysCard, FreeShippingCard } from './components';

function Skeleton(): JSX.Element {
  return (
    <>
      <CostAndDaysCard.Skeleton />
      <FreeShippingCard.Skeleton />
    </>
  );
}

export default Skeleton;
