import {
  Box,
  Card,
  Checkbox,
  Icon,
  Link,
  Tag,
  Title,
} from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import NubeLogo from './logo-nube-grey.svg';
import NuvemLogo from './logo-nuvem-grey.svg';
import { SettingsCheckoutFormProps } from '../../types';

export function AcceleratedCheckoutCard({
  values: { installWallet },
  onChange,
}: SettingsCheckoutFormProps) {
  const country = useGetCountry();
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.acceleratedCheckoutCard',
  );

  const handleChangeTogle = ({ target: { name, checked } }) => {
    onChange({ name, value: checked });
  };

  const logo = country === Country.BR ? NuvemLogo : NubeLogo;

  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Title fontSize="h3">{t('title')}</Title>
          </Box>
          <Tag appearance="primary">{t('tagRecommended')}</Tag>
        </Box>
      </Card.Header>

      <Card.Body>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="4"
        >
          <Box as="img" alt="nuvempay" src={logo} height="20px" />
          <Checkbox
            name="installWallet"
            label={t('installWalletLabel')}
            checked={!!installWallet}
            onChange={handleChangeTogle}
          />
          <Link
            appearance="primary"
            as="a"
            href={t('linkHref')}
            target="_blank"
          >
            {t('linkText')}
            <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
          </Link>
        </Box>
      </Card.Body>
    </Card>
  );
}
