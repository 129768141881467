export const LABEL_ERRORS = [
  'default',
  'internal_error',
  'invalid_operation_number',
  'invalid_account_number',
  'invalid_zipcode',
  'invalid_oca_floor',
  'invalid_char',
  'invalid_oca_info',
  'invalid_oca_sending_method',
  'invalid_oca_sending_method_door',
  'invalid_xml',
  'oca_backend_error',
  'origin_branch_disabled',
  'destination_branch_disabled',
  'no_error_description',
  'null_response_from_server',
  'wrong_user_pwd',
  'unknown',
];

export const DEFAULT_ERROR = 'default';

export const LABEL_ERRORS_TRANSLATES = {
  internal_error: 'internalError',
  invalid_operation_number: 'invalidOperationNumber',
  invalid_account_number: 'invalidAccountNumber',
  invalid_zipcode: 'invalidZipcode',
  invalid_oca_floor: 'invalidOcaFloor',
  invalid_char: 'invalidChar',
  invalid_oca_info: 'invalidOcaInfo',
  invalid_oca_sending_method: 'invalidOcaSendingMethod',
  invalid_oca_sending_method_door: 'invalidOcaSendingMethodDoor',
  invalid_xml: 'invalidXml',
  oca_backend_error: 'ocaBackendError',
  origin_branch_disabled: 'originBranchDisabled',
  destination_branch_disabled: 'destinationBranchDisabled',
  no_error_description: 'noErrorDescription',
  null_response_from_server: 'nullResponseFromServer',
  wrong_user_pwd: 'wrongUserPwd',
  unknown: 'unknown',
};
