import { Box, Text } from '@nimbus-ds/components';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling/useTranslationBilling';

function AccountDataContent(): JSX.Element {
  const t = useTranslationBilling('checkout.plansAndSubscriptions.accountData');
  const { name, url } = useHeaderMenuData();

  const urlShortened = url.replace('https://', '');

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Box display="flex" flexDirection="row" gap="1-5">
        <Text>{`${t('name')}: `}</Text>
        <Text fontWeight="medium">{name}</Text>
      </Box>
      <div className="stratus--checkout-text_ellipsis">
        <Box display="flex" flexDirection="row" gap="1-5">
          <Text>{`${t('url')}: `}</Text>
          <Text fontWeight="medium">{urlShortened}</Text>
        </Box>
      </div>
    </Box>
  );
}

export default AccountDataContent;
