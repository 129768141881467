import { Box } from '@nimbus-ds/components';

import './Carousel.scss';

type CarouselProps = {
  children: JSX.Element[];
};

function Carousel({ children }: CarouselProps) {
  return (
    <Box display="grid" overflow="hidden" className="stratus--carousel">
      <Box display="flex" flexWrap="nowrap" gap="3" overflow="auto">
        {children.map((item, i) => (
          <Box
            display="flex"
            padding="0-5"
            key={`carousel-item${i}`}
            overflowX="hidden"
            flexGrow="1"
            flexShrink="0"
            width={{
              lg: 'calc(80% / 3)',
              md: 'calc(80% / 2)',
              xs: '80%',
            }}
          >
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Carousel;
