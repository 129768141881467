import { combineReducers } from 'redux';
import { reducer as addressesReducers } from './Addresses/addressesSlice';
import { reducer as deliveryMethodsReducer } from './DeliveryMethods/deliveryMethodsSlice';
import { reducer as locationsReducer } from './Locations/locationSlice';
import { reducer as shippingMethodsReducer } from './ShippingMethods/shippingSlice';

const shippingReducers = combineReducers({
  locations: locationsReducer,
  shippingMethods: shippingMethodsReducer,
  adresses: addressesReducers,
  deliveryMethods: deliveryMethodsReducer,
});

export default shippingReducers;
