import { Status } from '@tiendanube/common/src/enums';

export default function getShippingOrderStatus(
  status: string,
  isShippablePickup: boolean,
): string {
  return status === Status.PICKUPED && isShippablePickup
    ? Status.SHIPPED
    : status;
}
