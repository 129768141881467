import { Card, Text, Link, Box } from '@nimbus-ds/components';
import {
  openModal as SmoochOpenModal,
  statusIsOk as SmoochStatusIsOk,
} from 'App/components/ExternalServices/SunshineConversations/startSmooch';
import {
  openWidget as WebWidgetOpen,
  verifyStatus as WebWidgetStatusIsOk,
} from 'App/components/ExternalServices/WebWidgetLiveChat';
import { ZENDESK_LIVE_CHAT_WIDGET_TAG } from 'App/featuresFlags';
import { trackingNewAdminCanLinkClick } from 'App/tracking';
import { HELP_LINKS_ADD_CURRENCIES_LANGUAGES } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetTags, useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function AddMoreCurrenciesCard() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const language = useTranslationLanguage();
  const isMobileDevice = useIsMobileDevice();
  const tags = useGetTags();

  const getChatStatus = () => {
    if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) {
      return WebWidgetStatusIsOk();
    }
    return SmoochStatusIsOk();
  };

  const handleClick = () => {
    if (isMobileDevice) {
      trackingNewAdminCanLinkClick('', t('helpLinks.confAddCurrencies'));
      openWindow(HELP_LINKS_ADD_CURRENCIES_LANGUAGES[language], true);
    } else if (getChatStatus()) {
      if (tags.includes(ZENDESK_LIVE_CHAT_WIDGET_TAG)) WebWidgetOpen();
      else SmoochOpenModal();
    }
  };

  return (
    <Card padding="none">
      <Box display="inline-flex" gap="1" padding="2-5">
        <Text>{t('addMoreCurrencies.content')}</Text>
        <Link
          as="a"
          appearance="primary"
          textDecoration="none"
          onClick={handleClick}
        >
          {t('addMoreCurrencies.link')}
        </Link>
      </Box>
    </Card>
  );
}

export default AddMoreCurrenciesCard;
