import { useCallback } from 'react';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsLogged } from 'domains/Auth/hooks';
import { useGetUnreadMessagesCount } from 'domains/Customers/Messages/hooks';
import { useGetNewOrdersCount } from 'domains/Orders/Orders/hooks';
import { useGetTotalOpenedOrders } from 'domains/Orders/Orders/hooks/useGetTotalOpenedOrders';

function useRefreshOrdersInAppActive() {
  const newOrdersCount = useGetNewOrdersCount();
  const { validateRoles } = useRoles();
  const hasRoleOrders = validateRoles('orders');
  const hasRoleCustomers = validateRoles('customers');
  const { isLogin } = useIsLogged();
  const { getTotalOpenedOrders } = useGetTotalOpenedOrders();
  const { fetchUnreadMessagesCount } = useGetUnreadMessagesCount();

  const refreshOrders = useCallback(() => {
    if (isLogin && hasRoleOrders && newOrdersCount === 0) {
      getTotalOpenedOrders();
    }
  }, [isLogin, hasRoleOrders, newOrdersCount, getTotalOpenedOrders]);

  const refreshUnreadMessages = useCallback(() => {
    if (isLogin && hasRoleCustomers) {
      fetchUnreadMessagesCount();
    }
  }, [fetchUnreadMessagesCount, hasRoleCustomers, isLogin]);

  return { refreshOrders, refreshUnreadMessages };
}

export default useRefreshOrdersInAppActive;
