import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';
import {
  StoreIcon,
  Icon,
  CashIcon,
  DesktopIcon,
  UploadIcon,
  AppsIcon,
} from '@tiendanube/icons';

export type FromType =
  | 'store'
  | 'draft_order'
  | 'web_admin'
  | 'mobile_app'
  | 'product_import'
  | 'api'
  | 'pos';

interface LabelReasonProps {
  from: FromType;
}

const labels: Record<FromType, string> = {
  store: 'products.stockHistoryModal.from.store',
  draft_order: 'products.stockHistoryModal.from.draftOrder',
  web_admin: 'products.stockHistoryModal.from.webAdmin',
  mobile_app: 'products.stockHistoryModal.from.mobileApp',
  product_import: 'products.stockHistoryModal.from.productImport',
  api: 'products.stockHistoryModal.from.api',
  pos: 'products.stockHistoryModal.from.pos',
};

const icons: Record<FromType, Icon> = {
  store: StoreIcon,
  draft_order: CashIcon,
  web_admin: DesktopIcon,
  mobile_app: DesktopIcon,
  product_import: UploadIcon,
  api: AppsIcon,
  pos: DesktopIcon,
};

function LabelFrom({ from }: LabelReasonProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  return <Label icon={icons[from]} id={from} label={`${t(labels[from])}`} />;
}

export default LabelFrom;
