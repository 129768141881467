import HelpLink from 'App/HelpLink';
import {
  ErrorScreen,
  HeaderContent,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { HELP_LINKS_NOTIFICATIONS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  EmailOriginInfo,
  EmailsSection,
  NotificationsListPageSkeleton,
} from './components';
import { useGetEmailsListQuery } from '../../notificationsApi';
import './NotificationsListPage.scss';

function NotificationsListPage() {
  const t = useTranslationConfigurations('notifications.notificationsListPage');
  const language = useTranslationLanguage();

  const { data: emails, isLoading, isError, refetch } = useGetEmailsListQuery();

  const showSkeleton = isLoading;
  const showError = isError;
  const showList = !showSkeleton && !showError && !!emails;

  return (
    <IonPageStratus
      width="large"
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      <Stack column align="flex-start" spacing="loose">
        <EmailOriginInfo />
        {showSkeleton && <NotificationsListPageSkeleton />}
        {showError && <ErrorScreen onRetry={refetch} />}
        {showList && (
          <Stack column align="stretch" spacing="loose">
            <EmailsSection
              title={t('emailTypes.accounts')}
              emails={emails.accounts}
            />
            <EmailsSection
              title={t('emailTypes.orders')}
              emails={emails.orders}
            />
            <EmailsSection
              title={t('emailTypes.promotions')}
              emails={emails.promotions}
            />
          </Stack>
        )}
      </Stack>
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_NOTIFICATIONS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default NotificationsListPage;
