import { InterfaceNameValue, Select } from '@tiendanube/components';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

export type OptionPeriodCompareType =
  | 'samePeriod'
  | 'samePeriodLastYear'
  | 'custom';

interface SelectMainPeriodProps {
  value: OptionPeriodCompareType;
  onChange: (e: InterfaceNameValue) => void;
}

function SelectComparePeriod({ value, onChange }: SelectMainPeriodProps) {
  const t = useTranslationStatistics();

  const OPTIONS = [
    { label: t('statistics.filters.samePeriod'), value: 'samePeriod' },
    {
      label: t('statistics.filters.samePeriodLastYear'),
      value: 'samePeriodLastYear',
    },
    { label: t('statistics.filters.custom'), value: 'custom' },
  ];
  return (
    <Select
      label={t('statistics.filters.modal.comparePeriodLabel')}
      options={OPTIONS}
      name="comparePeriod"
      value={value}
      onChange={onChange}
    />
  );
}

export default SelectComparePeriod;
