import { LoginResponseDto } from '@tiendanube/common';
import { getStorage } from 'App/storage';

/**
 * We discovered that Android is attempting to register
 * the device token at app launch and before login,
 * resulting in the token not being registered to a store.
 * We apply this solution to check if the authentication
 * exists stored in the storage
 */
async function preventRegistrationWithoutPreviousAuth() {
  const auth = await getStorage<LoginResponseDto>('auth');
  return !!auth;
}

export default preventRegistrationWithoutPreviousAuth;
