const THEME_PROMOTION_CONDITIONS: Record<
  string,
  { freePlan: boolean; tags?: string[] }
> = {
  patagonia: {
    freePlan: false,
    tags: ['patagonia-theme', 'lightspeed_promote-patagonia-theme'],
  },
};
export const shouldPromoteTheme = (
  themeCode: string,
  isFreePlan: boolean,
  storeTags: string[],
  currentThemeCode: string,
  draftThemeCode?: string,
): boolean => {
  const conditions = THEME_PROMOTION_CONDITIONS[themeCode];
  return (
    themeCode !== currentThemeCode &&
    themeCode !== draftThemeCode &&
    (!conditions ||
      (conditions.freePlan === isFreePlan &&
        (!conditions.tags ||
          conditions.tags.every((tag) => storeTags.includes(tag)))))
  );
};
