import { Dispatch, SetStateAction, useState } from 'react';
import WebWidget, { ZendeskAPI } from 'react-zendesk';
import { useGetUserInfo } from 'domains/Auth/hooks';
import { config } from './config';

declare global {
  interface Window {
    zE: () => void;
    zESettings: {
      webWidget: {
        color: {
          theme: string;
        };
      };
    };
  }
}

export function verifyStatus() {
  if (window.zE === undefined) {
    console.log('Web Widget Live Chat is not available');
    return false;
  }
  return true;
}

function closeWidget() {
  ZendeskAPI('webWidget', 'hide');
  ZendeskAPI('webWidget', 'close');
}

export function openWidget() {
  ZendeskAPI('webWidget', 'show');
  ZendeskAPI('webWidget', 'open');
}

function handleWidgetLoaded(setIsLoaded: Dispatch<SetStateAction<boolean>>) {
  setIsLoaded(true);
  closeWidget();
}

function WebWidgetLiveChat() {
  const { email, firstName, lastName } = useGetUserInfo();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const setting = {
    color: {
      theme: '#2c3357',
    },
  };

  if (isLoaded) {
    ZendeskAPI('webWidget:on', 'close', function () {
      closeWidget();
    });

    ZendeskAPI('webWidget', 'identify', {
      name: `${firstName} ${lastName}`,
      email: email,
    });
  }

  return (
    <WebWidget
      defer
      zendeskKey={config.key}
      {...setting}
      onLoaded={() => handleWidgetLoaded(setIsLoaded)}
    />
  );
}

export default WebWidgetLiveChat;
