import { Link, Text } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { useIsMobileDevice } from 'domains/Auth/hooks';

interface NavigationLinkProps {
  name: string;
  url: string;
  goToNavegate: string;
  fontSize?: 'base' | 'caption' | 'highlight';
  fontWeight?: 'bold' | 'regular' | 'medium';
}

function NavigationLink({
  name,
  url,
  goToNavegate,
  fontSize,
  fontWeight,
}: NavigationLinkProps) {
  const isMobile = useIsMobileDevice();

  return isMobile ? (
    <Link
      as={LinkRoute}
      appearance="primary"
      to={goToNavegate}
      textDecoration="none"
    >
      <Text
        fontSize={fontSize}
        color="primary-interactive"
        fontWeight={fontWeight}
      >
        {name}
      </Text>
    </Link>
  ) : (
    <Link
      appearance="primary"
      as="a"
      href={url}
      target="_blank"
      textDecoration="none"
    >
      <Text
        fontSize={fontSize}
        color="primary-interactive"
        fontWeight={fontWeight}
      >
        {name}
      </Text>
    </Link>
  );
}

export default NavigationLink;
