import { useEffect, useState } from 'react';
import { InteractiveList, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  InputNumber,
} from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { Variant } from '../../Variants/types';

interface EditNewVariantStockModalProps {
  isShow: boolean;
  variantIndex: number;
  value: string | null;
  variantName?: string;
  onChange: (i: number, v: Partial<Variant>) => void;
  onClose: () => void;
}

function EditNewVariantStockModal({
  isShow,
  variantIndex,
  value,
  variantName,
  onChange,
  onClose,
}: EditNewVariantStockModalProps): JSX.Element {
  const t = useTranslationCatalog();
  const [isInfinite, setIsInfinite] = useState(true);
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setIsInfinite(value === null);
  }, [value]);

  useEffect(() => {
    if (isInfinite) setNewValue(null);
  }, [isInfinite]);

  const handleConfirm = () => {
    onChange(variantIndex, { stock: newValue });
    handleClose();
  };
  const handleClose = () => {
    setIsInfinite(true);
    setNewValue(null);
    onClose();
  };

  const isInfiniteOptions = [
    {
      title: t('products.detail.infinite'),
      name: 'infinite',
      active: isInfinite,
    },
    {
      title: t('products.detail.limited'),
      name: 'limited',
      active: !isInfinite,
    },
  ];

  const variation = variantName
    ? t('products.stockHistoryModal.variant', {
        variation: variantName,
      })
    : undefined;

  const isModified = newValue !== value;

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: handleClose }} />}
      headerContent={
        <HeaderContent
          title={t('products.detail.editStock.title')}
          subtitle={variation}
        />
      }
    >
      <Stack column align="stretch" spacing="base">
        <InteractiveList
          onChange={() => setIsInfinite(!isInfinite)}
          mode="single"
          options={isInfiniteOptions}
        />
        {!isInfinite && (
          <>
            <Stack.Item />
            <InputNumber
              type="integer"
              label={t('products.detail.quantity')}
              name="stockVariation"
              value={newValue || ''}
              onChange={(e) => setNewValue(e.value)}
            />
          </>
        )}
      </Stack>

      <CancelAndSaveButtons
        saveText={t('products.detail.save')}
        cancelText={t('products.detail.cancel')}
        onCancel={handleClose}
        onSave={handleConfirm}
        isDisabled={!isModified}
      />
    </ModalAside>
  );
}

export default EditNewVariantStockModal;
