import { Redirect, Route, Switch } from 'react-router-dom';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import AppPage from './pages/AppPage';
import AppScopesAuthorizationPage from './pages/AppScopesAuthorizationPage';
import AppsListPage from './pages/AppsListPage';
import NuvemAppPage from './pages/NuvemAppPage';
import { PARTNER_APPS_ROUTES } from './partnersAppsRoutes';

export const PATH_WIDGETS = '/apps/widgets';

function PartnersApps(): JSX.Element {
  const { validateRoles } = useRoles();
  const isMobileDevice = useIsMobileDevice();

  return (
    <Switch>
      <Route path={PARTNER_APPS_ROUTES.widgets}>
        <NuvemAppPage code="widgets" />
      </Route>
      {validateRoles('nuvem_pago') && (
        <Route
          exact
          path={[
            PARTNER_APPS_ROUTES.nuvempago,
            PARTNER_APPS_ROUTES.nuvempagoBr,
          ]}
        >
          <Redirect to="/nuvempago" />
        </Route>
      )}
      {validateRoles('nuvem_envio') && (
        <Route path={PARTNER_APPS_ROUTES.nuvemenvio}>
          <NuvemAppPage code="nuvemenvio" />
        </Route>
      )}
      {validateRoles('nuvem_envio') && (
        <Route path={PARTNER_APPS_ROUTES.envionubemx}>
          <NuvemAppPage code="envionubemx" />
        </Route>
      )}
      {validateRoles('nuvem_envio') && (
        <Route path={PARTNER_APPS_ROUTES.envionubear}>
          <NuvemAppPage code="envionubear" />
        </Route>
      )}
      <Route path={PARTNER_APPS_ROUTES.testApplication}>
        <NuvemAppPage code="test-application" />
      </Route>

      <Route path={PARTNER_APPS_ROUTES.authorization}>
        <AppScopesAuthorizationPage />
      </Route>

      <Route path={PARTNER_APPS_ROUTES.appsDetail}>
        <AppPage />
      </Route>
      {validateRoles(['full', 'apps']) && (
        <Route path={PARTNER_APPS_ROUTES.apps}>
          {isMobileDevice ? (
            <AppsListPage />
          ) : (
            <ToggleMigratedOldDomain domain="apps-list">
              <AppsListPage />
            </ToggleMigratedOldDomain>
          )}
        </Route>
      )}
    </Switch>
  );
}

export default PartnersApps;
