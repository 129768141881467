import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getCheckoutState } from './transactionFeeToPaySelectors';

export const getTransactionFeePaidStatus = createSimpleSelector(
  [getCheckoutState],
  ({ transactionFeePaid }) => transactionFeePaid.status,
);

export const fetchTransactionFeePaidSelector = createSimpleSelector(
  [getCheckoutState],
  ({ transactionFeePaid }) => transactionFeePaid.data,
);
