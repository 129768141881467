import {
  StandardColorResponseDtoV2,
  CustomPropertiesResponseDto,
  StandardSizeResponseDtoV2,
  TranslatedStandardProperty,
  CustomPropertyResponseDtoV2,
} from '@tiendanube/common';
import { LanguageInterface, LanguagesType } from 'App/i18n';
import { getLocaleStandardName } from './constants';
import {
  Attribute,
  AttributeOptionInterface,
  AttributeType,
} from '../../types';

export const formatAttributeOption = (
  attributeOption: AttributeOptionInterface,
): LanguageInterface => {
  const attributeOptionFormatted: AttributeOptionInterface = JSON.parse(
    JSON.stringify(attributeOption),
  );

  delete attributeOptionFormatted.id;

  return attributeOptionFormatted;
};

export const formatAttributeOptions = (
  attributeOptions: AttributeOptionInterface[],
) => {
  const attributeOptionFormatted = attributeOptions.map((attributeOption) =>
    formatAttributeOption(attributeOption),
  );

  return attributeOptionFormatted.filter(
    (option) =>
      Object.keys(option).filter(
        (key) => key !== 'id' && option[key].trim() !== '',
      ).length > 0,
  );
};

export function fillDefaultLanguageAttribute(
  attribute: Attribute,
  language: LanguagesType,
): Attribute {
  const attributeName = {
    ...attribute.name,
    [language]:
      attribute.name[language] ||
      Object.values(attribute.name).find((v) => v !== ''),
  };

  const attributeOptions = formatAttributeOptions(attribute.values).map(
    (option) => ({
      ...option,
      [language]:
        option[language] || Object.values(option).find((v) => v !== ''),
    }),
  );

  return {
    name: attributeName,
    values: attributeOptions,
  };
}

export const isStandardColor = (language: string, name?: string) =>
  !!name && name === getLocaleStandardName().color[language];

export const isStandardSize = (
  language: string,
  locale: string,
  name?: string,
) => !!name && name === getLocaleStandardName(locale).size[language];

export const isStandardVariant = (
  language: string,
  locale: string,
  name?: string,
) =>
  !!name &&
  (isStandardColor(language, name) || isStandardSize(language, locale, name));

export const getInitialSelectedVariantType = (
  name: string,
  language: string,
  locale: string,
  standardVariants: CustomPropertiesResponseDto | null,
): string => {
  if (isStandardColor(language, name)) return 'color';
  if (isStandardSize(language, locale, name)) return 'size';

  if (standardVariants) {
    const allPropertiesNames = Object.keys(standardVariants).reduce(
      (acc, current) => ({
        ...acc,
        [current]: standardVariants[current].name,
      }),
      {},
    );

    const currentKey = Object.keys(allPropertiesNames).find(
      (key) => allPropertiesNames[key]?.[language] === name,
    );

    return currentKey ?? 'new_attribute';
  }

  return 'new_attribute';
};

export const hasSomeAttributeOption = (attributeOptions: LanguageInterface[]) =>
  attributeOptions.length > 0 &&
  attributeOptions.every((option) =>
    Object.entries(option)
      .filter(([key]) => key !== 'id')
      .some(([_, value]) => value.trim() !== ''),
  );

export const getStandardSizesFlatten = (
  standardSizes?: StandardSizeResponseDtoV2,
): TranslatedStandardProperty => {
  if (!standardSizes) return {};

  const standardSizesFlatten = standardSizes
    ? Object.keys(standardSizes.standard).reduce(
        (acc, value) => ({ ...acc, ...standardSizes.standard[value] }),
        {},
      )
    : {};

  return { ...standardSizesFlatten, ...standardSizes.suggested };
};

export const getStandardColorsFlatten = (
  standardColors?: StandardColorResponseDtoV2,
): Record<string, LanguageInterface> | null => {
  if (!standardColors) {
    return null;
  }

  const standardColorsFormatted: StandardColorResponseDtoV2 = JSON.parse(
    JSON.stringify(standardColors),
  );

  Object.values(standardColorsFormatted.standard).forEach(
    (color) => delete color.visualHelper,
  );

  return {
    ...standardColorsFormatted.standard,
    ...standardColorsFormatted.suggested,
  };
};

const getSuggestedValuesFlatten = (
  standardVariants: CustomPropertyResponseDtoV2 | null,
) => {
  if (!standardVariants) return {};

  return standardVariants.suggested ?? {};
};

export const getStandardVariantsFlatten = (
  standardVariants: CustomPropertiesResponseDto | null,
  selectedVariantType: AttributeType | null,
): Record<string, LanguageInterface> => {
  if (selectedVariantType === 'color') {
    return getStandardColorsFlatten(standardVariants?.color) ?? {};
  }

  if (selectedVariantType === 'size') {
    return getStandardSizesFlatten(standardVariants?.size);
  }

  return selectedVariantType && standardVariants?.[selectedVariantType]
    ? getSuggestedValuesFlatten(standardVariants[selectedVariantType])
    : {};
};

export const areTranslatedStringsEqual = (
  language: LanguagesType,
  a?: LanguageInterface,
  b?: LanguageInterface,
) =>
  !!a?.[language] &&
  !!b?.[language] &&
  a[language]?.toLowerCase() === b[language]?.toLowerCase();

export const isAttributeValueSelected = (
  selectedValues: LanguageInterface[],
  languageToUse: LanguagesType,
  value: LanguageInterface,
) =>
  selectedValues.some((selectedValue) =>
    areTranslatedStringsEqual(languageToUse, selectedValue, value),
  );
