import { Input } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import {
  DEFAULT_LANGUAGE,
  LanguageInterface,
  LanguageNameValueInterface,
  LanguagesType,
} from 'App/i18n';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';

interface FormNameAndDescriptionProps {
  name: LanguageInterface;
  description: LanguageInterface;
  onChange: (data: LanguageNameValueInterface) => void;
  language?: LanguagesType;
  errors: Partial<Record<string, string>>;
}

function FormNameAndDescription({
  name,
  description,
  language = DEFAULT_LANGUAGE,
  onChange,
  errors,
}: Readonly<FormNameAndDescriptionProps>) {
  const t = useTranslationAccount();
  const { ref } = useAutoFocus();
  const isMobile = useIsMobileDevice();
  const errorMessageKey = errors[`name.${language}`] ?? null;

  const handleChangeName = ({ name: nameInput, value }) => {
    onChange({
      name: nameInput,
      value: { ...name, [language]: value },
    });
  };

  const handleChangeDescription = ({ name, value }) => {
    onChange({
      name,
      value: { ...description, [language]: value },
    });
  };

  useScrollToError(errors);

  return (
    <Stack column align="stretch" innerRef={isMobile ? undefined : ref}>
      <Input
        type="text"
        label={t('businessDataPage.nameAndDescriptionCard.name.label')}
        onChange={handleChangeName}
        name="name"
        value={name[language]}
        appearance={errorMessageKey ? 'validation_error' : undefined}
        helpText={
          errorMessageKey
            ? t(errorMessageKey)
            : t('businessDataPage.nameAndDescriptionCard.name.helpText')
        }
      />
      <Input
        type="text"
        label={t('businessDataPage.nameAndDescriptionCard.description.label')}
        onChange={handleChangeDescription}
        name="description"
        rows={3}
        multiRows
        maxLength={160}
        helpText={t(
          'businessDataPage.nameAndDescriptionCard.description.helpText',
          {
            count: description[language]?.length || 0,
          },
        )}
        value={description[language]}
      />
    </Stack>
  );
}

export default FormNameAndDescription;
