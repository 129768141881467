import { BaseCard, Input, Text } from '@tiendanube/components';
import CancelAndSaveButtons from 'commons/components/CancelAndSaveButtons';

function PaymentProviderSettingsSkeleton(): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Header>
          <Text.Skeleton size="featured" width="large" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Input.Skeleton />
        </BaseCard.Body>
      </BaseCard>
      <BaseCard>
        <BaseCard.Header>
          <Text.Skeleton size="featured" width="large" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Input.Skeleton />
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons.Skeleton />
    </>
  );
}

export default PaymentProviderSettingsSkeleton;
