import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const updateToFreePlanAction = createAsyncThunk(
  'dashboard/updateToFreePlanAction',
  dashboardService.updateToFreePlan,
);

const billingPlanSlice = createSlice({
  name: 'billingPlanSlice',
  initialState: initialState.billingPlan,
  reducers: {
    cleanUpdateToFreePlanStatus(state) {
      state.updateToFreePlan.status = 'idle';
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.billingPlan;
      return state;
    });

    builder
      .addCase(updateToFreePlanAction.pending, (state) => {
        state.updateToFreePlan.status = 'loading';
      })
      .addCase(updateToFreePlanAction.rejected, (state) => {
        state.updateToFreePlan.status = 'error';
      })
      .addCase(updateToFreePlanAction.fulfilled, (state) => {
        state.updateToFreePlan.status = 'success';
      });
  },
});

export const { reducer } = billingPlanSlice;
export const { cleanUpdateToFreePlanStatus } = billingPlanSlice.actions;
