import { combineReducers } from 'redux';
import { reducer as checkoutReducer } from './Checkout/CheckoutSlice';
import { reducer as couponsReducer } from './Coupons/couponsSlice';
import { reducer as invoicesReducer } from './Invoices/invoicesSlice';
import { reducer as transactionFeesReducer } from './TransactionFees/TransactionFeesSlice';

const billingReducers = combineReducers({
  invoices: invoicesReducer,
  transactionFees: transactionFeesReducer,
  coupons: couponsReducer,
  checkout: checkoutReducer,
});

export default billingReducers;
