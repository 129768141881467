import { Switch, Route, Redirect } from 'react-router-dom';
import { dashboardRoutes } from 'domains/Dashboard';
import { ExternalCodesPage } from './pages/ExternalCodesPage';
import { CONFIGURATIONS_ROUTES } from '../configurationsRoutes';

export function ExternalCodes() {
  return (
    <Switch>
      <Route path={CONFIGURATIONS_ROUTES.externalCodes} exact>
        <ExternalCodesPage />
      </Route>
      <Redirect from="*" to={dashboardRoutes.dashboard} exact />
    </Switch>
  );
}
