import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export const getDashboard = (state: RootStateType) => state.dashboard;

const getUsersEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.users,
);

const getForwardConfirmationEmailEntity = createSimpleSelector(
  [getUsersEntity],
  (users) => users.forwardConfirmationEmail,
);

export const getforwardConfirmationEmailStatus = createSimpleSelector(
  [getForwardConfirmationEmailEntity],
  (forwardConfirmationEmail) => forwardConfirmationEmail.status,
);
