import { Box, Tag, Text } from '@nimbus-ds/components';
import { LocationResponseDto } from '@tiendanube/common';
import { LocationIcon } from '@tiendanube/icons';
import { createTextSingleVariantPlace } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { VariantListItemInterface } from '../CardVariants/CardVariants';

interface VariantLocationTagProps {
  variant: VariantListItemInterface;
  locations: LocationResponseDto[];
}

function VariantLocationTag({ variant, locations }: VariantLocationTagProps) {
  const t = useTranslationCatalog();
  const levels = {
    inventory_levels: variant.inventoryLevels ?? [
      {
        location_id: variant.locationId || '',
      },
    ],
  };
  const location = createTextSingleVariantPlace(levels, locations);
  const labelText = t(`${location?.i18key}`, { value: location?.value });

  return (
    <Tag appearance="neutral">
      <Box display="inline-flex" alignItems="center">
        <LocationIcon size={12} />
      </Box>
      <Text fontSize="caption" lineHeight="caption" lineClamp={1}>
        {labelText}
      </Text>
    </Tag>
  );
}

export default VariantLocationTag;
