import { ChangeEvent, useState } from 'react';
import { LocationResponseDto } from '@tiendanube/common';
import { DataTable, InterfaceNameValue, Stack } from '@tiendanube/components';
import { EditIcon, CopyIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { ActionIconButton, InputNumber } from 'commons/components';
import { useClipboard, useModal } from 'commons/hooks';
import {
  useGetCurrency,
  useHasPermission,
  useHasShowVariantId,
} from 'domains/Auth/hooks';
import { useHasMultipleLocations } from 'domains/Catalog/hooks';
import { InputStock } from 'domains/Catalog/Products/pages/components';
import ApplyValuesToAllVariants from 'domains/Catalog/Products/pages/components/ApplyValuesToAllVariants';
import { LocationSelection } from 'domains/Catalog/Products/pages/components/Location/components';
import { LinkStock } from 'domains/Catalog/Products/pages/components/Stock/components';
import { useStockHistoryContext } from 'domains/Catalog/Products/pages/components/StockHistoryModal/hooks';
import WeightInput from 'domains/Catalog/Products/pages/components/WeightInput';
import { trackingProductVariantStockEditClick } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import EditVariantStockModal from './EditVariantStockModal';
import VariantsPeviewDesktopHeader from './VariantsPeviewDesktopHeader';
import { VariantListItemInterface } from '../../../CardVariants/CardVariants';
import VariantLocationTag from '../../../VariantLocationTag';
import { VariantsPreviewProps } from '../../VariantsPreview';
import {
  GROW_VARIANT,
  BASIS_VARIANT,
  GROW_LOCATION,
  GROW_ACTIONS,
  BASIS_ACTIONS,
} from '../constants';
import IconButtonStockHistory from '../IconButtonStockHistory';
import VariantInfo from '../VariantInfo';

const { Row, Cell } = DataTable;

type VariantsPreviewDesktopProps = VariantsPreviewProps & {
  locations: LocationResponseDto[];
};

function VariantsPreviewDesktop({
  variants,
  locations,
  isEdit,
  onClickVariant,
  onClickImage,
  onChange,
  onChangeToAllVariants,
  isDigital = false,
}: VariantsPreviewDesktopProps): JSX.Element {
  const t = useTranslationCatalog();
  const { goTo } = useNavegate();
  const hasMultipleLocations = useHasMultipleLocations(locations);
  const copyToClipboard = useClipboard();
  const hasShowVariantId = useHasShowVariantId();

  const canEditPrice = useHasPermission('edit_price');
  const canEditStock = useHasPermission('edit_stock');

  const { symbol } = useGetCurrency();
  const [selectedVariant, setSelectedVariant] =
    useState<VariantListItemInterface>();
  const [variantChangedId, setVariantChangedId] = useState<
    undefined | number
  >();
  const { openStockHistoryModal, showAccessToStockHistory } =
    useStockHistoryContext();
  const [showEditStockModal, openEditStockModal, closeEditStockModal] =
    useModal();

  const handleOnChange =
    (i: number) =>
    ({ name, value }: InterfaceNameValue) => {
      setVariantChangedId(i);
      onChange(i, { [name]: value });
    };

  const handleChangeWeight =
    (i: number) =>
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setVariantChangedId(i);
      onChange(i, { [name]: value });
    };

  const handleOpenVariantStock = (variant: VariantListItemInterface) => {
    trackingProductVariantStockEditClick(variant);
    if (hasMultipleLocations) {
      goTo(`/inventory/${variant.productId}/${variant.id}`);
    } else {
      setSelectedVariant(variant);
      openEditStockModal();
    }
  };

  const handleChangePrice =
    (i: number) =>
    ({ name, value }: InterfaceNameValue) => {
      setVariantChangedId(i);
      onChange(i, { [name]: value, pricePublished: true });
    };

  const handleCopyVariantId = (id: string) => {
    copyToClipboard(id, t('products.detail.copyVariantId.success'));
  };

  return (
    <>
      <DataTable>
        <VariantsPeviewDesktopHeader
          hasMultipleLocations={hasMultipleLocations}
          isDigital={isDigital}
        />
        {variants.map((variant, i) => (
          <Row key={i} id={variant.id}>
            <Cell grow={GROW_VARIANT} basis={BASIS_VARIANT}>
              <VariantInfo
                variant={variant}
                locations={locations}
                index={i}
                isDigital={isDigital}
                onChange={onChange}
                onClickImage={onClickImage}
                onClickVariant={onClickVariant}
                onOpenVariantStock={handleOpenVariantStock}
              />
            </Cell>
            {variant.show && (
              <>
                <Cell>
                  <Stack column align="stretch" spacing="tight">
                    {!isEdit || !variant.id ? (
                      <InputStock
                        value={variant.stock}
                        placeholder="∞"
                        onChange={handleOnChange(i)}
                        disabled={!canEditStock}
                      />
                    ) : (
                      <LinkStock
                        variant={variant}
                        onEditStock={() => handleOpenVariantStock(variant)}
                        canEditStock={canEditStock}
                      />
                    )}
                  </Stack>
                </Cell>
                {hasMultipleLocations && (
                  <Cell grow={GROW_LOCATION}>
                    {!variant.id ? (
                      <LocationSelection
                        locations={locations}
                        locationId={variant.locationId}
                        hasVariants
                        isMobile={false}
                        onChange={handleOnChange(i)}
                      />
                    ) : (
                      <Stack align="stretch" spacing="none">
                        <VariantLocationTag
                          variant={variant}
                          locations={locations}
                        />
                      </Stack>
                    )}
                  </Cell>
                )}
                <Cell>
                  <Stack column align="stretch" spacing="none">
                    <InputNumber
                      type="float"
                      value={`${variant.price}`}
                      name="price"
                      onChange={handleChangePrice(i)}
                      prependLabel={symbol}
                      disabled={!canEditPrice}
                      autoSelect
                    />
                    <ApplyValuesToAllVariants
                      valueWasManuallyChanged={variantChangedId === i}
                      value={variant.price}
                      onChange={onChangeToAllVariants('price', variant.price)}
                    />
                  </Stack>
                </Cell>
                {!isDigital && (
                  <Cell>
                    <Stack column align="stretch" spacing="none">
                      <WeightInput
                        value={`${variant.weight}`}
                        onChange={handleChangeWeight(i)}
                      />
                      <ApplyValuesToAllVariants
                        valueWasManuallyChanged={variantChangedId === i}
                        value={variant.weight}
                        onChange={onChangeToAllVariants(
                          'weight',
                          variant.weight,
                        )}
                      />
                    </Stack>
                  </Cell>
                )}
                <Cell grow={GROW_ACTIONS} basis={BASIS_ACTIONS}>
                  <Stack spacing="tight">
                    <ActionIconButton
                      content={t('products.variants.editVariant')}
                      source={<EditIcon />}
                      onClick={() => onClickVariant(i)}
                    />
                    {!!variant.id && hasShowVariantId && (
                      <ActionIconButton
                        content={t('products.detail.copyVariantId.label')}
                        source={<CopyIcon />}
                        onClick={() => handleCopyVariantId(variant.id)}
                      />
                    )}
                    {showAccessToStockHistory && !!variant.id && (
                      <IconButtonStockHistory
                        onClick={() =>
                          openStockHistoryModal({
                            variantId: variant.id,
                            variantName: variant.name,
                            productId: variant.productId,
                          })
                        }
                      />
                    )}
                  </Stack>
                </Cell>
              </>
            )}
          </Row>
        ))}
      </DataTable>
      {!hasMultipleLocations && showEditStockModal && selectedVariant && (
        <EditVariantStockModal
          closeEditStockModal={closeEditStockModal}
          variant={selectedVariant}
        />
      )}
    </>
  );
}

export default VariantsPreviewDesktop;
