import { Button, Stack } from '@tiendanube/components';
import {
  CustomerDataCardSkeleton,
  DeliveryInfoCardSkeleton,
} from '../../components';

function CustomerFormPageSkeleton(): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <CustomerDataCardSkeleton />
      </Stack.Item>
      <Stack.Item>
        <DeliveryInfoCardSkeleton />
      </Stack.Item>
      <Stack.Item>
        <Stack spacing="base" justify="flex-end">
          <Stack.Item>
            <Button.Skeleton />
          </Stack.Item>
          <Stack.Item>
            <Button.Skeleton />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item> </Stack.Item>
      <Stack.Item> </Stack.Item>
    </Stack>
  );
}

export default CustomerFormPageSkeleton;
