import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProductsSortByEnum } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getMoreSelectProductList,
  getSelectProductList,
} from 'domains/Catalog/Products/productsSlice';
import {
  getAuthorizeStatusCode,
  getSelectProductListDataSelector,
  getSelectProductListRefreshStatusSelector,
  getSelectProductListStatusSelector,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { SelectedProductInterface } from '../types';

interface UseSelectProductsListResult {
  products: SelectedProductInterface[] | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isRefreshError: boolean;
  isRefreshSuccess: boolean;
  isRefreshing: boolean;
  isInternalServerError: boolean;
  isNotFoundError: boolean;
  fetchDraftOrderProducts: (term?: string) => Promise<void>;
  getMoreDraftOrdersProducts: (term?: string) => Promise<void>;
}

const NOT_FOUND_ERROR_CODE = '404';

export function useSelectProductsList({
  sortBy,
}: {
  sortBy: ProductsSortByEnum | null;
}): UseSelectProductsListResult {
  const dispatch = useAppDispatch();
  const products = useSelector(getSelectProductListDataSelector);

  const { isLoading, isError, isSuccess } = useSelector(
    getSelectProductListStatusSelector,
  );
  const { isRefreshError, isRefreshSuccess, isRefreshing } = useSelector(
    getSelectProductListRefreshStatusSelector,
  );
  const statusCode = useSelector(getAuthorizeStatusCode);

  const fetchDraftOrderProducts = useCallback(
    async (q?: string) => {
      await dispatch(
        getSelectProductList({
          ...(q && { q }),
          ...(sortBy && { sortBy }),
        }),
      );
    },
    [dispatch, sortBy],
  );

  const getMoreDraftOrdersProducts = useCallback(
    async (q?: string) => {
      await dispatch(
        getMoreSelectProductList({
          ...(q && { q }),
          ...(sortBy && { sortBy }),
        }),
      );
    },
    [dispatch, sortBy],
  );

  useEffect(() => {
    fetchDraftOrderProducts();
  }, [fetchDraftOrderProducts]);

  const isInternalServerError =
    isError && statusCode !== '' && statusCode !== NOT_FOUND_ERROR_CODE;

  const isNotFoundError = isError && statusCode === '404';

  return {
    products,
    isLoading,
    isError,
    isSuccess,
    isRefreshing,
    isRefreshSuccess,
    isRefreshError,
    isNotFoundError,
    isInternalServerError,
    fetchDraftOrderProducts,
    getMoreDraftOrdersProducts,
  };
}

export default useSelectProductsList;
