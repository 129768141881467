import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useModal } from 'commons/hooks';
import { VariantListItemInterface } from 'domains/Catalog/Products/pages/components/Variants/components/CardVariants';

type EditStock = Pick<
  VariantListItemInterface,
  'id' | 'productId' | 'stock' | 'name' | 'locationId'
>;

interface EditStockContextInterface {
  openEditStock: (variant: EditStock) => void;
  closeEditStock: () => void;
  isOpenEditStock: boolean;
  variantForEditStock?: EditStock;
}

const noOp = () => void 0;

// In case there's no provider we will fail silently using these defaults.
export const EditStockContext = createContext<EditStockContextInterface>({
  openEditStock: noOp,
  closeEditStock: noOp,
  isOpenEditStock: false,
  variantForEditStock: undefined,
});

type EditStockProviderProps = {
  readonly children: ReactNode;
};

function EditStockProvider({ children }: EditStockProviderProps) {
  const [variantForEditStock, setVariantForEditStock] = useState<
    EditStock | undefined
  >(undefined);
  const [isOpen, openModal, closeModal] = useModal();

  const openEditStock = useCallback(
    (variant: EditStock) => {
      setVariantForEditStock(variant);
      openModal();
    },
    [openModal],
  );

  const closeEditStock = useCallback(() => {
    setVariantForEditStock(undefined);
    closeModal();
  }, [closeModal]);

  const memoizedValue = useMemo(
    () => ({
      openEditStock,
      closeEditStock,
      variantForEditStock,
      isOpenEditStock: isOpen,
    }),
    [openEditStock, closeEditStock, variantForEditStock, isOpen],
  );

  return (
    <EditStockContext.Provider value={memoizedValue}>
      {children}
    </EditStockContext.Provider>
  );
}

export default EditStockProvider;
