import { Link, Stack } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import downloadBlob from 'commons/utils/downloadBlob';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useDownloadTemplateCsv } from 'domains/Catalog/Products/pages/ImportAndExportProductsPage/hooks';

function DownloadTemplateLink(): JSX.Element | null {
  const t = useTranslationCatalog();
  const { isMobile } = useResponsive();
  const { isLoading, getTemplateCsv } = useDownloadTemplateCsv({
    messageSuccess: t('products.importExportPage.import.downloadCsv.success'),
    messageError: t('products.importExportPage.import.downloadCsv.error'),
  });

  const onClick = async () => {
    const templateCsv = await getTemplateCsv();
    if (templateCsv && templateCsv.fileName && templateCsv.content) {
      downloadBlob(templateCsv.fileName, templateCsv.content);
    }
  };

  const textLink = isLoading
    ? t('products.importExportPage.import.downloadCsv.loading')
    : t('products.importExportPage.import.downloadCsv.title');

  if (isMobile) {
    return null;
  }

  return (
    <Stack.Item>
      <Link
        appearance="default"
        icon={DownloadIcon}
        iconPosition="start"
        onClick={onClick}
        underline
        spinner={isLoading}
        disabled={isLoading}
      >
        {textLink}
      </Link>
    </Stack.Item>
  );
}

export default DownloadTemplateLink;
