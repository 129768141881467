import { Stack, Text } from '@tiendanube/components';
import { LeftAndRightButtons } from 'commons/components';
import { usePaginationStratus } from 'commons/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

interface StatsProductsItemPaginationInterface {
  totalResults: number;
  perPage: number;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
}

interface StatsProductsItemPaginationProps {
  pagination: StatsProductsItemPaginationInterface;
  onPageSelect: (page: number) => void;
}

function StatsProductsItemPagination({
  pagination: { totalResults, perPage, currentPage, totalPages },
  onPageSelect,
}: StatsProductsItemPaginationProps) {
  const t = useTranslationStatistics();
  const { firstItem, lastItem } = usePaginationStratus({
    currentPage,
    perPage,
    totalItems: totalResults,
  });
  const hasNextPage = currentPage < totalPages;
  const hasPrevPage = currentPage < firstItem;

  const handleOnNext = () => {
    onPageSelect(currentPage + 1);
  };
  const handleOnPrevious = () => {
    onPageSelect(currentPage - 1);
  };
  return (
    <Stack justify="space-between">
      <Stack.Item>
        <Text appearance="light">
          {t('statistics.statsProductsCard.paginationLabel', {
            currentPage: firstItem,
            perPage: lastItem,
            totalItems: totalResults,
          })}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <LeftAndRightButtons
          onPrevious={handleOnPrevious}
          onNext={handleOnNext}
          disabledNext={!hasNextPage}
          disabledPrevious={!hasPrevPage}
          grayed
        />
      </Stack.Item>
    </Stack>
  );
}

export default StatsProductsItemPagination;
