import { Stack, Text } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';

function Skeleton() {
  return (
    <CardWithTitle>
      <Stack column align="stretch" spacing="tight">
        <Stack wrap justify="space-between">
          <Text.Skeleton size="caption" />
          <Text.Skeleton size="caption" width="small" />
        </Stack>
        <Stack wrap justify="space-between">
          <Text.Skeleton size="caption" />
          <Text.Skeleton size="caption" width="small" />
        </Stack>
        <Stack wrap justify="space-between">
          <Text.Skeleton size="caption" />
          <Text.Skeleton size="caption" width="small" />
        </Stack>
      </Stack>
    </CardWithTitle>
  );
}

export default Skeleton;
