import { Text } from '@nimbus-ds/components';
import { LockIcon } from '@nimbus-ds/icons';
import { Trans, useTranslation } from 'react-i18next';
import { tranckingCloudflareMigrationAdBarClick } from 'App/tracking';
import { ExternalLink } from 'commons/components';
import { TOP_ADBAR_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { AdBar } from './AdBar';

export function NeedsCloudflareMigration() {
  const { t } = useTranslation('common');
  const language = useTranslationLanguage();
  const link = TOP_ADBAR_LINKS[language];

  const handleClick = () => {
    tranckingCloudflareMigrationAdBarClick();
  };

  return (
    <AdBar appearence="danger">
      <LockIcon />
      <Text color="danger-textLow">
        <Trans
          t={t}
          i18nKey="topAdBar.cloudflare.text"
          components={{ bold: <strong /> }}
        />{' '}
        <ExternalLink href={link} onClick={handleClick} appearance="primary">
          {t('topAdBar.cloudflare.link')}
        </ExternalLink>
      </Text>
    </AdBar>
  );
}
