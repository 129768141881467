import { ChangeEvent } from 'react';
import { Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NotificationDetailsDto } from '@tiendanube/common';
import { AlertSessionStorage } from 'App/components';
import { LanguagesType } from 'App/i18n';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface TextTemplateTabProps {
  values: NotificationDetailsDto;
  placeholder: string;
  language: LanguagesType;
  isPhishingSuspect: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function TextTemplateTab({
  values,
  placeholder,
  language,
  isPhishingSuspect,
  onChange,
}: Readonly<TextTemplateTabProps>) {
  const t = useTranslationConfigurations(
    'notifications.editNotificationPage.formCard.templateText',
  );
  return (
    <Stack column align="stretch">
      <Stack column spacing="tight" align="flex-start">
        <Text fontSize="highlight" fontWeight="bold">
          {t('title')}
        </Text>
        <Text>{t('subtitle')}</Text>
      </Stack>
      {values.htmlEnabled && (
        <AlertSessionStorage
          keyName="htmlEmailAlert"
          appearance="primary"
          title={t('htmlAlert.title')}
        >
          {t('htmlAlert.body')}
        </AlertSessionStorage>
      )}
      <FormField.Textarea
        id="templateText"
        label={t('body')}
        lines={28}
        placeholder={placeholder}
        name="templateText"
        disabled={isPhishingSuspect}
        value={values[language].templateText}
        onChange={onChange}
      />
    </Stack>
  );
}
