import { FacebookConnectionStatusResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import { DASHBOARD_SOCIAL_MESSAGES_FACEBOOK_CONNECTION_URL } from '../urls';

export async function getFacebookConnectionStatus() {
  const { data } = await axios.get<FacebookConnectionStatusResponseDto>(
    DASHBOARD_SOCIAL_MESSAGES_FACEBOOK_CONNECTION_URL,
  );

  return data;
}
