import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import useCompareFeatureValues from '../useCompareFeatureValues';

interface GetNextPlanParams {
  featureKey: string;
  minValue: TypeFeaturesPlansListResponseDto;
  isNegativeFeature: boolean;
}

function useFindPlanForFeature() {
  const { plansList, currentPlanId } = useBillingPlans();
  const { negatableMeetsRequirement } = useCompareFeatureValues();

  const getCurrentPlan = () =>
    plansList.find((plan) => plan.id === currentPlanId);

  const getNextPlan = ({
    featureKey,
    minValue,
    isNegativeFeature,
  }: GetNextPlanParams) =>
    plansList.find((plan) => {
      const featureValue = plan.featureKeys[featureKey];

      if (featureValue === undefined) {
        return false;
      }

      return negatableMeetsRequirement(
        featureValue,
        minValue,
        isNegativeFeature,
      );
    });

  return {
    getCurrentPlan,
    getNextPlan,
  };
}

export default useFindPlanForFeature;
