export const getTimestampUTC = (): number => {
  const date = new Date();
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
  return Math.floor(new Date(now_utc).getTime() / 1000);
};

export const isExpired = (time: number): boolean => {
  const now = getTimestampUTC();
  return now > time;
};
