import { Link } from '@tiendanube/components';
import { CheckCircleIcon } from '@tiendanube/icons';

interface IconSaveLink {
  onClick: () => void;
}

function IconSaveLink({ onClick }: IconSaveLink) {
  return <Link onClick={onClick} icon={CheckCircleIcon} />;
}

export default IconSaveLink;
