import { NON_FREEMIUM_PLANS_PAGE_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useGetIsFreemium } from 'domains/Auth/hooks';

/**
 * Custom hook to check if a redirection to Old Admin should be performed.
 * @returns A boolean. True if the flow should stay in Admin, False if the flow should be redirected to Old Admin.
 *  */
const useShouldRedirectPlanPage = (): boolean => {
  const isFreemium = useGetIsFreemium();
  const [hasNonFreemiumPlansPageTag] = useHasTags(NON_FREEMIUM_PLANS_PAGE_TAG);
  const plansPageNewAdmin = hasNonFreemiumPlansPageTag || isFreemium;

  return plansPageNewAdmin;
};

export default useShouldRedirectPlanPage;
