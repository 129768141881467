import { Text } from '@nimbus-ds/components';
import { dateFormat, Format } from 'commons/utils/date';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface ChargePeriodDetailProps {
  fromDate?: string;
  toDate?: string;
  isProportional?: boolean;
}

export default function ChargePeriodDetail({
  fromDate,
  toDate,
  isProportional,
}: Readonly<ChargePeriodDetailProps>) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.chargePeriodDetail',
  );

  if (!fromDate || !toDate) return null;

  return (
    <Text fontSize="caption">
      {t(isProportional ? 'proportionalMessage' : 'message', {
        toDate: dateFormat(toDate, Format.DD_MM_YYYY),
        fromDate: dateFormat(fromDate, Format.DD_MM_YYYY),
      })}
    </Text>
  );
}
