import { FC } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import { AddressCardValuesInterface } from '../../pages/components/AddressCard/AddressCard';
import AddressCardBrazil, {
  AddressCardBrazilProps,
} from '../../pages/components/AddressCard/components/AddressCardBrazil/AddressCardBrazil';
import AddressCardChile from '../../pages/components/AddressCard/components/AddressCardChile';
import { AddressCardChileProps } from '../../pages/components/AddressCard/components/AddressCardChile/AddressCardChile';
import AddressCardColombia from '../../pages/components/AddressCard/components/AddressCardColombia';
import { AddressCardColombiaProps } from '../../pages/components/AddressCard/components/AddressCardColombia/AddressCardColombia';
import AddressCardGeneral, {
  AddressCardGeneralProps,
} from '../../pages/components/AddressCard/components/AddressCardGeneral/AddressCardGeneral';

interface UseLocationFormByCountryResult {
  component:
    | FC<AddressCardBrazilProps>
    | FC<AddressCardGeneralProps>
    | FC<AddressCardColombiaProps>
    | FC<AddressCardChileProps>;
}

const useLocationFormByCountry = (
  values: AddressCardValuesInterface,
): UseLocationFormByCountryResult => {
  const storeCountry = useGetCountry();
  const country =
    values.country === Country.BR || storeCountry === Country.BR
      ? Country.BR
      : values.country;

  switch (country) {
    case Country.BR:
      return {
        component: AddressCardBrazil,
      };
    case Country.CO:
      return {
        component: AddressCardColombia,
      };
    case Country.CL:
      return {
        component: AddressCardChile,
      };
    default: {
      return {
        component: AddressCardGeneral,
      };
    }
  }
};

export default useLocationFormByCountry;
