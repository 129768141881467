import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  fetchTransactionFees as fetchTransactionFeesAction,
  getMoreTransactionsList as getMoreTransactionsAction,
} from 'domains/Billing/TransactionFees/TransactionFeesSlice';
import {
  getTransactionFeesIds,
  getTransactionFeesPagination,
  getTransactionFeesStatus,
} from '../../../TransactionFeesSlice';

interface UseTransactionsListResults {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  transactionIds: string[];
  pagination: PaginationResponseDto;
  refreshTransactions: () => Promise<void>;
  getMoreTransactions: () => Promise<void>;
  goToPage: (p: number) => void;
}

const DEFAULT_LIMIT = 20;

export interface UseTransactionListParams {
  split: boolean;
  limit?: number;
  paymentId?: string;
}

function useTransactionsList({
  split,
  limit = DEFAULT_LIMIT,
  paymentId,
}: UseTransactionListParams): UseTransactionsListResults {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(
    getTransactionFeesStatus,
  );
  const transactionIds = useSelector(getTransactionFeesIds);
  const pagination = useSelector(getTransactionFeesPagination);

  const refreshTransactions = useCallback(async () => {
    await dispatch(
      fetchTransactionFeesAction({ limit, paymentId, page: 1, split }),
    );
  }, [dispatch, split, limit, paymentId]);

  const goToPage = useCallback(
    (page: number) => {
      dispatch(fetchTransactionFeesAction({ limit, paymentId, page, split }));
    },
    [dispatch, limit, split, paymentId],
  );

  const getMoreTransactions = useCallback(async () => {
    await dispatch(getMoreTransactionsAction({ split, paymentId }));
  }, [dispatch, split, paymentId]);

  return {
    isLoading,
    isError,
    isSuccess,
    transactionIds,
    pagination,
    refreshTransactions,
    getMoreTransactions,
    goToPage,
  };
}

export default useTransactionsList;
