import { useRef, memo } from 'react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { getConfig } from './config';
import './Editor.css';

interface EditorProps {
  showToolbar: boolean;
  language: string;
  value: string;
  onChange: (value: string) => void;
}

const BASE_URL = import.meta.env.BASE_URL;
const SCRIPT_SRC = 'js/tinymce/5.10.7/tinymce.min.js';
const TINYMCE_SCRIPT =
  !BASE_URL || BASE_URL === '/' ? SCRIPT_SRC : `${BASE_URL}/${SCRIPT_SRC}`;

function Editor({
  showToolbar,
  language,
  value,
  onChange,
}: EditorProps): JSX.Element {
  const t = useTranslationCatalog();
  const editableRef = useRef(false);

  const config = getConfig(t);

  const handleChange = (value: string) => {
    if (editableRef.current) {
      onChange(value);
    }
  };

  const handleFocus = () => {
    editableRef.current = true;
  };

  const toolbarEditor = !showToolbar && { toolbar: false, statusbar: false };
  const init = { ...config, language, ...toolbarEditor };

  return (
    <TinyEditor
      value={value}
      onEditorChange={handleChange}
      tinymceScriptSrc={TINYMCE_SCRIPT}
      init={init}
      onFocus={handleFocus}
    />
  );
}

export default memo(Editor, (prev, next) => prev.value === next.value);
