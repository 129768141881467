import { ChangeEvent, useState } from 'react';
import { Box, Input, Text } from '@nimbus-ds/components';
import ModalAside from 'App/components/lab/ModalAside';
import { CancelAndConfirmButtons, HeaderContent } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { PAYMENT_METHOD_OTHER } from '../../../constants';

interface OtherPaymentMethodModalInterface {
  isOpen: boolean;
  isCharge: boolean;
  onClose: () => void;
  onSubmit: (method: string, name?: string) => void;
}

function OtherPaymentMethodModal({
  isOpen,
  isCharge,
  onClose,
  onSubmit,
}: Readonly<OtherPaymentMethodModalInterface>) {
  const t = useTranslationOrders();
  const [name, setName] = useState('');

  const labelsPrefix = isCharge ? 'charge' : 'return';

  const handleSubmit = () => {
    onSubmit(PAYMENT_METHOD_OTHER, name);
  };

  const handleMethodChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return (
    <ModalAside
      hasVirtualizationContent
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerContent={
        <HeaderContent
          title={t(`cardPayment.otherPaymentMethod.${labelsPrefix}.title`)}
        />
      }
    >
      <Box>
        <Text fontSize="highlight" color="neutral-textHigh">
          {t(`cardPayment.otherPaymentMethod.${labelsPrefix}.subtitle`)}
        </Text>
      </Box>
      <Box>
        <Text fontSize="caption">
          {t('cardPayment.otherPaymentMethod.methodName')}
        </Text>
        <Input
          value={name}
          placeholder={t('cardPayment.otherPaymentMethod.placeholder')}
          onChange={handleMethodChange}
        />
      </Box>
      <CancelAndConfirmButtons
        onCancel={onClose}
        onConfirm={handleSubmit}
        confirmText={t(
          `cardPayment.otherPaymentMethod.${labelsPrefix}.confirmButton`,
        )}
        isConfirmDisabled={name.length === 0}
      />
    </ModalAside>
  );
}

export default OtherPaymentMethodModal;
