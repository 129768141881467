import {
  useCategoriesMetafieldsAccess,
  useGetCategoriesMetafields,
} from 'domains/Metafields/hooks';

const useCategoriesAlerts = () => {
  const { hasInternals } = useGetCategoriesMetafields();
  const { isAvailable: hasCategoriesMetafieldsAccess } =
    useCategoriesMetafieldsAccess();

  const showCreateMetafieldsAlert =
    hasCategoriesMetafieldsAccess && !hasInternals;

  return {
    showCreateMetafieldsAlert,
  };
};

export default useCategoriesAlerts;
