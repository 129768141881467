import { Device } from '@capacitor/device';
import { LanguageDetectorAsyncModule } from 'i18next';
import { getFixedLocaleFromLanguage } from 'commons/utils';
import { i18nConfig, Locale } from './enums';
import { getStorage } from '../storage';

const getLanguageFromStorage = async (): Promise<Locale | null> => {
  const locale = await getStorage<Locale | null>(i18nConfig.STORAGE);
  return locale;
};

const getLanguageFromDevice = async (): Promise<Locale> => {
  const { value } = await Device.getLanguageCode();
  return getFixedLocaleFromLanguage(value);
};

type CallbackType = (locale: Locale) => undefined;

export const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: async (callback: CallbackType) => {
    const localeFromStorage = await getLanguageFromStorage();
    if (localeFromStorage) {
      return callback(localeFromStorage);
    } else {
      const localeFromDevice = await getLanguageFromDevice();
      return callback(localeFromDevice);
    }
  },
  init() {
    // do nothing.
  },
  cacheUserLanguage() {
    // do nothing.
  },
};
