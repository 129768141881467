import { Stack, BaseCard, Title, Text, Input } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function Skeleton(): JSX.Element {
  const t = useTranslationCatalog();
  const title = t('categories.detail.seoCategory.title');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{title}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column spacing="base" align="stretch">
          <Stack column spacing="tight" align="stretch">
            <Input.Skeleton />
            <Text.Skeleton width="large" />
          </Stack>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Skeleton;
