import { Alert, Link, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface PhishingSuspectAlertProps {
  isError: boolean;
  isPhishingSuspect: boolean;
}

export function PhishingSuspectAlert({
  isError,
  isPhishingSuspect,
}: Readonly<PhishingSuspectAlertProps>) {
  const t = useTranslationConfigurations(
    'notifications.editNotificationPage.formCard',
  );
  const showAlert = isError || isPhishingSuspect;
  const alertTitle = isError
    ? t('saveErrorAlert.titleError')
    : t('saveErrorAlert.titleLock');

  return (
    <Alert show={showAlert} title={alertTitle} appearance="danger">
      <Stack column align="flex-start">
        <Text>{t('saveErrorAlert.content')}</Text>
        <Link as="a" href={t('saveErrorAlert.linkUrl')} target="_blank">
          {t('saveErrorAlert.link')}
        </Link>
      </Stack>
    </Alert>
  );
}
