import { FC } from 'react';
import * as Yup from 'yup';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  AddressProps,
  BrazilianAddress,
  BrazilianAddressSchema,
  GenericAddress,
  GenericAddressSchema,
} from '../../components/Address';

interface UseAddressFormResult {
  component: FC<AddressProps>;
  schema: Yup.AnyObjectSchema;
}

const useAddressForm = (): UseAddressFormResult => {
  const country = useGetCountry();

  switch (country) {
    case Country.BR:
      return {
        component: BrazilianAddress,
        schema: BrazilianAddressSchema,
      };
    default: {
      return {
        component: GenericAddress,
        schema: GenericAddressSchema,
      };
    }
  }
};

export default useAddressForm;
