import { useMemo, useRef } from 'react';
import { Box, Button, Icon, Popover, Spinner } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { PaymentOption } from 'domains/Orders/Orders/hooks/usePartialPayment/usePartialPayment';

interface PaymentDropdownButtonProps {
  orderGatewayMethod: string;
  methodOptions: PaymentOption[];
  label: string;
  isMakingPayment: boolean;
  onPartialPayment: (gateway: string, method: string) => void;
}

function PaymentDropdownButton({
  orderGatewayMethod,
  methodOptions,
  label,
  isMakingPayment,
  onPartialPayment,
}: Readonly<PaymentDropdownButtonProps>) {
  const isSorted = useRef(false);

  const sortedMethodOptions = useMemo(
    () =>
      methodOptions.sort((a, b) => {
        if (
          a.method === orderGatewayMethod &&
          b.method !== orderGatewayMethod
        ) {
          isSorted.current = true;
          return -1;
        } else if (
          a.method !== orderGatewayMethod &&
          b.method === orderGatewayMethod
        ) {
          isSorted.current = true;
          return 1;
        } else {
          return 0;
        }
      }),
    [methodOptions, orderGatewayMethod],
  );

  return (
    <Popover
      visible={!isMakingPayment ? undefined : false}
      position="bottom-start"
      arrow={false}
      content={
        <Box>
          {sortedMethodOptions.map((option, idx) => (
            <MenuButton
              key={option.method}
              active={idx === 0 && isSorted.current}
              label={option.label}
              onClick={() =>
                option.handler
                  ? option.handler()
                  : onPartialPayment(option.gateway, option.method)
              }
            />
          ))}
        </Box>
      }
    >
      <Button appearance="primary" disabled={isMakingPayment}>
        {!isMakingPayment ? (
          <Icon color="neutral-background" source={<EllipsisIcon />} />
        ) : (
          <Spinner color="neutral-background" size="small" />
        )}
        {label}
      </Button>
    </Popover>
  );
}

export default PaymentDropdownButton;
