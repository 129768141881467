import { ItemContent, Virtuoso } from 'react-virtuoso';
import { InteractiveListPriv } from '@tiendanube/components';
import Spinner from 'commons/components/Spinner';

import './VirtualInteractiveList.css';

interface VirtualInteractiveListProps {
  itemContent: ItemContent<string, unknown>; // TODO: Validate second type
  totalCount: number;
  endReached?: () => void;
  showFooter?: boolean;
}

function VirtualInteractiveList({
  totalCount,
  itemContent,
  endReached,
  showFooter = false,
}: VirtualInteractiveListProps): JSX.Element {
  const footer = () => <Spinner show={showFooter} />;
  return (
    <InteractiveListPriv>
      <Virtuoso
        useWindowScroll
        initialItemCount={totalCount - 1} // This line was added to solve the issue that does not allow rendering the items contents
        totalCount={totalCount}
        itemContent={itemContent}
        endReached={endReached}
        components={{
          Footer: footer,
        }}
        className="stratus--virtual-interactive-list"
      />
    </InteractiveListPriv>
  );
}

export default VirtualInteractiveList;
