import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Alert } from '@tiendanube/components';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';

function AlertEditPrices(): JSX.Element | null {
  const { t } = useTranslation(DomainEnum.CATALOG, {
    keyPrefix: 'products.editPrice',
  });
  const { jobStatus, removeJob } = useBackgroundJobs('editPricesProducts');

  const title = {
    pending: t('loadingTitle'),
    ready: t('successTitle'),
    failed: t('errorTitle'),
  }[jobStatus];

  const description = {
    pending: t('loadingText'),
    ready: t('successText'),
    failed: null,
  }[jobStatus];

  const appearance = {
    pending: 'primary',
    ready: 'success',
    failed: 'danger',
  }[jobStatus];

  const hadleDismiss = jobStatus !== 'pending' ? removeJob : undefined;

  const hadleShow = jobStatus && jobStatus !== 'idle';

  return (
    <Alert
      title={title}
      appearance={appearance}
      onDismiss={hadleDismiss}
      show={hadleShow}
    >
      {description}
    </Alert>
  );
}
export default AlertEditPrices;
