import { FC, useEffect } from 'react';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import {
  EmptyInvoiceList,
  InvoiceHistory,
  ErrorComponent,
  SkeletonComponent,
} from './components';
import useInvoiceHistoryMobile from './components/InvoiceHistory/components/InvoiceHistoryMobile/useInvoiceHistoryMobile';

interface useInvoiceListPageResult {
  Component: FC;
}

function useInvoiceListPage(): useInvoiceListPageResult {
  const {
    fetchInvoiceInfo,
    status: invoiceInfoStatus,
    hasInvoiceInfo,
  } = useGetInvoiceInfo();
  const {
    fetchInvoiceHistory,
    status: historyStatus,
    invoiceIds,
  } = useInvoiceHistoryMobile();

  const { isSuccess, isError } = combineStatus(
    invoiceInfoStatus,
    historyStatus,
  );

  useEffect(() => {
    fetchInvoiceHistory();
  }, [fetchInvoiceHistory]);

  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  const getComponent = () => {
    if (isError) {
      return ErrorComponent;
    }
    if (isSuccess) {
      if (!hasInvoiceInfo && !invoiceIds?.length) {
        return EmptyInvoiceList;
      } else {
        return InvoiceHistory;
      }
    }
    return SkeletonComponent;
  };

  return {
    Component: getComponent(),
  };
}

export default useInvoiceListPage;
