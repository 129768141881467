import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getAppsScopeAuthorization,
  getAppsScopeAuthorizationData,
  getAppsScopeAuthorizationStatus,
  getAppsScopeAuthorizationStatusCode,
} from 'domains/PartnersApps/partnersAppsSlice';

function useGetAppsScopes(id: string, redirectUri: string | null) {
  const { isLoading, isError, isSuccess } = useSelector(
    getAppsScopeAuthorizationStatus,
  );

  const appScopes = useSelector(getAppsScopeAuthorizationData);
  const statusCode = useSelector(getAppsScopeAuthorizationStatusCode);

  const dispatch = useAppDispatch();

  const fetchAppScopes = useCallback(
    (id: string) => {
      dispatch(getAppsScopeAuthorization({ id, redirectUri }));
    },
    [dispatch, redirectUri],
  );

  useEffect(() => {
    if (appScopes?.appId !== id) fetchAppScopes(id);
  }, [appScopes?.appId, fetchAppScopes, id]);

  return {
    fetchAppScopes,
    statusCode,
    appScopes,
    isLoading,
    isError,
    isSuccess,
  };
}

export default useGetAppsScopes;
