import { Modal, Text } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface ConfirmIgnoreModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function ConfirmIgnoreModal({
  show,
  onClose,
  onConfirm,
}: ConfirmIgnoreModalProps) {
  const t = useTranslationCatalog();

  return (
    <Modal
      title={t(
        'products.importExportPage.alterations.modalConfirmIgnore.title',
      )}
      show={show}
      onDismiss={onClose}
      secondaryAction={{
        children: `${t('products.importExportPage.alterations.cancel')}`,
        onClick: onClose,
      }}
      primaryAction={{
        children: `${t('products.importExportPage.alterations.confirm')}`,
        appearance: 'primary',
        onClick: onConfirm,
      }}
    >
      <Text>
        {t('products.importExportPage.alterations.modalConfirmIgnore.message')}
      </Text>
    </Modal>
  );
}

export default ConfirmIgnoreModal;
