import { ReactElement } from 'react';
import { useUpdateApp, UpdateApp } from 'domains/Auth/hooks';
import ForcedUpdate from './ForcedUpdate';

interface RequiresForcedUpdateProps {
  children: ReactElement;
}

function RequiresForcedUpdate({ children }: RequiresForcedUpdateProps) {
  const isInmediatedUpdate = useUpdateApp(UpdateApp.INMEDIATED);

  return isInmediatedUpdate ? <ForcedUpdate /> : children;
}

export default RequiresForcedUpdate;
