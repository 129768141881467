import { ReactNode } from 'react';
import { AppStatus } from '../../hooks/useStatusEmbeddedApp';
import AppErrorState from '../AppErrorState';
import AppLoading from '../AppLoading';

import './WrapperApp.scss';

interface WaitingAppProps {
  appId: string;
  status: AppStatus;
  children: ReactNode;
  onRetry: () => void;
  className?: string;
}
function WrapperApp({
  appId,
  status,
  children,
  onRetry,
  className,
}: WaitingAppProps): JSX.Element {
  const classReadyWithExternalClassName = className
    ? `stratus--wrapper-app_ready ${className}`
    : 'stratus--wrapper-app_ready';

  const innerClassName =
    status === AppStatus.READY
      ? classReadyWithExternalClassName
      : 'stratus--wrapper-app_not_ready';

  return (
    <>
      {status === AppStatus.LOADING && <AppLoading />}
      {status === AppStatus.ERROR && (
        <AppErrorState appId={appId} onRetry={onRetry} />
      )}
      <div className={innerClassName}>{children}</div>
    </>
  );
}

export default WrapperApp;
