import { useSelector } from 'react-redux';
import { LanguagesType } from 'App/i18n';
import {
  getAllLanguages,
  getDefaultLanguage,
} from '../../authSlice/authSelectors';

function useGetLanguage(): LanguagesType {
  const allLanguages = useSelector(getAllLanguages);
  const defaultLanguage = useSelector(getDefaultLanguage);

  return allLanguages.includes(defaultLanguage)
    ? defaultLanguage
    : allLanguages[0];
}

export default useGetLanguage;
