import * as yup from 'yup';

const validationSchema = yup.object().shape({
  fbPixelCode: yup
    .string()
    .notRequired()
    .test('fbPixelCode', 'fbPixel.errors.fbPixelCode', (value) => {
      const regexNumeric = /^[0-9]+$/;
      const regex = /fbq\('init', '(.+)'\);/g;
      const regexAlt = /_fbq.push\(\['track',(.+),/g;

      if (value) {
        return (
          regex.test(value) || regexAlt.test(value) || regexNumeric.test(value)
        );
      }
      return true;
    }),
});

export default validationSchema;
