import {
  FreeShippingDeliveryMethodsDto,
  FreeShippingZoneDto,
} from '@tiendanube/common';
import { useForm, useModal } from 'commons/hooks';
import { UseFormProps } from 'commons/hooks/useForm';
import { CategoriesInterface } from 'domains/Marketing/components';
import { validationSchema } from '../pages/validationSchema';
import { FreeShippingFormState } from '../types';

interface UseFreeShippingFormProps {
  initialValues: UseFormProps<FreeShippingFormState>['initialValues'];
  onSubmit: (data: FreeShippingFormState) => void;
}

export function useFreeShippingForm({
  initialValues,
  onSubmit,
}: UseFreeShippingFormProps) {
  const [showConfirmModal, openConfirmModal, closeConfirmModal] = useModal();

  const {
    values,
    errors,
    isDirty,
    cleanErrors,
    setFieldValue,
    handleChange,
    handleOnSubmit,
  } = useForm({
    initialValues,
    validationSchema,
    onSubmit: (submitValues) => {
      const { categoriesType, zonesType } = submitValues;
      if (categoriesType === 'some' && zonesType === 'some') {
        return onSubmit(submitValues);
      }
      openConfirmModal();
    },
  });

  const handleChangeMethods = (methods: FreeShippingDeliveryMethodsDto[]) => {
    setFieldValue('methods', methods);
    if (methods.length > 0) cleanErrors();
  };

  const handleChangeCategories = (categories: CategoriesInterface[]) => {
    setFieldValue('categories', categories);
  };

  const handleChangeZones = (zones: FreeShippingZoneDto[]) => {
    setFieldValue('zones', zones);
  };

  return {
    values,
    errors,
    isDirty,
    showConfirmModal,
    handleChangeMethods,
    handleChangeCategories,
    handleChangeZones,
    handleChange,
    handleOnSubmit,
    closeConfirmModal,
  };
}
