import { useHistory } from 'react-router-dom';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useLoadFiscalData } from './components/LoadFiscalData/hooks';

function InvoicesInfoPage(): JSX.Element {
  const { Component } = useLoadFiscalData();
  const t = useTranslationBilling();
  const history = useHistory();

  const goToBack = () => {
    history.goBack();
  };

  const backNavitation = {
    onClick: goToBack,
  };

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavitation} />}
      headerContent={
        <HeaderContent
          title={t('invoicesInfoPage.title')}
          subtitle={t('invoicesInfoPage.subtitle')}
        />
      }
      width="small"
    >
      <Component />
    </IonPageStratus>
  );
}

export default InvoicesInfoPage;
