import { VirtualDataList } from 'commons/components';
import { useAbandonedCarts } from 'domains/Orders/AbandonedCart/hooks';
import {
  AbandonedCartsListMobileRow,
  AbandonedCartsListMobileSkeleton,
} from './components';

function AbandonedCartsListMobile() {
  const {
    abandonedCartsList,
    isLoading,
    fetchAbandonedCarts,
    isRefreshing,
    getMoreAbandonedCarts,
  } = useAbandonedCarts();

  const handleRefresh = async () => {
    if (isLoading || isRefreshing) return;
    await fetchAbandonedCarts();
  };

  const shouldShowSpinner = isRefreshing && abandonedCartsList.length !== 0;

  return (
    <>
      {isLoading && <AbandonedCartsListMobileSkeleton />}
      {abandonedCartsList.length > 0 && (
        <VirtualDataList
          isPullable
          data={abandonedCartsList}
          itemContent={(_, id) => <AbandonedCartsListMobileRow id={id} />}
          onRefresh={handleRefresh}
          endReached={getMoreAbandonedCarts}
          showFooter={shouldShowSpinner}
        />
      )}
    </>
  );
}

export default AbandonedCartsListMobile;
