import { useState } from 'react';
import { Card, Title, Text } from '@nimbus-ds/components';
import {
  InventoryLevelsRequestDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useModal, useToastStatus } from 'commons/hooks';
import { StockChange } from 'domains/Catalog/Inventory/inventoryServices';
import { Stock } from 'domains/Catalog/Inventory/types';
import EditStockModal from 'domains/Catalog/Products/pages/components/Stock/EditStockModal';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import {
  SelectedLocations,
  StockHistory,
  UnselectedLocations,
} from './components';
import useStockDistribution from './useStockDistribution';
import { useInventoryByVariant } from '../../hooks';

interface StockDistributionProps {
  locations: LocationResponseDto[];
  inventory: InventoryLevelsRequestDto[];
  initialinventory: InventoryLevelsRequestDto[];
  variantId: string;
  productId: string;
  variantName: string;
  setInventory: (locationsSelected: InventoryLevelsRequestDto[]) => void;
}

function StockDistribution({
  locations,
  inventory,
  initialinventory,
  variantId,
  variantName,
  productId,
  setInventory,
}: StockDistributionProps): JSX.Element {
  const t = useTranslationCatalog();
  const { goTo } = useNavegate();
  const { status } = useInventoryByVariant(productId, variantId);

  const {
    toggleLocations,
    isChecked,
    changeStockByLocation,
    getLocationName,
    getLocationStock,
    locationsNotSelected,
    getInventoryStock,
  } = useStockDistribution(
    locations,
    initialinventory,
    inventory,
    setInventory,
  );

  const [isShowModal, openModal, closeModal] = useModal();

  const [currentStock, setCurrentStock] = useState<{
    locationId: string;
    stock: Stock;
  }>({
    locationId: '',
    stock: 0,
  });

  const handleOpenModal = (locationId: string, stock?: Stock) => {
    setCurrentStock({ locationId, stock: stock ?? null });
    openModal();
  };

  const handleEditStockClose = (stockChange?: StockChange) => {
    if (stockChange) {
      changeStockByLocation({
        locationId: stockChange.locationId,
        stock: stockChange.stock,
      });
    }
    closeModal();
  };

  const locationName = currentStock.locationId
    ? getLocationName(currentStock.locationId)
    : '';

  useToastStatus({
    status,
    error: t('editInventory.stockDistributionCard.error'),
    success: t('editInventory.stockDistributionCard.success'),
    onSuccess: () => !isShowModal && goTo(`/products/${productId}`),
  });

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">
            {t('editInventory.stockDistributionCard.title')}
          </Title>
        </Card.Header>

        <Card.Body padding="none">
          <Text>{t('editInventory.stockDistributionCard.description')}</Text>
        </Card.Body>

        <SelectedLocations
          initialinventory={initialinventory}
          onOpenModal={handleOpenModal}
          getInventoryStock={getInventoryStock}
          getLocationName={getLocationName}
        />

        {locationsNotSelected.length > 0 && (
          <UnselectedLocations
            locationsNotSelected={locationsNotSelected}
            toggleLocations={toggleLocations}
            getLocationName={getLocationName}
            getLocationStock={getLocationStock}
            changeStockByLocation={changeStockByLocation}
            isChecked={isChecked}
          />
        )}

        <StockHistory
          variantId={variantId}
          variantName={variantName}
          productId={productId}
        />
      </Card>
      <EditStockModal
        isShow={isShowModal}
        showHeader={false}
        productId={productId}
        variantId={variantId}
        currentStock={
          currentStock.stock !== null ? String(currentStock.stock) : null
        }
        displayApplyToAll={false}
        isVariantStock
        variantName={variantName}
        onClose={handleEditStockClose}
        locationId={currentStock.locationId}
        locationName={locationName}
      />
    </>
  );
}

export default StockDistribution;
