import { useRef } from 'react';

type CallbackType = (index: number) => void;

function useInterval() {
  const indexRef = useRef(1);
  const intervalRef = useRef<number>();

  const setInterval = (
    callback: CallbackType,
    period: number,
    numIterations?: number,
  ) => {
    intervalRef.current = window.setInterval(() => {
      const index = indexRef.current++;

      callback(index);
      if (index === numIterations) {
        clearInterval();
      }
    }, period);
  };

  const clearInterval = () => {
    window.clearInterval(intervalRef.current);
    indexRef.current = 1;
  };

  return { setInterval, clearInterval };
}

export default useInterval;
