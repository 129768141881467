import { ReactNode, useEffect, useState } from 'react';
import { Stack } from '@tiendanube/components';
import { Spinner } from 'commons/components';
import { StatusType } from 'commons/types';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import {
  StatisticsGranularityType,
  TotalsInterface,
} from 'domains/Statistics/types';
import xAxisFormatDate from 'domains/Statistics/utils/xAxisFormatDate';
import SummaryList from './SummaryList';
import BarChartStratus, { BarChartInterface } from '../BarChartStratus';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import DoubleCardResponsive from '../DoubleCardResponsive';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';
import LegendStratus from '../LegendStratus';
import StratusLegendContent from '../StratusLegendContent';
import StratusTooltipContent from '../StratusTooltipContent';

interface BarChartAndSummaryCardProps<TKeys extends string> {
  title: string;
  rightTitle?: string;
  colors: Record<TKeys, string>;
  formatValueSummary: (value: number) => string;
  results: Record<TKeys, BarChartInterface[]>;
  totals: Record<TKeys, TotalsInterface>;
  granularity: StatisticsGranularityType;
  defaultSelected: TKeys;
  status: StatusType;
  hasComparedFilter?: boolean;
  statsPlanRequired: number;
  upsellSource: string;
  rightOnDisabled?: ReactNode;
  onFetch: () => void;
  onClickEmpty: () => void;
  formatLabelTooltip: (
    text: string,
    selectedPrimary: string,
    selectedSecondary: string,
  ) => string;
  formatSummaryTitle: (key: TKeys) => string;
  formatSummaryTooltip: (key: TKeys) => string;
}

function BarChartAndSummaryCard<TKeys extends string>({
  title,
  rightTitle,
  colors,
  results,
  totals,
  granularity,
  defaultSelected,
  formatValueSummary,
  status,
  hasComparedFilter = false,
  statsPlanRequired,
  upsellSource,
  rightOnDisabled,
  onFetch,
  onClickEmpty,
  formatLabelTooltip,
  formatSummaryTitle,
  formatSummaryTooltip,
}: BarChartAndSummaryCardProps<TKeys>) {
  const [selecteds, setSelecteds] = useState<string[]>([defaultSelected]);
  const getFormatedDate = xAxisFormatDate(granularity);

  useEffect(() => {
    if (hasComparedFilter) setSelecteds([defaultSelected]);
  }, [hasComparedFilter, defaultSelected]);

  const handleOnChange = (value: string) => {
    if (hasComparedFilter) {
      setSelecteds([value]);
    } else {
      if (selecteds.includes(value)) {
        const filtered = selecteds.filter((s) => s !== value);
        setSelecteds(filtered.length === 0 ? selecteds : filtered);
      } else {
        setSelecteds([...selecteds, value]);
      }
    }
  };

  const prioritySort = (selecteds: string[]) =>
    selecteds.sort((a, b) => {
      const aIndex = Object.keys(colors).indexOf(a);
      const bIndex = Object.keys(colors).indexOf(b);

      return aIndex - bIndex;
    });

  const getComparedValues = (
    data?: BarChartInterface[],
    compare?: BarChartInterface[],
  ) =>
    data?.map((value, i) => ({
      ...value,
      secondaryValue: compare?.[i]?.principalValue,
    })) || [];
  const handleFormatLabelTooltip = (text: string) =>
    formatLabelTooltip(text, selectedPrimary, selectedSecondary);

  const isMaxSelected = selecteds.length > 1;

  const [selectedPrimary, selectedSecondary] = prioritySort(selecteds);

  const values = getComparedValues(
    results[selectedPrimary],
    results[selectedSecondary],
  );

  const isEmpty = !Object.values<BarChartInterface[]>(results).some(
    (value) => value.length > 0,
  );

  const showLineChart = status === 'success' && !isEmpty;

  const enabled = useSupportsStatsPlan(statsPlanRequired);

  return (
    <DoubleCardResponsive
      equalWidth={!enabled}
      title={title}
      left={
        <ChartOrPlaceholder
          type="bars"
          statsPlanRequired={statsPlanRequired}
          upsellSource={upsellSource}
        >
          <Stack column align="stretch" spacing="tight">
            {status === 'error' && <GenericError onRetry={onFetch} />}
            {status === 'loading' && <Spinner size="medium" />}
            {status === 'success' && isEmpty && (
              <GenericEmptySearch onRetry={onClickEmpty} />
            )}
            {showLineChart && (
              <BarChartStratus
                values={hasComparedFilter ? results[selectedPrimary] : values}
                primaryColor={colors[selectedPrimary]}
                xAxisFormatter={getFormatedDate}
                oneYAxis={hasComparedFilter}
                secondaryColorOpacity={hasComparedFilter} // we force add opacity in the second color only when we apply comparison in the filters
                secondaryColor={
                  hasComparedFilter
                    ? colors[selectedPrimary]
                    : colors[selectedSecondary]
                }
                customTooltipContent={
                  <StratusTooltipContent
                    granularity={granularity as StatisticsGranularityType}
                    hasComparedData={hasComparedFilter}
                    formatLabel={handleFormatLabelTooltip}
                  />
                }
                cutomLegendContent={
                  <LegendStratus>
                    <StratusLegendContent
                      hasComparedData={hasComparedFilter}
                      formatLabel={handleFormatLabelTooltip}
                    />
                  </LegendStratus>
                }
              />
            )}
          </Stack>
        </ChartOrPlaceholder>
      }
      rightTitle={!enabled && rightOnDisabled ? '' : rightTitle}
      right={
        !enabled && rightOnDisabled ? (
          rightOnDisabled
        ) : (
          <SummaryList<TKeys>
            colors={colors}
            isMaxSelected={isMaxSelected}
            totals={totals}
            onChange={handleOnChange}
            selecteds={selecteds}
            showValues={enabled}
            dataHasComparison={hasComparedFilter}
            formatValue={formatValueSummary}
            formatSummaryTitle={formatSummaryTitle}
            formatSummaryTooltip={formatSummaryTooltip}
          />
        )
      }
    />
  );
}

export default BarChartAndSummaryCard;
