import { Box, Card, Link, Select } from '@nimbus-ds/components';
import { AppsIcon } from '@nimbus-ds/icons';
import { CalloutCard, FormField } from '@nimbus-ds/patterns';
import { BUY_X_PAY_Y_PROMOTIONS_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { usePromotionTypesQuery } from 'domains/Marketing/Promotions/promotionsApi';
import { BuyXPayY } from './BuyXPayY';
import { ProgressiveDiscounts } from './ProgressiveDiscounts';
import { PromotionTypes } from '../../../pages/constants';
import { PromotionFormProps } from '../PromotionForm';

export function DiscountTypeCard({
  values,
  errors,
  onChangeSelect,
  onChangeProgressiveDiscounts,
  onChangeBuyXPayY,
}: Readonly<
  Pick<
    PromotionFormProps,
    | 'values'
    | 'errors'
    | 'onChangeSelect'
    | 'onChangeProgressiveDiscounts'
    | 'onChangeBuyXPayY'
  >
>) {
  const t = useTranslationMarketing('promotions.settingsPage.discountType');
  const [hasProgressiveDiscountsTag, hasBuyXPayYTag] = useHasTags([
    'new-admin-progressive-discount',
    BUY_X_PAY_Y_PROMOTIONS_TAG,
  ]);

  const { data: discountTypes, isLoading, isError } = usePromotionTypesQuery();

  const { promotionType, discountForQuantity, progressiveDiscounts } = values;

  const discountTypeSelected = discountTypes?.find(
    (current) => current.type === promotionType,
  );

  const external = discountTypeSelected?.external || false;

  const errorDiscount = errors?.discount || '';
  const errorQuantity = errors?.quantity || '';

  if (isLoading || isError) return null;

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="3" marginBottom="1">
          <FormField.Select
            id="discountTypes"
            name="promotionType"
            onChange={onChangeSelect}
            value={promotionType}
          >
            {discountTypes?.map(({ type, description }) => (
              <Select.Option
                key={type}
                label={
                  hasProgressiveDiscountsTag &&
                  type === PromotionTypes.PROGRESSIVE_DISCOUNT
                    ? t('progressiveDiscountTitle')
                    : description
                }
                value={type}
              />
            ))}
          </FormField.Select>
          {external && (
            <CalloutCard
              icon={AppsIcon}
              appearance="primary"
              subtitle={external.subtitle || ''}
              title={external.title || ''}
              link={
                <Link
                  appearance="primary"
                  as="a"
                  href={external.linkUrl}
                  target="_link"
                >
                  {external.linkDescription}
                </Link>
              }
            />
          )}
          {hasProgressiveDiscountsTag ? (
            <ProgressiveDiscounts
              values={values}
              errors={errors}
              onChangeProgressiveDiscounts={onChangeProgressiveDiscounts}
            />
          ) : (
            discountForQuantity && (
              <>
                <FormField.Input
                  name="discount"
                  label={t('discount')}
                  value={progressiveDiscounts[0].discount || ''}
                  onChange={(e) => {
                    onChangeProgressiveDiscounts([
                      {
                        quantity: progressiveDiscounts[0].quantity,
                        discount: e.target.value,
                      },
                    ]);
                  }}
                  append="%"
                  appendPosition="end"
                  helpText={t(errorDiscount)}
                  showHelpText={!!errorDiscount}
                  appearance={errorDiscount ? 'danger' : undefined}
                />
                <FormField.Input
                  name="quantity"
                  label={t('quantity')}
                  value={progressiveDiscounts[0].quantity || ''}
                  onChange={(e) => {
                    onChangeProgressiveDiscounts([
                      {
                        quantity: e.target.value,
                        discount: progressiveDiscounts[0].discount,
                      },
                    ]);
                  }}
                  append={t('products')}
                  appendPosition="end"
                  helpText={t(errorQuantity)}
                  showHelpText={!!errorQuantity}
                  appearance={errorQuantity ? 'danger' : undefined}
                />
              </>
            )
          )}
          {hasBuyXPayYTag && (
            <BuyXPayY
              values={values}
              errors={errors}
              onChangeBuyXPayY={onChangeBuyXPayY}
            />
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}
