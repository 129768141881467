import { Modal, Text, Button } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface LinesExceededModalProps {
  open: boolean;
  onDismiss: () => void;
  maxLines: number;
}

function LinesExceededModal({
  open,
  onDismiss,
  maxLines,
}: LinesExceededModalProps) {
  const t = useTranslationCatalog();

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Modal.Header
        title={t('products.importExportPage.import.linesExceeded.titleModal')}
      />
      <Modal.Body padding="none">
        <Text>
          {t('products.importExportPage.import.linesExceeded.messageModal', {
            max: maxLines,
          })}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss} appearance="primary">
          {t('products.importExportPage.import.linesExceeded.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinesExceededModal;
