import { Box, Button } from '@nimbus-ds/components';
import { CardWithTitle, Spinner } from 'commons/components';

export function Skeleton() {
  return (
    <CardWithTitle>
      <Box
        height="364px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="medium" />
      </Box>
      <Box marginTop="4" display="flex" gap="2">
        <Button.Skeleton />
        <Button.Skeleton />
      </Box>
    </CardWithTitle>
  );
}
