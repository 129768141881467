import { Box } from '@nimbus-ds/components';
import { CopyLink } from 'commons/components';

interface CopyLinkBoxProps {
  value: string;
  message: string;
  text: string;
  hasBackground?: boolean;
}

export function CopyLinkBox({
  value,
  message,
  text,
  hasBackground = false,
}: Readonly<CopyLinkBoxProps>) {
  return (
    <Box
      backgroundColor={hasBackground ? 'primary-surface' : undefined}
      width="fit-content"
      padding={hasBackground ? '1' : 'none'}
      borderRadius="base"
    >
      <CopyLink value={value} message={message} text={text} />
    </Box>
  );
}
