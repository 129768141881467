import { Card, Title, Text } from '@nimbus-ds/components';
import { Input } from '@tiendanube/components';
import {
  CancelAndSaveButtons,
  HeaderContent,
  IonPageStratus,
  Stack,
} from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'nuvempagoSettings';

function NuvempagoInitSettingsPageSkeleton() {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <IonPageStratus
      width="small"
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Stack column align="flex-start">
        <Card>
          <Card.Header>
            <Title as="h3">{t('card.title')}</Title>
          </Card.Header>
          <Card.Body>
            <Stack column>
              <Text>{t('card.subtitle')}</Text>
              <Input.Skeleton />
            </Stack>
          </Card.Body>
        </Card>
        <Text.Skeleton width="200px" />
        <Stack alignSelf="flex-end">
          <CancelAndSaveButtons.Skeleton />
        </Stack>
      </Stack>
    </IonPageStratus>
  );
}

export default NuvempagoInitSettingsPageSkeleton;
