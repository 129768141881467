import { useState } from 'react';
import { IconButton, Tooltip } from '@nimbus-ds/components';
import { DataTable, Link, Stack, Text } from '@tiendanube/components';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  TrashIcon,
} from '@tiendanube/icons';
import {
  InternalNavLink,
  CurrencyPrice,
  DateDayMonthText,
} from 'commons/components';
import { useClipboard, useModal } from 'commons/hooks';
import { ResultProductsDesktop } from 'domains/Orders/components';
import { useDtaftOrderListItem } from 'domains/Orders/DraftOrders/hooks';
import { ConfirmationRemoveDraftOrderModal } from 'domains/Orders/DraftOrders/pages/DraftOrderDetailsPage/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface DraftOrderListRowDesktopProps {
  id: string;
}

function DraftOrderListRowDesktop({ id }: DraftOrderListRowDesktopProps) {
  const t = useTranslationOrders();
  const copyToClipboard = useClipboard();
  const { draftOrder, removeDraftOrder } = useDtaftOrderListItem(id);
  const [showProducts, setShowProducts] = useState(false);
  const [showRemove, openRemove, closeRemove] = useModal();

  const handleRemoveDraftOrder = async () => {
    closeRemove();
    await removeDraftOrder(draftOrder.id);
  };

  const handleClickCopy = () => {
    copyToClipboard(
      draftOrder.checkoutUrl,
      t('draftOrders.paymentLinkCard.copyLinkMessage'),
    );
  };

  const toggleShowProducts = () => {
    setShowProducts(!showProducts);
  };

  return (
    <>
      <DataTable.Row id="id" key={id} align="center">
        <DataTable.Cell grow={0} basis={120}>
          <InternalNavLink to={`/draft-orders/${id}`}>
            {draftOrder.id}
          </InternalNavLink>
        </DataTable.Cell>
        <DataTable.Cell grow={0} basis={80}>
          <DateDayMonthText date={draftOrder.date} />
        </DataTable.Cell>
        <DataTable.Cell grow={1} basis={100} trimText>
          <Text>{draftOrder.contactName}</Text>
        </DataTable.Cell>
        <DataTable.Cell grow={0} basis={200}>
          <CurrencyPrice
            price={draftOrder.total}
            currency={draftOrder.currency}
          />
        </DataTable.Cell>
        <DataTable.Cell grow={0} basis={200}>
          <Link
            appearance="primary"
            icon={showProducts ? ChevronUpIcon : ChevronDownIcon}
            iconPosition="end"
            onClick={toggleShowProducts}
          >
            {t('resultRow.products.show', {
              count: draftOrder.quantityProducts,
            })}
          </Link>
        </DataTable.Cell>
        <DataTable.Cell grow={0} basis={125}>
          <Stack>
            <Tooltip content={t('draftOrders.paymentLinkCard.copyLinkText')}>
              <IconButton
                size="2rem"
                source={<CopyIcon />}
                onClick={handleClickCopy}
              />
            </Tooltip>
            <Tooltip content={t('draftOrders.delete')}>
              <IconButton
                size="2rem"
                source={<TrashIcon />}
                onClick={openRemove}
              />
            </Tooltip>
          </Stack>
        </DataTable.Cell>
      </DataTable.Row>
      <ResultProductsDesktop
        show={showProducts}
        quantityUniqueProducts={draftOrder.products.length}
        products={draftOrder.products}
        currency={draftOrder.currency}
        listLayout="draftOrders"
      />
      {showRemove && (
        <ConfirmationRemoveDraftOrderModal
          onClose={closeRemove}
          onRemoveDraftOrder={handleRemoveDraftOrder}
        />
      )}
    </>
  );
}

export default DraftOrderListRowDesktop;
