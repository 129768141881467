import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';
import { SummaryType } from 'domains/Statistics/types';
import { formatPercentage, thousandSeparator } from 'domains/Statistics/utils';

function useGetFormattedValueSummary() {
  const {
    i18n: { language },
  } = useTranslation();
  const { code } = useGetCurrency();

  const getFormatedValue = (value: number, key?: SummaryType): string => {
    const currencyformatKeys = ['billing', 'averageSpend'];
    const thousandsformatKeys = ['uniqueVisits', 'orders'];

    if (currencyformatKeys.includes(key || ''))
      return formatCurrency(value, code, language);

    if (thousandsformatKeys.includes(key || ''))
      return thousandSeparator(value);

    return formatPercentage(value);
  };

  return getFormatedValue;
}

export default useGetFormattedValueSummary;
