import { Box } from '@nimbus-ds/components';
import { TotalsInterface } from 'domains/Statistics/types';
import SummaryItemCard from '../../../SummaryItemCard';

export interface CombinedSummaryProps<TKeys extends string> {
  colors: Record<TKeys, string>;
  totals: Record<TKeys, TotalsInterface>;
  onChange: (e: string) => void;
  selecteds: string[];
  dataHasComparison: boolean;
  isMaxSelected: boolean;
  showValues: boolean;
  formatValue: (value: number, key: TKeys) => string;
  formatSummaryTitle: (key: TKeys) => string;
  formatSummaryTooltip?: (key: TKeys) => string;
}

function CombinedSummary<TKeys extends string = string>({
  totals,
  onChange,
  selecteds,
  dataHasComparison,
  isMaxSelected,
  colors,
  showValues,
  formatValue,
  formatSummaryTitle,
  formatSummaryTooltip = () => '',
}: Readonly<CombinedSummaryProps<TKeys>>) {
  return (
    <Box
      display="grid"
      gridGap="4"
      paddingLeft="4"
      paddingRight="4"
      paddingBottom="4"
    >
      {Object.keys(colors).map((key) => {
        const item = key as TKeys;
        return (
          <SummaryItemCard<TKeys>
            key={item}
            formatValue={formatValue}
            name={item}
            color={colors[item]}
            dataHasComparison={dataHasComparison}
            onChange={onChange}
            isSelected={selecteds.includes(item)}
            title={formatSummaryTitle(item)}
            value={totals[item]?.value}
            percentageDiff={
              dataHasComparison ? totals[item]?.percentageDiff : undefined
            }
            disabled={!selecteds.includes(item) && isMaxSelected}
            showValues={showValues}
            itemMode={dataHasComparison ? 'radio' : 'toggle'}
            textTooltip={formatSummaryTooltip(item)}
          />
        );
      })}
    </Box>
  );
}

export default CombinedSummary;
