import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { InterfacePaymentProvidersSchema } from '../types';

// PaymentProviders

export const getPaymentProvidersState = (
  state: RootStateType,
): CombinedState<InterfacePaymentProvidersSchema> =>
  state.payments.paymentProviders;

export const getInstalledPaymentProvidersState = (state: RootStateType) =>
  getPaymentProvidersState(state).installed;

export const getNotInstalledPaymentProvidersState = (state: RootStateType) =>
  getPaymentProvidersState(state).notInstalled;

export const getInstalledPaymentProvidersData = (state: RootStateType) =>
  getInstalledPaymentProvidersState(state).list.data;

export const getInstalledPaymentProviderById = (
  state: RootStateType,
  id: string,
) =>
  getInstalledPaymentProvidersData(state)?.find(
    (provider) => provider.id === id,
  );

export const getInstalledPaymentProvidersStatus = (state: RootStateType) => {
  const status = getInstalledPaymentProvidersState(state).list.status;
  return convertStatusTypeToObject(status);
};

export const getInstalledPaymentProviderUpdateStatus = (state: RootStateType) =>
  getPaymentProvidersState(state).updateProvider.status;

// CheckoutPaymentOptions

export const getCheckoutPaymentOptionsState = (state: RootStateType) =>
  getPaymentProvidersState(state).checkoutPaymentOptions;

export const getCheckoutPaymentOptionsData = (state: RootStateType) =>
  getCheckoutPaymentOptionsState(state).detail.data;

export const getCheckoutPaymentOptionsStatus = (state: RootStateType) => {
  const status = getCheckoutPaymentOptionsState(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const getCheckoutPaymentOptionUpdateStatus = (state: RootStateType) =>
  getCheckoutPaymentOptionsState(state).update.status;

// NotInstalledPaymentProviders

export const getNotInstalledPaymentProvidersData = (state: RootStateType) =>
  getNotInstalledPaymentProvidersState(state).list.data?.filter(
    (provider) => provider.isInstallable,
  );

export const getNotInstallablePaymentProvidersData = (state: RootStateType) =>
  getNotInstalledPaymentProvidersState(state).list.data?.filter(
    (provider) => !provider.isInstallable,
  );

export const getNotInstalledPaymentProvidersStatus = (state: RootStateType) => {
  const status = getNotInstalledPaymentProvidersState(state).list.status;
  return convertStatusTypeToObject(status);
};

// NativePaymentProviders

export const getNativePaymentProvidersState = (state: RootStateType) =>
  getPaymentProvidersState(state).nativeProviders;

export const getMercadoPagoAuthorizationStatus = (state: RootStateType) => {
  const status =
    getNativePaymentProvidersState(state).statusMercadoPagoAuthorization;
  return convertStatusTypeToObject(status);
};

export const getUpdateInstallmentsStatus = (state: RootStateType) => {
  const status = getNativePaymentProvidersState(state).statusUpdateInstallments;
  return convertStatusTypeToObject(status);
};

// Recently

export const getRecentlyEnabledPaymentProvider = (state: RootStateType) =>
  getPaymentProvidersState(state).recentlyEnabled;

export const getRecentlyDisabledPaymentProvider = (state: RootStateType) =>
  getPaymentProvidersState(state).recentlyDisabled;
