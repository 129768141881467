import { DataList } from '@nimbus-ds/patterns';
import { CurrencyType } from '@tiendanube/common';
import { EditOrderProductItem } from './ProductItem';
import { OrderEditProduct } from '../types';

interface EditOrderProductList {
  products: OrderEditProduct[];
  currency: CurrencyType;
  onEditProduct: (product: OrderEditProduct) => void;
  onRemoveProduct: (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => void;
  onReAddProduct: (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => void;
  onRestoreStock: (
    productId: string,
    variantId: string,
    isRestore: boolean,
    lineItemId?: string,
  ) => void;
  editable: boolean;
}

function EditOrderProductList({
  products,
  currency,
  onEditProduct,
  onRemoveProduct,
  onReAddProduct,
  onRestoreStock,
  editable,
}: Readonly<EditOrderProductList>) {
  return (
    <DataList>
      {products.map((product) => (
        <DataList.Row
          key={`${product.id}-${product.productId}-${product.variantId}`}
        >
          <EditOrderProductItem
            product={product}
            currency={currency}
            onEditProduct={onEditProduct}
            onRemoveProduct={onRemoveProduct}
            onReAddProduct={onReAddProduct}
            onRestoreStock={onRestoreStock}
            editable={editable}
          />
        </DataList.Row>
      ))}
    </DataList>
  );
}

export default EditOrderProductList;
