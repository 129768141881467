import { useEffect } from 'react';
import { useIsMobileDevice } from 'domains/Auth/hooks';

export function useFixedBulkHeaderScroll() {
  const isMobile = useIsMobileDevice();
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled =
        !!document.scrollingElement?.scrollTop &&
        document.scrollingElement?.scrollTop > 160;

      const bulkHeader = document.getElementsByClassName(
        'nimbus--data-table__bulk-actions',
      )[0];
      if (!bulkHeader) return;

      if (isMobile) {
        bulkHeader.classList.toggle('fixed-to-top-mobile', isScrolled);
      }
      bulkHeader.classList.toggle('fixed-to-top', isScrolled);
    };

    const listener = () => handleScroll();
    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isMobile]);
}
