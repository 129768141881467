import { ErrorScreen } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface OrganizeSectionErrorProps {
  onRetry: () => void;
}

function OrganizeSectionError({ onRetry }: OrganizeSectionErrorProps) {
  const t = useTranslationCatalog();
  return (
    <ErrorScreen
      description={t('products.organizeProducts.featuredProducts.error')}
      onRetry={onRetry}
    />
  );
}

export default OrganizeSectionError;
