import { AddressForDocumentResponseDto } from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface SenderDocumentPreviewProps {
  storeName: string;
  address: AddressForDocumentResponseDto | null;
}

function SenderDocumentPreview({
  storeName,
  address,
}: Readonly<SenderDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="address-section">
      <p>{t('shipping.sender')}</p>
      <p className="bold-name">{storeName}</p>
      {!!address && (
        <>
          {!!address.streetAddress && <p>{address.streetAddress}</p>}
          {!!address.additionalAddressDetails && (
            <p>{address.additionalAddressDetails}</p>
          )}
          {!!address.zipLocation && <p>{address.zipLocation}</p>}
          {!!address.country && <p>{address.country}</p>}
        </>
      )}
    </div>
  );
}

export default SenderDocumentPreview;
