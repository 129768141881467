import HelpLink from 'App/HelpLink';
import { useNavegate, useQuery } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import {
  ErrorScreen,
  HeaderContent,
  IonPageStratus,
  Layout,
} from 'commons/components';
import { HELP_LINKS_THEMES } from 'commons/constants';
import { useBoolean, useTranslationLanguage } from 'commons/hooks';
import { useGetIsFreePlan, useGetTags } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { shouldPromoteTheme } from './business-rules/shouldPromoteTheme';
import {
  DraftCard,
  DraftPublishedAlert,
  FtpCard,
  IdentityCard,
  ThemeCard,
  ThemesPageSkeleton,
  UpgradePlanAlert,
} from './components';
import { PromotedTheme } from './components/PromotedTheme/PromotedTheme';
import { PROMOTED_THEME } from './constants';
import { useGetThemesConfigQuery } from '../../themesApi';
import { THEMES_ROUTES } from '../../themesRoutes';

function ThemesPage() {
  const t = useTranslationOnline('themesPage');
  const language = useTranslationLanguage();
  const { data: themesConfig, status, refetch } = useGetThemesConfigQuery();
  const { replace } = useNavegate();
  const publishedDraftmodal = useQuery().get('publishedDraft') === 'true';
  const [
    showDraftPublishedAlert,
    setShowDraftPublishedAlertOn,
    setShowDraftPublishedAlertOff,
  ] = useBoolean(publishedDraftmodal);
  const hideDraftPublishedAlert = () => {
    setShowDraftPublishedAlertOff();
    replace(THEMES_ROUTES.themes);
  };

  const isLoading = toStatusType(status) === 'loading';
  const isError = toStatusType(status) === 'error';
  const showThemesPage = !isLoading && !isError && !!themesConfig;
  const hasThemeConfigAvailable = themesConfig?.type !== 'LOJINHA';
  const hasFtpConfigAvailable = !themesConfig?.planUpgradeData;
  const hasFtpEnabled = themesConfig?.ftp?.status === 'open';
  const { isFreePlan } = useGetIsFreePlan();
  const hasDraft = hasThemeConfigAvailable && !!themesConfig?.draft;
  const shouldHighlightPublishDraftCTA = hasDraft && isFreePlan;
  const shouldHighlightEditDesignCTA = !shouldHighlightPublishDraftCTA;
  const makeSwitchDraftProminent = !isFreePlan;
  const tags = useGetTags();
  const { code: promotedTheme, isBeta: isBetaTheme } = PROMOTED_THEME;

  // Replace once codes are added in monolith response
  // https://tiendanube.atlassian.net/browse/LIG-589
  const currentThemeCode = themesConfig?.currentTheme.name.toLowerCase();
  const draftThemeCode = themesConfig?.draft?.name?.toLowerCase();

  const themeShouldBePromoted =
    !!currentThemeCode &&
    shouldPromoteTheme(
      promotedTheme,
      isFreePlan,
      tags,
      currentThemeCode,
      draftThemeCode,
    );

  const draft =
    hasThemeConfigAvailable && !!themesConfig?.draft
      ? themesConfig?.draft
      : undefined;

  return (
    <IonPageStratus
      width="medium"
      headerContent={<HeaderContent title={`${t('title')}`} />}
    >
      {isLoading && <ThemesPageSkeleton />}
      {isError && <ErrorScreen onRetry={refetch} />}
      {showThemesPage && (
        <>
          <UpgradePlanAlert show={!hasThemeConfigAvailable} />
          <DraftPublishedAlert
            show={showDraftPublishedAlert}
            onRemove={hideDraftPublishedAlert}
          />
          <Layout
            left={
              <>
                {hasFtpEnabled ? (
                  <FtpCard
                    currentTheme={themesConfig.currentTheme}
                    ftp={themesConfig.ftp}
                  />
                ) : (
                  <ThemeCard
                    currentTheme={themesConfig.currentTheme}
                    hasThemeConfigAvailable={hasThemeConfigAvailable}
                    hasFtpConfigAvailable={hasFtpConfigAvailable}
                    highlightEditCTA={shouldHighlightEditDesignCTA}
                  />
                )}
                {hasThemeConfigAvailable && (
                  <DraftCard
                    draft={themesConfig.draft}
                    isFtpOpen={hasFtpEnabled}
                    onDraftPublished={setShowDraftPublishedAlertOn}
                    highlightPublishCTA={!shouldHighlightEditDesignCTA}
                    makeSwitchDraftProminent={makeSwitchDraftProminent}
                    publishActionLocation={
                      shouldHighlightPublishDraftCTA ? 'toolbar' : 'menu'
                    }
                  />
                )}
              </>
            }
            right={
              <>
                <IdentityCard
                  images={themesConfig.images}
                  ogImageStatus={themesConfig.ogImageStatus}
                />
                {themeShouldBePromoted && (
                  <PromotedTheme
                    themeCode={promotedTheme}
                    draft={draft}
                    isBetaTheme={isBetaTheme}
                  />
                )}
              </>
            }
            mainContent
          />
        </>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_THEMES[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default ThemesPage;
