import { Checkbox } from '@nimbus-ds/components';
import {
  PaymentMethodDiscountDto,
  UpdatePaymentProviderSettingsRequestDto,
} from '@tiendanube/common';
import {
  BaseCard,
  InterfaceNameValue,
  Stack,
  Text,
  Title,
} from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const EXTERNAL_CHECKOUT_KEY = 'redirect';

interface DiscountsCardProps {
  discounts: PaymentMethodDiscountDto[];
  values: UpdatePaymentProviderSettingsRequestDto;
  errors: Partial<Record<string, string>>;
  handleOnChange: (data: InterfaceNameBooleanValue) => void;
  handleOnChangeGroup: (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function DiscountsCard({
  discounts,
  values,
  errors,
  handleOnChange,
  handleOnChangeGroup,
}: Readonly<DiscountsCardProps>): JSX.Element {
  const t = useTranslationPayments();

  const handleChangeDiscount = (data: InterfaceNameValue) => {
    handleOnChangeGroup('discounts', data);
  };

  const handleAllowDiscountCombination = () => {
    handleOnChange({
      name: 'allowDiscountCombination',
      value: !values.allowDiscountCombination,
    });
  };

  const transparentCheckoutDiscounts = discounts.filter(
    ({ paymentMethodType }) => paymentMethodType !== EXTERNAL_CHECKOUT_KEY,
  );
  const externalCheckoutDiscount = discounts.find(
    ({ paymentMethodType }) => paymentMethodType === EXTERNAL_CHECKOUT_KEY,
  );

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t('paymentProvider.settings.discounts')}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          {transparentCheckoutDiscounts.length > 0 && (
            <>
              <Text size="highlight">
                {t('paymentProvider.settings.transparentCheckout')}
              </Text>
              {transparentCheckoutDiscounts?.map(({ paymentMethodType }) => (
                <InputNumber
                  key={paymentMethodType}
                  type="integer"
                  name={paymentMethodType}
                  label={t(`paymentProvider.method.${paymentMethodType}`)}
                  appendLabel="%"
                  max="100"
                  value={values.discounts?.[paymentMethodType] || ''}
                  onChange={handleChangeDiscount}
                  helpText={t(
                    errors?.[`discounts[${paymentMethodType}]`] || '',
                  )}
                  appearance={
                    errors?.[`discounts[${paymentMethodType}]`]
                      ? 'validation_error'
                      : 'default'
                  }
                />
              ))}
            </>
          )}
          {!!externalCheckoutDiscount && (
            <>
              <Text size="highlight">
                {t('paymentProvider.method.redirect')}
              </Text>
              <InputNumber
                type="integer"
                name={EXTERNAL_CHECKOUT_KEY}
                appendLabel="%"
                max="100"
                value={values.discounts?.[EXTERNAL_CHECKOUT_KEY] || ''}
                onChange={handleChangeDiscount}
                helpText={t(
                  errors?.[`discounts[${EXTERNAL_CHECKOUT_KEY}]`] || '',
                )}
                appearance={
                  errors?.[`discounts[${EXTERNAL_CHECKOUT_KEY}]`]
                    ? 'validation_error'
                    : 'default'
                }
              />
            </>
          )}
          <Checkbox
            name="allowDiscountCombination"
            checked={values.allowDiscountCombination}
            label={t('customPayment.allowDiscountCombination')}
            onChange={handleAllowDiscountCombination}
          />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default DiscountsCard;
