import { Input, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

function Skeleton() {
  const t = useTranslationMarketing('coupons.couponForm.limits.valueCart');

  return (
    <Stack column align="stretch" spacing="tight">
      <Text fontWeight="bold">{t('title')}</Text>
      <Stack column align="flex-start" spacing="tight">
        <Text fontSize="caption">{t('label')}</Text>
        <Input.Skeleton />
        <Text fontSize="caption">{t('helpText')}</Text>
      </Stack>
    </Stack>
  );
}

export default Skeleton;
