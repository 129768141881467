import { useTranslation } from 'react-i18next';
import { DataTable, Pagination, Stack, Text } from '@tiendanube/components';
import { usePaginationStratus } from 'commons/hooks';

interface PaginationStratusProps {
  itemName: string;
  totalItems: number;
  perPage: number;
  currentPage: number;
  onPageSelect: (page: number) => void;
}

const { Footer } = DataTable;

function DataTableFooter({
  itemName,
  totalItems,
  perPage,
  currentPage,
  onPageSelect,
}: PaginationStratusProps): JSX.Element {
  const { t } = useTranslation('common');
  const {
    firstItem: firstRecordPage,
    lastItem: lastRecordPage,
    totalPages,
  } = usePaginationStratus({
    perPage,
    totalItems,
    currentPage,
  });

  return (
    <Footer>
      <Stack justify="space-between">
        <Text>
          {t('pagination.total', {
            firstRecordPage,
            lastRecordPage,
            totalItems,
            itemName,
          })}
        </Text>
        {totalItems > perPage && (
          <Pagination
            pageSelected={currentPage}
            pageTotal={totalPages}
            onPageSelect={onPageSelect}
          />
        )}
      </Stack>
    </Footer>
  );
}

export default DataTableFooter;
