import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useRefreshInfoToSync } from 'domains/Auth/hooks';
import {
  getRemoveMercadoPagoNotificationStatus,
  getUpdateMercadoPagoNotificationStatus,
} from 'domains/Dashboard/slices/paymentsMessagesSlice';
import {
  cleanRemoveMercadoPagoCredentialsNotificationAction,
  removeMercadoPagoCredentialsNotificationAction,
} from 'domains/Dashboard/slices/paymentsMessagesSlice/paymentsMessagesSlice';

function useMercadoPagoCredentialsAlert() {
  const dispatch = useAppDispatch();
  const refreshInfoSync = useRefreshInfoToSync();
  const removeNotificationStatus = useSelector(
    getRemoveMercadoPagoNotificationStatus,
  );
  const updateNotificationStatus = useSelector(
    getUpdateMercadoPagoNotificationStatus,
  );

  const cleanStatusAndRefreshInfoToSync = () => {
    dispatch(cleanRemoveMercadoPagoCredentialsNotificationAction());
    refreshInfoSync(false);
  };

  const removeNotifications = async () => {
    await dispatch(removeMercadoPagoCredentialsNotificationAction());
    cleanStatusAndRefreshInfoToSync();
  };

  return {
    removeNotificationStatus,
    updateNotificationStatus,
    removeNotifications,
  };
}

export default useMercadoPagoCredentialsAlert;
