import { TFunction } from 'i18next';
import { useFormatCurrency } from 'domains/Billing/hooks';

interface UsePartialPaymentParams {
  totalDiffPending: number;
  country: string;
  t: TFunction;
  onOpenModal: () => void;
}

interface UsePartialPaymentResult {
  totalDiffAlertText: string;
  partialPaymentButtonLabel: string;
  paymentMethodOptions: PaymentOption[];
}

export interface PaymentOption {
  label: string;
  gateway: string;
  method: string;
  handler?: () => void;
}

function usePartialPayment({
  totalDiffPending,
  country,
  t,
  onOpenModal,
}: UsePartialPaymentParams): UsePartialPaymentResult {
  const formatCurrency = useFormatCurrency();
  const totalDiffAlertKey =
    totalDiffPending > 0
      ? 'cardPayment.totalDiffAlert.charge'
      : 'cardPayment.totalDiffAlert.return';

  const totalDiffAlertText = t(totalDiffAlertKey, {
    totalDiff: formatCurrency(Math.abs(totalDiffPending)),
  });

  const partialPaymentButtonLabel =
    totalDiffPending > 0
      ? t('cardPayment.partialPayment.charge')
      : t('cardPayment.partialPayment.return');

  const basePaymentMethodOptions = [
    {
      label: t('cardPayment.paymentMethod.bankTransfer'),
      gateway: 'custom',
      method: 'wire_transfer',
    },
    {
      label: t('cardPayment.paymentMethod.other'),
      gateway: 'custom',
      method: 'other',
      handler: onOpenModal,
    },
  ];

  const paymentMethodOptions =
    totalDiffPending > 0
      ? [
          {
            label: t('cardPayment.paymentMethod.creditCard'),
            gateway: 'custom',
            method: 'credit_card',
          },
          {
            label: t('cardPayment.paymentMethod.debitCard'),
            gateway: 'custom',
            method: 'debit_card',
          },
          {
            label: t('cardPayment.paymentMethod.cash'),
            gateway: 'custom',
            method: 'cash',
          },
          ...(country === 'BR'
            ? [
                {
                  label: t('cardPayment.paymentMethod.pix'),
                  gateway: 'custom',
                  method: 'pix',
                },
              ]
            : []),
          ...basePaymentMethodOptions,
        ]
      : basePaymentMethodOptions;

  return {
    totalDiffAlertText,
    partialPaymentButtonLabel,
    paymentMethodOptions,
  };
}

export default usePartialPayment;
