import { Chip, Link, Stack, Text } from '@tiendanube/components';
import { EditIcon, PlusCircleIcon } from '@tiendanube/icons';
import { AlertSessionStorage } from 'App/components';
import ModalAside from 'App/components/lab/ModalAside';
import { CancelAndSaveButtons } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { SelectCategories } from 'domains/Catalog/Products/pages/components/Categories/components';
import useAssignCategories from './useAssignCategories';
import { useBulkActions } from '../../BulkActionsProvider';
import { BulkActionTypeEnum } from '../ModalProductsEditPrices/types';

function AssignCategoriesModal(): JSX.Element | null {
  const t = useTranslationCatalog();
  const {
    showModal,
    selectedRowsId,
    selectedAction,
    closeModal,
    clearBulkActions,
    toggleEditMode,
  } = useBulkActions();
  const {
    categories,
    isLoading,
    selectedCategories,
    setSelectedCategories,
    assignCategories,
  } = useAssignCategories(selectedRowsId);
  const [
    isCategoriesSelectorOpen,
    openCategoriesSelector,
    closeCategoriesSelector,
  ] = useModal();

  const handleClickEdit = () => {
    openCategoriesSelector();
  };

  const handleClickRemove = (id: string) => {
    setSelectedCategories(
      selectedCategories.filter((categoryId) => categoryId !== id),
    );
  };

  const handleSave = async () => {
    await assignCategories();
    handleClose();
  };

  const handleClose = () => {
    setSelectedCategories([]);
    clearBulkActions();
    toggleEditMode();
    closeModal();
  };

  const isModalOpen =
    showModal && selectedAction === BulkActionTypeEnum.ASSIGN_CATEGORIES;

  if (!isModalOpen) return null;

  return (
    <>
      <ModalAside
        isOpen
        onDidDismiss={handleClose}
        headerContent={t('products.assignCategoriesModal.title')}
      >
        <AlertSessionStorage
          keyName="assignCategoriesModalAlert"
          title={t('products.assignCategoriesModal.alertTitle')}
        >
          {t('products.assignCategoriesModal.alertBody')}
        </AlertSessionStorage>
        <Stack column align="stretch">
          <Text bold>{t('products.assignCategoriesModal.categories')}</Text>
          {selectedCategories.length === 0 && (
            <Link
              appearance="primary"
              icon={PlusCircleIcon}
              onClick={handleClickEdit}
            >
              {t('products.assignCategoriesModal.select')}
            </Link>
          )}
          {selectedCategories.length > 0 && (
            <>
              <Stack spacing="tight" wrap>
                {categories
                  .filter((category) =>
                    selectedCategories.includes(category.id),
                  )
                  .map((category) => (
                    <Chip
                      key={category.id}
                      id={category.id}
                      label={category.title}
                      onDismiss={handleClickRemove}
                    />
                  ))}
              </Stack>
              <Link
                appearance="primary"
                icon={EditIcon}
                onClick={handleClickEdit}
              >
                {t('products.assignCategoriesModal.edit')}
              </Link>
            </>
          )}
          <CancelAndSaveButtons
            saveText={t('products.assignCategoriesModal.save')}
            isLoading={isLoading}
            isDisabled={selectedCategories.length === 0}
            onSave={handleSave}
            onCancel={handleClose}
          />
        </Stack>
      </ModalAside>

      <ModalAside
        isOpen={isCategoriesSelectorOpen}
        onDidDismiss={closeCategoriesSelector}
        headerContent={t('products.detail.categories')}
      >
        <SelectCategories
          selecteds={selectedCategories}
          onChange={setSelectedCategories}
        />
      </ModalAside>
    </>
  );
}

export default AssignCategoriesModal;
