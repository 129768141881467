import { CodeIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useHandleMenuClick } from 'App/hooks';
import { configurationsRoutes } from 'domains/Configurations';
import { menuSecondaryExternalCodesClick } from './tracking';

const path = configurationsRoutes.externalCodes;

export function ExternalCodesMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  const handleClick = () => {
    menuSecondaryExternalCodesClick();
    handleClickItem();
  };

  return (
    <MenuButton
      as={Link}
      to={path}
      startIcon={CodeIcon}
      onClick={handleClick}
      active={pathname.startsWith(path)}
      label={t('settingsMenu.advanced.externalCodes')}
    />
  );
}
