import { ChangeEvent } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { ErrorBox } from './ErrorBox';
import { PromotionTypes } from '../../../pages/constants';
import { PromotionFormProps } from '../PromotionForm';

export function BuyXPayY({
  values,
  errors,
  onChangeBuyXPayY,
}: Readonly<
  Pick<PromotionFormProps, 'values' | 'errors' | 'onChangeBuyXPayY'>
>) {
  const t = useTranslationMarketing('promotions.settingsPage.discountType');

  const defaultValues = { buy: 0, pay: 0 };
  const { promotionType, buyXPayY } = values;

  const {
    buyXPayY: productsError,
    'buyXPayY.buy': buyError,
    'buyXPayY.pay': payError,
  } = errors || {};

  if (promotionType !== PromotionTypes.BUY_X_PAY_Y) return null;

  const handleBuyXPayYChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const previous = buyXPayY ?? defaultValues;

    onChangeBuyXPayY({ ...previous, [name]: value });
  };

  return (
    <>
      <Box display="flex" gap="4" alignItems="flex-start">
        <FormField.Input
          label={t('buy')}
          name="buy"
          type="number"
          value={buyXPayY?.buy || ''}
          onChange={handleBuyXPayYChange}
          append={<Text color="neutral-textLow">{t('products')}</Text>}
          appendPosition="end"
          appearance={productsError || buyError ? 'danger' : undefined}
        />

        <Box minWidth="140px" width="100%">
          <FormField.Input
            label={t('pay')}
            name="pay"
            type="number"
            value={buyXPayY?.pay || ''}
            onChange={handleBuyXPayYChange}
            append={<Text color="neutral-textLow">{t('products')}</Text>}
            appendPosition="end"
            appearance={productsError || payError ? 'danger' : undefined}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        {!!productsError && <ErrorBox errorMessage={t(productsError)} />}
        {!productsError && !!buyError && (
          <ErrorBox errorMessage={t(buyError)} />
        )}
        {!productsError && !!payError && (
          <ErrorBox errorMessage={t(payError)} />
        )}
      </Box>
    </>
  );
}
