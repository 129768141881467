import { Stack, Title } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { DateAndDeviceSkeleton } from '../../DateAndDevice';

interface DetailsHeaderSkeletonProps {
  isOrder?: boolean;
}

function DetailsHeaderSkeleton({
  isOrder,
}: Readonly<DetailsHeaderSkeletonProps>) {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();

  return (
    <Stack wrap justify="space-between">
      {isDesktop && (
        <Stack.Item fill>
          <Title type="h2">{`${t(
            isOrder ? 'detail.subTitleMain' : 'abandonedCarts.detail.mainTitle',
          )}`}</Title>
        </Stack.Item>
      )}
      <Stack.Item>
        <DateAndDeviceSkeleton />
      </Stack.Item>
    </Stack>
  );
}

export default DetailsHeaderSkeleton;
