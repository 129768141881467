import { ChangeEvent, useEffect, useRef } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import isEmpty from 'lodash.isempty';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  AddressCardErrorsInterface,
  AddressCardValuesInterface,
} from '../../AddressCard';
import Skeleton from '../../Skeleton';
import InputForLocation from '../InputForLocation';
import SelectForLocation from '../SelectForLocation';

export interface AddressCardChileProps {
  values: AddressCardValuesInterface;
  errors: Partial<AddressCardErrorsInterface>;
  countries: { value: string; label: string }[];
  provinces: { value: string; label: string }[];
  cities: { value: string; label: string }[];
  loadingCountries: boolean;
  loadingProvinces: boolean;
  loadingCities: boolean;
  fetchProvinces: (country: string) => Promise<string>;
  fetchCities: (countryCode: string, provinceCode: string) => Promise<string>;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  setFieldValue: (name: string, value: string) => void;
  clearFields: () => void;
}

function AddressCardChile({
  values,
  errors,
  countries,
  provinces,
  cities,
  loadingCountries,
  loadingProvinces,
  loadingCities,
  fetchProvinces,
  fetchCities,
  onChange,
  setFieldValue,
  clearFields,
}: AddressCardChileProps): JSX.Element {
  const t = useTranslationShipping();

  const cityHelpText = t(errors.city || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');
  const localityHelpText = t(errors.locality || '');

  const handleChangeCountry = async (
    e: ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    clearFields();
    onChange(e);
    if (e.target.name === 'country') {
      const province = await fetchProvinces(e.target.value);
      setFieldValue('province', province);
    }
  };

  const handleChangeProvince = async (
    e: ChangeEvent<HTMLSelectElement>,
  ): Promise<void> => {
    clearFields();
    onChange(e);
    if (e.target.name === 'province') {
      const city = await fetchCities(values.country, e.target.value);
      setFieldValue('city', city);
    }
  };

  const alreadyFetched = useRef(false);

  useEffect(() => {
    if (!alreadyFetched.current && !isEmpty(values.country)) {
      setFieldValue('locality', '');
      fetchProvinces(values.country).then((provinceCode) => {
        if (!values.province) {
          setFieldValue('province', provinceCode);
        }
        fetchCities(
          values.country,
          !values.province ? provinceCode : values.province,
        ).then((result) => {
          if (!values.city) {
            setFieldValue('city', result);
          }
        });
      });

      alreadyFetched.current = true;
    }
  }, [
    fetchProvinces,
    fetchCities,
    setFieldValue,
    values,
    loadingProvinces,
    loadingCities,
  ]);

  return (
    <Card title={t('locations.form.address.title')}>
      <Card.Header>
        <Title as="h3">{t('locations.form.address.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <SelectForLocation
            label={t(`locations.form.address.fields.CL.country`)}
            name="country"
            id="country"
            value={values.country ?? ''}
            onChange={handleChangeCountry}
            options={countries}
            isLoading={loadingCountries}
          />

          <SelectForLocation
            label={t(`locations.form.address.fields.CL.province`)}
            name="province"
            id="province"
            value={values.province ?? ''}
            onChange={handleChangeProvince}
            options={provinces}
            isLoading={loadingProvinces || !values.country}
          />

          <SelectForLocation
            label={t(`locations.form.address.fields.CL.city`)}
            name="city"
            id="city"
            value={values.city ?? ''}
            helpText={cityHelpText}
            onChange={onChange}
            options={cities}
            isLoading={loadingCities || !values.province}
          />

          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.CL.street`)}
            onChange={onChange}
            name="street"
            value={values.street ?? ''}
            helpText={streetHelpText}
            showHelpText={!!streetHelpText && !values.street}
            appearance={streetHelpText && !values.street ? 'danger' : 'none'}
          />

          <Stack spacing="tight" align="flex-start">
            <InputForLocation
              type="text"
              label={t(`locations.form.address.fields.BR.number`)}
              onChange={onChange}
              name="number"
              value={values.number ?? ''}
              helpText={numberHelpText}
              showHelpText={!!numberHelpText && !values.number}
              appearance={numberHelpText && !values.number ? 'danger' : 'none'}
            />
            <InputForLocation
              type="text"
              label={t(`locations.form.address.fields.CL.floor`)}
              onChange={onChange}
              name="floor"
              value={values.floor}
            />
          </Stack>
          <InputForLocation
            type="text"
            label={t(`locations.form.address.fields.CL.locality`)}
            onChange={onChange}
            name="locality"
            value={values.locality ?? ''}
            helpText={localityHelpText}
            showHelpText={!!localityHelpText && !values.locality}
            appearance={
              localityHelpText && !values.locality ? 'danger' : 'none'
            }
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

AddressCardChile.Skeleton = Skeleton;

export default AddressCardChile;
