import * as Yup from 'yup';

const currentYear = Number(String(new Date().getFullYear()).slice(-2));
export const expirationDateRegex =
  /^(?:\d{2}\/\d{2}|\d{1}|\d{2}|\d{2}\/\d{1}|\d{2}\/|\d{0})$/;

const commonForm = {
  cardNumber: Yup.number()
    .typeError('form.cardNumber.errors.invalid')
    .required('form.cardNumber.errors.empty'),
  cardholderName: Yup.string()
    .required('form.cardholderName.errors.empty')
    .typeError('form.cardholderName.errors.invalid'),
  expirationDate: Yup.string()
    .required('form.expirationMonth.errors.empty')
    .typeError('form.expirationDate.errors.invalid')
    .test(
      'expirationDateInvalid',
      'form.expirationDate.errors.invalid',
      (value) => {
        if (!value || !expirationDateRegex.test(value)) return false;
        const month = Number(value.slice(0, 2));
        const year = Number(value.slice(3));
        if (month < 1 || month > 12 || year < currentYear) return false;
        return true;
      },
    ),
  securityCode: Yup.number()
    .typeError('form.securityCode.errors.invalid')
    .required('form.securityCode.errors.empty'),
  cardholderEmail: Yup.string()
    .required('form.cardholderEmail.errors.empty')
    .email('form.cardholderEmail.errors.invalid')
    .typeError('form.cardholderEmail.errors.invalid'),
};

const formWithIdentification = {
  ...commonForm,
  identificationNumber: Yup.string()
    .required('form.identificationNumber.errors.empty')
    .typeError('form.identificationNumber.errors.invalid'),
};

export const formCreditCardSchema = (country: string) =>
  Yup.object().shape(country === 'MX' ? commonForm : formWithIdentification);
