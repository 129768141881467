import { useTranslation } from 'react-i18next';
import { Pagination, Stack, Text } from '@tiendanube/components';
import { usePaginationStratus } from 'commons/hooks';

interface PaginationStratusProps {
  itemName: string;
  page: number;
  totalItems: number;
  perPage: number;
  currentPage: number;
  onPageSelect: (page: number) => void;
}

function PaginationStratus({
  itemName,
  totalItems,
  page,
  perPage,
  currentPage,
  onPageSelect,
}: PaginationStratusProps): JSX.Element {
  const { t } = useTranslation('common');
  const {
    firstItem: firstRecordPage,
    lastItem: lastRecordPage,
    totalPages,
  } = usePaginationStratus({
    perPage,
    totalItems,
    currentPage,
  });

  return (
    <div className="stratus--pagination">
      <Stack justify="space-between">
        <Stack.Item>
          <Text>
            {t('pagination.total', {
              firstRecordPage,
              lastRecordPage,
              totalItems,
              itemName,
            })}
          </Text>
        </Stack.Item>
        <Stack.Item>
          {totalItems > perPage && (
            <Pagination
              pageSelected={page}
              pageTotal={totalPages}
              onPageSelect={onPageSelect}
            />
          )}
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default PaginationStratus;
