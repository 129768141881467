import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import {
  fetchRoutesToWatch,
  getCurrentPageState,
  getRoutesToWatchData,
  getRoutesToWatchStatus,
} from 'App/settings/routingLog';
import { useAppDispatch } from 'App/store';

const updateRoutesToWatchTime = 600000;

function useDatadogRecordRUM() {
  const [watching, setWatching] = useState(false);
  const status = useSelector(getRoutesToWatchStatus);
  const { pathPattern } = useSelector(getCurrentPageState);
  const routesToWatch = useSelector(getRoutesToWatchData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const startRecording = routesToWatch[pathPattern] && !watching;
    const stopRecording = !routesToWatch[pathPattern] && watching;

    if (startRecording) {
      datadogRum.startSessionReplayRecording();
      setWatching(true);
    } else if (stopRecording) {
      datadogRum.stopSessionReplayRecording();
      setWatching(false);
    }
  }, [pathPattern, routesToWatch, watching]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchRoutesToWatch());
      setInterval(() => {
        dispatch(fetchRoutesToWatch());
      }, updateRoutesToWatchTime);
    }
  }, [dispatch, status]);
}

export default useDatadogRecordRUM;
