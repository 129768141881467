import * as Yup from 'yup';
import { LanguagesType } from 'App/i18n';

export const isValidVideoUrl =
  /^(https:\/\/(www.youtube.*|www.vimeo.*|youtu.*|vimeo.*))?$/;

export const isSecureUrl = /^(https:\/\/.*|)$/;

export const buildProductSchema = (
  languages: LanguagesType[],
  fullValidateVideoUrl = true,
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    name: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().trim().required('products.errorName'),
        }),
        {},
      ),
    }),
    videoUrl: (fullValidateVideoUrl
      ? Yup.string().matches(isValidVideoUrl, 'products.errorVideoUrl')
      : Yup.string().matches(isSecureUrl, 'products.errorVideoUrl')
    ).nullable(),
  });
