import { Card, ErrorState as ErrorStateNimbus } from '@tiendanube/components';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

interface ErrorStateProps {
  onRetry: () => void;
}

function ErrorState({ onRetry }: ErrorStateProps) {
  const t = useTranslationsMetafields();
  return (
    <Card title={t('title')}>
      <ErrorStateNimbus
        title={t('errorState.title')}
        action={{
          children: t('errorState.action'),
          onClick: onRetry,
        }}
      />
    </Card>
  );
}

export default ErrorState;
