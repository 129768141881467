import { Alert, Box, Modal } from '@nimbus-ds/components';
import { RecoveryCodesResponseDto } from '@tiendanube/common';
import { useTranslationAccount } from 'domains/Account/hooks';
import RecoveryCodesCard from '../RecoveryCodesCard';

interface RecoveryCodesModalProps {
  recoveryCodes: RecoveryCodesResponseDto[];
  onDismiss: () => void;
  open: boolean;
}
function RecoveryCodesModal({
  recoveryCodes,
  onDismiss,
  open,
}: Readonly<RecoveryCodesModalProps>) {
  const t = useTranslationAccount('authenticationFactorPage');

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Box display="flex" flexDirection="column" gap="4">
          <Alert
            appearance="success"
            show
            title={t('recoveryCodesPage.totpActivatedAlert.title')}
          >
            {t('recoveryCodesPage.totpActivatedAlert.description')}
          </Alert>
          <RecoveryCodesCard recoveryCodes={recoveryCodes} showDescription />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

export default RecoveryCodesModal;
