import { Box, Card, Skeleton, Text, Title } from '@nimbus-ds/components';
import { Repeater, Stack } from 'commons/components';

function EmailItemCardSkeleton() {
  return (
    <div className="stratus--email-item-card">
      <Card padding="small">
        <Card.Body>
          <Box
            display="flex"
            gap="2"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Skeleton width="32px" height="32px" />
            <Stack.Item fill>
              <Stack column align="stretch" spacing="tight">
                <Title.Skeleton as="h5" height="32px" width="150px" />
                <Text.Skeleton width="100%" height="36px" />
                <Text.Skeleton width="80px" />
              </Stack>
            </Stack.Item>
          </Box>
        </Card.Body>
      </Card>
    </div>
  );
}

function EmailsSectionSkeleton() {
  return (
    <Stack column align="stretch" spacing="tight">
      <Title.Skeleton as="h3" />
      <Box
        display="grid"
        gridTemplateColumns={{
          lg: '1fr 1fr 1fr 1fr',
          md: '1fr',
          xs: '1fr',
        }}
        gridGap="4"
      >
        <Repeater times={4} item={EmailItemCardSkeleton} />
      </Box>
    </Stack>
  );
}

function NotificationsListPageSkeleton() {
  return (
    <Stack column align="stretch" spacing="loose">
      <Stack column align="stretch" spacing="loose">
        <Repeater times={3} item={EmailsSectionSkeleton} />
      </Stack>
    </Stack>
  );
}

export default NotificationsListPageSkeleton;
