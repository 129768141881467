import { useMemo } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { Carousel, FullHeightCardContainer } from 'commons/components';
import BlockWithTitle from 'commons/components/BlockWithTitle';
import { getRandomElements } from 'commons/utils/getRandomElements';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useFreeShippingStatus } from 'domains/Marketing/FreeShipping/hooks';
import AditionalSettingCard, {
  AdditionalSettingType,
} from './AditionalSettingCard';
import useOnboardingTasks from '../OnboardingTasks/useOnboardingTasks';

const EXCLUDES_COUNTRIES = [Country.AR, Country.CL, Country.CO];

const ADDITIONAL_SETTINGS: AdditionalSettingType[] = [
  'custom-link',
  'free-shipping',
  'sort-products',
  'abandoned-cart',
  'instagram-shops',
  'google-shopping',
];

function AdditionalSettings() {
  const t = useTranslationDashboard();
  const { available } = useFreeShippingStatus();
  const country = useGetCountry();

  const { hasCompleted } = useOnboardingTasks();

  const randomElements = useMemo(
    () =>
      getRandomElements(
        ADDITIONAL_SETTINGS.filter((element) => {
          if (element === 'free-shipping') {
            // we filter the free shipping card if it is not available in the menu
            return available;
          }
          if (element === 'instagram-shops')
            // By decision of Meta, Instagram Shopping is not included in Argentina, Colombia and Chile
            return !EXCLUDES_COUNTRIES.includes(country);

          return true;
        }),
      ),
    [available, country],
  );

  if (!hasCompleted) return null;

  return (
    <BlockWithTitle title={t('additionalSetting.title')}>
      <FullHeightCardContainer>
        <Carousel>
          {randomElements.map((item) => (
            <AditionalSettingCard key={item} item={item} />
          ))}
        </Carousel>
      </FullHeightCardContainer>
    </BlockWithTitle>
  );
}

export default AdditionalSettings;
