import { Box, Table } from '@nimbus-ds/components';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { PopoverMenu } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import {
  AppsListItem,
  AppsListItemStatus,
} from 'domains/PartnersApps/pages/AppsListPage/components';
import { useAppsListActions } from 'domains/PartnersApps/pages/AppsListPage/hooks';

interface AppsListDesktopRowProps {
  app: AppsListItemResponseDto;
}

function AppsListDesktopRow({ app }: AppsListDesktopRowProps) {
  const { t } = useTranslationPartnerApps('appsListPage');
  const { getAppActions, status } = useAppsListActions(app);

  useToastStatus({
    status,
    error: t('toast.error'),
    progress: t('toast.loading'),
  });

  return (
    <Table.Row>
      <Table.Cell>
        <AppsListItem app={app} />
      </Table.Cell>
      <Table.Cell>
        <AppsListItemStatus app={app} />
      </Table.Cell>
      <Table.Cell>
        <Box width="fit-content">
          <PopoverMenu actions={getAppActions()} />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}

export default AppsListDesktopRow;
