import { useEffect, useState } from 'react';

export enum AppStatus {
  READY = 'ready',
  LOADING = 'loading',
  ERROR = 'error',
}

export interface StatusEmbeddedApp {
  status: AppStatus;
  handleReady: () => void;
  handleRetry: () => void;
  handleError: () => void;
}

const TTL_CONNECT = 8000;

function useStatusEmbeddedApp(): StatusEmbeddedApp {
  const [status, setStatus] = useState<AppStatus>(AppStatus.LOADING);

  const isReady = status === AppStatus.READY;
  const isLoading = status === AppStatus.LOADING;

  const handleReady = () => {
    setStatus(AppStatus.READY);
  };

  const handleError = () => {
    setStatus(AppStatus.ERROR);
  };

  const handleRetry = () => {
    setStatus(AppStatus.LOADING);
  };

  useEffect(() => {
    const ttlConnect = setTimeout(() => {
      if (isLoading) {
        handleError();
      }
    }, TTL_CONNECT);

    if (isReady) {
      clearTimeout(ttlConnect);
    }

    return () => {
      clearTimeout(ttlConnect);
    };
  }, [isLoading, isReady]);

  return {
    status,
    handleReady,
    handleRetry,
    handleError,
  };
}

export default useStatusEmbeddedApp;
