import { ChangeEvent, useEffect, useState } from 'react';
import { RocketIcon } from '@nimbus-ds/icons';
import { CalloutCard, FormField } from '@nimbus-ds/patterns';
import { FEATURE_GOOGLE_TAG_MANAGER } from 'App/features';
import { PREFERENCES_GOOGLE_TAG_MANAGER } from 'config/upsellFlowSources';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useGetExternalCodesQuery } from 'domains/Configurations/ExternalCodes/externalCodesApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { LinkedGoogleTagManager } from './LinkedGoogleTagManager';
import { SplitSection } from '../SplitSection';

interface GoogleTagManagerProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  googleAnalyticsGtmId: string;
}

export function GoogleTagManager({
  onChange,
  googleAnalyticsGtmId,
}: Readonly<GoogleTagManagerProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.google.googleTagManager',
  );
  const { data } = useGetExternalCodesQuery();
  const [hasClicked, setHasClicked] = useState(false);
  const { isAvailable } = useStatusFeatureByKey(FEATURE_GOOGLE_TAG_MANAGER);
  const handleClick = () => {
    setHasClicked(!hasClicked);
  };

  const handleUpsellCallout = useUpsellFlow({
    title: t('upsell.modalTitle'),
    featureKey: FEATURE_GOOGLE_TAG_MANAGER,
    trackingSource: PREFERENCES_GOOGLE_TAG_MANAGER,
  });

  const showLinkedCard =
    googleAnalyticsGtmId && data?.googleAnalyticsGtmId && !hasClicked;

  useEffect(() => {
    if (googleAnalyticsGtmId === data?.googleAnalyticsGtmId)
      setHasClicked(false);
    // We avoid seeing when the googleAnalyticsGtmId value changes to avoid blocking the input when this value is changed
    // We just need to know when data?.googleAnalyticsGtmId changes to show the linked card
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.googleAnalyticsGtmId]);

  return (
    <SplitSection title={t('title')} description={t('description')}>
      {!isAvailable && (
        <CalloutCard
          appearance="primary"
          title={t('upsell.title')}
          subtitle={t('upsell.description')}
          icon={RocketIcon}
          onClick={handleUpsellCallout}
        />
      )}
      {showLinkedCard && isAvailable ? (
        <LinkedGoogleTagManager
          googleAnalyticsGtmId={googleAnalyticsGtmId}
          onClick={handleClick}
        />
      ) : (
        <FormField.Input
          onChange={onChange}
          value={googleAnalyticsGtmId}
          name="googleAnalyticsGtmId"
          placeholder={t('placeholder')}
          label={t('label')}
          disabled={!isAvailable}
        />
      )}
    </SplitSection>
  );
}
