import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetMainUserId, useGetUserInfo } from 'domains/Auth/hooks';
import {
  useGetRolesQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { validationSchema } from './validationSchema';
import { EDIT_USER_INITIAL_VALUES } from '../../constants';
import UserForm from '../UserForm';

interface EditUserFormProps {
  userId: string;
}

function EditUserForm({ userId }: Readonly<EditUserFormProps>) {
  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const [updateUser, { status, error: requestError }] = useUpdateUserMutation();
  const mainUserId = useGetMainUserId();
  const { id: currentUserId } = useGetUserInfo();
  const isCurrentUser = userId === currentUserId;

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    refetch: refetchUser,
  } = useGetUserQuery(userId);

  const {
    data: storeRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch: refetchRoles,
  } = useGetRolesQuery();

  const {
    values,
    errors,
    isDirty,
    setFieldValue,
    handleChange,
    handleValidations,
    handleOnSubmit,
  } = useForm({
    initialValues: user || EDIT_USER_INITIAL_VALUES,
    validationSchema,
    onSubmit: (data) => updateUser({ id: userId, payload: data }),
  });

  const updateStatus = toStatusType(status);
  const requestErrors =
    (requestError as any)?.data?.response?.description || {};

  useToastStatus({
    status: updateStatus,
    success: t('toast.editSuccess'),
    error: requestErrors?.description?.email
      ? t(`errors.emailAlreadyTaken`)
      : t('toast.editError'),
    onSuccess: goBack,
  });

  const refetch = () => {
    refetchUser();
    refetchRoles();
  };

  return (
    <UserForm
      isMainUser={String(userId) === String(mainUserId)}
      isLoading={isUserLoading || isRolesLoading}
      isError={isUserError || isRolesError || !user}
      isDirty={isDirty}
      values={values}
      errors={errors}
      requestErrors={requestErrors}
      submitStatus={updateStatus}
      storeRoles={storeRoles}
      onChange={handleChange}
      onChangeRole={setFieldValue}
      onRefetch={refetch}
      onSubmit={handleOnSubmit}
      onValidate={handleValidations}
      isCurrentUser={isCurrentUser}
    />
  );
}

export default EditUserForm;
