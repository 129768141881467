import { Box, Card, Title } from '@nimbus-ds/components';
import { ConceptCode } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { useToastStatus } from 'commons/hooks';
import {
  useGetDownloadInvoiceMutation,
  useGetPaymentHistoryForConceptQuery,
} from 'domains/Billing/Checkout/CheckoutApi';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PaymentData } from './components';

interface PaymentHistoryProps {
  concept: ConceptCode;
  externalReference?: string;
}

export default function PaymentHistory({
  concept,
  externalReference,
}: Readonly<PaymentHistoryProps>) {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.paymentHistory',
  );
  const { data, isSuccess } = useGetPaymentHistoryForConceptQuery({
    concept,
    externalReference,
  });

  const [_getDownloadInvoice, { status }] = useGetDownloadInvoiceMutation({
    fixedCacheKey: PaymentHistory.name,
  });

  useToastStatus({
    status: toStatusType(status),
    error: t('invoiceError'),
    progress: t('invoiceLoading'),
  });

  if (!isSuccess || !data?.length) return null;

  return (
    <Card padding="none">
      <Box padding="3" paddingTop="5" paddingBottom="none">
        <Title as="h3">{t('title')}</Title>
      </Box>
      {data.map((payment) => (
        <PaymentData
          key={payment.id}
          concept={concept}
          externalReference={externalReference}
          payment={payment}
        />
      ))}
      <Box padding="0-5" />
    </Card>
  );
}
