import {
  Input,
  InterfaceInput,
  InterfaceNameValue,
} from '@tiendanube/components';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';

interface ClonedProductNameInputProps {
  inputAppearance: InterfaceInput['appearance'];
  value: Partial<Record<LanguagesType, string>>;
  helpText?: string;
  language?: LanguagesType;
  onChange: (language: LanguagesType) => (e: InterfaceNameValue) => void;
  onBlur: () => void;
}

function ClonedProductNameInput({
  inputAppearance,
  onChange,
  value,
  helpText,
  onBlur,
  language = DEFAULT_LANGUAGE,
}: ClonedProductNameInputProps) {
  return (
    <Input
      appearance={inputAppearance}
      value={value[language]}
      name="name"
      onChange={onChange(language)}
      placeholder=""
      helpText={helpText}
      onBlur={onBlur}
    />
  );
}

export default ClonedProductNameInput;
