import * as Yup from 'yup';
import { emailRegex } from 'commons/utils';

export const account = Yup.object().shape({
  user: Yup.string()
    .required('oca.config.account.errors.user')
    .matches(emailRegex, 'oca.config.account.errors.userInvalid'),
  password: Yup.string().required('oca.config.account.errors.password'),
  numberAccount: Yup.string()
    .required('oca.config.account.errors.numberAccount')
    .matches(
      /^$|\d{6}\/\d{3}/,
      'oca.config.account.errors.numberAccountInvalid',
    ),
});

export const operational = Yup.object()
  .shape({
    door: Yup.string(),
    store: Yup.string(),
    storeToDoor: Yup.string(),
    storeToStore: Yup.string(),
  })
  .test(function (values) {
    const { door, store, storeToDoor, storeToStore } = values;

    if (!door && !store && !storeToDoor && !storeToStore) {
      return this.createError({
        path: 'operational.door',
        message: 'oca.config.operational.errors.door',
      });
    }

    if (door && !store) {
      return this.createError({
        path: 'operational.store',
        message: 'oca.config.operational.errors.store',
      });
    }

    if (!door && store) {
      return this.createError({
        path: 'operational.door',
        message: 'oca.config.operational.errors.door',
      });
    }

    if (storeToDoor && !storeToStore) {
      return this.createError({
        path: 'operational.storeToStore',
        message: 'oca.config.operational.errors.storeToStore',
      });
    }

    if (!storeToDoor && storeToStore) {
      return this.createError({
        path: 'operational.storeToDoor',
        message: 'oca.config.operational.errors.storeToDoor',
      });
    }

    return true;
  });

export const ocaConfigarationSchemaStep2 = Yup.object().shape({
  account,
  operational,
});
