import { Card, Title } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { AiFeature, Domain } from '@tiendanube/common/src/enums';
import { Text, Link, Stack, Chip } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import GenerateWithAiLink from 'commons/components/GenerateWithAiLink';
import { onChangeTagsType } from '../../TagsAndSeo';

interface ShowTagsAndSeoCardProps {
  onOpen: () => void;
  tags: string[];
  onChange: onChangeTagsType;
}

function ShowTagsAndSeoCard({
  onOpen,
  tags,
  onChange,
}: ShowTagsAndSeoCardProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const handleOnRemoveTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    onChange({ name: 'tags', value: newTags });
  };
  return (
    <Card>
      <Card.Header>
        <Stack spacing="tight">
          <Title as="h4">{t('products.detail.tags.title')}</Title>
          <GenerateWithAiLink
            trackingFeature={AiFeature.AI_PRODUCTS_SEO}
            fontSize="caption"
            onClick={onOpen}
          />
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Stack.Item>
            <Text>{t('products.detail.tags.descriptionCard')}</Text>
          </Stack.Item>
          {tags.length > 0 && (
            <Stack spacing="tight" wrap>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  id={`${index}`}
                  label={tag}
                  onDismiss={() => handleOnRemoveTag(index)}
                />
              ))}
            </Stack>
          )}
          <Stack.Item>
            <Link
              icon={EditIcon}
              iconPosition="start"
              appearance="primary"
              onClick={onOpen}
            >
              {t('products.detail.tags.edit')}
            </Link>
          </Stack.Item>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default ShowTagsAndSeoCard;
