import { Text, Table } from '@nimbus-ds/components';
import { InternalNavLink, Stack } from 'commons/components';
import useCouponById from 'domains/Marketing/Coupons/hooks/useCoupon';
import { trackingAccessEditCouponPage } from 'domains/Marketing/Coupons/tracking';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { Actions, CouponDiscountType, Limited, Shipping, Status } from '../..';
import './CouponListRow.scss';

interface CouponsListRowProps {
  id: string;
}
export function CouponsListRow({ id }: Readonly<CouponsListRowProps>) {
  const t = useTranslationMarketing();
  const { coupon } = useCouponById(id);

  if (!coupon) return null;

  const { code, status, type, value, includeShipping, used, limited } = coupon;

  const usageLimit = limited?.maxUses
    ? t('coupons.details.usageLimit', {
        used: used,
        maxUses: limited.maxUses,
      })
    : t('coupons.details.uses', { count: used });

  const showPopover =
    limited &&
    (limited.minPrice ||
      limited.categoriesV2?.length > 0 ||
      limited.products?.length > 0 ||
      limited.maxUsesPerClient);

  const limitedDate =
    limited?.startDate && limited?.endDate
      ? `${limited.startDate} - ${limited.endDate}`
      : t('coupons.details.indeterminate');

  return (
    <Table.Row>
      <Table.Cell>
        <InternalNavLink
          to={`/coupons/${id}`}
          className="stratus--coupon-row__code"
          onClick={() => trackingAccessEditCouponPage(id)}
        >
          <Text color="primary-interactive" lineClamp={1}>
            {code}
          </Text>
        </InternalNavLink>
      </Table.Cell>
      <Table.Cell>
        <CouponDiscountType type={type} value={value} />
      </Table.Cell>
      <Table.Cell>
        <Shipping type={type} includeShipping={includeShipping} />
      </Table.Cell>
      <Table.Cell>
        <Text lineClamp={2}>{limitedDate}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text lineClamp={1}>{usageLimit}</Text>
      </Table.Cell>
      <Table.Cell>
        {showPopover ? (
          <Limited
            minPrice={limited.minPrice}
            maxUsesPerClient={limited.maxUsesPerClient}
            categories={limited.categoriesV2}
            products={limited.products}
          />
        ) : (
          <Text>{t('coupons.details.unlimited')}</Text>
        )}
      </Table.Cell>
      <Table.Cell>
        <Status status={status} />
      </Table.Cell>
      {status !== 'deleted' && (
        <Table.Cell>
          <Stack justify="flex-start" gap="1-5">
            <Actions id={id} status={status} />
          </Stack>
        </Table.Cell>
      )}
    </Table.Row>
  );
}
