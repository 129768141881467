import { combineReducers } from 'redux';
import { reducer as categoriesMetafieldsReducer } from 'domains/Catalog/Categories/categoriesMetafields/categoriesMetafieldsSlice';
import {
  reducer as categoriesReducer,
  CategoriesInterface,
} from './Categories/categoriesSlice';
import {
  reducer as inventoryReducer,
  InventoryInterface,
} from './Inventory/inventorySlice';
import { reducer as productMetafieldsReducer } from './Products/ProductMetafields/productMetafieldsSlice';
import {
  reducer as productsReducer,
  ProductsInterface,
} from './Products/productsSlice';

export interface CatalogStateInterface {
  products: ProductsInterface;
  categories: CategoriesInterface;
  inventory: InventoryInterface;
}

const catalogReducers = combineReducers({
  products: productsReducer,
  categories: categoriesReducer,
  inventory: inventoryReducer,
  productMetafields: productMetafieldsReducer,
  categoriesMetafields: categoriesMetafieldsReducer,
});

export default catalogReducers;
