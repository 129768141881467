import { Route } from 'react-router-dom';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { Users } from 'domains/Configurations/Users';
import { ACCOUNT_ROUTES } from './accountRoutes';
import {
  BusinessDataPage,
  SocialNetworksPage,
  ShopCancellationPage,
  CanceledStorePage,
} from './pages';
import AuthenticationFactorPage from './pages/AuthenticationFactorPage';
import SessionManagementPage from './pages/SessionManagementPage';

function Account(): JSX.Element {
  const { validateRoles } = useRoles();

  if (!validateRoles('full'))
    return (
      <>
        <Route exact path={ACCOUNT_ROUTES.authenticationFactor}>
          <AuthenticationFactorPage />
        </Route>
        <Route exact path={ACCOUNT_ROUTES.sessionManagement}>
          <SessionManagementPage />
        </Route>
      </>
    );

  return (
    <>
      <Route exact path={ACCOUNT_ROUTES.businessData}>
        <BusinessDataPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.socialNetworks}>
        <SocialNetworksPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.shopCancellation}>
        <ShopCancellationPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.authenticationFactor}>
        <AuthenticationFactorPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.sessionManagement}>
        <SessionManagementPage />
      </Route>
      <Route path={ACCOUNT_ROUTES.canceledStore}>
        <CanceledStorePage />
      </Route>
      <Route path={ACCOUNT_ROUTES.users}>
        <Users />
      </Route>
    </>
  );
}

export default Account;
