import { Box, Card, Skeleton, Title, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function RestrictPurchasesCardSkeleton() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.restrictPurchasesCard',
  );

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title fontSize="h2">{t('title')}</Title>
        <Text>{t('description')}</Text>
      </Card.Header>

      <Card.Body>
        <InteractiveList>
          <InteractiveList.RadioItemSkeleton title="" />
          <InteractiveList.RadioItemSkeleton title="" />
        </InteractiveList>
      </Card.Body>

      <Card.Footer padding="base">
        <Box display="flex" flexDirection="column" width="100%" gap="4">
          <Skeleton height="1rem" width="300px" />
          <Box display="flex" flexDirection="column" gap="4">
            <Skeleton height="1rem" width="300px" />
            <Skeleton height="2rem" width="200px" />
          </Box>
          <Skeleton height="1rem" width="300px" />
        </Box>
      </Card.Footer>
    </Card>
  );
}
