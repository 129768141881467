import {
  CustomersOrdersResponseDto,
  OrderResponseDto,
} from '@tiendanube/common';

function showOriginLabel(
  order: OrderResponseDto | CustomersOrdersResponseDto,
): boolean {
  const enabledToShow = ['meli'];
  return enabledToShow.includes(order.origin);
}

export default showOriginLabel;
