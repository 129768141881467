import { useEffect } from 'react';
import { Box, Button, Card, Tag, Title } from '@nimbus-ds/components';
import { Link } from 'react-router-dom';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import { useTopAdBar } from 'App/components/TopAdBar/useTopAdBar';
import { CardWithTitle, ErrorScreen, IonPageStratus } from 'commons/components';
import { PAYMENT_SOFTBLOCK } from 'config/upsellFlowSources';
import { useBillingPlans } from 'domains/Billing/BillingPlans/hooks';
import { GoToPayPlanLink } from 'domains/Billing/Checkout/components/PaymentStatusAlert/components';
import { trackingBillingSoftBlockPaymentClick } from 'domains/Billing/tracking';
import useFindPlanForFeature from 'domains/Billing/UpsellFlow/hooks/useFindPlanForFeature';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { dashboardRoutes } from 'domains/Dashboard';
import { Skeleton } from './Skeleton';
import useUpsellSoftBlock from './useUpsellSoftBlock';
import { PlanNameAndPrice } from '../../components/PlanNameAndPrice';
import { UpsellBullets } from '../../components/UpsellBullets';

function UpsellSoftBlockPage() {
  const t = useTranslationBilling('upsellFlow');
  const { showMenu, hideMenu } = useDesktopMenuContext();
  const { hideTopAdBar, showTopAdBar } = useTopAdBar();
  const { getCurrentPlan } = useFindPlanForFeature();
  const { isLoading, isError, isSuccess, fetchBillingPlans } =
    useBillingPlans();
  const currentPlan = getCurrentPlan();
  const { dismiss } = useUpsellSoftBlock();

  useEffect(() => {
    hideMenu();
    hideTopAdBar();
    return () => {
      showMenu();
      showTopAdBar();
    };
  }, [hideMenu, hideTopAdBar, showMenu, showTopAdBar]);

  const rememberDismiss = () => {
    trackingBillingSoftBlockPaymentClick({
      featureKey: PAYMENT_SOFTBLOCK,
    });
    dismiss();
  };

  return (
    <IonPageStratus>
      {isError && <ErrorScreen onRetry={fetchBillingPlans} />}
      {!isError && (
        <Box display="flex" justifyContent="center">
          <Box display="flex" flexDirection="column" gap="8" width="500px">
            <Title>{t('softBlock.title')}</Title>
            <CardWithTitle title={t('softBlock.message')}>
              {isLoading && <Skeleton />}
              {isSuccess && currentPlan && (
                <>
                  <Card>
                    <Box display="flex" flexDirection="column" gap="2">
                      <Tag appearance="success">{t('modal.currentPlan')}</Tag>
                      <PlanNameAndPrice plan={currentPlan} align="left" />
                    </Box>
                    <Box>
                      <UpsellBullets
                        bullets={currentPlan.upsellBullets}
                        progress={currentPlan.upsellBullets.length}
                      />
                    </Box>
                  </Card>
                  <Box marginTop="4" display="flex" gap="2">
                    <Button
                      as={Link}
                      to={dashboardRoutes.dashboard}
                      onClick={dismiss}
                    >
                      {t('softBlock.cancel')}
                    </Button>
                    <GoToPayPlanLink
                      onClick={rememberDismiss}
                      appearance="primary"
                      text={t('softBlock.cta')}
                      as="button"
                      hideAdBars
                    />
                  </Box>
                </>
              )}
            </CardWithTitle>
          </Box>
        </Box>
      )}
    </IonPageStratus>
  );
}

export default UpsellSoftBlockPage;
