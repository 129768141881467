import { Box, Text, IconButton, BoxProps } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { SeoRedirectsResponseDto } from '@tiendanube/common';
import Skeleton from './Skeleton';

export const CELL_BOX_PROPS: BoxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 32px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0-5',
  gap: '2',
};

interface SeoRedirectsItemProps {
  redirect: SeoRedirectsResponseDto;
  onRemove: (redirect: SeoRedirectsResponseDto) => void;
}

function SeoRedirectsItem({ redirect, onRemove }: SeoRedirectsItemProps) {
  const { urlNew, urlOld } = redirect;
  const handleRemove = () => {
    onRemove(redirect);
  };
  return (
    <Box {...CELL_BOX_PROPS}>
      <Text wordBreak="break-all">{urlOld}</Text>
      <Text wordBreak="break-all">{urlNew}</Text>
      <IconButton
        size="32px"
        source={<TrashIcon size="small" />}
        onClick={handleRemove}
      />
    </Box>
  );
}

SeoRedirectsItem.Skeleton = Skeleton;

export default SeoRedirectsItem;
