import { useModal } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ProductFormState } from 'domains/Catalog/Inventory/pages/hooks/useProductInventory/types';
import { RelatedProductCard, RelatedProductModalAside } from './components';
import EditRelatedProductModalAside from './components/EditRelatedProductModalAside';
import useRelatedProduct from './useRelatedProduct';
import {
  RelatedProductInterface,
  RelatedProductType,
} from '../../hooks/useProductForm';

interface RelatedProductProps {
  relatedProducts?: RelatedProductInterface;
  mainProduct: ProductFormState;
  onChange: (relatedProducts: RelatedProductInterface) => void;
}

function RelatedProduct({
  onChange,
  mainProduct,
  relatedProducts,
}: Readonly<RelatedProductProps>) {
  const [isShowModal, openModal, closeModal] = useModal();
  const defaultLanguages = useGetLanguage();

  const [isShowEditModal, openEditModal, closeEditModal] = useModal();

  const parseMainProduct = ({
    name,
    images,
    id,
    published,
  }: ProductFormState): RelatedProductType => {
    const defaultName = name[defaultLanguages];
    const productName = defaultName || name?.es || name?.pt || name?.en;
    return {
      id: id as string,
      name: productName as string,
      imageUrl: images[0]?.src,
      visible: published,
    };
  };

  const { selectedRelatedProduct, setType, type, handleOnChange } =
    useRelatedProduct({ relatedProducts, onChange });

  if (relatedProducts?.status === 'error')
    return <RelatedProductCard.ErrorState />;

  return (
    <>
      <RelatedProductCard
        onEdit={openEditModal}
        relatedProducts={selectedRelatedProduct}
        onOpen={openModal}
        setEditingType={setType}
      />
      {isShowModal && (
        <RelatedProductModalAside
          type={type}
          selectedRelatedProducts={selectedRelatedProduct}
          mainProduct={parseMainProduct(mainProduct)}
          onChange={handleOnChange}
          onClose={closeModal}
        />
      )}
      {isShowEditModal && (
        <EditRelatedProductModalAside
          type={type}
          selectedRelatedProducts={selectedRelatedProduct}
          mainProduct={parseMainProduct(mainProduct)}
          onChange={handleOnChange}
          onClose={closeEditModal}
          onOpen={openModal}
        />
      )}
    </>
  );
}

export default RelatedProduct;
