import { useEffect, useRef, useCallback } from 'react';

type CallbackType = () => void;
type WrappedFunc = () => void;

function useTimeout(
  callback: CallbackType,
  delay?: number,
  resettable?: boolean,
): WrappedFunc {
  const savedCallback = useRef<CallbackType>(callback);
  const timer = useRef<number>(0);
  const defaultDelay = 4000;

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const wrappedFucn = useCallback(() => {
    if (resettable) {
      window.clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(
      savedCallback.current,
      delay || defaultDelay,
    );
  }, [delay, resettable]);

  useEffect(
    () => () => window.clearTimeout(timer.current),
    [delay, wrappedFucn],
  );

  return wrappedFucn;
}

export default useTimeout;
