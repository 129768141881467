import { useEffect } from 'react';
import { Alert, Text, Button, Link, Box } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Country } from '@tiendanube/common/src/enums';
import { ShowByTag } from 'App/components';
import ShowByCountry from 'App/components/ShowByCountry';
import { MERCADOPAGO_NOT_VERIFIED } from 'App/featuresFlags';
import { useBoolean } from 'commons/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useVerifyMercadoPagoAlert from './useVerifyMercadoPagoAlert';

function VerifyMercadoPagoAlert() {
  const t = useTranslationDashboard('paymentMessages.mercadoPago.notVerified');
  const [show, , close] = useBoolean(true);
  const { status, verifyMercadoPago, data } = useVerifyMercadoPagoAlert();

  useEffect(() => {
    if (status === 'success' && data?.verified) {
      close();
    }
  }, [close, data?.verified, status]);

  return (
    <ShowByCountry allowedCountries={[Country.BR]}>
      <ShowByTag tag={MERCADOPAGO_NOT_VERIFIED}>
        <Alert
          show={show}
          appearance="warning"
          title={t('title')}
          onRemove={close}
        >
          <Text>{t('description')}</Text>
          <Box display="flex" flexDirection="row" gap="4">
            <Button
              as="a"
              href={t('link')}
              target="_blank"
              appearance="primary"
              disabled={status === 'loading'}
            >
              {t('buttonText')}
              <ExternalLinkIcon />
            </Button>
            <Link as="button" onClick={verifyMercadoPago}>
              {t('linkText')}
            </Link>
          </Box>
        </Alert>
      </ShowByTag>
    </ShowByCountry>
  );
}

export default VerifyMercadoPagoAlert;
