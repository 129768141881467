import { ChangeEvent } from 'react';
import { Box, FileUploader, IconButton, Text } from '@nimbus-ds/components';
import { SizeHeightIcon, SizeWidthIcon, TrashIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { useToast } from 'commons/hooks';
import getBase64 from 'commons/utils/getBase64';
import validateImage, {
  BAD_IMAGE_SIZE,
  BAD_IMAGE_TYPE,
} from 'commons/utils/validateImage';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import './IdentityImage.scss';

interface IdentityImageProps {
  type: string;
  imageUrl?: string;
  recommendedWidth: string;
  recommendedHeight: string;
  onChange: (name: string, value: any) => void;
}

export function IdentityImage({
  type,
  imageUrl,
  recommendedWidth,
  recommendedHeight,
  onChange,
}: Readonly<IdentityImageProps>) {
  const t = useTranslationOnline('themesPage.identity.identityModal');
  const { addToast } = useToast();

  const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageError = await validateImage(file);
      if (imageError === BAD_IMAGE_TYPE)
        return addToast({
          appearance: 'danger',
          label: t('toast.badImageTypeError'),
        });

      if (imageError === BAD_IMAGE_SIZE)
        return addToast({
          appearance: 'danger',
          label: t('toast.badImageSizeError'),
        });

      onChange(event.target.name, (await getBase64(file)) || '');
    }
  };

  const handleClickRemove = (type: string) => onChange(type, undefined);

  return (
    <Stack column spacing="tight" align="stretch">
      {imageUrl ? (
        <Box
          display="flex"
          position="relative"
          height="80px"
          justifyContent="center"
          alignItems="center"
          padding="2"
          backgroundColor="neutral-surface"
          borderRadius="2"
          borderColor="neutral-surfaceDisabled"
          borderStyle="solid"
          borderWidth="1"
          data-style="stratus--identity-image-box"
        >
          <img src={imageUrl} alt="Logo" style={{ maxHeight: '100%' }} />
          <IconButton
            source={<TrashIcon />}
            size="2rem"
            onClick={() => handleClickRemove(type)}
          />
        </Box>
      ) : (
        <FileUploader
          id={type}
          name={type}
          placeholder={t('selectImage')}
          height="72px"
          onChange={handleChangeFile}
        />
      )}

      <Text fontSize="caption" color="neutral-textLow">
        {t('size')}{' '}
        <Text as="span" color="neutral-textHigh" fontSize="caption">
          <SizeWidthIcon style={{ verticalAlign: 'text-bottom' }} />{' '}
          {recommendedWidth}{' '}
          <SizeHeightIcon style={{ verticalAlign: 'text-bottom' }} />{' '}
          {recommendedHeight}
        </Text>
      </Text>
    </Stack>
  );
}
