import { combineReducers } from 'redux';
import { reducer as couponHistoryReducer } from './Coupons/couponHistorySlice/couponHistorySlice';
import { reducer as couponsReducer } from './Coupons/couponsSlice';
import { reducer as marketingAppsReducer } from './MarketingApps/marketingAppsSlice';

const marketingReducers = combineReducers({
  coupons: couponsReducer,
  marketingApps: marketingAppsReducer,
  couponHistory: couponHistoryReducer,
});

export default marketingReducers;
