import { Button, Icon, Text } from '@nimbus-ds/components';
import { ChevronLeftIcon, CheckCircleIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { AboutAppCard } from '..';
import { AddActiveShippingMethodsDetailInterface } from '../../AddActiveShippingMethodsDetailModal';

interface AddCorreiosModalProps {
  app: AddActiveShippingMethodsDetailInterface;
  onClose: () => void;
}

function AddCorreiosModal({ app, onClose }: AddCorreiosModalProps) {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();

  const handleOnConfirm = () => {
    goTo(DELIVERY_METHODS_ROUTES.correiosContract);
  };

  return (
    <SideModal
      open
      onRemove={onClose}
      maxWidth={{ xs: '100%', md: '45%', lg: '540px' }}
      padding="base"
      paddingHeader="none"
      title={`${t('deliveryMethods.activeShippingMethods.modal.detail.title', {
        app: app.name,
      })}`}
      headerAction={
        <Button appearance="transparent" onClick={onClose}>
          <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          <Text fontWeight="bold" fontSize="base">
            {t('deliveryMethods.title')}
          </Text>
        </Button>
      }
      headerIcon={
        <Icon
          color="primary-textHigh"
          onClick={handleOnConfirm}
          source={<CheckCircleIcon />}
        />
      }
    >
      <Stack column spacing="base" align="flex-end">
        <AboutAppCard
          name={app.name}
          description={app.description}
          tags={app.tags}
          links={app.links}
          id={app.appId}
        />
        <CancelAndConfirmButtons
          confirmText={t(
            'deliveryMethods.activeShippingMethods.modal.detail.correios.continue',
          )}
          onConfirm={handleOnConfirm}
          onCancel={onClose}
        />
      </Stack>
    </SideModal>
  );
}

export default AddCorreiosModal;
