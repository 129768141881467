import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getAcvailableAppsStatus,
  getAvailableAppsAction,
  getAvailableAppsData,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useAvailableApps() {
  const dispatch = useAppDispatch();
  const availableApps = useSelector(getAvailableAppsData);
  const { isError, isLoading } = useSelector(getAcvailableAppsStatus);

  const getAvailableApps = useCallback(() => {
    dispatch(getAvailableAppsAction());
  }, [dispatch]);

  return {
    getAvailableApps,
    availableApps,
    isLoading,
    isError,
  };
}

export default useAvailableApps;
