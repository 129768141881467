const VALID_CALLBACK_DOMAINS = [
  'nubestaging.com',
  'nubestaging.com.br',
  'tiendanube.com',
  'nuvemshop.com.br',
];

export default function isValidCallbackDomain(domain: string) {
  return VALID_CALLBACK_DOMAINS.some(
    (validDomain) =>
      domain === validDomain || domain.endsWith(`.${validDomain}`),
  );
}
