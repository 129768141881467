import { Route, Switch } from 'react-router-dom';
import { CATALOG_ROUTES } from './catalogRoutes';
import EditInventoryPage from './Inventory/pages/EditInventoryPage';
import InventoryListPage from './Inventory/pages/InventoryListPage';
import EditStockProvider from './Inventory/pages/InventoryListPage/components/EditStockProvider';
import StockHistoryProvider from './Products/pages/components/StockHistoryModal/StockHistoryProvider';

function Inventory(): JSX.Element {
  return (
    <>
      <Route path={CATALOG_ROUTES.inventoryList} exact>
        <StockHistoryProvider>
          <EditStockProvider>
            <InventoryListPage />
          </EditStockProvider>
        </StockHistoryProvider>
      </Route>
      <Switch>
        <Route path={CATALOG_ROUTES.editInventory}>
          <StockHistoryProvider>
            <EditInventoryPage />
          </StockHistoryProvider>
        </Route>
      </Switch>
    </>
  );
}

export default Inventory;
