import { Label, Text, IconButton, Table } from '@nimbus-ds/components';
import { Repeater, Stack } from 'commons/components';

export function RowSkeleton() {
  return (
    <Table.Row>
      <Table.Cell>
        <Text.Skeleton width="200px" />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Label.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Stack justify="flex-start" gap="1-5">
          <IconButton.Skeleton height="2rem" width="2rem" />
          <IconButton.Skeleton height="2rem" width="2rem" />
        </Stack>
      </Table.Cell>
    </Table.Row>
  );
}

const itemSkeleton = () => <RowSkeleton />;

export function CouponsListSkeleton() {
  return <Repeater times={10} item={itemSkeleton} />;
}
