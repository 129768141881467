import { ReactNode, createContext, useCallback, useRef } from 'react';
import classNames from 'classnames';
import './ScrollProvider.scss';

const scrollToTopDefault = () =>
  document.scrollingElement?.scrollTo({ top: 0, left: 0 });

export interface ScrollContextResult {
  scrollToTop: (behavior?: 'auto' | 'smooth') => void;
  scrollToError: () => void;
}

export interface ScrollContextProvider {
  children: ReactNode;
  isFromModal?: boolean;
  heightOffset?: string;
}

export const ScrollContext = createContext<ScrollContextResult>({
  scrollToTop: scrollToTopDefault,
  scrollToError: () => null,
});

function ScrollProvider({
  children,
  isFromModal = false,
  heightOffset = '0px',
}: Readonly<ScrollContextProvider>) {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(
    (behavior = 'smooth' as ScrollBehavior) => {
      if (!scrollRef?.current) scrollToTopDefault();

      scrollRef.current?.scrollIntoView({
        behavior,
      });
    },
    [scrollRef],
  );

  const scrollToError = useCallback(() => {
    //OBS: timeout is added to ensure that in the case of multilingual the corresponding window has been selected.
    setTimeout(() => {
      const inputErrors = document.querySelectorAll(
        ".nimbus--input-validation--error, [class^='nimbus-textarea_appearance_danger']",
      );

      const firstInputError = inputErrors[0] as HTMLDivElement;

      const topOffset = isNaN(firstInputError?.offsetTop)
        ? 0
        : firstInputError?.offsetTop - 80;

      document.scrollingElement?.scrollTo({
        top: topOffset,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);
  }, []);

  const onScroll = () => {
    const e = new Event('scroll', { bubbles: true, cancelable: true });
    window.dispatchEvent(e);
  };

  const styleContainer = classNames('stratus--scroll-context-container', {
    'stratus--scroll-context-container-modal': isFromModal,
  });

  const dynamicContainerStyle = isFromModal
    ? { height: `calc(100% - 58px - ${heightOffset})` }
    : {};

  return (
    <ScrollContext.Provider value={{ scrollToTop, scrollToError }}>
      <div
        ref={scrollRef}
        className={styleContainer}
        onScroll={onScroll}
        style={dynamicContainerStyle}
      >
        {children}
      </div>
    </ScrollContext.Provider>
  );
}

export default ScrollProvider;
