import { ImportFileRequestDto, JobStatusType } from '@tiendanube/common';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import { useAsyncFunc, useToast, WrappedFuncType } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import productsServices from 'domains/Catalog/Products/productsServices';

interface UseImportFileResult {
  sendFile: WrappedFuncType<ImportFileRequestDto, boolean>;
  isLoading: boolean;
  isError: boolean;
  cleanJob: () => Promise<void>;
  jobStatus: JobStatusType;
}

function useImportFile(): UseImportFileResult {
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const { setJob, cleanJob, jobStatus } =
    useBackgroundJobs('productsCsvImport');

  const handleError = () => {
    addToast({
      label: t('products.importExportPage.import.error'),
      appearance: 'danger',
    });
    clearError();
  };

  const handleSuccess = () => null;

  const [sendFile, isLoading, isError, clearError] = useAsyncFunc(
    async (params?: ImportFileRequestDto) => {
      if (params) {
        const { token } = await productsServices.importFile(params);
        setJob(token);
        return true;
      }
      return false;
    },
    handleSuccess,
    handleError,
  );

  return { sendFile, isLoading, isError, cleanJob, jobStatus };
}

export default useImportFile;
