import { createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';

export const getStatistics = (state: RootStateType) => state.statistics;

export const getStatisticsDaily = createSelector(
  getStatistics,
  (statistics) => statistics.daily,
);

export const getStatisticsDailyStatus = createSelector(
  getStatistics,
  (data) => data.status,
);

export const getStatisticsDailyLoadingError = createSelector(
  getStatisticsDailyStatus,
  (status) => ({
    error: status === 'error',
    loading: status === 'loading',
  }),
);

export const getGlobalInfo = (state: RootStateType) =>
  getStatistics(state).globalInfoEntity;

export const getGlobalInfoData = (state: RootStateType) =>
  getGlobalInfo(state).data.results;

export const getGlobalInfoStatus = (state: RootStateType) =>
  getGlobalInfo(state).status;

export const getGlobalInfoTotals = (state: RootStateType) =>
  getGlobalInfo(state).data.totals;

export const getGlobalInfoGranularity = (state: RootStateType) =>
  getGlobalInfo(state).data.granularity;

export const getGlobalInfoDataLastUpdate = (state: RootStateType) =>
  getGlobalInfo(state).data.lastUpdate;

export const getConversionStoreEntity = (state: RootStateType) =>
  getStatistics(state).conversionStoreEntity;

export const getConversionStoreEntityData = (state: RootStateType) =>
  getConversionStoreEntity(state).data;

export const getConversionStoreEntityStatus = (state: RootStateType) =>
  getConversionStoreEntity(state).status;

export const getStoreHasGAEntity = (state: RootStateType) =>
  getStatistics(state).storeHasGA;

export const getStoreHasGAEntityData = (state: RootStateType) =>
  getStoreHasGAEntity(state).data;

export const getStoreHasGAEntityStatus = (state: RootStateType) =>
  convertStatusTypeToObject(getStoreHasGAEntity(state).status);
