import { useEffect, useState } from 'react';
import {
  ProductDetailsResponseDto,
  VariantResponseDto,
} from '@tiendanube/common';
import { useToast } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useProductVariants } from 'domains/Catalog/Products/pages/hooks';
import { INITIAL_LOAD_VARIANTS } from 'domains/Catalog/Products/productsSlice/constants';

export interface UseVariantsRangeResult {
  variants: VariantResponseDto[];
  link: {
    show: boolean;
    text: string;
    handleClick: () => void;
  };
  spinner: {
    show: boolean;
    text: string;
  };
}

function useVariantsRange(
  product: ProductDetailsResponseDto,
  step: number,
): UseVariantsRangeResult {
  const t = useTranslationCatalog();

  const { addToast } = useToast();

  const { id: productId, variants: variantsLoaded, summary } = product;

  const { getProductVariants, cleanStatusVariants, statusVariants } =
    useProductVariants(productId);

  const [end, setEnd] = useState(step);
  const variantsSlice = variantsLoaded.slice(0, end);

  const shouldLoadMoreVariants =
    summary &&
    end >= INITIAL_LOAD_VARIANTS &&
    variantsLoaded.length < summary.variants;

  const canShowMoreVariants =
    variantsLoaded.length > end || shouldLoadMoreVariants;
  const canShowLessVariants = !canShowMoreVariants && end > step;

  const advanceVariants = () => setEnd(end + step);
  const showLessVariants = () => setEnd(step);
  const showMoreVariants = shouldLoadMoreVariants
    ? getProductVariants
    : advanceVariants;

  const [text, handleClick] = canShowMoreVariants
    ? [t('products.variants.moreVariants'), showMoreVariants]
    : [t('products.variants.lessVariants'), showLessVariants];

  useEffect(() => {
    if (statusVariants.isError) {
      addToast({
        appearance: 'danger',
        label: t('products.variants.errorLoad'),
      });
      cleanStatusVariants();
    }
    if (statusVariants.isSuccess) {
      advanceVariants();
      cleanStatusVariants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusVariants]);

  return {
    variants: variantsSlice,
    link: {
      show:
        (canShowMoreVariants || canShowLessVariants) &&
        !statusVariants.isLoading,
      text,
      handleClick,
    },
    spinner: {
      show: statusVariants.isLoading,
      text: t('products.variants.loading'),
    },
  };
}

export default useVariantsRange;
