import { Button, ButtonProps, Icon } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import './BackNavigation.scss';

export type BackNavigationProps = Pick<ButtonProps, 'children' | 'onClick'>;

function BackNavigation({
  children,
  onClick,
}: BackNavigationProps): JSX.Element {
  return (
    <div className="page-navbar__back">
      <Button
        onClick={onClick}
        appearance="transparent"
        data-testid="btn-navbar-back"
      >
        <Icon color="currentColor" source={<ChevronLeftIcon />} />
        {children}
      </Button>
    </div>
  );
}

export default BackNavigation;
