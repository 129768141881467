import {
  OrderForDocumentResponseDto,
  StoreForDocumentResponseDto,
} from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface DocumentHeaderPreviewProps {
  order: OrderForDocumentResponseDto;
  store: StoreForDocumentResponseDto;
  fulfillmentOrderNumber: string;
  isOca: boolean;
}

function DocumentHeaderPreview({
  order,
  store,
  fulfillmentOrderNumber,
  isOca,
}: Readonly<DocumentHeaderPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="header">
      <div>
        <div className="order-number">
          <h3>
            {t('order')} #{order.number}
          </h3>
          {!isOca && (
            <>
              <p>-</p>
              <p>
                {t('package')} #{fulfillmentOrderNumber}
              </p>
            </>
          )}
        </div>
        <p>
          {t('completedAt')} {order.completedAt}
        </p>
      </div>
      {!!store.logo && (
        <img src={store.logo} alt={store.name} className="logo" />
      )}
      {!store.logo && <h3>{store.name}</h3>}
    </div>
  );
}

export default DocumentHeaderPreview;
