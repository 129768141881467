import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from 'App/store';
import {
  createGetStatsProductsFiltersByItem,
  createGetStatsProductsPaginationByItem,
  createGetStatsProductsResultByItem,
  createGetStatsProductsStatusByItem,
} from 'domains/Statistics/selectors';
import { StatsProductsItemType } from 'domains/Statistics/types';

function useGetStatsProductsDataData(item: StatsProductsItemType) {
  const getProducts = useMemo(createGetStatsProductsResultByItem, []);
  const getStatus = useMemo(createGetStatsProductsStatusByItem, []);
  const getPagination = useMemo(createGetStatsProductsPaginationByItem, []);
  const getFilters = useMemo(createGetStatsProductsFiltersByItem, []);

  const products = useSelector((state: RootStateType) =>
    getProducts(state, item),
  );
  const status = useSelector((state: RootStateType) => getStatus(state, item));
  const pagination = useSelector((state: RootStateType) =>
    getPagination(state, item),
  );
  const filters = useSelector((state: RootStateType) =>
    getFilters(state, item),
  );

  const isEmpty = status === 'success' && products.length === 0;

  return {
    products,
    status,
    pagination,
    filters,
    isEmpty,
  };
}

export default useGetStatsProductsDataData;
