import { HelpLinkDto } from '@tiendanube/common';
import {
  BaseCard,
  Title,
  Stack,
  Text,
  CalloutCard,
} from '@tiendanube/components';
import {
  RocketIcon,
  DiscountCircleIcon,
  Icon,
  UserGroupIcon,
} from '@tiendanube/icons';
import { openWindow } from 'commons/utils/window';
import { trackingBillingDiscountForCancelAccount } from 'domains/Billing/tracking';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import CancelAndContinue from '../../CancelAndContinue';

interface SuggestionsCardProps {
  title: string;
  subTitle: string;
  links: HelpLinkDto[];
  onCancel: () => void;
  onContinue: () => void;
}

const handleClickAnnualDiscount = (linkUrl: string, storeId: string) => {
  trackingBillingDiscountForCancelAccount(storeId);
  openWindow(linkUrl, true);
};

const ICONS: Record<string, Icon> = {
  rocket: RocketIcon,
  'discount-circle': DiscountCircleIcon,
  'user-group': UserGroupIcon,
};

function SuggestionsCard({
  title,
  subTitle,
  links,
  onCancel,
  onContinue,
}: SuggestionsCardProps): JSX.Element {
  const { id: storeId } = useStoreInfo();
  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{title}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <div className="stratus--suggestion-card__cancellation-reasons-callout">
          <Stack column align="stretch" spacing="loose">
            <div
              className="stratus--embedded-html"
              dangerouslySetInnerHTML={{
                __html: `<p class="nimbus--text nimbus--text-size--base nimbus--text-color--default nimbus--text-align--left" >${subTitle}</p>`,
              }}
            />
            {links.map(({ title, icon, linkText, linkUrl }, index) => (
              <CalloutCard
                key={index}
                subtitle={title}
                icon={ICONS[icon || '']}
                appearance="secondary"
                title=""
                onClick={() => handleClickAnnualDiscount(linkUrl, storeId)}
                textLink={{
                  children: <Text>{linkText}</Text>,
                }}
              />
            ))}
          </Stack>
        </div>
      </BaseCard.Body>
      <BaseCard.Footer>
        <CancelAndContinue onCancel={onCancel} onContinue={onContinue} />
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default SuggestionsCard;
