import { HistoryIcon } from '@tiendanube/icons';
import { ActionIconButton } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { trackingInventoryHistoryClick } from 'domains/Catalog/Products/tracking';

interface IconButtonStockHistoryProps {
  onClick: () => void;
}

const handleClick = (onClick: () => void): void => {
  trackingInventoryHistoryClick();
  onClick();
};

function IconButtonStockHistory({
  onClick,
}: IconButtonStockHistoryProps): JSX.Element {
  const t = useTranslationCatalog();

  return (
    <ActionIconButton
      content={t('products.detail.goToStockHistory')}
      source={<HistoryIcon />}
      onClick={() => handleClick(onClick)}
    />
  );
}

export default IconButtonStockHistory;
