import { Text } from '@nimbus-ds/components';
import { generatePath } from 'react-router';
import { InternalNavLink } from 'commons/components';
import { FREE_SHIPPING_ROUTES } from '../freeShippingRoutes';

interface DeliveryMethodsItemProps {
  methods: string[];
  id: string;
}

export function DeliveryMethodsItem({
  methods,
  id,
}: Readonly<DeliveryMethodsItemProps>) {
  return (
    <>
      {methods.map((method) => (
        <InternalNavLink
          to={generatePath(FREE_SHIPPING_ROUTES.editFreeShipping, { id })}
          key={method}
        >
          <Text color="primary-interactive" fontWeight="medium">
            {method}
          </Text>
        </InternalNavLink>
      ))}
    </>
  );
}
