import { ExternalCodesFacebookCard } from '../ExternalCodesFacebookCard';
import { ExternalCodesGoogleCard } from '../ExternalCodesGoogleCard';
import { ExternalCodesOtherServicesCard } from '../ExternalCodesOtherServicesCard';

export function Skeleton() {
  return (
    <>
      <ExternalCodesGoogleCard.Skeleton />
      <ExternalCodesFacebookCard.Skeleton />
      <ExternalCodesOtherServicesCard.Skeleton />
    </>
  );
}
