import { useMemo } from 'react';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { useListMobile } from 'App/hooks';
import { PromotionsEndpoints } from '../promotionsApi';

export function usePromotionsMobile() {
  const result = useListMobile<PromotionsItemListResponseDto>({
    filters: { perPage: '20' },
    endpoint: PromotionsEndpoints.promotionsListInf,
  });

  const ids = useMemo(
    () => result.data.map((current) => current.id),
    [result.data],
  );
  return { ...result, ids };
}
