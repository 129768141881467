import { Link } from '@nimbus-ds/components';
import { useGetDomainsErrorsQuery } from 'domains/Configurations/Domains/domainsApi';
import {
  useCustomDomainDisabled,
  useHasDomainsErrorAlertTag,
} from 'domains/Configurations/Domains/hooks';
import { trackingDomainErrorReviewClick } from 'domains/Configurations/Domains/tracking';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface CheckDomainErrorsLinkProps {
  onClick: () => void;
  domainId: string;
}

export function CheckDomainErrorsLink({
  onClick,
  domainId,
}: Readonly<CheckDomainErrorsLinkProps>) {
  const t = useTranslationConfigurations('domains');
  const isFreePlan = useCustomDomainDisabled();
  const hasDomainsErrorAlertTag = useHasDomainsErrorAlertTag();

  const { data: domainsWithErrors } = useGetDomainsErrorsQuery(undefined, {
    skip: !hasDomainsErrorAlertTag,
  });
  const domainHasError =
    !isFreePlan &&
    !!domainsWithErrors &&
    domainsWithErrors.ids.includes(domainId);

  const handleClick = () => {
    trackingDomainErrorReviewClick();
    onClick();
  };

  if (!domainHasError || !hasDomainsErrorAlertTag) return null;

  return (
    <Link
      as="button"
      textDecoration="none"
      appearance="primary"
      fontSize="caption"
      onClick={handleClick}
    >
      {t('attachModal.verification.verifyBtn')}
    </Link>
  );
}
