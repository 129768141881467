import { EmptySearch } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function SelectProductEmptySearch(): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <EmptySearch
      title={t('products.selectProductList.empty.title')}
      text={t('products.selectProductList.empty.description')}
    />
  );
}

export default SelectProductEmptySearch;
