import { Alert, Box, Card, Chip, Text } from '@nimbus-ds/components';
import { FreeShippingDeliveryMethodsDto } from '@tiendanube/common';
import { SelectOrEditLink, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { DeliveryMethodsModalAside } from 'domains/Marketing/FreeShipping/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface DeliveryMethodsCardProps {
  methods: FreeShippingDeliveryMethodsDto[];
  showRequiredError: boolean;
  onChange: (methods: FreeShippingDeliveryMethodsDto[]) => void;
}

export function DeliveryMethodsCard({
  methods,
  showRequiredError,
  onChange,
}: Readonly<DeliveryMethodsCardProps>) {
  const t = useTranslationMarketing(
    'freeShipping.settingsPage.deliveryMethods',
  );
  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !methods.length;

  return (
    <>
      <Card>
        <Card.Header title={t('title')} />
        <Card.Body>
          <Stack column align="flex-start" spacing="loose">
            {isEmpty && <Text> {t('body')} </Text>}
            {showRequiredError && (
              <Alert appearance="danger">{t('required')}</Alert>
            )}
            {!isEmpty && (
              <Box display="flex" gap="2" flexWrap="wrap">
                {methods.map(({ idMethod, idOption, description }) => (
                  <Chip
                    key={`${idMethod}-${idOption}`}
                    text={description}
                    removable
                    onClick={() =>
                      onChange(
                        methods.filter(
                          (current) =>
                            !(
                              current.idMethod === idMethod &&
                              current.idOption === idOption
                            ),
                        ),
                      )
                    }
                  />
                ))}
              </Box>
            )}
          </Stack>
        </Card.Body>
        <Card.Footer>
          <SelectOrEditLink isEdit={!isEmpty} onSelect={openModal} />
        </Card.Footer>
      </Card>
      <DeliveryMethodsModalAside
        isOpen={isShowModal}
        selected={methods}
        onClose={closeModal}
        onChange={onChange}
      />
    </>
  );
}
