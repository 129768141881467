import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';
import { ClockIcon } from '@tiendanube/icons';

export interface MessagesListItemLabelRepliedProps {
  id: string;
  replied: boolean;
}

function MessagesListItemLabelReplied({
  id,
  replied,
}: MessagesListItemLabelRepliedProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  const labelReplied = replied
    ? `${t('customerDetails.customerActivity.messageLabels.answered')}`
    : `${t('customerDetails.customerActivity.messageLabels.notAnswered')}`;
  const appearanceReplied = replied ? 'primary' : 'warning';

  return (
    <Label
      id={`${id}-label`}
      label={labelReplied}
      icon={ClockIcon}
      appearance={appearanceReplied}
    />
  );
}

export default MessagesListItemLabelReplied;
