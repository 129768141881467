import { Box, Text, Link } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { logEvent } from 'commons/utils/tracking';
import { NUVEM_ENVIO_CLICK } from 'config/trackingEvents';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { useGetNuvemEnvioConfigs } from 'domains/Shipping/DeliveryMethods/hooks';
import useUpdateNuvemEnvioConfigs from 'domains/Shipping/DeliveryMethods/hooks/useUpdateNuvemEnvioConfigs';

interface NuvemEnvioPopoverBodyProps {
  storeId: string;
  usePrimaryPopoverBody: boolean;
  onLinkClick?: () => void;
}

const OPEN_NUVEM_ENVIO_DASHBOARD_ACTION = 'Open orders banner';

function NuvemEnvioPopoverBody({
  storeId,
  usePrimaryPopoverBody,
  onLinkClick,
}: NuvemEnvioPopoverBodyProps): JSX.Element {
  const t = useTranslationOrders();
  const { goTo } = useNavegate();
  const { updateNuvemEnvioConfigs } = useUpdateNuvemEnvioConfigs();
  const { fetchNuvemEnvioConfigs } = useGetNuvemEnvioConfigs();

  const handleGoNuvemEnvioDashboardClick = async () => {
    logEvent(NUVEM_ENVIO_CLICK, {
      storeId,
      action: OPEN_NUVEM_ENVIO_DASHBOARD_ACTION,
    });
    await updateNuvemEnvioConfigs({
      enableEngagementBanner: false,
    });
    fetchNuvemEnvioConfigs();
    if (onLinkClick) {
      onLinkClick();
    }
    goTo(partnersAppsRoutes.nuvemenvio);
  };

  return usePrimaryPopoverBody ? (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      marginTop="1"
    >
      <Box display="flex" flexDirection="column" marginBottom="4">
        <Text as="span" fontSize="base" color="neutral-background">
          {t(`nuvemEnvio.banner.primary.initText`)}
        </Text>
        <Box display="flex" flexDirection="row" gap="1">
          <Text
            as="span"
            fontSize="base"
            color="neutral-background"
            fontWeight="bold"
          >
            {t(`nuvemEnvio.banner.primary.secondText`)}
          </Text>
          <Text as="span" fontSize="base" color="neutral-background">
            {t(`nuvemEnvio.banner.primary.thirdText`)}
          </Text>
        </Box>
        <Text as="span" fontSize="base" color="neutral-background">
          {t(`nuvemEnvio.banner.primary.fourthText`)}
        </Text>
      </Box>
      <Link
        as="a"
        appearance="neutral-background"
        fontSize="caption"
        onClick={handleGoNuvemEnvioDashboardClick}
      >
        {t(`nuvemEnvio.banner.primary.cta`)}
      </Link>
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      marginTop="1"
    >
      <Box display="flex" flexDirection="column" marginBottom="4">
        <Text
          as="span"
          fontSize="base"
          color="neutral-background"
          fontWeight="bold"
        >
          {t(`nuvemEnvio.banner.secundary.initText`)}
        </Text>
        <Text as="span" fontSize="base" color="neutral-background">
          {t(`nuvemEnvio.banner.secundary.secondText`)}
        </Text>
      </Box>
      <Link
        as="a"
        appearance="neutral-background"
        fontSize="caption"
        onClick={handleGoNuvemEnvioDashboardClick}
      >
        {t(`nuvemEnvio.banner.secundary.cta`)}
      </Link>
    </Box>
  );
}

export default NuvemEnvioPopoverBody;
