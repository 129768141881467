import {
  SizeAttributeType,
  StandardSizeResponseDtoV2,
} from '@tiendanube/common';
import {
  InteractiveList,
  InterfaceInteractiveListOptions,
  Link,
  Stack,
  Text,
} from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { StandardSizeAttributeProps } from './StandardSizeAttribute';
import { filterSizesByType } from './utils';
import { TRANSLATED_STANDARD_SIZE } from '../../../constants';
import { isAttributeValueSelected } from '../../../utils';

interface StandardSizeOptionsProps
  extends Pick<StandardSizeAttributeProps, 'selectedValues' | 'languageToUse'> {
  standardSizes: StandardSizeResponseDtoV2['standard'];
  sizeType: SizeAttributeType;
  onCheckOption: (sizeType: SizeAttributeType, selected: string[]) => void;
  onCheckAll: (sizeType: SizeAttributeType) => void;
  onUncheckAll: (sizeType: SizeAttributeType) => void;
}

function StandardSizeOptions({
  sizeType,
  standardSizes,
  languageToUse,
  selectedValues,
  onCheckOption,
  onCheckAll,
  onUncheckAll,
}: StandardSizeOptionsProps) {
  const t = useTranslationCatalog();

  const options: InterfaceInteractiveListOptions[] = Object.keys(
    standardSizes[sizeType],
  ).map((sizeId) => {
    const standardSize = standardSizes[sizeType][sizeId];
    return {
      title: standardSize[languageToUse],
      name: sizeId,
      active: isAttributeValueSelected(
        selectedValues,
        languageToUse,
        standardSize,
      ),
    };
  });

  const showSelectAll =
    filterSizesByType(standardSizes[sizeType], selectedValues, languageToUse)
      .length === 0;

  const handleClick = () => {
    if (showSelectAll) {
      return onCheckAll(sizeType);
    }
    onUncheckAll(sizeType);
  };

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack justify="space-between">
        <Text size="caption" bold>
          {TRANSLATED_STANDARD_SIZE[sizeType][languageToUse]}
        </Text>
        <Link appearance="primary" size="small" onClick={handleClick}>
          {showSelectAll
            ? t('products.variants.selectAll')
            : t('products.variants.removeAll')}
        </Link>
      </Stack>
      <InteractiveList
        mode="multi"
        onChange={(selected) => onCheckOption(sizeType, selected)}
        options={options}
      />
    </Stack>
  );
}

export default StandardSizeOptions;
