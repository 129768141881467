import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { SocialNetworksRequestDto } from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { ErrorState } from '@tiendanube/components';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINK_SOCIAL_NETWORKS } from 'commons/constants';
import { useForm, useToast, useTranslationLanguage } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import SocialNetworksCard from './components/SocialNetworksCard';
import useSocialNetworks from './hook/useSocialNetworks';
import { socialNetworksSchema } from './validationSchema';

export interface InterfaceNameValue {
  name: string;
  value?: string | null;
}

function SocialNetworksPage(): JSX.Element {
  const t = useTranslationAccount();
  const language = useTranslationLanguage();
  const { addToast } = useToast();
  //TODO: Redirect to dashboard temporarily while fix menu behavior
  const { goTo } = useNavegate();
  const goToDashBoard = () => goTo('/');

  const {
    socialNetworks,
    fetchSocialNetworks,
    updateSocialNetworks,
    fetchStatus,
    updateStatus,
  } = useSocialNetworks({
    onUpdateSuccess: () =>
      addToast({
        label: t('socialNetworksPage.savedData'),
        appearance: 'success',
      }),
    onUpdateError: () =>
      addToast({
        label: t('socialNetworksPage.saveError'),
        appearance: 'danger',
      }),
  });

  const { values, errors, handleOnSubmit, setFieldValue, isDirty } =
    useForm<SocialNetworksRequestDto>({
      initialValues: socialNetworks,
      validationSchema: socialNetworksSchema,
      onSubmit: () => updateSocialNetworks(values),
    });
  useEffect(() => {
    fetchSocialNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = ({ name, value }: InterfaceNameValue) => {
    setFieldValue(name, value);
  };

  return (
    <IonPageStratus
      headerContent={
        <HeaderContent
          title={t('socialNetworksPage.title')}
          subtitle={t('socialNetworksPage.subtitle')}
        />
      }
      width="small"
    >
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />
      {fetchStatus.isLoading && <SocialNetworksCard.Skeleton />}
      {fetchStatus.isError && (
        <ErrorState
          title={t('socialNetworksPage.fetchErrorReTry')}
          action={{
            children: t('socialNetworksPage.fetchError'),
            onClick: fetchSocialNetworks,
          }}
        />
      )}
      {!fetchStatus.isLoading && !fetchStatus.isError && (
        <>
          <SocialNetworksCard
            values={values}
            errors={errors}
            handleOnChange={handleOnChange}
          />
          <CancelAndSaveButtons
            saveText={t('socialNetworksPage.save', {
              count: 0,
            })}
            onSave={handleOnSubmit}
            onCancel={goToDashBoard}
            cancelText={t('socialNetworksPage.cancel')}
            isDisabled={!isDirty}
            isLoading={updateStatus.isLoading}
          />
        </>
      )}
      <ShowByCountry allowedCountries={[Country.BR]}>
        <HelpLink
          title={t('socialNetworksPage.helpLink.title')}
          previousValue=""
          currentViewTracking=""
          linkURL={HELP_LINK_SOCIAL_NETWORKS[language]}
          icon="both"
        />
      </ShowByCountry>
    </IonPageStratus>
  );
}

export default SocialNetworksPage;
