import { useEffect } from 'react';
import { IconButton } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';
import {
  OrderFulfillBulkRequestDto,
  OrderFulfillmentUpdateRequestDto,
  OrderFulfillmentsDto,
  OrderResponseDto,
} from '@tiendanube/common';
import { Popover } from '@tiendanube/components';
import {
  CreditCardIcon,
  BoxPackedIcon,
  TruckIcon,
  StoreIcon,
  ArchiveIcon,
  MailIcon,
  LockOpenIcon,
  PrinterIcon,
} from '@tiendanube/icons';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useModal, useToastStatus } from 'commons/hooks';
import useQuickAction from 'domains/Orders/Orders/hooks/useQuickAction';
import FulfillOrdersModal from 'domains/Orders/Orders/pages/OrderListPage/components/FulfillOrdersModal';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface QuickActionsPopoverProps {
  order: OrderResponseDto;
}

function QuickActionsPopover({
  order,
}: QuickActionsPopoverProps): JSX.Element | null {
  const {
    archiveOrder,
    fulfillOrder,
    openOrder,
    packOrder,
    paidOrder,
    printNuvemEnvio,
    status,
  } = useQuickAction();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [showModal, openModal, closeModal] = useModal();
  const t = useTranslationOrders();

  useEffect(() => {
    if (status === 'loading')
      addToastProgress({ label: t('quickActions.loading') });
    else if (status === 'error' || status === 'success') closeToastProgress();
  }, [status, addToastProgress, closeToastProgress, t]);

  useToastStatus({
    status,
    error: t('quickActions.error'),
    success: t('quickActions.success'),
  });

  const hasFulfillments = order.fulfillmentOrders.length > 0;

  const getFulfillments = (order: OrderResponseDto) =>
    order.fulfillmentOrders.map(({ id, status, shipping_info }) => ({
      fulfillmentId: id,
      status,
      shippingType: shipping_info.shipping_type,
    }));

  const handleComfirmTrackingNum = (
    fulfillOrdersAction: OrderFulfillBulkRequestDto[],
  ) => {
    // Llamada a fulfillOrder para cada pedido con su respectivo número de seguimiento
    fulfillOrdersAction.forEach((fulfill) => {
      fulfillOrder(order, {
        ...fulfill,
        fulfillments: fulfill.fulfillment ? [fulfill.fulfillment] : [],
        sendNotification: fulfill.sendNotification,
      });
    });
    closeModal();
  };
  const ordersMissingTracking = order.fulfillmentOrders.filter(
    (fulfillment) => !fulfillment.shipping_info?.tracking_code,
  ).length;

  const ordersWithOutDigital = order.fulfillmentOrders.filter(
    (fulfillment) =>
      fulfillment.shipping_info.shipping_type !== 'non-shippable',
  );
  const packagesCount = order.fulfillmentOrders.length;
  const RESOURCES = {
    paymentReceived: {
      icon: CreditCardIcon,
      onClick: () => paidOrder(order.id),
    },
    packaging: {
      icon: BoxPackedIcon,
      onClick: () => packOrder(order.id, ordersWithOutDigital),
    },
    sent: {
      icon: TruckIcon,
      onClick: () => openModal(),
    },
    retired: {
      icon: StoreIcon,
      onClick: () =>
        order.isShippablePickup
          ? openModal()
          : fulfillOrder(order, { fulfillments: getFulfillments(order) }),
    },
    archive: {
      icon: ArchiveIcon,
      onClick: () => archiveOrder(order.id),
    },
    digitallySent: {
      icon: MailIcon,
      onClick: () => openModal(),
    },
    reopen: {
      icon: LockOpenIcon,
      onClick: () => openOrder(order.id),
    },
    printNuvemEnvio: {
      icon: PrinterIcon,
      onClick: () => printNuvemEnvio(order.id),
    },
  };

  const getFulfillmentsForModal = (
    order: OrderResponseDto,
  ): OrderFulfillmentsDto => {
    const fulfillments: OrderFulfillmentUpdateRequestDto[] =
      order.fulfillmentOrders.map((fulfillment) => ({
        fulfillmentId: fulfillment.id,
        status: fulfillment.status,
        shippingType: fulfillment.shipping_info.shipping_type,
      }));

    const fulfillmentIds = order.fulfillmentOrders.map(
      (fulfillment) => fulfillment.id,
    );

    return {
      orderId: order.id,
      fulfillments,
      fulfillmentIds,
    };
  };
  const fulfillmentsForModal = [getFulfillmentsForModal(order)];

  if (order.quickActions?.length < 1) return null;

  return (
    <>
      <Popover
        name="quickActions"
        renderInitiator={(onClick) => (
          <IconButton
            size="2rem"
            source={<EllipsisIcon size="small" />}
            onClick={onClick}
          />
        )}
        menu={order.quickActions.map(({ appearance, children, name }) => {
          const { icon, onClick } = RESOURCES[name];
          return {
            appearance,
            children,
            icon,
            onClick,
          };
        })}
        position="right"
      />

      {showModal && (
        <FulfillOrdersModal
          show={showModal}
          orderIds={[order.id]}
          isLoading={false}
          onConfirm={handleComfirmTrackingNum}
          onClose={closeModal}
          ordersMissingTracking={ordersMissingTracking}
          packagesCount={packagesCount}
          hasFulfillments={hasFulfillments}
          fulfillmentsForModal={fulfillmentsForModal}
          isSingleOrder
        />
      )}
    </>
  );
}

export default QuickActionsPopover;
