import { Modal, Title, List, Button, Icon, Link } from '@nimbus-ds/components';
import { LightbulbIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { Country } from '@tiendanube/common/src/enums';
import { useResponsive, Stack } from 'commons/components';
import {
  TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK,
  TIPS_TO_TAKE_PHOTO_BR_LINK,
  TIPS_TO_TAKE_PHOTO_MX_LINK,
} from 'commons/constants/externalLinks';
import { useModal } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ReactComponent as TipsImage } from './image.svg';

const HELP_LINKS: Record<Country, string> = {
  [Country.AR]: TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK,
  [Country.CO]: TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK,
  [Country.CL]: TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK,
  [Country.PE]: TIPS_TO_TAKE_PHOTO_AR_CO_CL_LINK,
  [Country.BR]: TIPS_TO_TAKE_PHOTO_BR_LINK,
  [Country.MX]: TIPS_TO_TAKE_PHOTO_MX_LINK,
};

function TipsForTakingProductPhotosModal() {
  const [isShow, openModal, closeModal] = useModal();
  const country = useGetCountry();
  const t = useTranslationCatalog('products.detail.imagesModalTips');
  const { isMobile } = useResponsive();

  const handleOnClickMoreTips = () => openWindow(HELP_LINKS[country], true);

  return (
    <>
      <Link textDecoration="underline" onClick={openModal}>
        <Icon color="currentColor" source={<LightbulbIcon />} />
        {t('title')}
      </Link>
      <Modal open={isShow} onDismiss={closeModal}>
        <Stack column align="center" spacing="loose">
          <TipsImage width={isMobile ? 350 : 452} />
          <Title as="h3">{t('title')}</Title>
          <List>
            <List.Item>{t('step1')}</List.Item>
            <List.Item>{t('step2')}</List.Item>
            <List.Item>{t('step3')}</List.Item>
          </List>
        </Stack>
        <Stack wrap spacing="tight" justify="center">
          <Button onClick={closeModal} appearance="neutral">
            {t('keepEditing')}
          </Button>
          <Button appearance="primary" onClick={handleOnClickMoreTips}>
            {t('link')}
            <Icon color="currentColor" source={<ExternalLinkIcon />} />
          </Button>
        </Stack>
      </Modal>
    </>
  );
}

export default TipsForTakingProductPhotosModal;
