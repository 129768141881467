import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetPendingChargesQuery } from 'domains/Billing/Checkout/CheckoutApi';

function usePendingCharges(initialFetch = true) {
  const {
    status,
    refetch,
    data: charges,
  } = useGetPendingChargesQuery(undefined, { skip: !initialFetch });

  const planPending = charges?.some(
    (charge) => charge.conceptCode === 'plan-cost',
  );

  return {
    ...convertStatusTypeToObject(status),
    status: toStatusType(status),
    charges,
    storeUpToDate: charges?.length === 0,
    planPending,
    refreshPendingCharges: refetch,
  };
}

export { usePendingCharges };
