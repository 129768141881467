import { ReactNode, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ModalConfirmationContext } from './components/ModalConfirmation/ModalConfirmation';

interface RouterProviderInterface {
  children: ReactNode;
}

function RouterProvider({ children }: Readonly<RouterProviderInterface>) {
  const baseUrl = import.meta.env.VITE_BASE_URL;
  const { userConfirmation } = useContext(ModalConfirmationContext);
  return (
    <Router basename={baseUrl} getUserConfirmation={userConfirmation}>
      {children}
    </Router>
  );
}

export default RouterProvider;
