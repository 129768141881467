import { useHistory } from 'react-router-dom';
import { Button } from '@tiendanube/components';
import { Icon as IconType } from '@tiendanube/icons';

interface LinkButton {
  text: string;
  to: string;
  icon?: IconType;
  appearance?: 'primary' | 'secondary' | 'default' | 'danger' | undefined;
  iconPosition?: 'start' | 'end';
}

function LinkButton({
  to,
  icon,
  appearance,
  iconPosition,
  text,
}: LinkButton): JSX.Element {
  const history = useHistory();

  const handleOnClick = (): void => {
    history.push(to);
  };

  return (
    <Button
      onClick={handleOnClick}
      appearance={appearance}
      icon={icon}
      iconPosition={iconPosition}
    >
      {text}
    </Button>
  );
}

export default LinkButton;
