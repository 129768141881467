import { useEffect, useState } from 'react';
import { FEATURE_MAX_SHIPPING_CUSTOMS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { useModal, useToastStatus } from 'commons/hooks';
import { SHIPPING_ADD_CUSTOM_METHOD } from 'config/upsellFlowSources';
import { useStatusFeatureByKeyWithDefaultValue } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  useDeleteCustomShipping,
  useGetCustomShipping,
} from 'domains/Shipping/DeliveryMethods/hooks';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { DeleteShippingModal, ShippingCard } from '../../../components';

function CustomShippingCard() {
  const t = useTranslationShipping();

  const { goTo } = useNavegate();
  const [isOpen, openModal, closeModal] = useModal();
  const [idToDelete, setIdToDelete] = useState('');
  const { status, deleteCustomShipping } = useDeleteCustomShipping();

  const { fetchCustomShipping, isLoading, isError, customShipping } =
    useGetCustomShipping();

  const handleClickAdd = useUpsellFlow({
    title: t('deliveryMethods.listCustomShipping.upsellTitle'),
    featureKey: FEATURE_MAX_SHIPPING_CUSTOMS,
    trackingSource: SHIPPING_ADD_CUSTOM_METHOD,
    callback: () => goTo(DELIVERY_METHODS_ROUTES.national),
  });

  const handleDelete = (id: string) => {
    openModal();
    setIdToDelete(id);
  };

  const handleConfirmDelete = () => {
    deleteCustomShipping(idToDelete);
    setIdToDelete('');
  };

  useToastStatus({
    status,
    success: `${t('deliveryMethods.customShipping.toastSuccess.deleted')}`,
    error: `${t('deliveryMethods.customShipping.toastError.elimination')}`,
    onSuccess: closeModal,
    onError: closeModal,
  });

  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_MAX_SHIPPING_CUSTOMS,
    -1,
  );

  useEffect(() => {
    if (!customShipping && isAvailable) fetchCustomShipping();
  }, [fetchCustomShipping, customShipping, isAvailable]);

  return (
    <>
      <ShippingCard
        title={`${t('deliveryMethods.listCustomShipping.title')}`}
        subTitle={t('deliveryMethods.listCustomShipping.subTitle')}
        linkText={t('deliveryMethods.listCustomShipping.link')}
        onClickAddDelivery={handleClickAdd}
        editDeliveryRoute={DELIVERY_METHODS_ROUTES.national}
        shippingList={customShipping ?? []}
        isLoading={isLoading}
        isError={isError}
        fetchData={fetchCustomShipping}
        handleDelete={handleDelete}
      />

      <DeleteShippingModal
        title={t('deliveryMethods.deleteShippingModal.title')}
        description={t('deliveryMethods.deleteShippingModal.message')}
        isLoading={status === 'loading'}
        onConfirmDelete={handleConfirmDelete}
        isOpen={isOpen}
        onClose={closeModal}
      />
    </>
  );
}

export default CustomShippingCard;
