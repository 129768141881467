import { CombinedState } from '@reduxjs/toolkit';
import { AvailableMenus } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { StatusType } from 'commons/types';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export interface MenuSchema {
  status: StatusType;
  data: AvailableMenus | null;
}

export const getMenuState = (state: RootStateType): CombinedState<MenuSchema> =>
  state.menu;

export const getMenuSelector = createSimpleSelector(
  [getMenuState],
  (menu) => menu.data,
);
