import { Box, Button, Modal, Text, Title } from '@nimbus-ds/components';

function DeleteLocationModalSkeleton() {
  return (
    <>
      <Modal.Header>
        <Box
          alignItems="center"
          display="flex"
          gap="4"
          justifyContent="space-between"
        >
          <Title.Skeleton as="h3" width="80%" />
        </Box>
      </Modal.Header>
      <Modal.Body>
        <Text.Skeleton width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <Button.Skeleton />
        <Button.Skeleton />
      </Modal.Footer>
    </>
  );
}
export default DeleteLocationModalSkeleton;
