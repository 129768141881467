import { Capacitor } from '@capacitor/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { COMPATIBLE_APP_VERSION } from 'App/version';

const { VITE_APP_ENV, VITE_APP_VERSION, VITE_API_BFF } = import.meta.env;

const isProd = VITE_APP_ENV === 'production';
const isStaging = VITE_APP_ENV === 'staging';

const commonDatadogConfig = {
  site: 'datadoghq.com',
  env: VITE_APP_ENV,
  useCrossSiteSessionCookie: true,
};

const getMobileVersion = () => {
  if (Capacitor.getPlatform() !== 'web')
    return `${Capacitor.getPlatform()}-${COMPATIBLE_APP_VERSION}`;

  return `web-mobile-${VITE_APP_VERSION}`;
};

const datadogConfig = {
  mobile: {
    clientToken: 'pubfe0ba73a59186d1e0bf6ecf4326bff83',
    applicationId: '9ec8d39a-5eea-4412-a240-39419559a982',
    service: 'frontend-new-admin-mobile',
    version: getMobileVersion(),
    ...commonDatadogConfig,
  },
  web: {
    clientToken: 'pubd7d84e443444622d889cf17d52fa6fda',
    applicationId: '9be7a1cb-966a-430c-a903-51e151ac825c',
    service: 'frontend-new-admin-web',
    version: `${VITE_APP_ENV}-${VITE_APP_VERSION}`,
    ...commonDatadogConfig,
  },
};
function initDatadogConfig(isMobile: boolean) {
  const defaultConfig = datadogConfig[isMobile ? 'mobile' : 'web'];
  datadogRum.init({
    ...defaultConfig,
    allowedTracingUrls: [
      'https://cirrus.tiendanube.com',
      (url) => url.startsWith(VITE_API_BFF),
    ],
    sessionSampleRate: isProd ? 20 : 0,
    sessionReplaySampleRate: isProd ? 20 : 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogLogs.init({
    ...defaultConfig,
    forwardErrorsToLogs: true,
    sessionSampleRate: isProd ? 100 : 0,
  });
}

if (isProd || isStaging)
  initDatadogConfig(
    Capacitor.isNativePlatform() || window.innerWidth < MOBILE_BREAKPOINT,
  );
