import { useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

export type DrawableCanvasRef = React.RefObject<CanvasDraw>;

function useDrawableCanvas() {
  const canvasRef: DrawableCanvasRef = useRef<CanvasDraw>(null);

  function getCanvasAsDataURL(): string {
    if (canvasRef.current) {
      return canvasRef.current.getDataURL();
    }

    return '';
  }

  function clearCanvas() {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }
  }

  function undoCanvas() {
    if (canvasRef.current) {
      canvasRef.current.undo();
    }
  }

  return {
    canvasRef,
    getCanvasAsDataURL,
    clearCanvas,
    undoCanvas,
  };
}

export default useDrawableCanvas;
