import { Alert } from '@nimbus-ds/components';
import { useGetChargeDetailsQuery } from 'domains/Billing/Checkout/CheckoutApi';
import { isPayableCharge } from 'domains/Billing/Checkout/hooks/utils';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

export interface ProportionalChargeAlertProps {
  chargeId: string;
}

export default function ProportionalChargeAlert({
  chargeId,
}: Readonly<ProportionalChargeAlertProps>) {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.proportionalChargeAlert',
  );
  const { data: charge } = useGetChargeDetailsQuery(chargeId);

  if (!charge?.isProportional || !isPayableCharge(charge.status)) return null;

  return (
    <Alert title={t('title')} appearance="neutral">
      {t('message')}
    </Alert>
  );
}
