import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_FREE_SHIPPING_LIST_CLICK,
  MENU_SECONDARY_PROMOTION_LIST_CLICK,
} from 'config/trackingEvents';

export const menuSecondaryPromotionListClick = () => {
  logEvent(MENU_SECONDARY_PROMOTION_LIST_CLICK, {});
};

export const menuSecondaryFreeshipingListClick = () => {
  logEvent(MENU_SECONDARY_FREE_SHIPPING_LIST_CLICK, {});
};
