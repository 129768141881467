import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceAbandonedCartsSchema } from '../abandonedCartsSlice';

const getAbandonedCartsState = (
  state: RootStateType,
): CombinedState<InterfaceAbandonedCartsSchema> => state.orders.abandonedCarts;

const getEntityById = (_: RootStateType, id: string) => id;

export const getAbandonedCartsStatus = createSelector(
  getAbandonedCartsState,
  (abandonedCartsState) => ({
    isLoading: abandonedCartsState.status === 'loading',
    isError: abandonedCartsState.status === 'error',
    isSuccess: abandonedCartsState.status === 'success',
    isIdle: abandonedCartsState.status === 'idle',
  }),
);

export const getAbandonedCartsRefreshStatus = createSelector(
  getAbandonedCartsState,
  (abandonedCartsState) => ({
    isRefreshError: abandonedCartsState.refreshStatus === 'error',
    isRefreshSuccess: abandonedCartsState.refreshStatus === 'success',
    isRefreshing: abandonedCartsState.refreshStatus === 'refreshing',
  }),
);

export const getAbandonedCartsEntities = createSelector(
  getAbandonedCartsState,
  (andonedCartsState) => andonedCartsState.entities,
);

export const getAbandonedCartsList = createSelector(
  getAbandonedCartsEntities,
  (drafOrdersEntities) => Object.values(drafOrdersEntities),
);

export const getAbandonedCartsPagination = createSelector(
  getAbandonedCartsState,
  (abandonedCartsState) => abandonedCartsState.pagination,
);

export const getAbandonedCartsIds = createSelector(
  getAbandonedCartsState,
  (getAbandonedCartsState) => getAbandonedCartsState.ids,
);

export const getAbandonedCartsListItemById = createSelector(
  getAbandonedCartsEntities,
  getEntityById,
  (abandonedCartsEntities, id) => abandonedCartsEntities[id],
);

export const getAbandonedCartDetailsStatus = createSelector(
  getAbandonedCartsState,
  (abandonedCartState) => ({
    isLoading: abandonedCartState.entityDetails.status === 'loading',
    isError: abandonedCartState.entityDetails.status === 'error',
    isSuccess: abandonedCartState.entityDetails.status === 'success',
  }),
);

export const getAbandonedCartDetails = createSelector(
  getAbandonedCartsState,
  (abandonedCartState) => abandonedCartState.entityDetails.data,
);

export const getAbandonedCartEmailConfig = createSelector(
  getAbandonedCartsState,
  (state) => state.abandonedCartsEmailConfig.config,
);

export const getAbandonedCartEmailStatus = createSelector(
  getAbandonedCartsState,
  (state) => ({
    isLoading: state.abandonedCartsEmailConfig.changeStatus === 'loading',
    isError: state.abandonedCartsEmailConfig.changeStatus === 'error',
    isSuccess: state.abandonedCartsEmailConfig.changeStatus === 'success',
  }),
);
