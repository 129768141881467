import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  NumberIdTypeTaxBillingDataResponseDto,
  TypeTaxBillingDataResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useForm } from 'commons/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import {
  cleanValidateCuit,
  createFiscalData,
  getFetchFiscalDataStatus,
  getFiscalDataCuit,
  getFiscalDataDni,
  getFiscalDataStatus,
  getFiscalDataType,
  getInvoiceInfoData,
  getInvoiceInfoExists,
  getValidatedCuit,
  getValidatedCuitStatus,
  updateFiscalData,
  validateCuit,
} from 'domains/Billing/Invoices/invoicesSlice';
import { CuitInterface, TaxDataInterface } from './ArgentineFiscalData';
import {
  uploadTaxDataWithCuitSchema,
  uploadTaxDataWithDniSchema,
} from './validationSchema';

interface FullFiscalDataInterface {
  numberId: string;
  numberIdType: NumberIdTypeTaxBillingDataResponseDto;
  vatCondition: string;
  type: TypeTaxBillingDataResponseDto;
  name: string;
  address: string;
}

function useArgentineFiscalData() {
  const dispatch = useAppDispatch();
  const { fetchInvoiceInfo, refreshInvoiceInfo } = useGetInvoiceInfo();

  const validatedCuitStatus = useSelector(getValidatedCuitStatus);
  const validatedCuit = useSelector(getValidatedCuit);
  const fiscalDataStatus = useSelector(getFetchFiscalDataStatus);
  const submitFiscalDataStatus = useSelector(getFiscalDataStatus);
  const initialValuesDni = useSelector(getFiscalDataDni);
  const initialValuesCuit = useSelector(getFiscalDataCuit);
  const invoiceInfoExists = useSelector(getInvoiceInfoExists);
  const invoiceInfo = useSelector(getInvoiceInfoData);
  const fiscalDataType = useSelector(getFiscalDataType);

  const resolveSubmitFiscalData = (data: FullFiscalDataInterface) => {
    if (invoiceInfoExists) {
      dispatch(
        updateFiscalData({
          ...data,
          id: invoiceInfo.id,
        }),
      );
    } else {
      dispatch(createFiscalData(data));
    }
  };

  useEffect(() => {
    fetchInvoiceInfo();
  }, [fetchInvoiceInfo]);

  const cuitForm = useForm<CuitInterface, CuitInterface>({
    initialValues: initialValuesCuit,
    validationSchema: uploadTaxDataWithCuitSchema,
    onSubmit: ({ cuit }) => dispatch(validateCuit(cuit)),
  });

  const dniForm = useForm<TaxDataInterface, TaxDataInterface>({
    initialValues: initialValuesDni,
    validationSchema: uploadTaxDataWithDniSchema,
    onSubmit: ({ businessName, address, dni }) => {
      resolveSubmitFiscalData({
        name: businessName,
        address,
        numberId: dni,
        numberIdType: 'DNI',
        vatCondition: 'consumidor_final',
        type: 'B',
      });
    },
  });

  const editCuit = () => dispatch(cleanValidateCuit());

  const submitFiscalDataFromCuit = () =>
    resolveSubmitFiscalData({
      name: validatedCuit.name,
      address: validatedCuit.address,
      vatCondition: String(validatedCuit.vatCondition ?? ''),
      type: validatedCuit.type ?? null,
      numberId: validatedCuit.numberId,
      numberIdType: 'CUIT',
    });

  return {
    cuitForm,
    editCuit,
    dniForm,
    validatedCuit,
    fiscalDataType,
    fetchInvoiceInfo,
    refreshInvoiceInfo,
    fiscalDataStatus,
    invoiceInfoExists,
    validatedCuitStatus,
    submitFiscalDataStatus,
    submitFiscalDataFromCuit,
    invoiceInfo,
  };
}

export default useArgentineFiscalData;
