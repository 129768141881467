import HelpLink from 'App/HelpLink';
import { Responsive } from 'commons/components';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { PagesListResultDesktop, PagesListResultMobile } from './components';

function PagesListResult() {
  const t = useTranslationOnline('contentPages.helpLink');

  return (
    <>
      <Responsive
        desktop={<PagesListResultDesktop />}
        mobile={<PagesListResultMobile />}
      />
      <HelpLink
        title={t('title')}
        linkURL={t('linkURL')}
        previousValue=""
        currentViewTracking={t('currentViewTracking')}
        icon="both"
      />
    </>
  );
}

export default PagesListResult;
