import { Grid, Input, Link, Stack, Text } from '@tiendanube/components';

import '../ComparisonSelect.scss';

function ComparisonSelectSkeleton(): JSX.Element {
  return (
    <div className="stratus--comparison__plan__select">
      <Grid fluid>
        <Grid.Row>
          <Grid.Column>
            <Stack column align="stretch">
              <Stack.Item>
                <Input.Skeleton />
              </Stack.Item>
              <Stack.Item>
                <Text.Skeleton width="fill" />
              </Stack.Item>
              <Stack.Item>
                <Link.Skeleton />
              </Stack.Item>
            </Stack>
          </Grid.Column>
          <Grid.Column>
            <Stack column align="stretch">
              <Stack.Item>
                <Input.Skeleton />
              </Stack.Item>
              <Stack.Item>
                <Text.Skeleton width="fill" />
              </Stack.Item>
              <Stack.Item>
                <Link.Skeleton />
              </Stack.Item>
            </Stack>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ComparisonSelectSkeleton;
