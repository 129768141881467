import { NotificationDetailsDto } from '@tiendanube/common';

export const NOTIFICATION_DETAILS_INITIAL_VALUES: NotificationDetailsDto = {
  title: '',
  description: '',
  variables: {
    variables: [],
    moreVariables: [],
  },
  htmlEnabled: false,
  en: {
    subject: '',
    templateHtml: '',
    templateText: '',
  },
  es: {
    subject: '',
    templateHtml: '',
    templateText: '',
  },
  pt: {
    subject: '',
    templateHtml: '',
    templateText: '',
  },
};
