import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCanChangePlanForFree } from 'domains/Auth/authSlice/authSelectors';
import { useCreatePaymentToUpdatePlanMutation } from 'domains/Billing/BillingPlans/billingPlansApi';

function usePlanChangePayment() {
  const [createPaymentToUpdatePlan] = useCreatePaymentToUpdatePlanMutation();
  const canChangePlanForFree = useSelector(getCanChangePlanForFree);

  const createPlanChangePayment = useCallback(
    async (planId: number) =>
      createPaymentToUpdatePlan(planId.toString()).unwrap(),
    [createPaymentToUpdatePlan],
  );

  return {
    canChangePlanForFree,
    createPlanChangePayment,
  };
}

export default usePlanChangePayment;
