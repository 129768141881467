import { Icon, Title } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  ADDRESSING_RULES_TRANSLATE_TYPES,
  ADDRESSING_RULES_TYPES,
} from '../../constants';

interface ActiveRulesCardListProps {
  rules: AddressingConfigurationRuleResponseDto[];
}

function ActiveRulesCardList({ rules }: Readonly<ActiveRulesCardListProps>) {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');

  const primaryRule = rules.find(
    (rule) => rule.priority === 1 && rule.enabled,
  ) as AddressingConfigurationRuleResponseDto;

  const restOfRules = rules.filter(
    (rule) =>
      rule.priority !== 1 &&
      rule.type !== ADDRESSING_RULES_TYPES.materialize &&
      rule.enabled,
  );

  return (
    <Stack spacing="tight">
      <Icon color="success-interactive" source={<CheckCircleIcon />} />
      <Title as="h5" textAlign="left" fontWeight="regular">
        <b>
          {t(
            `types.${ADDRESSING_RULES_TRANSLATE_TYPES[primaryRule.type]}.title`,
            {
              appName: primaryRule.appName,
            },
          )}
        </b>{' '}
        {restOfRules.length === 1 &&
          t('info.andOne', {
            rule: t(
              `types.${
                ADDRESSING_RULES_TRANSLATE_TYPES[restOfRules[0].type]
              }.title`,
              {
                appName: restOfRules[0].appName,
              },
            ),
          })}
        {restOfRules.length > 1 &&
          t('info.andMore', { count: restOfRules.length })}
      </Title>
    </Stack>
  );
}

export default ActiveRulesCardList;
