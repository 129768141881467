import { useEffect, useState } from 'react';
import { Box, Button, Text } from '@nimbus-ds/components';
import { BoxPackedIcon, TruckIcon, InvoiceIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import { ExternalLink, InfoModal } from 'commons/components';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping/useTranslationShipping';
import { trackJadLogWelcomeButton, trackJadLogWelcomeBanner } from './tracking';

const TRANSLATE_PREFIX = 'deliveryMethods.nuvemEnvioJadlogModal';

const content = [
  {
    title: 'deliveryMethods.nuvemEnvioJadlogModal.items.1.title',
    subtitle: 'deliveryMethods.nuvemEnvioJadlogModal.items.1.subtitle',
    icon: <TruckIcon />,
    link: 'deliveryMethods.nuvemEnvioJadlogModal.items.1.link',
    linkPath: 'https://www.jadlog.com.br/siteInstitucional/pickup.jad',
  },
  {
    title: 'deliveryMethods.nuvemEnvioJadlogModal.items.2.title',
    subtitle: 'deliveryMethods.nuvemEnvioJadlogModal.items.2.subtitle',
    icon: <BoxPackedIcon />,
  },
  {
    title: 'deliveryMethods.nuvemEnvioJadlogModal.items.3.title',
    subtitle: 'deliveryMethods.nuvemEnvioJadlogModal.items.3.subtitle',
    icon: <InvoiceIcon />,
  },
];

function NuvemEnvioJadlogModal(): JSX.Element {
  const t = useTranslationShipping();
  const { id: storeId } = useStoreInfo();

  const [isAlreadyLoggedEvent, setIsAlreadyLoggedEvent] = useState(false);

  const { goTo } = useNavegate();

  const handleClick = async () => {
    trackJadLogWelcomeButton(storeId);
    goTo(partnersAppsRoutes.nuvemenvio);
  };

  useEffect(() => {
    if (
      !isAlreadyLoggedEvent &&
      !localStorage.getItem('CapacitorStorage.wasNuvemEnvioJadlogModal')
    ) {
      setIsAlreadyLoggedEvent(true);
      trackJadLogWelcomeBanner(storeId);
    }
  }, [isAlreadyLoggedEvent, storeId]);

  return (
    <InfoModal
      text={t(`${TRANSLATE_PREFIX}.text`)}
      title={t(`${TRANSLATE_PREFIX}.title`)}
      storage="wasNuvemEnvioJadlogModal"
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap="4" flexDirection="column">
          {content.map((item, index) => (
            <Box key={index} display="flex" gap="2">
              <Box
                backgroundColor="neutral-surfaceDisabled"
                borderRadius="full"
                width="32px"
                height="32px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="32px"
              >
                {item.icon}
              </Box>
              <Box>
                <Text fontWeight="bold">{t(`${item.title}`)}</Text>

                <Text fontSize="caption" color="neutral-textDisabled">
                  {t(`${item.subtitle}`)}
                </Text>

                {!!item?.link && (
                  <ExternalLink href={item.linkPath} appearance="primary">
                    {t(`${item.link}`)}
                  </ExternalLink>
                )}
              </Box>
            </Box>
          ))}
          <Box ml="10">
            <Button appearance="primary" onClick={handleClick}>
              {t(`${TRANSLATE_PREFIX}.button`)}
            </Button>
          </Box>
        </Box>
      </Box>
    </InfoModal>
  );
}

export default NuvemEnvioJadlogModal;
