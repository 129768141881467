import { Card, Text, Title, Box } from '@nimbus-ds/components';
import { CalloutCard, DataList } from '@nimbus-ds/patterns';
import { AppsIcon } from '@tiendanube/icons';
import useShowByFeature from 'App/hooks/useShowByFeature';
import { ErrorScreen, Stack } from 'commons/components';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useGetEnabledCountriesListQuery } from 'domains/Configurations/I18n/i18nApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import EnableCountryLink from '../EnableCountryLink';
import ListEnabledCountries from '../ListEnabledCountries';

function EnabledCountriesCard() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const i18nEnabled = useShowByFeature('i18n_enabled');
  const { handleGoToPlans } = useGoToPlans();

  const { data, isFetching, isLoading, isError, refetch } =
    useGetEnabledCountriesListQuery();

  const isSkeleton = (isFetching || isLoading) && !data;

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title as="h3">{t('enabledCountries.title')}</Title>
      </Card.Header>
      <Card.Body padding="base">
        <Stack column align="flex-start">
          <Text fontSize="base" lineHeight="base" textAlign="left">
            {t('enabledCountries.subtitle')}
          </Text>
          {!i18nEnabled && (
            <CalloutCard
              appearance="primary"
              title={t('i18nDisabled.title')}
              subtitle={t('i18nDisabled.subtitle')}
              onClick={handleGoToPlans}
              icon={AppsIcon}
            />
          )}
        </Stack>
      </Card.Body>
      <DataList>
        {i18nEnabled && <EnableCountryLink />}
        {isSkeleton && <ListEnabledCountries.Skeleton />}
        {data && <ListEnabledCountries data={data.results} />}
        {isError && (
          <Box paddingBottom="4">
            <ErrorScreen onRetry={refetch} description={t('errorState')} />
          </Box>
        )}
      </DataList>
    </Card>
  );
}

export default EnabledCountriesCard;
