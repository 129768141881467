import { AbandonedCartDetailsResponseDto } from '@tiendanube/common';
import { Card, Label, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface CardPaymentProps {
  abandonedCart: AbandonedCartDetailsResponseDto;
}

export function CardPayment({ abandonedCart }: Readonly<CardPaymentProps>) {
  const t = useTranslationOrders();

  const gateway = abandonedCart.paymentInfo.gateway ?? '';
  const method = abandonedCart.paymentInfo.gatewayMethod ?? '';
  const installments = abandonedCart.paymentInfo.installments;
  const paymentCount = abandonedCart.paymentInfo.paymentCount;

  const showInstallments = !!installments && installments > 1;

  return (
    <Card title={t('cardPayment.paymentType')}>
      <Stack column align="flex-start" spacing="tight">
        <Text block bold>
          {t(`gateway.${gateway}`, gateway)}
        </Text>
        {method !== '' && <Label id={method} label={method} />}
        {showInstallments && (
          <Text block>{t('cardPayment.installments', { installments })}</Text>
        )}
        {!!paymentCount && (
          <Text block>
            {t('cardPayment.paymentCount', { count: paymentCount })}
          </Text>
        )}
      </Stack>
    </Card>
  );
}
