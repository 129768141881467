import {
  CategoryResponseDto,
  CategoryCreateRequestDto,
  CategoryTreeResponseDto,
  CategorySyncTreeRequestDto,
  CategoryUpdateRequestDto,
  CategoryDetailsResponseDto,
  CategoryTabedResponseDto,
  CategoryUploadImageResponseDto,
  GenerateCategorySeoRequestDto,
  GenerateCategorySeoResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';

export type GetCategoriesByIdType = (
  id: string,
) => Promise<CategoryDetailsResponseDto>;

type GetCategoriesType = () => Promise<CategoryResponseDto[]>;

type GetOptionsType = (prefix?: boolean) => Promise<CategoryTabedResponseDto[]>;
type GetTreeType = () => Promise<CategoryTreeResponseDto[]>;

type CreateCategoryType = (
  category: CategoryCreateRequestDto,
) => Promise<CategoryResponseDto>;

interface UpdateCategoryInterface {
  id: string;
  category: CategoryUpdateRequestDto;
}

type UpdateCategoryType = (
  data: UpdateCategoryInterface,
) => Promise<CategoryDetailsResponseDto>;

type DeleteCategoryType = (id: string) => Promise<void>;

type SyncTreeType = (
  categories: CategorySyncTreeRequestDto[],
) => Promise<CategoryTreeResponseDto[]>;

type UploadImageType = (file: File) => Promise<CategoryUploadImageResponseDto>;

type GenerateCategorySeoType = (
  requestDto: GenerateCategorySeoRequestDto,
) => Promise<GenerateCategorySeoResponseDto>;

const URL = `v1/categories`;

const getCategories: GetCategoriesType = async () => {
  const { data } = await axios.get<CategoryResponseDto[]>(URL);
  return data;
};

const getCategoryById: GetCategoriesByIdType = async (id) => {
  const response = await axios.get(`${URL}/${id}`);
  return response.data;
};

const getOptions: GetOptionsType = async () => {
  const { data } = await axios.get<CategoryTabedResponseDto[]>(
    `${URL}/options`,
  );
  return data;
};

const getTree: GetTreeType = async () => {
  const { data } = await axios.get<CategoryTreeResponseDto[]>(`${URL}/tree`);
  return data;
};

const createCategory: CreateCategoryType = async (category) => {
  const { data } = await axios.post<CategoryResponseDto>(URL, category);
  return data;
};

const updateCategory: UpdateCategoryType = async ({ id, category }) => {
  const { data } = await axios.patch<CategoryDetailsResponseDto>(
    `${URL}/${id}`,
    category,
  );
  return data;
};

const deleteCategory: DeleteCategoryType = async (id) => {
  await axios.delete<void>(`${URL}/${id}`);
};

const syncTree: SyncTreeType = async (categories) => {
  const { data } = await axios.post<CategoryTreeResponseDto[]>(
    `${URL}/sync-tree`,
    categories,
  );

  return data;
};

const uploadImageCategory = async (
  file: File,
): Promise<CategoryUploadImageResponseDto> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const response = await axios.post<CategoryUploadImageResponseDto>(
    `${URL}/image`,
    formData,
  );
  return response.data;
};

const generateCategorySeo: (
  requestDto: GenerateCategorySeoRequestDto,
) => Promise<GenerateCategorySeoResponseDto> = async (requestDto) => {
  const response = await axios.post(`${URL}/generate/seo`, requestDto);
  return response.data;
};

interface CategoriesServicesInterface {
  getCategories: GetCategoriesType;
  getCategoryById: GetCategoriesByIdType;
  getOptions: GetOptionsType;
  getTree: GetTreeType;
  createCategory: CreateCategoryType;
  updateCategory: UpdateCategoryType;
  deleteCategory: DeleteCategoryType;
  syncTree: SyncTreeType;
  uploadImageCategory: UploadImageType;
  generateCategorySeo: GenerateCategorySeoType;
}

const categoriesServices: CategoriesServicesInterface = {
  getCategories,
  getCategoryById,
  getOptions,
  getTree,
  createCategory,
  updateCategory,
  deleteCategory,
  syncTree,
  uploadImageCategory,
  generateCategorySeo,
};

export default categoriesServices;
