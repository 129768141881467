import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';
import { ToastProgress } from '@tiendanube/components';

export interface InterfaceToastContext {
  addToastProgress: (a: { label: string; number?: number }) => void;
  closeToastProgress: () => void;
}

export function useToastProgress(): InterfaceToastContext {
  return useContext(ToastProgressContext);
}

export const ToastProgressContext = createContext<InterfaceToastContext>(
  {} as InterfaceToastContext,
);

interface InterfaceToastProgressContextProvider {
  children: ReactNode;
}

interface ToastStateProps {
  show: boolean;
  label: string;
  number?: number;
}
const initialState: ToastStateProps = {
  show: false,
  label: '',
  number: undefined,
};

function ToastProgressContextProvider({
  children,
}: InterfaceToastProgressContextProvider): JSX.Element {
  const [toast, setToast] = useState<ToastStateProps>(initialState);

  const addToastProgress = useCallback(
    ({ label, number }: { label: string; number?: number }) => {
      setToast({ show: true, label, number });
    },
    [],
  );

  const closeToastProgress = useCallback(() => {
    setToast(initialState);
  }, []);

  return (
    <ToastProgressContext.Provider
      value={{ addToastProgress, closeToastProgress }}
    >
      {children}
      <ToastProgress
        show={toast.show}
        percentage={toast.number}
        label={toast.label}
      />
    </ToastProgressContext.Provider>
  );
}

export default ToastProgressContextProvider;
