import * as Yup from 'yup';

export const validationSchema = (lang: string) =>
  Yup.object().shape({
    title: Yup.object().shape({
      [lang]: Yup.string().required('validation.title'),
    }),
    content: Yup.object().shape({
      [lang]: Yup.string().required('validation.content'),
    }),
  });
