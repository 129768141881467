import { useEffect, useState } from 'react';
import { Accordion, Box, Icon, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import ModalAside from 'App/components/lab/ModalAside';
import {
  SubmitButton,
  HeaderContent,
  HeaderTop,
  Stack,
  ExternalLink,
  ErrorScreen,
} from 'commons/components';
import {
  useGetDomainConfigQuery,
  useGetHostnameDnsValuesQuery,
} from 'domains/Configurations/Domains/domainsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { ARecordVerification } from './ARecordVerification';
import { CNAMERecordVerification } from './CNAMERecordVerification';
import './DNSVerificationModal.scss';

interface DNSVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSubdomain: boolean;
  domainId: string;
  domainName: string;
  ourDomain: string;
}

export function DNSVerificationModal({
  isOpen,
  onClose,
  isSubdomain,
  domainId,
  domainName,
  ourDomain,
}: Readonly<DNSVerificationModalProps>) {
  const t = useTranslationConfigurations('domains.attachModal');
  const {
    data: dnsValues,
    isLoading: isLoadingDns,
    isFetching,
    isError: isErrorDns,
    refetch: refetchDnsValues,
  } = useGetHostnameDnsValuesQuery({ hostname: domainName }, { skip: !isOpen });
  const [expectedIps, setExpectedIps] = useState<string[]>([]);
  const {
    data,
    isLoading: isLoadingConfig,
    isError: isErrorConfig,
    refetch: refetchConfig,
  } = useGetDomainConfigQuery(domainId, { skip: !isOpen });
  const response = dnsValues
    ? dnsValues
    : { a: [], cname: '', unexpectedCname: '' };
  const isLoading = isLoadingConfig || isLoadingDns;
  const isError = isErrorConfig || isErrorDns;

  const refetch = () => {
    refetchConfig();
    refetchDnsValues();
  };

  useEffect(() => {
    if (!isLoadingConfig && data) {
      setExpectedIps(data.cloudflareIps);
    }
  }, [data, isLoadingConfig]);

  const showAError =
    !isSubdomain &&
    (response.a.length === 0 ||
      response.a.length < expectedIps.length ||
      !response.a.every((ip) => expectedIps.includes(ip)));

  const showCnameError =
    !response.cname || response.cname !== ourDomain || response.unexpectedCname;

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('verificationModal.title')} />}
    >
      <Text>{domainName}</Text>
      {isError ? (
        <Box padding="4">
          <ErrorScreen
            description={t('verificationModal.error')}
            onRetry={refetch}
          />
        </Box>
      ) : isLoading || isFetching ? (
        <Stack column align="stretch">
          <Text.Skeleton width="full" />
          <Text.Skeleton width="full" />
          <Text.Skeleton width="full" />
        </Stack>
      ) : (
        <div className="stratus--dns-verification-modal">
          <Accordion selectedDefault={showAError ? '0' : '1'}>
            {showAError && !isFetching && (
              <ARecordVerification
                currentARecords={response.a}
                expectedIps={expectedIps}
              />
            )}
            {!!showCnameError && !isFetching && (
              <CNAMERecordVerification
                currentCNAMERecord={response.cname}
                unexpectedCNAMERecord={response.unexpectedCname}
                domainName={domainName}
                ourDomain={ourDomain}
              />
            )}
            <Accordion.Item index="2">
              <Accordion.Header
                title={t('verificationModal.wait.title')}
                borderBottom="base"
                icon={<Icon source={<InfoCircleIcon size="medium" />} />}
              />
              <Accordion.Body>
                <Stack column align="stretch">
                  <Text>{t('verification.text')}</Text>
                  <SubmitButton onClick={refetchDnsValues}>
                    {t('verificationModal.wait.submit')}
                  </SubmitButton>
                  <ExternalLink
                    appearance="primary"
                    textDecoration="none"
                    href={t('verification.helpLink.href')}
                  >
                    {t('verification.helpLink.title')}
                  </ExternalLink>
                </Stack>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </ModalAside>
  );
}
