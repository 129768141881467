/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Checkbox, Spinner, Title } from '@nimbus-ds/components';
import { useParams } from 'react-router';
import {
  OcaLabelCostPreviewResponseDto,
  OcaCreateLabelRequestDto,
  OrderDetailsForOcaLabelResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Stack,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import AlertIsOverwritingOcaLabels from './components/AlertIsOverwritingOcaLabels';
import OcaLabelSettingCard from './components/OcaLabelSettingCard';
import OcaLabelSettingMassiveModal from './components/OcaLabelSettingMassiveModal';
import PrePrintOcaActionsDesktop from './components/PrePrintOcaActionsDesktop';
import PrePrintOcaLabelPageError from './components/PrePrintOcaLabelPageError';
import useOcaCardsInfo from './hooks/useOcaCardsInfo';
import useOcaLabelsCostsPreviews from './hooks/useOcaLabelsCostsPreviews';
import {
  defineInitialValues,
  formatCost,
  generateMassiveLabelsByOneLabel,
  mergeCostsPreviewList,
  mergeLabelsList,
} from './utils/utils';

const INITIAL_VALUES: OcaCreateLabelRequestDto[] = [];

function PrePrintOcaLabelPage() {
  const t = useTranslationFulfillmentOrders('ocaPrePrint');
  const selectedIds = useParams<{ ids: string }>().ids?.split(',') || [];
  const isOverwriting = !!useParams<{ overwrite: string }>().overwrite;
  const { goBack } = useNavegate();
  const {
    ordersDetails,
    isLoadingCardsInfo,
    isErrorCardsInfo,
    isSuccessCardsInfo,
    getCardsInfo,
  } = useOcaCardsInfo();
  const { generateCostsPreviews } = useOcaLabelsCostsPreviews();
  const [labels, setLabels] = useState(INITIAL_VALUES);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [showModal, openModal, closeModal] = useModal();
  const [isLoadingCostsPreviews, setIsLoadingCostsPreviews] = useState(false);
  const [costsPreviews, setCostsPreviews] = useState<
    OcaLabelCostPreviewResponseDto[]
  >([]);

  const handleUpdateCostsPreviews = (
    newCostsPreviews: OcaLabelCostPreviewResponseDto[],
  ) => {
    setCostsPreviews(mergeCostsPreviewList(newCostsPreviews, costsPreviews));
  };

  const totalLabelsCost = costsPreviews.reduce(
    (accumulator, currentItem) => accumulator + currentItem.cost.value,
    0,
  );
  const formattedTotalLabelsCost = `$ ${
    costsPreviews.length > 0 ? formatCost(totalLabelsCost) : '--,--'
  }`;

  const handleOnChangeLabel = (label: OcaCreateLabelRequestDto) => {
    const externalId = label.externalId;
    const newLabels = labels.filter((label) => label.externalId !== externalId);
    newLabels.push(label);
    setLabels(newLabels);
  };

  const handleOnChangeSelectedLabelsByLabel = async (
    label: Omit<OcaCreateLabelRequestDto, 'externalId'>,
  ) => {
    const newSelectedLabels = generateMassiveLabelsByOneLabel(
      selectedOrders,
      label,
      isOverwriting,
    );
    const newLabels = mergeLabelsList(newSelectedLabels, labels);
    setLabels(newLabels);
    setIsLoadingCostsPreviews(true);
    await generateCostsPreviews(newLabels).then((costsPreviews) => {
      handleUpdateCostsPreviews(costsPreviews);
      setIsLoadingCostsPreviews(false);
    });
  };

  const handleOnSelectLabel = (externalId: string) => {
    const index = selectedOrders.indexOf(externalId);
    const newSelectedLabels =
      index === -1
        ? [...selectedOrders, externalId]
        : selectedOrders.filter(
            (selectedOrder) => selectedOrder !== externalId,
          );
    setSelectedOrders(newSelectedLabels);
  };

  const handleSelectAllLabels = () => {
    if (selectedOrders.length !== labels.length) {
      const newSelectedLabels = labels.map((label) => label.externalId);
      setSelectedOrders(newSelectedLabels);
      openModal();
      return;
    }
    setSelectedOrders([]);
  };

  useEffect(() => {
    if (labels.length === 0) {
      getCardsInfo(selectedIds, isOverwriting).then((cardsInfo) => {
        const initialLabels = defineInitialValues(
          cardsInfo.ordersDetails,
          cardsInfo.documentSettings,
          isOverwriting,
        );
        setLabels(initialLabels);
      });
    }
  }, []);

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          navigation={{
            onClick: goBack,
          }}
        />
      }
      headerContent={
        <HeaderContent
          title={isOverwriting ? t('titleRegenerate') : t('title')}
          subtitle={t('subtitle')}
          actions={
            <PrePrintOcaActionsDesktop
              selectedIds={selectedIds}
              selectedOrders={selectedOrders}
              isError={isErrorCardsInfo}
              isLoading={isLoadingCardsInfo}
              doNotHasAvailableOrders={
                !isOverwriting &&
                isSuccessCardsInfo &&
                ordersDetails.length === 0
              }
              labels={labels}
              openModal={openModal}
            />
          }
        />
      }
    >
      {isErrorCardsInfo && (
        <PrePrintOcaLabelPageError
          onRetryError={() => getCardsInfo(selectedIds, isOverwriting)}
        />
      )}

      {!!isOverwriting && !isErrorCardsInfo && (
        <AlertIsOverwritingOcaLabels
          isUnique={labels.length === 1}
          totalCost={formattedTotalLabelsCost}
        />
      )}

      <Stack column align="stretch" justify="center">
        {isLoadingCardsInfo && !isErrorCardsInfo && (
          <Stack justify="space-between">
            <Title.Skeleton as="h5" width="25%" />
            <Checkbox.Skeleton />
          </Stack>
        )}

        {isSuccessCardsInfo && !isErrorCardsInfo && labels.length > 1 && (
          <Stack justify="space-between">
            <Title as="h5">
              {t('modal.totalCost')}{' '}
              {!isLoadingCostsPreviews && formattedTotalLabelsCost}
              {isLoadingCostsPreviews && <Spinner size="small" />}
            </Title>
            <Checkbox
              name="all"
              checked={labels.length === selectedOrders.length}
              label={t('selectAll')}
              onChange={() => handleSelectAllLabels()}
            />
          </Stack>
        )}

        {!isErrorCardsInfo && isLoadingCardsInfo && labels.length === 0 && (
          <Stack align="stretch" justify="space-between" wrap>
            <OcaLabelSettingCard.Skeleton />
            <OcaLabelSettingCard.Skeleton />
          </Stack>
        )}

        {!isErrorCardsInfo && !isLoadingCardsInfo && labels.length > 0 && (
          <Stack align="stretch" justify="space-between" wrap>
            {labels
              .sort((a, b) => parseInt(a.externalId) - parseInt(b.externalId))
              .map((label) => {
                const selected = !!selectedOrders.find(
                  (selectedOrder) => selectedOrder === label.externalId,
                );
                const order = ordersDetails.find(
                  (order) => order.externalId === label.externalId,
                ) as OrderDetailsForOcaLabelResponseDto;
                const costPreview = costsPreviews.find(
                  (costPreview) => costPreview.externalId === label.externalId,
                );
                return (
                  <OcaLabelSettingCard
                    key={label.externalId}
                    selected={selected}
                    order={order}
                    label={label}
                    costPreview={costPreview}
                    isLoadingCostPreviewByHandle={
                      isLoadingCostsPreviews && selected
                    }
                    isUnique={labels.length === 1}
                    onChangeLabel={handleOnChangeLabel}
                    onSelectLabel={handleOnSelectLabel}
                    updateCostsPreviews={handleUpdateCostsPreviews}
                  />
                );
              })}
          </Stack>
        )}
      </Stack>

      {showModal && (
        <OcaLabelSettingMassiveModal
          selectedOrders={selectedOrders}
          isOverwriting={isOverwriting}
          closeModal={closeModal}
          onChangeSelectedLabels={handleOnChangeSelectedLabelsByLabel}
        />
      )}
    </IonPageStratus>
  );
}

export default PrePrintOcaLabelPage;
