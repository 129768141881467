import { RequestValidator } from 'App/axiosUtils/requestValidation';
import { USER_PASSWORD_CHANGE_REQUIRED_ENABLED } from 'App/featuresFlags';
import { useGetTags, useIsLogged, useIsMobileDevice } from 'domains/Auth/hooks';
import Amplitude from './Amplitude';
import Appcues from './Appcues';
import GoogleAnalytics from './GoogleAnalytics';
import RequirePasswordReset from './RequirePasswordReset';
import { Satismeter } from './Satismeter';
import UserGuiding from './UserGuiding';

function ExternalServices() {
  const { isLogin } = useIsLogged();
  const tags = useGetTags();
  const isMobile = useIsMobileDevice();
  const hasRequirePasswordResetTag = tags.includes(
    USER_PASSWORD_CHANGE_REQUIRED_ENABLED,
  );

  return (
    <>
      {isLogin && <Amplitude />}
      {isLogin && !isMobile && <Appcues />}
      {isLogin && <UserGuiding />}
      {isLogin && hasRequirePasswordResetTag && <RequirePasswordReset />}
      <RequestValidator />
      <GoogleAnalytics />
      {isLogin && <Satismeter />}
    </>
  );
}

export default ExternalServices;
