import { useSelector } from 'react-redux';
import { CustomersResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getCustomersListItemById } from 'domains/Customers/Customers/customersSlice/customersSelectors';

interface UseCustomerListItemInterface {
  customer: CustomersResponseDto;
}

function useCustomerListItem(id: string): UseCustomerListItemInterface {
  const customerListItem = useSelector((state: RootStateType) =>
    getCustomersListItemById(state, id),
  );

  return { customer: customerListItem };
}

export default useCustomerListItem;
