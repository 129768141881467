import * as Yup from 'yup';

const isValidUrl =
  /(https?!:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/;

export const socialNetworksSchema = Yup.object().shape({
  instagram: Yup.string().nullable(),
  instagramToken: Yup.string().nullable(),
  facebook: Yup.string()
    .matches(isValidUrl, 'socialNetworksPage.invalidUrl')
    .nullable(),
  youtube: Yup.string()
    .matches(isValidUrl, 'socialNetworksPage.invalidUrl')
    .nullable(),
  tiktok: Yup.string().nullable(),
  twitter: Yup.string().nullable(),
  pinterest: Yup.string()
    .matches(isValidUrl, 'socialNetworksPage.invalidUrl')
    .nullable(),
  pinterestMeta: Yup.string().nullable(),
  blog: Yup.string()
    .matches(isValidUrl, 'socialNetworksPage.invalidUrl')
    .nullable(),
});
