import { useCallback, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Alert, Text, Button } from '@nimbus-ds/components';
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings';
import useAppStateChange from 'App/useAppStateChange';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import './ActivatePushNotificationsAlert.scss';

const isNativePlatform = Capacitor.isNativePlatform();
const isAndroid = Capacitor.getPlatform() === 'android';
const isIOs = Capacitor.getPlatform() === 'ios';

export function ActivatePushNotificationsAlert() {
  const t = useTranslationDashboard('activatePushNotificationsAlert');
  const [showAlert, setShowAlert] = useState(false);

  const checkNotificationsStatus = useCallback(async () => {
    if (!isNativePlatform) return;
    const notificationsStatus = await LocalNotifications.checkPermissions();
    setShowAlert(notificationsStatus.display !== 'granted' && isNativePlatform);
  }, []);

  const closeAlert = () => setShowAlert(false);

  useAppStateChange(checkNotificationsStatus);

  if (!isNativePlatform) return null;

  const handleAndroidOpenConfigurations = async () => {
    if (isAndroid) {
      await NativeSettings.openAndroid({
        option: AndroidSettings.AppNotification,
      });
    }

    if (isIOs) {
      await NativeSettings.openIOS({
        option: IOSSettings.App,
      });
    }
  };

  return (
    <div className="stratus--activate-push-notifications-alert">
      <Alert
        show={showAlert}
        appearance="warning"
        title={t('title')}
        onRemove={closeAlert}
      >
        <Text>{t('text')}</Text>
        <Button onClick={handleAndroidOpenConfigurations}>{t('btn')}</Button>
      </Alert>
    </div>
  );
}
