import differenceInBusinessDays from 'date-fns/differenceInBusinessDays';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18next';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';

const DATE_FILTER_DAYS_LIMIT = 90;

dayjs.extend(utc);
dayjs.extend(timezone);

export type DateType =
  | 'day'
  | 'week'
  | 'month'
  | 'year'
  | 'hour'
  | 'minute'
  | 'second'
  | 'millisecond';

export const getTimeFromString = (value: string): number => {
  const ua = navigator.userAgent.toLowerCase();
  const date = /firefox/.test(ua)
    ? value
    : value.replace(/-/g, '/').replace(/T/g, ' ');
  return new Date(date).getTime();
};

export const dateFormat = (
  value: string,
  format: Format,
  tz?: string,
): string => {
  dayjs.locale(i18n.language.split('-')[0]);
  return dayjs(getTimeFromString(value))
    .tz(tz ?? dayjs.tz.guess())
    .format(format);
};

export const subtractDays = (value: string, days: number): string =>
  dayjs(getTimeFromString(value))
    .tz(dayjs.tz.guess())
    .subtract(days, 'day')
    .format('YYYY-MM-DD');

export const getTodayIsoString = (): string =>
  new Date().toISOString().substring(0, 10);

export const addMounthsToday = (months: number): string =>
  dayjs().add(months, 'month').format('YYYY-MM-DD');

export const addDates = (date: string, unit: number, type: DateType) =>
  dayjs(date).add(unit, type).format('YYYY-MM-DD');

export const getDifference = (date: string, dateToCompare: string): number =>
  dayjs(date).diff(dateToCompare, 'days');

export const ninetyDaysAgo = subtractDays(
  getTodayIsoString(),
  DATE_FILTER_DAYS_LIMIT,
);

export const parseDateFrom = (date: string, timezone: string) =>
  dayjs(date, 'YYYY-MM-DD')
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .tz(timezone, true)
    .tz('UTC')
    .format(Format.ISO_8601);

export const parseDateTo = (date: string, timezone: string) =>
  dayjs(date, 'YYYY-MM-DD')
    .hour(23)
    .minute(59)
    .second(59)
    .millisecond(999)
    .tz(timezone, true)
    .tz('UTC')
    .format(Format.ISO_8601);

//MORE FORMATS https://day.js.org/docs/en/parse/string-format
export enum Format {
  HOUR_MINUTE = 'HH:mm',
  HOUR_MINUTE_LONG = 'D [de] MMMM, HH:mm',
  HOUR_MINUTE_XS = 'HH:mm', //NOSONAR We are setting the same value as the default to keep the standard keys
  DAY_MONTH = 'D MMM',
  DAY_MONTH_LONG = 'D [de] MMMM',
  DAY_MONTH_XS = 'DD MMM',
  DAY_MONTH_YEAR = 'D [de] MMMM [de] YYYY',
  DAY_MONTH_YEAR_NUMBER = 'DD-MM-YYYY',
  DD_MM_YYYY = 'DD/MM/YYYY',
  DD_MM_YY = 'DD/MM/YY',
  MONTH_YEAR = 'MMM - YYYY',
  MONTH_YEAR_LONG = 'MMMM [de] YYYY',
  MONTH_YEAR_XS = 'MMM YYYY',
  YEAR = 'YYYY',
  YEAR_LONG = 'D [de] MMMM [de] YYYY', //NOSONAR We are setting the same value as the default to keep the standard keys
  YEAR_XS = 'YYYY', //NOSONAR We are setting the same value as the default to keep the standard keys
  ISO_8601 = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  DAY_HOUR_MINUTE = 'D [de] MMMM [de] YYYY - HH:mm',
}

export const calculateDateRange = (
  startDate: string | null,
  minDate: string | null,
  maxDate: string | null,
) => {
  const createdAtDay = dayjs(startDate?.split('T')[0]).toDate();

  let minDays = 0;
  let maxDays = 0;

  if (minDate) {
    const minDeliveryDay = dayjs(minDate.split('T')[0]).toDate();
    // We need to include the end date and exclude the start date, and +0 will cancel out negative zeroes
    minDays = differenceInBusinessDays(createdAtDay, minDeliveryDay) * -1 + 0;
  }

  if (maxDate) {
    const maxDeliveryDay = dayjs(maxDate.split('T')[0]).toDate();
    maxDays = differenceInBusinessDays(createdAtDay, maxDeliveryDay) * -1 + 0;
  }

  return {
    min_days: minDays,
    max_days: maxDays,
    min_delivery_date: minDate,
    max_delivery_date: maxDate,
  };
};
