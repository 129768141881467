import { Icon, Table, Text, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { TRANSLATE_PAYMENT_PREFIX } from 'domains/Payments/PaymentProviders/components/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

export function PaymentMethodsHeader() {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);
  return (
    <Table.Head>
      <Table.Row>
        <Table.Cell width="20rem">
          <Text fontSize="caption">{t('table.head.provider')}</Text>
        </Table.Cell>
        <Table.Cell>
          <Text fontSize="caption">{t('table.head.term')}</Text>
        </Table.Cell>
        <Table.Cell width="10rem">
          <Text fontSize="caption">{t('table.head.fee')}</Text>
        </Table.Cell>
        <Table.Cell>
          <Stack spacing="tight">
            <Text fontSize="caption">{t('table.head.cpt')}</Text>
            <Tooltip content={t('table.head.cptDetail')} position="top">
              <Icon
                color="primary-interactive"
                source={<InfoCircleIcon size="small" />}
              />
            </Tooltip>
          </Stack>
        </Table.Cell>
      </Table.Row>
    </Table.Head>
  );
}
