import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { CorreiosOptionsResponseDto } from '@tiendanube/common';
import { Title, Text, Stack } from '@tiendanube/components';
import { CheckCircleIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import FreeShippingAlert from './components/FreeShippingAlert';
import {
  useUpdateCorreiosShippingModalities,
  useGetCorreiosDetail,
} from '../../hooks';
import { SHIPPING_METHODS_BASE_ROUTE } from '../../shippingRoutes';
import ShippingModalityCard from '../components/ShippingModalityCard';

export interface ShippingModalitiesCorreiosValues {
  [key: string]: CorreiosOptionsResponseDto;
}

const EMPTY_STATE = {};

function ShippingModalitiesCorreiosPage(): JSX.Element {
  const { goBack, goTo } = useNavegate();
  const t = useTranslationShipping();
  const isMobile = useIsMobileDevice();

  const { status: updateStatus, updateCorreiosShippingModalities } =
    useUpdateCorreiosShippingModalities();

  const { isLoading, isError, currentShippingModalities, fetchCorreiosDetail } =
    useGetCorreiosDetail();

  const { values, handleChangeGroup, handleOnSubmit, isDirty } = useForm<
    ShippingModalitiesCorreiosValues,
    Record<string, string>
  >({
    initialValues: currentShippingModalities ?? EMPTY_STATE,
    onSubmit: (values) => {
      const body = Object.values(values);
      updateCorreiosShippingModalities(body);
    },
  });

  useEffect(() => {
    fetchCorreiosDetail();
  }, [fetchCorreiosDetail]);

  useToastStatus({
    status: updateStatus,
    error: t('correios.errorToast'),
    success: t('correios.successToast'),
    onSuccess: () => goTo(SHIPPING_METHODS_BASE_ROUTE),
  });

  const backNavigation = {
    children: t('correios.headerBack'),
    onClick: goBack,
  };

  const headerAction = {
    onClick: handleOnSubmit,
    icon: CheckCircleIcon,
  };

  const showFreeShippingAlert =
    !isMobile && Object.values(values).some((modality) => modality.isEnabled);

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop navigation={backNavigation} mainAction={headerAction} />
      }
      headerContent={<HeaderContent title={t('correios.headerTitle')} />}
    >
      <Prompt
        when={isDirty && updateStatus !== 'success'}
        message={t('common:exitEdit.info')}
      />
      <Stack column align="stretch" spacing="base">
        <Title type="h3">{`${t('shippingModalities.title')}`}</Title>
        <Text>{t('shippingModalities.subtitle')}</Text>
        {isError && (
          <ErrorScreen
            onRetry={fetchCorreiosDetail}
            description={t('genericError.text')}
          />
        )}
        {isLoading && (
          <>
            <ShippingModalityCard.Skeleton />
            <CancelAndSaveButtons.Skeleton />
          </>
        )}
        {!isError && !isLoading && (
          <>
            {showFreeShippingAlert && <FreeShippingAlert />}
            {Object.keys(values).length > 0 &&
              currentShippingModalities &&
              Object.keys(currentShippingModalities).map((modalityCode) => (
                <ShippingModalityCard
                  key={modalityCode}
                  code={modalityCode}
                  name={values[modalityCode]?.optionName}
                  additionalCost={values[modalityCode]?.additionalCost ?? ''}
                  additionalDays={values[modalityCode]?.additionalDays ?? ''}
                  isEnabled={values[modalityCode]?.isEnabled}
                  allowedFreeShipping={
                    values[modalityCode]?.allowedFreeShipping
                  }
                  onChange={handleChangeGroup}
                />
              ))}
            <CancelAndSaveButtons
              onCancel={goBack}
              onSave={handleOnSubmit}
              saveText={t('shippingModalities.saveText')}
              isLoading={updateStatus === 'loading'}
            />
          </>
        )}
      </Stack>
    </IonPageStratus>
  );
}

export default ShippingModalitiesCorreiosPage;
