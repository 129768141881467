import { Box, Icon, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';

interface StepAlertDomainProps {
  title: string;
}

export function StepAlertDomain({ title }: Readonly<StepAlertDomainProps>) {
  return (
    <Box display="flex" gap="2" alignItems="flex-start">
      <Box marginTop="0-5">
        <Icon
          color="primary-textLow"
          source={<InfoCircleIcon size="medium" />}
        />
      </Box>
      <Box display="flex" flexDirection="column" alignSelf="center">
        <Text color="primary-textLow">{title}</Text>
      </Box>
    </Box>
  );
}
