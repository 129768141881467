import { Radio } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function Skeleton() {
  return (
    <Stack>
      <Radio.Skeleton as="button" />
      <Radio.Skeleton as="button" />
      <Radio.Skeleton as="button" />
    </Stack>
  );
}

export default Skeleton;
