import { DrawableCanvas } from 'commons/components';
import { useGetImageDimensions } from 'commons/hooks';
import useScaleDimensionsToFit from '../hooks/useScaleDimensionsToFit';

interface DrawableImageProps {
  src: string;
  maxWidth: number;
  maxHeight: number;
  canvasRef: any;
  disabled?: boolean;
  onDraw?: (isEmpty: boolean) => void;
}

function DrawableImage({
  maxWidth,
  maxHeight,
  src,
  canvasRef,
  disabled = false,
  onDraw,
}: Readonly<DrawableImageProps>) {
  const { dimensionsReady, width, height } = useGetImageDimensions(src);
  const { scaleToFit } = useScaleDimensionsToFit(maxWidth, maxHeight);

  if (!dimensionsReady) {
    return null;
  }

  const [scaledWidth, scaledHeight] = scaleToFit(width, height);

  return (
    <DrawableCanvas
      ref={canvasRef}
      backgroundImageSrc={src}
      width={scaledWidth}
      height={scaledHeight}
      brushRadius={10}
      brushColor="rgba(0,255,0,0.33)"
      disabled={disabled}
      onDraw={onDraw}
    />
  );
}

export default DrawableImage;
