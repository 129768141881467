import { Link } from '@nimbus-ds/components';
import { PaymentProviderDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useProvidersActionsItems } from 'domains/Payments/PaymentProviders/hooks';
import { OnPaymentProviderActionType } from '../../PaymentProviderActionModal';

interface PaymentProviderProps {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
}

export function ProvidersActions({
  paymentProvider,
  onAction,
}: Readonly<PaymentProviderProps>) {
  const { getLeftLink, getRightLink, handleGoToMainPage } =
    useProvidersActionsItems({ paymentProvider, onAction });

  return (
    <Stack column>
      <Stack alignSelf="flex-start">
        <Link
          onClick={handleGoToMainPage}
          appearance="primary"
          textDecoration="none"
        >
          {getLeftLink()}
        </Link>
      </Stack>
      <Stack alignSelf="flex-end">{getRightLink()}</Stack>
    </Stack>
  );
}
