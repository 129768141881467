import { ImageItemStratus } from 'commons/components';
import { useShowNoWeightAndDimension } from 'domains/Catalog/Products/pages/hooks';
import { VariantListItemInterface } from '../../../../../CardVariants';
import VariantMissingDataTag from '../../../VariantMissingDataTag';

export interface VisibleVariantInfoDesktopProps {
  variant: VariantListItemInterface;
  index: number;
  isDigital: boolean;
  onClickImage: (index: number) => void;
  onClickVariant: (index: number) => void;
}

function VisibleVariantInfoDesktop({
  variant: { id: variantId, weight, depth, width, height, name, imageUrl },
  index,
  isDigital,
  onClickImage,
  onClickVariant,
}: VisibleVariantInfoDesktopProps) {
  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight,
    depth,
    width,
    height,
  });

  return (
    <ImageItemStratus
      title={name}
      id={variantId}
      imageUrl={imageUrl}
      onClickImage={() => onClickImage(index)}
      onClickTitle={() => onClickVariant(index)}
    >
      {showNoWeightAndDimension && !isDigital && <VariantMissingDataTag />}
    </ImageItemStratus>
  );
}

export default VisibleVariantInfoDesktop;
