import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Input, InterfaceNameValue, Stack } from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { CATALOG_EXTENDED_CATEGORY_DESCRIPTION } from 'App/featuresFlags';
import { DEFAULT_LANGUAGE, LanguageInterface, LanguagesType } from 'App/i18n';
import useGetTags from 'domains/Auth/hooks/useGetTags';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingCategoriesDescriptionOnBlur } from 'domains/Catalog/Products/tracking';

interface NameValuesi18n {
  name: string;
  value: LanguageInterface;
}
export interface FormNameAndDescriptionProps {
  name: LanguageInterface;
  description: LanguageInterface;
  onChange: (data: NameValuesi18n) => void;
  language?: LanguagesType;
  errors: Partial<Record<string, string>>;
}

const EXTENDED_LENGTH = 5000;
const STANDARD_LENGTH = 140;

const EXTENDED_LENGTH_ROWS = 12;
const STANDARD_LENGTH_ROWS = 3;

function FormNameAndDescription({
  name,
  errors,
  description,
  onChange,
  language = DEFAULT_LANGUAGE,
}: FormNameAndDescriptionProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const { scrollToError } = useLayoutScroll();

  const tags = useGetTags();
  const sender = useTrackFullCatalog();
  const { categoryDescriptionLength, categoryDescriptionRows } =
    setParametersForDescriptionLength(tags);

  const hasError = !!errors[`name.${language}`];

  const handleChangeName = (data: InterfaceNameValue) => {
    onChange({
      name: data.name,
      value: { ...name, [language]: data.value },
    });
  };

  const handleChangeDescription = (data: InterfaceNameValue) => {
    onChange({
      name: data.name,
      value: { ...description, [language]: data.value },
    });
  };

  function handleBlurDescription(): void {
    sender(() => {
      trackingCategoriesDescriptionOnBlur(isDescriptionExtended(tags));
    });
  }

  const errorName = errors[`name.${language}`];
  const appearanceName = errorName ? 'validation_error' : undefined;
  const helpTextName = errorName ? t(errorName) : '';

  const errorDescription = errors[`description.${language}`];

  const appearanceDescription = errorDescription
    ? 'validation_error'
    : undefined;

  const helperTextDescription = errorDescription
    ? t(errorDescription)
    : t('categories.detail.description.helperText', {
        count: description[language]?.length || 0,
        max: categoryDescriptionLength,
      });

  useEffect(() => {
    if (hasError) scrollToError();
  }, [hasError, scrollToError]);

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <Input
          type="text"
          label={t('categories.detail.name.label')}
          placeholder={t('categories.detail.name.placeholder')}
          onChange={handleChangeName}
          name="name"
          value={name[language]}
          appearance={appearanceName}
          helpText={helpTextName}
        />
      </Stack.Item>
      <Stack.Item>
        <Input
          type="text"
          label={t('categories.detail.description.label')}
          placeholder={t('categories.detail.description.placeholder')}
          onChange={handleChangeDescription}
          name="description"
          value={description[language]}
          rows={categoryDescriptionRows}
          multiRows
          maxLength={categoryDescriptionLength}
          appearance={appearanceDescription}
          helpText={helperTextDescription}
          onBlur={handleBlurDescription}
        />
      </Stack.Item>
    </Stack>
  );
}

export default FormNameAndDescription;

function isDescriptionExtended(tags: string[]): boolean {
  return tags.includes(CATALOG_EXTENDED_CATEGORY_DESCRIPTION);
}

function setParametersForDescriptionLength(tags: string[]) {
  const isExtendedDescription = isDescriptionExtended(tags);
  const categoryDescriptionLength = isExtendedDescription
    ? EXTENDED_LENGTH
    : STANDARD_LENGTH;

  const categoryDescriptionRows = isExtendedDescription
    ? EXTENDED_LENGTH_ROWS
    : STANDARD_LENGTH_ROWS;

  return {
    categoryDescriptionLength,
    categoryDescriptionRows,
  };
}
