import { Box, Icon, Text } from '@nimbus-ds/components';
import { CheckIcon, CloseIcon } from '@nimbus-ds/icons';

export function Feature({
  feature,
  disabled,
}: React.PropsWithChildren<{ feature: string; disabled: boolean }>) {
  return (
    <Box display="flex" alignItems="center" gap="2">
      {disabled ? (
        <Icon source={<CloseIcon />} color="neutral-textDisabled" />
      ) : (
        <Icon source={<CheckIcon />} color="success-interactive" />
      )}
      <Text color={disabled ? 'neutral-textDisabled' : 'neutral-textHigh'}>
        {feature}
      </Text>
    </Box>
  );
}
