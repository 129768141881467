import { Route, Switch } from 'react-router-dom';
import { AUTH_ROUTES } from './authRoutes';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RecoveryCodePage from './pages/RecoveryCodePage';
import VerificationCodePage from './pages/VerificationCodePage';

function Auth(): JSX.Element {
  return (
    <Switch>
      <Route path={AUTH_ROUTES.login} exact>
        <LoginPage />
      </Route>
      <Route path={AUTH_ROUTES.verificationCode} exact>
        <VerificationCodePage />
      </Route>
      <Route path={AUTH_ROUTES.recoveryCode} exact>
        <RecoveryCodePage />
      </Route>
      <Route exact path={AUTH_ROUTES.logout}>
        <LogoutPage />
      </Route>
    </Switch>
  );
}

export default Auth;
