import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { AbandonedCartsResponseDto } from '@tiendanube/common';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc, useToast } from 'commons/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { sendEmail as sendEmailAction } from '../../abandonedCartsSlice';

function useAbandonedCartSendEmail(abandonedCart: AbandonedCartsResponseDto) {
  const dispatch = useAppDispatch();
  const t = useTranslationOrders();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [isEmailSent, setIsEmailSent] = useState(abandonedCart.wasNotified);

  const [handleSendEmail, isLoading] = useAsyncFunc(
    async () => {
      addToastProgress({
        label: t('abandonedCarts.sendEmail.toast.sendingEmail'),
      });
      await sendEmail();
    },
    () => {
      setIsEmailSent(true);
      closeToastProgress();
      addToast({
        label: t('abandonedCarts.sendEmail.toast.success'),
        appearance: 'success',
      });
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('abandonedCarts.sendEmail.toast.error'),
        appearance: 'danger',
      });
    },
  );

  const sendEmail = useCallback(
    async () => unwrapResult(await dispatch(sendEmailAction(abandonedCart.id))),
    [dispatch, abandonedCart.id],
  );

  return {
    isEmailSent,
    isLoading,
    handleSendEmail,
  };
}

export default useAbandonedCartSendEmail;
