import { useEffect } from 'react';
import { Checkbox, Label, Select } from '@nimbus-ds/components';
import { FormField, Layout } from '@nimbus-ds/patterns';
import { LocationResponseDto } from '@tiendanube/common';
import { ExternalLink } from 'commons/components';
import { useHasCatalogInventory } from 'domains/Auth/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { UpdateOptionsType } from '../../../../hooks/useImportProducts';

interface UpdateOptionsProps {
  disabled: boolean;
  options: UpdateOptionsType;
  onChange: (prop: string, value: string | boolean) => void;
}

function UpdateOptions({ disabled, options, onChange }: UpdateOptionsProps) {
  const {
    locations,
    isSuccess: isSuccessLocations,
    isLoading: isLoadingLocations,
    fetchLocations,
  } = useLocations();
  const hasCatalogInventory = useHasCatalogInventory();
  const hasMultipleLocations = locations.length > 1;
  const locationName = (location: LocationResponseDto) =>
    location.main
      ? `${location.name} (${t('inventory.mainLocation')})`
      : location.name;
  const { updateExisting, updateStock, selectedLocation } = options;
  const t = useTranslationCatalog();

  useEffect(() => {
    if (!isSuccessLocations && !isLoadingLocations) {
      fetchLocations();
    }
  }, [isSuccessLocations, isLoadingLocations, fetchLocations]);

  return (
    <Layout columns="1">
      <Layout.Section>
        <Checkbox
          label={t(
            'products.importExportPage.import.existingProducts.checkText',
          )}
          name="importCardCheckbox"
          checked={updateExisting}
          disabled={disabled}
          onChange={(e) => onChange('updateExisting', e.target.checked)}
        />
      </Layout.Section>
      {hasCatalogInventory && (
        <Layout.Section>
          <Checkbox
            label={t('products.importExportPage.import.updateStock.checkText')}
            name="importCardStockCheckbox"
            checked={updateStock}
            disabled={disabled || !updateExisting}
            onChange={(e) => onChange('updateStock', e.target.checked)}
          />
        </Layout.Section>
      )}
      {hasCatalogInventory && !isLoadingLocations && hasMultipleLocations && (
        <Layout.Section>
          <FormField
            id="select-id"
            label={t('products.importExportPage.import.updateStock.locations')}
            placeholder="Placeholder"
          >
            <Select
              disabled={disabled || !updateExisting || !updateStock}
              name="location-select"
              id="location-select"
              onChange={(e) => onChange('selectedLocation', e.target.value)}
            >
              <Select.Option
                label={t(
                  'products.importExportPage.import.updateStock.allLocations',
                )}
                value="all"
                selected={selectedLocation === 'all'}
              />
              {locations.map((location) => (
                <Select.Option
                  key={location.id}
                  label={locationName(location)}
                  value={location.id}
                  selected={selectedLocation === location.id}
                />
              ))}
            </Select>
          </FormField>
        </Layout.Section>
      )}
      {hasCatalogInventory && isLoadingLocations && (
        <Layout.Section>
          <FormField label={<Label.Skeleton />}>
            <Select.Skeleton width="100%" />
          </FormField>
        </Layout.Section>
      )}
      {hasCatalogInventory && (
        <Layout.Section>
          <ExternalLink
            href={t('products.importExportPage.import.updateStock.helpLinkUrl')}
          >
            {t('products.importExportPage.import.updateStock.helpLink')}
          </ExternalLink>
        </Layout.Section>
      )}
    </Layout>
  );
}

export default UpdateOptions;
