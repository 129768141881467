import { Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { InterfaceNameValue } from '@tiendanube/components';
import { InputNumberNimbus, Stack } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';

interface ValueCartProps {
  onChange: ({ name, value }: InterfaceNameValue) => void;
  value: string;
  disabled?: boolean;
}

export function ValueCart({
  onChange,
  value,
  disabled = false,
}: Readonly<ValueCartProps>) {
  const t = useTranslationMarketing('coupons.couponForm.limits.valueCart');
  const { symbol } = useGetCurrency();

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <Text fontWeight="bold">{t('title')}</Text>
      </Stack.Item>
      <Stack.Item>
        <Stack.Item>
          <FormField
            label={t('label')}
            helpText={t('helpText')}
            showHelpText
            id="minPrice"
          >
            <InputNumberNimbus
              name="minPrice"
              type="float"
              id="minPrice"
              append={symbol}
              appendPosition="start"
              value={value}
              onChange={({ target: { name, value } }) =>
                onChange({ name, value })
              }
              disabled={disabled}
            />
          </FormField>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
}

ValueCart.Skeleton = Skeleton;
