import { useHistory } from 'react-router-dom';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { useTrackBillingPayOrderCheckoutFinish } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  TransactionFeeToPayCard,
  TransactionFeePaidCard,
  PaymentsHistoryCard,
  TransactionFeesEmptyState,
  TransactionFeesPaymentStatusAlert,
  NuvemPagoFreeTransactionsAlert,
} from './components';
import { useIsTransactionFeeEmpty } from './hooks';

function TransactionFeesPage(): JSX.Element {
  const t = useTranslationBilling();
  const { goBack } = useHistory();
  const isEmpty = useIsTransactionFeeEmpty();

  useTrackBillingPayOrderCheckoutFinish();

  const backNavitation = {
    onClick: goBack,
  };
  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavitation} />}
      headerContent={<HeaderContent title={t('transactionFeesPage.title')} />}
      width="small"
    >
      {isEmpty && <TransactionFeesEmptyState />}
      {!isEmpty && (
        <>
          <NuvemPagoFreeTransactionsAlert />
          <TransactionFeesPaymentStatusAlert />
          <TransactionFeeToPayCard />
          <TransactionFeePaidCard />
          <PaymentsHistoryCard />
        </>
      )}
    </IonPageStratus>
  );
}

export default TransactionFeesPage;
