import { Text } from '@nimbus-ds/components';
import { ModalConfirmationBase } from 'App/components';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { usePublishDraftMutation } from 'domains/Online/Themes/themesApi';
import { trackingThemeLayoutDraftPublish } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface PublishDraftModalProps {
  showModal: boolean;
  isFtpOpen: boolean;
  onDraftPublished: () => void;
  onClose: () => void;
}

export function PublishDraftModal({
  showModal,
  isFtpOpen,
  onDraftPublished,
  onClose,
}: Readonly<PublishDraftModalProps>) {
  const t = useTranslationOnline('themesPage.draft.actions.publishDraftModal');
  const [publishDraft, { status, isLoading }] = usePublishDraftMutation();

  useToastStatus({
    status: toStatusType(status),
    progress: t('progress'),
    success: t('success'),
    error: t('error'),
  });

  const handleClickPublishDraft = async () => {
    trackingThemeLayoutDraftPublish();
    await publishDraft();
    onDraftPublished();
    onClose();
  };

  const subtitle = !isFtpOpen ? (
    t('subtitle')
  ) : (
    <Stack column align="stretch">
      <Text>{t('subtitleWithFtp1')}</Text>
      <Text>{t('subtitleWithFtp2')}</Text>
    </Stack>
  );

  return (
    <ModalConfirmationBase
      show={showModal}
      title={t('title')}
      text={subtitle}
      label={!isFtpOpen ? t('confirm') : t('confirmWithFtp')}
      labelCancel={t('cancel')}
      appearance="primary"
      disabled={isLoading}
      onConfirm={handleClickPublishDraft}
      onCancel={onClose}
    />
  );
}
