import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { InventoryLevelsRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  cleanUpInventory,
  cleanupdateStock,
  updateStock as updateStockAction,
} from 'domains/Catalog/Inventory/inventorySlice';
import { getEditStockStatus } from 'domains/Catalog/Inventory/inventorySlice/inventorySelectors';
import {
  updateInventoryLevels as updateInventoryLevelsAction,
  cleanUpdateInventoryLevels,
} from 'domains/Catalog/Products/productsSlice';
import { updateInventoryLevelsStatus } from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useInventoryItem } from '../../../hooks';

function useInventoryByVariant(productId: string, variantId: string) {
  const { product } = useInventoryItem(productId);
  const dispatch = useAppDispatch();

  const variant = product?.variants.find(({ id }) => id === variantId);
  const initialinventory: InventoryLevelsRequestDto[] =
    variant?.inventory_levels?.map(({ location_id, stock }) => ({
      locationId: location_id,
      stock,
    })) || [];
  const [inventoryLevels, setInventoryLevels] =
    useState<InventoryLevelsRequestDto[]>(initialinventory);

  const status = useSelector(updateInventoryLevelsStatus);

  const statusStock = useSelector(getEditStockStatus);

  const updateInventoryLevels = useCallback(async () => {
    await dispatch(
      updateInventoryLevelsAction({
        productId,
        variantId,
        inventoryLevels,
      }),
    );
    dispatch(cleanUpdateInventoryLevels());
    dispatch(cleanUpInventory());
  }, [dispatch, productId, variantId, inventoryLevels]);

  const updateStock = useCallback(
    async (params) => {
      await dispatch(updateStockAction(params));
      dispatch(cleanupdateStock());
    },
    [dispatch],
  );

  return {
    product,
    variant,
    initialinventory,
    inventoryLevels,
    setInventoryLevels,
    status,
    updateInventoryLevels,
    updateStock,
    statusStock,
  };
}

export default useInventoryByVariant;
