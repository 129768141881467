import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import {
  getPagesAction,
  getPagesListStatusSelector,
  getPagesListResultsSelector,
  getPagesListPaginationSelector,
  getPagesListIdsSelector,
  deletePageByIdAction,
  cleanDeletePageByIdStatusAction,
  getPageDeleteStatusSelector,
  getPagesListRefreshStatusSelector,
  getMorePagesAction,
} from '../../contentPagesSlice';

const defaultFilters = {
  page: 1,
};

function usePagesList() {
  const dispatch = useAppDispatch();
  const status = useSelector(getPagesListStatusSelector);
  const refreshStatus = useSelector(getPagesListRefreshStatusSelector);
  const pages = useSelector(getPagesListResultsSelector);
  const pagination = useSelector(getPagesListPaginationSelector);
  const pagesIds = useSelector(getPagesListIdsSelector);
  const deleteStatus = useSelector(getPageDeleteStatusSelector);

  const { changeFilters, filters } = useListFilters('pages', defaultFilters);

  const getPages = useCallback(async () => {
    changeFilters(filters);
    await dispatch(getPagesAction(filters));
  }, [dispatch, filters, changeFilters]);

  const refreshPage = useCallback(async () => {
    await dispatch(getPagesAction({ page: pagination?.currentPage }));
  }, [dispatch, pagination?.currentPage]);

  const goToPage = useCallback(
    (page: number) => {
      changeFilters({ page });
      dispatch(getPagesAction({ page }));
    },
    [dispatch, changeFilters],
  );

  const getMorePages = useCallback(() => {
    if (pagination?.nextPage) {
      changeFilters({ page: pagination?.nextPage });
      dispatch(getMorePagesAction({ page: pagination.nextPage }));
    }
  }, [dispatch, pagination?.nextPage, changeFilters]);

  const deleteContentPage = useCallback(
    async (id: string) => {
      await dispatch(deletePageByIdAction(id));
      dispatch(cleanDeletePageByIdStatusAction());
    },
    [dispatch],
  );

  const isEmpty = pagesIds.length === 0;

  const shouldShowSpinner = refreshStatus === 'loading' && !isEmpty;

  return {
    status,
    pages,
    pagination,
    isEmpty,
    pagesIds,
    shouldShowSpinner,
    deleteStatus,
    refreshPage,
    deleteContentPage,
    getPages,
    goToPage,
    getMorePages,
  };
}

export default usePagesList;
