import { useEffect, useState } from 'react';
import { Alert, Link } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetCountry } from 'domains/Auth/hooks';
import { useChargesToPay } from 'domains/Billing/Checkout/hooks';
import { INVOICES_ROUTES } from 'domains/Billing/Invoices';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import useGetBillingEngineInvoiceInfo from '../../../../../Invoices/hooks/useGetBillingEngineInvoiceInfo';

function NoFiscalDataAlert(): JSX.Element | null {
  const {
    hasInvoiceInfo,
    fetchInvoiceInfo,
    status: invoiceInfoStatus,
  } = useGetBillingEngineInvoiceInfo();
  const country = useGetCountry();
  const { status: chargesStatus, chargesToPay } = useChargesToPay();
  const { isSuccess } = combineStatus(chargesStatus, invoiceInfoStatus);
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.noFiscalDataAlert',
  );
  const [show, setShow] = useState(true);

  useEffect(fetchInvoiceInfo, [fetchInvoiceInfo]);

  if (
    !isSuccess ||
    hasInvoiceInfo ||
    !show ||
    ![Country.AR, Country.BR, Country.MX].includes(country)
  )
    return null;

  const localePath = chargesToPay?.length
    ? 'withPendingCharges'
    : 'withoutPendingCharges';

  return (
    <Alert
      appearance="danger"
      title={t(`${localePath}.title`)}
      onRemove={() => {
        setShow(false);
      }}
    >
      {t(`${localePath}.message`)}
      <Link as={LinkRoute} to={INVOICES_ROUTES.invoiceInfo}>
        {t('buttonText')}
      </Link>
    </Alert>
  );
}

export default NoFiscalDataAlert;
