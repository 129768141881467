import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail, Link, Grid } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { useGetLanguage } from 'domains/Auth/hooks';
import ImagesEmpty from '../../../ImagesEmpty';
import MainImageTag from '../../../MainImageTag';
import UploadingMessage from '../../../UploadingMessage';
import usePreviewImages from '../../usePreviewImages';
import RecommendationsGridRow from '../RecommendationsGridRow';
import { ImagePreviewsMobileProps } from '../types';

function ImagePreviewMobile({
  images,
  message,
  onError,
  onClickAdd,
  openModal,
}: ImagePreviewsMobileProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const language = useGetLanguage();
  const {
    isEmpty,
    isShowAddImage,
    isShowMoreImages,
    previewImages,
    morePhotosNumber,
    maxPreviewImages,
  } = usePreviewImages({
    images,
    numberOfImagesToPreview: 3,
  });

  return (
    <Grid spacing="base">
      <Grid.Row>
        {isEmpty && <ImagesEmpty onOpenModal={onClickAdd} />}
        {!isEmpty &&
          previewImages.map((image, index) => {
            const alt = image?.alt?.[language] || '';
            const firstPositionImage = index === 0;
            return (
              <Grid.Column key={image.id}>
                {image.isError ? (
                  <Thumbnail.Error
                    onClick={onError}
                    ariaLabel={t('products.detail.errorUploadPhoto')}
                    src={image.src}
                    altText={alt}
                  />
                ) : (
                  <div className="stratus--image-preview__item">
                    <Thumbnail
                      loading={image.isLoading}
                      src={image.src}
                      altText={alt}
                    />
                    {firstPositionImage && <MainImageTag />}
                  </div>
                )}
              </Grid.Column>
            );
          })}
        {isShowMoreImages && (
          <Grid.Column>
            <Thumbnail.Plus
              src={images[maxPreviewImages].src}
              altText={t('products.detail.morePhotos', { morePhotosNumber })}
              ariaLabel={t('products.detail.morePhotos', {
                morePhotosNumber,
              })}
              number={morePhotosNumber}
              onClick={openModal}
            />
          </Grid.Column>
        )}
        {isShowAddImage && (
          <Grid.Column>
            <Thumbnail.Add
              onClick={onClickAdd}
              ariaLabel={t('products.detail.addPhotos')}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      {!!message && (
        <Grid.Row>
          <Grid.Column>
            <UploadingMessage message={message} />
          </Grid.Column>
        </Grid.Row>
      )}
      <RecommendationsGridRow />
      {!isEmpty && (
        <Grid.Row>
          <Grid.Column>
            <Link appearance="primary" icon={EditIcon} onClick={openModal}>
              {t('products.detail.seeAllPhotos')}
            </Link>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}

export default ImagePreviewMobile;
