import { Modal, Text, Button, Box } from '@nimbus-ds/components';
import { Link, useLocation } from 'react-router-dom';
import { useDismissibleComponent, useTranslationCommon } from 'commons/hooks';
import { paymentsRoutes } from 'domains/Payments';
import { useInstalledPaymentProviders } from 'domains/Payments/PaymentProviders/hooks';
import { useKycStatus } from '../hooks/useKycStatus';

const EXCLUDED_KYC_REJECTED_MODAL_ROUTES = ['/nuvempago'];

export function KycRejectedModal() {
  const t = useTranslationCommon('kycRejectedModal');
  const { pathname } = useLocation();
  const { isKycRejected } = useKycStatus();
  const { paymentProviders, isSuccess } = useInstalledPaymentProviders();
  const hasPaymentProvider = paymentProviders?.some(
    (provider) => provider.isActive,
  );

  const { shouldShow, dismiss } = useDismissibleComponent(
    'kyc-rejected-modal',
    1,
  );

  const openModal =
    !EXCLUDED_KYC_REJECTED_MODAL_ROUTES.some((route) =>
      pathname.startsWith(route),
    ) &&
    isKycRejected &&
    isSuccess &&
    !hasPaymentProvider &&
    shouldShow;

  return (
    <Modal open={openModal} onDismiss={dismiss}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Box display="flex" flexDirection="column" gap="4">
          <Text>{t('description1')}</Text>
          <Text>{t('description2')}</Text>
          <Text>{t('description3')}</Text>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          as={Link}
          to={paymentsRoutes.customPaymentNewRoute}
          appearance="primary"
          onClick={dismiss}
        >
          {t('settingCustomPayment')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
