import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CategoryDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc, useToast, WrappedFuncType } from 'commons/hooks';
import categoriesServices from 'domains/Catalog/Categories/categoriesServices';
import { uploadImageAction } from 'domains/Catalog/Categories/categoriesSlice';
import useCategoriesMetafields from 'domains/Catalog/Categories/hooks/useCategoriesMetafields';
import { trackingCategoriesSaveMetafield } from 'domains/Catalog/Categories/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { sanitizerCategory } from './utils';

const initialState: CategoryDetailsResponseDto = {
  id: '',
  parent: '',
  name: {},
  description: {},
  googleShoppingCategory: '',
  seoTitle: {},
  seoDescription: {},
  handle: {},
  image: {
    id: '',
    name: '',
    url: '',
  },
  visibility: 'visible',
};

export interface ImageInterface {
  id: string;
  name: string;
  url: string;
}
interface useCategoryResult {
  category: CategoryDetailsResponseDto;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  isSaving: boolean;
  fetchCategory: WrappedFuncType<void, Promise<void>>;
  doSave: WrappedFuncType<CategoryDetailsResponseDto, Promise<void>>;
  doDelete: () => Promise<void>;
  uploadImage: (file: File) => Promise<ImageInterface>;
}

function useCategory(id: string): useCategoryResult {
  const [isSuccess, setIsSuccess] = useState(false);
  const [category, setCategory] =
    useState<CategoryDetailsResponseDto>(initialState);

  const { fetchCategoriesMetafields, categoriesMetafields } =
    useCategoriesMetafields(id);

  const { addToast } = useToast();
  const t = useTranslationCatalog();
  const dispatch = useAppDispatch();

  const [fetchCategory, isLoading, isError] = useAsyncFunc<void, Promise<void>>(
    useCallback(async () => {
      setIsSuccess(false);
      const categoryResponse: CategoryDetailsResponseDto =
        await categoriesServices.getCategoryById(id);
      setIsSuccess(true);
      setCategory((category) => ({
        ...category,
        ...categoryResponse,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]),
  );

  const [doSave, isSaving] = useAsyncFunc<
    CategoryDetailsResponseDto,
    Promise<void>
  >(
    async (category) => {
      if (!category) return;

      const sanitizedCategory = sanitizerCategory(category);

      const categoryUpdated: CategoryDetailsResponseDto =
        await categoriesServices.updateCategory({
          id,
          category: sanitizedCategory,
        });

      if (sanitizedCategory.metafields) {
        trackingCategoriesSaveMetafield(category.id);
      }
      setCategory(categoryUpdated);
    },
    () =>
      addToast({
        label: t('categories.toast.success'),
        appearance: 'success',
      }),
    () =>
      addToast({
        label: t('categories.toast.error'),
        appearance: 'danger',
      }),
  );

  const doDelete = async () => {
    await categoriesServices.deleteCategory(id);
  };

  const uploadImage = useCallback(
    async (file: File) => {
      const result = await dispatch(uploadImageAction(file));
      const image = unwrapResult(result);
      return image;
    },
    [dispatch],
  );

  useEffect(() => {
    if (id) {
      fetchCategoriesMetafields();
    }
  }, [id, fetchCategoriesMetafields]);

  useEffect(() => {
    setCategory((category) => ({
      ...category,
      metafields: categoriesMetafields,
    }));
  }, [categoriesMetafields]);

  return {
    category,
    isError,
    isLoading,
    isSuccess,
    isSaving,
    fetchCategory,
    doSave,
    doDelete,
    uploadImage,
  };
}

export default useCategory;
