import * as Yup from 'yup';

const validationSchema = Yup.object().shape(
  {
    codeAccount: Yup.string().when(['password', 'contractTypeCode'], {
      is: (password: string, contractTypeCode: string) =>
        password || contractTypeCode !== 'no_contract',
      then: Yup.string().required('correios.contract.errors.codeAccount'),
    }),
    password: Yup.string().when(['codeAccount', 'contractTypeCode'], {
      is: (codeAccount: string, contractTypeCode: string) =>
        codeAccount || contractTypeCode !== 'no_contract',
      then: Yup.string().required('correios.contract.errors.password'),
    }),
    contractTypeCode: Yup.string()
      .nullable()
      .when(['codeAccount', 'password'], {
        is: (codeAccount: string, password: string) => codeAccount || password,
        then: Yup.string().required('correios.contract.errors.contractType'),
      }),
  },
  [
    ['codeAccount', 'contractTypeCode'],
    ['codeAccount', 'password'],
    ['password', 'contractTypeCode'],
  ],
);

export default validationSchema;
