import { Route, Switch } from 'react-router-dom';
import { CUSTOMERS_SECTION_ROUTES } from './customersRoutes';
import {
  CustomerListPage,
  CustomerDetailsPage,
  CustomerFormPage,
} from './pages';

function Customers(): JSX.Element {
  return (
    <>
      <Route exact path={CUSTOMERS_SECTION_ROUTES.customerList}>
        <CustomerListPage />
      </Route>
      <Switch>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerNew}>
          <CustomerFormPage />
        </Route>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerEdit}>
          <CustomerFormPage />
        </Route>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerDetails}>
          <CustomerDetailsPage />
        </Route>
      </Switch>
    </>
  );
}

export default Customers;
