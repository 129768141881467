import { IconButton } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { MethodNameAndImage, Stack } from 'commons/components';

function ActiveShippingMethodsListSkeleton() {
  return (
    <DataList bottomDivider={false}>
      <DataList.Row>
        <Stack justify="space-between">
          <MethodNameAndImage.Skeleton />
          <Stack spacing="tight" justify="flex-end">
            <IconButton.Skeleton />
            <IconButton.Skeleton />
          </Stack>
        </Stack>
      </DataList.Row>
    </DataList>
  );
}

export default ActiveShippingMethodsListSkeleton;
