import { DataTable, Stack, Text, IconButton } from '@tiendanube/components';

const { Cell, Row } = DataTable;

function DataTableRow(): JSX.Element {
  return (
    <Row id="1" align="flex-start">
      <Cell grow={1}>
        <Text.Skeleton />
      </Cell>
      <Cell grow={2}>
        <Text.Skeleton />
      </Cell>
      <Cell grow={4}>
        <Stack column spacing="tight" align="flex-start">
          <Text.Skeleton />
          <Text.Skeleton />
          <Text.Skeleton />
        </Stack>
      </Cell>
      <Cell grow={1}>
        <Stack column spacing="tight" align="flex-start">
          <Text.Skeleton />
          <Text.Skeleton />
          <Text.Skeleton />
        </Stack>
      </Cell>
      <Cell grow={1}>
        <Stack column spacing="tight" justify="flex-start">
          <IconButton.Skeleton />
        </Stack>
      </Cell>
    </Row>
  );
}

export default DataTableRow;
