import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, InteractiveList } from '@tiendanube/components';

interface ProductTypeProps {
  isDigital: boolean;
  onChange: (data: { name: string; value: boolean }) => void;
}

function ProductType({ isDigital, onChange }: ProductTypeProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  const handleOnChange = () => {
    onChange({ name: 'isDigital', value: !isDigital });
  };

  const options = [
    {
      title: t('products.detail.physical'),
      name: 'physical',
      active: !isDigital,
    },
    {
      title: t('products.detail.digital'),
      name: 'digital',
      active: isDigital,
    },
  ];

  return (
    <Card title={t('products.detail.typeOfProduct')}>
      <InteractiveList
        onChange={handleOnChange}
        mode="single"
        options={options}
      />
    </Card>
  );
}

export default ProductType;
