import { ChangeEvent } from 'react';
import { Tabs } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { NotificationDetailsDto } from '@tiendanube/common';
import { DEFAULT_LANGUAGE, LanguagesType } from 'App/i18n';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { HtmlTemplateTab, TextTemplateTab } from '../components';

interface EmailValuesProps {
  values: NotificationDetailsDto;
  initialValues: NotificationDetailsDto;
  language?: LanguagesType;
  isPhishingSuspect: boolean;
  setFieldValue: (name: string, value: any) => void;
}

export function EmailValues({
  values,
  initialValues,
  language = DEFAULT_LANGUAGE,
  isPhishingSuspect,
  setFieldValue,
}: Readonly<EmailValuesProps>) {
  const t = useTranslationConfigurations('notifications.editNotificationPage');

  const handleChange = ({
    target: { name, value, type, checked },
  }: ChangeEvent<HTMLInputElement>) =>
    setFieldValue(language, {
      ...values[language],
      [name]: type === 'checkbox' ? checked : value,
    });

  const handleChangeTextarea = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement>) =>
    setFieldValue(language, {
      ...values[language],
      [name]: value,
    });

  const handleChangeHtmlEnabled = ({ target: { checked } }) =>
    setFieldValue('htmlEnabled', checked);

  return (
    <Stack column align="stretch">
      <FormField.Input
        label={t('formCard.subject')}
        placeholder={initialValues[language].subject}
        name="subject"
        autoFocus
        disabled={isPhishingSuspect}
        value={values[language].subject}
        onChange={handleChange}
      />
      <Tabs preSelectedTab={initialValues.htmlEnabled ? 1 : 0}>
        <Tabs.Item label={t('formCard.templateTextTab')}>
          <TextTemplateTab
            values={values}
            placeholder={initialValues[language].templateText}
            language={language}
            isPhishingSuspect={isPhishingSuspect}
            onChange={handleChangeTextarea}
          />
        </Tabs.Item>
        <Tabs.Item label={t('formCard.htmlTab')}>
          <HtmlTemplateTab
            values={values}
            placeholder={initialValues[language].templateHtml}
            language={language}
            isPhishingSuspect={isPhishingSuspect}
            onChangeHtmlEnabled={handleChangeHtmlEnabled}
            onChangeTextarea={handleChangeTextarea}
          />
        </Tabs.Item>
      </Tabs>
    </Stack>
  );
}
