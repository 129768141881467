import { Tag, Text } from '@nimbus-ds/components';
import { UserIcon } from '@nimbus-ds/icons';
import { UsersListItemResponseDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useGetUserInfo } from 'domains/Auth/hooks';
import {
  UserHas2faTag,
  UsersListActions,
} from 'domains/Configurations/Users/pages/UsersListPage/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import UserRolesPopover from '../UserRolesPopover';

interface UsersListMobileRowProps {
  user: UsersListItemResponseDto;
  storeRoles: Record<'roles' | 'appRoles', Record<string, string>>;
}

function UsersListMobileRow({
  user: { id, name, rolesWithoutFilters, appRoles, isOwner, has2FA },
  storeRoles,
}: Readonly<UsersListMobileRowProps>) {
  const t = useTranslationConfigurations('usersListPage.table');
  const { Row, Cell } = DataList;
  const { id: userId } = useGetUserInfo();
  const isCurrentUser = userId === `${id}`;

  return (
    <Row id={id}>
      <Cell width={100} rowTitle>
        <Stack align="flex-start">
          <Stack.Item fill>
            <Stack column align="flex-start" spacing="tight">
              {isCurrentUser && (
                <Tag appearance="primary">
                  <UserIcon size={12} /> {t('currentUserTag')}
                </Tag>
              )}
              <Text fontWeight="bold" lineClamp={1}>
                {name || t('withoutName')}
              </Text>
              <UserRolesPopover
                id={id}
                userRoles={rolesWithoutFilters}
                userAppRoles={appRoles}
                storeRoles={storeRoles}
              />
              <UserHas2faTag has2fa={has2FA} isCurrentUser={isCurrentUser} />
            </Stack>
          </Stack.Item>
          <UsersListActions
            userId={id}
            isCurrentUser={isCurrentUser}
            isOwner={isOwner}
          />
        </Stack>
      </Cell>
    </Row>
  );
}

export default UsersListMobileRow;
