import { useEffect } from 'react';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { StatusType } from 'commons/types';
import useToast from '../useToast';

interface UseToastStatusProps {
  status: StatusType;
  success?: string;
  error?: string;
  progress?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

function useToastStatus({
  status,
  success,
  error,
  onSuccess,
  onError,
  progress,
}: UseToastStatusProps) {
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  useEffect(() => {
    if (status === 'error') {
      progress && closeToastProgress();
      error &&
        addToast({
          appearance: 'danger',
          label: error,
        });
      onError?.();
    }
    if (status === 'success') {
      progress && closeToastProgress();
      success &&
        addToast({
          appearance: 'success',
          label: success,
        });
      onSuccess?.();
    }
    if (status === 'loading') {
      progress && addToastProgress({ label: progress });
    }
    return () => {
      progress && closeToastProgress();
    };
  }, [
    addToast,
    addToastProgress,
    closeToastProgress,
    error,
    onError,
    onSuccess,
    progress,
    status,
    success,
  ]);
}

export default useToastStatus;
