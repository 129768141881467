import { Box, BoxProps } from '@nimbus-ds/components';

function IconBox({
  backgroundColor = 'primary-surfaceHighlight',
  borderRadius = 'base',
  height = '36px',
  width = '36px',
  display = 'flex',
  alignItems = 'center',
  justifyContent = 'center',
  ...rest
}: BoxProps) {
  return (
    <Box
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      height={height}
      width={width}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...rest}
    />
  );
}

export default IconBox;
