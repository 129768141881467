import { ChooseRecurrency, DisabledRecurrenciesAlert } from './components';
import { ChoosePaymentMethodWithRecurrencyLayout } from '../components';

export default function ChoosePaymentRecurrencyPage() {
  return (
    <ChoosePaymentMethodWithRecurrencyLayout
      mainContent={
        <>
          <DisabledRecurrenciesAlert />
          <ChooseRecurrency />
        </>
      }
    />
  );
}
