import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  CreateContentDeclarationRequestDto,
  CreateDocumentRequestDto,
  CreateLabelRequestDto,
  CreateReceiptRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { WrappedFuncType, useAsyncFunc } from 'commons/hooks';
import {
  createLabelDocumentDownloadLink,
  createContentDeclarationDownloadLink,
  createReceiptDownloadLink,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice/fulfillmentOrdersSlice';
import { DocumentType } from '../../constants';
import useActionsToast from '../useActionsToast';

export interface HandleDownloadDocumentProps {
  type: DocumentType;
  params: CreateDocumentRequestDto;
}
export interface UseCreateDocumentsDownloadResult {
  handleDownloadDocument: WrappedFuncType<HandleDownloadDocumentProps, string>;
  setMultiple: (value: boolean) => void;
  isGenerating: boolean;
}

function useCreateDocumentsDownload(): UseCreateDocumentsDownloadResult {
  const dispatch = useAppDispatch();
  const [isMultiple, setIsMultiple] = useState<boolean>(true);

  const { onErrorPrintManager, onSuccessPrintManager, onLoadingPrintManager } =
    useActionsToast(isMultiple);

  const setMultiple = (value: boolean) => {
    setIsMultiple(value);
  };

  const getLabels: (param: CreateLabelRequestDto) => Promise<string> =
    useCallback(
      async (params: CreateLabelRequestDto) => {
        const action = createLabelDocumentDownloadLink(params);
        const actionResult = await dispatch(action);
        return unwrapResult(actionResult);
      },
      [dispatch],
    );

  const getContentDeclaration: (
    param: CreateContentDeclarationRequestDto,
  ) => Promise<string> = useCallback(
    async (params: CreateContentDeclarationRequestDto) => {
      const action = createContentDeclarationDownloadLink(params);
      const result = await dispatch(action);

      return unwrapResult(result);
    },
    [dispatch],
  );

  const getReceipts: (param: CreateReceiptRequestDto) => Promise<string> =
    useCallback(
      async (params: CreateReceiptRequestDto) => {
        const action = createReceiptDownloadLink(params);
        const result = await dispatch(action);

        return unwrapResult(result);
      },
      [dispatch],
    );

  const [handleDownloadDocument, isGenerating] = useAsyncFunc<
    HandleDownloadDocumentProps,
    string
  >(
    async (props) => {
      if (!props) {
        return '';
      }
      onLoadingPrintManager();
      const { type, params } = props;
      switch (type) {
        case DocumentType.LABEL: {
          return await getLabels(params);
        }
        case DocumentType.CONTENT_DECLARATION: {
          return await getContentDeclaration(params);
        }
        case DocumentType.RECEIPT: {
          return await getReceipts(params);
        }
      }
    },
    onSuccessPrintManager,
    onErrorPrintManager,
  );

  return {
    handleDownloadDocument,
    setMultiple,
    isGenerating,
  };
}

export default useCreateDocumentsDownload;
