import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop } from 'commons/components';
import IonPageSratus from 'commons/components/IonPageStratus/IonPageStratus';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ConfigureOcaMultiStep from './ConfigureOcaMultiStep';

interface ConfigureOcaPageProps {
  optionals?: boolean;
}

function ConfigureOcaPage({
  optionals = false,
}: ConfigureOcaPageProps): JSX.Element {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const title = t('oca.config.title');
  const backNavigation = {
    onClick: goBack,
  };
  return (
    <IonPageSratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={title} />}
      width="small"
    >
      <ConfigureOcaMultiStep
        initialStep={optionals ? 3 : 1}
        showStep={!optionals}
      />
    </IonPageSratus>
  );
}

export default ConfigureOcaPage;
