import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_WHATSAPP_CLICK,
  WHATSAPP_EDIT_NUMBER_CLICK,
  WHATSAPP_ENABLE_CHATBUTTON,
  WHATSAPP_DISABLE_CHATBUTTON,
} from 'config/trackingEvents';

export const menuSecondaryWhatsAppClick = () => {
  logEvent(MENU_SECONDARY_WHATSAPP_CLICK, {});
};

export const whatsappEnableChatbutton = () => {
  logEvent(WHATSAPP_ENABLE_CHATBUTTON, {});
};

export const whatsappDisableChatbutton = () => {
  logEvent(WHATSAPP_DISABLE_CHATBUTTON, {});
};

export const whatsappEditNumberCLick = () => {
  logEvent(WHATSAPP_EDIT_NUMBER_CLICK, {});
};
