import { IconButton, Table, Tag, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

export function Skeleton() {
  return (
    <Table.Row>
      <Table.Cell>
        <Text.Skeleton width="40%" />
      </Table.Cell>
      <Table.Cell>
        <Tag.Skeleton height="20px" width="30%" />
      </Table.Cell>
      <Table.Cell>
        <Tag.Skeleton height="20px" width="30%" />
      </Table.Cell>
      <Table.Cell>
        <Stack spacing="tight">
          <IconButton.Skeleton height="32px" width="32px" />
          <IconButton.Skeleton height="32px" width="32px" />
        </Stack>
      </Table.Cell>
    </Table.Row>
  );
}
