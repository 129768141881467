import { Tag, Text } from '@nimbus-ds/components';
import { LocationIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { trackingShippingDistributionCenterNowAllowed } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function NotAllowedMultipleLocationCard(): JSX.Element {
  const t = useTranslationShipping();
  const handleNotAllowedMultipleLocation = () => {
    trackingShippingDistributionCenterNowAllowed('card');
  };
  return (
    <CalloutCard
      appearance="neutral"
      icon={LocationIcon}
      title={t('locations.multiLocations.card.title')}
      subtitle={t('locations.multiLocations.card.subtitle')}
      onClick={handleNotAllowedMultipleLocation}
      link={
        <Tag>
          <Text fontSize="caption">
            {t('locations.multiLocations.comingSoon')}
          </Text>
        </Tag>
      }
    />
  );
}

export default NotAllowedMultipleLocationCard;
