import {
  AppsListItemResponseDto,
  GetAppUninstallReasonsDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { PartnerAppsTags, PartnerAppsEndpoints } from './types';
import {
  BASE_PARTNER_APPS,
  BASE_PARTNER_APPS_ID,
  OLD_ADMIN_AUTHORIZATION_URL,
} from './urls';
import { checkoutApi } from '../../Billing/Checkout/CheckoutApi';
import { checkoutTags } from '../../Billing/Checkout/CheckoutApi/config';

export const partnerAppsApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [PartnerAppsTags.appsList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [PartnerAppsEndpoints.getAppsList]: builder.query<
        AppsListItemResponseDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_PARTNER_APPS,
        }),
        providesTags: [PartnerAppsTags.appsList],
      }),
      [PartnerAppsEndpoints.deleteApp]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(BASE_PARTNER_APPS_ID, { id }),
        }),
        onQueryStarted(id, { dispatch, queryFulfilled }) {
          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                PartnerAppsEndpoints.getAppsList as never,
                undefined as never,
                (draft: AppsListItemResponseDto[]) =>
                  draft.filter(({ id: appId }) => appId !== id),
              ),
            );
          });
        },
      }),
      [PartnerAppsEndpoints.uninstallApp]: builder.mutation<
        void,
        {
          appId: string;
          reasonId?: string | null;
          reasonDetail?: string | null;
        }
      >({
        query: (data) => ({
          method: 'POST',
          url: getUrlWithParams(`${BASE_PARTNER_APPS_ID}/uninstall`, {
            id: data.appId,
          }),
          data,
        }),
        onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
          const postResult = dispatch(
            cirrusApi.util.updateQueryData(
              PartnerAppsEndpoints.getAppsList as never,
              undefined as never,
              (draft: AppsListItemResponseDto[]) =>
                draft.map((app) =>
                  app.id === data.appId
                    ? { ...app, installed: false, installable: true }
                    : app,
                ),
            ),
          );

          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
      [PartnerAppsEndpoints.getUninstallReasons]: builder.query<
        GetAppUninstallReasonsDto[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: `${BASE_PARTNER_APPS}/uninstall-reasons`,
        }),
        providesTags: [PartnerAppsTags.appsList],
      }),
      [PartnerAppsEndpoints.postAppCharge]: builder.mutation<void, string>({
        query: (appId) => ({
          method: 'POST',
          url: getUrlWithParams(`${BASE_PARTNER_APPS_ID}/create-charge`, {
            id: appId,
          }),
        }),
        onQueryStarted: async (_data, { queryFulfilled }) => {
          queryFulfilled.then(() => {
            checkoutApi.util.invalidateTags([checkoutTags.pendingCharges]);
          });
        },
      }),
      [PartnerAppsEndpoints.getOldAdminAuthorizationCode]: builder.query<
        string,
        void
      >({
        query: () => ({
          method: 'GET',
          url: OLD_ADMIN_AUTHORIZATION_URL,
        }),
      }),
    }),
  });

export const {
  useGetAppsListQuery,
  useDeleteAppMutation,
  useUninstallAppMutation,
  useGetUninstallReasonsQuery,
  usePostAppChargeMutation,
  useGetOldAdminAuthorizationCodeQuery,
} = partnerAppsApi;
