import { Box, Modal, Text, Icon } from '@nimbus-ds/components';
import { ArrowRightIcon } from '@nimbus-ds/icons';
import { SeoRedirectsResponseDto } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { CancelAndConfirmButtons } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useRemoveSeoRedirectMutation } from 'domains/Configurations/SeoRedirects/seoRedirectsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface RemoveSeoRedirectsModalProps {
  onClose: () => void;
  isOpen: boolean;
  redirect: SeoRedirectsResponseDto;
}

function RemoveSeoRedirectsModal({
  onClose,
  isOpen,
  redirect,
}: RemoveSeoRedirectsModalProps) {
  const t = useTranslationConfigurations(
    'seoRedirects.removeSeoRedirectsModal',
  );
  const [remove, { isLoading, status }] = useRemoveSeoRedirectMutation();
  useToastStatus({
    status: toStatusType(status),
    success: t('success'),
    error: t('error'),
    onSuccess: onClose,
  });
  const { id, urlNew, urlOld } = redirect;
  const handleRemove = () => remove(id);
  return (
    <Modal onDismiss={onClose} open={isOpen}>
      <Modal.Header title={t('title')} />
      <Text>{t('text')}</Text>
      <Box
        borderColor="neutral-interactive"
        backgroundColor="neutral-surface"
        borderRadius="2"
        borderStyle="dashed"
        minHeight="5rem"
        borderWidth="2"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="4"
        padding="2"
      >
        <Text textAlign="left">{urlOld}</Text>
        <Icon source={<ArrowRightIcon />} />
        <Text textAlign="right">{urlNew}</Text>
      </Box>
      <CancelAndConfirmButtons
        onCancel={onClose}
        onConfirm={handleRemove}
        appearance="danger"
        isLoading={isLoading}
        confirmText={t('remove')}
      />
    </Modal>
  );
}

export default RemoveSeoRedirectsModal;
