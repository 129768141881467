import { useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { useAutoFocus, useForm } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  SenderCard,
  OriginAddressCard,
  OriginAddressValuesInterface,
  SenderValuesInterface,
} from './components';
import Skeleton from './Skeleton';
import { ocaConfigarationSchemaStep1 } from './validationSchemaStep1';
import { useOcaAgencies } from '../../hooks';
import { onDirtyType } from '../../types';

export interface ConfigureOcaStep1Values {
  sender: SenderValuesInterface;
  originAddress: OriginAddressValuesInterface;
}

interface ConfigureOcaStep1Props {
  values: ConfigureOcaStep1Values;
  onChange: (values: ConfigureOcaStep1Values) => void;
  onDirty: onDirtyType;
}

function ConfigureOcaStep1({
  values,
  onChange,
  onDirty,
}: ConfigureOcaStep1Props) {
  const t = useTranslationShipping();
  const { getOcaAgencies } = useOcaAgencies();
  const { goBack } = useNavegate();
  const { ref } = useAutoFocus();

  const {
    values: valuesForm,
    errors,
    isValidating,
    isDirty,
    handleOnChangeGroup,
    handleOnSubmit,
  } = useForm({
    initialValues: values,
    validationSchema: ocaConfigarationSchemaStep1(getOcaAgencies),
    onSubmit: onChange,
  });

  useEffect(() => {
    onDirty('step1', isDirty);
  }, [isDirty, onDirty]);

  return (
    <Stack column align="flex-end" innerRef={ref}>
      <SenderCard
        values={valuesForm.sender}
        errors={errors}
        onChange={handleOnChangeGroup}
      />
      <OriginAddressCard
        values={valuesForm.originAddress}
        errors={errors}
        onChange={handleOnChangeGroup}
      />
      <CancelAndSaveButtons
        isLoading={isValidating}
        onCancel={goBack}
        onSave={handleOnSubmit}
        saveText={t('oca.config.continue')}
      />
    </Stack>
  );
}

ConfigureOcaStep1.Skeleton = Skeleton;

export default ConfigureOcaStep1;
