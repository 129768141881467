import React from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  ArgentineFiscalData,
  BrazilianFiscalData,
  ChileanFiscalData,
  ColombianFiscalData,
  MexicanFiscalData,
} from '../components';

interface useLoadFiscalDataResult {
  Component: React.FC;
}

function useLoadFiscalData(): useLoadFiscalDataResult {
  const country = useGetCountry();

  return {
    [Country.BR]: {
      Component: BrazilianFiscalData,
    },
    [Country.CL]: {
      Component: ChileanFiscalData,
    },
    [Country.CO]: {
      Component: ColombianFiscalData,
    },
    [Country.AR]: {
      Component: ArgentineFiscalData,
    },
    [Country.MX]: {
      Component: MexicanFiscalData,
    },
  }[country];
}

export default useLoadFiscalData;
