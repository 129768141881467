import { Button, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import EditOriginalPrice from './EditOriginalPrice';
import EditPromotionalPrice from './EditPromotionalPrice';
import { useModalProductsEditPrices } from './useModalProductsEditPrices';

function ModalProductsEditPrices(): JSX.Element | null {
  const t = useTranslationCatalog();
  const {
    title,
    priceVariation,
    priceVariationAction,
    priceVariationType,
    priceVariationError,
    showAlertDiscount,
    roundPrices,
    handleChangeRoundPrices,
    promoPriceVariation,
    promoPriceVariationAction,
    promoPriceVariationType,
    promoPriceVariationError,
    showAlertCreate,
    roundPromoPrices,
    handleChangeRoundPromoPrices,
    handleChange,
    disabledPrimayAction,
    showModal,
    isLoading,
    handleOnClose,
    handleOnClick,
  } = useModalProductsEditPrices();

  if (!showModal) return null;

  return (
    <ModalAside isOpen onDidDismiss={handleOnClose} headerContent={title}>
      <EditOriginalPrice
        priceVariation={priceVariation}
        priceVariationAction={priceVariationAction}
        priceVariationType={priceVariationType}
        showAlertDiscount={showAlertDiscount}
        roundPrices={roundPrices}
        handleChangeRoundPrices={handleChangeRoundPrices}
        handleChange={handleChange}
        error={priceVariationError}
      />
      <EditPromotionalPrice
        promoPriceVariation={promoPriceVariation}
        promoPriceVariationAction={promoPriceVariationAction}
        promoPriceVariationType={promoPriceVariationType}
        showAlertCreate={showAlertCreate}
        roundPromoPrices={roundPromoPrices}
        handleChangeRoundPromoPrices={handleChangeRoundPromoPrices}
        handleChange={handleChange}
        error={promoPriceVariationError}
      />
      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button onClick={handleOnClose} disabled={isLoading}>
            {`${t('products.editPrice.cancel')}`}
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            onClick={handleOnClick}
            disabled={disabledPrimayAction || isLoading}
            appearance="primary"
            spinner={isLoading}
          >
            {`${t('products.editPrice.update')}`}
          </Button>
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default ModalProductsEditPrices;
