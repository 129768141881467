import { IconButton } from '@nimbus-ds/components';
import { EditIcon, TrashIcon } from '@tiendanube/icons';
import Stack from 'commons/components/Stack';
import { useModal } from 'commons/hooks';
import DeleteFeaturedImageModal from '../DeleteFeaturedImageModal';

interface ButtonsActionsProps {
  handleOnClick: () => void;
  handleDeleteFeaturedImage: () => void;
}

function ButtonsActions({
  handleOnClick,
  handleDeleteFeaturedImage,
}: ButtonsActionsProps): JSX.Element {
  const [isShow, openModal, closeModal] = useModal();

  const handleClickDelete = () => {
    openModal();
  };
  const handleConfirmOnCancelModal = () => {
    closeModal();
    handleDeleteFeaturedImage();
  };
  const handleCancelOnCancelModal = () => {
    closeModal();
  };

  const handleClickEdit = () => {
    handleOnClick();
  };
  return (
    <>
      <Stack gap="2">
        <IconButton
          type="button"
          size="2rem"
          source={<EditIcon />}
          onClick={handleClickEdit}
        />
        <IconButton
          type="button"
          size="2rem"
          source={<TrashIcon />}
          onClick={handleClickDelete}
        />
      </Stack>
      <DeleteFeaturedImageModal
        show={isShow}
        onConfirm={handleConfirmOnCancelModal}
        onCancel={handleCancelOnCancelModal}
      />
    </>
  );
}

export default ButtonsActions;
