import { InteractiveList } from '@nimbus-ds/patterns';
import { Repeater } from 'commons/components';
import { ProductItemSkeleton } from './ProductItemSkeleton';

const getProductItemSkeleton = (i: number) => (
  <InteractiveList.CheckboxItemSkeleton
    key={i}
    title={i.toString()}
    showTitle={false}
  >
    <ProductItemSkeleton key={i} />
  </InteractiveList.CheckboxItemSkeleton>
);

function ProductListSkeleton() {
  return (
    <InteractiveList>
      <Repeater item={getProductItemSkeleton} />
    </InteractiveList>
  );
}

export default ProductListSkeleton;
