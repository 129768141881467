import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AddressingConfigurationRuleRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getAddressingConfigurationRules,
  getAddressingConfigurationRulesStatus,
  getAddressingConfigurationRulesAction,
  saveAddressingConfigurationRulesAction,
  cleanSaveAddressingConfigurationRulesStatus,
  getAddressingConfigurationRulesSaveStatus,
} from '../../deliveryMethodsSlice';

function useAddressingConfigurationRulesManagement() {
  const dispatch = useAppDispatch();
  const rules = useSelector(getAddressingConfigurationRules);
  const getStatus = useSelector(getAddressingConfigurationRulesStatus);

  const saveStatus = useSelector(getAddressingConfigurationRulesSaveStatus);

  const fetchRules = useCallback(
    () => dispatch(getAddressingConfigurationRulesAction()),
    [dispatch],
  );

  const saveConfigurationRules = useCallback(
    async (params: AddressingConfigurationRuleRequestDto[]) => {
      await dispatch(saveAddressingConfigurationRulesAction(params));
    },
    [dispatch],
  );

  const cleanStatus = useCallback(
    () => dispatch(cleanSaveAddressingConfigurationRulesStatus()),
    [dispatch],
  );

  return {
    isIdle: rules === null,
    rules,
    getStatus,
    saveStatus,
    fetchRules,
    saveConfigurationRules,
    cleanStatus,
  };
}

export default useAddressingConfigurationRulesManagement;
