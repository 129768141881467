import { useEffect, useState } from 'react';
import { Alert, Link, Text } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { INVOICES_ROUTES } from 'domains/Billing/Invoices';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function TaxAlert() {
  const { isConsumidorFinal, fetchInvoiceInfo } = useGetInvoiceInfo();

  useEffect(fetchInvoiceInfo, [fetchInvoiceInfo]);

  const t = useTranslationBilling('checkout.taxAlert');
  const [show, setShow] = useState(true);
  const onRemove = () => setShow(false);

  const { goTo } = useNavegate();
  const handleGoToLoadFiscalData = () => {
    goTo(INVOICES_ROUTES.invoiceInfo);
  };

  if (!isConsumidorFinal) return null;

  return (
    <Alert appearance="primary" show={show} onRemove={onRemove}>
      <Text>
        {t('messagePartOne')}{' '}
        <Link as="a" href={t('link')}>
          {t('messagePartTwo')}
        </Link>{' '}
      </Text>
      <Text>
        {t('messagePartThree')}{' '}
        <Link as="a" onClick={handleGoToLoadFiscalData} appearance="primary">
          {t('messagePartFour')}
        </Link>{' '}
        {t('messagePartFive')}
      </Text>
    </Alert>
  );
}

export default TaxAlert;
