import { Checkbox, Text, Title } from '@nimbus-ds/components';
import {
  SettingOptionInteractiveDto,
  UpdateMercadoPagoSettingsRequestDto,
} from '@tiendanube/common';
import {
  BaseCard,
  InteractiveList,
  InterfaceNameValue,
} from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { InterfaceNameBooleanValue } from 'commons/types';
import { toCamelCase } from 'commons/utils';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

interface CheckoutCardProps {
  integrationTypes: SettingOptionInteractiveDto[];
  paymentMethodsByCountry: Record<string, string[]>;
  values: UpdateMercadoPagoSettingsRequestDto;
  errors: Partial<Record<string, string>>;
  handleOnChange: (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
  setFieldValue: (name: string, value: any) => void;
}

function CheckoutCard({
  integrationTypes,
  paymentMethodsByCountry,
  values,
  errors,
  handleOnChange,
  setFieldValue,
}: CheckoutCardProps): JSX.Element {
  const t = useTranslationPayments();

  const checkoutOptions = integrationTypes.map((integrationType) => ({
    ...integrationType,
    active: values.integrationType === integrationType.name,
  }));

  const paymentMethodOptions = paymentMethodsByCountry[values.country] ?? [];

  const handleChangeIntegrationType = (checkout) =>
    handleOnChange({ name: 'integrationType', value: checkout[0] });

  const handleChangePaymentMethods = (event) => {
    if (event.target.checked) {
      return setFieldValue('paymentMethodTypes', [
        ...values.paymentMethodTypes,
        event.target.name,
      ]);
    }

    return setFieldValue(
      'paymentMethodTypes',
      values.paymentMethodTypes.filter((p) => p !== event.target.name),
    );
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title as="h3">
          {t('paymentProvider.settings.mercadopago.checkout.title')}
        </Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <InteractiveList
            mode="single"
            options={checkoutOptions}
            onChange={handleChangeIntegrationType}
          />
          {values.integrationType === 'transparent' && (
            <>
              <Text fontWeight="bold">
                {t(
                  'paymentProvider.settings.mercadopago.checkout.allowedPaymentMethods',
                )}
              </Text>
              {paymentMethodOptions.map((paymentMethod) => (
                <Checkbox
                  key={paymentMethod}
                  name={paymentMethod}
                  label={t(
                    `paymentProvider.method.${toCamelCase(paymentMethod)}`,
                  )}
                  checked={values.paymentMethodTypes.includes(paymentMethod)}
                  onChange={handleChangePaymentMethods}
                />
              ))}
              {!!errors.paymentMethodTypes && (
                <Text color="danger-interactive">
                  {t(errors.paymentMethodTypes)}
                </Text>
              )}
            </>
          )}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default CheckoutCard;
