import { HomeIcon, TagIcon, MoneyIcon, MenuIcon } from '@nimbus-ds/icons';
import { NavTabs } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { handleClickExternal } from 'commons/utils/dom';
import { useGetNewOrdersCount } from 'domains/Orders/Orders/hooks';
import useNavTabs from './useNavTabs';
import NavTabsContainer from '../NavTabsContainer';
import ShowByRole from '../ShowByRole';
import './Navtabs.scss';

const path = {
  dashboard: '/admin/',
  orders: '/admin/orders/',
  products: '/admin/products/',
};

function NavTabsWeb() {
  const count = useGetNewOrdersCount();
  const { pathname } = useLocation();
  const { toggleSideBar } = useNavTabs();
  const { t } = useTranslation(['common']);
  const openMenu = () => toggleSideBar();

  return (
    <NavTabsContainer>
      <div className="stratus--nav-tabs-container">
        <NavTabs>
          <NavTabs.Item
            ariaLabel={t('menu.dashboard')}
            icon={<HomeIcon size="medium" />}
            onClick={handleClickExternal(path.dashboard)}
            active={pathname === path.dashboard}
          />
          <ShowByRole includeRoles="orders">
            <NavTabs.Item
              ariaLabel={t('menu.orders')}
              badge={count > 0}
              icon={<MoneyIcon size="medium" />}
              onClick={handleClickExternal(path.orders)}
              active={pathname.startsWith(path.orders)}
            />
          </ShowByRole>
          <ShowByRole includeRoles="products">
            <NavTabs.Item
              ariaLabel={t('menu.products')}
              icon={<TagIcon size="medium" />}
              onClick={handleClickExternal(path.products)}
              active={pathname.startsWith(path.products)}
            />
          </ShowByRole>
          <NavTabs.Item
            ariaLabel={t('menu.hamburger')}
            icon={<MenuIcon size="medium" />}
            onClick={openMenu}
          />
        </NavTabs>
      </div>
    </NavTabsContainer>
  );
}

export default NavTabsWeb;
