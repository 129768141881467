import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import {
  MercadoPagoPaymentProviderDto,
  UpdateMercadoPagoSettingsRequestDto,
} from '@tiendanube/common';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import { ErrorState } from '@tiendanube/components';
import { SMART_PAYMENT_ADMIN } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useGetCountry, useGetTags } from 'domains/Auth/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  AccountCard,
  CheckoutCard,
  DiscountsCard,
  InstallmentsCard,
  MercadoPagoSettingsSkeleton,
  TermsAndRatesCard,
} from './components';
import { getValidationSchema } from './validationSchema';
import {
  useEnableMercadoPago,
  useGetSettingOptions,
  useNativePaymentProviderSettings,
} from '../../hooks';

const TRANSLATE_PREFIX = 'paymentProvider';

const buildPayload = (values: UpdateMercadoPagoSettingsRequestDto) => ({
  ...values,
  appId: NativePaymentProviders.mercadoPago,
});

function MercadoPagoSettingsPage(): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { t: globalTranslation } = useTranslation();
  const { goBack, replace } = useNavegate();
  const tags = useGetTags();
  const country = useGetCountry();

  const {
    isLoading: isOptionsLoading,
    isError: isOptionsError,
    countries,
    currencies,
    integrationTypes,
    paymentMethodsByCountry,
  } = useGetSettingOptions(NativePaymentProviders.mercadoPago);
  const {
    isError,
    isLoading,
    updateStatus,
    paymentProvider,
    fetch,
    updatePaymentProviderSettings,
  } = useNativePaymentProviderSettings<MercadoPagoPaymentProviderDto>(
    NativePaymentProviders.mercadoPago,
  );
  const { isEnableAction } = useEnableMercadoPago();

  const {
    values,
    isDirty,
    errors,
    handleOnChange,
    handleOnChangeGroup,
    handleOnSubmit,
    setFieldValue,
    handleValidations,
  } = useForm({
    initialValues:
      paymentProvider?.settings as UpdateMercadoPagoSettingsRequestDto,
    validationSchema: getValidationSchema(paymentProvider),
    onSubmit: (values) => updatePaymentProviderSettings(buildPayload(values)),
  });

  useToastStatus({
    error: t('settings.toast.updateError'),
    success: t('settings.toast.updateSuccess'),
    status: !isEnableAction ? updateStatus : 'idle',
    onSuccess: () => replace(PAYMENTS_BASE_ROUTE),
  });

  const shouldShowPrompt =
    isDirty && updateStatus !== 'success' && !isEnableAction;
  const showForm =
    !isOptionsLoading &&
    !isLoading &&
    !isOptionsError &&
    !isError &&
    paymentProvider;
  const showCheckoutCard = !tags.includes(SMART_PAYMENT_ADMIN);
  const isBrasil = country === 'BR';

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={t('settings.mercadopago.title')} />}
    >
      <Prompt
        when={shouldShowPrompt}
        message={globalTranslation('common:exitEdit.info')}
      />
      {(isLoading || isOptionsLoading) && <MercadoPagoSettingsSkeleton />}
      {(isError || isOptionsError) && (
        <ErrorState
          title={t('settings.error.title')}
          action={{
            children: t('settings.error.action'),
            onClick: fetch,
          }}
        />
      )}
      {showForm && (
        <>
          <AccountCard
            username={paymentProvider.settings.accountUsername}
            email={paymentProvider.settings.accountEmail}
            countries={countries}
            currencies={currencies}
            values={values}
            handleOnChange={handleOnChange}
          />
          {!isBrasil && <TermsAndRatesCard />}
          {showCheckoutCard && (
            <CheckoutCard
              integrationTypes={integrationTypes}
              paymentMethodsByCountry={paymentMethodsByCountry}
              values={values}
              errors={errors}
              handleOnChange={handleOnChange}
              setFieldValue={setFieldValue}
            />
          )}
          {isBrasil && (
            <DiscountsCard
              discounts={paymentProvider.discounts || []}
              values={values}
              errors={errors}
              handleOnChangeGroup={handleOnChangeGroup}
            />
          )}
          <InstallmentsCard
            minInstallmentValues={paymentProvider.minInstallmentValues ?? []}
            values={values}
            errors={errors}
            handleOnChangeGroup={handleOnChangeGroup}
          />
          <CancelAndSaveButtonsWithAuth
            typeModal="centered"
            payload={buildPayload(values)}
            onSave={handleOnSubmit}
            preSubmitValidation={handleValidations}
            onCancel={goBack}
            isDisabled={!isDirty}
            isLoading={updateStatus === 'loading'}
          />
        </>
      )}
      {!!paymentProvider?.helpSiteUrl && (
        <HelpLink
          previousValue=""
          title={t('helpLink.title', { paymentProvider: paymentProvider.name })}
          currentViewTracking={t('helpLink.amplitudeName')}
          linkURL={paymentProvider?.helpSiteUrl}
          icon="both"
          appearance="neutral"
          showInMobile
        />
      )}
    </IonPageStratus>
  );
}

export default MercadoPagoSettingsPage;
