import { IconButton, Text, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

function ShippingListSkeleton() {
  return (
    <DataList bottomDivider={false}>
      <DataList.Row>
        <Stack justify="space-between" align="flex-start">
          <Stack column align="flex-start" spacing="base">
            <Stack spacing="tight">
              <Title.Skeleton as="h5" />
            </Stack>
            <Stack column spacing="none" align="flex-start">
              <Text.Skeleton width="100px" />
              <Text.Skeleton width="100px" />
            </Stack>
          </Stack>
          <Stack spacing="tight" justify="flex-end">
            <IconButton.Skeleton />
            <IconButton.Skeleton />
          </Stack>
        </Stack>
      </DataList.Row>
    </DataList>
  );
}

export default ShippingListSkeleton;
