import { Button, Icon } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useHistory } from 'react-router';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import emptyCouponsImage from './empty-coupons.png';

export function CouponsListEmpty() {
  const t = useTranslationMarketing('coupons.emptyResults');
  const { push } = useHistory();
  const handlePrimary = () => push(MARKETING_ROUTES.newCoupon);

  return (
    <EmptyMessage
      title={t('title')}
      text={t('body')}
      actions={
        <Button appearance="primary" onClick={handlePrimary}>
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          {t('action')}
        </Button>
      }
      illustration={<img width="100%" alt="" src={emptyCouponsImage} />}
    />
  );
}
