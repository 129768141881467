import { useRef, useEffect } from 'react';
import Inputmask from 'inputmask';

function useInputMask(maskOptions: Inputmask.Options) {
  const maskOptionsRef = useRef(maskOptions);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      Inputmask(maskOptionsRef.current).mask(inputRef.current);
    }
  }, []);

  return inputRef;
}

export default useInputMask;
