import { Box, Icon, Link } from '@nimbus-ds/components';
import { CopyIcon } from '@nimbus-ds/icons';
import { useClipboard } from 'commons/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface CopyDomainTextProps {
  children: string;
}

export function CopyDomainText({ children }: Readonly<CopyDomainTextProps>) {
  const copyToClipboard = useClipboard();
  const t = useTranslationConfigurations('domains');

  const copyNs = (ns) => () => {
    copyToClipboard(ns, t('softBlock.copySuccess'));
  };

  return (
    <Link
      textDecoration="none"
      appearance="primary"
      as="button"
      onClick={copyNs(children)}
    >
      <Box display="flex" gap="0-5">
        {children}
        <Icon color="primary-interactive" source={<CopyIcon />} />
      </Box>
    </Link>
  );
}
