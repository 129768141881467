import { AuthenticationFactorType } from '@tiendanube/common';
import {
  useResetUpdateAuthenticationFactor,
  useStatusMessages,
} from 'domains/Account/hooks';
import { trackingAuthenticationFactorConfiguration } from 'domains/Account/tracking';
import {
  AuthenticationFactorVerifyCodeCard,
  OnSubmitValues,
} from 'domains/AuthenticationFactor/components/AuthenticationFactorVerifyCodeCard';
import { useLinkAccountPage } from './useLinkAccountPage';

interface LinkAccountPageProps {
  onCancel: () => void;
  onContinue: () => void;
  active: boolean;
  authenticationFactor: AuthenticationFactorType;
}

function LinkAccountPage({
  onContinue,
  onCancel,
  active,
  authenticationFactor,
}: LinkAccountPageProps): JSX.Element {
  const { addProgressToast, addSuccessToast, closeProgressToast } =
    useStatusMessages('configuration');
  const resetUpdateAuthenticationFactor = useResetUpdateAuthenticationFactor();
  const {
    title,
    description,
    onSubmit,
    submitText,
    showSuccessToast,
    showCard,
    isLoading,
    isError,
    allowToUseRecoveryCodes,
  } = useLinkAccountPage(authenticationFactor, active);

  const onAfterVerify = async (isValid: boolean) => {
    if (isValid) {
      trackingAuthenticationFactorConfiguration(active);
      closeProgressToast();
      if (showSuccessToast) {
        addSuccessToast(active);
      }
      onContinue();
    } else {
      closeProgressToast();
    }
  };

  const handleCancel = () => {
    resetUpdateAuthenticationFactor();
    onCancel();
  };

  const handleSubmit = (data: OnSubmitValues) => {
    addProgressToast();
    return onSubmit(data);
  };

  return (
    <AuthenticationFactorVerifyCodeCard
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      onAfterVerify={onAfterVerify}
      isLoading={isLoading}
      isError={isError}
      title={title}
      description={description}
      submitText={submitText}
      card={showCard}
      allowToUseRecoveryCodes={allowToUseRecoveryCodes}
    />
  );
}

export default LinkAccountPage;
