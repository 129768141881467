import { useEffect } from 'react';
import { Alert, Button, Text } from '@nimbus-ds/components';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import {
  useGetPaymentProviderSettingsRoute,
  useRecentlyEnabled,
} from 'domains/Payments/PaymentProviders/hooks';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders.recentlyInstalledAlert';

function RecentlyEnabledAlert(): JSX.Element | null {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const { cleanRecentlyEnabled, recentlyEnabledPaymentProviderId } =
    useRecentlyEnabled();

  const id = recentlyEnabledPaymentProviderId || '';

  const { goToSettingsPage } = useGetPaymentProviderSettingsRoute(id);

  // Restrict show Alert to NuvemPago or Pago Nube
  const show = muvemPagoOrPagoNubeIds.includes(id);

  useEffect(
    () => () => {
      cleanRecentlyEnabled();
    },
    [cleanRecentlyEnabled],
  );

  useEffect(() => {
    show && invokeEventPayments('enabledAlert', id);
  }, [show, id]);

  if (!show) return null;

  return (
    <Alert
      show
      title={t(`${id}.title`)}
      appearance="success"
      onRemove={cleanRecentlyEnabled}
    >
      <Text>{t(`${id}.body`)}</Text>
      <Button appearance="primary" onClick={goToSettingsPage}>
        {t(`${id}.button`)}
      </Button>
    </Alert>
  );
}

export default RecentlyEnabledAlert;
