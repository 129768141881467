import {
  BillingPaymentResponseDto,
  BillingPlansResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  billingPlansEndpoints,
  billingPlansTags,
  BASE_BILLING_PLANS_URL,
  UPDATE_PLAN_URL,
  CREATE_PAYMENT_TO_UPDATE_PLAN_URL,
} from './config';

export const billingPlansApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [billingPlansTags.billingPlansList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [billingPlansEndpoints.getPlans]: builder.query<
        BillingPlansResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_BILLING_PLANS_URL,
        }),
        providesTags: [billingPlansTags.billingPlansList],
      }),
      [billingPlansEndpoints.updatePlan]: builder.mutation<
        BillingPaymentResponseDto,
        number
      >({
        query: (id) => ({
          method: 'POST',
          url: getUrlWithParams(UPDATE_PLAN_URL, { id }),
        }),
        onQueryStarted(id, { dispatch, queryFulfilled }) {
          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                billingPlansEndpoints.getPlans as never,
                undefined as never,
                (billing: BillingPlansResponseDto) => {
                  const updatedPlan = billing.plansList.find(
                    (plan) => plan.id === id,
                  );
                  if (updatedPlan) billing.currentPlan = updatedPlan;
                },
              ),
            );
          });
        },
      }),
      [billingPlansEndpoints.createPaymentToUpdatePlan]: builder.mutation<
        BillingPaymentResponseDto,
        string
      >({
        query: (id) => ({
          method: 'POST',
          url: getUrlWithParams(CREATE_PAYMENT_TO_UPDATE_PLAN_URL, { id }),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [billingPlansTags.billingPlansList],
      }),
    }),
  });

export const {
  useGetPlansQuery,
  useUpdatePlanMutation,
  useCreatePaymentToUpdatePlanMutation,
} = billingPlansApi;
