import * as Yup from 'yup';

export const buildEnableCountrySchema = (
  enabledCountriesIds: string[] | undefined,
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    countryId: Yup.string().test('validate', 'errorValidate', (value) => {
      if (enabledCountriesIds?.some((id) => id === value)) {
        return false;
      }
      return true;
    }),
  });
