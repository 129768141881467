import { useCallback, useEffect, useState } from 'react';
import { FEATURE_MAX_SHIPPING_INTERNATIONALS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';
import useToastStatus from 'commons/hooks/useToastStatus';
import { SHIPPING_ADD_INTERNATIONAL_METHOD } from 'config/upsellFlowSources';
import { useStatusFeatureByKeyWithDefaultValue } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import useGetInternationalShipping from './useGetInternationalShipping';
import { DeleteShippingModal } from '../../../components';
import ShippingCard from '../../../components/ShippingCard';

function InternationalShippingMethods() {
  const t = useTranslationShipping();

  const { goTo } = useNavegate();
  const [idToDelete, setIdToDelete] = useState('');
  const [isOpen, openModal, closeModal] = useModal();
  const {
    internationalShipping,
    isError,
    isLoading,
    fetchInternationalShipping,
    deleteInternationalShipping,
    deleteStatus,
  } = useGetInternationalShipping();

  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_MAX_SHIPPING_INTERNATIONALS,
    -1,
  );

  useEffect(() => {
    if (!internationalShipping && isAvailable) fetchInternationalShipping();
  }, [fetchInternationalShipping, internationalShipping, isAvailable]);

  const handleOnClose = useCallback(() => {
    setIdToDelete('');
    closeModal();
  }, [closeModal]);

  const handleOnDelete = (id: string) => {
    setIdToDelete(id);
    openModal();
  };

  const handleOnConfirm = () => {
    deleteInternationalShipping(idToDelete);
  };

  const handleClickAdd = useUpsellFlow({
    title: t('deliveryMethods.internationalShippingMethods.upsellTitle'),
    featureKey: FEATURE_MAX_SHIPPING_INTERNATIONALS,
    trackingSource: SHIPPING_ADD_INTERNATIONAL_METHOD,
    callback: () => goTo(DELIVERY_METHODS_ROUTES.international),
  });

  useToastStatus({
    status: deleteStatus,
    success: t(
      'deliveryMethods.internationalShippingMethods.toastSuccess.deleted',
    ),
    error: t(
      'deliveryMethods.internationalShippingMethods.toastError.elimination',
    ),
    onSuccess: handleOnClose,
    onError: handleOnClose,
  });

  return (
    <>
      <ShippingCard
        title={`${t('deliveryMethods.internationalShippingMethods.title')}`}
        subTitle={t('deliveryMethods.internationalShippingMethods.subTitle')}
        linkText={t('deliveryMethods.internationalShippingMethods.link')}
        shippingList={internationalShipping ?? []}
        onClickAddDelivery={handleClickAdd}
        editDeliveryRoute={DELIVERY_METHODS_ROUTES.international}
        isError={isError}
        isLoading={isLoading}
        fetchData={fetchInternationalShipping}
        handleDelete={handleOnDelete}
      />
      <DeleteShippingModal
        title={t('deliveryMethods.deleteShippingModal.title')}
        description={t('deliveryMethods.deleteShippingModal.message')}
        isLoading={deleteStatus === 'loading'}
        onConfirmDelete={handleOnConfirm}
        isOpen={isOpen}
        onClose={handleOnClose}
      />
    </>
  );
}

export default InternationalShippingMethods;
