import { Box, Card, Icon, Text, Title } from '@nimbus-ds/components';
import { ExternalLink, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface HelpCardProps {
  readonly title: string;
  readonly icon: React.ReactNode;
  readonly text: string;
  readonly href: string;
}

export function HelpCard({ title, icon, text, href }: HelpCardProps) {
  const t = useTranslationConfigurations('domains.help');

  return (
    <Card>
      <Card.Header>
        <Stack>
          <Box
            backgroundColor="primary-surface"
            padding="2"
            borderRadius="base"
            color="primary-interactive"
          >
            <Icon color="currentColor" source={icon} />
          </Box>
          <Title as="h5">{title}</Title>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Text>{text}</Text>
      </Card.Body>
      <Card.Footer>
        <ExternalLink appearance="primary" textDecoration="none" href={href}>
          {t('openTutorialBtn')}
        </ExternalLink>
      </Card.Footer>
    </Card>
  );
}
