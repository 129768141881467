import { logEvent } from 'commons/utils/tracking';
import {
  CORE_APP_STORE_DROPSHIPPING_APP_CLICK,
  CORE_APP_STORE_DROPSHIPPING_LIST_CLICK,
  CORE_PRODUCT_ADD_ONBOARDING_CLICK,
  CORE_PRODUCT_IMPORT_ONBOARDING_CLICK,
  INVENTORY_HISTORY_CLICK,
  PRODUCT_ACTION_DUPLICATE_PRODUCT_MODAL,
  PRODUCT_ADD_NEW_PRODUCT_CLICK,
  PRODUCT_EXPORT_IMPORT,
  PRODUCT_LIST_ACTIONS_COPY_CLICK,
  PRODUCT_MASS_UPLOAD_IGNORE_ALL_COLUMNS,
  PRODUCT_SHOW_MORE_VARIANTS_ON_INVENTORY_LIST,
  PRODUCT_STOCK_CALCULATOR_CHANGE,
  PRODUCT_STOCK_CALCULATOR_FROM_INVENTORY,
  PRODUCT_STOCK_CHANGE_ON_INVENTORY_LIST,
  PRODUCT_STOCK_INVENTORY_FROM_INVENTORY,
  PRODUCT_VARIANT_METAFIELD_FILTER,
  PRODUCT_VARIANT_METAFIELD_FILTER_ERROR,
  PRODUCT_VARIANT_METAFIELD_SAVE,
  PRODUCT_VARIANT_METAFIELD_SAVE_ERROR,
  VARIANT_APPLY_TO_ALL,
  WEIGHT_AND_VOLUME_ALERT_CLICK,
  WEIGHT_AND_VOLUME_ALERT_CLOSE,
  PRODUCT_STOCK_EDIT_CLICK,
  PRODUCT_VARIANT_STOCK_EDIT_CLICK,
  PRODUCT_EDIT_ON_PRODUCT_LIST,
  CATALOG_PRODUCT_ORGANIZE_CLICK,
  CATEGORIES_TREE_SAVE_BEFORE,
  CATEGORIES_TREE_SAVE_AFTER,
  PRODUCT_EDIT_NEW_VARIANT_STOCK,
  CATALOG_PRODUCT_DETAIL_METAFIELD_CLICK,
  CATALOG_PRODUCT_DETAIL_IMAGES_CLICK,
  CATALOG_PRODUCT_DETAIL_VIDEO_BLUR,
  CATALOG_PRODUCT_DETAIL_SKU_BLUR,
  CATALOG_PRODUCT_LIST_FILTER_SEARCH,
  CATALOG_PRODUCT_LIST_ORDER_CLICK,
  CATALOG_PRODUCT_LIST_DUPLICATE_CLICK,
  CATALOG_PRODUCT_LIST_DELETE_CLICK,
  CATALOG_PRODUCT_DETAIL_IMAGES_SUCCESS,
  CATALOG_PRODUCT_DETAIL_IMAGES_ERROR,
  CATALOG_PRODUCT_DETAIL_CATEGORY_CLICK,
  CATALOG_PRODUCT_DETAIL_CANCEL_CLICK,
  CATALOG_PRODUCT_DETAIL_PRICE_BLUR,
  CATALOG_PRODUCT_DETAIL_PROMOTIONAL_BLUR,
  CATALOG_PRODUCT_DETAIL_COST_BLUR,
  CATALOG_PRODUCT_DETAIL_WEIGHT_BLUR,
  CATALOG_PRODUCT_DETAIL_DEPTH_BLUR,
  CATALOG_PRODUCT_DETAIL_WIDTH_BLUR,
  CATALOG_PRODUCT_DETAIL_HEIGHT_BLUR,
  CATALOG_PRODUCT_DETAIL_NETWORK_BLUR,
  CATALOG_PRODUCT_DETAIL_SEO_BLUR,
  CATALOG_PRODUCT_DETAIL_SAVE_SUCCESS,
  CATALOG_PRODUCT_DETAIL_SAVE_ERROR,
  CATALOG_PRODUCT_DROP_IMAGES,
  CATEGORIES_CREATE_BUTTON_CLICK,
  CATEGORIES_DESCRIPTION_IMAGE_CLICK,
  CATEGORIES_DESRIPTION_ON_BLUR,
  SHIPPING_DELETE_DISTRIBUTION_CENTER,
  CATALOG_INVENTORY_LIST_SELECTOR_CLICK,
  CATEGORIES_CATEGORY_CLICK,
  CATEGORIES_DELETE_CLICK,
  CATEGORIES_EDIT_CLICK,
  CATEGORIES_SUBCATEGORY_CLICK,
  CATALOG_PRODUCT_DETAIL_CREATE_CATEGORY_SUCCESS,
  CATEGORIES_HIDE_CLICK,
  CATEGORIES_UNHIDE_CLICK,
  CATALOG_PRODUCT_DETAIL_VARIANT_HIDE_SELECT,
} from 'config/trackingEvents';
import { OptionEditType, OptionType } from './utils';

const digestEdit = (isEdit?: boolean) =>
  isEdit ? { mode: 'edit' } : { mode: 'create' };
export const trackingApplyToAllVariants = (): void => {
  logEvent(VARIANT_APPLY_TO_ALL, {});
};

export const trackingProductListActionsCopyClick = (
  productUrl: string,
): void => {
  logEvent(PRODUCT_LIST_ACTIONS_COPY_CLICK, { 'product-url': productUrl });
};

export const trackingProductActionDuplicateProductModal = (
  productName: string,
): void => {
  logEvent(PRODUCT_ACTION_DUPLICATE_PRODUCT_MODAL, {
    'product-name': productName,
  });
};

export const trackingInventoryHistoryClick = (): void => {
  logEvent(INVENTORY_HISTORY_CLICK, {});
};

export const trackingProductVariantMetafieldSave = (
  metafieldId: string,
  productId: string,
  value: string | null,
): void => {
  logEvent(PRODUCT_VARIANT_METAFIELD_SAVE, {
    metafieldId,
    productId: productId || '',
    value: `${value}`,
  });
};

export const trackingProductVariantMetafieldSaveError = (
  productId: string,
  errorOnEvent: string,
): void => {
  logEvent(PRODUCT_VARIANT_METAFIELD_SAVE_ERROR, {
    productId: productId || '',
    errorOnEvent,
  });
};

export const trackingProductVariantMetafieldFilter = (
  metafieldId: string,
  value: string,
): void => {
  logEvent(PRODUCT_VARIANT_METAFIELD_FILTER, {
    metafieldId,
    value,
  });
};

export const trackingProductVariantMetafieldFilterError = (): void => {
  logEvent(PRODUCT_VARIANT_METAFIELD_FILTER_ERROR, {});
};

export const trackingProductAddNewProductClick = (): void => {
  logEvent(PRODUCT_ADD_NEW_PRODUCT_CLICK, {});
};

export const trackingProductExportImport = (): void => {
  logEvent(PRODUCT_EXPORT_IMPORT, {});
};

export const trackingIgnoreAllColumns = (): void => {
  logEvent(PRODUCT_MASS_UPLOAD_IGNORE_ALL_COLUMNS, {});
};

export const trackingWeightAndDimensionsAlertClick = (): void => {
  logEvent(WEIGHT_AND_VOLUME_ALERT_CLICK, {});
};

export const trackingWeightAndDimensionsAlertClose = (): void => {
  logEvent(WEIGHT_AND_VOLUME_ALERT_CLOSE, {});
};

export const trackingProductStockChangeOnInventoryList = (): void => {
  logEvent(PRODUCT_STOCK_CHANGE_ON_INVENTORY_LIST, {});
};

export const trackingProductStockInventoryFromInventory = (): void => {
  logEvent(PRODUCT_STOCK_INVENTORY_FROM_INVENTORY, {});
};

export const trackingProductStockCalculatorFromInventory = (): void => {
  logEvent(PRODUCT_STOCK_CALCULATOR_FROM_INVENTORY, {});
};

export const trackingProductStockEditClick = (previousStock): void => {
  //param previousStock: to identify the stock just before adding the stock change
  logEvent(PRODUCT_STOCK_EDIT_CLICK, {
    previousStock,
  });
};

export const trackingProductVariantStockEditClick = (previousVariant): void => {
  logEvent(PRODUCT_VARIANT_STOCK_EDIT_CLICK, { previousVariant });
};

export const trackingProductEditOnProductList = (type: OptionType): void => {
  logEvent(PRODUCT_EDIT_ON_PRODUCT_LIST, {
    type,
  });
};

export const trackingProductStockCalculatorChange = (
  action: OptionEditType,
  hasAllVariants: boolean,
  hasInfinite: boolean,
  hasCause: boolean,
) => {
  const actionTracking = action === 'discount' ? 'subtract' : action;
  logEvent(PRODUCT_STOCK_CALCULATOR_CHANGE, {
    action: actionTracking,
    has_all_variants: String(hasAllVariants),
    has_infinite: String(hasInfinite),
    has_cause: String(hasCause),
  });
};

export const trackingProductShowMoreVariantsOnInventoryList = (): void => {
  logEvent(PRODUCT_SHOW_MORE_VARIANTS_ON_INVENTORY_LIST, {});
};
export const trackingCoreProductAddOnboardingClick = (): void => {
  logEvent(CORE_PRODUCT_ADD_ONBOARDING_CLICK, {});
};

export const trackingCoreProductImportOnboardingClick = (): void => {
  logEvent(CORE_PRODUCT_IMPORT_ONBOARDING_CLICK, {});
};

export const trackingCoreAppStoreDropshippingAppClick = (): void => {
  logEvent(CORE_APP_STORE_DROPSHIPPING_APP_CLICK, {});
};

export const trackingCoreAppStoreDropshippingListClick = (): void => {
  logEvent(CORE_APP_STORE_DROPSHIPPING_LIST_CLICK, {});
};

export const trackingProductOrganizeClick = (categoryId = ''): void => {
  logEvent(CATALOG_PRODUCT_ORGANIZE_CLICK, {
    categoryId,
  });
};

export const trackingCategoriesTree = (
  action: 'beforeSave' | 'afterSave',
  treeData = '',
): void => {
  logEvent(
    action === 'afterSave'
      ? CATEGORIES_TREE_SAVE_AFTER
      : CATEGORIES_TREE_SAVE_BEFORE,
    {
      treeData,
    },
  );
};

export const trackingEditNewVariantStock = (diferences) => {
  const variants = diferences.variants
    .filter((variant) => variant.id === '')
    .map((variant) => ({
      name: variant.name,
      stock: variant.stock,
    }));
  if (variants.length > 0) {
    logEvent(PRODUCT_EDIT_NEW_VARIANT_STOCK, {
      variants: JSON.stringify(variants),
    });
  }
};

export const trackingMetafieldAlertClick = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_METAFIELD_CLICK, digestEdit(isEdit));
};

export const trackingProductDetailImageClick = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_IMAGES_CLICK, digestEdit(isEdit));
};

export const trackingProductDetailImageSuccess = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_IMAGES_SUCCESS, digestEdit(isEdit));
};

export const trackingProductDetailImageError = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_IMAGES_ERROR, digestEdit(isEdit));
};

export const trackingProductDetailVideoBlur = (
  hasValue: boolean,
  isEdit?: boolean,
): void => {
  const updateEdit = {
    ...digestEdit(isEdit),
    has_value: String(hasValue),
  };
  logEvent(CATALOG_PRODUCT_DETAIL_VIDEO_BLUR, updateEdit);
};

export const trackingProductDetailPriceBlur = (
  data,
  isEdit?: boolean,
): void => {
  const updateEdit = {
    ...digestEdit(isEdit),
    data: JSON.stringify(data),
  };
  logEvent(CATALOG_PRODUCT_DETAIL_PRICE_BLUR, updateEdit);
};

export const trackingProductDetailPromotionalBlur = (
  data,
  isEdit?: boolean,
): void => {
  const updateEdit = {
    ...digestEdit(isEdit),
    data: JSON.stringify(data),
  };
  logEvent(CATALOG_PRODUCT_DETAIL_PROMOTIONAL_BLUR, updateEdit);
};

export const trackingProductDetailCostBlur = (data, isEdit?: boolean): void => {
  const updateEdit = {
    ...digestEdit(isEdit),
    data: JSON.stringify(data),
  };
  logEvent(CATALOG_PRODUCT_DETAIL_COST_BLUR, updateEdit);
};

export const trackingProductDetailSKUBlur = (
  hasValue: boolean,
  isEdit?: boolean,
): void => {
  const updateEdit = {
    ...digestEdit(isEdit),
    has_value: String(hasValue),
  };
  logEvent(CATALOG_PRODUCT_DETAIL_SKU_BLUR, updateEdit);
};

export const trackingProductDetailWeightBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_WEIGHT_BLUR, digestEdit(isEdit));
};

export const trackingProductDetailDepthBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_DEPTH_BLUR, digestEdit(isEdit));
};

export const trackingProductDetailWidthBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_WIDTH_BLUR, digestEdit(isEdit));
};

export const trackingProductDetailHeightBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_HEIGHT_BLUR, digestEdit(isEdit));
};
export const trackinProductDetailMPNBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_NETWORK_BLUR, digestEdit(isEdit));
};

export const trackingProductDetailCategoriesClick = (
  isEdit?: boolean,
): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_CATEGORY_CLICK, digestEdit(isEdit));
};

export const trackingProductDetailSEOBlur = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_SEO_BLUR, digestEdit(isEdit));
};

export const trackingProductDetailSaveSuccess = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_SAVE_SUCCESS, digestEdit(isEdit));
};

export const trackingProductDetailSaveError = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_SAVE_ERROR, digestEdit(isEdit));
};

export const trackingProductDetailCancelClick = (isEdit?: boolean): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_CANCEL_CLICK, digestEdit(isEdit));
};

export const trackingProductListFilterEvent = (query): void => {
  logEvent(CATALOG_PRODUCT_LIST_FILTER_SEARCH, {
    query: JSON.stringify(query),
  });
};

export const trackingProductListOrderClick = (): void => {
  logEvent(CATALOG_PRODUCT_LIST_ORDER_CLICK, {});
};

export const trackingProductListDuplicateIconClick = (): void => {
  logEvent(CATALOG_PRODUCT_LIST_DUPLICATE_CLICK, {});
};

export const trackingProductListDeleteIconClick = (): void => {
  logEvent(CATALOG_PRODUCT_LIST_DELETE_CLICK, {});
};

export const trackingProductDropImages = ({
  from,
  count,
  isEdit,
}: {
  from: 'card' | 'modal';
  count: number;
  isEdit: boolean;
}): void => {
  const payload = {
    ...digestEdit(isEdit),
    from,
    count: `${count}`,
  };
  logEvent(CATALOG_PRODUCT_DROP_IMAGES, payload);
};

export const trackingCategoriesCreateButtonClick = (): void => {
  logEvent(CATEGORIES_CREATE_BUTTON_CLICK, {});
};

export const trackingCategoriesActionSubcategoryClick = (): void => {
  logEvent(CATEGORIES_SUBCATEGORY_CLICK, {});
};

export const trackingCategoriesActionEditClick = (): void => {
  logEvent(CATEGORIES_EDIT_CLICK, {});
};

export const trackingCategoriesActionHideClick = (): void => {
  logEvent(CATEGORIES_HIDE_CLICK, {});
};

export const trackingCategoriesActionUnhideClick = (): void => {
  logEvent(CATEGORIES_UNHIDE_CLICK, {});
};

export const trackingCategoriesActionDeleteClick = (): void => {
  logEvent(CATEGORIES_DELETE_CLICK, {});
};

export const trackingCategoriesActionCategoryClick = (): void => {
  logEvent(CATEGORIES_CATEGORY_CLICK, {});
};

export const trackingCategoriesDescriptionImageClick = (): void => {
  logEvent(CATEGORIES_DESCRIPTION_IMAGE_CLICK, {});
};

export const trackingCategoriesDescriptionOnBlur = (
  isExtended: boolean,
): void => {
  logEvent(CATEGORIES_DESRIPTION_ON_BLUR, { is_extended: String(isExtended) });
};

export const trackingShippingDeleteDistributionCenter = (
  locationId: string,
) => {
  logEvent(SHIPPING_DELETE_DISTRIBUTION_CENTER, {
    locationId: locationId,
  });
};

export const trackingInventoryListSelectorClick = () => {
  logEvent(CATALOG_INVENTORY_LIST_SELECTOR_CLICK, {});
};

export const trackingProductDetailCreateCategorySuccess = (): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_CREATE_CATEGORY_SUCCESS, {});
};

export const trackingProductDetailVariantHideSelect = (
  isHidden: boolean,
): void => {
  logEvent(CATALOG_PRODUCT_DETAIL_VARIANT_HIDE_SELECT, {
    is_hidden: String(isHidden),
  });
};
