import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import {
  changeStatsProductsFilter,
  getStatsProducts,
} from 'domains/Statistics/statisticsSlice';
import { StatsProductsItemType, ProductsStatsFilters } from '../../types';
import useGetStatsProductsDataData from '../useGetStatsProductsDataData';
import useStatisticsStoredFilters from '../useStatisticsStoredFilters';

function useStatsProductsByItem(item: StatsProductsItemType) {
  const dispatch = useAppDispatch();
  const data = useGetStatsProductsDataData(item);

  const fetchStatisticsProducts = useCallback(async () => {
    await dispatch(getStatsProducts(item));
  }, [dispatch, item]);

  const onApplyProductsFilters = useCallback(
    (newFilters: ProductsStatsFilters) => {
      dispatch(changeStatsProductsFilter({ values: newFilters, item }));
      fetchStatisticsProducts();
    },
    [dispatch, fetchStatisticsProducts, item],
  );

  useStatisticsStoredFilters(fetchStatisticsProducts);

  return {
    ...data,
    fetchStatisticsProducts,
    onApplyProductsFilters,
  };
}

export default useStatsProductsByItem;
