import { Input } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/types';

function DateItem({
  metafield,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const handleChange = ({ target: { value } }) => {
    onChange?.(value);
    onUpdate?.(value || null);
  };

  return (
    <Input
      type="date"
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      name={metafield.name}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}

export default DateItem;
