import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { usePatchUpdatePlanChargeMutation } from '../CheckoutApi';

function useUpdateCharge() {
  const [updateCharge, { status }] = usePatchUpdatePlanChargeMutation();

  return {
    updateCharge,
    status: toStatusType(status),
    ...convertStatusTypeToObject(status),
  };
}

export default useUpdateCharge;
