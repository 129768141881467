import { ChangeEvent, useEffect } from 'react';
import { Alert, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import ShippingModalityCard from '../ShippingModalityCard';

export interface OptionsCarriersInterface {
  id: string;
  code: string;
  optionName: string;
  isEnabled: boolean;
  allowedFreeShipping: boolean;
  additionalCost: string;
  additionalDays: string;
}

interface CarriersFormProps {
  values: Record<string, OptionsCarriersInterface>;
  isCompatibleWithMultiCD: boolean;
  handleOnChangeGroup: (
    group: string,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
}
function CarriersForm({
  values,
  isCompatibleWithMultiCD,
  handleOnChangeGroup,
}: CarriersFormProps) {
  const t = useTranslationShipping();
  const { locations, fetchLocations } = useLocations();

  useEffect(() => {
    if (!locations) fetchLocations();
  }, [locations, fetchLocations]);

  return (
    <Stack column align="stretch" spacing="base">
      {!isCompatibleWithMultiCD && locations.length > 1 && (
        <Alert
          appearance="warning"
          title={t(
            'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.isMultiCDIncompatibleTitle',
          )}
        >
          {t(
            'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.isMultiCDIncompatibleDescription',
          )}
        </Alert>
      )}
      <Title as="h3">
        {`${t(
          'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.title',
        )}`}
      </Title>
      <Text>
        {t(
          'deliveryMethods.activeShippingMethods.editPage.modalitiesCard.text',
        )}
      </Text>
      {Object.keys(values).length > 0 &&
        Object.keys(values).map((key) => {
          const item = values[key];
          return (
            <ShippingModalityCard
              key={item.code}
              code={item.code}
              name={item.optionName}
              additionalCost={item?.additionalCost ?? ''}
              additionalDays={item?.additionalDays ?? ''}
              isEnabled={item.isEnabled}
              allowedFreeShipping={item.allowedFreeShipping}
              onChange={handleOnChangeGroup}
            />
          );
        })}
    </Stack>
  );
}

CarriersForm.Skeleton = Skeleton;

export default CarriersForm;
