import { Card, Checkbox, Input, Text } from '@nimbus-ds/components';
import { CancelAndSaveButtons, Stack } from 'commons/components';

function AdvancedSettingsModalSkeleton() {
  return (
    <>
      <Card>
        <Card.Header>
          <Stack column align="stretch" spacing="tight">
            <Text.Skeleton />
            <Text.Skeleton />
          </Stack>
        </Card.Header>
        <Card.Body padding="base">
          <Stack column align="stretch">
            <Input.Skeleton />
            <Input.Skeleton />
            <Input.Skeleton />
          </Stack>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Stack column align="stretch" spacing="tight">
            <Text.Skeleton />
            <Text.Skeleton />
          </Stack>
        </Card.Header>
        <Card.Body padding="base">
          <Stack column align="stretch">
            <Checkbox.Skeleton />
            <Checkbox.Skeleton />
          </Stack>
        </Card.Body>
      </Card>
      <CancelAndSaveButtons.Skeleton />
    </>
  );
}

export default AdvancedSettingsModalSkeleton;
