import { Responsive } from 'commons/components';
import { ImagePreviewDesktop, ImagePreviewMobile } from './components';
import { ImagePreviewsProps } from './components/types';

import './ImagePreviews.scss';

function ImagePreviews({
  images,
  message,
  isEdit,
  openModal,
  onError,
  onClickAdd,
  onRemove,
  onEdit,
  onUploadImages,
}: ImagePreviewsProps): JSX.Element {
  return (
    <Responsive
      mobile={
        <ImagePreviewMobile
          images={images}
          message={message}
          openModal={openModal}
          onError={onError}
          onClickAdd={onClickAdd}
          onRemove={onRemove}
          onEdit={onEdit}
        />
      }
      desktop={
        <ImagePreviewDesktop
          isEdit={isEdit}
          images={images}
          message={message}
          openModal={openModal}
          onError={onError}
          onClickAdd={onClickAdd}
          onRemove={onRemove}
          onEdit={onEdit}
          onUploadImages={onUploadImages}
        />
      }
    />
  );
}

export default ImagePreviews;
