import { Box, Card, Skeleton, Title } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function DesignPreferencesCardSkeleton() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.designPreferencesCard',
  );

  return (
    <Card>
      <Card.Header>
        <Title fontSize="h2">{t('title')}</Title>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="row" gap="2">
          <Skeleton height="1rem" width="16px" />
          <Skeleton height="1rem" width="200px" />
        </Box>
      </Card.Body>
    </Card>
  );
}
