import { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Box,
  Input,
  Text,
  InputProperties,
  Spinner,
  Link,
} from '@nimbus-ds/components';
import { CurrencyResponseDto } from '@tiendanube/common';
import { useBoolean } from 'commons/hooks';
import {
  useGetCurrenciesQuery,
  useGetSuggestedRateQuery,
  useUpdateExchangeRateMutation,
} from 'domains/Configurations/I18n/i18nApi';
import { mainCurrencyCodeData } from 'domains/Configurations/I18n/utils';
import { TTL_CLEAN_APPEARENCE } from 'domains/Configurations/I18n/utils/constants';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface ExchangeRateInputProps {
  exchangeRate: CurrencyResponseDto;
}

function ExchangeRateInput({ exchangeRate }: ExchangeRateInputProps) {
  const [value, setValue] = useState(exchangeRate.equivalentTo);
  const [statusRequest, closeRequest, openRequest] = useBoolean(true);
  const t = useTranslationConfigurations(
    'languagesAndCurrencies.exchangeRates',
  );
  const { mainCurrencyCode } = useGetCurrenciesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      mainCurrencyCode: mainCurrencyCodeData(data),
    }),
  });

  const {
    isLoading: isLoadingSuggested,
    data,
    isSuccess: isSuccessSuggested,
  } = useGetSuggestedRateQuery(
    {
      equivalentCurrency: exchangeRate.currencyCode,
      mainCurrency: mainCurrencyCode,
    },
    {
      skip: statusRequest,
    },
  );

  const [appearance, setAppearance] =
    useState<InputProperties['appearance']>('neutral');

  const [
    updateExchangeRate,
    {
      isLoading: isLoadingExchangeRate,
      isSuccess: isSuccessExchangeRate,
      isError,
    },
  ] = useUpdateExchangeRateMutation();

  const handleBlur = () => {
    if (exchangeRate.equivalentTo === value) return;
    updateValue(value);
  };

  const updateValue = useCallback(
    (value: string) => {
      updateExchangeRate({
        currencyCode: exchangeRate.currencyCode,
        body: {
          rate: value,
        },
      });
    },
    [exchangeRate.currencyCode, updateExchangeRate],
  );
  const isSuccess = isSuccessExchangeRate && !isLoadingExchangeRate;

  useEffect(() => {
    setValue(exchangeRate.equivalentTo);
  }, [exchangeRate.equivalentTo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAppearance('neutral');
      if (isError) {
        setValue(exchangeRate.equivalentTo);
      }
    }, TTL_CLEAN_APPEARENCE);

    if (isSuccess) {
      setAppearance('success');
      return;
    }

    if (isError) {
      setAppearance('danger');
      return;
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, isError, setValue, exchangeRate.equivalentTo]);
  useEffect(() => {
    if (data && isSuccessSuggested) {
      updateValue(data.rate);
      closeRequest();
    }
  }, [isSuccessSuggested, data, closeRequest, updateValue]);

  const isLoading = isLoadingExchangeRate || isLoadingSuggested;

  return (
    <>
      <Table.Cell>
        <Input
          appendPosition={isLoading ? 'end' : 'start'}
          name="currency"
          append={
            isLoading ? (
              <Spinner color="neutral-textLow" size="small" />
            ) : (
              <Text>{mainCurrencyCode}</Text>
            )
          }
          onChange={({ target: { value } }) => setValue(value)}
          value={value}
          onBlur={handleBlur}
          appearance={appearance}
        />
      </Table.Cell>
      <Table.Cell>
        <Box paddingTop="1-5">
          <Link
            as="a"
            appearance="primary"
            textDecoration="none"
            onClick={openRequest}
          >
            {t('table.suggestion')}
          </Link>
        </Box>
      </Table.Cell>
    </>
  );
}

export default ExchangeRateInput;
