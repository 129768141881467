import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';

function PaymentLinkCardSkeleton(): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('draftOrders.paymentLinkCard.title')}>
      <Stack column align="stretch" spacing="tight">
        <Text.Skeleton width="large" />
        <Text.Skeleton />
      </Stack>
    </Card>
  );
}

export default PaymentLinkCardSkeleton;
