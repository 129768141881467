import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import SectionProductsAndVariantsMetafields, {
  SectionProductsAndVariantsMetafieldsProps,
} from '../SectionProductsAndVariantsMetafields';

type SectionProductsAndVariantsMetafieldsNewProps = Omit<
  SectionProductsAndVariantsMetafieldsProps,
  'showLoading' | 'showError' | 'showSuccess'
>;

export default function SectionProductsAndVariantsMetafieldsNew({
  ...rest
}: Readonly<SectionProductsAndVariantsMetafieldsNewProps>) {
  const { status } = useGetProductsAndVariantsMetafields();
  const { isError, isLoading, isSuccess } = status;

  const showError = isError;
  const showLoading = isLoading;
  const showSuccess = isSuccess;

  return (
    <SectionProductsAndVariantsMetafields
      {...rest}
      showError={showError}
      showLoading={showLoading}
      showSuccess={showSuccess}
    />
  );
}
