import useGetStoreHasGA from 'domains/Statistics/hooks/useGetStoreHasGA/useGetStoreHasGA';
import GoogleAnalyticsCardSetup from '../GoogleAnalyticsCardSetup/GoogleAnalyticsCardSetup';
import GoogleAnalyticsDifferencesCard from '../GoogleAnalyticsDifferencesCard/GoogleAnalyticsDifferencesCard';

function GoogleAnalyticsCard() {
  const { isLoading, isError: hasGAIsError, hasGA } = useGetStoreHasGA();

  if (isLoading || hasGAIsError) return null;

  return hasGA ? (
    <GoogleAnalyticsDifferencesCard />
  ) : (
    <GoogleAnalyticsCardSetup />
  );
}

export default GoogleAnalyticsCard;
