import { Tag } from '@nimbus-ds/components';
import {
  ExclamationTriangleIcon,
  LockIcon,
  LockOpenIcon,
} from '@nimbus-ds/icons';
import { DomainSSLStatus } from '@tiendanube/common';
import { useCustomDomainDisabled } from 'domains/Configurations/Domains/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

const ICON_SIZE = 14;

interface DomainSSLStatusTagProps {
  status: DomainSSLStatus;
  isOurDomain: boolean;
}

export function DomainSSLStatusTag({
  status,
  isOurDomain,
}: Readonly<DomainSSLStatusTagProps>) {
  const t = useTranslationConfigurations('domains');
  const isFreePlan = useCustomDomainDisabled();

  if (isFreePlan && !isOurDomain)
    return (
      <Tag appearance="neutral">
        <ExclamationTriangleIcon size={ICON_SIZE} />
        {t('tags.inactiveSSL')}
      </Tag>
    );

  if (status === 'ok')
    return (
      <Tag appearance="success">
        <LockIcon size={ICON_SIZE} />
        {t('tags.activeSSL')}
      </Tag>
    );

  if (status === 'pending')
    return (
      <Tag appearance="warning">
        <LockOpenIcon size={ICON_SIZE} />
        {t('tags.pendingSSL')}
      </Tag>
    );

  return (
    <Tag appearance="warning">
      <LockOpenIcon size={ICON_SIZE} />
      {t('tags.inactiveSSL')}
    </Tag>
  );
}
