import { Text } from '@nimbus-ds/components';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { Stack } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import { useGetLimitingFeatureByKey } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { STATS_PLAN_FREE } from 'domains/Statistics/utils';
import { Tablike } from './components';
import StatsDailyValueCard from '../CardStatisticsDaily/StatsDailyTabContent/StatsDailyValueCard';

const HOURS_BREAKPOINT = 72; // Show in days if more than this many hours

function splitIntoDaysHoursMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > HOURS_BREAKPOINT) {
    const days = Math.floor(hours / 24);
    const hoursRounding = minutes >= 30 ? 1 : 0;
    return { days, hours: (hours % 24) + hoursRounding, minutes: 0 };
  }

  return { days: 0, hours, minutes };
}

interface AiUsageReportProps {
  minutesSaved: number;
  moneySavedValue: number;
  moneySavedCurrency: CurrencyType;
}

function AiUsageReport({
  minutesSaved,
  moneySavedValue,
  moneySavedCurrency,
}: Readonly<AiUsageReportProps>) {
  const t = useTranslationDashboard('aiReport');
  const {
    i18n: { language },
  } = useTranslation();

  // This is used for cosmetic consistency with <CardStatisticsDaily>
  const isPlanFree = useGetLimitingFeatureByKey(
    FEATURE_NEW_ADMIN_STATS,
    STATS_PLAN_FREE,
  );

  const { days, hours, minutes } = splitIntoDaysHoursMinutes(minutesSaved);

  const formattedMoneySaved = formatCurrency(
    moneySavedValue,
    moneySavedCurrency,
    language,
  );

  const month = new Date().getMonth();
  const title = (
    <Trans
      t={t}
      i18nKey="title"
      components={{ bold: <strong /> }}
      values={{
        month: t(`months.${month}`),
      }}
    />
  );

  const cards = (
    <>
      <StatsDailyValueCard
        title={t('timeSaved.label')}
        value={
          days > 0
            ? t('timeSaved.formatDaysHours', { days, hours })
            : t('timeSaved.formatHoursMinutes', { hours, minutes })
        }
        textTooltip={t('timeSaved.tooltip')}
      />
      <StatsDailyValueCard
        title={t('moneySaved.label')}
        value={formattedMoneySaved}
        textTooltip={t('moneySaved.tooltip')}
      />
    </>
  );

  return isPlanFree ? (
    <Stack column align="stretch">
      <Text fontSize="highlight">{title}</Text>
      {cards}
    </Stack>
  ) : (
    <Tablike label={title}>
      <Stack column align="stretch">
        {cards}
      </Stack>
    </Tablike>
  );
}

export default AiUsageReport;
