import { Browser } from '@capacitor/browser';
import { TIENDA_NUBE_THEMES_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useIsMobileDevice } from 'domains/Auth/hooks';

export function useGoToThemesStore() {
  const isMobileDevice = useIsMobileDevice();
  const language = useTranslationLanguage();

  const handleGoToThemesStore = async () => {
    if (isMobileDevice) {
      await Browser.open({ url: TIENDA_NUBE_THEMES_LINKS[language] });
    } else {
      openWindow(TIENDA_NUBE_THEMES_LINKS[language], false);
    }
  };

  return handleGoToThemesStore;
}
