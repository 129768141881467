import { memo } from 'react';
import VirtualDataList from 'commons/components/VirtualDataList';
import { MessagesListMobileSkeleton, MessagesListRow } from './components';
import { useBulkActionMessages } from '../../hooks';
import useMessages from '../../hooks/useMessages';
import { useBulkActions } from '../BulkActionsProvider';

interface MessagesResultsInterface {
  onToggleEditMode: () => void;
  isEditMode: boolean;
}

function MessagesListMobile({
  onToggleEditMode,
  isEditMode,
}: MessagesResultsInterface): JSX.Element {
  const {
    messagesIds,
    isLoading,
    fetchMessages,
    isRefreshing,
    fetchMoreMessages,
  } = useMessages();

  const { selectedRowsId } = useBulkActions();

  const { bulkAction, handleOnSelectRow } = useBulkActionMessages({
    rowsId: messagesIds,
  });

  const handleRefresh = async () => {
    if (isLoading || isRefreshing) return;
    await fetchMessages();
  };

  const shouldShowSkeleton = isLoading && messagesIds.length === 0;
  const shouldShowSpinner = isRefreshing && messagesIds.length !== 0;

  return (
    <>
      {shouldShowSkeleton && <MessagesListMobileSkeleton />}
      {messagesIds.length > 0 && (
        <VirtualDataList
          editMode={isEditMode}
          onEditMode={onToggleEditMode}
          bulkAction={bulkAction}
          selectedRowsId={selectedRowsId}
          onSelectRow={handleOnSelectRow}
          onRefresh={handleRefresh}
          isPullable={!isEditMode}
          data={messagesIds}
          endReached={fetchMoreMessages}
          itemContent={(index, id) => <MessagesListRow messageId={id} />}
          showFooter={shouldShowSpinner}
        />
      )}
    </>
  );
}

export default memo(MessagesListMobile);
