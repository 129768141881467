import { Link } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface IgnoreAllLinkProps {
  allColumnsAreIgnored: boolean;
  onClickIgnoreAll: () => void;
  onClickUndoIgnoreAll: () => void;
}

function IgnoreAllLink({
  allColumnsAreIgnored,
  onClickIgnoreAll,
  onClickUndoIgnoreAll,
}: IgnoreAllLinkProps) {
  const t = useTranslationCatalog();

  if (!allColumnsAreIgnored)
    return (
      <Link
        appearance="primary"
        fontSize="caption"
        lineHeight="caption"
        textDecoration="none"
        onClick={onClickIgnoreAll}
      >
        {t('products.importExportPage.alterations.ignoreAll')}
      </Link>
    );
  else
    return (
      <Link
        appearance="primary"
        fontSize="caption"
        lineHeight="caption"
        textDecoration="none"
        onClick={onClickUndoIgnoreAll}
      >
        {t('products.importExportPage.alterations.undoIgnoreAll')}
      </Link>
    );
}

export default IgnoreAllLink;
