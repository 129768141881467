import { Box, Title } from '@nimbus-ds/components';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { HeaderContent, useResponsive } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface OrderEditPageHeaderProps {
  isError: boolean;
  orderDetails: OrderDetailsResponseDto | null;
}

export function OrderEditPageHeader({
  isError,
  orderDetails,
}: Readonly<OrderEditPageHeaderProps>) {
  const t = useTranslationOrders();
  const { isMobile } = useResponsive();

  return isError ? null : (
    <Box display="flex" flexDirection="row">
      {isMobile ? (
        <Box>
          <HeaderContent title={t('editOrders.titleBase')} />
          <Box display="flex" flexDirection="row">
            <HeaderContent
              title={t('editOrders.titleNumber', {
                number: orderDetails?.number,
              })}
            />
            {!orderDetails && !isError && <Title.Skeleton width="100px" />}
          </Box>
        </Box>
      ) : (
        <>
          <HeaderContent
            title={`${t('editOrders.titleBase')} ${t('editOrders.titleNumber', {
              number: orderDetails?.number,
            })}`}
          />
          {!orderDetails && !isError && <Title.Skeleton width="100px" />}
        </>
      )}
    </Box>
  );
}
