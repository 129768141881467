import React from 'react';

interface RepeaterProps {
  times?: number;
  item: (index: number) => React.ReactNode;
}

function Repeater({ times = 10, item }: RepeaterProps): JSX.Element {
  const items = Array(times).fill(null);
  return (
    <>
      {items.map((_, index) => (
        <React.Fragment key={index}>{item(index)}</React.Fragment>
      ))}
    </>
  );
}

export default Repeater;
