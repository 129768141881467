import { useParams } from 'react-router';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { FreeShippingDetailsSkeleton } from './FreeShippingDetailsSkeleton';
import {
  ConfirmScopeFreeShippingModal,
  FreeShippingForm,
  FreeShippingPage,
} from '../../components';
import {
  useGetFreeShippingQuery,
  useUpdateFreeShippingMutation,
} from '../../freeShippingApi';
import { useFreeShippingForm } from '../../hooks';
import { FreeShippingFormState } from '../../types';
import { payloadDigest } from '../../utils';
import { freeShippingDefault } from '../constants';
import { FreShippingPageModes } from '../types';

export function EditFreeShippingPage() {
  const t = useTranslationMarketing('freeShipping.settingsPage.edit');
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, isError, isSuccess, refetch } =
    useGetFreeShippingQuery(id);
  const { goBack } = useNavegate();

  const [updateFreeShipping, { status }] = useUpdateFreeShippingMutation();

  const initialValues: FreeShippingFormState = data || freeShippingDefault;

  const handleConfirm = (data: FreeShippingFormState) => {
    updateFreeShipping({
      id,
      payload: payloadDigest(data),
    });
  };

  const {
    values,
    errors,
    isDirty,
    showConfirmModal,
    handleChangeMethods,
    handleChangeCategories,
    handleChangeZones,
    handleChange,
    handleOnSubmit,
    closeConfirmModal,
  } = useFreeShippingForm({
    initialValues,
    onSubmit: handleConfirm,
  });

  const createStatus = toStatusType(status);

  useToastStatus({
    error: t('error'),
    success: t('success'),
    status: createStatus,
    onSuccess: goBack,
  });

  const showButtons = isLoading ? 'skeletons' : isSuccess ? 'normal' : 'hide';

  return (
    <FreeShippingPage
      values={values}
      status={createStatus}
      isDirty={isDirty}
      onSave={handleOnSubmit}
      showButtons={showButtons}
      mode={FreShippingPageModes.EDIT}
    >
      {isError && <ErrorScreen onRetry={refetch} />}
      {isLoading && <FreeShippingDetailsSkeleton />}
      {isSuccess && (
        <FreeShippingForm
          values={values}
          errors={errors}
          onChange={handleChange}
          onChangeMethods={handleChangeMethods}
          onChangeCategories={handleChangeCategories}
          onChangeZones={handleChangeZones}
        />
      )}
      <ConfirmScopeFreeShippingModal
        values={values}
        show={showConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
      />
    </FreeShippingPage>
  );
}
