import useGetValidationInitial from '../useGetValidationInitial';

function RequestValidator() {
  const validationInitial = useGetValidationInitial();

  if (!validationInitial?.pageValidation) return null;

  return (
    <iframe
      id="validationIframe"
      src={validationInitial.pageValidation}
      style={{ display: 'none' }}
    />
  );
}

export default RequestValidator;
