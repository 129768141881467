import { ReactNode } from 'react';
import { NodeRendererProps } from '@nosferatu500/react-sortable-tree/node-renderer-default';
import { DragIcon, Stack } from 'commons/components';
import './CustomNode.scss';

export function CustomNode(props: NodeRendererProps) {
  const {
    connectDragPreview,
    connectDragSource,
    canDrag,
    title,
    treeId: _treeId,
    isOver: _isOver, // Not needed, but preserved for other renderers
    parentNode: _parentNode, // Needed for dndManager
  } = props;
  const nodeTitle = title as ReactNode;

  const handle =
    canDrag &&
    connectDragSource(
      <div className="stratus--custom-node-icon">
        <DragIcon />
      </div>,
      {
        dropEffect: 'copy',
      },
    );

  return connectDragPreview(
    <div className="stratus--custom-node">
      {handle}
      <Stack.Item fill>{nodeTitle}</Stack.Item>
    </div>,
  );
}
