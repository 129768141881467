import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceSelectOptionGroup } from '@tiendanube/components';
import { useAppDispatch } from 'App/store';
import { fetchCountries, getCountriesArray } from '../../addressesSlice';

function useGetCountries(): InterfaceSelectOptionGroup[] {
  const dispatch = useAppDispatch();
  const countries = useSelector(getCountriesArray);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  return countries;
}

export default useGetCountries;
