const ACTION_REGEX = /[^a-zA-Z/_]/g;

// TODO: this method is duplicated in cirrus.
const getValidationActionName = (
  url: string,
  pathsWithVerification: string[],
) => {
  const pathsWithVerificationWithLogin = [
    '/auth/login',
    '/auth/v1',
    ...pathsWithVerification,
  ];

  const verificationPath = pathsWithVerificationWithLogin.find((path) =>
    url.includes(path),
  );

  if (!verificationPath) return 'unknow_action';

  return verificationPath
    .replaceAll('-', '_')
    .replaceAll(/[0-9]/g, 'X')
    .replaceAll(ACTION_REGEX, '');
};

export default getValidationActionName;
