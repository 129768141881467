import { Alert, AlertProperties } from '@nimbus-ds/components';
import { GoToPayButton } from '../../../';

interface AlertCaseProps {
  appearance: AlertProperties['appearance'];
  title: string;
  message: string;
  eventClick?: () => void;
}

function AlertCase({ appearance, title, message, eventClick }: AlertCaseProps) {
  return (
    <Alert appearance={appearance} title={title}>
      {message}
      <GoToPayButton onClick={eventClick} />
    </Alert>
  );
}

export default AlertCase;
