import { Box, Card, Link, Skeleton, Text } from '@nimbus-ds/components';

function MarketingAppsPageSkeleton() {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns={{
          lg: '1fr 1fr 1fr',
          md: '1fr',
          xs: '1fr',
        }}
        gridGap="2"
      >
        {[...Array(9)].map((_, index) => (
          <Box key={index}>
            <Card>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  gap="4"
                  height="80px"
                >
                  <Skeleton height="50px" width="50px" />
                  <Box flex="1 1 auto" height="100%">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      height="62px"
                      gap="0-5"
                    >
                      <Text.Skeleton fontSize="highlight" width="80px" />
                      <Text.Skeleton width="100%" />
                    </Box>
                    <Link.Skeleton width="80px" />
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Box>
        ))}
      </Box>
      <Card padding="small">
        <Card.Body>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="4"
          >
            <Skeleton height="36px" width="36px" />
            <Box flex="1 1 auto" height="100%">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                gap="0-5"
              >
                <Text.Skeleton fontSize="highlight" width="80px" />
                <Text.Skeleton width="100%" />
              </Box>
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </>
  );
}

export default MarketingAppsPageSkeleton;
