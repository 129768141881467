import {
  Button,
  Card,
  Input,
  Label,
  Text,
  Textarea,
} from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

function ContactInfoPageSkeleton() {
  const t = useTranslationOnline('contactInfoPage');
  const country = useGetCountry();

  return (
    <Card>
      <Card.Body>
        <Stack column align="stretch">
          {country === 'BR' && (
            <>
              <Stack column spacing="tight" align="stretch">
                <Label>{t('businessName')}</Label>
                <Input.Skeleton />
              </Stack>
              <Stack column spacing="tight" align="stretch">
                <Label>{t('businessId')}</Label>
                <Input.Skeleton />
              </Stack>
            </>
          )}
          <Stack column spacing="tight" align="stretch">
            <Label>{t('email')}</Label>
            <Input.Skeleton />
            <Text fontSize="caption">{t('emailInfo')}</Text>
          </Stack>
          <Stack column spacing="tight" align="stretch">
            <Label>{t('address')}</Label>
            <Input.Skeleton />
          </Stack>
          <Stack column spacing="tight" align="stretch">
            <Label>{t('phone')}</Label>
            <Input.Skeleton />
          </Stack>
          <Stack column spacing="tight" align="stretch">
            <Label>{t('contactText')}</Label>
            <Textarea.Skeleton width="100%" />
            <Text fontSize="caption">{t('contactTextInfo')}</Text>
          </Stack>
          <Stack spacing="base" justify="flex-end">
            <Button.Skeleton />
            <Button.Skeleton />
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default ContactInfoPageSkeleton;
