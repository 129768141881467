import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCorreiosContractTypesAction,
  getCorreiosContractTypesData,
  getCorreiosContractTypesStatus,
} from '../../deliveryMethodsSlice';

function useGetCorreiosContractTypes() {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(
    getCorreiosContractTypesStatus,
  );
  const correiosContractTypes = useSelector(getCorreiosContractTypesData);

  const fetchCorreiosContractTypes = useCallback(() => {
    dispatch(getCorreiosContractTypesAction());
  }, [dispatch]);

  return {
    isLoading,
    isError,
    isSuccess,
    correiosContractTypes,
    fetchCorreiosContractTypes,
  };
}

export default useGetCorreiosContractTypes;
