import { Alert } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  ADDRESSING_RULES_TRANSLATE_TYPES,
  ADDRESSING_RULES_TYPES,
} from '../../constants';

interface IncompatibleRuleAlertProps {
  deactivatedRule: string;
}

function IncompatibleRuleAlert({
  deactivatedRule,
}: Readonly<IncompatibleRuleAlertProps>) {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');
  const deactivatedRuleName = t(
    `types.${ADDRESSING_RULES_TRANSLATE_TYPES[deactivatedRule]}.title`,
  ).toLowerCase();
  const activatedRuleName = t(
    `types.${
      deactivatedRule === ADDRESSING_RULES_TYPES.cdPriority
        ? ADDRESSING_RULES_TRANSLATE_TYPES.CD_PROXIMITY
        : ADDRESSING_RULES_TRANSLATE_TYPES.CD_PRIORITY
    }.title`,
  ).toLowerCase();

  return (
    <Alert appearance="warning" title={t('modal.incompatibleRules.title')}>
      {t('modal.incompatibleRules.description', {
        deactivatedRule: deactivatedRuleName,
        activatedRule: activatedRuleName,
      })}
    </Alert>
  );
}

export default IncompatibleRuleAlert;
