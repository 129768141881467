import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { ProductsTokenResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import { ProductsFiltersType } from 'domains/Catalog/Products/productsServices';
import {
  fetchTotalProducts,
  exportProducts as exportProductsAction,
} from 'domains/Catalog/Products/productsSlice';
import { defaultExportFilters } from 'domains/Catalog/Products/productsSlice/constants';
import {
  getProductsExportStatus,
  getProductsExportTotal,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

interface UseExportProductsResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  filters: ProductsFiltersType;
  totalResults: number;
  getTotalProducts: (newFilters: ProductsFiltersType) => Promise<void>;
  refreshTotalProducts: () => Promise<void>;
  removeFilter: (id: string) => Promise<void>;
  exportProducts: (
    includeDescriptions: boolean,
  ) => Promise<ProductsTokenResponseDto>;
}

function useExportProducts(): UseExportProductsResult {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(
    getProductsExportStatus,
  );
  const { filters, changeFilters } = useListFilters(
    'products/import',
    defaultExportFilters,
  );

  const totalResults = useSelector(getProductsExportTotal);

  const getTotalProducts = useCallback(
    async (newFilters: ProductsFiltersType) => {
      if (!isEqual(filters, newFilters)) {
        changeFilters(newFilters);
        await dispatch(
          fetchTotalProducts({ ...newFilters, q: '', sortBy: '' }),
        );
      }
    },
    [changeFilters, dispatch, filters],
  );

  const refreshTotalProducts = useCallback(async () => {
    await dispatch(fetchTotalProducts({ ...filters, q: '', sortBy: '' }));
  }, [dispatch, filters]);

  const removeFilter = useCallback(
    async (id: string) => {
      const newFilters = { ...filters, [id]: '' };
      changeFilters(newFilters);
      await getTotalProducts(newFilters);
    },
    [changeFilters, filters, getTotalProducts],
  );

  const exportProducts = useCallback(
    async (includeDescriptions: boolean) => {
      const result = await dispatch(
        exportProductsAction({ ...filters, includeDescriptions }),
      );
      return unwrapResult(result);
    },
    [dispatch, filters],
  );

  return {
    isLoading,
    isSuccess,
    isError,
    filters,
    totalResults,
    getTotalProducts,
    refreshTotalProducts,
    removeFilter,
    exportProducts,
  };
}

export default useExportProducts;
