import { Fragment } from 'react';
import { Box, Button, Text } from '@nimbus-ds/components';
import getMockArrayForSkeleton from 'commons/utils/getMockArrayForSkeleton';
import { ChargeToPay } from './components';

function SelectChargesToPayTabSkeleton() {
  return (
    <Box display="flex" flexDirection="column">
      {getMockArrayForSkeleton(3).map((id) => (
        <Fragment key={id}>
          <ChargeToPay.Skeleton />
          <hr />
        </Fragment>
      ))}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="4"
        alignItems="center"
      >
        <Button.Skeleton />
        <Box
          display="flex"
          flexDirection="column"
          gap="2"
          alignItems="flex-end"
        >
          <Text.Skeleton width="170%" />
          <Text.Skeleton />
        </Box>
      </Box>
    </Box>
  );
}

export default SelectChargesToPayTabSkeleton;
