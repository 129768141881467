import { Box, Icon } from '@nimbus-ds/components';
import { LockIcon } from '@nimbus-ds/icons';
import Blur from 'commons/components/Blur';
import { useBoolean } from 'commons/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import placeholderHorizontalBarChart from './placeholder-bars-horizontal.png';
import placeholderBarChart from './placeholder-bars.png';
import placeholderFunnelChart from './placeholder-funnel.png';
import placeholderStackedBarChart from './placeholder-stacked.png';

export interface ChartPlaceholderProps {
  type: 'bars' | 'bars-horizontal' | 'bars-stacked' | 'funnel';
  height?: number;
  onClick: () => void;
}

const srcMap = {
  bars: placeholderBarChart,
  'bars-horizontal': placeholderHorizontalBarChart,
  'bars-stacked': placeholderStackedBarChart,
  funnel: placeholderFunnelChart,
};

function ChartPlaceholder({
  type,
  height = 340,
  onClick,
}: Readonly<ChartPlaceholderProps>) {
  const t = useTranslationStatistics();
  const [hover, setHover, unsetHover] = useBoolean(false);

  return (
    <Box
      position="relative"
      onMouseEnter={() => setHover()}
      onMouseLeave={() => unsetHover()}
      onClick={onClick}
      cursor="pointer"
    >
      <Blur>
        <img
          width="100%"
          height={height}
          src={srcMap[type]}
          style={{ opacity: hover ? 1 : '0.5' }}
          alt={t('statistics.placeholderAlt')}
        />
      </Blur>
      <Box
        padding="3"
        position="absolute"
        top="calc(50% - 28px)"
        left="calc(50% - 28px)"
        backgroundColor="neutral-surface"
        borderRadius="full"
        borderColor="neutral-surfaceHighlight"
        borderStyle="solid"
        borderWidth="1"
      >
        <Icon source={<LockIcon />} />
      </Box>
    </Box>
  );
}

export default ChartPlaceholder;
