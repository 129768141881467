import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDismissibleComponent, useStorage } from 'commons/hooks';
import { useCanEnterAdmin } from 'domains/Auth/hooks';
import {
  useLazyGetDomainSoftBlockQuery,
  useSetDomainToNotifyMutation,
} from '../domainsApi';
import { trackingSoftblockNameserverViewed } from '../tracking';

const noop = () => null;

export interface DomainSoftBlockContext {
  domainSoftBlock: boolean;
  dismissSoftblock: (fromErrorState: boolean) => void;
  notifyDomainUpdate: (fromErrorState: boolean) => void;
  shouldShowInDomainPage: boolean;
  dismissInDomainPage: (reset: boolean) => void;
}

export interface InterfaceDomainSoftBlockContextProvider {
  children: ReactNode;
}

export const UpsellSoftBlockContext = createContext<DomainSoftBlockContext>({
  domainSoftBlock: false,
  shouldShowInDomainPage: false,
  dismissSoftblock: noop,
  notifyDomainUpdate: noop,
  dismissInDomainPage: noop,
});

function DomainSoftBlockContextProvider({
  children,
}: Readonly<InterfaceDomainSoftBlockContextProvider>): JSX.Element {
  const [getData, { data, isSuccess }] = useLazyGetDomainSoftBlockQuery();
  const [notifyDomainStore] = useSetDomainToNotifyMutation();
  const [canShowInDomainPage, setCanShowInDomainPage] = useState(true);
  const canEnterAdmin = useCanEnterAdmin();

  const { shouldShow: showSoftblock, dismiss: handleDismissSoftblock } =
    useDismissibleComponent('domain-soft-block-dismiss', 1);

  const [domainNotify, setDomainNotify, loaded] = useStorage<
    boolean | undefined
  >('domain-notify-update', undefined, true);

  const {
    shouldShow: shouldShowByDismissibleError,
    dismiss: dismissErrorSoftblock,
  } = useDismissibleComponent('domain-soft-block-error-dismiss', 1);

  const { shouldShow: shouldShowBynotifyError, dismiss: notifyErrorSoftblock } =
    useDismissibleComponent('domain-notify-update-error', 1);

  const dismissSoftblock = useCallback(
    (fromErrorState) => {
      if (fromErrorState) dismissErrorSoftblock();
      else handleDismissSoftblock();
    },
    [dismissErrorSoftblock, handleDismissSoftblock],
  );

  const notifyDomainUpdate = useCallback(
    (fromErrorState) => {
      if (fromErrorState) notifyErrorSoftblock();
      else setDomainNotify(true);

      notifyDomainStore();
    },
    [notifyErrorSoftblock, setDomainNotify, notifyDomainStore],
  );

  const dismissInDomainPage = useCallback(
    (reset: boolean) => {
      setCanShowInDomainPage(reset);
    },
    [setCanShowInDomainPage],
  );

  const showErrorSoftblock =
    shouldShowBynotifyError && shouldShowByDismissibleError;

  const isNotify = data?.error
    ? !shouldShowBynotifyError
    : loaded && domainNotify;

  const domainSoftBlock = useMemo(() => {
    if (!isSuccess || !data) return false;

    if (data?.error) return showErrorSoftblock;

    if (loaded && domainNotify) return false;

    if (showSoftblock) return true;

    return false;
  }, [
    data,
    isSuccess,
    loaded,
    domainNotify,
    showSoftblock,
    showErrorSoftblock,
  ]);

  const isErrorScreen = !!data?.error;
  const shouldShowInDomainPage = !!data && !isNotify && canShowInDomainPage;

  useEffect(() => {
    if (domainSoftBlock && data?.storeId) {
      trackingSoftblockNameserverViewed(data.storeId, 'login', isErrorScreen);
    }
  }, [shouldShowInDomainPage, domainSoftBlock, data?.storeId, isErrorScreen]);

  const values = useMemo(
    () => ({
      domainSoftBlock,
      notifyDomainUpdate,
      dismissSoftblock,
      shouldShowInDomainPage,
      dismissInDomainPage,
    }),
    [
      dismissSoftblock,
      domainSoftBlock,
      notifyDomainUpdate,
      shouldShowInDomainPage,
      dismissInDomainPage,
    ],
  );

  useEffect(() => {
    if (canEnterAdmin) {
      getData();
    }
  }, [canEnterAdmin, getData]);

  return (
    <UpsellSoftBlockContext.Provider value={values}>
      {children}
    </UpsellSoftBlockContext.Provider>
  );
}

export default DomainSoftBlockContextProvider;
