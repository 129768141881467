import { Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import LoadingAiAnimation from './loading-ai.gif';
import { AiGenerationResultsCard } from '../AiGenerationResultsCard';

export function GeneratingContentAnimation() {
  const t = useTranslationCommon();

  return (
    <AiGenerationResultsCard
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 100px)"
    >
      <Stack column align="center">
        <img src={LoadingAiAnimation} width={64} height={64} />
        <Stack.Item>
          <Title as="h4" textAlign="center" color="primary-textLow">
            {t('aiGeneration.resultsModal.generating.title')}
          </Title>
          <Text fontSize="caption" textAlign="center" color="neutral-textLow">
            {t('aiGeneration.resultsModal.generating.subtitle')}
          </Text>
        </Stack.Item>
      </Stack>
    </AiGenerationResultsCard>
  );
}
