import { Alert, Text } from '@nimbus-ds/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { usePayment } from '../../hooks';
import { PaymentStatusAlertProps } from '../../PaymentStatusAlert';

function PaymentStatusAlertMercadoPago({
  concept,
  externalReference,
  chargeId,
}: PaymentStatusAlertProps) {
  const t = useTranslationBilling('checkout.paymentStatusAlert.mercadoPago');
  const paymentData = usePayment(concept, externalReference, chargeId);

  if (!paymentData) return null;
  const { payment, paymentStatus } = paymentData;

  switch (paymentStatus) {
    case 'IN_PROCESS':
      return (
        <Alert appearance="warning" title={t('inProgress.title')}>
          <Text>{t(`inProgress.message`)}</Text>
        </Alert>
      );
    case 'FAILED': {
      const path =
        payment?.gatewayDetail.paymentMethod === 'TICKET'
          ? 'ticketFailed'
          : 'failed';
      return (
        <Alert appearance="danger" title={t(`${path}.title`)}>
          <Text>{t(`${path}.message`)}</Text>
        </Alert>
      );
    }
    default:
      return null;
  }
}

export default PaymentStatusAlertMercadoPago;
