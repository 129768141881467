import { Alert, Text, Button } from '@nimbus-ds/components';
import { Link } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { ShowByTag } from 'App/components';
import ShowByCountry from 'App/components/ShowByCountry/ShowByCountry';
import { NUVEMENVIO_ADMIN_BLOCK } from 'App/featuresFlags';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { partnersAppsRoutes } from 'domains/PartnersApps';

function NuvemEnvioAdminBlockingAlert() {
  const t = useTranslationDashboard('billingMessages.nuvemEnvio.adminBlocking');
  return (
    <ShowByCountry allowedCountries={[Country.BR]}>
      <ShowByTag tag={NUVEMENVIO_ADMIN_BLOCK}>
        <Alert appearance="danger" title={t('title')}>
          <Text>{t('description')}</Text>
          <Button
            appearance="primary"
            as={Link}
            to={partnersAppsRoutes.nuvemenvioBilling}
          >
            {t('cta')}
          </Button>
        </Alert>
      </ShowByTag>
    </ShowByCountry>
  );
}

export default NuvemEnvioAdminBlockingAlert;
