export const MAX_DOMAIN_LENGTH = 25;
export const MIN_DOMAIN_LENGTH = 4;

export const OUR_DOMAIN_REGEX = /^[a-z][a-z0-9]*$/;
export const DOMAIN_REGEX =
  /^([\wá-ú-]+\.)*[\wá-ú][\wá-ú-]+\.[a-zA-Z-]{2,11}$/i;

export const isValidDomain = (domain: string) => DOMAIN_REGEX.test(domain);

export const isValidOurDomain = (domain: string) =>
  domain.length >= MIN_DOMAIN_LENGTH && OUR_DOMAIN_REGEX.test(domain);

const DOMAIN_ALREADY_IN_USE_MSG =
  'The domain is not available for registration';

const INVALID_DOMAIN_MSG =
  'The domain name does not comply with the allowed format';

export const isAlreadyInUseDomainError = (err) =>
  err?.data?.response?.description === DOMAIN_ALREADY_IN_USE_MSG;

export const isInvalidDomainError = (err) =>
  err?.data?.response?.description === INVALID_DOMAIN_MSG;

export const UPDATE_OUR_DOMAIN_MUTATION_CACHE_KEY = 'updateOurDomain';
export const UPDATE_DOMAIN_STATUS_CACHE_KEY = 'updateDomainStatus';
export const CREATE_DOMAIN_CACHE_KEY = 'createDomain';

const OUR_DOMAIN_INVALID_ERROR = 'invalid_domain_name';
const OUR_DOMAIN_ALREADY_IN_USE_ERROR = 'domain_already_in_use';

export const isInvalidOurDomainError = (err) =>
  err?.data?.response?.errors === OUR_DOMAIN_INVALID_ERROR;

export const isAlreadyInUseOurDomainError = (err) =>
  !err?.data?.response?.success &&
  (!!err?.data?.response?.suggestion ||
    err?.data?.response?.errors === OUR_DOMAIN_ALREADY_IN_USE_ERROR);

export function isEmailDomainMatch(domain: string, email?: string): boolean {
  if (!email) return false;

  const emailDomain = email.substring(email.lastIndexOf('@') + 1);
  return emailDomain.toLowerCase() === domain.toLowerCase();
}
