import { combineReducers } from 'redux';
import {
  firstSaleReducer,
  onBoardingReducer,
  usersReducer,
  trialMessagesReducer,
  billingPlanReducer,
  paymentsMessagesReducer,
  socialMessagesReducer,
} from './slices';

const ordersReducer = combineReducers({
  firstSale: firstSaleReducer,
  onboarding: onBoardingReducer,
  users: usersReducer,
  trialMessages: trialMessagesReducer,
  billingPlan: billingPlanReducer,
  paymentMessages: paymentsMessagesReducer,
  socialMessages: socialMessagesReducer,
});

export default ordersReducer;
