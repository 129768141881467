import { Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { TiendanubeIcon } from '@tiendanube/icons';
import ShowByRole from 'App/components/ShowByRole';
import { useHandleMenuClick } from 'App/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';

interface AppsNuvemEnvioMenuProps {
  isMobile?: boolean;
}

const PATHS = {
  BR: '/apps/nuvemenvio',
  MX: '/apps/envionube/mx',
  AR: '/apps/envionube/ar',
};

function NuvemEnvioMenu({ isMobile }: Readonly<AppsNuvemEnvioMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname, hash } = useLocation();
  const { nuvemApps } = useNuvemApps();
  const country = useGetCountry();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const APP_BY_COUNTRY = {
    MX: nuvemApps.envionubemx,
    AR: nuvemApps.envionubear,
    BR: nuvemApps.nuvemenvio,
  };

  const hasNuvemEnvio = APP_BY_COUNTRY[country];

  const pathByCountry = PATHS[country];

  const dashboard = {
    children: `${t('menu.manage.nuvemenvio.dashboard')}`,
    onClick: handleClickItem,
    active: !hash.includes(`#/billing`),
    href: `${pathByCountry}/#/dashboard`,
  };

  const billing = {
    children: `${t('menu.manage.nuvemenvio.billing')}`,
    onClick: handleClickItem,
    active: hash.includes(`#/billing`),
    label: t('menu.new'),
    href: `${pathByCountry}/#/billing`,
  };

  const MENU_BY_COUNTRY = {
    MX: [dashboard],
    AR: [dashboard],
    BR: [dashboard, billing],
  };

  const menuNuvemEnvio: {
    children: string;
    onClick: () => void;
    active: boolean;
    href: string;
    label?: string;
  }[] = MENU_BY_COUNTRY[country] ?? [];

  const dashboarOrders = [
    {
      ...dashboard,
      active: hash.includes(`#/dashboard`) || pathname === pathByCountry,
    },
  ];

  const itemsNuvemEnvio = [...menuNuvemEnvio];
  return (
    <>
      <ShowByRole includeRoles={['full']}>
        {hasNuvemEnvio && (
          <MenuButton.Accordion
            contentid="content-nuvemenvio"
            menuButton={{
              id: 'control-nuvemenvio',
              label: t('menu.maximize.nuvemEnvio'),
              startIcon: TiendanubeIcon,
              'aria-controls': 'content-nuvemenvio',
              onClick: handleClickAccordion,
            }}
            open={pathname.includes(pathByCountry)}
            active={pathname.includes(pathByCountry)}
            as={Link}
            to={pathByCountry}
          >
            {itemsNuvemEnvio.map((item, index) => (
              <MenuButton
                key={index}
                as={Link}
                label={item.children}
                onClick={item.onClick}
                to={item.href}
                active={item.active}
              >
                {!!item.label && isMobile && (
                  <Tag appearance="primary">{item.label}</Tag>
                )}
              </MenuButton>
            ))}
          </MenuButton.Accordion>
        )}
      </ShowByRole>
      <ShowByRole excludeRoles={['full']} includeRoles={['nuvem_envio']}>
        {hasNuvemEnvio && (
          <MenuButton.Accordion
            contentid="content-nuvemenvio-dashboard"
            menuButton={{
              id: 'control-nuvemenvio-dashboard',
              label: t('menu.maximize.nuvemEnvio'),
              startIcon: TiendanubeIcon,
              'aria-controls': 'content-nuvemenvio-dashboard',
              onClick: handleClickAccordion,
            }}
            open={pathname.includes(pathByCountry)}
            active={pathname.includes(pathByCountry)}
            as={Link}
            to={pathByCountry}
          >
            {dashboarOrders.map((item, index) => (
              <MenuButton
                key={index}
                as={Link}
                label={item.children}
                onClick={item.onClick}
                to={item.href}
                active={item.active}
              />
            ))}
          </MenuButton.Accordion>
        )}
      </ShowByRole>
    </>
  );
}

export default NuvemEnvioMenu;
