import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { fetchHasAvailableFunds as fetchHasAvailableFundsAction } from '../../ordersSlice';

interface UseRefundOrderResult {
  isLoading: boolean;
  hasAvailableFunds: boolean;
  fetchHasAvailableFunds: () => Promise<boolean>;
}
const appDigest: Record<string, 'nuvempago' | 'pagonube'> = {
  'Nuvem Pago': 'nuvempago',
  'Pago Nube': 'pagonube',
};

function useRefundOrder(amount: number, gateway: string): UseRefundOrderResult {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [hasAvailableFunds, setHasAvailableFunds] = useState(false);

  const fetchHasAvailableFunds = useCallback(async () => {
    let hasFunds: boolean;
    setIsLoading(true);

    try {
      const app = appDigest[gateway];
      const result = await dispatch(
        fetchHasAvailableFundsAction({ amount, app }),
      );
      hasFunds = unwrapResult(result).hasFunds;
    } catch (error) {
      hasFunds = false;
    }

    setHasAvailableFunds(hasFunds);
    setIsLoading(false);
    return hasFunds;
  }, [dispatch, amount, gateway]);

  return { isLoading, hasAvailableFunds, fetchHasAvailableFunds };
}

export default useRefundOrder;
