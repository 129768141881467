import { BaseCardCollapsible } from 'commons/components';
import { ShippingInformationCard } from '../../../components';

function Skeleton() {
  return (
    <>
      <ShippingInformationCard.Skeleton />
      <BaseCardCollapsible.Skeleton />
      <BaseCardCollapsible.Skeleton />
      <BaseCardCollapsible.Skeleton />
    </>
  );
}

export default Skeleton;
