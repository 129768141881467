import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { UpdateContentPagesRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  updatePageByIdAction,
  updatePageByIdStatusSelector,
  cleanPageUpdateAction,
  getPageByIdAction,
  getPageDetailSelector,
  getPagesIdSelector,
  getPagesByIdSelector,
} from '../../contentPagesSlice';

function useUpdateContentPages(id: string) {
  const dispatch = useAppDispatch();
  const statusUpdate = useSelector(updatePageByIdStatusSelector);
  const statusDetail = useSelector(getPageDetailSelector);
  const initialValues = useSelector(getPagesByIdSelector);
  const idPage = useSelector(getPagesIdSelector);
  const hasData = idPage && id === idPage;

  const updatePage = useCallback(
    async (payload: UpdateContentPagesRequestDto) => {
      unwrapResult(await dispatch(updatePageByIdAction({ id, payload })));
      dispatch(cleanPageUpdateAction());
    },
    [dispatch, id],
  );

  const getPageById = useCallback(async () => {
    await dispatch(getPageByIdAction(id));
  }, [dispatch, id]);

  return {
    statusUpdate,
    statusDetail,
    hasData,
    initialValues,
    updatePage,
    getPageById,
  };
}

export default useUpdateContentPages;
