import { Text, Stack } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function OrganizeProductsSkeleton() {
  const t = useTranslationCatalog();
  return (
    <Stack column align="stretch">
      <CardWithTitle title={t('products.order.productsWithoutStockCard.title')}>
        <Text.Skeleton size="featured" width="fill" />
        <Text.Skeleton size="featured" width="fill" />
      </CardWithTitle>
      <CardWithTitle title={t('products.order.productsBySeccion.title')}>
        <Stack column align="stretch">
          <Text>{t('products.order.productsBySeccion.subtitle')}</Text>
          <Text.Skeleton size="featured" width="fill" />
          <Text.Skeleton size="featured" width="fill" />
        </Stack>
      </CardWithTitle>
      <Stack.Item />
    </Stack>
  );
}

export default OrganizeProductsSkeleton;
