import { Switch, Route, Redirect } from 'react-router-dom';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { dashboardRoutes } from 'domains/Dashboard';
import SeoRedirectsPage from './pages/SeoRedirectsPage';
import { seoRedirectsRoutes } from './seoRedirectsRoutes';

function SeoRedirects() {
  return (
    <Switch>
      <Route path={seoRedirectsRoutes.redirects} exact>
        <ToggleMigratedOldDomain domain="seo-redirects">
          <SeoRedirectsPage />
        </ToggleMigratedOldDomain>
      </Route>
      <Redirect from="*" to={dashboardRoutes.dashboard} exact />
    </Switch>
  );
}

export default SeoRedirects;
