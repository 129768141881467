import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import {
  ContactInfoInterface,
  PasswordProtectedInterface,
  ThemesInterface,
} from '../themesSlice';

const getThemesState = (state: RootStateType): CombinedState<ThemesInterface> =>
  state.online.themes;

export const getProductSectionsSelector = (state) =>
  getThemesState(state).productSection;

export const getProductSectionsDataSelector = (state) =>
  getProductSectionsSelector(state).data;

export const getProductSectionsStatusSelector = (state) =>
  getProductSectionsSelector(state).status;

export const getProductSectionsDetailsSelector = (state) =>
  getProductSectionsSelector(state).entityDetail;

export const getProductSectionsDetailsDataSelector = (state) =>
  getProductSectionsDetailsSelector(state).data;

const getProductsSectionEntityById = (_: RootStateType, id: string) => id;

export const createGetProductsSectionById = () =>
  createSelector(
    getProductSectionsDetailsDataSelector,
    getProductsSectionEntityById,
    (entities, id) => entities[id] || null,
  );

export const getProductSectionsDetailsStatusSelector = (state) =>
  getProductSectionsDetailsSelector(state).status;

export const getProductSectionsDetailsUpdateStatusSelector = (state) =>
  getProductSectionsDetailsSelector(state).updateStatus;

const getContactInfoState = (
  state: RootStateType,
): CombinedState<ContactInfoInterface> => getThemesState(state).contactInfo;

export const contactInfoStatus = createSimpleSelector(
  [getContactInfoState],
  (contactInfo) => contactInfo.status,
);

export const contactInfoUpdateStatus = createSimpleSelector(
  [getContactInfoState],
  (contactInfo) => contactInfo.updateStatus,
);

export const contactInfoData = createSimpleSelector(
  [getContactInfoState],
  (contactInfo) => contactInfo.data,
);

const getPasswordProtectedState = (
  state: RootStateType,
): CombinedState<PasswordProtectedInterface> =>
  getThemesState(state).passwordProtected;

export const passwordProtectedStatus = createSimpleSelector(
  [getPasswordProtectedState],
  (passwordProtected) => passwordProtected.status,
);

export const passwordProtectedUpdateStatus = createSimpleSelector(
  [getPasswordProtectedState],
  (passwordProtected) => passwordProtected.updateStatus,
);

export const passwordProtectedData = createSimpleSelector(
  [getPasswordProtectedState],
  (passwordProtected) => passwordProtected.data,
);
