import { Box, Title, Text, Icon } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function FreeTrialAlert() {
  const t = useTranslationBilling('upsellFlow.modal.freeTrialAlert');

  return (
    <Box
      color="primary-textLow"
      backgroundColor="primary-surface"
      paddingX="4"
      paddingY="2"
      display="flex"
      alignItems="center"
      gap="2"
      borderStyle="solid"
      borderWidth="1"
      borderColor="primary-surfaceHighlight"
      borderRadius="2"
    >
      <Icon color="currentColor" source={<StarIcon size="medium" />} />
      <Box>
        <Title color="primary-textLow" as="h5">
          {t('title')}
        </Title>
        <Text color="currentColor">{t('description')}</Text>
      </Box>
    </Box>
  );
}

export default FreeTrialAlert;
