import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { CouponsSchema } from '../types';

const getCouponSchema = (state: RootStateType): CombinedState<CouponsSchema> =>
  state.billing.coupons;

export const getCouponStatus = (state: RootStateType) =>
  convertStatusTypeToObject(getCouponSchema(state).status);

export const getCouponData = (state: RootStateType) =>
  getCouponSchema(state).data;
