// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { Domain } from '@tiendanube/common/src/enums';
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './authentication-factor.es-AR.json';
import esMX from './authentication-factor.es-MX.json';
import ptBR from './authentication-factor.pt-BR.json';

const authenticationFactorTranslations: DomainTranslations = {
  name: Domain.AUTHENTICATION_FACTOR,
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
  },
};

export default authenticationFactorTranslations;
