import { useContext } from 'react';
import { VariantResponseDto } from '@tiendanube/common';
import { useGetLanguage } from 'domains/Auth/hooks';
import { joinVariantValues } from 'domains/Catalog/Inventory/utils';
import { useStockHistoryContext } from 'domains/Catalog/Products/pages/components/StockHistoryModal/hooks';
import { stockForLocation } from 'domains/Catalog/Products/utils';
import { EditStockContext } from '../components/EditStockProvider';

function useInventoryProductActions(productId: string, locationId?: string) {
  const language = useGetLanguage();

  const { showAccessToStockHistory, openStockHistoryModal } =
    useStockHistoryContext();

  const { openEditStock } = useContext(EditStockContext);

  const handleOpenStockHistoryModal = (variant: VariantResponseDto) =>
    openStockHistoryModal({
      variantId: variant.id,
      variantName: joinVariantValues(variant, language),
      productId,
      locationId,
    });

  const handleOpenEditStockModal = (variant: VariantResponseDto) => {
    const stock = stockForLocation(variant, locationId);
    openEditStock({
      id: variant.id,
      name: joinVariantValues(variant, language),
      productId,
      stock: stock !== null ? `${stock}` : null,
      locationId,
    });
  };

  return {
    showAccessToStockHistory,
    handleOpenStockHistoryModal,
    handleOpenEditStockModal,
  };
}

export default useInventoryProductActions;
