import { Box } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry/ShowByCountry';
import { FullHeightCardContainer, BlockWithTitle } from 'commons/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import FreeContentCard from './FreeContentCard';

function FreeContents() {
  const t = useTranslationDashboard();

  return (
    <ShowByCountry excludeCountries={[Country.BR, Country.CL, Country.PE]}>
      <BlockWithTitle title={t('freeContent.title')}>
        <FullHeightCardContainer>
          <Box
            display="flex"
            flexDirection={{
              lg: 'row',
              md: 'column',
              xs: 'column',
            }}
            gap="4"
          >
            <FreeContentCard
              link={t('freeContent.card1.link')}
              text={t('freeContent.card1.text')}
            />
            <FreeContentCard
              link={t('freeContent.card2.link')}
              text={t('freeContent.card2.text')}
            />
            <FreeContentCard
              link={t('freeContent.card3.link')}
              text={t('freeContent.card3.text')}
            />
          </Box>
        </FullHeightCardContainer>
      </BlockWithTitle>
    </ShowByCountry>
  );
}

export default FreeContents;
