import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceFulfillmentOrdersSchema } from '../fulfillmentOrdersSlice';

export const getFulfillmentOrderState = (
  state: RootStateType,
): CombinedState<InterfaceFulfillmentOrdersSchema> => state.fulfillmentOrders;

export const getDocumentSettingsStatus = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => ({
    isLoading: fulfillmentOrderState.documentSettings.status === 'loading',
    isError: fulfillmentOrderState.documentSettings.status === 'error',
    isSuccess: fulfillmentOrderState.documentSettings.status === 'success',
  }),
);

export const getDocumentSettingsData = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => fulfillmentOrderState.documentSettings.settings,
);

export const getDocumentStatus = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => ({
    isLoading: fulfillmentOrderState.document.status === 'loading',
    isError: fulfillmentOrderState.document.status === 'error',
    isSuccess: fulfillmentOrderState.document.status === 'success',
  }),
);

export const getOcaLabelSettingOptionsStatus = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => ({
    isLoading: fulfillmentOrderState.ocaDocumentSettings.status === 'loading',
    isError: fulfillmentOrderState.ocaDocumentSettings.status === 'error',
    isSuccess: fulfillmentOrderState.ocaDocumentSettings.status === 'success',
  }),
);

export const getOcaLabelSettingOptionsData = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => fulfillmentOrderState.ocaDocumentSettings.data,
);

export const getOrdersDetailsForOcaLabelsStatus = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => ({
    isLoading: fulfillmentOrderState.ocaOrdersDetails.status === 'loading',
    isError: fulfillmentOrderState.ocaOrdersDetails.status === 'error',
    isSuccess: fulfillmentOrderState.ocaOrdersDetails.status === 'success',
  }),
);

export const getOrdersDetailsForOcaLabelsData = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => fulfillmentOrderState.ocaOrdersDetails.data,
);

export const getOcaLabelsStatus = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => ({
    isLoading: fulfillmentOrderState.ocaLabels.status === 'loading',
    isError: fulfillmentOrderState.ocaLabels.status === 'error',
    isSuccess: fulfillmentOrderState.ocaLabels.status === 'success',
  }),
);

export const getGenerationOcaLabelsToken = createSelector(
  getFulfillmentOrderState,
  (fulfillmentOrderState) => fulfillmentOrderState.createOcaLabels.token,
);
