import { useEffect, useState } from 'react';
import { InputNumberNimbus } from 'commons/components';
import { ItemProps } from 'domains/Metafields/components/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

function NumberItem({
  metafield,
  disabled,
  value,
  onChange,
  onUpdate,
}: ItemProps) {
  const [internalValue, setInternalValue] = useState(value);
  const t = useTranslationsMetafields();
  const placeholder = t('assignMetafieldsForm.placeholders.textOrNumber');

  const handleChange = (event) => {
    const value = event.target.value;
    setInternalValue(value);
    onChange?.(value);
  };
  const handleBlur = (event) => {
    const value = event.target.value;
    setInternalValue(value);
    onUpdate?.(value || null);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <InputNumberNimbus
      type="float5d"
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      name={metafield.name}
      value={internalValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
}

export default NumberItem;
