import { FEATURE_CUSTOMER_ACCOUNTS } from 'App/features';
import useShowByFeature from 'App/hooks/useShowByFeature/useShowByFeature';
import { useRoles } from 'domains/Auth/authSlice/useRoles';

function useGetHasAccessToCustomers() {
  const { validateRoles } = useRoles();

  const hasCustomersFeature = useShowByFeature(FEATURE_CUSTOMER_ACCOUNTS);

  return validateRoles('customers') && hasCustomersFeature;
}

export default useGetHasAccessToCustomers;
