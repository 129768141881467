import { Table, Box } from '@nimbus-ds/components';
import { CurrencyResponseDto } from '@tiendanube/common';
import { CompactPaginationStratus } from 'commons/components';
import { useLocalPagination } from 'commons/hooks';
import { PER_PAGE } from 'domains/Configurations/I18n/utils/constants';
import Skeleton from './Skeleton';
import ExchangeRateInput from '../ExchangeRateInput';

interface ExchangeRateListProps {
  currencyList: CurrencyResponseDto[];
}
function ExchangeRateList({ currencyList }: ExchangeRateListProps) {
  const { setCurrentPage, currentData, hasPagination, ...pagination } =
    useLocalPagination(currencyList, PER_PAGE);
  return (
    <>
      <Table.Body>
        {currentData?.map((exchangeRate) => (
          <Table.Row key={exchangeRate.currencyId}>
            <Table.Cell>
              <Box paddingTop="1-5">{`1 ${exchangeRate.currencyCode}`}</Box>
            </Table.Cell>
            <ExchangeRateInput exchangeRate={exchangeRate} />
          </Table.Row>
        ))}
      </Table.Body>
      {hasPagination && (
        <tr>
          <td colSpan={3}>
            <Box backgroundColor="neutral-surface" paddingX="4" paddingY="2">
              <CompactPaginationStratus
                pagination={pagination}
                onPageSelect={setCurrentPage}
                itemName=""
              />
            </Box>
          </td>
        </tr>
      )}
    </>
  );
}

ExchangeRateList.Skeleton = Skeleton;

export default ExchangeRateList;
