import { cirrusApi } from 'App/rtk';
import { authTags, AUTH_SESSIONS_URL } from './config';
import { AuthEndpoints } from './types';

// TODO: We should also move here and implement the current authService and authSlice
const authApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [authTags.userSessions],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [AuthEndpoints.getSessions]: builder.query<string[], void>({
        query: () => ({
          method: 'GET',
          url: AUTH_SESSIONS_URL,
        }),
        providesTags: [authTags.userSessions],
      }),
    }),
  });

export const { useGetSessionsQuery } = authApi;
