import { Label } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import dayjs from 'dayjs';
import { Input, RadioButtonGroup, Stack } from 'commons/components';
import useTranslationMarketing from 'domains/Marketing/hook/useTranslationMarketing';

const CREATED_AT_MIN_FIELD_NAME = 'createdAtMin';
const CREATED_AT_MAX_FIELD_NAME = 'createdAtMax';

interface ShippingIncludedProps {
  dateFrom: string;
  dateTo: string;
  onChange: (
    name: typeof CREATED_AT_MIN_FIELD_NAME | typeof CREATED_AT_MAX_FIELD_NAME,
    value: string,
  ) => void;
}

const OPTIONS = ['all', 'custom'] as const;
type OptionsType = typeof OPTIONS[number];

const TODAY = new Date().toISOString().split('T')[0];
const ONE_MONTH_AGO = dayjs().subtract(1, 'month').toISOString().split('T')[0];
const DATE_SINCE_WE_ARE_SAVING_FIELD = '2024-05-08';

function CreatedAt({
  dateFrom,
  dateTo,
  onChange,
}: Readonly<ShippingIncludedProps>) {
  const t = useTranslationMarketing('coupons.filters.createdAt');
  const selected: OptionsType =
    dateFrom === '' && dateTo === '' ? 'all' : 'custom';

  function handleOnChange({ value }: { value: OptionsType }) {
    onChange(CREATED_AT_MIN_FIELD_NAME, value === 'all' ? '' : ONE_MONTH_AGO);
    onChange(CREATED_AT_MAX_FIELD_NAME, value === 'all' ? '' : TODAY);
  }

  const labelOptions = OPTIONS.map((value) => ({ value, label: t(value) }));

  return (
    <Stack column align="stretch">
      <RadioButtonGroup
        label={t('title')}
        name="created_at"
        options={labelOptions}
        onChange={handleOnChange}
        value={selected}
        boldLabel
      />
      {selected === 'custom' && (
        <Stack spacing="tight">
          <Stack.Item fill>
            <FormField>
              <Label>{t('from')}</Label>
              <Input
                name={CREATED_AT_MIN_FIELD_NAME}
                type="date"
                value={dateFrom}
                min={DATE_SINCE_WE_ARE_SAVING_FIELD}
                max={dateTo}
                onChange={({ value }) =>
                  onChange(CREATED_AT_MIN_FIELD_NAME, value)
                }
              />
            </FormField>
          </Stack.Item>
          <Stack.Item fill>
            <FormField>
              <Label>{t('until')}</Label>
              <Input
                name={CREATED_AT_MAX_FIELD_NAME}
                type="date"
                value={dateTo}
                min={dateFrom}
                max={TODAY}
                onChange={({ value }) =>
                  onChange(CREATED_AT_MAX_FIELD_NAME, value)
                }
              />
            </FormField>
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
}

export default CreatedAt;
