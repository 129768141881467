import HelpLink from 'App/HelpLink';
import { HeaderContent, IonPageStratus, Responsive } from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import {
  AppsListCalloutCard,
  AppsListDesktop,
  AppsListMobile,
} from './components';
import UninstallAppModal from './components/UninstallAppModal';

function AppsListPage() {
  const { t } = useTranslationPartnerApps('appsListPage');
  const language = useTranslationLanguage();

  return (
    <>
      <IonPageStratus
        width="small"
        headerContent={<HeaderContent title={t('title')} />}
      >
        <AppsListCalloutCard />
        <Responsive mobile={<AppsListMobile />} desktop={<AppsListDesktop />} />
        <HelpLink
          title={t('helpLink.title')}
          previousValue=""
          currentViewTracking={t('helpLink.amplitudeName')}
          linkURL={HELP_LINKS_APPS[language]}
          hasDisclaimer={false}
          icon="both"
        />
      </IonPageStratus>
      <UninstallAppModal />
    </>
  );
}

export default AppsListPage;
