import { ChangeEvent } from 'react';
import { InterfaceNameValue } from '@tiendanube/components';

export default function applyNimbusNameValue(
  fn: (arg: InterfaceNameValue) => void,
) {
  return function ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) {
    fn({ name, value });
  };
}
