import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import {
  useGetInitialState,
  useRefreshInfoToSyncInAppActive,
} from 'domains/Auth/hooks';
import { useKycStatus } from 'domains/NuvemPagoCommons/hooks/useKycStatus';
import { useIncrementNewOrders } from 'domains/Orders/Orders/hooks';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import { useRefreshStatisticsFreeTrial } from 'domains/Statistics';
import { useCapacitorListeners } from './capacitor';
import useAppStateChange from './useAppStateChange';
import useRefreshInAppActive from './useRefreshInAppActive';

const platform = Capacitor.getPlatform();

function useSetStatusBar(): void {
  const setStatusBarStyleLight = async () => {
    await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
    await StatusBar.setStyle({ style: Style.Light });
  };

  useEffect(() => {
    if (platform === 'web') return;
    setStatusBarStyleLight();
  }, []);
}

function useAppInit(): void {
  useGetInitialState(); // TODO: move to another component
  useSetStatusBar();
  const incrementNewOrder = useIncrementNewOrders();
  const { refreshOrders, refreshUnreadMessages } = useRefreshInAppActive();
  const refreshInfoToSync = useRefreshInfoToSyncInAppActive();
  const { fetchAppsLinks } = useAppsLinks();
  const refreshFreeTrial = useRefreshStatisticsFreeTrial();
  const { refreshKycStatus } = useKycStatus();

  useCapacitorListeners(incrementNewOrder); // TODO: move to another component

  useAppStateChange(() => {
    refreshInfoToSync();
    refreshOrders();
    refreshUnreadMessages();
  });

  useEffect(() => {
    refreshInfoToSync(true);
    refreshOrders();
    fetchAppsLinks();
    refreshFreeTrial();
    refreshKycStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAppsLinks, refreshInfoToSync]);
}

export default useAppInit;
