import { CHART_COLORS } from 'domains/Statistics/utils';

export const getSortedSelected = (selecteds: string[], colors) =>
  [...selecteds].sort((a, b) => {
    const aIndex = Object.keys(colors).indexOf(a);
    const bIndex = Object.keys(colors).indexOf(b);

    return aIndex - bIndex;
  });

export function noopFormat(value = '') {
  return value;
}

export function noopFormatValueSummary(value: number, key: string) {
  return `${value} - ${key}`;
}

export const COMBINED_CHART_COMPARED_COLORS = {
  mainPeriod: CHART_COLORS.royalBlue,
  comparedPeriod: CHART_COLORS.darkBlue,
};

export const FULL_BAR_LINE_OPACITY = 1.0;
export const LIGHT_BAR_OPACITY = 0.3;
export const LIGHT_LINE_OPACITY = 0.2;

export const LABEL_FONT_SIZE = 12;

export const ANIMATION_DURATION = 500;
