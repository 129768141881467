export const CHART_COLORS = {
  lightBlue: '#1395AE',
  brightRed: '#ED5E5E',
  darkPurple: '#CC0088',
  deepPurple: '#993399',
  navyBlue: '#003E74',
  skyBlue: '#006BC8',
  electricBlue: '#0F0FDC',
  burntOrange: '#BC462F',
  lightGray: '#CFD1D2',
  darkRed: '#930002',
  royalBlue: '#477EEB',
  darkBlue: '#380F8A',
  seaGreen: '#26A163',
  pink: '#FFB92E',
};
