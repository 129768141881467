import { Text } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeliveryDaysInfoProps {
  deliveryTimeMax?: string;
  deliveryTimeMin?: string;
}

function DeliveryDaysInfo({
  deliveryTimeMax,
  deliveryTimeMin,
}: Readonly<DeliveryDaysInfoProps>) {
  const t = useTranslationShipping();

  return (
    <Text>
      {deliveryTimeMax !== deliveryTimeMin &&
        Number(deliveryTimeMin) > 0 &&
        t('deliveryMethods.newInformationShipping.deliveryDaysRange', {
          deliveryTimeMax,
          deliveryTimeMin,
        })}
      {deliveryTimeMax === deliveryTimeMin &&
        Number(deliveryTimeMax) > 0 &&
        t('deliveryMethods.newInformationShipping.deliveryDaysWithSameDay', {
          deliveryTimeMin,
        })}
      {!!deliveryTimeMax &&
        Number(deliveryTimeMin) < 1 &&
        Number(deliveryTimeMax) > 0 &&
        t('deliveryMethods.newInformationShipping.deliveryDaysWithoutMin', {
          deliveryTimeMax,
        })}
    </Text>
  );
}

export default DeliveryDaysInfo;
