import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import {
  AppItemCard,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationSalesChannels from 'domains/SalesChannels/useTranslationSalesChannels';
import {
  SalesChannelsAppsPageError,
  SalesChannelsAppsPageSkeleton,
} from './components';
import useSalesChannelsApps from './useSalesChannelsApps';

function SalesChannelsAppsPage() {
  const t = useTranslationSalesChannels('salesChannelsApps');
  const language = useTranslationLanguage();
  const {
    isLoading,
    isSuccess,
    isError,
    salesChannelsApps,
    getSalesChannelsApps,
  } = useSalesChannelsApps();

  return (
    <IonPageStratus
      width="medium"
      headerTop={<HeaderTop />}
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      {isLoading && <SalesChannelsAppsPageSkeleton />}
      {isError && (
        <SalesChannelsAppsPageError
          getSalesChannelsApps={getSalesChannelsApps}
        />
      )}
      {isSuccess && salesChannelsApps.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns={{
            lg: '1fr 1fr 1fr',
            md: '1fr',
            xs: '1fr',
          }}
          gridGap="4"
        >
          {salesChannelsApps.map((salesChannelsApp) => (
            <AppItemCard key={salesChannelsApp.name} app={salesChannelsApp} />
          ))}
        </Box>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_APPS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default SalesChannelsAppsPage;
