import { v4 as uuidv4 } from 'uuid';
import {
  FulfillmentResponseDto,
  PaymentResponseDto,
  LogEntryResponseDto,
} from '@tiendanube/common';
import { Timeline, Card } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import CardTimelineErrorState from './CardTimelineErrorState';
import CardTimelineSkeleton from './CardTimelineSkeleton';
import useCardTimeline from './useCardTimeline';

interface CardTimelineProps {
  fulfillment: FulfillmentResponseDto;
  payment: PaymentResponseDto;
  logEntries: LogEntryResponseDto[];
  isShippablePickup: boolean;
}

function CardTimeline({
  logEntries,
  fulfillment,
  payment,
  isShippablePickup,
}: CardTimelineProps) {
  const t = useTranslationOrders();
  const timelineProps = useCardTimeline({
    fulfillment,
    payment,
    isShippablePickup,
  });

  const logClone = [...logEntries];

  const timeLogs = logClone
    .reverse()
    .map((log) => <Timeline.Item key={uuidv4()} {...timelineProps(log)} />);

  if (logEntries.length === 0) return null;

  return (
    <Card title={t('cardTimeline.title')}>
      <Timeline>{timeLogs}</Timeline>
    </Card>
  );
}

CardTimeline.Skeleton = CardTimelineSkeleton;
CardTimeline.ErrorState = CardTimelineErrorState;

export default CardTimeline;
