import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { ErrorState, Card } from '@tiendanube/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface LocationErrorStateProps {
  onRetryError: () => void;
}

function LocationErrorState({
  onRetryError,
}: LocationErrorStateProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const tShipping = useTranslationShipping();

  return (
    <Card title={t('products.detail.location')}>
      <ErrorState
        title={tShipping('locations.errorResults.title')}
        action={{
          children: tShipping('locations.errorResults.action'),
          onClick: onRetryError,
        }}
      />
    </Card>
  );
}

export default LocationErrorState;
