import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { AuthenticationFactorSchema } from './authenticationFactorSlice';

export const getAccountState = (
  state: RootStateType,
): CombinedState<AuthenticationFactorSchema> => state.authenticationFactor;

export const verifyAndAuthorizeStatus = createSimpleSelector(
  [getAccountState],
  (authenticationFactor) => ({
    status: authenticationFactor.verifyAndAuthorize.status,
  }),
);

export const verifyAndAuthorizeValidResult = createSimpleSelector(
  [getAccountState],
  (authenticationFactor) => authenticationFactor.verifyAndAuthorize.data?.valid,
);
