import { Box, Link } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { PRODUCT_UPDATES_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { ReactComponent as LogoLatam } from './images/es-latam.svg';
import { ReactComponent as LogoBrazil } from './images/pt-brasil.svg';

export function ProductUpdatesLink() {
  const language = useTranslationLanguage();
  const country = useGetCountry();

  return (
    <Link as="a" href={PRODUCT_UPDATES_LINKS[language]} target="_blank">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary-surface"
        borderRadius="2"
        paddingLeft="2"
        paddingRight="2"
        height="24px"
      >
        {country === Country.BR ? <LogoBrazil /> : <LogoLatam />}
      </Box>
    </Link>
  );
}
