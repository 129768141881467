import { useEffect } from 'react';
import { useModal } from 'commons/hooks';
import {
  useAuthenticationFactors,
  useResetAuthenticationFactors,
} from 'domains/Account/hooks';
import useVerifyAuthenticationFactor from '../useVerifyAuthenticationFactor';

interface UseAuthenticationFactorAuthorizationProps {
  skipAuthorization?: boolean;
  onSave: () => void;
  preSubmitValidation?: (onValid: () => void) => Promise<void>;
}

interface UseAuthenticationFactorAuthorizationResult {
  isLoading: boolean;
  showAuthenticationFactorModal: boolean;
  handleCancel: () => void;
  handleSave: () => void;
  handleIsValidCode: () => void;
}

function useAuthenticationFactorAuthorization({
  skipAuthorization = false,
  onSave,
  preSubmitValidation,
}: UseAuthenticationFactorAuthorizationProps): UseAuthenticationFactorAuthorizationResult {
  const { status, authenticationFactors, fetchAuthenticationFactors } =
    useAuthenticationFactors();
  const { cleanState } = useVerifyAuthenticationFactor();

  const hasActiveAuthenticationFactor = authenticationFactors.some(
    (current) => current.active,
  );

  const resetAuthenticationFactors = useResetAuthenticationFactors();

  const [
    showAuthenticationFactorModal,
    openAuthenticationFactorModal,
    closeAuthenticationFactorModal,
  ] = useModal();

  const has2FA = status === 'success' && hasActiveAuthenticationFactor;
  const hasNot2FA =
    (status === 'success' && !hasActiveAuthenticationFactor) ||
    status === 'error';

  const handleCancel = () => {
    closeAuthenticationFactorModal();
    cleanState();
  };
  const handleSave = () => {
    if (skipAuthorization) {
      onSave();
    } else {
      if (!preSubmitValidation) fetchAuthenticationFactors();
      else preSubmitValidation(fetchAuthenticationFactors);
    }
    cleanState();
  };

  const handleIsValidCode = () => {
    onSave();
    closeAuthenticationFactorModal();
  };

  useEffect(() => {
    if (has2FA) {
      const onValid = () => {
        resetAuthenticationFactors();
        openAuthenticationFactorModal();
        return;
      };
      preSubmitValidation ? preSubmitValidation(onValid) : onValid();
      return;
    }
    if (hasNot2FA) {
      resetAuthenticationFactors();
      onSave();
      return;
    }
  }, [
    has2FA,
    hasNot2FA,
    onSave,
    openAuthenticationFactorModal,
    preSubmitValidation,
    resetAuthenticationFactors,
  ]);

  return {
    isLoading: status === 'loading',
    showAuthenticationFactorModal,
    handleCancel,
    handleSave,
    handleIsValidCode,
  };
}

export default useAuthenticationFactorAuthorization;
