import { Table, Text } from '@nimbus-ds/components';
import { ProviderRateDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { PaymentMethodLabel } from 'domains/Payments/PaymentProviders/components';
import {
  TRANSLATE_PAYMENT_PREFIX,
  isLastItem,
} from 'domains/Payments/PaymentProviders/components/utils';
import { useGetCptFee } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { PaymentMethodsHeader } from './PaymentMethodsHeader';
import { PercentFeeItem } from '../../PercentFeeItem';

interface PaymentProviderProps {
  providerId: string;
  rates: ProviderRateDto[];
}

export function PaymentMethodsTable({
  rates,
  providerId,
}: Readonly<PaymentProviderProps>) {
  const t = useTranslationPayments(TRANSLATE_PAYMENT_PREFIX);
  const { getCptFee } = useGetCptFee();

  const getDayToWithdrawMoney = (daysToWithdrawMoney) => {
    if (daysToWithdrawMoney) {
      return t('daysToWithdrawMoney', { count: daysToWithdrawMoney });
    }
    return t('withdrawMoneyAtTheMoment');
  };

  return (
    <Table>
      <PaymentMethodsHeader />
      <Table.Body>
        {rates.map(({ paymentMethods, definitions }, index) => (
          <Table.Row key={`item-${index}`}>
            <Table.Cell>
              <div className="payment-method-cell">
                <PaymentMethodLabel
                  paymentMethods={paymentMethods}
                  providerId={providerId}
                />
              </div>
            </Table.Cell>
            <Table.Cell>
              {definitions.map(({ daysToWithdrawMoney }, index) => (
                <div key={`item-${index}`} className="payment-method-cell">
                  <Stack>
                    <Text>{getDayToWithdrawMoney(daysToWithdrawMoney)}</Text>
                  </Stack>
                  {!isLastItem(definitions, index) && <hr />}
                </div>
              ))}
            </Table.Cell>
            <Table.Cell>
              {definitions.map((definition, index) => (
                <div key={`item-${index}`} className="payment-method-cell">
                  <PercentFeeItem
                    definition={definition}
                    id={providerId}
                    key={index}
                  />
                  {!isLastItem(definitions, index) && <hr />}
                </div>
              ))}
            </Table.Cell>
            <Table.Cell>
              {definitions.map((_, index) => (
                <div key={`item-${index}`} className="payment-method-cell">
                  <Stack>
                    {getCptFee({
                      providerId,
                      paymentMethods,
                    })}
                  </Stack>
                  {!isLastItem(definitions, index) && <hr />}
                </div>
              ))}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
