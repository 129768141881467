import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  updateNuvemEnvioConfigsAction,
  updateNuvemEnvioConfigsStatus,
} from '../../deliveryMethodsSlice';

type NuvemEnvioConfigsType = {
  enableEngagementBanner?: boolean;
  showNuvemEnvioModal?: boolean;
};

function useUpdateNuvemEnvioConfigs() {
  const dispatch = useAppDispatch();

  const status = useSelector(updateNuvemEnvioConfigsStatus);

  const updateNuvemEnvioConfigs = useCallback(
    async (payload: NuvemEnvioConfigsType) =>
      await dispatch(updateNuvemEnvioConfigsAction(payload)),
    [dispatch],
  );

  return {
    status,
    updateNuvemEnvioConfigs,
  };
}

export default useUpdateNuvemEnvioConfigs;
