import { Box, Card, Spinner, Text, Title } from '@nimbus-ds/components';
import {
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import { Stack, useResponsive } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import ContentDeclarationPreview from './components/ContentDeclarationPreview';
import DocumentPreview from './components/DocumentPreview';
import { DocumentType } from '../../constants';
import './PrintDocumentPreview.scss';

interface PrintDocumentPreviewProps {
  type: DocumentType;
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  ocaLabels: OcaLabelResponseDto[];
  isLoading: boolean;
  hasOca: boolean;
  progressOca: string | null;
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
  componentRef?: React.RefObject<HTMLDivElement>;
}

function PrintDocumentPreview({
  type,
  information,
  isLoading,
  settings,
  locationId,
  componentRef,
  hasOca,
  ocaLabels,
  progressOca,
}: Readonly<PrintDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('printManager');
  const { isMobile } = useResponsive();

  const width = isMobile ? '100%' : '45vw';
  const alignItems = isLoading ? 'center' : 'flex-start';

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('viewTitle')}</Title>
      </Card.Header>
      <Card.Body>
        <Box
          id={`print-container-${type}`}
          backgroundColor="neutral-surfaceDisabled"
          borderColor="neutral-surfaceDisabled"
          borderRadius="2"
          width={width}
          minHeight="55vh"
          maxHeight="65vh"
          overflowX="auto"
          display="flex"
          alignItems={alignItems}
          justifyContent="center"
          flexWrap="wrap"
          ref={componentRef}
        >
          {isLoading && !hasOca && <Spinner size="large" />}
          {isLoading && hasOca && (
            <Stack column justify="center">
              <Spinner size="large" />
              {!!progressOca && (
                <Text>
                  {t('oca.progress')} {progressOca}
                </Text>
              )}
            </Stack>
          )}

          {type !== DocumentType.CONTENT_DECLARATION &&
            !isLoading &&
            information && (
              <DocumentPreview
                type={type}
                information={information as InformationForDocumentResponseDto[]}
                settings={settings}
                locationId={locationId}
                hasOca={hasOca}
                ocaLabels={ocaLabels}
              />
            )}

          {type === DocumentType.CONTENT_DECLARATION &&
            !isLoading &&
            information && (
              <ContentDeclarationPreview
                information={
                  information as InformationForContentDeclarationResponseDto[]
                }
                locationId={locationId}
              />
            )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PrintDocumentPreview;
