import { useEffect, useState } from 'react';
import { Box, Icon, Link } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { SideModal } from '@nimbus-ds/patterns';
import { generatePath } from 'react-router-dom';
import { GetAvailableMethodsResponseDto } from '@tiendanube/common';
import { FEATURE_ONLY_SHIPPING_NATIVE } from 'App/features';
import { useNavegate } from 'App/hooks';
import { ErrorScreen, Stack } from 'commons/components';
import { SHIPPING_INSTALL_APP } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import useEnableCarrier from 'domains/Shipping/DeliveryMethods/hooks/useEnableCarrier';
import { trackingShippingViewedMethod } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import EnabledShippingMethods from './components/EnabledShippingMethods';
import useAvailableApps from './useAvailableApps';
import AddActiveShippingMethodsDetailModal, {
  AddActiveShippingMethodsDetailInterface,
} from '../AddActiveShippingMethodsDetailModal';

interface AddActiveShippingMethodsModalProps {
  onClose: () => void;
}

function AddActiveShippingMethodsModal({
  onClose,
}: AddActiveShippingMethodsModalProps) {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const [app, setApp] = useState<AddActiveShippingMethodsDetailInterface>();
  const {
    enableCarrier,
    isLoading: isLoadingEnableCarrier,
    isSuccess: isSuccessEnableCarrier,
  } = useEnableCarrier();
  const { availableApps, getAvailableApps, isLoading, isError } =
    useAvailableApps();

  useEffect(() => {
    if (!availableApps) getAvailableApps();
  }, [availableApps, getAvailableApps]);

  const handleOnChange = ([id]: string[]) => {
    const appSelected = availableApps?.find((app) => app.appId === id);

    if (appSelected === undefined) {
      setApp(undefined);
      return;
    }

    if (appSelected.enableForStoreFeaturePlan) {
      selectApp(appSelected);
    } else {
      selectAppWithUpsell(appSelected);
    }
  };

  const selectApp = (appSelected: GetAvailableMethodsResponseDto) => {
    trackingShippingViewedMethod(appSelected.appId, appSelected.name);
    setApp(appSelected);
  };

  const selectAppWithUpsell = useUpsellFlow({
    title: t('deliveryMethods.activeShippingMethods.upsellTitle'),
    featureKey: FEATURE_ONLY_SHIPPING_NATIVE,
    trackingSource: SHIPPING_INSTALL_APP,
    callback: (appSelected: GetAvailableMethodsResponseDto) => {
      selectApp(appSelected);
      getAvailableApps();
    },
    asAside: true,
  });

  const handleOnClose = () => {
    setApp(undefined);
  };

  const handleOnSave = () => {
    const carrierId = app?.id;
    if (carrierId) {
      enableCarrier({ id: carrierId });
      if (!isLoadingEnableCarrier && isSuccessEnableCarrier) {
        onClose();
      }

      return;
    }
    const path = generatePath(partnersAppsRoutes.authorization, {
      id: app?.appId,
    });
    goTo(path);
  };

  const enabledApps =
    availableApps?.filter((app) => app.enableForStoreFeaturePlan) || [];
  const disabledApps =
    availableApps?.filter((app) => !app.enableForStoreFeaturePlan) || [];

  return (
    <SideModal
      open
      onRemove={onClose}
      maxWidth={{ xs: '100%', md: '45%', lg: '540px' }}
      padding="base"
      paddingHeader="none"
      headerAction={
        <Link as="a" onClick={onClose} textDecoration="none">
          <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
        </Link>
      }
    >
      <Box width="100%" paddingBottom="4">
        <Stack column>
          {isError && (
            <ErrorScreen
              description={t(
                'deliveryMethods.activeShippingMethods.modal.error.text',
              )}
              onRetry={getAvailableApps}
            />
          )}
          {isLoading && <EnabledShippingMethods.Skeleton />}
          {!isError &&
            availableApps &&
            enabledApps.length > 0 &&
            disabledApps.length > 0 && (
              <>
                <EnabledShippingMethods
                  title={t(
                    'deliveryMethods.activeShippingMethods.modal.title.availableForPlan',
                  )}
                  apps={enabledApps}
                  onChange={handleOnChange}
                />
                <EnabledShippingMethods
                  title={t(
                    'deliveryMethods.activeShippingMethods.modal.title.availableForUpsell',
                  )}
                  apps={disabledApps}
                  onChange={handleOnChange}
                />
              </>
            )}
          {!isError &&
            availableApps &&
            (enabledApps.length === 0 || disabledApps.length === 0) && (
              <EnabledShippingMethods
                title={t(
                  'deliveryMethods.activeShippingMethods.modal.title.allAvailable',
                )}
                apps={availableApps}
                onChange={handleOnChange}
              />
            )}
          {!!app && (
            <AddActiveShippingMethodsDetailModal
              app={app}
              onClose={handleOnClose}
              onSave={handleOnSave}
            />
          )}
        </Stack>
      </Box>
    </SideModal>
  );
}

export default AddActiveShippingMethodsModal;
