import { ChangeEvent, useState } from 'react';
import { Checkbox, Link, Text } from '@nimbus-ds/components';
import { EditIcon, EyeIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { NotificationDetailsDto } from '@tiendanube/common';
import { LanguagesType } from 'App/i18n';
import { Stack } from 'commons/components';
import { useStoreName } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { replacePreviewVariables } from '../utils';

interface HtmlTemplateTabProps {
  values: NotificationDetailsDto;
  placeholder: string;
  language: LanguagesType;
  isPhishingSuspect: boolean;
  onChangeHtmlEnabled: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeTextarea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function HtmlTemplateTab({
  values,
  placeholder,
  language,
  isPhishingSuspect,
  onChangeHtmlEnabled,
  onChangeTextarea,
}: Readonly<HtmlTemplateTabProps>) {
  const t = useTranslationConfigurations(
    'notifications.editNotificationPage.formCard.templateHtml',
  );
  const storeName = useStoreName();

  const [showHtmlEditor, setShowHtmlEditor] = useState(false);

  const handleClickToggleHtmlEditor = () => setShowHtmlEditor(!showHtmlEditor);

  return (
    <Stack column align="stretch">
      <Stack column spacing="tight" align="flex-start">
        <Text fontSize="highlight" fontWeight="bold">
          {t('title')}
        </Text>
        <Text>{t('subtitle')}</Text>
      </Stack>
      <Checkbox
        label={t('enableHtml')}
        name="htmlEnabled"
        checked={values.htmlEnabled}
        onChange={onChangeHtmlEnabled}
      />
      {values.htmlEnabled && (
        <Stack column align="flex-start">
          <Link
            appearance="primary"
            textDecoration="none"
            onClick={handleClickToggleHtmlEditor}
          >
            {showHtmlEditor ? (
              <>
                {t('hideHtmlEditor')} <EyeIcon />
              </>
            ) : (
              <>
                {t('showHtmlEditor')} <EditIcon />
              </>
            )}
          </Link>
          {showHtmlEditor ? (
            <FormField.Textarea
              id="templateHtml"
              label={t('body')}
              lines={28}
              placeholder={placeholder}
              name="templateHtml"
              disabled={isPhishingSuspect}
              value={values[language].templateHtml}
              onChange={onChangeTextarea}
            />
          ) : (
            <iframe
              srcDoc={replacePreviewVariables(
                values[language].templateHtml || '',
                t,
                storeName,
              )}
              width="100%"
              height="410px"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}
