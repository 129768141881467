import { useState } from 'react';
import { RelatedProductTypes } from '@tiendanube/common';
import {
  RelatedProductInterface,
  RelatedProductType,
} from '../../hooks/useProductForm';

export type RelatedProductAcceptTypes = RelatedProductTypes;

export type OnChangeRelatedProduct = (newProduct: RelatedProductType[]) => void;

export interface UseRelatedProductProps {
  onChange: (relatedProducts: RelatedProductInterface) => void;
  relatedProducts?: RelatedProductInterface;
}

function useRelatedProduct({
  relatedProducts,
  onChange,
}: UseRelatedProductProps) {
  const defaultRelatedProduct: RelatedProductInterface = {
    alternative: [],
    complementary: [],
    status: 'success',
  };
  const [selectedRelatedProduct, setSelectedRelatedProduct] =
    useState<RelatedProductInterface>(relatedProducts || defaultRelatedProduct);

  const [type, setType] = useState<RelatedProductTypes>();

  const handleOnChange = (newProducts: RelatedProductType[]): void => {
    const productType = type?.toLowerCase() || 'alternative';

    const currentState = {
      ...selectedRelatedProduct,
      [productType]: newProducts,
    };
    onChange(currentState);
    setSelectedRelatedProduct(currentState);
  };

  return {
    type,
    selectedRelatedProduct,
    setType,
    setSelectedRelatedProduct,
    handleOnChange,
  };
}

export default useRelatedProduct;
