import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchPhysicalLocationsAction,
  deletePhysicalLocationAction,
  getPhysicalLocations,
  getPhysicalLocationsStatus,
  cleanDeletePhysicalLocationStatus,
  deletePhysicalLocationsStatus,
} from '../../../../deliveryMethodsSlice';

function usePhysicalLocations() {
  const physicalLocations = useSelector(getPhysicalLocations);
  const getStatus = useSelector(getPhysicalLocationsStatus);
  const deleteStatus = useSelector(deletePhysicalLocationsStatus);
  const dispatch = useAppDispatch();

  const fetchPhysicalLocations = useCallback(
    (forceUpdate?: boolean) => {
      if (!physicalLocations || forceUpdate) {
        dispatch(fetchPhysicalLocationsAction());
      }
    },
    [dispatch, physicalLocations],
  );

  const deletePhysicalLocation = async (id: string) => {
    await dispatch(deletePhysicalLocationAction(id));
    dispatch(cleanDeletePhysicalLocationStatus());
  };

  return {
    fetchPhysicalLocations,
    deletePhysicalLocation,
    physicalLocations,
    getStatus,
    deleteStatus,
  };
}

export default usePhysicalLocations;
