import { useTranslation } from 'react-i18next';
import { Text } from '@tiendanube/components';
import { SearchIcon } from '@tiendanube/icons';

interface FilterTotalProps {
  label: string;
  total: number;
  loading: boolean;
}

function FilterTotal({ label, total, loading }: FilterTotalProps): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <>
      {loading && (
        <>
          <Text block appearance="light">
            {label}
          </Text>
          <Text.Skeleton width="large" size="featured" />
        </>
      )}
      {!loading && total > 0 && (
        <>
          <Text block appearance="light">
            {label}
          </Text>
          <Text appearance="primary" size="featured">
            {total}
          </Text>
        </>
      )}
      {!loading && total === 0 && (
        <Text size="caption">
          <SearchIcon /> {t('exportCard.noResults')}
        </Text>
      )}
    </>
  );
}

export default FilterTotal;
