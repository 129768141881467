export type CouponStatus = "enabled" | "disabled" | "forbidden" | "deleted";

export const COUPON_DISCOUNT_VALUES = [
  "percentage",
  "absolute",
  "shipping",
] as const;
export type CouponDiscountType = typeof COUPON_DISCOUNT_VALUES[number];

export const FILTER_ALL = "";
export type FilterAllType = typeof FILTER_ALL;

export const DISCOUNT_VALUES = [FILTER_ALL, ...COUPON_DISCOUNT_VALUES] as const;
export type CouponFilterDiscountType = typeof DISCOUNT_VALUES[number];

export const INCLUDE_SHIPPING_VALUES = [FILTER_ALL, "true", "false"] as const;
export type IncludeShippingType = typeof INCLUDE_SHIPPING_VALUES[number];

export const TERM_VALUES = [FILTER_ALL, "unlimited", "limited"] as const;
export type TermType = typeof TERM_VALUES[number];

export const STATUS_VALUES = [
  FILTER_ALL,
  "activated",
  "deactivated",
  "deleted",
] as const;
export type StatusType = typeof STATUS_VALUES[number];

export const LIMITATION_VALUES = [
  FILTER_ALL,
  "categories",
  "quantity",
  "cart_value",
] as const;
export type LimitationType = typeof LIMITATION_VALUES[number];

export const HAS_MIN_VALUE_VALUES = [FILTER_ALL, "false", "true"] as const;
export type HasMinValueType = typeof HAS_MIN_VALUE_VALUES[number];

export const HAS_USE_LIMIT_VALUES = [FILTER_ALL, "false", "true"] as const;
export type HasUseLimitType = typeof HAS_USE_LIMIT_VALUES[number];

export const SORT_BY_VALUES = [
  "alpha-ascending",
  "alpha-descending",
  "created-at-descending",
  "created-at-ascending",
  "uses-descending",
  "uses-ascending",
] as const;
export type SortByType = typeof SORT_BY_VALUES[number];

export const CLIENT_LIMITATION_VALUES = [
  "unlimited",
  "limited",
  "first_consumer_purchase",
] as const;
export type ClientLimitationType = typeof CLIENT_LIMITATION_VALUES[number];
