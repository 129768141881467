import { Text, TextProps, Link } from '@nimbus-ds/components';
import { FEATURE_AI_TIER } from 'App/features';
import { useTranslationCommon } from 'commons/hooks';
import { useAiUsage } from 'domains/Ai/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';

const SHOW_PERCENTAGE_THRESHOLD = 30; // Show text when remaining uses are below or equal to this percentage

interface RemainingAiUsesTextProps extends Omit<TextProps, 'children'> {
  aiFeatureTrackingSource?: string;
}

function RemainingAiUsesText({
  aiFeatureTrackingSource,
  ...props
}: Readonly<RemainingAiUsesTextProps>) {
  const t = useTranslationCommon();

  const { requiredTier, maxAiUsesInPlan, remainingAiUses, aiUsesUpsellTitle } =
    useAiUsage();

  const remainingAiUsesAsPercentage = (() => {
    if (maxAiUsesInPlan === -1) return 100;
    if (maxAiUsesInPlan === 0) return 0;
    return (remainingAiUses / maxAiUsesInPlan) * 100;
  })();

  const onClickUpsell = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: aiFeatureTrackingSource,
    minValue: requiredTier,
    asAside: true,
  });

  if (
    remainingAiUsesAsPercentage > SHOW_PERCENTAGE_THRESHOLD ||
    maxAiUsesInPlan === 0
  ) {
    return null;
  }

  const textKey = (() => {
    if (remainingAiUses === 1) return 'singular';
    if (remainingAiUses === 0) return 'zero';
    return 'plural';
  })();

  return (
    <Text fontSize="caption" {...props}>
      {t(`ai.remainingUses.${textKey}`, {
        count: remainingAiUses,
      })}
      {remainingAiUses === 0 && (
        <>
          {' '}
          <Link
            fontSize="caption"
            textDecoration="underline"
            onClick={onClickUpsell}
          >
            {t('ai.remainingUses.upsellCta')}
          </Link>
        </>
      )}
    </Text>
  );
}

export default RemainingAiUsesText;
