import { useMemo } from 'react';
import { usePaymentsHistory } from '../../TransactionFeesPage/hooks';

function useTransactionListDate(
  split: boolean,
  paymentId?: string,
): Date | undefined {
  const { paymentsHistory, isSuccess } = usePaymentsHistory(undefined, true);

  const result = useMemo(() => {
    if (!paymentId || !isSuccess || !paymentsHistory) return undefined;

    for (const { year, payments } of paymentsHistory) {
      const selectedPayments = payments[split ? 'split' : 'noSplit'];

      for (const { id, month } of selectedPayments) {
        /**
         * We use 15 as day input because it's a day in the middle of the month
         * that prevents timezone issues
         *  */
        if (id === paymentId)
          return new Date(Number(year), Number(month) - 1, 15);
      }
    }
  }, [paymentsHistory, isSuccess, paymentId, split]);

  return result;
}

export default useTransactionListDate;
