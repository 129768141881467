import { Alert, Text, Button } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { ShowByTag } from 'App/components';
import ShowByCountry from 'App/components/ShowByCountry';
import { PAGSEGURO_REVOKED_PERMISSIONS } from 'App/featuresFlags';
import { useToastStatus } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import usePagSgeuroCredentialsAlert from './usePagSgeuroCredentialsAlert/usePagSgeuroCredentialsAlert';

function PagseguroCredentialsAlert() {
  const t = useTranslationDashboard('paymentMessages.pagSeguro');

  const { getPagseguroUrl, url, status } = usePagSgeuroCredentialsAlert();

  useToastStatus({
    status,
    error: `${t('toastError')}`,
    onSuccess() {
      if (url) openWindow(url, true);
    },
  });

  return (
    <ShowByCountry allowedCountries={[Country.BR]}>
      <ShowByTag tag={PAGSEGURO_REVOKED_PERMISSIONS}>
        <Alert appearance="danger" title={`${t('title')}`}>
          <Text>{t('description')}</Text>
          <Button
            appearance="primary"
            onClick={getPagseguroUrl}
            disabled={status === 'loading'}
          >
            {t('cta')}
          </Button>
        </Alert>
      </ShowByTag>
    </ShowByCountry>
  );
}

export default PagseguroCredentialsAlert;
