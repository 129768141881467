import { toStatusType } from 'App/rtk';
import { useAppDispatch } from 'App/store';
import { useUninstallAppMutation } from 'domains/PartnersApps/partnersAppsApi';
import { removePaymentProverFromInstalledListAction } from 'domains/Payments/PaymentProviders/paymentProvidersSlice';

export function useUninstallApp(appId: string) {
  const [uninstallApp, { status }] = useUninstallAppMutation();
  const dispatch = useAppDispatch();

  const handleUninstallApp = () => {
    uninstallApp({ appId });
  };

  const makeHandleSuccess = (fn) => () => {
    dispatch(removePaymentProverFromInstalledListAction(appId));
    fn();
  };

  return {
    handleUninstallApp,
    makeHandleSuccess,
    uninstallStatus: toStatusType(status),
  };
}
