import { Text, Box, IconButton, Title } from '@nimbus-ds/components';
import { EyeOffIcon, EyeIcon, InfoCircleIcon } from '@nimbus-ds/icons';
import { Tooltip } from '@tiendanube/components';
import { AI_DASHBOARD_USAGE_REPORT_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { Stack } from 'commons/components';
import { useStorage } from 'commons/hooks';
import { useGetUsageReportQuery } from 'domains/Ai/aiApi';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import AiUsageReport from '../AiUsageReport';
import CardStatisticsDaily from '../CardStatisticsDaily';
import SalesQuantityRange from '../SalesQuantityRange';

const MIN_MINUTES_SAVED_TO_SHOW_REPORT = 300; //5h

interface StoreStatsProps {
  showDailyStats?: boolean;
}

function StoreStats({ showDailyStats = true }: Readonly<StoreStatsProps>) {
  const t = useTranslationDashboard();

  const aiUsageReportEnabled = useHasTags(AI_DASHBOARD_USAGE_REPORT_ENABLED);
  const { data: usageReport } = useGetUsageReportQuery();

  const [showAmounts, setShowAmount] = useStorage(
    'showAmountsDashboard',
    true,
    true,
  );

  function handleOnClick() {
    setShowAmount(!showAmounts);
  }

  if (usageReport === undefined) {
    return null;
  }

  // Only show the AI usage report if the time saved is greater than a min threshold, either this month or the previous one.
  // This is because the first few days of the new month would not meet the threshold and we don't want the report to go away if the previous month was good.
  const showAiUsageReport =
    aiUsageReportEnabled &&
    (usageReport.lastMonth.minutesSaved >= MIN_MINUTES_SAVED_TO_SHOW_REPORT ||
      usageReport.currentMonth.minutesSaved >=
        MIN_MINUTES_SAVED_TO_SHOW_REPORT);

  const reports: React.ReactNode[] = [];

  if (showDailyStats) {
    reports.push(<CardStatisticsDaily showAmounts={showAmounts} />);
  }

  if (showAiUsageReport) {
    reports.push(
      <AiUsageReport
        minutesSaved={usageReport.currentMonth.minutesSaved}
        moneySavedValue={usageReport.currentMonth.moneySavedValue}
        moneySavedCurrency={usageReport.currentMonth.moneySavedCurrency}
      />,
    );
  }

  reports.push(
    <div style={{ marginTop: '-32px' }}>
      <SalesQuantityRange />
    </div>,
  );

  if (reports.length < 2) {
    return null;
  }

  return (
    <Stack column align="stretch">
      <Box display="flex" gap="4" alignItems="center">
        <Title as="h3" fontWeight="medium">
          {t('tabs.title')}
        </Title>
        {showDailyStats && (
          <Box
            display="flex"
            flexDirection="row"
            gap="1"
            alignItems="flex-start"
          >
            <IconButton
              size="2rem"
              as="button"
              backgroundColor="neutral-surface"
              color="red"
              onClick={handleOnClick}
              source={
                showAmounts ? (
                  <EyeOffIcon size="small" />
                ) : (
                  <EyeIcon size="small" />
                )
              }
            />
            <Tooltip ariaLabel={t('tooltip')} labelIcon={InfoCircleIcon}>
              <Text fontSize="caption" color="neutral-surface">
                {t('tabs.tooltip')}
              </Text>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box
        display={{
          lg: 'grid',
        }}
        gridTemplateColumns={
          showDailyStats && showAiUsageReport ? '2fr 1fr' : '1fr 1fr'
        }
        gridGap="4"
      >
        {reports[0]}
        {reports[1]}
      </Box>
    </Stack>
  );
}

export default StoreStats;
