import { useMemo } from 'react';
import Cookies from 'js-cookie';
import {
  ADMIN_SESSION_COOKIE_KEY,
  ADMIN_SESSION_ID_PREFIX,
} from '../../constants';

function useCurrentUserSessionId(): string {
  const currentSessionId = useMemo(() => {
    const id = Cookies.get(ADMIN_SESSION_COOKIE_KEY);

    return id ? ADMIN_SESSION_ID_PREFIX + id : '';
  }, []);

  return currentSessionId;
}

export default useCurrentUserSessionId;
