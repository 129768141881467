import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { CurrencyType, OrderDetailsAmountsDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { DataList, Text } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { TOTAL_PAID_ORDER_DETAIL } from 'App/featuresFlags';
import { CurrencyPrice } from 'commons/components';

interface OrderSummaryAmountsProps {
  amounts: OrderDetailsAmountsDto;
  currency: CurrencyType;
  totalProducts: number;
}
const DRAFT_ORDER_DISCOUNT_COUPON_PREFIX = 'DRAFT-ORDER';
const POS_ORDER_DISCOUNT_COUPON_PREFIX = 'POS-ORDER-';

function OrderSummaryAmounts({
  amounts: {
    subtotal,
    total,
    weight,
    freeShippingDiscount,
    shipping,
    shippingMethod,
    discount,
    discountDetail,
    shippingDetail,
    gatewayDiscount,
    couponDiscount,
    otherDiscounts,
    totalPaid,
    otherCosts,
  },
  currency,
  totalProducts,
}: OrderSummaryAmountsProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  const hasWeight = !!(weight && weight > 0);
  const shippingMethodWeight = shippingMethod || hasWeight;

  const couponDiscountDraft = couponDiscount?.codes?.some((discount) =>
    discount.includes(DRAFT_ORDER_DISCOUNT_COUPON_PREFIX),
  );

  const couponDiscountPOS = couponDiscount?.codes?.some((discountCode) =>
    discountCode.startsWith(POS_ORDER_DISCOUNT_COUPON_PREFIX),
  );

  return (
    <>
      <DataList.Row grayed id="subtotal">
        <DataList.Cell width={70}>
          <Text>{`${t('orderSummaryCard.tableAmounts.subtotal', {
            count: totalProducts,
          })}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={subtotal}
            currency={currency}
            block
            textAlign="right"
          />
        </DataList.Cell>
      </DataList.Row>
      {!!shipping && shipping > 0 && (
        <DataList.Row grayed id="shippingCost">
          <DataList.Cell width={70}>
            <Text>
              {shippingDetail
                ? `${t('orderSummaryCard.tableAmounts.shippingDetail', {
                    shippingDetail,
                  })}`
                : `${t('orderSummaryCard.tableAmounts.shipping', {
                    shippingMethod,
                  })}`}
            </Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={shipping}
              currency={currency}
              block
              textAlign="right"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!discount && discount > 0 && (
        <DataList.Row id="discount">
          <DataList.Cell width={70}>
            <Text>{discountDetail}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={discount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!discount && !freeShippingDiscount && discount < 0 && (
        <DataList.Row id="additionalShippingCost">
          <DataList.Cell width={70}>
            <Text>{t('orderSummaryCard.tableAmounts.aditionalCost')}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={-discount}
              currency={currency}
              block
              textAlign="right"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!gatewayDiscount && gatewayDiscount > 0 && (
        <DataList.Row id="gatewayDiscount">
          <DataList.Cell width={70}>
            <Text>{`${t(
              'orderSummaryCard.tableAmounts.gatewayDiscount',
            )}`}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={gatewayDiscount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {!!couponDiscount?.amount && couponDiscount.amount > 0 && (
        <DataList.Row id="couponDiscount">
          <DataList.Cell width={70}>
            {couponDiscountPOS ? (
              <Text>
                {t('orderSummaryCard.tableAmounts.posCouponDiscount')}
              </Text>
            ) : (
              <Text>{`${t('orderSummaryCard.tableAmounts.couponDiscount', {
                coupons: couponDiscountDraft
                  ? t('orderSummaryCard.tableAmounts.couponDiscountDraft')
                  : couponDiscount.codes?.join(' / '),
              })}`}</Text>
            )}
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={couponDiscount.amount}
              currency={currency}
              block
              textAlign="right"
              preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
              appearance="danger"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {otherDiscounts &&
        otherDiscounts.length > 0 &&
        otherDiscounts.map((discount) => (
          <DataList.Row key={discount.name} id={discount.name}>
            <DataList.Cell width={70}>
              <Text>
                {discount.isCustom
                  ? discount.name
                  : t('orderSummaryCard.tableAmounts.promotionDiscount', {
                      promotionName: discount.name,
                    })}
              </Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={discount.amount}
                currency={currency}
                block
                textAlign="right"
                preFix={`${t('orderSummaryCard.tableAmounts.symbolDiscount')}`}
                appearance="danger"
              />
            </DataList.Cell>
          </DataList.Row>
        ))}

      <DataList.Row id="total" grayed>
        <DataList.Cell width={70}>
          <Text bold>{`${t('orderSummaryCard.tableAmounts.total')}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={total}
            currency={currency}
            block
            textAlign="right"
            bold
          />
        </DataList.Cell>
      </DataList.Row>

      <FeatureFlag flag={TOTAL_PAID_ORDER_DETAIL}>
        {!!totalPaid && !!otherCosts && (
          <>
            <DataList.Row id="otherCosts">
              <DataList.Cell width={70}>
                <Text>{`${t(
                  'orderSummaryCard.tableAmounts.otherCosts',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice price={otherCosts} block textAlign="right" />
              </DataList.Cell>
            </DataList.Row>

            <DataList.Row id="totalPaid" grayed>
              <DataList.Cell width={70}>
                <Text bold>{`${t(
                  'orderSummaryCard.tableAmounts.totalPaid',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice price={totalPaid} block textAlign="right" bold />
              </DataList.Cell>
            </DataList.Row>
          </>
        )}
      </FeatureFlag>

      {!!shippingMethodWeight && (
        <DataList.Row id="weight">
          <Box
            gap="none"
            display="flex"
            paddingLeft="2"
            flexDirection="column"
            paddingTop="1"
            paddingBottom="2"
          >
            {!!shippingMethod && <Text bold>{shippingMethod}</Text>}
            {hasWeight && (
              <Text>
                {`${t('orderSummaryCard.tableAmounts.weightTotal', {
                  weight,
                })}`}
              </Text>
            )}
          </Box>
        </DataList.Row>
      )}
    </>
  );
}
export default OrderSummaryAmounts;
