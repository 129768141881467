import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import {
  useBulkAction,
  InterfaceUseBulkActionResult,
} from '@tiendanube/components';
import { bulkactions } from './types';
import { useBulkActions } from '../../components/BulkActionsProvider';

interface useBulkActionMessagesParams {
  rowsId: string[];
}

function useBulkActionMessages({
  rowsId,
}: useBulkActionMessagesParams): InterfaceUseBulkActionResult {
  const { t } = useTranslation([DomainEnum.CUSTOMERS]);

  const { selectedRowsId, setSelectedRowsId, setSelectedAction, openModal } =
    useBulkActions();

  const count = selectedRowsId.length;

  const options = bulkactions.map((action) => {
    const labelCount = action.showCounts ? `(${count})` : '';
    return {
      label: `${t(
        `messages.bulkActions.options.${action.value}`,
      )} ${labelCount}`,
      value: action.value,
    };
  });

  const placeholder = t(`messages.bulkActions.options.choose`);

  const getLabel = (n: number) =>
    t(`messages.bulkActions.selected`, { count: n });

  const onSelectAction = (value) => {
    setSelectedAction(value);
    openModal();
  };

  const { bulkAction, handleOnSelectRow } = useBulkAction({
    rowsId,
    selectedRowsId,
    setSelectedRowsId,
    placeholder,
    getLabel,
    options,
    onSelectAction,
  });

  return { bulkAction, handleOnSelectRow };
}

export default useBulkActionMessages;
