import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@tiendanube/components';
import { EllipsisIcon } from '@tiendanube/icons';
import LinkPopover from 'commons/components/LinkPopover';
import { ActionProp } from '../../../types';

function ActionPopover({ actions }: { actions: ActionProp[] }): JSX.Element {
  const { t } = useTranslation('common');
  const labelMoreOptions = t('moreOptions');
  return (
    <Popover
      name="moreOptions"
      renderInitiator={(onClick) => (
        <Button onClick={onClick} icon={EllipsisIcon}>
          {labelMoreOptions}
        </Button>
      )}
    >
      {actions.map((action) => (
        <LinkPopover
          key={action.children}
          icon={action.icon}
          disabled={action.disabled}
          onClick={action.onClick}
          spinner={action.spinner}
          iconPosition={action.iconPosition}
          appearance={action.appearance}
          pro={action.pro}
        >
          {action.children}
        </LinkPopover>
      ))}
    </Popover>
  );
}

export default ActionPopover;
