import { ImageGalleryState } from '../../../ImageGallery';

interface UsePreviewImagesResult {
  previewImages: ImageGalleryState[];
  morePhotosNumber: number;
  isEmpty: boolean;
  isShowAddImage: boolean;
  isShowMoreImages: boolean;
  maxPreviewImages: number;
}

interface UsePreviewImagesProps {
  numberOfImagesToPreview: number;
  images: ImageGalleryState[];
}

function usePreviewImages({
  numberOfImagesToPreview,
  images,
}: UsePreviewImagesProps): UsePreviewImagesResult {
  const totalImages = images.length;
  const maxPreviewImages =
    totalImages > numberOfImagesToPreview
      ? numberOfImagesToPreview - 1
      : numberOfImagesToPreview;
  const previewImages = images.slice(0, maxPreviewImages);
  const morePhotosNumber = totalImages - (maxPreviewImages + 1);

  const isEmpty = totalImages === 0;
  const isShowAddImage = totalImages < maxPreviewImages && totalImages !== 0;
  const isShowMoreImages = totalImages > maxPreviewImages;

  return {
    previewImages,
    morePhotosNumber,
    isEmpty,
    isShowAddImage,
    isShowMoreImages,
    maxPreviewImages,
  };
}

export default usePreviewImages;
