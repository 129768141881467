import { useState } from 'react';
import {
  IconButton,
  Table,
  Text,
  Link,
  Tag,
  Tooltip,
} from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { Link as LinkRoute, generatePath } from 'react-router-dom';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import {
  CustomHeaderCell,
  ModalConfirmationAction,
  PaginationStratus,
} from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { CONTENT_PAGES_ROUTES } from 'domains/Online/ContentPages/contentPageRoutes';
import usePagesList from 'domains/Online/ContentPages/hooks/usePagesList';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

function PagesListResultDesktop() {
  const language = useGetLanguage();
  const t = useTranslationOnline('contentPages.pageList');
  const { pages, pagination, deleteStatus, goToPage, deleteContentPage } =
    usePagesList();
  const [idDelete, setIdDelete] = useState('');
  const [showRemoveModal, openRemoveModal, closeRemoveModal] = useModal();
  const { scrollToTop } = useLayoutScroll();

  const handleBackgroundColor = (line: number) =>
    line % 2 === 0 ? 'neutral-background' : 'neutral-surface';

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  const hadleDelete = () => deleteContentPage(idDelete);

  return (
    <>
      <Table>
        <Table.Head>
          <CustomHeaderCell width="60%">{t('headers.page')}</CustomHeaderCell>
          <CustomHeaderCell width="30%">
            {t('headers.lastUpdate')}
          </CustomHeaderCell>
          <CustomHeaderCell width="10%">
            {t('headers.actions')}
          </CustomHeaderCell>
        </Table.Head>
        <Table.Body>
          {pages?.map(({ id, title, lastUpdate, showInStore }, index) => {
            const goToPage = generatePath(CONTENT_PAGES_ROUTES.pageEditing, {
              id: String(id),
            });
            const handleClick = () => {
              setIdDelete(String(id));
              openRemoveModal();
            };

            return (
              <Table.Row
                key={id}
                backgroundColor={handleBackgroundColor(index)}
              >
                <Table.Cell width="60%" padding="base">
                  <Link
                    as={LinkRoute}
                    to={goToPage}
                    appearance="primary"
                    textDecoration="none"
                  >
                    {title[language]}
                    {!showInStore && (
                      <Tag appearance="warning">{t('draft')}</Tag>
                    )}
                  </Link>
                </Table.Cell>
                <Table.Cell width="30%" padding="base">
                  <Text>{lastUpdate}</Text>
                </Table.Cell>
                <Table.Cell width="10%" padding="small">
                  <Tooltip position="top" content={t('removeAction.btnOk')}>
                    <IconButton
                      onClick={handleClick}
                      source={<TrashIcon />}
                      size="2rem"
                      disabled={deleteStatus === 'loading'}
                    />
                  </Tooltip>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {pagination && (
        <PaginationStratus
          currentPage={pagination.currentPage}
          totalItems={pagination.totalResults}
          itemName={t('footer.item')}
          page={pagination.currentPage}
          perPage={pagination.perPage}
          onPageSelect={handleSelectPage}
        />
      )}
      {showRemoveModal && (
        <ModalConfirmationAction
          title={t('removeAction.title')}
          body={t('removeAction.body')}
          btnOk={t('removeAction.btnOk')}
          btnCancel={t('removeAction.btnCancel')}
          textProgress={t('removeAction.textProgress')}
          textSuccess={t('removeAction.textSuccess')}
          textError={t('removeAction.textError')}
          appearance="danger"
          onAction={hadleDelete}
          onClose={closeRemoveModal}
        />
      )}
    </>
  );
}

export default PagesListResultDesktop;
