import { Grid, Text } from '@tiendanube/components';

import '../ComparisonTableMobileRow.scss';

function ComparisonTableMobileRowSkeleton(): JSX.Element {
  return (
    <div className="stratus--comparison__plan__row">
      <Grid.Row>
        <Grid.Column sm={3} md={3} lg={3}>
          <Text.Skeleton />
        </Grid.Column>
        <Grid.Column sm={3} md={3} lg={3}>
          <div className="stratus--comparison__plan__row__options">
            <Grid>
              <Grid.Row>
                <Grid.Column sm={3} md={3} lg={3}>
                  <Text.Skeleton />
                </Grid.Column>
                <Grid.Column sm={3} md={3} lg={3}>
                  <Text.Skeleton />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </div>
  );
}

export default ComparisonTableMobileRowSkeleton;
