/**
 * Takes an array and returns a new array with its elements randomized.
 * This function does not mutate the original array.
 *
 * @param array - The original array to randomize.
 * @returns A new array with randomized order of the original elements.
 */
export function randomizeArrayOrder<T>(array: T[]): T[] {
  return [...array].sort(() => Math.random() - 0.5);
}
