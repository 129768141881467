import { useState } from 'react';
import { Box, Button, Card, Spinner, Title } from '@nimbus-ds/components';
import { CreditCardIcon, MoneyIcon, ShoppingCartIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { useToastStatus } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { trackingBillingPayOrderCheckoutContinueClick } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PaymentOption } from './components';
import { usePaymentSelectionForPayOrder } from './hooks';
import PaymentSelectionForPayOrderSkeleton from './Skeleton';

function PaymentSelectionForPayOrder() {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPayOrder.paymentSelection',
  );

  const {
    payWithCreditCard,
    payWithMercadoPago,
    payWithWireTransfer,
    isLoading,
    preferenceStatus,
    preferenceLoading,
    onFinish,
  } = usePaymentSelectionForPayOrder();

  useToastStatus({
    status: preferenceStatus,
    error: t('error'),
    onSuccess: onFinish,
    onError: onFinish,
  });

  const country = useGetCountry();

  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState<CheckoutPaymentMethod>('cc');

  const onContinue = () => {
    trackingBillingPayOrderCheckoutContinueClick(paymentMethodSelected);
    switch (paymentMethodSelected) {
      case 'cc':
        payWithCreditCard();
        break;
      case 'transfer':
        payWithWireTransfer();
        break;
      case 'ticket':
        payWithMercadoPago();
        break;
    }
  };

  if (isLoading) return <PaymentSelectionForPayOrderSkeleton />;

  return (
    <Card padding="none">
      <Box padding="4" paddingBottom="none">
        <Title as="h3">{t('title')}</Title>
      </Box>
      <DataList>
        <DataList.Row key="cc">
          <PaymentOption
            method="cc"
            paymentMethodSelected={paymentMethodSelected}
            setPaymentMethodSelected={setPaymentMethodSelected}
            icon={CreditCardIcon}
            isLoading={preferenceLoading}
          />
        </DataList.Row>
        {country === 'AR' && (
          <DataList.Row key="transfer">
            <PaymentOption
              method="transfer"
              paymentMethodSelected={paymentMethodSelected}
              setPaymentMethodSelected={setPaymentMethodSelected}
              icon={MoneyIcon}
              isLoading={preferenceLoading}
            />
          </DataList.Row>
        )}
        <DataList.Row key="ticket">
          <PaymentOption
            method="ticket"
            paymentMethodSelected={paymentMethodSelected}
            setPaymentMethodSelected={setPaymentMethodSelected}
            icon={ShoppingCartIcon}
            isLoading={preferenceLoading}
          />
        </DataList.Row>
      </DataList>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        paddingLeft="3"
        paddingBottom="3"
      >
        <Button
          appearance="primary"
          onClick={onContinue}
          disabled={preferenceLoading}
        >
          {t('continue')}
          {preferenceLoading && <Spinner size="small" />}
        </Button>
      </Box>
    </Card>
  );
}

export default PaymentSelectionForPayOrder;
