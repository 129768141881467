import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

const { Header, Body, Footer } = Modal;

interface DeleteFreeShippingModal {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export function DeleteFreeShippingModal({
  show,
  onClose,
  onDelete,
}: Readonly<DeleteFreeShippingModal>) {
  const t = useTranslationMarketing('freeShipping.deleteModal');
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal open={show} onDismiss={onClose}>
      <Header title={t('title')} />
      <Body padding="none">
        <Text>{t('body')}</Text>
      </Body>
      <Footer>
        <CancelAndConfirmButtons
          onCancel={onClose}
          onConfirm={handleDelete}
          cancelText={t('cancel')}
          confirmText={t('confirm')}
          appearance="danger"
        />
      </Footer>
    </Modal>
  );
}
