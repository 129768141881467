import {
  CancelAndSaveButtons,
  CancelAndSaveButtonsProps,
} from 'commons/components';
import { useAuthenticationFactorAuthorization } from 'domains/AuthenticationFactor/hooks';
import { AuthenticationFactorVerifyCodeModal } from '../AuthenticationFactorVerifyCodeModal';

interface CancelAndSaveButtonsWithAuthProps extends CancelAndSaveButtonsProps {
  typeModal: 'aside' | 'centered';
  skipAuthorization?: boolean;
  preSubmitValidation?: (onValid: () => void) => Promise<void>;
  payload?: object;
}

function CancelAndSaveButtonsWithAuth({
  typeModal,
  skipAuthorization = false,
  isLoading,
  payload = {},
  onSave,
  preSubmitValidation,
  ...props
}: CancelAndSaveButtonsWithAuthProps) {
  const {
    isLoading: isLoadingAuthFactor,
    showAuthenticationFactorModal,
    handleCancel,
    handleSave,
    handleIsValidCode,
  } = useAuthenticationFactorAuthorization({
    onSave,
    skipAuthorization,
    preSubmitValidation,
  });

  return (
    <>
      <CancelAndSaveButtons
        onSave={handleSave}
        isLoading={isLoading || isLoadingAuthFactor}
        {...props}
      />
      <AuthenticationFactorVerifyCodeModal
        isOpen={showAuthenticationFactorModal}
        type={typeModal}
        onCancel={handleCancel}
        onIsValidCode={handleIsValidCode}
        payload={payload}
      />
    </>
  );
}

export default CancelAndSaveButtonsWithAuth;
