import { useCallback, useState } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside';
import { toStatusType } from 'App/rtk';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { useTranslationOnline } from 'domains/Online';
import { useCreateMenuMutation } from 'domains/Online/Menus/MenusApi';
import { useAddMenuHandlers } from './hooks';
import MenuItemDataList from '../MenuItemDataList';
import MenuItemModal from '../MenuItemModal';

interface AddMenuModalProps {
  show: boolean;
  onClose: () => void;
}

function AddMenuModal({ show, onClose }: AddMenuModalProps) {
  const t = useTranslationOnline('menus.addMenuModal');
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();
  const [createMenu, { status, isLoading }] = useCreateMenuMutation();

  const {
    name,
    items,
    handleChange,
    handleAddMenuItem,
    handleDeleteMenuItem,
    handleDropMenuItem,
    handleEditMenuItem,
    cleanValues,
  } = useAddMenuHandlers();
  const [showMenuItemModal, openMenuItemModal, closeMenuItemModal] = useModal();

  const handleClose = useCallback(() => {
    cleanValues();
    onClose();
  }, [cleanValues, onClose]);

  const handleOpenEditMenuItemModal = (itemId: string) => {
    setSelectedItemId(itemId);
    openMenuItemModal();
  };

  const handleOpenAddMenuItemModal = () => {
    setSelectedItemId('');
    openMenuItemModal();
  };

  const handleSave = () => {
    createMenu({
      name,
      items,
    });
  };

  useToastStatus({
    error: t('createToast.error'),
    success: t('createToast.success'),
    status: toStatusType(status),
    onSuccess: handleClose,
  });

  const isNameEmpty = name.trim() === '';

  const selectedItem = selectedItemId
    ? items.find((item) => item.id === selectedItemId)
    : undefined;

  return (
    <>
      <ModalAside
        isOpen={show}
        onDidDismiss={handleClose}
        headerTop={<HeaderTop navigation={{ onClick: handleClose }} />}
        headerContent={<HeaderContent title={t('title')} />}
      >
        <FormField.Input
          id="name"
          label={t('inputName')}
          helpText={t('inputNameHelp')}
          onChange={handleChange}
          defaultValue={name}
          autoFocus
          showHelpText
        />
        <MenuItemDataList
          items={items}
          addItemLeftPadding="none"
          onOpenMenuItemModal={handleOpenAddMenuItemModal}
          onDeleteMenuItem={handleDeleteMenuItem}
          onDropMenuItem={handleDropMenuItem}
          onEditMenuItem={handleOpenEditMenuItemModal}
        />
        <CancelAndSaveButtons
          onCancel={handleClose}
          onSave={handleSave}
          saveText={isLoading ? t('creating') : t('create')}
          isDisabled={isNameEmpty || isLoading}
          isLoading={isLoading}
        />
      </ModalAside>
      <MenuItemModal
        item={selectedItem}
        show={showMenuItemModal}
        onAdd={handleAddMenuItem}
        onClose={closeMenuItemModal}
        onEdit={handleEditMenuItem}
      />
    </>
  );
}

export default AddMenuModal;
