import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  PayPalPaymentProviderDto,
  UpdatePayPalSettingsRequestDto,
} from '@tiendanube/common';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import {
  BaseCard,
  Title,
  Input,
  ErrorState,
  Label,
  Stack,
} from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { IonPageStratus, HeaderTop, HeaderContent } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import { useNativePaymentProviderSettings } from 'domains/Payments/PaymentProviders/hooks';
import { ShowInfoFinantialStore } from './components';
import { validationSchema } from './validationSchema';
import { AlertSessionStorage } from '../../../../../App/components';
import useTranslationPayments from '../../../useTranslationPayments';
import PaymentProviderSettingsSkeleton from '../PaymentProviderSettingsPage/components/PaymentProviderSettingsSkeleton';

const emptySettingValues = {
  email: '',
  showInfoOnStore: false,
  maxInstallments: 1,
  installmentsWithoutInterest: 1,
  minInstallmentsCurrencyCode: 'USD',
  minInstallmentsValue: '',
  minInstallmentsValueAllowed: 1,
};

const buildPayload = (values: UpdatePayPalSettingsRequestDto) => ({
  ...values,
  appId: NativePaymentProviders.paypal,
});

function PayPalSettingsPage(): JSX.Element {
  const t = useTranslationPayments('paymentProviders.payPalSettingsPage');
  const { t: globalTranslation } = useTranslation();
  const errorStateTranslations = useTranslationPayments('paymentProvider');
  const { goBack } = useNavegate();
  const [successSubmit, setSuccessSubmit] = useState(false);
  const {
    isError,
    isLoading,
    updateStatus,
    paymentProvider,
    fetch,
    updatePaymentProviderSettings,
  } = useNativePaymentProviderSettings<PayPalPaymentProviderDto>(
    NativePaymentProviders.paypal,
  );

  const initialValues =
    paymentProvider && paymentProvider.settings
      ? paymentProvider.settings
      : emptySettingValues;

  const {
    errors,
    values,
    isDirty,
    setFieldValue,
    handleOnBlur,
    handleOnChange,
    handleOnSubmit,
    handleValidations,
  } = useForm<UpdatePayPalSettingsRequestDto, UpdatePayPalSettingsRequestDto>({
    initialValues,
    validationSchema: validationSchema(),
    onSubmit: async (values) => {
      updatePaymentProviderSettings(buildPayload(values));
      setSuccessSubmit(true);
    },
  });

  const handleToogle = () =>
    setFieldValue('showInfoOnStore', !values.showInfoOnStore);

  useToastStatus({
    error: t('submit.error'),
    success: t('submit.success'),
    status: updateStatus,
    onSuccess: goBack,
  });

  useEffect(() => {
    if (
      Number(values.maxInstallments) <
      Number(values.installmentsWithoutInterest)
    ) {
      setFieldValue('installmentsWithoutInterest', values.maxInstallments);
    }
  }, [
    setFieldValue,
    values.installmentsWithoutInterest,
    values.maxInstallments,
  ]);

  useEffect(() => {
    if (!values.showInfoOnStore) {
      setFieldValue('maxInstallments', 1);
      setFieldValue('installmentsWithoutInterest', 1);
      setFieldValue('minInstallmentsValue', '');
    }
  }, [setFieldValue, values.showInfoOnStore]);

  const isSaving = updateStatus === 'loading';

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={`${t('title')}`} />}
    >
      <Prompt
        when={isDirty && !successSubmit}
        message={globalTranslation('common:exitEdit.info')}
      />
      {isError && (
        <ErrorState
          title={errorStateTranslations('settings.error.title')}
          action={{
            children: errorStateTranslations('settings.error.action'),
            onClick: fetch,
          }}
        />
      )}
      {isLoading && <PaymentProviderSettingsSkeleton />}
      {!isLoading && !isError && (
        <>
          <Stack column align="flex-start">
            <Label
              id="PayPalSettingsPageLabel"
              appearance="primary"
              label={`${t('label')}`}
            />
            <AlertSessionStorage
              appearance="primary"
              keyName="PayPalSettingsPage"
            >
              {t('banner')}
            </AlertSessionStorage>
          </Stack>
          <BaseCard>
            <BaseCard.Header>
              <Title type="h3">{`${t('account.title')}`}</Title>
            </BaseCard.Header>
            <BaseCard.Body>
              <Stack column justify="space-between" align="stretch">
                <Input
                  type="text"
                  name="email"
                  label={t(`account.email.label`)}
                  value={values.email}
                  appearance={
                    errors.email || isError ? 'validation_error' : undefined
                  }
                  helpText={errors.email ? t(errors.email) : ''}
                  onBlur={handleOnBlur}
                  onChange={handleOnChange}
                />
              </Stack>
            </BaseCard.Body>
          </BaseCard>
          <ShowInfoFinantialStore
            values={values}
            errors={errors}
            onChange={handleOnChange}
            onToogle={handleToogle}
          />
          <CancelAndSaveButtonsWithAuth
            typeModal="centered"
            payload={buildPayload(values)}
            onCancel={goBack}
            onSave={handleOnSubmit}
            preSubmitValidation={handleValidations}
            isLoading={isSaving}
          />
        </>
      )}
      {!!paymentProvider?.helpSiteUrl && (
        <HelpLink
          previousValue=""
          title={errorStateTranslations('helpLink.title', {
            paymentProvider: paymentProvider.name,
          })}
          currentViewTracking={errorStateTranslations('helpLink.amplitudeName')}
          linkURL={paymentProvider?.helpSiteUrl}
          icon="both"
          appearance="neutral"
          showInMobile
        />
      )}
    </IonPageStratus>
  );
}

export default PayPalSettingsPage;
