import { useEffect } from 'react';
import { Button, Modal, Text } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { toCamelCase } from 'commons/utils';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { useRecoveryCodesAlertToShow } from 'domains/Account/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingRecoveryCodesUpdateAfterLogin } from 'domains/Dashboard/tracking';

const QUERY_PARAM = 'login_with_recovery_code';

function RecoveryCodesAlert() {
  const t = useTranslationDashboard('recoveryCodesAlert');
  const { goTo, removeSearchParams, hasSearchParam } = useNavegate();
  const { fetch, reset, recoveryCodesAlertToShow } =
    useRecoveryCodesAlertToShow();

  useEffect(() => {
    if (hasSearchParam(QUERY_PARAM)) {
      removeSearchParams([QUERY_PARAM]);
      fetch();
    }
  }, [fetch, hasSearchParam, removeSearchParams]);

  if (recoveryCodesAlertToShow === null) {
    return null;
  }

  const handleClick = () => {
    trackingRecoveryCodesUpdateAfterLogin();
    reset();
    goTo(ACCOUNT_ROUTES.authenticationFactor);
  };

  return (
    <Modal open onDismiss={reset}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Text>{t(`${toCamelCase(recoveryCodesAlertToShow)}Text`)}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={handleClick}>
          {t('cta')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RecoveryCodesAlert;
