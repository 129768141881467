import { Text } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';

interface DateDayMouthHourMinuteTextProps {
  date: string;
}

function DateDayMouthHourMinuteText({
  date,
}: DateDayMouthHourMinuteTextProps): JSX.Element {
  return (
    <Text size="caption">
      {dateFormat(date, Format.DD_MM_YY)}
      {' - '}
      {dateFormat(date, Format.HOUR_MINUTE_XS)}
    </Text>
  );
}

export default DateDayMouthHourMinuteText;
