import { Alert, Text } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { useDismissibleComponent } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { GoToBillingPlansLink } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { showFreeTrialAlert } from './utils';

interface TrialExpirationAlertProps {
  mobileSuffix?: string;
  expiresIn?: number;
}

export function TrialExpirationAlert({
  mobileSuffix,
  expiresIn,
}: Readonly<TrialExpirationAlertProps>) {
  const t = useTranslationDashboard('trialMessages');
  const { shouldShow, dismiss } = useDismissibleComponent(
    'trial-expiration-alert-alert',
    1,
  );

  const storeCountry = useGetCountry();
  // Do not show ending alert if is BR, AR or MX, because expiresIn days are showing at topbar.
  if ([Country.BR, Country.AR, Country.MX].includes(storeCountry)) return null;

  return (
    <Alert
      show={shouldShow && showFreeTrialAlert(expiresIn)}
      appearance="warning"
      title={t('isEnding.title')}
      onRemove={dismiss}
    >
      <Text>{t(`${'isEnding.description'}${mobileSuffix}`)}</Text>
      <GoToBillingPlansLink>{t('cta')}</GoToBillingPlansLink>
    </Alert>
  );
}
