import { Tag, Text } from '@nimbus-ds/components';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';

interface RecurringAppStatusTagProps {
  app: AppsListItemResponseDto;
}

export function RecurringAppStatusTag({ app }: RecurringAppStatusTagProps) {
  const { t } = useTranslationPartnerApps('appsListPage.status');

  if (app.disabledInStore) {
    if (app.wasPaidForBefore)
      return (
        <Tag appearance="neutral">
          <Text fontSize="caption" lineClamp={1}>
            {t('disabledForPayment')}
          </Text>
        </Tag>
      );
    return <Tag appearance="neutral">{t('trialEnded')}</Tag>;
  }

  if (app.daysRemaining < 0)
    return <Tag appearance="warning">{t('pendingPayment')}</Tag>;
  return (
    <Tag appearance="success">
      {t('remainingDays', { count: app.daysRemaining })}
    </Tag>
  );
}
