import { Accordion, Icon, Link, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CopyLinkBox } from '../CopyLinkBox';

interface CNAMERecordVerificationProps {
  currentCNAMERecord: string;
  unexpectedCNAMERecord: string;
  ourDomain: string;
  domainName: string;
}

export function CNAMERecordVerification({
  currentCNAMERecord,
  unexpectedCNAMERecord,
  ourDomain,
  domainName,
}: Readonly<CNAMERecordVerificationProps>) {
  const t = useTranslationConfigurations('domains.attachModal');

  const showUnexpectedCnameError =
    !!unexpectedCNAMERecord &&
    (!currentCNAMERecord || currentCNAMERecord === ourDomain);

  return (
    <Accordion.Item index="1">
      <Accordion.Header
        title={t('verificationModal.cname.title')}
        icon={<Icon source={<InfoCircleIcon size="medium" />} />}
      />
      <Accordion.Body>
        <Stack column align="stretch" spacing="tight">
          {!currentCNAMERecord && !unexpectedCNAMERecord && (
            <Stack column align="stretch" spacing="tight">
              <Text color="danger-textLow" fontWeight="medium">
                {t('verificationModal.cname.errorEmpty')}
              </Text>
              <Text>
                <Trans
                  t={t}
                  i18nKey="step2.cname.copyText"
                  components={{
                    helpLink: (
                      <Link
                        as="a"
                        appearance="primary"
                        href={t('step2.cname.helpLink')}
                        target="_blank"
                        textDecoration="none"
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
              <CopyLinkBox
                value={ourDomain}
                message={t('step2.cname.copySuccess')}
                text={ourDomain}
              />
            </Stack>
          )}
          {!!currentCNAMERecord && currentCNAMERecord !== ourDomain && (
            <Stack column align="stretch" spacing="tight">
              <Text color="danger-textLow" fontWeight="medium">
                {t('verificationModal.cname.error')}
              </Text>
              <Text>
                {t('verificationModal.cname.replace')}
                <br />
                <s>{currentCNAMERecord}</s>
              </Text>
              <Stack column align="stretch" spacing="none">
                <Text color="neutral-textHigh">
                  {t('verificationModal.cname.replaceWith')}
                </Text>
                <CopyLinkBox
                  value={ourDomain}
                  message={t('copySuccess')}
                  text={ourDomain}
                />
              </Stack>
              <Text>
                <Trans
                  t={t}
                  i18nKey="verificationModal.cname.help"
                  components={{
                    helpLink: (
                      <Link
                        as="a"
                        appearance="primary"
                        href={t('verification.helpLink.href')}
                        target="_blank"
                        textDecoration="none"
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Stack>
          )}
          {showUnexpectedCnameError && (
            <Stack column align="stretch" spacing="tight">
              <Text color="danger-textLow" fontWeight="medium">
                {t('verificationModal.cname.incorrectTitle')}
              </Text>
              {currentCNAMERecord ? (
                <Text>
                  <Trans
                    t={t}
                    i18nKey="verificationModal.cname.deleteAndKeep"
                    values={{
                      domainWithoutWww: domainName.replace('www.', ''),
                      domainWithWww: domainName,
                    }}
                    components={{
                      bold: <strong />,
                    }}
                  />
                </Text>
              ) : (
                <Text>
                  <Trans
                    t={t}
                    i18nKey="verificationModal.cname.deleteAndAdd"
                    values={{
                      domainWithoutWww: domainName.replace('www.', ''),
                      domainWithWww: domainName,
                    }}
                    components={{
                      bold: <strong />,
                    }}
                  />
                </Text>
              )}
              <CopyLinkBox
                value={ourDomain}
                message={t('step2.cname.copySuccess')}
                text={ourDomain}
              />
              <Text>
                <Trans
                  t={t}
                  i18nKey="verificationModal.cname.help"
                  components={{
                    helpLink: (
                      <Link
                        as="a"
                        appearance="primary"
                        href={t('step2.cname.helpLink')}
                        target="_blank"
                        textDecoration="none"
                      >
                        {' '}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </Stack>
          )}
        </Stack>
      </Accordion.Body>
    </Accordion.Item>
  );
}
