import { useTranslation } from 'react-i18next';
import {
  CustomersOrdersResponseDto,
  OrderResponseDto,
} from '@tiendanube/common';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';

interface OriginLabelProps {
  order: OrderResponseDto | CustomersOrdersResponseDto;
}

function OriginLabel({ order }: OriginLabelProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  return (
    <Label
      id={`${order.number}`}
      label={`${t(`origin.${order.origin}`, order.origin)}`}
    />
  );
}

export default OriginLabel;
