import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Thumbnail,
  BaseCard,
  Stack,
  Text,
  Label,
  Spinner,
} from '@tiendanube/components';
import {
  FileIcon,
  TrashIcon,
  ExclamationTriangleIcon,
  RedoIcon,
} from '@tiendanube/icons';
import './InputFile.scss';
import { ActionIconButton } from '../ActionIconButton';

interface InputFileProps {
  fileName?: string;
  isError?: boolean;
  isErrorRetry?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onChangeFile?: (file: File) => void;
  onRemoveFile?: () => void;
  onRetry?: () => void;
  accept?: string;
  helperText?: string;
  errorText?: string;
  fullWidth?: boolean;
}

function InputFile({
  fileName,
  isError = false,
  isErrorRetry = false,
  isLoading = false,
  disabled,
  onChangeFile,
  onRemoveFile,
  onRetry,
  accept,
  helperText,
  errorText,
  fullWidth = false,
}: InputFileProps): JSX.Element {
  const { t } = useTranslation('common');
  const Icon = isError || isErrorRetry ? ExclamationTriangleIcon : FileIcon;
  const appearanceIcon = isError || isErrorRetry ? 'danger' : 'primary';
  const textFile = isLoading ? t('inputFile.loading') : fileName;

  const handleOnChangeFile = (file: File[]) => {
    onChangeFile && onChangeFile(file[0]);
  };

  const isShowRemoveButton = !isLoading && !isErrorRetry;

  return (
    <div
      className={classNames('nimbus--stack-item', 'stratus--input-file', {
        'stratus--half-width': !fullWidth,
      })}
    >
      {!fileName && !isError && !isLoading ? (
        <Thumbnail.File
          ariaLabel=""
          onChange={handleOnChangeFile}
          accept={accept}
          disabled={disabled}
          helperText={helperText}
          multiple={false}
        />
      ) : (
        <Stack spacing="tight" column align="stretch">
          <BaseCard>
            <BaseCard.Body padding="base">
              <Stack justify="space-between">
                <Stack.Item fill>
                  {isLoading ? (
                    <span className="nimbus--label nimbus--label--primary">
                      <Spinner />
                    </span>
                  ) : (
                    <Label
                      label=""
                      icon={Icon}
                      id="stratus--document-icon"
                      appearance={appearanceIcon}
                    />
                  )}
                  <Text bold>{textFile}</Text>
                </Stack.Item>
                <Stack.Item>
                  {isErrorRetry && (
                    <ActionIconButton
                      content={t('inputFile.retry')}
                      source={<RedoIcon />}
                      onClick={onRetry}
                    />
                  )}
                  {isShowRemoveButton && (
                    <ActionIconButton
                      content={t('inputFile.remove')}
                      source={<TrashIcon />}
                      onClick={onRemoveFile}
                    />
                  )}
                </Stack.Item>
              </Stack>
            </BaseCard.Body>
          </BaseCard>
          {isError && !isLoading && (
            <Text size="caption" appearance="danger">
              {errorText}
            </Text>
          )}
        </Stack>
      )}
    </div>
  );
}

export default InputFile;
