import { Box, Text } from '@nimbus-ds/components';

function WireDataSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box display="flex" flexDirection="column" gap="1-5">
        <Text.Skeleton width="20%" height="20px" />
        <Text.Skeleton width="35%" height="20px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="1">
        <Text.Skeleton width="70%" />
        <Text.Skeleton width="40%" />
        <Text.Skeleton width="60%" />
        <Text.Skeleton width="30%" />
        <Text.Skeleton width="50%" />
      </Box>
    </Box>
  );
}

export default WireDataSkeleton;
