import { memo, useContext, useEffect } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import { HeaderContent, IonPageStratus, Responsive } from 'commons/components';
import Stack from 'commons/components/Stack';
import { HELP_LINKS_INVENTORY_LIST } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { StockHistoryModal } from 'domains/Catalog/Products/pages/components';
import { EditStockModal } from 'domains/Catalog/Products/pages/components/Stock';
import { useStockHistoryContext } from 'domains/Catalog/Products/pages/components/StockHistoryModal/hooks';
import { getLocationName } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { EditStockContext } from './components/EditStockProvider';
import { InventoryEmpty } from './components/InventoryEmpty';
import { InventoryResultsDesktop } from './components/InventoryResultsDesktop';
import { InventoryResultsMobile } from './components/InventoryResultsMobile';
import { InventorySearch } from './components/InventorySearch';
import LocationSelector from './components/LocationSelector';
import {
  InventoryFiltersInterface,
  StockChange,
} from '../../inventoryServices';
import useInventory from '../hooks/useInventory';

function InventoryListPage(): JSX.Element {
  const t = useTranslationCatalog();

  const language = useTranslationLanguage();

  const {
    isLoading: isLoadingInventory,
    isSuccess: isSuccessInventory,
    productIds,
    productCount,
    filters,
    hasFilters,
    setFilters,
    setLocation,
    loadInventory,
    setStock,
  } = useInventory();

  const {
    isLoading: isLoadingLocations,
    isSuccess: isSuccessLocations,
    locations,
    locationsMainId,
    fetchLocations,
  } = useLocations();

  const handleChangeFilters = (newFilters: InventoryFiltersInterface) =>
    setFilters(newFilters);

  const onLocationChange = (locationId: string) => setLocation(locationId);

  const searchFilterActions = useStatusFeatureByKey('search_filter_actions');
  const areFiltersAllowed = searchFilterActions.isAvailable;

  const { variantStock, isOpen, closeStockHistoryModal } =
    useStockHistoryContext();
  const { closeEditStock, variantForEditStock, isOpenEditStock } =
    useContext(EditStockContext);

  const handleEditStockClose = (stockChange?: StockChange) => {
    if (stockChange) {
      setStock(stockChange);
    }
    closeEditStock();
  };

  const locationName =
    variantForEditStock &&
    getLocationName(locations, variantForEditStock.locationId);

  useEffect(() => {
    if (!locationsMainId) {
      fetchLocations();
    }
  }, [fetchLocations, locationsMainId]);

  useEffect(() => {
    if (isSuccessLocations && filters.locationId === undefined) {
      setLocation(locationsMainId ?? '');
    }
  }, [isSuccessLocations, locationsMainId, filters.locationId, setLocation]);

  useEffect(() => {
    loadInventory();
  }, [loadInventory]);

  if (isSuccessInventory && productIds.length === 0 && !hasFilters) {
    return (
      <IonPageStratus
        width="medium"
        headerContent={<HeaderContent title={t('inventory.title')} />}
      >
        <LocationSelector
          selectedLocationId={filters.locationId}
          onSelectionChange={onLocationChange}
        />
        <InventoryEmpty />
      </IonPageStratus>
    );
  }

  return (
    <IonPageStratus
      width="medium"
      headerContent={<HeaderContent title={t('inventory.title')} />}
    >
      <Stack column align="stretch" spacing="base">
        <LocationSelector
          selectedLocationId={filters.locationId}
          onSelectionChange={onLocationChange}
        />
        {areFiltersAllowed && (
          <InventorySearch
            filters={filters}
            productsCount={productCount}
            isLoading={isLoadingInventory || isLoadingLocations}
            onChange={handleChangeFilters}
          />
        )}
        <Responsive
          mobile={<InventoryResultsMobile />}
          desktop={<InventoryResultsDesktop />}
        />
        <ShowByCountry allowedCountries={[Country.BR]}>
          <HelpLink
            title={t('helpLink.aboutInventory')}
            previousValue=""
            currentViewTracking={t('helpLink.aboutInventoryTrackingName')}
            linkURL={HELP_LINKS_INVENTORY_LIST[language]}
            icon="both"
          />
        </ShowByCountry>
      </Stack>
      {variantStock && (
        <StockHistoryModal
          productId={variantStock.productId}
          variantId={variantStock.variantId}
          variantName={variantStock.variantName}
          locationId={variantStock.locationId}
          onClose={closeStockHistoryModal}
          isOpen={isOpen}
          hideLocationSelector
        />
      )}
      {variantForEditStock && (
        <EditStockModal
          isShow={isOpenEditStock}
          productId={variantForEditStock.productId}
          variantId={variantForEditStock.id}
          currentStock={variantForEditStock.stock}
          isVariantStock
          displayStockTypes
          displayApplyToAll={variantForEditStock.name !== ''}
          variantName={variantForEditStock.name}
          onClose={handleEditStockClose}
          locationId={variantForEditStock.locationId}
          locationName={locationName}
        />
      )}
    </IonPageStratus>
  );
}

export default memo(InventoryListPage);
