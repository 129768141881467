import * as Yup from 'yup';
import { MercadoPagoPaymentProviderDto } from '@tiendanube/common';

export const getValidationSchema = (
  mercadoPagoProvider: MercadoPagoPaymentProviderDto | undefined,
) => {
  if (!mercadoPagoProvider) return Yup.object().shape({});

  const discounts = mercadoPagoProvider?.discounts?.reduce(
    (values, { paymentMethodType }) => ({
      ...values,
      [paymentMethodType]: Yup.string().test(
        'discount-is-valid',
        function (value) {
          if (Number(value) < 0 || Number(value) > 100) {
            return this.createError({
              message: 'paymentProvider.settings.discountValueError',
              path: `discounts[${paymentMethodType}]`,
            });
          }

          return true;
        },
      ),
    }),
    {},
  );

  const minInstallmentValues =
    mercadoPagoProvider?.minInstallmentValues?.reduce(
      (values, { currency }) => ({
        ...values,
        [currency]: Yup.string().test('installment-is-valid', function (value) {
          if (value && Number(value) < 1) {
            return this.createError({
              message:
                'paymentProvider.settings.minDefaultInstallmentValueError',
              path: `installments[${currency}]`,
            });
          }

          return true;
        }),
      }),
      {},
    );

  return Yup.object().shape({
    paymentMethodTypes: Yup.array().when('integrationType', {
      is: (integrationType) => integrationType === 'transparent',
      then: Yup.array()
        .min(1, 'paymentProvider.settings.paymentMethodRequired')
        .required('required'),
      otherwise: Yup.array(),
    }),
    discounts: Yup.object().when('integrationType', {
      is: (integrationType) => integrationType === 'transparent',
      then: Yup.object().shape({ ...discounts }),
      otherwise: Yup.object(),
    }),
    minInstallmentValues: Yup.object().when(
      ['integrationType', 'paymentMethodTypes'],
      {
        is: (integrationType, paymentMethodTypes) =>
          integrationType === 'transparent' &&
          paymentMethodTypes?.includes('credit_card'),
        then: Yup.object().shape({
          ...minInstallmentValues,
        }),
        otherwise: Yup.object().shape({}),
      },
    ),
  });
};
