import { OrderForDocumentResponseDto } from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface ReceiptStubDocumentPreviewProps {
  order: OrderForDocumentResponseDto;
  fulfillmentOrderNumber: string;
}

function ReceiptStubDocumentPreview({
  order,
  fulfillmentOrderNumber,
}: Readonly<ReceiptStubDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="receipt-stub-section">
      <p>
        {t('order')} #{order.number} - {t('package')} #{fulfillmentOrderNumber}
      </p>
      <p className="bold-name"> {t('stub.detail')} </p>
      <div className="fields-row">
        <p>{t('stub.fullName')}</p>
        <span className="line" />
      </div>
      <div className="fields-row">
        <p>{t('stub.document')}</p>
        <span className="line" />
        <p>{t('stub.date')}</p>
        <span className="line" />
      </div>
      <div className="fields-row">
        <p className="bold">{t('stub.signature')}</p>
        <span className="line" />
      </div>
    </div>
  );
}

export default ReceiptStubDocumentPreview;
