import { AiFeature } from '@tiendanube/common/src/enums';
import { Stack } from '@tiendanube/components';
import { FEATURE_AI_TIER } from 'App/features';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useModal } from 'commons/hooks';
import { useAiUsage } from 'domains/Ai/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import FormSeo from './FormSeo';
import { FormSeoProps } from './FormSeo/FormSeo';
import TagAndSeoItemTitle from '../TagAndSeoItemTitle';

export type SeoProps = Omit<
  FormSeoProps,
  'showAiModal' | 'openAiModal' | 'closeAiModal' | 'showGenerateWithAi'
>;

function Seo(props: Readonly<SeoProps>): JSX.Element {
  const t = useTranslationCatalog();

  const [showAiModal, openAiModal, closeAiModal] = useModal();
  const { isLoadingAiUses, requiredTier, aiUsesUpsellTitle } = useAiUsage();

  const handleOpenSeoGenerationModal = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: AiFeature.AI_PRODUCTS_SEO,
    callback: openAiModal,
    minValue: requiredTier,
    asAside: true,
  });

  return (
    <TagAndSeoItemTitle
      title={t('products.detail.seo.title')}
      description={t('products.detail.seo.description')}
    >
      <Stack wrap spacing="tight" align="stretch">
        <Stack.Item fill>
          <TabsMultiLanguage>
            <FormSeo
              {...props}
              showAiModal={showAiModal}
              openAiModal={handleOpenSeoGenerationModal}
              closeAiModal={closeAiModal}
              showGenerateWithAi={!isLoadingAiUses}
            />
          </TabsMultiLanguage>
        </Stack.Item>
      </Stack>
    </TagAndSeoItemTitle>
  );
}

export default Seo;
