import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

export interface useModalConformationActionResult {
  title: string;
  body: string;
  btnOk: string;
  btnCancel: string;
  textProgress: string;
  textSuccess: string;
  textError: string;
}

function useModalConformationAction(
  domain: Domain,
  prefix: string,
  count = 1,
): useModalConformationActionResult {
  const { t } = useTranslation(domain);
  const texts = {
    title: t(`${prefix}.title`, { count }),
    body: t(`${prefix}.body`, { count }),
    btnOk: t(`${prefix}.ok`),
    btnCancel: t(`${prefix}.cancel`),
    textProgress: t(`${prefix}.progress`, { count }),
    textSuccess: t(`${prefix}.success`, { count }),
    textError: t(`${prefix}.error`, { count }),
  };
  return texts;
}

export default useModalConformationAction;
