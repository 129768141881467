import { Card, Checkbox, Title } from '@nimbus-ds/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductDetailVariantHideSelect } from 'domains/Catalog/Products/tracking';

interface VisibilityCardProps {
  show: boolean;
  onChange: ({ name, value }: InterfaceNameBooleanValue) => void;
}

function VisibilityCard({ show, onChange }: VisibilityCardProps) {
  const t = useTranslationCatalog();
  const sender = useTrackFullCatalog();

  const handleChangeShow = (event) => {
    sender(() => trackingProductDetailVariantHideSelect(!event.target.checked));
    onChange({ name: 'show', value: event.target.checked });
  };

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{t('products.detail.visibility')}</Title>
      </Card.Header>
      <Checkbox
        label={t('products.detail.showVariantInStore')}
        name="show"
        onChange={handleChangeShow}
        checked={show}
      />
    </Card>
  );
}

export default VisibilityCard;
