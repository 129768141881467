import { ChangeEventHandler } from 'react';
import { Box, Link, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { CardWithTitle } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface ClearSaleCardProps {
  clearSaleCode: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export function ClearSaleCard({
  clearSaleCode,
  onChange,
}: Readonly<ClearSaleCardProps>) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.clearSaleCard',
  );
  return (
    <CardWithTitle title={t('title')}>
      <Box display="flex" flexDirection="column" gap="4">
        <Text>
          <Trans
            t={t}
            i18nKey="description"
            components={{
              customLink: (
                <Link
                  as="a"
                  appearance="primary"
                  href={t('href')}
                  target="_blank"
                  textDecoration="none"
                >
                  {' '}
                </Link>
              ),
            }}
          />
        </Text>
        <FormField.Input
          label={t('label')}
          name="clearSaleCode"
          onChange={onChange}
          value={clearSaleCode}
        />
      </Box>
    </CardWithTitle>
  );
}
