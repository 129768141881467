import { useSelector } from 'react-redux';
import { getAdminURL } from 'domains/Auth/authSlice/authSelectors';

const REMOVE_FIRST_SLASH_REGEX = /^\//;

function pathWithOutFirstSlash(path = '') {
  return path.replace(REMOVE_FIRST_SLASH_REGEX, '');
}

function useAdminLinks() {
  const url = useSelector(getAdminURL);

  function getOldAdminLink(path?: string) {
    return `${url}/admin/${pathWithOutFirstSlash(path)}`;
  }

  function getNewAdminLink(path?: string) {
    return `${url}/admin/v2/${pathWithOutFirstSlash(path)}`;
  }

  return {
    getOldAdminLink,
    getNewAdminLink,
  };
}
export default useAdminLinks;
