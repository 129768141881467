import { Icon, Text } from '@nimbus-ds/components';
import { PaymentMethodType } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { usePaymentMethodLabel } from '../PaymentMethodLabel';
import { PAYMENT_METHOD_TYPE_ICONS, isLastItem } from '../utils';
import './PaymentMethodInlineLabel.scss';

interface PaymentMethodLabelProps {
  providerId: string;
  paymentMethods: PaymentMethodType[];
}

function PaymentMethodInlineLabel({
  paymentMethods,
  providerId,
}: Readonly<PaymentMethodLabelProps>) {
  const t = useTranslationPayments();
  const getTranslation = usePaymentMethodLabel();

  const PaymentIcon = PAYMENT_METHOD_TYPE_ICONS[paymentMethods[0]];
  return (
    <div className="stratus--payment-method-inline-label">
      <Stack gap="1-5">
        <Icon source={<PaymentIcon />} />
        <Text>
          {paymentMethods
            .map(
              (paymentMethod, index) =>
                `${t(getTranslation(providerId, paymentMethod))}${
                  !isLastItem(paymentMethods, index) ? ',' : ''
                } `,
            )
            .join('')}
        </Text>
      </Stack>
    </div>
  );
}

export default PaymentMethodInlineLabel;
