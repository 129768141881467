import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanAddresses,
  fetchAddresses as fetchAddressesAction,
  getAddressesData,
  getAddressesStatus,
} from 'domains/Billing/Invoices/invoicesSlice';

function useGetAddressesBrazil() {
  const dispatch = useAppDispatch();
  const addressesBrazil = useSelector(getAddressesData);
  const { isError, isLoading } = useSelector(getAddressesStatus);

  const fetchAddressesBrazil = useCallback(
    (zipcode) => {
      dispatch(fetchAddressesAction({ zipcode, countryCode: 'BR' }));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      dispatch(cleanAddresses());
    },
    [dispatch],
  );

  return {
    fetchAddressesBrazil,
    addressesBrazil,
    isError,
    isLoading,
  };
}

export default useGetAddressesBrazil;
