import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Text } from '@tiendanube/components';

interface AuthorProps {
  userName: string | null;
  orderId: string | null;
  orderNumber: number | null;
  isUserNameTN?: boolean;
  isExternalApp?: boolean;
}

function Author({
  userName,
  isUserNameTN = false,
  isExternalApp = false,
}: AuthorProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CATALOG);

  const getAuthor = (): string => {
    if (!userName) {
      return '';
    }
    if (isExternalApp) {
      return userName;
    }
    return `${userName} ${
      isUserNameTN ? t('products.stockHistoryModal.author.ofNuvemShop') : ''
    }`;
  };

  const author = getAuthor();
  const by = t('products.stockHistoryModal.author.by');

  return author ? (
    <Text size="caption">
      {by} {author}
    </Text>
  ) : null;
}

export default Author;
