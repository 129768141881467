import { Box, Tag, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

import './VariantMissingDataTag.scss';

function VariantMissingDataTag() {
  const t = useTranslationCatalog();
  const weightDimensionTag = t('products.filters.weightDimension.empty');
  return (
    <Box data-style="stratus--variant-missing-data-tag">
      <Tag appearance="warning">
        <ExclamationTriangleIcon size={12} />
        <Text color="warning-textLow" fontSize="caption" lineHeight="caption">
          {weightDimensionTag}
        </Text>
      </Tag>
    </Box>
  );
}

export default VariantMissingDataTag;
