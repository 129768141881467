import { useEffect } from 'react';
import { Link, Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { Stack } from 'commons/components';
import {
  useSetMailFromMutation,
  useLazyVerifyInmailRecordQuery,
} from 'domains/Configurations/Notifications/notificationsApi';
import { trackingCnameConfigCanClick } from 'domains/Configurations/tracking';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface CnameWarningAlertProps {
  isMailFromFailed: boolean;
}

export function CnameWarningAlert({
  isMailFromFailed,
}: Readonly<CnameWarningAlertProps>) {
  const t = useTranslationConfigurations(
    'notifications.notificationsListPage.cnameAlert',
  );

  const [verifyInmailRecord, { data: inmailVerification, status }] =
    useLazyVerifyInmailRecordQuery();
  const [setMailFrom] = useSetMailFromMutation();

  useEffect(() => {
    const fetchInmailVerification = async () => {
      const { data } = await verifyInmailRecord();
      if (data?.verified) setMailFrom();
    };
    if (!isMailFromFailed) fetchInmailVerification();
  }, [isMailFromFailed, setMailFrom, verifyInmailRecord]);

  const showCnameWarningAlert =
    isMailFromFailed ||
    (!['pending', 'uninitialized'].includes(status) &&
      !inmailVerification?.verified);

  return (
    <AlertSessionStorage
      show={showCnameWarningAlert}
      title={t('title')}
      appearance="warning"
      keyName="cnameWarningAlert"
    >
      <Stack column align="flex-start">
        <Text>{t('content')}</Text>
        <Link
          as="a"
          href={t('link')}
          target="_blank"
          onClick={trackingCnameConfigCanClick}
        >
          {t('action')}
        </Link>
      </Stack>
    </AlertSessionStorage>
  );
}
