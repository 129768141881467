import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getTemplatesAction,
  getTemplatesDataSelector,
  getTemplatesStatusSelector,
  cleanTemplatesStatusAction,
} from '../../contentPagesSlice';

function useTemplatesContent() {
  const dispatch = useAppDispatch();
  const status = useSelector(getTemplatesStatusSelector);
  const templates = useSelector(getTemplatesDataSelector);

  const getTemplates = useCallback(async () => {
    await dispatch(getTemplatesAction());
    dispatch(cleanTemplatesStatusAction());
  }, [dispatch]);

  return { status, templates, getTemplates };
}

export default useTemplatesContent;
