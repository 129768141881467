import {
  Grid,
  Stack,
  Text,
  DataList,
  InterfaceNameValue,
} from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { StatsProductsItemType } from 'domains/Statistics/types';
import ProductInNumberItem, {
  StatsProductsItemInterface,
} from '../StatsProductsItem';
import StatsProductsItemEmpty from '../StatsProductsItemEmpty';
import ProductsInNumberPagination from '../StatsProductsItemPagination';

export interface YourProductsInNumberPagination {
  totalResults: number;
  perPage: number;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
}

interface StatsProductsProps {
  item: StatsProductsItemType;
  labelHeader: string;
  inputType: 'currency' | 'number';
  inputLabel: string;
  value: string;
  onChange: (e: InterfaceNameValue) => void;
  onSubmit: () => void;
  products: StatsProductsItemInterface[];
  pagination: YourProductsInNumberPagination;
  onChangePagination: (page: number) => void;
  isEmpty?: boolean;
}

function StatsProducts({
  item,
  labelHeader,
  inputLabel,
  inputType,
  value,
  onChange,
  onSubmit,
  products,
  pagination,
  onChangePagination,
  isEmpty = false,
}: StatsProductsProps) {
  const { symbol } = useGetCurrency();

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item fill>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Text>{labelHeader}</Text>
            </Grid.Column>
            <Grid.Column>
              <InputNumber
                type="integer"
                name="quantity"
                prependLabel={inputType === 'currency' ? symbol : ''}
                appendLabel={inputType === 'number' ? inputLabel : ''}
                value={value}
                onChange={onChange}
                onBlur={onSubmit}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Stack.Item>
      <Stack.Item fill>
        {isEmpty ? (
          <StatsProductsItemEmpty quantity={value} type={item} />
        ) : (
          <DataList spacing="tight">
            {products.map((product) => (
              <DataList.Row key={product.productId} id={product.productId}>
                <DataList.Cell width={100}>
                  <ProductInNumberItem
                    product={product}
                    totalType={inputType}
                  />
                </DataList.Cell>
              </DataList.Row>
            ))}
          </DataList>
        )}
      </Stack.Item>
      {!isEmpty && (
        <Stack.Item fill>
          <ProductsInNumberPagination
            pagination={pagination}
            onPageSelect={onChangePagination}
          />
        </Stack.Item>
      )}
    </Stack>
  );
}

export default StatsProducts;
