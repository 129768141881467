import { Box, Button } from '@nimbus-ds/components';

function Skeleton(): JSX.Element {
  return (
    <Box display="flex" flexDirection="row" gap="2" justifyContent="flex-end">
      <Button.Skeleton />
      <Button.Skeleton />
    </Box>
  );
}

export default Skeleton;
