import { Text } from '@tiendanube/components';
import { Layout, RemarksCardSkeleton, useResponsive } from 'commons/components';
import {
  OrderSummaryCardSkeleton,
  CustomerDataCardSkeleton,
  DetailsHeaderSkeleton,
} from 'domains/Orders/components';
import {
  PaymentLinkCardSkeleton,
  PaymentCardSkeleton,
  DeliveryInfoCardSkeleton,
} from '../../../../components';

function OrderDetailsSkeleton(): JSX.Element {
  const { isDesktop } = useResponsive();
  return (
    <Layout
      mainContent
      left={
        <>
          <DetailsHeaderSkeleton />
          <PaymentLinkCardSkeleton />
          <OrderSummaryCardSkeleton />
          <PaymentCardSkeleton />
          <RemarksCardSkeleton />
        </>
      }
      right={
        <>
          {isDesktop && <Text.Skeleton />}
          <CustomerDataCardSkeleton />
          <DeliveryInfoCardSkeleton />
        </>
      }
    />
  );
}

export default OrderDetailsSkeleton;
