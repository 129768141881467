import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  LocationPriorityRequestDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getLocationsList,
  createLocation as createLocationAction,
  updateLocation as updateLocationAction,
  updateLocationsPriorities as updateLocationsPrioritiesAction,
  updateMainLocation as updateMainLocationAction,
  deleteLocation as deleteLocationAction,
} from '../../locationSlice';
import {
  getLocationMain,
  getLocationsEntitiesArray,
  getLocationsMainId,
  getLocationsOthers,
  getLocationsOthersIds,
  getLocationsStatus,
} from '../../locationSlice/locationSelectors';
import {
  EditLocationParamsInterface,
  LocationValuesInterface,
} from '../../pages/types';

export interface UseLocationsResult {
  locationMain?: LocationResponseDto;
  locationsOthers: LocationResponseDto[];
  locations: LocationResponseDto[];
  locationsMainId: string;
  locationsOthersIds: string[];
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  fetchLocations: () => Promise<void>;
  createLocation: (location: LocationValuesInterface) => Promise<void>;
  updateLocationAndFetch: (
    params: EditLocationParamsInterface,
  ) => Promise<void>;
  updateLocationsOrdering: (
    locations: LocationPriorityRequestDto[],
  ) => Promise<void>;
  updateMainLocation: (locationId: string) => Promise<void>;
  deleteLocation: (locationId: string) => Promise<void>;
}

function useLocations(): UseLocationsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(getLocationsStatus);

  const locationsMainId = useSelector(getLocationsMainId);

  const locations = useSelector(getLocationsEntitiesArray);

  const locationsOthersIds = useSelector(getLocationsOthersIds);

  const locationMain = useSelector(getLocationMain);

  const locationsOthers = useSelector(getLocationsOthers);

  const fetchLocations = useCallback(async () => {
    await dispatch(getLocationsList());
  }, [dispatch]);

  const createLocation = useCallback(
    async (location) => {
      unwrapResult(await dispatch(createLocationAction(location)));
    },
    [dispatch],
  );

  const updateLocationAndFetch = useCallback(
    async (location) => {
      unwrapResult(await dispatch(updateLocationAction(location)));
      fetchLocations();
    },
    [dispatch, fetchLocations],
  );

  const updateLocationsOrdering = useCallback(
    async (locations: LocationPriorityRequestDto[]) => {
      unwrapResult(await dispatch(updateLocationsPrioritiesAction(locations)));
    },
    [dispatch],
  );

  const updateMainLocation = useCallback(
    async (locationId: string) => {
      unwrapResult(await dispatch(updateMainLocationAction(locationId)));
    },
    [dispatch],
  );

  const deleteLocation = useCallback(
    async (locationId: string) => {
      unwrapResult(await dispatch(deleteLocationAction(locationId)));
    },
    [dispatch],
  );

  const isEmpty = locations.length === 0;

  return {
    locationMain,
    locationsOthers,
    locations,
    locationsMainId,
    locationsOthersIds,
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    fetchLocations,
    createLocation,
    updateLocationAndFetch,
    updateLocationsOrdering,
    updateMainLocation,
    deleteLocation,
  };
}

export default useLocations;
