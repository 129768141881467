import { Box, Card, Title } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { TabsMultiLanguage } from 'commons/components';
import { useToggle } from 'commons/useToggle';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import InputsSeoParameters from './InputsSeoParameters';
import { FormPageProps } from '../../FormPage';

function SeoParametersCard({
  values,
  onChange,
}: Omit<FormPageProps, 'errors'>) {
  const t = useTranslationOnline('contentPages.formPage.cardSeo');
  const [isOpen, setIsOpen] = useToggle();

  return (
    <Card>
      <Card.Header onClick={setIsOpen}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Title as="h2">{t('title')}</Title>
          {isOpen ? (
            <ChevronUpIcon size="medium" />
          ) : (
            <ChevronDownIcon size="medium" />
          )}
        </Box>
      </Card.Header>
      {isOpen && (
        <Card.Body>
          <TabsMultiLanguage>
            <InputsSeoParameters values={values} onChange={onChange} />
          </TabsMultiLanguage>
        </Card.Body>
      )}
    </Card>
  );
}

export default SeoParametersCard;
