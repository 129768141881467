import { cloneElement, ReactElement } from 'react';
import {
  NameType,
  Payload,
} from 'recharts/types/component/DefaultTooltipContent';

import './TooltipChartStratus.scss';

export interface TooltipChartStratusProps {
  payload?: Payload<number, NameType>[] | null;
  label?: string;
  children: ReactElement;
}

function TooltipChartStratus({
  children,
  label,
  payload,
}: TooltipChartStratusProps) {
  return (
    <div className="stratus--tooltip-chart-stratus">
      {cloneElement(children, { ...children.props, label, payload })}
    </div>
  );
}

export default TooltipChartStratus;
