export interface CreditCardFormFields {
  cardNumber: string;
  cardholderName: string;
  expirationDate: string;
  expirationMonth: string;
  expirationYear: string;
  securityCode: string;
  identificationType: string;
  identificationNumber: string;
  cardholderEmail: string;
  installments: string;
  issuer: string;
}

const errorsMap = {
  cc_rejected_bad_filled_card_number: 'cardNumber',
  cc_rejected_bad_filled_date: 'expirationDate',
  cc_rejected_bad_filled_security_code: 'securityCode',
} as const;

export function getCreditCardFormErrors(
  errorCode: string | undefined,
  errorsMercadoPago: Partial<CreditCardFormFields>,
  formErrors: Partial<CreditCardFormFields>,
): Partial<CreditCardFormFields> {
  let executionError: Partial<CreditCardFormFields> = {};
  if (errorCode && errorsMap[errorCode]) {
    const key = errorsMap[errorCode];
    executionError = { [key]: `form.${key}.errors.invalid` };
  }
  return { ...errorsMercadoPago, ...formErrors, ...executionError };
}
