import { useEffect } from 'react';
import { Stack, Title } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { InvoiceHistoryDataTable } from './components';
import InvoiceHistoryMobileSkeleton from './InvoiceHistoryMobileSkeleton';
import useInvoiceHistoryMobile from './useInvoiceHistoryMobile';
import TaxCondition from '../TaxCondition';

function InvoiceHistoryMobile(): JSX.Element {
  const t = useTranslationBilling();

  const {
    fetchInvoiceHistory,
    invoiceIds,
    fetchMoreInvoiceHistory,
    status,
    updateStatus,
  } = useInvoiceHistoryMobile();

  useEffect(() => {
    fetchInvoiceHistory();
  }, [fetchInvoiceHistory]);

  const shouldShowSpinner =
    updateStatus === 'loading' && invoiceIds.length !== 0;

  return (
    <Stack column spacing="loose" align="stretch">
      <TaxCondition />
      <Stack column align="stretch" spacing="base">
        <Title type="h3">{`${t('invoiceHistory.title')}`}</Title>
        {status === 'loading' ? (
          <InvoiceHistoryDataTable.Skeleton />
        ) : (
          <InvoiceHistoryDataTable
            data={invoiceIds}
            fetchMore={fetchMoreInvoiceHistory}
            onRefresh={fetchInvoiceHistory}
            shouldShowSpinner={shouldShowSpinner}
          />
        )}
      </Stack>
    </Stack>
  );
}

InvoiceHistoryMobile.Skeleton = InvoiceHistoryMobileSkeleton;

export default InvoiceHistoryMobile;
