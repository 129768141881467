import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { CategoryResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CatalogStateInterface } from '../../../types';

export const getCatalog = (
  state: RootStateType,
): CombinedState<CatalogStateInterface> => state.catalog;

export const getCategoriesData = createSelector(
  getCatalog,
  (catalog) => catalog.categories,
);

export const getCategoriesEntities = createSelector(
  getCategoriesData,
  (categoriesData) => categoriesData.entities,
);

export const getCategoriesList = createSelector(
  getCategoriesEntities,
  (entities): CategoryResponseDto[] => Object.values(entities),
);

export const getCategoryOptions = createSimpleSelector(
  [getCategoriesData],
  (data) => data.options,
);

export const getCategoriesStatus = createSimpleSelector(
  [getCategoriesData],
  ({ status }) => convertStatusTypeToObject(status),
);
