import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch, RootStateType } from 'App/store';
import { getProductById as getProductByIdAction } from 'domains/Catalog/Products/productsSlice';
import {
  getProductDetailsFetchStatus,
  createGetProductById,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

export interface UseProductDetailsInterface {
  loading: boolean;
  error: boolean;
  fetchProduct: () => void;
  product: ProductDetailsResponseDto;
}

function useProductDetails(id: string): UseProductDetailsInterface {
  const dispatch = useAppDispatch();
  const getProductById = useMemo(createGetProductById, []);
  const product = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );
  const { loading, error } = useSelector(getProductDetailsFetchStatus);

  const fetchProduct = useCallback(() => {
    dispatch(getProductByIdAction(id));
  }, [id, dispatch]);

  return useMemo(
    () => ({
      loading,
      error,
      fetchProduct,
      product,
    }),
    [loading, error, fetchProduct, product],
  );
}

export default useProductDetails;
