import { addDays, differenceInDays } from 'date-fns';
import { ConceptCode } from '@tiendanube/common';
import { useStoreStatus } from 'domains/Auth/hooks';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';

const DAYS_TILL_APP_EXPIRATION = 10;

export default function useExpirationDate(
  conceptCode: ConceptCode,
  externalReference?: string,
) {
  const isPlan = conceptCode === 'plan-cost';
  const isApp = conceptCode === 'app-cost';
  const { adminDisableDate } = useStoreStatus();
  const { data: appsList } = useGetAppsListQuery(undefined, { skip: !isApp });
  const app = isApp
    ? appsList?.find(({ id }) => id === externalReference)
    : undefined;

  const daysTillAppExpiration =
    app && app.daysRemaining + DAYS_TILL_APP_EXPIRATION;

  const expiresIn = isPlan
    ? adminDisableDate
      ? differenceInDays(new Date(adminDisableDate), new Date())
      : undefined
    : daysTillAppExpiration;

  let expirationDate: Date | undefined;

  if (isPlan) {
    expirationDate = adminDisableDate ? new Date(adminDisableDate) : undefined;
  } else {
    expirationDate = daysTillAppExpiration
      ? addDays(new Date(), daysTillAppExpiration)
      : undefined;
  }

  const appName = app?.name;

  return { expiresIn, expirationDate, appName };
}
