import { ReactComponent as Logo } from './logo-nuvem-envio.svg';

interface LogoNuvemEnvioProps {
  width?: string;
  height?: string;
  marginTop?: string;
  marginBottom?: string;
}

function LogoNuvemEnvio({
  width = '100px',
  height = '30px',
  marginTop = '15px',
  marginBottom = '15px',
}: LogoNuvemEnvioProps): JSX.Element {
  return (
    <Logo
      style={{
        width,
        height,
        marginTop,
        marginBottom,
      }}
    />
  );
}

export default LogoNuvemEnvio;
