import { PromotionResponseDto } from '@tiendanube/common';
import { addMounthsToday, getTodayIsoString } from 'commons/utils/date';

export const PromotionTypes = {
  /*
   * Replicated from Common to avoid known bug:
   * when importing variables from Common into Stratus (types have no issues),
   * compilation may crash with an error such as:
   * `Could not resolve "class-transformer/storage"`
   */
  BUY_X_PAY_Y: 'BuyXPayY',
  N_AT_X_OFF: 'NAtX%off', // Deprecated progressive discount
  PROGRESSIVE_DISCOUNT: 'ProgressiveDiscount',
  BUY_2_PAY_1: '2x1',
  BUY_3_PAY_2: '3x2',
} as const;

export const promotionDefault: PromotionResponseDto = {
  name: '',
  promotionType: PromotionTypes.BUY_3_PAY_2,
  discountTypeId: '3',
  scopeType: 'all',
  categories: [],
  products: [],
  discountForQuantity: false,
  quantity: null,
  discount: null,
  progressiveDiscounts: [{ quantity: 0, discount: 0 }],
  buyXPayY: null,
  dateType: 'unlimited',
  startDate: getTodayIsoString(),
  endDate: addMounthsToday(1),
  combinesWithOtherDiscounts: true,
};
