import { ReactNode } from 'react';
import './FooterBottom.scss';

interface FotterBottomProps {
  children: ReactNode;
  height: string;
}

function FooterBottom({ children, height }: Readonly<FotterBottomProps>) {
  return (
    <div className="stratus--footer-modal-container" style={{ height }}>
      {children}
    </div>
  );
}

export default FooterBottom;
