import { Box, Card, Input } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function RelatedProductCardSkeleton(): JSX.Element {
  const t = useTranslationCatalog();
  return (
    <Card title={t('products.detail.relatedProduct.title')}>
      <Box flexDirection="column" alignItems="stretch">
        <Input.Skeleton />
        <Input.Skeleton />
        <Box
          id="divider"
          backgroundColor="neutral-surfaceHighlight"
          height="1px"
          marginTop="3"
          marginBottom="3"
        />
        <Input.Skeleton />
        <Input.Skeleton />
      </Box>
    </Card>
  );
}

export default RelatedProductCardSkeleton;
