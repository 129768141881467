import { useState } from 'react';
import { Box, Popover } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { ActionIconButton } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { StockChange } from 'domains/Catalog/Inventory/inventoryServices';
import { useInventory } from 'domains/Catalog/Inventory/pages/hooks';
import { trackingProductStockCalculatorFromInventory } from 'domains/Catalog/Products/tracking';
import EditStock, { EditStockProps } from '../EditStock';

import './EditStockPopover.scss';

interface EditShowPopoverProps
  extends Pick<
    EditStockProps,
    'productId' | 'variantId' | 'locationId' | 'displayApplyToAll'
  > {
  currentStock: number | null;
}

function EditStockPopover({
  productId,
  variantId,
  currentStock,
  locationId,
  displayApplyToAll,
}: Readonly<EditShowPopoverProps>): JSX.Element {
  const t = useTranslationCatalog();
  const [isShow, setIsShow] = useState(false);
  const { setStock } = useInventory();
  const handleEditStockClose = (stockChange?: StockChange) => {
    if (stockChange) {
      setStock(stockChange);
    }
    setIsShow(false);
  };
  return (
    <Popover
      arrow
      position="bottom"
      visible={isShow}
      onVisibility={(visible) => setIsShow(visible)}
      content={
        <Box width="18.5rem" display="flex" flexDirection="column" gap="6">
          <EditStock
            productId={productId}
            variantId={variantId}
            currentStock={currentStock === null ? null : String(currentStock)}
            isVariantStock
            displayStockTypes
            displayStockTypesAs="radio"
            displayApplyToAll={displayApplyToAll}
            locationId={locationId}
            showHeader={false}
            onClose={handleEditStockClose}
          />
        </Box>
      }
    >
      <Box data-style="stratus-edit-stock-popover">
        <ActionIconButton
          content={t('inventory.actions.editStock')}
          source={<EditIcon />}
          backgroundColor="transparent"
          borderColor="transparent"
          onClick={trackingProductStockCalculatorFromInventory}
        />
      </Box>
    </Popover>
  );
}

export default EditStockPopover;
