import { Card, Checkbox, Title } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SettingsCheckoutFormProps } from '../../types';

export function DesignPreferencesCard({
  values: { allowsCheckoutStyling },
  onChange,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.designPreferencesCard',
  );

  const handleChange = ({ target: { name, checked } }) => {
    onChange({ name, value: checked });
  };

  return (
    <Card>
      <Card.Header>
        <Title fontSize="h3">{t('title')}</Title>
      </Card.Header>
      <Card.Body>
        <Checkbox
          checked={allowsCheckoutStyling}
          onChange={handleChange}
          name="allowsCheckoutStyling"
          label={t('allowsCheckoutStyling')}
        />
      </Card.Body>
    </Card>
  );
}
