import { InterfaceSelectOption } from '@tiendanube/components';
import { CATALOG_HIDDING_CATEGORIES } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from '../useTranslationCatalog';

function useHiddenCategories() {
  const t = useTranslationCatalog();

  const { categories } = useCategories();

  const [hasHiddenCategories] = useHasTags(CATALOG_HIDDING_CATEGORIES);
  const hiddenCategoriesText = t('categories.hidden');

  const categoriesSelectOptions: InterfaceSelectOption[] = categories.map(
    (category) => {
      const { id, title } = category;
      const hiddenLabel =
        !isCategoryVisible(category) && hasHiddenCategories
          ? ` (${hiddenCategoriesText})`
          : '';
      const label = title + hiddenLabel;
      return {
        value: id,
        label,
      };
    },
  );

  return { hasHiddenCategories, categoriesSelectOptions, hiddenCategoriesText };
}

export default useHiddenCategories;
