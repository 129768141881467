import { Button, Card, Select } from '@nimbus-ds/components';
import { Stack, useResponsive } from 'commons/components';
import './RecoveryCodesCard.scss';

const RECOVERY_CODES_QUANTITY = 10;

function RecoveryCodesCardSkeleton() {
  const { isDesktop } = useResponsive();

  return (
    <Card>
      <Card.Body>
        <Stack column>
          <div
            className={`stratus--recovery-codes__grid ${
              isDesktop ? 'stratus--recovery-codes__grid--desktop' : ''
            }`}
          >
            {[...Array(RECOVERY_CODES_QUANTITY).keys()].map((i) => (
              <Select.Skeleton key={i} width="100%" />
            ))}
          </div>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Button.Skeleton />
        <Button.Skeleton />
      </Card.Footer>
    </Card>
  );
}

export default RecoveryCodesCardSkeleton;
