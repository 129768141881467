import { Box, IconButton, Table, Tag, Text } from '@nimbus-ds/components';
import { Repeater, Stack } from 'commons/components';
import { UserSessionsListDesktopHeader } from './UserSessionsListDesktopHeader';

function UserSessionItemDesktopSkeleton() {
  return (
    <Table.Row>
      <Table.Cell width="40%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Text.Skeleton width="70%" />
        </Box>
      </Table.Cell>
      <Table.Cell width="35%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Text.Skeleton width="70%" />
        </Box>
      </Table.Cell>
      <Table.Cell width="25%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Text.Skeleton width="70%" />
        </Box>
      </Table.Cell>
      <Table.Cell width={{ md: '200px', xl: '230px' }}>
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Tag.Skeleton width="60%" height="1rem" />
        </Box>
      </Table.Cell>
      <Table.Cell width={{ md: '50px', xl: '65px' }}>
        <Box
          display="flex"
          alignItems="center"
          gap="none"
          minHeight="45px"
          justifyContent="center"
        >
          <IconButton.Skeleton height="2rem" width="2rem" />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}

export function UserSessionsListDesktopSkeleton() {
  return (
    <Stack spacing="tight" align="stretch" column>
      <Table>
        <UserSessionsListDesktopHeader />
        <Table.Body>
          <Repeater times={6} item={UserSessionItemDesktopSkeleton} />
        </Table.Body>
      </Table>
    </Stack>
  );
}
