import { Button, InterfaceButton } from '@tiendanube/components';
import { ChevronLeftIcon } from '@tiendanube/icons';

import './BackNavigation.scss';

export type BackNavigationProps = Pick<InterfaceButton, 'children' | 'onClick'>;

function BackNavigation({
  children,
  onClick,
}: BackNavigationProps): JSX.Element {
  return (
    <div className="stratus--page-navbar__back">
      <Button onClick={onClick} icon={ChevronLeftIcon} appearance="secondary">
        {children}
      </Button>
    </div>
  );
}

export default BackNavigation;
