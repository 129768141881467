import { createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { InterfaceOrderMetafieldsSchema } from '../orderMetafieldsSlice';

export const getOrderMetafieldsState = (
  state: RootStateType,
): InterfaceOrderMetafieldsSchema => state.orders.orderMetafields;

const getEntityById = (_: RootStateType, id: string) => id;

export const getMetafieldsData = createSelector(
  getOrderMetafieldsState,
  (orderMefafields) => orderMefafields.list.data,
);

export const getMetafieldsStatus = createSelector(
  getOrderMetafieldsState,
  (orderMefafields) => convertStatusTypeToObject(orderMefafields.list.status),
);

export const getMetafieldById = createSelector(
  getMetafieldsData,
  getEntityById,
  (metafields, id) =>
    metafields ? metafields.internals[id] || metafields.fromApi[id] : null,
);

export const getMetafieldUpdateStatusById = createSelector(
  getOrderMetafieldsState,
  getEntityById,
  (orderState, id) => orderState.updateStatus[id],
);

export const getOrdersBulkAssignmentMetafieldStatus = createSelector(
  getOrderMetafieldsState,
  (orderState) => orderState.bulkMetafieldsStatus,
);
