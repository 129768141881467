export const BASE_BILLING_PLANS_URL = 'v1/billing/plans';

export const UPDATE_PLAN_URL = `${BASE_BILLING_PLANS_URL}/:id`;
export const CREATE_PAYMENT_TO_UPDATE_PLAN_URL = `${UPDATE_PLAN_URL}/payment`;

export const billingPlansEndpoints = {
  getPlans: 'getPlans',
  updatePlan: 'updatePlan',
  createPaymentToUpdatePlan: 'createPaymentToUpdatePlan',
} as const;

export const billingPlansTags = {
  billingPlansList: 'billingPlansList',
} as const;
