import { useState } from 'react';
import { useModal } from 'commons/hooks';
import { useImportFile, useLoadCsvFileAndCompareColumns } from '../../../hooks';

export type UpdateOptionsType = {
  updateExisting: boolean;
  updateStock: boolean;
  selectedLocation: string;
};

function useImportProducts() {
  const defaultUpdateOptions = {
    updateExisting: true,
    updateStock: true,
    selectedLocation: 'all',
  };
  const [updateOptions, setUpdateOptions] =
    useState<UpdateOptionsType>(defaultUpdateOptions);
  const [
    showImportProductsAlterationsModal,
    openShowImportProductsAlterationsModal,
    closeShowImportProductsAlterationsModal,
  ] = useModal();
  const [
    showExistingProductsModal,
    openShowExistingProductsModal,
    closeShowExistingProductsModal,
  ] = useModal();
  const {
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    file,
    fileColumns,
    isLoading: isLoadingCsv,
    isError: isErrorCsv,
  } = useLoadCsvFileAndCompareColumns();
  const {
    sendFile,
    isLoading: isLoadingImport,
    isError: isErrorImport,
    jobStatus,
    cleanJob,
  } = useImportFile();

  const onUpdateOptionsChange = (prop: string, value: boolean | string) =>
    setUpdateOptions((options) => ({
      ...options,
      [prop]: value,
    }));

  const onCheckExistingProducts = (): void => {
    if (!updateOptions.updateExisting) {
      openShowExistingProductsModal();
    } else {
      onConfirm();
    }
  };

  const onConfirm = (): void => {
    closeShowExistingProductsModal();
    openShowImportProductsAlterationsModal();
  };

  const onImport = async () => {
    closeShowImportProductsAlterationsModal();
    const columns = fileColumns
      .map((key) =>
        ignoreColumn(matchColumns[key]) ? 'ignore' : matchColumns[key],
      )
      .join(',');
    const result = await sendFile({
      file,
      columns,
      replace: updateOptions.updateExisting,
      columnsChanged: !!badColumns.length,
    });
    if (result) {
      onRemoveFile();
      setUpdateOptions(defaultUpdateOptions);
    }
  };

  const ignoreColumn = (column: string) => {
    const { updateExisting, updateStock, selectedLocation } = updateOptions;
    if (updateExisting && column.match(/stock.*/)) {
      const ignoreLocation =
        selectedLocation !== 'all' && column !== `stock_${selectedLocation}`;
      return !updateStock || ignoreLocation;
    }
    return false;
  };

  return {
    updateOptions,
    showImportProductsAlterationsModal,
    showExistingProductsModal,
    jobStatus,
    cleanJob,
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    isLoadingCsv,
    isErrorCsv,
    isLoadingImport,
    isErrorImport,
    onUpdateOptionsChange,
    onCheckExistingProducts,
    onConfirm,
    onImport,
    closeShowImportProductsAlterationsModal,
    closeShowExistingProductsModal,
  };
}

export default useImportProducts;
