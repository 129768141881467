import { useEffect } from 'react';
import { ArchiveIcon } from '@nimbus-ds/icons';
import HelpLink from 'App/HelpLink';
import {
  HeaderContent,
  IonPageStratus,
  HeaderTop,
  Responsive,
} from 'commons/components';
import { HELP_LINKS_CUSTOMERS_MESSAGES } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import {
  MessagesListMobile,
  MessagesListDesktop,
  BulkActionsProvider,
  useBulkActions,
  MessagesListEmpty,
  MessagesListError,
  BulkActionsMessages,
} from './components';
import useMessages from './hooks/useMessages';

function MessagesListPageWithBulkActions() {
  return (
    <BulkActionsProvider>
      <MessagesListPage />
    </BulkActionsProvider>
  );
}

function MessagesListPage() {
  const { toggleEditMode, editMode, selectedRowsId } = useBulkActions();
  const t = useTranslationCustomers();
  const language = useTranslationLanguage();

  const { isError, isEmpty, fetchMessages } = useMessages();

  const editModeAction = editMode
    ? {
        onClick: toggleEditMode,
        children: t('common:editMode.cancel'),
      }
    : {
        onClick: toggleEditMode,
        children: t('Organizar'),
        icon: ArchiveIcon,
      };

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        selectedRowsId.length === 0 &&
        !isEmpty && <HeaderTop mainAction={editModeAction} />
      }
      headerContent={
        <HeaderContent
          title={t('messages.title')}
          subtitle={!isEmpty && !isError ? t('messages.subtitle') : ''}
        />
      }
    >
      <BulkActionsMessages />
      {isEmpty && <MessagesListEmpty />}
      {isError && <MessagesListError onRetryError={fetchMessages} />}
      {!isEmpty && !isError && (
        <Responsive
          mobile={
            <MessagesListMobile
              isEditMode={editMode}
              onToggleEditMode={toggleEditMode}
            />
          }
          desktop={<MessagesListDesktop />}
        />
      )}
      <HelpLink
        title={t('messages.helpLink.title')}
        previousValue=""
        currentViewTracking=""
        linkURL={HELP_LINKS_CUSTOMERS_MESSAGES[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default MessagesListPageWithBulkActions;
