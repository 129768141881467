import { ChangeEvent, useCallback, useState } from 'react';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import ModalAside from 'App/components/lab/ModalAside';
import {
  HeaderTop,
  HeaderContent,
  CancelAndSaveButtons,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { useCreateDomainMutation } from 'domains/Configurations/Domains/domainsApi';
import { trackingCreateDomainSubmitClick } from 'domains/Configurations/Domains/tracking';
import {
  isAlreadyInUseDomainError,
  isValidDomain,
  isInvalidDomainError,
  CREATE_DOMAIN_CACHE_KEY,
} from 'domains/Configurations/Domains/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { AddDomainAlert } from './AddDomainAlert';

interface AddDomainModalProps {
  show: boolean;
  onClose: () => void;
}

const toToastStatus = (isSuccess: boolean, apiError: unknown) => {
  if (isSuccess) return 'success';
  if (
    apiError &&
    !isAlreadyInUseDomainError(apiError) &&
    !isInvalidDomainError(apiError)
  )
    return 'error';

  return 'idle';
};

export function AddDomainModal({
  show,
  onClose,
}: Readonly<AddDomainModalProps>) {
  const t = useTranslationConfigurations('domains.addModal');
  const [createDomain, { isLoading, isSuccess, error: apiError }] =
    useCreateDomainMutation({ fixedCacheKey: CREATE_DOMAIN_CACHE_KEY });
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const isUrlEmpty = url.trim() === '';

  const handleClose = useCallback(() => {
    setError('');
    setUrl('');
    onClose();
  }, [onClose]);

  useToastStatus({
    error: t('errorToast'),
    success: t('successToast'),
    status: toToastStatus(isSuccess, apiError),
    onSuccess: handleClose,
  });

  const handleChangeUrl = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setUrl(value);
  };

  const handleSave = async () => {
    trackingCreateDomainSubmitClick();

    const isValid = isValidDomain(url);

    if (!url || isUrlEmpty || !isValid) {
      setError(t('error'));
      return;
    }

    try {
      await createDomain({ name: url }).unwrap();
    } catch (e) {
      if (e && isAlreadyInUseDomainError(e)) {
        setError(t('alreadyInUse'));
        return;
      }

      if (e && isInvalidDomainError(e)) {
        setError(t('error'));
        return;
      }
    }
  };

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <AddDomainAlert />
      <FormField.Input
        id="url"
        label={t('label')}
        placeholder="https://"
        helpText={error ?? t('helpText')}
        appearance={error ? 'danger' : undefined}
        helpIcon={error ? ExclamationCircleIcon : undefined}
        autoFocus
        showHelpText
        value={url}
        onChange={handleChangeUrl}
      />
      <CancelAndSaveButtons
        onCancel={onClose}
        onSave={handleSave}
        saveText={isLoading ? t('addingBtn') : t('addBtn')}
        isDisabled={isUrlEmpty || isLoading || !!error}
        isLoading={isLoading}
      />
    </ModalAside>
  );
}
