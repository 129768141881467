import { Box, Card, Skeleton, Title } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function BuyerNoteCardSkeleton() {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.buyerNoteCard',
  );

  return (
    <Card>
      <Card.Header>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Title fontSize="h2">{t('title')}</Title>
          <Skeleton height="1.25em" width="45px" borderRadius="1em" />
        </Box>
      </Card.Header>

      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Skeleton height="1rem" width="300px" />

          <Skeleton height="2rem" width="200px" />

          <Box display="flex" flexDirection="row" gap="2">
            <Skeleton height="1rem" width="16px" />
            <Skeleton height="1rem" width="200px" />
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
