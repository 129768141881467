import { ModalConfirmationBase } from 'App/components';
import { toStatusType } from 'App/rtk';
import { useToastStatus } from 'commons/hooks';
import { useDeleteDraftMutation } from 'domains/Online/Themes/themesApi';
import { trackingThemeLayoutDraftDelete } from 'domains/Online/Themes/tracking';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

interface DeleteDraftModalProps {
  showModal: boolean;
  onClose: () => void;
}

export function DeleteDraftModal({
  showModal,
  onClose,
}: Readonly<DeleteDraftModalProps>) {
  const t = useTranslationOnline('themesPage.draft.actions.deleteDraftModal');
  const [deleteDraft, { status, isLoading }] = useDeleteDraftMutation();

  useToastStatus({
    status: toStatusType(status),
    progress: t('progress'),
    success: t('success'),
    error: t('error'),
  });

  const handleClickDeleteDraft = async () => {
    trackingThemeLayoutDraftDelete();
    await deleteDraft();
    onClose();
  };

  return (
    <ModalConfirmationBase
      show={showModal}
      title={t('title')}
      text={t('subtitle')}
      label={t('confirm')}
      labelCancel={t('cancel')}
      appearance="danger"
      disabled={isLoading}
      onConfirm={handleClickDeleteDraft}
      onCancel={onClose}
    />
  );
}
