import { useEffect, useState } from 'react';
import { IonPageStratus } from 'commons/components';
import { useModal } from 'commons/hooks';
import { DomainSoftBlock } from '../../components';
import { useGetDomainSoftBlockQuery } from '../../domainsApi';
import { useDomainSoftBlock } from '../../hooks';
import {
  trackingNotifyChangesClick,
  trackingRememberLaterClick,
  trackingSoftblockNameserverViewed,
} from '../../tracking';
import { ActionType } from '../DomainSoftBlockPage';
import { DomainsPage } from '../DomainsPage/DomainsPage';

const PAGE_SOURCE = 'domain';

export function DomainsPageWithSoftBlock() {
  const { data } = useGetDomainSoftBlockQuery();
  const { shouldShowInDomainPage, dismissInDomainPage, notifyDomainUpdate } =
    useDomainSoftBlock();
  const [showNotificationModal, openNotificationModal, closeNotificationModal] =
    useModal();
  const [mode, setMode] = useState<ActionType>('cancel');

  const isErrorScreen = !!data?.error;

  useEffect(() => {
    if (data?.storeId) {
      trackingSoftblockNameserverViewed(
        data.storeId,
        PAGE_SOURCE,
        isErrorScreen,
      );
    }
    return () => {
      dismissInDomainPage(true);
    };
  }, [data?.storeId, dismissInDomainPage, isErrorScreen]);

  if (!shouldShowInDomainPage || !data) return <DomainsPage />;

  const handleConfirmAction = () => {
    closeNotificationModal();
    notifyDomainUpdate(isErrorScreen);
    trackingNotifyChangesClick(data.storeId, PAGE_SOURCE);
  };

  const handleCancel = () => {
    dismissInDomainPage(false);
    trackingRememberLaterClick(data.storeId, PAGE_SOURCE);
  };

  const handleConfirm = () => {
    openNotificationModal();
    setMode('confirm');
  };

  return (
    <IonPageStratus width="small">
      <DomainSoftBlock
        data={data}
        mode={mode}
        isErrorScreen={isErrorScreen}
        showNotificationModal={showNotificationModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        onConfirmAction={handleConfirmAction}
        source={PAGE_SOURCE}
      />
    </IonPageStratus>
  );
}
