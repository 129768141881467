import { Card, Link, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function AddressingConfigurationInfoCardSkeleton(): JSX.Element {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');

  return (
    <Card>
      <Card.Header title={t('info.title')} />
      <Card.Body>
        <Stack column spacing="base" align="stretch">
          <Text fontSize="base" lineHeight="base" textAlign="left">
            {t('info.description')}
          </Text>
          <Title.Skeleton width="40%" />
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Link.Skeleton />
      </Card.Footer>
    </Card>
  );
}

export default AddressingConfigurationInfoCardSkeleton;
