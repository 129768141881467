import { useEffect, useState } from 'react';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { trackingOrderBulkMetafieldsSave } from 'domains/Metafields/components/ModalOrdersAssignMetafields/tracking';
import useMetafieldsToast from 'domains/Metafields/hooks/useMetafieldsToast';
import { useBulkMetafieldsAssignment } from '../../pages/hooks';

export interface OrdersAssignMetafieldsProps {
  ordersId: string[];
  metafields: { id: string; value: string }[];
}

export default function useModalOrdersAssignMetafields({
  onToggleEditMode,
  handleOnClose,
}) {
  const [values, setValues] = useState<OrdersAssignMetafieldsProps>({
    ordersId: [],
    metafields: [],
  });
  const { isSuccess, isError, handleBulkMetafieldsAssignment } =
    useBulkMetafieldsAssignment();
  const { activeAlertMetafields, setSelectedRowsId, selectedRowsId } =
    useBulkActions();
  const { addMetafieldToast } = useMetafieldsToast();

  useEffect(() => {
    if (isSuccess) {
      onToggleEditMode();
      activeAlertMetafields();
      handleOnClose();
      trackingOrderBulkMetafieldsSave(
        values.ordersId,
        values.metafields.map(({ id }) => id),
      );
      setSelectedRowsId([]);
    }

    if (isError) {
      addMetafieldToast({
        appearance: 'danger',
        label: 'assignOrdersMetafieldsModal.saveError',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(() => {
    setValues((prevState) => ({ ...prevState, ordersId: selectedRowsId }));
  }, [selectedRowsId]);

  const handleChange = (id: string, value: string) => {
    setValues({
      ...values,
      ordersId: selectedRowsId,
      metafields: values.metafields
        .filter((m) => m.id !== id)
        .concat({ id, value }),
    });
  };

  const handleOnBulkMetafields = async (
    values: OrdersAssignMetafieldsProps,
  ) => {
    await handleBulkMetafieldsAssignment({
      selectedOrderIds: values.ordersId.map((orderId) => Number(orderId)),
      selectedMetafields: values.metafields.map((metafield) => ({
        id: metafield.id,
        value: metafield.value,
      })),
    });
  };

  return {
    handleChange,
    handleOnConfirm: handleOnBulkMetafields,
    values,
  };
}
