import { generatePath } from 'react-router';
import { PaymentProviderDto } from '@tiendanube/common';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { useNavegate } from 'App/hooks';
import { handleClickExternal } from 'commons/utils/dom';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { PAYMENTS_ROUTES } from 'domains/Payments/paymentsRoutes';
import useGetMercadoPagoAuthorizationUrl from '../useGetMercadoPagoAuthorizationUrl';

interface UseGoToPaymentProviderInstallationRouteResult {
  goToInstallationRoute: () => Promise<void>;
  isLoading: boolean;
}

function useGoToPaymentProviderInstallationRoute(
  paymentProvider: PaymentProviderDto,
): UseGoToPaymentProviderInstallationRouteResult {
  const { goTo } = useNavegate();
  const { getMercadoPagoAuthorizationUrl, isLoading } =
    useGetMercadoPagoAuthorizationUrl();

  const goToInstallationRoute = async (): Promise<void> => {
    if (paymentProvider.isApp) {
      if (paymentProvider.id === PaymentProvidersCode.customPayments) {
        return goTo(generatePath(PAYMENTS_ROUTES.customPaymentNewRoute));
      }
      return goTo(
        generatePath(partnersAppsRoutes.authorization, {
          id: paymentProvider.appId,
        }),
      );
    } else {
      switch (paymentProvider.id) {
        case NativePaymentProviders.mercadoPago:
          return handleClickExternal(await getMercadoPagoAuthorizationUrl())();
        case NativePaymentProviders.payu:
          return goTo(PAYMENTS_ROUTES.payUSettings);
        case NativePaymentProviders.paypal:
          return goTo(PAYMENTS_ROUTES.payPalSettings);
        default:
          throw new Error(`No path for provider: ${paymentProvider.id}`);
      }
    }
  };

  return { goToInstallationRoute, isLoading };
}

export default useGoToPaymentProviderInstallationRoute;
