import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

interface UseTranslationPartnerAppsResponse {
  t: TFunction;
  language: string;
}

function useTranslationPartnerApps(
  keyPrefix?: string,
): UseTranslationPartnerAppsResponse {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.PARTNERS_APPS, { keyPrefix });
  return { t, language };
}

export default useTranslationPartnerApps;
