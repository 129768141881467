import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  deleteMessages as deleteMessagesAction,
  refreshMessages as refreshMessagesAction,
  readMessages as readMessagesAction,
  unreadMessages as unreadMessagesAction,
  spamMessages as spamMessagesAction,
  getMessagesFilters,
} from 'domains/Customers/Messages/messagesSlice';

export interface UseActionProducts {
  deleteMessages: (payload: string[]) => Promise<void>;
  readMessages: (payload: string[]) => Promise<void>;
  unreadMessages: (payload: string[]) => Promise<void>;
  spamMessages: (payload: string[]) => Promise<void>;
  refreshMessages: () => Promise<void>;
}

function useActionMessages(): UseActionProducts {
  const dispatch = useAppDispatch();
  const filters = useSelector(getMessagesFilters);

  const deleteMessages = async (payload: string[]) => {
    const result = await dispatch(deleteMessagesAction(payload));
    return unwrapResult(result);
  };

  const readMessages = async (payload: string[]) => {
    const result = await dispatch(readMessagesAction(payload));
    return unwrapResult(result);
  };

  const unreadMessages = async (payload: string[]) => {
    const result = await dispatch(unreadMessagesAction(payload));
    return unwrapResult(result);
  };

  const spamMessages = async (payload: string[]) => {
    const result = await dispatch(spamMessagesAction(payload));
    return unwrapResult(result);
  };

  const refreshMessages = useCallback(async () => {
    await dispatch(refreshMessagesAction({ ...filters }));
  }, [dispatch, filters]);

  return {
    deleteMessages,
    readMessages,
    spamMessages,
    unreadMessages,
    refreshMessages,
  };
}

export default useActionMessages;
