import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_MARKETING_APPS_CLICK,
  MENU_SECONDARY_MARKETING_AUTOMATION_CLICK,
} from 'config/trackingEvents';

export const menuSecondaryMarketingAutomationClick = () => {
  logEvent(MENU_SECONDARY_MARKETING_AUTOMATION_CLICK, {});
};

export const menuSecondaryMarketingAutomationAppsClick = () => {
  logEvent(MENU_SECONDARY_MARKETING_APPS_CLICK, {});
};
