import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import getImageMimeType, {
  imageMimeTypes,
  videoMimeTypes,
} from 'commons/utils/getImageMimeType/getImageMimeType';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageGalleryState } from '../../components/ImageGallery/ImageGallery';

const ONE_MB_IN_BYTES = 1024 * 1024;

interface UseImageLoaderProps {
  onUploadImages: (images: ImageGalleryState[]) => void;
}

function useImageLoader({ onUploadImages }: UseImageLoaderProps) {
  const language = useGetLanguage();
  const [imagesWithBadType, setImagesWithBadType] = useState<string[]>([]);
  const [imagesExceededSize, setImagesExceededSize] = useState<string[]>([]);
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);

  const ACCEPT_TYPE_FILE_ARRAY = hasUploadVideos
    ? [...imageMimeTypes, ...videoMimeTypes]
    : [...imageMimeTypes];

  const handleOnchange = async (files: File[]) => {
    const newImages: ImageGalleryState[] = await Promise.all(
      Object.values(files).map(async (file) => {
        const mimeType = await getImageMimeType(file);
        return {
          alt: { [language]: file.name },
          isLoading: true,
          id: uuidv4(),
          file,
          src: URL.createObjectURL(file),
          isError: false,
          name: file.name,
          size: file.size,
          type: file.type,
          mimeType,
          isEdited: false,
          mediaType: mimeType.includes('video') ? 'video' : 'image',
        };
      }),
    );
    const imagesWithBadType = newImages
      .filter(
        (file) =>
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.type) ||
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.mimeType),
      )
      .map((file) => file.name);
    const imagesExceededSize = newImages
      .filter((file) => file.size / ONE_MB_IN_BYTES >= 10)
      .map((file) => file.name);
    const validImages = newImages.filter(
      (file) =>
        !imagesWithBadType.includes(file.name) &&
        !imagesExceededSize.includes(file.name),
    );
    setImagesWithBadType(imagesWithBadType);
    setImagesExceededSize(imagesExceededSize);
    onUploadImages(validImages);
  };

  return {
    imagesWithBadType,
    imagesExceededSize,
    acceptTypeFile: ACCEPT_TYPE_FILE_ARRAY.join(','),
    setImagesWithBadType,
    setImagesExceededSize,
    handleOnchange,
  };
}

export default useImageLoader;
