import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { LoginRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { authorize as authorizeAction } from 'domains/Auth/authSlice';
import {
  getAuthorizeStatus,
  getAuthorizeStatusCode,
} from 'domains/Auth/authSlice/authSelectors';

interface AuthorizeResult {
  hasAuthFactor: boolean;
  authorizationCode: string;
}

interface useAuthorizeResponse {
  authorize: (data: LoginRequestDto) => Promise<AuthorizeResult>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isInternalServerError: boolean;
  statusCode: string;
}

function useAuthorize(): useAuthorizeResponse {
  const status: StatusType = useSelector(getAuthorizeStatus);
  const isError = status === 'error';
  const statusCode = useSelector(getAuthorizeStatusCode);
  const dispatch = useAppDispatch();
  const authorize = useCallback(
    async ({ email, password }: LoginRequestDto): Promise<AuthorizeResult> => {
      const response = await dispatch(authorizeAction({ email, password }));
      const result = unwrapResult(response);
      const { authorizationCode, type } = result;
      const hasAuthFactor = type === 'authentication-factor';
      return { hasAuthFactor, authorizationCode };
    },
    [dispatch],
  );

  return {
    authorize,
    isLoading: status === 'loading',
    isError,
    isSuccess: status === 'success',
    statusCode,
    isInternalServerError: statusCode === '500',
  };
}

export default useAuthorize;
