import Papa from 'papaparse';

export const isFirefoxBrowser = () => {
  const userAgent = navigator.userAgent;
  const isTargetBrowser = userAgent.indexOf('Firefox') > -1;
  return isTargetBrowser;
};

export const isCsvExtensionFile = (name: string) =>
  name.split('.').pop() === 'csv';

export const isCsvFileType = (type: string) => type === 'text/csv';

export const isExcelFileType = (type: string) =>
  type === 'application/vnd.ms-excel';

export const isExcelTypeWithFirefox = ({ name, type }: File) =>
  isFirefoxBrowser() && isExcelFileType(type) && isCsvExtensionFile(name);

export const processFile = async (
  fileCols: Array<string>,
  colsOptions: Array<{
    label: string;
    value: string;
  }>,
) => {
  const badCols = fileCols.filter(
    (column) => !colsOptions.find(({ label }) => label === column),
  );
  const matchCols = fileCols.reduce((columns, key) => {
    const value = colsOptions.find(({ label }) => label === key)?.value;
    return {
      ...columns,
      [key]: value ? value : 'ignore',
    };
  }, {});

  return { badCols, matchCols };
};

export const getNumberOfLinesFromFile = (file: File, maxLines: number) =>
  new Promise<number>((resolve, reject) => {
    let lines = -1;
    Papa.parse(file, {
      skipEmptyLines: true,
      step: function (_, parser) {
        lines = lines + 1;
        if (lines > maxLines) {
          parser.abort();
        }
      },
      complete: () => resolve(lines),
      error: (error) => reject(error),
    });
  });
