import { BaseCard, Checkbox, Stack, Text } from '@tiendanube/components';

function Skeleton(): JSX.Element {
  return (
    <div className="stratus--card-collapsible">
      <BaseCard>
        <BaseCard.Header>
          <Stack justify="space-between" spacing="none">
            <Text.Skeleton size="base" width="large" />
            <Checkbox.Skeleton />
          </Stack>
        </BaseCard.Header>
      </BaseCard>
    </div>
  );
}

export default Skeleton;
