import { useEffect } from 'react';
import { useParams } from 'react-router';
import EmbeddedApp, {
  AppLoading,
  AppTopBar,
} from 'domains/PartnersApps/components/EmbeddedApp';
import { useGetApp } from '../../hooks';

function AppPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { status, app, fetchApp } = useGetApp(id);

  useEffect(() => {
    fetchApp();
  }, [fetchApp]);

  if (status.isLoading || app === null) return <AppLoading />;

  const shouldHideTopbar = app.id.startsWith('social-');

  return (
    <EmbeddedApp
      id={app.id}
      url={app.url}
      topBar={shouldHideTopbar ? undefined : <AppTopBar app={app} />}
    />
  );
}

export default AppPage;
