import { Prompt } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { initialValues } from './constants';
import { newInternationalShippingSchema } from './newInternationalShippingSchema';
import useNewInternationalShipping from './useNewInternationalShipping';
import ShippingPage from '../components/ShippingPage';
import { InternationalShippingForm } from '../DeliveryMethodsPage/components';

function NewInternationalShippingPage() {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const { createInternationalShipping, status } = useNewInternationalShipping();

  const { values, handleChange, errors, handleOnSubmit, isDirty } = useForm({
    initialValues,
    validationSchema: newInternationalShippingSchema,
    onSubmit: createInternationalShipping,
  });

  useToastStatus({
    status: status,
    success: t(
      'deliveryMethods.internationalShippingMethods.toastSuccess.created',
    ),
    error: t(
      'deliveryMethods.internationalShippingMethods.toastError.creation',
    ),
    onSuccess() {
      goBack();
    },
  });

  return (
    <ShippingPage
      handleSave={handleOnSubmit}
      title={t(
        'deliveryMethods.internationalShippingMethods.newInternationalShipping.title',
      )}
      isUpdating={status === 'loading'}
      isError={status === 'error'}
    >
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />
      <InternationalShippingForm
        values={values}
        handleChange={handleChange}
        errors={errors}
      />
    </ShippingPage>
  );
}

export default NewInternationalShippingPage;
