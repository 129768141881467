import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getDeliveryMethodsState } from './deliveryMethodsSelectors';

export const getCorreiosContract = (state: RootStateType) =>
  getDeliveryMethodsState(state).correios.contract;

export const getCorreiosContractTypesStatus = (state: RootStateType) => {
  const status = getCorreiosContract(state).list.status;
  return convertStatusTypeToObject(status);
};

export const getCorreiosContractTypesData = (state: RootStateType) =>
  getCorreiosContract(state).list.data;

export const getUpdateCorreiosContractStatus = (state: RootStateType) =>
  getCorreiosContract(state).create.status;

export const getCorreiosContractData = (state: RootStateType) =>
  getCorreiosContract(state).detail.data;

export const getCorreiosShippingModalities = (state: RootStateType) =>
  getDeliveryMethodsState(state).correios.shippingModalities;

export const getCorreiosShippingModalitiesData = (state: RootStateType) =>
  getCorreiosShippingModalities(state).detail.data;

export const getCorreiosShippingModalitiesStatus = (state: RootStateType) => {
  const status = getCorreiosShippingModalities(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const getUpdateCorreiosShippingModalitiesStatus = (
  state: RootStateType,
) => getCorreiosShippingModalities(state).create.status;
