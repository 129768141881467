import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceNotificationSchema } from '../paymentNotificationSlice';

const getNotificationState = (
  state: RootStateType,
): CombinedState<InterfaceNotificationSchema> =>
  state.payments.paymentNotification;

export const getNotificationDataSelector = (state: RootStateType) =>
  getNotificationState(state).data;

export const getNotificationStatusLoadSelector = (state: RootStateType) =>
  getNotificationState(state).status;
