import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDashboard } from '../../firstSaleSlice';

const getOnboardingEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.onboarding,
);

export const getOnboardingData = createSimpleSelector(
  [getOnboardingEntity],
  (onboarding) => onboarding.data,
);

export const getOnboardingStatus = createSimpleSelector(
  [getOnboardingEntity],
  (onboarding) => convertStatusTypeToObject(onboarding.status),
);
