import { useSelector } from 'react-redux';
import {
  getPlanNiceName,
  getPlanCommercialName,
  getPlanId,
  getPlanTransactionFee,
  getPlanName,
  getIsNext,
  getIsEvolucion,
  getIsFreePlan,
  getHasTransactionFeesForMX,
} from '../../authSlice/authSelectors';

interface useGetPlanResult {
  name: string;
  niceName: string;
  commercialName: string;
  transactionFee: number;
  planId: string;
  isNext: boolean;
  isEvolucion: boolean;
  isFreePlan: boolean;
  hasTransactionFeesForMx: boolean;
}

function useGetPlan(): useGetPlanResult {
  const name = useSelector(getPlanName);
  const niceName = useSelector(getPlanNiceName);
  const commercialName = useSelector(getPlanCommercialName);
  const transactionFee = useSelector(getPlanTransactionFee);
  const isNext = useSelector(getIsNext);
  const isFreePlan = useSelector(getIsFreePlan);
  const isEvolucion = useSelector(getIsEvolucion);
  const planId = useSelector(getPlanId);
  const hasTransactionFeesForMx = useSelector(getHasTransactionFeesForMX);

  return {
    name,
    niceName,
    commercialName,
    transactionFee,
    planId,
    isNext,
    isEvolucion,
    isFreePlan,
    hasTransactionFeesForMx,
  };
}

export default useGetPlan;
