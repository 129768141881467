import { CATALOG_FULL_EVENTS } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';

function useTrackFullCatalog() {
  const tag = useGetTags();
  const sendEvent = (tracking: () => void) => {
    if (tag.includes(CATALOG_FULL_EVENTS)) {
      tracking();
    }
  };
  return sendEvent;
}

export default useTrackFullCatalog;
