import { Box, Icon, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';

export function ErrorBox({ errorMessage }) {
  return (
    <Box display="inline-flex" gap="1">
      <Icon
        color="danger-textLow"
        source={<ExclamationCircleIcon size={12} />}
      />
      <Text color="danger-textLow" fontSize="caption">
        {errorMessage}
      </Text>
    </Box>
  );
}
