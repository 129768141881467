import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Stack, Modal } from '@tiendanube/components';
import axios from 'App/axios';
import { useModal, useToast, useScript } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import { getAccessToken } from 'domains/Auth/authSlice/authSelectors';

const REQUIRE_PASSWORD_RESET_SCRIPT_URL = `${
  import.meta.env.VITE_API_BFF
}/auth/require-password-reset-script`;

declare global {
  interface Window {
    RequirePasswordReset: {
      init: (
        config: Record<string, string>,
        callback: (value: boolean) => void,
      ) => void;
      destroy: () => void;
      isInitialized: boolean;
      setIsModalOpen: (open: boolean) => void;
    };
  }
}

function RequirePasswordReset(): JSX.Element | null {
  const t = useTranslationAccount();
  const [showModal, openModal, closeModal] = useModal();
  const [submitting, setSubmitting] = useState(false);
  const { addToast } = useToast();
  const accessToken = useSelector(getAccessToken);
  const scriptStatus = useScript(REQUIRE_PASSWORD_RESET_SCRIPT_URL);

  useEffect(() => {
    if (
      scriptStatus === 'ready' &&
      accessToken &&
      !window.RequirePasswordReset?.isInitialized
    ) {
      window.RequirePasswordReset?.init(
        { accessToken, bffUrl: import.meta.env.VITE_API_BFF || '' },
        (isPasswordResetRequired) => {
          if (!isPasswordResetRequired) {
            return;
          }

          window.RequirePasswordReset?.setIsModalOpen(true);
          openModal();
        },
      );
    }

    return () => {
      window.RequirePasswordReset?.destroy();
    };
  }, [scriptStatus, accessToken, openModal]);

  const handleCloseModal = () => {
    window.RequirePasswordReset?.setIsModalOpen(false);
    closeModal();
  };

  const handleSendEmail = async () => {
    setSubmitting(true);
    try {
      await axios.post('/v1/account/start-password-reset');
      addToast({
        label: t('requirePasswordReset.successMessage'),
        appearance: 'success',
      });
    } catch (e) {
      addToast({
        label: t('requirePasswordReset.errorMessage'),
        appearance: 'danger',
      });
    } finally {
      setSubmitting(false);
      handleCloseModal();
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      title={t('requirePasswordReset.modal.title')}
      show
      onDismiss={handleCloseModal}
      portal
      primaryAction={{
        children: `${t('requirePasswordReset.modal.confirmButton')}`,
        onClick: handleSendEmail,
        appearance: 'primary',
        disabled: submitting,
      }}
      secondaryAction={{
        children: `${t('requirePasswordReset.modal.cancelButton')}`,
        onClick: handleCloseModal,
      }}
    >
      <Stack column>
        <Text>{t('requirePasswordReset.modal.firstParagraph')}</Text>
        <Text>
          <Trans
            t={t}
            i18nKey="requirePasswordReset.modal.secondParagraph"
            components={{ bold: <strong /> }}
          />
        </Text>
      </Stack>
    </Modal>
  );
}

export default RequirePasswordReset;
