import { Tooltip, Box } from '@nimbus-ds/components';
import { Icon as IconType } from '@tiendanube/icons';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

import './CircleIcon.scss';

interface CircleIconProps {
  icon: IconType;
  paymentIntents?: number;
}
function CircleIcon({ icon: Icon, paymentIntents }: CircleIconProps) {
  const t = useTranslationOrders();

  const content =
    paymentIntents && paymentIntents > 0
      ? t('abandonedCarts.tooltip.hasPayment', {
          count: paymentIntents,
        })
      : t('abandonedCarts.tooltip.opportunity');

  return (
    <Box flexWrap="wrap" display="flex" marginLeft="2">
      <Tooltip content={content} position="right">
        <div className="circle-icon">
          <Icon />
        </div>
      </Tooltip>
    </Box>
  );
}

export default CircleIcon;
