import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationMetafields(): TFunction {
  const { t } = useTranslation(Domain.METAFIELDS);

  return t;
}

export default useTranslationMetafields;
