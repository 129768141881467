import { Box, BoxProps, Text } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Tooltip } from '@tiendanube/components';
import Skeleton from './Skeleton';

export const STYLE_STATS_DAILY_VALUE_CARD: BoxProps = {
  boxShadow: '2',
  backgroundColor: 'neutral-background',
  borderColor: 'neutral-surface',
  borderStyle: 'solid',
  borderRadius: 'base',
  borderWidth: '1',
  padding: '2',
  display: 'flex',
  gap: '1',
  flexDirection: 'column',
};

interface StatsDailyValueCardProps {
  title: string;
  value: string;
  textTooltip?: string;
}

function StatsDailyValueCard({
  value,
  title,
  textTooltip,
}: StatsDailyValueCardProps) {
  return (
    <Box {...STYLE_STATS_DAILY_VALUE_CARD}>
      <Box display="flex" gap="2">
        <Text>{title}</Text>
        {!!textTooltip && (
          <Tooltip ariaLabel={textTooltip} labelIcon={InfoCircleIcon}>
            <Text fontSize="caption" color="neutral-surface">
              {textTooltip}
            </Text>
          </Tooltip>
        )}
      </Box>
      <Text fontSize="highlight">{value}</Text>
    </Box>
  );
}

StatsDailyValueCard.Skeleton = Skeleton;

export default StatsDailyValueCard;
