import {
  CogIcon,
  CreditCardIcon,
  DownloadIcon,
  LifeRingIcon,
  RocketIcon,
  StarIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { MenuButtonProps } from '@nimbus-ds/menubutton';
import { generatePath } from 'react-router';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { BE_APP_SUBSCRIPTION_ENABLED } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import goToAdmin from 'commons/utils/gotToAdmin';
import { openWindow } from 'commons/utils/window';
import { useGetStoreRemainingDaysToExpiration } from 'domains/Auth/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import useRedirectOnSimilarCharge from 'domains/Billing/Checkout/hooks/useRedirectOnSimilarCharge';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import useCreateAppCharge from 'domains/PartnersApps/pages/AppsListPage/hooks/useCreateAppCharge';
import { setAppIdToUninstall } from 'domains/PartnersApps/partnersAppsSlice';

function useAppsListActions(app: AppsListItemResponseDto) {
  const dispatch = useAppDispatch();
  const { t } = useTranslationPartnerApps('appsListPage.actions');
  const { goTo } = useNavegate();
  const [appSubscriptionEnabled] = useHasTags(BE_APP_SUBSCRIPTION_ENABLED);
  const { handleGoToPlans } = useGoToPlans();
  const remainingDaysToExpiration = useGetStoreRemainingDaysToExpiration();
  const { createAppCharge, isLoading } = useCreateAppCharge();
  const { status, handleFunctionBehaviour } = useRedirectOnSimilarCharge(
    (id) => createAppCharge(id),
    CHECKOUT_ROUTES.plansAndSubscriptions,
  );

  const handleOpenExternal = (url: string) => () => openWindow(url, true);
  const openSettings = () =>
    app.isNative
      ? goTo(`/apps/${app.id}`)
      : openWindow(app.settingsUrl || '', true);

  const handleClickInstall = (id: string) =>
    goTo(
      generatePath(partnersAppsRoutes.authorization, {
        id,
      }),
    );

  const handlePaySubscription = (id: string, shouldCreateCharge: boolean) => {
    if (appSubscriptionEnabled) {
      if (shouldCreateCharge) {
        if (isLoading) return;
        handleFunctionBehaviour(id).then(() => {
          goTo(CHECKOUT_ROUTES.plansAndSubscriptions);
        });
      } else {
        goTo(CHECKOUT_ROUTES.plansAndSubscriptions);
      }
    } else {
      goToAdmin(`apps/${id}/subscription`)();
    }
  };

  const allActions = {
    upgradePlan: {
      label: t('upgradePlan'),
      startIcon: RocketIcon,
      onClick: handleGoToPlans,
    },
    install: {
      label: t('install'),
      startIcon: DownloadIcon,
      onClick: () => handleClickInstall(app.id),
    },
    startTrial: {
      label: t('startTrial'),
      startIcon: DownloadIcon,
      onClick: () => handleClickInstall(app.id),
    },
    support: {
      label: t('support'),
      startIcon: LifeRingIcon,
      onClick: handleOpenExternal(
        app.supportUrl || `mailto:${app.supportEmail}`,
      ),
    },
    rate: {
      label: t('rate'),
      startIcon: StarIcon,
      onClick: handleOpenExternal(app.ratingUrl),
    },
    startSubscription: {
      label: t('startSubscription'),
      startIcon: CreditCardIcon,
      onClick: () => {
        handlePaySubscription(app.id, app.disabledInStore);
      },
    },
    renewSubscription: {
      label: t('renewSubscription'),
      startIcon: CreditCardIcon,
      onClick: () => {
        handlePaySubscription(app.id, app.disabledInStore);
      },
    },
    buy: {
      label: t('buy'),
      startIcon: CreditCardIcon,
      onClick: goToAdmin(`apps/${app.id}/subscription`),
    },
    settings: {
      label: t('settings'),
      startIcon: CogIcon,
      onClick: openSettings,
    },
    uninstall: {
      label: t('uninstall'),
      startIcon: TrashIcon,
      onClick: () => {
        dispatch(setAppIdToUninstall(app.id));
      },
    },
  };

  const getAppActions = (): MenuButtonProps[] => {
    const actions: MenuButtonProps[] = [];
    const {
      supportUrl,
      supportEmail,
      installed,
      payable,
      wasPaidForBefore,
      needSubscription,
      settingsUrl,
      handle,
      trial,
      daysRemaining,
      installable,
      availableForStorePlan,
    } = app;
    const appDaysRemaining = daysRemaining ?? 0;
    const storeDaysRemaining = remainingDaysToExpiration ?? 0;

    if (!availableForStorePlan) actions.push(allActions.upgradePlan);
    if (supportUrl || supportEmail) actions.push(allActions.support);
    actions.push(allActions.rate);
    if (installed) {
      if (
        payable &&
        (appSubscriptionEnabled || appDaysRemaining < storeDaysRemaining)
      ) {
        if (wasPaidForBefore) actions.push(allActions.renewSubscription);
        else if (needSubscription)
          actions.push(
            needSubscription ? allActions.startSubscription : allActions.buy,
          );
      }
      if (settingsUrl) actions.push(allActions.settings);
      if (handle !== 'nuvempago') actions.push(allActions.uninstall);
    } else {
      if (payable)
        actions.push(
          needSubscription ? allActions.startSubscription : allActions.buy,
        );
      if (installable)
        actions.push(trial ? allActions.startTrial : allActions.install);
    }

    return actions;
  };

  return {
    getAppActions,
    status,
  };
}

export default useAppsListActions;
