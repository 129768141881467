import { Locale } from 'App/i18n';

export const BASE_URL = 'account/security/session-management';

export const RESULTS_PER_PAGE_MOBILE = '200';
export const RESULTS_PER_PAGE_DESKTOP = '200';

export const ICON_SIZE = 'small';

export const INACTIVITY_LIMIT_TIME_IN_DAYS = 10;

type LocalesLinksType = Partial<Record<Locale, string>>;
export const HELP_LINKS: LocalesLinksType = {
  'es-AR':
    'https://ayuda.tiendanube.com/es_AR/como-ver-y-administrar-los-accesos-a-mi-cuenta-de-tiendanube',
  'es-CL':
    'https://ayuda.tiendanube.com/es_ES/como-ver-y-administrar-los-accesos-a-mi-cuenta-de-tiendanube',
  'es-CO':
    'https://ayuda.tiendanube.com/es_CO/como-ver-y-administrar-los-accesos-a-mi-cuenta-de-tiendanube',
  'es-MX':
    'https://ayuda.tiendanube.com/es_MX/como-ver-y-administrar-los-accesos-a-mi-cuenta-de-tiendanube',
  'pt-BR':
    'https://atendimento.nuvemshop.com.br/pt_BR/seguranca-e-acessos/como-visualizar-e-gerenciar-os-acessos-minha-conta-nuvemshop?utm_source=newadmin&utm_medium=account&utm_campaign=CAN',
};
