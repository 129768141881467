import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useStatisticsFilterContext } from 'domains/Statistics/hooks';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getShippingSalesByProvinceData,
  getShippingSalesByProvinceStatus,
} from 'domains/Statistics/selectors';
import { fetchShippingSalesByProvince } from 'domains/Statistics/statisticsSlice';

function useGetShippingSalesByProvince() {
  const dispatch = useAppDispatch();
  const { openFilter } = useStatisticsFilterContext();
  const salesByProvince = useSelector(getShippingSalesByProvinceData);
  const status = useSelector(getShippingSalesByProvinceStatus);

  const fetchSalesByProvince = useCallback(() => {
    dispatch(fetchShippingSalesByProvince());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchSalesByProvince);

  return {
    openFilter,
    fetchSalesByProvince,
    salesByProvince,
    ...status,
  };
}

export default useGetShippingSalesByProvince;
