import { Box } from '@nimbus-ds/components';
import { PaginationStratus } from 'commons/components';
import { useFreeShippingDesktop } from 'domains/Marketing/FreeShipping/hooks';
import FreeShippingListTable from './FreeShippingListTable';
import { FreeShippingListPage } from '../../components';

export function FreeShippingListDesktopPage() {
  const {
    pagination,
    changePage,
    showSkeleton,
    data,
    isDataEmpty,
    isError,
    refetch,
  } = useFreeShippingDesktop();

  return (
    <FreeShippingListPage
      skeleton={<FreeShippingListTable.Skeleton />}
      isLoading={showSkeleton}
      isEmpty={isDataEmpty}
      isError={isError}
      onRetry={refetch}
    >
      <Box flexDirection="column" gap="2" display="flex">
        <FreeShippingListTable data={data} />
        <PaginationStratus
          currentPage={pagination.currentPage}
          totalItems={pagination.totalResults}
          itemName="items"
          page={pagination.currentPage}
          perPage={pagination.perPage}
          onPageSelect={changePage}
        />
      </Box>
    </FreeShippingListPage>
  );
}
