import * as billingPlanService from './billingPlanService';
import * as firstSaleService from './firstSaleService';
import * as onboardingService from './onboardingService';
import * as paymentsMessagesService from './paymentsMessagesService';
import * as socialMessagesService from './socialMessagesService';
import * as trialMessagesService from './trialMessagesService';
import * as usersService from './usersService';

export default {
  ...firstSaleService,
  ...onboardingService,
  ...usersService,
  ...trialMessagesService,
  ...billingPlanService,
  ...paymentsMessagesService,
  ...socialMessagesService,
};
