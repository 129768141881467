import { useEffect } from 'react';
import { InteractiveList } from '@nimbus-ds/patterns';
import { useNavegate } from 'App/hooks';
import { useToastStatus } from 'commons/hooks';
import { CONTENT_PAGES_ROUTES } from 'domains/Online/ContentPages/contentPageRoutes';
import { useTemplatesContent } from 'domains/Online/ContentPages/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import InteractiveListSkeleton from '../InteractiveListSkeleton';

interface TemplateListProps {
  onClose: () => void;
}

function TemplateList({ onClose }: TemplateListProps) {
  const t = useTranslationOnline('contentPages.pageList');
  const { goTo } = useNavegate();
  const { status, templates, getTemplates } = useTemplatesContent();

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  useToastStatus({
    error: t('errorTemplates'),
    onError: onClose,
    status,
  });

  const handleClick = (id: string) => {
    goTo(`${CONTENT_PAGES_ROUTES.pageCreation}?templateId=${id}`);
  };

  if (status === 'loading' || !templates?.length) {
    return <InteractiveListSkeleton />;
  }

  return (
    <InteractiveList bottomDivider>
      {templates.map(({ id, title, description }) => (
        <InteractiveList.ButtonItem
          key={id}
          title={title}
          description={description}
          iconButton={{
            onClick: () => {
              handleClick(id);
            },
          }}
        />
      ))}
    </InteractiveList>
  );
}

export default TemplateList;
