import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  GetDocumentSettingsResponseDto,
  UpdateDocumentSettingsRequestDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getDocumentSettings,
  updateDocumentSettings,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';
import {
  getDocumentSettingsData,
  getDocumentSettingsStatus,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice/fulfillmentOrdersSelectors';

export interface UseDocumentSettingsResult {
  documentSettings?: GetDocumentSettingsResponseDto;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  fetchDocumentSettings: () => void;
  updateSetting: (params: UpdateDocumentSettingsRequestDto) => Promise<void>;
}

function useDocumentSettings(): UseDocumentSettingsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getDocumentSettingsStatus,
  );

  const documentSettings = useSelector(getDocumentSettingsData);

  const fetchDocumentSettings = useCallback(
    async () => dispatch(getDocumentSettings()),
    [dispatch],
  );

  const updateSetting = useCallback(
    async (setting) => {
      dispatch(updateDocumentSettings(setting));
    },
    [dispatch],
  );

  return {
    documentSettings,
    isLoading,
    isSuccess,
    isError,
    fetchDocumentSettings,
    updateSetting,
  };
}

export default useDocumentSettings;
