import { FEATURE_USER_PERMISSIONS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useForm, useToastStatus } from 'commons/hooks';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import {
  useCreateUserMutation,
  useGetRolesQuery,
} from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { validationSchema } from './validationSchema';
import { NEW_USER_INITIAL_VALUES } from '../../constants';
import UserForm from '../UserForm';

function NewUserForm() {
  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const [createUser, { status, error: requestError }] = useCreateUserMutation();
  const { isAvailable: isRolesEditAvailable } = useStatusFeatureByKey(
    FEATURE_USER_PERMISSIONS,
  );

  const {
    data: storeRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch,
  } = useGetRolesQuery();

  const {
    values,
    errors,
    isDirty,
    setFieldValue,
    handleChange,
    handleValidations,
    handleOnSubmit,
  } = useForm({
    initialValues:
      NEW_USER_INITIAL_VALUES[
        isRolesEditAvailable ? 'editAvailable' : 'editUnavailable'
      ],
    validationSchema,
    onSubmit: createUser,
  });

  const createStatus = toStatusType(status);
  const requestErrors =
    (requestError as any)?.data?.response?.description || {};

  useToastStatus({
    status: createStatus,
    success: t('toast.success'),
    error: requestErrors?.description?.email
      ? t(`errors.emailAlreadyTaken`)
      : t('toast.editError'),
    onSuccess: goBack,
  });

  return (
    <UserForm
      isLoading={isRolesLoading}
      isError={isRolesError}
      isDirty={isDirty}
      values={values}
      errors={errors}
      requestErrors={requestErrors}
      submitStatus={createStatus}
      storeRoles={storeRoles}
      onChange={handleChange}
      onChangeRole={setFieldValue}
      onRefetch={refetch}
      onSubmit={handleOnSubmit}
      onValidate={handleValidations}
    />
  );
}

export default NewUserForm;
