import { Tabs } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import PaymentProviderDesktop from '../PaymentProviderDesktop';

const TRANSLATE_PREFIX = 'paymentProviders';

function Skeleton() {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const renderPaymentProviders = () => (
    <Stack column>
      <PaymentProviderDesktop.Skeleton />
      <PaymentProviderDesktop.Skeleton />
      <PaymentProviderDesktop.Skeleton />
      <PaymentProviderDesktop.Skeleton />
      <PaymentProviderDesktop.Skeleton />
    </Stack>
  );

  return (
    <Tabs preSelectedTab={0}>
      <Tabs.Item label={t('desktopTabs.all')}>
        {renderPaymentProviders()}
      </Tabs.Item>
      <Tabs.Item label={t('desktopTabs.enabled')}>
        {renderPaymentProviders()}
      </Tabs.Item>
      <Tabs.Item label={t('desktopTabs.disabled')}>
        {renderPaymentProviders()}
      </Tabs.Item>
      <Tabs.Item label={t('desktopTabs.pending')}>
        {renderPaymentProviders()}
      </Tabs.Item>
    </Tabs>
  );
}

export default Skeleton;
