import { ReactNode } from 'react';
import { Stack } from 'commons/components';
import MethodNameAndImage from '../MethodNameAndImage';

interface MethodItemProps {
  name: string;
  image: string;
  children: ReactNode;
  label?: string;
  blockRight?: ReactNode;
}

function MethodItem({
  name,
  image,
  label,
  blockRight,
  children,
}: MethodItemProps) {
  return (
    <Stack column align="stretch">
      <Stack justify="space-between" align="flex-start">
        <MethodNameAndImage name={name} image={image} label={label} />
        {blockRight}
      </Stack>
      <Stack wrap spacing="tight">
        {children}
      </Stack>
    </Stack>
  );
}

MethodItem.Skeleton = MethodNameAndImage.Skeleton;

export default MethodItem;
