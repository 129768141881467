import { Box, IconButton, Tag, Text } from '@nimbus-ds/components';
import { DataList } from '@tiendanube/components';
import { Repeater, Stack } from 'commons/components';

function ItemSkeleton({ index }): JSX.Element {
  const { Row, Cell } = DataList;

  return (
    <Row id={`skeleton-${index}`}>
      <Cell width="fill" rowTitle>
        <Stack column align="flex-start" spacing="tight">
          <Text.Skeleton width="150px" />
          <Text.Skeleton width="100px" />
          <Tag.Skeleton height="1rem" />
        </Stack>
      </Cell>
      <Cell width="auto">
        <Box width="fit-content">
          <Stack spacing="tight">
            <IconButton.Skeleton height="2rem" width="2rem" />
            <IconButton.Skeleton height="2rem" width="2rem" />
          </Stack>
        </Box>
      </Cell>
    </Row>
  );
}

function UsersListMobileSkeleton() {
  return (
    <DataList skeleton>
      <Repeater item={(index) => <ItemSkeleton index={index} />} />
    </DataList>
  );
}

export default UsersListMobileSkeleton;
