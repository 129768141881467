import { useEffect, useRef, ReactNode } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { v4 as uuidv4 } from 'uuid';
import ReactPortal from '../ReactPortal/ReactPortal';

import './Modal.scss';

export interface ModalProps {
  children: ReactNode;
  handleClose?: () => void;
  className?: string;
}

function Modal({
  children,
  handleClose,
  className = '',
}: ModalProps): JSX.Element {
  const uniqueId = useRef(uuidv4());

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? handleClose?.() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  return (
    <ReactPortal wrapperId={`modal-portal-${uniqueId.current}`}>
      <div className={className}>
        <div className="modal-background" onClick={handleClose} />
        <RemoveScroll forwardProps>
          <div className="modal">{children}</div>
        </RemoveScroll>
      </div>
    </ReactPortal>
  );
}
export default Modal;
