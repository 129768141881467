import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import { CurrencyType } from '@tiendanube/common';
import { CurrencyPrice, InputNumberNimbus } from 'commons/components';
import { useBoolean, useModal } from 'commons/hooks';
import { callWhenPress } from 'commons/transformers';
import { Variant } from 'domains/Catalog/Products/pages/components/Variants/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { OrderEditProduct } from '../../../../types';
import './EditOrderProductQuantity.scss';

interface EditOrderProductQuantityProps {
  variantDetails?: Variant;
  fetchVariant: () => void;
  orderProduct: OrderEditProduct;
  currency: CurrencyType;
  onSubmit: (editedProduct: OrderEditProduct) => void;
  editable: boolean;
}

export function EditOrderProductQuantity({
  variantDetails,
  fetchVariant,
  orderProduct,
  currency,
  onSubmit,
  editable,
}: Readonly<EditOrderProductQuantityProps>) {
  const t = useTranslationOrders();
  const [quantity, setQuantity] = useState(orderProduct.quantity);
  const [isFocused, setFocused, setNotFocused] = useBoolean(false);
  const [showModal, openModal, closeModal] = useModal();

  const maxStock = useMemo(() => {
    const inventoryLevelStock =
      variantDetails?.inventory_levels?.find(
        (inventoryLevel) =>
          inventoryLevel.location_id === orderProduct.locationId,
      )?.stock ?? variantDetails?.stock;

    return inventoryLevelStock !== undefined && inventoryLevelStock !== null
      ? Number(inventoryLevelStock) + (orderProduct.originalQuantity ?? 0)
      : null;
  }, [orderProduct, variantDetails]);

  const isInsuficcientStock = !!maxStock && quantity > maxStock;

  const onFocused = useCallback(() => {
    if (!variantDetails) {
      fetchVariant();
    }
    setFocused();
  }, [fetchVariant, setFocused, variantDetails]);

  const handleSetQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.currentTarget.value));
  };

  const submitEditedProduct = useCallback(() => {
    const editedProduct: OrderEditProduct = {
      ...orderProduct,
      quantity,
      totalPrice: orderProduct.price * quantity,
      maxStock,
    };
    setNotFocused();
    onSubmit(editedProduct);
  }, [onSubmit, orderProduct, quantity, setNotFocused, maxStock]);

  const onModalConfirm = () => {
    submitEditedProduct();
    closeModal();
  };

  const onProductQuantityEdit = useCallback(() => {
    if (quantity === 0) {
      openModal();
    } else {
      submitEditedProduct();
    }
  }, [quantity, openModal, submitEditedProduct]);

  useEffect(() => {
    if (orderProduct.editType === 'delete') {
      setQuantity(orderProduct.originalQuantity ?? orderProduct.quantity);
    }
  }, [orderProduct]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" paddingY="1">
        <Box display="flex" flexDirection="row" maxWidth="65px">
          <div className="stratus--edit-order-product-quantity">
            <InputNumberNimbus
              value={quantity}
              appearance={isInsuficcientStock ? 'danger' : 'neutral'}
              type="integer"
              onChange={handleSetQuantity}
              maxLength={5}
              onFocus={onFocused}
              onBlur={onProductQuantityEdit}
              onKeyDown={callWhenPress('Enter', onProductQuantityEdit)}
              disabled={!editable || orderProduct.editType === 'delete'}
            />
          </div>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="1"
        >
          <Text color="neutral-textLow">x</Text>
          <Box paddingLeft="1" paddingRight="2">
            <CurrencyPrice
              price={orderProduct.price}
              currency={currency}
              appearance="light"
            />
          </Box>
        </Box>
      </Box>
      {(isFocused || isInsuficcientStock) &&
        (variantDetails ? (
          <Text
            color={
              isInsuficcientStock ? 'danger-interactive' : 'neutral-textLow'
            }
            fontSize="caption"
          >
            {t(
              `editOrders.productEditor.${
                maxStock ? 'maxStock' : 'infiniteStock'
              }`,
              { stock: maxStock },
            )}
          </Text>
        ) : (
          <Text.Skeleton width="70px" />
        ))}

      <Modal open={showModal} onDismiss={closeModal}>
        <Modal.Header title={t('editOrders.productEditor.modalTitle')} />
        <Modal.Body padding="none">
          <Text>{t('editOrders.productEditor.modalBody')}</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>
            {t('editOrders.productEditor.modalButtonCancel')}
          </Button>
          <Button appearance="primary" onClick={onModalConfirm}>
            {t('editOrders.productEditor.modalButtonConfirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Box>
  );
}
