import { createSelector } from '@reduxjs/toolkit';
import { CombinedState } from 'redux';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { ContentPagesInterface } from '../types';
import { initialContentPageValues } from '../utils';

const getContentPagesSelectors = (
  state: RootStateType,
): CombinedState<ContentPagesInterface> => state.online.pages;

export const getAllPagesListStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).allPagesList.status;

export const getAllPagesListDataSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).allPagesList.data;

export const getPagesListStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pagesList.status;

export const getPagesListRefreshStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pagesList.refreshing;

export const getPagesListIdsSelector = createSelector(
  getContentPagesSelectors,
  ({ pagesList }) => pagesList.data?.ids || [],
);

export const getPagesListResultsSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pagesList.data?.results || [];

export const getPagesListPaginationSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pagesList.data?.pagination;

export const getTemplatesStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).templatesList.status;

export const getTemplatesDataSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).templatesList.data;

export const getPageDetailSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageDetail.status;

export const getPageDetailDataSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageDetail.data;

export const createPageStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageCreation.status;

export const createPageIdSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageCreation.data;

export const updatePageByIdStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageUpdate.status;

export const updatePageByIdDataSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageUpdate.data;

export const getPageDeleteStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).pageDelete.status;

export const getPagesIdSelector = createSelector(
  getContentPagesSelectors,
  ({ pageDetail }: ContentPagesInterface) => pageDetail.data?.id || '',
);

export const getPagesByIdSelector = createSelector(
  getContentPagesSelectors,
  ({ pageDetail }: ContentPagesInterface) => pageDetail.data,
);

export const getTemplateByIdStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).templateById.status;

export const getTemplateIdStatusSelector = (state: RootStateType) =>
  getContentPagesSelectors(state).templateById.data?.id;

const getTemplateById = createSimpleSelector(
  [getContentPagesSelectors],
  (contentPages) => contentPages.templateById,
);

export const getTemplateByIdDataSelector = createSelector(
  getTemplateById,
  (templateById) => ({
    ...initialContentPageValues,
    ...(templateById.data && {
      title: templateById.data.title,
    }),
    ...(templateById.data && {
      content: templateById.data.content,
    }),
  }),
);
