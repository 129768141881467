import { CalloutCard } from '@tiendanube/components';
import {
  StarIcon,
  MarketingIcon,
  DiscountCircleIcon,
  ClockIcon,
  RocketIcon,
  TagIcon,
  Icon,
} from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { SectionCodeType } from 'domains/Online';
import { CodeIconType } from '../../../../types';

interface SectionCalloutCardProps {
  code: SectionCodeType;
  name: string;
  total: number;
}

const ICONS: Record<CodeIconType, Icon> = {
  primary: StarIcon,
  new: MarketingIcon,
  sale: DiscountCircleIcon,
  offer: MarketingIcon,
  recent: ClockIcon,
  coming: RocketIcon,
  slider: TagIcon,
  custom: StarIcon,
};

function SectionCalloutCard({ code, name, total }: SectionCalloutCardProps) {
  const t = useTranslationCatalog();
  const { goTo } = useNavegate();

  const iconCode = code in ICONS ? code : 'custom';

  const handleOnClick = () => {
    goTo(`/products/featured?section=${code}`);
  };
  return (
    <CalloutCard
      icon={ICONS[iconCode]}
      appearance="primary"
      title={name}
      subtitle={t(
        'products.organizeProducts.sectionCalloutCard.totalproducts',
        { count: total },
      )}
      onClick={handleOnClick}
    />
  );
}

export default SectionCalloutCard;
