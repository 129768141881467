import { memo } from 'react';
import { DragDotsIcon } from '@nimbus-ds/icons';
import './DragIcon.scss';

function DragIcon() {
  return (
    <div className="stratus--drag-icon">
      <DragDotsIcon />
    </div>
  );
}

export default memo(DragIcon);
