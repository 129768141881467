import { Link, Box, Badge } from '@nimbus-ds/components';
import { IconProps } from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import { useTranslationBilling } from 'domains/Billing';

interface SupportCardEntryProps {
  title: string;
  icon: React.FC<IconProps>;
  count?: number;
  showUpsell?: boolean;
  onClick: () => void;
}

export function SupportCardEntry({
  title,
  icon,
  count = 0,
  showUpsell = false,
  onClick,
}: Readonly<SupportCardEntryProps>) {
  const t = useTranslationBilling();

  return (
    <Box
      position="relative"
      key={title}
      data-style="show-button-on-hover-wrapper"
    >
      <Menu.Button startIcon={icon} label={title} onClick={onClick}>
        {count > 0 && <Badge appearance="primary" count={count} />}
        {showUpsell && (
          <Link
            as="div"
            appearance="primary"
            fontSize="caption"
            textDecoration="none"
            lineHeight="caption"
          >
            {t('supportChanels.changePlan')}
          </Link>
        )}
      </Menu.Button>
    </Box>
  );
}
