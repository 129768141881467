import { useState } from 'react';
import { Spinner } from 'commons/components';
import { STATS_SHIPPING_PAGE } from 'config/upsellFlowSources';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import { STATS_PLAN_3 } from 'domains/Statistics/utils';
import useGetShippingSalesByProvince from './useGetShippingSalesByProvince';
import ChartOrPlaceholder from '../ChartOrPlaceholder';
import DoubleCardResponsive from '../DoubleCardResponsive';
import GenericEmptySearch from '../GenericEmptySearch';
import GenericError from '../GenericError';
import RankByProvinceChart from '../RankByProvinceChart';
import StatisticsProvincesMap from '../StatisticsProvincesMap';

import 'leaflet/dist/leaflet.css';

function ShippingSalesByProvinceCard() {
  const t = useTranslationStatistics();
  const [visible, setVisible] = useState('');
  const {
    fetchSalesByProvince,
    salesByProvince,
    isLoading,
    isError,
    isSuccess,
    openFilter,
  } = useGetShippingSalesByProvince();

  const handleOnMouseOver = (id: string) => {
    setVisible(id);
  };

  const handleOnMouseLeave = () => setVisible('');

  const filtered = visible
    ? salesByProvince?.coordinates.filter((province) => province.id === visible)
    : salesByProvince?.coordinates;

  return (
    <DoubleCardResponsive
      title={t('statistics.salesByProvinceCard.title')}
      left={
        <ChartOrPlaceholder
          type="bars-horizontal"
          statsPlanRequired={STATS_PLAN_3}
          upsellSource={STATS_SHIPPING_PAGE}
        >
          {isLoading && <Spinner size="medium" />}
          {isError && <GenericError onRetry={fetchSalesByProvince} />}
          {isSuccess && salesByProvince?.results.length === 0 && (
            <GenericEmptySearch onRetry={openFilter} />
          )}
          {isSuccess &&
            salesByProvince &&
            salesByProvince?.results.length > 0 && (
              <RankByProvinceChart
                onMouseOver={handleOnMouseOver}
                onMouseLeave={handleOnMouseLeave}
                provincesRanking={salesByProvince.results}
              />
            )}
        </ChartOrPlaceholder>
      }
      right={<StatisticsProvincesMap provinces={filtered} />}
    />
  );
}

export default ShippingSalesByProvinceCard;
