import { useCallback } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';

function useTranslationAuthenticationFactor(
  translatePrefix?: string,
): TFunction {
  const { t } = useTranslation(Domain.AUTHENTICATION_FACTOR);
  const getTranslatedText = useCallback(
    (key: string, options) =>
      key.startsWith('common:')
        ? t(key, options)
        : t(`${translatePrefix}.${key}`, options),
    [translatePrefix, t],
  );

  return translatePrefix ? (getTranslatedText as TFunction) : t;
}

export default useTranslationAuthenticationFactor;
