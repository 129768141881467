import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_NAVIGATION_CLICK,
  MENU_SECONDARY_PAGES_CLICK,
  MENU_SECONDARY_PASSWORD_PROTECTED_CLICK,
  MENU_SECONDARY_PREFERENCES_CLICK,
  MENU_SECONDARY_THEMES_CLICK,
  MENU_SECONDARY_BLOG_CLICK,
} from 'config/trackingEvents';

export const menuSecondaryThemesClick = () => {
  logEvent(MENU_SECONDARY_THEMES_CLICK, {});
};

export const menuSecondaryPreferencesClick = () => {
  logEvent(MENU_SECONDARY_PREFERENCES_CLICK, {});
};

export const menuSecondaryPasswordProtectedClick = () => {
  logEvent(MENU_SECONDARY_PASSWORD_PROTECTED_CLICK, {});
};

export const menuSecondaryPagesClick = () => {
  logEvent(MENU_SECONDARY_PAGES_CLICK, {});
};

export const menuSecondaryNavigationClick = () => {
  logEvent(MENU_SECONDARY_NAVIGATION_CLICK, {});
};

export const menuSecondaryBlogClick = () => {
  logEvent(MENU_SECONDARY_BLOG_CLICK, {});
};
