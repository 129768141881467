import { Text, Thumbnail } from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function AppsListItemSkeleton() {
  return (
    <Stack spacing="tight" align="flex-start">
      <Thumbnail.Skeleton width="48px" />
      <Stack.Item fill>
        <Stack column align="flex-start" spacing="tight">
          <Text.Skeleton width="120px" />
          <Text.Skeleton width="200px" />
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default AppsListItemSkeleton;
