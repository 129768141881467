import { AbandonedCartDetailsResponseDto } from '@tiendanube/common';
import { Title } from '@tiendanube/components';
import { Layout, useResponsive } from 'commons/components';
import { useStoreName } from 'domains/Auth/hooks';
import {
  CustomerDataCard,
  DateAndDevice,
  DetailsHeader,
  OrderSummaryCard,
} from 'domains/Orders/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import BillingAddressCard from '../BillingAddressCard';
import { CardPayment } from '../CardPayment';
import DeliveryInfoCard from '../DeliveryInfoCard';
import SendEmailAbandonedCartCard from '../SendEmailAbandonedCartCard';
import ShareAbandonedCartCard from '../ShareAbandonedCartCard';

interface AbandonedCartDetailsProps {
  abandonedCartDetails: AbandonedCartDetailsResponseDto;
}

function AbandonedCartDetails({
  abandonedCartDetails,
}: AbandonedCartDetailsProps) {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();
  const nameStore = useStoreName();

  const customerIsAnonymized = abandonedCartDetails.customer.isAnonymized;
  const showPaymentCard =
    !!abandonedCartDetails.paymentInfo.gateway &&
    !!abandonedCartDetails.paymentInfo.paymentCount;

  const email = {
    to: abandonedCartDetails.customer.email,
    subject: t('abandonedCarts.sendEmail.subject', {
      count: abandonedCartDetails.products.length,
    }),
    body: t('abandonedCarts.sendEmail.body', {
      nameClient: abandonedCartDetails.customer.name,
      nameStore,
    }),
    url: abandonedCartDetails.url,
    signature: t('abandonedCarts.sendEmail.signature', { nameStore }),
  };

  const whatsapp = {
    phoneNumber: abandonedCartDetails.customer.phone,
    message: t('abandonedCarts.sendEmail.body', {
      nameClient: abandonedCartDetails.customer.name,
      nameStore,
      interpolation: { escapeValue: false },
    }),
    url: abandonedCartDetails.url,
    signature: t('abandonedCarts.sendEmail.signature', {
      nameStore,
      interpolation: { escapeValue: false },
    }),
  };

  return (
    <Layout
      mainContent
      right={
        <>
          {isDesktop && (
            <Title type="h2">{t('abandonedCarts.detail.asideTitle')}</Title>
          )}
          <CustomerDataCard
            id={abandonedCartDetails.customer.id}
            name={abandonedCartDetails.customer.name}
            email={email}
            whatsapp={whatsapp}
            identification={abandonedCartDetails.customer.identification}
            isAnonymized={customerIsAnonymized}
          />
          <DeliveryInfoCard
            deliveryData={abandonedCartDetails.shippingInfo}
            url={abandonedCartDetails.url}
          />
          <BillingAddressCard
            billingAddressData={abandonedCartDetails.billingAddress}
          />
        </>
      }
      left={
        <>
          <DetailsHeader
            title={t('abandonedCarts.detail.mainTitle')}
            right={<DateAndDevice date={abandonedCartDetails.date} />}
          />
          <ShareAbandonedCartCard
            ulrAbanadonedCart={abandonedCartDetails.url}
            phoneCustomer={abandonedCartDetails.customer.phone}
            nameClient={abandonedCartDetails.customer.name}
            nameStore={nameStore}
          />
          {!customerIsAnonymized && (
            <SendEmailAbandonedCartCard
              customerId={abandonedCartDetails.customer.id}
              email={email}
            />
          )}
          {showPaymentCard && (
            <CardPayment abandonedCart={abandonedCartDetails} />
          )}
          <OrderSummaryCard
            amounts={abandonedCartDetails.amounts}
            products={abandonedCartDetails.products}
            currency={abandonedCartDetails.currency}
          />
        </>
      }
    />
  );
}

export default AbandonedCartDetails;
