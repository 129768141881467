import { useSelector } from 'react-redux';
import { CustomersOrdersResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getCustomerOrderById } from 'domains/Customers/Customers/customersSlice/customersSelectors';

function useCustomerOrderListItem(
  id: string,
): CustomersOrdersResponseDto | undefined {
  const customerOrderListItem = useSelector((state: RootStateType) =>
    getCustomerOrderById(state, id),
  );

  return customerOrderListItem;
}

export default useCustomerOrderListItem;
