import { Box, Tag, Title, Text } from '@nimbus-ds/components';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetPlanExpiresIn } from 'domains/Auth/hooks';
import {
  useGetLastPayment,
  useGetRecurrentPaymentConcepts,
} from 'domains/Billing/Checkout/hooks';
import { useStoreStatusTitle } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PaymentStatusTag from '../../../PaymentStatusTag';

function SubscriptionHeader() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps',
  );
  const expiresIn = useGetPlanExpiresIn();
  const title = useStoreStatusTitle();
  const { hasPayment, paymentStatus } = useGetLastPayment('plan-cost');
  const { recurrentPaymentForPlanActivated, ...getRecurrentConcepts } =
    useGetRecurrentPaymentConcepts();
  const { isError } = combineStatus(getRecurrentConcepts.status);

  const shouldShowStoreInTrialTag =
    (expiresIn ?? 0) > 0 && !hasPayment && !recurrentPaymentForPlanActivated;

  return (
    <Box display="flex" flexDirection="column" gap="2" marginBottom="4">
      <Title as="h3">{title}</Title>
      {shouldShowStoreInTrialTag && (
        <Tag appearance="neutral">
          <Text fontSize="caption">
            {t('tags.storeInTrial', { count: expiresIn })}
          </Text>
        </Tag>
      )}
      {!isError && (
        <Box display="flex" flexDirection="row" gap="2">
          {recurrentPaymentForPlanActivated && (
            <Tag appearance="neutral">
              <Text fontSize="caption">
                {t('tags.recurrentPaymentActivated')}
              </Text>
            </Tag>
          )}
          <PaymentStatusTag paymentStatus={paymentStatus} />
        </Box>
      )}
    </Box>
  );
}

export default SubscriptionHeader;
