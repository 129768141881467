import ResultRowDesktopSkeleton from '../ResultRowDesktopSkeleton';

function OrderResultsDesktopSkeleton(): JSX.Element {
  return (
    <>
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
      <ResultRowDesktopSkeleton />
    </>
  );
}

export default OrderResultsDesktopSkeleton;
