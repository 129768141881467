import { IconButton, Text, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack, useResponsive } from 'commons/components';

function PhysicalLocationsSkeleton() {
  const { isMobile } = useResponsive();
  return (
    <DataList bottomDivider={false}>
      <DataList.Row>
        <Stack justify="space-between">
          <Stack column align="flex-start" spacing="none">
            <Title.Skeleton as="h5" />
            <Text.Skeleton
              fontSize="caption"
              width={isMobile ? '150px' : '300px'}
            />
          </Stack>
          <Stack spacing="tight" justify="flex-end">
            <IconButton.Skeleton />
            <IconButton.Skeleton />
          </Stack>
        </Stack>
      </DataList.Row>
    </DataList>
  );
}

export default PhysicalLocationsSkeleton;
