import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  MessagesResponseDto,
  QueryParamsRequestDto,
  ResultPaginationResponseDto,
  UnreadMessagesCountDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { logout } from 'domains/Auth';
import { getMessagesPagination } from './messagesSelectors';
import { MessagesInterface, statusType } from './types';
import messagesServices from '../messagesServices';

const paginationDefault = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0,
  perPage: 20,
  nextPage: null,
};

const initialState: MessagesInterface = {
  status: statusType.idle,
  refreshStatus: statusType.idle,
  exportStatus: statusType.idle,
  currentRequestID: '',
  ids: [],
  entities: {},
  pagination: paginationDefault,
  filters: {
    page: '1',
    q: '',
  },
  unreadMessagesCount: 0,
};

type ThunkStateType = { state: RootStateType };

export const getMessagesList = createAsyncThunk<
  ResultPaginationResponseDto<MessagesResponseDto[]>,
  QueryParamsRequestDto
>(
  'messages/getMessagesList',
  async (
    filters,
  ): Promise<ResultPaginationResponseDto<MessagesResponseDto[]>> => {
    const response = await messagesServices.getMessages(filters);
    return response;
  },
);

export const getMoreMessagesList = createAsyncThunk<
  ResultPaginationResponseDto<MessagesResponseDto[]>,
  undefined,
  ThunkStateType
>(
  'messages/getMoreMessagesList',
  async (
    _,
    thunkApi,
  ): Promise<ResultPaginationResponseDto<MessagesResponseDto[]>> => {
    const state = thunkApi.getState();
    const pagination = getMessagesPagination(state);

    if (!pagination.nextPage) {
      throw new Error('no valid fetch');
    }
    const response = await messagesServices.getMessages({
      page: pagination.nextPage.toString(),
    });
    return response;
  },
);

export const removeMessage = createAsyncThunk<void, string>(
  'messages/removeMessage',
  async (id) => {
    await messagesServices.removeMessage(id);
  },
);

export const readMessage = createAsyncThunk<void, string>(
  'messages/readMessage',
  async (id) => {
    await messagesServices.readMessage(id);
  },
);

export const unreadMessage = createAsyncThunk<void, string>(
  'messages/unreadMessage',
  async (id) => {
    await messagesServices.unreadMessage(id);
  },
);

export const spamMessage = createAsyncThunk<void, string>(
  'messages/spamMessage',
  async (id) => {
    await messagesServices.spamMessage(id);
  },
);

export const deleteMessages = createAsyncThunk<void, string[]>(
  'messages/deleteMessages',
  async (ids) => {
    await messagesServices.actionsMessage(ids, 'delete');
  },
);

export const readMessages = createAsyncThunk<void, string[]>(
  'messages/readMessages',
  async (ids) => {
    await messagesServices.actionsMessage(ids, 'read');
  },
);

export const unreadMessages = createAsyncThunk<void, string[]>(
  'messages/unreadMessages',
  async (ids) => {
    await messagesServices.actionsMessage(ids, 'not_read');
  },
);

export const spamMessages = createAsyncThunk<void, string[]>(
  'messages/spamMessages',
  async (ids) => {
    await messagesServices.actionsMessage(ids, 'spam');
  },
);

export const getUnreadMessagesCountAction = createAsyncThunk(
  'messages/getUnreadMessagesCount',
  messagesServices.getUnreadMessagesCount,
);

export const refreshMessages = createAsyncThunk<
  {
    messages: ResultPaginationResponseDto<MessagesResponseDto[]>;
    unreadMessagesCount: UnreadMessagesCountDto;
  },
  QueryParamsRequestDto,
  ThunkStateType
>('messages/refreshMessages', async (filters) => {
  const [messages, unreadMessagesCount] = await Promise.all([
    messagesServices.getMessages(filters),
    messagesServices.getUnreadMessagesCount(),
  ]);
  return { messages, unreadMessagesCount };
});

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    changeFilters(state, action) {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder
      .addCase(getMessagesList.fulfilled, (state, action) => {
        const ids: string[] = [];
        state.entities = action.payload.results.reduce((acc, message) => {
          acc[message.id] = message;
          ids.push(message.id);
          return acc;
        }, {});
        state.ids = ids;
        state.status = statusType.success;
        state.pagination = action.payload.pagination;
      })
      .addCase(getMessagesList.pending, (state, action) => {
        state.status = statusType.loading;
        state.currentRequestID = action.meta.requestId;
      })
      .addCase(getMessagesList.rejected, (state, action) => {
        state.status = statusType.error;
        state.currentRequestID = action.meta.requestId;
      });

    builder
      .addCase(getMoreMessagesList.fulfilled, (state, action) => {
        const ids: string[] = [];
        state.entities = action.payload.results.reduce((acc, cuopons) => {
          acc[cuopons.id] = cuopons;
          ids.push(cuopons.id);
          return acc;
        }, state.entities);
        state.ids = [...state.ids, ...ids];
        state.refreshStatus = statusType.success;
        state.status = statusType.success;
        state.pagination = action.payload.pagination;
      })
      .addCase(getMoreMessagesList.pending, (state, action) => {
        state.refreshStatus = statusType.refresh;
        state.currentRequestID = action.meta.requestId;
      })
      .addCase(getMoreMessagesList.rejected, (state) => {
        state.refreshStatus = statusType.error;
      });

    builder
      .addCase(removeMessage.fulfilled, (state, action) => {
        delete state.entities[action.meta.arg];
        state.ids = state.ids.filter((id) => id !== action.meta.arg);
        state.status = statusType.success;
        state.refreshStatus = statusType.success;
      })
      .addCase(readMessage.fulfilled, (state, action) => {
        state.entities[action.meta.arg].replied = true;
      })
      .addCase(unreadMessage.fulfilled, (state, action) => {
        state.entities[action.meta.arg].replied = false;
      })
      .addCase(spamMessage.fulfilled, (state, action) => {
        state.ids = state.ids.filter((id) => id !== action.meta.arg);
        delete state.entities[action.meta.arg];
      });

    builder
      .addCase(deleteMessages.fulfilled, (state, action) => {
        action.meta.arg.forEach((id) => {
          delete state.entities[id];
        });
        state.ids = state.ids.filter((id) => !action.meta.arg.includes(id));
      })
      .addCase(readMessages.fulfilled, (state, action) => {
        action.meta.arg.forEach((id) => {
          state.entities[id].replied = true;
        });
      })
      .addCase(unreadMessages.fulfilled, (state, action) => {
        action.meta.arg.forEach((id) => {
          state.entities[id].replied = false;
        });
      })
      .addCase(spamMessages.fulfilled, (state, action) => {
        action.meta.arg.forEach((id) => {
          delete state.entities[id];
        });
        state.ids = state.ids.filter((id) => !action.meta.arg.includes(id));
      });

    builder
      .addCase(refreshMessages.pending, (state, action) => {
        state.currentRequestID = action.meta.requestId;
      })
      .addCase(refreshMessages.fulfilled, (state, action) => {
        const ids: string[] = [];
        state.entities = action.payload.messages.results.reduce(
          (acc, message) => {
            acc[message.id] = message;
            ids.push(message.id);
            return acc;
          },
          {},
        );
        state.ids = ids;
        state.refreshStatus = statusType.success;
        state.pagination = action.payload.messages.pagination;
        state.unreadMessagesCount = action.payload.unreadMessagesCount.count;
      })
      .addCase(refreshMessages.rejected, (state) => {
        state.refreshStatus = statusType.error;
      });

    builder.addCase(getUnreadMessagesCountAction.fulfilled, (state, action) => {
      state.unreadMessagesCount = action.payload.count;
    });
  },
});

export const { reducer } = messages;
export const { changeFilters } = messages.actions;
