import { Icon, Link } from '@nimbus-ds/components';
import { AppsIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { MARKETING_APP_STORE_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationMarketing from 'domains/Marketing/useTranslationMarketing';

function MarketingAppsCalloutCard() {
  const t = useTranslationMarketing('marketingApps');
  const language = useTranslationLanguage();

  return (
    <CalloutCard
      title={t('calloutCard.title')}
      subtitle={t('calloutCard.subtitle')}
      appearance="neutral"
      icon={AppsIcon}
      link={
        <Link
          appearance="primary"
          fontSize="caption"
          textDecoration="none"
          as="a"
          href={MARKETING_APP_STORE_LINKS[language]}
          target="_blank"
        >
          {t('calloutCard.link')}
          <Icon color="currentColor" source={<ExternalLinkIcon />} />
        </Link>
      }
    />
  );
}

export default MarketingAppsCalloutCard;
