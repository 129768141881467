enum Domain {
  ORDERS = "orders",
  CATALOG = "catalog",
  CUSTOMERS = "customers",
  MARKETING = "marketing",
  NUBEKEYBOARD = "nubekeyboard",
  DASHBOARD = "dashboard",
  STATISTICS = "statistics",
  SHIPPING = "shipping",
  BILLING = "billing",
  PAYMENTS = "payments",
  AUTHENTICATION_FACTOR = "authenticationFactor",
  PARTNERS_APPS = "partnersApps",
  ACCOUNT = "account",
  METAFIELDS = "metafields",
  FULFILLMENT_ORDERS = "fulfillmentOrders",
  ONLINE = "online",
  CONFIGURATIONS = "configurations",
  SALES_CHANNELS = "salesChannels",
}

export default Domain;
