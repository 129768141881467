import { ChangeEvent } from 'react';
import { Box, Modal, Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import useEnabledCountries from 'domains/Configurations/hooks/useEnabledCountries';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import Skeleton from './Skeleton';

interface MainCountryInterface {
  countryId: string;
  currencyId: string;
  languageId: string;
}

interface SelectsListProps {
  handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  mainCountry: MainCountryInterface;
  errors: Partial<Record<string, string>>;
}
function SelectsList({ handleChange, mainCountry, errors }: SelectsListProps) {
  const t = useTranslationConfigurations('languagesAndCurrencies');

  const { countriesList, currenciesList, languagesList } =
    useEnabledCountries();

  return (
    <Modal.Body padding="none">
      <Box display="grid" paddingTop="2" gridGap="3">
        <FormField.Select
          id="countries"
          label={t('enabledCountryModal.labelCountry')}
          name="countryId"
          onChange={handleChange}
          defaultValue={mainCountry.countryId}
          appearance={errors.countryId ? 'danger' : 'none'}
          helpText={errors.countryId ? t('helpText') : ''}
          showHelpText
        >
          {countriesList?.map(({ id, countryName }) => (
            <Select.Option key={id} label={countryName} value={id} />
          ))}
        </FormField.Select>
        <FormField.Select
          id="currencies"
          label={t('enabledCountryModal.labelCurrency')}
          name="currencyId"
          onChange={handleChange}
          defaultValue={mainCountry.currencyId}
        >
          {currenciesList?.map(({ currencyId, currencyName }) => (
            <Select.Option
              key={currencyId}
              label={currencyName}
              value={currencyId}
            />
          ))}
        </FormField.Select>
        <FormField.Select
          id="languages"
          label={t('enabledCountryModal.labelLanguage')}
          name="languageId"
          onChange={handleChange}
          defaultValue={mainCountry.languageId}
        >
          {languagesList?.map(({ languageId, languageName }) => (
            <Select.Option
              key={languageId}
              value={languageId}
              label={languageName}
            />
          ))}
        </FormField.Select>
      </Box>
    </Modal.Body>
  );
}

SelectsList.Skeleton = Skeleton;

export default SelectsList;
