import * as Yup from 'yup';
// TODO: define strategic location for this service
import { validateZipCode } from 'domains/Orders/DraftOrders/draftOrdersService';

export const deliveryAdressSchema = Yup.object().shape({
  zipcode: Yup.string().test(
    'zipcode-is-valid',
    'editDeliveryAdressModal.deliveryAdressCard.zipCodeError',
    async (value) => {
      if (!value) return true;
      try {
        const response = await validateZipCode(value);
        return response;
      } catch (e) {
        return false;
      }
    },
  ),
});
