import { Text } from '@tiendanube/components';
import { useTranslationAccount } from 'domains/Account/hooks';
import { trackingRecoveryCodesStartActivation } from 'domains/Account/tracking';
import AuthenticationFactorActivationCard from './AuthenticationFactorActivationCard';

interface RecoveryCodesActivationCardProps {
  active: boolean;
  allCodesUsed: boolean;
  onContinue: () => void;
}

function RecoveryCodesActivationCard({
  active,
  allCodesUsed,
  onContinue,
}: RecoveryCodesActivationCardProps) {
  const t = useTranslationAccount(
    'authenticationFactorPage.recoveryCodesActivationCard',
  );

  const inactiveTextTranslation = allCodesUsed ? 'allCodesUsed' : 'inactive';

  return (
    <AuthenticationFactorActivationCard
      title={t('title')}
      activeDescription={t('active.description')}
      inactiveDescription={
        <Text>{t(`${inactiveTextTranslation}.description`)}</Text>
      }
      activeActionText={t('active.action')}
      inactiveActionText={t(`${inactiveTextTranslation}.action`)}
      active={active}
      onContinue={() => {
        if (!active) trackingRecoveryCodesStartActivation();
        onContinue();
      }}
    />
  );
}

export default RecoveryCodesActivationCard;
