import { Select } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

import './SelectItem.scss';

function SelectItem({
  metafield,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const t = useTranslationsMetafields();
  const optionSelect = placeholder || t('select.one');

  const addOptionSelect = metafield
    ? [optionSelect, ...(metafield.values || [])]
    : [];

  const options = addOptionSelect.map((item, index) => (
    <Select.Option key={index} label={item} value={item} />
  ));

  const handleChange = ({ target: { value } }) => {
    const selectValue = value === optionSelect ? '' : value;
    onChange?.(selectValue);
    onUpdate?.(selectValue || null);
  };

  const metafieldSelected = value === '' ? optionSelect : value;

  return (
    <div className="select-item-wrapper">
      <Select
        value={metafieldSelected}
        onChange={handleChange}
        id={metafield.name}
        name={metafield.name}
        disabled={disabled !== undefined ? disabled : metafield.disabled}
      >
        {options}
      </Select>
    </div>
  );
}

export default SelectItem;
