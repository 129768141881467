import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { InvoiceDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useToast } from 'commons/hooks';
import downloadByUri from 'commons/utils/downloadByUri';
import { openWindow } from 'commons/utils/window';
import {
  getInvoiceDownloadLink,
  getInvoiceLinkStatus,
} from 'domains/Billing/Invoices/invoicesSlice';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function useDownloadInvoice() {
  const dispatch = useAppDispatch();
  const status = useSelector(getInvoiceLinkStatus);
  const t = useTranslationBilling();
  const { addToast } = useToast();

  useEffect(() => {
    if (status === 'error') {
      addToast({
        label: t('invoiceHistory.errors.download'),
        appearance: 'danger',
      });
    }
  }, [status, addToast, t]);

  const getLink = async ({
    invoice: { isFromBillingEngine, isCreditNote, id, associatedInvoiceId },
  }: InvoiceDto) => {
    if (!isFromBillingEngine) {
      const path =
        window.location.origin +
        (isCreditNote
          ? `/admin/account/invoices/${associatedInvoiceId}/note/print/`
          : `/admin/account/invoices/${id}/print/`);
      downloadByUri(path);
    } else {
      const linkAction = await dispatch(getInvoiceDownloadLink(id));
      openWindow(unwrapResult(linkAction).url, true);
    }
  };

  return {
    getLink,
    status,
  };
}

export default useDownloadInvoice;
