import { useToastProgress } from 'App/components/ToastProgressContext';
import { useToast } from 'commons/hooks';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface UseActionsToastResult {
  onErrorPrintManager: () => void;
  onSuccessPrintManager: () => void;
  onLoadingPrintManager: () => void;
}

function useActionsToast(isMultiple: boolean): UseActionsToastResult {
  const t = useTranslationFulfillmentOrders();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const onErrorPrintManager = () => {
    closeToastProgress();
    addToast({
      label: t(
        isMultiple
          ? 'printManager.actionErrorPlural'
          : 'printManager.actionError',
      ),
      appearance: 'danger',
    });
  };

  const onSuccessPrintManager = () => {
    closeToastProgress();
    addToast({
      label: t(
        isMultiple
          ? 'printManager.actionSuccessPlural'
          : 'printManager.actionSuccess',
      ),
      appearance: 'success',
    });
  };

  const onLoadingPrintManager = () => {
    addToastProgress({
      label: t(
        isMultiple
          ? 'printManager.actionLoadingPlural'
          : 'printManager.actionLoading',
      ),
    });
  };

  return {
    onErrorPrintManager,
    onSuccessPrintManager,
    onLoadingPrintManager,
  };
}

export default useActionsToast;
