import {
  FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_CONFIG,
  FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER,
} from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';

export default function useVariantsMetafieldsAccess() {
  const { isAvailable: isMetafieldAllowedFilters } = useStatusFeatureByKey(
    FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER,
  );

  const { isAvailable: isMetafieldAllowedConfig } = useStatusFeatureByKey(
    FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_CONFIG,
  );

  const isMetafieldFiltersEnabled = isMetafieldAllowedFilters;

  const isMetafieldConfigEnabled = isMetafieldAllowedConfig;

  return {
    isMetafieldFiltersEnabled,
    isMetafieldConfigEnabled,
  };
}
