import { useTranslation } from 'react-i18next';
import {
  Input,
  Stack,
  InterfaceNameValue,
  InterfaceInput,
} from '@tiendanube/components';

type AppearanceType = InterfaceInput['appearance'];

interface DateFromToProps {
  from: string;
  to: string;
  appearanceFrom?: AppearanceType;
  appearanceTo?: AppearanceType;
  nameFrom?: string;
  nameTo?: string;
  maxFrom?: string;
  maxTo?: string;
  minFrom?: string;
  minTo?: string;
  disabledFrom?: boolean;
  disabledTo?: boolean;
  onChange: (e: InterfaceNameValue) => void;
}

function DateFromTo({
  from,
  to,
  onChange,
  nameFrom = 'dateFrom',
  nameTo = 'dateTo',
  disabledFrom = false,
  disabledTo = false,
  maxFrom,
  maxTo,
  minFrom,
  minTo,
  appearanceFrom,
  appearanceTo,
}: DateFromToProps): JSX.Element {
  const { t } = useTranslation('common');
  return (
    <Stack spacing="tight">
      <Stack.Item fill>
        <Input
          label={t('dateFromTo.from')}
          name={nameFrom}
          type="date"
          value={from}
          max={maxFrom}
          min={minFrom}
          onChange={onChange}
          disabled={disabledFrom}
          appearance={appearanceFrom}
        />
      </Stack.Item>
      <Stack.Item fill>
        <Input
          label={t('dateFromTo.to')}
          name={nameTo}
          type="date"
          value={to}
          min={minTo}
          max={maxTo}
          onChange={onChange}
          disabled={disabledTo}
          appearance={appearanceTo}
        />
      </Stack.Item>
    </Stack>
  );
}

export default DateFromTo;
