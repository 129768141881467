import * as Yup from 'yup';

const customPaymentDetail = Yup.object().shape({
  name: Yup.string().test(
    'nameRequired',
    'customPayment.error.name',
    (_, { parent: { active, name } }) => (active && name !== '') || !active,
  ),
  instructions: Yup.string().test(
    'instructionsRequired',
    'customPayment.error.instructions',
    (_, { parent: { active, instructions } }) =>
      (active && instructions !== '') || !active,
  ),
  percentage: Yup.string().test(
    'percentageRequired',
    'customPayment.error.percentage',
    (_, { parent: { active, percentage } }) =>
      (active &&
        (percentage === '' ||
          (Number(percentage) >= 0 && Number(percentage) <= 100))) ||
      !active,
  ),
  allowDiscountCombination: Yup.boolean(),
});

export const validationSchema = Yup.object().shape({
  wireTransfer: customPaymentDetail,
  cash: customPaymentDetail,
  pix: customPaymentDetail,
  other: customPaymentDetail,
});
