import { Label, Radio } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { Categories, Products } from 'domains/Marketing/components';
import { Scope } from 'domains/Marketing/enums';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { PromotionFormProps } from '../PromotionForm';

interface ScopeSelectorProps
  extends Pick<
    PromotionFormProps,
    'values' | 'errors' | 'onChange' | 'onChangeCategories' | 'onChangeProducts'
  > {
  disabled?: boolean;
}

export function ScopeSelector({
  values,
  errors,
  disabled = false,
  onChange,
  onChangeCategories,
  onChangeProducts,
}: Readonly<ScopeSelectorProps>) {
  const t = useTranslationMarketing('promotions.settingsPage.applyTo');
  const errorCategories = errors['categories'] ? t(errors['categories']) : '';
  const errorProducts = errors['products'] ? t(errors['products']) : '';

  const { scopeType, categories, products } = values;

  return (
    <>
      <Stack spacing="tight">
        <Radio
          id="scope-type-1"
          name="scopeType"
          as="button"
          value="all"
          checked={scopeType === 'all'}
          label={t('all.title')}
          onChange={onChange}
          disabled={scopeType !== 'all' && disabled}
        />
        <Radio
          id="scope-type-2"
          name="scopeType"
          as="button"
          value="categories"
          checked={scopeType === 'categories'}
          label={t('categories.title')}
          onChange={onChange}
          disabled={scopeType !== 'categories' && disabled}
        />
        <Radio
          id="scope-type-3"
          name="scopeType"
          as="button"
          value="products"
          checked={scopeType === 'products'}
          label={t('products.title')}
          onChange={onChange}
          disabled={scopeType !== 'products' && disabled}
        />
      </Stack>
      {scopeType === Scope.ALL && <Label>{t('all.description')}</Label>}
      {scopeType === Scope.CATEGORIES && (
        <Stack column align="stretch">
          <Stack.Item>
            <Label>{t('categories.description')}</Label>
          </Stack.Item>
          <Stack.Item>
            <Categories
              categories={categories}
              requiredText={errorCategories}
              title={t('categories.title')}
              subtitle={t('categories.subtitle')}
              emptyTitle={t('categories.empty.title')}
              emptyText={t('categories.empty.text')}
              ctaText={t('categories.empty.ctaText')}
              onChange={onChangeCategories}
              showTitle={false}
              selectLinkText={t('categories.selector.select')}
              editLinkText={t('categories.selector.edit')}
            />
          </Stack.Item>
        </Stack>
      )}
      {scopeType === Scope.PRODUCTS && (
        <>
          <Label>{t('products.description')}</Label>
          <Products
            products={products}
            requiredText={errorProducts}
            title={t('products.title')}
            showTitle={false}
            subtitle={t('products.subtitle')}
            emptyTitle={t('products.empty.title')}
            emptyText={t('products.empty.text')}
            ctaText={t('products.empty.ctaText')}
            onChange={onChangeProducts}
            selectLinkText={t('products.selector.select')}
            editLinkText={t('products.selector.edit')}
          />
        </>
      )}
    </>
  );
}
