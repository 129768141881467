import { Box, Table } from '@nimbus-ds/components';
import { Repeater } from 'commons/components';
import { FreeShippingListHeader } from './FreeShippingListHeader';
import FreeShippingListRow from './FreeShippingListRow';

const rowSkeleton = () => <FreeShippingListRow.Skeleton />;

export function FreeShippingListTableSkeleton() {
  return (
    <Box flexDirection="column" gap="2" display="flex">
      <Table>
        <FreeShippingListHeader />
        <Table.Body>
          <Repeater times={5} item={rowSkeleton} />
        </Table.Body>
      </Table>
    </Box>
  );
}
