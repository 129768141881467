import { Alert, Button, Link, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { useGoToThemesStore } from '../hooks';

interface UpgradePlanAlertProps {
  show: boolean;
}

export function UpgradePlanAlert({ show }: Readonly<UpgradePlanAlertProps>) {
  const t = useTranslationOnline('themesPage.planAlert');
  const { handleGoToPlans } = useGoToPlans();
  const handleClickViewThemes = useGoToThemesStore();

  return (
    <Alert show={show} appearance="primary" title={t('title')}>
      <Stack column align="flex-start">
        <Text>{t('subtitle')}</Text>
        <Stack>
          <Button onClick={handleGoToPlans}>{t('upgrade')}</Button>
          <Link onClick={handleClickViewThemes}>{t('viewThemes')}</Link>
        </Stack>
      </Stack>
    </Alert>
  );
}
