import {
  Checkbox,
  InterfaceNameChecked,
  Stack,
  Text,
} from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

export const TRANSLATE_PREFIX = 'advancedSettingsModal.externalCheckout';

export interface ExternalCheckoutCardValueInterface {
  id: string;
  name: string;
  enabled: boolean;
  deactivationBlocked: boolean;
  deactivationBlockedReason: string;
}

interface ExternalCheckoutCardProps {
  redirect: ExternalCheckoutCardValueInterface[];
  onChange: (event: InterfaceNameChecked) => void;
}

function ExternalCheckoutCard({
  redirect,
  onChange,
}: ExternalCheckoutCardProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  return (
    <CardWithTitle title={t('title')}>
      <Stack column align="stretch">
        <Text>{t('subtitle')}</Text>
        {redirect?.map(
          ({
            id,
            name,
            enabled,
            deactivationBlocked,
            deactivationBlockedReason,
          }) => {
            const blockedReason = deactivationBlockedReason
              ? t('deactivationBlockedReason.mercado_envios_active')
              : '';
            const label = `${t('allow')} ${name} ${blockedReason}`;
            return (
              <Checkbox
                key={id}
                label={label}
                name={id}
                checked={enabled}
                disabled={deactivationBlocked}
                onChange={onChange}
              />
            );
          },
        )}
      </Stack>
    </CardWithTitle>
  );
}

export default ExternalCheckoutCard;
