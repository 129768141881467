import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';
import {
  CalendarIcon,
  DesktopIcon,
  InvoiceIcon,
  MobileIcon,
  StoreIcon,
} from '@tiendanube/icons';
import { Stack } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';

import './DateAndDevice.scss';

interface DateAndDeviceProps {
  date: string;
  origin?: string;
  orderOrigin?: string;
  initiatedBy?: string;
}

function DateAndDevice({
  date,
  origin,
  orderOrigin,
  initiatedBy,
}: DateAndDeviceProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  const getOriginIcon = () => {
    if (origin === 'mobile') return MobileIcon;
    if (origin === 'form') return InvoiceIcon;
    if (origin === 'pos') return StoreIcon;

    return DesktopIcon;
  };

  const getOriginLabel = (origin: string) => {
    let label = t(`origin.${origin}`, origin);

    if (origin === 'pos' && initiatedBy) {
      label += ` ${t('origin.by')} ${initiatedBy}`;
    }

    return orderOrigin || label;
  };

  return (
    <div className="stratus--order-details__date-and-device">
      <Stack spacing="tight">
        <Stack.Item>
          <Label
            appearance="transparent"
            id="date"
            icon={CalendarIcon}
            label={dateFormat(date, Format.DAY_HOUR_MINUTE)}
          />
        </Stack.Item>
        {!!origin && (
          <Stack.Item>
            <Label
              appearance="transparent"
              id="origin"
              icon={getOriginIcon()}
              label={getOriginLabel(origin)}
            />
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
}

export default DateAndDevice;
