import { Card, ErrorState } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface CardTimelineErrorStateProps {
  onReTry: () => void;
}

function CardTimelineErrorState({
  onReTry,
}: CardTimelineErrorStateProps): JSX.Element {
  const t = useTranslationOrders();
  return (
    <Card title={t('cardTimeline.title')}>
      <ErrorState
        title={t('cardTimeline.error.title')}
        action={{ onClick: onReTry, children: t('cardTimeline.error.retry') }}
      />
    </Card>
  );
}

export default CardTimelineErrorState;
