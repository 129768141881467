import {
  CancelRecurrentPaymentRequestDto,
  AssertStoreResponseDto,
  ChargeDto,
  ChargesListDto,
  ConceptCode,
  ExecutePaymentResponseDto,
  LastPaymentBillingEngineDto,
  MpPreferenceRequestDto,
  MpPreferenceResponseDto,
  PayOrderCreatedDto,
  PayWithCreditCardDto,
  PaymentMethodsResponseDto,
  RecurrentPaymentChargesDto,
  RecurrentPaymentConceptsDto,
  SubscriptionsListResponseDto,
  UpdatePlanChargeRequestDto,
  WireTransferDataResponseAr,
  PaymentBillingEngineDto,
  DownloadInvoiceResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  checkoutEndpoints,
  checkoutTags,
  DELETE_RECURRENT_PAYMENT,
  DELETE_SUBSCRIPTION,
  GET_LAST_PAYMENT,
  GET_PAY_ORDER,
  GET_PAYMENT_METHODS,
  GET_PENDING_CHARGES,
  GET_RECURRENT_PAYMENT_CONCEPTS,
  GET_STORE_SUBSCRIPTION_ENABLED,
  GET_SUBSCRIPTIONS,
  GET_WIRE_TRANSFER_DATA,
  PATCH_PLAN_CHARGE,
  POST_PAYMENT_CREDIT_CARD,
  POST_CREATE_PAY_ORDER,
  POST_CREATE_MP_PREFERENCE,
  POST_CREATE_ADVANCED_PLAN_CHARGE,
  POST_UPLOAD_PAYMENT_PROOF,
  CHECKOUT_API_CACHE_TIME,
  GET_RECURRENT_PAYMENT_CHARGE_DATA,
  GET_LAST_PAID_CHARGES,
  POST_ASSERT_STORE,
  GET_CHARGE_DETAILS,
  GET_PAYMENT_HISTORY_FOR_CONCEPT,
  GET_DOWNLOAD_INVOICE,
} from './config';

export const checkoutApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: Object.values(checkoutTags),
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [checkoutEndpoints.getSubscriptions]: builder.query<
        SubscriptionsListResponseDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.subscriptions],
        query: () => ({
          method: 'GET',
          url: GET_SUBSCRIPTIONS,
        }),
      }),
      [checkoutEndpoints.getStoreSubscriptionEnabled]: builder.query<
        boolean,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.storeSubscriptionEnabled],
        query: () => ({
          method: 'GET',
          url: GET_STORE_SUBSCRIPTION_ENABLED,
        }),
      }),
      [checkoutEndpoints.getRecurrentPaymentConcepts]: builder.query<
        RecurrentPaymentConceptsDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.recurrentPaymentConcepts],
        query: () => ({
          method: 'GET',
          url: GET_RECURRENT_PAYMENT_CONCEPTS,
        }),
      }),
      [checkoutEndpoints.getPendingCharges]: builder.query<
        ChargesListDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.pendingCharges],
        query: () => ({
          method: 'GET',
          url: GET_PENDING_CHARGES,
        }),
      }),
      [checkoutEndpoints.getPaymentMethods]: builder.query<
        PaymentMethodsResponseDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.paymentMethods],
        query: () => ({
          method: 'GET',
          url: GET_PAYMENT_METHODS,
        }),
      }),
      [checkoutEndpoints.getWireTransferData]: builder.query<
        WireTransferDataResponseAr,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.wireTransferData],
        query: () => ({
          method: 'GET',
          url: GET_WIRE_TRANSFER_DATA,
        }),
      }),
      [checkoutEndpoints.getPayOrderToPay]: builder.query<
        PayOrderCreatedDto,
        string
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: (_result, _error, id) => [
          { type: checkoutTags.payOrderToPay, id },
        ],
        query: (id) => ({
          method: 'GET',
          url: `${GET_PAY_ORDER}/${id}`,
        }),
      }),
      [checkoutEndpoints.getChargeDetails]: builder.query<ChargeDto, string>({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: (_result, _error, id) => [
          { type: checkoutTags.chargeDetails, id },
        ],
        query: (id) => ({
          method: 'GET',
          url: `${GET_CHARGE_DETAILS}/${id}`,
        }),
      }),
      [checkoutEndpoints.getLastPaymentData]: builder.query<
        LastPaymentBillingEngineDto,
        { concept: ConceptCode; externalReference?: string | null }
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: (_response, _error, { concept, externalReference }) => [
          {
            type: checkoutTags.lastPaymentData,
            id: externalReference ? `${concept}_${externalReference}` : concept,
          },
        ],
        query: ({ concept, externalReference }) => ({
          method: 'GET',
          url: `${GET_LAST_PAYMENT}/${concept}`,
          params: externalReference ? { externalReference } : {},
        }),
      }),
      [checkoutEndpoints.getRecurrentPaymentChargeData]: builder.query<
        RecurrentPaymentChargesDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.recurrentPaymentChargesData],
        query: () => ({
          method: 'GET',
          url: GET_RECURRENT_PAYMENT_CHARGE_DATA,
        }),
      }),
      [checkoutEndpoints.getLastPaidCharges]: builder.query<
        ChargesListDto,
        void
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: [checkoutTags.lastPaidCharges],
        query: () => ({
          method: 'GET',
          url: GET_LAST_PAID_CHARGES,
        }),
      }),
      [checkoutEndpoints.getPaymentHistoryForConcept]: builder.query<
        PaymentBillingEngineDto[],
        { concept: ConceptCode; externalReference?: string }
      >({
        keepUnusedDataFor: CHECKOUT_API_CACHE_TIME,
        providesTags: (_result, _error, { concept, externalReference }) => [
          {
            type: checkoutTags.paymentHistoryForConcept,
            id: `${concept}-${externalReference}`,
          },
        ],
        query: ({ concept, externalReference }) => ({
          method: 'GET',
          url: getUrlWithParams(GET_PAYMENT_HISTORY_FOR_CONCEPT, { concept }),
          params: externalReference ? { externalReference } : {},
        }),
      }),
      [checkoutEndpoints.deleteRecurrentPayment]: builder.mutation<
        void,
        CancelRecurrentPaymentRequestDto
      >({
        query: (data) => ({
          method: 'POST',
          url: DELETE_RECURRENT_PAYMENT,
          data,
        }),
      }),
      [checkoutEndpoints.deleteSubscription]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: `${DELETE_SUBSCRIPTION}/${id}`,
        }),
      }),
      [checkoutEndpoints.postCreatePayOrder]: builder.mutation<
        PayOrderCreatedDto,
        string[]
      >({
        query: (chargesIds) => ({
          method: 'POST',
          url: POST_CREATE_PAY_ORDER,
          data: { chargesIds },
        }),
      }),
      [checkoutEndpoints.patchUpdatePlanCharge]: builder.mutation<
        ChargeDto,
        UpdatePlanChargeRequestDto
      >({
        invalidatesTags: [checkoutTags.chargeDetails],
        query: (data) => ({
          method: 'PATCH',
          url: PATCH_PLAN_CHARGE,
          data,
        }),
      }),
      [checkoutEndpoints.postCreditCardPayment]: builder.mutation<
        ExecutePaymentResponseDto,
        PayWithCreditCardDto
      >({
        invalidatesTags: (response, _error, data) => [
          checkoutTags.lastPaymentData,
          { type: checkoutTags.payOrderToPay, id: data.payorderId },
          ...(response?.successfulExecution
            ? [
                checkoutTags.pendingCharges,
                checkoutTags.recurrentPaymentConcepts,
                checkoutTags.subscriptions,
                checkoutTags.recurrentPaymentChargesData,
                checkoutTags.lastPaidCharges,
                checkoutTags.chargeDetails,
              ]
            : []),
        ],
        query: (data) => ({
          method: 'POST',
          url: POST_PAYMENT_CREDIT_CARD,
          data,
        }),
      }),
      [checkoutEndpoints.postMpPreferenceForPayOrder]: builder.mutation<
        MpPreferenceResponseDto,
        MpPreferenceRequestDto
      >({
        query: (data) => ({
          method: 'POST',
          url: POST_CREATE_MP_PREFERENCE,
          data,
        }),
      }),
      [checkoutEndpoints.postAdvancedCharge]: builder.mutation<ChargeDto, void>(
        {
          invalidatesTags: [
            checkoutTags.chargeDetails,
            checkoutTags.pendingCharges,
            { type: checkoutTags.lastPaymentData, id: 'plan-cost' },
          ],
          query: () => ({
            method: 'POST',
            url: POST_CREATE_ADVANCED_PLAN_CHARGE,
          }),
        },
      ),
      [checkoutEndpoints.postPaymentProof]: builder.mutation<
        void,
        { payOrderId: string; file: File }
      >({
        invalidatesTags: [
          checkoutTags.pendingCharges,
          checkoutTags.lastPaymentData,
          checkoutTags.lastPaidCharges,
          checkoutTags.chargeDetails,
        ],
        query: ({ payOrderId, file }) => {
          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('pay_order_id', payOrderId);
          return {
            url: POST_UPLOAD_PAYMENT_PROOF,
            method: 'POST',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data;',
            },
          };
        },
      }),
      [checkoutEndpoints.postAssertStore]: builder.mutation<
        AssertStoreResponseDto,
        void
      >({
        invalidatesTags: (result) =>
          result?.storeWasCreated ? Object.values(checkoutTags) : [],
        query: () => ({
          method: 'POST',
          url: POST_ASSERT_STORE,
        }),
      }),
      [checkoutEndpoints.getDownloadInvoice]: builder.mutation<
        DownloadInvoiceResponseDto,
        string
      >({
        query: (invoiceId) => ({
          method: 'GET',
          url: getUrlWithParams(GET_DOWNLOAD_INVOICE, {
            invoiceId,
          }),
        }),
      }),
    }),
  });

export const {
  useGetSubscriptionsQuery,
  useGetStoreSubscriptionEnabledQuery,
  useGetRecurrentPaymentConceptsQuery,
  useGetPendingChargesQuery,
  useGetPaymentMethodsQuery,
  useGetPayOrderToPayQuery,
  useGetWireTransferDataQuery,
  useGetLastPaymentDataQuery,
  useGetRecurrentPaymentChargeDataQuery,
  useGetLastPaidChargesQuery,
  useGetChargeDetailsQuery,
  useGetPaymentHistoryForConceptQuery,
  useDeleteRecurrentPaymentMutation,
  useDeleteSubscriptionMutation,
  usePostCreatePayOrderMutation,
  usePatchUpdatePlanChargeMutation,
  usePostCreditCardPaymentMutation,
  usePostMpPreferenceForPayOrderMutation,
  usePostAdvancedChargeMutation,
  usePostPaymentProofMutation,
  usePostAssertStoreMutation,
  useGetDownloadInvoiceMutation,
} = checkoutApi;
