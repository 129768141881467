const readFile = (blob: Blob, coding = 'UTF-8'): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });
    reader.readAsText(blob, coding);
  });

const isValidCoding = (str: string) => {
  const regexValid = new RegExp('(á|é|í|ó|ú|ñ)', 'gm');
  const regexInValid = new RegExp('�', 'gm');
  const textToLower = str.toLowerCase();
  return regexValid.test(textToLower) || !regexInValid.test(textToLower);
};

const readTextFormFile = async (file: Blob): Promise<string> => {
  let content = (await readFile(file)) as string;
  if (!isValidCoding(content)) {
    content = (await readFile(file, 'ISO-8859-1')) as string;
  }
  return content;
};

export default readTextFormFile;
