import { CategoryDetailsResponseDto } from '@tiendanube/common';
import { BaseCard, Stack, Title } from '@tiendanube/components';
import { LanguageNameValueInterface, LanguagesType } from 'App/i18n';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import FormNameAndDescription from './components/FormNameAndDescription/FormNameAndDescription';
import Skeleton from './Skeleton';

interface InformationEditCategoryCardProps {
  values: Pick<CategoryDetailsResponseDto, 'name' | 'description'>;
  errors: Partial<Record<string, string>>;
  handleChange: (data: LanguageNameValueInterface) => void;
  title: string;
}

function InformationEditCategoryCard({
  values,
  errors,
  handleChange,
  title,
}: InformationEditCategoryCardProps): JSX.Element {
  const missingLanguages = Object.keys(errors)
    .filter((err) => err.startsWith('name'))
    .map((err) => err.split('.')[1]);

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column spacing="base" align="stretch">
          <Stack.Item>
            <Title type="h3">{title}</Title>
          </Stack.Item>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <TabsMultiLanguage
          initialLanguage={missingLanguages[0] as LanguagesType}
        >
          <FormNameAndDescription
            name={values.name}
            description={values.description}
            errors={errors}
            onChange={handleChange}
          />
        </TabsMultiLanguage>
      </BaseCard.Body>
    </BaseCard>
  );
}

InformationEditCategoryCard.Skeleton = Skeleton;

export default InformationEditCategoryCard;
