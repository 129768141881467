import { ChangeEventHandler, useEffect, useState } from 'react';
import { Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Chip } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { Stack } from 'commons/components';

export type SalesChannelValuesType = string;

interface SalesChannelProps {
  onChange: ({ value, name }: { value: string; name: string }) => void;
  value: SalesChannelValuesType;
  error?: string;
}

function SalesChannel({
  onChange,
  value,
  error,
}: SalesChannelProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const [salesChannel, setSalesChannel] = useState('');
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setSalesChannel(value);
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSalesChannel(e.target.value);
  };

  const handleOnBlur = () => {
    setShowInput(false);
    onChange({ name: 'salesChannel', value: salesChannel });
  };

  const handleOnClear = () => {
    onChange({ name: 'salesChannel', value: '' });
  };

  const handleOnClickEdit = () => setShowInput(true);

  return (
    <Card
      title={t('draftOrders.newDraftOrder.salesChannel.title')}
      headerIcon={!value ? EditIcon : undefined}
      onClickHeaderIcon={!value ? handleOnClickEdit : undefined}
    >
      {showInput && (
        <FormField.Input
          placeholder={t('draftOrders.newDraftOrder.salesChannel.placeholder')}
          name="salesChannel"
          value={salesChannel}
          onChange={handleChange}
          onBlur={handleOnBlur}
          autoFocus
          maxLength={28}
        />
      )}
      {!!value && !showInput && (
        <Stack column align="flex-start" spacing="tight">
          <Chip id="salesChannelChip" label={value} onDismiss={handleOnClear} />
          {!!error && <Text color="danger-textLow">{t(error || '')}</Text>}
        </Stack>
      )}
    </Card>
  );
}

export default SalesChannel;
