import { useTranslation } from 'react-i18next';
import { Domain, Country } from '@tiendanube/common/src/enums';
import { Card, Stack, InterfaceNameValue, Input } from '@tiendanube/components';
import { InputNumber } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';

export interface DeliveryAdressEditCardValues {
  street: string;
  number: string;
  floor: string;
  zipcode: string;
  locality: string;
  city: string;
  province: string;
  observations?: string;
  betweenStreet?: string;
  reference?: string;
}

interface DeliveryAdressEditCardProps extends DeliveryAdressEditCardValues {
  errorZipCode?: string;
  isShippingMethodOca: boolean;
  onChange: (event: InterfaceNameValue) => void;
  onBlur: (event: InterfaceNameValue) => void;
}

function DeliveryAdressEditCard({
  street,
  number,
  floor,
  zipcode,
  locality,
  city,
  province,
  errorZipCode,
  betweenStreet,
  reference,
  observations,
  isShippingMethodOca,
  onChange,
  onBlur,
}: DeliveryAdressEditCardProps) {
  const { t } = useTranslation([Domain.ORDERS]);
  const country = useGetCountry();

  return (
    <Card title={`${t('editDeliveryAdressModal.deliveryAdressCard.title')}`}>
      <Stack column align="stretch">
        <Stack.Item fill>
          <Input
            name="street"
            label={t('editDeliveryAdressModal.deliveryAdressCard.street')}
            onChange={onChange}
            value={street}
          />
        </Stack.Item>
        <Stack.Item fill>
          <Stack>
            <Stack.Item fill>
              <InputNumber
                type="integer"
                name="number"
                label={t('editDeliveryAdressModal.deliveryAdressCard.number')}
                onChange={onChange}
                value={number}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Input
                name="floor"
                label="Piso"
                onChange={onChange}
                value={floor}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item fill>
          <Stack align="flex-start">
            <Stack.Item fill>
              <Input
                name="zipcode"
                label={t('editDeliveryAdressModal.deliveryAdressCard.zipcode')}
                onChange={onChange}
                value={zipcode}
                helpText={t(errorZipCode as string)}
                appearance={errorZipCode ? 'validation_error' : 'default'}
                onBlur={onBlur}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Input
                name="locality"
                label={t('editDeliveryAdressModal.deliveryAdressCard.locality')}
                onChange={onChange}
                value={locality}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {country === Country.MX && (
          <>
            <Stack.Item fill>
              <Input
                name="betweenStreet"
                label={t(
                  'editDeliveryAdressModal.deliveryAdressCard.betweenStreet',
                )}
                onChange={onChange}
                value={betweenStreet}
              />
            </Stack.Item>
            <Stack.Item fill>
              <Input
                name="reference"
                label={t(
                  'editDeliveryAdressModal.deliveryAdressCard.reference',
                )}
                onChange={onChange}
                value={reference}
              />
            </Stack.Item>
          </>
        )}
        <Stack.Item fill>
          <Input
            name="city"
            label={t('editDeliveryAdressModal.deliveryAdressCard.city')}
            onChange={onChange}
            value={city}
          />
        </Stack.Item>
        <Stack.Item fill>
          <Input
            name="province"
            label={t('editDeliveryAdressModal.deliveryAdressCard.province')}
            onChange={onChange}
            value={province}
          />
        </Stack.Item>
        {isShippingMethodOca && (
          <Stack.Item fill>
            <Input
              name="observations"
              label="Observaciones"
              multiRows
              rows={3}
              onChange={onChange}
              value={observations}
            />
          </Stack.Item>
        )}
      </Stack>
    </Card>
  );
}

export default DeliveryAdressEditCard;
