import { DuplicateIcon } from '@nimbus-ds/icons';
import {
  TrashIcon,
  ExternalLinkIcon,
  ShareIcon,
  CopyIcon,
} from '@tiendanube/icons';
import { ActionProp } from 'commons/components/IonPageStratus/components';
import isNative from 'commons/utils/isNative';
import shareToAny from 'commons/utils/socialSharing';

interface ActionsProps {
  productTitle: string;
  productCanonicalUrl: string;
  viewProductText: string;
  shareText: string;
  duplicateText: string;
  deleteText: string;
  saveText: string;
  variantId?: string;
  copyVariantIdText: string;
  appsActionOpen: ActionProp[];
  isDesktop: boolean;
  isLoading: boolean;
  openCloneProductModal: () => void;
  openRemoveProductModal: () => void;
  handleCopyVariantId: () => void;
  handleSave: () => void;
}

function Actions({
  productTitle,
  productCanonicalUrl,
  viewProductText,
  shareText,
  duplicateText,
  deleteText,
  saveText,
  variantId,
  copyVariantIdText,
  appsActionOpen,
  isDesktop,
  isLoading,
  openCloneProductModal,
  openRemoveProductModal,
  handleCopyVariantId,
  handleSave,
}: ActionsProps): ActionProp[] {
  return [
    {
      children: viewProductText,
      icon: ExternalLinkIcon,
      onClick: () => {
        window.open(productCanonicalUrl, '_blank');
      },
    },
    ...(isNative()
      ? [
          {
            children: shareText,
            icon: ShareIcon,
            onClick: () => {
              shareToAny(productTitle, productCanonicalUrl);
            },
          },
        ]
      : []),
    {
      children: duplicateText,
      icon: DuplicateIcon,
      onClick: openCloneProductModal,
    },
    ...appsActionOpen,
    ...(isDesktop && variantId
      ? [
          {
            children: copyVariantIdText,
            icon: CopyIcon,
            onClick: handleCopyVariantId,
          },
        ]
      : []),
    {
      children: deleteText,
      icon: TrashIcon,
      onClick: openRemoveProductModal,
    },
    ...(isDesktop
      ? [
          {
            children: saveText,
            onClick: handleSave,
            appearance: 'primary' as const,
            spinner: isLoading,
            disabled: isLoading,
          },
        ]
      : []),
  ];
}

export default Actions;
