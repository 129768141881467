import { useEffect } from 'react';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { useParams } from 'react-router-dom';
import { ErrorState } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetLanguage, useGetStoreInfo } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import EditFormPage from './EditFormPage';
import useUpdateContentPages from '../../hooks/useUpdateContentPages';
import { FormPageSkeleton } from '../components';

function PageEdit() {
  const t = useTranslationOnline('contentPages');
  const { id } = useParams<{ id: string }>();
  const { goBack } = useNavegate();
  const {
    hasData,
    statusUpdate,
    statusDetail,
    getPageById,
    updatePage,
    initialValues,
  } = useUpdateContentPages(id);
  const { url } = useGetStoreInfo();
  const defaultLanguage = useGetLanguage();

  const headerNavigation = { onClick: goBack };

  useToastStatus({
    error: t('formPage.toast.edit.error'),
    success: t('formPage.toast.edit.success'),
    onSuccess: goBack,
    status: statusUpdate,
  });

  useEffect(() => {
    if (!hasData) {
      getPageById();
    }
  }, [hasData, getPageById]);

  const handleOpenStoreFront = () => {
    openWindow(`${url}/${initialValues?.pageUrl[defaultLanguage]}`, true);
  };

  const isSuccess = statusDetail === 'success';

  const actionsDesktop: ActionProp[] = [
    {
      children: t('formPage.viewPage'),
      icon: ExternalLinkIcon,
      onClick: handleOpenStoreFront,
    },
  ];

  const mainAction = {
    icon: ExternalLinkIcon,
    onClick: handleOpenStoreFront,
    children: t('formPage.viewPage'),
  };

  return (
    <IonPageStratus
      width="small"
      headerContent={
        <HeaderContent
          title={t('formPage.editPage')}
          actions={
            isSuccess ? <ActionsDesktop actions={actionsDesktop} /> : undefined
          }
        />
      }
      headerTop={
        <HeaderTop
          navigation={headerNavigation}
          mainAction={isSuccess ? mainAction : undefined}
        />
      }
    >
      {statusDetail === 'loading' && <FormPageSkeleton />}
      {statusDetail === 'error' && (
        <ErrorState
          title={t('error.title')}
          action={{
            onClick: getPageById,
            children: t('error.retry'),
          }}
        />
      )}
      {!!hasData && !!initialValues && (
        <EditFormPage
          initialValues={initialValues}
          updatePage={updatePage}
          statusUpdate={statusUpdate}
        />
      )}
    </IonPageStratus>
  );
}

export default PageEdit;
