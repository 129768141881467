import { RolesV2ResponseDto } from '@tiendanube/common';

export const UsersEndpoints = {
  getUsersList: 'getUsersList',
  getUsersListInf: 'getUsersListInf',
  getUser: 'getUser',
  getShortRoles: 'getShortRoles',
  getFullRoles: 'getFullRoles',
  getRoles: 'getRoles',
  createUser: 'createUser',
  updateUser: 'updateUser',
  deleteUser: 'deleteUser',
} as const;

export const UsersTags = {
  usersList: 'usersList',
  roles: 'roles',
} as const;

export type UserRolesType = {
  sections: RolesV2ResponseDto;
  roles: Record<string, string>;
  appRoles: Record<string, string>;
};
