import { Box, Button, Checkbox } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { CardCollapsible, CardWithTitle } from 'commons/components';
import { INFO_MESSAGE_FORM_LAYOUT_STYLE } from './InfoMessageForm';

export function Skeleton() {
  return (
    <Box {...INFO_MESSAGE_FORM_LAYOUT_STYLE}>
      <CardCollapsible.Skeleton />
      <ShowByCountry allowedCountries={[Country.AR]}>
        <CardWithTitle>
          <Checkbox.Skeleton />
        </CardWithTitle>
      </ShowByCountry>
      <Box display="flex" flexDirection="row" gap="2" justifyContent="flex-end">
        <Button.Skeleton />
      </Box>
    </Box>
  );
}
