import { InteractiveListPriv } from '@tiendanube/components';
import { ImageItemStratus, VirtualInteractiveList } from 'commons/components';
import { ProductHiddenAndWithoutStock } from 'domains/Catalog/Products/pages/components';
import { SelectProductListItemProps } from '../types';

function SelectProductListItem({
  onChange,
  onReached,
  products,
  isRefreshing = false,
  trimTitle = false,
  selectedProducts,
  reachedLimit = false,
}: SelectProductListItemProps) {
  return (
    <VirtualInteractiveList
      totalCount={products.length}
      endReached={onReached}
      showFooter={isRefreshing}
      itemContent={(index) => {
        const isSelected = selectedProducts.some(
          (prod) => prod.id === products[index].id,
        );
        return (
          <InteractiveListPriv.CheckItem
            blockLeft={
              <ImageItemStratus
                id={products[index].id}
                title={products[index].name}
                imageUrl={products[index].imageUrl}
                imageWidth="56px"
                appearance="default"
                bold={false}
                trimTitle={trimTitle}
              >
                <ProductHiddenAndWithoutStock
                  isVisible={products[index].visible}
                  hasStock={products[index].stock}
                  productId={products[index].id}
                />
              </ImageItemStratus>
            }
            disabled={reachedLimit && !isSelected}
            key={index}
            title=""
            name={products[index].id}
            checked={isSelected}
            onChange={onChange}
          />
        );
      }}
    />
  );
}

export default SelectProductListItem;
