import { CurrencyType, InvoiceDto } from '@tiendanube/common';
import { DataList, IconButton, Stack } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { CurrencyPrice } from 'commons/components';
import { useTranslationBilling } from 'domains/Billing';
import useDownloadInvoice from '../../../../../useDownloadInvoice';

const { Cell } = DataList;

interface DataTableRowProps {
  total: string | false;
  amount: string;
  invoice: InvoiceDto;
  currency: CurrencyType | undefined;
}

function RightCell({
  amount,
  total,
  invoice,
  currency,
}: DataTableRowProps): JSX.Element {
  const t = useTranslationBilling();

  const { getLink } = useDownloadInvoice();

  const handleDownload = () => {
    getLink(invoice);
  };

  return (
    <Cell alignRight>
      <Stack column align="flex-end">
        {!!total && (
          <CurrencyPrice
            appearance="secondary"
            bold
            price={Number(total)}
            currency={currency}
          />
        )}
        <Stack column align="flex-end" spacing="tight">
          <CurrencyPrice price={Number(amount)} currency={invoice.currency} />
          <IconButton
            ariaLabel={t('invoiceHistory.buttonArial')}
            icon={DownloadIcon}
            onClick={handleDownload}
          />
        </Stack>
      </Stack>
    </Cell>
  );
}

export default RightCell;
