import { Box, Button, Text, Title } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { MARKETING_AUTOMATION_APP_INSTALL_LINK } from 'commons/constants/externalLinks';
import { useTranslationLanguage } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { Divider } from './components/Divider';
import { MarketingAutomationPlans } from './components/MarketingAutomationPlans';
import firstSlideES from './images/carousel/slide-1-es.png';
import firstSlidePT from './images/carousel/slide-1-pt.png';
import secondSlideES from './images/carousel/slide-2-es.png';
import secondSlidePT from './images/carousel/slide-2-pt.png';
import thirdSlideES from './images/carousel/slide-3-es.png';
import thirdSlidePT from './images/carousel/slide-3-pt.png';
import HeroPoweredByPT from './images/hero-powered-by-pt.png';
import HeroPoweredBy from './images/hero-powered-by.png';
import HeroThumbnail from './images/hero-thumbnail.png';
import { trackingMarketingAutomationAppInstallation } from '../tracking';

import './MarketingAutomationPage.scss';

const carouselImagesMap = {
  'es-AR': [firstSlideES, secondSlideES, thirdSlideES],
  'es-MX': [firstSlideES, secondSlideES, thirdSlideES],
  'es-CO': [firstSlideES, secondSlideES, thirdSlideES],
  'es-CL': [firstSlideES, secondSlideES, thirdSlideES],
  'pt-BR': [firstSlidePT, secondSlidePT, thirdSlidePT],
};

export function MarketingAutomationPage() {
  const t = useTranslationMarketing('marketingAutomation');
  const language = useTranslationLanguage();

  const [emblaRef] = useEmblaCarousel({ loop: false }, [
    Autoplay({ delay: 5000 }),
  ]);

  const handleInstallApplicationClick = () => {
    const url = MARKETING_AUTOMATION_APP_INSTALL_LINK[language];
    trackingMarketingAutomationAppInstallation();
    openWindow(url, true);
  };

  const carouselImages =
    language in carouselImagesMap
      ? carouselImagesMap[language]
      : carouselImagesMap['es-AR'];

  const poweredBy = language === 'pt-BR' ? HeroPoweredByPT : HeroPoweredBy;

  return (
    <div className="stratus--marketing-automation-page">
      <div className="stratus--marketing-automation-page__hero">
        <div className="stratus--marketing-automation-page__wrapper">
          <Layout columns="2 - symmetric">
            <Layout.Section
              justifyContent="center"
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems={{ xs: 'center', md: 'flex-start' }}
                gap="2-5"
              >
                <Box maxWidth="350px">
                  <img src={poweredBy} alt="MarketingNube powered by Perfit" />
                </Box>
                <Title
                  as="h1"
                  color="neutral-background"
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  {t('hero.title')}
                </Title>
                <Text
                  color="neutral-background"
                  fontSize="highlight"
                  textAlign={{ xs: 'center', md: 'left' }}
                >
                  {t('hero.description')}
                </Text>
              </Box>
              <Box paddingY="2">
                <Button onClick={handleInstallApplicationClick}>
                  {t('hero.button')}
                </Button>
              </Box>
            </Layout.Section>
            <Layout.Section
              justifyContent="center"
              alignItems={{ xs: 'center', md: 'flex-end' }}
            >
              <Box maxWidth="500px">
                <img src={HeroThumbnail} alt="MarketingNube" />
              </Box>
            </Layout.Section>
          </Layout>
        </div>
      </div>
      <div className="stratus--marketing-automation-page__content">
        <div className="stratus--marketing-automation-page__wrapper">
          <Title as="h2" textAlign="center">
            {t('contentSection.title')}
          </Title>
          <Layout columns="2 - symmetric">
            <Layout.Section
              justifyContent="center"
              alignItems={{ xs: 'center', md: 'flex-start' }}
            >
              <div
                className="stratus--marketing-automation-carousel"
                ref={emblaRef}
              >
                <div className="stratus--marketing-automation-carousel__container">
                  {carouselImages.map((slideImage, index) => (
                    <div
                      key={`mac-slide-${index}`}
                      className="stratus--marketing-automation-carousel__slide"
                    >
                      <Box width="100%" maxWidth="520px">
                        <img src={slideImage} alt="" />
                      </Box>
                    </div>
                  ))}
                </div>
              </div>
            </Layout.Section>
            <Layout.Section justifyContent="center" alignItems="flex-start">
              <Box display="flex" flexDirection="column" gap="2">
                <Title as="h4">{t('contentSection.content1.title')}</Title>
                <Text>{t('contentSection.content1.description')}</Text>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="column" gap="2">
                <Title as="h4">{t('contentSection.content2.title')}</Title>
                <Text>{t('contentSection.content2.description')}</Text>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="column" gap="2">
                <Title as="h4">{t('contentSection.content3.title')}</Title>
                <Text>{t('contentSection.content3.description')}</Text>
              </Box>
              <Divider />
              <Box display="flex" flexDirection="column" gap="2">
                <Title as="h4">{t('contentSection.content4.title')}</Title>
                <Text>{t('contentSection.content4.description')}</Text>
              </Box>
            </Layout.Section>
          </Layout>
        </div>
      </div>
      <div className="stratus--marketing-automation-page__content">
        <div className="stratus--marketing-automation-page__wrapper">
          <MarketingAutomationPlans onClick={handleInstallApplicationClick} />
        </div>
      </div>
    </div>
  );
}
