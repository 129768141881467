import { EmbeddedApp } from 'domains/PartnersApps/components';
import { AppInstalled } from 'domains/PartnersApps/partnersAppService';
import ExperimentalApp, { useExperimentalApp } from '../ExperimentalApp';

function DisplayApp({ app }: { app: AppInstalled }): JSX.Element {
  const isExperimentalApp = useExperimentalApp(app);
  if (isExperimentalApp) {
    return <ExperimentalApp id={app.id} />;
  }

  return <EmbeddedApp id={app.id} url={app.url} />;
}

export default DisplayApp;
