import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import useEditPhysicalLocationPage, {
  PhysicalLocation,
} from './useEditPhysicalLocationPage';
import { validationSchema } from './validationSchema';
import { PhysicalLocationForm } from '../DeliveryMethodsPage/components';

interface ParamsProps {
  id?: string;
}

const defaultPhysicalLocation = { name: '', extra: '', id: '' };

function EditPhysicalLocationPage() {
  const t = useTranslationShipping();

  const { id } = useParams<ParamsProps>();
  const { goBack } = useNavegate();

  const {
    getPhysicalLocationById,
    physicalLocation,
    getStatus,
    updatePhysicalLocation,
    updateStatus,
  } = useEditPhysicalLocationPage();

  const retry = () => {
    if (id) getPhysicalLocationById(id);
  };

  useEffect(() => {
    if (id) {
      getPhysicalLocationById(id);
    }
  }, [getPhysicalLocationById, id]);

  const handleSubmit = (data: PhysicalLocation) => {
    const { extra, name, id } = data;
    updatePhysicalLocation({ extra, name, id });
  };

  const { values, handleChange, handleOnSubmit, errors, isDirty } = useForm<
    PhysicalLocation,
    PhysicalLocation
  >({
    initialValues: physicalLocation || defaultPhysicalLocation,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useToastStatus({
    error: t('physicalLocations.addAndEdit.errors.error'),
    success: t('physicalLocations.addAndEdit.errors.success'),
    status: updateStatus,
    onSuccess: () => {
      goBack();
    },
  });

  return (
    <>
      {getStatus === 'error' && (
        <ErrorScreen
          description={t('physicalLocations.addAndEdit.errors.errorOnLoad')}
          onRetry={retry}
        />
      )}
      {getStatus === 'loading' && <PhysicalLocationForm.Skeleton />}
      {getStatus !== 'loading' && getStatus !== 'error' && (
        <PhysicalLocationForm
          title={t('physicalLocations.addAndEdit.title.edit')}
          values={values}
          errors={errors}
          onChange={handleChange}
          onCancel={goBack}
          onSave={handleOnSubmit}
          loading={updateStatus === 'loading'}
          isDirty={isDirty}
        />
      )}
    </>
  );
}

export default EditPhysicalLocationPage;
