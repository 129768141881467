import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeleteLocationModalErrorProps {
  onRetryError: () => void;
  onClose: () => void;
}

function DeleteLocationModalError({
  onRetryError,
  onClose,
}: Readonly<DeleteLocationModalErrorProps>): JSX.Element {
  const t = useTranslationShipping();
  return (
    <>
      <Modal.Header title={t('locations.delete.error')} />
      <Modal.Body padding="none">
        <Text>{`${t('locations.delete.descriptionError')}`}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Stack spacing="base" justify="flex-end">
          <CancelAndConfirmButtons
            onCancel={onClose}
            onConfirm={onRetryError}
            confirmText={t('locations.errorResults.action')}
          />
        </Stack>
      </Modal.Footer>
    </>
  );
}

export default DeleteLocationModalError;
