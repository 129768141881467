import { useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { initialValues } from './constants';
import { newCustomShippingSchema } from './newCustomShippingSchema';
import useNewCustomShipping from './useNewCustomShipping';
import { NewCustomShippingParamsInterface } from '../../deliveryMethodsSlice';
import ShippingPage from '../components/ShippingPage';
import { CustomShippingForm } from '../DeliveryMethodsPage/components';
import './NewCustomShippingPage.scss';

function NewCustomShippingPage() {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const { createNewCustomShipping, status } = useNewCustomShipping();
  const customShippingSchema = newCustomShippingSchema();
  const {
    locations,
    isLoading: isLoadingLocations,
    fetchLocations,
    isError: isErrorLocations,
  } = useLocations();
  const initial = useRef<NewCustomShippingParamsInterface>({
    ...initialValues,
    locationId: locations.length === 1 ? locations[0]?.id : '',
  });

  const {
    values,
    handleChange,
    errors,
    handleOnSubmit,
    setFieldValue,
    isDirty,
    resetValues,
  } = useForm({
    initialValues: initial.current,
    validationSchema: customShippingSchema,
    onSubmit: async (values) => {
      await createNewCustomShipping(values);
    },
  });

  useToastStatus({
    status,
    success: t('deliveryMethods.customShipping.toastSuccess.created'),
    error: t('deliveryMethods.customShipping.toastError.creation'),
    onSuccess() {
      resetValues();
      goBack();
    },
  });

  return (
    <ShippingPage
      handleSave={handleOnSubmit}
      title={t('deliveryMethods.customShipping.title')}
      isUpdating={status === 'loading'}
      isError={isErrorLocations}
      isLoading={isLoadingLocations}
    >
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />

      {isErrorLocations && (
        <ErrorScreen
          description={t(
            'deliveryMethods.activeShippingMethods.error.description',
          )}
          onRetry={() => fetchLocations()}
        />
      )}

      {isLoadingLocations && !isErrorLocations && (
        <CustomShippingForm.Skeleton />
      )}
      {!isLoadingLocations && !isErrorLocations && (
        <CustomShippingForm
          values={values}
          locations={locations}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          fetchLocations={fetchLocations}
          errors={errors}
        />
      )}
    </ShippingPage>
  );
}

export default NewCustomShippingPage;
