import isEmpty from 'lodash.isempty';
import {
  CreateDocumentRequestDto,
  FulfillmentOrderAssignedLocationForDocumentResponseDto,
  GetDocumentPageSizeSettingResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { trackingNuvemEnvioBannerView } from 'domains/FulfillmentOrders/tracking';
import { PageSizesValidation } from '../components/PrintDocumentForm/PrintDocumentForm';
import {
  DocumentType,
  MAXIMUM_WIDTH_ALLOWED,
  MINIMUM_HEIGHT_ALLOWED,
  MINIMUM_WIDTH_ALLOWED,
  MAXIMUM_HEIGHT_ALLOWED,
} from '../constants';

interface GetRequestByLocationIdForContentDeclarationParams {
  informationForContentDeclaration?: InformationForContentDeclarationResponseDto[];
  locationId: string;
}

interface GetRequestByLocationIdForDocumentParams {
  informationForDocument?: InformationForDocumentResponseDto[];
  locationId: string;
  type: DocumentType;
}

export const getRequestByLocationIdForDocument = ({
  informationForDocument,
  locationId,
}: GetRequestByLocationIdForDocumentParams): CreateDocumentRequestDto => {
  const fulfillmentOrdersIdsByDocument = informationForDocument
    ? (informationForDocument
        .filter(
          ({ fulfillmentOrder }) =>
            (!locationId ||
              fulfillmentOrder.assignedLocation.id === locationId) &&
            fulfillmentOrder.id,
        )
        .map(({ fulfillmentOrder }) => fulfillmentOrder.id) as string[])
    : [];

  const ordersIdsByDocument = informationForDocument
    ? (informationForDocument
        .filter(
          ({ fulfillmentOrder }) =>
            (!locationId ||
              fulfillmentOrder.assignedLocation.id === locationId) &&
            !fulfillmentOrder.id,
        )
        .map(({ order }) => order.id) as string[])
    : [];

  return {
    fulfillmentOrders: fulfillmentOrdersIdsByDocument,
    orders: ordersIdsByDocument,
  };
};

export const getRequestByLocationIdForContentDeclaration = ({
  informationForContentDeclaration,
  locationId,
}: GetRequestByLocationIdForContentDeclarationParams): CreateDocumentRequestDto => {
  const fulfillmentOrdersIdsByContentDeclaration =
    informationForContentDeclaration
      ? (informationForContentDeclaration
          .filter(
            ({ fulfillmentOrder }) =>
              (!locationId ||
                fulfillmentOrder.assignedLocation.id === locationId) &&
              fulfillmentOrder.id,
          )
          .map(({ fulfillmentOrder }) => fulfillmentOrder.id) as string[])
      : [];

  const ordersIdsByContentDeclaration = informationForContentDeclaration
    ? (informationForContentDeclaration
        .filter(
          ({ fulfillmentOrder }) =>
            (!locationId ||
              fulfillmentOrder.assignedLocation.id === locationId) &&
            !fulfillmentOrder.id,
        )
        .map(({ order }) => order.id) as string[])
    : [];

  return {
    fulfillmentOrders: fulfillmentOrdersIdsByContentDeclaration,
    orders: ordersIdsByContentDeclaration,
  };
};

export const getAssignedLocationsByInformation = (
  information:
    | InformationForDocumentResponseDto[]
    | InformationForContentDeclarationResponseDto[],
): FulfillmentOrderAssignedLocationForDocumentResponseDto[] =>
  information.flatMap(
    ({ fulfillmentOrder }) => fulfillmentOrder.assignedLocation,
  );

export const getOrderNumberAndFulfillmentOrderNumber = (
  information:
    | InformationForDocumentResponseDto[]
    | InformationForContentDeclarationResponseDto[],
): string[] =>
  information.flatMap(({ order, fulfillmentOrder }) => {
    if (!order || !fulfillmentOrder) {
      return [];
    }

    const packsWithSameOrder = (
      information as InformationForDocumentResponseDto[]
    ).filter(({ order: orderFilter }) => orderFilter.id === order.id);

    if (packsWithSameOrder.length > 1) {
      return `#${order.number}-${fulfillmentOrder.number}`;
    }
    return `#${order.number}`;
  });

export const validateSizes = (
  pageSize: GetDocumentPageSizeSettingResponseDto,
): PageSizesValidation => {
  const { width, height } = pageSize;
  const parsedWidth = parseFloat(isEmpty(width) ? '0' : width);
  const parsedHeight = parseFloat(isEmpty(height) ? '0' : height);
  return {
    width:
      parsedWidth < MINIMUM_WIDTH_ALLOWED ||
      parsedWidth > MAXIMUM_WIDTH_ALLOWED,
    height:
      height !== 'auto' &&
      (parsedHeight < MINIMUM_HEIGHT_ALLOWED ||
        parsedHeight > MAXIMUM_HEIGHT_ALLOWED),
  };
};

export const defineIfShouldShowAlertNuvemEnvioLabel = (
  type: DocumentType,
  information:
    | InformationForDocumentResponseDto[]
    | InformationForContentDeclarationResponseDto[]
    | undefined,
) => {
  const isInstanceOfDocumentTypeLabel = type === DocumentType.LABEL;

  if (!isInstanceOfDocumentTypeLabel) {
    return false;
  }

  const hasInformationLength = information && information.length > 0;

  const hasNuvemEnvioLabel = information?.some(
    (info) =>
      info.fulfillmentOrder?.shipping?.carrier &&
      info.fulfillmentOrder?.shipping?.carrier?.includes('Nuvem Envio') &&
      isInstanceOfDocumentTypeLabel,
  );

  if (hasInformationLength && hasNuvemEnvioLabel) {
    trackingNuvemEnvioBannerView(information.map((info) => info.order.id));
  }

  return hasInformationLength && hasNuvemEnvioLabel;
};
