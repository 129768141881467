import { Text, Accordion, Box } from '@nimbus-ds/components';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderTop, HeaderContent } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { AttachDomainSteps, PurchaseDomainStep } from './components';
import './AttachDomainModal.scss';

interface AttachDomainModalProps {
  show: boolean;
  ourDomain: string;
  domainId: string;
  domainName: string;
  isSubdomain: boolean;
  supportsOurDns: boolean;
  onClose: () => void;
}

export function AttachDomainModal({
  show,
  ourDomain,
  domainName,
  domainId,
  isSubdomain,
  supportsOurDns,
  onClose,
}: Readonly<AttachDomainModalProps>) {
  const t = useTranslationConfigurations('domains.attachModal');

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onClose }} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <Text>{domainName}</Text>
      <div className="stratus--attach-domain-modal">
        <Box
          padding="4"
          borderTopWidth="1"
          borderColor="neutral-surfaceHighlight"
          borderStyle="solid"
        >
          <Text>{t('subtitle')}</Text>
        </Box>
        <Accordion selectedDefault="0">
          <PurchaseDomainStep domainName={domainName} />
          <AttachDomainSteps
            isSubdomain={isSubdomain}
            supportsOurDns={supportsOurDns}
            domainId={domainId}
            ourDomain={ourDomain}
            domainName={domainName}
          />
        </Accordion>
      </div>
    </ModalAside>
  );
}
