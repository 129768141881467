import { SalesChannelsAppResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import { V1_BASE_URL } from './urls';

export const getSalesChannelsApps = async (): Promise<
  SalesChannelsAppResponseDto[]
> => {
  const response = await axios.get(V1_BASE_URL);
  return response.data;
};
