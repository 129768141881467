import { Button, Stack } from '@tiendanube/components';
import {
  BoxPackedIcon,
  CreditCardIcon,
  EditIcon,
  InvoiceIcon,
  MoneyIcon,
  StatsIcon,
} from '@tiendanube/icons';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { InfoModal } from 'commons/components';
import StackInfoIcon from 'commons/components/InfoModal/StackInfoIcon/StackInfoIcon';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useUpdateNuvemEnvioConfigs from 'domains/Shipping/DeliveryMethods/hooks/useUpdateNuvemEnvioConfigs';
import {
  trackingNuvemEnvioModalClosed,
  trackingNuvemEnvioModalOpened,
} from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping/useTranslationShipping';

const TRANSLATE_PREFIX = 'deliveryMethods.onboardingNuvemEnvioModal';

const icons = {
  money: MoneyIcon,
  box: BoxPackedIcon,
  invoice: InvoiceIcon,
  creditCard: CreditCardIcon,
  edit: EditIcon,
  stats: StatsIcon,
};

function OnboardingNuvemEnvioModal(): JSX.Element {
  const t = useTranslationShipping();
  const { id: storeId } = useStoreInfo();

  const { goTo } = useNavegate();

  const { updateNuvemEnvioConfigs } = useUpdateNuvemEnvioConfigs();

  const handleClick = async () => {
    trackingNuvemEnvioModalOpened(storeId);
    await updateNuvemEnvioConfigs({
      showNuvemEnvioModal: false,
    });
    goTo(partnersAppsRoutes.nuvemenvioConfigurations);
  };

  const trackingNuvemEnvioModal = async () => {
    trackingNuvemEnvioModalClosed(storeId);

    await updateNuvemEnvioConfigs({
      showNuvemEnvioModal: false,
    });
  };

  return (
    <InfoModal
      text=""
      title={t(`${TRANSLATE_PREFIX}.title`)}
      storage="wasOnboardingNuvemEnvioModalShowed"
      onDismiss={trackingNuvemEnvioModal}
    >
      <Stack column align="flex-start">
        <Stack column spacing="loose" justify="center">
          <Stack column align="stretch">
            <StackInfoIcon
              color="success"
              icon={icons[t(`${TRANSLATE_PREFIX}.items.1.icon`)]}
              title={t(`${TRANSLATE_PREFIX}.items.1.title`)}
              subtitle={t(`${TRANSLATE_PREFIX}.items.1.subtitle`)}
            />
            <StackInfoIcon
              color="dark"
              icon={icons[t(`${TRANSLATE_PREFIX}.items.2.icon`)]}
              title={t(`${TRANSLATE_PREFIX}.items.2.title`)}
              subtitle={t(`${TRANSLATE_PREFIX}.items.2.subtitle`)}
            />
            <StackInfoIcon
              color="blue"
              icon={icons[t(`${TRANSLATE_PREFIX}.items.3.icon`)]}
              title={t(`${TRANSLATE_PREFIX}.items.3.title`)}
              subtitle={t(`${TRANSLATE_PREFIX}.items.3.subtitle`)}
            />
          </Stack>
          <Stack justify="center">
            <FeatureFlag
              flag={NEW_ONBOARDING_B}
              renderElse={
                <Button appearance="primary" onClick={handleClick}>
                  {`${t(`${TRANSLATE_PREFIX}.button`)}`}
                </Button>
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </InfoModal>
  );
}

export default OnboardingNuvemEnvioModal;
