import {
  trackingOrdersFrequentAccessWaitingPacking,
  trackingOrdersFrequentAccessWaitingPaymentAll,
  trackingOrdersFrequentAccessWaitingShippingAll,
} from 'domains/Dashboard/tracking';
import { PendingOrderType } from './PendingOrders';

export const ORDER_FILTERS = {
  old: {
    pendingPayment:
      '/orders/?q=&next_action=waiting_payment_all&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
    pendingShipping:
      '/orders/?q=&next_action=waiting_shipping_all&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
    pendingPacked:
      '/orders/?q=&next_action=waiting_packing&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
  },
  new: {
    pendingPayment: '?page=1&paymentStatus=pending',
    pendingShipping: '?page=1&fulfillmentStatus=unfulfilled',
    pendingPacked: '?page=1&fulfillmentStatus=unpacked&paymentStatus=paid',
  },
};

export const TRACKING_EVENTS: Record<PendingOrderType, () => void> = {
  pendingPayment: trackingOrdersFrequentAccessWaitingPaymentAll,
  pendingShipping: trackingOrdersFrequentAccessWaitingShippingAll,
  pendingPacked: trackingOrdersFrequentAccessWaitingPacking,
};

export const getEmptyPreffix = (isEmpty: boolean) => (isEmpty ? 'Empty' : '');

export const handleClick = (filter: PendingOrderType) => () => {
  TRACKING_EVENTS[filter]();
};
