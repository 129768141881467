import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  UpdateAuthenticationFactorRequestDto,
  UpdateAuthenticationFactorResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  updateAuthenticationFactorData,
  updateAuthenticationFactorStatus,
  updateAuthenticationFactor as updateAuthenticationFactorAction,
  invalidateSessions,
} from 'domains/Account/accountSlice';

interface useUpdateAuthenticationFactorResult {
  update: (payload: UpdateAuthenticationFactorRequestDto) => Promise<boolean>;
  data: UpdateAuthenticationFactorResponseDto;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useUpdateAuthenticationFactor(): useUpdateAuthenticationFactorResult {
  const { status } = useSelector(updateAuthenticationFactorStatus);
  const data = useSelector(updateAuthenticationFactorData);
  const dispatch = useAppDispatch();
  const update = useCallback(
    async (payload: UpdateAuthenticationFactorRequestDto) => {
      const response = await dispatch(
        updateAuthenticationFactorAction(payload),
      );
      const isValid =
        updateAuthenticationFactorAction.rejected.type !== response.type;
      if (isValid && payload.enabled) {
        await dispatch(invalidateSessions());
      }
      return isValid;
    },
    [dispatch],
  );

  return {
    update,
    data,
    isLoading: status === 'loading',
    isSuccess: status === 'success',
    isError: status === 'error',
  };
}

export default useUpdateAuthenticationFactor;
