import { useState, useCallback } from 'react';

export interface InterfaceSetToggle {
  (): void;
}
export interface IntefaceUseToggle {
  (initState?: boolean): [boolean, InterfaceSetToggle];
}

export const useToggle: IntefaceUseToggle = (
  initState = false,
): [boolean, () => void] => {
  const [toggle, setToggle] = useState(initState);

  return [
    toggle,
    useCallback(() => {
      setToggle((state) => !state);
    }, []),
  ];
};
