import { useTranslation } from 'react-i18next';
import {
  CustomersOrdersResponseDto,
  OrderResponseDto,
} from '@tiendanube/common';
import {
  Domain as DomainEnum,
  Status as StatusEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { Label, Stack } from '@tiendanube/components';
import { useResponsive, EllipsisWithTooltip } from 'commons/components';
import { fulfillment } from '../status';

interface ColumnFulfillmentStatusProps {
  order: CustomersOrdersResponseDto | OrderResponseDto;
}

function ColumnFulfillmentStatus({
  order,
}: ColumnFulfillmentStatusProps): JSX.Element | null {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const { isDesktop } = useResponsive();

  const isOpen = order.status === StatusEnum.OPEN;
  const hasFulfillmentStatus = order.fulfillment.status;
  const hasFulfillmentType =
    'preferenceType' in order.fulfillment || 'preference' in order.fulfillment;

  if (!isOpen || !hasFulfillmentStatus || !hasFulfillmentType) {
    return null;
  }

  const fulfillmentPreferenceType =
    'preferenceType' in order.fulfillment
      ? order.fulfillment['preferenceType']
      : order.fulfillment['preference']['type'];

  const { icon, status, appearance } = fulfillment(
    order.fulfillment.status as StatusEnum,
    fulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
  );

  return (
    <Stack column spacing="tight" align="flex-start">
      {fulfillmentPreferenceType !==
        FulfillmentPreferenceTypeEnum.NON_SHIPPABLE && (
        <Label
          id={status}
          label={`${t(
            `status.${
              status === 'pickuped' && order.isShippablePickup
                ? 'shipped'
                : status
            }`,
          )}`}
          icon={icon}
          appearance={appearance}
        />
      )}
      {isDesktop && !!order.fulfillment.preferenceCarrier && (
        <EllipsisWithTooltip
          content={order.fulfillment.preferenceCarrier}
          trimLines={1}
          textSize="caption"
          position="bottom"
          arrow
        />
      )}
    </Stack>
  );
}

export default ColumnFulfillmentStatus;
