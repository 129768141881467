import { useMemo } from 'react';
import { Box } from '@nimbus-ds/components';
import { RelatedProductTypes } from '@tiendanube/common';
import { Card } from '@tiendanube/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { RelatedProductInterface } from 'domains/Catalog/Products/pages/hooks/useProductForm';
import {
  trackCreateRelatedProductModal,
  trackUpdateRelatedProductModal,
} from 'domains/Online/tracking';
import {
  Skeleton,
  ErrorState,
  PreviewRelatedProducts,
  EmptyRelatedProducts,
} from './components';

interface RelatedProductCardProps {
  onOpen: () => void;
  onEdit: () => void;
  relatedProducts: RelatedProductInterface;
  setEditingType: (type: RelatedProductTypes) => void;
}

function RelatedProductCard({
  onOpen,
  onEdit,
  relatedProducts,
  setEditingType,
}: Readonly<RelatedProductCardProps>) {
  const t = useTranslationCatalog();

  const handleEdit = (type: RelatedProductTypes) => {
    trackUpdateRelatedProductModal(type);
    setEditingType(type);
    onEdit();
  };

  const handleOpen = (type: RelatedProductTypes) => {
    trackCreateRelatedProductModal(type);
    setEditingType(type);
    onOpen();
  };

  const alternative = useMemo(
    () => relatedProducts?.alternative || [],
    [relatedProducts?.alternative],
  );

  const complementary = useMemo(
    () => relatedProducts?.complementary || [],
    [relatedProducts?.complementary],
  );

  const renderAlternative = () => {
    if (alternative.length) {
      return (
        <PreviewRelatedProducts
          title={t('products.detail.relatedProduct.alternative.title', {
            count: `(${alternative.length})` as never,
          })}
          onOpen={() => handleEdit('ALTERNATIVE')}
          relatedProducts={alternative}
        />
      );
    }
    return (
      <EmptyRelatedProducts
        title={t('products.detail.relatedProduct.alternative.title')}
        description={t(
          'products.detail.relatedProduct.alternative.description',
        )}
        onOpen={() => handleOpen('ALTERNATIVE')}
      />
    );
  };

  const renderComplementary = () => {
    if (complementary.length) {
      return (
        <PreviewRelatedProducts
          title={t('products.detail.relatedProduct.complementary.title', {
            count: `(${complementary.length})` as never,
          })}
          onOpen={() => handleEdit('COMPLEMENTARY')}
          relatedProducts={complementary}
        />
      );
    }
    return (
      <EmptyRelatedProducts
        title={t('products.detail.relatedProduct.complementary.title')}
        description={t(
          'products.detail.relatedProduct.complementary.description',
        )}
        onOpen={() => handleOpen('COMPLEMENTARY')}
      />
    );
  };

  return (
    <Card title={t('products.detail.relatedProduct.title')}>
      {renderAlternative()}
      <Box
        id="divider"
        backgroundColor="neutral-surfaceHighlight"
        height="1px"
        marginTop="3"
        marginBottom="3"
      />
      {renderComplementary()}
    </Card>
  );
}

RelatedProductCard.Skeleton = Skeleton;
RelatedProductCard.ErrorState = ErrorState;

export default RelatedProductCard;
