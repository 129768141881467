import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getSalesChannelsAppsAction,
  getSalesChannelsAppsData,
  getSalesChannelsAppsStatus,
} from 'domains/SalesChannels/SalesChannelsApps';

function useSalesChannelsApps() {
  const dispatch = useAppDispatch();
  const { isError, isLoading, isSuccess } = useSelector(
    getSalesChannelsAppsStatus,
  );

  const salesChannelsApps = useSelector(getSalesChannelsAppsData);

  const getSalesChannelsApps = useCallback(
    () => dispatch(getSalesChannelsAppsAction()),
    [dispatch],
  );

  useEffect(() => {
    getSalesChannelsApps();
  }, [getSalesChannelsApps]);

  return {
    isLoading,
    isSuccess,
    isError,
    salesChannelsApps,
    getSalesChannelsApps,
  };
}

export default useSalesChannelsApps;
