import { useEffect } from 'react';
import { Card, ErrorState } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { AddressSkeleton } from 'domains/Shipping/Addresses/components/Address';
import {
  useAddressForm,
  useGetProvinces,
} from 'domains/Shipping/Addresses/hooks';
import { NewDraftOrdersValuesInterface } from '../../types';

interface AddressCardProps {
  values: NewDraftOrdersValuesInterface;
  errors: Record<string, string>;
  setFieldValue: (name: string, value: string) => void;
}

function AddressCard({
  values,
  errors,
  setFieldValue,
}: AddressCardProps): JSX.Element {
  const t = useTranslationOrders();
  const { component: AddressForm } = useAddressForm();
  const { isLoading, isError, provinces, fetchProvinces } = useGetProvinces();

  useEffect(() => {
    fetchProvinces();
  }, [fetchProvinces]);

  const valuesFromAddress = {
    street: values.street,
    number: values.number,
    complement: values.complement,
    neighborhood: values.neighborhood,
    city: values.city,
    zipcode: values.zipcode,
    province: values.province,
    provinceCode: values.provinceCode,
  };

  return (
    <Card
      title={t('draftOrders.newDraftOrder.deliveryInformation.title')}
      isCollapsible
    >
      {isLoading && <AddressSkeleton />}
      {isError && (
        <ErrorState
          title={t('abandonedCarts.detailsError.title')}
          action={{
            children: t('abandonedCarts.detailsError.action'),
            onClick: fetchProvinces,
          }}
        />
      )}
      {!isLoading && !isError && (
        <AddressForm
          values={valuesFromAddress}
          errors={errors}
          setFieldValue={setFieldValue}
          provinces={provinces}
        />
      )}
    </Card>
  );
}

export default AddressCard;
