import { Text, IconButton } from '@tiendanube/components';
import { INDENT_PER_DEPTH } from 'commons/components';
import './DraggableTreeRowSkeleton.scss';

interface DraggableTreeRowSkeletonProps {
  depth?: number;
}

function DraggableTreeRowSkeleton({
  depth = 0,
}: DraggableTreeRowSkeletonProps): JSX.Element {
  const indent = depth * INDENT_PER_DEPTH;

  return (
    <li>
      <div
        className="tree-node stratus--draggable-tree-row-skeleton"
        style={{ paddingInlineStart: indent }}
      >
        <div className="stratus--draggable-tree-row-skeleton-icon">
          <IconButton.Skeleton />
        </div>
        <div className="stratus--draggable-tree-row-skeleton-empty" />
        <Text.Skeleton width="large" />
        <div className="stratus--draggable-tree-row-skeleton-icon-end">
          <IconButton.Skeleton />
        </div>
      </div>
    </li>
  );
}

export default DraggableTreeRowSkeleton;
