import { Card, Title } from '@nimbus-ds/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { AccountDataContent } from '../../../components';

function AccountData(): JSX.Element {
  const t = useTranslationBilling('checkout.plansAndSubscriptions.accountData');

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{`${t('title')}`}</Title>
      </Card.Header>
      <Card.Body>
        <AccountDataContent />
      </Card.Body>
    </Card>
  );
}

export default AccountData;
