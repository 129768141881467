import { openWindow } from '../window';

const REMOVE_FIRST_SLASH_REGEX = /^\//;

/*
  WARNING!
  This method doesn't work on mobile. Please, replace it with useAdminLinks hook and 
  openWindow passing openInNewTab flag in true for mobile cases.  

  Ex:
  const { getOldAdminLink } = useAdminLinks();
  const handleGoToPayments= () => {
    openWindow(getOldAdminLink('/payments'), isMobileDevice);
  };
*/
export default function goToAdmin(
  path: string,
  openInNewTab = false,
): () => void {
  const pathWithOutFirstSlash = path.replace(REMOVE_FIRST_SLASH_REGEX, '');
  return () => openWindow(`/admin/${pathWithOutFirstSlash}`, openInNewTab);
}
