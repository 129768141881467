import { Alert, Box, Button, Icon, Text } from '@nimbus-ds/components';
import { MetafieldV2Dto } from '@tiendanube/common';
import { CheckCircleIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { useGetMetafieldsOrders } from 'domains/Metafields/hooks';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields';
import useModalOrdersAssignMetafields from 'domains/Orders/Orders/hooks/useModalOrdersAssignMetafields';
import ModalOrderButtons from './components/ModalOrderButtons';
import ModalOrderCard from './components/ModalOrderCard/ModalOrderCard';

interface ModalOrdersAssignMetafieldsProps {
  handleOnClose: () => void;
  onToggleEditMode: () => void;
  isLoading: boolean;
  selectedRowsId: string[];
  show: boolean;
}

const MAX_METAFIELDS_WITHOUT_SCROLL = 3;

const metafieldEntriesToArray = (entries): MetafieldV2Dto[] =>
  entries.map(([key, metafield]) => ({
    id: key,
    name: metafield.name || '',
    description: metafield.description || '',
    values: metafield.values || [],
    type: metafield.type,
    value: null,
  }));

export default function ModalOrdersAssignMetafields({
  handleOnClose,
  onToggleEditMode,
  isLoading,
  selectedRowsId,
  show,
}: ModalOrdersAssignMetafieldsProps) {
  const t = useTranslationMetafields();
  const { handleChange, values, handleOnConfirm } =
    useModalOrdersAssignMetafields({ handleOnClose, onToggleEditMode });
  const { metafields: allMetafields } = useGetMetafieldsOrders();

  const apiMetafields = metafieldEntriesToArray(
    Object.entries(allMetafields.fromApi),
  );
  const metafields = metafieldEntriesToArray(
    Object.entries(allMetafields.internals),
  );

  const hasInternals = !!metafields?.length;
  const hasExternals = !!apiMetafields?.length;
  const showAssignButton =
    hasInternals && hasExternals
      ? true
      : Number(metafields?.length || apiMetafields?.length) >
        MAX_METAFIELDS_WITHOUT_SCROLL;

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={handleOnClose}
      headerContent={t('assignOrdersMetafieldsModal.title')}
    >
      {!!showAssignButton && (
        <Box position="absolute" top="8px" right="16px">
          <Button
            appearance="transparent"
            onClick={() => handleOnConfirm(values)}
          >
            <Icon source={<CheckCircleIcon />} />
            <Text fontWeight="regular">
              {t('assignOrdersMetafieldsModal.buttonTopAssign')}
            </Text>
          </Button>
        </Box>
      )}

      <Text fontSize="highlight">
        {selectedRowsId.length} {t('assignOrdersMetafieldsModal.selectedItems')}
      </Text>

      <Alert appearance="neutral">
        {t('assignOrdersMetafieldsModal.neutralAlert')}
      </Alert>

      {hasInternals && (
        <ModalOrderCard
          metafields={metafields}
          filledMetafields={values.metafields}
          handleChange={handleChange}
          hasTitle={hasExternals}
          title={t('assignOrdersMetafieldsModal.internalsSubtitle')}
        />
      )}

      {hasExternals && (
        <ModalOrderCard
          metafields={apiMetafields}
          filledMetafields={values.metafields}
          handleChange={handleChange}
          hasTitle
          title={t('assignOrdersMetafieldsModal.fromApiSubtitle')}
        />
      )}

      <ModalOrderButtons
        isLoading={isLoading}
        handleOnClose={handleOnClose}
        handleOnConfirm={() => handleOnConfirm(values)}
      />
    </ModalAside>
  );
}
