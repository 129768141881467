import { useCallback, useEffect, useRef, useState } from 'react';
import {
  AggregationsResponseDto,
  VariantsAggregationDto,
} from '@tiendanube/common/src/domains/orders/orders/OrderResponse';
import { ProductInterface } from 'domains/Orders/components/ProductSearcher';

export function useFilterSelectedProducts(
  serializedProducts: string,
  aggregations: AggregationsResponseDto,
) {
  const loadedInitialProductsFromURL = useRef(false);
  const [selectedProducts, setSelectedProducts] = useState<
    ProductInterface<true>[]
  >([]);

  const deserializeProducts = useCallback(
    (serializedProducts: string): { variantId: string }[] => {
      if (!serializedProducts) {
        return [];
      }

      return serializedProducts
        .split(';')
        .map((serializedProduct) => {
          const [variantId] = serializedProduct.split(':');

          return {
            variantId,
          };
        })
        .filter(
          ({ variantId }) => !!variantId && Number.isInteger(Number(variantId)),
        );
    },
    [],
  );

  const serializeProducts = useCallback(
    (products: ProductInterface<true>[]): string => {
      if (!products) {
        return '';
      }

      const serializedProducts = products.map(
        (product) => `${product.variantId}`,
      );

      return serializedProducts.join(';');
    },
    [],
  );

  const parseProductNameAndVariants = useCallback(
    (
      input: string,
    ): {
      productName: string;
      variantValues: Record<string, string>;
    } => {
      const variantRegex = /(.*?)\s*\((.*?)\)?$/; // Matches "Chair (Big, Pink)" or just "Chair"
      const match = input.match(variantRegex);

      let productName = input.trim();
      let variantValues: Record<string, string> = {};

      if (match) {
        productName = match[1].trim(); // Extracts "Chair"
        const variants = match[2]?.split(',').map((v) => v.trim()) || []; // ["Big", "Pink"]

        // Convert variants to Record<string, string>
        variantValues = variants.reduce<Record<string, string>>(
          (acc, value, index) => {
            acc[`variant_${index}`] = value;
            return acc;
          },
          {},
        );
      }

      return { productName, variantValues };
    },
    [],
  );

  const hydrateSelectedProducts = useCallback(() => {
    const deserializedProducts = deserializeProducts(serializedProducts);
    const digestedSelectedProducts = deserializedProducts
      .map((product) => {
        const productDetails = aggregations?.['variants']?.find(
          (p) => p.key.toString() === product.variantId,
        );

        if (!productDetails?.label) {
          return null;
        }

        const { productName, variantValues } = parseProductNameAndVariants(
          productDetails?.label || '',
        );

        return {
          name: productName,
          productId: (
            productDetails as VariantsAggregationDto
          ).productId.toString(),
          variantId: product.variantId,
          variantValues: variantValues,
        };
      })
      .filter((product) => !!product);

    setSelectedProducts(digestedSelectedProducts as ProductInterface<true>[]);
  }, [
    deserializeProducts,
    serializedProducts,
    aggregations,
    parseProductNameAndVariants,
  ]);

  useEffect(() => {
    // This condition is in order to avoid rehydrating the selected products from url with the aggregations, since then we get the information from product searcher
    if (
      serializedProducts.length &&
      !selectedProducts.length &&
      !loadedInitialProductsFromURL.current &&
      aggregations?.['variants']?.length
    ) {
      hydrateSelectedProducts();
      loadedInitialProductsFromURL.current = true;
    }
  }, [
    serializedProducts,
    selectedProducts,
    hydrateSelectedProducts,
    aggregations,
  ]);

  return {
    selectedProducts,
    setSelectedProducts,
    serializeProducts,
    deserializeProducts,
  };
}
