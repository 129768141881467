import { Link, Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { useNavegate } from 'App/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingMetafieldAlertClick } from 'domains/Catalog/Products/tracking';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';

interface AlertCreateProductsAndVariantsMetafieldsProps {
  isEdit?: boolean;
}

export default function AlertCreateProductsAndVariantsMetafields({
  isEdit,
}: AlertCreateProductsAndVariantsMetafieldsProps): JSX.Element | null {
  const { hasInternals, hasExternals, status, isAvailable } =
    useGetProductsAndVariantsMetafields();
  const t = useTranslationsMetafields();
  const { goTo } = useNavegate();
  const sender = useTrackFullCatalog();

  const handleClick = () => {
    sender(() => {
      trackingMetafieldAlertClick(isEdit);
    });
    goTo('/settings/metafields');
  };

  if (!status.isSuccess || !isAvailable || hasInternals || hasExternals)
    return null;

  return (
    <AlertSessionStorage
      keyName="alertCreateProductsAndVariantsMetafields"
      appearance="primary"
      title={t('alertCreateProductsAndVariantsMetafields.title')}
    >
      <Text color="neutral-textDisabled">
        {t('alertCreateProductsAndVariantsMetafields.description')}
      </Text>
      <Link onClick={handleClick}>
        {t(`alertCreateProductsAndVariantsMetafields.link`)}
      </Link>
    </AlertSessionStorage>
  );
}
