import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PaginationResponseDto, ProductsSortByEnum } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { cleanUpInventory } from 'domains/Catalog/Inventory/inventorySlice';
import {
  getMoreSelectProductList,
  getSelectProductList,
} from 'domains/Catalog/Products/productsSlice';
import {
  getEditOrderProductsDataSelector,
  getSelectProductListPaginationSelector,
  getSelectProductListRefreshStatusSelector,
  getSelectProductListStatusSelector,
} from 'domains/Catalog/Products/productsSlice/productSelectors';
import { ProductInterface } from '../types';

interface UseProductSearchResult {
  products: ProductInterface<false>[];
  pagination: PaginationResponseDto;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isRefreshError: boolean;
  isRefreshSuccess: boolean;
  isRefreshing: boolean;
  fetchProducts: (term?: string) => Promise<void>;
  fetchMoreProducts: (term?: string) => Promise<void>;
}

function useProductSearch({
  sortBy,
  locationId,
  requiresShipping,
  onlyPublished = false,
}: {
  sortBy: ProductsSortByEnum | null;
  locationId?: string;
  requiresShipping?: boolean;
  onlyPublished?: boolean;
}): UseProductSearchResult {
  const dispatch = useAppDispatch();
  const productsResult = useSelector(getEditOrderProductsDataSelector);
  const products = useMemo(
    () =>
      productsResult?.map((p) => {
        const inventoryLevelStock = p.inventoryLevels.find(
          (il) => il.locationId === locationId,
        )?.stock;

        return {
          ...p,
          stock: locationId
            ? inventoryLevelStock !== undefined
              ? inventoryLevelStock
              : 0
            : p.stock,
        };
      }),
    [productsResult, locationId],
  );

  const pagination = useSelector(getSelectProductListPaginationSelector);

  const { isLoading, isError, isSuccess } = useSelector(
    getSelectProductListStatusSelector,
  );
  const { isRefreshError, isRefreshSuccess, isRefreshing } = useSelector(
    getSelectProductListRefreshStatusSelector,
  );

  const fetchProducts = useCallback(
    async (q?: string) => {
      await dispatch(
        getSelectProductList({
          ...(q && { q }),
          ...(sortBy && { sortBy }),
          ...(locationId && { locationId }),
          ...(requiresShipping !== undefined && { requiresShipping }),
          published: onlyPublished ? true : undefined,
        }),
      );
    },
    [dispatch, sortBy, locationId, requiresShipping, onlyPublished],
  );

  const fetchMoreProducts = useCallback(
    async (q?: string) => {
      await dispatch(
        getMoreSelectProductList({
          ...(q && { q }),
          ...(sortBy && { sortBy }),
          ...(locationId && { locationId }),
          ...(requiresShipping !== undefined && { requiresShipping }),
          published: onlyPublished ? true : undefined,
        }),
      );
    },
    [dispatch, sortBy, locationId, requiresShipping, onlyPublished],
  );

  useEffect(() => {
    dispatch(cleanUpInventory());
  }, [dispatch]);

  return {
    products: products ?? [],
    pagination,
    isLoading,
    isError,
    isSuccess,
    isRefreshing,
    isRefreshSuccess,
    isRefreshError,
    fetchProducts,
    fetchMoreProducts,
  };
}

export default useProductSearch;
