import {
  CountriesResponseDto,
  CurrencyResponseDto,
  EnableCountryRequestDto,
  EnabledCountriesListResponseDto,
  LanguagesResponseDto,
  RateSuggestResponseDto,
} from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  BASE_I18N_COUNTRIES_URL,
  BASE_I18N_CURRENCIES_URL,
  BASE_I18N_ENABLED_COUNTRY_DELETE_URL,
  BASE_I18N_ENABLED_COUNTRIES_URL,
  BASE_I18N_LANGUAGES_URL,
  BASE_I18N_ENABLED_COUNTRY_UPDATE_URL,
  BASE_I18N_UPDATE_MAIN_CURRENCY_URL,
  BASE_I18N_UPDATE_MAIN_COUNTRY_URL,
  BASE_I18N_RATE_SUGGEST_URL,
  BASE_I18N_UPDATE_EXCHANGE_RATE_URL,
  i18nTags,
} from './config';
import { I18nEndpoints } from './types';

export const i18nApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [i18nTags.enabledCountryList, i18nTags.currencyList],
  })

  .injectEndpoints({
    endpoints: (builder) => ({
      [I18nEndpoints.getEnabledCountriesList]: builder.query<
        EnabledCountriesListResponseDto,
        void
      >({
        query: () => ({
          method: 'GET',
          url: BASE_I18N_ENABLED_COUNTRIES_URL,
        }),
        providesTags: [i18nTags.enabledCountryList],
      }),

      [I18nEndpoints.getCountries]: builder.query<CountriesResponseDto[], void>(
        {
          query: () => ({
            method: 'GET',
            url: BASE_I18N_COUNTRIES_URL,
          }),
        },
      ),

      [I18nEndpoints.getCurrencies]: builder.query<CurrencyResponseDto[], void>(
        {
          query: () => ({
            method: 'GET',
            url: BASE_I18N_CURRENCIES_URL,
          }),
          providesTags: [i18nTags.currencyList],
        },
      ),

      [I18nEndpoints.getLanguages]: builder.query<LanguagesResponseDto[], void>(
        {
          query: () => ({
            method: 'GET',
            url: BASE_I18N_LANGUAGES_URL,
          }),
        },
      ),

      [I18nEndpoints.updateEnabledCountry]: builder.mutation<
        void,
        { id: string; body: EnableCountryRequestDto }
      >({
        query: ({ id, body }) => ({
          method: 'PUT',
          url: getUrlWithParams(BASE_I18N_ENABLED_COUNTRY_UPDATE_URL, { id }),
          data: body,
        }),
      }),

      [I18nEndpoints.updateMainCurrency]: builder.mutation<void, string>({
        query: (currencyStoreId) => ({
          method: 'PATCH',
          url: getUrlWithParams(BASE_I18N_UPDATE_MAIN_CURRENCY_URL, {
            currencyStoreId,
          }),
        }),
        invalidatesTags: (_, error) => (error ? [] : [i18nTags.currencyList]),
      }),

      [I18nEndpoints.enableCountry]: builder.mutation<
        void,
        EnableCountryRequestDto
      >({
        query: (newCountry) => ({
          method: 'POST',
          url: BASE_I18N_ENABLED_COUNTRIES_URL,
          data: newCountry,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [i18nTags.enabledCountryList],
      }),

      [I18nEndpoints.deleteEnabledCountry]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(BASE_I18N_ENABLED_COUNTRY_DELETE_URL, { id }),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [i18nTags.enabledCountryList],
      }),

      [I18nEndpoints.updateEnabledCountry]: builder.mutation<
        void,
        { id: string; body: EnableCountryRequestDto }
      >({
        query: ({ id, body }) => ({
          method: 'PUT',
          url: getUrlWithParams(BASE_I18N_ENABLED_COUNTRY_UPDATE_URL, { id }),
          data: body,
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [i18nTags.enabledCountryList],
      }),

      [I18nEndpoints.updateMainCountry]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'PATCH',
          url: getUrlWithParams(BASE_I18N_UPDATE_MAIN_COUNTRY_URL, { id }),
        }),
        invalidatesTags: (_, error) =>
          error ? [] : [i18nTags.enabledCountryList],
      }),

      [I18nEndpoints.getSuggestedRate]: builder.query<
        RateSuggestResponseDto,
        { equivalentCurrency: string; mainCurrency: string }
      >({
        query: ({ equivalentCurrency, mainCurrency }) => ({
          method: 'GET',
          url: getUrlWithParams(BASE_I18N_RATE_SUGGEST_URL, {
            equivalentCurrency,
            mainCurrency,
          }),
        }),
      }),

      [I18nEndpoints.updateExchangeRate]: builder.mutation<
        void,
        { currencyCode: string; body: { rate: string } }
      >({
        query: ({ currencyCode, body }) => ({
          method: 'PUT',
          url: getUrlWithParams(BASE_I18N_UPDATE_EXCHANGE_RATE_URL, {
            currencyCode,
          }),
          data: body,
        }),
        onQueryStarted({ currencyCode, body }, { dispatch, queryFulfilled }) {
          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                I18nEndpoints.getCurrencies as never,
                undefined as never,
                (currencies: CurrencyResponseDto[]) => {
                  const found = currencies.find(
                    (currency) => currency.currencyCode === currencyCode,
                  );
                  if (found) found.equivalentTo = body.rate;
                },
              ),
            );
          });
        },
      }),
    }),
  });

export const {
  useGetEnabledCountriesListQuery,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetLanguagesQuery,
  useEnableCountryMutation,
  useDeleteEnabledCountryMutation,
  useUpdateEnabledCountryMutation,
  useUpdateMainCountryMutation,
  useUpdateMainCurrencyMutation,
  useGetSuggestedRateQuery,
  useUpdateExchangeRateMutation,
} = i18nApi;
