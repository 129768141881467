// import { Locale } from 'App/i18n' throws error we need to make a research the error
import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import fields from './fields-card-translations.json';
import esAR from './shipping.es-AR.json';
import esCL from './shipping.es-CL.json';
import esCO from './shipping.es-CO.json';
import esMX from './shipping.es-MX.json';
import ptBR from './shipping.pt-BR.json';

esAR.locations.form.address.fields = fields;
esCL.locations.form.address.fields = fields;
esCO.locations.form.address.fields = fields;
esMX.locations.form.address.fields = fields;
ptBR.locations.form.address.fields = fields;

const shippingTranslations: DomainTranslations = {
  name: 'shipping',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
    [Locale.COLOMBIA]: esCO,
    [Locale.CHILE]: esCL,
  },
};

export default shippingTranslations;
