import { Thumbnail } from '@tiendanube/components';
import { CameraIcon } from '@tiendanube/icons';

interface ProductThumbnailProps {
  imageUrl: string | null;
  altText: string;
}

function ProductThumbnail({
  imageUrl,
  altText,
}: ProductThumbnailProps): JSX.Element {
  return (
    <>
      {!!imageUrl && (
        <Thumbnail
          aspectRatio="1-1"
          width="72px"
          src={imageUrl}
          altText={altText}
        />
      )}
      {!imageUrl && (
        <Thumbnail.Empty
          aspectRatio="1-1"
          width="72px"
          placeholderIcon={CameraIcon}
        />
      )}
    </>
  );
}

export default ProductThumbnail;
