import { ChangeEvent } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { CardWithTitle, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { Afip } from './Afip';
import { BingMeta } from './BingMeta';
import { Ebit } from './Ebit';
import { Olark } from './Olark';
import { Skeleton } from './Skeleton';

export interface ExternalCodesOtherServicesCardProps {
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  afip?: string;
  bingMeta: string;
  olark: string;
  ebitAccount?: string;
  ebitSlug?: string;
}

export function ExternalCodesOtherServicesCard({
  onChangeTextArea,
  onChange,
  afip,
  bingMeta,
  olark,
  ebitAccount,
  ebitSlug,
}: Readonly<ExternalCodesOtherServicesCardProps>) {
  const t = useTranslationConfigurations('externalCodes.otherServices');
  return (
    <CardWithTitle title={t('title')}>
      <Stack column spacing="loose" align="stretch">
        <ShowByCountry allowedCountries={[Country.AR]}>
          <Afip afip={afip} onChangeTextArea={onChangeTextArea} />
        </ShowByCountry>
        <ShowByCountry allowedCountries={[Country.BR]}>
          <Ebit
            onChange={onChange}
            ebitAccount={ebitAccount}
            ebitSlug={ebitSlug}
          />
        </ShowByCountry>
        <BingMeta bingMeta={bingMeta} onChangeTextArea={onChangeTextArea} />
        <Olark olark={olark} onChangeTextArea={onChangeTextArea} />
      </Stack>
    </CardWithTitle>
  );
}

ExternalCodesOtherServicesCard.Skeleton = Skeleton;
