import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { Modal } from '@nimbus-ds/components';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import CancelAndConfirmButtons from 'commons/components/CancelAndConfirmButtons';
import { useForm, useToastStatus } from 'commons/hooks';
import useRefreshInfoStoreUser from 'domains/Auth/hooks/useRefreshInfoToSync';
import useEnabledCountries from 'domains/Configurations/hooks/useEnabledCountries';
import {
  useEnableCountryMutation,
  useGetEnabledCountriesListQuery,
} from 'domains/Configurations/I18n/i18nApi';
import {
  enabledCountriesId,
  mainEnabledCountriesIds,
} from 'domains/Configurations/I18n/utils';
import { buildEnableCountrySchema } from 'domains/Configurations/I18n/utils/buildEnableCountrySchema';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import SelectsList from './SelectsList';

interface EnableCountryModalProps {
  show: boolean;
  onClose: () => void;
}

function EnableCountryModal({ show, onClose }: EnableCountryModalProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const refreshInfo = useRefreshInfoStoreUser();
  const mainCountry = useGetEnabledCountriesListQuery(undefined, {
    selectFromResult: ({ data }) => mainEnabledCountriesIds(data),
  });
  const enabledCountriesIds = useGetEnabledCountriesListQuery(undefined, {
    selectFromResult: ({ data }) => enabledCountriesId(data),
  });

  const [
    addCountry,
    { status: changeStatusStatus, isLoading: loadingAddCountry },
  ] = useEnableCountryMutation();

  const handleSuccessAddCountry = useCallback(() => {
    onClose();
    refreshInfo(true);
  }, [refreshInfo, onClose]);

  useToastStatus({
    status: toStatusType(changeStatusStatus),
    error: t('toastError'),
    success: t('toastSuccess'),
    onSuccess: handleSuccessAddCountry,
    onError: onClose,
  });

  const { isLoading, isSuccess, isError, onRefetch } = useEnabledCountries();

  const { handleOnChange, handleOnSubmit, errors, resetValues, cleanErrors } =
    useForm({
      initialValues: mainCountry,
      validationSchema: buildEnableCountrySchema(
        enabledCountriesIds.countriesId,
      ),
      onSubmit: (values) => {
        addCountry(values);
        formRef.current?.reset();
        resetValues();
      },
    });

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLSelectElement>) => {
    handleOnChange({ name, value });
  };

  useEffect(() => {
    cleanErrors();
    formRef.current?.reset();
  }, [show, cleanErrors]);

  return (
    <form ref={formRef}>
      <Modal open={show} onDismiss={onClose}>
        <Modal.Header title={t('enabledCountryModal.title')} />
        {isLoading && <SelectsList.Skeleton />}
        {isError && (
          <Modal.Body padding="none">
            <ErrorScreen onRetry={onRefetch} description={t('errorState')} />
          </Modal.Body>
        )}
        {isSuccess && (
          <>
            <SelectsList
              handleChange={handleChange}
              mainCountry={mainCountry}
              errors={errors}
            />
            <Modal.Footer>
              <CancelAndConfirmButtons
                isLoading={loadingAddCountry}
                isCancelDisabled={loadingAddCountry}
                confirmText={t('buttonEnabled')}
                onCancel={onClose}
                onConfirm={handleOnSubmit}
              />
            </Modal.Footer>
          </>
        )}
      </Modal>
    </form>
  );
}

export default EnableCountryModal;
