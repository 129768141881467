import {
  Button,
  Card,
  Input,
  Label,
  Skeleton,
  Tabs,
  Text,
  Title,
} from '@nimbus-ds/components';
import { Layout, Repeater, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function EditNotificationPageSkeleton() {
  const t = useTranslationConfigurations('notifications.editNotificationPage');

  return (
    <Layout
      mainContent
      left={
        <Stack column align="flex-end">
          <Card>
            <Card.Header>
              <Text fontSize="highlight" fontWeight="bold">
                {t('formCard.title')}
              </Text>
            </Card.Header>
            <Card.Body>
              <Stack column align="stretch">
                <Stack column spacing="tight" align="flex-start">
                  <Label>{t('formCard.subject')}</Label>
                  <Input.Skeleton />
                </Stack>
                <Tabs preSelectedTab={0}>
                  <Tabs.Item label={t('formCard.templateTextTab')}>
                    <Stack column align="stretch">
                      <Stack column spacing="tight" align="flex-start">
                        <Text fontSize="highlight" fontWeight="bold">
                          {t('formCard.templateText.title')}
                        </Text>
                        <Text>{t('formCard.templateText.subtitle')}</Text>
                      </Stack>
                      <Stack column spacing="tight" align="flex-start">
                        <Label>{t('formCard.templateText.body')}</Label>
                        <Skeleton
                          width="100%"
                          height="440px"
                          borderRadius="0.5rem"
                        />
                      </Stack>
                    </Stack>
                  </Tabs.Item>
                  <Tabs.Item label={t('formCard.htmlTab')}>
                    <Stack column align="stretch">
                      <Stack column spacing="tight" align="flex-start">
                        <Text fontSize="highlight" fontWeight="bold">
                          {t('formCard.templateHtml.title')}
                        </Text>
                        <Text>{t('formCard.templateHtml.subtitle')}</Text>
                      </Stack>
                      <Stack column spacing="tight" align="flex-start">
                        <Label>{t('formCard.templateHtml.body')}</Label>
                        <Skeleton
                          width="100%"
                          height="440px"
                          borderRadius="0.5rem"
                        />
                      </Stack>
                    </Stack>
                  </Tabs.Item>
                </Tabs>
              </Stack>
            </Card.Body>
          </Card>
          <Stack spacing="base" justify="flex-end">
            <Stack.Item>
              <Button.Skeleton />
            </Stack.Item>
            <Stack.Item>
              <Button.Skeleton />
            </Stack.Item>
          </Stack>
        </Stack>
      }
      right={
        <Card>
          <Card.Header>
            <Title as="h4" fontWeight="medium">
              {t('infoCard.title')}
            </Title>
          </Card.Header>
          <Card.Body>
            <Stack column align="stretch">
              <Text>{t('infoCard.description')}</Text>
              <Stack column spacing="tight" align="flex-start">
                <Repeater
                  times={3}
                  item={() => (
                    <>
                      <Text.Skeleton fontSize="caption" width="120px" />
                      <Text.Skeleton fontSize="caption" width="100px" />
                    </>
                  )}
                />
              </Stack>
            </Stack>
          </Card.Body>
        </Card>
      }
    />
  );
}
