import { useEffect } from 'react';
import { Popover, Box, Icon, Button } from '@nimbus-ds/components';
import { EllipsisIcon, PrinterIcon } from '@nimbus-ds/icons';
import { Menu, MenuButton } from '@nimbus-ds/patterns';
import { InterfaceButton } from '@tiendanube/components';
import useTopBar from 'App/components/Topbar/useTopBar';
import { ActionProp } from 'commons/components';
import BackNavigation from 'commons/components/BackNavigation';
import { useResponsive } from 'commons/components/Responsive';
import { useTranslationCommon } from 'commons/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export interface HeaderOrderMobileProps {
  navigation?: Pick<InterfaceButton, 'children' | 'onClick'>;
  actions: ActionProp[];
  mainAction?: ActionProp;
  applications?: ActionProp[];
  showAllways?: boolean;
}

function HeaderOrderMobile({
  navigation,
  actions,
  applications,
  mainAction,
  showAllways = false,
}: Readonly<HeaderOrderMobileProps>) {
  const { isMobile } = useResponsive();
  const { setHeaderNavigation } = useTopBar();

  useEffect(() => {
    !showAllways && setHeaderNavigation(navigation);
  }, [navigation, setHeaderNavigation, showAllways]);

  const tOrd = useTranslationOrders();
  const tComm = useTranslationCommon();

  if (!(isMobile || showAllways)) return null;

  const renderBackNavigation = navigation && (
    <BackNavigation onClick={navigation.onClick}>
      {navigation.children}
    </BackNavigation>
  );

  const printLabelActions = actions.filter(
    (action) => action.name && action.name === 'printLabel',
  );

  const otherActions = actions.filter(
    (action) => !action.name || action.name !== 'printLabel',
  );
  const buttomActions = otherActions && (
    <Popover
      key="actionsOrder"
      arrow={false}
      position="bottom"
      content={
        <Box flexDirection="column">
          {otherActions.map((action) => (
            <Box
              key={`action-${action.children}`}
              padding="1"
              flexDirection="column"
            >
              <MenuButton
                label={action.children ?? 'undefined'}
                startIcon={action.icon}
                onClick={action.onClick}
              />
            </Box>
          ))}

          {applications && applications.length > 0 && (
            <Menu.Section title={tComm('myApps')}>
              {applications.map((application) => (
                <Box
                  key={`application-${application.children}`}
                  padding="1"
                  flexDirection="column"
                >
                  <MenuButton
                    label={application.children ?? 'undefined'}
                    startIcon={application.icon}
                    onClick={application.onClick}
                  />
                </Box>
              ))}
            </Menu.Section>
          )}
        </Box>
      }
    >
      <Icon source={<EllipsisIcon size="medium" />} color="primary-textLow" />
    </Popover>
  );

  const printActions =
    printLabelActions && printLabelActions.length > 1 ? (
      <Popover
        key="printLabelOrder"
        arrow={false}
        position="bottom-end"
        content={
          <Box flexDirection="column">
            {printLabelActions.map((printLabelAction) => (
              <Box
                key={`printAction-${printLabelAction.children}`}
                padding="1"
                flexDirection="column"
              >
                <MenuButton
                  label={printLabelAction.children ?? 'lala'}
                  startIcon={printLabelAction.icon}
                  onClick={printLabelAction.onClick}
                />
              </Box>
            ))}
          </Box>
        }
      >
        <Button key={`printLabels-${0}`} appearance="transparent">
          <Icon source={<PrinterIcon />} color="primary-textLow" />
          {tOrd('detail.printLabels')}
        </Button>
      </Popover>
    ) : (
      printLabelActions.length === 1 && (
        <Button
          key={`printLabel-${0}`}
          onClick={printLabelActions[0].onClick}
          appearance="transparent"
        >
          <Icon source={<PrinterIcon />} color="primary-textLow" />
          {tOrd('detail.printLabel')}
        </Button>
      )
    );

  const renderMainAction = mainAction && mainAction !== undefined && (
    <Button
      key="mainAction-0"
      onClick={mainAction.onClick}
      appearance="transparent"
      disabled={mainAction.disabled}
    >
      {mainAction.children}
    </Button>
  );

  return (
    <Box
      className="stratus--page-mobile-navbar"
      display="flex"
      justifyContent="flex-end"
    >
      {renderBackNavigation}
      <Box
        className="stratus--page-mobile-navbar__toolbar"
        align-items="left"
        display="flex"
        flex-wrap="nowrap"
        justify-content="flex-end"
        text-align="right"
        alignItems="center"
      >
        {renderMainAction}
        {printActions}
        {buttomActions}
      </Box>
    </Box>
  );
}

export default HeaderOrderMobile;
