import { logEvent } from 'commons/utils/tracking';
import {
  MARKETING_AUTOMATION_INSTALL_CLICK,
  MARKETING_AUTOMATION_PAGE_VIEW,
} from 'config/trackingEvents';

export const trackingMarketingAutomationAppInstallation = () => {
  logEvent(MARKETING_AUTOMATION_INSTALL_CLICK, {});
};

export const trackingMarketingAutomationPageView = ({
  pageId,
}: {
  pageId: 'marketing-automation-landing' | 'marketing-automation-dashboard';
}) => {
  logEvent(MARKETING_AUTOMATION_PAGE_VIEW, {
    pageId,
  });
};
