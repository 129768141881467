import { Box, Button, Modal, Text } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface ConfirmModalProps {
  show: boolean;
  type: 'confirm' | 'cancel';
  onConfirm: () => void;
}

export function NotificationModal({
  show,
  onConfirm,
  type,
}: Readonly<ConfirmModalProps>) {
  const t = useTranslationConfigurations('domains');
  const { Header, Body } = Modal;

  return (
    <Modal open={show} onDismiss={onConfirm}>
      <Header title={t(`softBlock.confirmModal.${type}.title`)} />
      <Body padding="none">
        <Text>{t(`softBlock.confirmModal.${type}.body`)}</Text>
      </Body>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Button onClick={onConfirm} appearance="primary" as="button">
          {t(`softBlock.confirmModal.${type}.continue`)}
        </Button>
      </Box>
    </Modal>
  );
}
