import { useCallback, useState } from 'react';
import { CouponFiltersKeys, CouponFiltersType } from '../couponsService';

interface UseProductsFiltersResult {
  values: CouponFiltersType;
  handleChange: <T extends CouponFiltersKeys>(
    name: T,
    value: CouponFiltersType[T],
  ) => void;
  setAllValues: (filters: CouponFiltersType) => void;
}

function useCouponsFilters(
  filters: CouponFiltersType,
): UseProductsFiltersResult {
  const [values, setValues] = useState(filters);

  const handleChange = useCallback(
    <T extends CouponFiltersKeys>(name: T, value: CouponFiltersType[T]) => {
      setValues((values) => ({ ...values, [name]: value }));
    },
    [],
  );

  const setAllValues = useCallback((filters: CouponFiltersType) => {
    setValues(filters);
  }, []);

  return {
    values,
    handleChange,
    setAllValues,
  };
}

export default useCouponsFilters;
