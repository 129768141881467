import { logEvent } from 'commons/utils/tracking';
import { CATALOG_PRODUCT_BULK_PRICE } from 'config/trackingEvents';
import { EditPricesProductsDto } from './types';

export const trackingProductBulkPrice = ({
  priceVariation,
  priceVariationType,
  priceVariationAction,
  priceRoundPrices,
  promoPriceVariation,
  promoPriceVariationType,
  promoPriceVariationAction,
  promoPriceRoundPrices,
}: EditPricesProductsDto) => {
  logEvent(CATALOG_PRODUCT_BULK_PRICE, {
    price_original_edit: `${Number(priceVariation) !== 0}`,
    price_original_operation: priceVariationAction,
    price_original_change_type: priceVariationType,
    price_original_has_round_enabled: `${priceRoundPrices}`,
    price_promotional_edit: `${Number(promoPriceVariation) !== 0}`,
    price_promotional_operation: promoPriceVariationAction,
    price_promotional_change_type: promoPriceVariationType,
    price_promotional_has_round_enabled: `${promoPriceRoundPrices}`,
  });
};
