import { NewDraftOrdersValuesInterface } from './types';

export const initialValues: NewDraftOrdersValuesInterface = {
  products: [],
  subtotal: 0,
  total: 0,
  shipping: 0,
  discount: 0,
  discountType: 'percentage',
  firstName: '',
  lastName: '',
  email: '',
  identification: '',
  phone: '',
  paymentStatus: 'unpaid',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  zipcode: '',
  province: '',
  salesChannel: '',
  remarks: '',
  provinceCode: '',
};
