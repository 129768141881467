import { Box } from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import Responsive from './Responsive';
import SubmitButton, { SubmitButtonProps } from './SubmitButton/SubmitButton';

interface SubmitButtonHeaderTopProps extends SubmitButtonProps {
  isLoading?: boolean;
  onSubmit: () => void;
  children: React.ReactNode;
}

export function SubmitButtonHeaderTop({
  isLoading,
  onSubmit,
  children,
  ...rest
}: Readonly<SubmitButtonHeaderTopProps>) {
  return (
    <Responsive
      desktop={
        <SubmitButton isLoading={isLoading} onClick={onSubmit}>
          {children}
        </SubmitButton>
      }
      mobile={
        <Box display="flex" gap="none" alignItems="center" padding="2">
          <SubmitButton
            appearance="transparent"
            isLoading={isLoading}
            onClick={onSubmit}
            {...rest}
          >
            {!isLoading && <CheckCircleIcon />}
            {children}
          </SubmitButton>
        </Box>
      }
    />
  );
}
