import { Box, Icon, Text } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { CurrencyType, FulfillmentOrdersResponseDto } from '@tiendanube/common';
import { CurrencyPrice } from 'commons/components';
import Strikethrough from 'commons/components/Strikethrough';
import { formatCurrency, formatTwoDigits } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useShippingCosts } from '../../../hooks';
import { OrderEditProduct } from '../../Products';
import './FulfillmentsSummaryItem.scss';

interface EditOrderFulfillmentsSummaryProps {
  fulfillmentOrder?: FulfillmentOrdersResponseDto;
  fulfillmentProducts: OrderEditProduct[];
  totalShippingCost: number;
  currency: CurrencyType;
}

function FulfillmentsSummaryItem({
  fulfillmentOrder,
  fulfillmentProducts,
  totalShippingCost,
  currency,
}: Readonly<EditOrderFulfillmentsSummaryProps>) {
  const t = useTranslationOrders();
  const {
    i18n: { language },
  } = useTranslation();

  const {
    shippingCosts,
    isLoading: isLoadingShippingCosts,
    isError: failedShippingCotization,
  } = useShippingCosts(fulfillmentOrder?.id);

  const fulfillmentNewCost = shippingCosts?.costs;

  const merchantCost =
    fulfillmentOrder?.shipping.merchantCost.value ?? totalShippingCost;

  const newMerchantCost =
    fulfillmentNewCost?.merchantCost.amount ?? merchantCost;

  const differentPreviousCost =
    newMerchantCost !== undefined && newMerchantCost !== merchantCost;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      paddingTop="1"
      paddingBottom="1"
    >
      <Box display="flex" flexDirection="row">
        <Text color="neutral-textHigh">
          {fulfillmentOrder?.number
            ? t('editOrders.fulfillmentsSummary.number', {
                number: formatTwoDigits(fulfillmentOrder.number, language),
              })
            : t('editOrders.fulfillmentsSummary.shipping')}
        </Text>
        {(!fulfillmentProducts.length || failedShippingCotization) && (
          <Box paddingLeft="1" display="flex">
            <Icon
              source={<ExclamationTriangleIcon size="small" />}
              color="danger-interactive"
            />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" gap="2-5">
        {!isLoadingShippingCosts && differentPreviousCost && (
          <Text fontSize="base" color="neutral-textLow">
            <Strikethrough>
              {formatCurrency(merchantCost, currency, language)}
            </Strikethrough>
          </Text>
        )}
        {isLoadingShippingCosts ? (
          <Text.Skeleton width="80px" />
        ) : (
          <div
            className={
              !fulfillmentProducts.length
                ? 'stratus--fulfillments-summary-item-container-danger'
                : ''
            }
          >
            <CurrencyPrice
              price={newMerchantCost ?? merchantCost}
              currency={currency}
            />
          </div>
        )}
      </Box>
    </Box>
  );
}

export default FulfillmentsSummaryItem;
