import SummaryFreemiumCardContent from './SummaryFreemiumCardContent';
import useGetSummaryFreemium from './useGetSummaryFreemium';
import GenericError from '../GenericError';

function SummaryFreemiumCard() {
  const {
    summaryFreemium,
    isLoading,
    isError,
    isSuccess,
    fetchSummaryFreemium,
  } = useGetSummaryFreemium();

  return (
    <>
      {isError && !summaryFreemium && !isLoading && (
        <GenericError onRetry={fetchSummaryFreemium} />
      )}
      {isLoading && <SummaryFreemiumCardContent.Skeleton />}

      {isSuccess && summaryFreemium && (
        <SummaryFreemiumCardContent values={summaryFreemium} />
      )}
    </>
  );
}

export default SummaryFreemiumCard;
