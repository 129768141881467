import { Box, Table } from '@nimbus-ds/components';
import { ErrorScreen } from 'commons/components';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';
import { AppsListDesktopHeader, AppsListDesktopRow } from './components';
import AppsListDesktopSkeleton from './components/AppsListDesktopSkeleton';
import './AppsListDesktop.scss';

function AppsListDesktop() {
  const { data: apps, isLoading, isError, refetch } = useGetAppsListQuery();

  if (isLoading) return <AppsListDesktopSkeleton />;
  if (isError || !apps) return <ErrorScreen onRetry={refetch} />;
  return (
    <Box flexDirection="column" gap="2" display="flex">
      <div className="stratus--apps-list-page-table">
        <Table>
          <AppsListDesktopHeader />
          <Table.Body>
            {apps.map((app) => (
              <AppsListDesktopRow app={app} key={`apps-list-item-${app.id}`} />
            ))}
          </Table.Body>
        </Table>
      </div>
    </Box>
  );
}

export default AppsListDesktop;
