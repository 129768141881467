import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Input, Stack, Grid } from '@tiendanube/components';

function DeliveryInfoCardSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerForm.deliveryInformation.title')} isCollapsible>
      <Stack column align="stretch">
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={2} md={2} lg={2}>
                <Input.Skeleton />
              </Grid.Column>
              <Grid.Column sm={4} md={4} lg={4}>
                <Input.Skeleton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={2} md={2} lg={2}>
                <Input.Skeleton />
              </Grid.Column>
              <Grid.Column sm={4} md={4} lg={4}>
                <Input.Skeleton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCardSkeleton;
