import React from 'react';
import { Box, Icon, Input, InputProps, Text } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import { ItemProps, SelectItemProps } from '../../types';
import DateItem from '../DateItem';
import NumberItem from '../NumberItem';
import SelectItem from '../SelectItem';
import TextItem from '../TextItem';

interface MetafieldInputProps {
  metafield: any;
  disabled?: boolean;
  showDescription?: boolean;
  showLoadingInput?: boolean;
  showError?: boolean;
  showInput?: boolean;
  itemTypeGenericProps: Partial<ItemProps | SelectItemProps>;
  itemTypeLoadingProps?: InputProps;
  footerSlot?: React.ReactNode;
}

export const MAP_ACTION_ITEM_TYPE = {
  text: TextItem,
  numeric: NumberItem,
  date: DateItem,
  select: SelectItem,
};

export default function MetafieldInput({
  metafield,
  disabled,
  showDescription = !!metafield.description,
  showLoadingInput = false,
  showError = false,
  showInput = true,
  itemTypeGenericProps,
  itemTypeLoadingProps = {},
  footerSlot,
}: MetafieldInputProps) {
  const t = useTranslationsMetafields();

  if (metafield === null) {
    return null;
  }

  const { app, name, type, description } = metafield;

  const itemTitle = app ? `${name} (${app})` : name;

  const ItemTypeGeneric = MAP_ACTION_ITEM_TYPE[metafield.type];
  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Text color="neutral-textLow">{itemTitle}</Text>
      {showLoadingInput && (
        <Input
          type={type === 'date' ? 'date' : 'text'}
          appendPosition="end"
          name={name}
          {...itemTypeLoadingProps}
        />
      )}
      {showInput && (
        <ItemTypeGeneric
          disabled={disabled}
          metafield={metafield}
          {...itemTypeGenericProps}
        />
      )}
      {showError && (
        <Box gap="1" display="inline-flex">
          <Icon
            color="danger-textLow"
            source={<ExclamationCircleIcon size={12} />}
          />
          <Text as="p" color="danger-textLow" fontSize="caption">
            {t('select.error')} {t('select.retry')}
          </Text>
        </Box>
      )}
      {showDescription && (
        <div className="stratus--metafield-action-item--text_ellipsis">
          <Text as="p" color="neutral-textLow" fontSize="caption">
            {description}
          </Text>
        </div>
      )}
      {!!footerSlot && footerSlot}
    </Box>
  );
}
