import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Text } from '@tiendanube/components';
import {
  ACTIONS_COL_BASIC,
  INPUT_COL_BASIC,
  INPUT_COL_GROW,
  PRODUCT_COL_GROW,
  VARIANTS_COL_BASIC,
} from '../utils';

const { Header, Cell } = DataTable;

interface ResultHeaderDesktopProps {
  hasVariants?: boolean;
}

function ResultHeaderDesktop({
  hasVariants = false,
}: ResultHeaderDesktopProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);

  return (
    <Header>
      <Cell grow={PRODUCT_COL_GROW}>
        <Text>{t('products.table.header.product')}</Text>
      </Cell>
      <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
        <Text>{t('products.table.header.stock')}</Text>
      </Cell>
      <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
        <Text>{t('products.table.header.price')}</Text>
      </Cell>
      <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
        <Text>{t('products.table.header.promotionalPrice')}</Text>
      </Cell>
      <Cell grow={INPUT_COL_GROW} basis={VARIANTS_COL_BASIC}>
        {hasVariants && <Text>{t('products.table.header.variants')}</Text>}
      </Cell>
      <Cell grow={INPUT_COL_GROW} basis={ACTIONS_COL_BASIC}>
        <Text>{t('products.table.header.actions')}</Text>
      </Cell>
    </Header>
  );
}

export default ResultHeaderDesktop;
