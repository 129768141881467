import { RocketIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { APP_STORE_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { handleClickExternal } from 'commons/utils/dom';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';

function AppsListCalloutCard() {
  const { t } = useTranslationPartnerApps('appsListPage.calloutCard');
  const language = useTranslationLanguage();

  return (
    <CalloutCard
      appearance="primary"
      icon={RocketIcon}
      onClick={handleClickExternal(APP_STORE_LINKS[language])}
      subtitle={t('subtitle')}
      title={t('title')}
    />
  );
}

export default AppsListCalloutCard;
