import { Checkbox, Text } from '@nimbus-ds/components';
import { CreateUserRequestDto, RoleResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface AppsSectionRolesProps {
  appsRoles: RoleResponseDto[];
  externalAppsRoles: RoleResponseDto[];
  values: CreateUserRequestDto;
  isRolesEditAvailable: boolean;
  onChangeRole: (
    event: React.ChangeEvent<HTMLInputElement>,
    role: RoleResponseDto,
  ) => void;
  onChangeAppRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function AppsSectionRoles({
  appsRoles,
  externalAppsRoles,
  values,
  isRolesEditAvailable,
  onChangeRole,
  onChangeAppRole,
}: Readonly<AppsSectionRolesProps>) {
  const t = useTranslationConfigurations('userFormPage');

  return (
    <>
      <Text fontWeight="bold" fontSize="highlight" color="neutral-textHigh">
        {t('rolesCard.sections.apps')}
      </Text>
      {appsRoles.length > 0 &&
        appsRoles.map((role: RoleResponseDto) => (
          <Stack column key={`role-${role.id}`} align="baseline">
            <Stack spacing="tight" align="center">
              <Checkbox
                label={role.shortName}
                name={role.id}
                checked={values.roles.includes(role.id)}
                disabled={!isRolesEditAvailable}
                onChange={(e) => onChangeRole(e, role)}
              />
            </Stack>
          </Stack>
        ))}
      {externalAppsRoles.length > 0 &&
        externalAppsRoles.map((role) => (
          <Checkbox
            key={`role-${role.id}`}
            label={role.shortName}
            name={role.id}
            checked={values.appRoles.includes(role.id)}
            disabled={!isRolesEditAvailable}
            onChange={onChangeAppRole}
          />
        ))}
    </>
  );
}
