import { Responsive } from 'commons/components';
import {
  HiddenVariantInfo,
  HiddenVariantInfoProps,
  VisibleVariantInfoDesktop,
  VisibleVariantInfoDesktopProps,
  VisibleVariantInfoMobile,
  VisibleVariantInfoMobileProps,
} from './components';

type VariantInfoProps = VisibleVariantInfoDesktopProps &
  VisibleVariantInfoMobileProps &
  HiddenVariantInfoProps;

function VariantInfo({
  variant,
  locations,
  index,
  isDigital,
  onChange,
  onClickImage,
  onClickVariant,
  onOpenVariantStock,
}: VariantInfoProps) {
  if (variant.show)
    return (
      <Responsive
        mobile={
          <VisibleVariantInfoMobile
            variant={variant}
            locations={locations}
            index={index}
            isDigital={isDigital}
            onClickImage={onClickImage}
            onClickVariant={onClickVariant}
            onOpenVariantStock={onOpenVariantStock}
          />
        }
        desktop={
          <VisibleVariantInfoDesktop
            variant={variant}
            index={index}
            isDigital={isDigital}
            onClickImage={onClickImage}
            onClickVariant={onClickVariant}
          />
        }
      />
    );

  return (
    <HiddenVariantInfo
      isDigital={isDigital}
      variant={variant}
      index={index}
      onChange={onChange}
    />
  );
}

export default VariantInfo;
