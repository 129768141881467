import { useEffect } from 'react';
import { Link, Stack, Text } from '@tiendanube/components';
import { CogIcon } from '@tiendanube/icons';
import { Responsive } from 'commons/components';
import {
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useModal } from 'commons/hooks';
import {
  useAbandonedCarts,
  useAbandonedCartConfigEmail,
} from 'domains/Orders/AbandonedCart/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  AbandonedCartsListEmpty,
  AbandonedCartsListError,
  AbandonedCartsListMobile,
  AbandonedCartsConfigEmailModal,
  AbandonedCartsListDesktop,
  EmailConfigStatusLabel,
  OpportunityAlert,
} from './components';

function AbandonedCartsList() {
  const t = useTranslationOrders();
  const [isOpen, openModal, closeModal] = useModal();
  const { abandonedCartEmailConfig } = useAbandonedCartConfigEmail();
  const {
    isError,
    fetchAbandonedCarts,
    isEmpty,
    isSuccess,
    abandonedCartsList,
    isLoading,
    isIdle,
  } = useAbandonedCarts();

  useEffect(() => {
    if (abandonedCartsList.length === 0 && isIdle) {
      fetchAbandonedCarts();
    }
  }, [abandonedCartsList.length, fetchAbandonedCarts, isIdle]);

  return (
    <>
      <IonPageStratus
        width="medium"
        headerTop={
          <HeaderTop actions={<Link onClick={openModal} icon={CogIcon} />} />
        }
        headerContent={
          <HeaderContent
            title={t('abandonedCarts.title')}
            actions={
              !isLoading &&
              !isEmpty && (
                <ActionsDesktop
                  actions={[
                    {
                      children: `${t('abandonedCarts.config')}`,
                      icon: CogIcon,
                      onClick: openModal,
                      appearance: 'primary',
                    },
                  ]}
                />
              )
            }
          />
        }
      >
        <Stack column align="stretch">
          {isEmpty ? (
            <AbandonedCartsListEmpty onClickConfig={openModal} />
          ) : (
            <>
              <Text>{t('abandonedCarts.description')}</Text>
              <Stack.Item>
                <EmailConfigStatusLabel
                  status={abandonedCartEmailConfig}
                  isLoading={isLoading}
                />
              </Stack.Item>
            </>
          )}
          {isError && (
            <AbandonedCartsListError onRetryError={fetchAbandonedCarts} />
          )}
          {isSuccess && !isEmpty && <OpportunityAlert />}
          {!isEmpty && !isError && (
            <Responsive
              desktop={<AbandonedCartsListDesktop />}
              mobile={<AbandonedCartsListMobile />}
            />
          )}
        </Stack>
      </IonPageStratus>
      <AbandonedCartsConfigEmailModal isOpen={isOpen} onClose={closeModal} />
    </>
  );
}

export default AbandonedCartsList;
