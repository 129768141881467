import { Title, Card, Checkbox, Text } from '@nimbus-ds/components';
import { Input } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useTranslationAccount } from 'domains/Account/hooks';

function NameAndDescriptionBusinessDataCardSkeleton() {
  const t = useTranslationAccount();

  return (
    <Card>
      <Card.Header>
        <Title as="h3">
          {t('businessDataPage.nameAndDescriptionCard.title')}
        </Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="loose">
          <Input.Skeleton />
          <Input.Skeleton rows={2} />
          <Checkbox.Skeleton width="200px" />
          <Text.Skeleton width="fill" />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default NameAndDescriptionBusinessDataCardSkeleton;
