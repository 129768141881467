import { useCallback } from 'react';
import { useAppDispatch } from 'App/store';
import { resetAuthenticationFactors } from 'domains/Account/accountSlice';

function useResetAuthenticationFactors() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(resetAuthenticationFactors());
  }, [dispatch]);
}

export default useResetAuthenticationFactors;
