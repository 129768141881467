import { PaymentProviderDto } from '@tiendanube/common';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import useGetPaymentProviderSettingsRoute from '../useGetPaymentProviderSettingsRoute';

function useGetNuvemPagoFromPorviderList(
  paymentProviders: PaymentProviderDto[] | null,
) {
  const found = paymentProviders?.find((current) =>
    muvemPagoOrPagoNubeIds.includes(current.id),
  );

  const { goToSettingsPage } = useGetPaymentProviderSettingsRoute(
    found?.id || '',
  );

  return {
    nuvemPagoId: found?.id,
    nuvemPagoIsActive: found?.isActive,
    nuvemPagoGoToSettings: goToSettingsPage,
  };
}

export default useGetNuvemPagoFromPorviderList;
