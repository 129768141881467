import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './configurations.es-AR.json';
import esCL from './configurations.es-CL.json';
import esCO from './configurations.es-CO.json';
import esMX from './configurations.es-MX.json';
import ptBR from './configurations.pt-BR.json';

const configurationsTranslations: DomainTranslations = {
  name: 'configurations',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
    [Locale.COLOMBIA]: esCO,
    [Locale.CHILE]: esCL,
  },
};

export default configurationsTranslations;
