import { Component, ReactNode } from 'react';
import { LoggerRequestDto } from '@tiendanube/common';
import log, { logError } from 'commons/logger';
import ErrorScreen from './ErrorScreen';

interface InterfaceProps {
  children: ReactNode;
}

interface InterfaceState {
  hasError: boolean;
}

const reload = () => {
  window.location.href = '/';
};

class ErrorBoundary extends Component<InterfaceProps, InterfaceState> {
  state = { hasError: false };

  static getDerivedStateFromError(): InterfaceState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    const data: LoggerRequestDto = {
      message: 'Stratus App Crash',
      level: 'error',
      location: window.location.href,
      error: {
        message: error.message,
        stack: error.stack,
      },
    };
    console.error('App crashing', JSON.stringify(data), error);
    log(data);
    logError('App crashing', error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorScreen onReload={reload} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
