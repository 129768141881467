import {
  DataTable,
  EmptySearch,
  ErrorState,
  Stack,
} from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import PaginationStratus from 'commons/components/PaginationStratus';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useInventory } from 'domains/Catalog/Inventory/pages/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import {
  ResultDesktopSkeleton,
  ResultHeaderDesktop,
  ResultRowDesktop,
} from './components';

function InventoryResultsDesktop(): JSX.Element {
  const t = useTranslationCatalog();

  const {
    isLoading: isLoadingProducts,
    isSuccess,
    isError: isErrorProducts,
    productCount,
    productIds,
    filters,
    hasFilters,
    loadInventory,
    setPage,
  } = useInventory();

  const {
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
    fetchLocations,
  } = useLocations();

  const { scrollToTop } = useLayoutScroll();

  const handleSelectPage = (page: number) => {
    scrollToTop();
    setPage(page);
  };

  const locationId = filters.locationId !== '' ? filters.locationId : undefined;

  if (isErrorLocations || (isErrorProducts && productIds.length === 0)) {
    return (
      <ErrorState
        title={t('products.errorResults.title')}
        action={{
          children: t('products.errorResults.action'),
          onClick: isErrorLocations ? fetchLocations : loadInventory,
        }}
      />
    );
  }

  if (isSuccess && productIds.length === 0 && hasFilters) {
    return (
      <EmptySearch
        text={t('products.withoutResults.text')}
        title={t('products.withoutResults.title')}
      />
    );
  }

  const isLoading = isLoadingProducts || isLoadingLocations;

  return (
    <div className="stratus--product-results-desktop">
      <Stack spacing="tight" align="stretch" column>
        <DataTable>
          {isLoading && <ResultDesktopSkeleton />}
          {!isLoading && productIds && (
            <>
              <ResultHeaderDesktop />
              {productIds.map((id) => (
                <ResultRowDesktop key={id} id={id} locationId={locationId} />
              ))}
            </>
          )}
        </DataTable>
        {!isLoading && (
          <PaginationStratus
            currentPage={filters.page}
            totalItems={productCount}
            itemName={t('products.item')}
            page={filters.page}
            perPage={20}
            onPageSelect={handleSelectPage}
          />
        )}
      </Stack>
    </div>
  );
}

export default InventoryResultsDesktop;
