import { Alert, Text } from '@nimbus-ds/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function ActivateSSLAlert() {
  const t = useTranslationConfigurations('domains.activateSSLAlert');

  return (
    <Alert appearance="warning" title={t('title')}>
      <Text>{t('text')}</Text>
    </Alert>
  );
}
