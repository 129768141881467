import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanGetLinkAuthorizationStatus,
  getLinkAuthorizationStatus,
  getLinkAuthorization,
} from 'domains/PartnersApps/partnersAppsSlice';

function useGetAppsAuthorizationLink() {
  const dispatch = useAppDispatch();

  const status = useSelector(getLinkAuthorizationStatus);

  const getAppsLinkAuthorization = useCallback(
    async (id) => {
      const result = await dispatch(getLinkAuthorization(id));
      const { redirectLink } = unwrapResult(result);
      dispatch(cleanGetLinkAuthorizationStatus());
      return redirectLink;
    },
    [dispatch],
  );

  return {
    getAppsLinkAuthorization,
    status,
  };
}

export default useGetAppsAuthorizationLink;
