import { Card, Box, Thumbnail, Title, Text, Link } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { BlockWithTitle } from 'commons/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingEnaClick } from 'domains/Dashboard/tracking';
import ImageSrc from './images/banner-mx.png';

function EcommerceCourseCard() {
  const t = useTranslationDashboard('ecommerceCourseCard');
  return (
    <ShowByCountry allowedCountries={[Country.MX]}>
      <BlockWithTitle title={t('title')}>
        <Card>
          <Card.Body>
            <Box
              display="flex"
              flexDirection={{
                lg: 'row',
                md: 'row',
                xs: 'column',
              }}
              gap="2"
            >
              <Box
                borderStyle="solid"
                borderWidth="2"
                borderColor="neutral-surface"
                borderRadius="2"
                minWidth={{
                  md: '215px',
                }}
                maxWidth={{
                  lg: '237px',
                }}
              >
                <Thumbnail aspectRatio="16/9" alt="image" src={ImageSrc} />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap="4"
                justifyContent="center"
                maxWidth="703px"
              >
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h3">{t('subtitle')}</Title>
                  <Text>{t('description')}</Text>
                </Box>
                <Link
                  textDecoration="none"
                  as="a"
                  href={t('link.href')}
                  appearance="primary"
                  target="_blank"
                  onClick={trackingEnaClick}
                >
                  {t('link.text')}
                </Link>
              </Box>
            </Box>
          </Card.Body>
        </Card>
      </BlockWithTitle>
    </ShowByCountry>
  );
}

export default EcommerceCourseCard;
