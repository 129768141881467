import { ReactNode } from 'react';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ShippingPageProps {
  children: ReactNode;
  handleSave: () => void;
  title: string;
  isUpdating?: boolean;
  isError?: boolean;
  isLoading?: boolean;
}
function ShippingPage({
  children,
  handleSave,
  title,
  isUpdating = false,
  isError = false,
  isLoading = false,
}: ShippingPageProps) {
  const t = useTranslationShipping();

  const { goBack } = useNavegate();
  const backNavitation = {
    children: t('deliveryMethods.title'),
    onClick: goBack,
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={backNavitation} />}
      headerContent={<HeaderContent title={title} />}
    >
      {children}
      {!isLoading && !isError && (
        <CancelAndConfirmButtons
          isLoading={isUpdating}
          onCancel={goBack}
          onConfirm={handleSave}
        />
      )}
      {isLoading && !isError && <CancelAndConfirmButtons.Skeleton />}
    </IonPageStratus>
  );
}

export default ShippingPage;
