import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { EmptySearch, ErrorState, Link } from '@tiendanube/components';
import VirtualDataList from 'commons/components/VirtualDataList';
import ContentProductItemList from './ContentProductItemList';
import ProductResultsListSkeleton from './ProductResultsListSkeleton';
import { useProductsList } from '../../../hooks';
import { useBulkActionProducts } from '../hooks/useBulkActionProducts';

interface ProductResultsInterface {
  onToggleEditMode: () => void;
  isEditMode: boolean;
}

function ProductResults({
  isEditMode,
  onToggleEditMode,
}: ProductResultsInterface): JSX.Element {
  const { t } = useTranslation([DomainEnum.CATALOG]);

  const {
    isLoading,
    isSuccess,
    isError,
    productsCount,
    productsIds,
    hasFilters,
    filters,
    refreshProductsList,
    getMoreProducts,
  } = useProductsList();

  const selectAllElement = filters.q ? undefined : (
    <Link
      appearance="primary"
      size="small"
      onClick={() => handleOnSelectAll(!selectAll)}
    >
      {t(`products.bulkActions.selectAll`, { count: productsCount })}
    </Link>
  );

  const {
    bulkAction,
    handleOnSelectRow,
    handleOnSelectAll,
    selectedRowsId,
    selectAll,
  } = useBulkActionProducts({
    rowsId: productsIds,
    selectAllElement,
  });

  const shouldShowSpinner = isLoading && productsIds.length !== 0;

  if (isLoading && productsIds.length === 0) {
    return <ProductResultsListSkeleton />;
  }

  if (isError && productsIds.length === 0) {
    return (
      <ErrorState
        title={t('products.errorResults.title')}
        action={{
          children: t('products.errorResults.action'),
          onClick: refreshProductsList,
        }}
      />
    );
  }

  if (isSuccess && productsIds.length === 0 && hasFilters) {
    return (
      <EmptySearch
        text={t('products.withoutResults.text')}
        title={t('products.withoutResults.title')}
      />
    );
  }

  return (
    <VirtualDataList
      editMode={isEditMode}
      onEditMode={onToggleEditMode}
      bulkAction={bulkAction}
      selectedRowsId={selectedRowsId}
      onSelectRow={handleOnSelectRow}
      onRefresh={refreshProductsList}
      isPullable={!isEditMode}
      data={productsIds}
      endReached={getMoreProducts}
      itemContent={(index, id) => (
        <ContentProductItemList key={index} id={id} />
      )}
      showFooter={shouldShowSpinner}
    />
  );
}

export default memo(ProductResults);
