import { Fragment } from 'react';
import { Box, IconButton, Text } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { LanguageType, VariantResponseDto } from '@tiendanube/common';
import { DEFAULT_LANGUAGE } from 'App/i18n';
import { AttributesToFix } from 'domains/Catalog/Products/productsSlice';
import { getVariantsValuesByLanguage } from '../../utils';
import FieldAttribute from '../FieldAttribute';

interface DuplicateAttributesEditProps {
  values: AttributesToFix;
  language?: LanguageType;
  variants: VariantResponseDto[];
  setFieldValue: (name: string, value: any) => void;
}
function DuplicateAttributesEdit({
  values,
  language = DEFAULT_LANGUAGE,
  variants,
  setFieldValue,
}: DuplicateAttributesEditProps) {
  const handleDeleteClick = (index: number) => {
    setFieldValue('toDelete', [...values.toDelete, index]);
  };

  return (
    <>
      {values.attributes.map((_, index) => (
        <Fragment key={index}>
          {!values.toDelete.includes(index) && (
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom="4"
              key={index}
            >
              <Box
                display="grid"
                gridTemplateColumns="45fr 4fr"
                gap="2"
                key={index}
              >
                <Box>
                  <FieldAttribute
                    values={values}
                    language={language}
                    setFieldValue={setFieldValue}
                    index={index}
                  />
                  <Text fontSize="caption">
                    {`Valores: ${getVariantsValuesByLanguage(
                      variants,
                      index,
                      language,
                    ).join(', ')}`}
                  </Text>
                </Box>
                <Box>
                  <IconButton
                    size="2.2rem"
                    source={<TrashIcon size="small" />}
                    onClick={() => handleDeleteClick(index)}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Fragment>
      ))}
    </>
  );
}

export default DuplicateAttributesEdit;
