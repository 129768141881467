import { useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import { FROM_PAYMENT_QUERY } from '../checkoutRoutes';
import { trackingBillingPayOrderCheckoutFinish } from '../tracking';

export default function useTrackBillingPayOrderCheckoutFinish() {
  const { hasSearchParam } = useNavegate();
  const fromPayment = hasSearchParam(FROM_PAYMENT_QUERY);

  useEffect(() => {
    if (fromPayment) {
      trackingBillingPayOrderCheckoutFinish();
    }
  }, [fromPayment]);
}
