import { SummaryType } from 'domains/Statistics/types';
import { CHART_COLORS } from 'domains/Statistics/utils';

export const COLORS: Record<SummaryType, string> = {
  uniqueVisits: CHART_COLORS.lightBlue,
  orders: CHART_COLORS.brightRed,
  billing: CHART_COLORS.darkPurple,
  averageSpend: CHART_COLORS.deepPurple,
  conversion: CHART_COLORS.electricBlue,
};
