import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Card,
  InteractiveList,
  InterfaceInteractiveListOptions,
} from '@tiendanube/components';
import { StopIcon, MoneyIcon, CheckCircleIcon } from '@tiendanube/icons';
import { useHasPermission } from 'domains/Auth/hooks';

export interface OrderStatusValuesInterface {
  paymentStatus: 'unpaid' | 'pending_confirmation' | 'paid';
}

interface OrderStatusProps extends OrderStatusValuesInterface {
  onChange: ({ value, name }: { value: string; name: string }) => void;
}

function OrderStatus({
  paymentStatus,
  onChange,
}: OrderStatusProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const canSetPaymentReceived = useHasPermission('set_payment_received');
  const options: InterfaceInteractiveListOptions[] = [
    {
      title: `${t('draftOrders.newDraftOrder.orderStatus.notMade')}`,
      description: `${t(
        'draftOrders.newDraftOrder.orderStatus.notMadeDescription',
      )}`,
      name: 'unpaid',
      icon: StopIcon,
      iconAppearance: 'default',
      active: paymentStatus === 'unpaid',
    },
    {
      title: `${t('draftOrders.newDraftOrder.orderStatus.inProcess')}`,
      description: `${t(
        'draftOrders.newDraftOrder.orderStatus.inProcessDescription',
      )}`,
      name: 'pending_confirmation',
      icon: MoneyIcon,
      iconAppearance: 'warning',
      active: paymentStatus === 'pending_confirmation',
    },
  ];

  if (canSetPaymentReceived) {
    options.push({
      title: `${t('draftOrders.newDraftOrder.orderStatus.received')}`,
      description: `${t(
        'draftOrders.newDraftOrder.orderStatus.receivedDescription',
      )}`,
      name: 'paid',
      icon: CheckCircleIcon,
      iconAppearance: 'success',
      active: paymentStatus === 'paid',
    });
  }

  const handleOnChange = ([status]: string[]) => {
    onChange({ name: 'paymentStatus', value: status });
  };

  return (
    <Card title={t('draftOrders.newDraftOrder.orderStatus.title')}>
      <InteractiveList
        onChange={handleOnChange}
        mode="single"
        options={options}
      />
    </Card>
  );
}

export default OrderStatus;
