import { InvoicesSchema } from './types';

export const initialState: InvoicesSchema = {
  address: {
    countries: {
      data: null,
      status: 'idle',
    },
    addresses: {
      data: null,
      status: 'idle',
    },
    provinces: {
      status: 'idle',
      data: null,
    },
    cities: {
      status: 'idle',
      data: {},
    },
  },
  fiscalData: {
    status: 'idle',
    cities: {
      status: 'idle',
      data: [],
    },
    validateCuit: {
      status: 'idle',
      data: {
        address: '',
        name: '',
        numberId: '',
        numberIdType: 'CUIT',
        type: 'A',
      },
    },
    invoiceInformation: {
      status: 'idle',
      data: {
        id: undefined,
        billingEngineId: '',
        name: '',
        numberId: '',
        numberIdType: 'CC',
        address: '',
        number: undefined,
        floor: '',
        locality: '',
        cityId: undefined,
        cityName: '',
        provinceId: undefined,
        provinceName: '',
        countryId: undefined,
        countryName: '',
        type: 'A',
        vatCondition: '',
        personType: 'PERSONA_JURIDICA',
      },
      hasInvoiceInfo: false,
      billingEngineInvoiceInfoStatus: 'idle',
      hasBillingEngineInvoiceInfo: false,
    },
  },
  invoices: {
    status: {
      get: 'idle',
      update: 'idle',
      link: 'idle',
    },
    data: {
      results: [],
      pagination: {
        totalPages: 0,
        currentPage: 0,
        totalResults: 0,
        perPage: 0,
        nextPage: 0,
      },
    },
  },
};
