import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

export function FreeShippingListHeader() {
  const t = useTranslationMarketing('freeShipping.headers');

  return (
    <Table.Head>
      <CustomHeaderCell width={{ xl: '220px' }}>
        {t('shippingMethod')}
      </CustomHeaderCell>
      <CustomHeaderCell width={{ xl: '120px', lg: '100px' }}>
        {t('minimalPrice')}
      </CustomHeaderCell>
      <CustomHeaderCell width="auto">{t('categories')}</CustomHeaderCell>
      <CustomHeaderCell width={{ xl: 'auto', lg: '100px' }}>
        {t('shippingZones')}
      </CustomHeaderCell>
      <CustomHeaderCell width={{ xl: '140px', md: '120px' }}>
        {t('status')}
      </CustomHeaderCell>
      <CustomHeaderCell width={{ md: '120px' }}>
        {t('actions')}
      </CustomHeaderCell>
    </Table.Head>
  );
}
