import React from 'react';
import { Box, Button, Card, Text, Title } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { Divider } from './Divider';
import { Feature } from './Feature';

export function MarketingAutomationPlans({
  onClick,
}: React.PropsWithChildren<{ onClick: () => void }>) {
  const t = useTranslationMarketing('marketingAutomation');

  const freePlan = [
    { feature: t('plans.plan1.features.feature1'), disabled: false },
    { feature: t('plans.plan1.features.feature2'), disabled: false },
    { feature: t('plans.plan1.features.feature3'), disabled: false },
    { feature: t('plans.plan1.features.feature4'), disabled: false },
    { feature: t('plans.plan1.features.feature5'), disabled: false },
    { feature: t('plans.plan1.features.feature6'), disabled: false },
    { feature: t('plans.plan1.features.feature7'), disabled: false },
    { feature: t('plans.plan1.features.feature8'), disabled: false },
    { feature: t('plans.plan1.features.feature9'), disabled: true },
    { feature: t('plans.plan1.features.feature10'), disabled: true },
  ];

  const monthlyPlan = [
    { feature: t('plans.plan2.features.feature1'), disabled: false },
    { feature: t('plans.plan2.features.feature2'), disabled: false },
    { feature: t('plans.plan2.features.feature3'), disabled: false },
    { feature: t('plans.plan2.features.feature4'), disabled: false },
    { feature: t('plans.plan2.features.feature5'), disabled: false },
    { feature: t('plans.plan2.features.feature6'), disabled: false },
    { feature: t('plans.plan2.features.feature7'), disabled: false },
    { feature: t('plans.plan2.features.feature8'), disabled: false },
    { feature: t('plans.plan2.features.feature9'), disabled: false },
    { feature: t('plans.plan2.features.feature10'), disabled: false },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap="4">
        <Box display="flex" flexDirection="column" alignItems="center" gap="2">
          <Title as="h2" textAlign="center">
            {t('plans.title')}
          </Title>
          <Text fontSize="highlight" textAlign="center">
            {t('plans.subtitle')}
          </Text>
        </Box>
        <Button appearance="primary" onClick={onClick}>
          {t('hero.button')}
        </Button>
      </Box>
      <Layout width="100%" columns="2 - symmetric">
        <Layout.Section justifyContent="center" alignItems="flex-start">
          <Card>
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4">
                <Title as="h2">{t('plans.plan1.title')}</Title>
                <Divider />
                <Box display="flex" flexDirection="column" gap="1">
                  {freePlan.map(({ feature, disabled }) => (
                    <Feature
                      key={`ma-fp-${feature}`}
                      feature={feature}
                      disabled={disabled}
                    />
                  ))}
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Layout.Section>
        <Layout.Section justifyContent="center" alignItems="flex-end">
          <Card>
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4">
                <Title as="h2">{t('plans.plan2.title')}</Title>
                <Divider />
                <Box display="flex" flexDirection="column" gap="1">
                  {monthlyPlan.map(({ feature, disabled }) => (
                    <Feature
                      key={`ma-mp-${feature}`}
                      feature={feature}
                      disabled={disabled}
                    />
                  ))}
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Layout.Section>
      </Layout>
    </>
  );
}
