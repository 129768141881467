import { IonPageStratus, Responsive } from 'commons/components';
import {
  InvoiceHistoryMobile,
  InvoiceHistoryWeb,
} from '../InvoiceHistory/components';

function SkeletonComponent(): JSX.Element {
  return (
    <IonPageStratus>
      <Responsive
        mobile={<InvoiceHistoryMobile.Skeleton />}
        desktop={<InvoiceHistoryWeb.Skeleton />}
      />
    </IonPageStratus>
  );
}

export default SkeletonComponent;
