import { useEffect, useMemo } from 'react';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Button, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import {
  FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER,
  FEATURE_SEARCH_FILTER,
} from 'App/features';
import { useNavegate } from 'App/hooks';
import {
  CATALOG_METAFIELD_FILTERS_CALLOUT,
  CATALOG_METAFIELD_FILTERS_DROPDOWN,
  CATALOG_SHOW_FILTERS,
} from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useProductsFilters } from 'domains/Catalog/Products/pages/hooks';
import { ProductsFiltersType } from 'domains/Catalog/Products/productsServices/types';
import useGetProductsAndVariantsMetafields from 'domains/Metafields/hooks/ProductsAndVariants/useGetProductsAndVariantsMetafields';
import {
  Categories,
  FreeShipping,
  MetafieldsVariantsFilters,
  PromotionalPrice,
  Published,
  Stock,
  StockQuantity,
  WeightDimention,
} from './components';

export const defaultValuesFilter: ProductsFiltersType = {
  sortBy: '',
  categoryId: '',
  published: '',
  freeShipping: '',
  stock: '',
  maxStock: '',
  minStock: '',
  promotionalPrice: '',
  weightDimension: '',
};

interface FilterModalProps {
  show: boolean;
  hideModal: () => void;
  filter: ProductsFiltersType;
  addParam: (f: ProductsFiltersType) => void;
}

function FilterModal({ show, ...props }: FilterModalProps) {
  const { t } = useTranslation([Domain.CATALOG]);

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={props.hideModal}
      headerContent={t('products.filter.filterBy')}
    >
      <FilterModalContent {...props} />
    </ModalAside>
  );
}

function FilterModalContent({
  filter,
  addParam,
  hideModal,
}: Omit<FilterModalProps, 'show'>): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);
  const { goTo } = useNavegate();

  const {
    values,
    errors,
    selecteds,
    validateFilters,
    handleChange,
    handleChangeMetafields,
    setAllValues,
  } = useProductsFilters(filter);

  const {
    metafields,
    apiMetafields,
    status: metafieldsStatus,
    fetchMetafields,
  } = useGetProductsAndVariantsMetafields();

  const allMetafields = useMemo(
    () =>
      [...(metafields ?? []), ...(apiMetafields ?? [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [metafields, apiMetafields],
  );

  useEffect(() => {
    setAllValues(filter);
  }, [filter, setAllValues]);

  const showStockQuantity = values.stock === 'quantity';

  const handleApply = useUpsellFlow({
    title: t('products.filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    asAside: true,
    callback: () => {
      if (!validateFilters()) return;

      const buildValues = {
        ...values,
        ...(!showStockQuantity && { maxStock: '', minStock: '' }),
      } as ProductsFiltersType;

      addParam(buildValues);
      hideModal();
    },
    trackingSource: CATALOG_SHOW_FILTERS,
  });

  const handleReset = () => {
    setAllValues(defaultValuesFilter);
  };

  const handleChangeWithUpsell = useUpsellFlow({
    title: t('products.filter.upsell.openFilters'),
    featureKey: FEATURE_SEARCH_FILTER,
    callback: handleChange,
    trackingSource: CATALOG_SHOW_FILTERS,
    asAside: true,
  });

  const onChangeMetafieldFilter = useUpsellFlow({
    title: t('products.filter.upsell.metafieldFilters'),
    featureKey: FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER,
    trackingSource: CATALOG_METAFIELD_FILTERS_DROPDOWN,
    callback: handleChangeMetafields,
    asAside: true,
  });

  const onCickMetafieldCallout = useUpsellFlow({
    title: t('products.filter.upsell.metafieldFilters'),
    featureKey: FEATURE_METAFIELDS_PRODUCTS_VARIANTS_ADMIN_FILTER,
    trackingSource: CATALOG_METAFIELD_FILTERS_CALLOUT,
    callback: () => {
      goTo('/settings/metafields');
    },
    asAside: true,
  });

  return (
    <Stack column align="stretch">
      <Categories value={values.categoryId} onChange={handleChangeWithUpsell} />

      <Stock value={values.stock} onChange={handleChangeWithUpsell} />

      {showStockQuantity && (
        <StockQuantity
          min={values.minStock}
          max={values.maxStock}
          onChange={handleChangeWithUpsell}
          minError={errors.minStock}
          maxError={errors.maxStock}
        />
      )}

      <PromotionalPrice
        value={values.promotionalPrice}
        onChange={handleChangeWithUpsell}
      />

      <Published value={values.published} onChange={handleChangeWithUpsell} />

      <FreeShipping
        value={values.freeShipping}
        onChange={handleChangeWithUpsell}
      />

      <WeightDimention
        value={values.weightDimension}
        onChange={handleChangeWithUpsell}
      />

      {!!allMetafields && allMetafields.length > 0 ? (
        <MetafieldsVariantsFilters
          metafields={allMetafields}
          status={metafieldsStatus}
          fetchMetafields={fetchMetafields}
          selecteds={selecteds}
          onChange={onChangeMetafieldFilter}
        />
      ) : (
        <CalloutCard
          appearance="primary"
          icon={PlusCircleIcon}
          title={t('products.filter.metafieldFiltersCalloutTitle')}
          subtitle={t('products.filter.metafieldFiltersCalloutSubtitle')}
          onClick={onCickMetafieldCallout}
        />
      )}

      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button onClick={handleReset}>{`${t(
            'products.filter.cleanFilter',
          )}`}</Button>
        </Stack.Item>
        <Stack.Item>
          <Button onClick={handleApply} appearance="primary">
            {`${t('products.filter.filter')}`}
          </Button>
        </Stack.Item>
      </Stack>
    </Stack>
  );
}

export default FilterModal;
