import { useParams } from 'react-router-dom';
import { InterfaceNameValue, Select, Text } from '@tiendanube/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { UseLocationsResult } from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import { useProductDetails } from '../../../EditProductPage/hooks';
import LocationErrorState from '../../Location/LocationErrorState';

interface LocationSelectProps {
  locationId?: string | null;
  onChange: (event: InterfaceNameValue) => void;
  locationResult: UseLocationsResult;
}

function LocationSelect({
  locationId,
  onChange,
  locationResult: {
    locationMain,
    locations,
    fetchLocations,
    isLoading: isLoadingLocations,
    isError,
  },
}: LocationSelectProps): JSX.Element | null {
  const t = useTranslationCatalog();

  const { id } = useParams<{ id: string }>();

  const { loading: isLoadingProduct } = useProductDetails(id);

  if (isError) return <LocationErrorState onRetryError={fetchLocations} />;

  if (isLoadingLocations || isLoadingProduct)
    return <Text.Skeleton width="fill" />;

  const optionsSelect = locations.map((location) => {
    const { id, main } = location;
    const name = main
      ? `${location.name} (${t('products.detail.locationSelectMain')})`
      : location.name;
    return {
      value: id,
      label: name,
    };
  });

  return (
    <Select
      label={t('products.detail.location')}
      name="locationId"
      value={locationId ?? locationMain?.id}
      options={optionsSelect}
      onChange={onChange}
      helpText={t('products.detail.locationHelp')}
    />
  );
}

export default LocationSelect;
