import { Trans } from 'react-i18next';
import { BaseCard, Button, Stack, Title } from '@tiendanube/components';
import { CalendarIcon, MailIcon, TruckIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { ListItem } from 'commons/components';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function SuccessCard(): JSX.Element {
  const { goTo } = useNavegate();
  const handleSuccess = () => goTo(SHIPPING_METHODS_BASE_ROUTE);
  const t = useTranslationShipping();

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{`${t(
          'oca.requestRegistrationSuccess.title',
        )}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <ListItem
            icon={CalendarIcon}
            content={
              <Trans
                t={t}
                i18nKey="oca.requestRegistrationSuccess.items.calendar"
                components={{ bold: <strong /> }}
              />
            }
          />
          <ListItem
            icon={MailIcon}
            content={
              <Trans
                t={t}
                i18nKey="oca.requestRegistrationSuccess.items.mail"
                components={{ bold: <strong /> }}
              />
            }
          />
          <ListItem
            icon={TruckIcon}
            content={
              <Trans
                t={t}
                i18nKey="oca.requestRegistrationSuccess.items.truck"
                components={{ bold: <strong /> }}
              />
            }
          />
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button appearance="primary" onClick={handleSuccess}>
          {`${t('oca.requestRegistrationSuccess.confirmButton')}`}
        </Button>
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default SuccessCard;
