export const PATH = {
  paymentsAndSubscriptions: '/account/checkout/finish/',
  invoiceHistory: '/account/invoices/',
  plans: '/account/plans/',
  authenticationFactor: '/account/security/authentication-factor',
  sessionManagement: '/account/security/session-management',
  socialNetworks: '/account/social-networks/',
  dataBusinessAndCancellation: '/account/shop/',
  logout: '/auth/logout/',
};

export const addOldAdminPrefix = (url: string): string => `/admin${url}`;
