import { HandleChangeInterface } from '../../hooks/useProductForm';
import SectionProductsAndVariantsMetafieldsExisting from '../SectionProductsAndVariantsMetafieldsExisting';
import SectionProductsAndVariantsMetafieldsNew from '../SectionProductsAndVariantsMetafieldsNew';
import {
  MetafieldSelectedsOrErrorType,
  OnChangeToAllMetafieldsVariants,
} from '../Variants/types';

interface SectionProductsAndVariantsMetafieldsWrapperProps {
  hasVariant: boolean;
  productId: string;
  source: 'page' | 'modal';
  selecteds: {
    product: MetafieldSelectedsOrErrorType;
    product_variant: MetafieldSelectedsOrErrorType;
  };
  apiSelecteds: {
    product: MetafieldSelectedsOrErrorType;
    product_variant: MetafieldSelectedsOrErrorType;
  };
  onVariantChange: ({ name, value }: HandleChangeInterface) => void;
  onProductChange?: ({ name, value }: HandleChangeInterface) => void;
  onChangeToAllMetafieldsVariants?: OnChangeToAllMetafieldsVariants;
}

export default function SectionProductsAndVariantsMetafieldsWrapper({
  hasVariant,
  productId,
  source,
  ...rest
}: SectionProductsAndVariantsMetafieldsWrapperProps) {
  const getResource = () => {
    if (hasVariant && source === 'page') {
      return 'product';
    }

    if (hasVariant && source === 'modal') {
      return 'product_variant';
    }
  };

  const resource = getResource();

  return productId ? (
    <SectionProductsAndVariantsMetafieldsExisting
      id={productId}
      resource={resource}
      {...rest}
    />
  ) : (
    <SectionProductsAndVariantsMetafieldsNew resource={resource} {...rest} />
  );
}
