import { useCallback } from 'react';
import { useNavegate } from 'App/hooks';
import { useDismissibleComponent, useModal } from 'commons/hooks';
import { FROM_PAYMENT_QUERY } from 'domains/Billing/Checkout/checkoutRoutes';
import { useGetLastPayment } from 'domains/Billing/Checkout/hooks';

function useFirstPaymentModal() {
  const { hasSearchParam } = useNavegate();

  const { shouldShow: isNotDismissed, dismiss } = useDismissibleComponent(
    'new-checkout-first-payment-modal',
  );

  const { isFirstPayment } = useGetLastPayment('plan-cost');

  const fromPayment = hasSearchParam(FROM_PAYMENT_QUERY);

  const [show, _open, close] = useModal(true);

  const closeModal = useCallback(() => {
    dismiss();
    close();
  }, [close, dismiss]);

  return {
    shouldShow: show && isNotDismissed && fromPayment && !!isFirstPayment,
    closeModal,
  };
}

export default useFirstPaymentModal;
