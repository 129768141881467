import { Tooltip } from '@nimbus-ds/components';
import { Button } from '@tiendanube/components';
import { Stack, useResponsive } from 'commons/components';
import { ActionPopover } from './components';
import { ActionsProps } from '../types';

interface ActionsDesktopProps extends ActionsProps {
  renderActionsAsButtons?: boolean;
}

function ActionsDesktop({
  actions,
  renderActionsAsButtons = false,
}: Readonly<ActionsDesktopProps>) {
  const { isMobile } = useResponsive(1200);
  const mainAction = actions[actions.length - 1];
  const moreActions = actions.slice(0, actions.length - 1);
  const renderMoreAsButton = moreActions.length === 1;
  const renderActionsAsSubmenu = moreActions.length > 1;
  const renderActionsAsBtns =
    renderActionsAsButtons && !isMobile && moreActions.length > 1;

  if (actions.length === 0) return null;

  return (
    <Stack spacing="tight">
      {renderMoreAsButton && <Button {...moreActions[0]} />}
      {renderActionsAsBtns ? (
        moreActions.map((action, index) => <Button key={index} {...action} />)
      ) : renderActionsAsSubmenu ? (
        <ActionPopover actions={moreActions} />
      ) : null}
      {!!mainAction.tooltip && (
        <Tooltip content={mainAction.tooltip} arrow position="left">
          <Button {...mainAction} />
        </Tooltip>
      )}
      {!mainAction.tooltip && <Button {...mainAction} />}
    </Stack>
  );
}

export default ActionsDesktop;
