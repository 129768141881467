const downloadBlob = (fileName: string, content: string): void => {
  // Hack for client side csv download using Blob since msSaveBlob doesn't support
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveBlob
  const blob = new Blob([content], { type: 'text/csv' });
  const linkTemp = document.createElement('a');
  linkTemp.href = window.URL.createObjectURL(blob);
  linkTemp.download = fileName;
  document.body.appendChild(linkTemp);
  linkTemp.click();
  URL.revokeObjectURL(linkTemp.href);
  linkTemp.remove();
};

export default downloadBlob;
