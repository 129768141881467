import { IconButton, Tooltip } from '@nimbus-ds/components';
import { EyeIcon, EyeOffIcon, PencilIcon, TrashIcon } from '@nimbus-ds/icons';
import CouponModalConfirmationAction from 'domains/Marketing/Coupons/components/CouponModalConfirmationAction';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CustomActionProps {
  action: 'activate' | 'deactivate' | 'delete' | 'edit';
  onAction: () => Promise<void>;
}

const TRANSLATION_PREFIX = {
  activate: 'modalActivate',
  deactivate: 'modalDeactivate',
  delete: 'modalDelete',
  edit: 'actionEdit',
};

const ACTION_ICON = {
  activate: <EyeIcon />,
  deactivate: <EyeOffIcon />,
  delete: <TrashIcon />,
  edit: <PencilIcon />,
};

export function CustomAction({
  action,
  onAction,
}: Readonly<CustomActionProps>) {
  const t = useTranslationMarketing('coupons');

  const tooltip = (onClick: () => void) => (
    <Tooltip content={t(`${TRANSLATION_PREFIX[action]}.ok`)}>
      <IconButton size="2rem" source={ACTION_ICON[action]} onClick={onClick} />
    </Tooltip>
  );

  if (action === 'edit') {
    return tooltip(onAction);
  }

  return (
    <CouponModalConfirmationAction action={action} onAction={onAction}>
      {(openModal) => tooltip(openModal)}
    </CouponModalConfirmationAction>
  );
}
