import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';

export const getMarketingAppsState = (state: RootStateType) =>
  state.marketing.marketingApps;

export const getMarketingAppsStatus = createSimpleSelector(
  [getMarketingAppsState],
  ({ status }) => convertStatusTypeToObject(status),
);

export const getMarketingAppsData = createSimpleSelector(
  [getMarketingAppsState],
  ({ data }) => data,
);
