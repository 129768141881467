import { Link, Stack } from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import SecondaryTooltip from 'commons/components/SecondaryTooltip';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import useSelectBulkOrders from 'domains/Orders/Orders/hooks/useSelectBulkOrders';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

function OrderSelectInBulk() {
  const t = useTranslationOrders();
  const { setDisabled } = useBulkActions();
  const { filters, ordersCount } = useOrdersList();
  const { isBulkSelected, handleSelect, handleDeselect } = useSelectBulkOrders(
    filters,
    setDisabled,
  );

  if (isBulkSelected)
    return ordersCount < 100 ? (
      <Link
        underline
        size="small"
        appearance="secondary"
        onClick={handleDeselect}
      >
        {t('bulkActions.deselectInBulk')}
      </Link>
    ) : (
      <Stack>
        <SecondaryTooltip
          icon={InfoCircleIcon}
          tooltipText={t('bulkActions.tooltip')}
        />
        <Link
          underline
          size="small"
          appearance="secondary"
          onClick={handleDeselect}
        >
          {t('bulkActions.deselectInBulk')}
        </Link>
      </Stack>
    );

  return (
    <Stack>
      <Link
        underline
        size="small"
        appearance="secondary"
        onClick={handleSelect}
      >
        {ordersCount <= 100
          ? t('bulkActions.selectInBulk', { count: ordersCount })
          : t('bulkActions.selectInBulkMax')}
      </Link>
      {ordersCount > 100 && (
        <SecondaryTooltip
          icon={InfoCircleIcon}
          tooltipText={t('bulkActions.tooltip')}
        />
      )}
    </Stack>
  );
}

export default OrderSelectInBulk;
