import { ChangeEvent } from 'react';
import { Card } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Prompt } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
  Stack,
} from 'commons/components';
import IonPageSratus from 'commons/components/IonPageStratus/IonPageStratus';
import { PhysicalLocationBody } from 'domains/Shipping/DeliveryMethods/pages/AddPhysicalLocationPage/useAddPhysicalLocationPage';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import PhysicalLocationFormSkeleton from './Skeleton';

interface PhysicalLocationFormProps {
  title: string;
  values: PhysicalLocationBody;
  errors: Partial<PhysicalLocationBody>;
  isDirty: boolean;
  loading?: boolean;
  onSave: () => void;
  onCancel: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function PhysicalLocationForm({
  title,
  onChange,
  values,
  errors,
  loading,
  onSave,
  onCancel,
  isDirty = false,
}: PhysicalLocationFormProps) {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();

  const backNavigation = {
    children: t('deliveryMethods.title'),
    onClick: goBack,
  };

  return (
    <IonPageSratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent
          title={title}
          subtitle={`${t('physicalLocations.addAndEdit.subTitle')}`}
        />
      }
      width="small"
    >
      <Card>
        <Card.Body>
          <Stack column align="stretch">
            <FormField.Input
              label={t('physicalLocations.addAndEdit.name')}
              name="name"
              value={values?.name}
              appearance={!!errors.name && !values?.name ? 'danger' : 'none'}
              onChange={onChange}
              showHelpText={!!errors.name && !values?.name}
              helpText={!!errors.name && !values?.name ? t(errors.name) : ''}
            />
            <FormField.Textarea
              id="extra"
              label={t('physicalLocations.addAndEdit.complement')}
              name="extra"
              value={values?.extra}
              appearance={!!errors.extra && !values?.extra ? 'danger' : 'none'}
              onChange={(e) => onChange(e)}
              showHelpText
              helpText={
                !!errors.extra && !values?.extra
                  ? t(errors.extra)
                  : t('physicalLocations.addAndEdit.example')
              }
              rows={3}
              maxLength={3000}
            />
            <CancelAndConfirmButtons
              onCancel={onCancel}
              onConfirm={onSave}
              isLoading={loading}
              isCancelDisabled={loading}
            />
          </Stack>
        </Card.Body>
      </Card>
      <Prompt message={t('common:exitEdit.info')} when={isDirty} />
    </IonPageSratus>
  );
}

PhysicalLocationForm.Skeleton = PhysicalLocationFormSkeleton;
export default PhysicalLocationForm;
