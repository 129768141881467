import {
  CloneProductToParamsInterface,
  ProductToCloneInterface,
} from 'domains/Catalog/Products/productsServices';

export function cloneProductDigest(
  product: ProductToCloneInterface,
  id: string,
): CloneProductToParamsInterface {
  return {
    id,
    name: product.name,
    withImages: product.withImages,
    skus: product.skus,
  };
}
