import { PaymentMethodCode } from '@tiendanube/common/src/domains/payments/providers/PaymentMethodCode';
import { PaymentProvidersCode } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';

export const usePaymentMethodLabel = (): ((
  providerId: string,
  paymentMethod: string,
) => string) => {
  const country = useGetCountry();

  switch (country) {
    case Country.AR:
      return getArTranslation;
    case Country.MX:
      return getMxTranslation;
    case Country.CL:
      return getClTranslation;
    case Country.CO:
      return getCoTranslation;
    default:
      return getDefaultTranslation;
  }
};

const getArTranslation = (providerId: string, paymentMethod: string) => {
  if (
    providerId === PaymentProvidersCode.paypal &&
    paymentMethod === PaymentMethodCode.creditCard
  ) {
    return `paymentProvider.method.creditAndDebitCard`;
  }
  return `paymentProvider.method.${paymentMethod}`;
};

const getMxTranslation = (providerId: string, paymentMethod: string) => {
  if (paymentMethod === PaymentMethodCode.wireTransfer) {
    return `paymentProvider.method.bankDebit`;
  }
  return `paymentProvider.method.${paymentMethod}`;
};

const getClTranslation = (providerId: string, paymentMethod: string) => {
  if (
    providerId === PaymentProvidersCode.paypal &&
    paymentMethod === PaymentMethodCode.creditCard
  ) {
    return `paymentProvider.method.creditAndDebitCard`;
  }
  if (
    providerId === PaymentProvidersCode.customPayments &&
    paymentMethod === PaymentMethodCode.wireTransfer
  ) {
    return `paymentProvider.method.bankDebit`;
  }
  return `paymentProvider.method.${paymentMethod}`;
};

const getCoTranslation = (providerId: string, paymentMethod: string) => {
  if (
    (providerId === PaymentProvidersCode.openpay ||
      PaymentProvidersCode.customPayments) &&
    paymentMethod === PaymentMethodCode.wireTransfer
  ) {
    return `paymentProvider.method.bankDebit`;
  }

  return `paymentProvider.method.${paymentMethod}`;
};

const getDefaultTranslation = (providerId: string, paymentMethod: string) =>
  `paymentProvider.method.${paymentMethod}`;
