import {
  Alert,
  Checkbox,
  Box,
  Title,
  Input,
  Text,
  Icon,
  Tooltip,
} from '@nimbus-ds/components';
import {
  InterfaceNameChecked,
  InterfaceNameValue,
  BaseCard,
} from '@tiendanube/components';
import { QuestionCircleIcon } from '@tiendanube/icons';
import { CATALOG_FULL_EVENTS } from 'App/featuresFlags';
import { InputNumber, Stack, useResponsive } from 'commons/components';
import {
  useGetCurrency,
  useGetTags,
  useHasPermission,
} from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  trackingProductDetailCostBlur,
  trackingProductDetailPriceBlur,
  trackingProductDetailPromotionalBlur,
} from 'domains/Catalog/Products/tracking';
import ApplyValuesToAllVariants from '../ApplyValuesToAllVariants';
import { OnChangeToAllVariants } from '../Variants/types';

export enum NamesEnum {
  PRICE = 'price',
  PROMOTIONAL_PRICE = 'promotionalPrice',
  PUBLISHED = 'pricePublished',
  COST = 'costPrice',
}

export type PricesNamesTypes =
  | NamesEnum.PRICE
  | NamesEnum.PROMOTIONAL_PRICE
  | NamesEnum.PUBLISHED
  | NamesEnum.COST;
export type ValuePricesTypes = string | boolean;
interface onChangeProps {
  name: PricesNamesTypes;
  value: ValuePricesTypes;
}

interface PricesProps {
  price: string;
  promotionalPrice: string;
  published: boolean;
  costPrice: string;
  onChange: (data: onChangeProps) => void;
  hasVariants?: boolean;
  onChangeToAllVariants?: OnChangeToAllVariants;
  isEdit?: boolean;
}

function Prices({
  price,
  promotionalPrice,
  published,
  costPrice,
  onChange,
  onChangeToAllVariants,
  isEdit,
}: PricesProps): JSX.Element {
  const t = useTranslationCatalog();

  const { symbol } = useGetCurrency();

  const { isMobile } = useResponsive();

  const canEditPrice = useHasPermission('edit_price');

  const tag = useGetTags();

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as onChangeProps);
  };

  const handleBlurPrice = (data: InterfaceNameValue) => {
    if (tag.includes(CATALOG_FULL_EVENTS)) {
      trackingProductDetailPriceBlur(data, isEdit);
    }
  };

  const handleBlurPromotional = (data: InterfaceNameValue) => {
    if (tag.includes(CATALOG_FULL_EVENTS)) {
      trackingProductDetailPromotionalBlur(data, isEdit);
    }
  };

  const handleBlurCost = (data: InterfaceNameValue) => {
    if (tag.includes(CATALOG_FULL_EVENTS)) {
      trackingProductDetailCostBlur(data, isEdit);
    }
  };

  const handlePublished = (value: InterfaceNameChecked) => {
    onChange({ name: NamesEnum.PUBLISHED, value: value.checked });
  };

  const hasPromotionalAndCost = promotionalPrice !== '' && costPrice !== '';
  const hasPriceAndCost = price !== '' && costPrice !== '';

  const costMarginForPromotional = hasPromotionalAndCost
    ? Math.round(
        ((Number(promotionalPrice) - Number(costPrice)) /
          Number(promotionalPrice)) *
          100,
      )
    : '--';

  const costMarginForPrice = hasPriceAndCost
    ? Math.round(((Number(price) - Number(costPrice)) / Number(price)) * 100)
    : '--';

  const margin = promotionalPrice
    ? costMarginForPromotional
    : costMarginForPrice;

  const marginValue = margin === '--' ? '--' : `${margin} %`;
  const positionTooltip = isMobile ? 'left' : 'bottom';

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title as="h4">{`${t('products.detail.prices')}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Stack column align="stretch">
            <Stack align="flex-start">
              <Stack.Item fill>
                <InputNumber
                  type="float"
                  label={t('products.detail.price')}
                  prependLabel={symbol}
                  value={price}
                  placeholder="0.00"
                  name={NamesEnum.PRICE}
                  disabled={!canEditPrice || !published}
                  onChange={handleChange}
                  onBlur={handleBlurPrice}
                />
                {onChangeToAllVariants && (
                  <ApplyValuesToAllVariants
                    value={price}
                    onChange={onChangeToAllVariants('price', price)}
                  />
                )}
              </Stack.Item>
              <Stack.Item fill>
                <InputNumber
                  type="float"
                  label={t('products.detail.promotionalPrice')}
                  prependLabel={symbol}
                  value={promotionalPrice}
                  placeholder="0.00"
                  name={NamesEnum.PROMOTIONAL_PRICE}
                  disabled={!canEditPrice || !published}
                  onChange={handleChange}
                  onBlur={handleBlurPromotional}
                />
                {onChangeToAllVariants && (
                  <ApplyValuesToAllVariants
                    value={promotionalPrice}
                    onChange={onChangeToAllVariants(
                      'promotionalPrice',
                      promotionalPrice,
                    )}
                  />
                )}
              </Stack.Item>
            </Stack>
            <Checkbox
              label={t('products.detail.showPrice')}
              name={NamesEnum.PUBLISHED}
              onChange={(e) => handlePublished(e.target)}
              checked={published}
            />
            {!published && (
              <Box padding="0-5">
                <Alert appearance="primary">
                  {t('products.detail.alertWithOutPrice')}
                </Alert>
              </Box>
            )}
          </Stack>
          <Stack column gap="1" align="stretch">
            <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="4">
              <Stack.Item fill>
                <InputNumber
                  type="float"
                  label={t('products.detail.cost')}
                  prependLabel={symbol}
                  value={costPrice}
                  placeholder="0.00"
                  name={NamesEnum.COST}
                  disabled={!canEditPrice || !published}
                  onChange={handleChange}
                  onBlur={handleBlurCost}
                />
                {onChangeToAllVariants && (
                  <ApplyValuesToAllVariants
                    value={costPrice}
                    onChange={onChangeToAllVariants('costPrice', costPrice)}
                  />
                )}
              </Stack.Item>
              <Stack column align="flex-start" gap="1">
                <Stack gap="0-5">
                  <Text fontSize="caption">
                    {t('products.detail.labelMargin')}
                  </Text>
                  {hasPromotionalAndCost && (
                    <Tooltip
                      content={t('products.detail.infoMargin')}
                      position={positionTooltip}
                    >
                      <Icon source={<QuestionCircleIcon size="small" />} />
                    </Tooltip>
                  )}
                </Stack>
                <Input value={marginValue} name="margen" disabled />
              </Stack>
            </Box>
            <Box display="grid" gridTemplateColumns="1fr">
              <Text fontSize="caption">{t('products.detail.costInfo')}</Text>
            </Box>
          </Stack>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Prices;
