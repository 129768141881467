import { DEFAULT_LANGUAGE } from 'App/i18n';
import { Input } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

export function TrackingPageMessageInput({
  value: initialValues,
  language = DEFAULT_LANGUAGE,
  onChange,
  onBlur,
}) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.trackingPageMessageCard',
  );

  const handleChange = ({ name, value }) => {
    onChange({
      name,
      value: { ...initialValues, [language]: value },
    });
  };

  const handleBlur = ({ name, value }) => {
    onBlur({
      name,
      value: { ...initialValues, [language]: value },
    });
  };

  return (
    <Input
      type="textarea"
      name="postSaleMessage"
      value={initialValues[language]}
      onChange={handleChange}
      onBlur={handleBlur}
      label={t('postSaleMessageLabel')}
      placeholder={t('postSaleMessagePlaceholder')}
    />
  );
}
