import { Tag, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface StatusTagItemProps {
  status: boolean;
}

export function StatusTagItem({ status }: Readonly<StatusTagItemProps>) {
  const t = useTranslationMarketing('freeShipping');

  return (
    <Tag appearance={status ? 'success' : 'neutral'}>
      <Stack spacing="tight">
        <Text>{status ? t('activated') : t('disabled')}</Text>
      </Stack>
    </Tag>
  );
}
