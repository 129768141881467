import { useSelector } from 'react-redux';
import {
  vatCondition as vatConditionSelector,
  invoiceType as invoiceTypeSelector,
} from 'domains/Billing/Invoices/invoicesSlice';
import { formatVatCondition } from 'domains/Billing/Invoices/utils';

function useTaxCondition() {
  const vatCondition = useSelector(vatConditionSelector);
  const invoiceType = useSelector(invoiceTypeSelector);

  return {
    vatCondition: vatCondition && formatVatCondition(vatCondition.toString()),
    invoiceType,
  };
}

export default useTaxCondition;
