import { useState } from 'react';
import { Accordion, Text } from '@nimbus-ds/components';
import { Stack, RadioButtonGroup } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { ARecordInfo } from './ARecordInfo';
import { ARecordStep } from './ARecordStep';
import { CNameRecordInfo } from './CNameRecordInfo';
import { CNAMERecordStep } from './CNAMERecordStep';
import { DNSServersInfo } from './DNSServersInfo';
import { DNSVerificationStep } from './DNSVerificationStep';

interface AttachDomainStepsProps {
  domainId: string;
  isSubdomain: boolean;
  supportsOurDns: boolean;
  domainName: string;
  ourDomain: string;
}

export function AttachDomainSteps({
  domainId,
  isSubdomain,
  supportsOurDns,
  domainName,
  ourDomain,
}: Readonly<AttachDomainStepsProps>) {
  const t = useTranslationConfigurations('domains.attachModal');
  const [hosting, setHosting] = useState<'other' | 'tiendanube'>('tiendanube');

  if (isSubdomain)
    return (
      <>
        <CNAMERecordStep
          step={2}
          ourDomain={ourDomain}
          isSubdomain={isSubdomain}
        />
        <DNSVerificationStep
          step={3}
          isSubdomain={isSubdomain}
          domainId={domainId}
          domainName={domainName}
          ourDomain={ourDomain}
        />
      </>
    );

  if (!supportsOurDns)
    return (
      <>
        <ARecordStep step={2} domainId={domainId} />
        <CNAMERecordStep
          step={3}
          ourDomain={ourDomain}
          isSubdomain={isSubdomain}
        />
        <DNSVerificationStep
          step={4}
          isSubdomain={isSubdomain}
          domainId={domainId}
          domainName={domainName}
          ourDomain={ourDomain}
        />
      </>
    );

  return (
    <>
      <Accordion.Item index="1">
        <Accordion.Header title={t('step2.title')} />
        <Accordion.Body>
          <Stack column align="stretch">
            <Text>{t('step2.subtitle')}</Text>
            <RadioButtonGroup
              name="hosting"
              options={[
                { label: t('step2.hosting.tiendanube'), value: 'tiendanube' },
                { label: t('step2.hosting.other'), value: 'other' },
              ]}
              value={hosting}
              onChange={(e) => setHosting(e.value)}
            />
            {hosting === 'other' && (
              <>
                <Text>{t('step2.additionalServices')}</Text>
                <Stack column align="stretch" gap="1">
                  <Text fontWeight="medium">{t('step2.a.title')}</Text>
                  <ARecordInfo domainId={domainId} />
                </Stack>
                <Stack column align="stretch" gap="1">
                  <Text fontWeight="medium">{t('step2.cname.title')}</Text>
                  <CNameRecordInfo
                    ourDomain={ourDomain}
                    isSubdomain={isSubdomain}
                  />
                </Stack>
              </>
            )}
            {hosting === 'tiendanube' && (
              <>
                <Text>{t('step2.delegate')}</Text>
                <DNSServersInfo domainId={domainId} />
              </>
            )}
          </Stack>
        </Accordion.Body>
      </Accordion.Item>
      <DNSVerificationStep
        step={3}
        isSubdomain={isSubdomain}
        domainId={domainId}
        domainName={domainName}
        ourDomain={ourDomain}
      />
    </>
  );
}
