import { Button, Card, Tag, Text } from '@nimbus-ds/components';
import { CheckIcon, LinkOffIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface LinkedGoogleTagManagerProps {
  googleAnalyticsGtmId: string;
  onClick: () => void;
}

export function LinkedGoogleTagManager({
  googleAnalyticsGtmId,
  onClick,
}: Readonly<LinkedGoogleTagManagerProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.google.googleTagManager.linked',
  );
  return (
    <Card padding="small">
      <Stack column align="stretch">
        <Tag appearance="success">
          <CheckIcon />
          <Text fontSize="caption">{t('tag')}</Text>
        </Tag>
        <Text fontSize="highlight">
          <Trans
            t={t}
            i18nKey="code"
            values={{ code: googleAnalyticsGtmId }}
            components={{
              bold: <strong />,
            }}
          />
        </Text>
        <Button onClick={onClick} appearance="neutral">
          <LinkOffIcon />
          {t('unlinked')}
        </Button>
      </Stack>
    </Card>
  );
}
