import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import { PAYMENTS_ROUTES } from 'domains/Payments/paymentsRoutes';
import {
  getMercadoPagoAuthorizationAction,
  getMercadoPagoAuthorizationStatus,
} from '../../paymentProvidersSlice';

interface UseGetMercadoPagoAuthorizationUrlResult {
  getMercadoPagoAuthorizationUrl: () => Promise<string>;
  isLoading: boolean;
}

function useGetMercadoPagoAuthorizationUrl(
  isUpdate = false,
): UseGetMercadoPagoAuthorizationUrlResult {
  const dispatch = useAppDispatch();
  const { url } = useHeaderMenuData();

  const { isLoading } = useSelector(getMercadoPagoAuthorizationStatus);

  const getMercadoPagoAuthorizationUrl = async (): Promise<string> => {
    const data = await dispatch(
      getMercadoPagoAuthorizationAction(
        !isUpdate
          ? `${url}/admin/v2${PAYMENTS_ROUTES.enableMercadoPago}`
          : `${url}/admin/v2${PAYMENTS_ROUTES.mercadoPagoSettings}`,
      ),
    );
    const { authorizationUrl } = unwrapResult(data);

    return authorizationUrl;
  };

  return {
    getMercadoPagoAuthorizationUrl,
    isLoading,
  };
}

export default useGetMercadoPagoAuthorizationUrl;
