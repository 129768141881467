import { ChangeEvent, useCallback, useState } from 'react';
import { NavigationMenuItemResponseDto } from '@tiendanube/common';

function useAddMenuHandlers() {
  const [name, setName] = useState('');
  const [items, setItems] = useState<NavigationMenuItemResponseDto[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const handleAddMenuItem = (item: NavigationMenuItemResponseDto) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  const handleDeleteMenuItem = (itemId: string) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const handleDropMenuItem = (items: NavigationMenuItemResponseDto[]) => {
    setItems(items);
  };

  const handleEditMenuItem = (updatedItem: NavigationMenuItemResponseDto) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === updatedItem.id) return updatedItem;
        return item;
      }),
    );
  };

  const cleanValues = useCallback(() => {
    setName('');
    setItems([]);
  }, []);

  return {
    name,
    items,
    handleChange,
    handleAddMenuItem,
    handleDeleteMenuItem,
    handleDropMenuItem,
    handleEditMenuItem,
    cleanValues,
  };
}

export default useAddMenuHandlers;
