import { Box, Card, Link, Text } from '@nimbus-ds/components';

function Skeleton() {
  return (
    <Card padding="base">
      <Box display="flex" flexDirection="column" gap="2">
        <Text.Skeleton />
        <Box
          display="flex"
          flexDirection="column"
          gap="1"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" gap="0-5" justifyContent="space-between">
            <Text.Skeleton />
          </Box>
          <Link.Skeleton />
        </Box>
      </Box>
    </Card>
  );
}

export default Skeleton;
