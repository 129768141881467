import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CouponUsedAlertProps {
  couponIsUsed: boolean;
}

export function CouponUsedAlert({
  couponIsUsed,
}: Readonly<CouponUsedAlertProps>) {
  const t = useTranslationMarketing('coupons.couponForm.usedAlert');

  if (!couponIsUsed) {
    return null;
  }

  return (
    <Alert title={t('title')} appearance="primary">
      <Text>{t('description')}</Text>
    </Alert>
  );
}
