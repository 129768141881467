interface FreeTrialRangeInterface {
  min: number;
  max: number;
}

export const FREE_TRIAL_RANGE: FreeTrialRangeInterface = {
  min: 1,
  max: 7,
};

export function showFreeTrialAlert(expiresIn = 0) {
  return expiresIn <= FREE_TRIAL_RANGE.max && expiresIn >= FREE_TRIAL_RANGE.max;
}
