import { useEffect } from 'react';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useGetPlanExpiresIn } from 'domains/Auth/hooks';
import { AffiliateDiscountPercentageAlert } from './AffiliateDiscountPercentageAlert';
import { TrialExpirationAlert } from './TrialExpirationAlert';
import useTrialMessages from './useTrialMessages/useTrialMessages';

function TrialMessages() {
  const expiresIn = useGetPlanExpiresIn();
  const { getTrialMessages, trialMessage, isSuccess } = useTrialMessages();
  const { isMobileNonFreemium } = useGoToPlans();

  useEffect(() => {
    getTrialMessages();
  }, [getTrialMessages]);

  if (!isSuccess) return null;

  const mobileSuffix = isMobileNonFreemium ? 'Mobile' : '';

  return (
    <>
      <AffiliateDiscountPercentageAlert
        mobileSuffix={mobileSuffix}
        affiliateDiscountPercentage={trialMessage?.affiliateDiscountPercentage}
        show={trialMessage?.showMessage}
      />
      <TrialExpirationAlert expiresIn={expiresIn} mobileSuffix={mobileSuffix} />
    </>
  );
}

export default TrialMessages;
