import { RemarksCardSkeleton } from 'commons/components';
import Layout from 'commons/components/Layout';
import {
  CustomerDataCardSkeleton,
  CustomerActivitySkeleton,
} from '../../components';

function CustomerDetailsPageSkeleton(): JSX.Element {
  return (
    <Layout
      mainContent
      orderMobile="reverse"
      left={<CustomerActivitySkeleton />}
      right={
        <>
          <CustomerDataCardSkeleton />
          <RemarksCardSkeleton />
        </>
      }
    />
  );
}

export default CustomerDetailsPageSkeleton;
