import { useState } from 'react';
import { Alert } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { OrderCancelRequestDto } from '@tiendanube/common';
import { Domain as DomainEnum, Reason } from '@tiendanube/common/src/enums';
import {
  Text,
  Select,
  Checkbox,
  InterfaceNameValue,
  InterfaceNameChecked,
  Stack,
  Modal,
} from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { CancelAndSaveButtons } from 'commons/components';
import { useModal } from 'commons/hooks';
import { InterfaceSetToggle } from 'commons/useToggle';
import { useHasPermission } from 'domains/Auth/hooks';
import useRefundOrder from 'domains/Orders/Orders/hooks/useRefundOrder';

const cancelOrder = {
  reasons: [
    Reason.CUSTOMER,
    Reason.INVENTORY,
    Reason.FRAUD,
    Reason.TEST,
    Reason.OTHER,
  ],
  defaultValues: {
    reason: Reason.CUSTOMER,
    email: true,
    restock: true,
    refund: false,
  },
};

const NUVEM_PAGO_GATEWAY = 'Nuvem Pago';
const PAGO_NUBE_GATEWAY = 'Pago Nube';

interface SingleOrderCancelModalProps {
  orderId: number;
  canBeRefunded: boolean;
  amount: number;
  gateway: string;
  isPaid: boolean;
  show: boolean;
  hideModal: InterfaceSetToggle;
  appliedAction: (cancelOrder: OrderCancelRequestDto) => void;
  isLoading: boolean;
}

function SingleOrderCancelModal({
  orderId,
  canBeRefunded,
  amount,
  gateway,
  show,
  hideModal,
  appliedAction,
  isPaid,
  isLoading,
}: SingleOrderCancelModalProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const hasRefundOrderPermission = useHasPermission('refund_order');
  const { isLoading: isLoadingHasFunds, fetchHasAvailableFunds } =
    useRefundOrder(amount, gateway);
  const [showFundsWarning, openFundsWarning, closeFundsWarning] = useModal();
  const [cancelOrderState, setCancelOrderState] =
    useState<OrderCancelRequestDto>(cancelOrder.defaultValues);
  const [showAlertRefundNuvemPago, setShowAlertRefundNuvemPago] =
    useState<boolean>(false);

  const handleChange = (name: string, value: string | boolean) => {
    const nameSanitizate = name.replace('single_', '');
    setCancelOrderState((state) => ({
      ...state,
      [nameSanitizate]: value,
    }));
  };

  const handleChangeSelect = ({ name, value }: InterfaceNameValue) => {
    handleChange(name, value);
  };

  const handleChangeCheckbox = ({ name, checked }: InterfaceNameChecked) => {
    handleChange(name, checked);
  };

  const handleChangeCheckboxRefund = ({
    name,
    checked,
  }: InterfaceNameChecked) => {
    setShowAlertRefundNuvemPago(checked);
    handleChange(name, checked);
  };

  const onClickPrimary = async () => {
    const isNuvemPago = [PAGO_NUBE_GATEWAY, NUVEM_PAGO_GATEWAY].includes(
      gateway,
    );
    if (cancelOrderState.refund && isNuvemPago) {
      const hasFunds = await fetchHasAvailableFunds();
      return hasFunds ? onConfirm() : openFundsWarning();
    }

    return onConfirm();
  };

  const onConfirm = async () => {
    closeFundsWarning();
    await appliedAction(cancelOrderState);
    hideModal();
  };

  const options = cancelOrder.reasons.map((option) => ({
    label: t(`cancelModal.reason.options.${option}`),
    value: option,
  }));

  return (
    <>
      <ModalAside
        isOpen={show}
        onDidDismiss={hideModal}
        headerContent={t('cancelModal.titleDetail', { orderId })}
      >
        <Stack column align="stretch">
          {isPaid && !canBeRefunded && (
            <Alert
              appearance="warning"
              title={t('cancelModal.alertPaidTitle')}
              show
            >
              {t('cancelModal.alertPaidDescription')}
            </Alert>
          )}
          <Select
            label={t('cancelModal.reason.label')}
            name="single_reason"
            options={options}
            value={cancelOrderState.reason}
            onChange={handleChangeSelect}
          />
          <Checkbox
            label={t('cancelModal.email')}
            name="single_email"
            onChange={handleChangeCheckbox}
            checked={cancelOrderState.email}
          />
          <Checkbox
            label={t('cancelModal.restock')}
            name="single_restock"
            onChange={handleChangeCheckbox}
            checked={cancelOrderState.restock}
          />
          {canBeRefunded && hasRefundOrderPermission && (
            <Checkbox
              label={t('cancelModal.refund')}
              name="single_refund"
              onChange={handleChangeCheckboxRefund}
              checked={cancelOrderState.refund}
            />
          )}
          {showAlertRefundNuvemPago && (
            <Alert appearance="neutral">{t('cancelModal.alertRefund')}</Alert>
          )}

          <CancelAndSaveButtons
            saveText={t('cancelModal.actionPrimary')}
            cancelText={t('cancelModal.actionSecondary')}
            onCancel={hideModal}
            onSave={onClickPrimary}
            isDisabled={isLoading}
            isLoading={isLoading || isLoadingHasFunds}
          />
        </Stack>
      </ModalAside>

      <Modal
        title={t('cancelModal.fundsWarningModal.title', { orderId })}
        show={showFundsWarning}
        onDismiss={closeFundsWarning}
        primaryAction={{
          children: `${t('cancelModal.fundsWarningModal.actionPrimary')}`,
          onClick: onConfirm,
          appearance: 'primary',
        }}
        secondaryAction={{
          children: `${t('cancelModal.fundsWarningModal.actionSecondary')}`,
          onClick: closeFundsWarning,
        }}
      >
        <Text bold>{t('cancelModal.fundsWarningModal.subtitle')}</Text>
        <Text>{t('cancelModal.fundsWarningModal.info')}</Text>
      </Modal>
    </>
  );
}

export default SingleOrderCancelModal;
