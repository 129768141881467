import { Tag } from '@nimbus-ds/components';
import { PayOrderStatus } from '@tiendanube/common';
import { useGetPlanExpiresIn } from 'domains/Auth/hooks';
import { PaymentStatusTag } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface TrialStatusTagProps {
  paymentStatus: PayOrderStatus;
}

export default function TrialStatusTag({
  paymentStatus,
}: Readonly<TrialStatusTagProps>) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const expiresIn = useGetPlanExpiresIn();

  if (!expiresIn || expiresIn <= 0) {
    return <PaymentStatusTag paymentStatus={paymentStatus} />;
  }

  return <Tag appearance="neutral">{t('trialStatusTag', { expiresIn })}</Tag>;
}
