import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { CustomersAddressResponseDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Link, Stack, Text } from '@tiendanube/components';
import { MailIcon, WhatsappIcon, TrashIcon } from '@tiendanube/icons';
import { useEmailMessageLink, useWhatsappMessageLink } from 'commons/hooks';
import { Format, dateFormat } from 'commons/utils/date';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import { FormattedCustomerMetafield } from '../../hooks/useCustomerMetafields';

interface CustomerDataCardProps {
  name: string;
  email: string;
  phone?: string;
  identification?: string;
  businesName?: string;
  tradeName?: string;
  stateRegistration?: string;
  businessActivity?: string;
  fiscalRegime?: string;
  address: CustomersAddressResponseDto;
  allowRemove?: boolean;
  onRemoveData: () => void;
  metafields: FormattedCustomerMetafield[];
}

const uniqueId = uuidv4();

function CustomerDataCard({
  name,
  email,
  phone,
  identification,
  businesName,
  tradeName,
  stateRegistration,
  businessActivity,
  fiscalRegime,
  address,
  allowRemove,
  onRemoveData,
  metafields,
}: CustomerDataCardProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const { name: storeName } = useHeaderMenuData();
  const getSendEmailUrl = useEmailMessageLink();
  const getSendWhatsappUrl = useWhatsappMessageLink();

  const mailHref = getSendEmailUrl({
    email: email,
    subject: '',
    body: t('contactTemplate.mailBody', { storeName }),
  });
  const waHref = getSendWhatsappUrl({
    phone: phone || '',
    message: t('contactTemplate.waText', {
      clientName: name,
    }),
  });

  const headerIcon = {
    icon: phone ? WhatsappIcon : MailIcon,
    onClick: phone
      ? () => window.open(waHref, '_blank')
      : () => window.open(mailHref, '_blank'),
  };

  function AddressText() {
    let customerAddress = '';
    customerAddress += address.address ? `${address.address} ` : '';
    customerAddress += address.zipcode ? `(${address.zipcode})` : '';

    return customerAddress ? <Text block>{customerAddress}</Text> : null;
  }

  function LocationText() {
    let location = '';
    location += address.city ? `${address.city}, ` : '';
    location += address.province ? `${address.province}, ` : '';
    location += address.country ? `${address.country}` : '';

    return location ? <Text block>{location}</Text> : null;
  }

  return (
    <Card
      title={t('customerDetails.customerData.title')}
      headerIcon={headerIcon.icon}
      onClickHeaderIcon={headerIcon.onClick}
    >
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{name}</Text>
          <Text block>{email}</Text>
          <Text block>{phone}</Text>
          {!!identification && (
            <Text block>
              {t('customerDetails.customerData.identification')}:{' '}
              {identification}
            </Text>
          )}
          {!!businesName && (
            <Text block>
              {t('customerDetails.customerData.businessName')}: {businesName}
            </Text>
          )}
          {!!tradeName && (
            <Text block>
              {t('customerDetails.customerData.tradeName')}: {tradeName}
            </Text>
          )}
          {!!stateRegistration && (
            <Text block>
              {t('customerDetails.customerData.stateRegistration')}:{' '}
              {stateRegistration}
            </Text>
          )}
          {!!fiscalRegime && (
            <Text block>
              {t('customerDetails.customerData.fiscalRegime')}:{' '}
              {t(
                `customerDetails.customerData.fiscalRegimeCode${fiscalRegime}`,
              )}
            </Text>
          )}
          {!!businessActivity && (
            <Text block>
              {t('customerDetails.customerData.businessActivity')}:{' '}
              {businessActivity}
            </Text>
          )}
        </Stack.Item>
        <Stack.Item>
          <AddressText />
          <LocationText />
        </Stack.Item>
        <Stack.Item>
          {metafields?.map((metafield) => {
            const isDate = metafield.type === 'date';

            return (
              <div key={`${uniqueId}-${metafield.name}`}>
                <Text>
                  {metafield.name}
                  {!!metafield.app && `(${metafield.app})`}:{' '}
                  {isDate
                    ? dateFormat(metafield.value, Format.DD_MM_YYYY)
                    : metafield.value}
                </Text>
              </div>
            );
          })}
        </Stack.Item>
        {allowRemove && (
          <Stack.Item>
            <Link appearance="primary" icon={TrashIcon} onClick={onRemoveData}>
              {t('customerDetails.customerData.removeData')}
            </Link>
          </Stack.Item>
        )}
      </Stack>
    </Card>
  );
}

export default CustomerDataCard;
