import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getProductSections } from 'domains/Online/Themes/themesSlice';
import {
  getProductSectionsDataSelector,
  getProductSectionsStatusSelector,
} from 'domains/Online/Themes/themesSlice/themesSelectors';

function useProductSections() {
  const dispatch = useAppDispatch();
  const productSections = useSelector(getProductSectionsDataSelector);
  const status = useSelector(getProductSectionsStatusSelector);

  const fetchProductSections = useCallback(() => {
    dispatch(getProductSections());
  }, [dispatch]);

  return {
    productSections,
    status,
    fetchProductSections,
  };
}

export default useProductSections;
