import { useState } from 'react';
import { PlansListResponseDto } from '@tiendanube/common';
import { Tabs } from '@tiendanube/components';
import { useTranslationBilling } from 'domains/Billing';
import PlanCard from '../PlanCard';
import { SelectedPlan } from '../PlanCard/types';

interface ComparisonPlansCardsMobileProps {
  plansList: PlansListResponseDto[];
  currentPlanId: number;
  currentPlanLevel: number;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonPlansCardsMobile({
  plansList,
  currentPlanId,
}: ComparisonPlansCardsMobileProps): JSX.Element {
  const t = useTranslationBilling();
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (activeTab: number) => {
    setActiveTab(activeTab);
  };
  return (
    <Tabs activeTab={activeTab} onChange={handleChangeTab}>
      {plansList.map((plan) => (
        <Tabs.Item label={plan.name} key={plan.id}>
          <PlanCard
            currentPlan={plan.id === currentPlanId}
            title={plan.name}
            description={plan.description}
            price={plan.price}
            frequencyPrice={t('billingPlansPage.month')}
            mainFeatures={plan.mainFeatures}
            isNextPlan={plan.isNextPlan}
            isFree={plan.isFree}
          />
        </Tabs.Item>
      ))}
    </Tabs>
  );
}

export default ComparisonPlansCardsMobile;
