import { ConditionsInterface, ValuesInterface } from './types';

interface RenderMetafieldsWhenProps {
  values: ValuesInterface;
  conditions: ConditionsInterface[];
  children: React.ReactNode;
}

export default function RenderMetafieldsWhen({
  values,
  conditions,
  children,
}: RenderMetafieldsWhenProps) {
  const valuesKeys = Object.keys(values);
  const shouldRender = conditions.some((current) =>
    valuesKeys.every((key) => values[key] === current[key]),
  );

  if (!shouldRender) return null;

  return <div>{children}</div>;
}
