import { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InterfaceNameValue,
  Modal,
} from '@tiendanube/components';
import { useModal } from 'commons/hooks';
import { EmbeddedApp } from 'domains/PartnersApps/components';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import {
  addApp,
  getApp,
  getKeyQueryDevModeFromQueryParams,
  removeApp,
} from './utils';

import './ExperimentalApp.scss';

function ExperimentalApp({ id }: { id: string }): JSX.Element {
  const { t } = useTranslationPartnerApps();
  const app = getApp(id);

  const [urlDevMode, setUrlDevMode] = useState(
    getKeyQueryDevModeFromQueryParams,
  );
  const [show, openModal, closeModal] = useModal();
  const existAppDev = Boolean(app);

  const handleOnChange = ({ value }: InterfaceNameValue) => {
    setUrlDevMode(value);
  };

  const handleExitMode = () => {
    removeApp(id);
    window.location.reload();
  };

  const handleOnClick = () => {
    addApp({ id, url: urlDevMode });
    window.location.reload();
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    existAppDev ? closeModal() : openModal();
  }, [existAppDev, closeModal, openModal]);

  return (
    <>
      {app && <EmbeddedApp id={app.id} url={app.url} />}
      {show && (
        <Modal
          show
          title={t('experimentalApp.testApplication.title')}
          secondaryAction={{ children: 'Cancel', onClick: handleClose }}
          primaryAction={{
            children: `${t('experimentalApp.testApplication.btnAccept')}`,
            appearance: 'primary',
            onClick: handleOnClick,
          }}
        >
          <Input
            maxLength={500}
            label="Url"
            name="url"
            placeholder="http://localhost"
            onChange={handleOnChange}
            helpText={t('experimentalApp.testApplication.textHelp')}
            value={urlDevMode}
          />
        </Modal>
      )}
      {!show && (
        <div className="stratus--experimental-app__development-mode">
          <Button appearance="danger" onClick={handleExitMode}>
            {`${t('experimentalApp.testApplication.exitDevMode')}`}
          </Button>
        </div>
      )}
    </>
  );
}

export default ExperimentalApp;
