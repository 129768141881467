import { ReactNode } from 'react';
import { FEATURE_NEW_ADMIN_STATS } from 'App/features';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import useSupportsStatsPlan from 'domains/Statistics/hooks/useSupportsStatsPlan';
import ChartPlaceholder, {
  ChartPlaceholderProps,
} from '../ChartPlaceholder/ChartPlaceholder';

interface ChartOrPlaceholderProps {
  type: ChartPlaceholderProps['type'];
  height?: number;
  statsPlanRequired: number;
  upsellSource: string;
  children: ReactNode;
}

function ChartOrPlaceholder({
  type,
  height,
  statsPlanRequired,
  upsellSource,
  children,
}: Readonly<ChartOrPlaceholderProps>) {
  const t = useTranslationStatistics();
  const shouldDisplayStats = useSupportsStatsPlan(statsPlanRequired);

  const handleClick = useUpsellFlow({
    title: t('statistics.upsellTitle'),
    featureKey: FEATURE_NEW_ADMIN_STATS,
    minValue: statsPlanRequired,
    trackingSource: upsellSource,
  });

  return (
    <>
      {!shouldDisplayStats && (
        <ChartPlaceholder type={type} height={height} onClick={handleClick} />
      )}
      {shouldDisplayStats && children}
    </>
  );
}

export default ChartOrPlaceholder;
