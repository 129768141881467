import { Modal, Text } from '@nimbus-ds/components';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import useMultiCDActivation from 'domains/Shipping/Locations/hooks/useMultiCDActivation';
import { trackingEnableMultiCdByCdPage } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface MultiCdEnableModalProps {
  onClose: () => void;
}

function MultiCdEnableModal({
  onClose,
}: Readonly<MultiCdEnableModalProps>): JSX.Element {
  const t = useTranslationShipping('locations.multicd');
  const { goTo } = useNavegate();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const { activateMultiCD } = useMultiCDActivation();
  const handleClose = () => {
    trackingEnableMultiCdByCdPage('cancel');
    onClose();
  };

  const [handleActivate, isActivating] = useAsyncFunc(
    async () => {
      addToastProgress({
        label: t('toast.loading'),
      });
      await activateMultiCD();
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('toast.success'),
        appearance: 'success',
      });
      trackingEnableMultiCdByCdPage('confirm');
      goTo('/settings/locations/new/secondary');
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('toast.error'),
        appearance: 'danger',
      });
    },
  );

  return (
    <Modal open onDismiss={handleClose}>
      <Modal.Header title={t('title')} />
      <Modal.Body padding="none">
        <Text>{t('description')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndConfirmButtons
          onCancel={handleClose}
          onConfirm={handleActivate}
          confirmText={t('action')}
          isConfirmDisabled={isActivating}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default MultiCdEnableModal;
