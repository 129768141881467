import {
  GenerateContentsPageRequestDto,
  GenerateContentsPageResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  GetPagesServiceType,
  GetTemplatesServiceType,
  GetPageByIdServiceType,
  CreatePageServiceType,
  UpdatePageByIdServiceType,
  DeletePageByIdServiceType,
  GetTemplateByIdServiceType,
  GetAllPagesServiceType,
} from './type';
import {
  ALL_PAGES_URL,
  GENERATE_CONTENTS_PAGE_URL,
  PAGES_BASE_URL,
  PAGE_BY_ID_URL,
  TEMPLATES_URL,
  TEMPLATE_BY_ID_URL,
} from './url';

export const getPagesService: GetPagesServiceType = async (filters) => {
  const { data } = await axios.get(PAGES_BASE_URL, { params: filters });
  return data;
};

export const getAllPagesService: GetAllPagesServiceType = async () => {
  const { data } = await axios.get(ALL_PAGES_URL);
  return data;
};

export const getTemplatesService: GetTemplatesServiceType = async () => {
  const { data } = await axios.get(TEMPLATES_URL);
  return data;
};

export const getTemplateByIdService: GetTemplateByIdServiceType = async (
  id,
) => {
  const { data } = await axios.get(
    getUrlWithParams(TEMPLATE_BY_ID_URL, { id }),
  );
  return data;
};

export const getPageByIdService: GetPageByIdServiceType = async (id) => {
  const { data } = await axios.get(getUrlWithParams(PAGE_BY_ID_URL, { id }));
  return data;
};

export const createPageService: CreatePageServiceType = async (payload) => {
  const { data } = await axios.post(PAGES_BASE_URL, payload);
  return data;
};

export const updatePageByIdService: UpdatePageByIdServiceType = async ({
  id,
  payload,
}) => {
  const { data } = await axios.put(
    getUrlWithParams(PAGE_BY_ID_URL, { id }),
    payload,
  );
  return data;
};

export const deletePageByIdService: DeletePageByIdServiceType = async (id) => {
  const { data } = await axios.delete(getUrlWithParams(PAGE_BY_ID_URL, { id }));
  return data;
};

export const generateContentsPage: (
  payload: GenerateContentsPageRequestDto,
) => Promise<GenerateContentsPageResponseDto> = async (payload) => {
  const { data } = await axios.post(GENERATE_CONTENTS_PAGE_URL, payload);
  return data;
};
