import { useSelector } from 'react-redux';
import { getMainUserId } from '../../authSlice/authSelectors';

function useGetMainUserId(): string {
  const mainUserId = useSelector(getMainUserId);

  return mainUserId || '';
}

export default useGetMainUserId;
