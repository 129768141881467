import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createHiddenTempIframe } from 'commons/utils/dom';
import {
  getVisitedAfterPaying,
  getQualityLeadShouldNotify,
} from 'domains/Auth/authSlice/authSelectors';
import trackingAnalyticsService from '../../trackingAnalyticsService';

function useTrackingAnalytics() {
  const visitedAfterPaying = useSelector(getVisitedAfterPaying);
  const qualityLeadShouldNotify = useSelector(getQualityLeadShouldNotify);

  const getUrlWithRedirect = (url) => {
    const currentUrl = window.location.href;
    return `${url}?redirect_to=${encodeURIComponent(currentUrl)}`;
  };

  const updateQualityLeadNotify = useCallback(async () => {
    const { url } = await trackingAnalyticsService.qualityLeadNotify();
    createHiddenTempIframe(getUrlWithRedirect(url));
  }, []);

  const updateVisitedAfterPaying = useCallback(async () => {
    const { url } = await trackingAnalyticsService.visitedAfterPaying();
    createHiddenTempIframe(getUrlWithRedirect(url));
  }, []);

  const executeTrackingRedirect = useCallback(() => {
    if (visitedAfterPaying) updateVisitedAfterPaying();
    if (qualityLeadShouldNotify) updateQualityLeadNotify();
  }, [
    qualityLeadShouldNotify,
    updateQualityLeadNotify,
    updateVisitedAfterPaying,
    visitedAfterPaying,
  ]);

  return {
    executeTrackingRedirect,
  };
}

export default useTrackingAnalytics;
