import { InfoCircleIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { checkAndRegisterDevice } from 'App/capacitor/device';
import { ModalConfirmationBase } from 'App/components';
import { UPDATE_APP_NOTIFICATIONS_ALERT_TAG } from 'App/featuresFlags';
import { useModal, useToast } from 'commons/hooks';
import { useGetTags, useIsMobileDevice } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

export function UpdateNotificationsAlert() {
  const isMobile = useIsMobileDevice();
  const { addToast } = useToast();
  const [showModal, openModal, closeModal] = useModal();
  const tags = useGetTags();
  const t = useTranslationDashboard('updateNotificationsAlert');

  const handleConfirm = () => {
    closeModal();
    checkAndRegisterDevice(true);
    addToast({
      appearance: 'success',
      label: t('successToast'),
    });
  };

  if (!isMobile || !tags.includes(UPDATE_APP_NOTIFICATIONS_ALERT_TAG))
    return null;

  return (
    <>
      <CalloutCard
        appearance="warning"
        icon={InfoCircleIcon}
        title={t('title')}
        subtitle={t('subtitle')}
        onClick={openModal}
      />
      <ModalConfirmationBase
        show={showModal}
        appearance="primary"
        title={t('modal.title')}
        text={t('modal.text')}
        label={t('modal.confirm')}
        labelCancel={t('modal.cancel')}
        onConfirm={handleConfirm}
        onCancel={closeModal}
      />
    </>
  );
}
