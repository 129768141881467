import { Text } from '@nimbus-ds/components';
import { AppsListItemResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import { AppStatusTag } from './components';

interface AppsListItemStatusProps {
  app: AppsListItemResponseDto;
}

function AppsListItemStatus({ app }: AppsListItemStatusProps) {
  const { t } = useTranslationPartnerApps('appsListPage.status');

  return (
    <Stack column align="flex-start" spacing="none">
      <AppStatusTag app={app} />
      {!app.availableForStorePlan && (
        <Text fontSize="caption">{t('notInstallable')}</Text>
      )}
    </Stack>
  );
}

export default AppsListItemStatus;
