import { useEffect } from 'react';
import { Box, Button, Link, Modal, Text, Title } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { useStorage } from 'commons/hooks';
import { useTransactionFeeToPay } from 'domains/Billing/TransactionFees/pages/TransactionFeesPage/hooks';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingBillingTransactionFeesPageClick } from 'domains/Dashboard/tracking';
import {
  useGetNuvemPagoFromPorviderList,
  useInstalledPaymentProviders,
} from 'domains/Payments/PaymentProviders/hooks';

function TransactionFeeToPayInfoModal() {
  const t = useTranslationDashboard('transactionFeeToPayInfoModal');
  const { paymentProviders, fetchInstalledPaymentProviders } =
    useInstalledPaymentProviders();
  const { nuvemPagoIsActive } =
    useGetNuvemPagoFromPorviderList(paymentProviders);
  // To avoid multiple requests, the main request to "/v1/billing/transaction-fee-to-pay" is made from the DashboardPage component
  const { transactionFeeToPay } = useTransactionFeeToPay();
  const [
    transactionFeeToPayInfoModalDismissed,
    setTransactionFeeToPayInfoModalDismissed,
  ] = useStorage('transactionFeeToPayInfoModalDismissed', false);

  const handleCloseModal = () => {
    setTransactionFeeToPayInfoModalDismissed(true);
  };

  const openModal =
    !transactionFeeToPayInfoModalDismissed &&
    !!transactionFeeToPay?.requiresCptInfoText &&
    !nuvemPagoIsActive;

  useEffect(() => {
    /***
     * We call paymentProviders to find out whether or not the store has active nuvempago or pagonube
     */
    if (!paymentProviders) fetchInstalledPaymentProviders();
  }, [fetchInstalledPaymentProviders, paymentProviders]);

  return (
    <Modal open={openModal} onDismiss={handleCloseModal}>
      <Box display="flex" flexDirection="column" gap="4">
        <Title as="h3">{t('title')}</Title>
        <Text>{t('text1')}</Text>
        <Text>{t('text2')}</Text>
        <Text>{t('text3')}</Text>
        <Box display="flex" gap="2" justifyContent="flex-end">
          <Link appearance="primary" as="button" onClick={handleCloseModal}>
            {t('linkText')}
          </Link>
          <Button
            as={LinkRoute}
            to={TRANSACTION_FEES_ROUTES.transactionFees}
            appearance="primary"
            onClick={trackingBillingTransactionFeesPageClick}
          >
            {t('buttonText')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default TransactionFeeToPayInfoModal;
