export type Dispatch<TPayload = unknown> = (
  event: Message<TPayload>,
  windowRef: Window | null,
) => void;

type Unsuscribe = () => void;

export type Suscribe = (type: string, callback: any) => Unsuscribe;

export type Handler = (message: Message) => void;
export interface Message<T = unknown> {
  type: string;
  payload?: T;
}

export interface ReceivedMessage extends Message {
  source: {
    host: string;
  };
}

export const suscribeAndUnsuscribeHandlers = (
  handlers: Handler[],
  handler: Handler,
): (() => void) => {
  handlers.push(handler);
  return () => {
    handlers.splice(handlers.indexOf(handler), 1);
  };
};

export const createHandler =
  (type: string, callback: (payload: unknown) => void): Handler =>
  (message) => {
    if (message.type === type) {
      callback(message.payload);
    }
  };

export interface NexoInstance {
  dispatch: Dispatch;
  suscribe: Suscribe;
  disconnect: () => void;
}

export const createNexo = (iframe: HTMLIFrameElement): NexoInstance => {
  const iframeUrl = new URL(iframe.src);
  const handlers: Handler[] = [];

  const messageEventListener = ({ data, origin }: MessageEvent<Message>) => {
    if (!origin || origin !== iframeUrl.origin) {
      return;
    }
    handlers.forEach((handler) => handler(data));
  };

  window.addEventListener('message', messageEventListener);

  return {
    suscribe(type: string, callback: any) {
      const handler = createHandler(type, callback);
      return suscribeAndUnsuscribeHandlers(handlers, handler);
    },
    dispatch(message, windowRef) {
      if (windowRef) windowRef.postMessage(message, '*');
      iframe?.contentWindow?.postMessage(message, '*');
    },
    disconnect() {
      window.removeEventListener('message', messageEventListener);
    },
  };
};
