import { Capacitor } from '@capacitor/core';
import { Alert } from '@tiendanube/components';
import { STORE_APP } from 'commons/constants/externalLinks';
import { useIsMobileDevice, useUpdateApp, UpdateApp } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

function UpdateSuggestion() {
  const isSuggestedUpdate = useUpdateApp(UpdateApp.SUGGESTED);
  const platform = Capacitor.getPlatform();

  const isMobileDevice = useIsMobileDevice();
  const t = useTranslationDashboard();

  const handleOnClick = async () => {
    const url = STORE_APP[platform];

    if (Object.keys(STORE_APP).includes(platform)) {
      window.open(url);
    }
  };

  if (!isMobileDevice) return null;

  return (
    <Alert
      title={t('suggestedAppUpdate.title')}
      show={isSuggestedUpdate}
      appearance="primary"
      onClickPrimary={handleOnClick}
      primaryLabel={t('suggestedAppUpdate.update')}
    >
      {t('suggestedAppUpdate.info')}
    </Alert>
  );
}

export default UpdateSuggestion;
