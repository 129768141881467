import { Box, Tag, Icon, TagProps, Spinner } from '@nimbus-ds/components';
import { ArrowRightIcon, CheckCircleIcon } from '@nimbus-ds/icons';
import { PlansListResponseDto } from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PlanNameAndPrice } from '../PlanNameAndPrice';

interface PlanChangePreviewProps {
  currentPlan: PlansListResponseDto;
  newPlan: PlansListResponseDto;
  currentPlanTagAppearence?: TagProps['appearance'];
  newPlanTagAppearence: TagProps['appearance'];
  onClickNewPlanName?: () => void;
  isLoading?: boolean;
  planChangeComplete?: boolean;
}

export function PlanChangePreview({
  currentPlan,
  newPlan,
  currentPlanTagAppearence = 'neutral',
  newPlanTagAppearence,
  onClickNewPlanName,
  isLoading = false,
  planChangeComplete = false,
}: Readonly<PlanChangePreviewProps>) {
  const t = useTranslationBilling();
  const iconColor = planChangeComplete
    ? 'success-interactive'
    : 'primary-textLow';
  const iconSize = 'medium';

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap="2">
        <Tag appearance={currentPlanTagAppearence}>
          {planChangeComplete
            ? t('upsellFlow.modal.previousPlan')
            : t('upsellFlow.modal.currentPlan')}
        </Tag>
        <PlanNameAndPrice plan={currentPlan} align="left" />
      </Box>
      {isLoading && !planChangeComplete ? (
        <Spinner color="primary-textLow" size="medium" />
      ) : (
        <Icon
          color={iconColor}
          source={
            planChangeComplete ? (
              <CheckCircleIcon size={iconSize} />
            ) : (
              <ArrowRightIcon size={iconSize} />
            )
          }
        />
      )}
      <Box display="flex" flexDirection="column" gap="2" alignItems="flex-end">
        <Tag appearance={newPlanTagAppearence}>
          {planChangeComplete
            ? t('upsellFlow.modal.currentPlan')
            : t('upsellFlow.modal.newPlan')}
        </Tag>
        <PlanNameAndPrice
          plan={newPlan}
          align="right"
          onClickPlanName={onClickNewPlanName}
        />
      </Box>
    </Box>
  );
}
