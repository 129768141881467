import { useMemo } from 'react';
import { BLOG_ENABLED } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import { useTranslationOnline } from 'domains/Online';
import { useGetAllPages } from 'domains/Online/ContentPages/hooks';
import { useGetMenusQuery } from 'domains/Online/Menus/MenusApi';

function useMenuRedirectToOptions(currentMenuId = '') {
  const t = useTranslationOnline('menus.menuItemModal');

  const { data, isLoading: isLoadingMenus } = useGetMenusQuery();
  const { pages, isLoading: isLoadingPages } = useGetAllPages();
  const tags = useGetTags();

  const menus = useMemo(
    () =>
      data && data?.navigations.length > 0
        ? data?.navigations.filter((menu) => menu.id !== currentMenuId)
        : [],
    [data, currentMenuId],
  );

  const showMenuOption = menus.length > 0;
  const showPageOption = pages.length > 0;
  const showBlogOption = tags.includes(BLOG_ENABLED);
  const isLoading = isLoadingMenus || isLoadingPages;

  const options = useMemo(
    () =>
      [
        {
          label: t('selectOptions.home'),
          value: 'specialpage-home_url',
          show: true,
        },
        {
          label: t('selectOptions.contact'),
          value: 'specialpage-contact_form_url',
          show: true,
        },
        {
          label: t('selectOptions.blog'),
          value: 'specialpage-blog_url',
          show: showBlogOption,
        },
        {
          label: t('selectOptions.category'),
          value: 'category',
          show: true,
        },
        {
          label: t('selectOptions.page'),
          value: 'page',
          show: showPageOption,
        },
        {
          label: t('selectOptions.url'),
          value: 'url',
          show: true,
        },
        {
          label: t('selectOptions.menu'),
          value: 'menu',
          show: showMenuOption,
        },
      ].filter((option) => option.show),
    [t, showBlogOption, showPageOption, showMenuOption],
  );

  return {
    isLoading,
    options,
  };
}

export default useMenuRedirectToOptions;
