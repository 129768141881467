import { LocationDto } from '@tiendanube/common';
import { useTranslationAccount } from '../../../hooks';

export function useGetLocationDescription(
  location: LocationDto | undefined,
): string {
  const t = useTranslationAccount('sessionManagementPage.table.body');

  if (!location) {
    return t('withoutLocation');
  }

  const { cityName, regionName, countryName } = location;

  if (cityName && regionName) {
    return `${cityName}, ${regionName} - ${countryName}`;
  }

  if (regionName) {
    return `${regionName} - ${countryName}`;
  }

  return countryName;
}
