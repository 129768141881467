import { Button, Icon, Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { PaymentProviderDto } from '@tiendanube/common';
import {
  customPaymentsIds,
  muvemPagoOrPagoNubeIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Stack, useResponsive } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { OnPaymentProviderActionType } from '../../pages/PaymentProvidersListPage/components/PaymentProviderActionModal';
import { useProvidersAction } from '../useProvidersAction';

interface UseProvidersActionsItemsProps {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
}

const TRANSLATE_PREFIX = 'paymentProvider';

export const useProvidersActionsItems = ({
  paymentProvider,
  onAction,
}: Readonly<UseProvidersActionsItemsProps>) => {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { isMobile } = useResponsive();
  const isNuvemPagoOrPagoNube = muvemPagoOrPagoNubeIds.includes(
    paymentProvider.id,
  );

  const {
    handleGoToMainPage,
    handleEnable,
    handleCancelInstallation,
    handleGoToCompleteInstallation,
    handleDisable,
    handleGoToSettingPage,
  } = useProvidersAction({
    paymentProvider,
    onAction,
  });
  const {
    id,
    name,
    isActive,
    mustCompleteInstallation,
    isReadonly,
    processingInstallation,
    isProneToScam,
  } = paymentProvider;

  const getLeftLink = () => {
    if (processingInstallation) return [];
    if (customPaymentsIds.includes(id)) {
      return [];
    } else if (isNuvemPagoOrPagoNube) {
      return [t('actions.seeAccount')];
    } else {
      return [
        <Icon
          key={id}
          color="primary-interactive"
          source={<ExternalLinkIcon />}
        />,
        t('actions.goTo', { name }),
      ];
    }
  };

  const getRightLink = () => {
    if (processingInstallation) return [];
    if (!isReadonly && !isActive && !mustCompleteInstallation) {
      return (
        <Button
          appearance="primary"
          onClick={handleEnable}
          disabled={isProneToScam}
        >
          {t('actions.enable')}
        </Button>
      );
    } else if (!isActive && mustCompleteInstallation) {
      return (
        <div className="stratus--payment-provider-pending-buttons">
          <Stack gap="4">
            <Link
              as="button"
              appearance="primary"
              textDecoration="none"
              onClick={handleCancelInstallation}
            >
              {t('actions.cancelInstallation')}
            </Link>
            <Button onClick={handleGoToCompleteInstallation}>
              {t('actions.completeInstallation')}
            </Button>
          </Stack>
        </div>
      );
    } else if (isActive) {
      return (
        <Stack gap="4">
          {!isReadonly && (
            <Link
              as="button"
              appearance="primary"
              textDecoration="none"
              data-metric={muvemPagoOrPagoNubeIds.includes(id) || undefined}
              onClick={handleDisable}
            >
              {t('actions.disable')}
            </Link>
          )}
          <Button onClick={handleGoToSettingPage}>
            {isMobile ? t('actions.configureMobile') : t('actions.configure')}
          </Button>
        </Stack>
      );
    }
  };

  return {
    getLeftLink,
    getRightLink,
    handleGoToMainPage,
  };
};
