import { Tag } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { CurrencyType, OrderDetailsProductDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { DataList, Text, Stack } from '@tiendanube/components';
import { CurrencyPrice, ImageItemStratus } from 'commons/components';

interface OrderSummaryProductsProps {
  products: OrderDetailsProductDto[];
  currency: CurrencyType;
}

function OrderSummaryProducts({
  products,
  currency,
}: OrderSummaryProductsProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  return (
    <>
      {products.map((product) => (
        <DataList.Row key={product.id} id={product.id}>
          <DataList.Cell width={70}>
            <ImageItemStratus
              id={product.id}
              imageUrl={product.photoUrl}
              title={product.title}
              to={`/products/${product.productId}`}
              warning={!!product.issues}
            >
              <Stack wrap spacing="extra-tight" align="flex-start" column>
                <Stack wrap spacing="none" align="flex-start" column>
                  {!!product.variants && (
                    <Stack.Item>
                      <Text size="caption" appearance="light">
                        {product.variants}
                      </Text>
                    </Stack.Item>
                  )}
                  {product.properties &&
                    Object.keys(product.properties).map((key, index) => (
                      <Stack.Item key={index}>
                        <Text size="caption" appearance="light">
                          {`${key}: ${product.properties[key]}`}
                        </Text>
                      </Stack.Item>
                    ))}
                </Stack>
                {!!product.sku && (
                  <Stack.Item>
                    <Text size="caption" appearance="light">
                      {t('orderSummaryCard.products.sku', {
                        sku: product.sku,
                      })}
                    </Text>
                  </Stack.Item>
                )}
                <Stack.Item>
                  <CurrencyPrice
                    price={product.price}
                    currency={currency}
                    block
                    textAlign="right"
                    preFix={`${product.quantity}x`}
                  />
                </Stack.Item>

                {!!product.issues && (
                  <Stack.Item>
                    <Tag appearance="warning">
                      {product.quantity -
                        parseInt(product.issues.unclaimedStock) >=
                      1 ? (
                        <Text>
                          {t('orderSummaryCard.products.limitedStock', {
                            count: parseInt(product.issues.unclaimedStock),
                          })}
                        </Text>
                      ) : (
                        <Text>{t('orderSummaryCard.products.noStock')}</Text>
                      )}
                    </Tag>
                  </Stack.Item>
                )}
              </Stack>
            </ImageItemStratus>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={product.totalPrice}
              currency={currency}
              textAlign="right"
              block
            />
          </DataList.Cell>
        </DataList.Row>
      ))}
    </>
  );
}

export default OrderSummaryProducts;
