import { Box, IconButton, Tooltip } from '@nimbus-ds/components';
import { UndoIcon } from '@nimbus-ds/icons';
import { useResponsive } from 'commons/components';
import { OrderEditProduct } from 'domains/Orders/Orders/pages/OrderEditPage/components/Products/types';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { EditOrderProductDesktopActions } from './EditOrderProductDesktopActions';
import { EditOrderProductMobileActions } from './EditOrderProductMobileActions';

interface EditOrderProductActionsProps {
  product: OrderEditProduct;
  onRemoveProduct: (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => void;
  onReAddProduct: (
    productId: string,
    variantId: string,
    lineItemId?: string,
  ) => void;
}

function EditOrderProductActions({
  product,
  onRemoveProduct,
  onReAddProduct,
}: Readonly<EditOrderProductActionsProps>) {
  const t = useTranslationOrders();
  const { isMobile } = useResponsive();

  const getBaseProductActions = () =>
    isMobile ? (
      <EditOrderProductMobileActions
        onRemoveProduct={onRemoveProduct}
        product={product}
      />
    ) : (
      <EditOrderProductDesktopActions
        onRemoveProduct={onRemoveProduct}
        product={product}
      />
    );

  return (
    <Box>
      {product.editType !== 'delete' ? (
        getBaseProductActions()
      ) : (
        <Tooltip content={t('editOrders.actions.readd')} position="top" arrow>
          <IconButton
            onClick={() =>
              onReAddProduct(product.productId, product.variantId, product.id)
            }
            source={<UndoIcon />}
            size="2em"
          />
        </Tooltip>
      )}
    </Box>
  );
}

export default EditOrderProductActions;
