import { Link, Text } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { Stack, DataList } from '@tiendanube/components';
import { dateFormat, Format } from 'commons/utils/date';
import { CustomersMessagesType } from 'domains/Customers/Customers/customersSlice';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import MessagesListItemLabelReplied from '../MessagesListItemLabelReplied';
import MessagesListItemLabelType from '../MessagesListItemLabelType';

export interface MessagesListItemProps {
  id: string;
  name: string;
  date: string;
  message: string;
  type: CustomersMessagesType;
  replied: boolean;
  actions?: JSX.Element;
  onClick?: (id: string) => void;
  hasCustomerId: boolean;
  product?: {
    name: string;
    url: string;
  };
  order?: { id: string; number: number };
}

function MessagesListItem({
  id,
  name,
  date,
  message,
  type,
  replied,
  actions,
  onClick,
  hasCustomerId,
  product,
  order,
}: MessagesListItemProps): JSX.Element {
  const { Row, Cell } = DataList;
  const handleClick = () => onClick?.(id);
  const t = useTranslationCustomers();

  const notShowActionsLabelReplied =
    type !== 'newsletter' && type !== 'order_cancellation';

  const hasActions = onClick && hasCustomerId;

  return (
    <Row id={id}>
      <Cell width={60} rowTitle>
        {hasActions ? (
          <Link appearance="primary" onClick={handleClick}>
            {name}
          </Link>
        ) : (
          <Text fontWeight="bold">{name}</Text>
        )}
      </Cell>
      <Cell width={40} alignRight>
        <Text textAlign="right">{dateFormat(date, Format.DD_MM_YYYY)}</Text>
      </Cell>
      {product && (
        <Cell width={100}>
          <Text>{t('messages.product')}</Text>{' '}
          <Link as="a" appearance="primary" href={product.url} target="_blank">
            {product.name}
          </Link>
        </Cell>
      )}
      {order && (
        <Cell width={100}>
          <Text>{t('messages.order.title')}</Text>{' '}
          <Link as={LinkRoute} appearance="primary" to={`/orders/${order?.id}`}>
            {t('messages.order.descripcion', {
              orderNumber: order.number,
            })}
          </Link>
        </Cell>
      )}
      <Cell width={100}>
        <Text>{message}</Text>
      </Cell>
      <Cell width={90} extraPadding>
        <Stack spacing="tight">
          <Stack.Item>
            <MessagesListItemLabelType id={id} type={type} />
          </Stack.Item>
          {notShowActionsLabelReplied && (
            <Stack.Item>
              <MessagesListItemLabelReplied id={id} replied={replied} />
            </Stack.Item>
          )}
        </Stack>
      </Cell>
      <Cell width={10} extraPadding alignRight>
        {actions}
      </Cell>
    </Row>
  );
}

export default MessagesListItem;
