import {
  BaseCard,
  Stack,
  Title,
  Text,
  Link,
  InterfaceNameValue,
} from '@tiendanube/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { OcaFileInput, OcaPhoneNumberInput } from './OcaInputField';
import { RequestOcaRegistrationValues } from '../types';

const DOC_FILE_EXTENSIONS =
  '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf';

const CSV_FILE_EXTENSIONS =
  '.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx,.xls';

interface OcaDocumentationFormProps {
  values: RequestOcaRegistrationValues;
  errors: Partial<Record<string, string>>;
  onChange: (data: InterfaceNameValue) => void;
  setFieldValue: (name: string, value: any) => void;
}

function OcaDocumentationForm({
  values,
  errors,
  onChange,
  setFieldValue,
}: OcaDocumentationFormProps): JSX.Element {
  const t = useTranslationShipping();

  const handleFileChange = (name: string) => (file: File) => {
    setFieldValue(name, file);
  };

  const handleFileRemove = (name: string) => () => {
    setFieldValue(name, null);
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack column align="stretch" spacing="tight">
          <Title type="h3">{`${t('oca.requestRegistration.title')}`}</Title>
          <Text>{t('oca.requestRegistration.subtitle')}</Text>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <OcaFileInput
            index={1}
            title={t(
              'oca.requestRegistration.fields.proofOfRegistrationAfip.title',
            )}
            subtitle={
              <>
                {t(
                  'oca.requestRegistration.fields.proofOfRegistrationAfip.subtitle',
                )}
                <Link
                  appearance="primary"
                  size="small"
                  href={t(
                    'oca.requestRegistration.fields.proofOfRegistrationAfip.link',
                  )}
                >
                  {t(
                    'oca.requestRegistration.fields.proofOfRegistrationAfip.steps',
                  )}
                </Link>
                .
              </>
            }
            fileName={values?.proofOfRegistrationAfip?.name}
            error={errors.proofOfRegistrationAfip}
            onChange={handleFileChange('proofOfRegistrationAfip')}
            onRemove={handleFileRemove('proofOfRegistrationAfip')}
            accept={DOC_FILE_EXTENSIONS}
          />
          <OcaFileInput
            index={2}
            title={
              <>
                <Link
                  appearance="primary"
                  size="small"
                  href={t('oca.requestRegistration.fields.taxStatusForm.link')}
                >
                  {t('oca.requestRegistration.fields.taxStatusForm.title')}
                </Link>
                {t('oca.requestRegistration.fields.taxStatusForm.completed')}
              </>
            }
            error={errors.taxStatusForm}
            fileName={values?.taxStatusForm?.name}
            onChange={handleFileChange('taxStatusForm')}
            onRemove={handleFileRemove('taxStatusForm')}
            accept={DOC_FILE_EXTENSIONS}
          />
          <OcaFileInput
            index={3}
            title={t(
              'oca.requestRegistration.fields.proofOfRegistrationIIBB.title',
            )}
            subtitle={
              <>
                {t(
                  'oca.requestRegistration.fields.proofOfRegistrationIIBB.subtitle1',
                )}
                <Link
                  size="small"
                  underline
                  href={t(
                    'oca.requestRegistration.fields.proofOfRegistrationIIBB.link',
                  )}
                >
                  {t(
                    'oca.requestRegistration.fields.proofOfRegistrationIIBB.google',
                  )}
                </Link>
                {t(
                  'oca.requestRegistration.fields.proofOfRegistrationIIBB.subtitle2',
                )}
              </>
            }
            error={errors.proofOfRegistrationIIBB}
            fileName={values?.proofOfRegistrationIIBB?.name}
            onChange={handleFileChange('proofOfRegistrationIIBB')}
            onRemove={handleFileRemove('proofOfRegistrationIIBB')}
            accept={DOC_FILE_EXTENSIONS}
          />
          <OcaFileInput
            index={4}
            title={
              <Link
                appearance="primary"
                size="small"
                href={t(
                  'oca.requestRegistration.fields.ocaRegistrationData.link',
                )}
              >
                {t('oca.requestRegistration.fields.ocaRegistrationData.title')}
              </Link>
            }
            error={errors.ocaRegistrationData}
            fileName={values?.ocaRegistrationData?.name}
            onChange={handleFileChange('ocaRegistrationData')}
            onRemove={handleFileRemove('ocaRegistrationData')}
            accept={CSV_FILE_EXTENSIONS}
          />
          <OcaPhoneNumberInput
            countryCode={values.countryCode}
            areaCode={values.areaCode}
            phoneNumber={values.phoneNumber}
            error={errors.countryCode || errors.areaCode || errors.phoneNumber}
            onChange={onChange}
          />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default OcaDocumentationForm;
