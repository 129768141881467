import { useEffect } from 'react';
import { Stack } from '@tiendanube/components';
import PredictMultiSelect from 'commons/components/PredictMultiSelect';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useFetchTags } from 'domains/Catalog/Products/pages/hooks';
import { TagsType, onChangeTagsType } from '../../TagsAndSeo';
import TagAndSeoItemTitle from '../TagAndSeoItemTitle';

interface AllTagsProps {
  tags: TagsType;
  onChange: onChangeTagsType;
}

function AllTags({ tags, onChange }: AllTagsProps): JSX.Element {
  const t = useTranslationCatalog('products.detail.tags');
  const { optionsTags, isLoading } = useFetchTags();
  const { fetchStandardTags } = useFetchTags();

  const defaultValue = tags
    ? tags.map((tag) => ({
        label: tag,
        value: tag,
      }))
    : [];

  const handleChange = (values) => {
    const newValues = values.map(({ value }) => value);
    onChange({ name: 'tags', value: newValues });
  };
  const optionMessage = () => (isLoading ? t('loading') : t('withoutResults'));

  useEffect(() => {
    fetchStandardTags();
  }, [fetchStandardTags]);

  return (
    <TagAndSeoItemTitle title={t('subTitle')} description={t('description')}>
      <Stack column align="stretch">
        <PredictMultiSelect
          defaultValue={defaultValue}
          options={optionsTags}
          onChange={handleChange}
          optionMessage={optionMessage}
          createText={t('addTags')}
        />
      </Stack>
    </TagAndSeoItemTitle>
  );
}

export default AllTags;
