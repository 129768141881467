import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getDashboard } from '../../firstSaleSlice';

const getPaymentMessagesEntity = createSimpleSelector(
  [getDashboard],
  (dashboard) => dashboard.paymentMessages,
);

const getMercadoPagoMessages = createSimpleSelector(
  [getPaymentMessagesEntity],
  (paymentMessages) => paymentMessages.mercadopago,
);

const getMercadoPagoCredentials = createSimpleSelector(
  [getMercadoPagoMessages],
  (mercadoPagoMessages) => mercadoPagoMessages.credentials,
);

const getMercadoPagoVerify = createSimpleSelector(
  [getMercadoPagoMessages],
  (mercadoPagoMessages) => mercadoPagoMessages.verify,
);

const getRemoveMercadoPagoNotification = createSimpleSelector(
  [getMercadoPagoCredentials],
  (credentials) => credentials.removeNotification,
);

const getUpdateMercadoPagoCredentials = createSimpleSelector(
  [getMercadoPagoCredentials],
  (credentials) => credentials.updateCredentials,
);

export const getRemoveMercadoPagoNotificationStatus = createSimpleSelector(
  [getRemoveMercadoPagoNotification],
  (removeNotification) => removeNotification.status,
);

export const getUpdateMercadoPagoNotificationStatus = createSimpleSelector(
  [getUpdateMercadoPagoCredentials],
  (updateCredentials) => updateCredentials.status,
);

export const getMercadoPagoVerifyStatus = createSimpleSelector(
  [getMercadoPagoVerify],
  (mp) => mp.status,
);

export const getMercadoPagoVerifyData = createSimpleSelector(
  [getMercadoPagoVerify],
  (mp) => mp.data,
);
