import { useSelector } from 'react-redux';
import { AppsIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import { ActionProp } from 'commons/components/IonPageStratus/components';
import { getActionsOpenProducts, PATH_WIDGETS } from 'domains/PartnersApps';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';

function useAppsActionOpenProducts(id: string): ActionProp[] {
  const { goTo, buildPath } = useNavegate();
  const actions = useSelector(getActionsOpenProducts);
  const { nuvemApps } = useNuvemApps();

  const { isDesktop } = useResponsive();

  // Temporary avoid to show the apps option in desktop, this options is only available to show App Widget
  if (isDesktop || !nuvemApps.widgets) {
    return [];
  }

  return actions.map(({ appId, text, path }) => {
    const subPathname = buildPath(path, { id });
    const principalPathname =
      appId === nuvemApps.widgets?.id ? PATH_WIDGETS : `/apps/${id}`;
    return {
      children: text,
      icon: AppsIcon,
      onClick() {
        goTo(`${principalPathname}#${subPathname}`);
      },
    };
  });
}
export default useAppsActionOpenProducts;
