import { Box, BoxProps, Text, Title } from '@nimbus-ds/components';
import { Skeleton } from './Skeleton';

interface SplitSectionProps extends BoxProps {
  title: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}

export function SplitSection({
  title,
  description,
  children,
  ...boxProps
}: Readonly<SplitSectionProps>) {
  return (
    <Box display="flex" flexDirection="column" gap="4" {...boxProps}>
      <Box display="flex" flexDirection="column" gap="2">
        <Title as="h5">{title}</Title>
        <Text>{description}</Text>
      </Box>
      {children}
    </Box>
  );
}

SplitSection.Skeleton = Skeleton;
