import * as Yup from 'yup';
import { LanguagesType } from 'App/i18n';
import { validateEmojis } from 'domains/Catalog/Categories/pages/CategoriesListPage/utils';

export const categorySchema = (
  languages: LanguagesType[],
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    name: Yup.string()
      .required('categories.detail.name.error')
      .test(
        'emoji-validation',
        'categories.detail.name.validationEmojis',
        validateEmojis,
      ),
    googleShoppingCategory: Yup.string().test(
      'emoji-validation',
      'categories.detail.name.validationEmojis',
      validateEmojis,
    ),
    seoTitle: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().test(
            'emoji-validation',
            'categories.detail.name.validationEmojis',
            validateEmojis,
          ),
        }),
        {},
      ),
    }),
    seoDescription: Yup.object().shape({
      ...languages.reduce(
        (acc, current) => ({
          ...acc,
          [current]: Yup.string().test(
            'emoji-validation',
            'categories.detail.name.validationEmojis',
            validateEmojis,
          ),
        }),
        {},
      ),
    }),
  });
