import { Route } from 'react-router-dom';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { INVOICES_ROUTES } from './invoicesRoutes';
import { InvoicesListPage, InvoicesInfoPage } from './pages';

function Invoices() {
  const { validateRoles } = useRoles();

  if (!validateRoles('invoice_history')) return null;

  return (
    <>
      <Route exact path={INVOICES_ROUTES.invoicesList}>
        <InvoicesListPage />
      </Route>
      <Route exact path={INVOICES_ROUTES.invoiceInfo}>
        <InvoicesInfoPage />
      </Route>
    </>
  );
}

export default Invoices;
