import { useCallback, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CategoryTabedResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useToast } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { getCategoryOptions } from '../../categoriesSlice/categoriesSelectors';
import { getCategoryOptions as getCategoryOptionsAction } from '../../categoriesSlice/categoriesSlice';

function useGetCategories(): CategoryTabedResponseDto[] {
  const dispatch = useAppDispatch();
  const t = useTranslationCatalog();
  const { addToast } = useToast();
  const categories = useSelector(getCategoryOptions);

  const fetchCategoryOptions = useCallback(async () => {
    try {
      const result = await dispatch(getCategoryOptionsAction());
      return unwrapResult(result);
    } catch (error) {
      addToast({
        label: t('products.detail.getCategoriesError'),
        appearance: 'danger',
      });
    }
  }, [addToast, dispatch, t]);

  useEffect(() => {
    fetchCategoryOptions();
  }, [fetchCategoryOptions]);

  return categories.map((category) => ({
    ...category,
    title: category.title.trim(),
  }));
}

export default useGetCategories;
