import { CHART_COLORS } from 'domains/Statistics/utils';

export const STATS_PAYMENTS_SUMMARY_COLORS = {
  revenueByPaymentMethod: CHART_COLORS.lightBlue,
  salesByPaymentMethod: CHART_COLORS.darkPurple,
  revenueByPaymentGateway: CHART_COLORS.deepPurple,
  salesByPaymentGateway: CHART_COLORS.electricBlue,
  revenueByInstallments: CHART_COLORS.pink,
  salesByInstallments: CHART_COLORS.burntOrange,
};
