import { TrialMessagesResponseDto } from '@tiendanube/common';
import axios from 'App/axios';
import { DASHBOARD_BILLING_MESSAGES_URL } from '../urls';

export async function getTrialMessages() {
  const { data } = await axios.get<TrialMessagesResponseDto>(
    DASHBOARD_BILLING_MESSAGES_URL,
  );
  return data;
}
