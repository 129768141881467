import { ChangeEvent } from 'react';
import { Card, Text, Box, Title, Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import SubmitButton from 'commons/components/SubmitButton';
import { useForm, useToastStatus } from 'commons/hooks';
import {
  useGetEnabledCountriesListQuery,
  useUpdateMainCountryMutation,
} from 'domains/Configurations/I18n/i18nApi';
import {
  enabledCountries,
  mainCountryData,
} from 'domains/Configurations/I18n/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import Skeleton from './Skeleton';
import { getFlagEmoji } from '../../utils/getFlagEmoji';

function MainCountryCard() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const { t: tCommon } = useTranslation('common');

  const { data, isLoading, isSuccess, isError, refetch } =
    useGetEnabledCountriesListQuery();
  const mainCountry = useGetEnabledCountriesListQuery(undefined, {
    selectFromResult: ({ data }) => mainCountryData(data),
  });

  const [
    updateCountryMain,
    { status: statusUpdate, isLoading: loadingUpdate },
  ] = useUpdateMainCountryMutation();

  useToastStatus({
    status: toStatusType(statusUpdate),
    error: t('toastErrorUpate'),
    success: t('toastSuccessUpdate'),
  });

  const enabledCountriesList = enabledCountries(data);

  const hasOneOption = data?.results.length === 1;

  const { handleOnChange, handleOnSubmit, values, isDirty } = useForm({
    initialValues: mainCountry,
    onSubmit: ({ mainCountryId }) => {
      updateCountryMain(mainCountryId);
    },
  });

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLSelectElement>) => {
    handleOnChange({ name, value });
  };

  const submitButtonDisabled =
    values.mainCountryId === mainCountry.mainCountryId;

  return (
    <Card padding="none">
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
      <Card.Header padding="base">
        <Title as="h3">{t('mainCountry.title')}</Title>
      </Card.Header>
      <Card.Body padding="base">
        <Box display="grid" gridGap="4" paddingBottom="4">
          <Text>{t('mainCountry.subtitle')}</Text>
          {isLoading && <Skeleton />}
          {isSuccess && (
            <>
              <FormField.Select
                id="main-country"
                label={t('mainCountry.mainCountrylabel')}
                name="mainCountryId"
                onChange={handleChange}
                disabled={hasOneOption}
                defaultValue={mainCountry.mainCountryId}
              >
                {enabledCountriesList?.map(
                  ({ countryId, countryCode, countryName, id }) => {
                    const flag = getFlagEmoji(countryCode);
                    return (
                      <Select.Option
                        key={countryId}
                        value={id}
                        label={`${flag} ${countryName}`}
                      />
                    );
                  },
                )}
              </FormField.Select>
              {!hasOneOption && (
                <SubmitButton
                  onClick={handleOnSubmit}
                  isLoading={loadingUpdate}
                  disabled={submitButtonDisabled}
                  appearance="primary"
                >
                  {t('buttonSave')}
                </SubmitButton>
              )}
            </>
          )}
          {isError && (
            <Box paddingBottom="4">
              <ErrorScreen onRetry={refetch} description={t('errorState')} />
            </Box>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default MainCountryCard;
