import { Card, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { trackingShippingAppsMoreClick } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ActiveShippingMethodsTag from '../../../ActiveShippingMethodsTag';
import { ActiveShippingMethodIconType } from '../../../ActiveShippingMethodsTag/utils';

export interface AvailableShippingAppsTags {
  icon: ActiveShippingMethodIconType;
  text: string;
}

export interface AvailableShippingAppsLinks {
  type: string;
  text: string;
  url?: string;
  link?: string;
}

interface AboutAppCardProps {
  tags: AvailableShippingAppsTags[];
  links: AvailableShippingAppsLinks[];
  id: string;
  name: string;
  description: string;
}

function AboutAppCard({
  name,
  description,
  tags,
  links,
  id,
}: AboutAppCardProps) {
  const t = useTranslationShipping();

  const handleClick = (id: string, name: string, linkType: string) => {
    if (linkType === 'details') {
      trackingShippingAppsMoreClick(id, name);
    }
  };

  return (
    <Card>
      <Card.Header
        title={t('deliveryMethods.activeShippingMethods.modal.detail.about', {
          app: name,
        })}
      />
      <Card.Body>
        <Stack column align="stretch">
          <Text>{description}</Text>
          <Stack wrap spacing="tight">
            {tags.map((tag) => (
              <ActiveShippingMethodsTag
                key={tag.text}
                icon={tag.icon}
                text={tag.text}
              />
            ))}
          </Stack>
          {links.map((link) => (
            <Link
              as="a"
              textDecoration="none"
              key={link.type}
              appearance="primary"
              target="_blank"
              href={link.url ?? link.link}
              onClick={() => handleClick(id, name, link.type)}
            >
              {link.text}
              <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
            </Link>
          ))}
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default AboutAppCard;
