import axios from 'App/axios';
import { GetNotificationsResponse } from 'domains/Payments/PaymentNotification/types';

const getNotifications = async (): Promise<GetNotificationsResponse[]> => {
  const { data } = await axios.get(`v1/commons/notifications`);
  return data;
};

const deleteNotification = async (id: string): Promise<void> => {
  await axios.delete(`v1/commons/notifications/${id}`);
};

export default {
  getNotifications,
  deleteNotification,
};
