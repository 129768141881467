import { DataList } from '@tiendanube/components';
import { MetafieldItem } from 'domains/Metafields/components/MetafieldInput';
import {
  MetafieldInterface,
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
  SourceType,
} from 'domains/Metafields/types';

import './MetafieldsVariantsDataList.scss';

interface MetafieldsVariantsDataListProps {
  metafields: MetafieldInterface[] | null;
  selectedMetafields: MetafieldSelectedInterface[];
  source: SourceType;
  onChange: OnChangeMetafieldType;
  onApplytoAllVariants?: (
    value: MetafieldSelectedInterface,
    source: SourceType,
  ) => void;
}

export default function MetafieldsVariantsDataList({
  metafields,
  selectedMetafields,
  source,
  onChange,
  onApplytoAllVariants,
}: MetafieldsVariantsDataListProps) {
  return (
    <DataList spacing="base" ruled={false}>
      {metafields?.map((metafield, index) => {
        const found = selectedMetafields.find(
          (selectedMetafield) => selectedMetafield.id === metafield.id,
        );
        const value = found?.value || '';

        if (metafield === null) {
          return null;
        }

        return (
          <div
            className="data-list-row-wrapper data-list-row-wrapper-metafields"
            key={`${metafield.id}-${index}`}
          >
            <DataList.Row id={`${index}`}>
              <DataList.Cell width={100}>
                <MetafieldItem
                  value={value}
                  metafield={metafield}
                  showDescription={!!metafield.description}
                  source={source}
                  onChange={onChange}
                  onApplytoAllVariants={onApplytoAllVariants}
                />
              </DataList.Cell>
            </DataList.Row>
          </div>
        );
      })}
    </DataList>
  );
}
