import { Box } from '@nimbus-ds/components';
import classNames from 'classnames';

import './ColorTile.scss';

interface ColorTileProps {
  color: string;
  onClick: () => void;
  selected?: boolean;
}

function ColorTile({ color, onClick, selected }: Readonly<ColorTileProps>) {
  const isWhite = color === '#FFFFFF';
  const isNone = color === 'none';
  return (
    <Box width="100%" cursor="pointer" onClick={onClick}>
      <div
        className={classNames('stratus--color-tile-rectangle', {
          '--selected': selected,
          '--white': isWhite || isNone,
          '--none': isNone,
        })}
        style={!isNone ? { backgroundColor: color, borderColor: color } : {}}
      />
    </Box>
  );
}

export default ColorTile;
