import { ChangeEvent, useEffect, useState } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { ExternalLink } from 'commons/components';
import { useGetExternalCodesQuery } from 'domains/Configurations/ExternalCodes/externalCodesApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { LinkedGoogleAnalytics } from './LinkedGoogleAnalytics';
import { SplitSection } from '../SplitSection';

interface GoogleAnalitycsProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  googleAnalyticsMeasurementId: string;
  googleAnalyticsApiSecret: string;
}

export function GoogleAnalitycs({
  onChange,
  googleAnalyticsMeasurementId,
  googleAnalyticsApiSecret,
}: Readonly<GoogleAnalitycsProps>) {
  const t = useTranslationConfigurations(
    'externalCodes.google.googleAnalytics',
  );
  const { data } = useGetExternalCodesQuery();

  const [hasClicked, setHasClicked] = useState(false);

  const handleClick = () => {
    setHasClicked(!hasClicked);
  };

  const showLinkedCard =
    !!data?.googleAnalyticsApiSecret &&
    !!data.googleAnalyticsMeasurementId &&
    !hasClicked;

  useEffect(() => {
    if (
      data?.googleAnalyticsApiSecret === googleAnalyticsApiSecret ||
      data?.googleAnalyticsMeasurementId === googleAnalyticsMeasurementId
    ) {
      setHasClicked(false);
    }
    // We avoid seeing when the googleAnalyticsApiSecret or googleAnalyticsMeasurementId value changes to avoid blocking the input when this value is changed
    // We just need to know when data?.googleAnalyticsApiSecret or data?.googleAnalyticsMeasurementId changes to show the linked card
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.googleAnalyticsApiSecret, data?.googleAnalyticsMeasurementId]);

  return (
    <SplitSection title={t('title')} description={t('description')}>
      {showLinkedCard ? (
        <LinkedGoogleAnalytics
          googleAnalyticsMeasurementId={googleAnalyticsMeasurementId}
          googleAnalyticsApiSecret={googleAnalyticsApiSecret}
          onClick={handleClick}
        />
      ) : (
        <>
          <FormField.Input
            onChange={onChange}
            value={googleAnalyticsMeasurementId}
            name="googleAnalyticsMeasurementId"
            placeholder={t('googleAnalyticsMeasurementIdPlaceholder')}
            label={t('googleAnalyticsMeasurementIdLabel')}
          />
          <FormField.Input
            onChange={onChange}
            value={googleAnalyticsApiSecret}
            name="googleAnalyticsApiSecret"
            placeholder={t('googleAnalyticsApiSecretPlaceholder')}
            label={t('googleAnalyticsApiSecretLabel')}
          />
          <ExternalLink
            appearance="primary"
            textDecoration="none"
            href={t('href')}
          >
            {t('link')}
          </ExternalLink>
        </>
      )}
    </SplitSection>
  );
}
