import { useEffect, useState } from 'react';
import { FEATURE_MONTHLY_CATALOG_EXPORT } from 'App/features';
import { ExportCard } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { CATALOG_EXPORT_PRODUCTS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import ExportProductsAlert from './ExportProductsAlert';
import { useProductsFilters } from '../../../hooks';
import { useExportProducts } from '../../hooks';
import useDonwloadProductCsv from '../../hooks/useDonwloadProductCsv';
import ExportProductsFiltersModal from '../ExportProductsFiltersModal';

function ExportProductsCard(): JSX.Element {
  const t = useTranslationCatalog();
  const [showFiltersModal, openFiltersModal, closeFiltersModal] = useModal();
  const {
    filters,
    totalResults,
    isLoading,
    getTotalProducts,
    refreshTotalProducts,
    removeFilter,
  } = useExportProducts();
  const { getFilterChips } = useProductsFilters(filters);
  const {
    exportProducts,
    isExporting,
    jobCsvDownloadStatus,
    ressetExportingStatus,
    csvDownloadLinkUrl,
    exportProductsStatus,
  } = useDonwloadProductCsv();
  const [includeDescriptions, setIncludeDescriptions] = useState(false);

  useEffect(() => {
    refreshTotalProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnConfirm = useUpsellFlow({
    title: t('products.importExportPage.export.upsellTitle'),
    featureKey: FEATURE_MONTHLY_CATALOG_EXPORT,
    trackingSource: CATALOG_EXPORT_PRODUCTS,
    callback: () => {
      ressetExportingStatus();
      exportProducts(includeDescriptions);
    },
  });

  const disableExport = totalResults === 0 || isLoading;

  useToastStatus({
    error: t('products.importExportPage.export.exportError'),
    status: exportProductsStatus,
  });

  return (
    <>
      <ExportCard
        filters={getFilterChips()}
        totalLabel={t('products.importExportPage.export.total')}
        total={totalResults}
        loadingTotal={isLoading}
        checkboxLabel={t(
          'products.importExportPage.export.includeDescriptions',
        )}
        checkboxValue={includeDescriptions}
        exporting={isExporting}
        disabled={disableExport}
        alert={
          <ExportProductsAlert
            exportingStatus={jobCsvDownloadStatus}
            csvUrl={csvDownloadLinkUrl}
            ressetExportingStatus={ressetExportingStatus}
          />
        }
        onRemoveFilter={removeFilter}
        onOpenFilters={openFiltersModal}
        onCheckboxChange={setIncludeDescriptions}
        onConfirm={handleOnConfirm}
      />
      <ExportProductsFiltersModal
        show={showFiltersModal}
        filters={filters}
        onConfirm={getTotalProducts}
        onClose={closeFiltersModal}
      />
    </>
  );
}

export default ExportProductsCard;
