import { useEffect, useState } from 'react';
import { Input } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';

function TextItem({
  metafield,
  disabled,
  value,
  onChange,
  onUpdate,
}: ItemProps) {
  const [internalValue, setInternalValue] = useState(value);
  const t = useTranslationsMetafields();
  const placeholder = t('assignMetafieldsForm.placeholders.textOrNumber');

  const handleChange = ({ target: { value } }) => {
    setInternalValue(value);
    onChange?.(value);
  };

  const handleBlur = ({ target: { value } }) => {
    setInternalValue(value);
    onUpdate?.(value || null);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <Input
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      name={metafield.name}
      value={internalValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
}

export default TextItem;
