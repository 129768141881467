import { Badge, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { AddressingConfigurationRuleResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { ADDRESSING_RULES_TRANSLATE_TYPES } from '../../constants';

interface InactiveRulesListProps {
  rules: AddressingConfigurationRuleResponseDto[];
  handleAddRule: (rule: AddressingConfigurationRuleResponseDto) => void;
}

function InactiveRulesList({
  rules,
  handleAddRule,
}: Readonly<InactiveRulesListProps>) {
  const t = useTranslationShipping('deliveryMethods.addressingConfiguration');

  return (
    <Stack column spacing="tight" align="flex-start" alignSelf="stretch">
      <Stack spacing="tight">
        <Badge appearance="danger" count={rules.length} />
        <Title as="h6" fontWeight="bold">
          {t('modal.inactiveRules.title')}
        </Title>
      </Stack>
      {rules.length > 0 && (
        <div className="addressing-configuration-data-list">
          <DataList id="data-list">
            {rules.map((rule) => (
              <DataList.Row key={rule.code}>
                <Stack justify="space-between">
                  <Stack column spacing="tight" align="flex-start">
                    <Text>
                      {t(
                        `types.${
                          ADDRESSING_RULES_TRANSLATE_TYPES[rule.type]
                        }.title`,
                        {
                          appName: rule.appName,
                        },
                      )}
                    </Text>
                    <Text fontSize="caption">
                      {t(
                        `types.${
                          ADDRESSING_RULES_TRANSLATE_TYPES[rule.type]
                        }.description`,
                      )}
                    </Text>
                  </Stack>
                  <Link onClick={() => handleAddRule(rule)}>
                    <Icon
                      color="success-interactive"
                      source={<PlusCircleIcon />}
                    />
                  </Link>
                </Stack>
              </DataList.Row>
            ))}
          </DataList>
        </div>
      )}
      {rules.length === 0 && (
        <Text fontSize="caption">{t('modal.inactiveRules.noRules')}</Text>
      )}
    </Stack>
  );
}

export default InactiveRulesList;
