import { useHistory } from 'react-router-dom';
import {
  Card,
  Thumbnail,
  Input,
  Text,
  Stack,
  Grid,
} from '@tiendanube/components';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function ProductDetailsSkeleton(): JSX.Element {
  const t = useTranslationCatalog();
  const history = useHistory();

  const handleGoBack = () => history.goBack();

  const backNavigation = {
    onClick: handleGoBack,
  };

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title="skeleton" />}
      width="small"
    >
      <Stack column spacing="loose" align="stretch">
        <Card title={t('products.detail.nameAndDescription')}>
          <Input.Skeleton />
          <Input.Skeleton rows={4} />
        </Card>
        <Card title={t('products.detail.photos')}>
          <Grid spacing="tight">
            <Grid.Row>
              <Grid.Column>
                <Thumbnail.Skeleton />
              </Grid.Column>
              <Grid.Column>
                <Thumbnail.Skeleton />
              </Grid.Column>
              <Grid.Column>
                <Thumbnail.Skeleton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card>
        <Card title={t('products.detail.typeOfProduct')}>
          <Stack column align="flex-start" spacing="tight">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
          </Stack>
        </Card>
      </Stack>
    </IonPageStratus>
  );
}

export default ProductDetailsSkeleton;
