import { DataTable, Label, Link, Stack, Text } from '@tiendanube/components';

const { Row, Cell } = DataTable;

function ResultRowDesktopSkeleton(): JSX.Element {
  return (
    <Row id="skeleton-row" align="center">
      <Cell grow={0} basis={100}>
        <Stack spacing="tight">
          <Stack.Item>
            <Link.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell grow={0} basis={80}>
        <Text.Skeleton width="small" />
      </Cell>
      <Cell grow={1} basis={100}>
        <Stack spacing="tight">
          <Stack.Item>
            <Text.Skeleton />
          </Stack.Item>
        </Stack>
      </Cell>
      <Cell grow={0} basis={130}>
        <Text.Skeleton />
      </Cell>
      <Cell grow={0} basis={140}>
        <Text.Skeleton />
      </Cell>
      <Cell grow={0} basis={200}>
        <Label.Skeleton />
      </Cell>
      <Cell grow={0} basis={204}>
        <Label.Skeleton />
      </Cell>
      <Cell grow={0} basis={80}>
        <Text.Skeleton width="small" />
      </Cell>
    </Row>
  );
}

export default ResultRowDesktopSkeleton;
