import {
  PAGES_WITH_CODE_PARAM,
  DOMAINS_IGNORED_TO_ADMIN_WEB,
  DOMAINS_ENABLED_TO_ADMIN_WEB,
} from './constants';

export const isDomainWebAdmin = (currentHost: string): boolean => {
  const ignoreRegex = new RegExp(DOMAINS_IGNORED_TO_ADMIN_WEB.join('|'), 'i');
  const enabledRegex = new RegExp(DOMAINS_ENABLED_TO_ADMIN_WEB.join('|'), 'i');
  return !ignoreRegex.test(currentHost) && enabledRegex.test(currentHost);
};

export const getIsPageWithCodeParam = (pathname: string) =>
  PAGES_WITH_CODE_PARAM.some((path) => pathname.endsWith(path));
