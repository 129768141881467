import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Link, Modal, Stack, Text } from '@tiendanube/components';

interface RemoveCustomerDataModalProps {
  show: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

function RemoveCustomerDataModal({
  show,
  onConfirm,
  onDismiss,
}: RemoveCustomerDataModalProps): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const [disableConfirm, setDisableConfirm] = useState(false);

  const handleOnAnonymizeCustomer = () => {
    setDisableConfirm(true);
    onConfirm();
  };

  return (
    <Modal
      title={t('customerDetails.removeCustomerData.title')}
      show={show}
      onDismiss={onDismiss}
      primaryAction={{
        children: `${t('customerDetails.removeCustomerData.confirm')}`,
        appearance: 'danger',
        disabled: disableConfirm,
        onClick: handleOnAnonymizeCustomer,
      }}
      secondaryAction={{
        children: `${t('customerDetails.removeCustomerData.cancel')}`,
        onClick: onDismiss,
      }}
    >
      <Stack align="stretch" column>
        <Stack.Item>
          <Text>
            <b>{t('customerDetails.removeCustomerData.warningText1Bold')}</b>{' '}
            {t('customerDetails.removeCustomerData.warningText1')}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text>
            <b>{t('customerDetails.removeCustomerData.warningText2Bold')}</b>{' '}
            {t('customerDetails.removeCustomerData.warningText2')}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text>{t('customerDetails.removeCustomerData.warningText3')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text>
            {t('customerDetails.removeCustomerData.moreInfo')}{' '}
            <Link
              href={t('customerDetails.removeCustomerData.moreInfoLink')}
              target="_blank"
              underline
            >
              {t('customerDetails.removeCustomerData.moreInfoLinkText')}
            </Link>
          </Text>
        </Stack.Item>
      </Stack>
    </Modal>
  );
}

export default RemoveCustomerDataModal;
