import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ValidateCredentialsRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  validateCredentialsStatus,
  validateCredentials as validateCredentialsAction,
  validateCredentialsData,
} from 'domains/Account/accountSlice';

interface UseValidateCredentialsResult {
  validateCredentials: (
    payload: ValidateCredentialsRequestDto,
  ) => Promise<boolean>;
  isLoading: boolean;
  isError: boolean;
  isValid: boolean | undefined;
}

function useValidateCredentials(): UseValidateCredentialsResult {
  const status = useSelector(validateCredentialsStatus);
  const isValid = useSelector(validateCredentialsData);
  const dispatch = useAppDispatch();
  const validateCredentials = useCallback(
    async (payload: ValidateCredentialsRequestDto) => {
      const response = await dispatch(validateCredentialsAction(payload));
      return unwrapResult(response).valid;
    },
    [dispatch],
  );

  return {
    validateCredentials,
    isLoading: status === 'loading',
    isError: status === 'error',
    isValid: isValid,
  };
}

export default useValidateCredentials;
