import { Action } from '@tiendanube/common/src/enums';
import { logEvent } from 'commons/utils/tracking';
import {
  NUVEM_ENVIO_VIEW,
  ORDERS_BULK_METAFIELDS,
  ORDERS_BULK_METAFIELDS_CANCEL,
  ORDERS_BULK_METAFIELDS_SAVE,
} from 'config/trackingEvents';

export const trackingOrderBulkMetafields = (ordersId: string[]) => {
  logEvent(ORDERS_BULK_METAFIELDS, {
    ordersId: ordersId.join(', '),
  });
};

export const trackingOrderBulkMetafieldsCancel = (ordersId: string[]) => {
  logEvent(ORDERS_BULK_METAFIELDS_CANCEL, {
    ordersId: ordersId.join(', '),
  });
};

export const trackingOrderBulkMetafieldsSave = (
  ordersId: string[],
  metafieldsId: string[],
) => {
  logEvent(ORDERS_BULK_METAFIELDS_SAVE, {
    ordersId: ordersId.join(', '),
    metafieldsId: metafieldsId.join(', '),
  });
};

export const trackingOrderBulkNuvemEnvio = (ordersId: string[]) => {
  logEvent(NUVEM_ENVIO_VIEW, {
    ordersId: ordersId.join(', '),
    action: 'printNuvemEnvioLabel',
    page: 'orders',
  });
};

export const trackingOrderBulkActions = (
  ordersId: string[],
  action: Action,
) => {
  logEvent(ORDERS_BULK_METAFIELDS, {
    ordersId: ordersId.join(', '),
    page: 'orders',
    action,
  });
};
