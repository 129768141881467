import { useState } from 'react';
import { Card, Box } from '@nimbus-ds/components';
import { SeoRedirectsResponseDto } from '@tiendanube/common';
import {
  ListWithPagination,
  BlockWithTitle,
  ErrorScreen,
} from 'commons/components';
import { useGetSeoRedirectsListQuery } from 'domains/Configurations/SeoRedirects/seoRedirectsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SeoRedirectsItem, SeoRedirectListHeader } from './components';
import Skeleton from './Skeleton';
import RemoveSeoRedirectsModal from '../RemoveSeoRedirectsModal';

function SeoRedirectsListCard() {
  const t = useTranslationConfigurations('seoRedirects.seoRedirectsListCard');
  const { data, isLoading, isSuccess, isError, refetch } =
    useGetSeoRedirectsListQuery();
  const [seoRedirect, setSeoRedirect] =
    useState<SeoRedirectsResponseDto | null>(null);

  if (data?.length === 0) return null;

  const handleClose = () => {
    setSeoRedirect(null);
    close();
  };

  return (
    <BlockWithTitle title={t('title')}>
      <Card padding="none">
        {isError && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minHeight="240px"
          >
            <ErrorScreen description={t('errorState')} onRetry={refetch} />
          </Box>
        )}
        {isLoading && <Skeleton />}
        {isSuccess && !isLoading && !!data && (
          <ListWithPagination
            header={<SeoRedirectListHeader />}
            list={data}
            perPage={10}
            renderItem={(redirect) => (
              <SeoRedirectsItem redirect={redirect} onRemove={setSeoRedirect} />
            )}
          />
        )}
      </Card>
      {seoRedirect && (
        <RemoveSeoRedirectsModal
          isOpen={!!seoRedirect}
          onClose={handleClose}
          redirect={seoRedirect}
        />
      )}
    </BlockWithTitle>
  );
}

export default SeoRedirectsListCard;
