import { Button, Card, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

function SendEmailAbandonedCartCardSkeleton() {
  const t = useTranslationOrders();

  return (
    <Card title={t('abandonedCarts.sendEmail.title')}>
      <Stack column align="flex-start">
        <Text.Skeleton width="large" />
        <Button.Skeleton />
      </Stack>
    </Card>
  );
}

export default SendEmailAbandonedCartCardSkeleton;
