import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import {
  PayUPaymentProviderDto,
  UpdatePayUSettingsRequestDto,
} from '@tiendanube/common';
import { NativePaymentProviders } from '@tiendanube/common/src/domains/payments/providers/NativePaymentProviders';
import {
  BaseCard,
  Title,
  Text,
  Stack,
  Input,
  Checkbox,
  Link,
  ErrorState,
  Label,
} from '@tiendanube/components';
import { QuestionCircleIcon } from '@tiendanube/icons';
import { AlertSessionStorage } from 'App/components';
import { useNavegate } from 'App/hooks';
import { IonPageStratus, HeaderTop, HeaderContent } from 'commons/components';
import { HELP_LINKS_PAY_U_HOW_ENABLE_INT_PAYMENTS } from 'commons/constants';
import { useForm, useToastStatus } from 'commons/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import { useNativePaymentProviderSettings } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments, {
  useTranslationAndLanguagePayments,
} from 'domains/Payments/useTranslationPayments';
import { validationSchema } from './validationSchema';
import PaymentProviderSettingsSkeleton from '../PaymentProviderSettingsPage/components/PaymentProviderSettingsSkeleton';

const inputFields = ['tradeId', 'apiKey', 'accountId'];

const emptySettingValues = {
  tradeId: '',
  apiKey: '',
  accountId: '',
  acceptInternationalCreditCards: false,
};

const PAYU_ACCOUNT_CONFIGURATION_LINK =
  'https://secure.payulatam.com/configuration/';

const buildPayload = (values: UpdatePayUSettingsRequestDto) => ({
  ...values,
  appId: NativePaymentProviders.payu,
});

function PayUSettingsPage(): JSX.Element {
  const t = useTranslationPayments('paymentProviders.payUSettingsPage');
  const { t: errorStateTranslations, language } =
    useTranslationAndLanguagePayments('paymentProvider');
  const { t: globalTranslation } = useTranslation();
  const { goBack } = useNavegate();
  const [successSubmit, setSuccessSubmit] = useState(false);

  const {
    isError,
    isLoading,
    updateStatus,
    paymentProvider,
    fetch,
    updatePaymentProviderSettings,
  } = useNativePaymentProviderSettings<PayUPaymentProviderDto>(
    NativePaymentProviders.payu,
  );

  const {
    errors,
    values,
    isDirty,
    isValidating,
    handleOnBlur,
    handleOnChange,
    handleOnSubmit,
    handleValidations,
  } = useForm<UpdatePayUSettingsRequestDto, UpdatePayUSettingsRequestDto>({
    initialValues: paymentProvider
      ? paymentProvider.settings
      : emptySettingValues,
    validationSchema,
    onSubmit: async (values) => {
      updatePaymentProviderSettings(buildPayload(values));
      setSuccessSubmit(true);
    },
    validateOnBlur: ['tradeId'],
  });

  useToastStatus({
    error: t('submit.error'),
    success: t('submit.success'),
    status: updateStatus,
    onSuccess: goBack,
  });

  const isValidatingOrLoading = updateStatus === 'loading' || isValidating;

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={`${t('title')}`} />}
    >
      <Prompt
        when={isDirty && !successSubmit}
        message={globalTranslation('common:exitEdit.info')}
      />
      {isError && (
        <ErrorState
          title={errorStateTranslations('settings.error.title')}
          action={{
            children: errorStateTranslations('settings.error.action'),
            onClick: fetch,
          }}
        />
      )}
      {isLoading && <PaymentProviderSettingsSkeleton />}
      {!isLoading && !isError && (
        <>
          <Stack column align="flex-start">
            <Label
              id="PayUSettingsPageLabel"
              appearance="primary"
              label={`${t('label')}`}
            />
            <AlertSessionStorage
              appearance="primary"
              keyName="PayUSettingsPage"
            >
              {t('banner')}
            </AlertSessionStorage>
          </Stack>
          <BaseCard>
            <BaseCard.Header>
              <Title type="h3">{`${t('account.title')}`}</Title>
            </BaseCard.Header>
            <BaseCard.Body>
              <Stack column justify="space-between" align="stretch">
                <Text>
                  {t('account.description.firstPart')}
                  {t('account.description.space')}
                  <Link
                    href={PAYU_ACCOUNT_CONFIGURATION_LINK}
                    target="_blank"
                    appearance="default"
                    underline
                  >
                    {t('account.description.secondPart')}
                  </Link>
                  {t('account.description.space')}
                  {t('account.description.thirdPart')}
                </Text>
                {inputFields.map((field) => (
                  <Input
                    key={field}
                    onChange={handleOnChange}
                    value={values[field]}
                    name={t(`fields.${field}.name`)}
                    type={field === 'apiKey' ? 'text' : 'number'}
                    required
                    appearance={
                      errors[field] || isError ? 'validation_error' : undefined
                    }
                    label={t(`fields.${field}.label`)}
                    onBlur={handleOnBlur}
                    helpText={errors[field] ? t(errors[field]) : ''}
                  />
                ))}
              </Stack>
            </BaseCard.Body>
          </BaseCard>

          <BaseCard>
            <BaseCard.Header>
              <Title type="h3">{`${t('international_payments.title')}`}</Title>
            </BaseCard.Header>
            <BaseCard.Body>
              <Checkbox
                label={t(
                  'international_payments.accept_international_credit_cards',
                )}
                name="acceptInternationalCreditCards"
                checked={values.acceptInternationalCreditCards}
                onChange={({ name, checked }) =>
                  handleOnChange({ name, value: checked })
                }
              />
            </BaseCard.Body>
            <BaseCard.Body>
              <Stack justify="flex-start" spacing="tight">
                <QuestionCircleIcon />
                <Link
                  iconPosition="end"
                  href={HELP_LINKS_PAY_U_HOW_ENABLE_INT_PAYMENTS[language]}
                  target="_blank"
                  appearance="primary"
                  underline
                >
                  {t('international_payments.linkDescription')}
                </Link>
              </Stack>
            </BaseCard.Body>
          </BaseCard>

          <CancelAndSaveButtonsWithAuth
            typeModal="centered"
            payload={buildPayload(values)}
            onCancel={goBack}
            onSave={handleOnSubmit}
            preSubmitValidation={handleValidations}
            isLoading={isValidatingOrLoading}
          />
        </>
      )}
    </IonPageStratus>
  );
}

export default PayUSettingsPage;
