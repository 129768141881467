import { ErrorState } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

interface AbandonedCartsListErrorProps {
  onRetryError: () => void;
}

function AbandonedCartsListError({
  onRetryError,
}: AbandonedCartsListErrorProps) {
  const t = useTranslationOrders();
  return (
    <ErrorState
      title={t('abandonedCarts.error.title')}
      action={{
        children: t('abandonedCarts.error.action'),
        onClick: onRetryError,
      }}
    />
  );
}

export default AbandonedCartsListError;
