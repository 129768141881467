import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MetafieldsMapV2Dto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import {
  trackingOrderMetafieldSave,
  trackingOrderMetafieldSaveError,
} from 'domains/Orders/tracking';
import { MetafieldBulkAssignmentsInterface } from './types';
import ordersService from '../orderMetafieldsService/orderMetafieldsService';

export type UpdateStatusType = Record<string, StatusType>;

type MetafieldsType = {
  data: MetafieldsMapV2Dto | null;
  status: StatusType;
};
export interface InterfaceOrderMetafieldsSchema {
  list: MetafieldsType;
  updateStatus: UpdateStatusType;
  bulkMetafieldsStatus: StatusType;
}

const initialState: InterfaceOrderMetafieldsSchema = {
  list: {
    status: 'idle',
    data: null,
  },
  updateStatus: {},
  bulkMetafieldsStatus: 'idle',
};

export const fetchMetafieldsData = createAsyncThunk<MetafieldsMapV2Dto, string>(
  'orders/getMetafields',
  async (id) => {
    const data = await ordersService.getMetafields(id);
    return data;
  },
);

export const updateMetafield = createAsyncThunk<
  void,
  { orderId: string; metafieldId: string; value: string | null }
>('orders/updateMetafield', async ({ orderId, metafieldId, value }) => {
  await ordersService.updateMetafield(orderId, metafieldId, value);
});

export const ordersBulkAssignmentMetafield = createAsyncThunk<
  void,
  MetafieldBulkAssignmentsInterface
>('orders/metafields/bulk-assignment', async ({ orders, metafields }) => {
  await ordersService.ordersBulkAssignmentMetafield({ orders, metafields });
});

const orderMetafieldsSlice = createSlice({
  name: 'orderMetafields',
  initialState,
  reducers: {
    clearUpdateStatus(state, action) {
      state.updateStatus[action.payload.metafieldId] = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetafieldsData.pending, (state) => {
        state.list.status = 'loading';
      })
      .addCase(fetchMetafieldsData.fulfilled, (state, action) => {
        state.list.status = 'success';
        const statusItemMetafield = {};
        Object.keys(action.payload.internals).forEach((key) => {
          statusItemMetafield[key] = 'idle';
        });
        Object.keys(action.payload.fromApi).forEach((key) => {
          statusItemMetafield[key] = 'idle';
        });
        state.list.data = action.payload;
        state.updateStatus = statusItemMetafield;
      })
      .addCase(fetchMetafieldsData.rejected, (state) => {
        state.list.status = 'error';
      });

    builder
      .addCase(updateMetafield.pending, (state, action) => {
        state.updateStatus[action.meta.arg.metafieldId] = 'loading';
      })
      .addCase(updateMetafield.fulfilled, (state, action) => {
        const { orderId, metafieldId, value } = action.meta.arg;
        state.updateStatus[metafieldId] = 'success';
        if (state.list.data) {
          const internal = state.list.data.internals[metafieldId];
          const fromApi = state.list.data.fromApi[metafieldId];
          if (internal) {
            internal.value = value;
          } else {
            fromApi.value = value;
          }
        }
        trackingOrderMetafieldSave(metafieldId, orderId, value);
      })
      .addCase(updateMetafield.rejected, (state, action) => {
        const { orderId, metafieldId, value } = action.meta.arg;
        state.updateStatus[metafieldId] = 'error';
        trackingOrderMetafieldSaveError(metafieldId, orderId, value);
      });

    builder
      .addCase(ordersBulkAssignmentMetafield.pending, (state) => {
        state.bulkMetafieldsStatus = 'loading';
      })
      .addCase(ordersBulkAssignmentMetafield.fulfilled, (state) => {
        state.bulkMetafieldsStatus = 'success';
      })
      .addCase(ordersBulkAssignmentMetafield.rejected, (state) => {
        state.bulkMetafieldsStatus = 'error';
      });
  },
});

export const { reducer } = orderMetafieldsSlice;
export const { clearUpdateStatus } = orderMetafieldsSlice.actions;
