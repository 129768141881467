import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { generateProductAltText } from '../../../productsSlice';

function useGenerateProductAltText() {
  const dispatch = useAppDispatch();

  const generateAltText = useCallback(
    async (requestDto) => {
      const result = await dispatch(generateProductAltText(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { generateAltText };
}

export default useGenerateProductAltText;
