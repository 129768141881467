import { useEffect } from 'react';
import { EditIcon, PrinterIcon } from '@nimbus-ds/icons';
import { OcaCreateLabelRequestDto } from '@tiendanube/common';
import { ActionProp, ActionsDesktop } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import { useGoToPrintPage } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/hooks';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import useCreateOcaLabels from '../../hooks/useCreateOcaLabels';
import { validateLabels } from '../../utils/utils';

interface PrePrintOcaActionsDesktopProps {
  selectedIds: string[];
  selectedOrders: string[];
  labels: OcaCreateLabelRequestDto[];
  isError: boolean;
  isLoading: boolean;
  doNotHasAvailableOrders: boolean;
  openModal: () => void;
}

function PrePrintOcaActionsDesktop({
  selectedIds,
  selectedOrders,
  labels,
  isError,
  isLoading,
  doNotHasAvailableOrders,
  openModal,
}: Readonly<PrePrintOcaActionsDesktopProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint');
  const { goToPrintPage } = useGoToPrintPage();
  const { createOcaLabels } = useCreateOcaLabels();
  const { addToast } = useToast();

  const [handleCreateOcaLabels, isCreating] = useAsyncFunc(
    async () => {
      addToast({
        label: t('toast.loading'),
        appearance: 'primary',
      });
      await createOcaLabels(labels);
    },
    () => {
      addToast({
        label: t('toast.success'),
        appearance: 'success',
      });
      goToPrintPage({
        ids: selectedIds,
        type: DocumentType.LABEL,
        hasOca: true,
      });
    },
    () => {
      addToast({
        label: t('toast.error'),
        appearance: 'danger',
      });
    },
  );

  const actions: ActionProp[] = [
    {
      children: labels.length > 1 ? t('action') : t('actionUnique'),
      onClick: handleCreateOcaLabels,
      icon: PrinterIcon,
      appearance:
        !validateLabels(labels) || isLoading || isCreating
          ? 'default'
          : 'primary',
      disabled:
        labels.length > 0 &&
        (!validateLabels(labels) || isLoading || isCreating),
    },
  ];

  if (selectedIds.length > 1) {
    actions.push({
      children: `${t('editAll')} (${selectedOrders.length})`,
      onClick: () => openModal(),
      icon: EditIcon,
      appearance:
        selectedOrders.length <= 1 || isLoading ? 'default' : 'secondary',
      disabled: selectedOrders.length <= 1 || isLoading,
    });
  }

  useEffect(() => {
    if (doNotHasAvailableOrders) {
      goToPrintPage({
        ids: selectedIds,
        type: DocumentType.LABEL,
        hasOca: true,
      });
    }
  }, [goToPrintPage, doNotHasAvailableOrders, selectedIds]);

  return <ActionsDesktop actions={!isError ? actions.reverse() : []} />;
}

export default PrePrintOcaActionsDesktop;
