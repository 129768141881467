import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContactInfoRequestDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  contactInfoData,
  contactInfoStatus,
  contactInfoUpdateStatus,
  getContactInfoAction,
  updateContactInfoAction,
} from 'domains/Online/Themes/themesSlice';

function useContactInfo() {
  const dispatch = useAppDispatch();
  const status = useSelector(contactInfoStatus);
  const updateStatus = useSelector(contactInfoUpdateStatus);
  const contactInfo = useSelector(contactInfoData);

  const fetchContactInfo = useCallback(
    () => dispatch(getContactInfoAction()),
    [dispatch],
  );

  const updateContactInfo = (contactInfo: ContactInfoRequestDto) =>
    dispatch(updateContactInfoAction(contactInfo));

  useEffect(() => {
    fetchContactInfo();
  }, [fetchContactInfo]);

  return {
    status,
    updateStatus,
    contactInfo,
    fetchContactInfo,
    updateContactInfo,
  };
}

export default useContactInfo;
