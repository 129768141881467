import { useCallback } from 'react';
import { Box, Card, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';

interface AppItem {
  image: string;
  name: string;
  description: string;
  url: string;
}

interface AppItemCardProps {
  app: AppItem;
  onClick?: () => void;
}

function AppItemCard({ app, onClick }: AppItemCardProps) {
  const { t } = useTranslation('common');
  const { image, name, description, url } = app;

  const handleClick = useCallback(() => {
    window.open(url, '_blank');
    onClick && onClick();
  }, [url, onClick]);

  return (
    <Card>
      <Card.Body>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="4"
          height="80px"
        >
          <img src={image} width="50px" height="50px" />
          <Box flex="1 1 auto" height="100%">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              height="62px"
            >
              <Text fontSize="highlight" fontWeight="bold" lineClamp={1}>
                {name}
              </Text>
              <Text lineClamp={2}>{description}</Text>
            </Box>
            <Link
              as="a"
              appearance="primary"
              textDecoration="none"
              onClick={handleClick}
            >
              {t('viewDetails')}
              <Icon color="currentColor" source={<ExternalLinkIcon />} />
            </Link>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default AppItemCard;
