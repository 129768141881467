import { ReactNode } from 'react';
import { Card, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface ConditionsCardProps {
  children: ReactNode;
}

export function ConditionsCard({ children }: Readonly<ConditionsCardProps>) {
  const t = useTranslationMarketing('freeShipping.settingsPage.conditions');
  return (
    <Card>
      <Card.Header>
        <Stack spacing="tight">
          <Title as="h4">{t('title')}</Title>
          <Text>{t('optional')}</Text>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="loose">
          <Text>{t('info')}</Text>
          {children}
        </Stack>
      </Card.Body>
    </Card>
  );
}
