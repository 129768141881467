import { Box, Card, Input, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CouponStatus } from '@tiendanube/common';
import { InterfaceNameValue } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import { Status } from 'domains/Marketing/Coupons/pages/CouponListPage/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';

interface CouponCodeProps {
  onChange: ({ name, value }: InterfaceNameValue) => void;
  value: string;
  error?: string;
  disabled?: boolean;
  couponStatus?: CouponStatus;
}

export function CouponCode({
  onChange,
  value,
  error,
  disabled = false,
  couponStatus,
}: Readonly<CouponCodeProps>) {
  const t = useTranslationMarketing();
  const { ref } = useAutoFocus();

  const appearance = error ? 'danger' : undefined;

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{t('coupons.couponForm.code.title')}</Title>
          {couponStatus && <Status status={couponStatus} />}
        </Box>
      </Card.Header>
      <Card.Body>
        <Stack innerRef={ref} column align="stretch">
          <Stack.Item>
            <FormField
              helpText={
                error ? t(error) : t('coupons.couponForm.code.helpText')
              }
              appearance={appearance}
              showHelpText
            >
              <Input
                name="couponCode"
                placeholder={t('coupons.couponForm.code.placeholder')}
                value={value}
                onChange={({ target: { name, value } }) =>
                  onChange({ name, value })
                }
                appearance={appearance}
                disabled={disabled}
              />
            </FormField>
          </Stack.Item>
        </Stack>
      </Card.Body>
    </Card>
  );
}

CouponCode.Skeleton = Skeleton;
