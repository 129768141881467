import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Popover, Link, Icon } from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';
import { useStorage } from 'commons/hooks';
import { logEvent } from 'commons/utils/tracking';
import { NUVEM_ENVIO_CLICK } from 'config/trackingEvents';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';
import { useGetNuvemEnvioConfigs } from 'domains/Shipping/DeliveryMethods/hooks';
import useUpdateNuvemEnvioConfigs from 'domains/Shipping/DeliveryMethods/hooks/useUpdateNuvemEnvioConfigs';
import { LogoNuvemEnvio, NuvemEnvioPopoverBody } from './components';

interface NuvemEnvioPopoverProps {
  children: ReactNode;
}

const CLOSE_ORDERS_NUVEM_ENVIO_POPOVER_ACTION = 'Close orders banner';
const EXPIRATION_IN_MILLISECONDS = 10800000;

function useStorageWithExpiry(key: string, ttl: number) {
  const [dataWithExpiry, setDataWithExpiry, loaded] = useStorage<
    any | undefined
  >(key, null);

  const now = new Date();
  const item = dataWithExpiry !== null ? JSON.parse(dataWithExpiry) : null;

  const getEnableEngagementBannerValue = (data: any, time: Date) => {
    if (data === null) {
      return null;
    }
    return time.getTime() < data.expiry ? data.value : null;
  };

  const enableEngagementBanner = getEnableEngagementBannerValue(item, now);

  const setDataInStorage = useCallback(
    async (enableEngagementBannerValue: boolean, time: Date) => {
      const item = {
        value: enableEngagementBannerValue,
        expiry: time.getTime() + ttl,
      };
      setDataWithExpiry(JSON.stringify(item));
    },
    [setDataWithExpiry, ttl],
  );

  return {
    enableEngagementBanner,
    setDataInStorage,
    loaded,
  };
}

function NuvemEnvioPopover({
  children,
}: NuvemEnvioPopoverProps): JSX.Element | null {
  const { id: storeId } = useStoreInfo();
  const usePrimaryPopoverBody = !!(Number(storeId) % 2);

  const { nuvemApps } = useNuvemApps();
  const hasNuvemEnvioApp = !!nuvemApps.nuvemenvio;

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const { updateNuvemEnvioConfigs } = useUpdateNuvemEnvioConfigs();
  const { nuvemEnvioConfigs, fetchNuvemEnvioConfigs, status } =
    useGetNuvemEnvioConfigs();

  const { enableEngagementBanner, setDataInStorage, loaded } =
    useStorageWithExpiry(
      `showNuvemEnvioPopover-${storeId}`,
      EXPIRATION_IN_MILLISECONDS,
    );

  const handleClosePopoverClick = async (): Promise<void> => {
    const now = new Date();
    setIsVisible(false);
    await setDataInStorage(false, now);
    logEvent(NUVEM_ENVIO_CLICK, {
      storeId,
      action: CLOSE_ORDERS_NUVEM_ENVIO_POPOVER_ACTION,
    });
    await updateNuvemEnvioConfigs({ enableEngagementBanner: false });
    fetchNuvemEnvioConfigs();
  };

  useEffect(() => {
    const fn = async () => {
      const now = new Date();
      if (hasNuvemEnvioApp && enableEngagementBanner === null && loaded) {
        if (status.isIdle) {
          await fetchNuvemEnvioConfigs();
        }
        if (status.isSuccess) {
          await setDataInStorage(nuvemEnvioConfigs.enableEngagementBanner, now);
        }
      }
    };
    fn();
  }, [
    fetchNuvemEnvioConfigs,
    setDataInStorage,
    hasNuvemEnvioApp,
    enableEngagementBanner,
    nuvemEnvioConfigs.enableEngagementBanner,
    status,
    loaded,
  ]);

  return (
    <Popover
      backgroundColor="primary-interactiveHover"
      width="20rem"
      visible={
        isVisible && hasNuvemEnvioApp && enableEngagementBanner !== null
          ? enableEngagementBanner
          : false
      }
      content={
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="2"
            alignItems="flex-start"
          >
            <Icon
              color="neutral-background"
              source={
                <LogoNuvemEnvio
                  marginTop="5px"
                  marginBottom="5px"
                  width="160"
                />
              }
            />
            <Link onClick={handleClosePopoverClick}>
              <Icon color="neutral-background" source={<CloseIcon />} />
            </Link>
          </Box>
          <NuvemEnvioPopoverBody
            storeId={storeId}
            usePrimaryPopoverBody={usePrimaryPopoverBody}
            onLinkClick={() => {
              const now = new Date();
              setDataInStorage(false, now);
            }}
          />
        </Box>
      }
    >
      {children}
    </Popover>
  );
}

export default NuvemEnvioPopover;
