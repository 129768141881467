import { Stack, Text } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import Skeleton from './Skeleton';
import ScopesItem from '../ScopesItem';

interface ScopesCardProps {
  storePermissions: string[];
  clientPermissions: string[];
}

function ScopesCard({ storePermissions, clientPermissions }: ScopesCardProps) {
  const { t } = useTranslationPartnerApps();
  return (
    <CardWithTitle title={t('scopes.title')}>
      <Stack column align="stretch">
        <Text>{t('scopes.description')}</Text>
        <ScopesItem
          scopes={storePermissions}
          title={t('scopes.firstSubtitle')}
        />
        <ScopesItem
          scopes={clientPermissions}
          title={t('scopes.secondSubtitle')}
        />
      </Stack>
    </CardWithTitle>
  );
}

ScopesCard.Skeleton = Skeleton;

export default ScopesCard;
