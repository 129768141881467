import { logEvent } from 'commons/utils/tracking';
import {
  ACCESS_EDIT_COUPON_PAGE,
  COUPONS_FILTERED,
  COUPONS_ORDERED,
  DELETED_COUPONS_FILTERED,
  DELETED_COUPON_DETAIL,
  EXIT_EDIT_COUPON_PAGE,
} from 'config/trackingEvents';

export const trackingCouponsFiltered = () => {
  logEvent(COUPONS_FILTERED, {});
};

export const trackingCouponsOrdered = () => {
  logEvent(COUPONS_ORDERED, {});
};

export const trackingDeletedCouponsFiltered = () => {
  logEvent(DELETED_COUPONS_FILTERED, {});
};

export const trackingDeletedCouponDetail = () => {
  logEvent(DELETED_COUPON_DETAIL, {});
};

export const trackingAccessEditCouponPage = (couponId) => {
  logEvent(ACCESS_EDIT_COUPON_PAGE, { couponId });
};

export const trackingExitEditCouponPage = (couponId) => {
  logEvent(EXIT_EDIT_COUPON_PAGE, { couponId });
};
