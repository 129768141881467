import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { ExternalLink } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { SplitSection } from '../SplitSection';

interface OlarkProps {
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  olark?: string;
}

export function Olark({ onChangeTextArea, olark }: Readonly<OlarkProps>) {
  const t = useTranslationConfigurations('externalCodes.otherServices.olark');

  if (!olark) return null;

  return (
    <SplitSection
      title={t('title')}
      description={
        <Trans
          t={t}
          i18nKey="description"
          components={{
            customLink: (
              <ExternalLink
                textDecoration="none"
                appearance="primary"
                href={t('olarkHref')}
              >
                {' '}
              </ExternalLink>
            ),
          }}
        />
      }
    >
      <FormField.Textarea
        onChange={onChangeTextArea}
        value={olark}
        name="olark"
        id="olark"
        placeholder={t('placeholder')}
        label={t('label')}
        lines={6}
      />
      <ExternalLink textDecoration="none" href={t('href')} appearance="primary">
        {t('link')}
      </ExternalLink>
    </SplitSection>
  );
}
