/**
 * @deprecated This enum is deprecated. Please use CurrencyType instead.
 */
enum Currency {
  ARS = "ARS",
  BRL = "BRL",
  MXN = "MXN",
  USD = "USD",
}

export default Currency;
