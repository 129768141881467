import { Table, Text, Box, Tag, IconButton } from '@nimbus-ds/components';
import { Repeater, Stack, showMoreContentBoxStyle } from 'commons/components';

const tagSkeleton = () => <Tag.Skeleton />;

const textSkeleton = () => <Text.Skeleton width="120px" />;

function TagsListSkeleton() {
  return (
    <Box {...showMoreContentBoxStyle} maxHeight="80px" overflow="hidden">
      <Repeater times={2} item={tagSkeleton} />
    </Box>
  );
}

function FreeShippingListRowSkeleton() {
  return (
    <Table.Row>
      <Table.Cell>
        <Stack column align="flex-start">
          <Repeater times={2} item={textSkeleton} />
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Text.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <TagsListSkeleton />
      </Table.Cell>
      <Table.Cell>
        <TagsListSkeleton />
      </Table.Cell>
      <Table.Cell>
        <Tag.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Stack spacing="tight">
          <IconButton.Skeleton height="32px" width="32px" />
          <IconButton.Skeleton height="32px" width="32px" />
        </Stack>
      </Table.Cell>
    </Table.Row>
  );
}

export default FreeShippingListRowSkeleton;
