import { useCallback } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { combineStatus } from 'commons/utils/combineStatus';
import { Format, dateFormat } from 'commons/utils/date';
import {
  useGetRecurrentPaymentConcepts,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import SelectChargesToPaySkeleton from '../../Skeleton';

function NoCharges() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );

  const {
    status: subscriptionsStatus,
    subscriptions,
    refreshSubscriptions,
  } = useGetSubscriptions();
  const {
    status: conceptsStatus,
    refreshRecurrentPaymentConcepts,
    recurrentPaymentConcepts,
  } = useGetRecurrentPaymentConcepts();

  const { isLoading, isError } = combineStatus(
    subscriptionsStatus,
    conceptsStatus,
  );

  const retryLoad = useCallback(() => {
    refreshSubscriptions();
    refreshRecurrentPaymentConcepts();
  }, [refreshSubscriptions, refreshRecurrentPaymentConcepts]);

  if (isLoading) return <SelectChargesToPaySkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: retryLoad }}
      />
    );

  const subscriptionsWithoutRp = subscriptions?.filter(
    (sub) =>
      !recurrentPaymentConcepts?.some(
        ({ concept, externalReference }) =>
          sub.conceptCode === concept.code &&
          (!externalReference || sub.externalReference === externalReference),
      ),
  );
  const nextExecutionDate =
    subscriptionsWithoutRp?.length &&
    subscriptionsWithoutRp.reduce(
      (min, { nextExecutionDate }) =>
        nextExecutionDate < min ? nextExecutionDate : min,
      subscriptionsWithoutRp[0].nextExecutionDate,
    );

  return (
    <Box display="flex" flexDirection="column" padding="4" gap="2">
      <Text fontSize="highlight">{t('noCharges.title')}</Text>
      {!!nextExecutionDate && (
        <Text>
          {t('noCharges.dateDescription', {
            date: dateFormat(nextExecutionDate, Format.DD_MM_YYYY),
          })}
        </Text>
      )}
    </Box>
  );
}

export default NoCharges;
