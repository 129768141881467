import { Button } from '@nimbus-ds/components';
import { Card, Stack, Text } from '@tiendanube/components';
import { SendEmailButton } from 'commons/components';
import { useModal } from 'commons/hooks';
import { SendEmailModal } from 'domains/Customers/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import SendEmailAbandonedCartCardSkeleton from './SendEmailAbandonedCartCardSkeleton';

export interface EmailType {
  to: string;
  subject: string;
  body: string;
  url?: string;
  signature?: string;
}

interface SendEmailAbandonedCartCardProps {
  customerId?: string;
  email: EmailType;
}

function SendEmailAbandonedCartCard({
  customerId,
  email,
}: SendEmailAbandonedCartCardProps) {
  const t = useTranslationOrders();
  const [showSendEmailModal, openSendEmailModal, closeSendEmailModal] =
    useModal();

  return (
    <>
      <Card title={t('abandonedCarts.sendEmail.title')}>
        <Stack column align="flex-start">
          <Text>{t('abandonedCarts.sendEmail.text')}</Text>
          {!!customerId && (
            <Button onClick={openSendEmailModal}>
              {t('abandonedCarts.sendEmail.textButton')}
            </Button>
          )}
          {!customerId && (
            <SendEmailButton
              email={email.to}
              subject={email.subject}
              body={email.body}
              url={email.url}
              signature={email.signature}
              textButton={t('abandonedCarts.sendEmail.textButton')}
            />
          )}
        </Stack>
      </Card>
      {showSendEmailModal && (
        <SendEmailModal
          email={email.to}
          customerId={String(customerId)}
          closeSendEmailModal={closeSendEmailModal}
        />
      )}
    </>
  );
}

SendEmailAbandonedCartCard.Skeleton = SendEmailAbandonedCartCardSkeleton;

export default SendEmailAbandonedCartCard;
