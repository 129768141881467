export const DISPATCH_TYPES = {
  door: 'door',
  store: 'store',
};

export const SHIPPING_TYPE = {
  ship: 'ship',
  pickup: 'pickup',
};

export const DIMENSIONS_TYPES = ['weight', 'depth', 'height', 'width'];
