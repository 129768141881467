import { useMemo } from 'react';
import { Alert, Box, Text, Title } from '@nimbus-ds/components';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { InterfaceText } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import { formatCurrency } from 'commons/utils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface SummaryDifferenceProps {
  previousTotal: number;
  newTotal: number;
  currency: CurrencyType;
  isInsufficientStock?: boolean;
  fulfillmentWithoutProducts?: string;
  failedFulfillmentCotization?: string;
}

function SummaryMessage({
  previousTotal,
  newTotal,
  currency,
  fulfillmentWithoutProducts,
  failedFulfillmentCotization,
  isInsufficientStock,
}: Readonly<SummaryDifferenceProps>) {
  const t = useTranslationOrders();
  const {
    i18n: { language },
  } = useTranslation();

  const totalDifference = Math.abs(newTotal - previousTotal);

  const showAlertText = useMemo(
    () =>
      fulfillmentWithoutProducts !== undefined ||
      failedFulfillmentCotization !== undefined ||
      newTotal === previousTotal,
    [
      fulfillmentWithoutProducts,
      newTotal,
      previousTotal,
      failedFulfillmentCotization,
    ],
  );

  const showTitle = useMemo(
    () => fulfillmentWithoutProducts === undefined,
    [fulfillmentWithoutProducts],
  );

  const getSummaryBackgroundColor = () => {
    if (isInsufficientStock) return 'danger-surface';
    if (fulfillmentWithoutProducts !== undefined) return 'danger-surface';
    if (failedFulfillmentCotization !== undefined) return 'danger-surface';
    if (newTotal === previousTotal) return 'success-surface';
    return 'warning-surface';
  };

  const getTotalColor: () => InterfaceText['appearance'] = () => {
    if (isInsufficientStock) return 'default';
    if (newTotal === previousTotal) return 'success';
    return 'warning';
  };

  const getTextColor = () => {
    if (isInsufficientStock) return 'neutral-textLow';
    if (fulfillmentWithoutProducts !== undefined) return 'danger-textLow';
    if (failedFulfillmentCotization !== undefined) return 'danger-textLow';
    if (newTotal === previousTotal) return 'success-textLow';
    return 'warning-textLow';
  };

  const getAlertAppearance = () => {
    if (isInsufficientStock) return 'danger';
    if (fulfillmentWithoutProducts !== undefined) return 'danger';
    if (failedFulfillmentCotization !== undefined) return 'danger';
    if (newTotal === previousTotal) return 'success';
    return 'warning';
  };

  const alertTextKey = useMemo(() => {
    if (isInsufficientStock) return 'editOrders.summary.alert.missingStock';
    if (fulfillmentWithoutProducts === '')
      return 'editOrders.summary.alert.emptyFulfillment';
    if (fulfillmentWithoutProducts)
      return 'editOrders.summary.alert.emptyFulfillmentLocation';
    if (failedFulfillmentCotization === '')
      return 'editOrders.summary.alert.failedOrderCotization';
    if (failedFulfillmentCotization !== undefined)
      return 'editOrders.summary.alert.failedFulfillmentCotization';
    if (newTotal === previousTotal)
      return 'editOrders.summary.alert.newEqualTotal';
    return '';
  }, [
    isInsufficientStock,
    fulfillmentWithoutProducts,
    newTotal,
    previousTotal,
    failedFulfillmentCotization,
  ]);

  const titleKey = useMemo(() => {
    if (failedFulfillmentCotization !== undefined)
      return 'editOrders.fulfillmentsSummary.alert.failedCotizationTitle';
    if (newTotal > previousTotal)
      return 'editOrders.fulfillmentsSummary.alert.higherTitle';
    if (previousTotal > newTotal)
      return 'editOrders.fulfillmentsSummary.alert.lowerTitle';
    return 'editOrders.fulfillmentsSummary.alert.equalTitle';
  }, [previousTotal, newTotal, failedFulfillmentCotization]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="2"
      padding="2"
      backgroundColor={getSummaryBackgroundColor()}
    >
      {showTitle && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={
            failedFulfillmentCotization === undefined
              ? 'space-between'
              : 'center'
          }
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Title
              fontWeight="bold"
              fontSize="4"
              lineHeight="5"
              color={getTextColor()}
            >
              {t(titleKey)}
            </Title>
          </Box>
          {failedFulfillmentCotization === undefined && (
            <Box flexDirection="row" display="flex" paddingLeft="2">
              <CurrencyPrice
                price={totalDifference}
                currency={currency}
                appearance={getTotalColor()}
                size="highlight"
                bold
              />
            </Box>
          )}
        </Box>
      )}
      {showAlertText && (
        <Box paddingTop={showTitle ? '1' : undefined}>
          <Alert appearance={getAlertAppearance()}>
            <Text color={getTextColor()}>
              <Trans
                t={t}
                i18nKey={alertTextKey}
                values={{
                  amount: formatCurrency(totalDifference, currency, language),
                  location:
                    fulfillmentWithoutProducts || failedFulfillmentCotization,
                }}
                components={{
                  strong: <strong />,
                }}
              />
            </Text>
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export default SummaryMessage;
