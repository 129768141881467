import { ReactNode } from 'react';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';
import { DndProvider as ReactDndProvider } from 'react-dnd';

interface DndProviderProps {
  children: ReactNode;
}

function DndProvider({ children }: DndProviderProps) {
  return (
    <ReactDndProvider
      backend={MultiBackend}
      options={getBackendOptions()}
      context={window}
    >
      {children}
    </ReactDndProvider>
  );
}

export default DndProvider;
