import { DataList } from '@tiendanube/components';
import ScrollTop from 'commons/components/ScrollTop';
import ContentProductItemListSkeleton from '../ContentProductItemList/ContentProductItemListSkeleton';

function ProductResultsListSkeleton(): JSX.Element {
  return (
    <DataList skeleton>
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ContentProductItemListSkeleton />
      <ScrollTop />
    </DataList>
  );
}

export default ProductResultsListSkeleton;
