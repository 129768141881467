import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SalesChannelsAppResponseDto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import { getSalesChannelsApps } from '../salesChannelsAppsService';

interface SalesChannelsAppsInterface {
  status: StatusType;
  data: SalesChannelsAppResponseDto[];
}

const initialState: SalesChannelsAppsInterface = {
  status: 'idle',
  data: [],
};

export const getSalesChannelsAppsAction = createAsyncThunk(
  'salesChannelsApps/getSalesChannelsAppsAction',
  getSalesChannelsApps,
);

const salesChannelsApps = createSlice({
  name: 'sales-channels-apps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesChannelsAppsAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSalesChannelsAppsAction.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      })
      .addCase(getSalesChannelsAppsAction.rejected, (state) => {
        state.status = 'error';
        state.data = initialState.data;
      });
  },
});

export const { reducer } = salesChannelsApps;
