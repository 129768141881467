import {
  Button,
  Card,
  Checkbox,
  Input,
  Label,
  Title,
} from '@nimbus-ds/components';
import { Repeater, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface UserFormPageSkeleton {
  readonly showRolesCard: boolean;
}

function UserFormPageSkeleton({ showRolesCard }: UserFormPageSkeleton) {
  const t = useTranslationConfigurations('userFormPage');

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">{t('dataCard.title')}</Title>
        </Card.Header>
        <Card.Body>
          <Stack column align="stretch">
            <Stack column spacing="tight" align="flex-start">
              <Label>{t('dataCard.firstName')}</Label>
              <Input.Skeleton />
            </Stack>
            <Stack column spacing="tight" align="flex-start">
              <Label>{t('dataCard.lastName')}</Label>
              <Input.Skeleton />
            </Stack>
            <Stack column spacing="tight" align="flex-start">
              <Label>{t('dataCard.email')}</Label>
              <Input.Skeleton />
            </Stack>
          </Stack>
        </Card.Body>
      </Card>
      {showRolesCard && (
        <Card>
          <Card.Header>
            <Title as="h3">{t('rolesCard.title')}</Title>
          </Card.Header>
          <Card.Body>
            <Stack column align="baseline">
              <Checkbox.Skeleton width="200px" />
              <Repeater
                times={10}
                item={(index) => (
                  <Checkbox.Skeleton key={index} width="200px" />
                )}
              />
            </Stack>
          </Card.Body>
        </Card>
      )}
      <Card>
        <Card.Header>
          <Title as="h3">{t('passwordCard.title')}</Title>
        </Card.Header>
        <Card.Body>
          <Stack column align="stretch">
            <Stack column spacing="tight" align="flex-start">
              <Label>{t('passwordCard.password')}</Label>
              <Input.Skeleton />
            </Stack>
            <Stack column spacing="tight" align="flex-start">
              <Label>{t('passwordCard.confirmPassword')}</Label>
              <Input.Skeleton />
            </Stack>
          </Stack>
        </Card.Body>
      </Card>
      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Button.Skeleton />
        </Stack.Item>
      </Stack>
    </>
  );
}

export default UserFormPageSkeleton;
