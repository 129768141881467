import { memo } from 'react';
import useProductItemList from 'domains/Catalog/Products/pages/hooks/useProductItemList';
import ContentProductItemRow from '../ContentProductItemRow';

interface ContentProductItemListProps {
  id: string;
}

function ContentProductItemList({ id }: ContentProductItemListProps) {
  const { product, language } = useProductItemList(id);
  if (!product) return null;

  return <ContentProductItemRow product={product} language={language} />;
}

export default memo(ContentProductItemList);
