import { TrashIcon } from '@nimbus-ds/icons';
import HelpLink from 'App/HelpLink';
import {
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
} from 'commons/components';
import { useTranslationLanguage } from 'commons/hooks';
import {
  AppSessionsDisclaimer,
  DeleteUserSessionModal,
  InactiveUserSessionsAlert,
  UserSessionsListDesktop,
  UserSessionsListMobile,
} from './components';
import { useDeleteUserSessionAction, useGetUserSessions } from './hooks';
import { HELP_LINKS } from './settings';
import { useTranslationAccount } from '../../hooks';

function SessionManagementPage() {
  const t = useTranslationAccount('sessionManagementPage');
  const language = useTranslationLanguage();
  const userSessions = useGetUserSessions();
  const isDeleteAllActionAllowed = userSessions.length > 1;
  const { showDeleteModal, handleDelete, handleClick, handleClose } =
    useDeleteUserSessionAction();
  const deleteAllAction = {
    appearance: 'default' as const,
    icon: TrashIcon,
    onClick: () => handleClick(),
    children: t('actions.deleteAll.label'),
    disabled: !isDeleteAllActionAllowed,
  };
  const headerTop = <HeaderTop mainAction={deleteAllAction} />;
  const actionsDesktop = <ActionsDesktop actions={[deleteAllAction]} />;

  return (
    <IonPageStratus
      width="large"
      headerTop={headerTop}
      headerContent={
        <HeaderContent
          title={t('title')}
          subtitle={t('subtitle')}
          actions={actionsDesktop}
        />
      }
    >
      <DeleteUserSessionModal
        show={showDeleteModal}
        onClose={handleClose}
        onDelete={handleDelete}
        isDeleteAllAction
      />

      <AppSessionsDisclaimer />

      <InactiveUserSessionsAlert />

      <Responsive
        mobile={<UserSessionsListMobile />}
        desktop={<UserSessionsListDesktop />}
      />

      <HelpLink
        title={t('helpLinkTitle')}
        linkURL={HELP_LINKS[language]}
        icon="both"
        currentViewTracking=""
        previousValue=""
      />
    </IonPageStratus>
  );
}

export default SessionManagementPage;
