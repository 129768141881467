import { useEffect } from 'react';
import { useGetMetafields } from 'domains/Orders/Orders/hooks';

function useFetchOrdersMetafields(id: string) {
  const { fetchMetafields, isSuccess, isLoading, isError } = useGetMetafields();

  useEffect(() => {
    fetchMetafields(id);
  }, [fetchMetafields, id]);

  return {
    isSuccess,
    isLoading,
    isError,
  };
}

export default useFetchOrdersMetafields;
