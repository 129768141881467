import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthenticationFactorDashboardStatusResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import {
  fetchAuthenticationFactorsDashboardStatus as fetchAuthenticationFactorsDashboardStatusAction,
  authenticationFactorsDashboardData,
  authenticationFactorsDashboardStatus,
} from '../../accountSlice';

interface UseAuthenticationFactorsResult {
  fetchAuthenticationFactorsDashboardStatus: () => void;
  status: StatusType;
  authenticationFactorsDashboardStatus?: AuthenticationFactorDashboardStatusResponseDto;
}

function useAuthenticationFactorsDashboardStatus(): UseAuthenticationFactorsResult {
  const { status } = useSelector(authenticationFactorsDashboardStatus);
  const { data: authenticationFactorsDashboard } = useSelector(
    authenticationFactorsDashboardData,
  );
  const dispatch = useAppDispatch();
  const fetchAuthenticationFactorsDashboardStatus = useCallback(() => {
    dispatch(fetchAuthenticationFactorsDashboardStatusAction());
  }, [dispatch]);

  return {
    fetchAuthenticationFactorsDashboardStatus,
    status,
    authenticationFactorsDashboardStatus: authenticationFactorsDashboard,
  };
}

export default useAuthenticationFactorsDashboardStatus;
