import * as Yup from 'yup';
import { emailRegex } from 'commons/utils';

export const validationSchema = (minInstallmentsValueAllowed?) =>
  Yup.object().shape({
    email: Yup.string()
      .required('account.email.required')
      .matches(emailRegex, 'account.email.invalid'),
    minInstallmentsValue: Yup.string()
      .nullable()
      .test(
        'minInstallmentsValueAllowed',
        'showInfoFinantialStore.minInstallmentsValueAllowed',
        (value) => {
          if (!minInstallmentsValueAllowed) return true;
          else
            return value ? Number(value) >= minInstallmentsValueAllowed : true;
        },
      ),
  });
