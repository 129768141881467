import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'App/store';
import { generateProductSeo } from '../../../productsSlice';

function useGenerateProductSeo() {
  const dispatch = useAppDispatch();

  const generateSeo = useCallback(
    async (requestDto) => {
      const result = await dispatch(generateProductSeo(requestDto));
      return unwrapResult(result);
    },
    [dispatch],
  );

  return { generateSeo };
}

export default useGenerateProductSeo;
