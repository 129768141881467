import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ChargeDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetPlan } from 'domains/Auth/hooks';
import {
  selectPaymentMethod as selectPaymentMethodAction,
  getPaymentMethodSelected,
} from 'domains/Billing/Checkout/CheckoutSlice';
import useTranslationBilling from '../../useTranslationBilling';
import { useGetPaymentMethodsQuery } from '../CheckoutApi';
import { PaymentMethodSelection } from '../CheckoutSlice/types';

const usePaymentMethods = () => {
  const dispatch = useAppDispatch();
  const { status, data: paymentMethods, refetch } = useGetPaymentMethodsQuery();
  const t = useTranslationBilling('checkout');
  const { commercialName } = useGetPlan();

  const paymentMethodSelected = useSelector(getPaymentMethodSelected);

  const selectPaymentMethod = useCallback(
    (selection: PaymentMethodSelection) => {
      dispatch(selectPaymentMethodAction(selection));
    },
    [dispatch],
  );

  const firstRecurrencyOptionAsCharge: ChargeDto | undefined = useMemo(() => {
    const option = paymentMethods?.[0].recurrencyOptions[0];
    if (!option) {
      return undefined;
    }
    return {
      ...option,
      id: 'unique',
      conceptCode: 'plan-cost',
      concept: {
        id: 'unique',
        code: 'plan-cost',
        recurrentPaymentCompatible: undefined,
      },
      status: 'PENDING',
      description: `${t('planDescription')} ${commercialName}`,
      fromDate: new Date().toISOString(),
      toDate: new Date().toISOString(),
    };
  }, [t, paymentMethods, commercialName]);

  return {
    refreshPaymentMethods: refetch,
    paymentMethods,
    status,
    ...convertStatusTypeToObject(status),
    paymentMethodSelected,
    selectPaymentMethod,
    firstRecurrencyOptionAsCharge,
  };
};

export default usePaymentMethods;
