import { useCallback } from 'react';
import { Title } from '@nimbus-ds/components';
import { ConceptCode } from '@tiendanube/common';
import { BILLING_NEW_PAYMENT_HISTORY } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useGetIsFreePlan } from 'domains/Auth/hooks';
import { PaymentStatusAlert } from 'domains/Billing/Checkout/components';
import { CancelRecurrentPaymentButton } from 'domains/Billing/Checkout/pages/components';
import {
  trackingBillingRecurringCancel,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  SubscriptionDetail,
  ProportionalChargeAlert,
  TrialStatusAlert,
  PaymentHistory,
} from './components';

interface ConceptDetailContentProps {
  concept: ConceptCode;
  externalReference?: string;
  chargeId?: string;
}

function ConceptDetailContent({
  concept,
  externalReference,
  chargeId,
}: ConceptDetailContentProps) {
  const t = useTranslationBilling('checkout.conceptDetailContent');
  const isPlan = concept === 'plan-cost';
  const { isTrial } = useGetIsFreePlan();
  const logEvent = useCheckoutTracking();

  const trackRecurrentPaymentCancelation = useCallback(() => {
    trackingBillingRecurringCancel(logEvent);
  }, [logEvent]);
  const [paymentHistoryEnabled] = useHasTags(BILLING_NEW_PAYMENT_HISTORY);

  return (
    <>
      <Title>{t(`${concept}.title`)}</Title>
      {!!chargeId && <ProportionalChargeAlert chargeId={chargeId} />}
      {isPlan && isTrial ? (
        <TrialStatusAlert />
      ) : (
        <PaymentStatusAlert
          concept={concept}
          externalReference={externalReference}
          chargeId={chargeId}
        />
      )}
      <SubscriptionDetail
        concept={concept}
        externalReference={externalReference}
      />
      <CancelRecurrentPaymentButton
        conceptCode={concept}
        externalReference={externalReference}
        trackEvent={trackRecurrentPaymentCancelation}
      />
      {paymentHistoryEnabled && (
        <PaymentHistory
          concept={concept}
          externalReference={externalReference}
        />
      )}
    </>
  );
}

export default ConceptDetailContent;
