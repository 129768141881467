import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  PayOrderDto,
  TransactionFeeDetailListResponseDto,
  TransactionFeeDetailResponseDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { getTransactionFeesPagination } from './trasactionFessSelectors';
import { TransactionFeesListParamsInterface } from './types';
import { initialState } from './utils';
import transactionFeesServices from '../TransactionFeesServices/transactionFeesServices';

type ThunkStateType = { state: RootStateType };

export const fetchTransactionFeeToPay = createAsyncThunk(
  'billing/checkout/transactionFeeToPay',
  transactionFeesServices.fetchTransactionFeeToPay,
);

export const fetchTransactionFeePaid = createAsyncThunk(
  'billing/checkout/transactionFeePaid',
  transactionFeesServices.fetchTransactionFeePaid,
);

export const fetchPaymentsHistory = createAsyncThunk(
  'billing/checkout/paymentsHistory',
  transactionFeesServices.fetchPaymentsHistory,
);

export const fetchTransactionFees = createAsyncThunk<
  TransactionFeeDetailListResponseDto,
  TransactionFeesListParamsInterface,
  ThunkStateType
>('billing/transaction-fees/list', async (params) => {
  const response = await transactionFeesServices.fetchTransactionFees(params);
  return response;
});

export const getOrCreatePayOrder = createAsyncThunk<
  PayOrderDto,
  void,
  ThunkStateType
>('billing/transaction-fees/get-or-create-pay-order', async () => {
  const response = await transactionFeesServices.getOrCreatePayOrder();
  return response;
});

export const getMoreTransactionsList = createAsyncThunk<
  TransactionFeeDetailListResponseDto,
  { split: boolean; paymentId?: string },
  ThunkStateType
>(
  'billing/transaction-fees/getMoreTransactions',
  async ({ split, paymentId }, thunkApi) => {
    const state = thunkApi.getState();
    const pagination = getTransactionFeesPagination(state);

    if (!pagination.nextPage) {
      throw new Error('No valid fetch');
    }

    const response = await transactionFeesServices.fetchTransactionFees({
      page: pagination.nextPage,
      split,
      paymentId,
    });
    return response;
  },
);

const transactionFees = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    cleanFetchTransactionFeeToPay(state) {
      state.transactionFeeToPay.status =
        initialState.transactionFeeToPay.status;
    },
    cleanFetchTransactionFeePaid(state) {
      state.transactionFeePaid.status = initialState.transactionFeePaid.status;
    },
    cleanFetchPaymentsHistory(state) {
      state.paymentsHistory.status = initialState.paymentsHistory.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionFeeToPay.pending, (state) => {
        state.transactionFeeToPay.status = 'loading';
      })
      .addCase(fetchTransactionFeeToPay.fulfilled, (state, action) => {
        state.transactionFeeToPay.status = 'success';
        state.transactionFeeToPay.data = action.payload;
      })
      .addCase(fetchTransactionFeeToPay.rejected, (state) => {
        state.transactionFeeToPay.status = 'error';
      });

    builder
      .addCase(fetchTransactionFeePaid.pending, (state) => {
        state.transactionFeePaid.status = 'loading';
        state.transactionFeePaid.data = initialState.transactionFeePaid.data;
      })
      .addCase(fetchTransactionFeePaid.fulfilled, (state, action) => {
        state.transactionFeePaid.status = 'success';
        state.transactionFeePaid.data = action.payload;
      })
      .addCase(fetchTransactionFeePaid.rejected, (state) => {
        state.transactionFeePaid.status = 'error';
      });

    builder
      .addCase(fetchPaymentsHistory.pending, (state) => {
        state.paymentsHistory.status = 'loading';
        state.paymentsHistory.data = initialState.paymentsHistory.data;
      })
      .addCase(fetchPaymentsHistory.fulfilled, (state, action) => {
        state.paymentsHistory.status = 'success';
        state.paymentsHistory.data = action.payload;
      })
      .addCase(fetchPaymentsHistory.rejected, (state) => {
        state.paymentsHistory.status = 'error';
      })
      .addCase(fetchTransactionFees.pending, (state, action) => {
        state.transactionsList.status = 'loading';
        state.transactionsList.ids = [];
        state.transactionsList.entities = {};
        state.transactionsList.currentRequestID = action.meta.requestId;
      })
      .addCase(fetchTransactionFees.fulfilled, (state, action) => {
        if (state.transactionsList.currentRequestID === action.meta.requestId) {
          const ids: string[] = [];
          state.transactionsList.entities = action.payload.results.reduce(
            (acc, transaction) => {
              acc[transaction.id] = transaction;
              ids.push(transaction.id);
              return acc;
            },
            {} as Record<number, TransactionFeeDetailResponseDto>,
          );
          state.transactionsList.ids = ids;
          state.transactionsList.pagination = action.payload.pagination;
          state.transactionsList.status = 'success';
        }
      })
      .addCase(fetchTransactionFees.rejected, (state, action) => {
        state.transactionsList.status = 'error';
        state.transactionsList.split = action.meta.arg.split;
      });
    builder
      .addCase(getMoreTransactionsList.pending, (state) => {
        state.transactionsList.status = 'loading';
      })
      .addCase(getMoreTransactionsList.fulfilled, (state, action) => {
        const ids: string[] = [];
        action.payload.results.forEach((customer) => {
          state.transactionsList.entities[customer.id] = customer;
          ids.push(customer.id.toString());
        });

        state.transactionsList.ids = state.transactionsList.ids.concat(ids);
        state.transactionsList.pagination = action.payload.pagination;
        state.transactionsList.status = 'success';
      })
      .addCase(getMoreTransactionsList.rejected, (state) => {
        state.transactionsList.status = 'error';
      });
    builder
      .addCase(getOrCreatePayOrder.pending, (state) => {
        state.payOrder.status = 'loading';
      })
      .addCase(getOrCreatePayOrder.fulfilled, (state, action) => {
        state.payOrder.data = action.payload;
        state.payOrder.status = 'success';
      })
      .addCase(getOrCreatePayOrder.rejected, (state) => {
        state.payOrder.status = 'error';
      });
  },
});

export const { reducer } = transactionFees;
export const {
  cleanFetchTransactionFeeToPay,
  cleanFetchTransactionFeePaid,
  cleanFetchPaymentsHistory,
} = transactionFees.actions;
