import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useAppDispatch } from 'App/store';
import { useModal } from 'commons/hooks';
import { fetchAuthenticationFactors as fetchAuthenticationFactorsAction } from 'domains/Account';
import { useResetAuthenticationFactors } from 'domains/Account/hooks';
import { useVerifyAuthenticationFactor } from 'domains/AuthenticationFactor/hooks';

interface UseAuthenticationFactorAuthorizationProps {
  onSave: () => void;
  onCancel: () => void;
  loadingMessage?: string;
}

function useAuthenticationWithoutConfirmationModal({
  onSave,
  loadingMessage,
  onCancel,
}: UseAuthenticationFactorAuthorizationProps) {
  const dispatch = useAppDispatch();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const { cleanState } = useVerifyAuthenticationFactor();

  const resetAuthenticationFactors = useResetAuthenticationFactors();

  const [
    showAuthenticationFactorModal,
    openAuthenticationFactorModal,
    closeAuthenticationFactorModal,
  ] = useModal();

  const handleCancel = () => {
    closeAuthenticationFactorModal();
    cleanState();
    onCancel();
  };

  useEffect(() => {
    cleanState();
    const executeAuthenticationFactors = async () => {
      loadingMessage && addToastProgress({ label: loadingMessage });
      const action = await dispatch(fetchAuthenticationFactorsAction());
      loadingMessage && closeToastProgress();

      const hasActiveAuthenticationFactor = unwrapResult(action).some(
        (current) => current.active,
      );

      if (hasActiveAuthenticationFactor) {
        resetAuthenticationFactors();
        openAuthenticationFactorModal();
      }
      if (
        action.meta.requestStatus === 'rejected' ||
        !hasActiveAuthenticationFactor
      ) {
        resetAuthenticationFactors();
        onSave();
      }
    };
    executeAuthenticationFactors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsValidCode = () => {
    onSave();
    closeAuthenticationFactorModal();
  };

  return {
    showAuthenticationFactorModal,
    handleCancel,
    handleIsValidCode,
  };
}

export default useAuthenticationWithoutConfirmationModal;
