import { useEffect } from 'react';
import { Card, Icon, Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Link as LinkRoute, useParams } from 'react-router-dom';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceNameValue } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import { LocationSelection } from './components';
import LocationErrorState from './LocationErrorState';
import LocationSkeleton from './LocationSkeleton';
import { useProductDetails } from '../../EditProductPage/hooks';

interface LocationProps {
  locationId?: string | null;
  isMobile: boolean;
  hasVariants: boolean;
  onChange: (event: InterfaceNameValue) => void;
}

function Location({
  locationId,
  isMobile,
  hasVariants,
  onChange,
}: LocationProps): JSX.Element | null {
  const { t } = useTranslation(Domain.CATALOG);

  const { id } = useParams<{ id: string }>();

  const { loading: isLoadingProduct } = useProductDetails(id);

  const {
    locationMain,
    locations,
    isError,
    isLoading: isLoadingLocations,
    isSuccess,
    fetchLocations,
  } = useLocations();

  useEffect(() => {
    if (!isSuccess) {
      fetchLocations();
    }
  }, [isSuccess, fetchLocations]);

  if (isError) return <LocationErrorState onRetryError={fetchLocations} />;

  if (isLoadingLocations || isLoadingProduct) return <LocationSkeleton />;

  if (!isSuccess || !locationMain || locations.length <= 1) return null;

  const locationsPath = '/settings/locations';

  return (
    <Card padding="base" backgroundColor="neutral-background">
      <Card.Header title={t('products.detail.location')} />
      <Card.Body>
        <LocationSelection
          locations={locations}
          locationId={locationId}
          onChange={onChange}
          hasVariants={hasVariants}
          isMobile={isMobile}
        />
      </Card.Body>
      <Card.Footer>
        <Link
          as={LinkRoute}
          to={locationsPath}
          appearance="primary"
          textDecoration="underline"
          fontSize="highlight"
        >
          <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
          {t('products.detail.locationLink')}
        </Link>
      </Card.Footer>
    </Card>
  );
}

export default Location;
