import { useRef, useState } from 'react';
import { Icon, Link } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Card,
  InteractiveList,
  InterfaceInteractiveListOptions,
  Stack,
  Grid,
} from '@tiendanube/components';
import { HistoryIcon } from '@tiendanube/icons';
import { InputNumber } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  trackingInventoryHistoryClick,
  trackingProductStockEditClick,
} from 'domains/Catalog/Products/tracking';
import EditStockModal from './EditStockModal';
import { HandleChangeInterface } from '../../hooks/useProductForm/types';
import useVariantStock from '../../hooks/useVariantStock';
import { useStockHistoryContext } from '../StockHistoryModal/hooks';

interface StockProps {
  stock: string | null;
  variantId: string;
  variantName: string;
  productId: string;
  isEdit?: boolean;
  locationId?: string;
  canEditStock: boolean;
  onChange: (data: HandleChangeInterface) => void;
}

function Stock({
  stock,
  variantId,
  variantName,
  productId,
  isEdit,
  locationId,
  canEditStock,
  onChange,
}: StockProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);
  const [showEditStockModal, openEditStockModal, closeEditStockModal] =
    useModal();
  const { showAccessToStockHistory, openStockHistoryModal } =
    useStockHistoryContext();

  const title = t('products.detail.stock');

  const variantStock = useVariantStock(variantId);

  const refInitialStock = useRef(stock);

  const isInfinite = stock === null;

  const [selectedTypeStock, setSelectedTypeStock] = useState<
    'infinite' | 'limited'
  >(isInfinite ? 'infinite' : 'limited');

  const options: InterfaceInteractiveListOptions[] = [
    {
      title: t('products.detail.infinite'),
      name: 'infinite',
      active: selectedTypeStock === 'infinite',
      disabled: !canEditStock,
    },
    {
      title: t('products.detail.limited'),
      name: 'limited',
      active: selectedTypeStock === 'limited',
      disabled: !canEditStock,
    },
  ];

  const handleOnChangeInfinite = ([option]: string[]) => {
    setSelectedTypeStock(option as 'infinite' | 'limited');

    if (option === 'infinite') {
      onChange({ name: 'stock', value: null });
    } else {
      const newStock =
        refInitialStock.current === null ? '0' : refInitialStock.current;
      onChange({ name: 'stock', value: newStock });
    }
  };
  const handleOnClickStockHistory = () => {
    trackingInventoryHistoryClick();
    openStockHistoryModal({
      productId,
      variantName,
      variantId,
    });
  };

  const stockText = variantStock
    ? t('products.detail.available', {
        count: Number(variantStock),
      })
    : t('products.detail.variantStockInfinite');

  const handleOpenEditStock = () => {
    trackingProductStockEditClick(stock);
    openEditStockModal();
  };
  return (
    <>
      <Card title={title}>
        <Stack column align="stretch">
          {!isEdit && (
            <Stack.Item fill>
              <InteractiveList
                onChange={handleOnChangeInfinite}
                mode="single"
                options={options}
              />
            </Stack.Item>
          )}
          <Stack.Item fill>
            <Grid>
              <Grid.Row>
                <Grid.Column lg={3}>
                  {!isEdit && selectedTypeStock === 'limited' && (
                    <InputNumber
                      type="integer"
                      label={t('products.detail.quantity')}
                      placeholder=""
                      name="stock"
                      onChange={onChange}
                      value={stock || ''}
                      disabled={!canEditStock}
                    />
                  )}
                  {isEdit && (
                    <Link
                      as="button"
                      type="button" // to prevent submit form
                      appearance="primary"
                      onClick={handleOpenEditStock}
                      textDecoration="none"
                      disabled={!canEditStock}
                    >
                      {stockText}
                    </Link>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Stack.Item>

          {showAccessToStockHistory && !!variantId && (
            <Stack.Item>
              <Link
                onClick={handleOnClickStockHistory}
                appearance="primary"
                textDecoration="none"
              >
                <Icon color="currentColor" source={<HistoryIcon />} />
                {t('products.detail.goToStockHistory')}
              </Link>
            </Stack.Item>
          )}
        </Stack>
      </Card>
      {showEditStockModal && (
        <EditStockModal
          isShow={showEditStockModal}
          productId={productId}
          variantId={variantId}
          displayStockTypes
          currentStock={variantStock === null ? null : String(variantStock)}
          onClose={closeEditStockModal}
          locationId={locationId}
        />
      )}
    </>
  );
}

export default Stock;
