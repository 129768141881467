import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useNavTabs } from 'App/components/Navtabs';

/**
 * This hook is used to reuse menu click logic.
 *
 * ** HOW TO USE IT? **
 * This methods were implemented taking into consideration that it are used as handlers in Menu.Item compomente.
 * Ex: <Menu.Item onClick={handleClickItem} />
 *
 * If that's not the usage case, the handler must be called like: handleClickItem();
 *
 * It returns:
 *   - handleClickAccordion: should be used when click 'parent' menu option, like 'products', 'orders', etc.
 *   - handleClickItem: should be used when click 'child' options, like 'list of products', 'home', etc.
 *   - handleClick: should be used in case of none of above methods were suitable
 */
export function useHandleMenuClick() {
  const { hideSideBar } = useNavTabs();
  const { scrollToTop } = useLayoutScroll();

  const handleClick = (closeSidebar: boolean) => () => {
    scrollToTop();
    if (closeSidebar) hideSideBar();
  };

  const handleClickAccordion = handleClick(false);

  const handleClickItem = handleClick(true);

  return { handleClick, handleClickAccordion, handleClickItem };
}
