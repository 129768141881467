import { useMemo } from 'react';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetRecurrentPaymentChargeDataQuery } from 'domains/Billing/Checkout/CheckoutApi';
import { CONCEPTS_TO_SHOW } from './constants';

function useRecurrentPaymentChargeData() {
  const {
    status,
    refetch,
    data: recurrentPaymentChargeData,
  } = useGetRecurrentPaymentChargeDataQuery();

  const recurrentPaymentCharges = useMemo(
    () =>
      recurrentPaymentChargeData?.filter((data) =>
        CONCEPTS_TO_SHOW.includes(data.concept),
      ),
    [recurrentPaymentChargeData],
  );

  return {
    status: toStatusType(status),
    recurrentPaymentCharges,
    ...convertStatusTypeToObject(status),
    refreshRecurrentPaymentChargeData: refetch,
  };
}

export { useRecurrentPaymentChargeData };
