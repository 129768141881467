import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getCustomShippingData,
  getCustomShippingStatus,
  fetchCustomShipping as fetchCustomShippingAction,
} from '../../deliveryMethodsSlice';

function useGetCustomShipping() {
  const dispatch = useAppDispatch();
  const { isLoading, isError } = useSelector(getCustomShippingStatus);
  const customShipping = useSelector(getCustomShippingData);

  const fetchCustomShipping = useCallback(() => {
    dispatch(fetchCustomShippingAction());
  }, [dispatch]);

  return {
    isLoading,
    isError,
    customShipping,
    fetchCustomShipping,
  };
}

export default useGetCustomShipping;
