import { ChangeEvent } from 'react';
import { Select, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { OcaLabelSettingBoxOptionsDto } from '@tiendanube/common';
import { InputNumberNimbus, Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface OcaBoxOptionsFormProps {
  boxOption: OcaLabelSettingBoxOptionsDto;
  boxOptions: OcaLabelSettingBoxOptionsDto[];
  onChange: (
    name: string,
    value: OcaLabelSettingBoxOptionsDto | string,
  ) => void;
}

function OcaBoxOptionsForm({
  boxOption,
  boxOptions,
  onChange,
}: Readonly<OcaBoxOptionsFormProps>) {
  const t = useTranslationFulfillmentOrders('ocaPrePrint');

  const handleOnChangeBoxOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const boxOption = boxOptions.find(
      (boxOption) => boxOption.description === e.target.value,
    );
    onChange(
      'boxOption',
      boxOption ?? {
        description: e.target.value,
        dimensions: {
          depth: 0,
          height: 0,
          weight: 0,
          width: 0,
        },
      },
    );
  };

  return (
    <Stack column spacing="base" align="stretch">
      <Text fontWeight="bold">{t('card.box')}</Text>
      <FormField.Select
        label={t('card.boxOptions.title')}
        id="boxOption"
        name="boxOption"
        onChange={handleOnChangeBoxOption}
        value={boxOption.description}
      >
        <Select.Option
          key="empty"
          label={t('card.boxOptions.empty')}
          value=""
          disabled
        />
        {boxOptions.map((option) => (
          <Select.Option
            key={option.description}
            label={option.description}
            value={option.description}
          />
        ))}
        <Select.Option
          key="custom"
          label={t('card.boxOptions.custom')}
          value="custom"
        />
      </FormField.Select>
      {boxOption.description === 'custom' && (
        <Stack justify="space-between">
          <FormField label={t('card.boxOptions.dimensions.width')} id="width">
            <InputNumberNimbus
              id="width"
              name="width"
              type="integer"
              append="mm"
              appendPosition="end"
              value={boxOption.dimensions.width}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </FormField>
          <FormField label={t('card.boxOptions.dimensions.depth')} id="width">
            <InputNumberNimbus
              id="depth"
              name="depth"
              type="integer"
              append="mm"
              appendPosition="end"
              value={boxOption.dimensions.depth}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </FormField>
          <FormField label={t('card.boxOptions.dimensions.height')} id="width">
            <InputNumberNimbus
              id="height"
              name="height"
              type="integer"
              append="mm"
              appendPosition="end"
              value={boxOption.dimensions.height}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </FormField>
        </Stack>
      )}
    </Stack>
  );
}

export default OcaBoxOptionsForm;
