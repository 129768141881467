import { Domain as DomainEnum } from '@tiendanube/common/src/enums';

export enum CategoryEnum {
  ORDER = 'order',
  PRODUCT = 'product',
}

export enum Params {
  ORDER_ID = 'order_id',
  PRODUCT_ID = 'product_id',
}

const BASE_URL_ENV = import.meta.env.VITE_BASE_URL;
const BASE_URL = !BASE_URL_ENV || BASE_URL_ENV === '/' ? '' : BASE_URL_ENV;

const actionPushNotification = ({
  category,
  params,
}: {
  category: CategoryEnum;
  params: string;
}): void => {
  let dataReceived: Record<string, string>;
  try {
    dataReceived = JSON.parse(params);
  } catch (error) {
    console.log(error);
    return;
  }

  switch (category) {
    case CategoryEnum.ORDER:
      if (Params.ORDER_ID in dataReceived) {
        window.location.href = `${BASE_URL}/${DomainEnum.ORDERS}/${dataReceived.order_id}`;
      }
      break;
    case CategoryEnum.PRODUCT:
      if (Params.PRODUCT_ID in dataReceived) {
        window.location.href = `${BASE_URL}/products/${dataReceived.product_id}`;
      }
      break;
  }
};

export default actionPushNotification;
