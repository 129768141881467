import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { ErrorState } from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_BUSINESS_DATA } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useBusinessData, useTranslationAccount } from 'domains/Account/hooks';
import {
  GeneralInformationBusinessDataCard,
  NameAndDescriptionBusinessDataCard,
  GeneralInformationBusinessDataCardSkeleton,
  NameAndDescriptionBusinessDataCardSkeleton,
  CancelStoreBusinessDataPageCard,
} from './components';

function BusinessDataPage() {
  const t = useTranslationAccount();
  const language = useTranslationLanguage();

  //TODO: Redirect to dashboard temporarily while fix menu behavior
  const { goTo } = useNavegate();
  const goToDashboard = () => goTo('/');

  const {
    fetchBusinessData,
    setBusinessDataValues,
    isLoading,
    isError,
    errors,
    businessDataValues,
    businessDataOptions,
    handleOnSubmit,
    isLoadingUpdate,
    isDirty,
  } = useBusinessData();
  const { name, description, useStoreInfoForSeo, ...values } =
    businessDataValues;

  useEffect(() => {
    fetchBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goToDashboard }} />}
      headerContent={
        <HeaderContent
          title={t('businessDataPage.title')}
          subtitle={t('businessDataPage.subtitle')}
        />
      }
      width="small"
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      {isLoading && (
        <>
          <NameAndDescriptionBusinessDataCardSkeleton />
          <GeneralInformationBusinessDataCardSkeleton />
        </>
      )}

      {isError && (
        <ErrorState
          title={t('businessDataPage.errorState.title')}
          action={{
            children: t('businessDataPage.errorState.retry'),
            onClick: fetchBusinessData,
          }}
        />
      )}

      {!isLoading && !isError && (
        <>
          <NameAndDescriptionBusinessDataCard
            name={name}
            description={description}
            useStoreInfoForSeo={useStoreInfoForSeo}
            onChange={setBusinessDataValues}
            errors={errors}
          />

          <GeneralInformationBusinessDataCard
            onChange={setBusinessDataValues}
            values={values}
            options={businessDataOptions}
          />

          <CancelAndSaveButtons
            isLoading={isLoadingUpdate}
            onCancel={goToDashboard}
            onSave={handleOnSubmit}
            saveText={t(
              isLoadingUpdate
                ? 'businessDataPage.update.savingButton'
                : 'businessDataPage.update.saveButton',
            )}
            isDisabled={!isDirty}
          />
          <HelpLink
            title={t('businessDataPage.helpLink.title')}
            linkURL={HELP_LINKS_BUSINESS_DATA[language]}
            previousValue=""
            currentViewTracking={t('businessDataPage.helpLink.amplitudeName')}
            icon="both"
          />
          <CancelStoreBusinessDataPageCard />
        </>
      )}
    </IonPageStratus>
  );
}

export default BusinessDataPage;
