import { ChangeEvent } from 'react';
import { Checkbox, Title, Card } from '@nimbus-ds/components';
import {
  LanguageInterface,
  LanguageNameValueInterface,
  LanguagesType,
} from 'App/i18n';
import { ExternalLink, Stack } from 'commons/components';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useTranslationAccount } from 'domains/Account/hooks';
import { FormNameAndDescription } from './components';

type onChangeDataType = (data: {
  name: string;
  value: string | boolean | LanguageInterface;
}) => void;

interface NameAndDescriptionBusinessDataCardProps {
  name: LanguageInterface;
  description: LanguageInterface;
  useStoreInfoForSeo: boolean;
  onChange: onChangeDataType;
  errors: Partial<Record<string, string>>;
}

function NameAndDescriptionBusinessDataCard({
  onChange,
  name,
  description,
  useStoreInfoForSeo,
  errors,
}: Readonly<NameAndDescriptionBusinessDataCardProps>) {
  const t = useTranslationAccount();

  const missingLanguages = Object.keys(errors)
    .filter((err) => err.startsWith('name'))
    .map((err) => err.split('.')[1]);

  const handleChange = ({ name, value }: LanguageNameValueInterface) => {
    onChange({
      name,
      value,
    });
  };

  const handleCheck = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: checked,
    });
  };

  return (
    <Card>
      <Card.Header>
        <Title as="h3">
          {t('businessDataPage.nameAndDescriptionCard.title')}
        </Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="loose">
          <TabsMultiLanguage
            initialLanguage={missingLanguages[0] as LanguagesType}
          >
            <FormNameAndDescription
              onChange={handleChange}
              name={name}
              errors={errors}
              description={description}
            />
          </TabsMultiLanguage>
          <Checkbox
            label={t(
              'businessDataPage.nameAndDescriptionCard.useStoreInfoForSeo.label',
            )}
            name="useStoreInfoForSeo"
            checked={useStoreInfoForSeo}
            onChange={handleCheck}
          />
          <ExternalLink
            href={t(
              'businessDataPage.nameAndDescriptionCard.externalLink.href',
            )}
            textDecoration="none"
            appearance="primary"
          >
            {t('businessDataPage.nameAndDescriptionCard.externalLink.label')}
          </ExternalLink>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default NameAndDescriptionBusinessDataCard;
