import { SelectedProductInterface } from 'domains/Catalog/Products/components';
import { ProductsInterface } from './Products';

export const getProductsMaped = (
  products: SelectedProductInterface[],
): ProductsInterface[] =>
  products.map((p) => ({
    id: p.id,
    description: p.name,
  }));

export const getSelectedProductsMaped = (
  products: ProductsInterface[],
): SelectedProductInterface[] =>
  products.map((p) => ({
    id: p.id,
    name: p.description,
    imageUrl: '',
    stock: false,
    visible: false,
    attributes: [],
    variants: [],
  }));
