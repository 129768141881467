import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, Grid, Input, Stack } from '@tiendanube/components';

function CustomerDataCardSkeleton(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <Card title={t('customerForm.customerData.title')}>
      <Stack column align="stretch">
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Input.Skeleton />
        </Stack.Item>
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column sm={6} md={3} lg={3}>
                <Input.Skeleton />
              </Grid.Column>
              <Grid.Column sm={6} md={3} lg={3}>
                <Input.Skeleton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
      </Stack>
    </Card>
  );
}

export default CustomerDataCardSkeleton;
