import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ProvinceDto, ShippingAddressResponseDto } from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import {
  CitiesInterface,
  CodeCountryCodeProvinceInterface,
  InterfaceAdressesSchema,
} from './types';
import addressesService from '../addressesService';

const initialState: InterfaceAdressesSchema = {
  countries: {
    data: null,
    status: 'idle',
  },
  addressesBrasil: {
    data: null,
    status: 'idle',
  },
  provinces: {
    status: 'idle',
    data: null,
  },
  cities: {
    status: 'idle',
    data: {},
  },
};

export const fetchCountries = createAsyncThunk<
  Record<string, string>,
  undefined
>('shipping/addresses/fetchCountries', async () =>
  addressesService.getCountries(),
);

export const fetchAddressesBrazil = createAsyncThunk<
  ShippingAddressResponseDto,
  string
>('shipping/addresses/fetchAddressesBrazil', async (zipcode) =>
  addressesService.addressesBrazil(zipcode),
);

export const fetchProvinces = createAsyncThunk<ProvinceDto[], string>(
  'shipping/addresses/fetchProvinces',
  async (countryCode) => {
    const response = await addressesService.getProvinces(countryCode);
    return response;
  },
);

export const fetchCities = createAsyncThunk<
  CitiesInterface[],
  CodeCountryCodeProvinceInterface
>('shipping/addresses/fetchCities', async ({ countryCode, provinceCode }) => {
  const response = await addressesService.getCities(countryCode, provinceCode);
  return response;
});

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    cleanAddresses(state) {
      state.addressesBrasil = initialState.addressesBrasil;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder.addCase(fetchCountries.pending, (state) => {
      state.countries.data = initialState.countries.data;
      state.countries.status = 'loading';
    });

    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries.data = action.payload;
      state.countries.status = 'success';
    });

    builder.addCase(fetchCountries.rejected, (state) => {
      state.countries.data = initialState.countries.data;
      state.countries.status = 'error';
    });

    builder.addCase(fetchAddressesBrazil.pending, (state) => {
      state.addressesBrasil.data = initialState.addressesBrasil.data;
      state.addressesBrasil.status = 'loading';
    });

    builder.addCase(fetchAddressesBrazil.fulfilled, (state, action) => {
      state.addressesBrasil.data = action.payload;
      state.addressesBrasil.status = 'success';
    });

    builder.addCase(
      fetchAddressesBrazil.rejected,
      (state, { error: { message } }) => {
        state.addressesBrasil.data = initialState.addressesBrasil.data;
        state.addressesBrasil.status = message?.includes('404')
          ? 'not-found'
          : 'error';
      },
    );

    builder.addCase(fetchProvinces.pending, (state) => {
      state.provinces.status = 'loading';
      state.provinces.data = initialState.provinces.data;
    });

    builder.addCase(fetchProvinces.rejected, (state) => {
      state.provinces.data = initialState.provinces.data;
      state.provinces.status = 'error';
    });

    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.provinces.status = 'success';
      state.provinces.data = action.payload;
    });

    builder.addCase(fetchCities.pending, (state) => {
      state.cities.status = 'loading';
    });

    builder.addCase(fetchCities.rejected, (state) => {
      state.cities.status = 'error';
    });

    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities.status = 'success';
      state.cities.data = {
        ...state.cities.data,
        [action.meta.arg.provinceCode]: action.payload,
      };
    });
  },
});

export const { reducer } = addressesSlice;
export const { cleanAddresses } = addressesSlice.actions;
