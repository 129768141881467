import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getNotInstallablePaymentProvidersData,
  getNotInstalledPaymentProvidersAction,
  getNotInstalledPaymentProvidersData,
  getNotInstalledPaymentProvidersStatus,
} from '../../paymentProvidersSlice';

function useNotInstalledPaymentProviders() {
  const dispatch = useAppDispatch();
  const notInstalledPaymentProviders = useSelector(
    getNotInstalledPaymentProvidersData,
  );
  const notInstallablePaymentProviders = useSelector(
    getNotInstallablePaymentProvidersData,
  );
  const { isError, isLoading } = useSelector(
    getNotInstalledPaymentProvidersStatus,
  );

  const fetchNotInstalledPaymentProviders = useCallback(() => {
    dispatch(getNotInstalledPaymentProvidersAction());
  }, [dispatch]);

  return {
    isError,
    isLoading,
    notInstalledPaymentProviders,
    notInstallablePaymentProviders,
    fetchNotInstalledPaymentProviders,
  };
}

export default useNotInstalledPaymentProviders;
