import { LocationResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageItemDescription } from 'domains/Catalog/Inventory/pages/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { useInventoryByVariant } from '../../hooks';
import StockDistribution from '../StockDistribution';

interface InventoryByVariantProps {
  productId: string;
  variantId: string;
  locations: LocationResponseDto[];
}

function InventoryByVariant({
  productId,
  variantId,
  locations,
}: InventoryByVariantProps): JSX.Element {
  const t = useTranslationCatalog();
  const language = useGetLanguage();
  const { goBack } = useNavegate();

  const {
    variant,
    product,
    inventoryLevels,
    initialinventory,
    status,
    setInventoryLevels,
    updateInventoryLevels,
  } = useInventoryByVariant(productId, variantId);

  const variantName =
    variant?.values.map((value) => value[language]).join(' ') || '';

  const handleCancel = () => goBack();
  return (
    <>
      <ImageItemDescription
        language={language}
        product={product}
        variant={variant}
      />

      <StockDistribution
        locations={locations}
        initialinventory={initialinventory}
        inventory={inventoryLevels}
        variantId={variantId}
        productId={productId}
        variantName={variantName}
        setInventory={setInventoryLevels}
      />

      <CancelAndSaveButtons
        saveText={t('products.detail.save')}
        cancelText={t('products.detail.cancel')}
        onCancel={handleCancel}
        onSave={updateInventoryLevels}
        isLoading={status === 'loading'}
      />
    </>
  );
}

export default InventoryByVariant;
