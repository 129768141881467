export interface UsePaginationStratusProps {
  totalItems: number;
  perPage: number;
  currentPage: number;
}

function usePaginationStratus({
  currentPage,
  perPage,
  totalItems,
}: UsePaginationStratusProps) {
  const lastPage = currentPage * perPage;
  const firstItem = lastPage - perPage + 1;
  const lastItem = lastPage > totalItems ? totalItems : lastPage;
  const totalPages = Math.ceil(totalItems / perPage);

  return {
    firstItem,
    lastItem,
    totalPages,
  };
}

export default usePaginationStratus;
