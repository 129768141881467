import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  getCitiesData,
  getCitiesStatus,
  fetchCities as fetchActionCities,
} from '../../addressesSlice';

function useGetCities(provinceCode: string) {
  const countryCode = useGetCountry();
  const dispatch = useAppDispatch();
  const { isLoading, isError } = useSelector(getCitiesStatus);

  const cities = useSelector((state: RootStateType) =>
    getCitiesData(state, provinceCode),
  );

  const fetchCities = useCallback(() => {
    dispatch(fetchActionCities({ countryCode, provinceCode }));
  }, [dispatch, countryCode, provinceCode]);

  return {
    isLoading,
    isError,
    cities,
    fetchCities,
  };
}

export default useGetCities;
