import { useCallback, useMemo } from 'react';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  usePaymentMethods,
  usePayorderToPay,
  usePaymentSetup,
} from 'domains/Billing/Checkout/hooks';

function usePaymentSelectionForPayOrder() {
  const { paymentMethods, status: paymentMethodsStatus } = usePaymentMethods();

  const { payPayOrder, extraStatus, resetPreference } = usePaymentSetup();

  const preferenceLoading = extraStatus === 'loading';

  const availablePaymentMethods = useMemo(() => {
    if (!paymentMethods) return;
    return paymentMethods.map(({ method }) => method);
  }, [paymentMethods]);

  const { payorderToPay, status: payOrderStatus } = usePayorderToPay();

  const statusObject = combineStatus(payOrderStatus, paymentMethodsStatus);

  const payWithWireTransfer = useCallback(() => {
    if (preferenceLoading) return;
    payPayOrder.transfer(payorderToPay);
  }, [payorderToPay, payPayOrder, preferenceLoading]);

  const payWithCreditCard = useCallback(() => {
    if (preferenceLoading) return;
    payPayOrder.cc(payorderToPay);
  }, [payorderToPay, preferenceLoading, payPayOrder]);

  const payWithMercadoPago = useCallback(() => {
    if (preferenceLoading) return;
    payPayOrder.ticket(payorderToPay);
  }, [payorderToPay, payPayOrder, preferenceLoading]);

  return {
    availablePaymentMethods,
    payWithWireTransfer,
    payWithCreditCard,
    payWithMercadoPago,
    ...statusObject,
    preferenceStatus: extraStatus || 'idle',
    preferenceLoading,
    onFinish: resetPreference,
  };
}

export default usePaymentSelectionForPayOrder;
