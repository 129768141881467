import { ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';
import { BAR_CHART_HEIGTH } from 'domains/Statistics/utils';

interface ChartContainerProps {
  children: ReactElement;
  heigth?: number;
  className?: string;
}

function ChartContainer({
  children,
  heigth,
  className,
}: Readonly<ChartContainerProps>) {
  return (
    <ResponsiveContainer
      className={className}
      width="100%"
      height={heigth || BAR_CHART_HEIGTH}
    >
      {children}
    </ResponsiveContainer>
  );
}

export default ChartContainer;
