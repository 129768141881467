import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getNuvemEnvioConfigsAction,
  getNuvemEnvioConfigsData,
  getNuvemEnvioConfigsStatus,
} from '../../deliveryMethodsSlice';

function useGetNuvemEnvioConfigs() {
  const dispatch = useAppDispatch();
  const nuvemEnvioConfigs = useSelector(getNuvemEnvioConfigsData);
  const status = useSelector(getNuvemEnvioConfigsStatus);

  const fetchNuvemEnvioConfigs = useCallback(
    () => dispatch(getNuvemEnvioConfigsAction()),
    [dispatch],
  );

  return {
    nuvemEnvioConfigs,
    fetchNuvemEnvioConfigs,
    status,
  };
}

export default useGetNuvemEnvioConfigs;
