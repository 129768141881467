import { useTranslation } from 'react-i18next';

function useTranslationLanguage() {
  const {
    i18n: { language },
  } = useTranslation();
  return language;
}

export default useTranslationLanguage;
