import { FirstSaleResponseDto, FirstSaleRequestDto } from '@tiendanube/common';
import axios from 'App/axios';
import { DASHBOARD_FIRST_SALE_URL } from '../urls';

export async function markFirstSaleRead(payload: FirstSaleRequestDto) {
  const { data } = await axios.post<FirstSaleResponseDto>(
    DASHBOARD_FIRST_SALE_URL,
    payload,
  );
  return data;
}

export async function getFirstSale() {
  const { data } = await axios.get<FirstSaleResponseDto>(
    DASHBOARD_FIRST_SALE_URL,
  );
  return data;
}
