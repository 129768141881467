import { Box, Card, Tabs } from '@nimbus-ds/components';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { LastPayments, SelectChargesToPayTab } from './components';
import { useSelectCharges } from './components/SelectChargesToPayTab/hooks';
import { useManualPaidCharges } from '../../hooks';

function PaymentsTabs() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs',
  );
  const { storeStatus } = useStoreStatus();
  const { isTrial } = useGetIsFreePlan();

  const { chargesToSelect, chargesStatus } = useSelectCharges();
  const { manualPaidCharges, status } = useManualPaidCharges(
    chargesToSelect?.length === 0,
  );

  const { isSuccess } = combineStatus(status, chargesStatus);

  const shouldShowChargesTab =
    chargesToSelect?.length || isTrial || storeStatus === 'store_down';
  const shouldShowLastPaymentsTab = manualPaidCharges?.length;

  if (isSuccess && !shouldShowChargesTab && !shouldShowLastPaymentsTab)
    return null;

  return (
    <Card padding="none">
      <Box marginTop="2">
        <Tabs>
          <Tabs.Item label={t('toPay.tabTitle')}>
            <SelectChargesToPayTab />
          </Tabs.Item>
          <Tabs.Item label={t('lastPayments.tabTitle')}>
            <LastPayments />
          </Tabs.Item>
        </Tabs>
      </Box>
    </Card>
  );
}

export default PaymentsTabs;
