import { Card, Stack, Text } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';

function DeliveryInfoCardSkeleton() {
  const t = useTranslationOrders();

  return (
    <Card title={t('abandonedCarts.deliveryInfoCard.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Text.Skeleton width="large" />
        <Text.Skeleton size="caption" />
        <Text.Skeleton width="large" />
        <Text.Skeleton width="large" />
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCardSkeleton;
