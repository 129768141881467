import { ReactNode, useState } from 'react';
import {
  Box,
  Card,
  Tag,
  TagProperties,
  Title,
  Toggle,
} from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import CardCollapsibleSkeleton from './Skeleton';
import Stack from '../Stack';

interface CardCollapsibleProps {
  title: string | ReactNode;
  active: boolean;
  appearance?: 'chevron' | 'toggle';
  alignSwitch?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  disabled?: boolean;
  children: ReactNode;
  onChange?: (active: boolean) => void;
  headerTag?: {
    visible: boolean;
    text: string;
    appearance: TagProperties['appearance'];
  };
}

function CardCollapsible({
  title,
  active,
  disabled = false,
  children,
  appearance = 'chevron',
  alignSwitch = 'center',
  headerTag = { visible: false, text: '', appearance: 'neutral' },
  onChange,
}: CardCollapsibleProps): JSX.Element {
  const [isActive, setIsActive] = useState(active);

  const renderComponent: Record<'chevron' | 'toggle', ReactNode> = {
    chevron: isActive && !disabled ? <ChevronUpIcon /> : <ChevronDownIcon />,
    toggle: <Toggle name="" checked={isActive} />,
  };

  const header =
    typeof title === 'string' ? <Title as="h3">{title}</Title> : title;

  const handleToggle = () => {
    onChange?.(!isActive);

    if (!disabled) {
      setIsActive(!isActive);
    }
  };

  return (
    <Card>
      <Card.Header onClick={handleToggle}>
        <Stack spacing="none" justify="space-between" align={alignSwitch}>
          {header}
          {headerTag.visible && (
            <Box ml="auto" mr="2">
              <Tag appearance={headerTag.appearance}>{headerTag.text}</Tag>
            </Box>
          )}
          {renderComponent[appearance]}
        </Stack>
      </Card.Header>
      {isActive && !disabled && <Card.Body>{children}</Card.Body>}
    </Card>
  );
}

CardCollapsible.Skeleton = CardCollapsibleSkeleton;

export default CardCollapsible;
