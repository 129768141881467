import { Alert, Text, Link } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function ExportProductsAlert({
  exportingStatus,
  csvUrl,
  ressetExportingStatus,
}) {
  const t = useTranslationCatalog();

  const handleDismiss =
    exportingStatus === 'loading' ? undefined : ressetExportingStatus;

  const title = t(
    `products.importExportPage.export.alert.title.${exportingStatus}`,
  );
  const description = t(
    `products.importExportPage.export.alert.message.${exportingStatus}`,
  );
  const appearanceAlert = {
    success: 'success',
    error: 'danger',
  };
  const appearance = appearanceAlert[exportingStatus] || 'primary';
  const show = exportingStatus !== 'idle';
  const downloadTextLink =
    exportingStatus === 'success'
      ? t('products.importExportPage.export.alert.donwload')
      : undefined;

  return (
    <Alert
      title={title}
      appearance={appearance}
      show={show}
      onRemove={handleDismiss}
    >
      {exportingStatus !== 'error' && <Text as="p">{description}</Text>}
      {!!downloadTextLink && (
        <Link appearance="neutral" as="a" href={csvUrl} onClick={handleDismiss}>
          {downloadTextLink}
        </Link>
      )}
    </Alert>
  );
}

export default ExportProductsAlert;
