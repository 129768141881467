import ResultItemSkeleton from './ResultItemSkeleton';

function ResultRowDesktopSkeleton(): JSX.Element {
  return (
    <>
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
      <ResultItemSkeleton />
    </>
  );
}

export default ResultRowDesktopSkeleton;
