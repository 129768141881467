import { Radio, Text, Label } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { DeliveryZones } from './DeliveryZones';
import { SelectionModes } from './types';

export function DeliveryZonesSelector({
  zones,
  zonesType,
  onChange,
  onChangeZones,
}) {
  const t = useTranslationMarketing('freeShipping.asides.deliveryZones');

  return (
    <Stack column spacing="tight" align="flex-start">
      <Text fontWeight="bold">{t('title')}</Text>
      <Stack spacing="tight">
        <Radio
          id="zone-type-1"
          name="zonesType"
          as="button"
          value={SelectionModes.ALL}
          checked={zonesType === SelectionModes.ALL}
          label={t('all.label')}
          onChange={onChange}
        />
        <Radio
          id="zone-type-2"
          name="zonesType"
          as="button"
          value={SelectionModes.SOME}
          checked={zonesType === SelectionModes.SOME}
          label={t('some.label')}
          onChange={onChange}
        />
      </Stack>
      {zonesType === SelectionModes.ALL && (
        <Stack>
          <Stack.Item>
            <Label>{t('all.description')}</Label>
          </Stack.Item>
        </Stack>
      )}
      {zonesType === SelectionModes.SOME && (
        <Stack column align="stretch">
          <Stack.Item>
            <Label>{t('some.description')}</Label>
          </Stack.Item>
          <Stack.Item>
            <DeliveryZones zones={zones} onChange={onChangeZones} />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
}
