import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  deleteCustomShippingAction,
  deleteCustomShippingStatus,
  cleanDeleteCustomShippingStatus,
} from '../../deliveryMethodsSlice';

function useDeleteCustomShipping() {
  const dispatch = useAppDispatch();

  const status = useSelector(deleteCustomShippingStatus);

  const deleteCustomShipping = useCallback(
    async (id: string) => {
      await dispatch(deleteCustomShippingAction(id));
      dispatch(cleanDeleteCustomShippingStatus());
    },
    [dispatch],
  );

  return {
    status,
    deleteCustomShipping,
  };
}

export default useDeleteCustomShipping;
