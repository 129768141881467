import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceNameValue, Stack } from '@tiendanube/components';
import { InputNumber } from 'commons/components';

interface OnChangeValue {
  name: string;
  value: string;
}

interface StockQuantityProps {
  min: string;
  max: string;
  onChange: ({ name, value }: OnChangeValue) => void;
  minError: string;
  maxError: string;
}
function StockQuantity({
  min,
  max,
  onChange,
  minError,
  maxError,
}: StockQuantityProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <Stack align="stretch">
      <Stack.Item fill>
        <InputNumber
          type="integer"
          label={t('products.filter.min')}
          value={`${min}`}
          placeholder="0"
          name="minStock"
          onChange={handleChange}
          appearance={minError ? 'validation_error' : undefined}
          helpText={t(minError)}
        />
      </Stack.Item>
      <Stack.Item fill>
        <InputNumber
          type="integer"
          label={t('products.filter.max')}
          value={`${max}`}
          placeholder="∞"
          name="maxStock"
          onChange={handleChange}
          appearance={maxError ? 'validation_error' : undefined}
          helpText={t(maxError)}
        />
      </Stack.Item>
    </Stack>
  );
}

export default StockQuantity;
