import { Text } from '@nimbus-ds/components';
import { CouponDiscountType as CouponDiscountTypeType } from '@tiendanube/common';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface ShippingProps {
  type: CouponDiscountTypeType;
  includeShipping: boolean;
}

export function Shipping({ type, includeShipping }: Readonly<ShippingProps>) {
  const t = useTranslationMarketing();
  const included = includeShipping
    ? t('coupons.details.shipping.included')
    : t('coupons.details.shipping.notIncluded');
  const valueContent = type !== 'shipping' ? included : '-';

  return <Text>{valueContent}</Text>;
}
