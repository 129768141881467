import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MetafieldsListV2Dto, MetafieldsMapV2Dto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import {
  getMetafieldsCategories,
  getMetafieldsCustomer,
  getMetafieldsOrders,
  getMetafieldsProductsAndVariants,
  getMetafieldsVariants,
} from '../metafieldsService';

export interface InterfaceOrdersMetafieldsSchema {
  orders: {
    data: MetafieldsMapV2Dto;
    status: StatusType;
  };
  variants: {
    data: MetafieldsListV2Dto;
    status: StatusType;
  };
  customer: {
    data: MetafieldsListV2Dto;
    status: StatusType;
  };
  categories: {
    data: MetafieldsListV2Dto;
    status: StatusType;
  };
  productsAndVariants: {
    data: MetafieldsListV2Dto;
    status: StatusType;
  };
}

const initialState: InterfaceOrdersMetafieldsSchema = {
  orders: {
    data: {
      internals: {},
      fromApi: {},
    },
    status: 'idle',
  },
  variants: {
    data: {
      internals: [],
      fromApi: [],
    },
    status: 'idle',
  },
  customer: {
    data: {
      internals: [],
      fromApi: [],
    },
    status: 'idle',
  },
  categories: {
    data: {
      internals: [],
      fromApi: [],
    },
    status: 'idle',
  },
  productsAndVariants: {
    data: {
      internals: [],
      fromApi: [],
    },
    status: 'idle',
  },
};

export const fetchMetafieldsOrdersAction = createAsyncThunk<
  MetafieldsMapV2Dto,
  void
>('orders/fetchMetafieldsOrders', getMetafieldsOrders);

export const fetchMetafieldsVariantsAction = createAsyncThunk<
  MetafieldsListV2Dto,
  void
>('catalog/fetchMetafieldsVariants', getMetafieldsVariants);

export const fetchMetafieldsCustomerAction = createAsyncThunk<
  MetafieldsListV2Dto,
  void
>('customer/fetchMetafieldsCustomer', getMetafieldsCustomer);

export const fetchMetafieldsCategoriesAction = createAsyncThunk<
  MetafieldsListV2Dto,
  void
>('categories/fetchMetafieldsCategories', getMetafieldsCategories);

export const fetchMetafieldsProductsAndVariantsAction = createAsyncThunk<
  MetafieldsListV2Dto,
  void
>(
  'productsAndVariants/fetchMetafieldsProductsAndVariants',
  getMetafieldsProductsAndVariants,
);

const metafieldsSlice = createSlice({
  name: 'metafields',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetafieldsOrdersAction.pending, (state) => {
        state.orders.status = 'loading';
      })
      .addCase(fetchMetafieldsOrdersAction.fulfilled, (state, action) => {
        state.orders.status = 'success';
        state.orders.data = action.payload;
      })
      .addCase(fetchMetafieldsOrdersAction.rejected, (state) => {
        state.orders.status = 'error';
      });

    builder
      .addCase(fetchMetafieldsVariantsAction.pending, (state) => {
        state.variants.status = 'loading';
      })
      .addCase(
        fetchMetafieldsVariantsAction.fulfilled,
        (state, { payload }) => {
          const { internals, fromApi } = payload;
          state.variants.status = 'success';
          state.variants.data = {
            internals,
            fromApi,
          };
        },
      )
      .addCase(fetchMetafieldsVariantsAction.rejected, (state) => {
        state.variants.status = 'error';
      });

    builder
      .addCase(fetchMetafieldsCustomerAction.pending, (state) => {
        state.customer.status = 'loading';
      })
      .addCase(
        fetchMetafieldsCustomerAction.fulfilled,
        (state, { payload }) => {
          const { internals, fromApi } = payload;
          state.customer.status = 'success';
          state.customer.data = {
            internals,
            fromApi,
          };
        },
      )
      .addCase(fetchMetafieldsCustomerAction.rejected, (state) => {
        state.customer.status = 'error';
      });

    builder
      .addCase(fetchMetafieldsCategoriesAction.pending, (state) => {
        state.categories.status = 'loading';
      })
      .addCase(
        fetchMetafieldsCategoriesAction.fulfilled,
        (state, { payload }) => {
          const { internals, fromApi } = payload;
          state.categories.status = 'success';
          state.categories.data = {
            internals,
            fromApi,
          };
        },
      )
      .addCase(fetchMetafieldsCategoriesAction.rejected, (state) => {
        state.categories.status = 'error';
      });

    builder
      .addCase(fetchMetafieldsProductsAndVariantsAction.pending, (state) => {
        state.productsAndVariants.status = 'loading';
      })
      .addCase(
        fetchMetafieldsProductsAndVariantsAction.fulfilled,
        (state, { payload }) => {
          const { internals, fromApi } = payload;
          state.productsAndVariants.status = 'success';
          state.productsAndVariants.data = {
            internals,
            fromApi,
          };
        },
      )
      .addCase(fetchMetafieldsProductsAndVariantsAction.rejected, (state) => {
        state.productsAndVariants.status = 'error';
      });
  },
});

export const { reducer } = metafieldsSlice;
