import { Card } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface SummaryErrorProps {
  onClick: () => void;
}

function SummaryError({ onClick }: SummaryErrorProps): JSX.Element {
  const t = useTranslationBilling('checkout.plansAndSubscriptions.summary');
  return (
    <Card>
      <Card.Header title={t('withRP.title')} />

      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: onClick,
        }}
      />
    </Card>
  );
}

export default SummaryError;
