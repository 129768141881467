import { Box } from '@nimbus-ds/components';

export function Divider() {
  return (
    <Box
      width="100%"
      borderTopWidth="1"
      borderColor="neutral-surfaceHighlight"
      borderStyle="solid"
    />
  );
}
