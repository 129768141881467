import { Modal, Text } from '@nimbus-ds/components';
import { CancelAndConfirmButtons } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

const { Header, Body, Footer } = Modal;

interface ConfirmNullStockVariantsModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function ConfirmNullStockVariantsModal({
  show,
  onClose,
  onConfirm,
}: Readonly<ConfirmNullStockVariantsModalProps>) {
  const t = useTranslationCatalog('products.modal.confirmNullStockVariants');
  return (
    <Modal open={show} onDismiss={onClose}>
      <Header title={t('title')} />
      <Body padding="none">
        <Text>{t('body')}</Text>
      </Body>
      <Footer>
        <CancelAndConfirmButtons
          onCancel={onClose}
          onConfirm={onConfirm}
          cancelText={t('cancel')}
          confirmText={t('confirm')}
        />
      </Footer>
    </Modal>
  );
}
