import InputNumber, { InputNumberProps } from 'commons/components/InputNumber';

interface InputAsyncStockProps
  extends Omit<InputNumberProps, 'type' | 'name' | 'value'> {
  value: string | null;
}

function InputStock({ value, ...props }: InputAsyncStockProps): JSX.Element {
  const getAppearance = (value: string | null) => {
    if (value === null) {
      return 'default';
    }
    if (Number(value) <= 0) {
      return 'danger';
    }
    if (Number(value) <= 3) {
      return 'warning';
    }
    return 'default';
  };

  return (
    <InputNumber
      type="integer"
      value={`${value ?? ''}`}
      name="stock"
      appearance={getAppearance(value)}
      {...props}
    />
  );
}

export default InputStock;
