import { Text } from '@nimbus-ds/components';
import {
  IonPageStratus,
  HeaderContent,
} from 'commons/components/IonPageStratus';
import CouponForm from '../../components/CouponForm';
import { Status } from '../CouponListPage/components';

function Skeleton() {
  return (
    <IonPageStratus
      width="medium"
      headerContent={<HeaderContent title="skeleton" />}
    >
      <Text.Skeleton width="150px" />
      <Status.Skeleton />
      <CouponForm.Skeleton />
    </IonPageStratus>
  );
}

export default Skeleton;
