/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { OcaLabelResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getOcaLabels as getOcaLabelsAction,
  getOcaLabelsGenerationStatus as getOcaLabelsGenerationStatusAction,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice';
import {
  getGenerationOcaLabelsToken,
  getOcaLabelsStatus,
} from 'domains/FulfillmentOrders/fulfillmentOrdersSlice/fulfillmentOrdersSelectors';

const MAX_ATTEMPTS = 90;
const INTERVAL_TIME = 5000;

export interface UseGetOcaLabelsResult {
  labels: OcaLabelResponseDto[];
  progress: string | null;
  isLoadingLabels: boolean;
  isErrorLabels: boolean;
  mustWaitGeneration: boolean;
  getOcaLabels: (orders: string[]) => Promise<void>;
}

function useGetOcaLabels(): UseGetOcaLabelsResult {
  const dispatch = useAppDispatch();
  const { isError, isLoading } = useSelector(getOcaLabelsStatus);
  const token = useSelector(getGenerationOcaLabelsToken);
  const [labels, setLabels] = useState<OcaLabelResponseDto[]>([]);
  const [attemptCount, setAttemptCount] = useState(0);
  const [tokenStatus, setTokenStatus] = useState('pending');
  const [progress, setProgress] = useState<string | null>(null);
  const isLoadingLabels = isLoading || (tokenStatus === 'pending' && !!token);
  const isErrorLabels = isError || (tokenStatus === 'error' && !!token);
  const mustWaitGeneration = !!token && tokenStatus === 'pending';

  const getOcaLabels = useCallback(
    async (orders: string[]) => {
      const labels = unwrapResult(await dispatch(getOcaLabelsAction(orders)));
      setLabels(labels);
    },
    [dispatch],
  );

  const getOcaLabelsGenerationStatus = useCallback(
    async (token: string) =>
      unwrapResult(await dispatch(getOcaLabelsGenerationStatusAction(token))),
    [dispatch],
  );

  useEffect(() => {
    const getStatus = async (token: string) => {
      getOcaLabelsGenerationStatus(token).then((processStatus) => {
        setTokenStatus(processStatus.status);
        if (processStatus.status === 'pending' && processStatus.total > 0) {
          setProgress(`${processStatus.processed}/${processStatus.total}`);
        }
      });
      setAttemptCount((prev) => prev + 1);
    };
    if (token && attemptCount === 0) {
      getStatus(token);
    }
    const intervalId = setInterval(() => {
      if (
        !!token &&
        tokenStatus === 'pending' &&
        attemptCount <= MAX_ATTEMPTS
      ) {
        getStatus(token);
      }
    }, INTERVAL_TIME);

    return () => {
      clearInterval(intervalId);
    };
  }, [tokenStatus, token, attemptCount, getOcaLabelsGenerationStatus]);

  return {
    labels,
    progress,
    isLoadingLabels,
    isErrorLabels,
    mustWaitGeneration,
    getOcaLabels,
  };
}

export default useGetOcaLabels;
