import { useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons, Stack } from 'commons/components';
import { useAutoFocus, useForm } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  AccountCard,
  AccountValuesInterface,
  OperationalCard,
  OperationalValuesInterface,
} from './components';
import Skeleton from './Skeleton';
import { ocaConfigarationSchemaStep2 } from './validationSchemaStep2';
import { useOcaAgencies } from '../../hooks';
import { onDirtyType } from '../../types';

export interface ConfigureOcaStep2Values {
  account: AccountValuesInterface;
  operational: OperationalValuesInterface;
}

interface ConfigureOcaStep2Props {
  values: ConfigureOcaStep2Values;
  zipcode: string;
  door: string;
  onChange: (values: ConfigureOcaStep2Values) => void;
  onDirty: onDirtyType;
}

function ConfigureOcaStep2({
  values,
  zipcode,
  door,
  onChange,
  onDirty,
}: ConfigureOcaStep2Props) {
  const t = useTranslationShipping();
  const { goBack } = useNavegate();
  const { ref } = useAutoFocus();

  const { ocaAgencies, ocaAgenciesZipcode } = useOcaAgencies();

  const {
    values: valuesForm,
    errors,
    isDirty,
    handleOnChangeGroup,
    handleOnSubmit,
    setFieldValue,
  } = useForm({
    initialValues: values,
    validationSchema: ocaConfigarationSchemaStep2,
    onSubmit: onChange,
  });

  const handleChangeAgency = (code: string) => {
    const newValues = {
      ...valuesForm.operational,
      agency: {
        code,
        address: ocaAgencies.find((current) => current.code === Number(code))
          ?.description,
      },
    };
    setFieldValue('operational', newValues);
  };

  useEffect(() => {
    if (zipcode !== ocaAgenciesZipcode) {
      handleChangeAgency(`${ocaAgencies[0].code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onDirty('step2', isDirty);
  }, [isDirty, onDirty]);

  return (
    <Stack column align="flex-end" innerRef={ref}>
      <AccountCard
        values={valuesForm.account}
        errors={errors}
        onChange={handleOnChangeGroup}
      />
      <OperationalCard
        values={valuesForm.operational}
        errors={errors}
        agencies={ocaAgencies}
        door={door}
        onChange={handleOnChangeGroup}
        onChangeAgency={handleChangeAgency}
      />
      <CancelAndSaveButtons
        onCancel={goBack}
        onSave={handleOnSubmit}
        saveText={t('oca.config.continue')}
      />
    </Stack>
  );
}

ConfigureOcaStep2.Skeleton = Skeleton;

export default ConfigureOcaStep2;
