import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { AdressesInterface } from '../types';

export const getAddressesState = (
  state: RootStateType,
): CombinedState<AdressesInterface> => state.billing.invoices.address;

export const getCountries = (state: RootStateType) =>
  getAddressesState(state).countries.data || {};

export const getCountriesArray = createSelector(
  getAddressesState,
  (addresses) =>
    Object.keys(addresses.countries).map((k) => ({
      label: addresses.countries[k],
      value: k,
    })),
);

export const getEntityAddresses = (state: RootStateType) =>
  getAddressesState(state).addresses;

export const getAddressesData = (state: RootStateType) =>
  getEntityAddresses(state).data;

export const getAddressesStatus = (state: RootStateType) => {
  const status = getEntityAddresses(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const getProvinces = (state: RootStateType) =>
  getAddressesState(state).provinces;
export const getProvincesData = (state: RootStateType) =>
  getProvinces(state).data;

export const getProvincesStatus = (state: RootStateType) => {
  const status = getProvinces(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const getCities = (state: RootStateType) =>
  getAddressesState(state).cities;
export const getCitiesData = (state: RootStateType, provinceCode: string) =>
  getCities(state).data[provinceCode] || [];

export const getCitiesStatus = (state: RootStateType) => {
  const status = getCities(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const getCityId = (state: RootStateType) =>
  getEntityAddresses(state).data?.cityId;

export const getProvinceId = (state: RootStateType) =>
  getEntityAddresses(state).data?.provinceId;
