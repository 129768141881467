import { Table, Text, Link } from '@nimbus-ds/components';
import { useSelector } from 'react-redux';
import { useNavegate } from 'App/hooks';
import { RootStateType } from 'App/store';
import { CurrencyPrice } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import { getAbandonedCartsListItemById } from 'domains/Orders/AbandonedCart/abandonedCartsSlice/abandonedCartsSelectors';
import {
  AbandonedCartsSendEmail,
  LinkWithIcon,
} from 'domains/Orders/AbandonedCart/pages/AbandonedCartsListPage/components';

interface DraftOrderListHeaderDesktopProps {
  id: string;
}

function AbandonedCartsListDesktopRow({
  id,
}: DraftOrderListHeaderDesktopProps) {
  const { goTo } = useNavegate();
  const abandonedCarts = useSelector((state: RootStateType) =>
    getAbandonedCartsListItemById(state, id),
  );

  const goToCustomer = () => {
    goTo(`/customers/${abandonedCarts.customer.id}`);
  };

  const paymentCount = abandonedCarts.paymentCount ?? 0;

  return (
    <Table.Row id="id">
      <Table.Cell>
        <LinkWithIcon
          id={abandonedCarts.id}
          opportunity={abandonedCarts.opportunity}
          paymentIntents={paymentCount}
        />
      </Table.Cell>
      <Table.Cell>
        <Text>{dateFormat(abandonedCarts.date, Format.DAY_MONTH)}</Text>
      </Table.Cell>
      <Table.Cell>
        <CurrencyPrice
          currency={abandonedCarts.currency}
          price={abandonedCarts.total}
        />
      </Table.Cell>
      <Table.Cell>
        {abandonedCarts.customer.id ? (
          <Link
            as="button"
            appearance="primary"
            onClick={goToCustomer}
            textDecoration="none"
          >
            {abandonedCarts.customer.name}
          </Link>
        ) : (
          <Text>{abandonedCarts.customer.name}</Text>
        )}
      </Table.Cell>
      <Table.Cell>
        <Text>{abandonedCarts.customer.email}</Text>
      </Table.Cell>
      <Table.Cell>
        <AbandonedCartsSendEmail abandonedCart={abandonedCarts} />
      </Table.Cell>
    </Table.Row>
  );
}

export default AbandonedCartsListDesktopRow;
