import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanRecentlyDisabledAction,
  getRecentlyDisabledPaymentProvider,
} from '../../paymentProvidersSlice';

function useRecentlyDisabled() {
  const dispatch = useAppDispatch();

  const recentlyDisabledPaymentProvider = useSelector(
    getRecentlyDisabledPaymentProvider,
  );

  const cleanRecentlyDisabled = useCallback(() => {
    dispatch(cleanRecentlyDisabledAction());
  }, [dispatch]);

  return {
    recentlyDisabledPaymentProviderId: recentlyDisabledPaymentProvider.id,
    cleanRecentlyDisabled,
  };
}

export default useRecentlyDisabled;
