import { Country } from '@tiendanube/common/src/enums';
import { Grid } from '@tiendanube/components';
import ShowByCountry from 'App/components/ShowByCountry';
import HelpLink from 'App/HelpLink';
import {
  CustomersChartCard,
  ConversionOfYourStoreCard,
  GlobalInformationOfYourStoreCard,
  GoogleAnalyticsCard,
  StatisticsPage,
} from 'domains/Statistics/components';
import {
  useGetStatisticsGlobalInfoData,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';

function GeneralStatsPlanThreePage() {
  const t = useTranslationStatistics();
  const { globalInfoStatus, lastUpdate } = useGetStatisticsGlobalInfoData();

  return (
    <StatisticsPage
      title={t('statistics.generalPage.title')}
      lastUpdate={lastUpdate}
      isLoading={globalInfoStatus === 'loading'}
    >
      <GlobalInformationOfYourStoreCard />
      <Grid>
        <Grid.Row>
          <Grid.Column sm={6} md={6} lg={3}>
            <ConversionOfYourStoreCard />
          </Grid.Column>
          <Grid.Column sm={6} md={6} lg={3}>
            <CustomersChartCard />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <GoogleAnalyticsCard />
      <ShowByCountry excludeCountries={[Country.BR]}>
        <HelpLink
          title={t('statistics.helpLink.aboutStats')}
          linkURL={t('statistics.helpLink.aboutStatsUrl')}
          previousValue=""
          currentViewTracking={t('statistics.aboutProductsTrackingName')}
          icon="both"
        />
      </ShowByCountry>
    </StatisticsPage>
  );
}

export default GeneralStatsPlanThreePage;
