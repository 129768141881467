import {
  Card,
  Text,
  Title,
  Alert,
  Table,
  Box,
  IconButton,
} from '@nimbus-ds/components';
import { CloseIcon } from '@nimbus-ds/icons';
import { CustomHeaderCell, ErrorScreen, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetCurrenciesQuery } from 'domains/Configurations/I18n/i18nApi';
import { currencyExchangeRate } from 'domains/Configurations/I18n/utils';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import ExchangeRateList from './ExchangeRateList';
import {
  WIDTH_CELL_CURRENCY_EQUIVALENT,
  WIDTH_CELL_SUGGESTION,
} from '../../utils/constants';

function ExchangeRatesCard() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const {
    data,
    isLoading: loadingGetCurrencies,
    isSuccess,
    isFetching,
    isError,
    refetch,
  } = useGetCurrenciesQuery();
  const currencyList = currencyExchangeRate(data);

  const [showAlert, _, closeAlert] = useModal(true);

  const isLoading = isFetching || loadingGetCurrencies;

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Title as="h3">{t('exchangeRates.title')}</Title>
      </Card.Header>
      <Card.Body padding="base">
        <Box display="grid" gridGap="4" paddingBottom="4">
          <Text>{t('exchangeRates.subtitle')}</Text>
          {showAlert && (
            <Alert appearance="primary">
              <Stack justify="space-between" align="flex-start">
                <Text>{t('exchangeRates.alert')}</Text>
                <IconButton
                  source={<CloseIcon size="small" />}
                  borderColor="transparent"
                  backgroundColor="transparent"
                  size="1rem"
                  onClick={closeAlert}
                />
              </Stack>
            </Alert>
          )}
          {isLoading && !data && <ExchangeRateList.Skeleton />}
          {isSuccess && !!currencyList && (
            <Table>
              <Table.Head>
                <CustomHeaderCell width={WIDTH_CELL_CURRENCY_EQUIVALENT}>
                  {t('exchangeRates.table.currency')}
                </CustomHeaderCell>
                <CustomHeaderCell width={WIDTH_CELL_CURRENCY_EQUIVALENT}>
                  {t('exchangeRates.table.equivalent')}
                </CustomHeaderCell>
                <CustomHeaderCell width={WIDTH_CELL_SUGGESTION} />
              </Table.Head>
              <ExchangeRateList currencyList={currencyList} />
            </Table>
          )}
          {isError && (
            <Box paddingBottom="4">
              <ErrorScreen onRetry={refetch} description={t('errorState')} />
            </Box>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default ExchangeRatesCard;
