import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import useStatisticsStoredFilters from 'domains/Statistics/hooks/useStatisticsStoredFilters';
import {
  getRankProvincesByShippingCostData,
  getRankProvincesByShippingCostStatus,
} from 'domains/Statistics/selectors';
import { fetchRankProvincesByShippingCost as fetchRankProvincesByShippingCostAction } from 'domains/Statistics/statisticsSlice';

function useGetRankProvincesByShippingCost() {
  const dispatch = useAppDispatch();
  const rankProvincesByShippingCost = useSelector(
    getRankProvincesByShippingCostData,
  );
  const status = useSelector(getRankProvincesByShippingCostStatus);

  const fetchRankProvincesByShippingCost = useCallback(() => {
    dispatch(fetchRankProvincesByShippingCostAction());
  }, [dispatch]);

  useStatisticsStoredFilters(fetchRankProvincesByShippingCost);

  return {
    fetchRankProvincesByShippingCost,
    rankProvincesByShippingCost,
    ...status,
  };
}

export default useGetRankProvincesByShippingCost;
