import { Alert, Icon, Link, Text, Title } from '@nimbus-ds/components';
import {
  SettingOptionDto,
  UpdateMercadoPagoSettingsRequestDto,
} from '@tiendanube/common';
import { BaseCard, InterfaceNameValue } from '@tiendanube/components';
import { UserIcon } from '@tiendanube/icons';
import Stack from 'commons/components/Stack';
import { useModal } from 'commons/hooks';
import { InterfaceNameBooleanValue } from 'commons/types';
import { handleClickExternal } from 'commons/utils/dom';
import { ModalConfirmationWithAuth } from 'domains/AuthenticationFactor';
import { useGetMercadoPagoAuthorizationUrl } from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProvider.settings.mercadopago.account';

interface AccountCardProps {
  username: string;
  email: string;
  countries: SettingOptionDto[];
  currencies: SettingOptionDto[];
  values: UpdateMercadoPagoSettingsRequestDto;
  handleOnChange: (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function AccountCard({ username, email }: AccountCardProps): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const { getMercadoPagoAuthorizationUrl, isLoading } =
    useGetMercadoPagoAuthorizationUrl(true);
  const [showModalChangeUser, openModalChangeUser, closeModalChangeUser] =
    useModal();

  const handleChangeUser = async () => {
    const authUrl = await getMercadoPagoAuthorizationUrl();
    handleClickExternal(authUrl)();
  };

  return (
    <>
      <BaseCard>
        <BaseCard.Header>
          <Title as="h3">{t('title')}</Title>
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column align="stretch">
            {!!username && !!email && (
              <Stack.Item>
                <Text fontSize="caption">{t('info')}</Text>
                <Text>{`${username} (${email})`}</Text>
              </Stack.Item>
            )}
            <Alert>{t('changeUserAlert')}</Alert>
            {isLoading ? (
              <Link.Skeleton width="140px" />
            ) : (
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={openModalChangeUser}
              >
                <Icon color="currentColor" source={<UserIcon />} />
                {t('changeUser')}
              </Link>
            )}
          </Stack>
        </BaseCard.Body>
      </BaseCard>

      {showModalChangeUser && (
        <ModalConfirmationWithAuth
          title={t('changeUser')}
          text={t('changeUserAlert')}
          labelConfirm={t('changeUserConfirm')}
          labelCancel={t('changeUserCancel')}
          onCancel={closeModalChangeUser}
          onConfirm={handleChangeUser}
        />
      )}
    </>
  );
}

export default AccountCard;
