import { ReactComponent as LogoEvolucion } from './logo-evolucion.svg';
import { ReactComponent as LogoNext } from './logo-next.svg';
import { ReactComponent as LogoNuvemshop } from './logo-nuvemshop.svg';
import { ReactComponent as LogoTiendanube } from './logo-tiendanube.svg';

export interface LogoProps {
  height?: number;
  color?: string;
  src: 'Nuvemshop' | 'Tiendanube' | 'Next' | 'Evolucion';
}

function Logo({ height = 20, color, src }: LogoProps): JSX.Element | null {
  switch (src) {
    case 'Tiendanube':
      return <LogoTiendanube height={height} color={color} />;
    case 'Nuvemshop':
      return <LogoNuvemshop height={height} color={color} />;
    case 'Next':
      return <LogoNext height={height} color={color} />;
    case 'Evolucion':
      return <LogoEvolucion height={height} color={color} />;
    default:
      return null;
  }
}

export default Logo;
