import { useCallback, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import {
  AddressCard,
  AddressCardErrorsInterface,
  AddressCardValuesInterface,
  InformationCard,
  InformationCardErrorsInterface,
  InformationCardValuesInterface,
} from '..';
import SalesChannelCard, {
  SalesChannelCardValuesInterface,
} from '../SalesChannelCard/SalesChannelCard';

interface LocationValuesInterface
  extends InformationCardValuesInterface,
    AddressCardValuesInterface,
    SalesChannelCardValuesInterface {}

interface LocationErrorsInterface
  extends InformationCardErrorsInterface,
    AddressCardErrorsInterface {}

interface FormLocationProps {
  values: LocationValuesInterface;
  errors: Partial<LocationErrorsInterface>;
  countries: { value: string; label: string }[];
  provinces: { value: string; label: string }[];
  cities: { value: string; label: string }[];
  loadingCountries: boolean;
  loadingProvinces: boolean;
  loadingCities: boolean;
  fetchCountries: () => void;
  fetchProvinces: (country: string) => Promise<string>;
  fetchCities: (countryCode: string, provinceCode: string) => Promise<string>;
  handleChange: (event: { name: string; value: string }) => void;
  setFieldValue: (name: string, value: any) => void;
}

function FormLocation({
  values,
  errors,
  countries,
  provinces,
  cities,
  loadingCountries,
  loadingProvinces,
  loadingCities,
  fetchCountries,
  fetchProvinces,
  fetchCities,
  handleChange,
  setFieldValue,
}: FormLocationProps): JSX.Element {
  const { scrollToError } = useLayoutScroll();
  const hasShippingLocationTag = useHasShippingLocationTag();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    handleChange(e.target);
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToError();
    }
  }, [errors, scrollToError]);

  const handleOnCheckBoxChange = (name: string) => {
    const tags = values.tags.includes(name)
      ? values.tags.filter((tag) => name !== tag)
      : [...values.tags, name];
    setFieldValue('tags', tags);
  };

  const clearFields = useCallback(() => {
    setFieldValue('province', '');
    setFieldValue('city', '');
    setFieldValue('locality', '');
    setFieldValue('street', '');
    setFieldValue('number', '');
    setFieldValue('floor', '');
    setFieldValue('complement', '');
    setFieldValue('zipcode', '');
  }, [setFieldValue]);

  return (
    <>
      <InformationCard
        onChange={handleOnChange}
        values={values}
        errors={errors}
      />
      <AddressCard
        onChange={handleOnChange}
        values={values}
        errors={errors}
        countries={countries}
        provinces={provinces}
        cities={cities}
        loadingCountries={loadingCountries}
        loadingProvinces={loadingProvinces}
        loadingCities={loadingCities}
        fetchCountries={fetchCountries}
        fetchProvinces={fetchProvinces}
        fetchCities={fetchCities}
        setFieldValue={setFieldValue}
        clearFields={clearFields}
      />
      {hasShippingLocationTag && (
        <SalesChannelCard values={values} onChange={handleOnCheckBoxChange} />
      )}
    </>
  );
}

export default FormLocation;
