import { Box, Card, Link, Text, Thumbnail, Title } from '@nimbus-ds/components';
import { generatePath, Link as LinkRoute } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import ShowByCountry from 'App/components/ShowByCountry';
import { useGetEcommerceNaPraticaQuery } from 'domains/Dashboard/dashboardApi';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingEnpClickNewOnboarding202411 } from 'domains/Dashboard/tracking';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import ImageSrc from './enp-image-2.png';

const ECOMMERCE_APP_ID = '1006';

function NewEcommerceNaPraticaCard() {
  const t = useTranslationDashboard('improveStore_202411.e-commerce');

  const { data, isLoading } = useGetEcommerceNaPraticaQuery();

  if (isLoading) return null;
  // This component duplicates EcommerceNaPraticaCard to implement New Onboarding feature [NA-5773]. Should be unified after testing results.
  return (
    <ShowByCountry allowedCountries={[Country.BR]}>
      <Box>
        <Card>
          <Card.Body>
            <Box
              display="flex"
              flexDirection={{
                lg: 'row',
                md: 'row',
                xs: 'column',
              }}
              gap="4"
            >
              <Box
                minWidth={{
                  md: '300px',
                }}
                maxWidth={{
                  lg: '372px',
                }}
              >
                <Thumbnail aspectRatio="16/9" alt="image" src={ImageSrc} />
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                gap="4"
                justifyContent="center"
                maxWidth="703px"
              >
                <Box display="flex" flexDirection="column" gap="2">
                  <Title as="h3">{t('title')}</Title>
                  <Text>{t('description')}</Text>
                </Box>
                {!data?.hasInstalled ? (
                  <Link
                    textDecoration="none"
                    as={LinkRoute}
                    to={generatePath(partnersAppsRoutes.authorization, {
                      id: ECOMMERCE_APP_ID,
                    })}
                    appearance="primary"
                    onClick={trackingEnpClickNewOnboarding202411(
                      'primeira-venta',
                    )}
                  >
                    {t('linkText')}
                  </Link>
                ) : (
                  <Link
                    textDecoration="none"
                    appearance="primary"
                    as="a"
                    target="_blank"
                    href="https://escola.ecommercenapratica.com/166494-1-venda-na-nuvemshop-curso-expresso"
                  >
                    {t('linkText')}
                  </Link>
                )}
              </Box>
            </Box>
          </Card.Body>
        </Card>
      </Box>
    </ShowByCountry>
  );
}

export default NewEcommerceNaPraticaCard;
