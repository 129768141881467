import { useLocation } from 'react-router';
import {
  MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  MIGRATION_PRODUCTS_FORCE,
  MIGRATION_ORDERS_EXPORT_FORCE,
  MIGRATION_ABANDONED_CARTS_FORCE,
  MIGRATION_ORDER_DETAIL_FORCE,
  MIGRATION_ORDERS_LIST_FORCE,
} from 'App/featuresFlags';
import { ROUTE_MAPPING, TAGS_MIGRATE } from 'App/migrateConfig';
import { handleClickExternal } from 'commons/utils/dom';
import { useGetTags } from 'domains/Auth/hooks';

interface BackPreviousVersion {
  goToPreviosVersion: () => void;
  hasBackPreviousVersion: boolean;
}

const productPages = ['products', 'products-detail'];
const ordersPages = ['ordersexport'];
const abandonedCartsPages = ['abandoned-carts'];
const backToPreviousVersionTags = [
  TAGS_MIGRATE.products,
  TAGS_MIGRATE.productsnew,
  TAGS_MIGRATE.orders,
  TAGS_MIGRATE['orders-detail'],
  TAGS_MIGRATE['abandoned-carts'],
  TAGS_MIGRATE.ordersexport,
];

function useBackPreviousVersion(): BackPreviousVersion {
  const tags = useGetTags();
  const { pathname: currentPathPage } = useLocation();

  const routeName = currentPathPage
    .replace(/\d+/g, '-detail')
    .replace(/\//g, '');

  const posibleId = currentPathPage.match(/\d+/g);

  const pathToOldAdmin = ROUTE_MAPPING[routeName]?.replace(':id', posibleId);

  const tagToPathname = TAGS_MIGRATE[routeName];

  const hasMigrateTag = tagToPathname ? tags.includes(tagToPathname) : false;

  const hasOrdersExportForceTag =
    tags.includes(MIGRATION_ORDERS_EXPORT_FORCE) &&
    ordersPages.includes(routeName);

  const hasAbandonedCartsForceTag =
    tags.includes(MIGRATION_ABANDONED_CARTS_FORCE) &&
    abandonedCartsPages.includes(routeName);

  const hasOrderDetailForceTag =
    tags.includes(MIGRATION_ORDER_DETAIL_FORCE) &&
    routeName === 'orders-detail';

  const hasOrdersListForceTag =
    tags.includes(MIGRATION_ORDERS_LIST_FORCE) && routeName === 'orders';

  const hasOrdersForceTag =
    hasOrdersExportForceTag ||
    hasAbandonedCartsForceTag ||
    hasOrdersListForceTag ||
    hasOrderDetailForceTag;

  const hasForceTag =
    (tags.includes(MIGRATION_PRODUCTS_FORCE) &&
      productPages.includes(routeName)) ||
    hasOrdersForceTag;

  const hasBackPreviousVersion =
    tags.includes(MIGRATION_ENABLE_ALL_MIGRATED_PAGES) || hasForceTag
      ? false
      : hasMigrateTag && backToPreviousVersionTags.includes(tagToPathname);

  const urlToBackPreviousVersion = `/admin/${pathToOldAdmin}/?new-admin=opt-out`;
  const goToPreviosVersion = handleClickExternal(urlToBackPreviousVersion);

  return {
    goToPreviosVersion,
    hasBackPreviousVersion,
  };
}

export default useBackPreviousVersion;
