import { useMemo } from 'react';
import { Text } from '@nimbus-ds/components';
import { i18nResponseDto } from '@tiendanube/common';
import {
  CancelAndSaveButtons,
  TabsMultiLanguage,
  Stack,
} from 'commons/components';
import { useForm } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { AltTextGenerationContext } from './components/AltTextGenerationModal';
import EditTextAltForm from './components/EditTextAltForm';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditImageBaseModal from '../../../EditImageBaseModal';

interface EditAltTextModalProps {
  image: ImageGalleryState;
  altTextGenerationContext: AltTextGenerationContext;
  onChange: (value: i18nResponseDto) => void;
  isShow?: boolean;
  onClose?: () => void;
}

function EditAltTextModal({
  image,
  altTextGenerationContext,
  onChange,
  isShow = false,
  onClose,
}: Readonly<EditAltTextModalProps>) {
  const t = useTranslationCatalog('products.modal.editPhoto.actions.altText');
  const defaultValue = image.alt;
  /*
   * we memoize the initialValues to avoid the "Maximum update depth exceeded" error when useForm checks the data
   */
  const initialValues = useMemo(
    () => ({
      altText: defaultValue,
    }),
    [defaultValue],
  );

  const handleOnClose = () => {
    resetValues();
    if (onClose) {
      onClose();
    }
  };

  const { values, setFieldValue, isDirty, handleOnSubmit, resetValues } =
    useForm({
      initialValues,
      onSubmit({ altText }) {
        onChange(altText);
        handleOnClose();
      },
    });

  const handleOnChange = (value: i18nResponseDto) => {
    setFieldValue('altText', value);
  };

  const imageUrl = image.publicUrl || image.src;

  return (
    <EditImageBaseModal
      isShow={isShow}
      displayImageSource={image}
      onClose={handleOnClose}
      onHeaderActionClick={handleOnSubmit}
      translationPrefix="products.modal.editPhoto.actions.altText.modal"
      beforeImageChildren={
        <Text color="neutral-textLow">{t('modal.subtitle')}</Text>
      }
      afterImageChildren={
        <Stack column align="stretch" spacing="tight">
          <TabsMultiLanguage>
            <EditTextAltForm
              value={values.altText}
              imageUrl={imageUrl}
              altTextGenerationContext={altTextGenerationContext}
              onChange={handleOnChange}
            />
          </TabsMultiLanguage>
          <CancelAndSaveButtons
            saveText={t('modal.confirm')}
            isDisabled={!isDirty}
            onSave={handleOnSubmit}
            onCancel={handleOnClose}
          />
        </Stack>
      }
    />
  );
}

export default EditAltTextModal;
