import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { getStatistics } from '../statisticsSelectors';

const getStatisticsProductsSummaryEntity = createSimpleSelector(
  [getStatistics],
  (statistics) => statistics.productsSummary,
);

export const getStatisticsProductsSummaryStatus = createSimpleSelector(
  [getStatisticsProductsSummaryEntity],
  (productsSummary) => productsSummary.status,
);

export const getStatisticsProductsSummaryData = createSimpleSelector(
  [getStatisticsProductsSummaryEntity],
  (productsSummary) => productsSummary.data,
);
