import { Text } from '@nimbus-ds/components';
import { CouponDiscountType as CouponDiscountTypeType } from '@tiendanube/common';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface CouponDiscountTypeProps {
  type: CouponDiscountTypeType;
  value: number;
}

export function CouponDiscountType({
  type,
  value,
}: Readonly<CouponDiscountTypeProps>) {
  const t = useTranslationMarketing();
  const { symbol } = useGetCurrency();
  const currencySymbol = type === 'absolute' ? symbol : '';
  const percentageSymbol = type === 'percentage' ? '%' : '';
  const valueContent =
    type !== 'shipping' ? value : t('coupons.details.freeShipping');

  return (
    <Text>
      {currencySymbol} {valueContent} {percentageSymbol}
    </Text>
  );
}
