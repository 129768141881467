import { useEffect, ReactElement } from 'react';
import { useLocation } from 'react-router';
import { ROUTE_MAPPING, TAGS_MIGRATE_OLD } from 'App/migrateConfig';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useGetSyncStatus,
  useGetTags,
  useIsMobileDevice,
} from 'domains/Auth/hooks';

interface ToggleMigratedOldDomainProps {
  domain: keyof typeof ROUTE_MAPPING;
  children: ReactElement;
}

function ToggleMigratedOldDomain({
  domain,
  children,
}: ToggleMigratedOldDomainProps) {
  const tags = useGetTags();
  const { pathname } = useLocation();
  const isMobileDevice = useIsMobileDevice();
  const statusSync = useGetSyncStatus();

  useEffect(() => {
    if (statusSync !== 'success') {
      return;
    }

    if (isMobileDevice) return;

    if (!tags.includes(TAGS_MIGRATE_OLD[domain])) return;

    const possibleId = pathname.match(/\d+/g);
    const pathToOldAdmin = ROUTE_MAPPING[domain]?.replace(
      ':id',
      possibleId ? possibleId[0] : '',
    );
    goToAdmin(pathToOldAdmin)();
  }, [domain, isMobileDevice, pathname, statusSync, tags]);

  return children;
}

export default ToggleMigratedOldDomain;
