export const TRANSACTION_FEES_BASE_ROUTE = '/account/transaction-fees';

export const TRANSACTION_FEES_ROUTES = {
  transactionFees: `${TRANSACTION_FEES_BASE_ROUTE}/`,
  transactionFeesSplitList: `${TRANSACTION_FEES_BASE_ROUTE}/current-split`,
  transactionFeesNoSplitList: `${TRANSACTION_FEES_BASE_ROUTE}/current-no-split`,
  transactionFeesMonthSelection: `${TRANSACTION_FEES_BASE_ROUTE}/year/:year`,
  transactionFeesSplitMonthly: `${TRANSACTION_FEES_BASE_ROUTE}/detail/split/:paymentId`,
  transactionFeesNoSplitMonthly: `${TRANSACTION_FEES_BASE_ROUTE}/detail/no-split/:paymentId`,
};
