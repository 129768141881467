import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InterfaceSelectOption } from '@tiendanube/components/dist/common/interfaces';
import { useAppDispatch } from 'App/store';
import { getOrdersFilters } from 'domains/Orders/Orders/ordersSlice';
import {
  getApplicationFiltersData,
  getPaymentMethodsFiltersData,
  getPaymentProvidersFiltersData,
} from 'domains/Orders/Orders/ordersSlice/ordersSelectors';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { paymentProvider } from '../../ordersService/constants';
import { APP_ID_CUSTOM_PAYMENTS } from '../../pages/constants';

function useGetOrdersFilters() {
  const dispatch = useAppDispatch();
  const paymentMethodsOrdersFilters = useSelector(getPaymentMethodsFiltersData);
  const paymentProvidersOrdersFilters = useSelector(
    getPaymentProvidersFiltersData,
  );
  const applicationOrdersFilters = useSelector(getApplicationFiltersData);

  const fetchOrdersFilters = useCallback(
    () => dispatch(getOrdersFilters()),
    [dispatch],
  );

  const t = useTranslationOrders();

  const getApplicationOptions = () => {
    const applicationAllOption: InterfaceSelectOption = {
      value: '',
      label: t('filters.application.all'),
    };

    return [
      applicationAllOption,
      ...applicationOrdersFilters.map((app) => ({
        value: app.id,
        // Default should never happen since application name is always set. Only payment method's name could be null
        label: app.name ?? '',
      })),
    ];
  };

  const getPaymentMethodOptions = () => {
    const paymentMethodAllOption: InterfaceSelectOption = {
      value: '',
      label: t('filters.paymentMethod.all'),
    };

    return [
      paymentMethodAllOption,
      ...paymentMethodsOrdersFilters.map((method) => ({
        label: t(`filters.paymentMethod.${method.id}`),
        value: method.id,
      })),
    ];
  };

  const getPaymentProviderOptions = () => {
    const paymentProviderAllOption: InterfaceSelectOption = {
      value: '',
      label: t('filters.paymentProvider.all'),
    };

    const customProviders = paymentProvider.map((customProvider) => ({
      value: customProvider,
      label: t(`filters.paymentProvider.${customProvider}`),
    }));

    return [
      paymentProviderAllOption,
      ...paymentProvidersOrdersFilters
        .map((provider) => ({
          value: provider.id,
          // Default should never happen since provider name is always set. Only payment method's name could be null
          label: provider?.name ?? '',
        }))
        .filter((appId) => appId.value !== APP_ID_CUSTOM_PAYMENTS),
      ...customProviders,
    ];
  };

  const paymentMethodOptions = getPaymentMethodOptions();
  const paymentProviderOptions = getPaymentProviderOptions();
  const applicationOptions = getApplicationOptions();

  return {
    paymentMethodOptions,
    paymentMethodsOrdersFilters,
    paymentProviderOptions,
    paymentProvidersOrdersFilters,
    applicationOptions,
    applicationOrdersFilters,
    fetchOrdersFilters,
  };
}

export default useGetOrdersFilters;
