/**
 * We define the list of domains that ignore the auto login in web (non mobile)
 * the rest of the domains as:
 * - lojavirtualnuvem.com.br
 * - mitiendanube.com
 * - nubestaging.com
 * - nubestaging.com.br
 * - nubestaging.com.mx
 * - [feature-branch].nubestaging.com
 */
export const DOMAINS_IGNORED_TO_ADMIN_WEB = [
  //feature branch stratus
  'stratus\\.([a-z0-9-]*.)?nubestaging.com',
  //develop branch stratus
  'stratus.nubestaging.com',
  //develop and mobile app
  'localhost',
];

export const DOMAINS_ENABLED_TO_ADMIN_WEB = [
  'lojavirtualnuvem.com.br',
  'mitiendanube.com',
  'nubestaging.com',
  'localnube.com',
];

export const PAGES_WITH_CODE_PARAM = [
  '/auth/verification',
  '/settings/payments/mercadopago',
  '/settings/payments/mercadopago/enable',
];
