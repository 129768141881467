import { useEffect, useState } from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';

function OnboardingCompletedAlert() {
  const t = useTranslationDashboard();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const alertShown = localStorage.getItem('onboarding-202411-completed');
    if (!alertShown) {
      setShow(true);
    }
  }, []);

  const handleRemove = () => {
    localStorage.setItem('onboarding-202411-completed', 'true');
    setShow(false);
  };

  return (
    <Alert
      appearance="success"
      show={show}
      title={t('onboardingCompleteAlert.title')}
      onRemove={handleRemove}
    >
      <Text>{t('onboardingCompleteAlert.description')}</Text>
    </Alert>
  );
}

export default OnboardingCompletedAlert;
