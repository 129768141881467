import { Box, Title } from '@nimbus-ds/components';
import { NotificationsListItemResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import EmailItemCard from './EmailItemCard';

interface EmailsSectionProps {
  title: string;
  emails: NotificationsListItemResponseDto[];
}

function EmailsSection({ title, emails }: Readonly<EmailsSectionProps>) {
  return (
    <Stack column align="flex-start" spacing="tight">
      <Title as="h3">{title}</Title>
      <Box
        display="grid"
        gridTemplateColumns={{
          lg: '1fr 1fr 1fr 1fr',
          md: '1fr',
          xs: '1fr',
        }}
        gridGap="4"
      >
        {emails.map((email) => (
          <EmailItemCard key={email.id} email={email} />
        ))}
      </Box>
    </Stack>
  );
}

export default EmailsSection;
