import { Box, IconButton } from '@nimbus-ds/components';
import { EditIcon, TrashIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { accountRoutes } from 'domains/Account';
import { useDeleteUserMutation } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import DeleteUserModal from './DeleteUserModal';

interface UsersListActionsProps {
  userId: string;
  isCurrentUser: boolean;
  isOwner: boolean;
}

function UsersListActions({
  userId,
  isCurrentUser,
  isOwner,
}: Readonly<UsersListActionsProps>) {
  const t = useTranslationConfigurations('usersListPage');
  const { goTo } = useNavegate();
  const [deleteUser, { status }] = useDeleteUserMutation();
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();

  const deleteStatus = toStatusType(status);

  useToastStatus({
    status: deleteStatus,
    success: t('deleteModal.toast.success'),
    error: t('deleteModal.toast.error'),
    onSuccess: closeDeleteModal,
    onError: closeDeleteModal,
  });

  const onEdit = () =>
    goTo(getUrlWithParams(accountRoutes.userForm, { id: userId }));
  const onDelete = () => deleteUser(userId);

  return (
    <>
      <Box width="fit-content">
        <Stack spacing="tight">
          <IconButton size="2rem" source={<EditIcon />} onClick={onEdit} />
          {!isCurrentUser && !isOwner && (
            <IconButton
              size="2rem"
              source={<TrashIcon />}
              onClick={openDeleteModal}
            />
          )}
        </Stack>
      </Box>
      <DeleteUserModal
        isOpen={showDeleteModal}
        isLoading={deleteStatus === 'loading'}
        onClose={closeDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
}

export default UsersListActions;
