import { useContext } from 'react';
import {
  StockHistoryContext,
  StockHistoryContextResult,
} from '../../StockHistoryProvider';

function useStockHistoryContext(): StockHistoryContextResult {
  return useContext(StockHistoryContext);
}

export default useStockHistoryContext;
