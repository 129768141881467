import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Select,
  InterfaceSelectOptionGroup,
  InterfaceNameValue,
} from '@tiendanube/components';
import { useHiddenCategories } from 'domains/Catalog/hooks';

interface OnChangeValue {
  name: string;
  value: string;
}

interface CategoriesProps {
  value: string;
  onChange: ({ name, value }: OnChangeValue) => void;
}
function Categories({ value, onChange }: CategoriesProps): JSX.Element {
  const { t } = useTranslation([Domain.CATALOG]);

  const { categoriesSelectOptions } = useHiddenCategories();

  const options = [
    { value: '', label: t(`products.filters.categoryId.all`) },
    {
      value: 'has_no_categories',
      label: t(`products.filters.categoryId.hasNoCategories`),
    },
  ];

  const labelOptions: InterfaceSelectOptionGroup[] = [
    ...options,
    ...categoriesSelectOptions,
  ];

  const handleChange = (data: InterfaceNameValue) => {
    onChange(data as OnChangeValue);
  };

  return (
    <Select
      label={t('products.filter.categoryId')}
      name="categoryId"
      value={value}
      options={labelOptions}
      onChange={handleChange}
    />
  );
}

export default Categories;
