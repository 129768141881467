import { useRef, useState, ChangeEvent } from 'react';
import {
  Alert,
  Box,
  Card,
  FileUploader,
  Text,
  Title,
} from '@nimbus-ds/components';
import { CategoryDetailsResponseDto } from '@tiendanube/common';
import { AlertImagesValidation } from 'commons/components';
import getImageMimeType from 'commons/utils/getImageMimeType/getImageMimeType';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingCategoriesDescriptionImageClick } from 'domains/Catalog/Products/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import ButtonsActions from './ButtonsActions';
import ImageThumbnail from './ImageThumbnail';
import Skeleton from './Skeleton';
import { ImageInterface } from '../../hooks/useCategory/useCategory';

const ACCEPT_TYPE_FILE = 'image/jpeg,image/gif,image/png,image/webp';
const ACCEPT_TYPE_FILE_ARRAY = ACCEPT_TYPE_FILE.split(',');

interface FeaturedImageCardProps {
  image: CategoryDetailsResponseDto['image'];
  uploadImage: (file: File) => Promise<ImageInterface>;
  onChangeImage: (image: ImageInterface | null) => void;
}

function FeaturedImageCard({
  image,
  uploadImage,
  onChangeImage,
}: FeaturedImageCardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [imagesWithBadType, setImagesWithBadType] = useState<string[]>([]);
  const [imagesExceededSize, setImagesExceededSize] = useState<string[]>([]);

  const temporalImageRef = useRef<File>();
  const t = useTranslationCatalog();
  const inputRef = useRef<HTMLInputElement>(null);

  const sender = useTrackFullCatalog();

  const handleOnClick = () => {
    inputRef.current?.click();
  };

  const handleUploadImage = async (file: File) => {
    try {
      const previewImage = URL.createObjectURL(file);
      temporalImageRef.current = file;
      onChangeImage({ id: '', name: '', url: previewImage });
      const image = await uploadImage(file);
      onChangeImage({ id: image.id, name: image.name, url: image.url });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (
        !ACCEPT_TYPE_FILE_ARRAY.includes(file.type) ||
        !ACCEPT_TYPE_FILE_ARRAY.includes(await getImageMimeType(file))
      ) {
        setImagesWithBadType([file.name]);
        return;
      }
      if (file.size / 1024 / 1024 >= 10) {
        setImagesExceededSize([file.name]);
        return;
      }
      setImagesWithBadType([]);
      setImagesExceededSize([]);
      setIsLoading(true);
      handleUploadImage(file);
    }
  };

  const handleOnError = () => {
    setIsError(false);
    setIsLoading(true);
    if (temporalImageRef.current) {
      handleUploadImage(temporalImageRef.current);
    }
  };

  const handleDeleteImage = () => {
    onChangeImage(null);
  };

  const handleClick = () => {
    sender(trackingCategoriesDescriptionImageClick);
  };

  const isDeleteImage = image === null;
  const showImage = image?.url || '';
  const showImageThumbnail = showImage && !isDeleteImage;

  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{t('categories.detail.featuredImage.title')}</Title>
          {!!showImage && !isDeleteImage && (
            <ButtonsActions
              handleOnClick={handleOnClick}
              handleDeleteFeaturedImage={handleDeleteImage}
            />
          )}
          <input
            className="nimbus--thumbnail-file"
            ref={inputRef}
            type="file"
            accept={ACCEPT_TYPE_FILE}
            id="thumbnail-file"
            name="thumbnail-file"
            onChange={onChangeFile}
          />
        </Box>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="2-5">
        <Text>{t('categories.detail.featuredImage.description')}</Text>
        <AlertImagesValidation
          filenames={imagesWithBadType}
          validation="type"
        />
        <AlertImagesValidation
          filenames={imagesExceededSize}
          validation="size"
        />
        {(!!showImageThumbnail || isLoading) && (
          <ImageThumbnail
            url={showImage}
            isLoading={isLoading}
            isError={isError}
            onRetry={handleOnError}
          />
        )}
        {!showImageThumbnail && (
          <FileUploader
            placeholder={t('categories.detail.featuredImage.uploadImage')}
            width="311px"
            height="155.5px"
            onChange={onChangeFile}
            accept={ACCEPT_TYPE_FILE}
            onClick={handleClick}
          />
        )}
        <Alert
          appearance="primary"
          title={t('categories.detail.featuredImage.alertTitle')}
        >
          {t('categories.detail.featuredImage.alertDescription')}
        </Alert>
      </Box>
    </Card>
  );
}

FeaturedImageCard.Skeleton = Skeleton;

export default FeaturedImageCard;
