import { Button, Stack } from '@tiendanube/components';

function Skeleton(): JSX.Element {
  return (
    <Stack spacing="base" justify="flex-end">
      <Stack.Item>
        <Button.Skeleton />
      </Stack.Item>
      <Stack.Item>
        <Button.Skeleton />
      </Stack.Item>
    </Stack>
  );
}

export default Skeleton;
