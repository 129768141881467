export enum ProductsSortByEnum {
  PRICE_ASCENDING = "price-ascending",
  PRICE_DESCENDING = "price-descending",
  ALPHA_ASCENDING = "alpha-ascending",
  ALPHA_DESCENDING = "alpha-descending",
  CREATED_AT_ASCENDING = "created-at-ascending",
  CREATED_AT_DESCENDING = "created-at-descending",
  BEST_SELLING = "best-selling",
  CUSTOM = "user",
}
