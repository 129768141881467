import { Box, BoxProperties, Button, ButtonProps } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import Skeleton from './Skeleton';
import SubmitButton from '../SubmitButton';

export interface CancelAndConfirmButtonsProps {
  isLoading?: boolean;
  isCancelDisabled?: boolean;
  isConfirmDisabled?: boolean;
  cancelText?: string;
  confirmText?: React.ReactNode;
  appearance?: ButtonProps['appearance'];
  type?: ButtonProps['type'];
  containerBoxProps?: BoxProperties;
  onCancel: () => void;
  onConfirm?: () => void;
}

function CancelAndConfirmButtons({
  isLoading,
  isCancelDisabled = false,
  isConfirmDisabled = false,
  cancelText,
  confirmText,
  appearance = 'primary',
  containerBoxProps,
  onCancel,
  onConfirm,
  type,
}: CancelAndConfirmButtonsProps): JSX.Element {
  const { t } = useTranslation('common');

  const cancelButtonText = cancelText || t('cancelAndSaveButtons.cancel');
  const confirmButtonText = confirmText || t('cancelAndSaveButtons.save');

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="2"
      justifyContent="flex-end"
      {...containerBoxProps}
    >
      <Button type="button" disabled={isCancelDisabled} onClick={onCancel}>
        {cancelButtonText}
      </Button>
      <SubmitButton
        type={type}
        onClick={onConfirm}
        isLoading={isLoading}
        disabled={isConfirmDisabled}
        appearance={appearance}
      >
        {confirmButtonText}
      </SubmitButton>
    </Box>
  );
}

CancelAndConfirmButtons.Skeleton = Skeleton;

export default CancelAndConfirmButtons;
