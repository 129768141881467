import { SubscriptionResponseDto } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useGetPlan } from 'domains/Auth/hooks';
import { usePaymentMethods } from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import RowData from '../RowData';

export default function ChurnedPlanRowData() {
  const t = useTranslationBilling('checkout');
  const { commercialName } = useGetPlan();
  const { paymentMethods, isLoading, isError, refreshPaymentMethods } =
    usePaymentMethods();

  if (isLoading) return <RowData.Skeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('plansAndSubscriptions.plansAndApps.error.title')}
        action={{
          children: t('plansAndSubscriptions.plansAndApps.error.retry'),
          onClick: refreshPaymentMethods,
        }}
      />
    );

  if (!paymentMethods?.length) return null;

  const recurrencyOption = paymentMethods[0].recurrencyOptions[0];

  const subscription: SubscriptionResponseDto = {
    description: `${t('planDescription')} ${commercialName}`,
    amountCurrency: recurrencyOption.amountCurrency,
    amountValue: recurrencyOption.amountBaseValue,
    conceptCode: 'plan-cost',
    recurringFrequency: recurrencyOption.recurringFrequency,
    recurringInterval: recurrencyOption.recurringInterval,
    id: 'id',
    isPlan: true,
    nextExecutionDate: new Date().toISOString(),
    lastExecutionDate: new Date().toISOString(),
  };

  return <RowData subscription={subscription} />;
}
