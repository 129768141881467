import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toStatusType } from 'App/rtk';
import { useAppDispatch } from 'App/store';
import { useToastStatus } from 'commons/hooks';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import {
  useGetUninstallReasonsQuery,
  useUninstallAppMutation,
} from 'domains/PartnersApps/partnersAppsApi';
import {
  clearAppIdToUninstall,
  getAppIdToUninstallSelector,
} from 'domains/PartnersApps/partnersAppsSlice';

const DEFAULT_REASON = { id: '', name: 'Choose one reason' };

const MAX_REASON_DETAILS_CHARACTERS = 500;

const useAppUninstallReasons = () => {
  const dispatch = useAppDispatch();
  const appIdToUninstall = useSelector(getAppIdToUninstallSelector);
  const { t } = useTranslationPartnerApps('appsListPage.actions');

  const [uninstallApp, { status, isLoading: isLoadingUninstallApp }] =
    useUninstallAppMutation();

  const deleteStatus = toStatusType(status);

  useToastStatus({
    status: deleteStatus,
    progress: t('uninstallProgress'),
    success: t('uninstallSuccess'),
    error: t('uninstallError'),
  });

  const [reasonDetail, setReasonDetail] = useState('');
  const [reasonId, setReasonId] = useState('');

  const onChangeReasonId = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setReasonId(event.target.value);
  };
  const onChangeReasonDetail = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (event.target.value.length > MAX_REASON_DETAILS_CHARACTERS) {
      return;
    }
    setReasonDetail(event.target.value);
  };

  const onClose = () => {
    setReasonDetail('');
    setReasonId('');
    dispatch(clearAppIdToUninstall());
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const appId = appIdToUninstall ? appIdToUninstall.toString() : undefined;

    if (!appId || !reasonId) return;

    event.preventDefault();
    await uninstallApp({
      appId,
      reasonId,
      reasonDetail,
    });
    onClose();
  };

  const { data } = useGetUninstallReasonsQuery();
  const uninstallReasons = data ? [DEFAULT_REASON, ...data] : [DEFAULT_REASON];

  const shouldOpenReasonDetailsInput = !!reasonId;
  const shouldOpenModal = !!appIdToUninstall;

  return {
    reasonDetail,
    reasonId,
    onChangeReasonDetail,
    onChangeReasonId,
    handleSubmit,
    uninstallReasons,
    shouldOpenReasonDetailsInput,
    onClose,
    shouldOpenModal,
    isLoadingUninstallApp,
  };
};

export default useAppUninstallReasons;
