import { logEvent } from 'commons/utils/tracking';
import { NUVEM_ENVIO_VIEW } from 'config/trackingEvents';

export const trackPrintNuvemEnvioLabel = (externalSaleId: string) => {
  logEvent(NUVEM_ENVIO_VIEW, {
    page: 'orders',
    order: externalSaleId,
    action: 'print nuvem envio',
  });
};
