import { Fragment, useCallback, useEffect } from 'react';
import { Box, Button, Spinner, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  trackingBillingStartPlanPayment,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import { useGetInvoiceInfo } from 'domains/Billing/Invoices/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ChargeToPay, NoCharges } from '../';
import { useSelectCharges } from '../../hooks';
import SelectChargesToPayTabSkeleton from '../../Skeleton';

function SelectChargesToPay() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const formatCurrency = useFormatCurrency();
  const {
    hasInvoiceInfo,
    fetchInvoiceInfo,
    status: invoiceInfoStatus,
    refreshInvoiceInfo,
  } = useGetInvoiceInfo();
  useEffect(fetchInvoiceInfo, [fetchInvoiceInfo]);

  const {
    chargesStatus,
    refreshChargesToPay,
    chargesToSelect,
    setSelectedCharges,
    totalOfSelectedCharges,
    payOrderCreationStatus,
    onPay,
    isCreatingPayOrder,
  } = useSelectCharges();

  const logEvent = useCheckoutTracking();

  const handleOnPay = () => {
    trackingBillingStartPlanPayment(logEvent);
    onPay();
  };

  useToastStatus({
    status: payOrderCreationStatus,
    error: t('payOrderCreationFailed'),
  });

  const retryLoad = useCallback(() => {
    refreshInvoiceInfo();
    refreshChargesToPay();
  }, [refreshInvoiceInfo, refreshChargesToPay]);

  const { isLoading, isError } = combineStatus(
    chargesStatus,
    invoiceInfoStatus,
  );

  if (isLoading) return <SelectChargesToPayTabSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: retryLoad }}
      />
    );

  if (!chargesToSelect?.length) {
    return <NoCharges />;
  }

  return (
    <Box display="flex" flexDirection="column">
      {chargesToSelect?.map((charge) => (
        <Fragment key={charge.id}>
          <ChargeToPay
            charge={charge}
            setSelectedCharges={setSelectedCharges}
          />
          <hr />
        </Fragment>
      ))}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="4"
        alignItems="center"
      >
        <Button
          disabled={
            !hasInvoiceInfo || isCreatingPayOrder || totalOfSelectedCharges <= 0
          }
          appearance="primary"
          onClick={handleOnPay}
        >
          {t('continueToPay')}
          {isCreatingPayOrder && <Spinner size="small" />}
        </Button>
        <Box display="flex" flexDirection="column">
          <Text fontSize="caption">{t('total')}</Text>
          <Text color="primary-textHigh" fontSize="highlight" fontWeight="bold">
            {formatCurrency(
              totalOfSelectedCharges,
              chargesToSelect[0].amountCurrency,
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default SelectChargesToPay;
