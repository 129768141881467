import { logEvent } from 'commons/utils/tracking';
import { MARKETING_APPS_VIEW_DETAILS } from 'config/trackingEvents';

export const trackingMarketingAppsViewDetails = ({
  appName,
}: {
  appName: string;
}) => {
  logEvent(MARKETING_APPS_VIEW_DETAILS, {
    appName,
  });
};
