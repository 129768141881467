import { Box, Button, Card, FileUploader, Text } from '@nimbus-ds/components';
import WireDataSkeleton from '../WireData/Skeleton';

import 'domains/Billing/Checkout/Checkout.scss';

function InstructionsSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Card padding="none">
        <div className="stratus--checkout-card_content">
          <Box display="flex" flexDirection="column" gap="4" marginBottom="4">
            <Text.Skeleton />
            <WireDataSkeleton />
          </Box>
        </div>
        <hr />
        <div className="stratus--checkout-card_content">
          <Box display="flex" flexDirection="column" gap="4" marginBottom="4">
            <Text.Skeleton />
            <Text.Skeleton />
            <FileUploader.Skeleton />
          </Box>
        </div>
      </Card>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Button.Skeleton />
      </Box>
    </Box>
  );
}

export default InstructionsSkeleton;
