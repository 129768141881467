import { useCallback } from 'react';
import { toStatusType } from 'App/rtk';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import {
  checkoutApi,
  useDeleteRecurrentPaymentMutation,
} from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import { cleanFetchTransactionFeeToPay } from 'domains/Billing/TransactionFees/TransactionFeesSlice';

function useCancelRecurrentPayment() {
  const dispatch = useAppDispatch();
  const [cancelRecurrentPayment, { status, reset: resetStatus }] =
    useDeleteRecurrentPaymentMutation();

  const resetData = useCallback(() => {
    dispatch(cleanFetchTransactionFeeToPay());
    dispatch(
      checkoutApi.util.invalidateTags([
        checkoutTags.recurrentPaymentChargesData,
        checkoutTags.recurrentPaymentConcepts,
      ]),
    );
  }, [dispatch]);

  return {
    ...convertStatusTypeToObject(status),
    cancelRecurrentPayment,
    status: toStatusType(status),
    resetStatus,
    resetData,
  };
}

export { useCancelRecurrentPayment };
