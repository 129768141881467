import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Alert, Button, Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderTop } from 'commons/components';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import {
  DeliveryAdressEditCardValues,
  PersonReceivingOrderValues,
  PersonReceivingOrder,
  DeliveryAdressEditCard,
} from './components';
import { deliveryAdressSchema } from './deliveryAdressSchema';
import { hasShippingApp, isShippingMethodOca } from './utils';

export interface DeliveryAddressValuesType
  extends DeliveryAdressEditCardValues,
    PersonReceivingOrderValues {}

interface EditDeliveryAdressModalProps {
  deliveryAddress: DeliveryAddressValuesType;
  shipping: string;
  isShow: boolean;
  hasGeneratedTag?: boolean;
  onClose: () => void;
  onEditDeliveryAddress: (deliveryAddress: DeliveryAddressValuesType) => void;
  onBack: () => void;
}

function EditDeliveryAdressModal({
  deliveryAddress,
  shipping,
  isShow,
  hasGeneratedTag = false,
  onClose,
  onEditDeliveryAddress,
  onBack,
}: EditDeliveryAdressModalProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const { addToast } = useToast();

  const [save, isLoading, isError, clearError] = useAsyncFunc<
    DeliveryAddressValuesType,
    void
  >(async (data) => {
    if (data) await onEditDeliveryAddress(data);
    addToast({
      appearance: 'success',
      label: t('editDeliveryAdressModal.toast.success'),
    });
    onClose();
  });

  const {
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    values,
    errors,
    isValidating,
  } = useForm<DeliveryAddressValuesType>({
    initialValues: deliveryAddress,
    validationSchema: deliveryAdressSchema,
    validateOnBlur: ['zipcode'],
    onSubmit: async (data) => {
      save(data);
    },
  });

  useEffect(() => {
    if (isError) {
      addToast({
        appearance: 'danger',
        label: t('editDeliveryAdressModal.toast.error'),
      });
      clearError();
    }
  }, [addToast, clearError, isError, t]);

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={{ onClick: onBack }} />}
      headerContent={t('editDeliveryAdressModal.title')}
    >
      <Alert
        appearance="warning"
        show={hasShippingApp(shipping)}
        title={t('editDeliveryAdressModal.alert.hasShippingApp.title')}
      >
        {t('editDeliveryAdressModal.alert.hasShippingApp.description')}
      </Alert>
      <Alert
        appearance="warning"
        show={hasGeneratedTag}
        title={t('editDeliveryAdressModal.alert.hasGeneratedOcaTag.title')}
      >
        {t('editDeliveryAdressModal.alert.hasGeneratedOcaTag.description')}
      </Alert>
      <br />
      <PersonReceivingOrder
        firstName={values.firstName}
        lastName={values.lastName}
        phone={values.phone}
        onChange={handleOnChange}
      />

      <DeliveryAdressEditCard
        street={values.street}
        number={values.number}
        floor={values.floor}
        zipcode={values.zipcode}
        locality={values.locality}
        city={values.city}
        province={values.province}
        errorZipCode={errors.zipcode}
        observations={values.observations}
        reference={values.reference}
        betweenStreet={values.betweenStreet}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        isShippingMethodOca={isShippingMethodOca(shipping)}
      />
      <br />
      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button onClick={onBack}>{`${t(
            'editDeliveryAdressModal.cancel',
          )}`}</Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            spinner={isValidating || isLoading}
            disabled={isValidating || isLoading}
            appearance="primary"
            onClick={handleOnSubmit}
          >
            {`${t('editDeliveryAdressModal.save')}`}
          </Button>
        </Stack.Item>
      </Stack>
      <br />
    </ModalAside>
  );
}

export default EditDeliveryAdressModal;
