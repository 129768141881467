import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchTransactionFeePaid as fetchTransactionFeePaidAction,
  getTransactionFeePaidStatus,
  fetchTransactionFeePaidSelector,
} from 'domains/Billing/TransactionFees/TransactionFeesSlice';

function useTransactionFeePaid() {
  const dispatch = useAppDispatch();
  const status = useSelector(getTransactionFeePaidStatus);
  const transactionFeePaid = useSelector(fetchTransactionFeePaidSelector);

  const fetchTransactionFeePaid = useCallback(() => {
    dispatch(fetchTransactionFeePaidAction());
  }, [dispatch]);

  return {
    status,
    fetchTransactionFeePaid,
    transactionFeePaid,
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
}

export default useTransactionFeePaid;
