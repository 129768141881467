import { ProductDetailsResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { InventoryFiltersInterface } from '../inventoryServices';

export enum StatusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export type InventoryEntitiesType = Record<string, ProductDetailsResponseDto>;

export interface InventoryInterface {
  status: StatusType;
  currentRequestID: string;
  entities: InventoryEntitiesType;
  ids: string[];
  appliedFilters: InventoryFiltersInterface;
  lastRequestedFilters: InventoryFiltersInterface;
  totalProducts: number;
  updateStockStatus: StatusType;
}

export type ThunkStateType = { state: RootStateType };

export interface InventoryProductsAndFiltersInterface {
  products: ProductDetailsResponseDto[];
  filters: InventoryFiltersInterface;
  total: number;
}
