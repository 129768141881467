import { ErrorScreen } from 'commons/components';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';
import { AppsListMobileRow, AppsListMobileSkeleton } from './components';

function AppsListMobile() {
  const { data: apps, isLoading, isError, refetch } = useGetAppsListQuery();

  if (isLoading) return <AppsListMobileSkeleton />;
  if (isError || !apps) return <ErrorScreen onRetry={refetch} />;
  return (
    <>
      {apps.map((app) => (
        <AppsListMobileRow app={app} key={`apps-list-item-${app.id}`} />
      ))}
    </>
  );
}

export default AppsListMobile;
