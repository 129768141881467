import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  cleanNewInternationalShippingStatus,
  NewInternationalShippingParamsInterface,
  createInternationalShipping as createInternationalShippingAction,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';
import { newInternationalShippingStatus } from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice/deliveryMethodsSelectors';

function useNewInternationalShipping() {
  const dispatch = useAppDispatch();
  const status = useSelector(newInternationalShippingStatus);

  const createInternationalShipping = useCallback(
    async (arg: NewInternationalShippingParamsInterface) => {
      await dispatch(createInternationalShippingAction(arg));
      dispatch(cleanNewInternationalShippingStatus());
    },
    [dispatch],
  );

  return {
    status,
    createInternationalShipping,
  };
}

export default useNewInternationalShipping;
