import { Button } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { AiIcon } from 'commons/components/GenerateWithAiLink';
import { useTranslationCommon } from 'commons/hooks';

export interface FirstTimeGenerateButtonsProps {
  disabledGenerateCta: boolean;
  onGenerateContent: () => void;
  onCancel: () => void;
}

export function FirstTimeGenerateButtons({
  disabledGenerateCta,
  onGenerateContent,
  onCancel,
}: Readonly<FirstTimeGenerateButtonsProps>) {
  const t = useTranslationCommon();

  return (
    <Stack justify="flex-end" spacing="tight">
      <Button onClick={onCancel}>
        {t('aiGeneration.resultsModal.cta.cancel')}
      </Button>

      <Button
        appearance="primary"
        onClick={onGenerateContent}
        disabled={disabledGenerateCta}
      >
        <AiIcon color={disabledGenerateCta ? 'grey' : 'white'} />
        {t('aiGeneration.resultsModal.cta.generate')}
      </Button>
    </Stack>
  );
}
