import { useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
} from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { CreditCardDataCard } from './components';
import { usePayorderToPay } from '../../hooks';
import {
  trackingBillingViewPayCreditCard,
  useCheckoutTracking,
} from '../../tracking';
import { PayOrderSummary, IncludeTransactionFee } from '../components';

function PayWithCreditCard() {
  const t = useTranslationBilling('checkout.payWithCreditCard');
  const tBilling = useTranslationBilling();
  const { goBack } = useNavegate();
  const { isShowMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu } = useDesktopMenuContext();
  const backNavigation = {
    onClick: goBack,
    children:
      !isShowMenu && !isShowAsideMenu
        ? tBilling('billingPlansPage.back')
        : undefined,
  };
  const logEvent = useCheckoutTracking();

  useEffect(() => trackingBillingViewPayCreditCard(logEvent), [logEvent]);

  const [includeTransactionFee, setIncludeTransactionFee] = useState(true);

  const { recurrentPaymentCompatible } = usePayorderToPay();

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={
        <HeaderContent
          title={t(recurrentPaymentCompatible ? 'titleWithRp' : 'title')}
        />
      }
      width="large"
    >
      <Layout
        left={
          <CreditCardDataCard includeTransactionFee={includeTransactionFee} />
        }
        right={
          <Box display="flex" flexDirection="column" gap="3">
            <IncludeTransactionFee
              includeTransactionFee={includeTransactionFee}
              onCheckIncludeTransactionFee={setIncludeTransactionFee}
            />
            <PayOrderSummary />
          </Box>
        }
        mainContent
        orderMobile="reverse"
      />
    </IonPageStratus>
  );
}

export default PayWithCreditCard;
