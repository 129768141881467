import {
  AuthenticationFactorResponseDto,
  AuthenticationFactorType,
} from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import Skeleton from './components/Skeleton';
import {
  TOTPActivationCard,
  RecoveryCodesActivationCard,
} from '../AuthenticationFactorActivationCard';

interface AuthenticationFactorListProps {
  authenticationFactors: AuthenticationFactorResponseDto[];
  isLoading: boolean;
  onContinue: (type: AuthenticationFactorType) => () => void;
}

function AuthenticationFactorList({
  isLoading,
  authenticationFactors,
  onContinue,
}: Readonly<AuthenticationFactorListProps>): JSX.Element {
  if (isLoading) return <Skeleton />;

  return (
    <>
      {authenticationFactors.map((factor) => {
        switch (factor.type) {
          case AuthenticationFactorTypes.TOTP:
            return (
              <TOTPActivationCard
                active={factor.active}
                onContinue={onContinue(AuthenticationFactorTypes.TOTP)}
              />
            );
          case AuthenticationFactorTypes.RECOVERY_CODE:
            return (
              <RecoveryCodesActivationCard
                active={factor.active}
                allCodesUsed={factor.allCodesUsed}
                onContinue={onContinue(AuthenticationFactorTypes.RECOVERY_CODE)}
              />
            );
        }
      })}
    </>
  );
}

export default AuthenticationFactorList;
