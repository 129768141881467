import { ChangeEvent, forwardRef } from 'react';
import { Text, Box } from '@nimbus-ds/components';
import classNames from 'classnames';
import { ExclamationCircleIcon } from '@tiendanube/icons';
import { callWhenPress } from 'commons/transformers';
import { hasEmojisAllowed } from 'domains/Catalog/Categories/pages/CategoriesListPage/utils';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface InputCategoryProps {
  value: string;
  isAdding: boolean;
  showEmptyCategories: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter: () => void;
}

const InputCategory = forwardRef<HTMLInputElement, InputCategoryProps>(
  (
    { value, isAdding, showEmptyCategories, handleChange, onPressEnter },
    ref,
  ) => {
    const t = useTranslationCatalog();

    const messageValidationEmojis = t(
      'categories.detail.name.validationEmojis',
    );

    const validation =
      value && !hasEmojisAllowed(value) ? messageValidationEmojis : '';

    const wrapperClass = classNames('nimbus--input-wrapper', {
      'nimbus--input-validation--error': showEmptyCategories || validation,
    });

    const handlePressEnter = () => {
      if (value.trim() !== '') onPressEnter();
    };

    const showExclamationCircleIcon = showEmptyCategories || !!validation;

    return (
      <div className={wrapperClass}>
        <div className="stratus--category-node-label nimbus--input">
          {isAdding && (
            <>
              <input
                onKeyDown={callWhenPress('Enter', handlePressEnter)}
                ref={ref}
                className="draggable nimbus--input__field"
                value={value}
                onChange={handleChange}
              />
              {showExclamationCircleIcon && (
                <span className="nimbus--input__append nimbus--input__append__inside__icon">
                  <ExclamationCircleIcon />
                </span>
              )}
            </>
          )}
          {!isAdding && (
            <Text lineClamp={1} wordBreak="break-all">
              {value}
            </Text>
          )}
        </div>
        {isAdding && !!validation && (
          <Box pl="2" pt="1">
            <Text color="danger-textLow" fontSize="caption">
              {validation}
            </Text>
          </Box>
        )}
      </div>
    );
  },
);

InputCategory.displayName = 'InputCategory';

export default InputCategory;
