import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Link } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { Responsive } from 'commons/components';
import {
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import {
  DraftOrdersListDesktop,
  DraftOrdersListEmpty,
  DraftOrdersListError,
  DraftOrdersListMobile,
} from './components';
import { DraftOrdersPOSAlert } from '../../components/DraftOrdersPOSAlert';
import { useDraftOrders } from '../../hooks';

function DraftOrderList(): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const { goTo } = useNavegate();
  const isMobileDevice = useIsMobileDevice();

  const { isError, fetchDraftOrders, isEmpty, isSuccess } = useDraftOrders();

  const goToNewDraftOrder = () => {
    goTo('/draft-orders/new');
  };

  const title = t('draftOrders.title');

  useEffect(() => {
    if (isEmpty && !isSuccess) {
      fetchDraftOrders();
    }
  }, [fetchDraftOrders, isEmpty, isSuccess]);

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          actions={<Link onClick={goToNewDraftOrder} icon={PlusCircleIcon} />}
        />
      }
      headerContent={
        <HeaderContent
          title={title}
          actions={
            !isEmpty && (
              <ActionsDesktop
                actions={[
                  {
                    appearance: 'primary',
                    children: t('draftOrders.addDraftOrder'),
                    icon: PlusCircleIcon,
                    onClick: goToNewDraftOrder,
                  },
                ]}
              />
            )
          }
        />
      }
    >
      {!isMobileDevice && <DraftOrdersPOSAlert />}
      {isEmpty && <DraftOrdersListEmpty />}
      {isError && <DraftOrdersListError onRetryError={fetchDraftOrders} />}
      {!isEmpty && !isError && (
        <Responsive
          mobile={<DraftOrdersListMobile />}
          desktop={<DraftOrdersListDesktop />}
        />
      )}
      <HelpLink
        title={t('draftOrders.helpLink.aboutOrders')}
        linkURL={t('draftOrders.helpLink.aboutOrdersUrl')}
        currentViewTracking={t('draftOrders.helpLink.aboutOrdersTrackingName')}
        previousValue="draft_order_list"
      />
    </IonPageStratus>
  );
}

export default DraftOrderList;
