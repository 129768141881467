import { useEffect } from 'react';
import { Box, Chip } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { useNotifications } from 'App/capacitor';
import OnboardingCompletedAlert from 'App/components/OnboardingCompletedAlert';
import ShowByCountry from 'App/components/ShowByCountry';
import ShowByRole from 'App/components/ShowByRole/ShowByRole';
import HelpLink from 'App/HelpLink';
import {
  HeaderContent,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { DASHBOARD_CAN_LINKS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useGetCountry,
  useGetPlanExpiresIn,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { TransactionFeesPaymentModal } from 'domains/Billing/TransactionFees/components';
import { useTransactionFeeToPay } from 'domains/Billing/TransactionFees/pages/TransactionFeesPage/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useTotalOrders } from 'domains/Orders/Orders/hooks';
import { useHasAccessToStats } from 'domains/Statistics';
import {
  ActivatePushNotificationsAlert,
  AdditionalSettings,
  AlertNotifications,
  FirstSaleCourseCard,
  FreeContents,
  FreeTransactionFeeReminderModal,
  ImproveYourStore,
  NewAlertNotifications,
  NewEcommerceNaPraticaCard,
  NewOnboardingTasks,
  PendingOrders,
  RateSuggestionModal,
  StoreStats,
  TransactionFeeToPayInfoModal,
  UpdateNotificationsAlert,
} from './components';
import HeaderPage from './components/HeaderPage';
import useOnboardingTasks from './components/OnboardingTasks/useOnboardingTasks';

function NewDashboardPage() {
  const t = useTranslationDashboard();
  const language = useTranslationLanguage();
  const { isLoadingHasOrders, hasOrder, totalAllOrders } = useTotalOrders();
  const { validateRoles } = useRoles();
  const storeCountry = useGetCountry();
  const isMobile = useIsMobileDevice();
  const hasAccessToStats = useHasAccessToStats();
  const requireTransactionFeesFetch = validateRoles([
    'full',
    'nuvem_pago',
    'nuvem_envio',
  ]);
  useTransactionFeeToPay(requireTransactionFeesFetch);

  const expiresIn = useGetPlanExpiresIn();
  const { getOnboarding, hasCompleted } = useOnboardingTasks();
  useNotifications();
  const hasOrderRoleNonFull = validateRoles('orders', 'full');
  const hasOrderRoleStatsNonFull = validateRoles('statistics', 'full');
  const hasRoleFull = validateRoles('full');

  const showExpiresInChip =
    expiresIn !== undefined &&
    expiresIn > 0 &&
    (isMobile || ![Country.BR, Country.AR, Country.MX].includes(storeCountry));

  useEffect(() => {
    if (!hasCompleted && totalAllOrders < 3) getOnboarding();
  }, [getOnboarding, hasCompleted, totalAllOrders]);
  // This component duplicates DashboardPage to implement New Onboarding feature [NA-5773]. Should be unified after testing results.
  return (
    <IonPageStratus
      width={!hasCompleted && totalAllOrders < 3 ? 'small' : 'medium'}
      headerContent={<HeaderContent title={t('title')} />}
      headerTop={<ActivatePushNotificationsAlert />}
    >
      <UpdateNotificationsAlert />

      <Box display="flex" flexDirection="column" gap="12">
        <Box display="flex" flexDirection="column" gap="4">
          {showExpiresInChip && (
            <Chip text={t('header.chip', { count: expiresIn })} />
          )}

          {hasCompleted && totalAllOrders < 3 && <OnboardingCompletedAlert />}

          {!hasCompleted && totalAllOrders < 3 ? (
            <NewAlertNotifications />
          ) : (
            <AlertNotifications />
          )}

          <Box display="flex" flexDirection="column" gap="6">
            {!hasCompleted && totalAllOrders < 3 && <HeaderPage />}

            {hasCompleted && totalAllOrders < 3 && (
              <ShowByRole includeRoles="full">
                <NewEcommerceNaPraticaCard />
              </ShowByRole>
            )}

            {hasCompleted && totalAllOrders < 3 && (
              <ShowByRole includeRoles="full">
                <FirstSaleCourseCard />
              </ShowByRole>
            )}

            {!hasCompleted && totalAllOrders < 3 && <NewOnboardingTasks />}
          </Box>
        </Box>
        {/* Display Pending Orders block if the user has the "full" role and has completed the onboarding or has sales */}
        {/* Display Pending Orders block always if the user has only the "orders" role */}
        {((hasRoleFull && (hasCompleted || hasOrder)) ||
          hasOrderRoleNonFull) && (
          <ShowByRole includeRoles="orders">
            <PendingOrders />
          </ShowByRole>
        )}

        {/* Display the Statistics block if the user has "orders" and "statistics" roles or "full" role, and has orders */}
        {(hasOrderRoleNonFull || hasRoleFull) &&
          hasAccessToStats &&
          !isLoadingHasOrders && (
            <ShowByRole includeRoles="statistics">
              <StoreStats showDailyStats={hasOrder} />
            </ShowByRole>
          )}
        {/* Display the Statistics block if the user has only the "statistics" role */}
        {!hasOrderRoleNonFull &&
          hasOrderRoleStatsNonFull &&
          hasAccessToStats && (
            <ShowByRole includeRoles="statistics">
              <StoreStats />
            </ShowByRole>
          )}

        <ShowByRole includeRoles="full">
          <AdditionalSettings />
        </ShowByRole>

        {hasCompleted && totalAllOrders < 3 && (
          <ShowByCountry excludeCountries={[Country.MX]}>
            <FreeContents />
          </ShowByCountry>
        )}
        {(hasCompleted || totalAllOrders >= 3) && <ImproveYourStore />}
      </Box>
      <ShowByRole includeRoles="full">
        <TransactionFeeToPayInfoModal />
      </ShowByRole>
      <ShowByRole includeRoles={['full', 'nuvem_pago', 'nuvem_envio']}>
        <FreeTransactionFeeReminderModal />
      </ShowByRole>
      <ShowByRole includeRoles="full">
        <TransactionFeesPaymentModal initialFetch={false} />
      </ShowByRole>
      <ShowByRole includeRoles="full">
        <RateSuggestionModal />
      </ShowByRole>
      {/*  This code is commented out since this modal is temporarily removed until the flow is redefined. */}
      {/* <ShowByRole includeRoles="full">
        <EndTrialModal />
      </ShowByRole> */}
      {(hasCompleted || totalAllOrders >= 3) && (
        <HelpLink
          title={t('canLink')}
          linkURL={DASHBOARD_CAN_LINKS[language]}
          icon="both"
          previousValue=""
          currentViewTracking=""
        />
      )}

      {!hasCompleted && totalAllOrders < 3 && (
        <HelpLink
          title={t('onboardingLink.externalLink.label')}
          linkURL={t('onboardingLink.externalLink.href')}
          icon="both"
          previousValue="dashboard"
          currentViewTracking="primeros-pasos"
        />
      )}
    </IonPageStratus>
  );
}

export default NewDashboardPage;
