import { useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  PaginationResponseDto,
  ProductsWithoutStockOrganizeEnum,
  SectionProductDto,
} from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { InterfaceNameValue, Tabs } from '@tiendanube/components';
import { CheckCircleIcon } from '@tiendanube/icons';
import { ModalConfirmationBase } from 'App/components';
import ShowByCountry from 'App/components/ShowByCountry';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { NEW_ADMIN_EVENT_CATEGORIES } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  CardWithTitle,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_ORGANIZE_PRODUCTS } from 'commons/constants';
import {
  useAsyncFunc,
  useForm,
  useModal,
  useToast,
  useTranslationLanguage,
} from 'commons/hooks';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useGetTags } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  SortBySelectType,
  updateProductsOrder,
  useProductOrder,
} from 'domains/Online';
import useProductOrderList from 'domains/Online/Themes/hooks/useProductOrderList';
import { FeaturedProducts, OrganizeProducts } from './components';
import OrganizeProductsSkeleton from './components/OrganizeProductsSkeleton';
import { trackingProductOrganizeClick } from '../../tracking';

export type OrganizeProductsValuesType = {
  noStockProductToBottom: ProductsWithoutStockOrganizeEnum;
  categoryId: string;
  sortBy: SortBySelectType;
  order: Record<string, number>;
};

const TAB_FEATURED = 0;
const TAB_ORGANIZE = 1;

function OrganizeProductsPage() {
  const tags = useGetTags();
  const t = useTranslationCatalog();
  const language = useTranslationLanguage();
  const { goBack } = useNavegate();

  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const [activeTab, setActiveTab] = useState(0);
  const [showModalTabConfirm, openModalTabConfirm, closeModalTabConfirm] =
    useModal();
  const [
    showModalCategoryConfirm,
    openModalCategoryConfirm,
    closeModalCategoryConfirm,
  ] = useModal();
  const [tempCategoryId, setTempCategoryId] = useState<string>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<OrganizeProductsValuesType>();

  const { status, options, categories, fetchProductOrder } = useProductOrder();

  const {
    products,
    status: statusList,
    setProducts,
    resetProducts,
    fetchProductOrderlist,
  } = useProductOrderList();

  const [onSubmit, isSaving] = useAsyncFunc<OrganizeProductsValuesType, void>(
    async (data) => {
      const hasTagEventCategories = tags.includes(NEW_ADMIN_EVENT_CATEGORIES);

      if (hasTagEventCategories) {
        trackingProductOrganizeClick(data?.categoryId);
      }
      if (!data) return;
      addToastProgress({
        label: t('products.order.toast.progress'),
      });
      await updateProductsOrder(data);
      fetchProductOrder(false);
      setSavedValue(data);
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('products.order.toast.success'),
        appearance: 'success',
      });
      if (tempCategoryId) {
        const categoryId = tempCategoryId as string;
        const sortBy = getSortByFromCategoryId(categoryId);
        getProducts(categoryId, sortBy);
        setFieldValue('categoryId', categoryId);
        setFieldValue('sortBy', sortBy);
        sortBy &&
          setSavedValue({
            ...values,
            categoryId,
            sortBy,
          });
        setTempCategoryId(undefined);
      }
      setIsDirty(false);
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('products.order.toast.error'),
        appearance: 'danger',
      });
    },
  );

  const initialValue = useMemo(
    () =>
      savedValue
        ? savedValue
        : {
            noStockProductToBottom: options?.noStockProductToBottom,
            categoryId: '0',
            sortBy: options?.allProductsSortBy,
            order: {},
          },
    [savedValue, options?.allProductsSortBy, options?.noStockProductToBottom],
  ) as OrganizeProductsValuesType;

  const { values, handleOnChange, handleOnSubmit, setFieldValue, resetValues } =
    useForm<OrganizeProductsValuesType>({
      initialValues: initialValue,
      onSubmit,
    });

  const saveActionMobile: ActionProp | undefined =
    activeTab === TAB_ORGANIZE
      ? {
          onClick: handleOnSubmit,
          icon: CheckCircleIcon,
          spinner: isSaving,
          disabled: isSaving,
        }
      : undefined;

  const saveActionsDesktop: ActionProp[] | undefined =
    activeTab === TAB_ORGANIZE
      ? [
          {
            onClick: handleOnSubmit,
            children: t('common:cancelAndSaveButtons.save'),
            appearance: 'primary',
            spinner: isSaving,
            disabled: isSaving,
          },
        ]
      : undefined;

  const handleOnChangeTab = (tab: number) => {
    setActiveTab(tab);
  };

  const getSortByFromCategoryId = (categoryId: string) => {
    const categorySelected = categories?.find(
      (current) => current.id === categoryId,
    );
    return categoryId === '0'
      ? options?.allProductsSortBy
      : categorySelected?.sortBy;
  };

  const handleOnChangeData = (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => {
    if (statusList === 'loading') return;
    if (data.name === 'categoryId') {
      const categoryId = data.value as string;
      if (isDirty) {
        setTempCategoryId(categoryId);
        openModalCategoryConfirm();
        return;
      }
      const sortBy = getSortByFromCategoryId(categoryId);
      getProducts(categoryId, sortBy);
      setFieldValue('categoryId', categoryId);
      setFieldValue('sortBy', sortBy);
      return;
    }
    if (data.name === 'sortBy') {
      const categoryId = values.categoryId;
      const sortBy = data.value as SortBySelectType;
      getProducts(categoryId, sortBy);
      setFieldValue('sortBy', sortBy);
      setIsDirty(true);
      return;
    }
    setIsDirty(true);
    handleOnChange(data);
  };

  const getProducts = (category?: string, sortBy?: SortBySelectType) => {
    resetProducts();
    fetchProductOrderlist(
      {
        categoryId: category || values.categoryId,
        sortBy: sortBy || values.sortBy,
        page: 1,
        perPage: 50,
      },
      true,
    );
  };

  const getMoreProducts = () => {
    if (statusList === 'loading') {
      // avoid concurrent fecth
      return;
    }
    const page = products?.pagination.nextPage;
    const perPage = products?.pagination.perPage;
    if (page === null) {
      // no mmore pages to fetch
      return;
    }
    fetchProductOrderlist({
      categoryId: values.categoryId,
      sortBy: values.sortBy,
      page: page as number,
      perPage: perPage as number,
    });
  };

  const handleOnChangeProducts = (newProcutsOrdered: SectionProductDto[]) => {
    setProducts((current) => ({
      pagination: current?.pagination as PaginationResponseDto,
      results: newProcutsOrdered,
    }));
    const arr = newProcutsOrdered.map((current, idx) => ({
      id: current.productId,
      pos: idx + 1,
    }));
    const obj = arr.reduce(
      (accumulator, value) => ({ ...accumulator, [value.id]: value.pos }),
      {},
    );
    setFieldValue('order', obj);
    setIsDirty(true);
  };

  const handleOnActionModalCategoryConfirm = async () => {
    closeModalCategoryConfirm();
    handleOnSubmit();
  };

  const handleOnCloseModalCategoryConfirm = () => {
    setTempCategoryId(undefined);
    closeModalCategoryConfirm();
  };

  const handleOnActionModalTabConfirm = async () => {
    resetValues();
    getProducts(initialValue.categoryId, initialValue.sortBy);
    setIsDirty(false);
    closeModalTabConfirm();
  };

  const handleOnCloseModalTabConfirm = () => {
    closeModalTabConfirm();
    setActiveTab(TAB_ORGANIZE);
  };

  useEffect(() => {
    fetchProductOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === 'success') {
      getProducts(initialValue.categoryId, initialValue.sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (activeTab === TAB_FEATURED && status === 'success' && isDirty) {
      openModalTabConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, status, initialValue.categoryId, initialValue.sortBy]);

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop
          navigation={{
            onClick: goBack,
          }}
          mainAction={saveActionMobile}
        />
      }
      headerContent={
        <HeaderContent
          title={t('products.organizeProducts.title')}
          subtitle={t('products.organizeProducts.description')}
          actions={
            saveActionsDesktop && (
              <ActionsDesktop actions={saveActionsDesktop} />
            )
          }
        />
      }
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      <Tabs onChange={handleOnChangeTab} activeTab={activeTab}>
        <Tabs.Item label={t('products.organizeProducts.featured')}>
          <FeaturedProducts />
        </Tabs.Item>
        <Tabs.Item label={t('products.organizeProducts.organize')}>
          <>
            {status === 'loading' && <OrganizeProductsSkeleton />}
            {status === 'error' && (
              <CardWithTitle>
                <ErrorScreen
                  onRetry={fetchProductOrder}
                  description={t('products.order.error')}
                />
              </CardWithTitle>
            )}
            {status === 'success' && options && (
              <OrganizeProducts
                products={products?.results || []}
                productsStatus={statusList}
                productsCount={products?.pagination.totalResults}
                noStockProductToBottom={values.noStockProductToBottom}
                categoryId={values.categoryId}
                categories={categories || []}
                sortBy={values.sortBy}
                isSaving={isSaving}
                onEnded={getMoreProducts}
                onChange={handleOnChangeData}
                onChangeProducts={handleOnChangeProducts}
                onSubmit={handleOnSubmit}
              />
            )}
          </>
        </Tabs.Item>
      </Tabs>
      {showModalTabConfirm && (
        <ModalConfirmationBase
          show
          onConfirm={handleOnActionModalTabConfirm}
          onCancel={handleOnCloseModalTabConfirm}
        />
      )}
      {showModalCategoryConfirm && (
        <ModalConfirmationBase
          show
          title={t('products.order.modalConfirmation.title')}
          text={t('products.order.modalConfirmation.body')}
          label={t('products.order.modalConfirmation.confirm')}
          labelCancel={t('products.order.modalConfirmation.continue')}
          appearance="primary"
          onConfirm={handleOnActionModalCategoryConfirm}
          onCancel={handleOnCloseModalCategoryConfirm}
        />
      )}

      <ShowByCountry excludeCountries={[Country.BR]}>
        <HelpLink
          title={t('helpLink.organizeProducts')}
          previousValue=""
          currentViewTracking={t('helpLink.organizeProductsTrackingName')}
          linkURL={HELP_LINKS_ORGANIZE_PRODUCTS[language]}
          icon="both"
        />
      </ShowByCountry>
    </IonPageStratus>
  );
}

export default OrganizeProductsPage;
