import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { useResponsive } from 'commons/components';
import { calculateOpacity } from 'commons/utils/calculateOpacity';
import { BAR_CHART_HEIGTH, CHART_COLORS } from 'domains/Statistics/utils';
import SalesByProvinceTooltip from './components/RankByProvinceTooltip';
import RankByProvinceYAxisLeftTick from './components/RankByProvinceYAxisLeftTick';
import RankByProvinceYAxisRigthTick from './components/RankByProvinceYAxisRigthTick';
import {
  MARGIN_BOTTOM,
  RANK_BY_PROVINCE_BAR_CHART_MARGIN,
  TICK_WIDTH,
} from './utils';
import TooltipChartStratus from '../TooltipChartStratus';

const noop = () => null;

export interface ProvincesRankingPoint {
  id: string;
  name: string;
  value: number;
  percentageDiff?: number;
}

interface RankByProvinceChartProps {
  provincesRanking: ProvincesRankingPoint[];
  color?: string;
  onMouseOver?: (id: string) => void;
  onMouseLeave?: () => void;
  onClick?: (id: string) => void;
}

function RankByProvinceChart({
  provincesRanking,
  color = CHART_COLORS.burntOrange,
  onMouseOver = noop,
  onMouseLeave = noop,
  onClick = noop,
}: RankByProvinceChartProps) {
  const { isMobile, isDesktop } = useResponsive();

  return (
    <ResponsiveContainer width="100%" height={BAR_CHART_HEIGTH}>
      <BarChart
        margin={{
          ...RANK_BY_PROVINCE_BAR_CHART_MARGIN,
          ...(isDesktop && { right: MARGIN_BOTTOM }),
        }}
        layout="vertical"
        data={provincesRanking}
        barSize={24}
      >
        <CartesianGrid scale="point" horizontal={false} />
        <Tooltip
          trigger="hover"
          content={
            <TooltipChartStratus>
              <SalesByProvinceTooltip />
            </TooltipChartStratus>
          }
        />
        <XAxis type="number" hide />
        <YAxis
          scale="point"
          tick={
            <RankByProvinceYAxisLeftTick
              data={provincesRanking}
              width={TICK_WIDTH}
              showAllwaysPercentage={isMobile}
            />
          }
          axisLine={false}
          tickLine={false}
          yAxisId="0"
          type="category"
        />
        {isDesktop && (
          <YAxis
            scale="point"
            orientation="right"
            yAxisId="1"
            type="category"
            axisLine={false}
            tickLine={false}
            tick={
              <RankByProvinceYAxisRigthTick
                data={provincesRanking}
                width={TICK_WIDTH}
              />
            }
          />
        )}
        <Bar stroke={color} strokeWidth={1} dataKey="value" fill={color}>
          {provincesRanking.map((entry, index) => (
            <Cell
              onMouseOver={() => onMouseOver(entry.id)}
              onMouseLeave={onMouseLeave}
              key={entry.name}
              fillOpacity={calculateOpacity(index, provincesRanking.length)}
              onClick={() => onClick(entry.id)}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default RankByProvinceChart;
