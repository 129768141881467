import { useEffect } from 'react';
import { getIsPageWithCodeParam } from 'App/components/PrivateRoute/utils';
import { useAppDispatch } from 'App/store';
import { getCurrentAuth } from 'domains/Auth/authSlice';

function getCodeFromQuery() {
  // If we need to get the 'code' param from the query
  if (getIsPageWithCodeParam(window.location.pathname)) return '';

  return new URLSearchParams(window.location.search).get('code') || '';
}

function useGetInitialState(): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const code = getCodeFromQuery();
    dispatch(getCurrentAuth(code));
  }, [dispatch]);
}

export default useGetInitialState;
