import { EcosystemIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useHandleMenuClick } from 'App/hooks';
import { seoRedirectsRoutes } from './seoRedirectsRoutes';
import { menuSecondarySeoRedirectsClick } from './tracking';

const path = seoRedirectsRoutes.redirects;

export function SeoRedirectsMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const handleClick = () => {
    menuSecondarySeoRedirectsClick();
    handleClickItem();
  };

  return (
    <MenuButton
      as={Link}
      to={path}
      startIcon={EcosystemIcon}
      onClick={handleClick}
      active={pathname.startsWith(path)}
      label={t('settingsMenu.advanced.redirects301')}
    />
  );
}
