import { Box, Link, Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { BaseCardCollapsible, TabsMultiLanguage } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { TrackingPageMessageInput } from './components';
import { SettingsCheckoutFormProps } from '../../types';

export function TrackingPageMessageCard({
  values: { postSaleMessageShow, postSaleMessage },
  onChange,
  onBlur,
}: SettingsCheckoutFormProps) {
  const t = useTranslationConfigurations(
    'settingsCheckout.settingsCheckoutForm.trackingPageMessageCard',
  );

  const handleChangeTogle = () => {
    onChange({
      name: 'postSaleMessageShow',
      value: !postSaleMessageShow,
    });
  };

  return (
    <BaseCardCollapsible
      title={t('title')}
      subtitle={t('subTitle')}
      active={postSaleMessageShow}
      alignSwitch="flex-start"
      appearance="toggle"
      onChange={handleChangeTogle}
      paddingBody="base"
    >
      {postSaleMessageShow && (
        <Box display="flex" flexDirection="column" gap="4">
          <Text>
            <Trans
              t={t}
              i18nKey="description"
              components={{
                customLink: (
                  <Link
                    appearance="primary"
                    as="a"
                    textDecoration="none"
                    href={t('link')}
                    target="_blank"
                  >
                    {' '}
                  </Link>
                ),
              }}
            />
          </Text>
          <TabsMultiLanguage>
            <TrackingPageMessageInput
              value={postSaleMessage}
              onChange={onChange}
              onBlur={onBlur}
            />
          </TabsMultiLanguage>
        </Box>
      )}
    </BaseCardCollapsible>
  );
}
