import { OrderResponseDto } from '@tiendanube/common';
import { Label, Stack, Text } from '@tiendanube/components';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface OrderCancelLabelsProps {
  orders: OrderResponseDto[];
  bulkSelectedOrdersCount: number;
}

function OrderCancelLabels({
  orders,
  bulkSelectedOrdersCount,
}: OrderCancelLabelsProps): JSX.Element {
  const t = useTranslationOrders();

  return bulkSelectedOrdersCount > ORDERS_PER_PAGE ? (
    <>
      <Stack.Item>
        <Text size="caption">{t('cancelModal.titleBulkOrderSelected')}</Text>
      </Stack.Item>
      <Stack.Item>
        <Label
          id="orders-bulk-cancel-label"
          label={
            bulkSelectedOrdersCount < 100
              ? `${t('cancelModal.orderBulkSelected', {
                  count: bulkSelectedOrdersCount,
                })}`
              : `${t('cancelModal.orderBulkSelectedMax')}`
          }
        />
      </Stack.Item>
    </>
  ) : (
    <>
      <Stack.Item>
        <Text size="caption">
          {t('cancelModal.titleOrderSelected', {
            count: orders.length,
          })}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Stack wrap spacing="tight">
          {orders.map((order) => (
            <Label
              id={`${order.number}`}
              key={order.id}
              label={`${t('cancelModal.orderSelected', {
                number: order.number,
              })}`}
            />
          ))}
        </Stack>
      </Stack.Item>
    </>
  );
}

export default OrderCancelLabels;
