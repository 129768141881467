import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  getDetailActiveAppData,
  getDetailActiveAppStatus,
  getDetailActiveShippingAction,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

function useGetDetailActiveApp() {
  const dispatch = useAppDispatch();
  const detailActiveApp = useSelector(getDetailActiveAppData);
  const { isLoading, isError } = useSelector(getDetailActiveAppStatus);

  const fetchDetailActiveApp = useCallback(
    (id: string) => {
      dispatch(getDetailActiveShippingAction(id));
    },
    [dispatch],
  );

  return {
    detailActiveApp,
    isLoading,
    isError,
    fetchDetailActiveApp,
  };
}

export default useGetDetailActiveApp;
