import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Text } from '@tiendanube/components';

function RemarksCardSkeleton(): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('draftOrders.remarksCard.title')}>
      <Text.Skeleton />
    </Card>
  );
}

export default RemarksCardSkeleton;
