import { Route, Switch } from 'react-router-dom';
import { HideNavTabs } from 'App/components/Navtabs';
import { EditCouponPage, CouponListPage, NewCouponPage } from './pages';
import { MARKETING_ROUTES } from '../marketingRoutes';

function Coupons() {
  return (
    <Switch>
      <Route path={MARKETING_ROUTES.couponList} exact>
        <CouponListPage />
      </Route>
      <Route path={MARKETING_ROUTES.newCoupon} exact>
        <HideNavTabs />
        <NewCouponPage />
      </Route>
      <Route path={MARKETING_ROUTES.editCoupon}>
        <HideNavTabs />
        <EditCouponPage />
      </Route>
    </Switch>
  );
}

export default Coupons;
