import { useEffect } from 'react';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import autoDownloadFileFromUrl from 'commons/utils/autoDownloadFileFromUrl';

/* Increase the time limit from 5 by 5 seconds until
   reach 60 seconds, as is currently done in the core */
const INTERVAL_TIME = 5000; // 5 seconds
const LIMIT_INTERVAL_TIME = 60000; // 60 seconds
const MAX_ERROR_RETRIES = 5; // Error retries when the job fails

function useCheckBackgroundJobs(id: string, isCsvDownload = false) {
  const {
    jobStatus,
    csvDownloadLinkUrl,
    checkJob,
    setFailedJob,
    setDownloadedJob,
  } = useBackgroundJobs(id, isCsvDownload);

  useEffect(() => {
    let intervalTime = INTERVAL_TIME;
    let timeoutId: NodeJS.Timeout;
    let retryCount = 1;
    let status = 'idle';

    const initialize = async () => {
      retryCount = 1;
      intervalTime = INTERVAL_TIME;
      clearTimeout(timeoutId);
    };

    const updateIntervalTime = () => {
      if (intervalTime < LIMIT_INTERVAL_TIME) {
        intervalTime += INTERVAL_TIME;
      }
    };

    const handleRetries = () => {
      if (retryCount < MAX_ERROR_RETRIES) {
        retryCount++;
        updateIntervalTime();
        timeoutId = setTimeout(() => {
          checkStatus();
        }, intervalTime);
        return;
      }
      status = 'failed';
      setFailedJob();
      initialize();
    };

    const checkStatus = async () => {
      try {
        status = (await checkJob()) || 'idle';
        if (status === 'pending') {
          updateIntervalTime();
          timeoutId = setTimeout(() => {
            checkStatus();
          }, intervalTime);
        }
        if (status === 'ready') {
          initialize();
        }
      } catch (_error) {
        status !== 'failed' && handleRetries();
      }
    };

    checkStatus();

    return () => {
      initialize();
    };
  }, [checkJob, setFailedJob]);

  useEffect(() => {
    if (isCsvDownload && csvDownloadLinkUrl && jobStatus === 'ready') {
      autoDownloadFileFromUrl(csvDownloadLinkUrl);
      setDownloadedJob();
    }
  }, [csvDownloadLinkUrl, isCsvDownload, jobStatus, setDownloadedJob]);
}

export default useCheckBackgroundJobs;
