import { StatusType, InventoryInterface } from './types';
import { InventoryFiltersInterface } from '../inventoryServices';

export const defaultFilters: InventoryFiltersInterface = {
  page: 1,
  q: '',
  sortBy: '',
  categoryId: '',
  stock: '',
  minStock: '',
  maxStock: '',
  locationId: '',
};

export const initialFilters = { ...defaultFilters, locationId: undefined };

export const initialState: InventoryInterface = {
  status: StatusType.idle,
  currentRequestID: '',
  entities: {},
  totalProducts: 0,
  ids: [],
  appliedFilters: initialFilters,
  lastRequestedFilters: initialFilters,
  updateStockStatus: StatusType.idle,
};
