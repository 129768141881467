const objectToUrlParams = (
  params: Record<string, string | number | boolean>,
): string => {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    if (params[key] !== '') {
      searchParams.append(key, `${params[key]}`);
    }
  }
  return searchParams.toString();
};

export default objectToUrlParams;
